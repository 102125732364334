import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ArchitectureDownloadTableBody from './ArchitectureDownloadTableBody';


interface MediaDownloadSystemTreeI {
    downloadProductInfo: any;
    groupContextAbbreviation: any;
    openMediaDownload: any;
    type: any;
    groupContextImageCategory: any;
    userpermission?:any;
    groupContextId?: any;
}

const ArchitectureDownloadSystemTree: React.FC<MediaDownloadSystemTreeI> = (props) => {
    const { downloadProductInfo, groupContextAbbreviation, groupContextImageCategory, openMediaDownload, type, groupContextId,userpermission } = props;
    console.log(downloadProductInfo, "downloadProductInfodownloadProductInfo")
    return (
        <Table>
            <TableHead sx={{ backgroundColor: 'primary.light' }}>
                <TableRow>
                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Product</TableCell>
                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Range</TableCell>
                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Actions</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {downloadProductInfo && downloadProductInfo?.map((item: any) => {
                    return (<>
                        <ArchitectureDownloadTableBody userpermission={userpermission} topVaultName={item?.name} topVaultId={item?.top_vault_id} countImage={item?.count} groupContextAbbreviation={groupContextAbbreviation} groupContextId={groupContextId} groupContextImageCategory={groupContextImageCategory} openMediaDownload={openMediaDownload} type={type} />
                    </>)
                })
                }
            </TableBody>
        </Table>)
}

export default ArchitectureDownloadSystemTree;