import * as React from "react";
import {
  Box,
  Checkbox,
  Chip,
  Dialog,
  DialogContent,
  Divider,
  Menu,
  MenuItem,
  Paper,
  Skeleton,
  Slide,
  Typography,
} from "@mui/material";
import styles from "./MediaProductandSystemTreeDetails.module.scss";
import AddIcon from "@mui/icons-material/Add";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { API } from "../../api-services";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";


import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import CancelIcon from "@mui/icons-material/Cancel";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { useBaseModal } from "../SearchPage/useBaseModal";
import AddGroupNameModal from "./AddGroupNameModal";
import InfoIcon from "@mui/icons-material/Info";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import { set } from "lodash";
// import StatisticTable from "./StatisticTable";
import ArchMoveGroup from "./ArchMoveGroup";
import { TransitionProps } from "@mui/material/transitions";
import ArchitectureDownloadSystemTree from "./ArchitectureDownloadSystemTree";
import ArchGroupDownloadConsolidated from "./ArchGroupDownloadConsolidated";
import swal from "sweetalert";
import successIcon from "../../Assets/images/success.png";
import Swal from "sweetalert2";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import { UserPermissionData } from "./ArchitectureCreateLanding";
import { userSelectors } from "../../Redux/Reducers/user.reducer";
import { useSelector } from "react-redux";
import FolderIcon from '@mui/icons-material/Folder';
import openFolder from '../../Assets/images/svgs/openfolderblue.svg';
import { ADMIN } from "../../Redux/Services/admin.service";


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion
    disableGutters
    elevation={0}
    square
    {...props}
  />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    // expandIcon={<ArrowRightIcon sx={{ fontSize: "1.5rem", color: "primary.main" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "white",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(0deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  // borderTop: "1px solid #ddefff",
}));

export interface IArchitectureProductandSystemTreeDetailsProps {
  selectedType: any;
  setSelectedType: any;
  SequenceCount: any;
  setRecycleBinCounter: any;
  recycleBinCounter: any;


}

export function ArchitectureProductandSystemTreeDetails(
  props: IArchitectureProductandSystemTreeDetailsProps
) {
  const history = useHistory();
  const {
    projectId,
    projectname,
    abbr,
    topvoult,
    subSystem,
    groupId,
    collapestatus,
    pageNumber,
    product,
    groupName,
    diagramsCount
  } = useRouteParams<any>();

  const { selectedType, setSelectedType, setRecycleBinCounter, recycleBinCounter, SequenceCount } = props;
  const [systemdata, setSystemData] = React.useState<any>([]);
  const [rightClickType, setrightClickType] = React.useState<any>(null);
  const [GroupList, setGroupList] = React.useState<any>([]);
  const [systemLoader, setSysytemLoader] = React.useState<any>(false);
  const [GroupLoader, setGroupLoader] = React.useState<any>(false);
  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  const groupmodal = useBaseModal();
  const [SelectedsystemItem, setSelectedsystemItem] = React.useState<any>({});

  const [counter, setcounter] = React.useState<any>(0);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openSystemMediaDownload, setOpenSystemMediaDownload] =
    React.useState(false);
  const [anchorElTooltip, setAnchorElTooltip] =
    React.useState<null | HTMLElement>(null);
  const [openMediaConsolidatedDownload, setOpenMediaConsolidatedDownload] =
    React.useState(false);
  const [openMediaDownload, setOpenMediaDownload] = React.useState(false);
  const [downloadProductInfo, setDownloadProductInfo] = React.useState<any>([]);
  const [Infodata, setInfodata] = React.useState<any>([]);
  // const tablemodal = useBaseModal();
  const movemodal = useBaseModal();
  const open = Boolean(anchorEl);
  const [openMove, setOpenMove] = React.useState(false);
  const tooltipOpen = Boolean(anchorElTooltip);
  const [groupContextTopVault, setGroupContextTopVault] = React.useState([]);
  const [groupContextGroupName, setGroupContextGroupName] = React.useState();
  const [Blankstatus, setBlankstatus] = React.useState<any>({});
  const UserPermissionValue = React.useContext<any>(UserPermissionData)
  // ************************************* System Handler *************************************




  const [checked, setChecked] = React.useState<any>([])
  const userProfilePic = useSelector(userSelectors.selectAll);

const queries=(item:any,status:any)=>{


  if (item?.name=="Product"){
      return {project:projectId,status:status}
  }else{
      return {vault_info:item?.id,status:status}
  }
}



  const handleBoxChange = (event: React.ChangeEvent<HTMLInputElement>,id:any) => {
       if(event?.target?.checked==true){
          API.post("/circuit_diagram/architecture_submission_status/",{...queries(id,true)},{},0).then((res:any)=>{
              console.log(res.data,"datattatatat")
               setChecked([...checked,id?.id])
              ADMIN.toast.success("System/Product updated successfully.")
          }).catch((err:any)=>{
              ADMIN.toast.error("Server Error")
          })
       }
       else if (event?.target?.checked==false){
          API.post("/circuit_diagram/architecture_submission_status/",{...queries(id,false)},{},0).then((res:any)=>{
              ADMIN.toast.success("System/Product updated successfully.")
              setChecked((prev:any)=>prev?.filter((item:any)=>item!==id?.id))
          }).catch((err:any)=>{
              ADMIN.toast.error("Server Error")
          })
       }
      }
  const handleChange:any =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {

      setExpanded(newExpanded ? panel : false);
      history.push(
        `/architecturecreateinternal/${projectId}/${projectname}/${panel}/${topvoult}/${subSystem}/${0}/${collapestatus}/${1}/${1}/0/0`
      );
    };

  // ************************************Add Group Handler *************************************
  const handleCloseMove = () => {
    setOpenMove(false);
  };
  const AddGroupHandler = (e: any, item: any, type: any) => {
    e.stopPropagation();
    if (type == "system") {
      setAnchorEl(null);
      setSelectedsystemItem(SelectedsystemItem);
      groupmodal.open();
    } else {


      setSelectedsystemItem(item);
      groupmodal.open();
    }
  };

  // ************************************Group Handler *************************************
  const GroupHandler = (
    event: React.MouseEvent<HTMLButtonElement>,
    item: any
  ) => {
    history.push(
      `/architecturecreateinternal/${projectId}/${projectname}/${abbr}/${topvoult}/${item?.top_vault_list}/${item?.id
      }/${collapestatus}/${1}/${1}/${item?.name}/${item?.image_count}`
    );
  };
  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,

    data: any,
    type: any
  ) => {
    event.preventDefault();
    event.stopPropagation();
    setrightClickType(type);

    if (type == "system") {
      setSelectedsystemItem(data);
    }
    setGroupContextTopVault(data?.top_vault_list);

    setSelectedsystemItem(data);
    setAnchorEl(event?.currentTarget);

    setGroupContextGroupName(data?.name);
    if (type !== "system") {
      API.get(`/circuit_diagram/upload_na_image_group/`, {
        project: projectId,

        abbreviation: data?.abbreviation,

        categories: 3,

        group: data?.id,
      })
        .then((res: any) => {
          setBlankstatus(res?.data);
        })
        .catch((err: any) => {
          console.log("Server Error");
        });
    }
  };



  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleClosetooltip = () => {
    setAnchorElTooltip(null);
  };
  //   ************************************Get System List *************************************
  React.useEffect(() => {
    setSysytemLoader(true);
    API.get(`/circuit_diagram/arch_system/`, { project: projectId })
      .then((res: any) => {
        setSysytemLoader(false);
        let FilterDashboardStatus=res?.data?.filter((item:any)=>{return item?.dashboard_status==true})?.map((item1:any,index1:any)=>{return item1?.id})
            
        setChecked(FilterDashboardStatus)
        setSystemData(res?.data);
      })
      .catch((err: any) => {
        setSysytemLoader(false);
      });
  }, [projectId, counter, selectedType, SequenceCount]);
  // *************************************************Group List *************************************
  React.useEffect(() => {
    setGroupLoader(true);
    API.get(`/circuit_diagram/arch_groups/`, {
      project: projectId,
      abbreviation: abbr,
    })
      .then((res: any) => {
        setGroupList(res?.data);
        setGroupLoader(false);
      })
      .catch((err: any) => {
        setGroupLoader(false);
      });
  }, [projectId, abbr, counter, selectedType, SequenceCount]);


  //*************************************  User Permission         **************************************************************** */

  var UserPermissiondata = UserPermissionValue && UserPermissionValue !== undefined && UserPermissionValue?.filter((item: any, index: any) => { return item?.top_vault == "all" })[0]
  console.log(UserPermissiondata !== undefined && UserPermissiondata?.action?.includes("U"), "UserPermissiondataUserPermissiondata")

  const InfoHandler = (e: any, item: any) => {
    // e.stopPropagation();
    setAnchorElTooltip(e.currentTarget);
    history.push(
      `/architecturecreateinternal/${projectId}/${projectname}/${abbr}/${topvoult}/${item?.top_vault_list}/${item?.id
      }/${collapestatus}/${1}/${1}/${item?.name}/${item?.image_count}`
    );
    API.get("/circuit_diagram/page_validation/", {
      project: projectId,

      row: 5,

      abbreviation: abbr,

      category: 3,

      group_id: item?.id,
    })
      .then((res: any) => {
        setInfodata(res?.data);
      })
      .catch((err: any) => {
        console.log("Server Error");
      });
  };
  // const TableHandler = () => {
  //   tablemodal.open();
  // };

  const PaginationHandler = (item: any) => {
    history.push(
      `/architecturecreateinternal/${projectId}/${projectname}/${abbr}/${topvoult}/${subSystem}/${groupId}/${collapestatus}/${item}/${1}/${groupName}/${diagramsCount}`
    );
  };
  const GroupMoveHandler = (e: any) => {
    setAnchorEl(null);
    movemodal.open();
  };
  const handleCloseSystemMediaDownload = () => {
    setOpenSystemMediaDownload(false);
  };



  const handleCloseMediaConsolidatedDownload = () => {
    setOpenMediaConsolidatedDownload(false);
  };
  const MediaDownloadTree = (type: any) => {
    if (type == "systemLevel") {
      API.get(
        "/circuit_diagram/arch_download/",
        {
          project: projectId,

          categories: 3,
          abbreviation: abbr,
          download_image_count: true,
          subsystem_count: "true",
        },
        0
      )
        .then((res: any) => {
          setDownloadProductInfo(res?.data);
          setOpenSystemMediaDownload(true);
          setAnchorEl(null);
        })
        .catch((err: any) => { });
    }
    else if (type == "consolidatedGroup") {
      API.get(
        "/circuit_diagram/arch_download/",
        {
          project: projectId,
          group_id: groupId,
          categories: 3,
          abbreviation: abbr,
          download_image_count: true,
        },
        0
      )
        .then((res: any) => {
          setDownloadProductInfo(res?.data);
          setOpenMediaConsolidatedDownload(true);
          setAnchorEl(null);
        })
        .catch((err: any) => {
          console.log(err, "error console");
        });
    }


    else {
      API.get(
        "/circuit_diagram/arch_download/",
        {
          project: projectId,
          group_id: groupId,
          categories: 3,
          abbreviation: abbr,
          download_image_count: true,
        },
        0
      )
        .then((res: any) => {
          setDownloadProductInfo(res?.data);
          setOpenMediaDownload(true);

          setAnchorEl(null);
        })
        .catch((err: any) => { });
    }
  };
  const handleCloseMediaDownload = () => {
    setOpenMediaDownload(false);
  };





  const AddBlankImageHandler = (status: any) => {
    API.post(`/circuit_diagram/upload_na_image_group/`, {}, {
      project: projectId,

      abbreviation: SelectedsystemItem?.abbreviation,

      categories: 3,

      group: SelectedsystemItem?.id,
    }).then((res: any) => {
      setAnchorEl(null);
      if (status == true) {
        Swal.fire({
          html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
        <br />
        <p style="color:#007fff;">NA Images added successfully in blank place</p>   
         </div>`,
        });
      } else {
        Swal.fire({
          html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
        <br />
        <p style="color:#007fff;">Validation successfully </p>   
         </div>`,
        });
      }

      setSelectedType((prev: any) => prev + 1)
    });
  };

  const GroupDaleteHandler = () => {
    {
      swal({
        title: "Delete this Group?",
        text: " Deleting this group will permanently delete all  diagram of  this group even from recycle bin.",
        buttons: ["Cancel", "Yes"],

        icon: "error",
      }).then((confirm) => {
        if (confirm) {
          API.delete(
            `/circuit_diagram/arch_groups/${SelectedsystemItem?.id}/`,
            {},
            0
          )
            .then((res: any) => {
              setAnchorEl(null);
              setRecycleBinCounter((prev: any) => prev + 1)
              setSelectedType((prev: any) => prev + 1)
              history.push(
                `/architecturecreateinternal/${projectId}/${projectname}/${abbr}/${topvoult}/${subSystem}/${0}/${collapestatus}/${pageNumber}/${1}/0/0`
              );
            })
            .catch((err: any) => {
              console.log("Sever Error");
            });
        }
      });
    }
  };

  return (
    <div>
      <Box>
        {groupmodal?.isOpen && (
          <AddGroupNameModal
            UserPermissionValue={UserPermissionValue}
            onCloseModal={groupmodal.close}
            isOpen={groupmodal.isOpen}
            Item={SelectedsystemItem}
            setcounter={setcounter}
          />
        )}
        {/* {tablemodal.isOpen && (
          <StatisticTable
            onCloseModal={tablemodal.close}
            isOpen={tablemodal.isOpen}
          />
        )} */}

        {movemodal.isOpen && (
          <ArchMoveGroup
            onCloseModal={movemodal.close}
            isOpen={movemodal.isOpen}
            Item={SelectedsystemItem}
            setcounter={setcounter}
          />
        )}
        <Dialog
          open={openSystemMediaDownload}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseSystemMediaDownload}
          sx={{
            ".MuiPaper-root.MuiDialog-paper":
              { borderRadius: '10px' }
          }}>
          <DialogContent sx={{ width: "42rem", padding: "0rem" }}>
            <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  width: "100%",
                  textAlign: "center",
                  marginRight: "-2rem",
                }}>
                Architecture {abbr == "0" ? "Product" : `${abbr}`} Download
                maximum 125 images at a time
              </Typography>
              <CancelIcon
                titleAccess="Close"
                sx={{ cursor: "pointer", margin: "0.5rem" }}
                onClick={handleCloseSystemMediaDownload}
              />
            </Box>
            <Box sx={{ margin: "0.5rem", color: "grey" }}>
              <ArchitectureDownloadSystemTree

                downloadProductInfo={downloadProductInfo}
                groupContextAbbreviation={abbr}
                groupContextId={groupId}
                groupContextImageCategory={3}
                openMediaDownload={openMediaDownload}
                type={"productLevel"}
              />
            </Box>
          </DialogContent>
        </Dialog>

        {openMediaDownload && (
          <Dialog
            open={openMediaDownload}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseMediaDownload}
            sx={{
              ".MuiPaper-root.MuiDialog-paper":
                { borderRadius: '10px' }
            }}>
            <DialogContent sx={{ width: "42rem", padding: "0rem" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  height: "2.5rem",
                }}>
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    width: "100%",
                    textAlign: "center",
                    marginRight: "-2rem",
                  }}>
                  Group {groupContextGroupName} Download ( maximum 125 images at
                  a time)
                </Typography>
                <CancelIcon
                  titleAccess="Close"
                  sx={{ cursor: "pointer", margin: "0.5rem" }}
                  onClick={handleCloseMediaDownload}
                />
              </Box>
              <Divider />
              <Box sx={{ margin: "0.5rem", color: "grey" }}>
                <ArchitectureDownloadSystemTree
                  userpermission={UserPermissionValue}
                  downloadProductInfo={downloadProductInfo}
                  groupContextAbbreviation={abbr}
                  groupContextId={groupId}
                  groupContextImageCategory={3}
                  openMediaDownload={openMediaDownload}
                  type={"groupLevel"}
                />
              </Box>
            </DialogContent>
          </Dialog>
        )}

        {openMediaConsolidatedDownload && (
          <Dialog
            open={openMediaConsolidatedDownload}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleCloseMove}
            sx={{
              ".MuiPaper-root.MuiDialog-paper":
                { borderRadius: '10px' }
            }}>
            <DialogContent sx={{ width: "42rem", padding: "0rem" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  height: "2.5rem",
                }}>
                <Typography
                  sx={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    width: "100%",
                    textAlign: "center",
                    marginRight: "-2rem",
                  }}>
                  Architecture Consolidated Download (Excel)
                </Typography>
                <CancelIcon
                  titleAccess="Close"
                  sx={{ cursor: "pointer", margin: "0.5rem" }}
                  onClick={handleCloseMediaConsolidatedDownload}
                />
              </Box>
              <Box sx={{ margin: "0.5rem", color: "grey" }}>
                <ArchGroupDownloadConsolidated
                  countImage={
                    downloadProductInfo &&
                    downloadProductInfo?.reduce(
                      (totalItem: any, item: any) => totalItem + item?.count,
                      0
                    )
                  }
                  groupContextId={groupId}
                  groupContextTopVault={groupContextTopVault}
                  groupContextImageCategory={3}
                  groupContextAbbreviation={abbr}
                  openMediaConsolidatedDownload={openMediaConsolidatedDownload}
                  setOpenMediaConsolidatedDownload={
                    setOpenMediaConsolidatedDownload
                  }
                  groupContextGroupName={groupContextGroupName}
                />
              </Box>
            </DialogContent>
          </Dialog>
        )}
        {systemLoader ? (
          <>
            <Box sx={{ width: '100%', padding: '0 1rem', alignItems: "center" }}>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: '100%', padding: '0 1rem', alignItems: "center" }}>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: '100%', padding: '0 1rem', alignItems: "center" }}>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: '100%', padding: '0 1rem', alignItems: "center" }}>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: '100%', padding: '0 1rem', alignItems: "center" }}>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: '100%', padding: '0 1rem', alignItems: "center" }}>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: '100%', padding: '0 1rem', alignItems: "center" }}>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: '100%', padding: '0 1rem', alignItems: "center" }}>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: '100%', padding: '0 1rem', alignItems: "center" }}>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: '100%', padding: '0 1rem', alignItems: "center" }}>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: '100%', padding: '0 1rem', alignItems: "center" }}>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: '100%', padding: '0 1rem', alignItems: "center" }}>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
            </Box>
            <Box sx={{ width: '100%', padding: '0 1rem', alignItems: "center" }}>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
            </Box>
          </>
        ) : (
          <>
            {/* <Box onClick={() => TableHandler()} sx={{ cursor: "pointer" }}>
              <EqualizerIcon />
            </Box> */}
            <Box sx={{ minWidth: "30rem", position: "absolute" }}>
              <Menu
                id="basic-menu"
                anchorEl={anchorElTooltip}
                open={tooltipOpen && tooltipOpen}
                onClose={handleClosetooltip}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                sx={{
                  minWidth: "30rem",
                  ".MuiList-root.MuiMenu-list": {
                    padding: 0,
                    minWidth: "30rem",
                    borderRadius: '10px',
                  },
                }}>
                <>
                  <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
                    <Typography
                      sx={{
                        fontSize: "1rem",
                        fontWeight: "600",
                        width: "100%",
                        textAlign: "center",
                        marginRight: "-2rem",
                      }}
                    >
                      Page Not Validated
                    </Typography>
                    {/* <CancelIcon
                      titleAccess="Close"
                      sx={{ cursor: "pointer", margin: "0.5rem" }}
                      onClick={handleClose}
                    /> */}
                  </Box>
                  <Divider
                    sx={{
                      borderColor: "primary.light",
                      margin: "0 1rem",
                      marginBottom: "1rem",
                    }}
                  />
                  <div style={{ padding: "0rem 1rem", borderRadius: '5px', display: "flex", gap: "1rem", margin: "0.5rem", }}>
                    Pages that are not validated :
                    {Infodata &&
                      Infodata[0]?.page_list?.map((item: any) => {
                        return (
                          <>
                            <Typography sx={{ textDecoration: "underline", cursor: "pointer", fontSize: '1rem', }} onClick={() => PaginationHandler(item)}>
                              {item}
                            </Typography>
                            {/* <Chip
                                size="small"
                                sx={{
                                  "&.MuiButtonBase-root.MuiChip-root": {
                                    backgroundColor:
                                      item == pageNumber
                                        ? "#007fff"
                                        : "rgba(0, 0, 0, 0.08)",

                                    color:
                                      item == pageNumber ? "white" : "#007fff",
                                  },
                                }}
                                onClick={() => PaginationHandler(item)}
                                label={item}></Chip> */}
                          </>
                        );
                      })}
                  </div>

                  <div>
                    {Infodata &&
                      Infodata?.page_list?.map((item: any, inde: any) => {
                        return (
                          <>
                            <Typography sx={{ fontSize: '1rem', borderBottom: "1px solid" }}>
                              {item}
                            </Typography>
                          </>
                        );
                      })}
                  </div>
                  <MenuItem>
                    <div></div>
                  </MenuItem>
                </>
              </Menu>
            </Box>

            <Box sx={{ minWidth: "19rem", position: "absolute" }}>
              <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open && open}
                onClose={handleClose}
                MenuListProps={{
                  "aria-labelledby": "basic-button",
                }}
                sx={{
                  minWidth: "19rem",
                  ".MuiList-root.MuiMenu-list": {
                    padding: 0,
                    minWidth: "19rem",
                  },
                }}>
                {rightClickType == "group" &&

                  (
                    <>
                      {SelectedsystemItem?.image_count == 0 && (
                        UserPermissiondata !== undefined && UserPermissiondata?.action?.includes("D") &&
                        <MenuItem sx={{ fontSize: '1rem' }} onClick={() => GroupDaleteHandler()}>
                          Delete Group
                        </MenuItem>
                      )}

                      {
                        SelectedsystemItem?.image_count !== 0 && <>

                          {
                            UserPermissiondata !== undefined && UserPermissiondata?.action?.includes("R") &&
                            <MenuItem sx={{ fontSize: '1rem' }} onClick={() => MediaDownloadTree("groupLevel")}>
                              Architecture Download
                            </MenuItem>
                          }
                        </>
                      }
                      {
                        SelectedsystemItem?.image_count !== 0 && <>
                          {
                            UserPermissiondata !== undefined && UserPermissiondata?.action?.includes("U") &&

                            <MenuItem sx={{ fontSize: '1rem' }} onClick={(e: any) => GroupMoveHandler(e)}>
                              Move Diagrams
                            </MenuItem>
                          }
                        </>}

                      {
                        SelectedsystemItem?.image_count !== 0 && <>

                          {UserPermissiondata !== undefined && UserPermissiondata?.action?.includes("R") &&

                            <MenuItem sx={{ fontSize: '1rem' }}
                              onClick={() => MediaDownloadTree("consolidatedGroup")}>
                              Consolidated Excel Download
                            </MenuItem>
                          }


                        </>}


                      {SelectedsystemItem?.image_count !== 0 && Blankstatus?.is_NA_show && userProfilePic && userProfilePic[0]?.is_superuser && (
                        <MenuItem sx={{ fontSize: '1rem' }} onClick={() => AddBlankImageHandler(Blankstatus?.is_NA_show)}>
                          Fill blank as NA and Validate
                        </MenuItem>
                      )}
                      {SelectedsystemItem?.image_count !== 0 && Blankstatus?.is_NA_show == false && userProfilePic && userProfilePic[0]?.is_superuser && SelectedsystemItem?.colour_status != 3 && (
                        <MenuItem sx={{ fontSize: '1rem' }} onClick={() => AddBlankImageHandler(Blankstatus?.is_NA_show)}>
                          Validate diagrams
                        </MenuItem>
                      )}
                    </>
                  )}
                {rightClickType == "system" && (
                  <>
                    {
                      UserPermissiondata !== undefined && UserPermissiondata?.action?.includes("C") &&

                      <MenuItem sx={{ fontSize: '1rem' }}
                        onClick={(e: any) => AddGroupHandler(e, {}, "system")}>
                        Add Group
                      </MenuItem>
                    }


                    {
                      SelectedsystemItem?.image_count !== 0 && UserPermissiondata !== undefined && UserPermissiondata?.action?.includes("R") &&

                      <MenuItem sx={{ fontSize: '1rem' }} onClick={() => MediaDownloadTree("systemLevel")}>
                        Architecture Download
                      </MenuItem>
                    }
                  </>
                )}
              </Menu>
            </Box>
            {systemdata &&
              systemdata?.map((item: any, index: any) => {
                return (
                  <>
                    <Accordion
                      sx={{ border: "none" }}
                      expanded={item?.abbreviation == abbr}
                      onChange={ (checked?.includes(item?.id)==false ) && handleChange(item?.abbreviation)}>
                      <AccordionSummary
                        expandIcon={item?.abbreviation == abbr ? <img src={openFolder} style={{ height: '1.2rem' }} /> : <FolderIcon sx={{ fontSize: "1.5rem", color: "primary.main" }} />}
                        sx={{ minHeight: "24px", padding: "0 0.5rem" }}
                        aria-controls="panel1d-content"
                        id="panel1d-header">
                        <Box sx={{ width: "100%", display: "flex", alignItems: "center", fontSize: "1rem", justifyContent: "space-between", padding: '0.3rem 0', marginLeft: '0.5rem' }}>
                          <Box
                            sx={{
                              display: "flex", columnGap: "0.2rem",                              
                              fontSize: "0.87rem",
                              lineHeight: 1.1,
                              textTransform: "capitalize",
                              color:
                                item?.group_status == 1
                                  ? "red"
                                  : item?.group_status == 2
                                    ? "orange"
                                    : "green",
                            }}
                            onContextMenu={(event: any) => (checked?.includes(item?.id)==false ) &&
                              handleClick(event, item, "system")
                            }>
                            {item?.name.toLowerCase()}&nbsp;                            
                              ({item?.group_count == 1 || item?.group_count == 0 ? item?.group_count + " Group" : item?.group_count + " Groups"}, &nbsp;
                              {item?.image_count == 1 || item?.image_count == 0 ? item?.image_count + " Diagram" : item?.image_count + " Diagrams"})
                           
                          </Box>
                          <Box sx={{display:"flex"}}>
{
   item?.group_count == 0 && <>
   
   

                          { userProfilePic && userProfilePic[0]?.is_superuser && 
                                        <Checkbox
                                            checked={checked?.includes(item?.id)}
                                            onChange={(e:any)=>handleBoxChange(e,item)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            size='small'
                                            title={`${' Dashboard Status - No Architecture to be generated'}`}
                                            
                                            sx={{ color: "#007fff" }}
                                        />
                    }


</>
}
                          {
                            UserPermissiondata !== undefined && UserPermissiondata?.action?.includes("C") &&

                (checked?.includes(item?.id)==false ) &&

                            <Typography
                              sx={{ display: "flex", alignItems: "center", fontSize: "0.87rem" }}
                              onClick={(e: any) => AddGroupHandler(e, item, "add")}>
                              <AddIcon sx={{ fontSize: "1.5rem" }} />
                              Group
                            </Typography>
                          }
                          </Box>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        {GroupLoader ? (
                          <Box
                            sx={{ marginLeft: "1.5rem", }}>
                            <Box sx={{ width: '100%', alignItems: "center" }}>
                              <Skeleton />
                              <Skeleton animation="wave" />
                              <Skeleton animation={false} />
                            </Box>
                            <Box sx={{ width: '100%', alignItems: "center" }}>
                              <Skeleton />
                              <Skeleton animation="wave" />
                              <Skeleton animation={false} />
                            </Box>
                            <Box sx={{ width: '100%', alignItems: "center" }}>
                              <Skeleton />
                              <Skeleton animation="wave" />
                              <Skeleton animation={false} />
                            </Box>
                            <Box sx={{ width: '100%', alignItems: "center" }}>
                              <Skeleton />
                              <Skeleton animation="wave" />
                              <Skeleton animation={false} />
                            </Box>
                            <Box sx={{ width: '100%', alignItems: "center" }}>
                              <Skeleton />
                              <Skeleton animation="wave" />
                              <Skeleton animation={false} />
                            </Box>
                          </Box>
                        ) : (
                          <>
                            {GroupList &&
                              GroupList?.map((groupItem: any, indx: any) => {
                                return (
                                  <>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                      }}>
                                      <Box
                                        sx={{
                                          width: '100%',
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "space-between",
                                          position: "relative",
                                          marginLeft: "2rem",
                                          paddingRight: '0.2rem',
                                          marginBottom: "0.2rem",
                                          background:
                                            groupItem?.id == groupId
                                              ? "#bbdefb"
                                              : "white",
                                          cursor: "pointer",
                                        }}
                                        onClick={(e: any) =>
                                          GroupHandler(e, groupItem)
                                        }>
                                        <Typography
                                          sx={{
                                            fontSize: "0.87rem",
                                            cursor: "pointer",
                                            padding: '0.3rem 0.5rem',
                                            textTransform: "capitalize",
                                            lineHeight: 1.1,
                                            color:
                                              groupItem?.colour_status == 1
                                                ? "red"
                                                : groupItem?.colour_status == 2
                                                  ? "orange"
                                                  : "green",
                                          }}
                                          onContextMenu={(event: any) =>
                                            handleClick(
                                              event,
                                              groupItem,
                                              "group"
                                            )
                                          }>
                                          {groupItem?.name.toLowerCase()} ({groupItem?.image_count == 1 || groupItem?.image_count == 0 ? groupItem?.image_count + " Diagram" : groupItem?.image_count + " Diagrams"})

                                        </Typography>

                                        {(groupItem?.image_count !== 0) && (groupItem?.colour_status == 1 ||
                                          groupItem?.colour_status == 2) && (
                                            <InfoIcon
                                              sx={{ cursor: "pointer" }}
                                              onClick={(e: any) =>
                                                InfoHandler(e, groupItem)
                                              }
                                            />
                                          )}

                                      </Box>

                                    </Box>
                                  </>
                                );
                              })}
                          </>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  </>
                );
              })}
          </>
        )}
      </Box>
    </div>
  );
}
