
import { useEffect, useState } from "react";
import { CALCULATOR_DATABASES_MAP } from "../../../../../../Configuration/Costing/calculator.constant";
import { ID } from "../../../../../../utlis/commonInterfaces";
import { useAssumptioCard } from "../../../../useAssumptioCard";


// ----------------------------------------------------------------------------------

const useDatabaseSelectionModal = () => {
    const [isOpen, setIsOpen] = useState( false );
    const [revisionId, setRevisionId] = useState<ID>( 0 );
    const [database, setDatabase] = useState<any>( );
    const [CalData,setCaldata]=useState<any>(null)
    const [inputId, setInputId] = useState<ID>( 0 );

    const { 1: assumption } = useAssumptioCard();

    const getRevisionId = () => {
        if ( !assumption ) return 0;
        switch ( database ) {
            case CALCULATOR_DATABASES_MAP.LABOUR:
                return assumption.labour_revision;
            case CALCULATOR_DATABASES_MAP.MACHINE:
                return assumption.machine_revision;
            case CALCULATOR_DATABASES_MAP.MATERIAL:
                return assumption.material_revision;
                case CALCULATOR_DATABASES_MAP.TOOL:
                    return assumption.tools_revision;
                    case CALCULATOR_DATABASES_MAP.STROKE_RATE:
                        return assumption.operations_revision;
                     case CALCULATOR_DATABASES_MAP.PURCHASEING_ITEM :
                        return assumption.bop_revision
                     case CALCULATOR_DATABASES_MAP.CURRENCY :
                        return assumption.currency_revision
            default:
                return 0;
        }
    };

    const handleOpen = ( inputId: ID|any ) => ( database: number|any ,calIds?:any) => {
        // console.log(calIds,"calIdscalIds")
        setCaldata(calIds)
        setInputId( inputId );
        setDatabase( database );
        setIsOpen( true );
    };

    const handleClose = () => {
        setInputId( 0 );
        setDatabase( CALCULATOR_DATABASES_MAP.PURCHASEING_ITEM );
        setIsOpen( false );
    };

    // TODO: optimize asumption card fetch
    useEffect( () => {
        // console.log(getRevisionId(),"getRevisionId()")
        setRevisionId( getRevisionId() );
    }, [assumption, database] );

    return Object.freeze( {
        open: handleOpen,
        close: handleClose,
        isOpen,
        inputId,
        database,
        revisionId,
        assumption,
        CalData
    } );
};

// ----------------------------------------------------------------------------------

export { useDatabaseSelectionModal };