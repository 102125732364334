import React, { useEffect } from 'react';
import { Button, Skeleton, TableCell, TableRow } from '@mui/material';
import { styled, alpha } from '@mui/material/styles';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import LeaderboardIcon from '@mui/icons-material/Leaderboard';
import { useHistory } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { AxiosError, AxiosResponse } from 'axios';
import { API } from '../../api-services';
import { useRouteParams } from '../../ui-reusable-component/useRouteParams';
import InfoIcon from "@mui/icons-material/Info";
import InsightsIcon from '@mui/icons-material/Insights';
import FlagIcon from '@mui/icons-material/Flag';
import { set } from 'lodash';

interface Params {
    projectId: any;
    projectName: any;
    expandTable: any;
    initialTopVault: any;
    topVaultIds: any;
  }

interface IBOMCreateSystemTableRowProps {
  item: any;
  index: any;
  setHoveredIndex: any;
  internalData: any;
  indexInternal: any;
  hoveredIndex: any;
  handleTooltipOpen: any;
  openStatusContent: any;
  setContentText: any;
  setIconText: any;
  setStatisticsData: any;
  setSubsystemName: any;
  setOpenStatisticsModel: any;
  totalBOMCalculationDataLoader?: any;
  weightType?:any;
  setFlagData?:any;
  handleFlagOpen?:any;
}

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));

const BOMCreateSystemTableRow: React.FC<IBOMCreateSystemTableRowProps> = (props) => {
  const { item, index, setHoveredIndex, internalData, indexInternal, hoveredIndex, handleTooltipOpen, openStatusContent, setContentText, setIconText, setStatisticsData, setSubsystemName, setOpenStatisticsModel, totalBOMCalculationDataLoader, weightType, setFlagData, handleFlagOpen } = props;
  const { projectId,projectName,initialTopVault,topVaultIds,expandTable } = useRouteParams<Params>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [statisticsDataLoader, setStatisticsDataLoader] = React.useState(false);
  const [apiFlagLoading,setApiFlagLoading] = React.useState(false);
  const openDownload = Boolean(anchorEl);
  const [topVaultIdsArray, setTopVaultIdsArray] = React.useState<any>(String(topVaultIds)?.includes(',') ? topVaultIds?.split(',') : [topVaultIds]);
  // String(topVaultIds)?.includes(",") &&
  //                         topVaultIds?.split(",")?.length <= 4

  const history = useHistory();
  // console.log(topVaultIds.isArray(), "aaaaaaaaaaaaaaaaaaaaaaaaa");
  React.useEffect(() => {
    setTopVaultIdsArray( String(topVaultIds)?.includes(',') ? topVaultIds.split(',') : [topVaultIds]);
  }, [topVaultIds]);

  console.log(topVaultIdsArray, "topVaultIdsArray");

  let dataInternalFilter: any = internalData?.data_by_vehicle?.filter((itemFilterInternal: any) => {
    return itemFilterInternal?.top_vault_id == item?.top_vault_id
  })?.[0]

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleRedirect = (module: any, id: any) => {
    if (module === "w") {
      API.get(
        "/customer/check_consolidate_downoad/",
        {
          vault: id,
          with_image: true,
          consolidate_type: "BOM",
        },
        0
      ).then((res: any) => {

        if (res.data.status === "Failed") {
          setIconText("failed");
          setContentText(res.data.message);
          openStatusContent.open();
        } else if (res.data.status === "First Download") {
          setIconText("firstdownload");
          setContentText(res.data.message);
          handleClose();
          openStatusContent.open();
          API.get(
            "customer/consolidated_report/",
            {
              vault: id,
              with_image: true,
              consolidate_type: "BOM",
            },
            0
          ).then((res: any) => {
            handleClose();
            window.open(res?.data, "_self");
          });
        } else if (res.data.status === "In Progress") {
          setIconText("reportProgress");
          setContentText(res.data.message);
          handleClose();
          openStatusContent.open();
        } else if (res.data.status === "Completed") {
          handleClose();
          window.open(res?.data?.url, "_self");
        }
      });
    }
    else if (module === "w/o") {
      API.get(
        "/customer/check_consolidate_downoad/",
        {
          vault: id,
          with_image: false,
          consolidate_type: "BOM",
        },
        0
      ).then((res: any) => {
        if (res.data.status === "Failed") {
          setIconText("failed");
          setContentText(res.data.message);
          handleClose();
          openStatusContent.open();
        } else if (res.data.status === "First Download") {
          setIconText("firstdownload");
          setContentText(res.data.message);
          handleClose();
          openStatusContent.open();
          API.get(
            "customer/consolidated_report/",
            {
              vault: id,
              with_image: false,
              consolidate_type: "BOM",
            },
            0
          ).then((res: any) => {
            handleClose();
            window.open(res?.data, "_self");
          });
        } else if (res.data.status === "In Progress") {
          setIconText("reportProgress");
          setContentText(res.data.message);
          handleClose();
          openStatusContent.open();
        } else if (res.data.status === "Completed") {
          handleClose();
          window.open(res?.data?.url, "_self");
        }
      });
    }
  };

  const handleStaticticsOpen = (subSystem: any, type: any) => {
    setStatisticsDataLoader(true);
    API.get(`/cost/costing_create_ui/`, { start_top_vault:initialTopVault, get_bom_graph_data:projectId, abbreviation: type }, 0)
      .then((res: AxiosResponse) => {
        setStatisticsData(res.data);
        setSubsystemName(subSystem);
        setOpenStatisticsModel(true);
        setStatisticsDataLoader(false);
      })
      .catch((err: AxiosError) => {
        console.log(err, "error");
      });
  }

  const redirectToBOMInnerPage = () =>{

    
        sessionStorage.setItem(
          "BOMCreateNode",
          JSON.stringify([dataInternalFilter?.top_vault_id?.toString(),dataInternalFilter?.id])
        );
        window.open(
          `/#/bomentry/${projectId}/${projectName}/${dataInternalFilter?.top_vault_id}/${dataInternalFilter?.id}/${internalData?.abbreviation}/0/false/false`
        )
  }

  const handleFlag = () => {
    setApiFlagLoading(true);
    API.get('/xcpep/bom_hirearchy_function/',{vault:dataInternalFilter?.id,subsystem_cost_flag:true},0).then((res:AxiosResponse)=>{
        setFlagData(res.data);
        handleFlagOpen();
        setApiFlagLoading(false);
    }).catch((err:AxiosError)=>console.log(err));
  }
  
  return (
    <TableRow key={internalData?.name} sx={hoveredIndex == indexInternal ? { backgroundColor: '#f7e1f4', height: '3rem', zIndex: 1 } :  dataInternalFilter?.validation_status == "WIP"
    ? { backgroundColor: "#fffb90", height: "3rem" }
    : dataInternalFilter?.id == "-"
    ? { backgroundColor: "white", height: "3rem" }
    : { backgroundColor: "#c4efb3", height: "3rem" }}

      onMouseEnter={() => setHoveredIndex(indexInternal)}
      onMouseLeave={() => setHoveredIndex(null)}>
      {index == 0 && <TableCell sx={hoveredIndex == indexInternal ? { backgroundColor: '#f7e1f4', padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', fontWeight: 'bold', position: 'sticky', left: '0', zIndex: 1 } : { padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', fontWeight: 'bold', backgroundColor: 'white', position: 'sticky', left: '0', zIndex: 1 }} title={internalData?.name}>
        <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', columnGap: '0.5rem' }}>
          <span style={dataInternalFilter?.scenario == null ? dataInternalFilter?.is_costing==true ? { cursor: 'pointer', width:topVaultIdsArray.length > 3 ? "8rem" : "12rem", whiteSpace: "nowrap", overflow: "hidden", textOverflow:"ellipsis" } : {width:topVaultIdsArray.length > 3 ? "8rem" : "12rem", whiteSpace: "nowrap", overflow: "hidden", textOverflow:"ellipsis"} : { cursor: 'pointer', width:topVaultIdsArray.length > 3 ? "8rem" : "12rem", whiteSpace: "nowrap", overflow: "hidden", textOverflow:"ellipsis" }}>{internalData?.abbreviation} ({internalData?.name}) </span>
          <InsightsIcon sx={{ color: '#007fff', cursor:'pointer',p:0, m:0 }} titleAccess='Subsystem Analyse' onClick={()=>window.open(`/#/analysis/weight/${projectId}/${topVaultIds}`)}/>
          <LoadingButton loading={statisticsDataLoader}
            sx={{
              p: 0, minWidth: '1rem',
              '.MuiCircularProgress-root': {
                color: 'primary.main',
              },
            }}>
            <LeaderboardIcon titleAccess="Statistics" onClick={() => handleStaticticsOpen(internalData?.name, internalData?.abbreviation)} />
          </LoadingButton>
        </span>
      </TableCell>}
      {dataInternalFilter?.id != '-' && <TableCell title={internalData?.name} onClick={redirectToBOMInnerPage} sx={ { padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', cursor: 'pointer', textAlign: 'center' }}>{totalBOMCalculationDataLoader ? <Skeleton /> : dataInternalFilter?.count == '-' ? '-' : dataInternalFilter?.count}</TableCell>}
      {dataInternalFilter?.id != '-' && <TableCell title={internalData?.name} onClick={redirectToBOMInnerPage} sx={ { padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', cursor: 'pointer', textAlign: 'right' }}>{totalBOMCalculationDataLoader ? <Skeleton /> : dataInternalFilter?.weight == '-' ? '-' : weightType == 'Kg' ? (dataInternalFilter?.weight/1000)?.toFixed(2) : (dataInternalFilter?.weight)?.toFixed(2)}</TableCell>}
      {String(topVaultIds)?.includes(',') && topVaultIds?.split(',')?.length <=4 && (dataInternalFilter?.id != '-' && <TableCell title={internalData?.name} onClick={redirectToBOMInnerPage} sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', cursor: 'pointer', textAlign: 'right' }}>{totalBOMCalculationDataLoader ? <Skeleton /> : ((dataInternalFilter?.weight / item?.total_weight) * 100).toFixed(2) == 'NaN' ? '-' : `${((dataInternalFilter?.weight / item?.total_weight) * 100).toFixed(2)}%`}</TableCell>)}
      <TableCell colSpan={String(topVaultIds)?.includes(',') && topVaultIds?.split(',')?.length <=4 ? dataInternalFilter?.id != '-' ? 1 : 4 : dataInternalFilter?.id != '-' ? 1 : 3} sx={dataInternalFilter?.id != '-' ? { padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', textAlign: 'right' } : { padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', textAlign: 'center' }}>{dataInternalFilter?.id == '-' ? 'Not Available' : <span style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', columnGap: '0.5rem' }}>
       {dataInternalFilter?.cost_flag && <LoadingButton sx={{p:0, minWidth:'0.5rem'}} onClick={handleFlag} loading={apiFlagLoading}>
          <FlagIcon sx={{ cursor: 'pointer', color:'red' }} />
        </LoadingButton>}
        <Button
          id="demo-customized-button"
          aria-controls={openDownload ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openDownload ? 'true' : undefined}
          disableElevation
          onClick={handleClick}
          style={{ padding: '0rem', minWidth: '1rem' }}
          title='Download'
        >
          <CloudDownloadIcon sx={{ cursor: 'pointer' }} />
        </Button>
        <StyledMenu
          id="demo-customized-menu"
          MenuListProps={{
            'aria-labelledby': 'demo-customized-button',
          }}          
          anchorEl={anchorEl}
          open={openDownload}
          onClose={handleClose}
        >
          <MenuItem
            sx={{
              fontSize: "1rem",
              color: "primary.main",
              padding: "0.2rem 0.5rem",
              // borderBottom:'1px solid #8080801c !important'
            }}
            onClick={() =>
              handleRedirect(
                "w",
                dataInternalFilter?.id
              )
            }
          >
            Consolidated with images
          </MenuItem>
          <MenuItem
            sx={{
              fontSize: "1rem",
              color: "primary.main",
              padding: "0.2rem 0.5rem",
            }}
            onClick={() =>
              handleRedirect(
                "w/o",
                dataInternalFilter?.id
              )
            }
          >
            Consolidated w/o images
          </MenuItem>
        </StyledMenu>
        <InfoIcon sx={{ cursor: 'pointer' }} onClick={() => handleTooltipOpen(dataInternalFilter?.part_info)} />
      </span>}
      </TableCell>
    </TableRow>)
}

export default BOMCreateSystemTableRow;