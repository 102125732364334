import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CancelIcon from '@mui/icons-material/Cancel';

import { Divider, Tooltip } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExportContainer,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { API } from "../../api-services";
import useWindowDimensions from "../../ui-reusable-component/useWindowDimensions";
interface OEMInfomodalProps {
  onCloseModal: any;
  isOpen: any;
  rowIds: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40vw",
  bgcolor: "background.paper",
  borderRadius:'10px',
  p: 1,
};
const csvOptions: GridCsvExportOptions = {
  fileName: "Table In CSV",
  delimiter: ",",
  utf8WithBom: true,
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer style={{ height: "1.5rem", marginBottom: "0.5rem" }}>
    <span style={{ position: "absolute", right: "0rem" }}>
      {/* <GridToolbarColumnsButton /> */}
      {/* <GridToolbarDensitySelector /> */}
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);
const OEMInfomodal = (props: OEMInfomodalProps) => {
  const { onCloseModal, isOpen, rowIds } = props;
  const [Infolist, setInfolist] = useState<any>([]);
  const [loader,setLoader]=useState<any>(false)
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    setLoader(true)
    API.get("/api/db/check_variant/", { id: rowIds, type: "oem" })
      .then((res: any) => {
        setInfolist(res?.data);
        setLoader(false)
      })
      .catch((err: any) => {
        setLoader(false)
        console.log("Server errort");
      });
  }, [rowIds]);

  var Row: any =
    Infolist &&
    Infolist?.map((item: any, index: any) => ({ ...item, id: index + 1 }));

  var Columns: any = [
    { field: "id", headerName: "Name", hide: true },
    {
      field: "project",
      headerName: "Name",
      width: width > 1400 ? 200 : 150,      
    },

    {
      field: "top_vault",
      headerName: "Product",
      width: width > 1400 ? 400 : 300,      
      renderCell: (cellValues: any) => {
        return (
          <>
            <Tooltip title={cellValues?.row?.top_vault.toString(", ")}>
              <p>{cellValues?.row?.top_vault.toString(", ")}</p>
            </Tooltip>
          </>
        );
      },
    },
  ];

  return (
    <div>
      <Modal open={isOpen} onClose={onCloseModal}>
        <Box sx={style}>
        <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: '-2rem'
              }}
            >
              Info 
            </Typography>
            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ height: 300, width: "100%" }}>
            {Infolist && (
              <DataGrid
                rows={Row && Row}
                columns={Columns}
                loading={loader}
                density="compact"
                autoHeight={true}
                components={{
                  Toolbar: () => {
                    return CustomToolbar();
                  },
                }}
                hideFooterPagination={true}
              />
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default OEMInfomodal;
