import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import * as SpriteSpin from "spritespin";
import { useRef } from "react";
import ClearIcon from "@mui/icons-material/Clear";
import { isUndefined } from "lodash";
import Backdrop from "@mui/material/Backdrop";
import Fade from "@mui/material/Fade";
import styles from "./IdeagenThreeSixtyCreateModal.module.scss";


import useWindowDimensions from "../../../../../ui-reusable-component/useWindowDimensions";
import { useBaseModal } from "../../../../SearchPage/useBaseModal";
import TagTransitionablePortal from "../../../../../ViewModule/BomView/TagTransitionablePortal";
import { API } from "../../../../../api-services";
import { Menu, MenuItem, Popover } from "@mui/material";
import { useContextMenu } from "../../../../../CustomHook/useContextMenu";
import { zIndex } from "material-ui/styles";
import { useRouteParams } from "../../../../../ui-reusable-component/useRouteParams";
import IdeagenTagDataModal from "./IdeagenTagDataModal";



export interface BomThreeSixtyModalProps {
  isOpen: any;
  onCloseModal: any;
  ThreeSixityImages?: any;
  query: any;
  ImageType: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
};

const IdeagenThreeSixtyCreateModal = (props: BomThreeSixtyModalProps) => {
  const { isOpen, onCloseModal, query, ThreeSixityImages, ImageType } = props;
  const ref = useRef<any>();
  const {topVault,vault,image_id,projectId,projectName} = useRouteParams<any>();
  const [count, setCount] = useState<number>(0);
  const [FilterImages, setFilterImages] = useState<any>();
  const [currentFrame, setCurrentFrame] = useState(0);
  const [vistedId, setVistedId] = useState<any>(0);
  const [edit, setEdit] = useState(false);
  const [loadImage, setLoadImage] = useState<boolean>(false);
  const [dragging, setDragging] = useState<boolean>(false);
  const [isTagging, setIsTagging] = useState(false);
  const [load, setLoad] = useState(false);
  const { width } = useWindowDimensions();
  const [imageUrl, setImageUrl] = useState<any>("");
  const [canavas, setCanavas] = useState<HTMLCanvasElement | null>(null);
  const [tags, setTags] = useState<any>([]);
  const tagDataModal:any = useBaseModal();
  const [tagData, setTagData] = useState<any>();
  const [counter,setCounter]=useState<any>(0)
  const [editTag, setEditTag] = useState(false);
  const [NavInfoData,setNavInfoData]=useState<any>(null)
  const navModal=useBaseModal()
  let comparedata="360-View"
  const infoMenu = useContextMenu();

  const [Positionmenu,setPositionmenu]=useState<any>({top:0,left:0})
  const [anchorEl, setAnchorEl] = React.useState<any>(false)


console.log(ThreeSixityImages && ThreeSixityImages?.key,"ImageTypeImageType")


  const open = Boolean(anchorEl);
  const id = open ? 'threesixsty' : undefined;
  // ************************************Threesixty Images*****************************************
  const ThreesixtyImagesData = () => {
    let entries = ThreeSixityImages && ThreeSixityImages?.value[0];

 

    return entries;
  };


  console.log(ThreeSixityImages,"Cdckbdckbdhkcdkci",ThreesixtyImagesData())
  // ********************************* Paramerter Id get Handler***************************************
  const ThreesixtyParameter = () => {

    let entries = ThreeSixityImages && ThreeSixityImages?.key;
   
  

    return entries;
  };

  // ***************************Create ThreeSixty Images****************************************************

  useEffect(() => {
    if (!isOpen) return;
    SpriteSpin.create({
      target: "#" + ref.current?.id,
      source: ThreesixtyImagesData(),
   
      width: width > 600 ? 620 : 350,
      height: width > 600 ? 400 : 300,
      frames: 30,
      framesX: 6,
      framesY: 10,
      frameTime: 30,
      sense: -1,
      senseLane: 10,
      reverse: true,
      animate: false,
      fillMode: "scale-down",
      renderMode: "canvas",
      plugins: [
        "360",
        "drag",
        "wheel",
        // "progress",
        "swipe",
      ],

      onFrame: function (e, data) {
       
        setCurrentFrame(Number(data.frame));
      },
        onFrameChanged: function (e, data) {
          // setCreateTag(false);
          // infoMenu.close();
        },
      onComplete: function (e, data) {
        setLoad(true);
      },
      onDestroy: function (e, data) {
        setLoad(false);
        setIsTagging(false);
      },
    });

console.log(ThreesixtyImagesData, "ThreesixtyImagesData");

    return () => {
      SpriteSpin.destroy("#" + ref.current?.id);
      // dispatch(resetthreeSixty());
      setVistedId(0);
      // infoModal.close();
    };
  }, [isOpen, ThreeSixityImages, width]);

  console.log(width,"cdnbdkbjvkbjdvkjdvbj")
  //******************Tags API Call****************************************************

  useEffect(() => {
    API.get("/xcpep/360_image_view/", {
      ...query,
      parameter: ThreesixtyParameter(),
    }).then((res: any) => {
      setTags(res.data);
    });
  }, [query,counter]);

  // Tags Click Handler

  const tagClick = async (canvas: any, e: any) => {
    setAnchorEl(false)
    const rect = canvas.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    var mydivs = document.getElementById("tooltip") as HTMLElement;

    for (let i = 0; i < tags.length; i++) {
      const diff =
        Math.abs(tags[i].x_value - x) + Math.abs(tags[i].y_value - y);
      if (diff < 10 && tags[i].frame_no === currentFrame) {
        if (tags[i].tag_type === 1) {
          window.open(tags[i].text, "_blank");
          setVistedId(tags[i].id);
          mydivs.style.left = "-1000000px";
        } else {
          mydivs.style.left = "-1000000px";
          setTagData(tags[i]);

        
            // setEdit(true);
          setVistedId(tags[i].id);
          tagDataModal.open();
        //   editTag ? tagDataModal.open() : infoModal.open();
        }
      }
    }
  };


  //  Tags Mouse Hover Handler
  const handleToolTip = (canvas: any, e: any) => {
    if (!load) return;
    if (!canvas) return;
    const rect = canvas.getBoundingClientRect();

    console.log(rect,"cgiucsychscschch")
    var mydivs = document.getElementById("tooltip") as HTMLElement;
    var pTag = document.getElementById("tooltip-text") as HTMLElement;
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    var hit = false;

    for (let i = 0; i < tags.length; i++) {
      const diff =
        Math.abs(tags[i].x_value - x) + Math.abs(tags[i].y_value - y);


        console.log(diff,"vhdivuduvdvdjvhdjvhjdvhjdvhjdv",tags[i].frame_no)
      if (diff < 10 && tags[i].frame_no === currentFrame) {
        let dot = tags[i];
        hit = true;
        console.log(dot,"dbiucdhbchjdbbhddhdhvbj")
        mydivs.style.left = x + 700 + "px";
        mydivs.style.top = y + 100 + "px";
        mydivs.style.position="absolute"
        mydivs.style.zIndex = "2222";
        mydivs.style.width = "200px";
        mydivs.style.textAlign="center";
        mydivs.style.borderRadius="5px";
        mydivs.style.background="white";
        mydivs.style.height="auto";
      
       
        pTag.innerHTML = dot.text;
        setImageUrl(dot.image_url);
        canvas.style.cursor = "pointer";
      }
    }
    if (!hit) {
       pTag.innerHTML = "";
      mydivs.style.left = "-1000000px";
       canvas.style.cursor = "grab";
       setImageUrl("");
      setLoadImage(false);
    }
  };
  useEffect(() => {
    if (!load) return;
    const canvas = document.querySelector("canvas") as HTMLCanvasElement;
    setCanavas(canvas);
    console.log(load,"vdvuvjvbfvbfbj")
    if (canvas) {
      canvas.onmousedown = function (e) {
        setDragging(true);
        // canvas.style.cursor = "grabbing";
      };

      canvas.onmouseup = function () {
        setDragging(false);
        canvas.style.cursor = "grab";
      };
    }
  }, [load]);

  //  Creating Hotspot point ion images


  function getCursorPosition(canvas: any, event: any) {

    
    // infoMenu.close();
    // if (!isTagging) return;
     event.preventDefault();
    event.stopPropagation();
    const rect = canvas.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;

      // console.log(event.clientY,event.clientX,"clientcxxxxx")
    setAnchorEl(true)
    setPositionmenu({
        top: y,
        left:  x ,
      });
 
   
      const curentPlace = {
        top_vault: topVault,
        vault: vault,
        parameter: ThreesixtyParameter(),
        y_value: y,
        x_value: x,
        frame_no: currentFrame,
        tag_type: 1,
      
        image_url: "",
      };

      setNavInfoData(curentPlace)
    // infoMenudata.current = createContextFromEvent( event );
    // const curentPlace = {
    //   top_vault: topVaultId,
    //   vault: vaultId,
    //   parameter: config.parameter_id,
    //   y_value: y,
    //   x_value: x,
    //   frame_no: currentFrame,
    //   tag_type: 0,
    //   text: "",
    //   image_url: "",
    // };

    //  infoMenu.onContextThreeSixtyViewer(event, curentPlace);
  }

  const NavInfoHandler=()=>{
  let NaveData={...NavInfoData,tag_type:1}
  setNavInfoData(NaveData)
    navModal.open()
  }

  const InfoHandler=()=>{
    let NaveData={...NavInfoData,tag_type:0}
    setNavInfoData(NaveData)
      navModal.open()
  }
  
  useEffect(() => {
    // spritespin-canvas
    if (!canavas) return;
    if (width < 1190) return;
    if (!load) return;
    const canvas = document.querySelector("canvas") as HTMLCanvasElement;
   
    const ctx = canvas?.getContext("2d") as any;

    // if (!ctx) return;
    if (tags.length == 0) return;
    tags
      .filter((tag: any) => tag.frame_no === currentFrame)
      .forEach((tag: any) => {
        ctx.fillStyle = vistedId == tag.id ? "green" : "red";
        ctx.beginPath();
        ctx.arc(tag.x_value, tag.y_value, 7, 0, 2 * Math.PI);
        ctx.fill();
      });
  }, [currentFrame, tags, isTagging, load, vistedId, dragging, width]);

  return (
    <div className={styles.dataView}>
      {isOpen && (
        <>
          <TagTransitionablePortal
          isOpen={tagDataModal.isOpen}
          onClose={tagDataModal.close}
          tagData={tagData}
          setVistedId={setVistedId}
        />
        {navModal.isOpen &&
         <IdeagenTagDataModal
          isOpen={navModal.isOpen}
          onCloseModal={navModal.close}
          Item={NavInfoData}
          setCounter={setCounter}
        />
}
          <Box sx={{backgroundColor: '#00000059'}} className={styles.closedImgs}>
           
            <ClearIcon
            titleAccess="Close"
              sx={{color:'common.white',marginLeft: "1rem"} }
              onClick={() => onCloseModal()}
            />
          </Box>
          <div id="tooltip">

            {imageUrl !== "" ? (
              // eslint-disable-next-line jsx-a11y/alt-text, jsx-a11y/img-redundant-alt
              <img
                src={ imageUrl}
                style={{
                  width: "100%",
                  maxHeight: "20rem",
                  objectFit: "cover",
                  padding:"2rem"
                }}
                alt="image"
                onLoad={() => setLoadImage(true)}
              />
            ) : null}
            <div>
              <span    id="tooltip-text">

              </span>
            </div>
          </div>
          {/* <div> */}

          {(
          <>

          </>
        )}
     
 
 
          <div style={{background:"white",width:"60rem",height:"37rem",    margin: "0 auto",marginTop:"13rem"}}>


          <div style={{background:"white",  display: "flex",    flexDirection: "column",   marginLeft: "2rem"}}>

          <div>
            <Button
            //   active={isTagging}
              onClick={() => setIsTagging((prev) => !prev)}
            >
              Add Hotspots
            </Button>
            <Button
            //   active={editTag}
              onClick={() => setEditTag((prev) => !prev)}
            >
              Edit Hotspots
            </Button>
            </div>
            <div>

              Current Frame :{currentFrame}
            </div>
            </div>
        
          <div
            id="threesixsty"
            className={styles.threesixstyInnerContainer}
            ref={ref}
            onClick={(e) => {
              tagClick(canavas, e);
            }}
            onContextMenu={(e) => getCursorPosition(canavas, e)}
            onMouseMove={(e) => handleToolTip(canavas, e)}
          >
      
   

            <div style={{display:anchorEl?"flex":"none",position:"absolute",backgroundColor:"white",width:"14rem",
            height:"7rem",
            flexDirection: "column",
            border: "1px solid #ddefff",
            borderRadius: "5px",
            zIndex:"9990",
            
             top:`${Positionmenu?.top}px`,left:`${Positionmenu?.left}px`
        }
            } >
              <div className={styles.tabcolor2} onClick={()=>InfoHandler()}>
                    Info
                </div>
                <div className={styles.tabcolor} onClick={()=>NavInfoHandler()}>
                    Nav Link
                </div>
                
            </div> 
 



   
          </div>
          </div>


          <Box>
       
      
      </Box>
          {/* </div> */}
         

          





  
        </>
      )}
    </div>
  );
};

export default IdeagenThreeSixtyCreateModal;





