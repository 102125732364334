import { MODULES } from "../../Constants/modules.constants";
import { ID } from "../../utlis/commonInterfaces";
import { API } from "./index.service";



export default {

    get ( top_vault: number, name: string ) {
        return API.get( `/idea/priority_matrix/`, { [name]: top_vault }, 0 );

    },
    getTopVaults ( projectId: number ) {
        return API.get( `/xcpep/top_vault/`, { project: projectId, module: MODULES.IDEA_GENERATION, idea_base: true } );
    },

    getSummary ( top_vault: number, name: string ) {
        return API.get( `/idea/summary/`, { [name]: top_vault }, 0 );
    },


    getTree ( parent: number, module: ID , idea?:any) {
        return API.get( 'xcpep/vault_info/', { parent: parent, module, idea } );
    }



};