import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

import { VALIDATIONS } from "../Services/relation-validation.service";
import { ID } from "../../utlis/commonInterfaces";
import { ICustomValidation } from "../Reducers/customValidation.reducer";

// -------------------------------------------------------------------------------------
// api actions
// -------------------------------------------------------------------------------------

export interface GetCustomValidationsArg {
    topVaultId: ID;
    categoryId: ID;
}

const getCustomValidations = createAsyncThunk(
    "customValidations/get",
    async ( arg: GetCustomValidationsArg, { rejectWithValue } ) => {
        try {
            const { data } = await VALIDATIONS.getAllValidations(
                arg.topVaultId,
                arg.categoryId
            );

            return {
                validations: data as ICustomValidation[],
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

// -------------------------------------------------------------------------------------
// reducer actions
// -------------------------------------------------------------------------------------

const resetCustomValidations = createAction( "customValidations/reset" );

// -------------------------------------------------------------------------------------
// exports
// -------------------------------------------------------------------------------------

export { getCustomValidations, resetCustomValidations };
