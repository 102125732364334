import { useState } from "react";
import { ID } from "../utlis/commonInterfaces";

// ----------------------------------------------------------------------------------

const useBaseParamsModal = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [propsId, setPropsId] = useState<ID>(0);
  const [Item,setItem]=useState<any>(null)
  const handleOpen = (id?: any,data?:any) => {
    setPropsId(id);
    setIsOpen(true);
    setItem(data)
  };

  const handleClose = () => {
    setPropsId(0);
    setIsOpen(false);
    setItem(null)
  };

  return Object.freeze({
    open: handleOpen,
    close: handleClose,
    isOpen,
    propsId,
    Item,
  });
};

// ----------------------------------------------------------------------------------

export { useBaseParamsModal };
