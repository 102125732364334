// import React, { PureComponent } from 'react'
import React, { useEffect, useState, PureComponent } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import {
  Autocomplete,
  Button,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import ListItemText from "@mui/material/ListItemText";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Checkbox from "@mui/material/Checkbox";
import { API } from "../../../api-services";
import { LoadingButton } from "@mui/lab";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import CreateBoughtOutDatabaseModalMove from "./CreateBoughtOutDatabaseModalMove";
import { useBaseParamsModal } from "../../../CustomHook/useBaseParamsModal";
import SubRevisionEditModalMove from "../SubRevisionEditModalMove";
import { useRouteMatch } from "react-router-dom";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: { lg: '48vw', xl: " 40vw" },
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
  zIndex: 0,
};

interface RMBOPmoveModalProps {
  isOpen: any;
  onCloseModal: any;
  actions?: any;
  expanded?: any;
  getMaterialData?: any;
  categoryList?: any;
  getCategoryData: any;
  categoryListMaterial:any;
}


const RMBOPmoveModal = (props: RMBOPmoveModalProps) => {
  const { isOpen, onCloseModal, actions,expanded,getMaterialData,categoryList,getCategoryData,categoryListMaterial} = props;
  const { revisionId, typeId, databaseName, categoryName } = useRouteParams<any>();
  const {url}=useRouteMatch()
  const bopMoveModal = useBaseParamsModal();
  const BOPtoRMMoveModal =useBaseParamsModal();
  const [DataTypeValues, setDataTypeValues] = useState<any>({});
  const [loading, setLoading] = useState<any>(false)
  const [movetype,setMoveType]=React.useState<any>();
  const handleSelectdatatype = (item: any) => {
    setDataTypeValues(item);
  };
  const handleChange=(ed:any)=>{
   const {value}=ed.target;
   setMoveType(value)
   if(value === "Bought-out Parts"){
    bopMoveModal.open(actions);
   }else{
    BOPtoRMMoveModal.open(actions);
   }
   
  }
  const getOptions=()=>{
    if(url.includes("bopDB") && !actions?.mapped_status){
     return ["Raw Material","Bought-out Parts"];
    }
    else{
    return ["Bought-out Parts"]
    }
  }
  React.useEffect(()=>{
    return ()=>setMoveType(undefined)
  },[movetype])






  return (
    <div>
      <Modal
        open={isOpen}
        // onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <>
          <Box sx={style}>
            <Box
              sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  width: "100%",
                  textAlign: "center",
                  marginRight: "-2rem",
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                }}>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontWeight: 500,
                    marginBottom: "0.5rem",
                  }}>
                  BOP Information
                </Typography>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontWeight: 500,
                    marginBottom: "0.5rem",
                  }}>
                  Move BOP To
                </Typography>

              </Typography>
              <CancelIcon
                titleAccess="Close"
                sx={{ cursor: "pointer", margin: "0.5rem" }}
                onClick={onCloseModal}
              />
            </Box>

            <Divider
              sx={{
                borderColor: "primary.light",
                margin: "0 1rem",
                marginBottom: "1rem",
              }}
            />

            <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr", marginBottom: '0.5rem' }}>
              <Box sx={{ padding: '0 1rem' }}>
                <Typography sx={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
                  <Typography sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '8rem' }}>
                    <Typography sx={{ fontSize: '1rem', }}>Category Name</Typography>
                    <Typography sx={{ fontSize: '1rem' }}>:</Typography>
                  </Typography>
                  <Typography sx={{ fontSize: '1rem', whiteSpace: 'nowrap' }}> {loading ? <span>Loading....</span> : <span> {categoryName }</span>}</Typography>
                </Typography>
                <Typography sx={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
                  <Typography sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '8rem' }}>
                    <Typography sx={{ fontSize: '1rem', }}> Name</Typography>
                    <Typography sx={{ fontSize: '1rem' }}>:</Typography>
                  </Typography>
                  <Typography sx={{ fontSize: '1rem', whiteSpace: 'nowrap' }}>{actions.name}</Typography>
                </Typography>
                {/* <Typography sx={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
                  <Typography sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '8rem' }}>
                    <Typography sx={{ fontSize: '1rem', }}> Machine Code </Typography>
                    <Typography sx={{ fontSize: '1rem' }}>:</Typography>
                  </Typography>
                  <Typography sx={{ fontSize: '1rem', whiteSpace: 'nowrap' }}> Code</Typography>
                </Typography> */}
              </Box>
              <Box sx={{ padding: '0 1rem' }}>

                <FormControl
                  fullWidth
                  variant="standard">
                  <InputLabel sx={{ color: "primary.main" }} shrink id="demo-simple-select-label">Select Item name </InputLabel>
                  <Select
                    id="name"
                    name="name"
                    // className={classes.select}
                    labelId="demo-select-small"
                    // id="demo-select-small"
                    sx={{
                      "&:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                        fontSize: '1rem'
                      },
                      '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },

                    }}
               
                    MenuProps={{
                      style: {
                        maxHeight: 400,
                        color: "007fff",
                      },
                    }}
                    onChange={handleChange}
                    value={movetype|| ""}
                    >
                    {/* <option value={""} style={{ display: "none" }}></option> */}
                    {getOptions().map((item: any, index: any) => {
                      return (
                        <MenuItem
                          sx={{ color: "primary.main", fontSize: "1rem" }}
                          key={index}
                          value={item}>
                          {item}
                        </MenuItem>
                      );
                    })}
                  </Select>
                  {/* <div className={styles.errormsg}>{formik.touched.name && Boolean(formik.errors.name) && <p>{formik.errors.name}</p>}</div> */}
                </FormControl>

              </Box>
            </Box>
            <Box sx={{ width: '100%', textAlign: 'right', marginTop: '1rem', padding: '0 1rem 0.5rem' }}>
           
            </Box>
          </Box>
        </>
      </Modal>
      {bopMoveModal.isOpen && (
        <CreateBoughtOutDatabaseModalMove
          isOpen={bopMoveModal.isOpen}
          onCloseModal={bopMoveModal.close}
          actions={bopMoveModal.propsId}
          expanded={expanded}
          getMaterialData={getMaterialData}
          categoryList={categoryList}
          getCategoryData={getCategoryData}
          onCloseParent={onCloseModal}
        />
      )}
      {BOPtoRMMoveModal.isOpen && (
        <SubRevisionEditModalMove
          isOpen={BOPtoRMMoveModal.isOpen}
          onCloseModal={BOPtoRMMoveModal.close}
          actions={BOPtoRMMoveModal.propsId}
          expanded={expanded}
          getMaterialData={getMaterialData}
          categoryList={categoryListMaterial}
          onCloseParent={onCloseModal}
        />
      )}
    </div>
  )
}
export default RMBOPmoveModal;