import * as React from "react";
import { useEffect } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import * as yup from "yup";
import { Form, useFormik } from "formik";
import {
  Backdrop,
  Divider,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import styles from "./AddRevisionModal.module.scss";
import { API } from "../../api-services";
import { LoadingButton } from "@mui/lab";
import CancelIcon from "@mui/icons-material/Cancel";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import uploadS3ToolbarFlag from "./uploadS3ToolbarFlag";
import { useBaseModal } from "../../ComponentUI/SearchPage/useBaseModal";
import ErrorModalFileCosting from "./ErrorModalFileCosting";
import SuccessModalFileCosting from "./SuccessModalFileCosting";
import uploadS3ToolbarFlagFetch from "./uploadS3ToolbarFlagFetch";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45vw",
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 0,
};

const validationSchema = yup.object({
  commodity: yup.string().required("Please select Commodity"),
  toolCode: yup.string().required("Please enter Tool Code"),
  toolName: yup.string().required("Please enter Tool Name"),
  specification: yup.string().required("Please enter Specification"),
  category: yup.string().required("Please enter Category"),
  material: yup.string().required("Please enter Material"),
  unit: yup.mixed().required("Please enter unit"),
  currency_id: yup.mixed().required("Please enter currency"),
  life: yup
    .number()
    .min(1, "Must be a more then zero")
    .required("Please enter Life (No. of Parts)"),
});

const useStyles = makeStyles((theme) => ({
  select: {
    "&.MuiInputBase-root-MuiInput-root-MuiSelect-root": {
      borderBottom: "1px solid red",
    },
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface IAddToolbarDatabaseModel {
  isOpen: any;
  onCloseModal: any;
  id: any;
  actionSend: any;
  apiCallAccordianInsideData: any;
  setApiCallAccordianInsideData: any;
  valueEdit?: any;
  apiCallAccordianData?: any;
  setApiCallAccordianData?: any;
}

function AddToolbarDatabaseModel(props: IAddToolbarDatabaseModel) {
  const {
    isOpen,
    onCloseModal,
    id,
    actionSend,
    apiCallAccordianInsideData,
    setApiCallAccordianInsideData,
    apiCallAccordianData,
    setApiCallAccordianData,
    valueEdit,
  } = props;
  const { categoryName, search, databaseName, revisionId } =
    useRouteParams<any>();
  const [commodityData, setCommodityData] = React.useState<any>([]);
  const [isLoadingsave, setIsLoadingSave] = React.useState<any>(false);
  const [flag, setFlag] = React.useState<any>();
  const [ErrorModalFileMsg, setErrorModalFileMsg] = React.useState<any>();
  const [SuccessModalFileMsg, setSuccessModalFileMsg] = React.useState<any>();

    // ----------------------------------------------

    const [countryList, setCountryList] = React.useState<any[]>([]);
    const [currencyCodes, setCurrencyCodes] = React.useState<string[]>([]);
    const [countryLoading, setCountryLoading] = React.useState<boolean>(false);
    const [selectedCurrencyCodes, setSelectedCurrencyCodes] =
      React.useState<any>("");
    // ----------------------------------------------

  const ErrorModalFiles = useBaseModal();
  const SuccessModalFiles = useBaseModal();
  const classes = useStyles();

  useEffect(() => {
    API.get("/api/db/cost_databases/", { database_type: "tools" }, 0).then(
      (res: any) => {
        setCommodityData(res?.data);
      }
    );
  }, [isOpen]);


  React.useEffect(() => {
    if (commodityData != undefined && actionSend !== "add") {
      if (valueEdit?.currency_code) {
        setSelectedCurrencyCodes([valueEdit?.currency_code] || []);
      } else {
        setSelectedCurrencyCodes([]);
      }
      commodityData &&
        formik.setFieldValue(
          "commodity",
          String(
            commodityData?.filter(
              (item: any) => item?.name == valueEdit?.commodity
            )[0]?.id
          )
        );
      formik.setFieldValue("toolCode", valueEdit?.toolCode);
      formik.setFieldValue("toolName", valueEdit?.toolName);
      formik.setFieldValue("specification", valueEdit?.specification);
      formik.setFieldValue("category", valueEdit?.category);
      formik.setFieldValue("supplier", valueEdit?.supplier);
      formik.setFieldValue("material", valueEdit?.material);
      formik.setFieldValue("other", valueEdit?.other);
      formik.setFieldValue("life", valueEdit?.life);
      formik.setFieldValue("toolCost", valueEdit?.toolCost);
      formik.setFieldValue("reference", valueEdit?.reference);
      formik.setFieldValue("unit", valueEdit.unit);
      formik.setFieldValue("currency_id", valueEdit.currency_id);
      formik.setFieldValue("currency_code", valueEdit.currency_code);
    }
  }, [actionSend, commodityData]);


  const formik = useFormik({
    initialValues: {
      commodity: "",
      toolCode: "",
      toolName: "",
      specification: "",
      category: "",
      supplier: "",
      material: "",
      other: "",
      life: 1,
      toolCost: 0,
      reference: "",
      unit: "",
      currency_id: "",
      currency_code: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
     
      
      setIsLoadingSave(true);
      if (actionSend === "add") {
        if (flag) {
          if (!databaseName?.includes("DB")) {
            await uploadS3ToolbarFlagFetch(
              values?.commodity,
              values?.toolCode,
              values?.toolName,
              values?.specification,
              values?.category,
              values?.supplier,
              values?.material,
              values?.other,
              values?.life,
              values?.toolCost,
              values?.reference,
              // values?.unit,
              // values?.currency_id,
              // values?.currency_code,
              flag,
              onCloseModal,
              "toolFlag",
              "add",
              setIsLoadingSave,
              setApiCallAccordianInsideData,
              apiCallAccordianInsideData,
              apiCallAccordianData,
              setApiCallAccordianData,
              setSuccessModalFileMsg,
              setErrorModalFileMsg,
              SuccessModalFiles,
              ErrorModalFiles,
              revisionId
            );
          } else {
            await uploadS3ToolbarFlag(
              values?.commodity,
              values?.toolCode,
              values?.toolName,
              values?.specification,
              values?.category,
              values?.supplier,
              values?.material,
              values?.other,
              values?.life,
              values?.toolCost,
              values?.reference,
              
              flag,
              onCloseModal,
              "toolFlag",
              "add",
              setIsLoadingSave,
              setApiCallAccordianInsideData,
              apiCallAccordianInsideData,
              apiCallAccordianData,
              setApiCallAccordianData,
              setSuccessModalFileMsg,
              setErrorModalFileMsg,
              SuccessModalFiles,
              ErrorModalFiles
            );
          }
        } else {
          API.post(`/api/db/tool/`, {
            category: values?.category,
            code: values?.toolCode,
            commodity: values?.commodity,
            details: values?.reference,
            material: values?.material,
            name: values?.toolName,
            other: values?.other,
            price: values?.toolCost,
            shots: values?.life,
            specification: values?.specification,
            supplier: values?.supplier,
            currency_id: values?.currency_id,
            currency_code: values?.currency_code,
            unit: values?.unit,

          })
            .then((res: any) => {
              if (!databaseName?.includes("DB")) {
                API.post("/api/db/tool_cost/", {
                  revision: revisionId,
                  tools_id: [res.data.id],
                })
                  .then((res: any) => {
                    setSuccessModalFileMsg("Operation added successfully!");
                    SuccessModalFiles.open();
                    setApiCallAccordianData(!apiCallAccordianData);
                    setApiCallAccordianInsideData(!apiCallAccordianInsideData);
                    onCloseModal();
                  })
                  .catch((err: any) => {});
              } else {
                setSuccessModalFileMsg("Operation added successfully!");
                SuccessModalFiles.open();
                setApiCallAccordianData(!apiCallAccordianData);
                setApiCallAccordianInsideData(!apiCallAccordianInsideData);
                onCloseModal();
              }
            })
            .catch((err) => {
              setIsLoadingSave(false);
              setErrorModalFileMsg(err.response.data);
              ErrorModalFiles.open();
            });
        }
      } else {
        if (flag) {
          await uploadS3ToolbarFlag(
            values?.commodity,
            values?.toolCode,
            values?.toolName,
            values?.specification,
            values?.category,
            values?.supplier,
            values?.material,
            values?.other,
            values?.life,
            values?.toolCost,
            values?.reference,
            flag,
            onCloseModal,
            "toolFlag",
            "edit",
            setIsLoadingSave,
            setApiCallAccordianInsideData,
            apiCallAccordianInsideData,
            apiCallAccordianData,
            setApiCallAccordianData,
            setSuccessModalFileMsg,
            setErrorModalFileMsg,
            SuccessModalFiles,
            ErrorModalFiles,
            id,
            search
          );
        } else {
          API.put(`/api/db/tool/${id}/`, {
            category: values?.category,
            code: values?.toolCode,
            commodity: values?.commodity,
            details: values?.reference,
            material: values?.material,
            name: values?.toolName,
            other: values?.other,
            price: values?.toolCost,
            shots: values?.life,
            specification: values?.specification,
            supplier: values?.supplier,
            currency_id: values?.currency_id,
            currency_code: values?.currency_code,
            unit: values?.unit,
          })
            .then((res: any) => {
              setIsLoadingSave(false);
              setSuccessModalFileMsg("Operation edited successfully!");
              SuccessModalFiles.open();
              setApiCallAccordianInsideData(!apiCallAccordianInsideData);
            })
            .catch((err) => {
              setIsLoadingSave(false);
              setErrorModalFileMsg(err.response.data);
              ErrorModalFiles.open();
            });
        }
      }
    },
  });

  const viewFile = (file: string) => {
    window.open(file);
  };

  const handleChange = (e: any) => {
    setFlag(e.currentTarget.files);
  };

 console.log(flag);
 
  const getCountryData = () => {
    setCountryLoading(true);
    API.get("customer/currency_search/", { all_currencies: true, database:true }, 0)
      .then((res: any) => {
        const c_codes = res?.data.map((item: any) => item.currency_code);
        setCurrencyCodes(c_codes);
        setCountryList(res.data);
        setCountryLoading(false);
      })
      .catch((err: any) => {
        console.error("Error fetching currency data:", err);
        setCountryLoading(false);
      });
  };

  React.useEffect(() => {
    getCountryData();
  }, []);
  const flagMap = countryList?.reduce((acc, country) => {
    acc[country?.currency_code] = country?.flag;
    return acc;
  }, {});
console.log(formik.values);

  return (
    <div>
      {ErrorModalFiles.isOpen && (
        <ErrorModalFileCosting
          isOpen={ErrorModalFiles.isOpen}
          onCloseModal={ErrorModalFiles.close}
          ErrorMsg={ErrorModalFileMsg}
          onCloseParentModel={onCloseModal}
        />
      )}
      {SuccessModalFiles.isOpen && (
        <SuccessModalFileCosting
          isOpen={SuccessModalFiles.isOpen}
          onCloseModal={SuccessModalFiles.close}
          successMsg={SuccessModalFileMsg}
          onCloseParentModel={onCloseModal}
        />
      )}
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              {actionSend !== "add" ? "Edit" : "Add"} Tool
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ padding: "2rem", textAlign: "center" }}>
            <form onSubmit={formik.handleSubmit}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                <FormControl fullWidth variant="standard">
                  <InputLabel
                    sx={{ color: "primary.main" }}
                    shrink
                    id="demo-simple-select-label"
                  >
                    Select Commodity
                  </InputLabel>
                  <Select
                    id="commodity"
                    disabled={actionSend !== "add"}
                    name="commodity"
                    className={classes.select}
                    labelId="demo-select-small"
                    // id="demo-select-small"
                    sx={{
                      "&:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                        {
                          fontSize: "1rem",
                        },
                      ".MuiSvgIcon-root.MuiSelect-icon": {
                        color: "primary.main",
                      },
                    }}
                    error={
                      formik.touched.commodity &&
                      Boolean(formik.errors.commodity)
                    }
                    //@ts-ignore
                    helperText={
                      formik.touched.commodity && formik.errors.commodity
                    }
                    MenuProps={{
                      style: {
                        maxHeight: 400,
                        color: "007fff",
                      },
                    }}
                    onChange={formik.handleChange}
                    value={
                      formik.values.commodity !== null
                        ? formik.values.commodity
                        : ""
                    }
                  >
                    {commodityData &&
                      commodityData.map((item: any, index: any) => {
                        return (
                          <MenuItem
                            sx={{ color: "primary.main", fontSize: "1rem" }}
                            key={index}
                            value={item.id}
                          >
                            {item.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <div className={styles.errormsg}>
                    {formik.touched.commodity &&
                      Boolean(formik.errors.commodity) && (
                        <p>{formik.errors.commodity}</p>
                      )}
                  </div>
                </FormControl>
                <FormControl fullWidth variant="standard">
                  <TextField
                    variant="standard"
                    label="Tool Code"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="toolCode"
                    name="toolCode"
                    onChange={formik.handleChange}
                    value={formik.values.toolCode}
                    multiline
                    error={
                      formik.touched.toolCode && Boolean(formik.errors.toolCode)
                    }
                    helperText={
                      formik.touched.toolCode && formik.errors.toolCode
                    }
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                <FormControl fullWidth variant="standard">
                  <TextField
                    variant="standard"
                    label="Tool Name"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="toolName"
                    name="toolName"
                    onChange={formik.handleChange}
                    value={formik.values.toolName}
                    multiline
                    error={
                      formik.touched.toolName && Boolean(formik.errors.toolName)
                    }
                    helperText={
                      formik.touched.toolName && formik.errors.toolName
                    }
                  />
                </FormControl>
                <FormControl fullWidth variant="standard">
                  <TextField
                    variant="standard"
                    label="Category"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="category"
                    name="category"
                    onChange={formik.handleChange}
                    value={formik.values.category}
                    multiline
                    error={
                      formik.touched.category && Boolean(formik.errors.category)
                    }
                    helperText={
                      formik.touched.category && formik.errors.category
                    }
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                <FormControl fullWidth variant="standard">
                  <TextField
                    variant="standard"
                    label="Specification"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="specification"
                    name="specification"
                    onChange={formik.handleChange}
                    value={formik.values.specification}
                    multiline
                    error={
                      formik.touched.specification &&
                      Boolean(formik.errors.specification)
                    }
                    helperText={
                      formik.touched.specification &&
                      formik.errors.specification
                    }
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                <FormControl fullWidth>
                  <TextField
                    variant="standard"
                    label="Supplier"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="supplier"
                    name="supplier"
                    onChange={formik.handleChange}
                    value={formik.values.supplier}
                    multiline
                    error={
                      formik.touched.supplier && Boolean(formik.errors.supplier)
                    }
                    helperText={
                      formik.touched.supplier && formik.errors.supplier
                    }
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    variant="standard"
                    label="Material"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="material"
                    name="material"
                    onChange={formik.handleChange}
                    value={formik.values.material}
                    multiline
                    error={
                      formik.touched.material && Boolean(formik.errors.material)
                    }
                    helperText={
                      formik.touched.material && formik.errors.material
                    }
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                <FormControl fullWidth>
                  <TextField
                    variant="standard"
                    label="Other"
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="other"
                    name="other"
                    onChange={formik.handleChange}
                    value={formik.values.other}
                    multiline
                    error={formik.touched.other && Boolean(formik.errors.other)}
                    helperText={formik.touched.other && formik.errors.other}
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                <FormControl fullWidth>
                  <TextField
                    variant="standard"
                    type="number"
                    label="Life (No. of Parts)"
                    inputProps={{
                      step: "any",
                      style: { color: "#007fff" },
                      min: 1,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="life"
                    name="life"
                    onChange={formik.handleChange}
                    value={formik.values.life}
                    error={formik.touched.life && Boolean(formik.errors.life)}
                    helperText={formik.touched.life && formik.errors.life}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    variant="standard"
                    type="number"
                    label="Tool Cost (INR)"
                    inputProps={{
                      step: "any",
                      style: { color: "#007fff" },
                      min: 0,
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="toolCost"
                    name="toolCost"
                    onChange={formik.handleChange}
                    value={formik.values.toolCost}
                    error={
                      formik.touched.toolCost && Boolean(formik.errors.toolCost)
                    }
                    helperText={
                      formik.touched.toolCost && formik.errors.toolCost
                    }
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                <FormControl fullWidth>
                  <TextField
                    variant="standard"
                    label="Reference"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="reference"
                    name="reference"
                    onChange={formik.handleChange}
                    value={formik.values.reference}
                    multiline
                    error={
                      formik.touched.reference &&
                      Boolean(formik.errors.reference)
                    }
                    helperText={
                      formik.touched.reference && formik.errors.reference
                    }
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                {actionSend !== "add" && valueEdit?.flag && (
                  <FormControl fullWidth sx={{ marginTop: "0.5rem" }}>
                    <div
                      style={{
                        marginBottom: "1rem",
                        width: "100%",
                        textAlign: "left",
                      }}
                    >
                      <div
                        style={{
                          color: "#007fff",
                          fontSize: "0.8rem",
                          // paddingLeft: "0.5rem",
                          lineHeight: "1.4375em",
                          letterSpacing: "0.00938em",
                        }}
                      >
                        Previous Uploaded File View
                      </div>
                      <div
                        onClick={() => viewFile(valueEdit?.flag)}
                        style={{
                          cursor: "pointer",
                          // paddingLeft: "0.5rem",
                          textDecoration: "underline",
                        }}
                      >
                        {valueEdit?.flag && valueEdit?.flag}
                      </div>
                    </div>
                  </FormControl>
                )}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                <FormControl fullWidth>
                  <InputLabel
                    shrink
                    id="demo-simple-select-label-Activity"
                    sx={{
                      color: "primary.main",
                      marginLeft: "-1rem",
                      marginTop: "0.5rem",
                    }}
                  >
                    Flag
                  </InputLabel>
                  <Input
                    type="file"
                    style={{ fontSize: "1rem" }}
                    onChange={handleChange}
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "0.5rem",
                  marginBottom: "0.5rem",
                }}
              >
                <Box
                  sx={{
                    marginTop: "0.75rem",
                    display: "flex",
                    alignItems: "center",
                    columnGap: "0.25rem",
                    width: "50%",
                  }}
                >
                  <FormControl
                    fullWidth
                    variant="standard"
                    sx={{ ".MuiFormLabel-asterisk": { color: "red" } }}
                  >
                    <InputLabel
                      id="demo-multiple-name-label"
                      sx={{ fontSize: "0.75rem" }}
                      style={{
                        color: "#007fff",
                        fontSize: "1.1rem",
                      }}
                    >
                      Currency
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      multiple={false}
                      value={selectedCurrencyCodes}
                      onChange={(event: any) => {
                        setSelectedCurrencyCodes(event.target.value);

                        const selectedCurIds = countryList
                          .filter(
                            (item: any) =>
                              event.target.value === item?.currency_code
                          )
                          .map((obj: any) => obj?.id);
                        

                        formik.setFieldValue("currency_id", String(selectedCurIds));
                        formik.setFieldValue("currency_code", String(event.target.value));
                      
                      }}
                      input={
                        <Input id="standard-input" placeholder="Currency" />
                      }
                      error={
                        formik.touched.currency_id &&
                        Boolean(formik.errors.currency_id)
                      }
                      //@ts-ignore
                      helperText={
                        formik.touched.currency_id && formik.errors.currency_id
                      }
                      sx={{
                        "& .MuiSelect-select": {
                          textAlign: "left",
                          fontSize: "1rem",
                          borderBottom: "1px solid #007fff",
                        },
                      }}
                    >
                      {currencyCodes.map((name: any, index: any) => (
                        <MenuItem key={index} value={name}>
                          {flagMap[name] && (
                            <img
                              src={`data:image/png;base64, ${flagMap[name]}`}
                              alt={`${name} flag`}
                              style={{
                                width: "1.5rem",
                                height: "1.5rem",
                                marginLeft: "1rem",
                                marginRight: "1rem",
                              }}
                            />
                          )}
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {/* Add the '/' separator */}
                  <Typography
                    variant="body1"
                    sx={{
                      alignSelf: "center",
                      fontSize: "1.5rem",
                      marginTop: "1.85rem",
                    }}
                  >
                    /
                  </Typography>

                  <FormControl fullWidth variant="standard">
                    <TextField
                      variant="standard"
                      label="Unit"
                      InputProps={{
                        style: { color: "#007fff" },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        "& .Mui-error": {
                          color: "#d32f2f",
                          borderBottomColor: "#d32f2f",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "primary.light",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "primary.main",
                        },
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "primary.main",
                        },
                        "& .MuiFormLabel-root.MuiInputLabel-root ": {
                          color: "primary.main",
                        },
                        ".MuiInputBase-root.MuiInput-root": {
                          fontSize: "1rem !important",
                        },
                      }}
                      id="unit"
                      name="unit"
                      onChange={formik.handleChange}
                      value={formik.values.unit}
                      multiline
                      error={formik.touched.unit && Boolean(formik.errors.unit)}
                      helperText={formik.touched.unit && formik.errors.unit}
                    />
                  </FormControl>
                </Box>
              </Box>
              <Box sx={{ width: "100%", textAlign: "right" }}>
                <LoadingButton
                  size="small"
                  loading={isLoadingsave}
                  variant="contained"
                  type="submit"
                  sx={{
                    marginTop: "1rem",
                    "&:hover": {
                      transform: "Scale(1.05)",
                      transition: "transform 0.5s ease",
                    },
                  }}
                >
                  {actionSend !== "add" ? "Save" : "Add"}
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
export default AddToolbarDatabaseModel;
