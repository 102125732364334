import { Box, FormControl, MenuItem, Select, Tooltip } from "@mui/material";

import React, { useEffect } from "react";

function RenderValueColumnItemView({
  input,
  getOptionData,
  getCalculatorColumns,
  bomCalculatorColumns,
  isTableParameter,
  getdatafromtype,
}: any) {


  useEffect(() => {
    if (input?.parameter) {
      getCalculatorColumns(input?.parameter);
    }
  }, [input]);





  return (
    <Box sx={{ display: "flex", alignItems: "center", columnGap: "0.5rem", }}>
      <Tooltip title={
        getOptionData(input.type)?.filter((item: any) => item.value == input.parameter)[0]?.text || ""
      }>
        <FormControl sx={{ overflow: 'hidden' }} fullWidth>
          {
            getOptionData(input.type)?.filter((item: any) => item.value == input.parameter)[0]?.text || ""
          }
        </FormControl>
      </Tooltip>

      {input?.parameter && (
        <Tooltip title={
          bomCalculatorColumns?.filter((item: any) => item.id == input?.table_column_parameter)[0]?.name || ""
        }>
          <FormControl sx={{ overflow: 'hidden' }} fullWidth>
            {
              bomCalculatorColumns?.filter((item: any) => item.id == input?.table_column_parameter)[0]?.name || ""}
          </FormControl>
        </Tooltip>
      )}

      {input?.parameter && (
        <Tooltip title={input?.table_element_type || ""}>
          <FormControl fullWidth>
            {input?.table_element_type || ""}
          </FormControl>
        </Tooltip>
      )}

      {input.parameter && isTableParameter(input.type, input.parameter) && (
        <FormControl sx={{ overflow: 'hidden' }} fullWidth>
          {getdatafromtype(input)?.filter((item: any) => item.value == input.table_element)[0]?.text || ""}
        </FormControl>
      )}
      
    </Box>
  );
}

export default RenderValueColumnItemView;
