import React from 'react'
import { useHistory } from 'react-router-dom';
import { API } from '../api-services';
import { AUTH } from '../Redux/Services/auth.service';
import styles from "./ViewComponents.module.scss";
import QuickCompareLanding from '../ComponentUI/QuickCompare/QuickCompareLanding';
import AspectRatioIcon from '@mui/icons-material/AspectRatio';

type Props = {}

export default function Footer({ }: Props) {
  const [closeCount,setCloseCounts]=React.useState<any>(true)
  
  return (
    <div className={styles.footer} >
      {/* <span className={styles.fooCont} >Switch to old User Interface</span> */}
      {<QuickCompareLanding setCloseCounts={setCloseCounts}  />}
    </div>
  )
}
