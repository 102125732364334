import * as React from "react";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Autocomplete,
  Chip,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import auto from "../../../Assets/images/auto.png";
import styles from "../IdeaCreateLandingpage.module.scss";
import * as yup from "yup";
import MergeIcon from "@mui/icons-material/Merge";
import { API } from "../../../api-services";
import Swal from "sweetalert2";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import Image from "../../../ViewModule/FeatureLandingPage/ArchitectureImageCard";
import loadingImage from "../../../Assets/images/loading-screen.gif";
import { ADMIN } from "../../../Redux/Services/admin.service";
import { useParams } from "react-router-dom";
import { useFormik } from "formik";
import { LoadingButton } from "@mui/lab";

export interface IIdeaInfoModalProps {
  onCloseModal: any;
  isOpen: any;
  item: any;
  getIdeaList: any;
}

const validationSchema = yup.object({
  merge: yup.mixed().required("Please enter Idea Ids").test(
    "notEmptyArray",
    "Please Select minimum one  Idea Id for proceed",
    (value) => !Array.isArray(value) || value.length > 0
  ),
});

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "35vw",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

export default function MergeIdeaModal(props: IIdeaInfoModalProps) {
  const { onCloseModal, isOpen, item ,getIdeaList} = props;
  const {
    projectId,
    projectName,
    topVault,
    subSystem,
    subSystemId,
    expandTable,
    abbr,
    partId,
    depth,
    vault_id,
  } = useParams<any>();
  const [ideaAllDetails, setIdeaAllDetails] = React.useState<any>({});
  const [ideaList, setIdeaList] = React.useState<any>([]);
  const [loader, setLoader] = React.useState<any>(false);
  React.useEffect(() => {
    setLoader(true);
    API.get(`/idea/pool/`, {
      vault: partId != 0 ? partId : 0,
      top_vault: topVault,
      module: 15,
      page: 1,
      search_id: "",
      stage: item.stage,
    })
      .then((res: any) => {
        setLoader(false);
        setIdeaList(
          res.data?.results?.filter((itm:any)=>itm?.merge==null && itm?.id != item?.id)?.map((config: any, index: any) => ({
            key: index + 1,
            value: config?.id,
            text: config?.idea_id,
          }))
        );
      })
      .catch((err: any) => {
        setLoader(false);
      });
  }, []);


  const formik = useFormik({
    initialValues: {
      merge: [],
    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      API.put(
        `/idea/pool/${item.id}/`,
        {
          merge: values.merge?.map((item: any) => item.value),
        },
        {},
        0
      )
        .then((res: any) => {
          getIdeaList();
          
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Idea Merged Successfully",
            showConfirmButton: true,
            customClass: {
              container: "swal2Container",
            },
          });
          onCloseModal();
        })
        .catch((err: any) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            showConfirmButton: true,
            customClass: {
              container: "swal2Container",
            },
          });
        });
    },
  });

  return (
    <div>
      <Modal
        open={isOpen}
        // onClose={onCloseModal}
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Merge Ideas
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box
            sx={{ width: "100%", height: "fit-content", padding: "0 0.5rem" }}
          >
            <form onSubmit={formik.handleSubmit}>
              <FormControl
                required
                sx={{ ".MuiFormLabel-asterisk": { color: "red" } }}
                fullWidth
                variant="standard"
              >
                <Autocomplete
                  multiple
                  id="merge"
                  loading={loader}
                  loadingText="Loading..."
                  limitTags={4}
                  disableCloseOnSelect
                  sx={{ width: "100%" }}
                  options={ideaList || []}
                  value={formik.values.merge}
                  onChange={(event: any, newValue: any | null) => {
                    formik.setFieldValue("merge", newValue);
                  }}
                  // @ts-ignore
                  getOptionLabel={(option) => option?.text}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip
                        size="small"
                        //  @ts-ignore
                        sx={{
                          backgroundColor: "#007fff14",
                          color: "primary.main",
                          fontSize: "1rem",
                        }}
                        // @ts-ignore
                        label={option?.text}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      name="merge"
                      variant="standard"
                      placeholder="Select Ideas:"
                      sx={{
                        width: "100%",
                        ".MuiInputBase-root.MuiInput-root": {
                          fontSize: "1rem !important",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "primary.light",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "primary.main",
                        },
                        ".MuiSvgIcon-root": { color: "primary.main" },
                      }}
                    />
                  )}
                />
                {/* <Autocomplete
            loading={loader}
            loadingText="Loading..."
            id="base"
            getOptionLabel={(option: any) => option.text}
            options={ideaList}
            value={formik.values.merge}
            onChange={(event: any, newValue: any | null) => {
              formik.setFieldValue("merge", newValue);
           }}
            autoSelect
            sx={{ marginTop: "1.4rem" }}
            renderOption={(props, option) => (
              <li {...props} style={{ fontSize: '1rem' }}>
                {option.text}
              </li>
            )}
            renderInput={(params) => (
              <TextField
              name="base"
                {...params}
                variant="standard"
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "primary.light",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "primary.main",
                  },
                  ".MuiInputBase-root.MuiInput-root": {
                    fontSize: "1rem !important",
                  },
                  ".MuiSvgIcon-root": { color: "primary.main" },
                }}
              />
            )}
          /> */}
                <div className={styles.errormsg}>
                  {formik.touched.merge && Boolean(formik.errors.merge) && (
                    //@ts-ignore
                    <p>{formik.errors.merge}</p>
                  )}
                </div>
              </FormControl>
              <Box sx={{ width: "100%" }}>
                <LoadingButton
                  size="small"
                  variant="contained"
                  sx={{
                    padding: "0.5rem",
                    float: "right",
                    marginTop: "1rem",
                    lineHeight: 1,
                    "&:hover": {
                      transform: "Scale(1.05)",
                      transition: "transform 0.5s ease",
                    },
                    ".MuiCircularProgress-root": {
                      color: "primary.main",
                    },
                  }}
                  type="submit"
                >
                  Merge Ideas
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
