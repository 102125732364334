import * as React from "react";
import {
  Box,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { API } from "../../../api-services";

export interface ICostingInfoModalProps {
  onCloseModal: any;
  isOpen: any;
  vaultId:any
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

export function ChildPartModal(props: ICostingInfoModalProps) {
  const { onCloseModal, isOpen, vaultId } = props;
  const [partData,setPartData]=React.useState<any>({})
  const { calctype } = useRouteParams<any>();

  const getChildData=()=>{
    setPartData({})
    API.get("/cost/costing_view/",{
      level:"costing_info",
      vault:vaultId
    },0).then((res:any)=>{
      setPartData(res.data)
    }).catch((err:any)=>{

    })
  }

  React.useEffect(()=>{
    
    getChildData();
  },[])
  const getBody = () => {
    return <TableBody>
      <TableRow>
        <TableCell
          sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
        >
          Raw Material Cost
        </TableCell>
        <TableCell
          sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
        >
          ₹{partData?.rm_cost?.toFixed(2)}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
        >
          BOP Cost
        </TableCell>
        <TableCell
          sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
        >
          ₹{partData?.bop_cost?.toFixed(2)}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
        >
          Process Cost
        </TableCell>
        <TableCell
          sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
        >
          ₹{partData?.process_cost?.toFixed(2)}
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell
          sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
        >
          Overhead Cost
        </TableCell>
        <TableCell
          sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
        >
          ₹{partData?.overhead_cost?.toFixed(2)}
        </TableCell>
      </TableRow>
      
    </TableBody>;
    
  };
  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Child Part cost info
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Table>{getBody()}</Table>
        </Box>
      </Modal>
    </div>
  );
}
