import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { useParams } from "react-router-dom";
// import { makeStyles } from '@mui/styles';
import Typography from "@mui/material/Typography";
// import Typography from "@material-ui/core/Typography";
// import Typography from 'material-ui/styles/typography';
import React, { useEffect, useState } from "react";
import styles from "./IdeaModule.module.scss";
import { makeStyles } from "@mui/styles";
import { API } from "../api-services";
import BouncingLoader from "../ui-reusable-component/BouncingLoader";
import SubBouncingLoader from "../ui-reusable-component/SubBouncingLoader";
import Box from "@mui/material/Box";
import NoDocuments from ".././Assets/images/svgs/NoData.svg";

export interface IdeaModuleProps {

}

export default function IdeaModule(props: IdeaModuleProps) {
  // @ts-ignoreTopLevel
  const { projectId ,vault,treeabbr,topVault} = useParams<any>();
 
  const [IdeaData, setIdeaData] = useState<any>()
  const [Loader, setLoader] = useState<any>(false)
  
  const toggleTopvault = () => {
    if(topVault!==0   ){
    if (treeabbr=="product") {
      return { top_vault: topVault };
    } else {
      return { vault: vault };
    }
  }
  };


  useEffect(() => {
    setLoader(true)
   if(topVault!=0){
    API.get("/customer/view_data/", { idea: true, ...toggleTopvault() }).then((res: any) => {
      setIdeaData(res?.data)
      setLoader(false)
    })
  
  }
  }, [vault, topVault])







  return (

    <div className={styles.ideaModuleSec}
      adl-scrollbar="true"
      adl-scrollbar-width="0.3">
      

      {IdeaData?.length > 0 && <>
        {Loader ? <SubBouncingLoader /> :
          (IdeaData && IdeaData?.length > 0 && IdeaData?.map((IdeaItem: any, index: any) => {
        
            return (<>
              <Box style={{ marginBottom: "0.5rem", padding: '0 0.5rem' }}>
                <Box sx={{ display: "flex", }}>
                  <Typography style={{ color: "primary.main", fontSize: "12px", fontWeight: "500 !important", marginRight: "0.2rem" }}>{IdeaItem?.idea_id} : </Typography>
                  <Typography style={{ color: "primary.main", fontSize: "12px", }}> {IdeaItem?.cost} INR</Typography>
                </Box>
                <Typography style={{ color: "primary.main", fontSize: "12px", }}>{IdeaItem?.text_parameter && Object.values(IdeaItem["text_parameter"])} </Typography>
              </Box>
            </>)
          }))
     


        }
        {IdeaData && IdeaData?.length == 0 &&
        
          <span className={styles.archIamges}><img style={{ width: "2rem" }} src={NoDocuments} alt="no data" /><span>No Ideas</span></span>
        }
    
    
      </>}
    </div>
  );
}
