import { Box, IconButton, Popover, Typography } from '@mui/material';
import * as React from 'react';
import InfoIcon from '@mui/icons-material/Info';


export interface IProjectInfoDataProps {
    item: any;
    projectList: any;
    projectId: any;
}

export default function ProjectInfoData (props: IProjectInfoDataProps) {
    const { item, projectList,projectId } = props;
    // console.log("item", projectList, item);
    const [InfoList,setInfoList]=React.useState<any>([]);

    //popover code
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorEl(null);
    };    
    const open = Boolean(anchorEl);


    React.useEffect(()=>{
        let tempInfoList=projectList && projectList?.filter((item1:any,index:any)=>{return item1?.id==item})[0]
        setInfoList(tempInfoList)
    },[item])
    

// console.log("InfoList",InfoList)
    

  return (
    <>   
    <IconButton style={{padding:'0',color:'primary.main'}}                        
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onMouseEnter={handlePopoverOpen}
            onMouseLeave={handlePopoverClose}>
        <InfoIcon sx={{fontSize:'1.5rem',color: InfoList?.id == projectId ? 'white' : 'primary.main'}} />
    </IconButton>
    <Popover
        id="mouse-over-popover"
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        sx={{
        pointerEvents: 'none',
        '.MuiPaper-root.MuiPopover-paper':{
            boxShadow: 'rgba(128, 128, 128, 0.2) 0px 3px 3px -2px, rgba(128, 128, 128, 0.14) 0px 3px 4px 0px, rgba(128, 128, 128, 0.12) 0px 1px 8px 0px' 
        }
        }}                              
        anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
        }}
        transformOrigin={{
        vertical: 'top',
        horizontal: 'left',
        }}                             
        disableRestoreFocus                            
    >   
        <Box sx={{ padding: "0.5rem" }}>
            <Box
                sx={{
                    display: "flex",
                    alignItems: "flex-start",
                    justifyContent: "space-between",
                }}>
                <Typography sx={{ fontSize: "1rem", fontWeight: "700",marginRight:'0.5rem' }}>
                    {InfoList?.name}
                    {InfoList?.name !== "General Modules" &&
                    `(${InfoList?.description})`}
                </Typography>
                <Typography
                    sx={{ fontSize: "1rem", whiteSpace: "nowrap" }}
                >
                    {InfoList?.name !== "General Modules" &&
                    `(${InfoList?.product_count} Products)`}
                </Typography>
            </Box>
            <Typography sx={{ display: "flex" }}>
            <Typography sx={{ fontSize: "1rem", width: "8rem" }}>
            Admin
            </Typography>
            <Typography
                sx={{
                fontSize: "1rem",
                display: "flex",
                alignItems: "center",
                }}
            >
                :
                <Typography sx={{ margin: "0 10px" }}>
                {InfoList?.admin}
                </Typography>
            </Typography>
            </Typography>
            <Typography sx={{ display: "flex" }}>
            <Typography sx={{ fontSize: "1rem", width: "8rem" }}>
                Project Admin
            </Typography>
            <Typography
                sx={{
                fontSize: "1rem",
                display: "flex",
                alignItems: "center",
                }}
            >
                :
                <Typography sx={{ margin: "0 10px" }}>
                {InfoList?.project_admin}
                </Typography>
            </Typography>
            </Typography>
            <Typography sx={{ display: "flex" }}>
            <Typography sx={{ fontSize: "1rem", width: "8rem" }}>
                User
            </Typography>
            <Typography
                sx={{
                fontSize: "1rem",
                display: "flex",
                alignItems: "center",
                }}
            >
                :
                <Typography
                sx={{ fontSize: "1rem", margin: "0 10px" }}
                >
                {InfoList?.user?.internal} Internal,
                </Typography>
                <Typography sx={{ fontSize: "1rem" }}>
                {InfoList?.user?.external} External
                </Typography>
            </Typography>
            </Typography>
        </Box> 
        </Popover>
                  
    </>
  );
}
