import React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import ClearIcon from "@mui/icons-material/Clear";
import success from '../../Assets/images/success.png';
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  borderRadius: "0.5rem",
  bgcolor: "background.paper",
  p: 1,
};
interface SuccessModalFileCostingProps {
  isOpen: any;
  onCloseModal: any;
  successMsg: any;
  onCloseParentModel:any;
}

const SuccessModalFileCosting = (props: SuccessModalFileCostingProps) => {
  const { onCloseModal, isOpen, successMsg,onCloseParentModel } = props;

  const Closedhandler = () => {
    onCloseModal();
    onCloseParentModel();
  };
  return (
    <div>
      <Modal
        open={isOpen}
        // onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {/* <ClearIcon
            titleAccess="close"
            onClick={() => Closedhandler()}
            sx={{
              cursor: "pointer",
              marginLeft: "23rem",
              color: CheckboxdataMsg?.type == "error" ? "red" : "green",
            }}
          /> */}
          <Box sx={{ width: '100%', textAlign: 'center' }}>
            <img src={success} style={{ width: '7rem' }} />
            <Typography
              id="modal-modal-title"
              // variant="h6"
              // component="h2"


              sx={{fontSize:'1rem'}}
            >
              {successMsg && successMsg}
            </Typography>
            <Button size="small" onClick={() => Closedhandler()} sx={{
              marginTop: '1rem',
              "&:hover": {
                transform: 'Scale(1.05)',
                transition: 'transform 0.5s ease',
              }
            }} variant="contained">ok</Button>



          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default SuccessModalFileCosting;
