import * as React from "react";
import { Box, TextField } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import styles from "./ArchitectureCreateBody.module.scss";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { ArchitectureProductandSystemTreeDetails } from "./ArchitectureProductandSystemTreeDetails";
import { ArchitectureCreateImageSection } from "./ArchitectureCreateImageSection";
import { getArchImages } from "../../Redux/Actions/architecture.action";
import { API } from "../../api-services";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import { useAppDispatch } from "../../../AppRouter";
import BarChartIcon from "@mui/icons-material/BarChart";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import { useBaseModal } from "../SearchPage/useBaseModal";
import StatisticTable from "./StatisticTable";
import { UserPermissionData } from "./ArchitectureCreateLanding";

export interface IArchitectureCreateBodyProps {

  setSelectedType: any,
  selectedType: any,
  SequenceCount: any,
  setSequenceCount: any,
  recycleBinCounter: any,
  setRecycleBinCounter: any,

}



export function ArchitectureCreateBody(props: IArchitectureCreateBodyProps) {
  const {
    projectId,
    projectname,
    productpage,
    groupId,
    abbr,
    groupName,
    pageNumber,
    topvoult,
    subSystem,
    collapestatus,
    diagramsCount
  } = useRouteParams<any>();
  const { url } = useRouteMatch();
  const [Grouploader, setGroupLoader] = useState<any>(false);
  const [GroupName, setGroupName] = useState<any>(groupName);

  
  const {
    setSelectedType,
    selectedType,
    SequenceCount,
    setSequenceCount,
    recycleBinCounter,
    setRecycleBinCounter,
  } = props
  const history = useHistory();
  // const [selectedType, setSelectedType] = React.useState<any>(1);
  // const [SequenceCount, setSequenceCount] = React.useState<any>(0)
  // const [recycleBinCounter, setRecycleBinCounter] = React.useState<any>(0)
  const dispatch = useAppDispatch();




  const GroupNameHandler = (e: any) => {
    setGroupName(e.target.value);
  };
  React.useEffect(() => {
    setGroupName(groupName);
  }, [groupId, pageNumber]);
  const GroupNameSaveHandler = () => {
    setGroupLoader(true);
    API.put(
      `/circuit_diagram/arch_group/${groupId}/`,

      { name: GroupName, project: projectId, abbreviation: abbr }
    )
      .then((res: any) => {
        dispatch(
          getArchImages({
            project: projectId,

            abbreviation: abbr,
            col_page: productpage,
            page: pageNumber,
            group_id: groupId,
          })
        );
        setSelectedType((prev: any) => prev + 1)
        history.push(
          `/architecturecreateinternal/${projectId}/${projectname}/${abbr}/${topvoult}/${subSystem}/${groupId}/${collapestatus}/${pageNumber}/${productpage}/${GroupName}/${diagramsCount}`
        );
        setGroupLoader(false);
      })
      .catch((err: any) => {
        setGroupLoader(false);
      });
  };

  const [isActive, setIsActive] = React.useState(true);
  const handleClick = () => {
    // 👇️ toggle
    setIsActive((current) => !current);

    // 👇️ or set to true
    // setIsActive(true);
  };
  const tablemodal = useBaseModal();

  const TableHandler = () => {
    tablemodal.open();
  };



  return (
    <div>
      {tablemodal.isOpen && (
        <StatisticTable
          onCloseModal={tablemodal.close}
          isOpen={tablemodal.isOpen}
        />
      )}
      <Box sx={{
        display: "flex", alignItems: "flex-start",
        boxShadow: "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px",
        borderRadius: "10px",
      }}>
        <Box className={isActive ? styles.slideactive : styles.slideinactive}>
          <Box
            sx={{ height: { lg: "82vh", xl: "86vh" }, borderTopLeftRadius: "10px", }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3">
            <ArchitectureProductandSystemTreeDetails
              selectedType={selectedType}
              SequenceCount={SequenceCount}
              setSelectedType={setSelectedType}
              setRecycleBinCounter={setRecycleBinCounter}
              recycleBinCounter={recycleBinCounter}
            />
          </Box>

          <Box className={styles.featuretreefoot}>
            <BarChartIcon sx={{ cursor: "pointer" }} onClick={() => TableHandler()} />
            <Box className={styles.slideIcon}>
              <ArrowCircleLeftIcon
                onClick={handleClick}
                sx={{ cursor: "pointer" }}
              />
            </Box>
          </Box>
          {isActive ? (
            ""
          ) : (
            <>
              <Box className={styles.slideIcon}>
                <ArrowCircleRightIcon
                  sx={{ marginLeft: "-2.5rem", cursor: "pointer", zIndex: 2 }}
                  onClick={handleClick}
                />
              </Box>
            </>
          )}
        </Box>
        <Box
          sx={{ width: "100%", height: { lg: '86vh', xl: "88vh" }, }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3">
          <ArchitectureCreateImageSection
            setRecycleBinCounter={setRecycleBinCounter}
            recycleBinCounter={recycleBinCounter}
            setSequenceCount={setSequenceCount}
            SequenceCount={SequenceCount}
            setselectedType={setSelectedType} selectedType={selectedType} />
        </Box>
      </Box>
    </div>
  );
}
