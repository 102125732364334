import { Box, Grid, Typography } from '@mui/material';
import * as React from 'react';
import PlayCircleOutlineOutlinedIcon from '@mui/icons-material/PlayCircleOutlineOutlined';
//@ts-ignore
import video from "../../Assets/images/bgvideo.mp4";

export interface IVideoGuideProps {
}

export function VideoGuide(props: IVideoGuideProps) {
  return (
    <Box sx={{ padding: '1rem', height: "94vh" }}
      adl-scrollbar="true"
      adl-scrollbar-width="0.3">
      <Box sx={{ marginBottom: '2rem' }}>
        <Typography sx={{ width: '100%', textAlign: 'center', fontSize: '3rem' }}>xcPEP Guide Videos</Typography>
      </Box>
      <Box sx={{ display: 'grid', gridTemplateColumns: { sm: '1fr 1fr', md: '1fr 1fr', lg: '1fr 1fr 1fr 1fr', xl: '1fr 1fr 1fr 1fr' }, alignItems: 'center', columnGap: '2rem', rowGap: '3rem', padding: '0 3rem' }}>
        <Box sx={{
          height: '20rem', width: 'auto', textAlign: 'center', borderRadius: '10px'
        }}>
          <video
            style={{
              height: "16rem",
              width: "100%",
            }}
            
            controls
            src={"https://d3t1ry5dk092x0.cloudfront.net/videos/Login Landing and Search.mp4"}
          >
            <source type="video/mp4"></source>
          </video>
          <Typography sx={{ marginTop: '1rem' }}>Login & Search</Typography>

        </Box>
        <Box sx={{
          height: '20rem', width: 'auto', textAlign: 'center', borderRadius: '10px'
        }}>
          <video
            style={{
              height: "16rem",
              width: "100%",
            }}
            
            controls
            src={"https://d3t1ry5dk092x0.cloudfront.net/videos/View Page.mp4"}


          >
            <source type="video/mp4"></source>
          </video>
          <Typography sx={{ marginTop: '1rem' }}>View Data</Typography>

        </Box>
        <Box sx={{
          height: '20rem', width: 'auto', textAlign: 'center', borderRadius: '10px'
        }}>

          <video
            style={{
              height: "16rem",
              width: "100%",
            }}
            
            controls
            src={"https://d3t1ry5dk092x0.cloudfront.net/videos/Costing.mp4"}

          >
            <source type="video/mp4"></source>
          </video>
          <Typography sx={{ marginTop: '1rem' }}>Costing</Typography>

        </Box>
        <Box sx={{
          height: '20rem', width: 'auto', textAlign: 'center', borderRadius: '10px'
        }}>

          <video
            style={{
              height: "16rem",
              width: "100%",
            }}
            
            controls
            src={"https://d3t1ry5dk092x0.cloudfront.net/videos/Ideas.mp4"}

          >
            <source type="video/mp4"></source>
          </video>
          <Typography sx={{ marginTop: '1rem' }}>Idea</Typography>

        </Box>
        <Box sx={{
          height: '20rem', width: 'auto', textAlign: 'center', borderRadius: '10px'
        }}>

          <video
            style={{
              height: "16rem",
              width: "100%",
            }}            
            controls
            src={"https://d3t1ry5dk092x0.cloudfront.net/videos/Analysis and compare.mp4"}


          >
            <source type="video/mp4"></source>
          </video>
          <Typography sx={{ marginTop: '1rem' }}>Analysis & Compare</Typography>

        </Box>
        <Box sx={{
          height: '20rem', width: 'auto', textAlign: 'center', borderRadius: '10px'
        }}>

          <video
            style={{
              height: "16rem",
              width: "100%",
            }}
            
            controls
            src={"https://d3t1ry5dk092x0.cloudfront.net/videos/Old UI.mp4"}
          >
            <source type="video/mp4"></source>
          </video>
          <Typography sx={{ marginTop: '1rem' }}>Old UI</Typography>

        </Box>
        <Box sx={{
          height: '20rem', width: 'auto', textAlign: 'center', borderRadius: '10px'
        }}>

          <video
            style={{
              height: "16rem",
              width: "100%",
            }}
            
            controls
            src={"https://d3t1ry5dk092x0.cloudfront.net/videos/Full Training Video.mp4"}

          >
            <source type="video/mp4"></source>
          </video>
          <Typography sx={{ marginTop: '1rem' }}>All modules</Typography>

        </Box>
      </Box>
    </Box>
  );
}
