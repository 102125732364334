import { useState } from "react";

// ----------------------------------------------------------------------------------

const useSupplierShowDetailModel = () => {
    const [isOpen, setIsOpen] = useState( false );
    const [aboutLink,setAboutLink] = useState<any>();
    const [contactLink, setContactLink] = useState<any>();
    const [contact, setContact] = useState<any>();
    const [spocName, setSpocName] = useState<any>();
    const [spocEmail, setSpocEmail] = useState<any>();
    const [dialCode,setDialCode] = useState<any>();

    const handleOpen = (aboutLinkGet:any,contactLinkGet:any,contactGet:any,spocNameGet:any,spocEmailGet:any,dialCodeGet:any) => {
        setIsOpen( true );
        setAboutLink(aboutLinkGet);
        setContactLink(contactLinkGet);
        setContact(contactGet);
        setSpocName(spocNameGet);
        setSpocEmail(spocEmailGet);
        setDialCode(dialCodeGet);
    }
    const handleClose = () => {
        setIsOpen( false );
        setAboutLink(undefined);
        setContactLink(undefined);
        setContact(undefined);
        setSpocName(undefined);
        setSpocEmail(undefined);
        setDialCode(undefined);
    }

    return Object.freeze( {
        open: handleOpen,
        close: handleClose,
        isOpen,
        aboutLink,
        contactLink,
        contact,
        spocName,
        spocEmail,
        dialCode
    } );
};


// ----------------------------------------------------------------------------------

export { useSupplierShowDetailModel };