import { Box } from '@mui/material';
import * as React from 'react';
import TreeNode from '../../../utlis/TreeNode';
import HierarchyTree from '../../BOMCreate/HierarchyTree';


export interface ILocakFlowProps {}

export function LocakFlow(props: ILocakFlowProps) {

  return (
    <Box
      sx={{
        height: '94vh',
        width: '100vw',
        overflow: 'auto', // Enable scrolling if content goes beyond the view
        backgroundColor: 'white',
      }}
    >
      <HierarchyTree />
    </Box>
  );
}
