import { Box, IconButton } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styles from './MediaCreateImageSection.module.scss';
import { ID } from '../../utlis/commonInterfaces';
import { useRouteParams } from '../../ui-reusable-component/useRouteParams';
import MediaCreateProductName from './MediaCreateProductName';
import MediaCreateGroupingImage from './MediaCreateGroupingImage';
import { useQueryDispatch } from '../../CustomHook/useQueryDispatch';
import { fetchGroupsByType } from '../../Redux/Actions/groupingMediaCreate.action';
import { LoadingButton } from '@mui/lab';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { API } from '../../api-services';
import { useHistory } from 'react-router-dom';
import { useBaseModal } from '../SearchPage/useBaseModal';
import MediaBulkUploadMediaModel from './MediaBulkUploadMediaModel';

interface Params {
  projectId: any;
  projectName: any;
  mediaType: any;
  abbreviation: any;
  category: any;
  groupId: any;
  productList: any;
  pageNumber: any;
  apiCall: any;
}
export interface IMediaCreateImageSectionProps {
}

export function MediaCreateImageSection(props: IMediaCreateImageSectionProps) {
  const { projectId, projectName, mediaType, abbreviation, category, groupId, productList, pageNumber, apiCall } = useRouteParams<any>();
  const [selectedTopVaultIds, setSelectedTopVaultIds] = useState<ID[]>([]);
  const [nextPrevProductInfo, setNextPrevProductInfo] = useState<any>();
  const [mediaUploadBulkTopVault, setMediaUploadBulkTopVault] = useState<any>();
  const [mediaUploadBulkVault, setMediaUploadBulkVault] = useState<any>();

  const history = useHistory();
  const mediaBulkUploadModel = useBaseModal();

  const topVaultsParam: any =
    productList && typeof productList == "string"
      ? productList?.split(",")?.map(Number)
      : [productList];

  useEffect(() => {
    setSelectedTopVaultIds(topVaultsParam);
    if (groupId != 0 && productList != 0) {
      API.get('/image_repository/media_product_pagenation/', {
        top_vaults: productList,
        image_category: category,
        abbreviation: abbreviation,
        group: groupId,
        media_type: mediaType,
        project: projectId
      }, 0).then((res: any) => {
        setNextPrevProductInfo(res?.data);
      })
    }
  }, [productList]);

  //---------------------API get Group------------------------------

  useQueryDispatch({
    query: {
      action: fetchGroupsByType,
      arg: {
        projectId,
        mediaType,
        category,
        abbreviation,
        get_groups:true,
        get_media_groups:true
      },
      condition:
        selectedTopVaultIds?.length !== 0 && selectedTopVaultIds !== undefined,
      // arg: { abbreviation, topVault },
    },
    dependency: [apiCall, selectedTopVaultIds, abbreviation, category, groupId],
  });


  const renderColumn = (topVaultId: ID) => {
    return (
      <MediaCreateProductName
        key={topVaultId}
        topVaultId={topVaultId}
        selectedTopVaultIds={selectedTopVaultIds}
        mediaBulkUploadModel={mediaBulkUploadModel}
        setMediaUploadBulkTopVault={setMediaUploadBulkTopVault}
        setMediaUploadBulkVault={setMediaUploadBulkVault}
      />
    );
  };

  const handleNextProducts = (topVaultListGet: any) => {
    history.push(
      `/mediacreateinternal/${projectId}/${projectName}/${mediaType}/${abbreviation}/${category}/${groupId}/${topVaultListGet?.toString()}/${1}/${apiCall == "true" ? "false" : "true"}`
    );
  }

  return (
    <>
      {
        mediaBulkUploadModel.isOpen &&
        <MediaBulkUploadMediaModel
          isOpen={mediaBulkUploadModel.isOpen}
          onCloseModal={mediaBulkUploadModel.close}
          mediaUploadBulkTopVault={mediaUploadBulkTopVault}
          mediaUploadBulkVault={mediaUploadBulkVault}
        />
      }
      {groupId != 0 ? <Box>
        <div className={styles.main}
          data-columns={selectedTopVaultIds && selectedTopVaultIds.length}>

          <Box sx={{height:'3rem', cursor: "pointer", display: "flex", alignItems: "center",justifyContent:'center',width:'100%'}}><IconButton size='small'
            sx={{ cursor: "pointer", display: "flex", alignItems: "center", color: "primary.main", backgroundColor: "#007fff14", borderRadius: "50%", width: "1.7rem", height: "1.7rem" }}
            disabled={!(nextPrevProductInfo && nextPrevProductInfo?.previous)} onClick={() => handleNextProducts(nextPrevProductInfo?.pre_top_vault)}>{nextPrevProductInfo && <ChevronLeftIcon
              titleAccess="Next"
              sx={{ fontSize: "1.8rem" }}
            />}</IconButton>
          </Box>
          {selectedTopVaultIds?.map(renderColumn)}
          <Box sx={{height:'3rem', cursor: "pointer", display: "flex", alignItems: "center",justifyContent:'center',width:'100%'}}>
            <IconButton size='small'
              sx={{ cursor: "pointer", display: "flex", alignItems: "center", color: "primary.main", backgroundColor: "#007fff14", borderRadius: "50%", width: "1.7rem", height: "1.7rem" }}
              disabled={!(nextPrevProductInfo && nextPrevProductInfo?.next)} onClick={() => handleNextProducts(nextPrevProductInfo?.next_top_vault)}>{nextPrevProductInfo && <ChevronRightIcon
                titleAccess="Next"
                sx={{ fontSize: "1.8rem" }}
              />}</IconButton>
          </Box>
        </div>
        <MediaCreateGroupingImage selectedTopVaultIds={selectedTopVaultIds} />
      </Box>
        : <Box sx={{ height: '84vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <h2>Please Select Group First</h2>
        </Box>
      }
    </>
  );
}
