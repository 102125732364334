import { createEntityAdapter, createReducer, EntityState } from "@reduxjs/toolkit";
import { ID } from "../../utlis/commonInterfaces";
import { getSubsystemDataMedia, resetSubsystemDataMedia } from "../Actions/subsystem.action";
import { ADMIN } from "../Services/admin.service";
import { RootState } from "../Store/storeConfigurations";

// -----------------------------------------------------------------------------------
// entity adaptor

export interface SubsystemList {
    id: ID;
    abbreviation: string;
    name: string;
    allowed_actions: string;
    
}

const adaptor = createEntityAdapter<SubsystemList>( {
    // selectId: ( subsytem ) => subsytem.id,
    // sortComparer: ( a, b ) => 
} );

// -----------------------------------------------------------------------------------
// interfaces & initialState

type AdditionalFields = {};

type ISubsystemListReducer = EntityState<SubsystemList> & AdditionalFields;

const additionalFields: AdditionalFields = {};

const initialState: ISubsystemListReducer = Object.assign( {}, adaptor.getInitialState(), additionalFields );

// -----------------------------------------------------------------------------------
// reducer

const SubsystemListReducer = createReducer( initialState, builder => {
    builder
    .addCase( getSubsystemDataMedia.fulfilled, ( state, action: any ) => {
        adaptor.setAll( state, action.payload.subsystemlist );
    } )
    .addCase( getSubsystemDataMedia.rejected, ( _state, action ) => {
        // ADMIN.toast.throwError( action.payload, "Could no fetch variants" );
    } );

    builder.addCase( resetSubsystemDataMedia, () => initialState );
    
} );

// -----------------------------------------------------------------------------------
// selectors

const subsystemReducerSelectors= adaptor.getSelectors<RootState>( ( state ) => state.subsystemlist );



// -----------------------------------------------------------------------------------
// exports

export {
    SubsystemListReducer,
    initialState as SubsystemListReducerInit,
    subsystemReducerSelectors
};