import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  TextField,
  Theme,
  useTheme,
  Divider,
  Typography,
  Skeleton,
  Button,
  Autocomplete,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import CountriesCurrency from "../../utlis/CountriesCurrency";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import LoadingButton from "@mui/lab/LoadingButton";
import { API } from "../../api-services";
import { fontSize } from "@mui/system";
import CancelIcon from "@mui/icons-material/Cancel";
import { Padding } from "@mui/icons-material";
import { debounce } from "lodash";
import { useRouteMatch } from "react-router-dom";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import CostingScenerioSystemInsideLanding from "../../ComponentUI/CostingCreateModule/CostingScenario/CostingScenerioTableHierarchy/CostingScenerioSystemInsideLanding";

export interface ICurrencyAddModelProps {
  isOpen: any;
  onCloseModal: any;
  setCount: any;
  rowDataIncoming:any
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
      borderBottomColor: "primary.light",
      "& .MuiInput-underline:before": { borderBottomColor: "#007fff" },
      "& .MuiInput-underline:after": { borderBottomColor: "#007fff" },
    },
  },
};

function getStyles(name: string, countryName: string, theme: Theme) {
  return {
    fontWeight:
      countryName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const validationSchema = yup.object({
  current_currency: yup.mixed().required("Please Select Currency"),
  conversion_factor: yup.number().nullable(),
});

const styled = {
  boxSize: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "33rem",
    height: "fit-content",
    // minHeight: "20rem",
    borderRadius: "0.5rem",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    p: 0,
    outline: 0,
    paddingBottom: "1rem",
  },

  closedIcon: {
    position: "absolute",
    zIndex: "999",
    color: "#1976d2",
    right: "0.2rem",
    top: "0.5rem",
    cursor: "pointer",
  },
  errormsg: {
    fontSize: "0.75rem",
    color: "#d32f2f",
    height: "1rem",
  },
};

const useStyles = makeStyles(() => ({
  /** Changed modalStyle */
  ModalRoot: {
    backgroundColor: "rgba(111, 126, 140, 0.2) !important",
    backdropFilter: "blur(1px) !important",
  },
}));

export function CurrencyAddModel(props: ICurrencyAddModelProps) {
  const { isOpen, onCloseModal, setCount,rowDataIncoming } = props;
  const { revisionId, typeId, databaseName, categoryName, status, search } = useRouteParams<any>();
  const classes = useStyles();
  // const [fetchLoading, setFetchLoading] = useState<any>(false);
  const [applyLoading, setApplyLoading] = useState<any>(false);
  const [fromCurrency, setFromCurrency] = React.useState<any>([]);
  const [onTypingText, setOnTypingText] = useState<any>("");
  const [exchangeLoading, setExchangeLoading] = useState<any>(false);
  const [countryList, setCountryList] = useState<any>();
  const [countryLoading, setCountryLoading] = useState<boolean>(false);
  const [showFetch, setShowFetch] = useState<boolean>(false);

  const { url } = useRouteMatch();

  const getCountryData = () => {
    setCountryLoading(true);
    API.get("/api/db/currency_database/", { revision:revisionId, currency_from_id:rowDataIncoming?.id ? rowDataIncoming?.currency_id : null }, 0)
      .then((res: any) => {
        setCountryList(res.data);
        setCountryLoading(false);
      })
      .catch((err: any) => {
        setCountryLoading(false);
      });
  };

  React.useEffect(() => {
    if(rowDataIncoming?.id){
      setCountryLoading(true);
      API.get("/api/db/currency_database/", { revision:revisionId, currency_from_id:rowDataIncoming?.id ? rowDataIncoming?.currency_id : null }, 0)
      .then((res: any) => {
        setCountryList(res.data?.filter((item:any)=>{
            return item?.currency_name == rowDataIncoming?.countryName;
          }));
        setCountryLoading(false);
      })
      .catch((err: any) => {
        setCountryLoading(false);
      });
    }
    else{
    getCountryData();
  }
  }, []);

  useEffect(() => {
    return () => {
      setShowFetch(false);
      setFromCurrency([]);
      formik.values.current_currency = null;
      formik.values.conversion_factor = null;
      formik.values.conversion_factor_manually = null;
      formik.values.name = null;
      formik.values.currency_id = null;
      formik.values.sourceInformation = null;
      };
  }, []);

  const formik = useFormik({
    initialValues: {
      current_currency: rowDataIncoming?.id ? rowDataIncoming?.currencyFrom : null,
      conversion_factor: null,
      conversion_factor_manually: rowDataIncoming?.id ? rowDataIncoming?.currencyValue : null,
      name: rowDataIncoming?.id ? rowDataIncoming?.name : null,
      sourceInformation: rowDataIncoming?.id ? rowDataIncoming?.sourceInfo : null,
      currency_id: rowDataIncoming?.id ? rowDataIncoming?.currency_id : null,
    },
    validationSchema: validationSchema,
    onSubmit: (values: any) => {
      let newObj = Object.keys(values);
    },
  });

  const fetchCurrencyClicked = () => {
    setExchangeLoading(true);
    setShowFetch(true);
    API.get(
      "/customer/costing_conversion/",
      {
        get_conversion_rate: true,
        from_currency: formik.values.current_currency,
        to_currency: "INR",
        module: "Costing",
      },
      0
    )
      .then((res: any) => {
        formik.values.conversion_factor = res.data[0]?.conversion_factor;
        setFromCurrency(res.data);
        setExchangeLoading(false);
      })
      .catch(() => {
        formik.values.conversion_factor = null;
        setExchangeLoading(false);
      });
  };

  const onCancelClick = () => {
    setFromCurrency([]);
    formik.values.conversion_factor = null;
    formik.values.current_currency = null;
    formik.values.conversion_factor_manually = null;
    onCloseModal();
  };

  const handleChangee = (val: any) => {
    if (val) {
      setOnTypingText(val?.currency_code);
      formik.values.currency_id = val?.id;
      formik.values.current_currency = val?.currency_code;
      formik.values.name = formik.values.name ? formik.values.name : `${val?.currency_name} ( ${val?.currency_code} ) to INR`;
    } else {
      getCountryData();
    }
  };

  const addCurrencyRevision = () => {
    setApplyLoading(true);
    API.post(
      "/api/db/revision_currency/",
      {
       currency_id: formik.values.currency_id, 
       revision:revisionId,
       name: formik.values.name,
       manual_value:formik.values.conversion_factor_manually,
       source_info:formik.values.sourceInformation
      },
      0
    )
      .then((res: any) => {
        setCount((prev:number)=> prev+1);
        onCloseModal();
      })
      .catch(() => {
        setApplyLoading(false);
      });
  };

  const editCurrencyRevision = () => {
    setApplyLoading(true);
    API.put(
      "/api/db/revision_currency/"+ rowDataIncoming?.id +"/",
      {
       currency_id: formik.values.currency_id, 
       revision:revisionId,
       name: formik.values.name,
       manual_value:formik.values.conversion_factor_manually,
       source_info:formik.values.sourceInformation
      },
      0
    )
      .then((res: any) => {
        setCount((prev:number)=> prev+1);
        onCloseModal();
      })
      .catch(() => {
        setApplyLoading(false);
      });
  };

  const getManuallyButtonStatus = () => {
    if (formik.values.current_currency == "" || formik.values.current_currency == null) {
      return true;
    } else if (formik.values.conversion_factor_manually == null || formik.values.conversion_factor_manually == '') {
      return true;
    } 
    else if (formik.values.name == null || formik.values.name == "") {
      return true;
    } 
    else if (formik.values.sourceInformation == null || formik.values.sourceInformation == "") {
      return true;
    } 
    else {
      return false;
    }
  };

  const PartNameHandler = (item: any) => {
    setOnTypingText(item);
    if (item !== "") {
      ExactDebounce(item);
    } else {
      getCountryData();
      formik.values.conversion_factor = null;
      formik.values.current_currency = null;
      formik.values.conversion_factor_manually = null;
      formik.values.name = null;
      formik.values.sourceInformation = null;
      formik.values.currency_id = null;
      setFromCurrency([]);
    }
  };

  const ExactDebounce = React.useRef(
    debounce(async (criteria: any) => {
      setCountryLoading(true);
      setCountryList([]);
      API.get("/api/db/currency_database/", { revision:revisionId,search_key: criteria }, 0)
        .then((res: any) => {
          setCountryList(res.data);
          setCountryLoading(false);
        })
        .catch(() => {
          setCountryLoading(false);
        });

    }, 500)
  ).current;

  return (
    <>
      <Modal // autoFocusdal
        open={isOpen}
        className={classes.ModalRoot}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styled.boxSize}>
          <Box
            sx={{
              alignItem: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}
            >
              <Typography
                style={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  width: "100%",
                  textAlign: "center",
                  marginRight: "-2rem",
                }}
              >
                {rowDataIncoming?.id ? "Edit" : "Add"} Currency in Revision
              </Typography>
                <CancelIcon
                  titleAccess="Close"
                  sx={{ cursor: "pointer", margin: "0.5rem" }}
                  onClick={() => onCancelClick()}
                />
            </Box>
            <Divider
              sx={{
                borderColor: "primary.light",
                margin: "0 1rem",
                marginBottom: "1rem",
              }}
            />
            <form
              style={{ alignItems: "center", padding: "0 1rem" }}
              onSubmit={formik.handleSubmit}
            >
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "10px",
                    marginBottom: "1rem",
                  }}
                >
                  <Typography
                    style={{
                      whiteSpace: "nowrap",
                      fontSize: "1rem",
                      width: "8rem",
                    }}
                  >
                    Currency From :
                  </Typography>

                  <FormControl
                    variant="standard"
                    sx={{
                      m: "1",
                      width: "100%",
                      minWidth: { lg: "120px", xl: "120px" },
                      maxWidth: { lg: "300px", xl: "350px" },
                    }}
                  >
                    {countryList && <Autocomplete
                      defaultValue={rowDataIncoming?.id ? countryList && countryList[0] : formik.values.current_currency}
                      id="controllable-states-demo"
                      autoHighlight
                      filterOptions={(options, state) => options}
                      onChange={(event: any, newValue: string | null) => {
                        handleChangee(newValue);
                      }}
                      //@ts-ignore
                      // isOptionEqualToValue={(option, value) => option.currency_code === value}
                      // // @ts-ignore
                      loading={countryLoading}
                      inputValue={onTypingText}
                      getOptionLabel={(option: any) =>
                        option.currency_code == undefined
                          ? ""
                          : option.currency_code
                      }
                      onInputChange={(event, newInputValue) =>
                        PartNameHandler(newInputValue)
                      }
                      // // @ts-ignore
                      // getOptionLabel={(option) => option?.title == undefined ? "" : option?.title}
                      // @ts-ignore
                      options={countryList && countryList}
                      sx={{ width: "100%", padding: "0 0.5rem" }}
                      placeholder="Search Part Name"
                      renderOption={(props, option) => (
                        <Box
                          component="li"
                          style={{
                            display: "flex",
                            fontSize: "1rem",
                            // marginBottom: "1rem",
                          }}
                          sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                          {...props}
                        >
                          <img
                            //@ts-ignore
                            src={`data:image/png;base64, ${option.flag}`}
                            style={{
                              width: "1.5rem",
                              height: "1.5rem",
                              marginLeft: "1rem",
                              marginRight: "1rem",
                            }}
                          />
                          <div
                            style={{
                              marginLeft: "0.5rem",
                              fontSize: "1rem",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                            //@ts-ignore
                          >{`${option.currency_name}  ${
                            //@ts-ignore
                            option.currency_code
                          } ${
                            //@ts-ignore
                            option.symbol
                              ? //@ts-ignore
                                "(" + option.symbol + ")"
                              : " "
                          }`}</div>
                        </Box>
                      )}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          inputProps={{
                            ...params.inputProps,
                          }}
                          variant="standard"
                          sx={{
                            fontSize: "1rem",
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "primary.light",
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "primary.light",
                            },
                          }}
                        />
                      )}
                    />}
                  </FormControl>
                </Box>
              </Box>
              <Box
                sx={{
                  // display: "flex",
                  columnGap: "1rem",
                  // textAlign: "center",
                  alignItems: "center",
                  padding: "0rem",
                  marginBottom: "1rem",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    columnGap: "10px",
                    textAlign: "left",
                    alignItems: "center",
                    padding: "0rem",
                    marginBottom: "1rem",
                    whiteSpace: "nowrap",
                  }}
                >
                  <Typography style={{ fontSize: "1rem", width: "8rem" }}>
                    Enter Manually :
                  </Typography>
                  <Box sx={{ width: "17rem" }}>
                    <FormControl sx={{ m: 1, width: { lg: 180, xl: 230 } }}>
                      <TextField
                        type="number"
                        variant="standard"
                        id="conversion_factor_manually"
                        placeholder={
                          formik.values.current_currency
                            ? `Enter how much INR in 1 ${formik.values.current_currency}`
                            : ""
                        }
                        name="conversion_factor_manually"
                        onChange={formik.handleChange}
                        value={formik.values.conversion_factor_manually}
                        disabled={formik.values.current_currency == undefined}
                        sx={{
                          fontSize: "1rem",
                          "& .MuiInput-underline:before": {
                            borderBottomColor: "primary.light",
                          },
                          ".MuiInputBase-input": { fontSize: "1rem" },
                        }}
                        inputProps={{
                          step: 'any',
                          min: 0,
                          style: { fontSize: '1rem', }
                        }}
                      />
                    </FormControl>
                  </Box>
                </Box>
              </Box>
              <Box
                sx={{
                  margin: "0.3rem 0",
                  width: "100%",
                  textAlign: "center",
                }}
              >
                <Typography style={{ fontWeight: 500 }}> Reference </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  fontSize: "1rem",
                  alignItems: "center",
                  columnGap: "10px",
                }}
              >
                <Typography style={{ fontSize: "1rem", width: "8rem" }}>
                  Exchange Rate :&nbsp;
                </Typography>
                {formik.values.current_currency != null && showFetch ? (
                  !exchangeLoading ? (
                    fromCurrency[0]?.currency_rate ? (
                      <Box sx={{ fontSize: "1rem", width: "17rem" }}>{`1 ${
                        formik.values.current_currency
                          ? formik.values.current_currency
                          : "--"
                      } = ${
                        fromCurrency[0]?.currency_rate
                          ? fromCurrency[0]?.currency_rate
                          : "--"
                      } INR`}</Box>
                    ) : (
                      <Typography
                        align="center"
                        sx={{ fontSize: "1rem", width: "17rem" }}
                      >
                        Exchange Rate Not Available,Kindly Enter Manually
                      </Typography>
                    )
                  ) : (
                    <Typography
                      align="center"
                      sx={{ fontSize: "1rem", width: "17rem" }}
                    >
                      <Skeleton animation={"pulse"} />
                    </Typography>
                  )
                ) : (
                  <Typography
                    align="center"
                    sx={{
                      fontSize: "1rem",
                      lineHeight: "1.2",
                      width: "17rem",
                    }}
                  >
                    Click Fetch to know the exchange current rate!
                  </Typography>
                )}
                <LoadingButton
                  variant="contained"
                  size="small"
                  type="submit"
                  disabled={formik.values.current_currency == null}
                  sx={{
                    // fontSize:'0.9rem',
                    backgroundColor: "primary.main",
                    color: "primary.light",
                    // width:'6rem',
                    "&:hover": {
                      transform: "scale(1.1)",
                      transition: "transform 0.5s ease",
                    },
                  }}
                  onClick={() => fetchCurrencyClicked()}
                  // loading={fetchLoading}
                >
                  Fetch
                </LoadingButton>
              </Box>
              <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "10px",
                    marginBottom: "1rem",
                    marginTop: "1rem",
                  }}
                >
                  <Typography
                    style={{
                      whiteSpace: "nowrap",
                      fontSize: "1rem",
                      width: "6rem",
                    }}
                  >
                    Name :
                  </Typography>

                  <FormControl
                    variant="standard"
                    sx={{
                      width: "100%",
                      minWidth: { lg: "120px", xl: "120px" },
                      maxWidth: { lg: "300px", xl: "350px" },
                    }}
                  >
                    <TextField
                      variant="standard"
                      id="name"
                      name="name"
                      InputProps={{
                        style: { color: "#007fff", fontSize: "1rem" },
                      }}
                      InputLabelProps={{ style: { fontSize: "1rem" } }}
                      value={formik.values.name}
                      onChange={formik.handleChange}
                      sx={{
                        width: "100%",
                        "& .MuiFormLabel-root": {
                          color: "primary.main",
                        },
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "primary.main",
                        },
                        "& .Mui-focused": {
                          color: "primary.main",
                        },
                        "& .Mui-error": {
                          color: "#d32f2f",
                          borderBottomColor: "#d32f2f",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "primary.light",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "primary.main",
                        },
                      }}
                    />
                  </FormControl>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "10px",
                    marginBottom: "1rem",
                  }}
                >
                  <Typography
                    style={{
                      whiteSpace: "nowrap",
                      fontSize: "1rem",
                      width: "10rem",
                    }}
                  >
                    Source of information :
                  </Typography>

                  <FormControl
                    variant="standard"
                    sx={{
                      width: "100%",
                      minWidth: { lg: "100px", xl: "100px" },
                      maxWidth: { lg: "280px", xl: "330px" },
                    }}
                  >
                    <TextField
                      variant="standard"
                      InputProps={{
                        style: { color: "#007fff", fontSize: "1rem" },
                      }}
                      id="sourceInformation"
                      name="sourceInformation"
                      InputLabelProps={{ style: { fontSize: "1rem" } }}
                      value={formik.values.sourceInformation}
                      onChange={formik.handleChange}
                      sx={{
                        width: "100%",
                        "& .MuiFormLabel-root": {
                          color: "primary.main",
                        },
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "primary.main",
                        },
                        "& .Mui-focused": {
                          color: "primary.main",
                        },
                        "& .Mui-error": {
                          color: "#d32f2f",
                          borderBottomColor: "#d32f2f",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "primary.light",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "primary.main",
                        },
                      }}
                    />
                  </FormControl>
                </Box>
                <Box sx={{float:'right'}}>
                  <LoadingButton
                    variant="contained"
                    size="small"
                    disabled={
                      getManuallyButtonStatus()
                      //  formik.values.conversion_factor== null ? true : false
                    }
                    sx={{
                      // fontSize:'0.9rem',
                      backgroundColor: "primary.main",
                      color: "primary.light",
                      "&:hover": {
                        transform: "scale(1.1)",
                        transition: "transform 0.5s ease",
                      },
                    }}
                    onClick={() => rowDataIncoming?.id ? editCurrencyRevision() : addCurrencyRevision()}
                    // type="submit"
                    loading={applyLoading}
                  >
                    {rowDataIncoming?.id ? "Save" : "Add"}
                  </LoadingButton>
                </Box>
            </form>
          </Box>
        </Box>
      </Modal>
    </>
  );
}
