import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import { useHistory, useRouteMatch } from "react-router-dom";

import {
  Avatar,
  Box,
  Chip,
  FormControl,
  InputLabel,
  Divider,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { API } from "../../api-services";
import {
  RoleList,
  RoleListAll,
  RoleListIdea,
  projectSpecificModules,
} from "../../Constants/projectspecificmodules.constants";
import React from "react";
import { useDispatch } from "react-redux";
import { setPermissionFilter } from "../../Redux/Actions/compareopen.action";
import LoadingButton from "@mui/lab/LoadingButton";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import SaveIcon from "@mui/icons-material/Save";

const styled = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: "80%", xl: "80%" },
  bgcolor: "background.paper",
  borderRadius: "10px",
  // border: '2px solid #000',
  // boxShadow: 24,
  p: 1,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      // width: 250,
    },
  },
};

// @ts-ignore

const useStyles = makeStyles((theme) => ({
  /** Changed modalStyle */
  ModalRoot: {
    backgroundColor: "rgba(111, 126, 140, 0.2) !important",
    backdropFilter: "blur(1px) !important",
  },
}));

export interface AddAllModulePermissionModallProps {
  isOpen: any;
  onCloseModal: any;
  setPermissionApiCallCount: any;
  setCount: any;
  permissionApiCallCount: any;
  moduleId: any;
}

const AddAllModulePermissionModal = (
  props: AddAllModulePermissionModallProps
) => {
  const {
    isOpen,
    onCloseModal,
    setPermissionApiCallCount,
    setCount,
    permissionApiCallCount,
    moduleId,
  } = props;
  // console.log("moduleId", moduleId);
  const { projectId, filterOPtion, routeType } =
    useRouteParams<any>();
  const dispatch = useDispatch();
  const classes = useStyles();
  const { url } = useRouteMatch();
  const history = useHistory();
  const [roles, setRoles] = React.useState<any>([]);
  const [users, setUsers] = React.useState<any>([]);
  const [userList, setUserList] = React.useState<any>([]);
  const [module, setModule] = React.useState<any>([]);
  const [products, setProducts] = React.useState<any>([]);
  const [isSaveLoading, setIsSaveLoading] = React.useState<boolean>(false);
  const [productList, setProductList] = React.useState<any>([]);

  const getProductList = () => {
    API.get("/xcpep/product_list/", { project: projectId }, 0)
      .then((res: any) => {
        setProductList(res.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };
  React.useEffect(() => {
    getProductList();
  }, [projectId]);
  React.useEffect(() => {
    getUserList();
  }, [permissionApiCallCount, moduleId]);

  const getUserList = () => {
    if(filterOPtion === 'all'){
      API.get(
        "/auth/user/",
        {
          is_active: true,
          is_superuser: false,
          sub_module: moduleId,
          project: projectId,
          
        },
        0
      )
        .then((res: any) => {
          setUserList(res.data);
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
    else{
      API.get(
        "/auth/user/",
        {
          is_active: true,
          is_superuser: false,
          sub_module: moduleId,
          project: projectId,
          user_category: filterOPtion
        },
        0
      )
        .then((res: any) => {
          setUserList(res.data);
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
    
  };

  React.useEffect(() => {
    getUserList();
  }, [filterOPtion]);

  let handleOnClose = () => {
    onCloseModal();
  };
  const handleSelectChange = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    setUsers(value);
  };


  React.useEffect(() => {
    setUsers([]);
    setRoles([]);
    setModule([]);
    setProductList([]);
  }, [projectId, moduleId]);

  const handleSubmit = () => {
    API.post(
      "/auth/assign_permission/",
      {
        user_list: users,
        submodule_list:
          moduleId === "Project Specific Modules" ? module : [moduleId],
        products: products,
        action: roles,
      },
      {},
      0
    )
      .then((res: any) => {
        setPermissionApiCallCount((prev: any) => prev + 1);
        setCount((prev: any) => prev + 1);
        setUsers([]);
        setRoles([]);
        setProducts([]);
        setModule([]);
        handleOnClose()
      })
      .catch((err: any) => {
        console.log("permission error: " + err);
      });
  };

  const handleSelectChangeRole = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    setRoles(value);
  };

  const handleSelectChangeModule = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    setModule(value);
  };
  const handleSelectChangeProduct = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    setProducts(value);
  };

  return (
    <div>
      <Modal
        open={isOpen}
        className={classes.ModalRoot}
        onClose={handleOnClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styled}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              {moduleId}
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Table>
            <TableBody>
              <TableRow>               
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    borderBottom: "none",
                    width: "13vw",
                    verticalAlign: "bottom",
                  }}
                >
                  <FormControl fullWidth variant="standard">
                    <InputLabel id="demo-simple-select-label">
                      Select Users
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={users&&users}
                      multiple
                      multiline
                      label="User"
                      onChange={handleSelectChange}
                      size="small"
                      placeholder="Select Users"
                      sx={{
                        "&:before": {
                          borderBottomColor: "primary.main",
                        },
                        '.MuiSvgIcon-root.MuiSelect-icon':{color:'primary.main'},	
                      }}
                      renderValue={(selected) => (
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 0.5,
                          }}
                        >
                          {userList
                            .filter((item: any) => selected?.includes(item?.id))
                            ?.map((value: any) => (
                              <Chip
                                size="small"
                                key={value.id}
                                label={`${value?.first_name} ${value?.last_name}`}
                                onDelete={() =>
                                  setUsers((prev: any) =>
                                    prev?.filter(
                                      (item: any) => value.id !== item
                                    )
                                  )
                                }
                                deleteIcon={
                                  <CancelIcon
                                    onMouseDown={(event) =>
                                      event.stopPropagation()
                                    }
                                  />
                                }
                              />
                            ))}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      {userList &&
                        userList?.map((item: any, index: number) => {
                          return (
                            !users?.includes(item?.id) && (
                              <MenuItem key={index} value={item?.id} sx={{color : item?.user_category == "External" ? "red" : "primary.main"}} >
                                {item?.first_name} {item?.last_name}
                              </MenuItem>
                            )
                          );
                        })}
                    </Select>
                  </FormControl>
                </TableCell>
                {moduleId === "Project Specific Modules" && (
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottom: "none",
                      width: "15vw",
                      verticalAlign: "bottom",
                    }}
                  >
                    <FormControl fullWidth variant="standard">
                      <InputLabel id="demo-simple-select-label">
                        Select Module
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={module&&module}
                        multiple
                        multiline
                        //   label="Module"
                        placeholder="Select Module"
                        onChange={handleSelectChangeModule}
                        size="small"
                        sx={{
                          "&:before": {
                            borderBottomColor: "primary.main",
                          },
                          '.MuiSvgIcon-root.MuiSelect-icon':{color:'primary.main'},	
                        }}
                        renderValue={(selected) => (
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: 0.5,
                            }}
                          >
                            {projectSpecificModules
                              .filter((item: any) => selected?.includes(item))
                              ?.map((value: any) => (
                                <Chip
                                  size="small"
                                  key={value}
                                  label={value}
                                  onDelete={() =>
                                    setModule((prev: any) =>
                                      prev?.filter(
                                        (item: any) => value !== item
                                      )
                                    )
                                  }
                                  deleteIcon={
                                    <CancelIcon
                                      onMouseDown={(event) =>
                                        event.stopPropagation()
                                      }
                                    />
                                  }
                                />
                              ))}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        {projectSpecificModules &&
                          projectSpecificModules?.map(
                            (item: any, index: number) => {
                              return (
                                !module?.includes(item) && (
                                  <MenuItem key={index} value={item}>
                                    {item}
                                  </MenuItem>
                                )
                              );
                            }
                          )}
                      </Select>
                    </FormControl>
                  </TableCell>
                )}
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    borderBottom: "none",
                    width: "24vw",
                    verticalAlign: "bottom",
                  }}
                >
                  <FormControl fullWidth variant="standard">
                    <InputLabel id="demo-simple-select-label">
                      Select Products
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={products&&products}
                      multiple
                      multiline
                      //   label="User"
                      placeholder="Select Products"
                      onChange={handleSelectChangeProduct}
                      size="small"
                      sx={{
                        "&:before": {
                          borderBottomColor: "primary.main",
                        },
                        '.MuiSvgIcon-root.MuiSelect-icon':{color:'primary.main'},	
                      }}
                      renderValue={(selected) => (
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 0.5,
                          }}
                        >
                          {productList
                            .filter((item: any) => selected?.includes(item?.id))
                            ?.map((value: any) => (
                              <Chip
                                size="small"
                                key={value.id}
                                label={value.name}
                                onDelete={() =>
                                  setProducts((prev: any) =>
                                    prev?.filter(
                                      (item: any) => value.id !== item
                                    )
                                  )
                                }
                                deleteIcon={
                                  <CancelIcon
                                    onMouseDown={(event) =>
                                      event.stopPropagation()
                                    }
                                  />
                                }
                              />
                            ))}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      {productList &&
                        productList?.map((item: any, index: number) => {
                          return (
                            !products?.includes(item?.id) && (
                              <MenuItem key={index} value={item?.id}>
                                {item?.name}
                              </MenuItem>
                            )
                          );
                        })}
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    borderBottom: "none",
                    width: "7vw",
                    verticalAlign: "bottom",
                  }}
                >
                  {moduleId == "BOM-Create" ||  moduleId == "Product Inward" ||  moduleId === "Product Outward"|| moduleId === "Media-Create" || moduleId === 'Feature-Create' || moduleId === 'Costing-Create' || moduleId === 'Idea-Create' || moduleId === 'Architecture-Create' || moduleId === 'Costing-Scenario' ? ( <FormControl fullWidth variant="standard">
                      <InputLabel id="demo-simple-select-label">
                        Select Roles
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={roles&&roles}
                        multiple
                        multiline
                        label="Role"
                        placeholder="Select Role"
                        onChange={handleSelectChangeRole}
                        size="small"
                        sx={{
                          "&:before": {
                            borderBottomColor: "primary.main",
                          },
                          '.MuiSvgIcon-root.MuiSelect-icon':{color:'primary.main'},	
                        }}
                        renderValue={(selected) => (
                          <Box
                            sx={{
                              display: "flex",
                              flexWrap: "wrap",
                              gap: 0.5,
                            }}
                          >
                            {RoleListAll.filter((item: any) =>
                              selected?.includes(item?.value)
                            )?.map((value: any) => (
                              <Chip
                                size="small"
                                key={value.value}
                                label={value.value}
                                onDelete={() =>
                                  setRoles((prev: any) =>
                                    prev?.filter(
                                      (item: any) => value.value !== item
                                    )
                                  )
                                }
                                deleteIcon={
                                  <CancelIcon
                                    onMouseDown={(event) =>
                                      event.stopPropagation()
                                    }
                                  />
                                }
                              />
                            ))}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                      >
                        {RoleListAll &&
                          RoleListAll?.map((item: any, index: number) => {
                            return (
                              !roles?.includes(item?.value) && (
                                <MenuItem key={index} value={item?.value}>
                                  {item.text}
                                </MenuItem>
                              )
                            );
                          })}
                      </Select>
                    </FormControl>                   
                  ): moduleId == "Idea-View" ? 
                    ( <FormControl fullWidth variant="standard">
                    <InputLabel id="demo-simple-select-label">
                      Select Roles
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={roles&&roles}
                      multiple
                      multiline
                      label="Role"
                      placeholder="Select Role"
                      onChange={handleSelectChangeRole}
                      size="small"
                      sx={{
                        "&:before": {
                          borderBottomColor: "primary.main",
                        },
                        '.MuiSvgIcon-root.MuiSelect-icon':{color:'primary.main'},	
                      }}
                      renderValue={(selected) => (
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 0.5,
                          }}
                        >
                          {RoleListIdea.filter((item: any) =>
                            selected?.includes(item?.value)
                          )?.map((value: any) => (
                            <Chip
                              size="small"
                              key={value.value}
                              label={value.value}
                              onDelete={() =>
                                setRoles((prev: any) =>
                                  prev?.filter(
                                    (item: any) => value.value !== item
                                  )
                                )
                              }
                              deleteIcon={
                                <CancelIcon
                                  onMouseDown={(event) =>
                                    event.stopPropagation()
                                  }
                                />
                              }
                            />
                          ))}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      {RoleListIdea &&
                        RoleListIdea?.map((item: any, index: number) => {
                          return (
                            !roles?.includes(item?.value) && (
                              <MenuItem key={index} value={item?.value}>
                                {item.text}
                              </MenuItem>
                            )
                          );
                        })}
                    </Select>
                  </FormControl>                   
                )
                  : (
                    <FormControl fullWidth variant="standard">
                    <InputLabel id="demo-simple-select-label">
                      Select Roles
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={roles&&roles}
                      multiple
                      multiline
                      label="Role"
                      placeholder="Select Role"
                      onChange={handleSelectChangeRole}
                      size="small"
                      sx={{
                        "&:before": {
                          borderBottomColor: "primary.main",
                        },
                        '.MuiSvgIcon-root.MuiSelect-icon':{color:'primary.main'},	
                      }}
                      renderValue={(selected) => (
                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 0.5,
                          }}
                        >
                          {RoleList.filter((item: any) =>
                            selected?.includes(item?.value)
                          )?.map((value: any) => (
                            <Chip
                              size="small"
                              key={value.value}
                              label={value.value}
                              onDelete={() =>
                                setRoles((prev: any) =>
                                  prev?.filter(
                                    (item: any) => value.value !== item
                                  )
                                )
                              }
                              deleteIcon={
                                <CancelIcon
                                  onMouseDown={(event) =>
                                    event.stopPropagation()
                                  }
                                />
                              }
                            />
                          ))}
                        </Box>
                      )}
                      MenuProps={MenuProps}
                    >
                      {RoleList &&
                        RoleList?.map((item: any, index: number) => {
                          return (
                            !roles?.includes(item?.value) && (
                              <MenuItem key={index} value={item?.value}>
                                {item.text}
                              </MenuItem>
                            )
                          );
                        })}
                    </Select>
                  </FormControl>
                  )}
                </TableCell>
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem 0.2rem 0",
                    borderBottom: "none",
                    width: "1vw",
                    maxWidth: '1.5vw',
                    textAlign: "right",
                  }}
                >
                  <LoadingButton
                    sx={{ minWidth: "1rem" }}
                    disabled={
                      users?.length === 0 || products?.length === 0
                        ? true
                        : false
                    }
                    onClick={() => handleSubmit()}
                  >
                    <SaveIcon titleAccess="Save" />
                  </LoadingButton>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </Modal>
    </div>
  );
};
export default AddAllModulePermissionModal;
