import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { TopVault } from "../Reducers/topVault.reducer";
import topVaultService from "../Services/topVault.service";
import { ID } from "../../utlis/commonInterfaces";

// -------------------------------------------------------------------------------------
// api actions
// -------------------------------------------------------------------------------------

export interface GetTopVaultsArg {
    projectIds: ID[];
    module?: number;
}


const getTopVaults = createAsyncThunk(
    "topVault/get",
    async ( arg: GetTopVaultsArg, { rejectWithValue } ) => {
        try {
            const { data } = await topVaultService.get(
                arg.projectIds,
                arg.module
            );
            return {
                topVaults: data as TopVault[],
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);


//getTopvoultId

export interface GetTopVaultsIdArg {
    top_vault: any;
}


const getTopvoultId = createAsyncThunk(
    "topVault/getId",
    async ( arg: GetTopVaultsIdArg, { rejectWithValue } ) => {
        try {
            const { data } = await topVaultService.getTopvoultId(
                arg.top_vault
            );
            return {
                topVaults: data as TopVault[],
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

// -------------------------------------------------------------------------------------

export type CreateTopVaultArg = Pick<
    TopVault,
    | "error_acceptance"
    | "manufacturing_year"
    | "name"
    | "oem"
    | "product_id"
    | "type"
    | "project"
>;
const createTopVault = createAsyncThunk(
    "topVault/create",
    async ( arg: CreateTopVaultArg, { rejectWithValue } ) => {
        try {
            const { data } = await topVaultService.create( arg );

            return {
                topVault: data as TopVault,
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

// -------------------------------------------------------------------------------------

export interface EditTopVaultArg {
    id: ID;
    updates: Partial<Omit<TopVault, "id" | "project">>;
}
const editTopVault = createAsyncThunk(
    "topVault/update",
    async ( arg: EditTopVaultArg, { rejectWithValue } ) => {
        try {
            await topVaultService.update( arg );

            return {};
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

export interface LockedVaultArg {
    topVaultId: ID;
    weightlock: boolean;
}
const topVaultLocked = createAsyncThunk(
    "topVault/lockedItem",
    async ( arg: LockedVaultArg, { rejectWithValue } ) => {
        try {
            const { data } = await topVaultService.lockedVault( arg );

            return {
                topVault: data as TopVault,
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

export interface CopyTopVaultArg {
    source: ID,
    productId: any,
    name: any;
}
const copyTopVault = createAsyncThunk(
    "topVault/copied",
    async ( arg: CopyTopVaultArg, { rejectWithValue } ) => {
        try {
            const { data } = await topVaultService.copiedTopVault( arg );

            return {
                topVault: data as TopVault,
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);
// -------------------------------------------------------------------------------------

export interface DeleteTopVaultArg {
    id: ID;
    recycle?: boolean;
}
const deleteTopVault = createAsyncThunk(
    "topVault/delete",
    async ( arg: DeleteTopVaultArg, { rejectWithValue } ) => {
        try {
            await topVaultService.delete( arg );

            return {};
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

export interface DeleteTopVaultCardArg {
    id: ID;

}
const deleteTopcardVault = createAsyncThunk(
    "topVault/deleteCard",
    async ( arg: DeleteTopVaultCardArg, { rejectWithValue } ) => {
        try {
            await topVaultService.deleteCard( arg );

            return {};
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

// -------------------------------------------------------------------------------------
// reducer actions
// -------------------------------------------------------------------------------------

const resetTopVaults = createAction( "topVault/reset" );

// -------------------------------------------------------------------------------------
// exports
// -------------------------------------------------------------------------------------

export {
    getTopVaults,
    createTopVault,
    editTopVault,
    deleteTopVault,
    resetTopVaults, copyTopVault,
    topVaultLocked,
    getTopvoultId,
    deleteTopcardVault
};
