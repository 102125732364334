import React, { useEffect, useState } from 'react';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Badge, Box, Checkbox, IconButton, Popover, Table, TableCell, TableRow, Typography } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { useHistory } from 'react-router-dom';
import { API } from '../api-services';
import useWindowDimensions from '../ui-reusable-component/useWindowDimensions';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid";
import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from '@mui/material/styles';
import { useBaseModal } from '../ComponentUI/SearchPage/useBaseModal';
import { useCalibrationEditModal } from './useCalibrationEditModal';
import CalibrationAddModel from './CalibrationAddModel';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import swal from "sweetalert";
import CalibrationEditModel from './CalibrationEditModal';
import InfoIcon from '@mui/icons-material/Info';
import moment from 'moment';
import recyleBin from "../Assets/images/deletefill.svg";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { useDocumentTitle } from '../ui-reusable-component/useDocumentTitle';
import validated from "../Assets/images/Validated.svg";
import Swal from 'sweetalert2';
import { ADMIN } from '../Redux/Services/admin.service';

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Rows</Box>
    </StyledGridOverlay>
  );
}

const csvOptions: GridCsvExportOptions = {
  fileName: "Instrument Inventory and Calibration Details In CSV", delimiter: ",", utf8WithBom: true
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer style={{ height: "2rem" }}>
    <span style={{ position: "absolute", right: "0rem", fontSize: "1rem", paddingRight: '1rem' }}>
      <GridToolbarColumnsButton />
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);

interface CalibrationLandingPageI { }

const CalibrationLandingPage: React.FC<CalibrationLandingPageI> = (props) => {
  useDocumentTitle( "Instrument Inventory and Calibration" );
  const [dataCalibration, setDataCalibration] = useState<any>();
  const [dataLoader, setDataCalibrationLoader] = useState<boolean>(true);
  const [reloadCalibrationData, setReloadCalibrationData] = useState<boolean>(true);
  const [createdBy, setCreatedBy] = useState<any>();
  const [createdOn, setCreatedOn] = useState<any>();
  const [calibrationPermission, setCalibrationPermission] = useState<any>();

  const { height, width } = useWindowDimensions();
  const addCalibrationData = useBaseModal();
  const editCalibrationData = useCalibrationEditModal();
  const history = useHistory();
  const [recycleCount, setRecycleCount] = useState<any>();
  const [Loader, SetLoader] = useState<any>(false);
  const [CalibrationStatus,setCalibrationStatus]=useState<any>(false)
  const [CalibrationData,setCalibrationData]=useState<any>({})

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>, createdby: any, createdon: any) => {
    setAnchorEl(event.currentTarget);
    setCreatedBy(createdby);
    setCreatedOn(createdon);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
    setCreatedBy(undefined);
    setCreatedOn(undefined);
  };
  const open = Boolean(anchorEl);

  useEffect(() => {
    API.get("/auth/calibration_user_permission/", { action: true }, 0).then((res: any) => {
      setCalibrationPermission(res?.data?.action);
    })
  }, [])

  useEffect(() => {
    setDataCalibrationLoader(true)
    API.get("/xcpep/instrument_calibration/", {}, 0).then((res: any) => {
      setDataCalibration(res?.data);
      setDataCalibrationLoader(false)
    })
  }, [reloadCalibrationData])

  const openAddCalibration: any = () => {
    addCalibrationData.open()
  }

  const viewFile = (file: string) => {
    window.open(file)
  };

  const EditCalibrationPopUp: any = (id: any, instrument_any_description: any, date_of_purchase: any, instrument_life: any, calibration_required: any, last_calibration_date: any, calibration_expiry_date: any, calibration_report: any, instrument_status: any, ownership: any, quantity:any, calibrationImage:any) => {
    editCalibrationData.open(
      id, instrument_any_description, date_of_purchase, instrument_life, calibration_required, last_calibration_date, calibration_expiry_date, calibration_report, instrument_status, ownership, quantity, calibrationImage
    )
  }


  const DetailCalibrationCreator: any = (created_by_fullname: any, created_on: any) => {
    return (<Table>
      <TableRow>
        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Created by:</TableCell>
        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>{created_by_fullname}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Created on:</TableCell>
        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>{created_on}</TableCell>
      </TableRow>
    </Table>)
  }

  // handleDeleteTableData is used for deleting a single row data in Datagrid

  const handleDeleteCalibaration = async (e: React.MouseEvent, id: number) => {
    e.stopPropagation();
    swal({
      title: `Delete Data`,
      text: `Are you sure?`,
      icon: "error",
      //@ts-ignore
      buttons: {
        cancel: "Cancel",
        permanent: {
          text: `Delete Instrument Calibration`,
          value: `deleteCalibration`,
        },
      },
      closeOnClickOutside: false,
      closeOnEsc: false,
      className: "delete-swal",
    }).then(async (type: string) => {
      switch (type) {
        case "deleteCalibration":
          API.delete("/xcpep/instrument_calibration/" + id + "/")
            .then((res: any) => {
              setReloadCalibrationData(!reloadCalibrationData);
              API.get("/xcpep/instrument_calibration/", {
                get_recycle_bin_data_count: true
              }, 0).then((res: any) => {
                SetLoader(false)
                setRecycleCount(res?.data.count)
                // console.log(res?.data.count, "countdata")
              }).catch((err: any) => {
                // console.log("Server error")
                SetLoader(false)
              });
            })
            .catch((err: any) => {
              console.log(err, "Unable to upload file. Server Error");
            });
          break;
        default:
          swal(`Data saved from deleting`, { icon: "error" });
      }
    });
  };

  // columns is a variable where column data is stored. This columns is then used as a prop in dataGrid.

  let columns: any = [
    { field: "sno", headerName: "SNo.", width: width > 1370 ? 50 : width < 900 ? 50 : 50, },
    {
      field: "instrumentCode",
      headerName: "Instrument Code",
      // headerAlign: "center",
      // align: 'right',
      minWidth: width > 1370 ? 100 : width < 900 ? 120 : 100,
      flex: 1,
    },
    { field: "instrumentDescription", headerName: "Instrument Description", minWidth: width > 1370 ? 300 : 200, flex: 1 },
    {
      field: "dateOfPurchase",
      headerName: "Date Of Purchase",
      minWidth: width > 1370 ? 120 : 80,
      flex: 1,
    },
    {
      field: "lifeInYears",
      headerName: "Life In Years",
      minWidth: width > 1370 ? 80 : 40,
      flex: 1,
    },
    {
      field: "quantity",
      headerName: "Quantity",
      minWidth: width > 1370 ? 80 : 40,
      flex: 1,
    },
    {
      field: "calibrationImage", headerName: "Instrument Image", width: width > 1370 ? 130 : width < 1200 ? 130 : 120, renderCell: (cellValues: any) => {
        return (<>
          {cellValues?.row?.calibrationImage == null ? <></> : <VisibilityIcon onClick={() => viewFile(cellValues?.row?.calibrationImage)} titleAccess="view instrument image" sx={{ cursor: 'pointer' }} />}
        </>)
      }
    },
    { field: "calibrationRequired", headerName: "Calibration Required", minWidth: width > 1370 ? 150 : 120, flex: 1 },
    { field: "lastCalibrationDate", headerName: "Last Calibration Date", minWidth: width > 1370 ? 150 : 120, flex: 1 },
    { field: "calibrationExpiryDate", headerName: "Calibration Expiry Date", minWidth: width > 1370 ? 150 : width < 1200 ? 140 : 120, flex: 1 },
    {
      field: "calibrationReport", headerName: "Calibration Report", width: width > 1370 ? 130 : width < 1200 ? 130 : 120, renderCell: (cellValues: any) => {
        return (<>
          {cellValues?.row?.calibrationReport == null ? <></> : <VisibilityIcon onClick={() => viewFile(cellValues?.row?.calibrationReport)} titleAccess="view calibration report" sx={{ cursor: 'pointer' }} />}
        </>)
      }
    },
    { field: "status", headerName: "Status", width: width > 1370 ? 90 : width < 1200 ? 90 : 70, },
    { field: "ownership", headerName: "Ownership", width: width > 1370 ? 100 : width < 1200 ? 100 : 90, },
    {
      field: "actions",
      headerName: "Actions",
      width: width > 1370 ? 105 : width < 1200 ? 80 : 100,
      renderCell: (cellValues: any) => {
        return (<>
          {calibrationPermission && calibrationPermission?.includes('U') && <EditIcon sx={{ cursor: 'pointer' }} titleAccess="view calibration report" onClick={() => EditCalibrationPopUp(cellValues?.row?.id, cellValues?.row?.instrumentDescription, cellValues?.row?.dateOfPurchase, cellValues?.row?.lifeInYears, cellValues?.row?.calibrationRequired, cellValues?.row?.lastCalibrationDate, cellValues?.row?.calibrationExpiryDate, cellValues?.row?.calibrationReport, cellValues?.row?.status, cellValues?.row?.ownership, cellValues?.row?.quantity, cellValues?.row?.calibrationImage)} />
          }
          <InfoIcon
            sx={{ cursor: 'pointer' }}
            aria-owns={open ? 'mouse-over-popover' : undefined}
            aria-haspopup="true"
            onMouseEnter={(e: any) => handlePopoverOpen(e, cellValues?.row?.created_by_fullname, cellValues?.row?.created_on)}
            onMouseLeave={handlePopoverClose}
          />
          <Popover
            id="mouse-over-popover"
            sx={{
              pointerEvents: 'none',
            }}
            elevation={0}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Typography sx={{ width: 'fit-contnet', fontSize: '1rem', p: 1, border: '1px solid', borderColor: 'primay.light', lineHeight: '1.2', borderRadius: '3px' }}>
              {DetailCalibrationCreator(createdBy, createdOn)}
            </Typography>
          </Popover>
          {calibrationPermission && calibrationPermission?.includes('D') && <DeleteIcon sx={{ cursor: 'pointer' }} titleAccess="Delete calibration" onClick={(e: any) => { handleDeleteCalibaration(e, cellValues?.row?.id); }} />
          }
        </>)
      }
    },
  ];

  // In rows which is a array, we are pushing data which is structured in frontEnd so, that we can pass it to dataGrid

  let rows: any = [];

  let dataGridDataGenerator: any =
    dataCalibration &&
    dataCalibration?.map((ideaItem: any, index: any) => {
      let objShared: any = {
        id: ideaItem?.id,
        sno: index + 1,
        instrumentCode: ideaItem?.instrument_code,
        instrumentDescription: ideaItem?.instrument_description,
        dateOfPurchase: ideaItem?.date_of_purchase,
        lifeInYears: ideaItem?.instrument_life,
        quantity: ideaItem?.instrument_quantity,
        calibrationImage: ideaItem?.calibration_image_link,
        calibrationRequired: ideaItem?.calibration_required == true ? 'YES' : 'NO',
        lastCalibrationDate: ideaItem?.last_calibration_date,
        calibrationExpiryDate: ideaItem?.calibration_expiry_date,
        calibrationReport: ideaItem?.calibration_report_link,
        status: ideaItem?.instrument_status,
        ownership: ideaItem?.ownership,
        created_by_fullname: ideaItem?.created_by_fullname,
        created_on: moment(ideaItem?.created_on).format("YYYY-MM-DD")
      };
      rows.push(objShared);
    });

  // dataStructureDataGrid is the total data format which is ready to pass to the dataGrid Props

  let dataStructureDataGrid: any = {
    columns: columns,
    rows: rows,
  };

  useEffect(() => {
    SetLoader(true)
    API.get("/xcpep/instrument_calibration/", {
      get_recycle_bin_data_count: true
    }, 0).then((res: any) => {
      SetLoader(false)
      setRecycleCount(res?.data.count)
      // console.log(res?.data.count, "countdata")
    }).catch((err: any) => {
      // console.log("Server error")
      SetLoader(false)
    })
  }, [])
  useEffect(()=>{

    API.get(`/xcpep/instrument_validation/`).then((res:any)=>{
      setCalibrationStatus(res?.data?.validation)
      setCalibrationData(res?.data)
    }).catch((err:any)=>{
      console.log("Server Error")
    })
  },[])
  const handleChangeValidateStatus = () => {
    API.put(
      `/xcpep/instrument_validation/${CalibrationData?.id}/`,
      {
        validation: !CalibrationData?.validation,
      },
      {},
      0
    )
      .then((res: any) => {
        ADMIN.toast.success("Status updated successfully")
        API.get(`/xcpep/instrument_validation/`).then((res:any)=>{
          setCalibrationStatus(res?.data?.validation)
          setCalibrationData(res?.data)
        }).catch((err:any)=>{
          console.log("Server Error")
        })
      })
      .catch((err: any) => {
        const { data } = err?.response;
        Swal.fire({
          icon: "error",
          html: `<div>
          <br />
          <p style="color:"red">${data[0]}</p>   
           </div>`,
        });
      });
  };
  return (
    <div>
      {addCalibrationData.isOpen &&
        <CalibrationAddModel
          isOpen={addCalibrationData.isOpen}
          onCloseModal={addCalibrationData.close}
          reloadCalibrationData={reloadCalibrationData}
          setReloadCalibrationData={setReloadCalibrationData}
        />}
      {editCalibrationData.isOpen &&
        <CalibrationEditModel
          isOpen={editCalibrationData.isOpen}
          onCloseModal={editCalibrationData.close}
          reloadCalibrationData={reloadCalibrationData}
          setReloadCalibrationData={setReloadCalibrationData}
          id={editCalibrationData.id}
          instrument_Description={editCalibrationData.instrumentDescription}
          date_Of_Purchase={editCalibrationData.dateOfPurchase}
          life_In_Yrs={editCalibrationData.lifeInYrs}
          calibration_Required={editCalibrationData.calibrationRequired}
          last_Calibration_Date={editCalibrationData.lastCalibrationDate}
          calibration_ExpiryDate={editCalibrationData.calibrationExpiryDate}
          calibrationReport={editCalibrationData.calibrationReport}
          status_get={editCalibrationData.status}
          ownership_get={editCalibrationData.ownership}
          quantityGet={editCalibrationData.quantity}
          calibrationImageGet={editCalibrationData.calibrationImage}
        />}
      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', margin: '0 0.5rem' }}>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            sx={{ cursor: "pointer" }}
            title="Go Back"
            onClick={() => history.goBack()}
          >
            <ArrowBackIcon color="primary" />
          </IconButton>
          <Typography sx={{ marginLeft: "1rem", fontSize: '1rem' }}>Instrument Inventory and Calibration Details</Typography>
        </div>
       
        <Typography sx={{ display: 'flex', alignItems: 'center',columnGap:'0.5rem',padding: '0 0.5rem' }}>
        <Checkbox
                    style={{ padding: "3px" }}
                     onChange={() => handleChangeValidateStatus()}
                    checked={CalibrationStatus && CalibrationStatus}
                    value={CalibrationStatus}
                    checkedIcon={
                      <img src={validated} style={{ height: "1.5rem" }} />
                    }
                    title={
                      CalibrationStatus == true ? "Validated" : "Not Validated"
                    }
                    color="success"
                    sx={{
                      "& .MuiSvgIcon-root": { fontSize: 26 },
                      color: "#007fff",
                    }}
                  />
          {calibrationPermission && calibrationPermission?.includes('U') &&
            <IconButton onClick={() => history.push(`/calibrationRecyclebin`)}>
              {recycleCount > 0 ?
                <Badge badgeContent={`${recycleCount && recycleCount}`} color="error">
                  <img src={recyleBin} style={{ height: '1.3rem' }} />
                </Badge> :
                <DeleteOutlinedIcon titleAccess="Recycle bin" sx={{ color: 'primary.main' }} />}
            </IconButton>
          }
          {calibrationPermission && calibrationPermission?.includes('C') && <IconButton size='small' sx={{ color: 'primary.main' }} title="Add Instruments & Details" onClick={openAddCalibration}>
            <AddIcon />
          </IconButton>}
        </Typography>
      </div>
      <Box
        sx={{
          height: { sm: '90vh', md: '89vh', lg: '88vh', xl: '91vh' },
          width: "100%",
          padding: '0 1rem',
        }}>
        <DataGrid
          headerHeight={48}
          rowHeight={48}
          {...dataStructureDataGrid}
          loading={dataLoader}
          disableSelectionOnClick={true}
          components={{
            Toolbar: () => {
              return CustomToolbar();
            },
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          sx={{
            "& .MuiDataGrid-virtualScroller-thumb": {
              backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
              borderRadius: "10px",
            },
            "& .MuiDataGrid-virtualScroller-thumb: hover ": {
              height: '3px',
              backgroundColor: "#045DE9 !important",
            },
            ".MuiDataGrid-virtualScroller-track": {
              backgroundColor: "#e1e1f3 !important",
            },
            "&.MuiDataGrid-root": {
              border: 'none',
            },
            "&.MuiDataGrid-root .MuiDataGrid-cell": {
              borderBottomColor: "primary.light",
            },

          }}
          density={"compact"}
          pageSize={100}
          rowsPerPageOptions={[100]}
        />
      </Box>
    </div>)
}

export default CalibrationLandingPage;