import * as React from 'react';
import Box from '@mui/material/Box';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import MenuIcon from '@mui/icons-material/Menu';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import DownloadIcon from '@mui/icons-material/Download';
import CircularProgress from '@mui/material/CircularProgress';
import ShareIcon from '@mui/icons-material/Share';
import Swal from "sweetalert2";
import swal from "sweetalert";
import { useBaseParamsModal } from '../../CustomHook/useBaseParamsModal';
import { useRouteParams } from '../../ui-reusable-component/useRouteParams';
import EditCategoryModal from './EditCategoryModal';
import { API } from '../../api-services';
import { useHistory } from 'react-router-dom';
import infosvg from '../../Assets/images/svgs/Info_special.svg';
import Popover from '@mui/material/Popover';
import CostCategoryInfoModal from './CostCategoryInfoModal';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import { PermissionData } from './CostingTemplateConfig';
import { useState } from 'react';

export interface ICostingCategoryTopHeaderMenuProps {
    getTreeData: any;
    item: any;
    premissionData?: any;
    ParentCount: any;
    setParentCount: any;
    showMenuItemsDirectly?: boolean
}


interface MenuItemsProps {
    PermissionValue: any;
    item: any;
    LockHandlerAction: any;
    DeleteCategoryHandler: any;
    editCostingCategory: any;
    setDownloadLoading: any;
    downloadLoading: any;
    selectedForDownload: any[];
    setSelectedForDownload: any;
    openInfo: any;
    handlePopoverOpen: any;
    anchorInfoEl: any;
    handlePopoverClose: any;
    infoModal: any;
    handleShare: any;
}

export default function CostingCategoryTopHeaderMenu(props: ICostingCategoryTopHeaderMenuProps) {
    const { projectId, projectname, categoryId, productId, datatype, validationId, categoryGroup } = useRouteParams<any>();
    const { getTreeData, item, premissionData, ParentCount, setParentCount } = props;
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        handlePopoverClose();
        setAnchorEl(event.currentTarget);

    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    // Info popover
    const [anchorInfoEl, setAnchorInfoEl] = React.useState<HTMLElement | null>(null);
    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorInfoEl(event.currentTarget);
    };
    const handlePopoverClose = () => {
        setAnchorInfoEl(null);
    };
    const openInfo = Boolean(anchorInfoEl);



    const history = useHistory();
    const editCostingCategory = useBaseParamsModal();
    // const [ParentCount, setParentCount] = React.useState<any>(0);
    const [dataType, setDataType] = React.useState<any>("");

    const [downloadLoading, setDownloadLoading] = React.useState(false);
    const [selectedForDownload, setSelectedForDownload] = React.useState<any>(0);
    const infoModal = useBaseParamsModal();



    const LockHandlerAction = (item: any, type: any) => {
        if (type == false) {
            API.put(`api/db/costing_category/${item?.id}/`, {
                lock: type,
            }).then((res: any) => {
                setParentCount((prev: any) => prev + 1);
                getTreeData();
                // setLockStatusCounter((prev: any) => prev + 1);
            }).catch((err: any) => {
                const errRes = err?.response.data;
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: `${errRes?.[0]}`,
                })
                console.log("Server error")
            })
        }
        if (type == true) {
            API.put(`api/db/costing_category/${item?.id}/`, {
                lock: type,
            }).then((res: any) => {
                setParentCount((prev: any) => prev + 1);
                getTreeData();
                // setLockStatusCounter((prev: any) => prev + 1);
            }).catch((err: any) => {
                const errRes = err?.response.data;
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: `${errRes?.[0]}`,
                })
                console.log("Server error")
            })
        }
    }


    const DeleteCategoryHandler = (e: any, item: any) => {
        history.push(
            `/costingconfiguration-template/${projectId}/${projectname}/${0}/${categoryGroup}/false/0/0/0`
        );
        e.stopPropagation();

        Swal.fire({
            text: "Are you sure you want to delete?",
            icon: "warning",  // You can change the icon if needed
            showCancelButton: true,
            confirmButtonText: "Yes, Delete",
            cancelButtonText: "Cancel",
            confirmButtonColor: "#d33",  // Optional: change button color
            cancelButtonColor: "#3085d6",
        }).then((result) => {
            if (result.isConfirmed) {
                // Perform the delete action
                API.delete(`/api/db/costing_category/${item?.id}/`, {}, 0)
                    .then((res: any) => {
                        setParentCount((prev: any) => prev + 1);
                        setDataType("");
                        history.push(
                            `/costingconfiguration-template/${projectId}/${projectname}/${0}/${categoryGroup}/false/0/0/0`
                        );
                    })
                    .catch((err: any) => {
                        console.error(err);
                    });
            }
        });
    };

    const handleShare = (e: any, item: any) => {
        e.stopPropagation();
        // setItemList(item)
        // shareModal.open()
    };

    const PermissionValue = React.useContext<any>(PermissionData)

    // console.log('itemitem', item)



    return (
        <>
            {props.showMenuItemsDirectly ?
                (<>
                    <MenuItems
                        PermissionValue={PermissionValue}
                        item={item}
                        LockHandlerAction={LockHandlerAction}
                        DeleteCategoryHandler={DeleteCategoryHandler}
                        editCostingCategory={editCostingCategory}
                        setDownloadLoading={setDownloadLoading}
                        downloadLoading={downloadLoading}
                        selectedForDownload={selectedForDownload}
                        setSelectedForDownload={setSelectedForDownload}
                        openInfo={openInfo}
                        handlePopoverOpen={handlePopoverOpen}
                        anchorInfoEl={anchorInfoEl}
                        handlePopoverClose={handlePopoverClose}
                        infoModal={infoModal}
                        handleShare={handleShare}
                    />
                </>
                )
                :
                <>
                    <Tooltip title="Category options">
                        <IconButton
                            onClick={handleClick}
                            size="small"
                            sx={{ padding: '0', color: item?.id === categoryId ? 'white' : 'primary.main' }}
                            aria-controls={open ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                        >
                            <MenuIcon />
                        </IconButton>
                    </Tooltip>
                    <Menu anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                            elevation: 0, // Set elevation directly here
                            sx: {
                                overflow: 'visible',
                                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                mt: 1.5,
                                '& .MuiAvatar-root': {
                                    width: 32,
                                    height: 32,
                                    ml: -0.5,
                                    mr: 1,
                                },
                                '&::before': {
                                    content: '""',
                                    display: 'block',
                                    position: 'absolute',
                                    top: 0,
                                    left: 14,
                                    width: 10,
                                    height: 10,
                                    bgcolor: 'background.paper',
                                    transform: 'translateY(-50%) rotate(45deg)',
                                    zIndex: 0,
                                },
                            },
                        }}
                        transformOrigin={{ horizontal: 'left', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                    >
                        <MenuItems
                            PermissionValue={PermissionValue}
                            item={item}
                            LockHandlerAction={LockHandlerAction}
                            DeleteCategoryHandler={DeleteCategoryHandler}
                            editCostingCategory={editCostingCategory}
                            setDownloadLoading={setDownloadLoading}
                            downloadLoading={downloadLoading}
                            selectedForDownload={selectedForDownload}
                            setSelectedForDownload={setSelectedForDownload}
                            openInfo={openInfo}
                            handlePopoverOpen={handlePopoverOpen}
                            anchorInfoEl={anchorInfoEl}
                            handlePopoverClose={handlePopoverClose}
                            infoModal={infoModal}
                            handleShare={handleShare}
                        />
                    </Menu>
                </>
            }
            {infoModal.isOpen && (
                <CostCategoryInfoModal
                    isOpen={infoModal.isOpen}
                    onCloseModal={infoModal.close}
                    item={item}
                />
            )}
        </>
    );
}


const MenuItems = ({
    PermissionValue,
    item,
    LockHandlerAction,
    DeleteCategoryHandler,
    editCostingCategory,
    setDownloadLoading,
    downloadLoading,
    selectedForDownload,
    setSelectedForDownload,
    openInfo,
    handlePopoverOpen,
    anchorInfoEl,
    handlePopoverClose,
    infoModal,
    handleShare
}: MenuItemsProps) => {

    return (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem', padding: '0 1rem' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                {PermissionValue?.includes("U") && item?.lock == true ? <LockIcon sx={{ color: 'green', cursor: 'pointer', fontSize:'1.5rem' }}
                    onClick={() => {
                        swal({
                            title: "Unlock?",
                            text: "Are you sure you want to unlock?",
                            buttons: ["Cancel", "Yes"],

                            icon: "error",
                        }).then((confirm) => {
                            if (confirm) {
                                LockHandlerAction(item, false)
                            }
                        });

                    }} /> : <LockOpenIcon sx={{ color: 'red', cursor: 'pointer', fontSize:'1.5rem' }} onClick={() => {
                        swal({
                            title: "Lock?",
                            text: "Are you sure you want to lock?",
                            buttons: ["Cancel", "Yes"],

                            icon: "error",
                        }).then((confirm) => {
                            if (confirm) {
                                LockHandlerAction(item, true)
                            }
                        });
                    }} />}
            </Box>            
            {!item?.mapped_status ? (
                <>
                 {PermissionValue?.includes("D") && <DeleteIcon
                            titleAccess="Delete Category"
                            color="error"
                            sx={{fontSize:'1.5rem', cursor: 'pointer' }}
                            onClick={async (e: any) => {
                                DeleteCategoryHandler(e, item);
                            }}
                        />}
                </>
            ) : (
                <>
                    {PermissionValue?.includes("R") && <InfoIcon sx={{ fontSize: '1.6rem', cursor: 'pointer' }} onClick={() => infoModal.open(item)} />}
                </>
            )}
            {PermissionValue?.includes("R") && <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} title="Download configuration" onClick={(ed: any) => {
                ed.stopPropagation();
                ed.preventDefault();
                setDownloadLoading(true);
                setSelectedForDownload(item?.id);
                API.post(
                    "/config/download_costing_configuration/",
                    { config_category: item?.id },
                    {},
                    0
                )
                    .then((res: any) => {
                        setDownloadLoading(false);
                        window.open(res.data);
                    })
                    .catch((err: any) => {
                        setDownloadLoading(false);
                        Swal.fire({
                            title: "Error!",
                            text: "Error in downloading configuration",
                            icon: "error",
                            confirmButtonText: "Ok",
                        });
                    });
            }}>
                {!downloadLoading ? (
                    <DownloadIcon sx={{ fontSize: "1.7rem", cursor: "pointer" }} />
                ) : (
                    item?.id === selectedForDownload && (
                        <CircularProgress size="small" color="inherit" />
                    )
                )}
            </Box>}
            {PermissionValue?.includes("R") && item?.is_validated && <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} onClick={(e: any) => handleShare(e, item)}>
                <ShareIcon
                    sx={{
                        fontSize: "1.5rem",
                        opacity:
                            PermissionValue &&
                                PermissionValue?.includes("C")
                                ? 1
                                : "0.3",
                        cursor:
                            PermissionValue &&
                                PermissionValue?.includes("C")
                                ? "pointer"
                                : "default",
                    }}
                />
            </Box>}
            {PermissionValue?.includes("R") && <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <IconButton
                    sx={{ padding: '0' }}
                    aria-owns={openInfo ? 'mouse-over-popover' : undefined}
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}>
                    <img src={infosvg} alt="info" style={{ width: "1.3rem", height: "1.2rem", color: 'primary.main' }} />
                </IconButton>
                <Popover
                    id="mouse-over-popover"
                    sx={{ pointerEvents: 'none' }}
                    open={openInfo}
                    anchorEl={anchorInfoEl}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    onClose={handlePopoverClose}
                    disableRestoreFocus
                >
                    <Box sx={{ width: 'fit-content', padding: '0.5rem' }}>
                        <Typography textAlign='center' sx={{ fontSize: "0.85rem", width: '10rem' }}>Category Info</Typography>
                        <Typography
                            sx={{
                                fontSize: "0.85rem",
                                display: "flex",
                                alignItems: "center",
                                padding: "0.3rem",
                                whiteSpace: 'nowrap',
                            }}
                        >
                            <span style={{ width: '6rem' }}>Commodity </span>: {item?.commodity_count}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: "0.85rem",
                                display: "flex",
                                alignItems: "center",
                                padding: "0.3rem",
                                whiteSpace: 'nowrap',
                            }}
                        >
                            <span style={{ width: '6rem' }}>Process </span>: {item?.process_count}
                        </Typography>
                        <Typography
                            sx={{
                                fontSize: "0.85rem",
                                display: "flex",
                                alignItems: "center",
                                padding: "0.3rem",
                                whiteSpace: 'nowrap',
                            }}
                        >
                            <span style={{ width: '6rem' }}>Cost Model</span>: {item?.parameter_count}
                        </Typography>
                    </Box>
                </Popover>
            </Box>}
        </Box>
    )
}