import { TextField } from "@mui/material";
import * as React from "react";
import { API } from "../../api-services";
import { debounce } from "lodash";

export interface IFeatureCostUpdateProps {
  value?: any;
  productId: any;
  itemId: any;
}

export function FeatureCostUpdate(props: IFeatureCostUpdateProps) {
  const { value, productId, itemId } = props;

  return (
    <div>
      <TextField
        placeholder="Feature Cost"
        variant="standard"
        sx={{
          ".MuiInputBase-root.MuiInput-root": {
            fontSize: "1rem !important",
          },
          "& .MuiInput-underline:before": { borderBottomColor: "White" },
          "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
        }}
        disabled={true}
        // onChange={handleonChange}
        value={`${value} ₹`}
      />
    </div>
  );
}
