import { useState } from "react";

// ----------------------------------------------------------------------------------

const useEditOperationRevision = () => {
    const [isOpen, setIsOpen] = useState( false );
    const [rate, setRate] = useState(  );
    const [flag, setFlag] = useState(  );
    const [unit, setUnit] = useState();
    const [id,setId] = useState<any>();

    const handleOpen = (idGet:any,rateGet:any,flagGet:any,unitGet:any) => {
        setIsOpen( true );
        setId(idGet);
        setRate(rateGet);
        setFlag(flagGet);
        setUnit(unitGet);
    }
    const handleClose = () => {
        setIsOpen( false );
        setId(undefined);
        setFlag(undefined);
        setRate(undefined);
        setUnit(undefined);
    }

    return Object.freeze( {
        open: handleOpen,
        close: handleClose,
        isOpen,
        id,
        rate,
        flag,
        unit
    } );
};


// ----------------------------------------------------------------------------------

export { useEditOperationRevision };