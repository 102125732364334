import { Box, IconButton } from '@mui/material';
import * as React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import LaunchIcon from '@mui/icons-material/Launch';
import FlagIcon from '@mui/icons-material/Flag';
import move from "../../../Assets/images/right move.svg";






export interface IOperationActionComponentsProps {
    cellValues?: any;
    rowId?: any;
    handleDelete?: any;
    LockHandler?: any;
    handleListToolRevisionUse?: any;
    handleShareWith?: any;
    history?: any;
    tab?: any;
    year?: any;
    month?: any;
    revisionId?: any;
    typeId?: any;
    categoryName?: any;
    status?: any;
    search?: any;
    useraction?: any;
}

export default function OperationActionComponents (props: IOperationActionComponentsProps) {
    const { cellValues, rowId, handleDelete, LockHandler, handleListToolRevisionUse, handleShareWith, history, tab, year, month, revisionId, typeId, categoryName, status, search, useraction } = props;

    // console.log("cellValues", cellValues); 
    // console.log("rowId", rowId);   
   

    return (
    <Box sx={{display:'flex',flexDirection:'column',alignItems:'center',width:'4rem',}}>
          {rowId?.mapped_status ? <IconButton title='Operation Info' sx={{ color: '#007fff', padding: '0.6rem' }} onClick={() => handleListToolRevisionUse(rowId?.id)}> <InfoIcon /> </IconButton> : ( rowId?.lock_status==false && useraction && useraction?.includes("D")) && <IconButton title='Delete' sx={{ color: '#007fff', padding: '0.6rem' }} onClick={(e: any) => handleDelete(e, rowId?.id)}> <DeleteIcon /> </IconButton>}  
          <IconButton title='Redirect' sx={{ color: '#007fff', padding: '0.6rem' }} onClick={() => history.push(`/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/operationsDB/${categoryName}/${status}/${search}/0`)}> <LaunchIcon /> </IconButton>
          {rowId?.flag !== null && <IconButton title='Flag' sx={{ cursor: 'pointer', color: '#007fff', padding: '0.6rem' }} onClick={() => rowId?.flag != null && window.open(rowId?.flag)}> <FlagIcon sx={{ color: 'green' }} /> </IconButton>}          
          {(rowId?.lock_status==false && useraction && useraction?.includes("U") ) && <IconButton title='Move' sx={{ color: '#007fff', padding: '0.6rem' }} onClick={() => handleShareWith(rowId?.idGetInner, rowId?.operationCode, 'dummy', rowId?.category)}> <img src={move} style={{ height: '1.7rem',padding:'0.2rem' }} /> </IconButton>}
    </Box>
  );
}
