import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Checkbox,
  Chip,
  Divider,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  SelectChangeEvent,
} from "@mui/material";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";

import { useContext, useEffect, useRef, useState } from "react";
import { API } from "../../api-services";
import { useBaseModal } from "../SearchPage/useBaseModal";

import Image from "../../ViewModule/FeatureLandingPage/ArchitectureImageCard";
import NoImgLoader from "../../Assets/images/loading-screen.gif";
import { useHistory } from "react-router-dom";
import CameraIcon from "@mui/icons-material/Camera";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import videoIcon from "../../Assets/images/VideoIcon.svg";
import useWindowDimensions from "../../ui-reusable-component/useWindowDimensions";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import FeatureCreateImageModal from "./FeatureCreateImageModal";
import S3FeatureVideoUploadCreate from "./S3FeatureVideoUploadCreate";
import { useDispatch } from "react-redux";
import RViewerJS from "viewerjs-react";
import LoadingImage from "../../Assets/images/loading-screen.gif";
import LoaderImage from "../../Assets/images/loading-screen-landing.gif";
import { increaseCounter } from "../../Redux/Actions/compareopen.action";
import videoAudio from "../../Assets/images/VideoAudio.svg";
import videoMute from "../../Assets/images/VideoMute.svg";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import FeatureVideoRenderModal from "./FeatureVideoRenderModal";
import VerifiedIcon from "@mui/icons-material/Verified";
import videoAudiogreen from "../../Assets/images/VideoAudio-green.svg";
import videoMutegren from "../../Assets/images/VideoMute-green.svg";


const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  height: "90vh",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

export interface IFeatureMobileImageCaptureModalProps {
  onCloseModal: any;
  isOpen: any;
  response: any;
  closeParent: any;
  getModule: any;
}

export function FeatureMobileImageCaptureModal(
  props: IFeatureMobileImageCaptureModalProps
) {
  const { onCloseModal, isOpen, response, closeParent, getModule } = props;
  const singleVideoRenderModal = useBaseParamsModal();
  const dispatch = useDispatch();
  const history = useHistory();
  const [sequence, setSequence] = useState<any>();
  const [loadingImageId, setLoadingImageId] = React.useState<any>();
  const [loadingVideoId, setLoadingVideoId] = React.useState<any>();
  const {
    projectId,
    projectname,
    type,
    parent,
    child,
    featureId,
    feId,
    pageNumber,
  } = useRouteParams<any>();
  const uploadmodal = useBaseModal();
  const uploadmodalVideo = useBaseModal();
  const [productList, setProductList] = React.useState<any>([]);
  const [availability, setAvailability] = React.useState<any>("No");
  const { height, width } = useWindowDimensions();
  const [isProductListLoading, setIsProductListLoading] =
    React.useState<any>(false);
  const [selectedProduct, setSelectedProduct] = React.useState<any>();
  const [allParamsData, setAllParamsData] = React.useState<any>({});
  const [imageUploadLoading, setImageUploadLoading] =
    React.useState<any>(false);
  const [videoUploadLoading, setVideoUploadLoading] =
    React.useState<any>(false);
  const [selectedId, setSelectedId] = React.useState<any>();
  const [pageNumberr, setPageNumber] = useState(1);
  const [array, setArray] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9, 10]);

  const getProductList = () => {
    setIsProductListLoading(true);
    API.get(
      "/xcpep/feature_product/",
      { project: projectId, abbreviation: response?.abbreviation },
      0
    )
      .then((res) => {
        setProductList(res?.data);
        // setSelectedProduct(res?.data[0]?.id);
        // getFeatureParamsData(res?.data[0]?.id);
        setIsProductListLoading(false);
      })
      .catch((err) => {
        setIsProductListLoading(false);
      });
  };

  const paginateArray = (
    array: any,
    pageNumberr: number,
    pageSize: number
  ): any[] => {
    const startIndex = pageNumberr * pageSize;
    const endIndex = Math.min(array.length, (pageNumberr + 1) * pageSize);
    return array.slice(startIndex, endIndex);
  };
  const isFirstPage = pageNumberr === 1;
  const isLastPage = pageNumberr === Math.round((productList.length - 1) / 1);

  const disablePreviousButton = isFirstPage;
  const disableNextButton = isLastPage;

  const onPreviousPageClick = () => {
    setPageNumber((pageNumberr - 1) % productList.length);
  };

  const onNextPageClick = () => {
    setPageNumber((pageNumberr + 1) % productList.length);
  };

  const paginatedArray = paginateArray(productList, pageNumberr, 1);
  React.useEffect(() => {
    setSelectedProduct(paginatedArray[0]?.id);
  }, [paginatedArray]);

  React.useEffect(() => {
    getProductList();
  }, [projectId]);

  const getFeatureParamsData = (prodId: any) => {
    setAllParamsData([]);
    API.get(
      "/xcpep/feature_details/",
      {
        feature: response?.feature,
        abbreviation: response?.abbreviation,
        feature_instance: prodId,
        page: 1,
      },
      0
    )
      .then((res: any) => {
        setAllParamsData(res.data[0]);
        setAvailability(res.data[0]?.details?.Avalability?.value);
      })
      .catch((err: any) => {});
  };
  const inputElRefPdf = React.useRef<any>();
  const inputElRefVideo = React.useRef<any>();

  const uploadImage = (idd: any, ImageId: any) => {
    // setSelectedId(idd);
    setLoadingImageId(ImageId);
    return inputElRefPdf.current?.click?.();
  };
  const uploadVideo = (idd: any, ImageId: any) => {
    // setSelectedId(idd);
    setLoadingVideoId(ImageId);
    return inputElRefVideo.current?.click?.();
  };
  const handleInputClick = (e: React.MouseEvent) => {
    // clear the prev value on click so that same file can be uploaded again
    (e.target as HTMLInputElement).value = "";
  };
  const handleInputClickVideo = (e: React.MouseEvent) => {
    // clear the prev value on click so that same file can be uploaded again
    (e.target as HTMLInputElement).value = "";
  };

  const UploadFile = (e: any, imageId: any) => {
    FeatureCreateImageModal(
      [e.target.files[0]],
      loadingImageId,
      setImageUploadLoading,
      projectId,
      allParamsData?.id,
      getFeatureParamsData,
      selectedProduct,
      dispatch
    );
  };
  const UploadFileVideo = (e: any, imageId: any, is_mute: any) => {
    // if (selectedId) {
    //   setUploadingImageId(imageId);
    S3FeatureVideoUploadCreate(
      [e.target.files[0]],
      loadingVideoId,
      setVideoUploadLoading,
      projectId,
      allParamsData?.id,
      getFeatureParamsData,
      is_mute,
      selectedProduct,
      dispatch
    );
    // }
  };

  const selectProductFunc = (id: any) => {
    setSelectedProduct(id);
    // getFeatureParamsData(id);
  };
  React.useEffect(() => {
    if (selectedProduct) {
      getFeatureParamsData(selectedProduct);
    }
  }, [selectedProduct]);
  const [age, setAge] = React.useState("");

  const handleChange = (event: SelectChangeEvent) => {
    setAge(event.target.value as string);
  };

  const browseFiles = () => {
    uploadmodal.open();
  };

  const browseVideo = () => {
    uploadmodalVideo.open();
  };

  return (
    <div>
      <Modal
        open={isOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "2.5rem",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <FormControl
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  marginRight: "1rem",
                  width: "10rem",
                }}
              >
                <Checkbox
                  style={{ padding: "3px" }}
                  onClick={() => {
                    if (availability === "No") {
                      API.put(
                        `/xcpep/feature_details/${allParamsData?.id}/`,
                        { is_feature: "Yes" },
                        {},
                        0
                      )
                        .then((res: any) => {
                          setAvailability("Yes");
                        })
                        .catch((err: any) => {});
                    } else {
                      API.put(
                        `/xcpep/feature_details/${allParamsData?.id}/`,
                        { is_feature: "No" },
                        {},
                        0
                      )
                        .then((res: any) => {
                          setAvailability("No");
                        })
                        .catch((err: any) => {});
                    }
                  }}
                  checked={availability === "Yes" ? false : true}
                  title={"Not Available"}
                  color="success"
                  sx={{
                    "& .MuiSvgIcon-root": { fontSize: 22 },
                    color: "#007fff",
                  }}
                />
                <Typography sx={{ fontSize: "1rem" }}>
                  {"Not Available"}
                </Typography>
              </FormControl>
              <CancelIcon
                titleAccess="Close"
                sx={{ cursor: "pointer", margin: "0.5rem" }}
                onClick={() => {
                  onCloseModal();
                  var random: any = Math.floor(Math.random() * 1000000 + 1);
                  dispatch(increaseCounter(random));
                  // path:'/createfeature/:projectId/:projectname/:type/:parent/:child/:featureId/:feId/:pageNumber',
                  history.push(
                    `/createfeature/${projectId}/${projectname}/${type}/${allParamsData?.abbreviation}/${allParamsData?.group}/${allParamsData?.feature_name}/${allParamsData?.feature}/${pageNumber}`
                  );
                  closeParent();
                }}
              />
            </Box>
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              marginBottom: "0.2rem",
              width: "100%",
              justifyContent: "space-between",
              padding: "0.2rem 0",
            }}
          >
            <Box>
              <IconButton
                disabled={disablePreviousButton}
                onClick={onPreviousPageClick}
              >
                <ChevronLeftIcon sx={{ color: "primary.main" }} />
              </IconButton>
            </Box>

            {paginatedArray?.map((item: any) => {
              return (
                <Typography sx={{ fontSize: "1rem" }}>{item?.name}</Typography>
              );
            })}
            <Box>
              <IconButton
                disabled={disableNextButton}
                onClick={onNextPageClick}
              >
                <ChevronRightIcon sx={{ color: "primary.main" }} />
              </IconButton>
            </Box>
          </Box>

          <Box
            sx={{ width: "100%", height: "71vh" }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3"
          >
            {availability === "Yes" ? (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: { xs: "column", sm: "column", md: "row" },
                  rowGap: "1rem",
                  justifyContent: "space-evenly",
                  width: "100%",
                }}
              >
                {allParamsData?.details?.Image?.map((item: any) => {
                  return (
                    <Box>
                     <Typography sx={{fontSize:'1rem',textAlign:'center'}}> { item?.name}</Typography>
                      {item?.is_required && (
                        <sup style={{ color: "red" }}>*</sup>
                      )}
                      {item?.value === "Not Available" ? (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: {
                              xs: "column",
                              sm: "column",
                              md: "row",
                            },
                            alignItems: "center",
                            justifyContent: "space-evenly",
                            width: "100%",
                          }}
                        >
                          {!imageUploadLoading ? (
                            <AddAPhotoIcon
                              sx={{ fontSize: "7rem", cursor: "pointer" }}
                              onClick={() =>
                                uploadImage(allParamsData?.id, item?.id)
                              }
                            />
                          ) : (
                            loadingImageId === item?.id && (
                              <Image
                                placeholderImg={LoadingImage}
                                src={LoaderImage}
                                style={{ width: "5rem", height: "5rem" }}
                              />
                            )
                          )}
                          <input
                            id="PDF Upload"
                            accept="image/*"
                            type="file"
                            capture="user"
                            style={{ display: "none" }}
                            onChange={(e: any) => UploadFile(e, item?.id)}
                            onClick={handleInputClick}
                            ref={inputElRefPdf}
                          />
                        </Box>
                      ) : (
                        <Box>
                          {/* @ts-ignore */}
                          <RViewerJS>
                            <Image
                              placeholderImg={LoadingImage}
                              src={item?.value}
                              style={{
                                objectFit: "contain",
                                width: "100%",
                                height: "11rem",
                              }}
                            />
                          </RViewerJS>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: {
                                xs: "column",
                                sm: "column",
                                md: "row",
                              },
                              alignItems: "center",
                              justifyContent: "space-evenly",
                              width: "100%",
                            }}
                          >
                            <AddAPhotoIcon
                              sx={{ fontSize: "3rem",color:'green', cursor: "pointer" }}
                              onClick={() =>
                                uploadImage(allParamsData?.id, item.id)
                              }
                            />
                          </Box>
                        </Box>
                      )}
                    </Box>
                  );
                })}
                {allParamsData?.details?.Video?.map((item: any) => {
                  return (
                    <Box sx={{ width: "100%" }}>
                      <Typography sx={{fontSize:'1rem',textAlign:'center'}}>{item?.name}</Typography>
                      {item?.is_required && (
                        <sup style={{ color: "red" }}>*</sup>
                      )}
                      {item?.value === "Not Available" ? (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: { xs: "row", sm: "row", md: "row" },
                            alignItems: "center",
                            justifyContent: "space-evenly",
                            width: "100%",
                          }}
                        >
                          {!videoUploadLoading ? (
                            <img
                              src={videoMute}
                              title="without Audio"
                              style={{ width: "5rem", height: "5rem" }}
                              onClick={() =>
                                uploadVideo(allParamsData?.id, item?.id)
                              }
                            />
                          ) : (
                            loadingVideoId === item?.id && (
                              <Image
                                placeholderImg={LoadingImage}
                                src={LoaderImage}
                                style={{ width: "5rem", height: "5rem" }}
                              />
                            )
                          )}
                          <input
                            id="PDF Upload"
                            accept="video/*"
                            type="file"
                            capture="user"
                            style={{ display: "none" }}
                            onChange={(e: any) =>
                              UploadFileVideo(e, item?.id, true)
                            }
                            onClick={handleInputClickVideo}
                            ref={inputElRefVideo}
                          />
                          {!videoUploadLoading && (
                            <img
                              src={videoAudio}
                              title="With Audio"
                              style={{ width: "5rem", height: "5rem" }}
                              onClick={() =>
                                uploadVideo(allParamsData?.id, item?.id)
                              }
                            />
                          )}
                          <input
                            id="PDF Upload"
                            accept="video/*"
                            capture="user"
                            type="file"
                            style={{ display: "none" }}
                            onChange={(e: any) =>
                              UploadFileVideo(e, item?.id, false)
                            }
                            onClick={handleInputClickVideo}
                            ref={inputElRefVideo}
                          />
                          {/* <FileUploadIcon sx={{ fontSize: "7rem" }} /> */}
                        </Box>
                      ) : !videoUploadLoading ? (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: {
                              xs: "column",
                              sm: "column",
                              md: "row",
                            },
                            alignItems: "center",
                            justifyContent: "space-evenly",
                            width: "100%",
                          }}
                        >
                          {item?.thumbnail && (
                            <Image
                              placeholderImg={LoadingImage}
                              src={item?.thumbnail}
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                                cursor: "pointer",
                                maxHeight: width > 1370 ? "15rem" : "13rem",
                              }}
                              onClick={() => singleVideoRenderModal.open(item)}
                            />
                          )}
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              columnGap: "1rem",
                              marginTop: "1rem",
                            }}
                          >
                            {!videoUploadLoading ? (
                              <img
                                src={videoMutegren}
                                title="without Audio"
                                style={{ width: "auto", height: "3rem" }}
                                onClick={() =>
                                  uploadVideo(allParamsData?.id, item.id)
                                }
                              />
                            ) : (
                              loadingVideoId === item?.id && (
                                <Image
                                  placeholderImg={LoadingImage}
                                  src={LoaderImage}
                                  style={{ width: "auto", height: "7rem" }}
                                />
                              )
                            )}
                            <input
                              id="PDF Upload"
                              accept="video/*"
                              type="file"
                              capture="user"
                              style={{ display: "none" }}
                              onChange={(e: any) =>
                                UploadFileVideo(e, item?.id, true)
                              }
                              onClick={handleInputClickVideo}
                              ref={inputElRefVideo}
                            />
                            {!videoUploadLoading && (
                              <img
                                src={videoAudiogreen}
                                title="With Audio"
                                style={{ width: "auto", height: "3rem" }}
                                onClick={() =>
                                  uploadVideo(allParamsData?.id, item?.id)
                                }
                              />
                            )}
                            <input
                              id="PDF Upload"
                              accept="video/*"
                              capture="user"
                              type="file"
                              style={{ display: "none" }}
                              onChange={(e: any) =>
                                UploadFileVideo(e, item?.id, false)
                              }
                              onClick={handleInputClickVideo}
                              ref={inputElRefVideo}
                            />
                          </Box>
                        </Box>
                      ) : (
                        loadingVideoId === item?.id && (
                          <Image
                            placeholderImg={LoadingImage}
                            src={LoaderImage}
                            style={{ width: "auto", height: "3rem" }}
                          />
                        )
                      )}
                    </Box>
                  );
                })}
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: { xs: "column", sm: "column", md: "row" },
                  justifyContent: "space-evenly",
                  width: "100%",
                  height: "71vh",
                }}
                adl-scrollbar="true"
                adl-scrollbar-width="0.3"
              >
                {getModule} does not exist
              </Box>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Button
              disabled={allParamsData?.prev ? false : true}
              onClick={() => {
                setAllParamsData([]);
                API.get(
                  "/xcpep/feature_details/",
                  {
                    feature: allParamsData?.prev,
                    abbreviation: allParamsData?.abbreviation,
                    feature_instance: selectedProduct,
                    page: 1,
                  },
                  0
                )
                  .then((res: any) => {
                    setAllParamsData(res.data[0]);
                    setAvailability(res.data[0]?.details?.Avalability?.value);
                  })
                  .catch((err: any) => {});
              }}
            >
              Previous
            </Button>
            <Button
              disabled={allParamsData?.next !== null ? false : true}
              onClick={() => {
                setAllParamsData([]);
                API.get(
                  "/xcpep/feature_details/",
                  {
                    feature: allParamsData?.next,
                    abbreviation: allParamsData?.abbreviation,
                    feature_instance: selectedProduct,
                    page: 1,
                  },
                  0
                )
                  .then((res: any) => {
                    setAllParamsData(res.data[0]);
                    setAvailability(res.data[0]?.details?.Avalability?.value);
                  })
                  .catch((err: any) => {});
              }}
            >
              Next
            </Button>
          </Box>
        </Box>
      </Modal>
      {singleVideoRenderModal.isOpen && (
        <FeatureVideoRenderModal
          isOpen={singleVideoRenderModal.isOpen}
          onCloseModal={singleVideoRenderModal.close}
          url={singleVideoRenderModal.propsId}
        />
      )}
    </div>
  );
}
