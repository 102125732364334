import React, { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import styles from "./MediaCreateGroupingImage.module.scss";
import { Box, Checkbox, Pagination, Stack } from "@mui/material";
import clsx from "classnames";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { useSelector } from "react-redux";
import { byAbbreviationSelectors } from "../../Redux/Reducers/groupingMediaCreate.reducer";
import MediaCreateMultipleGallery from "./MediaCreateMultipleGallery";
import VerifiedIcon from '@mui/icons-material/Verified';
import useFetch from "../../Core/CustomHooks/useFetch";
import GROUPINGIMAGE from "../../Redux/Services/groupingMediaCreate.service";
import { API } from "../../api-services";
import { useBaseModal } from "../SearchPage/useBaseModal";
import ErrorModalFilesMail from "../ProjectMailModule/ErrorModalFilesMail";
import { MyContext } from "./MediaCreateLanding";
import { isNull } from "lodash";
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import VideoIcon from '../../Assets/images/VideoIcon.svg';
import useWindowDimensions from "../../ui-reusable-component/useWindowDimensions";
import { MediaMobileImageCapture } from "./MediaMobileImageCapture";

interface MediaCreateGroupingImageI {
  selectedTopVaultIds: any;
}

const MediaCreateGroupingImage: React.FC<MediaCreateGroupingImageI> = (
  props
) => {
  const { selectedTopVaultIds } = props;
  const {
    projectId,
    projectName,
    mediaType,
    abbreviation,
    category,
    groupId,
    productList,
    pageNumber,
    apiCall
  } = useRouteParams<any>();
  const [groupFilteredData, setGroupFilteredData] = useState<any>([]);
  const [checkboxcount, setcheckboxcount] = useState<any>(0);
  const [checking, setChecking] = useState<boolean>();
  const [checkbtn, setCheckbtn] = useState<any>(false);
  const [errorMessage, setErrorMessage] = useState<any>();
  const [sendSequence, setSendSequence] = useState<any>();
  
  const { callGroupAPI, setCallGroupAPI, permissionAccess } = useContext(MyContext);
  const history = useHistory();
  const errorValidation = useBaseModal();
  const { width } = useWindowDimensions();
  const MobileImageUploadModal = useBaseModal();

  const GroupNames = useSelector(byAbbreviationSelectors.selectAll);

  const { 1: validationGroup } = useFetch<any>({
    method: GROUPINGIMAGE.getValidationData,
    args: [projectId, abbreviation, category, 5, pageNumber,mediaType,groupId],
    dependencies: [abbreviation, pageNumber, checkboxcount,groupId,productList,projectId,mediaType, apiCall],
  });

  useEffect(() => {
    setGroupFilteredData(GroupNames?.filter((grp: any) => grp?.id == groupId));
  }, [GroupNames]);

  useEffect(() => {
    if (checkbtn == "error") {
      setChecking(false);
    } else {
      setChecking(false);
    }
  }, []);

  const pageNationHandler = (value: any) => {
    history.push(
      `/mediacreateinternal/${projectId}/${projectName}/${mediaType}/${abbreviation}/${category}/${groupId}/${productList}/${value}/true`
    );
  };

  const handleChangeVerification = (e: any, id: any) => {
    API.patch(`image_repository/quality_validation/${id}/`, {
      validation: e.target.checked,
    })
      .then((res: any) => {
        setCheckbtn(e.target.checked);
        setChecking(e.target.checked);
        setCallGroupAPI(!callGroupAPI);
        setcheckboxcount((pre: any) => pre + 1);
      })
      .catch((err: any) => {
        setCheckbtn("error");
        const { data } = err.response;
        setcheckboxcount((pre: any) => pre + 1);
        setErrorMessage(data);
        setChecking(checking);
        errorValidation.open();
      });
  }

  const openPopUpForMobile = (sequence:any) => {
    setSendSequence(sequence);
    MobileImageUploadModal.open();
  }

  const validationCheckbox: any = (image_count: any) => {
    let content: any = [];
    const maxCount: any = 5 * (pageNumber - 1 + 1);

    let count: any = maxCount > image_count ? image_count : maxCount;

    for (let i = 5 * (pageNumber - 1) + 1; i <= count; i++) {

      const filterData =
      validationGroup &&
      validationGroup.filter((item: any) => item.sequence == i);

      content.push(
        <div
          className={styles.checkboxContainer}
          data-columns={selectedTopVaultIds.length}
          key={i}
        >
          {
            !isNull(filterData) && permissionAccess && permissionAccess?.filter((item:any)=>item?.top_vault == 'all')?.[0]?.action?.includes('C') && <Checkbox
              style={{ padding: "3px" }}
              checkedIcon={
                <Box
                  sx={{
                    backgroundColor: "white",
                    borderRadius: "50%",
                    height: "1rem",
                    width: "1rem",
                  }}
                >
                  <VerifiedIcon
                    sx={{
                      color: "#39FF14",
                      position: "relative",
                      margin: "-0.35rem -0.25rem 0",
                    }}
                  />
                </Box>
              }
              checked={filterData && filterData[0]?.validation}
              title="Validated"
              onChange={(e: any) => handleChangeVerification(e, filterData && filterData[0]?.id)}
              color="success"
              sx={{ "& .MuiSvgIcon-root": { fontSize: 22 }, color: "#007fff" }}
            />
          }
          { width <= 600 && (mediaType == "image" ? <AddAPhotoIcon sx={{ fontSize: '1.5rem', cursor: 'pointer', marginTop:'0.5rem' }} onClick={()=>openPopUpForMobile(i)}/> : <img src={VideoIcon} style={{ width: '1.5rem', cursor: 'pointer' }} onClick={()=>openPopUpForMobile(i)}/>)}
        </div>
      );
    }

    return content;
  };

  return (
    <div
      className={styles.accMainDiv}
      adl-scrollbar="true"
      adl-scrollbar-width="0.3"
    >
      { MobileImageUploadModal.isOpen && (
        <MediaMobileImageCapture
            onCloseModal={MobileImageUploadModal.close}
            isOpen={MobileImageUploadModal.open}
            sendNextModelGroupId={groupId}
            categoryGet={category}
            abbreviationGet={abbreviation}
            sequenceSend={sendSequence}
            type={'imageSingleUpload'}
        />
      )}
      <ErrorModalFilesMail
        onCloseModal={errorValidation.close}
        isOpen={errorValidation.isOpen}
        ErrorMsg={errorMessage}
      />
      <div style={{ clear: "both" }}>
        <div
          className={styles.main}
          data-columns={selectedTopVaultIds.length}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3"
        >
          <div className={clsx(styles.column, "product-image-column")}>
            {validationCheckbox(groupFilteredData?.[0]?.max_sequence)}
          </div>
          {selectedTopVaultIds?.map((topVaultId: any) => {
            return (
              <div>
                <MediaCreateMultipleGallery
                  maxCount={(5*pageNumber) < groupFilteredData?.[0]?.max_sequence ? 5 : 5-((pageNumber*5)-groupFilteredData?.[0]?.max_sequence)}
                  topVaultId={topVaultId}
                  selectedTopVaultIds={selectedTopVaultIds}
                  groupPageBoolean={false}
                  pageNumber={pageNumber}
                  rowSize={5}
                  setcheckboxcount={setcheckboxcount}
                  validationvalue={
                    validationGroup && validationGroup
                  }
                />
              </div>
            );
          })}
        </div>
        <Box sx={{ float: "right", margin: "0.5rem" }}>
          <Stack spacing={5} flexDirection="column">
            <Pagination
              page={pageNumber}
              onChange={(event: any, value: any) => {
                pageNationHandler(value);
              }}              
              count={Math.ceil(groupFilteredData?.[0]?.max_sequence / 5)}
              size="small"
            />
          </Stack>
        </Box>
      </div>
    </div>
  );
};

export default MediaCreateGroupingImage;
