import * as React from 'react';
import {Box} from '@mui/material'
import DataBaseLandingPage from '../DataBaseLandingPage';
import { useRouteParams } from '../../ui-reusable-component/useRouteParams';
import CommodityandProcess from './CommodityAndProcess';
import { useDocumentTitle } from '../../ui-reusable-component/useDocumentTitle';

export interface ICommodityProcessProps {
}

export function CommodityProcess (props: ICommodityProcessProps) {
  useDocumentTitle( "Commodity and Process" );
  return (
    <Box>
       {/* <DataBaseLandingPage projectId={projectId} module={moduleId}/> */}
       <Box>
        <CommodityandProcess/>
       </Box>
    </Box>
  );
}
