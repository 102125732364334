import { Box, FormControl, MenuItem, Select } from "@mui/material";

import React, { useEffect } from "react";
import { BOMPCBOptions } from "./calculator.constant";

function RenderValueColumnItemPCBCalcView({
  input,
  getCalculatorColumns,
  namesAndParams,
}: any) {
  useEffect(() => {
    if (input?.parameter) {
      getCalculatorColumns(input?.parameter);
    }
  }, [input]);


  return (
    <Box sx={{ display: "flex", alignItems: "center", columnGap: "0.5rem" }}>
      <FormControl fullWidth>
        
          {(namesAndParams &&
            namesAndParams?.filter(
              (item: any) => item?.id == input.table_column_parameter
            )[0]?.name) ||
          ""}
      </FormControl>

      {input.table_column_parameter &&
        namesAndParams &&
        namesAndParams?.filter(
          (item: any) => item?.id == input.table_column_parameter
        )[0]?.name === "Item Name" && (
          <FormControl fullWidth>
            {
              input?.pcb_item_name
            }
          </FormControl>
        )}

      {input?.pcb_item_name &&
        namesAndParams &&
        namesAndParams?.filter(
          (item: any) => item?.id == input.table_column_parameter
        )[0]?.name === "Item Name" && (
          <FormControl fullWidth>
            {
              BOMPCBOptions?.filter(
                (item: any) => item.value == input?.pcb_item_type
              )[0]?.title || ""
            }
          </FormControl>
        )}

      {input.table_column_parameter &&
        namesAndParams &&
        namesAndParams?.filter(
          (item: any) => item?.id == input.table_column_parameter
        )[0]?.name !== "Item Name" && (
          <FormControl fullWidth>
            {
              input?.table_element_type || ""
            }
          </FormControl>
        )}
    </Box>
  );
}

export default RenderValueColumnItemPCBCalcView;
