import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  Box,
  Button,
  Chip,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { API } from "../../api-services";
import { RoleListAll } from "../../Constants/projectspecificmodules.constants";
import React from "react";
import { useDispatch } from "react-redux";
import { setPermissionFilter } from "../../Redux/Actions/compareopen.action";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";

const styled = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: '50%', xl: "35%" },
  bgcolor: "background.paper",
  borderRadius: '10px',
  // border: '2px solid #000',
  // boxShadow: 24,
  p: 1,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// @ts-ignore

const useStyles = makeStyles((theme) => ({
  /** Changed modalStyle */
  ModalRoot: {
    backgroundColor: "rgba(111, 126, 140, 0.2) !important",
    backdropFilter: "blur(1px) !important",
  },
}));

export interface HandoverModalProps {
  isOpen: any;
  onCloseModal: any;
}

const FilterModal = (props: HandoverModalProps) => {
  const { isOpen, onCloseModal } = props;
  const { projectId, moduleId, filterOPtion, routeType } = useRouteParams<any>();
  const dispatch = useDispatch()
  const classes = useStyles();
  const { url } = useRouteMatch();
  const history = useHistory();
  const [roles, setRoles] = React.useState<any>([]);

  let handleOnClose = () => {
    onCloseModal();
  };
  const handleSelectChangeRole = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    setRoles(value);
  };

  const applyClicked = () => {
    dispatch(setPermissionFilter(roles))
    // history.push(`/user-permissions/${projectId}/0/${filterOPtion}/${routeType}`)
  }

  return (
    <div>
      <Modal
        open={isOpen}
        className={classes.ModalRoot}
        onClose={handleOnClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styled}>
        <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: '-2rem'
              }}
            >
              Filter
            </Typography>
            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{padding:'1rem'}}>
            <FormControl fullWidth variant="standard">            
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={roles&&roles}
                multiple
                multiline
                label="Role"
                placeholder="Select Role"
                onChange={handleSelectChangeRole}
                size="small"
                sx={{
                  "&:before": {
                    borderBottomColor: "primary.main",
                  },
                  '.MuiSvgIcon-root.MuiSelect-icon':{color:'primary.main'},	
                }}
                renderValue={(selected) => (
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 0.5,
                    }}
                  >
                    {RoleListAll.filter((item: any) =>
                      selected?.includes(item?.value)
                    )?.map((value: any) => (
                      <Chip
                        key={value.value}
                        label={value.value}
                        onDelete={() => {
                          if (roles?.length > 1) {
                            setRoles((prev: any) =>
                              prev?.filter((item: any) => value.value !== item)
                            );
                          }
                        }}
                        deleteIcon={
                          <CancelIcon
                            onMouseDown={(event) => event.stopPropagation()}
                          />
                        }
                      />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {RoleListAll &&
                  RoleListAll?.map((item: any, index: number) => {
                    return (
                      <MenuItem key={index} value={item?.value}>
                        {item.text}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            <Box sx={{display:'flex',alignItems:'center',columnGap:'1rem',paddingTop:'1rem',justifyContent:'flex-end'}}>
              <Button variant="contained" onClick={() => applyClicked()}>Apply</Button>
              <Button variant="contained" onClick={() => dispatch(setPermissionFilter([]))}>Clear</Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
export default FilterModal;
