// import { ISupplier, ISupplierPlant } from "app/Factories/SupplierFactory";
import { API } from "./index.service";
import { ID } from "../../utlis/commonInterfaces";

const url = "api/db/supplier/";
const plantUrl = '/api/db/supplier_plant/';

class SupplierDB {
    getSuppliers = ( oem: boolean, page?: number ) => {
        return API.get( url, {
            is_oem: oem,
            key: page,
        } ,0);
    };
    getSuppliersById = ( oem: boolean, page?: number, id?: number ) => {
        return API.get( url, {
            is_oem: oem,
            key: page,
            mfg_type: id
        } , 0);
    };
    getSheetById = ( oem: boolean, id?: number ) => {
        return API.get( url, {
            is_oem: oem,
            mfg_type: id
        },0 );
    };
    searchSuppliers = ( search: string, meta: Record<string, any> ) => {
        return API.get( url, Object.assign( {}, { is_oem: false, search }, meta ) );
    };
    getSpecificSupplier = ( supplierId: ID ) => {
        return API.get( url, { id: supplierId } );
    };
    createSupplier = ( supplier: any, oem: boolean ) => {
        return API.post( url, { ...supplier, is_oem: oem } );
    };
    createSupplierLibrary = ( supplier: any, oem: boolean, id: any ) => {
        return API.post( url, { ...supplier, is_oem: oem, mfg_type: [parseInt( id )] } );
    };
    updateSupplier = ( supplierId: number, updates: any ) => {
        return API.put( url + supplierId + "/", updates );
    };
    updateSupplierLibrary = ( supplierId: number, updates: any, id: number ) => {
        return API.put( url + supplierId + "/", { ...updates, mfg_type: id } );
    };
    updateSuppliermfg = ( supplierId: number, updates: any ) => {
        return API.put( url + supplierId + "/", { other_mfg_process: updates } );
    };
    deleteSupplier = ( supplierId: number ) => {
        return API.delete( url + supplierId + "/" );
    };
    //supplier Plant
    getSupplierPlant = ( supplierId: ID ) => {
        return API.get( plantUrl, {
            supplier: supplierId
        } );
    };

    supplierPlantCreate = ( supplierId: ID, supplier: any ) => {
        return API.post( plantUrl, { ...supplier, supplier: supplierId } );
    };


    supplierPlantUpdate = ( supplierId: ID, plantId: ID, updates: any ) => {
        return API.put( plantUrl + plantId + "/", {
            ...updates
            , supplier: supplierId
        } );
    };
    supplierPlantDelete = ( id: ID ) => {
        return API.delete( plantUrl + id + "/" );
    };


    applyFilters = ( {
        oem,
        filters,
        pageSize = 30,
        page,
        mfg_type
    }: {
        oem: boolean;
        page?: number;
        pageSize?: number;
        mfg_type: number;
        filters: {
            [key: string]: {
                key: string;
                filter: string;
                value: string | number;
            };
        };
    } ) => {
        return API.post( "/api/db/supplier_database_filter/", filters, {
            page: typeof page === "number" ? page + 1 : undefined,
            pageSize,
            filter: oem ? "oem" : "supplier",
            mfg_type
        } );
    };
}

const SUPPLIERDB = new SupplierDB();

export default SUPPLIERDB;
