import { intersection, isNumber } from "lodash";
import * as yup from "yup";

const EXP = {
    string(lhs: string, rhs: string): StringExpression {
        return new StringExpression(lhs, rhs);
    },
    number(lhs: number, rhs: number | string): NumberExpression {
        return new NumberExpression(lhs, rhs);
    },
    boolean(A: boolean): LogicalOperator {
        return new LogicalOperator(A);
    },
};

export class AnyExpression {
    lhs: string | number;
    rhs: string | number;

    constructor(lhs: string | number, rhs: string | number) {
        this.lhs = lhs;
        this.rhs = rhs;
    }

    // ------------ string -----------------

    matches() {
        const regex = new RegExp(`^${(this.rhs as string).trim()}$`);
        return regex.test((this.lhs as string).trim()); //!!( this.lhs as string ).match( this.rhs as string );
    }

    includes() {
        return (this.lhs as string)
            .trim()
            .includes((this.rhs as string).trim());
    }

    in() {
        return (this.rhs as string)
            .trim()
            .includes((this.lhs as string).trim());
    }

    noMatch () {
        //we are converting into stings to compare
        const regex = new RegExp( `^${( this.rhs.toString() as string ).trim()}$` );
        return !regex.test( ( this.lhs.toString() as string ).trim() ); //!( this.lhs as string ).match( this.rhs as string );
    }

    // -------------- number -------------

    greaterThan() {
        return this.lhs > this.rhs;
    }

    lessThan() {
        return this.lhs < this.rhs;
    }

    greaterThanOrEqual() {
        return this.lhs >= this.rhs;
    }

    lessThanOrEqual() {
        return this.lhs <= this.rhs;
    }

    equality() {
        return this.lhs === this.rhs;
    }

    unEquality() {
        return this.lhs !== this.rhs;
    }

    // ------------- both ---------------

    oneOf() {
        if (!this.rhs) return;

        if (typeof this.rhs === "string" && typeof this.lhs === "string")
            return this.rhs
                .split(",")
                .map(s => s.trim())
                .filter(s => s.length > 0)
                .includes(this.lhs.trim());
        else if (Array.isArray(this.rhs) && typeof this.lhs === "string")
            return this.rhs.includes(this.lhs.trim());
        else if (Array.isArray(this.rhs) && typeof this.lhs === "number")
            return this.rhs
                .map(n => parseFloat(n))
                .filter(Boolean)
                .includes(this.lhs);
        else if (Array.isArray(this.rhs) && Array.isArray(this.lhs))
            return intersection(this.rhs, this.lhs).length > 0;
        return (this.rhs as string)
            .split(",")
            .map(n => parseFloat(n))
            .filter(Boolean)
            .includes(this.lhs as number);
    }

    notOneOf() {
        return !this.oneOf();
    }
}

export type FilterType =
    | "matches"
    | "includes"
    | "in"
    | "noMatch"
    | "greaterThan"
    | "lessThan"
    | "greaterThanOrEqual"
    | "lessThanOrEqual"
    | "equality"
    | "unEquality"
    | "oneOf"
    | "notOneOf"
    | "startsWith"
    | "endsWith"
    | "on"
    | "before"
    | "onOrBefore"
    | "after"
    | "onOrAfter"
    | null;
const filterTypes = [
    "matches",
    "includes",
    "in",
    "noMatch",
    "greaterThan",
    "lessThan",
    "greaterThanOrEqual",
    "lessThanOrEqual",
    "equality",
    "unEquality",
    "oneOf",
    "notOneOf",
    "startsWith",
    "endsWith",
    "on",
    "before",
    "onOrBefore",
    "after",
    "onOrAfter",
];
const filterSchema = yup.object().shape({
    type: yup.string().oneOf(filterTypes).required(),
    value: yup.string().required(),
});

export class Filter {
    private _type: FilterType;
    private _value: string | number;
    key: string;

    constructor(key?: string, type?: FilterType, value?: string | number) {
        this._type = type ?? null;
        this._value = value ?? "";
        this.key = key ?? "";
        Object.setPrototypeOf(this, Filter.prototype);
    }

    set value(value: string | number) {
        this._value = value;
    }

    get value() {
        return this._value;
    }

    set type(type: FilterType) {
        this._type = type;
    }

    get type() {
        return this._type;
    }

    get isValidFilter() {
        return filterSchema.isValidSync({
            type: this._type,
            value: this._value,
        });
    }

    // ------------ string -----------------

    matches(lhs: string | number) {
        const regex = new RegExp(
            `^${isNumber(this._value)
                ? this._value.toString()
                : this._value.trim()
            }$`
        );
        return regex.test(isNumber(lhs) ? lhs.toString() : lhs.trim()); //!!( this.value as string ).match( this._value as string );
    }

    includes(lhs: string | number) {
        return (lhs as string).trim().includes((this._value as string).trim());
    }

    in(lhs: string | number) {
        return (this._value as string).trim().includes((lhs as string).trim());
    }

    noMatch(lhs: string | number) {
        return !this.matches(lhs);
    }

    startsWith(lhs: string | number) {
        return (this._value as string).startsWith(lhs as string);
    }

    endsWith(lhs: string | number) {
        return (this._value as string).endsWith(lhs as string);
    }

    // -------------- number -------------

    greaterThan(lhs: string | number) {
        return lhs > this._value;
    }

    lessThan(lhs: string | number) {
        return lhs < this._value;
    }

    greaterThanOrEqual(lhs: string | number) {
        return lhs >= this._value;
    }

    lessThanOrEqual(lhs: string | number) {
        return lhs <= this._value;
    }

    equality(lhs: string | number) {
        return lhs === this._value;
    }

    unEquality(lhs: string | number) {
        return lhs !== this._value;
    }

    // ------------- both ---------------

    oneOf(lhs: string | number) {
        if (typeof this._value === "string" && typeof lhs === "string")
            return this._value
                .split(",")
                .map(s => s.trim())
                .filter(s => s.length > 0)
                .includes(lhs.trim());
        else if (Array.isArray(this._value) && typeof lhs === "string")
            return this._value.includes(lhs.trim());
        else if (Array.isArray(this._value) && typeof lhs === "number")
            return this._value
                .map(n => parseFloat(n))
                .filter(Boolean)
                .includes(lhs);
        return (this._value as string)
            .split(",")
            .map(n => parseFloat(n))
            .filter(Boolean)
            .includes(lhs as number);
    }

    notOneOf(lhs: string | number) {
        return !this.oneOf(lhs);
    }

    // ---------------------------------------------------------------------------

    // date operations

    on(lhs: string | number) {
        const _rhs = new Date(this._value).getTime();
        const _lhs = new Date(lhs).getTime();
        return _lhs === _rhs;
    }

    before(lhs: string | number) {
        const _rhs = new Date(this._value);
        const _lhs = new Date(lhs);
        return _lhs < _rhs;
    }

    after(lhs: string | number) {
        const _rhs = new Date(this._value);
        const _lhs = new Date(lhs);
        return _lhs > _rhs;
    }

    onOrBefore(lhs: string | number) {
        const _rhs = new Date(this._value);
        const _lhs = new Date(lhs);
        return _lhs <= _rhs;
    }

    onOrAfter(lhs: string | number) {
        const _rhs = new Date(this._value);
        const _lhs = new Date(lhs);
        return _lhs >= _rhs;
    }

    // ---------------------------------------------------------------------------

    transformForApi() {
        return {
            key: this.key,
            filter: this._type,
            value: this._value,
        };
    }

    apply(lhs: string | number) {
        return this._type && this[this._type](lhs);
    }
}

class StringExpression {
    lhs: string;
    rhs: string;

    constructor(lhs: string, rhs: string) {
        this.lhs = lhs;
        this.rhs = rhs;
    }

    matches() {
        const regex = new RegExp(`^${(this.rhs as string).trim()}$`);
        return regex.test((this.lhs as string).trim()); //!!( this.lhs as string ).match( this.rhs as string );
    }

    includes() {
        return (this.lhs as string)
            .trim()
            .includes((this.rhs as string).trim());
    }

    in() {
        return (this.rhs as string)
            .trim()
            .includes((this.lhs as string).trim());
    }

    noMatch() {
        const regex = new RegExp(`^${(this.rhs as string).trim()}$`);
        return !regex.test((this.lhs as string).trim()); //!( this.lhs as string ).match( this.rhs as string );
    }

    oneOf() {
        return this.rhs
            .split(",")
            .map(s => s.trim())
            .filter(s => s.length > 0)
            .includes(this.lhs);
    }

    notOneOf() {
        return !this.rhs
            .split(",")
            .map(s => s.trim())
            .filter(s => s.length > 0)
            .includes(this.lhs);
    }
}

class NumberExpression {
    lhs: number;
    rhs: number | string;

    constructor(lhs: number, rhs: number | string) {
        this.lhs = lhs;
        this.rhs = rhs;
    }
    
    greaterThan() {
        //@ts-ignore
        return this.lhs > this.rhs;
    }

    lessThan() {
        //@ts-ignore
        return this.lhs < this.rhs;
    }

    greaterThanOrEqual() {
        //@ts-ignore
        return this.lhs >= this.rhs;
    }

    lessThanOrEqual() {
        //@ts-ignore
        return this.lhs <= this.rhs;
    }

    equality() {
        return this.lhs === this.rhs;
    }

    unEquality() {
        return this.lhs !== this.rhs;
    }

    oneOf() {
        return (this.rhs as string)
            .split(",")
            .map(n => parseFloat(n))
            .filter(Boolean)
            .includes(this.lhs);
    }

    notOneof() {
        return !(this.rhs as string)
            .split(",")
            .map(n => parseFloat(n))
            .filter(Boolean)
            .includes(this.lhs);
    }
}

class LogicalOperator {
    private _value: boolean;

    constructor(A: boolean) {
        /**@type {Boolean} */
        this._value = A;
    }

    AND(B: boolean) {
        this._value = this._value && B;
        return this;
    }

    NAND(B: boolean) {
        this._value = !(this._value && B);
        return this;
    }

    OR(B: boolean) {
        this._value = this._value || B;
        return this;
    }

    NOR(B: boolean) {
        this._value = !(this._value || B);
        return this;
    }

    NOT() {
        this._value = !this._value;
        return this;
    }

    XOR(B: boolean) {
        this._value = (this._value && !B) || (!this._value && B);
        return this;
    }

    XNOR(B: boolean) {
        this._value = (!this._value && !B) || (this._value && B);
        return this;
    }

    value(): boolean {
        return this._value;
    }
}

export default EXP;
