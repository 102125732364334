import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import * as React from "react";
import APIConsoleMonitorLandingPage from "../DataBaseModule/APIConsoleMonitorLandingPage";
import APIConsoleDrawerComponent from "../DataBaseModule/APIConsoleDrawerComponent";
import styles from "./APIConsoleMainPage.module.scss";
import { API } from "../api-services";
import { useRouteParams } from "../ui-reusable-component/useRouteParams";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddEditAPIRequestModal from "./AddandEditAPIRequestModal";
import { useBaseParamsModal } from "../CustomHook/useBaseParamsModal";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import AddEditDomainModal from "./AddEditDomainModal";
import InfoIcon from "@mui/icons-material/Info";
import RequestPageIcon from "@mui/icons-material/RequestPage";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import { useBaseModal } from "../ComponentUI/SearchPage/useBaseModal";
import APIRequestTreeModal from "../ComponentUI/BOMCreate/BOMEntry/APIRequestTreeModal";

export interface IAPIConsoleMainPageProps {}

export function APIConsoleMainPage(props: IAPIConsoleMainPageProps) {
  const history = useHistory();
  const APIRequestModal = useBaseParamsModal();
  const { productId, projectId, projectName, productName, requestId } =
    useRouteParams<any>();

  const addEditRequestName = useBaseParamsModal();
  const addEditDomainName = useBaseParamsModal();

  const [allAPIRequest, setAllAPIRequest] = React.useState<any>([]);
  const [allDomainsName, setAllDomainsName] = React.useState<any>([]);
  const [credentials, setCredentials] = React.useState<any>([]);
  const [userName, setUserName] = React.useState<any>("");
  const [exsistingUsers, setExsistingUsers] = React.useState<any>([]);
  const [selectedExsistingUser, setSelectedExsistingUser] =
    React.useState<any>(null);

  const getAllAPIRequest = () => {
    API.get("/xcpep/api_requestr_view/", { top_vault: productId }, 0)
      .then((res: any) => {
        setAllAPIRequest(res.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };
  const getExsistingUsers = () => {
    API.get("/xcpep/api_credentials_view/", { api_users_list: true }, 0)
      .then((res: any) => {
        setExsistingUsers(res.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const getAllDomainsName = () => {
    API.get("/xcpep/domain_list_view/", { api_request: +requestId }, 0)
      .then((res: any) => {
        setAllDomainsName(res.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const getCredentials = () => {
    API.get("xcpep/api_credentials_view/", { api_request: +requestId }, 0)
      .then((res: any) => {
        setCredentials(res.data);
        if (res.data && res.data.length > 0) {
          setUserName(res.data[0]?.username);
        } else {
          setUserName("");
        }
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    if (requestId != 0) {
      getAllDomainsName();
      getCredentials();
      getExsistingUsers();
    }
  }, [requestId]);

  const onSubmitForm = (e: any) => {
    e.preventDefault();
    // e.stopPropogation();
    if (credentials && credentials?.length > 0) {
      API.put(
        `/xcpep/api_credentials_view/${credentials[0].id}/`,
        {
          generate_password: true,
        },
        0
      )
        .then((res: any) => {
          setSelectedExsistingUser(null);
          setUserName("");
          getCredentials();
        })
        .catch((err: any) => {
          const { data } = err.response;
          if (err.response && data) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: data[0],
            });
          }
        });
    } else {
      API.post(
        "/xcpep/api_credentials_view/",
        {
          api_request: +requestId,
          username: selectedExsistingUser ? undefined : userName,
          user: selectedExsistingUser ? selectedExsistingUser : undefined,
        },
        {},
        0
      )
        .then((res: any) => {
          setSelectedExsistingUser(null);
          setUserName("");
          getCredentials();
        })
        .catch((err: any) => {
          const { data } = err.response;
          if (err.response && data) {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: data[0],
            });
          }
        });
    }
  };
  const handleChange = (e: any) => {
    setUserName(e.target.value);
  };
  React.useEffect(() => {
    getAllAPIRequest();
  }, [productId]);
  return (
    <Box >
      <Box sx={{ display: "flex", width: "100%", height: "100%" }}>
      <Box sx={{ width: "fit-content" }}>
        <APIConsoleDrawerComponent />
      </Box>
      <Box className={styles.gridcontainer}>
        <Box className={`${styles.section} ${styles.section1}`}>
          <Box className={styles.box}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "1rem",
              }}
            >
              <Typography variant="h6">API Request</Typography>
              <AddCircleIcon
                sx={{ cursor: "pointer" }}
                onClick={() => addEditRequestName.open("Add")}
              />
            </Box>
            {allAPIRequest && allAPIRequest?.length > 0 ? (
              <Box
                adl-scrollbar="true"
                adl-scrollbar-width="0.3"
                sx={{ marginTop: "1rem", height: "20rem", width: "99.5%" }}
              >
                <Table stickyHeader aria-label="sticky table">
                  <TableHead
                    sx={{
                      backgroundColor: "primary.main",
                      color: "primary.light",
                    }}
                  >
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          backgroundColor: "primary.main",
                          borderBottomColor: "primary.light",
                          color: "primary.light",
                        }}
                      >
                        Sl No.
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                          backgroundColor: "primary.main",
                          color: "primary.light",
                        }}
                      >
                        API Request
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                          backgroundColor: "primary.main",
                          color: "primary.light",
                        }}
                      >
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allAPIRequest?.map((item: any, index: number) => {
                      return (
                        <TableRow
                          onClick={() =>
                            history.push(
                              `/API-console/${projectId}/${projectName}/${productId}/${productName}/0/0/landing/${item?.id}/0`
                            )
                          }
                          sx={{
                            backgroundColor:
                              requestId == item.id ? "primary.light" : "",
                            cursor: "pointer",
                          }}
                        >
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            {item?.name}
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <ModeEditIcon
                              sx={{ cursor: "pointer" }}
                              onClick={() => {
                                addEditRequestName.open(item);
                              }}
                            />
                            <DeleteIcon
                              sx={{ cursor: "pointer" }}
                              onClick={() => {
                                Swal.fire({
                                  title: "Are you sure?",
                                  text: "You want to delete this API Request",
                                  icon: "warning",
                                  showCancelButton: true,
                                  confirmButtonText: "Yes",
                                  cancelButtonText: "No",
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    API.delete(
                                      `/xcpep/api_requestr_view/${item.id}/`,
                                      {},
                                      0
                                    ).then((res) => {
                                      Swal.fire(
                                        "Deleted!",
                                        "Your API Request has been deleted.",
                                        "success"
                                      );
                                      getAllAPIRequest();
                                    });
                                  }
                                });
                              }}
                            />
                            <RequestPageIcon
                              sx={{ cursor: "pointer" }}
                              titleAccess="Configure Request"
                              onClick={()=>APIRequestModal.open("Request")}
                            />
                            <QuestionAnswerIcon
                              sx={{ cursor: "pointer" }}
                              titleAccess="Configure Response"
                              onClick={()=>APIRequestModal.open("Response")}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Please Create Request
              </Box>
            )}
          </Box>
        </Box>
        <Box className={`${styles.section} ${styles.section2}`}>
          <Box className={styles.box}>
            {requestId != 0 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <Typography variant="h6">Credentials</Typography>

                {/* {credentials && credentials?.length > 0 && (
                <EditIcon sx={{ cursor: "pointer" }} />
              )} */}
              </Box>
            )}
            {requestId != 0 ? (
              <Box sx={{height:'21rem'}}>
                <Box className={styles.formcontainer}>
                  <Box className={styles.formheader}>
                    {credentials && credentials?.length == 0
                      ? "Add Credentials"
                      : "Change Password"}
                  </Box>
                  <Box className={styles.formbody}>
                    <form>
                      <Box className={styles.formgroup}>
                        {
                          <input
                            type="email"
                            placeholder="Username"
                            required
                            disabled={credentials && credentials?.length > 0}
                            value={userName}
                            onChange={handleChange}
                          />
                        }
                      </Box>
                      {credentials && credentials?.length == 0 && (
                        <Box className={styles.formgroup}>Or</Box>
                      )}
                      {credentials && credentials?.length == 0 && (
                        <Box className={styles.formgroup}>
                          <FormControl fullWidth>
                            <InputLabel
                              id="demo-simple-select-label-Activity"
                              shrink
                              sx={{
                                color: "primary.main",
                                marginLeft: "-1rem",
                              }}
                            >
                              Select Credential of exsisting user
                            </InputLabel>
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={selectedExsistingUser}
                              onChange={(e: any) => {
                                setSelectedExsistingUser(e.target.value);
                              }}
                              variant="standard"
                              sx={{
                                width: "100%",
                                fontSize: "1rem",
                                backgroundColor: "white",
                                "&:before": {
                                  borderBottomColor: "primary.light",
                                },
                                ".MuiSvgIcon-root.MuiSelect-icon": {
                                  color: "primary.main",
                                },
                              }}
                              // value={credentials && credentials?.length > 0}
                              disabled={userName && userName?.length > 0}
                            >
                              {exsistingUsers &&
                                exsistingUsers?.map((item: any) => {
                                  return (
                                    <MenuItem value={item.id}>
                                      {item.username}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </FormControl>
                        </Box>
                      )}
                      <Box className={styles.formgroup}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            gap: "2px",
                          }}
                        >
                          {credentials && credentials?.length > 0 && (
                            <input
                              type="text"
                              placeholder="Password"
                              value={credentials[0]?.password}
                              required
                              disabled={credentials && credentials?.length > 0}
                            />
                          )}
                          <InfoIcon
                            sx={{ cursor: "pointer" }}
                            titleAccess="Password will automatically reset once a year."
                          />
                        </Box>
                      </Box>
                      <Box className={styles.formfooter}>
                        {/* <a>Forgot password?</a> */}
                        <button
                          type="submit"
                          className={styles.loginbutton}
                          onClick={(e: any) => onSubmitForm(e)}
                        >
                          {credentials && credentials?.length > 0
                            ? "Regenerate Password"
                            : "Add"}
                        </button>
                      </Box>
                    </form>
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Please Select Request
              </Box>
            )}
          </Box>
        </Box>
        <Box className={`${styles.section} ${styles.section3}`}>
          <Box className={styles.box}>
            {requestId != 0 && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "1rem",
                }}
              >
                <Typography variant="h6">Allowed Domains</Typography>
                <AddCircleIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => addEditDomainName.open("Add")}
                />
              </Box>
            )}
            {requestId != 0 ? (
              <Box
                adl-scrollbar="true"
                adl-scrollbar-width="0.3"
                sx={{ marginTop: "1rem", height: "20rem", width: "99.5%" }}
              >
                <Table stickyHeader aria-label="sticky table">
                  <TableHead
                    sx={{
                      backgroundColor: "primary.main",
                      color: "primary.light",
                    }}
                  >
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          backgroundColor: "primary.main",
                          borderBottomColor: "primary.light",
                          color: "primary.light",
                        }}
                      >
                        Sl No.
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                          backgroundColor: "primary.main",
                          color: "primary.light",
                        }}
                      >
                        API Request
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                          backgroundColor: "primary.main",
                          color: "primary.light",
                        }}
                      >
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {allDomainsName?.map((item: any, index: number) => {
                      return (
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            {item?.domain}
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <ModeEditIcon
                              sx={{ cursor: "pointer" }}
                              onClick={() => {
                                addEditDomainName.open(item);
                              }}
                            />
                            <DeleteIcon
                              sx={{ cursor: "pointer" }}
                              onClick={() => {
                                Swal.fire({
                                  title: "Are you sure?",
                                  text: "You want to delete this API Domain",
                                  icon: "warning",
                                  showCancelButton: true,
                                  confirmButtonText: "Yes",
                                  cancelButtonText: "No",
                                }).then((result) => {
                                  if (result.isConfirmed) {
                                    API.delete(
                                      `/xcpep/domain_list_view/${item.id}/`,
                                      {},
                                      0
                                    ).then((res) => {
                                      Swal.fire(
                                        "Deleted!",
                                        "Your API Request has been deleted.",
                                        "success"
                                      );
                                      getAllDomainsName();
                                    });
                                  }
                                });
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
            ) : (
              <Box
                sx={{
                  display: "flex",
                  height: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                Please Select Request
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      </Box>
      <Box sx={{backgroundColor:'red',height:'60%',width:"96%" }}>kkl</Box>
      {addEditRequestName.isOpen && (
        <AddEditAPIRequestModal
          onCloseModal={addEditRequestName.close}
          isOpen={addEditRequestName.isOpen}
          data={addEditRequestName.propsId}
          getAllAPIRequest={getAllAPIRequest}
        />
      )}
      {addEditDomainName.isOpen && (
        <AddEditDomainModal
          onCloseModal={addEditDomainName.close}
          isOpen={addEditDomainName.isOpen}
          data={addEditDomainName.propsId}
          getAllAPIRequest={getAllDomainsName}
        />
      )}
      {APIRequestModal.isOpen && <APIRequestTreeModal
        isOpen={APIRequestModal.isOpen}
        onCloseModal={APIRequestModal.close}
        type={APIRequestModal.propsId}
        />}
    </Box>
  );
}
