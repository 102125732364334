import { debounce } from 'lodash';
import * as React from 'react';
import { API } from '../../../api-services';
import Swal from 'sweetalert2';
import { TextField } from '@mui/material';
import { ADMIN } from '../../../Redux/Services/admin.service';

export interface IEditNameMaterialProps {
    value:any;
    getRMData:any;
    idd:any;
}

export function EditNameOverheadCreate (props: IEditNameMaterialProps) {
    const { value, getRMData ,idd} = props;
    const [name, setName] = React.useState<any>("");
    const [primaryName,setPrimaryName] = React.useState<any>("")
    const ExactDebounce = React.useRef(
      debounce(async (value: any,iddd:any) => {
        await API.put(
          `/cost/overhead/${iddd}/`,
          {
            alias: value,
          },
          {},
          0
        )
          .then((res: any) => {
            setPrimaryName(value)
            getRMData();
           ADMIN.toast.info("Name Updated Successfully")
            // getRMDataList();
          })
          .catch((err: any) => {
            const { data } = err?.response;
            Swal.fire({
              icon: "error",
              html: `<div>
                                 <br />
                                 <p style="color:"red">${data[0]}</p>   
                                  </div>`,
            });
          });
      }, 2000)
    ).current;
  
    React.useEffect(() => {
      return () => {
        ExactDebounce.cancel(); // Cancel the debounced function
      };
    }, []);
    React.useEffect(() => {
      if (value) {
        setName(value);
        setPrimaryName(value)
      }
    }, [value]);
    const handleChange = (e: any) => {
      const { value } = e.target;
      setName(value);
      // ExactDebounce(value,idd);
    };
    return (
      <TextField
        placeholder="Material Name"
        // label=' Material Name'
        variant="standard"
        size="small"
        value={name}
        onChange={handleChange}
        onBlur={(e:any)=>{
          if(e.target?.value != primaryName){
          ExactDebounce(e.target.value,idd)
          }
        }}
        fullWidth
        sx={{
          width: "100%",
          margin: "0.2rem 0",
          ".MuiInputBase-root.MuiInput-root": {
            fontSize: "1rem !important",
          },
          "& .MuiInput-underline:before": { borderBottomColor: "primary.main" },
          "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
        }}
      />
    );
}
