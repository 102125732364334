import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import ClearIcon from "@mui/icons-material/Clear";
import CancelIcon from '@mui/icons-material/Cancel';
import Plotly from "plotly.js";
import createPlotlyComponent from "react-plotly.js/factory";
import SkeletonLoaderComponent from "../../ComponentUI/SkeletonLoader/SkeletonLoaderComponent";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Label,
  LabelList,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { Divider } from "@mui/material";
const Plot = createPlotlyComponent(Plotly);


interface CompareCostingGraphmodalProps {
  Open: any;
  Close: any;
  parameterGraphData: any;
  ParameterNames: any;
  CostingGraphLoader: any;
  moduleType?:any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: '40%',
  height: '40vh',
  bgcolor: "background.paper",
  borderRadius: "10px",
  // border: '2px solid #000',
  boxShadow: 24,
  paddingBottom:'1rem'
  // p: 4,
};

const CompareCostingGraphmodal = (props: CompareCostingGraphmodalProps) => {
  const {
    Open,
    Close,
    parameterGraphData,
    ParameterNames,
    CostingGraphLoader,
    moduleType
  } = props;

  const [ParameterData, setParameterData] = useState<any>();
  console.log("CompareCostingGraphmodal",parameterGraphData,ParameterNames)
  var DataId =
    parameterGraphData &&
    parameterGraphData.map((item: any, index: any) => ({
      ...item,
      id: index + 1,
    }));

 

  // useEffect(()=>{

  //     setParameterData(parameterGraphData && parameterGraphData)

  // },[parameterGraphData])

  const CustomTooltip = ({ active, payload, label }: any) => {


  


    if (active && payload && payload.length) {
      return (
        <div className="custom-tooltip">
          
          <Typography className="label" style={{fontSize:'0.75rem', background:"white",padding:"1rem",color:'black',display:'flex',alignItems:'center',columnGap:'5px'}}><Box sx={{width:'0.75rem',height:'0.75rem',background:`${payload[0]?.payload?.colors}`}}></Box>{`${label} : ${payload[0].value}`}</Typography>
        
        </div>
      );
    }
  
    return null;
  };




  
  return (
    <Modal
      open={Open}
      onClose={Close}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>

          { moduleType && moduleType=="Bom"?
          
          <Typography
          style={{
            fontSize: "1rem",
            fontWeight: "600",
            width: "100%",
            textAlign: "center",
            marginRight: '-2rem'
          }}
        >
          BOM
        </Typography>:
        
          <Typography
            style={{
              fontSize: "1rem",
              fontWeight: "600",
              width: "100%",
              textAlign: "center",
              marginRight: '-2rem'
            }}
          >
            Costing
          </Typography>
}
          <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={Close} />
        </Box>
        <Divider
          sx={{
            borderColor: "primary.light",
            margin: "0 1rem",
            marginBottom: "1rem",
          }}
        />

        {CostingGraphLoader ? (
          <SkeletonLoaderComponent />
        ) : (
          parameterGraphData &&  parameterGraphData?.length > 0 && (
            <ResponsiveContainer>
              <BarChart
                data={parameterGraphData && parameterGraphData}
                margin={{
                  top: 20,
                  right: 20,
                  left: 50,
                  bottom: 50,
                }}
              >
                {/* <Bar
                        dataKey="Cost"
                        barSize={100}
                        // fill="rgb(144, 238, 144)"
                        // style={{ overflow: "visible" }}
                        >
                      </Bar> */}

               
                <XAxis
                  dataKey="name"
                  // angle={-90}
                  textAnchor="middle"
                  fontSize="0.75rem"
                  stroke="#000000"
                  interval={0}
                />
                <YAxis fontSize="0.75rem" stroke="#000000">
                  <Label
                    style={{
                      textAnchor: "middle",
                      fontSize: "0.87rem",
                      fontWeight: "500",
                      fill: "#000000",
                      textTransform:'capitalize',
                    }}
                    angle={270}
                    dx={-40}
                    value={ParameterNames}
                  />
                </YAxis>
                <Tooltip
                  cursor={{ fill: "white" }}
                // @ts-ignore
                 content={<CustomTooltip />}
                />
                <Bar
                  barSize={100}
                  isAnimationActive={true}
                  dataKey="value"
                  stackId="a"
                  fill="#b8d8be"
                >
                  {DataId &&
                    DataId?.map((entry: any) => <Cell fill={entry?.colors} />)}
                  {<LabelList dataKey="value" position="top" fill="black" />}
                </Bar>
              </BarChart>
            </ResponsiveContainer>
          )
        )}
      </Box>
    </Modal>
  );
};

export default CompareCostingGraphmodal;
