import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import { Checkbox, Chip, Divider, FormControlLabel, Skeleton } from "@mui/material";
import AddAPhotoIcon from "@mui/icons-material/AddAPhoto";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { API } from "../../api-services";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import Image from "../../ViewModule/FeatureLandingPage/ArchitectureImageCard";
import NoImgLoader from "../../Assets/images/loading-screen.gif";
import ArchitectureSingleUploadModalS3 from "./ArchitectureSingleUploadModalS3";
import { useHistory } from "react-router-dom";
import VerifiedIcon from "@mui/icons-material/Verified";

import ValidatedImg from "../../Assets/images/Validated.svg";
import { MaxRange } from "devextreme-react/range-selector";


const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  height: "90vh",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};


export interface IMediaCreateImageUploadModalProps {
  onCloseModal: any;
  isOpen: any;
  GroupData: any;
  type: any;
  setcounter: any;
  Item: any;
  userPermissionTopvaluewise: any
  onCloseModalPrarent?: any;
}

export function ArchCreateImageUploadModal(
  props: IMediaCreateImageUploadModalProps
) {
  const { onCloseModal, isOpen, GroupData, type, setcounter, Item, onCloseModalPrarent, userPermissionTopvaluewise } = props;

  const [ProductList, setProductList] = React.useState<any>([]);
  const [selectedProduct, setselectedProduct] = React.useState<any>(GroupData?.top_vault);
  const SingleimageRef = React.useRef<any>();
  const [ImagedataList, setImagedataList] = React.useState<any>([]);
  const [premissionData, setpermissiondata] = React.useState<any>()
  const [sequenceCounter, setSequenceCounter] = React.useState<any>(type == "old_group" ? GroupData?.sequence : 1);
  const [Counter, setCounter] = React.useState<any>(0);
  const history = useHistory();
  const {
    projectId,
    projectname,
    abbr,
    topvoult,
    subSystem,
    groupId,
    collapestatus,
    pageNumber,
    productpage,
    groupName,
    diagramsCount
  } = useRouteParams<any>();
  const [Loader, setLoader] = React.useState<any>(false);
  const [MaxCount, setMaxCount] = React.useState<any>("null")
  const [checked, setchecked] = React.useState<any>(false)
  const [NACounter, setNACounter] = React.useState<any>(0)


  React.useEffect(() => {

    if (GroupData !== undefined) {
      setselectedProduct(GroupData?.top_vault)


    }
  }, [GroupData])


  const ProductHandler = (id: any, item: any) => {

    // setSequenceCounter(1);
    setchecked(false)

    setselectedProduct(id);
  };
  const Closehandler = () => {
    onCloseModal();
    if (type == "old_group") {
      history.push(
        `/architecturecreateinternal/${projectId}/${projectname}/${Item?.abbreviation}/${topvoult}/${subSystem}/${GroupData?.group
        }/${collapestatus}/${pageNumber}/${productpage}/${GroupData?.name}/${diagramsCount}`
      );

      setcounter((prev: any) => prev + 1);
    } else {
      onCloseModalPrarent()
      history.push(
        `/architecturecreateinternal/${projectId}/${projectname}/${Item?.abbreviation}/${topvoult}/${subSystem}/${GroupData?.id
        }/${collapestatus}/${1}/${1}/${GroupData?.name}/${diagramsCount}`
      );

      setcounter((prev: any) => prev + 1);
    }
  };



  React.useEffect(() => {

    if (type == "old_group" && ProductList?.length > 0) {
      if (selectedProduct !== "null") {
        var filter = ProductList && ProductList?.filter((item: any) => { return item?.top_vault_id == selectedProduct })[0]
        // console.log(filter, "Vrbiriurhiurhu")
        setpermissiondata(filter)
        setLoader(true);

        API.get(
          "/circuit_diagram/arch_group_upload/",
          {
            project: projectId,

            categories: 3,
            abbreviation: Item?.abbreviation,
            top_vault: selectedProduct,
            sequence: sequenceCounter,
            group_id: type == "old_group" ? GroupData?.group : GroupData?.id,
            current_sequence: true,
          },
          0
        ).then((res: any) => {
          setLoader(false);
          setImagedataList(res.data);
        });

        var topvault = ProductList && ProductList?.map((item: any, index: any) => { return item?.top_vault_id })

        API.get(
          "/circuit_diagram/arch_group_upload/",
          {
            project: projectId,

            categories: 3,
            abbreviation: Item?.abbreviation,

            top_vault: topvault?.toString(),

            sequence: sequenceCounter,
            group_id: type == "old_group" ? GroupData?.group : GroupData?.id,
            max_sequence: true,
          },
          0
        ).then((res: any) => {
          setLoader(false);
          setMaxCount(res.data);
        }).catch((err: any) => {

          setLoader(false);
        })
      }


    }

    if (type == "new_group" && ProductList?.length > 0) {

      if (selectedProduct !== "null") {
        var filter = ProductList && ProductList?.filter((item: any) => { return item?.top_vault_id == selectedProduct })[0]
        // console.log(filter, "Vrbiriurhiurhu")
        setpermissiondata(filter)
        setLoader(true);

        API.get(
          "/circuit_diagram/arch_group_upload/",
          {
            project: projectId,

            categories: 3,
            abbreviation: Item?.abbreviation,
            top_vault: selectedProduct,
            sequence: sequenceCounter,
            group_id: type == "old_group" ? GroupData?.group : GroupData?.id,
            current_sequence: true,
          },
          0
        ).then((res: any) => {
          setLoader(false);
          setImagedataList(res.data);
        });

        var topvault = ProductList && ProductList?.map((item: any, index: any) => { return item?.top_vault_id })

        API.get(
          "/circuit_diagram/arch_group_upload/",
          {
            project: projectId,

            categories: 3,
            abbreviation: Item?.abbreviation,

            top_vault: topvault?.toString(),

            sequence: sequenceCounter,
            group_id: type == "old_group" ? GroupData?.group : GroupData?.id,
            max_sequence: true,
          },
          0
        ).then((res: any) => {
          setLoader(false);
          setMaxCount(res.data);
        }).catch((err: any) => {

          setLoader(false);
        })
      }

    }
  }, [sequenceCounter, selectedProduct, Counter, ProductList]);

  // console.log(userPermissionTopvaluewise, "premissionDatapremissionData")

  React.useEffect(() => {

    if (GroupData !== undefined) {


      API.get(
        "/circuit_diagram/arch_download/",
        {
          project: projectId,
          sequence: sequenceCounter,
          categories: 3,
          group_id: type == "old_group" ? GroupData?.group : GroupData?.id,
          abbreviation: Item?.abbreviation,
        },
        0
      ).then((res: any) => {

        var Permissiondata: any = res?.data && res?.data?.map((item: any, index: any) => {
          return userPermissionTopvaluewise.map((item1: any, index: any) => {
            if (item1?.top_vault == item?.top_vault_id && item1?.action !== null) {
              return { ...item, ["action"]: item1?.action }
            }

          })
        })?.flat(1)
        // @ts-ignore
        var uniqueData = [...new Set(Permissiondata)]
        var datavalue = uniqueData && uniqueData?.filter((item: any) => {
          return item !== undefined
        })


        setProductList(datavalue);





      });
    }
  }, [isOpen, NACounter, Counter, GroupData, sequenceCounter]);


  const SingleUploadImageS3 = (e: any) => {
    var File = e?.target?.files[0];
    if (type == "old_group") {
      ArchitectureSingleUploadModalS3(
        [File],
        GroupData?.group,
        sequenceCounter,
        selectedProduct,
        ImagedataList[0]?.vault,
        projectId,
        Item?.abbreviation,
        setLoader,
        ImagedataList[0],
        setCounter
      );
    } else {
      ArchitectureSingleUploadModalS3(
        [File],
        GroupData?.id,
        sequenceCounter,
        selectedProduct,
        ImagedataList[0]?.vault,
        projectId,
        Item?.abbreviation,
        setLoader,
        ImagedataList[0],
        setCounter
      );
    }


  };
  const singleImageUplaod = () => {
    return SingleimageRef.current.click();
  };

  const PaginationHandler = (type: any) => {
    if (type == "Next") {
      setchecked(false)
      setSequenceCounter(sequenceCounter + 1);
    } else {
      setchecked(false)
      setSequenceCounter(sequenceCounter - 1);
    }
  };



  const CheckboxHandler = (e: any) => {
    setchecked(e?.target?.checked)
    var topvault = ProductList?.map((item: any, index: any) => { return item?.top_vault_id })
    setLoader(true)
    API.get(
      "/circuit_diagram/arch_group_upload/",
      {
        project: projectId,

        categories: 3,
        abbreviation: Item?.abbreviation,

        group_id: type == "old_group" ? GroupData?.group : GroupData?.id,
        top_vault: selectedProduct?.toString(),
        sequence: sequenceCounter,

        upload_na: true,
      },
      0
    ).then((res: any) => {
      setLoader(false);
      setImagedataList(res?.data)



      setNACounter((prev: any) => prev + 1)

      API.get(
        "/circuit_diagram/arch_group_upload/",
        {
          project: projectId,

          categories: 3,
          abbreviation: Item?.abbreviation,

          top_vault: topvault?.toString(),

          sequence: sequenceCounter,
          group_id: type == "old_group" ? GroupData?.group : GroupData?.id,
          max_sequence: true,
        },
        0
      ).then((res: any) => {

        setMaxCount(res.data);
      }).catch((err: any) => {


      })
    }).catch((err: any) => {
      setchecked(false)
      setLoader(false);

    })
  }


  return (
    <div>
      <Modal
        open={isOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            ref={SingleimageRef}
            onChange={(e) => SingleUploadImageS3(e)}
          />
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                // textAlign: "center",
                marginLeft: "1rem",
                marginRight: "-2rem",
              }}>
              {Item?.abbreviation == 0 ? "Product" : Item?.abbreviation} {"->"} {GroupData?.name} {"->"} Add Diagrams
            </Typography>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "15%",
                // textAlign: "center",
                marginLeft: "1rem",
                marginRight: "-2rem",
              }}>
              {ImagedataList?.length > 0 &&
                <FormControlLabel control={<Checkbox
                  onChange={(e: any) => CheckboxHandler(e)}
                  checked={checked}
                />} label="Not Available " />
              }
            </Typography>

            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={Closehandler}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ display: "flex", alignItems: "center",width:'100%',paddingBottom:'0.2rem' }}
           adl-scrollbar="true"
           adl-scrollbar-width="0.3">

            {ProductList?.map((item: any, index: any) => {
              return (
                <>
                  {
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                      <Chip
                        icon={<VerifiedIcon sx={{ color: item?.status == 2 ? "#26e726 !important" : "#ffa500 !important", display: item?.status == 0 ? "none" : "block" }} />}
                        onClick={() => ProductHandler(item?.top_vault_id, item)}
                        label={item?.name}
                        sx={{
                          width: "100%",
                          marginRight: "0.5rem",
                          '.MuiButtonBase-root.MuiChip-root': {
                            backgroundColor: 'primary.main', color: 'white',
                          },
                          backgroundColor:
                            item?.top_vault_id == selectedProduct
                              ? "primary.main"
                              : "primary.light",
                          color:
                            item?.top_vault_id == selectedProduct
                              ? "white"
                              : "primary.main",
                          padding: "0 1rem",
                          '&:hover': {
                            backgroundColor: 'primary.main', color: 'white',
                          },
                        }}
                      />
                      {ProductList?.length - 1 !== index && <ArrowRightAltIcon />}
                    </Box>
                  }
                </>
              );
            })}
          </Box>
          <Box sx={{ marginBottom: "-2rem", marginTop: "0.5rem" }}>
            <Box
              sx={{
                padding: "0.5rem",
                borderRadius: "50%",
                border: "1px solid",
                width: "2rem",
                height: "2rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}>
              {ImagedataList?.length > 0 ? sequenceCounter : ""}

            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-evenly",
              width: "100%",
              height: "71vh",
            }}>
            {ImagedataList?.length > 0 && (
              <>
                <>
                  {Loader ? (
                    <Skeleton
                      variant="rectangular"
                      width={281}
                      height={137}
                      sx={{ margin: " 0rem 1rem 1rem 1rem" }}
                    />
                  ) : (
                    <>
                      {ImagedataList[0]?.image_url_cdn !== "" ? (
                        <>
                          <Box
                            sx={{
                              display: "flex",
                              flexDirection: "column",
                              height: "60%",
                              alignItems: "center",
                              gap: "0.9rem",
                              width: "100%",
                            }}>
                            <Image
                              src={
                                ImagedataList &&
                                ImagedataList[0]?.image_url_cdn &&
                                ImagedataList[0]?.image_url_cdn
                              }
                              placeholderImg={NoImgLoader}
                              alt={ImagedataList[0]?.name}
                              style={{
                                objectFit: "contain",
                                height: "100%",
                                width: "100%",
                                cursor: "pointer",
                              }}
                            />
                            {premissionData && premissionData?.action?.includes("U") &&
                              <FileUploadIcon
                                titleAccess="Upload Diagrams"
                                onClick={() => singleImageUplaod()}
                                sx={{ cursor: "pointer" }}
                              />
                            }
                          </Box>
                        </>
                      ) : (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            height: "60%",
                            alignItems: "center",
                            gap: "0.9rem",
                            width: "100%",
                          }}>
                          {
                            premissionData && premissionData?.action?.includes("C") &&


                            <><FileUploadIcon
                              titleAccess="Upload Diagrams"
                              onClick={() => singleImageUplaod()}
                              sx={{ fontSize: "12rem", cursor: "pointer" }} /><Typography>Upload Diagrams</Typography></>
                          }
                        </Box>
                      )}
                    </>
                  )}
                </>
              </>
            )}

            {/* {ImagedataList?.length == 0 && <Typography>Select Vehicle</Typography>} */}
          </Box>

          {
            Loader == false &&

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}>
              <Button
                disabled={sequenceCounter > 1 ? false : true}
                onClick={() => PaginationHandler("Previous")}>
                Previous ({sequenceCounter})
              </Button>
              <Button disabled={MaxCount?.max_sequence > sequenceCounter ? false : true} onClick={() => PaginationHandler("Next")}>Next ({MaxCount?.max_sequence})</Button>
            </Box>
          }
        </Box>
      </Modal>
    </div>
  );
}
