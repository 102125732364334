import * as React from 'react';
import styles from './MediaCreateProjectSelection.module.scss';
import {
    Box,
} from "@mui/material";
import Button from "@mui/material/Button";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useState } from 'react';

import { useRouteParams } from '../../ui-reusable-component/useRouteParams';
import { API } from '../../api-services';
import { LoadingButton } from '@mui/lab';
import { AxiosError, AxiosResponse } from 'axios';
import MediaCreateDrawerBody from './MediaCreateDrawerBody';


interface Params {
    projectId: any;
}

export interface IMediaCreateDrawerProps {
    anchorComing:any;
    toggleDrawer:any;
}


export function MediaCreateDrawer(props: IMediaCreateDrawerProps) {
    const {anchorComing,toggleDrawer} = props;
    const { projectId } = useRouteParams<Params>();
    const [projectList, setProjectList] = useState<any>();
    const [selectedProject, setSelectedProject] = useState<any>([]);
    const [childMediaType,setChildMediaType] = useState<any>();
    const [mediaCount,setMediaCount] = useState<any>();
    const [selectedMediaType, setSelectedMediaType] = useState<any>([]);

    const history = useHistory();


    React.useEffect(()=>{
        setSelectedProject([projectId]);
    },[]);

    React.useEffect(() => {
        API.get(`/image_repository/media_module/`, { media_project_drawer: true}, 0)
            .then((res: AxiosResponse) => {
                setProjectList(res?.data);
            })
            .catch((err: AxiosError) => {
                console.log(err, "error");
            });
        
        API.get(`image_repository/media_module/`, { project_repository_details: projectId}, 0)
        .then((res: AxiosResponse) => {
            setMediaCount(res?.data);
        })
        .catch((err: AxiosError) => {
            console.log(err, "error");
        });
    }, []);

    const selectAllProject = (productIds: any) => {
        setSelectedProject([productIds]);
        API.get(`image_repository/media_module/`, { project_repository_details: productIds}, 0)
            .then((res: AxiosResponse) => {
                setMediaCount(res?.data);
            })
            .catch((err: AxiosError) => {
                console.log(err, "error");
            });
    };

    const getselectedProjectBackgroundColor = (idd: any) => {
        if (selectedProject?.includes(idd)) {
            return "primary.main";
        } else {
            return "primary.light";
        }
    };

    const getSelectedProjectTextColor = (idd: any) => {
        if (selectedProject?.includes(idd)) {
            return "primary.light";
        } else {
            return "primary.main";
        }
    };

    const redirectToProject: any = () => {
        history.push(`/mediacreateinternal/${selectedProject[0]}/${projectList && projectList?.filter((item: any) => item?.id == selectedProject[0])[0]?.name}/${childMediaType}/0/1/0/0/0/true`);
    };

    return (
        <Box
            sx={{
                minWidth: 300,
                width: anchorComing === "top" || anchorComing === "bottom" ? "auto" : "auto",
            }}
            role="presentation"
        >
            <Box sx={{ width: "100%", padding: "0" }}>
                <Box
                    sx={{ width: "100%", padding: "0 1rem" }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3"
                >
                    <Box sx={{ marginTop: '1rem' }}>
                        {projectList &&
                            projectList?.map((item: any, index: any) => {
                                return (
                                    <>
                                        <Box sx={{ alignItems: "center" }}>
                                        {mediaCount && <MediaCreateDrawerBody item={item} selectedProject={selectedProject} selectAllProject={selectAllProject} getselectedProjectBackgroundColor={getselectedProjectBackgroundColor} getSelectedProjectTextColor={getSelectedProjectTextColor} setChildMediaType={setChildMediaType} mediaCount={mediaCount} selectedMediaType={selectedMediaType} setSelectedMediaType={setSelectedMediaType}/>}
                                        </Box>
                                    </>
                                );
                            })}
                    </Box>
                </Box>
                <div
                    style={{
                        paddingBottom: "0.5rem",
                        position: "sticky",
                        bottom: "0",
                        height: "4rem",
                        backgroundColor: "aliceblue",
                        marginTop: "2rem",
                    }}
                >
                    <Box sx={{ textAlign: "right", paddingRight: "1rem" }}>
                        <Button
                            className={styles.drawBtn}
                            sx={{
                                marginRight: "1rem",
                                // fontSize: "0.75rem",
                                "&:hover": {
                                    transform: "Scale(1.1)",
                                    transition: "transform 0.5s ease",
                                },
                            }}
                            variant="contained"
                            onClick={toggleDrawer(anchorComing, false)}
                            size="small"
                        >
                            Close
                        </Button>
                        <LoadingButton size="small" onClick={redirectToProject} disabled={selectedMediaType?.length == 0} sx={selectedMediaType?.length == 0?{cursor:'not-allowed'}:{}}>
                            <Button
                                variant="contained"
                                sx={selectedMediaType?.length == 0 ?
                                    {cursor:'not-allowed'}
                                    :{
                                    // fontSize: "0.75rem",
                                    "&:hover": {
                                        transform: "Scale(1.1)",
                                        transition: "transform 0.5s ease",
                                    },
                                }}
                                onClick={toggleDrawer(anchorComing, false)}
                                size="small"
                            >
                                Ok
                            </Button>
                        </LoadingButton>
                    </Box>
                </div>
            </Box>
        </Box>
    );
}
