import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { Backdrop, TextField, ToggleButton, ToggleButtonGroup } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { useFormik } from "formik";
import * as Yup from "yup";
import { LoadingButton } from "@mui/lab";
import { API } from "../../api-services";
import Swal from "sweetalert2";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50vw",
  maxWidth: "600px",
  bgcolor: "background.paper",
  borderRadius: "12px",
  boxShadow: 24,
  p: 0,
};

interface IWatermarkModelProps {
  isOpen: any;
  onCloseModal: any;
  counterApiCall?: any;
  data?:any;
  getTreeData?:any;
}

function EditCategoryModal(props: IWatermarkModelProps) {
  const { isOpen, onCloseModal, counterApiCall,data,getTreeData } = props;
  const [loading, setLoading] = React.useState<boolean>(false);
  const { projectId } = useRouteParams<any>();

  // Formik setup
  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      is_bop: "DC", // Default selection is 'DC'
    },
    validationSchema: Yup.object({
      name: Yup.string().required("Name is required"),
      description: Yup.string(),
      is_bop: Yup.string().required("Selection is required"), // Required validation for DC/BOP
    }),
    onSubmit: (values) => {
      setLoading(true);
      const requestData = {
        cat_name: values.name,
        description: values.description,
        is_bop: values.is_bop === "BOP", // Convert to boolean for API
        project: projectId,
      };
      API.put(`/api/db/costing_category/${data.id}/`, requestData, {}, 0)
        .then((res: any) => {
          setLoading(false);
          counterApiCall((prev: any) => prev + 1);
          getTreeData();
          onCloseModal();
        })
        .catch((err: any) => {
          setLoading(false);
          if (err && err?.response && err?.response?.data[0]) {
            Swal.fire({
              icon: "error",
              customClass: {
                container: "swal2Container",
              },
              html: `<div>
      <br />
      <p style="color:red;">${err?.response?.data[0]}</p>   
       </div>`,
            });
          }
        });
    },
  });

  React.useEffect(() => {
    if(data){
      formik.setValues({
        name: data.name,
        description: data.description,
        is_bop: data.is_bop ? "BOP" : "DC",
      });
    }
  }, [data]);

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "3.5rem",
              p: 2,
              backgroundColor: "#007fff", // Same color as the button background
              borderTopLeftRadius: "12px",
              borderTopRightRadius: "12px",
            }}
          >
            <Typography
              sx={{
                fontSize: "1.2rem",
                fontWeight: "bold",
                width: "100%",
                textAlign: "center",
                color: "#fff", // White text for contrast
              }}
            >
              Edit Costing Categories
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", color: "#fff", fontSize: "1.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider sx={{ borderColor: "primary.light" }} />

          <Box sx={{ p: 3 }}>
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ marginBottom: "1.5rem" }}>
                <TextField
                  fullWidth
                  id="name"
                  name="name"
                  label="Name"
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                  variant="outlined"
                />
              </Box>
              <Box sx={{ marginBottom: "1.5rem" }}>
                <TextField
                  fullWidth
                  id="description"
                  name="description"
                  label="Description"
                  value={formik.values.description}
                  onChange={formik.handleChange}
                  error={formik.touched.description && Boolean(formik.errors.description)}
                  helperText={formik.touched.description && formik.errors.description}
                  variant="outlined"
                />
              </Box>

                {/* <Box sx={{ display: "flex", justifyContent: "center", mb: 3 }}>
                  <ToggleButtonGroup
                    value={formik.values.is_bop}
                    exclusive
                    onChange={(e, value) => {
                      formik.setFieldValue("is_bop", value);
                    }}
                    aria-label="DC/BOP selection"
                  >
                    <ToggleButton
                      value="DC"
                      sx={{
                        width: "150px",
                        textTransform: "none",
                        backgroundColor: formik.values.is_bop === "DC" ? "#007fff" : "#fff",
                        color: formik.values.is_bop === "DC" ? "#fff" : "#007fff",
                        border: "1px solid #007fff",
                        "&:hover": {
                          backgroundColor: formik.values.is_bop === "DC" ? "#007fff" : "#e6f7ff",
                        },
                      }}
                      aria-label="DC"
                    >
                      DC
                    </ToggleButton>
                    <ToggleButton
                      value="BOP"
                      sx={{
                        width: "150px",
                        textTransform: "none",
                        backgroundColor: formik.values.is_bop === "BOP" ? "#007fff" : "#fff",
                        color: formik.values.is_bop === "BOP" ? "#fff" : "#007fff",
                        border: "1px solid #007fff",
                        "&:hover": {
                          backgroundColor: formik.values.is_bop === "BOP" ? "#007fff" : "#fff",
                        },
                      }}
                      aria-label="BOP"
                    >
                      BOP
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box> */}
              {formik.touched.is_bop && formik.errors.is_bop && (
                <Typography variant="body2" color="error" sx={{ textAlign: "center", mb: 2 }}>
                  {formik.errors.is_bop}
                </Typography>
              )}
              <Box sx={{ textAlign: "center" }}>
                <LoadingButton
                  type="submit"
                  variant="contained"
                  color="primary"
                  loading={loading}
                  sx={{ width: "150px", fontSize: "1rem", borderRadius: "8px", backgroundColor: "#007fff" }}
                >
                  Save
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default EditCategoryModal;
