import { MenuItem, Select, TextField, debounce } from "@mui/material";
import * as React from "react";

import Swal from "sweetalert2";

import { customValidationSelectors } from "../../../../Redux/Reducers/customValidation.reducer";
import { useSelector } from "react-redux";
import { parameterConfigSelectors } from "../../../../Redux/Reducers/parameterConfig.reducer";
import { workstationSelectors } from "../../../../Redux/Reducers/workstation.reducer";
import { RootState } from "../../../../Redux/Store/storeConfigurations";
import { ID } from "../../../../utlis/commonInterfaces";
import { customValidate } from "../../../../utlis/CustomValidate";
import FormError from "../../../../Core/FromError";
import { userPermissionAction } from "../../../BOMCreate/BOMEntry/BOMEntryLandingPage";
import { useRouteParams } from "../../../../ui-reusable-component/useRouteParams";
import { API } from "../../../../api-services";
import { ADMIN } from "../../../../Redux/Services/admin.service";
import validateValues from "../../../BOMCreate/validateValues";


export interface IIdeaSingleSelectInputBoxProps {
    item: any;
    details: any;
    // BOMValidationStatus?: any;
    allValues: any;
    getAllValues: any;
}

export default function IdeaSingleSelectInputBox (props: IIdeaSingleSelectInputBoxProps) {
    const { item, details, allValues, getAllValues } = props;
  const userAction = React.useContext(userPermissionAction);
  let UserAction = userAction && userAction?.action;
  const { vault, workstation, topVault, copyStatus,ideaId } = useRouteParams<any>();
  const [value, setValue] = React.useState<any>("");
  const [fieldError, setFieldError] = React.useState<any>(undefined);
  const customValidations = useSelector(customValidationSelectors.selectAll);
  const parameterConfigs = useSelector(
    parameterConfigSelectors.selectNonImageNonTableChildernConfigs
  );

  const workstationList = useSelector((state: RootState) => {
    return workstationSelectors.selectById(state, +workstation || 0);
  });

  const getObj = (vlt: any) => {
    if (vlt == 0) {
      return { top_vault: topVault };
    } else {
      return { vault: vlt };
    }
  };

  const configToDbMap = parameterConfigs.reduce((result, config) => {
    result[config.id] = config.parameter_id;
    return result;
  }, {} as Record<string, ID>);

  const configToDataTypeMap = parameterConfigs.reduce((result, config) => {
    result[config.id] = config.data_type;
    return result;
  }, {} as Record<string, string>);
  const categoryIncludedInWorkstion = workstationList
    ? workstationList.categories.includes(1)
    : false;

  const allInputData = parameterConfigs
    .filter((data) => data.data_type !== "table")
    .filter((data) => data.data_type !== "document");
  const allowedParameters = categoryIncludedInWorkstion
    ? workstationList?.allowed_parameters
    : undefined;

  var errors = validateValues(
    allInputData,
    { [item?.key]: value },
    allowedParameters,
    "parameter_id"
  );
  React.useEffect(() => {
    setValue(item?.value);
  }, [item]);

  const validateField = (parameter_id: ID, value?: any) => {
    const val = value ?? { [item?.key]: +value }[parameter_id];
    let error: string | undefined;
    const config: any = parameterConfigs.find(
      (config) => config.parameter_id === parameter_id
    );

    if (config)
      error = validateValues([config], { [parameter_id]: val })[parameter_id];

    // custom validations
    const relation = customValidations?.find(
      (relation) => relation.parameter_id === parameter_id
    );
    // { [item?.key]: +value },
    allValues[item?.key] = value;

    if (relation)
      error = customValidate(
        relation.parameter_id,
        configToDataTypeMap,
        relation.validation,
        undefined,
        allValues,
        details,
        configToDbMap
      );
    setFieldError(error!);
  };
  const updateDataVaultParams = async (name: any, value: any, vlt: any) => {
    if (value) {
      API.put(
        `/idea/pool/${ideaId}/`,
        {
          ...{parameter_json:{[name]: value}}
        },
        {
          ...getObj(vlt),
          module:15
        },
        0
      )
        .then((res: any) => {
          // BOMValidationStatus();
          ADMIN.toast.info("Updated Successfully");
        })
        .catch((err: any) => {
          const { data } = err.response;
          Swal.fire({
            title: "Error!",
            text: data[0],
            icon: "error",
            confirmButtonText: "Ok",
          });
        });
    }
  };

  const ExactDebounceParameters = React.useRef(
    debounce(async (value: any, name: any, idd: any) => {
      await updateDataVaultParams(name, value, idd);
    }, 1000)
  ).current;

  const handleOnChange = (event: any) => {
    const { name, value } = event.target;
    validateField(item?.key, value);
    if (fieldError) {
    } else {
      ExactDebounceParameters(value, name, vault);
    }

    setValue(value);
  };


  return (
    <>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        variant="standard"
        onChange={handleOnChange}
        disabled={!item?.editable}
        name={item?.key}
        // disabled={
        //   (copyStatus == false || copyStatus == "false") &&
        //   (UserAction?.includes("U") || UserAction?.includes("C"))
        //     ? false
        //     : true
        // }
        value={value || ""}
        label="Calibration Required"
        sx={{
          width: "100%",
          fontSize: "12px",
          backgroundColor: "rgba(255,255,255,0.3)",
          paddingLeft: "0.3rem",
          "&:before": {
            borderBottomColor: "transparent",
          },
          ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
        }}
      >
        {item?.allowed_values?.map((option: any) => {
          return (
            <MenuItem sx={{ fontSize: "12px" }} value={option}>
              {option}
            </MenuItem>
          );
        })}
      </Select>
      {fieldError && <FormError error={fieldError} align="center" />}
    </>
  );
}
