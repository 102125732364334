import * as React from 'react';
import {
    Box,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
  } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Swal from "sweetalert2";

import FunctionsIcon from "@mui/icons-material/Functions";
import LoadingButton from "@mui/lab/LoadingButton";
import { API } from '../../../../api-services';
import { ADMIN } from '../../../../Redux/Services/admin.service';
import { userPermissionAction } from '../../../BOMCreate/BOMEntry/BOMEntryLandingPage';
import { useRouteParams } from '../../../../ui-reusable-component/useRouteParams';
import IdeaTableOptionsInput from './IdeaTableOptionsInput';
import IdeaNumberInputBoxTable from './IdeaNumberInputBoxTable';
import IdeaTextInputBoxTable from './IdeaTextInputBoxTable';
import IdeaMultiSelectInputBoxTable from './IdeaMultiSelectInputBoxTable';
import IdeaImageInputViewBox from './IdeaImageInputViewBox';
import IdeaSingleSelectInputBoxTable from './IdeaSingleSelectInputBoxTable';
import IdeaDescriptionInputBoxTable from './IdeaDescriptionInputBoxTable';

export interface IIdeaPCBTableModalProps {
    expandStatus: any;
    idddd: any;
    getTableDataaa: any;
    // BOMValidationStatus?:any;
}

export default function IdeaPCBTableModal (props: IIdeaPCBTableModalProps) {
    const { expandStatus, idddd, getTableDataaa,} = props;
    const { workstation, topVault, vault, projectId,ideaId } = useRouteParams<any>();
    const handleSelectChangecategory = (event: any, newSystemValue: any) => {};
    const [tableData, setTableData] = React.useState<any>([]);
    const [tableDataLoader, setTableDataLoader] = React.useState<any>(false);
    const [tableDataAll, setTableDataAll] = React.useState<any>([]);
    const [selectedOptions, setSelectedOptions] = React.useState<any>([]);
    const [dataForUpdate, setDataForUpdate] = React.useState<any>([]);
    const [tableDataOptions, setTableDataOptions] = React.useState<any>([]);
    const [colorButton, setColorButton] = React.useState<any>("");
    const [showButton, setShowButton] = React.useState<any>(false);
    const [selectedTableId, setSelectedTableId] = React.useState<any>("");
    const [isDeleting, setIsDeleting] = React.useState<any>(false);
    const [selectedIdDelete, setSelectedIdDelete] = React.useState<any>("");
    const userAction = React.useContext(userPermissionAction);
    const [headerCount, setHeaderCount] = React.useState<any>([]);
    let UserAction = userAction && userAction?.action;
  
    const getObjKeyName = () => {
      if (vault == 0) {
        return { top_vault: topVault };
      } else {
        return { vault: vault };
      }
    };
    const getTableData = () => {
      setTableDataLoader(true);
      API.get(
        "/idea/idea_create_view/",
        {
          table: true,
          drop_empty: true,
          top_vault:topVault,
          id:ideaId,
          table_param_id: idddd,
        },
        0
      )
        .then((res: any) => {
          setTableDataLoader(false);
          setTableData(res.data);
        })
        .catch((err: any) => {
          setTableDataLoader(false);
        });
    };
  
    const getTableDataWOLoader = () => {
      API.get(
        "/idea/idea_create_view/",
        {
          table: true,
          top_vault:topVault,
          drop_empty: true,
          table_param_id: idddd,
          id:ideaId
        },
        0
      )
        .then((res: any) => {
          setTableData(res.data);
        })
        .catch((err: any) => {});
    };
    const getTableDataAll = () => {
      API.get(
        "/idea/idea_create_view/",
        {
          table: true,
          top_vault:topVault,
          id:ideaId
        },
        0
      )
        .then((res: any) => {
          setTableDataAll(res.data);
          setDataForUpdate([]);
        })
        .catch((err: any) => {});
    };
  
    React.useEffect(() => {
      getTableData();
      getTableDataAll();
    }, [vault, workstation]);
    const getTableRow = (
      key: any,
      count: any,
      itmKey: any,
      itmm: any,
      target: any,
      options: any
    ) => {
      let data: any = tableData
        ?.filter((itm: any) => itm.key === itmKey)[0]
        ?.header_item?.filter((i: any) => i.key === key);
      let dataBody: any = tableData?.filter((itm: any) => itm.key === itmKey)[0]
        ?.data;
      let AllData = tableDataAll?.filter((itm: any) => itm.key === itmKey)[0]
        ?.data;
      switch (data[0]?.data_type) {
        case "number":
        case "float":
        case "nonzero-positive-int":
        case "nonzero-positive-float":
        case "positive-float":
        case "nonzero-float":
        case "percentage":
        case "positive-int":
        case "integer":
          return (
            <IdeaNumberInputBoxTable
              itmm={itmm}
              key1={key && key}
              dataBody={dataBody}
              val={itmm[key]}
              data={data}
              count={count}
              setDataForUpdate={setDataForUpdate}
              target={target}
              AllData={AllData}
              setColorButton={setColorButton}
              setShowButton={setShowButton}
            />
          );
        case "text":
        case "string":
          return (
            <IdeaTextInputBoxTable
              itmm={itmm}
              key1={key && key}
              dataBody={dataBody}
              val={itmm[key]}
              data={data}
              count={count}
              setDataForUpdate={setDataForUpdate}
              target={target}
              AllData={AllData}
              setColorButton={setColorButton}
              setShowButton={setShowButton}
            />
          );
  
        case "textarea":
        case "longtext":
          return (
            <IdeaDescriptionInputBoxTable
              itmm={itmm}
              key1={key && key}
              dataBody={dataBody}
              val={itmm[key]}
              data={data}
              count={count}
              setDataForUpdate={setDataForUpdate}
              target={target}
              AllData={AllData}
              setColorButton={setColorButton}
              setShowButton={setShowButton}
            />
          );
  
        case "dropdown":
        case "singleselect":
          return (
            <IdeaSingleSelectInputBoxTable
              itmm={itmm}
              key1={key}
              dataBody={dataBody}
              val={itmm[key]}
              data={data}
              count={count}
              setDataForUpdate={setDataForUpdate}
              target={target}
              AllData={AllData}
              selectedOptions={selectedOptions}
              allOptions={options && options[0]?.allowed_values}
              setColorButton={setColorButton}
              setShowButton={setShowButton}
            />
          );
  
        case "image":
          return (
            <IdeaImageInputViewBox
              itmm={itmm}
              key1={key}
              dataBody={dataBody}
              val={itmm[key]}
              data={data}
              count={count}
              setDataForUpdate={setDataForUpdate}
              target={target}
              AllData={AllData}
              selectedOptions={selectedOptions}
              allOptions={options && options[0]?.allowed_values}
              option={options}
              getTableDataWOLoader={getTableDataWOLoader}
              itmKey={itmKey}
              setColorButton={setColorButton}
              setShowButton={setShowButton}
            />
          );
  
        case "multiselect":
          return (
            <IdeaMultiSelectInputBoxTable
              itmm={itmm}
              key1={key}
              dataBody={dataBody && dataBody}
              val={itmm && itmm[key]}
              data={data && data}
              count={count}
              setDataForUpdate={setDataForUpdate}
              target={target}
              AllData={AllData && AllData}
              selectedOptions={selectedOptions && selectedOptions}
              allOptions={options && options[0]?.allowed_values}
              option={options}
              getTableDataWOLoader={getTableDataWOLoader}
              itmKey={itmKey}
              setColorButton={setColorButton}
              setShowButton={setShowButton}
            />
          );
  
        default:
          return <></>;
      }
    };
  
    const sumationHandler = () => {
      API.get(
        "/xcpep/table_param_sum/",
        {
          vault: vault,
          parameter: idddd,
        },
        0
      )
        .then((res: any) => {
          setHeaderCount(res.data);
        })
        .catch((err: any) => {});
    };



  return (
    <div>
      {!tableDataLoader && tableData ? (
        tableData?.map((item: any, index: number) => {
          return (<>

              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  columnGap: "0.5rem",
                  width: "100%",
                  paddingRight: "0.5rem",
                }}
              >
                
                  <Typography sx={{ fontSize: "12px", paddingLeft: "0.5rem" }}>
                    {item?.table_name} ({item?.data?.length})
                  </Typography>
                
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "1rem",
                  }}
                >

                  {/* <FunctionsIcon
                    sx={{ cursor: "pointer" }}
                    titleAccess="Add all"
                    onClick={() => sumationHandler()}
                  /> */}
                  <IdeaTableOptionsInput
                    expandStatus={expandStatus}
                    tableName={item?.table_name}
                    item={item?.key}
                    getTableData={getTableData}
                    dataForUpdate={dataForUpdate}
                    tableData={tableData}
                    setTableData={setTableData}
                    dataForCheck={item?.header_item}
                    setSelectedOptionsTable={setSelectedOptions}
                    setColorButton={setColorButton}
                    colorButton={colorButton}
                    setSelectedTableId={setSelectedTableId}
                    selectedTableId={selectedTableId}
                    getTableDataAll={getTableDataAll}
                    tableDataLoader={tableDataLoader}
                    setShowButton={setShowButton}
                    showButton={showButton}
                    // BOMValidationStatus={BOMValidationStatus}
                  />
                </Box>
              </Box>
              
              <Box sx={{ width: "100%", marginTop: "0.5rem",maxHeight:{lg:'80vh',xl:'81vh'} }} 
                adl-scrollbar="true"
                adl-scrollbar-width="0.3">
                <Table stickyHeader aria-label="sticky table">
                  <TableHead sx={{ backgroundColor: "primary.light" }}>
                    <TableRow>
                      <TableCell
                        sx={{
                          width: "6rem",
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                          backgroundColor: "primary.light",
                          whiteSpace: "nowrap",
                        }}
                      >
                        Actions
                      </TableCell>
                      {Array.isArray(item?.data) &&
                        item?.data?.length > 0 &&
                        Object.keys(item?.data[0])?.map((ii: any) => {
                          //  item?.header_item?.filter((i:any)=>i.key===i)[0]

                          return (
                            <TableCell
                              sx={{
                                // width:header?.paramter_name =='Sl No.'? '4rem':'100%',
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                                backgroundColor: "primary.light",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {item?.header_item?.filter(
                                (i: any) => i.key === ii
                              )[0] &&
                                item?.header_item?.filter(
                                  (i: any) => i.key === ii
                                )[0]?.paramter_name}
                              &nbsp;
                              {headerCount[
                                item?.header_item?.filter(
                                  (i: any) => i.key === ii
                                )[0]?.paramter_name
                              ] &&
                                `(${
                                  headerCount[
                                    item?.header_item?.filter(
                                      (i: any) => i.key === ii
                                    )[0]?.paramter_name
                                  ]
                                })`}
                              &nbsp;
                              {item?.header_item?.filter(
                                (i: any) => i.key === ii
                              )[0] &&
                              item?.header_item?.filter(
                                (i: any) => i.key === ii
                              )[0]?.unit
                                ? `(${
                                    item?.header_item?.filter(
                                      (i: any) => i.key === ii
                                    )[0]?.unit
                                  })`
                                : "-"}
                            </TableCell>
                          );
                        })}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {item?.data?.map((itm: any, ind: any) => {
                      return (
                        <TableRow>
                          <TableCell
                            sx={{
                              width: "2rem",
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <LoadingButton
                              sx={{
                                padding: "0.3rem",
                                lineHeight: 1,
                                "&:hover": {
                                  transform: "Scale(1.05)",
                                  transition: "transform 0.5s ease",
                                },
                                ".MuiCircularProgress-root": {
                                  color: "primary.main",
                                },
                              }}
                              // loading={
                              //   ind == selectedIdDelete ? isDeleting : false
                              // }
                            >
                              <DeleteIcon
                                titleAccess="Delete"
                                sx={{ cursor: "pointer" }}
                                onClick={() => {
                                  // if (UserAction?.includes("D")) {
                                    setIsDeleting(true);
                                    API.get(
                                      "/idea/idea_create_view/",
                                      {
                                        table: true,
                                        top_vault:topVault,
                                        id:ideaId,
                                      },                                      
                                      0
                                    ).then((res: any) => {
                                      let data: any = res.data?.filter(
                                        (itm1: any) => itm1.key === item.key
                                      )[0]?.data;
                                      let filteredData = data.filter(
                                        (itm1: any, indd: any) => indd !== ind
                                      );
                                      Swal.fire({
                                        title: "Are you sure?",
                                        text: "You will not be able to recover this data!",
                                        icon: "warning",
                                        showCancelButton: true,
                                        confirmButtonText: "Yes, delete it!",
                                        cancelButtonText: "No, keep it",
                                        customClass: {
                                          container: "swal2Container",
                                        },
                                      }).then((result) => {
                                        if (result.isConfirmed) {
                                          setSelectedIdDelete(ind);
                                          API.put(
                                            `/idea/pool/${ideaId}/`,
                                            { ...{parameter_json:{[`${item.key}`]: filteredData}} },
                                            {
                                              ...getObjKeyName(),
                                            },
                                            0
                                          )
                                            .then((res: any) => {
                                              setIsDeleting(false);
                                              setColorButton("");
                                              setShowButton(false);
                                              ADMIN.toast.info("Deleted Successfully")
                                              getTableData();
                                              getTableDataAll();
                                            })
                                            .catch((err: any) => {
                                              setIsDeleting(false);
                                            });
                                        } else if (
                                          result.dismiss ===
                                          Swal.DismissReason.cancel
                                        ) {
                                          Swal.fire({
                                            title: 'Cancelled',
                                            text: 'Your data is safe :)',
                                            icon: 'error',
                                            customClass: {
                                              container: 'swal2Container',
                                              },
                                            });
                                        }
                                      });
                                    });
                                  // }
                                }}
                              />
                            </LoadingButton>
                          </TableCell>
                          {Object.keys(itm).map((key: any, count: any) => {
                            return (
                              <TableCell
                                sx={{
                                  padding: "0.2rem 0.5rem",
                                  borderBottomColor: "primary.light",
                                }}
                              >
                                {getTableRow(
                                  key && key,
                                  count,
                                  item?.key,
                                  itm,
                                  ind,
                                  item?.header_item?.filter(
                                    (item: any) => item.key === key
                                  )
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </Box>
              
                </>);
        })
      ) : (
        <Box sx={{ width: "100%", padding: "0.5rem", marginTop: "2rem" }}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
        </Box>
      )}
    </div>
  );
}
