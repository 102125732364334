// react
import React, { useEffect, useState } from "react";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid";
import Modal from "@mui/material/Modal";
import styles from "./AnalyseModal.module.scss";
import { ButtonProps } from "@mui/material/Button";
import { Box, Divider, Skeleton, Typography } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import useWindowDimensions from "../../ui-reusable-component/useWindowDimensions";

// Below code is used for the toolbar in DataGrid Table

let urlVal: any;
let bopdcGet:any;

const csvOptions: GridCsvExportOptions = {
  fileName: `${urlVal && urlVal?.includes('weight') ? "Weight (Kg) Comparison" : urlVal && urlVal?.includes('cost') ? `Manufacturing Cost (${sessionStorage.getItem('currency_analysis')}) Comparison`: urlVal && urlVal?.includes('kgCost') ? `Cost/Kg (${sessionStorage.getItem('currency_analysis')}) Comparison`: urlVal && urlVal?.includes('bopdc') ? `${bopdcGet && bopdcGet=='dc'?'Detailed Costing':'Bought-out Costing'} Parts Distribution Comparison (${sessionStorage.getItem('currency_analysis')})` : "Part Count (Qty) Comparison"} Table In CSV`,
  delimiter:",",
  utf8WithBom: true
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = (props: any) => (
  <GridToolbarContainer style={{ height: "2rem" }}>
    {/* <h4 style={{ position: "absolute", left: "40%" }}>{props}</h4> */}
    <span style={{ position: "absolute", right: "0rem" }}>
      <GridToolbarColumnsButton />
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);

interface AnalyseModalProps {
  isOpen: boolean;
  onClose: () => void;
  analyseWeight: any;
  typeBopDc?:any;
}

const AnalyseModal: React.FC<AnalyseModalProps> = (props) => {
  const { isOpen, onClose, analyseWeight,typeBopDc } = props;
  let [analyseWeightModalData, setAnalyseWeightModalData] = useState<any>();
  // const [analyseModalLoader, setanalyseModalLoader] = useState<boolean>(true);
  let handleOnClose = () => {
    onClose();
  }
  const { url } = useRouteMatch();
  urlVal = url;
  bopdcGet=typeBopDc && typeBopDc;
  const { height, width } = useWindowDimensions();
  useEffect(() => { setAnalyseWeightModalData(analyseWeight) }, [analyseWeight])

  // columns is a variable where column data is stored. This columns is then used as a prop in dataGrid.
  // If changeEvent is false then it for My files and true is for shared with me.

  let columns: any = [{
    field: "subsytem_name",
    headerName: "Subsytem Name",
    minWidth: width > 1370 ? 220 : 180,
    flex: 1,
  }];
  let columnHeader: any = analyseWeightModalData && Object.keys(analyseWeightModalData && analyseWeightModalData).length>0 && Object.keys(analyseWeightModalData && analyseWeightModalData[0])?.filter((item: any) => { return item != 'abbreviation' && item != "top_vault_details" && item != "subsytem_name" })
  let columnHeaderData: any = columnHeader && columnHeader?.map((item: any) => {
    columns.push({
      field: item,
      headerName: item,
      minWidth: width > 1370 ? 150 : 120,
      flex: 1,
      type: 'number'
    })
  });

  let rows: any = [];

  const dataGridDataGenerator: any = analyseWeightModalData && analyseWeightModalData?.map(({ top_vault_details, abbreviation, ...rest }: any) => {
    return rest;
  })?.map((ideaItem: any, index: any) => {
    let objShared: any = {
      id: index + 1,
      ...ideaItem
    };
    rows.push(objShared);
  });

  let dataStructureDataGrid: any = {
    columns: columns,
    rows: rows,
  };

  // all state are declared here
  return (<Modal
    open={isOpen}
    onClose={handleOnClose}
    className={styles.container}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description">
    <div className={styles.containerInside}>
      <Box
        sx={{
          height: '94vh',
          width: "100%",
          borderRadius: '1rem',
        }} >
        <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
          <Typography
            style={{
              fontWeight: "600",
              width: "100%",
              textAlign: "center",
              marginRight: '-2rem'
            }}>
            {url?.includes('weight') ? "Weight (Kg) Comparison" : url?.includes('manufacturing') ? `Manufacturing Cost (${sessionStorage.getItem('currency_analysis')}) Comparison` : url?.includes('kgCost') ? `Cost/Kg (${sessionStorage.getItem('currency_analysis')}) Comparison`: url?.includes('bopdc') ? `${typeBopDc && typeBopDc=='dc'?'Detailed Costing':'Bought-out Costing'} Parts Distribution Comparison (${sessionStorage.getItem('currency_analysis')})` : "Part Count (Qty) Comparison"}
          </Typography>
          <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={handleOnClose} />
        </Box>
        <Divider
          sx={{
            borderColor: "primary.light",
            // margin: "0 1rem",
            // marginBottom: "1rem",
          }}
        />
         {/* <> {!analyseModalLoader? <> */}
        <DataGrid
        headerHeight={48}
        rowHeight={36}
          {...dataStructureDataGrid}
          // loading={apiIdeaDataFetch}
          disableSelectionOnClick={true}
          components={{
            Toolbar: () => {
              return CustomToolbar(url?.includes('weight') ? "Weight (Kg) Comparison" : url?.includes('manufacturing') ? `Manufacturing Cost (${sessionStorage.getItem('currency_analysis')}) Comparison` : url?.includes('kgCost') ? `Cost/Kg (${sessionStorage.getItem('currency_analysis')}) Comparison`: url?.includes('bopdc') ? `${typeBopDc && typeBopDc=='dc'?'Detailed Costing':'Bought-out Costing'} Parts Distribution Comparison (${sessionStorage.getItem('currency_analysis')})` : "Part Count (Qty) Comparison");
            },
          }}
          getRowClassName={(params) => `super-app-theme--${params.row.stage}`}
          sx={{
            height:'95%',
            fontSize: "1rem",
            "&.MuiDataGrid-root.MuiDataGrid-autoHeight .MuiDataGrid-row--lastVisible .MuiDataGrid-cell":
            {
              borderBottomColor: "primary.light",
            },
            "&.MuiDataGrid-root .MuiDataGrid-cell": {
              borderBottomColor: "primary.light",
            },
          }}
          density={"compact"}
          pageSize={28}
          rowsPerPageOptions={[28]}
        />
        {/* </>
            : 
            <div>
                  <Skeleton variant="rectangular" animation="pulse" width={1100} height={630}/>
            </div>
        }</> */}
      </Box>
    </div>
  </Modal>);
};

export default AnalyseModal;
