import * as React from "react";
import { Box, Button, Popover, Typography, Tooltip } from "@mui/material";
import { DataGrid, GridColDef } from "@mui/x-data-grid";

// Define the structure of each result in the fetch array
interface IResult {
  id: number;
  calc_name: string;
  field_name: string;
  formula: string;
  address: string;
  usage_info_count: any;
  usage_info: any;
}

interface IFetchedInfoProps {
  fetch: IResult[]; // Data array
  value_type: string; // Min, Max, Avg, Sum, Individual, etc.
}

const FetchedInfoDisplay: React.FC<IFetchedInfoProps> = ({
  fetch,
  value_type,
}) => {
  // Popover state
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [popoverContent, setPopoverContent] = React.useState<IResult[]>([]);

  // Handle click for popover
  const handlePopoverClick = (
    event: React.MouseEvent<HTMLElement>,
    content: any
  ) => {
    setAnchorEl(event.currentTarget);
    setPopoverContent(content);
  };

  // Handle closing of popover
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  // Define the columns for the DataGrid inside the popover
  const popoverColumns: any = [
    {
      field: "calc_name",
      headerName: "Cost Model Name",
      flex: 1,
      renderCell: (params: any) => (
        <Tooltip title={params.value} arrow>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: "field_name",
      headerName: "Field Name",
      flex: 1,
      renderCell: (params: any) => (
        <Tooltip title={params.value} arrow>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: "address",
      headerName: "Source Address",
      flex: 2,
      renderCell: (params: any) => (
        <Tooltip title={params.value} arrow>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
  ];

  // Define the columns for the main DataGrid
  const columns: GridColDef[] = [
    { field: "calc_name", headerName: "Cost Model Name", flex: 1 },
    { field: "field_name", headerName: "Field Name", flex: 1 },
    { field: "formula", headerName: "Formula", flex: 1.5 },
    { field: "address", headerName: "Source Address", flex: 2 },
    {
      field: "usage_info_count",
      headerName: "Usage",
      flex: 1,
      renderCell: (params: any) => (
        <Button
          sx={{
            textDecoration: params?.value > 0 ? "underline" : "none",
            cursor: "pointer",
            color: "#007fff",
          }}
          onClick={(e) => {
            if (params?.value > 0) {
              handlePopoverClick(e, params?.row?.usage_info);
            }
          }}
        >
          {params?.value} CM
        </Button>
      ),
    },
  ];

  // Map the fetch array to rows for the DataGrid
  const rows = fetch?.map((row) => ({
    id: row.id,
    calc_name: row.calc_name,
    field_name: row.field_name,
    formula: row.formula,
    address: row.address,
    usage_info_count: row.usage_info_count,
    usage_info: row.usage_info,
  }));

  return (
    <Box
      sx={{
        p: 2,
        backgroundColor: "#f9f9f9",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {/* Display fetched data using DataGrid */}
      {fetch && fetch.length > 0 && (
        <Box
          sx={{
            height: 400, // Set a fixed height
            width: "100%",
            backgroundColor: "#fff",
            p: 2,
            borderRadius: "8px",
            boxShadow: 3,
          }}
        >
          <DataGrid
            rows={rows}
            columns={columns}
            // Set a large page size and remove pagination
            pageSize={rows.length}
            hideFooterPagination
            hideFooter
            autoHeight={false}
            disableSelectionOnClick
            disableColumnMenu
            sx={{
              "& .MuiDataGrid-root": {
                overflowY: "auto", // Enable vertical scrolling
              },
            }}
          />
        </Box>
      )}

      {/* Popover Component with DataGrid */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box sx={{ p: 2, width: 800, height: 250 }}>
          <Typography variant="h6" gutterBottom>
            Details
          </Typography>
          <DataGrid
            //@ts-ignore
            rows={popoverContent}
            columns={popoverColumns}
            pageSize={popoverContent.length}
            hideFooterPagination
            hideFooter
            autoHeight={false}
            disableSelectionOnClick
            disableColumnMenu
            sx={{
              "& .MuiDataGrid-root": {
                overflowY: "auto", // Enable vertical scrolling in the popover as well
              },
            }}
          />
        </Box>
      </Popover>
    </Box>
  );
};

export default FetchedInfoDisplay;
