// vendors
import { createEntityAdapter, createReducer, EntityState } from "@reduxjs/toolkit";
import { ID } from "../../utlis/commonInterfaces";
import { createOverheadCardCost, deleteOverheadCardCost, getOverheadCardCosts, resetOverheadCard, updateOverheadCardCost } from "../Actions/overheadCard.action";
import { ADMIN } from "../Services/admin.service";
import { RootState } from "../Store/storeConfigurations";



// -----------------------------------------------------------------------------------
// entity adaptor

export interface OverheadCardCost {
    id: ID;
    result: string | null;
    sequence?:any;
    readonly cost: number;
    alias: string;
    quantity: number;

    /** ids for redirection(has no other inportance) */
    readonly link: {
        commodity_mapping: ID;
        process: ID;
    } | null;
}

const collator = new Intl.Collator( undefined, { numeric: true, sensitivity: "base" } );

const adaptor = createEntityAdapter<OverheadCardCost>( {
    selectId: ( overheadCard ) => overheadCard.id,
} );

// -----------------------------------------------------------------------------------
// interfaces & initialState

type AdditionalFields = {};

type IOverheadCardReducer = EntityState<OverheadCardCost> & AdditionalFields;

const additionalFields: AdditionalFields = {};

const initialState: IOverheadCardReducer = Object.assign( {}, adaptor.getInitialState(), additionalFields );

// -----------------------------------------------------------------------------------
// reducer

const OverheadCardReducer = createReducer( initialState, builder => {
    // get
    builder
        .addCase( getOverheadCardCosts.fulfilled, ( state, action: any ) => {
            adaptor.setAll( state, action.payload.costs );
        } )
        .addCase( getOverheadCardCosts.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could not fetch costs" );
        } );

    // create
    builder
        .addCase( createOverheadCardCost.fulfilled, ( state, action: any ) => {
            adaptor.addOne( state, action.payload.cost );

            // ADMIN.toast.success( "Raw overhead cost(s) created" );
        } )
        .addCase( createOverheadCardCost.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could not create cost" );
        } );


    // update
    builder
        .addCase( updateOverheadCardCost.fulfilled, ( state, action: any ) => {
            adaptor.upsertOne( state, action.payload.cost );

            // ADMIN.toast.success( "Raw overhead cost updated" );
        } )
        .addCase( updateOverheadCardCost.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could not update cost" );
        } );

    // delete
    builder
        .addCase( deleteOverheadCardCost.fulfilled, ( state, action ) => {
            const { id } = action.meta.arg;
            adaptor.removeOne( state, id );

            // ADMIN.toast.success( "Raw overhead cost deleted" );
        } )
        .addCase( deleteOverheadCardCost.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could not delete cost" );
        } );

    // reset
    builder.addCase( resetOverheadCard, () => initialState );
} );

// -----------------------------------------------------------------------------------
// selectors

const selectors = adaptor.getSelectors<RootState>( ( state ) => state.overheadCard );

const overheadCardSelectors = Object.assign( {}, {}, selectors );

// -----------------------------------------------------------------------------------
// exports

export {
    OverheadCardReducer,
    initialState as OverheadCardReducerInit,
    overheadCardSelectors
};