import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Chip, Skeleton } from '@mui/material';
import SendIcon from "@mui/icons-material/Send";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import sendIcon from "../../Assets/images/sendOutline.png";
import { API } from '../../api-services';



const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: " 10px",
    height: "90vh",
    width: "80vw",
    // overflowY: "scroll",
    p: 1,
};



export interface PreviewMailModalProps {

    onCloseModal: any;
    isOpen: any;
    rowIds: any;




}



export function PreviewMailLogsModal(props: PreviewMailModalProps) {

    const { onCloseModal, isOpen, rowIds,
    } = props


    const [PrevieWData, setPreviewData] = React.useState<any>([])

    const [loader, setLoader] = React.useState<any>(false)
    const sendmail = () => {
        onCloseModal()
    }


    React.useEffect(() => {

        setLoader(true)
        API.get("/activity/mail_log_view/", { id: rowIds }, 0).then((res: any) => {
            setLoader(false)
            setPreviewData(res?.data)
        }).catch((err: any) => {
            setLoader(false)
        })
    }, [rowIds])


    return (
        <div>
            <Modal
                open={isOpen}

                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box>
                        <Box sx={{
                            display: "flex",
                            alignItems: 'center',
                            justifyContent: "space-between",
                            position: 'sticky', top: '0',
                            backgroundColor: '#ffffff'
                        }}>
                            <Typography sx={{ fontSize: '1rem', width: '100%', textAlign: 'center' }}> Mail Preview</Typography>
                            <Box sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                gap: "1rem"
                            }}>

                                <HighlightOffIcon
                                    onClick={() => onCloseModal()}
                                    sx={{ cursor: "pointer" }}
                                />
                            </Box>
                        </Box>


                        <Box sx={{ padding: '0 1rem', height: '82vh' }}
                            adl-scrollbar="true"
                            adl-scrollbar-width="0.3">

                            {loader ? <Box sx={{ width: "100%" }}>
                                <Skeleton />
                                <Skeleton animation="wave" />
                                <Skeleton animation={false} />
                                <Skeleton />
                                <Skeleton animation="wave" />
                                <Skeleton animation={false} />
                                <Skeleton />
                                <Skeleton animation="wave" />
                                <Skeleton animation={false} />
                                <Skeleton />
                                <Skeleton animation="wave" />
                                <Skeleton animation={false} />
                                <Skeleton />
                                <Skeleton animation="wave" />
                                <Skeleton animation={false} />
                                <Skeleton />
                                <Skeleton animation="wave" />
                                <Skeleton animation={false} />
                                <Skeleton />
                                <Skeleton animation="wave" />
                                <Skeleton animation={false} />
                                <Skeleton />
                                <Skeleton animation="wave" />
                                <Skeleton animation={false} />
                                <Skeleton />
                                <Skeleton animation="wave" />
                                <Skeleton animation={false} />
                                <Skeleton />
                                <Skeleton animation="wave" />
                                <Skeleton animation={false} />
                                <Skeleton />
                                <Skeleton animation="wave" />
                                <Skeleton animation={false} />
                                <Skeleton />
                                <Skeleton animation="wave" />
                                <Skeleton animation={false} />
                                <Skeleton />
                                <Skeleton animation="wave" />
                                <Skeleton animation={false} />

                            </Box> :

                                <><div style={{ marginBottom: '1rem' }}>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            gap: "1rem",
                                            marginBottom:'0.5rem',
                                        }}


                                    >
                                        <Typography sx={{ width: '6rem', textAlign: 'right', fontSize: '1rem' }}>To :</Typography>
                                        <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '10px', flexWrap: 'wrap', width: '100%' }}> {PrevieWData && PrevieWData[0]?.to_mails?.map((item: any, index: any) => {
                                            return (<>

                                                <Chip size='small' sx={{ borderColor: 'primary.main' }} label={item} variant="outlined" />
                                            </>);
                                        })}
                                        </Box>
                                    </div>
                                    <div

                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            gap: "1rem",
                                            marginBottom:'0.5rem',
                                        }}
                                    >


                                        <Typography sx={{ width: '6rem', textAlign: 'right', fontSize: '1rem' }}>Cc:</Typography>
                                        <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '10px', flexWrap: 'wrap', width: '100%' }}>
                                            {PrevieWData && PrevieWData[0]?.cc_mails?.map((item: any, index: any) => {
                                                return (<>

                                                    <Chip size='small' sx={{ borderColor: 'primary.main' }} label={item} variant="outlined" />
                                                </>);
                                            })}
                                        </Box>
                                    </div>
                                    <div

                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            gap: "1rem"
                                        }}

                                    ><Typography sx={{ width: '6rem', textAlign: 'right', fontSize: '1rem' }}>Subject :</Typography>

                                        <p>
                                            {PrevieWData && PrevieWData[0]?.subject}
                                        </p>
                                    </div>  <div


                                        style={{
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                            gap: "1rem"
                                        }}
                                    >


                                        <Typography sx={{ width: '6rem', textAlign: 'right', fontSize: '1rem' }}>Attachments: </Typography>
                                        {PrevieWData && PrevieWData[0]?.attachment && PrevieWData[0]?.attachment?.map((item: any, index: any) => {
                                            return (<>
                                                <Chip size='small' sx={{ borderColor: 'primary.main' }} label={item?.file_name} variant="outlined"

                                                    onClick={() => window.open(item?.file_url, "_blank")} />
                                            </>);
                                        })}

                                    </div>
                                </div><div dangerouslySetInnerHTML={{ __html: PrevieWData && PrevieWData[0]?.mail_body }}></div></>}
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}
