import * as React from "react";
import {
  Avatar,
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import avatar from "../../Assets/images/maleavatar.png";
import CloseIcon from "@mui/icons-material/Close";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import { useHistory } from "react-router-dom";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { API } from "../../api-services";
import {
  GeneralModules,
  RoleList,
  RoleListAll,
  projectSpecificModules,
} from "../../Constants/projectspecificmodules.constants";
import { LoadingButton } from "@mui/lab";
import Image from "../../ViewModule/FeatureLandingPage/ArchitectureImageCard";
import LoadingImage from "../../Assets/images/loading-screen.gif";
import { EditGeneralRole } from "./EditGeneralRole";
import { useSelector } from "react-redux";

export interface IGeneralPermissionProps {}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export function GeneralPermission(props: IGeneralPermissionProps) {
  const { projectId, moduleId, filterOPtion } = useRouteParams<any>();
  const history = useHistory();
  const store: any = useSelector((state) => state);
  // console.log("modulessssssss",modules)
  const [users, setUsers] = React.useState<any>([]);
  const [module, setModule] = React.useState<any>([]);
  const [expanded, setExpanded] = React.useState<string | false>(moduleId);
  const [userList, setUserList] = React.useState<any>([]);
  const [productList, setProductList] = React.useState<any>([]);
  const [products, setProducts] = React.useState<any>([]);
  const [roles, setRoles] = React.useState<any>([]);
  const [permissionList, setPermissionList] = React.useState<any>([]);
  const [permissionApiCallCount, setPermissionApiCallCount] =
    React.useState<any>(0);
  const [isPermissionListLoading, setIsPermissionListLoading] =
    React.useState<boolean>(false);

  // const handleChange =
  //   (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
  //     setExpanded(newExpanded ? panel : false);
  //     history.push(`/user-permissions/${projectId}/${panel}/${filterOPtion}`);
  //   };

  const getUserList = () => {
    if(filterOPtion !== "all"){
      API.get(
        "/auth/user/",
        { is_active: true, is_superuser: false, sub_module: "General", user_category: filterOPtion},
        0
      )
        .then((res: any) => {
          setUserList(res.data);
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
    else{
      API.get(
        "/auth/user/",
        { is_active: true, is_superuser: false, sub_module: "General"},
        0
      )
        .then((res: any) => {
          setUserList(res.data);
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
    
  };

  React.useEffect(() => {
    getUserList();
  }, [filterOPtion]);

  React.useEffect(() => {
    if (moduleId === "Project Specific Modules") {
      setExpanded(false);
    }
  }, [moduleId]);

  const handleSelectChange = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    setUsers(value);
  };

  const handleSelectChangeModule = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    setModule(value);
  };

  const handleSelectChangeProduct = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    setProducts(value);
  };

  const handleSelectChangeRole = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    setRoles(value);
  };
  React.useEffect(() => {
    setUsers([]);
    setRoles([]);
    setProducts([]);
    setModule([]);
  }, [projectId, moduleId]);

  const handleSubmit = () => {
    API.post(
      "/auth/assign_permission/",
      {
        user_list: users,
        submodule_list: module,
        module: "General",
        action: roles,
      },
      {},
      0
    )
      .then((res: any) => {
        setPermissionApiCallCount((prev: any) => prev + 1);
        setUsers([]);
        setRoles([]);
        setProducts([]);
        setModule([]);
      })
      .catch((err: any) => {
        console.log("permission error: " + err);
      });
  };
  const getPermissionsData = () => {
    setIsPermissionListLoading(true);
    API.get(
      "/auth/assign_permission/",
      {
        submodule: "General",
        user_type: filterOPtion,
        action:
          store?.is_compare?.permission_filter?.length > 0
            ? store?.is_compare?.permission_filter?.toString()
            : undefined,
        user_id:
          typeof store?.is_compare?.selected_userId !== "string"
            ? store?.is_compare?.selected_userId
            : undefined,
      },
      0
    )
      .then((res: any) => {
        setPermissionList(res.data);
        setIsPermissionListLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setIsPermissionListLoading(false);
      });
  };
  React.useEffect(() => {
    getPermissionsData();
  }, [
    permissionApiCallCount,
    store?.is_compare?.selected_userId,
    filterOPtion,
    store?.is_compare?.permission_filter,
  ]);

  var AvatarName = (item: any) => {
    const [first, last] = item?.split(" ");
    const FirstLatter = first?.charAt(0);
    const lastWord = last?.charAt(0);
    return `${FirstLatter}${lastWord}`;
  };

  return (
    <div>
      <Table>
          <TableBody>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottom: "none",
                  width: "2vw",
                }}
              ></TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottom: "none",
                  width: "4vw",
                }}
              ></TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottom: "none",
                  width: "15vw",
                }}
              >
                <FormControl fullWidth variant="standard">
                  <InputLabel id="demo-simple-select-label">
                    Select Users
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={users && users}
                    multiple
                    multiline
                    label="User"
                    onChange={handleSelectChange}
                    size="small"
                    placeholder="Select Users"
                    sx={{
                      "&:before": {
                        borderBottomColor: "primary.main",
                      },
                      ".MuiSvgIcon-root.MuiSelect-icon": {
                        color: "primary.main",
                      },
                    }}
                    renderValue={(selected) => (
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 0.5,
                        }}
                      >
                        {userList
                          .filter((item: any) => selected?.includes(item?.id))
                          ?.map((value: any) => {
                            return (
                            <Chip

                              size="small"
                              key={value.id}
                              
                              label={`${value?.first_name} ${value?.last_name}`}
                              onDelete={() =>
                                setUsers((prev: any) =>
                                  prev?.filter((item: any) => value.id !== item)
                                )
                              }
                              deleteIcon={
                                <CancelIcon
                                  onMouseDown={(event) =>
                                    event.stopPropagation()
                                  }
                                />
                              }
                            />
                          )})}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {userList &&
                      userList?.map((item: any, index: number) => {
                        return (
                          !users?.includes(item?.id) && (
                            <MenuItem key={index} value={item?.id} sx={{color : item?.user_category == "External" ? "red" : "primary.main"}}>
                              {item?.first_name} {item?.last_name}
                            </MenuItem>
                          )
                        );
                      })}
                  </Select>
                </FormControl>
              </TableCell>

              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottom: "none",
                  width: "20vw",
                }}
              >
                <FormControl fullWidth variant="standard">
                  <InputLabel id="demo-simple-select-label">
                    Select Module
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={module && module}
                    multiple
                    multiline
                    //   label="Module"
                    placeholder="Select Module"
                    onChange={handleSelectChangeModule}
                    size="small"
                    sx={{
                      "&:before": {
                        borderBottomColor: "primary.main",
                      },
                      ".MuiSvgIcon-root.MuiSelect-icon": {
                        color: "primary.main",
                      },
                    }}
                    renderValue={(selected) => (
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 0.5,
                        }}
                      >
                        {GeneralModules.filter((item: any) =>
                          selected?.includes(item)
                        )?.map((value: any) => (
                          <Chip
                            size="small"
                            key={value}
                            label={value}
                            onDelete={() =>
                              setModule((prev: any) =>
                                prev?.filter((item: any) => value !== item)
                              )
                            }
                            deleteIcon={
                              <CancelIcon
                                onMouseDown={(event) => event.stopPropagation()}
                              />
                            }
                          />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {GeneralModules &&
                      GeneralModules?.map((item: any, index: number) => {
                        return (
                          !module.includes(item) && (
                            <MenuItem key={index} value={item}>
                              {item}
                            </MenuItem>
                          )
                        );
                      })}
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottom: "none",
                  width: "20vw",
                }}
              >
                <FormControl fullWidth variant="standard">
                  <InputLabel id="demo-simple-select-label">
                    Select roles
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={roles && roles}
                    multiple
                    multiline
                    label="Role"
                    placeholder="Select Role"
                    onChange={handleSelectChangeRole}
                    size="small"
                    sx={{
                      "&:before": {
                        borderBottomColor: "primary.main",
                      },
                      ".MuiSvgIcon-root.MuiSelect-icon": {
                        color: "primary.main",
                      },
                    }}
                    renderValue={(selected) => (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexWrap: "wrap",
                          gap: 0.5,
                        }}
                      >
                        {RoleListAll.filter((item: any) =>
                          selected?.includes(item?.value)
                        )?.map((value: any) => (
                          <Chip
                            size="small"
                            sx={{ height: "1.5rem" }}
                            key={value.value}
                            label={value.value}
                            onDelete={() =>
                              setRoles((prev: any) =>
                                prev?.filter(
                                  (item: any) => value.value !== item
                                )
                              )
                            }
                            deleteIcon={
                              <CancelIcon
                                onMouseDown={(event) => event.stopPropagation()}
                              />
                            }
                          />
                        ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {RoleListAll &&
                      RoleListAll?.map((item: any, index: number) => {
                        return (
                          !roles?.includes(item.value) && (
                            <MenuItem key={index} value={item?.value}>
                              {item.text}
                            </MenuItem>
                          )
                        );
                      })}
                  </Select>
                </FormControl>
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottom: "none",
                  width: "4vw",
                  textAlign: "center",
                }}
              >
                <LoadingButton
                  sx={{ width: "1rem" }}
                  disabled={
                    users?.length === 0 || module?.length === 0 ? true : false
                  }
                  onClick={() => handleSubmit()}
                >
                  <SaveIcon titleAccess="Save" />
                </LoadingButton>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        {!isPermissionListLoading ? <Box
        sx={{ maxHeight: {lg:'72vh',xl:"79vh" }}}
        adl-scrollbar="true"
        adl-scrollbar-width="0.3"
      >        
        <Table>
          <TableBody>
            {permissionList?.map((item: any, index: number) => {
              return (
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottom: "none",
                      width: "2vw",
                    }}
                  >
                    {index + 1}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottom: "none",
                      width: "4vw",
                      // textAlign: "left",
                    }}
                  >
                    {item && item.image ? (
                      <Image
                        alt="UserImg"
                        placeholderImg={LoadingImage}
                        src={item?.image}
                        style={{
                          width: "2rem",
                          height: "2rem",
                          borderRadius: "50%",
                          objectFit: "cover",
                        }}
                      />
                    ) : (
                      <Avatar
                        src="/broken-image.jpg"
                        sx={{
                          width: { xs: "2rem", sm: "2rem" },
                          height: { xs: "2rem", sm: "2rem" },
                          fontSize: "1rem !important",
                          objectFit: "cover",
                          backgroundColor: "#007fff !important",
                          textTransform: "uppercase",
                          " .MuiAvatar-root.MuiAvatar-circular": {
                            backgroundColor: "primary.main",
                          },
                        }}
                      >
                        {AvatarName(item?.fullname)}
                      </Avatar>
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottom: "none",
                      width: "15vw",
                      textAlign: "left",
                    }}
                  >
                    <Typography sx={{ fontSize: "1rem" }}>
                      {item?.fullname}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottom: "none",
                      width: "20vw",
                      textAlign: "left",
                    }}
                  >
                    <Typography sx={{ fontSize: "1rem" }}>
                      {" "}
                      {item?.submodule}
                    </Typography>
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottom: "none",
                      width: "20vw",
                    }}
                  >
                    {item?.actions[0] !== "N" ? (
                      <EditGeneralRole
                        action={item.actions}
                        itemId={item.id}
                        roleOptions={RoleListAll}
                        submodule={item?.submodule}
                      />
                    ) : (
                      item?.actions
                    )}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottom: "none",
                      width: { lg: "6vw", xl: "4vw" },
                      textAlign: "center",
                    }}
                  >
                    <CloseIcon
                      sx={{ color: "red", cursor: "pointer" }}
                      onClick={() => {
                        API.delete(
                          "/auth/assign_permission/",
                          { ids: item?.id },
                          0
                        ).then((res: any) => {
                          setPermissionApiCallCount((prev: any) => prev + 1);
                        });
                      }}
                    />
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      </Box> :<Box sx={{width:'100%',padding:'0 0.5rem'}}>
              <Skeleton animation="pulse" sx={{ height: "3rem" }} />
              <Skeleton animation="pulse" sx={{ height: "3rem" }} />
              <Skeleton animation="pulse" sx={{ height: "3rem" }} />
              <Skeleton animation="pulse" sx={{ height: "3rem" }} />
              <Skeleton animation="pulse" sx={{ height: "3rem" }} />
              <Skeleton animation="pulse" sx={{ height: "3rem" }} />
              <Skeleton animation="pulse" sx={{ height: "3rem" }} />
              <Skeleton animation="pulse" sx={{ height: "3rem" }} />
              <Skeleton animation="pulse" sx={{ height: "3rem" }} />
              <Skeleton animation="pulse" sx={{ height: "3rem" }} />
              <Skeleton animation="pulse" sx={{ height: "3rem" }} />
              <Skeleton animation="pulse" sx={{ height: "3rem" }} />
            </Box> }
    </div>
  );
}
