import * as React from "react";
import {
  Box,
  Checkbox,
  Chip,
  debounce,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  Skeleton,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory, useRouteMatch } from "react-router-dom";
import styles from "./FeatureConfigurationLanding.module.scss";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import ShareIcon from "@mui/icons-material/Share";
import FeatureConfiguration from "./FeatureConfiguration";
import CancelIcon from "@mui/icons-material/Cancel";
import { API } from "../../api-services";
import { useBaseModal } from "../../ComponentUI/SearchPage/useBaseModal";
import AddCategoryModal from "./AddCategoryModal";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import InfoIcon from "@mui/icons-material/Info";
import { useEffect, useState } from "react";
import ShareCategoryModal from "./ShareCategoryModal";
import CostingConfigurationDrawer from "../Costing/CostingConfigurationDrawer";
import ShareParameterModal from "./ShareParameterModal";
import ErrorModalFilesMail from "../../ComponentUI/ProjectMailModule/ErrorModalFilesMail";
import swal from "sweetalert";
import AddFeatureParameterModal from "./AddFeatureParameterModal";
import { LoadingButton } from "@mui/lab";
import Swal from "sweetalert2";
import validated from "../../Assets/images/Validated.svg";
import notvalidated from "../../Assets/images/NotValidated.svg";
import { useDocumentTitle } from "../../ui-reusable-component/useDocumentTitle";
import { styled } from '@mui/material/styles';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion, { AccordionProps } from '@mui/material/Accordion';
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import FolderIcon from '@mui/icons-material/Folder';
import openFolder from '../../Assets/images/svgs/openfolderblue.svg';
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `0px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&::before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'white',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(0deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: '0px solid rgba(0, 0, 0, .125)',
}));


type Anchor = "top" | "left" | "bottom" | "right";
export const UserContextFeature = React.createContext<any>(null);
export interface IIdeaConfigurationLandingProps { }
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}
export const lockContext = React.createContext<any>(false)
export function FeatureConfigurationLanding(
  props: IIdeaConfigurationLandingProps
) {
  const history = useHistory();
  const {
    projectId,
    projectname,
    categoryId,
    productId,
    datatype,
    validationId,
    categoryname,
    mainId,
    paramType,
  } = useRouteParams<any>();
  const { url } = useRouteMatch();
  useDocumentTitle("Feature Config");
  const theme = useTheme();
  const ParameterModals = useBaseModal();
  const addcategoryUpop = useBaseModal();
  const sharepopup = useBaseModal();
  const ErrorModals = useBaseModal();

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [categoryIds, setCategoryIds] = useState<any>(null);
  const [ErrorModalFileMsg, setErrorModalFileMsg] = useState<any>();

  const [validationdata, setValidationdata] = useState<any>();
  const [CategoryCount, setcategoryCount] = useState<any>(0);
  const ParametershareModals = useBaseModal();
  const [CategoryData, setCategoryData] = useState<any>([]);
  const [loader, setLoader] = useState<any>(false);
  const [Premisssionaction, setPremissionaction] = useState<any>(null);
  const [FeatureCounter, setFeatureCounter] = useState<any>(0);
  const [DownloadLoader, setDownloadLoader] = useState<any>(false);
  const [ParentCount, setParentCount] = useState<any>(0);
  const [expanded, setExpanded] = React.useState<string | false>('');
  // const [isLoadingcategory, setIsLoadingcategory] = useState<boolean>(false);
  // const [ParmeterList, setParameterList] = useState<any>([]);
  const [Datatypelist, setDatatypelist] = useState<any>([]);
  const [datatypeLoader, setdatatypeLoader] = useState<any>(false);
  const [selectedTab, setSelectedTab] = useState<any>(0);
  const [lock,setlock]=React.useState<any>(false)


  // const handleChange =
  //   (item: any) => (event: React.SyntheticEvent, newExpanded: boolean) => {
  //     setExpanded(newExpanded ? item?.category : false);
  //     getDataType(item?.category);
  //     history.push(`/feature-configuration/${projectId}/${projectname}/${item?.id}/${item?.category}/${item?.name}/0/0`);
  //   };

  useEffect(() => {
    const savedExpanded = sessionStorage.getItem('expandedAccordionFeature');

    if (savedExpanded) {
      setExpanded(JSON.parse(savedExpanded)); // Load saved expanded state
    } else if (CategoryData?.length > 0) {
      setExpanded(CategoryData[0]?.category); // Expand the first Accordion if no state is saved
    }
  }, [CategoryData]);

  const handleChange =
    (item: any) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      const newAccordionState = newExpanded ? item?.category : false;

      // Set the expanded state for the Accordion
      setExpanded(newAccordionState);

      // Save the expanded state to localStorage
      sessionStorage.setItem('expandedAccordionFeature', JSON.stringify(newAccordionState));

      // Fetch related data when the Accordion is expanded
      if (newExpanded) {
        getDataType(item?.category);
      }

      // Update the URL with the necessary parameters
      history.push(`/feature-configuration/${projectId}/${projectname}/${item?.id}/${item?.category}/${item?.name}/0/0`);
    };

  React.useEffect(() => {
    API.get(
      "/auth/calibration_user_permission/",
      {
        action: true,
        configuration: true,
        project: projectId,
        module: "Feature Configuration",
      },
      0
    )
      .then((res: any) => {
        setPremissionaction(res.data.action);
      })
      .catch((err: any) => { });
  }, [projectId]);

  // console.log("CategoryDataCategoryData",CategoryData)
  React.useEffect(()=>{
    setlock(()=>CategoryData?.filter((item:any)=>item?.category == categoryId)[0]?.lock)
  },[CategoryData,categoryId])

  // console.log(
  //   Premisssionaction && Premisssionaction?.includes("C"),
  //   "Premisssionaction"
  // );
  useEffect(() => {
    setLoader(true);
    API.get("/config/feature_config/", { module: 17, project: projectId })
      .then((res: any) => {
        setCategoryData(res?.data);
        setLoader(false);
      })
      .catch((err: any) => {
        setLoader(false);
        console.log("Srver Error");
      });
  }, [projectId, CategoryCount, FeatureCounter, ParentCount]);


  const Parameterhandler = (categoryId?: any, sqe?: any) => {
    ParameterModals.open();
    setCategoryIds(categoryId);
  };

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }

        setState({ ...state, [anchor]: open });
      };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
    // onClick={toggleDrawer(anchor, false)}
    // onKeyDown={toggleDrawer(anchor, false)}
    >
      <CostingConfigurationDrawer />
    </Box>
  );

  const handleClick = () => {
    history.goBack();
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const ShareHandler = () => {
    sharepopup.open();
  };
  const CategoryHandler = (item: any) => {
    history.push(
      `/feature-configuration/${projectId}/${projectname}/${item?.id}/${item?.category}/${item?.name}/0/0`
    );
  };
  const DataTypeHandler = (item: any) => {
    history.push(
      `/feature-configuration/${projectId}/${projectname}/${mainId}/${categoryId}/${categoryname}/${item?.parameter__data_type}/${paramType}`
    );
  };

  const handleChangeCheckBox = (type: any, Id: any) => {
    if (type == "check") {
      const checkboxvalue =
        CategoryData &&
        CategoryData?.filter((item: any, index: any) => {
          return item?.category == categoryId;
        })[0];
      API.patch("/config/feature_config/", {
        id: mainId,
        validated: false,
      })
        .then((res: any) => {
          setValidationdata(res?.data?.validated);
        })
        .catch((err: any) => {
          const { data } = err?.response;
          setValidationdata(false);
        });
    }
    if (type == "Uncheck") {
      const checkboxvalue =
        CategoryData &&
        CategoryData?.filter((item: any, index: any) => {
          return item?.category == categoryId;
        })[0];

      API.patch("/config/feature_config/", {
        id: mainId,
        validated: false,
      })
        .then((res: any) => {
          setValidationdata(res?.data?.validated);
          setcategoryCount((prev: any) => prev + 1);
        })
        .catch((err: any) => {
          const { data } = err?.response;
          setValidationdata(false);
        });
    }
    if (type == "main") {
      const checkboxvalue =
        CategoryData &&
        CategoryData?.filter((item: any, index: any) => {
          return item?.category == categoryId;
        })[0];
      API.patch("/config/feature_config/", {
        id: mainId,
        validated: !validationdata,
      })
        .then((res: any) => {
          setValidationdata(res?.data?.validated);
          setcategoryCount((prev: any) => prev + 1);
        })
        .catch((err: any) => {
          const { data } = err?.response;
          setValidationdata(false);

          Swal.fire({
            icon: "error",
            html: `<div>
      <br />
      <p style="color:red;">${data[0]}</p>   
       </div>`,
          });
        });
    }
  };
  const ParameterShareHandler = (id: any) => {
    ParametershareModals.open();
    setCategoryIds(id);
  };
  const Validationhandlerparameter = () => {
    if (validationdata && validationdata?.includes(categoryId)) {
      setValidationdata((prev: any) =>
        prev.filter((item: any) => item !== categoryId)
      );
    }
    API.patch("/config/feature_config/", {
      id: mainId,
      validated: false,
    })
      .then((res1: any) => {
        setcategoryCount((prev: any) => prev + 1);
      })
      .catch((err: any) => { });
  };

  useEffect(() => {
    const checkboxvalue =
      CategoryData &&
      CategoryData?.filter((item: any, index: any) => {
        return item?.category == categoryId;
      })[0];

    setValidationdata(checkboxvalue?.is_validated);
  }, [CategoryData, categoryId]);


  const CategoryDeleteHandler = (id: any) => {
    var checkedData =
      CategoryData &&
      CategoryData?.filter((item: any, indx: any) => {
        return item?.id !== id;
      });

    var collectIds =
      checkedData &&
      checkedData?.map((item: any, index: any) => {
        return item?.id;
      });

    swal({
      title: `Delete Parameter`,
      text: `Are you sure?`,
      icon: "warning",

      buttons: ["Cancel", "Ok"],
    })
      .then((confirm) => {
        if (confirm) {
          API.delete(`/config/feature_config/`, { id: id }).then((res: any) => {
            API.get("/config/feature_config/", {
              module: 17,
              project: projectId,
            })
              .then((res: any) => {
                setCategoryData(res?.data);
              })
              .catch((err: any) => {
                console.log("Srver Error");
              });
          });
        }
      })
      .catch((e: any) => { });
  };

  const downloadhandler = () => {
    setDownloadLoader(true);
    API.get(
      "/config/parameter_mapping_download/",
      { project: projectId, module: 17 },
      0
    )
      .then((res: any) => {
        window.open(res?.data);
        setDownloadLoader(false);
      })
      .catch((err: any) => {
        setDownloadLoader(false);
        console.log("Sever Error");
      });
  };


  const LockHandlerAction = (item: any, type: any) => {
    if (type == false) {
      API.patch(`/config/feature_config/`, {
        lock: type,
        id: item?.id,
        top_vault: productId?.toString(),
        validated: item?.is_validated,
      }).then((res: any) => {
        // history.push(
        //   `/feature-configuration/${projectId}/${projectname}/${mainId}/${categoryId}/${categoryname}/${res?.data[0]?.parameter__data_type}/${paramType}`
        // );
        setParentCount((prev: any) => prev + 1);
      }).catch((err: any) => {
        console.log("Server error")
      })
    }
    if (type == true) {
      API.patch(`/config/feature_config/`, {
        lock: type,
        id: item?.id,
        top_vault: productId?.toString(),
        validated: item?.is_validated,
      }).then((res: any) => {
        // history.push(
        //   `/feature-configuration/${projectId}/${projectname}/${mainId}/${categoryId}/${categoryname}/${res?.data[0]?.parameter__data_type}/${paramType}`
        // );
        setParentCount((prev: any) => prev + 1);
      }).catch((err: any) => {
        console.log("Server error")
      })
    }
  }

  const getDataType = (datatype: any) => {
    setdatatypeLoader(true);
    API.get("/config/parameter_datatype_config/", {
      module: 17,
      category: categoryId,
      project: projectId,
    })
      .then((res: any) => {
        setdatatypeLoader(false);
        setDatatypelist(res?.data);
        if (datatype == 0) {
          setSelectedTab(res?.data[0]?.parameter__data_type);
          history.push(
            `/feature-configuration/${projectId}/${projectname}/${mainId}/${categoryId}/${categoryname}/${res?.data[0]?.parameter__data_type}/${paramType}`
          );
        }
      })
      .catch((err: any) => {
        setdatatypeLoader(false);
      });
  };

  useEffect(() => {
    getDataType(datatype);
  }, [projectId, categoryId, CategoryCount]);

  const [lockStatus, setLockStatus] = useState<any>(null);

  const getLockStatusByName = (categoryId: any) => {
    // Filter the CategoryData array to find the item with the matching name
    const filteredItem = CategoryData.filter((item: any) => item.category === categoryId);
    if (filteredItem.length > 0) {
      setLockStatus(filteredItem[0].lock);
    } else {
      // If no matching item is found, set the lockStatus to null or any default value
      setLockStatus(null);
    }
  };
  useEffect(() => {
    if (categoryId) {
      getLockStatusByName(categoryId);
    }
  }, [categoryId, ParentCount]);

  console.log("lockStatuslockStatuslockStatus",lockStatus)


  return (
    <div>
      {addcategoryUpop.isOpen && (
        <AddCategoryModal
          projectId={projectId}
          isOpen={addcategoryUpop.isOpen}
          onCloseModal={addcategoryUpop.close}
          selectedData={CategoryData}
          setcategoryCount={setcategoryCount}
        />
      )}

      {ParametershareModals.isOpen && (
        <ShareParameterModal
          isOpen={ParametershareModals.isOpen}
          ClosedHandler={ParametershareModals.close}
          CotegoryIds={categoryIds}
          CategoryDatalist={CategoryData}
          projectIds={projectId}
          setcategoryCount={setcategoryCount}
          setcategoryData={setCategoryData}
        />
      )}
      {ParameterModals.isOpen && (
        <AddFeatureParameterModal
          setcategoryCount={setcategoryCount}
          isOpen={ParameterModals.isOpen}
          onCloseModal={ParameterModals.close}
          parameterValidation={handleChangeCheckBox}
          showAll={false}
        />
      )}
      {ErrorModals.isOpen && (
        <ErrorModalFilesMail
          isOpen={ErrorModals.isOpen}
          onCloseModal={ErrorModals.close}
          ErrorMsg={ErrorModalFileMsg}
        />
      )}
      {sharepopup.isOpen && (
        <ShareCategoryModal
          isOpen={sharepopup.isOpen}
          ClosedHandler={sharepopup.close}
          CategoryDatalist={CategoryData}
          projectIds={projectId}
        />
      )}

      <Box sx={{ margin: "0 0.5rem" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            sx={{ display: "flex", alignItems: "center", columnGap: "1rem" }}
          >
            <IconButton
              sx={{ cursor: "pointer" }}
              title="Go Back"
              onClick={handleClick}
            >
              <ArrowBackIcon color="primary" titleAccess="Go Back" />
            </IconButton>
            <Typography
              sx={{
                fontSize: "1rem",
                whiteSpace: "nowrap",
              }}
            >
              {projectname}-Feature Configuration-Parameter Selection
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "1rem",
              paddingRight: "1rem",
            }}
          >
            {Premisssionaction && Premisssionaction?.includes("C") ? (
              <PlaylistAddIcon
                titleAccess="Add Feature Parameter"
                sx={{ cursor: "pointer", fontSize: "1.8rem" }}
                onClick={() => Parameterhandler()}
              />
            ) : (
              <PlaylistAddIcon sx={{ opacity: "0.3", fontSize: "1.8rem" }} />
            )}
            {Premisssionaction && Premisssionaction?.includes("C") ? (
              <ShareIcon
                titleAccess="Share Feature"
                onClick={() => ShareHandler()}
                sx={{ fontSize: "1.5rem", cursor: "pointer" }}
              />
            ) : (
              <ShareIcon sx={{ opacity: 0.3, fontSize: "1.5rem" }} />
            )}
            <LoadingButton
              onClick={() => downloadhandler()}
              title="Download"
              sx={{
                minWidth: "1rem",
                cursor: "pointer",
                padding: "0.1rem 0.5rem",
                ".MuiCircularProgress-root": {
                  color: "primary.main",
                },
              }}
              loading={DownloadLoader}
              startIcon={
                <FileDownloadIcon
                  sx={{ marginRight: "-1rem", fontSize: "1.7rem !important" }}
                />
              }
            />
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "flex-start" }}>
        <Box className={styles.expandIconBox}>
          <div className={styles.iconSec}>
            {(["left"] as const).map((anchor) => (
              <React.Fragment key={anchor}>
                <IconButton
                  size="medium"
                  sx={{
                    backgroundColor: "primary.light",
                    color: "primary.main",
                  }}
                  onClick={toggleDrawer(anchor, true)}
                >
                  <AddIcon sx={{ fontSize: "1.7rem" }} />
                </IconButton>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  className={styles.drawerContainer}
                  PaperProps={{
                    sx: {
                      backgroundColor: "aliceblue",
                    },
                  }}
                >
                  <Box
                    sx={{ padding: "1rem 0rem", paddingBottom: "0" }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3"
                  >
                    <Typography
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "1rem",
                      }}
                    >
                      Select Configuration Module
                    </Typography>
                    <Divider sx={{ margin: "0.5rem" }} />
                    {list(anchor)}
                  </Box>
                </Drawer>
                <Typography
                  style={{
                    transform: "rotate(270deg)",
                    whiteSpace: "nowrap",
                    marginTop: "40vh",
                    cursor: "pointer",
                    fontSize: "1rem",
                  }}
                  onClick={toggleDrawer(anchor, true)}
                >
                  Select Configuration Module
                </Typography>
              </React.Fragment>
            ))}
          </div>
        </Box>
        <Box
          sx={{
            width: "97%",
            display: "flex",
            alignItems: "flex-start",
          }}
        >
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "flex-start",
              paddingRight: "1rem",
            }}
          >
            <Box sx={{ borderRight: '1px solid', borderColor: 'primary.light' }}>
              <Box sx={{ width: "100%", padding: "0 1rem 0.5rem" }}>
                {Premisssionaction && Premisssionaction?.includes("C") ? (
                  <Button
                    size="small"
                    variant="contained"
                    startIcon={<AddIcon sx={{ marginRight: "-0.5rem" }} />}
                    sx={{
                      width: "100%",
                      cursor: "pointer",
                      borderRadius: "3rem",
                    }}
                    onClick={() => addcategoryUpop.open()}
                  >
                    Add Category
                  </Button>
                ) : (
                  <Button
                    size="small"
                    variant="contained"
                    disabled={true}
                    startIcon={<AddIcon sx={{ marginRight: "-0.5rem" }} />}
                    sx={{ width: "100%", borderRadius: "3rem" }}
                  >
                    Add Category
                  </Button>
                )}
              </Box>
              <Box
                sx={{
                  height: { lg: "82vh", xl: "85vh" },
                  width: { lg: "19vw", xl: "19vw" },
                  padding: "0.5rem",
                }}
                adl-scrollbar="true"
                adl-scrollbar-width="0.3"
              >
                {loader ? (
                  <Box sx={{ width: "100%" }}>
                    <Skeleton
                      sx={{ marginBottom: "1rem" }}
                      variant="rounded"
                      width="100%"
                      height="6rem"
                    ></Skeleton>
                    <Skeleton
                      sx={{ marginBottom: "1rem" }}
                      variant="rounded"
                      width="100%"
                      height="6rem"
                    ></Skeleton>
                    <Skeleton
                      sx={{ marginBottom: "1rem" }}
                      variant="rounded"
                      width="100%"
                      height="6rem"
                    ></Skeleton>
                    <Skeleton
                      sx={{ marginBottom: "1rem" }}
                      variant="rounded"
                      width="100%"
                      height="6rem"
                    ></Skeleton>
                    <Skeleton
                      sx={{ marginBottom: "1rem" }}
                      variant="rounded"
                      width="100%"
                      height="6rem"
                    ></Skeleton>
                    <Skeleton
                      sx={{ marginBottom: "1rem" }}
                      variant="rounded"
                      width="100%"
                      height="6rem"
                    ></Skeleton>
                    <Skeleton
                      sx={{ marginBottom: "1rem" }}
                      variant="rounded"
                      width="100%"
                      height="6rem"
                    ></Skeleton>
                    <Skeleton
                      sx={{ marginBottom: "1rem" }}
                      variant="rounded"
                      width="100%"
                      height="6rem"
                    ></Skeleton>
                    <Skeleton
                      sx={{ marginBottom: "1rem" }}
                      variant="rounded"
                      width="100%"
                      height="6rem"
                    ></Skeleton>
                    <Skeleton
                      sx={{ marginBottom: "1rem" }}
                      variant="rounded"
                      width="100%"
                      height="6rem"
                    ></Skeleton>
                    <Skeleton
                      sx={{ marginBottom: "1rem" }}
                      variant="rounded"
                      width="100%"
                      height="6rem"
                    ></Skeleton>
                  </Box>
                ) : (
                  <>
                    <Box sx={{ width: '100%' }}>
                      {CategoryData &&
                        CategoryData?.map((item: any, index: any) => {
                          return (
                            <>
                              <Accordion expanded={expanded === item?.category} onChange={handleChange(item)}>
                                <Box
                                  sx={{
                                    width: "100%",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: '0.5rem',
                                  }}
                                >
                                  <AccordionSummary aria-controls="panel1d-content" id="panel1d-header"
                                    sx={{ padding: '0', minHeight: '24px', width: '100%', }}
                                    expandIcon={expanded === item?.category ? (<img src={openFolder} style={{ height: '1.2rem', }} />)
                                      : (<FolderIcon sx={{ fontSize: '1.5rem', color: 'primary.main', }} />)
                                    }>

                                    <Box
                                      sx={{
                                        width: "100%",
                                        display: "flex",
                                        gap: '0.5rem',
                                        alignItems: "center",
                                        padding: '0 0.5rem',
                                      }}
                                    >
                                      <Typography sx={{ fontSize: "1rem" }}>
                                        {item?.name}
                                      </Typography>
                                      {item?.is_leaf ? (
                                        <Typography sx={{ fontSize: "1rem" }}>
                                          Leaf
                                        </Typography>
                                      ) : (
                                        <Typography sx={{ fontSize: "1rem" }}>
                                          Non Leaf
                                        </Typography>
                                      )}
                                    </Box>
                                    <Typography sx={{ fontSize: "1rem" }}>
                                      ({item?.parameter_count})
                                    </Typography>

                                  </AccordionSummary>
                                  <Box
                                    sx={{
                                      display: "grid",
                                      gridTemplateColumns: '1fr 1fr 1fr 1fr',
                                      gap: '0.5rem',
                                      justifyContent: "space-between",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Box>
                                      {(item?.lock && item?.is_validated) ? (
                                        <IconButton
                                          sx={{
                                            padding: "0",
                                            cursor: "pointer",
                                            color: "green",
                                          }}
                                          onClick={() => {
                                            swal({
                                              title: "Unlock?",
                                              text: "Are you sure you want to unlock?",
                                              buttons: ["Cancel", "Yes"],

                                              icon: "error",
                                            }).then((confirm) => {
                                              if (confirm) {
                                                LockHandlerAction(item, false)
                                              }
                                            });

                                          }
                                          }
                                        >
                                          <LockIcon sx={{ color: "green" }} />
                                        </IconButton>
                                      ) : (
                                        (item?.is_validated && <IconButton
                                          sx={{
                                            padding: "0",
                                            cursor: "pointer",
                                            color: "color:'red",
                                          }}
                                          onClick={() => {
                                            swal({
                                              title: "Lock?",
                                              text: "Are you sure you want to Lock?",
                                              buttons: ["Cancel", "Yes"],

                                              icon: "error",
                                            }).then((confirm) => {
                                              if (confirm) {
                                                LockHandlerAction(item, true)
                                              }
                                            });

                                          }
                                          }
                                        >
                                          <LockOpenIcon sx={{ color: 'red' }} />
                                        </IconButton>)
                                      )}
                                    </Box>
                                    <Box>
                                      {item?.is_validated && (
                                        <>
                                          {Premisssionaction &&
                                            Premisssionaction?.includes("C") ? (
                                            <ShareIcon
                                              sx={{ fontSize: "1.5rem" }}
                                              onClick={() =>
                                                ParameterShareHandler(item?.id)
                                              }
                                            />
                                          ) : (
                                            <ShareIcon
                                              sx={{
                                                fontSize: "1.5rem",
                                                opacity: "0.3",
                                              }}
                                            />
                                          )}
                                        </>
                                      )}
                                    </Box>
                                    <Box>
                                      {item?.parameter_count == 0 && (
                                        <>
                                          {Premisssionaction &&
                                            Premisssionaction?.includes("D") ? (
                                            <DeleteIcon
                                              sx={{
                                                fontSize: "1.5rem",
                                                cursor: "pointer",
                                              }}
                                              onClick={() =>
                                                CategoryDeleteHandler(item.id)
                                              }
                                            />
                                          ) : (
                                            <DeleteIcon
                                              sx={{
                                                fontSize: "1.5rem",
                                                opacity: "0.3",
                                              }}
                                            />
                                          )}
                                        </>
                                      )}
                                    </Box>
                                    <img
                                      src={
                                        item?.is_validated
                                          ? validated
                                          : notvalidated
                                      }
                                      style={{ width: "1.5rem" }}
                                    />
                                  </Box>
                                </Box>
                                <AccordionDetails>
                                  {datatypeLoader ? (
                                    <>
                                      <Skeleton />
                                      <Skeleton />
                                      <Skeleton />
                                      <Skeleton />
                                      <Skeleton />
                                      <Skeleton />
                                    </>
                                  ) : (
                                    <Box
                                      sx={{
                                        marginLeft: '1.3rem',
                                        // marginRight: '0.5rem',
                                        borderLeft: '1px solid',
                                        borderColor: 'primary.main',
                                      }}>
                                      {Datatypelist && Datatypelist?.map((item: any, indx: any) => {
                                        // console.log(item, 'itemData')
                                        return (
                                          <>
                                            <Typography
                                              key={indx}
                                              sx={{
                                                backgroundColor:
                                                  item?.parameter__data_type === datatype
                                                    ? "primary.main"
                                                    : item?.parameter_group === 1
                                                      ? "#cef0ff"
                                                      : item?.parameter_group === 2
                                                        ? "#d0f1aa"
                                                        : item?.parameter_group === 3
                                                          ? "#fbdddd"
                                                          : item?.parameter_group === 4
                                                            ? "#ffee93"
                                                            : "#d1cfe2",
                                                color:
                                                  item?.parameter__data_type === datatype
                                                    ? "white"
                                                    : "text.primary",
                                                display: 'flex',
                                                alignItems: 'center',
                                                fontSize: '1rem',
                                                columnGap: '2px',
                                                cursor: 'pointer',
                                                marginBottom: '3px',
                                                padding: item?.parameter__data_type === datatype ? '2px 0.3rem 2px 0' : '0rem 0.3rem 0 0',
                                                border: item?.parameter__data_type === datatype ? '2px solid #000' : '',
                                                borderRadius: item?.parameter__data_type === datatype ? '5px' : '',
                                              }}
                                              onClick={(e: any) => { DataTypeHandler(item) }}
                                            >
                                              {' '}
                                              <span style={{ whiteSpace: 'nowrap' }}>--</span>
                                              <span style={{ lineHeight: '1.2', width: '85%', textTransform: 'capitalize' }}>{
                                                item?.parameter__data_type
                                              }</span>{' '}
                                              ({item?.count})
                                              {/* {!premissionData && premissionData?.includes("U") ? (
                                                    <AddCircleOutlineIcon sx={{ marginLeft: "0.2rem", opacity: 0.3 }} />
                                                  ) : ( */}
                                              {/* <AddCircleOutlineIcon
                                                titleAccess="Add BOM Parameter"
                                                sx={{
                                                  marginLeft: "0.2rem",
                                                  cursor: "pointer",
                                                  opacity: validationId == "true" ? 0.5 : 1,
                                                }}
                                                onClick={() => {
                                                  if (validationId != "true") {
                                                    Parameterhandler();
                                                  }
                                                }}
                                              /> */}
                                              {/* )} */}
                                            </Typography>
                                          </>
                                        );
                                      }
                                      )}
                                    </Box>
                                  )}
                                </AccordionDetails>
                              </Accordion>
                            </>
                          );
                        })}
                    </Box>
                  </>
                )}
              </Box>
            </Box>

            <Box sx={{ width: "82vw" }}>

              <UserContextFeature.Provider value={Premisssionaction}>
                <lockContext.Provider value={lock}>
                <FeatureConfiguration
                  projectId={projectId}
                  CategoryCount={CategoryCount}
                  handleChangeCheckBox={handleChangeCheckBox}
                  setParentsCounter={setFeatureCounter}
                  lockStatus={lockStatus}
                  ParentCount={ParentCount}
                  CategoryData={CategoryData}
                  setcategoryCount={setcategoryCount}
                />
                </lockContext.Provider>
              </UserContextFeature.Provider>
            </Box>
          </Box>
          {/* )} */}
        </Box>
      </Box>
    </div >
  );
}
