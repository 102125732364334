import React from 'react';
import { RootState } from '../../Redux/Store/storeConfigurations';
import { useSelector } from 'react-redux';
import { imageRepositoryProductInfoSelectors } from '../../Redux/Reducers/imageRepositoryProductInfo.reducer';
import { ID } from '../../utlis/commonInterfaces';
import { times } from 'lodash';
import MediaCreateProductImageCard from './MediaCreateProductImageCard';


interface MediaCreateMultipleGalleryI{
    topVaultId: ID;
    maxCount: number;
    selectedTopVaultIds: any;
    groupForward?: any;
    groupPageBoolean?: any;
    pageNumber?: any;
    rowSize?: any;
    setcheckboxcount?:any;
    validationvalue?:any;
  }

const MediaCreateMultipleGallery:React.FC<MediaCreateMultipleGalleryI>=(props)=>{
    const {
        topVaultId,
        maxCount,
        selectedTopVaultIds,
        groupPageBoolean,
        pageNumber,
        rowSize,
        setcheckboxcount,
        validationvalue
      } = props;

    const productInfo = useSelector((state: RootState) => {
        return imageRepositoryProductInfoSelectors.selectById(state, topVaultId);
      });
    
      const renderImageCard = (num: number) => {
        if (!productInfo) return null;
        return (
          <MediaCreateProductImageCard
            sequence={groupPageBoolean ? num + 1 : rowSize * (pageNumber - 1) + num + 1}
            topVaultId={topVaultId}
            vaultId={ productInfo.vault }
            page={pageNumber}
            rowSize={rowSize}
            selectedTopVaultIds={selectedTopVaultIds}
            setcheckboxcount={setcheckboxcount}
            validationvalueProduct={validationvalue && validationvalue}
          />
        );
      };
      return (
        <>
          {times(maxCount, renderImageCard)}
        </>
      );
}

export default MediaCreateMultipleGallery;