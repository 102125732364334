

import { ID } from "../../utlis/commonInterfaces";
import { API } from "../../api-services";
import { SHARING_TYPES } from "../../utlis/sharing.constants";
import { GetWorkstionsArg, GetMappedWorkstionsArg, CreateWorkstationArg, UpdateWorkstationArg, DeleteWorkstationArg } from "../Actions/workstation.actions";

// ---------------------------------------------------------------------------------

export interface ShareWorkstationArg {
    topVaultIds: ID[];
    workstationId: ID;
}

// ---------------------------------------------------------------------------------

const url = "/config/workstation/";
const shareUrl = "/config/share_config/";

// ---------------------------------------------------------------------------------

export default {
    get ( arg: GetWorkstionsArg ) {
        return API.get( url, { top_vault: arg.topVaultId, module: arg.module },0 );
    },

    getMappedWorkstations ( arg: GetMappedWorkstionsArg ) {
        return API.get( "auth/workstation/", {
            top_vault: arg.topVaultId,
            vault:arg.vaultId
        },0 );
    },

    share ( arg: ShareWorkstationArg ) {
        return API.post( shareUrl, {
            workstation: arg.workstationId,
            target_top_vaults: arg.topVaultIds,
            type: SHARING_TYPES.WORKSTATION,
        } );
    },

    create ( arg: CreateWorkstationArg ) {
        return API.post( url, arg );
    },

    update ( arg: UpdateWorkstationArg ) {
        return API.put( url + arg.id + "/", arg.updates );
    },

    delete ( arg: DeleteWorkstationArg ) {
        return API.delete( url + arg.id + "/" );
    },
};
