

// -------------------------------------------------------------------------------------

import { API } from "../../api-services";
import { CopyRawMaterialId, CreateRawMaterialCardCostsArg, DeleteRawMaterialCardCost, GetRawMateralCardCostsArg, UpdateRawMaterialCardCostArg } from "../Actions/rawMaterialCard.actions";

const url = "cost/material/";
const copyUrl = "cost/cost_copy/";

// -------------------------------------------------------------------------------------

export default {
    get ( arg: GetRawMateralCardCostsArg ) {
      console.log("arg",arg)
      
        return API.get( url, {
            cost_estimation: arg.costEstimationId,
            id:arg.tableId
        }, 20000 );
    },
    create ( arg: CreateRawMaterialCardCostsArg ) {
        return API.post( url, {
            cost_estimation: arg.costEstimationId,
            materials: arg.materials,
            purchasing_items: arg.purchasingItems,
            fasteners: arg.fasteners,
            procure_items: arg.procure_items
        } );
    },
    update ( arg: UpdateRawMaterialCardCostArg ) {
        return API.put( url + arg.id + "/", arg.updates );
    },
    delete ( arg: DeleteRawMaterialCardCost ) {
        return API.delete( url + arg.id + "/" );
    },
    materialCopy ( arg: CopyRawMaterialId ) {
        return API.post( copyUrl, {
            material_id: arg.id,
            data: arg.data ? arg.data : undefined,
        } );
    }
};