import * as React from "react";
import styles from "./CostingScenarioCreateDrawer.module.scss";
import { Box, Checkbox, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useState } from "react";

import { LoadingButton } from "@mui/lab";
import { AxiosError, AxiosResponse } from "axios";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { API } from "../../../api-services";

interface Params {
  projectId: any;
  projectName: any;
  productId: any;
}

export interface ICostingScenarioCreateDrawerProps {
  anchorComing: any;
  toggleDrawer: any;
  productList: any;
  setProductList: any;
  selectedProduct: any;
  setSelectedProduct: any;
}

export function CostingCreateScenarioMainDrawer(
  props: ICostingScenarioCreateDrawerProps
) {
  const {
    anchorComing,
    toggleDrawer,
    productList,
    setProductList,
    selectedProduct,
    setSelectedProduct,
  } = props;
  const { projectId, projectName, productId } = useRouteParams<Params>();
  const [projectList, setProjectList] = useState<any>();
  const [selectedProject, setSelectedProject] = useState<any>([]);

  const history = useHistory();

  React.useEffect(() => {
    setSelectedProject([projectId]);
  }, []);

  React.useEffect(() => {
    setSelectedProduct([productId]);
  }, []);

  React.useEffect(() => {
    API.get(`/xcpep/project_list_new/`, { module: "Cost-Scenario" }, 0)
      .then((res: AxiosResponse) => {
        setProjectList(res?.data);
      })
      .catch((err: AxiosError) => {
        console.log(err, "error");
      });
  }, []);

  const selectAllProject = (projectIds: any) => {
    setSelectedProject([projectIds]);
    API.get(
      `/xcpep/product_list_new/`,
      { project: `${projectIds}`, module: "Cost-Scenario" },
      0
    )
      .then((res: AxiosResponse) => {
        setProductList(res?.data);
      })
      .catch((err: AxiosError) => {
        console.log(err, "error");
      });
  };

  const selectAllProduct = (productIds: any) => {
    setSelectedProduct([productIds]);
  };

  const getselectedProjectBackgroundColor = (idd: any) => {
    if (selectedProject?.includes(idd)) {
      return "primary.main";
    } else {
      return "primary.light";
    }
  };

  const getSelectedProjectTextColor = (idd: any) => {
    if (selectedProject?.includes(idd)) {
      return "primary.light";
    } else {
      return "primary.main";
    }
  };

  const getselectedProductBackgroundColor = (idd: any) => {
    if (selectedProduct?.includes(idd)) {
      return "#2b9cf6eb";
    } else {
      return "primary.light";
    }
  };

  const getSelectedPrductTextColor = (idd: any) => {
    if (selectedProduct?.includes(idd)) {
      return "primary.light";
    } else {
      return "primary.main";
    }
  };

  const redirectToProject: any = () => {
    sessionStorage.setItem("addScenarioStatus","3")
    history.push(`/costingscenario/${selectedProject[0]}/${
      projectList?.filter((item: any) => {
        return selectedProject[0] == item?.id;
      })[0]?.name
    }/${selectedProduct[0]}/${
      0
    }/1/PRODUCT/0`);
  };

  return (
    <Box
      sx={{
        minWidth: 300,
        width:
          anchorComing === "top" || anchorComing === "bottom" ? "auto" : "auto",
      }}
      role="presentation"
    >
      <Box sx={{ width: "100%", padding: "0" }}>
        <Box
          sx={{ width: "100%", padding: "0 1rem" }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3"
        >
          <Box sx={{ marginTop: "1rem" }}>
            {projectList &&
              projectList?.map((item: any, index: any) => {
                return (
                  <Box sx={{ alignItems: "center" }} key={index + 1}>
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "column",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        <Checkbox
                          style={{ padding: "3px" }}
                          checked={
                            selectedProject?.includes(item.id) ? true : false
                          }
                          onClick={() => {
                            return selectAllProject(item?.id);
                          }}
                          inputProps={{ "aria-label": "controlled" }}
                          sx={{
                            "& .MuiSvgIcon-root": { fontSize: 24 },
                            color: "primary.main",
                          }}
                        />
                        <Box
                          onClick={() => {
                            return selectAllProject(item?.id);
                          }}
                          key={item?.id}
                          className={styles.selectVehBtn}
                          sx={{
                            // margin: "0rem 2rem",
                            padding: "0.2rem 0.5rem",
                            backgroundColor: getselectedProjectBackgroundColor(
                              item.id
                            ),
                            color: getSelectedProjectTextColor(item.id),
                            textAlign: "left",
                            cursor: "pointer",
                            width: "100%",
                          }}
                        >
                          <Typography
                            style={{ fontSize: "0.87rem", display: "flex" }}
                          >
                            <span>{item.name}</span>
                            <span style={{ marginLeft: "1rem" }}>
                              [ {item.description} ]
                            </span>
                          </Typography>
                        </Box>
                      </Box>
                      {selectedProject?.includes(item.id) ? (
                        <>
                          {productList &&
                            productList?.map((item: any, index: any) => {
                              return (
                                <>
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      marginLeft: "2rem",
                                      width: "90%",
                                    }}
                                  >
                                    <Checkbox
                                      style={{ padding: "3px" }}
                                      checked={
                                        selectedProduct?.includes(item.id)
                                          ? true
                                          : false
                                      }
                                      onClick={() => {
                                        return selectAllProduct(item?.id);
                                      }}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                      sx={{
                                        "& .MuiSvgIcon-root": {
                                          fontSize: { lg: "24", xl: "30" },
                                          color: "#2b9cf6eb",
                                        },
                                      }}
                                    />
                                    <Box
                                      onClick={() => {
                                        return selectAllProduct(item?.id);
                                      }}
                                      key={item?.id}
                                      className={styles.selectVehBtn}
                                      sx={{
                                        // margin: "0rem 2rem",
                                        padding: "0.2rem 0.5rem",
                                        backgroundColor:
                                          getselectedProductBackgroundColor(
                                            item.id
                                          ),
                                        color: getSelectedPrductTextColor(
                                          item.id
                                        ),
                                        textAlign: "left",
                                        cursor: "pointer",
                                        width: "100%",
                                      }}
                                    >
                                      <Typography
                                        style={{
                                          fontSize: "0.87rem",
                                          display: "flex",
                                        }}
                                      >
                                        <span>{item.name}</span>
                                        {/* <span style={{ marginLeft: '1rem' }}>[ {item.description} ]</span> */}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </>
                              );
                            })}
                        </>
                      ) : (
                        ""
                      )}
                    </Box>
                  </Box>
                );
              })}
          </Box>
        </Box>
        <div
          style={{
            paddingBottom: "0.5rem",
            position: "sticky",
            bottom: "0",
            height: "4rem",
            backgroundColor: "aliceblue",
            marginTop: "2rem",
          }}
        >
          <Box sx={{ textAlign: "right", paddingRight: "1rem" }}>
            <Button
              className={styles.drawBtn}
              sx={{
                marginRight: "1rem",
                // fontSize: "0.75rem",
                "&:hover": {
                  transform: "Scale(1.1)",
                  transition: "transform 0.5s ease",
                },
              }}
              variant="contained"
              onClick={toggleDrawer(anchorComing, false)}
              size="small"
            >
              Close
            </Button>
            <LoadingButton size="small" onClick={redirectToProject}>
              <Button
                variant="contained"
                sx={{
                  // fontSize: "0.75rem",
                  "&:hover": {
                    transform: "Scale(1.1)",
                    transition: "transform 0.5s ease",
                  },
                }}
                onClick={toggleDrawer(anchorComing, false)}
                size="small"
              >
                Ok
              </Button>
            </LoadingButton>
          </Box>
        </div>
      </Box>
    </Box>
  );
}
