
import { useState } from "react";
import { ID } from "../../../../../../utlis/commonInterfaces";

// ----------------------------------------------------------------------------------

const useTableFieldRowSelectionModal = () => {
    const [isOpen, setIsOpen] = useState( false );
    const [inputId, setInputId] = useState<ID>( 0 );
    const [inputType, setInputType] = useState( 0 );
    const [parameterId, setParameterId] = useState<ID>( 0 );

    const handleOpen = ( inputId: ID ) => ( inputType: number, parameterId: ID ) => {
        setInputId( inputId );
        setInputType( inputType );
        setParameterId( parameterId );
        setIsOpen( true );
    };
    const handleClose = () => {
        setInputId( 0 );
        setInputType( 0 );
        setParameterId( 0 );
        setIsOpen( false );
    };

    return Object.freeze( {
        open: handleOpen,
        close: handleClose,
        isOpen,
        inputId,
        inputType,
        parameterId
    } );
};

// ----------------------------------------------------------------------------------

export { useTableFieldRowSelectionModal };
