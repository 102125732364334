import { Box, IconButton } from '@mui/material';
import * as React from 'react';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import LaunchIcon from '@mui/icons-material/Launch';
import FlagIcon from '@mui/icons-material/Flag';
import move from '../../../Assets/images/right move.svg';
import EqualizerIcon from '@mui/icons-material/Equalizer';

export interface IMachineHourRateActionComponentsProps {
	cellValues?: any;
	rowId?: any;
	InfoHandler?: any;
	DeleteHandler?: any;
	GraphHandler?: any;
	redirectionHanlder?: any;
	handleShareWith?: any;
	useraction?: any;
	setAnchorEl?: any;
}

export default function MachineHourRateActionComponents(
	props: IMachineHourRateActionComponentsProps
) {
	const { cellValues, rowId, InfoHandler, DeleteHandler, GraphHandler, redirectionHanlder, handleShareWith, useraction, setAnchorEl } = props;

	// console.log("cellValues", cellValues);


	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				width: '6rem',
			}}>
			{rowId.status ? (
				<IconButton
					title='Machine Info'
					sx={{ cursor: 'pointer', color: 'primary.main', padding: '0.5rem' }}
					onClick={() => InfoHandler(rowId)}>
					<InfoIcon />
				</IconButton>
			) : (
				useraction &&
				useraction?.includes('D') && (
					<IconButton
						title='Delete'
						sx={{ cursor: 'pointer', color: 'primary.main', padding: '0.5rem' }}
						onClick={() => DeleteHandler(rowId)}>
						<DeleteIcon />
					</IconButton>
				)
			)}
			<IconButton
				title='Graph'
				sx={{ cursor: 'pointer', color: 'primary.main', padding: '0.5rem' }}
				onClick={() => GraphHandler(rowId)}>
				<EqualizerIcon />
			</IconButton>
			<IconButton
				title='Redirect'
				sx={{ cursor: 'pointer', color: 'primary.main', padding: '0.5rem' }}
				onClick={() => redirectionHanlder(rowId)}>
				<LaunchIcon />{' '}
			</IconButton>
			{rowId.flag !== null &&  rowId.flag !== undefined &&(
				<IconButton title='Machine Cost' sx={{ color: 'primary.main', padding: '0.5rem' }}>
					<FlagIcon
						onClick={() => window.open(rowId?.flag, '_blank')}
						sx={{ cursor: 'pointer', color: 'green' }}
					/>
				</IconButton>
			)}
			{rowId.specification !== null && rowId.specification !== undefined && (
				<IconButton title='Machine Specification' sx={{ color: 'primary.main', padding: '0.5rem' }}>
					<FlagIcon
						onClick={() => window.open(rowId?.specification, '_blank')}
						sx={{ cursor: 'pointer', color: 'green' }}
					/>
				</IconButton>
			)}
			{useraction && useraction.includes('U') && (
				<IconButton
					title='Move'
					sx={{ color: 'primary.main', padding: '0.5rem', cursor: 'pointer' }}
					onClick={() => handleShareWith(rowId)}>
					<img
						src={move}
						style={{ height: '1.7rem', cursor: 'pointer', padding: '0.2rem' }}
					/>
				</IconButton>
			)}
		</Box>
	);
}
