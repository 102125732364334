// react


// vendors
// services
// utils
// constants
// factories
// core
// redux
// ui-components
// others
// styles
import React from "react";
import styles from "./StrokeDatabaseModal.module.scss";
import { Box, Modal, Typography } from "@mui/material";
import { ID } from "../../../../../../../utlis/commonInterfaces";
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: "60vw",
    maxWidth: '80vw',
    // height: '80vh',
    bgcolor: 'background.paper',
    borderRadius: '10px',
    p: 1,
};
// interfaces and types
// lazy

// ----------------------------------------------------------------------------------
const OperationRates = [
    {
        id: 2,
        header: "Operation Rates",
        value: 2,
        description: 'Stroke Rates/Machine Rates/Operation Rates'
    },
    {
        id: 1,
        header: "Labour Rates",
        value: 1,
        description: "Labour rates per operation"
    }
];
interface StrokeDatabaseModalProps {
    isOpen: boolean;
    project: ID;
    onClose: () => void;
    onSubmit: ( record: any ) => void;
    showTable: boolean;
    selectedCard: ( ed: any ) => void;
    type: any;
    path: string;
}

const StrokeDatabaseModal: React.FC<StrokeDatabaseModalProps> = ( props ) => {
    const { isOpen, onClose, project, onSubmit, showTable, type, path, selectedCard } = props;
    const [selection, setSelection] = React.useState<any>( '' );

    const handleSubmit = () => {
        if ( !selection ) return console.log( "Select an item" );
        onSubmit( selection );
    };

    const handleSelectionChange = ( selection: any ) => {
        setSelection( selection );
    };

    return (
        <Modal
            
            open={ isOpen }
            onClose={ onClose }

        >
             <Box sx={style}>
            <Typography>{ 'Operation Rate Database' } { type === 0 ? '' : type === 2 ?
                <span style={ { fontSize: '1.1rem', color: "#0000ff", fontWeight: 'lighter' } }>(Operation Rates)</span>
                :
                <span style={ { fontSize: '1.1rem', color: "#0000ff", fontWeight: 'lighter' } }>(Labour Rates) </span>

            }</Typography>


            {/* <Modal.Content>
                { isOpen && (
                    <div className={ styles.table_container }
                    >
                        { showTable === false ?
                            <Card.Group centered>
                                { OperationRates.map( ( res: any ) => (
                                    <Card key={ res.id } onClick={ (e:any) => selectedCard( res.id ) }>
                                        <Card.Content >
                                            <Card.Header>{ res.header }</Card.Header>
                                            <Card.Description>{ res.description }</Card.Description>
                                        </Card.Content>
                                    </Card>
                                ) ) }
                            </Card.Group>
                            :
                            <div
                                className={ styles.main }

                            >
                                < MappedTable
                                    columns={ [
                                        {
                                            key: "code",
                                            label: "Operation Code",
                                            control: "input",
                                            controlProps: {
                                                type: "text",
                                                placeholder: "Operation Code",
                                                width: 2
                                            },
                                            cellProps: {
                                                className: styles["input-container"],
                                                width: 2,
                                                border: 'none'
                                            },
                                        },
                                        {
                                            key: "category",
                                            label: "Category",
                                            control: "input",
                                            controlProps: {
                                                type: "text",
                                                placeholder: "Category",
                                            },
                                            cellProps: {
                                                className: styles["input-container"],
                                                width: 3
                                            },
                                            headerCellProps: {
                                                width: 3
                                            },
                                        },
                                        {
                                            key: "description",
                                            label: "Description",
                                            control: "input",
                                            controlProps: {
                                                type: "text",
                                                placeholder: "Description",
                                                width: 2
                                            },
                                            cellProps: {
                                                className: styles["input-container"],
                                                width: 2
                                            },
                                        },
                                        {
                                            key: "remark",
                                            label: "Remarks",
                                            control: "input",
                                            controlProps: {
                                                type: "text",
                                                placeholder: "Remarks",
                                                width: 2
                                            },
                                            cellProps: {
                                                className: styles["input-container"],
                                                width: 2
                                            },
                                        },
                                        {
                                            key: "unit",
                                            label: "Unit",
                                            control: "input",
                                            controlProps: {
                                                type: "text",
                                                placeholder: "Unit",
                                                width: 2
                                            },
                                            cellProps: {
                                                className: styles["input-container"],
                                                width: 2
                                            },
                                        },
                                        {
                                            key: "rate_per_unit",
                                            label: "Rate/Unit",
                                            control: "input",
                                            controlProps: {
                                                type: "text",
                                                placeholder: "Rate/Unit",
                                                width: 2
                                            },
                                            cellProps: {
                                                className: styles["input-container"],
                                                width: 2
                                            },
                                        },

                                    ] }
                                    fetchOptions={ {
                                        method: operationDatabase.getStrokeDatabase,
                                        args: [project, type, path],
                                        fallBackErrMsg: "SERVER_ERROR (GET_OPERATION)",
                                    } }
                                    tableProps={ {
                                        className: styles["table"],
                                        celled: true,
                                        textAlign: "center",
                                    } }
                                    addButton="FALSE"
                                    selectRows={ {
                                        // type: "multiple",
                                        headerCellProps: {
                                            width: "1",
                                        },
                                    } }
                                    callbacks={ {
                                        onSelectedRowChange: handleSelectionChange,
                                    } }
                                    scroll
                                    constainerProps={ {
                                        className: styles.stroke_mappedTable
                                    } }

                                />
                            </div>

                        }
                    </div>


                ) }

            </Modal.Content>


            <Modal.Actions>
                <Button onClick={ onClose }><Icon name="close"
                    title="Cancel"
                    alt="Cancel"
                    className="bicon"
                /></Button>
                <Button onClick={ handleSubmit } disabled={ !showTable }><Icon name="check"
                    title="Select"
                    alt="Select"
                    className="bicon"
                />
                </Button>
            </Modal.Actions> */}
            </Box>
        </Modal>
    );
};

// ----------------------------------------------------------------------------------

export default StrokeDatabaseModal;