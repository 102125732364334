import { useState } from "react";

// ----------------------------------------------------------------------------------

const useAddGroupModel = () => {
    const [isOpen, setIsOpen] = useState<boolean>( false );
    const [abbreviationGet, setAbbreviationGet] = useState<any>();
    const [categoryGet, setCategoryGet] = useState<any>();

    const handleOpen = ( abbreviation: any, category:any ) => {
        setAbbreviationGet( abbreviation );
        setCategoryGet(category);
        setIsOpen( true );
    };

    const handleClose = () => {
        setIsOpen( false );
        setAbbreviationGet( undefined );
    };

    return Object.freeze( {
        isOpen,
        open: handleOpen,
        close: handleClose,
        abbreviationGet,
        categoryGet
    } );
};

// ----------------------------------------------------------------------------------

export { useAddGroupModel };
