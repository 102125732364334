const REPORT_TYPES = {
    CONSOLIDATED_BOM: 1,
    INPUT_BOM: 2,
    FEATURE_BOM: 3,
    FEATURE_MEDIA: 4,
};

const REPORT_TYPES_TO_NOT_ALLOWED_COLUMNS: Record<string, string[]> = {
    1: ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j"],
    2: [],
    3: ["a", "b", "c", "d", "e", "f", "g", "h", "i", "j", "k", "L"],
    4: [],
};

const REPORT_TYPES_LIST = [
    { label: "BOM Consolidated", value: 1 },
    { label: "BOM Media", value: 2 },
    { label: "Features Consolidated", value: 3 },
    { label: "Features Media", value: 4 },
    { label: "IDEA Consolidated", value: 5 },
];

const REPORT_TYPE_OPTIONS = REPORT_TYPES_LIST.map( el => ( {
    key: el.value,
    text: el.label,
    value: el.value,
} ) );

export {
    REPORT_TYPES,
    REPORT_TYPES_LIST,
    REPORT_TYPE_OPTIONS,
    REPORT_TYPES_TO_NOT_ALLOWED_COLUMNS,
};
