import { useState } from "react";

// ----------------------------------------------------------------------------------

const useEditSupplierDatabase = () => {
    const [isOpen, setIsOpen] = useState( false );
    const [id,setId] = useState<any>( );
    const [supplier,setSupplier] = useState<any>();
    const [commodity, setCommodity] = useState<any>();
    const [category, setCategory] = useState<any>();
    const [system, setSystem] = useState<any>();
    const [about, setAbout] = useState<any>();
    const [contactUs, setContactUs] = useState<any>();
    const [contactNo, setContactNo] = useState<any>();
    const [spocName, setSpocName] = useState<any>();
    const [spocEmail, setSpocEmail] = useState<any>();
    const [logo,setLogo] = useState<any>();
    const [supplierType, setSupplierType] = useState<any>();
    const [certification, setCertification] = useState<any>();

    const handleOpen = (idGet:any,supplierGet:any,commodityGet:any,categoryGet:any,systemGet:any,aboutGet:any,contactUsGet:any,contactNoGet:any,spocNameGet:any,spocEmailGet:any,logoGet:any,supplierTypeGet:any,certificationGet:any) => {
        setIsOpen( true );
        setId(idGet);
        setSupplier(supplierGet);
        setCommodity(commodityGet);
        setCategory(categoryGet);
        setSystem(systemGet);
        setAbout(aboutGet);
        setContactUs(contactUsGet);
        setContactNo(contactNoGet);
        setSpocName(spocNameGet);
        setSpocEmail(spocEmailGet);
        setLogo(logoGet);
        setSupplierType(supplierTypeGet);
        setCertification(certificationGet);
    }
    const handleClose = () => {
        setIsOpen( false );
        setId(undefined);
        setSupplier(undefined);
        setCommodity(undefined);
        setCategory(undefined);
        setSystem(undefined);
        setAbout(undefined);
        setContactUs(undefined);
        setContactNo(undefined);
        setSpocName(undefined);
        setSpocEmail(undefined);
        setLogo(undefined);
        setSupplierType(undefined);
        setCertification(undefined);
    }

    return Object.freeze( {
        open: handleOpen,
        close: handleClose,
        isOpen,
        id,
        supplier,
        commodity,
        category,
        system,
        about,
        contactUs,
        contactNo,
        spocName,
        spocEmail,
        logo,
        supplierType,
        certification
    } );
};


// ----------------------------------------------------------------------------------

export { useEditSupplierDatabase };