import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import TreeView from "@mui/lab/TreeView";
import TreeItem from "@mui/lab/TreeItem";
import { ExpandMore, ChevronRight } from "@mui/icons-material";
import {
  fetchTopLevelNodesAction,
  setOpenNodes,
  fetchChildNodesAction,
} from "../../Redux/Actions/hierarchy.actions";
import { RootState } from "../../Redux/Store/storeConfigurations";
import { ID } from "../../utlis/commonInterfaces";

const HierarchyTree: React.FC = () => {
  const dispatch = useDispatch();
  const { nodes, openNodes } = useSelector((state: RootState) => state.hierarchy);

  // Fetch top-level nodes on mount
  useEffect(() => {
    dispatch(fetchTopLevelNodesAction());

    // Restore open nodes from localStorage
    const storedOpenNodes = JSON.parse(localStorage.getItem("openNodes") || "[]");
    if (storedOpenNodes.length) {
      dispatch(setOpenNodes(storedOpenNodes));
    }
  }, [dispatch]);

  // Handle node toggle (expanding/collapsing)
  const handleNodeToggle = (event: React.SyntheticEvent<Element>, nodeIds: string[]) => {
    dispatch(setOpenNodes(nodeIds));
    localStorage.setItem("openNodes", JSON.stringify(nodeIds));
  };

  // Fetch child nodes without interrupting expansion
  const handleNodeClick = (nodeId: ID, isLeaf: boolean) => {
    if (!isLeaf) {
      dispatch(fetchChildNodesAction(nodeId)); // Fetch child nodes only for non-leaf nodes
    }
  };

  // Recursive function to render the tree structure
  const renderTree = (node: any) => (
    <TreeItem
      key={node.id}
      nodeId={node.id}
      label={node.name}
      onChange={() => handleNodeClick(node.id, node.is_leaf)} // Fetch child nodes on label click
      sx={{ marginBottom: "5px", padding: "0 5px", "&:hover": { backgroundColor: "#e0f7fa" } }} // Add margin and hover effect for better UI
    >
      {Array.isArray(node.subsystem) && node.subsystem.length > 0
        ? node.subsystem.map(renderTree)
        : null}
    </TreeItem>
  );

  return (
    <TreeView
      defaultCollapseIcon={<ExpandMore />}
      defaultExpandIcon={<ChevronRight />}
      //@ts-ignore
      expanded={openNodes} // Controlled expansion
      onNodeToggle={handleNodeToggle} // Updated to match expected event type
      sx={{
        padding: "10px",
        border: "1px solid #ccc",
        borderRadius: "8px",
        backgroundColor: "#f9f9f9",
        "& .MuiTreeItem-root": { marginBottom: "5px" }, // Add margin for better UI spacing
      }} // UI Enhancements with MUI sx prop
    >
      {nodes.map(renderTree)} {/* Render top-level nodes */}
    </TreeView>
  );
};

export default HierarchyTree;
