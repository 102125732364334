import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { styled } from "@mui/material/styles";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { TreeItemProps, treeItemClasses } from "@mui/lab/TreeItem";
import Collapse from "@mui/material/Collapse";
import Paper from "@mui/material/Paper";
// web.cjs is required for IE11 support

import { TransitionProps } from "@mui/material/transitions";
import { animated, useSpring } from "@react-spring/web";

import { isNull, isUndefined } from "lodash";
// src/Components/Assets/images/svgs/openfolderblue.svg
// openfolderblue.svg
import openFolder from "../../Assets/images/svgs/openfolderblue.svg";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FolderIcon from "@mui/icons-material/Folder";
import styles from "./CompareTreeViewcomponents.module.scss";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

import { ConstructionOutlined } from "@mui/icons-material";
import { API } from "../../api-services";
import { Typography } from "@mui/material";


function TransitionComponent(props: TransitionProps) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: "translate3d(20px,0,0)",
      display: "none",
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
      display: "block",
    },
  });

  return (
    <animated.div style={style} className="Tempdekho">
      <Collapse {...props} />
    </animated.div>
  );
}

const StyledTreeItem = styled((props: TreeItemProps) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 10,
    paddingLeft: 8,
    borderLeft: `1px dashed ${theme.palette.text.primary}`,
  },
}));

interface CompareTreeViewcomponentsProps {
  HierarchyData: any;
  onSelectCallback: any;
}
const CompareTreeViewcomponents = (props: CompareTreeViewcomponentsProps) => {
  const { HierarchyData, onSelectCallback } = props;
  const { projectId, topVaultId, vault, leafNode, treeabbr ,NodeId,abbr,Partname,TopId,depthNode} = useParams<any>();
  const { url } = useRouteMatch();
  const [activeId, setActiveId] = React.useState<any>(null);
  const [HierarchyAllData, setHierarchyAllData] = React.useState<any>(null);
  const [loader, setLoader] = React.useState<any>(false);
  const [TopVaultsfetched, setTopVaultsfetch] = useState<any>([]);
  const [menuItems, setMenuItems] = React.useState<any>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  console.log("HierarchyAllData",HierarchyAllData)
 



  // @ts-ignore
  const [expanded, setExpanded] = React.useState<any>([parseInt(TopId)]);
  const [TopLevel, setTopLevel] = React.useState();
  const [titleString,setTitleString] = React.useState<any>("")
  const [selected, setSelected] = React.useState<any>(TopId);
  const[PartName,setPartName]=React.useState()
  const history = useHistory<any>();
 

  const handleChange = ( nodeId: any, abbreviation: any,partName:any,leaf:any,depth:any,path:any) => {




    let a:any=sessionStorage.getItem("Comparenode");
    let val:any=JSON.parse(a);
    if(val?.includes(nodeId)){
      let noDuplicate:any = val?.filter((item:any)=>{return item != nodeId})
      let b:any = sessionStorage.setItem("Comparenode", JSON.stringify(noDuplicate));
    }
    else{
      val.push(nodeId);
      let b:any = sessionStorage.setItem("Comparenode", JSON.stringify(val));
    }  
   
    setActiveId(nodeId);
    setPartName(partName)
    onSelectCallback(nodeId, abbreviation, partName,leaf,depth,path);
    const index = expanded.indexOf(nodeId);
   
 
    const copyExpanded = [...expanded];
    if (index === -1) {
      copyExpanded.push(nodeId);
    } else {
      copyExpanded.splice(index, 1);
    }
   
    setExpanded(copyExpanded);
    setSelected(copyExpanded);

   
  };

  // useEffect(() => {
  //   if (abbr!=="product"  && !isNull(HierarchyAllData) 
  //   ) {
  //     setLoader(true);
  //     API.get("/customer/compare_module/", { hierarchy: true,top_vaults:topVaultId ,part_name:PartName,depth:depthNode,part_id:activeId}).then(
  //       (res: any) => {
  //         setHierarchyAllData(res.data);
  //         setLoader(false);
  //       }
  //     );
  //   }
  // }, [activeId]);





  useEffect(()=>{ let a:any=sessionStorage.getItem("Comparenode");
  let val:any=JSON.parse(a);
  if(val!=null){
   setExpanded(val);
   setSelected(val);
  }},[TopId,sessionStorage.getItem("Comparenode")])

  useEffect(() => {
    setHierarchyAllData(HierarchyData && HierarchyData);
  }, [HierarchyData]);

  return (
    <TreeView
      expanded={expanded}
      key={HierarchyAllData?.s_name}
      id={HierarchyAllData?.s_name}
      defaultParentIcon={<FolderIcon fill="primary" />}
      className={styles.treetemp}
      defaultCollapseIcon={
        HierarchyAllData &&
        HierarchyAllData?.is_leaf == false && (
          <img src={openFolder} style={{ width: "18px", color: "#007fff" }} />
        )
      }
      defaultExpandIcon={
        HierarchyAllData?.is_leaf == false && <FolderIcon color="primary" />
      }
      defaultEndIcon={
        HierarchyAllData?.is_leaf ? (
          <InsertDriveFileIcon color="primary" />
        ) : (
          <FolderIcon color="primary" />
        )
      }
      onNodeSelect={() =>handleChange( HierarchyAllData?.s_name, HierarchyAllData?.abbreviation,HierarchyAllData?.parts_name,HierarchyAllData?.is_leaf,HierarchyAllData?.depth,HierarchyAllData?.path)
      }
      selected={selected}
      aria-controls={open ? "basic-menu" : undefined}
      aria-haspopup="true"
      aria-expanded={open ? "true" : undefined}
      sx={{
        "&.MuiTreeItem-content{}.Mui-selected": {
          backgroundColor:
            HierarchyAllData?.s_name == NodeId
              ? "#cee6fa !important"
              : "white !important",
        },
      }}
    >
      <StyledTreeItem
        data-index={HierarchyAllData?.s_name}
        nodeId={HierarchyAllData?.s_name}
        // onMouseEnter={()=>hoverData(`${HierarchyAllData?.part_no} ${
        //   HierarchyAllData?.part_no ? "x" : ""
        // } ${HierarchyAllData?.quantity} ${HierarchyAllData?.name}`,HierarchyAllData.id)}
        // title={titleString && url.includes("costing") && titleString}
        label={
          <Typography className={styles.treeViewData}>
            <Typography className={styles.treeViewText}>          
               {HierarchyAllData?.parts_name}
            </Typography>
          </Typography>
        }
      >
        {HierarchyAllData &&
          HierarchyAllData?.subsystem?.map((subsystemData: any, index: any) => {
            return (
              <>
                <CompareTreeViewcomponents
                  HierarchyData={subsystemData}
                  key={subsystemData?.s_name}
                  onSelectCallback={onSelectCallback}
                />
              </>
            );
          })}
      </StyledTreeItem>
    </TreeView>
  );
};

export default CompareTreeViewcomponents;
