import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import { DataGrid, GridColDef, GridCsvExportMenuItem, GridCsvExportOptions, GridToolbarContainer, GridToolbarExportContainer, GridValueGetterParams } from "@mui/x-data-grid";
import {
  CellNameOne,
  CellNameSecond,
  CellNameThird,
  FeatureCellNames,
} from "./ParameterHeaderType";
import { useHistory, useParams } from "react-router-dom";
import { API } from "../../api-services";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import swal from "sweetalert";
import InfoIcon from "@mui/icons-material/Info";
import { Button, ButtonProps, TextField } from "@mui/material";
import { AddParameterDataBaseModal } from "./AddParameterDataBaseModal";
import { useBaseModal } from "../../ComponentUI/SearchPage/useBaseModal";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import TableChartIcon from '@mui/icons-material/TableChart';
import AddIcon from '@mui/icons-material/Add';
import { TableParameterModal } from "./TableParameterModal";
import SearchIcon from "@mui/icons-material/Search";
import { ParameterlibraryInfoModal } from "./ParameterlibraryInfoModal";
import useWindowDimensions from "../../ui-reusable-component/useWindowDimensions";
import { darken, lighten, styled } from '@mui/material/styles';
import { AddParameterDataBaseFeatureModal } from "./AddParameterDataBaseFeatureModal";
import { FeatureParameterLibraryInfo } from "./FeatureParameterLibraryInfo";
import Swal from "sweetalert2";
import { AUTH } from "../../Redux/Services/auth.service";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";




const getBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.7) : lighten(color, 0.7);

const getHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.6) : lighten(color, 0.6);

const getSelectedBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.5) : lighten(color, 0.5);

const getSelectedHoverBackgroundColor = (color: string, mode: string) =>
  mode === 'dark' ? darken(color, 0.4) : lighten(color, 0.4);

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  '& .super-app-theme--Open': {
    backgroundColor: getBackgroundColor(theme.palette.info.main, theme.palette.mode),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.info.main,
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.info.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.info.main,
          theme.palette.mode,
        ),
      },
    },
  },
  '& .super-app-theme--Filled': {
    backgroundColor: getBackgroundColor(
      theme.palette.success.main,
      theme.palette.mode,
    ),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.success.main,
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.success.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.success.main,
          theme.palette.mode,
        ),
      },
    },
  },
  '& .super-app-theme--PartiallyFilled': {
    backgroundColor: getBackgroundColor(
      theme.palette.warning.main,
      theme.palette.mode,
    ),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.warning.main,
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.warning.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.warning.main,
          theme.palette.mode,
        ),
      },
    },
  },
  '& .super-app-theme--Rejected': {
    backgroundColor: getBackgroundColor(
      theme.palette.error.main,
      theme.palette.mode,
    ),
    '&:hover': {
      backgroundColor: getHoverBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode,
      ),
    },
    '&.Mui-selected': {
      backgroundColor: getSelectedBackgroundColor(
        theme.palette.error.main,
        theme.palette.mode,
      ),
      '&:hover': {
        backgroundColor: getSelectedHoverBackgroundColor(
          theme.palette.error.main,
          theme.palette.mode,
        ),
      },
    },
  },
}));

export interface ParameterTableProps {
  setParentsCounter: any;
  TableCounter: any;
  setRecyclebinCounter: any;
  useraction: any;
}
const csvOptions: GridCsvExportOptions = { fileName: 'Table In CSV', delimiter: ",", utf8WithBom: true };

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer style={{ height: '1.5rem', marginBottom: '0.5rem' }}>
    <span style={{ position: 'absolute', right: '0rem' }}>
      {/* <GridToolbarColumnsButton /> */}
      {/* <GridToolbarDensitySelector /> */}
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
)
export const FeatureParameterTable = (props: ParameterTableProps) => {
  const { setParentsCounter, TableCounter, setRecyclebinCounter, useraction } = props;
  const { datatype, search } = useParams<any>();
  const [TableRows, setTableRows] = useState<any>([]);
  const [loader, setLoader] = useState<any>(false);
  const [Counter, setCounter] = useState<any>(0);
  const [PageCount, setPageCount] = useState<any>();
  const [count, setCount] = useState<any>(1);
  const parameterModal = useBaseParamsModal()
  const [rows, setrows] = useState<any>()
  const tableModal = useBaseParamsModal()
  const infoModal = useBaseModal()
  const [searchvalue, setsearchvalue] = useState<any>(null);
  const [EditCounter, setEditCounter] = useState<any>(0)
  const [searchkey, setSearchkey] = useState<any>(null);
  const { height, width } = useWindowDimensions();
  const history = useHistory<any>()
  const EditHandler = (Item: any) => {
    parameterModal.open(Item)
    setrows(Item)
  }

  useEffect(() => {
    API.get("/api/db/feature_library/", {
      page_count: true,
      name_type: datatype,
    }).then((res: any) => {
      setPageCount(res?.data?.page_count);

    });
  }, [datatype]);

  useEffect(() => {
    setsearchvalue(datatype == "0" || datatype == 0 ? null : search)
  }, [search, datatype])
  const PrveCount = () => {
    if (count !== 1) {
      setCount((prev: any) => prev - 1);
    }
  };

  const NextCount = () => {
    if (count <= PageCount - 1) {
      setCount((prev: any) => prev + 1);
    }
  };
  const TableHandler = (id: any) => {
    tableModal.open(id)
  }

  const DeleteHanlder = (id: any) => {
    swal({
      title: "Delete",
      text: "Are sure you want to delete ?",
      buttons: ["Cancel", "Ok"],
      // icon: "sucess",
    }).then((confirm: any) => {
      if (confirm) {
        API.delete(`/api/db/feature_library/${id}/`, { trash: true }, 0).then((res: any) => {
          setCounter((prev: any) => prev + 1);
          setRecyclebinCounter((prev: any) => prev + 1)
          setParentsCounter((prev: any) => prev + 1);
          Swal.fire({
            title: "Success!",
            text: "Deleted Successfully!",
            icon: "success",
            confirmButtonText: "Ok",
          })
        }).catch((err: any) => {
          const { data } = err?.response;
          Swal.fire({
            title: "Error!",
            text: data[0],
            icon: "error",
            confirmButtonText: "Ok",
          })
        })
      }
    });
  };

  const InfoHandler = (item: any) => {
    infoModal?.open()
    setrows(item)
  }
  const handlechange = (event: any) => {

    setsearchvalue(event?.target.value);
    if (event?.target.value.length == 0) {
      setsearchvalue(null)
      setSearchkey(null)
      setLoader(true);
      history.push(`/data-base/Feature-library/${datatype}/0`)
    }
  };


  const handlechangeDown = (e: any) => {


    if (e?.key == "Enter") {
      if (searchvalue !== null) {

        setSearchkey(searchvalue);
        history.push(`/data-base/Feature-library/${datatype}/${searchvalue}`)

      }
    }
  }


  const SearchHandler = () => {

    if (searchvalue != null) {

      setSearchkey(searchvalue);
      history.push(`/data-base/Feature-library/${datatype}/${searchvalue}`)
    }
  }

  useEffect(() => {
    setLoader(true);
    setTableRows([]);
    if (search == "0" || searchvalue == 0) {


      API.get(`/api/db/feature_library/`, {
        name_type: datatype,

        page_items: width > 1400 ? 100 : 100,
      })
        .then((res: any) => {

          setTableRows(res?.data);
          setLoader(false);
        })
        .catch((err: any) => {
          console.log("Srver Error");
          setLoader(false);
        });
    } else {
      API.get(`/api/db/feature_library/`, {
        search_key: search?.trim()
      })
        .then((res: any) => {

          setTableRows(res?.data);
          setLoader(false);
        })
        .catch((err: any) => {
          console.log("Srver Error");
          setLoader(false);
        });
    }
  }, [datatype, Counter, count, TableCounter, EditCounter, search]);

  const lockHandler = (rows: any) => {
    Swal.fire({
      title: rows?.lock == true ? "Unlock" : "Lock",
      text:
        rows?.lock == true
          ? "Are you sure you want to Unlock this parameter?"
          : "Are you sure you want to lock this parameter?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result: any) => {
      if (result.isConfirmed) {
        setLoader(true);
        API.put(`/api/db/feature_library/${rows?.id}/`, {
          lock: rows?.lock == true ? false : true,
        }).then((res: any) => {
          // setParentsCounter((prev: any) => prev + 1);
          setEditCounter((prev: any) => prev + 1);
          setLoader(false);
        })
          .catch((err: any) => {
            console.log("Server Error");
            setLoader(false);
          });
      }
    });
  };



  var RowData = TableRows && TableRows?.map((item: any, index: any) => ({ ...item, Ids: ((index + 1) + (count * 100)) - 100 }));


  const SearchColumsFiled = [
    { field: "id", hide: true },
    { field: "Ids", headerName: "Sl No." },
    {
      field: "name",
      headerName: "Parameter Name",
      minWidth: width > 1400 ? 400 : 500,
      // minWidth: width > 1400 ? 70 : 110

      flex: 1
    },

    {
      field: "unit",
      headerName: "Unit",
      minWidth: width > 1400 ? 100 : 80,

      // minWidth: width > 1400 ? 70 : 110

      flex: 1
    },
    {
      field: "data_type",
      headerName: "Type",
      minWidth: width > 1400 ? 100 : 80,

      // minWidth: width > 1400 ? 70 : 110

      flex: 1
    },

    {
      field: "actions",
      headerName: "Action",
      minWidth: width > 1400 ? 90 : 80,
      renderCell: (cellvalues: any) => {
        return (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
              {useraction && useraction?.includes("U") && cellvalues?.row?.lock != true ? (<EditIcon titleAccess="Edit" sx={{ cursor: "pointer", }} onClick={() => EditHandler(cellvalues?.row)} />)
                : (<EditIcon titleAccess="Edit" sx={{ opacity: 0.5 }} />)}

              {cellvalues?.row?.mapped_status ? (
                <InfoIcon sx={{ cursor: "pointer" }} onClick={() => InfoHandler(cellvalues?.row)} />
              ) : (
                useraction && useraction?.includes("D") && <DeleteIcon
                  onClick={() => {
                    if (cellvalues?.row?.lock != true) {
                      DeleteHanlder(cellvalues?.row?.id)
                    }
                  }}
                  titleAccess="Delete"
                  sx={{ cursor: "pointer", opacity: cellvalues?.row?.lock != true ? 1 : 0.5 }}
                />
              )}
              {cellvalues?.row?.lock === true ? (
                // Case 1: If user is SuperUser or ProjectAdmin, show clickable green lock
                (AUTH.isSuperUser || AUTH.isProjectAdmin) ? (
                  <LockIcon
                    sx={{ cursor: 'pointer', color: 'green' }}
                    onClick={() => lockHandler(cellvalues?.row)}
                  />
                ) : (
                  // Case 2: If user is not SuperUser or ProjectAdmin, show disabled lock
                  <LockIcon sx={{ opacity: 0.5 }} />
                )
              ) : (
                // Case 3: If lock is false and user is SuperUser or ProjectAdmin, show red open lock
                (AUTH.isSuperUser || AUTH.isProjectAdmin) && (
                  <LockOpenIcon
                    sx={{ cursor: 'pointer', color: 'red' }}
                    onClick={() => lockHandler(cellvalues?.row)}
                  />
                )
              )}
            </Box>
          </>
        );
      },
      // minWidth: width > 1400 ? 70 : 110

      flex: 1
    },
  ]





  const columnsFiled = FeatureCellNames.includes(datatype)
    ? [
      { field: "id", hide: true },
      { field: "Ids", headerName: "Sl No." },
      {
        field: "name",
        headerName: "Parameter Name",
        minWidth: width > 1400 ? 400 : 500,
        // minWidth: width > 1400 ? 70 : 110

        flex: 1
      },

      {
        field: "unit",
        headerName: "Unit",
        minWidth: width > 1400 ? 100 : 80,

        // minWidth: width > 1400 ? 70 : 110

        flex: 1
      },
      {
        field: "data_type",
        headerName: "Type",
        minWidth: width > 1400 ? 100 : 80,

        // minWidth: width > 1400 ? 70 : 110

        flex: 1
      },

      {
        field: "actions",
        headerName: "Action",
        width: width > 1400 ? 90 : 80,
        renderCell: (cellvalues: any) => {
          return (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                {useraction && useraction?.includes("U") && cellvalues?.row?.lock != true ? (<EditIcon titleAccess="Edit" sx={{ cursor: "pointer", }} onClick={() => EditHandler(cellvalues?.row)} />) :
                  <EditIcon titleAccess="Edit" sx={{ opacity: 0.5 }} />}

                {cellvalues?.row?.mapped_status ? (
                  <InfoIcon sx={{ cursor: "pointer" }} onClick={() => InfoHandler(cellvalues?.row)} />
                ) : (
                  useraction && useraction?.includes("D") && <DeleteIcon
                    onClick={() => {
                      if (cellvalues?.row?.lock != true) {
                        DeleteHanlder(cellvalues?.row?.id)
                      }
                    }}
                    titleAccess="Delete"
                    sx={{ cursor: "pointer", opacity: cellvalues?.row?.lock != true ? 1 : 0.5 }}
                  />
                )}
                {cellvalues?.row?.lock === true ? (
                  // Case 1: If user is SuperUser or ProjectAdmin, show clickable green lock
                  (AUTH.isSuperUser || AUTH.isProjectAdmin) ? (
                    <LockIcon
                      sx={{ cursor: 'pointer', color: 'green' }}
                      onClick={() => lockHandler(cellvalues?.row)}
                    />
                  ) : (
                    // Case 2: If user is not SuperUser or ProjectAdmin, show disabled lock
                    <LockIcon sx={{ opacity: 0.5 }} />
                  )
                ) : (
                  // Case 3: If lock is false and user is SuperUser or ProjectAdmin, show red open lock
                  (AUTH.isSuperUser || AUTH.isProjectAdmin) && (
                    <LockOpenIcon
                      sx={{ cursor: 'pointer', color: 'red' }}
                      onClick={() => lockHandler(cellvalues?.row)}
                    />
                  )
                )}
              </Box>
            </>
          );
        },
        // minWidth: width > 1400 ? 70 : 110

        // flex: 1
      },
    ]
    : [
      { field: "id", hide: true },
      { field: "Ids", headerName: "Sl No." },
      {
        field: "name",
        headerName: "Parameter Name",
        minWidth: width > 1400 ? 400 : 500,
        // minWidth: width > 1400 ? 70 : 110

        flex: 1
      },

      {
        field: "unit",
        headerName: "Unit",
        minWidth: width > 1400 ? 100 : 80,

        // minWidth: width > 1400 ? 70 : 110

        flex: 1
      },
      {
        field: "data_type",
        headerName: "Type",
        minWidth: width > 1400 ? 100 : 80,

        // minWidth: width > 1400 ? 70 : 110

        flex: 1
      },

      {
        field: "actions",
        headerName: "Action",
        minWidth: width > 1400 ? 90 : 80,
        renderCell: (cellvalues: any) => {
          return (
            <>
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
                {useraction && useraction?.includes("U") && cellvalues?.row?.lock != true ? (<EditIcon titleAccess="Edit" sx={{ cursor: "pointer", }} onClick={() => EditHandler(cellvalues?.row)} />)
                  : (<EditIcon titleAccess="Edit" sx={{ opacity: 0.5 }} />)}

                {cellvalues?.row?.mapped_status ? (
                  <InfoIcon sx={{ cursor: "pointer" }} onClick={() => InfoHandler(cellvalues?.row)} />
                ) : (
                  useraction && useraction?.includes("D") && <DeleteIcon
                    onClick={() => {
                      if (cellvalues?.row?.lock != true) {
                        DeleteHanlder(cellvalues?.row?.id)
                      }
                    }}
                    titleAccess="Delete"
                    sx={{ cursor: "pointer", opacity: cellvalues?.row?.lock != true ? 1 : 0.5 }}
                  />
                )}
                {cellvalues?.row?.lock === true ? (
                  // Case 1: If user is SuperUser or ProjectAdmin, show clickable green lock
                  (AUTH.isSuperUser || AUTH.isProjectAdmin) ? (
                    <LockIcon
                      sx={{ cursor: 'pointer', color: 'green' }}
                      onClick={() => lockHandler(cellvalues?.row)}
                    />
                  ) : (
                    // Case 2: If user is not SuperUser or ProjectAdmin, show disabled lock
                    <LockIcon sx={{ opacity: 0.5 }} />
                  )
                ) : (
                  // Case 3: If lock is false and user is SuperUser or ProjectAdmin, show red open lock
                  (AUTH.isSuperUser || AUTH.isProjectAdmin) && (
                    <LockOpenIcon
                      sx={{ cursor: 'pointer', color: 'red' }}
                      onClick={() => lockHandler(cellvalues?.row)}
                    />
                  )
                )}
              </Box>
            </>
          );
        },
        // minWidth: width > 1400 ? 70 : 110
        flex: 1
      },
    ]




  return (
    <>
      {parameterModal.isOpen &&
        <AddParameterDataBaseFeatureModal
          Modaltype={"Edit"}
          datatype={datatype}
          isOpen={parameterModal.isOpen}
          onCloseModal={parameterModal.close}
          Item={parameterModal?.Item}
          rows={rows}
          setTableCounter={setEditCounter}
        />}
      {tableModal.isOpen &&
        <TableParameterModal
          isOpen={tableModal.isOpen}
          onCloseModal={tableModal.close}
          Item={tableModal?.propsId}
          setCounter={setCounter}
        />}

      {infoModal.isOpen &&
        <FeatureParameterLibraryInfo

          isOpen={infoModal.isOpen}
          onCloseModal={infoModal.close}
          Item={rows}
        />
      }
      <Box sx={{ position: 'absolute', right: '17rem', marginTop: '-2.5rem' }}>
        {datatype &&
          <>
            <TextField
              type="search"
              placeholder="Search"
              value={searchvalue == 0 ? "" : searchvalue}
              style={{ color: "#007fff", fontSize: "0.9rem" }}
              onKeyDown={(event: any) => handlechangeDown(event)}
              onChange={(event: any) => handlechange(event)}
              variant="standard"
              sx={{
                "& .MuiInput-underline:before": {
                  borderBottomColor: 'primary.main'
                }
              }} /><SearchIcon sx={{ color: "#007fff", cursor: "pointer", fontSize: { lg: '2.4rem', xl: '2.25rem', }, padding: '3px', borderBottom: '1px solid', }} onClick={() => SearchHandler()} />
          </>
        }
      </Box>
      <Box sx={{
        width: "100%", height: { lg: '81vh', xl: "85vh", },
        // "& .super-app-theme--true": {
        //   bgcolor: "#ecffec",
        //   color: "green",
        // },
        "& .super-app-theme--true": {
          bgcolor: "#ecffec",
          color: "green",
        },
        "& .super-app-theme--lock": {
          bgcolor: "#c5c5c5",
        },
      }}>
        {RowData && (
          <DataGrid
            headerHeight={48}
            rowHeight={42}
            rows={RowData} columns={searchvalue == 0 ? columnsFiled : SearchColumsFiled} loading={loader}
            density="compact"
            // autoHeight={true}
            // components={{
            //   Toolbar: () => {
            //     return CustomToolbar();
            //   },
            // }}
            sx={{
              "& ::-webkit-scrollbar": {
                width: "0.3rem",
                height: "0.3rem",
              },
              "& ::-webkit-scrollbar-thumb": {
                // background: $thumb-color;
                backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                borderRadius: "10px",
              },
              "& ::- webkit-scrollbar-thumb: hover ": {
                // width: "4px",
                backgroundColor: "#045DE9 !important",
              },
              "::-webkit-scrollbar-track": {
                backgroundColor: "#e1e1f3 !important",
              },
              '&.MuiDataGrid-root .MuiDataGrid-cell': {
                borderBottomColor: 'primary.light',
              },
            }}
            getRowClassName={(params) => {
              // Handle both lock and mapped_status conditions
              if (params.row.lock === true) {
                return "super-app-theme--lock";
              }
              return `super-app-theme--${params.row.mapped_status}`;
            }}
            // getRowClassName={(params) => `super-app-theme--${params.row.mapped_status}`}
            disableSelectionOnClick={true}
            hideFooter={true}
            hideFooterPagination={true}
          />
        )}

      </Box>
      <Box sx={{
        display: "flex",
        justifyContent: "flex-end",
        alignItems: "center",
        columnGap: '0.5rem',
      }}>

        {searchvalue == 0 ?
          <><Button sx={{ cursor: "pointer", padding: '0.5rem', minWidth: '1rem' }} disabled={count !== 1 ? false : true}>
            <ChevronLeftIcon onClick={() => PrveCount()} />
          </Button><Box sx={{ backgroundColor: 'primary.light', borderRadius: '50%', width: '2rem', height: '2rem', justifyContent: "center", alignItems: 'center', display: 'flex', }}>{count}</Box><Button sx={{ cursor: "pointer", padding: '0.5rem', minWidth: '1rem' }} disabled={count <= PageCount - 1 ? false : true}>
              <ChevronRightIcon onClick={() => count <= PageCount && NextCount()} />
            </Button></> : ""}
      </Box>
    </>
  );
};
