import { TextField } from "@mui/material";
import { debounce } from "lodash";
import * as React from "react";
import Swal from "sweetalert2";
import { API } from "../../../api-services";
import { ADMIN } from "../../../Redux/Services/admin.service";

export interface IEditItemsNameProps {
  value?: any;
  getRMData: any;
  idd: any;
  getRMDataList: any;
  CalcculatorUpdatehandler:any;
}

export function EditNameOverhead(props: IEditItemsNameProps) {
  const { value, getRMData ,idd,getRMDataList,CalcculatorUpdatehandler} = props;
  const [name, setName] = React.useState<any>("");
  const [oldName,setOldName]=React.useState<any>('');
  const ExactDebounce = React.useRef(
    debounce(async (value: any,iddd:any) => {
      await API.put(
        `/cost/overhead/${iddd}/`,
        {
          alias: value,
        },
        {},
        0
      )
        .then((res: any) => {
          CalcculatorUpdatehandler()
          setOldName(value);
       ADMIN.toast.info("Name Updated Successfully");
          // getRMDataList();
        })
        .catch((err: any) => {
          const { data } = err?.response;
          Swal.fire({
            icon: "error",
            html: `<div>
                               <br />
                               <p style="color:"red">${data[0]}</p>   
                                </div>`,
          });
        });
    }, 1000)
  ).current;

  React.useEffect(() => {
    return () => {
      ExactDebounce.cancel(); // Cancel the debounced function
    };
  }, []);
  React.useEffect(() => {
    if (value) {
      setName(value?.alias);
      setOldName(value?.alias);
    }
  }, [value]);
  const handleChange = (e: any) => {
    const { value } = e.target;
    setName(value);
  };
  return (
    <TextField
      placeholder="Material Name"
      // label=' Material Name'
      variant="standard"
      size="small"
      value={name}
      onChange={handleChange}
      onBlur={(event:any)=>{
        if(name!==oldName){
          ExactDebounce(event.target.value,idd);
        }
      }}
      fullWidth
      sx={{
        width: "100%",
        margin: "0.2rem 0",
        ".MuiInputBase-root.MuiInput-root": {
          fontSize: "1rem !important",
        },
        "& .MuiInput-underline:before": { borderBottomColor: "white" },
        "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
      }}
    />
  );
}
