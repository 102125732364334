import {
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from "@mui/material";
import styles from "./CostingTableSection.module.scss";

export interface IMachineRevisionProps {
  machineRevisionData?: any;
}

export function MachineRevision(props: IMachineRevisionProps) {
  const { machineRevisionData } = props;
  return (
    <Box>
      <Table>
        <TableHead
          sx={{ backgroundColor: "primary.main", color: "common.white" }}
        >
          <TableRow>
            <TableCell colSpan={3} sx={{ color: "common.white", padding: "0.1rem 0.5rem",fontSize:'1rem', }}>
              Machine Hour Rate
            </TableCell>
            {/* <TableCell
              sx={{ color: "common.white", padding: "0.2rem 0.5rem",textAlign:'right' }}
            >
              Value
            </TableCell> */}
           
          </TableRow>
        </TableHead>
        <TableBody>
          {machineRevisionData &&Object.keys(machineRevisionData)?.length > 0 ?
            Object.keys(machineRevisionData)?.map((item: any, index: any) => {
              return (
                index !== 0 && (
                  <TableRow>
                    <TableCell
                      sx={{
                        borderBottomColor: "primary.light",
                        padding: "0 0.5rem",
                        color:'#646464',                        
                      }}
                      className={styles.tableBcell}
                    >
                      {item}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottomColor: "primary.light",
                        width:'25%',
                        textAlign:'right',
                        padding: "0 0.5rem",
                      }}
                      className={styles.tableBcell}
                    >
                      {machineRevisionData[item]['val']}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottomColor: "primary.light",
                        width:'25%',
                        textAlign:'right',
                        padding: "0 0.5rem",
                      }}
                      className={styles.tableBcell}
                    >
                      {machineRevisionData[item]?.['unit']}
                    </TableCell>
                  </TableRow>
                )
              );
            }):<Typography align="center" mt={2}>No Data available</Typography>}
        </TableBody>
      </Table>
    </Box>
  );
}
