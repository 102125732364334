import * as React from 'react';
import {
    Accordion,
    Box,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
  } from "@mui/material";
  import CircleIcon from '@mui/icons-material/Circle';
import { API } from '../../../../api-services';
import { userPermissionAction } from '../../../BOMCreate/BOMEntry/BOMEntryLandingPage';
import { useRouteParams } from '../../../../ui-reusable-component/useRouteParams';
import IdeaEntryAccordian from './IdeaEntryAccordian';


export interface IIdeaEntryTableComponentsProps {
    tableDataGetCount: any;
    // BOMValidationStatus?:any;
}

export default function IdeaEntryTableComponents (props: IIdeaEntryTableComponentsProps) {
  const { tableDataGetCount } = props;
  const { workstation, topVault, vault, projectId ,system,ideaId} = useRouteParams<any>();
  const handleSelectChangecategory = (event: any, newSystemValue: any) => {};
  const expandStatus = true;
  const [tableData, setTableData] = React.useState<any>([]);
  const [tableDataLoader, setTableDataLoader] = React.useState<any>(false);
  const [tableAccData,setTableAccData]=React.useState<any>([]);
  const [tableDataAll, setTableDataAll] = React.useState<any>([]);
  const [selectedOptions, setSelectedOptions] = React.useState<any>([]);
  const [dataForUpdate, setDataForUpdate] = React.useState<any>([]);
  const [tableDataOptions, setTableDataOptions] = React.useState<any>([]);
  const [colorButton, setColorButton] = React.useState<any>("");
  const [showButton, setShowButton] = React.useState<any>(false);
  const [selectedTableId, setSelectedTableId] = React.useState<any>("");
  const [isDeleting, setIsDeleting] = React.useState<any>(false);
  const [selectedIdDelete, setSelectedIdDelete] = React.useState<any>("");
  const userAction = React.useContext(userPermissionAction);
  let UserAction = userAction && userAction?.action;

  const getObjKeyName = () => {
    if (vault == 0) {
      return { top_vault: topVault };
    } else {
      return { vault: system };
    }
  };
  const getTableData = () => {
    
    API.get(
      "/idea/idea_create_view/",
      {
        table: true,
        top_vault:topVault,
        id:ideaId,
        drop_empty: true,
      },
      0
    )
      .then((res: any) => {
        
        setTableData(res.data);
      })
      .catch((err: any) => {
        
      });
  };

  const getTableNameCount=()=>{
    setTableDataLoader(true);
    API.get("/idea/idea_create_view/",{
     top_vault:topVault,
     id:ideaId,
      table:true,
      basic_info:true,
    },0).then((res:any)=>{
      setTableAccData(res.data);
      setTableDataLoader(false);
    }).catch((err:any)=>{
      setTableDataLoader(false);
    })
  }

  const getTableDataWOLoader = () => {
    API.get(
      "/idea/idea_create_view/",
      {
        table: true,
        top_vault:topVault,
        id:ideaId,
        drop_empty: true,
      },
      0
    )
      .then((res: any) => {
        setTableData(res.data);
      })
      .catch((err: any) => {});
  };
  const getTableDataAll = () => {
    API.get(
      "/idea/idea_create_view/",
      {
        table: true,
        top_vault:topVault,
        id:ideaId
      },
      0
    )
      .then((res: any) => {
        setTableDataAll(res.data);
        setDataForUpdate([]);
      })
      .catch((err: any) => {});
  };

  React.useEffect(() => {
    if (ideaId != 0) {
      getTableData();
      getTableDataAll();
      getTableNameCount();
    }
  }, [tableDataGetCount,ideaId]);


  const [expanded, setExpanded] = React.useState<string | false>("panel1");

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? false : false);
    };


  return (
    <div>
      {!tableDataLoader && tableAccData ? (
        tableAccData?.map((item: any, index: number) => {
          return (
            <Accordion
              // expanded={expanded === `${index}`}
              onChange={handleChange(`${index}`)}              
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  columnGap: "0.5rem",
                  width: "100%",

                }}
              >               
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",                    
                    backgroundColor: " #e2e9aa",  
                    height:'2rem',   
                    width: "100%", 
                    paddingRight: "0.5rem",           
                  }}
                ><Typography sx={{display: "flex",
                alignItems: "center",
                columnGap: "0.5rem",}}>
                  <CircleIcon  sx={{fontSize:'0.65rem',margin:'0 0.5rem'}} />                  
                  </Typography>
                  <IdeaEntryAccordian
                    expandStatus={expandStatus}
                    tableName={item?.name}
                    item={item?.id}
                    getTableData={getTableData}
                    dataForUpdate={dataForUpdate}
                    tableData={tableData}
                    setTableData={setTableData}
                    dataForCheck={item?.name}
                    setSelectedOptionsTable={setSelectedOptions}
                    setColorButton={setColorButton}
                    colorButton={colorButton}
                    setSelectedTableId={setSelectedTableId}
                    selectedTableId={selectedTableId}
                    getTableDataAll={getTableDataAll}
                    tableDataLoader={tableDataLoader}
                    setShowButton={setShowButton}
                    showButton={showButton}
                    materialCount={item?.count}
                    // BOMValidationStatus={BOMValidationStatus}
                    tableAccData={getTableNameCount}
                  />                 
                </Box>
              </Box>             
            </Accordion>
          );
        })
      ) : (
        <Box sx={{ width: "100%", padding: "0.5rem", marginTop: "2rem" }}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
        </Box>
      )}
    </div>
  );
}
