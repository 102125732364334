// import { blue, lightBlue, pink } from "@mui/material/colors";
import { PaletteMode } from "@mui/material";
import {
    createTheme,
    responsiveFontSizes,
} from '@mui/material/styles';



let theme = createTheme({
    typography: {
        fontSize: 16,
        fontFamily: "Roboto, sans-serif, Helvetica, Arial",        
        fontWeightLight: 300,
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,

        h1: { // incase h1 needs to have a separate fontFamily 
            fontFamily: 'Roboto',
            fontSize: 32,
        },
        h2: { // incase h2 needs to have a separate fontFamily 
            fontFamily: 'Roboto',
            fontSize: 26,
        },
        h3: { // incase h3 needs to have a separate fontFamily 
            fontFamily: 'Roboto',
            fontSize: 22,
        },
        h4: { // incase h4 needs to have a separate fontFamily 
            fontFamily: 'Roboto',
            fontSize: 20,
        },
        h5: { // incase h5 needs to have a separate fontFamily 
            fontFamily: 'Roboto',
            fontSize: 18,
        },
        subtitle1: {
            fontFamily: 'Roboto',
            fontSize: 16,
        },
        subtitle2: {
            fontFamily: 'Roboto',
            fontSize: 12,
        },
        body1: {
            fontFamily: 'Roboto',
            fontSize: 16,
        },
        body2: {
            fontFamily: 'Roboto',
            fontSize: 12,
        },
    },
    components: {
        MuiContainer: {
            styleOverrides: {
                root: {
                    fontSize: 16,
                }
            }
        }
    }
});
theme = responsiveFontSizes(theme);

export const appTheme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },

    palette: {
        mode: "light",
        common: {
            black: "#000",
            white: "#FFF",
        },
        primary: {
            light: '#ddefff',
            main: '#007fff',
            dark: '#0069d3', //#3f51b5
            contrastText: '#ffffff',

        },
        secondary: {
            light: '#1976d214',
            main: '#007fff14',
        },
        text: {
            primary: '#007fff',
            secondary: '#ddefff',
            // disabled: 'rgba(60, 72, 88, 0.38)',
            // hint: 'rgba(60, 72, 88, 0.38)',
        }
    },
    typography: {
        fontSize: 16,
        fontFamily: "Roboto",        
    },

});
