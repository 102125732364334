import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
// import FastForwardIcon from "@mui/icons-material/FastForward";

// import EditIcon from '@mui/icons-material/Edit';

import { makeStyles } from '@mui/styles';
import { useQueryDispatch } from "../../CustomHook/useQueryDispatch";
import { getUserProfilePic } from "../../Redux/Actions/user.action";
import { AUTH } from "../../Redux/Services/auth.service";
import { useSelector } from "react-redux";
import { userSelectors } from "../../Redux/Reducers/user.reducer";
import useFetch from "../../Core/CustomHooks/useFetch";
import { USER } from "../../Redux/Services/user.service";
import Grid from "@mui/material/Grid";
import LetSuspense from "../../LetSuspense";
import BouncingLoader from "../../ui-reusable-component/BouncingLoader";
import Guestuser from "./GusetUser";
import SkeletonLoaderComponent from "../SkeletonLoader/SkeletonLoaderComponent";
import { Divider, Typography } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';



const styled = {
  boxSize: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",

    width: "39rem",
    height: "fit-content",
    minHeight: "20rem",
    borderRadius: "0.5rem",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    p: 0,
    outline: 0
  },
  NoAccessBoxSize: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: "center !important",
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "39rem",
    height: "fit-content",
    minHeight: "20rem",
    borderRadius: "0.5rem",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    p: 0,
    outline: 0
  }
  ,
  HeaderLine: {
    textAlign: "center",
    position: "relative",
  },
  SelectedHeaderLine: {
    color: "#1976d2",
  },
  nextButton: {
    position: "absolute",
    zIndex: "9999",
    left: "35rem",
    top: "1.8rem",
  },

  summery: {
    backgroundColor: "rgb(240, 247, 255)",
    borderColor: " rgb(0, 127, 255)",
    borderRadius: "10px",
    minHeight: "45px",
  },
  searchButton: {
    position: "absolute",

    // right: "37rem",
    top: "0.4rem",

    left: "-2rem",
    cursor: "default",
    color: "#1976d2",
    padding: " 0rem 0rem 0rem 2rem",
  },
  closedIcon: {
    position: "absolute",
    zIndex: "999",
    color: "#1976d2",
    right: "0.2rem",
    top: "0.5rem",
    cursor: "pointer"
  },
  editIcon: {
    marginTop: "0.3rem",
    color: "#1976d2",
    cursor: "pointer"

  },



};
// @ts-ignore

const useStyles = makeStyles((theme) => ({
  /** Changed modalStyle */
  ModalRoot:
  {
    backgroundColor: "rgba(111, 126, 140, 0.2) !important",
    backdropFilter: "blur(1px) !important",
  },
}));


export interface GuestUserModalProps {
  isOpen: any;
  onCloseModal: any;

}
const GuestUserModal = (props: GuestUserModalProps) => {
  const classes = useStyles();


  const [currentUrl, setCurrentUrl] = useState<any>("");
  const [deleteGuest, setDeleteGuest] = useState<any>(0);
  const [count, setCount] = useState<any>(0);

  const [selectedValue, setSelectedValue] = React.useState<any>();

  const [fillfromId, setFillfromId] = useState<any>();
  const [PrefilledValue, setPrefilledValue] = useState<any>();
  const [filledData, setFilledData] = useState<any>(true)
  const [tabId, setTabId] = useState<any>();
  const [VarientColor, setVarientColor] = useState<any>(false)
  const [IdColor, setIdColor] = useState<any>()


  const { isOpen, onCloseModal } = props;


  const [gusetUser, setguestUser] = useState<any>({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });
  const [hide, setHide] = useState<boolean>(false);

  useQueryDispatch({
    query: {
      action: getUserProfilePic,
      arg: {
        id: AUTH.user,
      },
    },
    dependency: [count]
    // cleanup: {
    //     action: resetTopVaults,
    //     args: [],
    // },
  });


  const userProfilePic = useSelector(userSelectors.selectAll);

  const { 0: fetched, 1: PrefilledData } = useFetch<any>({
    method: USER.guestUser,
    args: [AUTH.user],
    dependencies: [count, deleteGuest],
  });

  const GUCount =
    userProfilePic[0]?.remaining_guest_count &&
    userProfilePic[0]?.remaining_guest_count;

  const tabHandler = (e: any, action: any, type?: any) => {
    if (type == "defaultClick") {
      if (action == "filledFrom") {
        setTabId(e);


        setFillfromId(null);
      } else if (action == "PrefilledFrom") {
        setFillfromId(e);
        setTabId(null);
      }
    } else {
      if (action == "filledFrom") {
        setIdColor(e.target.id)
        setTabId(e.target.id);
        setFillfromId(null);
      } else if (action == "PrefilledFrom") {
        setFillfromId(e.target?.id);
        setIdColor(e.target?.id)
        setTabId(null);
      }
    }

  };



  useEffect(() => {
    if (PrefilledData && PrefilledData[0]?.id > 0) {
      setIdColor(PrefilledData[0]?.id)
      tabHandler(PrefilledData[0]?.id, "PrefilledFrom", "defaultClick")
      setCount(0)
    } else {
      setIdColor('1')
      tabHandler('1', "filledFrom", "defaultClick")
      setCount(0)
    }


  }, [isOpen])

  // console.log(count,"counrtrttrtrtrt")


  const renderTab = (count: any) => {
    const data = PrefilledData.map((item: any, index: any) => (
      <>
        <Button
          style={{
            color: "white",
            backgroundColor: IdColor == item?.id ? "#007fff" : "green",
            width: "3.1rem",
            minWidth: "3rem",
            height: " 2rem",
            position: "relative",
            justifyContent: "space-evenly",
            margin: "0 0.3rem 1%",
          }}


          id={item.id}
          //@ts-ignore
          onClick={(e) => tabHandler(e, "PrefilledFrom")}
        >
          {1 + index}
          {/* <span className={item.id == fillfromId ? "active_tab" : "inactive_tab"}> </span> */}
        </Button>
      </>
    ));

    var tabdata: any = [...data];
    for (var i = 0; i < count; i++) {
      //@ts-ignore
      tabdata.push(
        <>
          {/* @ts-ignore */}
          <Button
            style={{
              color: IdColor == (
                i + 1) ? "white" : "#007fff",
              backgroundColor: IdColor == (i + 1) ? "#007fff" : "white",
              border: "1px solid #007fff",
              width: "3.1rem",
              minWidth: "3rem",
              height: "2rem",
              justifyContent: "space-evenly",
              position: "relative",
              margin: "0 0.3rem 1%",
            }}

            id={i + 1}
            onClick={(i) => tabHandler(i, "filledFrom")}
          >
            {PrefilledData.length + i + 1}
            {/* <span className={i + 1 == tabId ? "active_tab" : "inactive_tab"}></span> */}
          </Button>
        </>
      );
    }
    return tabdata;
  };


  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}

      <Modal // autoFocusdal
        open={isOpen}
        className={classes.ModalRoot}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={(PrefilledData &&
          PrefilledData.length == 0 &&
          GUCount === null) ? styled.NoAccessBoxSize : styled.boxSize} >





          {/* <Popover
                  id={id}
                  open={openPop}
                  anchorEl={anchorElpop}
                  onClose={handleClosePopup}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                > */}
          {(PrefilledData &&
            PrefilledData.length == 0 &&
            GUCount === null)
            ? (
              <div style={{ alignItems: "center" }}>
                <p style={{ fontSize: "1.1rem", color: "red" }}>
                  You are not authorized to create Guest User
                </p>
              </div>
            ) : (
              <Grid
                className={
                  PrefilledData &&
                    PrefilledData?.filter(
                      (item: any, ind: any) =>
                        item.id == parseInt(fillfromId)
                    ).length > 0
                    ? "from_true"
                    : "from_height"
                }
              >

                <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
                  <Typography
                    style={{
                      fontSize: "1rem",
                      fontWeight: "600",
                      width: "100%",
                      textAlign: "center",
                      marginRight: '-2rem'
                    }}
                  >
                    Guest User
                  </Typography>
                  <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
                </Box>
                <Divider
                  sx={{
                    borderColor: "primary.light",
                    margin: "0 1rem",
                    marginBottom: "1rem",
                  }}
                />

                {/* <LetSuspense
                  loadingPlaceholder={BouncingLoader}
                  condition={fetched === "TRUE"}
                  errorCondition={fetched === "ERROR"}
                > */}
                <>
                  {fetched === "TRUE" ? <div>
                    <>
                      {/* <div style={{ color: "#007fff", fontSize: "1.1rem", fontWeight: "500", padding: "0.5rem 1rem" }}>Guest User Details</div> */}
                      <div style={{ padding: "0 1rem" }}>
                        <div style={{ width: "100%" }}>
                          {fetched == "TRUE" &&
                            PrefilledData &&
                            renderTab(GUCount)}
                        </div>
                      </div>
                    </>
                    {fillfromId == undefined && tabId == undefined && tabId == null && (
                      <div style={{ margin: "22% 22% 21% 18%" }}></div>
                    )}
                    {fillfromId && (
                      <Guestuser
                        dependencyDelete={setDeleteGuest}
                        dependency={setCount}
                        tab={"PrefilledFrom"}
                        filled={true}
                        setIdColor={setIdColor}
                        data={
                          PrefilledData &&
                          PrefilledData.filter(
                            (item: any, ind: any) =>
                              item.id == parseInt(fillfromId)
                          )
                        }
                        fillfromId={fillfromId}
                      />
                    )}
                    {tabId && (
                      <Guestuser
                        dependency={setCount}
                        filled={false}
                        setIdColor={setIdColor}

                        data={{}}
                        tab={"fillFrom"}
                        tabId={tabId}
                      />
                    )}
                  </div>
                    :
                    <SkeletonLoaderComponent />}
                </>
                {/* </LetSuspense> */}
              </Grid>
            )}
          {/* </Popover> */}
        </Box>
      </Modal>
    </div>
  );
}
export default GuestUserModal;
