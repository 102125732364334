import Box from '@mui/material/Box/Box';
import React, { useRef, useState ,useEffect} from 'react'
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import UploadIcon from "@mui/icons-material/Upload";
import DeleteIcon from "@mui/icons-material/Delete";
import styles from "../IdeagenImagesandVideosCombine.module.scss";
import CameraAltRoundedIcon from "@mui/icons-material/CameraAltRounded";
import StraightenIcon from '@mui/icons-material/Straighten';
import NoImgLoader from "../../../../../Assets/images/loading-screen.gif";
import Image from "../../../../../ViewModule/FeatureLandingPage/ArchitectureImageCard";

import DownloadIcon from '@mui/icons-material/Download';    
import RViewerJS from 'viewerjs-react';
import { LoadingButton, Skeleton } from '@mui/lab';
import { API } from '../../../../../api-services';
import { useRouteParams } from '../../../../../ui-reusable-component/useRouteParams';
import swal from "sweetalert";
import { AxiosError, AxiosResponse } from 'axios';
import { CircularProgress } from '@mui/material';
// import BomThreeSixtyModal from '../../../../../ViewModule/BomView/BomThreeSixtyModal';
import { useBaseModal } from '../../../../SearchPage/useBaseModal';
import IdeagenThreeSixtyCreateModal from './IdeagenThreeSixtyCreateModal';

interface IdeagenThreeSixtyCreateProps {

    threesixtyData: any;
    setthreesixtyCounter: any;
    actions:any
}

const IdeagenThreeSixtyCreate = (props: IdeagenThreeSixtyCreateProps) => {

    const { threesixtyData, setthreesixtyCounter,actions } = props;
    const { projectId, projectName, topVault, vault, treeabbr, workstation, isLeaf,copyStatus,vault_id } = useRouteParams<any>();
    const inputElRef = useRef<any>();
    const [isUpdating, setIsUpdating] = React.useState<any>(false);
    const [isUpdating1, setIsUpdating1] = React.useState<any>(false);
    const [DeleteLoading, setDeleteLoading] = useState<any>(false)
    const [imagetype, setimagetype] = useState<any>()
    const [isOpenmodal, setIsopenModal] = useState<any>(false)
    const ThreesixsityModalOpen = useBaseModal();
    const [BomImages, setBomImages] = useState<any>();
    const [ImageType, setImageType] = useState<any>();
    const browseFiles = (type: any) => {
        setimagetype(type)
        return inputElRef.current?.click?.();
    };

    const handleChange = (e: any) => {
        if (imagetype == false) {
            const formData = new FormData();
            // @ts-ignore
            var file = e.currentTarget.files[0]
            var keys = threesixtyData?.key?.toString()

            formData.append(keys, file)


            setIsUpdating(true);
            API.put(`/idea/pool/${vault_id}/`, formData, { vault: vault, module: 4, remove_bg: imagetype }, 0).then((res: any) => {
                setthreesixtyCounter((prev: any) => prev + 1)
                setIsUpdating(false)
            }).catch((err: any) => {
                setIsUpdating(false)
                console.log("Server EEror")
            })
        } else if (imagetype == true) {
            const formData = new FormData();
            // @ts-ignore
            var file = e.currentTarget.files[0]
            var keys = threesixtyData?.key?.toString()

            formData.append(keys, file)


            setIsUpdating1(true);
            API.put(`/idea/pool/${vault_id}/`, formData,
                { vault: vault, module: 4, remove_bg: imagetype }

                , 0).then((res: any) => {
                    setthreesixtyCounter((prev: any) => prev + 1)
                    setIsUpdating1(false)
                }).catch((err: any) => {
                    setIsUpdating1(false)
                    console.log("Server EEror")
                })
        }



        // setDisable( false );
    };

    const handleInputClick = (e: React.MouseEvent) => {
        // clear the prev value on click so that same image can be uploaded again
        (e.target as HTMLInputElement).value = "";
    };
    const togglevault = () => {
        if (vault == "0") {
            return { top_vault: topVault }
        } else if (vault != "0") {
            return { vault: vault }
        }

    }
 

    const ThreeSixityHandler = (ImageType: any) => {
        // let entries = BomImages && Object.entries(BomImages["three_sixity"]);
        ThreesixsityModalOpen.open();
        setIsopenModal(true)
        setBomImages(ImageType);
    
    
    
        // let data =
        //   BomImages &&
        //   entries.map(([key, val]: any) => {
        //     return Object.entries(val).map(([key, value]: any) => {
        //       if (key == ImageType) {
        //         return value;
        //       }
        //     });
        //   });
    
    
    
      };

    const handleClose = () => setIsopenModal(false);

    const handleDeleteSelectedVideos = async (keyId:any) => {

        // e.stopPropagation();
        swal({
            title: `Delete 360 Video Parameter`,
            text: `Are you sure?`,
            icon: "error",
            //@ts-ignore
            buttons: {
                cancel: "Cancel",
                permanent: {
                    text: `Delete`,
                    value: `deleteVideo`,
                },
            },
            closeOnClickOutside: false,
            closeOnEsc: false,
            className: "delete-swal",
        }).then(async (type: string) => {
            switch (type) {
                case "deleteVideo":
                    setDeleteLoading(true);
                    API.put(
                        `/idea/pool/${vault_id}/`,
                        { [keyId]: "null" },
                        { ...togglevault(), module: 4, remove_bg: true }


                        , 0).then((res: AxiosResponse) => {
                            setthreesixtyCounter((prev: any) => prev + 1)
                            setDeleteLoading(false);
                        }).catch((err: AxiosError) => {
                            setDeleteLoading(false);
                        });

                    break;
                default:
                    swal(`360 video parameter saved from deleting`, { icon: "error" });
            }
        });
    };


    return (
        <>

            <input
                type="file"
                style={{ display: 'none' }}
                // required
                accept="video/*"
                id="file"
                name="file"
                ref={inputElRef}
                onChange={handleChange}
                onClick={handleInputClick}
            />


            {isOpenmodal &&
              <IdeagenThreeSixtyCreateModal
                isOpen={isOpenmodal}
                onCloseModal={handleClose}
                ThreeSixityImages={BomImages && BomImages}
                query={togglevault()}

                ImageType={ImageType && ImageType}
              />
            }
            {
                threesixtyData?.value && Object.keys(threesixtyData?.value)?.length > 0 ?

                    <Box className={styles.bomThreeSixtySection}>
                        {

                            <>
                                <Box className={styles.bomThreeSixtyContainer}>
                                    {
                                        DeleteLoading ? <Box className={styles.bomdeleteImages}><CircularProgress size={40} /></Box> :

                                            <Image
                                                className={styles.bomgridImages}
                                                src={threesixtyData?.value[1]}
                                                placeholderImg={NoImgLoader}
                                                alt={threesixtyData?.paramter_name}
                                                onClick={() => ThreeSixityHandler(threesixtyData)}
                                                style={{ width: '12rem', cursor: "pointer" }}
                                            />
                                    }

                                    {(copyStatus==false || copyStatus=="false"  )&&

                                    <Box className={styles.imageOptions} sx={{ margin: '1rem 0' }}>
                                        <Box className={styles.optionDiv}>
                                            {
                                                threesixtyData?.value && Object.keys(threesixtyData?.value)?.length > 0 &&
                                                <a href={threesixtyData?.value[2]} download>
                                                    <DownloadIcon sx={{ cursor: 'pointer' }} titleAccess="Download Image" />
                                                </a>
                                            }
                                            { actions?.includes('U') &&

                                                <LoadingButton style={{ padding: 0, minWidth: '2rem' }} onClick={() => browseFiles(false)} loading={isUpdating}>
                                                    <UploadIcon titleAccess="Upload Original Background Video" sx={{ cursor: 'pointer' }} />
                                                </LoadingButton>
                                            }
                                            { actions?.includes('U') &&
                                            <LoadingButton style={{ padding: 0, minWidth: '2rem' }} onClick={() => browseFiles(true)} loading={isUpdating1} >
                                                <UploadIcon titleAccess="Upload without Background" sx={{ cursor: 'pointer', color: '#999191' }} />
                                            </LoadingButton>
                                            } 
                                            { actions?.includes('U') && <CameraAltRoundedIcon titleAccess="Camera" sx={{ cursor: 'pointer' }} />}

                                            {threesixtyData?.value && Object.keys(threesixtyData?.value)?.length > 0 && actions?.includes('D') &&
                                                <LoadingButton style={{ padding: 0, minWidth: '2rem' }} onClick={() => handleDeleteSelectedVideos(threesixtyData?.key)} loading={DeleteLoading} >
                                                    <DeleteIcon titleAccess="Delete" sx={{ cursor: 'pointer' }} />

                                                </LoadingButton>
                                            }

                                        </Box>
                                    </Box>
}
                                </Box>
                            </>
                        }

                        {


                            <Box className={styles.bomThreeSixtyContainer}>
                                {/* @ts-ignore */}
                                <RViewerJS>
                                    <Image
                                        className={styles.bomgridImages}
                                        src={threesixtyData?.value[0]}
                                        placeholderImg={NoImgLoader}
                                        alt={threesixtyData?.paramter_name}

                                        style={{ width: '12rem', cursor: "pointer" }}

                                    />
                                </RViewerJS>
{ (copyStatus==false || copyStatus=="false"  ) &&
                                <Box className={styles.imageOptions} sx={{ margin: '1rem 0' }}>
                                    <Box className={styles.optionDiv}>
                                        {threesixtyData?.value && Object.keys(threesixtyData?.value)?.length > 0 &&
                                            <a href={threesixtyData?.value[0]} download>
                                                <DownloadIcon sx={{ cursor: 'pointer' }} titleAccess="Download Image" />
                                            </a>
                                        }



                                    </Box>

                                </Box>
}
                            </Box>
                        }

                    </Box> :

                    <Box className={styles.withoutimages}>
                        {

                            <>
                                <Box className={styles.bomThreeSixtyContainer}>
                                    {isUpdating1 || isUpdating ? <Box className={styles.bomUploadVideo}><CircularProgress size={40} /></Box> :
                                        <Box className={styles.bomUploadVideo}>
                                            <span>Upload {threesixtyData?.paramter_name}</span>
                                        </Box>
                                    }
                                    {
                                        (copyStatus==false || copyStatus=="false"  ) &&
                                    
                                    <Box className={styles.imageOptions} sx={{ margin: '1rem 0' }}>
                                        <Box className={styles.optionDiv}>

                                            {  actions?.includes('C') && 

                                                <LoadingButton style={{ padding: 0, minWidth: '2rem' }} onClick={() => browseFiles(false)} >
                                                    <UploadIcon titleAccess="Upload Original Background Video" sx={{ cursor: 'pointer' }} />
                                                </LoadingButton>
                                            }
                                            {actions?.includes('C') && 
                                            <LoadingButton style={{ padding: 0, minWidth: '2rem' }} onClick={() => browseFiles(true)}  >
                                                <UploadIcon titleAccess="Upload without Background" sx={{ cursor: 'pointer', color: '#999191' }} />
                                            </LoadingButton>}
                                            {actions?.includes('C') && 
                                            <CameraAltRoundedIcon titleAccess="Camera" sx={{ cursor: 'pointer' }} />}



                                        </Box>
                                    </Box>
}
                                </Box>
                            </>
                        }



                    </Box>


            }
        </>




    )
}
export default IdeagenThreeSixtyCreate;


