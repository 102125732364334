import { ID } from "../../utlis/commonInterfaces";
import { API } from "./index.service";


const url = "/api/db/parameter/";

export default {
    get () {
        return API.get( url );
    },
    getById ( parameterId: number ) {
        return API.get( url, { id: parameterId } );
    },
    getByTableId ( tableId: ID ) {
        return API.get( url, { table_id: tableId } );
    },
    create ( parameter: any, meta?: Record<string, any> ) {
        return API.post( url, Object.assign( {}, parameter, meta ) );
    },
    update ( parameterId: number, parameter: any ) {
        return API.put( url + parameterId + "/", parameter );
    },
    delete ( parameterid: number ) {
        return API.delete( url + parameterid + "/" );
    },
};
