import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import { Divider, Skeleton } from "@mui/material";
import { useBaseModal } from "../../ComponentUI/SearchPage/useBaseModal";
import { CostingScenarioTableModal } from "./CostingScenarioTableModal";
import TableChartIcon from "@mui/icons-material/TableChart";
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList,
} from "recharts";
import { API } from "../../api-services";
import { fill } from "lodash";

export interface ICostingScenarioChartModalProps {
  isOpen: any;
  onCloseModal: any;
  query: any;
  chartType?: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "fit-content",
  maxWidth: "90vw",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

export function CostingScenarioChartModal(
  props: ICostingScenarioChartModalProps
) {
  const { isOpen, onCloseModal, query, chartType } = props;

  

  const ScenarioTableModalHandler = () => {
    ScenarioTableModal.open();
  };
    const ScenarioTableModal = useBaseModal();
    const [CompareGraphData, SetCompareGraphData] = React.useState<any>([])
    const [TotalCompareGraphData, setTotalCompareGraphData] = React.useState<any>([])
    const [Loader, setLoader] = React.useState<any>(false)
    const [TotalLoader, serTotalLoader] = React.useState<any>(false)

  React.useEffect(() => {
    setLoader(true);
    API.get("/cost/scenario_graph/", { ...query },0)
      .then((res: any) => {
        setLoader(false);
        SetCompareGraphData(res?.data);
      })
      .catch((err: any) => {
        setLoader(false);
        console.log("Server Error");
      });
  }, [query]);

  React.useEffect(() => {
    serTotalLoader(true);

    API.get("/cost/scenario_graph/", { ...query, total_cost: true },0)
      .then((res: any) => {
        setTotalCompareGraphData(res?.data);
        serTotalLoader(false);
      })
      .catch((err: any) => {
        serTotalLoader(false);
      })

    // React.useEffect(() => {
    //     setLoader(true)
    //     API.get("/cost/scenario_graph/", { ...query }).then((res: any) => {
    //         setLoader(false)
    //         SetCompareGraphData(res?.data)
    //     }).catch((err: any) => {
    //         setLoader(false)
    //         console.log("Server Error")
    //     })
    }, [query])

  function CustomizedTick(props: any) {
    const { x, y, stroke, payload } = props;
    // console.log(payload.value, "vfkvnfknvfkvnj");
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={8} fill="#666">
          <tspan
            style={{ fill: "black", fontSize: "12" }}
            textAnchor="middle"
            x="0"
          >
            {payload.value}
          </tspan>
        </text>
      </g>
    );
  }

  const CustomizedLabel = (props: any) => {
    const { x, y, fill, value } = props;

    // console.log(value, "valuevaluevalue", props)
    return (
      <text
        x={x}
        y={y}
        dy={-4}
        fontSize="10"
        fontFamily="sans-serif"
        fill={fill}
        textAnchor="inherit"
      >
        {TotalCompareGraphData && TotalCompareGraphData[0]?.currency} {value}
      </text>
    );
  };

  // console.log(TotalCompareGraphData.length,"TotalCompareGraphData");

  const CustomizedCostTooltip: any = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: "white",
            padding: "0.2rem 0.5rem",
            borderRadius: "5px",
            border: "1px solid",
          }}
        >
          <Typography
            style={{
              fontWeight: "bold",
              fontSize: "0.87rem",
              minWidth: "8rem",
              width: "100%",
              textAlign: "center",
            }}
          >{`${label}`}</Typography>

          {payload.map((item: any, index: any) => {
            return (
              <>
                {/* @ts-ignore */}
                {
                  <Typography
                    sx={{
                      fontSize: "0.87rem",
                      width: "100%",
                      display: "flex",
                      columnGap: "0.5rem",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{ width: "6rem", textTransform: "capitalize" }}
                    >
                      {item?.name}:
                    </span>
                    <span style={{ width: "7rem", textAlign: "right" }}>
                      {item?.value?.toFixed(2)} {item.payload?.currency}
                    </span>
                  </Typography>
                }
              </>
            );
          })}
        </div>
      );
    }
    return null;
  };
//   console.log(CompareGraphData, "CompareGraphData");
  return (
    <div>
      {ScenarioTableModal.isOpen && (
        <CostingScenarioTableModal
          isOpen={ScenarioTableModal.isOpen}
          onCloseModal={ScenarioTableModal.close}
          query={query}
        />
      )}

      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Scenario {chartType ? "Independent" : "Dependent"} Costing Chart
            </Typography>
            <TableChartIcon
              titleAccess="Scenario Costing Table"
              sx={{ cursor: "pointer", marginRight: "0.5rem" }}
              onClick={() => ScenarioTableModalHandler()}
            />
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          {TotalCompareGraphData.length <= 5 ? (
            <Box sx={{ display: "flex", alignItems: "flex-end" }}>
              <Box sx={{ width: "42vw", height: "60vh", paddingTop: "2rem" }}>
                {TotalLoader ? (
                  <Box sx={{ padding: "0 1rem" }}>
                    <Skeleton></Skeleton>
                    <Skeleton></Skeleton>
                    <Skeleton></Skeleton>
                    <Skeleton></Skeleton>
                    <Skeleton></Skeleton>
                    <Skeleton></Skeleton>
                  </Box>
                ) : (
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      width={500}
                      height={300}
                      // data={[...TotalCompareGraphData,...TotalCompareGraphData,...TotalCompareGraphData,...TotalCompareGraphData,...TotalCompareGraphData]}
                      data={TotalCompareGraphData}
                      margin={{
                        top: 20,
                        right: 30,
                        left: 20,
                        bottom: 18,
                      }}
                    >
                      {/* <CartesianGrid strokeDasharray="3 3" /> */}
                      <XAxis dataKey="name" stroke="black"  />
                      <YAxis
                        //@ts-ignore
                        left={0}
                        stroke="#007FFF"
                        fill="#1a4faa"
                        fontSize="0.87rem"
                        // tick={<CustomizedYAxisTick />}

                        domain={[
                          () => 0,
                          (dataMax: number) =>
                            Math.round(dataMax + (dataMax * 5) / 100),
                        ]}
                        label={{
                          value: `Currency (${
                            TotalCompareGraphData &&
                            TotalCompareGraphData[0]?.currency
                          })`,
                          angle: -90,
                          position: "middle",
                          dx: -32,
                          fill: "black",
                          fontSize: "12px",
                        }}
                      />
                      <Bar dataKey="cost" maxBarSize={80}>
                        {TotalCompareGraphData &&
                          TotalCompareGraphData?.map((entry: any) => {
                            return <Cell fill={entry?.color} />;
                          })}
                      </Bar>

                      <Tooltip
                        cursor={false}
                        content={<CustomizedCostTooltip />}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                )}
              </Box>
              <Box sx={{ width: "47vw", height: "60vh", paddingTop: "2rem" }}>
                {Loader ? (
                  <Box sx={{ padding: "0 1rem" }}>
                    <Skeleton></Skeleton>
                    <Skeleton></Skeleton>
                    <Skeleton></Skeleton>
                    <Skeleton></Skeleton>
                    <Skeleton></Skeleton>
                    <Skeleton></Skeleton>
                  </Box>
                ) : (
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      width={500}
                      height={300}
                      data={CompareGraphData}
                      // stackOffset="expand"
                      margin={{ top: 10, right: 30, left: 10, bottom: 0 }}
                    >
                      <XAxis
                        dataKey="name"
                        interval={0}
                        style={{
                          fontSize: "10px",
                          fontWeight: "bold",
                        }}
                        stroke="#007FFF"
                        tick={<CustomizedTick />}                        
                      />
                      <YAxis
                        //@ts-ignore
                        left={0}
                        stroke="#007FFF"
                        fill="#1a4faa"
                        fontSize="0.87rem"
                        type="number"
                        // domain={[(dataMin:any) => (0 - Math.abs(dataMin)),( dataMax:any) => (dataMax * 2)]}
                        domain={[
                          () => 0,
                          (dataMax: number) =>
                            Math.round(dataMax + (dataMax * 5) / 100),
                        ]}
                        label={{
                          value: `Currency (${
                            CompareGraphData && CompareGraphData[0]?.currency
                          })`,
                          angle: -90,
                          position: "middle",
                          dx: -32,
                          fill: "black",
                          fontSize: "12px",
                        }}                        
                      />
                      <Legend verticalAlign="bottom" align="center" />
                      <Tooltip
                        cursor={false}
                        content={<CustomizedCostTooltip />}
                      />
                      <Bar
                        maxBarSize={80}
                        dataKey="RM Cost"
                        stackId="1"
                        stroke="#8884d8"
                        fill="#8884d8"
                        id="aarea"
                      >
                        {/* <LabelList
dataKey="rm_cost"
position="outside"
fill="black"
style={{ fontSize: "0.7rem" }}
/> */}
                      </Bar>
                      <Bar
                        maxBarSize={80}
                        dataKey="Process Cost"
                        stackId="1"
                        stroke="#82ca9d"
                        fill="#82ca9d"
                        id="barea"
                        cursor={"pointer"}
                        // onClick={(data: any, i: number) => alert('Arm2 clicked ' + i)}
                      >
                        {/* <LabelList
dataKey="process_cost"
position="outside"
fill="black"
style={{ fontSize: "0.7rem" }}
/> */}
                      </Bar>
                      <Bar
                        maxBarSize={80}
                        cursor={"pointer"}
                        dataKey="Overhead Cost"
                        stackId="1"
                        stroke="#ffc658"
                        fill="#ffc658"
                        id="carea"

                        // onClick={(data: any, i: number) => alert('Arm3 clicked ' + i)}
                        // onMouseOver={() => alert()}
                      >
                        {/* <LabelList
dataKey="overhead_cost"
position="outside"
fill="black"
style={{ fontSize: "0.7rem" }}
/> */}
                      </Bar>
                      <Bar
                        maxBarSize={80}
                        cursor={"pointer"}
                        dataKey="Child Cost"
                        stackId="1"
                        stroke="MediumSeaGreen"
                        fill="MediumSeaGreen"
                        id="carea"

                        // onClick={(data: any, i: number) => alert('Arm3 clicked ' + i)}
                        // onMouseOver={() => alert()}
                      >
                        {/* <LabelList
dataKey="child_cost"
position="outside"
fill="black"
style={{ fontSize: "0.7rem" }}
/> */}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                )}
              </Box>
            </Box>
          ) : (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Box sx={{ width: "90vw", height: "42vh" }}>
                {TotalLoader ? (
                  <>
                    <Skeleton></Skeleton>
                    <Skeleton></Skeleton>
                    <Skeleton></Skeleton>
                    <Skeleton></Skeleton>
                    <Skeleton></Skeleton>
                    <Skeleton></Skeleton>
                  </>
                ) : (
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      // width={500}
                      // height={300}
                      // data={[...TotalCompareGraphData,...TotalCompareGraphData,...TotalCompareGraphData,...TotalCompareGraphData,...TotalCompareGraphData]}
                      data={TotalCompareGraphData}
                      margin={{
                        top: 20,
                        right: 20,
                        left: 30,
                        bottom: TotalCompareGraphData.length > 10 ? 20 : 5,
                      }}
                    >
                      {/* <CartesianGrid strokeDasharray="3 3" /> */}
                      <XAxis
                        dataKey="name"
                        stroke="black"
                        fontSize="0.75rem"
                        angle={TotalCompareGraphData.length > 10 ? -25 : 0}
                        textAnchor={
                          TotalCompareGraphData.length > 10 ? "end" : "middle"
                        }
                      />
                      <YAxis
                        //@ts-ignore
                        left={0}
                        stroke="#007FFF"
                        fill="#1a4faa"
                        fontSize={12}
                        // tick={<CustomizedYAxisTick />}

                        domain={[
                          () => 0,
                          (dataMax: number) =>
                            Math.round(dataMax + (dataMax * 5) / 100),
                        ]}
                        label={{
                          value: `Currency (${
                            TotalCompareGraphData &&
                            TotalCompareGraphData[0]?.currency
                          })`,
                          angle: -90,
                          position: "middle",
                          dx: -32,
                          fill: "black",
                          fontSize: "12px",
                        }}
                      />
                      <Bar dataKey="cost" maxBarSize={80}>
                        {/* label={<CustomizedLabel />} */}
                        {TotalCompareGraphData &&
                          TotalCompareGraphData?.map((entry: any) => {
                            return <Cell fill={entry?.color} />;
                          })}
                      </Bar>

                      <Tooltip
                        cursor={false}
                        content={<CustomizedCostTooltip />}
                      />
                    </BarChart>
                  </ResponsiveContainer>
                )}
              </Box>
              <Box sx={{ width: "90vw", height: "42vh" }}>
                {Loader ? (
                  <>
                    <Skeleton></Skeleton>
                    <Skeleton></Skeleton>
                    <Skeleton></Skeleton>
                    <Skeleton></Skeleton>
                    <Skeleton></Skeleton>
                    <Skeleton></Skeleton>
                  </>
                ) : (
                  <ResponsiveContainer width="100%" height="100%">
                    <BarChart
                      // width={500}
                      // height={300}
                      data={CompareGraphData}
                      // stackOffset="expand"
                      margin={{
                        top: 10,
                        right: 20,
                        left: 30,
                        bottom: CompareGraphData.length > 10 ? 20 : 5,
                      }}
                    >
                      <XAxis
                        dataKey="name"
                        stroke="black"
                        fontSize="0.75rem"                        
                        angle={CompareGraphData.length > 10 ? -25 : 0}
                        textAnchor={
                          CompareGraphData.length > 10 ? "end" : "middle"
                        }
                        tick={<CustomizedTick />}
                      />
                      <YAxis
                        //@ts-ignore
                        left={0}
                        stroke="#007FFF"
                        fill="#1a4faa"
                        fontSize={12}
                        // tick={<CustomizedYAxisTick />}
                        domain={[
                          () => 0,
                          (dataMax: number) =>
                            Math.round(dataMax + (dataMax * 5) / 100),
                        ]}
                        label={{
                          value: `Currency (${
                            CompareGraphData && CompareGraphData[0]?.currency
                          })`,
                          angle: -90,
                          position: "middle",
                          dx: -32,
                          fill: "black",
                          fontSize: "12px",
                        }}
                      />
                      <Legend verticalAlign="bottom" align="center" />
                      <Tooltip
                        cursor={false}
                        content={<CustomizedCostTooltip />}
                      />
                      <Bar
                        maxBarSize={80}
                        dataKey="RM Cost"
                        stackId="1"
                        stroke="#8884d8"
                        fill="#8884d8"
                        id="aarea"
                      >
                        {/* <LabelList
                                                dataKey="rm_cost"
                                                position="outside"
                                                fill="black"
                                                style={{ fontSize: "0.7rem" }}
                                                /> */}
                      </Bar>
                      <Bar
                        maxBarSize={80}
                        dataKey="Process Cost"
                        stackId="1"
                        stroke="#82ca9d"
                        fill="#82ca9d"
                        id="barea"
                        cursor={"pointer"}
                        // onClick={(data: any, i: number) => alert('Arm2 clicked ' + i)}
                      >
                        {/* <LabelList
                                                    dataKey="process_cost"
                                                    position="outside"
                                                    fill="black"
                                                    style={{ fontSize: "0.7rem" }}
                                                    /> */}
                      </Bar>
                      <Bar
                        maxBarSize={80}
                        cursor={"pointer"}
                        dataKey="Overhead Cost"
                        stackId="1"
                        stroke="#ffc658"
                        fill="#ffc658"
                        id="carea"

                        // onClick={(data: any, i: number) => alert('Arm3 clicked ' + i)}
                        // onMouseOver={() => alert()}
                      >
                        {/* <LabelList
                                                    dataKey="overhead_cost"
                                                    position="outside"
                                                    fill="black"
                                                    style={{ fontSize: "0.7rem" }}
                                                    /> */}
                      </Bar>
                      <Bar
                        maxBarSize={80}
                        cursor={"pointer"}
                        dataKey="Child Cost"
                        stackId="1"
                        stroke="MediumSeaGreen"
                        fill="MediumSeaGreen"
                        id="carea"

                        // onClick={(data: any, i: number) => alert('Arm3 clicked ' + i)}
                        // onMouseOver={() => alert()}
                      >
                        {/* <LabelList
                                                        dataKey="child_cost"
                                                        position="outside"
                                                        fill="black"
                                                        style={{ fontSize: "0.7rem" }}
                                                        /> */}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                )}
              </Box>
            </Box>
          )}
        </Box>
      </Modal>
    </div>
  );
}