import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import { Typography } from "@mui/material";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import { API } from "../../api-services";
export interface IScenarioCloseAlertModalProps {
  isOpen: any;
  onCloseModal: any;
  parentCloseModal: any;
  scenarioIdd: any;
  setCount:any
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

export default function ScenarioCloseAlertModal(
  props: IScenarioCloseAlertModalProps
) {
  const { isOpen, onCloseModal,parentCloseModal,scenarioIdd,setCount } = props;

  return (
    <div>
      <Modal
        open={isOpen}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ padding: "1rem" }}>
            <Box
              sx={{
                color: "#f27474",
                borderRadius: "50%",
                height: "6rem",
                width: "6rem",
                border: "2px solid #f27474",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                margin: "0 auto 1rem",
              }}
            >
              <PriorityHighIcon sx={{ color: "#f27474", fontSize: "4rem" }} />
            </Box>
            <Typography
              sx={{ fontWeight: "500", fontSize: "1rem", textAlign: "center" }}
            >
              Closing this will cancel scenario creation and all unsaved changes
              will be lost. Are you sure you want to close?
            </Typography>

            <Box
              sx={{
                width: "100%",
                marginTop: "1rem",
                display: "flex",
                alignItems: "center",
                columnGap: "1rem",
                justifyContent: "center",
              }}
            >
              <Button
                size="small"
                variant="contained"
                onClick={onCloseModal}
                sx={{}}
              >
                cancel
              </Button>
              <Button size="small" variant="contained" sx={{}} onClick={()=>{
                 API.delete(
                  `/cost/scenario/${scenarioIdd}/`,
                  {},
                  0
                )
                  .then((res: any) => {
                    setCount((prev:any)=>prev+1)
                    parentCloseModal();
                    onCloseModal();
                   
                  })
                  .catch((err: any) => {});
              }}>
                ok
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
