import * as React from 'react';
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import { useHistory } from "react-router-dom";
import { Divider, InputLabel, MenuItem, Select, SelectChangeEvent, Typography } from "@mui/material";
import { API } from "../../api-services";
import { LoadingButton } from '@mui/lab';
import { useRouteParams } from '../../ui-reusable-component/useRouteParams';
import CancelIcon from '@mui/icons-material/Cancel';
import Swal from 'sweetalert2';
import successIcon from "../../Assets/images/success.png";


const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30vw",
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 0,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface IWatermarkModelProps {
  isOpen: any;
  onCloseModal: any;
}

function ShareIdeaParameterModal(props: IWatermarkModelProps) {
  const { isOpen, onCloseModal } = props;
  const [value, setValue] = React.useState("Delivery");
  const { projectId } = useRouteParams<any>();
  const [projectList, setProjectList] = React.useState<any>([])
  const [selectedProject, setSelectedProject] = React.useState<any>('')


  const getProjectList = () => {
    API.get("/xcpep/project/", {
      module: 6
    }, 0).then((res: any) => {
      setProjectList(res.data)
      // let resolve = res.data?.map((item: any) => {
      //   user.push({
      //     label: `${item?.first_name} ${item?.last_name}`,
      //     value: item?.id,
      //   });
      // });
    }).catch((err: any) => {

    })
  }
  React.useEffect(() => {
    getProjectList()
  }, [])
  const handleSelectChangeProject = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    setSelectedProject(value);
  };


  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: '-2rem'
              }}
            >
              Share Parameters
            </Typography>
            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />

          <Box sx={{ padding: '0 1rem' }}>
            <FormControl fullWidth variant="standard">
              <InputLabel sx={{ color: 'primary.main', }} shrink id="demo-simple-select-label">
                Select Project
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedProject && selectedProject}
                // label="User"
                autoFocus
                onChange={handleSelectChangeProject}
                size="small"
                sx={{
                  "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                    fontSize: '1rem',
                    textAlign: 'left',
                  },
                  "&:before": {
                    borderBottomColor: "primary.main",
                  },
                  ".MuiSvgIcon-root.MuiSelect-icon": {
                    color: "primary.main",
                  },
                }}
                MenuProps={MenuProps}
              >
                {projectList &&
                  projectList?.map((item: any, index: number) => {
                    return (
                      <MenuItem sx={{ fontSize: '1rem' }} key={index} value={item?.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ width: '100%', textAlign: 'right', paddingRight: '1rem', marginBottom: '1rem' }}>
            <LoadingButton size='small' variant='contained' sx={{
              marginTop: '1rem',
              '.MuiCircularProgress-root': {
                color: 'primary.main',
              },
              "&:hover": {
                transform: 'Scale(1.05)',
                transition: 'transform 0.5s ease',
              }
            }} onClick={() => {
              API.post("/config/share_feature/", {
                module: 14,
                target_project: selectedProject,
                type: 5,
                source: projectId
              }, {}, 0).then((res: any) => {
                Swal.fire({
                  html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
            <br />
            <p style="color:#007fff;">Project Shared successfully
            </p>   
             </div>`,
                });
                onCloseModal()
              }).catch((err: any) => {

              })
            }}>Share</LoadingButton>
          </Box>
        </Box>
      </Modal>

    </div>
  );
}
export default ShareIdeaParameterModal;
