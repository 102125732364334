import { Box } from "@mui/material";
import { Checkbox } from "@mui/material";
import * as React from "react";
import { API } from "../../../../api-services";
import Swal from "sweetalert2";
import { useRouteParams } from "../../../../ui-reusable-component/useRouteParams";

export interface IParametershowCheckBoxProps {
  checkedValue: any;
  item?: any;
  type?: any;
}

export function ParametershowCheckBox(props: IParametershowCheckBoxProps) {
  const { checkedValue, item, type } = props;
  const { ideaId } = useRouteParams<any>();
  const [checked, setChecked] = React.useState<any>(false);
  const getKey = () => {
    if (type === "text") {
      return { text_parameter: item?.key };
    } else {
      return { parameter_clicked: item?.key };
    }
  };
  React.useEffect(() => {
    if (checkedValue) {
      setChecked(checkedValue);
    }
  }, [checkedValue]);
  return (
    <Box>
      <Checkbox
        sx={{padding:'3px',color:'primary.main',}}
        checked={checked}
        onChange={(e) => {
          API.put(
            `/idea/pool/${ideaId}/`,
            {
              //   text_parameter: item?.key,
              ...getKey(),
              checked: e.target.checked,
            },
            {},
            0
          )
            .then((res: any) => {
              setChecked((prev: any) => !prev);
            })
            .catch((err: any) => {
              if (err.response.data) {
                const { data } = err.response;
                Swal.fire({
                  title: "Error!",
                  text: data[0],
                  icon: "error",
                  confirmButtonText: "OK",
                  customClass: {
                    container: "swal2Container",
                  },
                });
              }
            });
        }}
      />
    </Box>
  );
}
