import { useState } from "react";

// ----------------------------------------------------------------------------------

const useGraphBaseModal = () => {
    const [isOpen, setIsOpen] = useState( false );
    const [id, setId] = useState<any>();
    const [analysisType,setAnalysisType] = useState<any>();

    const handleOpen = ( grpId: any,analysisGetType:any ) => {
        setId( grpId );
        setIsOpen( true );
        setAnalysisType(analysisGetType)
    };

    const handleClose = () => {
        setId( undefined );
        setIsOpen( false );
        setAnalysisType(undefined);
    };

    return Object.freeze( {
        open: handleOpen,
        close: handleClose,
        isOpen,
        id,
        analysisType
    } );
};

// ----------------------------------------------------------------------------------

export { useGraphBaseModal };
