import { Divider, Modal } from "@mui/material";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CancelIcon from "@mui/icons-material/Cancel";
import { API } from "../../api-services";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import {
  DataGrid,
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridToolbarContainer,
  GridToolbarExportContainer,
} from "@mui/x-data-grid";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60rem",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

const csvOptions: GridCsvExportOptions = {
  fileName: "Table In CSV",
  delimiter: ",",
  utf8WithBom: true,
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer style={{ height: "1.5rem", marginBottom: "0.5rem" }}>
    <span style={{ position: "absolute", right: "0rem" }}>
      {/* <GridToolbarColumnsButton /> */}
      {/* <GridToolbarDensitySelector /> */}
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);
interface StatisticTableProps {
  onCloseModal: any;
  isOpen: any;
}

const StatisticTable = (props: StatisticTableProps) => {
  const { onCloseModal, isOpen } = props;
  const {
    projectId,
    projectname,
    abbr,
    topvoult,
    subSystem,
    groupId,
    collapestatus,
    pageNumber,
  } = useRouteParams<any>();
  const [TableData, setTableData] = useState<any>([]);
  const [loader, setloader] = useState<any>(false);

  useEffect(() => {
    setloader(true);
    API.get("/circuit_diagram/arch_system/", {
      project: projectId,

      statistics_table: true,
    })
      .then((res: any) => {
        setloader(false);
        setTableData(res?.data);
      })
      .catch((err: any) => {
        setloader(false);
        console.log("error");
      });
  }, [isOpen]);
  const Columns: any = [
    {
      field: "id",
      hide: true,
    },
    {
      field: "name",
      headerName: "Name",
      width: 400,
    },
    {
      field: "image_count",
      headerName: "Total Diagrams",
      width: 150,
    },
    {
      field: "validated_count",
      headerName: "Validated Diagrams",
      width: 150,
    },
  ];

  console.log(TableData, "TableDataTableData");

  return (
    <Modal
      open={isOpen}
      onClose={onCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style}>
        <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
          <Typography
            style={{
              fontSize: "1rem",
              fontWeight: "600",
              width: "100%",
              textAlign: "center",
              marginRight: "-2rem",
            }}>
           Architecture Statistics 
          </Typography>
          <CancelIcon
            titleAccess="Close"
            sx={{ cursor: "pointer", margin: "0.5rem" }}
            onClick={onCloseModal}
          />
        </Box>
        <Divider
          sx={{
            borderColor: "primary.light",
            margin: "0 1rem",
            marginBottom: "1rem",
          }}
        />
        <Box>
          <Box
            sx={{ height: "65vh", width: "100%" }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3">
            {
              <DataGrid
                headerHeight={48}
                rowHeight={36}
                loading={loader}
                rows={TableData && TableData}
                columns={Columns}
                density="compact"
                // autoHeight={true}
                components={{
                  Toolbar: () => {
                    return CustomToolbar();
                  },
                }}
                hideFooter={true}
                hideFooterPagination={true}
                sx={{
                  "&.MuiDataGrid-root .MuiDataGrid-cell": {
                    borderBottomColor: "primary.light",
                  },
                  "& ::-webkit-scrollbar": {
                    width: "0.3rem",
                    height: "0.3rem",
                  },
                  "& ::-webkit-scrollbar-thumb": {
                    // background: $thumb-color;
                    backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                    borderRadius: "10px",
                  },
                  "& ::- webkit-scrollbar-thumb: hover ": {
                    // width: "4px",
                    backgroundColor: "#045DE9 !important",
                  },
                  "::-webkit-scrollbar-track": {
                    backgroundColor: "#e1e1f3 !important",
                  },
                }}
              />
            }
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

export default StatisticTable;
