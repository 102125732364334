import React, { useEffect, useState } from 'react'
import AddIcon from '@mui/icons-material/Add';
import { Box, Table, TableBody, TableCell, TableHead } from '@mui/material';
import { API } from '../../api-services';
import ProductCategoryRow from './ProductCategoryRow';
import { useBaseModal } from '../SearchPage/useBaseModal';
import InfoModal from './InfoModal';
import SkeletonLoader from '../SkeletonLoader/SkeletonLoader';
interface ProductCategoryProps {

}

const ProductCategory = (props: ProductCategoryProps) => {

    const [CategoriesData, setCategoriesData] = useState<any>([]);
    const [savetype, setsaveType] = useState<any>();
    const [Counter, setCounter] = useState<any>(0);
    const [DataGridTable, setDataGridTable] = useState<any>([]);
    const [Loader, setLoader] = useState<any>(false);
    const [status,setstatus]=useState<any>(false)


    const infoPopup = useBaseModal();
    useEffect(() => {
if(Counter==0){
   
    setLoader(true);
    API.get("/api/db/vehicle_type/").then((res: any) => {
        setLoader(false);
        setCategoriesData(res?.data)
        setstatus(false)
    }).catch((err: any) => {
        console.log("Server EEror")
        setLoader(false)
    })
}

if(Counter>0){
    
    API.get("/api/db/vehicle_type/").then((res: any) => {
        
        setCategoriesData(res?.data)

    }).catch((err: any) => {
        console.log("Server EEror")
      
    })
}
    }, [Counter])


    const AddRows = () => {
        setstatus(true)
        setsaveType("create")
        var data = { id: 0, abbreviation: "", created_by: "", created_on: "", name: "", product_count: "", project_list: [], updated_by: null, updated_on: "", description: "", mapped_status: false,status:true }

        var statusdata=CategoriesData && CategoriesData.map((item:any,index:any)=>({...item,status:false}))

        
        setCategoriesData([data, ...statusdata])
    }
  
    const GetRowIds = (id: any) => {

        var filterMapData = CategoriesData && CategoriesData?.filter((item: any, index: any) => { return item?.id == id })[0]
        setDataGridTable(filterMapData && filterMapData?.project_list)

        infoPopup.open()

    }


    return (
        <div>
            {infoPopup.isOpen &&
                <InfoModal

                    onCloseModal={infoPopup.close}
                    isOpen={infoPopup.isOpen}
                    Item={DataGridTable && DataGridTable}
                />}
            {!Loader ? <Box>
                <Box sx={{ textAlign: 'right', padding: '0 1rem' }}>
                    <AddIcon titleAccess='Add Category' onClick={() => AddRows()} sx={{ cursor: 'pointer' }} />
                </Box>
                <Box sx={{ padding: '0 1rem', height: '83vh' }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3">
                    <Table>
                        <TableHead sx={{ backgroundColor: 'primary.light', position: 'sticky', top: '0', zIndex: '1' }}>
                            <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Name</TableCell>
                            <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Abbreviation</TableCell>
                            <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Description</TableCell>
                            <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Actions</TableCell>
                        </TableHead>
                        <TableBody>
                            {CategoriesData && CategoriesData?.map((item: any, index: any) => {
                                return (
                                    <ProductCategoryRow GetRowIds={GetRowIds} rowItem={item} savetype={savetype} setsaveType={setsaveType} setCounter={setCounter}  />
                                )
                            })}
                        </TableBody>

                    </Table>
                </Box>
            </Box>
                :
                <Box sx={{ padding: '0 1rem' }}>
                    <SkeletonLoader />
                    <SkeletonLoader />
                </Box>}
        </div>
    )
}
export default ProductCategory;