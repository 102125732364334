import { useState } from "react";

// ----------------------------------------------------------------------------------

const useGanttChartModal = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [modalData, setModalData] = useState<any>();

    const handleOpen = (data: any) => {
        setIsOpen(true);
        setModalData(data);
    }
    const handleClose = () => {
        setIsOpen(false);
        setModalData({});
    }

    return Object.freeze({
        open: handleOpen,
        close: handleClose,
        modalData:modalData,
        isOpen,
    });
};


// ----------------------------------------------------------------------------------

export { useGanttChartModal };
