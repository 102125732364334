import * as React from "react";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import * as yup from "yup";
import { useFormik } from "formik";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { API } from "../../api-services";
import LoadingButton from "@mui/lab/LoadingButton";
import styles from "./AddGroupModal.module.scss";
import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import { AUTH } from "../../Redux/Services/auth.service";
import CancelIcon from '@mui/icons-material/Cancel';


export interface IAddGroupModalProps {
  isOpen: boolean;
  onClose: () => void;
  groupData: any;
  setCreateMeetingCount?: any;
  pointDetails?:any;
}
const validationSchema = yup.object({
  responsible: yup.number().required("Please Select attendees"),
  date: yup.string().nullable().required("Project can't be blank"),
  meeting: yup.mixed(),
  points: yup.string().required("Please enter Points"),
  remark: yup.string(),
  status: yup.string().required("Please enter status"),
});

const styled = {
  boxSize: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",

    width: "39rem",
    maxWidth: "80%",
    height: "fit-content",
    minHeight: "20rem",
    borderRadius: "0.5rem",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    p: 0,
    outline: 0,
  },
  NoAccessBoxSize: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center !important",
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "39rem",
    height: "fit-content",
    minHeight: "20rem",
    borderRadius: "0.5rem",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    p: 0,
    outline: 0,
  },
  HeaderLine: {
    textAlign: "center",
    position: "relative",
  },
  SelectedHeaderLine: {
    color: "#1976d2",
  },
  nextButton: {
    position: "absolute",
    zIndex: "9999",
    left: "35rem",
    top: "1.8rem",
  },

  summery: {
    backgroundColor: "rgb(240, 247, 255)",
    borderColor: " rgb(0, 127, 255)",
    borderRadius: "10px",
    minHeight: "45px",
  },
  searchButton: {
    position: "absolute",

    // right: "37rem",
    top: "0.4rem",

    left: "-2rem",
    cursor: "default",
    color: "#1976d2",
    padding: " 0rem 0rem 0rem 2rem",
  },
  closedIcon: {
    position: "absolute",
    zIndex: "999",
    color: "#1976d2",
    right: "0.2rem",
    top: "0.5rem",
    cursor: "pointer",
  },
  editIcon: {
    marginTop: "0.3rem",
    color: "#1976d2",
    cursor: "pointer",
  },
};
// @ts-ignore

const useStyles = makeStyles((theme) => ({
  /** Changed modalStyle */
  ModalRoot: {
    backgroundColor: "rgba(111, 126, 140, 0.2) !important",
    backdropFilter: "blur(1px) !important",
  },
}));

export function AddMeetingPointsModal(props: IAddGroupModalProps) {
  const { isOpen, onClose, groupData, setCreateMeetingCount,pointDetails } = props;
  const classes = useStyles();
  const { projectId, groupId, pointsId } = useRouteParams<any>();
  const [allUserList, setAllUserList] = React.useState<any>([]);

  const formik = useFormik({
    initialValues: {
      responsible: AUTH.user,
      date: moment().format("YYYY-MM-DD").toString(),
      meeting: parseInt(pointsId),
      points: "",
      remark: "",
      status: "open",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let obj={
        responsible:values.responsible,
        date:values.date,
        points:values.points,
        remark:values.remark,
        status:values.status,
      }
      if(pointDetails ==="Add"){
      API.post("/drive/meeting_detail/", values, {}, 0).then((res: any) => {
        setCreateMeetingCount((prev: any) => prev + 1)
        onClose()
      }).catch((err: any) => {
        console.log(err)
      })
    }else{
      API.patch(`/drive/meeting_detail/${pointDetails?.pointsId}/`, obj, {}, 0).then((res: any) => {
        setCreateMeetingCount((prev: any) => prev + 1)
        onClose()
      }).catch((err: any) => {
        console.log(err)
      })
    }
    },
  });
  React.useEffect(()=>{
    if(pointDetails !=="Add"){
      formik.setFieldValue("points",pointDetails.points)
      formik.setFieldValue("remark",pointDetails.remark)
      formik.setFieldValue("status",pointDetails.status)
      formik.setFieldValue("responsible",pointDetails.responsible)
      formik.setFieldValue("date",pointDetails.date)
    }
  },[pointDetails])
  const getAlluserList = () => {
    let temp: any = {};
    temp = groupData?.filter((item: any) => item.id === parseInt(groupId))[0];
    API.get(
      "/auth/user_list_drive/",
      { module: 42, project: projectId },
      0
    ).then((res: any) => {
      setAllUserList(
        res.data.users.filter((item: any) => temp?.attendees.includes(item.id))
      );
    });
  };

  React.useEffect(() => {
    getAlluserList();
  }, [groupData]);

  return (
    <div>
      <Modal // autoFocusdal
        open={isOpen}
        className={classes.ModalRoot}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styled.boxSize}>
          <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: '-2rem'
              }}
            >
              {pointDetails==="Add"?"Add Meeting Points":"Edit meeting points"}
            </Typography>
            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onClose} />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box
            sx={{ width: "100%", overflowWrap: "break-word", padding: "1rem" }}
          >
            <form onSubmit={formik.handleSubmit}>


              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <FormControl
                  variant="standard"
                  sx={{ margin:'0 0.5rem', minWidth: 120, width: '30%' }}
                  size="medium"
                >
                  <InputLabel>Responsible</InputLabel>
                  <Select
                    id="responsible"
                    name="responsible"
                    // className={classes.select}
                    labelId="demo-select-small"
                    // id="demo-select-small"
                    sx={{
                      "& .MuiFormLabel-root": {
                        color: "#007fff",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      color: "#007fff",
                      "& .MuiSelect-select-MuiInputBase-input-MuiInput-input.MuiSelect-select:before":
                        { borderBottom: "1px solid blue !important" },
                      "& .MuiInputBase-root-MuiInput-root-MuiSelect-root.Mui-disabled:before":
                        { borderBottom: "1px solid blue !important" },
                      "& .MuiInput-root-MuiSelect-root:after": {
                        borderBottomColor: "primary.light",
                      },
                      ".MuiSelect-select.MuiInputBase-input.MuiInput-input": {
                        fontSize: "1rem",
                      },
                      ".MuiSvgIcon-root ": {
                        fill: "#007fff !important",
                      },
                      borderBottom:'1px solid',
                      borderBottomColor:'primary.main',                
                      
                      "&:before": {
                        borderBottomColor: "white",
                      },
                    }}
                    required
                    MenuProps={{
                      style: {
                        maxHeight: 400,
                        color: "007fff",
                      },
                    }}
                    onChange={formik.handleChange}
                    value={formik.values.responsible}
                  >
                    {/* <option value={""} style={{ display: "none" }}></option> */}
                    {allUserList &&
                      [...allUserList,...[{id:AUTH.user,username:AUTH.username}]].map((item: any, index: any) => {
                        return (
                          <MenuItem
                            sx={{ color: "primary.main", fontSize: "1rem" }}
                            key={index}
                            value={item.id}
                          >
                            {item.username}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <div className={styles.errormsg}>
                    {formik.touched.responsible &&
                      Boolean(formik.errors.responsible) && (
                        //@ts-ignore
                        <p>{formik.errors.responsible}</p>
                      )}
                  </div>
                </FormControl>
                <FormControl
                  variant="standard"
                  sx={{ margin: '0 0.5rem', marginBottom: '-8px', minWidth: 120, width: '30%' }}
                  size="medium"
                >
                  <input value={formik.values.date} onChange={formik.handleChange} type="date" name="date" required
                    style={{
                      color: '#007fff',
                      borderTop: 'none',
                      borderLeft: 'none',
                      borderRight: 'none',
                      borderBottom: '1px solid #007fff',
                      paddingBottom: '5px',
                      fontFamily:'roboto',
                    }}
                  />
                </FormControl>
                <FormControl
                  variant="standard"
                  sx={{ margin:'0 0.5rem', minWidth: 120, width: '30%' }}
                  size="medium"
                >
                  <InputLabel>Status</InputLabel>
                  <Select
                    id="status"
                    name="status"
                    // className={classes.select}
                    labelId="demo-select-small"
                    // id="demo-select-small"
                    sx={{
                      "& .MuiFormLabel-root": {
                        color: "#007fff",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      color: "#007fff",
                      "& .MuiSelect-select-MuiInputBase-input-MuiInput-input.MuiSelect-select:before":
                        { borderBottom: "1px solid blue !important" },
                      "& .MuiInputBase-root-MuiInput-root-MuiSelect-root.Mui-disabled:before":
                        { borderBottom: "1px solid blue !important" },
                      "& .MuiInput-root-MuiSelect-root:after": {
                        borderBottomColor: "primary.light",
                      },
                      ".MuiSelect-select.MuiInputBase-input.MuiInput-input": {
                        fontSize: "1rem",
                      },
                      ".MuiSvgIcon-root ": {
                        fill: "#007fff !important",
                      },
                      borderBottom:'1px solid',
                      borderBottomColor:'primary.main',                
                      
                      "&:before": {
                        borderBottomColor: "white",
                      },
                    }}
                    required
                    MenuProps={{
                      style: {
                        maxHeight: 400,
                        color: "007fff",
                      },
                    }}
                    onChange={formik.handleChange}
                    value={formik.values.status}
                  >
                    {/* <option value={""} style={{ display: "none" }}></option> */}
                    {
                      ["open", "closed"].map((item: any, index: any) => {
                        return (
                          <MenuItem
                            sx={{ color: "primary.main", fontSize: "1rem" }}
                            key={index}
                            value={item}
                          >
                            {item}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <div className={styles.errormsg}>
                    {formik.touched.status &&
                      Boolean(formik.errors.status) && (
                        //@ts-ignore
                        <p>{formik.errors.status}</p>
                      )}
                  </div>
                </FormControl>
              </Box>
              <FormControl sx={{ margin: '0 0.5rem', minWidth: 120, width: '97%' }}>
              <label>Discussion Points</label>
                <TextField
                  variant="standard"
                  InputProps={{
                    sx: { color: "primary.main" },
                  }}
                  // InputLabelProps={{
                  //   shrink: true,
                  // }}
                  sx={{
                    width: "100%",
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "primary.main",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#ffffff",
                    },
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "primary.main",
                    },
                  }}
                  id="points"
                  name="points"
                  // label="Discussion Points"                              
                  onChange={formik.handleChange}
                  value={formik.values.points}
                  multiline
                  rows={4}
                  error={formik.touched.points && Boolean(formik.errors.points)}
                  //@ts-ignore
                  helperText={formik.touched.points && formik.errors.points}
                />
              </FormControl>
              <FormControl sx={{ margin: '0 0.5rem',minWidth: 120, width: '97%',marginTop:'1rem' }}>
                <label>Remarks</label>
                <TextField
                  variant="standard"
                  InputProps={{
                    sx: { color: "primary.main" },
                  }}
                  // InputLabelProps={{
                  //   shrink: true,
                  //   color:'primary'
                  // }}
                  sx={{
                    width: "100%",
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "#007fff",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#ffffff",
                    },
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "primary.main",
                    },
                  }}
                  id="remark"
                  name="remark"
                  // label="Remarks"
                  // defaultValue="Remarks"
                  onChange={formik.handleChange}
                  value={formik.values.remark}
                  multiline
                  rows={4}
                  error={formik.touched.remark && Boolean(formik.errors.remark)}
                  //@ts-ignore
                  helperText={formik.touched.remark && formik.errors.remark}
                />
              </FormControl>
              <Box sx={{textAlign:'right',paddingRight:'0.5rem',marginTop:'1rem'}}>
                <LoadingButton
                  //   className={styles.feedbackSubmit}
                  variant="contained"
                  sx={{backgroundColor: pointDetails==="Add"? 'primary.main':'#6aa84f',
                  "&:hover":{
                    transform:'scale(1.1)',
                    transition:'transform 0.5s ease'
                  }
                }}
                  type="submit"
                  size="small"
                //   loading={isLoading}
                //   disabled={userId !== "" ? false : true}
                >
                  {pointDetails==="Add"?"Create Meeting points":"Save Changes"}
                </LoadingButton>
               {pointDetails !== "Add" && <LoadingButton
                  //   className={styles.feedbackSubmit}
                  variant="contained"
                  size="small"
                  sx={{marginLeft:'2rem',backgroundColor:'#d65e5e',
                  "&:hover":{
                    transform:'scale(1.1)',
                    transition:'transform 0.5s ease'
                  }}}

                  onClick={()=>{
                    API.delete(`/drive/meeting_detail/${pointDetails?.pointsId}/`,{},0).then((res:any)=>{
                      setCreateMeetingCount((prev:any)=>prev+1);
                      onClose();
                    })
                  }}
              
                >
                  Delete point
                </LoadingButton>}
              </Box>
            </form>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
