import { AsyncThunk } from "@reduxjs/toolkit";
import {
  CALCULATOR_CATEGORY,
  CALCULATOR_TYPE,
  calculatorCategoryOptions,
} from "../../../../Configuration/Costing/calculator.constant";
import { updateRawMaterialCardCost } from "../../../../Redux/Actions/rawMaterialCard.actions";
import { rawMaterialCardSelectors } from "../../../../Redux/Reducers/rawMaterialCard.reducer";
import styles from "./CalculatorCard.module.scss";
import {
  RouteComponentProps,
  useHistory,
  useParams,
  useRouteMatch,
} from "react-router-dom";
import { mapValues } from "lodash";
import { useAppDispatch } from "../../../../../AppRouter";
import { Fragment, useContext, useEffect, useState } from "react";
import { ID } from "../../../../utlis/commonInterfaces";
import { useSelector } from "react-redux";
import { RootState } from "../../../../Redux/Store/storeConfigurations";
import { userInputSelectors } from "../../../../Redux/Reducers/userInput.reducer";
import { useQueryDispatch } from "../../../../CustomHook/useQueryDispatch";
import swal from "sweetalert";
import {
  getUserInputs,
  resetUserInputs,
} from "../../../../Redux/Actions/userInput.actions";
import successIcon from "../../../../Assets/images/success.png";
import {
  Box,
  Button,
  Chip,
  Skeleton,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
  TableBody,
  IconButton,
} from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { ICalculator } from "../../../../Redux/Reducers/calculator.reducer";
import { useRouteParams } from "../../../../ui-reusable-component/useRouteParams";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import { LoadingButton } from "@mui/lab";
import Calculator from "./Calculator/Calculator";
import AddIcon from "@mui/icons-material/Add";
import { API } from "../../../../api-services";
import { updateProcessCardCost } from "../../../../Redux/Actions/processCard.action";
import { updateOverheadCardCost } from "../../../../Redux/Actions/overheadCard.action";
import { processCardSelectors } from "../../../../Redux/Reducers/processCard.reducer";
import { overheadCardSelectors } from "../../../../Redux/Reducers/overheadCard.reducer";
import { AddCostModal } from "./Calculator/CostModal/AddCostModal";
import { useBaseModal } from "../../../SearchPage/useBaseModal";
import React from "react";
import Swal from "sweetalert2";
import { userPermission } from "../CostigngCalculatorSection";
import { AddCostModelProcess } from "./Calculator/CostModal/AddCostModelProcess";
import { useBaseParamsModal } from "../../../../CustomHook/useBaseParamsModal";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DoneOutlineIcon from "@mui/icons-material/DoneOutline";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import CopyCostModal from "./CopyCostModal";
import { toExponential } from "../../../../utlis/toExponential";
import CommentIcon from "@mui/icons-material/Comment";
import CommentModal from "./CommentModal";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MenuIcon from "@mui/icons-material/Menu";
import { ADMIN } from "../../../../Redux/Services/admin.service";
import CostModelMenuItemActive from "./Calculator/CostModelMenuItemActive";
import CostModelMenuItem from "./Calculator/CostModelMenuItem";

// interfaces and types
// import { ID } from "app/commonInterfaces";

// lazy

// ----------------------------------------------------------------------------------
// params

interface Params {
  projectId: string;
  topVaultId: string;
  vaultId: string;
  subsystemId: string;
  cardCostId: string;
  column: string;
  commodityMappingId: string;
  processId: string;
  calculatorCategory: string;
}

// ----------------------------------------------------------------------------------
// constants

const selectorsMap = {
  [CALCULATOR_TYPE.RAW_MATERIAL]: rawMaterialCardSelectors,
  [CALCULATOR_TYPE.PROCESS]: processCardSelectors,
  [CALCULATOR_TYPE.OVERHEAD]: overheadCardSelectors,
};

const actionsMap: Record<number, AsyncThunk<any, any, any>> = {
  [CALCULATOR_TYPE.RAW_MATERIAL]: updateRawMaterialCardCost,
  [CALCULATOR_TYPE.PROCESS]: updateProcessCardCost,
  [CALCULATOR_TYPE.OVERHEAD]: updateOverheadCardCost,
};

const resultKeyMap: Record<number, any> = {
  [CALCULATOR_CATEGORY.RAW_MATERIAL]: "material_result",
  [CALCULATOR_CATEGORY.LABOUR]: "labour_result",
  [CALCULATOR_CATEGORY.MACHINE]: "machine_result",
  [CALCULATOR_CATEGORY.PURCHASE_ITEM]: "bop_result",
  [CALCULATOR_CATEGORY.SET_UP]: "setup_result",
  [CALCULATOR_CATEGORY.TOOL]: "tool_result",
};

const getType = (type: any) => {
  switch (type) {
    case 1:
      return 1;
    case 2:
      return 1;
    case 3:
      return 2;
    case 4:
      return 2;
    case 5:
      return 2;
    case 6:
      return 2;
    case 0:
      return 3;

    default:
      return 1;
  }
};

// ----------------------------------------------------------------------------------

interface CalculatorCardProps {
  calculatorType?: number;
  passingObj?: any;
}

const CalculatorCard: React.FC<CalculatorCardProps> = (props) => {
  const { calculatorType, passingObj } = props;
  // const params = useParams<Params>();
  // const { cardCostId, calculatorCategory } = mapValues( params, Number );
  const {
    vault,
    topVault,
    projectId,
    projectname,
    productId,
    abbrebation,
    cardtype,
    tableId,
    costEstimationId,
    tableCounter,
    condition,
    calctype,
    calcultorId,
    scenarioId,
    newUI
  } = useRouteParams<any>();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { url } = useRouteMatch();
  const addcostmodaldata = useBaseParamsModal();
  const addCostModalProcess = useBaseParamsModal();
  const copyCostModal = useBaseModal();
  const commentModal = useBaseModal();
  const [CostDetailsData, setCostDetailsData] = useState<any>();
  const [Loader, setLoader] = useState<any>(false);
  const [isWeightInput, setIsWeightInput] = useState<any>(false);
  const [costCalculatorId, setCostCalculatorId] = useState<any>(0);
  // const readonly = useCostingReadonly();

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  // ----------------------------------------------------------------------------------
  // state

  /** active calculator id */
  const [active, setActive] = useState<ID>(0);
  const [result, setResult] = useState("");
  const [copyCMInfo, setCopyCMInfo] = useState<any>();
  const [editedItems, setEditedItems] = useState<any>();
  const [oldEditedItems, setOldEditedItems] = useState<any>();
  const [orderedCalculators, setOrderedCalculators] = useState<any>([]);
  const textRef = React.useRef<HTMLDivElement>(null);
  // ----------------------------------------------------------------------------------
  // selectors

  const calculators = useSelector((state: RootState) => {
    return userInputSelectors.selectCalculatorByCategory(
      state,
      calctype ? calctype : 0
    );
  });
  const alldatacalculators = useSelector(userInputSelectors.selectAll);

  const cardCost = useSelector((state: RootState) => {
    const selector = selectorsMap[getType(calctype ? calctype : 0)];
    if (!selector) {
      console.error("Unhandled 'calculatorType' provided");
      return;
    }

    return selector.selectById(state, tableId);
  });

  const useraction = useContext<any>(userPermission);
  const [statustracker, setStatustracker] = useState<any>(false);

  const getResultKey = () =>
    (resultKeyMap[calctype] || "result") as keyof typeof cardCost;

  // ----------------------------------------------------------------------------------
  // handlers

  const handleChange = (e: any) => {
    setStatustracker(true);
   
    const newValue = e.target.value;
        // Allow only letters, special characters, and parentheses
        if (/^[^0-9]*$/.test(newValue)) {
          setResult(e?.target?.value);
        }
  };

  const CopyAliasHandler = (item: any) => {
    var data = `${result}${item}`;
    setStatustracker(true);
    setResult(data);
  };

  // const handleCategoryChange = ( e: React.MouseEvent, ed: MenuItemProps ) => {
  //     const link = url.split( "/calc/" ).filter( Boolean )?.[0];
  //     if ( !link ) return;
  //     history.push( `${link}/calc/${ed.category}` );
  // };

  const handleActiveCalculatorChange = (item: any) => {
    setIsWeightInput(
      orderedCalculators.filter((element: any, index: any) => {
        // @ts-ignore
        return element?.calculator?.id == item?.id;
        //@ts-ignore
      })[0]?.is_weight_input
    );
    setCostCalculatorId(
      orderedCalculators.filter((element: any, index: any) => {
        // @ts-ignore
        return element?.calculator?.id == item?.id;
        // @ts-ignore
      })[0]?.id
    );
    setActive(item?.id);
    history.push(
      `/createcostingpart/${projectId}/${projectname}/${productId}/${topVault}/${vault}/${abbrebation}/${cardtype}/${tableId}/${costEstimationId}/${tableCounter}/${condition}/${calctype}/${item?.id}/${scenarioId}/0/${newUI}`
    );
  };

  const handleSave = () => {
    const update = {
      id: tableId,
      updates: { [getResultKey()]: result },
    };

    const action = actionsMap[getType(calctype ? calctype : 0)];

    if (!action) return console.error("Unhandled 'calculatorType' provided");

    // else, if process card calculator
    const resultData: any = dispatch(action(update));
    var datavalue = Math.random();
    if (resultData) {
      setTimeout(() => {
        history.push(
          `/createcostingpart/${projectId}/${projectname}/${productId}/${topVault}/${vault}/${abbrebation}/${cardtype}/${tableId}/${costEstimationId}/${tableCounter}/${datavalue}/${calctype}/${calcultorId}/${scenarioId}/0/${newUI}`
        );
      }, 500);
    }
  };
  // ----------------------------------------------------------------------------------
  // side effects

  // fetch costCalculations (where user inputs are saved)

  const userInputsFetched = useQueryDispatch({
    query: {
      action: getUserInputs,
      arg: { category: calctype, cardCostId: tableId },
    },
    cleanup: { action: resetUserInputs, args: [] },
    dependency: [tableId, costEstimationId, calctype],
  });

  // make the first calculator as default
  useEffect(() => {
    // TODO: skip if there is no change in category
    if (calcultorId == 0) {
      if (calculators.length > 0) {
        setActive(calculators[0].id);
        // @ts-ignore

        setCostCalculatorId(orderedCalculators[0]?.id);
        // @ts-ignore
        setIsWeightInput(orderedCalculators[0]?.is_weight_input);
      } else {
        setActive(0);
      }
    } else {
      setActive(calcultorId);
    }
  }, [calculators]);

  const handleDoubleClick = () => {
    if (textRef.current) {
      const textToCopy: any = textRef.current.textContent;
      navigator.clipboard
        .writeText(textToCopy)
        .then(() => {
          ADMIN.toast.info("Copied to clipboard");
        })
        .catch((err) => {
          ADMIN.toast.error("Unable to Copy");
        });
    }
  };

  // sync result
  useEffect(() => {
    if (!cardCost) return;

    const key = getResultKey(),
      result = cardCost[key];

    if (typeof result === "string") setResult(result);
    else setResult("");
  }, [cardCost, calctype]);

  const handleCopied = (e: any, calc: any) => {
    e.stopPropagation();
    setCopyCMInfo(calc);
    copyCostModal.open();
  };

  const handleCommentEdit = (e: any, calc: any) => {
    e.stopPropagation();
    setCopyCMInfo(calc);
    commentModal.open();
  };
  // ----------------------------------------------------------------------------------
  // jsx abstraction

  // const renderCategoryItem = ( item: Record<string, any> ) => {
  //     return (
  //         <Menu.Item key={ item.key }
  //             name={ item.text }
  //             category={ item.value }
  //             active={ item.value === calculatorCategory }
  //             onClick={ handleCategoryChange }
  //         />
  //     );
  // };

  useEffect(() => {
    const calculatorIds: any = orderedCalculators?.map(
      (item: any, index: any) => {
        return item?.sequence;
      }
    );

    setEditedItems(calculatorIds);
    setOldEditedItems(calculatorIds);
  }, [orderedCalculators]);
  const handleDelete = (id: any, e: any) => {
    e?.stopPropagation();

    const SelectedId: any =
      orderedCalculators &&
      orderedCalculators?.filter((item: any, index: any) => {
        return item?.calculator?.id == id;
      })[0];

    var data = Math?.random();
    swal({
      text: " Update the final formula and click apply! Are you sure you want to delete this cost model?",

      icon: "warning",
      buttons: ["No", "Yes"],
    })
      .then((isData: any) => {
        if (isData) {
          API.delete(
            `cost/calculation/${SelectedId?.id}/`,
            { project: projectId },
            0
          )
            .then((res: any) => {
              history.push(
                `/createcostingpart/${projectId}/${projectname}/${productId}/${topVault}/${vault}/${abbrebation}/${cardtype}/${tableId}/${costEstimationId}/${tableCounter}/${data}/${calctype}/${0}/${scenarioId}/0/${newUI}`
              );
            })
            .catch((err: any) => {
              console.log("Server Error");
            });
        }
      })
      .catch((e: any) => {});
  };

  const renderMenuItem = (calculator: any, index: number) => {
    const handleSequenceChange = (sequence: any, costCalculationId: any) => {
      API.put(
        `/cost/calculation/${costCalculationId}/`,

        { sequence: sequence }
      )
        .then((res: any) => {
          var data = Math?.random();

          ADMIN.toast.info("Sequence Updated Successfully");
          // history.push(
          //   `/createcostingpart/${projectId}/${projectname}/${productId}/${topVault}/${vault}/${abbrebation}/${cardtype}/${tableId}/${costEstimationId}/${tableCounter}/${data}/${calctype}/${0}/${scenarioId}`
          // );
          getCalculators();
        })
        .catch((err: any) => {
          console.log(err);
        });
    };

    return (
      <>
        {active == calculator?.calculator?.id ? (
          <>
            {useraction && useraction?.action?.includes("D") ? (
              <TableRow
                sx={{
                  backgroundColor: calculator.is_used ? "#add8e6" : "#ddefff",
                  color: "#fff",
                  padding: 0,
                }}
                onClick={() =>
                  handleActiveCalculatorChange(calculator?.calculator)
                }
              >
                <TableCell
                  sx={{
                    color: "#007fff",
                    padding: "0.2rem",
                    borderBottomColor: "primary.light",
                  }}
                >
                  <TextField
                    placeholder="Sequence"
                    variant="standard"
                    size="small"
                    type="number"
                    value={editedItems[index]}
                    onChange={(event: any) => {
                      const updatedList = [...editedItems];
                      updatedList[index] = parseInt(event.target.value);
                      setEditedItems(updatedList);
                    }}
                    onBlur={(event: any) => {
                      if (
                        editedItems[index] &&
                        editedItems[index] !== oldEditedItems[index]
                      ) {
                        handleSequenceChange(
                          editedItems[index],
                          calculator?.id
                        );
                      }
                    }}
                    inputProps={{
                      min: 1,
                      sx: {
                        "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button":
                          {
                            display: "none",
                          },
                        "&::-moz-inner-spin-button, &::-moz-outer-spin-button":
                          {
                            display: "none",
                          },
                        "&[type=number]": {
                          MozAppearance: "textfield",
                        },
                      },
                    }}
                    fullWidth
                    sx={{
                      width: "3rem",
                      margin: "0.2rem 0",
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                    }}
                  />
                </TableCell>
                <TableCell
                  sx={{
                    padding: "0.2rem",
                    cursor: "pointer",
                    borderBottomColor: "primary.light",
                  }}
                >
                  <Tooltip
                    title={calculator?.calculator?.name}
                    placement="left"
                  >
                    <Box
                      sx={{
                        width: { lg: "10rem", xl: "14rem" },
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        paddingRight: "0.5rem",
                      }}
                    >
                      {calculator?.calculator?.name}
                    </Box>
                  </Tooltip>
                </TableCell>
                <TableCell
                  title="Double Click to Copy"
                  ref={textRef}
                  onDoubleClick={handleDoubleClick}
                  sx={{
                    padding: "0.2rem",
                    borderBottomColor: "primary.light",
                    width: "5rem",
                  }}
                >
                  <Box
                    sx={{
                      width: { lg: "5rem" },
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      cursor: "pointer",
                    }}
                  >
                    {toExponential(calculator?.result ?? 0)}
                  </Box>
                </TableCell>
                <TableCell
                  sx={{
                    padding: "0.2rem",
                    borderBottomColor: "primary.light",
                    width: "3rem",
                    zIndex: 1,
                  }}
                >
                  <CostModelMenuItemActive
                    calculator={calculator}
                    index={index}
                    handleCopied={handleCopied}
                    handleCommentEdit={handleCommentEdit}
                    handleDelete={handleDelete}
                  />
                </TableCell>
              </TableRow>
            ) : (
              <TableRow
                sx={{
                  backgroundColor: calculator.is_used ? "add8e6" : "#ddefff",
                  color: "#fff",
                  padding: 0,
                }}
                onClick={() =>
                  handleActiveCalculatorChange(calculator?.calculator)
                }
              >
                <TableCell
                  sx={{
                    color: "#007fff",
                    fontSize: "1.4rem",
                    borderBottomColor: "primary.light",
                  }}
                >
                  {index + 1}
                </TableCell>
                <TableCell
                  sx={{
                    padding: "0.2rem 0rem",
                    cursor: "pointer",
                    borderBottomColor: "primary.light",
                  }}
                >
                  <Tooltip
                    title={calculator?.calculator?.name}
                    placement="left"
                  >
                    <Box
                      sx={{
                        width: { lg: "10rem", xl: "14rem" },
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        paddingRight: "0.5rem",
                      }}
                    >
                      {calculator?.calculator?.name}
                    </Box>
                  </Tooltip>
                </TableCell>
                <TableCell
                  title="Double Click to Copy"
                  ref={textRef}
                  onDoubleClick={handleDoubleClick}
                  sx={{
                    padding: "0.2rem",
                    borderBottomColor: "primary.light",
                    width: "5rem",
                  }}
                >
                  <Box
                    sx={{
                      width: { lg: "5rem" },
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    {toExponential(calculator?.result ?? 0)}
                  </Box>
                </TableCell>
                <TableCell
                  sx={{
                    padding: "0.2rem",
                    borderBottomColor: "primary.light",
                    width: "3rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: "0.5rem",
                      justifyContent: "center",
                      width: "3rem",
                    }}
                  >
                    <Tooltip
                      title={
                        calculator?.message != null ? calculator?.message : ""
                      }
                      placement="top"
                    >
                      <CommentIcon
                        sx={{ fontSize: "1.4rem", cursor: "pointer" }}
                      />
                    </Tooltip>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </>
        ) : (
          <>
            {useraction && useraction?.action?.includes("D") ? (
              <TableRow
                sx={{
                  backgroundColor: calculator.is_used ? "#a5e1a6" : "#fff",
                  padding: 0,
                }}
                onClick={() =>
                  handleActiveCalculatorChange(calculator?.calculator)
                }
              >
                <TableCell
                  sx={{
                    padding: "0.2rem",
                    paddingRight: "0.4rem",
                    borderBottomColor: "primary.light",
                  }}
                >
                  <TextField
                    placeholder="Sequence"
                    variant="standard"
                    size="small"
                    type="number"
                    value={editedItems[index]}
                    onChange={(event: any) => {
                      const updatedList = [...editedItems];
                      updatedList[index] = parseInt(event.target.value);
                      setEditedItems(updatedList);
                    }}
                    onBlur={(event: any) => {
                      if (
                        editedItems[index] &&
                        editedItems[index] !== oldEditedItems[index]
                      ) {
                        handleSequenceChange(
                          editedItems[index],
                          calculator?.id
                        );
                      }
                    }}
                    inputProps={{
                      min: 1,
                      sx: {
                        "&::-webkit-inner-spin-button, &::-webkit-outer-spin-button":
                          {
                            display: "none",
                          },
                        "&::-moz-inner-spin-button, &::-moz-outer-spin-button":
                          {
                            display: "none",
                          },
                        "&[type=number]": {
                          MozAppearance: "textfield",
                        },
                      },
                    }}
                    fullWidth
                    sx={{
                      width: "3rem",
                      margin: "0.2rem 0",
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                    }}
                  />
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    padding: "0.2rem 0rem",
                    cursor: "pointer",
                    borderBottomColor: "primary.light",
                  }}
                >
                  <Tooltip
                    title={calculator?.calculator?.name}
                    placement="left"
                  >
                    <Box
                      sx={{
                        width: { lg: "10rem", xl: "14rem" },
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {calculator?.calculator?.name}
                    </Box>
                  </Tooltip>
                </TableCell>
                <TableCell
                  title="Double Click to Copy"
                  ref={textRef}
                  onDoubleClick={handleDoubleClick}
                  sx={{
                    padding: "0.2rem",
                    borderBottomColor: "primary.light",
                    width: "5rem",
                  }}
                >
                  <Box
                    sx={{
                      width: { lg: "5rem" },
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    {toExponential(calculator?.result ?? 0)}
                  </Box>
                </TableCell>
                <TableCell
                  sx={{
                    padding: "0.2rem",
                    borderBottomColor: "primary.light",
                    width: "3rem",
                    zIndex: 1,
                  }}
                >
                  <CostModelMenuItem
                    calculator={calculator}
                    index={index}
                    handleCommentEdit={handleCommentEdit}
                    handleDelete={handleDelete}
                  />
                </TableCell>
              </TableRow>
            ) : (
              <TableRow
                sx={{
                  backgroundColor: calculator.is_used ? "#a5e1a6" : "#fff",
                }}
                onClick={() =>
                  handleActiveCalculatorChange(calculator?.calculator)
                }
              >
                <TableCell
                  sx={{ padding: "0.2rem", borderBottomColor: "primary.light" }}
                >
                  {index + 1}
                </TableCell>
                <TableCell
                  sx={{
                    textAlign: "left",
                    padding: "0.2rem 0rem",
                    cursor: "pointer",
                    borderBottomColor: "primary.light",
                  }}
                >
                  <Tooltip
                    title={
                      calculator?.calculator?.name +
                      toExponential(calculator?.result ?? 0)
                    }
                    placement="left"
                  >
                    <Box
                      sx={{
                        width: { lg: "10rem", xl: "14rem" },
                        textOverflow: "ellipsis",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        paddingRight: "0.5rem",
                      }}
                    >
                      {calculator?.calculator?.name}
                    </Box>
                  </Tooltip>
                </TableCell>
                <TableCell
                  title="Double Click to Copy"
                  ref={textRef}
                  onDoubleClick={handleDoubleClick}
                  sx={{
                    padding: "0.2rem",
                    borderBottomColor: "primary.light",
                    width: "5rem",
                  }}
                >
                  <Box
                    sx={{
                      width: { lg: "5rem" },
                      textOverflow: "ellipsis",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                    }}
                  >
                    {toExponential(calculator?.result ?? 0)}
                  </Box>
                </TableCell>
                <TableCell
                  sx={{
                    padding: "0.2rem",
                    borderBottomColor: "primary.light",
                    width: "3rem",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: "0.5rem",
                      justifyContent: "center",
                      width: "3rem",
                    }}
                  >
                    <Tooltip
                      title={
                        calculator?.message != null ? calculator?.message : ""
                      }
                      placement="top"
                    >
                      <CommentIcon
                        sx={{ fontSize: "1.4rem", cursor: "pointer" }}
                      />
                    </Tooltip>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </>
        )}
      </>
    );
  };

  const SwalAlertHandlerImages = (type: any) => {
    if (type == true) {
      var data = Math?.random();
      history.push(
        `/createcostingpart/${projectId}/${projectname}/${productId}/${topVault}/${vault}/${abbrebation}/${cardtype}/${tableId}/${costEstimationId}/${tableCounter}/${data}/${calctype}/${0}/${scenarioId}/0/${newUI}`
      );
      addcostmodaldata.close();

      {
        Swal.fire({
          html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
        <br />
        <p style="color:#007fff;"> Cost model added  successfully
        </p>   
         </div>`,
        });
      }
    }

    if (type == false) {
      {
        addcostmodaldata.close();
        Swal.fire({
          icon: "error",
          html: `<div>
        <br />
        <p style="color:#007fff;"> Server Error
        </p>   
         </div>`,
        });
      }
    }
  };
  const AddCostModalHandler = () => {
    setLoader(true);
    API.get(
      "/cost/costing_view/",
      {
        vault: vault,
        level: "cost_detail",
        scenario: scenarioId ? scenarioId : undefined,
      },
      0
    )
      .then((res: any) => {
        setLoader(false);
        setCostDetailsData(res.data[0]);
        if (calctype == 3 || calctype == 4 || calctype == 5 || calctype == 6) {
          addCostModalProcess.open(res.data[0]);
        } else {
          addcostmodaldata.open(res.data[0]);
        }
      })
      .catch((err: any) => {
        setLoader(false);
      });
  };

  const getCalculators = () => {
    API.get("cost/calculation/", {
      category: calctype,
      /** RawMaterialCost/ProcessCost */
      object_id: tableId,
    })
      .then((res: any) => {
        setOrderedCalculators(res.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getCalculators();
  }, [alldatacalculators]);

  // ----------------------------------------------------------------------------------
  // jsx
  
  

  return (
    <Fragment>
      {/* <CalculatorInputsColorsLegend /> */}
      <Box
        data-testid="calculator"
        className={styles.container}
        style={{ width: "100%" }}
      >
        {addcostmodaldata.isOpen && (
          <AddCostModal
            onCloseModal={addcostmodaldata.close}
            isOpen={addcostmodaldata.isOpen}
            costDetailsData={addcostmodaldata.propsId}
            SwalAlertHandlerImages={SwalAlertHandlerImages}
            // parentClose={parentClose}
          />
        )}
        {addCostModalProcess.isOpen && (
          <AddCostModelProcess
            onCloseModal={addCostModalProcess.close}
            isOpen={addCostModalProcess.isOpen}
            costDetailsData={addCostModalProcess.propsId}
            SwalAlertHandlerImages={SwalAlertHandlerImages}
            //@ts-ignore
            passingObj={JSON.parse(sessionStorage?.getItem("selectedProcess"))}
          />
        )}
        {copyCostModal.isOpen && (
          <CopyCostModal
            onCloseModal={copyCostModal.close}
            isOpen={copyCostModal.isOpen}
            copyCMInfo={copyCMInfo}
            SwalAlertHandlerImages={SwalAlertHandlerImages}
          />
        )}

        {commentModal.isOpen && (
          <CommentModal
            onCloseModal={commentModal.close}
            isOpen={commentModal.isOpen}
            commentInfo={copyCMInfo}
            SwalAlertHandlerImages={SwalAlertHandlerImages}
          />
        )}

        {userInputsFetched.match("FALSE") ? (
          <Skeleton
            variant="rectangular"
            height="66vh"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
        ) : (
          <>
            <div
              // className={styles.other}
              adl-scrollbar="true"
              adl-scrollbar-width="0.3"
              style={{
                width: "inherit",
                display: "flex",
                flexDirection: "row",
                gap: "0.5rem",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  // gap: "1rem",
                  // columnGap: "0.5rem",
                  zIndex: 1,
                  position: "relative",
                  width: { lg: "23vw", xl: "20vw" },
                  // border: "1px solid #e0e0e0",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  {useraction && useraction?.action?.includes("C") && (
                    <LoadingButton
                      loading={Loader}
                      startIcon={<AddIcon sx={{ marginRight: "-0.5rem" }} />}
                      variant="contained"
                      size="small"
                      onClick={() => AddCostModalHandler()}
                      sx={{
                        textAlign: "center",
                        width: "100%",
                        backgroundColor: "#007fff",
                        color: "#fff",
                        padding: "0.175rem",
                      }}
                    >
                      Add CM ({calculators.length})
                    </LoadingButton>
                  )}
                </Box>
                <Box
                  sx={{
                    height: { lg: "63vh", xl: "72vh" },
                    marginBottom: "0.1rem",
                    width: "100%",
                    overflowX: "hidden",
                  }}
                  adl-scrollbar="true"
                  adl-scrollbar-width="0.3"
                >
                  <Table>
                    <TableHead sx={{ width: "15rem" }}>
                      <TableRow>
                        <TableCell
                          sx={{
                            color: "#007fff",
                            padding: "0.2rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          S.No.
                        </TableCell>
                        <TableCell
                          sx={{
                            // color: "#007fff",
                            textAlign: "left",
                            padding: "0.2rem 0rem",
                            cursor: "pointer",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          <Box
                            sx={{
                              width: { lg: "10rem", xl: "14rem" },
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              paddingRight: "0.5rem",
                            }}
                          >
                            Calculator Name
                          </Box>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem",
                            borderBottomColor: "primary.light",
                            width: "5rem",
                          }}
                        >
                          Result
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem",
                            borderBottomColor: "primary.light",
                            width: "4rem",
                          }}
                        >
                          Action
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody sx={{ width: "15rem" }}>
                      {orderedCalculators.map(renderMenuItem)}
                    </TableBody>
                  </Table>
                </Box>
              </Box>
              <div style={{ flexGrow: 1 }}>
                <Calculator
                  CopyAliasHandler={CopyAliasHandler}
                  setActive={setActive}
                  calculatorId={active}
                  handleSave={handleSave}
                  status={statustracker}
                  calculators={calculators}
                  costModelCalculatorId={costCalculatorId}
                  handleChangeFinalformula={handleChange}
                  isWeightInput={isWeightInput}
                  result={result}
                  itemCategory={orderedCalculators?.[0]?.item_category}
                />
              </div>
            </div>
          </>
        )}
      </Box>
    </Fragment>
  );
};

// ----------------------------------------------------------------------------------

export default CalculatorCard;

// ----------------------------------------------------------------------------------
