import * as React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Chip, Divider } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { API } from "../../api-services";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import LoadingButton from "@mui/lab/LoadingButton";
import Swal from "sweetalert2";
import SuccessIcon from "../../Assets/images/success.png";
import { useDispatch } from "react-redux";
import { increaseCounter } from "../../Redux/Actions/compareopen.action";
import { useHistory } from "react-router-dom";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30vw",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export interface ICreateFeatureMoveModalProps {
  onCloseModal: any;
  isOpen: any;
  featuresData: any;
  data: any;
  getModule: any;
}

export function CreateFeatureMoveModal(props: ICreateFeatureMoveModalProps) {
  const { projectId, projectname, type, parent, child, featureId, feId, pageNumber, } = useRouteParams<any>();
  const { onCloseModal, isOpen, featuresData, data, getModule } = props;
  const history = useHistory();
  const [selectedFeatures, setSelectedFeatures] = React.useState<any>([]);
  const [groupsData, setGroupsData] = React.useState<any>([]);
  const [selectedGroup, setSelectedGroup] = React.useState<any>("");
  const dispatch = useDispatch();
  const [moveLoading, setMoveLoading] = React.useState<any>(false);

  React.useEffect(() => {
      API.get(
        "/xcpep/feature_groups/",
        {
          project: projectId,
          type: parseInt(type) - 1,
          abbreviation: parent,
          group: child,
          move: true,
        },
        0
      )
        .then((res: any) => {
          setGroupsData(res?.data);
        })
        .catch((err: any) => {});
  }, []);

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedGroup(event.target.value as string);
  };

  const handleSelectChangeFeatures = (e: any) => {
    const { value } = e.target;
    setSelectedFeatures(value);
  };
  console.log(getModule(), "getModule")
  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}>
              {getModule()} Move
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ display: "flex", alignItems: "center", width: "100%", columnGap: "1rem" }}>
            {/* <FormControl
              fullWidth
              variant="standard">
            
              {selectedFeatures && (
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedFeatures || ""}
                  multiple
                  multiline
                  label="Role"
                  placeholder="Select Role"
                  onChange={handleSelectChangeFeatures}
                  size="small"
                  sx={{
                    "&:before": {
                      borderBottomColor: "primary.main",
                    },
                    ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
                  }}
                  renderValue={(selected) => (
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 0.5,
                      }}>
                      {featuresData
                        .filter((item: any) => selected?.includes(item?.id))
                        ?.map((value: any) => (
                          <Chip
                            size="small"
                            key={value.id}
                            label={value.name}
                            onDelete={() => {
                              //   if (selectedFeatures.length > 1) {
                              setSelectedFeatures((prev: any) => prev?.filter((item: any) => value.id !== item));

                              //   }
                            }}
                            deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
                          />
                        ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}>
                  {featuresData &&
                    featuresData?.map((item: any, index: number) => {
                      return (
                        !selectedFeatures.includes(item?.id) && (
                          <MenuItem
                            key={index}
                            value={item?.id}>
                            {item.name}
                          </MenuItem>
                        )
                      );
                    })}
                </Select>
              )}
            </FormControl> */}

            <FormControl fullWidth variant="standard">
              <InputLabel
                sx={{ color: "primary.main" }}
                shrink
                id="demo-simple-select-label">
                Destination Group
              </InputLabel>
              <Select
                variant="standard"
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedGroup}
                placeholder="Select a Group"
                onChange={handleChange}
                sx={{
                  "&:before": {
                    borderBottomColor: "primary.light",
                  },
                  "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                    fontSize: "1rem",
                  },
                  ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
                }}>
                {groupsData &&
                  groupsData?.map((item: any) => {
                    return <MenuItem sx={{ fontSize: '1rem' }}  value={item?.id}>{item?.name}</MenuItem>;
                  })}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ width: "100%", textAlign: "right", margin: "1rem", paddingRight: "1rem" }}>
            <LoadingButton
              variant="contained"
              size="small"
              loading={moveLoading}
              sx={{
                padding: "0.5rem",
                lineHeight: 1,                
                "&:hover": {
                  transform: 'Scale(1.05)',
                  transition: 'transform 0.5s ease',
                },
                '.MuiCircularProgress-root': {
                  color: 'primary.main',
                },
              }}
              disabled={selectedGroup === "" ? true : false}
              onClick={() => {
                setMoveLoading(true);
                API.post(
                  "xcpep/move_feature/",
                  {
                    feature: data.feature,
                    group: selectedGroup,
                  },
                  {},
                  0
                )
                  .then((res: any) => {
                    onCloseModal();

                    Swal.fire({
                      html: `<div><img src="${SuccessIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
                      <br />
                      <p style="color:#007fff;">${getModule()} moved successfully</p>   
                       </div>`,
                    });
                    setMoveLoading(false);
                    var random: any = Math.floor(Math.random() * 1000000 + 1);
                    dispatch(increaseCounter(random));
                    history.push(
                      `/createfeature/${projectId}/${projectname}/${type}/${parent}/${selectedGroup}/${0}/${0}/${0}`
                    );
                  })
                  .catch((err: any) => {
                    onCloseModal();
                    const { data } = err?.response;
                    Swal.fire({
                      icon: "error",
                      html: `<div>
            <br />
            <p style="color:"red">${data[0]}</p>   
             </div>`,
                    });
                    setMoveLoading(false);
                  });
              }}>
              Move
            </LoadingButton>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
