import {
  Box,
  FormControlLabel,
  Skeleton,
  Switch,
  Typography,
} from "@mui/material";
import * as React from "react";
import styles from "./CostingCalculatorSection.module.scss";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { useQueryDispatch } from "../../../CustomHook/useQueryDispatch";

import {
  getRawMaterialCardCosts,
  resetRawMaterialCard,
} from "../../../Redux/Actions/rawMaterialCard.actions";
import { API } from "../../../api-services";
import CalculatorCardConfig from "./CalculatorCard/CalculatorCardConfig";
import RawMaterialCardConfig from "./RawMaterialCard/RawMaterialCardConfig";
import { useHistory } from "react-router-dom";
import ProcessCardConfig from "./RawMaterialCard/ProcessCardConfig";
import OverheadCardConfig from "./RawMaterialCard/OverheadCardConfig";
export interface ICostigngCalculatorSectionProps {
  passingObj?: any;
  calculatorData?: any;
  costDetailsData: any;
}

type CalculatorItem = {
  id: number;
  calculator_id: number;
  sequence_id: string;
  type: string;
  ancestors: string[];
  parent_id: any;
  category: any;
};

export const isShowUserInputVal = React.createContext<any>(false);
export function CostingCalculatorConfig(
  props: ICostigngCalculatorSectionProps
) {
  const {
    vault,
    topVault,
    projectId,
    projectname,
    productId,
    abbrebation,
    cardtype,
    tableId,
    costEstimationId,
    condition,
    tableCounter,
    calctype,
    calcultorId,
    scenarioId,
    conditionPopup,
    newUI
  } = useRouteParams<any>();
  const history = useHistory();
  const { passingObj, calculatorData, costDetailsData } = props;
  const handleDelete = () => {
    console.info("You clicked the delete icon.");
  };

  const [PermissionData, setPermissionData] = React.useState<any>(null);
  const [showUserInput, setShowUserInput] = React.useState<any>(false);
  const [calcDataRedirect, setCalcDataRedirect] = React.useState<any>([]);
  const [nextCalc, setNextCal] = React.useState<any>(null);
  const [prevCalc, setPrevCal] = React.useState<any>(null);
  const [currenctCalc, setCurrentCalc] = React.useState<any>(null);

  const costsFetched = useQueryDispatch({
    // @ts-ignore
    query: {
      action: getRawMaterialCardCosts,
      arg: { costEstimationId, tableId: tableId },
    },
    cleanup: { action: resetRawMaterialCard, args: [] },

    dependency: [tableId, vault, condition, calcultorId],
  });

  // console.log("LKJLKJKLJKLJ7867",costsFetched.match("TRUE"))

  React.useEffect(() => {
    API.get(
      "/auth/calibration_user_permission/",
      {
        action: true,
        costing_create: true,
        vault: vault,
        scenario: scenarioId ? scenarioId : undefined,
      },
      0
    )
      .then((res: any) => {
        setPermissionData(res?.data);
      })
      .catch((err: any) => {
        console.log("Srver Error");
      });
  }, [vault]);

  const getComponent = () => {
    
  
   
    switch (calctype) {
      case 1:
        return <RawMaterialCardConfig costDetailsData={costDetailsData} />;
      case 2:
        return <RawMaterialCardConfig costDetailsData={costDetailsData} />;
      case 3:
        return <ProcessCardConfig />;
      case 4:
        return <ProcessCardConfig />;
      case 5:
        return <ProcessCardConfig />;
      case 6:
        return <ProcessCardConfig />;

      default:
        return <OverheadCardConfig />;
    }
  };
  {
    /* <RawMaterialCard />
                          <ProcessCard/> */
  }
  return (
    <div>
      <Box sx={{ height: "100%", width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row ",
            gap: "0.2rem",
            justifyContent: "space-between",
            ml: 1,
            mr: 1,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: "1rem", width: "100%", justifyContent: "space-between" }}>
            <Typography variant="body2" sx={{ fontWeight: "bold" }}>
              {currenctCalc && currenctCalc?.calculator_name}
            </Typography>
            {/* <Button
            size="small"
            sx={{ float: "right", mb: 1 }}
            variant={showUserInput ? "contained" : "outlined"}
            onClick={() => setShowUserInput((prev: any) => !prev)}
          >
            User Inputs
          </Button> */}
            <FormControlLabel
              control={
                <Switch
                  checked={conditionPopup === "true" ? true : false}
                  onChange={() => {
                    // /createcostingpart/:projectId/:projectname/:productId/:topVault/:vault/:abbrebation/:cardtype/:tableId/:costEstimationId/:tableCounter/:condition/:calctype/:calcultorId/:scenarioId/:conditionPopup
                    history.push(`/createcostingpart/${projectId}/${projectname}/${productId}/${topVault}/${vault}/${abbrebation}/${cardtype}/${tableId}/${costEstimationId}/${tableCounter}/${condition}/${calctype}/${calcultorId}/${scenarioId}/${!showUserInput}/${newUI}`);
                    setShowUserInput((prev: boolean) => !prev)
                  }}
                  sx={{
                    "& .MuiSwitch-switchBase": {
                      color: "gray", // Switch color when off
                    },
                    "& .Mui-checked": {
                      color: "#007fff", // Switch color when on
                    },
                    "& .MuiSwitch-track": {
                      backgroundColor: conditionPopup === "true" ? "#00887A" : "#bfbfbf", // Track color
                    },
                    "& .MuiSwitch-thumb": {
                      boxShadow: conditionPopup === "true" ? "0 0 10px #00887A" : "none", // Creative glow effect when on
                    },
                  }}
                />
              }
              label={
                conditionPopup ? "User Inputs" : "User Inputs"
              }
              sx={{
                display: "flex",
                justifyContent: "right",
                color: conditionPopup === "true" ? "#007fff" : "gray",
                fontWeight: "bold",
                // mb: 1,
              }}
            />
          </Box>

        </Box>
        {costsFetched?.match("FETCHING") ? (
          <Skeleton
            variant="rectangular"
            height="66vh"
            width="50vw"
            sx={{ marginBottom: "1rem" }}
          />
        ) : (
          <>
            <Box>
              {costsFetched?.match("FETCHING") ? (
                <Skeleton
                  variant="rectangular"
                  height="74vh"
                  width="50vw"
                  sx={{ marginBottom: "1rem" }}
                />
              ) : (
                <>
                  <Box sx={{ height: "8vh", width: "100%" }}>
                  {tableId && getComponent()}
                  </Box>
                  <Box
                    className={styles.calculatorTabSection}
                  // sx={{ height: "78vh !important" }}
                  >
                    <isShowUserInputVal.Provider value={showUserInput}>
                      <CalculatorCardConfig
                        setCurrentCalc={setCurrentCalc}
                      />
                    </isShowUserInputVal.Provider>
                  </Box>
                </>
              )}
            </Box>
          </>
        )}
      </Box>
    </div>
  );
}
