import {
    Autocomplete,
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    InputLabel,
    MenuItem,
    TextField,
    Typography,
  } from "@mui/material";
  import * as React from "react";
  import Modal from "@mui/material/Modal";
  import CancelIcon from "@mui/icons-material/Cancel";
  import Select, { SelectChangeEvent } from "@mui/material/Select";
  import { useBaseModal } from "../../../SearchPage/useBaseModal";
  import OtherIdeaDetailsModal from "../../IdeaCreateForm/OtherIdeaDetailsModal";
  import { useRouteParams } from "../../../../ui-reusable-component/useRouteParams";
  import BOMPromoteFrom from "./BOMPromoteFrom";
  import CostingPromoteFrom from "./CostingPromoteFrom";
  import FeaturePromoteForm from "./FeaturePromoteForm";
  
  import { IdeaOriginList } from "../../../../utlis/Idea.constant";
import { get } from "lodash";
  
  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: { lg: "60vw", xl: "50vw" },
    bgcolor: "background.paper",
    borderRadius: "10px",
    p: 1,
  };
  
  interface FilmOptionType {
    title: string;
    year: number;
  }
  interface Params {
    projectId: any;
    projectName: any;
    topVault: any;
    subSystem: any;
    ideaId: any;
    abbrevID: any;
    originID: any;
  }
  export interface IIdeaPromoteFormModalProps {
    onCloseModal: any;
    isOpen: any;
    getIdeaPromotedDetails?: any;
    getIdeaDetailedData?: any;
    getIdeaInitData?: any;
    getCategoryList?: any;
  }
  const top100Films = [
    { title: "The Shawshank Redemption", year: 1994 },
    { title: "The Godfather", year: 1972 },
    { title: "The Godfather: Part II", year: 1974 },
    { title: "The Dark Knight", year: 2008 },
    { title: "12 Angry Men", year: 1957 },
    { title: "Schindler's List", year: 1993 },
  ];
  
  export default function IdeaPromoteFormModal(props: IIdeaPromoteFormModalProps) {
    const { onCloseModal, isOpen, getIdeaPromotedDetails, getIdeaDetailedData, getIdeaInitData, getCategoryList } = props;
    const {subSystem, abbrevID, originID} = useRouteParams<Params>();

    const [age, setAge] = React.useState("");
    const [ideaOrigin, setIdeaOrigin] = React.useState<any>("");
  
    const defaultProps = {
      options: top100Films,
      getOptionLabel: (option: FilmOptionType) => option.title,
    };
    const flatProps = {
      options: top100Films.map((option) => option.title),
    };
    const [value, setValue] = React.useState<FilmOptionType | null>(null);
  
    const OriginHandleChange = (event: SelectChangeEvent) => {
      setIdeaOrigin(+event.target.value);
    };

    console.log(getIdeaPromotedDetails, "getIdeaPromotedDetails");

    const data = {
      name : getIdeaPromotedDetails?.combination?.combination_base,
      id: getIdeaPromotedDetails?.base?.id,
    }

    console.log(abbrevID, "abbrevID");
    
  
    const getFormDetails = () => {
      switch (originID) {
        case 1:
          return (
            <>
              <BOMPromoteFrom
                onCloseModal={onCloseModal}
                isOpen={isOpen}
                subSystem={subSystem}
                ideaOrigin={ideaOrigin}
                subSystemId={abbrevID}
                getIdeaPromotedDetails={getIdeaPromotedDetails}
                data={data}
                getIdeaDetailedData={getIdeaDetailedData}
                getIdeaInitData={getIdeaInitData}
                getCategoryList={getCategoryList}
              />
            </>
          );
  
        case 3:
          return (
            <>
              <FeaturePromoteForm
                onCloseModal={onCloseModal}
                isOpen={isOpen}
                subSystem={subSystem}
                ideaOrigin={ideaOrigin}
                subSystemId={abbrevID}
                getIdeaPromotedDetails={getIdeaPromotedDetails}
                data={data}
                getIdeaDetailedData={getIdeaDetailedData}
                getIdeaInitData={getIdeaInitData}
                getCategoryList={getCategoryList}
                
              />
            </>
          );
        case 4:
          return (
            <>
              <CostingPromoteFrom
                onCloseModal={onCloseModal}
                isOpen={isOpen}
                subSystem={subSystem}
                ideaOrigin={ideaOrigin}
                subSystemId={abbrevID}
                getIdeaPromotedDetails={getIdeaPromotedDetails}
                data={data}
                getIdeaDetailedData={getIdeaDetailedData}
                getIdeaInitData={getIdeaInitData}
                getCategoryList={getCategoryList}
              />
            </>
          );
  
        case 7:
          return (
            <>
              <FeaturePromoteForm
                onCloseModal={onCloseModal}
                isOpen={isOpen}
                subSystem={subSystem}
                ideaOrigin={ideaOrigin}
                subSystemId={abbrevID}
                getIdeaPromotedDetails={getIdeaPromotedDetails}
                data={data}
                getIdeaDetailedData={getIdeaDetailedData}
                getIdeaInitData={getIdeaInitData}
                getCategoryList={getCategoryList}
              />
            </>
          );
      }
    };
  
    const OtherIdeaModal = useBaseModal();
    const OtherIdeaFormHandler = () => {
      OtherIdeaModal.open();
    };
  
    return (
      <div>
        {OtherIdeaModal.isOpen && (
          <OtherIdeaDetailsModal
            onCloseModal={OtherIdeaModal.close}
            isOpen={OtherIdeaModal.open}
            subSystem={subSystem}
            closeParentModal={onCloseModal}
          />
        )}
        <Modal
          open={isOpen}
          onClose={onCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
              <Typography
                style={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  width: "100%",
                  textAlign: "center",
                  // marginRight: '-2rem'
                }}
              >
                Idea Form Entry ({subSystem})
              </Typography>
              {/* <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} /> */}
            </Box>
            <Divider
              sx={{
                borderColor: "primary.light",
                margin: "0 1rem",
                marginBottom: "1rem",
              }}
            />
            <Box sx={{ padding: "0 1rem", width: "100%" }}>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "1fr 1fr",
                  width: "100%",
                  gap: "1rem",
                  marginBottom: "1rem",
                }}
              >
                <FormControl
                  required
                  sx={{ ".MuiFormLabel-asterisk": { color: "red" } }}
                  fullWidth
                  variant="standard"
                  disabled={true}
                >
                  <InputLabel
                    sx={{ color: "primary.main" }}
                    shrink
                    id="demo-simple-select-label"
                  >
                    Select Origin
                  </InputLabel>
                  <Select
                    id="Origin"
                    name="Origin"
                    labelId="demo-select-small"
                    required
                    onChange={OriginHandleChange}
                    sx={{
                      "&:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                        {
                          fontSize: "1rem",
                        },
                      ".MuiSvgIcon-root.MuiSelect-icon": {
                        color: "primary.main",
                      },
                    }}
                    MenuProps={{
                      style: {
                        maxHeight: 400,
                        color: "007fff",
                      },
                    }}
                    value={originID}
                  >
                    {IdeaOriginList &&
                      IdeaOriginList?.map((item: any) => {
                        return (
                          <MenuItem
                            sx={{ color: "primary.main", fontSize: "1rem" }}
                            value={item?.value}
                          >
                            {item?.text}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
                <FormControl fullWidth variant="standard"></FormControl>
              </Box>
              {getFormDetails()}
            </Box>
          </Box>
        </Modal>
      </div>
    );
  }
  