import React, { useState } from 'react'
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CancelIcon from '@mui/icons-material/Cancel';

import { Divider, Tooltip } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { API } from '../../../../../api-services';

interface InfoModalProps {


  onCloseModal: any;
  isOpen: any;
  Item: any;
  setCounter: any;

}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: "30vw",
  maxWidth: '50vw',
  // height: '80vh',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  p: 1,
};
const style1 = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: "49vw",
  maxWidth: '50vw',
  height: '44rem',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  p: 1,
};

const TagDataModal = (props: InfoModalProps) => {



  const { onCloseModal,
    isOpen,
    Item, setCounter } = props
  const [loader, setLoader] = useState<any>(false)

  const [tagData, settabdata] = useState<any>(null)
  const onchangeHandler = (e: any) => {
    settabdata(e?.target?.value)
  }
  const Createhandler = () => {
    setLoader(true)
    API.post("/xcpep/360_image_view/", { ...Item, text: tagData }).then((res: any) => {
      setCounter((prev: any) => prev + 1)
      setLoader(false)
    }).catch((err: any) => {
      console.log("Server Error")
      setLoader(false)
    })
  }
  console.log(Item?.tag_type, "viuvhjvhfhvfhvfhjvbhvfhvj")

  return (
    <div>


      <Modal
        open={isOpen}
      // onClose={onCloseModal}
      >
        <Box sx={Item?.tag_type == 0 ? style1 : style}>
          <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: '-2rem'
              }}
            >
              Nav Info
            </Typography>
            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{
            width: '100%', display: "flex",
            flexDirection: "column"
          }}

          >
            {Item?.tag_type == 0 ?


              <div>
                <div>

                  <div style={{
                    width: "28rem",
                    height: "13rem",
                    /* display: flex; */

                    border: "4px solid #ddefff",

                    /* justify-content: center; */
                    /* align-items: center; */
                    margin: "0 auto"
                  }}>

                  </div>
                </div>


                <textarea
                  value={tagData}
                  onChange={(e: any) => onchangeHandler(e)}
                  style={{ height: "22rem", margin: "0.7rem", width: "98%", border: "1px solid #ddefff" }}
                  placeholder='Enter tag Data'

                />



              </div>
              :







              <textarea
                value={tagData}
                onChange={(e: any) => onchangeHandler(e)}
                style={{ height: "10rem", margin: "0.7rem" }}
                placeholder='Enter tag Data'

              />
            }
            <div style={{
              display: "flex",
              justifyContent: "flex-end"
            }}>
              <LoadingButton onClick={() => Createhandler()} loading={loader} sx={{ cursor: "pointer" }} variant='contained'>
                Create
              </LoadingButton>
            </div>
          </Box>
        </Box>
      </Modal>
    </div>
  )
}

export default TagDataModal;