import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import * as React from "react";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useBaseModal } from "../../SearchPage/useBaseModal";
import OtherIdeaDetailsModal from "./OtherIdeaDetailsModal";
import * as yup from "yup";
import styles from "../IdeaCreateLandingpage.module.scss";
import { useFormik } from "formik";
import { IdeaTypesList } from "../../../utlis/Idea.constant";
import { API } from "../../../api-services";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { uniqBy } from "lodash";
import { parameterConfigSelectors } from "../../../Redux/Reducers/parameterConfig.reducer";
import { useSelector } from "react-redux";
import { MODULES } from "../../../Constants/modules.constants";
import { ADMIN } from "../../../Redux/Services/admin.service";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";

interface FilmOptionType {
  title: string;
  year: number;
}
const validationSchema = yup.object({
  origin: yup.mixed(),
  // .required("Please enter your Origin"),
  idea_type: yup.mixed().required("Please enter Idea Type"),
  reference: yup.mixed(),
  parameter: yup.mixed(),
  unit: yup.mixed(),
  competitor_model: yup.mixed(),
  competitor: yup.mixed(),
  duration: yup.mixed().required("Please enter your Implemetation time"),
  ease: yup.mixed().required("Please enter your Implemetation ease"),
  owner: yup.mixed(),
  idea_level: yup.mixed().required("Please enter your Idea Level"),
  duration_level: yup.mixed(),
});

export interface IFeatureFormProps {
  subSystem: any;
  onCloseModal: any;
  isOpen: any;
  ideaOrigin: any;
  subSystemId?: any;
  getTableData?: any;
  data?:any;
  getUpdatedIdeaList?: any;
}

export default function FeatureForm(props: any) {
  const history = useHistory();
  const [featureDropDown, setFeatureDropDown] = React.useState<any>([]);
  const { topVault, projectId,projectName,system } = useRouteParams<any>();
  const { onCloseModal, isOpen, subSystem, subSystemId, ideaOrigin,data, getTableData, getUpdatedIdeaList} = props;
  const [competitorOptions, setCompetitorOptions] = React.useState<any>([]);
  const [parameters, setParameters] = React.useState<any>([]);
  const [owner, setOwner] = React.useState<any>([]);
  const [value, setValue] = React.useState<FilmOptionType | null>(null);
  const configs = useSelector(parameterConfigSelectors.selectAll);

  const ideaLevelOptions: any = [
    {
      key: 1,
      text: "Easy",
      value: 1,
    },
    {
      key: 2,
      text: "Medium",
      value: 2,
    },
    {
      key: 3,
      text: "Complex",
      value: 3,
    },
  ];
  const durationOptions = [
    {
      key: 1,
      text: "24 Hrs",
      value: 1,
    },
    {
      key: 2,
      text: "48 Hrs",
      value: 2,
    },
    {
      key: 3,
      text: "72 Hrs",
      value: 3,
    },
  ];
  const OtherIdeaModal = useBaseModal();
  const OtherIdeaFormHandler = () => {
    OtherIdeaModal.open();
  };

  console.log("subSystemId", subSystemId, subSystem);

  const getFeatures = () => {
    if(subSystem === "PRODUCT"){
      API.get(
        "/xcpep/features/",
        {
          top_vault: topVault,
          
        },
        0
      )
        .then((res: any) => {
          setFeatureDropDown(
            res.data?.map((obj: any) => ({
              id: obj.id,
              value: obj.feature,
              text: obj.name,
            }))
          );
        })
        .catch((err: any) => {});
    }
    else{
    API.get(
      "/xcpep/features/",
      {
        
        vault: subSystemId,
      },
      0
    )
      .then((res: any) => {
        setFeatureDropDown(
          res.data?.map((obj: any) => ({
            id: obj.id,
            value: obj.feature,
            text: obj.name,
          }))
        );
      })
      .catch((err: any) => {});
    }
  };

  const getOwnerOptions = () => {
    API.get(
      "/auth/idea_user_list/",
      {
        project: projectId,
      },
      0
    )
      .then((res: any) => {
        if (res) {
          setOwner(
            res?.data.map((item: any, index: number) => ({
              key: item.id,
              text: item.username,
              value: item.id,
            }))
          );
        }
      })
      .catch((err: any) => {});
  };
  React.useEffect(() => {
    getOwnerOptions();
  }, []);

  React.useEffect(() => {
    if (ideaOrigin && subSystemId) {
      formik.setFieldValue("origin", ideaOrigin);
      formik.setFieldValue("base", subSystemId);
    }
  }, [ideaOrigin, subSystemId]);

  React.useEffect(() => {
    API.get("/xcpep/top_vault/", {
      project: projectId,
      module: MODULES.IDEA_GENERATION,
      subSystem,
    }).then((res) => {
      const { data } = res;
      const competitorData: any =
        data &&
        data
          .filter((obj: any) => obj.id !== topVault)
          .map((obj: any) => ({ id: obj.id, value: obj.id, text: obj.name }));
      const permissionData: any =
        data &&
        data?.filter((obj: any) => obj.id == topVault)[0]?.allowed_action;
      setCompetitorOptions([...competitorData]);
    });
  }, []);

  React.useEffect(() => {
    getFeatures();
  }, [subSystemId]);

  const formik = useFormik({
    initialValues: {
      base: "",
      origin: "",
      idea_type: null,
      feature_type: 0,
      category: 0,
      reference: null,
      parameter: null,
      unit: "",
      sub_system: 0,
      cost: 0,
      weight: 0,
      competitor_model: null,
      competitor: null,
      duration: 0,
      ease: 0,
      owner: null,
      idea_level: "",
      duration_level: "",
      feature_param_type: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let obj = {
        
        
        origin: 3,
        //@ts-ignore
        idea_type: values.idea_type?.value,
        feature_type: values.feature_type,
        category: values.category,
        //@ts-ignore
        reference: values.reference?.value,
        //@ts-ignore
        parameter: values.parameter?.value,
        unit: values.unit,
        sub_system: values.sub_system,
        cost: values.cost,
        weight: values.weight,
        
        //@ts-ignore
        competitor: values.competitor?.value,
        duration: values.duration,
        ease: values.ease,
        owner: values.owner,
        idea_level: values.idea_level,
        duration_level: values.duration_level,
        //@ts-ignore
        feature_param_type: values.parameter?.type,
      };
      let newObj = {};
      if(subSystem === "PRODUCT"){
        newObj ={
          ...obj,
          base_veh: topVault,
        }
      }
      else{
        newObj ={
          ...obj,
          base: subSystemId,
        }
      }

      API.post("/idea/pool/", newObj, {}, 0)
        .then((res: any) => {
          ADMIN.toast.info("Idea Added Successfully");
          if(window.location.href.split("/")[4] == "idea-generation-view"){
            history.push(`/idea-generation-view/${projectId}/${projectName}/${topVault}/${subSystem}/${subSystemId}/0/0/${subSystemId}/0/0/${res.data?.id}/${res.data?.idea_id}`)
            }else{
            history.push(`/idea-generation/${projectId}/${projectName}/${topVault}/${system}/${res.data?.id}/${res.data?.idea_id}`)
            }
            OtherIdeaFormHandler();
            // onCloseModal();
        })
        .catch((err: any) => {
          const { data } = err.response;
          Swal.fire({
            title: "Error!",
            text: data[0],
            icon: "error",
            confirmButtonText: "Ok",
            customClass: {
              container: "swal2Container",
            },
          });
        });
    },
  });

  const getFilteredConfigOptions = () => {
    API.get(
      "/idea/idea_feature_parameter/",
      {
        project: projectId,
        //@ts-ignore
        feature: formik.values.reference?.value,
      },
      0
    )
      .then((res: any) => {
        setParameters(
          res.data.map((config: any) => ({
            key: config.param_id,
            value: config.param_id,
            text: config?.param_name,
            type: config?.type,
            unit: config?.unit,
          }))
        );
      })
      .catch((err: any) => {});
  };

  React.useEffect(() => {
    if (formik.values.reference) getFilteredConfigOptions();
  }, [formik.values.reference]);

  return (
    <div>
      {OtherIdeaModal.isOpen && (
        <OtherIdeaDetailsModal
          onCloseModal={OtherIdeaModal.close}
          isOpen={OtherIdeaModal.open}
          subSystem={subSystem}
          data={data}
          closeParentModal={onCloseModal}
          getTableData={getTableData}
          getUpdatedIdeaList={getUpdatedIdeaList}
        />
      )}
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            width: "100%",
            gap: "1rem",
            marginBottom: "1rem",
          }}
        >
          <FormControl 
            fullWidth
            variant="standard"
            sx={{ ".MuiFormLabel-asterisk": { color: "red" } }}
          >
            <InputLabel
              sx={{ color: "primary.main" }}
              shrink
              id="demo-simple-select-label"
            >
              Idea Type<sup style={{color:"red", marginTop:"0.5rem", marginLeft:"0.4rem"}}>*</sup>
            </InputLabel>
            <Autocomplete
              id="idea_type"
              autoSelect
              getOptionLabel={(option: any) => option.text}
              value={formik.values.idea_type}
              onChange={(event: any, newValue: any | null) => {
                return formik.setFieldValue("idea_type", newValue);
              }}
              options={IdeaTypesList}
              sx={{ marginTop: "1.4rem" }}
              renderOption={(props, option) => (
                <li {...props} style={{ fontSize: '1rem' }}>
                  {option.text}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  name="idea_type"
                  sx={{
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "primary.light",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "primary.main",
                    },
                    ".MuiInputBase-root.MuiInput-root": {
                      fontSize: "1rem !important",
                    },
                    ".MuiSvgIcon-root": { color: "primary.main" },
                  }}
                />
              )}
            />
            <div className={styles.errormsg}>
              {formik.touched.idea_type && Boolean(formik.errors.idea_type) && (
                //@ts-ignore
                <p>{formik.errors.idea_type}</p>
              )}
            </div>
          </FormControl>
          <FormControl fullWidth variant="standard">
            <InputLabel
              sx={{ color: "primary.main" }}
              shrink
              id="demo-simple-select-label"
            >
              Select Feature<sup style={{color:"red", marginTop:"0.5rem", marginLeft:"0.4rem"}}>*</sup>
            </InputLabel>
            <Autocomplete
              id="reference"
              value={formik.values.reference}
              getOptionLabel={(option: any) => option.text}
              onChange={(event: any, newValue: any | null) => {
                return formik.setFieldValue("reference", newValue);
              }}
              autoSelect
              options={featureDropDown}
              sx={{ marginTop: "1.4rem" }}
              renderOption={(props, option) => (
                <li {...props} style={{ fontSize: '1rem' }}>
                  {option.text}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  name="reference"
                  sx={{
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "primary.light",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "primary.main",
                    },
                    ".MuiInputBase-root.MuiInput-root": {
                      fontSize: "1rem !important",
                    },
                    ".MuiSvgIcon-root": { color: "primary.main" },
                  }}
                />
              )}
            />
            <div className={styles.errormsg}>
              {formik.touched.reference && Boolean(formik.errors.reference) && (
                <p>{formik.errors.reference}</p>
              )}
            </div>
          </FormControl>
          <FormControl fullWidth variant="standard">
            <InputLabel
              sx={{ color: "primary.main" }}
              shrink
              id="demo-simple-select-label"
            >
              Parameter:<sup style={{color:"red", marginTop:"0.5rem", marginLeft:"0.4rem"}}>*</sup>
            </InputLabel>
            <Autocomplete
              id="parameter"
              autoSelect
              getOptionLabel={(option: any) => option.text}
              options={parameters}
              onChange={(event: any, newValue: any | null) => {
                formik.setFieldValue("parameter", newValue);
                formik.setFieldValue("unit", newValue?.unit);
              }}
              sx={{ marginTop: "1.4rem" }}
              renderOption={(props, option) => (
                <li {...props} style={{ fontSize: '1rem' }}>
                  {option.text}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  name="parameter"
                  sx={{
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "primary.light",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "primary.main",
                    },
                    ".MuiInputBase-root.MuiInput-root": {
                      fontSize: "1rem !important",
                    },
                    ".MuiSvgIcon-root": { color: "primary.main" },
                  }}
                />
              )}
            />
            <div className={styles.errormsg}>
              {formik.touched.parameter && Boolean(formik.errors.parameter) && (
                <p>{formik.errors.parameter}</p>
              )}
            </div>
          </FormControl>

          <FormControl fullWidth variant="standard">
            <TextField
              variant="standard"
              label="Unit"
              // autoFocus={autoFocusStatus}
              disabled={true}
              InputProps={{
                style: { color: "#007fff" },
              }}
              value={formik.values.unit}
              onChange={formik.handleChange}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="unit"
              name="unit"
              error={formik.touched.unit && Boolean(formik.errors.unit)}
              helperText={formik.touched.unit && formik.errors.unit}
            />
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: "1fr 1fr",
            width: "100%",
            gap: "1rem",
            marginBottom: "1rem",
          }}
        >
          <FormControl fullWidth variant="standard">
            <InputLabel
              sx={{ color: "primary.main" }}
              shrink
              id="demo-simple-select-label"
            >
              Competitor Models: <sup style={{color:"red", marginTop:"0.5rem", marginLeft:"0.4rem"}}>*</sup>
            </InputLabel>
            <Autocomplete
              id="competitor"
              options={competitorOptions}
              getOptionLabel={(option: any) => option.text}
              value={formik.values.competitor}
              onChange={(event: any, newValue: any | null) => {
                return formik.setFieldValue("competitor", newValue);
              }}
              autoSelect
              sx={{ marginTop: "1.4rem" }}
              renderOption={(props, option) => (
                <li {...props} style={{ fontSize: '1rem' }}>
                  {option.text}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  name="competitor"
                  sx={{
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "primary.light",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "primary.main",
                    },
                    ".MuiInputBase-root.MuiInput-root": {
                      fontSize: "1rem !important",
                    },
                    ".MuiSvgIcon-root": { color: "primary.main" },
                  }}
                />
              )}
            />
            <div className={styles.errormsg}>
              {formik.touched.competitor &&
                Boolean(formik.errors.competitor) && (
                  <p>{formik.errors.competitor}</p>
                )}
            </div>
          </FormControl>
          <FormControl 
            fullWidth
            variant="standard"
          >
            <TextField
              variant="standard"
              label="Implementation Time (In Days)"
              // autoFocus={autoFocusStatus}
              
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              required
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                ".MuiFormLabel-asterisk": { color: "red" },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="duration"
              name="duration"
              value={formik.values.duration}
              onChange={formik.handleChange}
              error={formik.touched.duration && Boolean(formik.errors.duration)}
              helperText={formik.touched.duration && formik.errors.duration}
            />
          </FormControl>
          <FormControl            
            fullWidth
            variant="standard"
          > 
            <TextField
              variant="standard"
              label="Implementation Ease (1-Easy to 10-difficult)"
              value={formik.values.ease}
              onChange={formik.handleChange}
              // autoFocus={autoFocusStatus}
              InputProps={{
                style: { color: "#007fff" },
              }}
              InputLabelProps={{
                shrink: true,
              }}
              required
              sx={{
                "& .Mui-error": {
                  color: "#d32f2f",
                  borderBottomColor: "#d32f2f",
                },
                ".MuiFormLabel-asterisk": { color: "red" } ,
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "& .MuiFormLabel-root.MuiInputLabel-root ": {
                  color: "primary.main",
                },
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
              }}
              id="ease"
              name="ease"
              error={formik.touched.ease && Boolean(formik.errors.ease)}
              helperText={formik.touched.ease && formik.errors.ease}
            />
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            columnGap: "1rem",
            margin: "1rem 0",
          }}
        >
          <FormControl fullWidth variant="standard">
            <InputLabel
              sx={{ color: "primary.main" }}
              shrink
              id="demo-simple-select-label"
            >
              Name
            </InputLabel>
            <Select
              id="owner"
              name="owner"
              labelId="demo-select-small"
              sx={{
                "&:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                  {
                    fontSize: "1rem",
                  },
                ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
              }}
              MenuProps={{
                style: {
                  maxHeight: 400,
                  color: "007fff",
                },
              }}
              value={formik.values.owner}
              onChange={formik.handleChange}
            >
              {owner &&
                owner?.map((item: any, index: any) => {
                  return (
                    <MenuItem
                      sx={{ color: "primary.main", fontSize: "1rem" }}
                      value={item?.value}
                    >
                      {item?.text}
                    </MenuItem>
                  );
                })}
            </Select>
            <div className={styles.errormsg}>
              {formik.touched.owner && Boolean(formik.errors.owner) && (
                <p>{formik.errors.owner}</p>
              )}
            </div>
          </FormControl>
          <FormControl 
            sx={{ ".MuiFormLabel-asterisk": { color: "red" } }}
            fullWidth
            variant="standard"
          >
            <InputLabel
              sx={{ color: "primary.main" }}
              shrink
              id="demo-simple-select-label"
            >
              Idea Level<sup style={{color:"red", marginTop:"0.5rem", marginLeft:"0.4rem"}}>*</sup>
            </InputLabel>
            <Select
              id="idea_level"
              name="idea_level"
              labelId="demo-select-small"
              sx={{
                "&:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                  {
                    fontSize: "1rem",
                  },
                ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
              }}
              MenuProps={{
                style: {
                  maxHeight: 400,
                  color: "007fff",
                },
              }}
              value={formik.values.idea_level}
              onChange={(event: any) => {
                const { value } = event.target;
                formik.setFieldValue("idea_level", value);
                formik.setFieldValue("duration_level", value);
              }}
            >
              {ideaLevelOptions &&
                ideaLevelOptions?.map((item: any) => {
                  return (
                    <MenuItem
                      sx={{ color: "primary.main", fontSize: "1rem" }}
                      value={item?.value}
                    >
                      {item?.text}
                    </MenuItem>
                  );
                })}
            </Select>
            <div className={styles.errormsg}>
              {formik.touched.idea_level &&
                Boolean(formik.errors.idea_level) && (
                  <p>{formik.errors.idea_level}</p>
                )}
            </div>
          </FormControl>
          <FormControl            
            fullWidth
            variant="standard"
          >
            <InputLabel
              sx={{ color: "primary.main" }}
              shrink
              id="demo-simple-select-label"
            >
              Duration
            </InputLabel>
            <Select
              id="duration_level"
              name="duration_level"
              labelId="demo-select-small"
              disabled={true}
              sx={{
                "&:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                  {
                    fontSize: "1rem",
                  },
                ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
              }}
              MenuProps={{
                style: {
                  maxHeight: 400,
                  color: "007fff",
                },
              }}
              value={formik.values.duration_level}
            >
              {durationOptions &&
                durationOptions?.map((item: any) => {
                  return (
                    <MenuItem
                      sx={{ color: "primary.main", fontSize: "1rem" }}
                      value={item?.value}
                    >
                      {item?.text}
                    </MenuItem>
                  );
                })}
            </Select>
            <div className={styles.errormsg}>
              {formik.touched.duration_level &&
                Boolean(formik.errors.duration_level) && (
                  <p>{formik.errors.duration_level}</p>
                )}
            </div>
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "flex-end",
            columnGap: "1rem",
            marginBottom: "0.5rem",
          }}
        >
          <Button variant="contained" size="small" onClick={onCloseModal}>
            Close
          </Button>
          <Button variant="contained" size="small" type="submit"
                    disabled={formik.values.base === null || formik.values.idea_type === null || formik.values.competitor === null || formik.values.reference === null || formik.values.duration < 0 || formik.values.ease < 1 || formik.values.ease > 10  || formik.values.idea_level === "" || formik.values.parameter === null || formik.values.unit === "" }

          >
            Next
          </Button>
        </Box>
      </form>
    </div>
  );
}
