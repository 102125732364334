import React from 'react';
import DataGrid, { Column, Export } from 'devextreme-react/data-grid';
import { Workbook } from 'exceljs';
//@ts-ignore
import { saveAs } from 'file-saver-es';
import Image from '../ViewModule/FeatureLandingPage/ArchitectureImageCard';
import loaderImage from "../Assets/images/loading-screen.gif";
import { exportDataGrid } from 'devextreme/excel_exporter';
import styles from "./ImagesExportToExcel.module.scss";
import { Box } from '@mui/material';
class ImagesExportToExcel extends React.Component {
    employees: any;
    topVaultId: any;
    routeTopVault: any;
    moduleName: any;
    constructor(props: any) {
        super(props);
        //@ts-ignore
        this.employees = this.props.data;
        //@ts-ignore
        this.topVaultId = this.props.topVaultId;
        //@ts-ignore
        this.routeTopVault = this.props.routeTopVault;
        //@ts-ignore
        this.moduleName = this.props.moduleName;
        this.onExporting = this.onExporting.bind(this);
    }

    render() {
        return (
            <div>
                <DataGrid
                    // headerHeight={48}
                    // rowHeight={36}
                    id="gridContainer"
                    style={{
                        justifyContent: "center",
                        objectFit: 'contain'
                    }}
                    dataSource={this.employees}
                    keyExpr="ID"
                    showBorders={true}
                    showRowLines={true}
                    showColumnLines={true}
                    onExporting={this.onExporting} >
                    {this.topVaultId.map((value: any, index: any) => {
                        return <Column dataField={`image${index++}`} cellRender={this.renderGridCell} caption={`${value.product_name} \n (${value.part_no})`} />;
                        // <Column dataField={ `image${index++}` } cellRender={ this.renderGridCell } caption={ `${value.product_name} \n ${value.component_name}\n(${value.part_no})` } />;
                    })
                    }
                    <Export enabled={true} />
                </DataGrid>
            </div>
        );
    }

    onExporting(e: any) {
        const workbook = new Workbook();
        const worksheet = workbook.addWorksheet('Main sheet');

        exportDataGrid({
            component: e.component,
            worksheet,
            autoFilterEnabled: true,
            customizeCell: ({ gridCell, excelCell }: any) => {
                if (gridCell.rowType === 'data') {
                    if (gridCell.column.dataField === 'image0' ||
                        gridCell.column.dataField === 'image1' ||
                        gridCell.column.dataField === 'image2' ||
                        gridCell.column.dataField === 'image3' ||
                        gridCell.column.dataField === 'image4') {
                        excelCell.value = undefined;

                        const image = workbook.addImage({
                            base64: gridCell.value,
                            extension: 'png',
                        });
                        const headerRow = worksheet.getRow(1);
                        headerRow.font = { color: { argb: '000000' }, italic: true };
                        // headerRow.fill = 
                        //@ts-ignore
                        headerRow.fill = { bgColor: { argb: "0000FF" } };
                        headerRow.height = 50;
                        worksheet.getRow(excelCell.row).height = 200;
                        excelCell._column.width = 60;
                        worksheet.addImage(image, {
                            //@ts-ignore
                            tl: { col: excelCell.col - 1, row: excelCell.row - 1 },
                            //@ts-ignore
                            br: { col: excelCell.col, row: excelCell.row },
                        });
                    }
                }




            },
        }).then(() => {
            workbook.xlsx.writeBuffer().then((buffer) => {
                saveAs(new Blob([buffer], { type: 'application/octet-stream' }), 'CompareImages.xlsx');
            });
        });
        e.cancel = true;
    }

    renderGridCell(cellData: any) {
        return (<Box sx={{ height: '15rem', width: 'auto', objectFit: 'contain', margin: '0 auto' }}><Image width="100%" height="100%" src={cellData.value} placeholderImg={loaderImage} style={{ objectFit: 'contain', margin: '0 auto' }} /></Box>);
    }
}

export default ImagesExportToExcel;
