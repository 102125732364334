import * as React from "react";
import {
  Badge,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Drawer,
  FormControlLabel,
  IconButton,
  Menu,
  MenuProps,
  Switch,
  Typography,
  alpha,
  styled,
} from "@mui/material";
import styles from "./CreateCostingPartLanding.module.scss";
import { CreateCostingPart } from "./CreateCostingPart";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import DescriptionIcon from "@mui/icons-material/Description";
import { CreateCostingDrawer } from "./CreateCostingDrawer";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import validated from "../../../Assets/images/Validated.svg";
import ToggleButton from "@mui/material/ToggleButton";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { API } from "../../../api-services";
import { AUTH } from "../../../Redux/Services/auth.service";
import DeleteIcon from "@mui/icons-material/Delete";
import arrowRight from "../../../Assets/images/rightArrowswhite.svg";
import scnarioprog from "../../../Assets/images/scenarioInProgress.svg";
import scnarioComp from "../../../Assets/images/scenarioDone.svg";
import Swal from "sweetalert2";
import swal from "sweetalert";
import { AxiosError, AxiosResponse } from "axios";
import { useDocumentTitle } from "../../../ui-reusable-component/useDocumentTitle";
import { useStage } from "spritespin";
import WariningModalFilesMail from "./WariningModalFilesMail";
import { useBaseModal } from "../../SearchPage/useBaseModal";
import config from "../../../Assets/images/configuration.svg";
import AccountTreeIcon from '@mui/icons-material/AccountTree';

interface ICreateCostingPartLandingProps { }

interface Params {
  projectId: any;
  projectname: any;
  product_name: any;
  productId: any;
}

type Anchor = "top" | "left" | "bottom" | "right";

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",

      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",

      horizontal: "left",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,

    border: "0.1px solid #007fff",

    minWidth: 150,

    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],

    "& .MuiMenu-list": {
      padding: "4px 0",
    },

    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,

        color: theme.palette.text.secondary,

        marginRight: theme.spacing(1.5),
      },

      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,

          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export function CreateCostingPartLanding(
  props: ICreateCostingPartLandingProps
) {
  const label = { inputProps: { 'aria-label': 'Switch demo' } };
  const {
    projectId,
    projectname,
    productId,
    topVault,
    vault,
    abbrebation,
    scenarioId,
    cardtype,
    calcultorId,
    calctype,
    condition,
    tableCounter,
    costEstimationId,
    tableId,
    newUI
  } = useRouteParams<any>();
  useDocumentTitle("Zero-based Costing");
  const history = useHistory();
  const [hierarchyData, setHierarchyData] = React.useState<any>({});
  const [anchorElRedirect, setAnchorElRedirect] =
    React.useState<null | HTMLElement>(null);

  const openRedirect = Boolean(anchorElRedirect);
// console.log(tableCounter, "tableCounter");

  const [redirectData, setRedirectData] = React.useState<any>([]);
  const [PermissionData, setPermissionData] = React.useState<any>();
  const [CalulatorList, setCalulatorList] = React.useState<any>()

  const ErrorModalFiles = useBaseModal()
  const [hierarchyDataLoading, setHierarchyDataLoading] =
    React.useState<any>(false);
  const [costData, setCostData] = React.useState<any>({});
  const [allProducts, setAllProducts] = React.useState<any>([]);
  const [ValidationLoader, setValidationLoader] = React.useState<any>(false)
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [openBox, setOpenBox] = React.useState<any>(false);
  const getObj = (val: any) => {
    if (val === "dc") {
      return { dc_validation: true };
    } else {
      return { bop_validation: true };
    }
  };

  const [anchorEll, setAnchorEll] = React.useState(null);

  const handleClickk = (event: any) => {
    setAnchorEll(event.currentTarget);
  };

  const handleClosee = () => {
    setAnchorEll(null);
  };

  const handleViewClick = () => {
    handleClosee();
    window.open(
      `/#/view/${projectId}/${topVault}/${vault}/${abbrebation === "none" ? "null" : abbrebation
      }/true`,
      "_blank",
      "noopener noreferrer"
    );
  };

  const handleCreateClick = () => {
    handleClosee();
    // Add your create logic here
    console.log("Redirect to BOM Create");
  };

  const [status, setStatus] = React.useState("");
  const [costingStatus, setCostingStatus] = React.useState<any>("");
  const [count, setCount] = React.useState<any>(0);
  const [treeCount, setTreeCount] = React.useState<any>(1);
  const getAllTopVaults = () => {
    API.get(
      "/auth/costing_product_list/",
      {
        project: projectId,
        top_vault: topVault,
        costing_create: true,
      },
      0
    )
      .then((res: any) => {
        setAllProducts(res.data);
      })
      .catch((err: any) => { });
  };

  const getObjj = () => {
    if (vault == 0) {
      return { top_vault: topVault };
    } else {
      return { vault: vault };
    }
  };
  const getTableData = () => {
    API.get("/cost/scenario_table/", { ...getObjj() }, 0)
      .then((res: any) => {
        setRedirectData(res?.data);
      })
      .catch((err: any) => {
        console.log("Sever Error");
      });
  };

  React.useEffect(() => {
    getTableData();
  }, [vault, topVault, scenarioId]);

  const handleClickRedirect = async (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElRedirect(event.currentTarget);
  };

  const handleCloseRedirect = () => {
    setAnchorElRedirect(null);
  };

  React.useEffect(() => {
    getAllTopVaults();
  }, []);
  const handleChange = (event: SelectChangeEvent) => {
    // console.log(event.target.value);
    if (event.target.value === 'bop') {
      Swal.fire({
        icon: "warning",
        title: "Are you Sure?",
        text: 'Changing the costing type to BOP will result in the deletion of entire Cost Calculation. Are you sure you want to proceed?',
        showCancelButton: true,
        showConfirmButton: true,
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          API.put(
            `cost/update_estimation/${costData?.cost_estimation}/`,
            {
              ...getObj(event.target.value),
            },
            {},
            0
          )
            .then((res: any) => {
              setStatus(event.target.value);
              setCount((prev: any) => prev + 1);
              history.push(`/createcostingpart/${projectId}/${projectname}/${productId}/${topVault}/${vault}/${abbrebation}/0/0/0/0/0/0/0/0/0/0/${newUI}`);
            })
            .catch((err: any) => {
              const { data }: any = err?.response;
              Swal.fire({
                icon: "error",
                html: `<div>
                            <br />
                            <p style="color:"red">${data[0]}</p>   
                          </div>`,
              });
            });
        }
      });
    } else {
      API.put(
        `cost/update_estimation/${costData?.cost_estimation}/`,
        {
          ...getObj(event.target.value),
        },
        {},
        0
      )
        .then((res: any) => {
          setStatus(event.target.value);
          setCount((prev: any) => prev + 1);
        })
        .catch((err: any) => {
          const { data }: any = err?.response;
          Swal.fire({
            icon: "error",
            html: `<div>
                        <br />
                        <p style="color:"red">${data[0]}</p>   
                      </div>`,
          });
        });
    }
  };
  const handleCompletePopUp = (close: any) => {
    API.get(`cost/unmapped_calculator/`, { vault: vault }).then((res: any) => {


      if (res?.data?.status == true) {
        setCalulatorList(res?.data?.error)
        ErrorModalFiles.open()
        close();
        // API.put(
        //   `/cost/update_estimation/${costData?.cost_estimation}/`,
        //   { [event.target.value]: true },
        //   {},
        //   0
        // )
        //   .then((res: any) => {
        //     setCostingStatus(event.target.value);
        //     setTreeCount((prev: any) => prev + 1);
        //     // setCount((prev: any) => prev + 1);
        //   })
        //   .catch((err: any) => {
        //     const { data }: any = err?.response;
        //     Swal.fire({
        //       icon: "error",
        //       html: `<div>
        //                 <br />
        //                 <p style="color:"red">${data[0]}</p>   
        //               </div>`,
        //     });
        //   });     
      }
      else {
        setValidationLoader(true)
        API.put(
          `/cost/update_estimation/${costData?.cost_estimation}/`,
          { validated: true },
          {},
          0
        )
          .then((res: any) => {
            setCostingStatus("validated");
            setTreeCount((prev: any) => prev + 1);
            setValidationLoader(false)
            history.push(`/createcostingpart/${projectId}/${projectname}/${productId}/${topVault}/${vault}/${abbrebation}/${cardtype}/${tableId}/${costEstimationId}/${tableCounter}/${Math.random()}/${calctype}/${calcultorId}/${scenarioId}/0/${newUI}`)
            close();
            // setCount((prev: any) => prev + 1);
          })

          .catch((err: any) => {
            const { data }: any = err?.response;
            setValidationLoader(false)
            Swal.fire({
              icon: "error",
              html: `<div>
                        <br />
                        <p style="color:"red">${data[0]}</p>   
                      </div>`,
            });
          });
      }
    }).catch((err: any) => {
      console.log("Server Error")
    })
  }
  const handleChangeCostingStatus = (event: SelectChangeEvent) => {


    if (event.target.value == "validated") {
      API.get(`cost/unmapped_calculator/`, { vault: vault }).then((res: any) => {


        if (res?.data?.status == true) {
          setCalulatorList(res?.data?.error)
          ErrorModalFiles.open()
          // API.put(
          //   `/cost/update_estimation/${costData?.cost_estimation}/`,
          //   { [event.target.value]: true },
          //   {},
          //   0
          // )
          //   .then((res: any) => {
          //     setCostingStatus(event.target.value);
          //     setTreeCount((prev: any) => prev + 1);
          //     // setCount((prev: any) => prev + 1);
          //   })
          //   .catch((err: any) => {
          //     const { data }: any = err?.response;
          //     Swal.fire({
          //       icon: "error",
          //       html: `<div>
          //                 <br />
          //                 <p style="color:"red">${data[0]}</p>   
          //               </div>`,
          //     });
          //   });

        }
        else {
          setValidationLoader(true)
          API.put(
            `/cost/update_estimation/${costData?.cost_estimation}/`,
            { [event.target.value]: true },
            {},
            0
          )
            .then((res: any) => {
              setCostingStatus(event.target.value);
              setTreeCount((prev: any) => prev + 1);
              setValidationLoader(false)
              history.push(`/createcostingpart/${projectId}/${projectname}/${productId}/${topVault}/${vault}/${abbrebation}/${cardtype}/${tableId}/${costEstimationId}/${tableCounter}/${Math.random()}/${calctype}/${calcultorId}/${scenarioId}/0/${newUI}`)
              // setCount((prev: any) => prev + 1);
            })
            .catch((err: any) => {
              const { data }: any = err?.response;
              setValidationLoader(false)
              Swal.fire({
                icon: "error",
                html: `<div>
                          <br />
                          <p style="color:"red">${data[0]}</p>   
                        </div>`,
              });
            });
        }
      }).catch((err: any) => {
        console.log("Server Error")
      })


    }

    else {
      setValidationLoader(true)
      API.put(
        `/cost/update_estimation/${costData?.cost_estimation}/`,
        { [event.target.value]: true },
        {},
        0
      )
        .then((res: any) => {
          setCostingStatus(event.target.value);
          setTreeCount((prev: any) => prev + 1);
          setValidationLoader(false)
          // setCount((prev: any) => prev + 1);
        })
        .catch((err: any) => {
          const { data }: any = err?.response;
          setValidationLoader(false)
          Swal.fire({
            icon: "error",
            html: `<div>
                      <br />
                      <p style="color:"red">${data[0]}</p>   
                    </div>`,
          });
        });
    }

  };

  React.useEffect(() => {
    if (Object.keys(costData)?.length > 0) {
      setStatus(costData?.costing_type);
      setCostingStatus(costData?.costing_status);
    }
  }, [costData]);

  const getHierarchyData = () => {
    setHierarchyDataLoading(true);
    API.get(
      "/cost/cost_hierachy/",
      {
        top_vault: topVault,
      },
      0
    )
      .then((res: any) => {
        setHierarchyData(res?.data);
        setHierarchyDataLoading(false);
      })
      .catch((err: any) => {
        setHierarchyDataLoading(false);
      });
  };

  React.useEffect(() => {
    API.get(
      "/auth/calibration_user_permission/",
      {
        action: true,
        costing_create: true,
        vault: vault,
        scenario: scenarioId ? scenarioId : undefined,
      },
      0
    )
      .then((res: any) => {
        setPermissionData(res?.data);
      })
      .catch((err: any) => {
        console.log("Srver Error");
      });
  }, [vault]);

  React.useEffect(() => {
    if (openBox === true) {
      getHierarchyData();
    }
  }, [openBox]);

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }
        setState({ ...state, [anchor]: open });
        setOpenBox(open);
      };

  const list = (anchor: Anchor) => (
    <Box
      sx={{
        minWidth: 300,
        width: anchor === "top" || anchor === "bottom" ? "auto" : "auto",
      }}
      role="presentation"
    // onClick={toggleDrawer(anchor, false)}
    // onKeyDown={toggleDrawer(anchor, false)}
    >
      <CreateCostingDrawer
        hierarchyData={hierarchyData}
        hierarchyDataLoading={hierarchyDataLoading}
      />
    </Box>
  );

  const handleClick = (dataAbbr: any) => {
    if (scenarioId) {
      history.push(
        `/costingscenario/${projectId}/${projectname}/${topVault}/${vault}/1/${abbrebation}/0`
      );
    } else {
      dataAbbr &&
        history.push(
          `/costingabbrlanding/${projectId}/${projectname}/${topVault}/${allProducts?.toString()}/${true}/${topVault}/${productId}/${dataAbbr?.parent_abbreviation
            ? dataAbbr?.parent_abbreviation
            : "none"
          }/${dataAbbr?.parent_id}`
        );
    }
    // history.goBack();
  };
  const guidelinesHandler = () => {
    window.open(`/#/costingguidelines/${projectId}/${projectname}`, "_blank");
    // history.push(`/costingguidelines/${projectId}/${projectname}`);

    // setDownloadLoader(true);
    // try {
    //   // API.apiBase + `cost/generate_cost_guide_line/?project=${projectId}&user=${AUTH.user}`

    //   fetch(
    //     API.apiBase +
    //       `cost/generate_cost_guide_line/?project=${projectId}&user=${AUTH.user}`,
    //     {
    //       method: "GET",
    //     }
    //   ).then((resp) => window.open(resp.url));
    // } catch (e) {
    //   setDownloadLoader(false);
    // }
  };

  const redirectScenario = () => {
    if (vault == 0) {
      sessionStorage.removeItem("nodeCostingScenario");
      sessionStorage.setItem("nodeCostingScenario", JSON.stringify([topVault]));
      sessionStorage.setItem("addScenarioStatus", "3");
      window.open(
        `/#/costingscenario/${projectId}/${projectname}/${topVault}/${vault}/1/${abbrebation}/0`
      );
    } else {
      API.get(`xcpep/vault_info/`, { vault: vault }, 0)
        .then((res: AxiosResponse) => {
          sessionStorage.removeItem("nodeCostingScenario");
          sessionStorage.setItem(
            "nodeCostingScenario",
            JSON.stringify(res?.data?.[0]?.ancestors)
          );
          sessionStorage.setItem("addScenarioStatus", "3");
          window.open(
            `/#/costingscenario/${projectId}/${projectname}/${topVault}/${vault}/1/${abbrebation}/0`
          );
        })
        .catch((err: AxiosError) => { });
    }
  };

  return (
    <div>
      <Box
        sx={{
          padding: "0 0.5rem",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
        }}
      >


        {ErrorModalFiles?.isOpen && <WariningModalFilesMail
          isOpen={ErrorModalFiles.isOpen}
          onCloseModal={ErrorModalFiles.close}
          ErrorMsg={CalulatorList}
          setCostingStatus={setCostingStatus}
          setTreeCount={setTreeCount}
          costData={costData}
        />
        }
        <Box
          sx={{
            display: "flex",
            columnGap: "1rem",
            alignItems: "center",
            width: "100%",
            maxWidth: { lg: "43.8vw", xl: "38vw" },
            height: '1.5rem !important'
          }}
        >
          {Object.keys(costData)?.length > 0 ? (
            <IconButton
              sx={{ cursor: "pointer" }}
              onClick={() => handleClick(costData)}
            >
              <ArrowBackIcon color="primary" titleAccess="Go Back" />
            </IconButton>
          ) : (
            <IconButton
              sx={{ cursor: "pointer" }}
            // onClick={() => handleClick(costData)}
            >
              <ArrowBackIcon color="primary" titleAccess="Go Back" />
            </IconButton>
          )}
          <Typography sx={{ fontSize: "1rem", whiteSpace: "nowrap" }}>
            {projectname} Estimate {scenarioId != 0 && "Scenario"} Cost -{" "}
            {productId}
            {">"}
            {scenarioId ? (
              <span style={{ textTransform: "capitalize" }}>
                {costData?.parent_name} {">"} {costData?.scenario_name}(
                {costData?.scenario_type})
              </span>
            ) : (
              <span style={{ textTransform: "capitalize" }}>
                {costData?.parent_name}
              </span>
            )}
          </Typography>
        </Box>
        {newUI == "false" && <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "1rem",
            justifyContent: "space-between",
            paddingRight: "1rem",
            width: "100%",
          }}
        >
          <Box>
            {/* <FormControlLabel control={<Switch checked={newUI == "true"} />} label={newUI =="true"?"New UI":"Old UI"} onChange={()=>{
            // /createcostingpart/:projectId/:projectname/:productId/:topVault/:vault/:abbrebation/:cardtype/:tableId/:costEstimationId/:tableCounter/:condition/:calctype/:calcultorId/:scenarioId/:conditionPopup/:newUI
             history.push(
              `/createcostingpart/${projectId}/${projectname}/${productId}/${topVault}/${vault}/${abbrebation}/${0}/${0}/${0}/${0}/${0}/${0}/${0}/${0}/${0}/${newUI == "true"?false:true}`
            );
          }} /> */}
          </Box>
          {scenarioId ? (
            <Box></Box>
          ) : newUI != "true" && (
            <Box
              sx={{ display: "flex", columnGap: "1rem", alignItems: "center" }}
            >
              Status: {ValidationLoader ? <CircularProgress size={17} /> : <>
                {costingStatus == "Not Started" ? (
                  <Box
                    sx={{
                      border: "2px solid #d32f2f",
                      color: "#d32f2f",
                      borderRadius: "5px",
                      height: { lg: "1.8rem", xl: "1.8rem" },
                      padding: "0.15rem 0.5rem",
                      width: { lg: "9.2rem", xl: "8rem", textAlign: "center" },
                    }}
                  >
                    {" "}
                    Not Started
                  </Box>
                ) : costingStatus == "In Progress" ? (
                  <FormControl
                    sx={{ minWidth: 110 }}
                    size="small"
                    variant="standard"
                  >
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={costingStatus}
                      disabled={
                        PermissionData && PermissionData?.action?.includes("U")
                          ? false
                          : true
                      }
                      label="Status"
                      onChange={handleChangeCostingStatus}
                      sx={{
                        "&:before": {
                          borderBottomColor: "transparent !important",
                          borderRadius: "5px",
                        },
                        "&:after": {
                          borderBottomColor: "transparent !important",
                          borderRadius: "5px",
                        },
                        "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                        {
                          fontSize: "1rem",
                          border: "2px solid",
                          borderRadius: "5px",
                          height: "1rem",
                          padding: "0.1rem 0.5rem 0",
                          color:
                            costingStatus == "Not Started"
                              ? "red"
                              : costingStatus == "In Progress"
                                ? "#ffc800"
                                : costingStatus == "completed"
                                  ? "#007fff"
                                  : costingStatus == "validated"
                                    ? "#008000"
                                    : "red", //status Color update
                        },
                        "& .MuiInputBase-root.MuiInput-root.MuiSelect-root:hover":
                        {
                          border: "2px solid",
                          borderColor: "red !important", //status Color update
                        },
                        ".MuiSvgIcon-root.MuiSelect-icon": {
                          color: "#ffc800",
                        },
                      }}
                    >
                      <MenuItem
                        sx={{ fontSize: "1rem", color: "red", display: "none" }}
                        value={"Not Started"}
                      >
                        Not Started
                      </MenuItem>
                      <MenuItem
                        sx={{
                          fontSize: "1rem",
                          color: "#ffc800",
                          display: "none",
                        }}
                        value={"In Progress"}
                      >
                        In Progress
                      </MenuItem>
                      <MenuItem
                        sx={{ fontSize: "1rem", color: "#007fff" }}
                        value={"completed"}
                      >
                        Completed
                      </MenuItem>
                      {
                        <MenuItem
                          sx={{
                            fontSize: "1rem",
                            color: "#008000",
                            display:
                              costData?.costing_status === "In Progress"
                                ? "none"
                                : "",
                          }}
                          value={"validated"}
                        >
                          Validated
                        </MenuItem>
                      }
                    </Select>
                  </FormControl>
                ) : costingStatus == "completed" ? (
                  <FormControl
                    sx={{ minWidth: 110 }}
                    size="small"
                    variant="standard"
                  >
                    <Select
                      labelId="demo-select-small-label"
                      id="demo-select-small"
                      value={costingStatus}
                      disabled={
                        PermissionData && PermissionData?.action?.includes("U")
                          ? false
                          : true
                      }
                      label="Status"
                      onChange={handleChangeCostingStatus}
                      sx={{
                        "&:before": {
                          borderBottomColor: "transparent !important",
                          borderRadius: "5px",
                        },
                        "&:after": {
                          borderBottomColor: "transparent !important",
                          borderRadius: "5px",
                        },
                        "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                        {
                          fontSize: "1rem",
                          border: "2px solid",
                          borderRadius: "5px",
                          height: "1rem",
                          padding: "0.1rem 0.5rem 0",
                          color:
                            costingStatus == "Not Started"
                              ? "red"
                              : costingStatus == "In Progress"
                                ? "#ffc800"
                                : costingStatus == "completed"
                                  ? "#007fff"
                                  : costingStatus == "validated"
                                    ? "#008000"
                                    : "red", //status Color update
                        },
                        "& .MuiInputBase-root.MuiInput-root.MuiSelect-root:hover":
                        {
                          border: "2px solid",
                          borderColor: "red !important", //status Color update
                        },
                        ".MuiSvgIcon-root.MuiSelect-icon": {
                          color: "primary.main",
                        },
                      }}
                    >
                      <MenuItem
                        sx={{ fontSize: "1rem", color: "red", display: "none" }}
                        value={"Not Started"}
                      >
                        Not Started
                      </MenuItem>
                      <MenuItem
                        sx={{
                          fontSize: "1rem",
                          color: "#ffc800",
                          display: "none",
                        }}
                        value={"In Progress"}
                      >
                        In Progress
                      </MenuItem>
                      {
                        <MenuItem
                          sx={{
                            fontSize: "1rem",
                            color: "#007fff",
                            display: costingStatus == "completed" ? "none" : "",
                          }}
                          value={"completed"}
                        >
                          Completed
                        </MenuItem>
                      }
                      {
                        <MenuItem
                          sx={{
                            fontSize: "1rem",
                            color: "#008000",
                            display:
                              costData?.costing_status === "In Progress"
                                ? "none"
                                : "",
                          }}
                          value={"validated"}
                        >
                          Validated
                        </MenuItem>
                      }
                    </Select>
                  </FormControl>
                ) : costingStatus == "validated" ? (
                  <Box
                    sx={{
                      border: "2px solid #008000",
                      color: "#008000",
                      borderRadius: "5px",
                      height: { lg: "2rem", xl: "1.85rem" },
                      padding: "0.15rem 0.5rem",
                      width: { lg: "9.2rem", xl: "8rem", textAlign: "center" },
                    }}
                  >
                    Validated
                  </Box>
                ) : (
                  ""
                )}
              </>}
            </Box>
          )}

          <Box
            sx={{ display: "flex", alignItems: "center", columnGap: "1rem" }}
          >
            {/* <AccountTreeIcon sx={{cursor:'pointer',fontSize:'1.5rem !important'}} onClick={()=>history.push(`/costingconfiguration-lock-flow/0/0/0/0`)} /> */}
            {costData && (
              <img
                title="Project Configuration"
                src={config}
                style={{
                  width: "1.5rem",
                  height: '1.5rem !important',
                  verticalAlign: "middle",
                  marginRight: "1rem",
                  // marginLeft: "4rem",
                  marginBottom: "0.5rem",
                  cursor: "pointer",
                }}
                // onClick={() =>
                //   history.push(
                //     `/costingconfiguration-template/${projectId}/${projectname}/${selectedCategory}`
                //   )
                // }
                onClick={() => {
                  if (costData?.costing_category) {
                    const newUrl = `/costingconfiguration-template/${projectId}/${projectname}/${costData?.costing_category}/${costData?.costing_category_group}/false/0/0/0`;
                    window.open(`${window.location.origin}/#${newUrl}`, "_blank");
                  } else {
                    Swal.fire({
                      icon: "error",
                      title: "Please select costing category",
                    });
                  }
                }}
              />
            )}
            
            <FormControlLabel control={<Switch checked={newUI == "true"} />} label={newUI == "true" ? "New UI" : "Old UI"} onChange={() => {
              history.push(
                `/createcostingpart/${projectId}/${projectname}/${productId}/${topVault}/${vault}/${abbrebation}/${0}/${0}/${0}/${0}/${0}/${0}/${0}/${0}/${0}/${newUI == "true" ? false : true}`
              );
            }} />
            <Button
              variant="contained"
              endIcon={<img src={arrowRight} style={{ height: "1.1rem" }} />}
              title="GO to BOM"
              sx={{
                padding: "0.4rem 0.7rem",
                minWidth: "1.5rem",
                lineHeight: 1,
                height: '1.5rem !important'
              }}
              onClick={() => {
                window.open(
                  `/#/view/${projectId}/${topVault}/${vault}/${abbrebation === "none" ? "null" : abbrebation
                  }/true`,
                  "_blank",
                  "noopener noreferrer"
                );
              }}
            // onClick={handleClickk}
            >
              BOM
            </Button>
            <Menu
              anchorEl={anchorEll}
              open={Boolean(anchorEll)}
              onClose={handleClosee}
            >
              <MenuItem onClick={handleViewClick}>Go to BOM View</MenuItem>
              <MenuItem onClick={handleCreateClick}>Go to BOM Create</MenuItem>
            </Menu>
            {scenarioId ? (
              <Box>
                <StyledMenu
                  id="demo-customized-menu"
                  MenuListProps={{
                    "aria-labelledby": "demo-customized-button",
                  }}
                  anchorEl={anchorElRedirect}
                  open={openRedirect}
                  onClose={handleCloseRedirect}
                >
                  {redirectData &&
                    redirectData
                      ?.filter((item: any) => item.id != scenarioId)
                      ?.map((item: any) => {
                        return (
                          <MenuItem
                            sx={{
                              fontSize: "1rem",

                              color: "primary.main",

                              padding: "0.2rem 0.5rem",
                            }}
                            // /view/:projectId/:topVault/:vault/:treeabbr/costing/</StyledMenu>
                            onClick={() =>
                              item?.name === "Main Costing"
                                ? window.open(
                                  `/#/view/${projectId}/${topVault}/${vault}/${abbrebation}/costing`
                                )
                                : window.open(
                                  `/#/createcostingpart/${projectId}/${projectname}/${item?.product_name}/${topVault}/${vault}/${abbrebation}/${cardtype}/${tableId}/${costEstimationId}/${tableCounter}/${condition}/${calctype}/${calcultorId}/${item?.id}/0/${newUI}`
                                )
                            }
                          >
                            <span
                              style={{
                                marginRight: "0.5rem",
                                whiteSpace: "nowrap",
                                textOverflow: "ellipsis",
                                overflow: "hidden",
                                width: "12rem",
                              }}
                              title={item.name}
                            >
                              {item?.name}
                            </span>

                            {/* <span
                            style={{ marginRight: "0.5rem", color: "green" }}
                          >
                            {item?.currency}
                          </span> */}

                            <span style={{ color: "green" }}>{item?.cost}</span>
                          </MenuItem>
                        );
                      })}
                </StyledMenu>
                <Button
                  variant="contained"
                  endIcon={
                    <img src={arrowRight} style={{ height: "1.1rem" }} />
                  }
                  title="Other Scenarios"
                  sx={{
                    padding: "0.4rem 0.7rem",
                    minWidth: "1.5rem",
                    lineHeight: 1,
                  }}
                  onClick={handleClickRedirect}
                >
                  Other Scenarios
                </Button>
              </Box>
            ) : (
              <DescriptionIcon
                titleAccess="Costing Guideline"
                sx={{ cursor: "pointer", fontSize: "1.5rem", height: '1.5rem !important' }}
                onClick={() => guidelinesHandler()}
              />
            )}

            {scenarioId ? (
              <Box></Box>
            ) : (
              <img
                src={scnarioprog}
                style={{ height: "1.5rem", cursor: "pointer" }}
                onClick={() => redirectScenario()}
              />
            )}
            {newUI != "true" && <FormControl
              sx={{ m: 1, minWidth: 60 }}
              size="small"
              variant="standard"
            >
              <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={status}
                disabled={
                  PermissionData && PermissionData?.action?.includes("U")
                    ? false
                    : true
                }
                label="BOP"
                onChange={handleChange}
                sx={{
                  display: scenarioId ? "none" : "",
                  "&:before": {
                    borderBottomColor: "primary.main",
                    borderRadius: "5px",
                  },
                  "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                  {
                    fontSize: "1rem",
                    border: "2px solid",
                    borderRadius: "5px",
                    height: "1rem",
                    padding: "0.1rem 0.5rem 0",
                  },
                  ".MuiSvgIcon-root.MuiSelect-icon": {
                    color: "primary.main",
                  },
                }}
              >
                <MenuItem sx={{ fontSize: "1rem" }} value={"bop"}>
                  BOP
                </MenuItem>
                <MenuItem sx={{ fontSize: "1rem" }} value={"dc"}>
                  DC
                </MenuItem>
              </Select>
            </FormControl>}
            {scenarioId ? (
              <Box></Box>
            ) : (
              <QrCodeScannerIcon
                titleAccess="Scan QR"
                sx={{ cursor: "pointer", height: '1.5rem !important', fontSize: '1.5rem' }}
                onClick={() =>
                  history.push(`/costingscanner/${projectId}/${projectname}`)
                }
              />
            )}

            {AUTH.isSuperUser && (
              <DeleteIcon
                titleAccess="Delete Costing"
                color="error"
                sx={{ fontSize: "1.5rem", cursor: "pointer", marginLeft: "3rem", height: '1.5rem !important' }}
                onClick={() => {
                  swal({
                    title: `Delete Costing`,
                    text: `Are you sure?`,
                    icon: "error",
                    //@ts-ignore
                    buttons: {
                      cancel: "Cancel",
                      permanent: {
                        text: `Delete Costing`,
                        // value: `deleteParameterLibrary`,
                      },
                    },
                    closeOnClickOutside: false,
                    closeOnEsc: false,
                    className: "delete-swal",
                  }).then(async (type: string) => {
                    if (type === "permanent") {
                      API.delete(
                        `/cost/update_estimation/${costData?.cost_estimation}/`
                      )
                        .then((res: any) => {
                          setCount((prev: any) => prev + 1);
                        })
                        .catch((err: any) => {
                          const { data }: any = err?.response;
                          Swal.fire({
                            icon: "error",
                            html: `<div>
                                      <br />
                                      <p style="color:"red">${data[0]}</p>   
                                    </div>`,
                          });
                        });
                    } else if (type == "Cancel") {
                      // @ts-ignore
                      swal.close();
                    }
                  });
                }}
              />
            )}
          </Box>
        </Box>}
      </Box>
      <Box sx={{ display: "flex", alignItems: "flex-start", width: "100%" }}>
        <Box className={styles.expandIconBox}>
          {scenarioId ? (
            <Box></Box>
          ) : (
            <div className={styles.iconSec}>
              {(["left"] as const).map((anchor) => (
                <React.Fragment key={anchor}>
                  <IconButton
                    size="medium"
                    sx={{
                      backgroundColor: "primary.light",
                      color: "primary.main",
                    }}
                    onClick={toggleDrawer(anchor, true)}
                  >
                    <AddIcon sx={{ fontSize: "1.7rem" }} />
                  </IconButton>
                  <Drawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                    className={styles.drawerContainer}
                    PaperProps={{
                      sx: {
                        backgroundColor: "aliceblue",
                      },
                    }}
                  >
                    <Box
                      sx={{ padding: "0.5rem 0", position: "sticky", top: "0" }}
                      adl-scrollbar="true"
                      adl-scrollbar-width="0.3"
                    >
                      <Typography
                        style={{
                          width: "100%",
                          textAlign: "center",
                          fontSize: "1rem",
                        }}
                      >
                        Select Part
                      </Typography>
                      <Divider sx={{ margin: "0.2rem" }} />
                      {list(anchor)}
                    </Box>
                  </Drawer>
                  <Typography
                    sx={{ fontSize: "1rem", color: "primary.main" }}
                    onClick={toggleDrawer(anchor, true)}
                    className={styles.sideTitle}
                  >
                    Selection Options
                  </Typography>
                </React.Fragment>
              ))}
            </div>
          )}
        </Box>
        <Box
          sx={{
            width: { xs: "80vw", sm: "96%", md: "96%", lg: "96%", xl: "97%" },
            height: { xs: "87vh", lg: "86vh", xl: "90vh" },
            paddingRight: "0.5rem",
            overflow: { xs: "auto", lg: "visible", xl: "visible" },
          }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3"
        >
          {vault != 0 && (
            <CreateCostingPart
              setCostData={setCostData}
              count={count}
              Cost_status={costData?.costing_status}
              getHierarchyData={getHierarchyData}
              treeCount={treeCount}
              handleChangeCostingStatus={handleCompletePopUp}
            />
          )}
        </Box>
      </Box>
    </div>
  );
}
