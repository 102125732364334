// react
import { Box, Divider, Modal, Typography } from "@mui/material";
import React, { useEffect, useRef } from "react";
import UseImageConverter from "../../../../../../ui-reusable-component/UseImageConverter";
import ImagesExportToExcel from "../../../../../../ui-reusable-component/ImagesExportToExcel";
import { useRouteMatch } from "react-router-dom";
import styles from "./MediaExcelModalDownload.module.scss";
import CancelIcon from '@mui/icons-material/Cancel';

// ----------------------------------------------------------------------------------


interface MediaExcelModalDownloadProps {
    isOpen: boolean;
    onClose: () => void;
    topVaultId?: any;
    range?: any;
    imagesData?: any;
    result?: any;
    setOpenMediaConsolidatedDownload?:any;
}

const MediaExcelModalDownload: React.FC<MediaExcelModalDownloadProps> = props => {
    const { isOpen, onClose, topVaultId, range, imagesData, result, setOpenMediaConsolidatedDownload } = props;
    const imageRef = useRef({ imageList: [], topVault: [] });
    const { url } = useRouteMatch();
    UseImageConverter(topVaultId, imageRef, result, range, imagesData);
    
    useEffect(() => {

        // returned function will be called on component unmount 
        return () => {
            imageRef.current.imageList = [];
            imageRef.current.topVault = [];
        };
    }, [isOpen]);

    const handleCloseExcelModel = () => {
        onClose();
        url?.includes('mediacreateinternal') && setOpenMediaConsolidatedDownload(false);
    }

    return (
        <div >
            <Modal
                open={isOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className={imageRef.current.imageList.length && imageRef.current.topVault.length ? styles.image : styles.imagess}>
                    <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
                        <Typography
                            style={{
                                fontSize: "1rem",
                                fontWeight: "600",
                                width: "100%",
                                textAlign: "center",
                                marginRight: '-2rem'
                            }}
                        >
                            {url.includes("architecturecreateinternal") ?
                                <Typography sx={{fontSize:'1rem'}} id="modal-modal-title" variant="h6" component="h2">
                                    Architecture Download in Excel 
                                </Typography>
                                : <Typography sx={{fontSize:'1rem'}} id="modal-modal-title" variant="h6" component="h2">
                                    Images Download in Excel
                                </Typography>}
                        </Typography>
                        <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={handleCloseExcelModel} />
                    </Box>
                    <Divider
                        sx={{
                            borderColor: "primary.light",
                            margin: "0 1rem",
                            marginBottom: "1rem",
                        }}
                    />

                    <Typography id="modal-modal-description" sx={{ m: 2, mt: 0 }}>
                        <Box className={styles.excelpopBox}
                         adl-scrollbar="true"
                         adl-scrollbar-width="0.3">
                            {/* @ts-ignore */}
                            {imageRef.current.imageList.length && imageRef.current.topVault.length && <ImagesExportToExcel data={result.current.data.filter((data) => Object.keys(data).length !== 0)} topVaultId={imageRef.current.topVault} />}
                        </Box>
                    </Typography>
                </Box>
            </Modal>
        </div>
    );
};

// ----------------------------------------------------------------------------------

export default MediaExcelModalDownload;
