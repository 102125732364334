import { ParameterConfig } from "../../Redux/Reducers/parameterConfig.reducer";
import { ID } from "../../utlis/commonInterfaces";
import { isFloat } from "../../utlis/other";

const validateValues = (
    configuration: ParameterConfig[],
    values: Record<string, any>,
    allowedParameters?: ID[],
    key: "parameter_id" | "parameter_table_id" = "parameter_id"
) => {
    const errors: {
        /** parameter config id */
        [parameter_id: string]: string;
    } = {};
    configuration.forEach((p: any) => {
        if (
            (Array.isArray(allowedParameters) &&
                allowedParameters.includes(p[key])) ||
            !allowedParameters
        ) {
            const val = values[p[key]];
            if (
                p.required &&
                (val === undefined ||
                    val === null ||
                    val.length === 0 ||
                    (!values[`checkbox-${p[key]}`] &&
                        p.exceptions.includes(val)))
                // || ( val === 0 && !p.allowed_values.includes( 0 ) )
            ) {
                // ---------------------------------try lodash
                errors[p[key]] = `"${p.name}" is required.`;
            } else if (!p.required && !val) {
                // if not required and val not present
                // just skip,
            } else if (p.data_type?.includes("nonzero") && val === 0) {
                errors[p[key]] = `"${p.name}" should be non zero value`;
            } else if (p.data_type?.includes("positive") && val < 0) {
                errors[p[key]] = `"${p.name}" should be positive`;
            } else if (p.data_type?.includes("negative") && val > 0) {
                errors[p[key]] = `"${p.name}" should be negative`;
            } else if (p.data_type?.includes("int") && isFloat(val)) {
                errors[p[key]] = `"${p.name}" should be an integer`;
            } else if (p.data_type === "percentage" && (val < 0 || val > 100)) {
                errors[p[key]] = `"${p.name}" should be in range 0 to 100`;
            } else if (
                p.allowed_values.length !== 0 &&
                !values[`checkbox-${p[key]}`] && // exception not enabled
                !p.allowed_values.includes(val)
            ) {
                if (p.data_type !== "multiselect")
                    errors[p[key]] = `"${
                        p.name
                    }" should be one in: ${p.allowed_values.toString()}`;
            } else if (
                p.notAllowedValue &&
                p.notAllowedValue.length !== 0 &&
                p.notAllowedValue.includes(val)
            ) {
                if (p.data_type !== "multiselect")
                    errors[p[key]] = `"${
                        p.name
                    }" cant't be one in: ${p.notAllowedValue.toString()}`;
            } else if (
                p.exceptions.length !== 0 && // if exceptions list not empty
                values[`checkbox-${p[key]}`] && // type text allowed to enter in field
                !p.exceptions.includes(val) // throw error if value not in exceptions list
            ) {
                errors[p[key]] = `"${
                    p.name
                }" should be one in: ${p.exceptions.toString()}`;
            }
        }
    });
    return errors;
};

export default validateValues;
