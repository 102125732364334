import * as React from "react";
import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { RoleList } from "../../Constants/projectspecificmodules.constants";
import { API } from "../../api-services";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";

export interface IEditRoleProps {
  action: any;
  itemId?: any;
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
export interface IEditProductProps {
  selectedProducts: any;
  productList: any;
  itemId: any;
}

export function EditProduct(props: IEditProductProps) {
  const { selectedProducts, productList, itemId } = props;
  const { moduleId,projectId } = useRouteParams<any>();
  const [products, setProducts] = React.useState<any>([]);

  React.useEffect(() => {
    if (selectedProducts) {
      setProducts(selectedProducts);
    }
  }, [selectedProducts]);

  const handleSelectChangeProducts = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    if (value.length === 0) {
      setProducts((prev: any) => prev);
      return;
    }
    setProducts(value);
    API.put(
      "/auth/assign_permission/",
      {
        user: itemId,
        product: value,
        submodule: moduleId,
        project:projectId
      },
      {},
      0
    )
      .then((res: any) => {})
      .catch((err: any) => {});
  };
  return (
    <div>
      <FormControl fullWidth variant="standard">
        {/* <InputLabel id="demo-simple-select-label">
                          Select Products
                        </InputLabel> */}
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={products&&products}
          multiple
          multiline
          //   label="User"
          placeholder="Select Products"
          onChange={handleSelectChangeProducts}
          size="small"
          sx={{
            "&:before": {
              borderBottomColor: "primary.main",
            },
            ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
          }}
          renderValue={(selected) => (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 0.5,
              }}
            >
              {productList
                .filter((item: any) => selected?.includes(item?.id))
                ?.map((value: any) => (
                  <Chip
                    size="small"
                    key={value.id}
                    label={value.name}
                    onDelete={() => {
                      if (products.length > 1) {
                        setProducts((prev: any) =>
                          prev?.filter((item: any) => value.id !== item)
                        );
                        API.put(
                          "/auth/assign_permission/",
                          {
                            user: itemId,
                            product: products?.filter(
                              (item: any) => value.id !== item
                            ),
                            submodule: moduleId,
                            project:projectId
                          },
                          {},
                          0
                        )
                          .then((res: any) => {})
                          .catch((err: any) => {});
                      }
                    }}
                    deleteIcon={
                      <CancelIcon
                        onMouseDown={(event) => event.stopPropagation()}
                      />
                    }
                  />
                ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {productList &&
            productList?.map((item: any, index: number) => {
              return (
                !products?.includes(item?.id) && (
                  <MenuItem key={index} value={item?.id}>
                    {item?.name}
                  </MenuItem>
                )
              );
            })}
        </Select>
      </FormControl>
    </div>
  );
}
