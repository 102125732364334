import React, { useState } from 'react'


import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { TextField } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "fit-content",
  height: "8rem",
  bgcolor: 'background.paper',
  borderRadius: "0.5rem",
  padding: 1,
};

interface InwardPdfNameModalProps {


  isOpen: any;
  onCloseModal: any;
  SubmitPdfFile: any;
  FileData: any;
}

const InwardPdfNameModal = (props: InwardPdfNameModalProps) => {

  const { isOpen,
    onCloseModal, SubmitPdfFile, FileData } = props



  const [Filename, setFileName] = useState<any>(FileData && FileData)

  const handleChange = (e: any) => {
    setFileName(e?.target?.value)
  }
  const ClosedHandler = () => {
    onCloseModal()
    SubmitPdfFile(Filename)
  }


  console.log(Filename, "FilenameFilename")
  return (
    <div>


      <Modal
        open={isOpen}
        // close={ClosedHandler}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{width:'100%',textAlign:'right'}}>
          <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={ onCloseModal} />
                    </Box>
         <Box sx={{ margin: 'auto',alignItems: 'center',display: 'flex',padding:'0 2rem' }}>
            <label style={{ marginRight: '1rem' }}>File Name : </label>
            <TextField
              value={Filename}
              variant='standard'
              autoFocus
              onChange={(e: any) => handleChange(e)}
              placeholder='File Name'
              sx={{
                minWidth: '250px', marginLeft: '1rem',
                '& .MuiInput-underline:before': { borderBottomColor: '#007fff' },
                '& .MuiInput-underline:after': { borderBottomColor: '#007fff' },
              }} />
            <LoadingButton
              sx={{
                marginLeft: '1rem',
                minWidth: '1rem',
                '.MuiCircularProgress-root': {
                  color: 'primary.main',
                },
              }}>
              <SaveIcon onClick={() => ClosedHandler()} />
            </LoadingButton>
          </Box>
        </Box>
      </Modal>


    </div>
  )
}

export default InwardPdfNameModal;