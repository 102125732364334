const IMAGE_CATEGORY = {
    VEHICLE: 1,
    SUB_SYSTEM: 2,
    TEARDOWN: 3,
    PART: 4,
    OTHER: 5
};

const getImageCategoryList = ( isTopVault: boolean ) => {
    return isTopVault ? [
        { key: IMAGE_CATEGORY.VEHICLE, value: IMAGE_CATEGORY.VEHICLE, text: "Organised" },
        { key: IMAGE_CATEGORY.TEARDOWN, value: IMAGE_CATEGORY.TEARDOWN, text: "Teardown" },
        { key: IMAGE_CATEGORY.OTHER, value: IMAGE_CATEGORY.OTHER, text: "Other" },
    ] : [
        { key: IMAGE_CATEGORY.SUB_SYSTEM, value: IMAGE_CATEGORY.SUB_SYSTEM, text: "On Product" },
        { key: IMAGE_CATEGORY.TEARDOWN, value: IMAGE_CATEGORY.TEARDOWN, text: "Teardown" },
        { key: IMAGE_CATEGORY.OTHER, value: IMAGE_CATEGORY.OTHER, text: "Other" },
    ];
};

export {
    IMAGE_CATEGORY,
    getImageCategoryList
};