import {
  Autocomplete,
  Box,
  Chip,
  InputAdornment,
  Skeleton,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import swal from "sweetalert";
import React, { useEffect, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import ReplyIcon from "@mui/icons-material/Reply";
import { API } from "../../api-services";
interface OEMlistRowProps {
  rowItem: any;
  keys: any;
  
  vehicleList: any;
  setOemListCounter: any;
  InfoHandler: any;
  getPlantIds: any;
  searchkey:any;
   setSearchCount:any;
   setFilterTypecounter:any;
    FilterType:any;
    count:any;
    width:any;
 

}

const OEMlistRow = (props: OEMlistRowProps) => {
  const { rowItem, keys, vehicleList, setOemListCounter, InfoHandler, getPlantIds, searchkey,


    setFilterTypecounter,
    FilterType,
    setSearchCount,count,width } = props;
  const [Name, setName] = useState<any>();
  const [AboutUs, setAboutus] = useState<any>();
  const [ContactUs, setContactUs] = useState<any>();
  const [SelectedVehicle, setSelectedVehicle] = useState<any>( vehicleList && vehicleList?.filter((item: any, index: any) => { return rowItem && rowItem?.vehicle_type.includes(item?.id) }));
  const [Errorname, setErrorname] = useState<any>(false)
  const [ErrorAboutUs, setErrorAboutUs] = useState<any>(false)
  const [ErrorContactUs, setErrorContactUs] = useState<any>(false)
  const [vehicleError, setVehicleError] = useState<any>(false)
  const [loader, setLoader] = useState<any>(false)
  const [rowIds, setRowIds] = useState<any>()
  const [Color, setColor] = useState<any>(false)
  const [CounterData,setCounter]=useState<any>(1)

  useEffect(() => {
    if (rowItem !== undefined) {
      setName(rowItem?.name);
      setAboutus(rowItem?.supplier_details);

      setContactUs(rowItem?.contact_info);

      var filterseletedIds = vehicleList && vehicleList?.filter((item: any, index: any) => { return rowItem && rowItem?.vehicle_type.includes(item?.id) })


      setSelectedVehicle(filterseletedIds)
    }
  }, [rowItem,vehicleList]);

  console.log("FilterType",rowItem,vehicleList)
console.log(searchkey?.length,SelectedVehicle,"searchkeysearchkey",vehicleList)

  var OptionVehicle = () => {
    var selecteIdsdata = SelectedVehicle && SelectedVehicle.map((item: any, index: any) => { return item?.id })

    var filterseletedIds = vehicleList && vehicleList?.filter((item: any, index: any) => { return selecteIdsdata && !selecteIdsdata?.includes(item?.id) })
    return filterseletedIds;
  }


  const UpdateField = () => {

    if (rowItem?.name !== Name) {
      return { name: Name }
    } if (rowItem?.contact_info !== ContactUs) {
      return { contact_info: ContactUs }
    } if (rowItem?.supplier_details !== AboutUs) {
      return { supplier_details: AboutUs }
    }


  }


 

  const saveHandler = (Id: any, status: any) => {
    const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;

    var validatedUrlContact = urlRegex.test(ContactUs);
    var validatedUrlAbout = urlRegex.test(AboutUs);



    setRowIds(Id)

    if (status == true) {
    

      if (validatedUrlContact == true && validatedUrlAbout == true && Name?.length > 0 && ContactUs?.length > 0 && AboutUs?.length > 0 && SelectedVehicle?.length > 0) {
        setLoader(true)



        var Ids = SelectedVehicle && SelectedVehicle?.map((item: any) => { return item?.id })

        console.log(Ids, "Ids")
        API.post(`/api/db/supplier/`, {
          contact_info: ContactUs,
          name: Name,
          supplier_details: AboutUs,
          fasteners: false,
          is_oem: true,
          other_mfg_process: {},
          plants: {},
          mfg_type: [null],
          subsystem: [],
          miscellaneous: "",
          supplier_id: -1,
          vault: "",
          vehicle_type: Ids
        }).then((res: any) => {

          if(searchkey!==null ||  searchkey?.length!=0 || searchkey!==undefined){
            setSearchCount((prev: any) => prev + 1)
            setLoader(false)
            setColor(false)
          }
          if(FilterType=="filter"){
            setFilterTypecounter((pre:any)=>pre+1)
            setLoader(false)
            setColor(false)
          }
            
            
           
       else{

            setOemListCounter((prev: any) => prev + 1)
            setLoader(false)
            setColor(false)
          
          }
          
         
         
        }).catch((err: any) => {
          setLoader(false)
        })
      }


      else {
        if (validatedUrlContact == false) {
          setErrorContactUs(true)
        } if (validatedUrlAbout == false) {
          setErrorAboutUs(true)
        } if (Name == null || Name.length == 0) {
          setErrorname(true)
        } if (SelectedVehicle?.length == 0) {
          setVehicleError(true)
        }
      }
    }

    else {
      if (validatedUrlContact == true || validatedUrlAbout == true || Name?.length > 0 || ContactUs?.length > 0 || AboutUs?.length > 0 || SelectedVehicle?.length > 0) {
        setLoader(true)



        var Ids = SelectedVehicle && SelectedVehicle?.map((item: any) => { return item?.id })


        API.put(`/api/db/supplier/${Id}/`, {
          ...UpdateField(), vehicle_type: Ids
        }).then((res: any) => {
          if(searchkey!==null ||  searchkey?.length!=0 || searchkey!==undefined){
            setSearchCount((prev: any) => prev + 1)
            setLoader(false)
            setColor(false)
          }
          
          if(FilterType=="filter"){
              
           
            setFilterTypecounter((pre:any)=>pre+1)
            setLoader(false)
            setColor(false)
          }
          else
          {

            setOemListCounter((prev: any) => prev + 1)
            setLoader(false)
            setColor(false)
          }
        }).catch((err: any) => {
          setLoader(false)
        })
      }


      else {
        if (validatedUrlContact == false) {
          setErrorContactUs(true)
        } if (validatedUrlAbout == false) {
          setErrorAboutUs(true)
        } if (Name == null || Name.length == 0) {
          setErrorname(true)
        } if (SelectedVehicle?.length == 0) {
          setVehicleError(true)
        }
      }
    }
  }


  const DeleteHandlerdata = (Id: any) => {
    
    swal({

      text: "Are you sure you want to delete ?",
      buttons: ["No", "Yes"],
    }).then(confirm => {
      if (confirm) {
        // setRowIds(Id)
        setLoader(true)
      
        API.delete(`/api/db/supplier/${Id}`).then((res: any) => {
         
          if(searchkey!=null ||  searchkey?.length!=0 || searchkey!=undefined){
            setSearchCount((prev: any) => prev + 1)
            setLoader(false)
            
          }
          if(searchkey==null || searchkey==undefined)
          {
            setOemListCounter((prev: any) => prev + 1)
            setLoader(false)
          }
        })

       
      }

    });
  }

  const handleSelectProduct = (item: any) => {
    setColor(true)
    setSelectedVehicle(item)
  }


  const Handlechange = (e: any, type: any) => {

    if (type == "name") {
      setColor(true)
      setErrorname(false)
      setName(e?.target?.value)
    }
    if (type == "AboutUs") {
      setColor(true)
      setErrorAboutUs(false)
      setAboutus(e?.target?.value)
    }
    if (type == "ContactUs") {
      setColor(true)
      setErrorContactUs(false)
      setContactUs(e?.target?.value)
    }


  }

  const InfoHandlerData = (id: any) => {
    InfoHandler(id)
  }
  const PlantHandler = (id: any) => {
    getPlantIds(id)
  }

  var index=  width > 1400 ? count*19+keys:count*13+keys;
  return (

    // (loader && rowIds == rowItem?.id ?
    //   <TableRow>
    //     <TableCell sx={{ padding: '0.5rem 0.5rem', borderBottomColor: 'primary.light' }}>
    //       <Skeleton></Skeleton>
    //     </TableCell>
    //     <TableCell sx={{ padding: '0.5rem 0.5rem', borderBottomColor: 'primary.light' }}>
    //       <Skeleton></Skeleton>
    //     </TableCell>
    //     <TableCel  width > 1400 ? count*19+keys:count*13+keys;l sx={{ padding: '0.5rem 0.5rem', borderBottomColor: 'primary.light' }}>
    //       <Skeleton></Skeleton>
    //     </TableCell>
    //     <TableCell sx={{ padding: '0.5rem 0.5rem', borderBottomColor: 'primary.light' }}>
    //       <Skeleton></Skeleton>
    //     </TableCell>
    //     <TableCell sx={{ padding: '0.5rem 0.5rem', borderBottomColor: 'primary.light' }}>
    //       <Skeleton></Skeleton>
    //     </TableCell>
    //     <TableCell sx={{ padding: '0.5rem 0.5rem', borderBottomColor: 'primary.light' }}>
    //       <Skeleton></Skeleton>
    //     </TableCell>
    //   </TableRow> :
      <TableRow key={keys}>
        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>{index && index}</TableCell>
        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
          <TextField
            error={Errorname}
            id="input-with-icon-textfield"
            value={Name}
            focused={rowItem?.rowstatus}
            placeholder="name"
            onChange={(e: any) => Handlechange(e, "name")}
            variant="standard"
            sx={{
              width:'20vw',
              fontSize:'1rem',
              '& .MuiInput-underline:before': { borderBottomColor: 'white' },
              '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
            }}
          />
          {Errorname && <Typography sx={{ color: "red", fontSize: "0.8rem" }}>Please enter name</Typography>}
        </TableCell>
        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
          <TextField
            error={ErrorAboutUs}
            id="input-with-icon-textfield"
            value={AboutUs}
            focused={rowItem?.rowstatus}
            onChange={(e: any) => Handlechange(e, "AboutUs")}
            placeholder="About Us"
            helperText={ErrorAboutUs ? "*Please enter valid url" : ""}
            sx={{
              width:'20vw',
              fontSize:'1rem',
              '& .MuiInput-underline:before': { borderBottomColor: 'white' },
              '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  {AboutUs?.length > 0 &&
                    <ReplyIcon
                      sx={{
                        color:'primary.main',
                        cursor: "pointer",
                        fontSize: "1rem",
                        transform: "scaleX(-1)",
                      }}
                      onClick={() => window.open(AboutUs, "_blank")}
                    />
                  }

                </InputAdornment>
              ),
            }}
            variant="standard"
          />
        </TableCell>

        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
          <TextField
            id="input-with-icon-textfield"
            value={ContactUs}
            error={ErrorContactUs}
            focused={rowItem?.rowstatus}
            placeholder="Contact Us"
            helperText={ErrorContactUs ? "*Please enter valid url" : ""}
            onChange={(e: any) => Handlechange(e, "ContactUs")}
            sx={{
              textAlign:'1rem',
              width:{lg:'20vw',xl:'25vw'},
              '& .MuiInput-underline:before': { borderBottomColor: 'white' },
              '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">

                  {ContactUs?.length > 0 &&
                    <ReplyIcon
                      // titleAccess="Go to BOM"
                      sx={{
                        color:'primary.main',
                        cursor: "pointer",
                        fontSize: "16px",
                        transform: "scaleX(-1)",
                      }}
                      onClick={() => window.open(ContactUs, "_blank")}
                    />
                  }
                </InputAdornment>
              ),
            }}
            variant="standard"
          />
        </TableCell>

        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
          <Autocomplete
            multiple
            id="multiple-limit-tags"
            limitTags={3}
            placeholder="Select vehicle"
            disableCloseOnSelect
            //@ts-ignore
            focused={rowItem?.rowstatus}
            options={OptionVehicle() && OptionVehicle()}
            //@ts-ignore
            getOptionLabel={(option) => option.abbreviation}
            // defaultValue={[top100Films[13], top100Films[12], top100Films[11]]}
            sx={{
              '.MuiSvgIcon-root':{color:'primary.main'},	
            }}
            value={SelectedVehicle && SelectedVehicle}
            onChange={(event, newValue, situation) =>
              handleSelectProduct(newValue)
            }

            // @ts-ignore

            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  size="small"
                  sx={{ background: "#007fff14", fontSize: "1rem" }}
                  //@ts-ignore
                  label={option?.abbreviation}
                  {...getTagProps({ index })}
                // disabled={fixedOptions.indexOf(option) !== -1}
                />
              ))
            }
            renderOption={(props, option, { selected }) => (
              <li style={{ fontSize: "1rem" }} {...props}>
                {/*@ts-ignore */}
                {option?.abbreviation}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                // @ts-ignore 
                placeholder={"Select vehicle"}
                variant="standard"                
                sx={{
                  width:'18rem',
                  fontSize: "1rem",
                  '& .MuiInput-underline:before': { borderBottomColor: vehicleError?"red": 'white' },
                  '& .MuiInput-underline:after': { borderBottomColor: vehicleError?"red": 'primary.main' },                  
                }}
              />
            )}
          />
          {
            vehicleError && <Typography sx={{ color: "red", fontSize: "0.8rem" }}>Please select vehicle</Typography>
          }
         
        </TableCell>
        <TableCell sx={{ cursor: "pointer", padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light',verticalAlign:'bottom'  }} onClick={() => PlantHandler(rowItem?.id)}><Typography sx={{ borderBottom: "1px solid",textAlign: "center" }}>{Object.keys(rowItem?.plants)?.length}</Typography> </TableCell>
        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
          <SaveIcon sx={{ color: Color ? "red" : "#007fff", cursor: "pointer" }} onClick={() => saveHandler(rowItem?.id, rowItem?.rowstatus)} />
          {rowItem?.oem_mapped_status ?
            <InfoIcon sx={{ cursor: "pointer" }} onClick={() => InfoHandlerData(rowItem?.id)} />
            : <DeleteIcon sx={{ cursor: "pointer" }} onClick={() => DeleteHandlerdata(rowItem?.id)} />
          }
        </TableCell>
      </TableRow>
    // )
  );
};

export default OEMlistRow;
