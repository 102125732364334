// react
import React, { useEffect, useMemo, useState } from 'react';

// vendors
// import { Button, Checkbox, CheckboxProps, Icon, Modal, Table } from 'semantic-ui-react';
// import { useSelector } from 'react-redux';
// import { useParams } from 'react-router-dom';
// import { isUndefined, mapValues } from 'lodash';

// // services
// import processValuesService from 'app/services/processValues.service';
// import templateValuesService from 'app/services/templateValues.service';
// import { ADMIN } from 'app/services';

// // utils
// // constants
// import { CALCULATOR_INPUT_TYPE } from 'app/constants/calculator.constants';

// // factories
// // core
// import { useQueryDispatch } from 'app/Core/CustomHooks/useQueryDispatch';
// import useFetch from 'app/Core/CustomHooks/useFetch';

// // redux
// import { getParametersByTableId, resetParameters } from 'app/Redux/Actions/parameter.actions';
// import { Parameter, parameterSelectors } from 'app/Redux/Reducers/parameter.reducer';

// ui-components
// others
// styles
import styles from "./TableFieldRowSelectionModal.module.scss";
import { ID, ObjectType } from '../../../../../../utlis/commonInterfaces';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Parameter, parameterSelectors } from '../../../../../../Redux/Reducers/parameter.reducer';
import useFetch from '../../../../../../Core/CustomHooks/useFetch';
import { CALCULATOR_INPUT_TYPE } from '../../../../../../Configuration/Costing/calculator.constant';
import { isUndefined, mapValues } from 'lodash';
import { useQueryDispatch } from '../../../../../../CustomHook/useQueryDispatch';
import { getParametersByTableId, resetParameters } from '../../../../../../Redux/Actions/parameter.actions';
import { Box, Button, Modal, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import templateValuesService from '../../../../../../Redux/Services/templateValues.service';
import { useRouteParams } from '../../../../../../ui-reusable-component/useRouteParams';

// interfaces and types
// import { ID, ObjectType } from 'app/commonInterfaces';

// lazy

// ----------------------------------------------------------------------------------
// params
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: "60vw",
    maxWidth: '80vw',
    // height: '80vh',
    bgcolor: 'background.paper',
    borderRadius: '10px',
    p: 1,
  };




// ----------------------------------------------------------------------------------

interface TableFieldRowSelectionModalProps {
    isOpen: boolean;
    onClose: () => void;
    parameterId: ID;
    inputType: number;
    selectedRowIndex?: number;
    onSubmit: ( rowIndex: number ) => void;
}

const TableFieldRowSelectionModal: React.FC<TableFieldRowSelectionModalProps> = ( props ) => {
    const { isOpen, onClose, parameterId, inputType, onSubmit, selectedRowIndex } = props;
    const { vault, topVault, projectId, projectname, productId, abbrebation, cardtype, tableId, costEstimationId, tableCounter, condition, calctype, calcultorId } =
    useRouteParams<any>();
    // const params = useParams<Params>();
    // const { topVaultId, processId, vaultId } = mapValues( params, Number );

    // ----------------------------------------------------------------------------------
    // state

    const [selection, setSelection] = useState<number>();

    // ----------------------------------------------------------------------------------
    // selectors

    const parameters = useSelector( parameterSelectors.selectAll );

    // ----------------------------------------------------------------------------------
    // fetch values

    const { 1: valuesObject } = useFetch<Record<ID, any>>( {
        // @ts-ignore
        method: inputType === CALCULATOR_INPUT_TYPE.BOM_PARAMETER && templateValuesService.get ,
        args: inputType === CALCULATOR_INPUT_TYPE.BOM_PARAMETER && [{ topVault, vault }] ,
        fallBackErrMsg: "Could not fetch values",
        transformResData: ( data ) => data[0].details,
        condition: Boolean( inputType ),
        dependencies: [inputType]
    } );

    // ----------------------------------------------------------------------------------
    // memoiezed

    /** value of the table parameter */
    const value = useMemo( () => {
        return  valuesObject && valuesObject?.[parameterId] || [];
    }, [valuesObject, parameterId] );
    // console.log(parameterId && parameterId,value ,"parameter",valuesObject)
    // ----------------------------------------------------------------------------------
    // handlers

    const handleSelectionChange = ( e: React.SyntheticEvent ) => {
        // setSelection( ed.index );
    };

    const handelSubmit = () => {
        if ( isUndefined( selection ) ) return console.log( "Select a row" );

        onSubmit( selection );
    };

    // ----------------------------------------------------------------------------------
    // side effects

    // fetch column configs
    useQueryDispatch( {
        query: {
            action: getParametersByTableId,
            arg: { tableId: parameterId },
            condition: Boolean( parameterId )
        },
        cleanup: { action: resetParameters, args: [] },
        dependency: [parameterId]
    } );

    // sync already selected information
    useEffect( () => {
        if ( !isUndefined( selectedRowIndex ) ) setSelection( selectedRowIndex );
    }, [selectedRowIndex] );

    // reset state on close
    useEffect( () => {
        if ( isOpen ) return;

        setSelection( undefined );
    }, [isOpen] );

    // ----------------------------------------------------------------------------------
    // jsx abstraction

    const renderHeaderCell = ( parameter: Parameter ) => {
        return (
            <TableCell key={ parameter.id }>
                { parameter.name }   ({ parameter.unit ? parameter.unit : null })
            </TableCell>
        );
    };

    const renderRow = ( data: ObjectType, index: number ) => {
        return (
            <TableRow key={ index }>
                <TableCell >
                    {/* <Checkbox
                        checked={ index === selection }
                        index={ index }
                        onChange={ handleSelectionChange }
                    /> */}
                </TableCell>
                { parameters?.map( config => (
                    <TableCell key={ config.id }>{ data[config.id] }</TableCell>
                ) ) }
            </TableRow>
        );
    };

    // ----------------------------------------------------------------------------------
    // jsx

    return (
        <Modal
        open={isOpen}
        onClose={onClose}
      >
         <Box sx={style}>

         <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: '-2rem'
              }}
            >
          Select row for table field
            </Typography>
            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onClose} />
          </Box>

{value &&
          <><Box sx={{ height: '65vh', width: '100%' }}
                        adl-scrollbar="true"
                        adl-scrollbar-width="0.3">
                        <div className={styles.tablaContainer}>
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Select</TableCell>
                                        {parameters.map(renderHeaderCell)}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {value && value?.map(renderRow)}
                                </TableBody>
                            </Table>
                        </div>
                    </Box><Box>
                            <Button onClick={handelSubmit} variant="contained">Save</Button>
                        </Box></>
}
         </Box>
       
            
            
        </Modal>
    );
};

// ----------------------------------------------------------------------------------

export default TableFieldRowSelectionModal;