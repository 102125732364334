import { Box, TextField, TextareaAutosize, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import EditIcon from '@mui/icons-material/Edit';
import SaveIcon from '@mui/icons-material/Save';
import styles from './InwardInputCard.module.scss';
import { API } from '../../api-services';
import LoadingButton from '@mui/lab/LoadingButton/LoadingButton';
import swal from 'sweetalert';
import { Button } from 'devextreme-react';
interface InwardInputCardProps {
	DetailsItem: any;
	keys: any;
	UpdateState?: any;
	Parametername?: any;
	topVoultId?: any;
	intialState?: any;
	setCounter?: any;
	Premission: any;
}

const InwardInputCard = (props: InwardInputCardProps) => {
	const {
		DetailsItem,
		keys,
		UpdateState,
		intialState,
		Parametername,
		topVoultId,
		setCounter,
		Premission,
	} = props;

	const [Name, setName] = useState<any>(DetailsItem?.name);
	const [Loader, setLoader] = useState<any>(false);

	const [Status, setStatus] = useState<any>(DetailsItem?.status);
	const [error, setError] = useState<any>(false);
	const [btnstate, setbtnstate] = useState<any>('');
	useEffect(() => {
		setName(DetailsItem?.name);
		setStatus(DetailsItem?.status);
	}, [DetailsItem]);

	useEffect(() => {
		setbtnstate('');
	}, [DetailsItem]);

	const handlechange = (e: any) => {
		setName(e?.target?.value);
	};

	const EditHander = () => {
		setStatus(true);

		setbtnstate('Updated');
	};

	// console.log(Premission.includes("C") || Premission.includes("U") ,"vuduvudvuvgiu")
	const DeleteHandler = () => {
		swal({
			title: 'Delete',
			text: 'Are sure you want to delete ?',
			buttons: ['Cancel', 'Ok'],
			// icon: "sucess",
		}).then((confirm: any) => {
			if (confirm) {
				API.delete(
					'/vehicle_inward/inward_view/',
					{
						parameter_name: Parametername,
						top_vault_id: topVoultId,
						data: Name,
					},
					0
				).then((res: any) => {
					setCounter((prev: any) => prev + 1);
				});
			}
		});
	};

	const SaveHandler = () => {
		var testdata = /\S+/.test(Name);
		if (Parametername == 'product_details') {
			if (testdata == true) {
				setError(false);
				if (btnstate == 'Updated') {
					setLoader(true);
					API.put('/vehicle_inward/inward_view/', {
						parameter_name: Parametername,
						top_vault_id: topVoultId,
						old_data: DetailsItem?.name,
						new_data: Name,
					})
						.then((res: any) => {
							UpdateState(false);
							setLoader(false);
							setbtnstate('');
							setStatus(false);
							UpdateState(false);
							setCounter((prev: any) => prev + 1);
						})
						.catch((err: any) => {
							setbtnstate('');
							setLoader(false);
							console.log('server Error');
						});
				} else {
					setLoader(true);
					API.post('/vehicle_inward/inward_view/', {
						parameter_name: Parametername,
						top_vault_id: topVoultId,
						data: Name,
					})
						.then((res: any) => {
							UpdateState(false);
							setLoader(false);
							setStatus(false);
							UpdateState(false);
							setCounter((prev: any) => prev + 1);
						})
						.catch((err: any) => {
							setLoader(false);

							console.log('server Error');
						});
				}
			} else {
				setError(true);
			}
		}

		if (Parametername == 'received_items_list') {
			if (testdata == true) {
				if (btnstate == 'Updated') {
					setLoader(true);

					API.put('/vehicle_inward/inward_view/', {
						parameter_name: Parametername,
						top_vault_id: topVoultId,
						old_data: DetailsItem?.name,
						new_data: Name,
					})
						.then((res: any) => {
							UpdateState(false);
							setLoader(false);
							setStatus(false);
							UpdateState(false);
							setCounter((prev: any) => prev + 1);
						})
						.catch((err: any) => {
							setLoader(false);
							console.log('server Error');
						});
				} else {
					setLoader(true);
					API.post('/vehicle_inward/inward_view/', {
						parameter_name: Parametername,
						top_vault_id: topVoultId,
						data: Name,
					})
						.then((res: any) => {
							UpdateState(false);
							setLoader(false);
							setStatus(false);
							UpdateState(false);
							setbtnstate('');
							setCounter((prev: any) => prev + 1);
						})
						.catch((err: any) => {
							setLoader(false);
							setbtnstate('');
							console.log('server Error');
						});
				}
			} else {
				setError(true);
			}
		}

		if (Parametername == 'remarks') {
			if (testdata == true) {
				if (btnstate == 'Updated') {
					setLoader(true);

					API.put('/vehicle_inward/inward_view/', {
						parameter_name: Parametername,
						top_vault_id: topVoultId,
						old_data: DetailsItem?.name,
						new_data: Name,
					})
						.then((res: any) => {
							setLoader(false);
							setStatus(false);
							UpdateState(false);
							setCounter((prev: any) => prev + 1);
						})
						.catch((err: any) => {
							setbtnstate('');
							setLoader(false);
							console.log('server Error');
						});
				} else {
					setLoader(true);
					API.post('/vehicle_inward/inward_view/', {
						parameter_name: Parametername,
						top_vault_id: topVoultId,
						data: Name,
					})
						.then((res: any) => {
							setCounter((prev: any) => prev + 1);
							// console.log(res.data, "fivnfvfvfvfvfvfvfvfnv");
							UpdateState(false);
							setLoader(false);
							setStatus(false);

							setbtnstate('');
						})
						.catch((err: any) => {
							setLoader(false);
							setbtnstate('');
							console.log('server Error');
						});
				}
			} else {
				setError(true);
			}
		}
		if (Parametername == 'any_damages') {
			if (testdata == true) {
				if (btnstate == 'Updated') {
					setLoader(true);
					API.put('/vehicle_inward/inward_view/', {
						parameter_name: Parametername,
						top_vault_id: topVoultId,
						old_data: DetailsItem?.name,
						new_data: Name,
					})
						.then((res: any) => {
							UpdateState(false);
							setLoader(false);
							setStatus(false);
							UpdateState(false);
							setCounter((prev: any) => prev + 1);
						})
						.catch((err: any) => {
							setLoader(false);
							setbtnstate('');
							console.log('server Error');
						});
				} else {
					setLoader(true);
					API.post('/vehicle_inward/inward_view/', {
						parameter_name: Parametername,
						top_vault_id: topVoultId,
						data: Name,
					})
						.then((res: any) => {
							UpdateState(false);
							setLoader(false);
							setStatus(false);
							UpdateState(false);
							setCounter((prev: any) => prev + 1);
						})
						.catch((err: any) => {
							setLoader(false);

							console.log('server Error');
						});
				}
			} else {
				setError(true);
			}
		}

		if (Parametername == 'missing_items') {
			if (testdata == true) {
				if (btnstate == 'Updated') {
					setLoader(true);
					API.put('/vehicle_inward/inward_view/', {
						parameter_name: Parametername,
						top_vault_id: topVoultId,
						old_data: DetailsItem?.name,
						new_data: Name,
					})
						.then((res: any) => {
							UpdateState(false);
							setLoader(false);
							setStatus(false);
							setbtnstate('');
							UpdateState(false);
							setCounter((prev: any) => prev + 1);
						})
						.catch((err: any) => {
							setLoader(false);
							setbtnstate('');
							console.log('server Error');
						});
				} else {
					setLoader(true);
					API.post('/vehicle_inward/inward_view/', {
						parameter_name: Parametername,
						top_vault_id: topVoultId,
						data: Name,
					})
						.then((res: any) => {
							UpdateState(false);
							setLoader(false);
							setStatus(false);
							UpdateState(false);
							setbtnstate('');
							setCounter((prev: any) => prev + 1);
						})
						.catch((err: any) => {
							setLoader(false);
							setbtnstate('');
							console.log('server Error');
						});
				}
			} else {
				setError(true);
			}
		}

		if (Parametername == 'non-working_functionalities') {
			if (testdata == true) {
				if (btnstate == 'Updated') {
					setLoader(true);

					API.put('/vehicle_inward/inward_view/', {
						parameter_name: Parametername,
						top_vault_id: topVoultId,
						old_data: DetailsItem?.name,
						new_data: Name,
					})
						.then((res: any) => {
							UpdateState(false);
							setLoader(false);
							setbtnstate('');
							setStatus(false);
							UpdateState(false);
							setCounter((prev: any) => prev + 1);
						})
						.catch((err: any) => {
							setbtnstate('');
							setLoader(false);
							console.log('server Error');
						});
				} else {
					setLoader(true);
					API.post('/vehicle_inward/inward_view/', {
						parameter_name: Parametername,
						top_vault_id: topVoultId,
						data: Name,
					})
						.then((res: any) => {
							UpdateState(false);
							setLoader(false);
							setStatus(false);
							UpdateState(false);
							setCounter((prev: any) => prev + 1);
						})
						.catch((err: any) => {
							setLoader(false);

							console.log('server Error');
						});
				}
			} else {
				setError(true);
			}
		}
	};

	return (
		<Box
			sx={{
				display: 'flex',
				margin: '1rem 0.5rem',
				alignItems: 'start',
				columnGap: '3px',
			}}
			key={keys}
			className={styles.inputContainer}>
			<Typography sx={{ fontSize: '1rem', marginTop: '2px' }}>
				{' '}
				{Parametername !== 'remarks' &&
					Parametername !== 'product_details' &&
					`${keys + 1}.`}
			</Typography>
			<div style={{ width: '100%' }}>
				<TextareaAutosize
					disabled={Status == false ? true : false}
					aria-label='empty textarea'
					placeholder='Enter Value...'
					value={Name}
					// minRows={1}
					style={{
						fontFamily: 'Roboto',
						lineHeight: 1.5,
						border:
							Status == false ? 'none' : error ? '1px solid red' : '1px solid',
						borderTop: 'none !important',
						borderRight: 'none !important',
						borderLeft: 'none !important',
					}}
					onChange={(e: any) => handlechange(e)}
					className={styles.Inwardinputcard_container_textfiledata}
				/>
			</div>

			{
				<div className={styles.buttonconatiner}>
					{Status ? (
						(Premission?.includes('C') || Premission?.includes('U')) && (
							<LoadingButton
								size='small'
								loading={Loader}
								sx={{ padding: '0', minWidth: '20px' }}>
								<SaveIcon
									sx={{ cursor: 'pointer' }}
									onClick={() => SaveHandler()}
								/>
							</LoadingButton>
						)
					) : (
						<>
							{(Premission?.includes('C') || Premission?.includes('U')) && (
								<EditIcon
									titleAccess='Edit Point'
									onClick={() => EditHander()}
									sx={{ cursor: 'pointer', marginRight: '0.5rem' }}
								/>
							)}

							{Premission?.includes('D') && (
								<HighlightOffIcon
									titleAccess='Delete Point'
									onClick={() => DeleteHandler()}
									sx={{ cursor: 'pointer', color: 'red' }}
								/>
							)}
						</>
					)}
				</div>
			}
		</Box>
	);
};

export default InwardInputCard;
