import * as React from "react";
import { Box, Button, Checkbox, IconButton, Typography } from "@mui/material";
import { useHistory, useRouteMatch } from "react-router-dom";

import warningImg from "../../Assets/images/Warning.png";
import { useRouteParams } from "../ui-reusable-component/useRouteParams";
import { API } from "../api-services";
import { useEffect } from "react";
interface ICostingConfigurationDrawerProps {
  productList: any;
  toggleDrawer: any;
}
function APIConsoleDrawer(props: ICostingConfigurationDrawerProps) {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { productList,toggleDrawer } = props;
  //   productId/:productName/:BOMCategory/:GrpId
  const { projectId, projectName, productId, productName, BOMCategory, GrpId } =
    useRouteParams<any>();
  const [checkCosting, setCheckCosting] = React.useState<any>(false);
  const [useraction, setuseraction] = React.useState<any>(null);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [BOMCategories, setBOMCategories] = React.useState<any>([]);
  console.log("BOMCategoriesBOMCategoriesBOMCategories", BOMCategories);

  const getBOMCategories = () => {
    API.get(
      "/config/template_config/",
      {
        product: productId,
        project: projectId,
        module: 6,
      },
      0
    )
      .then((res: any) => {
        setBOMCategories(res.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    if (productId != 0) {
      getBOMCategories();
    }
  }, [productId]);

  return (
    <div>
      <Box
        sx={{ padding: "0 0.5rem" }}
        // onClick={toggleDrawer(anchor, false)}
        // onKeyDown={toggleDrawer(anchor, false)}
      >
        {productList?.length > 0 &&
          productList?.map((item: any, index: number) => {
            return (
              <Box sx={{ padding: "0 0.5rem" }}>
                {
                  <Box sx={{ alignItems: "center", display: "flex" }}>
                    <Checkbox
                      style={{ padding: "3px" }}
                      checked={item?.id == productId}
                      onChange={() =>
                        {if(url.includes("landing")){
                            history.push(
                                `/API-console/${projectId}/${projectName}/${item?.id}/${item?.name}/0/0/landing/0/0`
                              )
                        }else{
                            history.push(
                          `/API-console/${projectId}/${projectName}/${item?.id}/${item?.name}/0/0/`
                        )}}
                      }
                      inputProps={{ "aria-label": "controlled" }}
                      sx={{
                        "& .MuiSvgIcon-root": {
                          fontSize: { lg: "24", xl: "30" },
                        },
                      }}
                    />
                    <Box
                      // key={index}
                      onClick={() =>
                        {if(url.includes("landing")){
                            history.push(
                                `/API-console/${projectId}/${projectName}/${item?.id}/${item?.name}/0/0/landing/0/0`
                              )
                        }else{
                            history.push(
                          `/API-console/${projectId}/${projectName}/${item?.id}/${item?.name}/0/0/`
                        )}}
                      }
                      sx={{
                        padding: "0.2rem",
                        margin: "0.5rem,0rem",
                        backgroundColor:
                          item?.id == productId
                            ? "primary.main"
                            : "primary.light",
                        color: item?.id == productId ? "white" : "primary.main",
                        textAlign: "left",
                        cursor: "pointer",
                        display: "inline-block",
                        width: "100%",
                      }}
                    >
                      <Typography
                        style={{
                          fontSize: "0.87rem",
                          textTransform: "capitalize",
                        }}
                      >
                        {item?.name}
                      </Typography>
                    </Box>
                  </Box>
                }
                <Box></Box>
              </Box>
            );
          })}

        <Button
          variant="contained"
          color="primary"
          onClick={() =>
            {
                history.push(
              `/API-console/${projectId}/${projectName}/${productId}/${productName}/0/0/landing/0/0`
            )
            toggleDrawer()
        }
          }
        >
          Ok
        </Button>
      </Box>
    </div>
  );
}
export default APIConsoleDrawer;
