import React, {useEffect, useState} from "react";
import {useHistory, useParams} from "react-router-dom";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import {IconButton} from "@mui/material";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import styles from "./ReportProjectList.module.scss";
import {
  Typography,
  TextField,
  MenuItem,
  TableContainer,
  Table,
  TableCell,
  TableHead,
  TableRow,
  TableBody,
  Checkbox,
  Autocomplete,
} from "@mui/material";
import RepotsLandingPage from "./ReportsLandingPage";
import { AnyAction } from "redux";
// import styles from "./DriveProjectList.module.scss";
type Anchor = "top" | "left" | "bottom" | "right";
interface ReportsProjectListProps {
  projects?: any;
  fetched?: string;
}
interface param {
  projectId: any;
  type: any;
  projectName: any;
  moduleId:any;
}




const ReportsProjectList: React.FC<ReportsProjectListProps> = (props) => {
  const {projectId, type, projectName,moduleId} = useParams<param>();
  // const {projects, fetched} = props;
  const [state, setState] = React.useState({
    left: false,
  });
  var CovertInt = parseInt(projectId);
  const [selectedId, setselectedId] = useState<any>([CovertInt]);
  const [ProjectInformation, setprojectInformation] =
    useState<any>(projectName);
  // history for pushing the path in url

  const history = useHistory();

  const handleClick = (projectId: string) => {
    if (projectId) {
      history.push(`/reports/${projectId}/${type}/0`);
    }
  };

  const handleGoBack = () => {
    history.push("/");
  };

  
  const getselectedBackgroundColor = (idd: any) => {
    if (selectedId?.includes(idd)) {
      return "primary.main";
    } else {
      return "primary.light";
    }
  };
  const getSelectedTextColor = (idd: any) => {
    if (selectedId?.includes(idd)) {
      return "primary.light";
    } else {
      return "primary.main";
    }
  };

 
  const selectAllProduct = (productIds: any, projectname: any) => {
    setselectedId((prev: any) => [productIds]);
    setprojectInformation(projectname);

    // if (selectedId?.includes(productIds)) {
    //   setselectedId((prev: any) =>
    //     prev?.filter((item: any) => item !== productIds)
    //   );

    // } else {
    //   setselectedId((prev: any) => [ productIds]);
    //   history.push(`/reports/${productIds}/${type}/0`);
    // }
  };

  // useEffect for side effect that will push the url

  useEffect(() => {
    if (moduleId == 0) {
      //  setprojectInformation(projects[0]?.name)

      // when we first time land to drive then it will automatically select the first project present

      history.push(`/reports/0/${type}/0`);
    }else{
      history.push(`/reports/0/${type}/${moduleId}`);
    }
  }, []);



  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      history.push(`/reports/0/${type}/0`);
      setState({...state, [anchor]: open});
    };
  // const list = (anchor: Anchor) => (
  //   <Box
  //     sx={{minWidth:300,width: anchor === "top" || anchor === "bottom" ? "auto" : "auto"}}
  //     role="presentation"
  //   >
  //     <List>
  //       <ListItem sx={{display: "grid"}}>
  //         <Box sx={{width: "16.5rem"}}>
  //           <Typography
  //             style={{
  //               width: "100%",
  //               fontSize: "1rem",
  //             }}
  //           >
  //             Select Project*
  //           </Typography>
  //           {projects &&
  //             projects?.map((item: any, index: any) => {
  //               return (
  //                 <>
  //                   <Box sx={{alignItems: "center", display: "flex"}}>
  //                     <Checkbox
  //                       checked={selectedId?.includes(item?.id) ? true : false}
  //                       onClick={() => selectAllProduct(item?.id, item?.name)}
  //                       inputProps={{"aria-label": "controlled"}}
  //                       sx={{"& .MuiSvgIcon-root": {fontSize: 24}}}
  //                     />
  //                     <Box
  //                       onClick={() => selectAllProduct(item?.id, item?.name)}
  //                       key={index}
  //                       // className={styles.selectVehBtn}
  //                       sx={{
  //                         // margin: "0rem 2rem",
  //                         padding: "0.2rem 0.5rem",
  //                         backgroundColor: getselectedBackgroundColor(item.id),
  //                         color: getSelectedTextColor(item.id),
  //                         textAlign: "center",
  //                         cursor: "pointer",
  //                         display: "inline-block",
  //                         width: "100%",
  //                       }}
  //                     >
  //                       <Typography style={{fontSize: "1rem"}}>
  //                         {item?.name}
  //                       </Typography>
  //                     </Box>
  //                   </Box>
  //                 </>
  //               );
  //             })}

  //           {selectedId?.length > 1 && (
  //             <p
  //               style={{
  //                 fontSize: "12px",
  //                 color: "#d32f2f",
  //                 marginTop: "0.4rem",
  //                 marginLeft: "1.45rem",
  //               }}
  //             >
  //               {"*Please select less then 5 Products"}
  //             </p>
  //           )}
  //         </Box>
  //         <Box style={{textAlign: "right", marginTop: "1rem"}}>
  //           <Button
  //             className={styles.drawBtn}
  //             sx={{marginRight: "1rem"}}
  //             variant="contained"
  //             size="small"
  //             onClick={toggleDrawer(anchor, false)}
  //           >
  //             Close
  //           </Button>
  //           <Button
  //           // onClick={SearchViewHandler}
  //           // disabled={
  //           //   selectedId?.length < 1 ||
  //           //   selectedId?.length > 5
  //           >
  //             <Button
  //               className={styles.drawBtn}
  //               variant="contained"
  //               onClick={toggleDrawer(anchor, false)}
  //               size="small"
  //             >
  //               Ok
  //             </Button>
  //           </Button>
  //         </Box>
  //       </ListItem>
  //     </List>
  //   </Box>
  // );

  return (
    <div style={{height: "94vh"}}>
      <>
        <Box sx={{width: "3rem",marginTop:'-0.5rem'}}>
          <Box sx={{height:'100%'}}>
            <Box sx={{width: "3rem", padding: "0 0.5rem", marginTop: "0.5rem"}}>
              <IconButton sx={{cursor: "pointer"}}>
                <ArrowBackIcon color="primary" titleAccess="Go Back" onClick={handleGoBack} />
              </IconButton>
            </Box>            
          </Box>
        </Box>
        <Box sx={{width: "100%",padding:'0 1rem'}}>
          {/* <Box sx={{fontSize:'1rem',}}> {ProjectInformation}</Box> */}
          <RepotsLandingPage />
        </Box>
      </>
    </div>
  );
};

export default ReportsProjectList;
