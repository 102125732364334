import { TextField } from "@mui/material";
import * as React from "react";

export interface IAddQtyProps {
  index: any;
  selectedId: any;
  disableStatus: any;
  selectedProcessQtyList: any;
}

export function AddQty(props: IAddQtyProps) {
  const { index, selectedId, disableStatus, selectedProcessQtyList } = props;
  const [qty, setQty] = React.useState<any>(1);
  const handleChangeQty = (e: any) => {
    const { value } = e.target;
    if (value > 0) {
      setQty(value);
      const targetItem = selectedProcessQtyList?.find(
        (item: any) => item?.id === selectedId
      );
      targetItem.qty = parseInt(value);
    }
  };
  return (
    <TextField
      placeholder="Qty"
      // label='Sequence'
      variant="standard"
      size="small"
      key={index}
      disabled={disableStatus}
      type="number"
      value={qty}
      onChange={handleChangeQty}
      fullWidth
      sx={{
        width: "60%",
        margin: "0.2rem 0",
        ".MuiInputBase-root.MuiInput-root": {
          fontSize: "1rem !important",
        },
        "& .MuiInput-underline:before": {
          borderBottomColor: "white",
        },
        "& .MuiInput-underline:after": {
          borderBottomColor: "primary.main",
        },
      }}
    />
  );
}
