// react
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';

// vendors
// import { Button, Icon, Image, Modal } from 'semantic-ui-react';
// import { useSelector } from 'react-redux';
// import clsx from "classnames";

// // services
// import { fetchS3Url } from 'app/services/s3-uri.service';

// // utils
// // constants
// // factories
// // core
// import NoData from 'app/Core/NoData';

// // redux
// import { RootState } from 'app/Redux/storeConfigurations';
// import { flaggedAssumptionSelectors } from 'app/Redux/Reducers/flaggedAssumption.reducer';
// import { createFlaggedAssumption, deleteFlaggedAssumption, updateFlaggedAssumption } from 'app/Redux/Actions/flaggedAssumption.actions';

// // ui-components
// import { BouncingLoader } from 'app/ui-components';

// // others
// import { useAppDispatch } from 'AppRouter';
// import { useCostingReadonly } from 'app/Components/Costing/useCostingReadonly';

// // styles
import styles from "./FlaggedAssumptionFileModal.module.scss";

// // interfaces and types
// import { ID } from 'app/commonInterfaces';
import swal from 'sweetalert';
import { ID } from '../../../../../../utlis/commonInterfaces';
import { useAppDispatch } from '../../../../../../../AppRouter';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../../Redux/Store/storeConfigurations';
import { flaggedAssumptionSelectors } from '../../../../../../Redux/Reducers/flaggedAssumption.reducer';
import { deleteFlaggedAssumption, updateFlaggedAssumption } from '../../../../../../Redux/Actions/flaggedAssumption.actions';
import { fetchS3Url } from '../../../../../../Redux/Services/S3-url.service';
import { Box, Divider, Modal, Skeleton, Typography } from '@mui/material';
import UploadIcon from '@mui/icons-material/Upload';
import CloseIcon from '@mui/icons-material/Close';
import DeleteIcon from '@mui/icons-material/Delete';
import CancelIcon from '@mui/icons-material/Cancel';
import { LoadingButton } from '@mui/lab';
import { userPermission } from '../../../CostigngCalculatorSection';
// lazy

// ----------------------------------------------------------------------------------

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '30vw',
    // height: '80vh',
    bgcolor: 'background.paper',
    borderRadius: '10px',
    p: 1,
};
interface FlaggedAssumptionFileModalProps {
    isOpen: boolean;
    onClose: () => void;
    flaggedAssumptionId: ID;
    userInputId: any;
}

const FlaggedAssumptionFileModal: React.FC<FlaggedAssumptionFileModalProps> = (props) => {
    const { isOpen, flaggedAssumptionId, onClose, userInputId } = props;
    const dispatch = useAppDispatch();

    const useraction=useContext<any>(userPermission)
    // const readonly = useCostingReadonly();

    // ----------------------------------------------------------------------------------
    // state

    const [s3url, setS3url] = useState<string | null>(null);

    // ----------------------------------------------------------------------------------
    // selectors

    const flaggedAssumption = useSelector((state: RootState) => {
        return flaggedAssumptionSelectors.selectById(state, flaggedAssumptionId);
    });

    // ----------------------------------------------------------------------------------
    // ref

    const inputElRef = useRef<any>(null);

    // ----------------------------------------------------------------------------------
    // handlers

    const browseFiles = () => inputElRef.current?.click?.();

    const handleInputClick = (e: React.MouseEvent) => {
        // clear the prev value on click so that same image can be uploaded again
        (e.target as HTMLInputElement).value = "";
    };

    const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
        const file = e.target.files?.[0];

        dispatch(updateFlaggedAssumption({
            id: flaggedAssumptionId,
            image: file || null
        }));
    };

    const handleDeleteFile = () => {
        dispatch(updateFlaggedAssumption({
            id: flaggedAssumptionId,
            image: null
        }));
    };

    // ----------------------------------------------------------------------------------
    // side effects

    useEffect(() => {
        if (!flaggedAssumption?.reference) return;

        fetchS3Url(flaggedAssumption.reference).then(setS3url);
    }, [flaggedAssumption]);

    useEffect(() => {
        if (isOpen) return;

        setS3url(null);
    }, [isOpen]);

    // ----------------------------------------------------------------------------------
    // jsx helpers

    const isImage = useMemo(() => {
        return flaggedAssumption?.reference && flaggedAssumption.reference.includes(".webp");
    }, [flaggedAssumption?.reference]);

    const isPDF = useMemo(() => {
        return flaggedAssumption?.reference && flaggedAssumption.reference.includes(".pdf");
    }, [flaggedAssumption?.reference]);

    // ----------------------------------------------------------------------------------
    // jsx abstractions

    const renderImage = () => (
        // @ts-ignore
        <img src={s3url} />
        // <Image size="huge" src={s3url} />
    );

    const renderFile = () => (
        s3url ? <a className={styles.link} href={s3url} target="_blank">Open file in new tab</a> : <Skeleton width="10rem" height="2rem"/>
    );


    const handleInputFlag = async () => {

        if (flaggedAssumption) {
            // unflag 
            const confirm = await swal({
                buttons: ["Cancel", "Yes, Unflag"],
                title: "Delete Flag?",
                text: "It will Unflag input assumption and delete the assumption file,",
                icon: "error"
            });

            if (confirm) dispatch(deleteFlaggedAssumption({ id: flaggedAssumption.id }));
            return;
        }

        // dispatch(createFlaggedAssumption({
        //     inputId: inputCalculation.id,
        //     userInputId
        // }));
    };



    // ----------------------------------------------------------------------------------
    // jsx

    return (
        <Modal
            // data-testid="flagged-assumption-file-modal"
            // className={styles.container}
            open={isOpen}
            onClose={onClose}
        >

            <Box sx={style}>

                <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
                    <Typography
                        style={{
                            fontSize: "1rem",
                            fontWeight: "600",
                            width: "100%",
                            textAlign: "center",
                            marginRight: '-2rem'
                        }}
                    >
                        <span>Assumption Reference File</span>
                    </Typography>
                    <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onClose} />
                </Box>
                <Divider
                    sx={{
                        borderColor: "primary.light",
                        margin: "0 1rem",
                        marginBottom: "1rem",
                    }}
                />
                <Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '1rem', width: '100%' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '1rem', width: '100%' }}>
                            {
                                (useraction && useraction?.action?.includes("U") || useraction && useraction?.action?.includes("C")) &&
                            
                            <LoadingButton
                                size="small"
                                title='Upload Assumption'
                                sx={{
                                    padding: "0.2rem 0.5rem",
                                    minWidth: '2rem',
                                    lineHeight: 1,
                                    "&:hover": {
                                        transform: 'Scale(1.05)',
                                        transition: 'transform 0.5s ease',
                                    },
                                    '.MuiCircularProgress-root': {
                                        color: 'primary.main',
                                    },
                                }}>
                                <UploadIcon sx={{ cursor: "pointer" }} onClick={browseFiles} />
                            </LoadingButton>
}
                            {isImage ? renderImage() : isPDF ? renderFile() : (
                                <Typography sx={{ fontSize: '1rem' }}>
                                    Add Image/PDF to view
                                </Typography>

                            )}
                            <input
                                type="file"
                                ref={inputElRef}
                                // accept="image/*"
                                onChange={handleChange}
                                onClick={handleInputClick}
                                hidden
                            />
                        </Box>
                        


{useraction && useraction?.action?.includes("D")  &&<>
                        
                        {flaggedAssumption && s3url && (
                            <DeleteIcon titleAccess='Delete Assumption Flag' sx={{ cursor: "pointer" }} onClick={handleInputFlag} />
                        )}
</>}
                    </Box>

                </Box>
            </Box>
            {/* <Modal.Header>
                <span className={styles.header}>
                    <span>Assumption Reference File</span>
                    <span className={clsx({ [styles.hide]: readonly })}>
                        <Icon name="upload" link onClick={browseFiles} />
                        
                    </span>
                </span>
            </Modal.Header>
            <Modal.Content className={styles.content}>
                {isImage ? renderImage() : isPDF ? renderFile() : (
                    <NoData
                        label="No File uploaded"
                        iconClass="none"
                        subheading="Add Image/PDF to view"
                    />
                )}
                <input
                    type="file"
                    ref={inputElRef}
                    // accept="image/*"
                    onChange={handleChange}
                    onClick={handleInputClick}
                    hidden
                />
            </Modal.Content>
            <Modal.Actions>
                <Button onClick={onClose}>
                    <Icon name="close"
                        title="Cancel"
                        alt="Cancel"
                        className="bicon"
                    />
                </Button>
                {flaggedAssumption && s3url && (
                    <Button onClick={handleInputFlag}>
                         <Icon name="trash"
                        className="bicon"
                    />
                    </Button>
                )}
            </Modal.Actions> */}
        </Modal>
    );
};

// ----------------------------------------------------------------------------------

export default FlaggedAssumptionFileModal;