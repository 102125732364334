import React, { useEffect, useState } from "react";
import styles from "./RecycleBinLandingPage.module.scss";
import AddIcon from "@mui/icons-material/Add";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import FlipCameraAndroidIcon from "@mui/icons-material/FlipCameraAndroid";
import Button, { ButtonProps } from "@mui/material/Button";
import swal from "sweetalert";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid";
import { API } from "../../api-services";
import moment from "moment";
import {
  Box,
  Checkbox,
  Divider,
  Drawer,
  IconButton,
  Typography,
  styled,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { useHistory } from "react-router-dom";
import { useDocumentTitle } from "../../ui-reusable-component/useDocumentTitle";

type Anchor = "top" | "left" | "bottom" | "right";

const csvOptions: GridCsvExportOptions = {
  fileName: `Pareto Analysis Table In CSV`,
  delimiter: ",",
  utf8WithBom: true,
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = (selectionModel: any, onDeleteSelected: any) => (
  <GridToolbarContainer style={{ height: "2rem" }}>
    <span style={{ position: "absolute", right: "0rem", display: "flex" }}>
      {selectionModel?.length > 0 && (
        <span
          style={{
            marginRight: "4px",
            fontWeight: "450",
            fontSize: "0.95rem",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={() => onDeleteSelected(selectionModel)}
        >
          <DeleteForeverIcon fontSize="small" /> DELETE SELECTED
        </span>
      )}
      <GridToolbarColumnsButton />
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);

const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Rows</Box>
    </StyledGridOverlay>
  );
}
interface Params {
  projectId: number;
}

interface RecycleBinLandingPageI {}

const RecycleBinLandingPage: React.FC<RecycleBinLandingPageI> = (props) => {
  const { projectId } = useRouteParams<Params>();
  useDocumentTitle("Recycle Bin");
  const [loaderRecycleBin, setLoaderRecycleBin] = useState<boolean>(false);
  const [recycleBinData, setRecycleBinData] = useState<any>();
  const [selectionModel, setSelectionModel] = React.useState<any>([]);
  const [selectedProject, setSelectedProject] = useState<any>([]);
  const [projectList, setProjectList] = useState<any>();
  const [width, height] = [window.innerWidth, window.innerHeight];
  const history: any = useHistory();

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  useEffect(() => {
    setSelectedProject([projectId]);
    setLoaderRecycleBin(false);
    API.get(`auth/mui_trash_view/`, { project_id: projectId }, 0)
      .then((res: any) => {
        setLoaderRecycleBin(true);
        setRecycleBinData(res?.data);
      })
      .catch((err: any) => {
        setLoaderRecycleBin(true);
        console.log(err, "error");
      });
  }, [projectId]);

  useEffect(() => {
    setLoaderRecycleBin(false);
    API.get(`/customer/dashboard/`, { project_list_bin: true }, 0)
      .then((res: any) => {
        setProjectList(res?.data);
      })
      .catch((err: any) => {
        console.log(err, "error");
      });
  }, []);

  const redirectToProjectRecyclebin: any = () => {
    history.push(`/recyclebin/${selectedProject[0]}`);
  };

  const onDeleteSelected: any = (props: any) => {
    setLoaderRecycleBin(false);
    swal({
      title: `Delete selected item permanently`,
      text: "Are you sure?",
      icon: "warning",
      buttons: ["Cancel", "Yes, Delete"],
    }).then((confirm) => {
      if (confirm) {
        API.delete("/auth/trash/" + props[0] + "/", { id_list: props }).then(
          (res: any) => {
            API.get(`auth/mui_trash_view/`, { project_id: projectId }, 0)
              .then((res: any) => {
                setLoaderRecycleBin(true);
                setRecycleBinData(res?.data);
              })
              .catch((err: any) => {
                setLoaderRecycleBin(true);
                console.log(err, "error");
              });
          }
        );
      } else setLoaderRecycleBin(true);
    });
  };

  const onDeleteSingle: any = (props: any, entity: any, name: any) => {
    setLoaderRecycleBin(false);
    swal({
      title: `Delete ${entity} permanently`,
      text: `Are you sure you want to delete "${name}" ?`,
      icon: "warning",
      buttons: ["Cancel", "Yes, Delete"],
    }).then((confirm) => {
      if (confirm) {
        API.delete("/auth/trash/" + props + "/").then((res: any) => {
          API.get(`auth/mui_trash_view/`, { project_id: projectId }, 0)
            .then((res: any) => {
              setLoaderRecycleBin(true);
              setRecycleBinData(res?.data);
            })
            .catch((err: any) => {
              setLoaderRecycleBin(true);
              console.log(err, "error");
            });
        });
      } else setLoaderRecycleBin(true);
    });
  };

  const onRestoreSingle: any = (props: any, entity: any, name: any) => {
    setLoaderRecycleBin(false);
    swal({
      title: `Restore ${entity}`,
      text: `Are you sure you want to restore "${name}" ?`,
      icon: "warning",
      buttons: ["Cancel", "Yes, Restore"],
    }).then((confirm) => {
      if (confirm) {
        API.put("/auth/trash/" + props + "/", {}).then((res: any) => {
          API.get(`auth/mui_trash_view/`, { project_id: projectId }, 0)
            .then((res: any) => {
              setLoaderRecycleBin(true);
              setRecycleBinData(res?.data);
            })
            .catch((err: any) => {
              setLoaderRecycleBin(true);
              console.log(err, "error");
            });
        });
      } else setLoaderRecycleBin(true);
    });
  };

  let columnsRecyclebin: any = [
    {
      field: "sno",
      headerName: "SNo.",
      width: 60,
      // flex: 1,
    },
    {
      field: "entity",
      headerName: "Entity",
      minWidth: width > 1370 ? 210 : 150,
      flex: 1,
    },
    {
      field: "name",
      headerName: `Name`,
      minWidth: width > 1370 ? 430 : 230,
      flex: 1,
    },
    {
      field: "deletedby",
      headerName: "Deleted By",
      minWidth: width > 1370 ? 260 : 180,
      flex: 1,
    },
    {
      field: "deletedat",
      headerName: `Deleted At`,
      minWidth: width > 1370 ? 280 : 150,
      flex: 1,
    },
    {
      field: "remarks",
      headerName: `Remarks`,
      minWidth: width > 1370 ? 320 : 200,
      flex: 1,
    },
    {
      field: "valueRs",
      headerName: `Actions`,
      width: width > 1370 ? 80 : 70,
      // flex: 1,
      renderCell: (cellValues: any) => {
        return (
          <>
            <FlipCameraAndroidIcon
              style={{ cursor: "pointer" }}
              onClick={() =>
                onRestoreSingle(
                  cellValues?.row?.id,
                  cellValues?.row?.entity,
                  cellValues?.row?.name
                )
              }
            />
            <DeleteForeverIcon
              style={{ cursor: "pointer", marginLeft: "0.5rem" }}
              onClick={() =>
                onDeleteSingle(
                  cellValues?.row?.id,
                  cellValues?.row?.entity,
                  cellValues?.row?.name
                )
              }
            />
          </>
        );
      },
    },
  ];

  let rowsRecyclebin: any = [];

  const dataGridDataGeneratorRecyclebin: any =
    recycleBinData &&
    recycleBinData?.map((val: any, index: number) => {
      let objShared: any = {
        id: val?.id,
        sno: index + 1,
        entity: val.entity,
        name: val?.name,
        deletedby: val?.user,
        deletedat: moment(val?.timestamp).format("DD MMMM YYYY"),
        remarks: val?.entity_id,
      };
      rowsRecyclebin.push(objShared);
    });

  let dataStructureDataGridRecyclebin: any = {
    columns: columnsRecyclebin,
    rows: rowsRecyclebin,
  };

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setState({ ...state, [anchor]: open });
      if (open) {
        setSelectedProject([projectId]);
      }
    };

  const selectAllProject = (productIds: any) => {
    setSelectedProject([productIds]);
  };

  const getselectedProjectBackgroundColor = (idd: any) => {
    if (selectedProject?.includes(idd)) {
      return "primary.main";
    } else {
      return "primary.light";
    }
  };

  const getSelectedProjectTextColor = (idd: any) => {
    if (selectedProject?.includes(idd)) {
      return "primary.light";
    } else {
      return "primary.main";
    }
  };

  const list = (anchor: Anchor) => (
    <Box
      sx={{
        minWidth: 300,
        width: anchor === "top" || anchor === "bottom" ? "auto" : "auto",
      }}
      role="presentation"
    >
      <Box sx={{ width: "100%", padding: "0" }}>
        <Box sx={{ width: "100%" }}>
          <Box
            style={{
              display: "flex",
              columnGap: "5px",
              padding: "0 1rem",
              marginBottom: "0.5rem",
            }}
          >
            Select Projects
          </Box>
        </Box>
        <Box
          sx={{ width: "100%", padding: "0 1rem" }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3"
        >
          <Box>
            <Typography
              style={{
                width: "100%",
                fontSize: "0.75rem",
              }}
            >
              Project Name
            </Typography>
            {projectList &&
              projectList?.map((item: any, index: any) => {
                return (
                  <>
                    <Box sx={{ alignItems: "center" }}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Checkbox
                          style={{ padding: "3px" }}
                          checked={
                            selectedProject?.includes(item.id) ? true : false
                          }
                          onClick={() => selectAllProject(item?.id)}
                          inputProps={{ "aria-label": "controlled" }}
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                        />
                        <Box
                          onClick={() => selectAllProject(item?.id)}
                          key={index}
                          className={styles.selectVehBtn}
                          sx={{
                            // margin: "0rem 2rem",
                            padding: "0.2rem 0.5rem",
                            backgroundColor: getselectedProjectBackgroundColor(
                              item.id
                            ),
                            color: getSelectedProjectTextColor(item.id),
                            textAlign: "left",
                            cursor: "pointer",
                            width: "100%",
                          }}
                        >
                          <Typography
                            style={{ fontSize: "0.75rem", display: "flex" }}
                          >
                            {item.name}{" "}
                            <span
                              style={{
                                marginLeft: "0.5rem",
                                display: "inline-block",
                                width: "14rem",
                              }}
                            >
                              [ {item.description} ]
                            </span>
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </>
                );
              })}
          </Box>
        </Box>
        <div
          style={{
            paddingBottom: "0.5rem",
            position: "sticky",
            bottom: "0",
            height: "4rem",
            backgroundColor: "aliceblue",
            marginTop: "2rem",
          }}
        >
          <Box sx={{ textAlign: "right", paddingRight: "1rem" }}>
            <Button
              className={styles.drawBtn}
              sx={{ marginRight: "1rem", fontSize: "0.75rem" }}
              variant="contained"
              onClick={toggleDrawer(anchor, false)}
              size="small"
            >
              Close
            </Button>
            <LoadingButton size="small" onClick={redirectToProjectRecyclebin}>
              <Button
                variant="contained"
                sx={{ fontSize: "0.75rem" }}
                onClick={toggleDrawer(anchor, false)}
                size="small"
              >
                Ok
              </Button>
            </LoadingButton>
          </Box>
        </div>
      </Box>
    </Box>
  );

  return (
    <div className={styles.container}>
      <Box className={styles.expandIconBox}>
        <div className={styles.iconSec}>
          {(["left"] as const).map((anchor) => (
            <React.Fragment key={anchor}>
              <IconButton
                size="medium"
                sx={{
                  backgroundColor: "primary.light",
                  color: "primary.main",
                }}
                onClick={toggleDrawer(anchor, true)}
              >
                <AddIcon sx={{ fontSize: "1.7rem" }} />
              </IconButton>
              <Drawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
                className={styles.drawerContainer}
                PaperProps={{
                  sx: {
                    backgroundColor: "aliceblue",
                  },
                }}
              >
                <Box sx={{ padding: "0.5rem 0rem", paddingBottom: "0" }}>
                  <Box sx={{ position: "sticky", top: "0" }}>
                    <Typography
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "1rem",
                        lineHeight: "1.2",
                      }}
                    >
                      Select to Change Project
                    </Typography>
                    <Divider sx={{ margin: "0.5rem" }} />
                  </Box>
                  {list(anchor)}
                </Box>
              </Drawer>
              <Typography
                style={{
                  transform: "rotate(270deg)",
                  whiteSpace: "nowrap",
                  marginTop: "45vh",
                  cursor: "pointer",
                  fontSize: "1rem",
                }}
                onClick={toggleDrawer(anchor, true)}
              >
                Select to Change Project
              </Typography>
            </React.Fragment>
          ))}
        </div>
      </Box>
      <div className={styles.containerRecyclebin}>
        <div style={{ margin: "0.5rem 1rem" }}>
          <div>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Typography sx={{ fontSize: "1.3rem" }}>Recycle Bin</Typography>
            </Box>
            <span style={{ color: "grey", margin: "0rem" }}>
              <b>Note:</b> When you delete something we'll keep it here for 30
              days, after that it will be deleted permanently.
            </span>
          </div>
        </div>
        <Box
          sx={{
            width: "99%",
            height: { lg: "88vh", xl: "90vh" },
            marginTop: "-2rem",
            marginLeft: "0.5rem",
          }}
        >
          {
            <DataGrid
              headerHeight={48}
              rowHeight={36}
              loading={!loaderRecycleBin}
              {...dataStructureDataGridRecyclebin}
              disableSelectionOnClick={true}
              components={{
                Toolbar: () => {
                  return CustomToolbar(selectionModel, onDeleteSelected);
                },
              }}
              getRowClassName={(params) =>
                `super-app-theme--${params.row.stage}`
              }
              sx={{
                height: "100%",
                fontSize: "1rem",
                "&.MuiDataGrid-root": {
                  border: "none",
                },
                "& .MuiDataGrid-checkboxInput": {
                  color: 'primary.main', // Change to the color you want
                },
                "&.MuiDataGrid-root.MuiDataGrid-autoHeight .MuiDataGrid-row--lastVisible .MuiDataGrid-cell":
                  {
                    borderBottomColor: "primary.light",
                  },
                "&.MuiDataGrid-root .MuiDataGrid-cell": {
                  borderBottomColor: "primary.light",
                },

                "& ::-webkit-scrollbar": {
                  width: "0.3rem",
                  height: "0.3rem",
                },
                "& ::-webkit-scrollbar-thumb": {
                  // background: $thumb-color;
                  backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                  borderRadius: "10px",
                },
                "& ::- webkit-scrollbar-thumb: hover ": {
                  // width: "4px",
                  backgroundColor: "#045DE9 !important",
                },
                "::-webkit-scrollbar-track": {
                  backgroundColor: "#e1e1f3 !important",
                },
              }}
              density={"compact"}
              pageSize={30}
              rowsPerPageOptions={[30]}
              checkboxSelection
              onSelectionModelChange={setSelectionModel}
              selectionModel={selectionModel}
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
              }}
            />
          }
        </Box>
      </div>
    </div>
  );
};

export default RecycleBinLandingPage;
