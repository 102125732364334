import * as React from "react";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import successIcon from "../../../Assets/images/success.png";
import {
  Box,
  Button,
  ButtonProps,
  IconButton,
  InputAdornment,
  Skeleton,
  Table,
  TableCell,
  TableRow,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { DataGrid, GridColDef, GridCsvExportMenuItem, GridCsvExportOptions, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExportContainer, GridValueGetterParams } from "@mui/x-data-grid";
import { API } from "../../../api-services";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { useCallback, useRef, useState } from "react";

import AddIcon from "@mui/icons-material/Add";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useHistory } from "react-router-dom";
import { useBaseModal } from "../../../ComponentUI/SearchPage/useBaseModal";

import LoadingButton from "@mui/lab/LoadingButton";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import InfoIcon from "@mui/icons-material/Info";

import FlagIcon from "@mui/icons-material/Flag";
import FlagOutlinedIcon from '@mui/icons-material/FlagOutlined';

import UploadIcon from "@mui/icons-material/Upload";
import GetAppIcon from "@mui/icons-material/GetApp";
import Swal from "sweetalert2";
import swal from "sweetalert"
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DeleteIcon from '@mui/icons-material/Delete';
import AddMachineModal from "../MHRDataBase/AddMachineModal";
import MHRmoveModal from "../MHRDataBase/MHRmoveModal";
import AddLabourModal from "./AddLabourModal";
import LabourmoveModal from "./LabourmoveModal";
import move from "../../../Assets/images/right move.svg";
import useWindowDimensions from "../../../ui-reusable-component/useWindowDimensions";
import { LHRDatabaseInfoModal } from "./LHRDatabaseInfoModal";
import openFolder from '../../../Assets/images/svgs/openfolderblue.svg';
import FolderIcon from '@mui/icons-material/Folder';
import { AUTH } from "../../../Redux/Services/auth.service";
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';


export interface IRawMaterialRevisionTableProps {


  setRecyclebinCounter: any;
}
const csvOptions: GridCsvExportOptions = {
  fileName: "Labour Hours Rates CSV", delimiter: ",", utf8WithBom: true
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridToolbarColumnsButton />
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer sx={{
    width: '100%',
    height: '2.3rem',
    justifyContent: 'flex-end',
    paddingRight: '1rem',
    marginTop: '-2.4rem',
  }}>
    <span>
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem", color: 'primary.main' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "white",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(0deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "0px solid rgba(0, 0, 0, .125)",
}));

export default function LabourDatabase(props: IRawMaterialRevisionTableProps) {
  const { tab, year, month, revisionId, typeId, databaseName, status, search, categoryName, lockCounter } =
    useRouteParams<any>();

  const [expanded, setExpanded] = React.useState<string | false>(categoryName);
  const [CategoryList, setCategoryList] = React.useState<any>([]);
  const [CommodityLoader, setCommodityLoader] = useState<any>(false);

  const { setRecyclebinCounter } = props
  const [TableData, setTableData] = useState<any>([]);


  const [statusColor, setStatusColor] = useState<any>(false);
  const [Loader, setLoader] = useState<any>(false);
  const [resverUpload, setReverseUpload] = useState<any>(false);
  const history = useHistory<any>();
  const labourModal = useBaseModal();
  const [RowsItem, setRowsItem] = useState<any>();
  const Editmachinemodal = useBaseModal();
  const [Counter, setCounter] = useState<any>(0);
  const [tableLoader, settableLoder] = useState<any>(false);
  const [reverseLoader, setreverseLoader] = useState<any>(false)
  const infomodal = useBaseModal()
  const inputElRefPdf = useRef<any>();
  const LabourMove = useBaseModal();
  const [rowItems, setRowItems] = useState<any>({});
  const { height, width } = useWindowDimensions();
  const [useraction, setuseraction] = useState<any>(null)
  const [tabValue, setTabValue] = useState<any>(categoryName);
  // const handleChange =
  //   (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
  //     setExpanded(newExpanded ? panel : false);
  //     if (newExpanded == true) {
  //       history.push(
  //         `/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/laborDB/${panel}/${status}/0`
  //       );
  //     }
  //   };
  const handleChange = (categoryName: any) => {
    history.push(
      `/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/laborDB/${categoryName}/${status}/0/${lockCounter}`
    );
    setTabValue(categoryName);
  };
  React.useEffect(() => {
    API.get(
      "/auth/calibration_user_permission/",
      {
        action: true,
        database: true,
        module: "DB-Costing"
      },
      0
    )
      .then((res: any) => {
        setuseraction(res?.data?.action)
        // sessionStorage.setItem("DBPermission", res.data.action);
      })
      .catch((err: any) => { });
  }, []);
  const handleShareWith: any = (item: any) => {
    setRowItems(item);
    LabourMove.open();
    // toolMaterialShare.open(idGet,shareItemCodeGet,listCommodityGet,shareItemNameGet)
  };
  const ReverseUpload = () => {
    return inputElRefPdf.current?.click?.();
  };

  const InfoHandler: any = (item: any) => {
    setRowItems(item)
    infomodal.open()
  }

  const swalalterHandlerAdd = useCallback((type: any, action: any) => {
    if (action == "cearte") {

      if (type == true) {
        Swal.fire({
          html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
              <br />
              <p style="color:#007fff;">Created successfully!</p>   
               </div>`,
        });
      }

    } else {
      if (type == true) {
        Swal.fire({
          html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
              <br />
              <p style="color:#007fff;">Updated successfully!</p>   
               </div>`,
        });
      }

    }
  }, []);

  React.useEffect(() => {
    settableLoder(true);

    API.get("/api/db/labour/", {
      commodity: categoryName,
    })
      .then((res: any) => {
        settableLoder(false);
        setTableData(res?.data);
      })
      .catch((err: any) => {
        settableLoder(false);
        console.log("Sever EEror");
      });
  }, [categoryName, Counter]);

  const EditHandler = (row: any, e: any) => {
    Editmachinemodal.open();
    setRowsItem(row);
  };

  const LockHandler = (item: any, type: any) => {
    Swal.fire({
      title: item?.lock == true ? "Unlock" : "Lock",
      text: item?.lock == true == true
        ? "Are you sure you want to Unlock this parameter?"
        : "Are you sure you want to lock this parameter?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result: any) => {
      if (result.isConfirmed) {
        // setLoader(true);
        API.put(`/api/db/labour/${item?.id}/`, {
          lock: type, revision: revisionId,
          category: categoryName
        }).then((res: any) => {
          setCounter((prev: any) => prev + 1);
        })
          .catch((err: any) => {
            console.log("Server Error");
            // setLoader(false);
          });
      }
    });
  }



  const columns: any = [
    {
      field: "Actions",
      headerName: "Actions",
      width: width > 1400 ? 180 : 150,
      renderCell: (cellvalues: any) => {
        return (<Box sx={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem' }}>
          {cellvalues?.row?.lock === true ? (
            // Case 1: If user is SuperUser or ProjectAdmin, show clickable green lock
            (AUTH.isSuperUser || AUTH.isProjectAdmin) ? (
              <LockIcon
                sx={{ cursor: 'pointer', color: 'green' }}
                onClick={() => LockHandler(cellvalues?.row, false)}
              />
            ) : (
              // Case 2: If user is not SuperUser or ProjectAdmin, show disabled lock
              <LockIcon sx={{ opacity: 0.5 }} />
            )
          ) : (
            // Case 3: If lock is false and user is SuperUser or ProjectAdmin, show red open lock
            (AUTH.isSuperUser || AUTH.isProjectAdmin) && (
              <LockOpenIcon
                sx={{ cursor: 'pointer', color: 'red' }}
                onClick={() => LockHandler(cellvalues?.row, true)}
              />
            )
          )}

          {cellvalues?.row?.mapped_status ?
            <IconButton title="Labour Info" sx={{ cursor: "pointer", color: 'primary.main', padding: '0' }} onClick={() => InfoHandler(cellvalues?.row)}><InfoIcon /></IconButton> :
            useraction && useraction?.includes("D") && <IconButton title="Delete" sx={{ cursor: "pointer", color: 'primary.main', padding: '0', opacity: cellvalues?.row?.lock == true ? 0.3 : 1 }} onClick={() => {
              if (cellvalues?.row?.lock != true) { DeleteHandler(cellvalues?.row) }
            }} ><DeleteIcon /></IconButton>}
          {/* <LaunchIcon style={{cursor:"pointer"}} onClick={()=> redirectionHanlder(cellvalues?.row) }/> */}
          {cellvalues.row.flag == null ? <IconButton title="Flag" sx={{ color: 'primary.main', padding: '0' }}><FlagOutlinedIcon /></IconButton>
            : <IconButton title="Flag" sx={{ cursor: "pointer", color: 'primary.main', padding: '0' }} onClick={() => cellvalues.row.flag !== null && window.open(cellvalues?.row?.flag, "_blank")} ><FlagIcon sx={{ color: 'green' }} /></IconButton>
          }
          {useraction && useraction?.includes("U") && <IconButton
            title="Move"
            sx={{ color: "#007fff", padding: "0rem", cursor: "pointer", opacity: cellvalues?.row?.lock == true ? 0.3 : 1 }}
            onClick={() => {
              if (cellvalues?.row?.lock != true) { handleShareWith(cellvalues?.row) }
            }}
          >
            <img src={move} style={{ height: '1.3rem', cursor: 'pointer' }} />
          </IconButton>}
          {useraction && useraction?.includes("U") && <EditIcon titleAccess="Edit" style={{ cursor: "pointer", opacity: cellvalues?.row?.lock == true ? 0.3 : 1 }} onClick={(e: any) => {
            if (cellvalues?.row?.lock != true) { EditHandler(cellvalues?.row, e) }
          }} />}
        </Box>)
      }
    },
    { field: "Ids", headerName: "Sl.No", width: 90 },

    {
      field: "labour_code",
      headerName: "Labour Code",
      width: 180,
    },

    {
      field: "labour_type",
      headerName: "Labour Type",
      width: 150,
    },
    {
      field: 'currency_code',
      headerName: 'Currency/Unit',
      sortable: false,
      minWidth: width > 1370 ? 150 : width < 1200 ? 150 : 150,
      renderCell: (cellValues: any) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', columnGap: '1rem', }}>
            {`${cellValues?.row?.currency_code ? cellValues?.row?.currency_code : "--"}/${cellValues?.row?.unit ? cellValues?.row?.unit : "--"}`}
          </Box>
        );
      },
    },

    {
      field: "description",
      headerName: "Description",
      width: width > 1400 ? 800 : 650,
      flex: 1,
    },


  ];


  const Rows: any = () =>
    TableData &&
    TableData?.map((item: any, index: any) => ({ ...item, Ids: index + 1 }));

  const AddLabourHanlder = (e: any) => {
    e?.stopPropagation();
    labourModal.open();
  };

  React.useEffect(() => {
    setCommodityLoader(true);
    API.get("/api/db/cost_databases/", {
      database_type: "labour",
    })
      .then((res: any) => {
        setCommodityLoader(false);
        setCategoryList(res?.data);
        if (categoryName == 0) {
          setTabValue(res?.data[0]?.id);
          history.push(
            `/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/laborDB/${res?.data[0]?.id}/${status}/0/${lockCounter}`
          );

        }
      })
      .catch((err: any) => {
        setCommodityLoader(false);
      });
  }, [revisionId, Counter]);

  const handleInputClick = (e: React.MouseEvent) => {
    // clear the prev value on click so that same file can be uploaded again
    (e.target as HTMLInputElement).value = "";
  };

  const handleDBChange = (item: any) => (event: React.SyntheticEvent, newExpanded: boolean) => {
    // console.log(item,'panel');
    setExpanded(newExpanded ? item : false);
    // console.log(item,expanded, 'panel');    
  }

  React.useEffect(() => {
    if (databaseName == 'rmDB') {
      setExpanded('rmDB');
    } else if (databaseName == 'bopDB') {
      setExpanded('bopDB');
    } else if (databaseName == 'machineDB') {
      setExpanded('machineDB');
    } else if (databaseName == 'laborDB') {
      setExpanded('laborDB');
    } else if (databaseName == 'toolDB') {
      setExpanded('toolDB');
    } else if (databaseName == 'operationsDB') {
      setExpanded('operationsDB');
    } else if (databaseName == 'currencyDB') {
      setExpanded('currencyDB');
    }
  }, [databaseName]);

  const redirectDBHandler = (item: any) => {
    history.push(`/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/${item}/${0}/${status}/${search}/${lockCounter}`);
  }



  const DeleteHandler = (item: any) => {

    swal({
      text: "Are you sure, you want to delete ?",
      buttons: ["Cancel", "Ok"],
      icon: "warning",
    }).then((confirm: any) => {
      if (confirm) {
        API.delete(`/api/db/labour/${item?.id}/`).then((res: any) => {
          setCounter((prev: any) => prev + 1)
          setRecyclebinCounter((prev: any) => prev + 1)
        }).catch((err: any) => {

        })
      }
    });





  }
  const UploadFile = (e: any) => {
    const formData = new FormData();

    formData.append("file", e?.target?.files[0]);
    setReverseUpload(true);
    API.post("/api/db/import_labour_newui/", formData, {})
      .then((res: any) => {
        // setParentsCounter((prev: any) => prev + 1);
        setCounter((prev: any) => prev + 1);
        Swal.fire({
          html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
        <br />
        <p style="color:#007fff;">${res?.data}</p>   
         </div>`,
        });
        setReverseUpload(false);
      })
      .catch((err: any) => {
        setReverseUpload(false);

        Swal.fire({
          icon: "error",
          html: `<div>
          <br />
          <p style="color:red;">Server Error</p>   
           </div>`,
        });


      });
  };

  const DownLoadFile = (e: any) => {
    e?.stopPropagation();
    setLoader(true);
    API.get("/api/db/database/download/labour/")
      .then((res: any) => {
        window.open(res?.data, "_self", " noopener");
        setLoader(false);
      })
      .catch((err: any) => {
        setLoader(false);

        Swal.fire({
          icon: "error",
          html: `<div>
          <br />
          <p style="color:red;">Server Error</p>   
           </div>`,
        });
        console.log("Sever Error");
      });
  };



  const swalalterHandler = useCallback((type: any) => {
    if (type == true) {
      setCounter((prev: any) => prev + 1);
      Swal.fire({
        html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
    
      <p style="color:#007fff;">Moved successfully!</p>   
       </div>`,

      });
    } else {
      Swal.fire({
        html: `<div>
  
    
  
      <p style="color:red;">Server Error!</p>   
       </div>`,
        icon: "error",
      });
    }
  }, []);

  return (
    <Box sx={{ width: "100%" }}>
      {(useraction && useraction?.includes("C") || useraction && useraction?.includes("U")) && <input
        id="PDF Upload"
        accept=".csv,.xlsx"
        type="file"
        style={{ display: "none" }}
        onChange={(e: any) => UploadFile(e)}
        onClick={handleInputClick}
        ref={inputElRefPdf}
      />}
      {labourModal.isOpen && (
        <AddLabourModal
          swalalterHandlerAdd={swalalterHandlerAdd}
          setCounter={setCounter}
          onCloseModal={labourModal.close}
          isOpen={labourModal.isOpen}
        />
      )}

      {infomodal.isOpen &&
        <LHRDatabaseInfoModal

          onCloseModal={infomodal?.close}
          isOpen={infomodal?.isOpen}
          Item={rowItems}


        />
      }

      {LabourMove.isOpen && (
        <LabourmoveModal
          type={"library"}
          isOpen={LabourMove.open}
          onCloseModal={LabourMove.close}
          rowItems={rowItems}
          swalalterHandler={swalalterHandler}
        />
      )}
      {Editmachinemodal.isOpen && (
        <AddLabourModal
          type={"edit"}
          swalalterHandlerAdd={swalalterHandlerAdd}
          actions={RowsItem}
          setCounter={setCounter}
          onCloseModal={Editmachinemodal.close}
          isOpen={Editmachinemodal.isOpen}
        />
      )}

      <Box sx={{ display: 'grid', gridTemplateColumns: '20rem 1fr', width: '100%' }}>
        <Box sx={{ width: '100%', height: { lg: '87vh', xl: '89vh' } }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3">
          <Accordion sx={{ border: 'none', marginLeft: '0.5rem' }} expanded={expanded === 'rmDB'} onClick={() => redirectDBHandler('rmDB')}>
            <AccordionSummary aria-controls="panel1d-content" id="panel1d-header"
              expandIcon={
                expanded === 'rmDB' ? (
                  <img
                    src={openFolder}
                    style={{ height: '1.2rem' }}
                  />
                ) : (
                  <FolderIcon
                    sx={{
                      fontSize: '1.5rem',
                      color: 'primary.main',
                    }}
                  />
                )
              } sx={{ minHeight: '24px', padding: '0', }}>
              <Typography sx={{ marginLeft: '0.5rem', fontSize: '1rem' }}>Raw Material</Typography>
            </AccordionSummary>
            <AccordionDetails>

            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ border: 'none', marginLeft: '0.5rem' }} expanded={expanded === 'bopDB'} onClick={() => redirectDBHandler('bopDB')} >
            <AccordionSummary aria-controls="bopDB-content" id="bopDB-header" expandIcon={
              expanded === 'bopDB' ? (
                <img
                  src={openFolder}
                  style={{ height: '1.2rem' }}
                />
              ) : (
                <FolderIcon
                  sx={{
                    fontSize: '1.5rem',
                    color: 'primary.main',
                  }}
                />
              )
            } sx={{ minHeight: '24px', padding: '0', }}>
              <Typography sx={{ marginLeft: '0.5rem', fontSize: '1rem' }}>Bought-out Parts</Typography>
            </AccordionSummary>
            <AccordionDetails>

            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ border: 'none', marginLeft: '0.5rem' }} expanded={expanded === 'machineDB'} onClick={() => redirectDBHandler('machineDB')}>
            <AccordionSummary aria-controls="machineDB-content" id="machineDB-header" expandIcon={
              expanded === 'machineDB' ? (
                <img
                  src={openFolder}
                  style={{ height: '1.2rem' }}
                />
              ) : (
                <FolderIcon
                  sx={{
                    fontSize: '1.5rem',
                    color: 'primary.main',
                  }}
                />
              )
            } sx={{ minHeight: '24px', padding: '0', }}>
              <Typography sx={{ marginLeft: '0.5rem', fontSize: '1rem' }}>Machine Hour Rates</Typography>
            </AccordionSummary>
            <AccordionDetails>

            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ border: 'none', marginLeft: '0.5rem' }} expanded={expanded === 'laborDB'} onChange={handleDBChange('laborDB')} >
            <AccordionSummary aria-controls="laborDB-content" id="laborDB-header" expandIcon={
              expanded === 'laborDB' ? (
                <img
                  src={openFolder}
                  style={{ height: '1.2rem' }}
                />
              ) : (
                <FolderIcon
                  sx={{
                    fontSize: '1.5rem',
                    color: 'primary.main',
                  }}
                />
              )
            } sx={{ minHeight: '24px', padding: '0', }}>
              <Typography sx={{ marginLeft: '0.5rem', fontSize: '1rem' }}>Labour Hour Rates</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "column",
                  columnGap: "0.5rem",
                  width: "94%",
                  borderLeft: "1px solid",
                  marginLeft: '1rem',
                }}
                adl-scrollbar="true"
                adl-scrollbar-width="0.2"
              >
                {CommodityLoader ? (
                  <Box sx={{ padding: "0 1rem", width: "100%" }}>
                    <Skeleton
                      variant="rectangular"
                      height="2rem"
                      width="100%"
                      sx={{ marginBottom: "1rem" }}
                    />

                    <Skeleton
                      variant="rectangular"
                      height="2rem"
                      width="100%"
                      sx={{ marginBottom: "1rem" }}
                    />

                    <Skeleton
                      variant="rectangular"
                      height="2rem"
                      width="100%"
                      sx={{ marginBottom: "1rem" }}
                    />
                    <Skeleton
                      variant="rectangular"
                      height="2rem"
                      width="100%"
                      sx={{ marginBottom: "1rem" }}
                    />
                    <Skeleton
                      variant="rectangular"
                      height="2rem"
                      width="100%"
                      sx={{ marginBottom: "1rem" }}
                    />
                    <Skeleton
                      variant="rectangular"
                      height="2rem"
                      width="100%"
                      sx={{ marginBottom: "1rem" }}
                    />
                    <Skeleton
                      variant="rectangular"
                      height="2rem"
                      width="100%"
                      sx={{ marginBottom: "1rem" }}
                    />
                  </Box>
                ) : (
                  <>
                    {CategoryList &&
                      CategoryList?.map((item: any, index: any) => {
                        return (
                          <>
                            <Box
                              key={index}
                              sx={{
                                color: "primary.main",
                                // height: "2rem",
                                // minHeight: "2.1rem",
                                maxWidth: "100% !important",
                                padding: "0.1rem 0",
                                fontSize: "1rem",
                                width: "100%",
                                backgroundColor:
                                  item?.id == categoryName
                                    ? "primary.main"
                                    : "",
                                cursor: "pointer",
                                display: "flex",
                                alignItems: "center",
                              }}
                            ><Typography sx={{
                              whiteSpace: "nowrap", color:
                                item?.id == categoryName
                                  ? "white"
                                  : "primary.main",
                            }}>--</Typography>
                              <Typography
                                sx={{
                                  color:
                                    item?.id == categoryName
                                      ? "white"
                                      : "primary.main",
                                  // height: "2rem",
                                  // minHeight: "2.1rem",
                                  maxWidth: "100% !important",
                                  padding: "0.1rem 0rem",
                                  fontSize: "1rem",
                                  width: "100%",
                                  textAlign: "center",
                                  display: "flex",
                                  whiteSpace: "nowrap",
                                  alignItems: "center",
                                  justifyContent: "flex-start",
                                  textTransform: "capitalize",
                                }}
                                onClick={() => handleChange(item?.id)}
                              >
                                {item?.name.toLowerCase()} ({item?.count})
                              </Typography>
                            </Box>
                          </>
                        );
                      })}
                  </>)}
              </Box>
            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ border: 'none', marginLeft: '0.5rem' }} expanded={expanded === 'toolsDB'} onClick={() => redirectDBHandler('toolsDB')} >
            <AccordionSummary aria-controls="toolsDB-content" id="toolsDB-header" expandIcon={
              expanded === 'toolsDB' ? (
                <img
                  src={openFolder}
                  style={{ height: '1.2rem' }}
                />
              ) : (
                <FolderIcon
                  sx={{
                    fontSize: '1.5rem',
                    color: 'primary.main',
                  }}
                />
              )
            } sx={{ minHeight: '24px', padding: '0', }}>
              <Typography sx={{ marginLeft: '0.5rem', fontSize: '1rem' }}>Tools</Typography>
            </AccordionSummary>
            <AccordionDetails>

            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ border: 'none', marginLeft: '0.5rem' }} expanded={expanded === 'operationsDB'} onClick={() => redirectDBHandler('operationsDB')} >
            <AccordionSummary aria-controls="operationsDB-content" id="operationsDB-header" expandIcon={
              expanded === 'operationsDB' ? (
                <img
                  src={openFolder}
                  style={{ height: '1.2rem' }}
                />
              ) : (
                <FolderIcon
                  sx={{
                    fontSize: '1.5rem',
                    color: 'primary.main',
                  }}
                />
              )
            } sx={{ minHeight: '24px', padding: '0', }}>
              <Typography sx={{ marginLeft: '0.5rem', fontSize: '1rem' }}>Operation Cost</Typography>
            </AccordionSummary>
            <AccordionDetails>

            </AccordionDetails>
          </Accordion>
          <Accordion sx={{ border: 'none', marginLeft: '0.5rem' }} expanded={expanded === 'currencyDB'} onClick={() => redirectDBHandler('currencyDB')}>
            <AccordionSummary aria-controls="currencyDB-content" id="currencyDB-header" expandIcon={
              expanded === 'currencyDB' ? (
                <img
                  src={openFolder}
                  style={{ height: '1.2rem' }}
                />
              ) : (
                <FolderIcon
                  sx={{
                    fontSize: '1.5rem',
                    color: 'primary.main',
                  }}
                />
              )
            } sx={{ minHeight: '24px', padding: '0', }}>
              <Typography sx={{ marginLeft: '0.5rem', fontSize: '1rem' }}>Currency</Typography>
            </AccordionSummary>
            <AccordionDetails>

            </AccordionDetails>
          </Accordion>
        </Box>
        <Box>
          {CommodityLoader ? (
            <Box sx={{ padding: "0 1rem", width: "100%" }}>
              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />

              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />

              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />

              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />
              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />
              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />
              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />
              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />
              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />
              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />
              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />
              <Skeleton
                variant="rectangular"
                height="2rem"
                width="100%"
                sx={{ marginBottom: "1rem" }}
              />
            </Box>
          ) : (
            <>
              <Box>
                {/* <Box sx={{
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: '0.5rem',
                  width: '100%',
                  borderBottom: '1px solid',
                }} adl-scrollbar="true"
                  adl-scrollbar-width="0.2">
                  {CategoryList &&
                    CategoryList?.map((item: any, index: any) => {
                      return (
                        <>
                          <Box
                            key={index}
                            sx={{
                              color: '#007fff',
                              height: '2rem',
                              minHeight: '2.1rem',
                              maxWidth: '100% !important',
                              padding: '0',
                              fontSize: '1rem',
                              width: '100%',
                              backgroundColor:
                                item?.id == categoryName
                                  ? 'primary.main'
                                  : 'primary.light',
                              cursor: 'pointer',
                              display: 'flex',
                              alignItems: 'center',
                            }}>
                            <Typography
                              sx={{
                                color:
                                  item?.id == categoryName ? 'white' : '#007fff',
                                height: '2rem',
                                minHeight: '2.1rem',
                                maxWidth: '100% !important',
                                padding: '0 0.5rem',
                                fontSize: '1rem',
                                width: '100%',
                                textAlign: 'center',
                                display: 'flex',
                                alignItems: 'center',
                                whiteSpace: 'nowrap',
                                justifyContent: 'center',
                                textTransform: 'capitalize',
                              }}
                              onClick={() => handleChange(item?.id)}>
                              {item?.name.toLowerCase()} ({item?.count})
                            </Typography>
                          </Box>                          
                        </>
                      );
                    })}
                </Box> */}

                <Box sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: 'flex-start',
                  marginLeft: "0.5rem",
                  columnGap: "0.5rem",
                }}>
                  {(
                    <Box sx={{ display: 'flex', alignItems: 'center', marginLeft: '0.5rem', columnGap: '0.5rem' }}>
                      {(useraction && useraction?.includes("U")) &&
                        <LoadingButton
                          loading={resverUpload}
                          title="Upload"
                          onClick={() => ReverseUpload()}
                          sx={{
                            padding: '0.2rem 1rem',
                            zIndex: 1,
                            cursor: "pointer", fontSize: { lg: '1.8rem', xl: '1.6rem' }, minWidth: '1rem',
                            '.MuiCircularProgress-root': {
                              color: 'primary.main',
                            },
                          }}
                          startIcon={<UploadIcon sx={{ marginRight: '-1rem' }} />}
                        />
                      }
                      <LoadingButton
                        title="Download"
                        sx={{
                          padding: '0.2rem 1rem',
                          zIndex: 1,
                          color: statusColor ? "red" : "primary.main", minWidth: '1rem', fontSize: { lg: '1.8rem', xl: '1.6rem' },
                          '.MuiCircularProgress-root': {
                            color: 'primary.main',
                          },
                        }}
                        loading={Loader}
                        onClick={(e: any) => DownLoadFile(e)}
                        startIcon={<GetAppIcon sx={{ marginRight: '-1rem' }} />}
                      />
                      {/* {console.log((useraction && useraction?.includes("C")), "useraction", useraction)} */}
                      {(useraction && useraction?.includes("C")) &&
                        <Button
                          size="small"
                          sx={{ width: '9.5rem', zIndex: 1, }}
                          onClick={(e: any) => AddLabourHanlder(e)}
                          startIcon={<AddIcon sx={{ marginRight: '-0.5rem' }} />}>
                          ADD Labour
                        </Button>}
                    </Box>
                  )}
                </Box>
                <Box
                  sx={{
                    height: { lg: '83vh', xl: '85vh' },
                    width: "100%",
                    "& .super-app-theme--cell": {
                      backgroundColor: "#ddefff !important",
                      textAlign: "center !important",
                    },
                  }}>
                  {Rows() && Rows() && (
                    <DataGrid
                      loading={tableLoader}
                      headerHeight={48}
                      rowHeight={48}
                      rows={Rows()}
                      columns={columns}
                      disableSelectionOnClick={true}
                      disableColumnSelector={true}
                      density="compact"

                      components={{
                        Toolbar: () => {
                          return CustomToolbar();
                        },
                      }}
                      sx={{
                        "& ::-webkit-scrollbar": {
                          width: "0.3rem",
                          height: "0.3rem",
                        },
                        "& ::-webkit-scrollbar-thumb": {
                          backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                          borderRadius: "10px",
                        },
                        "& ::- webkit-scrollbar-thumb: hover ": {
                          backgroundColor: "#045DE9 !important",
                        },
                        "::-webkit-scrollbar-track": {
                          backgroundColor: "#e1e1f3 !important",
                        },
                        "&.MuiDataGrid-root": {
                          border: "none",
                        },
                        "&.MuiDataGrid-root .MuiDataGrid-cell": {
                          borderBottomColor: "primary.light",
                        },
                      }}

                    />
                  )}
                </Box>
              </Box>
            </>
          )}
        </Box>
      </Box>
    </Box>
  );
}
