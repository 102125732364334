
import { ID } from "../../utlis/commonInterfaces";
import { CreateProjectArg, DeleteProjectArg, UpdateProjectArg } from "../Actions/project.actions";
import { API } from "./index.service";

const url = "xcpep/project/";
const top_vault_url = "xcpep/top_vault/";

export default {
    get ( module: number , vehicle_type?:any ) { 
        return API.get( url, { module  , vehicle_type} );
    },

    getTopVault ( project?: number, module?: number ) {
        return API.get( top_vault_url, { project, module } );
    },

    getById ( projectId: ID ) {
        return API.get( url, { id: projectId } );
    },

    create ( arg: CreateProjectArg ) {
        return API.post( url, arg );
    },

    update ( arg: UpdateProjectArg ) {
        return API.put( url + arg.id + "/", arg.updates );
    },

    delete ( arg: DeleteProjectArg ) {
        return API.delete( url + arg.id + "/", { trash: arg.recycle } );
    },
};
