import { Box, ButtonProps, Dialog, Divider, Modal, Skeleton, Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import React, { useEffect, useState } from "react";
import { API } from "../../../api-services";
import useWindowDimensions from "../../../../Components/ui-reusable-component/useWindowDimensions";
import InfoIcon from '@mui/icons-material/Info';
import {
  DataGrid,
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExportContainer,
} from "@mui/x-data-grid";
import LoadingButton from "@mui/lab/LoadingButton";
import { useBaseModal } from "../../SearchPage/useBaseModal";
import RViewerJS from "viewerjs-react";
import CameraAltIcon from '@mui/icons-material/CameraAlt';
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  width: "75vw",
  // height: '80vh',
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

interface SheetViewModal {
  onCloseModal: any;
  isOpen: any;
  getData: any;
}
const csvOptions: GridCsvExportOptions = {
  fileName: `Sheet View Table`,
  delimiter: ",",
  utf8WithBom: true,
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  
   <GridToolbarContainer style={{ height: "2rem" }}>
   <span style={{ position: "absolute", right: "0rem", fontSize: "1rem", paddingRight: '1rem' }}>
 
     <GridToolbarColumnsButton />
     <CustomExportButton />
   </span>
 </GridToolbarContainer>
);
export default function SheetInfoModal(props: SheetViewModal) {
  const { onCloseModal, isOpen, getData } = props;
  const [SheetData, setSheetData] = useState<any>([]);
  const [loader, setloader] = useState<any>(true);
  const { height, width } = useWindowDimensions();


  useEffect(() => {
    setloader(true);
    API.get(`/xcpep/sheet_view/`, { vault: getData?.id, sheet_view: true ,compact:true}, 0)
      .then((res: any) => {
        setSheetData(res?.data);
        setloader(false);
      })
      .catch((err: any) => {
        setloader(false);
        console.log("Server Error");
      });
  }, [getData]);
 

  const TableRow = () => {
    var valuedata =
      SheetData &&
      SheetData.map((item: any, id: any) => ({
        ...item,
        id: id + 1,
      }));

    return valuedata;
  };

console.log(SheetData,"Cdhcdjucbjkdcbjdbjcdbj")

  let columns: any = [
    { field: "id", headerName: "id",hide:true, width: width > 1370 ? 50 : width < 900 ? 50 : 50, },
    {
      field: "Part Name",
      headerName: "Part Name",
      // headerAlign: "center",
      // align: 'right',
      minWidth: width > 1370 ? 100 : width < 900 ? 120 : 100,
      flex: 1,
    },
    { field: "Part Number", headerName: "Part Number", minWidth: width > 1370 ? 300 : 200, flex: 1 },
    {
      field: "Part Quantity",
      headerName: "Part Quantity",
      minWidth: width > 1370 ? 80 : 40,
    //   flex: 1,
    },
    {
        field: "Category",
        headerName: "Category",
        minWidth: width > 1370 ? 80 : 40,
      //   flex: 1,
      },
     

    {
      field: "Weight",
      headerName: "Weight(g)",
      minWidth: width > 1370 ? 80 : 40,
    //   flex: 1,
    },
    {
        field: "BOM View",
        headerName:"BOM View",
        minWidth: width > 1370 ? 80 : 40,
        renderCell: (cellValues: any) => {
            return (<>
             
             <span style={{borderBottom:"1px solid",cursor:"pointer"}} onClick={()=>{window.open(cellValues?.row?.["BOM View"])}}>Link</span>
              
             
            </>)
          }
      //   flex: 1,
      },
      {
        field: "Costing View",
        headerName: "Costing View",
        minWidth: width > 1370 ? 80 : 40,
        renderCell: (cellValues: any) => {
            console.log(cellValues?.row?.["Costing View"],"vdbvdbvbjdvbjdbjv")
            return (<>
             
             <span style={{borderBottom:"1px solid",cursor:"pointer"}} onClick={()=>{window.open(cellValues?.row?.["Costing View"])}}>Link</span>
              
             
            </>)
          }
      //   flex: 1,
      },
    // {
    //   field: "quantity",
    //   headerName: "Quantity",
    //   minWidth: width > 1370 ? 80 : 40,
    //   flex: 1,
    // },
 
 
    {
      field: "Weight Image",
      headerName: "Weight Image",
      
      // width: width > 1370 ? 105 : width < 1200 ? 80 : 100,
      renderCell: (cellValues: any) => {
        return (<>
       
                 <span style={{borderBottom:"1px solid ",cursor:"pointer"}} onClick={()=>window.open(cellValues?.row?.["Weight Image"], "_blank"," noopener")}>Image </span>
               
       
      
      
   

        </>
        )
      }
    },
  ];
  var columsData = () => {
    console.log(SheetData);
    if (SheetData?.length > 0) {
      return Object?.keys(SheetData[0]);
    }
  };
  // console.log(BomparameterData())

  const dataRender: any = {
    columns: columns && columns,
    rows: TableRow(),
  };



  return (
    <Modal
      open={isOpen}
      onClose={onCloseModal}
      disableEscapeKeyDown={true}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>


     

        <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
          <Typography
            style={{
              fontSize: "1rem",
              fontWeight: "600",
              width: "100%",
              textAlign: "center",
              marginRight: "-2rem",
            }}
          >
            Sheet View Table({getData?.name})
          </Typography>
          <CancelIcon
            titleAccess="Close"
            sx={{ cursor: "pointer", margin: "0.5rem" }}
            onClick={onCloseModal}
          />
        </Box>
        <Divider
          sx={{
            borderColor: "primary.light",
            margin: "0 1rem",
            marginBottom: "1rem",
          }}
        />
        <Box
          sx={{ height: "75vh", width: "100%" }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3"
        >
          {
            loader?
            <><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton></>
            
            :<>
           
          {columsData() && columsData() && (
            <DataGrid
              {...dataRender}
              headerHeight={48}
              rowHeight={36}
              loading={loader}
              density="compact"
              // autoHeight={true}
              components={{
                Toolbar: () => {
                  return CustomToolbar();
                },
              }}
              // hideFooter={true}
              // hideFooterPagination={true}
              sx={{
                "&.MuiDataGrid-root .MuiDataGrid-cell": {
                  borderBottomColor: "primary.light",
                },
                "& ::-webkit-scrollbar": {
                  width: "0.3rem",
                  height: "0.3rem",
                },
                "& ::-webkit-scrollbar-thumb": {
                  // background: $thumb-color;
                  backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                  borderRadius: "10px",
                },
                "& ::- webkit-scrollbar-thumb: hover ": {
                  // width: "4px",
                  backgroundColor: "#045DE9 !important",
                },
                "::-webkit-scrollbar-track": {
                  backgroundColor: "#e1e1f3 !important",
                },
              }}
            />
          )}
           </>

}
        </Box>
      </Box>
    </Modal>
  );
}
