import * as React from "react";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControl,
  FormControlLabel,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  Skeleton,
  Theme,
  Typography,
  useTheme,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory, useRouteMatch } from "react-router-dom";
import styles from "./IdeaConfigurationLanding.module.scss";
import Drawer from "@mui/material/Drawer";
import Divider from "@mui/material/Divider";
import AddIcon from "@mui/icons-material/Add";
import PlaylistAddIcon from "@mui/icons-material/PlaylistAdd";
import ShareIcon from "@mui/icons-material/Share";
import IdeaConfiguration from "./IdeaConfiguration";
import CancelIcon from "@mui/icons-material/Cancel";
import { API } from "../../api-services";
import { useBaseModal } from "../../ComponentUI/SearchPage/useBaseModal";
import AddParameterModal from "./AddParameterModal";
import VerifiedIcon from "@mui/icons-material/Verified";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import InfoIcon from "@mui/icons-material/Info";
import ShareIdeaParameterModal from "./ShareIdeaParameterModal";
import LoadingButton from "@mui/lab/LoadingButton";
import ErrorModalFilesMail from "../../ComponentUI/ProjectMailModule/ErrorModalFilesMail";
import { useState } from "react";
import CostingConfigurationDrawer from "../Costing/CostingConfigurationDrawer";
import IdeaSequenceEditModal from "./IdeaSequenceEditModal";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
type Anchor = "top" | "left" | "bottom" | "right";
export const UserContextIdea = React.createContext<any>(null)


export interface IIdeaConfigurationLandingProps { }


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};
function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

export function IdeaConfigurationLanding(
  props: IIdeaConfigurationLandingProps
) {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { projectId, projectname, datatype } = useRouteParams<any>();
  const theme = useTheme();
  const addeditParamModal = useBaseModal();
  const sequenceEdit = useBaseModal()
  const ideaParamsShare = useBaseModal();
  const [productList, setProductList] = React.useState<any>([]);
  const [Premisssionaction, setPremissionaction] = useState<any>(null);
  const [products, setProducts] = React.useState<any>([]);
  const [count, setCount] = React.useState<any>(0);
  const [dataCounter, setDataCounter] = useState<any>(0)
  const [checked, setChecked] = React.useState<any>(false);
  const [DataTypeList, setDataTypeList] = useState<any>([]);
  const [Loader, setLoader] = useState<any>(false);
  const [DownloadLoader, setDownloadLoader] = useState<any>(false);
  const [totalParamsCount, setTotalParamsCount] =
    React.useState<any>(undefined);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [datatypeListUpdate, setDatatypeListUpdate] = React.useState<any>(false);

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    // setCreatedBy(createdby);
    // setCreatedOn(createdon);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
    // setCreatedBy(undefined);
    // setCreatedOn(undefined);
  };
  const open = Boolean(anchorEl);

  const [ErrorModalFileMsg, setErrorModalFileMsg] = useState<any>();
  const ErrorModals = useBaseModal();
  const getProductList = () => {
    API.get(
      "/xcpep/top_vault/",
      {
        project: projectId,
        module: 14,
      },
      0
    )
      .then((res: any) => {
        setProductList(res.data);
      })
      .catch((err: any) => { });
  };
  React.useEffect(() => {
    getProductList();
  }, [projectId]);




  React.useEffect(() => {
    API.get(
      "/auth/calibration_user_permission/",
      {
        action: true,
        configuration: true,
        project: projectId,
        module: "Ideas Configuration"
      },
      0
    )
      .then((res: any) => {
        setPremissionaction(res.data.action);
      })
      .catch((err: any) => { });
  }, [projectId]);

  // console.log(Premisssionaction && Premisssionaction?.includes("C") ? false : true, "PremisssionactionPremisssionaction")
  React.useEffect(() => {
    setLoader(true);
    API.get("/config/parameter_datatype_config/", {
      project: projectId,
      module: 14,
    })
      .then((res: any) => {
        setLoader(false);
        setDataTypeList(res?.data);
      })
      .catch((err: any) => {
        setLoader(false);
        console.log("Server Error");
      });
  }, [dataCounter, datatypeListUpdate]);


  const getBaseVehData = () => {
    API.get(
      "/xcpep/top_vault/",
      {
        project: projectId,
        module: 15,
      },
      0
    )
      .then((res: any) => {
        let selected = res.data
          ?.filter((itm: any) => itm.idea_base === true)
          .map((item: any) => item.id);
        setProducts(selected);
      })
      .catch((err: any) => { });
  };
  React.useEffect(() => {
    getBaseVehData();
  }, [projectId]);
  const getValidationData = () => {
    API.get("/xcpep/project/", { id: projectId }, 0)
      .then((res: any) => {
        setChecked(res.data[0]?.idea_config);
      })
      .catch((err: any) => { });
  };
  React.useEffect(() => {
    getValidationData();
  }, [projectId, count]);

  const handleChangeCheckBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    API.patch(
      `/xcpep/project/${projectId}/`,
      {
        idea_config: event.target.checked,
      },
      {},
      0
    )
      .then((res: any) => {
        getValidationData();
      })
      .catch((err: any) => {
        const { data } = err?.response;
        ErrorModals.open();

        setErrorModalFileMsg(data[0]);
      });
  };
  const handleChange = (event: any) => {
    const {
      target: { value },
    } = event;
    setProducts(value);
    API.put(
      `/xcpep/top_vault/${parseInt(value[value?.length - 1])}/`,
      {
        idea_base: true,
      },
      {},
      0
    )
      .then((res: any) => { })
      .catch((err: any) => { });
  };

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }

        setState({ ...state, [anchor]: open });
      };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
    // onClick={toggleDrawer(anchor, false)}
    // onKeyDown={toggleDrawer(anchor, false)}
    >
      <CostingConfigurationDrawer />
    </Box>
  );

  const handleClick = () => {
    history.goBack();
  };

  const DataTypeHandler = (item: any) => {
    history.push(
      `/ideaconfiguration/${projectId}/${projectname}/${item?.parameter__data_type}`
    );
  };

  const downloadhandler = () => {
    setDownloadLoader(true);
    API.get(
      "/config/parameter_mapping_download/",
      { project: projectId, module: 14 },
      0
    )
      .then((res: any) => {
        window.open(res?.data);
        setDownloadLoader(false);
      })
      .catch((err: any) => {
        setDownloadLoader(false);
        console.log("Sever Error");
      });
  };
  const sequenceHandler = () => {
    sequenceEdit?.open()
  }

  const updateDatatypeList = () => {
    setDatatypeListUpdate(!datatypeListUpdate)
  }
  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  return (
    <div>
      {addeditParamModal.isOpen && (
        <AddParameterModal
          isOpen={addeditParamModal.isOpen}
          onCloseModal={addeditParamModal.close}
          setCount={setCount}
          setDataCounter={setDataCounter}
          datatype={datatype}
          updateDatatypeList={updateDatatypeList}

        />
      )}
      {sequenceEdit?.isOpen &&
        <IdeaSequenceEditModal
          isOpen={sequenceEdit.isOpen}
          onCloseModal={sequenceEdit.close}
          setCount={setCount}


        />
      }

      {ErrorModals.isOpen && (
        <ErrorModalFilesMail
          isOpen={ErrorModals.isOpen}
          onCloseModal={ErrorModals.close}
          ErrorMsg={ErrorModalFileMsg}
        />
      )}
      {ideaParamsShare.isOpen && (
        <ShareIdeaParameterModal
          isOpen={ideaParamsShare.isOpen}
          onCloseModal={ideaParamsShare.close}
        />
      )}

      <Box sx={{ margin: "0 0.5rem" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
            paddingRight: "1rem",
          }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "1rem",
              justifyContent: "space-between",
              width: "100%",
            }}>
            <Box
              sx={{ display: "flex", alignItems: "center", columnGap: "1rem" }}>
              <IconButton
                sx={{ cursor: "pointer" }}
                title="Go Back"
                onClick={handleClick}>
                <ArrowBackIcon color="primary" titleAccess="Go Back" />
              </IconButton>
              <Typography
                sx={{
                  fontSize: "1rem",
                  whiteSpace: "nowrap",
                }}>
                {projectname}-Idea Configuration
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: "1rem",
                marginBottom: "0.2rem",
              }}>
              <FormControl variant="standard" sx={{ minWidth: "50vw" }}>
                <InputLabel
                  id="demo-simple-select-label"
                  sx={{ color: "#007fff" }}>
                  Select Base Vehicle
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={products}
                  multiple
                  disabled={Premisssionaction && Premisssionaction?.includes("U") ? false : true}
                  multiline
                  label="Select Base Vehicles"
                  placeholder="Select Base Vehicles"
                  onChange={handleChange}
                  size="small"
                  sx={{
                    "&:before": {
                      borderBottomColor: "primary.main",
                    },
                    ".MuiSvgIcon-root.MuiSelect-icon": {
                      color: "primary.main",
                    },
                  }}
                  renderValue={(selected) => (
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        gap: 0.5,
                      }}>
                      {productList
                        .filter((item: any) => selected?.includes(item?.id))
                        ?.map((value: any) => (
                          <Chip
                            size="small"
                            key={value.id}
                            label={value.name}
                            sx={{ backgroundColor: "#007fff14" }}
                            onDelete={() => {
                              if (products.length > 1) {
                                setProducts((prev: any) =>
                                  prev?.filter((item: any) => value.id !== item)
                                );
                                let temp = products.filter(
                                  (item: any) => value.id === item
                                );
                                API.put(
                                  `/xcpep/top_vault/${parseInt(temp[0])}/`,
                                  {
                                    idea_base: false,
                                  },
                                  {},
                                  0
                                )
                                  .then((res: any) => { })
                                  .catch((err: any) => { });
                              }
                            }}
                            deleteIcon={
                              <CancelIcon
                                onMouseDown={(event) => event.stopPropagation()}
                              />
                            }
                          />
                        ))}
                    </Box>
                  )}
                  MenuProps={MenuProps}>
                  {/* <Box><InfoIcon/></Box> */}
                  {productList &&
                    productList?.map((item: any, index: number) => {
                      return (
                        !products?.includes(item?.id) && (
                          <MenuItem
                            sx={{ fontSize: "1rem" }}
                            key={index}
                            value={item?.id}>
                            {item?.name}
                          </MenuItem>
                        )
                      );
                    })}
                </Select>
              </FormControl>

              <InfoIcon
                sx={{ cursor: "pointer" }}
                onMouseEnter={(e: any) => handlePopoverOpen(e)}
                onMouseLeave={handlePopoverClose}
              // titleAccess={`${"Base vehicles are those for which cost saving ideas are to be generated while comparing with other vehicles"}`}
              />
              <Popover
                id="mouse-over-popover"
                sx={{
                  pointerEvents: "none",
                }}
                elevation={0}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus>
                <Typography
                  sx={{
                    width: "20vw",
                    fontSize: "1rem",
                    p: 1,
                    border: "1px solid",
                    borderColor: "primay.light",
                    lineHeight: "1.2",
                    borderRadius: "3px",
                  }}>
                  Base vehicles are those for which cost saving ideas are to be
                  generated while comparing with other vehicles
                </Typography>
              </Popover>
            </Box>
            <Box
              sx={{ display: "flex", alignItems: "center", columnGap: "1rem" }}>

              {Premisssionaction && Premisssionaction?.includes("C") ?
                <PlaylistAddIcon sx={{ fontSize: "2.2rem", cursor: "pointer" }} onClick={() => addeditParamModal.open()} /> :
                <PlaylistAddIcon sx={{ fontSize: "2.2rem", opacity: '0.3' }} />}

              <Typography sx={{ width: "2rem", textAlign: 'center' }}>

                <Checkbox
                  style={{ padding: '3px', }}
                  onChange={handleChangeCheckBox}
                  checked={checked}
                  title={checked ? "Validated" : "Not Validate"}
                  sx={{ color: 'primary.main', }}
                  checkedIcon={
                    <Box
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "50%",
                        height: "1rem",
                        width: "1rem",
                      }}>
                      <VerifiedIcon
                        sx={{
                          color: "#39FF14",
                          position: "relative",
                          margin: "-0.36rem -0.25rem 0",
                        }}
                      />
                    </Box>
                  }
                />
              </Typography>
              <Button disabled={Premisssionaction && Premisssionaction?.includes("U") ? false : true} sx={{ cursor: "pointer", padding: '0.2rem 1rem' }} onClick={() => sequenceHandler()}> Sequence</Button>
              <LoadingButton onClick={() => downloadhandler()} title="Download" sx={{
                minWidth: "1rem", cursor: "pointer",
                padding: '0.2rem 0.5rem',
                '.MuiCircularProgress-root': {
                  color: 'primary.main',
                },
              }} loading={DownloadLoader} startIcon={<FileDownloadIcon sx={{ marginRight: '-1rem', fontSize: '1.7rem !important' }} />} />

              <LoadingButton
                size="small"
                sx={{
                  padding: "0.2rem 0.5rem",
                  lineHeight: 1,
                  minWidth: '1rem',
                  "&:hover": {
                    transform: 'Scale(1.05)',
                    transition: 'transform 0.5s ease',
                  },
                  '.MuiCircularProgress-root': {
                    color: 'primary.main',
                  },
                }}
                disabled={Premisssionaction && Premisssionaction?.includes("C") ? false : true} onClick={() => ideaParamsShare.open()}>
                <ShareIcon sx={{ cursor: "pointer", fontSize: '1.5rem !important' }} />
              </LoadingButton>

            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "flex-start" }}>
        <Box className={styles.expandIconBox}>
          <div className={styles.iconSec}>
            {(["left"] as const).map((anchor) => (
              <React.Fragment key={anchor}>
                <IconButton
                  size="medium"
                  sx={{
                    backgroundColor: "primary.light",
                    color: "primary.main",
                  }}
                  onClick={toggleDrawer(anchor, true)}>
                  <AddIcon sx={{ fontSize: "1.7rem" }} />
                </IconButton>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  className={styles.drawerContainer}
                  PaperProps={{
                    sx: {
                      backgroundColor: "aliceblue",
                    },
                  }}>
                  <Box
                    sx={{ padding: "1rem 0rem", paddingBottom: "0" }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3">
                    <Typography
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "1rem",
                      }}>
                      Idea Configuration
                    </Typography>
                    <Divider sx={{ margin: "0.5rem" }} />
                    {list(anchor)}
                  </Box>
                </Drawer>
                <Typography
                  style={{
                    transform: "rotate(270deg)",
                    whiteSpace: "nowrap",
                    marginTop: "40vh",
                    cursor: "pointer",
                    fontSize: "1rem",
                  }}
                  onClick={toggleDrawer(anchor, true)}>
                  Module Selection
                </Typography>
              </React.Fragment>
            ))}
          </div>
        </Box>

        <Box sx={{ width: "100%", display: "flex", gap: '1rem' }}>
          <Box
            sx={{
              height: { lg: "85vh", xl: "89vh" },
              width: { lg: "15vw", xl: "15vw" },
              display: "flex",
              alignItems: "start",
              flexDirection: "column",
              rowGap: "1rem",
            }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3">
            {Loader ? (
              <>
                <Box sx={{ width: '100%' }}>
                  <Skeleton sx={{ marginBottom: '1rem' }} variant="rounded" width='100%' height="6rem"></Skeleton>
                  <Skeleton sx={{ marginBottom: '1rem' }} variant="rounded" width='100%' height="6rem"></Skeleton>
                  <Skeleton sx={{ marginBottom: '1rem' }} variant="rounded" width='100%' height="6rem"></Skeleton>
                  <Skeleton sx={{ marginBottom: '1rem' }} variant="rounded" width='100%' height="6rem"></Skeleton>
                  <Skeleton sx={{ marginBottom: '1rem' }} variant="rounded" width='100%' height="6rem"></Skeleton>
                  <Skeleton sx={{ marginBottom: '1rem' }} variant="rounded" width='100%' height="6rem"></Skeleton>
                  <Skeleton sx={{ marginBottom: '1rem' }} variant="rounded" width='100%' height="6rem"></Skeleton>
                  <Skeleton sx={{ marginBottom: '1rem' }} variant="rounded" width='100%' height="6rem"></Skeleton>
                  <Skeleton sx={{ marginBottom: '1rem' }} variant="rounded" width='100%' height="6rem"></Skeleton>
                  <Skeleton sx={{ marginBottom: '1rem' }} variant="rounded" width='100%' height="6rem"></Skeleton>
                  <Skeleton sx={{ marginBottom: '1rem' }} variant="rounded" width='100%' height="6rem"></Skeleton>
                </Box>
              </>
            ) : (
              <Box sx={{
                borderLeft: '1px solid',
                marginLeft: '0.5rem',
                maxHeight: { lg: "85vh", xl: "89vh" },
                width: '100%',
              }}>
                {DataTypeList &&
                  DataTypeList?.map((item: any, index: any) => {
                    return (
                      <>
                        <Box
                          onClick={() => DataTypeHandler(item)}
                          sx={{
                            // boxShadow:
                            //   "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px",
                            // borderRadius: "10px",
                            cursor: "pointer",
                            width: "100%",
                          }}>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              width: "100%",
                              height: "100%",
                              // borderRadius: "5px",
                              paddingRight: "0.5rem",
                              backgroundColor:
                                datatype == item?.parameter__data_type
                                  ? "primary.main"
                                  : "white",
                              color:
                                datatype == item?.parameter__data_type
                                  ? "white "
                                  : "primary.main",
                            }}>
                            <Box sx={{
                              width: "100%", display: "flex",
                              alignItems: "center",
                              paddingRight: '0.5rem',
                            }}>
                              <Box sx={{
                                width: "100%", display: "flex",
                                alignItems: "center",
                                padding: "0.3rem 0.5rem 0.3rem 0rem",
                              }}>
                                <span>--</span>
                                <Typography sx={{ fontSize: "1rem", textTransform: 'capitalize' }}>
                                  {item?.parameter__data_type?.toLowerCase()}
                                </Typography>
                              </Box>
                              <Typography sx={{ fontSize: "1rem" }}>
                                ({item?.count})
                              </Typography>
                            </Box>
                          </Box>
                        </Box>
                      </>
                    );
                  })}
              </Box>
            )}
          </Box>

          <Box
            adl-scrollbar="true"
            adl-scrollbar-width="0.3"
            sx={{
              width: "83vw",
              margin: "0 auto",
              height: { lg: "85vh", xl: "85vh" },
            }}>
            <UserContextIdea.Provider value={Premisssionaction}>
              <IdeaConfiguration
                setTotalParamsCount={setTotalParamsCount}
                callbackFunc={getValidationData}
                count={count}
                setDataCounter={setDataCounter}
                setCount={setCount}
                updateDatatypeList={updateDatatypeList}
              />
            </UserContextIdea.Provider>
          </Box>
        </Box>
      </Box>
    </div>
  );
}
