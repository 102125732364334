import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";

import { Box, Button, Divider, FormControl, Skeleton, TextField, Typography, Chip } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import Table from "@mui/material/Table";
import { TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { API } from "../../api-services";
import React, { memo, useState } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { LoadingButton } from "@mui/lab";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import Autocomplete from "@mui/material/Autocomplete";
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';

import { set } from "lodash";
import { ADMIN } from "../../Redux/Services/admin.service";
import FolderIcon from '@mui/icons-material/Folder';
import openFolder from '../../Assets/images/svgs/openfolderwhite.svg';


const popstyled = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90%",
  height: { lg: "90vh", xl: "80vh" },
  bgcolor: "background.paper",
  borderRadius: "10px",
  // border: '2px solid #000',
  // boxShadow: 24,
  p: 1,
};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: { lg: "35vw", xl: "30vw" },
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

const styleAddCostModal = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: { lg: "55vw", xl: "50vw" },
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 2,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// @ts-ignore

const useStyles = makeStyles((theme) => ({
  /** Changed modalStyle */
  ModalRoot: {
    backgroundColor: "rgba(111, 126, 140, 0.2) !important",
    backdropFilter: "blur(1px) !important",
  },
}));

// const Accordion = styled((props: AccordionProps) => (
//   <MuiAccordion disableGutters elevation={0} square {...props} />
// ))(({ theme }) => ({
//   border: `1px solid ${theme.palette.divider}`,
//   "&:not(:last-child)": {
//     borderBottom: 0,
//   },
//   "&:before": {
//     display: "none",
//   },
//   transition: "none !important", // Disable the transition animation
// }));

// const AccordionSummary = styled((props: AccordionSummaryProps) => (
//   <MuiAccordionSummary
//     expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem", color: 'primary.main' }} />}
//     {...props}
//   />
// ))(({ theme }) => ({
//   backgroundColor:
//     theme.palette.mode === "dark"
//       ? "rgba(255, 255, 255, .05)"
//       : "rgba(0, 0, 0, .03)",
//   flexDirection: "row-reverse",
//   "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
//     transform: "rotate(90deg)",
//   },
//   "& .MuiAccordionSummary-content": {
//     marginLeft: theme.spacing(1),
//   },
// }));

// const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
//   padding: theme.spacing(2),
//   borderTop: "1px solid rgba(0, 0, 0, .125)",
// }));

export interface AddParameterModalProps {
  isOpen: any;
  onCloseModal: any;
  setCount: any;
  setDataCounter: any;
  datatype: any;
  updateDatatypeList: any;
}

const AddParameterModal = (props: AddParameterModalProps) => {
  const { isOpen, onCloseModal, setCount, setDataCounter, datatype, updateDatatypeList } = props;
  const { projectId, projectname } = useRouteParams<any>();
  const classes = useStyles();
  const [paramsData, setParamsData] = React.useState<any>([]);
  const [searchKeys, setSearchKeys] = useState<any>(null);
  const [searchvalue, setSearchvalue] = useState<any>(null);
  const [expanded, setExpanded] = React.useState<any>(undefined);
  const [dataTypes, setDataTypes] = React.useState<any>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isLoadingSave, setIsLoadingSave] = React.useState<boolean>(false);
  const [searchLoader, setSearchLoader] = useState<any>(false)
  const [loader, setLoader] = useState<any>(false)
  const [tempselectedParameters, setTempSelectedParameters] =
    React.useState<any>([]);
  const [name, setName] = React.useState<any>(null);
  const [editableAt, setEditableAt] = React.useState<any>([]);
  const [editableError, setEditableError] = React.useState<any>(false);
  const [requiredAt, setRequiredAt] = React.useState<any>(null);
  const [requiredError, setRequiredError] = React.useState<any>(false);
  const [disabled, setDisabled] = React.useState<any>(false);
  const [selectedTab, setSelectedTab] = React.useState('');

  // const handleChange =
  //   (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
  //     event.stopPropagation();
  //     setExpanded(newExpanded ? panel : false);
  //   };

  const handleChange = (name: any) => {
    setSelectedTab(name)
  };
  // const getParamsData = () => {
  //   API.get("/api/db/parameter/", {}, 0)
  //     .then((res: any) => {
  //       setParamsData(res.data);
  //     })
  //     .catch((err: any) => {});
  // };
  const getDataTypes = () => {
    API.get(
      "/config/parameter_types/",
      {
        project: projectId,
        module: 14,
      },
      0
    )
      .then((res: any) => {
        setDataTypes(res.data);
      })
      .catch((err: any) => { });
  };
  const getParametersByDataType = (type: any) => {
    setIsLoading(true);
    API.get(
      "/api/db/parameter/",
      {
        project: projectId,
        module: 14,
        data_types: type,
      },
      0
    )
      .then((res: any) => {
        setParamsData(res.data);
        setIsLoading(false);
      })
      .catch((err: any) => {
        setIsLoading(false);
      });
  };
  React.useEffect(() => {
    if (selectedTab) {
      getParametersByDataType(selectedTab);
    }
  }, [selectedTab]);
  React.useEffect(() => {
    getDataTypes();
  }, [projectId]);
  const handleChangeCheck = (e: any, item: any) => {
    if (tempselectedParameters.includes(item.id)) {
      setTempSelectedParameters((prev: any) =>
        prev.filter((itm: any) => itm !== item.id)
      );
    } else {
      setTempSelectedParameters((prev: any) => [...prev, item.id]);
    }
  };
  const handleSearch = (event: any) => {
    setSearchKeys(event?.target.value);
    if (event?.target.value.length == 0) {
      getDataTypes();
      setSearchKeys(null);
      setSearchvalue(null);
    }
  };

  const handleChangeName = (e: any) => {
    setName(e.target.value);
  }
  const searchClick = () => {
    if (searchKeys !== null) {
      setSearchLoader(true)
      API.get(`/config/parameter_mapping_search/`, {
        module: 14,

        project: projectId,
        search_key: searchKeys?.trim(),
      })
        .then((res: any) => {
          setDataTypes(res?.data);
          setSearchLoader(false)
        })
        .catch((err: any) => {
          console.log("Server Error");
          setSearchLoader(false)
        });
      setSearchvalue(searchKeys);
      setSearchKeys(searchKeys);
    }
  };

  const handleSelectChangMaterial = (event: any, newSystemValue: any) => {
    setEditableAt(newSystemValue);
  };

  const getOptionDisabled = (option: any) => {
    return editableAt.some(
      (selectedOption: any) =>
        selectedOption.value === option.value
    );
  };
  // const getOptionDisabledRequired = (option: any) => {
  //   return requiredAt.some(
  //     (selectedOption: any) =>
  //       selectedOption.value === option.value
  //   );
  // }
  const handleSubmit = () => {
    console.log(editableAt)
    setLoader(true)
    API.post(`/config/idea_config_cost_model/`, {
      project: projectId,
      name: name,
      editable: editableAt?.map((item: any) => item.value),
      required_on: requiredAt?.value
    }, {}, 0).then((res: any) => {
      setLoader(false)
      setName(null)
      console.log(res.data, "resres");
      ADMIN.toast.success("Cost Model Added Successfully")
      updateDatatypeList()
    }).catch((err: any) => {
      setLoader(false)
      console.log(err, "errerr")
      ADMIN.toast.error("Something Went Wrong")
    }
    )
    onCloseModal()


  }
  const handlechangeDown = (e: any) => {
    if (e?.key == "Enter") {
      if (searchKeys !== null) {
        API.get(`/config/parameter_mapping_search/`, {
          module: 14,

          project: projectId,
          search_key: searchKeys?.trim(),
        })
          .then((res: any) => {
            setDataTypes(res?.data);
          })
          .catch((err: any) => {
            console.log("Server Error");
          });
        setSearchvalue(searchKeys);
        setSearchKeys(searchKeys);
      }
    }
  };

  const editableAtOptions = [
    { id: 0, text: "Initiated", value: 0 },
    { id: 1, text: "In-Review", value: 1 },
    { id: 2, text: "Submitted", value: 2 },
    { id: 3, text: "Accepted", value: 3 },
  ];

  const requiredAtOptions = [
    { id: 0, text: "Initiated", value: 0 },
    { id: 1, text: "In-Review", value: 1 },
    { id: 2, text: "Submitted", value: 2 },
    { id: 3, text: "Accepted", value: 3 },
  ];

  const handleSelectChangeRequired = (event: any, newSystemValue: any) => {
    if (newSystemValue == null) {
      setRequiredAt(newSystemValue);
      setRequiredError(true);
    }
    else {
      setRequiredAt(newSystemValue);
      setRequiredError(false);
    }
  }
  return (
    <div>
      <Modal
        open={isOpen}
        className={classes.ModalRoot}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        {datatype === "Cost Model" ? <Box sx={styleAddCostModal}><Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
          <Typography
            style={{
              fontSize: "1rem",
              fontWeight: "600",
              width: "100%",
              textAlign: "center",
              marginRight: "-2rem",
            }}
          >
            Add Cost Model
          </Typography>
          <CancelIcon
            titleAccess="Close"
            sx={{ cursor: "pointer", margin: "0.5rem" }}
            onClick={onCloseModal}
          />
        </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box>


            <FormControl fullWidth>
              <TextField
                value={name}
                onChange={(e: any) => handleChangeName(e)}
                variant="standard"
                type="text"
                required
                InputLabelProps={{
                  shrink: true,
                  style: {
                    color: '#007fff'
                  }
                }}
                label="Name"
                placeholder="Name"
                sx={{
                  ".MuiInputBase-root.MuiInput-root": {
                    fontSize: "1rem !important",
                  },
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "primary.main",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "primary.main",
                  },
                }}
              />
            </FormControl>

            <Box sx={{ display: "flex", gap: "0.5rem", width: "100%", marginTop: "1rem" }}>

              <FormControl fullWidth sx={{ flexGrow: "1" }}>

                <Autocomplete
                  multiple
                  id="tags-standard"
                  limitTags={3}
                  // disableClearable
                  disableCloseOnSelect
                  sx={{
                    ".MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator":
                    {
                      color: "primary.main",
                    },
                    ".MuiButtonBase-root.MuiChip-root": {
                      backgroundColor: "primary.light",
                      height: "26px",
                    },
                  }}
                  options={editableAtOptions}
                  getOptionLabel={(option: any) => option?.text}
                  value={editableAt}
                  onChange={(event: any, newSystemValue: any) => {
                    handleSelectChangMaterial(event, newSystemValue);
                  }}
                  getOptionDisabled={getOptionDisabled}
                  renderOption={(props, option) => (
                    <li {...props} style={{ fontSize: "1rem" }}>
                      {option.text}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Select Editable At"
                      placeholder="Select Editable At"
                      InputLabelProps={{
                        sx: { color: "primary.main" }
                      }}
                      sx={{
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "primary.main",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "primary.main",
                        },
                        ".MuiInputBase-root.MuiInput-root": {
                          fontSize: "1rem !important",
                        },
                        ".MuiSvgIcon-root": { color: "primary.main" },
                      }}
                    />
                  )}
                />
                {editableError ? (
                  <span style={{ color: "red" }}> *Please Select Ediatable At</span>
                ) : (
                  ""
                )}
              </FormControl>






              <FormControl fullWidth sx={{ flexGrow: "1" }}>
                <Autocomplete

                  id="tags-standard"

                  sx={{
                    ".MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator":
                    {
                      color: "primary.main",
                    },
                    ".MuiButtonBase-root.MuiChip-root": {
                      backgroundColor: "primary.light",
                      height: "26px",
                    },
                  }}
                  options={requiredAtOptions}
                  getOptionLabel={(option: any) => option?.text}
                  value={requiredAt}
                  onChange={(event: any, newSystemValue: any) => {
                    setRequiredAt(newSystemValue);
                  }}
                  // getOptionDisabled={getOptionDisabledRequired}
                  renderOption={(props, option) => (
                    <li {...props} style={{ fontSize: "1rem" }}>
                      {option.text}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      label="Select Required At"
                      placeholder="Select Required At"
                      InputLabelProps={{
                        sx: { color: "primary.main" }
                      }}
                      sx={{
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "primary.main",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "primary.main",
                        },
                        ".MuiInputBase-root.MuiInput-root": {
                          fontSize: "1rem !important",
                        },
                        ".MuiSvgIcon-root": { color: "primary.main" },
                      }}
                    />
                  )}
                />

                {requiredError ? (
                  <span style={{ color: "red" }}> *Please Select Required At</span>
                ) : (
                  ""
                )}
              </FormControl>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "flex-end", width: "100%", marginTop: "1rem" }}>
              <LoadingButton disabled={name == null || editableAt.length == 0} loading={loader} variant="contained" onClick={() => handleSubmit()}>Add</LoadingButton>
            </Box>
          </Box>
        </Box> : (<Box sx={popstyled}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Add Parameter
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              columnGap: "1rem",
              width: "100%",
              marginBottom: "0.5rem",
              padding: "0 1rem",
            }}
          >
            <Box>
              <Typography sx={{ fontSize: "1rem", }}>
                Data Type
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", columnGap: "1rem" }}>
              <Box
                sx={{
                  marginBottom: "0.5rem",
                  padding: "0 1rem",
                  display: "flex",
                  alignItems: "center",
                }}>
                <TextField
                  type="search"
                  value={searchKeys == null ? "" : searchKeys}
                  onKeyDown={(event: any) => handlechangeDown(event)}
                  onChange={(e: any) => handleSearch(e)}
                  id="standard-basic"
                  placeholder="Search Parameter Name"
                  variant="standard"
                />
                <SearchIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() => searchClick()}
                />
              </Box>

              <Typography sx={{ fontSize: "1rem" }}>
                {tempselectedParameters?.length} Selected
              </Typography>
              <Button
                variant="contained"
                size="small"
                sx={{
                  ":hover": {
                    transform: "Scale(1.05)",
                    transition: "transform 0.5s ease",
                  },
                }}
                onClick={() => setTempSelectedParameters([])}
              >
                Clear Selected
              </Button>

              {tempselectedParameters?.length > 0 && (
                <LoadingButton
                  variant="contained"
                  size="small"
                  loading={isLoadingSave}
                  sx={{
                    ":hover": {
                      transform: "Scale(1.05)",
                      transition: "transform 0.5s ease",
                    },
                    backgroundColor: "#5bad06",
                  }}
                  onClick={() => {
                    setLoader(true)
                    setIsLoadingSave(true);
                    API.post(
                      "/config/parameter_mapping/",
                      {
                        project: projectId,
                        module: 14,
                        idea_config: true,
                        parameters: tempselectedParameters,
                      },
                      {},
                      0
                    )
                      .then((res: any) => {
                        setExpanded(false);
                        setLoader(false)
                        setSearchvalue(null);
                        setSearchKeys(null);
                        setTempSelectedParameters([]);
                        getDataTypes()
                        setIsLoadingSave(false);
                        API.patch(
                          `/xcpep/project/${projectId}/`,
                          {
                            idea_config: false,
                          },
                          {},
                          0
                        )
                          .then((res: any) => {
                            setCount((prev: any) => prev + 1)
                            setDataCounter((prev: any) => prev + 1)
                          })
                          .catch((err: any) => {

                          });
                      })
                      .catch((err: any) => {
                        setLoader(false)
                        setIsLoadingSave(false);
                      });
                  }}
                >
                  Save
                </LoadingButton>
              )}
            </Box>
          </Box>
          <Box
            sx={{ height: { lg: '86%', xl: "90%" }, width: "100%" }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3"
          >

            {searchvalue ? (
              <Table>
                <TableHead
                  sx={{
                    backgroundColor: "primary.light",
                    position: "sticky",
                    top: "0",
                  }}>
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}></TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}>
                      Parameter Name
                    </TableCell>

                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}>
                      Description
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}>
                      Unit
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}>
                      Exceptions
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}>
                      Allowed Values
                    </TableCell>

                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}>
                      Prohibited Values
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}>
                      Default Values
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                      }}>
                      Guideline
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {searchLoader ?
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}>
                        <Skeleton />
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}>
                        <Skeleton />
                      </TableCell>

                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}>
                        <Skeleton />
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}>
                        <Skeleton />
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}>
                        <Skeleton />
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}>
                        <Skeleton />
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}>
                        {" "}
                        <Skeleton />
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}>
                        <Skeleton />
                      </TableCell>
                      <TableCell
                        sx={{
                          cursor: "pointer",
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        <Skeleton />
                      </TableCell>
                    </TableRow>
                    : <>
                      {loader ? <><Skeleton></Skeleton>

                        <Skeleton></Skeleton>
                        <Skeleton></Skeleton>
                        <Skeleton></Skeleton>
                        <Skeleton></Skeleton>
                        <Skeleton></Skeleton>
                      </> : <>
                        {dataTypes &&
                          dataTypes?.map((rowItem: any, index: any) => {
                            return (
                              <>
                                <TableRow>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}>
                                    <input
                                      type="checkbox"
                                      style={{
                                        padding: "10px",
                                        cursor: "pointer",
                                        borderColor: "primary.light",
                                      }}
                                      // size="small"
                                      key={index}
                                      checked={
                                        tempselectedParameters.includes(rowItem.id)
                                          ? true
                                          : false
                                      }
                                      onChange={(e: any) => handleChangeCheck(e, rowItem)}
                                    />
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}>
                                    {rowItem?.name}
                                  </TableCell>

                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}>
                                    {rowItem?.description}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}>
                                    {rowItem.unit}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}>
                                    {rowItem?.exceptions}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}>
                                    {rowItem?.allowed_values?.length == 0
                                      ? "_"
                                      : rowItem?.allowed_values?.toString()}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}>
                                    {" "}
                                    {rowItem?.not_allowed_values?.length == 0
                                      ? "_"
                                      : rowItem?.not_allowed_values?.toString()}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}>
                                    {rowItem?.default_value}
                                    {rowItem?.default_value == ""
                                      ? "_"
                                      : rowItem?.default_value}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      cursor: "pointer",
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                    onClick={() =>
                                      rowItem?.info_doc != null &&
                                      window.open(rowItem?.info_doc, "_blank")
                                    }>
                                    {rowItem?.info_doc == null ? (
                                      "-"
                                    ) : (
                                      <VisibilityOutlinedIcon />
                                    )}
                                  </TableCell>
                                </TableRow>
                              </>
                            );
                          })}</>}
                    </>}
                </TableBody>
              </Table>
            ) : <>
              <Box
                sx={{ display: 'grid', gridTemplateColumns: '17rem 1fr', columnGap: '0.5rem', height: { lg: "95%", xl: "96%" }, width: "100%" }}
                adl-scrollbar="true"
                adl-scrollbar-width="0.3">
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    columnGap: '0.5rem',
                    width: '100%',
                    flexDirection: 'column',
                    rowGap: '2px',
                  }} adl-scrollbar="true"
                  adl-scrollbar-width="0.2">
                  {dataTypes &&
                    dataTypes?.map((item: any, index: any) => {
                      return (<>
                        <Box
                          key={index}
                          sx={{
                            color: 'primary.main',
                            height: '2rem',
                            minHeight: '2.1rem',
                            maxWidth: '100% !important',
                            padding: '0 3px',
                            fontSize: '1rem',
                            width: '100%',
                            backgroundColor:
                              item?.name == selectedTab
                                ? 'primary.main'
                                : item?.parameter_group === 1 ? '#cef0ff'
                                  : item?.parameter_group === 2 ? '#d0f1aa'
                                    : item?.parameter_group === 3 ? '#fbdddd'
                                      : item?.parameter_group === 4 ? '#ffee93'
                                        : '#d1cfe2',
                            cursor: 'pointer',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-start'
                          }}>
                          {item?.name == selectedTab ? (
                            <img
                              src={
                                openFolder
                              }
                              style={{
                                height:
                                  '1.2rem',
                              }}
                            />
                          ) : (
                            <FolderIcon
                              sx={{
                                fontSize:
                                  '1.5rem',
                                color:
                                  'primary.main',
                              }}
                            />
                          )
                          }
                          <Typography
                            sx={{
                              color:
                                item?.name == selectedTab ? 'white' : 'primary.main',
                              height: '2rem',
                              minHeight: '2.1rem',
                              maxWidth: '100% !important',
                              padding: '0 0.5rem',
                              fontSize: '1rem',
                              width: '100%',
                              textAlign: 'center',
                              display: 'flex',
                              whiteSpace: 'nowrap',
                              alignItems: 'center',
                              justifyContent: 'flex-start',
                              textTransform: 'capitalize',
                            }}
                            onClick={() => handleChange(item.name)}>
                            {item.name} ({item?.prameter_count ? item?.prameter_count : "-"})
                          </Typography>
                        </Box>
                        {/* <Accordion
                          expanded={expanded === item.name}
                          onChange={handleChange(item.name)}
                        >
                          <AccordionSummary
                            aria-controls="panel1d-content"
                            id="panel1d-header"
                            sx={{ minHeight: "36px" }}
                          >
                            <Box
                              sx={{
                                padding: "0 1rem",
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                                width: "100%",
                              }}
                            >
                              <Typography sx={{ fontSize: "1rem", textTransform: "capitalize" }}>
                                {item.name}
                              </Typography>
                              <Typography sx={{ fontSize: "1rem" }}>
                                {item?.prameter_count ? item?.prameter_count : "-"}
                              </Typography>
                            </Box>
                          </AccordionSummary>
                          {!isLoading ? (
                            <AccordionDetails
                              sx={{
                                height: { lg: '30rem', xl: "33rem" },
                                width: "100%",
                                paddingRight: "1rem",
                              }}
                            >
                               <Box sx={{ paddingRight: '0.5rem', height: '100%' }}
                                adl-scrollbar="true"
                                adl-scrollbar-width="0.3">
                                <Table>
                                  <TableHead
                                    sx={{
                                      backgroundColor: "primary.light",
                                      position: "sticky",
                                      top: "0",
                                      zIndex: "1",
                                    }}
                                  >
                                    <TableRow>
                                      <TableCell
                                        sx={{
                                          padding: "0.2rem 0.5rem",
                                          borderBottomColor: 'primary.light',
                                          width: "3vw",
                                        }}
                                      >
                                       
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          padding: "0.2rem 0.5rem",
                                          borderBottomColor: 'primary.light',
                                          width: "7vw",
                                        }}
                                      >
                                        Name
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          padding: "0.2rem 0.5rem",
                                          borderBottomColor: 'primary.light',
                                          width: "5vw",
                                        }}
                                      >
                                        Unit
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          padding: "0.2rem 0.5rem",
                                          borderBottomColor: 'primary.light',
                                          width: "7vw",
                                        }}
                                      >
                                        Data Type
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          padding: "0.2rem 0.5rem",
                                          borderBottomColor: 'primary.light',
                                          width: "7vw",
                                        }}
                                      >
                                        Exceptions
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          padding: "0.2rem 0.5rem",
                                          borderBottomColor: 'primary.light',
                                          maxWidth: "10vw",
                                        }}
                                      >
                                        Allowed Values
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          padding: "0.2rem 0.5rem",
                                          borderBottomColor: 'primary.light',
                                          width: "7vw",
                                        }}
                                      >
                                        Not Allowed Values
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          padding: "0.2rem 0.5rem",
                                          borderBottomColor: 'primary.light',
                                          width: "7vw",
                                        }}
                                      >
                                        Default Value
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          padding: "0.2rem 0.5rem",
                                          borderBottomColor: 'primary.light',
                                          width: "7vw",
                                        }}
                                      >
                                        Guideline Doc
                                      </TableCell>
                                    </TableRow>
                                  </TableHead>
                                  <TableBody>
                                    {paramsData &&
                                      paramsData?.map((item: any, index: any) => {
                                        return (
                                          <TableRow key={index + 1}>
                                            <TableCell
                                              sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}
                                            >
                                              <input
                                                type="checkbox"
                                                style={{
                                                  padding: "10px",
                                                  cursor: "pointer",
                                                  borderColor: "primary.light",
                                                }}
                                                // size="small"
                                                key={index}
                                                checked={
                                                  tempselectedParameters.includes(
                                                    item.id
                                                  )
                                                    ? true
                                                    : false
                                                }
                                                onChange={(e: any) =>
                                                  handleChangeCheck(e, item)
                                                }
                                              />
                                            </TableCell>
                                            <TableCell
                                              sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}
                                            >
                                              {item?.name}
                                            </TableCell>
                                            <TableCell
                                              sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}
                                            >
                                              {item?.unit && item?.unit?.length > 0
                                                ? item?.unit
                                                : "-"}
                                            </TableCell>
                                            <TableCell
                                              sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}
                                            >
                                              {item?.data_type}
                                            </TableCell>
                                            <TableCell
                                              sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}
                                            >
                                              {item?.exceptions?.length > 0
                                                ? item?.exceptions
                                                : "-"}
                                            </TableCell>
                                            <TableCell
                                              sx={{
                                                padding: "0.2rem 0.5rem",
                                                borderBottomColor: 'primary.light',
                                                width: "10vw",
                                              }}
                                            >
                                              <Typography
                                                sx={{
                                                  width: "10vw",
                                                  fontSize: "1rem",
                                                  display: "flex",
                                                  flexWrap: "wrap",
                                                }}
                                              >
                                                {" "}
                                                {item?.allowed_values?.length > 0
                                                  ? item?.allowed_values.join(", ")
                                                  : "-"}
                                              </Typography>
                                            </TableCell>
                                            <TableCell
                                              sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}
                                            >
                                              {item?.not_allowed_values?.length > 0
                                                ? item?.not_allowed_values
                                                : "-"}
                                            </TableCell>
                                            <TableCell
                                              sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}
                                            >
                                              {item?.default_value?.length > 0
                                                ? item?.default_value
                                                : "-"}
                                            </TableCell>
                                            <TableCell
                                              sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}
                                            >
                                              {item?.parameter_info
                                                ? item?.parameter_info
                                                : "-"}
                                            </TableCell>
                                          </TableRow>
                                        );
                                      })}
                                  </TableBody>
                                </Table>
                              </Box>
                            </AccordionDetails>
                          ) : (
                            <Box>
                              <Skeleton />
                              <Skeleton animation="wave" />
                              <Skeleton animation="pulse" />
                            </Box>
                          )}
                        </Accordion> */}
                      </>);
                    })}
                </Box>
                {!isLoading ? (
                  <Box sx={{ paddingRight: '0.5rem', height: '100%' }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3">
                    <Table>
                      <TableHead
                        sx={{
                          backgroundColor: "primary.light",
                          position: "sticky",
                          top: "0",
                          zIndex: "1",
                        }}
                      >
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: 'primary.light',
                              width: "3vw",
                            }}
                          >
                            {/* <Checkbox style={{ padding: "3px" }} size="small" /> */}
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: 'primary.light',
                              width: "7vw",
                            }}
                          >
                            Name
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: 'primary.light',
                              width: "5vw",
                            }}
                          >
                            Unit
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: 'primary.light',
                              width: "7vw",
                            }}
                          >
                            Data Type
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: 'primary.light',
                              width: "7vw",
                            }}
                          >
                            Exceptions
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: 'primary.light',
                              maxWidth: "10vw",
                            }}
                          >
                            Allowed Values
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: 'primary.light',
                              width: "7vw",
                            }}
                          >
                            Not Allowed Values
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: 'primary.light',
                              width: "7vw",
                            }}
                          >
                            Default Value
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: 'primary.light',
                              width: "7vw",
                            }}
                          >
                            Guideline Doc
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {paramsData &&
                          paramsData?.map((item: any, index: any) => {
                            return (
                              <TableRow key={index + 1}>
                                <TableCell
                                  sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}
                                >
                                  <input
                                    type="checkbox"
                                    style={{
                                      padding: "10px",
                                      cursor: "pointer",
                                      borderColor: "primary.light",
                                    }}
                                    // size="small"
                                    key={index}
                                    checked={
                                      tempselectedParameters.includes(
                                        item.id
                                      )
                                        ? true
                                        : false
                                    }
                                    onChange={(e: any) =>
                                      handleChangeCheck(e, item)
                                    }
                                  />
                                </TableCell>
                                <TableCell
                                  sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}
                                >
                                  {item?.name}
                                </TableCell>
                                <TableCell
                                  sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}
                                >
                                  {item?.unit && item?.unit?.length > 0
                                    ? item?.unit
                                    : "-"}
                                </TableCell>
                                <TableCell
                                  sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}
                                >
                                  {item?.data_type}
                                </TableCell>
                                <TableCell
                                  sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}
                                >
                                  {item?.exceptions?.length > 0
                                    ? item?.exceptions
                                    : "-"}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "0.2rem 0.5rem",
                                    borderBottomColor: 'primary.light',
                                    width: "10vw",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      width: "10vw",
                                      fontSize: "1rem",
                                      display: "flex",
                                      flexWrap: "wrap",
                                    }}
                                  >
                                    {" "}
                                    {item?.allowed_values?.length > 0
                                      ? item?.allowed_values.join(", ")
                                      : "-"}
                                  </Typography>
                                </TableCell>
                                <TableCell
                                  sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}
                                >
                                  {item?.not_allowed_values?.length > 0
                                    ? item?.not_allowed_values
                                    : "-"}
                                </TableCell>
                                <TableCell
                                  sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}
                                >
                                  {item?.default_value?.length > 0
                                    ? item?.default_value
                                    : "-"}
                                </TableCell>
                                <TableCell
                                  sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}
                                >
                                  {item?.parameter_info
                                    ? item?.parameter_info
                                    : "-"}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </Box>
                ) : (
                  <Box>
                    <Skeleton />
                    <Skeleton animation="wave" />
                    <Skeleton animation="pulse" />
                  </Box>
                )}
              </Box>
            </>}
          </Box>
        </Box>)}
      </Modal>
    </div>
  );
};
const AddParameterModals = memo(AddParameterModal);
export default AddParameterModals;
