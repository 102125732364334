
export const OPEN_COMPARE = 'OPEN_COMPARE';
export const ADD_COMPARE_COUNT = 'ADD_COMPARE_COUNT';
export const SET_COMPARE_OPTIONS = 'SET_COMPARE_OPTIONS';
export const SET_PERMISSION_FILTER = 'SET_PERMISSION_FILTER';
export const SET_PERMISSION_FILTER_ACTION = 'SET_PERMISSION_FILTER';
export const SET_USER_SCEARCH = 'SET_USER_SCEARCH';
export const INCREASE_COUNTER = 'INCREASE_COUNTER';
export const VALIDATION_COUNTER = 'VALIDATION_COUNTER';
export const RECYCLEBIN_COUNTER = 'RECYCLEBIN_COUNTER';
export const SELECT_CALCULATORS = 'SELECT_CALCULATORS';
export const BOM_ALL_TABLE_DATA = 'BOM_ALL_TABLE_DATA';


export const setIsCompare =(data:any)=>async (dispatch:any)=>{
    dispatch({type:"OPEN_COMPARE",payload:data});
}
export const setAddCompareCount =(data:any)=>async (dispatch:any)=>{
    dispatch({type:"ADD_COMPARE_COUNT",payload:data});
}

export const setCompareOPtions =(data:any)=>async (dispatch:any)=>{
    dispatch({type:"SET_COMPARE_OPTIONS",payload:data});
}

export const setPermissionFilter =(data:any)=>async (dispatch:any)=>{
    dispatch({type:"SET_PERMISSION_FILTER",payload:data});
}
export const setUserSerach =(data:any)=>async (dispatch:any)=>{
    dispatch({type:"SET_USER_SCEARCH",payload:data});
}
export const increaseCounter =(data:any)=>async (dispatch:any)=>{
    dispatch({type:"INCREASE_COUNTER",payload:data});
}

export const validationCheckCounter =(data:any)=>async (dispatch:any)=>{
    dispatch({type:"VALIDATION_COUNTER",payload:data});
}

export const recyclebinCounter =(data:any)=>async (dispatch:any)=>{
    dispatch({type:"RECYCLEBIN_COUNTER",payload:data});
}

export const selectedCalculators =(data:any)=>async (dispatch:any)=>{
    console.log("selectedCalculators",data)
    dispatch({type:"SELECT_CALCULATORS",payload:data});
}

export const setBomAllTableData =(data:any)=>async (dispatch:any)=>{
    dispatch({type:"BOM_ALL_TABLE_DATA",payload:data});
}




