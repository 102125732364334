import * as React from "react";
import {
  Modal,
  Divider,
  Box,
  TableCell,
  Table,
  TableBody,
  TableRow,
  TableHead,
  Button,
  FormLabel,
  Skeleton,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import CancelIcon from "@mui/icons-material/Cancel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import styles from "./AddMaterialRevisionModal.module.scss";
import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { CostingTreeComponent } from "./CostingTreeComponent";
import { API } from "../../../api-services";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import CalculatorListTree from "./CalculatorListTree";
import { useDispatch, useSelector } from "react-redux";
import { selectedCalculators } from "../../../Redux/Actions/compareopen.action";
import { BorderRight, Height } from "@mui/icons-material";
import CostingCostCalculator from "../../../Configuration/Costing/CostingCreateCalculator";
import { LoadingButton } from "@mui/lab";
import { EditSequenceMaterial } from "./EditSequenceMaterial";
import { EditNameMaterial } from "./EditNameMaterial";
import { CostModelCancelAlertModal } from "./CostModelCancelAlertModal";
import { useBaseParamsModal } from "../../../CustomHook/useBaseParamsModal";
import { useHistory } from "react-router-dom";
import FileDownloadDoneIcon from "@mui/icons-material/FileDownloadDone";
import { useBaseModal } from "../../SearchPage/useBaseModal";
import CombinedAssumtionModal from "./CombinedAssumtionModal";

export interface IAssignCostModeltoAddedMaterialsModalProps {
  onCloseModal: any;
  isOpen: any;
  costDetailsData: any;
  parentClose: any;
  getRMData: any;
  selectedCategory?: any;
  revisionsName?: any;
  SelectedmulIds?: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "96vw",
  heigth: "80vh",
  bgcolor: "background.paper",
  // backgroundColor: "primary.light",
  borderRadius: "10px",
  p: 1,
};

export function AssignCostModeltoAddedMaterialsModal(
  props: IAssignCostModeltoAddedMaterialsModalProps
) {
  const { onCloseModal, isOpen, costDetailsData, parentClose, getRMData, SelectedmulIds, selectedCategory, revisionsName } =
    props;
  const history = useHistory();
  const {
    projectId,
    projectname,
    productId,
    topVault,
    vault,
    abbrebation,
    cardtype,
    tableId,
    costEstimationId,
    tableCounter,
    condition,
    calctype,
    calcultorId,
    scenarioId,
    newUI
  } = useRouteParams<any>();
  // :projectname/:productId/:topVault/:vault/:abbrebation/:cardtype/:tableId/:costEstimationId/:tableCounter/:condition/:calctype/:calcultorId
  const dispatch = useDispatch();
  const [proceedButtonStatus, setProccedButtonStatus] =
    React.useState<any>(false);
  const [age, setAge] = React.useState("");
  const [selectedMateialItem, setSelectedMaterialItem] = React.useState<any>(
    []
  );
  const [commonCalc, setCommonCalc] = React.useState<any>([]);
  const [materialItemsData, setMaterialItemsData] = React.useState<any>([]);
  const [allHierarchy, setAllHierarchy] = React.useState<any>([]);
  const [isTreeLoading, setIsTreeLoading] = React.useState<any>(false);
  const store: any = useSelector((state) => state);
  // store?.is_compare?.selcetedCalculators
  const [selectedTreeData, setSelectedTreeData] = React.useState<any>([]);
  const [singleSelectedTreeData, setSingleSelectedTreeData] =
    React.useState<any>([]);
  const [counterApiCall, setCounterApiCall] = React.useState<any>(0);
  const [quantity, setQuantity] = React.useState<any>(1);
  const [loading, setLoading] = React.useState<any>(false);
  const [AddCMClick, setAddCMClick] = React.useState<any>(false);
  const [apiCallCount, setApiCallCount] = React.useState<any>(0);
  const [calculatorsData, setCalculatorsData] = React.useState<any>([]);
  
  const combinedassumption = useBaseModal()
  const handleChangeQuantity = (event: any) => {
    const { value } = event.target;
    setQuantity(value);
  };

  const getMaterialItemData = () => {
    API.get(
      "/cost/material/",
      {
        cost_estimation: costDetailsData.cost_estimation,
        calculator: false,
      },
      0
    )
      .then((res: any) => {
        setMaterialItemsData(res.data);
        // setSelectedMaterialItem(res.data[0].id);
      })
      .catch((err: any) => { });
  };

  React.useEffect(() => {
    getMaterialItemData();
  }, [apiCallCount]);
  React.useEffect(() => {
    if (selectedMateialItem !== "") {
      dispatch(selectedCalculators([]));
    }
  }, [selectedMateialItem]);
  // const getCalcsDetailsData = () => {
  //   API.get(
  //     "/cost/calculator_input/",
  //     { calculator: singleSelectedTreeData[0]?.id },
  //     0
  //   )
  //     .then((res: any) => {
  //       console.log("kjsfukgsdkjsds", res.data);
  //       setCalculatorsData(res.data);
  //     })
  //     .catch((err: any) => {});
  // };
  // React.useEffect(() => {
  //   if (singleSelectedTreeData?.length > 0) {
  //     getCalcsDetailsData();
  //   }
  // }, [singleSelectedTreeData]);

  // React.useEffect(() => {
  //   if (store?.is_compare?.selcetedCalculators) {
  //     setSelectedTreeData(store?.is_compare?.selcetedCalculators);
  //   }
  // }, [store?.is_compare?.selcetedCalculators]);
console.log(store?.is_compare?.selcetedCalculators, "store?.is_compare?.selcetedCalculators")
  const getTreeData = () => {
    setIsTreeLoading(true);
    API.get(
      "/config/costing_configuration/",
      {
        project: projectId,
        costing_config_hier: true,
        commodity: costDetailsData?.commodity?.toString(),
        cal_type: 1,
      },
      0
    )
      .then((res: any) => {
        setAllHierarchy(res.data[0]?.data);
        setIsTreeLoading(false);
      })
      .catch((err: any) => {
        setIsTreeLoading(false);
      });
  };
  React.useEffect(() => {
    getTreeData();
  }, []);

  const onSelect = (
    nodeId: any,
    id: any,
    HierarchyAllData: any,
    itemId: any
  ) => {
    // history.push(`/costingconfiguration/${projectId}/${projectname}/${itemId}/${HierarchyAllData.type}/`);
  };
  const CancelCostModal = useBaseParamsModal();
  const cancelCostModelHndler = () => {
    CancelCostModal.open();
    getRMData();
    // getRMData();
    // parentClose();
    // onCloseModal();
    // / :projectname/:productId/:topVault/:vault/:abbrebation/:cardtype/:tableId/:costEstimationId/:tableCounter/:condition/:calctype/:calcultorId
    history.push(
      `/createcostingpart/${projectId}/${projectname}/${productId}/${topVault}/${vault}/${abbrebation}/${cardtype}/${materialItemsData[0]?.id
      }/${costDetailsData?.cost_estimation}/${0}/${0}/${1}/0/${scenarioId}/0/${newUI}`
    );
  };
  const closeHandlerParent = () => {
    API.post(
      "/cost/cost_calculator/",
      {
        material: true,
        cost_estimation: costDetailsData?.cost_estimation,
        scenario: scenarioId ? scenarioId : undefined,
      },
      {},
      0
    )
      .then((res: any) => {
        onCloseModal();
        parentClose();
      })
      .catch((err: any) => { });
  };

  var materialStatus =
    materialItemsData &&
    materialItemsData
      ?.filter((item: any, index: any) => {
        return item?.calculator_mapped == true;
      })[0]
  const ProceedHandler = () => {



    if (SelectedmulIds && SelectedmulIds?.length > 0) {

      if (materialStatus?.is_material == true || materialStatus?.is_scrap == true) {
        combinedassumption.open()
      } else {
        API.post(
          "/cost/cost_calculator/",
          {
            material: true,
            cost_estimation:
              costDetailsData?.cost_estimation,
            scenario: scenarioId
              ? scenarioId
              : undefined,
          },
          {},
          0
        )
          .then((res: any) => { })
          .catch((err: any) => { });
        getRMData();
        parentClose();
        onCloseModal();

        // / :projectname/:productId/:topVault/:vault/:abbrebation/:cardtype/:tableId/:costEstimationId/:tableCounter/:condition/:calctype/:calcultorId
        history.push(
          `/createcostingpart/${projectId}/${projectname}/${productId}/${topVault}/${vault}/${abbrebation}/${cardtype}/${materialItemsData[0]?.id
          }/${costDetailsData?.cost_estimation
          }/${0}/${0}/${1}/0/${scenarioId}/0/${newUI}`
        );
      }

      // parentClose();
      // onCloseModal();
    }
    else {
      API.post(
        "/cost/cost_calculator/",
        {
          material: true,
          cost_estimation:
            costDetailsData?.cost_estimation,
          scenario: scenarioId
            ? scenarioId
            : undefined,
        },
        {},
        0
      )
        .then((res: any) => { })
        .catch((err: any) => { });
      getRMData();
      parentClose();
      onCloseModal();

      // / :projectname/:productId/:topVault/:vault/:abbrebation/:cardtype/:tableId/:costEstimationId/:tableCounter/:condition/:calctype/:calcultorId
      history.push(
        `/createcostingpart/${projectId}/${projectname}/${productId}/${topVault}/${vault}/${abbrebation}/${cardtype}/${materialItemsData[0]?.id
        }/${costDetailsData?.cost_estimation
        }/${0}/${0}/${1}/0/${scenarioId}/0/${newUI}`
      );
    }


  }

  const ApplyCostType = () => {

    API.post(
      "/cost/cost_calculator/",
      {
        material: true,
        cost_estimation:
          costDetailsData?.cost_estimation,
        scenario: scenarioId
          ? scenarioId
          : undefined,
      },
      {},
      0
    )
      .then((res: any) => { })
      .catch((err: any) => { });
    getRMData();
    parentClose();
    onCloseModal();
    combinedassumption.close()
    // / :projectname/:productId/:topVault/:vault/:abbrebation/:cardtype/:tableId/:costEstimationId/:tableCounter/:condition/:calctype/:calcultorId
    history.push(
      `/createcostingpart/${projectId}/${projectname}/${productId}/${topVault}/${vault}/${abbrebation}/${cardtype}/${materialItemsData[0]?.id
      }/${costDetailsData?.cost_estimation
      }/${0}/${0}/${1}/0/${scenarioId}/0/${newUI}`
    );

  }





  const findCommonCalc = () => {
    let allCalc: any = [];
    for (let i = 0; i < selectedMateialItem.length; i++) {
      let item = materialItemsData.find((item: any) => item.id === selectedMateialItem[i]);
      console.log(item, "item")
      

        allCalc.push(item?.cal_ids)
      

    }
    console.log(allCalc, "allCalc")
    if (allCalc.length > 1) {
      const commonElements = allCalc[0].filter((element: any) =>

        allCalc.every((subArray: any) => subArray.includes(element))
      );
      console.log(commonElements, "commonElements")
      setCommonCalc(commonElements);

    }
    else {
      console.log(allCalc[0], "allCalc")
      setCommonCalc(allCalc[0]);
    }

  }

  React.useEffect(() => {

    findCommonCalc();


  }, [selectedMateialItem, materialItemsData]);

  return (
    <div>
      {CancelCostModal.isOpen && (
        <CostModelCancelAlertModal
          isOpen={CancelCostModal.open}
          onCloseModal={CancelCostModal.close}
          cancelHandler={closeHandlerParent}
          parentClose={parentClose}
        />
      )}
      {combinedassumption.isOpen && <CombinedAssumtionModal
        isOpen={combinedassumption.isOpen}
        onCloseModal={combinedassumption.close}
        materialItemsData={materialItemsData}
        SelectedmulIds={SelectedmulIds}
        ApplyCostType={ApplyCostType}
        selectedCategory={selectedCategory} revisionsName={revisionsName}

      />}

      <Modal
        open={isOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                // textAlign: "center",
                marginLeft: "1rem",
              }}
            >
              Add Cost Model
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={() => {
                cancelCostModelHndler();
              }}
            // onClick={() => {
            //   API.post(
            //     "/cost/cost_calculator/",
            //     {
            //       material: true,
            //       cost_estimation: costDetailsData?.cost_estimation,
            //     },
            //     {},
            //     0
            //   )
            //     .then((res: any) => { })
            //     .catch((err: any) => { });
            //   onCloseModal();
            // }}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "0.5rem",
            }}
          />
          <Box sx={{ padding: "0 1rem" }}>
            <Box sx={{ height: "25vh" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  columnGap: "0.5rem",
                  width: "100%",
                  marginBottom: "0.5rem",
                  height: "24vh",
                  // boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px',
                  borderRadius: "10px",
                  backgroundColor: "white",
                }}
                adl-scrollbar="true"
                adl-scrollbar-width="0.3"
              >
                {/* <FormControl
                  sx={{ width: { lg: "17vw", xl: "15vw" } }}
                  component="fieldset"
                  variant="standard"
                >
                  <FormLabel
                    sx={{
                      fontSize: "1rem",
                      backgroundColor: "primary.light",
                      color: "primary.main",
                      width: "100%",
                      fontWeight: "500",
                      padding: "0.36rem 0.5rem",
                      position: "sticky",
                      top: 0,
                      zIndex: 1,
                    }}
                  >
                    Name
                  </FormLabel>
                  <FormGroup sx={{ fontSize: "1rem", marginLeft: "1rem" }}>
                    {materialItemsData &&
                      materialItemsData?.map((item: any) => {
                        return (
                          <FormControlLabel
                            title={item?.alias}
                            sx={{
                              height: { lg: "2.4rem", xl: "2.3rem" },
                              "span.MuiTypography-root.MuiTypography-body1.MuiFormControlLabel-label.MuiTypography-root":
                                {
                                  width: {
                                    lg: "16rem !important",
                                    xl: "18rem !important",
                                  },
                                  fontSize:'1rem',
                                  overflow: "hidden",
                                  textOverflow: "ellipsis",
                                  whiteSpace: "nowrap",
                                },
                            }}
                            control={
                              item?.calculator_mapped ? (
                                <Checkbox
                                  checked={true}
                                  disabled={true}
                                  sx={{
                                    padding: "3px",
                                    fontSize: "18px",
                                    color: "#007fff",
                                  }}
                                  onClick={() =>
                                    setSelectedMaterialItem(item?.id)
                                  }
                                />
                              ) : (
                                <Checkbox
                                  checked={
                                    selectedMateialItem === item?.id
                                      ? true
                                      : false
                                  }
                                  sx={{
                                    padding: "3px",
                                    fontSize: "18px",
                                    color: "#007fff",
                                  }}
                                  onClick={() =>
                                    setSelectedMaterialItem(item?.id)
                                  }
                                />
                              )
                            }
                            label={item?.alias}
                          />
                        );
                      })}
                  </FormGroup>
                </FormControl> */}
                <Box sx={{ width: { lg: "45vw", xl: "40vw" } }}>
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    sx={{ width: "100%" }}
                  >
                    <TableHead
                      sx={{
                        backgroundColor: "primary.light",
                        position: "sticky",
                        top: "0",
                        zIndex: 1,
                      }}
                    >
                      <TableRow>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                            backgroundColor: "primary.light",
                          }}
                        >
                          Select
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                            backgroundColor: "primary.light",
                          }}
                        >
                          Seq
                        </TableCell>
                        <TableCell
                          sx={{
                            width: "100%",
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                            backgroundColor: "primary.light",
                          }}
                        >
                          Material Name
                        </TableCell>
                        <TableCell
                          className={styles.tableCell}
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                            backgroundColor: "primary.light",
                          }}
                        >
                          Material Code
                        </TableCell>
                        <TableCell
                          className={styles.tableCell}
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                            backgroundColor: "primary.light",
                          }}
                        >
                          Grade
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {materialItemsData &&
                        materialItemsData?.map((item: any) => {
                          return (
                            <TableRow
                              key={item?.id}
                              sx={{
                                backgroundColor:
                                  item?.id === selectedMateialItem
                                    ? "#c4efb3"
                                    : "",
                              }}
                            >
                              <TableCell
                                sx={{
                                  maxWidth: { lg: "10rem", xl: "15rem" },
                                  padding: "0rem 0.5rem",
                                  borderBottomColor: "primary.light",
                                }}
                              >
                                {item?.calculator_mapped ? (
                                  <Checkbox
                                    checked={
                                      selectedMateialItem?.includes(item?.id)
                                        ? true
                                        : false
                                    }
                                    style={{
                                      padding: "3px",
                                      fontSize: "18px",
                                      color: "#007fff",
                                    }}
                                    onClick={() => {
                                      // setSelectedMaterialItem(()item?.id)
                                      if (
                                        selectedMateialItem?.includes(item?.id)
                                      ) {
                                        setSelectedMaterialItem((prev: any) =>
                                          prev?.filter(
                                            (item1: any) => item1 !== item?.id
                                          )
                                        );


                                      } else {
                                        setSelectedMaterialItem((prev: any) => [
                                          ...prev,
                                          item?.id,
                                        ]);

                                      }
                                    }}
                                  />
                                ) : (
                                  <Checkbox
                                    checked={
                                      selectedMateialItem?.includes(item?.id)
                                        ? true
                                        : false
                                    }
                                    style={{
                                      padding: "3px",
                                      fontSize: "18px",
                                      color: "#007fff",
                                    }}
                                    onClick={() => {
                                      // setSelectedMaterialItem(()item?.id)
                                      if (
                                        selectedMateialItem?.includes(item?.id)
                                      ) {
                                        setSelectedMaterialItem((prev: any) =>
                                          prev?.filter(
                                            (item1: any) => item1 !== item?.id
                                          )
                                        );
                                      } else {
                                        setSelectedMaterialItem((prev: any) => [
                                          ...prev,
                                          item?.id,
                                        ]);
                                      }
                                    }}
                                  />
                                )}
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: "0rem 0.5rem",
                                  borderBottomColor: "primary.light",
                                }}
                              >
                                <EditSequenceMaterial
                                  value={item?.sequence}
                                  costEstimationId={item?.id}
                                  getMaterialItemData={setApiCallCount}
                                  key={item?.id}
                                  item={item}
                                />
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: "0rem 0.5rem",
                                  borderBottomColor: "primary.light",
                                }}
                              >
                                <EditNameMaterial
                                  value={item?.alias}
                                  idd={item?.id}
                                  getRMData={getMaterialItemData}
                                />
                              </TableCell>
                              <TableCell className={styles.tableCell}>
                                <Box
                                  className={styles.tablewowrap}
                                  sx={{ maxWidth: "12rem" }}
                                >
                                  {item?.material?.material_code}
                                </Box>
                              </TableCell>
                              <TableCell className={styles.tableCell}>
                                <Box
                                  className={styles.tablewowrap}
                                  sx={{ maxWidth: "12rem" }}
                                >
                                  {item?.material?.material_grade}
                                </Box>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </Box>
                <Box sx={{ width: { lg: "47vw", xl: "53vw" } }}>
                  <Table
                    stickyHeader
                    aria-label="sticky table"
                    sx={{ width: "100%" }}
                  >
                    <TableHead
                      sx={{
                        backgroundColor: "primary.light",
                        position: "sricky",
                        top: "0",
                        zIndex: 1,
                      }}
                    >
                      <TableRow>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                            backgroundColor: "primary.light",
                            width: "4rem",
                          }}
                        >
                          Qty
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                            backgroundColor: "primary.light",
                          }}
                        >
                          Selected Cost Models
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {materialItemsData &&
                        materialItemsData?.map((item: any) => {
                          return (
                            <TableRow>
                              <TableCell className={styles.tableCell}>
                                {item?.qty}
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: "0.2rem 0.5rem",
                                  borderBottomColor: "primary.light",
                                  height: { lg: "2.4rem", xl: "2.3rem" },
                                }}
                                title={
                                  item?.cal_name?.length > 0
                                    ? item?.cal_name?.join(", ")
                                    : "---"
                                }
                              >
                                <Box
                                  className={styles.tableCell}
                                  sx={{ width: { lg: "38vw", xl: "50vw" } }}
                                >
                                  {item?.cal_name?.length > 0
                                    ? item?.cal_name?.join(", ")
                                    : "---"}
                                </Box>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </Box>
              </Box>
            </Box>
            <Box sx={{ height: "62vh", marginTop: "0.5rem" }}>
              {selectedMateialItem != "" && (
                <>
                  <Box className={styles.selectMaterialCost}>
                    <Box className={styles.selectedMaterialCalc}>
                      <Box
                        sx={{
                          width: "25vw",
                          height: { lg: "60vh", xl: "60vh" },
                          borderRight: "1px solid",
                          borderColor: "primary.light",
                        }}
                        adl-scrollbar="true"
                        adl-scrollbar-width="0.3"
                      >
                        <Typography
                          sx={{
                            fontSize: "1rem",
                            fontWeight: "500",
                            padding: "0.2rem 0.5rem",
                            backgroundColor: "primary.light",
                          }}
                        >
                          Select Cost Model
                          {/* <Button
                            style={{
                              marginLeft: "1rem",
                              // border: "1px solid #ccc",
                              borderRadius: 5,
                              padding: "0.2rem 0.5rem",
                              boxShadow: "0 3px 5px rgba(0, 0, 0, 0.2)",
                            }}
                            onClick={() => setAddCMClick(true)}
                            endIcon={
                              <FileDownloadDoneIcon
                                sx={{ color: AddCMClick ? "green" : "" }}
                              />
                            }
                          ></Button> */}
                        </Typography>
                        {!isTreeLoading && allHierarchy ? (
                          allHierarchy?.map((item: any) => {

                            return (
                              <CalculatorListTree
                                HierarchyData={item}
                                onSelectCallback={onSelect}
                                setSelectedTreeData={setSelectedTreeData}
                                setCounterApiCall={setCounterApiCall}
                                selectedTreeData={selectedTreeData}
                                setSingleSelectedTreeData={
                                  setSingleSelectedTreeData
                                }
                                commonCalc={commonCalc}
                                singleSelectedTreeData={singleSelectedTreeData}
                              />
                            );
                          })
                        ) : (
                          <Box sx={{ width: "100%", padding: "0 0.5rem" }}>
                            <Skeleton sx={{ height: "2rem", width: "30%" }} />
                            <Skeleton sx={{ height: "2rem", width: "45%" }} />
                            <Skeleton sx={{ height: "2rem", width: "50%" }} />
                            <Skeleton sx={{ height: "2rem", width: "65%" }} />
                            <Skeleton sx={{ height: "2rem", width: "70%" }} />
                            <Skeleton sx={{ height: "2rem", width: "60%" }} />
                            <Skeleton sx={{ height: "2rem", width: "55%" }} />
                          </Box>
                        )}
                      </Box>
                      <Box sx={{ width: "100%" }}>
                        <Box
                          sx={{
                            height: {
                              sm: "60vh",
                              md: "69vh",
                              lg: "55vh",
                              xl: "56vh",
                            },
                            width: "100%",
                          }}
                        >
                          {singleSelectedTreeData?.length > 0 && (
                            <CostingCostCalculator
                              selectedTreeData={singleSelectedTreeData[0]}
                              setCounterApiCall={setCounterApiCall}
                              dataType={singleSelectedTreeData?.type}
                              vault={singleSelectedTreeData?.id}
                            />
                          )}
                        </Box>
                        {selectedTreeData != "" && (
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "flex-end",
                              columnGap: "1rem",
                              marginTop: "0.5rem",
                              paddingRight: "0.5rem",
                            }}
                          >
                            {selectedTreeData != "" && (
                              <FormControl
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  columnGap: "1rem",
                                  flexDirection: "row",
                                }}
                              >
                                <label>
                                  Add Quantity
                                  <sup style={{ color: "red" }}>*</sup> :
                                </label>
                                <TextField
                                  type="number"
                                  variant="standard"
                                  // placeholder="Quantity*"
                                  value={quantity}
                                  onChange={handleChangeQuantity}
                                  inputProps={{
                                    step: "any",
                                    min: 0,
                                    style: { fontSize: "1rem" },
                                  }}
                                  sx={{
                                    width: "5rem",
                                    borderBottomColor: "primary.light",
                                    "& .MuiInput-underline:before": {
                                      borderBottomColor: "primary.light",
                                    },
                                    "& .MuiInput-underline:after": {
                                      borderBottomColor: "primary.main",
                                    },
                                  }}
                                />
                              </FormControl>
                            )}
                            <LoadingButton
                              size="small"
                              variant="contained"
                              sx={{
                                ".MuiCircularProgress-root": {
                                  color: "primary.main",
                                },
                                "&:hover": {
                                  transform: "Scale(1.05)",
                                  transition: "transform 0.5s ease",
                                },
                              }}
                              loading={loading}
                              disabled={
                                quantity !== 0 &&
                                  store?.is_compare?.selcetedCalculators?.length >
                                  0
                                  ? false
                                  : true
                              }
                              onClick={() => {
                                setLoading(true);

                                API.post(
                                  "/cost/calculation/",
                                  {
                                    object_id: selectedMateialItem,
                                    calculators:
                                      store?.is_compare?.selcetedCalculators?.map(
                                        (item: any) => item.id
                                      ),
                                    qty: parseInt(quantity),
                                    category: 1,
                                    multiple: true,
                                  },
                                  {},
                                  0
                                )
                                  .then((res: any) => {
                                    getMaterialItemData();
                                    setLoading(false);
                                    setProccedButtonStatus(true);
                                    console.log(materialItemsData, "materialItemsData")
                                    dispatch(selectedCalculators([]));
                                    

                                  })
                                  .catch((err: any) => {
                                    setLoading(false);
                                  });
                              }}
                            >
                              Add Cost Model
                            </LoadingButton>

                            <Button
                              size="small"
                              variant="contained"
                              sx={{
                                "&:hover": {
                                  transform: "Scale(1.05)",
                                  transition: "transform 0.5s ease",
                                },
                              }}
                              disabled={proceedButtonStatus ? false : true}
                              // onClick={() => {
                              //   API.post(
                              //     "cost/cost_calculator/",
                              //     {
                              //       material: materialItemsData
                              //         ?.filter(
                              //           (item: any) => item?.calculator_mapped === true
                              //         )
                              //         .map((item: any) => item?.id),
                              //     },
                              //     {},
                              //     0
                              //   )
                              //     .then((res: any) => {
                              //       getRMData();
                              //       parentClose();
                              //       onCloseModal();
                              //     })
                              //     .catch((err: any) => {});
                              // }}
                              onClick={() => {
                                ProceedHandler()


                              }
                              }
                            >
                              Proceed
                            </Button>
                          </Box>
                        )}
                      </Box>
                    </Box>
                  </Box>
                </>
              )}
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
