import * as React from 'react';
import { Box, MenuItem, Select } from "@mui/material";
import UploadIcon from "@mui/icons-material/Upload";
import PreviewIcon from "@mui/icons-material/Preview";
import { LoadingButton } from "@mui/lab";
import { ChangeEvent, useRef, useState } from "react";
import RViewerJS from "viewerjs-react";
import { useParams } from "react-router-dom";
import { API } from '../../../../../api-services';
import { useRouteParams } from '../../../../../ui-reusable-component/useRouteParams';
import { userPermissionAction } from '../../../../BOMCreate/BOMEntry/BOMEntryLandingPage';
// import { userPermissionAction } from '../../../BOMCreate/BOMEntry/BOMEntryLandingPage';
// import { API } from '../../../../api-services';
// import { useRouteParams } from '../../../../ui-reusable-component/useRouteParams';

export interface IIdeaTabImageInputViewBoxProps {
    itmm: any;
    key1: any;
    dataBody: any;
    data: any;
    val: any;
    count: any;
    setDataForUpdate: any;
    target: any;
    AllData: any;
    selectedOptions: any;
    allOptions: any;
    option: any;
    getTableDataWOLoader: any;
    itmKey: any;
    setColorButton: any;
    setShowButton: any;
}

export default function IdeaTabImageInputViewBox (props: IIdeaTabImageInputViewBoxProps) {
    const userAction = React.useContext(userPermissionAction);
  let UserAction = userAction && userAction?.action;

  const {
    itmm,
    key1,
    dataBody,
    data,
    val,
    count,
    setDataForUpdate,
    target,
    AllData,
    selectedOptions,
    allOptions,
    option,
    getTableDataWOLoader,
    itmKey,
    setColorButton,
    setShowButton
  } = props;
  const [value, setValue] = React.useState<any>("");
  const { copyStatus } = useParams<any>();
  const [loading, setLoading] = React.useState<any>(false);
  const { vault, topVault } = useRouteParams<any>();
  const getObj = () => {
    if (vault == 0) {
      return { top_vault: topVault };
    } else {
      return { vault: vault };
    }
  };
  const ImageViewer = useRef<any>();
  const [srcImages, setSrcImages] = useState<any>();
  const [options, setOptions] = React.useState<any>([]);
  const inputRef = useRef<HTMLInputElement>(null);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);

  const handleImageSelect = () => {
    if (inputRef.current) {
      inputRef.current.click();
    }
  };
  const handleClick = (srcImg: any) => {
    if (srcImg?.length > 0) {
      ImageViewer.current.src = srcImg;
      ImageViewer.current?.click?.();
      setSrcImages(srcImg);
    }
  };

  React.useEffect(() => {
    if (allOptions?.length > 0) {
      setOptions(allOptions);
    }
  }, [allOptions]);

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      setSelectedFile(file);
      const formData = new FormData();
      formData.append("image", file);
      setLoading(true);
      API.post(
        `/xcpep/table_parameter_image/`,
        formData,
        {
          ...getObj(),
          parameter: itmKey,
          image_param_id: option[0]?.key,
          index: target,
          new_ui: true,
        },
        0
      )
        .then((res: any) => {
            setColorButton("red")
            setShowButton(true)
          setValue(res.data[key1]);
          AllData.filter((item: any, index: any) => index === target)[0][key1] =
            res.data[key1] ? res.data[key1] : "";
          setDataForUpdate(AllData);
          // getTableDataWOLoader();
          setLoading(false);
        })
        .catch((err: any) => {
          setLoading(false);
        });
      // onImageSelect(file);
    }
  };

  React.useEffect(() => {
    if (val) {
      setValue(val);
    } else {
      setValue("");
    }
  }, [val]);


  return (
    <div>
      
    </div>
  );
}
