// react
import React, { useState, useRef, useEffect } from "react";
import {
    Autocomplete,
    Box,
    Button,
    Dialog,
    DialogContent,
    Divider,
    IconButton,
    Slide,
    TextField,
    Typography,
    debounce,
  } from "@mui/material";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { QrReader } from "react-qr-reader";
import Modal from "@mui/material/Modal";
import { AxiosError, AxiosResponse } from "axios";
import Swal from "sweetalert2";
// styles
// import styles from "./ScanQRModal.module.scss";
import styles from "./BOMCostScanQRModal.module.scss";
import { TransitionProps } from "@mui/material/transitions";
import { useHistory, useRouteMatch } from "react-router-dom";
import { API } from "../../api-services";
import { Cancel, Countertops } from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import { useScroll } from "@react-spring/web";
import ClearIcon from '@mui/icons-material/Clear';
import useWindowDimensions from "../../ui-reusable-component/useWindowDimensions";
import BOMEntryMobileModal from "../BOMCreate/BOMEntryMobileModal";
import { useBaseModal } from "../SearchPage/useBaseModal";
import BOMImageSingleUpload from ".././BOMCreate/BOMImageSingleUpload";

import { LoadingButton } from "@mui/lab";
import ArrowCircleLeftRoundedIcon from "@mui/icons-material/ArrowCircleLeftRounded";
import ArrowCircleRightRoundedIcon from "@mui/icons-material/ArrowCircleRightRounded";
import EditIcon from "@mui/icons-material/Edit";
import UploadIcon from "@mui/icons-material/Upload";
import DeleteIcon from "@mui/icons-material/Delete";
import CameraAltRoundedIcon from "@mui/icons-material/CameraAltRounded";
import RViewerJS from "viewerjs-react";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import SearchIcon from "@mui/icons-material/Search";
import swal from "sweetalert";
import ErrorModalFilesMail from "../ProjectMailModule/ErrorModalFilesMail";
// interfaces and types

// lazy

// ----------------------------------------------------------------------------------

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

interface BOMDataEntryQRModalProps {
  isOpen: boolean;
  onClosess:any;

  changeRootId?: any;
}

const BOMDataEntryQRModal: React.FC<BOMDataEntryQRModalProps> = (props) => {
  const { isOpen, onClosess, changeRootId } = props;
  const { url } = useRouteMatch<any>();
  const scannerAPIDataRef = useRef<any>([]);
  const partClickEventDataRef = useRef<any>(undefined);
  const { height, width } = useWindowDimensions();
  const [openQRScanner, setOpenQRScanner] = React.useState(false);
  const [CounterData, setCounterData] = useState<any>(0);
  var Module = localStorage.getItem("ModuleName");
  const history = useHistory();
  const [openEntryMobile, setOpenEntryMobile] = React.useState(false);
  const [vaultData, setVaultData] = React.useState<any>();
  const [searchVaultsOptions, setSearchVaultsOptions] = useState<any>([]);
  const timeout = useRef<any>(null);

  
  const [weight, setWeight] = React.useState<any>();
  const [weightDetails, setWeightDetails] = React.useState();
  
  const [touchStart, setTouchStart] = React.useState(null);
  const [touchEnd, setTouchEnd] = React.useState(null);
  const [ErrorModalFileMsg, setErrorModalFileMsg] = React.useState<any>();
  const [id, setIsID] = React.useState<number>(0);
  const [isUpdating, setIsUpdating] = React.useState<any>(false);
  const [isUpdatingIconName, setIsUpdatingIconName] = React.useState<any>("");
  const [apiCallVaultData, setApiCallVaultData] = React.useState<any>(false);
  const [deleteLoading, setDeleteLoading] = React.useState<any>(false);
  const [actions, setActions] = React.useState<any>()
  const [projectId, setProjectId] = React.useState<any>();
  const [showQRSCanner,setShowQRSCanner] = React.useState<any>(true);

  const inputElRef = useRef<any>();
  const inputElRefPhone = useRef<any>();

  const ErrorModalFiles = useBaseModal();

  const OuterRef = React.useRef<any>();


  const [selectedValue, setSelectedValue] = React.useState<any>(null)
  const [SearchValue, setSearchValue] = React.useState<any>([])
  const inputRef = React.useRef<any>(null);
  const descRef = React.useRef<any>();

  // console.log(Module, "serachUrl");
  // ----------------------------------------------------------------------------------
  // handlers

  useEffect(() => {
    if (openEntryMobile) {
      setVaultData(undefined);
      setWeight(undefined);
      setWeightDetails(undefined);

      API.get(
        `/xcpep/bom_sequence_image/`,
        {
          vault: (partClickEventDataRef.current == undefined
            ? scannerAPIDataRef.current
            : partClickEventDataRef.current
          )?.id,
        },
        0
      )
        .then((res: AxiosResponse) => {
          setVaultData(res.data);
        })
        .catch((err: AxiosError) => {
          console.log(err, "error");
        });

      API.get(
        `/xcpep/bom_create_view/`,
        {
          vault: (partClickEventDataRef.current == undefined
            ? scannerAPIDataRef.current
            : partClickEventDataRef.current
          )?.id,
          weight: true,
        },
        0
      )
        .then((res: AxiosResponse) => {
          setWeight(res.data?.weight);
          setWeightDetails(res.data);
        })
        .catch((err: AxiosError) => {
          console.log(err, "error");
        });

      API.get(
        `/auth/calibration_user_permission/`,
        {
          action: true,
          bom_create: true,
          vault: (partClickEventDataRef.current == undefined
            ? scannerAPIDataRef.current
            : partClickEventDataRef.current
          )?.id
        },
        0
      )
        .then((res: AxiosResponse) => {
          setActions(res.data?.action);
        })
        .catch((err: AxiosError) => {
          console.log(err, "error");
        });
    }
  }, [openEntryMobile]);

  useEffect(() => {
    if (openEntryMobile && id != 0) {
      API.get(
        `/xcpep/bom_sequence_image/`,
        {
          vault: (partClickEventDataRef.current == undefined
            ? scannerAPIDataRef.current
            : partClickEventDataRef.current
          )?.id,
          parameter_id: vaultData?.image_id,
        },
        0
      )
        .then((res: AxiosResponse) => {
          setVaultData(res.data);
        })
        .catch((err: AxiosError) => {
          console.log(err, "error");
        });
    }
  }, [openEntryMobile, apiCallVaultData]);

  //-------------------image upload--------------------------------------------------------

  const saveImage = async (file: File, id: number) => {
    setIsUpdating(true);
    if (id == 1) {
      weightDetails && BOMImageSingleUpload(
        [file],
        weightDetails?.["component_details"],
        vaultData?.image_id,
        (partClickEventDataRef.current == undefined ? scannerAPIDataRef.current : partClickEventDataRef.current)?.id,
        (partClickEventDataRef.current == undefined ? scannerAPIDataRef.current : partClickEventDataRef.current)?.top_vault,
        setApiCallVaultData,
        apiCallVaultData,
        vaultData?.next,
        setIsUpdating,
        callAPIOfNextImage,
      );
    } else {
      const data = new FormData();
      weightDetails && data.append(vaultData?.image_id, file);
      weightDetails && API.put(`/xcpep/component_details/${weightDetails?.["component_details"]}/`, data, { vault: (partClickEventDataRef.current == undefined ? scannerAPIDataRef.current : partClickEventDataRef.current)?.id, image: true, rgb: true }, 0).then((res: AxiosResponse) => {
        setIsUpdating(false);
        vaultData?.next ? callAPIOfNextImage('next') : setApiCallVaultData(!apiCallVaultData);
      }).catch((err: AxiosError) => {

      });
    };
  };

  // -----------------------------------------------------------------------------------------------------

  const browseFilesPhone = (idGet: any, iconName: string) => {
    setIsID(idGet);
    setIsUpdatingIconName(iconName);
    return inputElRefPhone.current?.click?.();
  };

  const handleChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file: any = e.target.files?.[0];
    saveImage(file, id);
  };

  const handleInputClickPhone = (e: React.MouseEvent) => {
    // clear the prev value on click so that same image can be uploaded again
    (e.target as HTMLInputElement).value = "";
  };

  // -------------------------------------------------------------------------------------------------------

  const browseFiles = (idGet: any, iconName: string) => {
    setIsID(idGet);
    setIsUpdatingIconName(iconName);
    return inputElRef.current?.click?.();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file: any = e.target.files?.[0];
    saveImage(file, id);
    // setDisable( false );
  };

  const handleInputClick = (e: React.MouseEvent) => {
    // clear the prev value on click so that same image can be uploaded again
    (e.target as HTMLInputElement).value = "";
  };

  // -------------------------------------------------------------------------------------------------------------

  const handleWeightChange = (e: any) => {
    e.stopPropagation();
    setWeight(e.target.value);
  };

  const handleWeightAPICall = (event: any) => {
    weightDetails &&
      API.put(
        `/xcpep/component_details/${weightDetails?.["component_details"]}/`,
        { [weightDetails?.["parameter_id"]]: Number(weight) },
        {
          vault: (partClickEventDataRef.current == undefined
            ? scannerAPIDataRef.current
            : partClickEventDataRef.current
          )?.id,
        },
        0
      )
        .then((res: AxiosResponse) => { })
        .catch((err: AxiosError) => {
          setErrorModalFileMsg(err.response?.data[0]);
          ErrorModalFiles.open();
        });
  };

  //----------------------------------------------------------------------------------------------------------------

  const handleDeleteSelectedImages = async (e: React.MouseEvent) => {
    e.stopPropagation();
    swal({
      title: `Delete Image Parameter`,
      text: `Are you sure?`,
      icon: "error",
      //@ts-ignore
      buttons: {
        cancel: "Cancel",
        permanent: {
          text: `Delete Image`,
          value: `deleteImages`,
        },
      },
      closeOnClickOutside: false,
      closeOnEsc: false,
      className: "delete-swal",
    }).then(async (type: string) => {
      switch (type) {
        case "deleteImages":
          setDeleteLoading(true);
          weightDetails &&
            API.put(
              `/xcpep/component_details/${weightDetails?.["component_details"]}/`,
              { [vaultData?.image_id]: null },
              {
                vault: (partClickEventDataRef.current == undefined
                  ? scannerAPIDataRef.current
                  : partClickEventDataRef.current
                )?.id,
                image: true,
                rgb: true,
              },
              0
            )
              .then((res: AxiosResponse) => {
                setApiCallVaultData(!apiCallVaultData);
                setDeleteLoading(false);
              })
              .catch((err: AxiosError) => { });
          break;
        default:
          swal(`Image saved from deleting`, { icon: "error" });
      }
    });
  };


  const callAPIOfNextImage = (type: any) => {
    if (type == "prev") {
      vaultData?.prev &&
        API.get(
          `/xcpep/bom_sequence_image/`,
          {
            vault: (partClickEventDataRef.current == undefined
              ? scannerAPIDataRef.current
              : partClickEventDataRef.current
            )?.id,
            parameter_id: vaultData?.prev,
          },
          0
        )
          .then((res: AxiosResponse) => {
            setVaultData(res.data);
          })
          .catch((err: AxiosError) => {
            console.log(err, "error");
          });
    } else {
      vaultData?.next &&
        API.get(
          `/xcpep/bom_sequence_image/`,
          {
            vault: (partClickEventDataRef.current == undefined
              ? scannerAPIDataRef.current
              : partClickEventDataRef.current
            )?.id,
            parameter_id: vaultData?.next,
          },
          0
        )
          .then((res: AxiosResponse) => {
            setVaultData(res.data);
          })
          .catch((err: AxiosError) => {
            console.log(err, "error");
          });
    }
  };

  // ---------------------------------------------------------------------------------------------------------------------------------

  // the required distance between touchStart and touchEnd to be detected as a swipe
  const minSwipeDistance = 100;

  const onTouchStart = (e: any) => {
    setTouchEnd(null); // otherwise the swipe is fired even with usual touch events
    setTouchStart(e.targetTouches[0].clientX);
  };

  const onTouchMove = (e: any) => setTouchEnd(e.targetTouches[0].clientX);

  const onTouchEnd = () => {
    if (!touchStart || !touchEnd) return;
    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > minSwipeDistance;
    const isRightSwipe = distance < -minSwipeDistance;
    if (isRightSwipe) {
      vaultData?.prev != null && callAPIOfNextImage("prev");
    } else if (isLeftSwipe) {
      vaultData?.next != null && callAPIOfNextImage("next");
    }
    // add your conditional logic here
  };


  const BOMMobileEntryHandler = () => {
    // BOMMobileEntryModal.open();
    setOpenEntryMobile(true);
    
  };
  const handleCloseModal = () => {
    scannerAPIDataRef.current = [];
    partClickEventDataRef.current = undefined;
    setSearchVaultsOptions([]);
    setVaultData(undefined);
    setOpenEntryMobile(false);
    setShowQRSCanner(true)
  };

  const redirectToBOMPage = (type: string) => {
    
    if (type == "mobile") {
      BOMMobileEntryHandler();
    } else {
      if (partClickEventDataRef.current == undefined) {
        sessionStorage.setItem(
          "BOMCreateNode",
          JSON.stringify(scannerAPIDataRef.current?.ancestors)
        );
        history.push(
          `/bomentry/${scannerAPIDataRef.current?.project}/${scannerAPIDataRef.current?.project_name}/${scannerAPIDataRef.current?.top_vault
          }/${scannerAPIDataRef.current?.id}/${scannerAPIDataRef.current?.abbreviation == null
            ? "none"
            : scannerAPIDataRef.current?.abbreviation
          }/0/${scannerAPIDataRef.current?.is_leaf}/${scannerAPIDataRef.current?.is_copy}`
        );
      } else {
        sessionStorage.setItem(
          "BOMCreateNode",
          JSON.stringify(partClickEventDataRef.current?.ancestors)
        );
        history.push(
          `/bomentry/${partClickEventDataRef.current?.project}/${partClickEventDataRef.current?.project_name}/${partClickEventDataRef.current?.top_vault
          }/${partClickEventDataRef.current?.id}/${partClickEventDataRef.current?.abbreviation == null
            ? "none"
            : partClickEventDataRef.current?.abbreviation
          }/0/${partClickEventDataRef.current?.is_leaf}/${partClickEventDataRef.current?.is_copy}`
        );
      }
    }
    
  };

  const handleScan = async (vaultId: any) => {
    if (!vaultId) return;
    API.get(
      `/xcpep/vault_info/`,
      {
        vault: vaultId?.text,
        type: "bom"
        
      },
      0
    )
      .then((res: AxiosResponse) => {
        console.log(res.data)
        scannerAPIDataRef.current = res.data?.[0];
        setProjectId(res?.data?.[0]?.project);

        if (width > 650) {
          redirectToBOMPage("desktop");

        } else {
          redirectToBOMPage("mobile");
        }
        setOpenQRScanner(false);
        onClosess()
      })
      .catch((error: AxiosError) => {
        setOpenQRScanner(false);
        onClosess()
        const { data }: any = error?.response;
        Swal.fire({
          icon: "error",
          html: `<div>
                <br />
                <p style="color:"red">${data[0]}</p>   
                 </div>`,
                 customClass: {
                  container: 'swal2Container'
                }
        });
      });
  };


  const handleOnChange = (event: any, newInputValue: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (newInputValue.length < 1) {
      setSearchVaultsOptions([]);
    }
    if (newInputValue.length > 5) {
      clearInterval(timeout.current);
      timeout.current = setTimeout(() => {
        const vault = API.get(
          `/xcpep/vault/search/`,
          { search: newInputValue, project: projectId },
          0
        )
          .then((res) => {
            setSearchVaultsOptions(res.data);
          })
          .catch((err: AxiosError) => {
            setSearchVaultsOptions([]);
            const { data }: any = err?.response;
            Swal.fire({
              icon: "error",
              html: `<div>
                      <br />
                      <p style="color:"red">${data[0]}</p>   
                    </div>`,
            });
          });
      }, 1000);
    }
  };

  const handleClickPart = (event: any, newValue: any) => {
    event.preventDefault();
    event.stopPropagation();
    if (newValue != null) {
      partClickEventDataRef.current = newValue;
      if (width > 650) {
        redirectToBOMPage("desktop");
      } else {
        redirectToBOMPage("mobile");
      }
    }
  };

  const closeModal = (e:any) => {
    e?.stopPropagation();
    console.log("helllllllll",)
    onClosess()
  }
  
  const ExactDebounce = React.useRef(

    debounce(async (criteria: any) => {
      setSearchVaultsOptions([])
      const vault = API.get(`/xcpep/vault_descendants/`, { search: criteria,
        
        bom_exist: true }, 0)
      .then((res) => {
          setSearchVaultsOptions(res.data);
      }).catch((err: AxiosError) => {
        setSearchVaultsOptions([]);
        const { data }: any = err?.response;
        setErrorModalFileMsg(err.response?.data[0]);
                        ErrorModalFiles.open();
        // Swal.fire({
        //   icon: "error",
        //   html: `<div>
        //           <br />
        //           <p style="color:"red">${data[0]}</p>   
        //         </div>`,
        // });
      })
    }, 500)
  ).current;

  const handleClickOutside = (event: any) => {
    // if (!isUndefined(projectCount) && projectCount > 0)

    {
      
      {

        
        inputRef.current.style.display = "none";
      }
    }
  };
 
  React.useEffect(() => {
   
    document.addEventListener("click", handleClickOutside, false);
    return () => {
      document.removeEventListener("click", handleClickOutside, false);
    };
  }, []);

  const handleOnChangeSearch = async (event: any) => {
    setSelectedValue(event.target.value);

    var DataSplit = event?.target?.value?.split("(")?.shift();
    inputRef.current.style.display = "block";
    if (event?.target?.value?.length > 0) {
      ExactDebounce(DataSplit);
    }



  };

  // ----------------------------------------------------------------------------------
  // jsx
  const videoframestyle = {
    height: {xs : "60vh", lg: "100vh"},
    width: {xs: "100vw", lg: "40vw"},
  }

  return (
    <>
      <Modal
        open={isOpen}
        disableEnforceFocus
      >
        { <Box className={styles.child} onClick={(e)=>handleClickOutside(e)}>

        <ErrorModalFilesMail
            isOpen={ErrorModalFiles.isOpen}
            onCloseModal={ErrorModalFiles.close}
            ErrorMsg={ErrorModalFileMsg}
          />
          <Box sx={{ width: '100%'}}>
            <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
              <Typography
                style={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  width: "100%",
                  textAlign: "center",
                  marginRight: '-2rem'
                }}
              >
              BOM QR Scanner
              </Typography>     
              <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={(e)=>closeModal(e)} /> 
            </Box>
            <Divider
              sx={{
                borderColor: "primary.light",
                margin: "0 1rem",
                marginBottom: "1rem",
              }}
            />
             
          <Box sx={{ width: '100% !important',  overflow:'hidden',height:'auto',minHeight: {lg:'27rem',xl:'35rem'}}}>
              {/* @ts-ignore */}
              <QrReader
                className={styles.scannerSection}
                containerStyle={videoframestyle}
                constraints={{ facingMode: "environment" }}
                // scanDelay={300}
                onResult={handleScan}
              />
            </Box>            
          </Box>
          <Box sx={{display:"flex",
              flexDirection: "row",
              justifyContent: "center",
              margin: "1rem"}}>
            <div className={styles.inputbox} ref={OuterRef} >
            {
              <>
                <p>Search Part Number/Name</p>
                  <input
                      type="search"
                      placeholder="Search Part Number/Name"
                      value={selectedValue}
                      className={styles.searchInput}                            
                      style={{ color: "#007fff" }}
                      // @ts-expect-
                      ref={descRef}
                      onKeyPress={(event: any) => handleOnChangeSearch(event)}
                      onChange={(event: any) => handleOnChangeSearch(event)} />
              </>
            }
          <div
          style={{ display: "none", zIndex: 900, position: 'absolute' ,top:"1rem"}}
          className={styles.AutoSearchBox}
          ref={inputRef}>
          <>
            <div className={styles.searchSubText}>
              {searchVaultsOptions.map((item: any, index: any) => {
                return (
                  <div className={styles.searchRow}>
                    <div
                      key={index}
                      //  ref={focusedIndex==index?resItConatiner:null} style={{backgroundColor:index==focusedIndex?"red":""}}
                      className={styles.innserSearchSubText}
                      onClick={() => {
                        setSelectedValue(item.name);
                        inputRef.current.style.display = "none";
                        if(item!=null)
                        {
                          API.get(`/xcpep/vault_descendants/`, { vault:item?.id }, 0)
                                .then((res) => {
                                        sessionStorage.setItem(
                                          "BOMCreateNode",
                                          JSON.stringify(item?.ancestors)
                                        );
                                        window.open(
                                          `/#/bomentry/${item?.project}/${item?.project_name}/${item?.top_vault}/${item?.id}/${item?.abbreviation == null ? "none" : item?.abbreviation}/0/${item?.is_leaf}/${item?.is_copy}`
                                        );                                  
                                }).catch((err: AxiosError) => {
                                  setSearchVaultsOptions([]);
                                  const { data }: any = err?.response;
                                  setErrorModalFileMsg(err.response?.data[0]);
                                  ErrorModalFiles.open();
                                
                                })
                              }                                              
                      }
                    }
                    >
                      <span
                      // onClick={() => {
                      //   handleChangeSelection(item?.vault_id, item?.value, item?.vault_name, item?.part_no, item?.topvault_name, item?.topvault_id, item?.model_ob);
                      // }}
                      >

                        <>
                          {/* <span>{item?.value} </span> */}
                          <span>
                          
                            <span>
                              <span>(</span>
                              {item?.part_no}
                              <span>)</span>
                            </span>
                            | <span>{item?.name}</span>
                          </span>
                        </>
                      </span>
                      <span
                        style={{
                          // marginRight: "0.5rem",
                          paddingTop: "5px",
                        }}>
                        <SearchIcon />
                      </span>
                    </div>
                  </div>
                );
              })}
              {searchVaultsOptions && searchVaultsOptions?.length == 0 && (
                <p
                  style={{
                    paddingLeft: "1rem",
                    fontSize: "0.9rem",
                  }}>
                  No Data
                </p>
              )}
            </div>
          </>
          </div>
          </div>
          {/* <div style={{cursor:"pointer"}} ><SearchIcon />

          </div> */}
          </Box>

        </Box>}
        
      </Modal>
      {openEntryMobile && (<Dialog
          open={openEntryMobile}
          TransitionComponent={Transition}
          keepMounted
          aria-describedby="alert-dialog-slide-description"
          sx={{
            ".MuiPaper-root.MuiDialog-paper": {
              maxWidth: "90vw",
              minWidth: "90vw",
              borderRadius: "10px",
              margin: "0.5rem",
            },
          }}
        >
          <DialogContent
            sx={{
              padding: "0rem",
              width: { xs: "22rem", sm: "34rem" },
              overflow: "hidden",
            }}
          >
            <Box
              sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}
            >
              <Typography
                style={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  width: "100%",
                  textAlign: "center",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {vaultData?.required_image_filled && (
                  <IconButton title="Scan QR" onClick={handleCloseModal}>
                    <QrCodeScannerIcon
                      sx={{ color: "#007fff", cursor: "pointer" }}
                    />
                  </IconButton>
                )}
                <Typography
                  sx={{
                    fontSize: "1rem",
                    width: "100%",
                    justifyContent: "center",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  BOM Entry (
                  <Typography
                    style={{
                      fontSize: "1rem",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      maxWidth: "10.5rem",
                      // display: "inline-flex",
                    }}
                    textAlign={
                      (partClickEventDataRef.current == undefined
                        ? scannerAPIDataRef.current
                        : partClickEventDataRef.current
                      )?.top_vault_name
                    }
                  >
                    {
                      (partClickEventDataRef.current == undefined
                        ? scannerAPIDataRef.current
                        : partClickEventDataRef.current
                      )?.top_vault_name
                    }
                  </Typography>
                  )
                </Typography>
              </Typography>
              <CancelIcon
                titleAccess="Close"
                sx={{ cursor: "pointer", margin: "0.5rem" }}
                onClick={handleCloseModal}
              />
            </Box>
            <Divider
              sx={{
                borderColor: "primary.light",
                margin: "0 1rem",
                marginBottom: "1rem",
              }}
            />
            <Box sx={{ padding: "0 1rem 1rem" }}>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "0.2rem",
                  }}
                >
                  <Typography sx={{ fontSize: "1rem", width: "7rem" }}>
                    Part Name
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      whiteSpace: "nowrap",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      width: "11rem",
                    }}
                  >
                    :{" "}
                    {
                      (partClickEventDataRef.current == undefined
                        ? scannerAPIDataRef.current
                        : partClickEventDataRef.current
                      )?.name
                    }
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "0.2rem",
                  }}
                >
                  <Typography sx={{ fontSize: "1rem", width: "7rem" }}>
                    Part Number
                  </Typography>
                  <Typography sx={{ fontSize: "1rem" }}>
                    :{" "}
                    {
                      (partClickEventDataRef.current == undefined
                        ? scannerAPIDataRef.current
                        : partClickEventDataRef.current
                      )?.part_no
                    }
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "0.2rem",
                  }}
                >
                  <Typography sx={{ fontSize: "1rem", width: "7rem" }}>
                    Weight <span style={{ color: "red" }}>*</span>
                  </Typography>
                  <div style={{ fontSize: "1rem", width: "60%" }}>
                    :{" "}
                    <TextField
                      type="number"
                      variant="standard"
                      placeholder="Weight"
                      disabled={actions?.includes('C') || actions?.includes('U') ? false : true}
                      size="small"
                      value={weight}
                      onChange={handleWeightChange}
                      onBlur={handleWeightAPICall}
                      inputProps={{
                        step: "any",
                        min: 0,
                        style: { fontSize: "1rem", marginLeft: "0.5rem" },
                      }}
                      InputLabelProps={{
                        shrink: true,
                        color: "primary",
                      }}
                      sx={{
                        width: "70%",
                        borderBottomColor: "primary.light",
                        ".MuiInputBase-root.MuiInput-root": {
                          fontSize: "1rem !important",
                          paddingBottom: "0rem",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "primary.light",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "primary.main",
                        },
                      }}
                    />
                  </div>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    marginBottom: "0.2rem",
                  }}
                >
                  <Typography sx={{ fontSize: "1rem", width: "7rem" }}>
                    Quantity
                  </Typography>
                  <Typography sx={{ fontSize: "1rem" }}>
                    :{" "}
                    {
                      (partClickEventDataRef.current == undefined
                        ? scannerAPIDataRef.current
                        : partClickEventDataRef.current
                      )?.quantity
                    }
                  </Typography>
                </Box>
              </Box>
              <Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    marginTop: "1.5rem",
                    marginBottom: "0.5rem",
                  }}
                >
                  <LoadingButton
                    disabled={vaultData?.prev == null}
                    onClick={() => callAPIOfNextImage("prev")}
                  >
                    <ArrowCircleLeftRoundedIcon />
                  </LoadingButton>
                  <Typography sx={{ fontSize: "1rem" }}>
                    {vaultData?.image_name}
                    {vaultData?.required && (
                      <span style={{ color: "red" }}> *</span>
                    )}
                  </Typography>
                  <LoadingButton
                    disabled={vaultData?.next == null}
                    onClick={() => callAPIOfNextImage("next")}
                  >
                    <ArrowCircleRightRoundedIcon />
                  </LoadingButton>
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    height: "22rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                  onTouchStart={onTouchStart}
                  onTouchMove={onTouchMove}
                  onTouchEnd={onTouchEnd}
                >
                  {/* @ts-ignore */}
                  {vaultData?.image_url ? (<RViewerJS>
                    <img
                      src={vaultData?.image_url}
                      style={{
                        width: "100%",
                        objectFit: "contain",
                        maxHeight: "21rem",
                        maxWidth: "78vw",
                      }}
                    />
                    {/* <Image
                    src={vaultData?.image_url}  
                    style={{
                      width: "100%",
                      objectFit: "contain",
                      maxHeight: "21rem",
                      maxWidth: "78vw",
                    }}
                  /> */}
                  </RViewerJS>
                  ) : (
                    <span onClick={() => actions?.includes('C') && browseFilesPhone(1, "OriginalCamera")}>
                      No Image Available
                    </span>
                  )}
                </Box>
                <Box sx={{ margin: "1rem 0" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: "1rem",
                      justifyContent: "center",
                    }}
                  >
                    {/* <EditIcon titleAccess="Edit Image" sx={{ cursor:'pointer' }} /> */}
                    {actions?.includes('U') && <LoadingButton
                      startIcon={
                        <UploadIcon
                          titleAccess="Upload Original Background"
                          sx={{ cursor: "pointer", marginRight: "-1rem" }}
                        />
                      }
                      sx={{
                        padding: 0, minWidth: "2rem",
                        '.MuiCircularProgress-root': {
                          color: 'primary.main',
                        },
                      }}
                      onClick={() =>
                        browseFiles(1, "OriginalWithBackgroudGallery")
                      }
                      loading={
                        isUpdatingIconName == "OriginalWithBackgroudGallery" &&
                        isUpdating
                      }
                    >
                    </LoadingButton>}
                    {actions?.includes('U') && <LoadingButton
                      startIcon={
                        !isUpdating && <UploadIcon
                          titleAccess="Upload without Background"
                          sx={{
                            cursor: "pointer",
                            marginRight: "-1rem",
                          }}
                        />
                      }
                      sx={{
                        padding: 0, minWidth: "2rem",
                        color: "#999191",
                        '.MuiCircularProgress-root': {
                          color: 'primary.main',
                        },
                      }}
                      onClick={() =>
                        browseFiles(2, "OriginalWithoutBackgroudGallery")
                      }
                      loading={
                        isUpdatingIconName == "OriginalWithoutBackgroudGallery" && isUpdating
                      }
                    ></LoadingButton>}
                    {actions?.includes('U') &&
                      <LoadingButton
                        startIcon={
                          <CameraAltRoundedIcon
                            titleAccess="Camera"
                            sx={{
                              cursor: "pointer",
                              marginRight: "-1rem",
                            }}
                          />
                        }
                        sx={{
                          padding: 0, minWidth: "2rem",
                          '.MuiCircularProgress-root': {
                            color: 'primary.main',
                          },
                        }}
                        onClick={() => browseFilesPhone(1, "OriginalCamera")}
                        loading={
                          isUpdatingIconName == "OriginalCamera" && isUpdating
                        }
                      ></LoadingButton>}
                    {!vaultData?.required && vaultData?.image_url && actions?.includes('D') && (
                      <LoadingButton
                        startIcon={
                          <DeleteIcon
                            titleAccess="Delete"
                            sx={{ cursor: "pointer", marginRight: "-1rem" }}
                          />
                        }
                        sx={{
                          padding: 0, minWidth: "2rem",
                          '.MuiCircularProgress-root': {
                            color: 'primary.main',
                          },
                        }}
                        onClick={handleDeleteSelectedImages}
                        loading={deleteLoading}
                      ></LoadingButton>
                    )}
                  </Box>
                </Box>
              </Box>
            </Box>
            <input
              type="file"
              style={{ display: "none" }}
              // required
              accept="image/*"
              id="file"
              name="file"
              ref={inputElRefPhone}
              onChange={handleChangePhone}
              onClick={handleInputClickPhone}
              capture="user"
            />

            <input
              type="file"
              style={{ display: "none" }}
              // required
              accept="image/*"
              id="file"
              name="file"
              ref={inputElRef}
              onChange={handleChange}
              onClick={handleInputClick}
            />
          </DialogContent>
        </Dialog>)}
    </>
  );
};

export default BOMDataEntryQRModal;
