import React, { useEffect, useState } from "react";
import {
  Box,
  Checkbox,
  Divider,
  Drawer,
  Button,
  IconButton,
  List,
  ListItem,
  MenuItem,
  Typography,
  Menu,
  Table,
  TableRow,
  TableCell,
  Chip,
  Skeleton,
  Autocomplete,
  TextField
} from "@mui/material";
import { API } from "../../api-services";
import AddIcon from "@mui/icons-material/Add";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory, useRouteMatch } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import TableIcon from "../../Assets/images/svgs/tableIcon.svg"
import styles from "./AnalysisLandingPage.module.scss";
import {
  Bar,
  BarChart,
  Cell,
  Label,
  LabelList,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import CancelIcon from '@mui/icons-material/Cancel';
import { ReactComponent as GotoIcon } from "../../Assets/images/rightArrows.svg";

//Dilalog Box
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { useBaseModal } from "../SearchPage/useBaseModal";
import { CurrencyChange } from "../../ViewModule/CostingView/CurrencyChange";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { currencyFormat } from "../../utlis/CurrencyFormat";
import { indexOf, set } from "lodash";
import useWindowDimensions from "../../ui-reusable-component/useWindowDimensions";
import { useDocumentTitle } from "../../ui-reusable-component/useDocumentTitle";
import ConfigureColorModal from "./ConfigureColorModal";
import { to } from "@react-spring/web";
import CostingAnalysisPareto from "./CostingAnalysisPareto";
import { ButtonProps } from "@mui/material/Button";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid";
import TableChartIcon from '@mui/icons-material/TableChart';

type Anchor = "top" | "left" | "bottom" | "right";

interface Params {
  projectId: any;
  topVault: any;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface AnalysisLandingPageProps { }

const AnalysisLandingPage: React.FC<AnalysisLandingPageProps> = (props) => {
  useDocumentTitle("BOM Analysis");
  const { projectId, topVault } = useRouteParams<Params>();
  const [selectedProject, setSelectedProject] = useState<any>([]);
  const [selectedProduct, setSelectedProduct] = useState<any>([]);
  const [anchorElMenu, setAnchorElMenu] = React.useState<null | HTMLElement>(
    null
  );
  const [okButtonLoader, setOKButtonLoader] = useState<boolean>(false);
  const [weightLoader, setWeightLoader] = useState<boolean>(true);
  const [partCountLoader, setPartCountLoader] = useState<boolean>(true);
  const [categoryWisePartLoader, setCategoryWisePartLoader] = useState<boolean>(true);
  const [materialLoader, setMaterialLoader] = useState<boolean>(true);
  const [analyseWeight, setAnalyseWeight] = useState<any>([]);
  const [analyseCategoryWisePart, setCategoryWisePart] = useState<any>([]);
  const [analyseMaterial, setAnalyseMaterial] = useState<any>([]);
  const [analysePartCount, setAnalysePartCount] = useState<any>([]);
  const [analyseProjects, setAnalyseProjects] = useState<any>();
  const [analyseProduct, setAnalyseProduct] = useState<any>();
  const [AllProjectdata, setAllProjectdata] = useState();
  const [analysisModuleName, setAnalysisModuleName] = useState<any>();
  const currencyModal = useBaseModal();
  const colorConfigurationModal = useBaseModal();
  const [count, setCount] = React.useState<any>(0);
  const [Counter, setCounter] = useState(0)
  const { height, width } = useWindowDimensions();
  const [moduleApi, setModuleApi] = useState<any>();
  const [currentProject, setCurrentProject] = useState<any>();
  const [topVaultColorMap, setTopVaultColorMap] = useState<any>({});
  const [topVaultsColor, setTopVaultsColor] = useState<any>([]);
  const [topVaultColorisFetched, setTopVaultColorisFetched] = useState<boolean>(false);
  const [allCategory, setAllCategory] = useState<any>([]);
  const [selectedCategory, setSelectedCategory] = useState<any>([]);
  const [allMaterial, setAllMaterial] = useState<any>([]);
  const [selectedMaterial, setSelectedMaterial] = useState<any>([]);
  const [redirectRoute, setRedirectRoute] = useState<any>();
  const [redirectInnerRoute, setRedirectInnerRoute] = useState<any>();
  const [closeRedirectPopup, setCloseRedirectPopup] = useState<boolean>(false);

  const [openMoreGraphPopUp, setOpenMoreGraphPopUp] = React.useState(false);
  const [type, setType] = useState<any>();
  const [loaderWeightAnalysis, setLoaderWeightAnalysis] = React.useState(false);
  const [tableOpenParetoAnalysis, setTableOpenParetoAnalysis] = React.useState(false);
  const [closeRedirectPopupPart, setCloseRedirectPopupPart] = React.useState(false);
  const [selectedTopVault, setSelectedTopVault] = useState<any>();


  let [x, setX] = useState<any>();
  let [y, setY] = useState<any>();
  const valBarRedirect: any = (e: any) => {
    // setRedirectRoute(itemSelected);
    setType(e?.tooltipPayload?.[0]?.dataKey);
    setSelectedTopVault(e?.topvault_id);
    setRedirectInnerRoute(e);
    console.log(e?.tooltipPayload?.[0]?.dataKey, "e?.tooltipPayload?.[0]?.dataKey")
    if (e?.tooltipPayload?.[0]?.dataKey == "weight") {
      setCloseRedirectPopup(true);
      setCloseRedirectPopupPart(false);
    }
    else {
      setCloseRedirectPopupPart(true);
      setCloseRedirectPopup(false);
    }
    setX(e?.x);
    setY(e?.y);
  }

  const valRedirect: any = (moduleType: any) => {
    // const valueFiltered: any = redirectInnerRoute && redirectInnerRoute?.top_vault_details?.filter((item: any) => { return item?.top_vault_name == redirectRoute });
    sessionStorage.setItem("node", JSON.stringify(selectedTopVault));
    window.open(`/#/view/${projectId}/${selectedTopVault}/0/product/true`);
    setCloseRedirectPopup(false);
    setCloseRedirectPopupPart(false);
  }
  const history = useHistory();
  const { url } = useRouteMatch();

  //Dialog BOx
  const [openDialog, setDialogOpen] = React.useState(false);
  const handleDialogClickOpen = () => {
    setDialogOpen(true);
  };
  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  const csvOptions: GridCsvExportOptions = {
    fileName: `${type == "weight" ? "Weight" : "Part"} Pareto Analysis Table In CSV`,
    delimiter: ",",
    utf8WithBom: true
  };

  const CustomExportButton = (props: ButtonProps) => (
    <GridToolbarExportContainer {...props}>
      <GridCsvExportMenuItem options={csvOptions} />
    </GridToolbarExportContainer>
  );

  const CustomToolbar = (props: any) => (
    <GridToolbarContainer style={{ height: "2rem" }}>
      {/* <h4 style={{ position: "absolute", left: "40%" }}>{props}</h4> */}
      <span style={{ position: "absolute", right: "0rem" }}>
        <GridToolbarColumnsButton />
        <CustomExportButton />
      </span>
    </GridToolbarContainer>
  );


  const color: any = [
    "#dd6b4d",
    "#ffce76",
    "#716f96",
    "#2b82bf",
    "#003d68",
    "#eaba51",
    "#ff9d9a",
    "#d3d3d3",
    "#a0cbe8",
    "#4e79a7",
    "#d9b364",
    "#e5cd99",
    "#c7e5e3",
    "#91cdc7",
    "#58b4ac",
    "#00688b",
    "#00bfff",
    "#ffa500",
    "#cd6600",
    "#3c0000",
    "#d22323",
    "#5a4b50",
    "#ffd2d7",
    "#1e64af",
    "#faaf96",
    "#9bb996",
    "#aae6cd",
    "#aaa5a5",
    "#782d2d",
    "#1e4b55",
    "#69785f",
    "#ffd28c",
    "#419bd2",
    "#f57382",
    "#dcebc3",
    "#b44641",
    "#91e6cd",
    "#be6e82",
    "#a5236e",
    "#f5dc50",
    "#2d969b",
    "#fa913c",
    "#ff4164",
    "#64505a",
    "#9b5a64",
    "#376ea5",
    "#c86469",
    "#5f7896",
    "#e1b496",
    "#a0a5a0",
    "#e1e6e6",
    "#6e6437",
    "#2d2d1e",
    "#00324b",
    "#e1d2af",
    "#82dce1",
    "#fff5af",
    "#cddcdc",
    "#f57ddc",
    "#ffb946",
    "#facdaf",
    "#aa96b4",
    "#191423",
    "#beaf5f",
    "#6d6d6d",
    "#3bf210",
    "#666666",
    "#cccc99",
    "#669900",];

  const topVaultColor: any = [
    "#075482",
    "#e14958",
    "#fe932b",
    "#64d0dc",
    "#33b1e4",
    "#ffcc67",
    "#8dc73f",
    "#8945aa",
    "#9fd3c7",
    "#385170",
  ];

  const topVaultsParam: any =
    topVault && typeof topVault == "string"
      ? topVault?.split(",")?.map(Number)
      : [topVault];
  const projectIdParam: any =
    projectId && typeof projectId == "string"
      ? projectId?.split(",")?.map(Number)
      : [projectId];
  var filterDataIds = topVaultsParam?.sort(function (a: any, b: any) { return a - b });
  var sortedTopVault = filterDataIds?.toString();



  var projectLength = AllProjectdata && Object.keys(AllProjectdata[0])?.length;

  const deleteProject = (item: any) => {
    setCounter(Counter + 1)
    var dataArray = [item];
    const filteredProject =
      AllProjectdata && AllProjectdata[0] && Object.keys(AllProjectdata[0])
        .filter((key) => !dataArray.includes(key))
        .reduce((obj: any, key: any) => {
          obj[key] = AllProjectdata[0][key];
          return obj;
        }, {});
    const FilterTopVault =
      filteredProject && Object.values(filteredProject)
        .map((item: any, ind: any) => {
          return item;
        })
        .flat(1)
        .map((item: any, ind: any) => item?.id);

    let valProject: any = [];
    let abc: any = filteredProject && Object.entries(filteredProject).forEach(([key, value]: any) => {
      valProject.push(filteredProject && filteredProject[key][0]['project_id']);
    });
    //  @ts-ignore
    history.push(`/analysis/${valProject?.length > 0 && valProject && valProject.toString()}/${FilterTopVault?.toString()}`)
  }

  const valOpenMoreGraphPopUpDialog: any = () => {
    setOpenMoreGraphPopUp(true);
    setCloseRedirectPopup(false);
    setCloseRedirectPopupPart(false);
  };


  const getColorCode = () => {
    API.get("/customer/get_product_color/", {
      product_ids: sortedTopVault
    }, 0)
      .then((res) => {
        let topVaultColorObj: any = {};
        res?.data?.forEach((item: any, index: any) => {
          topVaultColorObj[item?.product_id] = item?.color;
        })
        console.log(topVaultColorObj, "topVaultColorObjCheck")
        setTopVaultColorMap(topVaultColorObj);
        setTopVaultColorisFetched(true);

      })
      .catch((e) => {
        console.log(e);
      }
      )
  }

  const getAllCategory = () => {
    API.get("/customer/get_product_all_category/", {
      vehicle_ids: sortedTopVault
    }, 0)
      .then((res) => {
        console.log(res?.data?.category, "res?.data")
        setAllCategory(res?.data?.category);
      })
      .catch((e) => {
        console.log(e);
      }
      )
  }

  const getAllMaterial = () => {
    API.get("/customer/get_product_material/", {
      vehicle_ids: sortedTopVault
    }, 0)
      .then((res) => {
        console.log(res?.data?.materials, "res?.data")
        setAllMaterial(res?.data?.materials);
      })
      .catch((e) => {
        console.log(e);
      }
      )
  }


  React.useEffect(() => {
    getColorCode()
    getAllCategory()
    getAllMaterial()
  }
    , [topVault, Counter]);

  const updateColor = () => {
    let topVaultsMap: any = {};
    sortedTopVault?.split(',')?.forEach((item: any, index: any) => {
      console.log(item);
      if (topVaultColorMap[item] != '' && topVaultColorMap[item] != undefined && topVaultColorMap[item] != null) {
        topVaultsMap[item] = topVaultColorMap[item];
      }
      else {
        topVaultsMap[item] = topVaultColor[index];
      }

    });
    console.log(topVaultsMap);
    setTopVaultsColor(topVaultsMap);

    //key value
  }

  React.useEffect(() => {
    updateColor()
  }, [topVaultColorMap, Counter]);

  const handleDelete = (item1: any) => {
    setCounter(Counter + 1)
    var ProjectIdsdata = AllProjectdata && AllProjectdata[0];
    var CollectionProjectids = ProjectIdsdata && Object.values(ProjectIdsdata).map((item: any, index: any) => {
      return item.map((item2: any, index1: any) => { return item2?.id })
    }).flat(1).filter((item3: any, index: any) => { return item3 != item1 })

    var CollectionProjectidsABC = ProjectIdsdata && Object.values(ProjectIdsdata).map((item: any, index: any) => {
      return item.map((item2: any, index1: any) => { return item2?.id })
    })

    let vaultsData: any = [];
    let vaultVal: any = [];
    let abc: any = ProjectIdsdata && Object.entries(ProjectIdsdata).forEach(([key, value]: any) => {
      vaultsData.push(ProjectIdsdata && ProjectIdsdata[key])
      // ?.map((item:any)=> item?.id == item1 && vaultsData.push(item)),"ProjectIdsdata");
    });

    const leftProduct: any = vaultsData?.flat()?.filter((item: any) => item?.id != item1);

    let projectIDLeft: any = [];
    let mapleftProduct: any = leftProduct && leftProduct?.map((item: any) => {
      if (projectIDLeft.includes(item?.project_id)) {
        return;
      }
      else {
        projectIDLeft.push(item?.project_id);
      }
    });
    // @ts-ignore
    history.push(`/analysis/${projectIDLeft.toString()}/${CollectionProjectids?.toString()}`)
  }

  const ChipContainer = (chipItem: any) => {
    var chipdata = AllProjectdata && AllProjectdata[0][chipItem]
    // @ts-ignore
    var mapChipData = chipdata?.map((item: any, index: any) => {
      return (<>{filterDataIds && filterDataIds?.length <= 2 ?
        <Chip
          label={item?.name}
          sx={{
            fontSize: "0.87rem",
            height: { xs: "30px", lg: "20px", xl: "30px" },
            color: "primary.light !important",
            display: 'flex',
            columnGap: '5px',
            backgroundColor: '#007fff14 !important',
            "& .MuiChip-deleteIcon 	.MuiChip-icon	.MuiChip-deleteIconColorPrimary":
            {
              fontSize: "1rem !important",
              color: 'primary.main !important',
            },
          }}
        /> :
        <Chip
          icon={
            <CancelIcon
              onClick={() => handleDelete(item?.id)}
              color="primary"
              sx={{
                fontSize: "1.2rem",
                cursor: "pointer",
                color: 'primary.main !important',
                opacity: 0.8,
                "& :hover": {
                  transform: "scale(1.1)",
                  transition: "transform 0.5s ease",
                },
              }}
              titleAccess="Delete"
            />
          }
          // onDelete={() => handleDelete(item?.id)}
          label={item?.name}
          sx={{
            fontSize: "0.87rem",
            height: { xs: "30px", lg: "20px", xl: "30px" },
            margin: "2px 0",
            color: "primary.light !important",
            backgroundColor: "#007fff14 !important",
            '& :hover': {
              opacity: 1,
            }
          }}
        />}
      </>)
    })
    return mapChipData;
  }

  useEffect(() => {
    API.get(
      "/customer/analysis/",
      { get_drawer_values: "modules" },
      0
    ).then((res: any) => {
      setModuleApi(res?.data);
    });
  }, [])

  useEffect(() => { setAnalysisModuleName(url?.includes('analysis_costing') ? 'analysis_costing' : 'analysis') }, [projectId, topVault])

  useEffect(() => {
    API.get("customer/analysis/", { product_ids: sortedTopVault, get_drawer_values: url?.includes('analysis_costing') ? 'Costing' : 'BOM' }).then((res: any) => {
      var datavalues = res?.data?.map((item: any, index: any) => {
        return Object.values(item).map((item1: any, index: any) => {
          return item1.map((item2: any, index: any) => { return item2?.project_id })
        })
      }).flat(2)
      //  @ts-ignore
      setAllProjectdata(res.data)
    })
  }, [topVault])

  useEffect(() => {
    API.get(`customer/analysis/`, { get_drawer_values: "project", module: analysisModuleName == 'analysis_costing' ? 'Costing' : 'BOM' }, 0)
      .then((res: any) => {
        setAnalyseProjects(res?.data);
      })
      .catch((err: any) => {
        console.log(err, "error");
      });
  }, [analysisModuleName]);

  useEffect(() => {
    setWeightLoader(true);
    if (topVaultColorisFetched) {
      API.get(
        `/customer/analysis/`,
        {
          vehicle_ids: sortedTopVault,
          analysis_type: "weight",
          colors: sortedTopVault?.split(',')?.map((item: any, index: any) => {
            console.log(item, topVaultColorMap[item], "topVaultColorObjCheck");
            if (topVaultColorMap[item] != '' && topVaultColorMap[item] != undefined && topVaultColorMap[item] != null) {
              return topVaultColorMap[item];
            }
            else {
              return topVaultColor[index];
            }
          })?.toString(),

        },
        0
      )
        .then((res: any) => {
          setAnalyseWeight(res?.data);
          setWeightLoader(false);
        })
        .catch((err: any) => {
          console.log(err, "error");
        });
    }
  }, [projectId, topVault, topVaultColorMap, Counter, topVaultColorisFetched]);

  useEffect(() => {
    if(selectedCategory.length == 0){
    setCategoryWisePartLoader(true);
    API.get(
      `/customer/analysis/`,
      { vehicle_ids: sortedTopVault, analysis_type: "category_part_analysis" },
      0
    )
      .then((res: any) => {
        console.log(res?.data, "res?.data")

        setCategoryWisePart(res?.data);
        setCategoryWisePartLoader(false);
      })
      .catch((err: any) => {
        console.log(err, "error");
      });
    }
  }, [projectId, topVault, selectedCategory]);

  const handleApplyFilterCategory = () => {
    console.log(selectedCategory, "selectedCategory")
    setCategoryWisePartLoader(true);
    API.get(
      `/customer/get_product_all_category/`,
      { vehicle_ids: sortedTopVault, category_ids: selectedCategory?.toString() },
      0
    )
      .then((res: any) => {
        // console.log(res?.data, "res?.data")
        setCategoryWisePart(res?.data);
        setCategoryWisePartLoader(false);
      })
      .catch((err: any) => {
        console.log(err, "error");
      });

  }

  const handleApplyFilterMaterial = () => {
    console.log(selectedMaterial, "selectedMaterial")
    setMaterialLoader(true);
    API.get(
      `/customer/get_product_material/`,
      { vehicle_ids: sortedTopVault, materials: selectedMaterial?.toString() },
      0
    )
      .then((res: any) => {
        let newDataStrcuture: any = res?.data.map((item: any, index: any) => Object.keys(item));
        newDataStrcuture = newDataStrcuture.flat(1);
        // console.log(newDataStrcuture, "hjjjjjjjjjjjjjjjjjj")
        newDataStrcuture = new Set(newDataStrcuture);
        newDataStrcuture.delete('name');
        // console.log(newDataStrcuture, "hjjjjjjjjjjjjjjjjjj")

        let newMaterialDataSet: any = res?.data.map((item: any, index: any) => {
          let analyseMaterialData: any = {};
          newDataStrcuture.forEach((item1: any, index1: any) => {
            // console.log(item[item1], item1 ,"item[item1]")
            if (!item[item1]) {
              analyseMaterialData[item1] = 0;
            }
            else {
              analyseMaterialData[item1] = item[item1];
            }

          })
          analyseMaterialData["name"] = item?.name
          return analyseMaterialData
        })

        // console.log(newMaterialDataSet, "hjjjjjjjjjjjjjjjjjj")
        setAnalyseMaterial(newMaterialDataSet);
        setMaterialLoader(false);
      })
      .catch((err: any) => {
        console.log(err, "error");
      });

  }

  useEffect(() => {
    setPartCountLoader(true);
    if (topVaultColorisFetched) {
      API.get(
        `/customer/analysis/`,
        {
          vehicle_ids: sortedTopVault,
          analysis_type: "qty",
          colors: sortedTopVault?.split(',')?.map((item: any, index: any) => {
            console.log(item, topVaultColorMap[item], "topVaultColorObjCheck");
            if (topVaultColorMap[item] != '' && topVaultColorMap[item] != undefined && topVaultColorMap[item] != null) {
              return topVaultColorMap[item];
            }
            else {
              return topVaultColor[index];
            }
          })?.toString(),
        },
        0
      )
        .then((res: any) => {
          setAnalysePartCount(res?.data);
          setPartCountLoader(false);
        })
        .catch((err: any) => {
          console.log(err, "error");
        });
    }
  }, [projectId, topVault, topVaultColorMap, Counter, topVaultColorisFetched]);

  useEffect(() => {
    if(selectedMaterial.length ==  0){
    setMaterialLoader(true);
    API.get(
      `/customer/analysis/`,
      { vehicle_ids: sortedTopVault, analysis_type: "material_distribution" },
      0
    )
      .then((res: any) => {
        // console.log(res?.data, "hjjjjjjjjjjjjjjjjjj")
        let newDataStrcuture: any = res?.data.map((item: any, index: any) => Object.keys(item));
        newDataStrcuture = newDataStrcuture.flat(1);
        // console.log(newDataStrcuture, "hjjjjjjjjjjjjjjjjjj")
        newDataStrcuture = new Set(newDataStrcuture);
        newDataStrcuture.delete('name');
        // console.log(newDataStrcuture, "hjjjjjjjjjjjjjjjjjj")

        let newMaterialDataSet: any = res?.data.map((item: any, index: any) => {
          let analyseMaterialData: any = {};
          newDataStrcuture.forEach((item1: any, index1: any) => {
            // console.log(item[item1], item1 ,"item[item1]")
            if (!item[item1]) {
              analyseMaterialData[item1] = 0;
            }
            else {
              analyseMaterialData[item1] = item[item1];
            }

          })
          analyseMaterialData["name"] = item?.name
          return analyseMaterialData
        })

        // console.log(newMaterialDataSet, "hjjjjjjjjjjjjjjjjjj")
        setAnalyseMaterial(newMaterialDataSet);
        setMaterialLoader(false);
      })
      .catch((err: any) => {
        console.log(err, "error");
      });
    }
  }, [projectId, topVault, selectedMaterial]);

  useEffect(() => {
    if (selectedProject.length > 0 && selectedProject != undefined) {
      API.get(
        `customer/analysis/`,
        { get_drawer_values: "top_vault", project: selectedProject.toString(), module: analysisModuleName == 'analysis_costing' ? 'Costing' : 'BOM' },
        0
      )
        .then((res: any) => {
          // console.log(res?.data, "res?.data")
          setAnalyseProduct(res?.data);
          setSelectedProduct([...res?.data?.filter((item: any) => item?.project == currentProject)?.map((item: any) => item?.id?.toString()), ...selectedProduct]);



        })
        .catch((err: any) => {
          console.log(err, "error");
        });
    }
  }, [selectedProject, analysisModuleName]);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }
        setSelectedProject(projectId && projectId.toString()?.includes(',') ? projectId?.split(",") : [projectId?.toString()]);
        setSelectedProduct(sortedTopVault && sortedTopVault.toString()?.includes(',') ? sortedTopVault?.split(",") : [sortedTopVault?.toString()]);
        setState({ ...state, [anchor]: open });
        if (open) {
          setAnalysisModuleName(url?.includes('analysis_costing') ? 'analysis_costing' : 'analysis');
        }
      };

  const selectAllProject = (productIds: any) => {
    // console.log(selectedProject, productIds,"selectedProject")
    if (selectedProject?.includes(productIds?.toString())) {
      let letProjects: any = selectedProject && selectedProject?.filter((item: any) => item !== productIds?.toString());
      let remainingProductData: any = analyseProduct && analyseProduct?.filter((item: any) => { return selectedProduct?.includes(item?.id?.toString()) });
      let includedProductData: any = analyseProduct && remainingProductData?.filter((item: any) => { return letProjects?.includes(item?.project?.toString()) });
      let remainingProduct: any = analyseProduct && remainingProductData && includedProductData && includedProductData?.map((item: any) => { return item?.id?.toString() })
      analyseProduct && remainingProductData && includedProductData && remainingProduct && setSelectedProduct(remainingProduct);
      setSelectedProject((prev: any) =>
        prev?.filter((item: any) => item !== productIds?.toString())
      );
    } else {
      setSelectedProject((prev: any) => [...prev, productIds?.toString()]);
      setCurrentProject(productIds)

    }
  };

  const getselectedProjectBackgroundColor = (idd: any) => {
    if (selectedProject?.includes(idd?.toString())) {
      return "primary.main";
    } else {
      return "primary.light";
    }
  };
  const getSelectedProjectTextColor = (idd: any) => {
    if (selectedProject?.includes(idd?.toString())) {
      return "primary.light";
    } else {
      return "primary.main";
    }
  };

  const selectAllProduct = (productIds: any) => {
    if (selectedProduct?.includes(productIds?.toString())) {
      setSelectedProduct((prev: any) =>
        prev?.filter((item: any) => item !== productIds.toString())
      );
    } else {
      // console.log(productIds, "res?.data");
      setSelectedProduct((prev: any) => [...prev, productIds?.toString()]);
    }
  };

  const getselectedProductBackgroundColor = (idd: any) => {
    if (selectedProduct?.includes(idd?.toString())) {
      return "#2b9cf6eb";
    } else {
      return "primary.light";
    }
  };
  const getSelectedProductTextColor = (idd: any) => {
    if (selectedProduct?.includes(idd?.toString())) {
      return "primary.light";
    } else {
      return "primary.main";
    }
  };

  const backButtonHandler: any = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    history.push("/landing");
  };

  let disableOkBtn = () => {
    let temp = false;
    if (selectedProject?.length >= 1) {
      if (selectedProduct?.length >= 1) {
        if (selectedProduct?.length < 8) {
          temp = false;
        } else {
          temp = true;
        }
      } else {
        temp = true;
      }
    } else {
      temp = true;
    }
    return temp;
  };

  const changeOkButton = () => {
    history.push(`/${analysisModuleName}/${selectedProject.toString()}/${selectedProduct.toString()}`);
  }

  const list = (anchor: Anchor) => (
    <Box
      sx={{ minWidth: 300, width: anchor === "top" || anchor === "bottom" ? "auto" : "auto" }}
      role="presentation"
    >
      <Box sx={{ width: "100%", padding: '0' }}>
        <Box sx={{ width: '100%' }}>
          <Box style={{ display: "flex", columnGap: '5px', padding: '0 1rem', marginBottom: '0.5rem' }}>
            {moduleApi && moduleApi?.includes('BOM') &&
              <div onClick={() => { return setAnalysisModuleName('analysis'), setSelectedProject([]), setSelectedProduct([]), setAnalyseProduct(undefined) }} style={analysisModuleName == 'analysis' ? { width: `${moduleApi && moduleApi?.length > 1 ? '50%' : '100%'}`, backgroundColor: '#007fff', padding: '0.2rem', textAlign: 'center', cursor: 'pointer', } : { width: '50%', backgroundColor: '#007fff14', padding: '0.2rem', textAlign: 'center', cursor: 'pointer' }}>
                <Typography style={analysisModuleName == 'analysis' ? { color: 'white', fontSize: '0.75rem' } : { color: '#007fff', fontSize: '0.75rem' }}>BOM</Typography>
              </div>}
            {moduleApi && moduleApi?.includes('Costing') &&
              <div style={analysisModuleName == 'analysis_costing' ? { width: `${moduleApi && moduleApi?.length > 1 ? '50%' : '100%'}`, backgroundColor: '#007fff', padding: '0.2rem', textAlign: 'center', cursor: 'pointer' } : { width: '50%', backgroundColor: '#007fff14', padding: '0.2rem', textAlign: 'center', cursor: 'pointer' }}>
                <Typography onClick={() => { return setAnalysisModuleName('analysis_costing'), setSelectedProject([]), setSelectedProduct([]), setAnalyseProduct(undefined) }} style={analysisModuleName == 'analysis_costing' ? { color: 'white', fontSize: '0.75rem' } : { color: '#007fff', fontSize: '0.75rem' }}>Costing</Typography>{" "}
              </div>}
          </Box>
        </Box>
        <Box sx={{ width: "100%", height: { lg: '84vh', xl: '83vh' }, padding: "0 1rem" }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3">
          <Box>
            <Typography
              style={{
                width: "100%",
                fontSize: "0.75rem",
              }}
            >
              Project Name
            </Typography>
            {analyseProjects &&
              analyseProjects?.map((item: any, index: any) => {
                return (
                  <>
                    <Box sx={{ alignItems: "center" }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Checkbox
                          style={{ padding: '3px' }}
                          checked={
                            selectedProject?.includes(item.id?.toString()) ? true : false
                          }
                          onClick={() => selectAllProject(item?.id?.toString())}
                          inputProps={{ "aria-label": "controlled" }}
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                        />
                        <Box
                          onClick={() => selectAllProject(item?.id?.toString())}
                          key={index}
                          className={styles.selectVehBtn}
                          sx={{
                            // margin: "0rem 2rem",
                            padding: "0.2rem 0.5rem",
                            backgroundColor:
                              getselectedProjectBackgroundColor(item.id?.toString()),
                            color: getSelectedProjectTextColor(item.id?.toString()),
                            textAlign: "left",
                            cursor: "pointer",
                            width: "100%",
                          }}
                        >
                          <Typography style={{ fontSize: '0.75rem', display: 'flex' }}>{item.name} <span style={{ marginLeft: '0.5rem', display: 'inline-block', width: '14rem' }}>[ {item.description} ]</span></Typography>
                        </Box>
                      </Box>
                      <div style={{ marginLeft: "2rem" }}>
                        {analyseProduct &&
                          analyseProduct
                            ?.filter((itemVal: any) => {
                              return itemVal?.project == item?.id;
                            })
                            ?.map((itemVal: any, indexVal: any) => {
                              return (
                                <>
                                  <Box
                                    sx={{
                                      alignItems: "center",
                                      display: "flex",
                                      width: "100%",
                                    }}
                                  >
                                    <Checkbox
                                      style={{ padding: '3px' }}
                                      checked={
                                        selectedProduct?.includes(
                                          itemVal?.id?.toString()
                                        )
                                          ? true
                                          : false
                                      }
                                      onClick={() =>
                                        selectAllProduct(itemVal?.id)
                                      }
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                      sx={{
                                        "& .MuiSvgIcon-root": {
                                          fontSize: 24,
                                          color: "#2b9cf6eb",
                                        },
                                      }}
                                    />
                                    <Box
                                      onClick={() =>
                                        selectAllProduct(itemVal?.id)
                                      }
                                      key={indexVal}
                                      className={styles.selectVehBtn}
                                      sx={{
                                        // margin: "0rem 2rem",
                                        padding: "0.2rem 0.5rem",
                                        backgroundColor:
                                          getselectedProductBackgroundColor(
                                            itemVal.id
                                          ),
                                        color: getSelectedProductTextColor(
                                          itemVal.id
                                        ),
                                        textAlign: "left",
                                        cursor: "pointer",
                                        display: "inline-block",
                                        width: "100%",
                                      }}
                                    >
                                      <Typography style={{ fontSize: '0.75rem' }}>{itemVal.name}</Typography>
                                    </Box>
                                  </Box>
                                </>
                              );
                            })}
                      </div>
                    </Box>
                  </>
                );
              })}
          </Box>
        </Box>
        <div style={{ paddingBottom: '0.5rem', position: 'sticky', bottom: '0', height: '4rem', backgroundColor: "aliceblue" }}>
          <Box sx={{ height: "1rem" }}>
            {selectedProject?.length < 1 && (
              <Typography style={{ margin: "0", marginLeft: "1.8rem", color: "red", fontSize: '0.75rem' }}>
                * Please select more than one project
              </Typography>
            )}
            {selectedProject?.length >= 1 && selectedProduct?.length < 1 && (
              <Typography style={{ margin: "0", marginLeft: "1.8rem", color: "red", fontSize: '0.75rem' }}>
                * Please select more than one product
              </Typography>
            )}
            {selectedProduct?.length > 7 && (
              <Typography style={{ margin: "0", marginLeft: "1.8rem", color: "red", fontSize: '0.75rem' }}>
                * Please select maximum seven products
              </Typography>
            )}
          </Box>
          <Box sx={{ textAlign: "right", paddingRight: '1rem' }}>
            <Button
              className={styles.drawBtn}
              sx={{ marginRight: "1rem", fontSize: '0.75rem' }}
              variant="contained"
              onClick={toggleDrawer(anchor, false)}
              size="small"
            >
              Close
            </Button>
            <LoadingButton
              style={
                disableOkBtn()
                  ? { backgroundColor: "aliceblue", cursor: "not-allowed" }
                  : {}
              }
              loading={okButtonLoader}
              size="small"
              disabled={disableOkBtn()}
              onClick={changeOkButton}
            >
              <Button
                style={
                  disableOkBtn()
                    ? { backgroundColor: "#ddd", cursor: "not-allowed", color: 'grey' }
                    : {}
                }
                variant="contained"
                sx={{ fontSize: '0.75rem' }}
                onClick={toggleDrawer(anchor, false)}
                size="small"
              >
                Ok
              </Button>
            </LoadingButton>
          </Box>
        </div>
      </Box>
    </Box>
  );

  const handleClose = () => {
    setAnchorElMenu(null);
  };

  const CustomizedWeightLabel = (props: any) => {
    const { x, y, value } = props;
    return (
      <text
        x={x}
        y={y}
        dy={-4}
        dx={50}
        fontSize="0.8rem"
        fontFamily="sans-serif"
        fill={"black"}
        textAnchor="middle"
      >
        {value} Kg
      </text>
    );
  };

  const CustomizedPartLabel = (props: any) => {
    const { x, y, value } = props;
    return (
      <text
        x={x}
        y={y}
        dy={-4}
        dx={50}
        fontSize="0.8rem"
        fontFamily="sans-serif"
        fill={"black"}
        textAnchor="middle"
        textRendering="center"
      >
        {value}
      </text>
    );
  };

  const renderColorfulLegendText = (value: string, entry: any) => {

    return <span style={{ color: "#000000" }}>{value}</span>
  };

  const valGet: any = analyseMaterial.length > 0 && analyseMaterial && analyseMaterial[0];
  const sortable: any = analyseMaterial.length > 0 && analyseMaterial && Object.fromEntries(
    // @ts-ignore
    Object.entries(valGet).sort(([, a], [, b]) => b - a)
  );

  const AnalysisMaterialName: any =
    analyseMaterial.length > 0 && Object.keys(sortable)?.length > 0 &&
    Object.keys(sortable)?.filter((item: any) => {
      return item != "name";
    });

  const valGetCategoryWisePart: any = analyseCategoryWisePart.length > 0 && analyseCategoryWisePart && analyseCategoryWisePart[0];
  const sortableCategoryWisePart: any = analyseCategoryWisePart.length > 0 && analyseCategoryWisePart && Object.fromEntries(
    // @ts-ignore
    Object.entries(valGetCategoryWisePart).sort(([, a], [, b]) => b - a)
  );

  const AnalysisCategoryWisePartName: any =
    analyseCategoryWisePart.length > 0 && Object.keys(sortableCategoryWisePart)?.length > 0 &&
    Object.keys(sortableCategoryWisePart)?.filter((item: any) => {
      return item != "name";
    });

  const CustomizedMaterialTooltip: any = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      let valueNonZero: any = payload && payload?.filter((item: any) => { return item?.value != 0 });
      let a: any = [];
      for (var i = 0; i <= Math.round(payload.length / 2); i++) {
        a.push(<>
          <TableRow>
            {(valueNonZero[2 * i]?.value != undefined && valueNonZero[2 * i + 1]?.value != 0) && <>
              <TableCell sx={{ padding: '0 0.5rem', borderBottomColor: 'primary.light' }}><Box sx={{ width: '0.75rem', height: '0.75rem', backgroundColor: valueNonZero[2 * i]?.color }}></Box></TableCell>
              <TableCell sx={{ padding: "0 0.6rem", color: 'black', fontSize: '0.75rem', borderBottomColor: 'primary.light' }}>{valueNonZero[2 * i]?.dataKey}</TableCell>
              <TableCell sx={{ textAlign: 'right', color: 'black', padding: "0 0.5rem", fontSize: '0.75rem', borderBottomColor: 'primary.light' }}>{((valueNonZero[2 * i]?.value) / 1000).toFixed(2)} Kg</TableCell>
            </>}
            {(valueNonZero[2 * i + 1]?.value != undefined && valueNonZero[2 * i + 1]?.value != 0) && <>
              <TableCell sx={{ padding: '0 0.5rem', borderBottomColor: 'primary.light' }}><Box sx={{ width: '0.75rem', height: '0.75rem', backgroundColor: valueNonZero[2 * i + 1]?.color }}></Box></TableCell>
              <TableCell sx={{ padding: "0 0.5rem", color: 'black', fontSize: '0.75rem', borderBottomColor: 'primary.light' }}>{valueNonZero[2 * i + 1]?.dataKey}</TableCell>
              <TableCell sx={{ textAlign: 'right', color: 'black', padding: "0 0.5rem", fontSize: '0.75rem', borderBottomColor: 'primary.light' }}>{((valueNonZero[2 * i + 1]?.value) / 1000).toFixed(2)} Kg</TableCell>
            </>}
          </TableRow>
        </>)
      }
      return (
        <div style={{ backgroundColor: 'white', borderRadius: '10px', overflow: 'hidden' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}><span style={{ fontSize: '0.75rem', fontWeight: 'bold' }}>{label}</span></div>
          <Table>
            {a}
          </Table>
        </div>
      );

    }
    return null;
  };

  const CustomizedCategoryWisePartCountTooltip: any = ({ active, payload, label }: any) => {
    if (active && payload && payload.length) {
      let valueNonZero: any = payload && payload?.filter((item: any) => { return item?.value != 0 });
      let a: any = [];
      for (var i = 0; i <= Math.round(payload.length / 2); i++) {
        a.push(<>
          <TableRow>
            {(valueNonZero[2 * i]?.value != undefined && valueNonZero[2 * i]?.value != 0) && <>
              <TableCell sx={{ padding: '0 0.5rem', borderBottomColor: 'primary.light' }}><Box sx={{ width: '0.75rem', height: '0.75rem', backgroundColor: valueNonZero[2 * i]?.color }}></Box></TableCell>
              <TableCell sx={{ padding: "0 0.6rem", color: 'black', fontSize: '0.75rem', borderBottomColor: 'primary.light' }}>{valueNonZero[2 * i]?.dataKey}</TableCell>
              <TableCell sx={{ textAlign: 'right', color: 'black', padding: "0 0.5rem", fontSize: '0.75rem', borderBottomColor: 'primary.light' }}>{(valueNonZero[2 * i]?.value)} Parts</TableCell>
            </>}
            {(valueNonZero[2 * i + 1]?.value != undefined && valueNonZero[2 * i + 1]?.value != 0) && <>
              <TableCell sx={{ padding: '0 0.5rem', borderBottomColor: 'primary.light' }}><Box sx={{ width: '0.75rem', height: '0.75rem', backgroundColor: valueNonZero[2 * i + 1]?.color }}></Box></TableCell>
              <TableCell sx={{ padding: "0 0.5rem", color: 'black', fontSize: '0.75rem', borderBottomColor: 'primary.light' }}>{valueNonZero[2 * i + 1]?.dataKey}</TableCell>
              <TableCell sx={{ textAlign: 'right', color: 'black', padding: "0 0.5rem", fontSize: '0.75rem', borderBottomColor: 'primary.light' }}>{(valueNonZero[2 * i + 1]?.value)} Parts</TableCell>
            </>}
          </TableRow>
        </>)
      }
      return (
        <div style={{ backgroundColor: 'white', borderRadius: '10px', overflow: 'hidden' }}>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}><span style={{ fontSize: '0.75rem', fontWeight: 'bold' }}>{label}</span></div>
          <Table>
            {a}
          </Table>
        </div>
      );

    }
    return null;
  };

  const handleModalOpen = () => {
    colorConfigurationModal.open();
  }
  const handleSelectChange = (categoryName: any) => {
    // @ts-ignore
    setSelectedCategory([...categoryName]);
  };

  const handleSelectMaterialChange = (materialName: any) => {
    // @ts-ignore
    setSelectedMaterial([...materialName]);
  };

  const [paretoWeightAnalysis, setParetoWeightAnalysis] = useState<any>();

  useEffect(() => {
    if (openMoreGraphPopUp == true) {
      setLoaderWeightAnalysis(false);
      const valueFiltered: any =
        redirectInnerRoute &&
        redirectInnerRoute?.top_vault_details?.filter((item: any) => {
          return item?.top_vault_name == redirectRoute;
        });
      if (type == "weight") {
        API.get(
          `customer/pareto_analysis/`,
          { product_ids: selectedTopVault, bom_weight: true },
          0
        )
          .then((res: any) => {
            setParetoWeightAnalysis(res?.data);
            setLoaderWeightAnalysis(true)
          })
          .catch((err: any) => {
            console.log(err, "error");
          });
      }
      else {
        API.get(
          `customer/pareto_analysis/`,
          { product_ids: selectedTopVault, bom_count: true },
          0
        )
          .then((res: any) => {
            setParetoWeightAnalysis(res?.data);
            setLoaderWeightAnalysis(true)
          })
          .catch((err: any) => {
            console.log(err, "error");
          });
      }
    }
  }, [openMoreGraphPopUp]);

  const handleMoreGraphPopUpClose: any = () => {
    setOpenMoreGraphPopUp(false);
    setTableOpenParetoAnalysis(false);
  };

  const handleTableOpen = () => {
    setTableOpenParetoAnalysis(!tableOpenParetoAnalysis);
  }

  const handleShowCategoryTable = () => {
    window.location.href = `#/analysis_bom_category/${projectId}/${topVault}/`;

  }

  const handleShowMaterialTable = () => {
    window.location.href = `#/analysis_bom_material/${projectId}/${topVault}/`;

  }

  let columns: any = [{
    field: "partNo",
    headerName: "Part No.",
    width: 180,
    flex: 1
  },
  {
    field: "partName",
    headerName: "Part Name",
    width: 320,
    flex: 1
  },
  {
    field: "valueRs",
    headerName: `Value (${paretoWeightAnalysis && paretoWeightAnalysis?.unit})`,
    width: 90,
    flex: 1
  },
  {
    field: "impact",
    headerName: "Impact %",
    width: 90,
    flex: 1,
  }];

  let rows: any = [];

  const dataGridDataGenerator: any = paretoWeightAnalysis && paretoWeightAnalysis?.pareto_data?.map((val: any, index: number) => {
    let objShared: any = {
      id: index + 1,
      partNo: val.part_no,
      partName: val?.part_name,
      valueRs: url?.includes("weight") ? (val?.param_value).toFixed(2) : (val?.param_value),
      impact: (val?.impact)?.toFixed(2)
    };
    rows.push(objShared);
  })


  let dataStructureDataGrid: any = {
    columns: columns,
    rows: rows,
  };

  useEffect(() => {
    setSelectedCategory([]);
    setSelectedMaterial([]);
  }, [topVault]);

  return (
    <>
      <Box>
        <Box sx={{ padding: '0 0.5rem', display: 'flex', alignItems: 'center', columnGap: '10px' }}>
          <IconButton sx={{ cursor: "pointer" }} onClick={backButtonHandler}>
            <ArrowBackIcon color="primary" titleAccess="Go Back" />
          </IconButton>
          <Box sx={{ padding: '0 0.5rem', display: 'flex', alignItems: 'center', columnGap: '5px', justifyContent: "space-between", width: "100%" }}>
            <Button variant="contained" size="small">
              Default Analysis
            </Button>
            <Button variant="contained" size="small" onClick={handleModalOpen}>Configure Color</Button>
            {/* <span style={{ padding: '0.5rem', marginLeft: '1.3rem', border: '0.05rem solid #0080ff38', borderRadius: '6px', color: 'white', backgroundColor: '#007fff', fontSize: '1rem' }}>Default Analysis</span> */}
            {/* <span style={{ padding: '0.5rem', marginLeft: '0.2rem', border: '0.05rem solid #0080ff38', borderRadius: '6px', fontSize: '1rem' }}>+ Add More Analysis</span> */}

            {/* <Button variant="outlined" disabled size="small" onClick={handleDialogClickOpen}>
              + Add More Analysis
            </Button> */}
            <Dialog
              open={openDialog}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleDialogClose}
              aria-describedby="alert-dialog-slide-description"
            >
              <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
                <Typography
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    width: "100%",
                    textAlign: "center",
                    marginRight: '-2rem'
                  }}
                >
                  Add More Analysis
                </Typography>
                <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={handleDialogClose} />
              </Box>
              <Divider
                sx={{
                  borderColor: "primary.light",
                  margin: "0 1rem",
                  marginBottom: "1rem",
                }}
              />
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  Default Analysis Content section  Default Analysis Content sectionDefault Analysis Content section
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                {/* <Button onClick={handleDialogClose}>Close</Button> */}
                <Button onClick={handleDialogClose}>Ok</Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Box>
        <Dialog
          open={openMoreGraphPopUp}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleMoreGraphPopUpClose}
          aria-describedby="alert-dialog-slide-description"
          sx={{
            ".MuiPaper-root.MuiDialog-paper": {
              minWidth: "80%",
            },
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem', justifyContent: "center" }}>
            {loaderWeightAnalysis ? <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: '-2rem',
                marginTop: '0.5rem'
              }}
            >
              {type == "weight" ? `${paretoWeightAnalysis && paretoWeightAnalysis?.subsystem_name} - Weight (${paretoWeightAnalysis && paretoWeightAnalysis?.unit}) Pareto Analysis` : `${paretoWeightAnalysis && paretoWeightAnalysis?.subsystem_name} - Part Count Pareto Analysis`}
            </Typography> : <Skeleton sx={{ width: "95%", height: "2.5rem", display: "flex", alignItems: "center", justifyContent: "center" }} />}
            {loaderWeightAnalysis ? <img src={TableIcon} style={{ cursor: 'pointer', marginRight: '0.5rem', width: '1.7rem', height: '1.8rem' }} onClick={handleTableOpen} /> : <Skeleton sx={{ width: "5%", height: "2.5rem", display: "flex", alignItems: "center", justifyContent: "center" }} />}
            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={handleMoreGraphPopUpClose} />
          </Box>
          <DialogContent>
            {!tableOpenParetoAnalysis ? <>
              {
                <div>
                  {loaderWeightAnalysis ? paretoWeightAnalysis && (
                    <Box sx={{ textAlign: 'right' }}>
                      <CostingAnalysisPareto fullscreen={true} dataIncoming={paretoWeightAnalysis} />
                    </Box>
                  ) : <Skeleton sx={{ width: "100rem", height: "52rem" }} />}
                </div>}
            </> :
              <div style={{ width: '100%', height: '77vh' }}>
                <DataGrid
                  headerHeight={48}
                  rowHeight={36}
                  {...dataStructureDataGrid}
                  disableSelectionOnClick={true}
                  components={{
                    Toolbar: () => {
                      return CustomToolbar(url?.includes('weight') ? "Weight Pareto Analysis" : "");
                    },
                  }}
                  getRowClassName={(params) => `super-app-theme--${params.row.stage}`}
                  sx={{
                    height: '100%',
                    fontSize: "1rem",
                    "&.MuiDataGrid-root.MuiDataGrid-autoHeight .MuiDataGrid-row--lastVisible .MuiDataGrid-cell":
                    {
                      borderBottomColor: "primary.light",
                    },
                    "&.MuiDataGrid-root .MuiDataGrid-cell": {
                      borderBottomColor: "primary.light",
                    },
                  }}
                  density={"compact"}
                  pageSize={28}
                  rowsPerPageOptions={[28]}
                />
              </div>}
          </DialogContent>
        </Dialog>
        <Box sx={{ height: '3rem', display: 'flex', alignItems: 'center', marginLeft: "4.4rem" }}>
          <Box sx={{ display: "flex", flexDirection: "row", columnGap: '10px' }}>{/* @ts-ignore */}
            {AllProjectdata && AllProjectdata?.map((item: any, index: any) => {
              return Object.keys(item).map((key: any, index: any) => {
                return (
                  <Box sx={{ display: 'flex', columnGap: '5px', alignItems: 'center', border: '1px solid', borderColor: 'primary.main', borderRadius: '1.5rem' }}>
                    {(filterDataIds && filterDataIds?.length <= 2 && projectLength && projectLength <= 2) || (projectLength && projectLength == 1 && filterDataIds?.length > 2) || (projectLength && projectLength == 2 && filterDataIds?.length < 5) ? " " :
                      <CancelIcon titleAccess="Delete" sx={{
                        "& :hover": {
                          transform: "scale(1.1)",
                          transition: "transform 0.5s ease",
                        },
                      }} onClick={() => deleteProject(key)} style={{ cursor: "pointer" }} />
                    } <Typography style={{ fontSize: '1rem', paddingLeft: '5px' }}>{key}</Typography>
                    <Box sx={{ display: 'flex', columnGap: '5px' }}>
                      {ChipContainer(key)}
                    </Box>
                  </Box>)
              })
            })}
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex" }}>
        <Box className={styles.expandIconBox}>
          <div className={styles.iconSec}>
            {(["left"] as const).map((anchor) => (
              <React.Fragment key={anchor}>
                <IconButton
                  size="medium"
                  sx={{
                    backgroundColor: "primary.light",
                    color: "primary.main",
                  }}
                  onClick={toggleDrawer(anchor, true)}
                >
                  <AddIcon sx={{ fontSize: "1.7rem" }} />
                </IconButton>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  className={styles.drawerContainer}
                  PaperProps={{
                    sx: {
                      backgroundColor: "aliceblue",
                    },
                  }}
                >
                  <Box sx={{ padding: "0.5rem 0rem", paddingBottom: '0' }} >
                    <Box sx={{ position: 'sticky', top: '0' }}>
                      <Typography style={{ width: "100%", textAlign: "center", fontSize: '1rem', lineHeight: '1.2' }}>
                        Select to Change Analysis <br />( Project / Products )
                      </Typography>
                      <Divider sx={{ margin: "0.5rem" }} />
                    </Box>
                    {list(anchor)}
                  </Box>
                </Drawer>
                <Typography
                  style={{
                    transform: "rotate(270deg)",
                    whiteSpace: "nowrap",
                    marginTop: "49vh",
                    cursor: "pointer",
                    fontSize: '1rem',
                  }}
                  onClick={toggleDrawer(anchor, true)}
                >
                  Select to Change Analysis ( Project / Products )
                </Typography>
              </React.Fragment>
            ))}
          </div>
        </Box>
        <Box sx={{ display: 'grid', gridTemplateColumns: { sm: '1fr', md: '1fr', lg: '1fr 1fr', xl: '1fr 1fr' }, width: '100%', height: '89vh' }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3">
          <Box>
            <>{!weightLoader ?
              <Box>
                <Box sx={{ width: "100%", height: '42vh' }}>
                  <Box>
                    <Typography
                      style={{
                        textAlign: "center",
                        width: "96%",
                        display: "inline-block",
                        fontSize: '1rem',
                        fontWeight: '600'
                      }}
                    >
                      Weight Comparison
                    </Typography>
                    {analyseWeight.length > 0 && <ZoomOutMapIcon
                      titleAccess="Maximize"
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        history.push(`/analysis/weight/${projectId}/${sortedTopVault}`)
                      }
                    />}
                  </Box>
                  {analyseWeight.length > 0 ? (
                    <>
                      <ResponsiveContainer>
                        <BarChart
                          data={analyseWeight}
                          className={styles.BarChartAnalysis}
                          margin={{
                            top: 20,
                            right: 20,
                            left: 20,
                            bottom: 40,
                          }}

                        >
                          <Bar
                            dataKey="weight"
                            barSize={100}
                            style={{ overflow: "hidden" }}
                            label={<CustomizedWeightLabel />}
                            onClick={(e: any) => valBarRedirect(e)}                                          >
                            {analysePartCount &&
                              analysePartCount?.map((entry: any) => (
                                <Cell fill={entry?.color} />
                              ))}
                          </Bar>
                          <XAxis dataKey="name"
                            // angle={-90}                        
                            textAnchor="middle" fontSize="0.75rem" stroke="#000000" interval={0}
                          >
                          </XAxis>
                          <YAxis stroke="#000000" textAnchor="end" fontSize="0.75rem" tickFormatter={(tick: any) => String((Number(tick)))}>
                            <Label
                              style={{
                                textAnchor: "middle",
                                fontSize: "0.75rem",
                                fontWeight: '500',
                                fill: '#000000'
                              }}
                              angle={270}
                              dx={-22}
                              value={"Weight ( Kg )"}
                            />
                          </YAxis>
                        </BarChart>
                      </ResponsiveContainer>
                      {x && y && closeRedirectPopup && <Box
                        sx={{
                          left: x + 66 + "px",
                          position: "fixed",
                          top: y + 200 + "px",
                          zIndex: 1000,
                          width: "fit-content",
                          background: '#ffffc9',
                          borderRadius: '5px',
                          padding: '0.5rem',
                          boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px',
                          "&:before": {
                            content: '""',
                            display: "block",
                            position: "absolute",
                            top: 0,
                            left: 8,
                            width: 20,
                            height: 18,
                            bgcolor: "#ffffc9",
                            transform: "translateY(-50%) rotate(45deg)",
                            zIndex: 0,
                          },
                        }}
                      >
                        <Box sx={{ display: 'flex', columnGap: '8px', alignItems: 'center' }}>

                          {<Button
                            color="primary"
                            size="small"
                            variant="contained"
                            onClick={valOpenMoreGraphPopUpDialog}
                          >
                            Pareto Analysis
                          </Button>}

                          <IconButton title="Close" onClick={() => { setCloseRedirectPopup(false) }}><CancelIcon titleAccess="Close" sx={{ fontSize: '1.5rem' }} /></IconButton>
                        </Box>
                      </Box>}
                    </>)

                    : <Box className={styles.analysisModuleNotFound}>
                      BOM Permission is not there.
                    </Box>
                  }
                </Box>
              </Box>
              :
              <div>
                <Skeleton animation="pulse" width="45vw" height="40vh" sx={{ marginLeft: '3rem' }} />
              </div>
            }
            </>
            <>
              {!partCountLoader ?
                <Box>
                  <Box sx={{ width: "100%", height: '42vh' }}>
                    <Box>
                      <Typography
                        style={{
                          textAlign: "center",
                          width: "96%",
                          display: "inline-block",
                          fontSize: '1rem',
                          fontWeight: '600'
                        }}
                      >
                        Part Count Comparison
                      </Typography>
                      {analysePartCount.length > 0 && <ZoomOutMapIcon
                        titleAccess="Maximize"
                        style={{ cursor: "pointer", position: "relative", zIndex: '3' }}
                        onClick={() => history.push(`/analysis/part/${projectId}/${sortedTopVault}`)}
                      />}
                    </Box>
                    {analysePartCount.length > 0 ? (
                      <>
                        <ResponsiveContainer>
                          <BarChart
                            margin={{
                              top: 20,
                              right: 20,
                              left: 20,
                              bottom: 40,
                            }}
                            data={analysePartCount}
                            className={styles.BarChartAnalysis}
                          >
                            <Bar
                              dataKey="qty"
                              barSize={100}
                              fill="rgb(144, 238, 144)"
                              style={{ overflow: "visible" }}
                              label={<CustomizedPartLabel />}
                              onClick={(e: any) => valBarRedirect(e)}
                            >
                              {analysePartCount &&
                                analysePartCount?.map((entry: any) => (
                                  <Cell fill={entry?.color} />
                                ))}
                            </Bar>
                            <XAxis dataKey="name"
                              // angle={-90} 
                              textAnchor="middle" fontSize="0.75rem" stroke="#000000" interval={0} />
                            <YAxis fontSize="0.75rem" stroke="#000000">
                              <Label
                                style={{
                                  textAnchor: "middle",
                                  fontSize: "0.75rem",
                                  fontWeight: '500',
                                  fill: '#000000'
                                }}
                                angle={270}
                                dx={-22}
                                value={"Part ( Qty )"}
                              />
                            </YAxis>
                          </BarChart>
                        </ResponsiveContainer>
                        {x && y && closeRedirectPopupPart && <Box
                          sx={{
                            left: x + 66 + "px",
                            position: "fixed",
                            top: y + 600+ "px",
                            zIndex: 1000,
                            width: "fit-content",
                            background: '#ffffc9',
                            borderRadius: '5px',
                            padding: '0.5rem',
                            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px',
                            "&:before": {
                              content: '""',
                              display: "block",
                              position: "absolute",
                              top: 0,
                              left: 8,
                              width: 20,
                              height: 18,
                              bgcolor: "#ffffc9",
                              transform: "translateY(-50%) rotate(45deg)",
                              zIndex: 0,
                            },
                          }}
                        >
                          <Box sx={{ display: 'flex', columnGap: '8px', alignItems: 'center' }}>

                            {<Button
                              color="primary"
                              size="small"
                              variant="contained"
                              onClick={valOpenMoreGraphPopUpDialog}
                            >
                              Pareto Analysis
                            </Button>}

                            <IconButton title="Close" onClick={() => { setCloseRedirectPopupPart(false) }}><CancelIcon titleAccess="Close" sx={{ fontSize: '1.5rem' }} /></IconButton>
                          </Box>
                        </Box>}
                      </>
                    )
                      : <Box className={styles.analysisModuleNotFound}>
                        BOM Permission is not there.
                      </Box>
                    }
                  </Box>
                </Box>
                :
                <div>
                  <Skeleton animation="pulse" width="45vw" height="40vh" sx={{ marginLeft: '3rem' }} />
                </div>
              }</>
          </Box>
          <Box>
            <>
              {!categoryWisePartLoader ? <>
                {analyseCategoryWisePart.length > 0 && analyseCategoryWisePart?.length > 0 && (
                  <Box sx={{ width: "100%", height: '40vh', marginBottom: '2rem' }}>
                    <Box sx={{ display: "flex", alignItems: 'center', columnGap: '0.2rem' }}>
                      <Typography
                        style={{
                          textAlign: "center",
                          width: "60%",
                          display: "inline-block",
                          fontSize: '1rem',
                          fontWeight: '600'
                        }}
                      >
                        Category Wise Part Count Distribution Comparison
                      </Typography>
                      {allCategory && <Box sx={{ display: "flex", justifyContent: "space-around", alignItems: "center", columnGap: "0.2rem" }}><Autocomplete
                        multiple
                        disableClearable
                        id='Category'
                        value={selectedCategory}
                        limitTags={1}
                        disableCloseOnSelect
                        placeholder="Filter Category"
                        onChange={(event, newValue) => {
                          handleSelectChange(newValue);
                        }}
                        sx={{ marginBottom: '0.5rem', width: '60%' }}
                        options={allCategory && allCategory?.length > 0 ? allCategory : []}
                        // @ts-ignore
                        getOptionLabel={(option) => option}
                        renderOption={(props, option) => (
                          <li {...props} style={{ fontSize: '1rem' }}>
                            {option}
                          </li>
                        )}
                        renderTags={(tagValue, getTagProps) =>
                          tagValue.map((option, index) => (
                            <Chip
                              size='small'
                              //  @ts-ignore
                              sx={{
                                backgroundColor: '#007fff14',
                                color: 'primary.main',
                                fontSize: '1rem',
                              }}
                              // @ts-ignore
                              label={option}
                              {...getTagProps({ index })}
                            />
                          ))
                        }
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label="Filter Category"
                            variant='standard'
                            sx={{
                              width: '12rem',
                              '& .MuiInput-underline:before': {
                                borderBottomColor: 'primary.light',
                              },
                              '& .MuiInput-underline:after': {
                                borderBottomColor: 'primary.main',
                              },
                              '.MuiSvgIcon-root': { color: 'primary.main' },
                            }}
                          />
                        )}
                      />
                        <Button
                          variant="contained"
                          size="small"
                          sx={{ margin: '0 0.5rem' }}
                          disabled={selectedCategory.length == 0}
                          onClick={() => handleApplyFilterCategory()}
                        >
                          Apply
                        </Button>
                        {analyseCategoryWisePart.length > 0 && analyseCategoryWisePart?.length > 0 && <ZoomOutMapIcon titleAccess="Maximize"
                          onClick={() => { return history.push(`/analysis/category/${projectId}/${sortedTopVault}`) }}
                          style={{ cursor: "pointer", position: "relative", zIndex: '1' }}
                        />}
                      </Box>}
                      <TableChartIcon onClick={handleShowCategoryTable} sx={{ cursor: "pointer" }} />

                    </Box>
                    {analyseCategoryWisePart.length > 0 && analyseCategoryWisePart?.length > 0 ? (<ResponsiveContainer>
                      <BarChart
                        layout="vertical"
                        // // width={880}
                        // height={155}
                        data={analyseCategoryWisePart}
                        // stackOffset="expand"
                        barGap={30}
                        barCategoryGap={30}
                        margin={{
                          top: 5,
                          right: 50,
                          left: 20,
                          bottom: 50,
                        }}
                      >
                        <Legend
                          layout="vertical"
                          adl-scrollbar="true"
                          adl-scrollbar-width="0.3"
                          verticalAlign="top"
                          align="right"
                          stroke="#000000"
                          wrapperStyle={{ height: "30vh", overflowY: 'scroll', right: "10px", fontSize: '0.75rem', color: 'black' }}
                          formatter={renderColorfulLegendText}
                        />
                        <XAxis
                          type="number"
                          tickFormatter={(tick) => `${(tick)}`}
                          fontSize="0.75rem"
                          stroke="#000000"
                          interval={0}
                        >
                          <Label value="Parts (Qty)" offset={0} fontSize="0.75rem" fontWeight='500' position="insideBottom" fill="#000000" />
                        </XAxis>
                        <YAxis type="category" dataKey="name" interval={0} dx={-5}
                          width={180} fontSize="0.75rem" stroke="#000000" />
                        <Tooltip cursor={false} content={<CustomizedCategoryWisePartCountTooltip />} />
                        {AnalysisCategoryWisePartName?.length > 0 &&
                          AnalysisCategoryWisePartName?.map((item: any, index: number) => {
                            return (
                              <Bar dataKey={item} stackId="a" barSize={80} fill={color[index]} />
                            );
                          })}
                      </BarChart>
                    </ResponsiveContainer>)
                      : <Box className={styles.analysisModuleNotFound}>
                        BOM Permission is not there
                      </Box>}
                  </Box>
                )} </> :
                <div>
                  <Skeleton animation="pulse" width="35vw" height="40vh" sx={{ marginLeft: '10rem' }} />
                </div>
              }</>

            <> {!materialLoader ? <>
              {analyseMaterial.length > 0 && AnalysisMaterialName?.length > 0 && (


                <Box sx={{ width: "100%", height: '40vh' }}>
                  <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '0.2rem' }}>
                    <Typography
                      style={{
                        textAlign: "center",
                        width: "60%",
                        display: "inline-block",
                        fontSize: '1rem',
                        fontWeight: '600'
                      }}
                    >
                      Material Distribution Comparison
                    </Typography>
                    {allMaterial && <Box sx={{ display: "flex", justifyContent: "space-around", alignItems: "center", gap: "0.2rem" }}><Autocomplete
                      multiple
                      disableClearable
                      id='Material'
                      value={selectedMaterial}
                      limitTags={1}
                      disableCloseOnSelect
                      onChange={(event, newValue) => {
                        handleSelectMaterialChange(newValue);
                      }}
                      placeholder="Filter Material"
                      sx={{ marginBottom: '0.5rem', width: '60%' }}
                      options={allMaterial && allMaterial?.length > 0 ? allMaterial : []}
                      // @ts-ignore
                      getOptionLabel={(option) => option}
                      renderOption={(props, option) => (
                        <li {...props} style={{ fontSize: '1rem' }}>
                          {option}
                        </li>
                      )}
                      renderTags={(tagValue, getTagProps) =>
                        tagValue.map((option, index) => (
                          <Chip
                            size='small'
                            //  @ts-ignore
                            sx={{
                              backgroundColor: '#007fff14',
                              color: 'primary.main',
                              fontSize: '1rem',
                            }}
                            // @ts-ignore
                            label={option}
                            {...getTagProps({ index })}
                          />
                        ))
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Filter Material"
                          variant='standard'
                          sx={{
                            width: '12rem',
                            '& .MuiInput-underline:before': {
                              borderBottomColor: 'primary.light',
                            },
                            '& .MuiInput-underline:after': {
                              borderBottomColor: 'primary.main',
                            },
                            '.MuiSvgIcon-root': { color: 'primary.main' },
                          }}
                        />
                      )}
                    />
                      <Button
                        variant="contained"
                        size="small"
                        sx={{ margin: '0 0.5rem' }}
                        disabled={selectedMaterial.length == 0}
                        onClick={() => handleApplyFilterMaterial()}
                      >
                        Apply
                      </Button>
                      {analyseMaterial.length > 0 && AnalysisMaterialName?.length > 0 && <ZoomOutMapIcon titleAccess="Maximize"
                        onClick={() => { return history.push(`/analysis/material/${projectId}/${sortedTopVault}`) }}
                        style={{ cursor: "pointer", position: "relative", zIndex: '1' }}
                      />}

                    </Box>}
                    <TableChartIcon onClick={handleShowMaterialTable} sx={{ cursor: "pointer" }} />
                  </Box>
                  {analyseMaterial.length > 0 && AnalysisMaterialName?.length > 0 ? (<ResponsiveContainer>
                    <BarChart
                      layout="vertical"
                      // width={880}
                      // height={355}
                      data={analyseMaterial}
                      // stackOffset="expand"
                      margin={{
                        top: 20,
                        right: width > 1400 ? 115 : 99,
                        left: 20,
                        bottom: 50,
                      }}
                    >
                      <Legend
                        layout="vertical"
                        verticalAlign="top"
                        align="right"
                        stroke="#000000"
                        wrapperStyle={{ right: width > 1400 ? "75px" : '65px', fontSize: '0.75rem', color: 'black' }}
                        formatter={renderColorfulLegendText}
                      />
                      <XAxis
                        type="number"
                        tickFormatter={(tick) => `${(tick / 1000)}`}
                        fontSize="0.75rem"
                        stroke="#000000"
                        interval={0}
                      >
                        <Label value="Material (Kg)" offset={0} fontSize="0.75rem" fontWeight='500' position="insideBottom" fill="#000000" />
                      </XAxis>
                      <YAxis type="category" dataKey="name" interval={0} dx={-5}
                        width={180} fontSize="0.75rem" stroke="#000000" />
                      <Tooltip cursor={false} content={<CustomizedMaterialTooltip />} />
                      {AnalysisMaterialName?.length > 0 &&
                        AnalysisMaterialName?.map((item: any, index: number) => {
                          return (
                            <Bar dataKey={item} stackId="a" barSize={80} fill={color[index]} />
                          );
                        })}
                    </BarChart>
                  </ResponsiveContainer>)
                    : <Box className={styles.analysisModuleNotFound}>
                      BOM Permission is not there
                    </Box>}
                </Box>
              )} </> :
              <div>
                <Skeleton animation="pulse" width="35vw" height="40vh" sx={{ marginLeft: '10rem' }} />
              </div>
            }</>
          </Box>
        </Box>
      </Box>
      <CurrencyChange
        isOpen={currencyModal.isOpen}
        onCloseModal={currencyModal.close}
        setCount={setCount}
      />
      {
        colorConfigurationModal.isOpen && (
          <ConfigureColorModal isOpen={colorConfigurationModal.isOpen} onClose={colorConfigurationModal.close} AllProjectdata={AllProjectdata} topVaultColor={topVaultsColor} setCounter={setCounter} />
        )
      }
    </>
  );
};
export default AnalysisLandingPage;
