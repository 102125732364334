import React from 'react';
import { API } from '../../api-services';





class inward {
   
    getProject= ( ) => {
       return API.get("/vehicle_inward/inward_view", {project_list:true});
    };
 
  
 
  
 }
 
 const Inward = new inward();
 
 export { Inward };