import React, { useState } from "react";
import LoginActivityCount from "./LoginActivityCount/LoginActivityCount";
import TimeSpent from "./TimeSpent/TimeSpent";
import ModuleWiseActivityCount from "./ModuleWiseActivityCount/ModuleWiseActivityCount";
import PieChartActivityAnalysis from "./PieChartActivityAnalysis";
import ParetoGraphActivityAnalysis from "./ParetoGraphActivityAnalysis";
import { Box, FormControlLabel, IconButton, MenuItem, Select, SelectChangeEvent, Switch } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import FormControl from '@mui/material/FormControl';
import { useHistory } from "react-router-dom";
import { borders } from '@mui/system';

interface LogsAnalysisPageI { }

const LogsAnalysisPage: React.FC<LogsAnalysisPageI> = (props) => {
  const [usertypeChange, setUsertypeChange] = useState<any>("Internal");
  const history = useHistory();
  const handleChangeUserTypeType = (event: SelectChangeEvent) => {
    setUsertypeChange(event.target.value as string);
  };

  const backButtonHandler: any = () => {
    history.goBack();
  };

  return (<>
    <Box sx={{ margin: '0 1rem',height:{sm:'95vh',md:'92vh',lg:'93vh',xl:'95vh'} }}
     adl-scrollbar="true"
     adl-scrollbar-width="0.3">
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            sx={{
              cursor: "pointer", width: '3rem',
              height: '3rem'
            }}
            onClick={() => backButtonHandler()}
          >
            <ArrowBackIcon color="primary" titleAccess="Go Back" />
          </IconButton>
          <h2 style={{ fontSize: "1rem" }}>Activity Logs Analysis</h2>
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '20px' }}>
          <FormControl variant="standard" sx={{ m: 1, minWidth: 75 }}>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={usertypeChange}
              onChange={handleChangeUserTypeType}
              sx={{ fontSize: "1rem" }}
            >
              <MenuItem sx={{ fontSize: '1rem' }} value={"Internal"}>Internal</MenuItem>
              <MenuItem sx={{ fontSize: '1rem' }} value={"External"}>External</MenuItem>
            </Select>
          </FormControl>
          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "end", marginRight: "3.5em" }}><span style={{ fontSize: '1rem', fontWeight: '500' }}>All Data</span></div>
        </Box>
      </Box>
      <div>
        <Box sx={{ display: 'grid', gridTemplateColumns:{sm:"1fr", md:"1fr",lg:"1fr 1fr",xl:"1fr 1fr"},height:{lg:'39vh',xl:'33vh'} }}>
          <Box>
            <PieChartActivityAnalysis />
          </Box>
          <Box>
            <ParetoGraphActivityAnalysis usertypeChange={usertypeChange} />
          </Box>
        </Box>
      </div>
      <Box sx={{ display: 'grid', gridTemplateColumns: {sm:"1fr", md:"1fr",lg:"1fr 1fr",xl:"1fr 1fr"}, columnGap: '10px' ,rowGap:'10px',padding: "5px"}}
       adl-scrollbar="true"
       adl-scrollbar-width="0.3">
        <Box sx={{ height: {lg:"45vh",xl:'55.5vh'},borderRadius:'7px', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px' }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3">
          <div style={{ marginBottom: '1rem' }}>
              <LoginActivityCount />
          </div>
          <div >
              <TimeSpent />
          </div>
        </Box>
        <Box sx={{ height: {lg:"45vh",xl:'55.5vh'},borderRadius:'7px', boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px' }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3">
          <ModuleWiseActivityCount />
        </Box>
      </Box>
    </Box>
  </>)
}

export default LogsAnalysisPage;