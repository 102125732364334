import {
    createEntityAdapter,
    createReducer,
    createSelector,
    EntityState,
} from "@reduxjs/toolkit";

import {
    getMappedParameters,
    getParameters,
    getParametersByTableId,
    resetParameters,
} from "../Actions/parameter.actions";
import { ID } from "../../utlis/commonInterfaces";
import { RootState } from "../Store/storeConfigurations";


// -----------------------------------------------------------------------------------
// entity adaptor

export interface Parameter {
    id: ID;
    name: string;
    unit: string;
    data_type: string;
    exceptions: string[];
    allowed_values: string[];
    not_allowed_values: string[];
    default_value: string;
    parameter_info: ID | null;
    table_id: ID | null;
}

const parameterAdaptor = createEntityAdapter<Parameter>( {
    selectId: parameter => parameter.id,
    sortComparer: ( a, b ) => a.name.localeCompare( b.name ),
} );

// -----------------------------------------------------------------------------------
// interfaces & initialState

type AdditionalFields = {};

type IParameterReducer = EntityState<Parameter> & AdditionalFields;

const additionalFields: AdditionalFields = {};

const initialState: IParameterReducer = Object.assign(
    {},
    parameterAdaptor.getInitialState(),
    additionalFields
);

// -----------------------------------------------------------------------------------
// reducer

const ParameterReducer = createReducer( initialState, builder => {
    // get
    builder
        .addCase( getParameters.fulfilled, ( state, action: any ) => {
            parameterAdaptor.setAll( state, action.payload.parameters );
        } )
        .addCase( getParameters.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError(
            //     action.payload,
            //     "Could not fetch parameters"
            // );
        } );

    // get bom parameters for report
    builder
        .addCase( getMappedParameters.fulfilled, ( state, action: any ) => {
            parameterAdaptor.setAll( state, action.payload.parameters );
        } )
        .addCase( getMappedParameters.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError(
            //     action.payload,
            //     "Could not fetch parameters"
            // );
        } );

    // get table column parameters
    builder
        .addCase( getParametersByTableId.fulfilled, ( state, action: any ) => {
            parameterAdaptor.setAll( state, action.payload.parameters );
        } )
        .addCase( getParametersByTableId.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could not fetch parameters" );
        } );

    // reset
    builder.addCase( resetParameters, () => initialState );
} );

// -----------------------------------------------------------------------------------
// selectors

const selectors = parameterAdaptor.getSelectors<RootState>(
    state => state.parameters
);

const selectByIds = createSelector(
    selectors.selectAll,
    ( _state: RootState, ids: ID[] ) => ids,
    ( parameters, ids ) => {
        return parameters.filter( parameter => ids.includes( parameter.id ) );
    }
);

const selectImagesNVideos = createSelector( selectors.selectAll, parameters => {
    return parameters.filter( parameter =>
        ["image", "video"].includes( parameter.data_type )
    );
} );

const parameterSelectors = Object.assign(
    {},
    { selectByIds, selectImagesNVideos },
    selectors
);

// -----------------------------------------------------------------------------------
// exports

export {
    ParameterReducer,
    initialState as ParameterReducerInit,
    parameterSelectors,
};
