import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import parameterService from "../Services/parameter.service";
import reportService from "../Services/report.service";
import { REPORT_TYPES } from "../../utlis/report.constants";
import { Parameter } from "../Reducers/parameter.reducer";
import { ID } from "../../utlis/commonInterfaces";


// -------------------------------------------------------------------------------------
// helpers
// -------------------------------------------------------------------------------------

const isBom = ( reportType: number ) => {
    return [REPORT_TYPES.CONSOLIDATED_BOM, REPORT_TYPES.INPUT_BOM].includes(
        reportType
    );
};

// -------------------------------------------------------------------------------------
// api actions
// -------------------------------------------------------------------------------------

export interface GetParametersArg { }
const getParameters = createAsyncThunk(
    "parameter/get",
    async ( arg: GetParametersArg, { rejectWithValue } ) => {
        try {
            const { data } = await parameterService.get();

            return {
                parameters: data as Parameter[],
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

// -------------------------------------------------------------------------------------

export interface GetMappedParametersArg {
    projectId: ID;
    module?:any;
    reportType: number;
}
/**
 * for fetching intersection of parameters that are mapped in template configuration
 */
const getMappedParameters = createAsyncThunk(
    "parameter/bomOrFeature",
    async ( arg: GetMappedParametersArg, { rejectWithValue } ) => {
        try {
            let res;
            if ( isBom( arg.reportType ) ) {
                res = await reportService.getBomParameters( arg );
            } else {
                res = await reportService.getFeatureParameters( arg );
            }

            return {
                parameters: res.data as Parameter[],
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

// -------------------------------------------------------------------------------------

export interface GetParametersByTableIdArg {
    tableId: ID;
}
const getParametersByTableId = createAsyncThunk(
    "parameter/getByTableId",
    async ( arg: GetParametersByTableIdArg, { rejectWithValue } ) => {
        try {
            const { data } = await parameterService.getByTableId( arg.tableId );

            return {
                parameters: data as Parameter[],
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

// -------------------------------------------------------------------------------------
// reducer actions
// -------------------------------------------------------------------------------------

const resetParameters = createAction( "parameter/reset" );

// -------------------------------------------------------------------------------------
// exports
// -------------------------------------------------------------------------------------

export { getParameters, getMappedParameters, getParametersByTableId, resetParameters };
