// vendors
import { createEntityAdapter, createReducer, createSelector, EntityState } from "@reduxjs/toolkit";
import { ID } from "../../utlis/commonInterfaces";

// interfaces
// import { getDataImage } from "../Actions/CardView.action";
import { getRepoImagesHeaderData } from "../Actions/imageRepository.actions";
import { RootState } from "../Store/storeConfigurations";
import { clearArchRepoImages, getArchRepoImagesHeaderData } from "../Actions/archRepository.action";

// redux

// -----------------------------------------------------------------------------------
// entity adaptor

export interface ImageRepositoryProductInfo {
    top_vault: ID;
    vault: ID | null;
    product_name: string | null;
    component_name: string | null;
    part_no: string | number;
    count: number;
    allowed_action: any;
    vault_status:boolean;
}


const collator = new Intl.Collator( undefined, { numeric: true, sensitivity: "base" } );


const adaptor = createEntityAdapter<ImageRepositoryProductInfo>( {
    selectId: ( imageRepositoryProductInfo ) => imageRepositoryProductInfo.top_vault,
} );


// -----------------------------------------------------------------------------------
// interfaces & initialState

type AdditionalFields = {};

type IImageRepositoryProductInfoReducer = EntityState<ImageRepositoryProductInfo> & AdditionalFields;

const additionalFields: AdditionalFields = {};

const initialState: IImageRepositoryProductInfoReducer = Object.assign( {}, adaptor.getInitialState(), additionalFields );

// -----------------------------------------------------------------------------------
// reducer

const ImageRepositoryProductInfoReducer = createReducer( initialState, builder => {
    builder
        .addCase( getRepoImagesHeaderData.fulfilled, ( state, action: any ) => {
            adaptor.setMany( state, action.payload );
        } );
    builder
        .addCase( getArchRepoImagesHeaderData.fulfilled, ( state, action: any ) => {
            adaptor.setMany( state, action.payload );
        } );
    builder
        .addCase( clearArchRepoImages, () => initialState );

} );

// -----------------------------------------------------------------------------------
// selectors

const selectors = adaptor.getSelectors<RootState>( ( state ) => state.imageRepositoryProductInfos );

const imageRepositoryProductInfoSelectors = Object.assign( {}, {}, selectors );


// -----------------------------------------------------------------------------------
// exports

export {
    ImageRepositoryProductInfoReducer,
    initialState as ImageRepositoryProductInfoReducerInit,
    imageRepositoryProductInfoSelectors
};
