import { SET_USERDIRECTORY_FILTER } from "../Actions/PermissionRoute.action";

  
  const initialState = {
    userDirectory_filter:["Internal", "External", "active", "inactive"]
  };
  
  export default (state: any = initialState, action: any) => {
    switch (action.type) {
      case SET_USERDIRECTORY_FILTER:
        // console.log("setUserDirectoryFilter",action.payload);
        return Object.assign({}, state, {
          userDirectory_filter: action.payload,
        });

  
      default:
        return state;
    }
  };
  