import * as React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { Box, ButtonProps, Divider, Modal, Skeleton, Typography } from "@mui/material";
import { API } from "../../../api-services";
import { DataGrid, GridCsvExportMenuItem, GridCsvExportOptions, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExportContainer } from "@mui/x-data-grid";
import CloseIcon from '@mui/icons-material/Close';
import swal from "sweetalert";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { useHistory } from "react-router-dom";
import { userPermissionAction } from "./BOMEntryLandingPage";

export interface BOMFlagModalProps {
  onCloseModal: any;
  isOpen: any;
  getData:any;
}
const csvOptions: GridCsvExportOptions = { fileName:`Flag  Table`, delimiter: ",", utf8WithBom: true };

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer style={{ height: '1.5rem', marginBottom: '0.5rem' }}>
    <span style={{ position: 'absolute', right: '0rem' }}>
      <GridToolbarColumnsButton />
      {/* <GridToolbarDensitySelector /> */}
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
)
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width:"64vw",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

export default function BOMFlagModal(
  props: BOMFlagModalProps
) {
  const { onCloseModal, isOpen,getData } = props;
  const {
    projectId,
    projectName,
    topVault,
    vault,
    treeabbr,
    workstation,
    isLeaf,
    copyStatus
  } = useRouteParams<any>();

  const [FlagData,setFlagData]=React.useState<any>({})
  const [Loader,setLoader]=React.useState<any>(false)
  const history=useHistory<any>()
  const userAction=React.useContext(userPermissionAction)
  let ActionUser=userAction && userAction?.action
  React.useEffect(()=>{
    setLoader(true)
    API.get(`/xcpep/bom_hirearchy_function/`,{cost_flag:true,

      vault:getData?.id}).then((res:any)=>{
        setLoader(false)
        setFlagData(res?.data)
      }).catch((err:any)=>{
        setLoader(false)
        console.log("Server Error")
      })
  },[getData])
  const TableRow = () => {
    var valuedata =
      FlagData?.length>0 &&
      FlagData.map((item: any, id: any) => ({
        ...item,
        sno: id + 1,
      }));

    return valuedata;
  };

  let columns: any = [
    { field: "sno", headerName: "SNo.", width: 50, },
    { field: "part_no", headerName: "Part No.", minWidth: 300 , flex: 1,
    renderCell:(item:any)=>{
      return (<>
     <span style={{borderBottom:"1px solid",cursor:"pointer"}} onClick={()=>partrounting(item?.row)}>{item?.row?.part_no}</span>
      
      </>)

    }

  
  },
    { field: "parameter_name", headerName: "Parameter Name", minWidth: 300 , flex: 1 },
    { field: "user_name", headerName: "Updated By", minWidth: 300 , flex: 1 },
   
    {
      field: "message",
      headerName: "Comments",
      minWidth: 110 ,
      flex: 1,
    },

    {  
     
      headerName: "Action",
      minWidth: 110 ,
      flex: 1,
      renderCell:(item:any)=>{
        return (<>
        {
          (ActionUser && ActionUser?.includes("D")) &&
        
        <CloseIcon titleAccess="Remove" sx={{color:"red",cursor:"pointer"}} onClick={()=>RemoveHandler(item?.row)}/>
      }
        
        </>)

      }
    },
    
  ];

  const partrounting = (data: any) => {
    console.log(data,"datadatadata")
         sessionStorage.setItem("BOMCreateNode", JSON.stringify(data?.ansectors));
    history.push(
      `/bomentry/${projectId}/${projectName}/${topVault}/${data?.id}/${treeabbr}/${workstation}/${isLeaf}/${copyStatus}`
    );
    onCloseModal();

  };
  const dataRender: any = {
    columns: columns ,
    rows: TableRow(),
  };
  const RemoveHandler=(data:any)=>{


    swal({
      title: "Clear Flag?",
      text: "Are you sure you want to remove this Flag?",
      // icon: "warning",
      buttons: ["No", "Yes"],
    }).then((isData: any) => {
      if (isData) {
        API.put(`/xcpep/cost_bom_data/`, { parameter_id:data?.parameter_id,vault:data?.id}).then((res: any) => {
        
          setLoader(true)
          API.get(`/xcpep/bom_hirearchy_function/`,{cost_flag:true,
      
            vault:getData?.id}).then((res:any)=>{
              setLoader(false)
              setFlagData(res?.data)
            }).catch((err:any)=>{
              setLoader(false)
              console.log("Server Error")
            })
         });
      }
    }).catch((e: any) => {
    });
  }

  
  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
             Comment from costing engineer
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          { Loader?
            <Box sx={{ padding: "0 1rem 1rem" }}>



              <Skeleton/>
              <Skeleton/>
              <Skeleton/>
              <Skeleton/>
              <Skeleton/>
            </Box>:
          
          <Box sx={{ height: '65vh', width: '100%' }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3">
           
{ FlagData &&
               <DataGrid
              {...dataRender}
                headerHeight={48}
                rowHeight={36}
               
               
                density="compact"
                // autoHeight={true}
                components={{ Toolbar: () => { return CustomToolbar(); } }}
                hideFooter={true}
                hideFooterPagination={true}
                sx={{
                  "&.MuiDataGrid-root .MuiDataGrid-cell": {
                    borderBottomColor: "primary.light",
                  },
                  "& ::-webkit-scrollbar": {
                    width: "0.3rem",
                    height: "0.3rem",
                  },
                  "& ::-webkit-scrollbar-thumb": {
                    // background: $thumb-color;
                    backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                    borderRadius: "10px",
                  },
                  "& ::- webkit-scrollbar-thumb: hover ": {
                    // width: "4px",
                    backgroundColor: "#045DE9 !important",
                  },
                  "::-webkit-scrollbar-track": {
                    backgroundColor: "#e1e1f3 !important",
                  },                  
                }}
              />
            }
          </Box>
}
        </Box>
      </Modal>
    </div>
  );
}
