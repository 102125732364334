import * as React from 'react';
import { Checkbox } from '@mui/material';
import Swal from 'sweetalert2';
import { userPermissionAction } from '../../../BOMCreate/BOMEntry/BOMEntryLandingPage';
import { API } from '../../../../api-services';
import { ADMIN } from '../../../../Redux/Services/admin.service';
import { useRouteParams } from '../../../../ui-reusable-component/useRouteParams';




export interface IIdeaCheckBoxComponentProps {
    item: any;
    getIdeaParametersData: any;
    // BOMValidationStatus?: any;
    check: any;
}

export default function IdeaCheckBoxComponent (props: IIdeaCheckBoxComponentProps) {
    const { item, getIdeaParametersData, check } = props;
  const { vault, topVault, copyStatus,ideaId } = useRouteParams<any>();
  const [value, setValue] = React.useState<any>({});
  const [isCheck, setIsCheck] = React.useState<any>()
  const userAction = React.useContext(userPermissionAction)
  let UserAction = userAction && userAction?.action

  React.useEffect(() => {
    setIsCheck(check)
  }, [check])


  const getobj = (v: any, tv: any) => {
    if (v == 0) {
      return { top_vault: tv }
    } else {
      return { vault: v }
    }
  }

  const getChecked = (itemm: any) => {

    if (item?.exceptions?.includes(item?.value)) {
      return true
    } else {
      return false
    }
  }

  const updateDataVaultCheckbox = async (name: any, valuee: any, vlt: any, tv: any) => {
    API.put(`/idea/pool/${ideaId}/`, {
      ...{parameter_json:{[name]: valuee}},
    }, {
      ...getobj(vlt, tv),
      module:15
    }, 0).then((res: any) => {
      // window.location.reload();
      getIdeaParametersData();
      // BOMValidationStatus()
      ADMIN.toast.info("Updated Successfully")
    }).catch((err: any) => {
      const { data } = err.response;
      Swal.fire({
        title: "Error!",
        text: data[0],
        icon: "error",
        confirmButtonText: "Ok",
      });
    })
  }


  return (
    <Checkbox  checked={getChecked(item)}
    disabled={!item?.editable}
    // disabled={((copyStatus == false || copyStatus == "false") && (UserAction?.includes("U") || UserAction?.includes("C"))) ? false : true}
     sx={{
      color: 'primary.main',
      padding: '3px',
      [`&.Mui-checked`]: {
        color: 'green',
      },
    }}
      onClick={() => {
        if (getChecked(item)) {
          updateDataVaultCheckbox(item?.key, "", vault, topVault)


        } else {
          updateDataVaultCheckbox(item?.key, item?.exceptions[0], vault, topVault)
        }
      }
      }

    />
  );
}
