// react
import React from 'react';
import { Table, TableCell, TableRow } from "@mui/material";

// vendors
// services
// utils
// constants
// factories
// core
// redux
// ui-components
// others
// styles


// interfaces and types
// lazy

// ----------------------------------------------------------------------------------

interface MomMailRowProps {
    data: any;
    index: any;
    getData: any;
}

const MomMailRow: React.FC<MomMailRowProps> = (props) => {
    const { data, index, getData } = props;
    return (
        <>
            <tr key={data?.id} style={{ border: "1px solid black" }} >
                <td style={{
                    width: "2rem", backgroundColor: "#ffffff",
                    padding: "5px 10px",
                    border: "1px solid #000",
                    color: "#007fff",
                }} >{index + 1}</td>
                <td style={{
                    width: "20rem", backgroundColor: "#ffffff",
                    padding: "5px 10px",
                    border: "1px solid #000",
                    color: "#007fff",
                }}  >
                    {data?.points}
                </td>
                <td style={{
                    width: "6rem", backgroundColor: "#ffffff",
                    padding: "5px 10px",
                    border: "1px solid #000",
                    color: "#007fff",
                }}  >
                    <a href={data?.absolute_path} >{data?.file_name}</a>
                </td>
                {getData && getData?.users?.filter((userDetails: any, index: number) => userDetails.id === data?.responsible).map((details: any, ind: number) => {
                    return (
                        <>
                            <td style={{
                                width: "10rem", backgroundColor: "#ffffff",
                                padding: "5px 10px",
                                border: "1px solid #000",
                                color: "#007fff",
                                whiteSpace:'nowrap',
                            }}  >
                                {details.username}
                            </td>
                        </>
                    );
                })}
                <td style={{
                    width: "7rem", backgroundColor: "#ffffff",
                    padding: "5px 10px",
                    border: "1px solid #000",
                    color: "#007fff",
                }}  >
                    {data?.date}
                </td>
                <td style={{
                    width: "4rem", backgroundColor: "#ffffff",
                    padding: "5px 10px",
                    border: "1px solid #000",
                    color: "#007fff",
                }}  >
                    {data?.status}
                </td>
                <td style={{
                    width: "4rem", backgroundColor: "#ffffff",
                    padding: "5px 10px",
                    border: "1px solid #000",
                    color: "#007fff",
                }}  >
                    {data?.remark}
                </td>
            </tr>
        </>
    );
};

// ----------------------------------------------------------------------------------

export default MomMailRow;