import { Schema } from 'yup';
import { ObjectType } from './commonInterfaces';

// todo: write sync Fn for this

function yupValidate<T extends ObjectType> ( schema: Schema<T>, obj: T ): Promise<T> {//obj partial T ?????
    const promise = schema.validate( obj, { abortEarly: false, } );
    return new Promise( ( resolve, reject ) => {
        promise
            .then( ( validatedData ) => {
                resolve( validatedData );
            } )
            .catch( errors => {
                const errorsObj: ObjectType = {};

                errors.inner.forEach( ( each: ObjectType ) => {
                    errorsObj[each.path] = each.message;
                } );

                reject( errorsObj );
            } );
    } );
};

export default yupValidate;