import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import {
  Box,
  Checkbox,
  MenuItem,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
} from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import styles from "./CostEstimation.module.scss";
import InfoIcon from "@mui/icons-material/Info";
import { AddMaterialRevisionModal } from "./AddMaterialRevisionModal";
import { useBaseModal } from "../../SearchPage/useBaseModal";
import { API } from "../../../api-services";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { AddBoughtOutRevisionModal } from "./AddBoughtOutRevisionModal";
import { CostingInfoModal } from "./CostingInfoModal";
import { BoughtOutInfoModal } from "./BoughtOutInfoModal";
import { useBaseParamsModal } from "../../../CustomHook/useBaseParamsModal";
import { ControlledMenu } from "@szhsin/react-menu";
import { EditNameandSequenceModal } from "./EditNameandSequenceModal";
import { useHistory } from "react-router-dom";
import { EditNameandBoughtSequenceModal } from "./EditNameandBoughtSequenceModal";
import swal from "sweetalert";
import { CreateCostCopyCountModal } from "./CreateCostCopyCountModal";
// import FileCopyIcon from "@mui/icons-material/FileCopy";
import { AddProcessRevisionModal } from "./Process/AddProcessRevisionModal";
import { CreateOverHeadsModal } from "../OverHeads/CreateOverHeadsModal";
import { EditNameAndSequenceProcess } from "../EditNameAndSequenceProcess";
import { EditNameAndSequenceOverhead } from "./EditNameAndSequenceOverhead";
import { ChildPartModal } from "./ChildPartInfoModal";
import { useQueryDispatch } from "../../../CustomHook/useQueryDispatch";
import {
  getRawMaterialCardCosts,
  resetRawMaterialCard,
} from "../../../Redux/Actions/rawMaterialCard.actions";
import { useAppDispatch } from "../../../../AppRouter";
import CombinedAssumtionModal from "./CombinedAssumtionModal";
import CopyPasteProcessModal from "../CopyPasteProcessModal";
import { getTopThreeHighestObjectsIds } from "../../../CustomHook/getTopHighestValuesFromArray";
import Swal from "sweetalert2";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{ fontSize: "0.9rem", color: "primary.main" }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export interface ICostEstimationProps {
  costDetailsData?: any;
  isExpand?: any;
  setPassingObj?: any;
  getCostDetailsData?: any;
  getHierarchyData?: any;
  SelectedmulIds?: any;
}
const isUrl = (url: any) => {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
};
export function CostEstimation(props: ICostEstimationProps) {
  const {
    vault,
    topVault,
    projectId,
    projectname,
    productId,
    abbrebation,
    cardtype,
    tableId,
    tableCounter,
    condition,
    calctype,
    calcultorId,
    scenarioId,
    costEstimationId,
    newUI
  } = useRouteParams<any>();

  const {
    costDetailsData,
    isExpand,
    setPassingObj,
    getCostDetailsData,
    getHierarchyData,
    SelectedmulIds,
  } = props;
  const createOverheads = useBaseParamsModal();
  const [materialHClick, setMaterialHClick] = React.useState<boolean>(false);
  const [BOPHClick, setBOPHClick] = React.useState<boolean>(false);
  const [processHClick, setProcessHClick] = React.useState<boolean>(false);
  const [overheadHClick, setOverheadHClick] = React.useState<boolean>(false);
  const childPartInfo = useBaseParamsModal();
  const [expanded, setExpanded] = React.useState<string | false>("panel1"); //"panel1"
  const [expanded1a, setExpanded1a] = React.useState<string | false>(cardtype); //"panel1a"
  const [expanded1b, setExpanded1b] = React.useState<string | false>(cardtype); //"panel1b"
  const [expanded2, setExpanded2] = React.useState<string | false>("panel2"); //"panel2"
  const [expanded2a, setExpanded2a] = React.useState<string | false>(cardtype); //"panel2a"
  const [expanded2b, setExpanded2b] = React.useState<string | false>(false); //"panel2b"
  const [expanded2c, setExpanded2c] = React.useState<string | false>(false); //"panel2c"
  const [expanded3, setExpanded3] = React.useState<string | false>("panel3"); //"panel3"
  const [expanded3a, setExpanded3a] = React.useState<string | false>(false); //"panel3a"
  const [expanded4, setExpanded4] = React.useState<string | false>("panel4"); //"panel4"
  const [expanded4a, setExpanded4a] = React.useState<string | false>(false); //"panel4a"
  const [rawMaterialData, setRawMaterialData] = React.useState<any>([]);
  const [ProcessData, setProcessData] = React.useState<any>([]);
  const [overheadsData, setOverheadsData] = React.useState<any>([]);
  const [childPartsData, setChildPartsData] = React.useState<any>([]);
  const [materialData, setMaterialData] = React.useState<any>([]);
  const [boughtoutPartData, setBoughtOutPartData] = React.useState<any>([]);
  const [processMainData, setProcessMainData] = React.useState<any>([]);
  const [overHeadsData, setOverHeadsData] = React.useState<any>([]);
  const [partsData, setPartsData] = React.useState<any>([]);
  const [deleteCategory, setDeleteCategory] = React.useState<any>("");
  const [type, setType] = React.useState<any>("");
  const [TableSelecteIds, setTableSelecteIds] = React.useState<any>();
  const [DataLoading, setParentDataLoading] = React.useState<boolean>(false);
  const [targetItemId, setTargetItemId] = React.useState<any>("");
  const [targetItem,setTargetItem]= React.useState<any>({})
  const history = useHistory<any>();  
  //Right Click Menu
  const [isOpenContext, setOpenContext] = React.useState(false);
  const [anchorPointCosting, setAnchorPointCosting] = React.useState({
    x: 0,
    y: 0,
  });
  //Right Click Item Menu
  const [isOpenContextItem, setOpenContextItem] = React.useState(false);
  const [anchorPointCostingItem, setAnchorPointCostingItem] = React.useState({
    x: 0,
    y: 0,
  });

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const handleChange1a =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded1a(newExpanded ? panel : false);
    };
  React.useEffect(() => {
    // setExpanded1a(cardtype);
    // setExpanded2a(cardtype);
    setTableSelecteIds(tableId);
    setMaterialHClick(false);
    setBOPHClick(false);
    setProcessHClick(false);
    setOverheadHClick(false);
  }, [vault, tableId]);

  const handleChange1b =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded1b(newExpanded ? panel : false);
    };

  const handleChange2 =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded2(newExpanded ? panel : false);
    };

  const handleChange2a =
    (panel: string, item: any) =>
      (event: React.SyntheticEvent, newExpanded: boolean) => {
        sessionStorage.setItem("selectedProcess", JSON.stringify(item));
        setPassingObj(item);
        setExpanded2a(newExpanded ? panel : false);
      };
  const handleChange2b =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded2b(newExpanded ? panel : false);
    };

  const handleChange2c =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded2c(newExpanded ? panel : false);
    };

  const handleChange3 =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded3(newExpanded ? panel : false);
    };

  const handleChange3a =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded3a(newExpanded ? panel : false);
    };

  const handleChange4 =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded4(newExpanded ? panel : false);
    };

  const handleChange4a =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded4a(newExpanded ? panel : false);
    };
  const getObj = () => {
    if (vault == 0) {
      return { top_vault: topVault };
    } else {
      return { vault: vault };
    }
  };

  const AddMaterialModal = useBaseParamsModal();
  const AddBoughtOutModal = useBaseParamsModal();
  const AddMatrialHandler = () => {
    AddMaterialModal.open(costDetailsData);
  };
  const AddBoughtOutHandler = () => {
    AddBoughtOutModal.open(costDetailsData);
  };

  const cosingInfoModal = useBaseParamsModal();
  const costInfoHandler = (material: any) => {
    cosingInfoModal.open(material);
  };

  const BoughtoutInfoModal = useBaseModal();
  const BoughtOutInfoHandler = () => {
    BoughtoutInfoModal.open();
  };

  // React.useEffect(() => {
  //   getRMData();
  // }, [tableCounter, vault]);

  const getRMData = () => {
    API.get(
      "/cost/cost_calculator/",
      {
        vault: vault,
        get_level: "material_calculator",
        scenario: scenarioId ? scenarioId : undefined,
      },
      0
    )
      .then((res: any) => {
        setMaterialData(res.data[0]);
        setBoughtOutPartData(res.data[1]);
      })
      .catch((err: any) => { });
  };

  const getProcessData = () => {
    API.get(
      "/cost/cost_calculator/",
      {
        ...getObj(),
        get_level: "process_calculator",
        scenario: scenarioId ? scenarioId : undefined,
      },
      0
    )
      .then((res: any) => {
        setProcessMainData(res.data);
      })
      .catch((err: any) => { });
  };

  const getOverHeadsData = () => {
    API.get(
      "/cost/cost_calculator/",
      {
        ...getObj(),
        get_level: "overheads",
        scenario: scenarioId ? scenarioId : undefined,
      },
      0
    )
      .then((res: any) => {
        setOverHeadsData(res.data);
      })
      .catch((err: any) => { });
  };

  const getPartsData = () => {
    API.get(
      "/cost/cost_calculator/",
      {
        ...getObj(),
        get_level: "child_part",
        scenario: scenarioId ? scenarioId : undefined,
      },
      0
    )
      .then((res: any) => {
        setPartsData(res.data);
      })
      .catch((err: any) => { });
  };

  React.useEffect(() => {
    setExpanded2a(cardtype);
    setExpanded1b(cardtype);
  }, [cardtype]);

  const getParentData = () => {
    API.get(
      "/cost/cost_calculator/",
      {
        ...getObj(),
        get_level: "category",
        scenario: scenarioId ? scenarioId : undefined,
      },
      0
    )
      .then((res: any) => {
        setRawMaterialData(res.data[0]);
        setProcessData(res.data[1]);
        setOverheadsData(res.data[2]);
        setChildPartsData(res.data[3]);
      })
      .catch((err: any) => { });
  };
  React.useEffect(() => {
    getParentData();
    getRMData();
    getProcessData();
    getOverHeadsData();
    getPartsData();
  }, [vault, tableCounter]);
  // React.useEffect(() => {
  //   getParentData();

  //   getProcessData();
  //   getOverHeadsData();
  //   getPartsData();
  // }, [vault, tableCounter]);

  const EditNameandSeqModal = useBaseParamsModal();
  const copyToAll = useBaseParamsModal();
  const combinedassumption = useBaseModal();
  const EditNameandSeqBOPModal = useBaseParamsModal();
  const EditNameProcessSeqModal = useBaseParamsModal();
  const EditNameOverheadSeqModal = useBaseParamsModal();
  const dispatch = useAppDispatch();
  const EditNameandSeqHandler = (MaterialData: any) => {
    EditNameandSeqModal.open(MaterialData);
  };

  const MaterialHandler = (item: any, type: any, active: any) => {
    setTableSelecteIds(item?.id);
    var data = Math?.random();
    history.push(
      `/createcostingpart/${projectId}/${projectname}/${productId}/${topVault}/${vault}/${abbrebation}/${active}/${item?.id}/${costDetailsData?.cost_estimation}/${tableCounter}/${condition}/${type}/0/${scenarioId}/0/${newUI}`
    );
  };

  const [isOpenContextProcess, setOpenContextProcess] = React.useState(false);
  const [anchorPointCostingProcess, setAnchorPointCostingProcess] =
    React.useState({ x: 0, y: 0 });
  const [isOpenContextProcessItem, setOpenContextProcessItem] =
    React.useState(false);
  const [anchorPointCostingProcessItem, setAnchorPointCostingProcessItem] =
    React.useState({ x: 0, y: 0 });

  const CreateCopyModal = useBaseParamsModal();
  const CreateCopyHandler = (idd: any) => {
    CreateCopyModal.open({ id: idd, type: "delinked" });
  };
  // CreateLinkedCopyHandler
  const CreateLinkedCopyHandler = (idd: any) => {
    CreateCopyModal.open({ id: idd, type: "linked" });
  };

  const CreateCopyToAllHandler = (idd: any) => {
    copyToAll.open(idd);
  };
  const handleOpenLinkReference = (reference: string, event: any) => {
    event.stopPropagation();
    window.open(reference);
  };

  const getType = (typ: any) => {
    switch (typ) {
      case "material":
        return "material";
      case "boughtout":
        return "bop";
      case "process":
        return "process";
      case "overhead":
        return "overheads";
      default:
        return "";
    }
  };

  const AddProcessModal = useBaseParamsModal();
  const AddProcessHandler = () => {
    AddProcessModal.open();
  };

  const AddOverheadsModal = useBaseParamsModal();
  const AddOverheadsHandler = () => {
    AddOverheadsModal.open();
  };

  // Material Cost
  const materialDataUnitCost = () => {
    return (
      materialData?.data &&
      materialData?.data?.map((item: any) => {
        return item?.material_cost;
      })
    );
  };
  const materialDataTotalCost = () => {
    return (
      materialData?.data &&
      materialData?.data?.map((item: any) => {
        return item?.material_cost * item?.quantity;
      })
    );
  };
  const totalmaterialCost =
    materialDataUnitCost() &&
    materialDataUnitCost()?.reduce((item1: any, item2: any) => {
      return item1 + item2;
    }, 0);

  const totalMaterialCostAdded =
    materialDataTotalCost() &&
    materialDataTotalCost()?.reduce((item1: any, item2: any) => {
      return item1 + item2;
    }, 0);

  //Boughtout Part Cost
  const boughtoutPartDataUnitCost = () => {
    return (
      boughtoutPartData?.data &&
      boughtoutPartData?.data?.map((item: any) => {
        return item?.bop_cost;
      })
    );
  };
  const TotalBOP = () => {
    return (
      boughtoutPartData?.data &&
      boughtoutPartData?.data?.map((item: any) => {
        return item?.bop_cost * item?.quantity;
      })
    );
  };
  const totalboughtoutPartCost =
    boughtoutPartDataUnitCost() &&
    boughtoutPartDataUnitCost()?.reduce((item1: any, item2: any) => {
      return item1 + item2;
    }, 0);

  const getBackground = (
    itemId: any,
    selectedId: any,
    highlightedIds: any,
    clicked: any
  ) => {
    if (itemId == selectedId) {
      return "#cee6fa !important";
    } else {
      return "";
    }
  };
  const getBackgroundProcess = (
    itemId: any,
    selectedId: any,
    highlightedIds: any,
    clicked: any
  ) => {
    return "#fffb90 !important";

  };

  const getHighLightedBC = (highlightedIds: any, itemId: any) => {
    let color: any = "";
    if (highlightedIds.includes(itemId)) {
      if (itemId === highlightedIds[0]) {
        color = "red !important";
      } else if (itemId === highlightedIds[1]) {
        color = "#ff00008a !important";
      }
      else if (itemId === highlightedIds[2]) {
        color = "#ff000045 !important";
      }
    } else {
      color = "";
    }
    return color;
  };
  const getHighLightedBCProcess = (highlightedIds: any, itemId: any) => {
    if (highlightedIds.includes(itemId)) {
      return "#ffe1b4 !important";
    } else {
      return "#fffb90";
    }
  };

  const totalBOPCost =
    TotalBOP() &&
    TotalBOP()?.reduce((item1: any, item2: any) => {
      return item1 + item2;
    }, 0);

  //Process Cost
  const processMainDataUnitCost = () => {
    return (
      processMainData?.data &&
      processMainData?.data?.map((item: any) => {
        return item?.machine_cost;
      })
    );
  };
  const totalprocessMainDataCost =
    processMainDataUnitCost() &&
    processMainDataUnitCost()?.reduce((item1: any, item2: any) => {
      return item1 + item2;
    }, 0);

  // OverHeads Cost
  const overHeadsDataUnitCost = () => {
    return (
      overHeadsData &&
      overHeadsData?.map((item: any) => {
        return item?.cost;
      })
    );
  };
  const overHeadsDataUnitCostAll = () => {
    return (
      overHeadsData &&
      overHeadsData?.map((item: any) => {
        return item?.cost * item?.quantity;
      })
    );
  };
  const totaloverHeadsDataCost =
    overHeadsDataUnitCost() &&
    overHeadsDataUnitCost()?.reduce((item1: any, item2: any) => {
      return item1 + item2;
    }, 0);

  const totaloverHeadsDataCostAll =
    overHeadsDataUnitCostAll() &&
    overHeadsDataUnitCostAll()?.reduce((item1: any, item2: any) => {
      return item1 + item2;
    }, 0);

  // Child Part Cost
  const childPartUnitCost = () => {
    return (
      partsData &&
      partsData?.map((item: any) => {
        return item?.cost;
      })
    );
  };

  const showStatus = (typ: any) => {
    let status: any = false;
    if (typ === "material") {
      if (materialData?.data?.length > 0) {
        status = true;
      } else {
        status = false;
      }
    } else if (typ === "boughtout") {
      if (boughtoutPartData?.data?.length > 0) {
        status = true;
      } else {
        status = false;
      }
    } else if (typ === "process") {
      if (processMainData?.length > 0) {
        status = true;
      } else {
        status = false;
      }
    } else if (typ === "overhead") {
      if (overHeadsData?.length > 0) {
        status = true;
      } else {
        status = false;
      }
    }
    return status;
  };

  const handleUnCheck = () => {
    API.put(
      `cost/material/${tableId}/`,
      {
        weight_cal_id: null,
        weight_input: null,
        cal_field_id: null,
      },
      0
    )
      .then((res: any) => {
        getRMData();
        getHierarchyData();
        getCostDetailsData();
      })
      .catch((err: any) => { });
  };
  const childPartUnitCostAllTotal = () => {
    return (
      partsData &&
      partsData?.map((item: any) => {
        return item?.cost * item?.quantity;
      })
    );
  };
  const totalPartcost =
    childPartUnitCost() &&
    childPartUnitCost()?.reduce((item1: any, item2: any) => {
      return item1 + item2;
    }, 0);
  const totalPartcostAll =
    childPartUnitCostAllTotal() &&
    childPartUnitCostAllTotal()?.reduce((item1: any, item2: any) => {
      return item1 + item2;
    }, 0);

  const CalcculatorUpdatehandler = () => {
    dispatch(
      getRawMaterialCardCosts({
        costEstimationId: costEstimationId,
        tableId: tableId,
      })
    );
  };

  const handlermultipleAssumptions = () => {
    combinedassumption.open();
  };

  return (
    <div>
      {AddMaterialModal.isOpen && (
        <AddMaterialRevisionModal
          isOpen={AddMaterialModal.isOpen}
          onCloseModal={AddMaterialModal.close}
          costDetailsData={AddMaterialModal.propsId}
          getRMData={getRMData}
          SelectedmulIds={SelectedmulIds}
        />
      )}

      {AddBoughtOutModal.isOpen && (
        <AddBoughtOutRevisionModal
          isOpen={AddBoughtOutModal.isOpen}
          onCloseModal={AddBoughtOutModal.close}
          costDetailsData={AddBoughtOutModal.propsId}
          getRMData={getRMData}
          SelectedmulIds={SelectedmulIds}
        />
      )}
      {copyToAll.isOpen && (
        <CopyPasteProcessModal
          isOpen={copyToAll.isOpen}
          onClose={copyToAll.close}
          sourceId={vault}
          processId={copyToAll.propsId}
        />
      )}
      {createOverheads.isOpen && (
        <CreateOverHeadsModal
          costDetailsData={createOverheads.propsId}
          getRMData={getOverHeadsData}
          isOpen={createOverheads.isOpen}
          onCloseModal={createOverheads.close}
        />
      )}

      {cosingInfoModal.isOpen && (
        <CostingInfoModal
          isOpen={cosingInfoModal.isOpen}
          onCloseModal={cosingInfoModal.close}
          material={cosingInfoModal.propsId}
          handleUnCheck={handleUnCheck}
        />
      )}
      { }
      {BoughtoutInfoModal.isOpen && (
        <BoughtOutInfoModal
          isOpen={BoughtoutInfoModal.isOpen}
          onCloseModal={BoughtoutInfoModal.close}
        />
      )}

      {EditNameandSeqModal.isOpen && (
        <EditNameandSequenceModal
          isOpen={EditNameandSeqModal.isOpen}
          onCloseModal={EditNameandSeqModal.close}
          itemsData={EditNameandSeqModal.propsId}
          getRMData={getRMData}
          CalcculatorUpdatehandler={CalcculatorUpdatehandler}
        />
      )}
      {EditNameProcessSeqModal.isOpen && (
        <EditNameAndSequenceProcess
          isOpen={EditNameProcessSeqModal.isOpen}
          onCloseModal={EditNameProcessSeqModal.close}
          itemsData={EditNameProcessSeqModal.propsId}
          getRMData={getProcessData}
          CalcculatorUpdatehandler={CalcculatorUpdatehandler}
        />
      )}
      {/* EditNameandSeqBOPModal */}
      {EditNameandSeqBOPModal.isOpen && (
        <EditNameandBoughtSequenceModal
          isOpen={EditNameandSeqBOPModal.isOpen}
          onCloseModal={EditNameandSeqBOPModal.close}
          itemsData={EditNameandSeqBOPModal.propsId}
          getRMData={getRMData}
          CalcculatorUpdatehandler={CalcculatorUpdatehandler}
        />
      )}
      {EditNameOverheadSeqModal.isOpen && (
        <EditNameAndSequenceOverhead
          isOpen={EditNameOverheadSeqModal.isOpen}
          onCloseModal={EditNameOverheadSeqModal.close}
          itemsData={EditNameOverheadSeqModal.propsId}
          getRMData={getOverHeadsData}
          CalcculatorUpdatehandler={CalcculatorUpdatehandler}
        />
      )}

      {CreateCopyModal.isOpen && (
        <CreateCostCopyCountModal
          isOpen={CreateCopyModal.isOpen}
          onCloseModal={CreateCopyModal.close}
          itemId={CreateCopyModal.propsId}
          getRMData={getRMData}
          type={type && type}
          getProcessData={getProcessData}
          getOverheadsData={getOverHeadsData}
        />
      )}

      {AddProcessModal.isOpen && (
        <AddProcessRevisionModal
          isOpen={AddProcessModal.isOpen}
          onCloseModal={AddProcessModal.close}
          costDetailsData={costDetailsData}
          getRMData={getProcessData}
        />
      )}
      {childPartInfo.isOpen && (
        <ChildPartModal
          isOpen={childPartInfo.isOpen}
          onCloseModal={childPartInfo.close}
          vaultId={childPartInfo.propsId}
        />
      )}

      {/* Material RIght Menu */}
      <ControlledMenu
        anchorPoint={anchorPointCosting}
        position="anchor"
        transition
        state={isOpenContext ? "open" : "closed"}
        direction="right"
        onClose={() => setOpenContext(false)}
        onClick={(e: any) => {
          e.stopPropagation();
        }}
      >
        {costDetailsData?.assumption &&
          costDetailsData?.commodity?.length > 0 && (
            <MenuItem
              sx={{ color: "primary.main", fontSize: "1rem" }}
              onClick={() => {
                if (type === "material") {
                  AddMaterialModal.open(costDetailsData);
                } else if (type === "boughtout") {
                  AddBoughtOutHandler();
                } else if (type === "process") {
                  AddProcessHandler();
                } else if (type === "overhead") {
                  createOverheads.open(costDetailsData);
                }
              }}
              disabled={
                costDetailsData?.permission?.includes("C") ? false : true
              }
            >
              Add
            </MenuItem>
          )}
        {showStatus(type) && (
          <MenuItem
            disabled={costDetailsData?.permission?.includes("D") ? false : true}
            sx={{ color: "primary.main", fontSize: "1rem" }}
            onClick={() => {
              swal({
                title:
                  "Deleting this all cost models from item will be deleted.",
                text: "Are you sure you want to delete cost models?",
                buttons: ["Cancel", "Yes"],

                icon: "error",
              }).then((confirm) => {
                if (confirm) {
                  if (type === "material" || type === "boughtout") {
                    API.delete(
                      `/cost/material/${costDetailsData?.cost_estimation}/`,
                      {
                        type: getType(type),
                        cost_model: true,
                      },
                      0
                    )
                      .then((res: any) => {
                        getHierarchyData();
                        getRMData();
                        getParentData();
                        getCostDetailsData();
                      })
                      .catch((err: any) => { });
                  } else if (type === "process") {
                    API.delete(
                      `/cost/process/${costDetailsData?.cost_estimation}/`,
                      {
                        type: getType(type),
                        cost_model: true,
                      },
                      0
                    )
                      .then((res: any) => {
                        getHierarchyData();
                        getProcessData();
                        getParentData();
                        getCostDetailsData();
                      })
                      .catch((err: any) => { });
                  } else if (type === "overhead") {
                    API.delete(
                      `/cost/overhead/${costDetailsData?.cost_estimation}/`,
                      {
                        type: getType(type),
                        cost_model: true,
                      },
                      0
                    )
                      .then((res: any) => {
                        getHierarchyData();
                        getOverHeadsData();
                        getParentData();
                        getCostDetailsData();
                      })
                      .catch((err: any) => {
                        const {data}= err.response;
                        if(err && err.response && data){
                          Swal.fire({
                            title: 'Error!',
                            text: data[0],
                            icon: 'error',
                            confirmButtonText: 'Ok'
                          })
                        }
                       });
                  }
                }
              });
            }}
          >
            Delete All Cost Models
          </MenuItem>
        )}
        {showStatus(type) && (
          <MenuItem
            disabled={costDetailsData?.permission?.includes("D") ? false : true}
            sx={{ color: "primary.main", fontSize: "1rem" }}
            onClick={() => {
              swal({
                title: "Deleting this all items  will be deleted.",
                text: "Are you sure you want to delete all items?",
                buttons: ["Cancel", "Yes"],

                icon: "error",
              }).then((confirm) => {
                if (confirm) {
                  if (type === "material" || type === "boughtout") {
                    API.delete(
                      `/cost/material/${costDetailsData?.cost_estimation}/`,
                      {
                        type: getType(type),
                        cost_model: false,
                      },
                      0
                    )
                      .then((res: any) => {
                        getHierarchyData();
                        getRMData();
                        getParentData();
                        getCostDetailsData();
                      })
                      .catch((err: any) => { });
                  } else if (type === "process") {
                    API.delete(
                      `/cost/process/${costDetailsData?.cost_estimation}/`,
                      {
                        type: getType(type),
                        cost_model: false,
                      },
                      0
                    )
                      .then((res: any) => {
                        getHierarchyData();
                        getProcessData();
                        getParentData();
                      })
                      .catch((err: any) => { });
                  } else if (type === "overhead") {
                    API.delete(
                      `/cost/overhead/${costDetailsData?.cost_estimation}/`,
                      {
                        type: getType(type),
                        cost_model: false,
                      },
                      0
                    )
                      .then((res: any) => {
                        getHierarchyData();
                        getOverHeadsData();
                        getParentData();
                        getCostDetailsData();
                      })
                      .catch((err: any) => {
                        const {data}= err.response;
                        if(err && err.response && data){
                          Swal.fire({
                            title: 'Error!',
                            text: data[0],
                            icon: 'error',
                            confirmButtonText: 'Ok'
                          })
                        }
                       });
                  }
                }
              });
            }}
          >
            Delete All (Items)
          </MenuItem>
        )}
        {showStatus(type) && (
          <MenuItem
            sx={{ color: "primary.main", fontSize: "1rem" }}
            onClick={() => {
              if (type === "material") {
                EditNameandSeqHandler(materialData);
              } else if (type === "boughtout") {
                EditNameandSeqBOPModal.open(boughtoutPartData);
              } else if (type === "process") {
                EditNameProcessSeqModal.open(processMainData);
              } else if (type === "overhead") {
                EditNameOverheadSeqModal.open(overHeadsData);
              }
            }}
            disabled={costDetailsData?.permission?.includes("C") ? false : true}
          >
            Edit the Names & Sequence
          </MenuItem>
        )}
      </ControlledMenu>

      <ControlledMenu
        anchorPoint={anchorPointCostingItem}
        position="anchor"
        transition
        state={isOpenContextItem ? "open" : "closed"}
        direction="right"
        onClose={() => setOpenContextItem(false)}
        onClick={(e: any) => {
          e.stopPropagation();
        }}
      >
        <MenuItem
          sx={{ color: "primary.main", fontSize: "1rem" }}
          disabled={costDetailsData?.permission?.includes("D") ? false : true}
          onClick={() => {
            swal({
              title: (type==="process" && targetItem?.mirror_id == null)?`Deleting the parent process will delink all its child processes.`:`Delete ${type}.`,
              text: "Are you sure?",
              buttons: ["Cancel", "Yes"],

              icon: "error",
            }).then((confirm) => {
              if (confirm) {
                if (type === "material" || type === "boughtout") {
                  API.delete(
                    `/cost/material/${targetItemId}/`,
                    {
                      cost_model: false,
                    },
                    0
                  )
                    .then((res: any) => {
                      getRMData();
                      getParentData();
                      getCostDetailsData();
                    })
                    .catch((err: any) => { });
                } else if (type === "process") {
                  API.delete(
                    `/cost/process/${targetItemId}/`,
                    {
                      cost_model: false,
                    },
                    0
                  )
                    .then((res: any) => {
                      getProcessData();
                      getParentData();
                      getCostDetailsData();
                    })
                    .catch((err: any) => { });
                } else if (type === "overhead") {
                  API.delete(
                    `/cost/overhead/${targetItemId}/`,
                    {
                      cost_model: false,
                    },
                    0
                  )
                    .then((res: any) => {
                      getOverHeadsData();
                      getParentData();
                      getCostDetailsData();
                    })
                    .catch((err: any) => { 
                      const {data}= err.response;
                      if(err && err.response && data){
                        Swal.fire({
                          title: 'Error!',
                          text: data[0],
                          icon: 'error',
                          confirmButtonText: 'Ok'
                        })
                      }
                    });
                }
              }
            });
          }}
        >
          Delete Item
        </MenuItem>
        {(type == "process" && !targetItem?.mirror_id) &&<MenuItem
          sx={{ color: "primary.main", fontSize: "1rem" }}
          onClick={() => CreateCopyHandler(targetItemId)}
          disabled={costDetailsData?.permission?.includes("C") ? false : true}
        >
          Create a delinked Copy
        </MenuItem> }
        {type != "process" && <MenuItem
          sx={{ color: "primary.main", fontSize: "1rem" }}
          onClick={() => CreateCopyHandler(targetItemId)}
          disabled={costDetailsData?.permission?.includes("C") ? false : true}
        >
          Create a  Copy
        </MenuItem>}

        {(type == "process" && !targetItem?.mirror_id) && <MenuItem
          sx={{ color: "primary.main", fontSize: "1rem" }}
          onClick={() => CreateLinkedCopyHandler(targetItemId)}
          disabled={costDetailsData?.permission?.includes("C") ? false : true}
        >
          Create a linked Copy
        </MenuItem>}

     { type != "process" &&  <MenuItem
          sx={{ color: "primary.main", fontSize: "1rem" }}
          onClick={() => CreateCopyToAllHandler(targetItemId)}
          disabled={costDetailsData?.permission?.includes("C") ? false : true}
        >
          Copy to other project/product
        </MenuItem>}
      { (type == "process" && !targetItem?.mirror_id) &&  <MenuItem
          sx={{ color: "primary.main", fontSize: "1rem" }}
          onClick={() => CreateCopyToAllHandler(targetItemId)}
          disabled={costDetailsData?.permission?.includes("C") ? false : true}
        >
          Copy to other project/product
        </MenuItem>}
      </ControlledMenu>

      {/* Process Right Menu */}
      <ControlledMenu
        anchorPoint={anchorPointCostingProcess}
        position="anchor"
        transition
        state={isOpenContextProcess ? "open" : "closed"}
        direction="right"
        onClose={() => setOpenContextProcess(false)}
        onClick={(e: any) => {
          e.stopPropagation();
        }}
      >
        <MenuItem
          sx={{ color: "primary.main", fontSize: "1rem" }}
        // onClick={() => {
        //   AddMaterialModal.open(costDetailsData);
        // }}
        >
          Add
        </MenuItem>
        <MenuItem
          sx={{ color: "primary.main", fontSize: "1rem" }}
        // onClick={() => {
        //   API.delete(
        //     `/cost/material/${costDetailsData?.cost_estimation}/`,
        //     {
        //       type: "material",
        //       cost_model: true,
        //     },
        //     0
        //   )
        //     .then((res: any) => {
        //       getRMData();
        //     })
        //     .catch((err: any) => { });
        // }}
        >
          Delete All Cost Models
        </MenuItem>
        <MenuItem
          sx={{ color: "primary.main", fontSize: "1rem" }}
        // onClick={() => {
        //   API.delete(
        //     `/cost/material/${costDetailsData?.cost_estimation}/`,
        //     {
        //       type: "material",
        //       cost_model: false,
        //     },
        //     0
        //   )
        //     .then((res: any) => {
        //       getRMData();
        //     })
        //     .catch((err: any) => { });
        // }}
        >
          Delete All (Items)
        </MenuItem>
        <MenuItem
          sx={{ color: "primary.main", fontSize: "1rem" }}
        // onClick={() => EditNameandSeqHandler(materialData)}
        >
          Edit the Names & Sequence
        </MenuItem>
      </ControlledMenu>

      <ControlledMenu
        anchorPoint={anchorPointCostingProcessItem}
        position="anchor"
        transition
        state={isOpenContextProcessItem ? "open" : "closed"}
        direction="right"
        onClose={() => setOpenContextProcessItem(false)}
        onClick={(e: any) => {
          e.stopPropagation();
        }}
      >
        <MenuItem sx={{ color: "primary.main", fontSize: "1rem" }}>
          Delete Item
        </MenuItem>
        <MenuItem sx={{ color: "primary.main", fontSize: "1rem" }}>
          Create a Copy
        </MenuItem>
      </ControlledMenu>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          backgroundColor: "primary.light",
          padding: "0.1rem 0.5rem",
        }}
      >
        <Box
          sx={{
            color: "primary.main",
            fontWeight: "500",
            width: "50%",
            paddingLeft: "2rem",
          }}
        >
          Name
        </Box>
        <Box
          sx={{
            color: "primary.main",
            fontWeight: "500",
            textAlign: "center",
            width: "10%",
          }}
        >
          Qty
        </Box>

        <Box
          sx={{
            color: "primary.main",
            fontWeight: "500",
            textAlign: "right",
            width: "20%",
          }}
        >
          Unit Cost
        </Box>
        <Box
          sx={{
            color: "primary.main",
            fontWeight: "500",
            textAlign: "right",
            width: "20%",
          }}
        >
          Total Cost
        </Box>
      </Box>
      <Box
        className={
          isExpand == true
            ? styles.costEstAccordian
            : styles.costEstAccordianInactive
        }
        // sx={{ height: { lg: "45vh", xl: "59vh" } }}
        adl-scrollbar="true"
        adl-scrollbar-width="0.3"
      >
        {/* Raw Material */}
        <Accordion
          expanded={expanded === "panel1"}
          onChange={handleChange("panel1")}
        >
          <AccordionSummary
            aria-controls="panel1d-content"
            id="panel1d-header"
            sx={{
              minHeight: "24px",
              backgroundColor: "#c4efb3",
              padding: "0 0.5rem",
            }}
          >
            <Box
              sx={{
                marginLeft: "1rem",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <Typography sx={{ fontSize: "1rem", fontWeight: "600" }}>
                Raw Material (RM-{rawMaterialData?.count?.material}, BOP-
                {rawMaterialData?.count?.bop})
              </Typography>
              <Typography sx={{ fontSize: "1rem", fontWeight: "600" }}>
                {rawMaterialData?.cost?.toFixed(2)} INR
              </Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails>
            {costDetailsData?.costing_type !== "bop" && (
              <Accordion
                expanded={expanded1a === "panel1a"}
                onChange={handleChange1a("panel1a")}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    width: "100%",
                    backgroundColor: "#c4efb3",
                    paddingRight: "0.5rem",
                  }}
                >
                  <Box
                    sx={{ width: "46%", textAlign: "center" }}
                    onContextMenu={(e) => {
                      e.stopPropagation();
                      e.preventDefault();
                      setAnchorPointCosting({ x: e.clientX, y: e.clientY });
                      setOpenContext(true);
                      setDeleteCategory("material");
                      setType("material");
                    }}
                  >
                    <AccordionSummary
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                      sx={{ minHeight: "24px", backgroundColor: "#c4efb3" }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          marginLeft: "0.5rem",
                          padding: "0.15rem 0",
                          textAlign: "left",
                        }}
                      >
                        <Typography
                          sx={{
                            lineHeight: "1.2",
                            fontSize: "1rem",
                            marginLeft: "0.5rem",
                          }}
                        >
                          Material
                        </Typography>
                        {materialData?.data?.length > 5 && (
                          <Typography
                            sx={{
                              marginLeft: "0.4rem",
                              marginRight: "0.4rem",
                              cursor: "pointer",
                              color: materialHClick ? "green" : "",
                            }}
                            onClick={(event: any) => {
                              event.stopPropagation();
                              setMaterialHClick(true);
                            }}
                          >
                            <span title="Highlight top three cost item">
                              {" "}
                              H
                            </span>
                          </Typography>
                        )}
                      </Box>
                    </AccordionSummary>
                  </Box>
                  <Box
                    sx={{
                      width: "5%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    {costDetailsData?.assumption &&
                      costDetailsData?.commodity?.length > 0 &&
                      costDetailsData?.permission?.includes("C") && (
                        <AddCircleOutlineIcon
                          titleAccess="Add Revision Material"
                          sx={{ cursor: "pointer" }}
                          onClick={() => AddMatrialHandler()}
                        />
                      )}
                  </Box>
                  <Box sx={{ width: "15%", textAlign: "center" }}>
                    <Typography sx={{ fontSize: "1rem" }}>
                      {materialData?.count}
                    </Typography>
                  </Box>
                  <Box sx={{ width: "20%", textAlign: "right" }}>
                    <Typography sx={{ fontSize: "1rem" }}>
                      {" "}
                      {totalmaterialCost?.toFixed(2)}
                    </Typography>
                  </Box>
                  <Box sx={{ width: "20%", textAlign: "right" }}>
                    <Typography sx={{ fontSize: "1rem" }}>
                      {totalMaterialCostAdded?.toFixed(2)}
                    </Typography>
                  </Box>
                </Box>
                <AccordionDetails>
                  <Box sx={{ marginLeft: "2rem" }}>
                    <Table>
                      <TableBody>
                        {materialData &&
                          materialData?.data?.map((item: any) => {
                            return (
                              <TableRow
                                sx={{
                                  cursor: "pointer",
                                  background:
                                    getBackground(
                                      item?.id,
                                      TableSelecteIds,
                                      getTopThreeHighestObjectsIds(
                                        materialData?.data,
                                        "material_cost"
                                      ),
                                      materialHClick
                                    ),
                                }}
                                onClick={() => {
                                  MaterialHandler(item, 1, "panel1a");
                                }}
                              >
                                <TableCell
                                  sx={{
                                    padding: "0.2rem 0.15rem",
                                    borderBottomColor: "primary.light",
                                    width: "45%",
                                    paddingLeft: "1rem",
                                  }}
                                  onContextMenu={(e) => {
                                    e.stopPropagation();
                                    e.preventDefault();
                                    setAnchorPointCostingItem({
                                      x: e.clientX,
                                      y: e.clientY,
                                    });
                                    setOpenContextItem(true);
                                    setTargetItemId(item?.id);
                                    setTargetItem(item)
                                    setType("material");
                                  }}
                                >
                                  <Box className={styles.materialName}>
                                    <Tooltip
                                      title={`${item?.alias} ${item?.cost_model_count > 0 ? (`(${item?.cost_model_count})`) :''}`}
                                      placement="top-start"
                                    >
                                      <Typography
                                        className={styles.aliasName}
                                        sx={{ fontSize: "1rem" }}
                                      >
                                        {item?.alias} {item?.cost_model_count > 0 ? (`(${item?.cost_model_count})`) :''} 
                                      </Typography>
                                    </Tooltip>
                                    <Tooltip title={
                                      <React.Fragment>
                                        <Typography sx={{ fontSize: "1rem" }}>
                                          Material Code : {item?.material?.material_code}
                                        </Typography>
                                        <Typography sx={{ fontSize: "1rem" }}>
                                          Material Category : {item?.material?.category}
                                        </Typography>
                                        <Typography sx={{ fontSize: "1rem" }}>
                                          Grade : {item?.material?.material_grade}
                                        </Typography>
                                        <Typography sx={{ fontSize: "1rem" }}>
                                          Sub Grade : {item?.material?.material_subgrade}
                                        </Typography>
                                        <Typography sx={{ fontSize: "1rem" }}>
                                          Description : {item?.material?.description}
                                        </Typography>
                                        <Typography sx={{ fontSize: "1rem" }}>
                                          Shape : {item?.material?.shape}
                                        </Typography>
                                        <Typography sx={{ fontSize: "1rem" }}>
                                          Density(kg/m3) : {item?.material?.density}
                                        </Typography>

                                        {item?.weight_input != null && <Typography sx={{ fontSize: "1rem" }}>
                                          Selected Parameter : {item?.weight_input_name} - {item?.weight_input}
                                        </Typography>}
                                      </React.Fragment>
                                    }
                                      placement="top-start">
                                      <InfoIcon
                                        sx={{ cursor: "pointer" }}

                                        onClick={() =>
                                          costInfoHandler(item)
                                        }
                                      />
                                    </Tooltip>
                                  </Box>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "0.2rem 0.5rem",
                                    borderBottomColor: "primary.light",
                                    width: "15%",
                                    textAlign: "center",
                                  }}
                                >
                                  {item?.quantity}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "0.2rem 0.5rem",
                                    borderBottomColor: "primary.light",
                                    width: "20%",
                                  }}
                                >
                                  <Box className={styles.unitCost}>
                                    <CurrencyRupeeIcon
                                      sx={{ fontSize: "1.5rem" }}
                                    />
                                    <Typography sx={{ fontSize: "1rem" }}>
                                      {item?.material_cost?.toFixed(2)}
                                    </Typography>
                                  </Box>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "0.2rem 0.5rem",
                                    borderBottomColor: "primary.light",
                                    width: "20%",
                                  }}
                                >
                                  <Box className={styles.totalCost}>
                                    <CurrencyRupeeIcon
                                      sx={{ fontSize: "1.5rem" }}
                                    />
                                    <Typography sx={{
                                      fontSize: "1rem", borderBottom: (materialHClick === true && getTopThreeHighestObjectsIds(materialData?.data?.map((item: any) => { return { id: item.id, bop_cost: item?.quantity * item?.material_cost } }), "bop_cost")?.includes(item?.id)) ? `3px solid ${getHighLightedBC(getTopThreeHighestObjectsIds(
                                        materialData?.data?.map((item: any) => { return { id: item.id, material_cost: item?.quantity * item?.material_cost } }),
                                        "material_cost"
                                      ),
                                        item.id
                                      )}` : ""
                                    }}>
                                      {(
                                        item?.material_cost * item?.quantity
                                      )?.toFixed(2)}
                                    </Typography>
                                  </Box>
                                </TableCell>
                              </TableRow>
                            );
                          })}
                      </TableBody>
                    </Table>
                  </Box>
                </AccordionDetails>
              </Accordion>
            )}
            <Accordion
              expanded={expanded1b == "panel1b"}
              onChange={handleChange1b("panel1b")}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  backgroundColor: "#c4efb3",
                  paddingRight: "0.5rem",
                }}
              >
                <Box
                  sx={{ width: "46%", textAlign: "center" }}
                  onContextMenu={(e) => {
                    e.stopPropagation();
                    e.preventDefault();
                    setAnchorPointCosting({ x: e.clientX, y: e.clientY });
                    setOpenContext(true);
                    setDeleteCategory("boughtout");
                    setType("boughtout");
                  }}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                    sx={{
                      minHeight: "24px",
                      backgroundColor: "#c4efb3",
                      paddingRight: "0.5rem",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        width: "100%",
                        marginLeft: "0.5rem",
                        padding: "0.15rem 0",
                        textAlign: "left",
                      }}
                    >
                      <Typography
                        sx={{
                          lineHeight: "1.2",
                          fontSize: "1rem",
                          marginLeft: "0.5rem",
                        }}
                      >
                        Bought-out Items
                      </Typography>
                      {boughtoutPartData?.data?.length > 5 && (
                        <Typography
                          sx={{
                            marginLeft: "0.4rem",
                            marginRight: "0.4rem",
                            cursor: "pointer",
                            color: BOPHClick ? "green" : "",
                          }}
                          onClick={(event: any) => {
                            event.stopPropagation();
                            setBOPHClick(true);
                          }}
                        >
                          <span title="Highlight top three cost item"> H</span>
                        </Typography>
                      )}
                    </Box>
                  </AccordionSummary>
                </Box>
                <Box
                  sx={{
                    width: "5%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {costDetailsData?.assumption &&
                    costDetailsData?.commodity?.length > 0 &&
                    costDetailsData?.permission?.includes("C") && (
                      <AddCircleOutlineIcon
                        titleAccess="Add Bought-Out Item"
                        sx={{ cursor: "pointer" }}
                        onClick={() => AddBoughtOutHandler()}
                      />
                    )}
                </Box>
                <Box sx={{ width: "15%", textAlign: "center" }}>
                  <Typography sx={{ fontSize: "1rem" }}>
                    {boughtoutPartData?.count}
                  </Typography>
                </Box>
                <Box sx={{ width: "20%", textAlign: "right" }}>
                  <Typography sx={{ fontSize: "1rem" }}>
                    {totalboughtoutPartCost
                      ? totalboughtoutPartCost?.toFixed(2)
                      : "--"}
                  </Typography>
                </Box>
                <Box sx={{ width: "20%", textAlign: "right" }}>
                  <Typography sx={{ fontSize: "1rem" }}>
                    {totalBOPCost ? totalBOPCost?.toFixed(2) : "--"}
                  </Typography>
                </Box>
              </Box>
              <AccordionDetails>
                <Box sx={{ marginLeft: "2rem" }}>
                  <Table>
                    <TableBody>
                      {boughtoutPartData &&
                        boughtoutPartData?.data?.map((item: any) => {

                          return (
                            <TableRow
                              onClick={() =>
                                MaterialHandler(item, 2, "panel1b")
                              }
                              sx={{
                                cursor: "pointer",
                                background:
                                  getBackground(
                                    item?.id,
                                    TableSelecteIds,
                                    getTopThreeHighestObjectsIds(
                                      boughtoutPartData?.data,
                                      "bop_cost"
                                    ),
                                    BOPHClick
                                  ),
                              }}
                            >
                              <TableCell
                                sx={{
                                  padding: "0.2rem 0.15rem",
                                  borderBottomColor: "primary.light",
                                  width: "45%",
                                  paddingLeft: "1rem",
                                }}
                                onContextMenu={(e) => {
                                  e.stopPropagation();
                                  e.preventDefault();
                                  setAnchorPointCostingItem({
                                    x: e.clientX,
                                    y: e.clientY,
                                  });
                                  setOpenContextItem(true);
                                  setTargetItemId(item?.id);
                                  setTargetItem(item);
                                  setType("boughtout");
                                }}
                              >
                                <Box className={styles.materialName}>
                                  <Tooltip
                                    title={`${item?.alias} ${item?.cost_model_count > 0 ? (`(${item?.cost_model_count})`) :''}`}
                                    placement="top-start"
                                  >
                                    <Typography
                                      className={styles.aliasName}
                                      sx={{ fontSize: "1rem" }}
                                    >
                                      {item?.alias}  {item?.cost_model_count > 0 ? (`(${item?.cost_model_count})`) :''} 
                                    </Typography>
                                  </Tooltip>
                                  <Tooltip title={
                                    <React.Fragment>
                                      <Typography sx={{ fontSize: "1rem" }}>
                                        BOP Code : {item?.purchasing_item?.code}
                                      </Typography>
                                      <Typography sx={{ fontSize: "1rem" }}>
                                        BOP Name : {item?.purchasing_item?.purchase_name}
                                      </Typography>
                                      <Typography sx={{ fontSize: "1rem" }}>
                                        Item Details : {item?.purchasing_item?.item_detail}
                                      </Typography>
                                      <Typography sx={{ fontSize: "1rem" }}>
                                        Length(mm) : {item?.purchasing_item?.length}
                                      </Typography>
                                      <Typography sx={{ fontSize: "1rem" }}>
                                        Width(mm) : {item?.purchasing_item?.width}
                                      </Typography>
                                      <Typography sx={{ fontSize: "1rem" }}>
                                        Height(mm) : {item?.purchasing_item?.height}
                                      </Typography>
                                      <Typography sx={{ fontSize: "1rem" }}>
                                        Weight(mm) : {item?.purchasing_item?.weight}
                                      </Typography>
                                      <Typography sx={{ fontSize: "1rem" }}>
                                        Reference :
                                        {isUrl(item?.purchasing_item?.reference) ? <span
                                          style={{ textDecoration: "underline", cursor: "pointer" }}
                                          onClick={(e: any) => handleOpenLinkReference(item?.purchasing_item?.reference, e)}>
                                          Link
                                        </span> : <span
                                          style={{ textDecoration: "underline", cursor: "pointer" }}
                                        >
                                          {item?.purchasing_item?.reference}
                                        </span>}
                                      </Typography>
                                      <Typography sx={{ fontSize: "1rem" }}>
                                        Per unit cost(INR) : {item?.total_bop_cost.toFixed(2)}
                                      </Typography>
                                      {item?.weight_input != null && <Typography sx={{ fontSize: "1rem" }}>
                                        Selected Parameter : {item?.weight_input_name} - {item?.weight_input}
                                      </Typography>}
                                    </React.Fragment>
                                  }
                                    placement="right-start">
                                    <InfoIcon
                                      sx={{ cursor: "pointer" }}
                                      onClick={() => costInfoHandler(item)}
                                    />
                                  </Tooltip>
                                </Box>
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: "0.2rem 0.5rem",
                                  borderBottomColor: "primary.light",
                                  width: "15%",
                                  textAlign: "center",
                                }}
                              >
                                {item?.quantity}
                              </TableCell>

                              <TableCell
                                sx={{
                                  padding: "0.2rem 0.5rem",
                                  borderBottomColor: "primary.light",
                                  width: "20%",
                                }}
                              >
                                <Box className={styles.unitCost}>
                                  <CurrencyRupeeIcon
                                    sx={{ fontSize: "1.5rem" }}
                                  />
                                  <Typography sx={{ fontSize: "1rem" }}>
                                    {item?.bop_cost?.toFixed(2)}
                                  </Typography>
                                </Box>
                              </TableCell>
                              <TableCell
                                sx={{
                                  padding: "0.2rem 0.5rem",
                                  borderBottomColor: "primary.light",
                                  width: "20%",
                                }}
                              >
                                <Box className={styles.totalCost}>
                                  <CurrencyRupeeIcon
                                    sx={{ fontSize: "1.5rem" }}
                                  />
                                  {/* @ts-ignore */}
                                  <Typography sx={{
                                    fontSize: "1rem", borderBottom: (BOPHClick === true && getTopThreeHighestObjectsIds(boughtoutPartData?.data?.map((item: any) => { return { id: item.id, bop_cost: item?.quantity * item?.bop_cost } }), "bop_cost")?.includes(item?.id)) ? `3px solid ${getHighLightedBC(getTopThreeHighestObjectsIds(
                                      boughtoutPartData?.data?.map((item: any) => { return { id: item.id, bop_cost: item?.quantity * item?.bop_cost } }),
                                      "bop_cost"
                                    ),
                                      item.id
                                    )}` : ""
                                  }}>
                                    {(item?.quantity * item?.bop_cost)?.toFixed(
                                      2
                                    )}
                                  </Typography>
                                </Box>
                              </TableCell>
                            </TableRow>
                          );
                        })}
                    </TableBody>
                  </Table>
                </Box>
              </AccordionDetails>
            </Accordion>
          </AccordionDetails>
        </Accordion>

        {/* Process */}
        {costDetailsData?.costing_type !== "bop" && (
          <Accordion
            expanded={expanded2 === "panel2"}
            onChange={handleChange2("panel2")}
          >
            <Box
              sx={{
                width: "100%",
                textAlign: "center",
                display: "flex",
                alignItems: "center",
                backgroundColor: "#fffb90",
                paddingRight: "0.5rem",
              }}
            >
              <AccordionSummary
                aria-controls="panel1d-content"
                id="panel1d-header"
                sx={{
                  minHeight: "24px",
                  width: "44%",
                  padding: "0 0.5rem",
                  backgroundColor: "#fffb90",
                }}
                onContextMenu={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                  setAnchorPointCosting({ x: e.clientX, y: e.clientY });
                  setOpenContext(true);
                  setDeleteCategory("process");
                  setType("process");
                }}
              >
                <Box sx={{ marginLeft: "1rem", display: "flex" }}>
                  <Typography sx={{ fontSize: "1rem", fontWeight: "600" }}>
                    Process ({ProcessData?.count})
                  </Typography>
                  {processMainData?.length > 5 && (
                    <Typography
                      sx={{
                        marginLeft: "0.4rem",
                        marginRight: "0.4rem",
                        cursor: "pointer",
                        color: processHClick ? "green" : "",
                      }}
                      onClick={(event: any) => {
                        event.stopPropagation();
                        setProcessHClick(true);
                      }}
                    >
                      <span title="Highlight top three cost item">H</span>
                    </Typography>
                  )}
                </Box>
              </AccordionSummary>
              <Box
                sx={{
                  width: "56%",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                {costDetailsData?.assumption &&
                  costDetailsData?.commodity?.length > 0 &&
                  costDetailsData?.permission?.includes("C") && (
                    <AddCircleOutlineIcon
                      titleAccess="Add Process Material"
                      sx={{ cursor: "pointer" }}
                      onClick={() => AddProcessHandler()}
                    />
                  )}
                <Typography sx={{ fontSize: "1rem", fontWeight: "600" }}>
                  {ProcessData?.cost?.toFixed(2)} INR
                </Typography>
              </Box>
            </Box>
            <AccordionDetails>
              {processMainData &&
                processMainData?.map((item: any) => {
                  return (
                    <Accordion
                      expanded={expanded2a == item?.alias}
                      onChange={handleChange2a(item?.alias, item)}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          backgroundColor:
                            getBackgroundProcess(
                              item?.id,
                              TableSelecteIds,
                              getTopThreeHighestObjectsIds(
                                processMainData?.map((itm: any) => {
                                  return {
                                    id: itm.id,
                                    process_cost:
                                      itm?.machine_cost +
                                      itm?.tool_cost +
                                      itm?.labour_cost +
                                      itm?.setup_cost,
                                  };
                                }),
                                "process_cost"
                              ),
                              processHClick
                            ),
                          paddingRight: "0.5rem",
                        }}
                      >
                        <Box sx={{ width: "46%", textAlign: "center" }}>
                          <AccordionSummary
                            aria-controls="panel2a-content"
                            id="panel2a-header"
                            sx={{
                              minHeight: "24px",
                              backgroundColor:
                                getBackgroundProcess(
                                  item?.id,
                                  TableSelecteIds,
                                  getTopThreeHighestObjectsIds(
                                    processMainData?.map((itm: any) => {
                                      return {
                                        id: itm.id,
                                        process_cost:
                                          itm?.machine_cost +
                                          itm?.tool_cost +
                                          itm?.labour_cost +
                                          itm?.setup_cost,
                                      };
                                    }),
                                    "process_cost"
                                  ),
                                  processHClick
                                ),
                              paddingRight: "0.5rem",
                            }}
                            onContextMenu={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              setAnchorPointCostingItem({
                                x: e.clientX,
                                y: e.clientY,
                              });
                              setOpenContextItem(true);
                              setTargetItemId(item?.id);
                              setTargetItem(item)
                              setDeleteCategory("process");
                              setType("process");
                              // setOpenContextItem(true);
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                width: "100%",
                                marginLeft: "0.5rem",
                                padding: "0.15rem 0",
                                textAlign: "left",
                              }}
                            >
                              <Tooltip
                                title={`${item?.alias} ${item?.cost_model_count > 0 ? (`(${item?.cost_model_count})`) :''}`}
                                placement="top-start"
                              >
                                <Typography
                                  className={styles.aliasName}
                                  sx={{
                                    lineHeight: "1.2",
                                    fontSize: "1rem",
                                    marginLeft: "0.5rem",
                                    textDecoration:!item?.mirror_id?"underline":""
                                  }}
                                >
                                  {item?.alias} 
                                </Typography>
                              </Tooltip>
                              {item?.cost_model_count > 0 ? (`(${item?.cost_model_count})`) :''} 
                            </Box>
                          </AccordionSummary>
                        </Box>
                        <Box
                          sx={{
                            width: "5%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          {/* <FileCopyIcon sx={{ fontSize: "1.5rem" }} /> */}
                        </Box>
                        <Box sx={{ width: "15%", textAlign: "center" }}>
                          <Typography sx={{ fontSize: "1rem" }}>
                            {item?.quantity}
                          </Typography>
                        </Box>
                        <Box sx={{ width: "20%", textAlign: "right" }}>
                          <Typography sx={{ fontSize: "1rem" }}>
                            {(
                              item?.machine_cost +
                              item?.tool_cost +
                              item?.labour_cost +
                              item?.setup_cost
                            )?.toFixed(2)}
                          </Typography>
                        </Box>
                        <Box sx={{ width: "20%", textAlign: "right" }}>
                          <Typography sx={{
                            fontSize: "1rem", float: "right", borderBottom: (processHClick === true && getTopThreeHighestObjectsIds(processMainData?.map((item: any) => {
                              return {
                                id: item.id, process_cost: item?.quantity *
                                  (item?.machine_cost +
                                    item?.tool_cost +
                                    item?.labour_cost +
                                    item?.setup_cost)
                              }
                            }), "process_cost")?.includes(item?.id)) ? `3px solid ${getHighLightedBC(getTopThreeHighestObjectsIds(
                              processMainData?.map((item: any) => {
                                return {
                                  id: item.id, process_cost: item?.quantity *
                                    (item?.machine_cost +
                                      item?.tool_cost +
                                      item?.labour_cost +
                                      item?.setup_cost)
                                }
                              }),
                              "process_cost"
                            ),
                              item.id
                            )}` : ""
                          }}>
                            {(
                              item?.quantity *
                              (item?.machine_cost +
                                item?.tool_cost +
                                item?.labour_cost +
                                item?.setup_cost)
                            )?.toFixed(2)}
                          </Typography>
                        </Box>
                      </Box>
                      <AccordionDetails>
                        <Box sx={{ marginLeft: "2rem" }}>
                          <Table>
                            <TableBody>
                              <TableRow
                                sx={{
                                  background:
                                    parseInt(calctype) == 3
                                      ? "#cee6fa !important"
                                      : "",
                                  cursor: "pointer",
                                }}
                              >
                                <TableCell
                                  sx={{
                                    padding: "0.2rem 0.15rem",
                                    borderBottomColor: "primary.light",
                                    width: "45%",
                                    paddingLeft: "1rem",
                                  }}
                                  onClick={() =>
                                    MaterialHandler(item, 3, item?.alias)
                                  }
                                >
                                  <Box className={styles.materialName}>
                                    <Typography sx={{ fontSize: "1rem" }}>
                                      Labour {item?.labour_cost_model_count > 0 ? (`(${item?.labour_cost_model_count})`) :''} 
                                    </Typography>
                                    {/* <InfoIcon
                                    sx={{ cursor: "pointer" }}
                                    titleAccess="Labour Info"
                                  /> */}
                                  </Box>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "0.2rem 0.5rem",
                                    borderBottomColor: "primary.light",
                                    width: "15%",
                                    textAlign: "center",
                                  }}
                                >
                                  {item?.quantity}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "0.2rem 0.5rem",
                                    borderBottomColor: "primary.light",
                                  }}
                                >
                                  <Box className={styles.unitCost}>
                                    <CurrencyRupeeIcon
                                      sx={{ fontSize: "1.5rem" }}
                                    />
                                    <Typography sx={{ fontSize: "1rem" }}>
                                      {item?.labour_cost?.toFixed(2)}
                                    </Typography>
                                  </Box>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "0.2rem 0.5rem",
                                    borderBottomColor: "primary.light",
                                  }}
                                >
                                  <Box className={styles.totalCost}>
                                    <CurrencyRupeeIcon
                                      sx={{ fontSize: "1.5rem" }}
                                    />
                                    <Typography sx={{ fontSize: "1rem" }}>
                                      {(
                                        item?.quantity * item?.labour_cost
                                      )?.toFixed(2)}
                                    </Typography>
                                  </Box>
                                </TableCell>
                              </TableRow>
                              <TableRow
                                sx={{
                                  background:
                                    parseInt(calctype) == 4
                                      ? "#cee6fa !important"
                                      : "",
                                  cursor: "pointer",
                                }}
                              >
                                <TableCell
                                  sx={{
                                    padding: "0.2rem 0.15rem",
                                    borderBottomColor: "primary.light",
                                    width: "45%",
                                    paddingLeft: "1rem",
                                  }}
                                  onClick={() =>
                                    MaterialHandler(item, 4, item?.alias)
                                  }
                                >
                                  <Box className={styles.materialName}>
                                    <Typography sx={{ fontSize: "1rem" }}>
                                      Machine {item?.machine_cost_model_count > 0 ? (`(${item?.machine_cost_model_count})`) :''} 
                                    </Typography>
                                    {/* <InfoIcon
                                    sx={{ cursor: "pointer" }}
                                    titleAccess="Machine Info"
                                  /> */}
                                  </Box>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "0.2rem 0.5rem",
                                    borderBottomColor: "primary.light",
                                    width: "15%",
                                    textAlign: "center",
                                  }}
                                >
                                  {item?.quantity}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "0.2rem 0.5rem",
                                    borderBottomColor: "primary.light",
                                    width: "20%",
                                  }}
                                >
                                  <Box className={styles.unitCost}>
                                    <CurrencyRupeeIcon
                                      sx={{ fontSize: "1.5rem" }}
                                    />
                                    <Typography sx={{ fontSize: "1rem" }}>
                                      {item?.machine_cost?.toFixed(2)}
                                    </Typography>
                                  </Box>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "0.2rem 0.5rem",
                                    borderBottomColor: "primary.light",
                                    width: "20%",
                                  }}
                                >
                                  <Box className={styles.totalCost}>
                                    <CurrencyRupeeIcon
                                      sx={{ fontSize: "1.5rem" }}
                                    />
                                    <Typography sx={{ fontSize: "1rem" }}>
                                      {(
                                        item?.quantity * item?.machine_cost
                                      )?.toFixed(2)}
                                    </Typography>
                                  </Box>
                                </TableCell>
                              </TableRow>
                              <TableRow
                                sx={{
                                  background:
                                    parseInt(calctype) == 5
                                      ? "#cee6fa !important"
                                      : "",
                                  cursor: "pointer",
                                }}
                              >
                                <TableCell
                                  sx={{
                                    padding: "0.2rem 0.15rem",
                                    borderBottomColor: "primary.light",
                                    width: "45%",
                                    paddingLeft: "1rem",
                                  }}
                                  onClick={() =>
                                    MaterialHandler(item, 5, item?.alias)
                                  }
                                >
                                  <Box className={styles.materialName}>
                                    <Typography sx={{ fontSize: "1rem" }}>
                                      Tool  {item?.tool_cost_model_count > 0 ? (`(${item?.tool_cost_model_count})`) :''}
                                    </Typography>
                                    {/* <InfoIcon
                                    sx={{ cursor: "pointer" }}
                                    titleAccess="Tool Info"
                                  /> */}
                                  </Box>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "0.2rem 0.5rem",
                                    borderBottomColor: "primary.light",
                                    width: "15%",
                                    textAlign: "center",
                                  }}
                                >
                                  {item?.quantity}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "0.2rem 0.5rem",
                                    borderBottomColor: "primary.light",
                                  }}
                                >
                                  <Box className={styles.unitCost}>
                                    <CurrencyRupeeIcon
                                      sx={{ fontSize: "1.5rem" }}
                                    />
                                    <Typography sx={{ fontSize: "1rem" }}>
                                      {item?.tool_cost?.toFixed(2)}
                                    </Typography>
                                  </Box>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "0.2rem 0.5rem",
                                    borderBottomColor: "primary.light",
                                  }}
                                >
                                  <Box className={styles.totalCost}>
                                    <CurrencyRupeeIcon
                                      sx={{ fontSize: "1.5rem" }}
                                    />
                                    <Typography sx={{ fontSize: "1rem" }}>
                                      {(
                                        item?.quantity * item?.tool_cost
                                      )?.toFixed(2)}
                                    </Typography>
                                  </Box>
                                </TableCell>
                              </TableRow>
                              <TableRow
                                sx={{
                                  background:
                                    parseInt(calctype) == 6
                                      ? "#cee6fa !important"
                                      : "",
                                  cursor: "pointer",
                                }}
                              >
                                <TableCell
                                  sx={{
                                    padding: "0.2rem 0.15rem",
                                    borderBottomColor: "primary.light",
                                    width: "45%",
                                    paddingLeft: "1rem",
                                  }}
                                  onClick={() =>
                                    MaterialHandler(item, 6, item?.alias)
                                  }
                                >
                                  <Box className={styles.materialName}>
                                    <Typography sx={{ fontSize: "1rem" }}>
                                      Set-Up  {item?.setup_cost_model_count > 0 ? (`(${item?.setup_cost_model_count})`) :''} 
                                    </Typography>
                                    {/* <InfoIcon
                                    sx={{ cursor: "pointer" }}
                                    titleAccess="Setup Info"
                                  /> */}
                                  </Box>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "0.2rem 0.5rem",
                                    borderBottomColor: "primary.light",
                                    width: "15%",
                                    textAlign: "center",
                                  }}
                                >
                                  {item?.quantity}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "0.2rem 0.5rem",
                                    borderBottomColor: "primary.light",
                                  }}
                                >
                                  <Box className={styles.unitCost}>
                                    <CurrencyRupeeIcon
                                      sx={{ fontSize: "1.5rem" }}
                                    />
                                    <Typography sx={{ fontSize: "1rem" }}>
                                      {item?.setup_cost?.toFixed(2)}
                                    </Typography>
                                  </Box>
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "0.2rem 0.5rem",
                                    borderBottomColor: "primary.light",
                                  }}
                                >
                                  <Box className={styles.totalCost}>
                                    <CurrencyRupeeIcon
                                      sx={{ fontSize: "1.5rem" }}
                                    />
                                    <Typography sx={{ fontSize: "1rem" }}>
                                      {(
                                        item?.quantity * item?.setup_cost
                                      )?.toFixed(2)}
                                    </Typography>
                                  </Box>
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          </Table>
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  );
                })}
            </AccordionDetails>
          </Accordion>
        )}

        {/* Overhead */}
        <Accordion
          expanded={expanded3 === "panel3"}
          onChange={handleChange3("panel3")}
        >
          <Box
            sx={{
              width: "100%",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              backgroundColor: "#f7e1f4",
              paddingRight: "0.5rem",
            }}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id="panel1d-header"
              sx={{
                minHeight: "24px",
                width: "44%",
                padding: "0 0.5rem",
                backgroundColor: "#f7e1f4",
              }}
              onContextMenu={(e) => {
                e.stopPropagation();
                e.preventDefault();
                setAnchorPointCosting({ x: e.clientX, y: e.clientY });
                setOpenContext(true);
                setDeleteCategory("overhead");
                setType("overhead");
              }}
            >
              <Box sx={{ marginLeft: "1rem" }}>
                <Typography sx={{ fontSize: "1rem", fontWeight: "600", display: 'inline-block' }}>
                  Overheads ({overheadsData?.count})
                  {overHeadsData?.length > 5 && (
                    <Typography
                      sx={{
                        marginLeft: "0.4rem",
                        marginRight: "0.4rem",
                        cursor: "pointer",
                        color: overheadHClick ? "green" : "",
                        display: 'inline-block'
                      }}
                      onClick={(event: any) => {
                        event.stopPropagation();
                        setOverheadHClick(true);
                      }}
                    >
                      <span title="Highlight top three cost item"> H</span>
                    </Typography>
                  )}
                </Typography>
              </Box>
            </AccordionSummary>
            <Box
              sx={{
                width: "56%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              {costDetailsData?.assumption &&
                costDetailsData?.commodity?.length > 0 &&
                costDetailsData?.permission?.includes("C") && (
                  <AddCircleOutlineIcon
                    titleAccess="Add Process Material"
                    sx={{ cursor: "pointer" }}
                    onClick={() => createOverheads.open(costDetailsData)}
                  />
                )}
              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Box sx={{ textAlign: "right" }}>
                  <Typography sx={{ fontSize: "1rem" }}>
                    {totaloverHeadsDataCost
                      ? totaloverHeadsDataCost?.toFixed(2)
                      : "--"}
                  </Typography>
                </Box>
                <Typography sx={{ width: { lg: '8rem', xl: '9rem' }, fontSize: "1rem", fontWeight: "600", textAlign: 'right' }}>
                  {overheadsData?.cost?.toFixed(2)} INR
                </Typography>
              </Box>
            </Box>
          </Box>
          <AccordionDetails>
            <Box sx={{ marginLeft: "2rem" }}>
              <Table>
                <TableBody>
                  {overHeadsData &&
                    overHeadsData?.map((item: any) => {
                      return (
                        <TableRow
                          sx={{
                            background: getBackground(
                              item?.id,
                              parseInt(tableId),
                              getTopThreeHighestObjectsIds(
                                overHeadsData,
                                "cost"
                              ),
                              overheadHClick
                            ),
                            cursor: "pointer",
                          }}
                        >
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.15rem",
                              borderBottomColor: "primary.light",
                              width: "45%",
                              paddingLeft: "1rem",
                            }}
                            onClick={() => {
                              MaterialHandler(item, 0, "panel3");
                            }}
                            onContextMenu={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              setAnchorPointCostingItem({
                                x: e.clientX,
                                y: e.clientY,
                              });
                              setOpenContextItem(true);
                              setTargetItemId(item?.id);
                              setTargetItem(item)
                              setDeleteCategory("overhead");
                              setType("overhead");
                            }}
                          >
                            <Box className={styles.materialName}>
                              <Tooltip
                                title={item?.alias}
                                placement="top-start"
                              >
                                <Typography
                                  className={styles.aliasName}
                                  sx={{ fontSize: "1rem" }}
                                >
                                  {item?.alias} {item?.cost_model_count > 0 ? `(${item?.cost_model_count})` :''}
                                </Typography>
                              </Tooltip>
                              {/* <FileCopyIcon
                                sx={{ cursor: "pointer" }}
                                titleAccess="Overhead Info"
                              /> */}
                            </Box>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "15%",
                              textAlign: "center",
                            }}
                          >
                            {item?.quantity}
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "20%",
                            }}
                          >
                            <Box className={styles.unitCost}>
                              <CurrencyRupeeIcon sx={{ fontSize: "1.5rem" }} />
                              <Typography sx={{ fontSize: "1rem" }}>
                                {item?.cost?.toFixed(2)}
                              </Typography>
                            </Box>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              width: "20%",
                            }}
                          >
                            <Box className={styles.totalCost}>
                              <CurrencyRupeeIcon sx={{ fontSize: "1.5rem" }} />
                              <Typography sx={{
                                fontSize: "1rem", borderBottom: (overheadHClick === true && getTopThreeHighestObjectsIds(overHeadsData?.map((item: any) => { return { id: item.id, overhead_cost: item?.cost * item?.quantity } }), "overhead_cost")?.includes(item?.id)) ? `3px solid ${getHighLightedBC(getTopThreeHighestObjectsIds(
                                  overHeadsData?.map((item: any) => { return { id: item.id, overhead_cost: item?.cost * item?.quantity } }),
                                  "overhead_cost"
                                ),
                                  item.id
                                )}` : ""
                              }}>
                                {(item?.cost * item?.quantity)?.toFixed(2)}
                              </Typography>
                            </Box>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </Box>
          </AccordionDetails>
        </Accordion>

        {/* Child Parts */}
        {!costDetailsData?.is_leaf && (
          <Accordion
            expanded={expanded4 === "panel4"}
            onChange={handleChange4("panel4")}
          >
            <AccordionSummary
              aria-controls="panel4-content"
              id="panel4-header"
              sx={{
                minHeight: "24px",
                backgroundColor: "#d6f1f4",
                padding: "0 0.5rem",
              }}
            >
              <Box
                sx={{
                  marginLeft: "1rem",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Typography sx={{ fontSize: "1rem", fontWeight: "600" }}>
                  Child Parts
                </Typography>
                <Typography sx={{ fontSize: "1rem", fontWeight: "600" }}>
                  {childPartsData?.cost?.toFixed(2)} INR
                </Typography>
              </Box>
            </AccordionSummary>
            <AccordionDetails>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  backgroundColor: "#d6f1f4",
                  paddingRight: "0.5rem",
                }}
              >
                <Box sx={{ width: "46%", textAlign: "center" }}>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      width: "100%",
                      marginLeft: "2rem",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "1rem",
                        marginLeft: "0.5rem",
                        paddingLeft: "0.5rem",
                      }}
                    >
                      Part Number
                    </Typography>
                  </Box>
                </Box>
                <Box
                  sx={{
                    width: "5%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {/* <AddCircleOutlineIcon
                  titleAccess="Add Revision Material"
                  sx={{ cursor: "pointer" }}
                /> */}
                </Box>
                <Box sx={{ width: "15%", textAlign: "center" }}>
                  <Typography sx={{ fontSize: "1rem" }}>
                    {partsData?.length}
                  </Typography>
                </Box>
                <Box sx={{ width: "20%", textAlign: "right" }}>
                  <Typography sx={{ fontSize: "1rem" }}>
                    {totalPartcost ? totalPartcost.toFixed(2) : "--"}
                  </Typography>
                </Box>
                <Box sx={{ width: "20%", textAlign: "right" }}>
                  <Typography sx={{ fontSize: "1rem" }}>
                    {totalPartcostAll ? totalPartcostAll?.toFixed(2) : "--"}
                  </Typography>
                </Box>
              </Box>

              <Box sx={{ marginLeft: "2rem" }}>
                <Table>
                  <TableBody>
                    {partsData &&
                      partsData?.map((item: any) => {
                        return (
                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.15rem",
                                borderBottomColor: "primary.light",
                                width: "45%",
                                paddingLeft: "1rem",
                              }}
                            >
                              <Box className={styles.materialName}>
                                <Typography
                                  sx={{
                                    fontSize: "1rem",
                                    cursor: "pointer",
                                    textDecoration: "underline",
                                  }}
                                  onClick={() =>
                                    history.push(
                                      `/createcostingpart/${projectId}/${projectname}/${productId}/${topVault}/${item?.id}/none/0/0/0/0/0/0/0/${scenarioId}/0/${newUI}`
                                    )
                                  }
                                >
                                  {item?.part_no}
                                </Typography>
                                <InfoIcon
                                  sx={{ cursor: "pointer" }}
                                  titleAccess="ChildPart Info"
                                  onClick={() => {
                                    childPartInfo.open(item?.id);
                                  }}
                                />
                              </Box>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                                width: "15%",
                                textAlign: "center",
                              }}
                            >
                              {item?.quantity}
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                                width: "20%",
                              }}
                            >
                              <Box className={styles.unitCost}>
                                <CurrencyRupeeIcon
                                  sx={{ fontSize: "1.5rem" }}
                                />
                                <Typography sx={{ fontSize: "1rem" }}>
                                  {item?.cost?.toFixed(2)}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                                width: "20%",
                              }}
                            >
                              <Box className={styles.totalCost}>
                                <CurrencyRupeeIcon
                                  sx={{ fontSize: "1.5rem" }}
                                />
                                <Typography sx={{ fontSize: "1rem" }}>
                                  {(item?.cost * item?.quantity)?.toFixed(2)}
                                </Typography>
                              </Box>
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  </TableBody>
                </Table>
              </Box>
            </AccordionDetails>
          </Accordion>
        )}
      </Box>
    </div>
  );
}
