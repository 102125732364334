import {AUTH} from "../Redux/Services/index.service";
import { ObjectType } from "../utlis/commonInterfaces";
import { setItems } from "../utlis/storage";

const sessionExpired = ( forceReload = false, cb?: () => void ) => {
    const setDataInStorage = ( obj: ObjectType ) => {
        AUTH.rememberMe
            ? setItems.call( localStorage, obj )
            : setItems.call( sessionStorage, obj );
    };
    AUTH.logout( cb );
};

export default sessionExpired;
