import React from 'react';

interface JsonViewerProps {
  data: any;
}

const JsonViewer: React.FC<JsonViewerProps> = ({ data }) => {
  const renderJson = (data: any) => {
    if (typeof data === 'string' || typeof data === 'number' || typeof data === 'boolean') {
      return <span>{String(data)}</span>;
    }

    if (Array.isArray(data)) {
      return (
        <ul>
          {data.map((item, index) => (
            <li key={index}>{renderJson(item)}</li>
          ))}
        </ul>
      );
    }

    if (typeof data === 'object' && data !== null) {
      return (
        <ul>
          {Object.entries(data).map(([key, value]) => (
            <li key={key}>
              <strong>{key}:</strong> {renderJson(value)}
            </li>
          ))}
        </ul>
      );
    }

    return null;
  };

  return <div>{renderJson(data)}</div>;
};

export default JsonViewer;
