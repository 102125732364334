import * as React from 'react';

export interface IMainDatabaseProps {
}

export default function MainDatabase(props: IMainDatabaseProps) {
    return (
        <div>

        </div>
    );
}
