import React, { useState } from 'react';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import axios from 'axios';
import { API } from '../../../api-services';
import { useRouteParams } from '../../../ui-reusable-component/useRouteParams';

interface Option {
	label: string;
	value: string;
}

interface SearchAutocompleteProps {
	setSelectedSupplier: any;
}

const SearchSupplier: React.FC<SearchAutocompleteProps> = (props) => {
	const { setSelectedSupplier } = props;
	const [inputValue, setInputValue] = useState<any>('');
	const [selectedValue, setSelectedValue] = useState<any>({});
	const { vault } = useRouteParams<any>();
	const [options, setOptions] = useState<any>([]);

	const handleInputChange = (e: any, newInputValue: any) => {
		if (e?.type == 'change' && typeof newInputValue === 'string') {
			if (newInputValue && typeof newInputValue === 'string') {
				setInputValue(newInputValue);
			}

			if (typeof newInputValue === 'string') {
				// Make an API call here to fetch search results
				API.get(
					'/api/db/supplier/',
					{ is_oem: false, search: newInputValue },
					0
				)
					.then((res: any) => {
						if (res.data?.length > 0) {
							setOptions(res.data);
						}
					})
					.catch((err: any) => {});
			} else {
				setOptions([]); // Clear the options when the input is empty
			}
		}
	};

	return (
		<Autocomplete
			options={options}
			getOptionLabel={(option: any) => option.name}
			style={{ width: 300 }}
			value={selectedValue && selectedValue}
            placeholder='Search Supplier'
			inputValue={inputValue}
			onChange={(event: any, newValue: any) => {
				if (newValue) {
					setSelectedValue(newValue);
					setInputValue(newValue?.name);
					setSelectedSupplier(newValue?.id);
				} else {
					setSelectedValue({});
					setInputValue('');
					setSelectedSupplier('');
				}
			}}
            sx={{
				'.MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator':
					{
						color: 'primary.main',
					},
				'.MuiButtonBase-root.MuiChip-root': {
					backgroundColor: 'primary.light',
				},
			}}
			onInputChange={(e: any, newInputValue: any) =>
				handleInputChange(e, newInputValue)
			}
			renderOption={(props, option) => (
				<li {...props} style={{ fontSize: '1rem' }}>
				  {option.name}
				</li>
			  )}
			renderInput={(params) => (
				<TextField
					{...params}					
					variant='standard'
					placeholder='Search Supplier'
					sx={{
						'& .MuiInput-underline:before': {
							borderBottomColor: 'primary.main',
						},
						'& .MuiInput-underline:after': {
							borderBottomColor: 'primary.main',
						},
						'.MuiInputBase-root.MuiInput-root': {
							fontSize: '12px',
						},
						'.MuiSvgIcon-root': { color: 'primary.main' },
					}}
				/>
			)}
		/>
	);
};

export default SearchSupplier;
