import React, { useEffect, useRef, useState } from "react";
import styles from "./CompanionConfig.module.scss";
import { useHistory, useParams } from "react-router-dom";
import {
  Box,
  Button,
  TextField,
  Typography,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import { AxiosError, AxiosResponse } from "axios";
import { API } from "../api-services";
import { QRCode } from "react-qrcode-logo";
import logo from "../Assets/images/svgs/logo.svg";
import { useReactToPrint } from "react-to-print";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
// @ts-ignore
import OTPInput from "otp-input-react";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import moment from "moment";
import { AUTH } from "../Redux/Services/auth.service";
import CompanionSessionLogs from "./CompanionSessionLogs";
import useWindowDimensions from "../ui-reusable-component/useWindowDimensions";

interface Params {
  projectId: any;
  projectname: any;
}

interface CompanionConfigI { }

const CompanionConfig: React.FC<CompanionConfigI> = (props) => {
  const { projectId, projectname } = useParams<Params>();
  const [fromDate, setFromDate] = useState<any>(new Date());
  const [toDate, setToDate] = useState<any>(new Date());
  const [OTP, setOTP] = useState("");
  const [sessionIdAPILoader, setSessionIdAPILoader] = useState<boolean>(false);
  const [urlLinkQR, setUrlLinkQR] = useState<any>();
  const [callApiAfterDeactivate, setCallApiAfterDeactivate] = useState<any>(false);
  const [afterSubmitApiCall, setAfterSubmitApiCall] = useState<boolean>(false);
  const [afterNotificationLoader, setAfterNotificationLoader] = useState<boolean>(false);
  const [errorSessionExists, setErrorSessionExists] = useState<any>();
  const history: any = useHistory();

  const { height, width } = useWindowDimensions();
  const imageIframeRef = useRef<any>(null);

  useEffect(() => {
    API.get(`/web_companion/companion_project/`, { companion_link: true, project: projectId }, 0)
      .then((res: AxiosResponse) => {
        setUrlLinkQR(res?.data);
      })
      .catch((err: AxiosError) => {
        console.log(err, "error");
      });
    setOTP("");
    setErrorSessionExists(undefined);
  }, [callApiAfterDeactivate, afterSubmitApiCall]);

  const handlePrint = useReactToPrint({
    content: () => imageIframeRef.current,
  });

  useEffect(() => {
    setErrorSessionExists(undefined)
  }, [OTP])

  const sendNotificationCompanionConfig = () => {
    setAfterNotificationLoader(true)
    API.get(`/web_companion/comapanion_mail/`, { project: projectId }, 0)
      .then((res: AxiosResponse) => {
        setAfterNotificationLoader(false);
      })
      .catch((err: AxiosError) => {
        console.log(err, "error");
        setAfterNotificationLoader(false)
      });
  }

  const handleSubmittedSessionId = () => {
    setSessionIdAPILoader(true);
    API.post("/web_companion/companion_project/", {
      session: Number(OTP),
      from_session: moment(fromDate).format("YYYY-MM-DD"),
      to_session: moment(toDate).format("YYYY-MM-DD"),
      project: projectId,
    }, 0).then((res: any) => {
      setSessionIdAPILoader(false);
      setAfterSubmitApiCall(!afterSubmitApiCall);
      setOTP("");
    }).catch((err: any) => {
      let { data }: any = err.response;
      setErrorSessionExists(data[0]);
      setSessionIdAPILoader(false);
    });
  };

  return (
    <div className={styles.container}>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
          <Typography
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span
              style={{
                backgroundColor: "#007fff14",
                color: "#007fff",
                padding: "0.5rem 1rem",
                borderRadius: "1.5rem",
                fontSize: "1rem",
              }}
            >
              {urlLinkQR && urlLinkQR?.link}
            </span>
          </Typography>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: 'column',
              justifyContent: "center",
            }}
          >
            <Box sx={{ boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px', borderRadius: '10px' }}>
              <Box ref={imageIframeRef}>
                <QRCode
                  value={urlLinkQR && urlLinkQR?.companion_link}
                  size={width > 1400 ? 280 : 180}
                  logoImage={logo}
                  logoWidth={width > 1400 ? 80 : 40}
                  logoHeight={width > 1400 ? 45 : 22}
                  logoPadding={5}
                  fgColor="#0a509e"
                  style={{ margin: 'auto', borderRadius: '10px' }}
                  removeQrCodeBehindLogo={true}
                  eyeRadius={[
                    {
                      // top/left eye
                      outer: [10, 10, 0, 10],
                      inner: [0, 10, 10, 10],
                    },
                    [10, 10, 10, 0], // top/right eye
                    [10, 0, 10, 10], // bottom/left
                  ]}
                  qrStyle="squares"
                  eyeColor={[
                    {
                      // top/left eye
                      outer: "#05377e",
                      inner: "#05377e",
                    },
                    {
                      // top/left eye
                      outer: "#05377e",
                      inner: "#05377e",
                    }, // top/right eye
                    {
                      // top/left eye
                      outer: "#05377e",
                      inner: "#05377e",
                    }, // bottom/left eye
                  ]}
                />
              </Box>
            </Box>
            <Typography sx={{ textAlign: "center", marginTop: '1rem', }}>
              <span
                style={{
                  cursor: "pointer",
                  padding: "0.5rem 2.5rem",
                  border: "0.1rem solid",
                  borderRadius: "1.5rem",
                  fontSize: '1rem',
                }}
                onClick={handlePrint}
              >
                Print
              </span>
            </Typography>
          </Box>
        </Box>
        {AUTH.isSuperUser && <>
          {urlLinkQR && urlLinkQR?.active == false ? (
            <Box
              sx={{
                // height: { lg: '20rem', xl: "27rem" },
                height: { lg: '47vh', xl: '53vh' },
                marginTop: "1rem",
                backgroundColor: '#007fff14',
                // backgroundImage: "linear-gradient(to bottom,#007fff26,white)",
              }}
            >
              <Box
                sx={{
                  marginBottom: { lg: '2rem', xl: "4rem" },
                  paddingTop: "2rem",
                  display: "flex",
                  justifyContent: "end",
                  width: "100%",
                  padding: "1rem",
                }}
              >
                <Box>
                 { sessionStorage.getItem("ConfigPermission")?.includes("C") || sessionStorage.getItem("ConfigPermission")?.includes("D") ||sessionStorage.getItem("ConfigPermission")?.includes("U") &&<Button
                    // size="small"
                    variant="contained"
                    sx={{
                      lineHeight: 1,
                      padding: '0.5rem 1rem',
                      "&:hover": {
                        transform: 'Scale(1.05)',
                        transition: 'transform 0.5s ease',
                      }
                    }}
                    onClick={() => history.push(`/companionSessionLogs/${projectId}/${projectname}`)}
                  >
                    Session Logs
                  </Button>}
                </Box>
              </Box>
              <Box
                sx={{
                  // display: "grid",
                  // gridTemplateColumns: "1fr repeat(4,2fr)",
                  display: 'flex',
                  alignItems: "center",

                  // textAlign: "center",                  
                  width: '100%'
                }}
              >
                <Box sx={{ width: '100%', display: 'flex', columnGap: '5rem', alignItems: "center", justifyContent: 'center', marginRight: '-9rem', }}>
                  <LocalizationProvider
                    variant="standard"
                    dateAdapter={AdapterMoment}

                  >
                    <DesktopDatePicker
                      label="From *"
                      value={fromDate}
                      inputFormat="DD/MM/YYYY"
                      minDate={new Date()}
                      onChange={(newValue) => {
                        return setFromDate(newValue);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          sx={{
                            width: "300px",
                            "& .MuiFormLabel-root": {
                              color: "primary.main",
                            },
                            "& .MuiFormLabel-root.Mui-focused": {
                              color: "primary.main",
                            },
                            "& .MuiSvgIcon-root": {
                              color: "primary.main",
                            },
                            ".MuiInputBase-input.MuiInput-input": {
                              fontSize: "1rem",
                            },
                            "& .Mui-error": {
                              color: "#d32f2f",
                              borderBottomColor: "#d32f2f",
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "primary.light",
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "primary.main",
                            },
                            "& .MuiInput-underline": { fontSize: "1rem" },
                          }}
                        />
                      )}
                    />
                    <div>
                      <ArrowRightAltIcon sx={{ fontSize: "3rem" }} />
                    </div>
                    <DesktopDatePicker
                      label="To *"
                      value={toDate}
                      inputFormat="DD/MM/YYYY"
                      minDate={fromDate}
                      onChange={(newValue) => {
                        return setToDate(newValue?._d);
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="standard"
                          sx={{
                            width: "300px",
                            "& .MuiFormLabel-root": {
                              color: "primary.main",
                            },
                            "& .MuiFormLabel-root.Mui-focused": {
                              color: "primary.main",
                            },
                            "& .MuiSvgIcon-root": {
                              color: "primary.main",
                            },
                            ".MuiInputBase-input.MuiInput-input": {
                              fontSize: "1rem",
                            },
                            "& .Mui-error": {
                              color: "#d32f2f",
                              borderBottomColor: "#d32f2f",
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "primary.light",
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "primary.main",
                            },
                            "& .MuiInput-underline": { fontSize: "1rem" },
                          }}
                        />
                      )}
                    />
                  </LocalizationProvider>
                </Box>
                <Box sx={{ width: "9.5rem",}}>
                  <Typography>
                    <span
                      style={{
                        border: "0.1rem solid",
                        borderRadius: "1.5rem",
                        padding: "0.5rem 3rem",
                        fontSize: '1rem',
                      }}
                    >
                      {Math.round((toDate - fromDate) / (1000 * 60 * 60 * 24))}{" "}
                      {`${Math.round((toDate - fromDate) / (1000 * 60 * 60 * 24)) > 1
                        ? "Days"
                        : "Day"
                        }`}
                    </span>
                    {Math.round((toDate - fromDate) / (1000 * 60 * 60 * 24)) < 0 ||
                      Math.round((toDate - fromDate) / (1000 * 60 * 60 * 24)) > 10 ? (
                      <p
                        style={{
                          fontSize: "0.75rem",
                          color: "#d32f2f",
                          marginTop: "1rem",
                        }}
                      >
                        *Duration can not be more then 10 days
                      </p>
                    ) : (
                      <></>
                    )}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  marginTop: { lg: '2rem', xl: "5rem" },
                }}
              >
                <OTPInput
                  value={OTP}
                  className={styles.otpinput}
                  onChange={setOTP}
                  OTPLength={6}
                  otpType="number"
                  disabled={false}
                />
              </Box>
              <Box sx={{ display: "flex", justifyContent: 'center', marginTop: { lg: '2rem', xl: "7rem" } }}>
                <Typography
                  sx={{ width: "100%", display: "flex", justifyContent: "center" }}
                >
                  {sessionStorage.getItem("ConfigPermission")?.includes("C") &&<LoadingButton
                    variant="contained"
                    sx={{
                      lineHeight: 1,
                      padding: '0.5rem 1rem',
                      "&:hover": {
                        transform: 'Scale(1.05)',
                        transition: 'transform 0.5s ease',
                      }
                    }}
                    disabled={
                      OTP?.length < 6 ||
                      Math.round((toDate - fromDate) / (1000 * 60 * 60 * 24)) <
                      0 ||
                      Math.round((toDate - fromDate) / (1000 * 60 * 60 * 24)) > 10
                    }
                    onClick={handleSubmittedSessionId}
                    loading={sessionIdAPILoader}
                  >
                    Save session ID
                  </LoadingButton>}
                </Typography>
              </Box>
              <Box>
                {errorSessionExists && <p style={{
                  fontSize: "0.75rem",
                  color: "#d32f2f",
                  marginTop: "0.4rem",
                  // marginRight: "10vw",
                  textAlign: "center"
                }}>*{errorSessionExists}</p>}
              </Box>
            </Box>
          ) : (
            <Box sx={{ marginTop: { lg: '1rem', xl: "2rem" } }}>
              {urlLinkQR && urlLinkQR?.active == true && <Box sx={{ display: 'flex', justifyContent: 'center', }}><LoadingButton variant="contained"
                sx={{
                  padding: '0.2rem 1.5rem',
                  '.MuiCircularProgress-root': {
                    color: 'primary.main',
                  },
                }} onClick={sendNotificationCompanionConfig} loading={afterNotificationLoader}>
                Send notification to users
              </LoadingButton></Box>}
              {urlLinkQR && urlLinkQR?.active == true && <CompanionSessionLogs callApiAfterDeactivate={callApiAfterDeactivate} setCallApiAfterDeactivate={setCallApiAfterDeactivate} />}
            </Box>
          )}
        </>}
      </Box>
    </div>
  );
};

export default CompanionConfig;
