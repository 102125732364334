import * as React from "react";
import styles from "./ArchitectureCreateLanding.module.scss";
import {
  Badge,
  Box,
  Checkbox,
  Dialog,
  DialogContent,
  Divider,
  Drawer,
  IconButton,
  InputBase,
  Slide,
  TextField,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import { useHistory } from "react-router-dom";
import { createContext, useState } from "react";

import EditIcon from "@mui/icons-material/Edit";
import InfoIcon from "@mui/icons-material/Info";
import { useBaseModal } from "../SearchPage/useBaseModal";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { API } from "../../api-services";
import { LoadingButton } from "@mui/lab";
import { AxiosError, AxiosResponse } from "axios";
import SearchIcon from "@mui/icons-material/Search";
import { ArchitectureCreateBody } from "./ArchitectureCreateBody";
import { ArchitectureCreateDrawer } from "./ArchitectureCreateDrawer";
import { ArchImageRepository } from "./ArchImageRepository";
import recyleBin from "../../Assets/images/deletefill.svg";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import SaveIcon from "@mui/icons-material/Save";
import ArchInfoModal from "./ArchInfoModal";
import Swal from "sweetalert2";
import {
  clearArchRepoImages,
  getArchImages,
} from "../../Redux/Actions/architecture.action";
import { debounce, set } from "lodash";
import { useAppDispatch } from "../../../AppRouter";
import ArchProductlevelDeleteModal from "./ArchProductlevelDeleteModal";
import ArchitectureDownloadSystemTree from "./ArchitectureDownloadSystemTree";
import CancelIcon from "@mui/icons-material/Cancel";
import { TransitionProps } from "@mui/material/transitions";
import ArchGroupDownloadConsolidated from "./ArchGroupDownloadConsolidated";
import excelIcon from "../../Assets/images/excel.png";
import { useDocumentTitle } from "../../ui-reusable-component/useDocumentTitle";

type Anchor = "top" | "left" | "bottom" | "right";
export const UserPermissionData = createContext<any>(null);
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export interface IArchitectureCreateLandingProps {
  selectedType: any;
  setselectedType: any;
  setRecycleBinCounter: any;
  recycleBinCounter: any;
  setSequenceCount: any;
  SequenceCount: any;
}

export function ArchitectureCreateLanding(
  props: IArchitectureCreateLandingProps
) {
  const {
    projectId,
    projectname,
    abbr,
    topvoult,
    subSystem,
    groupId,
    collapestatus,
    pageNumber,
    groupName,
    productpage,
    diagramsCount,
  } = useRouteParams<any>();
  useDocumentTitle("Architecture Create");
  const [selectedProject, setSelectedProject] = useState<any>([]);
  const createMediaFetch = useBaseModal();
  const InfoModal = useBaseModal();
  const [GroupName, setGroupName] = useState<any>(groupName);
  const deleteModal = useBaseModal();
  const [recyclebinCount, setRecyclebinCount] = useState<any>();
  const [downloadProductInfo, setDownloadProductInfo] = React.useState<any>([]);
  const [openMediaConsolidatedDownload, setOpenMediaConsolidatedDownload] =
    React.useState(false);
  const [groupContextTopVault, setGroupContextTopVault] = React.useState([]);
  const [openMediaDownload, setOpenMediaDownload] = React.useState(false);
  const dispatch = useAppDispatch();
  const [CounterPage, setCounterPage] = useState<any>(pageNumber);
  const [ProductCounter, setProductCounter] = useState<any>(
    parseInt(productpage)
  );
  const [openMove, setOpenMove] = React.useState(false);

  const [selectedType, setSelectedType] = React.useState<any>(1);

  const [SequenceCount, setSequenceCount] = React.useState<any>(0);
  const [recycleBinCounter, setRecycleBinCounter] = React.useState<any>(0);
  const [userpermission, setUserPermission] = useState<any>([]);
  const history = useHistory();

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const selectAllProject = (productIds: any) => {
    setSelectedProject([productIds]);
  };
  const handleFetchImageOpen = () => {
    createMediaFetch.open();
  };
  const getselectedProjectBackgroundColor = (idd: any) => {
    if (selectedProject?.includes(idd)) {
      return "primary.main";
    } else {
      return "primary.light";
    }
  };

  const getSelectedProjectTextColor = (idd: any) => {
    if (selectedProject?.includes(idd)) {
      return "primary.light";
    } else {
      return "primary.main";
    }
  };

  console.log(userpermission, "userpermissionuserpermission");
  React.useEffect(() => {
    setGroupName(groupName);
  }, [groupName, groupId]);

  React.useEffect(() => {
    API.get(`/auth/calibration_user_permission/`, {
      action: true,

      project: projectId,

      architecture_create: true,
    })
      .then((res: any) => {
        setUserPermission(res.data);
      })
      .catch((err: any) => {
        console.log("Server EEror");
      });
  }, [projectId]);

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <Box
      sx={{
        minWidth: 300,
        width: anchor === "top" || anchor === "bottom" ? "auto" : "auto",
      }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <ArchitectureCreateDrawer />
    </Box>
  );

  const handleClick = () => {
    history.push("/internallanding");
  };
  const exploreHandler = () => {
    createMediaFetch.open();
  };
  const InfoHandler = () => {
    InfoModal.open();
  };

  const GroupNameHandler = (e: any) => {
    if (e.target.value.length > 0) {
      ExactDebounce(
        e.target.value,
        projectId,
        abbr,
        groupId,
        pageNumber,
        productpage,
        diagramsCount
      );
    }

    setGroupName(e.target.value);
  };

  const ExactDebounce = React.useRef(
    debounce(
      async (
        criteria: any,
        projectId: any,
        abbreviation: any,
        groupId: any,
        pageNumber: any,
        productpage: any,
        diagramsCount: any
      ) => {
        await API.put(`/circuit_diagram/arch_group/${groupId}/`, {
          name: criteria,
          project: projectId,
          abbreviation: abbreviation,
        })
          .then((res: any) => {
            setSequenceCount((prev: any) => prev + 1);
            history.push(
              `/architecturecreateinternal/${projectId}/${projectname}/${abbreviation}/${topvoult}/${subSystem}/${groupId}/${collapestatus}/${pageNumber}/${productpage}/${criteria}/${diagramsCount}`
            );
            dispatch(
              getArchImages({
                project: projectId,

                abbreviation: abbreviation,

                page: pageNumber,
                col_page: productpage,
                group_id: groupId,
              })
            );
          })
          .catch((err: any) => {
            const { data } = err?.response;
            Swal.fire({
              icon: "error",
              html: `<div>
              <br />
              <p style="color:"red">${data[0]}</p>   
               </div>`,
            });
          });
      },
      1000
    )
  ).current;

  const ProductDeleteHandler = () => {
    deleteModal.open();
  };

  const ConsolidatedReportHandler = () => {
    {
      API.get(
        "/circuit_diagram/arch_download/",
        {
          project: projectId,
          group_id: groupId,
          categories: 3,
          abbreviation: abbr,
          download_image_count: true,
        },
        0
      )
        .then((res: any) => {
          setDownloadProductInfo(res?.data);
          setOpenMediaConsolidatedDownload(true);
        })
        .catch((err: any) => {
          console.log(err, "error console");
        });
      API.get(`/circuit_diagram/arch_groups/`, {
        project: projectId,
        abbreviation: abbr,
      }).then((res: any) => {
        var filterdata = res?.data?.filter(
          (item: any) => item?.id == groupId
        )[0];

        setGroupContextTopVault(filterdata?.top_vault_list);
      });
    }
  };

  const ArchDownloadHandler = () => {
    {
      API.get(
        "/circuit_diagram/arch_download/",
        {
          project: projectId,
          group_id: groupId,
          categories: 3,
          abbreviation: abbr,
          download_image_count: true,
        },
        0
      )
        .then((res: any) => {
          setDownloadProductInfo(res?.data);
          setOpenMediaDownload(true);
        })
        .catch((err: any) => {});
    }
  };

  const RecycleBinHanlder = () => {
    history.push(
      `/architecturecreate/recycleBinRrchitecturecreate/${projectId}`
    );
  };

  React.useEffect(() => {
    setCounterPage(pageNumber);

    setProductCounter(parseInt(productpage));
  }, [groupId, pageNumber]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCounterPage(value);
    history.push(
      `/architecturecreateinternal/${projectId}/${projectname}/${abbr}/${topvoult}/${subSystem}/${groupId}/${collapestatus}/${value}/${productpage}/${groupName}`
    );
  };

  const handleCloseMove = () => {
    setOpenMove(false);
  };
  const handleCloseMediaDownload = () => {
    setOpenMediaDownload(false);
  };

  const handleCloseMediaConsolidatedDownload = () => {
    setOpenMediaConsolidatedDownload(false);
  };

  React.useEffect(() => {
    API.get("/circuit_diagram/arch_recyclebin/", {
      bin_count: true,
      project: projectId,
    })
      .then((res: any) => {
        setRecyclebinCount(res?.data);
      })
      .catch((err: any) => {});
  }, [projectId, recycleBinCounter]);

  var PermissionValues =
    userpermission &&
    userpermission?.filter((item: any, index: any) => {
      return item?.top_vault == "all";
    })[0];

  return (
    <div>
      {openMediaConsolidatedDownload && (
        <Dialog
          open={openMediaConsolidatedDownload}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseMove}
          sx={{
            ".MuiPaper-root.MuiDialog-paper": { borderRadius: "10px" },
          }}
        >
          <DialogContent
            sx={{ width: { lg: "40vw", xl: "31vw" }, padding: "0rem" }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                height: "2.5rem",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  width: "100%",
                  textAlign: "center",
                  marginRight: "-2rem",
                }}
              >
                Architecture Consolidated Download (Excel)
              </Typography>
              <CancelIcon
                titleAccess="Close"
                sx={{ cursor: "pointer", margin: "0.5rem" }}
                onClick={handleCloseMediaConsolidatedDownload}
              />
            </Box>
            <Box sx={{ margin: "0.5rem", color: "grey" }}>
              <ArchGroupDownloadConsolidated
                countImage={
                  downloadProductInfo &&
                  downloadProductInfo?.reduce(
                    (totalItem: any, item: any) => totalItem + item?.count,
                    0
                  )
                }
                groupContextId={groupId}
                groupContextTopVault={groupContextTopVault}
                groupContextImageCategory={3}
                groupContextAbbreviation={abbr}
                openMediaConsolidatedDownload={openMediaConsolidatedDownload}
                setOpenMediaConsolidatedDownload={
                  setOpenMediaConsolidatedDownload
                }
                groupContextGroupName={groupName}
              />
            </Box>
          </DialogContent>
        </Dialog>
      )}

      {openMediaDownload && (
        <Dialog
          open={openMediaDownload}
          TransitionComponent={Transition}
          keepMounted
          onClose={handleCloseMediaDownload}
          sx={{
            ".MuiPaper-root.MuiDialog-paper": { borderRadius: "10px" },
          }}
        >
          <DialogContent
            sx={{ width: { lg: "40vw", xl: "31vw" }, padding: "0rem" }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                height: "2.5rem",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  width: "100%",
                  textAlign: "center",
                  marginRight: "-2rem",
                }}
              >
                Group {groupName} Download ( maximum 125 images at a time)
              </Typography>
              <CancelIcon
                titleAccess="Close"
                sx={{ cursor: "pointer", margin: "0.5rem" }}
                onClick={handleCloseMediaDownload}
              />
            </Box>
            <Divider />
            <Box sx={{ margin: "0.5rem", color: "grey" }}>
              <ArchitectureDownloadSystemTree
                userpermission={userpermission}
                downloadProductInfo={downloadProductInfo}
                groupContextAbbreviation={abbr}
                groupContextId={groupId}
                groupContextImageCategory={3}
                openMediaDownload={openMediaDownload}
                type={"groupLevel"}
              />
            </Box>
          </DialogContent>
        </Dialog>
      )}

      {InfoModal.isOpen && (
        <ArchInfoModal
          onCloseModal={InfoModal.close}
          isOpen={InfoModal.isOpen}
        />
      )}
      {deleteModal.isOpen && (
        <ArchProductlevelDeleteModal
          isOpen={deleteModal.isOpen}
          userpermission={userpermission}
          setRecycleBinCounter={setRecycleBinCounter}
          setselectedType={setSelectedType}
          onCloseModal={deleteModal.close}
        />
      )}

      <ArchImageRepository
        onCloseModal={createMediaFetch.close}
        isOpen={createMediaFetch.isOpen}
      />
      <Box
        sx={{
          padding: "0 0.5rem",
          display: "flex",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box
          sx={{
            width: { lg: "30vw", xl: "29vw" },
            display: "flex",
            columnGap: "1rem",
            alignItems: "center",
          }}
        >
          <IconButton sx={{ cursor: "pointer" }} onClick={handleClick}>
            <ArrowBackIcon color="primary" titleAccess="Go Back" />
          </IconButton>
          <Typography sx={{ fontSize: "1rem", whiteSpace: "nowrap" }}>
            {projectname} [ Upload Architecture -Diagrams]
          </Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box>
            {GroupName !== 0 && (
              <Box>
                <TextField
                  value={GroupName}
                  disabled={
                    PermissionValues && PermissionValues?.action?.includes("U")
                      ? false
                      : true
                  }
                  placeholder="group name"
                  variant="standard"
                  onChange={(e: any) => {
                    GroupNameHandler(e);
                  }}
                  sx={{
                    width: "20rem",
                    ".MuiInputBase-root.MuiInput-root": {
                      fontSize: "1rem !important",
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "primary.light",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "primary.main",
                    },
                  }}
                />
              </Box>
            )}
          </Box>
          {GroupName !== 0 && (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: "0.5rem",
                paddingRight: "1rem",
              }}
            >
              <Button size="small" onClick={() => exploreHandler()}>
                Explore Images
              </Button>
              <InfoIcon titleAccess="info" onClick={() => InfoHandler()} />
              {diagramsCount !== 0 && (
                <>
                  <DeleteIcon
                    titleAccess="Delete"
                    sx={{ cursor: "pointer" }}
                    onClick={() => ProductDeleteHandler()}
                  />
                  <>
                    {PermissionValues &&
                      PermissionValues?.action?.includes("R") && (
                        <img
                          src={excelIcon}
                          title="Architecture Consolidated Download (Excel)"
                          style={{ cursor: "pointer", height: "1.5rem" }}
                          onClick={() => ConsolidatedReportHandler()}
                        />
                      )}

                    {PermissionValues &&
                      PermissionValues?.action?.includes("R") && (
                        <FileDownloadIcon
                          titleAccess="Diagrams Download"
                          sx={{ cursor: "pointer" }}
                          onClick={() => ArchDownloadHandler()}
                        />
                      )}
                  </>
                </>
              )}

              {recyclebinCount && recyclebinCount?.count > 0 ? (
                <Typography
                  title="Recycle bin"
                  style={{ cursor: "pointer", marginTop: "-0.2rem" }}
                  onClick={() => RecycleBinHanlder()}
                >
                  <Badge
                    badgeContent={`${
                      recyclebinCount && recyclebinCount?.count
                    }`}
                    color="error"
                  >
                    <img src={recyleBin} style={{ height: "1.6rem" }} />
                  </Badge>
                </Typography>
              ) : (
                <DeleteOutlineIcon
                  onClick={() => RecycleBinHanlder()}
                  titleAccess="Recycle bin"
                  sx={{ color: "primary.main", fontSize: "2rem" }}
                />
              )}
            </Box>
          )}
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "flex-start", width: "100%" }}>
        <Box className={styles.expandIconBox}>
          <div className={styles.iconSec}>
            {(["left"] as const).map((anchor) => (
              <React.Fragment key={anchor}>
                <IconButton
                  size="medium"
                  sx={{
                    backgroundColor: "primary.light",
                    color: "primary.main",
                  }}
                  onClick={toggleDrawer(anchor, true)}
                >
                  <AddIcon sx={{ fontSize: "1.7rem" }} />
                </IconButton>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  className={styles.drawerContainer}
                  PaperProps={{
                    sx: {
                      backgroundColor: "aliceblue",
                    },
                  }}
                >
                  <Box
                    sx={{ padding: "0.5rem 0", position: "sticky", top: "0" }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3"
                  >
                    <Typography
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "1rem",
                      }}
                    >
                      Select Project
                    </Typography>
                    <Divider sx={{ margin: "0.2rem" }} />
                    {list(anchor)}
                  </Box>
                </Drawer>
                <Typography
                  sx={{ fontSize: "1rem", color: "primary.main" }}
                  onClick={toggleDrawer(anchor, true)}
                  className={styles.sideTitle}
                >
                  Selection Options
                </Typography>
              </React.Fragment>
            ))}
          </div>
        </Box>
        <Box sx={{ width: { lg: "96%", xl: "97%" }, paddingRight: "0.5rem" }}>
          <UserPermissionData.Provider value={userpermission}>
            <ArchitectureCreateBody
              setSelectedType={setSelectedType}
              selectedType={selectedType}
              SequenceCount={SequenceCount}
              setSequenceCount={setSequenceCount}
              recycleBinCounter={recycleBinCounter}
              setRecycleBinCounter={setRecycleBinCounter}
            />
          </UserPermissionData.Provider>
        </Box>
      </Box>
    </div>
  );
}
