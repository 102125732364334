import { useParams } from "react-router-dom";
// styles
import styles from "./RawMaterialCard.module.scss";
import { useAppDispatch } from "../../../../../AppRouter";
import { Fragment, useContext, useState } from "react";
import { useSelector } from "react-redux";
import { rawMaterialCardSelectors } from "../../../../Redux/Reducers/rawMaterialCard.reducer";
import {
  createRawMaterialCardCosts,
  getRawMaterialCardCosts,
  resetRawMaterialCard,
  updateRawMaterialCardCost,
} from "../../../../Redux/Actions/rawMaterialCard.actions";
import { useQueryDispatch } from "../../../../CustomHook/useQueryDispatch";
import { ID } from "../../../../utlis/commonInterfaces";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { joinClsx } from "../../../../utlis/joinClsx";
import { mapValues } from "lodash";
import { useRouteParams } from "../../../../ui-reusable-component/useRouteParams";
import { Dependencies } from "devextreme-react/gantt";
import RawMaterialCardCostRow from "./RawMaterialCardCostRow/RawMaterialCardCostRow";
import RawMaterialCardCostRowConfig from "./RawMaterialCardCostRow/RawMaterialCardCostRowConfig";

// lazy

// ----------------------------------------------------------------------------------
// params

interface Params {
  projectId: string;
  topVaultId: string;
  vaultId: string;
  subsystemId: string;
  scenarioId: string;
}

// ----------------------------------------------------------------------------------

interface RawMaterialCardProps {
  costDetailsData: any;
}

const RawMaterialCardConfig: React.FC<RawMaterialCardProps> = ({costDetailsData}:any) => {
  const {
    vault,
    topVault,
    projectId,
    projectname,
    productId,
    abbrebation,
    cardtype,
    tableId,
    costEstimationId,
    condition,
    calctype,
    calcultorId,
  } = useRouteParams<any>();

  const dispatch = useAppDispatch();
  // const readonly = useCostingReadonly();

  // ----------------------------------------------------------------------------------
  // state

  const [showCode, setShowCode] = useState(false);
  const [activeTab, setActiveTab] = useState<any>();

  // ----------------------------------------------------------------------------------
  // context

  // const costEstimationId = useContext( CostEstimationIdContext );

  // ----------------------------------------------------------------------------------
  // selectors

  /** raw material card cost ids */
  const costIds = useSelector(rawMaterialCardSelectors.selectIds);
  var SelectedTableIds =
    costIds &&
    costIds?.filter((item: any, index: any) => {
      return item == tableId;
    });


  // ----------------------------------------------------------------------------------
  // material cost selection props

  // const materialSelection = useMaterialSelectionModal();
  // const newMaterialSelection = useMaterialSelectionModal();
  // const fastenerSelection = useFastenersSelectionModal();
  // const purchasingSelection = usePurchasingItemSelectionModal();

  // ----------------------------------------------------------------------------------
  // boughtout selection props

  // const boughtOutSelection = useBaseModal();

  // ----------------------------------------------------------------------------------
  // handlers

  const handleChange = () => setShowCode((prev) => !prev);

  const handleNewSelect = async (selection: any) => {
    let action;
    // action = await dispatch( updateRawMaterialCardCost( {
    //     id: newMaterialSelection.cardCostId,
    //     updates: {
    //         material: selection
    //     }
    // } ) );

    // close modal on success
    // if ( updateRawMaterialCardCost.fulfilled.match( action ) ) {
    //     newMaterialSelection.close();
    // }
  };

  const handleSelect = async (selection: any) => {
    let action;
    // action = await dispatch( createRawMaterialCardCosts( {
    //     costEstimationId,
    //     materials: [selection]
    //     // materials: selection.map( material => material.id )
    // } ) );

    // close modal on success
    // if ( createRawMaterialCardCosts.fulfilled.match( action ) ) {
    //     materialSelection.close();
    // }
  };

  // ----------------------------------------------------------------------------------
  // side effects

  const costsFetched = useQueryDispatch({
    // @ts-ignore
    query: { action: getRawMaterialCardCosts, arg: { costEstimationId ,tableId:tableId} },
    cleanup: { action: resetRawMaterialCard, args: [] },
    dependency: [tableId, vault,calctype],
  });
  

  const getName = () => {
    switch (calctype) {
      case 1:
        return "material";
      case 2:
        return "boughtout";
      case 3:
        return "process";
      case 4:
        return "process";
      case 5:
        return "process";

      case 6:
        return "process";

      default:
        return "overhead";
    }
  };

  // ----------------------------------------------------------------------------------
  // jsx abstraction

  const renderRow = (costId: ID) => {
    return (
      <RawMaterialCardCostRowConfig
        key={costId}
        costId={costId}
        showCode={showCode}
        // handleChangeMaterial={ materialSelection.open( costId ) && newMaterialSelection.open( costId ) }
        // handleChangeFastener={ fastenerSelection.open( costId ) }
        // handleChangePurchasingItem={ purchasingSelection.open( costId ) }
        setActiveTab={setActiveTab}
        activeTab={activeTab}
        costDetailsData={costDetailsData}
      />
    );
  };

  // ----------------------------------------------------------------------------------
  // jsx

  return (
    <Fragment>
      <div data-testid="raw-material-card" className={styles.container}>
        {/* <Divider /> */}
        <div className={styles.main}>
          <div
            className={styles.tableContainer}
            adl-scrollbar="true"
            adl-scrollbar-width="0.6"
          >
            
            {!SelectedTableIds?.length ? (
              <Typography>No {getName()} added yet</Typography>
            ) : (             
              <Table stickyHeader aria-label="sticky table">
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        backgroundColor: "primary.light",
                        textAlign: "center",
                        color: "primary.main",
                        width: "3rem",
                      }}
                    >
                      Seq
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        backgroundColor: "primary.light",
                        textAlign: "center",
                        color: "primary.main",
                        lineHeight: "1.2",
                      }}
                    >
                      Name
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        backgroundColor: "primary.light",
                        textAlign: "center",
                        color: "primary.main",
                        lineHeight: "1.2",
                      }}
                    >
                      Item Category
                    </TableCell>
                    {calctype == 1 && <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        backgroundColor: "primary.light",
                        textAlign: "center",
                        color: "primary.main",
                        lineHeight: "1.2",
                      }}
                    >
                      Grade
                    </TableCell>}
                    {/* <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        backgroundColor: "primary.light",
                        textAlign: "center",
                        color: "primary.main",
                        lineHeight: "1.2",
                      }}
                    >
                      Item Code
                    </TableCell> */}
                    {/* <TableCell sx={{padding:'0.2rem 0.5rem'}}>RM Cost</TableCell>

                                            <TableCell sx={{padding:'0.2rem 0.5rem'}}>BOP Cost</TableCell> */}
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        backgroundColor: "primary.light",
                        textAlign: "center",
                        color: "primary.main",
                        lineHeight: "1.2",
                      }}
                    >
                      Unit Cost
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        backgroundColor: "primary.light",
                        textAlign: "center",
                        color: "primary.main",
                        width: "5rem",
                      }}
                    >
                      Qty.
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        backgroundColor: "primary.light",
                        textAlign: "center",
                        color: "primary.main",
                        lineHeight: "1.2",
                      }}
                    >
                      Total Cost
                    </TableCell>
                    {/* <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        backgroundColor: "primary.light",
                        textAlign: "center",
                        color: "primary.main",
                      }}
                    >
                      Actions
                    </TableCell> */}
                  </TableRow>
                </TableHead>
                <TableBody>{SelectedTableIds.map(renderRow)}</TableBody>
              </Table>
            )}           
          </div>         
        </div>
      </div>
    </Fragment>
  );
};

// ----------------------------------------------------------------------------------

export default RawMaterialCardConfig;
