import * as React from 'react';
import styles from './DispatchLandingPage.module.scss';
import { DragDropContext, DropResult } from "react-beautiful-dnd";
// import { API } from "../../api-services";
import {
  Box,
  Checkbox,
  Divider,
  Drawer,
  IconButton,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import SportsBasketballIcon from "@mui/icons-material/SportsBasketball";
// import { AUTH } from "../../Redux/Services/auth.service";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
// import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { useDispatch, useSelector } from "react-redux";
import { Dispatchpage } from './Dispatchpage';
import { useEffect, useState } from 'react';
import { API } from '../api-services';
import { useDocumentTitle } from '../ui-reusable-component/useDocumentTitle';



type Anchor = 'top' | 'left' | 'bottom' | 'right';

export interface IDispatchLandingPageProps {
}

const DispatchLandingPage = (props: IDispatchLandingPageProps) => {
  const { projectId, projectname, productId } = useParams<any>();
  const [ProjectData, setProjectData] = useState<any>();
  const [selectedId, setSelectedId] = useState<any>([parseInt(projectId)]);
  const [selectedLogs, setSelectedLogs] = useState<any>([parseInt(projectId)]);
  // const [selectProjectName,setSelectedProjectName]=useState<any>(projectName?projectName:"");
  const [selectedProduct, setSelectedProduct] = useState<any>([
    parseInt(productId),
  ]);
  useDocumentTitle( "Dispatch" );
  const [projectName, setProjectName] = useState<any>(projectname);
  const history = useHistory();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const selectLogsType = (logs: any,name:any) => {
    setSelectedLogs([logs]);
    setProjectName(name);
  };

  const getselectedBackgroundColor = (idd: any) => {
    if (selectedLogs?.includes(idd)) {
      return "primary.main";
    } else {
      return "primary.light";
    }
  };
  const getSelectedTextColor = (idd: any) => {
    if (selectedLogs?.includes(idd)) {
      return "primary.light";
    } else {
      return "primary.main";
    }
  };

  useEffect(() => {
    API.get("/xcpep/dispatch/",{
      outward_project_list:true
    },0).then((res: any) => {
      setProjectData(res?.data);
    });
  }, []);
  

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        console.log("anchor",selectedLogs)
        history.push(`/dispatch/${selectedLogs[0]}/${projectName}/${productId}`)
        setState({ ...state, [anchor]: open });
      };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        <ListItem>
          <Box sx={{ width: "100%" }}>
            <div>
              {ProjectData &&
                ProjectData?.map((item: any, index: any) => {
                  return (
                    <>
                      <Box sx={{ alignItems: "center", display: "flex" }}>
                        <Checkbox
                          style={{ padding: "3px" }}
                          checked={
                            selectedLogs?.includes(item.proj_id) ? true : false
                          }
                          onClick={() => selectLogsType(item?.proj_id,item?.proj_name)}
                          inputProps={{ "aria-label": "controlled" }}
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                        />
                        <Box
                          key={index}
                          className={styles.selectVehBtn}
                          onClick={() => selectLogsType(item?.proj_id,item?.proj_name)}
                          sx={{
                            padding: "0.2rem 0.5rem",
                            backgroundColor: getselectedBackgroundColor(
                              item.proj_id
                            ),
                            color: getSelectedTextColor(item.proj_id),
                            // textAlign: "center",
                            cursor: "pointer",
                            display: "inline-block",
                            width: "100%",
                          }}
                        >
                          <Typography sx={{ fontSize: "1rem" }}>
                            {item.proj_name}
                          </Typography>
                        </Box>
                      </Box>
                    </>
                  );
                })}
            </div>
            <div style={{ textAlign: "right", marginTop: "1rem" }}>
              <Button
                className={styles.drawBtn}
                sx={{ marginRight: "1rem" }}
                variant="contained"
                size="small"
                onClick={toggleDrawer(anchor, false)}
              >
                Close
              </Button>
              <Button
                className={styles.drawBtn}
                variant="contained"
                onClick={toggleDrawer(anchor, false)}
                size="small"
              >
                Ok
              </Button>
            </div>
          </Box>
        </ListItem>
      </List>
    </Box>
  );

  const handleClick = () => {
    history.goBack();
  };

  return (
    <Box>
      <Box sx={{ padding: "0 0.5rem" }}>
        <Box sx={{ display: 'flex', columnGap: '1rem', alignItems: 'center' }}>
          <IconButton sx={{ cursor: "pointer", }} onClick={handleClick}>
            <ArrowBackIcon
              color="primary"
              titleAccess="Go Back"
            />
          </IconButton>
          <Typography sx={{ fontSize: '1rem' }}> Dispatch -{projectname} </Typography>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'flex-start', width: '100%' }}>
        <Box className={styles.expandIconBox}>
          <div className={styles.iconSec}>
            {(["left"] as const).map((anchor) => (
              <React.Fragment key={anchor}>
                <IconButton
                  size="medium"
                  sx={{
                    backgroundColor: "primary.light",
                    color: "primary.main",
                  }}
                  onClick={toggleDrawer(anchor, true)}
                >
                  <AddIcon sx={{ fontSize: "1.7rem" }} />
                </IconButton>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  className={styles.drawerContainer}
                  PaperProps={{
                    sx: {
                      backgroundColor: "aliceblue",
                    },
                  }}
                >
                  <Box
                    sx={{ padding: "0.5rem 0", position: "sticky", top: "0" }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3"
                  >
                    <Typography
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "1rem",
                      }}
                    >
                      Selection Options
                    </Typography>
                    <Divider sx={{ margin: "0.2rem" }} />
                    {list(anchor)}
                  </Box>
                </Drawer>
                <Typography
                  sx={{ fontSize: "1rem", color: "primary.main" }}
                  onClick={toggleDrawer(anchor, true)}
                  className={styles.sideTitle}
                >
                  Selection Options
                </Typography>
              </React.Fragment>
            ))}
          </div>
        </Box>
        <Box sx={{ width: { lg: '96%', xl: '97%' } }}>
          <Dispatchpage />
        </Box>
      </Box>
    </Box>
  );
}
export default DispatchLandingPage;