import * as React from "react";
import Box from "@mui/material/Box";
import ProductPermission from "../PermissionModule/ProductPermission";
import { API } from "../../api-services";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";

type Anchor = "top" | "left" | "bottom" | "right";

interface IPermissionModuleLandingProps {}

function PermissionModuleLanding(props: IPermissionModuleLandingProps) {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const { projectId, routeType } = useRouteParams<any>();
  const [isProjectSuccess,setIsProjectSuccess]=React.useState<any>(false)
  const [projectList, setProjectList] = React.useState<any>([]);
  const getProjectList = () => {
    setIsProjectSuccess(true)
    API.get(
      "/auth/permission_project/",
      {
        project: routeType === "projectSpecific" ? projectId : undefined,
      },
      0
    )
      .then((res: any) => {
        setProjectList(res.data);
        setIsProjectSuccess(false)
      })
      .catch((err: any) => {
        setIsProjectSuccess(false)
      });
  };

  React.useEffect(() => {
    getProjectList();
  }, [routeType]);

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 300 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      Change Product
    </Box>
  );

  return (
    <Box sx={{width:'97%'}}>
      <ProductPermission projectList={projectList && projectList}  isProjectSuccess={isProjectSuccess}/>
    </Box>
  );
}
export default PermissionModuleLanding;
