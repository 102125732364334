import { TextField } from "@mui/material";
import { debounce } from "lodash";
import * as React from "react";
import { API } from "../../../api-services";
import Swal from "sweetalert2";
import { ADMIN } from "../../../Redux/Services/admin.service";

export interface IEditScequenceCostItemsMaterialProps {
    value?: any;
    getRMData: any;
    idd: any;
    getRMDataList: any;
    type:any;
    CalcculatorUpdatehandler:any;
}

export function EditSequenceCostItemOverhead(
  props: IEditScequenceCostItemsMaterialProps
) {
    const {value,getRMData,idd,getRMDataList,type,CalcculatorUpdatehandler} = props;
    const [sequence, setScequence] = React.useState<any>(0)
    const [oldSequence,setOldSequence]=React.useState<any>(0)
    const ExactDebounce = React.useRef(
        debounce(async (value: any,iddd:any) => {
          
            await API.put(
              `/cost/overhead/${iddd}/`,
              {
                sequence: parseInt(value),
              },
              {},
              0
            )
              .then((res: any) => {
                getRMDataList();
                CalcculatorUpdatehandler()
                getRMData();
                setOldSequence(value);
               ADMIN.toast.info("Sequence Updated Successfully");
              })
              .catch((err: any) => {
                const { data } = err?.response;
                Swal.fire({
                  icon: "error",
                  html: `<div>
                                   <br />
                                   <p style="color:"red">${data[0]}</p>   
                                    </div>`,
                });
              });
        }, 1000)
      ).current;
    
      React.useEffect(() => {
        return () => {
          ExactDebounce.cancel(); // Cancel the debounced function
        };
      }, []);
    const handleChange = (e: any) => {
        const {value}=e.target
        setScequence(value)
        // ExactDebounce(value,idd)

    }
    React.useEffect(() => {
        if(value) {
            setScequence(value?.sequence)
            setOldSequence(value?.sequence)
        }
    }, [value]);
  return (
    <TextField
      placeholder="Sequence"
      // label='Sequence'
      variant="standard"
      size="small"
      type="number"
      value={sequence}
      onChange={handleChange}
      onBlur={(event:any)=>{
        if(sequence!==oldSequence){
          ExactDebounce(event.target.value,idd);
        }
      }}
      fullWidth
      sx={{
        width: "100%",
        margin: "0.2rem 0",
        ".MuiInputBase-root.MuiInput-root": {
          fontSize: "1rem !important",
        },
        "& .MuiInput-underline:before": { borderBottomColor: "white" },
        "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
      }}
    />
  );
}
