import { useState } from "react";

// ----------------------------------------------------------------------------------

const useCurrencyAddEditModel = () => {
    const [isOpen, setIsOpen] = useState(false);
    const [rowData,setRowData] = useState<any>(null);

    const handleOpen = (data?: any) => {
        setIsOpen(true);
        setRowData(data);
    }
    const handleClose = () => {
        setIsOpen(false);
        setRowData(undefined);
    }

    return Object.freeze({
        open: handleOpen,
        close: handleClose,
        rowData,
        isOpen,
    });
};


// ----------------------------------------------------------------------------------

export { useCurrencyAddEditModel };