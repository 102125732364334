import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { Backdrop, IconButton, Popover, Button, Tooltip } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import DeleteIcon from "@mui/icons-material/Delete";
import { DataGrid, GridColDef } from "@mui/x-data-grid";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { API } from "../../api-services";
import Swal from "sweetalert2";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  bgcolor: "background.paper",
  borderRadius: "12px",
  boxShadow: 24,
  p: 0,
};

interface IBucketViewModalProps {
  isOpen: any;
  onCloseModal: any;
  getBucketList: any;
  data: any;
  setCounterApiCall: any;
  setBucketRefreshCounter:any;
}

function BucketViewModal(props: IBucketViewModalProps) {
  
  const { isOpen, onCloseModal, getBucketList, data , setCounterApiCall,setBucketRefreshCounter} = props;
  const { projectId, categoryId } = useRouteParams<any>();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [popoverContent, setPopoverContent] = React.useState<any[]>([]);

  const handlePopoverClick = (event: React.MouseEvent<HTMLElement>, content: any) => {
    setAnchorEl(event.currentTarget);
    setPopoverContent(content);
  };
  
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const handleDelete = (id: number) => {
    Swal.fire({
      title: "Are you sure?",
      text: "This parameter will be removed from the bucket",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
      customClass: {
        container: "swal2Container",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        API.delete(`/config/configuration_drop_bucket/`, { bucket_id: id }, 0)
          .then((res: any) => {
            Swal.fire({
              title: "Deleted!",
              text: "Removed from Bucket.",
              icon: "success",
              customClass: {
                container: "swal2Container",
              },
            });
            getBucketList(); 
            setCounterApiCall((prev: any) => prev + 1);   
            setBucketRefreshCounter((prev:any)=>prev+1);       
          })
          .catch((err: any) => {
            // Handle error if needed
          });
      }
    });
  };

  // Define columns for the popover data grid
  const popoverColumns: any = [
    {
      field: "calc_name",
      headerName: "Cost Model Name",
      flex: 1,
      renderCell: (params: any) => (
        <Tooltip title={params.value} arrow>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: "field_name",
      headerName: "Cost Model Field Name",
      flex: 1,
      renderCell: (params: any) => (
        <Tooltip title={params.value} arrow>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
    {
      field: "address",
      headerName: "Source",
      flex: 2,
      renderCell: (params: any) => (
        <Tooltip title={params.value} arrow>
          <span>{params.value}</span>
        </Tooltip>
      ),
    },
  ];

  // Define columns for the main data grid
  const columns: GridColDef[] = [
    { field: "id", headerName: "Sl No.", width: 70 },
    {
      field: "calc_name",
      headerName: "Cost Model Name",
      width: 300,
      valueGetter: (params) => params.row?.calc_name || "N/A",
    },
    {
      field: "field_name",
      headerName: "Cost Model Field Name",
      width: 300,
      valueGetter: (params) => params.row?.field_name || "N/A",
    },
    {
      field: "address",
      headerName: "Source",
      width: 330,
      valueGetter: (params) => params.row?.address || "N/A",
    },
    {
      field: "formula",
      headerName: "Cost Model Formula",
      width: 400,
      valueGetter: (params) => params.row?.formula || "N/A",
    },
    {
      field: "usage_info_count",
      headerName: "Usage",
      width: 150,
      renderCell: (params) => (
        <Button
          sx={{ textDecoration: params.value>0?"underline":"none", cursor: "pointer", color: "#007fff" }}
          onClick={(e) => {
            if(params.value>0){
            handlePopoverClick(e, params.row.usage_info)
            }
          
          }}
        >
          {params.value>0 ?
            params.value+" CM"
          :
           <IconButton
                 aria-label="delete"
                 onClick={() => handleDelete(params.row.ID)}
                 style={{ color: "red" }}
               >
                 <DeleteIcon />
               </IconButton>
          } 
        </Button>
      ),
    },
  ];

  // Map data to rows for the data grid
  const rows = data
    ? data.map((item: any, index: any) => ({
        id: index + 1,
        ID: item?.id,
        calc_name: item.calc_name,
        field_name: item.field_name,
        address: item.address,
        formula: item.formula,
        usage_info_count: item.usage_info_count, // Add usage info count here
        usage_info: item.usage_info, // Add usage info here
      }))
    : [];

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "3.5rem",
              p: 2,
              backgroundColor: "#007fff",
              borderTopLeftRadius: "12px",
              borderTopRightRadius: "12px",
            }}
          >
            <Typography
              sx={{
                fontSize: "1.2rem",
                fontWeight: "bold",
                width: "100%",
                textAlign: "center",
                color: "#fff",
              }}
            >
              Reusable Parameters
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", color: "#fff", fontSize: "1.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider sx={{ borderColor: "primary.light" }} />

          <Box sx={{ p: 3 }}>
            <div style={{ height: 400, width: "100%" }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                disableSelectionOnClick
              />
            </div>
          </Box>
        </Box>
      </Modal>

      {/* Popover for usage info */}
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handlePopoverClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Box sx={{ p: 2, width: 800, height: 300, overflowY: "auto" }}>
          <Typography variant="h6" gutterBottom>
            Details
          </Typography>
          <DataGrid
            //@ts-ignore
            rows={popoverContent}
            columns={popoverColumns}
            pageSize={3}
            rowsPerPageOptions={[3]}
            disableSelectionOnClick
            autoHeight
            disableColumnMenu
          />
        </Box>
      </Popover>
    </div>
  );
}

export default BucketViewModal;
