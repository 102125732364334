import { Box, ButtonProps, Dialog, Divider, Modal, Skeleton, Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import React, { useEffect, useState } from "react";
import { API } from "../../../api-services";
import useWindowDimensions from "../../../../Components/ui-reusable-component/useWindowDimensions";
import InfoIcon from '@mui/icons-material/Info';
import {
  DataGrid,
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExportContainer,
} from "@mui/x-data-grid";
import LoadingButton from "@mui/lab/LoadingButton";
import { useBaseModal } from "../../SearchPage/useBaseModal";
import SheetInfoModal from "./SheetInfoModal";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",

  width: "90vw",
  // height: '80vh',
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

interface SheetViewModal {
  onCloseModal: any;
  isOpen: any;
  getData: any;
}
const csvOptions: GridCsvExportOptions = {
  fileName: `Sheet View Table`,
  delimiter: ",",
  utf8WithBom: true,
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = (OpeninfoModal: any) => (

  <GridToolbarContainer style={{ height: "2rem" }}>
    <span style={{ position: "absolute", right: "0rem", fontSize: "1rem", paddingRight: '1rem' }}>
      {<>
        <LoadingButton

          onClick={OpeninfoModal}

        ><InfoIcon titleAccess="Info" sx={{ fontSize: '1.2rem', marginRight: '0.2rem' }} /><span style={{ fontSize: "0.9rem" }}>Link Sheet</span></LoadingButton>



      </>}
      <GridToolbarColumnsButton />
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);
export default function SheetViewModal(props: SheetViewModal) {
  const { onCloseModal, isOpen, getData } = props;
  const [SheetData, setSheetData] = useState<any>([]);
  const [loader, setloader] = useState<any>(false);
  const { height, width } = useWindowDimensions();
  const sheetinfo = useBaseModal()

  useEffect(() => {
    setloader(true);
    API.get(`/xcpep/sheet_view/`, { vault: getData?.id, sheet_view: true }, 0)
      .then((res: any) => {
        setSheetData(res?.data);
        setloader(false);
      })
      .catch((err: any) => {
        setloader(false);
        console.log("Server Error");
      });
  }, [getData]);
  const BomparameterData = () => {
    var valuedata = SheetData && SheetData[0];
    var data = SheetData && valuedata && Object?.keys(valuedata);

    var valuedata =
      data &&
      data?.map((key: any, value: any) => {

        console.log(key, value, "valuesfssfsfsffsfsffssfdfdfsdfsfdsfdfdfsdfd");
        return {
          field: key,
          headerName: key,
          width: key == "Part Name" ? width > 1400 ? 250 : 200 : key == "Part Number" ? width > 1400 ? 200 : 150 : width > 1400 ? 150 : 100,
        };
      });

    return valuedata;
  };

  const TableRow = () => {
    var valuedata =
      SheetData &&
      SheetData.map((item: any, id: any) => ({
        ...item,
        id: id + 1,
      }));

    return valuedata;
  };

  var columsData = () => {
    console.log(SheetData);
    if (SheetData?.length > 0) {
      return Object?.keys(SheetData[0]);
    }
  };
  // console.log(BomparameterData())

  const dataRender: any = {
    columns: BomparameterData() && BomparameterData(),
    rows: TableRow(),
  };

  const OpeninfoModal = () => {
    sheetinfo.open()
  }

  return (
    <Modal
      open={isOpen}
      onClose={onCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      
    >
      <Box sx={style}>


        {sheetinfo?.isOpen && (
          <SheetInfoModal
            onCloseModal={sheetinfo.close}
            isOpen={sheetinfo.open}
            getData={getData}
          />
        )}

        <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
          <Typography
            style={{
              fontSize: "1rem",
              fontWeight: "600",
              width: "100%",
              textAlign: "center",
              marginRight: "-2rem",
            }}
          >
            Sheet View Table({getData?.name})
          </Typography>
          <CancelIcon
            titleAccess="Close"
            sx={{ cursor: "pointer", margin: "0.5rem" }}
            onClick={onCloseModal}
          />
        </Box>
        <Divider
          sx={{
            borderColor: "primary.light",
            margin: "0 1rem",
            marginBottom: "1rem",
          }}
        />
        <Box
          sx={{ height: "75vh", width: "100%" }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3"
        >{
            loader ?<><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton></>
        :<>
          {columsData() && columsData() && (
            <DataGrid
              {...dataRender}
              headerHeight={48}
              rowHeight={36}
              loading={loader}
              density="compact"
              // autoHeight={true}
              components={{
                Toolbar: () => {
                  return CustomToolbar(OpeninfoModal);
                },
              }}
              // hideFooter={true}
              // hideFooterPagination={true}
              sx={{
                "&.MuiDataGrid-root .MuiDataGrid-cell": {
                  borderBottomColor: "primary.light",
                },
                "& ::-webkit-scrollbar": {
                  width: "0.3rem",
                  height: "0.3rem",
                },
                "& ::-webkit-scrollbar-thumb": {
                  // background: $thumb-color;
                  backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                  borderRadius: "10px",
                },
                "& ::- webkit-scrollbar-thumb: hover ": {
                  // width: "4px",
                  backgroundColor: "#045DE9 !important",
                },
                "::-webkit-scrollbar-track": {
                  backgroundColor: "#e1e1f3 !important",
                },
              }}
            />
          )}
          </>}
        </Box>
      </Box>
    </Modal>
  );
}
