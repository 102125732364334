import * as React from "react";
import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { RoleList } from "../../Constants/projectspecificmodules.constants";
import { API } from "../../api-services";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";

export interface IEditRoleProps {
  action: any;
  itemId?: any;
  roleOptions: any;
  submodule?: any;
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export function EditGeneralRole(props: IEditRoleProps) {
  const { action, itemId, roleOptions, submodule } = props;
  const { moduleId } = useRouteParams<any>();
  const [roles, setRoles] = React.useState<any>([]);
  React.useEffect(() => {
    if (action) {
      setRoles(action);
    }
  }, [action]);
  const handleSelectChangeRole = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    if(value.length === 0){
        setRoles((prev:any)=>prev);
        return;
    }
    if(value.length === 0){
        setRoles((prev:any)=>prev);
        return;
    }
    setRoles(value);
    API.put(
      "/auth/assign_permission/",
      {
        ids: itemId,
        action: value,
        submodule: submodule,
        module: "General",
      },
      {},
      0
    )
      .then((res: any) => {})
      .catch((err: any) => {});
  };
  return (
    <div>
      <FormControl fullWidth variant="standard">
        {/* <InputLabel id="demo-simple-select-label">
                          Select roles
                        </InputLabel> */}
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={roles && roles}
          multiple
          multiline
          label="Role"
          placeholder="Select Role"
          onChange={handleSelectChangeRole}
          size="small"
          sx={{
            borderBottom: "1px solid",
            borderBottomColor: "primary.main",
            "& .MuiFormLabel-root.Mui-focused": {
              color: "primary.main",
            },
            "&:before": {
              borderBottomColor: "white",
            },
          }}
          renderValue={(selected) => (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 0.5,
              }}
            >
              {roleOptions
                .filter((item: any) => selected?.includes(item?.value))
                ?.map((value: any) => (
                  <Chip
                  size="small"
                    key={value.value}
                    label={value.value}
                    onDelete={() => {
                    if(roles?.length>1){
                      setRoles((prev: any) =>
                        prev?.filter((item: any) => value.value !== item)
                      );
                      API.put(
                        "/auth/assign_permission/",
                        {
                          ids: itemId,
                          action: roles?.filter(
                            (item: any) => value.value !== item
                          ),
                          submodule: submodule,
                          module: "General",
                        },
                        {},
                        0
                      )
                        .then((res: any) => {})
                        .catch((err: any) => {});
                    }}}

                    deleteIcon={
                      <CancelIcon
                        onMouseDown={(event) => event.stopPropagation()}
                      />
                    }
                  />
                ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {roleOptions &&
            roleOptions?.map((item: any, index: number) => {
              return (
                (!roles.includes(item?.value)&&<MenuItem key={index} value={item?.value}>
                  {item.text}
                </MenuItem>)
              );
            })}
        </Select>
      </FormControl>
    </div>
  );
}
