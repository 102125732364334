import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";

import styles from "../ViewComponents.module.scss";

import BomViewModule from "../BomViewModule";

export interface BomViewDataProps {

}

const BomViewData = (props: BomViewDataProps) => {
   
    const {projectId,topVault,vault,treeabbr,leafNode,}=useParams<any>()
    const history=useHistory<any>()
  
    const [expendbom, setExpendBom] = useState<any>(false)
    const togglebom=sessionStorage.getItem("bomModule")
   
  
   
  
  
    const OpenExpendHanler = () => {
      setExpendBom(true)
      // @ts-ignore
      sessionStorage.setItem("bomModule",true)
     history.push(`/view/${projectId}/${topVault}/${vault}/${treeabbr}/true`)
      
    }
  
    const ClosedExpendHandler = () => {
      setExpendBom(false)
        // @ts-ignore
      sessionStorage.setItem("bomModule",false)
      history.push(`/view/${projectId}/${topVault}/${vault}/${treeabbr}/false`)
    }
   
  return (
    <div className={styles.submodulesContainer}>
     
        <div className={styles.partLevelContainer}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3">         
         { vault &&  <BomViewModule/>}
      </div>
     
    </div>
  );
};
export default BomViewData;
