import React, { useEffect, useState } from "react";
import {
    Backdrop,
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Modal,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Skeleton,
    Tooltip,
    CircularProgress,
    Fade,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { API } from "../../api-services";
import Swal from "sweetalert2";
import { LoadingButton } from "@mui/lab";
import { ADMIN } from "../../Redux/Services/admin.service";

interface Item {
    id: number;
    name: string;
    oldCost: number;
    newCost: number;
}

export interface ICostmodelSyncResultTableProps {
    onCloseModal: any;
    isOpen: any;
    selectedIdTree?: any;
    setIsSyncRecomended: any;
    setParentCount?: any;
}


const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "80vw",
    bgcolor: "background.paper",
    borderRadius: "10px",
    p: 2,
};

export function CostmodelSyncResultTable(props: ICostmodelSyncResultTableProps) {
    const { onCloseModal, isOpen, selectedIdTree, setIsSyncRecomended, setParentCount, } = props;
    const handleSync = (itemId: number) => {
        // console.log(`Syncing item with ID: ${itemId}`);
        // Add your sync logic here
    };
    const [loading, setLoading] = useState<any>(false);
    const [data, setData] = useState<any>([]);
    const [syncLoader, setSyncLoader] = useState(false);
    const [syncLoader2, setSyncLoader2] = useState(false);
    const [selectedEstimationIds, setSelectedEstimationIds] = useState<any>([]);
    const [activeButton, setActiveButton] = useState<string | null>(null);

    const [isCostSync, setIsCostSync] = useState<any>(false);
    const [syncButtonAPICall, setSyncButtonAPICall] = useState<any>(true);
    const [syncButtonColor, setSyncButtonColor] = useState<any>("");

    const handleCheckboxChange = (estimationId: number) => {
        setSelectedEstimationIds((prev: any) => {
            if (prev.includes(estimationId)) {
                // Remove the id if it's already selected
                return prev.filter((id: any) => id !== estimationId);
            } else {
                // Add the id if it's not selected
                return [...prev, estimationId];
            }
        });
    };

    const handleSelectAllChange = (
        event: React.ChangeEvent<HTMLInputElement>
    ) => {
        if (event.target.checked) {
            const allVaultIds = data.map((item: any) => item.vault_id);
            setSelectedEstimationIds(allVaultIds);

        } else {
            setSelectedEstimationIds([]);
        }
        setActiveButton(null)
    };

    useEffect(() => {
        setLoading(true);
        API.get("/cost/cost_calculator_update/", { calculator: +selectedIdTree }, 0).then((res: any) => {
            setData(res.data);
            setSyncButtonColor("")
            setIsSyncRecomended(false)
            setLoading(false);
            setParentCount((prev: any) => prev + 1);
        }).catch((err: any) => {
            setLoading(false)
        })
    }, []);


    const handleValidation = () => {
        setSyncLoader(true);
        API.put(
            `cost/calculator/${selectedIdTree}/`,
            { vault_list: selectedEstimationIds, remove_cost_validation: true },
            0
        )
            .then(() => {
                setSyncLoader(false);
                ADMIN.toast.info("Synced successfully");
                setParentCount((prev: any) => prev + 1);
                onCloseModal();
            })
            .catch((err) => {
                setSyncLoader(false);
                console.log(err);
            })
            .finally(() => {
                setSyncLoader(false);
            });
    };

    const handlewithoutValidation = () => {
        setParentCount((prev: any) => prev + 1);
        onCloseModal();
    }

    const handleButtonClick = (type: string) => {
        // Filter items based on delta value
        const filteredItems = data.filter((item: any) =>
            type === "zeroDelta" ? item.delta === 0 : item.delta > 0
        );

        // Update selectedEstimationIds with vault_id of filtered items
        const newSelectedIds = filteredItems.map((item: any) => item.vault_id);
        setSelectedEstimationIds(newSelectedIds);

        // Toggle active button state
        setActiveButton(type === activeButton ? null : type);
    };



    return (
        <Modal
            open={isOpen}
            // onClose={onCloseModal}
            aria-labelledby="transition-modal-title"
            aria-describedby="transition-modal-description"
            closeAfterTransition
            slots={{ backdrop: Backdrop }}
            slotProps={{
                backdrop: { timeout: 500 },
            }}
        >
            <Box sx={style}>
                <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: "0.5rem" }}>

                    <Typography variant="h6" component="h2" gutterBottom>
                        Affected Parts Due to Cost Model Modification
                    </Typography>

                    {data?.length > 0 && <Box sx={{ display: "flex", gap: "0.5rem" }}>
                        <Button
                            variant={activeButton === "zeroDelta" ? "contained" : "outlined"}
                            sx={{ color: activeButton === "zeroDelta" ? "primary" : "default" }}
                            onClick={() => handleButtonClick("zeroDelta")}
                        >
                            Parts with 0 delta {activeButton === "zeroDelta" && `(${selectedEstimationIds?.length})`}
                        </Button>
                        <Button
                            variant={activeButton === "positiveDelta" ? "contained" : "outlined"}
                            sx={{ color: activeButton === "positiveDelta" ? "primary" : "default" }}
                            onClick={() => handleButtonClick("positiveDelta")}
                        >
                            Select all parts with delta more than 0 {activeButton === "positiveDelta" && `(${selectedEstimationIds?.length})`}
                        </Button>
                    </Box>}


                </Box>

                <TableContainer
                    // component={Paper}
                    sx={{ height: { lg: "62vh", xl: "61vh" } }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3"
                >
                    {loading ? (
                        <Box
                            sx={{
                                width: "100%",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                flexDirection: "column",
                                marginTop: "3rem",
                                height: "25rem",
                            }}
                        >
                            <Box sx={{ height: 40, marginBottom: '2rem' }}>
                                <Fade
                                    in={loading}
                                    style={{
                                        transitionDelay: loading ? '800ms' : '0ms',
                                    }}
                                    unmountOnExit
                                >
                                    <CircularProgress />
                                </Fade>
                            </Box>
                            <Typography>Generating delta report...</Typography>
                        </Box>
                    ) : (
                        <Table>
                            <TableHead>
                                <TableRow
                                    sx={{
                                        backgroundColor: "primary.main",
                                        color: "common.white",
                                    }}
                                >
                                    <TableCell sx={{ padding: "0.3rem 0.5rem", color: "#fff" }}>
                                        <Tooltip title="Select All">
                                            <Checkbox
                                                sx={{
                                                    padding: '5px',
                                                    color: "#fff",
                                                    "&.Mui-checked": {
                                                        color: "#fff", // Maintain white color when checked
                                                    },
                                                    "&.Mui-checked:hover": {
                                                        backgroundColor: "transparent", // Prevent hover background effect
                                                    },
                                                }}
                                                checked={
                                                    selectedEstimationIds.length === data.length &&
                                                    data.length > 0
                                                }
                                                onChange={handleSelectAllChange}
                                            />
                                        </Tooltip>
                                    </TableCell>
                                    <TableCell sx={{ borderBottomColor: 'primary.light', padding: "0.3rem 0.5rem", color: "#fff" }}>
                                        Part Name
                                    </TableCell>
                                    <TableCell sx={{ borderBottomColor: 'primary.light', padding: "0.3rem 0.5rem", color: "#fff" }}>
                                        Part No.
                                    </TableCell>
                                    <TableCell sx={{ borderBottomColor: 'primary.light', padding: "0.3rem 0.5rem", color: "#fff" }}>
                                        Old Cost (INR)
                                    </TableCell>
                                    <TableCell sx={{ borderBottomColor: 'primary.light', padding: "0.3rem 0.5rem", color: "#fff" }}>
                                        New Cost (INR)
                                    </TableCell>
                                    <TableCell sx={{ borderBottomColor: 'primary.light', padding: "0.3rem 0.5rem", color: "#fff" }}>
                                        Delta (INR)
                                    </TableCell>
                                </TableRow>
                            </TableHead>

                            <TableBody>
                                {Array.isArray(data) && data?.length > 0 ? (
                                    data?.map((item: any) => (
                                        <TableRow key={item.id}>
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    sx={{ padding: '5px', }}
                                                    checked={selectedEstimationIds.includes(
                                                        item.vault_id
                                                    )}
                                                    onChange={() => handleCheckboxChange(item.vault_id)}
                                                />
                                            </TableCell>
                                            <TableCell sx={{ padding: "0.3rem 0.5rem", }}>{item?.name}</TableCell>
                                            <TableCell sx={{ padding: "0.3rem 0.5rem", }}>{item?.part_no}</TableCell>
                                            <TableCell sx={{ padding: "0.3rem 0.5rem", }}>{item?.old_cost}</TableCell>
                                            <TableCell sx={{ padding: "0.3rem 0.5rem", }}>{item?.new_cost}</TableCell>
                                            <TableCell sx={{ padding: "0.3rem 0.5rem", }}><Tooltip title={item?.delta} placement="top-start"><Typography>{item?.delta.toFixed(2)}</Typography>
                                            </Tooltip> </TableCell>
                                        </TableRow>
                                    ))
                                ) : (
                                    <TableRow sx={{ width: "100%" }}>
                                        <TableCell colSpan={6} sx={{ borderBottomColor: 'transparent' }}>
                                            <Box sx={{
                                                display: "flex",
                                                justifyContent: "center",
                                                flexDirection: "column",
                                                gap: "2rem",
                                                alignItems: "center",
                                                height: "10rem",
                                            }}>
                                                <Typography>No data found</Typography>
                                                <Button                                                    
                                                    variant="contained"
                                                    color="primary"
                                                    onClick={onCloseModal}
                                                > Close</Button>
                                            </Box>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    )}
                </TableContainer>

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        marginTop: "1rem",
                    }}
                >
                    {data?.length > 0 && <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
                        {selectedEstimationIds?.length === 0 && <LoadingButton

                            loading={syncLoader2}
                            variant="outlined"
                            color="primary"
                            onClick={handlewithoutValidation}
                        >
                            Proceed without removing validation
                        </LoadingButton>}
                        <LoadingButton
                            disabled={selectedEstimationIds?.length === 0}
                            loading={syncLoader}
                            variant="contained"
                            color="primary"
                            onClick={handleValidation}
                        >
                            Proceed by removing validation
                        </LoadingButton>
                    </Box>}
                </Box>
            </Box>
        </Modal>
    );
}

export default CostmodelSyncResultTable;
