// react
import React, { useState } from "react";
import { QrReader } from "react-qr-reader";
import Modal from "@mui/material/Modal";

// styles
// import styles from "./ScanQRModal.module.scss";
import styles from "./ScanQRModal.module.scss";
import { Box, Divider, Typography } from "@mui/material";
import { useHistory, useRouteMatch } from "react-router-dom";
import { API } from "../../api-services";
import { Cancel, Countertops } from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import { useScroll } from "@react-spring/web";
import ClearIcon from '@mui/icons-material/Clear';
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import ReplyIcon from '@mui/icons-material/Reply';
import QrCodeIcon from '@mui/icons-material/QrCode';
import CostingQRModal from "./CostingQRModal";
import BOMDataEntryQRModal from "./BOMDataEntryQRModal";
import { useBaseModal } from "../SearchPage/useBaseModal";
import useWindowDimensions from "../../ui-reusable-component/useWindowDimensions";
import { AxiosError, AxiosResponse } from "axios";
import ScanQRModal from "./ScanQRModal";

// interfaces and types

// lazy

// ----------------------------------------------------------------------------------

interface QRRedirectionModalProps {
  isOpen: boolean;
  onClose: () => void;

  changeRootId?: any;
}

const QRRedirectionModal: React.FC<QRRedirectionModalProps> = (props) => {
  const { isOpen, onClose, changeRootId } = props;
  const { url } = useRouteMatch<any>();
  const [CounterData, setCounterData] = useState<any>(0);
  var Module = localStorage.getItem("ModuleName");
  const history = useHistory();
  const BOMDataEntryQRHandler = useBaseModal()
  const CostingQRHandler = useBaseModal()
  const { height, width } = useWindowDimensions();
  const [openCostingModal, setOpenCostingModal] = useState<boolean>(false);
  const ScannerModalOpen = useBaseModal();

  // console.log(Module, "serachUrl");
  // ----------------------------------------------------------------------------------
  // handlers


  // ----------------------------------------------------------------------------------
  // jsx
  const videoframestyle = {
    height: 'auto',
    width: '45vw',
  }

  const OpenScannerModal = (e:any) => {
    e?.stopPropagation()
    ScannerModalOpen.open();
  };

  const CostingQRModalHandler = (e:any) => {
    e?.stopPropagation();
    CostingQRHandler.open();
    
  }
  const BOMDataEntryModal = (e:any) => {
    e?.stopPropagation()
    
    BOMDataEntryQRHandler.open()
  }
  const handleCompanionView = () => {
    API.get(
        `/web_companion/companion_project/`,
        {
            companion_link: true,
        },
        0
    )
    .then((res: AxiosResponse) => {
        
        window.open(res?.data?.companion_link, "_blank")
    })
    .catch((error: AxiosError) => {
        console.log(error)
    })
  }

  const handleCloseBOM = () => {
    BOMDataEntryQRHandler.close();
    onClose();
  }

  const handleCloseCosting = () => {
    CostingQRHandler.close();
    onClose();
  }
  return (
    <>
      <Modal
        open={isOpen}
        onClose={onClose}
      >
        <Box sx={{backgroundColor: "#fff", width: {xs: "90%", lg: "40%"}, height:'fit-content', position: "absolute", left:{xs: "5%", lg: "30%"}, top:{xs: "10%", lg: "27.5%"}, borderRadius:"10px" }}>
          <Box sx={{ width: '100%', height: '100%', display:"flex", flexDirection: "column" }}>
            <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
              <Typography
                style={{
                  fontSize: "1.2rem",
                  fontWeight: "600",
                  width: "100%",
                  textAlign: "center",
                  marginRight: '-2rem'
                }}
              >
                Easy Redirection (QR Scanner)
              </Typography>
              <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={() => onClose()} />
            </Box>
            <Divider
              sx={{
                borderColor: "primary.light",
                margin: "0 1rem",
                // marginBottom: "1rem",
              }}
            />
            <Box sx={{  padding:"2rem",}}
             adl-scrollbar="true"
             adl-scrollbar-width="0.3">
              <Box sx={{ display: "flex", flexDirection: "column", gap:"1rem" , alignItems: "center",height:{xs:'30rem',sm:'auto'}}}
              adl-scrollbar="true"
              adl-scrollbar-width="0.3">
                <Box sx={{width: "100%", display:"flex", flexDirection:{xs:"column", lg: "row"}, gap: "1rem" }}>
                <Box sx={{ width: {xs:"100%", lg:"50%"},  fontSize: "1rem", fontWeight: "600", display: "flex", flexDirection:"column",  flexGrow:"1", alignItems: "center",  backgroundColor: "#ddefff", borderRadius: "10px", padding: "1rem 0.5rem"}} onClick={(e:any) => BOMDataEntryModal(e)}>
                  
                  {width >  650 ? "BOM Data Entry" : "BOM Media Entry"}
                  <QrCodeIcon
                          
                          titleAccess="BOM Data Entry"
                          sx={{ cursor: "pointer", fontSize:"6rem"}}
                        />
                      {BOMDataEntryQRHandler.isOpen && <BOMDataEntryQRModal isOpen={BOMDataEntryQRHandler.isOpen} onClosess={handleCloseBOM} />}
                
                </Box>
                <Box sx={{ width: {xs:"100%", lg:"50%"}, fontSize: "1rem", fontWeight: "600", display: "flex", flexDirection:"column",   alignItems: "center",  backgroundColor: "#ddefff", borderRadius: "10px", padding: "1rem 0.5rem"}} onClick={(e:any) => CostingQRModalHandler(e)}>
                Zero Based Costing
                  <QrCodeScannerIcon
                          
                          titleAccess="Zero Based Costing"
                          sx={{ cursor: "pointer", fontSize:"6rem", color: "#FFBD33"}}
                        />
                        {CostingQRHandler.isOpen && <CostingQRModal isOpen={CostingQRHandler.isOpen}  onClose={handleCloseCosting} />}
                </Box>
                
                </Box>
                <Box sx={{width: "100%", display:"flex", flexDirection:{xs:"column", lg: "row"}, gap: "1rem" }}>
                
                
                <Box sx={{ width: {xs:"100%", lg:"50%"}, fontSize: "1rem", fontWeight: "600", display: "flex", flexDirection:"column",   alignItems: "center",  backgroundColor: "#ddefff", borderRadius: "10px", padding: "1rem 0.5rem"}} onClick={(e:any) => OpenScannerModal(e)}>
                View Data
                  <QrCodeScannerIcon
                          
                          titleAccess="View Data"
                          sx={{ cursor: "pointer", fontSize:"6rem", color: "#FF8733"}}
                        />
                        {ScannerModalOpen.isOpen && <ScanQRModal
                        isOpen={ScannerModalOpen.isOpen}
                        onClose={ScannerModalOpen.close}
                        // module={ module }
                        // changeRootId={ changeRootId }
                      />}
                </Box>
                <Box sx={{width: {xs:"100%", lg:"50%"}, ffontSize: "1rem", fontWeight: "600", display: "flex", flexDirection:"column",  alignItems: "center",  backgroundColor: "#ddefff", borderRadius: "10px", padding: "1rem 0.5rem"}} onClick={handleCompanionView}>
                Companion View
                  <ReplyIcon
                          
                          titleAccess="Companion View"
                          sx={{ cursor: "pointer", fontSize:"6rem", color: "#EB33FF" , transform: "scaleX(-1)"}}
                        />
                </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default QRRedirectionModal;
