import React, { useEffect, useState } from "react";
import styles from "./BomCategories.module.scss";
import { useHistory, useRouteMatch } from "react-router-dom";
import { API } from "../../api-services";
import { Badge, Box, Checkbox, CircularProgress, Divider, Drawer, IconButton, Typography } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import useWindowDimensions from "../../ui-reusable-component/useWindowDimensions";
import { DataGrid, GridToolbarContainer, GridToolbarExportContainer, GridCsvExportMenuItem, GridCsvExportOptions, GridToolbarColumnsButton } from "@mui/x-data-grid";
import Button, { ButtonProps } from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import moment from "moment";
import { useBaseModal } from "../../ComponentUI/SearchPage/useBaseModal";
import BomCategoryAddModel from "./BomCategoryAddModel";
import BomCategoryEditModel from "./BomCategoryEditModel";
import { useBomCategoryEditModel } from "./useBomCategoryEditModel";
import swal from "sweetalert";
import InfoIcon from "@mui/icons-material/Info";
import { useBomCategoryUse } from "./useBomCategoryUse";
import BomCategoryUseList from "./BomCategoryUseList";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import recyleBin from "../../Assets/images/deletefill.svg";
import AddIcon from "@mui/icons-material/Add";
import DataBaseDrawer from "../DataBaseDrawer";
import { useDocumentTitle } from "../../ui-reusable-component/useDocumentTitle";
import LockIcon from "@mui/icons-material/Lock";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import Swal from "sweetalert2";
import { AUTH } from "../../Redux/Services/auth.service";
import { userSelectors } from "../../Redux/Reducers/user.reducer";
import { useSelector } from "react-redux";


type Anchor = "top" | "left" | "bottom" | "right";

const StyledGridOverlay = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  justifyContent: "center",
  height: "100%",
  "& .ant-empty-img-1": {
    fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
  },
  "& .ant-empty-img-2": {
    fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
  },
  "& .ant-empty-img-3": {
    fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
  },
  "& .ant-empty-img-4": {
    fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
  },
  "& .ant-empty-img-5": {
    fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
    fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false">
        <g
          fill="none"
          fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g
            className="ant-empty-img-4"
            transform="translate(149.65 15.383)">
            <ellipse
              cx="20.654"
              cy="3.167"
              rx="2.849"
              ry="2.815"
            />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Rows</Box>
    </StyledGridOverlay>
  );
}

const csvOptions: GridCsvExportOptions = {
  fileName: "Database (BOM Categories) In CSV",
  delimiter: ",",
  utf8WithBom: true,
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = (addBomCategoryData: any, redirectToRecycleBin: any, count: any, useraction: any) => (
  <GridToolbarContainer sx={{ height: "2rem", marginBottom: { lg: '0.8rem', xl: '0.5rem' }, marginTop: { lg: '-1.5rem', xl: '-0.9rem' } }}>
    <span style={{ position: "absolute", right: "0rem", fontSize: "1rem", paddingRight: '0.5rem', display: 'flex', columnGap: '1rem', alignItems: 'center' }}>
      {useraction && useraction?.includes("C") && (
        <Button style={{ fontSize: "0.9rem", paddingRight: '1rem' }} startIcon={<AddIcon sx={{ marginRight: '-0.5rem' }} />} onClick={() => addBomCategoryData.open()}>Add Category</Button>
      )}
      <GridToolbarColumnsButton />
      <CustomExportButton />
      {count > 0 ?
        <IconButton sx={{ padding: '0', margin: '0 0.5rem' }} aria-label="Recycle Bin" onClick={redirectToRecycleBin}>
          <Badge badgeContent={count} color="error">
            <img src={recyleBin} style={{ height: '1.6rem' }} />
          </Badge>
        </IconButton> :
        <IconButton sx={{ padding: '0', margin: '0 0.5rem', marginTop: '-0.5rem' }} type="button" aria-label="Recycle Bin" onClick={redirectToRecycleBin} >
          <DeleteOutlineIcon sx={{ color: "primary.main" }} />
        </IconButton>
      }
    </span>
  </GridToolbarContainer>
);

interface BomCategoriesI { }

const BomCategories: React.FC<BomCategoriesI> = (props) => {
  useDocumentTitle("BOM Categories");
  const [categoriesData, setCategoriesData] = useState<any>();
  const [categoriesDataLoader, setCategoriesDataLoader] = useState<boolean>(true);
  const [reloadBomCategoriesData, setReloadBomCategoriesData] = useState<boolean>(true);
  const [binCount, setBinCount] = useState<number | undefined>();
  const [binCountApiCall, setBinCountApiCall] = useState<boolean>(true);

  const { width } = useWindowDimensions();
  const addBomCategoryData = useBaseModal();
  const editBomCategoryData = useBomCategoryEditModel();
  const listBomCategoryUse = useBomCategoryUse();
  const history = useHistory();
  const [useraction, setuseraction] = useState<any>(null)
  const { url } = useRouteMatch();
  const [loader, setLoader] = React.useState<any>(false);
  const [Counter, setCounter] = useState<any>(0);
  const [rowId, setRowId] = useState<any>(0);

  const userProfilePic = useSelector(userSelectors.selectAll);
  
  React.useEffect(() => {
    API.get(
      "/auth/calibration_user_permission/",
      {
        action: true,
        database: true,
        module: "DB-BOM Categories"
      },
      0
    )
      .then((res: any) => {
        setuseraction(res.data.action)
        sessionStorage.setItem("DBPermission", res.data.action);
      })
      .catch((err: any) => { });
  }, []);

  useEffect(() => {
    setCategoriesDataLoader(true);
    API.get("/api/db/category_newui_View/", {}, 0).then((res: any) => {
      setCategoriesData(res?.data);
      setCategoriesDataLoader(false);
    });
  }, [reloadBomCategoriesData, Counter]);

  useEffect(() => {
    setCategoriesDataLoader(true);
    API.get("/auth/mui_trash_view/", { bin_type: "category", count: true }, 0).then((res: any) => {
      setBinCount(res?.data?.count);
    });
  }, [binCountApiCall]);

  const EditBomCategoryPopUp: any = (id: any, name: any, prefix: any, postfix: any, color: any, is_leaf: any) => {
    editBomCategoryData.open(id, name, prefix, postfix, color, is_leaf);
  };

  const redirectToRecycleBin = () => {
    history.push("/data-base/recycleBinBom");
  };

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === "keydown" && ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
    // onClick={toggleDrawer(anchor, false)}
    // onKeyDown={toggleDrawer(anchor, false)}
    >
      <DataBaseDrawer />
    </Box>
  );

  // handleDeleteTableData is used for deleting a single row data in Datagrid

  const handleDeleteBomCategory = async (e: React.MouseEvent, id: number) => {
    e.stopPropagation();
    swal({
      title: `Delete BOM Category`,
      text: `Are you sure?`,
      icon: "error",
      //@ts-ignore
      buttons: {
        cancel: "Cancel",
        permanent: {
          text: `Delete BOM Category`,
          value: `deleteBomCategory`,
        },
      },
      closeOnClickOutside: false,
      closeOnEsc: false,
      className: "delete-swal",
    }).then(async (type: string) => {
      switch (type) {
        case "deleteBomCategory":
          API.delete("/api/db/category/" + id + "/", { trash: true }, 0)
            .then((res: any) => {
              setReloadBomCategoriesData(!reloadBomCategoriesData);
              setBinCountApiCall(!binCountApiCall);
            })
            .catch((err: any) => {
              console.log(err, "Unable to upload file. Server Error");
            });
          break;
        default:
          swal(`Data saved from deleting`, { icon: "error" });
      }
    });
  };

  const lockHandler = (rows: any) => {
    setRowId(rows?.id);
    Swal.fire({
      title: rows?.lock == true ? "Unlock" : "Lock",
      text:
        rows?.lock == true
          ? "Are you sure you want to Unlock this parameter?"
          : "Are you sure you want to lock this parameter?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result: any) => {
      if (result.isConfirmed) {
        setLoader(true);
        API.put(`/api/db/category/${rows?.id}/`, {
          lock: rows?.lock == true ? false : true,
        }).then((res: any) => {
          // setParentsCounter((prev: any) => prev + 1);
          setCounter((prev: any) => prev + 1);
          // setEditCounter((prev: any) => prev + 1);
          setLoader(false);
        })
          .catch((err: any) => {
            console.log("Server Error");
            setLoader(false);
          });
      }
    });
  };


  const handleInfoBomCategory = (id: any) => {
    listBomCategoryUse.open(id);
  };

  // columns is a variable where column data is stored. This columns is then used as a prop in dataGrid.

  let columns: any = [
    { field: "sno", headerName: "SNo.", width: width > 1370 ? 50 : width < 900 ? 50 : 50 },
    {
      field: "name",
      headerName: "Name",
      headerAlign: "left",
      //   align: 'right',
      minWidth: width > 1370 ? 100 : width < 900 ? 120 : 200,
      flex: 1,
    },
    { field: "prefix", headerName: "Prefix", width: width > 1370 ? 120 : 100 },
    {
      field: "postfix",
      headerName: "Postfix",
      width: width > 1370 ? 120 : 100,
      //   flex: 1,
    },
    {
      field: "color",
      headerName: "Color",
      minWidth: width > 1370 ? 120 : 80,
      renderCell: (cellValues: any) => {
        return (
          <div>
            <span style={{ backgroundColor: cellValues?.row?.color, padding: "2rem", color: cellValues?.row?.color == "" ? "white" : cellValues?.row?.color }}>.</span>
          </div>
        );
      },
    },
    { field: "is_leaf", headerName: "Is Leaf", width: width > 1370 ? 100 : 80 },
    { field: "created_by_user", headerName: "Created by", minWidth: width > 1370 ? 150 : 120, flex: 1 },
    { field: "updated_by", headerName: "Updated by", minWidth: width > 1370 ? 150 : width < 1200 ? 140 : 120, flex: 1 },
    { field: "updated_at", headerName: "Updated on", minWidth: width > 1370 ? 90 : width < 1200 ? 90 : 120, flex: 1 },
    {
      field: "actions",
      headerName: "Actions",
      width: width > 1370 ? 170 : 130,
      renderCell: (cellValues: any) => {
        console.log("cellValues", cellValues);
        return (
          <>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
              {useraction && useraction?.includes("U") && cellValues?.row?.lock != true ? (
                <IconButton
                  style={{ color: "#007fff", padding: "3px" }}
                  onClick={() => EditBomCategoryPopUp(cellValues?.row?.id, cellValues?.row?.name, cellValues?.row?.prefix, cellValues?.row?.postfix, cellValues?.row?.color, cellValues?.row?.is_leaf)}
                  title="Edit BOM Category">
                  <EditIcon />
                </IconButton>) :
                (<IconButton
                  style={{ color: "#007fff", padding: "3px", opacity: 0.5 }}
                  title="Edit BOM Category">
                  <EditIcon />
                </IconButton>
                )}
              {cellValues?.row?.mapped_status ? (
                <IconButton
                  style={{ color: "#007fff", padding: "3px" }}
                  onClick={() => {
                    handleInfoBomCategory(cellValues?.row?.id);
                  }}
                  title="Place Used (BOM Category)">
                  <InfoIcon />
                </IconButton>
              ) : (
                useraction && useraction?.includes("D") && <IconButton
                  style={{ color: "#007fff", padding: "3px", opacity: cellValues?.row?.lock != true ? 1 : 0.5 }}
                  onClick={(e: any) => {
                    if (cellValues?.row?.lock != true) {
                      handleDeleteBomCategory(e, cellValues?.row?.id);
                    }
                  }}
                  title="Delete BOM Category">
                  <DeleteIcon />
                </IconButton>
              )}

              {cellValues?.row?.id == rowId && loader ?
                <CircularProgress size={18} /> : cellValues?.row?.lock === true ? (
                  // Case 1: If user is SuperUser or ProjectAdmin, show clickable green lock
                  (AUTH.isSuperUser || userProfilePic[0]?.is_project_admin) ? (
                    <LockIcon
                      sx={{ cursor: 'pointer', color: 'green' }}
                      onClick={() => lockHandler(cellValues?.row)}
                    />
                  ) : (
                    // Case 2: If user is not SuperUser or ProjectAdmin, show disabled lock
                    <LockIcon sx={{ opacity: 0.5 }} />
                  )
                ) : (
                  // Case 3: If lock is false and user is SuperUser or ProjectAdmin, show red open lock
                  (AUTH.isSuperUser || userProfilePic[0]?.is_project_admin) && (
                    <LockOpenIcon
                      sx={{ cursor: 'pointer', color: 'red' }}
                      onClick={() => lockHandler(cellValues?.row)}
                    />
                  )
                )}
            </Box>
          </>
        );
      },
    },
  ];

  // In rows which is a array, we are pushing data which is structured in frontEnd so, that we can pass it to dataGrid

  let rows: any = [];

  let dataGridDataGenerator: any =
    categoriesData &&
    categoriesData?.map((ideaItem: any, index: any) => {
      let objShared: any = {
        id: ideaItem?.id,
        sno: index + 1,
        name: ideaItem?.name,
        prefix: ideaItem?.prefix,
        postfix: ideaItem?.postfix,
        color: ideaItem?.color,
        is_leaf: ideaItem?.is_leaf == true ? "YES" : "NO",
        created_by_user: ideaItem?.created_by_user,
        updated_by: ideaItem?.updated_by,
        updated_at: moment(ideaItem?.updated_at).format("DD-MM-YYYY, h:mm a"),
        mapped_status: ideaItem?.mapped_status,
        lock: ideaItem?.lock,
      };
      rows.push(objShared);
    });

  // dataStructureDataGrid is the total data format which is ready to pass to the dataGrid Props

  let dataStructureDataGrid: any = {
    columns: columns,
    rows: rows,
  };

  return (
    <div>
      {addBomCategoryData.isOpen && (
        <BomCategoryAddModel
          isOpen={addBomCategoryData.isOpen}
          onCloseModal={addBomCategoryData.close}
          reloadBomCategoriesData={reloadBomCategoriesData}
          setReloadBomCategoriesData={setReloadBomCategoriesData}
        />
      )}
      {editBomCategoryData.isOpen && (
        <BomCategoryEditModel
          isOpen={editBomCategoryData.isOpen}
          onCloseModal={editBomCategoryData.close}
          id={editBomCategoryData.id}
          name={editBomCategoryData.categoryName}
          prefix={editBomCategoryData.prefixName}
          postfix={editBomCategoryData.postfixName}
          color={editBomCategoryData.colorSelect}
          is_leaf={editBomCategoryData.isLeafSelect}
          reloadBomCategoriesData={reloadBomCategoriesData}
          setReloadBomCategoriesData={setReloadBomCategoriesData}
        />
      )}
      {listBomCategoryUse.isOpen && (
        <BomCategoryUseList
          isOpen={listBomCategoryUse.isOpen}
          onCloseModal={listBomCategoryUse.close}
          id={listBomCategoryUse.id}
        />
      )}
      <div style={{ display: "flex", alignItems: "center", margin: "0 0.5rem" }}>
        <IconButton
          sx={{ cursor: "pointer" }}
          title="Go Back"
          onClick={() => history.goBack()}>
          <ArrowBackIcon
            color="primary"
            titleAccess="Go Back"
          />
        </IconButton>
        <Typography sx={{ marginLeft: "1rem", fontSize: "1rem" }}>Database (BOM Categories)</Typography>
      </div>
      <Box sx={{ display: "flex", alignItems: "flex-start" }}>
        <Box className={styles.expandIconBox}>
          <div className={styles.iconSec}>
            {(["left"] as const).map((anchor) => (
              <React.Fragment key={anchor}>
                <IconButton
                  size="medium"
                  sx={{
                    backgroundColor: "primary.light",
                    color: "primary.main",
                  }}
                  onClick={toggleDrawer(anchor, true)}>
                  <AddIcon sx={{ fontSize: "1.7rem" }} />
                </IconButton>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  className={styles.drawerContainer}
                  PaperProps={{
                    sx: {
                      backgroundColor: "aliceblue",
                    },
                  }}>
                  <Box
                    sx={{ padding: "1rem 0rem", paddingBottom: "0" }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3">
                    <Typography
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "1rem",
                      }}>
                      BOM Categories
                    </Typography>
                    <Divider sx={{ margin: "0.5rem" }} />
                    {list(anchor)}
                  </Box>
                </Drawer>
                <Typography
                  style={{
                    transform: "rotate(270deg)",
                    whiteSpace: "nowrap",
                    marginTop: "40vh",
                    cursor: "pointer",
                    fontSize: "1rem",
                  }}
                  onClick={toggleDrawer(anchor, true)}>
                  BOM Categories
                </Typography>
              </React.Fragment>
            ))}
          </div>
        </Box>
        <Box
          sx={{
            height: { sm: "90vh", md: "89vh", lg: "88vh", xl: "93vh" },
            width: "100%",
            padding: "0 1rem",
            position: "relative",
            top: "-2.5vh",
            "& .super-app-theme--lock": {
              bgcolor: "#c5c5c5",
            },
          }}>
          <DataGrid
            headerHeight={48}
            rowHeight={40}
            {...dataStructureDataGrid}
            loading={categoriesDataLoader}
            disableSelectionOnClick={true}
            components={{
              Toolbar: () => {
                return CustomToolbar(addBomCategoryData, redirectToRecycleBin, binCount, useraction);
              },
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            sx={{
              "& ::-webkit-scrollbar": {
                width: "0.3rem",
                height: "0.3rem",
              },
              "& ::-webkit-scrollbar-thumb": {
                // background: $thumb-color;
                backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                borderRadius: "10px",
              },
              "& ::- webkit-scrollbar-thumb: hover ": {
                // width: "4px",
                backgroundColor: "#045DE9 !important",
              },
              "::-webkit-scrollbar-track": {
                backgroundColor: "#e1e1f3 !important",
              },
              "&.MuiDataGrid-root": {
                border: "none",
              },
              "&.MuiDataGrid-root .MuiDataGrid-cell": {
                borderBottomColor: "primary.light",
              },
            }}
            getRowClassName={(params) =>
              `super-app-theme--${params.row.lock === true
                ? "lock"
                : params.row.mapped_status === true
                  ? "true"
                  : "false"
              }`
            }
            density={"compact"}
            pageSize={100}
            rowsPerPageOptions={[100]}
          />
        </Box>
      </Box>
    </div>
  );
};

export default BomCategories;
