// react
import React, { useState, useRef, useEffect } from "react";
// vendors
// services

// import { getMomColumn, getMomGroup, getMomMeetingColumn } from 'app/Redux/Actions/Mom.action';
// import { byMomAbbreviationSelectors } from 'app/Redux/Reducers/groupingMom.reducer';
// import { bymomDataSaveAbbreviationSelectors } from 'app/Redux/Reducers/momDavaSave.reducer';
// import { byMomDetailSaveAbbreviationSelectors } from 'app/Redux/Reducers/momDetailSave.reducer';
// ui-components
import JoditEditor from "jodit-react";
import ReactDOMServer from "react-dom/server";
import { isEmail, ReactMultiEmail } from "react-multi-email";
// others
// styles
import styles from "./SentMail.module.scss";
import { useSelector } from "react-redux";
import "react-multi-email/style.css";
import MomMailRow from "./MomMailRow";
import {
  getMomColumn,
  getMomGroup,
  getMomMeetingColumn,
} from "../../Redux/Actions/Mom.action";
import { byMomAbbreviationSelectors } from "../../Redux/Reducers/groupingMom.reducer";
import { bymomDataSaveAbbreviationSelectors } from "../../Redux/Reducers/momDaveSave.reducer";
import { byMomDetailSaveAbbreviationSelectors } from "../../Redux/Reducers/momDetailSave.reducer";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import useFetch from "../../Core/CustomHooks/useFetch";
import { USER } from "../../Redux/Services/user.service";
import { useQueryDispatch } from "../../CustomHook/useQueryDispatch";
import { ADMIN } from "../../Redux/Services/admin.service";
import {
  Autocomplete,
  Box,
  Button,
  Chip,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { AUTH } from "../../Redux/Services/auth.service";
import MomData from "../../Redux/Services/Mom.service";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory, useParams } from "react-router-dom";
import sendIcon from "../../Assets/images/send.png";
import { LoadingButton } from "@mui/lab";
import { API } from "../../api-services";
import useWindowDimensions from "../../ui-reusable-component/useWindowDimensions";
import sendMIcon from "../../Assets/images/sendOutline.png";
import { useBaseModal } from "../SearchPage/useBaseModal";
import { PreviewMOMMailModal } from "../ProjectMailModule/PreviewMOMMailModal"
import './SentMail.scss';

// interfaces and types
// lazy
// ----------------------------------------------------------------------------------
interface SentMailProps {
  setFiles: any;
  userMailId: any;
}
interface Params {
  projectId: any;
  groupId: any;
  pointsId: any;
}
const SentMail: React.FC<SentMailProps> = (props) => {
  // const { project, group, meeting } = useRouteParams<Params>();
  const { projectId, groupId, pointsId } = useRouteParams<Params>();
  //@ts-ignore
  const { setFiles, userMailId } = props;
  //states:
  const [emails, setEmails] = useState<any>([]);
  const previewModal = useBaseModal();
  //@ts-ignore
  const [ccemails, setCcEmails] = React.useState<any>([]);
  const [content, setContent] = useState<any>("");
  const [sendMailLoading, setsendMailLoading] = useState<any>(false);
  const editor = useRef(null);
  const history = useHistory();
  const GroupNames = useSelector(byMomAbbreviationSelectors.selectAll);
  const MomDataName = useSelector(
    byMomDetailSaveAbbreviationSelectors.selectAll
  );

  const { emailtype, projectname, productIds, status, ccIds, date } =
    useParams<any>();
  const { height, width } = useWindowDimensions();
  const [UserManualEamil, setUserManualEamil] = useState<any>();
  const [userList, setUserList] = useState<any>([]);
  const [disableProduct, setDisableProduct] = useState<any>(status);
  const [Selceteddate, setSelceteddate] = useState<any>(date);
  const [usersCC, setUsersCC] = React.useState<any>([]);
  const [value, setValue] = React.useState<any>([]);
  const [SubjectData, setSubjectData] = useState<any>("");

  useEffect(() => {
    API.get("/analytics/user_access_list/", { project_id: projectId, meeting: true }).then(
      (res: any) => {
        setUserList(res?.data);
      }
    );
  }, [projectId]);

  useEffect(() => {
    setSelceteddate(date);
    setUsersCC([]);
    setValue([]);
    setDisableProduct(status);
  }, [date]);
  React.useEffect(() => {
    let sub: any = "";
    let filterCall = GroupNames?.filter((item: any) => {
      sub = item?.subject;
    });
    setSubjectData(sub);
  }, [GroupNames]);
  React.useEffect(() => {
    let arr: any = userList.filter((item: any) => emails?.includes(item.email));
    setValue((prev: any) => [...arr]);
  }, [emails, userList]);

  React.useEffect(() => {
    //@ts-ignore
    //@ts-ignore
    if (sessionStorage.getItem("attendees_name")) {
      //@ts-ignore
      setEmails(JSON.parse(sessionStorage.getItem("attendees_name")));
    }
    //@ts-ignore

    //@ts-ignore
  }, [sessionStorage.getItem("attendees_name")]);
  const MomAccName = useSelector(bymomDataSaveAbbreviationSelectors.selectAll);
  const FilteredHeader: any =
    MomAccName &&
    MomAccName?.filter((value: any) => {
      return value?.id == groupId;
    });

  const { 0: fetched, 1: getData } = useFetch<any>({
    method: USER.getAllusers,
    args: [],
    fallBackErrMsg: "Could not fetch Users",
  });

  const { 1: userDetails } = useFetch({
    method: USER.getUsers,
    args: [AUTH.user],
    fallBackErrMsg: "Could not fetch the user Details",
    dependencies: [],
  });

  const openCount: number =
    MomDataName &&
    MomDataName?.filter((values) => {
      return values.status == "open";
    }).length;
  const closedCount: number =
    MomDataName &&
    MomDataName?.filter((values) => {
      return values.status == "closed";
    }).length;
  const userData: any = [];
  const valueUser = FilteredHeader[0]?.attendees
    ?.map((value: number) => {
      const userList: any =
        getData &&
        getData?.users?.filter((user: any) => {
          return value === user?.id;
        });
      return userList && userList[0];
    })
    ?.map((user: any, index: number) => {
      userData.push({ key: index, value: user?.id, text: user?.username });
    });
  useQueryDispatch({
    query: {
      action: getMomGroup,
      arg: {
        projectId,
      },
    },
  });
  useQueryDispatch({
    query: {
      action: getMomMeetingColumn,
      arg: {
        meeting: pointsId,
      },
    },
  });
  useQueryDispatch({
    query: {
      action: getMomColumn,
      arg: {
        project: projectId,
      },
    },
  });
  const textRef = useRef<any>(null);
  // handlers:
  const onChangeEmail = (ed: any) => {
    setEmails(ed);
  };
  const onChangeCcEmail = (ed: any) => {
    setCcEmails(ed);
  };
  const sendMail = (
    emails: any,
    ccemails: any,
    content: any,
    module: any,
    project: any
  ) => {
    setsendMailLoading(true);
    let ccemailId = ccemails?.map((itm: any) => itm.email);
    MomData.post_mailDetails(
      emails,
      ccemailId,
      content,
      43,
      projectId,
      groupId,
      SubjectData
    )
      .then((res: any) => {
        setsendMailLoading(false);
        history.push(`/MinutesofMeeting/${projectId}/${groupId}/${pointsId}/`);
      })
      .catch((e: any) => {
        setsendMailLoading(false);
      });
    // setFiles( 0 );
  };
  const htmlElement = ReactDOMServer.renderToStaticMarkup(
    <>
      <div className={styles.editorInput}>
        <div>
          <div>
            <span>Dear Participants,</span>
            <br />
          </div>

          <p> I hope this email finds you well. Following our {FilteredHeader[0]?.date} meeting, <br />
            I would like to share the minutes of the meeting for your reference and records. Please review the minutes below:
          </p>

        </div>


        <div className={styles.details}>
          {GroupNames && GroupNames.length >= 1 ? (
            <>
              {GroupNames &&
                GroupNames?.filter((grp: any) => {
                  return grp?.id == groupId;
                })?.map((grp: any, index: number) => {
                  return (
                    <>
                      <div className={styles.tabletitle}>
                        {/* { FilteredHeader[0]?.name } | { FilteredHeader[0]?.date } */}
                        {grp.name} {`|`} {FilteredHeader[0]?.name} |{" "}
                        {FilteredHeader[0]?.date}
                      </div>
                      <div className={styles.mailstatus}>
                        <span style={{ marginRight: "1rem", color: "green" }}>
                          {`Closed : ${closedCount && closedCount <= 9 && closedCount != 0
                            ? "0" + closedCount
                            : closedCount
                            }`}
                        </span>
                        <span style={{ marginRight: "1rem", color: "red" }}>
                          {`| Open : ${openCount && openCount <= 9 && openCount != 0
                            ? "0" + openCount
                            : openCount
                            }`}
                        </span>
                        <span style={{ marginRight: "1rem" }}>
                          {`| Points : ${MomDataName &&
                            MomDataName?.length <= 9 &&
                            MomDataName?.length != 0
                            ? "0" + MomDataName?.length
                            : MomDataName?.length
                            }  `}
                        </span>
                      </div>
                    </>
                  );
                })}
            </>
          ) : (
            <></>
          )}
        </div>
        <div className={styles.mailstatustable}>
          <table cellSpacing={0}>
            <tr>
              <td
                style={{
                  width: '2rem',
                  backgroundColor: "#007fff",
                  padding: "5px 10px",
                  border: "1px solid #000",
                  color: "white",
                }}
              >
                S.No
              </td>
              <td
                style={{
                  width: '20rem',
                  backgroundColor: "#007fff",
                  padding: "5px 10px",
                  border: "1px solid #000",
                  color: "white",
                }}
              >
                Point
              </td>
              <td
                style={{
                  width: '6rem',
                  backgroundColor: "#007fff",
                  padding: "5px 10px",
                  border: "1px solid #000",
                  color: "white",
                }}
              >
                View
              </td>
              <td
                style={{
                  width: '10rem',
                  backgroundColor: "#007fff",
                  padding: "5px 10px",
                  border: "1px solid #000",
                  color: "white",
                  whiteSpace: 'nowrap',
                }}
              >
                Responsible
              </td>
              <td
                style={{
                  width: '7rem',
                  backgroundColor: "#007fff",
                  padding: "5px 10px",
                  border: "1px solid #000",
                  color: "white",
                }}
              >
                DOC
              </td>
              <td
                style={{
                  width: '4rem',
                  backgroundColor: "#007fff",
                  padding: "5px 10px",
                  border: "1px solid #000",
                  color: "white",
                }}
              >
                Status
              </td>
              <td
                style={{
                  width: '4rem',
                  backgroundColor: "#007fff",
                  padding: "5px 10px",
                  border: "1px solid #000",
                  color: "white",
                }}
              >
                Remarks
              </td>
            </tr>
            <tbody>
              {MomDataName &&
                MomDataName?.map((data: any, index: number) => {
                  // console.log(data, "data data")
                  return (
                    <MomMailRow data={data} index={index} getData={getData} />
                  );
                })}
            </tbody>
          </table>
        </div>
        <br />
      </div>
      <div>
        <p>
          If there are any corrections, additions, or changes you would like to make to the minutes, kindly reply to this email with your feedback. I will update the minutes accordingly and circulate the finalized version to all participants.
        </p>
        <p>
          Thank you all for your active participation and valuable contributions to the meeting. I look forward to your prompt review and feedback on the minutes.
        </p>
      </div>

      <div style={{ fontSize: "1rem" }}>
        <br />
        <span>Thanks & Regards</span>
        <br />
        <span>
          {userDetails && `${userDetails.first_name} ${userDetails.last_name}`}
        </span>
        <br />
        <span>
          {userDetails && `${AUTH.email}`}
        </span>
        <br />
        <span>
          {userDetails && `${AUTH.phone}`}
        </span>
        <br />
        <span>
          <a href="https://advancedstructures.in/">
            www.advancedstructures.in
          </a>
        </span>
        <br />
      </div>
    </>
  );
  useEffect(() => {
    setContent(htmlElement);
  }, [htmlElement]);

  const backButtonHandler: any = () => {
    history.push(`/MinutesofMeeting/${projectId}/${groupId}/${pointsId}/`);
  };

  const PartNameHandler = (item: any) => {
    setUserManualEamil(item);
  };

  const handleSelectChange = (touser: any) => {
    var ToIds =
      touser &&
      touser?.map((item: any, index: any) => {
        return item?.id;
      });

    // @ts-ignore
    setValue([...touser]);
  };

  const PasteEmailHandler = (data: any) => {
    if (data) {
      let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");

      var EmailValidation = regex.test(data);
      const domain = data?.split("@")?.pop();
      var conditionDomin = domain == "gmail.com" ||
        domain == "yahoo.com" ||
        domain == "hotmail.com";
      if (EmailValidation) {
        if (
          !conditionDomin
        ) {
          if (domain == "advancedstructures.in") {
            NormalUser(data, domain);
          } else {
            NormalUser(data, domain);
          }
        }
      }
    }
  };

  const NormalUser = (userData: any, domain: any) => {
    if (domain == "advancedstructures.in") {
      var newdata = { email: userData, full_name: "", user_color: "#a6f0b9" };
      var emailfilter = userList && userList?.filter((item: any, index: any) => { return item?.email !== userData })

      //  console.log(emailfilter,"emailfilteremailfilter")
      setUserList([newdata, ...emailfilter]);
    } else {
      var newdata = { email: userData, full_name: "", user_color: "#fbe5d6" };
      var emailfilter = userList && userList?.filter((item: any, index: any) => { return item?.email !== userData })
      setUserList([newdata, ...emailfilter]);
    }
  };

  var TouserListOption = () => {
    // console.log(userList,usersCC,"bjmghjfsjhmvjhdfjfhjfd")
    let ccemailId = usersCC?.map((itm: any) => itm.email);
    return (
      userList &&
      userList?.filter((itm: any) => !ccemailId?.includes(itm.email))?.map((item: any, index: any) => {
        return { ...item, id: index + 1 };
      })
    );
  };

  const handleSelectChangeCC = (ccuser: any) => {
    // @ts-ignore

    var CCIds =
      ccuser &&
      ccuser?.map((item: any, index: any) => {
        return item?.id;
      });

    //  history.push(
    //    `/mail-module/${emailtype}/${projectname}/${projectId}/${SelectedIds?.toString()}/${toIds}/${CCIds?.toString()}/${Selceteddate}`
    //  )
    setUsersCC([...ccuser]);
    setCcEmails([...ccuser]);
  };

  const ManualEmailHandler = (e: any) => {
    if (e.key == "Enter") {
      let regex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");
      var EmailValidation = regex.test(UserManualEamil);
      const domain = UserManualEamil?.split("@")?.pop();
      if (EmailValidation) {
        if (
          domain !== "gmail.com" ||
          domain !== "yahoo.com" ||
          domain !== "hotmail.com"
        ) {
          if (domain == "advancedstructures.in") {
            NormalUser(UserManualEamil, domain);
          } else {
            NormalUser(UserManualEamil, domain);
          }
        }
      }
    }
  };

  const SubjectHandler = (e: any) => {
    setSubjectData(e?.target?.value);
  };

  const maillogdirection = () => {
    window.open(`/#/Project-mail-logs/${projectId}/${5}/${"MOM"}`, "_blank")
  }

  //-----------------------------------------------------------------------------------------------------------
  //jsx:
  return (
    <div data-testid="" className={styles.container}>
      {previewModal.isOpen && emails && value && sendMail && <PreviewMOMMailModal
        isOpen={previewModal.isOpen}
        onCloseModal={previewModal.close}
        Tousers={value && value}
        CCusers={usersCC}
        Subject={SubjectData}
        AttchmentData={[]}
        emailContent={content}
        FinalEmailSendHandler={sendMail}
        projectId={projectId}
        sendMailIds={emails}
      />}
      {/* {successModal.isOpen &&
        <MailSucessModal
          isOpen={successModal.isOpen}
          onCloseModal={successModal.close}
          SendEmail={SendEmail}
          parentsClosed={PreviewModal.close}
          Project={projectId}
          ProjectName={`${projectDetails && projectDetails?.[0]?.name}`}
          emailType={'2'}
        />
      } */}
      <div className={styles.topheader}>
        <span className={styles.goback} onClick={() => console.log("clicked")}>
          <IconButton
            sx={{
              cursor: "pointer",
              width: "3rem",
              height: "3rem",
            }}
            onClick={() => backButtonHandler()}
          >
            <ArrowBackIcon color="primary" titleAccess="Go Back" />
          </IconButton>
          Minutes of Meeting
        </span>
        <div>
          <span className={styles.mailname}>
            {GroupNames && GroupNames.length >= 1 ? (
              <>
                {GroupNames &&
                  GroupNames?.filter((grp: any) => {
                    return grp?.id == groupId;
                  })?.map((grp: any, index: number) => {
                    return (
                      <>
                        {/* <span className={styles.title}>
                           {grp.name} |{"MOM Notification"}
                          {FilteredHeader[0]?.date}
                        </span> */}
                      </>
                    );
                  })}
              </>
            ) : (
              ""
            )}
          </span>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', columnGap: '2rem', marginRight: "2rem" }}>
        <Typography variant='body2' sx={{color: "#ef5350"}}>Max 50 recipients*
 </Typography>
          <Button variant="contained" onClick={() => maillogdirection()} size="small">Email logs</Button>
          <img
            src={sendMIcon}
            title="Preview Email"
            style={{
              cursor: "pointer",
              width: "1.7rem",
              opacity:
                value?.length > 0 &&
                  content?.length > 0 &&
                  SubjectData?.length > 0
                  ? "1"
                  : "0.4",
            }}
            onClick={() =>
              previewModal.open()
            }
          />
        </div>
      </div>
      <div className={styles.mail_body}>
        <div className={styles.parents_userContainer}>
          <div className={styles.userContainer}>
            <Typography
              sx={{
                fontSize: "1rem",
                marginRight: "1rem",
                whiteSpace: "nowrap",
                width: "10rem",
                textAlign: "right",
              }}
            >
              To :
            </Typography>
            <Autocomplete
              multiple
              disableClearable
              id="User"
              value={value}
              disabled={true}
              limitTags={6}
              disableCloseOnSelect
              sx={{ width: { lg: "87.5vw", xl: "93vw" } }}
              onInputChange={(event, newInputValue) => {
                PartNameHandler(newInputValue);
              }}
              onChange={(event, newValue) => {
                handleSelectChange(newValue);
              }}
              onPaste={(event: any) => {
                PasteEmailHandler(event.clipboardData.getData("text"));
              }}
              options={TouserListOption() && TouserListOption()}
              // @ts-ignore
              getOptionLabel={(option) => option?.email}
              getOptionDisabled={(option) => usersCC?.length + value?.length >= 50 }

              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    size="small"
                    //  @ts-ignore
                    sx={{
                      color: "primary.main",
                      background: `${option?.user_color}`,
                      fontSize: "1rem",
                    }}
                    // @ts-ignore
                    label={option?.email}
                    {...getTagProps({ index })}
                  // disabled={fixedOptions.indexOf(option) !== -1}
                  />
                ))
              }
              onKeyDown={(e: any) => ManualEmailHandler(e)}
              renderOption={(props, option) => (
                // @ts-ignore
                <li {...props} style={{ fontSize: "1rem", color: option?.user_color == '#a6f0b9' ? '#2b9546' : '#eb8c4b', fontWeight: '500', marginBottom: '1px' }}>
                  {/* @ts-ignore */}
                  {option?.email}
                </li>
              )}  
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  sx={{
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "#007fff",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#007fff",
                    },
                    ".MuiSvgIcon-root": { color: "primary.main" },
                    // @ts-ignore
                    // backgroundColor: `${params?.user_color}`,
                  }}
                //   InputProps={{
                //     // background: `${params?.user_color}`
                //     // @ts-ignore
                //     style: "background-color: ${params?.user_color",

                //  }}
                />
              )}
            />
          </div>
          <div className={styles.userContainer}>
            <Typography
              sx={{
                fontSize: "1rem",
                marginRight: "1rem",
                whiteSpace: "nowrap",
                width: "10rem",
                textAlign: "right",
              }}
            >
              Cc :
            </Typography>
            <Autocomplete
              multiple
              disableClearable
              id="User"
              limitTags={6}
              disableCloseOnSelect
              disabled={disableProduct == "false"  ? true : false}
              sx={{ width: { lg: "87.5vw", xl: "93vw" } }}
              onInputChange={(event, newInputValue) => {
                PartNameHandler(newInputValue);
              }}
              value={usersCC}
              onChange={(event, newValue) => {
                handleSelectChangeCC(newValue);
              }}
              onKeyDown={(e: any) => ManualEmailHandler(e)}
              onPaste={(event: any) => {
                PasteEmailHandler(event.clipboardData.getData("text"));
              }}
              options={TouserListOption() && TouserListOption()}
              // @ts-ignore
              getOptionLabel={(option) => option?.email}
              getOptionDisabled={(option) => usersCC?.length + value?.length >= 50 }

              renderOption={(props, option) => (
                // @ts-ignore
                <li {...props} style={{ fontSize: "1rem", color: option?.user_color == '#a6f0b9' ? '#2b9546' : '#eb8c4b', fontWeight: '500', marginBottom: '1px' }}>
                  {/* @ts-ignore */}
                  {option?.email}
                </li>
              )}  
              // renderOption={(props, option) => (
              //   <Box
              //     component="li"
              //     sx={{
              //       // color:`${option?.user_color}`,

              //       marginBottom: "0.5rem",
              //       background: "white",
              //     }}
              //     {...props}
              //   >
              //     {option.email}
              //   </Box>
              // )}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    //  @ts-ignore
                    size="small"
                    sx={{
                      color: "#007fff",
                      background: `${option?.user_color}`,
                      fontSize: "1rem",
                    }}
                    // @ts-ignore
                    label={option?.email}
                    {...getTagProps({ index })}
                  // disabled={fixedOptions.indexOf(option) !== -1}
                  />
                ))
              }
              // style={{ width: "93vw" }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  sx={{
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "#007fff",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#007fff",
                    },
                  }}
                />
              )}
            />
          </div>
        </div>
        <div className={styles.userContainersubject}>
          <Typography
            sx={{
              fontSize: "1rem",
              marginRight: "1rem",
              whiteSpace: "nowrap",
              width: "10rem",
              textAlign: "right",
            }}
          >
            Subject :
          </Typography>
          <TextField
            disabled={disableProduct == "false" ? true : false}
            value={SubjectData}
            onChange={(e: any) => SubjectHandler(e)}
            // defaultValue="Small"
            size="small"
            variant="standard"
            InputProps={{
              style: { fontSize: "1rem" },
            }}
            sx={{
              width: { lg: "87.5vw", xl: "93vw" },
              "& .MuiInput-underline:before": { borderBottomColor: "#007fff" },
              "& .MuiInput-underline:after": { borderBottomColor: "#007fff" },
            }}
          />
        </div>
        <div style={{ padding: '0 1rem' }}>
          <JoditEditor
            ref={editor}
            value={content}
            className="MomMailEditor"
            onBlur={(newContent) => setContent(newContent)}
          // onChange={(newContent) => setContent(newContent)}
          />
        </div>
      </div>
    </div>
  );
};
// ----------------------------------------------------------------------------------
export default SentMail;
