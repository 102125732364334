import { Autocomplete, Chip, TableCell, TableRow, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useBaseModal } from '../../ComponentUI/SearchPage/useBaseModal';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from "@mui/icons-material/Save";
import { API } from '../../api-services';
import ErrorModalFilesMail from '../../ComponentUI/ProjectMailModule/ErrorModalFilesMail';

import { useParams } from 'react-router-dom';
interface SubststemListcategoryrenderRowsProps {

    rowItem: any;
    Keys: any;
    ProjectList: any;
    setCounter: any;
    useraction?:any;

}

const SubststemListcategoryrenderRows = (props: SubststemListcategoryrenderRowsProps) => {
    const { rowItem, Keys, ProjectList, setCounter,useraction } = props
    const { categoryId, subsystemId, search } = useParams<any>();


    const ErrorModalFiles = useBaseModal();
    const [saveLoader, setsaveLoader] = useState<any>(false);
    const [Name, setName] = useState<any>(rowItem && rowItem?.name);
    const [SucessLoader, setSucessLoader] = useState<any>(false);
    const [ErrorModalFileMsg, setErrorModalFileMsg] = useState<any>(false);
    const [SelectedVehicle, setSelectedVehicle] = useState<any>(ProjectList && ProjectList?.filter((item: any, index: any) => { return rowItem && rowItem?.project.includes(item?.id) }));
    const [Description, setDescription] = useState<any>(rowItem && rowItem?.description);
    const [Abbrivations, setAbbrivations] = useState<any>(rowItem && rowItem?.abbreviation);
    const [NameError, setNameError] = useState<any>(false);
    const [AbbrivationsError, setAbbrivationsError] = useState<any>(false);
    const [Color, setColor] = useState<any>(false)
    const handleChange = (e: any, type: any) => {
        if (type == "Name") {
            setNameError(false);
            setColor(true)
            setName(e?.target?.value?.toUpperCase());
        } if (type == "Abbrivations") {
            setAbbrivationsError(false);
            setColor(true)
            setAbbrivations(e?.target?.value?.toUpperCase());
        } if (type == "Description") {
            setColor(true)
            setDescription(e?.target?.value);
        }
    };
    var OptionVehicle = () => {
        var selecteIdsdata = SelectedVehicle && SelectedVehicle?.map((item: any, index: any) => { return item?.id })

        var filterseletedIds = ProjectList && ProjectList?.filter((item: any, index: any) => { return selecteIdsdata && !selecteIdsdata?.includes(item?.id) })
        return filterseletedIds;
    }
    const handleSelectProject = (item: any) => {
        setColor(true)
        setSelectedVehicle(item)
    }



    useEffect(() => {
        setAbbrivations(rowItem && rowItem?.abbreviation)
        setName(rowItem && rowItem?.name)
        setDescription(rowItem && rowItem?.description)
        var filterseletedIds = ProjectList && ProjectList?.filter((item: any, index: any) => { return rowItem && rowItem?.project?.includes(item?.id) })
        setSelectedVehicle(filterseletedIds)
    }, [rowItem])

    const SaveHandler = () => {

        var testdata = /\S+/.test(Name);

        var testAbbr = /\S+/.test(Abbrivations);


        if (testdata == true && testAbbr == true) {

            setsaveLoader(true)
            var Ids = SelectedVehicle && SelectedVehicle?.map((item: any) => { return item?.id })
            API.put(`/api/db/sub_system/${rowItem && rowItem?.id}/`, { project: Ids, name: Name?.trim(), description: Description, abbrivation: Abbrivations?.trim(), vehicle_types: categoryId }).then((res: any) => {
                // setCounter((prev:any)=>prev+1)
                setName(res?.data?.name)
                setAbbrivations(res?.data?.abbreviation)
                var filterseletedIds = ProjectList && ProjectList?.filter((item: any, index: any) => { return res?.data?.project && res?.data?.project.includes(item?.id) })

                setSelectedVehicle(filterseletedIds)
                setColor(false)
                setsaveLoader(false)
            }).catch((err: any) => {
                setsaveLoader(false)
                setName(Name)
                setAbbrivations(Abbrivations)
                setSelectedVehicle(SelectedVehicle)
                const { data } = err?.response;
                setErrorModalFileMsg(data[0])
                ErrorModalFiles.open()
                setsaveLoader(false)

            })
        }
        if (testdata == false) {
            setNameError(true)
        }
        if (testAbbr == false) {
            setAbbrivationsError(true)
        }


    }


    return (
        <><ErrorModalFilesMail
            isOpen={ErrorModalFiles.isOpen}
            onCloseModal={ErrorModalFiles.close}
            ErrorMsg={ErrorModalFileMsg} />
            <TableRow key={Keys}>
                <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>
                    <TextField
                        type="text"
                        variant='standard'
                        value={Name || ""}
                        error={NameError}
                        onChange={(e: any) => handleChange(e, "Name")}
                        placeholder="Part Name"
                        sx={{
                            ".MuiInputBase-root.MuiInput-root": {
                                fontSize: '1rem !important'
                            },
                            '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                            '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                        }}
                    />
                </TableCell>
                <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>


                    <TextField
                        type="text"
                        variant='standard'
                        disabled={rowItem && rowItem?.mapped_status == true ? true : false}
                        value={Abbrivations || ""}
                        error={AbbrivationsError}
                        
                        onChange={(e: any) => handleChange(e, "Abbrivations")}
                        placeholder="Part Abbreviation"
                        sx={{
                            ".MuiInputBase-root.MuiInput-root": {
                                fontSize: '1rem !important'
                            },
                            '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                            '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                        }}
                    />
                </TableCell>
                <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>

                    <Autocomplete
                        multiple
                        id="multiple-limit-tags"
                        limitTags={4}
                        placeholder="Select vehicle"
                        disableCloseOnSelect
                        //@ts-ignore
                        // focused={rowItem?.rowstatus}
                        options={OptionVehicle() && OptionVehicle()}
                        //@ts-ignore
                        getOptionLabel={(option) => option?.name}
                        // defaultValue={[top100Films[13], top100Films[12], top100Films[11]]}
                        sx={{
                            '.MuiSvgIcon-root': { color: 'primary.main' },
                        }}
                        value={SelectedVehicle && SelectedVehicle}
                        onChange={(event, newValue, situation) => handleSelectProject(newValue)}

                        // @ts-ignore
                        renderTags={(tagValue, getTagProps) => tagValue.map((option, index) => (
                            <Chip
                                size="small"
                                sx={{ background: "#007fff14", fontSize: "1rem" }}
                                //@ts-ignore
                                label={option?.name}
                                {...getTagProps({ index })} />
                        ))}
                        renderOption={(props, option, { selected }) => (
                            <li style={{ fontSize: "1rem" }} {...props}>
                                {/*@ts-ignore */}
                                {option?.name}
                            </li>
                        )}
                        disabled={!useraction && useraction?.includes("U")}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                // @ts-ignore 
                                placeholder={"Select Project"}
                                variant="standard"
                                sx={{
                                    // width: '18rem',
                                    fontSize: "1rem",
                                    ".MuiInputBase-root.MuiInput-root": {
                                        fontSize: '1rem !important'
                                    },
                                    '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                                    '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                                }} />
                        )} />
                </TableCell>
                <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>
                    <TextField
                        type="text"
                        value={Description || ""}
                        variant='standard'
                        onChange={(e: any) => handleChange(e, "Description")}
                        placeholder="Description"
                        sx={{
                            ".MuiInputBase-root.MuiInput-root": {
                                fontSize: '1rem !important'
                            },
                            '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                            '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                        }} />
                </TableCell>
                <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>
                    {useraction && useraction?.includes("U") &&<LoadingButton loading={saveLoader}>
                        <SaveIcon
                            sx={{ color: Color ? "red" : "#007fff", cursor: "pointer" }}
                            onClick={() => SaveHandler()} />
                    </LoadingButton>}
                </TableCell>
            </TableRow></>
    )
}
export default SubststemListcategoryrenderRows;