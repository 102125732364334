import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";

import { Box, Button, Divider, Skeleton, Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import Table from "@mui/material/Table";
import { TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { API } from "../../api-services";
import React, { memo, useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { LoadingButton } from "@mui/lab";
import {
  CellNameOne,
  CellNameSecond,
  CellNameThird,
} from "./BomTemplatedHeader";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import TableRowRander from "./TableRowRander";
import { Tab } from "material-ui";

import bomCalculatorInputService from "../../Redux/Services/bomCalculatorInput.service";
import { BOMConfigTableCalculator } from "../../DataBaseModule/ParameterLibrary/BOMTableCalculatorr";
import { useBaseModal } from "../../ComponentUI/SearchPage/useBaseModal";




const popstyled = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: "80%", xl: "80%" },
  height: { lg: "90vh", xl: "80vh" },
  bgcolor: "background.paper",
  borderRadius: "10px",
  // border: '2px solid #000',
  // boxShadow: 24,
  p: 1,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// @ts-ignore

const useStyles = makeStyles((theme) => ({
  /** Changed modalStyle */
  ModalRoot: {
    backgroundColor: "rgba(111, 126, 140, 0.2) !important",
    backdropFilter: "blur(1px) !important",
  },
}));

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
  transition: "none !important", // Disable the transition animation
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{ fontSize: "0.9rem", color: "primary.main" }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export interface AddParameterModalProps {
  isOpen: any;
  onCloseModal: any;
  setCount?: any;
  setParentCount?: any;
  handleChangeCheckBox: any;
  tableItem: any;
  setUpdatedCounter: any;
}

const TableDataTypeModal = (props: AddParameterModalProps) => {
  const { isOpen, onCloseModal, setCount, setParentCount, handleChangeCheckBox, tableItem, setUpdatedCounter } = props;
  const {
    projectId,
    projectname,
    categoryId,
    productId,
    datatype,
    validationId,
  } = useRouteParams<any>();
  const classes = useStyles();
  const createCostModal = useBaseModal();
  const [costModals, setCostModals] = useState<any>([]);
  const [inpuData, setInputData] = useState<any>(null);
  const [selectedCalculator, setSelectedCalculator] = useState<any>(null);
  const [Loader, setLoader] = useState<any>(false)
  const [Counter, setCounter] = useState<any>(0)
  const [TableData, SetTableData] = useState<any>([])
  useEffect(() => {

    setLoader(true)
    API.get("/config/parameter_mapping/", { table_id: tableItem?.id }).then((res: any) => {
      SetTableData(res?.data)
      setLoader(false)
    }).catch((res: any) => {
      setLoader(false)
    })

    API.get(
      "config/bom_table_calculator_config/",
      { parameter_relation_id: tableItem?.id,
        },
      0
    )
      .then((res: any) => {
        
        

        setCostModals(res.data)
      })
      .catch((err: any) => {
      
      });
  }, [tableItem, Counter])

  useEffect(()=> {
    setInputData({
      id: tableItem?.id,
        calcId: tableItem?.table_calculator?.id,
        finalResult: tableItem?.table_calculator?.result,
        BOM_table_unit: tableItem?.table_calculator?.unit,
        table_calculator: tableItem?.table_calculator,
    })
  },[tableItem])

 

  







  return (
    <div>
      <Modal
        open={isOpen}
        className={classes.ModalRoot}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={popstyled}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}>
              BOM Calculator Table Parameter
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box
            sx={{

              padding: "0 1rem",
            }}>

            <Box
              sx={{ height: { lg: "81vh", xl: "72vh" }, }}
              adl-scrollbar="true"
              adl-scrollbar-width="0.3">

              {Loader ?
                <Box sx={{ width: "100%", padding: '0 1rem' }}>
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                </Box>


                : 
                <>

                <Table>

                  <TableHead
                    sx={{
                      backgroundColor: "primary.light",
                      position: "sticky",
                      top: "0",
                      zIndex: "1",
                    }}>
                    <TableRow>
                    
                      <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>Sequence</TableCell>
                      <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>	Parameter Name</TableCell>
                      <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>Description</TableCell>
                      <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>Guideline</TableCell>
                    
                      <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>	Is required?</TableCell>
                      <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>	Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {TableData && TableData?.map((item: any, index: any) => {
                      return (<>

                        <TableRowRander setCounter={setCounter} keys={index + 1} tableItem={tableItem} rowItem={item} setUpdatedCounter={setUpdatedCounter} handleChangeCheckBox={handleChangeCheckBox} />
                      </>)
                    })}

                  </TableBody>
                </Table>


                {/* <Table sx={{marginTop:"1rem"}}>
                <TableHead
                    sx={{
                      backgroundColor: "primary.light",
                      position: "sticky",
                      top: "0",
                      zIndex: "1",
                    }}>
                      <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>Sl No.</TableCell>
                      <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>	 Name</TableCell>
                      {TableData?.slice(1)?.map((item:any, index:any)=> {
                        return (
                          <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>	 Parameter {index+1}</TableCell>
                        )
                      })}
                      <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}></TableCell>
                    </TableHead>

                    <TableBody>
                      <TableRow>
                        <TableCell sx={{ padding: "0.5rem 0.5rem", borderBottomColor: 'primary.light' }}>1</TableCell>
                        <TableCell sx={{ padding: "0.5rem 0.5rem", borderBottomColor: 'primary.light' }}>	 Name</TableCell>
                        {TableData?.slice(1)?.map((item:any, index:any)=> {
                          return (
                            <TableCell sx={{ padding: "0.5rem 0.5rem", borderBottomColor: 'primary.light' }}>	 {item?.parameter?.name}</TableCell>
                          )
                        })}
                        <TableCell sx={{ padding: "0.5rem 0.5rem", borderBottomColor: 'primary.light' }}>
                        <Typography
									sx={{
										display: "flex",
										flexDirection: "column",
										alignItems: "center",
										justifyContent: "center",
										fontSize: "0.85rem",
                    cursor: "pointer",
										
									}}
								>
									<AddIcon
										

										sx={{ fontSize: "1.2rem", cursor: "pointer" }}
									/>
									Add Formula
								</Typography>
                        </TableCell>
                        </TableRow>


                    </TableBody>

                </Table> */}


                {/* {selectedCalculator && (
        <BOMConfigTableCalculator
      
      isOpen={true}
      onCloseModal={createCostModal.close}
          rowObj={inpuData}
          inputData={tableItem}
          setCounter={setCounter}
        />
      )} */}




                </>
                
              }
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default TableDataTypeModal;


