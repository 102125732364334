import { Select, TextField, TextareaAutosize } from "@mui/material";
import { debounce } from "lodash";
import * as React from "react";
import { API } from "../../api-services";
import { useDispatch } from "react-redux";
import { increaseCounter, validationCheckCounter } from "../../Redux/Actions/compareopen.action";

export interface ParamsInputTextProps {
  value: any;
  paramsId: any;
  parentId: any;
  placeholderName: any;
  disabled?: any;
  condition?:any;
}

export function AdditionalInput(props: ParamsInputTextProps) {
  const { value, paramsId, parentId, placeholderName,disabled, } = props;
  const dispatch=useDispatch();
  const [paramsValue, setParamsValue] = React.useState<any>("");
  const ExactDebounce = React.useRef(
    debounce(async (value: any, pId: any) => {
      await API.put(`/xcpep/feature_details/${pId}/`, { [paramsId]: paramsId === "input_cost" ? parseInt(value) : value }, {}, 0)
        .then((res: any) => {
            var random: any = Math.floor(Math.random() * 1000000 + 1);
            // dispatch(increaseCounter(random));
            dispatch(validationCheckCounter(random+1))
        })
        .catch((err: any) => {});
    }, 500)
  ).current;

  React.useEffect(() => {
    return () => {
      ExactDebounce.cancel(); // Cancel the debounced function
    };
  }, []);

  React.useEffect(() => {
    if (value) {
      setParamsValue(value);
    }
  }, [value]);

  const handleonChange = (e: any) => {
    const { value } = e.target;
    setParamsValue(value);
    ExactDebounce(value, parentId);
  };
  const getInputBoxbyType = () => {
    return (
      <TextField
        placeholder={placeholderName}
        variant="standard"
        sx={{
          width:'100%',
          ".MuiInputBase-root.MuiInput-root": {
            fontSize: "1rem !important",
          },
          "& .MuiInput-underline:before": { borderBottomColor: "white" },
          "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
        }}
        value={paramsValue}
        disabled={disabled || false}
        onChange={handleonChange}
      />
    );
  };

  return <div>{getInputBoxbyType()}</div>;
}
