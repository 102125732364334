import React from "react";
import { Button, Skeleton, TableCell, TableRow } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import scenerioDoneIcon from "../../Assets/images/scenarioDone.svg";
import LppIcon from "../../Assets/images/LppIcon.svg";
import scenerioInProgressIcon from "../../Assets/images/scenarioInProgress.svg";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import InfoIcon from "@mui/icons-material/Info";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import { API } from "../../api-services";
import { useHistory } from "react-router-dom";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { LoadingButton } from "@mui/lab";
import { AxiosError, AxiosResponse } from "axios";

interface Params {
  projectId: any;
  projectName: any;
  topVaultIds: any;
  expandTable: any;
  initialTopVault: any;
}
interface ICostingCreateSystemTableRowsProps {
  item: any;
  index: any;
  setHoveredIndex: any;
  internalData: any;
  indexInternal: any;
  hoveredIndex: any;
  handleTooltipOpen: any;
  openStatusContent: any;
  setContentText: any;
  setIconText: any;
  setStatisticsData: any;
  setSubsystemName: any;
  setOpenStatisticsModel: any;
  totalCostCalculationDataLoader?: any;
}

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const CostingCreateSystemTableRows: React.FC<
  ICostingCreateSystemTableRowsProps
> = (props) => {
  const {
    item,
    index,
    setHoveredIndex,
    internalData,
    indexInternal,
    hoveredIndex,
    handleTooltipOpen,
    openStatusContent,
    setContentText,
    setIconText,
    setStatisticsData,
    setSubsystemName,
    setOpenStatisticsModel,
    totalCostCalculationDataLoader,
  } = props;
  const { projectId, projectName, topVaultIds, expandTable, initialTopVault } =
    useRouteParams<Params>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [statisticsDataLoader, setStatisticsDataLoader] = React.useState(false);
  const openDownload = Boolean(anchorEl);

  const history = useHistory();

  const dataInternalFilter: any = internalData?.data_by_vehicle?.filter(
    (itemFilterInternal: any) => {
      return itemFilterInternal?.top_vault_id == item?.top_vault_id;
    }
  )?.[0];

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  console.log(topVaultIds, "topVaultIds");

  const handleRedirect = (module: any, id: any) => {
    if (module === "w") {
      API.get(
        "/customer/check_consolidate_downoad/",
        {
          vault: id,
          with_image: true,
          consolidate_type: "Costing",
          scenario: undefined,
        },
        0
      ).then((res: any) => {
        if (res.data.status === "Failed") {
          setIconText("failed");
          setContentText(res.data.message);
          openStatusContent.open();
        } else if (res.data.status === "First Download") {
          setIconText("firstdownload");
          setContentText(res.data.message);
          handleClose();
          openStatusContent.open();
          API.get(
            "customer/consolidated_report/",
            {
              vault: id,
              with_image: true,
              consolidate_type: "Costing",
              scenario: 0,
            },
            0
          ).then((res: any) => {
            handleClose();
            window.open(res?.data, "_self");
          });
        } else if (res.data.status === "In Progress") {
          setIconText("reportProgress");
          setContentText(res.data.message);
          handleClose();
          openStatusContent.open();
        } else if (res.data.status === "Completed") {
          handleClose();
          window.open(res?.data?.url, "_self");
        }
      });
    } else if (module === "w/o") {
      API.get(
        "/customer/check_consolidate_downoad/",
        {
          vault: id,
          with_image: false,
          consolidate_type: "Costing",
          scenario: undefined,
        },
        0
      ).then((res: any) => {
        if (res.data.status === "Failed") {
          setIconText("failed");
          setContentText(res.data.message);
          handleClose();
          openStatusContent.open();
        } else if (res.data.status === "First Download") {
          setIconText("firstdownload");
          setContentText(res.data.message);
          handleClose();
          openStatusContent.open();
          API.get(
            "customer/consolidated_report/",
            {
              vault: id,
              with_image: false,
              scenario: 0,
              consolidate_type: "Costing",
            },
            0
          ).then((res: any) => {
            handleClose();
            window.open(res?.data, "_self");
          });
        } else if (res.data.status === "In Progress") {
          setIconText("reportProgress");
          setContentText(res.data.message);
          handleClose();
          openStatusContent.open();
        } else if (res.data.status === "Completed") {
          handleClose();
          window.open(res?.data?.url, "_self");
        }
      });
    }
  };

  const handleStaticticsOpen = (subSystem: any, type: any) => {
    setStatisticsDataLoader(true);
    API.get(
      `cost/costing_create_ui/`,
      {
        start_top_vault: initialTopVault,
        get_costing_graph_data: projectId,
        abbreviation: type,
      },
      0
    )
      .then((res: AxiosResponse) => {
        setStatisticsData(res.data);
        setSubsystemName(subSystem);
        setOpenStatisticsModel(true);
        setStatisticsDataLoader(false);
      })
      .catch((err: AxiosError) => {
        console.log(err, "error");
      });
  };

  const handleRedirectToLpp = (abbreviationComing: any) => {
    history.push(
      `/costingqualitycheck/${projectId}/${projectName}/${initialTopVault}/${topVaultIds}/${expandTable}/${abbreviationComing}/0/0/${dataInternalFilter?.vault_id}`
    );
  };

  const scenarioHandler = (productId: any, data: any) => {
    sessionStorage.removeItem("nodeCostingScenario");
    sessionStorage.setItem(
      "nodeCostingScenario",
      JSON.stringify(data?.ancestors)
    );
    sessionStorage.setItem("addScenarioStatus","3")
    history.push(
      `/costingscenario/${projectId}/${projectName}/${productId}/${
        data?.vault_id
      }/1/${data?.abbreviation ? data?.abbreviation : "none"}/0`
    );
  };

  return (
    <TableRow
      key={internalData?.name}
      sx={
        hoveredIndex == indexInternal
          ? { backgroundColor: "#f7e1f4", height: "3rem", zIndex: 1 }
          : dataInternalFilter?.validation_status == "WIP"
          ? { backgroundColor: "#fffb90", height: "3rem" }
          : dataInternalFilter?.validation_status == "-"
          ? { backgroundColor: "white", height: "3rem" }
          : { backgroundColor: "#c4efb3", height: "3rem" }
      }
      onMouseEnter={() => setHoveredIndex(indexInternal)}
      onMouseLeave={() => setHoveredIndex(null)}
    >
      {index == 0 && (
        <TableCell
          sx={
            hoveredIndex == indexInternal
              ? {
                  backgroundColor: "#f7e1f4",
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                  fontWeight: "bold",
                  position: "sticky",
                  left: "0",
                  zIndex: 1,
                }
              : {
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                  fontWeight: "bold",
                  cursor: "pointer",
                  backgroundColor: "white",
                  position: "sticky",
                  left: "0",
                  zIndex: 1,
                }
          }
          title={internalData?.name}
        >
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              columnGap: "0.5rem",
            }}
          >
            
            <span
              onClick={() =>
                history.push(
                  `/costingabbrlanding/${projectId}/${projectName}/${initialTopVault}/${topVaultIds}/${expandTable}/${dataInternalFilter?.top_vault_id}/${dataInternalFilter?.top_vault_name}/${internalData?.abbreviation}/${dataInternalFilter?.vault_id}`
                )
              }
              style={{ cursor: "pointer", whiteSpace: "nowrap",
              width: String(topVaultIds).split(",").length > 3 ? "10rem" : "15rem",
              overflow: "hidden",
              textOverflow: "ellipsis" }}
            >
              {internalData?.abbreviation} ({internalData?.name})
            </span>
            <LoadingButton
              loading={statisticsDataLoader}
              sx={{
                p: 0,
                minWidth: "1rem",
                ".MuiCircularProgress-root": {
                  color: "primary.main",
                },
              }}
            >
              <LeaderboardIcon
                titleAccess="Statistics"
                onClick={() =>
                  handleStaticticsOpen(
                    internalData?.name,
                    internalData?.abbreviation
                  )
                }
              />
            </LoadingButton>
          </span>
        </TableCell>
      )}
      {dataInternalFilter?.vault_id != "-" && (
        <TableCell
          title={internalData?.name}
          onClick={() =>
            history.push(
              `/costingabbrlanding/${projectId}/${projectName}/${initialTopVault}/${topVaultIds}/${expandTable}/${dataInternalFilter?.top_vault_id}/${dataInternalFilter?.top_vault_name}/${internalData?.abbreviation}/${dataInternalFilter?.vault_id}`
            )
          }
          sx={{
            padding: "0.2rem 0.5rem",
            borderBottomColor: "primary.light",
            cursor: "pointer",
            textAlign: "center",
          }}
        >
          {totalCostCalculationDataLoader ? (
            <Skeleton />
          ) : dataInternalFilter?.count == "-" ? (
            "-"
          ) : (
            dataInternalFilter?.count
          )}
        </TableCell>
      )}
      {dataInternalFilter?.vault_id != "-" && (
        <TableCell
          title={internalData?.name}
          onClick={() =>
            history.push(
              `/costingabbrlanding/${projectId}/${projectName}/${initialTopVault}/${topVaultIds}/${expandTable}/${dataInternalFilter?.top_vault_id}/${dataInternalFilter?.top_vault_name}/${internalData?.abbreviation}/${dataInternalFilter?.vault_id}`
            )
          }
          sx={{
            padding: "0.2rem 0.5rem",
            borderBottomColor: "primary.light",
            cursor: "pointer",
            textAlign: "right",
          }}
        >
          {totalCostCalculationDataLoader ? (
            <Skeleton />
          ) : dataInternalFilter?.weight == "-" ? (
            "-"
          ) : (
            (dataInternalFilter?.weight / 1000)?.toFixed(2)
          )}
        </TableCell>
      )}
      {dataInternalFilter?.vault_id != "-" && (
        <TableCell
          title={internalData?.name}
          onClick={() =>
            history.push(
              `/costingabbrlanding/${projectId}/${projectName}/${initialTopVault}/${topVaultIds}/${expandTable}/${dataInternalFilter?.top_vault_id}/${dataInternalFilter?.top_vault_name}/${internalData?.abbreviation}/${dataInternalFilter?.vault_id}`
            )
          }
          sx={{
            padding: "0.2rem 0.5rem",
            borderBottomColor: "primary.light",
            cursor: "pointer",
            textAlign: "right",
          }}
        >
          {totalCostCalculationDataLoader ? (
            <Skeleton />
          ) : dataInternalFilter?.weight == "-" ? (
            "-"
          ) : (
            dataInternalFilter?.cost?.toFixed(3)
          )}
        </TableCell>
      )}
      {dataInternalFilter?.vault_id != "-" && (
        <TableCell
          title={internalData?.name}
          onClick={() =>
            history.push(
              `/costingabbrlanding/${projectId}/${projectName}/${initialTopVault}/${topVaultIds}/${expandTable}/${dataInternalFilter?.top_vault_id}/${dataInternalFilter?.top_vault_name}/${internalData?.abbreviation}/${dataInternalFilter?.vault_id}`
            )
          }
          sx={{
            padding: "0.2rem 0.5rem",
            borderBottomColor: "primary.light",
            cursor: "pointer",
            textAlign: "right",
          }}
        >
          {totalCostCalculationDataLoader ? (
            <Skeleton />
          ) : ((dataInternalFilter?.cost / item?.total_cost) * 100).toFixed(
              2
            ) == "NaN" ? (
            "-"
          ) : (
            `${((dataInternalFilter?.cost / item?.total_cost) * 100).toFixed(
              2
            )}%`
          )}
        </TableCell>
      )}
      <TableCell
        colSpan={dataInternalFilter?.vault_id != "-" ? 1 : 5}
        sx={
          dataInternalFilter?.vault_id != "-"
            ? {
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }
            : {
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "center",
              }
        }
      >
        {dataInternalFilter?.vault_id == "-" ? (
          "Not Available"
        ) : (
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "end",
              columnGap: "0.5rem",
            }}
          >
            {dataInternalFilter?.scenario_show &&
              (dataInternalFilter?.scenario_exists ? (
                <img
                  src={scenerioDoneIcon}
                  title="Scenario"
                  height="18px"
                  style={{ marginRight: "0.5rem", cursor: "pointer" }}
                  onClick={() =>
                    scenarioHandler(
                      dataInternalFilter?.top_vault_id,
                      dataInternalFilter
                    )
                  }
                />
              ) : (
                <img
                  src={scenerioInProgressIcon}
                  title="Scenario"
                  height="18px"
                  style={{ marginRight: "0.5rem", cursor: "pointer" }}
                  onClick={() =>
                    scenarioHandler(
                      dataInternalFilter?.top_vault_id,
                      dataInternalFilter
                    )
                  }
                />
              ))}
            <Button
              id="demo-customized-button"
              aria-controls={openDownload ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={openDownload ? "true" : undefined}
              disableElevation
              onClick={handleClick}
              style={{ padding: "0rem", minWidth: "1rem" }}
              title="Download"
            >
              <CloudDownloadIcon sx={{ cursor: "pointer" }} />
            </Button>
            <StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={openDownload}
              onClose={handleClose}
            >
              <MenuItem
                sx={{
                  fontSize: "1rem",
                  color: "primary.main",
                  padding: "0.2rem 0.5rem",
                  // borderBottom:'1px solid #8080801c !important'
                }}
                onClick={() =>
                  handleRedirect("w", dataInternalFilter?.vault_id)
                }
              >
                Consolidated with images
              </MenuItem>
              <MenuItem
                sx={{
                  fontSize: "1rem",
                  color: "primary.main",
                  padding: "0.2rem 0.5rem",
                }}
                onClick={() =>
                  handleRedirect("w/o", dataInternalFilter?.vault_id)
                }
              >
                Consolidated w/o images
              </MenuItem>
            </StyledMenu>
            <img
              src={LppIcon}
              height="20px"
              style={{
                marginLeft: "0.4rem",
                marginRight: "0.2rem",
                cursor: "pointer",
              }}
              onClick={() => handleRedirectToLpp(internalData?.abbreviation)}
            />
            {/* <InfoIcon
              sx={{ cursor: "pointer" }}
              onClick={() => handleTooltipOpen(dataInternalFilter?.part_info)}
            /> */}
          </span>
        )}
      </TableCell>
    </TableRow>
  );
};

export default CostingCreateSystemTableRows;
