// react
import React, { useEffect, useMemo, useRef, useState } from "react";

// vendors
import { RouteComponentProps, useHistory, useParams } from "react-router-dom";
import {
  invert,
  isNull,
  isNumber,
  isUndefined,
  mapValues,
  omit,
  pickBy,
  uniqBy,
} from "lodash";
// import { Button, Dropdown, DropdownProps, Icon, IconProps, Input, InputOnChangeData, Table } from 'semantic-ui-react';
import * as yup from "yup";

// services
// import calculatorInputService from 'app/services/calculatorInput.service';
// import { ADMIN, API } from 'app/services';
// import bomConfigService from 'app/services/bomConfig.service';
// import processConfigService from 'app/services/processConfig.service';
// import calculatorService from 'app/services/calculator.service';

// utils
// import { deleteSwal } from 'app/utils/deleteSwal';
// import yupValidate from 'app/utils/yupValidate';
// import { pipe } from 'app/utils/other';

// constants
// import {
//     calculatorCategoryOptions,
//     calculatorCostInputOptions,
//     calculatorDatabaseColumnsOptions,
//     calculatorDatabaseOptions,
//     calculatorInputBackgroundColorMap,
//     TriangulationInputOptions,
//     CALCULATOR_COST_INPUT_MAP,
//     CALCULATOR_INPUT_CONTENT_TYPE,
//     CALCULATOR_INPUT_TYPE,
//     CALCULATOR_TYPE,
//     InputContentType,
//     triangulation_calculator
// } from 'app/constants/calculator.constants';
// import { transformConfigurationsFromApi } from 'app/constants/configuration.constants';
// import { numericDataTypes } from 'app/constants/parametersDataTypes.constants';

// factories
// core
// import Crumbs from 'app/Core/Crumbs';
// import { useCollection } from 'app/Core/CustomHooks/useCollection';
// import useFetch from 'app/Core/CustomHooks/useFetch';
// import NoData from 'app/Core/NoData';

// redux
// import { CalculatorInput } from 'app/Redux/Reducers/calculatorInput.reducer';
// import { ParameterConfig } from 'app/Redux/Reducers/parameterConfig.reducer';
// import { ICalculator } from 'app/Redux/Reducers/calculator.reducer';

// ui-components
// import { BouncingLoader } from 'app/ui-components';

// others
// styles
import styles from "./InputConfiguration.module.scss";

// interfaces and types
// import { fetched, ID, ObjectType } from 'app/commonInterfaces';
import RViewerJS from "viewerjs-react";
import {
  CALCULATOR_INPUT_CONTENT_TYPE,
  CALCULATOR_INPUT_TYPE,
  CALCULATOR_TYPE,
  InputContentType,
  TriangulationInputOptions,
  calculatorCategoryOptions,
  calculatorCostInputOptions,
  calculatorDatabaseColumnsOptions,
  calculatorDatabaseOptions,
  calculatorInputBackgroundColorMap,
  triangulation_calculator,
} from "../../../Configuration/Costing/calculator.constant";
import { ID, ObjectType, fetched } from "../../../utlis/commonInterfaces";
import { CalculatorInput } from "../../../Redux/Reducers/calculatorInput.reducer";
import yupValidate from "../../../utlis/yupValidate";
import { ParameterConfig } from "../../../Redux/Reducers/parameterConfig.reducer";
import { numericDataTypes } from "../../../utlis/parameterDataTypes.constants";
import { pipe } from "../../../utlis/other";
import { useCollection } from "../../../CustomHook/useCollection";
import useFetch from "../../../Core/CustomHooks/useFetch";
import { transformConfigurationsFromApi } from "../../../utlis/configuration.constants";
import { ICalculator } from "../../../Redux/Reducers/calculator.reducer";
import calculatorService from "../../../Redux/Services/calculator.service";

import calculatorInputService from "../../../Redux/Services/calculatorInput.service";
import {
  Box,
  FormControl,
  IconProps,
  MenuItem,
  Select,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { deleteSwal } from "../../../utlis/deleteSwal";
import BouncingLoader from "../../../ui-reusable-component/BouncingLoader";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import bomconfigService from "../../../Redux/Services/bomconfig.service";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import SyncIcon from "@mui/icons-material/Sync";
import { LoadingButton } from "@mui/lab";
// lazy

// ----------------------------------------------------------------------------------
// params

type Params = {
  projectID: string;
  type: string;
  calculatorId: string;
  topVaultID: string;
};

// ----------------------------------------------------------------------------------
// constants

const inputKeysToOmit: Record<number, string[]> = {
  [CALCULATOR_INPUT_TYPE.BOM_PARAMETER]: [
    "database",
    "formula",
    "constant",
    "volume",
    "attribute",
    "user_input",
  ],
  [CALCULATOR_INPUT_TYPE.COMMODITY_PARAMETER]: [
    "database",
    "formula",
    "attribute",
    "constant",
    "volume",
    "user_input",
  ],
  [CALCULATOR_INPUT_TYPE.CONSTANT]: [
    "database",
    "formula",
    "parameter",
    "user_input",
    "volume",
    "table_element",
  ],
  [CALCULATOR_INPUT_TYPE.VOLUME]: [
    "database",
    "formula",
    "parameter",
    "constant",
    "user_input",
    "table_element",
  ],
  [CALCULATOR_INPUT_TYPE.DATABASE]: [
    "formula",
    "constant",
    "volume",
    "parameter",
    "user_input",
    "table_element",
  ],
  [CALCULATOR_INPUT_TYPE.FORMULA]: [
    "database",
    "constant",
    "volume",
    "parameter",
    "attribute",
    "table_element",
  ],
  [CALCULATOR_INPUT_TYPE.USER_INPUT]: [
    "database",
    "constant",
    "volume",
    "parameter",
    "attribute",
    "formula",
    "table_element",
  ],
};
interface CalculatorInputFromApi {
  id: ID;
  content_type: InputContentType;
  name: string;
  /** string used to refere the input in the formula */
  alias: string;

  parameter?: ID;
  table_element?: ID;

  attribute?: string;

  formula?: string;
  constant?: number;
  user_input?: string;
  calculator_category?: number;
  volume?: number;
  calculator: ID;
  image_url?: string;
  test_input?: number;
  result?: number;
}

const inputContentTypeToDatabaseMap: ObjectType<number> = {
  Material: 1,
  Machine: 2,
  Labour: 3,
  ToolDatabase: 4,
  FastenerDatabase: 5,
  PurchasingItem: 6,
  StrokeRateDatabase: 7,
};

const databaseMapToConntentType = invert(inputContentTypeToDatabaseMap);

export const transformCalculatorInputFromApi = (
  input: CalculatorInputFromApi
): CalculatorInput => {
  const { content_type, ...rest } = input;
  const obj: any = {};
  if (content_type) {
    if (rest.parameter) {
      if (content_type === CALCULATOR_INPUT_CONTENT_TYPE.BOM_PARAMETER)
        obj.type = CALCULATOR_INPUT_TYPE.BOM_PARAMETER;
      if (content_type === CALCULATOR_INPUT_CONTENT_TYPE.COMMODITY_PARAMETER)
        obj.type = CALCULATOR_INPUT_TYPE.COMMODITY_PARAMETER;
    } else if (rest.attribute) {
      if (content_type === CALCULATOR_INPUT_CONTENT_TYPE.COST_INPUT) {
        obj.type = CALCULATOR_INPUT_TYPE.COST_INPUT;
      } else {
        obj.type = CALCULATOR_INPUT_TYPE.DATABASE;
        obj.database = inputContentTypeToDatabaseMap[content_type];
      }
    } else if (isNumber(rest.calculator_category)) {
      obj.type = CALCULATOR_INPUT_TYPE.CALCULATOR_RESULT;
    }
  }
  if (rest.formula) obj.type = CALCULATOR_INPUT_TYPE.FORMULA;
  else if (!isNull(rest.user_input))
    obj.type = CALCULATOR_INPUT_TYPE.USER_INPUT;
  if (rest.constant) obj.type = CALCULATOR_INPUT_TYPE.CONSTANT;
  if (rest.volume) obj.type = CALCULATOR_INPUT_TYPE.VOLUME;

  return Object.assign({}, rest, obj);
};

const toApi = async (
  input: CalculatorInput
): Promise<CalculatorInputFromApi> => {
  const transformedInput = transformInput(input) as Required<CalculatorInput>;
  try {
    const validatedInput = (await yupValidate(
      schema,
      transformedInput
    )) as CalculatorInput;

    const { type, database, ...rest } = validatedInput;
    const obj: any = {};

    if (database) {
      obj.content_type = databaseMapToConntentType[database];
    }
    if (type === CALCULATOR_INPUT_TYPE.BOM_PARAMETER) {
      obj.content_type = CALCULATOR_INPUT_CONTENT_TYPE.BOM_PARAMETER;
    }

    if (type === CALCULATOR_INPUT_TYPE.COMMODITY_PARAMETER) {
      obj.content_type = CALCULATOR_INPUT_CONTENT_TYPE.COMMODITY_PARAMETER;
    }
    if (type === CALCULATOR_INPUT_TYPE.COST_INPUT) {
      obj.content_type = CALCULATOR_INPUT_CONTENT_TYPE.COST_INPUT;
    }
    if (type === CALCULATOR_INPUT_TYPE.CALCULATOR_RESULT) {
      obj.content_type = CALCULATOR_INPUT_CONTENT_TYPE.CALCULATOR_RESULT;
    }

    return pickBy(
      Object.assign({}, rest, obj),
      (value) => !isUndefined(value)
    ) as any;
  } catch (e: any) {
    const msg = Object.values(e).join(", ");
    throw new Error(msg);
  }
};

// ----------------------------------------------------------------------------------
// helpers

const generateConfigsOptions = (configs: ParameterConfig[]) => {
  return configs.map((config) => ({
    key: config.id,
    value: config.parameter_id,
    text: config.name,
  }));
};

// to only maintain keys that are related to the input type during saving of data
const transformInputs = (inputs: CalculatorInput[]) => {
  return inputs.map(transformInput);
};

/** removes unwanted columns depending on type */
const transformInput = (input: CalculatorInput) => {
  return omit(input, inputKeysToOmit[input.type] || []) as CalculatorInput;
};

const numericOrTableDataTypes = numericDataTypes.concat("table");
const filterNumericOrTableConfigs = (configs: ParameterConfig[]) => {
  return configs.filter((config) =>
    numericOrTableDataTypes.includes(config.data_type)
  );
};

const filterNonTableColumnConfigs = (configs: ParameterConfig[]) => {
  return configs.filter((config) => !config.parameter_table_id);
};

const filterTableColumnConfigs = (
  configs: ParameterConfig[],
  parameterId: ID
) => {
  return configs.filter((config) => config.parameter_table_id === parameterId);
};

/** configs that need to be shown for BOM/Commodity parameters dropdown */
const getConfigsOptions = pipe(
  filterNumericOrTableConfigs,
  filterNonTableColumnConfigs,
  generateConfigsOptions
);

/** configs that need to shown for columns of selectedParameter */
const getTableColumnConfigsOptions = (
  configs: ParameterConfig[],
  parameterId: ID
) => {
  const tableColumnConfigs = filterTableColumnConfigs(configs, parameterId);
  return pipe(
    filterNumericOrTableConfigs,
    generateConfigsOptions
  )(tableColumnConfigs);
};

// ----------------------------------------------------------------------------------
// schema

const schema = yup.object().shape({
  type: yup.number().required('"Type" is required'),
  name: yup.string().required('"Name" is required'),
  alias: yup.string().required('"As" is required'),
  parameter: yup.mixed().when("type", {
    is: (type) =>
      [
        CALCULATOR_INPUT_TYPE.BOM_PARAMETER,
        CALCULATOR_INPUT_TYPE.COMMODITY_PARAMETER,
      ].includes(type),
    then: yup
      .number()
      .transform((value) => (Boolean(value) ? value : undefined))
      .required('"Parameter" is required'),
    otherwise: yup.number().transform(() => undefined),
  }),
  table_element: yup.mixed().when("type", {
    is: (type) =>
      [
        CALCULATOR_INPUT_TYPE.BOM_PARAMETER,
        CALCULATOR_INPUT_TYPE.COMMODITY_PARAMETER,
      ].includes(type),
    then: yup
      .number()
      .transform((value) => (Boolean(value) ? value : undefined))
      .notRequired(),
    otherwise: yup.number().transform(() => undefined),
  }),
  attribute: yup.string().when("type", {
    is: (type) =>
      [
        CALCULATOR_INPUT_TYPE.DATABASE,
        CALCULATOR_INPUT_TYPE.COST_INPUT,
      ].includes(type),
    then: yup
      .string()
      .transform((value) => (isNull(value) ? "" : value))
      .required('"Column/Cost Type" is required'),
    otherwise: yup.string().transform(() => undefined),
  }),
  database: yup.number().when("type", {
    is: CALCULATOR_INPUT_TYPE.DATABASE,
    then: yup
      .number()
      .transform((value) => (Boolean(value) ? value : undefined))
      .required('"Database" is required'),
    otherwise: yup.number().transform(() => undefined),
  }),
  formula: yup.string().when("type", {
    is: CALCULATOR_INPUT_TYPE.FORMULA,
    then: yup
      .string()
      .transform((value) => (isNull(value) ? "" : value))
      .required('"Formula" is required'),
    otherwise: yup.string().transform(() => undefined),
  }),
  constant: yup.number().when("type", {
    is: CALCULATOR_INPUT_TYPE.CONSTANT,
    then: yup
      .number()
      .transform((value) => (Boolean(value) ? value : 0))
      .notOneOf([0])
      .required('"Constant Value" is required'),
    otherwise: yup.number().transform(() => undefined),
  }),
  // volume: yup.number().when( "type", {
  //     is: CALCULATOR_INPUT_TYPE.VOLUME,
  //     then: yup.string().transform( value => isNull( value ) ? "" : value ),
  //     otherwise: yup.number().transform( () => undefined )
  // } ),
  user_input: yup.string().when("type", {
    is: (type) =>
      [
        CALCULATOR_INPUT_TYPE.USER_INPUT,
        CALCULATOR_INPUT_TYPE.FORMULA,
      ].includes(type),
    then: yup.string().transform((value) => (isNull(value) ? "" : value)),
    otherwise: yup.string().transform(() => undefined),
  }),
  calculator_category: yup.number().when("type", {
    is: CALCULATOR_INPUT_TYPE.CALCULATOR_RESULT,
    then: yup
      .number()
      .transform((value) => (isNumber(value) ? value : undefined))
      .required('"Calculator Category" is required'),
    otherwise: yup.number().transform(() => undefined),
  }),
});

// ----------------------------------------------------------------------------------

interface InputConfigurationProps extends RouteComponentProps { }

const InputConfiguration: React.FC<InputConfigurationProps> = (props) => {
  const { crumbs } = props;
  const params = useParams<Params>();
  const { calculatorId, projectID, type, topVaultID } = mapValues(
    params,
    Number
  );

  // ----------------------------------------------------------------------------------
  // state
  const history = useHistory<any>();

  const [inputsFetched, setInputsFetched] = useState<fetched>("FALSE");
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [currentSelected, setCurrentSelected] = useState<any>(-1);
  const [Loader, setloader] = useState<any>(false);

  const inputElRef = useRef<any>();
  const imageViewerElRef = useRef<any>();
  const [color, setColor] = useState<any>("blue");
  const [saveApiCallCount, setSaveApiCallCount] = useState<any>(0);
  const [activeId, setActiveId] = useState<any>();

  // ----------------------------------------------------------------------------------
  // collection hook to hold inputs

  const inputs = useCollection<CalculatorInput>();

  // ----------------------------------------------------------------------------------
  // fetch parameter mappings

  // fetch bom parameters
  const { 1: bomConfigs } = useFetch<ParameterConfig[]>({
    method: bomconfigService.getByProjectId,
    args: [projectID, true],
    transformResData(data) {
      const configs = transformConfigurationsFromApi(data);

      return uniqBy(configs, (config) => config.parameter_id);
    },
    fallBackErrMsg: "Could not fetch bom parameters",
  });

  const calculatorName = triangulation_calculator.filter(
    (data: any) => data.key == calculatorId
  );

  const {
    0: gettingCalvalue,
    1: calculator,
    2: setCalculator,
  } = useFetch<ICalculator>({
    method: calculatorService.getByIdTriangulation,
    args: [topVaultID, calculatorName[0].value],
    getZeroth: true,
  });

  // ----------------------------------------------------------------------------------
  // helpers

  const bomTableConfigOptions = (parameterId: ID) => {
    return bomConfigs
      ? getTableColumnConfigsOptions(bomConfigs, parameterId)
      : [];
  };

  type DE_TYPE =
    | typeof CALCULATOR_INPUT_TYPE.BOM_PARAMETER
    | typeof CALCULATOR_INPUT_TYPE.COMMODITY_PARAMETER;
  /**
   * indicates whether the give parameter id is table or not
   * @param type whether the parameterId needs to found in bom or peocess paramaters
   * @param parameterId id
   */

  // ----------------------------------------------------------------------------------
  // memorization

  const bomConfigsOptions = useMemo(() => {
    return bomConfigs ? getConfigsOptions(bomConfigs) : [];
  }, [bomConfigs]);

  // ----------------------------------------------------------------------------------
  // api helpers

  const fetchInputs = async () => {
    setInputsFetched("FETCHING");
    try {
      if (gettingCalvalue == "TRUE") {
        const { data } = await calculatorInputService.getTriangulation(
          calculator?.id
        );
        inputs.setAll(
          data
            .map(transformCalculatorInputFromApi)
            .sort((a: any, b: any) => a.id - b.id)
        );
        setInputsFetched("TRUE");
      }
    } catch (e) {
      // ADMIN.toast.throwError( e, "Could not fetch input" );
      setInputsFetched("ERROR");
    }
  };

  const createInput = async (inputId: ID, input: CalculatorInput) => {
    setloader(true);
    try {
      const validatedInput = await toApi(input);
      var datavalue = validatedInput?.volume
        ? { ...validatedInput, attribute: "volume" }
        : validatedInput;

      const { data } = await calculatorInputService.createTiangulation(
        datavalue
      );
      inputs.overlapOne(inputId, transformCalculatorInputFromApi(data));
      setSaveApiCallCount((prev: any) => prev + 1);
      setColor("");
      // ADMIN.toast.success( "Input Created" );
    } catch (e) {
      setColor("");
      // ADMIN.toast.throwError( e, "Could not create input", true );
    }
  };

  const updateInput = async (input: Partial<CalculatorInput>) => {
    setloader(true);
    try {
      const validatedInput = await toApi(input as any);
      var datavalue = validatedInput?.volume
        ? { ...validatedInput, attribute: "volume" }
        : validatedInput;
      await calculatorInputService.updateTriangulation(datavalue);
      setSaveApiCallCount((prev: any) => prev + 1);
      setColor("");
      // ADMIN.toast.success( "Input Updated" );
    } catch (e) {
      setColor("");
      // ADMIN.toast.throwError( e, "Could not update input", true );
    }
  };

  const deleteInput = async (inputId: ID) => {
    // delete directly from memory if not created in backend
    // @ts-ignore
    if (inputId < 1) return inputs.removeOne(inputId);

    try {
      await calculatorInputService.deleteTriangulation(inputId);
      inputs.removeOne(inputId);

      // ADMIN.toast.success( "Input deleted" );
    } catch (e) {
      // ADMIN.toast.throwError( e, "Could not delete input" );
    }
  };

  // const updateResult = async () => {
  //     if ( !calculator ) return;
  //     if ( !calculator.result ) return ADMIN.toast.error( '"Result Formula" is required.' );

  //     try {
  //         await calculatorService.updateTiangulation( { id: calculator.id, updates: { result: calculator.result } } );
  //         ADMIN.toast.success( "Result updated" );
  //     } catch ( e ) {
  //         ADMIN.toast.throwError( e, "Could not update result" );
  //     }
  // };

  // ----------------------------------------------------------------------------------
  // handlers
  const handleChange = (inputId: ID) => (e: any) => {
    const { name, value } = e.target;

    setColor("red");
    setActiveId(inputId);
    inputs.updateOne({ id: inputId, changes: { [name]: value } });
  };

  // const handleResultChange = ( e: React.SyntheticEvent, ed: InputOnChangeData ) => {
  //     setCalculator( ( prev: any ) => Object.assign( {}, prev, { result: ed.value } ) );
  // };

  const getData = (data: any) => {
    
    if (type !== CALCULATOR_TYPE.OVERHEAD) {
      return data.filter(
        (item: any) => item.value !== CALCULATOR_INPUT_TYPE.COST_INPUT
      );
    } else {
      return data;
    }
  };
  const handleAdd = () => {
    const newInput: CalculatorInput = {
      id: Math.random(),
      name: "",
      alias: "",
      calculator: calculator?.id,
      type: CALCULATOR_INPUT_TYPE.BOM_PARAMETER,
    };

    inputs.addOne(newInput);
  };

  const handleDelete = (id: any) => async () => {
    const confirm = await deleteSwal("Delete Input");
    if (confirm) deleteInput(id);
  };

  console.log(Loader, "LoaderLoader");
  const handleSave = (inputId: ID) => () => {
    // create
    const input = inputs.getById(inputId);
    if (!input) return;
    // @ts-ignore
    if (inputId < 1) {
      createInput(inputId, input);
      return;
    }

    updateInput(input);
  };

  // ----------------------------------------------------------------------------------
  // side effects

  // fetch input for the caluclator on mount
  useEffect(() => {
    fetchInputs();
  }, [gettingCalvalue, saveApiCallCount]);
  const getOptionData = (type: any) => {
    if (type === CALCULATOR_INPUT_TYPE.BOM_PARAMETER) {
      return bomConfigsOptions;
    } else {
      return null;
    }
  };
  // ----------------------------------------------------------------------------------
  // jsx

  // TODO: substitute 1,2,3 w/ CALC_INPUT_MAP values
  const renderValueColumn = (input: CalculatorInput) => {
    switch (input.type) {
      case CALCULATOR_INPUT_TYPE.BOM_PARAMETER:
      case CALCULATOR_INPUT_TYPE.COMMODITY_PARAMETER:
        return (
          // <Dropdown
          //     name="parameter"
          //     options={
          //         input.type === CALCULATOR_INPUT_TYPE.BOM_PARAMETER
          //             ? bomConfigsOptions
          //             : null
          //     }
          //     placeholder="Parameter"
          //     search
          //     selection
          //     value={ input.parameter || "" }
          //     onChange={ handleChange( input.id ) }
          //     data-type={ input.type }
          // />

          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              variant="standard"
              label="Type"
              // disabled={
              //   !sessionStorage.getItem("ConfigPermission")?.includes("U")
              // }
              value={input.parameter || ""}
              name="parameter"
              sx={{
                width: "50%",
                fontSize: "1rem",
                "&:before": {
                  borderBottomColor: "primary.light",
                },
                ".MuiSvgIcon-root.MuiSelect-icon": {
                  color: "primary.main",
                },
              }}
              //    @ts-ignore
              onChange={handleChange(input.id)}>
              {getOptionData(input.type)?.map((item: any) => {
                return (
                  <MenuItem sx={{ fontSize: "1rem" }} value={item.value}>
                    {item.text}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        );
      case CALCULATOR_INPUT_TYPE.DATABASE:
        return (
          <span className="flex">
            {/* <Dropdown
                            name="database"
                            options={ calculatorDatabaseOptions }
                            placeholder="Database"
                            search
                            selection
                            value={ input.database || "" }
                            onChange={ handleChange( input.id ) }
                        /> */}
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                variant="standard"
                label="Type"
                // disabled={!sessionStorage.getItem("ConfigPermission")?.includes("U")}
                value={input.database || ""}
                // @ts-ignore
                onChange={handleChange(input.id)}
                name="database"
                sx={{
                  width: "50%",
                  fontSize: "1rem",
                  "&:before": {
                    borderBottomColor: "primary.light",
                  },
                  ".MuiSvgIcon-root.MuiSelect-icon": {
                    color: "primary.main",
                  },
                }}>
                {calculatorDatabaseOptions?.map((item: any) => {
                  return (
                    <MenuItem sx={{ fontSize: "1rem" }} value={item.value}>
                      {item.text}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {/* <Dropdown
                            name="attribute"
                            options={
                                calculatorDatabaseColumnsOptions[
                                input.database || 0
                                ] || []
                            }
                            value={ input.attribute || "" }
                            placeholder="Column"
                            search
                            selection
                            onChange={ handleChange( input.id ) }
                        /> */}

            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                variant="standard"
                label="Type"
                // disabled={!sessionStorage.getItem("ConfigPermission")?.includes("U")}
                value={input.attribute || ""}
                // @ts-ignore
                onChange={handleChange(input.id)}
                name="attribute"
                sx={{
                  width: "50%",
                  fontSize: "1rem",
                  "&:before": {
                    borderBottomColor: "primary.light",
                  },
                  ".MuiSvgIcon-root.MuiSelect-icon": {
                    color: "primary.main",
                  },
                }}>
                {calculatorDatabaseColumnsOptions[input.database || 0]?.map(
                  (item: any) => {
                    return (
                      <MenuItem sx={{ fontSize: "1rem" }} value={item.value}>
                        {item.text}
                      </MenuItem>
                    );
                  }
                )}
              </Select>
            </FormControl>
          </span>
        );
      case CALCULATOR_INPUT_TYPE.CONSTANT:
        return (
          <TextField
            name="constant"
            type="number"
            variant="standard"
            placeholder="Constant Value"
            required
            // sx={{ width: "19rem !important" }}
            sx={{
              width: "100%",
              ".MuiInputBase-root.MuiInput-root": {
                fontSize: "1rem !important",
              },
              "& .MuiInput-underline:before": {
                borderBottomColor: "white",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "primary.main",
              },
            }}
            value={input.constant || 0}
            onChange={handleChange(input.id)}
          />
        );
      case CALCULATOR_INPUT_TYPE.VOLUME:
        return (
          <>
            <TextField
              variant="standard"
              name="volume"
              type="number"
              // disabled={!sessionStorage.getItem("ConfigPermission")?.includes("U")}
              placeholder="volume"
              value={input.volume || ""}
              onChange={handleChange(input.id)}
              InputProps={{
                style: { fontSize: "1rem" },
              }}
              sx={{
                "& .MuiInput-underline:before": {
                  borderBottomColor: "white",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
              }}
            />
            {/* <TextField
                                name="volume"
                                type="number"
                                variant="standard"
                                placeholder="volume"
                                sx={{ width: "19rem !important" }}
                                required
                                value={input.volume || 0}
                                onChange={handleChange(input.id)} /> */}
          </>
        );
      case CALCULATOR_INPUT_TYPE.FORMULA:
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "0.5rem",
              width: "100%",
            }}>
            <TextField
              name="formula"
              placeholder="Formula"
              variant="standard"
              required
              sx={{
                width: "100%",
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "white",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
              }}
              value={input.formula || ""}
              onChange={handleChange(input.id)}
            />
            <TextField
              name="user_input"
              variant="standard"
              placeholder="Unit (optional)"
              required
              sx={{
                width: "100%",
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "white",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
              }}
              value={input.user_input || ""}
              onChange={handleChange(input.id)}
            />
          </Box>
        );
      case CALCULATOR_INPUT_TYPE.USER_INPUT:
        return (
          <TextField
            name="user_input"
            variant="standard"
            placeholder="User Input Unit (optional)"
            required
            sx={{
              width: "100%",
              ".MuiInputBase-root.MuiInput-root": {
                fontSize: "1rem !important",
              },
              "& .MuiInput-underline:before": {
                borderBottomColor: "white",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "primary.main",
              },
            }}
            value={input.user_input || ""}
            onChange={handleChange(input.id)}
          />
        );
      case CALCULATOR_INPUT_TYPE.COST_INPUT:
        return (
          // <Dropdown
          //     name="attribute"
          //     options={ calculatorCostInputOptions }
          //     value={ input.attribute || "" }
          //     placeholder="Cost Type"
          //     search
          //     selection
          //     onChange={ handleChange( input.id ) }
          // />

          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              variant="standard"
              disabled={
                !sessionStorage.getItem("ConfigPermission")?.includes("U")
              }
              size="small"
              label="Cost Type"
              value={input.attribute || ""}
              // @ts-ignore
              onChange={handleChange(input.id)}
              name="attribute"
              sx={{
                width: "100%",
                fontSize: "1rem",
                "&:before": {
                  borderBottomColor: "primary.light",
                },
                ".MuiSvgIcon-root.MuiSelect-icon": {
                  color: "primary.main",
                },
              }}>
              {calculatorCostInputOptions?.map((item: any) => {
                return (
                  <MenuItem sx={{ fontSize: "1rem" }} value={item.value}>
                    {item.text}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        );
      case CALCULATOR_INPUT_TYPE.CALCULATOR_RESULT:
        return (
          // <Dropdown
          //     name="calculator_category"
          //     options={ calculatorCategoryOptions[type] }
          //     value={ input.calculator_category ?? "" }
          //     placeholder="Calculator Category"
          //     search
          //     selection
          //     onChange={ handleChange( input.id ) }
          // />

          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            variant="standard"
            size="small"
            // disabled={!sessionStorage.getItem("ConfigPermission")?.includes("U")}
            label="calculator_category"
            value={input.calculator_category ?? ""}
            // @ts-ignore
            onChange={handleChange(input.id)}
            name="calculator_category"
            sx={{
              width: "100%",
              fontSize: "1rem",
              "&:before": {
                borderBottomColor: "primary.light",
              },
              ".MuiSvgIcon-root.MuiSelect-icon": {
                color: "primary.main",
              },
            }}>
            {calculatorCategoryOptions[type]?.map((item: any) => {
              return (
                <MenuItem sx={{ fontSize: "1rem" }} value={item.value}>
                  {item.text}
                </MenuItem>
              );
            })}
          </Select>
        );
      default:
        return null;
    }
  };
  //this code is for multiple image upload

  // const handleFileUpload = ( e: React.ChangeEvent<HTMLInputElement>, id: any ) => {
  //     setIsUploading( true );
  //     const data = new FormData();
  //     if ( e.target.files?.length ) {
  //         //@ts-ignore
  //         data.append( `image0`, e.currentTarget.files[0] );

  //         API.put( `cost/calculator_field_image_upload/${inputs.getCollection()[currentSelected].id}/`, data, {}, 0 )
  //             .then( ( res: any ) => {
  //                 setIsUploading( false );
  //                 ADMIN.toast.success( "Data uploaded successfully" );
  //                 fetchInputs();
  //             } ).catch( ( err: any ) => {
  //                 ADMIN.toast.error( "Could not upload file" );
  //                 setIsUploading( false );
  //             } );
  //     }

  // };

  const handleFileInputClick = (e: React.MouseEvent) => {
    // clear the prev value on click so that same image can be uploaded again

    (e.target as HTMLInputElement).value = "";
  };

  // ----------------------------------------------------------------------------------
  // jsx

  if (["FALSE", "FETCHING"].includes(inputsFetched))
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginTop: "4rem",
          justifyContent: "center",
        }}>
        {" "}
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  return (
    <div data-testid="input-configuration" className={styles.container}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <div style={{ display: "flex", marginLeft: "1rem", gap: "1rem" }}>
          <ArrowBackIcon
            sx={{ cursor: "pointer" }}
            onClick={() => history.goBack()}
          />

          <Typography sx={{ fontSize: '1rem' }}>Triangulation [Input Configuration]</Typography>
        </div>
        <div className={styles.options}>
          <div className="fit">
            <AddIcon
              titleAccess="Add"
              sx={{ cursor: "pointer" }}
              onClick={handleAdd}
            />
            <SyncIcon
              sx={{ cursor: "pointer" }}
              titleAccess="sync result"
              onClick={fetchInputs}
            />
            {/* <Button onClick={ handleAdd }><Icon name="plus"
                        title="Add"
                        alt="Add"
                        className="bicon"
                    /></Button>
                    <Button onClick={ fetchInputs }>
                        <Icon title="sync result" name="sync" className="bicon" />
                    </Button> */}
          </div>
        </div>
      </div>
      <div
        className={styles.tableWrapper}
        adl-scrollbar="true"
        adl-scrollbar-width="0.6">
        {inputs.length === 0 && (
          <Typography className={styles.noInputCreated} onClick={handleAdd}>No inputs created yet</Typography>
          // <NoData label="No inputs created yet" link onClick={ handleAdd } />
        )}
        {inputs.length > 0 && (
          <Table stickyHeader={true}>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    background: " #e8f0fe !important",
                    fontSize: "1.1rem",
                    padding: "0.2rem 0.5rem",
                    whiteSpace: "nowrap",
                  }}>
                  Sl No.
                </TableCell>
                <TableCell
                  sx={{
                    background: " #e8f0fe !important",
                    fontSize: "1.1rem",
                    padding: "0.2rem 0.5rem",
                  }}>
                  Input Name
                </TableCell>
                <TableCell
                  sx={{
                    background: " #e8f0fe !important",
                    fontSize: "1.1rem",
                    padding: "0.2rem 0.5rem",
                  }}>
                  As
                </TableCell>
                <TableCell
                  sx={{
                    background: " #e8f0fe !important",
                    fontSize: "1.1rem",
                    padding: "0.2rem 0.5rem",
                  }}>
                  Type
                </TableCell>
                <TableCell
                  sx={{
                    background: " #e8f0fe !important",
                    fontSize: "1.1rem",
                    padding: "0.2rem 0.5rem",
                  }}
                  width="6">
                  Value
                </TableCell>
                <TableCell
                  sx={{
                    background: " #e8f0fe !important",
                    fontSize: "1.1rem",
                    padding: "0.2rem 0.5rem",
                  }}>
                  Test Input
                </TableCell>
                <TableCell
                  sx={{
                    background: " #e8f0fe !important",
                    fontSize: "1.1rem",
                    padding: "0.2rem 0.5rem",
                  }}>
                  Result
                </TableCell>
                <TableCell
                  sx={{
                    background: " #e8f0fe !important",
                    fontSize: "1.1rem",
                    padding: "0.2rem 0.5rem",
                  }}
                  width="1">
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {inputs.getCollection().map((input, index) => (
                <TableRow key={input.id}>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    {index + 1}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    <TextField
                      name="name"
                      placeholder="Name"
                      variant="standard"
                      value={input.name}
                      sx={{
                        width: "100%",
                        ".MuiInputBase-root.MuiInput-root": {
                          fontSize: "1rem !important",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "white",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "primary.main",
                        },
                      }}
                      onChange={handleChange(input.id)}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    <TextField
                      name="alias"
                      placeholder="As"
                      variant="standard"
                      value={input.alias}
                      sx={{
                        width: "100%",
                        ".MuiInputBase-root.MuiInput-root": {
                          fontSize: "1rem !important",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "white",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "primary.main",
                        },
                      }}
                      onChange={handleChange(input.id)}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    {/* <Dropdown
                                            name="type"
                                            search
                                            selection
                                            value={ input.type }
                                            options={ TriangulationInputOptions.filter( input => {
                                            
                                                return type !== CALCULATOR_TYPE.OVERHEAD ? input.value !== CALCULATOR_INPUT_TYPE.COST_INPUT : true;
                                            } ) 
                                        }
                                            onChange={ handleChange( input.id ) }
                                        /> */}

                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      variant="standard"
                      // disabled={!sessionStorage.getItem("ConfigPermission")?.includes("U")}
                      size="small"
                      value={input.type}
                      // @ts-ignore
                      onChange={handleChange(input.id)}
                      name="type"
                      sx={{
                        width: "100%",
                        fontSize: "1rem",
                        "&:before": {
                          borderBottomColor: "primary.light",
                        },
                        ".MuiSvgIcon-root.MuiSelect-icon": {
                          color: "primary.main",
                        },
                      }}>
                      {
                        // calculatorInputOptions.filter(input => {
                        //                         /** filter cost_input type, when calculator type is not overhead */
                        //         return selectedTreeData.calculator_type !== CALCULATOR_TYPE.OVERHEAD ? input.value !== CALCULATOR_INPUT_TYPE.COST_INPUT : input;
                        // })
                        //   @ts-ignore
                        getData(TriangulationInputOptions)?.map((item: any) => {
                          return (
                            <MenuItem
                              sx={{ fontSize: "1rem" }}
                              value={item.value}>
                              {item.text !== "Volume" && item.text}
                            </MenuItem>
                          );
                        })
                      }
                    </Select>
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                      width: "30vw",
                    }}>
                    {renderValueColumn(input)}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    <TextField
                      name="test_input"
                      placeholder="Enter Value"
                      variant="standard"
                      value={input.test_input}
                      sx={{
                        width: "100%",
                        ".MuiInputBase-root.MuiInput-root": {
                          fontSize: "1rem !important",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "white",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "primary.main",
                        },
                      }}
                      onChange={handleChange(input.id)}
                    // sx={{}}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                      backgroundColor:
                          calculatorInputBackgroundColorMap[input.type],
                    }}>
                    <div
                      style={{
                        
                        color: "blue",
                      }}>
                      <TextField
                        name="result"
                        placeholder=""
                        variant="standard"
                        sx={{
                          width: "100%",
                          ".MuiInputBase-root.MuiInput-root": {
                            fontSize: "1rem !important",
                          },
                          "& .MuiInput-underline:before": {
                            borderBottomColor: "white",
                          },
                          "& .MuiInput-underline:after": {
                            borderBottomColor: "primary.main",
                          },
                        }}
                        value={input.result}
                        disabled
                      />
                    </div>
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    {/* <Icon title="save" name="save" link inputid={ input.id } onClick={ handleSave( input.id ) } /> */}
                    <div>
                      <SaveIcon
                        titleAccess="Save"
                        sx={
                          activeId && activeId == input.id
                            ? { cursor: "pointer", color: color }
                            : { cursor: "pointer" }
                        }
                        onClick={handleSave(input.id)}
                      />

                      <DeleteIcon
                        sx={{ cursor: "pointer" }}
                        titleAccess="Delete"
                        onClick={handleDelete(input.id)}
                      />
                    </div>
                    {/* <Icon title="delete" name="trash" link inputid={ input.id } onClick={ handleDelete } /> */}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
            {/* <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell textAlign="center">-</Table.HeaderCell>
                                <Table.HeaderCell>Result</Table.HeaderCell>
                                <Table.HeaderCell>-</Table.HeaderCell>
                                <Table.HeaderCell><Input value="Result" /></Table.HeaderCell>
                                <Table.HeaderCell><Input placeholder="Formula" value={ calculator?.result || "" } onChange={ handleResultChange } /></Table.HeaderCell>
                                <Table.HeaderCell textAlign="center">
                                    <Icon name="save" link onClick={ updateResult } />
                                </Table.HeaderCell>
                            </Table.Row>
                    </Table.Footer> */}
          </Table>
        )}
      </div>
    </div>
  );
};

// ----------------------------------------------------------------------------------

export default InputConfiguration;
