import {
  Box,
  Button,
  Checkbox,
  Hidden,
  Modal,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import { usePdf } from "@mikecousins/react-pdf";

import CancelIcon from "@mui/icons-material/Cancel";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import FileDownloadcon from "@mui/icons-material/FileDownload";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import InwardS3images from "./InwardS3images";
import { API } from "../../api-services";
import VerifiedIcon from "@mui/icons-material/Verified";
import InwardPdfViewer from "./InwardPdfViewer";
import { useBaseModal } from "../SearchPage/useBaseModal";
import InwardPdfNameModal from "./InwardPdfNameModal";
import editmail from "../../Assets/images/mailEdit.png"
const label = { inputProps: { "aria-label": "Checkbox demo" } };

interface InwardDocumentModalProps {
  isOpen: any;
  onCloseModal: any;
  DocList: any;
  productId: any;
  setDocumentCounter: any;
  checkstatusDoc: any;
  DocName: any;
  DocFileName:any;
  Premission:any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  height: "96vh",
  width: "auto",
  minWidth:'50vw',
  maxWidth: "80vw",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "10px",
  // border: "2px solid #000",
  // boxShadow: 24,
  p: 1,
};
const InwardDocumentModal = (props: InwardDocumentModalProps) => {
  const [loader, setLoader] = useState<boolean>(true);
  const {
    isOpen,
    onCloseModal,
    DocList,
    productId,
    setDocumentCounter,
    checkstatusDoc,
    DocName,
    DocFileName,Premission
  } = props;
  const [documentData, setDocumentData] = useState<any>(DocList);

  const [DocfileName,setDofilecName]=useState<any>(DocFileName)

  // const [CheckboxStatus,SetCheckboxStatus]=useState<any>()

  const [status, setStatus] = useState<any>(checkstatusDoc);

  const inputElRef = useRef<any>(null);

  const [Counter, setCounter] = useState<any>(0);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [FileData, setFiledata] = useState<any>()
  const onDocumentLoadSuccess = (data: any) => {
    setLoader(false);
  };
  const pdfnamemodal = useBaseModal()
  const pdfFilemodal=useBaseModal()
  const onDocumentLoadFail = () => {
    // ADMIN.toast.error("Failed to load pdf please try again");
    setLoader(true);
  };

  useEffect(() => {
    if (isOpen == true) {
      setDocumentData(DocList);
      setLoader(true);
    } else {
      setDocumentData("");
    }
  }, [isOpen, DocList]);

  const browseFiles = (imagesUrl?: any, imageName?: any) => {
    return inputElRef.current?.click?.();
  };
  const ClosedHandler = () => {
    setLoader(true);
    onCloseModal();
    setDocumentCounter((prev: any) => prev + 1);
    // setDocumentData('')
  };


  const FileEditHanlder=()=>{
    pdfFilemodal.open()
  }

  const SubmitPdfNameFile=(data:any)=>{

    API.put("/vehicle_inward/inward_view/",{

      parameter_name :DocName=="upload_doc-1"?"doc_1_name":"doc_1_name",
      top_vault_id: productId,
      new_data :data

    }).then((res:any)=>{
      // setDofilecName(data)   
      setCounter((prev: any) => prev + 1);
    })
  

  }
// console.log(DocName,"svvscvhsvchshvcshvcvhsc")

  const downloadClicked = () => {
    API.get(
      "/vehicle_inward/inward_view/",
      {
        top_vault_id: productId,
        parameter: "documents",
      },
      0
    ).then((res: any) => {
      var datafiler = res?.data?.documents.filter((item: any, index: any) => {
      
        
        return Object.keys(item).includes(DocName) 
      });

  
      var datastructures: any = Object?.entries(datafiler[0])?.map(
        ([key, val]) => {
          return { parametername: key, file: val };
        }
      )[0];

      fetch(datastructures?.file, {
        method: "GET",
      })
        .then((resp) => resp.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = "Document.pdf"; // the filename you want
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
        });
      // setStatus(false)
      // SetCheckboxStatus(datastructures)
      // setDocumentData(datastructures);
    });
  };

  const Checkboxhandler = (e: any) => {
    setStatus(e?.target?.checked);

    API.put("/vehicle_inward/inward_view/", {
      top_vault_id: productId,
      completed: e?.target?.checked,
      parameter_name: documentData?.parametername,
    })
      .then((res: any) => { })
      .catch((err: any) => {
        const { data } = err?.response;
        setStatus(!e?.target?.checked);
        // setCheckboxdataMsg({msg:data[0],type:"error"})
        // Validationmodal.open()
      });
  };

  useEffect(() => {
    if (Counter > 0) {
      API.get(
        "/vehicle_inward/inward_view/",
        {
          top_vault_id: productId,
          parameter: "documents",
        },
        0
      ).then((res: any) => {
        // setDocumentCounter((prev: any) => prev + 1);
        setLoader(false);
        var datafiler = res?.data?.documents.filter((item: any, index: any) => {


          return Object.keys(item).includes(DocName)
        });

        setDofilecName(datafiler[0]?.name)


        var datastructures = Object?.entries(datafiler[0])?.map(([key, val]) => {
          return { parametername: key, file: val };
        })[0];

        setStatus(false);
        // SetCheckboxStatus(datastructures)
        setDocumentData(datastructures);
      });
    }
  }, [Counter]);




  const DeleteImagehandler = () => {
    setLoader(true);

    API.delete(`/vehicle_inward/inward_view/`, {
      parameter_name: documentData && documentData?.parametername,
      top_vault_id: productId,
      data: documentData && documentData?.file,
    })
      .then((res: any) => {
        setCounter((prev: any) => prev + 1);
           setDofilecName(null)   
        setLoader(false);
      })
      .catch((err: any) => {
        setLoader(false);
      });
  };

  const uploadImage = (e: any) => {
    // var oldUrlSplit =
    //   documentData && documentData?.file == "null"
    //     ? ""
    //     : documentData?.file?.split(".net")[1];
    var filedata = e?.target?.files[0];

    if (filedata) {
      pdfnamemodal.open()
      setFiledata(e?.target?.files[0])



    }


    // var parameterName = documentData && documentData?.parametername;

    // InwardS3images(
    //   [filedata],
    //   oldUrlSplit,
    //   parameterName,
    //   setCounter,
    //   productId,
    //   setLoader,
    //   null
    // );
  };

  // console.log(documentData,"documentDatadocumentData")
  const SubmitPdfFile = (updatefilename: any) => {
    var oldUrlSplit =
      DocList && DocList?.file == "null" ? "" : DocList?.file?.split(".net")[1];

    var docfile = { doc_name: updatefilename }


    var parameterName = DocList && DocList?.parametername;

    InwardS3images(
      [FileData],
      oldUrlSplit,
      parameterName,
      setCounter,
      productId,
      setLoader,
      null,
      docfile
    );
  }
  return (
    <div>

      {pdfnamemodal.isOpen &&
        <InwardPdfNameModal
          isOpen={pdfnamemodal.isOpen}
          onCloseModal={pdfnamemodal.close}
          SubmitPdfFile={SubmitPdfFile}
          FileData={FileData && FileData?.name?.split(".pdf")[0]}
        />}
       {

<InwardPdfNameModal

isOpen={pdfFilemodal.isOpen}
onCloseModal={pdfFilemodal.close}
SubmitPdfFile={SubmitPdfNameFile}
FileData={DocfileName && DocfileName }
/>

       }
      <Modal
        open={isOpen}
        // onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {documentData && documentData?.file !=="null" &&
            <InwardPdfViewer
              canvasRef={canvasRef}
              onDocumentLoadFail={onDocumentLoadFail}
              onDocumentLoadSuccess={onDocumentLoadSuccess} documentData={documentData}
            />
          }
          <input
            accept=".pdf"
            id="profilePhoto"
            type="file"
            style={{ display: "none" }}
            onChange={uploadImage}
            ref={inputElRef}
          />
          <Box sx={{
            width: "100%", display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}>

{documentData?.file !=="null" && 
              <div
              
              style={{    display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "1rem"}}
              ><p>{DocfileName && DocfileName}</p>
           {   (Premission?.includes("C")||Premission?.includes("U") ) &&

           
            
              
              <img   src={editmail} onClick={() =>  FileEditHanlder()} title="Edit File Name" style={{ width: '1.4rem', height: '1.4rem', cursor: "pointer" }} />
         
            
}
              </div>
              
              
              }
            <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}></Box>
            <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
              {loader ? ' ' : <> 

               {(Premission?.includes("C")||Premission?.includes("D")||Premission?.includes("U") ) && <>
              <Button
                sx={{
                  "&:hover": {
                    transform: "Scale(1.05)",
                    transition: "transform 0.5s ease",
                  },
                }}
                onClick={() => downloadClicked()}
                disabled={(Premission?.includes("C")||Premission?.includes("U"))?false:true}
              >
                Download  <FileDownloadcon sx={{ fontSize: '1.5rem' }} />
              </Button>
                <Button
                  sx={{
                    "&:hover": {
                      transform: "Scale(1.05)",
                      transition: "transform 0.5s ease",
                    },
                  }}
                  onClick={() => browseFiles()}
                >

{documentData && documentData?.file !=="null"?"Replace File":"Upload File" }
                  
                </Button>
                
                <Button
                  sx={{
                    "&:hover": {
                      transform: "Scale(1.05)",
                      transition: "transform 0.5s ease",
                    },
                  }}
                  disabled={Premission?.includes("D")?false:true}
                  onClick={() => DeleteImagehandler()}
                >
                  Delete File
                </Button>
                {DocName && DocName == "upload_doc-1" && (
                  <Checkbox
                    size="small"
                    style={{ padding: "3px", marginTop: '2px' }}
                    {...label}
                    disabled={(Premission?.includes("C")||Premission?.includes("U"))?false:true}
                    title="Validation"
                    checked={status}
                    onChange={(e: any) => Checkboxhandler(e)}
                    checkedIcon={
                      <Box sx={{ backgroundColor: "white", borderRadius: "50%", height: "1rem", width: "1rem", marginTop: "-8px", marginRight: '8px' }}>
                        <VerifiedIcon
                          sx={{
                            color: "#39FF14",
                            position: "relative",
                            margin: "-0.5rem 0",
                          }}
                        />
                      </Box>
                    }
                    // onChange={(e: any) => Checkboxhandler(e)}
                    color="success"
                    sx={{
                      "& .MuiSvgIcon-root": { fontSize: 22 },
                      color: "#007fff",
                      width: "2rem",
                      marginRight: "0.5rem",
                    }}
                  />
                )}

</>}
              </>
              
              
              }
              <CancelIcon
                titleAccess="Close"
                sx={{ cursor: "pointer" }}
                onClick={() => ClosedHandler()}
              />

            </Box>
          </Box>
          <Box
            sx={{
              textAlign: "center",
              height: { lg: "87vh", xl: "88vh" },
              minWidth: "35vw",             
            }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3"
          >
            <a
              // onClick={() => downloadClicked(file_paths_read, fileNo, fileName)}
              style={
                loader ? { pointerEvents: "none" } : { pointerEvents: "auto" }
              }
            >
              {loader ? (
                // <CircularProgressWithLabel value={progress} />
                <span
                  style={{
                    padding: "0 1rem",
                    height: "80vh",
                    width: "30vw",
                    // display: "flex",
                  }}
                >
                  <Skeleton
                    variant="rectangular"
                    width="95%"
                    height="96%"
                    sx={{ padding: "0 1rem", margin: "0 auto" }}
                  />
                </span>
              ) : (
                <>

               {documentData?.file !=="null"?
                  <canvas
                    ref={canvasRef}
                    // id= {isExpand==true ?"canvasexpand" : "canvas"}
                    // title={fileName}
                    width="auto"
                    height="20"
                  />
              :<p style={{    marginTop:" 30rem"}}>NO PDF </p>}
                </>
              )}
            </a>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default InwardDocumentModal;