import * as React from "react";
import {
  Box,
  Button,
  Card,
  CardMedia,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Divider,
  Typography,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import RestoreIcon from "@mui/icons-material/Restore";
import PushPinIcon from "@mui/icons-material/PushPin";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setIsCompare } from "../../Redux/Actions/compareopen.action";
import { useHistory } from "react-router-dom";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { API } from "../../api-services";

interface ICompareHistoryModalProps {
  // setCloseCounts: any;
  Open: any;
  Close: any;
  setModalClick:any
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40vw",
  height: "fit-content",
  // width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px",
  // border: '2px solid #000',
  // boxShadow: 24,
  p: 1,
};

function CompareHistoryModal(props: ICompareHistoryModalProps) {
  const { Close, Open,setModalClick } = props;
  const { combinationId } = useRouteParams<any>();
  // const {
  //     Open,
  //     Close,
  //     // ParameterNames,
  //   } = props;
  // const { setCloseCounts } = props;
  const [searchHistory, setSearchHistory] = React.useState<any>([]);
  const [deleteCount, setDeleteCount] = React.useState<any>(0);

  const history = useHistory<any>();
  const callCompare = (item:any)=>{
    history.push(`/CompareCombination/${item?.id}`)
    let abc =Close()
  }
  const getHistoryData = () => {
    API.get(
      "/customer/compare_cart/",
      {
        compare_history: true,
      },
      0
    )
      .then((res: any) => {
        setSearchHistory(res.data);
      })
      .catch((err: any) => {});
  };
  // React.useEffect(()=>{
  //   return()=>setModalClick(false)
  // },[])
  React.useEffect(() => getHistoryData(), [deleteCount]);

  return (
    <div>
      <Modal
        open={Open}
        onClose={Close}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Compare History
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={Close}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box>
            {/* <Box sx={{ display: "flex", marginBottom: "0.2rem" }}>
              <RestoreIcon />
              <Typography
                sx={{
                  fontSize: "1rem",
                  width: "100%",
                  textAlign: "center",
                  lineHeightL: "1.2",
                }}
              >
                Compare History
              </Typography>
            </Box>
            <Divider /> */}
            <Box>
              <Table>
                <TableBody>
                  {searchHistory &&
                    searchHistory?.map((item: any, index: any) => {
                      return (
                        <TableRow
                          sx={{ cursor: "pointer" }}
                         
                        >
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottom: "none",
                            }}
                          >
                            {index + 1}
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottom: "none",
                            }}
                            onClick={() =>
                                callCompare(item)
                              }
                          >
                            <Typography sx={{ fontSize: "1rem" }}>
                              {item?.name}
                            </Typography>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottom: "none",
                              width: "5rem",
                            }}
                          >
                            <PushPinIcon
                             sx={item?.is_pinned && {color: 'green'}}
                              onClick={(event:any) => {
                                event.stopPropagation();
                                API.put(
                                  "/customer/compare_cart/",
                                  { compare_id: item.id, is_pinned: !item?.is_pinned },
                                  {},
                                  0
                                )
                                  .then((res: any) => {
                                    setDeleteCount((prev: any) => prev + 1);
                                  })
                                  .catch((err: any) => {
                                    console.log(err);
                                  });
                              }}
                            />
                            <DeleteForeverIcon
                              onClick={(event:any) => {
                                event.stopPropagation();
                                API.delete(
                                  "/customer/compare_cart/",
                                  { compare_id: item?.id },
                                  0
                                )
                                  .then((res: any) => {
                                    setDeleteCount((prev: any) => prev + 1);
                                  })
                                  .catch((err: any) => {
                                    console.log(err);
                                  });
                              }}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
              </Table>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
export default CompareHistoryModal;
