// react

// import { triangulationSelectors } from 'app/Redux/Reducers/TrinagulationReducers/triangulation.reducer';

import React from "react";
import { useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import CalculateIcon from "@mui/icons-material/Calculate";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
// import CalculatorValueInputModal from './CalculatorValueInputModal/CalculatorValueInputModal';
import styles from "./TriangulationRow.module.scss";
import { Box, TableBody, TableCell, TableRow } from "@mui/material";
import { useBaseModal } from "../../SearchPage/useBaseModal";
import { MODULES } from "../../../Constants/modules.constants";
import calculatorService from "../../../Redux/Services/calculator.service";
import useFetch from "../../../Core/CustomHooks/useFetch";
import { ICalculator } from "../../../Redux/Reducers/calculator.reducer";
import { triangulation_calculator } from "../../../Configuration/Costing/calculator.constant";
import { triangulationSelectors } from "../../../Redux/Reducers/triagulation.reducer";
import CalculatorValueInputModal from "./CalculatorValueInputModal";
import calcIcon from '../../../Assets/images/calculator.svg';


// interfaces and types
// lazy

// ----------------------------------------------------------------------------------

interface TriangulationRowProps {
  value: any;
  setCountValue: any;
}

interface Params {
  projectID: string;
  topVaultID: string;
  topVaultName: string;
}

const TriangulationRow: React.FC<TriangulationRowProps> = (props) => {
  const { value, setCountValue } = props;

  const { projectID, topVaultID, topVaultName } = useParams<Params>();

  const [count, setCount] = React.useState<number>(0);

  const [calculatorName, setCalculatorName] = React.useState<
    string | undefined
  >(undefined);

  const history = useHistory();

  const inputModal = useBaseModal();

  const { 0: gettingCalvalue, 1: calculator } = useFetch<ICalculator>({
    method: calculatorService.getByIdTriangulation,
    args: [topVaultID, calculatorName],
    dependencies: [calculatorName],
    condition: calculatorName !== undefined,
  });

  React.useEffect(() => {
    setCountValue(count);
  }, [count]);

  const handleCostingRouting = (id: number) => {


    history.push(
      `/costing/${MODULES.COSTING}/costing-triangulation/${projectID}/${topVaultID}/${topVaultName}/${id}`
    );
  };

  const handleCostingRoutingEdit = (id: number) => {
    const calculatorName = triangulation_calculator.filter(
      (data: any) => data.key == id
    );
    setCalculatorName(calculatorName[0].text);
    inputModal.open();
  };

  const percentage = value.percentage;

  const triangulationData: any = useSelector(triangulationSelectors.selectAll);

  const permisson =
    triangulationData.length != 0 && triangulationData[0].allowed_action
      ? !triangulationData[0]?.allowed_action.includes("C" || "U")
      : false;

  return (
    <>
      <TableBody
        className={styles.tableBody}
        adl-scrollbar="true"
        adl-scrollbar-width="0.6">
        <TableRow key={value.id} className={styles.tablerow}>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              borderRight: "1px solid",
              borderColor: "primary.light",
            }}>
            {value.name}
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              borderRight: "1px solid",
              borderColor: "primary.light",
            }}>
            <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem' }}>
              <SettingsIcon
                titleAccess="Setting"
                sx={{ cursor: "pointer" }}
                onClick={() => handleCostingRouting(value?.oem_data)}
              />
              <img src={calcIcon}
                title="Calculator"
                style={{ cursor: "pointer", height: '1.4rem' }}
                onClick={() => handleCostingRoutingEdit(value?.oem_data)}
              />
            </Box>
          </TableCell>          
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              borderRight: "1px solid",
              borderColor: "primary.light",
            }}>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}>             
              <CurrencyRupeeIcon titleAccess="rupee" sx={{ fontSize: '1.5rem' }} />
              {value?.manufacturing_cost}
            </Box>
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}>
            <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem' }}>
              <SettingsIcon
                titleAccess="Setting"
                sx={{ cursor: "pointer" }}
                onClick={() => handleCostingRouting(value?.asi_data)}
              />
              <img src={calcIcon}
                title="Calculator"
                style={{ cursor: "pointer", height: '1.4rem' }}
                onClick={() => handleCostingRoutingEdit(value?.asi_data)}
              />
            </Box>          
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              background: value?.oem_data == 1 ? "#c5e9ba" : "#4bad50",
            }}>
            <Box
              style={{
                display: "flex",
                justifyContent: "space-between",
                color: value?.oem_data == 1 ? "#007fff" : "#ffffff",
              }}>
              <CurrencyRupeeIcon titleAccess="rupee" sx={{ fontSize: '1.5rem' }} />
              {value.showroom_cost}
            </Box>
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              background: Math?.abs(percentage) <= 5 ? "#92D050" : "#f7e07e",
            }}>
            <Box
              style={{
                height: "100%",
                width: "100%",
              }}>
              {Number?.isNaN(percentage) ? 0 : `${percentage} %`}
            </Box>
          </TableCell>
        </TableRow>
      </TableBody>
      <CalculatorValueInputModal
        isOpen={inputModal.isOpen}
        onClose={inputModal.close}
        fetchCalculator={gettingCalvalue}
        calculatorData={calculator}
        setCount={setCount}
        count={count}
      />
    </>
  );
};

// ----------------------------------------------------------------------------------

export default TriangulationRow;
