import {
  ADD_COMPARE_COUNT,
  OPEN_COMPARE,
  SET_COMPARE_OPTIONS,
  SET_PERMISSION_FILTER,
  SET_USER_SCEARCH,
  INCREASE_COUNTER,
  VALIDATION_COUNTER,
  RECYCLEBIN_COUNTER,
  SELECT_CALCULATORS,
  BOM_ALL_TABLE_DATA
} from "../Actions/compareopen.action";

const initialState = {
  is_compare: "false",
  add_count: 0,
  actionCount: 0,
  validationCounter: 1,
  recyclebinCounter: 1,
  selcetedCalculators: [],
  allBOMData: [],
};

export default (state: any = initialState, action: any) => {
  switch (action.type) {
    case OPEN_COMPARE:
      return Object.assign({}, state, {
        is_compare: action.payload,
      });

    case ADD_COMPARE_COUNT:
      return Object.assign({}, state, {
        add_count: action.payload,
      });

    case SET_COMPARE_OPTIONS:
      return Object.assign({}, state, {
        compare_options: action.payload,
      });

    case SET_PERMISSION_FILTER:
      return Object.assign({}, state, {
        permission_filter: action.payload,
      });

    case SET_USER_SCEARCH:
      return Object.assign({}, state, {
        selected_userId: action.payload,
      });

    case INCREASE_COUNTER:
      return Object.assign({}, state, {
        actionCount: action.payload,
      });

    case VALIDATION_COUNTER:
      return Object.assign({}, state, {
        validationCounter: action.payload,
      });

    case RECYCLEBIN_COUNTER:
      return Object.assign({}, state, {
        recyclebinCounter: action.payload,
      });

      case SELECT_CALCULATORS:
        return Object.assign({}, state, {
          selcetedCalculators: action.payload,
        });
    case BOM_ALL_TABLE_DATA:
      return Object.assign({}, state, {
        allBOMData: action.payload,
      });
    default:
      return state;
  }
};
