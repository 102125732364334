// react
import React, { useEffect, useState } from 'react';

import Plotly from "plotly.js";
import createPlotlyComponent from "react-plotly.js/factory";
import { ID } from '../../../utlis/commonInterfaces';
import { numberFormat } from '../../../utlis/valueFormat';


// vendors
// services
// utils
// constants
// factories
// core
// redux
// ui-components
// others
// styles

// interfaces and types
// lazy

// ----------------------------------------------------------------------------------

interface ModuleChartProps {
   analysis?: any;
   projectId?: ID;
   parameter?: string;
   topVault?: any;


}

const BarGraph: React.FC<ModuleChartProps> = ( props ) => {
   const { projectId, analysis, parameter, topVault } = props;

   const [coun, setCount] = useState<any>();
   const Plot = createPlotlyComponent( Plotly );

   useEffect( () => {
      setCount( analysis );
   }, analysis );


   return (
      <div>
         <Plot
            data={
               analysis?.map( ( values: any ) => {

                  return ( {
                     x: [values.name],
                     y: [parameter ? values[`${parameter}`] : values.cost],
                     type: "bar",
                     text: ` ${numberFormat( parameter ? values[`${parameter}`] : values.cost )?.toString().replace( /\B(?=(\d{3})+(?!\d))/g, ',' )}`,
                     textposition: 'bottom',
                     hoverinfo: 'x+y',
                     name: values.name
                  } );
               } )
            }
            layout={ {
               xaxis: {
                  visible: true,
                  showticklabels: false,
                  fixedrange: true,
                  rangemode: "tozero",
                  title: {
                     text: `<b>${topVault ? topVault : parameter}</b>`,
                  },
               },
               yaxis: {
                  fixedrange: true,
                  rangemode: "tozero",
               },
               height: 170,
               width: 240,
               margin: {
                  l: 30, t: 10, r: 10, b: 25,
                  pad: 0,
               },
               font: {
                //@ts-ignore
                  size: '10'
               },
               showlegend: false,
               // legend: { "orientation": "t" }
            } }
            config={
               {
                  modeBarButtonsToRemove: [
                     "zoom2d",
                     "pan2d",
                     "select2d",
                     "lasso2d",
                     "zoomIn2d",
                     "zoomOut2d",
                     "autoScale2d",
                     "resetScale2d",
                     "hoverClosestGl2d",
                     "hoverClosestPie",
                     "toggleHover",
                     "resetViews",
                     "sendDataToCloud",
                     "hoverClosestCartesian",
                     "hoverCompareCartesian",
                     "toggleSpikelines",
                  ],
                  displaylogo: false,
               } }
         />
      </div>

   );
};

// ----------------------------------------------------------------------------------

export default BarGraph;