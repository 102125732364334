import { Box, Skeleton, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import React, { useEffect, useState } from "react";
import { API } from "../../../api-services";
import moment from "moment";
import EqualizerIcon from '@mui/icons-material/Equalizer';
import { useGraphBaseModal } from "../useGraphBaseModal";
import GraphShowModal from "../GraphShowModal";

interface LoginActivityCountI { }

const LoginActivityCount: React.FC<LoginActivityCountI> = (props) => {
  const [dataGet, setDataGet] = useState<any>();
  const [dataLoad, setDataLoad] = useState<boolean>(true);
  const graphBaseModal = useGraphBaseModal();

  useEffect(() => {
    setDataLoad(true);
    API.get(
      `/customer/activity_log_status/`, {}, 0)
      .then((res: any) => {
        setDataLoad(false);
        setDataGet(res?.data);
      })
  }, [])


  const TimePeriod = (period: any, type: any) => {
    graphBaseModal.open(period, type);
  }

  return (<>
    {!dataLoad ? <>
      <GraphShowModal Open={graphBaseModal.isOpen} Close={graphBaseModal.close} id={graphBaseModal.id} analysisType={graphBaseModal.analysisType} />
      {dataGet && <Table>
        <TableHead sx={{ position: 'sticky', top: '0', backgroundColor: 'white', zIndex: '1' }}>
          <TableRow>
            <TableCell sx={{ cursor: 'pointer', fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', textAlign: 'left', lineHeight: '1.2', backgroundColor: 'primary.light', width: '14rem', }} rowSpan={2}>Logins & Activity</TableCell>
            <TableCell sx={{ cursor: 'pointer', fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', backgroundColor: 'primary.light' }} title={`${moment().format('DD-MMM')}`}>Today <EqualizerIcon titleAccess='Graph' style={{ cursor: "pointer", marginBottom: '-0.3rem' }} onClick={() => { return TimePeriod('1', 'loginAnalysis') }} /></TableCell>
            <TableCell sx={{ cursor: 'pointer', fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', backgroundColor: 'primary.light' }} title={`${moment().format('DD-MMM')} - ${moment().subtract(7, 'd').format('DD-MMM')}`}  >7D <EqualizerIcon titleAccess='Graph' style={{ cursor: "pointer", marginBottom: '-0.3rem' }} onClick={() => { return TimePeriod('7', 'loginAnalysis') }} /></TableCell>
            <TableCell sx={{ cursor: 'pointer', fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', backgroundColor: 'primary.light' }} title={`${moment().format('DD-MMM')} - ${moment().subtract(30, 'd').format('DD-MMM')}`} >30D <EqualizerIcon titleAccess='Graph' style={{ cursor: "pointer", marginBottom: '-0.3rem' }} onClick={() => { return TimePeriod('30', 'loginAnalysis') }} /></TableCell>
            <TableCell sx={{ cursor: 'pointer', fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', backgroundColor: 'primary.light' }} title={`${moment().format('DD-MMM')} - ${moment().subtract(365, 'd').format('DD-MMM')}`}>365D <EqualizerIcon titleAccess='Graph' style={{ cursor: "pointer", marginBottom: '-0.3rem' }} onClick={() => { return TimePeriod('365', 'loginAnalysis') }} /></TableCell>
            <TableCell sx={{ cursor: 'pointer', fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', backgroundColor: 'primary.light' }} title={'From the day one'}>All <EqualizerIcon titleAccess='Graph' style={{ cursor: "pointer", marginBottom: '-0.3rem' }} onClick={() => { return TimePeriod('all', 'loginAnalysis') }} /></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', width: '14rem', }}>Total Logins</TableCell>
            <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{(dataGet?.total_login?.internal?.["1day"] + dataGet?.total_login?.external?.["1day"])?.toLocaleString("en-IN")}</TableCell>
            <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{(dataGet?.total_login?.internal?.["7day"] + dataGet?.total_login?.external?.["7day"])?.toLocaleString("en-IN")}</TableCell>
            <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{(dataGet?.total_login?.internal?.["30day"] + dataGet?.total_login?.external?.["30day"])?.toLocaleString("en-IN")}</TableCell>
            <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{(dataGet?.total_login?.internal?.["365day"] + dataGet?.total_login?.external?.["365day"])?.toLocaleString("en-IN")}</TableCell>
            <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{(dataGet?.total_login?.internal?.["all"] + dataGet?.total_login?.external?.["all"])?.toLocaleString("en-IN")}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem 0.2rem 2rem', width: '14rem', }}>External</TableCell>
            <TableCell sx={{ fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{(dataGet?.total_login?.external?.["1day"])?.toLocaleString("en-IN")}</TableCell>
            <TableCell sx={{ fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{(dataGet?.total_login?.external?.["7day"])?.toLocaleString("en-IN")}</TableCell>
            <TableCell sx={{ fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{(dataGet?.total_login?.external?.["30day"])?.toLocaleString("en-IN")}</TableCell>
            <TableCell sx={{ fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{(dataGet?.total_login?.external?.["365day"])?.toLocaleString("en-IN")}</TableCell>
            <TableCell sx={{ fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{(dataGet?.total_login?.external?.["all"])?.toLocaleString("en-IN")}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem 0.2rem 2rem', width: '14rem', }}>Internal</TableCell>
            <TableCell sx={{ fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{(dataGet?.total_login?.internal?.["1day"])?.toLocaleString("en-IN")}</TableCell>
            <TableCell sx={{ fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{(dataGet?.total_login?.internal?.["7day"])?.toLocaleString("en-IN")}</TableCell>
            <TableCell sx={{ fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{(dataGet?.total_login?.internal?.["30day"])?.toLocaleString("en-IN")}</TableCell>
            <TableCell sx={{ fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{(dataGet?.total_login?.internal?.["365day"])?.toLocaleString("en-IN")}</TableCell>
            <TableCell sx={{ fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{(dataGet?.total_login?.internal?.["all"])?.toLocaleString("en-IN")}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', width: '14rem', }}>Total Users</TableCell>
            <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{(dataGet?.total_user?.internal?.["1day"] + dataGet?.total_user?.external?.["1day"])?.toLocaleString("en-IN")}</TableCell>
            <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{(dataGet?.total_user?.internal?.["7day"] + dataGet?.total_user?.external?.["7day"])?.toLocaleString("en-IN")}</TableCell>
            <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{(dataGet?.total_user?.internal?.["30day"] + dataGet?.total_user?.external?.["30day"])?.toLocaleString("en-IN")}</TableCell>
            <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{(dataGet?.total_user?.internal?.["365day"] + dataGet?.total_user?.external?.["365day"])?.toLocaleString("en-IN")}</TableCell>
            <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{(dataGet?.total_user?.internal?.["all"] + dataGet?.total_user?.external?.["all"])?.toLocaleString("en-IN")}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem 0.2rem 2rem', width: '14rem', }}>External</TableCell>
            <TableCell sx={{ fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{(dataGet?.total_user?.external?.["1day"])?.toLocaleString("en-IN")}</TableCell>
            <TableCell sx={{ fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{(dataGet?.total_user?.external?.["7day"])?.toLocaleString("en-IN")}</TableCell>
            <TableCell sx={{ fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{(dataGet?.total_user?.external?.["30day"])?.toLocaleString("en-IN")}</TableCell>
            <TableCell sx={{ fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{(dataGet?.total_user?.external?.["365day"])?.toLocaleString("en-IN")}</TableCell>
            <TableCell sx={{ fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{(dataGet?.total_user?.external?.["all"])?.toLocaleString("en-IN")}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem 0.2rem 2rem', width: '14rem', }}>Internal</TableCell>
            <TableCell sx={{ fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{(dataGet?.total_user?.internal?.["1day"])?.toLocaleString("en-IN")}</TableCell>
            <TableCell sx={{ fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{(dataGet?.total_user?.internal?.["7day"])?.toLocaleString("en-IN")}</TableCell>
            <TableCell sx={{ fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{(dataGet?.total_user?.internal?.["30day"])?.toLocaleString("en-IN")}</TableCell>
            <TableCell sx={{ fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{(dataGet?.total_user?.internal?.["365day"])?.toLocaleString("en-IN")}</TableCell>
            <TableCell sx={{ fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{(dataGet?.total_user?.internal?.["all"])?.toLocaleString("en-IN")}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem', width: '14rem', }}>Total Activity</TableCell>
            <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{(dataGet?.total_activity?.internal?.["1day"] + dataGet?.total_activity?.external?.["1day"])?.toLocaleString("en-IN")}</TableCell>
            <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{(dataGet?.total_activity?.internal?.["7day"] + dataGet?.total_activity?.external?.["7day"])?.toLocaleString("en-IN")}</TableCell>
            <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{(dataGet?.total_activity?.internal?.["30day"] + dataGet?.total_activity?.external?.["30day"])?.toLocaleString("en-IN")}</TableCell>
            <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{(dataGet?.total_activity?.internal?.["365day"] + dataGet?.total_activity?.external?.["365day"])?.toLocaleString("en-IN")}</TableCell>
            <TableCell sx={{ fontWeight: 'bold', fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{(dataGet?.total_activity?.internal?.["all"] + dataGet?.total_activity?.external?.["all"])?.toLocaleString("en-IN")}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem 0.2rem 2rem', width: '14rem', }}>External</TableCell>
            <TableCell sx={{ fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{(dataGet?.total_activity?.external?.["1day"])?.toLocaleString("en-IN")}</TableCell>
            <TableCell sx={{ fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{(dataGet?.total_activity?.external?.["7day"])?.toLocaleString("en-IN")}</TableCell>
            <TableCell sx={{ fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{(dataGet?.total_activity?.external?.["30day"])?.toLocaleString("en-IN")}</TableCell>
            <TableCell sx={{ fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{(dataGet?.total_activity?.external?.["365day"])?.toLocaleString("en-IN")}</TableCell>
            <TableCell sx={{ fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{(dataGet?.total_activity?.external?.["all"])?.toLocaleString("en-IN")}</TableCell>
          </TableRow>
          <TableRow>
            <TableCell sx={{ fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem 0.2rem 2rem', width: '14rem', }}>Internal</TableCell>
            <TableCell sx={{ fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{(dataGet?.total_activity?.internal?.["1day"])?.toLocaleString("en-IN")}</TableCell>
            <TableCell sx={{ fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{(dataGet?.total_activity?.internal?.["7day"])?.toLocaleString("en-IN")}</TableCell>
            <TableCell sx={{ fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{(dataGet?.total_activity?.internal?.["30day"])?.toLocaleString("en-IN")}</TableCell>
            <TableCell sx={{ fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{(dataGet?.total_activity?.internal?.["365day"])?.toLocaleString("en-IN")}</TableCell>
            <TableCell sx={{ fontSize: '1rem', borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{(dataGet?.total_activity?.internal?.["all"])?.toLocaleString("en-IN")}</TableCell>
          </TableRow>
        </TableBody>
      </Table>}
    </>
      :
      <Box sx={{height:"28vh",width:"100%"}}>
        <Skeleton variant="rectangular" height="100%" />
      </Box>
    }
  </>
  )
}

export default LoginActivityCount;