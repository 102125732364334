import { API } from "../../api-services";
const url = "/auth/permissions/";
const newUrl = "/auth/permission/";

export default {
    get () {
        return API.get( url );
    },

    getNewPermissions () {
        return API.get( newUrl );
    }
};
