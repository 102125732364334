import * as React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { Box, Divider, Modal, Skeleton, Typography } from "@mui/material";
import { API } from "../../../api-services";

export interface IBOMEntryWeightAlertModalProps {
  onCloseModal: any;
  isOpen: any;
  queryData:any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: "35vw", xl: "27vw" },
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

export default function BOMEntryWeightAlertModal(
  props: IBOMEntryWeightAlertModalProps
) {
  const { onCloseModal, isOpen ,queryData} = props;


  const [WeigthData,setWeightData]=React.useState<any>({})
  const [Loader,setLoader]=React.useState<any>(false)
  React.useEffect(()=>{
    setLoader(true)
    API.get(`/xcpep/bom_hirearchy_function/`,{weight_validation:true,

      vault:queryData?.id}).then((res:any)=>{
        setLoader(false)
        setWeightData(res?.data)
      }).catch((err:any)=>{
        setLoader(false)
        console.log("Server Error")
      })
  },[queryData])

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Weight Delta Break-Up
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          { Loader?
            <Box sx={{ padding: "0 1rem 1rem" }}>



              <Skeleton/>
              <Skeleton/>
              <Skeleton/>
              <Skeleton/>
              <Skeleton/>
            </Box>:
          
          <Box sx={{ padding: "0 1rem 1rem" }}>
            <Box>
              <Box sx={{ display: "flex", alignItems: "center", columnGap: "1rem" }}>
                <Typography sx={{ fontSize: "1rem",width:'10rem' }}>
                  Assembly Weight
                </Typography>
                <Typography sx={{ fontSize: "1rem" }}>
                  : {WeigthData?.assembly_weight?.toFixed(2)} g
                </Typography>
            </Box>
             <Box sx={{ display: "flex", alignItems: "center", columnGap: "1rem" }}>
                <Typography sx={{ fontSize: "1rem",width:'10rem' }}>
                  Child Parts Weight
                </Typography>
                <Typography sx={{ fontSize: "1rem" }}>
                  : {WeigthData?.children_weight?.toFixed(2)} g
                </Typography>
             </Box>
             <Box sx={{ display: "flex", alignItems: "center", columnGap: "1rem" }}>
                <Typography sx={{ fontSize: "1rem",width:'10rem',color:WeigthData?.delta_weight_percentage <= WeigthData?.error_percentage * 100 && WeigthData?.delta_weight_percentage >= -WeigthData?.error_percentage * 100 ? "green" : "red"}}>Delta Weight</Typography>
                <Typography sx={{ fontSize: "1rem",color: WeigthData?.delta_weight_percentage <= WeigthData?.error_percentage * 100 && WeigthData?.delta_weight_percentage >= -WeigthData?.error_percentage * 100 ? "green" : "red" }}>
                  : {WeigthData?.diference} g ({WeigthData?.delta_weight_percentage}% {WeigthData?.status} Limit)
                </Typography>
            </Box>
              <Box sx={{ display: "flex", alignItems: "center", columnGap: "1rem" }}>
                <Typography sx={{ fontSize: "1rem",width:'10rem' }}>Limit</Typography>
                <Typography sx={{ fontSize: "1rem" }}>: {WeigthData?.limit?.toFixed(2)} g ({WeigthData?.error_percentage*100}%)</Typography>
              </Box>
            </Box>
            <Typography sx={{ fontSize: "1rem", marginTop: "1rem" }}>
              Assembly weight should be within <b>{WeigthData?.error_percentage*100}%</b> of the sum of child
              parts weight
            </Typography>
          </Box>
}
        </Box>
      </Modal>
    </div>
  );
}
