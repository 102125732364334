import { Box, Divider, IconButton, Typography } from '@mui/material';
import * as React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useHistory } from 'react-router-dom';
import { useRouteParams } from '../../../ui-reusable-component/useRouteParams';
import pdfDownload from '../../../Assets/images/pdf.png';
import { API } from '../../../api-services';
import { AUTH } from '../../../Redux/Services/auth.service';

export interface ICostingGuideLinesProps {}

interface Params {
	projectId: any;
	projectname: any;
}

export default function CostingGuideLines(props: ICostingGuideLinesProps) {
	const { projectId, projectName } = useRouteParams<any>();
	const history = useHistory();
	const handleClick = () => {
		history.goBack();
	};
	const [downloadLoader, setDownloadLoader] = React.useState<any>(false);
	const guidelinesHandler = () => {
		// window.open(`/#/costingguidelines/${projectId}/${projectname}`, '_blank');
		// history.push(`/costingguidelines/${projectId}/${projectname}`);
		setDownloadLoader(true);
		try {
			// API.apiBase + `cost/generate_cost_guide_line/?project=${projectId}&user=${AUTH.user}`

			fetch(
				API.apiBase +
					`cost/generate_cost_guide_line/?project=${projectId}&user=${AUTH.user}`,
				{
					method: 'GET',
				}
			).then((resp) => window.open(resp.url));
		} catch (e) {
			setDownloadLoader(false);
		}
	};

	const [content, setContent] = React.useState<any>('');
	const [contentDataCount, setContentDataCount] = React.useState<any>();
	const [guidelinesId, setGuidelinesId] = React.useState<any>();
	const [guidelineScequenceId, setGuidelineScequenceId] = React.useState<any>();
	const [validation, setValidation] = React.useState<any>(false);
	const [guideLinesData, setGuideLinesData] = React.useState<any>({});

	React.useEffect(() => {
		// Fetch the data from your API
		API.get('/cost/cost_guide_line/', { project: projectId }, 0)
			.then((res) => {
				setGuideLinesData(res.data);
			})
			.catch((err) => {
				console.error('Error fetching data:', err);
			});
	}, [projectId]);

	console.log('getGuidelinesData', guideLinesData);

	return (
		<div>
			<Box>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'flex-end',
						width: '100%',
						padding: '0 0.5rem',
					}}>
					<Box>
						{/* <IconButton
              sx={{ cursor: "pointer" }}
              onClick={() => handleClick()}
            >
              <ArrowBackIcon color="primary" titleAccess="Go Back" />
            </IconButton> */}
					</Box>
					<Box sx={{ width: '100%', textAlign: 'center' }}>
						<Typography sx={{ fontSize: '2rem', fontWeight: '600' }}>
							{projectName}
						</Typography>
						<Typography>Cost Estimation Guidelines & Assumption</Typography>
					</Box>
					<Box>
						<img
							src={pdfDownload}
							alt='pdfDownload'
							title='Costing Guideline Download'
							style={{ cursor: 'pointer', height: '2rem', marginRight: '2rem' }}
							onClick={() => guidelinesHandler()}
						/>
					</Box>
				</Box>
				{/* <Divider sx={{ borderColor: "primary.light",margin:'0 1rem' }} /> */}
				<Box
					sx={{
						margin: '0rem 2rem',
						width: '97%',
						height: { lg: '80vh', xl: '85vh' },
						borderTop: '1px solid',
						// borderRadius: "10px",
						borderColor: 'primary.light',
					}}
					adl-scrollbar='true'
					adl-scrollbar-width='0.3'>
					{/* <Typography sx={{ fontSize: "1rem", padding: "1rem 2rem" }}> */}
					{Array.isArray(guideLinesData) ? (
						guideLinesData.map((item: any) => (
							<Box sx={{padding:'1rem 2rem'}} key={item.id}>
								<div dangerouslySetInnerHTML={{ __html: item.content }} />
							</Box>
						))
					) : (
						<p>No guidelines data available.</p>
					)}
					{/* </Typography> */}
				</Box>
			</Box>
		</div>
	);
}
