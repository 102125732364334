import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { Backdrop, IconButton, Button, MenuItem, Select, FormControl } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { DataGrid, GridColDef } from "@mui/x-data-grid"; // Removed GridActionsCellItem
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { API } from "../../api-services";
import Swal from "sweetalert2";
import FetchedInfoDisplay from "./FetchedInfoDisplay";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  bgcolor: "background.paper",
  borderRadius: "12px",
  boxShadow: 24,
  p: 0,
};

interface IWatermarkModelProps {
  isOpen: any;
  onCloseModal: any;
  fieldId: any;
}

function FetchParameterInfoModal(props: IWatermarkModelProps) {
  const { isOpen, onCloseModal, fieldId } = props;
  const { projectId, categoryId, config_id } = useRouteParams<any>();
  const [data, setData] = React.useState<any>([]);

  const getInfodata = ()=>{
    API.get("/config/configuration_fetch_bucket/",{
        field_id:fieldId,
        config_id:config_id
    },0).then((res:any)=>{
        setData(res.data)
    }).catch((err:any)=>{})
  }

  React.useEffect(()=>{
    getInfodata()
  },[])
 
  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "3.5rem",
              p: 2,
              backgroundColor: "#007fff", // Same color as the button background
              borderTopLeftRadius: "12px",
              borderTopRightRadius: "12px",
            }}
          >
            <Typography
              sx={{
                fontSize: "1.2rem",
                fontWeight: "bold",
                width: "100%",
                textAlign: "center",
                color: "#fff", // White text for contrast
              }}
            >
              Fetched Parameter info ({data?.value_type})
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", color: "#fff", fontSize: "1.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider sx={{ borderColor: "primary.light" }} />
              <Box>
              <FetchedInfoDisplay fetch={data.fetch} value_type={data.value_type} />
              </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default FetchParameterInfoModal;
