import Plotly from 'plotly.js';
import React from 'react';
import createPlotlyComponent from 'react-plotly.js/factory';
import useWindowDimensions from '../../utlis/useWindowDimensions';

interface PieChartComponentI{
  pieChart:any;
}


const Plot = createPlotlyComponent(Plotly);
const COLORS = ["#007fff", "#a3c182"];

const PieChartComponent:React.FC<PieChartComponentI>=(props)=>{
const {pieChart}=props;
const pieData:any = pieChart && pieChart?.map((item:any)=>{return item?.value});
const pieDataLabel:any = pieChart && pieChart?.map((item:any)=>{return item?.name});
const { height, width } = useWindowDimensions();

return(<>
<div style={{width:'auto',textAlign: 'center'}}>
  <Plot
      data={[{
          values: pieData,
          labels: pieDataLabel,
          marker: {
              colors: COLORS
          },
          texttemplate: "%{percent}",
          type: "pie"
      }]}
      layout={{
          height: width > 1440 ? 220 : 160,
          width: width > 1440 ? 300 : 280,
          margin: {
              l: 15, t: 15, r: 15, b: 1,
          },
          showlegend: true,
          hovermode: 'closest'
      }}
      config={{
          displaylogo: false,
      }}
  />
</div>
</>)
};
export default PieChartComponent;