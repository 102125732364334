import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

import workstationService from "../Services/workstation.service";
import { ID } from "../../utlis/commonInterfaces";
import { Workstation } from "../Reducers/workstation.reducer";

// -------------------------------------------------------------------------------------
// api actions
// -------------------------------------------------------------------------------------

export interface GetWorkstionsArg {
    topVaultId: any;
    module: number;
}
const getWorkstations = createAsyncThunk(
    "workstation/get",
    async ( arg: GetWorkstionsArg, { rejectWithValue } ) => {
        try {
            const { data } = await workstationService.get( arg );

            return {
                workstations: data,
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

// -------------------------------------------------------------------------------------

export interface GetMappedWorkstionsArg {
    topVaultId: ID;
    vaultId?: any;
}
const getMappedWorkstations = createAsyncThunk(
    "workstation/get",
    async ( arg: GetMappedWorkstionsArg, { rejectWithValue } ) => {
        try {
            const { data } = await workstationService.getMappedWorkstations(
                arg
            );

            return {
                workstations: data,
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

// -------------------------------------------------------------------------------------

export type CreateWorkstationArg = Omit<Workstation, "id">;
const createWorkstation = createAsyncThunk(
    "workstation/create",
    async ( arg: CreateWorkstationArg, { rejectWithValue } ) => {
        try {
            const { data } = await workstationService.create( arg );

            return {
                workstation: data,
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

// -------------------------------------------------------------------------------------

export interface UpdateWorkstationArg {
    id: ID;
    updates: Partial<Omit<Workstation, "id">>;
}
const updateWorkstation = createAsyncThunk(
    "workstation/update",
    async ( arg: UpdateWorkstationArg, { rejectWithValue } ) => {
        try {
            await workstationService.update( arg );

            return {};
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

// -------------------------------------------------------------------------------------

export interface DeleteWorkstationArg {
    id: ID;
}
const deleteWorkstation = createAsyncThunk(
    "workstation/delete",
    async ( arg: DeleteWorkstationArg, { rejectWithValue } ) => {
        try {
            await workstationService.delete( arg );

            return {};
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

// -------------------------------------------------------------------------------------
// reducer actions
// -------------------------------------------------------------------------------------

const resetWorkstations = createAction( "workstation/reset" );

// -------------------------------------------------------------------------------------
// exports
// -------------------------------------------------------------------------------------

export {
    getWorkstations,
    getMappedWorkstations,
    createWorkstation,
    updateWorkstation,
    deleteWorkstation,
    resetWorkstations,
};
