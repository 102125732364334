import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import * as yup from "yup";
import { useFormik } from "formik";
import {
  Backdrop,
  Chip,
  Divider,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import styles from "./AddRevisionModal.module.scss";
import { API } from "../../api-services";
import { LoadingButton } from "@mui/lab";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import CancelIcon from "@mui/icons-material/Cancel";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import Swal from "sweetalert2";
import successIcon from "../../Assets/images/success.png";
import { useHistory } from "react-router-dom";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45vw",
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 0,
};
const RevisionTypeList = [
  { id: 1, text: "Main Costing", value: 1 },
  { id: 2, text: "Scenario Costing", value: 2 },
];
const validationSchema = yup.object({
  project_code: yup.mixed(),
  country: yup.string().required("Please enter Country"),
  city: yup.string().required("Please enter City"),
  revision_type: yup.string().required("Please select revision type"),
  revised_on: yup.string().required("Please select revised on date"),
  source: yup.boolean(),
  revision: yup.mixed(),
  item_name: yup.string().required("Please type name"),
});

const useStyles = makeStyles((theme) => ({
  select: {
    "&.MuiInputBase-root-MuiInput-root-MuiSelect-root": {
      borderBottom: "1px solid red",
    },
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface IWatermarkModelProps {
  isOpen: any;
  onCloseModal: any;
  actions?: any;
  Curdaction:any;
  getRevisionData?: any;
  setCounter:any;
}

function AddRevisionModal(props: IWatermarkModelProps) {
  const { isOpen, onCloseModal, actions, getRevisionData,Curdaction,setCounter } = props;
  const [revisionList, setRevisionList] = React.useState<any>([]);
  const { tab, year,month, revisionId, typeId, databaseName, status, search, categoryName } =
  useRouteParams<any>();
  const [isLoadingsave, setIsLoadingSave] = React.useState<any>(false);
  const [projectCodeList, setProjectCodeList] = React.useState<any>([]);
  const classes = useStyles();
  const history=useHistory<any>()
  var today: any = new Date();
  var dd: number | string = today.getDate();
  var mm: number | string = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  // @ts-ignore
  if (dd < 10) {
    dd = "0" + dd;
  }
  // @ts-ignore
  if (mm < 10) {
    mm = "0" + mm;
  }
  today = yyyy + "-" + mm + "-" + dd;

  const getAllRevisionList = () => {
    API.get("/api/db/location_revision/", {scenario:Curdaction?.scenario,revision_main:Curdaction?.revision_main}, 0)
      .then((res: any) => {
        let tempData = [
          ...[{ id: "true", item_name: "Fetch from Database" }],
          ...res.data,
        ];
        setRevisionList(tempData);
      })
      .catch((err: any) => {});
  };
  const getAllProjectCode = () => {
    API.get(
      "/xcpep/project/",
      {
        module: 3,
      },
      0
    )
      .then((res: any) => {
        setProjectCodeList(res.data);
      })
      .catch((err: any) => {});
  };
  React.useEffect(() => {
    getAllRevisionList();
    getAllProjectCode();
  }, []);
  React.useEffect(() => {
    if (actions !== "add") {
      formik.setFieldValue("project_code", actions.project_code);
      formik.setFieldValue("country", actions?.country);
      formik.setFieldValue("city", actions.city);
      formik.setFieldValue("revision_type", actions?.revision_type);
      formik.setFieldValue("revised_on", actions.revised_on);
      formik.setFieldValue("revision", actions.id);
      formik.setFieldValue("item_name", actions.item_name);
    }
  }, [actions]);



  const formik = useFormik({
    initialValues: {
      project_code: "",
      country: "",
      city: "",
      revision_type: "",
      revised_on: today,
      source: false,
      revision: "",
      item_name: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      //   setIsloading(true);
      let obj = {};
      if (values.revision === "true") {
        obj = {
          project_code: values.project_code,
          country: values.country,
          city: values.city,
          revision_type: values.revision_type,
          revised_on: values.revised_on,
          source: true,
          revision: "",
          item_name: values.item_name,
          database: true,
        };
      } else if (values.revision === "") {
        obj = {
          project_code: values.project_code,
          country: values.country,
          city: values.city,
          revision_type: values.revision_type,
          revised_on: values.revised_on,
          source: true,
          revision: "",
          item_name: values.item_name,
          database: false,
        };
      } else {
        obj = {
          project_code: values.project_code,
          country: values.country,
          city: values.city,
          revision_type: values.revision_type,
          revised_on: values.revised_on,
          source: false,
          revision: values.revision,
          item_name: values.item_name,
          database: false,
        };
      }
      if (actions === "add") {
        setIsLoadingSave(true);
        API.post("/api/db/location_revision/", obj, {}, 0)
          .then((res: any) => {
            setIsLoadingSave(false);
            formik.resetForm();
            setCounter((prev:any)=>prev+1)
            getRevisionData();
            onCloseModal();
            history.push(`/data-base/revision-database/${tab}/${res?.data?.year}/${res?.data?.month}/${res?.data?.id}/${0}/${0}/${0}/false/0/0`)
            Swal.fire({
              html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />

      <br />

      <p style="color:#007fff;">Revision Created successfully!</p>   

       </div>`,
            });
          })
          .catch((err: any) => {
            setIsLoadingSave(false);
            Swal.fire({
              icon: "error",
              html: `<div>

      <br />

      <p style="color:#007fff;">Revision creation failed!</p>   

       </div>`,
            });
          });
      } else {
        setIsLoadingSave(true);
        API.put(`/api/db/location_revision/${actions.id}/`, values, {}, 0)
          .then((res: any) => {
            console.log(res,"datattattatattattatattatat")

            setIsLoadingSave(false);
            onCloseModal();
             history.push(`/data-base/revision-database/${tab}/${res?.data?.year}/${res?.data?.month}/${res?.data?.id}/${typeId}/${databaseName}/${categoryName}/false/0/0`)

            setCounter((prev:any)=>prev+1)
            getRevisionData();
            Swal.fire({
              html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />

      <br />

      <p style="color:#007fff;">Revision updated successfully!</p>   

       </div>`,
            });
          })
          .catch((err: any) => {
            setIsLoadingSave(false);
            Swal.fire({
              icon: "error",
              html: `<div>

      <br />

      <p style="color:#007fff;">Revision update failed!</p>   

       </div>`,
            });
          });
      }
    },
  });

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              {actions === "add" ? "Add" : "Edit"} Revision
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ padding: "0 2rem 1rem", textAlign: "center" }}>
            <form onSubmit={formik.handleSubmit}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                <FormControl fullWidth>
                  <TextField
                    variant="standard"
                    label={
                      <span>
                        Revision Name <sup style={{ color: "red" }}>*</sup>
                      </span>
                    }
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="item_name"
                    name="item_name"
                    onChange={formik.handleChange}
                    value={formik.values.item_name}
                    multiline
                    error={
                      formik.touched.item_name &&
                      Boolean(formik.errors.item_name)
                    }
                    helperText={
                      formik.touched.item_name && formik.errors.item_name
                    }
                  />
                </FormControl>
                <FormControl fullWidth>
                  <TextField
                    variant="standard"
                    // autoFocus={autoFocusStatus}
                    label={
                      <span>
                        City <sup style={{ color: "red" }}>*</sup>
                      </span>
                    }
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="city"
                    name="city"
                    error={formik.touched.city && Boolean(formik.errors.city)}
                    //@ts-ignore
                    helperText={formik.touched.city && formik.errors.city}
                    onChange={formik.handleChange}
                    value={formik.values.city}
                    multiline
                  />
                  {/* <div className={styles.errormsg}>{formik.touched.revision && Boolean(formik.errors.revision) && <p>{formik.errors.revision}</p>}</div> */}
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                <FormControl fullWidth variant="standard">
                  <InputLabel
                    sx={{ color: "primary.main" }}
                    shrink
                    id="demo-simple-select-label"
                  >
                    Select project code <sup style={{ color: "red" }}>*</sup>
                  </InputLabel>

                  <Select
                    id="project_code"
                    name="project_code"
                    className={classes.select}
                    labelId="demo-select-small"
                    // id="demo-select-small"
                    sx={{
                      "&:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      ".MuiSelect-select.MuiInputBase-input.MuiInput-input": {
                        textAlign: "left",
                        fontSize: "1rem",
                      },
                      ".MuiSvgIcon-root ": {
                        color: "primary.main",
                      },
                    }}
                    error={
                      formik.touched.project_code &&
                      Boolean(formik.errors.project_code)
                    }
                    //@ts-ignore
                    helperText={
                      formik.touched.project_code && formik.errors.project_code
                    }
                    MenuProps={{
                      style: {
                        maxHeight: 400,
                        color: "007fff",
                      },
                    }}
                    onChange={formik.handleChange}
                    disabled={actions.mapped_status}
                    value={
                      formik.values.project_code !== null
                        ? formik.values.project_code
                        : ""
                    }
                  >
                    {/* <option value={""} style={{ display: "none" }}></option> */}
                    {projectCodeList &&
                      projectCodeList.map((item: any, index: any) => {
                        return (
                          <MenuItem
                            sx={{ color: "primary.main", fontSize: "1rem" }}
                            key={index}
                            value={item.id}
                          >
                            {item.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <div className={styles.errormsg}>
                    {formik.touched.project_code &&
                      Boolean(formik.errors.project_code) && (
                        <p>{formik.errors.project_code}</p>
                      )}
                  </div>
                </FormControl>

                {
                  actions === "add" ?
                  <FormControl fullWidth variant="standard">
                  <InputLabel
                    sx={{ color: "primary.main" }}
                    shrink
                    id="demo-simple-select-label"
                  >
                    Select revision type <sup style={{ color: "red" }}>*</sup>
                  </InputLabel>
                  <Select
                    id="revision_type"
                    name="revision_type"
                    className={classes.select}
                    labelId="demo-select-small"
                    // id="demo-select-small"
                    sx={{
                      "&:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiFormLabel-root": {
                        color: "#007fff",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      color: "#007fff",
                      "& .MuiSelect-select-MuiInputBase-input-MuiInput-input.MuiSelect-select:before":
                        { borderBottom: "1px solid blue !important" },
                      "& .MuiInputBase-root-MuiInput-root-MuiSelect-root.Mui-disabled:before":
                        { borderBottom: "1px solid blue !important" },
                      "& .MuiInput-root-MuiSelect-root:after": {
                        borderBottomColor: "primary.light",
                      },
                      ".MuiSelect-select.MuiInputBase-input.MuiInput-input": {
                        textAlign: "left",
                        fontSize: "1rem",
                      },
                      ".MuiSvgIcon-root ": {
                        fill: "#007fff !important",
                      },
                    }}
                    error={
                      formik.touched.revision_type &&
                      Boolean(formik.errors.revision_type)
                    }
                    //@ts-ignore
                    helperText={
                      formik.touched.revision_type &&
                      formik.errors.revision_type
                    }
                    MenuProps={{
                      style: {
                        maxHeight: 400,
                        color: "007fff",
                      },
                    }}
                    onChange={formik.handleChange}
                    disabled={actions.mapped_status}
                    value={
                      formik.values.revision_type !== null
                        ? formik.values.revision_type
                        : ""
                    }
                  >
                    {/* <option value={""} style={{ display: "none" }}></option> */}
                    {RevisionTypeList &&
                      RevisionTypeList.map((item: any, index: any) => {
                        return (
                          <MenuItem
                            sx={{ color: "primary.main", fontSize: "1rem" }}
                            key={index}
                            value={item.value}
                          >
                            { tab==2 &&
                              (Curdaction && Curdaction?.scenario ) && (
                                item.text=="Scenario Costing" && (item.text)
                              )
                            }
                             { tab==1 &&
                              (Curdaction && Curdaction?.revision_main ) && (
                                item.text=="Main Costing" && (item.text)
                              )
                            }
                          
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <div className={styles.errormsg}>
                    {formik.touched.revision_type &&
                      Boolean(formik.errors.revision_type) && (
                        <p>{formik.errors.revision_type}</p>
                      )}
                  </div>
                </FormControl>
                  
                  :
                  <FormControl fullWidth variant="standard">
                  <InputLabel
                    sx={{ color: "primary.main" }}
                    shrink
                    id="demo-simple-select-label"
                  >
                    Select revision type <sup style={{ color: "red" }}>*</sup>
                  </InputLabel>
                  <Select
                    id="revision_type"
                    name="revision_type"
                    className={classes.select}
                    labelId="demo-select-small"
                    // id="demo-select-small"
                    sx={{
                      "&:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiFormLabel-root": {
                        color: "#007fff",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      color: "#007fff",
                      "& .MuiSelect-select-MuiInputBase-input-MuiInput-input.MuiSelect-select:before":
                        { borderBottom: "1px solid blue !important" },
                      "& .MuiInputBase-root-MuiInput-root-MuiSelect-root.Mui-disabled:before":
                        { borderBottom: "1px solid blue !important" },
                      "& .MuiInput-root-MuiSelect-root:after": {
                        borderBottomColor: "primary.light",
                      },
                      ".MuiSelect-select.MuiInputBase-input.MuiInput-input": {
                        textAlign: "left",
                        fontSize: "1rem",
                      },
                      ".MuiSvgIcon-root ": {
                        fill: "#007fff !important",
                      },
                    }}
                    error={
                      formik.touched.revision_type &&
                      Boolean(formik.errors.revision_type)
                    }
                    //@ts-ignore
                    helperText={
                      formik.touched.revision_type &&
                      formik.errors.revision_type
                    }
                    MenuProps={{
                      style: {
                        maxHeight: 400,
                        color: "007fff",
                      },
                    }}
                    onChange={formik.handleChange}
                    disabled={actions.mapped_status}
                    value={
                      formik.values.revision_type !== null
                        ? formik.values.revision_type
                        : ""
                    }
                  >
                    {/* <option value={""} style={{ display: "none" }}></option> */}
                    {RevisionTypeList &&
                      RevisionTypeList.map((item: any, index: any) => {
                        return (
                          <MenuItem
                            sx={{ color: "primary.main", fontSize: "1rem" }}
                            key={index}
                            value={item.value}
                          >
                            {}
                            {item.text}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  <div className={styles.errormsg}>
                    {formik.touched.revision_type &&
                      Boolean(formik.errors.revision_type) && (
                        <p>{formik.errors.revision_type}</p>
                      )}
                  </div>
                </FormControl>


                }
             
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                <FormControl fullWidth>
                  <TextField
                    variant="standard"
                    label={
                      <span>
                        Country <sup style={{ color: "red" }}>*</sup>
                      </span>
                    }
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="country"
                    name="country"
                    onChange={formik.handleChange}
                    value={formik.values.country}
                    multiline
                    error={
                      formik.touched.country && Boolean(formik.errors.country)
                    }
                    helperText={formik.touched.country && formik.errors.country}
                  />
                </FormControl>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "1rem",
                  marginBottom: "0.5rem",
                }}
              >
                <FormControl fullWidth variant="standard">
                  <LocalizationProvider
                    variant="standard"
                    disabled={actions.mapped_status}
                    dateAdapter={AdapterMoment}
                    sx={{
                      ".MuiSvgIcon-root ": {
                        fill: "primary.main !important",
                      },
                    }}
                  >
                    <DatePicker
                      label="Revised on"
                      // id="revised_on"
                      // @ts-ignore
                      value={formik.values.revised_on}
                      // minDate={today}

                      onChange={(newValue) => {
                        //   setDate(moment(newValue).format("YYYY-MM-DD HH:mm:ss"));
                        formik.setFieldValue(
                          "revised_on",
                          moment(newValue).format("YYYY-MM-DD")
                        );
                      }}
                      renderInput={(params) => (
                        <TextField
                          name="revised_on"
                          {...params}
                          //@ts-ignore
                          onError={
                            formik.errors.revised_on &&
                            formik.touched.revised_on
                          }
                          sx={{
                            marginBottom: "0.5rem",
                            width: "100%",
                            "& .MuiSvgIcon-root": {
                              color: "primary.main",
                            },
                            "& .MuiFormLabel-root": {
                              color: "primary.main",
                            },
                            "& .MuiFormLabel-root.Mui-focused": {
                              color: "primary.main",
                            },
                            "& .Mui-error": {
                              color: "#d32f2f",
                              borderBottomColor: "#d32f2f",
                            },
                            ".MuiInputBase-root.MuiInput-root": {
                              fontSize: "1rem !important",
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "primary.light",
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "primary.main",
                            },
                          }}
                          variant="standard"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
                {actions === "add" && (
                  <FormControl fullWidth variant="standard">
                    <InputLabel
                      sx={{ color: "primary.main" }}
                      shrink
                      id="demo-simple-select-label"
                    >
                      Select revision
                    </InputLabel>
                    <Select
                      id="revision"
                      name="revision"
                      //   className={classes.select}
                      labelId="demo-select-small"
                      // id="demo-select-small"
                      sx={{
                        "&:before": {
                          borderBottomColor: "primary.light",
                        },
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "primary.main",
                        },
                        ".MuiSelect-select.MuiInputBase-input.MuiInput-input": {
                          fontSize: "1rem",
                          textAlign: "left",
                        },
                        ".MuiSvgIcon-root ": {
                          color: "primary.main",
                        },
                      }}
                      error={
                        formik.touched.revision &&
                        Boolean(formik.errors.revision)
                      }
                      //@ts-ignore
                      helperText={
                        formik.touched.revision && formik.errors.revision
                      }
                      MenuProps={{
                        style: {
                          maxHeight: 400,
                          color: "007fff",
                        },
                      }}
                      onChange={formik.handleChange}
                      value={formik.values.revision || ""}
                    >
                      {/* <option value={""} style={{ display: "none" }}></option> */}
                      {revisionList &&
                        revisionList.map((item: any, index: any) => {
                          return (
                            <MenuItem
                              sx={{ color: "primary.main", fontSize: "1rem" }}
                              key={index}
                              value={item.id}
                            >
                              {item?.item_name
                                ? `${item?.item_name}${
                                    item?.item_name === "Fetch from Database"
                                      ? ""
                                      : ","
                                  }`
                                : ""}{" "}
                              {item.city}
                            </MenuItem>
                          );
                        })}
                    </Select>
                    <div className={styles.errormsg}>
                      {formik.touched.revision &&
                        Boolean(formik.errors.revision) && (
                          <p>{formik.errors.revision}</p>
                        )}
                    </div>
                  </FormControl>
                )}
              </Box>
              <Box sx={{ width: "100%", textAlign: "right" }}>
                <LoadingButton
                  size="small"
                  loading={isLoadingsave}
                  variant="contained"
                  type="submit"
                  sx={{
                    marginTop: "1rem",
                    "&:hover": {
                      transform: "Scale(1.05)",
                      transition: "transform 0.5s ease",
                    },
                    ".MuiCircularProgress-root": {
                      color: "primary.main",
                    },
                  }}
                >
                  {actions === "add" ? "Add" : "Save"}
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
export default AddRevisionModal;
