import { useState } from "react";

// ----------------------------------------------------------------------------------

const useShareCustomValidationModal = () => {
    const [isOpen, setIsOpen] = useState<boolean>( false );
    const [id, setId] = useState<number | null>();
    const [type, setType] = useState<any>();
    const [nameHeader, setNameHeader] = useState<string>();

    const handleOpen = ( id?: number, typeIncoming?:any, nameIncoming?:any ) => {
        setId( id );
        setType(typeIncoming);
        setNameHeader(nameIncoming)
        setIsOpen( true );
    };

    const handleClose = () => {
        setIsOpen( false );
        setType(undefined);
        setNameHeader(undefined)
        setId( null );
    };

    return Object.freeze( {
        isOpen,
        open: handleOpen,
        close: handleClose,
        id,
        type,
        nameHeader
    } );
};

// ----------------------------------------------------------------------------------

export { useShareCustomValidationModal };
