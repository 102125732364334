import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  Drawer,
  IconButton,
  List,
  ListItem,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import styles from "./IdeaDashboard.module.scss";
import { API } from "../../../api-services";
import LeftGraphComponent from "./LeftGraphComponent";
import { useHistory } from "react-router-dom";
import MiddleIdeaComponent from "./MiddleIdeaComponent/MiddleIdeaComponent";
import { useDocumentTitle } from "../../../ui-reusable-component/useDocumentTitle";

type Anchor = "top" | "left" | "bottom" | "right";

interface IdeaDashboardI {}

interface Params {
  projectId: number;
  topVaultId: number;
}

const IdeaDashboard: React.FC<IdeaDashboardI> = (props) => {
  const { projectId, topVaultId } = useRouteParams<Params>();
  useDocumentTitle("Idea Dashboard");
  const [selectedProducts, setSelectedProducts] = useState<number[]>([]);
  const [selectedProject, setSelectedProject] = useState<number[]>([]);
  const [productAvailable, setProductAvailable] = useState<Array<{topvault_id: number, topvault_name: string}>|[]>([]);
  const [selectedDummyProducts, setSelectedDummyProducts] = useState<number[]|[]>([]);
  const [projectList, setProjectList] = useState<Array<{proj_description: string,proj_id:number,proj_name:string}> | []>([]);

  const [productApiLoader, setProductApiLoader] = useState<boolean>(false);
  const [dashboardListAPI, setDashboardListAPI] = useState<boolean>(false);
  const [minMaxRange, setMinMaxRange] = useState<{max_range:number,min_range:number} | {}>({});
  const history = useHistory();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  useEffect(() => {
    sessionStorage.setItem("historyProject", String(projectId));
  }, [projectId]);


  useEffect(() => {
    API.get(
      `/customer/dashboard/`,
      {
        get_module_project: "Ideas",
      },
      0
    ).then((res: any) => {
      setProjectList(res?.data as {proj_description: string,proj_id:number,proj_name:string}[]);
      setDashboardListAPI(true);
    });
  }, []);

  useEffect(() => {
    API.get(
      `/customer/dashboard/`,
      {
        get_idea_topvault: true,
        project: projectId,
      },
      0
    ).then((res: any) => {
      setProductAvailable(res?.data as {topvault_id: number, topvault_name: string}[]);
      setProductApiLoader(true);
    });
  }, [projectId]);

  useEffect(() => {
    setSelectedProject([projectId]);
  }, []);

  useEffect(() => {
    API.get(
      `/customer/idea_data/`,
      {
        top_vault_id: topVaultId,
        project: projectId,
        idea_dashboard: "saving_ideas_summary_range",
      },
      0
    ).then((res: any) => {
      setMinMaxRange(res?.data as {max_range:number,min_range:number});
    });
  }, [projectId, topVaultId]);

  const selectAllProduct = (productIds: number) => {
    setSelectedDummyProducts([productIds])
    setSelectedProducts([productIds]);
  };

  const selectAllProject = (projectIds: number) => {
    if(projectIds==projectId){
      setSelectedDummyProducts([topVaultId])
    }
    else{
      setSelectedDummyProducts([])
    }
    setSelectedProject([projectIds]);
    API.get(
      `/customer/dashboard/`,
      {
        get_idea_topvault: true,
        project: projectIds,
      },
      0
    ).then((res: any) => {
      setProductAvailable(res?.data as {topvault_id: number, topvault_name: string}[]);
    });
  };

  const getselectedProjectBackgroundColor = (idd: number) => {
    if (selectedProject?.includes(idd)) {
      return "primary.main";
    } else {
      return "primary.light";
    }
  };
  const getSelectedProjectTextColor = (idd: number) => {
    if (selectedProject?.includes(idd)) {
      return "primary.light";
    } else {
      return "primary.main";
    }
  };

  const getselectedBackgroundColor = (idd: number) => {
    if (selectedProducts?.includes(idd)) {
      return "primary.main";
    } else {
      return "primary.light";
    }
  };
  const getSelectedTextColor = (idd: number) => {
    if (selectedProducts?.includes(idd)) {
      return "primary.light";
    } else {
      return "primary.main";
    }
  };


  const handleChangeIdea = (anchor: any) => {    
    history.push(
      `/idea_dashboard/${selectedProject[0]}/${selectedProducts[0]}`
    );
  };

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setSelectedProducts([topVaultId]);
      API.get(
        `/customer/dashboard/`,
        {
          get_idea_topvault: true,
          project: projectId,
        },
        0
      ).then((res: any) => {
        setProductAvailable(res?.data as {topvault_id: number, topvault_name: string}[]);
      });
      setSelectedDummyProducts([topVaultId]);
      setSelectedProject([projectId]);
      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ minWidth:300,width: anchor === "top" || anchor === "bottom" ? "auto" : "auto" }}
      role="presentation"
    >
      <List>
        <ListItem>
          <Box sx={{ width: "100%" }}>
            <div>
              <Typography
                style={{
                  width: "100%",
                  fontSize: "1rem",
                }}
              >
                Project Name
              </Typography>
              {projectList &&
                projectList?.map((item: {proj_description: string,proj_id:number,proj_name:string}, index: number) => {
                  return (
                    <>
                      <Box sx={{ alignItems: "center", display: "flex" }}>
                        <Checkbox
                          checked={
                            selectedProject?.includes(item.proj_id)
                              ? true
                              : false
                          }
                          onClick={() => selectAllProject(item?.proj_id)}
                          inputProps={{ "aria-label": "controlled" }}
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                        />
                        <Box
                          key={index}
                          onClick={() => selectAllProject(item?.proj_id)}
                          className={styles.selectVehBtn}
                          sx={{
                            padding: "0.2rem 0.5rem",
                            backgroundColor: getselectedProjectBackgroundColor(
                              item.proj_id
                            ),
                            color: getSelectedProjectTextColor(item.proj_id),
                            textAlign: "left",
                            cursor: "pointer",
                            display: "inline-block",
                            width: "100%",
                          }}
                        >
                          <Typography>{item.proj_name}</Typography>
                        </Box>
                      </Box>
                    </>
                  );
                })}
            </div>
            <div>
              <Typography
                style={{
                  width: "100%",
                  fontSize: "1rem",
                }}
              >
                Product Name
              </Typography>
              {productAvailable &&
                productAvailable?.map((item: {topvault_id: number, topvault_name: string}, index: number) => {
                  return (
                    <>
                      <Box sx={{ alignItems: "center", display: "flex" }}>
                        <Checkbox
                          checked={
                            selectedProducts?.includes(item?.topvault_id)
                              ? true
                              : false
                          }
                          onClick={() => selectAllProduct(item?.topvault_id)}
                          inputProps={{ "aria-label": "controlled" }}
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                        />
                        <Box
                          key={index}
                          onClick={() => selectAllProduct(item?.topvault_id)}
                          className={styles.selectVehBtn}
                          sx={{
                            padding: "0.2rem 0.5rem",
                            backgroundColor: getselectedBackgroundColor(
                              item.topvault_id
                            ),
                            color: getSelectedTextColor(item.topvault_id),
                            textAlign: "left",
                            cursor: "pointer",
                            display: "inline-block",
                            width: "100%",
                            // marginBottom: '1rem'
                          }}
                        >
                          <Typography>{item?.topvault_name}</Typography>
                        </Box>
                      </Box>
                    </>
                  );
                })}
            </div>
            <div style={{ textAlign: "right", marginTop: "1rem" }}>
              <Button
                className={styles.drawBtn}
                sx={{ marginRight: "1rem" }}
                variant="contained"
                onClick={toggleDrawer(anchor, false)}     
                size="small"           
              >
                Close
              </Button>
              <Button               
                className={styles.drawBtn}
                size="small"
                onClick={() => handleChangeIdea(anchor)}
                disabled={
                  selectedProducts?.length < 1 || selectedProducts?.length > 1 || selectedDummyProducts?.length==0
                }
                >
                <Button
                className={styles.drawBtn}
                variant="contained"
                size="small"
                onClick={toggleDrawer(anchor, false)}     
                disabled={
                  selectedProducts?.length < 1 || selectedProducts?.length > 1 || selectedDummyProducts?.length==0
                }           
               > Ok
              </Button>
              </Button>
            </div>
          </Box>
        </ListItem>
      </List>
    </Box>
  );

  const backButtonHandler = () => {
    history.push("/landing");
  };

  const projectDetail: {proj_description: string,proj_id:number,proj_name:string}[] =
    projectList &&
    projectList?.filter((item: {proj_description: string,proj_id:number,proj_name:string}) => {
      return item?.proj_id == projectId;
    });

  return (
    <>
      {productAvailable?.length > 0 &&
      projectList &&
      projectList?.filter((item: {proj_description: string,proj_id:number,proj_name:string}) => {
        return item?.proj_id == projectId;
      })?.length > 0 ? (
        <div
          className={styles.container}
        >
          <Box>
            <Box sx={{ width: "3rem", padding: "0 0.5rem" }}>
              <IconButton
                sx={{ cursor: "pointer" }}
                onClick={backButtonHandler}
              >
                <ArrowBackIcon color="primary" titleAccess="Go Back" />
              </IconButton>
            </Box>
            <Box className={styles.expandIconBox}>
              <div className={styles.iconSec}>
                {(["left"] as const).map((anchor) => (
                  <React.Fragment key={anchor}>
                    <IconButton
                      size="medium"
                      sx={{
                        backgroundColor: "primary.light",
                        color: "primary.main",
                      }}
                      onClick={toggleDrawer(anchor, true)}
                    >
                      <AddIcon sx={{ fontSize: "1.7rem" }} />
                    </IconButton>
                    <Drawer
                      anchor={anchor}
                      open={state[anchor]}
                      onClose={toggleDrawer(anchor, false)}
                      className={styles.drawerContainer}
                      PaperProps={{
                        sx: {
                          backgroundColor: "aliceblue",
                        },
                      }}
                    >
                      <Box
                        sx={{ padding: "1rem" }}
                        adl-scrollbar="true"
                        adl-scrollbar-width="0.3"
                      >
                        <Typography
                          style={{ width: "100%", textAlign: "center" }}
                        >
                          Select Project & Product
                        </Typography>
                        <Divider sx={{ margin: "1rem" }} />
                        {list(anchor)}
                      </Box>
                    </Drawer>
                    <Typography
                      style={{ cursor: "pointer" }}
                      className={styles.sideTitle}
                      onClick={toggleDrawer(anchor, true)}
                    >
                      Select Project & Product
                    </Typography>
                  </React.Fragment>
                ))}
              </div>
            </Box>
          </Box>
          
            <div style={{ margin: "0 0.5rem" }}>
              <Typography
                style={{ marginTop: "0.5rem",fontWeight: "bold",fontSize:'1rem' }}
              >{`Idea Dashboard`}</Typography>
              <Typography
                style={{fontSize:'1rem', margin:"0.5rem 0" }}
              >{` ${projectDetail && projectDetail[0]?.proj_name} [ ${
                projectDetail && projectDetail[0]?.proj_description
              } ] `}</Typography>
              <LeftGraphComponent/>
            </div>
            <div
              style={{ padding: "0 1rem", borderLeft: "1px solid #007FFF14" }}
            >
              <MiddleIdeaComponent minMaxRange={minMaxRange} />
            </div>
        </div>
      ) : (
        <div className={styles.containerNoIdea}>
          {productApiLoader && dashboardListAPI && <h1>No Ideas</h1>}
        </div>
      )}
    </>
  );
};

export default IdeaDashboard;
