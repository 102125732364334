// react
import React, { useEffect, useState } from "react";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid";
import Modal from "@mui/material/Modal";
import styles from "./AnalyseModal.module.scss";
import { ButtonProps } from "@mui/material/Button";
import { Box, Divider, Typography } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import { useRouteMatch } from "react-router-dom";

// Below code is used for the toolbar in DataGrid Table

let materialVal: any;
let comparisonGet:any;

const csvOptions: GridCsvExportOptions = {
  fileName: `${materialVal} - ${comparisonGet && comparisonGet} Table In CSV`,
  delimiter: ",",
  utf8WithBom: true
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = (props: any) => (
  <GridToolbarContainer style={{ height: "2.5rem" }}>
    {/* <h4 style={{ position: "absolute", left: "40%" }}>{props}</h4> */}
    <span style={{ position: "absolute", right: "0rem" }}>
      <GridToolbarColumnsButton />
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);

interface AnalyseMaterialModalProps {
  isOpen: boolean;
  onClose: () => void;
  analyseMaterial: any;
  materialName: any;
}

const AnalyseMaterialModal: React.FC<AnalyseMaterialModalProps> = (props) => {
  const { isOpen, onClose, analyseMaterial, materialName } = props;
  let [analyseMaterialtModalData, setAnalyseMaterialModalData] = useState<any>();
  let {url} = useRouteMatch();
  let handleOnClose = () => {
    onClose();
  }
  materialVal = materialName;
  comparisonGet= url?.includes('material') ? 'Material Percentage Comparison' : url?.includes('category') ? 'Category Wise Part Count Distribution Comparison' :'Category Wise Part Cost Distribution Comparison';

  useEffect(() => { if (analyseMaterial != undefined) { setAnalyseMaterialModalData(analyseMaterial) } }, [analyseMaterial])

  // columns is a variable where column data is stored. This columns is then used as a prop in dataGrid.
  // If changeEvent is false then it for My files and true is for shared with me.

  let columns: any = url?.includes('material') ? [{
    field: "name",
    headerName: "Product Name",
    minWidth: 250,
    flex: 1,
  },
  {
    field: "material_percent",
    headerName: "Material Percentage (%)",
    minWidth: 250,
    flex: 1,
    type: "number"
  },
  {
    field: "value",
    headerName: "Material (Kg)",
    minWidth: 250,
    flex: 1,
    type: "number",
    valueGetter: (params: any) => (params.row.value / 1000).toFixed(2)
  },
  ]:url?.includes('category') ? [{
    field: "name",
    headerName: "Product Name",
    minWidth: 250,
    flex: 1,
  },
  {
    field: "value",
    headerName: "Parts (Qty)",
    minWidth: 250,
    flex: 1,
    type: "number",
    valueGetter: (params: any) => (params.row.value)
  },
  ]:
  [{
    field: "name",
    headerName: "Product Name",
    minWidth: 250,
    flex: 1,
  },
  {
    field: "value",
    headerName: `Cost (${sessionStorage.getItem('currency_analysis')})`,
    minWidth: 250,
    flex: 1,
    type: "number",
    valueGetter: (params: any) => (params.row.value).toFixed(1)
  },
  ]

  let rows: any = [];

  const dataGridDataGenerator: any = analyseMaterialtModalData && analyseMaterialtModalData?.map(({ color, ...rest }: any) => {
    return rest;
  })?.map((ideaItem: any, index: any) => {
    let objShared: any = {
      id: index + 1,
      ...ideaItem
    };
    rows.push(objShared);
  });

  let dataStructureDataGrid: any = {
    columns: columns,
    rows: rows,
  };

  // all state are declared here
  return (<Modal
    open={isOpen}
    onClose={handleOnClose}
    className={styles.container}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description">
    <div className={styles.materalcontainerInside}>
      <Box
        sx={{
          height: '94vh',
          width: "100%",
        }} >
        <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
          <Typography
            style={{
              fontWeight: "600",
              width: "100%",
              textAlign: "center",
              marginRight: '-2rem',
            }}>
            {`${materialName} - ${url?.includes('material') ? 'Material Percentage Comparison' : url?.includes('category') ? 'Category Wise Part Count Distribution Comparison' :'Category Wise Part Cost Distribution Comparison' }`}
          </Typography>
          <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={handleOnClose} />
        </Box>
        <Divider
          sx={{
            borderColor: "primary.light",
            // margin: "0 1rem",
            // marginBottom: "1rem",
          }}
        />
        < DataGrid
          {...dataStructureDataGrid}
          // loading={apiIdeaDataFetch}
          disableSelectionOnClick={true}
          components={{
            Toolbar: () => {
              return CustomToolbar(`${materialName} - ${url?.includes('material') ? 'Material Percentage Comparison' : url?.includes('category') ? 'Category Wise Part Count Distribution Comparison' :'Category Wise Part Cost Distribution Comparison' }`);
            },
          }}
          getRowClassName={(params) => `super-app-theme--${params.row.stage}`}
          sx={{
            height: '95%',
            fontSize: "1rem",
            "&.MuiDataGrid-root.MuiDataGrid-autoHeight .MuiDataGrid-row--lastVisible .MuiDataGrid-cell":
            {
              borderBottomColor: "primary.light",
            },
            "&.MuiDataGrid-root .MuiDataGrid-cell": {
              borderBottomColor: "primary.light",
            },
          }}
          density={"compact"}
          pageSize={10}
          rowsPerPageOptions={[10]}
        />
      </Box>
    </div>
  </Modal>);
};

export default AnalyseMaterialModal;
