import React, { useEffect, useRef, useState } from "react";
import styles from "./ProjectDirectoryLandingPage.module.scss";
import { useHistory } from "react-router-dom";
import TextField from "@mui/material/TextField";
import { Avatar, Box, Chip, IconButton, Skeleton, Typography } from "@mui/material";
import { AxiosResponse } from "axios";
import { API } from "../../api-services";
import Image from "../../ViewModule/FeatureLandingPage/ArchitectureImageCard";
import loaderImage from "../../Assets/images/loading-screen.gif";
import ProjectDirectoryVehiclePage from "./ProjectDirectoryVehiclePage";
import SaveIcon from "@mui/icons-material/Save";
import moment from "moment";
import useWindowDimensions from "../../ui-reusable-component/useWindowDimensions";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDocumentTitle } from "../../ui-reusable-component/useDocumentTitle";


interface ProjectDirectoryLandingPageI { }

const ProjectDirectoryLandingPage: React.FC<ProjectDirectoryLandingPageI> = (
  props
) => {
  const [
    projectDirectoryOrganisationDetails,
    setProjectDirectoryOrganisationDetails,
  ] = useState<any>();
  useDocumentTitle( "Project Directory" );
  const inputElRef = useRef<any>(null);
  const [UserList, setUserList] = useState<any>([]);
  const history = useHistory();
  const [Counter, setCounter] = useState<any>(0);

  const [Name, setName] = useState<any>();
  const [Datedata, setDatedata] = useState<any>();
  const [description, setDescription] = useState<any>();

  const [editSatatusName, setEditSatatusName] = useState<any>(false);
  const [editSatatusdate, setEditSatatusdate] = useState<any>(false);
  const [editSatatusdes, setEditSatatusdes] = useState<any>(false);
  const [loader, setLoader] = useState<any>(true)
  const { height, width } = useWindowDimensions();

  const browseFiles = () => {


    return inputElRef.current?.click?.();
  };
  useEffect(() => {
    setLoader(false)
    API.get("auth/organisation/").then((res: AxiosResponse) => {
      setProjectDirectoryOrganisationDetails(res?.data);
      setName(res?.data[0]?.name);
      setLoader(true)
      var datetype = moment(res?.data[0]?.created_at).format("YYYY-MM-DD");
      setDatedata(datetype);


      setDescription(res?.data[0]?.description);
    });
  }, [Counter]);

  useEffect(() => {
    API.get("auth/super_user_list/").then((res: AxiosResponse) => {
      // setProjectDirectoryOrganisationDetails(res?.data);
      // console.log(res?.data, "res?.data");
      setUserList(res?.data);
    });
  }, []);

  const EditHandler = (status: any, type: any) => {
    if (type == "name") {
      setEditSatatusName(status);
    }
    if (type == "Date") {
      setEditSatatusdate(status);
    }
    if (type == "description") {
      setEditSatatusdes(status);
    }
  };

  var AvatarName = (item: any) => {

    const [first, last] = item?.split(' ')

    const FirstLatter = first?.charAt(0);

    const lastWord = last?.charAt(0)

    return `${FirstLatter}${lastWord}`;

  }

  const SaveHandler = (status: any, type: any) => {
    if (type == "name") {
      API.patch(
        `auth/organisation/${projectDirectoryOrganisationDetails[0]?.id}/`,
        { name: Name }
      )
        .then((res: any) => {
          setName(res?.data?.name);
          setEditSatatusName(status);
        })
        .catch((err: any) => {
          setEditSatatusName(status);
        });
    }
    if (type == "Date") {
      API.patch(
        `auth/organisation/${projectDirectoryOrganisationDetails[0]?.id}/`,
        { created_at: Datedata }
      )
        .then((res: any) => {
          var datetype = moment(res?.data?.created_at).format("YYYY-MM-DD");
          setDatedata(datetype);
          setEditSatatusdate(status);
        })
        .catch((err: any) => {
          setEditSatatusdate(status);
        });
    }
    if (type == "description") {
      API.patch(
        `auth/organisation/${projectDirectoryOrganisationDetails[0]?.id}/`,
        { description: description }
      )
        .then((res: any) => {
          setDescription(res?.data?.description);
          setEditSatatusdes(status);
        })
        .catch((err: any) => {
          setEditSatatusdes(status);
        });
    }
  };
  const handelchnage = (e: any, type: any) => {
    if (type == "name") {
      setName(e.target.value);
    }
    if (type == "Date") {
      var date = new Date(e.target?.value);
      var datedata = moment(date).format("YYYY-MM-DD");
      setDatedata(datedata);
    }
    if (type == "description") {
      setDescription(e.target.value);
    }
  };
  const handleImg = (e: any) => {
    const data = new FormData();

    data.append(
      "organization_id",
      projectDirectoryOrganisationDetails &&
      projectDirectoryOrganisationDetails[0]?.id
    );

    data.append("logo", e.target.files[0]);

    API.post("auth/organisation/", data, {}, 0).then(
      (res: AxiosResponse) => {
        setCounter((prev: any) => prev + 1);
        //   setApiCall(!apiCall);

        // console.log("Changed Organisation Logo");
      },
      (err: any) => {
        console.log(`Could not change Organisation Logo`);
      }
    );
  };

  const handleClick = () => {
    history.push("/internallanding");
  };


  const ProjectListredirection = (type: any) => {
    history.push(`/Project-Directory-list/${type}/0`)


  }
  return (
    <>
      <Box sx={{ margin: '0 1rem' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <IconButton sx={{ cursor: "pointer", }} title='Go Back' onClick={handleClick}>
              <ArrowBackIcon color="primary" titleAccess="Go Back" />
            </IconButton>
            <Typography sx={{ fontSize: '1rem', whiteSpace: 'nowrap', fontWeight: '500' }}>Project Directory</Typography>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '10px', marginRight: '1rem' }}>
            <Chip size="small" label="Product Categories" sx={{
              backgroundColor: 'primary.main', color: 'white', cursor: "pointer",
              "&:hover": {
                backgroundColor: 'primary.main',
              },
            }}

              onClick={() => ProjectListredirection("Categories")} />
            <Chip size="small" label="OEM's List" sx={{
              backgroundColor: 'primary.main', color: 'white', cursor: "pointer",
              "&:hover": {
                backgroundColor: 'primary.main',
              },
            }}
              onClick={() => ProjectListredirection("OEM")} />
          </Box>
        </Box>
      </Box>
      <Box className={styles.container}>
        <Box className={styles.topContainer} sx={{ height: { xl: '88vh' }, boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px', borderRadius: '10px' }}>
          <input
            accept="image/*"
            id="profilePhoto"
            type="file"
            style={{ display: "none" }}
            onChange={handleImg}
            ref={inputElRef}
          />
          <Box>
            <Box sx={{ display: "flex", alignItems: "center", padding: "1rem", justifyContent: 'center', }}>
              {loader ?
                (projectDirectoryOrganisationDetails &&

                  <Image
                    placeholderImg={loaderImage}
                    src={
                      projectDirectoryOrganisationDetails &&
                      projectDirectoryOrganisationDetails[0]?.logo
                    }
                    style={{ cursor: "pointer", }}

                    alt={"Logo Organization"}
                    width="auto"
                    height={width > 1400 ? "150px" : "120px"}
                    onClick={browseFiles}
                  />


                )


                : <Skeleton animation="wave" sx={{ width: width > 1400 ? "100px" : "80px", height: "100px" }} />}
            </Box>
            <Box style={{ width: "100%", padding: "1rem", }}>
              <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr', alignItems: 'center', columnGap: '1rem' }}>
                <Box style={{ display: "flex", columnGap: "1rem" }}>
                  <TextField
                    placeholder="Organisation Name"
                    InputProps={{
                      sx: {
                        color: "primary.main",
                        fontSize: "1rem",
                        // marginTop: "1rem",
                        paddingLeft: "0.5rem",
                      },
                      // readOnly: editSatatusName==false?true:false,
                    }}
                    InputLabelProps={{ style: { fontSize: "1rem" } }}
                    sx={{
                      width: "100%",
                      "& .MuiFormLabel-root": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .Mui-focused": {
                        color: "primary.main",
                      },
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.light",
                      },
                    }}
                    focused={editSatatusName}
                    variant="standard"
                    value={Name}
                    onFocus={() => EditHandler(true, "name")}
                    // onBlur={ () => EditHandler(false, "name")}
                    onChange={(e: any) => handelchnage(e, "name")}
                  // onChange={formik.handleChange}
                  />

                  {editSatatusName ? (
                    <>
                      <SaveIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => SaveHandler(false, "name")}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </Box>

                <Box style={{ display: "flex", columnGap: "1rem" }}>
                  <TextField
                    focused={editSatatusdate}
                    placeholder="Date of Creation"
                    InputProps={{
                      sx: {
                        color: "primary.main",
                        fontSize: "1rem",
                        // marginTop: "1rem",
                        paddingLeft: "0.5rem",
                      },
                      // readOnly: editSatatusdate==false?true:false,
                    }}
                    onChange={(e: any) => handelchnage(e, "Date")}
                    onFocus={() => EditHandler(true, "Date")}
                    // onBlur={ () => EditHandler(false, "Date")}
                    InputLabelProps={{ style: { fontSize: "1rem" } }}
                    sx={{
                      width: "100%",
                      "& .MuiFormLabel-root": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .Mui-focused": {
                        color: "primary.main",
                      },
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.light",
                      },
                    }}
                    variant="standard"
                    type="date"
                    inputProps={{
                      min: moment(new Date()).format("YYYY-MM-DD"),
                    }}
                    value={Datedata}
                  // onChange={formik.handleChange}
                  />

                  {editSatatusdate ? (
                    <>
                      <SaveIcon
                        style={{ cursor: "pointer", }}
                        onClick={() => SaveHandler(false, "Date")}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </Box>
              </Box>
              <Box style={{ display: "flex", columnGap: "1rem" }}>
                <textarea
                  //  autoFocus={editSatatusdes}

                  // disabled={editSatatusdes==false?true:false}

                  aria-label="empty textarea"
                  onFocus={() => EditHandler(true, "description")}
                  // onBlur={ () => EditHandler(false, "description")}
                  onChange={(e: any) => handelchnage(e, "description")}
                  placeholder="Description"
                  style={{
                    height: "8rem",
                    width: "100%",
                    maxWidth: '41vw',
                    maxHeight: '30vh',
                    marginTop: "1rem",
                    borderRadius: "5px",
                    border: "2px solid aliceblue",
                    fontFamily: "roboto",
                    fontSize: "1rem",
                    padding: "0.5rem",
                    color: '#007fff',
                  }}
                  //@ts-ignore

                  value={description}
                />

                {editSatatusdes ? (
                  <>
                    <SaveIcon
                      style={{ cursor: "pointer", marginTop: "1.5rem", marginLeft: '-3rem' }}
                      onClick={() => SaveHandler(false, "description")}
                    />
                  </>
                ) : (
                  <></>
                )}
              </Box>
            </Box>
          </Box>
          <Typography sx={{ fontSize: '1rem', marginLeft: '1rem', fontWeight: '500' }}>Administrators : </Typography>
          <Box
            className={styles.userconatiner}
            sx={{ height: { lg: "34vh", xl: "47vh" } }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3"
          >
            <Box>
              {UserList &&
                UserList?.map((Item: any, index: any) => {
                  return (
                    <>
                      <Box className={styles.userlists}>
                        <Box
                          sx={{
                            display: "flex",
                            // width: "20rem",
                            alignItems: "center",
                          }}
                        >
                          {Item?.profile_url !== null ? (
                            <Typography
                              sx={{ marginRight: "1rem", lineHeight: "1" }}
                            >
                              <img
                                style={{
                                  width: "3rem",
                                  borderRadius: "50%",
                                  height: "3rem",
                                  objectFit: "cover",
                                }}
                                src={Item?.profile_url}
                              />
                            </Typography>
                          ) : (
                            // text.charAt(0)
                            <Typography
                              sx={{ marginRight: "1rem", lineHeight: "1" }}
                            >
                              <Avatar
                                src="/broken-image.jpg"
                                sx={{
                                  width: "3rem",
                                  height: "3rem",
                                  objectFit: "cover",
                                  backgroundColor: "#007fff !important",
                                }}
                              >
                                {AvatarName(Item?.name)}
                              </Avatar>
                            </Typography>
                          )}

                          <Typography sx={{ fontSize: "1rem" }}>
                            {Item?.name}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            width: "100%",
                          }}
                        >
                          <Typography
                            sx={{ fontSize: "1rem", whiteSpace: "nowrap" }}
                          >
                            {Item?.email}
                          </Typography>
                          <Typography
                            sx={{ fontSize: "1rem", whiteSpace: "nowrap" }}
                          >
                            {Item?.phone_no}
                          </Typography>
                        </Box>
                      </Box>
                    </>
                  );
                })}
            </Box>
          </Box>
        </Box>
        {/* <Divider orientation="vertical" variant="middle" sx={{ marginRight: '1rem', borderColor: 'primary.light' }} flexItem /> */}
        <Box className={styles.bottomContainer}>
          <ProjectDirectoryVehiclePage />
        </Box>
      </Box>
    </>
  );
};

export default ProjectDirectoryLandingPage;
