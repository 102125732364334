import {
    createEntityAdapter,
    createReducer,
    createSelector,
    EntityState,

} from "@reduxjs/toolkit";
import { ID } from "../../utlis/commonInterfaces";



import {
    createProject,
    deleteProject,
    getProjectById,
    getProjects,
    resetProjects,
    updateProject,
} from "../Actions/project.actions";
import { ADMIN } from "../Services/admin.service";
import { RootState } from "../Store/storeConfigurations";

// import { RootState } from "../storeConfigurations";


// -----------------------------------------------------------------------------------
// entity adaptor

export interface Project {
    id: ID;
    name: string;
    description: string;
    box_dimension: number;
    alias_view: boolean;
    created_by: string;
    created_at: string;
    type: any;
    module?: any;
    allowed_modules: any;
    is_project_admin?: any;
    can_configure_report?: boolean | null;
    report_permission?: any ;
    proposal_document: any;
    proposal_time_plan: any;
    is_qc_alld?: any;
    is_triangulation_alld?: any;
    has_configured_categories?: any;
    outward_mail_permission?:boolean
}


const projectAdaptor = createEntityAdapter<Project>( {
    selectId: project => project.id,
    sortComparer: ( a, b ) => a.name.localeCompare( b.name ),
} );



// -----------------------------------------------------------------------------------
// interfaces & initialState

type AdditionalFields = {};

type IProjectReducer = EntityState<Project> & AdditionalFields;

const additionalFields: AdditionalFields = {};

const initialState: IProjectReducer = Object.assign(
    {},
    projectAdaptor.getInitialState(),
    additionalFields
);




// -----------------------------------------------------------------------------------
// reducer

const ProjectReducer = createReducer( initialState, builder => {
    // get
    builder
        .addCase( getProjects.fulfilled, ( state, action: any ) => {
            projectAdaptor.setAll( state, action.payload.projects );
        } )
        .addCase( getProjects.rejected, ( state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could not fetch projects" );
        } );

    // getById
    builder
        .addCase( getProjectById.fulfilled, ( state, action: any ) => {
            projectAdaptor.setAll( state, action.payload.project );
        } )
        .addCase( getProjectById.rejected, ( state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could not fetch project" );
        } );

    // create
    builder
        .addCase( createProject.fulfilled, ( state, action: any ) => {
            projectAdaptor.addOne( state, action.payload.project );
        } )
        .addCase( createProject.rejected, ( state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could not create project" );
        } );

    // update
    builder
        .addCase( updateProject.fulfilled, ( state, action ) => {
            const { id, updates: changes } = action.meta.arg;

            projectAdaptor.updateOne( state, { id, changes } );
        } )
        .addCase( updateProject.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could not update project" );
        } );


    // delete
    builder
        .addCase( deleteProject.fulfilled, ( state, action ) => {
            const { id } = action.meta.arg;

            projectAdaptor.removeOne( state, id );
        } )
        .addCase( deleteProject.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could not delete project" );
        } );

    builder.addCase( resetProjects, () => initialState );
} );





// -----------------------------------------------------------------------------------
// selectors

const selectors = projectAdaptor.getSelectors<RootState>(
    state => state.projects
);




const selectOptions = createSelector( selectors.selectAll, projects => {
    return projects.map( project => ( {
        key: project.id,
        value: project.id,
        text: project.name,
        type: "project"
    } ) );
} );

const selectcheck = createSelector( selectors.selectAll, projects => {
    return projects.map( project => ( {
        id: project.id,
        has_configured_categories: project.has_configured_categories,
    } ) );
} );

const crumbProjectSelect = createSelector( selectors.selectAll, projects => {
    return projects.map( project => ( {
        id: project.id,
        name: project.name,
    } ) );
} );






const projectSelectors = Object.assign( {}, {
     selectOptions,
     selectcheck,
     crumbProjectSelect
 }, selectors );

// -----------------------------------------------------------------------------------
// exports

export { ProjectReducer, initialState as ProjectReducerInit, projectSelectors };
