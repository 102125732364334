import * as React from 'react';
import Modal from '@mui/material/Modal';
import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Divider, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import VisibilityIcon from '@mui/icons-material/Visibility';
import moment from 'moment';
import swal from "sweetalert";
import { API } from '../../api-services';
import { useHistory } from 'react-router-dom';
import { useRouteParams } from '../../ui-reusable-component/useRouteParams';
import { boolean } from 'yup';

interface Params{
    projectId:any,
    projectname:any,
    abbreviation:any,
    fileIndex:any
}
export interface IWorkshopPresentationFileinfoModalProps {
    onCloseModal: any;
    isOpen: any;
    pdfFilePassToInfo:any;
    apiCallWorkstationData:any;
    setApiCallWorkstationData:any
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50vw',
    height: 'fit-content',
    bgcolor: 'background.paper',
    borderRadius: '10px',
    p: 1,
};

export function WorkshopPresentationFileinfoModal(props: IWorkshopPresentationFileinfoModalProps) {
    const { onCloseModal, isOpen,pdfFilePassToInfo,apiCallWorkstationData,setApiCallWorkstationData } = props;
    const [deleteHandlerCalled,setDeleteHandlerCalled] = React.useState<boolean>(false);
    const [pdfFileInfo,setPdfFileInfo] = React.useState<any>();
    const [pdfFileInfoLoader,setPdfFileInfoLoader] = React.useState<boolean>(false);
    const {projectId,projectname,abbreviation,fileIndex} = useRouteParams<Params>();
    const history = useHistory();

    React.useEffect(()=>{
        setPdfFileInfo(pdfFilePassToInfo);
    },[])

    const handleDelete = (idGet:any) => {
        swal({
          text: `Are you sure you want to delete Banner ?`,
          icon: "error",
          buttons: ["Cancel", "Yes, Delete"],
        }).then((confirm) => {
          if (confirm) {
            setPdfFileInfoLoader(true);
            API.post(
              `workshop/banner_fields/`,
              {delete_file:true,id:idGet},
              0
            )
              .then((res: any) => {
                setPdfFileInfo((prev:any)=>prev?.filter((item:any)=>item?.id != idGet));
                pdfFilePassToInfo?.length>1 ? Number(fileIndex)!=pdfFilePassToInfo?.length ? history.push(`/eirandworkshop/${projectId}/${projectname}/${abbreviation}/${fileIndex}/0`) : history.push(`/eirandworkshop/${projectId}/${projectname}/${abbreviation}/${Number(fileIndex)-1}/0`) : history.push(`/eirandworkshop/${projectId}/${projectname}/0/0/0`);
                setDeleteHandlerCalled(true);
                setPdfFileInfoLoader(false);
              })
              .catch((err: any) => {});
          }
        });
      };

    const onCloseHandler = () =>{
        if(deleteHandlerCalled){
            setApiCallWorkstationData(!apiCallWorkstationData);
            onCloseModal();
        }
        else{
            onCloseModal();
        }
    }

    return (
        <div>
            <Modal
                open={isOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
                        <Typography
                            style={{
                                fontSize: "1rem",
                                fontWeight: "600",
                                width: "100%",
                                textAlign: "center",
                                marginRight: '-2rem'
                            }}
                        >
                            EIR Information
                        </Typography>
                        <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseHandler} />
                    </Box>
                    <Divider
                        sx={{
                            borderColor: "primary.light",
                            margin: "0 1rem",
                            marginBottom: "1rem",
                        }}
                    />
                    <Box>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}>Sl.No</TableCell>
                                    <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}>PDF Name</TableCell>
                                    <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}>Upload Date</TableCell>
                                    <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}>Uploaded by</TableCell>
                                    <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}>Size</TableCell>
                                    <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}>View</TableCell>
                                    <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                            {!pdfFileInfoLoader ? 
                            pdfFileInfo && pdfFileInfo?.map((item:any,index:number)=>{
                                return  <TableRow>
                                <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}>{index+1}</TableCell>
                                <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}>{item?.file_name}</TableCell>
                                <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}>{moment(item?.created_at).format(' DD-MM-YYYY, h:mm:ss a')}</TableCell> 
                                <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}>{item?.createdby_name}</TableCell>
                                <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}>{item?.file_size} MB</TableCell>
                                <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><VisibilityIcon sx={{cursor:'pointer'}} onClick={()=> window.open(item?.file_link)}/></TableCell>
                                <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><DeleteIcon titleAccess="Delete" onClick={()=>handleDelete(item?.id)} sx={{cursor:'pointer', "&:hover": { transform: 'Scale(1.05)', transition: 'transform 0.5s ease',}}} /></TableCell>
                            </TableRow>
                            })
                            : <>
                            <TableRow>
                                <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton/></TableCell>
                                <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton/></TableCell>
                                <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton/></TableCell>
                                <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton/></TableCell>
                                <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton/></TableCell>
                                <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton/></TableCell>
                                <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton/></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton/></TableCell>
                                <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton/></TableCell>
                                <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton/></TableCell>
                                <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton/></TableCell>
                                <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton/></TableCell>
                                <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton/></TableCell>
                                <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton/></TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton/></TableCell>
                                <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton/></TableCell>
                                <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton/></TableCell>
                                <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton/></TableCell>
                                <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton/></TableCell>
                                <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton/></TableCell>
                                <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton/></TableCell>
                            </TableRow>
                            </>}
                            </TableBody>
                        </Table>

                    </Box>

                </Box>
            </Modal>
        </div>
    );
}
