import * as React from 'react';
import { Box, Divider, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import CancelIcon from '@mui/icons-material/Cancel';
import verifyweight from '../Assets/images/Verifyweight.svg';


export interface IVerifyWeightModalProps {
    onCloseModal: any;
    isOpen: any;
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { lg: '90vw', xl: '80vw' },
    height: '90vh',
    bgcolor: 'background.paper',
    borderRadius: '10px',
    p: 1,
};


export function VerifyWeightModal(props: IVerifyWeightModalProps) {
    const { onCloseModal, isOpen, } = props;


    return (
        <div>
            <Modal
                open={isOpen}
                onClose={onCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
                        <Typography
                            style={{
                                fontSize: "1rem",
                                fontWeight: "600",
                                width: "100%",
                                textAlign: "center",
                                marginRight: '-2rem'
                            }}
                        >
                            Verify Weight
                        </Typography>
                        <img src={verifyweight} title='Verify Weight' style={{ height: '1.5rem', marginRight: '0.5rem' }} />
                        <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
                    </Box>
                    <Divider
                        sx={{
                            borderColor: "primary.light",
                            margin: "0 1rem",
                            marginBottom: "1rem",
                        }}
                    />
                    <Box sx={{ height: {lg:'80vh',xl:'83vh'} }}
                        adl-scrollbar="true"
                        adl-scrollbar-width="0.3">
                        <Table>
                            <TableHead sx={{ backgroundColor: 'primary.light', position: 'sticky', top: '0' }}>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', width: '25rem' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                            </TableHead>

                           <TableBody>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                 <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sl.No</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No.</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part Name</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Image</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Level</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Type</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Quantity</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Weight</TableCell>
                                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Verified</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}
