import { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { Divider, FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import CalibrationFileUpload from "./CalibrationFileUpload";
import CancelIcon from '@mui/icons-material/Cancel';
import styles from './CalibrationModel.module.scss';
import { LoadingButton } from "@mui/lab";
import CalibrationFileS3send from "./CalibrationFileS3send";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: " 50rem",
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

interface CalibrationAddModelProps {
  isOpen: any;
  onCloseModal: any;
  reloadCalibrationData: any;
  setReloadCalibrationData: any;
}

const CalibrationAddModel = (props: CalibrationAddModelProps) => {
  const { isOpen, onCloseModal, reloadCalibrationData, setReloadCalibrationData } = props;
  const [instrumentDescription, setInstrumentDescription] = useState<any>();
  const [dateOfPurchase, setDateOfPurchase] = useState<any>();
  const [lifeInYrs, setLifeInYrs] = useState<number>(0);
  const [calibrationRequired, setCalibrationRequired] = useState<any>();
  const [lastCalibrationDate, setLastCalibrationDate] = useState<any>();
  const [calibrationExpiryDate, setCalibrationExpiryDate] = useState<any>();
  const [inputFileCalibration, setInputFileCalibration] = useState<any>();
  const [instrumentFileCalibration, setInstrumentFileCalibration] = useState<any>();
  const [quantity, setQuantity] = useState<number>(1);
  const [error, setError] = useState<boolean>(false);
  const [status, setStatus] = useState<any>();
  const [ownership, setOwnership] = useState<any>();
  const [savePopupLoader, setSavePopupLoader] = useState<boolean>(false);
  const sendImageAndReportCalibration = useRef<any>({ value: [] });

  useEffect(() => {
    setInstrumentDescription('');
    setDateOfPurchase(new Date());
    setLifeInYrs(0);
    setCalibrationRequired("YES");
    setLastCalibrationDate(new Date());
    setCalibrationExpiryDate(new Date());
    setStatus('Working');
    setOwnership('BOM');
    setInputFileCalibration(undefined);
    setInstrumentFileCalibration(undefined);
    setQuantity(1);
    setError(false);
  }, [isOpen, onCloseModal])

  const handleAddDuration: any = (e: any) => {
    setLifeInYrs(Number(e.target.value));
  }

  const handleAddQuantity: any = (e: any) => {
    setQuantity(Number(e.target.value));
  }

  const handleInstrumentDescription: any = (e: any) => {
    setInstrumentDescription(e.target.value);
  }

  const handleChangeCalibrationRequired = (event: SelectChangeEvent) => {
    setCalibrationRequired(event.target.value as string);
  };

  const handleChangeStatus = (event: SelectChangeEvent) => {
    setStatus(event.target.value as string);
  };

  const handleChangeOwnership = (event: SelectChangeEvent) => {
    setOwnership(event.target.value as string);
  };

  const handlerFileChange = (e: any) => {
    setInputFileCalibration(e.target.files)
  }

  const handlerImageChange = (e: any) => {
    setInstrumentFileCalibration(e.target.files)
  }

  const submitInstrumentDetails = async () => {
    if (instrumentDescription == '' || instrumentFileCalibration == undefined || (calibrationRequired == 'YES' && inputFileCalibration == undefined)) {
      setError(true)
    }
    else {
      setSavePopupLoader(true);
      if (calibrationRequired == 'YES') {
        await CalibrationFileS3send(
          [inputFileCalibration[0], instrumentFileCalibration[0]],
          instrumentDescription,
          dateOfPurchase,
          lifeInYrs,
          calibrationRequired,
          lastCalibrationDate,
          calibrationExpiryDate,
          status,
          ownership,
          reloadCalibrationData,
          setReloadCalibrationData,
          onCloseModal,
          "createCalibration",
          setSavePopupLoader,
          -1,
          quantity,
          sendImageAndReportCalibration
        )
      }
      else {
        CalibrationFileUpload(
          instrumentFileCalibration,
          instrumentDescription,
          dateOfPurchase,
          lifeInYrs,
          calibrationRequired,
          lastCalibrationDate,
          calibrationExpiryDate,
          status,
          ownership,
          reloadCalibrationData,
          setReloadCalibrationData,
          onCloseModal,
          "createCalibration",
          setSavePopupLoader,
          -1,
          quantity
        )
      }
    }
  }

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: '-2rem'
              }}
            >
              Add Instrument Calibration
            </Typography>
            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />




          <Box sx={{ padding: '0 0.5rem 0.5rem', display: 'flex', flexDirection: 'column', rowGap: '1rem', width: '100%' }}>
            <FormControl fullWidth>
              <TextField
                autoFocus
                variant="standard"
                InputProps={{
                  style: { color: "#007fff", fontSize: "1rem" },
                }}
                InputLabelProps={{ style: { fontSize: "1rem" } }}
                value={instrumentDescription}
                onChange={handleInstrumentDescription}
                sx={{
                  width: "100%",
                  "& .MuiFormLabel-root": {
                    color: "primary.main",
                  },
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: "primary.main",
                  },
                  "& .Mui-focused": {
                    color: "primary.main",
                  },
                  "& .Mui-error": {
                    color: "#d32f2f",
                    borderBottomColor: "#d32f2f",
                  },
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "primary.light",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "primary.main",
                  },
                }}
                label="Instrument Description *"
              />
              {error && instrumentDescription == '' ? <p
                style={{
                  fontSize: "0.75rem",
                  color: "#d32f2f",
                  marginTop: "0.4rem",
                }}
              >
                *Please write instrument description
              </p> : <></>}
            </FormControl>
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'baseline', columnGap: '1rem' }}>
              <LocalizationProvider variant="standard" dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  label="Date of Purchase *"
                  value={dateOfPurchase}
                  inputFormat="DD/MM/YYYY"
                  maxDate={new Date()}
                  onChange={(newValue) => { return setDateOfPurchase(newValue) }}
                  renderInput={(params) => <TextField {...params} variant="standard" sx={{
                    width: "100%",
                    "& .MuiFormLabel-root": {
                      color: 'primary.main'
                    },
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: 'primary.main'
                    },
                    "& .MuiSvgIcon-root": {
                      color: 'primary.main',
                    },
                    ".MuiInputBase-input.MuiInput-input": {
                      fontSize: '1rem'
                    },
                    "& .Mui-error": {
                      color: '#d32f2f',
                      borderBottomColor: '#d32f2f',
                    },
                    '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                    '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                    '& .MuiInput-underline': { fontSize: '1rem' },
                  }} />}
                />
              </LocalizationProvider>
              <FormControl fullWidth>
                <InputLabel sx={{ color: 'primary.main', marginLeft: '-1rem', marginTop: '-0.45rem' }} shrink>Life in Yrs *</InputLabel>
                <TextField
                  type="number"
                  variant='standard'
                  // label="Life in Yrs *"
                  placeholder="Life in Yrs *"
                  value={lifeInYrs}
                  onChange={handleAddDuration}
                  inputProps={{
                    step: 'any',
                    min: 0,
                    style: { fontSize: '1rem', }
                  }}
                  InputLabelProps={{
                    shrink: true,
                    color: 'primary',
                  }}
                  sx={{
                    width: "100%",
                    borderBottomColor: 'primary.light',
                    '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                    '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                  }}
                />
              </FormControl>
            </Box>
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
              <FormControl fullWidth>
                <InputLabel sx={{ color: "primary.main", marginLeft: '-1rem' }} shrink>
                  Calibration Required
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  variant='standard'
                  // size="small"
                  value={calibrationRequired}
                  onChange={handleChangeCalibrationRequired}
                  label="Calibration Required"
                  sx={{
                    width: '100%',
                    fontSize: '1rem',
                    "&:before": {
                      borderBottomColor: "primary.light",
                    },
                    "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                      fontSize: '1rem'
                    },
                    '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
                  }}
                >
                  <MenuItem sx={{ fontSize: '1rem', color: 'green' }} value={"YES"}>YES</MenuItem>
                  <MenuItem sx={{ fontSize: '1rem', color: 'red' }} value={"NO"}>NO</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ marginTop: '0.8rem' }}>
                <InputLabel sx={{ color: 'primary.main', marginLeft: '-1rem' }} shrink>Quantity *</InputLabel>
                <TextField
                  type="number"
                  variant='standard'
                  // label="Life in Yrs *"
                  placeholder="Quantity *"
                  value={quantity}
                  onChange={handleAddQuantity}
                  inputProps={{
                    step: 'any',
                    min: 1,
                    style: { fontSize: '1rem', marginTop: '0.4rem' }
                  }}
                  InputLabelProps={{
                    shrink: true,
                    color: 'primary',
                  }}
                  sx={{
                    width: "100%",
                    borderBottomColor: 'primary.light',
                    '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                    '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                  }}
                />
              </FormControl>
            </Box>
            {calibrationRequired == 'NO' ? <></> : <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
              <LocalizationProvider variant="standard" dateAdapter={AdapterMoment}>
                <DesktopDatePicker
                  label="Last Calibration Date *"
                  value={lastCalibrationDate}
                  inputFormat="DD/MM/YYYY"
                  maxDate={new Date()}
                  onChange={(newValue) => { return setLastCalibrationDate(newValue) }}
                  renderInput={(params) => <TextField {...params} variant="standard" sx={{
                    width: "100%",
                    "& .MuiFormLabel-root": {
                      color: 'primary.main'
                    },
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: 'primary.main'
                    },
                    "& .MuiSvgIcon-root": {
                      color: 'primary.main',
                    },
                    ".MuiInputBase-input.MuiInput-input": {
                      fontSize: '1rem'
                    },
                    "& .Mui-error": {
                      color: '#d32f2f',
                      borderBottomColor: '#d32f2f',
                    },
                    '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                    '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                    '& .MuiInput-underline': { fontSize: '1rem' },
                  }} />}
                />
                <DesktopDatePicker
                  label="Calibration Expiry Date *"
                  value={calibrationExpiryDate}
                  inputFormat="DD/MM/YYYY"
                  minDate={new Date()}
                  onChange={(newValue) => { return setCalibrationExpiryDate(newValue) }}
                  renderInput={(params) => <TextField {...params} variant="standard" sx={{
                    width: "100%",
                    "& .MuiFormLabel-root": {
                      color: 'primary.main'
                    },
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: 'primary.main'
                    },
                    "& .MuiSvgIcon-root": {
                      color: 'primary.main',
                    },
                    ".MuiInputBase-input.MuiInput-input": {
                      fontSize: '1rem'
                    },
                    "& .Mui-error": {
                      color: '#d32f2f',
                      borderBottomColor: '#d32f2f',
                    },
                    '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                    '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                    '& .MuiInput-underline': { fontSize: '1rem' },
                  }} />}
                />
              </LocalizationProvider>
            </Box>}
            {calibrationRequired == 'NO' ? <></> : <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
              <Box sx={{ width: '100%' }}>
                <InputLabel style={{ marginTop: '0.5rem', color: '#007fff' }} shrink>Report File *</InputLabel>
                <input
                  type="file"
                  // required
                  id="file"
                  name="file"
                  onChange={handlerFileChange}
                  accept=".pdf,.jpeg,.png,.jpg"
                  className={styles.fileupload}
                  style={{
                    width: '100%', borderBottomColor: '#007fff',
                  }}
                />
              </Box>
              {error && inputFileCalibration == undefined && calibrationRequired == 'YES' ? <p
                style={{
                  fontSize: "0.75rem",
                  color: "#d32f2f",
                  marginTop: "0.4rem",
                }}
              >
                *Please upload calibration report
              </p> : <></>}
            </Box>}
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
              <Box sx={{ width: '100%' }}>
                <InputLabel style={{ marginTop: '0.5rem', color: '#007fff' }} shrink>Instrument Image *</InputLabel>
                <input
                  type="file"
                  // required
                  id="file"
                  name="file"
                  onChange={handlerImageChange}
                  accept=".jpeg,.png,.jpg"
                  className={styles.fileupload}
                  style={{
                    width: '100%', borderBottomColor: '#007fff',
                  }}
                />
              </Box>
              {error && instrumentFileCalibration == undefined ? <p
                style={{
                  fontSize: "0.75rem",
                  color: "#d32f2f",
                  marginTop: "0.4rem",
                }}
              >
                *Please upload instrument image
              </p> : <></>}
            </Box>
            <Box sx={{ width: '100%', display: 'flex', alignItems: 'center', columnGap: '1rem', marginTop: '0.5rem' }}>
              <FormControl fullWidth>
                <InputLabel
                  id="demo-simple-select-label-Activity"
                  sx={{ color: "primary.main", marginLeft: '-1rem' }} shrink
                >
                  Status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  variant='standard'
                  value={status}
                  onChange={handleChangeStatus}
                  sx={{
                    width: '100%',
                    marginTop: '0.5rem !important',
                    "&:before": {
                      borderBottomColor: "primary.light",
                    },
                    "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                      fontSize: '1rem'
                    },
                    '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
                  }}
                >
                  <MenuItem sx={{ fontSize: '1rem' }} value={"Working"}>Working</MenuItem>
                  <MenuItem sx={{ fontSize: '1rem' }} value={"Obsolete"}>Obsolete</MenuItem>
                  <MenuItem sx={{ fontSize: '1rem' }} value={"Not-Working"}>Not-Working</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth>
                <InputLabel
                  id="demo-simple-select-label-Activity"
                  sx={{ color: "#007fff", marginLeft: '-1rem' }} shrink
                >
                  Ownership
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  variant='standard'
                  value={ownership}
                  onChange={handleChangeOwnership}
                  sx={{
                    width: '100%',
                    marginTop: '0.5rem !important',
                    "&:before": {
                      borderBottomColor: "primary.light",
                    },
                    "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                      fontSize: '1rem'
                    },
                    '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
                  }}
                >
                  <MenuItem sx={{ fontSize: '1rem' }} value={"BOM"}>BOM</MenuItem>
                  <MenuItem sx={{ fontSize: '1rem' }} value={"CO"}>CO</MenuItem>
                  <MenuItem sx={{ fontSize: '1rem' }} value={"ID"}>ID</MenuItem>
                  <MenuItem sx={{ fontSize: '1rem' }} value={"MAF"}>MAF</MenuItem>
                  <MenuItem sx={{ fontSize: '1rem' }} value={"E&E"}>E&E</MenuItem>
                  <MenuItem sx={{ fontSize: '1rem' }} value={"PCB"}>PCB</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </Box>
          <Box sx={{ width: '100%', textAlign: 'right', margin: '0.5rem', paddingRight: '1rem' }} >
            <LoadingButton onClick={submitInstrumentDetails} loading={savePopupLoader}
              size="small"
              variant="contained"
              sx={{
                padding: "0.5rem",
                lineHeight: 1,
                "&:hover": {
                  transform: 'Scale(1.05)',
                  transition: 'transform 0.5s ease',
                },
                '.MuiCircularProgress-root': {
                  color: 'primary.main',
                },
              }}>
              Add
            </LoadingButton>
          </Box>
        </Box>

      </Modal>
    </div >
  );
};

export default CalibrationAddModel;
