const CALCULATOR_TYPE = {
    RAW_MATERIAL: 1,
    PROCESS: 2,
    OVERHEAD: 3,
};

export type InputContentType =
    "ComponentDetails"
    | "StrokeRateDatabase"
    | "ProcessDetails"
    | "MaterialCost"
    | "ToolDatabase"
    | "FastenerDatabase"
    | "PurchasingItem"
    | "MachineCost"
    | "LabourCost"
    | "CostCalculation"
    | "BOMTableCalculator"

const DataBaseContentTypes: InputContentType[] = ["MachineCost", "MaterialCost", "FastenerDatabase", "PurchasingItem", "ToolDatabase", "LabourCost", "StrokeRateDatabase"];

const CALCULATOR_INPUT_CONTENT_TYPE = {
    BOM_PARAMETER: "ComponentDetails",
    COMMODITY_PARAMETER: "ProcessDetails",
    MATERIAL_DB: "Material",
    MACHINE_DB: "Machine",
    LABOUR_DB: "Labour",
    TOOL_DB: "ToolDatabase",
    FASTENER_DB: "FastenerDatabase",
    PURCHASING_ITEM_DB: "PurchasingItem",
    CALCULATOR_RESULT: "CostCalculation",
    COST_INPUT: "CostEstimation",
    SELL_BOM_PARAMETER: "SellBOMVersionDetail",
    PROCURE_DATABASE: "ProductVersion",
    STROKE_RATE_DATABASE: "StrokeRateDatabase",
    BOM_TABLE_CALCULATOR: "BOMTableCalculator"
};

const CALCULATOR_INPUT_TYPE = {
    BOM_PARAMETER: 1,
    COMMODITY_PARAMETER: 2,
    DATABASE: 3,
    CONSTANT: 4, // fixed value, default value filled during configuration
    FORMULA: 5,
    USER_INPUT: 6,
    COST_INPUT: 7,
    CALCULATOR_RESULT: 8,
    SELL_BOM_PARAMETER: 9,
    PROCURE_DATABASE: 10,
    VOLUME: 11,
    BOM_TABLE_CALCULATOR: 12,
    PCB_BOM_TABLE: 13
};

const BOM_CALCULATOR_INPUT_TYPE = {
    BOM_PARAMETER: 1,
    COMMODITY_PARAMETER: 2,
    BOM_TABLE_CALCULATOR: 12,
    CONSTANT: 4, // fixed value, default value filled during configuration
    FORMULA: 5,
    USER_INPUT: 6,
    COST_INPUT: 7,

};
const lightenColor = (color: any, factor: any) => {
    // Convert hex color to RGB
    let r = parseInt(color.substring(1, 3), 16);
    let g = parseInt(color.substring(1, 5).substring(2, 4), 16);
    let b = parseInt(color.substring(1, 7).substring(4, 6), 16);

    // Increase lightness by multiplying each channel value
    r = Math.min(255, r + (255 - r) * factor);
    g = Math.min(255, g + (255 - g) * factor);
    b = Math.min(255, b + (255 - b) * factor);

    // Convert RGB back to hex
    return `#${((1 << 24) + (Math.round(r) << 16) + (Math.round(g) << 8) + Math.round(b)).toString(16).slice(1)}`;
};

const calculatorInputBackgroundColorMapView = {
    [CALCULATOR_INPUT_TYPE.BOM_PARAMETER]: lightenColor("#FFC1C1", 0.5), // Lightened light red
    [CALCULATOR_INPUT_TYPE.COMMODITY_PARAMETER]: lightenColor("#FFEB80", 0.5), // Lightened light yellow
    [CALCULATOR_INPUT_TYPE.DATABASE]: lightenColor("#FFD1A1", 0.5), // Lightened light green
    [CALCULATOR_INPUT_TYPE.CONSTANT]: lightenColor("#FFB3FF", 0.5), // Lightened light magenta
    [CALCULATOR_INPUT_TYPE.FORMULA]: lightenColor("#B2FFC1", 0.5), // Lightened light orange
    [CALCULATOR_INPUT_TYPE.USER_INPUT]: lightenColor("#F7F7F7", 0.5), // Lightened light gray
    [CALCULATOR_INPUT_TYPE.COST_INPUT]: lightenColor("#FFBFD3", 0.5), // Lightened light pink
    [CALCULATOR_INPUT_TYPE.CALCULATOR_RESULT]: lightenColor("#D1C6FF", 0.5), // Lightened light lavender
    [CALCULATOR_INPUT_TYPE.SELL_BOM_PARAMETER]: lightenColor("#FFE5B4", 0.5), // Lightened light peach
    [CALCULATOR_INPUT_TYPE.BOM_TABLE_CALCULATOR]: lightenColor("#C1E6FF", 0.5), // Lightened light cyan
    [CALCULATOR_INPUT_TYPE.VOLUME]: lightenColor("#B2B2FF", 0.5), // Lightened light periwinkle
};



const calculatorInputBackgroundColorMap = {
    [CALCULATOR_INPUT_TYPE.BOM_PARAMETER]: "#FFFFFF",
    [CALCULATOR_INPUT_TYPE.BOM_TABLE_CALCULATOR]: "#FFFFFF",
    [CALCULATOR_INPUT_TYPE.COMMODITY_PARAMETER]: "#FFFFFF",
    [CALCULATOR_INPUT_TYPE.DATABASE]: "#FFFFFF",
    [CALCULATOR_INPUT_TYPE.CONSTANT]: "#FFFFFF",
    [CALCULATOR_INPUT_TYPE.FORMULA]: "#a5e1a6",
    [CALCULATOR_INPUT_TYPE.USER_INPUT]: "#FFF",
    [CALCULATOR_INPUT_TYPE.COST_INPUT]: "#FFFFFF",
    [CALCULATOR_INPUT_TYPE.CALCULATOR_RESULT]: "#FFFFFF",
    [CALCULATOR_INPUT_TYPE.SELL_BOM_PARAMETER]: "#FFFFFF",

    [CALCULATOR_INPUT_TYPE.VOLUME]: "#add8e6",
};

const CALCULATOR_RESULT_COLOR = "#ddefff";

const calculatorInputOptions = [
    { key: 1, value: 1, text: "BOM Parameter" },
    // { key: 2, value: 2, text: "Commodity Parameter" },
    { key: 3, value: 3, text: "Database" },
    { key: 4, value: 4, text: "Constant" },
    { key: 5, value: 5, text: "Formula" },
    { key: 6, value: 6, text: "User Input" },
    { key: 7, value: 7, text: "Cost Input" },
    { key: 8, value: 8, text: "Calculator Result" },
    { key: 12, value: 12, text: "BOM Table Calculator" },
    { key: 11, value: 11, text: "Volume (Fetch from Assumptions Card)" },
    { key: 13, value: 13, text: "PCB BOM Table" },
];

const BOMcalculatorInputOptions = [
    { key: 1, value: 1, text: "BOM Parameter" },
    { key: 4, value: 4, text: "Constant" },
    { key: 5, value: 5, text: "Formula" },
    { key: 12, value: 12, text: "BOM Table Calculator" },
    { key: 6, value: 6, text: "User Input" },
];
const BOMcalculatorTableInputOptions = [
    { key: 1, value: 1, text: "BOM Parameter" },
    { key: 12, value: 12, text: "BOM Table Calculator" },
];

const TriangulationInputOptions = [
    { key: 4, value: 4, text: "Constant" },
    { key: 5, value: 5, text: "Formula" },
    { key: 6, value: 6, text: "User Input" },
    { key: 7, value: 7, text: "Cost Input" },
    { key: 11, value: 11, text: "Volume" }
];

const CALCULATOR_COST_INPUT_MAP = {
    RAW_MATERIAL_COST: 1,
    PROCESS_COST: 2,
    BOP_COST: 3,
};

const CALCULATOR_CATEGORY = {
    RAW_MATERIAL: 1,
    PURCHASE_ITEM: 2,
    LABOUR: 3,
    MACHINE: 4,
    TOOL: 5,
    SET_UP: 6,
    STROKE_RATE: 7
};

const CALCULAOR_CATEGORY_LABEL = {
    [CALCULATOR_CATEGORY.RAW_MATERIAL]: "Raw Material",
    [CALCULATOR_CATEGORY.PURCHASE_ITEM]: "Purchase Item",
    [CALCULATOR_CATEGORY.LABOUR]: "Labour",
    [CALCULATOR_CATEGORY.MACHINE]: "Machine",
    [CALCULATOR_CATEGORY.TOOL]: "Tool",
    [CALCULATOR_CATEGORY.SET_UP]: "Set-up",
    [CALCULATOR_CATEGORY.STROKE_RATE]: "Stroke Rate",
    0: "Overhead"
};

const calculatorCategoryOptions = {
    [CALCULATOR_TYPE.RAW_MATERIAL]: [
        { key: 1, value: 1, text: "Raw Material" },
        { key: 2, value: 2, text: "Bought Out" },
    ],
    [CALCULATOR_TYPE.PROCESS]: [
        { key: 3, value: 3, text: "Labour" },
        { key: 4, value: 4, text: "Machine" },
        { key: 5, value: 5, text: "Tool" },
        { key: 6, value: 6, text: "Set-up" },
    ],
    [CALCULATOR_TYPE.OVERHEAD]: [
        { key: 0, value: 0, text: "Overhead" },
    ],
};

const getDefaultCalculatorCategory = (inputType: number) => {
    return inputType === CALCULATOR_TYPE.RAW_MATERIAL
        ? CALCULATOR_CATEGORY.RAW_MATERIAL
        : inputType === CALCULATOR_TYPE.PROCESS
            ? CALCULATOR_CATEGORY.LABOUR
            : 0;
};

const calculatorCostInputOptions = [
    { key: 1, value: "raw_material", text: "Raw Material Cost" },
    { key: 2, value: "bop", text: "Bought-out parts Cost" },
    { key: 3, value: "labour", text: "Labour Cost" },
    { key: 4, value: "machine", text: "Machine Cost" },
    { key: 5, value: "tool", text: "Tool Cost" },
    { key: 6, value: "setup", text: "Set-up Cost" },
    // { key: 7, value: "stoke_rate", text: "Stroke Rate Cost" },
    { key: 8, value: "child_part_cost", text: "Child Part Cost" },
];

const CALCULATOR_DATABASES_MAP = {
    MATERIAL: 1,
    MACHINE: 2,
    LABOUR: 3,
    TOOL: 4,
    FASTENER: 5,
    PURCHASEING_ITEM: 6,
    STROKE_RATE: 7,
    CURRENCY: 8,
};

const calculatorDatabaseOptions = [
    { key: 1, value: 1, text: "Material" },
    { key: 2, value: 2, text: "Machine" },
    { key: 3, value: 3, text: "Labour" },
    { key: 4, value: 4, text: "Tool" },
    { key: 6, value: 6, text: "Bought-out Part" },
    { key: 7, value: 7, text: "Operation Rate Database" },
    { key: 8, value: 8, text: "Currency Database" },
];
const procureOptions = [
    { key: "dynamic_parameter", value: "dynamic_parameter", text: "Dynamic Parameter" },
    { key: "unit_rate", value: "unit_rate", text: "Unit Rate" },
];
const calculatorDatabaseColumnsOptions = {
    [CALCULATOR_DATABASES_MAP.MACHINE]: [
        { key: "cost_per_hour", value: "cost_per_hour", text: "Cost/Hour" },
    ],
    [CALCULATOR_DATABASES_MAP.MATERIAL]: [
        { key: "density", value: "density", text: "Density (kg/m3)" },
        { key: "material_cost", value: "material_cost", text: "Material Cost" },
        { key: "scrap_cost", value: "scrap_cost", text: "Scrap Cost" },
    ],
    [CALCULATOR_DATABASES_MAP.LABOUR]: [
        { key: "cost_per_hour", value: "cost_per_hour", text: "Cost/Hour" },
    ],
    [CALCULATOR_DATABASES_MAP.TOOL]: [
        { key: "price", value: "price", text: "Tool Cost" },
    ],
    [CALCULATOR_DATABASES_MAP.PURCHASEING_ITEM]: [
        { key: "unit_cost", value: "unit_cost", text: "Per unit Cost (INR)" },
    ],
    [CALCULATOR_DATABASES_MAP.STROKE_RATE]: [
        { key: "rate_per_unit", value: "rate_per_unit", text: "Rate/Unit" },
    ],
    [CALCULATOR_DATABASES_MAP.CURRENCY]: [
        { key: "rate_per_unit", value: "currency", text: "Conversion to INR" },
    ],
};


const triangulation_calculator = [
    { key: 1, value: "Manufacturing Cost 1", text: "Manufacturing Cost 1" },
    { key: 2, value: "Manufacturing Cost 2", text: "Manufacturing Cost 2" },
    { key: 3, value: "Ex-Showroom Price Excluding GST 1", text: "Ex-Showroom Price Excluding GST 1" },
    { key: 4, value: "Ex-Showroom Price Excluding GST 2", text: "Ex-Showroom Price Excluding GST 2" },
    { key: 5, value: "OEM Assembly cost", text: "OEM Assembly cost" },
    // { key: 6, value: "MFG cost", text: "MFG cost" },
    { key: 7, value: "Warranty", text: "Warranty" },
    { key: 8, value: "OBL", text: "OBL" },
    // { key: 9, value: "OEM Cost", text: "OEM Cost" },
    { key: 10, value: "R&D", text: "R&D" },
    { key: 11, value: "Finance costs", text: "Finance costs" },
    { key: 12, value: "ICC", text: "ICC" },
    { key: 13, value: "overheads", text: "overheads" },
    { key: 14, value: "OEM profit", text: "OEM profit" },
    { key: 15, value: "Dealer profit", text: "Dealer profit" },
    // { key: 16, value: "Ex showroom price", text: "Ex showroom price" },

];

const BOMPCBOptions = [
    {
        id: 1,
        title: "ALL",
        value: "ALL"
    },
    {
        id: 2,
        title: "SMD-TOP",
        value: "SMD-Top"
    },
    {
        id: 3,
        title: "SMD-BOTTOM",
        value: "SMD-Bottom"
    },
    {
        id: 4,
        title: "THD-TOP",
        value: "THD-Top"
    },
    {
        id: 5,
        title: "THD-BOTTOM",
        value: "THD-Bottom"
    },
    
]

const pcb_table_element_list:any = [
    { id: 1, title: "Individual row parameter", value: "Individual row parameter" },
    { id: 2, title: "Sum of all rows", value: "Sum of all rows" },
    { id: 3, title: "Average of all rows", value: "Average of all rows" },  
    { id: 4, title: "Minimum value", value: "Minimum value" }, 
    { id: 5, title: "Maximum value", value: "Maximum value" },
    
]

export {
    CALCULATOR_TYPE,
    DataBaseContentTypes,
    CALCULATOR_INPUT_CONTENT_TYPE,
    CALCULATOR_DATABASES_MAP,
    CALCULATOR_INPUT_TYPE,
    CALCULATOR_COST_INPUT_MAP,
    CALCULATOR_CATEGORY,
    calculatorCategoryOptions,
    calculatorInputOptions,
    calculatorDatabaseOptions,
    calculatorDatabaseColumnsOptions,
    calculatorCostInputOptions,
    calculatorInputBackgroundColorMap,
    procureOptions,
    CALCULATOR_RESULT_COLOR,
    getDefaultCalculatorCategory,
    CALCULAOR_CATEGORY_LABEL,
    TriangulationInputOptions,
    BOM_CALCULATOR_INPUT_TYPE,
    BOMcalculatorInputOptions,
    triangulation_calculator,
    BOMcalculatorTableInputOptions,
    calculatorInputBackgroundColorMapView,
    BOMPCBOptions,
    pcb_table_element_list
};
