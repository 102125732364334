import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { SubsystemList } from "../Reducers/subsytem.reducer";
import subsystem_Services from "../Services/subsystem.service";




export interface SubsystemListProps {
    projectId: any;
    module:any
}

export const getSubsystemDataMedia = createAsyncThunk(
    "subsystem_media/get",
    async ( arg: SubsystemListProps, { rejectWithValue } ) => {
        try {
            const { data } = await subsystem_Services.getSubsystemData( arg.projectId , arg.module );

            return {
                subsystemlist: data as SubsystemList[],
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
)



export const resetSubsystemDataMedia = createAction( "subsystem/reset" );