// vendors
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";


// redux
// import { RepoImage } from "app/Redux/Reducers/imageRepository.reducer";
// import { ImageRepositoryProductInfo } from "app/Redux/Reducers/imageRepositoryProductInfo.reducer";
import { ID } from "../../utlis/commonInterfaces";
import { RepoImage } from "../Reducers/imageRepository.reducer";
import { ImageRepositoryProductInfo } from "../Reducers/imageRepositoryProductInfo.reducer";
import archRepositoryService from "../Services/archRepository.service";

// -------------------------------------------------------------------------------------
// api actions
// -------------------------------------------------------------------------------------

export interface GetRepoArchArg {
    project:any,
    topVault: any,
    abbreviation: string|number,
    module: any;
    row:any;
    page:any;
    group?: any;
}
const getArchRepoImages = createAsyncThunk(
    "archRepository/get",
    async ( arg: GetRepoArchArg, { rejectWithValue } ) => {
        try {
            const { data: { images, ...rest } } = await archRepositoryService.get_dataImage( arg );
            // console.log(data,"data")
            return {
                images: images as RepoImage[],
                productInfo: rest as ImageRepositoryProductInfo
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);
//---------------------------------------------------------------------------------------------

export interface GetArchRepoImagesHeaderDataArg {
    topVault: any,
    abbreviation: string,
    module: any;
}
export const getArchRepoImagesHeaderData = createAsyncThunk(
    "archRepository/getHeaderData",
    async ( arg: GetArchRepoImagesHeaderDataArg, { rejectWithValue } ) => {
        try {
            const { data } = await archRepositoryService.get_infoImage( arg );

            return {
                infoImage: data as any,
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

// -------------------------------------------------------------------------------------
// reducer actions
// -------------------------------------------------------------------------------------

const clearArchRepoImages = createAction<ID>( "circuit-diagram/reset" );

// -------------------------------------------------------------------------------------
// exportsclearRepoImages
// -------------------------------------------------------------------------------------

export {
    getArchRepoImages,
    clearArchRepoImages,
};
