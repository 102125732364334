import React, { useEffect, useRef, useState } from "react";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { usePdf } from "@mikecousins/react-pdf";
import styles from "./Workshoppdfmodule.module.scss";
import "./Workshoppdfmodule.scss";
import { Box, Checkbox, IconButton, Skeleton, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import VerifiedIcon from '@mui/icons-material/Verified';
import DownloadIcon from '@mui/icons-material/Download';
import DeleteIcon from '@mui/icons-material/Delete';
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { LoadingButton } from "@mui/lab";
import { API } from "../../api-services";
import swal from "sweetalert";

interface Params {
  projectId: string;
  fileIndex: any;
  pageCount: any;
  projectname: any;
  abbreviation: any;
}
interface WorkshoppdfmoduleProps {
  file_paths_read: any;
  page: number;
  pdfName?: any;
  setIsExpand?: any;
  isExpand?: any;
  pdfDataLength?: any;
  apiCallWorkstationData?: boolean;
  setApiCallWorkstationData?: any;
  apiCallAfterDelete?: any;
  setApiCallAfterDelete?: any;
  pdfInfo?: any;
}

const Workshoppdfmodule: React.FC<WorkshoppdfmoduleProps> = (props) => {
  const { file_paths_read, page, pdfName, setIsExpand, isExpand, pdfDataLength, apiCallWorkstationData, setApiCallWorkstationData, pdfInfo, apiCallAfterDelete, setApiCallAfterDelete } = props;
  const { projectId, fileIndex, pageCount, projectname, abbreviation } = useRouteParams<Params>();
  const [loader, setLoader] = useState<boolean>(false);
  const [downloadLoading, setDownloadLoading] = useState<boolean>(false);
  const [apiDeleteLoading, setApiDeleteLoading] = useState<boolean>(false);
  const [verificationCheck, setVerificationCheck] = useState<boolean>();

  const history = useHistory();

  useEffect(() => {
    if (pdfInfo) {
      setVerificationCheck(pdfInfo.is_validated);
    }
  }, [pdfInfo])

  const handleOpen = () => setIsExpand(true);
  const handleClose = () => setIsExpand(false);

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const onDocumentLoadSuccess = (data: any) => {
    setLoader(true);
  };

  const onDocumentLoadFail = () => {
    setLoader(false);
  };

  useEffect(() => { setLoader(false); }, [fileIndex])

  const downloadClicked = () => {
    setDownloadLoading(true)
    fetch(file_paths_read, {
      method: 'GET'
    }).then(resp => resp.blob())
      .then(async (blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = pdfName; // the filename you want
        await document.body.appendChild(a);
        a.click();
        window.URL.revokeObjectURL(url);
        setDownloadLoading(false)
      })
  }

  const handleNext = () => {
    history.push(`/eirandworkshop/${projectId}/${projectname}/${abbreviation}/${Number(fileIndex) + 1}/0`);
  };

  const handlePrev = () => {
    history.push(`/eirandworkshop/${projectId}/${projectname}/${abbreviation}/${Number(fileIndex) - 1}/0`);
  };

  const { pdfDocument, pdfPage } = usePdf({
    file:
      file_paths_read && file_paths_read != undefined
        ? file_paths_read
        : "https://arxiv.org/pdf/quant-ph/0410100.pdf",
    page,
    canvasRef,
    onDocumentLoadFail,
    onDocumentLoadSuccess,
  });

  const handleChangeVerification = (e: any) => {
    API.post('workshop/banner_fields/', {
      validate_file: e.target.checked,
      id: pdfInfo.id,
    }, 0).then((res: any) => {
      setVerificationCheck(e.target.checked);
      setApiCallWorkstationData(!apiCallWorkstationData);
    })
  }

  const handleDelete = () => {
    swal({
      text: `Are you sure you want to delete Banner ?`,
      icon: "error",
      buttons: ["Cancel", "Yes, Delete"],
    }).then((confirm) => {
      if (confirm) {
        setApiDeleteLoading(true);
        API.post(
          `workshop/banner_fields/`,
          { delete_file: true, id: pdfInfo.id },
          0
        ).then((res: any) => {
          setApiDeleteLoading(false);
          pdfDataLength > 1 ? Number(fileIndex) != pdfDataLength ? history.push(`/eirandworkshop/${projectId}/${projectname}/${abbreviation}/${fileIndex}/0`) : history.push(`/eirandworkshop/${projectId}/${projectname}/${abbreviation}/${Number(fileIndex) - 1}/0`) : history.push(`/eirandworkshop/${projectId}/${projectname}/0/0/0`);
          setApiCallAfterDelete(!apiCallAfterDelete);
          setApiCallWorkstationData(!apiCallWorkstationData)
        })
          .catch((err: any) => { });
      }
    });
  };

  return (
    <>
      <Box className={styles.expandBanner} >
        <Box sx={{ display: 'flex', alignItems: 'center', }}>
          <Box className={styles.navBar}>
            <IconButton
              size="small"
              onClick={handlePrev}
              sx={{ color: 'primary.main' }}
              style={Number(fileIndex) == 1 ? { cursor: "not-allowed" } : { cursor: "pointer", color: '#007fff' }}
              disabled={Number(fileIndex) === 1}
            >
              <ChevronLeftIcon
                titleAccess="Previous"
              />
            </IconButton>

            <Box className={styles.right_arrow_icon}>
              <Typography className={isExpand == true ? styles.pdfnameexpanded : styles.pdfname} sx={{textAlign:'center', fontSize: "1rem", textOverflow: 'ellipsis', whiteSpace: 'nowrap', overflow: 'hidden',width:{lg:'11rem',xl:'18rem'} }}>
                {pdfName}
              </Typography>
            </Box>

            <IconButton
              size="small"
              sx={Number(fileIndex) == pdfDataLength ? { cursor: "not-allowed" } : { cursor: "pointer", color: '#007fff' }}
              onClick={handleNext}
              disabled={Number(fileIndex) == pdfDataLength}
            >
              <ChevronRightIcon
                style={{
                  cursor: "pointer",
                }}
                titleAccess="Next"
              />
            </IconButton>
          </Box>
          <Box sx={{ display: 'flex', alignItems: 'flex-start', columnGap: '0.5rem',height:'2rem' }}>
            {verificationCheck != undefined && <Checkbox
              style={{ padding: '3px' }}
              checkedIcon={<Box sx={{ backgroundColor: 'white', borderRadius: '50%', height: '1rem', width: '1rem' }}>
                <VerifiedIcon sx={{ color: '#39FF14', position: 'relative', margin: '-0.35rem -0.25rem 0', }} />
              </Box>}
              checked={verificationCheck}
              title="Validated"
              onChange={handleChangeVerification}
              color="success"
              sx={{ "& .MuiSvgIcon-root": { fontSize: 22 }, color: "#007fff" }} />}
            <LoadingButton
              loading={downloadLoading}
              onClick={downloadClicked} title="Download" sx={{ p: 0, m: 0, minWidth: '1rem' }}>
              <DownloadIcon sx={{
                cursor: 'pointer',
                "&:hover": {
                  transform: 'Scale(1.05)',
                  transition: 'transform 0.5s ease',
                  color: '#007fff'
                }
              }}
              />
            </LoadingButton>
            {isExpand == true ? <ZoomInMapIcon onClick={handleClose} sx={{
              cursor: 'pointer', "&:hover": {
                transform: 'Scale(1.05)',
                transition: 'transform 0.5s ease',
              }
            }} /> : <ZoomOutMapIcon onClick={handleOpen} sx={{
              cursor: 'pointer', "&:hover": {
                transform: 'Scale(1.05)',
                transition: 'transform 0.5s ease',
              }
            }} />}
            <LoadingButton sx={{ minWidth: '1rem', padding: 0 }} onClick={handleDelete} loading={apiDeleteLoading}>
              <DeleteIcon titleAccess="Delete"
                sx={{
                  cursor: 'pointer',
                  "&:hover": {
                    transform: 'Scale(1.05)',
                    transition: 'transform 0.5s ease',
                  }
                }} />
            </LoadingButton>
          </Box>
        </Box>
      </Box >
      <Box sx={!pdfDocument ? { textAlign: "center", paddingLeft: '1rem' } : { textAlign: "center", height: { lg: '82vh', xl: '87vh' }, paddingLeft: '1rem' }}
        adl-scrollbar="true"
        adl-scrollbar-width="0.3">
        {loader ?
          <canvas
            ref={canvasRef}
            id={isExpand == true ? "canvasexpand" : "canvas"}
            title={pdfName}
            width="20"
            height="20"
          />
          :
          <Skeleton variant="rectangular" width="100%" height="96%"
            sx={{ padding: '0 1rem', margin: "0 auto", }}
          />}
        {!pdfDocument && <Skeleton variant="rectangular" width="100%" height="96%"
          sx={{ padding: '1rem 1rem', margin: "0 auto", position: 'absolute', top: '13.2vh' }}
        />}
      </Box>
    </>
  );
};

// ----------------------------------------------------------------------------------

export default Workshoppdfmodule;
