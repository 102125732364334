// vendors
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "axios";

// interfaces
// import { transformConfigurationsFromApi } from "app/constants/configuration.constants";

// services
// import bomConfigService from "app/services/bomConfig.service";
// import featureConfigService from "app/services/featureConfig.service";
// import ideaConfigService from "app/services/ideaConfig.service";
import { transformConfigurationsFromApi } from "../../utlis/configuration.constants";
import { ID } from "../../utlis/commonInterfaces";
import bomconfigService from "../Services/bomconfig.service";
import ideaConfigService from "../Services/ideaConfig.service";
import processConfigService from "../Services/processConfig.service";
import inwardConfigService from "../Services/inwardConfig.service";
import featureConfigService from "../Services/featureConfig.service";

// -------------------------------------------------------------------------------------
// api actions
// -------------------------------------------------------------------------------------

export interface GetIdeaConfigsArg {
    projectId: ID;
    stage?:any
}
const getIdeaConfigurations = createAsyncThunk(
    "parameterConfig/getIdeaConfigs",
    async ( arg: GetIdeaConfigsArg, { rejectWithValue } ) => {
        try {
            const { data } = await ideaConfigService.get( arg.projectId, true,arg.stage );
            return {
                configs: transformConfigurationsFromApi( data ),
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

// -------------------------------------------------------------------------------------

export interface GetBOMConfigsArg {
    topVaultId: ID;
}
const getBOMConfigurations = createAsyncThunk(
    "parameterConfig/getBOMConfigs",
    async ( arg: GetBOMConfigsArg, { rejectWithValue } ) => {
        try {
            const { data } = await bomconfigService.get( arg.topVaultId, true );

            return {
                configs: transformConfigurationsFromApi( data ),
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

//Data analysis dropdown option
export interface GetParameterArg {
    category: ID;
    project: ID;
    module: ID;
}
const getParameterData = createAsyncThunk(
    "parameterConfig/parameter",
    async ( arg: GetParameterArg, { rejectWithValue } ) => {
        try {
            const { data } = await bomconfigService.getParameters( arg.category, arg.project, arg.module );

            return {
                configs: transformConfigurationsFromApi( data ),
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

//getParametersOptions

//Data analysis dropdown option
export interface GetParameterArg {
    category: ID;
    project: ID;
    module: ID;
}
const getParameterDataOption = createAsyncThunk(
    "parameterConfig/options",
    async ( arg: GetParameterArg, { rejectWithValue } ) => {
        try {
            const { data } = await bomconfigService.getParametersOptions( arg.category, arg.project, arg.module );

            return {
                configs: transformConfigurationsFromApi( data ),
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

export interface GetParameterArgs {
    type: any;
}
const getCombinationOptions = createAsyncThunk(
    "combinationOptions/options",
    async ( arg: GetParameterArgs, { rejectWithValue } ) => {
        try {
            const { data } = await bomconfigService.getCombinationOptions( arg.type );

            return {
                configs: transformConfigurationsFromApi( data ),
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);
//getCombinationOptions

// -------------------------------------------------------------------------------------

export interface GetBOMConfigsByCategoryArg {
    topVaultId: ID;
    categoryId: ID | ID[];
    all?: boolean;
    vaultId? : any;
}

const getBOMConfigurationsByCategory = createAsyncThunk(
    "parameterConfig/getBOMConfigsByCategory",
    async ( arg: GetBOMConfigsByCategoryArg, { rejectWithValue, signal } ) => {

        try {
            const source = Axios.CancelToken.source();
            signal.addEventListener( "abort", () => {
                source.cancel();
            } );

            const { data } = await bomconfigService.getByCategoryId(

                arg.topVaultId,
                arg.categoryId,
                arg.all,
                source.token,
                arg.vaultId
            );
            return {
                configs: transformConfigurationsFromApi( data ),
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);



const getBOMConfigurationsByCategoryAddModal = createAsyncThunk(
    "parameterConfigAdd/getBOMConfigsByCategory",
    async ( arg: GetBOMConfigsByCategoryArg, { rejectWithValue, signal } ) => {

        try {
            const source = Axios.CancelToken.source();
            signal.addEventListener( "abort", () => {
                source.cancel();
            } );

            const { data } = await bomconfigService.getByCategoryId(

                arg.topVaultId,
                arg.categoryId,
                arg.all,
                source.token,
                arg.vaultId
            );
            return {
                addModalconfigs: transformConfigurationsFromApi( data ),
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

// -------------------------------------------------------------------------------------

export interface GetBOMConfigsByDatatypeArg {
    topVaultId: ID;
    categoryId: ID;
    dataType: string;
}
const getBOMConfigurationsByDatatype = createAsyncThunk(
    "parameterConfig/getBomConfigsByDatatype",
    async ( arg: GetBOMConfigsByDatatypeArg, { rejectWithValue } ) => {
        try {
            const { data } = await bomconfigService.getByDataType( arg );

            return {
                configs: transformConfigurationsFromApi( data ),
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

// -------------------------------------------------------------------------------------

export interface GetFeatureConfigsByCategoryArg {
    projectId: ID;
    categoryId: any;
    all?: boolean;
}
const getFeatureConfigurationsByCategory = createAsyncThunk(
    "parameterConfig/getFeatureConfigsByCategory",
    async ( arg: GetFeatureConfigsByCategoryArg, { rejectWithValue } ) => {
        try {
            const { data } = await ideaConfigService.get( arg.projectId, arg.categoryId, arg.all );

            return {
                configs: transformConfigurationsFromApi( data ),
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

// -------------------------------------------------------------------------------------

export interface GetFeatureConfigsArg {
    projectId: ID;
}
const getFeatureConfigurations = createAsyncThunk(
    "parameterConfig/getFeatureConfigs",
    async ( arg: GetFeatureConfigsArg, { rejectWithValue } ) => {
        try {
            const { data } = await featureConfigService.getByProjectId(
                arg.projectId,
                true
            );

            return {
                configs: transformConfigurationsFromApi( data ),
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

// -------------------------------------------------------------------------------------

export interface GetProcessConfigurationsArg {
    projectId: ID;
    all?: boolean;
}
const getProcessConfigurations = createAsyncThunk(
    "parameterConfig/getProcessConfig",
    async ( arg: GetProcessConfigurationsArg, { rejectWithValue } ) => {
        try {
            const { data } = await processConfigService.get(
                arg.projectId,
                arg.all
            );

            return {
                configs: transformConfigurationsFromApi( data ),
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

//Inward Configuration----------
export interface GetInwardConfigsArg {
    projectId: any;
    top_vault: any;
}
const getInwardConfigurations = createAsyncThunk(
    "inwardparameterConfig/getInwardConfigs",
    async ( arg: GetInwardConfigsArg, { rejectWithValue } ) => {
        try {
            const { data } = await inwardConfigService.get( arg.projectId, arg.top_vault, true );
            return {
                configs: transformConfigurationsFromApi( data ),
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

// -------------------------------------------------------------------------------------
// reducer actions
// -------------------------------------------------------------------------------------

const resetParameterConfigs = createAction( "parameterConfig/reset" );

const resetParameterConfigsAddModal = createAction( "parameterConfigAdd/resetAddModal" );

// -------------------------------------------------------------------------------------
// exports
// -------------------------------------------------------------------------------------

export {
    getIdeaConfigurations,
    getBOMConfigurations,
    getProcessConfigurations,
    getBOMConfigurationsByDatatype,
    getFeatureConfigurations,
    resetParameterConfigs,
    getBOMConfigurationsByCategory,
    getFeatureConfigurationsByCategory,
    getInwardConfigurations,
    getParameterData,
    getParameterDataOption,
    getCombinationOptions,
    getBOMConfigurationsByCategoryAddModal,
    resetParameterConfigsAddModal
};
