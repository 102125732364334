import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { Backdrop, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton, TextField } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { API } from "../../api-services";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60vw",
  maxWidth: "65vw",
  bgcolor: "background.paper",
  borderRadius: "12px",
  boxShadow: 24,
  p: 0,
};

interface IWatermarkModelProps {
  isOpen: any;
  onCloseModal: any;
  data: any;
}

function SequenceChangeModal(props: IWatermarkModelProps) {
  const { isOpen, onCloseModal, data } = props;
  const [tableData, setTableData] = React.useState<any[]>([]);

  const getTableData = () => {
    API.get("/config/change_sequence_in_configuration/",{estimation_id:data?.id}).then((res:any)=>{
        setTableData(res.data)
    }).catch((err:any)=>{

    })
  }

  React.useEffect(()=>{
    getTableData()
  },[])

 

 
  
  return (
    <Modal
      open={isOpen}
      onClose={onCloseModal}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Box sx={style}>
        {/* Header Section */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "3.5rem",
            p: 2,
            backgroundColor: "#007fff",
            borderTopLeftRadius: "12px",
            borderTopRightRadius: "12px",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.2rem",
              fontWeight: "bold",
              width: "100%",
              textAlign: "center",
              color: "#fff",
            }}
          >
            Sequence Change
          </Typography>
          <CancelIcon
            titleAccess="Close"
            sx={{ cursor: "pointer", color: "#fff", fontSize: "1.5rem" }}
            onClick={onCloseModal}
          />
        </Box>
        <Divider sx={{ borderColor: "primary.light" }} />

        {/* Table Section */}
        <Box sx={{ p: 3 }}>
          {/* need a table with two column one sequence and another name, sequence will be editable */}
            <TableContainer>
                <Table>
                <TableHead sx={{backgroundColor:'primary.light'}}>
                    <TableRow>
                    <TableCell>Sequence</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell>Commodity</TableCell>
                    <TableCell>Process</TableCell>
                    <TableCell>alias</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {tableData.map((row: any) => (
                    <TableRow key={row.id}>
                        <TableCell sx={{whiteSpace:'nowrap'}} align="center">
                            {/* <TextField
                                id="outlined-number"
                                type="number"
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                sx={{width: "100px",height: "10px"}}
                                variant="outlined"
                                defaultValue={row.sequence}
                            /> */}
                            <Box sx={{ display: "flex", alignItems: "center", ml: 1 }}>
                  {/* <p style={{ fontSize: "13px" }}>
                    {" "}
                    Qty<sup style={{ color: "red" }}>*</sup> :{" "}
                  </p> */}
                  <TextField
                    type="number"
                    variant="standard" // Use 'standard' variant for underline
                    defaultValue={row.sequence}
                    placeholder="sequence"
                    InputProps={{
                      disableUnderline: false, // Keep underline enabled
                      //@ts-ignore
                      min: 1, // Minimum value
                      sx: {
                        padding: "0px", // Remove extra padding
                        margin: "0px", // Remove extra margin
                        fontSize: "0.875rem", // Smaller font size
                        minHeight: "1.5rem", // Minimal height for compact design
                      },
                    }}
                    sx={{
                      width: "3rem", // Adjust width as needed
                      textAlign: "center", // Center text
                      marginLeft: "1rem",
                      "& .MuiInput-root": {
                        padding: 0, // Remove internal padding
                        height: "1.5rem", // Minimal height
                      },
                      "& .MuiInput-underline:before": {
                        borderBottom: "1px solid black", // Custom underline color before focus
                      },
                      "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
                        borderBottom: "1px solid #007fff", // Custom underline color on hover
                      },
                      "& .MuiInput-underline:after": {
                        borderBottom: "1px solid #007fff", // Custom underline color on focus
                      },
                    }}
                  />
                </Box>
                        </TableCell>
                        <TableCell>{row.calculator_ref_name}</TableCell>
                        <TableCell>{row.cost_calculator__commodity_mapping__commodity__name}</TableCell>
                        <TableCell>{row.cost_calculator__process__name}</TableCell>
                        <TableCell>{row.alias}</TableCell>
                    </TableRow>
                    ))}
                </TableBody>
                </Table>
            </TableContainer>
        </Box>
      </Box>
    </Modal>
  );
}

export default SequenceChangeModal;
