import * as React from "react";
import {
  Box,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Button,
  IconButton,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { API } from "../../../api-services";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { TableOptionsInput } from "../TableOptionsInput";
import Swal from "sweetalert2";
import { NumberInputBoxTable } from "../NumberInputBoxTable";
import { DescriptionInputBoxTable } from "../DescriptionInputBoxTable";
import { SingleSelectInputBoxTable } from "../SingleSelectInputBoxTable";
import { TextInputBoxTable } from "../TextInputBoxTable";

import { ImageInputViewBox } from "./ImageInputViewBox";
import { MultiSelectInputBoxTable } from "./MultiSelectInputBoxTable";
import FunctionsIcon from "@mui/icons-material/Functions";
import LoadingButton from "@mui/lab/LoadingButton";
import { userPermissionAction } from "./BOMEntryLandingPage";
import { ADMIN } from "../../../Redux/Services/admin.service";
import { LinkInputBox } from "../LinkInputBox";
import BOMAllDataService from "../../../Redux/Services/bomTable.service";
import { useDispatch, useSelector } from "react-redux";
import { useBaseModal } from "../../SearchPage/useBaseModal";
import { useBaseParamsModal } from "../../../CustomHook/useBaseParamsModal";
import ArrowCircleLeftIcon from "@mui/icons-material/ArrowCircleLeft";
import ArrowCircleRightIcon from "@mui/icons-material/ArrowCircleRight";
import EditModalForm from "./EditModalForm";
import LaunchIcon from "@mui/icons-material/Launch";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import SyncIcon from "@mui/icons-material/Sync";
import { add, get, set } from "lodash";
import { a } from "@react-spring/web";
export interface IPCBTableModalProps {
  expandStatus: any;
  idddd: any;
  getTableDataaa: any;
  BOMValidationStatus?: any;
  tableIdddList?: any;
  setTableDataCount?: any;
}

export default function PCBTableModal(props: IPCBTableModalProps) {
  const dispatch = useDispatch();
  const {
    expandStatus,
    idddd,
    getTableDataaa,
    BOMValidationStatus,
    tableIdddList,
    setTableDataCount,
  } = props;
  const store: any = useSelector((state) => state);
  const { workstation, topVault, vault, projectId, projectName, copyStatus } =
    useRouteParams<any>();
  const [tableData, setTableData] = React.useState<any>([]);
  const [tableDataLoader, setTableDataLoader] = React.useState<any>(false);
  const modalContentRef = React.useRef<any>();
  const [selectedOptions, setSelectedOptions] = React.useState<any>([]);
  const [type, setType] = React.useState<any>("");
  const [dataForUpdate, setDataForUpdate] = React.useState<any>([]);
  const [colorButton, setColorButton] = React.useState<any>("");
  const [showButton, setShowButton] = React.useState<any>(false);
  const [addClickCounter, setAddClickCounter] = React.useState<any>(0);
  const [counter, setCounter] = React.useState<any>(0);
  const [selectedTableId, setSelectedTableId] = React.useState<any>("");
  const [isDeleting, setIsDeleting] = React.useState<any>(false);
  const [selectedIdDelete, setSelectedIdDelete] = React.useState<any>("");
  const userAction = React.useContext(userPermissionAction);
  const [headerCount, setHeaderCount] = React.useState<any>([]);
  const [key, setKey] = React.useState<any>("");
  const [itmm, setItmm] = React.useState<any>("");
  const [target, setTarget] = React.useState<any>(0);
  const [currentPage, setCurrentPage] = React.useState<number>(1);
  const [totalPageLength, setTotalPageLength] = React.useState<any>(0);
  const [totalData, setTotalData] = React.useState<any>(0);
  const [AllData, setAllData] = React.useState<any>("");
  const [targetTableID, setTargetTableID] = React.useState<any>("");
  const [selectedRow, setSelectedRow] = React.useState<any>(null);
  const [topVaultName, setTopVaultName] = React.useState<any>("");
  const [mappKeyValue, setMappKeyValue] = React.useState<any>({});
  const [headerList, setHeaderList] = React.useState<any>([]);
  const [isSyncing, setIsSyncing] = React.useState<any>(false);
  const [initialData, setInitialData] = React.useState<any>([]);
  const [targetTable, setTargetTable] = React.useState<any>([]);
  const [lastRow, setLastRow] = React.useState<any>([]);
  const [copiedRow, setCopiedRow] = React.useState<any>(false);
  // const [selectedOptions, setSelectedOptions] = React.useState<any>([]);
  let optionForImage: any = [];
  let UserAction = userAction && userAction?.action;
  const editPCBTableModal = useBaseParamsModal();

  const isUrl = (url: any) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };
  const handleOpenLinkReference = (reference: string) => {
    window.open(reference);
  };
  const getObjKeyName = () => {
    if (vault == 0) {
      return { top_vault: topVault };
    } else {
      return { vault: vault };
    }
  };

  React.useEffect(() => {
    API.get(
      `xcpep/bom_create_hirearchy/`,
      {
        top_vault: topVault,
      },
      0
    ).then((res: any) => {
      // console.log(res?.data?.data?.name);
      setTopVaultName(res?.data?.data?.name);
    });
  }, []);
  // React.useEffect(() => {
  //   // Scroll to the bottom when the modal content changes
  //   if (modalContentRef.current) {
  //     modalContentRef.current.scrollTop = modalContentRef.current.scrollHeight;
  //   }
  // }, [addClickCounter]);
  const getTableData = () => {
    setTableDataLoader(true);
    API.get(
      "/xcpep/bom_create_view/",
      {
        workstation: workstation,
        table: true,
        ...getObjKeyName(),
        drop_empty: true,
        table_param_id: idddd,
        page_no: currentPage,
      },
      0
    )
      .then((res: any) => {
      

        setTableDataLoader(false);
        setTableData(res.data);

        setTargetTableID(res.data?.[0]?.key);
        setTotalData(res.data?.[0]?.total_length);
        setTotalPageLength(res.data?.[0]?.page_length);
      })
      .catch((err: any) => {
        setTableDataLoader(false);
      });
  };

  const getHeaderData = () => {
    
    
    API.get(
      "/xcpep/bom_create_view/",
      {
        workstation: workstation,
        table: true,
        ...getObjKeyName(),
       

        table_param_id: idddd,
      },
      0
    )
      .then((res: any) => {
        setHeaderList(res.data);
        setLastRow(res?.data?.[0]?.data?.slice(-1));
      })
      .catch((err: any) => {
        setTableDataLoader(false);
      });
  };

  React.useEffect(() => {

    if (headerList?.length > 0) {

      
      
     
      
      
      
      let obj: any = {};
      headerList?.[0]?.header_item.map((item: any) => {
         //console.log(item?.paramter_name, "itm param name");
        
        
        switch (item?.data_type) {
          case "number":
          case "float":
          case "nonzero-positive-int":
          case "nonzero-positive-float":
          case "nonzero-float":
          case "percentage":
            case "positive-float":
          case "positive-int":
          case "integer":
          case "percentage":
            obj[item?.key] =  item?.paramter_name === "Quantity" ? "1" :"0";
            break;
          case "text":
          case "string":
          case "textarea":
          case "longtext":
          case "link":
          case "image":
            obj[item?.key] = "";
            break;
          case "boolean":
            obj[item?.key] = false;
            break;
          case "dropdown":
          case "singleselect":
          case "multiselect":
            obj[item?.key] = [];
            break;
        }
      });
      setInitialData(obj);
    }
  }, [headerList]);



  

  const getTableDataAddNewRow = () => {
    // setTableDataLoader(true);
    API.get(
      "/xcpep/bom_create_view/",
      {
        workstation: workstation,
        table: true,
        ...getObjKeyName(),
        drop_empty: true,
        table_param_id: idddd,
        page_no: currentPage,
      },
      0
    )
      .then((res: any) => {
        // setTableDataLoader(false);
        setTableData(res.data);
        //
        setTargetTableID(res.data?.[0]?.key);
        setTotalData(res.data?.[0]?.total_length);
        setTotalPageLength(res.data?.[0]?.page_length);
        handleEditClick("", key, 0, AllData, res?.data, "add");
      })
      .catch((err: any) => {
        setTableDataLoader(false);
      });
  };
  const getTableDataWOLoader = () => {
    API.get(
      "/xcpep/bom_create_view/",
      {
        workstation: workstation,
        table: true,
        ...getObjKeyName(),
        drop_empty: true,
        table_param_id: idddd,
        page_no: currentPage,
      },
      0
    )
      .then((res: any) => {
        setTableDataLoader(false);
        setTableData(res.data);
        // setLastRow(res?.data?.[0]?.data?.[0]);
        setAddClickCounter((prev: any) => prev + 1);
        setTargetTableID(res.data?.[0]?.key);
        setTotalPageLength(res.data?.[0]?.page_length);
        setTotalData(res.data?.[0]?.total_length);
      })
      .catch((err: any) => {
        setTableDataLoader(false);
      });
  };

  const getTableDataWOLoaderFDelete = () => {
    API.get(
      "/xcpep/bom_create_view/",
      {
        workstation: workstation,
        table: true,
        ...getObjKeyName(),
        drop_empty: true,
        table_param_id: idddd,
        page_no: currentPage,
      },
      0
    )
      .then((res: any) => {
        setTableDataLoader(false);
        setTableData(res.data);
        // setLastRow(res?.data?.[0]?.data?.[0]);
        setAddClickCounter((prev: any) => prev + 1);
        setTargetTableID(res.data?.[0]?.key);
        setTotalPageLength(res.data?.[0]?.page_length);
        setTotalData(res.data?.[0]?.total_length);
        ADMIN.toast.info("Deleted Successfully");
      })
      .catch((err: any) => {
        setTableDataLoader(false);
      });
  };
  const getTableDataWOLoaderFCopy = () => {
    API.get(
      "/xcpep/bom_create_view/",
      {
        workstation: workstation,
        table: true,
        ...getObjKeyName(),
        drop_empty: true,
        table_param_id: idddd,
        page_no: currentPage,
      },
      0
    )
      .then((res: any) => {
        setTableDataLoader(false);
        setTableData(res.data);
        setCopiedRow(true);
        // setLastRow(res?.data?.[0]?.data?.[0]);
        setAddClickCounter((prev: any) => prev + 1);
        setTargetTableID(res.data?.[0]?.key);
        setTotalPageLength(res.data?.[0]?.page_length);
        setTotalData(res.data?.[0]?.total_length);
        ADMIN.toast.info("Copied Successfully");
      })
      .catch((err: any) => {
        setTableDataLoader(false);
      });
  };
  const getData = (key: any, itmKey: any, headerOptions?: any) => {
    const headerItems =
      headerOptions ||
      tableData?.filter((itm: any) => itm.key === itmKey)[0]?.header_item;

    // return tableData
    //   ?.filter((itm: any) => itm.key === itmKey)[0]
    //   ?.header_item?.filter((i: any) => i.key === key);

    return headerItems?.filter((i: any) => i?.key === key);
  };

  const getAllData = (itmKey: any) => {
    return store?.is_compare?.allBOMData?.filter(
      (itm: any) => itm.key === itmKey
    )[0]?.data;
  };

  React.useEffect(() => {
    if (copiedRow) {
      setTimeout(() => {
        setCopiedRow(false);
      }, 3000);
    }
  }, [copiedRow]);

  React.useEffect(() => {
    if (counter > 0) {
      // console.log("counter", counter);
      getTableDataWOLoader();
      getHeaderData();
    }
  }, [counter]);

  const getTableDataAll = () => {
    BOMAllDataService.getAllTableDataService(
      {
        workstation: workstation,
        table: true,
        ...getObjKeyName(),
        table_param_id: idddd,
      },
      dispatch
    );
  };

  React.useEffect(() => {
    // console.log(tableData?.[0]?.header_item);
    // console.log(tableData?.[0]?.header_item)

    if (tableData?.[0]?.header_item) {
      let obj: any = {};
      tableData?.[0]?.header_item.map((item: any) => {
        obj[item?.paramter_name] = item?.key;
      });
      setMappKeyValue(obj);
    }
  }, [tableData]);

  console.log("tableData", tableData);
  console.log(tableIdddList, "tableIdddList");
  console.log(headerList, "headerList");
  console.log(targetTable?.data?.[targetTable?.data?.length-1]?.[mappKeyValue?.["Sl No"]], "targetTable", mappKeyValue);

  console.log(mappKeyValue["Sl No"]);
  
  
  
  
  
  
  // console.log(mappKeyValue, "mappKeyValue");

  const handleEditClick = (
    val: any,
    key: any,
    target: any,
    AllData: any,
    tabledata: any,
    type: any
  ) => {
    // console.log("target", target);
    // console.log(tabledata?.[0]?.total_length - (75 * (currentPage - 1) + target + 1));

    if (copyStatus == "false") {
      setSelectedRow(target);
      setKey(key);
      setItmm(val);
      setTarget(
        tabledata?.[0]?.total_length - (75 * (currentPage - 1) + target + 1)
      );
      setAllData(getAllData(targetTableID));
      setType(type);
      editPCBTableModal.open(tabledata);
    }
  };
  React.useEffect(() => {
    // getTableData();
    if (copiedRow == false) {
      getTableDataWOLoader();
    }
    // getTableDataWOLoader();
  }, [currentPage, copiedRow]);

  React.useLayoutEffect(() => {
    getTableData();
    getTableDataAll();
    getHeaderData();
  }, [vault, workstation]);
  const getTableRow = (
    key: any,
    count: any,
    itmKey: any,
    itmm: any,
    target: any,
    headerOptions: any,
    rowIndex?:any
  ) => {
    let data: any = getData(key, itmKey, headerOptions);

    let options = headerOptions?.filter((item: any) => item.key === key);
    optionForImage = options;
    // tableData
    //   ?.filter((itm: any) => itm.key === itmKey)[0]
    //   ?.header_item?.filter((i: any) => i.key === key);
    let AllData = getAllData(itmKey);
    

  
    const rIndex = typeof rowIndex === "number" ? rowIndex+1 : Number(rowIndex)+1
    
    
    
    


  
    


    switch (data[0]?.data_type) {
      case "number":
      case "float":
      case "nonzero-positive-int":
      case "Nonzero-positive-int":
      case "nonzero-positive-float":
      case "positive-float":
      case "nonzero-float":
      case "percentage":
      case "positive-int":
      case "table-calculator-result":
      case "integer":
      case "percentage":
        // console.log("keykeyykeyyy", key);
        if (key == mappKeyValue["Total Cost"]) {
          return;
        }
        return (
          // <NumberInputBoxTable
          //   key1={key && key}
          //   val={itmm[key]}
          //   setDataForUpdate={setDataForUpdate}
          //   target={target}
          //   AllData={AllData}
          //   setColorButton={setColorButton}
          //   setShowButton={setShowButton}
          // />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
           
            <div
              style={{
                width: "8rem",
                fontSize: "1rem",
                backgroundColor: "rgba(0,0,0,0.04)",
                padding: "0.3rem 0.3rem",
                border: "transparent",
                color: "#007fff",
                cursor: "pointer",
                lineHeight: "1rem",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                overflow: "hidden",
                // height: '1.4rem'
              }}
              title={itmm[key] ? itmm[key] : <div style={{ height: "1.4rem" }}>{idddd !== tableIdddList["PCB BOM Table"] ? (key == mappKeyValue["Sl No"] ? rowIndex+1 : key == mappKeyValue["Quantity"] ? 1 : 0 ) : 0 } </div>}
              onClick={() =>
                handleEditClick(
                  itmm[key],
                  key,
                  target,
                  AllData,
                  tableData,
                  "edit"
                )
              }
            >
              
              {itmm[key] ? itmm[key] :
              <div style={{ height: "1.4rem" }}>
                0
              {/* {idddd !== tableIdddList["PCB BOM Table"] ? (key == mappKeyValue["Sl No"] ? rIndex : 0 ) : 0 }  */}
 
            </div>
    }
    </div>
          </Box>
        );
      case "text":
      case "string":
        return (
          // <TextInputBoxTable
          //   key1={key && key}
          //   val={itmm[key]}
          //   setDataForUpdate={setDataForUpdate}
          //   target={target}
          //   AllData={AllData}
          //   setColorButton={setColorButton}
          //   setShowButton={setShowButton}
          // />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                width: "12rem",
                fontSize: "1rem",
                backgroundColor: "rgba(0,0,0,0.04)",
                padding: "0.3rem 0.3rem",
                border: "transparent",
                color: "#007fff",
                cursor: "pointer",
                overflowWrap: "break-word",
                lineHeight: "1.4rem",
                // height: '1rem'
              }}
              onClick={() =>
                handleEditClick(
                  itmm[key],
                  key,
                  target,
                  AllData,
                  tableData,
                  "edit"
                )
              }
            >
              {isUrl(itmm[key]) ? (
                <span
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={() => handleOpenLinkReference(itmm[key])}
                >
                  Link
                </span>
              ) : (
                <span style={{ cursor: "pointer" }}>
                  {itmm[key] || <div style={{ height: "1.4rem" }}>
                    
                  {/* {(idddd !== tableIdddList["PCB BOM Table"] && key == mappKeyValue["Name"]) ? "--" : "" }  */}
                    </div>}
                </span>
              )}
            </div>
          </Box>
        );

      case "link":
        return (
          // <LinkInputBox
          //   key1={key && key}
          //   val={itmm[key]}
          //   setDataForUpdate={setDataForUpdate}
          //   target={target}
          //   AllData={AllData}
          //   setColorButton={setColorButton}
          //   setShowButton={setShowButton}
          // />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                width: "12rem",
                fontSize: "1rem",
                backgroundColor: "rgba(0,0,0,0.04)",
                padding: "0.3rem 0.3rem",
                border: "transparent",
                color: "#007fff",
                cursor: "pointer",
                overflowWrap: "break-word",
                lineHeight: "1rem",
                // height: '1.4rem'
              }}
              onClick={() =>
                handleEditClick(
                  itmm[key],
                  key,
                  target,
                  AllData,
                  tableData,
                  "edit"
                )
              }
            >
              {isUrl(itmm[key]) ? (
                <span
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                  onClick={() => handleOpenLinkReference(itmm[key])}
                >
                  Link
                </span>
              ) : (
                <span
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                >
                  {"-"}
                </span>
              )}
            </div>
          </Box>
        );

      case "textarea":
      case "longtext":
        return (
          // <DescriptionInputBoxTable
          //   itmm={itmm}
          //   key1={key && key}
          //   val={itmm[key]}
          //   setDataForUpdate={setDataForUpdate}
          //   target={target}
          //   AllData={AllData}
          //   setColorButton={setColorButton}
          //   setShowButton={setShowButton}
          // />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                width: "12rem",
                fontSize: "1rem",
                backgroundColor: "rgba(0,0,0,0.04)",
                padding: "0.3rem 0.3rem",
                border: "transparent",
                color: "#007fff",
                cursor: "pointer",
                overflowWrap: "break-word",
                lineHeight: "1rem",
                // height: '1.4rem'
              }}
              onClick={() =>
                handleEditClick(
                  itmm[key],
                  key,
                  target,
                  AllData,
                  tableData,
                  "edit"
                )
              }
            >
              {itmm[key] || <div style={{ height: "1.4rem" }}></div>}
            </div>
          </Box>
        );

      case "dropdown":
      case "singleselect":
        return (
          // <SingleSelectInputBoxTable
          //   key1={key}
          //   val={itmm[key]}
          //   data={data}
          //   count={count}
          //   setDataForUpdate={setDataForUpdate}
          //   target={target}
          //   AllData={AllData}
          //   allOptions={options && options[0]?.allowed_values}
          //   setColorButton={setColorButton}
          //   setShowButton={setShowButton}
          // />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                width: "8rem",
                fontSize: "1rem",
                backgroundColor: "rgba(0,0,0,0.04)",
                padding: "0.3rem 0.3rem",
                border: "transparent",
                color: "#007fff",
                cursor: "pointer",
                // height: '1.4rem'
              }}
              onClick={() =>
                handleEditClick(
                  itmm[key],
                  key,
                  target,
                  AllData,
                  tableData,
                  "edit"
                )
              }
            >
              {itmm[key] || <div style={{ height: "1.4rem" }}></div>}
            </div>
          </Box>
        );

      case "image":
        return (
          <ImageInputViewBox
            key1={key}
            val={itmm[key]}
            setDataForUpdate={setDataForUpdate}
            target={totalData - (75 * (currentPage - 1) + target + 1)}
            AllData={AllData}
            allOptions={options && options[0]?.allowed_values}
            option={options}
            itmKey={itmKey}
            setColorButton={setColorButton}
            setShowButton={setShowButton}
            setCounter={setCounter}
          />
        );

      case "boolean":
        if (key == mappKeyValue["is_validated"]) {
          return;
        }
        return (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                width: "8rem",
                fontSize: "1rem",
                backgroundColor: "rgba(0,0,0,0.04)",
                padding: "0.3rem 0.3rem",
                border: "transparent",
                color: "#007fff",
                cursor: "pointer",
                // height: '1.4rem'
              }}
              onClick={() =>
                handleEditClick(
                  itmm[key],
                  key,
                  target,
                  AllData,
                  tableData,
                  "edit"
                )
              }
            >
              {itmm[key] == true ? "Yes" : "No"}
            </div>
          </Box>
        );

      case "multiselect":
        return (
          // <MultiSelectInputBoxTable
          //   key1={key}
          //   val={itmm && itmm[key]}
          //   setDataForUpdate={setDataForUpdate}
          //   target={target}
          //   AllData={AllData && AllData}
          //   allOptions={options && options[0]?.allowed_values}
          //   setColorButton={setColorButton}
          //   setShowButton={setShowButton}
          //   itemHeader={tableData && tableData?.[0]?.header_item}
          // />
          <Box
            sx={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <div
              style={{
                width: "8rem",
                fontSize: "1rem",
                backgroundColor: "rgba(0,0,0,0.04)",
                padding: "0.3rem 0.3rem",
                border: "transparent",
                color: "#007fff",
                cursor: "pointer",
                // height: '1.4rem'
              }}
              onClick={() =>
                handleEditClick(
                  itmm[key],
                  key,
                  target,
                  AllData,
                  tableData,
                  "edit"
                )
              }
            >
              {Array.isArray(itmm[key]) ? (
                itmm[key].join(", ")
              ) : (
                <div style={{ height: "1.4rem" }}></div>
              )}
            </div>
          </Box>
        );

      default:
        return <></>;
    }
  };
  const handleRedirection = (tableID: any, index: any) => {
    API.get(
      "/xcpep/pcbcosting_redirection/",
      {
        table_id: tableID,
        index: index,
        ...getObjKeyName(),
      },

      0
    )
      .then((res: any) => {
        const url = `/#/createcostingpart/${projectId}/${projectName}/${topVaultName}/${topVault}/${vault}/none/panel1b/${res?.data?.bought_out}/${res?.data?.estimation}/0/0/2/0/0/0/true`;
        window.open(url, "_blank");
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const handleSync = () => {
    setIsSyncing(true);
    API.get(
      `/xcpep/bom_pcb_table_sync/`,
      {
        table_id: idddd,
        ...getObjKeyName(),
      },
      0
    )
      .then((res: any) => {
        setIsSyncing(false);
        ADMIN.toast.success("Synced Successfully");
        getTableDataWOLoader();
      })
      .catch((err: any) => {
        ADMIN.toast.error("Sync Failed");
      });
  };

  const sumationHandler = () => {
    API.get(
      "/xcpep/table_param_sum/",
      {
        vault: vault,
        parameter: idddd,
      },
      0
    )
      .then((res: any) => {
        setHeaderCount(res.data);
      })
      .catch((err: any) => {});
  };




  


 

  return (
    <div>
      {editPCBTableModal.isOpen && (
        <EditModalForm
          onCloseModal={editPCBTableModal.close}
          isOpen={editPCBTableModal.open}
          tableData={type == "add" ? editPCBTableModal.propsId : tableData}
          key1={key && key}
          val={itmm[key]}
          type={type}
          setDataForUpdate={setDataForUpdate}
          target={target}
          AllData={AllData}
          setColorButton={setColorButton}
          setCounter={setCounter}
          options={optionForImage}
          itmKey={targetTableID}
          currentPage={currentPage}
          counter={counter}
          mappKeyValue={mappKeyValue}
          tableIdddList={tableIdddList}
          setSelectedOptions={setSelectedOptions}
          initialData={initialData}
          setTableDataCount={setTableDataCount}
          lastRow={lastRow}
          setShowButton={setShowButton}
        />
      )}
      {!tableDataLoader && tableData ? (
        tableData?.map((item: any, index: number) => {
          const sortedHeaderItem = [...item.header_item]?.sort((a, b) => a?.sequence - b?.sequence);

//       const reorderedData = item?.data?.map((dataItem: any) => {
        
        
//         // Create an array to hold the reordered key-value pairs
//         const reorderedDataItem: any[] = [];
  
//         // Iterate through the sorted header_item to maintain the desired order
//         sortedHeaderItem?.forEach((header: any) => {
//           const key = header?.key;
  
//           // Check if the dataItem has the key
//           if (dataItem?.hasOwnProperty(key)) {
//             // Push an array of [key, value] to preserve order
//             reorderedDataItem.push([key, dataItem[key]]);
//           }
//         });

        

//         // Object.fromEntries(reorderedDataItem);

        
        
  
//         // Convert the array of entries back into an object
//         return reorderedDataItem
//       });
      
//       // const convertedObjects = reorderedData && reorderedData?.map(innerArray =>
//       //   innerArray?.reduce((acc: any, [key, value]: any) => {
//       //     acc[key] = value;
//       //     return acc;
//       //   }, {})
//       // );
// console.log(sortedHeaderItem,"sortedHeaderItem");
// console.log(reorderedData,"reorderedData");

// // const convertedObjects = reorderedData && reorderedData?.[0]?.map(([key, value]:any) => ({ [key]: value }));
// // Convert each array of key-value pairs into an object
// const convertedObjects = reorderedData?.map((innerArray:any) =>
//   innerArray?.reduce((acc:any, [key, value]:any) => {
//     acc[key] = value;
//     return acc;
//   }, {})
// );


// console.log(convertedObjects, "convertedObjects");




          return (
            <>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  columnGap: "0.5rem",
                  width: "100%",
                  paddingRight: "0.5rem",
                  flexGrow: 1,
                }}
              >
                <Typography sx={{ fontSize: "12px", paddingLeft: "0.5rem" }}>
                  {item?.table_name} ({item?.data?.length})
                </Typography>
                {idddd == tableIdddList["PCB BOM Table"] && (
                  <LoadingButton
                    variant="contained"
                    loading={isSyncing}
                    onClick={() => handleSync()}
                  >
                    <SyncIcon />
                    Sync
                  </LoadingButton>
                )}
                <Box
                  sx={{
                    width: "70%",
                    display: "flex",
                    justifyContent: "end",
                    alignItems: "center",
                    gap: "0.5rem",
                  }}
                >
                  <IconButton
                    title="Prev"
                    disabled={currentPage == 1}
                    onClick={() => setCurrentPage(currentPage - 1)}
                  >
                    <ArrowCircleLeftIcon
                      sx={{ color: currentPage == 1 ? "#00dfff" : "#007fff" }}
                    />
                  </IconButton>
                  <Box
                    sx={{
                      border: "1px solid #00dfff",
                      borderRadius: "50%",
                      padding: "0.1rem 0.5rem",
                    }}
                  >
                    {currentPage}
                  </Box>
                  <IconButton
                    title="Next"
                    disabled={currentPage == totalPageLength}
                    onClick={() => setCurrentPage(currentPage + 1)}
                  >
                    <ArrowCircleRightIcon
                      sx={{
                        color:
                          currentPage == totalPageLength
                            ? "#00dfff"
                            : "#007fff",
                      }}
                    />
                  </IconButton>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",

                    justifyContent: "end",
                    columnGap: "1rem",
                  }}
                >
                  <FunctionsIcon
                    sx={{ cursor: "pointer" }}
                    titleAccess="Add all"
                    onClick={() => sumationHandler()}
                  />
                  <TableOptionsInput
                 setTargetTable={setTargetTable}
                    expandStatus={expandStatus}
                    tableName={item?.table_name}
                    item={item?.key}
                    getTableData={getTableDataWOLoader}
                    dataForUpdate={dataForUpdate}
                    tableData={tableData}
                    setTableData={setTableData}
                    dataForCheck={item?.header_item}
                    setSelectedOptionsTable={setSelectedOptions}
                    selectedOptions={selectedOptions}
                    setColorButton={setColorButton}
                    colorButton={colorButton}
                    setSelectedTableId={setSelectedTableId}
                    selectedTableId={selectedTableId}
                    getTableDataAll={getTableDataAll}
                    tableDataLoader={tableDataLoader}
                    setShowButton={setShowButton}
                    showButton={showButton}
                    BOMValidationStatus={BOMValidationStatus}
                    handleEditClick={handleEditClick}
                    getTableDataAddNewRow={getTableDataAddNewRow}
                    mappKeyValue={mappKeyValue}
                    counter={counter}
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  width: "100%",
                  marginTop: "0.5rem",
                  maxHeight: { lg: "80vh", xl: "81vh" },
                }}
                ref={modalContentRef}
                adl-scrollbar="true"
                adl-scrollbar-width="0.3"
              >
                <Table stickyHeader aria-label="sticky table">
                  {idddd == tableIdddList["PCB BOM Table"] && (
                    <TableHead sx={{ backgroundColor: "primary.light" }}>
                      <TableRow>
                        <TableCell
                          sx={{
                            width: "8rem",
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                            backgroundColor: "primary.light",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Actions
                        </TableCell>
                        <TableCell
                          sx={{
                            width: "8rem",
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                            backgroundColor: "primary.light",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Sl No.
                        </TableCell>
                        <TableCell
                          sx={{
                            width: "8rem",
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                            backgroundColor: "primary.light",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Item Name
                          <sup style={{ color: "red", fontSize: "0.8rem" }}>
                            *
                          </sup>
                        </TableCell>
                        <TableCell
                          sx={{
                            width: "8rem",
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                            backgroundColor: "primary.light",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Item Description
                          <sup style={{ color: "red", fontSize: "0.8rem" }}>
                            *
                          </sup>
                        </TableCell>
                        <TableCell
                          sx={{
                            width: "8rem",
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                            backgroundColor: "primary.light",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Component Type
                          <sup style={{ color: "red", fontSize: "0.8rem" }}>
                            *
                          </sup>
                        </TableCell>
                        <TableCell
                          sx={{
                            width: "8rem",
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                            backgroundColor: "primary.light",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Length
                          <sup style={{ color: "red", fontSize: "0.8rem" }}>
                            *
                          </sup>
                        </TableCell>
                        <TableCell
                          sx={{
                            width: "8rem",
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                            backgroundColor: "primary.light",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Width
                          <sup style={{ color: "red", fontSize: "0.8rem" }}>
                            *
                          </sup>
                        </TableCell>
                        <TableCell
                          sx={{
                            width: "8rem",
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                            backgroundColor: "primary.light",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Unit Cost
                          <sup style={{ color: "red", fontSize: "0.8rem" }}>
                            *
                          </sup>
                        </TableCell>
                        <TableCell
                          sx={{
                            width: "8rem",
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                            backgroundColor: "primary.light",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Reference
                          <sup style={{ color: "red", fontSize: "0.8rem" }}>
                            *
                          </sup>
                        </TableCell>
                        {Array.isArray(item?.data) &&
                          item?.data?.length > 0 &&
                          Object.keys(item?.data[0])?.map((ii: any) => {
                            //  item?.header_item?.filter((i:any)=>i.key===i)[0]
                            // console.log(mappKeyValue['Sl No.'])
                            if (
                              ii == mappKeyValue["is_validated"] ||
                              ii == mappKeyValue["Total Cost"] ||
                              ii == mappKeyValue["Sl No."] ||
                              ii == mappKeyValue["Length"] ||
                              ii == mappKeyValue["Width"] ||
                              ii == mappKeyValue["Unit Cost"] ||
                              ii == mappKeyValue["Reference"] ||
                              ii == mappKeyValue["Item Name"] ||
                              ii == mappKeyValue["Item Description"] ||
                              ii == mappKeyValue["Component Type"]
                            ) {
                              return;
                            }
                            return (
                              <TableCell
                                sx={{
                                  // width:header?.paramter_name =='Sl No.'? '4rem':'100%',
                                  padding: "0.2rem 0.5rem",
                                  borderBottomColor: "primary.light",
                                  backgroundColor: "primary.light",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {item?.header_item?.filter(
                                  (i: any) => i.key === ii
                                )[0] &&
                                (ii == mappKeyValue["Length"] ||
                                  ii == mappKeyValue["Width"] ||
                                  ii == mappKeyValue["Unit Cost"] ||
                                  ii == mappKeyValue["Reference"] ||
                                  ii == mappKeyValue["Item Name"] ||
                                  ii == mappKeyValue["Item Description"] ||
                                  ii == mappKeyValue["Component Type"]) ? (
                                  <>
                                    {
                                      item?.header_item?.filter(
                                        (i: any) => i.key === ii
                                      )[0]?.paramter_name
                                    }
                                    <sup
                                      style={{
                                        color: "red",
                                        fontSize: "0.8rem",
                                      }}
                                    >
                                      *
                                    </sup>
                                  </>
                                ) : (
                                  item?.header_item?.filter(
                                    (i: any) => i.key === ii
                                  )[0]?.paramter_name
                                )}
                                &nbsp;
                                {headerCount[
                                  item?.header_item?.filter(
                                    (i: any) => i.key === ii
                                  )[0]?.paramter_name
                                ] &&
                                  `(${
                                    headerCount[
                                      item?.header_item?.filter(
                                        (i: any) => i.key === ii
                                      )[0]?.paramter_name
                                    ]
                                  })`}
                                &nbsp;
                                {/* {item?.header_item?.filter(
                                (i: any) => i.key === ii
                              )[0] &&
                                item?.header_item?.filter(
                                  (i: any) => i.key === ii
                                )[0]?.unit
                                ? `(${item?.header_item?.filter(
                                  (i: any) => i.key === ii
                                )[0]?.unit
                                })`
                                : "-"} */}
                              </TableCell>
                            );
                          })}
                      </TableRow>
                    </TableHead>
                  )}
                  {idddd != tableIdddList["PCB BOM Table"] && (
                    <TableHead sx={{ backgroundColor: "primary.light" }}>
                      <TableRow>
                        <TableCell
                          sx={{
                            width: "8rem",
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                            backgroundColor: "primary.light",
                            whiteSpace: "nowrap",
                          }}
                        >
                          Actions
                        </TableCell>
                        {Array.isArray(item?.data) &&
                          item?.data?.length > 0 &&
                          Object.keys(item?.data[0])?.map((ii: any) => {
                            //  item?.header_item?.filter((i:any)=>i.key===i)[0]
                            // console.log(mappKeyValue['Sl No.'])

                            return (
                              <TableCell
                                sx={{
                                  // width:header?.paramter_name =='Sl No.'? '4rem':'100%',
                                  padding: "0.2rem 0.5rem",
                                  borderBottomColor: "primary.light",
                                  backgroundColor: "primary.light",
                                  whiteSpace: "nowrap",
                                }}
                              >
                                {item?.header_item?.filter(
                                  (i: any) => i.key === ii
                                )[0] &&
                                (ii == mappKeyValue["Length"] ||
                                  ii == mappKeyValue["Width"] ||
                                  ii == mappKeyValue["Unit Cost"] ||
                                  ii == mappKeyValue["Reference"] ||
                                  ii == mappKeyValue["Item Name"] ||
                                  ii == mappKeyValue["Item Description"] ||
                                  ii == mappKeyValue["Component Type"]) ? (
                                  <>
                                    {
                                      item?.header_item?.filter(
                                        (i: any) => i.key === ii
                                      )[0]?.paramter_name
                                    }
                                    <sup
                                      style={{
                                        color: "red",
                                        fontSize: "0.8rem",
                                      }}
                                    >
                                      *
                                    </sup>
                                  </>
                                ) : (
                                  item?.header_item?.filter(
                                    (i: any) => i.key === ii
                                  )[0]?.paramter_name
                                )}
                                &nbsp;
                                {headerCount[
                                  item?.header_item?.filter(
                                    (i: any) => i.key === ii
                                  )[0]?.paramter_name
                                ] &&
                                  `(${
                                    headerCount[
                                      item?.header_item?.filter(
                                        (i: any) => i.key === ii
                                      )[0]?.paramter_name
                                    ]
                                  })`}
                                &nbsp;
                                {item?.header_item?.filter(
                                (i: any) => i.key === ii
                              )[0] &&
                                item?.header_item?.filter(
                                  (i: any) => i.key === ii
                                )[0]?.unit
                                ? `(${item?.header_item?.filter(
                                  (i: any) => i.key === ii
                                )[0]?.unit
                                })`
                                : ""}
                              </TableCell>
                            );
                          })}
                      </TableRow>
                    </TableHead>
                  )}

                  <TableBody>
                    {item?.data?.map((itm: any, ind: any) => {
                
               const rowIndex = (item?.data?.length > 0 && mappKeyValue["Sl No"]) ? item?.data?.[1]?.[mappKeyValue["Sl No"]] : 1;
                
                
                      return (
                        <TableRow
                          sx={{
                            backgroundColor:
                              (selectedRow && selectedRow === ind) ||
                              (ind == 0 && copiedRow == true)
                                ? "#c3e0ea"
                                : "",
                          }}
                        >
                         
                          <TableCell
                            sx={{
                              width: "6rem",
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                              display: "flex",
                              justifyContent: "start",
                              alignItems: "center",
                            }}
                          >
                            <IconButton
                              sx={{
                                // padding: "0.3rem",
                                lineHeight: 1,

                                "&:hover": {
                                  transform: "Scale(1.05)",
                                  transition: "transform 0.5s ease",
                                },
                                ".MuiCircularProgress-root": {
                                  color: "primary.main",
                                },
                              }}
                              disabled={totalData == 0 || copyStatus == "true"}
                              // loading={
                              //   ind == selectedIdDelete ? isDeleting : false
                              // }
                            >
                              <DeleteIcon
                                titleAccess="Delete"
                                sx={{
                                  cursor: "pointer",
                                  color: "primary.main",
                                }}
                                onClick={() => {
                                  if (
                                    UserAction?.includes("D") &&
                                    copyStatus == "false"
                                  ) {
                                    setSelectedRow(ind);
                                    API.get(
                                      "/xcpep/bom_create_view/",
                                      {
                                        workstation: workstation,
                                        table: true,
                                        ...getObjKeyName(),
                                      },
                                      0
                                    ).then((res: any) => {
                                      let data: any = res.data?.filter(
                                        (itm1: any) => itm1.key === item.key
                                      )[0]?.data;
                                      let filteredData = data.filter(
                                        (itm1: any, indd: any) => indd !== ind
                                      );
                                      Swal.fire({
                                        title: "Are you sure?",
                                        text: "You will not be able to recover this data!",
                                        icon: "warning",
                                        showCancelButton: true,
                                        confirmButtonText: "Yes, delete it!",
                                        cancelButtonText: "No, keep it",
                                        customClass: {
                                          container: "swal2Container",
                                        },
                                      }).then((result) => {
                                        if (result.isConfirmed) {
                                          setIsDeleting(true);
                                          setSelectedIdDelete(ind);
                                          API.put(
                                            "/xcpep/component_details/74699/",
                                            {
                                              table_id: item?.key,
                                              index:
                                                totalData -
                                                (75 * (currentPage - 1) +
                                                  ind +
                                                  1),
                                            },
                                            {
                                              ...getObjKeyName(),
                                            },
                                            0
                                          )
                                            .then((res: any) => {
                                              setIsDeleting(false);
                                              setSelectedRow(null);
                                              setSelectedIdDelete("");
                                              setColorButton("");
                                              setShowButton(false);
                                              getTableDataWOLoaderFDelete();
                                              getTableDataAll();
                                            })
                                            .catch((err: any) => {
                                              setIsDeleting(false);
                                            });
                                        } else if (
                                          result.dismiss ===
                                          Swal.DismissReason.cancel
                                        ) {
                                          Swal.fire({
                                            title: "Cancelled",
                                            text: "Your data is safe :)",
                                            icon: "error",
                                            customClass: {
                                              container: "swal2Container",
                                            },
                                          });
                                        }
                                      });
                                    });
                                  }
                                }}
                              />
                            </IconButton>
                            {idddd != tableIdddList["PCB BOM Table"] && (
                              <ContentCopyIcon
                                titleAccess="Copy Row"
                                sx={{
                                  cursor: "pointer",
                                  color: "primary.main",
                                }}
                                onClick={() => {
                                  if (UserAction?.includes("C")) {
                                    setSelectedRow(ind);

                                    API.put(
                                      "/xcpep/component_details/74699/",
                                      {
                                        table_id: item?.key,
                                        index:
                                          totalData -
                                          (75 * (currentPage - 1) + ind + 1),
                                        copy: true,
                                      },
                                      {
                                        ...getObjKeyName(),
                                      },
                                      0
                                    )
                                      .then((res: any) => {
                                        setSelectedRow(null);
                                        getTableDataWOLoaderFCopy();
                                        getTableDataAll();
                                      })
                                      .catch((err: any) => {});
                                  }
                                }}
                              />
                            )}
                            {idddd == tableIdddList["PCB BOM Table"] && (
                              <LaunchIcon
                                titleAccess={itm?.[mappKeyValue["Total Cost"]]}
                                sx={{
                                  cursor: "pointer",
                                  lineHeight: 1,
                                  color:
                                    itm?.[mappKeyValue["is_validated"]] == true
                                      ? "green"
                                      : "red",
                                  "&:hover": {
                                    transform: "Scale(1.05)",
                                    transition: "transform 0.5s ease",
                                  },
                                  ".MuiCircularProgress-root": {
                                    color: "primary.main",
                                  },
                                }}
                                onClick={() =>
                                  handleRedirection(
                                    item?.key,
                                    totalData -
                                      (75 * (currentPage - 1) + ind + 1)
                                  )
                                }
                              />
                            )}
                          </TableCell>
                          {idddd == tableIdddList["PCB BOM Table"] && (
                            <TableCell
                              sx={{
                                width: "8rem",
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              {getTableRow(
                                mappKeyValue["Sl No."] &&
                                  mappKeyValue["Sl No."],
                                1,
                                item?.key,
                                itm,
                                ind,
                                item?.header_item,
                                rowIndex
                                
                              )}
                            </TableCell>
                          )}
                          {idddd == tableIdddList["PCB BOM Table"] && (
                            <TableCell
                              sx={{
                                width: "8rem",
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              {getTableRow(
                                mappKeyValue["Item Name"] &&
                                  mappKeyValue["Item Name"],
                                2,
                                item?.key,
                                itm,
                                ind,
                                item?.header_item,
                                rowIndex
                              )}
                            </TableCell>
                          )}
                          {idddd == tableIdddList["PCB BOM Table"] && (
                            <TableCell
                              sx={{
                                width: "8rem",
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              {getTableRow(
                                mappKeyValue["Item Description"] &&
                                  mappKeyValue["Item Description"],
                                3,
                                item?.key,
                                itm,
                                ind,
                                item?.header_item,
                                rowIndex
                              )}
                            </TableCell>
                          )}
                          {idddd == tableIdddList["PCB BOM Table"] && (
                            <TableCell
                              sx={{
                                width: "8rem",
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              {getTableRow(
                                mappKeyValue["Component Type"] &&
                                  mappKeyValue["Component Type"],
                                4,
                                item?.key,
                                itm,
                                ind,
                                item?.header_item,
                                rowIndex
                              )}
                            </TableCell>
                          )}
                          {idddd == tableIdddList["PCB BOM Table"] && (
                            <TableCell
                              sx={{
                                width: "8rem",
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              {getTableRow(
                                mappKeyValue["Length"] &&
                                  mappKeyValue["Length"],
                                5,
                                item?.key,
                                itm,
                                ind,
                                item?.header_item,
                                rowIndex
                              )}
                            </TableCell>
                          )}
                          {idddd == tableIdddList["PCB BOM Table"] && (
                            <TableCell
                              sx={{
                                width: "8rem",
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              {getTableRow(
                                mappKeyValue["Width"] && mappKeyValue["Width"],
                                6,
                                item?.key,
                                itm,
                                ind,
                                item?.header_item,
                                rowIndex
                              )}
                            </TableCell>
                          )}
                          {idddd == tableIdddList["PCB BOM Table"] && (
                            <TableCell
                              sx={{
                                width: "8rem",
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              {getTableRow(
                                mappKeyValue["Unit Cost"] &&
                                  mappKeyValue["Unit Cost"],
                                7,
                                item?.key,
                                itm,
                                ind,
                                item?.header_item,
                                rowIndex
                              )}
                            </TableCell>
                          )}
                          {idddd == tableIdddList["PCB BOM Table"] && (
                            <TableCell
                              sx={{
                                width: "8rem",
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              {getTableRow(
                                mappKeyValue["Reference"] &&
                                  mappKeyValue["Reference"],
                                8,
                                item?.key,
                                itm,
                                ind,
                                item?.header_item,
                                rowIndex
                              )}
                            </TableCell>
                          )}
                          {Object.keys(itm).map((key: any, count: any) => {
                            if (
                              key == mappKeyValue["is_validated"] ||
                              key == mappKeyValue["Total Cost"] ||
                              key == mappKeyValue["Sl No."] ||
                              key == mappKeyValue["Length"] ||
                              key == mappKeyValue["Width"] ||
                              key == mappKeyValue["Unit Cost"] ||
                              key == mappKeyValue["Reference"] ||
                              key == mappKeyValue["Item Name"] ||
                              key == mappKeyValue["Item Description"] ||
                              key == mappKeyValue["Component Type"]
                            ) {
                              return;
                            }
                            return (
                              <TableCell
                                sx={{
                                  padding: "0.2rem 0.5rem",
                                  borderBottomColor: "primary.light",
                                }}
                              >
                               
                                {getTableRow(
                                  key && key,
                                  count + 9,
                                  item?.key,
                                  itm,
                                  ind,
                                  item?.header_item,
                                  rowIndex
                                )}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                        // :<Skeleton animation="wave"  width={1000} />
                      );
                    })}
                  </TableBody>
                </Table>



                
              </Box>
            </>
          );
        })
      ) : (
        <Box sx={{ width: "100%", padding: "0.5rem", marginTop: "2rem" }}>
          <Skeleton
            animation="wave"
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            animation="wave"
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
        </Box>
      )}

    </div>
  );
}
