import React, { useEffect, useRef, useState } from 'react';
import styles from './GanttMailLandingPage.module.scss';
import { useHistory, useParams } from 'react-router-dom';
import { Autocomplete, Box, Button, Chip, IconButton, Skeleton, TextField, Typography } from '@mui/material';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { API } from '../../../api-services';
import sendIcon from "../../../Assets/images/sendOutline.png";
import useWindowDimensions from '../../../ui-reusable-component/useWindowDimensions';
import ReactDOMServer from 'react-dom/server';
import { useSelector } from 'react-redux';
import { userSelectors } from '../../../Redux/Reducers/user.reducer';
import JoditEditor from 'jodit-react';
import { useBaseModal } from '../../SearchPage/useBaseModal';
import { PreviewMailModal } from '../../ProjectMailModule/PreviewMailModal';
import AttachFileIcon from "@mui/icons-material/AttachFile";
import MailAttchmentS3Upload from '../../ProjectMailModule/MailAttchmentS3Upload';
import ErrorModalFilesMail from '../../ProjectMailModule/ErrorModalFilesMail';
import { MailSucessModal } from '../../ProjectMailModule/MailSuccessModal';
import './GanttMailEditor.scss';


const editorConfig = {

  readonly: false,
  toolbar: true,
  // @ts-ignore
  spellcheck: true,
  saveSelectionOnFocus: true,
  saveSelectionOnBlur: true,
  language: "en",
  // toolbarButtonSize: 'medium',
  // toolbarAdaptive: false,
  showCharsCounter: true,
  showWordsCounter: true,
  showXPathInStatusbar: false,
  askBeforePasteHTML: true,
  askBeforePasteFromWord: true,

  //defaultActionOnPaste: "insert_clear_html",
  // buttons: buttons,
  uploader: {
    insertImageAsBase64URI: true,
  },

  controls: {
    font: {
      command: "fontname",
      list: {
        "'Open Sans',sans-serif": "Open Sans",
        "Helvetica,sans-serif": "Helvetica",
        "Arial,Helvetica,sans-serif": "Arial",
        "Georgia,serif": "Georgia",
        "Impact,Charcoal,sans-serif": "Impact",
        "Tahoma,Geneva,sans-serif": "Tahoma",
        "'Times New Roman',Times,serif": "Times New Roman",
        "Verdana,Geneva,sans-serif": "Verdana",
        "Consolas,monaco,monospace": "Consolas",
      },
    },
  },

}

interface GanttMailLandingPage{}

const GanttMailLandingPage:React.FC<GanttMailLandingPage>=(props)=>{
    const [projectDetails,setProjectDetails] = React.useState<any>();
    const { projectId } = useParams<{ projectId: string; }>();
    const [value, setValue] = React.useState([]);
    const [UserManualEamil, setUserManualEamil] = useState<any>();
    const [userList, setUserList] = useState<any>([]);
    const [usersCC, setUsersCC] = React.useState<any>([]);
    const [SubjectData, setSubjectData] = useState<any>("");
    const [content, setContent] = useState("");
    const [AttachmentDataList, setAttachmentDataList] = useState<any>([]);
    const [AttachmentDataLoader, setAttachmentDataLoader] = useState<boolean>(true);
    const [ImageUploadCounter, setImageUploadCounter] = useState<any>(0);
    const [ErrorModalFileMsg, setErrorModalFileMsg] = useState<any>();
    const [SendEmail, setSendEmail] = useState<any>(false)

    const InputRef = useRef<any>();
    const PreviewModal = useBaseModal();
    let ErrorModalFiles = useBaseModal();
    const successModal = useBaseModal()
    const editor = useRef(null);
    const history = useHistory();
    const { height, width } = useWindowDimensions();

    const userProfilePic = useSelector(userSelectors.selectAll);

    useEffect(()=>{
        API.get("/xcpep/project/", {
            id: projectId,
          },
            0).then((res: any) => {
                setProjectDetails(res?.data);
                setSubjectData(`${res?.data?.[0]?.name} Progress Update: Gantt Chart and Milestone Overview`);
            });
    },[projectId]);

    useEffect(() => {
        API.get("/analytics/user_access_list/", { project_id: projectId }).then(
          (res: any) => {
            setUserList(res?.data);
          }
        );
      }, [projectId]);

    useEffect(()=>{
        setAttachmentDataLoader(true)
        API.get("analytics/store_attachment_details", {
            project: projectId,
            mail_type: 2
          },
            0).then((res: any) => {
                setAttachmentDataLoader(false)
                setAttachmentDataList(res?.data)
            });
    },[projectId,ImageUploadCounter])

    const handleSelectChange = (touser: any) => {
        let ToIds =
          touser &&
          touser?.map((item: any, index: any) => {
            return item?.id;
          });
    
    
        // @ts-ignore
        setValue([...touser]);
      };

      const FinalEmailSendHandler = () => {
        let ToIds = value && value?.map((item: any, index: any) => { return item?.email });
        let CCuser = usersCC && usersCC?.map((item: any, index: any) => { return item?.email });
    
        let attachedFile = AttachmentDataList && AttachmentDataList?.map((item: any, index: any) => { return item?.file_path })
        setSendEmail(true);
        successModal.open()

      API.post("/xcpep/email_send_function/",
      {
        to_mail: ToIds,
        cc_mail: CCuser,
        data: content,
        project: projectId,
        subject: SubjectData,
        attachment: attachedFile,
        mail_type: 2
      },
      {},
      0
    ).then((res: any) => {
      setSubjectData("")
      setAttachmentDataList([])
      setSendEmail(false)
    }).catch((err: any) => {
      setSendEmail(false)
      setErrorModalFileMsg('Mail not sent. Please recheck!');
      PreviewModal.close();
      ErrorModalFiles.open();
    })

      }
    
  const PartNameHandler = (item: any) => {
    setUserManualEamil(item)
  }

  const NormalUser = (userData: any, domain: any) => {
    if (domain == "advancedstructures.in") {
      var newdata = { email: userData, full_name: "", user_color: "#a6f0b9" };
       var emailfilter= userList && userList?.filter((item:any,index:any)=>{return item?.email!==userData})

      //  console.log(emailfilter,"emailfilteremailfilter")
      setUserList([newdata, ...emailfilter]);
    } else {
      var newdata = { email: userData, full_name: "", user_color: "#fbe5d6" };
      var emailfilter= userList && userList?.filter((item:any,index:any)=>{return item?.email!==userData})
      setUserList([newdata, ...emailfilter]);
    }
  };

  const PasteEmailHandler = (data: any) => {
    if (data) {
      let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');

      let EmailValidation = regex.test(data)
      const domain = data?.split("@")?.pop();
      var domaindata= domain == "gmail.com" ||domain == "yahoo.com" || domain =="hotmail.com"
      if (EmailValidation) {
        if (!domaindata) {
          if (domain == "advancedstructures.in") {
            NormalUser(data, domain)
          } else {
            NormalUser(data, domain)
          }

        }
      }
    }

  }

  var TouserListOption = () => {
    let ccemailId = usersCC?.map((itm: any) => itm.email);
    let tomailId:any = value?.map((itm: any) => itm.email);
    return (
      userList &&
      userList?.filter((item:any)=>![...ccemailId,...tomailId]?.includes(item.email))?.map((item: any, index: any) => {
        return { ...item, id: index + 1 };
      })
    );
  };

  const ManualEmailHandler = (e: any) => {

    if (e.key == "Enter") {
      let regex = new RegExp('[a-z0-9]+@[a-z]+\.[a-z]{2,3}');

      let EmailValidation = regex.test(UserManualEamil)
      const domain = UserManualEamil?.split("@")?.pop();
      var domaindata= domain == "gmail.com" ||domain == "yahoo.com" || domain =="hotmail.com"

      if (EmailValidation) {
        if (
          !domaindata ) {
          if (domain == "advancedstructures.in") {
            NormalUser(UserManualEamil, domain)
          } else {
            NormalUser(UserManualEamil, domain)
          }
        }
      }
    }

  }

  const PreviewHandler = () => {
    PreviewModal.open();
  }

  const handleSelectChangeCC = (ccuser: any) => {
    // @ts-ignore

    let CCIds =
      ccuser &&
      ccuser?.map((item: any, index: any) => {
        return item?.id;
      });
    setUsersCC([...ccuser]);
  };

  const SubjectHandler = (e: any) => {
    setSubjectData(e?.target?.value);
  };

  const htmlElement = ReactDOMServer.renderToStaticMarkup(
    <>       
          <div>
            <div>
              <div>
                <span>Dear User,</span>
                <br />
                <br/>
              </div>

              <p>
              Please find attached the Gantt chart, which offers a visual representation of the project timeline, key tasks, and milestones.
              <br/>
              This document will help you stay informed about our progress and track important dates.
              </p>
              <br/>
              <p>
              If you have any questions or would like to discuss any specific aspect of the project, please let me know.
              </p>
              <br/>
              <p>
              Thank you for your ongoing support and collaboration.
              </p>
              <br/>
            </div>
              <div>
                <p>Best Regards,
                  <br/>
                  <br/>
                  {userProfilePic && `${userProfilePic[0]?.first_name} ${userProfilePic[0]?.last_name} `}
                  <br />
                  {userProfilePic && `${userProfilePic[0]?.email}`}
                  <br />
                  {userProfilePic && `${userProfilePic[0]?.country_code}-${userProfilePic[0]?.phone} `}
                </p>
                <p>
                  <a href="https://advancedstructures.in/">
                    www.advancedstructures.in
                  </a>
                </p>
              </div>
          </div>        
    </>
  );

  useEffect(() => {
    setContent(htmlElement);
  }, [htmlElement]);

  const handleDelete = (FileIds: any) => {
    setAttachmentDataLoader(true)
    API.delete("/analytics/store_attachment_details/", { id: FileIds, mail_type: 3 }, 0).then((res: any) => {
        setAttachmentDataLoader(true)
      setImageUploadCounter((prev: any) => prev + 1)
    })
  };

  const browserFilehandler = () => {
    return InputRef?.current?.click();
  };

  const filehandlechange = (e: any) => {
    let compareFileSize = 7564320;

    let datalength = AttachmentDataList?.length + e.target.files?.length;
    if (datalength <= 4) {
      let fileSizeData = Object.values(e.target.files).map(
        (item: any, index: any) => {
          return item.size;
        }
      );

      let getFileSize = AttachmentDataList && AttachmentDataList?.map((item: any, index: any) => {
        { return item?.file_size }
      })

      let GetTotalFileSize = getFileSize?.reduce((accumulator: any, currentValue: any) => {
        return accumulator + currentValue;
      },
        0
      );
      const TotalFileSize = fileSizeData.reduce(
        (accumulator: any, currentValue: any) => {
          return accumulator + currentValue;
        },
        0
      );

      let finalSize = GetTotalFileSize + TotalFileSize;

      if (finalSize <= compareFileSize) {
        MailAttchmentS3Upload([...e?.target?.files], projectId, setImageUploadCounter, TotalFileSize, setAttachmentDataLoader, 2)
      }


      else {
        setErrorModalFileMsg("You can select upto 7.5Mb files.")
        ErrorModalFiles.open()
      }
    }

    else {
      setErrorModalFileMsg("You can select upto 4 files.")
      ErrorModalFiles.open()
    }

  }

return(
  <div className={styles.container}>
    <input
        type="file"
        id="files"
        name="files"
        style={{ display: "none" }}
        onChange={(e: any) => filehandlechange(e)}
        ref={InputRef}
        multiple
      />
    <ErrorModalFilesMail
        isOpen={ErrorModalFiles.isOpen}
        onCloseModal={ErrorModalFiles.close}
        ErrorMsg={ErrorModalFileMsg}
      />
    {PreviewModal.isOpen &&
    <PreviewMailModal
        isOpen={PreviewModal.isOpen}
        onCloseModal={PreviewModal.close}
        Tousers={value && value}
        CCusers={usersCC}
        Subject={SubjectData}
        AttchmentData={AttachmentDataList}
        emailContent={content}
        FinalEmailSendHandler={FinalEmailSendHandler}
        SendEmail={SendEmail}
      //  ErrorMsg={ErrorModalFileMsg}
      />}
      {successModal.isOpen &&
        <MailSucessModal
          isOpen={successModal.isOpen}
          onCloseModal={successModal.close}
          SendEmail={SendEmail}
          parentsClosed={PreviewModal.close}
          Project={projectId}
          ProjectName={`${projectDetails && projectDetails?.[0]?.name}`}
          emailType={'2'}
        />
      }
    <div className={styles.crumbSection}>
        <div style={{ position: 'absolute', left: '0', marginLeft: '0.5rem' }}>
             <IconButton sx={{ cursor: "pointer" }} title='Go Back' onClick={() => history.push(`/GanttChart/${projectId}`)}>
                 <ArrowBackIcon color="primary" titleAccess="Go Back" />
             </IconButton>
             {projectDetails && <span style={{font:'1rem',fontWeight:'bold'}}>{`${projectDetails && projectDetails?.[0]?.name} [ ${projectDetails && projectDetails?.[0]?.description} ] - Gantt Chart`}</span>}
         </div>
         <div style={{ marginRight: '2.1rem', display: 'flex', columnGap: '2rem', alignItems: 'center' }}>
         <Typography variant='body2' sx={{color: "#ef5350"}}>Max 50 recipients*
 </Typography>
        <Button size="small" variant="contained" 
          onClick={()=> window.open(`/#/Project-mail-logs/${projectId}/2/Project Plan`, "_blank")}
          sx={{
            "&:hover": {
              transform: 'Scale(1.05)',
              transition: 'transform 0.5s ease',
              }
          }}>Email logs</Button>
          {/* {(value?.length > 0 && content?.length > 0 && SubjectData?.length > 0) && */}
          <img src={sendIcon} title="Preview Email" style={value && value?.length>0 ? {
            cursor: "pointer", width: '1.7rem', }  :       
          {
            cursor: "not-allowed", width: '1.7rem', opacity: '0.4',         
          }}
          onClick={() => value && value?.length>0 ? PreviewHandler() : ''}/>
         </div>
    </div>
    <Box sx={{ display: 'flex', alignItems: 'center', }}>
      </Box>
      <div className={styles.parents_userContainer}>
        <div className={styles.userContainer}>
          <Typography sx={{ fontSize: '1rem', marginRight: '1rem', whiteSpace: 'nowrap', width: '10rem', textAlign: 'right' }}>To :</Typography>
          <Autocomplete
            multiple
            disableClearable
            id="User"
            value={value}
            limitTags={6}
            disableCloseOnSelect
            sx={{ width: {lg:'87.5vw',xl:"93vw"}, }}
            

            onInputChange={(event, newInputValue) => {
              PartNameHandler(newInputValue)
            }}
            onChange={(event, newValue) => {
              handleSelectChange(newValue);
            }}

            onPaste={(event: any) => {

              PasteEmailHandler(event.clipboardData.getData('text'))

            }}
            options={TouserListOption() && TouserListOption()}
            // @ts-ignore
            getOptionLabel={(option) => option?.email}
            getOptionDisabled={(option) => usersCC?.length + value?.length >= 50 }

            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                size="small"
                  //  @ts-ignore
                  sx={{ color: "primary.main", background: `${option?.user_color}`,fontSize: '1rem' }}
                  // @ts-ignore
                  label={option?.email}
                  {...getTagProps({ index })}
                // disabled={fixedOptions.indexOf(option) !== -1}
                />
              ))
            }
            onKeyDown={(e: any) => ManualEmailHandler(e)}
            renderOption={(props, option) => (
              // @ts-ignore
              <li {...props} style={{ fontSize: "1rem", color: option?.user_color == '#a6f0b9' ? '#2b9546' : '#eb8c4b', fontWeight: '500', marginBottom: '1px' }}>
                {/* @ts-ignore */}
                {option?.email}
              </li>
            )}  
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                sx={{
                  '& .MuiInput-underline:before': { borderBottomColor: '#007fff' },
                  '& .MuiInput-underline:after': { borderBottomColor: '#007fff' },
                  '.MuiSvgIcon-root': { color: 'primary.main' },
                  // @ts-ignore
                  // backgroundColor: `${params?.user_color}`,
                }}
              //   InputProps={{
              //     // background: `${params?.user_color}`
              //     // @ts-ignore
              //     style: "background-color: ${params?.user_color",

              //  }}

              />
            )}
          />
        </div>
        <div className={styles.userContainer}>
          <Typography sx={{ fontSize: '1rem', marginRight: '1rem', whiteSpace: 'nowrap', width: '10rem', textAlign: 'right' }}>Cc :</Typography>
          <Autocomplete
            multiple
            disableClearable
            id="User"
            limitTags={6}
            disableCloseOnSelect
            sx={{ width: {lg:'87.5vw',xl:"93vw"}, }}
            onInputChange={(event, newInputValue) => {
              PartNameHandler(newInputValue)
            }}
            value={usersCC}
            onChange={(event, newValue) => {
              handleSelectChangeCC(newValue);
            }}
            

            onKeyDown={(e: any) => ManualEmailHandler(e)}
            onPaste={(event: any) => {

              PasteEmailHandler(event.clipboardData.getData('text'))

            }}
            options={TouserListOption() && TouserListOption()}
            // @ts-ignore
            getOptionLabel={(option) => option?.email}
            getOptionDisabled={(option) => usersCC?.length + value?.length >= 50 }

            renderOption={(props, option) => (
              // @ts-ignore
              <li {...props} style={{ fontSize: "1rem", color: option?.user_color == '#a6f0b9' ? '#2b9546' : '#eb8c4b', fontWeight: '500', marginBottom: '1px' }}>
                {/* @ts-ignore */}
                {option?.email}
              </li>
            )}  
            // renderOption={(props, option) => (
            //   <Box
            //     component="li"
            //     sx={{
            //       // color:`${option?.user_color}`,

            //       marginBottom: "0.5rem",
            //       background: "white",
            //     }}
            //     {...props}
            //   >
            //     {option.email}
            //   </Box>
            // )}
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  //  @ts-ignore
                  size="small"
                  sx={{ color: "#007fff", background: `${option?.user_color}`,fontSize: '1rem' }}
                  // @ts-ignore
                  label={option?.email}
                  {...getTagProps({ index })}
                // disabled={fixedOptions.indexOf(option) !== -1}
                />
              ))
            }
            // style={{ width: "93vw" }}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                sx={{
                  '& .MuiInput-underline:before': { borderBottomColor: '#007fff' },
                  '& .MuiInput-underline:after': { borderBottomColor: '#007fff' },
                }}
              />
            )}
          />
        </div>
      </div>
      <div className={styles.userContainersubject}>
        <Typography sx={{ fontSize: '1rem', marginRight: '1rem', whiteSpace: 'nowrap', width: '10rem', textAlign: 'right' }}>Subject :</Typography>
        <TextField
          value={SubjectData}
          onChange={(e: any) => SubjectHandler(e)}
          // defaultValue="Small"
          size="small"
          variant="standard"
          InputProps={{
            style: {fontSize: '1rem' },
          }} 
          sx={{
            width: {lg:'87.5vw',xl:"93vw"},
            '& .MuiInput-underline:before': { borderBottomColor: '#007fff' },
            '& .MuiInput-underline:after': { borderBottomColor: '#007fff' },
          }}
        />
      </div>
      <div className={styles.attchmentcontainer}>
        <Typography sx={{ fontSize: '1rem', marginRight: '1rem', whiteSpace: 'nowrap', width: '10rem', textAlign: 'right', }}>Attachments :</Typography>
        <div style={{ width: "95%", display: 'flex', alignItems: 'center', justifyContent: 'space-between', }}>
          <Box sx={{ width: "100%", display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
            {AttachmentDataLoader ? <>
              <Skeleton animation="wave" sx={{ width: '98%' }} /> </> : <><>
                {AttachmentDataList && AttachmentDataList?.length == 0 &&
                  <span style={{ color: "#007fff80" }}>
                    User can attach file upto 7.5 mb and max 4 files .
                  </span>}
                  {AttachmentDataList && AttachmentDataList?.map((item: any, index: any) => {
                    return (<>
                      <Chip
                        size="small"
                        label={item?.file_name}
                        variant="outlined"
                        onClick={() => window.open(item?.file_url, "_blank")}
                        onDelete={() => handleDelete(item?.id)}
                        sx={{ borderColor: 'primary.main',fontSize: '1rem' }} />
                    </>);
                  })}
              </>
            </>
            }
          </Box>
          {      
              <AttachFileIcon
                titleAccess={"Attachments"}
                onClick={() => browserFilehandler()}
                sx={{ cursor: "pointer", color: 'primary.main',marginRight:'1.6rem'}} />
            }
        </div>

      </div>
      <div style={{ padding: "0 1rem" }}>
      {/* @ts-ignore */}
      <JoditEditor
            ref={editor}
            config={editorConfig}
            className='GanttMailEditor'
            value={content}
            onBlur={(newContent) => setContent(newContent)}
            // onChange={(newContent) => setContent(newContent)}
          />
        </div>
</div>)
}

export default GanttMailLandingPage;