import { useState } from "react";

// ----------------------------------------------------------------------------------

const useIdeaImageOpenModal = () => {
    const [isOpen, setIsOpen] = useState<boolean>( false );
    const [id, setId] = useState<any>();
    const [imageData,setImageData] = useState<any>();

    const handleOpen = (id: string, imageDataComing?: number) => {
        setId( id );
        setImageData(imageDataComing);
        setIsOpen( true );
    };

    const handleClose = () => {
        setIsOpen( false );
        setId( null );
        setImageData(null);
    };

    return Object.freeze( {
        isOpen,
        open: handleOpen,
        close: handleClose,
        id,
        imageData
    } );
};

// ----------------------------------------------------------------------------------

export { useIdeaImageOpenModal };
