// vendors
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";


// redux
// import { RepoImage } from "app/Redux/Reducers/imageRepository.reducer";
// import { ImageRepositoryProductInfo } from "app/Redux/Reducers/imageRepositoryProductInfo.reducer";
import { ID } from "../../utlis/commonInterfaces";
import { RepoImage } from "../Reducers/imageRepository.reducer";
import { ImageRepositoryProductInfo } from "../Reducers/imageRepositoryProductInfo.reducer";
import { architectureAction } from "../Services/architecture.service";
import { ArchImage } from "../Reducers/architecture.reducer";


// -------------------------------------------------------------------------------------
// api actions
// -------------------------------------------------------------------------------------

export interface GetRepoArchArg {
    
    project: any,
    abbreviation: any,
   
    page: any,
    group_id:any,
    col_page:any,
}
const getArchImages = createAsyncThunk(
    "archImage/get",
    async ( arg: GetRepoArchArg, { rejectWithValue } ) => {
        try {
            const { data } = await architectureAction.get_dataImage( arg );
            console.log(data,"datavdwdvsdvydvgdvy")
            return {
                data
               
                
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);
//---------------------------------------------------------------------------------------------

export interface GetArchRepoImagesHeaderDataArg {
    topVault: any,
    abbreviation: string,
    module: any;
}


// -------------------------------------------------------------------------------------
// reducer actions
// -------------------------------------------------------------------------------------

const clearArchRepoImages = createAction<ID>( "archImage/reset" );

// -------------------------------------------------------------------------------------
// exportsclearRepoImages
// -------------------------------------------------------------------------------------

export {
    getArchImages,
    clearArchRepoImages,
};
