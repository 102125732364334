import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CancelIcon from '@mui/icons-material/Cancel';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import {
	Checkbox,
	Divider,
	Skeleton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Tooltip,
} from '@mui/material';
import {
	DataGrid,
	GridColDef,
	GridCsvExportMenuItem,
	GridCsvExportOptions,
	GridToolbar,
	GridToolbarColumnsButton,
	GridToolbarContainer,
	GridToolbarDensitySelector,
	GridToolbarExportContainer,
	GridValueGetterParams,
} from '@mui/x-data-grid';
import { API } from '../../../api-services';
import { LoadingButton } from '@mui/lab';
import { useBaseModal } from '../../SearchPage/useBaseModal';
import ErrorModalFilesMail from '../../ProjectMailModule/ErrorModalFilesMail';


interface InfoModalProps {
	onCloseModal: any;
	isOpen: any;
	materialItemsData: any;
	SelectedmulIds: any;
	selectedCategory: any;
	revisionsName: any;
	ApplyCostType: any;
}
const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '80vw',
	// height: '80vh',
	bgcolor: 'background.paper',
	borderRadius: '10px',
	p: 1,
};
const csvOptions: GridCsvExportOptions = {
	fileName: 'Table In CSV',
	delimiter: ',',
	utf8WithBom: true,
};

const CustomExportButton = (props: ButtonProps) => (
	<GridToolbarExportContainer {...props}>
		<GridCsvExportMenuItem options={csvOptions} />
	</GridToolbarExportContainer>
);

const CustomToolbar = () => (
	<GridToolbarContainer style={{ height: '1.5rem', marginBottom: '0.5rem' }}>
		<span style={{ position: 'absolute', right: '0rem' }}>
			{/* <GridToolbarColumnsButton /> */}
			{/* <GridToolbarDensitySelector /> */}
			<CustomExportButton />
		</span>
	</GridToolbarContainer>
);
const CombinedAssumtionModal = (props: InfoModalProps) => {
	const {
		onCloseModal,
		isOpen,
		materialItemsData,
		SelectedmulIds,
		selectedCategory,
		revisionsName,
		ApplyCostType,
	} = props;
	const [DataList, setDataList] = useState<any>([]);
	const [SelectedMaterial, setSelectedMaterial] = useState<any>([]);
	const [SelectedScrap, setSelectedScrap] = useState<any>([]);
	const [loader, setLoader] = useState<any>(false);

	const [MaterialCostType, setMaterialCostType] = useState<any>([]);

	const [ScrapCostType, setScrapCostType] = useState<any>([]);


	const [costData, setCostData] = useState<any>(null)
	const [costScrapData, setCostScrapData] = useState<any>(null)
	const [Count, setCount] = useState<any>(1)
	const [MaterialType, setMaterialType] = useState<any>(null)
	const [revisionIdMaterila, setRevisionIdMaterial] = useState<any>(null)
	const [ScrapType, setScrapType] = useState<any>(null)
	const [NextLoader, setNextLoader] = useState<any>(false)
	const [PreviousLoader, setPreviousLoader] = useState<any>(false)
	const ErrorModalFiles=useBaseModal()
	const [ErrorModalFileMsg, setErrorModalFileMsg] = useState<any>();
	const [revisionIdScrap, setRevisionIdScrap] = useState<any>(null)

console.log(costData,costScrapData,"costScrapDatacostScrapDatacostScrapData")
	var materialIds =
		materialItemsData &&
		materialItemsData
			?.filter((item: any, index: any) => {
				return item?.calculator_mapped == true;
			})
			?.map((item2: any, index: any) => {
				return item2?.material?.id;
			});
			var materialStatus =
		materialItemsData &&
		materialItemsData
			?.filter((item: any, index: any) => {
				return item?.calculator_mapped == true;
			})[0]
			


console.log(materialStatus?.is_material,materialStatus?.is_scrap
	,"materialStatusmaterialStatus")

	var calIds: any = materialItemsData &&
		materialItemsData
			?.filter((item: any, index: any) => {
				return item?.calculator_mapped == true;
			})[0]


	function removeDuplicateKeys(obj: any) {
		const keys = Object.keys(obj);
		const uniqueKeys = new Set();

		for (const key of keys) {
			if (uniqueKeys.has(key)) {
				delete obj[key];
			} else {
				uniqueKeys.add(key);
			}
		}

		return obj;
	}




	const MaterialCheckBoxhandler = (id: any, item: any) => {
      if(materialStatus?.is_material==true){
		if (id == "min_material_cost") {
			setMaterialType("min")
			let DataListvalue = DataList && DataList[Count - 1]?.min_material_cost
			setCostData(DataListvalue)


			var Material_EstimationIds = materialItemsData &&
				materialItemsData
					?.filter((item: any, index: any) => {
						return item?.calculator_mapped == true && (DataList[Count - 1] && DataList[Count - 1]?.material_id == item?.material?.id);
					})[0]
			API.post(`/cost/update_calculation/`, {

				material_estimation: Material_EstimationIds?.id,

				calculator_ids: calIds?.cal_ids,

				material_type: "min",




			}).then((res: any) => {


				setMaterialType("min")

				setSelectedMaterial([id])
				// 	setCostData(null)
				// setCostScrapData(null)
				// setScrapCostType([])

			}).catch((err: any) => {
				setNextLoader(false)
				const { data } = err.response;

          ErrorModalFiles.open();
          setErrorModalFileMsg(data);
				console.log("Server Error")
			})



		} else if (id == "max_material_cost") {
			let DataListvalue = DataList && DataList[Count - 1]?.max_material_cost
			setMaterialType("max")
			var Material_EstimationIds = materialItemsData &&
				materialItemsData
					?.filter((item: any, index: any) => {
						return item?.calculator_mapped == true && (DataList[Count - 1] && DataList[Count - 1]?.material_id == item?.material?.id);
					})[0]
			API.post(`/cost/update_calculation/`, {

				material_estimation: Material_EstimationIds?.id,

				calculator_ids: calIds?.cal_ids,

				material_type: "max",




			}).then((res: any) => {

				setNextLoader(false)

				setMaterialType("max")

				setSelectedMaterial([id])
				// 	setCostData(null)
				// setCostScrapData(null)
				// setScrapCostType([])

			}).catch((err: any) => {
				setNextLoader(false)
				const { data } = err.response;

          ErrorModalFiles.open();
          setErrorModalFileMsg(data);
				console.log("Server Error")
			})
			setCostData(DataListvalue)
		} else if (id == "avg_material_cost") {
			let DataListvalue = DataList && DataList[Count - 1]?.avg_material_cost
			setCostData(DataListvalue)
			setMaterialType("avg")
			var Material_EstimationIds = materialItemsData &&
				materialItemsData
					?.filter((item: any, index: any) => {
						return item?.calculator_mapped == true && (DataList[Count - 1] && DataList[Count - 1]?.material_id == item?.material?.id);
					})[0]
			API.post(`/cost/update_calculation/`, {

				material_estimation: Material_EstimationIds?.id,

				calculator_ids: calIds?.cal_ids,

				material_type: "avg",




			}).then((res: any) => {

				setNextLoader(false)

				setMaterialType("avg")

				setSelectedMaterial([id])
				// 	setCostData(null)
				// setCostScrapData(null)
				// setScrapCostType([])

			}).catch((err: any) => {
				setNextLoader(false)
				const { data } = err.response;

          ErrorModalFiles.open();
          setErrorModalFileMsg(data);
				console.log("Server Error")
			})
		}
		else {
			setMaterialType("single")
			let DataListValue = item?.material_cost
			let Name = item?.revision_name
			setCostData({ name: Name, value: DataListValue })
			setRevisionIdMaterial(item?.revision_id)
			var Material_EstimationIds = materialItemsData &&
				materialItemsData
					?.filter((item: any, index: any) => {
						return item?.calculator_mapped == true && (DataList[Count - 1] && DataList[Count - 1]?.material_id == item?.material?.id);
					})[0]
			API.post(`/cost/update_calculation/`, {

				material_estimation: Material_EstimationIds?.id,

				calculator_ids: calIds?.cal_ids,

				material_type: "single",

				revision_id: item?.revision_id,




			}).then((res: any) => {

				setNextLoader(false)

				setMaterialType("single")

				setSelectedMaterial([id])
				// 	setCostData(null)
				// setCostScrapData(null)
				// setScrapCostType([])

			}).catch((err: any) => {
				setNextLoader(false)
				const { data } = err.response;

          ErrorModalFiles.open();
          setErrorModalFileMsg(data);
				console.log("Server Error")
			})

		}
		// const updatedTodos = SelectedMaterial?.map((todo: any, index: any) => {
		// 	if (Object.keys(todo) == item) {
		// 		let filter = Object.values(todo).flat(1);
		// 		if (filter?.includes(id)) {
		// 			return { ...todo, [item]: [] };
		// 		} else {
		// 			return { ...todo, [item]: [id] };
		// 		}
		// 	} else {
		// 		return { ...todo };
		// 	}
		// });

		setSelectedMaterial([id]);
	}
	};

	const ScrapCheckBoxhandler = (id: any, item: any) => {
        if(materialStatus?.is_scrap==true){

	
		if (id == "min_scrap_cost") {
			


			let DataListvalue = DataList && DataList[Count - 1]?.min_scrap_cost
			setCostScrapData(DataListvalue)




			var Material_EstimationIds = materialItemsData &&
				materialItemsData
					?.filter((item: any, index: any) => {
						return item?.calculator_mapped == true && (DataList[Count - 1] && DataList[Count - 1]?.material_id == item?.material?.id);
					})[0]
			API.post(`/cost/update_calculation/`, {

				material_estimation: Material_EstimationIds?.id,

				calculator_ids: calIds?.cal_ids,

				scrap_type: "min",




			}).then((res: any) => {


				setScrapType("min")

				setSelectedScrap([id])
				// 	setCostData(null)
				// setCostScrapData(null)
				// setScrapCostType([])

			}).catch((err: any) => {
				setNextLoader(false)
				const { data } = err.response;

          ErrorModalFiles.open();
          setErrorModalFileMsg(data);
				console.log("Server Error")
			})



		}




		else if (id == "max_scrap_cost") {
			
			let DataListvalue = DataList && DataList[Count - 1]?.max_scrap_cost
			var Material_EstimationIds = materialItemsData &&
				materialItemsData
					?.filter((item: any, index: any) => {
						return item?.calculator_mapped == true && (DataList[Count - 1] && DataList[Count - 1]?.material_id == item?.material?.id);
					})[0]
			API.post(`/cost/update_calculation/`, {

				material_estimation: Material_EstimationIds?.id,

				calculator_ids: calIds?.cal_ids,

				scrap_type: "max",




			}).then((res: any) => {


				setScrapType("max")

				setSelectedScrap([id])
				// 	setCostData(null)
				// setCostScrapData(null)
				// setScrapCostType([])

			}).catch((err: any) => {
				setNextLoader(false)
				console.log("Server Error")
				const { data } = err.response;

          ErrorModalFiles.open();
          setErrorModalFileMsg(data);
			})

			setCostScrapData(DataListvalue)
		} else if (id == "avg_scrap_cost") {
			
			let DataListvalue = DataList && DataList[Count - 1]?.avg_scrap_cost
			setCostScrapData(DataListvalue)
			var Material_EstimationIds = materialItemsData &&
				materialItemsData
					?.filter((item: any, index: any) => {
						return item?.calculator_mapped == true && (DataList[Count - 1] && DataList[Count - 1]?.material_id == item?.material?.id);
					})[0]
			API.post(`/cost/update_calculation/`, {

				material_estimation: Material_EstimationIds?.id,

				calculator_ids: calIds?.cal_ids,

				scrap_type: "avg",




			}).then((res: any) => {


				setScrapType("avg")

				setSelectedScrap([id])
				// 	setCostData(null)
				// setCostScrapData(null)
				// setScrapCostType([])

			}).catch((err: any) => {
				setNextLoader(false)
				const { data } = err.response;

          ErrorModalFiles.open();
          setErrorModalFileMsg(data);
				console.log("Server Error")
			})
		}
		else {
			let DataListValue = item?.scrap_cost
			
			let Name = item?.revision_name
			setCostScrapData({ name: Name, value: DataListValue })
			setRevisionIdMaterial(item?.revision_id)
			var Material_EstimationIds = materialItemsData &&
				materialItemsData
					?.filter((item: any, index: any) => {
						return item?.calculator_mapped == true && (DataList[Count - 1] && DataList[Count - 1]?.material_id == item?.material?.id);
					})[0]
			API.post(`/cost/update_calculation/`, {

				material_estimation: Material_EstimationIds?.id,

				calculator_ids: calIds?.cal_ids,

				scrap_type: "single",
				revision_id:item?.revision_id




			}).then((res: any) => {


				setScrapType("single")

				setSelectedScrap([id])
				// 	setCostData(null)
				// setCostScrapData(null)
				// setScrapCostType([])

			}).catch((err: any) => {
				setNextLoader(false)
				const { data } = err.response;

          ErrorModalFiles.open();
          setErrorModalFileMsg(data);
				console.log("Server Error")
			})

		}
		setSelectedScrap([id])

	}
		// const updatedTodos = SelectedScrap?.map((todo: any, index: any) => {
		// 	if (Object.keys(todo) == item) {
		// 		let filter = Object.values(todo).flat(1);
		// 		if (filter?.includes(id)) {
		// 			return { ...todo, [item]: [] };
		// 		} else {
		// 			return { ...todo, [item]: [id] };
		// 		}
		// 	} else {
		// 		return { ...todo };
		// 	}
		// });
		// setSelectedScrap(updatedTodos);
	};

	const CostType = (id: any, item: any, row: any, type: any) => {
		let updatedCostType = MaterialCostType?.map((rowItem: any, index: any) => {
			if (Object.keys(rowItem) == row?.material_code) {
				let filter: any = Object.values(rowItem)[0];

				if (Object.keys(filter)?.length > 0) {
					if (Object.keys(filter) == type) {
						return { ...rowItem, [row?.material_code]: {} };
					} else {
						return { ...rowItem, [row?.material_code]: { [type]: item } };
					}
				} else {
					return { ...rowItem, [row?.material_code]: { [type]: item } };
				}
			} else {
				return { ...rowItem };
			}
		});
		setMaterialCostType(updatedCostType);
	};

	const CostScrapType = (id: any, item: any, row: any, type: any) => {
		setScrapCostType(item)
		// let updatedCostType = ScrapCostType?.map((rowItem: any, index: any) => {
		// 	if (Object.keys(rowItem) == row?.material_code) {
		// 		let filter: any = Object.values(rowItem)[0];

		// 		if (Object.keys(filter)?.length > 0) {
		// 			if (Object.keys(filter) == type) {
		// 				return { ...rowItem, [row?.material_code]: {} };
		// 			} else {
		// 				return { ...rowItem, [row?.material_code]: { [type]: item } };
		// 			}
		// 		} else {
		// 			return { ...rowItem, [row?.material_code]: { [type]: item } };
		// 		}
		// 	} else {
		// 		return { ...rowItem };
		// 	}
		// });
		// setScrapCostType(updatedCostType);
	};
	// @ts-ignore

	useEffect(() => {
		setLoader(true)
		API.get(`/api/db/material_cost/`, {
			category: selectedCategory,
			revision: revisionsName,
			assumption_list: SelectedmulIds?.toString(),
			material_list: materialIds?.toString(),
		}).then((res: any) => {
			setDataList(res?.data);
			setLoader(false)
			let obj = {};
			let Data = res?.data?.map((item: any, index: any) => {
				// @ts-ignore
				return { [item?.material_code]: [] };
			});
			let DataMinmax = res?.data?.map((item: any, index: any) => {
				// @ts-ignore
				return { [item?.material_code]: {} };
			});

			// setMaterialCostType(DataMinmax);
			// setScrapCostType(DataMinmax);
			// setSelectedScrap(Data);
			// setSelectedMaterial(Data);
		}).catch((err: any) => {

			setLoader(false)
		})
	}, [SelectedmulIds]);

	let chekboxvalueMaterial =
		SelectedMaterial &&
		SelectedMaterial?.map((item: any, index: any) => {
			return Object?.values(item);
		})?.flat(2);

	let chekboxvalueScrap =
		SelectedScrap &&
		SelectedScrap?.map((item: any, index: any) => {
			return Object?.values(item);
		})?.flat(2);

	let datavalue = (row: any) => {
		let value =
			MaterialCostType &&
			MaterialCostType.filter((item: any, ins: any) => {
				if (Object.keys(item) == row?.material_code) {
					return Object.values(item)[0];
				}
			});

		return value;
	};
	let ScrapCostchekboxvalue = (row: any) => {
		let value =
			ScrapCostType &&
			ScrapCostType.filter((item: any, ins: any) => {
				if (Object.keys(item) == row?.material_code) {
					return Object.values(item)[0];
				}
			});

		return value;
	};


	const PreviousHandler = () => {
		setCount((prev: any) => prev - 1)
		
		var Material_EstimationIds = materialItemsData &&
			materialItemsData
				?.filter((item: any, index: any) => {
					return item?.calculator_mapped == true && (DataList[Count - 2] && DataList[Count - 2]?.material_id == item?.material?.id);
				})[0]

				


		setPreviousLoader(true)

		API.get(`/cost/update_calculation/`, {

			material_estimation: Material_EstimationIds?.id,




		},0).then((res: any) => {

            if (materialStatus?.is_material==true)  {
				
			   

			if (res?.data?.material_type == "single") {
				setMaterialType("single")
				let DataListvalue = DataList && DataList[Count - 2]?.other_revision.filter((item:any)=>{return item?.id==res?.data?.material})[0]
                let Name=DataListvalue?.revision_name;
				let value=DataListvalue?.material_cost
				setCostData({ name: Name, value: value })
				console.log(DataListvalue,"DataListvalue")
			// setCostData(DataListvalue)
				setSelectedMaterial([DataListvalue?.id])
			} if (res?.data?.material_type == "min") {
				let DataListvalue = DataList && DataList[Count - 2]?.min_material_cost
			setCostData(DataListvalue)
				setMaterialType("min")
				setSelectedMaterial(["min_material_cost"])
			} if (res?.data?.material_type == "max") {
				setMaterialType("max")
				let DataListvalue = DataList && DataList[Count - 2]?.max_material_cost
			setCostData(DataListvalue)
				setSelectedMaterial(["max_material_cost"])
			} if (res?.data?.material_type == "avg") {
				setMaterialType("avg")
				let DataListvalue = DataList && DataList[Count - 2]?.avg_material_cost
			setCostData(DataListvalue)
				setSelectedMaterial(["avg_material_cost"])
			}

			if (res?.data?.material_type==null){
				setCostData(null)
				
				setSelectedMaterial([])
			}
		}
			if (materialStatus?.is_scrap==true){
			if (res?.data?.scrap_type == "single") {
				setScrapType("single")
				let DataListvalue = DataList && DataList[Count - 2]?.other_revision.filter((item:any)=>{return item?.id==res?.data?.material})[0]
                let Name=DataListvalue?.revision_name;
				let value=DataListvalue?.scrap_cost
				setCostScrapData({ name: Name, value: value })
				
			   console.log(DataListvalue,"DataListvalueDataListvalue")
				setSelectedScrap([DataListvalue?.id])
			} if (res?.data?.scrap_type == "min") {
				let DataListvalue = DataList && DataList[Count - 2]?.min_scrap_cost
				setCostScrapData(DataListvalue)
				setScrapType("min")
				setSelectedScrap(["min_scrap_cost"])
			} if (res?.data?.scrap_type == "max") {
				setScrapType("max")
				let DataListvalue = DataList && DataList[Count - 2]?.max_scrap_cost
				setCostScrapData(DataListvalue)
				setSelectedScrap(["max_scrap_cost"])
			} if (res?.data?.scrap_type == "avg") {
				
				let DataListvalue = DataList && DataList[Count - 2]?.avg_scrap_cost
				setCostScrapData(DataListvalue)
				setScrapType("avg")
				setSelectedScrap(["avg_scrap_cost"])
			}
			if(res?.data?.scrap_type==null){
				setCostScrapData(null)
				setSelectedScrap([])


			}
			
		}
			
			

			setPreviousLoader(false)
			// 	setScrapType(null)
			// setMaterialType(null)
			// setSelectedScrap([])
			// 	setSelectedMaterial([])
			// 	setCostData(null)
			// setCostScrapData(null)
			// setScrapCostType([])

		}).catch((err: any) => {
			setPreviousLoader(false)
			console.log("Server Error")
		})
		// API.post(,{},0).then((res:any)=>{

		// 	setSelectedScrap([])
		// 	setSelectedMaterial([])
		// 	setCostData(null)
		// setCostScrapData(null)
		// setScrapCostType([])
		// })

	}
	const NextHandler = () => {
      
		// setNextLoader(true)

		console.log(Count,"chiuchiudhicdhiuc")

		setCount((prev: any) => prev + 1)
		console.log(Count,"chiuchiudhicdhiuc")
		var Material_EstimationIds = materialItemsData &&
			materialItemsData
				?.filter((item: any, index: any) => {
					return item?.calculator_mapped == true && (DataList[Count] && DataList[Count]?.material_id == item?.material?.id);
				})[0]


		// setPreviousLoader(true)

		API.get(`/cost/update_calculation/`, {

			material_estimation: Material_EstimationIds?.id,




		},0).then((res: any) => {



			if (res?.data?.material_type == "single") {
				setMaterialType("single")
				let DataListvalue = DataList && DataList[Count]?.other_revision.filter((item:any)=>{return item?.id==res?.data?.material})[0]
                let Name=DataListvalue?.revision_name;
				let value=DataListvalue?.material_cost
				setCostData({ name: Name, value: value })
				console.log(DataListvalue,"DataListvalue")
			// setCostData(DataListvalue)
				setSelectedMaterial([DataListvalue?.id])
			} if (res?.data?.material_type == "min") {
				let DataListvalue = DataList && DataList[Count]?.min_material_cost
			setCostData(DataListvalue)
				setMaterialType("min")
				setSelectedMaterial(["min_material_cost"])
			} if (res?.data?.material_type == "max") {
				setMaterialType("max")
				let DataListvalue = DataList && DataList[Count]?.max_material_cost
			    setCostData(DataListvalue)
				setSelectedMaterial(["max_material_cost"])
			} if (res?.data?.material_type == "avg") {
				setMaterialType("avg")
				let DataListvalue = DataList && DataList[Count]?.avg_material_cost
			setCostData(DataListvalue)
				setSelectedMaterial(["avg_material_cost"])
			}
			if (res?.data?.scrap_type == "single") {
				setScrapType("single")
				let DataListvalue = DataList && DataList[Count]?.other_revision.filter((item:any)=>{return item?.id==res?.data?.material})[0]
                let Name=DataListvalue?.revision_name;
				let value=DataListvalue?.scrap_cost
				setCostScrapData({ name: Name, value: value })
				
			
				setSelectedScrap([DataListvalue?.id])
			} if (res?.data?.scrap_type == "min") {
				let DataListvalue = DataList && DataList[Count]?.min_scrap_cost
				setCostScrapData(DataListvalue)
				setScrapType("min")
				setSelectedScrap(["min_scrap_cost"])
			} if (res?.data?.scrap_type == "max") {
				setScrapType("max")
				let DataListvalue = DataList && DataList[Count]?.max_scrap_cost
				setCostScrapData(DataListvalue)
				setSelectedScrap(["max_scrap_cost"])
			} if (res?.data?.scrap_type == "avg") {
				
				let DataListvalue = DataList && DataList[Count]?.avg_scrap_cost
				setCostScrapData(DataListvalue)
				setScrapType("avg")
				setSelectedScrap(["avg_scrap_cost"])
			}

			if (res?.data?.scrap_type==null){
				setCostScrapData(null)
				
				setSelectedScrap([])
			} if(res?.data?.material_type==null){
				setCostData(null)
			
				setSelectedMaterial([])
			}
			// 	setScrapType(null)
			// setMaterialType(null)
			// setSelectedScrap([])
			// 	setSelectedMaterial([])
			// 	setCostData(null)
			// setCostScrapData(null)
			// setScrapCostType([])

		}).catch((err: any) => {
			setPreviousLoader(false)
			console.log("Server Error")
		})
		// API.post(,{},0).then((res:any)=>{

		// 	setSelectedScrap([])
		// 	setSelectedMaterial([])
		// 	setCostData(null)
		// setCostScrapData(null)
		// setScrapCostType([])
		// })
		// 	setSelectedScrap([])
		// 	setSelectedMaterial([])
		// 	setCostData(null)
		// setCostScrapData(null)
		// setScrapCostType([])

	}
console.log(Count,"CountCountCount")

	const ApplyHandler = () => {
		ApplyCostType();
		
	};

	const closedHandler = () => {
		onCloseModal()
		ApplyCostType();
	}

	console.log(SelectedMaterial, "SelectedMaterialSelectedMaterial")



	return (
		<div>
			<Modal open={isOpen}
			// onClose={onCloseModal}
			>



				<Box sx={style}>

				{ ErrorModalFiles.isOpen &&  <ErrorModalFilesMail
            isOpen={ErrorModalFiles.isOpen}
            onCloseModal={ErrorModalFiles.close}
            ErrorMsg={ErrorModalFileMsg}
          />}
					<Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
						<Typography
							style={{
								fontSize: '1rem',
								fontWeight: '600',
								width: '100%',
								textAlign: 'center',
								marginRight: '-2rem',
							}}>
							Select RM Cost
						</Typography>
						<CancelIcon
							titleAccess='Close'
							sx={{ cursor: 'pointer', margin: '0.5rem' }}
							onClick={() => closedHandler()}
						/>
					</Box>
					<Divider
						sx={{
							borderColor: 'primary.light',
							margin: '0 1rem',
							marginBottom: '1rem',
						}}
					/>
					<Box
						sx={{ maxHeight: '65vh', width: '100%' }}
						adl-scrollbar='true'
						adl-scrollbar-width='0.3'>
						{loader ? <>
							<Skeleton variant="rectangular" height="10px" sx={{ marginBottom: '10px' }} />
							<Skeleton variant="rectangular" height="10px" sx={{ marginBottom: '10px' }} />
							<Skeleton variant="rectangular" height="10px" sx={{ marginBottom: '10px' }} />
							<Skeleton variant="rectangular" height="10px" sx={{ marginBottom: '10px' }} />
							<Skeleton variant="rectangular" height="10px" sx={{ marginBottom: '10px' }} />
							<Skeleton variant="rectangular" height="10px" sx={{ marginBottom: '10px' }} />
							<Skeleton variant="rectangular" height="10px" sx={{ marginBottom: '10px' }} />
							<Skeleton variant="rectangular" height="10px" sx={{ marginBottom: '10px' }} />
							<Skeleton variant="rectangular" height="10px" sx={{ marginBottom: '10px' }} />
							<Skeleton variant="rectangular" height="10px" sx={{ marginBottom: '10px' }} />
						</>
							:

							<>

								<Typography sx={{ fontSize: '1rem', fontWeight: '600' }}> {Count}. Material Code : {DataList && DataList[Count - 1] && DataList[Count - 1].material_code} ({DataList[Count - 1] && DataList[Count - 1]?.other_revision?.length})</Typography>
								<Table
									stickyHeader
									aria-label='sticky table'
									sx={{ minWidth: 650 }}>
									<TableHead
										sx={{ background: '#ddefff !important' }}>
										<TableCell
											sx={{
												padding: '0.2rem 0.5rem',
												borderBottomColor: 'primary.light',
												backgroundColor: 'primary.light',
											}}>
											Name
										</TableCell>
										{
											materialStatus?.is_material==true &&
										
										<TableCell
											sx={{
												padding: '0.2rem 0.5rem',
												borderBottomColor: 'primary.light',
												backgroundColor: 'primary.light',
											}}>
											Material Cost (INR/kg)
										</TableCell>
}
{

}

										<TableCell
											sx={{
												padding: '0.2rem 0.5rem',
												borderBottomColor: 'primary.light',
												backgroundColor: 'primary.light',
											}}>
											Scrap Cost  (INR/kg)
										</TableCell>
									</TableHead>
									<TableBody>
										{DataList && DataList[Count - 1]?.other_revision?.map(
											(item: any, inde: any) => {
												return (
													<TableRow>
														<TableCell
															component='th'
															scope='row'
															sx={{ borderBottom: 'none', padding: '0.2rem 0.5rem' }}>
															{item?.revision_name}
														</TableCell>
														{
															materialStatus?.is_material==true &&
														
														<TableCell
															component='th'
															scope='row'
															sx={{
																borderBottom: 'none', padding: '0.2rem 0.5rem',
																borderRigth: '1px solid rgba(224, 224, 224, 1) !important',
															}}>
															{item?.material_cost?.toFixed(2)}

															<Checkbox
																style={{ padding: '3px', margin: '0 1rem 0 0.5rem',color:"#007fff" }}
																{...label}
																// checked={chekboxvalueMaterial?.includes(
																// 	item?.id
																// )}
																checked={SelectedMaterial && SelectedMaterial?.includes(item?.id)}
																// onChange={(e: any) => MaterialCheckBoxhandler(
																// 	item?.id,
																// 	DataList[0]?.material_code,

																// )} 
																onChange={(e: any) => MaterialCheckBoxhandler(
																	item?.id,
																	item,

																)}
															/>															
															
														</TableCell>
											}
														<TableCell
															component='th'
															scope='row'
															sx={{ borderBottom: 'none', padding: '0.2rem 0.5rem' }}>
															{item?.scrap_cost?.toFixed(2)}{' '}
															<Checkbox
																style={{ padding: '3px', margin: '0 1rem 0 0.5rem',color:"#007fff" }}
																{...label}
																// checked={chekboxvalueScrap?.includes(
																// 	item?.id
																// )}
																checked={SelectedScrap && SelectedScrap?.includes(item?.id) ? true : false}
																onChange={(e: any) => ScrapCheckBoxhandler(
																	item?.id,
																	item,

																)}

															/>
														</TableCell>
													</TableRow>
												);
											}
										)}
										<TableRow>
											<TableCell
												sx={{ borderBottom: 'none', padding: '0.2rem 0.5rem' }}></TableCell>
											
											{
												materialStatus?.is_material==true &&
											
											<TableCell sx={{ borderBottom: 'none', padding: '0.2rem 0.5rem' }}>
												<TableRow sx={{ display: 'flex !important' }}>
													<TableCell
														sx={{
															borderBottom: 'none',
															padding: '0rem !important',
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															width: '100%',
														}}>
														<Typography sx={{ fontSize: '1rem', whiteSpace: 'nowrap', color: 'green' }}>Minimum  ({DataList && DataList[Count - 1]?.min_material_cost?.toFixed(2)})</Typography>
														<Checkbox
															style={{ padding: '3px', margin: '0 1rem 0 0.5rem', color: 'green' }}
															{...label}
															// checked={datavalue(DataList[0])[0] &&
															// 	datavalue(DataList[0])[0][DataList[0].material_code] &&
															// 	datavalue(DataList[0])[0][DataList[0].material_code]
															// 		?.Min
															// 	? true
															// 	: false}

															checked={SelectedMaterial && SelectedMaterial?.includes("min_material_cost") ? true : false}

															onChange={(e: any) => MaterialCheckBoxhandler(
																"min_material_cost",
																DataList[Count - 1]?.material_code,

															)}
														// onChange={() => CostType(
														// 	DataList[0],
														// 	DataList[0]?.min_material_cost,
														// 	DataList[0],
														// 	'Min'
														// )} 

														/>
													</TableCell>
													<TableCell
														sx={{
															borderBottom: 'none',
															padding: '0rem !important',
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															width: '100%',
														}}>
														<Typography sx={{ fontSize: '1rem', whiteSpace: 'nowrap', color: '#ffc800' }}>Average ({DataList && DataList[Count - 1]?.avg_material_cost?.toFixed(2)})</Typography>
														<Checkbox
															style={{ padding: '3px', margin: '0 1rem 0 0.5rem', color: '#ffc800' }}
															{...label}
															// checked={datavalue(DataList[0])[0] &&
															// 	datavalue(DataList[0])[0][DataList[0].material_code] &&
															// 	datavalue(DataList[0])[0][DataList[0].material_code]
															// 		?.Average
															// 	? true
															// 	: false}

															checked={SelectedMaterial && SelectedMaterial?.includes("avg_material_cost") ? true : false}

															onChange={(e: any) => MaterialCheckBoxhandler(
																"avg_material_cost",
																DataList[Count - 1]?.material_code,

															)}
														// onChange={() => CostType(
														// 	DataList[0],
														// 	DataList[0]?.avg_material_cost,
														// 	DataList[0],
														// 	'Average'
														// )}

														/>
													</TableCell>
													<TableCell
														sx={{
															borderBottom: 'none',
															padding: '0rem !important',
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															width: '100%',
														}}>
														<Typography sx={{ fontSize: '1rem', whiteSpace: 'nowrap' }}>Maximum ({DataList && DataList[Count - 1]?.max_material_cost?.toFixed(2)})</Typography>
														<Checkbox
															style={{ padding: '3px', margin: '0 1rem 0 0.5rem', color: '#007fff' }}
															{...label}
															// checked={datavalue(DataList[0])[0] &&
															// 	datavalue(DataList[0])[0][DataList[0].material_code] &&
															// 	datavalue(DataList[0])[0][DataList[0].material_code]
															// 		?.Max
															// 	? true
															// 	: false

															// }

															// onChange={() => CostType(
															// 	DataList[0],
															// 	DataList[0]?.max_material_cost,
															// 	DataList[0],
															// 	'Max'
															// )} 

															checked={SelectedMaterial && SelectedMaterial?.includes("max_material_cost") ? true : false}
															onChange={(e: any) => MaterialCheckBoxhandler(
																"max_material_cost",
																DataList[Count - 1]?.material_code,

															)}

														/>
													</TableCell>

												</TableRow>
												<TableRow>
													<TableCell
														colSpan={3}
														sx={{
															background: '#ddefff !important',
															padding: '0.2rem 0',
															borderBottomColor: 'primary.light',
															width: '100%',
														}}>

														<Box sx={{width:'31vw',marginLeft:'0.5rem'}}>

														{
															SelectedMaterial && SelectedMaterial?.length > 0 ? <>
																Selected Value: &nbsp;
																{SelectedMaterial && SelectedMaterial?.includes("max_material_cost") ? <span>
																Maximum  <b>({costData && costData} INR/kg)</b>
																</span> : SelectedMaterial && SelectedMaterial?.includes("min_material_cost") ? <span>Minimum  <b>({costData && costData} INR/kg)</b> </span> : SelectedMaterial && SelectedMaterial?.includes("avg_material_cost") ? <span>Average <b>({costData && costData} INR/kg)</b></span> : <span>{costData &&  costData!==undefined && costData?.name}  <b>({costData && costData!==undefined && costData?.value} INR/kg)</b></span>
																}
															</> :
																<>
																	Selected Value:
																</>
														}
														</Box>
													</TableCell>
												</TableRow>
											</TableCell>
}
{
	materialStatus?.is_scrap==true  &&

											<TableCell sx={{ borderBottom: 'none', padding: '0.2rem 0.5rem' }}>
												<TableRow sx={{ display: 'flex !important' }}>
													<TableCell
														sx={{
															borderBottom: 'none',
															padding: '0rem !important',
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															width: '100%',
														}}>
														<Typography sx={{ fontSize: '1rem', whiteSpace: 'nowrap', color: 'green' }}>Minimum  ({DataList && DataList[Count - 1]?.min_scrap_cost?.toFixed(2)})</Typography>
														<Checkbox
															style={{ padding: '3px', margin: '0 1rem 0 0.5rem', color: 'green' }}
															{...label}
															// checked={ScrapCostchekboxvalue(DataList[0])[0] &&
															// 	ScrapCostchekboxvalue(DataList[0])[0][DataList[0].material_code] &&
															// 	ScrapCostchekboxvalue(DataList[0])[0][DataList[0].material_code].Min
															// 	? true
															// 	: false}
															// onChange={() => CostScrapType(
															// 	DataList[0],
															// 	DataList[0]?.min_scrap_cost,
															// 	DataList[0],
															// 	'Min'
															// )}

															checked={SelectedScrap && SelectedScrap?.includes("min_scrap_cost") ? true : false}
															onChange={(e: any) => ScrapCheckBoxhandler(
																"min_scrap_cost",
																DataList[Count - 1]?.material_code,

															)}
														/>
													</TableCell>
													<TableCell
														sx={{
															borderBottom: 'none',
															padding: '0rem !important',
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															width: '100%',
														}}>
														<Typography sx={{ fontSize: '1rem', whiteSpace: 'nowrap', color: '#ffc800' }}>Average ({DataList && DataList[Count - 1]?.avg_scrap_cost?.toFixed(2)})</Typography>
														<Checkbox
															style={{ padding: '3px', margin: '0 1rem 0 0.5rem', color: '#ffc800' }}
															{...label}
															// checked={ScrapCostchekboxvalue(DataList[0])[0] &&
															// 	ScrapCostchekboxvalue(DataList[0])[0][DataList[0].material_code] &&
															// 	ScrapCostchekboxvalue(DataList[0])[0][DataList[0].material_code]?.Average
															// 	? true
															// 	: false}
															// onChange={() => CostScrapType(
															// 	DataList[0],
															// 	DataList[0]?.avg_scrap_cost,
															// 	DataList[0],
															// 	'Average'
															// )}

															checked={SelectedScrap && SelectedScrap?.includes("avg_scrap_cost") ? true : false}
															onChange={(e: any) => ScrapCheckBoxhandler(
																"avg_scrap_cost",
																DataList[Count - 1]?.material_code,

															)}

														/>
													</TableCell>
													<TableCell
														sx={{
															borderBottom: 'none',
															padding: '0rem !important',
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'center',
															width: '100%',
														}}>
														<Typography sx={{ fontSize: '1rem', whiteSpace: 'nowrap' }}>Maximum ({DataList && DataList[Count - 1]?.max_scrap_cost?.toFixed(2)})</Typography>
														<Checkbox
															style={{ padding: '3px', margin: '0 1rem 0 0.5rem', color: '#007fff' }}
															{...label}
															// checked={ScrapCostchekboxvalue(DataList[0])[0] &&
															// 	ScrapCostchekboxvalue(DataList[0])[0][DataList[0].material_code] &&
															// 	ScrapCostchekboxvalue(DataList[0])[0][DataList[0].material_code]?.Max
															// 	? true
															// 	: false}
															// onChange={() => CostScrapType(
															// 	DataList[0],
															// 	DataList[0]?.max_scrap_cost,
															// 	DataList[0],
															// 	'Max'
															// )}

															checked={SelectedScrap && SelectedScrap?.includes("max_scrap_cost") ? true : false}
															onChange={(e: any) => ScrapCheckBoxhandler(
																"max_scrap_cost",
																DataList[Count - 1]?.material_code,

															)}


														/>
													</TableCell>
												</TableRow>
												<TableRow>



													<TableCell
														colSpan={3}
														sx={{
															background: '#ddefff !important',
															padding: '0.2rem 0',
															borderBottomColor: 'primary.light',
															width: '100%',
														}}>
														<Box sx={{width:'31vw',marginLeft:'0.5rem'}}>

														{
															SelectedScrap && SelectedScrap?.length > 0 ? <>


																Selected Value: &nbsp;
																{SelectedScrap && SelectedScrap?.includes("max_scrap_cost") ? <span>

																Maximum  <b>({costScrapData && costScrapData} INR/kg)</b>
																</span> : SelectedScrap && SelectedScrap?.includes("min_scrap_cost") ? <span>Minimum <b>({costScrapData && costScrapData} INR/kg)</b></span> : SelectedScrap && SelectedScrap?.includes("avg_scrap_cost") ? <span>Average    <b>({costScrapData && costScrapData} INR/kg)</b></span> : <span> {costScrapData && costScrapData?.name} <b>({costScrapData && costScrapData?.value} INR/kg)</b></span>

																}
															</> :
																<>
																	Selected Value:
																</>

														}
														</Box>
													</TableCell>

												</TableRow>
											</TableCell>
}
										</TableRow>





									</TableBody>
								</Table></>
						}
					</Box>
					{loader == false &&
						<Box sx={{ display: 'flex', columnGap: '2rem', justifyContent: 'flex-end', alignItems: 'center', width: '100%', padding: '0.5rem' }}>
							<LoadingButton loading={PreviousLoader} disabled={Count != 1 ? false : true} startIcon={<NavigateBeforeIcon sx={{ marginRight: '-0.5rem' }} />} size='small' variant='contained' onClick={() => PreviousHandler()}>
								Previous {Count-1!=0 && (`${Count-1}`)}
							</LoadingButton>
							{
								DataList && DataList?.length == Count ?



								<>
								<>{(materialStatus?.is_material == true && materialStatus?.is_scrap == true) &&



										<LoadingButton
											disabled={SelectedMaterial && SelectedMaterial?.length == 0 ? true : false}
											// disabled={DataList?.length<=Count?false:true} 
											size='small' variant='contained' onClick={() => ApplyHandler()}>
											Proceed
										</LoadingButton>
										
										}


									</>


									<>{(materialStatus?.is_material == false && materialStatus?.is_scrap == true) &&



<LoadingButton
	disabled={SelectedScrap && SelectedScrap?.length == 0 ? true : false}
	// disabled={DataList?.length<=Count?false:true} 
	size='small' variant='contained' onClick={() => ApplyHandler()}>
	Proceed
</LoadingButton>

}


</>
									<>


											{(materialStatus?.is_material == true && materialStatus?.is_scrap == false) &&



												<LoadingButton
													disabled={SelectedMaterial && SelectedMaterial?.length == 0 ? true : false}
													// disabled={DataList?.length<=Count?false:true} 
													size='small' variant='contained' onClick={() => ApplyHandler()}>
													Proceed
												</LoadingButton>}


										</></>


											
											
											
											
											



									:
                                     <>

{  (materialStatus?.is_material == true && materialStatus?.is_scrap == true) &&

<LoadingButton loading={NextLoader}

disabled={SelectedMaterial && SelectedMaterial?.length == 0 ? true : false}
endIcon={<NavigateNextIcon sx={{ marginLeft: '-0.5rem' }} />} size='small' variant='contained' onClick={() => NextHandler()}>
Next ({DataList?.length - Count})
</LoadingButton>

}
{  (materialStatus?.is_material == true && materialStatus?.is_scrap == false) &&

<LoadingButton loading={NextLoader}

disabled={SelectedMaterial && SelectedMaterial?.length == 0 ? true : false}
endIcon={<NavigateNextIcon sx={{ marginLeft: '-0.5rem' }} />} size='small' variant='contained' onClick={() => NextHandler()}>
Next ({DataList?.length - Count})
</LoadingButton>

}
{  (materialStatus?.is_material == false && materialStatus?.is_scrap == true) &&

<LoadingButton loading={NextLoader}

disabled={SelectedScrap && SelectedScrap?.length == 0 ? true : false}
endIcon={<NavigateNextIcon sx={{ marginLeft: '-0.5rem' }} />} size='small' variant='contained' onClick={() => NextHandler()}>
Next ({DataList?.length - Count})
</LoadingButton>

}
									 </>

									
									
							}

						</Box>}
				</Box>
			</Modal>
		</div>
	);
};

export default CombinedAssumtionModal;
