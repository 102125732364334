import { SET_MOM_PERMISSION } from "../Actions/Mom.action";

const initialState = {
  action: "",
};

export default (state: any = initialState, action: any) => {
  switch (action.type) {
    case SET_MOM_PERMISSION:
      return action.payload;
    default:
      return state;
  }
};
