// vendors
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

// services

import permissionsService from "../Services/permissions.service";

// redux
import { RootState } from "../Store/storeConfigurations";
// ----------------------------------------------------------------------------
// actions

export const getPermissions = createAsyncThunk( "permssions/get", async () => {
    const { data } = await permissionsService.get();

    return {
        permissions: data,
    };
}, {
    condition ( _arg, api ) {
        return !( api.getState() as RootState ).permissions.fetched;
    }
} );

// ----------------------------------------------------------------------------
// slice

export const PermissionsReducerInit = {
    fetched: false,
    data: [] as number[]
};

const permissionsSlice = createSlice( {
    name: "permissions",
    initialState: PermissionsReducerInit,
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase( getPermissions.fulfilled, ( state, action ) => {
                return {
                    data: action.payload.permissions,
                    fetched: true,
                };
            } )
            .addCase( getPermissions.rejected, () => ( {
                data: [],
                fetched: false
            } ) );
    },
} );

// ----------------------------------------------------------------------------
// exports

export const { reducer: PermissionsReducer } = permissionsSlice;
