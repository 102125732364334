import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Autocomplete from "@mui/material/Autocomplete";
import { API } from "../../api-services";
import { Divider, FormControl, InputLabel, Skeleton, TextField } from "@mui/material";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import CancelIcon from "@mui/icons-material/Cancel";
import { LoadingButton } from "@mui/lab";
import { useHistory } from "react-router-dom";
import Swal from "sweetalert2";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "30vw",
  bgcolor: "background.paper",
  p: 1,
  borderRadius: "10px"
};

interface AddBomConfigurationModalProps {
  onCloseModal: any;
  isOpen: any;
  setParentCount: any;
  SwalAlertHandler: any;
  setDeleteCounter: any;
}

export const AddBomConfigurationModal = (
  props: AddBomConfigurationModalProps
) => {
  const { onCloseModal, isOpen, setParentCount, SwalAlertHandler, setDeleteCounter } = props;

  const {
    projectId,
    projectname,
    categoryId,
    productId,
    datatype,
    validationId, categoryname
  } = useRouteParams<any>();

  const [CategoryList, setCotegoryList] = React.useState<any>([]);
  const history = useHistory<any>()
  const [categoryIds, setcategoryIds] = React.useState<any>([]);
  const [isLoadingSave, setIsLoadingSave] = React.useState<boolean>(false);
  const [Counter, setCounter] = React.useState<any>(0)
  const [loader, setloader] = React.useState<any>(false);
  const handleSelectChange = (item: any) => {
    setcategoryIds(item);
    var ids = item?.map((item: any, index: any) => {
      return item?.id;
    });
    var filterdata = CategoryList?.filter((items: any, index: any) => {
      return !ids.includes(items?.id);
    });

    setCotegoryList(filterdata);
  };

  var optionData = CategoryList && CategoryList;

  React.useEffect(() => {
    setloader(true);
    API.get("/api/db/category/", {
      top_vault: productId,
      project: projectId,
      module: 6,
    }).then((res: any) => {
      setCotegoryList(res?.data);
      setloader(false);
    });
  }, [setParentCount]);

  const SaveHandler = () => {
    setIsLoadingSave(true);
    var Ids = categoryIds && categoryIds?.map((item: any, index: any) => { return item?.id })
    API.post("/config/template_config/", {
      top_vault_id: validationId == 0 ? productId?.toString() : validationId?.toString(),
      category: Ids?.toString(),
      project: projectId,
    }).then((res: any) => {
      setIsLoadingSave(false)
      SwalAlertHandler(true)
      onCloseModal()
      setParentCount((prev: any) => prev + 1)
      setDeleteCounter((prev: any) => prev + 1)
      history.push(
        `/bomconfiguration/${projectId}/${projectname}/${productId}/${Ids?.[0]}/${categoryname}/${validationId}/0`
      );
    }).catch((err: any) => {
      SwalAlertHandler(false)
      onCloseModal()
      setIsLoadingSave(false)
    })
  };

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}>
              Add Category
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <FormControl fullWidth>
            <InputLabel sx={{ color: 'primary.main', marginLeft: '-1rem' }} shrink>Category</InputLabel>
            {loader ? <Skeleton variant="rounded" sx={{ height: '2rem', width: '100%', marginTop: '1.5rem' }} /> : <Autocomplete
              id="combo-box-demo"
              size="small"
              value={categoryIds}
              sx={{
                width: '100%', marginTop: '0.5rem',
                '.MuiButtonBase-root.MuiChip-root': {
                  backgroundColor: 'primary.light',
                },
              }}
              multiple
              disableClearable
              limitTags={2}
              disableCloseOnSelect
              onChange={(event, newValue) => {
                handleSelectChange(newValue);
              }}
              options={optionData}
              // @ts-ignore
              getOptionLabel={(option) => option?.name}
              renderInput={(params) => (
                <TextField
                  // placeholder="category"
                  {...params}
                  variant="standard"
                  sx={{
                    fontSize: '1rem',
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "#007fff",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#007fff",
                    },
                    ".MuiSvgIcon-root": { color: "primary.main" },
                  }}

                />
              )}
            />}
          </FormControl>
          <Box sx={{
            display: "flex",
            justifyContent: "end",
            marginTop: "1rem"
          }}>
            <LoadingButton
              variant="contained"
              size="small"
              disabled={categoryIds?.length > 0 ? false : true}
              loading={isLoadingSave}
              sx={{
                ":hover": {
                  transform: "Scale(1.05)",
                  transition: "transform 0.5s ease",
                },
                // backgroundColor: "",
              }}
              onClick={() => {
                SaveHandler()
              }}>
              Add
            </LoadingButton>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
