import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { API } from '../../api-services';
import { Box, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import useWindowDimensions from '../../ui-reusable-component/useWindowDimensions';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid";
import { ButtonProps } from "@mui/material/Button";
import { styled } from '@mui/material/styles';
import moment from 'moment';
import swal from "sweetalert";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import RestoreIcon from '@mui/icons-material/Restore';
import { LoadingButton } from '@mui/lab';

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Rows</Box>
    </StyledGridOverlay>
  );
}

const csvOptions: GridCsvExportOptions = {
  fileName: "Recycle Bin (BOM Categories Database) In CSV", delimiter: ",", utf8WithBom: true
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = (rowSelectionModel:any, handleDeleteSelectedImages:any, handleRestoreSelectedImages:any, restoreSelectedLoader:any, deletedSelectedLoader:any, restoreSelectedDisable:any, deletedSelectedDisable:any) => (
  <GridToolbarContainer style={{ height: "2rem" }}>
    <span style={{ position: "absolute", right: "0rem", fontSize: "1rem", paddingRight: '1rem' }}>
    {rowSelectionModel?.length > 0 && <>
      <LoadingButton disabled={deletedSelectedDisable} loading={deletedSelectedLoader} onClick={handleDeleteSelectedImages}><DeleteIcon sx={{fontSize:'1.2rem', marginRight:'0.2rem'}}/><span style={{fontSize:"0.9rem"}}>Delete Selected</span></LoadingButton>
      <LoadingButton disabled={restoreSelectedDisable} loading={restoreSelectedLoader} onClick={handleRestoreSelectedImages}><RestoreIcon sx={{fontSize:'1.2rem', marginRight:'0.2rem'}}/><span style={{fontSize:"0.9rem"}}>Restore Selected</span></LoadingButton>
      </>}
      <GridToolbarColumnsButton />
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);


interface BomRecycleBinI { }

const BomRecycleBin: React.FC<BomRecycleBinI> = (props) => {
  const [categoriesRecycleBinData, setCategoriesRecycleBinData] = useState<any>();
  const [categoriesRecycleBinDataLoader, setCategoriesRecycleBinDataLoader] = useState<boolean>(true);
  const [categoriesRecycleBinDataReload, setCategoriesRecycleBinDataReload] = useState<boolean>(true);
  const [GetSelectedIds, setGetSelectedIds] = useState<any>([]);
  const [restoreSelectedLoader,setRestoreSelectedLoader] = useState<boolean>(false);
  const [deletedSelectedLoader,setDeletedSelectedLoader] = useState<boolean>(false);
  const [restoreSelectedDisable,setRestoreSelectedDisable] = useState<boolean>(false);
  const [deletedSelectedDisable,setDeletedSelectedDisable] = useState<boolean>(false);
  const { width } = useWindowDimensions();
  const history = useHistory();

  useEffect(() => {
    setCategoriesRecycleBinDataLoader(true)
    API.get("/auth/mui_trash_view/", { bin_type: "category" }, 0).then((res: any) => {
      setCategoriesRecycleBinData(res?.data);
      setCategoriesRecycleBinDataLoader(false)
    })
  }, [categoriesRecycleBinDataReload])

  // handleDeleteTableData is used for deleting a single row data in Datagrid

  const handleDeleteBomCategory = async (e: React.MouseEvent, id: number) => {
    e.stopPropagation();
    swal({
      title: `Delete BOM Category`,
      text: `Are you sure?`,
      icon: "error",
      //@ts-ignore
      buttons: {
        cancel: "Cancel",
        permanent: {
          text: `Delete BOM Category`,
          value: `deleteBomCategory`,
        },
      },
      closeOnClickOutside: false,
      closeOnEsc: false,
      className: "delete-swal",
    }).then(async (type: string) => {
      switch (type) {
        case "deleteBomCategory":
          API.delete("/auth/trash/" + id + "/", {}, 0)
            .then((res: any) => {
              setCategoriesRecycleBinDataReload(!categoriesRecycleBinDataReload);
            })
            .catch((err: any) => {
              console.log(err, "Unable to upload file. Server Error");
            });
          break;
        default:
          swal(`Data saved from deleting`, { icon: "error" });
      }
    });
  };
  const AllDeleteHandler = async (e: React.MouseEvent, id: number) => {
    e.stopPropagation();
    swal({
      title: `Delete BOM Category`,
      text: `Are you sure?`,
      icon: "error",
      //@ts-ignore
      buttons: {
        cancel: "Cancel",
        permanent: {
          text: `Delete BOM Category`,
          value: `deleteBomCategory`,
        },
      },
      closeOnClickOutside: false,
      closeOnEsc: false,
      className: "delete-swal",
    }).then(async (type: string) => {
      switch (type) {
        case "deleteBomCategory":
          setDeletedSelectedLoader(true);
          setRestoreSelectedDisable(true);
          
          API.delete("/auth/trash/" + GetSelectedIds[0] + "/", {id_list:GetSelectedIds}, 0)
            .then((res: any) => {
              setDeletedSelectedLoader(false);
              setRestoreSelectedDisable(false);
              setGetSelectedIds([])
              setCategoriesRecycleBinDataReload(!categoriesRecycleBinDataReload);
            })
            .catch((err: any) => {
              setDeletedSelectedLoader(false);
              setRestoreSelectedDisable(false);
              
              console.log(err, "Unable to upload file. Server Error");
            });
          break;
        default:
          swal(`Data saved from deleting`, { icon: "error" });
      }
    });
  };
  const handleRestoreBomCategory = async (e: React.MouseEvent, id: number) => {
    e.stopPropagation();
    swal({
      title: `Restore BOM Category`,
      text: `Are you sure?`,
      icon: "warning",
      //@ts-ignore
      buttons: {
        cancel: "Cancel",
        permanent: {
          text: `Restore BOM Category`,
          value: `restoreBomCategory`,
        },
      },
      closeOnClickOutside: false,
      closeOnEsc: false,
      className: "warning-swal",
    }).then(async (type: string) => {
      switch (type) {
        case "restoreBomCategory":
          API.put("/auth/trash/" + id + "/", {}, 0)
            .then((res: any) => {
              setCategoriesRecycleBinDataReload(!categoriesRecycleBinDataReload);
            })
            .catch((err: any) => {
              console.log(err, "Unable to upload file. Server Error");
            });
          break;
        default:
          swal(`Data saved from restoring`, { icon: "error" });
      }
    });
  };
  const AllRestoreParameterLibrary = async (e: React.MouseEvent, id: number) => {
    e.stopPropagation();
    swal({
      title: `Restore BOM Category`,
      text: `Are you sure?`,
      icon: "warning",
      //@ts-ignore
      buttons: {
        cancel: "Cancel",
        permanent: {
          text: `Restore BOM Category`,
          value: `restoreBomCategory`,
        },
      },
      closeOnClickOutside: false,
      closeOnEsc: false,
      className: "warning-swal",
    }).then(async (type: string) => {
      switch (type) {
        case "restoreBomCategory":
          setRestoreSelectedLoader(true);
              setDeletedSelectedDisable(true);
           
          API.put("/auth/trash/" + id + "/", {}, 0)
            .then((res: any) => {
              setRestoreSelectedLoader(false);
              setDeletedSelectedDisable(false);
              setGetSelectedIds([])
              setCategoriesRecycleBinDataReload(!categoriesRecycleBinDataReload);
            })
            .catch((err: any) => {
              setRestoreSelectedLoader(false);
              setDeletedSelectedDisable(false);
         
              console.log(err, "Unable to upload file. Server Error");
            });
          break;
        default:
          swal(`Data saved from restoring`, { icon: "error" });
      }
    });
  };


  // columns is a variable where column data is stored. This columns is then used as a prop in dataGrid.

  let columns: any = [
    { field: "sno", headerName: "SNo.", width: width > 1370 ? 50 : width < 900 ? 50 : 50, },
    {
      field: "name",
      headerName: "Name",
      headerAlign: "left",
      //   align: 'right',
      //   minWidth: width > 1370 ? 100 : width < 900 ? 120 : 200,
      flex: 1,
    },
    { field: "deletedBy", headerName: "Deleted By", flex: 1 },
    {
      field: "deletedAt",
      headerName: "Deleted At",
      //   width: width > 1370 ? 320 : 300,
      flex: 1,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: width > 1370 ? 105 : width < 1200 ? 80 : 100,
      renderCell: (cellValues: any) => {
        return (<>
          <IconButton sx={{ color: 'primary.main', padding: '3px' }}
            title="Restore BOM Category" onClick={(e: any) => handleRestoreBomCategory(e, cellValues?.row?.id)}>
            <RestoreIcon />
          </IconButton>
          <IconButton sx={{ color: 'primary.main', padding: '3px', }}
            title="Delete BOM Category" onClick={(e: any) => handleDeleteBomCategory(e, cellValues?.row?.id)}>
            <DeleteIcon />
          </IconButton>
        </>)
      }
    },
  ];

  // In rows which is a array, we are pushing data which is structured in frontEnd so, that we can pass it to dataGrid

  let rows: any = [];

  let dataGridDataGenerator: any =
    categoriesRecycleBinData &&
    categoriesRecycleBinData?.map((ideaItem: any, index: any) => {
      let objShared: any = {
        id: ideaItem?.id,
        sno: index + 1,
        name: ideaItem?.name,
        deletedBy: ideaItem?.user,
        deletedAt: moment(ideaItem?.timestamp).format('DD-MM-YYYY'),
      };
      rows.push(objShared);
    });

  // dataStructureDataGrid is the total data format which is ready to pass to the dataGrid Props

  let dataStructureDataGrid: any = {
    columns: columns,
    rows: rows,
  };

  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', margin: '0 0.5rem' }}>
        <IconButton
          sx={{ cursor: "pointer", zIndex: '1' }}
          title="Go Back"
          onClick={() => history.goBack()}
        >
          <ArrowBackIcon color="primary" titleAccess="Go Back" />
        </IconButton>
        <Typography sx={{ marginLeft: "1rem",fontSize: '1rem' }}>Recycle Bin ( BOM Categories Database )</Typography>
      </div>
      <Box
        sx={{
          height: { sm: '90vh', md: '89vh', lg: '88vh', xl: '90vh' },
          width: "100%",
          padding: '0 1rem',
          position: 'relative',
          top: '-2.5vh'
        }}>
        <DataGrid
          headerHeight={48}
          rowHeight={48}
          {...dataStructureDataGrid}
          loading={categoriesRecycleBinDataLoader}
          disableSelectionOnClick={true}
          components={{
            Toolbar: () => {
              return CustomToolbar(GetSelectedIds,AllDeleteHandler, AllRestoreParameterLibrary, restoreSelectedLoader, deletedSelectedLoader, restoreSelectedDisable, deletedSelectedDisable);
            },
            noRowsOverlay: CustomNoRowsOverlay,
          }}
        
          sx={{

            "& .MuiDataGrid-checkboxInput": {
              color: "primary.main",
            },
            "& ::-webkit-scrollbar": {
              width: "0.3rem",
              height: "0.3rem",
            },
            "& ::-webkit-scrollbar-thumb": {
              // background: $thumb-color;
              backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
              borderRadius: "10px",
            },
            "& ::- webkit-scrollbar-thumb: hover ": {
              // width: "4px",
              backgroundColor: "#045DE9 !important",
            },
            "::-webkit-scrollbar-track": {
              backgroundColor: "#e1e1f3 !important",
            },
            "&.MuiDataGrid-root": {
              border: 'none',
            },
            "&.MuiDataGrid-root .MuiDataGrid-cell": {
              borderBottomColor: "primary.light",
            },
            
           

          }}
          density={"compact"}
          pageSize={100}
          checkboxSelection
          onSelectionModelChange={(ids) => {
            const selectedIDs = new Set(ids);
            setGetSelectedIds(ids);
          }}
          rowsPerPageOptions={[100]}
        />
      </Box>
    </div>)
}

export default BomRecycleBin;