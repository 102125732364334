import { Box, Button, Divider, Modal, Skeleton, Typography, styled } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import { ID } from '../../../../../../utlis/commonInterfaces';
import styles from './DatabaseSelectionModal.module.scss';
import { API } from '../../../../../../api-services';
import useWindowDimensions from '../../../../../../ui-reusable-component/useWindowDimensions';
import { DataGrid } from '@mui/x-data-grid/DataGrid';
import { useBaseModal } from '../../../../../SearchPage/useBaseModal';
import CurrencyAssumptionModal from '../../../CurrencyAssumptionModal';
import { OtherAssumptionIdContext } from '../../../CreateCostingPart';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: "85vw",
    maxWidth: '90vw',
    // height: '80vh',
    bgcolor: 'background.paper',
    borderRadius: '10px',
    p: 1,
};

const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
      fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
      fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
  }));
  

function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <svg
          width="120"
          height="100"
          viewBox="0 0 184 152"
          aria-hidden
          focusable="false"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse
                className="ant-empty-img-5"
                cx="67.797"
                cy="106.89"
                rx="67.797"
                ry="12.668"
              />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <Box sx={{ mt: 1 }}>No Rows</Box>
      </StyledGridOverlay>
    );
  }
  

interface IDatabaseCurrencySelectionModelProps { 
    isOpen: boolean | any;
    database: number | any;
    onClose: () => void;
    onSubmit: (record: any) => void;
    revisiondata: any;
    calInfo:any;
    
    calculatorId: any;
    userInput: any;
}

const DatabaseCurrencySelectionModel: React.FC<IDatabaseCurrencySelectionModelProps> = (props) => {
    const { isOpen, onClose, database, onSubmit, revisiondata,calInfo,calculatorId,userInput} = props;
    const [selection, setSelection] = useState<any>([]);
    const [currencyDataBaseList, setCurrencyDataBaseList] = useState<any>();
    const [loader, setLoader] = useState<any>(false);
    const BoughtOutmodal=useBaseModal()
    const AssumtionIds = useContext<any>(OtherAssumptionIdContext)
  const { width } = useWindowDimensions();
  const [DataBaseStatus,setDataBaseStatus]=useState<any>(null)
    useEffect(() => {
        if (revisiondata && revisiondata?.currency_revision !== 0 && revisiondata && revisiondata?.currency_revision !== undefined) {
            setLoader(true)
                API.get("/api/db/revision_currency/", { revision: revisiondata && revisiondata?.currency_revision }, 0).then((res: any) => {
                    setCurrencyDataBaseList(res.data)
                    setLoader(false)
                }).catch((err: any) => {
                    console.log("Server Error")
                    setLoader(false)
                })
            }
           
    }, [revisiondata, isOpen]);



    useEffect(()=>{

    {
          API.get(`/cost/update_calculation/`,{calculation_id:userInput?.id,calculator_id:calInfo?.id,currency_type:true}).then((res:any)=>{
              setDataBaseStatus(res?.data)
          }).catch((err:any)=>{
              console.log("Server Error")
          })
      }
      },[isOpen])

    let columns: any = [
        {
          field: "flag",
          headerName: "Flag",
          headerAlign: "left",
          //   align: 'right',
          minWidth: width > 1370 ? 100 : 80,
          flex: 1,
          disableExport: true,
          renderCell: (cellValues: any) => {
            return (<Box sx={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem', }}>
                <img style={{height:'1.7rem',width:'3rem'}} src={`data:image/png;base64, ${cellValues?.row?.flag}`}/>
               </Box>)
          }
      },
      { field: "name", headerName: "Name", width: width > 1370 ? 380 : 260, },
      { field: "countryName", headerName: "Currency of Country", width: width > 1370 ? 280 : 160, },
      { field: "currencyFrom", headerName: "Currency From", width: width > 1370 ? 180 : 100, 
      renderCell: (cellValues: any) => {
        return (<Box sx={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem', }}>
            <span>{cellValues?.row?.currencyFrom} </span>{`${cellValues?.row?.currencySymbol!=null && cellValues?.row?.currencySymbol!="_" ? `( ${cellValues?.row?.currencySymbol} )` : ""}`}
           </Box>)
      }},
      { field: "currencyTo", headerName: "Currency To", width: width > 1370 ? 140 : 120, },
      { field: "currencyValue", headerName: "Exchange Rate", width: width > 1370 ? 140 : 120, },
      { field: "sourceInfo", headerName: "Source Information", width: width > 1370 ? 140 : 120, },
    ];
    
      // In rows which is a array, we are pushing data which is structured in frontEnd so, that we can pass it to dataGrid
    
      let rows: any = currencyDataBaseList && currencyDataBaseList?.map((ideaItem: any, index: any) => {
        let objShared: any = {
          id: ideaItem?.id,
          flag: ideaItem?.flag,
          name: ideaItem?.name,
          countryName: ideaItem?.country_name,
          currencyFrom: ideaItem?.currency_from,
          currencyTo: ideaItem?.currency_to,
          currencyValue: ideaItem?.manual_value,
          sourceInfo: ideaItem?.source_info,
          currencySymbol: ideaItem?.symbol,
          currency_id : ideaItem?.currency_from_id,
        };
        return objShared
      });
    
      // dataStructureDataGrid is the total data format which is ready to pass to the dataGrid Props
    
      let dataStructureDataGrid: any = {
        columns: columns,
        rows: rows,
      };

    const handleSubmit = () => {
      if(AssumtionIds && AssumtionIds.length>0){
        if(DataBaseStatus && DataBaseStatus?.is_currency==true){
          BoughtOutmodal.open()
        }else{
          onSubmit(selection[0]);
        }
      }else{
        onSubmit(selection[0]);
      }
     
        // if (!selection) return console.log("Select an item");
         
      
    };
    const BoughtOutOthermodalHandler = () => {
      onSubmit(selection[0]);
  }



  return (
    <Modal
        className={styles.container}
        open={isOpen}
        onClose={onClose}
    >
     
        <Box sx={style}>
     
        {
           BoughtOutmodal.isOpen &&
           <CurrencyAssumptionModal


           onCloseModal={BoughtOutmodal.close}
           isOpen={BoughtOutmodal.isOpen}
           CalculatorIds={calculatorId}
           SelectedItem={selection}
           assumptionList={AssumtionIds}
           ApplyCostType={BoughtOutOthermodalHandler}
           currencyDataBaseList={currencyDataBaseList}
           userInput={userInput}



           />
      }
        
            <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>

          
              <Typography
                style={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  width: "100%",
                  textAlign: "center",
                  marginRight: '-2rem'
                }}
              >
                <span>Currency Database Selection</span> {selection?.length > 1 && <span style={{ marginLeft: '0.5rem', color: 'red' }}>( *Please select only one row ) </span>}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '1rem', height: '2.5rem' }}>
                {selection?.length > 0 &&
                  <><Box sx={{ width: "10rem" }}>

                    <Typography>Selected Item: <span style={{ color: "green" }}>{selection?.length}</span></Typography>
                  </Box>
                    <Button size='small' variant='contained' sx={{
                      cursor: "pointer",
                      lineHeight: 1,
                      padding: '0.5rem 1rem',
                      "&:hover": {
                        transform: 'Scale(1.05)',
                        transition: 'transform 0.5s ease',
                      },
                    }} onClick={handleSubmit} disabled={selection?.length > 1}>
                      Fetch
                    </Button></>}
                <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onClose} />
              </Box>
            </Box><Divider
                sx={{
                  borderColor: "primary.light",
                  margin: "0 1rem",
                  marginBottom: "1rem",
                }} /><Box sx={{ maxHeight: '80vh' }}
                  adl-scrollbar="true"
                  adl-scrollbar-width="0.3">
                {loader ? <><Skeleton /><Skeleton /><Skeleton /><Skeleton /><Skeleton /><Skeleton /><Skeleton /><Skeleton /><Skeleton /><Skeleton /><Skeleton /></> : <>
                  <Box sx={{ height: "90vh", width: "100%" }}>
                    <DataGrid
                      headerHeight={48}
                      rowHeight={40}
                      {...dataStructureDataGrid}
                      disableSelectionOnClick={true}
                      components={{
                        // Toolbar: () => {
                        //   return CustomToolbar(fetchDataFromDb);
                        // },
                        noRowsOverlay: CustomNoRowsOverlay,
                      }}
                      sx={{
                        "& ::-webkit-scrollbar": {
                          width: "0.3rem",
                          height: "0.3rem",
                        },
                        "& ::-webkit-scrollbar-thumb": {
                          // background: $thumb-color;
                          backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                          borderRadius: "10px",
                        },
                        "& ::- webkit-scrollbar-thumb: hover ": {
                          // width: "4px",
                          backgroundColor: "#045DE9 !important",
                        },
                        "::-webkit-scrollbar-track": {
                          backgroundColor: "#e1e1f3 !important",
                        },
                        "&.MuiDataGrid-root": {
                          border: 'none',
                        },
                        "&.MuiDataGrid-root .MuiDataGrid-cell": {
                          borderBottomColor: "primary.light",
                        },
                        "& .super-app-theme--cell": {
                          backgroundColor: "#ddefff !important",
                        },
                      }}
                      density={"compact"}
                      pageSize={100}
                      rowsPerPageOptions={[100]}
                      checkboxSelection
                      // @ts-ignore
                      onSelectionModelChange={(ids) => {
                        const selectedIDs = new Set(ids);
                        setSelection(ids);
                      } }
                      disableMultipleRowSelection
                      disableRowSelectionOnClick />
                  </Box>

                </>}
              </Box>
        </Box>
    </Modal>)
}

export default DatabaseCurrencySelectionModel;