import React, { useCallback, useContext, useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";

import {
  Box,
  Button,
  Checkbox,
  Divider,
  IconButton,
  Skeleton,
  Typography,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import Table from "@mui/material/Table";
import { TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { API } from "../../api-services";

import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import styles from "./BomConfiguration.module.scss";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { LoadingButton } from "@mui/lab";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useHistory } from "react-router-dom";
import VerifiedIcon from "@mui/icons-material/Verified";
import {
  CellNameFive,
  CellNameOne,
  CellNameSecond,
  CellNameThird,
} from "./BomTemplatedHeader";
import BomConfigurationsRowrender from "./BomConfigurationsRowrender";
import AddCostModelParameterModal from "./AddCostModelParameterModal";

import AddBomParameterModals from "./AddBomParameterModal";
import PlaylistAddOutlinedIcon from "@mui/icons-material/PlaylistAddOutlined";
import { useBaseModal } from "../../ComponentUI/SearchPage/useBaseModal";
import Swal from "sweetalert2";
import EditSequenceModal from "./EditSequenceModal";
import TableDataTypeModal from "./TableDataTypeModal";
import { BomTemplateinfoModal } from "./BomTemplateinfoModal";
import { UserContext } from "./BomConfigurationLanding";
import { set } from "lodash";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// @ts-ignore

const useStyles = makeStyles((theme) => ({
  /** Changed modalStyle */
  ModalRoot: {
    backgroundColor: "rgba(111, 126, 140, 0.2) !important",
    backdropFilter: "blur(1px) !important",
  },
}));

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
  transition: "none !important", // Disable the transition animation
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem", color: 'primary.main' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
interface FeatureParameterModalProps {
  setParentCount: any;
  CategoryList: any;
  ParentCount: any;
  setDeleteCounter: any;
  Counter: any,
  setCounter: any;
}

const BomConfiguration = (props: FeatureParameterModalProps) => {
  const {
    projectId,
    projectname,
    categoryId,
    productId,
    datatype,
    validationId,
    categoryname,
  } = useRouteParams<any>();
  const userpremission = useContext(UserContext);
  const { setParentCount, CategoryList, ParentCount, setDeleteCounter, Counter, setCounter } = props;
  // console.log(CategoryList, "CategoryList");
  const classes = useStyles();
  const [ParameterData, setParameterData] = React.useState<any>([]);
  const [expanded, setExpanded] = React.useState<any>(null);
  const [dataTypes, setDataTypes] = React.useState<any>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [isLoadingcategory, setIsLoadingcategory] =
    React.useState<boolean>(false);

  const [tableItem, settableItem] = useState<any>({});
  const [DataList, setDataList] = useState<any>([]);
  const addeditParametreModal = useBaseModal();
  const TableParametreModal = useBaseParamsModal()
  const infoModal = useBaseModal()
  const addedittSequenceModal = useBaseModal();
  const addcostmodalparameterModal = useBaseModal();
  // const [Counter, setCounter] = useState<any>(0);
  const [workstationList, setWorkStationList] = useState<any>([]);
  const [validationdata, setValidationdata] = useState<any>();
  const [UpdatedCounter, setUpdatedCounter] = useState<any>(0);
  const [selectedTab, setSelectedTab] = React.useState('');
  const history = useHistory<any>();
  useEffect(() => {
    setIsLoadingcategory(true);
    API.get("/config/parameter_datatype_config/", {
      product: productId,
      module: 6,
      category: categoryId,
    })
      .then((res: any) => {
        // setDataList(res?.data);
        // setIsLoadingcategory(false);

        // Sort the received data by parameter_group in ascending order
        let sortedData = res?.data.sort((a: any, b: any) => a.parameter_group - b.parameter_group);
        // sortedData.push({"parameter__data_type": "Cost Model",
        // "count": 0,
        // "parameter_group": 3 })

        setDataList(sortedData);
        setIsLoadingcategory(false);
      })
      .catch((err: any) => {
        console.log("Sever error");
        setIsLoadingcategory(false);
      });
  }, [categoryId, Counter]);

  // const handleChange = (name: any) => {
  //   // setExpanded(name !== expanded ? name : null);

  //   // setTabVale(name);
  //   setSelectedTab(name)
  //   history.push(
  //     `/bomconfiguration/${projectId}/${projectname}/${productId}/${categoryId}/${categoryname}/${validationId}/${name}`
  //   );
  // };

  useEffect(() => {
    const checkboxvalue =
      CategoryList &&
      CategoryList?.filter((item: any, index: any) => {
        return item?.category == categoryId;
      })[0];

    setValidationdata(checkboxvalue?.is_validated);
  }, [CategoryList, categoryId]);

  // console.log(userpremission && userpremission?.includes("U"), "userpremission")

  useEffect(() => {
    if (datatype !== 0) {
      setSelectedTab(datatype);
    } else {
      setSelectedTab("0");
    }
  }, [categoryId, datatype]);

  useEffect(() => {
    setIsLoading(true);
    setParameterData([]);
    if (datatype == "Cost Model") {
      API.get("/xcpep/bom_calculator_config/", {
        category_id: categoryId,
        top_vault_id: productId,
      }, 0)
        .then((res: any) => {
          setIsLoading(false);
          setParameterData(res?.data);
        })
        .catch((err: any) => {
          setIsLoading(false);
        });
    }
    else {
      API.get("/config/parameter_mapping/", {
        top_vault: productId,
        module: 6,
        project: projectId,
        category: categoryId,
      })
        .then((res: any) => {
          setIsLoading(false);
          var FilterData =
            res?.data &&
            res?.data.filter((item: any, index: any) => {
              return item?.data_type == datatype;
            });
          setParameterData(FilterData);
        })
        .catch((err: any) => {
          setIsLoading(false);
        });
    }
  }, [datatype, Counter, ParentCount, UpdatedCounter]);

  const columnsFiled = CellNameOne.includes(datatype) ? (
    <TableHead
      sx={{
        backgroundColor: "primary.light",
        color: "white",
        position: "sticky",
        top: "0",
        zIndex: "1",
      }}

    >
      <TableRow>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light', width: '5rem' }}>
          Sequence
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light', lineHeight: 1 }}>
          Parameter Name
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Description
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Unit
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Exceptions
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light', lineHeight: 1 }}>
          Allowed Values
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light', lineHeight: 1 }}>
          Prohibited Values
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light', lineHeight: 1 }}>
          Default Values
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Guideline
        </TableCell>

        {/* <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light', lineHeight: 1 }}>
          Show in Tag
        </TableCell> */}
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light', lineHeight: 1 }}>
          Is required?
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Work Station
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", textAlign: 'center', borderBottomColor: 'primary.light' }}>
          Actions
        </TableCell>
      </TableRow>
    </TableHead>
  ) : CellNameSecond.includes(datatype) ? (
    <TableHead
      sx={{
        backgroundColor: "primary.light",
        color: "white",
        position: "sticky",
        top: "0",
        zIndex: "1",
      }}>
      <TableRow>

        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light', width: '5rem' }}>
          Sequence
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light', lineHeight: 1 }}>
          Parameter Name
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Description
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Guideline
        </TableCell>
        {datatype == "database" &&
          <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
            Data Base
          </TableCell>
        }
        {/* <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light', lineHeight: 1 }}>
          Show in Tag
        </TableCell> */}
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light', lineHeight: 1 }}>
          Is required?
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light', lineHeight: 1 }}>
          Work Station
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", textAlign: 'center', borderBottomColor: 'primary.light' }}>
          Actions
        </TableCell>
      </TableRow>
    </TableHead>
  ) : CellNameThird.includes(datatype) ? (
    <TableHead
      sx={{
        backgroundColor: "primary.light",
        color: "white",
        position: "sticky",
        top: "0",
        zIndex: "1",
      }}>
      <TableRow>

        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light', width: '5rem' }}>
          Sequence
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light', lineHeight: 1 }}>
          Parameter Name
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Description
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Unit
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Exceptions
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light', lineHeight: 1 }}>
          Allowed Values
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Guideline
        </TableCell>
        {/* <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light', lineHeight: 1 }}>
          Show in Tag
        </TableCell> */}
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light', lineHeight: 1 }}>
          Is required?
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light', lineHeight: 1 }}>
          Work Station
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", textAlign: 'center', borderBottomColor: 'primary.light' }}>
          Actions
        </TableCell>
      </TableRow>
    </TableHead>
  ) : CellNameFive.includes(datatype) ? (<TableHead
    sx={{
      backgroundColor: "primary.light",
      color: "white",
      position: "sticky",

      top: "0",
      zIndex: "1",
    }}>
    <TableRow>

      <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light', width: '5rem' }}>
        Sequence
      </TableCell>
      <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light', lineHeight: 1 }}>
        Parameter Name
      </TableCell>
      <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
        Description
      </TableCell>
      {/* <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
        Guideline
      </TableCell> */}
      {/* <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light', lineHeight: 1 }}>
        Show in Tag
      </TableCell> */}
      <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light', lineHeight: 1 }}>
        Is required?
      </TableCell>
      {/* <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light', lineHeight: 1 }}>
        Work Station
      </TableCell> */}
      <TableCell sx={{ padding: "0.2rem 0.5rem", textAlign: 'center', borderBottomColor: 'primary.light' }}>
        Actions
      </TableCell>
    </TableRow>
  </TableHead>) : (
    <TableHead
      sx={{
        backgroundColor: "primary.light",
        color: "white",
        position: "sticky",

        top: "0",
        zIndex: "1",
      }}>
      <TableRow>

        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light', width: '5rem' }}>
          Sequence
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light', lineHeight: 1 }}>
          Parameter Name
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Description
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
          Guideline
        </TableCell>
        {/* <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light', lineHeight: 1 }}>
          Show in Tag
        </TableCell> */}
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light', lineHeight: 1 }}>
          Is required?
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light', lineHeight: 1 }}>
          Work Station
        </TableCell>
        <TableCell sx={{ padding: "0.2rem 0.5rem", textAlign: 'center', borderBottomColor: 'primary.light' }}>
          Actions
        </TableCell>
      </TableRow>
    </TableHead>
  );
  const Parameterhandler = () => {
    if (datatype == "Cost Model") {
      addcostmodalparameterModal.open();
    }
    else {
      addeditParametreModal.open();
    }
  };

  useEffect(() => {
    API.get(`/config/workstation/`, {
      map_parameter: true,
      module: 6,
      top_vault: productId,
    })
      .then((res: any) => {
        setWorkStationList(res.data);
      })
      .catch((err: any) => {
        console.log("Sever Error");
      });
  }, [datatype]);

  const handleChangeCheckBox = (type?: any) => {
    if (type == "checkbox") {
      const checkboxvalue =
        CategoryList &&
        CategoryList?.filter((item: any, index: any) => {
          return item?.category == categoryId;
        })[0];
      API.patch("/config/template_config/", {
        id: checkboxvalue?.id,
        validated: !validationdata,
        top_vault:
          validationId == 0 ? productId?.toString() : validationId?.toString(),
      })
        .then((res: any) => {
          setValidationdata(res?.data?.validated);
          setParentCount((prev: any) => prev + 1);
          setDeleteCounter((prev: any) => prev + 1);
        })
        .catch((err: any) => {
          const { data } = err?.response;
          setValidationdata(false);

          Swal.fire({
            icon: "error",
            html: `<div>
      <br />
      <p style="color:red;">${data[0]}</p>   
       </div>`,
          });
        });
    } else {
      const checkboxvalue =
        CategoryList &&
        CategoryList?.filter((item: any, index: any) => {
          return item?.category == categoryId;
        })[0];
      API.patch("/config/template_config/", {
        id: checkboxvalue?.id,
        validated: false,
        top_vault:
          validationId == 0 ? productId?.toString() : validationId?.toString(),
      })
        .then((res: any) => {
          setValidationdata(res?.data?.validated);
          setParentCount((prev: any) => prev + 1);
          setDeleteCounter((prev: any) => prev + 1);
        })
        .catch((err: any) => {
          const { data } = err?.response;
          setValidationdata(false);
          Swal.fire({
            icon: "error",
            html: `<div>
            <br />
            <p style="color:red;">${data[0]}</p>   
            </div>`,
                });
        });
    }
  };


  const tablecallBackhandler = ((item: any) => {


    settableItem(item)
    TableParametreModal.open()
  })
  const InfoModalhandler = ((item: any) => {
    infoModal.open()
    settableItem(item)
  })
  const SequenceHandler = () => {
    addedittSequenceModal.open();
  };



  return (
    <Box>
      {addeditParametreModal.isOpen && (
        <AddBomParameterModals
          isOpen={addeditParametreModal.isOpen}
          onCloseModal={addeditParametreModal.close}
          setParentCount={setParentCount}
          setCount={setCounter}
          handleChangeCheckBox={handleChangeCheckBox}
        />
      )}

      {infoModal.isOpen &&
        <BomTemplateinfoModal

          onCloseModal={infoModal.close}
          isOpen={infoModal.isOpen}
          Item={tableItem}


        />
      }
      {TableParametreModal?.isOpen && <TableDataTypeModal
        setUpdatedCounter={setUpdatedCounter}
        handleChangeCheckBox={handleChangeCheckBox}
        tableItem={tableItem}
        isOpen={TableParametreModal.isOpen}
        onCloseModal={TableParametreModal.close}
        setParentCount={setParentCount}
        setCount={setCounter}
      />}
      {addedittSequenceModal?.isOpen && (
        <EditSequenceModal
          InfoModalhandler={InfoModalhandler}
          handleChangeCheckBox={handleChangeCheckBox}
          isOpen={addedittSequenceModal.isOpen}
          onCloseModal={addedittSequenceModal.close}
          setParentCount={setParentCount}
          setCount={setCounter}
        />
      )}
      {
        addcostmodalparameterModal?.isOpen && <AddCostModelParameterModal
          isOpen={addcostmodalparameterModal.isOpen}
          onCloseModal={addcostmodalparameterModal.close}
          setCounter={setCounter}
          handleChangeCheckBox={handleChangeCheckBox}


        />
      }

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between;",
          margin: "auto 1rem",
          alignItems: "center",
          columnGap: '0.5rem',
          height: '2.5rem',
        }}>
        <Typography sx={{ fontSize: '1rem' }}> {(categoryname !== 0 && categoryname != "undefined") && categoryname}</Typography>
        <Box sx={{ display: "flex", alignItems: "center", columnGap: '0.5rem', }}>
          {isLoadingcategory
            ? ""
            : validationdata !== undefined && (
              <Box sx={{ width: '2rem', textAlign: 'center', }}>
                <Checkbox
                  style={{ padding: '3px' }}
                  checkedIcon={
                    <Box
                      sx={{
                        backgroundColor: "white",
                        borderRadius: "50%",
                        height: "1rem",
                        width: "1rem",
                      }}>
                      <VerifiedIcon
                        sx={{
                          color: "#39FF14",
                          position: "relative",
                          margin: "-0.35rem -0.25rem 0",
                        }}
                      />
                    </Box>
                  }
                  onChange={(e) => handleChangeCheckBox("checkbox")}
                  checked={validationdata}
                  title="Validated"
                  color="success"
                  sx={{
                    "& .MuiSvgIcon-root": { fontSize: 22 },
                    color: "#007fff",
                  }}
                />
              </Box>
            )}

          {!userpremission && userpremission?.includes("U") ?
            <PlaylistAddOutlinedIcon sx={{ fontSize: '2rem', opacity: 0.3 }} /> :
            <PlaylistAddOutlinedIcon titleAccess="Add BOM Parameter" sx={{ fontSize: '2rem', cursor: "pointer", opacity: 1 }} onClick={() => Parameterhandler()} />}

          <Button sx={{ lineHeight: 1 }} disabled={userpremission && userpremission?.includes("U") ? false : true} onClick={() => SequenceHandler()}>Sequence</Button>
        </Box>
      </Box>
      <Box
        sx={{ height: { lg: "95%", xl: "89%" }, width: "100%" }}
        adl-scrollbar="true"
        adl-scrollbar-width="0.3">
        {isLoadingcategory ? (
          <Box sx={{ padding: '0 1rem' }}>
            <Skeleton sx={{ height: '3rem' }}></Skeleton>
            <Skeleton sx={{ height: '3rem' }}></Skeleton>
            <Skeleton sx={{ height: '3rem' }}></Skeleton>
            <Skeleton sx={{ height: '3rem' }}></Skeleton>
            <Skeleton sx={{ height: '3rem' }}></Skeleton>
            <Skeleton sx={{ height: '3rem' }}></Skeleton>
            <Skeleton sx={{ height: '3rem' }}></Skeleton>
            <Skeleton sx={{ height: '3rem' }}></Skeleton>
            <Skeleton sx={{ height: '3rem' }}></Skeleton>
            <Skeleton sx={{ height: '3rem' }}></Skeleton>
            <Skeleton sx={{ height: '3rem' }}></Skeleton>
            <Skeleton sx={{ height: '3rem' }}></Skeleton>
            <Skeleton sx={{ height: '3rem' }}></Skeleton>
            <Skeleton sx={{ height: '3rem' }}></Skeleton>
            <Skeleton sx={{ height: '3rem' }}></Skeleton>
            <Skeleton sx={{ height: '3rem' }}></Skeleton>
            <Skeleton sx={{ height: '3rem' }}></Skeleton>
            <Skeleton sx={{ height: '3rem' }}></Skeleton>
            <Skeleton sx={{ height: '3rem' }}></Skeleton>
          </Box>
        ) : (
          <>
            <Box
              sx={{ maxHeight: { lg: "82vh", xl: "87vh" }, }}
              adl-scrollbar="true"
              adl-scrollbar-width="0.3">
              <Box sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: 'flex-start',
                margin: "0.3rem",
                columnGap: "0.5rem",
              }}>
                {DataList && DataList?.length == 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "28rem ",
                      fontWeight: "bold",
                      marginRight: "29rem"


                    }}>
                    <Typography>Add Category/Add Parameter </Typography>
                  </Box>
                )}
              </Box>
              <Box
                sx={{ maxHeight: { lg: "76vh", xl: "82vh" }, }}
                adl-scrollbar="true"
                adl-scrollbar-width="0.3">
                {isLoading ? (
                  <Box sx={{ width: '100%' }}>
                    <Table>
                      <TableRow>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                          <Box>
                            <Skeleton></Skeleton>
                          </Box>
                        </TableCell>
                      </TableRow>
                    </Table>
                  </Box>
                ) : (
                  <Table>
                    {columnsFiled}
                    <TableBody>
                      {selectedTab == "0" ? (
                        <>
                          <TableRow><TableCell colSpan={7} sx={{ borderBottom: 'none', padding: '0' }}>
                            <Box sx={{ width: '100%', height: { lg: '70vh', xl: '78vh' }, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <Typography >Select Category</Typography>
                            </Box></TableCell></TableRow>
                        </>)
                        :
                        <>
                          {ParameterData &&
                            ParameterData?.map(
                              (rowvalue: any, index1: any) => {
                                return (
                                  <>
                                    <BomConfigurationsRowrender
                                      setUpdatedCounter={
                                        setUpdatedCounter
                                      }
                                      rowItem={rowvalue}
                                      keys={index1}
                                      handleChangeCheckBox={
                                        handleChangeCheckBox
                                      }
                                      CategoryList={CategoryList}
                                      InfoModalhandler={InfoModalhandler}
                                      tablecallBackhandler={tablecallBackhandler}
                                      workstationList={workstationList}
                                      setCounter={setCounter}
                                      setParentCount={setParentCount}
                                      validationdata={validationdata}
                                    />
                                  </>
                                );
                              }
                            )}
                        </>}
                    </TableBody>
                  </Table>
                )}
              </Box>
            </Box>
          </>
        )}
      </Box>
    </Box>
  );
};
export default BomConfiguration;
