// vendors
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import flaggedAssumptionService from "../Services/flaggedAssumption.service";
import { ID } from "../../utlis/commonInterfaces";
import { FlaggedAssumption } from "../Reducers/flaggedAssumption.reducer";

// interfaces
// import { ID } from "app/commonInterfaces";

// // services
// import flaggedAssumptionService from "app/services/flaggedAssumption.service";

// // redux
// import { FlaggedAssumption } from "app/Redux/Reducers/flaggedAssumption.reducer";

// -------------------------------------------------------------------------------------
// api actions
// -------------------------------------------------------------------------------------

export interface GetFlaggedAssumptionsArg {
    userInputId: ID;
}
const getFlaggedAssumptions = createAsyncThunk(
    "flaggedAssumption/get",
    async ( arg: GetFlaggedAssumptionsArg, { rejectWithValue } ) => {
        try {
            const { data } = await flaggedAssumptionService.get( arg );

            return {
                flaggedAssumptions: data as FlaggedAssumption[]
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

// -------------------------------------------------------------------------------------

export interface CreateFlaggedAssumptionArg {
    userInputId: ID;
    inputId: ID;
}
const createFlaggedAssumption = createAsyncThunk(
    "flaggedAssumption/create",
    async ( arg: CreateFlaggedAssumptionArg, { rejectWithValue } ) => {
        try {
            const { data } = await flaggedAssumptionService.create( arg );

            return {
                flaggedAssumption: data as FlaggedAssumption
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

// -------------------------------------------------------------------------------------

export interface UpdateFlaggedAssumptionArg {
    id: ID;
    image: File | null;
}
const updateFlaggedAssumption = createAsyncThunk(
    "flaggedAssumption/update",
    async ( arg: UpdateFlaggedAssumptionArg, { rejectWithValue } ) => {
        try {
            const { data } = await flaggedAssumptionService.update( arg );

            return {
                flaggedAssumption: data as FlaggedAssumption
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

// -------------------------------------------------------------------------------------

export interface DeleteFlaggedAssumptionArg {
    id: ID;
}
const deleteFlaggedAssumption = createAsyncThunk(
    "flaggedAssumption/delete",
    async ( arg: DeleteFlaggedAssumptionArg, { rejectWithValue } ) => {
        try {
            await flaggedAssumptionService.delete( arg );

            return {};
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

// -------------------------------------------------------------------------------------
// reducer actions
// -------------------------------------------------------------------------------------

const resetFlaggedAssumptions = createAction( "flaggedAssumption/reset" );

// -------------------------------------------------------------------------------------
// exports
// -------------------------------------------------------------------------------------

export {
    getFlaggedAssumptions,
    createFlaggedAssumption,
    updateFlaggedAssumption,
    deleteFlaggedAssumption,
    resetFlaggedAssumptions
};