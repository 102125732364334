import React, { useEffect, useRef, useState } from "react";
import styles from "./InwarLandingPage.module.scss";
import AddIcon from "@mui/icons-material/Add";
import { useParams } from "react-router-dom";
import { API } from "../../api-services";
import InwardInputCard from "./InwardInputCard";
import Box from "@mui/material/Box";
import Image from "../../ViewModule/FeatureLandingPage/ArchitectureImageCard";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import loaderImage from "../../Assets/images/loading-screen.gif";
import VisibilityIcon from "@mui/icons-material/Visibility";
import ImageS3Upload from "./InwardS3images";
import InwardS3images from "./InwardS3images";
import { Button, Checkbox, IconButton, Skeleton, Typography } from "@mui/material";
import RViewerJS from "viewerjs-react";
import { useBaseModal } from "../SearchPage/useBaseModal";
import InwardDocumentModal from "./InwardDocumentModal";
import EditIcon from "@mui/icons-material/Edit";
import InwardImageEditorModal from "./InwardImageEditorModal";
import { boolean } from "yup";
import UploadOutlinedIcon from '@mui/icons-material/UploadOutlined';
import UploadIcon from "@mui/icons-material/Upload";
import VerifiedIcon from '@mui/icons-material/Verified';


import noImage from "../../Assets/images/noImage.png";
import { LoadingButton } from "@mui/lab";
import ValidationModal from "./ValidationModal";
import InwardPdfNameModal from "./InwardPdfNameModal";
import { isUndefined } from "lodash";
const label = { inputProps: { "aria-label": "Checkbox demo" } };
interface InwardParameterdataProps {
  Premissiondata: any;
}

const InwardParameterdata = (props: InwardParameterdataProps) => {
  const { Premissiondata } = props;
  const { projectId, projectname, productId } = useParams<any>();
  const [ProductDetails, setProdiuctDetails] = useState<any>([]);

  const [ProductReceivedItem, setProductReceivedItem] = useState<any>([]);

  const [RemarksItem, setRemarksItem] = useState<any>([]);
  const [DamagesData, setDamagesData] = useState<any>([]);
  const [MissingData, setMissingData] = useState<any>([]);

  const [ProductStatus, setProductStatus] = useState<any>();
  const [ReceivedStatus, setReceivedStatus] = useState<any>(false);
  const [RemarkStatus, setRemarksStatus] = useState<any>();
  const [DamagesStatus, setDamagesStatus] = useState<any>(false);

  const [MissingStatus, setMissingStatus] = useState<any>(false);
  const [FunctionalitiesStatus, setFunctionalitiesStatus] =
    useState<any>(false);
  const [ImageList, setImageList] = useState<any>();

  const [FunctionalitiesData, setFunctionalitiesData] = useState<any>([]);

  const [ReceivedCounter, setReceivedCounter] = useState<any>(0);
  const [DamageCounter, setDamageCounter] = useState<any>(0);
  const [MissinCounter, setMissinCounter] = useState<any>(0);
  const [FunctionalitiesCounter, setFunctionalitiesCounter] = useState<any>(0);
  const [ProductCounter, setProductCounter] = useState<any>(0);
  const [RemarkCounter, setRemarkCounter] = useState<any>(0);
  const [ImageOldUrl, setImageOldUrl] = useState<any>();
  const [ImageNamedata, setImageNamedata] = useState<any>();
  const [DocumentsList, setDocumentsList] = useState<any>([]);
  const [imgUploadCounter, setImageUploadCounter] = useState<any>(0);
  const [CheckboxdataMsg, setCheckboxdataMsg] = useState<any>();
  const [imageLoder, setImageLoader] = useState<any>(false);

  const [DocName, setDocName] = useState<any>()
  const inputElRef = useRef<any>(null);

  const inputElRefWithout = useRef<any>(null);
  const [ProductLoader, setProductLoader] = useState<any>(false);
  const [ReceivedLoader, setReceivedLoader] = useState<any>(false);

  const [checkstatusProduct, setcheckstatusProduct] = useState<any>(false);
  const [checkstatusReceived, setcheckstatusReceived] = useState<any>(false);
  const [checkstatusDoc, setcheckstatusDoc] = useState<any>(false);
  const [DamageLoader, setDamageLoader] = useState<any>(false);
  const [FunctionalitiesLoader, setFunctionalitiesLoader] =
    useState<any>(false);
  const [RemarkLoader, setRemarkLoader] = useState<any>(false);
  const [MissingItemLoader, setMissingLoader] = useState<any>(false);
  const [ImageItemLoader, setmageItemLoader] = useState<any>(false);
  const [checkstatusImage, setcheckstatusImage] = useState<any>(false)
  const [DocumentCounter, setDocumentCounter] = useState<any>(0);
  const [DocList, setDocList] = useState<any>();
  const Documentmodal = useBaseModal();
  const inputElRefPdf = useRef<any>();
  const inputElRefPdf2 = useRef<any>();
  const EditModal = useBaseModal();
  const pdfnamemodal = useBaseModal()
  const [docLoader, setDocLoder] = useState<any>(false);
  const [docLoader2, setDocLoder2] = useState<any>(false);
  const Validationmodal = useBaseModal();
  const [FileData, setFiledata] = useState<any>()
  const [FileData2, setFiledata2] = useState<any>()
  const [fileCondition, setFileCondition] = useState<any>()
  const [Name, setName] = useState<any>()
  const browseFiles = (imagesUrl: any, imageName: any, actiontype: any) => {
    if (actiontype == "withoutImage") {
      setImageOldUrl(imagesUrl);
      setImageNamedata(imageName);

      return inputElRefWithout.current?.click?.();
    } else {
      setImageOldUrl(imagesUrl);
      setImageNamedata(imageName);

      return inputElRef.current?.click?.();
    }
  };

  const documentHandlerFile = (docname: any, fileurl: any) => {
    setDocList({ parametername: docname, file: fileurl });

    return inputElRefPdf.current?.click?.();
  };

  const documentHandlerFile2 = (docname: any, fileurl: any) => {
    setDocList({ parametername: docname, file: fileurl });

    return inputElRefPdf2.current?.click?.();
  };
  const uploadImage = (e: any) => {
    var oldUrlSplit =
      ImageOldUrl == "null" ? "" : ImageOldUrl?.split(".net")[1];

    var filedata = e?.target?.files[0];

    InwardS3images(
      [filedata],
      oldUrlSplit,
      ImageNamedata,
      setImageUploadCounter,
      productId,
      setImageLoader,
      null,
      null
    );
  };

  const UploadImageWithout = (e: any) => {
    var oldUrlSplit =
      ImageOldUrl == "null" ? "" : ImageOldUrl?.split(".net")[1];

    var filedata = e?.target?.files[0];

    const formdata = new FormData();
    formdata.append("new_data", filedata);
    formdata.append("top_vault_id", productId);
    formdata.append(
      "old_data",
      ImageOldUrl == "null" ? "" : ImageOldUrl?.split(".net")[1]
    );
    formdata.append("parameter_name", ImageNamedata);
    // @ts-ignore
    formdata.append("rgb", true);

    if (e?.target?.files[0] !== null) {
      setImageLoader(true);

      API.put("/vehicle_inward/inward_view/", formdata, {}, 0).then(
        (res: any) => {
          setImageUploadCounter((prev: any) => prev + 1);
          setImageLoader(true);
        }
      );
    }
  };

  const UploadPDF = (e: any) => {



    var filedata = e?.target?.files[0];

    setFileCondition("file")

    if (filedata) {
      pdfnamemodal.open()
      setFiledata(e?.target?.files[0])



    }
  };


  const SubmitPdfFile = (updatefilename: any) => {
    var oldUrlSplit =
      DocList && DocList?.file == "null" ? "" : DocList?.file?.split(".net")[1];

    var docfile = { doc_name: updatefilename }


    var parameterName = DocList && DocList?.parametername;

    InwardS3images(
      [FileData],
      oldUrlSplit,
      parameterName,
      setDocumentCounter,
      productId,
      setDocLoder,
      null,
      docfile
    );
  }





  const UploadPDF2 = (e: any) => {
    // var oldUrlSplit =
    //   DocList && DocList?.file == "null" ? "" : DocList?.file?.split(".net")[1];
    var filedata = e?.target?.files[0];


    setFileCondition("file1")


    if (filedata) {
      pdfnamemodal.open()
      setFiledata2(e?.target?.files[0])



    }

    // setFiledata2
    // var parameterName = DocList && DocList?.parametername;

    // InwardS3images(
    //   [filedata],
    //   oldUrlSplit,
    //   parameterName,
    //   setDocumentCounter,
    //   productId,
    //   setDocLoder2,
    //   null,null
    // );
  };

  var Premission = Premissiondata && Premissiondata !== undefined && Premissiondata?.action[0];


  const SubmitPdfFile1 = (updatefilename: any) => {
    var oldUrlSplit =
      DocList && DocList?.file == "null" ? "" : DocList?.file?.split(".net")[1];

    var docfile = { doc_name: updatefilename }


    var parameterName = DocList && DocList?.parametername;

    InwardS3images(
      [FileData2],
      oldUrlSplit,
      parameterName,
      setDocumentCounter,
      productId,
      setDocLoder2,
      null,
      docfile
    );
  }


  const EditHandler = (image: any, name: any) => {
    setImageOldUrl(image);
    setImageNamedata(name);
    EditModal.open();
  };
  const DeleteImagehandler = (keys: any, value: any) => {
    setImageLoader(true);

    setImageNamedata(keys);
    API.delete(
      `/vehicle_inward/inward_view/`,
      {
        parameter_name: keys,
        top_vault_id: productId,
        data: value,
      },
      0
    )
      .then((res: any) => {
        setImageUploadCounter((prev: any) => prev + 1);
        setImageNamedata("");
        setImageLoader(false);
      })
      .catch((err: any) => {
        setImageNamedata("");
        setImageLoader(false);
      });
  };



  const Checkboxhandler = (e: any, checkboxtype: any) => {

    if (checkboxtype == "Product") {
      API.put("/vehicle_inward/inward_view/", {
        top_vault_id: productId,
        completed: e?.target?.checked,
        parameter_name: "product_details"
      }).then((res: any) => {

        // var {data} =res.response;
        // setCheckboxdataMsg({ msg: "Update Sucessfully", type: "sucess" })
        // Validationmodal.open()

        API.get(
          "/vehicle_inward/inward_view/",
          {
            top_vault_id: productId,
            parameter: "completed",
            parameter_key: "product_details"
          },
          0
        ).then((res: any) => {


          setcheckstatusProduct(res?.data[0]?.completed);
        });
      }).catch((err) => {

        var { data } = err.response
        setCheckboxdataMsg({ msg: data[0], type: "error" })
        Validationmodal.open()


      })
    }


    else if (checkboxtype == "Received") {
      API.put("/vehicle_inward/inward_view/", {
        top_vault_id: productId,
        completed: e?.target?.checked,
        parameter_name: "received_items_list"
      }).then((res: any) => {

        // const { data } = res.response;
        // Validationmodal.open()
        // setCheckboxdataMsg({ msg: "Update Sucessfully", type: "sucess" })
        API.get(
          "/vehicle_inward/inward_view/",
          {
            top_vault_id: productId,
            parameter: "completed",
            parameter_key: "received_items_list"
          },
          0
        ).then((res: any) => {
          setcheckstatusReceived(res?.data[0]?.completed);
        });
      }).catch((err: any) => {
        const { data } = err?.response;
        setCheckboxdataMsg({ msg: data[0], type: "error" })
        Validationmodal.open()

      })
    }


    else if (checkboxtype == "Images") {
      API.put("/vehicle_inward/inward_view/", {
        top_vault_id: productId,
        completed: e?.target?.checked,
        parameter_name: "image"
      }).then((res: any) => {

        // const { data } = res.response;
        // Validationmodal.open()
        // setCheckboxdataMsg({ msg: "Update Sucessfully", type: "sucess" })
        API.get(
          "/vehicle_inward/inward_view/",
          {
            top_vault_id: productId,
            parameter: "completed",
            parameter_key: "image"
          },
          0
        ).then((res: any) => {
          setcheckstatusImage(res?.data[0]?.completed);
        });
      }).catch((err: any) => {
        const { data } = err?.response;
        setCheckboxdataMsg({ msg: data[0], type: "error" })
        Validationmodal.open()

      })
    }

  }


  useEffect(() => {
    API.get(
      "/vehicle_inward/inward_view/",
      {
        top_vault_id: productId,
        parameter: "completed",
        parameter_key: "product_details"
      },
      0
    ).then((res: any) => {
      setcheckstatusProduct(res?.data[0]?.completed);
    });
  }, [productId]);

  useEffect(() => {
    API.get(
      "/vehicle_inward/inward_view/",
      {
        top_vault_id: productId,
        parameter: "completed",
        parameter_key: "image"
      },
      0
    ).then((res: any) => {
      setcheckstatusImage(res?.data[0]?.completed);
    });
  }, [productId]);

  // setcheckstatusImage
  useEffect(() => {
    API.get(
      "/vehicle_inward/inward_view/",
      {
        top_vault_id: productId,
        parameter: "completed",
        parameter_key: "upload_doc-1"
      },
      0
    ).then((res: any) => {
      setcheckstatusDoc(res?.data[0]?.completed);
    });
  }, [productId]);
  useEffect(() => {
    API.get(
      "/vehicle_inward/inward_view/",
      {
        top_vault_id: productId,
        parameter: "completed",
        parameter_key: "received_items_list"
      },
      0
    ).then((res: any) => {
      setcheckstatusReceived(res?.data[0]?.completed);
    });
  }, [productId]);

  useEffect(() => {
    if (ProductCounter == 0) {
      setProductLoader(true);
      API.get(
        "/vehicle_inward/inward_view/",
        {
          top_vault_id: productId,
          parameter: "product_details",
        },
        0
      )
        .then((res: any) => {
          var NewDataSet = res?.data?.product_details?.map(
            (item: any, index: any) => ({ ...item, status: false })
          );
          var statusdata = NewDataSet?.length > 0 ? true : false;
          setProductStatus(statusdata);
          setProdiuctDetails(NewDataSet);

          setProductLoader(false);
        })
        .catch((err: any) => {
          setProductLoader(false);
        });
    } else {
      API.get(
        "/vehicle_inward/inward_view/",
        {
          top_vault_id: productId,
          parameter: "product_details",
        },
        0
      )
        .then((res: any) => {
          var NewDataSet = res?.data?.product_details?.map(
            (item: any, index: any) => ({ ...item, status: false })
          );
          var statusdata = NewDataSet?.length > 0 ? true : false;
          setProductStatus(statusdata);
          setProdiuctDetails(NewDataSet);

          API.get(
            "/vehicle_inward/inward_view/",
            {
              top_vault_id: productId,
              parameter: "completed",
              parameter_key: "product_details"
            },
            0
          ).then((res: any) => {
            setcheckstatusProduct(res?.data[0]?.completed);
          });

          setProductLoader(false);
        })
        .catch((err: any) => { });
    }
  }, [productId, ProductCounter]);

  useEffect(() => {
    if (ReceivedCounter == 0) {
      setReceivedLoader(true);
      API.get(
        "/vehicle_inward/inward_view/",
        {
          top_vault_id: productId,
          parameter: "received_items_list",
        },
        0
      )
        .then((res: any) => {
          var NewDataSet = res?.data?.received_items_list?.map(
            (item: any, index: any) => ({ ...item, status: false })
          );
          setReceivedLoader(false);
          setReceivedStatus(false);
          setProductReceivedItem(NewDataSet);
        })
        .catch((error: any) => {
          setReceivedLoader(false);
        });
    } else {
      API.get(
        "/vehicle_inward/inward_view/",
        {
          top_vault_id: productId,
          parameter: "received_items_list",
        },
        0
      )
        .then((res: any) => {
          var NewDataSet = res?.data?.received_items_list?.map(
            (item: any, index: any) => ({ ...item, status: false })
          );
          API.get(
            "/vehicle_inward/inward_view/",
            {
              top_vault_id: productId,
              parameter: "completed",
              parameter_key: "received_items_list"
            },
            0
          ).then((res1: any) => {
            setcheckstatusReceived(res1?.data[0]?.completed);
            // setcheckstatus(res?.data[0]?.completed);
          });
          setReceivedStatus(false);
          setProductReceivedItem(NewDataSet);
        })
        .catch((error: any) => { });
    }
  }, [productId, ReceivedCounter]);

  useEffect(() => {
    if (RemarkCounter == 0) {
      setRemarkLoader(true);

      API.get(
        "/vehicle_inward/inward_view/",
        {
          top_vault_id: productId,
          parameter: "remarks",
        },
        0
      )
        .then((res: any) => {
          var NewDataSet = res?.data?.remarks?.map((item: any, index: any) => ({
            ...item,
            status: false,
          }));

          var statusdata = NewDataSet?.length > 0 ? true : false;
          setRemarksStatus(statusdata);
          setRemarksItem(NewDataSet);
          setRemarkLoader(false);
        })
        .catch((err: any) => {
          setRemarkLoader(false);
        });
    } else {
      API.get(
        "/vehicle_inward/inward_view/",
        {
          top_vault_id: productId,
          parameter: "remarks",
        },
        0
      ).then((res: any) => {
        var NewDataSet = res?.data?.remarks?.map((item: any, index: any) => ({
          ...item,
          status: false,
        }));

        var statusdata = NewDataSet?.length > 0 ? true : false;
        setRemarksStatus(statusdata);
        setRemarksItem(NewDataSet);

        API.get(
          "/vehicle_inward/inward_view/",
          {
            top_vault_id: productId,
            parameter: "completed",
          },
          0
        ).then((res: any) => {
          // setcheckstatus(res?.data[0]?.completed);
        });
      });
    }
  }, [productId, RemarkCounter]);

  useEffect(() => {
    if (DamageCounter == 0) {
      setDamageLoader(true);
      API.get(
        "/vehicle_inward/inward_view/",
        {
          top_vault_id: productId,
          parameter: "any_damages",
        },
        0
      )
        .then((res: any) => {
          var NewDataSet = res?.data?.any_damages?.map(
            (item: any, index: any) => ({
              ...item,
              status: false,
            })
          );
          setDamagesData(NewDataSet);
          setDamageLoader(false);
          // setcheckstatus(false);
        })
        .catch((err: any) => {
          setDamageLoader(false);
        });
    } else {
      API.get(
        "/vehicle_inward/inward_view/",
        {
          top_vault_id: productId,
          parameter: "any_damages",
        },
        0
      ).then((res: any) => {
        var NewDataSet = res?.data?.any_damages?.map(
          (item: any, index: any) => ({
            ...item,
            status: false,
          })
        );
        API.get(
          "/vehicle_inward/inward_view/",
          {
            top_vault_id: productId,
            parameter: "completed",
          },
          0
        ).then((res: any) => {
          // setcheckstatus(res?.data[0]?.completed);
        });
        setDamagesData(NewDataSet);
      });
    }
  }, [productId, DamageCounter]);

  useEffect(() => {
    if (MissinCounter == 0) {
      setMissingLoader(true);
      API.get(
        "/vehicle_inward/inward_view/",
        {
          top_vault_id: productId,
          parameter: "missing_items",
        },
        0
      )
        .then((res: any) => {
          var NewDataSet = res?.data?.missing_items?.map(
            (item: any, index: any) => ({ ...item, status: false })
          );
          setMissingData(NewDataSet);
          setMissingLoader(false);
        })
        .catch((err: any) => {
          setMissingLoader(false);
        });
    } else {
      API.get(
        "/vehicle_inward/inward_view/",
        {
          top_vault_id: productId,
          parameter: "missing_items",
        },
        0
      ).then((res: any) => {
        var NewDataSet = res?.data?.missing_items?.map(
          (item: any, index: any) => ({ ...item, status: false })
        );
        API.get(
          "/vehicle_inward/inward_view/",
          {
            top_vault_id: productId,
            parameter: "completed",
          },
          0
        ).then((res: any) => {
          // setcheckstatus(res?.data[0]?.completed);
        });
        // setcheckstatus(false);
        setMissingData(NewDataSet);
      });
    }
  }, [productId, MissinCounter]);

  useEffect(() => {
    if (FunctionalitiesCounter == 0) {
      setFunctionalitiesLoader(true);
      API.get(
        "/vehicle_inward/inward_view/",
        {
          top_vault_id: productId,
          parameter: "non-working_functionalities",
        },
        0
      )
        .then((res: any) => {
          var NewDataSet = res?.data["non-working_functionalities"]?.map(
            (item: any, index: any) => ({ ...item, status: false })
          );

          setFunctionalitiesData(NewDataSet);
          setFunctionalitiesLoader(false);
        })
        .catch((err: any) => {
          setFunctionalitiesLoader(false);
        });
    } else {
      API.get(
        "/vehicle_inward/inward_view/",
        {
          top_vault_id: productId,
          parameter: "non-working_functionalities",
        },
        0
      ).then((res: any) => {
        var NewDataSet = res?.data["non-working_functionalities"]?.map(
          (item: any, index: any) => ({ ...item, status: false })
        );
        API.get(
          "/vehicle_inward/inward_view/",
          {
            top_vault_id: productId,
            parameter: "completed",
          },
          0
        ).then((res: any) => {
          // setcheckstatus(res?.data[0]?.completed);
        });
        setFunctionalitiesData(NewDataSet);
      });
    }
  }, [productId, FunctionalitiesCounter]);

  useEffect(() => {
    if (imgUploadCounter > 0) {
      API.get(
        "/vehicle_inward/inward_view/",
        {
          top_vault_id: productId,
          parameter: "images",
        },
        0
      ).then((res: any) => {
        setImageLoader(false);
        setImageList(res?.data?.images);

        API.get(
          "/vehicle_inward/inward_view/",
          {
            top_vault_id: productId,
            parameter: "completed",
            parameter_key: "image"
          },
          0
        ).then((res: any) => {
          setcheckstatusImage(res?.data[0]?.completed);
        });
        // setcheckstatus(false)
      });
    } else {
      setmageItemLoader(true);
      API.get(
        "/vehicle_inward/inward_view/",
        {
          top_vault_id: productId,
          parameter: "images",
        },
        0
      )
        .then((res: any) => {
          setmageItemLoader(false);
          setImageList(res?.data?.images);
          // setcheckstatus(false)
        })
        .catch((err: any) => {
          setmageItemLoader(false);
        });
    }
  }, [productId, imgUploadCounter]);

  useEffect(() => {
    if (DocumentCounter == 0) {
      API.get(
        "/vehicle_inward/inward_view/",
        {
          top_vault_id: productId,
          parameter: "documents",
        },
        0
      ).then((res: any) => {
        setDocumentsList(res?.data?.documents);
        // setcheckstatus(false)
      });
    } else {
      API.get(
        "/vehicle_inward/inward_view/",
        {
          top_vault_id: productId,
          parameter: "documents",
        },
        0
      ).then((res: any) => {
        setDocLoder(false);
        setDocLoder2(false);

        API.get(
          "/vehicle_inward/inward_view/",
          {
            top_vault_id: productId,
            parameter: "completed",
            parameter_key: "upload_doc-1"
          },
          0
        ).then((res: any) => {
          setcheckstatusDoc(res?.data[0]?.completed);
        });
        setDocList("");
        setDocumentsList(res?.data?.documents);

        // setcheckstatus(false)
      });
    }
  }, [productId, DocumentCounter]);

  const documentHandler = (docname: any, fileurl: any, fileName: any) => {
    setDocName(docname)
    setName(fileName)
    setDocList({ parametername: docname, file: fileurl });

    Documentmodal.open();
  };

  const AddnewRows = (plustype: any) => {
    if (plustype == "Product") {
      // @ts-ignore
      var data: any = { name: "", status: true };
      setProdiuctDetails([data, ...ProductDetails]);

      setProductStatus(true);
    } else if (plustype == "Remarks") {
      // @ts-ignore
      var data: any = { name: "", status: true };
      setRemarksStatus(true);
      setRemarksItem([data, ...RemarksItem]);
    } else if (plustype == "ReceivedItem") {
      // @ts-ignore
      var data: any = { name: "", status: true };
      setReceivedStatus(true);
      setProductReceivedItem([data, ...ProductReceivedItem]);
    } else if (plustype == "MissingItem") {
      setMissingStatus(true);
      // @ts-ignore
      var data: any = { name: "", status: true };
      setMissingData([data, ...MissingData]);
    } else if (plustype == "Functionalities") {
      setFunctionalitiesStatus(true);
      // @ts-ignore
      var data: any = { name: "", status: true };
      setFunctionalitiesData([data, ...FunctionalitiesData]);
    } else if (plustype == "Damages") {
      setDamagesStatus(true);
      // @ts-ignore

      var data: any = { name: "", status: true };
      setDamagesData([data, ...DamagesData]);
    }
  };

  const renderImages = (Itemdata: any) => {
    var ImagesField =
      Itemdata &&
      Object.entries(Itemdata).map(([key, value]) => {
        return (
          <div className={styles.imagescontainer}>
            {ImageNamedata == key && imageLoder ? (
              <Skeleton variant="rectangular" width={150} height={90} />
            ) :
              (
                (
                  <>
                    {value == "null" ? (
                      <>{key !== "defects-2" && key !== "accessories-2" ? <sup style={{ color: "red" }}>*</sup> : <sup style={{ color: "aliceblue" }}>*</sup>}
                        <Box sx={{ width: "100%", height: "120px", display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <Typography>{key}</Typography>
                        </Box>
                      </>

                    ) : (
                      <div title={key}>
                        <Image
                          placeholderImg={loaderImage}
                          // @ts-ignore
                          src={value && value}
                          style={{ cursor: "pointer", objectFit: "contain" }}
                          alt={key}
                          width="100%"
                          height={"120px"}
                        />
                      </div>
                    )}
                    {(Premission?.includes("C") || Premission?.includes("D") || Premission?.includes("U")) &&
                      <div className={styles.Buttonfield}>
                        {value !== "null" && (
                          <Button sx={{ minWidth: '1rem', padding: '3px' }} disabled={(Premission?.includes("C") || Premission?.includes("U")) ? false : true}>
                            <EditIcon
                              titleAccess="Edit"

                              sx={{ cursor: "pointer" }}
                              onClick={() => EditHandler(value, key)}
                            />
                          </Button>
                        )}
                        <Button sx={{ minWidth: '1rem', padding: '3px' }} disabled={(Premission?.includes("C") || Premission?.includes("U")) ? false : true}>
                          <FileUploadIcon
                            titleAccess="With background Image"

                            onClick={() => browseFiles(value, key, "withImage")}
                            sx={{ cursor: "pointer" }}
                          />

                        </Button>
                        <Button sx={{ minWidth: '1rem', padding: '3px' }} disabled={(Premission?.includes("C") || Premission?.includes("U")) ? false : true}>

                          <UploadOutlinedIcon
                            titleAccess="Without background Image"
                            onClick={() => browseFiles(value, key, "withoutImage")}
                            sx={{ cursor: "pointer" }}
                          />
                        </Button>

                        {(value !== "null" && Premission?.includes("D")) && (

                          <Button sx={{ minWidth: '1rem', padding: '3px' }}>
                            <HighlightOffIcon
                              titleAccess="Delete"
                              onClick={() => DeleteImagehandler(key, value)}
                              sx={{ cursor: "pointer", color: "red" }}
                            />
                          </Button>
                        )}
                      </div>
                    }
                  </>
                ))}
          </div>
        );
      });
    return ImagesField;
  };

  const renderIntoDocument = (documentItem: any, name: any) => {
    var documentData: any =
      documentItem &&
      Object.entries(documentItem)?.map(([key, value]) => {
        return (
          <>

            {key == "upload_doc-1" &&



              (value == "null" ? (
                <>

                  {(Premission?.includes("C") || Premission?.includes("D") || Premission?.includes("U")) &&
                    <div className={styles.documents_container}>
                      <Typography sx={{ fontSize: '1rem' }}>
                        Upload Document-1<sup style={{ color: "red" }}>*</sup>
                      </Typography>
                      <Box>

                        <LoadingButton
                          variant="contained"
                          title="Upload Document"
                          loading={docLoader}
                          disabled={(Premission?.includes("C") || Premission?.includes("U")) ? false : true}
                          onClick={() => documentHandlerFile("upload_doc-1", value)}
                          style={{ boxShadow: 'none !important' }}
                          sx={{
                            minWidth: '8px', padding: '0', border: 'none',
                            boxShadow: 'none',
                            "&:hover": {
                              backgroundColor: 'white',
                              borderRadius: '50%',
                              color: 'primary.main',
                            },
                            '.MuiCircularProgress-root': {
                              color: 'white',
                            },
                          }}
                        >
                          <UploadIcon
                            style={{ cursor: "pointer" }}
                          // onClick={() => documentHandlerFile("upload_doc-1", value)}
                          />
                        </LoadingButton>
                      </Box>
                    </div>
                  }
                </>
              ) : (
                <>

                  <div className={styles.documents_container}>
                    <Typography title={name} sx={{ fontSize: '1rem',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis' }}>
                      View {name} <sup style={{ color: "red" }}>*</sup>
                    </Typography>

                    <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '5px' }}>
                      {checkstatusDoc == true &&
                        <Box sx={{ backgroundColor: 'white', borderRadius: '50%', height: '1rem', width: '1rem', marginRight: '1rem' }}>
                          <VerifiedIcon sx={{ color: '#39FF14', position: 'relative', margin: '-0.35rem -0.25rem 0', }} />
                        </Box>
                      }
                      <VisibilityIcon
                        style={{ cursor: "pointer" }}
                        onClick={() => documentHandler("upload_doc-1", value, name)}
                      />
                    </Box>
                  </div>
                </>
              ))}

            {key == "upload_doc-2" &&
              (value == "null" ? (
                <>

                  {(Premission?.includes("C") || Premission?.includes("D") || Premission?.includes("U")) &&
                    <div className={styles.documents_container}>
                      <Typography sx={{ fontSize: '1rem' }}>Upload Document-2</Typography>
                      <LoadingButton
                        variant="contained"
                        title="Upload Document"
                        loading={docLoader2}
                        sx={{
                          minWidth: '8px', padding: '0',
                          boxShadow: 'none',
                          "&:hover": {
                            backgroundColor: 'white',
                            borderRadius: '50%',
                            color: 'primary.main',
                          },
                          '.MuiCircularProgress-root': {
                            color: 'white',
                          },
                        }}
                        onClick={() =>
                          documentHandlerFile2("upload_doc-2", value)
                        }
                      >
                        <UploadIcon
                          style={{ cursor: "pointer" }}
                        // onClick={() => documentHandlerFile("upload_doc-2", value)}sss
                        />
                      </LoadingButton>
                    </div>
                  }
                </>
              ) : (
                <>
                  <div className={styles.documents_container}>
                    <Typography title={name} sx={{ fontSize: '1rem',whiteSpace:'nowrap',overflow:'hidden',textOverflow:'ellipsis' }}>View {name}</Typography>
                    <VisibilityIcon
                      style={{ cursor: "pointer" }}
                      onClick={() => documentHandler("upload_doc-2", value, name)}
                    />
                  </div>
                </>
              ))}
          </>
        );
      });
    return documentData;
  };
  return (
    <div className={styles.parameterdata_container}>
      {Documentmodal.isOpen == true && (
        <InwardDocumentModal
          isOpen={Documentmodal.isOpen}
          onCloseModal={Documentmodal.close}
          productId={productId}
          DocList={DocList && DocList}
          setDocumentCounter={setDocumentCounter}
          checkstatusDoc={checkstatusDoc}
          DocName={DocName}
          DocFileName={Name}
          Premission={Premission && Premission}
        />
      )}

      {pdfnamemodal.isOpen &&
        <InwardPdfNameModal
          isOpen={pdfnamemodal.isOpen}
          onCloseModal={pdfnamemodal.close}
          SubmitPdfFile={fileCondition == "file" ? SubmitPdfFile : SubmitPdfFile1}
          FileData={fileCondition == "file" ? FileData && FileData?.name?.split(".pdf")[0] : FileData2 && FileData2?.name?.split(".pdf")[0]}
        />}

      {EditModal.isOpen == true && (
        <InwardImageEditorModal
          isOpen={EditModal.isOpen}
          onCloseModal={EditModal.close}
          productId={productId}
          imageURI={ImageOldUrl}
          ImageNamedata={ImageNamedata}
          setImageUploadCounter={setImageUploadCounter}
          setImageLoader={setImageLoader}
        />
      )}

      {Validationmodal.isOpen && (
        <ValidationModal
          isOpen={Validationmodal.isOpen}
          onCloseModal={Validationmodal.close}
          CheckboxdataMsg={CheckboxdataMsg}
        />
      )}

      <input
        accept="image/*"
        id="profilePhoto"
        type="file"
        style={{ display: "none" }}
        onChange={uploadImage}
        ref={inputElRef}
      />

      <input
        accept="image/*"
        id="profilePhoto1"
        type="file"
        style={{ display: "none" }}
        onChange={UploadImageWithout}
        ref={inputElRefWithout}
      />

      <input
        accept=".pdf"
        id="PDF Upload"
        type="file"
        style={{ display: "none" }}
        onChange={(e: any) => UploadPDF(e)}
        ref={inputElRefPdf}
      />
      {docLoader2 == false && (
        <input
          accept=".pdf"
          id="PDF Upload"
          type="file"
          style={{ display: "none" }}
          onChange={(e: any) => UploadPDF2(e)}
          ref={inputElRefPdf2}
        />
      )}
      <div className={styles.parametername}>
        <div>
          <div className={styles.parameterHeadingProduct}>
            <div className={styles.productInnerTitle}>
              <Typography sx={{ fontSize: '1rem' }}>
                Product details <sup style={{ color: "red" }}>*</sup>:
              </Typography>
              <Box>
                {ProductDetails.length > 0 && <>
                  {checkstatusProduct ?

                    <Checkbox

                      style={{ padding: '3px' }}

                      {...label}
                      // @ts-ignore
                      disabled={(Premission?.includes("C") || Premission?.includes("D") || Premission?.includes("U")) ? false : true}
                      title="Validated"

                      checkedIcon={<Box sx={{ backgroundColor: 'white', borderRadius: '50%', height: '1rem', width: '1rem', marginRight: '6px' }}>
                        <VerifiedIcon sx={{ color: '#39FF14', position: 'relative', margin: '-0.35rem -0.25rem 0', }} />
                      </Box>}

                      checked={checkstatusProduct}

                      onChange={(e: any) => Checkboxhandler(e, "Product")}

                      color="success"

                      sx={{ "& .MuiSvgIcon-root": { fontSize: 22 }, color: "white", cursor: 'pointer' }}

                    />
                    :

                    <Checkbox

                      style={{ padding: '3px' }}

                      {...label}

                      title="Not Validated"
                      // @ts-ignore
                      disabled={(Premission?.includes("C") || Premission?.includes("D") || Premission?.includes("U")) ? false : true}
                      checked={checkstatusProduct}

                      onChange={(e: any) => Checkboxhandler(e, "Product")}

                      color="success"

                      sx={{ "& .MuiSvgIcon-root": { fontSize: 22 }, color: "white" }}

                    />
                  }

                </>
                }
                {/* {checkstatusProduct ?
                  <Checkbox
                    style={{ padding: '3px' }}
                    {...label}
                    title="Validated"
                    checkedIcon={<Box sx={{ backgroundColor: 'white', borderRadius: '50%', height: '1rem', width: '1rem', marginRight: '1rem' }}>
                      <VerifiedIcon sx={{ color: '#39FF14', position: 'relative', margin: '-0.35rem -0.25rem 0', }} />
                    </Box>}
                    checked={checkstatusProduct}
                    onChange={(e: any) => Checkboxhandler(e, "Product")}
                    color="success"
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 22 }, color: "white", cursor: 'pointer' }}
                  />
                  :
                  <Checkbox
                    style={{ padding: '3px' }}
                    {...label}
                    
                    title="Not Validated"
                    checked={checkstatusProduct}
                    onChange={(e: any) => Checkboxhandler(e, "Product")}
                    color="success"
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 22 }, color: "white" }}
                  />
                } */}

                {/* <Checkbox
                  style={{ padding: '3px' }}
                  {...label}
                  title="Validation"
                  checked={checkstatusProduct}
                  onChange={(e: any) => Checkboxhandler(e, "Product")}
                  color="success"
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 22, }, color: "white" }} /> */}
                {ProductStatus ? (
                  " "
                ) : (
                  (Premission?.includes("C") || Premission?.includes("D") || Premission?.includes("U")) &&
                  <>
                    <IconButton
                      disabled={ProductStatus !== undefined && ProductStatus}
                      sx={{ cursor: ProductStatus ? "not-allowed" : "pointer", padding: '3px' }}
                      onClick={() => AddnewRows("Product")}
                    >
                      <AddIcon
                        titleAccess="Add Product Details"
                        sx={{
                          color: "white",
                          fontSize: '1.5rem',
                          "&:hover": {
                            color: "primary.main",
                          },

                          cursor: ProductStatus ? "not-allowed" : "pointer",
                        }} />
                    </IconButton></>


                )}
              </Box>
            </div>
            {ProductLoader ? (
              <div style={{ height: "38vh", }}
                adl-scrollbar="true"
                adl-scrollbar-width="0.3">
                <Box sx={{ width: 330 }}>
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                </Box>
              </div>
            ) : (
              <Box
                sx={{ height: "38vh" }}
                adl-scrollbar="true"
                adl-scrollbar-width="0.3"
              >
                {ProductDetails &&
                  ProductDetails?.map((item: any, index: any) => {
                    return (
                      <>
                        <InwardInputCard
                          DetailsItem={item}
                          Premission={Premission}
                          keys={index}
                          UpdateState={setProductStatus}
                          intialState={ProductStatus}
                          Parametername={"product_details"}
                          topVoultId={productId}
                          setCounter={setProductCounter}
                        />
                      </>
                    );
                  })}
              </Box>
            )}
          </div>

          <div className={styles.parameterHeadingProduct}>
            <div className={styles.productInnerTitle}>
              <Typography sx={{ fontSize: '1rem' }}>Remarks:</Typography>
              {RemarkStatus ? (
                " "
              ) : (

                (Premission?.includes("C") || Premission?.includes("D") || Premission?.includes("U")) &&
                <IconButton
                  disabled={RemarkStatus}
                  style={{ cursor: RemarkStatus ? "not-allowed" : "pointer", padding: '3px' }}
                  onClick={() => AddnewRows("Remarks")}
                >
                  <AddIcon
                    titleAccess="Add Remarks"
                    sx={{
                      color: "white",
                      fontSize: '1.5rem',
                      "&:hover": {
                        color: "primary.main",
                      },

                      cursor: RemarkStatus ? "not-allowed" : "pointer",
                    }}
                  />
                </IconButton>
              )}
            </div>
            {RemarkLoader ? (
              <Box
                sx={{ height: "38vh" }}
                adl-scrollbar="true"
                adl-scrollbar-width="0.3"
              >
                <Box sx={{ width: 330 }}>
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                </Box>
              </Box>
            ) : (
              <Box
                sx={{ height: "38vh" }}
                adl-scrollbar="true"
                adl-scrollbar-width="0.3"
              >
                {RemarksItem &&
                  RemarksItem?.map((item: any, index: any) => {
                    return (
                      <>
                        <InwardInputCard
                          DetailsItem={item}
                          Premission={Premission}
                          keys={index}
                          UpdateState={setRemarksStatus}
                          intialState={RemarkStatus}
                          Parametername={"remarks"}
                          topVoultId={productId}
                          setCounter={setRemarkCounter}
                        />
                      </>
                    );
                  })}
              </Box>
            )}
          </div>
        </div>
      </div>
      <div className={styles.parametername}>
        <div className={styles.parameterHeading}>
          <Typography sx={{ fontSize: '1rem' }}>
            Received items list <sup style={{ color: "red" }}>*</sup>:
          </Typography>
          <Box>
            {ProductReceivedItem?.length > 0 && <>

              {checkstatusReceived ?
                <Checkbox
                  style={{ padding: '3px' }}
                  {...label}
                  title="Validated"
                  // @ts-ignore
                  disabled={(Premission?.includes("C") || Premission?.includes("U")) ? false : true}
                  checkedIcon={<Box sx={{ backgroundColor: 'white', borderRadius: '50%', height: '1rem', width: '1rem', marginRight: '6px' }}>
                    <VerifiedIcon sx={{ color: '#39FF14', position: 'relative', margin: '-0.35rem -0.25rem 0', }} />
                  </Box>}
                  checked={checkstatusReceived}
                  onChange={(e: any) => Checkboxhandler(e, "Received")}
                  color="success"
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 22 }, color: "white", cursor: 'pointer' }}
                />
                :
                <Checkbox
                  style={{ padding: '3px' }}
                  {...label}
                  title="Not Validated"
                  // @ts-ignore
                  disabled={(Premission?.includes("C") || Premission?.includes("U")) ? false : true}
                  checked={checkstatusReceived}
                  onChange={(e: any) => Checkboxhandler(e, "Received")}
                  color="success"
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 22 }, color: "white" }}
                />
              }
            </>}

            {
              (Premission?.includes("C") || Premission?.includes("D") || Premission?.includes("U") || Premission?.includes("U"))

              && <>
                <IconButton
                  // ReceivedStatus

                  disabled={ReceivedStatus}
                  style={{
                    cursor: ReceivedStatus
                      ? "not-allowed !important"
                      : "pointer !important",
                    padding: '3px'
                  }}
                  onClick={() => AddnewRows("ReceivedItem")}
                >
                  <AddIcon
                    titleAccess="Add Received Items"
                    sx={{
                      color: "white",
                      fontSize: '1.5rem',
                      "&:hover": {
                        color: "primary.main",
                      },
                      cursor: ReceivedStatus
                        ? "not-allowed !important"
                        : "pointer !important",
                    }}
                  />
                </IconButton>
              </>
            }
          </Box>
        </div>
        {ReceivedLoader ? (
          <div style={{ height: "83vh", }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3">
            <Box sx={{ width: 330 }}>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
            </Box>
          </div>
        ) : (
          <Box
            sx={{ height: "83vh" }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3"
          >
            {ProductReceivedItem &&
              ProductReceivedItem?.map((item: any, index: any) => {
                return (
                  <>
                    <InwardInputCard
                      DetailsItem={item}
                      Premission={Premission}
                      keys={index}
                      intialState={ReceivedStatus}
                      UpdateState={setReceivedStatus}
                      Parametername={"received_items_list"}
                      topVoultId={productId}
                      setCounter={setReceivedCounter}
                    />

                    {/* <input value={item?.name}/> */}
                  </>
                );
              })}
          </Box>
        )}
      </div>
      <div className={styles.parametername}>
        <div>
          <div className={styles.parameterHeadingProduct}>
            <div className={styles.productInnerTitle}>
              <Typography sx={{ fontSize: '1rem' }}>Any damages:</Typography>


              {(Premission?.includes("C") || Premission?.includes("D") || Premission?.includes("U")) &&
                <IconButton
                  disabled={DamagesStatus}
                  style={{ cursor: DamagesStatus ? "not-allowed" : "pointer", padding: '3px' }}
                  onClick={() => AddnewRows("Damages")}
                >
                  <AddIcon
                    titleAccess="Add Damages"
                    sx={{
                      color: "white",
                      fontSize: '1.5rem',
                      "&:hover": {
                        color: "primary.main",
                      },
                    }}
                  />
                </IconButton>
              }
            </div>
            {DamageLoader ? (
              <div style={{ height: "38vh", }}
                adl-scrollbar="true"
                adl-scrollbar-width="0.3">
                <Box sx={{ width: 330 }}>
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                </Box>
              </div>
            ) : (
              <div style={{ height: "38vh", }}
                adl-scrollbar="true"
                adl-scrollbar-width="0.3">
                {DamagesData &&
                  DamagesData?.map((item: any, index: any) => {
                    return (
                      <>
                        <InwardInputCard
                          DetailsItem={item}
                          Premission={Premission}
                          keys={index}
                          intialState={DamagesStatus}
                          UpdateState={setDamagesStatus}
                          Parametername={"any_damages"}
                          topVoultId={productId}
                          setCounter={setDamageCounter}
                        />

                        {/* <input value={item?.name}/> */}
                      </>
                    );
                  })}
              </div>
            )}
          </div>

          <div className={styles.parameterHeadingProduct}>
            <div className={styles.productInnerTitle}>
              <Typography sx={{ fontSize: '1rem' }}>Missing items:</Typography>

              {(Premission?.includes("C") || Premission?.includes("D") || Premission?.includes("U")) &&
                <IconButton
                  disabled={MissingStatus}
                  style={{ cursor: MissingStatus ? "not-allowed" : "pointer", padding: '3px' }}
                  onClick={() => AddnewRows("MissingItem")}
                >
                  <AddIcon
                    titleAccess="Add Missing Items"
                    sx={{
                      color: "white",
                      fontSize: '1.5rem',
                      "&:hover": {
                        color: "primary.main",
                      },
                    }}
                  />
                </IconButton>}
            </div>
            {MissingItemLoader ? (
              <div style={{ height: "38vh", }}
                adl-scrollbar="true"
                adl-scrollbar-width="0.3">
                <Box sx={{ width: 330 }}>
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                  <Skeleton />
                  <Skeleton animation="wave" />
                  <Skeleton animation={false} />
                </Box>
              </div>
            ) : (
              <Box
                sx={{ height: "38vh" }}
                adl-scrollbar="true"
                adl-scrollbar-width="0.3"
              >
                {MissingData &&
                  MissingData?.map((item: any, index: any) => {
                    return (
                      <>
                        <InwardInputCard
                          DetailsItem={item}
                          Premission={Premission}
                          keys={index}
                          intialState={MissingStatus}
                          UpdateState={setMissingStatus}
                          Parametername={"missing_items"}
                          topVoultId={productId}
                          setCounter={setMissinCounter}
                        />

                        {/* <input value={item?.name}/> */}
                      </>
                    );
                  })}
              </Box>
            )}
          </div>
        </div>
      </div>
      <div className={styles.parametername}>
        <div className={styles.parameterHeading}>
          <Typography sx={{ fontSize: '1rem' }}>Non-working functionalities:</Typography>


          {(Premission?.includes("C") || Premission?.includes("D") || Premission?.includes("U")) &&
            <IconButton
              disabled={FunctionalitiesStatus}
              style={{
                cursor: FunctionalitiesStatus ? "not-allowed" : "pointer", padding: '3px'
              }}
              onClick={() => AddnewRows("Functionalities")}
            >
              <AddIcon
                titleAccess="Add Nonworking Functionalities"
                sx={{
                  color: "white",
                  fontSize: '1.5rem',
                  "&:hover": {
                    color: "primary.main",
                  },
                }}
              />
            </IconButton>
          }
        </div>

        {FunctionalitiesLoader ? (
          <div style={{ height: "72vh", }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3">
            <Box sx={{ width: 330 }}>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
            </Box>
          </div>
        ) : (
          <Box
            sx={{ height: "72vh" }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3"
          >
            {FunctionalitiesData &&
              FunctionalitiesData?.map((item: any, index: any) => {
                return (
                  <>
                    <InwardInputCard
                      DetailsItem={item}
                      Premission={Premission}
                      keys={index}
                      intialState={FunctionalitiesStatus}
                      UpdateState={setFunctionalitiesStatus}
                      Parametername={"non-working_functionalities"}
                      topVoultId={productId}
                      setCounter={setFunctionalitiesCounter}
                    />

                    {/* <input value={item?.name}/> */}
                  </>
                );
              })}
          </Box>
        )}

        <div>
          {DocumentsList &&
            DocumentsList.length > 0 &&
            DocumentsList.map((item: any) => {
              return <>{renderIntoDocument(item, item?.name)}




              </>;
            })}
        </div>
      </div>
      <div className={styles.parameterImage}>
        <div className={styles.parameterHeading}>
          <Typography sx={{ fontSize: '1rem' }}>Images:</Typography>
          <Box>
            {ImageList?.length > 0 && <>
              {checkstatusImage ?
                <Checkbox
                  style={{ padding: '3px' }}
                  {...label}
                  // icon={<VerifiedIcon />}
                  // @ts-ignore
                  disabled={(Premission?.includes("C") || Premission?.includes("D") || Premission?.includes("U")) ? false : true}
                  checkedIcon={<Box sx={{ backgroundColor: 'white', borderRadius: '50%', height: '1rem', width: '1rem', marginRight: '6px' }}>
                    <VerifiedIcon sx={{ color: '#39FF14', position: 'relative', margin: '-0.35rem -0.25rem 0', }} />
                  </Box>}
                  title="Validated"
                  checked={checkstatusImage}
                  onChange={(e: any) => Checkboxhandler(e, "Images")}
                  color="success"
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 22 }, color: "white", cursor: 'pointer' }}
                /> :
                <Checkbox
                  style={{ padding: '3px' }}
                  {...label}
                  title="Not Validated"
                  // @ts-ignore
                  disabled={(Premission?.includes("C") || Premission?.includes("D") || Premission?.includes("U")) ? false : true}
                  checked={checkstatusImage}
                  onChange={(e: any) => Checkboxhandler(e, "Images")}
                  color="success"
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 22 }, color: "white" }}
                />}
            </>}
          </Box>
        </div>
        <Box sx={{ padding: "1rem", height: "85vh", }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3"
        >
          {ImageItemLoader ? (
            <Box
              sx={{
                width: 330,
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                gap: " 1rem",
              }}
            >
              <Skeleton variant="rectangular" width={155} height={123} />
              <Skeleton variant="rectangular" width={155} height={123} />
              <Skeleton variant="rectangular" width={155} height={123} />
              <Skeleton variant="rectangular" width={155} height={123} />
              <Skeleton variant="rectangular" width={155} height={123} />
              <Skeleton variant="rectangular" width={155} height={123} />
              <Skeleton variant="rectangular" width={155} height={123} />
              <Skeleton variant="rectangular" width={155} height={123} />
              <Skeleton variant="rectangular" width={155} height={123} />
              <Skeleton variant="rectangular" width={155} height={123} />
            </Box>
          ) : (
            // @ts-ignore
            <RViewerJS>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fit, minmax(7vw, 1fr))",
                  gridGap: "1rem",
                }}
              >
                {ImageList &&
                  ImageList?.length > 0 &&
                  ImageList?.map((item: any, index: any) => {
                    return (
                      <>
                        <div>{renderImages(item)}</div>
                      </>
                    );
                  })}
              </Box>
            </RViewerJS>
          )}
        </Box>
      </div>
    </div>
  );
};

export default InwardParameterdata;
