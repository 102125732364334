import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Divider,Table,TableCell,TableRow,Typography } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: " 50rem",
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

interface SupplierShowDetailsModelProps {
  isOpen: any;
  onCloseModal: any;
  aboutLink:any;
  contactLink:any;
  contact:any;
  spocName:any;
  spocEmail:any;
  dialCode:any;
}

const SupplierShowDetailsModel = (props: SupplierShowDetailsModelProps) => {
  const { isOpen, onCloseModal, aboutLink, contactLink, contact, spocName, spocEmail,dialCode } = props;
  let regex = /(http|https|www|\.com|\.in)/;
  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: '-2rem'
              }}
            >
              Details
            </Typography>
            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ padding: '0 0.5rem 0.5rem', display: 'flex', flexDirection: 'column', rowGap: '1rem', width: '100%' }}>
            <Table>
                <TableRow>
                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light',width:'12rem' }}><Typography sx={{display:'flex',alignItems:'center',justifyContent:'space-between'}}><Typography sx={{fontSize:'1rem'}}>About Us</Typography><Typography sx={{fontSize:'1rem'}}>:</Typography></Typography></TableCell>
                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><span style={{textDecoration:'underline', cursor:'pointer', color:'#0000ff'}} onClick={()=>window.open(aboutLink)}>{aboutLink}</span></TableCell>
                </TableRow>
                <TableRow>
                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light',width:'12rem' }}><Typography sx={{display:'flex',alignItems:'center',justifyContent:'space-between'}}><Typography sx={{fontSize:'1rem'}}>Contact Us</Typography><Typography sx={{fontSize:'1rem'}}>:</Typography></Typography></TableCell>
                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><span style={{textDecoration:'underline', cursor:'pointer', color:'#0000ff'}} onClick={()=>window.open(contactLink)}>{contactLink}</span></TableCell>
                </TableRow>
                <TableRow>
                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light',width:'12rem' }}><Typography sx={{display:'flex',alignItems:'center',justifyContent:'space-between'}}><Typography sx={{fontSize:'1rem'}}>Contact Number</Typography><Typography sx={{fontSize:'1rem'}}>:</Typography></Typography></TableCell>
                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>{dialCode == null ? contact : dialCode+'-'+contact}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light',width:'12rem' }}><Typography sx={{display:'flex',alignItems:'center',justifyContent:'space-between'}}><Typography sx={{fontSize:'1rem'}}>SPOC Name</Typography><Typography sx={{fontSize:'1rem'}}>:</Typography></Typography></TableCell>
                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>{spocName}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light',width:'12rem' }}><Typography sx={{display:'flex',alignItems:'center',justifyContent:'space-between'}}><Typography sx={{fontSize:'1rem'}}>SPOC Email</Typography><Typography sx={{fontSize:'1rem'}}>:</Typography></Typography></TableCell>
                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>{spocEmail}</TableCell>
                </TableRow>
            </Table>
          </Box>
        </Box>

      </Modal>
    </div >
  );
};

export default SupplierShowDetailsModel;
