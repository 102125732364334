import { Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import React, { useEffect, useState } from 'react';
import MediaDownloadTableBody from './MediaDownloadTableBody';

interface MediaDownloadSystemTreeI {
    downloadProductInfo: any;
    groupContextAbbreviation: any;
    openMediaDownload: any;
    type: any;
    groupContextImageCategory: any;
    permissionAccess:any;
    groupContextId?: any;
}

const MediaDownloadSystemTree: React.FC<MediaDownloadSystemTreeI> = (props) => {
    const { downloadProductInfo, groupContextAbbreviation, groupContextImageCategory, openMediaDownload, type, groupContextId, permissionAccess } = props;

    return (
        <Table>
            <TableHead sx={{ backgroundColor: 'primary.light' }}>
                <TableRow>
                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Product</TableCell>
                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Range</TableCell>
                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Actions</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {downloadProductInfo && downloadProductInfo?.map((item: any) => {
                    return <MediaDownloadTableBody topVaultName={item?.name} topVaultId={item?.top_vault_id} countImage={item?.count} groupContextAbbreviation={groupContextAbbreviation} groupContextId={groupContextId} groupContextImageCategory={groupContextImageCategory} openMediaDownload={openMediaDownload} type={type} permissionAccess={permissionAccess}/>
                })
                }
            </TableBody>
        </Table>)
}

export default MediaDownloadSystemTree;