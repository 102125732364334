import { debounce } from "lodash";
import React, { useState } from "react";
import { API } from "../../../api-services";
import Swal from "sweetalert2";
import { Autocomplete, Box, Divider, FormControl, Modal, TextField, Typography } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import useWindowDimensions from "../../../ui-reusable-component/useWindowDimensions";
import { LoadingButton } from "@mui/lab";
import { useStage } from "spritespin";
import { ADMIN } from "../../../Redux/Services/admin.service";


interface InfoModalProps {


  onCloseModal: any;
  isOpen: any;
  Item: any;

  assumptionList:any;
  costDetailsData:any;
  scenario:any;
  setCounter:any;
  Selectedmulassumption:any;
  setselectedmulassumption:any;

}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: "60vh",
  maxWidth: '80vh',
  // height: '80vh',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  p: 1,
};


const OtherAssumptionModal = (props: InfoModalProps) => {

  const { height, width } = useWindowDimensions();

  const { onCloseModal,
    isOpen,assumptionList,costDetailsData,scenario,setCounter,
    Item,Selectedmulassumption,setselectedmulassumption } = props
    const [loader,setloader]=useState<any>(false)
    const handleSelectChangAssumtionParams = (event: any, newSystemValue: any) => {
        setselectedmulassumption(newSystemValue);
       
        ExactDebounceMultipleAssumption(
          newSystemValue?.map(
            (item: any) => item.id
          ),
          Item
          
        );
      
        
      };
    const ExactDebounceMultipleAssumption = React.useRef(
        debounce(async (value: any,vaultId:any) => {
          console.log(value,"vbivfbiuvbufvbfbiu")
          API.post(
            `/cost/costing_assumption/`,
            {
              assumption_list: value,
              vault:vaultId,
              scenario:scenario
            },
            {},
            0
          )
            .then((res: any) => {
              setCounter((prev:any)=>prev+1)
              ADMIN.toast.info("Assumptions updated successfully");
    
              // setUpdateCount((prev: any) => prev + 1);
            })
            .catch((err: any) => {
              const { data } = err?.response;
              Swal.fire({
                icon: "error",
                html: `<div>
              <br />
              <p style="color:"red">${data[0]}</p>   
               </div>`,
              });
            });
        }, 1000)
      ).current;
const Savehandler=()=>{
   let IdsCollector= Selectedmulassumption?.map(
        (item: any) => item.id
      )

setloader(true)
      API.post(
        `/cost/costing_assumption/`,
        {
          assumption_list: IdsCollector,
          vault:Item,
          scenario:scenario
        },
        {},
        0
      )
        .then((res: any) => {
            setloader(false)
          setCounter((prev:any)=>prev+1)
       

          // setUpdateCount((prev: any) => prev + 1);
        })
        .catch((err: any) => {
            setloader(false)
          const { data } = err?.response;
          Swal.fire({
            icon: "error",
            html: `<div>
          <br />
          <p style="color:"red">${data[0]}</p>   
           </div>`,
          });
        });



}

  return (
    <div>


      <Modal
        open={isOpen}
        onClose={onCloseModal}
      >
        <Box sx={style}>
          <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: '-2rem'
              }}
            >
              Other Assumptions
            </Typography>
            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{  width: '100%' }}
           >
             { (
                        <FormControl fullWidth>
                          <Autocomplete
                            multiple
                            disableClearable
                            disabled={
                              costDetailsData &&
                              costDetailsData?.permission?.includes("U")
                                ? false
                                : true
                            }
                            filterSelectedOptions
                            disableCloseOnSelect
                            onChange={(event: any, newSystemValue: any) => {
                              handleSelectChangAssumtionParams(event, newSystemValue);
                            }}
                            id="tags-standard"
                            limitTags={3}
                            options={assumptionList && assumptionList }
                            // getOptionDisabled={getOptionDisabled}/
                           value={Selectedmulassumption && Selectedmulassumption}
                            getOptionLabel={(option: any) => option.name}
                            // defaultValue={[top100Films[3]]}
                            sx={{
                              ".MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator":
                                {
                                  color: "primary.main",
                                },
                              ".MuiButtonBase-root.MuiChip-root": {
                                backgroundColor: "primary.light",
                                height: "26px",
                              },
                            }}
                            // onBlur={() => {
                            //   if (Selectedmulassumption) {
                            //     ExactDebounceMultipleAssumption(
                            //       Selectedmulassumption?.map(
                            //         (item: any) => item.id
                            //       ),
                            //       vault
                                  
                            //     );
                            //   }
                            // }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                variant="standard"
                                placeholder="Select multiple assumptions"
                                sx={{
                                  ".MuiInputBase-root.MuiInput-root": {
                                    fontSize: "1rem !important",
                                  },
                                  "& .MuiInput-underline:before": {
                                    borderBottomColor: "primary.main",
                                  },
                                  "& .MuiInput-underline:after": {
                                    borderBottomColor: "primary.main",
                                  },
                                }}
                              />
                            )}
                          />
                        </FormControl>
                      )}
                      {/* <Box sx={{display: "flex",
    justifyContent: "end",marginTop: "3rem"}}>
                      <LoadingButton loading={loader} 
                      disabled={Selectedmulassumption?.length>0?false:true}
                       variant="contained" size="small" sx={{cursor:"pointer"}} onClick={()=>Savehandler()}>
                        Add Assumption
                      </LoadingButton>
                      </Box> */}
          </Box>
        </Box>
      </Modal>
    </div>
  )
}

export default OtherAssumptionModal;