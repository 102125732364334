import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { styled } from "@mui/material/styles";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { TreeItemProps, treeItemClasses } from "@mui/lab/TreeItem";
import Collapse from "@mui/material/Collapse";
import { TransitionProps } from "@mui/material/transitions";
import { animated, useSpring } from "@react-spring/web";
import {
  Box,
  Typography,
  MenuItem,
  Menu,
  CircularProgress,
  Skeleton,
} from "@mui/material";
import FlagIcon from "@mui/icons-material/Flag";
import CopyrightIcon from "@mui/icons-material/Copyright";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import AgentIcon from "../../../Assets/images/AgentIcon.svg";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import { API } from "../../../api-services";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FolderIcon from "@mui/icons-material/Folder";
import styles from "./BOMTreeViewCreate.module.scss";
import { useBaseModal } from "../../SearchPage/useBaseModal";
import successIcon from "../../../Assets/images/success.png";
import Swal from "sweetalert2";
import { useReactToPrint } from "react-to-print";
import MovePartModal from "./MovePartModal";
import BOMCopyModal from "./BOMCopyModal";
import BOMEntryWeightAlertModal from "./BOMEntryWeightAlertModal";
import BOMEntryAddSystemModal from "./BOMEntryAddSystemModal";
import BOMEntryCopytoOtherModal from "./BOMEntryCopytoOtherModal";
import SheetViewModal from "./SheetViewModal";
import AgentModal from "./AgentModal";
import BOMFlagModal from "./BOMFlagModal";
import BOMEntryAddBOMPartModal from "./BOMEntryAddBOMPartModal";
import { userPermissionAction } from "./BOMEntryLandingPage";
import { useSelector } from "react-redux";
import NestedMenuItem from "material-ui-nested-menu-item";
import { userSelectors } from "../../../Redux/Reducers/user.reducer";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import swal from "sweetalert";


function TransitionComponent(props: TransitionProps) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: "translate3d(20px,0,0)",
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });
  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

const StyledTreeItem = styled((props: TreeItemProps) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 10,
    paddingLeft: 8,
    borderLeft: `1px dashed ${theme.palette.text.primary}`,
  },
}));

interface TreeViewComponentsProps {
  HierarchyData: any;
  onSelectCallback: any;
  setrecyclebinCounter: any;
  setParentsCounter?: any;
  childRef?: any;
  locatorCount?:any;
  setLocatorCount?:any
}

const BOMTreeViewCreate = forwardRef((props: TreeViewComponentsProps) => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const {
    HierarchyData,
    onSelectCallback,
    setParentsCounter,
    setrecyclebinCounter,
    childRef,
    locatorCount,
    setLocatorCount
  } = props;
  const {
    projectId,
    topVault,
    vault,
    scenarioId,
    projectName,
    workstation,
    isLeaf,
    copyStatus,
  } = useParams<any>();
  const AddBOMPartModal = useBaseModal();
  const CopytoOtherModal = useBaseModal();
  const WeightAlertModal = useBaseModal();
  const AddSystemModal = useBaseModal();
  const sheetview = useBaseModal();
  const [titleString, setTitleString] = React.useState<any>("");
  const openStatusContent = useBaseModal();
  const FlagModal = useBaseModal();
  const AgentDataModal = useBaseModal();
  const imageIframeRef = useRef<any>(null);
  
  const [expanded, setExpanded] = useState<any>([topVault]);
  const [contentText, setContentText] = React.useState<any>("");
  const [mastervalidationloader, setmastervalidationloader] =
    useState<any>(false);
    const userProfilePic = useSelector(userSelectors.selectAll);
  const [selected, setSelected] = useState<any>([topVault]);
  const movemodal = useBaseModal();
  const [systemloader, setSystemLoader] = useState<any>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [iconText, setIconText] = React.useState<any>("");
  const [activeId, setActiveId] = useState<any>(topVault);
  const [routeLoader, setrouteLoader] = useState<any>(false);
  const [isProduct, setIsProduct] = useState<boolean>(false);
  const [HierarchyAllData, setHierarchyAllData] = useState<any>(HierarchyData);
  const [SystemData, setSystemData] = useState([]);
  const [contentRef, setContentRef] = React.useState<any>();
  const [newVault, setNewvault] = useState<any>();
  const [PartLoader, setPartLoader] = React.useState<any>(false);
  const [ModalData, setModalData] = useState<any>(null);
  const [copytype, setcopytype] = useState<any>();
  const [PartAssemblyData, setPartAssemblyData] = React.useState<any>([]);
  const [QRLoader, setQRLoader] = useState<any>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const copymodal = useBaseModal();
  const appendChildData = (data: any, nodeId: any, newChildData: any): any => {


    // Base case: if this node's id matches nodeId, append newChildData
    if (data.id == nodeId) {
      return {
        ...data,
        children: [...(data.children || []), ...newChildData], // Append new data to the existing children
      };
    }

    // If the node has children, recursively search in the children
    if (data.children) {
      return {
        ...data,
        children: data.children.map((child: any) =>
          appendChildData(child, nodeId, newChildData)
        ),
      };
    }

    // If no match and no children, return the node as is
    return data;
  };
  const AddBOMPartHandlerSystem = (e: any, data: any) => {
    {
      e?.stopPropagation();
      setModalData(data);
      AddBOMPartModal.open();
    }
  };
  const userAction = React.useContext(userPermissionAction);
  const open = Boolean(anchorEl);
  useEffect(() => {
    setTimeout(
      () =>
        document.getElementById(vault)?.scrollIntoView({
          block: "nearest",
          inline: "start",
          behavior: "smooth",
        }),
      1000
    );
  }, [vault]);
  let ActionUser = userAction && userAction?.action;
  const handleClose = () => {
    setAnchorEl(null);
  };

  const CopyPart = () => {
    setModalData(ModalData);
    sessionStorage.setItem("copyId", ModalData?.id);
    setcopytype("pastenew");
    setModalData(ModalData);
    copymodal.open();

    setAnchorEl(null);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    idd: any,
    is_leaf: any,
    data: any
  ) => {
    event.preventDefault();
    event.stopPropagation();
    setModalData(data);
    sessionStorage.setItem("TargetIds", idd);
    setAnchorEl(event.currentTarget);
    // setAnchorPointBOMCreate({ x: event.clientX, y: event.clientY });
    // setOpenContext(true);
    // getMenuItems(event.currentTarget, idd, is_leaf, data?.abbreviation);
  };
  const getColor = (status: any) => {
    switch (status) {
      case 0:
        return "red";
      case 1:
        return "#007fff";
      case 2:
        return "#007fff";
      default:
        return "green";
    }
  };

  const handleChange = async (e: any, nodeId: any, abbreviation: any) => {
    let a: any = sessionStorage.getItem("BOMCreateNode");
    let val: any = JSON.parse(a) || [];




    // Only proceed to fetch child data if the node is not a leaf
    if (!HierarchyAllData.is_leaf) {
      if (!val.includes(nodeId)) {
        val.push(nodeId);
        sessionStorage.setItem("BOMCreateNode", JSON.stringify(val));
        fetchChildData(nodeId, abbreviation); // Fetch child data when a node is expanded
      } else {
        let noDuplicate: any = val?.filter((item: any) => {
          return item != nodeId;
        });
        if (noDuplicate?.includes(topVault)) {
          let b: any = sessionStorage.setItem(
            "BOMCreateNode",
            JSON.stringify(noDuplicate)
          );
        } else {
          noDuplicate.unshift(topVault);
          let b: any = sessionStorage.setItem(
            "BOMCreateNode",
            JSON.stringify([topVault])
          );
        }
      }
    }
 
    const newExpanded = expanded.includes(nodeId)
      ? expanded.filter((id: any) => id !== nodeId)
      : [...expanded, nodeId];

    setExpanded(newExpanded);
    setSelected([nodeId]); // Select the node

    setActiveId(nodeId);
    onSelectCallback(
      nodeId,
      abbreviation,
      HierarchyAllData,
      HierarchyAllData.id
    );
  };

  useEffect(() => {
    // Retrieve previously expanded nodes on component mount
    const savedExpandedNodes = sessionStorage.getItem("BOMCreateNode");
    if (savedExpandedNodes) {
      setExpanded(JSON.parse(savedExpandedNodes));
    }
  }, [topVault]);

  const CopytoOtherHandler = () => {
    CopytoOtherModal.open();
  };


  const Sheetviewhandler = () => {
    sheetview?.open();
  };
  
  const WeightAlertHandler = (e: any, data: any) => {
    e?.stopPropagation();
    setModalData(data);
    WeightAlertModal.open();
  };


  const DeleteHanlder = () => {
    if (ModalData?.cost_status >= 1) {
      swal({
        // text: `Are you sure ?`,
        title: `This action will delete the BOM Parameter and link Costing`,
        buttons: ["Cancel", "Ok"],
        icon: "warning",
      }).then((confirm: any) => {
        if (confirm) {
          API.delete(`/xcpep/vault_info/${ModalData?.id}/`, { trash: true }, 0)
            .then(() => {
              setParentsCounter((prev: any) => prev + 1);
              setrecyclebinCounter((prev: any) => prev + 1);
              setAnchorEl(null);
              history.push(
                `/bomentry/${projectId}/${projectName}/${topVault}/${0}/delete/${workstation}/${isLeaf}/${copyStatus}`
              );
              // getFeaturesData(targetFeatureData?.group);
              // var random: any = Math.floor(Math.random() * 1000000 + 1);
              // dispatch(increaseCounter(random));
              // dispatch(recyclebinCounter(random + 1));
            })
            .catch((err: any) => {
              const { data } = err?.response;
              Swal.fire({
                icon: "error",
                html: `<div>
                       <br />
                       <p style="color:"red">${data[0]}</p>   
                        </div>`,
              });
            });
        }
      });
    } else {
      swal({
        // text: `Are you sure ?`,
        title: `This action will  delete ${ModalData?.name}`,

        buttons: ["Cancel", "Ok"],
        icon: "warning",
      }).then((confirm: any) => {
        if (confirm) {
          API.delete(`/xcpep/vault_info/${ModalData?.id}/`, { trash: true }, 0)
            .then(() => {
              setParentsCounter((prev: any) => prev + 1);
              setrecyclebinCounter((prev: any) => prev + 1);
              setAnchorEl(null);
              history.push(
                `/bomentry/${projectId}/${projectName}/${topVault}/${0}/delete/${workstation}/${isLeaf}/${copyStatus}`
              );
              // getFeaturesData(targetFeatureData?.group);
              // var random: any = Math.floor(Math.random() * 1000000 + 1);
              // dispatch(increaseCounter(random));
              // dispatch(recyclebinCounter(random + 1));
            })
            .catch((err: any) => {
              const { data } = err?.response;
              Swal.fire({
                icon: "error",
                html: `<div>
                       <br />
                       <p style="color:"red">${data[0]}</p>   
                        </div>`,
              });
            });
        }
      });
    }
  };

  const AddSystemHandler = (e: any, data: any) => {
    e?.stopPropagation();

    setSystemLoader(true);

    API.get(
      `/api/db/sub_system/`,
      {
        project: projectId,
        vehicle_types: data?.vechile_type,
        top_vault: data?.id,
      },
      0
    )
      .then((res: any) => {
        setSystemData(res?.data);
        AddSystemModal.open();
        setSystemLoader(false);
        setModalData(data);
      })
      .catch(() => {
        setSystemLoader(false);
        console.log("Server Error");
      });
  };
  const AddBOMPartHandler = () => {
    {
      // setPartLoader(true);
      AddBOMPartModal.open();
      setAnchorEl(null);
      // API.get("/api/db/part_name/", { vault: ModalData?.id ,search:true}, 0)
      //   .then((res: any) => {
      //     setPartAssemblyData(res?.data);
      //     AddBOMPartModal.open();
      //     setAnchorEl(null);
      //     setPartLoader(false);
      //   })
      //   .catch((err: any) => {
      //     setPartLoader(false);
      //     console.log("Server Error");
      //   });
    }
  };

  const copypartmsg = () => {
    {
      Swal.fire({
        html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
<br />
<p style="color:#007fff;"> Part/Assembly Added Successfully.
</p>
<p style="color:#007fff;">Due to  this action, parents is no longer a copy, and the part number will change to update the details.
</p>
<br/>   
</div>`,
      });
    }
  };

  const getVault = (treeabbr: any, idd: any) => {
    if (treeabbr == "product") {
      return { top_vault: idd };
    } else {
      return { vault: idd };
    }
  };
  // Fetch child data dynamically when expanding a node, passing abbreviation as well
  const fetchChildData = async (nodeId: any, abbreviation: string) => {
    setLoading(true);
    try {
      const response = await API.get(
        `/xcpep/bom_create_hirearchy_new/`,{
          id: nodeId,
          abbreviation: abbreviation,
        },0
      );

      const ids=new Set(response.data.map((item:any)=>item.id))

      // Append the new child data to the node with the given nodeId
      const updatedData = appendChildData(
        {...HierarchyAllData,
          children:HierarchyAllData?.children?.filter((item:any)=>!ids.has(item?.id))
        },
        nodeId,
        response.data
      );

      


      // Update the state with the modified hierarchy
      setHierarchyAllData(updatedData);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error("Failed to fetch child data:", error);
    }
  };




  useImperativeHandle(childRef, () => ({
    fetchChildData,
  }));

  const handlePrint = useReactToPrint({
    content: () => imageIframeRef.current,
  });

  const PrintQRCode = () => {
    setQRLoader(true);
    API.get("/xcpep/vault_info/", { vault: ModalData?.id })
      .then((res1: any) => {
        setContentRef(res1?.data[0]?.qr_link);
        setTimeout(() => {
          setQRLoader(false);
          handlePrint();
          setAnchorEl(null);
        }, 1000);
      })
      .catch(() => {
        setQRLoader(false);
        console.log("Server Error");
      });
  };

  const FlagHandler = (e: any, data: any) => {
    FlagModal.open();
    setModalData(data);
  };
  const AgentPartModalHandler = (e: any, data: any, isProduct: boolean) => {
    e?.stopPropagation();
    AgentDataModal.open();
    setModalData(data);
    setIsProduct(isProduct);
  };
  const costroute = () => {
    setAnchorEl(null);
    window.open(
      `/#/createcostingpart/${projectId}/${projectName}/${ModalData?.name}/${topVault}/${ModalData?.id}/0/0/0/0/0/0/0/0/0/0/true`
    );
  };

  const SucessAndErrorMsg = (type: any) => {
    if (type == true) {
      Swal.fire({
        html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
<br />
<p style="color:#007fff;"> System Added Successfully
</p>   
</div>`,
      });
    }
    if (type == false) {
      Swal.fire({
        icon: "error",
        html: `<div>
<br />
<p style="color:red;">Server Error
</p>   
 </div>`,
      });
    }
  };

  const SucessAndErrorMsgVault = () => {
    {
      Swal.fire({
        html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
<br />
<p style="color:#007fff;"> Part/Assembly Added Successfully
</p>   
</div>`,
      });
    }
  };

  const costcomapreroute = () => {
    setrouteLoader(true);

    API.get("/xcpep/vault_info/", { vault: ModalData?.id })
      .then((res: any) => {
        if (res?.data[0]) {
          sessionStorage.setItem(
            "Comparenode",
            JSON.stringify(res?.data[0]?.ansectors_name)
          );
          window.open(
            `/#/newcompare/${projectId}/${1}/${res?.data[0]?.product_list?.toString()}/${res?.data[0]?.abbreviation
            }/${res?.data[0]?.s_name}/${res?.data[0]?.part_name}/1/vehicle/${res?.data[0]?.is_leaf
            }/${res?.data[0]?.depth}/${res?.data[0]?.path}`
          );
        }
        // if (abbreviation == "product") {
        //   history.push(
        //     `
        //   );
        // } else {
        //   history.push(
        //     `/newcompare/${projectId}/${1}/${res?.data[0]?.product_list}/${res?.data[0]?.abbreviation}/${value}/${name}/${Count}/${TopId}/${leafNode}/${depth}/${path}`
        //   );
        // }
        setrouteLoader(false);
      })
      .catch(() => {
        setrouteLoader(false);
        console.log("Server Error");
      });
  };
  const CutPart = () => {
    setModalData(ModalData);
    movemodal.open();
    sessionStorage.setItem("moveId", ModalData?.id);

    setAnchorEl(null);
  };

  let CopyId = sessionStorage?.getItem("copyId");
  let MoveId = sessionStorage?.getItem("moveId");
  // @ts-ignore
  // @ts-ignore

  let matchquery = sessionStorage?.getItem("TargetIds");
  // @ts-ignore
  const SucessAndCoptpart = () => {
    {
      Swal.fire({
        html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
<br />
<p style="color:#007fff;"> You will be notified once a part/assembly is successfully copied.
</p>   
</div>`,
      });
    }
  };
  const SucessAndMovepart = () => {
    {
      Swal.fire({
        html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
<br />
<p style="color:#007fff;"> You will be notified once a part/assembly is successfully moved.
</p>   
</div>`,
      });
    }
  };
  const SucessAndCoptpartToOther = () => {
    {
      Swal.fire({
        html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
  <br />
  <p style="color:#007fff;"> You will be notified once a part/assembly is successfully copied.
  </p>   
  </div>`,
      });
    }
  };

  const MasterValodationhandler = () => {
    setmastervalidationloader(true);
    API.get(`xcpep/bom_hirearchy_function/`, {
      master_validation: true,
      top_vault: topVault,
    })
      .then(() => {
        setmastervalidationloader(false);
        {
          Swal.fire({
            html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
    <br />
    <p style="color:#007fff;"> All subsystems/assemblies/parts have been validated successfully.
    </p>   
    </div>`,
          });
        }

        setParentsCounter((prev: any) => prev + 1);
      })
      .catch(() => {
        {
          Swal.fire({
            icon: "error",
            html: `<div>
    <br />
    <p style="color:#007fff;"> Server Error.
    </p>   
    </div>`,
          });
        }
        setmastervalidationloader(false);
        console.log("Sever error");
      });
  };
  const handleRedirect = (module: any, treeabbr: any, id: any) => {
    if (module === "w") {
      API.get(
        "/customer/check_consolidate_downoad/",
        {
          ...getVault(treeabbr, id),
          with_image: true,
          consolidate_type: "BOM",
        },
        0
      ).then((res: any) => {
        if (res.data.status === "Failed") {
          setIconText("failed");
          setContentText(res.data.message);
          openStatusContent.open();
        } else if (res.data.status === "First Download") {
          setIconText("firstdownload");
          setContentText(res.data.message);
          openStatusContent.open();
          API.get(
            "customer/consolidated_report/",
            {
              ...getVault(treeabbr, id),
              with_image: true,
              consolidate_type: "BOM",
            },
            0
          ).then((res: any) => {
            window.open(res?.data, "_self");
          });
        } else if (res.data.status === "In Progress") {
          setIconText("reportProgress");
          setContentText(res.data.message);
          openStatusContent.open();
        } else if (res.data.status === "Completed") {
          //  openStatusContent.open();
          window.open(res?.data?.url, "_self");
        }
      });
    } else if (module === "w/o") {
      API.get(
        "/customer/check_consolidate_downoad/",
        {
          ...getVault(treeabbr, id),
          with_image: false,
          consolidate_type: "BOM",
        },
        0
      ).then((res: any) => {
        if (res.data.status === "Failed") {
          setIconText("failed");
          setContentText(res.data.message);
          openStatusContent.open();
        } else if (res.data.status === "First Download") {
          setIconText("firstdownload");
          setContentText(res.data.message);
          openStatusContent.open();
          API.get(
            "customer/consolidated_report/",
            {
              ...getVault(treeabbr, id),
              with_image: false,
              consolidate_type: "BOM",
            },
            0
          ).then((res: any) => {
            window.open(res?.data, "_self");
          });
        } else if (res.data.status === "In Progress") {
          setIconText("reportProgress");
          setContentText(res.data.message);
          openStatusContent.open();
        } else if (res.data.status === "Completed") {
          //  openStatusContent.open();
          window.open(res?.data?.url, "_self");
        }
      });
    }
  };

  const handleRedirectSingle = (id: any) => {
    setIconText("firstdownload");
    setContentText(
      "We’ve got this report downloading faster than a cheetah running. You will also receive a notification.But in the meantime, keep using xcPEP like the productivity champion !"
    );
    openStatusContent.open();
    API.get(
      "customer/individual_report/",
      { vault: id, individual_type: "BOM" },
      0
    )
      .then((res: any) => {
        window.open(res?.data, "_self");
      })
      .catch(() => { });
  };

  // useEffect(() => {
  //   // Store the expanded nodes in sessionStorage
  //   sessionStorage.setItem("BOMCreateNode", JSON.stringify(expanded));
  // }, [expanded]);
 

  useEffect(() => {
    setTimeout(
      () =>
        document.getElementById(locatorCount?.id)?.scrollIntoView({
          block: "nearest",
          inline: "start",
          behavior: "smooth",
        }),
      1000
    );
  }, [locatorCount]);

  return (
    <>
   
    {contentRef && (
        <>
          <Box className={styles.centeredContent}>
            <img
              ref={imageIframeRef}
              src={contentRef}
              height={200}
              width={200}
            />
          </Box>
        </>
      )}
      {AddBOMPartModal.isOpen && (
        <BOMEntryAddBOMPartModal
          onCloseModal={AddBOMPartModal.close}
          isOpen={AddBOMPartModal.open}
          getData={ModalData}
          copypartmsg={copypartmsg}
          AssemblyData={PartAssemblyData}
          setParentsCounter={setParentsCounter}
          SucessAndErrorMsgVault={SucessAndErrorMsgVault}
        />
      )}

      {FlagModal.isOpen && (
        <BOMFlagModal
          onCloseModal={FlagModal.close}
          isOpen={FlagModal.open}
          getData={ModalData}
        />
      )}
      {AgentDataModal?.isOpen && (
        <AgentModal
          onCloseModal={AgentDataModal.close}
          isOpen={AgentDataModal.open}
          getData={ModalData}
          isProduct={isProduct}
        />
      )}

      {sheetview?.isOpen && (
        <SheetViewModal
          onCloseModal={sheetview.close}
          isOpen={sheetview.open}
          getData={ModalData}
        />
      )}

      {CopytoOtherModal.isOpen && (
        <BOMEntryCopytoOtherModal
          onCloseModal={CopytoOtherModal.close}
          isOpen={CopytoOtherModal.open}
          getData={ModalData}
          setParentsCounter={setParentsCounter}
          SucessAndCoptpartToOther={SucessAndCoptpartToOther}
        />
      )}

      {AddSystemModal.isOpen && (
        <BOMEntryAddSystemModal
          onCloseModal={AddSystemModal.close}
          isOpen={AddSystemModal.open}
          getData={ModalData}
          SystemData={SystemData}
          SucessAndErrorMsg={SucessAndErrorMsg}
          setParentsCounter={setParentsCounter}
        />
      )}

      {WeightAlertModal.isOpen && (
        <BOMEntryWeightAlertModal
          onCloseModal={WeightAlertModal.close}
          isOpen={WeightAlertModal.open}
          queryData={ModalData}
        />
      )}
      {copymodal.isOpen && (
        <BOMCopyModal
          onCloseModal={copymodal.close}
          isOpen={copymodal.open}
          getData={ModalData}
          copytype={copytype}
          SucessAndCoptpart={SucessAndCoptpart}
          setParentsCounter={setParentsCounter}
        />
      )}

      {movemodal.isOpen && (
        <MovePartModal
          onCloseModal={movemodal.close}
          isOpen={movemodal.open}
          getData={ModalData}
          SucessAndMovepart={SucessAndMovepart}
          setParentsCounter={setParentsCounter}
        />
      )}

      {
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          {userProfilePic && userProfilePic[0]?.is_superuser && (
            <>
              {ModalData?.abbreviation == "product" && (
                <MenuItem
                  sx={{ color: "primary.main", fontSize: "1rem" }}
                  onClick={() => MasterValodationhandler()}
                >
                  Master Validation{" "}
                  <p style={{ textAlign: "center" }}>
                    {mastervalidationloader && <CircularProgress size={20} />}
                  </p>
                </MenuItem>
              )}
            </>
          )}

          {ActionUser && ActionUser.includes("C") && (
            <>
              {ModalData?.abbreviation == "product" ? (
                <>
                  {PartLoader ? (
                    <MenuItem sx={{ color: "primary.main", fontSize: "1rem" }}>
                      Add Part
                      <p style={{ textAlign: "center" }}>
                        <CircularProgress size={20} />
                      </p>
                    </MenuItem>
                  ) : (
                    <MenuItem
                      sx={{ color: "primary.main", fontSize: "1rem" }}
                      onClick={(e: any) => AddSystemHandler(e, ModalData)}
                    >
                      Add System
                    </MenuItem>
                  )}
                </>
              ) : (
                <>
                  {ModalData?.is_leaf == false ? (
                    <>
                      {PartLoader ? (
                        <MenuItem
                          sx={{ color: "primary.main", fontSize: "1rem" }}
                        >
                          Add Part
                          <p style={{ textAlign: "center" }}>
                            <CircularProgress size={20} />
                          </p>
                        </MenuItem>
                      ) : (
                        <MenuItem
                          sx={{ color: "primary.main", fontSize: "1rem" }}
                          onClick={() => AddBOMPartHandler()}
                        >
                          Add Part
                        </MenuItem>
                      )}
                    </>
                  ) : (
                    <></>
                  )}
                </>
              )}
            </>
          )}

          {ActionUser && ActionUser?.includes("D") && (
            <>
              {ModalData?.abbreviation !== "product" && (
                <MenuItem
                  sx={{ color: "primary.main", fontSize: "1rem" }}
                  onClick={() => DeleteHanlder()}
                >
                  Delete Part
                </MenuItem>
              )}
            </>
          )}
          {ActionUser && ActionUser?.includes("C") && (
            <>
              {
                <MenuItem
                  sx={{
                    color: "primary.main",
                    fontSize: "1rem",
                    opacity: ModalData?.abbreviation == null ? "1" : "0.5",
                  }}
                  onClick={() => ModalData?.abbreviation == null && CopyPart()}
                >
                  Copy Part
                </MenuItem>
              }
              {
                <MenuItem
                  sx={{
                    color: "primary.main",
                    fontSize: "1rem",
                    opacity: ModalData?.abbreviation == null ? "1" : "0.5",
                    // opacity: ModalData?.status == 3 ? "1" : "0.5",
                  }}
                  onClick={() => ModalData?.abbreviation == null && CutPart()}
                >
                  Cut
                </MenuItem>
              }

            </>
          )}

          {ActionUser && ActionUser?.includes("C") && (
            <>
              {ModalData?.abbreviation == null && (
                <MenuItem
                  sx={{ color: "primary.main", fontSize: "1rem" }}
                  onClick={() => CopytoOtherHandler()}
                >
                  Copy to other Project/Product
                </MenuItem>
              )}
            </>
          )}
          {ModalData?.is_leaf == false && (
            //@ts-ignore
            <NestedMenuItem
              style={{
                color: "primary.main",
              }}
              label={`Download Report`}
              parentMenuOpen={open}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  padding: "0 0.5rem",
                  color: "primary.main",
                }}
              >
                <MenuItem
                  sx={{
                    fontSize: "1rem",
                    color: "primary.main",
                    padding: "0.2rem 0.5rem",
                    // borderBottom:'1px solid #8080801c !important'
                  }}
                  onClick={() =>
                    handleRedirect(
                      "w",
                      HierarchyAllData?.abbreviation,
                      HierarchyAllData?.id
                    )
                  }
                >
                  Consolidated with images
                </MenuItem>
                <MenuItem
                  sx={{
                    fontSize: "1rem",
                    color: "primary.main",
                    padding: "0.2rem 0.5rem",
                  }}
                  onClick={() =>
                    handleRedirect(
                      "w/o",
                      HierarchyAllData?.abbreviation,
                      HierarchyAllData?.id
                    )
                  }
                >
                  Consolidated w/o images
                </MenuItem>
                <MenuItem
                  sx={{
                    fontSize: "1rem",
                    color: "primary.main",
                    padding: "0.2rem 0.5rem",
                    // borderBottom:'1px solid #8080801c !important'
                  }}
                  onClick={
                    () => {
                      setIconText("firstdownload");
                      setContentText(
                        "We’ve got this report downloading faster than a cheetah running.But in the meantime, keep using xcPEP like the productivity champion !"
                      );
                      openStatusContent.open();
                      API.get(
                        "/xcpep/download_part_image/",
                        {
                          top_vault:
                            HierarchyAllData?.abbreviation === "product"
                              ? HierarchyAllData?.id
                              : undefined,
                          vault:
                            HierarchyAllData?.abbreviation !== "product"
                              ? HierarchyAllData?.id
                              : undefined,
                        },
                        0
                      )
                        .then((res: any) => {
                          window.open(res?.data, "_self");
                        })
                        .catch(() => {
                          Swal.fire({
                            icon: "error",
                            html: `<div>
                                 <br />
                                 <p style="color:"red">Server Error</p>   
                                  </div>`,
                          });
                        });
                    }
                    // handleRedirect(
                    //   "w",
                    //   HierarchyAllData?.abbreviation,
                    //   HierarchyAllData?.id
                    // )
                  }
                >
                  Download vault Images as ZIP
                </MenuItem>
              </Box>
            </NestedMenuItem>
          )}
          {ModalData?.is_leaf == true && (
            <MenuItem
              sx={{ color: "primary.main", fontSize: "1rem" }}
              onClick={() => handleRedirectSingle(HierarchyAllData?.id)}
            >
              Part BOM excel Download
            </MenuItem>
          )}
          {ModalData?.is_leaf == true && (
            <MenuItem
              sx={{ color: "primary.main", fontSize: "1rem" }}
              onClick={() => {
                setIconText("firstdownload");
                setContentText(
                  "We’ve got this report downloading faster than a cheetah running.But in the meantime, keep using xcPEP like the productivity champion !"
                );
                openStatusContent.open();
                API.get(
                  "/xcpep/download_part_image/",
                  {
                    top_vault:
                      HierarchyAllData?.abbreviation === "product"
                        ? HierarchyAllData?.id
                        : undefined,
                    vault:
                      HierarchyAllData?.abbreviation !== "product"
                        ? HierarchyAllData?.id
                        : undefined,
                  },
                  0
                )
                  .then((res: any) => {
                    window.open(res?.data, "_self");
                  })
                  .catch(() => {
                    Swal.fire({
                      icon: "error",
                      html: `<div>
                           <br />
                           <p style="color:"red">Server Error</p>   
                            </div>`,
                    });
                  });
              }}
            >
              Download vault Images as ZIP
            </MenuItem>
          )}
          {ModalData?.abbreviation !== "product" && (
            <>
              {ModalData?.is_leaf == false && (
                <MenuItem
                  sx={{ color: "primary.main", fontSize: "1rem" }}
                  onClick={() => Sheetviewhandler()}
                >
                  Sheet View
                </MenuItem>
              )}
            </>
          )}
          {userAction && userAction?.costing && (
            <>
              {ModalData?.cost_status >= 1 && (
                <MenuItem
                  sx={{ color: "primary.main", fontSize: "1rem" }}
                  onClick={() => costroute()}
                >
                  Go to Costing Create
                </MenuItem>
              )}
            </>
          )}
          {ModalData?.is_leaf == true &&
            (QRLoader ? (
              <MenuItem
                sx={{
                  color: "primary.main",
                  fontSize: "1rem",
                  display: "flex",
                  alignItems: "center",
                  columnGap: "0.5rem",
                }}
              >
                Print QR Code
                <p style={{ textAlign: "center" }}>
                  <CircularProgress size={16} />
                </p>
              </MenuItem>
            ) : (
              <MenuItem
                sx={{ color: "primary.main", fontSize: "1rem" }}
                onClick={() => PrintQRCode()}
              >
                Print QR Code
              </MenuItem>
            ))}

          {ModalData?.abbreviation !== "product" &&
            (routeLoader ? (
              <p style={{ textAlign: "center" }}>
                <CircularProgress size={16} />
              </p>
            ) : (
              <MenuItem
                sx={{ color: "primary.main", fontSize: "1rem" }}
                onClick={() => costcomapreroute()}
              >
                Go to Compare Page
              </MenuItem>
            ))}
        </Menu>
      }
      <TreeView
        expanded={expanded}
        key={HierarchyAllData?.id}
        id={HierarchyAllData?.id}
        className={styles.treetemp}
        // Control the icon and expand behavior based on is_leaf
        defaultCollapseIcon={
          !HierarchyAllData?.is_leaf && (
            <FolderOpenIcon style={{ color: HierarchyAllData?.color }} />
          )
        }
        defaultExpandIcon={
          !HierarchyAllData?.is_leaf && (
            <FolderIcon sx={{ color: HierarchyAllData?.color }} />
          )
        }
        defaultEndIcon={
          HierarchyAllData?.is_leaf ? (
            <InsertDriveFileIcon sx={{ color: HierarchyAllData?.color }} />
          ) : (
            <FolderIcon sx={{ color: HierarchyAllData?.color }} />
          )
        }
        onNodeSelect={(e: any) => {
          setLocatorCount(null);
          handleChange(e, HierarchyAllData?.id, HierarchyAllData?.abbreviation)
        }
          
        }
        selected={selected}
        sx={{
          "&.MuiTreeItem-content{}.Mui-selected": {
            backgroundColor:
              HierarchyAllData?.id == parseInt(vault)
                ? "#cee6fa !important"
                : "white !important",
          },
        }}
      >
        {loading ? (
          // Skeleton Loader Placeholder
          <StyledTreeItem
            nodeId={HierarchyAllData?.id}
            title={titleString && url.includes("costing") && titleString}
          label={
            <>
            <Skeleton variant="text" width={200} />
            <Skeleton variant="text" width={100} />
          </>
          }
          />
        ) : (
          <StyledTreeItem
            nodeId={HierarchyAllData?.id}
            label={
              <Typography className={styles.treeViewData}>
              <Typography
                style={{
                  fontSize: "12px",
                  color: getColor(HierarchyAllData?.status),
                }}
                className={styles.treeViewText}
                onContextMenu={(event: any) =>
                  handleClick(
                    event,
                    HierarchyAllData.id,
                    HierarchyAllData.is_leaf,
                    HierarchyAllData
                  )
                }
              >
                {HierarchyAllData?.part_no} x {HierarchyAllData?.quantity}
                <br /> {HierarchyAllData?.name}
              </Typography>

              {newVault == undefined || newVault == null ? (
                ""
              ) : (
                <>
                  {newVault == HierarchyAllData?.id && (
                    <span className={styles.blink}>New</span>
                  )}
                </>
              )}
              <Typography className={styles.treeViewText}>
                {HierarchyAllData?.is_copy && (
                  <CopyrightIcon sx={{ color: "red" }} />
                )}

                {HierarchyAllData?.is_copy == false && (
                  <>
                    {HierarchyAllData?.is_leaf == false && (
                      <>
                        {HierarchyAllData?.status !== 3 &&
                          HierarchyAllData?.abbreviation != "product" && (
                            <img
                              title="Agent"
                              src={AgentIcon}
                              style={{ height: "1.5rem" }}
                              onClick={(e: any) =>
                                AgentPartModalHandler(
                                  e,
                                  HierarchyAllData,
                                  false
                                )
                              }
                            />
                          )}
                        {HierarchyAllData?.abbreviation == "product" && (
                          <img
                            title="Agent"
                            src={AgentIcon}
                            style={{ height: "1.5rem" }}
                            onClick={(e: any) =>
                              AgentPartModalHandler(e, HierarchyAllData, true)
                            }
                          />
                        )}
                      </>
                    )}

                    {HierarchyAllData?.weight_validation !== 0 && (
                      <WarningRoundedIcon
                        titleAccess={`The Difference is (${HierarchyAllData?.weight_validation})gm`}
                        color="error"
                        onClick={(e: any) =>
                          WeightAlertHandler(e, HierarchyAllData)
                        }
                      />
                    )}

                    {HierarchyAllData?.flag == true && (
                      <FlagIcon
                        color="error"
                        sx={{ cursor: "pointer" }}
                        onClick={(e: any) => FlagHandler(e, HierarchyAllData)}
                      />
                    )}
                  </>
                )}

                {ActionUser && ActionUser?.includes("C") && (
                  <>
                    {HierarchyAllData?.is_leaf == false && (
                      <>
                        {systemloader ? (
                          <p style={{ textAlign: "center" }}>
                            <CircularProgress size={20} />
                          </p>
                        ) : (
                          <AddCircleOutlineIcon
                            titleAccess={
                              HierarchyAllData?.abbreviation == "product"
                                ? "Add Systems"
                                : "Add Assembly/Part"
                            }
                            onClick={(e: any) =>
                              HierarchyAllData?.abbreviation == "product"
                                ? AddSystemHandler(e, HierarchyAllData)
                                : AddBOMPartHandlerSystem(e, HierarchyAllData)
                            }
                          />
                        )}
                      </>
                    )}
                  </>
                )}
              </Typography>
            </Typography>
            }
          >
            
            {HierarchyAllData?.children?.map((child: any) => {
              return (
                <BOMTreeViewCreate
                  key={child.id}
                  ref={childRef} // Each child gets its own ref
                  HierarchyData={child}
                  onSelectCallback={onSelectCallback}
                  setParentsCounter={setParentsCounter}
                  setrecyclebinCounter={setrecyclebinCounter}
                  locatorCount={locatorCount}
                  setLocatorCount={setLocatorCount}
                />
              );
            })}
          </StyledTreeItem>
        )}
      </TreeView>
    </>
  );
});

export default BOMTreeViewCreate;
