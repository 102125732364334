const materialcategories = [
    {
      "category": "STEEL",
      "count": 76
    },
    {
      "category": "ALUMINIUM",
      "count": 27
    },
    {
      "category": "PLASTIC",
      "count": 333
    },
    {
      "category": "BRASS",
      "count": 12
    },
    {
      "category": "COPPER",
      "count": 5
    },
    {
      "category": "RUBBER",
      "count": 11
    },
    {
      "category": "FABRIC",
      "count": 0
    },
    {
      "category": "NICKEL",
      "count": 1
    },
    {
      "category": "FRP",
      "count": 0
    },
    {
      "category": "OTHERS",
      "count": 74
    }
  ]

  const BOPCategories = [
    {
      "id": 1,
      "category": "Fasteners",
      "count": 873
    },
    {
      "id": 3,
      "category": "Bearings",
      "count": 75
    },
    {
      "id": 4,
      "category": "Connectors",
      "count": 138
    },
    {
      "id": 5,
      "category": "Consumables",
      "count": 72
    },
    {
      "id": 9,
      "category": "Uncategorized",
      "count": 5292
    },
    {
      "id": 2,
      "category": "Electronic Child Components",
      "count": 1064
    },
    {
      "id": 10,
      "category": "Electrical & Electronics Proprietary Parts",
      "count": 2
    },
    {
      "id": 11,
      "category": "Mechanical Proprietory Parts",
      "count": 14
    }
  ]


  export { materialcategories, BOPCategories };