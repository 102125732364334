import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  Tooltip,
  TooltipProps,
  Typography,
  tooltipClasses,
} from "@mui/material";
import { styled } from '@mui/material/styles';
import React, { useRef } from "react";
import { Draggable } from "react-beautiful-dnd";
import avatar from "../../Assets/images/avatar.svg";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import Image from "../../ViewModule/FeatureLandingPage/ArchitectureImageCard";
import loaderImage from "../../Assets/images/loading-screen.gif";
import InfoIcon from "@mui/icons-material/Info";
import swal from "sweetalert";
import EditIcon from "@mui/icons-material/Edit";
import CloseIcon from "@mui/icons-material/Close";
import { API } from "../../api-services";
import moment from "moment";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import { UserActivationActivityLog } from "./UserActivationActivityLog";
import AddEditUserFormModal from "./AddEditUserFormModal";
import RestoreIcon from "@mui/icons-material/Restore";
import { useBaseModal } from "../SearchPage/useBaseModal";
import { useHistory } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";

export interface ISingleUserCardProps {
  index: number;
  item: any;
  setApiCallCount: any;
}

export default function SingleUserCard(Props: ISingleUserCardProps) {
  const { index, item, setApiCallCount } = Props;
  const history = useHistory();
  const activitylogModal = useBaseParamsModal();
  const [comment, setComment] = React.useState<any>("");
  const addEditModal = useBaseParamsModal();
  const dateinputref = useRef<any>();
  const currentDate = new Date().toISOString().split('T')[0];
  const [open, setOpen] = React.useState<boolean>(false);
  const [openTooltip,setCloseTooltip] = React.useState<boolean>(false);
  const [selectedDate, setSelectedDate] = React.useState<any>("");
  const closeToolTip=()=>{
    setCloseTooltip(false)
  }
  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} open={openTooltip} onClose={closeToolTip} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: "rgba(0, 0, 0, 0.87)",
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));

  const handleChange = (ed: any) => {
    setSelectedDate(ed.target.value);
  };
  const handleClose = () => {
    history.push("/user-management");
    setOpen(false);
  };
  const handleChangetext = (e: any) => {
    setComment(e.target.value);
  };
  const CloseButtonClicked = (item: any) => {
    closeToolTip()
    swal({
      title: "Are you sure?",
      text: "User will be deactivated.",
      // icon: "warning",
      buttons: ["No", "Yes"],
    })
      .then((isData: any) => {
        if (isData) {
          API.patch(
            `auth/user/${item.id}/`,
            {
              is_active: item?.is_active ? false : true,
              user_expiration: item.is_active
                ? undefined
                : moment().format("YYYY-MM-DD").toString(),
                comments: comment,
            },
            {},
            0
          ).then((res: any) => {
            setApiCallCount((prev: any) => prev + 1);
          });
        }
      })
      .catch((e: any) => {});
  };

  var AvatarName = (item: any) => {
    const [first, last] = item?.split(" ");

    const FirstLatter = first?.charAt(0);

    const lastWord = last?.charAt(0);

    return `${FirstLatter}${lastWord}`;
  };

  return (
    <>
      <Draggable draggableId={item?.id.toString()} index={index}>
        {(provided, snapshot) => (
          <Box
            sx={{
              borderRadius: "5px",
              boxShadow:
                "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px",
              display: "flex",
              margin: "0 0.5rem",
              marginBottom: "0.5rem",
              backgroundColor:
                item.user_category == "External" ? "yellow" : "white",
              "&:hover": {
                backgroundColor: "#d0eaff",
                transform: "scale(1.03)",
                transition: "transform 0.5s ease",
              },
            }}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            ref={provided.innerRef}
          >
            <Box sx={{ margin: "auto", width: "6rem", padding: "10px" }}>
              {item?.user_info ? (              
                <Image
                  src={item?.user_info}
                  placeholderImg={loaderImage}
                  title={item?.fullname}
                  style={{
                    width: "5rem",
                    height: "5rem",
                    borderRadius: "50%",
                    objectFit: "cover",
                  }}
                />
              ) : (               
                <Avatar
                  src="/broken-image.jpg"
                  sx={{
                    width: "5rem",
                    height: "5rem",
                    fontSize: "2rem !important",
                    objectFit: "cover",
                    backgroundColor: "#007fff !important",
                    textTransform:'uppercase',
                  }}
                >
                  {AvatarName(item?.fullname)}
                </Avatar>
              )}
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "-2rem",
                  marginLeft: "0rem",
                }}
              >
                {item.is_active == true ? (
                  <Box
                    sx={{
                      borderRadius: "50%",
                      height: "1.5rem",
                      width: "1.5rem",
                      backgroundColor: "#5bad06",
                      position: "relative",
                      zIndex: "1",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <CheckIcon sx={{ color: "white", fontSize: "1.2rem" }} />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      borderRadius: "50%",
                      height: "1.5rem",
                      width: "1.5rem",
                      backgroundColor: "red",
                      position: "relative",
                      zIndex: "1",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <ClearIcon sx={{ color: "white", fontSize: "1.2rem" }} />
                  </Box>
                )}
              </Box>
            </Box>
            <Box sx={{ width: "100%", padding: "0.5rem" }}>
              <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                <Typography
                  sx={{
                    fontSize: "1.1rem",
                    color: "black",
                    fontWeight: "bold",
                    marginTop: "0.5rem",
                    textTransform: "capitalize",
                  }}
                >
                  {item?.fullname}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "10px",
                  }}
                >
                  <InfoIcon
                    titleAccess="Info"
                    sx={{ fontSize: "1.2rem", cursor: "pointer" }}
                    onClick={() => activitylogModal.open(item.id)}
                  />
                  {item?.is_active && (
                    <EditIcon
                      titleAccess="Edit Details"
                      sx={{ fontSize: "1.2rem", cursor: "pointer" }}
                      onClick={() => addEditModal.open(item)}
                    />
                  )}
                  {item.is_active ? (
                   <>
                      <CloseIcon
                        titleAccess="Deactivate"
                        sx={{
                          fontSize: "1.2rem",
                          cursor: "pointer",
                          color: "red",
                        }}
                         onClick={() => setCloseTooltip(true)}
                      />
                      <Dialog
                        PaperProps={{
                          style: { borderRadius: 10, width: "20%" },
                        }}
                        open={openTooltip}
                        onClose={closeToolTip}
                      >
                        <Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              height: "2.5rem",
                              width: "100%",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "1rem",
                                fontWeight: "600",
                                width: "100%",
                                textAlign: "center",
                                marginRight: "-2rem",
                              }}
                            >
                              Deactivate User
                            </Typography>
                            <CancelIcon
                              titleAccess="Close"
                              sx={{ cursor: "pointer", margin: "0.5rem" }}
                              onClick={closeToolTip}
                            />
                          </Box>
                          <Divider
                            sx={{
                              borderColor: "primary.light",
                              margin: "0 1rem",
                              marginBottom: "1rem",
                            }}
                          />
                          <Box sx={{ width: "100%", textAlign: "center" }}>
                            {/* <CheckCircleOutlineIcon sx={{ color: 'green', fontSize: '5rem', textAlign:'center' }} /> */}
                            {/* <WarningAmberRoundedIcon /> */}
                            {/* {` Alert`} */}
                          </Box>
                          <DialogContent
                            sx={{
                              padding: "0 20px 10px",
                              alignContent: "center",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                columnGap: "1rem",
                              }}
                            >
                           
                              <input
                                type="text"
                                value={comment}
                                onChange={handleChangetext}
                                min={currentDate}
                                placeholder="Comments"
                                style={{
                                  color: "#007fff",
                                  borderTop: "none",
                                  borderLeft: "none",
                                  borderRight: "none",
                                  borderBottom: "1px solid #007fff",
                                  paddingBottom: "5px",
                                  fontFamily: "roboto",
                                  height: "5rem",
                                  width: "100%",
                                }}
                              />
                            </Box>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              size="small"
                              disabled={comment?.length > 0 ? false : true}
                              variant="contained"
                              onClick={() => CloseButtonClicked(item)}
                            >
                              Deactivate User
                            </Button>
                          </DialogActions>
                        </Box>
                      </Dialog>
                      </>
                  ) : (
                    <>
                      <RestoreIcon
                        titleAccess="Reactivate"
                        sx={{
                          fontSize: "1.2rem",
                          cursor: "pointer",
                          color: "#5bad06",
                        }}
                        onClick={() => setOpen(true)}
                      />
                      {/* <input
                        type="date"
                        // style={{ display: "none" }}
                        ref={dateinputref}
                      /> */}
                      <Dialog
                        PaperProps={{
                          style: { borderRadius: 10, width: "20%" },
                        }}
                        open={open}
                        onClose={handleClose}
                      >
                        <Box>
                          <Box
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              height: "2.5rem",
                              width: "100%",
                            }}
                          >
                            <Typography
                              style={{
                                fontSize: "1rem",
                                fontWeight: "600",
                                width: "100%",
                                textAlign: "center",
                                marginRight: "-2rem",
                              }}
                            >
                              Select User Expiration
                            </Typography>
                            <CancelIcon
                              titleAccess="Close"
                              sx={{ cursor: "pointer", margin: "0.5rem" }}
                              onClick={handleClose}
                            />
                          </Box>
                          <Divider
                            sx={{
                              borderColor: "primary.light",
                              margin: "0 1rem",
                              marginBottom: "1rem",
                            }}
                          />
                          <Box sx={{ width: "100%", textAlign: "center" }}>
                            {/* <CheckCircleOutlineIcon sx={{ color: 'green', fontSize: '5rem', textAlign:'center' }} /> */}
                            {/* <WarningAmberRoundedIcon /> */}
                            {/* {` Alert`} */}
                          </Box>
                          <DialogContent
                            sx={{
                              padding: "0 20px 10px",
                              alignContent: "center",
                            }}
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                columnGap: "1rem",
                              }}
                            >
                              <input
                                type="date"
                                onChange={handleChange}
                                min={currentDate}
                                style={{
                                  color: "#007fff",
                                  borderTop: "none",
                                  borderLeft: "none",
                                  borderRight: "none",
                                  borderBottom: "1px solid #007fff",
                                  paddingBottom: "5px",
                                  fontFamily: "roboto",
                                  width: "100%",
                                }}
                              />
                              <input
                                type="text"
                                value={comment}
                                onChange={handleChangetext}
                                placeholder="Comments"
                                style={{
                                  color: "#007fff",
                                  borderTop: "none",
                                  borderLeft: "none",
                                  borderRight: "none",
                                  borderBottom: "1px solid #007fff",
                                  paddingBottom: "5px",
                                  fontFamily: "roboto",
                                  height: "5rem",
                                  width: "100%",
                                }}
                              />
                            </Box>
                          </DialogContent>
                          <DialogActions>
                            <Button
                              size="small"
                              disabled={selectedDate?.length > 0 ? false : true}
                              variant="contained"
                              onClick={() => {
                                API.patch(
                                  `auth/user/${item.id}/`,
                                  {
                                    is_active: true,
                                    user_expiration: `${selectedDate}`,
                                    comments: comment,
                                  },
                                  {},
                                  0
                                ).then((res: any) => {
                                  setApiCallCount((prev: any) => prev + 1);
                                });
                              }}
                            >
                              Activate User
                            </Button>
                          </DialogActions>
                        </Box>
                      </Dialog>
                    </>
                  )}
                </Box>
              </Box>
              <Typography
                sx={{
                  fontSize: "0.9rem",
                  color: "black",
                  marginLeft: "0.5rem",
                }}
              >
                {item?.email}
              </Typography>
              <Typography
                sx={{
                  fontSize: "0.9rem",
                  color: "black",
                  marginLeft: "0.5rem",
                }}
              >
                {item?.phone ? `${item?.country_code}-${item?.phone}` : "--"}
              </Typography>
              {item?.is_expired ? (
                <Typography sx={{ fontSize: "0.9rem", color: "red" }}>
                  Access expired
                </Typography>
              ) : (
                <Typography
                  sx={{
                    fontSize: "0.9rem",
                    color: "black",
                    // marginBottom: "0.5rem",
                  }}
                >
                  {`Access expires on ${item?.user_expiration} ( ${item?.expiry_in} Days)`}
                </Typography>
              )}
            </Box>
            {activitylogModal.isOpen && (
              <UserActivationActivityLog
                isOpen={activitylogModal.isOpen}
                onClose={activitylogModal.close}
                user={activitylogModal.propsId}
              />
            )}
          </Box>
        )}
      </Draggable>
      {addEditModal.isOpen && (
        <AddEditUserFormModal
          isOpen={addEditModal.isOpen}
          onClose={addEditModal.close}
          userDetails={addEditModal.propsId}
          setApiCallCount={setApiCallCount}
        />
      )}
    </>
  );
}
