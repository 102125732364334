import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import { useHistory } from "react-router-dom";
import {
  Backdrop,
  Chip,
  Divider,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from "@mui/material";
import { API } from "../../api-services";
import { LoadingButton } from "@mui/lab";
import CancelIcon from "@mui/icons-material/Cancel";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { DataGrid,
    GridToolbarContainer,
    GridToolbarExportContainer,
    GridCsvExportMenuItem,
    GridCsvExportOptions,
    GridToolbarColumnsButton, } from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/Delete';
import swal from "sweetalert";
import {AUTH} from "../../Redux/Services/auth.service"

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60vw",
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 0,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface IUnusedCalcListModalProps {
  isOpen: any;
  onCloseModal: any;
  unusedCalcList: any;
  setCounterApiCall: any;
  loadingCalcList: any;
}

function UnusedCalcListModal(props: IUnusedCalcListModalProps) {
  const { isOpen, onCloseModal, unusedCalcList, setCounterApiCall, loadingCalcList } = props;
  const [selectedCalc, setSelectedCalc] = React.useState<any>([]);
    const [deletedSelectedLoader, setDeletedSelectedLoader] = React.useState<boolean>(false);
    const [deletedSelectedDisable, setDeletedSelectedDisable] = React.useState<boolean>(false);
    const handleAllDelete = async(e : React.MouseEvent) => {
        console.log("handleAllDelete");
        e.stopPropagation();
    swal({
      title: `Delete Unused Calculator Permanently`,
      text: `Are you sure?`,
      icon: "error",
      //@ts-ignore
      buttons: {
        cancel: "Cancel",
        permanent: {
          text: `Delete Unused Calculator Permanently`,
          value: `deleteCalc`,
        },
      },
      closeOnClickOutside: false,
      closeOnEsc: false,
      className: "delete-swal",
    })
    .then(async (type : string) => {
        switch (type) {
            case "deleteCalc":
              setDeletedSelectedLoader(true);
              API.delete("/config/calculator_information/", {ids: selectedCalc}, 0)
                .then((res: any) => {
                  setDeletedSelectedLoader(false);
                  
                  setSelectedCalc([])
                    setCounterApiCall((prev: any) => prev +1);
                })
                .catch((err: any) => {
                  setDeletedSelectedLoader(false);
                  
                  console.log(err, "Unable to delete calculator. Server Error");
                });
              break;
            default:
              swal(`Calculators saved from deleting`, { icon: "error" });
          }
    });
}
  const CustomToolbar = (selectedCalc:any, handleAllDelete: any,  deletedSelectedLoader:any, deletedSelectedDisable:any) => (
  
    <GridToolbarContainer style={{ height: "1rem" }}>
    <span style={{ position: "absolute", right: "0rem", fontSize: "1rem", paddingRight: '1rem' }}>
    {selectedCalc?.length > 0 && <>
      <LoadingButton disabled={deletedSelectedDisable} loading={deletedSelectedLoader} onClick={handleAllDelete} sx={{marginBottom:"0.5rem"}}><DeleteIcon sx={{fontSize:'1.2rem', marginRight:'0.2rem',}}/><span style={{fontSize:"0.9rem"}}>Delete Selected</span></LoadingButton>
      </>}      
    </span>
  </GridToolbarContainer>
  );
  
  const columns: any = [
    {
        field: "name",
        headerName: "Calculator Name",
        width: 400,
        editable: false,
    },
    {
        field: "process",
        headerName: "Process",
        width: 200,
        editable: false,
    }
    ,
    {
        field: "category",
        headerName: "Category",
        width: 200,
        editable: false,
        renderCell: (cellValues: any) => {
            return (<>
                {cellValues?.row?.category == 0 ? "Overheads" : cellValues?.row?.category == 1 ? "Raw Material" : cellValues?.row?.category == 2 ? "Bought-Out Parts" : cellValues?.row?.category == 3 ? "Labour" : cellValues?.row?.category == 4 ? "Machine" : cellValues?.row?.category == 5 ? "Tool" : cellValues?.row?.category == 6 ? "Setup" : "" }
            </>)
          }

    }
  ]

  let rows: any = [];

  let unusedCalcDatagrid: any = {
    columns: columns,
    rows: unusedCalcList && unusedCalcList,
  };

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        
      >
        <Box sx={style}>
        <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: '-2rem'
              }}
            >
                Unused Calculator List
            </Typography>
            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ padding: "2rem", textAlign: "center", height: "80vh", width: "100%" }}>
            {unusedCalcList.length == 0 && <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginBottom: "1rem",
              }}
            >
              No Unused Calculation Found
            </Typography>}
            {unusedCalcList.length > 0 &&
                <DataGrid
                loading={loadingCalcList}
                headerHeight={48}
                rowHeight={48}
                rows={unusedCalcList && unusedCalcList}
                columns={columns}
                
                disableSelectionOnClick={true}
                components={{
                    Toolbar: () => {
                      return CustomToolbar(selectedCalc, handleAllDelete,  deletedSelectedLoader, deletedSelectedDisable);
                    },
                    
                  }}
                sx={{
                  "& .MuiDataGrid-checkboxInput": {
                    color: "primary.main",
                  },
                  "& ::-webkit-scrollbar": {
                    width: "0.3rem",
                    height: "0.3rem",
                  },
                  "& ::-webkit-scrollbar-thumb": {
                    // background: $thumb-color;
                    backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                    borderRadius: "10px",
                  },
                  "& ::- webkit-scrollbar-thumb: hover ": {
                    // width: "4px",
                    backgroundColor: "#045DE9 !important",
                  },
                  "::-webkit-scrollbar-track": {
                    backgroundColor: "#e1e1f3 !important",
                  },
                  "&.MuiDataGrid-root": {
                    border: 'none',
                  },
                  "&.MuiDataGrid-root .MuiDataGrid-cell": {
                    borderBottomColor: "primary.light",
                  },
      
                }}
                density={"compact"}
                pageSize={100}
                rowsPerPageOptions={[100]}
                checkboxSelection = {AUTH?.isSuperUser ? true : false}
                onSelectionModelChange={(ids) => {
                  console.log(ids,"idsidsids")
                  const selectedIDs = new Set(ids);
                    setSelectedCalc(ids);
                }}
              />
            }
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
export default UnusedCalcListModal;
