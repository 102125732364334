// -------------------------------------------------------------------------------------

import { API } from "../../api-services";
import {
  CopyProcessModelId,
  CreateProcessCardCostsArg,
  DeleteProcessCardCost,
  GetProcessCardCostsArg,
  UpdateProcessCardCostArg,
} from "../Actions/processCard.action";

const url = "cost/process/";
const copyUrl = "cost/cost_copy/";

// -------------------------------------------------------------------------------------

export default {
  get(arg: GetProcessCardCostsArg) {
    return API.get(
      url,
      {
        cost_estimation: arg.costEstimationId,
        id:arg.tableId
      },
      20000
    );
  },
  create(arg: CreateProcessCardCostsArg) {
    return API.post(url, {
      cost_estimation: arg.costEstimationId,
      processes: arg.processes,
      commodity_mapping: arg.commodityMappingId,
    });
  },
  update(arg: UpdateProcessCardCostArg) {
    return API.put(url + arg.id + "/", arg.updates);
  },
  delete(arg: DeleteProcessCardCost) {
    return API.delete(url + arg.id + "/");
  },
  processCopy(arg: CopyProcessModelId) {
    return API.post(copyUrl, {
      process_id: arg.id,
      data: arg.data ? arg.data : undefined,
    });
  },
};
