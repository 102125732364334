import React from 'react';
import ReactDOM from "react-dom";
import './index.css';
import AppRouter from './AppRouter';
import * as serviceWorker from "./Components/serviceWorker";
import 'react-toastify/dist/ReactToastify.css';
import "./Components/Assets/scss/main.scss";
import "./Components/Assets/scss/layout/_scrollbar.scss";
import "./Components/Assets/scss/layout/_scrollsnap.scss";
import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme, responsiveFontSizes, } from "@mui/material/styles";
import { appTheme } from "./themes/theme";
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
// import { darkTheme, lightTheme } from "./themes/theme"


let theme = createTheme();
theme = responsiveFontSizes(theme);

ReactDOM.render(
  <React.Fragment>
    {/* <ColorModeContext.Provider value={colorMode}> */}
      <ThemeProvider theme={appTheme}>
      <CssBaseline enableColorScheme />
        <AppRouter />
      </ThemeProvider>
    {/* </ColorModeContext.Provider> */}
  </React.Fragment>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
serviceWorker.unregister();
