import React, { useRef } from "react";
import { useEffect, useState } from "react";
import {
  Badge,
  Box,
  IconButton,
  InputBase,
  Pagination,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import styles from "./SuppliersLandingPage.module.scss";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import AddIcon from "@mui/icons-material/Add";
import Supplierpage from "./Supplierpage";
import SearchIcon from "@mui/icons-material/Search";
import UploadIcon from "@mui/icons-material/Upload";
import DownloadIcon from "@mui/icons-material/Download";
import { LoadingButton } from "@mui/lab";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { API } from "../../api-services";
import { useBaseModal } from "../../ComponentUI/SearchPage/useBaseModal";
import PlantModal from "../../ComponentUI/ProjectDirectory/PlantModal";
import { useSupplierShowDetailModel } from "./useSupplierShowDetailModel";
import SupplierShowDetailsModel from "./SupplierShowDetailsModel";
import AddSupplierModel from "./AddSupplierModel";
import { useEditSupplierDatabase } from "./useEditSupplierDatabase";
import EditSupplierModel from "./EditSupplierModel";
import swal from "sweetalert";
import recyleBin from "../../Assets/images/deletefill.svg";
import DataBaseDrawer from "../DataBaseDrawer";
import SingleSupplierCardModel from "./SingleSupplierCardModel";
import { useSingleSupplierCard } from "./useSingleSupplierCard";
import Swal from "sweetalert2";
import WarningIcon from "../../Assets/images/Warning.png";
import ErrorModalFilesMail from "../../ComponentUI/ProjectMailModule/ErrorModalFilesMail";
import SupplierUseList from "./SupplierUseList";
import { useBomCategoryUse } from "../BOM Categories/useBomCategoryUse";
import SuccessModalFilesMail from "./SuccessModalFilesMail";
import FilterSupplierModel from "./FilterSupplierModel";
import { AxiosResponse } from "axios";
import { useDocumentTitle } from "../../ui-reusable-component/useDocumentTitle";

type Anchor = "top" | "left" | "bottom" | "right";

interface Params {
  page: any;
}

interface ISuppliersLandingPageProps { }

function SuppliersLandingPage(props: ISuppliersLandingPageProps) {
  const { page } = useParams<Params>();
  useDocumentTitle( "Supplier Database" );
  const [supplierPageCount, setSupplierPageCount] = useState<any>();
  const [supplierData, setSupplierData] = useState<any>();
  const [supplierDataLoader, setSupplierDataLoader] = useState<boolean>(true);
  const [supplierDataApiCall, setSupplierDataApiCall] = useState<boolean>(true);
  const [supplierFilterShowPagination,setSupplierFilterShowPagination] = useState<boolean>(true);
  const [recyclebinItemCount, setRecyclebinItemCount] = useState<any>();
  const [search, setSearch] = useState<any>('');
  const [rowIds, setrowIds] = useState<any>();
  const [reloadRecyclebinItemCount, setReloadRecyclebinItemCount] = useState<boolean>(false);
  const [reverseUploadLoader, setReverseUploadLoader] = useState<boolean>(false)
  const [downloadLoader, setDownloadLoader] = useState<boolean>(false)
  const [ErrorModalFileMsg, setErrorModalFileMsg] = useState<any>();
  const [SuccessModalFileMsg, setSuccessModalFileMsg] = useState<any>();

  const [supplierType, setSupplierType] = useState<any>();
  const [commodityName, setCommodityName] = useState<any>([]);
  const [productCategory, setProductCategory] = useState<any>([]);
  const [systems, setSystems] = useState<any>([]);
  const [useraction,setuseraction]=useState<any>(null)

  const history = useHistory();
  const plantpopup = useBaseModal();
  const supplierDetail = useSupplierShowDetailModel();
  const addSupplierModal = useBaseModal();
  const editSupplierModal = useEditSupplierDatabase();
  const singleSupplierCardModel = useSingleSupplierCard();
  const filterSupplierModal = useBaseModal();
  const ErrorModalFiles = useBaseModal();
  const SuccessModalFiles = useBaseModal();
  const listSupplierUse = useBomCategoryUse();
  const [parentCounter, setParentsCounter] = useState(0);
  // helpers
  const inputRef = useRef<any>();
  // ------------------------------------------------------------------------------------
  //handlers:
  const browseFiles = (ed: any) => {
    return inputRef.current?.click?.();
  };

  const handleReverseUpload = async (e: any) => {
    setReverseUploadLoader(true);
    const formData = new FormData();
    formData.append("file", e?.target?.files[0])
    API.post("/api/db/supplier_reverse_uploadView/", formData, { is_oem: false }, 0).then((res: any) => {
      setReverseUploadLoader(false);
      setSuccessModalFileMsg(res.data);
      SuccessModalFiles.open();
      setSupplierDataApiCall(!supplierDataApiCall);
    }).catch((err) => {
      setReverseUploadLoader(false);
      setErrorModalFileMsg(err.response.data);
      ErrorModalFiles.open();
    })
  };

  const handleDownloadSupplierDatabase = () => {
    setDownloadLoader(true)
    API.get("/api/db/supplier_download_view/", {}, 0).then((res: any) => {
      setDownloadLoader(false)
      window.open(res?.data)
    })
  }

  useEffect(() => {
    API.get("/api/db/OEM_List_Count/", { is_oem: false, page_items: 50 }, 0).then((res: any) => {
      setSupplierPageCount(res?.data?.page_count);
    })
  }, [])

  useEffect(() => {
    if (search.length > 0) {
      if(!(supplierType== undefined || commodityName?.length == 0 || productCategory?.length == 0 || systems?.length == 0)){
        setSupplierFilterShowPagination(true);
        API.get('/api/db/supplier_mui_view/',{
          search_key:search,
          vehicle_type:productCategory?.map((item: any) => item?.id).toString(),
          commodity:commodityName?.map((item: any) => item?.id).toString(),
          system:systems?.map((item: any) => item?.id).toString(),
          supplier_type:supplierType
        },0).then((res:AxiosResponse)=>{
          setSupplierDataLoader(false);
          setSupplierData(res?.data);
          setSupplierFilterShowPagination(false);
        })
      }
      else{
      setSupplierDataLoader(true);
      setSupplierFilterShowPagination(true);
      API.get("/api/db/supplier_name_search/", { search_key: search }, 0).then((res: any) => {
        setSupplierDataLoader(false);
        setSupplierData(res?.data);
        setSupplierFilterShowPagination(false);
      });
    }
    }
    else {
      if(!(supplierType== undefined || commodityName?.length == 0 || productCategory?.length == 0 || systems?.length == 0)){
        API.get('/api/db/supplier_mui_view/',{
          vehicle_type:productCategory?.map((item: any) => item?.id).toString(),
          commodity:commodityName?.map((item: any) => item?.id).toString(),
          system:systems?.map((item: any) => item?.id).toString(),
          supplier_type:supplierType
        },0).then((res:AxiosResponse)=>{
          setSupplierDataLoader(false);
          setSupplierData(res?.data);
          setSupplierFilterShowPagination(false);});
      }
      else{
      setSupplierDataLoader(true);
      API.get("/api/db/supplier_mui_view/", { is_oem: false, key: page - 1, page_items: 50 }, 0).then((res: any) => {
        setSupplierDataLoader(false);
        setSupplierData(res?.data);
        setSupplierFilterShowPagination(true);
      })
    }
    }
  }, [page, supplierDataApiCall, parentCounter])

  useEffect(() => {
    API.get("/auth/mui_trash_view/", { is_oem: false, bin_type: "supplierdatabase", count: true }, 0).then((res: any) => {
      setRecyclebinItemCount(res?.data?.count)
    })
  }, [reloadRecyclebinItemCount])

  const viewFile = (file: string) => {
    window.open(file)
  };

  const getPlantIds = (id: any) => {
    plantpopup.open();
    setrowIds(id);
  };

  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    history.push(`/data-base/supplier-database/${value}`);
  }

  const getContactDetail = (aboutLink: any, contactLink: any, contact: any, spocName: any, spocEmail: any, dialCode: any) => {
    supplierDetail.open(aboutLink, contactLink, contact, spocName, spocEmail, dialCode);
  }

  const editSupplierDatabase = (id: any, supplier: any, commodity: any, category: any, system: any, about: any, contactUs: any, contactNo: any, dialCode: any, spocName: any, spocEmail: any, logo: any, supplierType: any, certification: any) => {
    editSupplierModal.open(id, supplier, commodity, category, system, about, contactUs, `${dialCode}-${contactNo}`, spocName, spocEmail, logo, supplierType, certification)
  }

  const singleSupplierShow = (id: any, supplier: any, commodity: any, category: any, system: any, about: any, contactUs: any, contactNo: any, dialCode: any, spocName: any, spocEmail: any, logo: any, supplierType: any, certification: any, noOfPlant: any, createdBy: any, lastUpdated: any) => {
    singleSupplierCardModel.open(id, supplier, commodity, category, system, about, contactUs, dialCode == null ? contactNo : `${dialCode}-${contactNo}`, spocName, spocEmail, logo, supplierType, certification, noOfPlant, createdBy, lastUpdated)
  }

  const handleListSupplierUse = (id: any) => {
    listSupplierUse.open(id)
  }

  const loaderFunction = () => {
    let arr = []
    for (var i = 0; i < 22; i++) {
      arr.push(<TableRow>
        <TableCell sx={{ padding: "0.5rem 0.5rem", borderBottomColor: "primary.light" }}>
          <Skeleton animation="pulse" />
        </TableCell>
        <TableCell sx={{ padding: "0.5rem 0.5rem", borderBottomColor: "primary.light" }}>
          <Skeleton animation="pulse" />
        </TableCell>
        <TableCell sx={{ padding: "0.5rem 0.5rem", borderBottomColor: "primary.light" }}>
          <Skeleton animation="pulse" />
        </TableCell>
        <TableCell sx={{ padding: "0.5rem 0.5rem", borderBottomColor: "primary.light" }}>
          <Skeleton animation="pulse" />
        </TableCell>
        <TableCell sx={{ padding: "0.5rem 0.5rem", borderBottomColor: "primary.light" }}>
          <Skeleton animation="pulse" />
        </TableCell>
        <TableCell sx={{ padding: "0.5rem 0.5rem", borderBottomColor: "primary.light" }}>
          <Skeleton animation="pulse" />
        </TableCell>
        <TableCell sx={{ padding: "0.5rem 0.5rem", borderBottomColor: "primary.light" }}>
          <Skeleton animation="pulse" />
        </TableCell>
        <TableCell sx={{ padding: "0.5rem 0.5rem", borderBottomColor: "primary.light" }}>
          <Skeleton animation="pulse" />
        </TableCell>
        <TableCell sx={{ padding: "0.5rem 0.5rem", borderBottomColor: "primary.light" }}>
          <Skeleton animation="pulse" />
        </TableCell>
        <TableCell sx={{ padding: "0.5rem 0.5rem", borderBottomColor: "primary.light" }}>
          <Skeleton animation="pulse" />
        </TableCell>
        <TableCell sx={{ padding: "0.5rem 0.5rem", borderBottomColor: "primary.light" }}>
          <Skeleton animation="pulse" />
        </TableCell>
        <TableCell sx={{ padding: "0.5rem 0.5rem", borderBottomColor: "primary.light" }}>
          <Skeleton animation="pulse" />
        </TableCell>
      </TableRow>)
    }
    return arr;
  }

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }

        setState({ ...state, [anchor]: open });
      };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
    // onClick={toggleDrawer(anchor, false)}
    // onKeyDown={toggleDrawer(anchor, false)}
    >
      <DataBaseDrawer />
    </Box>
  );

  const handleClick = () => {
    history.push("/internallanding");
  };

  const handleSearchInput = (e: any) => {
    setSearch(e.target.value);
    if(e.target.value.length == 0){
      setSupplierDataApiCall(!supplierDataApiCall);
      setSupplierFilterShowPagination(true);
    }
  }

  const handleKeyDown = (e: any) => {
    if (e.key === "Enter") {
      if (search.length == 0) {
        setSupplierDataApiCall(!supplierDataApiCall)
        setSupplierFilterShowPagination(true);
      }
      else {
        setSupplierDataLoader(true);
        API.get("/api/db/supplier_name_search/", { search_key: search }, 0).then((res: any) => {
          setSupplierDataLoader(false);
          setSupplierData(res?.data);
          setSupplierFilterShowPagination(true);
        }
        )
      }
    }
  }
  React.useEffect(() => {
    API.get(
      "/auth/calibration_user_permission/",
      {
        action: true,
        database: true,
        module:"DB-Supplier Library"
      },
      0
    )
      .then((res: any) => {
        setuseraction(res.data.action)
        // sessionStorage.setItem("DBPermission", res.data.action);
      })
      .catch((err: any) => {});
  }, []);

  const onClickOfSearchBtn = () => {
    if (search.length == 0) {
      return;
    }
    else {
      setSupplierDataLoader(true);
      API.get("/api/db/supplier_name_search/", { search_key: search }, 0).then((res: any) => {
        setSupplierDataLoader(false);
        setSupplierData(res?.data);
      })
    }
  }

  //Dummy function to push to the plant popup

  const onCloseModalSupplierDummyFunction = () => {
    return;
  }

  // handleDeleteTableData is used for deleting a single row data in Datagrid

  const handleDeleteSupplier = async (e: React.MouseEvent, id: number) => {
    e.stopPropagation();
    swal({
      title: `Delete Data`,
      text: `Are you sure?`,
      icon: "error",
      //@ts-ignore
      buttons: {
        cancel: "Cancel",
        permanent: {
          text: `Delete Supplier`,
          value: `deleteSupplier`,
        },
      },
      closeOnClickOutside: false,
      closeOnEsc: false,
      className: "delete-swal",
    }).then(async (type: string) => {
      switch (type) {
        case "deleteSupplier":
          API.delete("/api/db/supplier/" + id + "/")
            .then((res: any) => {
              setSupplierDataApiCall(!supplierDataApiCall);
              setReloadRecyclebinItemCount(!reloadRecyclebinItemCount);
            })
            .catch((err: any) => {
              console.log(err, "Unable to delete file. Server Error");
            });
          break;
        default:
          swal(`Supplier saved from deleting`, { icon: "error" });
      }
    });
  };

  const handleInputClick = (e: React.MouseEvent) => {
    // clear the prev value on click so that same file can be uploaded again
    (e.target as HTMLInputElement).value = "";
  };

  const handleFilterApply = () =>{
    setSupplierDataLoader(true);
    setSupplierFilterShowPagination(true);
    if (search.length > 0) {
      API.get('/api/db/supplier_mui_view/',{
        search_key:search,
        vehicle_type:productCategory?.map((item: any) => item?.id).toString(),
        commodity:commodityName?.map((item: any) => item?.id).toString(),
        system:systems?.map((item: any) => item?.id).toString(),
        supplier_type:supplierType
      },0).then((res:AxiosResponse)=>{
        setSupplierDataLoader(false);
        setSupplierData(res?.data);
        setSupplierFilterShowPagination(false);
        filterSupplierModal.close();
      })
    }
    else{
      API.get('/api/db/supplier_mui_view/',{
        vehicle_type:productCategory?.map((item: any) => item?.id).toString(),
        commodity:commodityName?.map((item: any) => item?.id).toString(),
        system:systems?.map((item: any) => item?.id).toString(),
        supplier_type:supplierType
      },0).then((res:AxiosResponse)=>{
        setSupplierDataLoader(false);
        setSupplierData(res?.data);
        setSupplierFilterShowPagination(false);
        filterSupplierModal.close();
      })
    }
  }

  
  const handleClearFilter = () =>{

    history.push(`/data-base/supplier-database/${1}`);
    filterSupplierModal.close();
    setSupplierType(undefined);
    setCommodityName([]);
    setProductCategory([]);
    setSystems([]);

    if (search.length > 0) {
      setSupplierDataLoader(true);
      setSupplierFilterShowPagination(true);
      API.get("/api/db/supplier_name_search/", { search_key: search }, 0).then((res: any) => {
        setSupplierDataLoader(false);
        setSupplierData(res?.data);
        setSupplierFilterShowPagination(false);
      });
    }
    else {
      if (page == 1){
      setSupplierDataLoader(true);
      API.get("/api/db/supplier_mui_view/", { is_oem: false, key: page - 1, page_items: 50 }, 0).then((res: any) => {
        setSupplierData(res?.data);
        setSupplierDataLoader(false);
        setSupplierFilterShowPagination(true);
       })
      }
      API.get("/api/db/OEM_List_Count/", { is_oem: false, page_items: 50 }, 0).then((res: any) => {
        setSupplierPageCount(res?.data?.page_count);
        setSupplierFilterShowPagination(true);
      })
    }
  }

  return (
    <div>
      <input
        style={{ display: "none" }}
        type="file"
        accept=".xls,.xlsx"
        id="1"
        ref={inputRef}
        onChange={(e: any) => handleReverseUpload(e)}
        onClick={handleInputClick}
      />
      {ErrorModalFiles.isOpen &&
        <ErrorModalFilesMail
          isOpen={ErrorModalFiles.isOpen}
          onCloseModal={ErrorModalFiles.close}
          ErrorMsg={ErrorModalFileMsg}
        />
      }
      {SuccessModalFiles.isOpen &&
        <SuccessModalFilesMail
          isOpen={SuccessModalFiles.isOpen}
          onCloseModal={SuccessModalFiles.close}
          successMsg={SuccessModalFileMsg}
        />
      }
      {listSupplierUse.isOpen &&
        <SupplierUseList
          isOpen={listSupplierUse.isOpen}
          onCloseModal={listSupplierUse.close}
          id={listSupplierUse.id}
        />}
      {singleSupplierCardModel.isOpen && (
        <SingleSupplierCardModel
          onCloseModal={singleSupplierCardModel.close}
          isOpen={singleSupplierCardModel.isOpen}
          supplierGet={singleSupplierCardModel?.supplier}
          commodityGet={singleSupplierCardModel?.commodity}
          categoryGet={singleSupplierCardModel?.category}
          systemGet={singleSupplierCardModel?.system}
          aboutGet={singleSupplierCardModel?.about}
          contactUsGet={singleSupplierCardModel?.contactUs}
          contactNoGet={singleSupplierCardModel?.contactNo}
          spocNameGet={singleSupplierCardModel?.spocName}
          spocEmailGet={singleSupplierCardModel?.spocEmail}
          logoGet={singleSupplierCardModel?.logo}
          supplierTypeGet={singleSupplierCardModel?.supplierType}
          certificationGet={singleSupplierCardModel?.certification}
          idGet={singleSupplierCardModel?.id}
          noOfPlant={singleSupplierCardModel?.noOfPlant}
          createdBy={singleSupplierCardModel?.createdBy}
          lastUpdated={singleSupplierCardModel?.lastUpdated}
        />
      )}
      {supplierDetail.isOpen && (
        <SupplierShowDetailsModel
          onCloseModal={supplierDetail.close}
          isOpen={supplierDetail.isOpen}
          aboutLink={supplierDetail?.aboutLink}
          contactLink={supplierDetail?.contactLink}
          contact={supplierDetail?.contact}
          spocName={supplierDetail?.spocName}
          spocEmail={supplierDetail?.spocEmail}
          dialCode={supplierDetail?.dialCode}
        />
      )}
      {plantpopup.isOpen && (
        <PlantModal
          key={rowIds}
          useraction={useraction}
          onCloseModal={plantpopup.close}
          isOpen={plantpopup.isOpen}
          rowIds={rowIds}
          supplierDataApiCall={supplierDataApiCall}
          setSupplierDataApiCall={setSupplierDataApiCall}
          onCloseModalSupplier={onCloseModalSupplierDummyFunction}
        />
      )}
      {addSupplierModal.isOpen &&
        <AddSupplierModel
           useraction={useraction}
          onCloseModal={addSupplierModal.close}
          isOpen={addSupplierModal.isOpen}
          supplierDataApiCall={supplierDataApiCall}
          setSupplierDataApiCall={setSupplierDataApiCall}
        />
      }
      {editSupplierModal.isOpen &&
        <EditSupplierModel
          onCloseModal={editSupplierModal.close}
          isOpen={editSupplierModal.isOpen}
          supplierDataApiCall={supplierDataApiCall}
          setSupplierDataApiCall={setSupplierDataApiCall}
          idGet={editSupplierModal?.id}
          supplierGet={editSupplierModal?.supplier}
          commodityGet={editSupplierModal?.commodity}
          categoryGet={editSupplierModal?.category}
          systemGet={editSupplierModal?.system}
          aboutGet={editSupplierModal?.about}
          contactUsGet={editSupplierModal?.contactUs}
          contactNoGet={editSupplierModal?.contactNo}
          spocNameGet={editSupplierModal?.spocName}
          spocEmailGet={editSupplierModal?.spocEmail}
          logoGet={editSupplierModal?.logo}
          supplierTypeGet={editSupplierModal?.supplierType}
          certificationGet={editSupplierModal?.certification}
        />
      }
      {filterSupplierModal.isOpen &&
        <FilterSupplierModel 
         onCloseModal={filterSupplierModal.close}
         isOpen={filterSupplierModal.isOpen}
         handleFilterApply={handleFilterApply}
         handleClearFilter={handleClearFilter}
         supplierType={supplierType}
         setSupplierType={setSupplierType}
         commodityName={commodityName}
         setCommodityName={setCommodityName}
         productCategory={productCategory}
         setProductCategory={setProductCategory}
         systems={systems}
         setSystems={setSystems}
        />
      }
      <Box sx={{ margin: "0 0.5rem" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            sx={{ display: "flex", alignItems: "center", columnGap: "1rem" }}
          >
            <IconButton
              sx={{ cursor: "pointer" }}
              title="Go Back"
              onClick={handleClick}
            >
              <ArrowBackIcon color="primary" titleAccess="Go Back" />
            </IconButton>
            <Typography
              sx={{
                fontSize: "1rem",
                whiteSpace: "nowrap",
                // fontWeight: "bold",
              }}
            >
              Database (Supplier)
            </Typography>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", columnGap: "1rem", paddingRight: '0.5rem' }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                borderBottom: "1px solid",
                borderBottomColor: "primary.light",
              }}
            >
              <InputBase
                sx={{ ml: 1, flex: 1, fontSize: "1rem", width: '20rem' }}
                placeholder="Search.."
                inputProps={{ "aria-label": "Search.." }}
                onChange={handleSearchInput}
                type="search"
                onKeyDown={handleKeyDown}
              />
              <IconButton type="button" sx={{ p: "5px" }} aria-label="search" onClick={onClickOfSearchBtn}>
                <SearchIcon sx={{ color: "primary.main" }} />
              </IconButton>
            </Box>
            <Button            
              size="small"
              startIcon={<FilterAltIcon sx={{ marginRight: '-0.5rem' }} />}
              title="Filter"
              sx={{
                padding: '0.2rem 0.5rem',
                cursor: 'pointer',
                "&:hover": {
                  transform: "Scale(1.05)",
                  transition: "transform 0.5s ease",
                },
              }}
              onClick={() => filterSupplierModal.open()}
            >
              Filter
            </Button>
           { useraction && useraction?.includes("C") &&
           <Button            
              size="small"
              startIcon={<AddIcon sx={{ marginRight: '-0.5rem' }} />}
              title="Add Supplier"
              sx={{
                padding: '0.2rem 0.5rem',
                cursor: 'pointer',
                "&:hover": {
                  transform: "Scale(1.05)",
                  transition: "transform 0.5s ease",
                },
              }}
              onClick={() => addSupplierModal.open()}
            >
              Add Supplier
            </Button>}
            <LoadingButton            
              sx={{
                padding: '0.2rem 0.5rem',
                cursor: 'pointer',
                "&:hover": {
                  transform: "Scale(1.05)",
                  transition: "transform 0.5s ease",
                },
              }}
              title="Download"
              className="btnlogin"
              id="basic-button"
              aria-haspopup="true"
              endIcon={<DownloadIcon sx={{ marginLeft: '-0.5rem' }} />}
              onClick={handleDownloadSupplierDatabase}
              loading={downloadLoader}
              size="small">
              Download
            </LoadingButton>
          { (useraction && useraction?.includes("U")||useraction && useraction?.includes("C"))&& <LoadingButton            
              sx={{
                padding: '0.2rem 0.5rem',
                cursor: 'pointer',
                "&:hover": {
                  transform: "Scale(1.05)",
                  transition: "transform 0.5s ease",
                },
              }}
              title="Upload"
              className="btnlogin"
              id="basic-button"
              aria-haspopup="true"
              endIcon={<UploadIcon sx={{ marginLeft: '-0.5rem' }} />}
              loading={reverseUploadLoader}
              onClick={(ed: any) => browseFiles(ed)}
              size="small">
              Upload
            </LoadingButton>}
            <Box sx={{width:'2.5rem'}}>
              {recyclebinItemCount && recyclebinItemCount > 0 ?
                <span title="Recycle bin" style={{ cursor: 'pointer' }} onClick={() => history.push('/data-base/supplier-recyclebin')}>
                  <Badge badgeContent={`${recyclebinItemCount && recyclebinItemCount}`} color="error">
                    <img src={recyleBin} style={{ height: '1.6rem' }} />
                  </Badge>
                </span>
                : <DeleteOutlineIcon sx={{ color: "primary.main", fontSize: '2rem' }} />}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "flex-start" }}>
        <Box className={styles.expandIconBox}>
          <div className={styles.iconSec}>
            {(["left"] as const).map((anchor) => (
              <React.Fragment key={anchor}>
                <IconButton
                  size="medium"
                  sx={{
                    backgroundColor: "primary.light",
                    color: "primary.main",
                  }}
                  onClick={toggleDrawer(anchor, true)}
                >
                  <AddIcon sx={{ fontSize: "1.7rem" }} />
                </IconButton>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  className={styles.drawerContainer}
                  PaperProps={{
                    sx: {
                      backgroundColor: "aliceblue",
                    },
                  }}
                >
                  <Box
                    sx={{ padding: "1rem 0rem", paddingBottom: "0" }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3"
                  >
                    <Typography
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "1rem",
                      }}
                    >
                      Supplier Database
                    </Typography>
                    <Divider sx={{ margin: "0.5rem" }} />
                    {list(anchor)}
                  </Box>
                </Drawer>
                <Typography
                  style={{
                    transform: "rotate(270deg)",
                    whiteSpace: "nowrap",
                    marginTop: "40vh",
                    cursor: "pointer",
                    fontSize: "1rem",
                  }}
                  onClick={toggleDrawer(anchor, true)}
                >
                  Supplier Database
                </Typography>
              </React.Fragment>
            ))}
          </div>
        </Box>
        <Box sx={{ width: "100%",paddingRight: '1rem' }}>
          <Box sx={{ height: { lg: '80vh', xl: "85vh" } }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3">
            <Table>
              <TableHead sx={{ backgroundColor: "primary.light", position: 'sticky', top: 0, zIndex: 10 }}>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                      width: '2vw',
                    }}
                  >
                    S.No
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                      width: '8vw',
                    }}
                  >
                    Supplier Name
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                      width: '9vw',
                    }}
                  >
                    Commodity
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                      width: '3vw',
                    }}
                  >
                    Details
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                      width: '6vw',
                      lineHeight: '1.2',
                    }}
                  >
                    Product Category
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                      width: '6vw',
                    }}
                  >
                    Systems
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                      width: '3vw',
                      lineHeight: '1.2',
                    }}
                  >
                    No of Plants
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                      width: '6vw',
                    }}
                  >
                    Supplier Type
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                      width: '10vw',
                    }}
                  >
                    Certifications
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                      width: '4vw',
                    }}
                  >
                    Created by
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                      width: '6vw',
                    }}
                  >
                    Last Updated
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                      width: '3vw',
                    }}
                  >
                    Actions
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {!supplierDataLoader ? <>{supplierData && supplierData?.map((item: any, index: any) => {
                  return <TableRow sx={{backgroundColor: item?.lock === true ? '#c5c5c5': ''}}>
                    <Supplierpage useraction={useraction} key={item?.id} item={item} index={((page - 1) * 50) + (index + 1)} setParentsCounter={setParentsCounter} getPlantIds={getPlantIds} getContactDetail={getContactDetail} editSupplierDatabase={editSupplierDatabase} handleDeleteSupplier={handleDeleteSupplier} viewFile={viewFile} singleSupplierShow={singleSupplierShow} handleListSupplierUse={handleListSupplierUse} />
                  </TableRow>
                })}</> :
                  <>
                    {loaderFunction()}
                  </>}
              </TableBody>
            </Table>
          </Box>
          {supplierFilterShowPagination && <Pagination count={supplierPageCount} color="primary" sx={{ float: 'right', marginTop: '0.3rem' }} page={Number(page)} onChange={handleChangePage} />}
        </Box>
      </Box>
    </div>
  );
}
export default SuppliersLandingPage;
