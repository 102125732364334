import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Autocomplete, Button, Chip, Divider, FormControl, InputLabel, MenuItem, Select, Skeleton, TextField, Typography } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import { API } from "../../api-services";
import { LoadingButton } from "@mui/lab";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: " 40vw",
  height: "fit-Conent",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

interface EditSupplierModelProps {
  isOpen: any;
  onCloseModal: any;
  handleFilterApply: any;
  handleClearFilter: any;
  supplierType: any;
  setSupplierType: any;
  commodityName: any;
  setCommodityName: any;
  productCategory: any;
  setProductCategory: any;
  systems: any;
  setSystems: any;
}

const EditSupplierModel = (props: EditSupplierModelProps) => {
  const { isOpen, onCloseModal, handleFilterApply, handleClearFilter, supplierType, setSupplierType, commodityName, setCommodityName, productCategory, setProductCategory, systems, setSystems } = props;

  const [productCategoryLoader, setProductCategoryLoader] = useState<boolean>(true);
  const [commodityLoader, setCommodityLoader] = useState<boolean>(true);
  const [systemsLoader, setSystemsLoader] = useState<boolean>(true);

  const [productCategoryData, setProductCategoryData] = useState<any>();
  const [commodityData, setCommodityData] = useState<any>();
  const [systemsData, setSystemsData] = useState<any>();

  useEffect(() => {
    setSystemsLoader(true);
    setSystems([]);
    setSystemsData(undefined);
    if (productCategory?.length > 0) {
      let productCategoryList: any = productCategory?.map((item: any) => { return item?.id });
      API.get('/api/db/sub_system/', { vehicle_type: productCategoryList?.toString() }, 0).then((res: any) => {
        let system: any = [];
        const systemLoop = res?.data?.map((item: any) => {
          system.push({
            id: item?.id,
            name: item?.abbreviation,
            abbreviation: item?.vehicle_abbreviation,
          })
        });
        setSystemsData(system);
        setSystems(system?.filter((item: any) => systems?.map((innerItem: any) => innerItem.id)?.includes(item.id)));
        setSystemsLoader(false);
      })
    }
  }, [productCategory])

  useEffect(() => {
    setProductCategoryLoader(true);
    setCommodityLoader(true)
    API.get('/api/db/vehicle_type/', {}, 0).then((res: any) => {
      setProductCategoryLoader(false)
      let productCategory: any = [];
      const productCategoryLoop = res?.data?.map((item: any) => {
        productCategory.push({
          id: item?.id,
          name: item?.name,
          abbreviation: item?.abbreviation,
        })
      });
      setProductCategoryData(productCategory);
      setProductCategoryLoader(false)
    })

    API.get('/api/db/commodity/', {}, 0).then((res: any) => {
      let commodity: any = [];
      const commodityLoop = res?.data?.map((item: any) => {
        commodity.push({
          id: item?.id,
          name: item?.name,
          value: item?.name,
        })
      });
      setCommodityData(commodity);
      setCommodityLoader(false)
    })
  }, [isOpen])


  let commodityListOption = () => {
    let commodityId: any = commodityName && commodityName?.map((itm: any) => itm.id);
    return (
      commodityData &&
      commodityData
        ?.filter((item: any) => ![...commodityId]?.includes(item.id))
    );
  };

  const handleSelectChange = (commodityId: any) => {
    // @ts-ignore
    setCommodityName([...commodityId]);
  };

  let productCategoryListOption = () => {
    let productCategoryId: any = productCategory && productCategory?.map((itm: any) => itm.id);
    return (
      productCategoryData &&
      productCategoryData
        ?.filter((item: any) => ![...productCategoryId]?.includes(item.id))
    );
  };

  const handleSelectProductCategoryChange = (productCategoryId: any) => {
    // @ts-ignore
    setProductCategory([...productCategoryId]);
  };

  let systemsListOption = () => {
    let systemId: any = systems && systems?.map((itm: any) => itm.id);
    return (
      systemsData &&
      systemsData
        ?.filter((item: any) => ![...systemId]?.includes(item.id))
    );
  };

  const handleSelectSystemsChange = (systemsId: any) => {
    // @ts-ignore
    setSystems([...systemsId]);
  };

  const handleSupplierType: any = (e: any) => {
    setSupplierType(e.target.value);
  }

  return (
    <div>
      <Modal
        open={isOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
              }}
            >
              Filter Supplier
            </Typography>
            <span style={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem' }}>
              <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer' }} onClick={onCloseModal} />
            </span>
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box>
            <FormControl fullWidth sx={{ marginBottom: '0.5rem' }}>
              {/* <InputLabel sx={{ fontSize: '1rem', color: 'primary.main', margin: '0.5rem', marginLeft: '-1rem' }} shrink>Commodity</InputLabel> */}
              {commodityLoader ? <Skeleton sx={{ height: "3rem", width: "100%", marginTop: '1rem' }} />
                :
                commodityData && <Autocomplete
                  multiple
                  disableClearable
                  id="Commodity"
                  value={commodityName}
                  limitTags={6}
                  disableCloseOnSelect
                  onChange={(event, newValue) => {
                    handleSelectChange(newValue);
                  }}
                  sx={{ marginBottom: '0.5rem' }}
                  options={commodityListOption() && commodityListOption()}
                  // @ts-ignore
                  getOptionLabel={(option) => option?.name}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip
                        size="small"
                        //  @ts-ignore
                        sx={{ backgroundColor: '#007fff14', color: "primary.main", fontSize: '1rem' }}
                        // @ts-ignore
                        label={option?.name}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Commodity *"
                      variant="standard"
                      sx={{
                        marginTop: '1rem',
                        '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                        '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                        '.MuiSvgIcon-root': { color: 'primary.main' },
                      }}
                    />
                  )}
                />}
            </FormControl>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <span style={{ fontWeight: 'bold' }}>AND</span>
            </Box>
            <FormControl fullWidth sx={{ marginBottom: '0.5rem' }}>
              {/* <InputLabel sx={{ fontSize: '1rem', color: 'primary.main', margin: '0.5rem', marginLeft: '-1rem' }} shrink>Product Category</InputLabel> */}
              {productCategoryLoader ? <Skeleton sx={{ height: "3rem", width: "100%", marginTop: '1rem' }} />
                :
                productCategoryData && <Autocomplete
                  multiple
                  disableClearable
                  id="ProductCategory"
                  value={productCategory}
                  limitTags={6}
                  sx={{ marginBottom: '0.5rem' }}
                  disableCloseOnSelect
                  onChange={(event, newValue) => {
                    handleSelectProductCategoryChange(newValue);
                  }}
                  options={productCategoryListOption() && productCategoryListOption()}
                  // @ts-ignore
                  getOptionLabel={(option) => option?.abbreviation}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip
                        size="small"
                        //  @ts-ignore
                        sx={{ backgroundColor: '#007fff14', color: "primary.main", fontSize: '1rem' }}
                        // @ts-ignore
                        label={option?.abbreviation}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder="Select Product Category *"
                      variant="standard"
                      sx={{
                        fontSize: '1rem',
                        marginTop: '1rem',
                        ".MuiInputBase-root.MuiInput-root": {
                          fontSize: '1rem !important'
                        },
                        '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                        '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                        '.MuiSvgIcon-root': { color: 'primary.main' },
                      }}
                    />
                  )}
                />}
            </FormControl>
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>
              <span style={{ fontWeight: 'bold' }}>AND</span>
            </Box>
            <FormControl fullWidth sx={{ marginBottom: '0.5rem' }}>
              {productCategoryData && systemsData && <InputLabel sx={{ fontSize: '1rem', color: 'primary.main', margin: '0.5rem', marginLeft: '-1rem' }} shrink>System</InputLabel>}
              {productCategory.length > 0 && systemsLoader ? <Skeleton sx={{ height: "3rem", width: "100%" }} />
                :
                productCategoryData && systemsData && <>
                  <Autocomplete
                    multiple
                    disableClearable
                    id="ProductCategory"
                    value={systems}
                    limitTags={6}
                    disableCloseOnSelect
                    sx={{ marginBottom: '0.5rem' }}
                    onChange={(event, newValue) => {
                      handleSelectSystemsChange(newValue);
                    }}
                    options={systemsListOption() && systemsListOption()}
                    // @ts-ignore
                    getOptionLabel={(option) => `${option?.abbreviation} ${option?.name}`}
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          size="small"
                          //  @ts-ignore
                          sx={{ color: "primary.main", fontSize: '1rem' }}
                          // @ts-ignore
                          label={`${option?.abbreviation} ${option?.name}`}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        placeholder="Select Systems *"
                        variant="standard"
                        sx={{
                          fontSize: '1rem',
                          marginTop: '1rem',
                          ".MuiInputBase-root.MuiInput-root": {
                            fontSize: '1rem !important'
                          },
                          '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                          '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                          '.MuiSvgIcon-root': { color: 'primary.main' },
                        }}
                      />
                    )}
                  />
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <span style={{ fontWeight: 'bold' }}>AND</span>
                  </Box></>}
            </FormControl>
            {/* <FormControl fullWidth>
                <InputLabel sx={{ color: 'primary.main', marginLeft: '-1rem', marginTop: '-0.45rem' }} shrink>Plants</InputLabel>
                <TextField
                  type="number"
                  variant='standard'
                  // label="Life in Yrs *"
                  placeholder="Plants"
                  value={plantsCount}
                  onChange={handlePlantsCount}
                  inputProps={{
                    step: 'any',
                    min: 0,
                    style: { fontSize: '1rem', }
                  }}
                  InputLabelProps={{
                    shrink: true,
                    color: 'primary',
                  }}
                  sx={{
                    width: "100%",
                    borderBottomColor: 'primary.light',
                    '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                    '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                  }}
                />
              </FormControl>
              <Box sx={{display:'flex',justifyContent:'center',marginTop:'0.5rem'}}>
                  <span style={{fontWeight:'bold'}}>AND</span>
              </Box> */}
            <FormControl fullWidth sx={{ marginBottom: '0.5rem', marginTop: '0.5rem' }}>
              <InputLabel id="demo-simple-select-label-Activity" sx={{ color: "primary.main", fontSize: '1.13rem', marginLeft: '-1rem' }}>
                Select Supplier Type
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                placeholder="Supplier Type"
                id="demo-simple-select"
                variant='standard'
                value={supplierType}
                onChange={handleSupplierType}
                sx={{
                  width: '100%',
                  marginTop: '-0.5rem',
                  "&:before": {
                    borderBottomColor: "primary.light",
                  },
                  '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
                }}
              >
                <MenuItem sx={{ fontSize: '1rem' }} value={'Small - 0 to 50 Mn Revenue'}>Small - 0 to 50 Million Revenue</MenuItem>
                <MenuItem sx={{ fontSize: '1rem' }} value={'Medium - 50 to 500 Mn Revenue'}>Medium - 50 to 500 Million Revenue</MenuItem>
                <MenuItem sx={{ fontSize: '1rem' }} value={'Large > 500 Mn Revenue'}>{"Large > 500 Million Revenue"}</MenuItem>
              </Select>
            </FormControl>
          </Box>
          <Box>
            <span style={{ float: 'right', marginTop: '1rem' }}>
              <Button variant="contained" sx={{ marginRight: '1rem' }} onClick={handleClearFilter}>Clear Filter</Button>
              <LoadingButton onClick={handleFilterApply}><Button variant="contained" disabled={supplierType == undefined || commodityName?.length == 0 || productCategory?.length == 0 || systems?.length == 0}>Apply</Button></LoadingButton>
            </span>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default EditSupplierModel;
