import { ID } from "../../utlis/commonInterfaces";
import { API } from "./index.service";


const url = "/drive/meeting_group/";
const urlData = "/drive/meeting/";
const urlMeetingData = "/drive/meeting_detail/";
const mailDetails = "/drive/mom/send_mail/";
const mailNew = "/xcpep/email_send_function/"


class momServices {
    get_groupName = ( project: number ) => {
        return API.get( url, {
            project: project,
        } );
    };

    create_groupName = ( project: number, name: string, type: boolean,
        attendees: any ) => {
        return API.post( url, { project: project, name: name, client: type, attendees: attendees } );
    };

    get_mom_permissions=(project:any,action:any)=>{
          return API.get("/auth/mom_permission/",{project:project,action:action},0);
    }


    delete_groupName = ( id: ID ) => {
        return API.delete( url + id + "/" );
    };

    update_groupName = ( id: ID, name: string ) => {
        return API.patch( url + id + "/", { name: name } );
    };
    update_MomGroupAttendees = ( id: any,
        type: boolean,
        attendees: any ) => {
        return API.patch( url + id + "/", { client: type, attendees: attendees } );
    };

    create_Column = ( project: number,
        group: ID,
        date: string,
        attendees: any,
        // remark: string,
    ) => {
        return API.post( urlData, { project: project, group: group, date: date, attendees: attendees, } );
    };
    get_Column = ( project: number ) => {
        return API.get( urlData, { project: project } );
    };
    update_Column = ( id: ID,
        date: string | undefined,
        attendees: any,
        // remark: string 
    ) => {
        return API.patch( urlData + id + "/", { date: date, attendees: attendees } );
    };
    delete_Column = ( id: ID ) => {
        return API.delete( urlData + id + "/" );
    };
    create_MeetingColumn = ( meeting: number,
        points?: string | undefined,
        responsible?: any,
        date?: string | undefined,
        status?: any,
        remark?: string | undefined, ) => {
        return API.post( urlMeetingData, { meeting: meeting, points: points, responsible: responsible, date: date, status: status, remark: remark, } );
    };
    get_MeetingColumn = ( meeting: number ) => {
        return API.get( urlMeetingData, { meeting: meeting } );
    };
    update_MeetingColumn = ( id: ID,
        points?: string | undefined,
        responsible?: any,
        date?: string | undefined,
        status?: any,
        remark?: string | undefined,
        path?: string | undefined,
        name?: string | undefined, ) => {
        return API.patch( urlMeetingData + id + "/", {
            points: points, responsible: responsible, date: date, status: status, remark: remark, path: path, file_name: name
        } );
    };
    delete_MeetingColumn = ( id: ID ) => {
        return API.delete( urlMeetingData + id + "/" );
    };
    getProject = ( module: any ) => {
        return API.get( "xcpep/project/", { module: module } );
    };
    post_mailDetails = ( to: any, cc: any, body: any, module: any, project: any,meeting_group:any,subject:any) => {
        return API.post( mailNew, {
            to_mail: to,
            cc_mail: cc,
            data: body,
            module: module,
            project: project,
            meeting_group:meeting_group,
            mail_type:5,
            attachment:[],
            subject:subject
        } );
    };
    notification = ( url: string,
        responsible?: string ,project?:any,group?:any,meeting?:any) => {
        return API.get( "/drive/notification/", { url: url, responsible: responsible,project:project,group:group,meeting:meeting } );
    };

}
const MomData = new momServices();
export default MomData;

// "to" : [],
//     "cc" : [],
//         "body" : "";
// "module" : "";