import React, { useContext, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Checkbox, Divider, Typography } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { ADMIN } from "../../Redux/Services/admin.service";
import { LoadingButton } from "@mui/lab";
import { useHistory } from "react-router-dom";
import { useAppDispatch } from "../../../AppRouter";
import { uploadRepoImages } from "../../Redux/Actions/imageRepository.actions";
import mediaUpload from "./MediaUpload";
import { MyContext } from "./MediaCreateLanding";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: '80vw', sm: '50vw', md: "30vw" },
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
  zIndex: 0
};

interface MediaBulkUploadMediaModelProps {
  isOpen: any;
  onCloseModal: any;
  mediaUploadBulkTopVault: any;
  mediaUploadBulkVault: any;
}

const MediaBulkUploadMediaModel = (props: MediaBulkUploadMediaModelProps) => {
  const { isOpen, onCloseModal, mediaUploadBulkTopVault, mediaUploadBulkVault } = props;
  const { projectId, projectName, mediaType, abbreviation, category, groupId, productList, pageNumber, apiCall } = useRouteParams<any>();
  const VideoModalRef = useRef<any>(null);
  const [checked, setChecked] = useState(false);
  const [checked2, setChecked2] = useState(false);
  const [disable, setDisable] = useState<boolean>(false);
  const [buttonDisable, setButtonDisable] = useState(false);
  const [files, setFiles] = useState<FileList | null>(null);
  const [isLoading, setIsLoading] = useState(false);
  const [FilesSize, setFilesSize] = useState<any>()

  const { callGroupAPI, setCallGroupAPI } = useContext(MyContext);
  const history = useHistory();
  const dispatch = useAppDispatch();

  const onChangeHandler = (type: any) => {
    if (type === "1") {
      setChecked(true);
      setChecked2(false);
      setDisable(false);


    } else if (type === "2") {
      setChecked2(true);
      setChecked(false);
      setDisable(true);
    }
    else if (type === "3") {
      setChecked(true);
      setChecked2(false);
    }
    else if (type === "4") {
      setChecked2(true);
      setChecked(false);
    }
  };

  const handleChange = (event: any) => {
    if (mediaType == "video") {
      if (event.currentTarget.files?.length >= 11) {
        setButtonDisable(true);
        ADMIN.toast.error("Select minimum 10 files");
      }
      else if (event.currentTarget.files?.length <= 10) {
        setButtonDisable(false);
        let data = Object?.values(event?.target?.files).map((item: any) => {
          return item?.size;
        })?.reduce((a: any, b: any) => { return a + b })

        let _size = data;
        let fSExt = new Array('Bytes', 'KB', 'MB', 'GB'),
          i = 0; while (_size > 900) { _size /= 1024; i++; }
        let exactSize = (Math.round(_size * 100) / 100) + ' ' + fSExt[i];
        setFilesSize(exactSize)
        setFiles(event.currentTarget.files);
      };
    }
    else {
      if (event.currentTarget.files?.length >= 51) {
        setButtonDisable(true);
        ADMIN.toast.error("Select minimum 50 files");
      }
      else if (event.currentTarget.files?.length <= 50) {
        setButtonDisable(false);
        let data = Object?.values(event?.target?.files).map((item: any) => {
          return item?.size;
        })?.reduce((a: any, b: any) => { return a + b })

        let _size = data;
        let fSExt = new Array('Bytes', 'KB', 'MB', 'GB'),
          i = 0; while (_size > 900) { _size /= 1024; i++; }
        let exactSize = (Math.round(_size * 100) / 100) + ' ' + fSExt[i];
        setFilesSize(exactSize)
        setFiles(event.currentTarget.files);
      };

    }
  };

  const createImage = async (images: FileList) => {
    setIsLoading(true);
    if (disable == false) {
      mediaUpload(images, groupId, mediaUploadBulkTopVault, mediaUploadBulkVault, dispatch, category, onCloseModal, setIsLoading, mediaType, abbreviation, projectId, category, groupId, productList, pageNumber, apiCall, projectName, history, callGroupAPI, setCallGroupAPI, checked2)
    }
    else {
      try {
        const action = await dispatch(uploadRepoImages({
          images,
          topVaultId: mediaUploadBulkTopVault,
          vaultId: mediaUploadBulkVault,
          imageCategory: +category,
          rgb: disable,
          media_type: mediaType,
          group: groupId
        })).then((res: any) => {
          history.push(
            `/mediacreateinternal/${projectId}/${projectName}/${mediaType}/${abbreviation}/${category}/${groupId}/${productList}/${pageNumber}/${apiCall == "true" ? "false" : "true"}`
          );
          onCloseModal();
        });
      } catch (e) {
        ADMIN.toast.throwError(e, "Could not create image");
      } finally {
        setIsLoading(false);
      }
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (!files) return ADMIN.toast.error("Select atleast 1 image"); {
      createImage(files);
    }
  };

  return (
    <div>
      <Modal
        open={isOpen}
        // onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: '-2rem',
                textTransform: 'capitalize'
              }}
            >
              Media Add {mediaType}
            </Typography>
            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ display: 'inline-flex', marginBottom: "1rem", }}>
            <Typography sx={{ fontSize: "1rem", fontWeight: "600", marginRight: '1rem' }}> File Size : </Typography>
            <Typography sx={{ fontSize: "1rem", textTransform: 'capitalize', }}>{FilesSize && FilesSize}</Typography>
          </Box>
          <Box>
            {mediaType == "image" ?
              <input
                type="file"
                name="image"
                required
                // label="Images:"
                accept="image/*"
                onChange={handleChange}
                multiple
              /> :
              <input
                type="file"
                name="image"
                required
                // label="Video:"
                accept="video/*"
                onChange={handleChange}
                ref={VideoModalRef}
                multiple
              />
            }
            {mediaType == "image" ?
              <div style={{ display: 'flex', flexDirection: "row", margin: '0rem 0.5rem 0rem', columnGap: '1rem' }}>
                <span>
                  <Checkbox
                    style={{ padding: '6px' }}
                    checked={checked}
                    onClick={() => onChangeHandler('1')}
                    inputProps={{ "aria-label": "controlled" }}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                  />
                  <span>Original Background</span>
                </span>
                <span>
                  <Checkbox
                    style={{ padding: '6px' }}
                    checked={checked2}
                    onClick={() => onChangeHandler('2')}
                    inputProps={{ "aria-label": "controlled" }}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                  />
                  <span>White Background</span>
                </span>
              </div>
              : <div style={{ display: 'flex', flexDirection: "row", margin: '0rem 0.5rem 0rem', columnGap: '1rem' }}>
                <span>
                  <Checkbox
                    style={{ padding: '6px' }}
                    checked={checked}
                    onClick={() => onChangeHandler('3')}
                    inputProps={{ "aria-label": "controlled" }}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                  />
                  <span>With Audio</span>
                </span>
                <span>
                  <Checkbox
                    style={{ padding: '6px' }}
                    checked={checked2}
                    onClick={() => onChangeHandler('4')}
                    inputProps={{ "aria-label": "controlled" }}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                  />
                  <span>Without Audio</span>
                </span>
              </div>
            }
          </Box>
          <Box sx={{ width: '100%', textAlign: 'right', marginBottom: '1rem' }}>
            <LoadingButton
              size="small"
              variant="contained"
              sx={{
                padding: "0.5rem",
                lineHeight: 1,
                "&:hover": {
                  transform: 'Scale(1.05)',
                  transition: 'transform 0.5s ease',
                },
                '.MuiCircularProgress-root': {
                  color: 'primary.main',
                },
              }}
              loading={isLoading}
              disabled={isLoading || buttonDisable}
              onClick={(e: any) => handleSubmit(e)}
            >Upload</LoadingButton>
          </Box>
        </Box>
      </Modal>
    </div >
  );
};

export default MediaBulkUploadMediaModel;
