// vendors
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { ID } from "../../utlis/commonInterfaces";
import rawMaterialCardService from "../Services/rawMaterialCard.service";
import { RawMaterialCardCost } from "../Reducers/rawMaterialCard.reducer";


// -------------------------------------------------------------------------------------
// api actions
// -------------------------------------------------------------------------------------

export interface GetRawMateralCardCostsArg {
    costEstimationId?: ID;
    tableId:any;

}

const getRawMaterialCardCosts = createAsyncThunk(
    "rawMaterialCard/get",
    async ( arg: GetRawMateralCardCostsArg, { rejectWithValue } ) => {
        try {
            const { data } = await rawMaterialCardService.get( arg );

            return {
                costs: data as RawMaterialCardCost[]
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

// -------------------------------------------------------------------------------------

export interface CreateRawMaterialCardCostsArg {
    costEstimationId: ID;
    materials?: ID[];
    fasteners?: ID[];
    purchasingItems?: ID[];
    procure_items?: ID[];
}
const createRawMaterialCardCosts = createAsyncThunk(
    "rawMaterialCard/create",
    async ( arg: CreateRawMaterialCardCostsArg, { rejectWithValue } ) => {
        try {
            const { data } = await rawMaterialCardService.create( arg );

            return {
                costs: data as RawMaterialCardCost[]
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

// -------------------------------------------------------------------------------------

export interface UpdateRawMaterialCardCostArg {
    id: ID;
    updates: Partial<Pick<RawMaterialCardCost, "bop_result" | "material_result"| "grade" | "alias" | "quantity"|"sequence">> & { material?: ID; } | { fastener?: ID; } | {grade:any} | { purchasing_item?: ID; };
}
const updateRawMaterialCardCost = createAsyncThunk(
    "rawMaterialCard/update",
    async ( arg: UpdateRawMaterialCardCostArg, { rejectWithValue } ) => {
        try {
            const { data } = await rawMaterialCardService.update( arg );

            return {
                cost: data as RawMaterialCardCost
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

//sellProcureDatabase
export interface UpdateProcureRawMaterialCardCostArg {
    id: ID;
    updates: Partial<Pick<RawMaterialCardCost, "bop_result" | "material_result" | "alias" | "quantity">> & { procure?: ID; };
}
const updateProcureRawMaterialCardCost = createAsyncThunk(
    "rawProcureMaterialCard/update",
    async ( arg: UpdateProcureRawMaterialCardCostArg, { rejectWithValue } ) => {
        try {
            const { data } = await rawMaterialCardService.update( arg );

            return {
                cost: data as RawMaterialCardCost
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);
// -------------------------------------------------------------------------------------

export interface DeleteRawMaterialCardCost {
    id: ID;
}
const deleteRawMaterialCardCost = createAsyncThunk(
    "rawMaterialCard/delete",
    async ( arg: DeleteRawMaterialCardCost, { rejectWithValue } ) => {
        try {
            await rawMaterialCardService.delete( arg );

            return {};
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

export interface CopyRawMaterialId {
    id: ID;
    data: boolean;
}

const copyRawMaterial = createAsyncThunk(
    "cost/material-copy",
    async ( arg: CopyRawMaterialId, { rejectWithValue } ) => {
        try {
            const { data } = await rawMaterialCardService.materialCopy( { id: arg.id, data: arg.data } );
            return {
                costs: data as RawMaterialCardCost[]
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

// -------------------------------------------------------------------------------------
// reducer actions
// -------------------------------------------------------------------------------------

const resetRawMaterialCard = createAction( "rawMaterialCard/reset" );

// -------------------------------------------------------------------------------------
// exports
// -------------------------------------------------------------------------------------

export {
    getRawMaterialCardCosts,
    createRawMaterialCardCosts,
    updateRawMaterialCardCost,
    updateProcureRawMaterialCardCost,
    deleteRawMaterialCardCost,
    resetRawMaterialCard,
    copyRawMaterial,

};