import React, { useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import CancelIcon from "@mui/icons-material/Cancel";
import { Autocomplete, Divider, TextField } from "@mui/material";
import { API } from "../../api-services";
import { LoadingButton } from "@mui/lab";
import Swal from "sweetalert2";
import successIcon from "../../Assets/images/success.png";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40vw",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};
interface CardMoveModalProps {
  onCloseModal: any;
  isOpen: any;
  setSelectedType: any;
  Item: any;
}

const CardMoveModal = (props: CardMoveModalProps) => {
  const {
    projectId,
    projectname,
    abbr,
    topvoult,
    subSystem,
    groupId,
    collapestatus,

    pageNumber,
    productpage,
  } = useRouteParams<any>();

  const { onCloseModal, isOpen, Item, setSelectedType } = props;
  const [GroupList, setGroupList] = useState<any>([]);
  const [SelectedGroup, setSelectedGroup] = useState<any>({});
  const [loader, setLoader] = useState<any>(false);
  const [sequence, setSequence] = useState<any>(null);
  const handleGroupSelectChange = (item: any) => {
    setSelectedGroup(item);
  };
  const handleChange = (e: any) => {
    setSequence(e.target.value);
  };

  React.useEffect(() => {
    API.get(`/circuit_diagram/arch_groups/`, {
      project: projectId,
      abbreviation: abbr,
    })
      .then((res: any) => {
        var filterGroup = res?.data?.filter(
          (item: any) => item?.id !== Item?.id
        );
        setGroupList(res?.data);
      })
      .catch((err: any) => { });
  }, [isOpen]);

  const Movehandler = () => {
    setLoader(true);
    API.put(`/circuit_diagram/archs/${Item?.id}/`, {
      group: SelectedGroup?.id,
      move_singal_sequence: sequence,
    })
      .then((res: any) => {
        setSelectedType((prev: any) => prev + 1);
        onCloseModal();
        Swal.fire({
          html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
          <br />
          <p style="color:#007fff;">Move Successfully!</p>   
           </div>`,
        });
        setLoader(false);
      })
      .catch((err: any) => {
        setLoader(false);
      });
  };

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}>
              Architecture Move
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ padding: '0 1rem' }}>
            <Box sx={{
              display: "flex",
              columnGap: "2rem"
            }}>
              <TextField
                onChange={(e) => handleChange(e)}
                type="number"
                value={sequence}
                sx={{
                  width: "100%",
                  ".MuiInputBase-root.MuiInput-root": {
                    fontSize: '1rem !important'
                  },
                  '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                  '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                }}
                placeholder="Enter Sequence Number"
                variant="standard"
              />
              <Autocomplete
                id="combo-box-demo"
                value={SelectedGroup}
                sx={{
                  width: "100%",
                  ".MuiAutocomplete-root .MuiAutocomplete-input": {
                    fontSize: "1rem",
                  },
                }}
                onChange={(event, newValue) => {
                  handleGroupSelectChange(newValue);
                }}
                options={GroupList}
                // @ts-ignore
                getOptionLabel={(option) =>
                  option?.name == undefined ? "" : option?.name
                }
                renderInput={(params) => (
                  <TextField
                    placeholder="Select Group"
                    {...params}
                    variant="standard"
                    sx={{
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                      ".MuiSvgIcon-root": { color: "primary.main" },
                    }}
                  />
                )}
              />
            </Box>
            <Box sx={{ textAlign: "right", width: "100%", marginTop: "1rem" }}>
              <LoadingButton size="small" variant="contained" onClick={() => Movehandler()} loading={loader}>
                Move
              </LoadingButton>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
export default CardMoveModal;
