// vendors
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { ID } from "../../utlis/commonInterfaces";
import overheadCardService from "../Services/overheadCard.service";
import { OverheadCardCost } from "../Reducers/overheadCard.reducer";

// interfaces

// redux
// import { OverheadCardCost } from "../Reducers/overheadCard.reducer";

// // services
// import overheadCardService from "app/services/overheadCard.service";

// redux

// -------------------------------------------------------------------------------------
// api actions
// -------------------------------------------------------------------------------------

export interface GetOverheadCardCostsArg {
    costEstimationId: ID;
    tableId:any
}
const getOverheadCardCosts = createAsyncThunk(
    "overheadCard/get",
    async ( arg: GetOverheadCardCostsArg, { rejectWithValue } ) => {
        try {
            const { data } = await overheadCardService.get( arg );

            return {
                costs: data as OverheadCardCost[]
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

// -------------------------------------------------------------------------------------

export interface CreateOverheadCardCostsArg {
    costEstimationId: ID;
    alias: string;
}
const createOverheadCardCost = createAsyncThunk(
    "overheadCard/create",
    async ( arg: CreateOverheadCardCostsArg, { rejectWithValue } ) => {
        try {
            const { data } = await overheadCardService.create( arg );

            return {
                cost: data as OverheadCardCost
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

// -------------------------------------------------------------------------------------

export interface UpdateOverheadCardCostArg {
    id: ID;
    updates: Partial<Pick<OverheadCardCost, "result" | "alias" | "quantity"|"sequence">>;
}
const updateOverheadCardCost = createAsyncThunk(
    "overheadCard/update",
    async ( arg: UpdateOverheadCardCostArg, { rejectWithValue } ) => {
        try {
            const { data } = await overheadCardService.update( arg );

            return {
                cost: data as OverheadCardCost
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

// -------------------------------------------------------------------------------------

export interface DeleteOverheadCardCost {
    id: ID;
}
const deleteOverheadCardCost = createAsyncThunk(
    "overheadCard/delete",
    async ( arg: DeleteOverheadCardCost, { rejectWithValue } ) => {
        try {
            await overheadCardService.delete( arg );

            return {};
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

// -------------------------------------------------------------------------------------
// reducer actions
// -------------------------------------------------------------------------------------

const resetOverheadCard = createAction( "overheadCard/reset" );

// -------------------------------------------------------------------------------------
// exports
// -------------------------------------------------------------------------------------

export {
    getOverheadCardCosts,
    createOverheadCardCost,
    updateOverheadCardCost,
    deleteOverheadCardCost,
    resetOverheadCard
};