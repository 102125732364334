import React, { useContext, useEffect, useState } from "react";
import {
  CellNameFive,
  CellNameOne,
  CellNameSecond,
  CellNameThird,
} from "./BomTemplatedHeader";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import {
  Autocomplete,
  Box,
  Chip,
  IconButton,
  MenuItem,
  Select,
  SelectChangeEvent,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import DeleteIcon from "@mui/icons-material/Delete";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { API } from "../../api-services";
import { debounce, set } from "lodash";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";
import TableChartIcon from "@mui/icons-material/TableChart";
import { UserContext } from "./BomConfigurationLanding";
import { useBaseModal } from "../../ComponentUI/SearchPage/useBaseModal";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import BOMCreateCostCalculatorModal from "./BOMCreateCostCalculatorModal";
import { BOMConfigCalculator } from "../../DataBaseModule/ParameterLibrary/BOMCalculatorModal";
import SettingsIcon from "@mui/icons-material/Settings";
import calcIcon from "../../Assets/images/calculator.svg";
import calculator_green from "../../Assets/images/calculator_green.svg";
import { ConstantLine } from "devextreme-react/chart";
import { BOMConfigTableCalculator } from "../../DataBaseModule/ParameterLibrary/BOMTableCalculatorModal";
import { BomTemplateinfoModal } from "./BomTemplateinfoModal";
import { AUTH } from "../../Redux/Services/auth.service";
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import Swal from "sweetalert2";
import { userSelectors } from "../../Redux/Reducers/user.reducer";
import { useSelector } from "react-redux";


interface BomConfigurationsRowrenderProps {
  rowItem: any;
  keys: any;
  setCounter: any;
  setParentCount:any;
  setUpdatedCounter: any;
  workstationList: any;
  handleChangeCheckBox: any;
  tablecallBackhandler: any;
  InfoModalhandler: any;
  validationdata: any;
  CategoryList: any;
}

const BomConfigurationsRowrender = (props: BomConfigurationsRowrenderProps) => {
  const {
    rowItem,
    keys,
    setCounter,
    setParentCount,
    workstationList,
    handleChangeCheckBox,
    setUpdatedCounter,
    tablecallBackhandler,
    InfoModalhandler,
    validationdata,
    CategoryList,
  } = props;

  const history = useHistory<any>();
  const {
    projectId,
    projectname,
    categoryId,
    productId,
    datatype,
    categoryname,
    validationId,
  } = useRouteParams<any>();
  // console.log("validationId", validationId, validationdata);
  const userProfilePic = useSelector(userSelectors.selectAll);
  
  const [tagData, settagData] = useState<any>(rowItem?.tag);
  const userpremission = useContext(UserContext);
  const [sequence, setSequence] = useState<any>(rowItem?.sequence);
  const [requiredValue, setrequiredValue] = useState<any>(rowItem?.required);
  const [WorkStations, setWorkStations] = useState<any>([]);
  const [database, setDataBase] = useState<any>({});
  const [workstationData, setworkstationData] = useState<any>([]);
  const [inputData, setInputData] = useState<any>([]);
  const [calculatorID, setCalculatorID] = useState<any>(0);
  const calculatorModal = useBaseParamsModal();
  const calculatorTableModal = useBaseParamsModal();
  const bomCostCalculator = useBaseParamsModal();
  const infoModal = useBaseParamsModal();
  const DataBaseName = [{ text: "SupplierDatabase", name: "Supplier DB" }];
  useEffect(() => {
    var filterdIds = workstationList?.filter((item: any, index: any) => {
      return rowItem?.workstation?.includes(item?.id);
    });
    setworkstationData(workstationList && workstationList);
    setWorkStations(filterdIds);
    var data = DataBaseName?.filter((item: any, index: any) => {
      return item?.text == rowItem?.database;
    });

    setDataBase(data[0]);
  }, [rowItem]);


  const parameterDatabaseRidirection = () => {
    window.open(
      `/#/data-base/Parameter-library/${rowItem?.data_type}/0`,
      "__blank"
    );
  };

  const [lockStatus, setLockStatus] = useState<any>(null);

  const getLockStatusByName = (categoryname: any) => {
    // Filter the CategoryList array to find the item with the matching name
    const filteredItem = CategoryList.filter((item: any) => item.category === categoryId);
    if (filteredItem.length > 0) {
      setLockStatus(filteredItem[0].lock);
    } else {
      // If no matching item is found, set the lockStatus to null or any default value
      setLockStatus(null);
    }
  };
  useEffect(() => {
    if (categoryname) {
      getLockStatusByName(categoryname);
    }
  }, [categoryname, lockStatus]);


  const CalculatorHandler = (rowItem: any) => {
    setInputData(rowItem);
    setCalculatorID(rowItem?.id);
    if (datatype == "BOM-table-calculator") {
      calculatorTableModal.open({
        id: rowItem?.id,
        calcId: rowItem?.table_calculator?.id,
        finalResult: rowItem?.table_calculator?.result,
        BOM_table_unit: rowItem?.table_calculator?.unit,
        table_calculator: rowItem?.table_calculator,
      });
    } else {
      calculatorModal.open(rowItem?.id);
    }

    // bomCostCalculator.open(rowItem?.id);
  };

  var optionData = workstationData && workstationData;
  const ExactDebounce = React.useRef(
    debounce(
      async (
        criteria: any,
        Id: any,
        type: any,
        topVault: any,
        validationId: any
      ) => {
        if (type == "Tag") {
          await API.put(`/config/parameter_mapping/${Id}/`, {
            tag: criteria,
            top_vault:
              validationId == 0
                ? topVault.toString()
                : validationId?.toString(),
          }).then((res: any) => {
            settagData(res.data?.tag);
            handleChangeCheckBox();
            // setCounter((prev:any)=>prev+1)
          });
        } else if (type == "sequence") {
          if (criteria !== null) {
            await API.put(`/config/parameter_mapping/${Id}/`, {
              sequence: parseInt(criteria),
              top_vault:
                validationId == 0
                  ? topVault?.toString()
                  : validationId?.toString(),
            }).then((res: any) => {
              //  setSequence(res.data?.sequence );
              setUpdatedCounter((prev: any) => prev + 1);
              handleChangeCheckBox();
            });
          }
        } else if (type == "required") {
          await API.put(`/config/parameter_mapping/${Id}/`, {
            required: criteria,
            top_vault:
              validationId == 0
                ? topVault?.toString()
                : validationId?.toString(),
          }).then((res: any) => {
            setrequiredValue(res.data?.required);
            handleChangeCheckBox();
            // setCounter((prev:any)=>prev+1)
          });
        } else if (type == "workstations") {
          await API.put(`/config/parameter_mapping/${Id}/`, {
            workstation: criteria,
            top_vault:
              validationId == 0
                ? topVault?.toString()
                : validationId?.toString(),
          }).then((res: any) => {
            var filterdIds = workstationList?.filter(
              (item: any, index: any) => {
                return res.data?.workstation?.includes(item?.id);
              }
            );

            setworkstationData(workstationList && workstationList);
            setWorkStations(filterdIds);
          });
        }
        if (type == "dataBase") {
          await API.put(`/config/parameter_mapping/${Id}/`, {
            database: criteria == undefined ? null : criteria,
            top_vault:
              validationId == 0
                ? topVault?.toString()
                : validationId?.toString(),
          }).then((res: any) => {
            var data = DataBaseName?.filter((item: any, index: any) => {
              return item?.text == res?.data?.database;
            });

            setDataBase(data[0]);
          });
        }
      },
      700
    )
  ).current;

  const handleChange = (e: any, type: any) => {
    if (type == "Tag") {
      settagData(e.target.value);
      ExactDebounce(
        e.target.value,
        rowItem?.id,
        "Tag",
        productId,
        validationId
      );
      // setCounter((prev:any)=>prev+1)
    } else if (type == "sequence") {
      setSequence(e.target.value);
      if (e.target.value !== null || e?.target?.value?.length !== 0) {
        ExactDebounce(
          e.target.value,
          rowItem?.id,
          "sequence",
          productId,
          validationId
        );
      }
    }

    if (type == "required") {
      setrequiredValue(e.target.value);
      ExactDebounce(
        e.target.value,
        rowItem?.id,
        "required",
        productId,
        validationId
      );
      // setCounter((prev:any)=>prev+1)
    }
  };
  
  const validationIdArray = Array.isArray(validationId)
  ? validationId // If it's already an array, use it as-is
  : validationId?.toString().includes(',')
    ? validationId.split(',').map((item: any) => item.trim()) // Convert comma-separated string to array
    : [validationId.toString()];
  const handleUpdateChange = (e: any) => {
    setrequiredValue(e.target.value);    
    API.put(
      `/xcpep/bom_calculator_config/`,
      {
        calculator_id: rowItem?.id,
        is_required: e.target.value,
        top_vault_id: validationIdArray,
      },
      0
    )
      .then((res: any) => {
        setCounter((prev: any) => prev + 1);
        handleChangeCheckBox();
      })
      .catch((err: any) => { });
  };

  const handleSelectChange = (item: any) => {
    setWorkStations(item);
    var ids = item?.map((item: any, index: any) => {
      return item?.id;
    });
    var filterdata = workstationData?.filter((items: any, index: any) => {
      return !ids.includes(items?.id);
    });

    ExactDebounce(ids, rowItem?.id, "workstations", productId, validationId);
    setworkstationData(filterdata);
  };

  const handledatabseSelectChange = (item: any) => {
    setDataBase(item);
    ExactDebounce(item?.text, rowItem?.id, "dataBase", productId, validationId);
  };

  const lockparameterHandler = (rows: any) => {
    Swal.fire({
      title: rows?.lock == true ? "Unlock" : "Lock",
      text:
        rows?.lock == true
          ? "Are you sure you want to Unlock this parameter?"
          : "Are you sure you want to lock this parameter?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result: any) => {
      if (result.isConfirmed) {
        // setLoader(true);
        API.put(`/config/parameter_mapping/${rows?.id}/`, {
          lock: rows?.lock == true ? false : true,
          top_vault:
            validationId == 0
              ? productId?.toString()
              : validationId?.toString(),
        }).then((res: any) => {
          setParentCount((prev: any) => prev + 1);
          setCounter((prev: any) => prev + 1);
          // setLoader(false);
        })
          .catch((err: any) => {
            console.log("Server Error");
            // setLoader(false);
          });
      }
    });
  };

  // Cost model
  const lockCostModelHandler = (rows: any) => {
    Swal.fire({
      title: rows?.lock == true ? "Unlock" : "Lock",
      text:
        rows?.lock == true
          ? "Are you sure you want to Unlock this parameter?"
          : "Are you sure you want to lock this parameter?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result: any) => {
      if (result.isConfirmed) {
        // setLoader(true);
        API.put(`/xcpep/bom_calculator_config/${rows?.id}/`, {
          lock: rows?.lock == true ? false : true,
          top_vault:
            validationId == 0
              ? productId?.toString()
              : validationId?.toString(),
        }).then((res: any) => {
          setParentCount((prev: any) => prev + 1);
          setCounter((prev: any) => prev + 1);
          // setLoader(false);
        })
          .catch((err: any) => {
            console.log("Server Error");
            // setLoader(false);
          });
      }
    });
  };

  const DeleteHandler = () => {
    swal({
      text: `Are you sure you want to delete ?`,
      icon: "error",
      buttons: ["Cancel", "Yes, Delete"],
    }).then((confirm) => {
      if (confirm) {
        API.delete(
          `/config/parameter_mapping/${rowItem?.id}/`,
          {
            top_vault:
              validationId == 0
                ? productId?.toString()
                : validationId?.toString(),
          },
          0
        )
          .then((res: any) => {
            setCounter((prev: any) => prev + 1);
            handleChangeCheckBox();
          })
          .catch((err: any) => { });
      }
    });
  };

  // http://localhost:9000/xcpep/bom_calculator_config?calculator_id=157

  const DeleteHandlerCostModel = () => {
    swal({
      text: `Are you sure you want to delete ?`,
      icon: "error",
      buttons: ["Cancel", "Yes, Delete"],
    }).then((confirm) => {
      if (confirm) {
        API.delete(
          `/xcpep/bom_calculator_config/`,
          {
            calculator_id: rowItem?.id,
            top_vault_id: validationIdArray,
          },
          0
        )
          .then((res: any) => {
            setCounter((prev: any) => prev + 1);
            handleChangeCheckBox();
          })
          .catch((err: any) => { });
      }
    });
  };

  const TableHandler = (item: any) => {
    tablecallBackhandler(item);
  };
  const Infohandler = (item: any) => {
    InfoModalhandler(item);
  };


  
 console.log("rowItem", rowItem);

 



  return (
    <>
      {CellNameOne.includes(datatype) ? (
        <TableRow>
          {/* <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', }}>{keys + 1}</TableCell> */}
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <TextField
              disabled={rowItem?.lock == true ? true :
                userpremission && userpremission?.includes("U") ? false : true
              }
              value={sequence}
              type="number"
              variant="standard"

              inputProps={{
                step: "any",
                min: 1,
                // style: { fontSize: '1rem', marginTop: '0.4rem' }
              }}
              onChange={(e: any) => handleChange(e, "sequence")}
              placeholder="sequence"
              sx={{
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
              }}
            />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {rowItem?.parameter?.name}
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {rowItem?.parameter?.description}
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {rowItem?.parameter?.unit}
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {rowItem?.parameter?.exceptions}
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {rowItem?.parameter?.allowed_values?.length == 0
              ? "_"
              : rowItem?.parameter?.allowed_values.toString()}
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {" "}
            {rowItem?.parameter?.not_allowed_values?.length == 0
              ? "_"
              : rowItem?.parameter?.not_allowed_values.toString()}
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {rowItem?.parameter?.default_value}
            {rowItem?.parameter?.default_value == ""
              ? "_"
              : rowItem?.parameter?.default_value}
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
            onClick={() =>
              rowItem?.info_doc != null &&
              window.open(rowItem?.info_doc, "_blank")
            }
          >
            {rowItem?.info_doc == null ? (
              "-"
            ) : (
              <VisibilityOutlinedIcon sx={{ cursor: "pointer" }} />
            )}
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Select
              disabled={rowItem?.lock == true ? true :
                userpremission && userpremission?.includes("U") ? false : true
              }
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={requiredValue}
              variant="standard"
              sx={{
                "&:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                {
                  fontSize: "1rem",
                },
                ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
              }}
              onChange={(e: any) => handleChange(e, "required")}
              label="required"
            >
              {/* @ts-ignore */}
              <MenuItem sx={{ fontSize: "1rem" }} value={true}>
                Yes
              </MenuItem>
              {/* @ts-ignore */}
              <MenuItem sx={{ fontSize: "1rem" }} value={false}>
                No
              </MenuItem>
            </Select>
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Autocomplete
              disabled={rowItem?.lock == true ? true :
                userpremission && userpremission?.includes("U") ? false : true
              }
              id="combo-box-demo"
              value={WorkStations}
              sx={{
                ".MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator":
                {
                  color: "primary.main",
                },
                ".MuiButtonBase-root.MuiChip-root": {
                  backgroundColor: "primary.light",
                },
              }}
              multiple
              disableClearable
              limitTags={2}
              disableCloseOnSelect
              onChange={(event, newValue) => {
                handleSelectChange(newValue);
              }}
              options={optionData}
              // @ts-ignore
              getOptionLabel={(option) => option?.name}
              renderOption={(props, option) => (
                <li
                  {...props}
                  style={{ fontSize: "1rem", whiteSpace: "nowrap" }}
                >
                  {option?.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  placeholder="work station"
                  {...params}
                  variant="standard"
                  sx={{
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "primary.light",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "primary.main",
                    },
                    ".MuiInputBase-root.MuiInput-root": {
                      fontSize: "1rem !important",
                    },
                    ".MuiSvgIcon-root": { color: "primary.main" },
                  }}
                />
              )}
            />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              textAlign: "center",
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '1rem' }}>
              {rowItem?.map_status ? (
                <InfoIcon
                  titleAccess="Info"
                  onClick={() => Infohandler(rowItem)}
                  sx={{ cursor: "pointer" }}
                />
              ) : (
                <>
                  {(userpremission && userpremission?.includes("D")) ? (
                    <DeleteIcon
                      sx={{ cursor: "pointer", opacity: 1 }}
                      titleAccess="Delete"
                      onClick={() => DeleteHandler()}
                    />
                  ) : (
                    <DeleteIcon sx={{ opacity: 0.3 }} titleAccess="Delete" />
                  )}
                </>

              )}
              <LaunchOutlinedIcon
                sx={{ cursor: "pointer" }}
                titleAccess="Go to Parameter-DataBase"
                onClick={() => parameterDatabaseRidirection()}
              />
              {/* {(rowItem?.table_calculator?.result == "" || rowItem?.table_calculator?.result == null) && (datatype == "BOM-table-calculator") && (
                <img
                  src={calcIcon}
                  style={{ cursor: rowItem?.table_calc_enable ? "pointer" : "not-allowed", height: "1.4rem", opacity: rowItem?.table_calc_enable ? 1 : 0.5 }}
                  title={
                    (rowItem?.table_calculator?.result == "" || rowItem?.table_calculator?.result == null)
                      ? "BOM Configuration table Calculator"
                      : `Result: ${rowItem?.table_calculator?.result}`
                  }
                  onClick={() => {
                    if (rowItem?.table_calc_enable) {
                      CalculatorHandler(rowItem)
                    }
                  }}
                />
              )} */}
              {(rowItem?.table_calculator?.result != "" && rowItem?.table_calculator?.result != null) && (datatype == "BOM-table-calculator") && (
                <img
                  src={calculator_green}
                  style={{ cursor: "pointer", height: "1.4rem" }}
                  title={
                    rowItem?.table_calculator?.result == ""
                      ? "BOM Configuration Calculator"
                      : `Result: ${rowItem?.table_calculator?.result}`
                  }
                  onClick={() => CalculatorHandler(rowItem)}
                />
              )}
              {datatype === "BOM-table-calculator" && (
                <>
                  {/*  <IconButton
                   disabled={
                     false : true
                   }> */}
                  {userpremission && userpremission?.includes("U") ? (
                    <TableChartIcon
                      onClick={() => TableHandler(rowItem)}
                      sx={{ cursor: "pointer", opacity: "1" }}
                    />
                  ) : (
                    <TableChartIcon sx={{ opacity: "0.3" }} />
                  )}
                  
                  {/* </IconButton> */}
                </>
              )}

              {rowItem?.lock === true ? (
                // Case 1: If user is SuperUser or ProjectAdmin, show clickable green lock
                (AUTH.isSuperUser || userProfilePic[0]?.is_project_admin) ? (
                  <LockIcon
                    sx={{ cursor: 'pointer', color: 'green' }}
                    onClick={() => lockparameterHandler(rowItem)}
                  />
                ) : (
                  // Case 2: If user is not SuperUser or ProjectAdmin, show disabled lock
                  <LockIcon sx={{ opacity: 0.5 }} />
                )
              ) : (
                // Case 3: If lock is false and user is SuperUser or ProjectAdmin, show red open lock
                (AUTH.isSuperUser || userProfilePic[0]?.is_project_admin) && (
                  <LockOpenIcon
                    sx={{ cursor: 'pointer', color: 'red' }}
                    onClick={() => lockparameterHandler(rowItem)}
                  />
                )
              )}
            </Box>
          </TableCell>
        </TableRow>
      ) : CellNameSecond.includes(datatype) ? (
        <TableRow>
          {/* <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', }}>{keys + 1}</TableCell> */}
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <TextField
              variant="standard"
              value={sequence}
              type="number"
              inputProps={{
                step: "any",
                min: 1,
                // style: { fontSize: '1rem', marginTop: '0.4rem' }
              }}
              disabled={rowItem?.lock}
              onChange={(e: any) => handleChange(e, "sequence")}
              placeholder="sequence"
              sx={{
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
              }}
            />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {rowItem?.parameter?.name}
          </TableCell>

          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {rowItem?.parameter?.description}
          </TableCell>

          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
            onClick={() =>
              rowItem?.info_doc != null &&
              window.open(rowItem?.info_doc, "_blank")
            }
          >
            {rowItem?.info_doc == null ? (
              "-"
            ) : (
              <VisibilityOutlinedIcon sx={{ cursor: "pointer" }} />
            )}
          </TableCell>
          {datatype == "database" && (
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              <Autocomplete
                disabled={rowItem?.lock == true ? true :
                  userpremission && userpremission?.includes("U") ? false : true
                }
                id="combo-box-demo"
                value={database}
                sx={{ width: "auto" }}
                onChange={(event, newValue) => {
                  handledatabseSelectChange(newValue);
                }}
                options={DataBaseName}
                // @ts-ignore
                getOptionLabel={(option) =>
                  option?.name == undefined ? "" : option?.name
                }
                renderInput={(params) => (
                  <TextField
                    placeholder="Data base"
                    {...params}
                    variant="standard"
                    sx={{
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      ".MuiSvgIcon-root": { color: "primary.main" },
                    }}
                  />
                )}
              />
            </TableCell>
          )}

          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Select
              disabled={rowItem?.lock == true ? true :
                userpremission && userpremission?.includes("U") ? false : true
              }
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={requiredValue}
              variant="standard"
              sx={{
                "&:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                {
                  fontSize: "1rem",
                },
                ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
              }}
              onChange={(e: any) => handleChange(e, "required")}
              label="required"
            >
              {/* @ts-ignore */}
              <MenuItem sx={{ fontSize: "1rem" }} value={true}>
                Yes
              </MenuItem>
              {/* @ts-ignore */}
              <MenuItem sx={{ fontSize: "1rem" }} value={false}>
                No
              </MenuItem>
            </Select>
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Autocomplete
              id="combo-box-demo"
              value={WorkStations}
              disabled={rowItem?.lock == true ? true :
                userpremission && userpremission?.includes("U") ? false : true
              }
              sx={{ width: "auto" }}
              multiple
              disableClearable
              limitTags={2}
              disableCloseOnSelect
              onChange={(event, newValue) => {
                handleSelectChange(newValue);
              }}
              options={optionData}
              // @ts-ignore
              getOptionLabel={(option) => option?.name}
              // renderTags={(tagValue, getTagProps) =>
              //   tagValue.map((option, index) => (
              //     <Chip
              //       size="small"
              //       //  @ts-ignore
              //       sx={{
              //         color: "primary.main",
              //         background: `${option?.user_color}`,
              //         fontSize: "1rem",
              //       }}
              //       // @ts-ignore
              //       label={option?.email}
              //       {...getTagProps({ index })}
              //     // disabled={fixedOptions.indexOf(option) !== -1}
              //     />
              //   ))
              // }
              renderOption={(props, option) => (
                <li
                  {...props}
                  style={{ fontSize: "1rem", whiteSpace: "nowrap" }}
                >
                  {option?.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  placeholder="work station"
                  {...params}
                  disabled={rowItem?.lock}
                  variant="standard"
                  sx={{
                    ".MuiInputBase-root.MuiInput-root": {
                      fontSize: "1rem !important",
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "primary.main",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "primary.main",
                    },
                    ".MuiSvgIcon-root": { color: "primary.main" },
                    // @ts-ignore
                    // backgroundColor: `${params?.user_color}`,
                  }}
                //   InputProps={{
                //     // background: `${params?.user_color}`
                //     // @ts-ignore
                //     style: "background-color: ${params?.user_color",

                //  }}
                />
              )}
            />
          </TableCell>

          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              textAlign: "center",
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '1rem' }}>
              {rowItem?.map_status ? (
                <InfoIcon
                  titleAccess="Info"
                  onClick={() => Infohandler(rowItem)}
                  sx={{ cursor: "pointer" }}
                />
              ) : (
                <>
                  {userpremission && userpremission?.includes("D") ? (
                    <DeleteIcon
                      sx={{ cursor: "pointer", opacity: 1 }}
                      titleAccess="Delete"
                      onClick={() => DeleteHandler()}
                    />
                  ) : (
                    <DeleteIcon sx={{ opacity: 0.3 }} titleAccess="Delete" />
                  )}
                </>
              )}
              <LaunchOutlinedIcon
                sx={{ cursor: "pointer" }}
                titleAccess="Go to Parameter-DataBase"
                onClick={() => parameterDatabaseRidirection()}
              />
              {datatype == "table" && (
                <>
                  {/*  <IconButton
                   disabled={
                     false : true
                   }> */}
                  {userpremission && userpremission?.includes("U") ? (
                    <TableChartIcon
                      onClick={() => TableHandler(rowItem)}
                      sx={{ cursor: "pointer", opacity: "1" }}
                    />
                  ) : (
                    <TableChartIcon sx={{ opacity: "0.3" }} />
                  )}
                  {/* </IconButton> */}
                </>
              )}

              {rowItem?.lock === true ? (
                // Case 1: If user is SuperUser or ProjectAdmin, show clickable green lock
                (AUTH.isSuperUser || userProfilePic[0]?.is_project_admin) ? (
                  <LockIcon
                    sx={{ cursor: 'pointer', color: 'green' }}
                    onClick={() => lockparameterHandler(rowItem)}
                  />
                ) : (
                  // Case 2: If user is not SuperUser or ProjectAdmin, show disabled lock
                  <LockIcon sx={{ opacity: 0.5 }} />
                )
              ) : (
                // Case 3: If lock is false and user is SuperUser or ProjectAdmin, show red open lock
                (AUTH.isSuperUser || userProfilePic[0]?.is_project_admin) && (
                  <LockOpenIcon
                    sx={{ cursor: 'pointer', color: 'red' }}
                    onClick={() => lockparameterHandler(rowItem)}
                  />
                )
              )}
            </Box>
          </TableCell>
        </TableRow>
      ) : CellNameThird.includes(datatype) ? (
        <TableRow>
          {/* <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', }}>{keys + 1}</TableCell> */}
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <TextField
              disabled={rowItem?.lock == true ? true :
                userpremission && userpremission?.includes("U") ? false : true
              }
              variant="standard"
              value={sequence}
              onChange={(e: any) => handleChange(e, "sequence")}
              type="number"
              inputProps={{
                step: "any",
                min: 1,
                // style: { fontSize: '1rem', marginTop: '0.4rem' }
              }}
              placeholder="sequence"
              sx={{
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
              }}
            />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {rowItem?.parameter?.name}
          </TableCell>

          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {rowItem?.parameter?.description}
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {rowItem?.parameter?.unit}
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {rowItem?.parameter?.exceptions}
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {rowItem?.parameter?.allowed_values?.length == 0
              ? "_"
              : rowItem?.parameter?.allowed_values.toString()}
          </TableCell>

          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
            onClick={() =>
              rowItem?.info_doc != null &&
              window.open(rowItem?.info_doc, "_blank")
            }
          >
            {rowItem?.info_doc == null ? (
              "-"
            ) : (
              <VisibilityOutlinedIcon sx={{ cursor: "pointer" }} />
            )}
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Select
              disabled={rowItem?.lock == true ? true :
                userpremission && userpremission?.includes("U") ? false : true
              }
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={requiredValue}
              variant="standard"
              sx={{
                "&:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                {
                  fontSize: "1rem",
                },
                ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
              }}
              onChange={(e: any) => handleChange(e, "required")}
              label="required"
            >
              {/* @ts-ignore */}
              <MenuItem sx={{ fontSize: "1rem" }} value={true}>
                Yes
              </MenuItem>
              {/* @ts-ignore */}
              <MenuItem sx={{ fontSize: "1rem" }} value={false}>
                No
              </MenuItem>
            </Select>
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Autocomplete
              id="combo-box-demo"
              value={WorkStations}
              sx={{ width: "auto" }}
              multiple
              disableClearable
              limitTags={2}
              disabled={rowItem?.lock}
              disableCloseOnSelect
              onChange={(event, newValue) => {
                handleSelectChange(newValue);
              }}
              options={optionData}
              // @ts-ignore
              getOptionLabel={(option) => option?.name}
              renderOption={(props, option) => (
                <li
                  {...props}
                  style={{ fontSize: "1rem", whiteSpace: "nowrap" }}
                >
                  {option?.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  placeholder="work station"
                  sx={{
                    ".MuiInputBase-root.MuiInput-root": {
                      fontSize: "1rem !important",
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "primary.main",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "primary.main",
                    },
                    ".MuiSvgIcon-root": { color: "primary.main" },
                  }}
                />
              )}
            />
          </TableCell>

          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              textAlign: "center",
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '1rem' }}>
              {rowItem?.map_status ? (
                <InfoIcon
                  titleAccess="Info"
                  onClick={() => Infohandler(rowItem)}
                  sx={{ cursor: "pointer" }}
                />
              ) : (
                <>
                  {userpremission && userpremission?.includes("D") ? (
                    <DeleteIcon
                      sx={{ cursor: "pointer", opacity: 1 }}
                      titleAccess="Delete"
                      onClick={() => DeleteHandler()}
                    />
                  ) : (
                    <DeleteIcon sx={{ opacity: 0.3 }} titleAccess="Delete" />
                  )}
                </>
              )}
              <LaunchOutlinedIcon
                sx={{ cursor: "pointer" }}
                titleAccess="Go to Parameter-DataBase"
                onClick={() => parameterDatabaseRidirection()}
              />
              {rowItem?.lock === true ? (
                // Case 1: If user is SuperUser or ProjectAdmin, show clickable green lock
                (AUTH.isSuperUser || userProfilePic[0]?.is_project_admin) ? (
                  <LockIcon
                    sx={{ cursor: 'pointer', color: 'green' }}
                    onClick={() => lockparameterHandler(rowItem)}
                  />
                ) : (
                  // Case 2: If user is not SuperUser or ProjectAdmin, show disabled lock
                  <LockIcon sx={{ opacity: 0.5 }} />
                )
              ) : (
                // Case 3: If lock is false and user is SuperUser or ProjectAdmin, show red open lock
                (AUTH.isSuperUser || userProfilePic[0]?.is_project_admin) && (
                  <LockOpenIcon
                    sx={{ cursor: 'pointer', color: 'red' }}
                    onClick={() => lockparameterHandler(rowItem)}
                  />
                )
              )}
            </Box>
          </TableCell>
        </TableRow>
      ) : CellNameFive.includes(datatype) ? (
        <TableRow>
          {/* <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', }}>{keys + 1}</TableCell> */}
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <TextField
              disabled={rowItem?.lock == true ? true :
                userpremission && userpremission?.includes("U") ? false : true
              }
              value={rowItem?.sequence}
              variant="standard"
              type="number"
              inputProps={{
                step: "any",
                min: 1,
                // style: { fontSize: '1rem', marginTop: '0.4rem' }
              }}
              onChange={(e: any) => handleChange(e, "sequence")}
              placeholder="sequence"
              sx={{
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
              }}
            />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {rowItem?.name}
          </TableCell>

          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {rowItem?.description}
          </TableCell>


          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Select
              disabled={rowItem?.lock == true ? true :
                userpremission && userpremission?.includes("U") ? false : true
              }
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={rowItem?.is_required}
              variant="standard"
              sx={{
                "&:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                {
                  fontSize: "1rem",
                },
                ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
              }}
              onChange={(e: any) => handleUpdateChange(e)}
              label="required"
            >
              {/* @ts-ignore */}
              <MenuItem sx={{ fontSize: "1rem" }} value={true}>
                Yes
              </MenuItem>
              {/* @ts-ignore */}
              <MenuItem sx={{ fontSize: "1rem" }} value={false}>
                No
              </MenuItem>
            </Select>
          </TableCell>


          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              textAlign: "center",
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '1rem' }}>
              {rowItem?.map_status ? (
                <InfoIcon
                  titleAccess="Info"
                  onClick={() => Infohandler(rowItem)}
                  sx={{ cursor: "pointer" }}
                />
              ) : (
                <>
                  {rowItem?.mapped_status ?
                    <IconButton
                      style={{ color: "#007fff", }}
                      onClick={() => infoModal.open(rowItem)}
                      title="Place Used (BOM Category)">
                      <InfoIcon />
                    </IconButton>
                    : <IconButton title="Delete"
                      sx={{ cursor: "pointer", color: 'primary.main', opacity: userpremission && userpremission?.includes("D") ? 1 : 0.5 }}
                      onClick={() => {
                        if (userpremission && userpremission?.includes("D")) {
                          DeleteHandlerCostModel()
                        }
                      }}>
                      <DeleteIcon />
                    </IconButton>}
                </>
              )}
              {/* <LaunchOutlinedIcon
              sx={{ cursor: "pointer" }}
              titleAccess="Go to Parameter-DataBase"
              onClick={() => parameterDatabaseRidirection()}
            /> */}
              {(rowItem?.result == "" || rowItem?.result == null) && (
                <img
                  src={calcIcon}
                  style={{ cursor: "pointer", height: "1.4rem" }}
                  title={
                    rowItem?.result == ""
                      ? "BOM Configuration Calculator"
                      : `Result: ${rowItem?.result}`
                  }
                  onClick={() => CalculatorHandler(rowItem)}
                />
              )}
              {rowItem?.result != "" && rowItem?.result != null && (
                <img
                  src={calculator_green}
                  style={{ cursor: "pointer", height: "1.4rem" }}
                  title={
                    rowItem?.result == ""
                      ? "BOM Configuration Calculator"
                      : `Result: ${rowItem?.result}`
                  }
                  onClick={() => CalculatorHandler(rowItem)}
                />
              )}
              {rowItem?.lock === true ? (
                // Case 1: If user is SuperUser or ProjectAdmin, show clickable green lock
                (AUTH.isSuperUser || userProfilePic[0]?.is_project_admin) ? (
                  <LockIcon
                    sx={{ cursor: 'pointer', color: 'green' }}
                    onClick={() => lockCostModelHandler(rowItem)}
                  />
                ) : (
                  // Case 2: If user is not SuperUser or ProjectAdmin, show disabled lock
                  <LockIcon sx={{ opacity: 0.5 }} />
                )
              ) : (
                // Case 3: If lock is false and user is SuperUser or ProjectAdmin, show red open lock
                (AUTH.isSuperUser || userProfilePic[0]?.is_project_admin) && (
                  <LockOpenIcon
                    sx={{ cursor: 'pointer', color: 'red' }}
                    onClick={() => lockCostModelHandler(rowItem)}
                  />
                )
              )}
            </Box>
          </TableCell>
        </TableRow>
      ) : (
        <TableRow>

          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <TextField
              disabled={rowItem?.lock == true ? true :
                userpremission && userpremission?.includes("U") ? false : true
              }
              value={sequence}
              variant="standard"
              type="number"
              inputProps={{
                step: "any",
                min: 1,
                // style: { fontSize: '1rem', marginTop: '0.4rem' }
              }}
              onChange={(e: any) => handleChange(e, "sequence")}
              placeholder="sequence"
              sx={{
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
              }}
            />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {rowItem?.parameter?.name}
          </TableCell>

          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            {rowItem?.parameter?.description}
          </TableCell>

          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
            onClick={() =>
              rowItem?.info_doc != null &&
              window.open(rowItem?.info_doc, "_blank")
            }
          >
            {rowItem?.info_doc == null ? (
              "-"
            ) : (
              <VisibilityOutlinedIcon sx={{ cursor: "pointer" }} />
            )}
          </TableCell>

          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Select
              disabled={rowItem?.lock == true ? true :
                userpremission && userpremission?.includes("U") ? false : true
              }
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={requiredValue}
              variant="standard"
              sx={{
                "&:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                {
                  fontSize: "1rem",
                },
                ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
              }}
              onChange={(e: any) => handleChange(e, "required")}
              label="required"
            >
              {/* @ts-ignore */}
              <MenuItem sx={{ fontSize: "1rem" }} value={true}>
                Yes
              </MenuItem>
              {/* @ts-ignore */}
              <MenuItem sx={{ fontSize: "1rem" }} value={false}>
                No
              </MenuItem>
            </Select>
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Autocomplete
              disabled={rowItem?.lock == true ? true :
                userpremission && userpremission?.includes("U") ? false : true
              }
              id="combo-box-demo"
              value={WorkStations}
              sx={{ width: "auto" }}
              multiple
              disableClearable
              limitTags={2}
              disableCloseOnSelect
              onChange={(event, newValue) => {
                handleSelectChange(newValue);
              }}
              options={optionData}
              // @ts-ignore
              getOptionLabel={(option) => option?.name}
              // renderTags={(tagValue, getTagProps) =>
              //   tagValue.map((option, index) => (
              //     <Chip
              //       size="small"
              //       //  @ts-ignore
              //       sx={{
              //         color: "primary.main",
              //         background: `${option?.user_color}`,
              //         fontSize: "1rem",
              //       }}
              //       // @ts-ignore
              //       label={option?.email}
              //       {...getTagProps({ index })}
              //     // disabled={fixedOptions.indexOf(option) !== -1}
              //     />
              //   ))
              // }
              renderOption={(props, option) => (
                <li
                  {...props}
                  style={{ fontSize: "1rem", whiteSpace: "nowrap" }}
                >
                  {option?.name}
                </li>
              )}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  placeholder="work station"
                  sx={{
                    ".MuiInputBase-root.MuiInput-root": {
                      fontSize: "1rem !important",
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "primary.main",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "primary.main",
                    },
                    ".MuiSvgIcon-root": { color: "primary.main" },
                    // @ts-ignore
                    // backgroundColor: `${params?.user_color}`,
                  }}
                //   InputProps={{
                //     // background: `${params?.user_color}`
                //     // @ts-ignore
                //     style: "background-color: ${params?.user_color",

                //  }}
                />
              )}
            />
          </TableCell>

          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              textAlign: "center",
            }}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '1rem' }}>
              {rowItem?.map_status ? (
                <InfoIcon
                  titleAccess="Info"
                  onClick={() => Infohandler(rowItem)}
                  sx={{ cursor: "pointer" }}
                />
              ) : (
                <>
                  {userpremission && userpremission?.includes("D") ? (
                    <DeleteIcon
                      sx={{ cursor: "pointer", opacity: 1 }}
                      titleAccess="Delete"
                      onClick={() => DeleteHandler()}
                    />
                  ) : (
                    <DeleteIcon sx={{ opacity: 0.3 }} titleAccess="Delete" />
                  )}
                </>
              )}
              <LaunchOutlinedIcon
                sx={{ cursor: "pointer" }}
                titleAccess="Go to Parameter-DataBase"
                onClick={() => parameterDatabaseRidirection()}
              />
              {rowItem?.lock === true ? (
                // Case 1: If user is SuperUser or ProjectAdmin, show clickable green lock
                (AUTH.isSuperUser || userProfilePic[0]?.is_project_admin) ? (
                  <LockIcon
                    sx={{ cursor: 'pointer', color: 'green' }}
                    onClick={() => lockparameterHandler(rowItem)}
                  />
                ) : (
                  // Case 2: If user is not SuperUser or ProjectAdmin, show disabled lock
                  <LockIcon sx={{ opacity: 0.5 }} />
                )
              ) : (
                // Case 3: If lock is false and user is SuperUser or ProjectAdmin, show red open lock
                (AUTH.isSuperUser || userProfilePic[0]?.is_project_admin) && (
                  <LockOpenIcon
                    sx={{ cursor: 'pointer', color: 'red' }}
                    onClick={() => lockparameterHandler(rowItem)}
                  />
                )
              )}
            </Box>
          </TableCell>
        </TableRow>
      )}  

      {calculatorModal.isOpen && (
        <BOMConfigCalculator
          isOpen={calculatorModal.isOpen}
          onCloseModal={calculatorModal.close}
          id={calculatorModal.propsId}
          inputData={inputData}
          setCounter={setCounter}
        />
      )}
      {calculatorTableModal.isOpen && (
        <BOMConfigTableCalculator
          isOpen={calculatorTableModal.isOpen}
          onCloseModal={calculatorTableModal.close}
          rowObj={calculatorTableModal.propsId}
          inputData={inputData}
          setCounter={setCounter}
        />
      )}
      {infoModal.isOpen &&
        <BomTemplateinfoModal

          onCloseModal={infoModal.close}
          isOpen={infoModal.isOpen}
          Item={infoModal.propsId}


        />
      }
      {/* {
        bomCostCalculator.isOpen && <BOMCreateCostCalculatorModal isOpen={bomCostCalculator.isOpen} onCloseModal={bomCostCalculator.close} id={bomCostCalculator.propsId} inputData={inputData} />
      } */}
    </>
  );
};

export default BomConfigurationsRowrender;
