import * as React from 'react';
import { usePdf } from "@mikecousins/react-pdf";

export interface InwardPdfViewerProps {
    documentData:any

    canvasRef:any;
    onDocumentLoadFail:any;
    onDocumentLoadSuccess:any
}

export default function InwardPdfViewer (props: InwardPdfViewerProps) {


    const {documentData,canvasRef,onDocumentLoadFail,onDocumentLoadSuccess}=props



    const { pdfDocument, pdfPage } = usePdf({
        file:
          documentData && documentData?.file !== "null"
            ? documentData?.file
            : "https://arxiv.org/pdf/quant-ph/0410100.pdf",
    
        canvasRef,
        onDocumentLoadFail,
        onDocumentLoadSuccess,
      });
  return (
    <div>
      
    </div>
  );
}
