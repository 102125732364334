import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { USER } from "../Services/user.service";


export interface GetProfilePic {
    id: any;
}

const getUserProfilePic = createAsyncThunk(
    "user/getProfilePic",
    async ( arg: GetProfilePic, { rejectWithValue } ) => {
        try {
            const { data } = await USER.getUsers( arg.id );
            return {
                permissionsOptions: data,
            };
        } catch ( error:any ) {
            return rejectWithValue( error );
        }
    }
);

const resetUsers = createAction( "user/reset" );

export { getUserProfilePic, resetUsers };