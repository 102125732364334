import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  DataGrid,
  GridColDef,
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExportContainer,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { Autocomplete, Divider, TextField, Tooltip } from "@mui/material";
import { API } from "../../../api-services";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import LoadingButton from "@mui/lab/LoadingButton";
import useWindowDimensions from "../../../ui-reusable-component/useWindowDimensions";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: '80vw',
  maxWidth: "90vw",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};
const csvOptions: GridCsvExportOptions = {
  fileName: "Table In CSV",
  delimiter: ",",
  utf8WithBom: true,
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer style={{ height: "2rem", marginBottom: "0.5rem", }}>
    <span style={{ position: "absolute", right: "0rem" }}>
      {/* <GridToolbarColumnsButton /> */}
      {/* <GridToolbarDensitySelector /> */}
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);
interface AddMachineListModalProps {
  onCloseModal: any;
  isOpen: any;
  setCounter: any;
  expanded: any;
  getSubRevisionData?:any;
}




const FetchMachineListModal = (props: AddMachineListModalProps) => {
  const { onCloseModal, isOpen, setCounter, expanded ,getSubRevisionData} = props;

  const { revisionId, typeId, databaseName, categoryName } = useRouteParams<any>();

  const [MachineList, setMachineList] = useState<any>([]);
  const [GetSelectedIds, setGetSelectedIds] = useState<any>([]);
  const [Loader, setLoader] = useState<any>(false)
  const { height, width } = useWindowDimensions();
  const [CommodityList, setCommodityList] = useState<any>([])
  const [DataTypeValues, setDataTypeValues] = useState<any>({});
  const [CounterLoader, setCounterLoader] = useState<any>(0);
  const [fetchloader, setFetchLoader] = useState<any>(false);
  const handleSelectdatatype = (item: any) => {
    setFetchLoader(true)
    setDataTypeValues(item);
    setMachineList([])
    API.get("/api/db/machine/", { commodity: item?.id, revision: revisionId,fetch:true })
      .then((res: any) => {
        setMachineList(res?.data);
        setFetchLoader(false)
      })
      .catch((err: any) => {
        setFetchLoader(false)
        console.log("Srver Error");
      });

  };




  useEffect(() => {
    setMachineList([])
    setFetchLoader(true)
    API.get("/api/db/commodity/", { machine_commodity: true }).then((res: any) => {

      var data = res?.data?.filter((item: any, index: any) => { return item?.name !== "UnCategorised" })
      setCommodityList(data)

      if (categoryName == 0) {
        var FilterData = res?.data?.filter((item: any, index: any) => { return item?.id == expanded })

        var filterdByname = FilterData?.filter((item: any, index: any) => { return item?.name !== "UnCategorised" })[0]


        if (filterdByname == undefined) {

          setDataTypeValues(res?.data[0])
          setFetchLoader(true)
          API.get("/api/db/machine/", { commodity: res?.data[0]?.id, revision: revisionId })
            .then((res: any) => {
              setMachineList(res?.data);
              setFetchLoader(false)
            })
            .catch((err: any) => {
              setFetchLoader(false)
              console.log("Srver Error");
            });

        } else {

          setDataTypeValues(filterdByname)
          setFetchLoader(true)
          API.get("/api/db/machine/", { commodity: filterdByname?.id, revision: revisionId })
            .then((res: any) => {
              setMachineList(res?.data);
              setFetchLoader(false)
            })
            .catch((err: any) => {
              setFetchLoader(false)
              console.log("Srver Error");
            });
        }



      } else {
        var FilterData = res?.data?.filter((item: any, index: any) => { return item?.id == categoryName })
        var filterdByname = FilterData?.filter((item: any, index: any) => { return item?.name !== "UnCategorised" })[0]

        if (filterdByname == undefined) {
          setDataTypeValues(res?.data[0])
          setFetchLoader(true)
          API.get("/api/db/machine/", { commodity: res?.data[0]?.id, revision: revisionId })
            .then((res: any) => {
              setMachineList(res?.data);
              setFetchLoader(false)
            })
            .catch((err: any) => {
              setFetchLoader(false)
              console.log("Srver Error");
            });


        } else {

          setDataTypeValues(filterdByname)
          setFetchLoader(true)
          API.get("/api/db/machine/", { commodity: filterdByname?.id, revision: revisionId })
            .then((res: any) => {
              setMachineList(res?.data);
              setFetchLoader(false)
            })
            .catch((err: any) => {
              setFetchLoader(false)
              console.log("Srver Error");
            });


        }

      }




    }).catch((err: any) => { console.log("Sever Error") })

  }, [CounterLoader])




  var Columns: any = [
    {
      field: "machine_code",
      headerName: "Machine Code",
      minWidth: 180,
      // flex:1,
    },

    {
      field: "name",
      headerName: "Machine Name",
      minWidth: 150,
      flex: 1,
    },
    {
      field: "operation",
      headerName: "Operation",
      minWidth: width > 1400 ? 200 : 160,
    },
    {
      field: "brand_name",
      headerName: "Barnd Name",
      minWidth: width > 1400 ? 200 : 160,
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 150,
      flex: 1,
    },
  ];

  const saveHandler = () => {
    setLoader(true)
    API.post("/api/db/machine_cost/", {
      commodity: typeId,

      machines: GetSelectedIds,

      revision: revisionId
    }).then((res: any) => {
      setGetSelectedIds([])
      getSubRevisionData()
      setCounterLoader((prev: any) => prev + 1)
      setCounter((prev: any) => prev + 1)
      setLoader(false)
    }).catch((err: any) => {
      setLoader(false)
    })
  }


  return (
    <Modal
      open={isOpen}
      onClose={onCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style}>
        <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
          <Typography
            style={{
              fontSize: "1rem",
              fontWeight: "600",
              width: "100%",
              textAlign: "center",
              marginRight: "-2rem",
            }}>
            Fetch Machine
          </Typography>
          <CancelIcon
            titleAccess="Close"
            sx={{ cursor: "pointer", margin: "0.5rem" }}
            onClick={onCloseModal}
          />


        </Box>
        <Divider
          sx={{
            borderColor: "primary.light",
            margin: "0 1rem",
            marginBottom: "1rem",
          }}
        />
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '91%', paddingLeft: '1rem', marginBottom: '-2rem' }}>
          <Autocomplete
            sx={{
              zIndex: 1,
              minWidth: '300px',
              '.MuiSvgIcon-root': { color: 'primary.main' },
              fontSize: '1rem !important',
            }}
            size="small"
            id="combo-box-demo"
            //   disabled={Modaltype == "Edit" || Modaltype == "tableEdit" ? true : false}
            value={DataTypeValues}
            getOptionLabel={(option) =>
              option?.name == undefined ? "" : option?.name
            }
            onChange={(event, newValue, situation) =>
              handleSelectdatatype(newValue)
            }
            options={CommodityList && CommodityList}
            renderOption={(props, option) => (
              <Box component="li" sx={{ fontSize: "1rem" }} {...props}>
                {option?.name}
              </Box>
            )}
            renderInput={(params) => (
              <TextField
                variant="standard"
                sx={{
                  fontSize: '1rem',
                  borderBottom: "none",
                  ".MuiInputBase-root.MuiInput-root": {
                    fontSize: '1rem !important'
                  },
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "primary.main",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "primary.main",
                  },
                }}
                {...params}
                placeholder="Data Type"
              />
            )}
          />
          <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
            <Typography sx={{ fontSize: '1rem' }}>Selected Item : {GetSelectedIds?.length}</Typography>
            {/* @ts-ignore */}

            <LoadingButton variant="contained" sx={{
              lineHeight: '1',
              padding: '0.5rem',
              zIndex: 1,
              "&:hover": {
                transform: 'Scale(1.05)',
                transition: 'transform 0.5s ease',
              },
              background: GetSelectedIds?.length > 0 ? "#d32f2f" : "", '.MuiCircularProgress-root': {
                color: 'primary.main',
              },
            }} disabled={GetSelectedIds?.length > 0 ? false : true} loading={Loader} onClick={() => saveHandler()}>
              Add
            </LoadingButton>
          </Box>
        </Box>
        <Box
          sx={{ height: "75vh", width: "100%", padding: '0 0.5rem' }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3">
          {DataTypeValues && Object.keys(DataTypeValues) && (
            <DataGrid
              loading={fetchloader}
              headerHeight={42}
              rowHeight={36}
              rows={MachineList && MachineList}
              columns={Columns}
              density="compact"
              // autoHeight={true}
              checkboxSelection
              components={{
                Toolbar: () => {
                  return CustomToolbar();
                },
              }}
              onSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids);
                setGetSelectedIds(ids);
              }}

              sx={{
                "& .MuiDataGrid-checkboxInput": {
                  color: "primary.main",

                },
                "& ::-webkit-scrollbar": {
                  width: "0.4rem",
                  height: "0.4rem",
                },
                "& ::-webkit-scrollbar-thumb": {
                  // background: $thumb-color;
                  backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                  borderRadius: "10px",
                },
                "& ::- webkit-scrollbar-thumb: hover ": {
                  // width: "4px",
                  backgroundColor: "#045DE9 !important",
                },
                "::-webkit-scrollbar-track": {
                  backgroundColor: "#e1e1f3 !important",
                },
                "&.MuiDataGrid-root": {
                  border: 'none',
                },
                "&.MuiDataGrid-root .MuiDataGrid-cell": {
                  borderBottomColor: "primary.light",
                },
              }}
            />
          )}
        </Box>
      </Box>
    </Modal>
  );
};

export default FetchMachineListModal;
