import React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { makeStyles } from '@mui/styles';
import { useRouteMatch } from "react-router-dom";
import {
  Button,
  Divider,
  FormLabel,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup"
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css'
import swal from "sweetalert";
import styles from './HandoverModal.module.scss'
import { API } from "../../api-services";
import { AUTH } from "../../Redux/Services/auth.service";
import { ADMIN } from "../../Redux/Services/admin.service";
import { useDispatch } from "react-redux";
import CancelIcon from '@mui/icons-material/Cancel';


const styled = {
  boxSize: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "39rem",
    height: "fit-content",
    minHeight: "17rem",
    borderRadius: "0.5rem",
    padding: '1rem',
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    p: 0,
    outline: 0
  }
}

// @ts-ignore

const useStyles = makeStyles((theme) => ({
  /** Changed modalStyle */
  ModalRoot:
  {
    backgroundColor: "rgba(111, 126, 140, 0.2) !important",
    backdropFilter: "blur(1px) !important",
  },
}));

const validationSchema = yup.object({
  first_name: yup.string().required("Please enter your First Name"),
  last_name: yup.string().required("Please enter your Last Name"),
  employee_id: yup.string().required("Please enter your Employee ID"),
  email: yup
    .string()
    .email("Please enter valid email")
    .required("Please enter your Email"),
  domain: yup.string().when(["email", "client"], {
    is: (email) => {
      const domain = email?.split("@")?.pop();
      return (
        domain == "gmail.com" ||
        domain == "yahoo.com" ||
        domain == "hotmail.com"
      );
    },
    then: yup
      .string()
      .required(
        "This domain is not allowed for user creation please contact ASI representative"
      ),
  }),
  phone: yup.string().required("Please enter your Phone Number")
});

export interface HandoverModalProps {
  isOpen: any;
  onCloseModal: any;

}

const HandoverModal = (props: HandoverModalProps) => {
  const { isOpen, onCloseModal } = props;
  const classes = useStyles();
  const { url } = useRouteMatch();
  const dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      employee_id: "",
      email: "",
      domain: "",
      phone: "",
      dialCode: {
        countryCode: "",
        dialCode: "",
        format: "",
        name: ""
      }
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let val: any = values;
      val.domain = val.email?.split("@")?.pop();
      val.phone = val.phone.substring(val.dialCode.dialCode.length);
      val['country_code'] = val.dialCode.dialCode;
      val["username"] = val.first_name + val.last_name;
      delete val.dialCode;
      swal({
        title: "Hand Over",
        text: "Are you sure you want to hand over ? \n Once you click on proceed , you will be logged out and you will no longer access the account. \n The person you handed over the account will recieve an email and can login using email or phone and permission will be same as yours.",
        buttons: ["No", "Proceed"],
      }).then(confirm => {
        if (confirm) {
          API.patch(
            `auth/user/${AUTH.user}/?type=handover`,
            val,
            0
          )
            .then((res: any) => {
              if (res.status == 200) {
                AUTH.logout(() =>
                  dispatch({ type: "RESET_ALL" })
                );
              }
              // ADMIN.toast.success(
              //   "User Account Update Successfully"
              // );
            })
            .catch((error: any) => {

            }
              // ADMIN.toast.throwError(
              //   error,
              //   "Server Error"
              // )
            );
        }
        else {
          formik.resetForm();
          onCloseModal();
        }
      });
    },
  });

  let handleOnClose = () => {
    formik.resetForm();
    onCloseModal();
  }

  return (
    <div>
      <Modal
        open={isOpen}
        className={classes.ModalRoot}
        onClose={handleOnClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styled.boxSize}>
          <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: '-2rem'
              }}
            >
              Handover
            </Typography>
            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Typography style={{ margin: '0rem', color: '#007fff', fontSize: '1rem', marginTop: '0.5rem', paddingLeft: '0.8rem', }}>* Please enter details of the user you want to handover your account to.</Typography>
          <form onSubmit={formik.handleSubmit}>
            <Grid container columnSpacing={2} sx={{ padding: 2 }}>
              <Grid item xs={6} sx={{ pb: 1 }}>
                <TextField
                  sx={{
                    width: "100%",
                    "& .MuiFormLabel-root": {
                      color: 'primary.main'
                    },
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: 'primary.main'
                    },
                    "& .Mui-error": {
                      color: '#d32f2f',
                      borderBottomColor: '#d32f2f',
                    },
                    '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                    '& .MuiInput-underline:after': { borderBottomColor: 'primary.light' },
                  }}
                  InputProps={{
                    style: { color: "#007fff", fontSize: "1rem", borderColor: 'primary.light' },
                  }}
                  InputLabelProps={{ style: { fontSize: "1rem" } }}

                  id="first_name"
                  name="first_name"
                  label="First Name *"
                  variant="standard"
                  value={formik.values.first_name}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.first_name && Boolean(formik.errors.first_name)
                  }
                  helperText={formik.touched.first_name && formik.errors.first_name}
                />
              </Grid>
              <Grid item xs={6} sx={{ pb: 1 }}>
                <TextField
                  sx={{
                    width: "100%",
                    "& .MuiFormLabel-root": {
                      color: 'primary.main'
                    },
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: 'primary.main'
                    },
                    "& .Mui-error": {
                      color: '#d32f2f',
                      borderBottomColor: '#d32f2f',
                    },
                    '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                    '& .MuiInput-underline:after': { borderBottomColor: 'primary.light' },
                  }}
                  InputProps={{
                    style: { color: "#007fff", fontSize: "1rem", borderColor: 'primary.light' },
                  }}
                  InputLabelProps={{ style: { fontSize: "1rem" } }}
                  id="last_name"
                  name="last_name"
                  label="Last Name *"
                  value={formik.values.last_name}
                  variant="standard"
                  onChange={formik.handleChange}
                  error={
                    formik.touched.last_name && Boolean(formik.errors.last_name)
                  }
                  helperText={formik.touched.last_name && formik.errors.last_name}
                />
              </Grid>
              <Grid item xs={12} md={12} lg={12} xl={12} columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 5, xl: 0 }} sx={{ pb: 1 }}>
              <div>
                    <TextField
                      fullWidth
                      InputProps={{
                        style: { color: "#007fff", fontSize: "1rem" },
                      }}
                      InputLabelProps={{ style: { fontSize: "1rem" } }}
                      sx={{
                        width: "100%",
                        "& .MuiFormLabel-root": {
                          color: "primary.main",
                        },
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "primary.main",
                        },
                        "& .Mui-focused": {
                          color: "primary.main",
                        },
                        "& .Mui-error": {
                          color: "#d32f2f",
                          borderBottomColor: "#d32f2f",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "primary.light",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "primary.light",
                        },
                      }}
                      id="employee_id"
                      name="employee_id"
                      label="Employee ID *"
                      variant="standard"
                      size="small"
                      value={formik.values.employee_id}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.employee_id &&
                        Boolean(formik.errors.employee_id)
                      }
                      helperText={
                        formik.touched.employee_id && formik.errors.employee_id
                      }
                    />
                  </div>
              </Grid>
              <Grid item xs={12} md={6} lg={6} xl={6} columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 5, xl: 0 }} sx={{ pb: 1 }}>
                <TextField
                  fullWidth
                  // sx={{ width: "95%", }}
                  sx={{
                    // width: "100%",
                    "& .MuiFormLabel-root": {
                      color: 'primary.main'
                    },
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: 'primary.main'
                    },
                    "& .Mui-error": {
                      color: '#d32f2f',
                      borderBottomColor: '#d32f2f',
                    },
                    '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                    '& .MuiInput-underline:after': { borderBottomColor: 'primary.light' },
                  }}
                  InputProps={{
                    style: { color: "#007fff", fontSize: "1rem", borderColor: 'primary.light' },
                  }}
                  InputLabelProps={{ style: { fontSize: "1rem" } }}
                  id="email"
                  name="email"
                  label="Email ID *"
                  variant="standard"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  error={formik.touched.email && Boolean(formik.errors.email)}
                  helperText={formik.touched.email && formik.errors.email}
                />
              </Grid>
              <Grid item xs={12} md={6} lg={6} xl={6} >
                <TextField
                  sx={{
                    width: "100%",
                    "& .MuiFormLabel-root": {
                      color: 'primary.light',
                    },
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: 'primary.main'
                    },
                    '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                    '& .MuiInput-underline:after': { borderBottomColor: 'primary.light' },
                  }}
                  InputProps={{
                    style: { color: "#007fff", fontSize: "1rem", borderColor: 'primary.light' },
                  }}
                  InputLabelProps={{ style: { fontSize: "1rem" } }}
                  id="domain"
                  name="domain"
                  label="Domain"
                  variant="standard"
                  value={formik.values.email?.split("@")?.pop()}
                  disabled
                  onChange={formik.handleChange(
                    formik.values.email?.split("@")?.pop()
                  )}
                  error={formik.touched.domain && Boolean(formik.errors.domain)}
                  helperText={formik.touched.domain && formik.errors.domain}
                />
              </Grid>
              <Grid item xs={12} lg={12} xl={12}>
                <div className={styles.phoneNum}>
                  <FormLabel sx={{ color: "#007fff", fontSize: '0.75rem', mt: "0.2rem", textAlign: "left" }} style={formik.touched.phone && Boolean(formik.errors.phone) ? { color: "#d32f2f" } : {}}>
                    Phone Number *
                  </FormLabel>
                  {/* @ts-ignore */}
                  <PhoneInput
                    //@ts-ignore
                    id="phone"
                    value={formik.values.phone}
                    name="phone"
                    className="phoneInput"
                    onChange={(value, data) => { return formik.setFieldValue("phone", value), formik.setFieldValue("dialCode", data) }}
                    country={"in"}
                  />
                  {formik.touched.phone && Boolean(formik.errors.phone) && (
                    <p style={{
                      fontSize: "0.75rem",
                      color: "#d32f2f",
                      marginTop: "0.4rem",
                    }}>{formik.errors.phone}</p>
                  )}
                </div>
              </Grid>
              <Box className={styles.handoverbtnaction}>
                <Box className={styles.actionbtn}>
                  <Button type="submit" className={styles.btn} size="small" variant="contained">
                    Proceed
                  </Button>
                </Box>                
              </Box>
            </Grid>
          </form>
        </Box>
      </Modal>
    </div>
  );
}
export default HandoverModal;
