import { API } from "../api-services";

const url = "/auth/generate_url/";

class s3 {
    getS3URI = ( relativePath: string | undefined, thumb = false ) => {
        return API.get( url, { rel: relativePath, thumb: thumb || undefined }, 0 );
    };
}

const fetchS3Url = async ( rel: string, thumb= false ) => {
    try {
        const { data } = await S3.getS3URI( rel, thumb );
        // console.log("originalData",await S3.getS3URI( rel, thumb ))
        return data;
    } catch ( e ) {
        return "";
    }
};
const fetchS3UrlOriginal = async ( rel: string, thumb = false ) => {
    try {
        const { data } = await S3.getS3URI( rel, undefined );
        // console.log("originalData",await S3.getS3URI( rel, thumb ))
        return data;
    } catch ( e ) {
        return "";
    }
};

const S3 = new s3();

export { S3, fetchS3Url,fetchS3UrlOriginal };
