import * as React from "react";
import Box from "@mui/material/Box";

import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import {
  Autocomplete,
  Checkbox,
  Chip,

  Divider,

  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import useFetch from "../../Core/CustomHooks/useFetch";
import reportsbank from "../../Redux/Services/reportsBank.service";
import { isUndefined, transform } from "lodash";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { API } from "../../api-services";
import { EastRounded, Preview } from "@mui/icons-material";
import LoadingButton from "@mui/lab/LoadingButton";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import Input from "@mui/material/Input";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Button from "@mui/material/Button";
import moment from "moment";
import CancelIcon from '@mui/icons-material/Cancel';
import { useState } from "react";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: '40%',
  bgcolor: "background.paper",
  borderRadius:"1rem",
  // border: "2px solid #000",
  //   boxShadow: 24,
  p: 2,
};
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export interface ShareReportsProps {
  Open: any;
  isClose: any;
  ProjectIdData: any;
  reprtsType: any;
  fileId: any;
  selectedUserNamesId: any;
  setCounters: any;
}

const ShareReportsModal = (props: ShareReportsProps) => {
  const {
    Open,
    isClose,
    ProjectIdData,
    reprtsType,
    fileId,
    selectedUserNamesId,
    setCounters,
  } = props;
  const [UserIds, setUserIds] = React.useState([]);
  const [UserNamesTitle, setUserNamesTitle] = React.useState(selectedUserNamesId && selectedUserNamesId?.title);
  const [selecteData, setSelectedData] = React.useState("Internal");
  const [errormsg, setErrormsg] = React.useState(false);
  const [loader, setLoader] = React.useState(false);
  const[msgLoader,setMsgLoader]=useState(true);
  const { 0: fetched, 1: UsersName } = useFetch({
    method: reportsbank.getAllUsersReportsName,
    args: [ProjectIdData, reprtsType, selecteData],
    fallBackErrMsg: "Could not fetch projects",
    dependencies: [ProjectIdData, reprtsType, fileId, selecteData],
    condition: !isUndefined(reprtsType),
  });

  //   @ts-ignore
  const userDatavalue: any = [];
  const valueUser =
    UsersName &&
    UsersName?.map((user: any, index: number) => {
      userDatavalue.push({ title: user?.full_name, value: user?.id });
    });


  const UsertypeHandleChange = (e: any) => {

    setSelectedData(e.target.value);
    // @ts-ignore
    setUserIds(selectedUserNamesId && selectedUserNamesId?.ids);

  };
  const handlechange = (data: any, Values: any) => {
    // fileId

    var DataFilter =
      Values &&
      Values?.map((item: any, index: number) => {
        return item.value;
      });


    setUserIds(DataFilter);
  };



  React.useEffect(() => {
    setSelectedData("Internal");
    setMsgLoader(true)
    // setUserIds([])
  }, [isClose]);


  // console.log(selectedUserNamesId && selectedUserNamesId?.ids,"selectedUserNamesId",UserIds)

  var currentDataExternal = selectedUserNamesId && selectedUserNamesId?.external;
  var InternalData = selectedUserNamesId && selectedUserNamesId?.internal;


  React.useEffect(()=>{


    // @ts-ignore
    selectedUserNamesId && setUserIds(selectedUserNamesId && selectedUserNamesId?.ids)
  },[selectedUserNamesId])

  const SaveHandler = () => {

    if (UserIds && UserIds?.length > 0) {

      setErrormsg(false);
      setLoader(true);
      var filterValue = selecteData == "Internal" ? [UserIds, currentDataExternal].flat(1) : [UserIds, InternalData].flat(1);
      //  var filterData = UserIds.length>0? [UserIds,currentData].flat(1):UserIds

      API.put("xcpep/report_bank/stats/", { auth_user: filterValue, id: fileId }, 0)
        .then((res: any) => {

          setMsgLoader(false)
          // setCounters((prev: any) => prev + 1);
          setLoader(false);
          // isClose();
        })
        .catch((err: any) => {
          setErrormsg(false);
          setLoader(false);
          // isClose();
        });
    } else {
      setErrormsg(true);
    }
  };

  const CloseHanlder=()=>{
    setCounters((prev: any) => prev + 1);
  
     isClose();
  }
  const OptionData = () => {
    var options =
      UsersName &&
      UsersName.map((item: any, ind: any) => {
        return {
          title: item?.full_name,
          value: item?.id,
        };
      });
    return options;
  };



  return (
    <div>
      <Modal
        open={Open}
     
      >
        <Box sx={style}>
        { msgLoader? <Box>
          <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: '-2rem'
              }}
            >
              select user to share
            </Typography>
            <CancelIcon titleAccess="Close" onClick={()=>CloseHanlder()} sx={{ cursor: 'pointer', margin: '0.5rem', marginRight: '-1rem' }} />
           
          </Box>
          <Divider
                sx={{
                  borderColor: "primary.light",
                  margin: "0 1rem",
                  marginBottom: "1rem",
                }}
              />
          <Typography id="modal-modal-title" style={{ fontSize: "12px" }}>
            User Type
          </Typography>
          <FormControl fullWidth variant="standard">
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selecteData}
              // label="Age"
              onChange={UsertypeHandleChange}
              sx={{
                '& .MuiSvgIcon-root': {
                  color: 'primary.main'
                },
              }}             
            >
              <MenuItem sx={{ fontSize: "1rem" }} value={"Internal"}>Internal</MenuItem>
              <MenuItem sx={{ fontSize: "1rem" }} value={"External"}>External</MenuItem>
            </Select>
          </FormControl>

          <Typography
            id="modal-modal-title"
            style={{ fontSize: "0.75rem", marginTop: "1rem" }}
          >
            Select User to Share
          </Typography>

          {OptionData() && (
            <Autocomplete
              multiple
              id="checkboxes-tags-demo"
              onChange={(data: any, value: any) => {
                handlechange(data, value);
              }}
              options={OptionData() && OptionData()}
              isOptionEqualToValue={(option: any, value: any) =>
                option.value === value.value
              }
              size="small"
              disableCloseOnSelect
              disableClearable
              sx={{
                "&.MuiAutocomplete-root .MuiInput-root.MuiInputBase-sizeSmall .MuiInput-input":
                {
                  fontSize: "1rem",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.light",
                },
                '& .MuiSvgIcon-root': {
                  color: 'primary.main'
                },
              }}
              // @ts-ignore
              getOptionLabel={(option) => option?.title}
              defaultValue={
                OptionData() &&
                OptionData()?.filter((item: any) => {
                  return selectedUserNamesId && selectedUserNamesId?.title.includes(item?.title);
                })
              }
              renderOption={(props, option, { selected }) => {
                return (
                  <li
                    style={{ padding: "0 0.5rem", fontSize: "1rem" }}
                    {...props}
                  >
                    <Checkbox
                      icon={icon}
                      checkedIcon={checkedIcon}
                      style={{ marginRight: 8 }}
                      checked={selected}
                    />
                    {/* @ts-ignore */}
                    {option?.title}
                  </li>
                );
              }}
              // style={{ width: '100%' }}
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  sx={{
                    "& .MuiFormLabel-root": {
                      color: "primary.main",
                      fontSize: "1rem",
                    },
                  }}
                  {...params}
                />
              )}
            />
          )}

          {errormsg ? <Typography id="modal-modal-title" style={{ fontSize: "0.75rem", color: "red" }}>*Please  select user to share </Typography> : ""}
          <Box
            sx={{
              display: "flex",
              columnGap: "10px",
              justifyContent: "flex-end",
              width: "100%",
              marginTop: "0.5rem",
            }}
          >
            {/* <Button
              sx={{
                "&:hover": {
                  transform: "scale(1.1)",
                  transition: "transform 0.5s ease",
                },
              }}
              size="small"
              variant="contained"
              onClick={() => SaveHandler()}
            > */}

            <LoadingButton
              loading={loader}
              loadingPosition="center"
              sx={{
                "&:hover": {
                  transform: "scale(1.1)",
                  transition: "transform 0.5s ease",
                },
              }}
              size="small"
              variant="contained"
              onClick={() => SaveHandler()}
            >
              Share
            </LoadingButton>
            {/* <Button
              sx={{
                "&:hover": {
                  transform: "scale(1.1)",
                  transition: "transform 0.5s ease",
                },
              }}
              size="small"
              variant="contained"
              onClick={() => isClose()}
            >
              Cancel
            </Button> */}
          </Box>
          </Box>
          :<Box
          
          
          >

<Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>

            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: '-2rem',
                color:"green"
              }}
            >
               Report shared successfully
            </Typography>
            <CancelIcon titleAccess="Close" onClick={()=>CloseHanlder() } sx={{ cursor: 'pointer', margin: '0.5rem', marginRight: '-1rem' }} />
           
          </Box>

           
          </Box>}
        </Box>
      </Modal>
    </div>
  );
};
export default ShareReportsModal;
