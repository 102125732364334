import { isArray } from "lodash";
import { ID } from "../../utlis/commonInterfaces";
import { CreateBlankRepoImageArg, CreateRepoImageArg, DeleteBlankRepoImageArg, DeleteRepoImageArg, GetRepoImagesArg, GetRepoImagesHeaderDataArg, MoveRepoImageArg, RotateRepoImageArg, UpdateRepoImageArg, UploadRepoImagesArg } from "../Actions/imageRepository.actions";
import { API } from "./index.service";

// -------------------------------------------------------------------------------------

export default {
    get ( arg: GetRepoImagesArg ) {
        return API.get( "image_repository/media_fetch/", {
            top_vault: arg.topVaultId,
            abbreviation: arg.subsystemAbbreviation,
            image_category: arg.imageCategory,
            media_type: arg.media_type,
            project:arg.project,
            row:arg.row,
            page:arg.page,
            group:arg.group,
        }, 0 );
    },

    getBaseImages ( topVaultId: ID, imageCategory: number, abbreviation: string | number ) {
        return API.get( "idea/fetch_component/", {
            top_vault: topVaultId,
            image_category: imageCategory,
            abbreviation: abbreviation,
            origin: 5
        },0 );
    },

    getCreate ( arg: GetRepoImagesArg ) {
        return API.get( "image_repository/fetchs/", {
            top_vault: arg.topVaultId,
            abbreviation: arg.subsystemAbbreviation,
            image_category: arg.imageCategory,
            media_type: arg.media_type,
            project:arg.project,
            row:arg.row,
            page:arg.page,
            group:arg.group,
        }, 0 );
    },

    get_infoImage ( arg: GetRepoImagesHeaderDataArg ) {
        return API.get( `image_repository/media_details/`, {
            top_vault: arg.topVaultId,
            abbreviation: arg.subsystemAbbreviation || undefined,
            image_category: arg.imageCategory,
            media_type: arg.media_type
        } );
    },
   
    download ( topVaultId: ID | ID[], imageCategory: number, abbreviation: string | number, media_type: string, range: any ) {
        return API.get( "image_repository/repo_download/", {
            top_vault: isArray( topVaultId ) ? topVaultId.join() : topVaultId,
            image_category: imageCategory,
            abbreviation: abbreviation || undefined,
            media_type: media_type,
            range: isArray( range ) ? range.join() : range
        }, 0 );
    },

    downloadbatch ( topVaultId: ID | ID[], imageCategory: number | undefined, abbreviation: string | number, media_type: string, range: any, GrpId: any ) {
        return API.get( "image_repository/repo_download/", {
            top_vault: isArray( topVaultId ) ? topVaultId.join() : topVaultId,
            image_category: imageCategory,
            abbreviation: abbreviation || undefined,
            media_type: media_type,
            range: isArray( range ) ? range.join() : range,
            group: GrpId
        }, 0 );
    },
    delete ( arg: DeleteRepoImageArg ) {
        return API.delete( `image_repository/update/${arg.id}/`, {
            reorder: arg.rearrange,
            // page_items: arg.page_items,
            // key: arg.key,
            media_type: arg.media_type
        } );
    },
    deleteBlankSpace ( arg: DeleteBlankRepoImageArg ) {
        return API.put( `image_repository/update/0/`, {
            top_vault: arg.vaultId ? undefined : arg.topVaultId,
            vault: arg.vaultId ? arg.vaultId : undefined,
            image_category: arg.category,
            sequence: arg.sequence,
            // page_items: arg.page_items,
            // key: arg.key,
            media_type: arg.media_type
        } );
    },
    moveRepoImage ( arg: MoveRepoImageArg ) {
        return API.post( `image_repository/fetchs/`, {
            new_sequence: arg.newSequence,
        }, {
            id: arg.id,
            media_type: arg.media_type,
            project: arg.project,
            abbreviation: arg.abbreviation,
        } );
    },

    rotate ( arg: RotateRepoImageArg ) {
        return API.put( `image_repository/update/${arg.id}/`, {
            rotation: arg.rotation,
            media_type: arg.mediaType
        },0 );
    },

    createBlankSpace ( arg: CreateBlankRepoImageArg ) {
        return API.post( `image_repository/fetchs/`, {
            reorder: arg.sequence,
        }, {
            top_vault: arg.vaultId ? undefined : arg.topVaultId,
            vault: arg.vaultId ? arg.vaultId : undefined,
            image_category: arg.category,
            media_type: arg.mediaType,
            group: arg.groupId
        } );
    },

    moveImageCard ( id: any, new_sequence: number, project: any, abbreviation: any ) {
         return API.put( "image_repository/update/" + id + '/', {
             new_sequence: new_sequence, project:project, abbreviation:abbreviation },0 ); 
    },

    create ( arg: CreateRepoImageArg ) {
        const { image, vault, sequence, media_type, rgb, is_mute, fetch, ...rest } = arg;
        if ( arg.media_type === "video" ) {
            return API.uploadPost( "image_repository/fetchs/", { videoData: arg.image }, {
                ...rest,
                vault: vault || undefined,
                sequence: sequence,
                media_type: media_type,
                rgb: rgb,
                is_mute:is_mute
            } );
        } else {
            const data = new FormData();
            data.append( "image", arg.image );

            return API.uploadPost( "image_repository/fetchs/", data, {
                ...rest,
                vault: vault || undefined,
                sequence: sequence,
                media_type: media_type,
                rgb: rgb,
                fetch: fetch
            } );
        }
    },
    
    update ( arg: UpdateRepoImageArg ) {
        let data;
        let method: "put" | "uploadPut" = "put";

        if ( arg.media_type === "video" ) {
            if ( arg.image ) {
                return API[method]( `image_repository/update/${arg.id}/`, { videoData: arg.image, media_type: arg.media_type }, { rgb: arg.rgb, group: arg.group, is_mute:arg?.is_mute } );
            } else if ( arg.sequence ) {
                data = { sequence: arg.sequence };
                return API[method]( `image_repository/update/${arg.id}/`, data, { rgb: arg.rgb, group: arg.group,is_mute:arg?.is_mute } );
            }

        } else {
            if ( arg.image ) {
                data = new FormData();
                data.append( "image", arg.image );
                data.append( "media_type", arg.media_type );
                method = "uploadPut";
            } else if ( arg.sequence ) {
                data = { sequence: arg.sequence };
            }

            return API[method]( `image_repository/update/${arg.id}/`, data, { rgb: arg.rgb, group: arg.group } );
        }
    },

    upload ( arg: UploadRepoImagesArg ) {
        let data = new FormData();
        if ( arg.media_type === "video" ) {

            return API.uploadPost( "image_repository/fetchs/", { videoData: arg.images }, {
                top_vault: arg.vaultId ? undefined : arg.topVaultId,
                vault: arg.vaultId ? arg.vaultId : undefined,
                image_category: arg.imageCategory,
                rgb: arg.rgb,
                media_type: arg.media_type,
                group: arg.group,
                is_mute: arg.is_mute
            } );
        } else {
            Array.from( arg.images ).forEach( ( image: any, index: any ) => {
                data.append( `image[${index}]`, image );
            } );
            return API.uploadPost( "image_repository/fetchs/", data, {
                top_vault: arg.vaultId ? undefined : arg.topVaultId,
                vault: arg.vaultId ? arg.vaultId : undefined,
                image_category: arg.imageCategory,
                rgb: arg.rgb,
                media_type: arg.media_type,
                group: arg.group
            } );
        }


    },

};
