import * as React from "react";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  Drawer,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import styles from "./IdeaGenerationViewLanding.module.scss";
import AddIcon from "@mui/icons-material/Add";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import { AxiosError, AxiosResponse } from "axios";
import InputLabel from "@mui/material/InputLabel";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import IdeaViewpage from "./IdeaViewpage";
import EditIcon from "@mui/icons-material/Edit";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import promote from "../../../Assets/images/Promote.svg";
import { API } from "../../../api-services";
import { CreateIdeaDrawer } from "./CreateIdeaDrawer";
import { set } from "lodash";
import { useBaseModal } from "../../SearchPage/useBaseModal";
import IdeaSummaryModal from "../IdeaSummaryModal";
import Swal from "sweetalert2";
import swal from "sweetalert";
import { ADMIN } from "../../../Redux/Services/admin.service";
import IdeaSubmittedmodal from "./IdeaSubmittedmodal";
import { DialogComponent } from "../../../utlis/DialogComponent";
import InfoIcon from "@mui/icons-material/Info";
import IdeaInfoModal from "./IdeaInfoModal";
import IdeaPromoteFormModal from "../IdeaAutoGenerate/IdeaPromoteFrom/IdeaPromoteFormModal";
import { useSelector } from "react-redux";
import { userSelectors } from "../../../Redux/Reducers/user.reducer";
import RefreshIcon from "@mui/icons-material/Refresh";
import SettingsBackupRestoreIcon from "@mui/icons-material/SettingsBackupRestore";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ClearIcon from '@mui/icons-material/Clear';
import { useDocumentTitle } from "../../../ui-reusable-component/useDocumentTitle";


import IdeaEditFormModal from '../IdeaAutoGenerate/IdeaEditForm/IdeaEditFormModal';
export const UserAction = React.createContext(null)
export interface IIdeaGenerationViewLandingProps { }

interface Params {
  projectId: any;
  projectName: any;
  topVault: any;
  subSystem: any;
}

type Anchor = "top" | "left" | "bottom" | "right";

export default function IdeaGenerationViewLanding(
  props: IIdeaGenerationViewLandingProps
) {
  const history = useHistory();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  useDocumentTitle("Idea");

  const [projectList, setProjectList] = useState<any>();
  const [productList, setProductList] = useState<any>();
  const [counter, setCounter] = React.useState<any>(0)
  const [selectedProject, setSelectedProject] = useState<any>();
  const openDialogModal = useBaseModal();
  const [selectedProduct, setSelectedProduct] = useState<any>();
  const [workflowState, setWorkflowState] = useState<any>(true);
  const [ideaDetails, setIdeaDetails] = useState<any>(true);
  const [contentText, setContentText] = React.useState<any>(
    "Report download is running in background"
  );
  const [iconText, setIconText] = React.useState<any>("success");
  const { vault_id } = useRouteParams<any>();
  const summaryModal = useBaseModal();
  const [ideaAllDetails, setIdeaAllDetails] = React.useState<any>([]);
  const [vehicleList, setVehicleList] = useState<any>();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const Submittedmodal = useBaseModal();

  const {
    projectId,
    projectName,
    topVault,
    subSystem,
    subSystemId,
    expandTable,
    abbr,
    partId,
    depth,
  } = useParams<any>();
  const [selectedVehicle, setSelectedVehicle] = useState<any>(topVault);
  const [editData, setEditData] = useState<any>([]);
  const [allIdeaData, setAllIdeaData] = useState<any>([]);
  const [hierarchyDataLoading, setHierarchyDataLoading] =
    React.useState<any>(false);
  const [hierarchyData, setHierarchyData] = React.useState<any>({});
  const [StatusCounter, setStatusCounter] = useState<any>(0);
  const [openBox, setOpenBox] = React.useState<any>(false);
  const userProfilePic = useSelector(userSelectors.selectAll);

  // console.log("userProfilePic",userProfilePic && userProfilePic[0]?.is_superuser)
  const [SystemNameList, setSystemNameList] = useState<any>([]);
  const [updateIdeaList, setUpdateIdeaList] = React.useState<any>(false);
  const [promoteLoader, setPromoteLoader] = React.useState<any>(false);
  const [rejectLoader, setRejectLoader] = React.useState<any>(false);
  const [PermissionAction,SetPermissionAction]=useState<any>()
  const handleMenuClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const editIdeaModal = useBaseModal();

  const InfoModal = useBaseModal();
  const InfoModalHandler = () => {
    InfoModal.open();
  };

  const getALLIdeaData = () => {

      API.get(`/idea/pool/`, {
        vault: subSystem != "PRODUCT" ? partId : undefined,
      top_vault: topVault,
      module: 15,
      page: 1,
      search_id: "",
      })
        .then((res: any) => {
          setAllIdeaData(res?.data?.results);
         
        })
        .catch((err: any) => {
          console.log(err, "error");
        });
      
   
    
  };

  React.useEffect(() => {
    getALLIdeaData();
  }, [vault_id]);


  React.useEffect(()=>{
    API.get(`/auth/calibration_user_permission/`,{action:true,idea_create:true,top_vault:topVault}).then((res:any)=>{
      SetPermissionAction(res.data)
    }).catch((err:any)=>{
      console.log("Server Error")
    })
  },[topVault])






 
  const getIdeaDetailsData = () => {
    setIdeaAllDetails([])
    API.get(
      "/idea/pool/",
      {
        module: 15,
        page: 1,
        table: true,
        single_idea: vault_id,
      },
      0
    )
      .then((res: any) => {
        // console.log(res?.data, "res?.data");
        setIdeaAllDetails(res?.data);
      })
      .catch((err: any) => {
        if (err.response.data) {
          const { data } = err.response;
          Swal.fire({
            title: "Error!",
            text: data[0],
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      });
  };

  React.useEffect(() => {
    if (subSystem != "PRODUCT") {
      API.get(`/xcpep/vault_info/`, { vault: partId })
        .then((res: any) => {
          setSystemNameList(res.data);
        })
        .catch((err: any) => {
          console.log("Server Error");
        });
    }
  }, [partId]);

  React.useEffect(() => {
    if (vault_id != 0) {
      getIdeaDetailsData();
    }
  }, [vault_id, StatusCounter]);
  React.useEffect(() => {
    API.get(`/xcpep/project_list_new/`, { module: "Idea-Create" }, 0)
      .then((res: AxiosResponse) => {
        setProjectList(res?.data);
      })
      .catch((err: AxiosError) => {
        console.log(err, "error");
      });
  }, []);

  const getAllVehicleList = () => {
    API.get(
      "/xcpep/top_vault/",
      {
        project: projectId,
        module: 15,
      },
      0
    )
      .then((res: AxiosResponse) => {
        setVehicleList(
          res?.data.filter((item: any) => item?.idea_base === true)
        );

        setSelectedVehicle(topVault);
      })
      .catch((err: AxiosError) => {
        console.log(err, "error");
      });
  };
  React.useEffect(() => {
    getAllVehicleList();
  }, [projectId]);

  const handleInReview = (id: any, type: any) => {
    if (ideaAllDetails[0]?.rejected == false) {
      if (type == "submitted") {
        swal({
          title: `Are you sure?`,
          text: `you want to change the status of Idea?`,
          icon: "warning",
          buttons: ["Cancel", "Yes"],
        }).then((confirm: any) => {
          if (confirm) {
            setPromoteLoader(true);
            API.put(
              `idea/pool/${vault_id}/`,
              {
                current_stage: ideaAllDetails && ideaAllDetails[0]?.stage,
                stage: id?.key,
                client_owner: id?.user,
                url: window.location.href,
              },
              {},
              0
            )
              .then((res: any) => {
                setPromoteLoader(false);
                setStatusCounter((prev: any) => prev + 1);
                ADMIN.toast.info("Updated Successfully");
              })
              .catch((err: any) => {
                setPromoteLoader(false)
                console.log(err);
                const { data } = err.response;
                Swal.fire({
                  title: "Error!",
                  html: data[0],
                  icon: "error",
                  confirmButtonText: "Ok",
                });
              });
            handleClose();
          }
        });
      } else {
        swal({
          title: `Are you sure?`,
          text: `you want to change the status of Idea?`,
          icon: "warning",
          buttons: ["Cancel", "Yes"],
        }).then((confirm: any) => {
          if (confirm) {
            
            if(id==0 && ideaAllDetails && ideaAllDetails[0]?.stage==1){
              
            setRejectLoader(true);
            API.put(
              `idea/pool/${vault_id}/`,
              {
                current_stage: ideaAllDetails && ideaAllDetails[0]?.stage,
                stage: id==3?2:id,
                url: window.location.href,
              },
              {},0
            )
              .then((res: any) => {
                setRejectLoader(false);
                setStatusCounter((prev: any) => prev + 1);
                ADMIN.toast.info("Updated Successfully");
              })
              .catch((err: any) => {
                console.log(err);

                const { data } = err.response;
                Swal.fire({
                  title: "Error!",
                  html: data[0],
                  icon: "error",
                  confirmButtonText: "Ok",
                });
              });
            }
            else{
              setPromoteLoader(true);
            API.put(
              `idea/pool/${vault_id}/`,
              {
                current_stage: ideaAllDetails && ideaAllDetails[0]?.stage,
                stage: id==3?2:id,
                url: window.location.href,
              },
              {},0
            )
              .then((res: any) => {
                setPromoteLoader(false);
                setStatusCounter((prev: any) => prev + 1);
                ADMIN.toast.info("Updated Successfully");
              })
              .catch((err: any) => {
                console.log(err);

                const { data } = err.response;
                Swal.fire({
                  title: "Error!",
                  html: data[0],
                  icon: "error",
                  confirmButtonText: "Ok",
                });
              });
            }

            handleClose();
          }
        });
      }
    }
     else if (ideaAllDetails[0]?.rejected == true) {
      if (type == "submitted") {
        swal({
          title: `Are you sure?`,
          text: `you want to change the status of Idea?`,
          icon: "warning",
          buttons: ["Cancel", "Yes"],
        }).then((confirm: any) => {
          if (confirm) {
            API.put(
              `idea/pool/${vault_id}/`,
              {
                rejected: false,
              },
              {},
              0
            )
              .then((res: any) => {
                setStatusCounter((prev: any) => prev + 1);
                ADMIN.toast.info("Updated Successfully");
              })
              .catch((err: any) => {
                console.log(err);
                const { data } = err.response;
                Swal.fire({
                  title: "Error!",
                  html: data[0],
                  icon: "error",
                  confirmButtonText: "Ok",
                });
              });
            handleClose();
          }
        });
      } else {
        swal({
          title: `Are you sure?`,
          text: `you want to change the status of Idea?`,
          icon: "warning",
          buttons: ["Cancel", "Yes"],
        }).then((confirm: any) => {
          if (confirm) {
            API.put(
              `idea/pool/${vault_id}/`,
              {
                rejected: false,
              },
              {},
              0
            )
              .then((res: any) => {
                setStatusCounter((prev: any) => prev + 1);
                ADMIN.toast.info("Updated Successfully");
              })
              .catch((err: any) => {
                console.log(err);

                const { data } = err.response;
                Swal.fire({
                  title: "Error!",
                  html: data[0],
                  icon: "error",
                  confirmButtonText: "Ok",
                });
              });

            handleClose();
          }
        });
      }
    }
  };

  const handleReject = () => {
    swal({
      title: `Reject Idea`,
      text: `Are you sure you want to Reject Idea ?`,
      icon: "error",
      buttons: ["Cancel", "Yes, Reject Idea"],
    }).then((confirm: any) => {
      if (confirm) {

        API.put(
          `idea/pool/${vault_id}/`,
          {
            current_stage: ideaAllDetails && ideaAllDetails[0]?.stage,
            rejected: true,
          },
          {},0
        )
          .then((res: any) => {
            ADMIN.toast.info("Updated Successfully");
            setStatusCounter((prev: any) => prev + 1);
          })
          .catch((err: any) => {
            console.log(err);

            const { data } = err.response;
            Swal.fire({
              title: "Error!",
              html: data[0],
              icon: "error",
              confirmButtonText: "Ok",
            });
          });

        handleClose();
      }
    });
  };

  const handleItemClick = (id: any) => {
    if (id === 4) {
      handleReject();
    } else if (id == 2) {
      Submittedmodal.open();
    } else {
      handleInReview(id, "");
    }
  };

  const selectAllProject = (projectIds: any) => {
    setSelectedProject([projectIds]);
    API.get(
      `/xcpep/product_list_new/`,
      { project: `${projectIds}`, module: "Idea-Create" },
      0
    )
      .then((res: AxiosResponse) => {
        setProductList(res?.data);
      })
      .catch((err: AxiosError) => {
        console.log(err, "error");
      });
  };

  const selectAllProduct = (productIds: any) => {
    setSelectedProduct([productIds]);

    history.push(
      `/idea-generation/${projectId}/${projectName}/${productIds}/0`
    );
  };

  const getselectedProjectBackgroundColor = (idd: any) => {
    if (selectedProject?.includes(idd)) {
      return "primary.main";
    } else {
      return "primary.light";
    }
  };

  const getSelectedProjectTextColor = (idd: any) => {
    if (selectedProject?.includes(idd)) {
      return "primary.light";
    } else {
      return "primary.main";
    }
  };

  const getselectedProductBackgroundColor = (idd: any) => {
    if (selectedProduct?.includes(idd)) {
      return "#2b9cf6eb";
    } else {
      return "primary.light";
    }
  };

  const getSelectedPrductTextColor = (idd: any) => {
    if (selectedProduct?.includes(idd)) {
      return "primary.light";
    } else {
      return "primary.main";
    }
  };

  const ToggleQuery = () => {
    if (subSystem == "PRODUCT") {
      return { top_vault: topVault };
    } else {
      return { system_id: subSystemId };
    }
  };
  const getHierarchyData = () => {
    setHierarchyDataLoading(true);
    API.get(
      `/idea/idea_hierachy/`,
      // system_id=66442,
      {
        ...ToggleQuery(),
      },
      0
    )
      .then((res: any) => {
        setHierarchyData(res?.data?.data);
        setHierarchyDataLoading(false);
      })
      .catch((err: any) => {
        setHierarchyDataLoading(false);
      });
  };
  
  React.useEffect(() => {
    if (openBox === true) {
      getHierarchyData();
    }
  }, [openBox, StatusCounter]);

  const getUpdatedIdeaList = () => {
    setUpdateIdeaList((prev: any) => !prev);
  };

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }
        setState({ ...state, [anchor]: open });
        setOpenBox(open);
      };

  const list = (anchor: Anchor) => (
    <Box
      sx={{
        minWidth: 300,
        width: anchor === "top" || anchor === "bottom" ? "auto" : "auto",
      }}
      role="presentation"
    >
      <Box sx={{ width: "100%", padding: "0" }}>
        <Box
          sx={{ width: "100%", padding: "0 1rem" }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3"
        >
          <Box sx={{ marginTop: "1rem" }}>
          <UserAction.Provider value={PermissionAction}>
            <CreateIdeaDrawer
              hierarchyData={hierarchyData}
              getUpdatedIdeaList={getUpdatedIdeaList}
              hierarchyDataLoading={hierarchyDataLoading}
            />
            </UserAction.Provider>
          </Box>
        </Box>       
      </Box>
    </Box>
  );

  const handleClick = () => {
    history.push(
      `/idea-generation/${projectId}/${projectName}/${topVault}/0/0/0`
    );
  };

  const handleExcelReport = async (ideaIdComing: any) => {
    setIconText("firstdownload");
    setContentText(
      "We've got this report downloading faster than a cheetah running. You will also receive a notification.But in the meantime, keep using xcPEP like the productivity champion !"
    );
    openDialogModal.open();
    try {
      const { data: url } = await API.get(
        "/customer/individual_report/",
        {
          idea_id: ideaIdComing,
          individual_type: "Idea",
        },
        0
      );
      window.open(url, "_blank", "noopener noreferrer");
    } catch (e) { }
  };

  const redirectToProject: any = () => {
    // history.push();
  };

  const [vehicle, setVehicle] = React.useState("10");

  const handleChange = (event: SelectChangeEvent) => {
    setSelectedVehicle(event.target.value);

    history.push(
      `/idea-generation-view/${projectId}/${projectName}/${event.target.value}/PRODUCT/${event.target.value}/0/0/${event.target.value}/0/0/0/0`
    );
  };

  const workflowHandler = () => {
    setWorkflowState((current: any) => !current);
  };

  const IdeaDetailsHandler = () => {
    setIdeaDetails((current: any) => !current);
  };

  const ideaCloseHandler = () => {
    setIdeaDetails(false); // Setting ideaDetails to false when the close icon is clicked
  };

  const EditHandler=()=>{
    editIdeaModal.open()
    setEditData(ideaAllDetails[0]);
  
  }

  let vehicleName = vehicleList && vehicleList?.filter((item: any, index: any) => { return item?.id == topVault })[0]

  const checkhandler=()=>{
    document.getElementById(vault_id)?.scrollIntoView({
          block: "nearest",
          inline: "start",
          behavior: "smooth",
        });


  }

  return (
    <div>
      <Box sx={{ padding: "0 0.5rem", display: "flex", alignItems: "center" }}>
        <DialogComponent
          isOpen={openDialogModal.isOpen}
          onClose={openDialogModal.close}
          content={contentText}
          type={iconText}
        />
        {Submittedmodal.isOpen && (
          <IdeaSubmittedmodal
            onCloseModal={Submittedmodal.close}
            isOpen={Submittedmodal.isOpen}
            Item={ideaAllDetails}
            handleInReview={handleInReview}
          />
        )}

        {InfoModal.isOpen && (
          <IdeaInfoModal
            onCloseModal={InfoModal.close}
            isOpen={InfoModal.isOpen}
            setCounter={setCounter}
          />
        )}

        <Box className={styles.titleSection}>
          <Box className={styles.titleSectionLeft}>
            <IconButton sx={{ cursor: "pointer" }} onClick={handleClick}>
              <ArrowBackIcon color="primary" titleAccess="Go Back" />
            </IconButton>
            <Typography sx={{ fontSize: "1rem", whiteSpace: "nowrap", marginLeft:'-0.7rem',display:'flex',alignItems:'center',columnGap:'0.5rem' }}>
              Idea Generation ({projectName} {`>`} <span title={vehicleName?.name} className={ideaAllDetails && ideaAllDetails[0]?.stage == 1 && ideaAllDetails[0]?.rejected == false ? styles.vehiclename : ''}>{vehicleName?.name}</span>{" "}
              {SystemNameList && SystemNameList?.length > 0 && `${`>`}`}{" "}
              {SystemNameList &&
                SystemNameList?.length > 0 &&
                <span title={SystemNameList[0]?.name} className={ideaAllDetails && ideaAllDetails[0]?.stage == 1 && ideaAllDetails[0]?.rejected == false ? styles.vehiclename :''}> {SystemNameList[0]?.name}</span>}{" "}
              )
            </Typography>
          </Box>
          {vault_id != 0 &&
          <Box className={styles.IdeaIdField} >  <Typography  sx={{textDecoration:"underline",cursor:"pointer",fontSize:"1rem",whiteSpace:'nowrap'}} onClick={()=>checkhandler()}> <span title="Locate Idea in Hierarchy">{ideaAllDetails && ideaAllDetails[0]?.idea_id}</span></Typography></Box>
}
        
          {vault_id != 0 && (
            <Box className={styles.titleSectionRight}>
              {PermissionAction && PermissionAction?.action?.includes("U") &&
              <EditIcon sx={{cursor:"pointer", display: ideaAllDetails?.[0]?.stage == 0 || ideaAllDetails?.[0]?.stage == 1 ? "block": "none"}}  onClick={() => EditHandler() } />}

              <InfoIcon
                titleAccess="Idea Info"
                sx={{ cursor: "pointer" }}
                onClick={InfoModalHandler}
              />

              <CloudDownloadIcon
                sx={{ fontSize: "2rem", cursor: "pointer" }}
                onClick={() => handleExcelReport(vault_id)}
              />


              {
              (ideaAllDetails && ideaAllDetails[0]?.stage == 0 && ideaAllDetails[0]?.rejected == false) ?
                <>
                  <Tooltip title="Promote Idea to (In Review) Stage">{promoteLoader ? <CircularProgress size={20} /> : <Box sx={{
                    cursor:  PermissionAction && PermissionAction?.action?.includes("U")?'pointer':"default", border: '1px solid', borderRadius: '3rem', lineHeight: 1,
                    padding: '0.4rem 1rem', backgroundColor: "#ffe699", color: "#007fff", borderColor: "#ffe699", display: "flex",
                    whiteSpace:'nowrap',
                    justifyContent: "center",
                    alignItems:"center",columnGap:"0.5rem",
                    "&:hover": {
                      transform: 'Scale(1.05)',
                      transition: 'transform 0.5s ease',
                     },
                  }} onClick={() => PermissionAction && PermissionAction?.action?.includes("U") && handleItemClick(1)} ><ArrowUpwardIcon sx={{
                    fontSize: "1.3rem !important",
                    /* line-height: 1; */
                    marginTop: " -0.2rem"
                  }} /></Box>}</Tooltip>                  
                </> : (ideaAllDetails && ideaAllDetails[0]?.stage == 0 && ideaAllDetails[0]?.rejected == true) ?
                  <>
                    <Box sx={{
                      border: '1px solid', borderRadius: '3rem', lineHeight: 1,
                      padding: '0.4rem 1rem', backgroundColor: "#ffffff", color: "#007fff", borderColor: "#007fff",
                      "&:hover": {
                        transform: 'Scale(1.05)',
                        transition: 'transform 0.5s ease',
                       }
                    }}
                    // onClick={() => handleItemClick(0)}

                    >Initiated</Box>
                  </>
                  : (ideaAllDetails && ideaAllDetails[0]?.stage == 1 && ideaAllDetails[0]?.rejected == false) ?

                    <>
                      <Tooltip title="Promote Idea to (Submit) Stage">{promoteLoader ? <CircularProgress size={20} /> : <Box sx={{
                        cursor: PermissionAction && PermissionAction?.action?.includes("U")?'pointer':"default", border: '1px solid', borderRadius: '3rem', lineHeight: 1,
                        padding: '0.4rem 1rem', backgroundColor: "#bbdbf7", color: "#007fff", borderColor: "#bbdbf7",
                        display: "flex",                        
                        justifyContent: "center",
                        alignItems:"center",columnGap:"0.5rem",
                        "&:hover": {
                          transform: 'Scale(1.05)',
                          transition: 'transform 0.5s ease',
                         }
                      }} onClick={() => PermissionAction && PermissionAction?.action?.includes("U") && handleItemClick(2)}><ArrowUpwardIcon  sx={{
                        fontSize: "1.3rem !important",
                        /* line-height: 1; */
                        marginTop: " -0.2rem"
                      }} /></Box>}</Tooltip>

                      <Tooltip title="Demote Idea to (Initiated) Stage">{<Box sx={{
                        cursor: PermissionAction && PermissionAction?.action?.includes("U")? 'pointer':"default", border: '1px solid', borderRadius: '3rem', lineHeight: 1,
                        padding: '0.4rem 1rem', backgroundColor: "#ffffff", color: "#007fff", borderColor: "#007fff",
                        display: "flex",                        
                        justifyContent: "center",
                        alignItems:"center",columnGap:"0.5rem",
                        "&:hover": {
                          transform: 'Scale(1.05)',
                          transition: 'transform 0.5s ease',
                         }
                      }}
                        onClick={() => PermissionAction && PermissionAction?.action?.includes("U") && handleItemClick(0)}><ArrowDownwardIcon  sx={{
                          fontSize: "1.3rem !important",
                          /* line-height: 1; */
                          marginTop: " -0.2rem"
                        }} /></Box>}</Tooltip>
                      <Box sx={{
                        cursor:  PermissionAction && PermissionAction?.action?.includes("U")?'pointer':"default", border: '1px solid', borderRadius: '3rem', lineHeight: 1,
                        padding: '0.4rem 1rem', backgroundColor: "#fbe5d6", color: "#007fff", borderColor: "#fbe5d6",
                        display: "flex",                        
                        justifyContent: "center",                        
                        alignItems:"center",columnGap:"0.5rem",
                        "&:hover": {
                          transform: 'Scale(1.05)',
                          transition: 'transform 0.5s ease',
                         }
                      }} onClick={() => PermissionAction && PermissionAction?.action?.includes("U") && handleItemClick(4)}><span>Reject</span> <ClearIcon sx={{
                        fontSize: "1.3rem !important",
                        /* line-height: 1; */
                        marginTop: " -0.2rem"
                      }} /></Box>

                    </>

                    : ((ideaAllDetails && ideaAllDetails[0]?.stage == 1 && ideaAllDetails[0]?.rejected == true) && (userProfilePic && userProfilePic[0]?.is_superuser == true || userProfilePic && userProfilePic[0]?.is_project_admin)) ?

                      <Tooltip title="Demote Idea to (In Review) Stage">{ <Box sx={{
                        cursor: PermissionAction && PermissionAction?.action?.includes("U")?'pointer':"default", border: '1px solid', borderRadius: '3rem', lineHeight: 1,
                        padding: {lg:'0.4rem 1rem',xl:'0.16rem 1rem'}, backgroundColor: "#ffe699", color: "#007fff", borderColor: "#ffe699",
                        display: "flex", 
                        whiteSpace:'nowrap',
                        justifyContent: "center",                       
                        alignItems:"center",columnGap:"0.5rem",
                        "&:hover": {
                          transform: 'Scale(1.05)',
                          transition: 'transform 0.5s ease',
                         }
                      }} onClick={() => PermissionAction && PermissionAction?.action?.includes("U") && handleItemClick(1)}><ArrowDownwardIcon  fontSize="small" /></Box>}</Tooltip>

                      : (ideaAllDetails && ideaAllDetails[0]?.stage == 2 && ideaAllDetails[0]?.rejected == false) ?

                        <Tooltip title="Demote Idea to (In Review) Stage">{<Box sx={{
                          cursor: PermissionAction && PermissionAction?.action?.includes("U")?'pointer':"default", border: '1px solid', borderRadius: '3rem', lineHeight: 1,
                          padding: '0.25rem 1rem',  backgroundColor: "#ffe699", color: "#007fff", borderColor: "#ffe699",
                          display: "flex",  
                          whiteSpace:'nowrap',
                        justifyContent: "center",                      
                        alignItems:"center",columnGap:"0.5rem",
                        "&:hover": {
                          transform: 'Scale(1.05)',
                          transition: 'transform 0.5s ease',
                         }
                        }} onClick={() => PermissionAction && PermissionAction?.action?.includes("U") && handleItemClick(1)} ><ArrowDownwardIcon fontSize="small" /></Box>}</Tooltip>
                        : (userProfilePic && userProfilePic[0]?.is_superuser == true && ideaAllDetails && ideaAllDetails[0]?.stage == 2 && ideaAllDetails[0]?.rejected == true) ?

                          <SettingsBackupRestoreIcon titleAccess="Reset Idea Status" sx={{ cursor: "pointer" }} onClick={() => handleItemClick(1)} /> :(userProfilePic && userProfilePic[0]?.is_superuser == true && ideaAllDetails && ideaAllDetails[0]?.stage == 3) ?
                          
                          <SettingsBackupRestoreIcon titleAccess="Reset Idea Status" sx={{ cursor: "pointer" }} onClick={() => handleItemClick(3)} /> 
                          
                          
                          : ""
                          }



              <Box
                sx={{
                  cursor: "pointer",
                  border: "1px solid",
                  borderRadius: "3rem",
                  lineHeight: 1,
                  padding: "0.4rem 1rem",
                  backgroundColor: workflowState == false ? "primary.main" : "",
                  color: workflowState == false ? "white" : "",
                  borderColor: workflowState == false ? "primary.main" : "",
                  "&:hover": {
                    transform: 'Scale(1.05)',
                    transition: 'transform 0.5s ease',
                   }
                }}
                title={
                  workflowState == true ? "Hide Workflow" : "Show Workflow"
                }
                onClick={workflowHandler}
              >
                Workflow
              </Box>
              <Box
                sx={{
                  cursor: "pointer",
                  border: "1px solid",
                  borderRadius: "3rem",
                  lineHeight: 1,
                  padding: "0.4rem 1rem",
                  backgroundColor: ideaDetails == false ? "primary.main" : "",
                  color: ideaDetails == false ? "white" : "",
                  borderColor: ideaDetails == false ? "primary.main" : "",
                  "&:hover": {
                    transform: 'Scale(1.05)',
                    transition: 'transform 0.5s ease',
                   }
                }}
                title={
                  ideaDetails == true
                    ? "Hide Idea Details"
                    : "Show Idea Details"
                }
                onClick={IdeaDetailsHandler}
              >
                Details
              </Box>
              <Box
                sx={{
                  cursor: "pointer",
                  border: "1px solid",
                  borderRadius: "3rem",
                  lineHeight: 1,
                  padding: "0.4rem 1rem",
                  backgroundColor: "primary.main",
                  color: "white",
                  borderColor: "primary.main",
                  "&:hover": {
                    transform: 'Scale(1.05)',
                    transition: 'transform 0.5s ease',
                   }
                }}
                onClick={() => summaryModal.open()}
              >
                Summary
              </Box>
              {vehicleList && vehicleList.length > 1 ? (
                <FormControl variant="standard" sx={{ borderRadius: '3rem', }}>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedVehicle}
                    size="small"
                    onChange={handleChange}
                    sx={{
                      minWidth: "15rem",
                      "&:before": {
                        borderBottomColor: "transparent",
                        borderRadius: "5px",
                      },
                      "&:after": {
                        borderBottomColor: "transparent",
                        borderRadius: "5px",
                      },
                      "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                      {
                        fontSize: "1rem",
                        borderRadius: "5px",
                        backgroundColor: "primary.main",
                        padding: {
                          lg: "0.4rem 1rem",
                          xl: "0.25rem 1rem",
                        },
                        color: "white",
                      },
                      ".MuiSvgIcon-root.MuiSelect-icon": { color: "white" },
                      overflow: "hidden",
                    }}
                  >
                    {vehicleList &&
                      vehicleList?.map((item: any, index: any) => {
                        return (
                          <MenuItem
                            sx={{ fontSize: "1rem" }}
                            key={index}
                            value={item?.id}
                          >
                            {item?.name}
                          </MenuItem>
                        );
                      })}
                  </Select>
                </FormControl>
              ) : (
                <Box
                  sx={{
                    border: "1px solid #007fff",
                    borderRadius: '3rem',
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      whiteSpace: "nowrap",
                      borderRadius: "5px",
                      color: "primary.main",
                      padding: "0.2rem 1rem",
                    }}
                  >
                    {vehicleList?.[0]?.name}
                  </Typography>
                </Box>
              )}
            </Box>
          )}
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "flex-start", width: "100%" }}>
        <Box className={styles.expandIconBox}>
          <div className={styles.iconSec}>
            {(["left"] as const).map((anchor) => (
              <React.Fragment key={anchor}>
                <IconButton
                  size="medium"
                  sx={{
                    backgroundColor: "primary.light",
                    color: "primary.main",
                  }}
                  onClick={toggleDrawer(anchor, true)}
                >
                  <AddIcon sx={{ fontSize: "1.7rem" }} />
                </IconButton>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  className={styles.drawerContainer}
                  PaperProps={{
                    sx: {
                      backgroundColor: "aliceblue",
                    },
                  }}
                >
                  <Box
                    sx={{ padding: "0.5rem 0", position: "sticky", top: "0" }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3"
                  >
                    <Typography
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "1rem",
                      }}
                    >
                      Select Part
                    </Typography>
                    <Divider sx={{ margin: "0.2rem" }} />
                    {list(anchor)}
                  </Box>
                </Drawer>
                <Typography
                  sx={{ fontSize: "1rem", color: "primary.main" }}
                  onClick={toggleDrawer(anchor, true)}
                  className={styles.sideTitle}
                >
                  Selection Options
                </Typography>
              </React.Fragment>
            ))}
          </div>
        </Box>
        <Box
          sx={{
            width: { xs: "80vw", sm: "96%", md: "96%", lg: "96%", xl: "97%" },
          }}
        >
          <UserAction.Provider value={PermissionAction}>
          
          <IdeaViewpage
            StatusCounter={StatusCounter}
            setStatusCounter={setStatusCounter}
            workflow={workflowState}
            ideaDetails={ideaDetails}
            ideaAllDetails={ideaAllDetails && ideaAllDetails}
            setWorkflowState={setWorkflowState}
            setIdeaDetails={setIdeaDetails}
            updateIdeaList={updateIdeaList}
            counter={counter}
          />
          </UserAction.Provider>
        </Box>
      </Box>
      {summaryModal.isOpen && <IdeaSummaryModal isOpen={summaryModal.isOpen} onCloseModal={summaryModal.close} />}
      {editData && editIdeaModal?.isOpen && <IdeaEditFormModal isOpen={editIdeaModal.isOpen} onCloseModal={editIdeaModal.close} getIdeaPromotedDetails={editData && editData} getIdeaDetailsData={getIdeaDetailsData} />}

      <Box>
        <Button></Button>
      </Box>
    </div>
  );
}
