
// -----------------------------------------------------------------------------------
// entity adaptor

import { EntityState, createEntityAdapter, createReducer,createSelector } from "@reduxjs/toolkit";
import { RootState } from "../Store/storeConfigurations";
import { getGanttChartData } from "../Actions/ganttChart.action";
import { ID } from "../../utlis/commonInterfaces";

export interface GanttChart {
    status: any;
    end_by: any;
    start_by: any;
    end_date: any;
    start_date: any;
    name: any;
    module: any;
    id: ID;
    notes: any;

}

const adaptor = createEntityAdapter<GanttChart>({
    // selectId: ( ganttCharts ) => ganttCharts.id,
    // sortComparer: ( a, b ) => 
});

// -----------------------------------------------------------------------------------
// interfaces & initialState

type AdditionalFields = {};

type IGanttChartReducer = EntityState<GanttChart> & AdditionalFields;

const additionalFields: AdditionalFields = {};

const initialState: IGanttChartReducer = Object.assign({}, adaptor.getInitialState(), additionalFields);

// -----------------------------------------------------------------------------------
// reducer

const GanttChartReducer = createReducer(initialState, builder => {

    builder
        .addCase(getGanttChartData.fulfilled, (state, action: any) => {
            adaptor.setAll(state, action.payload.ganttChart);
        })
        .addCase(getGanttChartData.rejected, (_state, action) => {
            // console.log(action.payload, "Could no fetch Data");
        });

    // builder.
    //     addCase(resetGanttChart, () => initialState);
});

// -----------------------------------------------------------------------------------
// selectors

const selectors = adaptor.getSelectors<RootState>((state) => state.ganttChart);



const selectALLDates = createSelector(selectors.selectAll, ganttChart => {
    const dates:any = [];
    ganttChart.forEach((ganttChart) => {
        dates.push(ganttChart.start_date);
        dates.push(ganttChart.end_date);
        dates.push(ganttChart.start_by);
        dates.push(ganttChart.end_by);
    });

   const filterdates = dates.filter((date:any) => date !== null);
 
    return filterdates;
})


const ganttChartsSelectors = Object.assign({}, { selectALLDates }, selectors);

// -----------------------------------------------------------------------------------
// exports

export {
    GanttChartReducer,
    initialState as GanttChartReducerInit,
    ganttChartsSelectors
};