import React, { useEffect, useState } from "react";
// @ts-ignore
import ImageEditor from "@toast-ui/react-image-editor";
import "tui-image-editor/dist/tui-image-editor.css";
import "tui-color-picker/dist/tui-color-picker.css";
import "./CustomImageEditor.scss";



interface CustomImageEditorProps {
    imageURI: string | null | undefined;
    imageEditor: any;
    imageUrl: any;
}

//--------------------------------------------------------------------------

const CustomImageEditor: React.FC<CustomImageEditorProps> = ( props ) => {

    const { imageEditor, imageURI, imageUrl } = props;



   
    const [s3URl, setS3URl] = useState<string | null>( null );

    //fetching the image url:
    // const getURL = ( relativePath: string | any ) =>
        // fetchS3Url( relativePath ).then( ( d ) => setS3URl( d ) );

    useEffect( () => {

        
         imageUrl && 
         
         
         setS3URl( imageUrl );
    }, [imageUrl] );

    
    // console.log(s3URl,)

    return (
        <>
            { s3URl &&
            
            
            <ImageEditor
            includeUI={ {
                    loadImage: {
                        path: s3URl,
                        name: "EditedImage",
                    },
                    menu: [
                        "shape",
                        "filter",
                        "text",
                        "mask",
                        "icon",
                        "draw",
                        "crop",
                        "flip",
                        "rotate",
                        "resize",
                    ],
                   
                    uiSize: {
                        width: "100%",
                        height: "73vh",
                    },
                    menuBarPosition: "left",
                } }
                cssMaxHeight={ 500 }
                cssMaxWidth={ 1000 }
                selectionStyle={ {
                    cornerSize: 20,
                    rotatingPointOffset: 70,
                } }
                usageStatistics={ true }
                ref={ imageEditor }
            /> }
        </>
    );
};

export default CustomImageEditor;
