import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CancelIcon from '@mui/icons-material/Cancel';

import { Autocomplete, Divider, InputLabel, TextField, Tooltip } from '@mui/material';
import { DataGrid, GridColDef, GridCsvExportMenuItem, GridCsvExportOptions, GridToolbar, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExportContainer, GridValueGetterParams } from "@mui/x-data-grid";
import useWindowDimensions from '../../ui-reusable-component/useWindowDimensions';
import { API } from '../../api-services';
import { useRouteParams } from '../../ui-reusable-component/useRouteParams';
interface InfoModalProps {


  onCloseModal: any;
  isOpen: any;
  Item: any;

}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "45vw",
  height: 'fit-content',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  p: 1,
};


const AssumptionRouteModal = (props: InfoModalProps) => {

  const { height, width } = useWindowDimensions();
  const [loader, setLoader] = React.useState<any>(false);
  const {
    tab,
    year,
    month,
    revisionId,
    typeId,
    databaseName,
    status,
    search,
    categoryName,
  } = useRouteParams<any>();
  const { onCloseModal,
    isOpen,
    Item } = props
  const [optionsAssembly, setOptionsAssembly] = React.useState<any>([]);
  const [selectedAssembly, setSelectedAssembly] = React.useState<any>([]);
  const [projectId, setProjectId] = useState<any>({})
  const [ProductId, setProductId] = useState<any>({})
  const [ProjectList, setProjectList] = useState<any>([])
  const [ProducttList, setProducttList] = useState<any>([])

  const handleSelectChangProject = (event: any, newSystemParaValue: any) => {
    setProjectId(newSystemParaValue)
    // setProducttList()

    API.get(`xcpep/product_list_new/`, { project: newSystemParaValue?.id, module: 'Costing' }).then((res: any) => {
      setProducttList(res?.data)
    }).catch((err: any) => {
      console.log("Server Error")
    })

  };

  const handleSelectChangProduct = (event: any, newSystemParaValue: any) => {
    setProductId(newSystemParaValue)
  };

  const AssumptionRoute = () => {
    window.open(
      `/#/costingconfiguration/${projectId?.id}/${projectId?.name}/assumptionCard/${ProductId?.id}/${tab}/${Item?.revision_main}/${Item.scenario}`
    );
  }


  useEffect(() => {
    API.get(`/xcpep/project/`, { module: 3 }).then((res: any) => {
      setProjectList(res.data)
    })
  }, [])

  // console.log(ProjectList, "vdvdvdvvbhvhfvbjh")
  return (
    <div>


      <Modal
        open={isOpen}
        onClose={onCloseModal}
      >
        <Box sx={style}>
          <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: '-2rem'
              }}
            >
              Assumption Info
            </Typography>
            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />

          <Box sx={{ display: "flex", flexDirection: "row", columnGap: '1rem', padding: '0 1rem' }}>
            <Box sx={{ width: '100%' }}>
              <InputLabel shrink sx={{ color: 'primary.main' }}>Select Project</InputLabel>
              <Autocomplete

                size="small"
                loading={loader}
                disableClearable

                id="size-small-standard"
                options={ProjectList}

                ListboxProps={{
                  style: { fontSize: '1rem' }
                }}
                sx={{
                  '.MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator': {
                    color: 'primary.main'
                  },
                  '.MuiButtonBase-root.MuiChip-root': {
                    backgroundColor: 'primary.light',
                  },
                  width: "100%"
                }}
                getOptionLabel={(option) =>

                  option?.name == undefined ? "" :
                    option?.name
                }

                //  defaultValue={[top100Films[13]]}            
                onChange={(event: any, newSystemParaValue: any) => {
                  handleSelectChangProject(event, newSystemParaValue);
                }}
                value={projectId}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    placeholder="Select Project"
                    sx={{
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: '1rem !important'
                      },
                      '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                      '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                    }}
                  />
                )}
              />
            </Box>
            <Box sx={{ width: '100%' }}>
              <InputLabel shrink sx={{ color: 'primary.main' }}>Select Product</InputLabel>
              <Autocomplete

                size="small"
                loading={loader}
                disableClearable

                id="size-small-standard"
                options={ProducttList}

                ListboxProps={{
                  style: { fontSize: '1rem' }
                }}
                sx={{
                  '.MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator': {
                    color: 'primary.main'
                  },
                  '.MuiButtonBase-root.MuiChip-root': {
                    backgroundColor: 'primary.light',
                  },
                  width: "100%"
                }}
                getOptionLabel={(option) =>

                  option?.name == undefined ? "" :
                    option?.name
                }

                //  defaultValue={[top100Films[13]]}            
                onChange={(event: any, newSystemParaValue: any) => {
                  handleSelectChangProduct(event, newSystemParaValue);
                }}
                value={ProductId}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    placeholder="Select Product"
                    sx={{
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: '1rem !important'
                      },
                      '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                      '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                    }}
                  />
                )}
              />
            </Box>
          </Box>
          <Box sx={{ display: "flex", alignItems: 'center', justifyContent: "end", padding: '1rem' }}>
            {Object.keys(ProductId)?.length > 0 &&
              <Button variant='contained' size="small" onClick={() => AssumptionRoute()}>Go to Assumptions</Button>}
          </Box>
        </Box>

      </Modal>
    </div>
  )
}

export default AssumptionRouteModal;