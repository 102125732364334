
import React, { useEffect, useRef, useState } from 'react';
import scanner from '../../../Assets/images/scanner.png';
import { Autocomplete, Box, Dialog, DialogContent, Divider, IconButton, Slide, TextField, Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CancelIcon from '@mui/icons-material/Cancel';
import { TransitionProps } from '@mui/material/transitions';
import { QrReader } from "react-qr-reader";
import { AxiosError, AxiosResponse } from 'axios';
import Swal from 'sweetalert2';
import { API } from '../../../api-services';
import { useRouteParams } from '../../../ui-reusable-component/useRouteParams';
import styles from './BOMEntryQRScannerPage.module.scss';
import useWindowDimensions from '../../../ui-reusable-component/useWindowDimensions';



const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});


interface Params {
  projectId: any;
  projectName: any;
  topVault: any;
}

interface IBOMEntryQRScannerPageProps { }

const BOMEntryQRScannerPage: React.FC<IBOMEntryQRScannerPageProps> = (props) => {
  const { projectId, projectName ,topVault} = useRouteParams<Params>();
  const [openQRScanner, setOpenQRScanner] = React.useState(false);
  const [searchVaultsOptions, setSearchVaultsOptions] = useState<any>([]);
  const [searchVaultValue, setSearchVaultValue] = useState<any>();
  const timeout = useRef<any>(null);
  const { height, width } = useWindowDimensions();
  const history = useHistory();

  const handleQRScannerClose = () => {
    setOpenQRScanner(false);
  }

  const handleScan = async (vaultId: any) => {
    if (!vaultId) return;
    API.get(`/xcpep/vault/search/`, { top_vault: topVault, type: 'costing', project: projectId, cost_exist: true }, 0)
      .then((res: AxiosResponse) => {
        sessionStorage.setItem("CostingCreateCalc", JSON.stringify(res?.data?.[0]?.ancestors));
        setOpenQRScanner(false);
        history.push(`/createcostingpart/${projectId}/${projectName}/${res?.data?.[0]?.top_vault_name}/${res?.data?.[0]?.top_vault}/${res?.data?.[0]?.id}/${res?.data?.[0]?.abbreviation == null ? "none" : res?.data?.[0]?.abbreviation}/0/0/0/0/0/0/0/0/0/true`);
        
      }).catch((error: AxiosError) => {
        setOpenQRScanner(false);
        const { data }: any = error?.response;
        Swal.fire({
          icon: "error",
          html: `<div>
                <br />
                <p style="color:"red">${data[0]}</p>   
                 </div>`,
                 customClass: {
                  container: 'swal2Container'
                }
        });
      });;
  };

  const handleOnChange = (event: any) => {
    if (event.length < 1) {
      setSearchVaultsOptions([]);
    }
    if (event.length > 5) {
      clearInterval(timeout.current);
      timeout.current = setTimeout(() => {
        const vault = API.get(`/xcpep/vault/search/`, { search: event, top_vault:topVault }, 0)
          .then((res) => {
              setSearchVaultsOptions(res.data);
          }).catch((err: AxiosError) => {
            setSearchVaultsOptions([]);
            const { data }: any = err?.response;
            Swal.fire({
              icon: "error",
              html: `<div>
                      <br />
                      <p style="color:"red">${data[0]}</p>   
                    </div>`,
            });
          })
      }, 1000)
    }
  }


  const handleClickPart = (event: any) => { 
    if(event!=null){
    API.get(`/xcpep/vault_descendants/`, { vault:event?.id }, 0)
          .then((res) => {
            history.push(`/createcostingpart/${projectId}/${projectName}/${event?.name}/${event?.top_vault}/${event?.id}/${event?.abbreviation == null ? "none" : event?.abbreviation}/0/0/0/0/0/0/0/0/0/true`);
          }).catch((err: AxiosError) => {
            setSearchVaultsOptions([]);
            const { data }: any = err?.response;
            Swal.fire({
              icon: "error",
              html: `<div>
                      <br />
                      <p style="color:"red">${data[0]}</p>   
                    </div>`,
            });
          })
        }
  }

  const videoStyle = {
    width: "100%",
    height: "100%",
  };


  return (
    <>
      {openQRScanner && <Dialog
        open={openQRScanner}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        sx={width > 650? {
            ".MuiPaper-root.MuiDialog-paper": {
              minWidth: "40vw",
            },
          } : {
            ".MuiPaper-root.MuiDialog-paper": {
              maxWidth: {xs:'90vw',lg:"70vw"},
            },
          }}
      >
        <DialogContent sx={{ padding: "0rem", minWidth: {xs:'23rem',lg:'44rem'}}}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              QR Scanner
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={handleQRScannerClose}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
            //   marginBottom: "1rem",
            }}
          />
          <Box
            sx={{
              padding: "1rem",
              width: '100%',
              overflow: "hidden",
            }}
          >
            <QrReader
              scanDelay={300}
              constraints={{ facingMode: "environment" }}
              onResult={handleScan}
              className={styles.videoStyle}
                videoContainerStyle={{ width: "100%" }}
                containerStyle={{ width: "100%" }}
            />
          </Box>
        </DialogContent>
      </Dialog>}
      <Box sx={{ padding: "0 0.5rem" }}>
        <IconButton sx={{ cursor: "pointer", }} onClick={() => history.goBack()}>
          <ArrowBackIcon
            color="primary"
            titleAccess="Go Back"
          />
        </IconButton>
        <Typography sx={{ fontSize: '1rem', whiteSpace: 'nowrap', display: 'inline-block', marginLeft: '1rem' }}>BOM Search ( {projectName} )</Typography>
      </Box>     
      <Box className={styles.qrScannerdiv}>
        <div className={styles.qrScannerleft}>
          <img
            src={scanner}
            draggable="false"
            className={styles.qrScannerimg}
            title="Scan Barcode"
            onClick={() => setOpenQRScanner(true)}
          />
          <Typography className={styles.titletext}>
            Allow camera access for QR Scanning
          </Typography>
        </div>
        <Divider
          orientation="vertical"
          flexItem
          sx={{ borderColor: "primary.light" }}
        />
        <div className={styles.qrScannerright}>
          <Typography className={styles.titletext}>
            Search Part Number
          </Typography>
          <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={searchVaultsOptions}
            // @ts-ignore
            getOptionLabel={(option) => option.part_no}
            onChange={(event, newValue) => {
              handleClickPart(newValue);
            }}
            sx={{

              '.MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator': {
                color: 'primary.main'
              },
              '.MuiButtonBase-root.MuiChip-root': {
                backgroundColor: 'primary.light',
              },
            }}
            onInputChange={(event, newInputValue) => {
              handleOnChange(newInputValue);
            }}

            renderInput={(params) => <TextField
              sx={{
                width: '300px',
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: '1rem !important'
                },
                '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
              }}
              {...params} variant="standard" />}
          />
        </div>
      </Box>
    </>
  )
}

export default BOMEntryQRScannerPage;