import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  Slide,
  Typography,
  Menu,
  MenuItem
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import styles from "./BOMCreateLandingPage.module.scss";
import { useHistory } from "react-router-dom";
import CompressIcon from "@mui/icons-material/Compress";
import ExpandIcon from "@mui/icons-material/Expand";
import InsightsIcon from "@mui/icons-material/Insights";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
// import { AxiosError, AxiosResponse } from 'axios';
// import { API } from '../../../api-services';
import { AxiosError, AxiosResponse } from "axios";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { BOMCreateDrawer } from "./BOMCreateDrawer";
import { API } from "../../api-services";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import BOMCreateSystemTable from "./BOMCreateSystemTable";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@mui/material";

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { TransitionProps } from "@mui/material/transitions";
import { LoadingButton } from "@mui/lab";
import { useDocumentTitle } from "../../ui-reusable-component/useDocumentTitle";
import { ReverseUploadModal } from "../CostingCreateModule/ReverseUploadModal";
import { useBaseModal } from "../SearchPage/useBaseModal";
import { set } from "lodash";
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Anchor = "top" | "left" | "bottom" | "right";

interface Params {
  projectId: any;
  projectName: any;
  expandTable: any;
  initialTopVault: any;
  topVaultIds: any;
}

interface IBOMCreateLandingPageProps {}

const BOMCreateLandingPage: React.FC<IBOMCreateLandingPageProps> = (props) => {
  useDocumentTitle( "BOM Create" );
  const { projectId, projectName, initialTopVault, topVaultIds, expandTable } =
    useRouteParams<Params>();
  const [compactViewInfo, setCompactViewInfo] = React.useState<any>({});
  const [bomStatusData, setBomStatusData] = React.useState<any>();
  const [bomStatusLoader, setBomStatusLoader] = React.useState<any>(true);
  const [openBOMStatus, setOpenBOMStatus] = React.useState(false);
  const [anchorElHierarchy, setAnchorElHierarchy] = React.useState<null | HTMLElement>(null);
  const [topVaultSelected, setTopVaultSelected] = React.useState<any>();
  const [productListLoader, setProductListLoader] = React.useState<any>(true);
  const [productList, setProductList] = React.useState<any>([]);
  const openHierarchy = Boolean(anchorElHierarchy);
  const reverseuploadmodal = useBaseModal();
  const handleClickHierarchy = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElHierarchy(event.currentTarget);
  }
  const handleCloseHierarchy = () => {
    setAnchorElHierarchy(null);
  }

  React.useEffect(() => {

  API.get(
    `/xcpep/bom_total_count/`,
    { top_vault: topVaultIds, project: projectId },
    0
  )
    .then((res: AxiosResponse) => {
      console.log(res.data, "res?.data")
      setProductList(res?.data);
      setProductListLoader(false);
    })
    .catch((err: AxiosError) => {
      console.log(err, "error");
      setProductListLoader(false);
    });
  }, [projectName])



  const ReverseModalHandler = (selectItem: any) => {
    setTopVaultSelected(selectItem);
    reverseuploadmodal.open();
  }


  const handleBOMStatusClose = () => {
    setOpenBOMStatus(false);
  };

  const handleBOMStatusOpen = () => {
    setOpenBOMStatus(true);
    setBomStatusLoader(true);
    API.get(`/xcpep/bom_project_status/`, { project: projectId }, 0)
      .then((res: AxiosResponse) => {
        setBomStatusData(res.data);
        setBomStatusLoader(false);
      })
      .catch((err: AxiosError) => {
        console.log(err, "error");
      });
  };

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const history = useHistory();

  useEffect(() => {
    setBomStatusLoader(true);
    API.get(`/cost/costing_create_ui/`, { get_bom_project: projectId }, 0)
      .then((res: AxiosResponse) => {
        setCompactViewInfo(res.data);
      })
      .catch((err: AxiosError) => {
        console.log(err, "error");
      });

    
  }, [projectId]);

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <BOMCreateDrawer anchorComing={anchor} toggleDrawer={toggleDrawer} />
  );

  const handleClickBack = () => {
    history.push(`/internallanding`);
  };

  const handleClickExpand = () => {
    expandTable == "true"
      ? history.push(
          `/BOMCreate/${projectId}/${projectName}/${initialTopVault}/${compactViewInfo?.top_vault_list?.toString()}/${
            expandTable == "true" ? "false" : "true"
          }`
        )
      : history.push(
          `/BOMCreate/${projectId}/${projectName}/${initialTopVault}/${compactViewInfo?.top_vault_list
            ?.slice(0, 4)
            ?.toString()}/${expandTable == "true" ? "false" : "true"}`
        );
  };

  const qrScannerHandler = () => {
    history.push(`/bomscanner/${projectId}/${projectName}`);
  };

  const redirectToBOMInnerPage = (internalData:any,dataInternalFilter:any) =>{
    sessionStorage.setItem(
      "BOMCreateNode",
      JSON.stringify([dataInternalFilter?.top_vault_id,dataInternalFilter?.id])
    );
    window.open(
      `/#/bomentry/${projectId}/${projectName}/${dataInternalFilter?.top_vault_id}/${dataInternalFilter?.id}/${internalData?.abbreviation}/0/false/${false}`
    )
}

  return (
    <>
    {
      reverseuploadmodal.isOpen && <ReverseUploadModal isOpen={reverseuploadmodal.isOpen} onCloseModal={reverseuploadmodal.close}  topVaultSelected={topVaultSelected}/>
    }
      <Dialog
        open={openBOMStatus}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        sx={{
          ".MuiPaper-root.MuiDialog-paper": {
            maxWidth: "93vw",
            borderRadius: "10px",
            maxHeight:'94vh'
          },
        }}
      >
        <DialogContent sx={{ padding: "0rem", width: "93vw", }}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Validation Status
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={handleBOMStatusClose}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              // marginBottom: "1rem",
            }}
          />
          {bomStatusLoader ? (<Box sx={{padding:'0 2rem'}}>
                    <Skeleton></Skeleton>
                    <Skeleton></Skeleton>
                    <Skeleton></Skeleton>
                    <Skeleton></Skeleton>
                    <Skeleton></Skeleton>
                    <Skeleton></Skeleton>
                    <Skeleton></Skeleton>
                    <Skeleton></Skeleton>
                    <Skeleton></Skeleton>
                    <Skeleton></Skeleton>
                    <Skeleton></Skeleton>
                    <Skeleton></Skeleton>
                  </Box>) : <>
          <Box sx={{Width: "100%" }}>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: `repeat(${
                  bomStatusData && bomStatusData[0]?.data_by_vehicle?.length
                }, 1fr)`,
                width: "100%",
                maxHeight: {xs:'84vh',sm:'84vh',md:'84vh', lg: "85vh", xl: "90vh" },
                padding: "0.5rem",
              }}
              adl-scrollbar="true"
              adl-scrollbar-width="0.3"
            >
              {bomStatusData &&
                bomStatusData[0]?.data_by_vehicle?.map(
                  (item: any, index: any) => {
                    return (
                      <Table className={styles.bomstatustable} sx={{borderRight:'1px solid',borderColor:'primary.light'}}>
                        <TableHead>
                          <TableRow>
                            {index == 0 && (
                              <TableCell
                                rowSpan={2}
                                sx={{
                                  padding: "0.2rem 0.5rem",
                                  borderBottomColor: "primary.light",
                                  backgroundColor: "primary.light",
                                }}
                              >
                                Subsystem
                              </TableCell>
                            )}
                            <TableCell
                              colSpan={2}
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                                backgroundColor: "primary.light",
                                textAlign: "center",
                              }}
                            >
                              {item?.top_vault_name}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                                whiteSpace: "nowrap",
                                backgroundColor: "primary.light",
                                textAlign: "center",
                              }}
                            >
                              Total Parts
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                                whiteSpace: "nowrap",
                                backgroundColor: "primary.light",
                                textAlign: "center",
                              }}
                            >
                              Validated Parts
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                           {
                            bomStatusData && bomStatusData?.map((itemInside:any)=>{
                            const data = itemInside?.data_by_vehicle?.filter((data:any)=>data?.top_vault_name == item?.top_vault_name);
                              return(
                                <TableRow>
                                  {index == 0 && <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', textAlign: 'left', fontWeight:'500',whiteSpace:'nowrap', }} title={data?.name}>
                                    {itemInside?.name}
                                  </TableCell>}
                                  {data[0]?.id != undefined && <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', textAlign: 'center' }}>
                                    {data[0]?.total_parts}
                                  </TableCell>}
                                  {<TableCell colSpan={data[0]?.id == undefined ? 2 : 1} onClick={()=>data[0]?.id != undefined && data[0]?.total_parts != data[0]?.validated && redirectToBOMInnerPage(itemInside,data[0]) } sx={data[0]?.id != undefined ? data[0]?.total_parts == data[0]?.validated ? { padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', textAlign: 'center', color:'green' }:{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', cursor: 'pointer', textAlign: 'center', color:'red', textDecoration:'underline' }:{padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', textAlign: 'center' }}>
                                    {data[0]?.id == undefined ? 'Not Available' : data[0]?.validated}
                                  </TableCell>}
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    );
                  }
                )}
                
            </Box>
          </Box>
          </>}
        </DialogContent>
      </Dialog>

      <Box sx={{ padding: "0 0.5rem", display: "flex", alignItems: "center" }}>
        <Box className={styles.titleSection}>
          <Box className={styles.titleSectionLeft}>
            <IconButton sx={{ cursor: "pointer" }} onClick={handleClickBack}>
              <ArrowBackIcon color="primary" titleAccess="Go Back" />
            </IconButton>
            <Typography
              sx={{
                fontSize: "1rem",
                whiteSpace: "nowrap",
                display: "inline-block",
              }}
            >
              BOM Summary ( {projectName} )
            </Typography>
          </Box>
          <Box className={styles.titleSectionRight}>
          <Button
            variant="contained"
            className={styles.downloadBtn}
            size="small"
            endIcon={<ArrowDropDownIcon />}
            style={{ fontSize: "12px" }}
            onClick={handleClickHierarchy}
          >
            Reverse Upload
          </Button>
          <Menu
            id="basic-menu"
            anchorEl={anchorElHierarchy}
            open={openHierarchy}
            onClose={handleCloseHierarchy}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            sx={{
              '.MuiPaper-root.MuiMenu-paper.MuiPopover-paper':{
                width:'12.5rem',
              }
            }}
          >
         {!productListLoader ? <> {productList && productList?.map((item: any) => {
            return (
              <MenuItem sx={{fontSize:'1rem'}} value={item.top_vault_id} onClick={()=>ReverseModalHandler(item.top_vault_id)}>{item.name}</MenuItem>
            )
          })}</> : <Box sx={{padding:'0 1rem'}}><Skeleton></Skeleton>
          <Skeleton></Skeleton>
          </Box>}          
          </Menu>
            <Button
              className={styles.bomStatus}
              size="small"
              
              variant={"contained"}
              sx={{
                borderRadius: "1.5rem",
                ".MuiCircularProgress-root": {
                  color: "primary.main",
                },
              }}
              onClick={handleBOMStatusOpen}
            >
              BOM Status
            </Button>
            {compactViewInfo?.visibility && (
              <IconButton
                className={styles.expandColapIcon}
                onClick={handleClickExpand}
              >
                {" "}
                {expandTable == "true" ? (
                  <CompressIcon
                    sx={{ color: "#007fff", transform: "rotate(90deg)" }}
                    titleAccess="Compact View"
                  />
                ) : (
                  <ExpandIcon
                    sx={{ color: "#007fff", transform: "rotate(90deg)" }}
                    titleAccess="Normal View"
                  />
                )}{" "}
              </IconButton>
            )}
            <IconButton title="Scan QR" onClick={() => qrScannerHandler()}>
              <QrCodeScannerIcon sx={{ color: "#007fff", cursor: "pointer" }} />
            </IconButton>
            <IconButton
              className={styles.chartIcon}
              title="Analyse"
              onClick={() =>
                window.open(`/#/analysis/${projectId}/${topVaultIds}`)
              }
            >
              <InsightsIcon sx={{ color: "#007fff" }} />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "flex-start", width: "100%", }}>
        <Box className={styles.expandIconBox}>
          <div className={styles.iconSec}>
            {(["left"] as const).map((anchor) => (
              <React.Fragment key={anchor}>
                <IconButton
                  size="medium"
                  sx={{
                    backgroundColor: "primary.light",
                    color: "primary.main",
                  }}
                  onClick={toggleDrawer(anchor, true)}
                >
                  <AddIcon sx={{ fontSize: "1.7rem" }} />
                </IconButton>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  className={styles.drawerContainer}
                  PaperProps={{
                    sx: {
                      backgroundColor: "aliceblue",
                    },
                  }}
                >
                  <Box
                    sx={{ padding: "0.5rem 0", position: "sticky", top: "0" }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3"
                  >
                    <Typography
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "1rem",
                      }}
                    >
                      Select Project
                    </Typography>
                    <Divider sx={{ margin: "0.2rem" }} />
                    {list(anchor)}
                  </Box>
                </Drawer>
                <Typography
                  sx={{ fontSize: "1rem", color: "primary.main" }}
                  onClick={toggleDrawer(anchor, true)}
                  className={styles.sideTitle}
                >
                  Project Selection
                </Typography>
              </React.Fragment>
            ))}
          </div>
        </Box>
        <Box
          sx={
            String(topVaultIds)?.includes(",")
              ? topVaultIds?.split(",")?.length == 2
                ? {
                    display: "grid",
                    gridTemplateColumns: `1.2fr 1fr`,
                    width: "98%",
                    maxHeight: {xs:'82vh',sm:'82vh',md:'82vh', lg: "86vh", xl: "90vh" },
                  }
                : {
                    display: "grid",
                    gridTemplateColumns: `1.2fr repeat(${
                      topVaultIds?.split(",")?.length - 1
                    }, 1fr)`,
                    width: "98%",
                    // height: { xs: "82vh",sm:'82vh',md:'82vh', lg: "86vh", xl: "90vh"  },
                    maxHeight: {xs:'82vh',sm:'82vh',md:'82vh', lg: "86vh", xl: "90vh" },
                  }
              : {
                  display: "grid",
                  gridTemplateColumns: `1fr`,
                  width: "98%",
                  maxHeight: {xs:'82vh',sm:'82vh',md:'82vh', lg: "86vh", xl: "90vh" },
                }
          }
          adl-scrollbar="true"
          adl-scrollbar-width="0.3"
        >
          <BOMCreateSystemTable />
        </Box>
      </Box>
    </>
  );
};

export default BOMCreateLandingPage;
