export const fetchTopLevelNodes = async () => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          data: [
            { id: 1, name: "Node 1" },
            { id: 2, name: "Node 2" },
            { id: 3, name: "Node 3" },
          ],
        });
      }, 1000);
    });
  };
  
  export const fetchChildNodes = async (nodeId: any) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          data: [
            { id: `${nodeId}-1`, name: `Child of Node ${nodeId}` },
            { id: `${nodeId}-2`, name: `Child of Node ${nodeId}` },
          ],
        });
      }, 1000);
    });
  };
  