import React, { useEffect, useState } from "react";
import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Modal,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Skeleton,
  Tooltip,
  CircularProgress,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { API } from "../../api-services";
import Swal from "sweetalert2";
import { LoadingButton } from "@mui/lab";
import { ADMIN } from "../../Redux/Services/admin.service";

interface Item {
  id: number;
  name: string;
  oldCost: number;
  newCost: number;
}

interface CostingConfigSyncModalProps {
  isOpen: boolean;
  onCloseModal: () => void;
  data: Item[];
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "80vw",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 2,
};

function CostingConfigSyncModal({
  isOpen,
  onCloseModal,
  category_id,
  setParentCount,
  setSyncCount,
}: any) {
  const handleSync = (itemId: number) => {
    console.log(`Syncing item with ID: ${itemId}`);
    // Add your sync logic here
  };
  const [loading, setLoading] = useState<any>(false);
  const [data, setData] = useState<any>([]);
  const [syncLoader, setSyncLoader] = useState(false);
  const [showClose, setShowClose] = useState(false);
  const [syncLoader2, setSyncLoader2] = useState(false);
  const [selectedEstimationIds, setSelectedEstimationIds] = useState<any>([]);
  const [activeButton, setActiveButton] = useState<string | null>(null);

  const handleCheckboxChange = (estimationId: number) => {
    setSelectedEstimationIds((prev: any) => {
      if (prev.includes(estimationId)) {
        // Remove the id if it's already selected
        return prev.filter((id: any) => id !== estimationId);
      } else {
        // Add the id if it's not selected
        return [...prev, estimationId];
      }
    });
  };

  const handleSelectAllChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    if (event.target.checked) {
      const allVaultIds = data.map((item: any) => item.vault_id);
      setSelectedEstimationIds(allVaultIds);

    } else {
      setSelectedEstimationIds([]);
    }
    setActiveButton(null)
  };

  useEffect(() => {
    setLoading(true);
    API.post(`config/sync_costing_configuration/${category_id}/`, {}, {}, 600000)
      .then((res: any) => {
        setData(res.data);
        setParentCount((prev: any) => prev + 1);
      })
      .catch((err: any) => {
        if (err && err.response && err?.response?.data) {
          Swal.fire({
            title: "Error!",
            text: err?.response?.data[0],
            icon: "error",
            confirmButtonText: "Ok",
          });
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);
  console.log(data);

  const handleValidation = () => {
    setSyncLoader(true);
    API.put(
      `api/db/costing_category/${category_id}/`,
      { vault_list: selectedEstimationIds, remove_cost_validation: true },
      0
    )
      .then(() => {
        setSyncLoader(false);
        ADMIN.toast.info("Synced successfully");
        setParentCount((prev: any) => prev + 1);
        setSyncCount((prev: any) => prev + 1);
        onCloseModal();
      })
      .catch((err) => {
        setSyncLoader(false);
        console.log(err);

        //   Swal.fire({
        //     title: "Error!",
        //     text: "",
        //     icon: "error",
        //     confirmButtonText: "Ok",
        //   });
      })
      .finally(() => {
        setSyncLoader(false);
      });
  };

  const handlewithoutValidation = () => {
    onCloseModal();
  }

  const handleButtonClick = (type: string) => {
    // Filter items based on delta value
    const filteredItems = data.filter((item: any) =>
      type === "zeroDelta" ? item.delta === 0 : item.delta > 0
    );

    // Update selectedEstimationIds with vault_id of filtered items
    const newSelectedIds = filteredItems.map((item: any) => item.vault_id);
    setSelectedEstimationIds(newSelectedIds);

    // Toggle active button state
    setActiveButton(type === activeButton ? null : type);
  };

  console.log(selectedEstimationIds);


  useEffect(() => {
    setTimeout(() => {
      if (loading === false && Array.isArray(data) && data?.length === 0) {
        setShowClose(true);
      }
    }, 1000)
  }, [loading])
  console.log(showClose);


  return (
    <Modal
      open={isOpen}
      onClose={onCloseModal}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: { timeout: 500 },
      }}
    >
      <Box sx={style}>
        <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: "0.5rem" }}>

          <Typography variant="h6" component="h2" gutterBottom>
            Affected Parts Due to Costing Configuration Modification
          </Typography>

          {data?.length > 0 && <Box sx={{ display: "flex", gap: "0.5rem" }}>
            <Button
              variant={activeButton === "zeroDelta" ? "contained" : "outlined"}
              sx={{ color: activeButton === "zeroDelta" ? "primary" : "default" }}
              onClick={() => handleButtonClick("zeroDelta")}
            >
              Parts with 0 delta {activeButton === "zeroDelta" && `(${selectedEstimationIds?.length})`}
            </Button>
            <Button
              variant={activeButton === "positiveDelta" ? "contained" : "outlined"}
              sx={{ color: activeButton === "positiveDelta" ? "primary" : "default" }}
              onClick={() => handleButtonClick("positiveDelta")}
            >
              Select all parts with delta more than 0 {activeButton === "positiveDelta" && `(${selectedEstimationIds?.length})`}
            </Button>
          </Box>}

          {showClose === true && data?.length === 0 && <CancelIcon sx={{ cursor: "pointer" }} onClick={onCloseModal} />}


        </Box>

        <TableContainer
          component={Paper}
          sx={{ height: { lg: "62vh", xl: "61vh" } }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3"
        >
          {loading ? (
            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                gap: "1rem",
                marginTop: "3rem",
                height: "10rem",
              }}
            >
              <CircularProgress size={36} />
              <Typography>Generating delta report...</Typography>
            </Box>
          ) : (
            <>
              {Array.isArray(data) && data?.length > 0 ? (
                <Table>
                  <TableHead>
                    <TableRow
                      sx={{
                        backgroundColor: "primary.main",
                        color: "common.white",
                      }}
                    >
                      <TableCell sx={{ padding: "0.3rem 0.5rem", color: "#fff" }}>
                        <Tooltip title="Select All">
                          <Checkbox
                            sx={{
                              color: "#fff",
                              "&.Mui-checked": {
                                color: "#fff", // Maintain white color when checked
                              },
                              "&.Mui-checked:hover": {
                                backgroundColor: "transparent", // Prevent hover background effect
                              },
                            }}
                            checked={
                              selectedEstimationIds.length === data.length &&
                              data.length > 0
                            }
                            onChange={handleSelectAllChange}
                          />
                        </Tooltip>



                      </TableCell>
                      <TableCell sx={{ padding: "0.3rem 0.5rem", color: "#fff" }}>
                        Part Name
                      </TableCell>
                      <TableCell sx={{ padding: "0.3rem 0.5rem", color: "#fff" }}>
                        Part No.
                      </TableCell>
                      <TableCell sx={{ padding: "0.3rem 0.5rem", color: "#fff" }}>
                        Old Cost (INR)
                      </TableCell>
                      <TableCell sx={{ padding: "0.3rem 0.5rem", color: "#fff" }}>
                        New Cost (INR)
                      </TableCell>
                      <TableCell sx={{ padding: "0.3rem 0.5rem", color: "#fff" }}>
                        Delta (INR)
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>

                    {
                      data?.map((item: any) => (
                        <TableRow key={item.id}>
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={selectedEstimationIds.includes(
                                item.vault_id
                              )}
                              onChange={() => handleCheckboxChange(item.vault_id)}
                            />
                          </TableCell>
                          <TableCell>{item?.name}</TableCell>
                          <TableCell>{item?.part_no}</TableCell>
                          <TableCell>{item?.old_cost}</TableCell>
                          <TableCell>{item?.new_cost}</TableCell>
                          <TableCell>{item?.delta}</TableCell>
                        </TableRow>
                      ))}

                  </TableBody>
                </Table>
              ) : <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  flexDirection: "column",
                  marginTop: "3rem",
                  height: "10rem",
                }}
              >
                <Typography>No part found</Typography>
              </Box>}

            </>
          )}
        </TableContainer>

        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
            marginTop: "1rem",
          }}
        >
          {data?.length > 0 && <Box sx={{ display: "flex", gap: "1rem", alignItems: "center" }}>
            {selectedEstimationIds?.length === 0 && <LoadingButton

              loading={syncLoader2}
              variant="outlined"
              color="primary"
              onClick={handlewithoutValidation}
            >
              Proceed without removing validation
            </LoadingButton>}
            <LoadingButton
              disabled={selectedEstimationIds?.length === 0}
              loading={syncLoader}
              variant="contained"
              color="primary"
              onClick={handleValidation}
            >
              Proceed by removing validation
            </LoadingButton>
          </Box>}
        </Box>
      </Box>
    </Modal>
  );
}

export default CostingConfigSyncModal;
