import { API } from "../../api-services";
import { setItems, clear, getItem, setItem } from "../../utlis/storage";
import { ICredentials } from "../../utlis/commonInterfaces";
import { ADMIN } from "./admin.service";

// --------------------------------------- prototype pattern -------------------------------

class authenticate {
    login = (credentials: ICredentials) => {
        const { rememberMe, phone_no, email } = credentials;
        return API.login({ phone_no, email }).then(res => {
            setItem.call(localStorage, "rememberMe", rememberMe);

            if ("error_mess" in  res.data) {
                sessionStorage.setItem("LoginInfoMsg", res?.data?.error_mess);
                return res.data.token
            };

            const obj = {
                user: res.data?.user?.id,
                isSuperUser: res.data.user.is_superuser,
                isProjectAdmin: res.data.is_project_admin,
                username: res.data.user.user,
                phone: res.data.user.phone,
                email: res.data.user.email,
                empId: res.data.user.employee_id,
                token: res.data.token,
                organisation: res.data.user.organisation,
                organisationId: res.data.user.organisation_id,
                user_image: res.data.user.user_info,
                user_category: res.data.user.user_category,
                user_expiration: res.data.user.user_expiration,
                designation: res.data.user.designation
            };

            
                 setItems.call(localStorage, obj)
                 setItems.call(sessionStorage, obj);
            return res;
        });
    };

    verify = (credentials: { token: string; otp: number; }) => {
        const { token, otp } = credentials;
        return API.post("auth/login/", {
            token,
            otp,
        }).then(res => {
            const obj = {
                user: res.data.user.id,
                isSuperUser: res.data.user.is_superuser,
                isProjectAdmin: res.data.is_project_admin,
                username: res.data.user.user,
                phone: res.data.user.phone,
                email: res.data.user.email,
                empId: res.data.user.employee_id,
                token: res.data.token,
                organisation: res.data.user.organisation,
                organisationId: res.data.user.organisation_id,
                user_image: res.data.user.user_info,
                user_category: res.data.user.user_category,
                user_expiration: res.data.user.user_expiration,
                designation: res.data.user.designation
            };

                setItems.call(localStorage, obj)
                setItems.call(sessionStorage, obj);
            return res;
        });
    };

    resend = (credentials: { token: string; }) => {
        const { token } = credentials;
        return API.post("auth/login/", {
            token,
        }).then(res => {
            return res;
        });
    };

    get rememberMe() {
        return this.getItemLS("rememberMe");
    }

    private getItemLS = getItem.bind(localStorage);
    private getItemSS = getItem.bind(sessionStorage);

    logout = (cb?: () => void) => {
        clear.call(localStorage);
        clear.call(sessionStorage);
        window.location.replace("/#/");
        if (cb) setTimeout(cb, 200);
    };

    get user() {
        return this.rememberMe
            ? this.getItemLS("user")
            : this.getItemSS("user");
    }

    get username() {
        return this.rememberMe
            ? this.getItemLS("username")
            : this.getItemSS("username");
    }


    get user_category() {
        return this.rememberMe
            ? this.getItemLS("user_category")
            : this.getItemSS("user_category");
    }


    get user_expiration() {
        return this.rememberMe
            ? this.getItemLS("user_expiration")
            : this.getItemSS("user_expiration");
    }


    get designation() {
        return this.rememberMe
            ? this.getItemLS("designation")
            : this.getItemSS("designation");
    }


    get email() {
        return this.rememberMe
            ? this.getItemLS("email")
            : this.getItemSS("eamil");
    }


    get empId() {
        return this.rememberMe
            ? this.getItemLS("empId")
            : this.getItemSS("empId");
    }

    get phone() {
        return this.rememberMe
            ? this.getItemLS("phone")
            : this.getItemSS("phone");
    }


    get user_image() {
        return this.rememberMe
            ? this.getItemLS("user_image")
            : this.getItemSS("user_image");
    }

    get isLoggedIn() {
        return !!API.token && !!this.user;
    }

    get isSuperUser() {
        return this.rememberMe
            ? this.getItemLS("isSuperUser")
            : this.getItemSS("isSuperUser");
    }

    get isProjectAdmin() {
        return this.rememberMe
            ? this.getItemLS("isProjectAdmin")
            : this.getItemSS("isProjectAdmin");
    }

    get isSessionTimedOut() {
        return !API.token && !!this.user; // user exits and token does'nt
    }

    get organisation() {
        return this.rememberMe
            ? this.getItemLS("organisation")
            : this.getItemSS("organisation");
    }

    get organisationId() {
        return this.rememberMe
            ? this.getItemLS("organisationId")
            : this.getItemSS("organisationId");
    }

    get expirytime() {
        return this.rememberMe
            ? this.getItemLS("expirytime")
            : this.getItemSS("expirytime");
    }
    get token(){
        return this.getItemLS("token")
    }
}

const AUTH = new authenticate();

export { AUTH };
