



import { Box, Button, Divider, Modal, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import CancelIcon from '@mui/icons-material/Cancel';
import SaveIcon from "@mui/icons-material/Save";
import { API } from '../../api-services';
import { LoadingButton } from '@mui/lab';
import ErrorModalFilesMail from '../../ComponentUI/ProjectMailModule/ErrorModalFilesMail';
import { useBaseModal } from '../../ComponentUI/SearchPage/useBaseModal';
import sucessIcon from "../../Assets/images/success.png";


interface AddSystemInformationModalProps {

  isOpen: any;
  onCloseHanlder: any;
  setCounter: any;
  ModalType: any;
  RowItem: any
}
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '25vw',
  bgcolor: 'background.paper',
  borderRadius: "10px",
  p: 1,
};

const AddFeatureInformationModal = (props: AddSystemInformationModalProps) => {
  const { categoryId, subsystemId, search,Featuretype } = useParams<any>();
  const { isOpen, onCloseHanlder, setCounter, ModalType, RowItem } = props;
  const ErrorModalFiles = useBaseModal()
  const [saveLoader, setsaveLoader] = useState<any>(false)
  const [Name, setName] = useState<any>("")
  const [SucessLoader, setSucessLoader] = useState<any>(false)
  const [ErrorModalFileMsg, setErrorModalFileMsg] = useState<any>(false)
  const [NameError, setNameError] = useState<any>(false)
  const handleChange = (e: any) => {
    setNameError(false)
    setName(e?.target?.value?.trim())
  }


  useEffect(() => {
    setSucessLoader(false)
  }, [])

  useEffect(() => {
    if (ModalType == "Edit") {
      setName(RowItem?.name)
    }
  }, [RowItem])

  const SaveHandler = () => {
    var testdata = /\S+/.test(Name);
    if (testdata == true) {
      if (ModalType == "Add") {
        setsaveLoader(true)
        API.post("/api/db/feature_name/", {
          id: 0,
          name: Name,
          subsystem: parseInt(subsystemId),
          vehicle_type: parseInt(categoryId),
          feature_type: parseInt(Featuretype),
        },0).then((res: any) => {
          setSucessLoader(true)
          setsaveLoader(false)
          setCounter((prev: any) => prev + 1)
        }).catch((err: any) => {
          const { data } = err?.response;
          console.log(err?.response,"cdcdcdcdcdc")
          setErrorModalFileMsg(data[0])
          ErrorModalFiles.open()
          setsaveLoader(false)
        })
      } else {
        setsaveLoader(true)
        API.put(`/api/db/feature_name/${RowItem && RowItem?.id}/`, {
          name: Name,
        },0).then((res: any) => {
          setSucessLoader(true)
          setsaveLoader(false)
          setCounter((prev: any) => prev + 1)
        }).catch((err: any) => {
          const { data } = err?.response;
          setErrorModalFileMsg(data[0])
          ErrorModalFiles.open()
          setsaveLoader(false)
        })
      }
    } else {
      setNameError(true)
    }

  }
  return (
    <div>
      <Modal open={isOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ErrorModalFilesMail
            isOpen={ErrorModalFiles.isOpen}
            onCloseModal={ErrorModalFiles.close}
            ErrorMsg={ErrorModalFileMsg}
          />
          <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
            {ModalType == "Edit" ?

              <Typography
                style={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  width: "100%",
                  textAlign: "center",
                  marginRight: '-2rem'
                }}
              >
                Edit {Featuretype==0?"Feature":Featuretype==1?"Specification":"Information"} Name
              </Typography>
              :
              <Typography
                style={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  width: "100%",
                  textAlign: "center",
                  marginRight: '-2rem'
                }}
              >
                Add {Featuretype==0?"Feature":Featuretype==1?"Specification":"Information"}  Name
              </Typography>}
            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseHanlder} />
          </Box >
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          {SucessLoader ?
            <Box sx={{ textAlign: 'center', }} >
              <img src={sucessIcon} style={{ width: '7rem' }} />
              {ModalType == "Edit" ? <Typography sx={{ color: "green", marginTop: '1rem' }}>{Featuretype==0?"Feature":Featuretype==1?"Specification":"Information"}   name updated successfully!</Typography> :
                <Typography sx={{ color: "green", marginTop: '1rem' }}>{Featuretype==0?"Feature":Featuretype==1?"Specification":"Information"}   name added successfully!</Typography>}
              <Button sx={{ marginTop: '1rem' }} variant='contained' size='small' onClick={onCloseHanlder}>ok</Button>
            </Box>
            :
            <Box sx={{ padding: '0 0.5rem', width: '100%', marginBottom: '1rem' }}>
              <TextField
                error={NameError}
                variant='standard'
                type='text'
                defaultValue={Name}
                sx={{
                  width: '82%',
                  '& .MuiInput-underline:before': { borderBottomColor: 'primary.main' },
                  '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                }}
                onChange={(e: any) => handleChange(e)}
                placeholder={`${Featuretype==0?"Feature":Featuretype==1?"Specification":"Information"} Name`}
              />
              <LoadingButton variant='contained' onClick={() => SaveHandler()} sx={{
                cursor: 'pointer', minWidth: '1rem', padding: '0.2rem 0.5rem', marginLeft: '0.5rem',
                fontSize:'0.87rem',
                "&:hover": {
                  transform: 'Scale(1.05)',
                  transition: 'transform 0.5s ease',
                }
              }} loading={saveLoader}>
                {/* <SaveIcon  sx={{  margin: '0.5rem' }} /> */}
                Save
              </LoadingButton>
            </Box>
          }
        </Box>
      </Modal >
    </div >
  )
}
export default AddFeatureInformationModal;