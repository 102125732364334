import * as React from "react";
import styles from "./CompareHome.module.scss";
import AddIcon from "@mui/icons-material/Add";
import {
  Autocomplete,
  Box,
  Button,
  CardMedia,
  Drawer,
  List,
  ListItem,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { Divider } from "@mui/material";
import { Card } from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import swal from "sweetalert";
import car1 from "../Assets/images/images/image1.jpg";

import { API } from "../api-services";
import { useHistory, useParams } from "react-router-dom";
import BomNumerical from "./Numerical/BOM/BomNumerical";

import ModeEditIcon from '@mui/icons-material/ModeEdit';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import { IconButton } from '@mui/material';
import FilterAltRoundedIcon from '@mui/icons-material/FilterAltRounded';
import ArrowCircleRightRoundedIcon from '@mui/icons-material/ArrowCircleRightRounded';
import ArrowCircleLeftRoundedIcon from '@mui/icons-material/ArrowCircleLeftRounded';

export interface ICompareHomeProps { }
export interface Params {
  projectId: any,
  module: any,
  level: any,
  comparedId: any,
}
export function CompareHome(props: ICompareHomeProps) {
  const [value, setValue] = React.useState("1");
  const [productList, setProductList] = React.useState<any>([]);
  const history = useHistory();
  const [moduleId, setModuleId] = React.useState<any>(null);
  const [combination, setCombination] = React.useState<any>([]);
  const [selectedProducts, setSelectedProducts] = React.useState<any>([])
  const [combinationData, setCombinationData] = React.useState<any>({});
  const [productLevel, setProductLevel] = React.useState<string>("");
  const { projectId, module, level, comparedId } = useParams<Params>();
  const [loadingStatus, setLoadingStatus] = React.useState<boolean>(true)
  var [endIndex, setendIndex] = React.useState<any>(5);
  var [startIndex, setstartIndex] = React.useState<any>(0);
  const [active, setActive] = React.useState<any>(1);


  const getModuleNameById: any = (moduleId: any) => {
    switch (moduleId) {
      case "Architecture":
        return 26;
      case "Features":
        return 19;
      case "BOM":
        return 13;
      default:
        return 16;
    }
  };

  var MAX_LENGTH: any = 5;


  var MAP_LIST: any = combination?.slice(startIndex, endIndex);
  const btnHandler = (key: any) => {
    switch (key) {
      case 1:
        startIndex = endIndex;
        endIndex = startIndex + MAX_LENGTH;
        setstartIndex(startIndex);
        setendIndex(endIndex);
        setActive((prev: any) => prev + 1);
        MAP_LIST = combination?.slice(startIndex, endIndex);
        break;
      case 2:
        endIndex = endIndex - MAX_LENGTH;
        startIndex = startIndex - MAX_LENGTH;
        setstartIndex(startIndex);
        setendIndex(endIndex);
        setActive((prev: any) => prev - 1);
        MAP_LIST = combination?.slice(startIndex, endIndex);
        break;
    }
  };
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };
  const getcombinationData = (combinationId: any) => {
    setLoadingStatus(false)
    API.get("analytics/get_combination_data/", {
      combination: combinationId,
      page: 1,
      group_page: null
    }).then((res: any) => {
      setCombinationData(res.data);
      setLoadingStatus(true)
    }).catch((err: any) => {
      setLoadingStatus(true)
    })
  }

  React.useEffect(() => {
    if (comparedId !== 0) getcombinationData(comparedId)

  }, [comparedId])



  type Anchor = "top" | "left" | "bottom" | "right";

  const getProductByProject = () => {
    API.get("/auth/authorized_projects_mui/", {
      module: module,
      project: projectId
    }, 0).then((res: any) => {
      setProductList(res?.data?.products)

    }).catch((err: any) => {

    })
  }

  React.useEffect(() => {
    getProductByProject()
  }, [])

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const moduleList = [
    {
      label: "BOM",
      value: 13
    },
    {
      label: "Costing",
      value: 16
    },
    {
      label: "Feature",
      value: 19
    },
    {
      label: "Architecture",
      value: 26
    }
  ]

  const productLevelList = [
    {
      label: "Product"
    },
    {
      label: "System"
    },
    {
      label: "Assembly"
    },
    {
      label: "Part"
    }
  ]

  const handleDelete = () => {
  }

  const selectAllProduct = (productIds: any) => {
    if (selectedProducts.includes(productIds)) {
      setSelectedProducts((prev: any) => prev.filter((item: any) => item !== productIds))
    } else {
      setSelectedProducts((prev: any) => [...prev, productIds])
    }
  }
  const getselectedBackgroundColor = (idd: any) => {
    if (selectedProducts.includes(idd)) {
      return 'primary.main'
    } else {
      return 'primary.light'
    }
  }
  const getSelectedTextColor = (idd: any) => {
    if (selectedProducts.includes(idd)) {
      return 'primary.light'
    } else {
      return 'primary.main'
    }
  }

  const getCombinationData = () => {
    API.get("circuit_diagram/compare_combination", { module: module }, 0).then((res: any) => {
      setCombination(res?.data);
      history.push(`/compare/${projectId}/${getModuleNameById(res?.data[0].module)}/${res?.data[0].level}/${res?.data[0].id}/`)
    }).catch((err: any) => {
    })
  }

  React.useEffect(() => {
    getCombinationData()
  }, [module])

  const createCombination = () => {
    API.post(`/circuit_diagram/compare_combination/`, { module: moduleId, level: productLevel, products: selectedProducts }).then((res: any) => {
      setModuleId(null);
      setProductLevel("");
      setSelectedProducts([]);


    }).catch((e: any) => {
      if (e.response.status === 400) {

        swal({
          title: "Are you sure?",
          text: "A Combination with the configuration already exists.",
          // icon: "warning",
          buttons: ["No", "Yes"],
        }).then((isData: any) => {
          if (isData) {
            API.post(`/circuit_diagram/compare_combination/`, { module: moduleId, level: productLevel, products: selectedProducts, force: true }).then((res: any) => {
              setModuleId(null);
              setProductLevel("");
              setSelectedProducts([]);

            });
          }
        }).catch((e: any) => {
        });
      }

    });
  }


  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }
        setSelectedProducts([])
        setState({ ...state, [anchor]: open });
      };

  const showCombination = (combinationId: any) => {
    getcombinationData(combinationId)
    history.push(`/compare/${projectId}/${module}/${level}/${combinationId}`)
  }
  const selectModuleFilter = (moduleId: any) => {
    history.push(`/compare/${projectId}/${moduleId}/${level}/${comparedId}`)
  }

  const getCombinationChipBGColor = (selectedId: any) => {
    let color = "";
    if (comparedId !== 0) {
      if (selectedId === parseInt(comparedId)) {
        color = '#7fb3ee'
      } else {
        color = "primary.light";
      }
    }
    return color
  }
  const getCombinationChipColor = (selectedId: any) => {
    let color = "";
    if (comparedId !== 0) {
      if (selectedId === parseInt(comparedId)) {
        color = 'common.white'
      } else {
        color = "primary.main";
      }
    }
    return color
  }
  const list = (anchor: Anchor) => (

    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 350 }}
      role="presentation"
    >

      <List>
        <ListItem>
          <Box sx={{ width: '100%' }}>
            <TextField id="standard-basic"
              sx={{
                width: "97%",
                marginBottom: '1rem',
                "& .MuiFormLabel-root": {
                  color: "#007fff",
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                  borderColor: "primary.main",
                },
                "&:focus-visible": {
                  outline: "none",
                  borderColor: "#007fff",
                },
                "&:hover": {
                  borderColor: "#007fff",
                },
              }}
              label="Compare Name" variant="standard" />
            <Typography sx={{ marginBottom: '0.5rem' }}>Select Compare Module</Typography>
            <Box sx={{ display: 'flex', columnGap: '10px', justifyContent: 'space-evenly' }}>
              <Button sx={{ backgroundColor: 'primary.light' }}>BOM</Button>
              <Button sx={{ backgroundColor: 'primary.light' }}>Costing</Button>
              <Button sx={{ backgroundColor: 'primary.light' }}>Feature</Button>
              <Button sx={{ backgroundColor: 'primary.light' }}>Architecture</Button>
            </Box>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              onChange={(e: any, val: any) => setModuleId(val.value)}
              sx={{ width: '100%' }}
              renderInput={(params) => <TextField variant="standard"
                sx={{
                  // margin: "0.5rem",
                  width: "97%",
                  "& .MuiFormLabel-root": {
                    color: "#007fff",
                  },
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: "primary.main",
                    borderColor: "primary.main",
                  },
                  "&:focus-visible": {
                    outline: "none",
                    borderColor: "#007fff",
                  },
                  "&:hover": {
                    // outline: "none",
                    borderColor: "#007fff",
                  },
                }}
                {...params} label="Module" size="small" />}
              options={moduleList}
            />

            <div style={{ margin: '1rem' }} />
            <Typography style={{ width: '100%', }}>Select Products</Typography>
            <div className={styles.selectProduct}>
              <div>
                {
                  productList.map((item: any, index: any) => {
                    return (
                      <Box onClick={() => selectAllProduct(item?.id)} key={index} sx={{ margin: '1rem', padding: '0.2rem', backgroundColor: getselectedBackgroundColor(item.id), color: getSelectedTextColor(item.id), textAlign: 'center', cursor: 'pointer' }}>
                        <Typography>{item.name}</Typography>
                      </Box>
                    )
                  })
                }
              </div>
            </div>
            <div style={{ margin: '1rem' }} />
            <Typography sx={{ marginBottom: '0.5rem' }}> Select Compare Type </Typography>
            <Box sx={{ display: 'flex', columnGap: '10px', justifyContent: 'space-evenly' }}>
              <Button sx={{ backgroundColor: 'primary.light' }}>Product</Button>
              <Button sx={{ backgroundColor: 'primary.light' }}>System</Button>
              <Button sx={{ backgroundColor: 'primary.light' }}>Assembly</Button>
              <Button sx={{ backgroundColor: 'primary.light' }}>Part</Button>
            </Box>
            <Autocomplete
              disablePortal
              id="combo-box-demo"
              onChange={(e: any, val: any) => setProductLevel(val.label)}
              sx={{ width: '100%' }}
              renderInput={(params) => <TextField variant="standard"
                sx={{
                  margin: "0.5rem",
                  width: "97%",
                  "& .MuiFormLabel-root": {
                    color: "#007fff",
                  },
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: "primary.main",
                    borderColor: "primary.main",
                  },
                  "&:focus-visible": {
                    outline: "none",
                    borderColor: "#007fff",
                  },
                  "&:hover": {
                    // outline: "none",
                    borderColor: "#007fff",
                  },
                }}
                {...params}
                label="Select Compare Type"
                size="small" />}
              options={productLevelList}
            />
          </Box>
        </ListItem>
      </List>
      <div className={styles.createBtn}>
        <Button className={styles.crBtn} variant="contained" sx={{ width: '100%', }} onClick={() => createCombination()}>Create</Button>
      </div>
    </Box>

  );

  return (
    <div
      className={styles.container}
      adl-scrollbar="true"
      adl-scrollbar-width="0.3"
    >
      <Box className={styles.expandIconBox}>
        <div className={styles.iconSec}>
          {(["left",] as const).map((anchor) => (
            <React.Fragment key={anchor}>
              <IconButton size="medium" sx={{ backgroundColor: 'primary.light', color: 'primary.main' }} onClick={toggleDrawer(anchor, true)}><AddIcon sx={{ fontSize: '1.7rem' }} /></IconButton>
              <Drawer
                anchor={anchor}
                open={state[anchor]}
                onClose={toggleDrawer(anchor, false)}
                className={styles.drawerContainer}
                PaperProps={{
                  sx: {
                    backgroundColor: 'aliceblue',
                  }
                }}
              >
                <Box sx={{ padding: '1rem', }}>
                  <Typography style={{ width: '100%', textAlign: 'center' }}>Create Combination</Typography>
                  <Divider sx={{ margin: '1rem' }} />
                  <Typography>Select Module</Typography>
                  {list(anchor)}
                </Box>
              </Drawer>
            </React.Fragment>
          ))}
        </div>
        <Typography style={{ cursor: 'pointer', transform: 'rotate(270deg)', whiteSpace: 'nowrap', marginTop: '20rem', }}>Create Combination</Typography>
      </Box>
      <Box>
        <Box className={styles.projectSelectSection}
        >

          <div className={styles.SelectedProject}>
            <Box sx={{ display: "flex", columnGap: "10px" }}>
              <IconButton onClick={() => btnHandler(2)} style={{ cursor: startIndex !== 0 ? "pointer" : "not-allowed" }} disabled={startIndex !== 0 ? false : true}><ArrowCircleLeftRoundedIcon sx={{ fontSize: '2rem' }} /></IconButton>
              <div className={styles.copareNameSec}
                adl-scrollbar="true"
                adl-scrollbar-width="0.3">
                {MAP_LIST?.map((item: any, index: any) => {
                  return <>
                    <Box className={styles.chipBox} sx={{ color: getCombinationChipColor(item.id), backgroundColor: getCombinationChipBGColor(item.id) }} style={{ cursor: 'pointer' }}>
                      <Typography onClick={() => { showCombination(item.id) }}>Compare Option Name</Typography>
                      <ModeEditIcon className={styles.editIcon} titleAccess="Edit Name" sx={{ cursor: 'pointer' }} />
                      <CancelRoundedIcon className={styles.deleteIcon} titleAccess="Delete Combination" onClick={handleDelete} sx={{ cursor: 'pointer' }} />
                    </Box>
                  </>
                })}
              </div>
              <Button onClick={() => btnHandler(1)} style={{ cursor: combination?.length > endIndex ? "pointer" : "not-allowed" }} disabled={combination?.length > endIndex ? false : true}><ArrowCircleRightRoundedIcon sx={{ fontSize: '2rem' }} /></Button>
            </Box>
            <Box sx={{ marginTop: '0.5rem' }}>
              <FilterAltRoundedIcon sx={{ fontSize: '1.7rem' }} />
            </Box>
          </div>
        </Box>
        <Divider />

        <Box className={styles.productImageSec}>
          <Box className={styles.compareProductBtn} sx={{ display: 'grid', gridAutoFlow: 'row', rowGap: '10px' }}>
            {moduleList.map((item: any) => {
              return <Button className={styles.moduleBtn} onClick={() => { selectModuleFilter(item.value) }} sx={{ backgroundColor: module == item.value ? "primary.main" : 'primary.light', color: module == item.value ? "common.white" : "primary.main", minWidth: '8rem' }}>{item.label}</Button>
            })}
          </Box>
          <Box>
            <div className={styles.compareProductImage}>
              <Card className={styles.prodImage} sx={{ width: "100%", }}>
                <CardMedia
                  component="img"
                  height="100%"
                  src={car1}
                  alt="green iguana"
                />
              </Card>
              <Card className={styles.prodImage} sx={{ width: "100%", }}>
                <CardMedia
                  component="img"
                  height="100%"
                  src={car1}
                  alt="green iguana"
                />
              </Card>
              <Card className={styles.prodImage} sx={{ width: "100%", }}>
                <CardMedia
                  component="img"
                  height="100%"
                  src={car1}
                  alt="green iguana"
                />
              </Card>
              <Card className={styles.prodImage} sx={{ width: "100%", }}>
                <CardMedia
                  component="img"
                  height="100%"
                  src={car1}
                  alt="green iguana"
                />
              </Card>
              <Card className={styles.prodImage} sx={{ width: "100%", }}>
                <CardMedia
                  component="img"
                  height="100%"
                  src={car1}
                  alt="green iguana"
                />
              </Card>
            </div>
          </Box>
        </Box>
        <Box className={styles.tabpanelsec}>
          {loadingStatus ? <>
            <Box >
              <TabContext value={value} >
                <Box sx={{ border: '1px solid', borderColor: "divider" }}>
                  <TabList variant="fullWidth" onChange={handleChange} aria-label="lab API tabs example"
                    sx={{
                      '& .MuiTabs-indicator': { backgroundColor: 'primary.main' },
                      '& .MuiTab-root': { color: 'primary.main' },
                      '& .Mui-selected': { color: '#fff !important', backgroundColor: 'primary.main' },
                      minHeight: '30px'
                    }}
                  >
                    <Tab sx={{ padding: 0, minHeight: '30px' }} className={styles.tabHeader} label="Numerical Parameter" value="1" />
                    <Tab sx={{ padding: 0, minHeight: '30px' }} className={styles.tabHeader} label="Text Parameter" value="2" />
                  </TabList>
                </Box>
                <TabPanel className={styles.parametSect} value="1" sx={{ padding: '0' }}>
                  <Box className={styles.tabPanel}>
                    <BomNumerical combinationData={combinationData} />
                  </Box>
                </TabPanel>
                <TabPanel value="2" sx={{ padding: '0' }}>
                  <div className={styles.textParameter}>
                    <TableContainer component={Paper}>
                      <Table stickyHeader aria-label="sticky table" sx={{ width: '100%' }}>
                        <TableHead>
                          <TableRow className={styles.headerRow}>
                            <TableCell className={styles.headerRowD}>Parameter</TableCell>
                            <TableCell className={styles.headerRowD} >Vehicle 1</TableCell>
                            <TableCell className={styles.headerRowD} >Vehicle 2</TableCell>
                            <TableCell className={styles.headerRowD} >Vehicle 3</TableCell>
                            <TableCell className={styles.headerRowD} >Vehicle 4</TableCell>
                            <TableCell className={styles.headerRowD} >Vehicle 5</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          <TableRow>
                            <TableCell className={styles.dataCell}>Parameter</TableCell>
                            <TableCell className={styles.dataCell}>Data 1</TableCell>
                            <TableCell className={styles.dataCell}>Data 1</TableCell>
                            <TableCell className={styles.dataCell}>Data 1</TableCell>
                            <TableCell className={styles.dataCell}>Data 1</TableCell>
                            <TableCell className={styles.dataCell}>Data 1</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={styles.dataCell}>Parameter</TableCell>
                            <TableCell className={styles.dataCell}>Data 2</TableCell>
                            <TableCell className={styles.dataCell}>Data 2</TableCell>
                            <TableCell className={styles.dataCell}>Data 2</TableCell>
                            <TableCell className={styles.dataCell}>Data 2</TableCell>
                            <TableCell className={styles.dataCell}>Data 2</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={styles.dataCell}>Parameter</TableCell>
                            <TableCell className={styles.dataCell}>Data 3</TableCell>
                            <TableCell className={styles.dataCell}>Data 3</TableCell>
                            <TableCell className={styles.dataCell}>Data 3</TableCell>
                            <TableCell className={styles.dataCell}>Data 3</TableCell>
                            <TableCell className={styles.dataCell}>Data 3</TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className={styles.dataCell}>Parameter</TableCell>
                            <TableCell className={styles.dataCell}>Data 4</TableCell>
                            <TableCell className={styles.dataCell}>Data 4</TableCell>
                            <TableCell className={styles.dataCell}>Data 4</TableCell>
                            <TableCell className={styles.dataCell}>Data 4</TableCell>
                            <TableCell className={styles.dataCell}>Data 4</TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>

                </TabPanel>
              </TabContext>
            </Box>
            </> 
            : <Skeleton />
            }
        </Box>

      </Box>
    </div>
  );
}
