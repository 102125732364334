import * as React from "react";
import { AllUserListWithGroup } from "./AllUserListWithGroup";
import { userModel } from "./user-model";
import { DragDropContext, DropResult } from "react-beautiful-dnd";
import { API } from "../../api-services";
import {
  Box,
  Checkbox,
  Divider,
  Drawer,
  IconButton,
  Typography,
} from "@mui/material";
import styles from "./UserManagementLanding.module.scss";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import SportsBasketballIcon from "@mui/icons-material/SportsBasketball";
import { AUTH } from "../../Redux/Services/auth.service";
import { useHistory, useRouteMatch } from "react-router-dom";
import PermissionModuleLanding from "../PermissionModule/PermissionModuleLanding";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { useDispatch, useSelector } from "react-redux";
import { setUserDirectoryFilter } from "../../Redux/Actions/PermissionRoute.action";
import { useDocumentTitle } from "../../ui-reusable-component/useDocumentTitle";

type Anchor = "top" | "left" | "bottom" | "right";

export interface IUserManagementLandingProps {}

export function UserManagementLanding() {
  const history = useHistory();
  useDocumentTitle( "User Management" );
  const dispatch = useDispatch();
  const store: any = useSelector((state) => state);
  const { projectId, moduleId, filterOPtion, routeType } =
    useRouteParams<any>();
  const [projectadmin, setProjectadmin] = React.useState<Array<userModel>>([]);
  const [apiCallCount, setApiCallCount] = React.useState<any>(0);
  const [moduleAdmin, setModuleAdmin] = React.useState<Array<userModel>>([]);
  const [superUserList, setSuperUserList] = React.useState<Array<userModel>>(
    []
  );
  const { url } = useRouteMatch();
  const [superUserListStatus, setSuperUserListstatus] = React.useState<
    Array<userModel>
  >([]);
  const [users, setUsers] = React.useState<Array<userModel>>([]);
  const [guestUser, setGuestUser] = React.useState<Array<userModel>>([]);
  const [unapproveUser, setUnapproveUser] = React.useState<Array<userModel>>(
    []
  );
  const [finalFilter, setFinalFilter] = React.useState<any>([]);
  // /auth/user_directory/

  const [projectadminStatus, setProjectadminStatus] = React.useState<any>({});
  const [moduleadminStatus, setModuleadminStatus] = React.useState<any>({});
  const [userStatus, setUserStatus] = React.useState<any>({});
  const [guestuserStatus, setGuestuserStatus] = React.useState<any>({});
  const [unapproveUserStatus, setUnapproveUserStatus] = React.useState<any>({});
  const [selectedOption, setSelectedOption] = React.useState<any>(
    url.includes("user-management") ? 0 : 1
  );
  const [getAllUserDetailsCount, setGetAllUserDetailsCount] =
    React.useState<any>({});
  const [allCount, setAllCount] = React.useState<any>({});
  const [userCountCall, setUserCountCall] = React.useState<any>(0);
  const [unApprovedApiCallCount, setUnApprovedApiCallCount] =
    React.useState<any>(0);
  const [selectedFilterOptions, setSelectedFilterOptions] = React.useState<any>(
    []
  );

  React.useEffect(() => {
    if (
      sessionStorage.getItem("user-management") &&
      sessionStorage.getItem("user-management") !== null
    ) {
      //@ts-ignore
      let arr: any =sessionStorage.getItem("user-management") &&JSON?.parse(sessionStorage.getItem("user-management"));
      setSelectedFilterOptions(arr);
      setFinalFilter(arr);
    } else {
      setSelectedFilterOptions(["Internal", "External", "active", "inactive"]);
    }
  }, [sessionStorage.getItem("user-management")]);
  //@ts-ignore
  const getData = () => {
    switch (filterOPtion) {
      case "all":
        return ["Internal", "External"];
      case "Internal":
        return ["Internal"];
      case "External":
        return ["External"];
    }
  };
  const [selectedFilterOptionsPermission, setSelectedFilterOptionsPermission] =
    React.useState<any>(getData() || ["Internal", "External"]);
  const allcountStatus = () => {
    API.get("/auth/user_directory/", { get_details: "user_details" }, 0)
      .then((res: any) => {
        setAllCount(res.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };
  function arrayEquals(a: any, b: any) {
    return (
      Array.isArray(a) &&
      Array.isArray(b) &&
      a.length === b.length &&
      a.every((val, index) => val === b[index])
    );
  }

  React.useEffect(() => {
    allcountStatus();
  }, []);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toogleOPtionsData = url?.includes("projectSpecific")
    ? [
        { id: 1, name: "User Permission" },
        // { id: 0, name: "User Directory" },
      ]
    : [
        { id: 1, name: "User Permission" },
        { id: 0, name: "User Directory" },
      ];

  const filterOptions: any = [
    {
      id: 0,
      name: "Internal",
      value: "Internal",
    },
    {
      id: 1,
      name: "External",
      value: "External",
    },
    {
      id: 2,
      name: "Active",
      value: "active",
    },
    {
      id: 3,
      name: "Inactive",
      value: "inactive",
    },
  ];
  const userPermissionFilterOptions = [
    {
      id: 4,
      name: "Internal",
      value: "Internal",
    },
    {
      id: 5,
      name: "External",
      value: "External",
    },
  ];

  const getProjectAdminData = () => {
    setProjectadmin([]);
  if(selectedFilterOptions){
    API.get(
      "/auth/user_directory/",
      {
        user_filter: "project_admin",
        user_category: selectedFilterOptions?.toString(),
      },
      0
    ).then((res: any) => {
      setProjectadminStatus(res.data[0]?.active_inactive_status);
      setProjectadmin(res?.data[0]?.user_details);
    });
  }
  };

  // const getModuleAdminData = () => {
  //   API.get(
  //     "/auth/user_directory/",
  //     {
  //       user_filter: "module_admin",
  //       user_category: selectedFilterOptions?.toString(),
  //     },
  //     0
  //   ).then((res: any) => {
  //     setModuleadminStatus(res.data[0]?.active_inactive_status);
  //     setModuleAdmin(res?.data[0]?.user_details);
  //   });
  // };

  const getUserData = () => {
    setUsers([]);
    API.get(
      "/auth/user_directory/",
      {
        user_filter: "user",
        user_category: selectedFilterOptions?.toString(),
      },
      0
    ).then((res: any) => {
      setUserStatus(res.data[0]?.active_inactive_status);
      setUsers(res?.data[0]?.user_details);
    });
  };

  const getGuestuserData = () => {
    setGuestUser([]);
    API.get(
      "/auth/user_directory/",
      {
        user_filter: "guest_user",
        user_category: selectedFilterOptions?.toString(),
      },
      0
    ).then((res: any) => {
      setGuestuserStatus(res.data[0]?.active_inactive_status);
      setGuestUser(res?.data[0]?.user_details);
    });
  };
  const getselectedOptionBackgroundColor = (idd: any) => {
    if (selectedOption === idd) {
      return "primary.main";
    } else {
      return "primary.light";
    }
  };
  const getSelectedOptionTextColor = (idd: any) => {
    if (selectedOption === idd) {
      return "primary.light";
    } else {
      return "primary.main";
    }
  };

  const getUnapproveData = () => {
    setUnapproveUser([]);
    setUnapproveUserStatus({});
    API.get(
      "/auth/user_directory/",
      {
        user_filter: "unapproved_user",
        user_category: selectedFilterOptions?.toString(),
      },
      0
    ).then((res: any) => {
      setUnapproveUserStatus(res.data[0]?.active_inactive_status);
      setUnapproveUser(res?.data[0]?.user_details);
    });
  };
  const getUserType = (type: any) => {
    switch (type) {
      case "projectadmin":
        return "Project Administrator";
      case "moduleadmin":
        return "Module Administrator";
      case "users":
        return "User";
      case "guestusers":
        return "Guest User";
    }
  };
  const getSuperUserList = () => {
    setSuperUserList([]);
    API.get(
      "/auth/user_directory/",
      {
        user_filter: "Superuser",
        user_category: selectedFilterOptions?.toString(),
      },
      0
    ).then((res: any) => {
      setSuperUserListstatus(res.data[0]?.active_inactive_status);
      setSuperUserList(res?.data[0]?.user_details);
    });
  };
  const getAllCountsofUsers = () => {
    API.get(
      "/auth/user_directory/",
      {
        user_category: selectedFilterOptions?.toString(),
        get_details: "get_all_count",
      },
      0
    )
      .then((res: any) => {
        setGetAllUserDetailsCount(res.data);
      })
      .catch(() => {});
  };
  React.useEffect(() => {
    if (!url.includes("user-permissions")) {
      if(selectedFilterOptions.toString()?.length>0) {
        getProjectAdminData();
        // getModuleAdminData();
        getGuestuserData();
  
        getUserData();
        getSuperUserList();
      }
      
    }
  }, [apiCallCount, finalFilter]);
  React.useEffect(() => {
    getUnapproveData();
  }, [unApprovedApiCallCount, finalFilter]);
  React.useEffect(() => {
    getAllCountsofUsers();
  }, [apiCallCount, finalFilter, userCountCall]);

  const onDragEnd = (result: DropResult) => {
    const { destination, source, draggableId } = result;

    if (!destination) {
      return;
    }

    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return;
    }

    let add: any;
    // Source Logic
    if (source.droppableId === "projectadmin") {
      if (destination.droppableId !== "guestusers") {
        add = projectadmin[source.index];
        projectadmin.splice(source.index, 1);
      }
    } else if (source.droppableId === "moduleadmin") {
      if (destination.droppableId !== "guestusers") {
        add = moduleAdmin[source.index];
        moduleAdmin.splice(source.index, 1);
      }
    } else if (source.droppableId === "users") {
      if (destination.droppableId !== "guestusers") {
        add = users[source.index];
        users.splice(source.index, 1);
      }
    } else if (source.droppableId === "guestusers") {
      add = guestUser[source.index];
      guestUser.splice(source.index, 1);
    } else {
      if (destination.droppableId !== "guestusers") {
        add = unapproveUser[source.index];
        unapproveUser.splice(source.index, 1);
      }
    }

    // Destination Logic
    if (destination.droppableId === "projectadmin") {
      projectadmin.splice(destination.index, 0, add);
      API.patch(
        `/auth/user/${draggableId}/`,
        { type: getUserType(destination.droppableId) },
        {},
        0
      )
        .then(() => {
          setUserCountCall((prev: any) => prev + 1);
        })
        .catch((err: any) => {
          console.log(err);
        });
    } else if (destination.droppableId === "moduleadmin") {
      moduleAdmin.splice(destination.index, 0, add);
      API.patch(
        `/auth/user/${draggableId}/`,
        { type: getUserType(destination.droppableId) },
        {},
        0
      )
        .then(() => {
          setUserCountCall((prev: any) => prev + 1);
        })
        .catch((err: any) => {
          console.log(err);
        });
    } else if (destination.droppableId === "users") {
      users.splice(destination.index, 0, add);
      API.patch(
        `/auth/user/${draggableId}/`,
        { type: getUserType(destination.droppableId) },
        {},
        0
      )
        .then(() => {
          setUserCountCall((prev: any) => prev + 1);
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
    // else if (destination.droppableId === "guestusers") {
    //   guestUser.splice(destination.index, 0, add);
    //   API.patch(
    //     `/auth/user/${draggableId}/`,
    //     { type: getUserType(destination.droppableId) },
    //     {},
    //     0
    //   )
    //     .then((res: any) => {})
    //     .catch((err: any) => {
    //       console.log(err);
    //     });
    // }
    else {
      unapproveUser.splice(destination.index, 0, add);
    }

    //   setModuleAdmin(complete);
    //   setProjectadmin(active);
  };

  const getSelectedOption = (id: any) => {
    setSelectedOption(id);
  };

  const getFilterOption = (val: any) => {
    // if(!url.includes("user-permissions")){
    if (selectedFilterOptions?.includes(val)) {
      setSelectedFilterOptions((prev: any) =>
        prev?.filter((x: any) => x !== val)
      );
    } else {
      setSelectedFilterOptions((prev: any) => prev.concat(val));
    }
    // }
  };

  // React.useEffect(()=>{
  //   dispatch(setUserDirectoryFilter(selectedFilterOptions))
  // },[selectedFilterOptions])
  const getFilterOptionPermission = (val: any) => {
    // if(url.includes("user-permissions")){
    if (selectedFilterOptionsPermission?.includes(val)) {
      setSelectedFilterOptionsPermission((prev: any) =>
        prev.filter((x: any) => x !== val)
      );
    } else {
      setSelectedFilterOptionsPermission((prev: any) => prev.concat(val));
    }
  };
  // };

  const backButtonHandler: any = () => {};
  const getDataCount = (name: any) => {
    switch (name) {
      case "Internal":
        return allCount?.internel_active;
      case "External":
        return allCount?.externel_active;
      case "Active":
        return allCount?.active_count;
      case "Inactive":
        return allCount?.inactive_count;
    }
  };

  const getDeactiveCount = (name: any) => {
    switch (name) {
      case "Internal":
        return allCount?.internel_inactive;
      case "External":
        return allCount?.externel_inactive;
      case "Active":
        return "";
      case "Inactive":
        return "";
    }
  };
  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <Box
      sx={{
        minWidth: 300,
        width: anchor === "top" || anchor === "bottom" ? "auto" : "auto",
      }}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box>
        {toogleOPtionsData.map((item, index) => (
          <Box>
            <Box
              sx={{ display: "flex", alignItems: "center", padding: "0 1rem" }}
            >
              <Checkbox
                checked={selectedOption === item?.id ? true : false}
                onClick={() => getSelectedOption(item.id)}
                inputProps={{ "aria-label": "controlled" }}
                sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
              />
              <Box
                onClick={() => getSelectedOption(item.id)}
                key={index}
                className={styles.selectVehBtn}
                sx={{
                  // margin: "0rem 2rem",
                  padding: "0.2rem 0.5rem",
                  backgroundColor: getselectedOptionBackgroundColor(item.id),
                  color: getSelectedOptionTextColor(item.id),
                  textAlign: "center",
                  cursor: "pointer",
                  width: "100%",
                }}
              >
                <Typography
                  style={{
                    fontSize: "1rem",
                    textAlign: "left",
                    display: "flex",
                  }}
                >
                  {item?.name}{" "}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                rowGap: "0.5rem",
                padding: "0 1rem 0 3rem",
                width: "100%",
              }}
            >
              {item.id === 0 &&
                selectedOption === 0 &&
                filterOptions?.map((itm: any) => {
                  return (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        style={{ padding: "3px" }}
                        checked={
                          selectedFilterOptions?.includes(itm.value)
                            ? true
                            : false
                        }
                        onClick={() => getFilterOption(itm.value)}
                        // disabled={url.includes("user-permissions")?true:false}
                        inputProps={{ "aria-label": "controlled" }}
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                      />
                      <Box
                        onClick={() => getFilterOption(itm.value)}
                        key={index}
                        className={styles.selectVehBtn}
                        sx={{
                          // margin: "0rem 2rem",
                          padding: "0.2rem 0.5rem",
                          // backgroundColor: getselectedFilterBackgroundColor(
                          //   itm.value
                          // ),
                          // color: getSelectedFilterTextColor(itm.value),
                          backgroundColor: "#007fff14",
                          color: "primary.main",
                          textAlign: "center",
                          cursor: "pointer",
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          columnGap: "3px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "1rem",
                            // lineHeight: "1.2",
                          }}
                        >
                          {itm?.name}
                        </Typography>
                        <Typography
                          sx={{
                            display: "flex",
                            alignContent: "center",
                            fontSize: "1rem",
                            // lineHeight: "1.2",
                          }}
                        >
                          (
                          <Typography
                            sx={{
                              fontSize: "1rem",
                              color:
                                itm?.name === "Inactive" ? "red" : "#5bad06",
                              // lineHeight: "1.2",
                            }}
                          >
                            {getDataCount(itm?.name)}
                          </Typography>
                          {getDeactiveCount(itm?.name) !== "" ? "," : ""}
                          <Typography
                            sx={{
                              fontSize: "1rem",
                              color: "red",
                              // lineHeight: "1.2",
                            }}
                          >
                            {getDeactiveCount(itm?.name)}
                          </Typography>
                          )
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
              {item.id === 1 &&
                selectedOption === 1 &&
                userPermissionFilterOptions?.map((itm: any) => {
                  return (
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <Checkbox
                        style={{ padding: "3px" }}
                        checked={
                          selectedFilterOptionsPermission.includes(itm.value)
                            ? true
                            : false
                        }
                        onClick={() => getFilterOptionPermission(itm.value)}
                        inputProps={{ "aria-label": "controlled" }}
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                        // disabled={url?.includes("user-permissions")?false:true}
                      />
                      <Box
                        onClick={() => getFilterOptionPermission(itm.value)}
                        key={index}
                        className={styles.selectVehBtn}
                        sx={{
                          // margin: "0rem 2rem",
                          padding: "0.2rem 0.5rem",
                          // backgroundColor: getselectedFilterBackgroundColor(
                          //   itm.value
                          // ),
                          // color: getSelectedFilterTextColor(itm.value),
                          backgroundColor: "#007fff14",
                          color: "primary.main",
                          textAlign: "center",
                          cursor: "pointer",
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          columnGap: "3px",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: "1rem",
                            // lineHeight: "1.2",
                          }}
                        >
                          {itm?.name}
                        </Typography>
                      </Box>
                    </Box>
                  );
                })}
            </Box>
            <Box
              sx={{
                width: "100%",
                textAlign: "right",
                margin: "1rem 0",
                padding: "0 1rem",
              }}
            >
              {item.id === selectedOption && (
                <span onClick={toggleDrawer(anchor, false)}>
                  <Button
                    size="small"
                    variant="contained"
                    onClick={() => {
                      setSuperUserList([]);
                      setUnapproveUser([]);
                      setGuestUser([]);
                      setUsers([]);
                      setProjectadmin([]);
                      sessionStorage.setItem(
                        "user-management",
                        JSON.stringify(selectedFilterOptions)
                      );
                      if (selectedOption === 0) {
                        history.push("/user-management");
                        // setFinalFilter(selectedFilterOptions);
                        dispatch(setUserDirectoryFilter(selectedFilterOptions));

                        setUnapproveUserStatus({});
                      } else {
                        if (url.includes("user-management")) {
                          if (
                            arrayEquals(selectedFilterOptionsPermission, [
                              "Internal",
                              "External",
                            ]) === true
                          ) {
                            history.push(
                              "/user-permissions/Gen/0/all/allModule"
                            );
                          } else if (
                            arrayEquals(selectedFilterOptionsPermission, [
                              "Internal",
                            ]) === true
                          ) {
                            history.push(
                              "/user-permissions/Gen/0/Internal/allModule"
                            );
                          } else if (
                            arrayEquals(selectedFilterOptionsPermission, [
                              "External",
                            ]) === true
                          ) {
                            history.push(
                              "/user-permissions/Gen/0/External/allModule"
                            );
                          }
                        } else {
                          
                          if (
                            arrayEquals(selectedFilterOptionsPermission, [
                              "External",
                              "Internal",
                            ]) === true
                          ) {
                           
                            history.push(
                              `/user-permissions/${projectId || "Gen"}/${
                                moduleId || 0
                              }/${"all"}/${routeType || "allModule"}`
                            );
                          } else if (
                            arrayEquals(selectedFilterOptionsPermission, [
                              "Internal",
                            ]) === true
                          ) {
                            history.push(
                              `/user-permissions/${projectId || "Gen"}/${
                                moduleId || 0
                              }/${"Internal"}/${routeType || "allModule"}`
                            );
                          } else if (
                            arrayEquals(selectedFilterOptionsPermission, [
                              "External",
                            ]) === true
                          ) {
                            history.push(
                              `/user-permissions/${projectId || "Gen"}/${
                                moduleId || 0
                              }/${"External"}/${routeType || "allModule"}`
                            );
                          }
                        }
                      }
                      // toggleDrawer(anchor, false)
                    }}
                  >
                    Ok
                  </Button>
                </span>
              )}
            </Box>
          </Box>
        ))}
      </Box>
    </Box>
  );

  return (
    <Box
      sx={{
        display: "flex",
        // backgroundImage: "linear-gradient(to right, #ffffff, aliceblue)"
      }}
    >
      <Box>
        <Box sx={{ width: "3rem", padding: "0 0.5rem" }}>
          <IconButton sx={{ cursor: "pointer" }} onClick={backButtonHandler}>
            <ArrowBackIcon
              color="primary"
              titleAccess="Go Back"
              onClick={() => {
                if (AUTH.user_category === "Internal") {
                  history.push("/internallanding");
                } else {
                  history.push(`/landing`);
                }
              }}
            />
          </IconButton>
        </Box>
        <Box className={styles.expandIconBox}>
          <div className={styles.iconSec}>
            {(["left"] as const).map((anchor) => (
              <React.Fragment key={anchor}>
                <IconButton
                  size="medium"
                  sx={{
                    backgroundColor: "primary.light",
                    color: "primary.main",
                  }}
                  onClick={toggleDrawer(anchor, true)}
                >
                  <AddIcon sx={{ fontSize: "1.7rem" }} />
                </IconButton>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  className={styles.drawerContainer}
                  PaperProps={{
                    sx: {
                      backgroundColor: "aliceblue",
                    },
                  }}
                >
                  <Box
                    sx={{ padding: "0.5rem 0", position: "sticky", top: "0" }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3"
                  >
                    <Typography
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "1rem",
                      }}
                    >
                      Selection Options
                    </Typography>
                    <Divider sx={{ margin: "0.2rem" }} />
                    {list(anchor)}
                  </Box>
                </Drawer>
                <Typography
                  sx={{ fontSize: "1rem", color: "primary.main" }}
                  onClick={toggleDrawer(anchor, true)}
                  className={styles.sideTitle}
                >
                  Selection Options
                </Typography>
              </React.Fragment>
            ))}
          </div>
        </Box>
        {/* PermissionModuleLanding */}
      </Box>
      {url.includes("user-management") ? (
        <Box>
          <DragDropContext onDragEnd={onDragEnd}>
            <div>
              <AllUserListWithGroup
                projectadmin={projectadmin && projectadmin}
                setModuleAdmin={setModuleAdmin}
                moduleAdmin={moduleAdmin && moduleAdmin}
                users={users && users}
                guestUser={guestUser && guestUser}
                unapproveUser={unapproveUser && unapproveUser}
                projectadminStatus={projectadminStatus}
                moduleadminStatus={moduleadminStatus}
                usersStatus={userStatus}
                guestUserStatus={guestuserStatus}
                unapproveUserStatus={unapproveUserStatus}
                setApiCallCount={setApiCallCount}
                superUserList={superUserList}
                superUserStatus={superUserListStatus}
                getAllUserDetailsCount={
                  Object.keys(getAllUserDetailsCount)?.length > 0 &&
                  getAllUserDetailsCount
                }
                setUnApprovedApiCallCount={setUnApprovedApiCallCount}
              />
            </div>
          </DragDropContext>
        </Box>
      ) : (
        <PermissionModuleLanding />
      )}
    </Box>
  );
}
