import { createEntityAdapter, createReducer, createSelector, EntityState } from "@reduxjs/toolkit";
import { createGroupsByType, deleteGroupsByType, fetchGroupsByType, updateGroupsByType } from "../Actions/groupingMediaCreate.action";
import { ADMIN } from "../Services/admin.service";
import { RootState } from "../Store/storeConfigurations";
import { ID } from "../../utlis/commonInterfaces";

// -----------------------------------------------------------------------------------
// entity adaptor
export interface groupingImage {
    id: ID;
    name: string;
    abbreviation: string;
    projectId: ID;
    media_type?: any;
    topVault: any;
    image_count?:any;
    max_sequence?:any;
}
const adaptor = createEntityAdapter<groupingImage>( {
    selectId: ( imagegroups ) => imagegroups.id,
} );
// -----------------------------------------------------------------------------------
// interfaces & initialState
type AdditionalFields = {};
type IgroupingMediaCreateReducer = EntityState<groupingImage> & AdditionalFields;
const additionalFields: AdditionalFields = {};
const initialState: IgroupingMediaCreateReducer = Object.assign( {}, adaptor.getInitialState(), additionalFields );
// -----------------------------------------------------------------------------------
// reducer
const groupingMediaCreateReducer = createReducer( initialState, builder => {
    // get
    builder
        .addCase( fetchGroupsByType.fulfilled, ( state, action: any ) => {
            adaptor.setAll( state, action.payload.Imagegroups );
        } )
        .addCase( fetchGroupsByType.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could not fetch Group" );
        } );

    //create
    builder
        .addCase( createGroupsByType.fulfilled, ( state, action: any ) => {
            adaptor.addOne( state, action.payload.Imagegroups );
            // ADMIN.toast.success( "Group created" );
        } )
        .addCase( createGroupsByType.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could not create Group" );
        } );

    // update
    builder
        .addCase( updateGroupsByType.fulfilled, ( state, action: any ) => {
            adaptor.upsertOne( state, action.payload.Imagegroups );

            // ADMIN.toast.success( "Group updated" );
        } )
        .addCase( updateGroupsByType.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could not update group" );
        } );

    //delete
    builder
        .addCase( deleteGroupsByType.fulfilled, ( state, action: any ) => {
            const { id } = action.meta.arg;
            adaptor.removeOne( state, id );
            // ADMIN.toast.success( "Deleted Successfully" );
        } )
        .addCase( deleteGroupsByType.rejected, ( state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could not delete Group" );
        } );

} );

// selectors
const mediaCreateGroupsSelector = adaptor.getSelectors<RootState>( ( state ) => state.mediaCreateGrouping );
const byAbbreviationSelectors = Object.assign(
    {},
    mediaCreateGroupsSelector ,

);

// -----------------------------------------------------------------------------------
// exports

export {
    groupingMediaCreateReducer,
    initialState as groupingMediaCreateReducerInit,
    byAbbreviationSelectors
};