import { useState } from "react";

// ----------------------------------------------------------------------------------

const useBomCategoryEditModel = () => {
    const [isOpen, setIsOpen] = useState( false );
    const [id,setId] = useState<any>();
    const [categoryName, setCategoryName] = useState<any>();
    const [prefixName, setPrefixName] = useState<any>();
    const [postfixName, setPostfixName] = useState<any>();
    const [colorSelect, setColorSelect] = useState<any>();
    const [isLeafSelect, setIsLeafSelect] = useState<any>();

    const handleOpen = (id:any,name:any,prefix:any,postfix:any,color:any,is_leaf:any) => {
        setIsOpen( true );
        setId(id);
        setCategoryName(name);
        setPrefixName(prefix);
        setPostfixName(postfix);
        setColorSelect(color);
        setIsLeafSelect(is_leaf);
    }
    const handleClose = () => {
        setIsOpen( false );
        setId(undefined);
        setCategoryName(undefined);
        setPrefixName(undefined);
        setPostfixName(undefined);
        setColorSelect(undefined);
        setIsLeafSelect(undefined);
    }

    return Object.freeze( {
        open: handleOpen,
        close: handleClose,
        isOpen,
        id,
        categoryName,
        prefixName,
        postfixName,
        colorSelect,
        isLeafSelect
    } );
};


// ----------------------------------------------------------------------------------

export { useBomCategoryEditModel };