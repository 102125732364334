import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Autocomplete, Chip, Divider, FormControl, InputLabel, TextField } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { LoadingButton } from '@mui/lab';
import { API } from '../../Redux/Services/index.service';
import sucessIcon from "../../Assets/images/success.png";
import ErrorModalFilesMail from '../../ComponentUI/ProjectMailModule/ErrorModalFilesMail';
import { useBaseModal } from '../../ComponentUI/SearchPage/useBaseModal';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "40vw",
  bgcolor: 'background.paper',
  borderRadius: '10px',
  p: 1,
};

interface ShareCategoryModalProps {

  isOpen: any;
  ClosedHandler: any;
  CategoryDatalist: any;
  projectIds: any;


}

const ShareCategoryModal = (props: ShareCategoryModalProps) => {
  const { isOpen, ClosedHandler, CategoryDatalist, projectIds } = props;

  const [Selectedcategory, setSelectedcategory] = useState<any>({})
  const [Selectedproject, setSelectedproject] = useState<any>({})
  const [Selectedparamter, setSelectedparamer] = useState<any>([])
  const [loader, setloader] = useState<any>(false)
  const [CategoryList, setCategorylist] = useState<any>()
  const [PojectList, setPojectList] = useState<any>([])
  const [Projectlength, setProjectLength] = useState<any>([])
  const [lengthCategory, setCategorylength] = useState<any>([])
  const [sucessLoader, setSucessLoader] = useState<any>(false)
  const ErrorModalFiles = useBaseModal()
  const [ErrorModalFileMsg, setErrorModalFileMsg] = useState<any>();
  const handleSelectcategory = (item: any) => {
    setSelectedcategory(item)
    setCategorylength(item == null ? [] : [item])



  }

  const handleSelectProject = (item: any) => {
    setSelectedproject(item)
    setProjectLength(item == null ? [] : [item])

  }

  useEffect(() => {

    setSucessLoader(false)
    API.get("/xcpep/project/", { module: 6 }).then((res: any) => {
      var filterProject = res?.data?.filter((item: any, index: any) => { return item?.id !== projectIds })

      var data = filterProject && filterProject.map((item: any, index: any) => ({ name: item?.name, id: item?.id }))


      setPojectList(data)
    }).catch((err: any) => {
      console.log("server error")
    })
  }, [projectIds])

  // console.log("PojectListPojectList", (lengthCategory?.length > 0 && Projectlength?.length > 0))
  const saveHandler = () => {
    setloader(true)
    API.post("/config/share_feature/", {


      category: Selectedcategory && Selectedcategory?.category.toString(),
      module: 17,
      source: projectIds,
      target_project: Selectedproject && Selectedproject?.id,
      type: 4
    }).then((res: any) => {
      setSucessLoader(true)
      setloader(false)
    }).catch((err: any) => {

      const { data } = err?.response;

      console.log(data[0], "datadatadata")
      ErrorModalFiles.open();

      setErrorModalFileMsg(data[0]);
      setloader(false)
    })


  }
  return (
    <div><Modal
      open={isOpen}
      keepMounted
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {sucessLoader ?
        <Box sx={style}>

          {/* <Typography>Share Category</Typography>
          <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={ClosedHandler} /> */}
          <Box sx={{ textAlign: 'center', }} >
            <img src={sucessIcon} style={{ width: '7rem' }} />
            <Typography sx={{ color: "green", marginTop: '1rem' }}>Shared Successfully</Typography>
            <Button sx={{ marginTop: '1rem' }} variant='contained' size='small' onClick={ClosedHandler}>ok</Button>
          </Box>
        </Box>

        :
        <Box sx={style}>

          <ErrorModalFilesMail
            isOpen={ErrorModalFiles.isOpen}
            onCloseModal={ErrorModalFiles.close}
            ErrorMsg={ErrorModalFileMsg}
          />
          <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: '-2rem'
              }}
            >
              Share Category
            </Typography>
            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={ClosedHandler} />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ width: '100%', padding: '0 1rem 1rem' }}>
            <Box sx={{ width: '100%', display: 'flex', columnGap: '1rem', alignItems: 'center', }}>
              <FormControl fullWidth>
                <InputLabel sx={{ color: 'primary.main', marginLeft: '-1rem' }} shrink>Select Project</InputLabel>
                <Autocomplete
                  //@ts-ignore
                  options={PojectList && PojectList}
                  //@ts-ignore

                  getOptionLabel={(option) =>

                    option?.name == undefined ? "" :
                      option?.name
                  }
                  // defaultValue={[top100Films[13], top100Films[12], top100Films[11]]}
                  sx={{
                    marginTop: '0.5rem',
                    '.MuiSvgIcon-root': { color: 'primary.main' },
                  }}
                  value={Selectedproject && Selectedproject}
                  onChange={(event, newValue, situation) =>
                    handleSelectProject(newValue)
                  }

                  // @ts-ignore

                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip
                        size="small"
                        sx={{ backgroundColor: "#007fff14", fontSize: "1rem" }}
                        //@ts-ignore
                        label={option?.name}
                        {...getTagProps({ index })}
                      // disabled={fixedOptions.indexOf(option) !== -1}
                      />
                    ))
                  }
                  renderOption={(props, option, { selected }) => (
                    <li style={{ fontSize: "1rem" }} {...props}>
                      {/*@ts-ignore */}
                      {option?.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // @ts-ignore 
                      // placeholder={"Select Project"}
                      variant="standard"
                      sx={{
                        width: '100%',
                        fontSize: "1rem",
                        ".MuiInputBase-root.MuiInput-root": {
                          fontSize: '1rem !important'
                        },
                        '& .MuiInput-underline:before': { borderBottomColor: 'primary.main' },
                        '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                      }}
                    />
                  )}
                />
              </FormControl>
              <FormControl fullWidth>
                <InputLabel sx={{ color: 'primary.main', marginLeft: '-1rem' }} shrink>Select Cateogry</InputLabel>
                <Autocomplete
                  //@ts-ignore
                  options={CategoryDatalist && CategoryDatalist}
                  //@ts-ignore

                  getOptionLabel={(option) =>

                    option?.name == undefined ? "" :
                      option?.name
                  }
                  // defaultValue={[top100Films[13], top100Films[12], top100Films[11]]}
                  sx={{
                    marginTop: '0.5rem',
                    '.MuiSvgIcon-root': { color: 'primary.main' },
                  }}
                  value={Selectedcategory}
                  onChange={(event, newValue, situation) =>
                    handleSelectcategory(newValue)
                  }

                  // @ts-ignore

                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip
                        size="small"
                        sx={{ backgroundColor: "#007fff14", fontSize: "1rem" }}
                        //@ts-ignore
                        label={option?.name}
                        {...getTagProps({ index })}
                      // disabled={fixedOptions.indexOf(option) !== -1}
                      />
                    ))
                  }
                  renderOption={(props, option, { selected }) => (
                    <li style={{ fontSize: "1rem" }} {...props}>
                      {/*@ts-ignore */}
                      {option?.name}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // @ts-ignore 
                      // placeholder={"Select Cateogry"}
                      variant="standard"
                      sx={{
                        width: '100%',
                        fontSize: "1rem",
                        ".MuiInputBase-root.MuiInput-root": {
                          fontSize: '1rem !important'
                        },
                        '& .MuiInput-underline:before': { borderBottomColor: 'primary.main' },
                        '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                      }}
                    />
                  )}
                />
              </FormControl>
            </Box>
            <Box sx={{ width: '100%', textAlign: 'right', padding: '0 1rem' }}>
              {(lengthCategory?.length > 0 && Projectlength?.length > 0) &&
                <LoadingButton size="small"
                  variant="contained"
                  sx={{
                    padding: "0.5rem",
                    lineHeight: 1, 
                    marginTop:'1rem',                   
                    "&:hover": {
                      transform: 'Scale(1.05)',
                      transition: 'transform 0.5s ease',
                    },  
                    '.MuiCircularProgress-root': {
                      color: 'primary.main',
                    },                 
                  }}
                  loading={loader}
                   onClick={() => saveHandler()}>
                  Share
                </LoadingButton>}
            </Box>
          </Box>
        </Box>
      }


    </Modal></div>
  )
}
export default ShareCategoryModal;