import React, { useEffect, useRef, useState } from "react";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { usePdf } from "@mikecousins/react-pdf";
import styles from "./../EIRandWorkshop/Workshoppdfmodule.module.scss";
import { Box, IconButton, Skeleton, Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import ZoomInMapIcon from '@mui/icons-material/ZoomInMap';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import DownloadIcon from '@mui/icons-material/Download';
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { LoadingButton } from "@mui/lab";

interface Params{
  projectId: string;
  fileIndex: any;
  pageCount: any;
  projectname:any;
  abbreviation:any;
}
interface BannerpdfmoduleProps {
  file_paths_read: any;
  page: number;
  pdfName?: any;
  setIsExpand?: any;
  isExpand?: any;
  pdfDataLength?:any;
  pdfInfo?:any;
}

const Bannerpdfmodule: React.FC<BannerpdfmoduleProps> = (props) => {
  const { file_paths_read, page, pdfName, setIsExpand, isExpand,pdfDataLength,pdfInfo } = props;
  const { projectId, fileIndex, pageCount,projectname,abbreviation } = useRouteParams<Params>();
  const [loader, setLoader] = useState<boolean>(false);
  const [downloadLoading,setDownloadLoading] = useState<boolean>(false);
  const [verificationCheck,setVerificationCheck] = useState<boolean>();

  const history = useHistory();

  useEffect(()=>{
    if(pdfInfo){
   setVerificationCheck(pdfInfo.is_validated);}
  },[pdfInfo])

  const handleOpen = () => setIsExpand(true);
  const handleClose = () => setIsExpand(false);

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const onDocumentLoadSuccess = (data: any) => {
    setLoader(true);
  };

  const onDocumentLoadFail = () => {
    setLoader(false);
  };

  useEffect(()=>{setLoader(false);},[fileIndex])

  const downloadClicked = () => {
    setDownloadLoading(true)
        fetch(file_paths_read, {
          method: 'GET'
        }).then(resp => resp.blob())
          .then(async(blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = pdfName; // the filename you want
            await document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
            setDownloadLoading(false)
          })
  }

  const handleNext = () => {
      history.push(`/banner/${projectId}/${projectname}/${abbreviation}/${Number(fileIndex) + 1}/0`);
  };

  const handlePrev = () => {
      history.push(`/banner/${projectId}/${projectname}/${abbreviation}/${Number(fileIndex) - 1}/0`);
  };

  const { pdfDocument, pdfPage } = usePdf({
    file:
      file_paths_read && file_paths_read != undefined
        ? file_paths_read
        : "https://arxiv.org/pdf/quant-ph/0410100.pdf",
    page,
    canvasRef,
    onDocumentLoadFail,
    onDocumentLoadSuccess,
  });

  return (
    <>
      <Box className={styles.expandBanner} >
        <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem' }}>
          <Box className={styles.navBar}>
            <IconButton
              size="small"
              onClick={handlePrev}
              sx={{ color: 'primary.main' }}
              style={Number(fileIndex) == 1 ? { cursor: "not-allowed" } : { cursor: "pointer", color: '#007fff' }}
              disabled={Number(fileIndex) === 1}
            >
              <ChevronLeftIcon
                titleAccess="Previous"
              />
            </IconButton>

            <Box className={styles.right_arrow_icon}
            >
              <Typography sx={{ fontSize: "1rem", textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                {pdfName}
              </Typography>
            </Box>

            <IconButton
              size="small"
              sx={Number(fileIndex)==pdfDataLength ? { cursor: "not-allowed" } : { cursor: "pointer", color: '#007fff' }}
              onClick={handleNext}
              disabled={Number(fileIndex)==pdfDataLength}
            >
              <ChevronRightIcon
                style={{
                  cursor: "pointer",
                }}
                titleAccess="Next"
              />
            </IconButton>
          </Box>
          <LoadingButton 
          loading={downloadLoading}
          onClick={downloadClicked} title="Download" sx={{p:0,m:0}}>
          <DownloadIcon sx={{
            cursor:'pointer',
            "&:hover": {
              transform: 'Scale(1.05)',
              transition: 'transform 0.5s ease',
              color:'#007fff'
            }
          }}
          />
          </LoadingButton>
          {isExpand == true ? <ZoomInMapIcon onClick={handleClose} sx={{
            cursor: 'pointer', "&:hover": {
              transform: 'Scale(1.05)',
              transition: 'transform 0.5s ease',
            }
          }} /> : <ZoomOutMapIcon onClick={handleOpen} sx={{
            cursor: 'pointer', "&:hover": {
              transform: 'Scale(1.05)',
              transition: 'transform 0.5s ease',
            }
          }} />}
        </Box>
      </Box>
      <Box sx={!pdfDocument ? {textAlign: "center"} : { textAlign: "center", height: { lg: '85vh', xl: '87vh' } }}
        adl-scrollbar="true"
        adl-scrollbar-width="0.3">
        {loader ? 
          <canvas
            ref={canvasRef}
            id={isExpand == true ? "canvasexpand" : "canvas"}
            title={pdfName}
            width="20"
            height="20"
          />
          : 
            <Skeleton variant="rectangular" width="100%" height="96%"
              sx={{ padding:'0 1rem', margin: "0 auto",}}
            />}
          {!pdfDocument && <Skeleton variant="rectangular" width="100%" height="96%"
                  sx={{ padding:'1rem 1rem', margin: "0 auto", position:'absolute',top:'13.2vh' }}
                />}
      </Box>
    </>
  );
};

// ----------------------------------------------------------------------------------

export default Bannerpdfmodule;
