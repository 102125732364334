import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import {
	Autocomplete,
	Chip,
	Divider,
	FormControl,
	FormLabel,
	Input,
	InputLabel,
	MenuItem,
	Select,
	SelectChangeEvent,
	Skeleton,
	TextField,
	TextareaAutosize,
	Typography,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { LoadingButton } from '@mui/lab';

import styles from '../../../DataBaseModule/Suppliers/AddSupplierModel.module.scss';
import Swal from 'sweetalert2';
import WarningIcon from '../../Assets/images/Warning.png';
import { API } from '../../../api-services';
import uploadS3Supplier from '../../../DataBaseModule/Suppliers/uploadS3Supplier';
import PlantModal from '../../ProjectDirectory/PlantModal';
import ErrorModalFilesMail from '../../ProjectMailModule/ErrorModalFilesMail';
import { useBaseModal } from '../../SearchPage/useBaseModal';
import PhoneInput from 'react-phone-input-2';
import { useRouteParams } from '../../../ui-reusable-component/useRouteParams';

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: ' 40vw',
	height: '85vh',
	bgcolor: 'background.paper',
	borderRadius: '10px',
	p: 1,
	zIndex: 0,
};

interface AddSupplierModelProps {
	onCloseModalParent: any;
	tab: any;
}

const AddSupplierDB = (props: AddSupplierModelProps) => {
	//   const { isOpen, onCloseModal, supplierDataApiCall, setSupplierDataApiCall ,useraction} = props;
	const { onCloseModalParent, tab } = props;
	const { topVault, vault } = useRouteParams<any>();
	const [supplierName, setSupplierName] = useState<any>();

	const [commodityName, setCommodityName] = useState<any>([]);
	const [productCategory, setProductCategory] = useState<any>([]);
	const [systems, setSystems] = useState<any>([]);

	const [logo, setLogo] = useState<any>();

	const [supplierType, setSupplierType] = useState<any>();
	const [certification, setCertification] = useState<any>();
	const [aboutUsName, setAboutUsName] = useState<any>();
	const [contactUsName, setContactUsName] = useState<any>();
	const [contactNumber, setContactNumber] = useState<any>();
	const [contactDialCode, setContactDialCode] = useState<any>();
	const [spocName, setSpocName] = useState<any>();
	const [spocEmail, setSpocEmail] = useState<any>();

	const [productCategoryData, setProductCategoryData] = useState<any>();
	const [commodityData, setCommodityData] = useState<any>();
	const [systemsData, setSystemsData] = useState<any>();

	const [error, setError] = useState<any>(false);
	const [loader, setLoader] = useState<any>(false);

	const [productCategoryLoader, setProductCategoryLoader] =
		useState<boolean>(true);
	const [commodityLoader, setCommodityLoader] = useState<boolean>(true);
	const [systemsLoader, setSystemsLoader] = useState<boolean>(true);
	const [ErrorModalFileMsg, setErrorModalFileMsg] = useState<any>();
	const [supplierDataApiCall, setSupplierDataApiCall] = useState<any>(0);
	const [autoSelectData, setAutoSelectData] = React.useState<any>({})

	const [rowIds, setrowIds] = useState<any>();
	const plantpopup = useBaseModal();
	const ErrorModalFiles = useBaseModal();

	const getData = () => {
		API.get("/api/db/sub_system/", {
			top_vault: +topVault,
			vault: vault != 0 ? +vault : undefined
		}, 0).then((res: any) => {
			setAutoSelectData(res.data[0]);
			setProductCategory([res.data[0].type])
			setSystems([res.data[0].id])
		}).catch((err: any) => {

		})
	}

	React.useEffect(() => {
		if (tab === 1) {
			getData();
		}
	}, [tab])

	useEffect(() => {
		setSupplierName('');
		setAboutUsName('');
		setContactUsName('');
		setContactNumber('');
		setContactDialCode('');
		setSpocName('');
		setSpocEmail('');
		setCommodityName([]);
		setLogo(undefined);
		setSupplierType(undefined);
		setCertification(undefined);
	}, []);

	useEffect(() => {
		setProductCategoryLoader(true);
		setCommodityLoader(true);
		API.get('/api/db/vehicle_type/', {}, 0).then((res: any) => {
			setProductCategoryLoader(false);
			let productCategory: any = [];
			const productCategoryLoop = res?.data?.map((item: any) => {
				productCategory.push({
					id: item?.id,
					name: item?.name,
					abbreviation: item?.abbreviation,
				});
			});
			setProductCategoryData(productCategory);
			setProductCategoryLoader(false);
		});

		API.get('/api/db/commodity/', {}, 0).then((res: any) => {
			let commodity: any = [];
			const commodityLoop = res?.data?.map((item: any) => {
				commodity.push({
					id: item?.id,
					name: item?.name,
					value: item?.name,
				});
			});
			setCommodityData(commodity);
			setCommodityLoader(false);
		});
	}, []);

	useEffect(() => {
		setSystemsLoader(true);
		setSystems([]);
		setSystemsData(undefined);
		if (productCategory?.length > 0) {
			let productCategoryList: any = productCategory?.map((item: any) => {
				return item?.id;
			});
			API.get(
				'/api/db/sub_system/',
				{ vehicle_type: productCategoryList?.toString() },
				0
			).then((res: any) => {
				let system: any = [];
				const systemLoop = res?.data?.map((item: any) => {
					system.push({
						id: item?.id,
						name: item?.abbreviation,
						abbreviation: item?.vehicle_abbreviation,
					});
				});
				setSystemsData(system);
				setSystemsLoader(false);
			});
		}
	}, [productCategory]);

	const handleSupplierName: any = (e: any) => {
		setSupplierName(e.target.value);
	};
	const handleAboutUsName: any = (e: any) => {
		setAboutUsName(e.target.value);
	};
	const handleContactUsName: any = (e: any) => {
		setContactUsName(e.target.value);
	};
	const handleContactNumber: any = (e: any) => {
		setContactNumber(e.target.value);
	};
	const handleSpocName: any = (e: any) => {
		setSpocName(e.target.value);
	};
	const handleSpocEmail: any = (e: any) => {
		setSpocEmail(e.target.value);
	};

	// const handleCommodityName: any = (e: any) => {
	//   setCommodityName(e.target.value);
	// }

	// const handleSystems: any = (e: any) => {
	//   setSystems(e.target.value);
	// }

	const handleSupplierType: any = (e: any) => {
		setSupplierType(e.target.value);
	};

	const handleCertification = (event: any) => {
		setCertification(event.target.value);
	};

	let commodityListOption = () => {
		let commodityId: any =
			commodityName && commodityName?.map((itm: any) => itm.id);
		return (
			commodityData &&
			commodityData?.filter((item: any) => ![...commodityId]?.includes(item.id))
		);
	};

	const handleSelectChange = (commodityId: any) => {
		// @ts-ignore
		setCommodityName([...commodityId]);
	};

	let productCategoryListOption = () => {
		let productCategoryId: any =
			productCategory && productCategory?.map((itm: any) => itm.id);
		return (
			productCategoryData &&
			productCategoryData?.filter(
				(item: any) => ![...productCategoryId]?.includes(item.id)
			)
		);
	};

	const handleSelectProductCategoryChange = (productCategoryId: any) => {
		// @ts-ignore
		setProductCategory([...productCategoryId]);
	};

	let systemsListOption = () => {
		let systemId: any = systems && systems?.map((itm: any) => itm.id);
		return (
			systemsData &&
			systemsData?.filter((item: any) => ![...systemId]?.includes(item.id))
		);
	};

	const handleSelectSystemsChange = (systemsId: any) => {
		// @ts-ignore
		setSystems([...systemsId]);
	};

	const handleChange = (e: any) => {
		setLogo(e.currentTarget.files);
	};

	const submitBomCategoryDetails = async () => {
		if (
			supplierName == '' ||
			commodityName?.length == 0 ||
			aboutUsName == '' ||
			contactUsName == '' ||
			supplierType == undefined
		) {
			setError(true);
		} else {
			setLoader(true);
			if (logo) {
				await uploadS3Supplier(
					commodityName?.map((item: any) => item?.id),
					contactUsName,
					spocName,
					spocEmail,
					supplierName,
					[autoSelectData?.id],
					aboutUsName,
					productCategory,
					contactNumber,
					contactDialCode,
					supplierType,
					certification,
					logo,
					setLoader,
					setSupplierDataApiCall,
					supplierDataApiCall,
					onCloseModalParent,
					'logo-supplierDatabase',
					'addSupplier',
					1, // dummy id passed
					setrowIds,
					plantpopup,
					ErrorModalFiles,
					setErrorModalFileMsg
				);
			} else {
				API.post('/api/db/supplier/', {
					commodity: commodityName?.map((item: any) => item?.id),
					contact_info: contactUsName,
					spoc_name: spocName,
					is_oem: false,
					spoc_email: spocEmail,
					miscellaneous: '',
					name: supplierName,
					other_mfg_process: {},
					plants: {},
					subsystem: [autoSelectData?.id],
					supplier_details: aboutUsName,
					supplier_id: -1,
					vehicle_type: productCategory,
					contact: contactNumber,
					dialCode: contactDialCode,
					supplier_type: supplierType,
					certification: certification,
				})
					.then((res: any) => {
						setLoader(false);
						setrowIds(res?.data?.id);
						plantpopup.open();
					})
					.catch((err) => {
						setLoader(false);
						setErrorModalFileMsg(err.response.data);
						ErrorModalFiles.open();
					});
			}
		}
	};

	const phoneNumberSave: any = (number: any, basicDiscription: any) => {
		setContactNumber(number.substring(basicDiscription.dialCode.length));
		setContactDialCode(`+${basicDiscription.dialCode}`);
	};

	return (
		<div>
			<>
				<ErrorModalFilesMail
					isOpen={ErrorModalFiles.isOpen}
					onCloseModal={ErrorModalFiles.close}
					ErrorMsg={ErrorModalFileMsg}
				/>
				<PlantModal
					key={rowIds}
					useraction={['CRUD']}
					onCloseModal={plantpopup.close}
					isOpen={plantpopup.isOpen}
					rowIds={rowIds}
					supplierDataApiCall={supplierDataApiCall}
					setSupplierDataApiCall={setSupplierDataApiCall}
					onCloseModalSupplier={onCloseModalParent}
				/>

				<Box
					sx={{
						height: { lg: '68vh', xl: '72vh' },
						padding: '0 0.5rem 1rem',
						width: '100%',
					}}
					adl-scrollbar='true'
					adl-scrollbar-width='0.3'>
					<FormControl fullWidth sx={{ marginBottom: '0.5rem' }}>
						<TextField
							autoFocus
							variant='standard'
							InputProps={{
								style: { color: '#007fff', fontSize: '1rem' },
							}}
							InputLabelProps={{
								shrink: true,
								sx: { fontSize: '1rem' },
							}}
							value={supplierName}
							onChange={handleSupplierName}
							sx={{
								width: '100%',
								'& .MuiFormLabel-root': {
									color: 'primary.main',
								},
								'& .MuiFormLabel-root.Mui-focused': {
									color: 'primary.main',
								},
								'& .Mui-focused': {
									color: 'primary.main',
								},
								'& .Mui-error': {
									color: '#d32f2f',
									borderBottomColor: '#d32f2f',
								},
								'& .MuiInput-underline:before': {
									borderBottomColor: 'primary.light',
								},
								'& .MuiInput-underline:after': {
									borderBottomColor: 'primary.main',
								},
							}}
							label='Supplier Name *'
						/>
						{error && supplierName == '' ? (
							<p
								style={{
									fontSize: '0.75rem',
									color: '#d32f2f',
									marginTop: '0.4rem',
								}}>
								*Please write Supplier name
							</p>
						) : (
							<></>
						)}
					</FormControl>
					<FormControl fullWidth sx={{ marginBottom: '0.5rem' }}>
						<InputLabel
							sx={{
								fontSize: '1rem',
								color: 'primary.main',
								margin: '0.5rem',
								marginLeft: '-1rem',
							}}
							shrink>
							Commodity *
						</InputLabel>
						{commodityLoader ? (
							<Skeleton
								sx={{ height: '3rem', width: '100%', marginTop: '1rem' }}
							/>
						) : (
							commodityData && (
								<Autocomplete
									multiple
									disableClearable
									id='Commodity'
									value={commodityName}
									limitTags={6}
									disableCloseOnSelect
									onChange={(event, newValue) => {
										handleSelectChange(newValue);
									}}
									sx={{ marginBottom: '0.5rem' }}
									options={commodityListOption() && commodityListOption()}
									// @ts-ignore
									getOptionLabel={(option) => option?.name}
									renderOption={(props, option) => (
										<li {...props} style={{ fontSize: '1rem' }}>
											{option.name}
										</li>
									)}
									renderTags={(tagValue, getTagProps) =>
										tagValue.map((option, index) => (
											<Chip
												size='small'
												//  @ts-ignore
												sx={{
													backgroundColor: '#007fff14',
													color: 'primary.main',
													fontSize: '1rem',
												}}
												// @ts-ignore
												label={option?.name}
												{...getTagProps({ index })}
											/>
										))
									}
									renderInput={(params) => (
										<TextField
											{...params}
											// label="Commodity *"
											variant='standard'
											sx={{
												marginTop: '1rem',
												'& .MuiInput-underline:before': {
													borderBottomColor: 'primary.light',
												},
												'& .MuiInput-underline:after': {
													borderBottomColor: 'primary.main',
												},
												'.MuiSvgIcon-root': { color: 'primary.main' },
											}}
										/>
									)}
								/>
							)
						)}
						{error && commodityName?.length == 0 ? (
							<p
								style={{
									fontSize: '0.75rem',
									color: '#d32f2f',
									marginTop: '0.4rem',
								}}>
								*Please write Commodity
							</p>
						) : (
							<></>
						)}
					</FormControl>
					{/* <FormControl fullWidth sx={{ marginBottom: '0.5rem' }}>
						<InputLabel
							sx={{
								fontSize: '1rem',
								color: 'primary.main',
								margin: '0.5rem',
								marginLeft: '-1rem',
							}}
							shrink>
							Product Category *
						</InputLabel>
						{productCategoryLoader ? (
							<Skeleton
								sx={{ height: '3rem', width: '100%', marginTop: '1rem' }}
							/>
						) : (
							productCategoryData && (
								<Autocomplete
									multiple
									disableClearable
									id='ProductCategory'
									value={productCategory}
									limitTags={6}
									sx={{ marginBottom: '0.5rem' }}
									disableCloseOnSelect
									onChange={(event, newValue) => {
										handleSelectProductCategoryChange(newValue);
									}}
									options={
										productCategoryListOption() && productCategoryListOption()
									}
									// @ts-ignore
									getOptionLabel={(option) => option?.abbreviation}
									renderOption={(props, option) => (
										<li {...props} style={{ fontSize: '1rem' }}>
											{option.abbreviation}
										</li>
									)}
									renderTags={(tagValue, getTagProps) =>
										tagValue.map((option, index) => (
											<Chip
												size='small'
												//  @ts-ignore
												sx={{
													backgroundColor: '#007fff14',
													color: 'primary.main',
													fontSize: '1rem',
												}}
												// @ts-ignore
												label={option?.abbreviation}
												{...getTagProps({ index })}
											/>
										))
									}
									renderInput={(params) => (
										<TextField
											{...params}
											// label="Product Category *"
											variant='standard'
											sx={{
												fontSize: '1rem',
												marginTop: '1rem',
												'.MuiInputBase-root.MuiInput-root': {
													fontSize: '1rem !important',
												},
												'& .MuiInput-underline:before': {
													borderBottomColor: 'primary.light',
												},
												'& .MuiInput-underline:after': {
													borderBottomColor: 'primary.main',
												},
												'.MuiSvgIcon-root': { color: 'primary.main' },
											}}
										/>
									)}
								/>
							)
						)}
						{error && productCategory?.length == 0 ? (
							<p
								style={{
									fontSize: '0.75rem',
									color: '#d32f2f',
									marginTop: '0.4rem',
								}}>
								*Please select Product Category
							</p>
						) : (
							<></>
						)}
					</FormControl> */}
					{/* <FormControl fullWidth sx={{ marginBottom: '0.5rem' }}>
						{productCategoryData && systemsData && (
							<InputLabel
								sx={{
									fontSize: '1rem',
									color: 'primary.main',
									margin: '0.5rem',
									marginLeft: '-1rem',
								}}
								shrink>
								System *
							</InputLabel>
						)}
						{productCategory.length > 0 && systemsLoader ? (
							<Skeleton sx={{ height: '3rem', width: '100%' }} />
						) : (
							productCategoryData &&
							systemsData && (
								<Autocomplete
									multiple
									disableClearable
									id='ProductCategory'
									value={systems}
									limitTags={6}
									disableCloseOnSelect
									sx={{ marginBottom: '0.5rem' }}
									onChange={(event, newValue) => {
										handleSelectSystemsChange(newValue);
									}}
									options={systemsListOption() && systemsListOption()}
									// @ts-ignore
									getOptionLabel={(option) =>
										`${option?.abbreviation} ${option?.name}`
									}
									renderTags={(tagValue, getTagProps) =>
										tagValue.map((option, index) => (
											<Chip
												size='small'
												//  @ts-ignore
												sx={{ color: 'primary.main', fontSize: '1rem' }}
												// @ts-ignore
												label={`${option?.abbreviation} ${option?.name}`}
												{...getTagProps({ index })}
											/>
										))
									}
									renderInput={(params) => (
										<TextField
											{...params}
											// label="Systems *"
											variant='standard'
											sx={{
												fontSize: '1rem',
												marginTop: '1rem',
												'.MuiInputBase-root.MuiInput-root': {
													fontSize: '1rem !important',
												},
												'& .MuiInput-underline:before': {
													borderBottomColor: 'primary.light',
												},
												'& .MuiInput-underline:after': {
													borderBottomColor: 'primary.main',
												},
												'.MuiSvgIcon-root': { color: 'primary.main' },
											}}
										/>
									)}
								/>
							)
						)}
						{systemsData && error && systems?.length == 0 ? (
							<p
								style={{
									fontSize: '0.75rem',
									color: '#d32f2f',
									marginTop: '0.4rem',
								}}>
								*Please select Systems
							</p>
						) : (
							<></>
						)}
					</FormControl> */}
					<Box
						sx={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
						<FormControl fullWidth sx={{ marginBottom: '0.5rem' }}>
							<TextField
								autoFocus
								variant='standard'
								InputProps={{
									style: { color: '#007fff', fontSize: '1rem' },
								}}
								InputLabelProps={{
									shrink: true,
									sx: { fontSize: '1rem' },
								}}
								value={aboutUsName}
								onChange={handleAboutUsName}
								sx={{
									width: '100%',
									'& .MuiFormLabel-root': {
										color: 'primary.main',
									},
									'& .MuiFormLabel-root.Mui-focused': {
										color: 'primary.main',
									},
									'& .Mui-focused': {
										color: 'primary.main',
									},
									'& .Mui-error': {
										color: '#d32f2f',
										borderBottomColor: '#d32f2f',
									},
									'& .MuiInput-underline:before': {
										borderBottomColor: 'primary.light',
									},
									'& .MuiInput-underline:after': {
										borderBottomColor: 'primary.main',
									},
								}}
								label='About us Link'
							/>
							{error && aboutUsName == '' ? (
								<p
									style={{
										fontSize: '0.75rem',
										color: '#d32f2f',
										marginTop: '0.4rem',
									}}>
									*Please write About us Link
								</p>
							) : (
								<></>
							)}
						</FormControl>
						<FormControl fullWidth sx={{ marginBottom: '0.5rem' }}>
							<TextField
								autoFocus
								variant='standard'
								InputProps={{
									style: { color: '#007fff', fontSize: '1rem' },
								}}
								InputLabelProps={{
									shrink: true,
									sx: { fontSize: '1rem' },
								}}
								value={contactUsName}
								onChange={handleContactUsName}
								sx={{
									width: '100%',
									'& .MuiFormLabel-root': {
										color: 'primary.main',
									},
									'& .MuiFormLabel-root.Mui-focused': {
										color: 'primary.main',
									},
									'& .Mui-focused': {
										color: 'primary.main',
									},
									'& .Mui-error': {
										color: '#d32f2f',
										borderBottomColor: '#d32f2f',
									},
									'& .MuiInput-underline:before': {
										borderBottomColor: 'primary.light',
									},
									'& .MuiInput-underline:after': {
										borderBottomColor: 'primary.main',
									},
								}}
								label='Contact us Link'
							/>
							{error && contactUsName == '' ? (
								<p
									style={{
										fontSize: '0.75rem',
										color: '#d32f2f',
										marginTop: '0.4rem',
									}}>
									*Please write Contact us Link
								</p>
							) : (
								<></>
							)}
						</FormControl>
					</Box>
					<Box sx={{ marginBottom: '0.5rem' }}>
						<FormLabel
							sx={{
								color: 'primary.main',
								mt: {
									xl: '0.2rem',
									lg: '0.2rem',
									md: '0.5rem',
									sm: '0.5rem',
									xs: '0.5rem',
								},
								textAlign: 'left',
								fontSize: '0.8rem',
							}}>
							Phone Number
						</FormLabel>

						<PhoneInput
							//@ts-ignore
							id='phone'
							name='phone'
							className='phoneInput'
							onChange={(value: any, data: any) => {
								phoneNumberSave(value, data);
							}}
							country={'in'}
						/>
					</Box>
					<Box
						sx={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
						<FormControl fullWidth sx={{ marginBottom: '0.5rem' }}>
							<TextField
								autoFocus
								variant='standard'
								InputProps={{
									style: { color: '#007fff', fontSize: '1rem' },
								}}
								InputLabelProps={{
									shrink: true,
									sx: { fontSize: '1rem' },
								}}
								value={spocName}
								onChange={handleSpocName}
								sx={{
									width: '100%',
									'& .MuiFormLabel-root': {
										color: 'primary.main',
									},
									'& .MuiFormLabel-root.Mui-focused': {
										color: 'primary.main',
									},
									'& .Mui-focused': {
										color: 'primary.main',
									},
									'& .Mui-error': {
										color: '#d32f2f',
										borderBottomColor: '#d32f2f',
									},
									'& .MuiInput-underline:before': {
										borderBottomColor: 'primary.light',
									},
									'& .MuiInput-underline:after': {
										borderBottomColor: 'primary.main',
									},
								}}
								label='SPOC Name'
							/>
						</FormControl>
						<FormControl fullWidth sx={{ marginBottom: '0.5rem' }}>
							<TextField
								variant='standard'
								InputProps={{
									style: { color: '#007fff', fontSize: '1rem' },
								}}
								InputLabelProps={{
									shrink: true,
									sx: { fontSize: '1rem' },
								}}
								value={spocEmail}
								onChange={handleSpocEmail}
								sx={{
									width: '100%',
									'& .MuiFormLabel-root': {
										color: 'primary.main',
									},
									'& .MuiFormLabel-root.Mui-focused': {
										color: 'primary.main',
									},
									'& .Mui-focused': {
										color: 'primary.main',
									},
									'& .Mui-error': {
										color: '#d32f2f',
										borderBottomColor: '#d32f2f',
									},
									'& .MuiInput-underline:before': {
										borderBottomColor: 'primary.light',
									},
									'& .MuiInput-underline:after': {
										borderBottomColor: 'primary.main',
									},
								}}
								label='SPOC Email'
							/>
						</FormControl>
					</Box>

					<FormControl fullWidth sx={{ marginBottom: '0.5rem' }}>
						<InputLabel
							shrink
							id='demo-simple-select-label-Activity'
							sx={{
								color: 'primary.main',
								marginLeft: '-1rem',
								marginTop: '0.5rem',
							}}>
							Logo
						</InputLabel>
						<Input
							type='file'
							style={{ fontSize: '1rem', borderBottomColor: 'aliceblue' }}
							onChange={handleChange}
						/>
					</FormControl>
					<FormControl fullWidth sx={{ marginTop: '1rem' }}>
						<InputLabel
							shrink
							id='demo-simple-select-label-Activity'
							sx={{
								color: 'primary.main',
								fontSize: '1rem',
								marginLeft: '-1rem',
							}}>
							Supplier Type *
						</InputLabel>
						<Select
							size='small'
							labelId='demo-simple-select-label'
							id='demo-simple-select'
							variant='standard'
							value={supplierType}
							onChange={handleSupplierType}
							sx={{
								width: '100%',
								'&:before': {
									borderBottomColor: 'primary.light',
								},
								'.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
							}}>
							<MenuItem
								sx={{ fontSize: '1rem' }}
								value={'Small - 0 to 50 Mn Revenue'}>
								Small - 0 to 50 Million Revenue
							</MenuItem>
							<MenuItem
								sx={{ fontSize: '1rem' }}
								value={'Medium - 50 to 500 Mn Revenue'}>
								Medium - 50 to 500 Million Revenue
							</MenuItem>
							<MenuItem
								sx={{ fontSize: '1rem' }}
								value={'Large > 500 Mn Revenue'}>
								{'Large > 500 Million Revenue'}
							</MenuItem>
						</Select>
						{error && supplierType == undefined ? (
							<p
								style={{
									fontSize: '0.75rem',
									color: '#d32f2f',
									marginTop: '0.4rem',
								}}>
								*Please select Systems
							</p>
						) : (
							<></>
						)}
					</FormControl>
					<InputLabel
						shrink
						id='demo-simple-select-label-Activity'
						sx={{
							color: 'primary.main',
							fontSize: '1rem',
							marginTop: '1rem',
						}}>
						Certification
					</InputLabel>
					<TextareaAutosize
						placeholder='Certifications'
						onChange={handleCertification}
						value={certification}
						style={{
							marginBottom: '0.5rem',
							width: '100%',
							fontSize: '1rem',
							margin: '0 auto',
							padding: '0.5rem',
							border: '1px solid #007fff14',
							minHeight: '10rem',
							maxWidth: '28vw',
							minWidth: '28vw',
							maxHeight: '65vh',
						}}
					/>
				</Box>
				<Box
					sx={{
						width: '100%',
						display: 'flex',
						alignItems: 'center',
						columnGap: '1rem',
						justifyContent: 'flex-end',
						marginTop: '1rem',
					}}>
					<LoadingButton
						size='small'
						variant='contained'
						onClick={submitBomCategoryDetails}
						loading={loader}
						sx={{
							'.MuiCircularProgress-root': {
								color: 'primary.main',
							},
							'&:hover': {
								transform: 'Scale(1.05)',
								transition: 'transform 0.5s ease',
							},
						}}>
						Save
					</LoadingButton>
				</Box>
			</>
		</div>
	);
};

export default AddSupplierDB;
