import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import projectService from "../Services/project.service"
import { Project } from "../Reducers/project.reducer";
import { ID } from "../../utlis/commonInterfaces";



// -------------------------------------------------------------------------------------
// api actions
// -------------------------------------------------------------------------------------

export interface GetProjectsArg {
    module: number;
    vehicle_type?:any
}


const getProjects = createAsyncThunk(
    "project/get",
    async ( arg: GetProjectsArg, { rejectWithValue } ) => {
        try {
            const { data } = await projectService.get( arg.module , arg.vehicle_type );

            return {
                projects: data as Project[],

            };


        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);


const getProjectById = createAsyncThunk(
    "project/getById",
    async ( arg: {projectID:any} ,  { rejectWithValue } ) => {
        try {
            const { data } = await projectService.getById( arg.projectID );

            return {
                project: data as Project,
            };

        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);




// -------------------------------------------------------------------------------------

export type CreateProjectArg = Omit<
    Project,
    "id" | "created_by" | "created_at" | "box_dimension" | "alias_view" | "allowed_modules" | "proposal_document" | "proposal_time_plan"
>;
const createProject = createAsyncThunk(
    "project/create",
    async ( arg: CreateProjectArg, { rejectWithValue } ) => {
        try {
            const { data } = await projectService.create( arg );
            return {
                project: data as Project,
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

// -------------------------------------------------------------------------------------

export interface UpdateProjectArg {
    id: ID;
    updates: Partial<Omit<Project, "id" | "created_by" | "created_at">>;
}
const updateProject = createAsyncThunk(
    "project/update",
    async ( arg: UpdateProjectArg, { rejectWithValue } ) => {
        try {
            await projectService.update( arg );

            return {};
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

// -------------------------------------------------------------------------------------

export interface DeleteProjectArg {
    id: ID;
    recycle: boolean;
}
const deleteProject = createAsyncThunk(
    "project/delete",
    async ( arg: DeleteProjectArg, { rejectWithValue } ) => {
        try {
            await projectService.delete( arg );

            return {};
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

// -------------------------------------------------------------------------------------
// reducer actions
// -------------------------------------------------------------------------------------

const resetProjects = createAction( "project/reset" );

// -------------------------------------------------------------------------------------
// exports
// -------------------------------------------------------------------------------------

export {
    getProjects,
    createProject,
    updateProject,
    deleteProject,
    getProjectById,
    resetProjects,
};
