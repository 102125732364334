import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { Backdrop, IconButton, Button, MenuItem, Select, FormControl } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { DataGrid, GridColDef } from "@mui/x-data-grid"; // Removed GridActionsCellItem
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { API } from "../../api-services";
import Swal from "sweetalert2";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "74vw",
  bgcolor: "background.paper",
  borderRadius: "12px",
  boxShadow: 24,
  p: 0,
};

interface IWatermarkModelProps {
  isOpen: any;
  onCloseModal: any;
  fieldId: any;
  fetchInputsWOL:any;
  getBucketList:any;
  setCounterApiCall?: any;

}

function FetchBucketDataModal(props: IWatermarkModelProps) {
  const { isOpen, onCloseModal, fieldId ,fetchInputsWOL,getBucketList:getBucketListt, setCounterApiCall,} = props;
  const { projectId, categoryId, config_id } = useRouteParams<any>();
  const [data, setData] = React.useState<any>([]);
  const [selectedRows, setSelectedRows] = React.useState<any[]>([]);
  const [dropdownValue, setDropdownValue] = React.useState<string>("");

  const getBucketList = () => {
    API.get(
      `/config/configuration_drop_bucket/`,
      {
        category_id: categoryId,
      },
      0
    )
      .then((res: any) => {
        setData(res.data);
      })
      .catch((err: any) => {});
  };

  React.useEffect(() => {
    getBucketList();
  }, []);

  const handleFetch = () => {
    if (selectedRows.length === 1) {
      // Single row selected - directly fetch
      API.post(`/config/configuration_fetch_bucket/`,{
        drop_ids: selectedRows,
        configuration_id: config_id,
        value_type: "Individual",
        field_id: fieldId
      },{},0).then((res:any)=>{
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Parameter fetched successfully",
        });
        fetchInputsWOL();
        getBucketListt()
        setCounterApiCall((prev: any) => prev + 1);
        onCloseModal();
      }).catch((err:any)=>{})
    } else if (selectedRows.length > 1) {
      // Multiple rows selected - with dropdown value
      API.post(`/config/configuration_fetch_bucket/`,{
        drop_ids: selectedRows,
        configuration_id: config_id,
        value_type: dropdownValue,
        field_id: fieldId
      },{},0).then((res:any)=>{
        Swal.fire({
          icon: "success",
          title: "Success",
          text: "Parameter fetched successfully",
        });
        fetchInputsWOL();
        getBucketListt()
        setCounterApiCall((prev: any) => prev + 1);
        onCloseModal();
      }).catch((err:any)=>{})
      console.log("Selected Row IDs:", selectedRows, "Operation:", dropdownValue);
    }
  };

  // Define columns for the data grid
  const columns: GridColDef[] = [
    { field: "ID", headerName: "Sl No.", width: 70 },
    {
      field: "calc_name",
      headerName: "Cost Model Name",
      width: 300,
      valueGetter: (params) => params.row?.calc_name || "N/A",
    },
    {
      field: "field_name",
      headerName: "Cost Model Field Name",
      width: 300,
      valueGetter: (params) => params.row?.field_name || "N/A",
    },
    {
      field: "address",
      headerName: "Source",
      width: 450,
      valueGetter: (params) => params.row?.address || "N/A",
    },
    {
      field: "formula",
      headerName: "Cost Model Formula",
      width: 200,
      valueGetter: (params) => params.row?.formula || "N/A",
    },
  ];

  // Placeholder for rows - assuming data is passed as an array
  const rows = data
    ? data.map((item: any, index: any) => ({
        id: item?.id,
        ID: index+1,
        calc_name: item.calc_name,
        field_name: item.field_name,
        address: item.address,
        formula: item.formula,
      }))
    : [];

  return (  
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "3.5rem",
              p: 2,
              backgroundColor: "#007fff", // Same color as the button background
              borderTopLeftRadius: "12px",
              borderTopRightRadius: "12px",
            }}
          >
            <Typography
              sx={{
                fontSize: "1.2rem",
                fontWeight: "bold",
                width: "100%",
                textAlign: "center",
                color: "#fff", // White text for contrast
              }}
            >
              Fetch Reusable Parameters
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", color: "#fff", fontSize: "1.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider sx={{ borderColor: "primary.light" }} />

          <Box sx={{ p: 3 }}>
            <div style={{ height: 400, width: "100%" }}>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                disableSelectionOnClick
                checkboxSelection
                onSelectionModelChange={(newSelection) => {
                  setSelectedRows(newSelection);
                  setDropdownValue(""); // Reset dropdown value when selection changes
                }}
              />
            </div>

            {selectedRows.length > 1 && (
              <FormControl fullWidth sx={{ mt: 2 }}>
                <Select
                  value={dropdownValue}
                  onChange={(e) => setDropdownValue(e.target.value as string)}
                  displayEmpty
                >
                  <MenuItem value="" disabled>
                    Select Operation
                  </MenuItem>
                  <MenuItem value="Min">Min</MenuItem>
                  <MenuItem value="Max">Max</MenuItem>
                  <MenuItem value="Avg">Avg</MenuItem>
                  <MenuItem value="Sum">Sum</MenuItem>
                  {/* <MenuItem value="Individual">Individual</MenuItem> */}
                </Select>
              </FormControl>
            )}

            <Button
              variant="contained"
              sx={{ mt: 2 }}
              fullWidth
              disabled={
                selectedRows.length === 0 ||
                (selectedRows.length > 1 && dropdownValue === "")
              }
              onClick={handleFetch}
            >
              Fetch
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default FetchBucketDataModal;
