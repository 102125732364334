import * as React from 'react';
import { Box, Button, TextField } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
// import SearchAutocomplete from './SearchAutoComplete';
// import { useBaseModal } from '../../SearchPage/useBaseModal';
// import SupplierListMoreModal from './SupplierListMoreModal';
import AddIcon from '@mui/icons-material/Add';
import { useBaseModal } from '../../../../SearchPage/useBaseModal';
import { userPermissionAction } from '../../../../BOMCreate/BOMEntry/BOMEntryLandingPage';
import { useRouteParams } from '../../../../../ui-reusable-component/useRouteParams';
import { API } from '../../../../../api-services';
import { ADMIN } from '../../../../../Redux/Services/admin.service';
import SearchAutocomplete from '../../../../BOMCreate/BOMEntry/SearchAutoComplete';
import SupplierListMoreModal from '../../../../BOMCreate/BOMEntry/SupplierListMoreModal';
import { UserAction } from '../../IdeaGenerationViewLanding';
// import { userPermissionAction } from '../../../BOMCreate/BOMEntry/BOMEntryLandingPage';
// import { API } from '../../../../api-services';
// import { ADMIN } from '../../../../Redux/Services/admin.service';
// import { useRouteParams } from '../../../../ui-reusable-component/useRouteParams';
// import { useBaseModal } from '../../../SearchPage/useBaseModal';
// import SearchAutocomplete from '../../../BOMCreate/BOMEntry/SearchAutoComplete';
// import SupplierListMoreModal from '../../../BOMCreate/BOMEntry/SupplierListMoreModal';



export interface IIdeaTabDatabaseInputBoxProps {
    item: any,
    details: any,
    // BOMValidationStatus?: any;
    allValues: any;
    getAllValues: any;
}

export default function IdeaTabDatabaseInputBox (props: IIdeaTabDatabaseInputBoxProps) {
    const databaseMoreModal = useBaseModal();
    const { item, details, allValues, getAllValues } = props;
    const userAction = React.useContext<any>(UserAction);
    let UserActionParams = userAction && userAction?.action;
    const { vault, topVault, copyStatus,vault_id } = useRouteParams<any>()
    const [supplierData, setSupplierData] = React.useState<any>([])
    const [count, setCount] = React.useState<any>(0)
    const [selectedLocation, setSelectedLocation] = React.useState<any>("")
    const [selectedId, setSelectedId] = React.useState<any>(undefined)
    const [message, setMessage] = React.useState<any>("")

    const getObj = (vlt: any) => {
        if (vlt == 0) {
            return { top_vault: topVault }
        } else {
            return { vault: vlt }
        }
    }
    React.useEffect(() => {
        if (item) {
            setSelectedLocation(item?.value?.plant)
            API.get("/api/db/supplier/", {
                id: item?.value?.id
            }, 0).then((res: any) => {
                setSupplierData(res.data)
                setSelectedId(res.data[0]?.id)
            }).catch((err: any) => {

            })
        }
    }, [item])

    React.useEffect(() => {
        if (selectedId) {
            API.get("/api/db/supplier/", {
                id: selectedId && selectedId
            }, 0).then((res: any) => {
                setSupplierData(res.data)
            }).catch((err: any) => {

            })
        }
    }, [selectedId])

    const handleChange = (event: SelectChangeEvent) => {
        const { value } = event.target
        setSelectedLocation(value)
        if (selectedId) {
            setMessage("")
            API.put(`/idea/pool//${vault_id}/`, {
                ...{ parameter_json:{[item?.key]: { id: selectedId, plant: value, instance: item?.database }} }
            }, { ...getObj(vault),module:15 }, 0).then((res: any) => {
                // BOMValidationStatus();
                ADMIN.toast.info("Updated Successfully")
            }).catch((err: any) => {

            })
        } else {
            setMessage("Please select supplier")
        }
    };

    React.useEffect(() => {
        if (selectedId !== supplierData[0]?.id) {
            API.put(`/idea/pool/${vault_id}/`, {
                ...{ parameter_json:{[item?.key]: { id: selectedId, plant: "", instance: item?.database }} }
            }, { ...getObj(vault) }, 0).then((res: any) => {
                // BOMValidationStatus();
                // ADMIN.toast.info("Updated Successfully")
            }).catch((err: any) => {

            })
        }
    }, [selectedId])


  return (
     <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem' }}>
            <SearchAutocomplete setSupplierData={setSupplierData} setCount={setCount} supplierData={{ id: supplierData[0]?.id, name: supplierData[0]?.name, plants: supplierData[0]?.plants_list }} setSelectedId={setSelectedId} />

            <FormControl variant='standard'>
                <Select
                    size='small'
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedLocation}
                    label=" Select Supplier"
                    onChange={handleChange}
                    disabled={((UserActionParams?.includes("U") )) ? false : true}
                    sx={{
                        width: { lg: 130, xl: 200 },
                        "&:before": {
                            borderBottomColor: "transparent",
                        },
                        "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                            fontSize: '12px',
                            backgroundColor: 'rgba(255,255,255,0.3)',
                            padding: '0.2rem',
                            paddingLeft: '0.3rem',

                        },
                        '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
                    }}
                    //@ts-ignore
                    helperText={message}
                >
                    {supplierData[0]?.plants_list?.map((item: any) => {
                        return <MenuItem sx={{ fontSize: '12px' }} value={item?.id}>{item?.city}</MenuItem>
                    })}

                </Select>
            </FormControl>

            <Button size='small' variant="contained"
                disabled={
                    (UserActionParams?.includes("U")
                    ) ? false : true
                }
                sx={{ lineHeight: '1', padding: '0.3rem 0.5rem', whiteSpace: 'nowrap' }} onClick={() => databaseMoreModal.open()} startIcon={<AddIcon sx={{ marginRight: '-0.5rem' }} />}>Add New</Button>

            <SupplierListMoreModal isOpen={databaseMoreModal.isOpen} onCloseModal={databaseMoreModal.close} />
        </Box>
  );
}
