
import { TableRow, TableCell, Table, TableBody, Box, TableHead } from "@mui/material";
import React, { useState } from "react";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import BarGraph from "../BarGraph/BarGraph";
import BomAnalysisGraphs from "./BomAnalysisGraphs";
import styles from "./BomNumerical.module.scss";
// ----------------------------------------------------------------------------------
interface NumericalParameterProps {
    combinationData: any;
    selectedProducts?: any;
    activePage?: any;
    activeCombination?: any;
}
const BomNumerical: React.FC<NumericalParameterProps> = (props) => {
    const { combinationData, selectedProducts, activePage, activeCombination } =
        props;
    const { module, level } = useRouteParams<any>();
    var [endIndex, setendIndex] = useState<any>(5);
    // var [endIndexGraph, setendIndexGraph] = useState<any>( 5 );
    var [startIndex, setstartIndex] = useState<any>(0);
    // var [startIndexGraph, setstartIndexGraph] = useState<any>( 0 );
    var MAX_LENGTH: any = 5;
    // var MAX_LENGTH_GRAPH: any = 5;
    // var MAP_LIST_GRAPH: any = selectedProducts?.splice( startIndexGraph, endIndexGraph );
    // const graphHandlerPage: any = ( key: any ) => {
    //     switch ( key ) {
    //         case 1:
    //             startIndexGraph = endIndexGraph;
    //             endIndexGraph = startIndexGraph + MAX_LENGTH;
    //             setstartIndex( startIndexGraph );
    //             setendIndex( endIndexGraph );
    //             MAP_LIST_GRAPH = getJsxElements()["graphData"]?.slice( startIndexGraph, endIndexGraph );
    //             break;
    //         case 2:
    //             endIndexGraph = endIndexGraph - MAX_LENGTH;
    //             startIndexGraph = startIndexGraph - MAX_LENGTH;
    //             setstartIndex( startIndexGraph );
    //             setendIndex( endIndexGraph );
    //             MAP_LIST_GRAPH = getJsxElements()["graphData"]?.slice( startIndexGraph, endIndexGraph );
    //             break;
    //     }
    // };
    const getGraphData = (data: any, param: any) => {
        const graphData: Array<any> = [];
        Object.keys(data).map((key: string) => {
            var obj: any = {};
            obj["name"] = key;
            obj[`${param}`] = data[key];
            graphData.push(obj);
        });
        return graphData;
    };
    const getTopVault = () => {
        const dataList: any = [];
        //    @ts-ignore
        const data =
            Object.keys(combinationData).length > 0 &&
            Object.values(combinationData?.numeric)[0] &&
            //    @ts-ignore
            Object.keys(Object.values(combinationData?.numeric)[0])?.map((key, ind) =>
                dataList.push(
                    <TableCell sx={{ padding: '0.3rem 0.5rem', textAlign: 'center' }} className={styles.columnRowDataheader} key={ind}>
                        {key?.indexOf("(") !== -1
                            ? `${key?.slice(0, key?.indexOf("("))}`
                            : key}
                        <br />
                        {key?.indexOf("(") !== -1
                            ? `${key?.slice(key.indexOf("("), key?.length)}`
                            : null}
                    </TableCell>
                )
            );
        if (dataList) {
            for (let i = dataList?.length; i < 5; i++) {
                dataList.push(
                    <TableCell sx={{ padding: '0.3rem 0.5rem', textAlign: 'center' }} className={styles.columnRowDataheaderEmpty} key={i}>
                        -
                    </TableCell>
                );
            }
        }
        return dataList;
    };
    const getCellData = (max: any) => {
        let data = [];
        for (let i = max; i < 5; i++) {
            data.push(<TableCell sx={{ padding: '0.3rem 0.5rem' }} className={styles.columnRowData}>-</TableCell>);
        }
        return data;
    };
    const getJsxElements = () => {
        var dataList: any = [];
        var graphDataList: any = [];
        var data =
            Object.keys(combinationData).includes("numeric") &&
            Object.keys(combinationData?.numeric).length > 0 &&
            Object.keys(combinationData?.numeric).map((key: any, ind: number) => {
                // if ( Object.values( combinationData?.numeric[key] ).every(
                //     value => value ) ) {
                dataList.push(
                    <TableRow key={ind}>
                        {<TableCell sx={{ padding: '0.3rem 0.5rem' }} className={styles.parameter}>{key}</TableCell>}
                        {Object.keys(combinationData?.numeric[key])?.map((key1, ind1) => (
                            <TableCell sx={{ padding: '0.3rem 0.5rem' }} className={styles.columnRowData} key={ind1}>
                                {combinationData?.numeric[key][key1]}{" "}
                            </TableCell>
                        ))}
                        {getCellData(Object.keys(combinationData?.numeric[key]).length)}
                    </TableRow>
                );
                // }
                graphDataList.push(
                    <div className={styles.gridItem}>
                        <BarGraph
                            key={ind}
                            analysis={getGraphData(combinationData?.numeric[key], key)}
                            parameter={key}
                        />
                    </div>
                );
            });
        // for ( let i = dataList.length; i < 5; i++ ) {
        //     dataList.push( <Table.Row key={ i }>
        //         <Table.Cell className={ joinClsx( "pctab", styles.columnRowData ) }> - </Table.Cell>
        //     </Table.Row> );
        // }
        var finalData: any = {};
        finalData["table_data"] = dataList;
        finalData["graphData"] = graphDataList;
        return finalData;
    };
    var DATA_LIST: any = getJsxElements()["table_data"]?.slice(
        startIndex,
        endIndex
    );
    const dataHandler: any = (key: any) => {
        switch (key) {
            case 1:
                startIndex = endIndex;
                endIndex = startIndex + MAX_LENGTH;
                setstartIndex(startIndex);
                setendIndex(endIndex);
                DATA_LIST = getJsxElements()["table_data"]?.slice(startIndex, endIndex);
                break;
            case 2:
                endIndex = endIndex - MAX_LENGTH;
                startIndex = startIndex - MAX_LENGTH;
                setstartIndex(startIndex);
                setendIndex(endIndex);
                DATA_LIST = getJsxElements()["table_data"]?.slice(startIndex, endIndex);
                break;
        }
    };
    var GRAPH_LIST: any = getJsxElements()["graphData"]?.slice(
        startIndex,
        endIndex
    );
    const graphHandler: any = (key: any) => {
        switch (key) {
            case 1:
                startIndex = endIndex;
                endIndex = startIndex + MAX_LENGTH;
                setstartIndex(startIndex);
                setendIndex(endIndex);
                GRAPH_LIST = getJsxElements()["graphData"]?.slice(startIndex, endIndex);
                break;
            case 2:
                endIndex = endIndex - MAX_LENGTH;
                startIndex = startIndex - MAX_LENGTH;
                setstartIndex(startIndex);
                setendIndex(endIndex);
                GRAPH_LIST = getJsxElements()["graphData"]?.slice(startIndex, endIndex);
                break;
        }
    };
    return (
        <div data-testid="" className={styles.Container}>
            <Box className={styles.ChartBox}>
                <div className={styles.chartContainer}>
                    {combinationData ? (
                        GRAPH_LIST
                    ) : (
                        <div className={styles.gridItem}>No data</div>
                    )}
                </div>
            </Box>
            <div
                className={styles.NumericSection}
                adl-scrollbar="true"
                adl-scrollbar-width="0.6"
            >
                <div className={styles.numericContainer}>
                    <div className={styles.tablecontainer}>
                        {combinationData ? (
                            <Table className={styles.tablecontain}>
                                <TableHead>
                                    <TableRow sx={{ backgroundColor: 'primary.main', color: 'common.white' }}>
                                        <TableCell sx={{ padding: '0.3rem 0.5rem' }}>
                                            Parameter
                                        </TableCell>
                                        {
                                            //@ts-ignore
                                            getTopVault()
                                        }
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {getJsxElements()["table_data"]?.slice(startIndex, endIndex)}
                                    {combinationData ? (
                                        <>
                                            <div className={styles.slider}>
                                                {/* <Icon name="caret up" title="Prev Product" onClick={ () => { graphHandler( 2 ) } } style={ { color: "#0000FF", background: "none", borderRadius: "0.5rem", cursor: startIndex !== 0 ? "pointer" : "not-allowed", opacity: startIndex !== 0 ? " " : "0.5" } } disabled={ startIndex !== 0 ? false : true }/>
                                        <Icon name="caret down" title="Next Product" onClick={ () => graphHandler( 1 ) } style={ { color: "#0000FF", background: "none", borderRadius: "0.5rem", cursor: getJsxElements()["graphData"]?.length > endIndex ? "pointer" : "not-allowed", opacity: getJsxElements()["graphData"]?.length > endIndex ? " " : "0.5" } } disabled={ getJsxElements()["graphData"]?.length > endIndex ? false : true } /> */}
                                            </div>
                                        </>
                                    ) : null}
                                </TableBody>
                            </Table>
                        ) : (
                            <Table></Table>
                        )}
                    </div>
                </div>
                {/* <div className={ styles.featureGraph }></div> */}
                <div className={styles.bottomGraph}>
                    {module === 13 ? (
                        <BomAnalysisGraphs
                            project={selectedProducts?.toString()}
                            activePage={activePage}
                            activeCombination={activeCombination}
                        />
                    ) : null}
                </div>
            </div>
        </div>
    );
};
// ----------------------------------------------------------------------------------
export default BomNumerical;