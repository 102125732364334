// vendors
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

// services


// redux

import { ID } from "../../utlis/commonInterfaces";



import { UserInput } from "../Reducers/BOMuserInput.reducer";

import IdeauserInputService from "../Services/BOMuserInput.service";


// interfaces


// -------------------------------------------------------------------------------------
// api actions
// -------------------------------------------------------------------------------------

export interface GetUserInputsArg {
    category?: number;
    cardCostId: ID;
}
const getUserInputs = createAsyncThunk(
    "userInput/get",
    async ( arg: GetUserInputsArg, { rejectWithValue } ) => {
        try {
            const { data } = await IdeauserInputService.get( arg );

            return {
                inputs: data as UserInput[]
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);


export interface GetUserInputsTriangulationArg {
    cardCostId: ID;
}
const getUserInputsTriangulation = createAsyncThunk(
    "userInput/getTriangulation",
    async ( arg: GetUserInputsTriangulationArg, { rejectWithValue } ) => {
        try {
            const { data } = await IdeauserInputService.getIdeaList( arg );

            return {
                inputs: data as UserInput[]
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);


// -------------------------------------------------------------------------------------

export interface UpdateUserInputArg {
    id: ID;
    updates: Omit<UserInput, "id" | "calculator">;
}
const updateUserInput = createAsyncThunk(
    "userInput/update",
    async ( arg: UpdateUserInputArg, { rejectWithValue } ) => {
        try {
            const { data } = await IdeauserInputService.update( arg );

            return {
                input: data as UserInput
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);


export interface UpdateUserInputTriangulationArg {
    id: ID;
    calculator: ID;
    updates: Omit<UserInput, "id" | "calculator">;
}
const updateUserInputTriangulation = createAsyncThunk(
    "userInput/updateTriangulation",
    async ( arg: UpdateUserInputTriangulationArg, { rejectWithValue } ) => {
        try {
            const { data } = await IdeauserInputService.updateTriangulation( arg );

            return {
                input: data as UserInput
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);


// -------------------------------------------------------------------------------------
// reducer actions
// -------------------------------------------------------------------------------------

const resetUserInputs = createAction( "userInputs/reset" );

// -------------------------------------------------------------------------------------
// exports
// -------------------------------------------------------------------------------------

export {
    getUserInputs,
    updateUserInput,
    resetUserInputs,
    getUserInputsTriangulation,
    updateUserInputTriangulation
};