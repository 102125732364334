import { Avatar, Button, Tooltip, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import * as React from 'react';
import VisibilityIcon from "@mui/icons-material/Visibility";
import { Preview } from '@mui/icons-material';
import { PreviewMailLogsModal } from './PreviewMailLogsModal';
import { useBaseModal } from '../SearchPage/useBaseModal';
import useWindowDimensions from '../../ui-reusable-component/useWindowDimensions';


export interface MailLogsTableProps {
  tableData: any;
  loader: any
}

export default function MailLogsTable(props: MailLogsTableProps) {


  const { tableData, loader } = props;
  const [RowIds, setRowIds] = React.useState<any>([])
  const previewlogs = useBaseModal()
  const { height, width } = useWindowDimensions();

  var AvatarName = (item: any) => {
    const [first, last] = item?.split(' ')
    const FirstLatter = first?.charAt(0).toUpperCase()
    const lastWord = last?.charAt(0).toUpperCase()
    return `${FirstLatter}${lastWord}`;
  }

  const PreviewHandler = (Id: any) => {
    setRowIds(Id)
    previewlogs.open()

  }

  let columns: any =
    [
      { field: "ids", headerName: "Sl.No.", width: 50, },
      {
        field: "user_name", headerName: "From", minWidth: width > 1400 ? 300 : 160, renderCell: (cellValues: any) => {

          return (<>
            {cellValues?.row?.profile_db !== "" ?


              <Avatar
                src={cellValues?.row?.profile_db}
                sx={{
                  width: "2rem",
                  height: "2rem",
                  objectFit: "cover",
                  backgroundColor: "#007fff !important",
                }}
              >
                {AvatarName(cellValues?.row?.user_name)}
              </Avatar>
              :
              <Avatar
                src="/broken-image.jpg"
                sx={{
                  width: "2rem",
                  height: "2rem",
                  objectFit: "cover",
                  backgroundColor: "#007fff !important",
                }}
              >
                {AvatarName(cellValues?.row?.user_name)}
              </Avatar>
            }
            <Typography sx={{ fontSize: '1ren', marginLeft: '0.5rem' }}>{cellValues?.row?.user_name}</Typography>


          </>)
        }
      },
      {
        field: "to_internal",
        headerName: "To (Internal)",
        minWidth: width > 1400 ? 300 : 240,
        flex: 1,
        renderCell: (cellValues: any) => {
          return (
            <>
              <Tooltip title={cellValues?.row?.to_internal.toString(", ")}>
                <Typography> {cellValues?.row?.to_internal.toString(", ")}   </Typography>
              </Tooltip>
            </>
          );
        },
      },
      {
        field: "to_external",
        headerName: "To (External)",
        minWidth: width > 1400 ? 300 : 240,
        flex: 1,
        renderCell: (cellValues: any) => {
          return (
            <>
              <Tooltip title={cellValues?.row?.to_external.toString(", ")} >
                <Typography>
                  {cellValues?.row?.to_external.toString(", ")}
                </Typography>
              </Tooltip>
            </>
          );
        },

      },
      {
        field: "cc_mails",
        headerName: "CC",
        minWidth: width > 1400 ? 300 : 240,
        flex: 1,
        renderCell: (cellValues: any) => {
          return (
            <>
              <Tooltip title={cellValues?.row?.cc_mails.toString(", ")} >
                <p>
                  {cellValues?.row?.cc_mails.toString(", ")}
                </p>
              </Tooltip>
            </>
          );
        },
      },
      {
        field: "mail_created_at",
        headerName: "Date",
        type: "date",
        minWidth: width > 1400 ? 120 : 80,
        flex: 1,
      },
      {
        field: "subject",
        headerName: "Subject",
        minWidth: width > 1400 ? 300 : 170,
        flex: 1,
      },
      {
        field: "View",
        headerName: "View",
        minWidth: width > 1400 ? 70 : 60,
        flex: 1,
        renderCell: (cellValues: any) => {
          return (
            <>
              <VisibilityIcon sx={{ cursor: "pointer" }} onClick={() => PreviewHandler(cellValues?.row?.id)} />             
            </>
          );
        },
      },
    ]

  const TableRow = () => {
    var valuedata =
      tableData &&
      tableData.map((item: any, id: any) => ({
        ...item,
        ids: id + 1,
      }));

    return valuedata;
  };

  return (
    <div>
      {previewlogs.isOpen &&
        <PreviewMailLogsModal
          onCloseModal={previewlogs.close}
          isOpen={previewlogs.isOpen}
          rowIds={RowIds}
        />
      }
      <Box sx={{ height: '88vh', width: '100%' }}
        adl-scrollbar="true"
        adl-scrollbar-width="0.3">
        <DataGrid
          headerHeight={48}
          rowHeight={44}
          rows={TableRow() && TableRow()}
          columns={columns}
          density="compact"
          autoHeight={true}
          loading={loader}
          hideFooterPagination={true}
        // pageSizeOptions={[5]}
        />
      </Box>
    </div>
  );
}
