// import { getOptionsMap } from "app/utils/getOptionsMap";
import { invert } from "lodash";
import { MODULES } from "../Constants/modules.constants";
import { getOptionsMap } from "./getOptionsMap";
// import { MODULES } from "./modules.constants";

// -----------------------------------------------------------------------------
// stages

const IDEA_STAGES = {
    STAGE_0: 0,
    STAGE_1: 1,
    STAGE_2: 2,
    STAGE_3: 3,
};

const IdeaStagesList = [
    { key: IDEA_STAGES.STAGE_0, value: IDEA_STAGES.STAGE_0, text: "Initiated" },
    {
        key: IDEA_STAGES.STAGE_1,
        value: IDEA_STAGES.STAGE_1,
        text: "Idea Evaluation",
    },
    { key: IDEA_STAGES.STAGE_2, value: IDEA_STAGES.STAGE_2, text: "Submitted" },
    {
        key: IDEA_STAGES.STAGE_3,
        value: IDEA_STAGES.STAGE_3,
        text: "Approve/Reject",
    },
];

// -----------------------------------------------------------------------------
// idea origin

const IDEA_ORIGIN = {
    BOM: 1,
    ARCHITECTURE: 2,
    FEATURE: 3,
    COSTING: 4,
    MEDIA: 5,
    SPECIFICATION: 7,
};

const getConfigurationModuleByIdeaOrigin = ( origin: number ): number =>
( {
    [IDEA_ORIGIN.BOM]: MODULES.TEMPLATE_CONFIGURATION,
    [IDEA_ORIGIN.FEATURE]: MODULES.FEATURE_CONFIGURATION,
    [IDEA_ORIGIN.SPECIFICATION]: MODULES.FEATURE_CONFIGURATION,
}[origin] || 0 );

const IdeaOriginList = [
    { key: IDEA_ORIGIN.BOM, value: IDEA_ORIGIN.BOM, text: "BOM" },
    {
        key: IDEA_ORIGIN.ARCHITECTURE,
        value: IDEA_ORIGIN.ARCHITECTURE,
        text: "Architecture",
    },
    {
        key: IDEA_ORIGIN.SPECIFICATION,
        value: IDEA_ORIGIN.SPECIFICATION,
        text: "Specification",
    },
    { key: IDEA_ORIGIN.FEATURE, value: IDEA_ORIGIN.FEATURE, text: "Feature" },
    {
        key: IDEA_ORIGIN.COSTING,
        value: IDEA_ORIGIN.COSTING,
        text: "Cost Estimation",
    },
    { key: IDEA_ORIGIN.MEDIA, value: IDEA_ORIGIN.MEDIA, text: "Media" },
];

const IdeaOriginListProduct = [
    // { key: IDEA_ORIGIN.BOM, value: IDEA_ORIGIN.BOM, text: "BOM" },
    {
        key: IDEA_ORIGIN.ARCHITECTURE,
        value: IDEA_ORIGIN.ARCHITECTURE,
        text: "Architecture",
    },
    {
        key: IDEA_ORIGIN.SPECIFICATION,
        value: IDEA_ORIGIN.SPECIFICATION,
        text: "Specification",
    },
    { key: IDEA_ORIGIN.FEATURE, value: IDEA_ORIGIN.FEATURE, text: "Feature" },
    // {
    //     key: IDEA_ORIGIN.COSTING,
    //     value: IDEA_ORIGIN.COSTING,
    //     text: "Cost Estimation",
    // },
    { key: IDEA_ORIGIN.MEDIA, value: IDEA_ORIGIN.MEDIA, text: "Media" },
];

export const costEstimationParameters: any = [
    {
        id: 1,
        value: 1,
        text: "Material Cost",
    },
    {
        id: 2,
        value: 2,
        text: "Raw Material",
    },
    {
        id: 3,
        value: 3,
        text: "Purchase  Item",
    },
    {
        id: 4,
        value: 4,
        text: "Process Cost",
    },
    {
        id: 5,
        value: 5,
        text: "Labour Cost",
    },
    {
        id: 6,
        value: 6,
        text: "Machine Cost",
    },
    {
        id: 7,
        value: 7,
        text: "Setup Cost",
    },
    {
        id: 8,
        value: 8,
        text: "Tool Cost",
    },
    {
        id: 9,
        value: 9,
        text: "Overhead Cost",
    },
    {
        id: 10,
        value: 10,
        text: "Total Cost",
    },
    {
        id: 11,
        value: 11,
        text: "Child Cost",
    },
    {
        id: 12,
        value: 16,
        text: "Cost/Kg",
    },
];

export const costEstimationParametersCount: any = [
    {
        id: 1,
        value: 12,
        text: "Raw Material",
    },
    {
        id: 2,
        value: 13,
        text: "Purchase  Item",
    },
    {
        id: 3,
        value: 14,
        text: "Process Cost",
    },
    {
        id: 4,
        value: 15,
        text: "Children Count",
    },
];


export const costEstimationParametersNew: any = [
    {
        id: 1,
        value: 1,
        name: "Material Cost",
    },
    {
        id: 2,
        value: 2,
        name: "Raw Material",
    },
    {
        id: 3,
        value: 3,
        name: "Purchase  Item",
    },
    {
        id: 4,
        value: 4,
        name: "Process Cost",
    },
    {
        id: 5,
        value: 5,
        name: "Labour Cost",
    },
    {
        id: 6,
        value: 6,
        name: "Machine Cost",
    },
    {
        id: 7,
        value: 7,
        name: "Setup Cost",
    },
    {
        id: 8,
        value: 8,
        name: "Tool Cost",
    },
    {
        id: 9,
        value: 9,
        name: "Overhead Cost",
    },
    {
        id: 10,
        value: 10,
        name: "Total Cost",
    },
    {
        id: 11,
        value: 11,
        name: "Child Cost",
    },
    {
        id: 12,
        value: 16,
        name: "Cost/Kg",
    },
];

export const costEstimationParametersCountNew: any = [
    {
        id: 1,
        value: 12,
        name: "Raw Material",
    },
    {
        id: 2,
        value: 13,
        name: "Purchase  Item",
    },
    {
        id: 3,
        value: 14,
        name: "Process Cost",
    },
    {
        id: 4,
        value: 15,
        name: "Children Count",
    },
];

const getIdeaOriginName = ( origin: number ) =>
    IdeaOriginList.find( o => o.value === origin )?.text || "";

// -----------------------------------------------------------------------------
// idea types

const IdeaTypesList = [
    { key: 1, value: 1, text: "Alternate Material" },
    { key: 2, value: 2, text: "Supplier Change" },
    { key: 3, value: 3, text: "Mfg. Process Change" },
    { key: 4, value: 4, text: "Packaging Change" },
    { key: 5, value: 5, text: "Joinery Type Change" },
    { key: 6, value: 6, text: "Thickness Change" },
    { key: 7, value: 7, text: "Diameter Change" },
    { key: 8, value: 8, text: "Styling Change" },
    { key: 9, value: 9, text: "Routing Change" },
    { key: 10, value: 10, text: "De-Content" },
    { key: 11, value: 11, text: "Design Change" },
    { key: 12, value: 12, text: "Cosumable Reduction" },
    { key: 13, value: 13, text: "Fastener Reduce/ Change" },
    { key: 14, value: 14, text: "Coating Thickness Change" },
    { key: 15, value: 15, text: "Coating Type Change" },
    { key: 16, value: 16, text: "Part/Feature Deletion" },
].sort( ( a, b ) => a.text.localeCompare( b.text ) );

const getIdeaTypeName = ( type: number ) =>
    IdeaTypesList.find( t => t.value === type )?.text || "";

// -----------------------------------------------------------------------------
// combination types

const IDEA_COMBINATION_TYPE = {
    NUMERICAL: 1,
    SUBJECTIVE: 2,
    MATERIAL: 3,
    COMPLEXITY_QUANTITY: 4,
    COMPLEXITY_TYPE: 5,
    BOOLEAN: 6,
    DROPDOWN: 7,
    COUNT: 8,
    TEXT: 9,
};

const IdeaCombinationTypesList = {
    [IDEA_ORIGIN.BOM]: [
        {
            key: IDEA_COMBINATION_TYPE.NUMERICAL,
            value: IDEA_COMBINATION_TYPE.NUMERICAL,
            text: "Numerical",
        },
        {
            key: IDEA_COMBINATION_TYPE.DROPDOWN,
            value: IDEA_COMBINATION_TYPE.DROPDOWN,
            text: "Dropdown",
        },
        {
            key: IDEA_COMBINATION_TYPE.MATERIAL,
            value: IDEA_COMBINATION_TYPE.MATERIAL,
            text: "Material",
        },
        {
            key: IDEA_COMBINATION_TYPE.COMPLEXITY_QUANTITY,
            value: IDEA_COMBINATION_TYPE.COMPLEXITY_QUANTITY,
            text: "Complexity (Quantity)",
        },
        {
            key: IDEA_COMBINATION_TYPE.COMPLEXITY_TYPE,
            value: IDEA_COMBINATION_TYPE.COMPLEXITY_TYPE,
            text: "Complexity (Type)",
        },
    ],
    [IDEA_ORIGIN.FEATURE]: [
        {
            key: IDEA_COMBINATION_TYPE.TEXT,
            value: IDEA_COMBINATION_TYPE.TEXT,
            text: "Text ",
        },
        {
            key: IDEA_COMBINATION_TYPE.NUMERICAL,
            value: IDEA_COMBINATION_TYPE.NUMERICAL,
            text: "Numerical",
        },
        
    ],
    [IDEA_ORIGIN.SPECIFICATION]: [
        {
            key: IDEA_COMBINATION_TYPE.TEXT,
            value: IDEA_COMBINATION_TYPE.TEXT,
            text: "Text ",
        },
        {
            key: IDEA_COMBINATION_TYPE.NUMERICAL,
            value: IDEA_COMBINATION_TYPE.NUMERICAL,
            text: "Numerical",
        },
        
    ],
    [IDEA_ORIGIN.COSTING]: [
        {
            key: IDEA_COMBINATION_TYPE.NUMERICAL,
            value: IDEA_COMBINATION_TYPE.NUMERICAL,
            text: "Numerical",
        },
        {
            key: IDEA_COMBINATION_TYPE.COUNT,
            value: IDEA_COMBINATION_TYPE.COUNT,
            text: "Count",
        },
    ],
};

const IDEA_COMBINATION_TYPE_NAMES = getOptionsMap(
    Object.values( IdeaCombinationTypesList ).flat()
);

const getIdeaCombinationTypeName = ( type: number ) =>
    IDEA_COMBINATION_TYPE_NAMES[type];

// -----------------------------------------------------------------------------
// combination - idea filter

const IDEA_FILTER = {
    All: 1,
    "Base > Competitor": 2,
    "Base < Competitor": 3,
    "Base = Competitor": 4,
    "Base != Competitor": 5,
    "Base Leaf Node > Competitor Leaf Node": 6,
    "Leaf of Non Leaf Base > Leaf of Non Leaf Competitor": 7,
    "Non Leaf Base > Non Leaf Competitor": 8,
    "Base Leaf Node < Competitor Leaf Node": 9,
    "Leaf of Non Leaf Base < Leaf of Non Leaf Competitor": 10,
    "Non Leaf Base < Non Leaf Competitor": 11,
    "Base and Competitor": 12,
    "Base Only": 13,
    "Competitor Only": 14,
    "Base <= Competitor": 15,
};

const IDEA_FILTER_REVERSE = invert( IDEA_FILTER );

const getIdeaFilterName = ( filter: number ) => IDEA_FILTER_REVERSE[filter];

const IdeaFilterList = {
    [IDEA_COMBINATION_TYPE.NUMERICAL]: [
        {
            key: IDEA_FILTER.All,
            value: IDEA_FILTER.All,
            text: getIdeaFilterName( IDEA_FILTER.All ),
        },
        {
            key: IDEA_FILTER["Base > Competitor"],
            value: IDEA_FILTER["Base > Competitor"],
            text: getIdeaFilterName( IDEA_FILTER["Base > Competitor"] ),
        },
        {
            key: IDEA_FILTER["Base <= Competitor"],
            value: IDEA_FILTER["Base <= Competitor"],
            text: getIdeaFilterName( IDEA_FILTER["Base <= Competitor"] ),
        },
    ],
    [IDEA_COMBINATION_TYPE.SUBJECTIVE]: [
        {
            key: IDEA_FILTER.All,
            value: IDEA_FILTER.All,
            text: getIdeaFilterName( IDEA_FILTER.All ),
        },
        {
            key: IDEA_FILTER["Base = Competitor"],
            value: IDEA_FILTER["Base = Competitor"],
            text: getIdeaFilterName( IDEA_FILTER["Base = Competitor"] ),
        },
        {
            key: IDEA_FILTER["Base != Competitor"],
            value: IDEA_FILTER["Base != Competitor"],
            text: getIdeaFilterName( IDEA_FILTER["Base != Competitor"] ),
        },
    ],
    [IDEA_COMBINATION_TYPE.MATERIAL]: [
        {
            key: IDEA_FILTER.All,
            value: IDEA_FILTER.All,
            text: getIdeaFilterName( IDEA_FILTER.All ),
        },
        {
            key: IDEA_FILTER["Base = Competitor"],
            value: IDEA_FILTER["Base = Competitor"],
            text: getIdeaFilterName( IDEA_FILTER["Base = Competitor"] ),
        },
        {
            key: IDEA_FILTER["Base != Competitor"],
            value: IDEA_FILTER["Base != Competitor"],
            text: getIdeaFilterName( IDEA_FILTER["Base != Competitor"] ),
        },
    ],
    [IDEA_COMBINATION_TYPE.COMPLEXITY_QUANTITY]: [
        {
            key: IDEA_FILTER.All,
            value: IDEA_FILTER.All,
            text: getIdeaFilterName( IDEA_FILTER.All ),
        },
        {
            key: IDEA_FILTER["Base > Competitor"],
            value: IDEA_FILTER["Base > Competitor"],
            text: getIdeaFilterName( IDEA_FILTER["Base > Competitor"] ),
        },
        {
            key: IDEA_FILTER["Base <= Competitor"],
            value: IDEA_FILTER["Base <= Competitor"],
            text: getIdeaFilterName( IDEA_FILTER["Base <= Competitor"] ),
        },

    ],
    [IDEA_COMBINATION_TYPE.COMPLEXITY_TYPE]: [
        {
            key: IDEA_FILTER.All,
            value: IDEA_FILTER.All,
            text: getIdeaFilterName( IDEA_FILTER.All ),
        },
        {
            key: IDEA_FILTER["Base > Competitor"],
            value: IDEA_FILTER["Base > Competitor"],
            text: getIdeaFilterName( IDEA_FILTER["Base > Competitor"] ),
        },
        {
            key: IDEA_FILTER["Base <= Competitor"],
            value: IDEA_FILTER["Base <= Competitor"],
            text: getIdeaFilterName( IDEA_FILTER["Base <= Competitor"] ),
        },
    ],
    [IDEA_COMBINATION_TYPE.BOOLEAN]: [
        {
            key: IDEA_FILTER["Base = Competitor"],
            value: IDEA_FILTER["Base = Competitor"],
            text: getIdeaFilterName( IDEA_FILTER["Base = Competitor"] ),
        },
        {
            key: IDEA_FILTER["Base != Competitor"],
            value: IDEA_FILTER["Base != Competitor"],
            text: getIdeaFilterName( IDEA_FILTER["Base != Competitor"] ),
        },
        {
            key: IDEA_FILTER.All,
            value: IDEA_FILTER.All,
            text: getIdeaFilterName( IDEA_FILTER.All ),
        },
    ],
    [IDEA_COMBINATION_TYPE.DROPDOWN]: [
        {
            key: IDEA_FILTER["Base = Competitor"],
            value: IDEA_FILTER["Base = Competitor"],
            text: getIdeaFilterName( IDEA_FILTER["Base = Competitor"] ),
        },
        {
            key: IDEA_FILTER["Base != Competitor"],
            value: IDEA_FILTER["Base != Competitor"],
            text: getIdeaFilterName( IDEA_FILTER["Base != Competitor"] ),
        },
        {
            key: IDEA_FILTER.All,
            value: IDEA_FILTER.All,
            text: getIdeaFilterName( IDEA_FILTER.All ),
        },
    ],
    [IDEA_COMBINATION_TYPE.COUNT]: [
        {
            key: IDEA_FILTER.All,
            value: IDEA_FILTER.All,
            text: getIdeaFilterName( IDEA_FILTER.All ),
        },
        {
            key: IDEA_FILTER["Base > Competitor"],
            value: IDEA_FILTER["Base > Competitor"],
            text: getIdeaFilterName( IDEA_FILTER["Base > Competitor"] ),
        },
        {
            key: IDEA_FILTER["Base <= Competitor"],
            value: IDEA_FILTER["Base <= Competitor"],
            text: getIdeaFilterName( IDEA_FILTER["Base <= Competitor"] ),
        },
    ],
    [IDEA_COMBINATION_TYPE.TEXT]: [
        {
            key: IDEA_FILTER["Base = Competitor"],
            value: IDEA_FILTER["Base = Competitor"],
            text: getIdeaFilterName( IDEA_FILTER["Base = Competitor"] ),
        },
        {
            key: IDEA_FILTER["Base != Competitor"],
            value: IDEA_FILTER["Base != Competitor"],
            text: getIdeaFilterName( IDEA_FILTER["Base != Competitor"] ),
        },
        {
            key: IDEA_FILTER.All,
            value: IDEA_FILTER.All,
            text: getIdeaFilterName( IDEA_FILTER.All ),
        },
    ],
};

// -----------------------------------------------------------------------------

export {
    IDEA_STAGES,
    IdeaStagesList,
    IDEA_COMBINATION_TYPE,
    IdeaCombinationTypesList,
    getIdeaCombinationTypeName,
    IDEA_FILTER,
    getIdeaFilterName,
    IdeaFilterList,
    IDEA_ORIGIN,
    IdeaOriginList,
    getConfigurationModuleByIdeaOrigin,
    getIdeaOriginName,
    IdeaTypesList,
    getIdeaTypeName,
    IdeaOriginListProduct
};
