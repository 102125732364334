import axios, { AxiosError, AxiosResponse } from "axios";
import { API } from "../../../api-services";
import { MODULES } from "../../../Constants/modules.constants";
import { s3Url } from "../../../utlis/s3url.utils";

const uploadS3BOPFlag = (id: any, values: any, fileList: any, setIsLoadingSave: any, pathName: any, onCloseModal: any, getMaterialData: any,categoryName:any) => {
  var random: any = Math.floor(Math.random() * 1000000 + 1);
  var editedImageName: any = `EditedImage${random}`;
  var obj: Array<any> = [];
  API.get("cad/cad_upload/", {
    module: MODULES.CAD,
  }).then((res) => {
    const data = Array.from(fileList).forEach((file: any, index: any) => {
      const formData = new FormData();
      formData.append("key", `${pathName}/${random}/${file.name}`);
      formData.append("Content-Type", file?.type);
      formData.append("x-amz-server-side-encryption", "AES256");
      formData.append("X-Amz-Credential", res.data.data.x_amz_credential);
      formData.append("X-Amz-Algorithm", "AWS4-HMAC-SHA256");
      formData.append("X-Amz-Date", res.data.data.x_amz_date);
      formData.append("X-Amz-Signature", res.data.data.aws_policy_signature);
      formData.append("Policy", res.data.data.aws_policy);
      formData.append("file", file, file?.name);
      axios
        .post(s3Url, formData, {
          onUploadProgress: (data) => {
            //Set the progress value to show the progress bar
          },
        })
        .then(async (res: any) => {
          setIsLoadingSave(true);
          API.put(`/api/db/bought_out_cost/${id}/`, { ...values, ...{ flag_reference: `/${pathName}/${random}/${file.name}` } }, {}, 0)
            .then((res: any) => {
              setIsLoadingSave(false);
              getMaterialData(categoryName);
              onCloseModal();
            })
            .catch((err: any) => {
              setIsLoadingSave(false);
            });
        });
    });
  });
};
// ----------------------------------------------------------------------------------
export default uploadS3BOPFlag;
