import React from 'react'
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CancelIcon from '@mui/icons-material/Cancel';

import { Divider, Tooltip } from '@mui/material';
// import IdeaCostCalculator from './IdeaCostCalculator';
import { useParams } from 'react-router-dom';
import IdeaCostCalculatorForm from './IdeaCostCalculatorForm';

interface InfoModalProps {


  onCloseModal: any;
  isOpen: any;
  Item: any;
  setCounter:any;

}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: {lg:"90vw",xl:'80vw'},
  bgcolor: 'background.paper',
  borderRadius: '10px',
  p: 1,
};


const IdeaCreateCostCalculatorModalForm = (props: InfoModalProps) => {

const {projectId,topVault,type}=useParams<any>()

  const { onCloseModal,
    isOpen,
    Item ,setCounter} = props

 
const CloseHandler=()=>{
    setCounter((prev:any)=>prev+1)
    onCloseModal()
}

  return (
    <div>


      <Modal
        open={isOpen}
        // onClose={onCloseModal}
      >
        <Box sx={style}>
          <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: '-2rem'
              }}
            >
              Idea Generation  Cost Model ({Item?.name})
            </Typography>
            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={()=>CloseHandler()} />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ height: {lg:'85vh',xl:'75vh'}, width: '100%' }}
           adl-scrollbar="true"
           adl-scrollbar-width="0.3">               
            <IdeaCostCalculatorForm projectID={projectId} topVaultID={topVault} type={type} Item={Item?.id}/>
                       
          </Box>
        </Box>
      </Modal>
    </div>
  )
}

export default IdeaCreateCostCalculatorModalForm;