import * as React from "react";
import {
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
} from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { RoleList } from "../../Constants/projectspecificmodules.constants";
import { API } from "../../api-services";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";

export interface IEditRoleProps {
  action: any;
  itemId?: any;
  roleOptions: any;
  selectedModules?: any;
  user:any;
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export function EditRoleProjectSpec(props: IEditRoleProps) {
  const { action, itemId, roleOptions, selectedModules,user } = props;
  const { moduleId ,projectId} = useRouteParams<any>();
  const [roles, setRoles] = React.useState<any>([]);
  React.useEffect(() => {
    if (action && !action.includes(null)) {
      setRoles(action);
    }
  }, [action]);
  const handleSelectChangeRole = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    if (value.length === 0) {
      setRoles((prev: any) => prev);
      return;
    }
    setRoles(value);
    API.put(
      "/auth/assign_permission/",
      {
        user: user,
        action: value,
        submodule: selectedModules,
        project:projectId
      },
      {},
      0
    )
      .then((res: any) => { })
      .catch((err: any) => { });
  };
  return (
    <div>
      <FormControl fullWidth variant="standard">
        {/* <InputLabel id="demo-simple-select-label">
                          Select roles
                        </InputLabel> */}
        <Select
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={roles&&roles}
          multiple
          multiline
          label="Role"
          placeholder="Select Role"
          onChange={handleSelectChangeRole}
          size="small"
          sx={{
            "&:before": {
              borderBottomColor: "primary.main",
            },
            '.MuiSvgIcon-root.MuiSelect-icon':{color:'primary.main'},	
          }}
          renderValue={(selected) => (
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 0.5,
              }}
            >
              {roleOptions.filter((item: any) =>
                selected?.includes(item?.value)
              )?.map((value: any) => (
                <Chip
                  size="small"
                  key={value.value}
                  label={value.value}
                  onDelete={() => {
                    if (roles.length > 1) {
                      setRoles((prev: any) =>
                        prev?.filter((item: any) => value.value !== item)
                      )
                      API.put(
                        "/auth/assign_permission/",
                        {
                          user: user,
                          action: roles?.filter((item: any) => value.value !== item),
                          submodule: selectedModules,
                          project:projectId

                        },
                        {},
                        0
                      )
                        .then((res: any) => { })
                        .catch((err: any) => { });
                    }
                  }
                  }
                  deleteIcon={
                    <CancelIcon
                      onMouseDown={(event) => event.stopPropagation()}
                    />
                  }
                />
              ))}
            </Box>
          )}
          MenuProps={MenuProps}
        >
          {roleOptions &&
            roleOptions?.map((item: any, index: number) => {
              return (
                (!roles.includes(item?.value)&&<MenuItem key={`${index}`} value={item?.value?item?.value:""}>
                  {item.text}
                </MenuItem>)
              );
            })}
        </Select>
      </FormControl>
    </div>
  );
}
