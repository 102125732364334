import {
    createEntityAdapter,
    createReducer,
    createSelector,
    EntityState,
} from "@reduxjs/toolkit";

import { maxBy } from "lodash";
import {
    createWorkstation,
    deleteWorkstation,
    getWorkstations,
    resetWorkstations,
    updateWorkstation,
} from "../Actions/workstation.actions";
import { ID } from "../../utlis/commonInterfaces";
import { ADMIN } from "../Services/admin.service";
import { RootState } from "../Store/storeConfigurations";

// -----------------------------------------------------------------------------------
// entity adaptor

export interface Workstation {
    id: ID;
    name: string;
    categories: ID[];
    allowed_parameters: ID[];
    top_vault: ID;
    commodity: boolean;
    color_status?:any;
}

const workstationAdaptor = createEntityAdapter<Workstation>( {
    selectId: workstation => workstation.id,
    sortComparer: ( a, b ) => a.name.localeCompare( b.name ),
} );

// -----------------------------------------------------------------------------------
// interfaces & initialState

type AdditionalFields = {};

type IWorkstationReducer = EntityState<Workstation> & AdditionalFields;

const additionalFields: AdditionalFields = {};

const initialState: IWorkstationReducer = Object.assign(
    {},
    workstationAdaptor.getInitialState(),
    additionalFields
);

// -----------------------------------------------------------------------------------
// reducer

const WorkstationReducer = createReducer( initialState, builder => {
    // get
    builder
        .addCase( getWorkstations.fulfilled, ( state, action: any ) => {
            workstationAdaptor.setAll( state, action.payload.workstations );
        } )
        .addCase( getWorkstations.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError(
            //     action.payload,
            //     "Could not fetch workstations"
            // );
        } );

    // create
    builder
        .addCase( createWorkstation.fulfilled, ( state, action: any ) => {
            workstationAdaptor.addOne( state, action.payload.workstation );

            // ADMIN.toast.success( "Workstation created" );
        } )
        .addCase( createWorkstation.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError(
            //     action.payload,
            //     "Could not create workstation"
            // );
        } );

    // update
    builder
        .addCase( updateWorkstation.fulfilled, ( state, action ) => {
            const { id, updates: changes } = action.meta.arg;

            workstationAdaptor.updateOne( state, { id, changes } );
            // ADMIN.toast.success( "Workstation updated" );
        } )
        .addCase( updateWorkstation.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError(
            //     action.payload,
            //     "Could not update workstation"
            // );
        } );

    // delete
    builder
        .addCase( deleteWorkstation.fulfilled, ( state, action ) => {
            const { id } = action.meta.arg;

            workstationAdaptor.removeOne( state, id );
        } )
        .addCase( deleteWorkstation.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError(
            //     action.payload,
            //     "Could not delete workstation"
            // );
        } );

    // reset
    builder.addCase( resetWorkstations, () => initialState );
} );

// -----------------------------------------------------------------------------------
// selectors

const selectors = workstationAdaptor.getSelectors<RootState>(
    state => state.workstations
);

const selectByIdWithDefault = createSelector(
    selectors.selectById,
    workstation => {
        return (
            workstation || {
                id: Math.random(),
                name: "",
                categories: [],
                allowed_parameters: [],
                top_vault: 0,
                commodity: false
            }
        );
    }
);

const selectByIds = createSelector(
    selectors.selectAll,
    ( _state: RootState, ids: ID[] ) => ids,
    ( workstations, ids ) => {
        return workstations.filter( workstation => ids.includes( workstation.id ) );
    }
);

const selectOptions = createSelector( selectors.selectAll, workstations => {
    return workstations.map( workstation => ( {
        key: workstation.id,
        value: workstation.id,
        text: workstation.name,
        color_status:workstation.color_status
    } ) );
} );

/**
 * workstation with more number of parameter mapped should be made as default
 */
const selectDefaultWorkstationId = createSelector(
    selectors.selectAll,
    workstations => {
        return (
            maxBy(
                workstations,
                workstation => workstation.allowed_parameters.length
            )?.id || 0
        );
    }
);

const selectShowCommodity = createSelector(
    selectors.selectById,
    workstation => workstation?.commodity || false
);

const selectWorkStationFirstParameter = createSelector(
    selectors.selectAll,
    workstations =>{
        return workstations.filter( workstation => workstation.name == "CS" )[0];
    }
);


const workstationSelectors = Object.assign(
    {},
    {
        selectByIdWithDefault,
        selectByIds,
        selectOptions,
        selectDefaultWorkstationId,
        selectShowCommodity,
        selectWorkStationFirstParameter
    },
    selectors
);

// -----------------------------------------------------------------------------------
// exports

export {
    WorkstationReducer,
    initialState as WorkstationReducerInit,
    workstationSelectors,
};
