import { Select, TextField, TextareaAutosize } from "@mui/material";
import { debounce } from "lodash";
import * as React from "react";
import { API } from "../../api-services";
import { TextArea } from "devextreme-react";
import { useDispatch } from "react-redux";
import { validationCheckCounter } from "../../Redux/Actions/compareopen.action";

export interface ParamsInputTextProps {
  value: any;
  paramsId: any;
  parentId: any;
  placeholderName: any;
  dataType: any;
  disabled?: any;
}

export function LibraryParamsInput(props: ParamsInputTextProps) {
  const { value, paramsId, parentId, placeholderName, dataType, disabled } =
    props;
  const dispatch = useDispatch();
  const [paramsValue, setParamsValue] = React.useState<any>("");
  const ExactDebounce = React.useRef(
    debounce(async (value: any) => {
      await API.put(
        `/xcpep/feature_details/${parentId}/`,
        { product_feature_value_id: paramsId, value: value },
        {},
        0
      )
        .then((res: any) => {
          var randomm: any = Math.floor(Math.random() * 1000000 + 1);
          dispatch(validationCheckCounter(randomm + 1));
        })
        .catch((err: any) => {});
    }, 500)
  ).current;

  React.useEffect(() => {
    return () => {
      ExactDebounce.cancel(); // Cancel the debounced function
    };
  }, []);

  React.useEffect(() => {
    if (value) {
      setParamsValue(value);
    }
  }, [value]);

  const handleonChange = (e: any) => {
    const { value } = e.target;
    setParamsValue(value);
    ExactDebounce(value);
  };

  const getDisableStatus = () =>{
    if(disabled===true){
      return true
    }else if(typeof placeholderName !== "string"){
      return true
    }else{
      return
    }
  }

  const getInputBoxbyType = () => {
    switch (dataType) {
      case "string":
        return (
          <TextField
            placeholder={placeholderName}
            variant="standard"
            disabled={getDisableStatus()}
            sx={{
              width: "100%",
              ".MuiInputBase-root.MuiInput-root": {
                fontSize: "1rem !important",
              },
              "& .MuiInput-underline:before": { borderBottomColor: "transparent" },
              "& .MuiInput-underline:after": {
                borderBottomColor: "primary.main",
              },
            }}
            value={paramsValue || ""}
            onChange={handleonChange}
          />
        );
      case "textarea":
        return (
          <TextareaAutosize
            placeholder={placeholderName}
            minRows={3}
            disabled={disabled || false}
            style={{
              border: "1px solid #bbdefb",
              borderRadius: "3px",
              height: "7.25rem",
              maxHeight: "7.25rem",
              width: "100%",
              color: "#007fff",
              fontFamily: "Roboto",
              // ".MuiInputBase-root.MuiInput-root": {
              //   fontSize: "1rem !important",
              // },
              // "& .MuiInput-underline:before": { borderBottomColor: "transparent" },
              // "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
            }}
            value={paramsValue || ""}
            onChange={handleonChange}
          />
        );
      case "float":
        return (
          <TextField
            placeholder={placeholderName}
            variant="standard"
            disabled={getDisableStatus() || false}
            sx={{
              width: "100%",
              ".MuiInputBase-root.MuiInput-root": {
                fontSize: "1rem !important",
              },
              "& .MuiInput-underline:before": { borderBottomColor: "transparent" },
              "& .MuiInput-underline:after": {
                borderBottomColor: "primary.main",
              },
            }}
            value={paramsValue||""}
            onChange={handleonChange}
            type="number"
          />
        );
      // case "singleselect":
      //   return <Select
      //   variant="standard"
      //   sx={{
      //     ".MuiInputBase-root.MuiInput-root": {
      //       fontSize: "1rem !important",
      //     },
      //     "& .MuiInput-underline:before": { borderBottomColor: "primary.main" },
      //     "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
      //   }}
      //   value={paramsValue}
      //   onChange={handleonChange}
      // />
      default:
        return (
          <TextField
            placeholder={placeholderName}
            variant="standard"
            disabled={ getDisableStatus() || false}
            sx={{
              width: "100%",
              ".MuiInputBase-root.MuiInput-root": {
                fontSize: "1rem !important",
              },
              "& .MuiInput-underline:before": { borderBottomColor: "transparent" },
              "& .MuiInput-underline:after": {
                borderBottomColor: "primary.main",
              },
            }}
            value={paramsValue || ""}
            onChange={handleonChange}
          />
        );
    }
  };
  return <div>{getInputBoxbyType()}</div>;
}
