import * as React from "react";
import {
  AccordionDetails,
  Box,
  Button,
  IconButton,
  Skeleton,
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { darken, lighten, styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import AddIcon from "@mui/icons-material/Add";
import { API } from "../../api-services";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { useHistory, useRouteMatch } from "react-router-dom";
import { DataGrid } from "@mui/x-data-grid";
import MomMeIcon from "../../Assets/images/meetingGroup.png";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import ImageIcon from "@mui/icons-material/Image";
import { AddGroupModal } from "./AddGroupModal";
import { CreateMeetingModal } from "./CreateMeetingModal";
import { useBaseModal } from "../SearchPage/useBaseModal";
import NotificationsIcon from "@mui/icons-material/Notifications";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import EmailIcon from "@mui/icons-material/Email";
import { AddMeetingPointsModal } from "./AddMeetingPointsModal";
import MomDocumentUpload from "../../utlis/MomDocumentUpload";
import { transform } from "lodash";
import { LoadingButton } from "@mui/lab";
import RViewerJS from "viewerjs-react";
import useWindowDimensions from "../../ui-reusable-component/useWindowDimensions";
import CancelIcon from '@mui/icons-material/Cancel';
import SettingsBackupRestoreIcon from '@mui/icons-material/SettingsBackupRestore';
import { AUTH } from "../../Redux/Services/auth.service";
import { useDispatch, useSelector } from "react-redux";
import { useQueryDispatch } from "../../CustomHook/useQueryDispatch";
import { setMOMPermission } from "../../Redux/Actions/Mom.action";
import Swal from "sweetalert2";
import successIcon from "../../Assets/images/success.png"
import { useDocumentTitle } from "../../ui-reusable-component/useDocumentTitle";



const getBackgroundColor = (color: string, mode: string) => {
  return mode === "dark" ? darken(color, 0.6) : lighten(color, 0.8);
};

interface IMomLandingProps { }
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const MinutesofMeeting = (props: IMomLandingProps) => {
  const history = useHistory();
  useDocumentTitle( "Minutes of meeting" );
  const store: any = useSelector((state) => state);
  const dispatch = useDispatch();
  const inputElRef = React.useRef<any>();
  const imageViewer = React.useRef<any>();
  const { url } = useRouteMatch();
  const { projectId, groupId, pointsId } = useRouteParams<any>();
  const addGroup = useBaseParamsModal();
  const createMeeting = useBaseParamsModal();
  const createMeetingPoint = useBaseParamsModal();
  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  const [meetingGroups, setMeetingGroups] = React.useState<any>([]);
  const [isLoadingMeetingGroups, setIsLoadingMeetingGroups] =
    React.useState<boolean>(false);
  const [meetingPoints, setMeetingPoints] = React.useState<any>([]);
  const [isMeetingPointsLoading, setIsMeetingPointsLoading] =
    React.useState<boolean>(false);
  const [isMeetingPointscount, setIsMeetingPointsCount] =
    React.useState<any>(0);
  const [meetingType, setMeetingType] = React.useState<any>();
  const [meetingsData, setMeetingsData] = React.useState<any>([]);
  const [isMeetingDetailsLoading, setIsMeetingDetailsLoading] =
    React.useState<boolean>(false);
  const [createCount, setCreateCount] = React.useState<any>(0);
  const [createMeetingCount, setCreateMeetingCount] = React.useState<any>(0);
  const [uploadLoading, setUploadLoading] = React.useState<boolean>(false);
  const [createMeetingPoints, setCreateMeetingPointsCount] =
    React.useState<any>(0);
  const [projectList, setProjectList] = React.useState<any>([]);
  const [src, setsrc] = React.useState<any>("");
  const [itemIdSend, setItemIdSend] = React.useState<any>();
  const { height, width } = useWindowDimensions();
  const browseFiles = (id: any) => {
    setItemIdSend(id);
    return inputElRef.current?.click?.();
  };

  const handleImageView = (srcc: any) => {
    setsrc(srcc)
    imageViewer.current?.click?.()
  }
  const handleImg = (e: any) => {
    if (e.target.files[0]) {
      MomDocumentUpload(
        [e.target.files[0]],
        itemIdSend,
        setCreateMeetingPointsCount,
        setUploadLoading
      );
      //   USER.updateImage( e.target.files[0], userProfilePic && userProfilePic[0]?.id )
      //   .then( ( res: any ) => {
      // })
    }
  };

  // useQueryDispatch({
  //   query: {
  //     action: setMOMPermission,
  //     arg: {
  //       project: projectId,
  //       action:true
  //     },
  //   },
  // });
  React.useEffect(() => {
    dispatch(setMOMPermission(projectId, true));
  }, [projectId])


  const Column = [
    {
      field: "id",
      headerName: "S.No",
      minWidth: 20, flex: 1,

    },
    {
      field: "points",
      headerName: " Discussion points",
      minWidth: width > 1440 ? 600 : 400,
      flex: 1,

    },
    {
      field: "file_name",
      headerName: "View",
      flex: 1,
      renderCell: (cellValues: any) => {
        return (
          <Box sx={{ marginTop: 0.7 }}>
            {cellValues.row.file_name ? (
              <ImageIcon sx={{ cursor: "pointer" }} onClick={() =>
                handleImageView(cellValues.row.absolute_path)
              } />
            ) : null}
          </Box>
        );
      },
    },
    { field: "responsible_name", headerName: "Responsible", minWidth: 120, flex: 1 },
    { field: "date", headerName: "DOC", minWidth: 100, flex: 1 },
    { field: "status", headerName: "Status", minWidth: 80, flex: 1 },
    { field: "remark", headerName: "Remarks", minWidth: 120, flex: 1 },
    {
      field: "action",
      headerName: "",
      flex: 1,
      align: 'right',
      minWidth: width > 1440 ? 180 : 130,
      renderCell: (cellValues: any) => {
        return (
          <>
            {cellValues.row.status !== "open" && <Box onClick={() => {
              API.patch(`/drive/meeting_detail/${cellValues.row?.pointsId}/`, { status: "open" }, {}, 0).then((res: any) => {
                setCreateMeetingPointsCount((prev: any) => prev + 1)
              }).catch((err: any) => {
                console.log(err)
              })
            }}>
              <SettingsBackupRestoreIcon titleAccess="Restore Point" sx={{ cursor: 'pointer', marginRight: '1rem' }} />
            </Box>}
            {cellValues.row.status === "open" && cellValues.row.status === "open" && store?.MOMPermission?.action?.includes("U") && <EditIcon
              sx={{ cursor: "pointer" }}
              onClick={() => createMeetingPoint.open(cellValues.row)}
            />}

            {cellValues.row.status === "open" && store?.MOMPermission?.action?.includes("U") && <LoadingButton sx={{ minWidth: '39px' }} loading={uploadLoading && cellValues.row.pointsId === itemIdSend ? true : false}>
              <FileUploadIcon
                titleAccess="Upload File"
                sx={{ cursor: "pointer" }}
                onClick={() => browseFiles(cellValues.row.pointsId)}
              />
              <input
                type="file"
                accept=".png, .jpg, .jpeg"
                id="photo"
                style={{ display: "none" }}
                onChange={handleImg}
                ref={inputElRef}
              //  disabled={ type === "to_user" ? true : false }
              />
            </LoadingButton>}

            {cellValues.row.status === "open" &&
              <LoadingButton disabled={cellValues?.row?.notification ? false : true} onClick={(e: any) => {
                e.stopPropagation()
                API.get(
                  "/drive/notification/",
                  { url: `/#${url}`, responsible: cellValues.row.responsible, id: cellValues.row.pointsId, project: projectId },
                  0
                )
                  .then((res: any) => {
                    Swal.fire({
                      html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
                  <br />
                  <p style="color:#007fff;">Notification sent Successfully!</p>   
                   </div>`,
                    });
                  })
                  .catch((err: any) => {
                    console.log(err);
                  });
              }}>
                <NotificationsIcon
                  titleAccess="Send Notification"
                  sx={{ cursor: "pointer", color: cellValues?.notification ? "#ffba00" : "", marginRight: '5px', marginLeft: '-3px' }}

                /></LoadingButton>}
            {cellValues.row.status === "open" && store?.MOMPermission?.action?.includes("D") && <Box sx={{ marginTop: '0.15rem' }} onClick={() => {
              API.patch(`/drive/meeting_detail/${cellValues.row?.pointsId}/`, { status: "closed" }, {}, 0).then((res: any) => {
                setCreateMeetingPointsCount((prev: any) => prev + 1)
              }).catch((err: any) => {
                console.log(err)
              })
            }} >
              <CancelIcon titleAccess="Close Point" sx={{ cursor: 'pointer', marginRight: '1rem' }} />
            </Box>}

          </>
        );
      },
    },
  ];

  let Rows: any = [];

  meetingsData &&
    meetingsData?.map((item: any, index: number) => {
      Rows.push({
        id: index + 1,
        points: item.points,
        path: item.path,
        responsible_name: item.responsible_name,
        date: item.date,
        status: item.status,
        remark: item.remark,
        responsible: item.responsible,
        pointsId: item.id,
        absolute_path: item?.absolute_path,
        point_status: item?.point_status,
        file_name: item?.file_name,
        notification: item?.notification,
      });
    });

  function getRandomColor() {
    var letters = "BCDEF".split("");
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * letters.length)];
    }
    return color;
  }
  const handleChange =
    (panel: any) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      if (newExpanded) {
        getMeetingPointsByGroupId(parseInt(panel));
        history.push(`/MinutesofMeeting/${projectId}/${groupId}/${panel}`);
      }
      setExpanded(newExpanded ? panel : false);
    };

  const getMeetingGroupCards = () => {
    setIsLoadingMeetingGroups(true);
    API.get("/drive/meeting_group/", { project: projectId }, 0)
      .then((res: any) => {
        setMeetingGroups(res.data);
        setIsLoadingMeetingGroups(false);
      })
      .catch((err: any) => {
        console.log(err);
        setIsLoadingMeetingGroups(false);
      });
  };

  const getAllMeetingPoints = () => {
    setIsMeetingPointsLoading(true);

    API.get("/drive/meeting/", { project: projectId }, 0)
      .then((res: any) => {
        setIsMeetingPointsLoading(false);
        setMeetingPoints(
          res.data.filter((item: any) => item.group === groupId)
        );
      })
      .catch((err: any) => {
        console.log(err);
        setIsMeetingPointsLoading(false);
      });
  };

  React.useEffect(() => {
    if (pointsId != 0) {
      getMeetingPointsByGroupId(pointsId);
    }
  }, [pointsId, createMeetingPoints]);
  React.useEffect(() => {
    if (pointsId != 0) {
      setExpanded(pointsId);
    }
  }, [pointsId]);

  const getMeetingPointsByGroupId = (meetingId: any) => {
    setIsMeetingDetailsLoading(true);
    API.get("/drive/meeting_detail/", { meeting: meetingId }, 0)
      .then((res: any) => {
        setMeetingsData(res?.data);
        setIsMeetingDetailsLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setIsMeetingDetailsLoading(false);
      });
  };

  const getAllProjects = () => {
    API.get("/auth/mom_permission/", { project_list: true }, 0)
      .then((res: any) => {
        if (projectId == 0) {
          history.push(
            `/MinutesofMeeting/${res.data?.length > 0 ? res.data[0]?.id : 0
            }/0/0`
          );
        } else {
          history.push(`/MinutesofMeeting/${projectId}/${groupId}/${pointsId}`);
        }

        setProjectList(res.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    getMeetingGroupCards();
  }, [projectId, createCount]);
  React.useEffect(() => {
    getAllMeetingPoints();
  }, [groupId, createMeetingCount]);
  React.useEffect(() => {
    getAllProjects();
  }, []);

  const getData = (type: string) => {
    switch (type) {
      case "admin":
        return projectList
          .filter((item: any) => item.id == projectId)[0]
          ?.project_admin?.join(", ");
    }
  };

  const sendEmailToAttendees = (e: any, item: any) => {
    e.stopPropagation();
    sessionStorage.setItem(
      "attendees_name",
      JSON.stringify(item?.attendees_email)
    );
    history.push(
      `/MinutesofMeeting/${projectId}/${groupId}/${item.id}/sentmail`
    );
  };

  const sendNotification = (e: any, item: any) => {
    e.stopPropagation();
    API.get(
      "/drive/notification/",
      {
        url: `/#${url}`,
        project: projectId,
        group: groupId,
        meeting: item.id,
      },
      0
    )
      .then((res: any) => {
        Swal.fire({
          html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
        <br />
        <p style="color:#007fff;">Notification sent Successfully!</p>   
         </div>`,
        });
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const editClicked = (e: any, item: any) => {
    e.stopPropagation();
    addGroup.open(item);
  };

  const createClick = () => {
    addGroup.open("Add");
  };

  const groupDeleteClick = (IDD: any) => {
    API.delete(`/drive/meeting_group/${IDD}/`, {}, 0)
      .then((res: any) => {
        getMeetingGroupCards();
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  return (
    <Box>
      <Box sx={{ padding: "0 1rem", marginTop: '0.5rem' }}>
        <Box
          sx={{ display: "flex", alignItems: "center", columnGap: "0.5rem" }}
        >
          <Box>
            <IconButton sx={{ cursor: "pointer" }}>
              <ArrowBackIcon color="primary" titleAccess="Go Back" onClick={() => {
                if (AUTH.user_category === "Internal") {
                  history.push("/internallanding")
                } else {
                  history.push(`/landing`)
                }
              }} />
            </IconButton>
          </Box>
          <Box sx={{ width: '95%', display: 'flex', alignItems: 'center', columnGap: '0.5rem', padding: '0.5rem' }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3">
            {projectList.map((item: any) => {
              return (
                <Box
                  sx={
                    item.id == projectId
                      ? {
                        backgroundColor: "primary.main",
                        width: "fit-content",
                        cursor: "pointer",
                        padding: "0.5rem 1rem",
                        borderRadius: "5px",
                        boxShadow:
                          "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px",
                      }
                      : {
                        backgroundColor: "white",
                        width: "fit-content",
                        cursor: "pointer",
                        padding: "0.5rem 1rem",
                        borderRadius: "5px",
                        boxShadow:
                          "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px",
                      }
                  }
                  onClick={() => history.push(`/MinutesofMeeting/${item.id}/0/0`)}
                >
                  <Typography
                    sx={

                      item?.id == projectId
                        ? { fontSize: "1rem", color: "white", whiteSpace: 'nowrap' }
                        : { fontSize: "1rem", color: "primary.main", whiteSpace: 'nowrap' }
                    }
                  >
                    {item?.name}
                  </Typography>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
      <Box sx={{ padding: "0.3rem 1rem", height: "89vh" }}>
        <Box
          sx={{
            display: "flex",
            alignContent: "center",
            columnGap: "1rem",
            // marginTop: "1rem",
          }}
        >
          <Box
            sx={{ width: "20%", padding: "0.2rem 0.5rem", height: "87vh" }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3"
          >
            {projectList && projectList?.length > 0 && store?.MOMPermission?.action?.includes("C") && <Box sx={{ width: '100%', marginBottom: '0.5rem' }}>
              <Button
                variant="contained"
                size="small"
                onClick={() => createClick()}
                sx={{
                  '&:hover': {
                    transform: 'scale(1.1)',
                    transition: 'transform 0.5s ease'
                  }
                }}
              >
                Create Meeting Group
              </Button>
            </Box>}
            {!isLoadingMeetingGroups ? (
              meetingGroups?.map((item: any) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      height: "auto",
                      backgroundImage:
                        item.id == groupId
                          ? "linear-gradient(to left, #007fff24, #ffffff)"
                          : "",
                      // transform:item.id == groupId ?'Scale(1.05':"",
                      transition:
                        item.id == groupId ? "transform 0.5s ease" : "",
                      backgroundColor: item.id == groupId ? "#007fff24" : "",
                      marginBottom: "1rem",
                      cursor: "pointer",
                      boxShadow:
                        "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px",
                      borderRadius: "10px",
                      "&:hover": {
                        transform: "scale(1.03)",
                        transition: "transform 0.5s ease",
                      },
                    }}
                    onClick={() => {
                      setMeetingType(item?.external ? "External" : "Internal");
                      history.push(
                        `/MinutesofMeeting/${projectId}/${item?.id}/${pointsId}`
                      );
                    }}
                  >

                    <Box
                      sx={{
                        backgroundColor: item?.external
                          ? "primary.main"
                          : "primary.light",
                        color: item?.external ? "white" : "primary.main",
                        padding: "5px",
                        borderTopLeftRadius: "10px",
                        borderBottomLeftRadius: "10px",
                        display: 'flex',
                        alignItems: 'center',
                        width: "2rem",
                      }}
                    >
                      <Typography
                        sx={{
                          transform: "rotate(270deg)",
                          fontSize: "1rem",
                          marginLeft: "-1rem",
                          position: "relative",
                          lineHeight: '1.2',
                        }}
                      >
                        {item?.external ? "External" : "Internal"}
                      </Typography>
                    </Box>
                    <Box sx={{ padding: "10px", width: "100%" }}>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "space-between",
                          columnGap: "0.5rem",
                        }}
                      >
                        <img
                          src={MomMeIcon}
                          style={{ width: "2.2rem", height: "auto" }}
                        />
                        <Typography
                          sx={{
                            fontSize: "1rem",
                            width: "100%",
                            color: "#242e34",
                            lineHeight: "1",
                          }}
                        >
                          {item?.created_at ? item?.created_at : ""}
                        </Typography>
                        <Typography
                          style={{ display: "flex", columnGap: "5px" }}
                        >
                          {store?.MOMPermission?.action?.includes("U") && <EditIcon onClick={(e: any) => editClicked(e, item)} />}
                          {/* <DeleteIcon onClick={() => groupDeleteClick(item.id)} /> */}
                        </Typography>
                      </Box>
                      <Box sx={{ padding: "0.5rem 0 0 0" }}>
                        {/* <Typography sx={{ fontSize: '1rem',width:'100%',textAlign:'right' }}>Meeting Date: </Typography> */}
                        <Typography
                          style={{ fontSize: "1rem", fontWeight: "500" }}
                        >
                          {item?.name}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "1rem",
                            display: "flex",
                            columnGap: "0.5rem",
                            alignItems: "center",
                          }}
                        >
                          <Typography sx={{ color: "green", fontSize: "1rem" }}>
                            Closed: {item?.closed}{" "}
                          </Typography>{" "}
                          |{" "}
                          <Typography sx={{ color: "red", fontSize: "1rem" }}>
                            Open: {item.open}{" "}
                          </Typography>{" "}
                          |{" "}
                          <Typography
                            sx={{ color: "primary.main", fontSize: "1rem" }}
                          >
                            Notes: {item?.notes_count}
                          </Typography>
                        </Typography>
                        <Typography sx={{ fontSize: '1rem' }}> Created By: {item?.created_by ? item?.created_by : "---"}</Typography>
                        {/* <Typography style={{ fontSize: "1rem", color: "#7c7c7c" }}>
                      Meeting type : {item?.external ? "External":"Internal"}
                    </Typography> */}
                      </Box>
                    </Box>
                  </Box>
                );
              })
            ) : (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  rowGap: "1rem",
                }}
              >
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height="100px"
                  sx={{ borderRadius: "5px" }}
                  animation="wave"
                />
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height="100px"
                  sx={{ borderRadius: "5px" }}
                  animation="wave"
                />
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height="100px"
                  sx={{ borderRadius: "5px" }}
                  animation="wave"
                />
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height="100px"
                  sx={{ borderRadius: "5px" }}
                  animation="wave"
                />
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height="100px"
                  sx={{ borderRadius: "5px" }}
                  animation="wave"
                />
              </Box>
            )}
          </Box>
          <Box
            sx={{ width: "80%", height: "87vh" }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3"
          >
            <Box>
              <Box sx={{ margin: "0 1.1rem 0 0.5rem" }}>
                <Table>
                  {meetingGroups?.length > 0 && groupId != 0 ? (
                    <TableHead>
                      <TableRow>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottom: "none",
                            width: "3rem",
                          }}
                        ></TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottom: "none",
                            width: "6rem",
                          }}
                        >
                          Date
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottom: "none",
                            width:
                              meetingType && meetingType === "External"
                                ? "25vw"
                                : "45vw",
                          }}
                        >
                          Internal Attendees
                        </TableCell>
                        {meetingType && meetingType === "External" && (
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottom: "none",
                              width: "25vw",
                            }}
                          >
                            External Attendees
                          </TableCell>
                        )}
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottom: "none",
                            width: "9rem",
                          }}
                        >
                          Total Points
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottom: "none",
                            width: "5rem",
                          }}
                        >
                          Closed
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottom: "none",
                            width: "5rem",
                          }}
                        >
                          Opened
                        </TableCell>
                        {/* <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottom: "none",
                            width: { lg: "5rem", xl: "5rem" },
                          }}
                        >
                          Actions
                        </TableCell> */}
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottom: "none",
                            width: "12rem",
                            textAlign: "right",
                          }}
                        >
                          {store?.MOMPermission?.action?.includes("C") && <Button onClick={() => createMeeting.open("Add")}
                            startIcon={<AddIcon sx={{ marginRight: '-0.5rem' }} />}>
                            Add Meeting
                          </Button>}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                  ) : (
                    store?.MOMPermission?.action?.includes("C") && <Box
                      sx={{
                        marginTop: "26%",
                        marginRight: "20%",
                        cursor: "pointer",
                      }}
                      textAlign="center"
                      onClick={() => createClick()}
                    >
                      {meetingGroups?.length === 0 && groupId == 0
                        ? "No Meeting group created, Please create meeting group"
                        : "Select Group"}
                    </Box>
                  )}
                </Table>
              </Box>
              {meetingPoints?.length > 0 ? (
                meetingPoints?.map((item: any, index: any) => {
                  return !isMeetingPointsLoading ? (
                    <Accordion
                      expanded={expanded === item?.id}
                      onChange={handleChange(item?.id)}
                    >
                      <AccordionSummary
                        aria-controls="panel1d-content"
                        id={item?.id}
                        sx={{
                          minHeight: "36px",
                          // pointerEvents: "none"
                        }}
                      >
                        <Box sx={{ paddingLeft: "0.5rem", width: "100%" }}>
                          <Table>
                            <TableHead>
                              <TableRow>
                                {/* <TableCell
                                sx={{
                                  padding: "0.2rem 0.5rem",
                                  borderBottom: "none",
                                  minWidth: "2rem",
                                  flex: "1",
                                }}
                              >
                                {index + 1}
                              </TableCell> */}
                                <TableCell
                                  sx={{
                                    padding: "0.2rem 0.5rem",
                                    borderBottom: "none",
                                    width: "7rem",
                                    flex: "1",
                                  }}
                                >
                                  {item?.date}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "0.2rem 0.5rem",
                                    borderBottom: "none",
                                    width:
                                      item?.external_attendees_name?.length > 0
                                        ? "25vw"
                                        : "45vw",
                                    // flex: "1",
                                  }}
                                >
                                  {item?.attendees_name?.join(", ")}
                                </TableCell>
                                {item?.external_attendees_name?.length > 0 && (
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottom: "none",
                                      width:
                                        item?.external_attendees_name?.length >
                                          0
                                          ? "22vw"
                                          : "25vw",
                                      // flex: "1",
                                    }}
                                  >
                                    {item?.external_attendees_name?.join(", ")}
                                  </TableCell>
                                )}
                                <TableCell
                                  sx={{
                                    padding: "0.2rem 0.5rem",
                                    borderBottom: "none",
                                    textAlign: "center",
                                    width: "9rem",
                                  }}
                                >
                                  {item?.open + item?.closed}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "0.2rem 0.5rem",
                                    borderBottom: "none",
                                    textAlign: "center",
                                    width: "5rem",
                                  }}
                                >
                                  {item?.closed}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "0.2rem 0.5rem",
                                    borderBottom: "none",
                                    textAlign: "center",
                                    width: { lg: "5rem", xl: "5rem" },
                                  }}
                                >
                                  {item?.open}
                                </TableCell>
                                <TableCell
                                  sx={{
                                    padding: "0.2rem 0.5rem",
                                    borderBottom: "none",
                                    width: "12rem",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      columnGap: "5px",
                                      justifyContent: 'flex-end',
                                    }}
                                  >
                                    {store?.MOMPermission?.action?.includes("U") && <EditIcon
                                      onClick={(e: any) => {
                                        e.stopPropagation()
                                        createMeeting.open(item)
                                      }}
                                    />}
                                    <EmailIcon
                                      onClick={(e: any) => sendEmailToAttendees(e, item)}
                                    />
                                    <LoadingButton disabled={item?.notification ? false : true} onClick={(e: any) => {
                                      e.preventDefault();
                                      sendNotification(e, item)
                                    }}>
                                      <NotificationsIcon
                                        sx={{
                                          cursor: "pointer",
                                          color: item?.notification ? "#ffba00" : "",
                                        }}

                                      />
                                    </LoadingButton>
                                    {/* <IconButton> */}
                                    {store?.MOMPermission?.action?.includes("C") && <AddIcon
                                      titleAccess="Add Points"
                                      sx={{
                                        cursor: "pointer",
                                        color: "primary.main",
                                      }}
                                      onClick={(e: any) => {
                                        e.stopPropagation();
                                        history.push(`/MinutesofMeeting/${projectId}/${groupId}/${item.id}`)
                                        createMeetingPoint.open("Add")
                                      }
                                      }
                                    />}
                                    {/* </IconButton> */}
                                  </Box>
                                </TableCell>
                              </TableRow>
                            </TableHead>
                          </Table>
                        </Box>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Box
                          sx={{
                            height: {
                              sm: "60vh",
                              md: "60vh",
                              lg: "60vh",
                              xl: "60vh",
                            },
                            width: "100%",
                            "& .super-app-theme--overdue": {
                              color: "primary.main !important",
                              bgcolor: (theme) =>
                                getBackgroundColor("#d32f2f", "light"),
                              "&:hover": {
                                // "#FF6962 !important",
                                backgroundColor: "#F8AFA6 !important",
                              },
                            },
                          }}
                        >
                          {!isMeetingDetailsLoading ? (
                            //@ts-ignore
                            <DataGrid
                              headerHeight={48}
                              rowHeight={36}
                              {...{
                                columns: Column,
                                rows: Rows,
                              }}
                              pageSize={59}
                              rowsPerPageOptions={[59]}
                              density={"compact"}
                              getRowClassName={(params) => `super-app-theme--${params.row.point_status.replace(/ /g, "")}`
                              }
                            />
                          ) : (
                            <Box>
                              <Skeleton />
                              <Skeleton animation="wave" />
                              <Skeleton animation={false} />
                              <Skeleton />
                              <Skeleton animation="wave" />
                              <Skeleton animation={false} />
                            </Box>
                          )}
                        </Box>
                      </AccordionDetails>
                    </Accordion>
                  ) : (
                    <Box>
                      <Skeleton sx={{ height: 50 }} animation="wave" />
                    </Box>
                  );
                })
              ) : (
                store?.MOMPermission?.action?.includes("C") && <Box
                  textAlign="center"
                  onClick={() => createMeeting.open("Add")}
                  sx={{
                    marginTop: "26%",
                    marginRight: "20%",
                    cursor: "pointer",
                  }}
                >
                  {meetingGroups?.length !== 0 &&
                    groupId !== 0 &&
                    " No meeting created yet,Add a meeting."}
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      {addGroup.isOpen && (
        <AddGroupModal
          isOpen={addGroup.isOpen}
          onClose={addGroup.close}
          groupDetails={addGroup.propsId}
          setCreateCount={setCreateCount}
        />
      )}
      {createMeeting.isOpen && (
        <CreateMeetingModal
          isOpen={createMeeting.isOpen}
          onClose={createMeeting.close}
          groupData={meetingGroups && meetingGroups}
          setCreateMeetingCount={setCreateMeetingCount}
          meetingDetails={createMeeting.propsId}
        />
      )}
      {createMeetingPoint.isOpen && (
        <AddMeetingPointsModal
          isOpen={createMeetingPoint.isOpen}
          onClose={createMeetingPoint.close}
          groupData={meetingGroups && meetingGroups}
          setCreateMeetingCount={setCreateMeetingPointsCount}
          pointDetails={createMeetingPoint.propsId}
        />
      )}
      {/* @ts-ignore */}
      <RViewerJS>
        {src !== "" && <img src={src} alt="image" ref={imageViewer} style={{ display: 'none' }} />}
      </RViewerJS>
    </Box>
  );
};

export default MinutesofMeeting;
