import React, { useEffect, useRef, useState } from "react";
import styles from "./MilestonePage.module.scss";
import { useHistory, useParams } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { MileStone } from "../../Redux/Services/milestone.service";
import MilestoneRows from "./MilestoneRows";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import EmailIcon from "@mui/icons-material/Email";
import LoadingButton from "@mui/lab/LoadingButton";
import AddIcon from "@mui/icons-material/Add";
import { API } from "../../api-services";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import bvIcon from "../../Assets/images/gantt_chart.png";
import ArticleIcon from "@mui/icons-material/Article";
import PdfModule from "../BannerPage/PdfModule";
import milestoneFileUpload from "./Milestone";
import DownloadIcon from "@mui/icons-material/Download";
import ProposalDocumentModal from "./ProposalDocumentModal";
import { useBaseModal } from "../SearchPage/useBaseModal";
import CancelIcon from "@mui/icons-material/Cancel";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useDocumentTitle } from "../../ui-reusable-component/useDocumentTitle";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Params {
  projectId: any;
}

type Anchor = "top" | "left" | "bottom" | "right";

interface MilestonePageI { }

const MilestonePage: React.FC<MilestonePageI> = (props) => {
  const { projectId } = useParams<Params>();
  useDocumentTitle("Milestone");
  const [mileStone, setMileStone] = useState<any>([]);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const [projectList, setProjectList] = useState<any>();
  const [selectedProject, setSelectedProject] = useState<any>([]);
  const [products, setproducts] = useState<any>();
  const [openFilter, setOpenFilter] = React.useState(false);
  const [openPdfViewer, setOpenPdfViewer] = React.useState(false);
  const [loader, setLoader] = useState<boolean>(false);
  const [projectDetails, setProjectDetails] = React.useState<any>();
  const [selectFilterStatus, setSelectFilterStatus] = React.useState<any>("All");
  const [selectFilterActivityType, setSelectFilterActivityType] = React.useState<any>("All");
  // const [selectFilterActivity, setSelectFilterActivity] = React.useState<any>("true");
  const [selectFilterActivity, setSelectFilterActivity] = React.useState<any>("All");
  const [selectFilterDepartment, setSelectFilterDepartment] = React.useState<any>("All");
  const [selectFilterGroup, setSelectFilterGroup] = React.useState<any>("All");
  const [idUpdated, setIdUpdated] = React.useState<any>();
  const [filePathGet, setFilePathGet] = React.useState<any>();
  const [documentData, setDocumentData] = useState<any>();
  const [getdocumentDataAPICall, setGetdocumentDataAPICall] = useState<boolean>(true);
  const [filePathName, setFilePathName] = React.useState<any>();
  const [proposalFileAvailable, setProposalFileAvailable] = React.useState<any>();
  const [FilterItem, setFilterItem] = React.useState<any>();
  const history: any = useHistory();
  const Documentmodal = useBaseModal();
  const [checked, setChecked] = React.useState(true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };
  const getDetails = () => {
    setDataLoading(true);
    // MileStone.getMileStoneRaw(Number(projectId), 1)
    //   .then((res: any) => {
    //     setMileStone(res.data);
    //     setIdUpdated(undefined);
    //     setDataLoading(false);
    //     MileStone.getMileStoneRaw(Number(projectId), 2).then((res: any) => {
    //       setMileStone((prev: any) => [...prev, ...res.data]);
    //       MileStone.getMileStoneRaw(Number(projectId), 3).then((res: any) => {
    //         setMileStone((prev: any) => [...prev, ...res.data]);
    //         MileStone.getMileStoneRaw(Number(projectId), 4).then((res: any) => {
    //           setMileStone((prev: any) => [...prev, ...res.data]);
    //           MileStone.getMileStoneRaw(Number(projectId), 5).then(
    //             (res: any) => {
    //               setMileStone((prev: any) => [...prev, ...res.data]);
    //               MileStone.getMileStoneRaw(Number(projectId), 6).then(
    //                 (res: any) => {
    //                   setMileStone((prev: any) => [...prev, ...res.data]);
    //                   MileStone.getMileStoneRaw(Number(projectId), 7).then(
    //                     (res: any) => {
    //                       setMileStone((prev: any) => [...prev, ...res.data]);
    //                     }
    //                   );
    //                 }
    //               );
    //             }
    //           );
    //         });
    //       });
    //     });
    //   })
    //   .catch((err) => console.log(err, "Server Error"));
    MileStone.getMileStonePageCount(
      Number(projectId),
      selectFilterActivity,
      selectFilterStatus,
      selectFilterActivityType,
      selectFilterDepartment,
      selectFilterGroup,
      true
    ).then((res: any) => {
      const countPage: any = res.data?.page_count;
      MileStone.getMileStone(
        Number(projectId),
        1,
        selectFilterActivity,
        selectFilterStatus,
        selectFilterActivityType,
        selectFilterDepartment,
        selectFilterGroup
      )
        .then((res: any) => {
          setMileStone(res.data);
          setIdUpdated(undefined);
          setDataLoading(false);
          if (countPage >= 2) {
            MileStone.getMileStone(
              Number(projectId),
              2,
              selectFilterActivity,
              selectFilterStatus,
              selectFilterActivityType,
              selectFilterDepartment,
              selectFilterGroup
            )
              .then((res: any) => {
                setMileStone((prev: any) => [...prev, ...res.data]);
                if (countPage >= 3) {
                  MileStone.getMileStone(
                    Number(projectId),
                    3,
                    selectFilterActivity,
                    selectFilterStatus,
                    selectFilterActivityType,
                    selectFilterDepartment,
                    selectFilterGroup
                  )
                    .then((res: any) => {
                      setMileStone((prev: any) => [...prev, ...res.data]);
                      if (countPage >= 4) {
                        MileStone.getMileStone(
                          Number(projectId),
                          4,
                          selectFilterActivity,
                          selectFilterStatus,
                          selectFilterActivityType,
                          selectFilterDepartment,
                          selectFilterGroup
                        )
                          .then((res: any) => {
                            setMileStone((prev: any) => [...prev, ...res.data]);
                            if (countPage >= 5) {
                              MileStone.getMileStone(
                                Number(projectId),
                                5,
                                selectFilterActivity,
                                selectFilterStatus,
                                selectFilterActivityType,
                                selectFilterDepartment,
                                selectFilterGroup
                              )
                                .then((res: any) => {
                                  setMileStone((prev: any) => [
                                    ...prev,
                                    ...res.data,
                                  ]);
                                  if (countPage >= 6) {
                                    MileStone.getMileStone(
                                      Number(projectId),
                                      6,
                                      selectFilterActivity,
                                      selectFilterStatus,
                                      selectFilterActivityType,
                                      selectFilterDepartment,
                                      selectFilterGroup
                                    )
                                      .then((res: any) => {
                                        setMileStone((prev: any) => [
                                          ...prev,
                                          ...res.data,
                                        ]);
                                        if (countPage == 7) {
                                          MileStone.getMileStone(
                                            Number(projectId),
                                            7,
                                            selectFilterActivity,
                                            selectFilterStatus,
                                            selectFilterActivityType,
                                            selectFilterDepartment,
                                            selectFilterGroup
                                          )
                                            .then((res: any) => {
                                              setMileStone((prev: any) => [
                                                ...prev,
                                                ...res.data,
                                              ]);
                                            })
                                            .catch((err) =>
                                              console.log(err, "Server Error")
                                            );
                                        }
                                      })
                                      .catch((err) =>
                                        console.log(err, "Server Error")
                                      );
                                  }
                                })
                                .catch((err) =>
                                  console.log(err, "Server Error")
                                );
                            }
                          })
                          .catch((err) => console.log(err, "Server Error"));
                      }
                    })
                    .catch((err) => console.log(err, "Server Error"));
                }
              })
              .catch((err) => console.log(err, "Server Error"));
          }
        })
        .catch((err) => console.log(err, "Server Error"));
    });
  };

  const getDetailsUpdate = () => {
    MileStone.getMileStonePageCount(
      Number(projectId),
      selectFilterActivity,
      selectFilterStatus,
      selectFilterActivityType,
      selectFilterDepartment,
      selectFilterGroup,
      true
    ).then((res: any) => {
      const countPage: any = res.data?.page_count;
      MileStone.getMileStone(
        Number(projectId),
        1,
        selectFilterActivity,
        selectFilterStatus,
        selectFilterActivityType,
        selectFilterDepartment,
        selectFilterGroup
      )
        .then((res: any) => {
          setMileStone(res.data);
          setIdUpdated(undefined);
          setDataLoading(false);
          if (countPage >= 2) {
            MileStone.getMileStone(
              Number(projectId),
              2,
              selectFilterActivity,
              selectFilterStatus,
              selectFilterActivityType,
              selectFilterDepartment,
              selectFilterGroup
            )
              .then((res: any) => {
                setMileStone((prev: any) => [...prev, ...res.data]);
                countPage == 2 && setIdUpdated(undefined);
                if (countPage >= 3) {
                  MileStone.getMileStone(
                    Number(projectId),
                    3,
                    selectFilterActivity,
                    selectFilterStatus,
                    selectFilterActivityType,
                    selectFilterDepartment,
                    selectFilterGroup
                  )
                    .then((res: any) => {
                      setMileStone((prev: any) => [...prev, ...res.data]);
                      countPage == 3 && setIdUpdated(undefined);
                      if (countPage >= 4) {
                        MileStone.getMileStone(
                          Number(projectId),
                          4,
                          selectFilterActivity,
                          selectFilterStatus,
                          selectFilterActivityType,
                          selectFilterDepartment,
                          selectFilterGroup
                        )
                          .then((res: any) => {
                            setMileStone((prev: any) => [...prev, ...res.data]);
                            countPage == 4 && setIdUpdated(undefined);
                            if (countPage >= 5) {
                              MileStone.getMileStone(
                                Number(projectId),
                                5,
                                selectFilterActivity,
                                selectFilterStatus,
                                selectFilterActivityType,
                                selectFilterDepartment,
                                selectFilterGroup
                              )
                                .then((res: any) => {
                                  setMileStone((prev: any) => [
                                    ...prev,
                                    ...res.data,
                                  ]);
                                  countPage == 5 && setIdUpdated(undefined);
                                  if (countPage >= 6) {
                                    MileStone.getMileStone(
                                      Number(projectId),
                                      6,
                                      selectFilterActivity,
                                      selectFilterStatus,
                                      selectFilterActivityType,
                                      selectFilterDepartment,
                                      selectFilterGroup
                                    )
                                      .then((res: any) => {
                                        setMileStone((prev: any) => [
                                          ...prev,
                                          ...res.data,
                                        ]);
                                        countPage == 6 && setIdUpdated(undefined);
                                        if (countPage == 7) {
                                          MileStone.getMileStone(
                                            Number(projectId),
                                            7,
                                            selectFilterActivity,
                                            selectFilterStatus,
                                            selectFilterActivityType,
                                            selectFilterDepartment,
                                            selectFilterGroup
                                          )
                                            .then((res: any) => {
                                              setMileStone((prev: any) => [
                                                ...prev,
                                                ...res.data,
                                              ]);
                                              countPage == 7 && setIdUpdated(undefined);
                                            })
                                            .catch((err) =>
                                              console.log(err, "Server Error")
                                            );
                                        }
                                      })
                                      .catch((err) =>
                                        console.log(err, "Server Error")
                                      );
                                  }
                                })
                                .catch((err) =>
                                  console.log(err, "Server Error")
                                );
                            }
                          })
                          .catch((err) => console.log(err, "Server Error"));
                      }
                    })
                    .catch((err) => console.log(err, "Server Error"));
                }
              })
              .catch((err) => console.log(err, "Server Error"));
          }
        })
        .catch((err) => console.log(err, "Server Error"));
    });

    // MileStone.getMileStone(Number(projectId))
    //   .then((res: any) => {
    //     setMileStone(res.data);
    //     setIdUpdated(undefined);
    //   })
    //   .catch((err) => console.log(err, "Server Error"));
  };

  useEffect(() => {
    API.get("/xcpep/project/", {
      id: projectId,
    },
      0).then((res: any) => {
        setProjectDetails(res?.data);
      });

    API.get("/xcpep/project_scope_verification/", {
      project: projectId,
      get_dept_groups: true
    },
      0).then((res: any) => {
        setFilterItem(res?.data);
      });
  }, [projectId])

  useEffect(() => {
    getDetails();

    MileStone.getProject(projectId)
      .then((res: any) => {
        setproducts(res.data);
      })
      .catch((err) => console.log(err, "Server Error"));

    setSelectedProject([Number(projectId)]);
  }, [projectId]);

  useEffect(() => {
    API.get(`/customer/dashboard/`, { project_list_bin: true }, 0)
      .then((res: any) => {
        setProjectList(res?.data);
      })
      .catch((err: any) => {
        console.log(err, "error");
      });
  }, []);

  useEffect(() => {
    API.get(
      `/xcpep/proposal_document_view/`,
      { id: projectId, status: "true" },
      0
    )
      .then((res: any) => {
        setProposalFileAvailable(res?.data);
      })
      .catch((err: any) => {
        console.log(err, "error");
      });
  }, []);

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }
        setState({ ...state, [anchor]: open });
        if (open) {
          setSelectedProject([Number(projectId)]);
        }
      };

  const selectAllProject = (productIds: any) => {
    setSelectedProject([productIds]);
  };

  const getselectedProjectBackgroundColor = (idd: any) => {
    if (selectedProject?.includes(idd)) {
      return "primary.main";
    } else {
      return "primary.light";
    }
  };

  const getSelectedProjectTextColor = (idd: any) => {
    if (selectedProject?.includes(idd)) {
      return "primary.light";
    } else {
      return "primary.main";
    }
  };

  const redirectToProject: any = () => {
    history.push(`/Milestone/${selectedProject[0]}`);
  };

  const list = (anchor: Anchor) => (
    <Box
      sx={{
        minWidth: 300,
        width: anchor === "top" || anchor === "bottom" ? "auto" : "auto",
      }}
      role="presentation"
    >
      <Box sx={{ width: "100%", padding: "0" }}>
        <Box
          sx={{ width: "100%", padding: "0 1rem" }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3"
        >
          <Box>
            <Typography
              style={{
                width: "100%",
                fontSize: "0.75rem",
              }}
            >
              Project Name
            </Typography>
            {projectList &&
              projectList?.map((item: any, index: any) => {
                return (
                  <>
                    <Box sx={{ alignItems: "center" }}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Checkbox
                          style={{ padding: "3px" }}
                          checked={
                            selectedProject?.includes(item.id) ? true : false
                          }
                          onClick={() => selectAllProject(item?.id)}
                          inputProps={{ "aria-label": "controlled" }}
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                        />
                        <Box
                          onClick={() => selectAllProject(item?.id)}
                          key={index}
                          className={styles.selectVehBtn}
                          sx={{
                            // margin: "0rem 2rem",
                            padding: "0.2rem 0.5rem",
                            backgroundColor: getselectedProjectBackgroundColor(
                              item.id
                            ),
                            color: getSelectedProjectTextColor(item.id),
                            textAlign: "left",
                            cursor: "pointer",
                            width: "100%",
                          }}
                        >
                          <Typography
                            style={{ fontSize: "0.87rem", display: "flex" }}
                          >
                            {item.name}{" "}
                            <span
                              style={{
                                marginLeft: "0.5rem",
                                display: "inline-block",
                                width: "14rem",
                              }}
                            >
                              [ {item.description} ]
                            </span>
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </>
                );
              })}
          </Box>
        </Box>
        <div
          style={{
            paddingBottom: "0.5rem",
            position: "sticky",
            bottom: "0",
            height: "4rem",
            backgroundColor: "aliceblue",
            marginTop: "2rem",
          }}
        >
          <Box sx={{ textAlign: "right", paddingRight: "1rem" }}>
            <Button
              className={styles.drawBtn}
              sx={{
                marginRight: "1rem",
                fontSize: "0.75rem",
                "&:hover": {
                  transform: "Scale(1.1)",
                  transition: "transform 0.5s ease",
                },
              }}
              variant="contained"
              onClick={toggleDrawer(anchor, false)}
              size="small"
            >
              Close
            </Button>
            <LoadingButton size="small" onClick={redirectToProject}>
              <Button
                variant="contained"
                sx={{
                  fontSize: "0.75rem",
                  "&:hover": {
                    transform: "Scale(1.1)",
                    transition: "transform 0.5s ease",
                  },
                }}
                onClick={toggleDrawer(anchor, false)}
                size="small"
              >
                Ok
              </Button>
            </LoadingButton>
          </Box>
        </div>
      </Box>
    </Box>
  );

  // helpers
  const inputRef = useRef<any>();
  // ------------------------------------------------------------------------------------
  //handlers:
  const browseFiles = (ed: any) => {
    return inputRef.current?.click?.();
  };

  const handleUploadPDF = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoader(true);
    const file: any = e.target.files;
    await milestoneFileUpload(
      file,
      projectId,
      "milestone",
      setLoader,
      setFilePathGet,
      setFilePathName
    );
    setProposalFileAvailable({ show: true });
  };

  useEffect(() => {
    API.get(`xcpep/proposal_document_view`, { id: projectId }, 0)
      .then((res: any) => {
        setDocumentData(res?.data);
      })
      .catch((err: any) => {
        console.log(err, "error");
      });
  }, [projectId, getdocumentDataAPICall]);

  const handleOpenPdfViewer = () => {
    Documentmodal.open();
  };

  const handleClose = () => {
    setOpenFilter(false);
  };

  const handleOpen = () => {
    setOpenFilter(true);
  };

  const handleChangeFilterStatus = (e: any) => {
    setSelectFilterStatus(e.target.value);
  };

  const handleChangeFilterActivityType = (e: any) => {
    setSelectFilterActivityType(e.target.value);
  };


  //  const handleChangeFilterActivity = (e: any) => {






  //     setSelectFilterActivity(e.target.value);


  // };


  const handleChangeFilterDepartment = (e: any) => {
    setSelectFilterDepartment(e.target.value);
  };

  const handleChangeFilterGroup = (e: any) => {
    setSelectFilterGroup(e.target.value);
  };

  const handlefilterDataValues = () => {
    MileStone.getMileStonePageCount(
      Number(projectId),
      selectFilterActivity,
      selectFilterStatus,
      selectFilterActivityType,
      selectFilterDepartment,
      selectFilterGroup
    ).then((res: any) => {
      const countPage: any = res.data?.page_count;
      MileStone.getMileStone(
        Number(projectId),
        1,
        selectFilterActivity,
        selectFilterStatus,
        selectFilterActivityType,
        selectFilterDepartment,
        selectFilterGroup
      )
        .then((res: any) => {
          setMileStone(res.data);
          setOpenFilter(false);
          if (countPage >= 2) {
            MileStone.getMileStone(
              Number(projectId),
              2,
              selectFilterActivity,
              selectFilterStatus,
              selectFilterActivityType,
              selectFilterDepartment,
              selectFilterGroup
            )
              .then((res: any) => {
                setMileStone((prev: any) => [...prev, ...res.data]);
                if (countPage >= 3) {
                  MileStone.getMileStone(
                    Number(projectId),
                    3,
                    selectFilterActivity,
                    selectFilterStatus,
                    selectFilterActivityType,
                    selectFilterDepartment,
                    selectFilterGroup
                  )
                    .then((res: any) => {
                      setMileStone((prev: any) => [...prev, ...res.data]);
                      if (countPage >= 4) {
                        MileStone.getMileStone(
                          Number(projectId),
                          4,
                          selectFilterActivity,
                          selectFilterStatus,
                          selectFilterActivityType,
                          selectFilterDepartment,
                          selectFilterGroup
                        )
                          .then((res: any) => {
                            setMileStone((prev: any) => [...prev, ...res.data]);
                            if (countPage >= 5) {
                              MileStone.getMileStone(
                                Number(projectId),
                                5,
                                selectFilterActivity,
                                selectFilterStatus,
                                selectFilterActivityType,
                                selectFilterDepartment,
                                selectFilterGroup
                              )
                                .then((res: any) => {
                                  setMileStone((prev: any) => [
                                    ...prev,
                                    ...res.data,
                                  ]);
                                  if (countPage >= 6) {
                                    MileStone.getMileStone(
                                      Number(projectId),
                                      6,
                                      selectFilterActivity,
                                      selectFilterStatus,
                                      selectFilterActivityType,
                                      selectFilterDepartment,
                                      selectFilterGroup
                                    )
                                      .then((res: any) => {
                                        setMileStone((prev: any) => [
                                          ...prev,
                                          ...res.data,
                                        ]);
                                        if (countPage == 7) {
                                          MileStone.getMileStone(
                                            Number(projectId),
                                            7,
                                            selectFilterActivity,
                                            selectFilterStatus,
                                            selectFilterActivityType,
                                            selectFilterDepartment,
                                            selectFilterGroup
                                          )
                                            .then((res: any) => {
                                              setMileStone((prev: any) => [
                                                ...prev,
                                                ...res.data,
                                              ]);
                                            })
                                            .catch((err) =>
                                              console.log(err, "Server Error")
                                            );
                                        }
                                      })
                                      .catch((err) =>
                                        console.log(err, "Server Error")
                                      );
                                  }
                                })
                                .catch((err) =>
                                  console.log(err, "Server Error")
                                );
                            }
                          })
                          .catch((err) => console.log(err, "Server Error"));
                      }
                    })
                    .catch((err) => console.log(err, "Server Error"));
                }
              })
              .catch((err) => console.log(err, "Server Error"));
          }
        })
        .catch((err) => console.log(err, "Server Error"));
    });
  };


  const handlefilterDataValuestoggle = (activity: any) => {
    MileStone.getMileStonePageCount(
      Number(projectId),
      activity,
      selectFilterStatus,
      selectFilterActivityType,
      selectFilterDepartment,
      selectFilterGroup
    ).then((res: any) => {
      const countPage: any = res.data?.page_count;
      MileStone.getMileStone(
        Number(projectId),
        1,
        activity,
        selectFilterStatus,
        selectFilterActivityType,
        selectFilterDepartment,
        selectFilterGroup
      )
        .then((res: any) => {
          setMileStone(res.data);
          setOpenFilter(false);
          if (countPage >= 2) {
            MileStone.getMileStone(
              Number(projectId),
              2,
              activity,
              selectFilterStatus,
              selectFilterActivityType,
              selectFilterDepartment,
              selectFilterGroup
            )
              .then((res: any) => {
                setMileStone((prev: any) => [...prev, ...res.data]);
                if (countPage >= 3) {
                  MileStone.getMileStone(
                    Number(projectId),
                    3,
                    activity,
                    selectFilterStatus,
                    selectFilterActivityType,
                    selectFilterDepartment,
                    selectFilterGroup
                  )
                    .then((res: any) => {
                      setMileStone((prev: any) => [...prev, ...res.data]);
                      if (countPage >= 4) {
                        MileStone.getMileStone(
                          Number(projectId),
                          4,
                          activity,
                          selectFilterStatus,
                          selectFilterActivityType,
                          selectFilterDepartment,
                          selectFilterGroup
                        )
                          .then((res: any) => {
                            setMileStone((prev: any) => [...prev, ...res.data]);
                            if (countPage >= 5) {
                              MileStone.getMileStone(
                                Number(projectId),
                                5,
                                activity,
                                selectFilterStatus,
                                selectFilterActivityType,
                                selectFilterDepartment,
                                selectFilterGroup
                              )
                                .then((res: any) => {
                                  setMileStone((prev: any) => [
                                    ...prev,
                                    ...res.data,
                                  ]);
                                  if (countPage >= 6) {
                                    MileStone.getMileStone(
                                      Number(projectId),
                                      6,
                                      activity,
                                      selectFilterStatus,
                                      selectFilterActivityType,
                                      selectFilterDepartment,
                                      selectFilterGroup
                                    )
                                      .then((res: any) => {
                                        setMileStone((prev: any) => [
                                          ...prev,
                                          ...res.data,
                                        ]);
                                        if (countPage == 7) {
                                          MileStone.getMileStone(
                                            Number(projectId),
                                            7,
                                            activity,
                                            selectFilterStatus,
                                            selectFilterActivityType,
                                            selectFilterDepartment,
                                            selectFilterGroup
                                          )
                                            .then((res: any) => {
                                              setMileStone((prev: any) => [
                                                ...prev,
                                                ...res.data,
                                              ]);
                                            })
                                            .catch((err) =>
                                              console.log(err, "Server Error")
                                            );
                                        }
                                      })
                                      .catch((err) =>
                                        console.log(err, "Server Error")
                                      );
                                  }
                                })
                                .catch((err) =>
                                  console.log(err, "Server Error")
                                );
                            }
                          })
                          .catch((err) => console.log(err, "Server Error"));
                      }
                    })
                    .catch((err) => console.log(err, "Server Error"));
                }
              })
              .catch((err) => console.log(err, "Server Error"));
          }
        })
        .catch((err) => console.log(err, "Server Error"));
    });
  };
  const handleChangeFilterActivity = (e: any) => {
    if (e?.target?.checked == true) {





      setSelectFilterActivity("true");
      handlefilterDataValuestoggle("true")
    } if (e?.target?.checked == false) {
      setSelectFilterActivity("All");
      handlefilterDataValuestoggle("All")
    }

  };
  const handleResetfilterDataValues = () => {
    setDataLoading(true);
    setSelectFilterActivity("All");
    setSelectFilterActivityType("All");
    setSelectFilterStatus("All");
    setSelectFilterDepartment("All");
    setSelectFilterGroup("All");
    MileStone.getMileStoneRaw(Number(projectId), 1)
      .then((res: any) => {
        setMileStone(res.data);
        setIdUpdated(undefined);
        setDataLoading(false);
        setOpenFilter(false);
        MileStone.getMileStoneRaw(Number(projectId), 2).then((res: any) => {
          setMileStone((prev: any) => [...prev, ...res.data]);
          MileStone.getMileStoneRaw(Number(projectId), 3).then((res: any) => {
            setMileStone((prev: any) => [...prev, ...res.data]);
            MileStone.getMileStoneRaw(Number(projectId), 4).then((res: any) => {
              setMileStone((prev: any) => [...prev, ...res.data]);
              MileStone.getMileStoneRaw(Number(projectId), 5).then(
                (res: any) => {
                  setMileStone((prev: any) => [...prev, ...res.data]);
                  MileStone.getMileStoneRaw(Number(projectId), 6).then(
                    (res: any) => {
                      setMileStone((prev: any) => [...prev, ...res.data]);
                      MileStone.getMileStoneRaw(Number(projectId), 7).then(
                        (res: any) => {
                          setMileStone((prev: any) => [...prev, ...res.data]);
                        }
                      );
                    }
                  );
                }
              );
            });
          });
        });
      })
      .catch((err) => console.log(err, "Server Error"));
  };

  const downloadProposalDocument: any = () => {
    API.get(`xcpep/proposal_document_view`, { id: projectId }, 0)
      .then((res: any) => {
        fetch(res?.data, {
          method: "GET",
        })
          .then((resp) => resp.blob())
          .then((blob) => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.style.display = "none";
            a.href = url;
            a.download = filePathName; // the filename you want
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          });
      })
      .catch((err: any) => {
        console.log(err, "error");
      });
  };

  return (
    <>
      {!dataLoading ? (
        <div className={styles.containerDataPage}>
          {Documentmodal.isOpen == true && (
            <ProposalDocumentModal
              isOpen={Documentmodal.isOpen}
              onCloseModal={Documentmodal.close}
              milestone={mileStone}
              documentDataGet={documentData}
              setGetdocumentDataAPICall={setGetdocumentDataAPICall}
              getdocumentDataAPICall={getdocumentDataAPICall}
            />
          )}
          <input
            style={{ display: "none" }}
            type="file"
            accept=".pdf"
            id="1"
            ref={inputRef}
            onChange={handleUploadPDF}
          />
          <Dialog
            open={openFilter}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            sx={{
              ".MuiPaper-root.MuiDialog-paper": {
                maxWidth: "60vw",
                minWidth: "25vw",
                borderRadius: "10px",
              },
            }}
          >
            <Box
              sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}
            >
              <Typography
                style={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  width: "100%",
                  textAlign: "center",
                  marginRight: "-2rem",
                }}
              >
                Filter
              </Typography>
              <CancelIcon
                titleAccess="Close"
                sx={{ cursor: "pointer", margin: "0.5rem" }}
                onClick={() => setOpenFilter(false)}
              />
            </Box>
            <Divider
              sx={{
                borderColor: "primary.light",
                margin: "0 1rem",
                marginBottom: "1rem",
              }}
            />
            <DialogContent sx={{ overflowY: "unset", paddingBottom: "0.2rem" }}>
              <FormControl
                variant="standard"
                fullWidth
                sx={{ m: 1, minWidth: 120 }}
              >
                <InputLabel
                  id="demo-simple-select-label-status"
                  style={{ color: "#007fff" }}
                >
                  Select status
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label-status"
                  id="demo-simple-select-status"
                  label="Select Status"
                  value={selectFilterStatus}
                  onChange={handleChangeFilterStatus}
                  sx={{
                    width: "95%",
                    "& .MuiSelect-select": {
                      padding: "0.5rem",
                      fontSize: "1rem",
                    },
                    "&:before": {
                      borderBottomColor: "primary.main",
                    },
                    '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
                  }}
                >
                  <MenuItem style={{ color: "green" }} value={"Completed"}>
                    COMPLETED
                  </MenuItem>
                  <MenuItem style={{ color: "red" }} value={"Not Started"}>
                    NOT STARTED
                  </MenuItem>
                  <MenuItem
                    style={{ color: "black" }}
                    value={"Work In Progress"}
                  >
                    WORK IN PROGRESS
                  </MenuItem>
                  <MenuItem style={{ color: "black" }} value={"All"}>
                    All
                  </MenuItem>
                </Select>
              </FormControl>
            </DialogContent>
            <h4 style={{ margin: "0", textAlign: "center" }}>AND</h4>
            <DialogContent
              sx={{
                overflowY: "unset",
                paddingBottom: "0.2rem",
                paddingTop: "0.2rem",
              }}
            >
              <FormControl
                variant="standard"
                fullWidth
                sx={{ m: 1, minWidth: 120 }}
              >
                <InputLabel
                  id="demo-simple-select-label-ActivityType"
                  style={{ color: "#007fff" }}
                >
                  Select activity type
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label-ActivityType"
                  id="demo-simple-select"
                  label="Select Activity Type"
                  value={selectFilterActivityType}
                  onChange={handleChangeFilterActivityType}
                  sx={{
                    width: "95%",
                    "& .MuiSelect-select": {
                      padding: "0.5rem",
                      fontSize: "1rem",
                    },
                    "&:before": {
                      borderBottomColor: "primary.main",
                    },
                    '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
                  }}
                >
                  <MenuItem value={"External"}>External</MenuItem>
                  <MenuItem value={"Internal"}>Internal</MenuItem>
                  <MenuItem value={"All"}>All</MenuItem>
                </Select>
              </FormControl>
            </DialogContent>
            <h4 style={{ margin: "0", textAlign: "center" }}>AND</h4>
            <DialogContent
              sx={{
                overflowY: "unset",
                paddingBottom: "0.2rem",
                paddingTop: "0.2rem",
              }}
            >
              <FormControl
                variant="standard"
                fullWidth
                sx={{ m: 1, minWidth: 120 }}
              >
                <InputLabel
                  id="demo-simple-select-label-Activity"
                  style={{ color: "#007fff" }}
                >
                  Select disable/enable activity
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label-Activity"
                  id="demo-simple-select"
                  label="Select Activity"
                  value={selectFilterActivity}
                  onChange={handleChangeFilterActivity}
                  sx={{
                    width: "95%",
                    "& .MuiSelect-select": {
                      padding: "0.5rem",
                      fontSize: "1rem",
                    },
                    "&:before": {
                      borderBottomColor: "primary.main",
                    },
                    '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
                  }}
                >
                  <MenuItem value={"false"}>Disabled Activity</MenuItem>
                  <MenuItem value={"true"}>Enabled Activity</MenuItem>
                  <MenuItem value={"All"}>All</MenuItem>
                </Select>
              </FormControl>
            </DialogContent>
            <h4 style={{ margin: "0", textAlign: "center" }}>AND</h4>
            <DialogContent
              sx={{
                overflowY: "unset",
                paddingBottom: "0.2rem",
                paddingTop: "0.2rem",
              }}
            >
              <FormControl
                variant="standard"
                fullWidth
                sx={{ m: 1, minWidth: 120 }}
              >
                <InputLabel
                  id="demo-simple-select-label-Activity"
                  style={{ color: "#007fff" }}
                >
                  Select department
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label-Activity"
                  id="demo-simple-select"
                  label="Select Activity"
                  value={selectFilterDepartment}
                  onChange={handleChangeFilterDepartment}
                  sx={{
                    width: "95%",
                    "& .MuiSelect-select": {
                      padding: "0.5rem",
                      fontSize: "1rem",
                    },
                    "&:before": {
                      borderBottomColor: "primary.main",
                    },
                    '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
                  }}
                >
                  {FilterItem &&
                    FilterItem?.[0]?.["dept"]?.map((item: any) => {
                      return <MenuItem value={item}>{item}</MenuItem>;
                    })}
                </Select>
              </FormControl>
            </DialogContent>
            <h4 style={{ margin: "0", textAlign: "center" }}>AND</h4>
            <DialogContent
              sx={{
                overflowY: "unset",
                paddingBottom: "0.2rem",
                paddingTop: "0.2rem",
              }}
            >
              <FormControl
                variant="standard"
                fullWidth
                sx={{ m: 1, minWidth: 120 }}
              >
                <InputLabel
                  id="demo-simple-select-label-Activity"
                  style={{ color: "#007fff" }}
                >
                  Select group
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label-Activity"
                  id="demo-simple-select"
                  label="Select Activity"
                  value={selectFilterGroup}
                  onChange={handleChangeFilterGroup}
                  sx={{
                    width: "95%",
                    "& .MuiSelect-select": {
                      padding: "0.5rem",
                      fontSize: "1rem",
                    },
                    "&:before": {
                      borderBottomColor: "primary.main",
                    },
                    '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
                  }}
                >
                  {FilterItem &&
                    FilterItem?.[0]?.["groups"]?.map((item: any) => {
                      return <MenuItem value={item}>{item}</MenuItem>;
                    })}
                </Select>
              </FormControl>
            </DialogContent>
            <DialogActions sx={{ padding: "0 2rem 1rem" }}>
              <Button
                size="small"
                variant="contained"
                sx={{
                  "&:hover": {
                    transform: "Scale(1.1)",
                    transition: "transform 0.5s ease",
                  },
                }}
                onClick={handlefilterDataValues}
              >
                apply
              </Button>
              <Button
                size="small"
                variant="contained"
                sx={{
                  "&:hover": {
                    transform: "Scale(1.1)",
                    transition: "transform 0.5s ease",
                  },
                }}
                onClick={handleResetfilterDataValues}
              >
                clear
              </Button>
            </DialogActions>
          </Dialog>
          <Box sx={{ width: '100%' }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <Box sx={{ display: 'flex', alignItems: 'center', padding: '0 0.5rem' }}>
                <IconButton
                  sx={{ cursor: "pointer" }}
                  title="Go Back"
                  onClick={() => history.push(`/internallanding`)}
                >
                  <ArrowBackIcon color="primary" titleAccess="Go Back" />
                </IconButton>
                <Typography sx={{ marginLeft: "1rem", fontSize: '1rem' }}>
                  {`${projectDetails && projectDetails?.[0]?.name} [ ${projectDetails && projectDetails?.[0]?.description} ]`}
                </Typography>
              </Box>
              <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '1rem', justifyContent: 'flex-end', paddingRight: '1rem' }}>
                <span>Enabled Activity:</span><span>{FilterItem && FilterItem?.[0]?.activity_count}</span>
                {/* <FilterAltIcon
                  style={{ cursor: "pointer" }}
                  onClick={handleOpen}
                  titleAccess='Filter'
                /> */}
                <Switch
                  checked={selectFilterActivity == "true" ? true : false}
                  onChange={handleChangeFilterActivity}
                  inputProps={{ 'aria-label': 'controlled' }}
                  title={selectFilterActivity=="true"?"Active activity":"All activity"}
                />
                {proposalFileAvailable && proposalFileAvailable?.show ? (
                  <ArticleIcon
                    style={{ cursor: "pointer" }}
                    onClick={handleOpenPdfViewer}
                    titleAccess='Proposal File'
                  />
                ) : (
                  <LoadingButton
                    loading={loader}
                    onClick={(ed: any) => browseFiles(ed)}
                    sx={{ minWidth: "8px", padding: "0" }}
                    title='Upload Proposal File'
                  >
                    <UploadFileIcon
                      style={{ cursor: "pointer", color: "#007fff" }}
                    />
                  </LoadingButton>
                )}

                <img
                  src={bvIcon}
                  style={{
                    height: "1.5rem",
                    margin: "0 0.5rem",
                    cursor: "pointer",
                  }}
                  alt="bv_pic"
                  draggable="false"
                  title="Gantt Chart"
                  onClick={() => history.push(`/GanttChart/${projectId}`)}
                />


              </Box>
            </Box>
            <Box sx={{ display: "flex" }}>
              <Box className={styles.expandIconBox}>
                <div className={styles.iconSec}>
                  {(["left"] as const).map((anchor) => (
                    <React.Fragment key={anchor}>
                      <IconButton
                        size="medium"
                        sx={{
                          backgroundColor: "primary.light",
                          color: "primary.main",
                        }}
                        onClick={toggleDrawer(anchor, true)}
                      >
                        <AddIcon sx={{ fontSize: "1.7rem" }} />
                      </IconButton>
                      <Drawer
                        anchor={anchor}
                        open={state[anchor]}
                        onClose={toggleDrawer(anchor, false)}
                        className={styles.drawerContainer}
                        PaperProps={{
                          sx: {
                            backgroundColor: "aliceblue",
                          },
                        }}
                      >
                        <Box
                          sx={{ padding: "0.5rem 0rem", paddingBottom: "0" }}
                        >
                          <Box sx={{ position: "sticky", top: "0" }}>
                            <Typography
                              style={{
                                width: "100%",
                                textAlign: "center",
                                fontSize: "1rem",
                                lineHeight: "1.2",
                              }}
                            >
                              Select to Change Project
                            </Typography>
                            <Divider sx={{ margin: "0.5rem" }} />
                          </Box>
                          {list(anchor)}
                        </Box>
                      </Drawer>
                      <Typography
                        style={{
                          transform: "rotate(270deg)",
                          whiteSpace: "nowrap",
                          marginTop: "45vh",
                          cursor: "pointer",
                          fontSize: "1rem",
                        }}
                        onClick={toggleDrawer(anchor, true)}
                      >
                        Select to Change Project
                      </Typography>
                    </React.Fragment>
                  ))}
                </div>
              </Box>
              <div className={styles.containerMilestonePage}>
                <div
                  className={styles.containerInner}
                  adl-scrollbar="true"
                  adl-scrollbar-width="0.3"
                >
                  <Table>
                    <TableHead
                      sx={{
                        backgroundColor: "primary.light",
                        position: "sticky",
                        top: 0,
                        zIndex: "1",
                      }}
                    >
                      <TableRow>
                        <TableCell
                          sx={{
                            padding: "0 0.6rem",
                            fontSize: "1rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          Seq.
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0 0.6rem",
                            fontSize: "1rem",
                            borderBottomColor: "primary.light",
                            minWidth: "10vw",
                            maxWidth: "10vw",
                          }}
                        >
                          Group
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0 0.6rem",
                            fontSize: "1rem",
                            borderBottomColor: "primary.light",
                            minWidth: "5vw",
                            maxWidth: "5vw",
                          }}
                        >
                          Department
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0 0.6rem",
                            fontSize: "1rem",
                            textAlign: 'center',
                            borderBottomColor: "primary.light",
                            minWidth: { lg: "17vw", xl: "20vw" },
                            maxWidth: { lg: "17vw", xl: "20vw" },
                          }}
                        >
                          Activity
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0 0.6rem",
                            fontSize: "1rem",
                            borderBottomColor: "primary.light",
                            textAlign: "center",
                            minWidth: { lg: "9vw", xl: "14vw" },
                            maxWidth: { lg: "9vw", xl: "14vw" },
                          }}
                        >
                          Products
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0 0.6rem",
                            fontSize: "1rem",
                            borderBottomColor: "primary.light",
                            minWidth: { lg: "9vw", xl: '8vw' },
                            maxWidth: { lg: "9vw", xl: '8vw' },
                          }}
                        >
                          Start Date
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0 0.6rem",
                            fontSize: "1rem",
                            borderBottomColor: "primary.light",
                            minWidth: "5vw",
                            maxWidth: "5vw",
                          }}
                        >
                          <Typography
                            sx={{
                              lineHeight: "1.2",
                              textAlign: "center",
                              fontSize: "1rem",
                              fontWeight: "500",
                            }}
                          >
                            Duration (Days)
                          </Typography>
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0 0.6rem",
                            fontSize: "1rem",
                            borderBottomColor: "primary.light",
                            minWidth: "5vw",
                            maxWidth: "5vw",
                          }}
                        >
                          End Date
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0 0.6rem",
                            fontSize: "1rem",
                            borderBottomColor: "primary.light",
                            minWidth: "7vw",
                            maxWidth: "7vw",
                          }}
                        >
                          Activity Type
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0 0.6rem",
                            fontSize: "1rem",
                            borderBottomColor: "primary.light",
                            minWidth: { lg: "11vw", xl: '10vw' },
                            maxWidth: { lg: "11vw", xl: '10vw' },
                          }}
                        >
                          Status
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.1rem 0.6rem",
                            fontSize: "1rem",
                            borderBottomColor: "primary.light",
                            minWidth: "3vw",
                            lineHeight: "1.2",
                            maxWidth: "3vw",
                          }}
                        >
                          ON/OFF xcPEP
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0 0.6rem",
                            fontSize: "1rem",
                            borderBottomColor: "primary.light",
                            textAlign: "center",
                            minWidth: { lg: "5vw", xl: "5vw" },
                            maxWidth: { lg: "5vw", xl: "5vw" },
                          }}
                        >
                          Actions
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {mileStone &&
                        mileStone?.map((item: any, index: number) => {
                          return (
                            <MilestoneRows
                              item={item}
                              products={products}
                              index={index}
                              getDetailsUpdate={getDetailsUpdate}
                              setIdUpdated={setIdUpdated}
                              idUpdated={idUpdated}
                            />
                          );
                        })}
                    </TableBody>
                  </Table>
                </div>
              </div>
            </Box>
          </Box>
        </div>
      ) : (
        <>
          <Skeleton sx={{ height: "5rem", width: "100%" }} />
          <Skeleton sx={{ height: "5rem", width: "100%" }} />
          <Skeleton sx={{ height: "5rem", width: "100%" }} />
          <Skeleton sx={{ height: "5rem", width: "100%" }} />
          <Skeleton sx={{ height: "5rem", width: "100%" }} />
          <Skeleton sx={{ height: "5rem", width: "100%" }} />
          <Skeleton sx={{ height: "5rem", width: "100%" }} />
          <Skeleton sx={{ height: "5rem", width: "100%" }} />
          <Skeleton sx={{ height: "5rem", width: "100%" }} />
          <Skeleton sx={{ height: "5rem", width: "100%" }} />
          <Skeleton sx={{ height: "5rem", width: "100%" }} />
          <Skeleton sx={{ height: "5rem", width: "100%" }} />
        </>
      )}
    </>
  );
};

export default MilestonePage;
