import * as React from "react";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { Box, Button, ButtonProps, Skeleton, Typography, styled } from "@mui/material";
import { DataGrid, GridCsvExportMenuItem, GridCsvExportOptions, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExportContainer } from "@mui/x-data-grid";
import InfoIcon from "@mui/icons-material/Info";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import LaunchIcon from "@mui/icons-material/Launch";
import AssistantPhotoIcon from "@mui/icons-material/AssistantPhoto";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import EditIcon from "@mui/icons-material/Edit";
import { LoadingButton } from "@mui/lab";
import UploadIcon from "@mui/icons-material/Upload";
import DownloadIcon from "@mui/icons-material/Download";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import GetAppIcon from "@mui/icons-material/GetApp";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { useBaseParamsModal } from "../../../CustomHook/useBaseParamsModal";
import { API } from "../../../api-services";
import useWindowDimensions from "../../../ui-reusable-component/useWindowDimensions";
import { useBaseModal } from "../../../ComponentUI/SearchPage/useBaseModal";
import CreateRevisionDatabaseModal from "../CreateRevisionDatabaseDataModal";
import RawMaterialDatabaseInfoModal from "../RawMaterialDatabaseInfoModal";
import FetchRawMaterialModal from "../FetchRawMaterialModal";
import { BearingsColumns, ConnectorsColumns, ConsumablesColumns, ElectronicChildColumns, FastenersCols, MEPartsColumns, OthersColumns } from "../../../utlis/BoughtOutPart.constant";
import { BulbColumns, FlasherModules, HornModules, IgnitionMOdules, RemoteKeys } from "../../../utlis/BoughtOutElectricalParts.constant";
import CreateBoughtOutDatabaseModal from "./CreateBoughtOutDatabaseModal";
import InfoBoughtoutrevisionModal from "./InfoBoughtoutrevisionModal";
import Swal from "sweetalert2";
import { useRef, useState } from "react";
import successIcon from "../../../Assets/images/success.png";
import CreateBoughtOutDatabaseModalMove from "./CreateBoughtOutDatabaseModalMove";
import SubRevisionEditModalMove from "../SubRevisionEditModalMove";
import RMBOPmoveModal from "./RMBOPmoveModal";
import move from '../../../Assets/images/right move.svg';


export interface IBoughtOutPartsTableDatabase { }

// const rows: any = [];

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion
    disableGutters
    elevation={0}
    square
    {...props}
  />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem", color: "primary.main" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));
const csvOptions: GridCsvExportOptions = {
  fileName: "Idea Approve Table User Session In CSV",
  delimiter: ",",
  utf8WithBom: true,
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer sx={{
    width: { lg: '87%', xl: '91%' },
    height: '2.3rem',
    justifyContent: 'flex-end',
    paddingRight: '1rem',
    marginTop: '-2.4rem',
  }}>
    <span
    // style={{ position: "absolute", right: "0rem" }}
    >
      <GridToolbarColumnsButton />
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);

const CustomToolbarElectrical = () => (
  <GridToolbarContainer sx={{
    width: { lg: '87%', xl: '91%' },
    height: '2.3rem',
    justifyContent: 'flex-end',
    paddingRight: '1rem',
    marginTop: '-2.4rem',
  }}>
    <span
    // style={{ position: "absolute", right: "0rem" }}
    >
      <GridToolbarColumnsButton />
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);
export function BOPDatabaseSearch(props: IBoughtOutPartsTableDatabase) {
  const { tab, year, month, revisionId, typeId, databaseName, status, search, categoryName, lockCounter } =
    useRouteParams<any>();
  const subRevisionEdit = useBaseParamsModal();
  const BOPtoRMMoveModal = useBaseParamsModal();
  const bopMoveModal = useBaseParamsModal();
  const databaseMaterialInfo = useBaseParamsModal();
  const fetchRawMaterial = useBaseModal();
  const history = useHistory();
  const { height, width } = useWindowDimensions();
  const [expanded, setExpanded] = React.useState<string | false>(categoryName && categoryName);
  const [expandedElectrical, setExpandedElectrical] = React.useState<string | false>(false);
  const [categoryList, setCategoryList] = React.useState<any>([]);
  const [electricalCategories, setElectricalCategories] = React.useState<any>([]);
  const [count, setCount] = React.useState<number>(0);
  const [tableDataLoading, setTabledataLoading] = React.useState<any>(false);
  const [tableData, setTableData] = React.useState<any>([]);
  const [electricalTableData, setElectricalTableData] = React.useState<any>([]);
  const [subElectricalLoadings, setSubElectricalLoadings] = React.useState<any>(false);
  const [resverUpload, setReverseUpload] = useState<any>(false);
  const [Loader, setLoader] = useState<any>(false);
  const [accLoader, setAccLoader] = useState<any>(false);
  const inputElRefPdf = useRef<any>();
  const [useraction, setuseraction] = useState<any>(null)
  const [selectedTab, setSelectedTab] = React.useState('');
  const [selectedModule, setSelectedModule] = React.useState('');

  const handleOpenLinkReference = (reference: string) => {
    window.open(reference);
  };
  const [categoryListMaterial, setCategoryListMaterial] = React.useState<any>([]);



  React.useEffect(() => {
    API.get(
      "/auth/calibration_user_permission/",
      {
        action: true,
        database: true,
        module: "DB-Costing"
      },
      0
    )
      .then((res: any) => {
        setuseraction(res?.data?.action)
        // sessionStorage.setItem("DBPermission", res.data.action);
      })
      .catch((err: any) => { });
  }, []);



  React.useEffect(() => {
    API.get("/api/db/cost_databases/", {
      database_type: 'material',
      search: search
    }, 0).then((res: any) => {
      setCategoryListMaterial(res.data)
    })
  }, [revisionId, search])

  const getColumnDataElectrical = (category: any) => {
    const newColumn = {
      field: 'currency_code',
      headerName: 'Currency/Unit',
      sortable: false,
      minWidth: width > 1370 ? 150 : width < 1200 ? 150 : 150,
      renderCell: (cellValues: any) => {


        return (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', columnGap: '1rem', }}>
            {`${cellValues?.row?.currency_code ? cellValues?.row?.currency_code : "--"}/${cellValues?.row?.unit ? cellValues?.row?.unit : "--"}`}
          </Box>
        );
      },
    };
    switch (category) {
      case "Bulb":
        return [
          {
            field: "actions",
            headerName: "Actions",
            width: 130,
            renderCell: (cellValues: any) => {
              return (
                <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem' }}>
                  {cellValues.row.mapped_status ? (
                    <InfoIcon titleAccess="Info"
                      sx={{ cursor: "pointer" }}
                      onClick={() => databaseMaterialInfo.open(cellValues.row.id)}
                    />
                  ) : (
                    useraction && useraction?.includes("D") && <DeleteIcon titleAccess="Delete"
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        swal({
                          title: `Delete material`,
                          text: `Are you sure you want to delete ?`,
                          icon: "error",
                          buttons: ["Cancel", "Yes, Delete"],
                        }).then((confirm) => {
                          if (confirm) {
                            API.delete(`/api/db/bought_out/${cellValues.row.id}/`, {}, 0)
                              .then((res: any) => {
                                getMaterialData(categoryName);
                              })
                              .catch((err: any) => { });
                          }
                        });
                      }}
                    />
                  )}


                  {!cellValues.row.mapped_status && useraction && useraction?.includes("U") && <img src={move} title="Move" style={{ height: '1.3rem', cursor: 'pointer' }}
                    onClick={() => bopMoveModal.open(cellValues.row)}
                  />}
                  {useraction && useraction?.includes("U") && <EditIcon titleAccess="Edit"
                    sx={{ cursor: "pointer" }}
                    onClick={() => subRevisionEdit.open(cellValues.row)}
                  />}
                </Box>
              );
            },
          },
          ...BulbColumns,
          newColumn
        ];
      case "Remote Key/Lock":
        return [
          {
            field: "actions",
            headerName: "Actions",
            width: 130,
            renderCell: (cellValues: any) => {
              return (
                <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem' }}>
                  {cellValues.row.mapped_status ? (
                    <InfoIcon titleAccess="Info"
                      sx={{ cursor: "pointer" }}
                      onClick={() => databaseMaterialInfo.open(cellValues.row.id)}
                    />
                  ) : (
                    useraction && useraction?.includes("D") && <DeleteIcon titleAccess="Delete"
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        swal({
                          title: `Delete material`,
                          text: `Are you sure you want to delete ?`,
                          icon: "error",
                          buttons: ["Cancel", "Yes, Delete"],
                        }).then((confirm) => {
                          if (confirm) {
                            API.delete(`/api/db/bought_out/${cellValues.row.id}/`, {}, 0)
                              .then((res: any) => {
                                getMaterialData(categoryName);
                              })
                              .catch((err: any) => { });
                          }
                        });
                      }}
                    />
                  )}



                  {!cellValues.row.mapped_status && useraction && useraction?.includes("U") && <img src={move} title="Move" style={{ height: '1.3rem', cursor: 'pointer' }}

                    onClick={() => bopMoveModal.open(cellValues.row)}
                  />}
                  {useraction && useraction?.includes("U") && <EditIcon titleAccess="Edit"
                    sx={{ cursor: "pointer" }}
                    onClick={() => subRevisionEdit.open(cellValues.row)}
                  //   onClick={() => editSubRevision(cellValues.row)}
                  />}
                </Box>
              );
            },
          },
          ...RemoteKeys,
          newColumn
        ];

      case "Horn":
        return [
          {
            field: "actions",
            headerName: "Actions",
            width: 130,
            renderCell: (cellValues: any) => {
              return (
                <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem' }}>
                  {cellValues.row.mapped_status ? (
                    <InfoIcon titleAccess="Info"
                      sx={{ cursor: "pointer" }}
                      onClick={() => databaseMaterialInfo.open(cellValues.row.id)}
                    />
                  ) : (
                    useraction && useraction?.includes("D") && <DeleteIcon titleAccess="Delete"
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        swal({
                          title: `Delete material`,
                          text: `Are you sure you want to delete ?`,
                          icon: "error",
                          buttons: ["Cancel", "Yes, Delete"],
                        }).then((confirm) => {
                          if (confirm) {
                            API.delete(`/api/db/bought_out/${cellValues.row.id}/`, {}, 0)
                              .then((res: any) => {
                                getMaterialData(categoryName);
                              })
                              .catch((err: any) => { });
                          }
                        });
                      }}
                    />
                  )}

                  {!cellValues.row.mapped_status && useraction && useraction?.includes("U") && <img src={move} title="Move" style={{ height: '1.3rem', cursor: 'pointer' }}

                    onClick={() => bopMoveModal.open(cellValues.row)}
                  />}
                  {useraction && useraction?.includes("U") && <EditIcon titleAccess="Edit"
                    sx={{ cursor: "pointer" }}
                    onClick={() => subRevisionEdit.open(cellValues.row)}
                  //   onClick={() => editSubRevision(cellValues.row)}
                  />}
                </Box>
              );
            },
          },
          ...HornModules,
          newColumn
        ];

      case "Ignition":
        return [
          {
            field: "actions",
            headerName: "Actions",
            width: 130,
            renderCell: (cellValues: any) => {
              return (
                <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem' }}>
                  {cellValues.row.mapped_status ? (
                    <InfoIcon titleAccess="Info"
                      sx={{ cursor: "pointer" }}
                      onClick={() => databaseMaterialInfo.open(cellValues.row.id)}
                    />
                  ) : (
                    useraction && useraction?.includes("D") && <DeleteIcon titleAccess="Delete"
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        swal({
                          title: `Delete material`,
                          text: `Are you sure you want to delete ?`,
                          icon: "error",
                          buttons: ["Cancel", "Yes, Delete"],
                        }).then((confirm) => {
                          if (confirm) {
                            API.delete(`/api/db/bought_out/${cellValues.row.id}/`, {}, 0)
                              .then((res: any) => {
                                getMaterialData(categoryName);
                              })
                              .catch((err: any) => { });
                          }
                        });
                      }}
                    />
                  )}



                  {!cellValues.row.mapped_status && useraction && useraction?.includes("U") && <img src={move} title="Move" style={{ height: '1.3rem', cursor: 'pointer' }}

                    onClick={() => bopMoveModal.open(cellValues.row)}
                  />}
                  {useraction && useraction?.includes("U") && <EditIcon titleAccess="Edit"
                    sx={{ cursor: "pointer" }}
                    onClick={() => subRevisionEdit.open(cellValues.row)}
                  //   onClick={() => editSubRevision(cellValues.row)}
                  />}
                </Box>
              );
            },
          },
          ...IgnitionMOdules,
          newColumn
        ];

      case "Flasher":
        return [
          {
            field: "actions",
            headerName: "Actions",
            width: 130,
            renderCell: (cellValues: any) => {
              return (
                <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem' }}>
                  {cellValues.row.mapped_status ? (
                    <InfoIcon titleAccess="Info"
                      sx={{ cursor: "pointer" }}
                      onClick={() => databaseMaterialInfo.open(cellValues.row.id)}
                    />
                  ) : (
                    useraction && useraction?.includes("D") && <DeleteIcon titleAccess="Delete"
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        swal({
                          title: `Delete material`,
                          text: `Are you sure you want to delete ?`,
                          icon: "error",
                          buttons: ["Cancel", "Yes, Delete"],
                        }).then((confirm) => {
                          if (confirm) {
                            API.delete(`/api/db/bought_out/${cellValues.row.id}/`, {}, 0)
                              .then((res: any) => {
                                getMaterialData(categoryName);
                              })
                              .catch((err: any) => { });
                          }
                        });
                      }}
                    />
                  )}



                  {!cellValues.row.mapped_status && useraction && useraction?.includes("U") && <img src={move} title="Move" style={{ height: '1.3rem', cursor: 'pointer' }}

                    onClick={() => bopMoveModal.open(cellValues.row)}
                  />}
                  {useraction && useraction?.includes("U") && <EditIcon titleAccess="Edit"
                    sx={{ cursor: "pointer" }}
                    onClick={() => subRevisionEdit.open(cellValues.row)}
                  //   onClick={() => editSubRevision(cellValues.row)}
                  />}
                </Box>
              );
            },
          },
          ...FlasherModules,
          newColumn
        ];
      default:
        return [];
    }
  };

  const getColumnData = (category: any) => {
    const newColumn = {
      field: 'currency_code',
      headerName: 'Currency/Unit',
      sortable: false,
      minWidth: width > 1370 ? 150 : width < 1200 ? 150 : 150,
      renderCell: (cellValues: any) => {


        return (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', columnGap: '1rem', }}>
            {`${cellValues?.row?.currency_code ? cellValues?.row?.currency_code : "--"}/${cellValues?.row?.unit ? cellValues?.row?.unit : "--"}`}
          </Box>
        );
      },
    };
    switch (category) {
      case "Connectors":
        return [
          {
            field: "actions",
            headerName: "Actions",
            width: 130,
            renderCell: (cellValues: any) => {
              return (
                <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem' }}>
                  {cellValues.row.mapped_status ? (
                    <InfoIcon titleAccess="Info"
                      sx={{ cursor: "pointer" }}
                      onClick={() => databaseMaterialInfo.open(cellValues.row.id)}
                    />
                  ) : (
                    useraction && useraction?.includes("D") && <DeleteIcon titleAccess="Delete"
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        swal({
                          title: `Delete material`,
                          text: `Are you sure you want to delete ?`,
                          icon: "error",
                          buttons: ["Cancel", "Yes, Delete"],
                        }).then((confirm) => {
                          if (confirm) {
                            API.delete(`/api/db/bought_out/${cellValues.row.id}/`, {}, 0)
                              .then((res: any) => {
                                getMaterialData(categoryName);
                              })
                              .catch((err: any) => { });
                          }
                        });
                      }}
                    />
                  )}



                  {!cellValues.row.mapped_status && useraction && useraction?.includes("U") && <img src={move} title="Move" style={{ height: '1.3rem', cursor: 'pointer' }}

                    onClick={() => bopMoveModal.open(cellValues.row)}
                  />}
                  {useraction && useraction?.includes("U") && <EditIcon titleAccess="Edit"
                    sx={{ cursor: "pointer" }}
                    onClick={() => subRevisionEdit.open(cellValues.row)}
                  //   onClick={() => editSubRevision(cellValues.row)}
                  />}
                </Box>
              );
            },
          },
          ...ConnectorsColumns,
          newColumn
        ];
      case "Bearings":
        return [
          {
            field: "actions",
            headerName: "Actions",
            width: 130,
            renderCell: (cellValues: any) => {
              return (
                <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem' }}>
                  {cellValues.row.mapped_status ? (
                    <InfoIcon titleAccess="Info"
                      sx={{ cursor: "pointer" }}
                      onClick={() => databaseMaterialInfo.open(cellValues.row.id)}
                    />
                  ) : (
                    useraction && useraction?.includes("D") && <DeleteIcon titleAccess="Delete"
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        swal({
                          title: `Delete material`,
                          text: `Are you sure you want to delete ?`,
                          icon: "error",
                          buttons: ["Cancel", "Yes, Delete"],
                        }).then((confirm) => {
                          if (confirm) {
                            API.delete(`/api/db/bought_out/${cellValues.row.id}/`, {}, 0)
                              .then((res: any) => {
                                getMaterialData(categoryName);
                              })
                              .catch((err: any) => { });
                          }
                        });
                      }}
                    />
                  )}




                  {!cellValues.row.mapped_status && useraction && useraction?.includes("U") && <img src={move} title="Move" style={{ height: '1.3rem', cursor: 'pointer' }}

                    onClick={() => bopMoveModal.open(cellValues.row)}
                  />}
                  {useraction && useraction?.includes("U") && <EditIcon titleAccess="Edit"
                    sx={{ cursor: "pointer" }}
                    onClick={() => subRevisionEdit.open(cellValues.row)}
                  //   onClick={() => editSubRevision(cellValues.row)}
                  />}
                </Box>
              );
            },
          },
          ...BearingsColumns,
          newColumn
        ];
      case "Consumables":
        return [
          {
            field: "actions",
            headerName: "Actions",
            width: 130,
            renderCell: (cellValues: any) => {
              return (
                <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem' }}>
                  {cellValues.row.mapped_status ? (
                    <InfoIcon titleAccess="Info"
                      sx={{ cursor: "pointer" }}
                      onClick={() => databaseMaterialInfo.open(cellValues.row.id)}
                    />
                  ) : (
                    useraction && useraction?.includes("D") && <DeleteIcon titleAccess="Delete"
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        swal({
                          title: `Delete material`,
                          text: `Are you sure you want to delete ?`,
                          icon: "error",
                          buttons: ["Cancel", "Yes, Delete"],
                        }).then((confirm) => {
                          if (confirm) {
                            API.delete(`/api/db/bought_out/${cellValues.row.id}/`, {}, 0)
                              .then((res: any) => {
                                getMaterialData(categoryName);
                              })
                              .catch((err: any) => { });
                          }
                        });
                      }}
                    />
                  )}



                  {!cellValues.row.mapped_status && useraction && useraction?.includes("U") && <img src={move} title="Move" style={{ height: '1.3rem', cursor: 'pointer' }}

                    onClick={() => bopMoveModal.open(cellValues.row)}
                  />}
                  {useraction && useraction?.includes("U") && <EditIcon titleAccess="Edit"
                    sx={{ cursor: "pointer" }}
                    onClick={() => subRevisionEdit.open(cellValues.row)}
                  //   onClick={() => editSubRevision(cellValues.row)}
                  />}
                </Box>
              );
            },
          },
          ...ConsumablesColumns,
          newColumn
        ];
      case "Electronic Child Components":
        return [
          {
            field: "actions",
            headerName: "Actions",
            width: 130,
            renderCell: (cellValues: any) => {
              return (
                <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem' }}>
                  {cellValues.row.mapped_status ? (
                    <InfoIcon titleAccess="Info"
                      sx={{ cursor: "pointer" }}
                      onClick={() => databaseMaterialInfo.open(cellValues.row.id)}
                    />
                  ) : (
                    useraction && useraction?.includes("D") && <DeleteIcon titleAccess="Delete"
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        swal({
                          title: `Delete material`,
                          text: `Are you sure you want to delete ?`,
                          icon: "error",
                          buttons: ["Cancel", "Yes, Delete"],
                        }).then((confirm) => {
                          if (confirm) {
                            API.delete(`/api/db/bought_out/${cellValues.row.id}/`, {}, 0)
                              .then((res: any) => {
                                getMaterialData(categoryName);
                              })
                              .catch((err: any) => { });
                          }
                        });
                      }}
                    />
                  )}




                  {!cellValues.row.mapped_status && useraction && useraction?.includes("U") && <img src={move} title="Move" style={{ height: '1.3rem', cursor: 'pointer' }}

                    onClick={() => bopMoveModal.open(cellValues.row)}
                  />}
                  {useraction && useraction?.includes("U") && <EditIcon titleAccess="Edit"
                    sx={{ cursor: "pointer" }}
                    onClick={() => subRevisionEdit.open(cellValues.row)}
                  //   onClick={() => editSubRevision(cellValues.row)}
                  />}
                </Box>
              );
            },
          },
          ...ElectronicChildColumns,
          newColumn
        ];
      case "Mechanical Proprietory Parts":
        return [
          {
            field: "actions",
            headerName: "Actions",
            width: 130,
            renderCell: (cellValues: any) => {
              return (
                <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem' }}>
                  {cellValues.row.mapped_status ? (
                    <InfoIcon titleAccess="Info"
                      sx={{ cursor: "pointer" }}
                      onClick={() => databaseMaterialInfo.open(cellValues.row.id)}
                    />
                  ) : (
                    useraction && useraction?.includes("D") && <DeleteIcon titleAccess="Delete"
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        swal({
                          title: `Delete material`,
                          text: `Are you sure you want to delete ?`,
                          icon: "error",
                          buttons: ["Cancel", "Yes, Delete"],
                        }).then((confirm) => {
                          if (confirm) {
                            API.delete(`/api/db/bought_out/${cellValues.row.id}/`, {}, 0)
                              .then((res: any) => {
                                getMaterialData(categoryName);
                              })
                              .catch((err: any) => { });
                          }
                        });
                      }}
                    />
                  )}



                  {!cellValues.row.mapped_status && useraction && useraction?.includes("U") && <img src={move} title="Move" style={{ height: '1.3rem', cursor: 'pointer' }}

                    onClick={() => bopMoveModal.open(cellValues.row)}
                  />}
                  {useraction && useraction?.includes("U") && <EditIcon titleAccess="Edit"
                    sx={{ cursor: "pointer" }}
                    onClick={() => subRevisionEdit.open(cellValues.row)}
                  //   onClick={() => editSubRevision(cellValues.row)}
                  />}
                </Box>
              );
            },
          },
          ...MEPartsColumns,
          newColumn
        ];

      case "Fasteners":
        return [
          {
            field: "actions",
            headerName: "Actions",
            width: 130,
            renderCell: (cellValues: any) => {
              return (
                <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem' }}>
                  {cellValues.row.mapped_status ? (
                    <InfoIcon titleAccess="Info"
                      sx={{ cursor: "pointer" }}
                      onClick={() => databaseMaterialInfo.open(cellValues.row.id)}
                    />
                  ) : (
                    useraction && useraction?.includes("D") && <DeleteIcon titleAccess="Delete"
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        swal({
                          title: `Delete material`,
                          text: `Are you sure you want to delete ?`,
                          icon: "error",
                          buttons: ["Cancel", "Yes, Delete"],
                        }).then((confirm) => {
                          if (confirm) {
                            API.delete(`/api/db/bought_out/${cellValues.row.id}/`, {}, 0)
                              .then((res: any) => {
                                getMaterialData(categoryName);
                              })
                              .catch((err: any) => { });
                          }
                        });
                      }}
                    />
                  )}




                  {!cellValues.row.mapped_status && useraction && useraction?.includes("U") && <img src={move} title="Move" style={{ height: '1.3rem', cursor: 'pointer' }}

                    onClick={() => bopMoveModal.open(cellValues.row)}
                  />}
                  {useraction && useraction?.includes("U") && <EditIcon titleAccess="Edit"
                    sx={{ cursor: "pointer" }}
                    onClick={() => subRevisionEdit.open(cellValues.row)}
                  //   onClick={() => editSubRevision(cellValues.row)}
                  />}
                </Box>
              );
            },
          },
          ...FastenersCols,
          newColumn
        ];
      default:
        return [
          {
            field: "actions",
            headerName: "Actions",
            width: 130,
            renderCell: (cellValues: any) => {
              return (
                <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem' }}>
                  {cellValues.row.mapped_status ? (
                    <InfoIcon titleAccess="Info"
                      sx={{ cursor: "pointer" }}
                      onClick={() => databaseMaterialInfo.open(cellValues.row.id)}
                    />
                  ) : (
                    useraction && useraction?.includes("D") && <DeleteIcon titleAccess="Delete"
                      sx={{ cursor: "pointer" }}
                      onClick={() => {
                        swal({
                          title: `Delete material`,
                          text: `Are you sure you want to delete ?`,
                          icon: "error",
                          buttons: ["Cancel", "Yes, Delete"],
                        }).then((confirm) => {
                          if (confirm) {
                            API.delete(`/api/db/bought_out/${cellValues.row.id}/`, {}, 0)
                              .then((res: any) => {
                                getMaterialData(categoryName);
                              })
                              .catch((err: any) => { });
                          }
                        });
                      }}
                    />
                  )}



                  {!cellValues.row.mapped_status && useraction && useraction?.includes("U") && <img src={move} title="Move" style={{ height: '1.3rem', cursor: 'pointer' }}

                    onClick={() => bopMoveModal.open(cellValues.row)}
                  />}
                  {useraction && useraction?.includes("U") && <EditIcon titleAccess="Edit"
                    sx={{ cursor: "pointer" }}
                    onClick={() => subRevisionEdit.open(cellValues.row)}
                  //   onClick={() => editSubRevision(cellValues.row)}
                  />}
                </Box>
              );
            },
          },
          ...OthersColumns,

        ];
    }
  };
  const getCategoryList = () => {
    setAccLoader(true);
    API.get(
      "/api/db/cost_databases/",
      {
        database_type: "purchaseitem",
        search: search
        // revision_id: revisionId,
      },
      0
    )
      .then((res: any) => {
        setCategoryList(res.data);
        setAccLoader(false);
      })
      .catch((err: any) => {
        setAccLoader(false);
      });
  };

  React.useEffect(() => {
    getCategoryList();
  }, []);
  const editSubRevision = (item: any) => {
    subRevisionEdit.open(item);
  };
  const getMaterialData = (category: any) => {
    setTabledataLoading(true);
    setTableData([]);
    API.get(
      "/api/db/bought_out/",
      {
        // revision: revisionId,
        // category: category,
        commodity: category,
        search: search
      },
      0
    )
      .then((res: any) => {
        setTableData(res.data);
        setTabledataLoading(false);
      })
      .catch((err: any) => {
        setTabledataLoading(false);
      });
  };
  const getRowDataElectrical = (category: any) => {
    switch (category) {
      case "Bulb":
        let rowsBulb: any = [];
        let connectors: any =
          electricalTableData &&
          electricalTableData?.map((item: any, index: any) => {
            let objShared: any = {
              id: item?.id,
              sno: index + 1,
              name: item?.name,
              type: item?.type,
              rated_voltage: item?.rated_voltage,
              base_code: item?.base_code,
              no_of_filament: item?.no_of_filament,
              watttage: item?.watttage,
              reference: item?.reference,
              unit_cost: ` ${item?.unit_cost}`,
              commodity_name: item?.commodity_name,
              module_type: item?.module_type,
              mapped_status: item?.mapped_status,
              currency_id: item?.currency_id,
              currency_code: item?.currency_code,
              unit: item?.unit,
            };
            rowsBulb.push(objShared);
          });

        let dataStructureDataGridBulb: any = {
          columns: getColumnDataElectrical(selectedModule) && getColumnDataElectrical(selectedModule),
          rows: rowsBulb && rowsBulb,
        };
        return dataStructureDataGridBulb && dataStructureDataGridBulb;

      case "Remote Key/Lock":
        let rowsRemoteKey: any = [];
        let remoteKey: any =
          electricalTableData &&
          electricalTableData?.map((item: any, index: any) => {
            let objShared: any = {
              id: item?.id,
              sno: index + 1,
              name: item?.name,
              type: item?.type,
              sensing_technology: item?.sensing_technology,
              type_of_key_fob: item?.type_of_key_fob,
              no_of_function: item?.no_of_function,
              function: item?.function,
              range: item?.range,
              reference: item?.reference,
              unit_cost: ` ${item?.unit_cost}`,
              commodity_name: item?.commodity_name,
              module_type: item?.module_type,
              mapped_status: item?.mapped_status,
              currency_id: item?.currency_id,
              currency_code: item?.currency_code,
              unit: item?.unit,
            };
            rowsRemoteKey.push(objShared);
          });

        let dataStructureDataGridRemoteKey: any = {
          columns: getColumnDataElectrical(selectedModule) && getColumnDataElectrical(selectedModule),
          rows: rowsRemoteKey && rowsRemoteKey,
        };
        return dataStructureDataGridRemoteKey && dataStructureDataGridRemoteKey;

      case "Horn":
        let rowsHorn: any = [];
        let horn: any =
          electricalTableData &&
          electricalTableData?.map((item: any, index: any) => {
            let objShared: any = {
              id: item?.id,
              sno: index + 1,
              name: item?.name,
              type: item?.type,
              rated_voltage: item?.rated_voltage,
              current_rating: item?.current_rating,
              power_rating: item?.power_rating,
              frequency: item?.frequency,
              sound_level: item?.sound_level,
              reference: item?.reference,
              unit_cost: ` ${item?.unit_cost}`,
              commodity_name: item?.commodity_name,
              module_type: item?.module_type,
              mapped_status: item?.mapped_status,
              currency_id: item?.currency_id,
              currency_code: item?.currency_code,
              unit: item?.unit,
            };
            rowsHorn.push(objShared);
          });

        let dataStructureDataGridHorn: any = {
          columns: getColumnDataElectrical(selectedModule) && getColumnDataElectrical(selectedModule),
          rows: rowsHorn && rowsHorn,
        };
        return dataStructureDataGridHorn && dataStructureDataGridHorn;

      case "Ignition":
        let rowsIgnition: any = [];
        let Ignition: any =
          electricalTableData &&
          electricalTableData?.map((item: any, index: any) => {
            let objShared: any = {
              id: item?.id,
              sno: index + 1,
              name: item?.name,
              type: item?.type,
              rated_voltage: item?.rated_voltage,
              no_of_position: item?.no_of_position,
              no_of_terminal: item?.no_of_terminal,
              with_handle_lock: item?.with_handle_lock,
              reference: item?.reference,
              unit_cost: ` ${item?.unit_cost}`,
              commodity_name: item?.commodity_name,
              module_type: item?.module_type,
              mapped_status: item?.mapped_status,
              currency_id: item?.currency_id,
              currency_code: item?.currency_code,
              unit: item?.unit,
            };
            rowsIgnition.push(objShared);
          });

        let dataStructureDataGridIgnition: any = {
          columns: getColumnDataElectrical(selectedModule) && getColumnDataElectrical(selectedModule),
          rows: rowsIgnition && rowsIgnition,
        };
        return dataStructureDataGridIgnition && dataStructureDataGridIgnition;

      case "Flasher":
        let rowsFlasher: any = [];
        let Flasher: any =
          electricalTableData &&
          electricalTableData?.map((item: any, index: any) => {
            let objShared: any = {
              id: item?.id,
              sno: index + 1,
              name: item?.name,
              type: item?.type,
              rated_voltage: item?.rated_voltage,
              frequency: item?.frequency,
              no_of_terminal: item?.no_of_terminal,
              watttage: item?.watttage,
              adjustable: item?.adjustable,
              reference: item?.reference,
              unit_cost: ` ${item?.unit_cost}`,
              commodity_name: item?.commodity_name,
              module_type: item?.module_type,
              mapped_status: item?.mapped_status,
              currency_id: item?.currency_id,
              currency_code: item?.currency_code,
              unit: item?.unit,
            };
            rowsFlasher.push(objShared);
          });

        let dataStructureDataGridFlasher: any = {
          columns: getColumnDataElectrical(selectedModule) && getColumnDataElectrical(selectedModule),
          rows: rowsFlasher && rowsFlasher,
        };
        return dataStructureDataGridFlasher && dataStructureDataGridFlasher;
    }
  };

  const getRowData = (category: any) => {
    switch (category) {
      case "Connectors":
        let rowsConnectors: any = [];
        let connectors: any =
          tableData &&
          tableData?.map((item: any, index: any) => {
            let objShared: any = {
              id: item?.id,
              sno: index + 1,
              name: item?.name,
              no_of_pins: item?.no_of_pins,
              postion_config: item?.postion_config,
              fit_type: item?.fit_type,
              pin_pitch: item?.pin_pitch,
              shape: item?.shape,
              connection_type: item?.connection_type,
              gender: item?.gender,
              material: item?.material,
              reference: item?.reference,
              unit_cost: ` ${item?.unit_cost}`,
              commodity_name: item?.commodity_name,
              mapped_status: item?.mapped_status,
              currency_id: item?.currency_id,
              currency_code: item?.currency_code,
              unit: item?.unit,
            };
            rowsConnectors.push(objShared);
          });

        let dataStructureDataGridConnectors: any = {
          columns: getColumnData(selectedTab) && getColumnData(selectedTab),
          rows: rowsConnectors && rowsConnectors,
        };
        return dataStructureDataGridConnectors && dataStructureDataGridConnectors;

      case "Bearings":
        let rowsBearings: any = [];
        let dataGridDataGeneratorBearings: any =
          tableData &&
          tableData?.map((item: any, index: any) => {
            let objShared: any = {
              id: item?.id,
              sno: index + 1,
              name: item?.name,
              code: item?.code,
              height: item?.height,
              weight: item?.weight,
              item_id: item?.item_id,
              item_od: item?.item_od,
              bearing_code: item?.bearing_code,
              reference: item?.reference,
              unit_cost: ` ${item?.unit_cost}`,
              commodity_name: item?.commodity_name,
              mapped_status: item?.mapped_status,
              currency_id: item?.currency_id,
              currency_code: item?.currency_code,
              unit: item?.unit,
            };
            rowsBearings.push(objShared);
          });

        let dataStructureDataGridBearings: any = {
          columns: getColumnData(selectedTab) && getColumnData(selectedTab),
          rows: rowsBearings && rowsBearings,
        };
        return dataStructureDataGridBearings && dataStructureDataGridBearings;

      case "Consumables":
        let rowsConsumables: any = [];
        let dataGridDataGeneratorConsumables: any =
          tableData &&
          tableData?.map((item: any, index: any) => {
            let objShared: any = {
              id: item?.id,
              sno: index + 1,
              name: item?.name,
              code: item?.code,
              description: item?.description,
              unit: item?.unit,
              reference: item?.reference,
              unit_cost: ` ${item?.unit_cost}`,
              commodity_name: item?.commodity_name,
              mapped_status: item?.mapped_status,
              currency_id: item?.currency_id,
              currency_code: item?.currency_code,

            };
            rowsConsumables.push(objShared);
          });

        let dataStructureDataGridConsumables: any = {
          columns: getColumnData(selectedTab) && getColumnData(selectedTab),
          rows: rowsConsumables && rowsConsumables,
        };
        return dataStructureDataGridConsumables && dataStructureDataGridConsumables;

      case "Electronic Child Components":
        let rowsElectronicsChild: any = [];
        let dataGridDataGeneratorElectricChild: any =
          tableData &&
          tableData?.map((item: any, index: any) => {
            let objShared: any = {
              id: item?.id,
              sno: index + 1,
              name: item?.name,
              length: item?.length,
              description: item?.description,
              width: item?.width,
              unit: item?.unit,
              component_type: item?.component_type,
              mapped_status: item?.mapped_status,
              reference: item?.reference,
              unit_cost: ` ${item?.unit_cost}`,
              commodity_name: item?.commodity_name,
              currency_id: item?.currency_id,
              currency_code: item?.currency_code,

            };
            rowsElectronicsChild.push(objShared);
          });

        let dataStructureDataGridElectricalChild: any = {
          columns: getColumnData(selectedTab) && getColumnData(selectedTab),
          rows: rowsElectronicsChild && rowsElectronicsChild,
        };
        return dataStructureDataGridElectricalChild && dataStructureDataGridElectricalChild;

      case "Mechanical Proprietory Parts":
        let rowsMEParts: any = [];
        let dataGridDataGeneratorMEParts: any =
          tableData &&
          tableData?.map((item: any, index: any) => {
            let objShared: any = {
              id: item?.id,
              sno: index + 1,
              name: item?.name,
              code: item?.code,
              length: item?.length,
              description: item?.description,
              width: item?.width,
              height: item?.height,
              weight: item?.weight,
              brand_name: item?.brand_name,
              mapped_status: item?.mapped_status,
              reference: item?.reference,
              unit_cost: ` ${item?.unit_cost}`,
              commodity_name: item?.commodity_name,
              currency_id: item?.currency_id,
              currency_code: item?.currency_code,
              unit: item?.unit,
            };
            rowsMEParts.push(objShared);
          });

        let dataStructureDataGridMEParts: any = {
          columns: getColumnData(selectedTab) && getColumnData(selectedTab),
          rows: rowsMEParts && rowsMEParts,
        };
        return dataStructureDataGridMEParts && dataStructureDataGridMEParts;
      case "Uncategorized":
        let rowsOthers: any = [];
        let dataGridDataGeneratorOthers: any =
          tableData &&
          tableData?.map((item: any, index: any) => {
            let objShared: any = {
              id: item?.id,
              sno: index + 1,
              name: item?.name,
              code: item?.code,
              length: item?.length,
              item_detail: item?.item_detail,
              width: item?.width,
              height: item?.height,
              weight: item?.weight,
              commodity_name: item?.commodity_name,
              mapped_status: item?.mapped_status,
              reference: item?.reference,
              unit_cost: ` ${item?.unit_cost}`,
              place: item.place,
              currency_id: item?.currency_id,
              currency_code: item?.currency_code,
              unit: item?.unit,
            };
            rowsOthers.push(objShared);
          });

        let dataStructureDataGridOthers: any = {
          columns: getColumnData(selectedTab) && getColumnData(selectedTab),
          rows: rowsOthers && rowsOthers,
        };
        return dataStructureDataGridOthers && dataStructureDataGridOthers;

      case "Fasteners":
        let rowFastners: any = [];
        let dataGridDataGeneratorFastner: any =
          tableData &&
          tableData?.map((item: any, index: any) => {
            let objShared: any = {
              id: item?.id,
              sno: index + 1,
              name: item?.name,
              code: item?.code,
              type: item?.type,
              grade: item?.grade,
              material: item?.material,
              m_size: item?.m_size,
              length: item?.length,
              thread_length: item?.thread_length,
              weight: item?.weight,
              commodity_name: item?.commodity_name,
              mapped_status: item?.mapped_status,
              reference: item?.reference,
              unit_cost: ` ${item?.unit_cost}`,
              place: item.place,
              currency_id: item?.currency_id,
              currency_code: item?.currency_code,
              unit: item?.unit,
            };
            rowFastners.push(objShared);
          });

        let dataStructureDataGridFastners: any = {
          columns: getColumnData(selectedTab) && getColumnData(selectedTab),
          rows: rowFastners && rowFastners,
        };
        return dataStructureDataGridFastners && dataStructureDataGridFastners;

      default:
        return [];
    }
  };

  // const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
  //   setExpanded(newExpanded ? panel : false);
  //   if (newExpanded) {
  //     if (expanded !== panel && panel !== "Electrical & Electronics Proprietary Parts") {
  //       getMaterialData(panel);
  //     }
  //     // /api/db/bought_out/?commodity=Electrical %26 Electronics Proprietary Parts&module_count=true
  //     // if(panel === "Electrical & Electronics Proprietary Parts"){
  //     //   API.get("/api/db/bought_out/",{
  //     //     commodity:panel,
  //     //     module_count:true
  //     //   },0).then((res:any)=>{
  //     //     setElectricalCategories(res.data);
  //     //   }).catch((err:any)=>{

  //     //   })
  //     // }
  //     // typeId, databaseName,categoryName
  //     history.push(`/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/${databaseName}/${panel}/${status}/${search}`);
  //   }
  // };

  const handleChange = (categoryName: any) => {
    setSelectedTab(categoryName);
    getMaterialData(categoryName);
    history.push(`/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/${databaseName}/${categoryName}/${status}/${search}/0`);
  };

  const getElectricalCategories = () => {
    API.get(
      "/api/db/bought_out/",
      {
        commodity: selectedTab,
        module_count: true,
        search: search
      },
      0
    )
      .then((res: any) => {
        setElectricalCategories(res.data);
      })
      .catch((err: any) => { });
  }


  React.useEffect(() => {
    if (selectedTab === "Electrical & Electronics Proprietary Parts") {
      getElectricalCategories();
    }
  }, [selectedTab, search]);
  const getCategoryData = () => {
    setSubElectricalLoadings(true);
    API.get(
      "/api/db/bought_out/",
      {
        commodity: selectedTab,
        module_type: selectedModule,
        search: search
      },
      0
    )
      .then((res: any) => {
        setElectricalTableData(res.data);
        setSubElectricalLoadings(false);
      })
      .catch((err: any) => {
        setSubElectricalLoadings(false);
      });
  };

  // const handleChangeElectrical = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
  //   event.stopPropagation();
  //   event.preventDefault();
  //   setExpandedElectrical(newExpanded ? panel : false);
  //   if (newExpanded !== expanded) {
  //     setElectricalTableData([]);
  //     setSubElectricalLoadings(true);

  //     API.get(
  //       "/api/db/bought_out/",
  //       {
  //         commodity: expanded,
  //         module_type: panel,
  //         search:search
  //       },
  //       0
  //     )
  //       .then((res: any) => {
  //         setElectricalTableData(res.data);
  //         setSubElectricalLoadings(false);
  //       })
  //       .catch((err: any) => {
  //         setSubElectricalLoadings(false);
  //       });
  //   }
  //   // if (newExpanded) {
  //   //   if (expanded !== panel) {
  //   //     getMaterialData(panel);
  //   //   }
  //   //   // /api/db/bought_out/?commodity=Electrical %26 Electronics Proprietary Parts&module_count=true
  //   //   if(panel === "Electrical & Electronics Proprietary Parts"){
  //   //     API.get("/api/db/bought_out/",{
  //   //       commodity:panel,
  //   //       module_count:true
  //   //     },0).then((res:any)=>{
  //   //       setElectricalCategories(res.data);
  //   //     }).catch((err:any)=>{

  //   //     })
  //   //   }
  //   //   // typeId, databaseName,categoryName
  //   //   history.push(`/data-base/revision-database/${revisionId}/${typeId}/${databaseName}/${panel}`);
  //   // }
  // };

  const handleChangeElectrical = (selectedModule: any) => {
    // event.stopPropagation();
    // event.preventDefault();
    setSelectedModule(selectedModule);
    if (selectedModule) {
      setElectricalTableData([]);
      setSubElectricalLoadings(true);

      API.get(
        "/api/db/bought_out/",
        {
          commodity: selectedTab,
          module_type: selectedModule,
          search: search
        },
        0
      )
        .then((res: any) => {
          setElectricalTableData(res.data);
          setSubElectricalLoadings(false);
        })
        .catch((err: any) => {
          setSubElectricalLoadings(false);
        });
    }
    // if (newExpanded) {
    //   if (expanded !== panel) {
    //     getMaterialData(panel);
    //   }
    //   // /api/db/bought_out/?commodity=Electrical %26 Electronics Proprietary Parts&module_count=true
    //   if(panel === "Electrical & Electronics Proprietary Parts"){
    //     API.get("/api/db/bought_out/",{
    //       commodity:panel,
    //       module_count:true
    //     },0).then((res:any)=>{
    //       setElectricalCategories(res.data);
    //     }).catch((err:any)=>{

    //     })
    //   }
    //   // typeId, databaseName,categoryName
    //   history.push(`/data-base/revision-database/${revisionId}/${typeId}/${databaseName}/${panel}`);
    // }
  };
  React.useEffect(() => {
    if (categoryName) {
      getMaterialData(categoryName);
    }
  }, [categoryName, search]);

  const handleInputClick = (e: React.MouseEvent) => {
    // clear the prev value on click so that same file can be uploaded again
    (e.target as HTMLInputElement).value = "";
  };

  const UploadFile = (e: any) => {
    const formData = new FormData();

    formData.append("file", e?.target?.files[0]);
    setReverseUpload(true);
    API.post("/api/db/import_boughtout/", formData, {}, 0)
      .then((res: any) => {
        // setParentsCounter((prev: any) => prev + 1);
        getCategoryList();
        Swal.fire({
          html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
        <br />
        <p style="color:#007fff;">"${res?.data}"</p>   
         </div>`,
        });
        setReverseUpload(false);
      })
      .catch((err: any) => {
        const { data } = err?.response;
        Swal.fire({
          icon: "error",
          html: `<div>
        <br />
        <p style="color:red;">${data[0]}</p>   
         </div>`,
        });
        setReverseUpload(false);
      });
  };
  const ReverseUpload = () => {
    return inputElRefPdf.current?.click?.();
  };

  const DownLoadFile = (e: any) => {
    e?.stopPropagation();
    setLoader(true);
    API.get("/api/db/database/download/boughtout/")
      .then((res: any) => {
        window.open(res?.data, "_self", " noopener");
        setLoader(false);
      })
      .catch((err: any) => {
        setLoader(false);
        console.log("Sever Error");
      });
  };

  return (
    <div
      style={{ width: "100%",}}>
      <input
        id="PDF Upload"
        accept=".csv,.xlsx"
        type="file"
        style={{ display: "none" }}
        onChange={(e: any) => UploadFile(e)}
        onClick={handleInputClick}
        ref={inputElRefPdf}
      />
      {accLoader ? (
        <Box sx={{ padding: "0 1rem", width: "100%" }}>
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />

          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />

          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />

          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
        </Box>
      ) : (
        <>
          <Box sx={{
            display: 'flex',
            alignItems: 'center',
            columnGap: '0.5rem',
            width: '100%',
            borderBottom: '1px solid',
            paddingRight: '0.5rem',
          }} adl-scrollbar='true'
            adl-scrollbar-width='0.2'>
            {categoryList &&
              categoryList?.map((item: any, index: any) => {
                return (<>
                  <Box
                    key={index}
                    sx={{
                      color: '#007fff',
                      height: '2rem',
                      minHeight: '2.1rem',
                      maxWidth: '100% !important',
                      padding: '0',
                      fontSize: '1rem',
                      width: '100%',
                      backgroundColor:
                        item?.name == selectedTab
                          ? 'primary.main'
                          : 'primary.light',
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                    }}>
                    <Typography
                      sx={{
                        color:
                          item?.name == selectedTab ? 'white' : '#007fff',
                        height: '2rem',
                        minHeight: '2.1rem',
                        maxWidth: '100% !important',
                        padding: '0 0.5rem',
                        fontSize: '1rem',
                        width: '100%',
                        textAlign: 'center',
                        display: 'flex',
                        whiteSpace: 'nowrap',
                        alignItems: 'center',
                        justifyContent: 'center',
                        textTransform: 'capitalize',
                      }}
                      onClick={() => handleChange(item?.name)}>
                      {item?.name.toLowerCase()} ({item?.count})
                    </Typography>
                  </Box>
                  {/* <Accordion
                  expanded={expanded === item?.name}
                  onChange={handleChange(item?.name)}
                  sx={index === 0 ? { width: "100%" } : {}}>
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <AccordionSummary
                      aria-controls="panel1d-content"
                      id="panel1d-header"
                      sx={{ width: "100%", minHeight: "36px" }}>
                      <Typography sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", marginLeft: "0.5rem" }}>
                        <Typography sx={{ fontSize: "1rem" }}>{item?.name}</Typography>
                        <Typography sx={{ fontSize: "1rem" }}>{item?.count} Bought-out parts</Typography>
                      </Typography>
                    </AccordionSummary>
                    {index === 0 &&(useraction &&  useraction?.includes("C") || useraction &&  useraction?.includes("U")) &&(
                      <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", columnGap: "0.5rem", padding: " 0 0.5rem" }}>
                        <LoadingButton
                          loading={resverUpload}
                          title="Upload"
                          onClick={() => ReverseUpload()}
                          sx={{
                            cursor: "pointer",
                            fontSize: { lg: "1.8rem", xl: "1.6rem" },
                            minWidth: "1rem",
                            ".MuiCircularProgress-root": {
                              color: "primary.main",
                            },
                          }}
                          startIcon={<UploadIcon sx={{ marginRight: "-1rem" }} />}
                        />
                      {<LoadingButton
                          title="Download"
                          sx={{
                            color: "primary.main",
                            fontSize: { lg: "1.8rem", xl: "1.6rem" },
                            minWidth: "1rem",
                            ".MuiCircularProgress-root": {
                              color: "primary.main",
                            },
                          }}
                          loading={Loader}
                          onClick={(e: any) => DownLoadFile(e)}
                          startIcon={<GetAppIcon sx={{ marginRight: "-1rem" }} />}
                        />}
                       {useraction &&  useraction?.includes("C") && <Button
                          size="small"
                          startIcon={<AddIcon sx={{ marginRight: "-0.5rem" }} />}
                          sx={{ whiteSpace: "nowrap" }}
                          onClick={() => subRevisionEdit.open("add")}>
                          Add BOP
                        </Button>}
                      </Box>
                    )}
                  </Box>

                  <AccordionDetails>
                    <Box
                      sx={{
                        height: expanded !== "Electrical & Electronics Proprietary Parts" ? 400 : "100%",
                        width: "100%",
                        "& .super-app-theme--cell": {
                          backgroundColor: "#ddefff !important",
                          textAlign: "center !important",
                        },
                      }}>
                      {tableData && expanded && categoryName !== "Electrical & Electronics Proprietary Parts" && getColumnData(expanded)?.length > 0 ? (
                        <DataGrid
                          headerHeight={42}
                          rowHeight={36}
                          {...getRowData(expanded)}
                          loading={tableDataLoading}
                          // checkboxSelection
                          // disableRowSelectionOnClick
                          components={{
                            Toolbar: () => {
                              return CustomToolbar();
                            },
                          }}
                          sx={{
                            "& ::-webkit-scrollbar": {
                              width: "0.3rem",
                              height: "0.3rem",
                            },
                            "& ::-webkit-scrollbar-thumb": {
                              // background: $thumb-color;
                              backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                              borderRadius: "10px",
                            },
                            "& ::- webkit-scrollbar-thumb: hover ": {
                              // width: "4px",
                              backgroundColor: "#045DE9 !important",
                            },
                            "::-webkit-scrollbar-track": {
                              backgroundColor: "#e1e1f3 !important",
                            },

                            "&.MuiDataGrid-root": {
                              border: "none",
                            },

                            "&.MuiDataGrid-root .MuiDataGrid-cell": {
                              borderBottomColor: "primary.light",
                            },
                          }}
                        />
                      ) : (
                        <Box
                          sx={{
                            height: expanded !== "Electrical & Electronics Proprietary Parts" ? 400 : "100%",
                            width: "100%",
                            "& .super-app-theme--cell": {
                              backgroundColor: "#ddefff !important",
                              textAlign: "center !important",
                            },
                          }}>
                          {electricalCategories &&
                            electricalCategories?.map((item: any, index: any) => {
                              return (
                                <Accordion
                                  expanded={expandedElectrical === item?.module_type}
                                  onChange={handleChangeElectrical(item?.module_type)}
                                  sx={{ marginLeft: "2.5rem" }}>
                                  <AccordionSummary
                                    aria-controls="panel1d-content"
                                    id="panel1d-header"
                                    sx={{ width: "100%", minHeight: "36px" }}>
                                    <Typography sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", marginLeft: "0.5rem" }}>
                                      <Typography sx={{ fontSize: "1rem" }}>{item?.module_type}</Typography>
                                      <Typography sx={{ fontSize: "1rem" }}>{item?.count} Bought-out parts</Typography>
                                    </Typography>
                                  </AccordionSummary>
                                  <AccordionDetails>
                                    <Box
                                      sx={{
                                        height: 400,
                                        width: "100%",
                                        "& .super-app-theme--cell": {
                                          backgroundColor: "#ddefff !important",
                                          textAlign: "center !important",
                                        },
                                      }}>
                                      {expanded && expandedElectrical && getColumnDataElectrical(expandedElectrical)?.length > 0 && (
                                        <DataGrid
                                          headerHeight={42}
                                          rowHeight={36}
                                          {...getRowDataElectrical(expandedElectrical)}
                                          loading={subElectricalLoadings}
                                          // checkboxSelection
                                          // disableRowSelectionOnClick
                                          components={{
                                            Toolbar: () => {
                                              return CustomToolbar();
                                            },
                                          }}
                                          sx={{
                                            "& ::-webkit-scrollbar": {
                                              width: "0.3rem",
                                              height: "0.3rem",
                                            },
                                            "& ::-webkit-scrollbar-thumb": {
                                              // background: $thumb-color;
                                              backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                                              borderRadius: "10px",
                                            },
                                            "& ::- webkit-scrollbar-thumb: hover ": {
                                              // width: "4px",
                                              backgroundColor: "#045DE9 !important",
                                            },
                                            "::-webkit-scrollbar-track": {
                                              backgroundColor: "#e1e1f3 !important",
                                            },
                                            "&.MuiDataGrid-root": {
                                              border: "none",
                                            },
                                            "&.MuiDataGrid-root .MuiDataGrid-cell": {
                                              borderBottomColor: "primary.light",
                                            },
                                          }}
                                        />
                                      )}
                                    </Box>
                                  </AccordionDetails>
                                </Accordion>
                              );
                            })}
                        </Box>
                      )}
                    </Box>
                  </AccordionDetails>
                </Accordion> */}
                </>);
              })}
          </Box>
          <Box sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: 'flex-end',
            marginLeft: "0.5rem",
            columnGap: "0.5rem",
            width: '99%',
          }}>
            {(useraction && useraction?.includes("C") || useraction && useraction?.includes("U")) && (
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", columnGap: "0.5rem", padding: " 0 0.5rem" }}>
                <LoadingButton
                  loading={resverUpload}
                  title="Upload"
                  onClick={() => ReverseUpload()}
                  sx={{
                    padding: '0.2rem 1rem',
                    zIndex: 1,
                    cursor: "pointer",
                    fontSize: { lg: "1.8rem", xl: "1.6rem" },
                    minWidth: "1rem",
                    ".MuiCircularProgress-root": {
                      color: "primary.main",
                    },
                  }}
                  startIcon={<UploadIcon sx={{ marginRight: "-1rem" }} />}
                />
                {<LoadingButton
                  title="Download"
                  sx={{
                    padding: '0.2rem 1rem',
                    zIndex: 1,
                    color: "primary.main",
                    fontSize: { lg: "1.8rem", xl: "1.6rem" },
                    minWidth: "1rem",
                    ".MuiCircularProgress-root": {
                      color: "primary.main",
                    },
                  }}
                  loading={Loader}
                  onClick={(e: any) => DownLoadFile(e)}
                  startIcon={<GetAppIcon sx={{ marginRight: "-1rem" }} />}
                />}
                {useraction && useraction?.includes("C") && <Button
                  size="small"
                  startIcon={<AddIcon sx={{ marginRight: "-0.5rem" }} />}
                  sx={{ whiteSpace: "nowrap", zIndex: 1, }}
                  onClick={() => subRevisionEdit.open("add")}>
                  Add BOP
                </Button>}
              </Box>
            )}
          </Box>
          {tableData && selectedTab && categoryName !== "Electrical & Electronics Proprietary Parts" && getColumnData(selectedTab)?.length > 0 ? (<Box
            sx={{
              height: { lg: '78vh', xl: '83vh' },
              // height: selectedTab !== "Electrical & Electronics Proprietary Parts" ? 400 : "100%",
              width: "100%",
              "& .super-app-theme--cell": {
                backgroundColor: "#ddefff !important",
                textAlign: "center !important",
              },
            }}>
            <DataGrid
              headerHeight={42}
              rowHeight={36}
              {...getRowData(selectedTab)}
              loading={tableDataLoading}
              // checkboxSelection
              // disableRowSelectionOnClick
              components={{
                Toolbar: () => {
                  return CustomToolbar();
                },
              }}
              sx={{
                "& ::-webkit-scrollbar": {
                  width: "0.3rem",
                  height: "0.3rem",
                },
                "& ::-webkit-scrollbar-thumb": {
                  // background: $thumb-color;
                  backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                  borderRadius: "10px",
                },
                "& ::- webkit-scrollbar-thumb: hover ": {
                  // width: "4px",
                  backgroundColor: "#045DE9 !important",
                },
                "::-webkit-scrollbar-track": {
                  backgroundColor: "#e1e1f3 !important",
                },

                "&.MuiDataGrid-root": {
                  border: "none",
                },

                "&.MuiDataGrid-root .MuiDataGrid-cell": {
                  borderBottomColor: "primary.light",
                },
              }}
            />
          </Box>) : tableData && selectedTab && categoryName == "Electrical & Electronics Proprietary Parts" ? (
            <Box
              sx={{
                marginTop: '-2.1rem',
                height: selectedTab !== "Electrical & Electronics Proprietary Parts" ? { lg: '73vh', xl: '79vh' } : " ",
                // height: expanded !== "Electrical & Electronics Proprietary Parts" ? 400 : "100%",
                width: "100%",
                "& .super-app-theme--cell": {
                  backgroundColor: "#ddefff !important",
                  textAlign: "center !important",
                },
              }}>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                columnGap: '0.5rem',
                width: '100%',
                borderBottom: '1px solid',
              }} adl-scrollbar='true'
                adl-scrollbar-width='0.2'>
                {electricalCategories && selectedTab &&
                  electricalCategories?.map((item: any, index: any) => {
                    return (<>
                      <Box
                        key={index}
                        sx={{
                          color: '#007fff',
                          height: '2rem',
                          minHeight: '2.1rem',
                          maxWidth: '100% !important',
                          padding: '0',
                          fontSize: '1rem',
                          width: '100%',
                          backgroundColor:
                            item?.module_type == selectedModule
                              ? 'primary.main'
                              : 'primary.light',
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          zIndex: 1,
                        }}>
                        <Typography
                          sx={{
                            color:
                              item?.module_type == selectedModule ? 'white' : '#007fff',
                            height: '2rem',
                            minHeight: '2.1rem',
                            maxWidth: '100% !important',
                            padding: '0 0.5rem',
                            fontSize: '1rem',
                            width: '100%',
                            textAlign: 'center',
                            display: 'flex',
                            whiteSpace: 'nowrap',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textTransform: 'capitalize',
                          }}
                          onClick={() => handleChangeElectrical(item?.module_type)}>
                          {item?.module_type.toLowerCase()} ({item?.count})
                        </Typography>
                      </Box>
                      {/* <Accordion
                                expanded={expandedElectrical === item?.module_type}
                                onChange={handleChangeElectrical(item?.module_type)}
                                sx={{ marginLeft: "2.5rem" }}>
                                <AccordionSummary
                                  aria-controls="panel1d-content"
                                  id="panel1d-header"
                                  sx={{ width: "100%", minHeight: "36px" }}>
                                  <Typography sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%", marginLeft: "0.5rem" }}>
                                    <Typography sx={{ fontSize: "1rem" }}>{item?.module_type}</Typography>
                                    <Typography sx={{ fontSize: "1rem" }}>{item?.count} Bought-out parts</Typography>
                                  </Typography>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Box
                                    sx={{
                                      height: 400,
                                      width: "100%",
                                      "& .super-app-theme--cell": {
                                        backgroundColor: "#ddefff !important",
                                        textAlign: "center !important",
                                      },
                                    }}>
                                    {expanded && expandedElectrical && getColumnDataElectrical(expandedElectrical)?.length > 0 && (
                                      <DataGrid
                                        headerHeight={42}
                                        rowHeight={36}
                                        {...getRowDataElectrical(expandedElectrical)}
                                        loading={subElectricalLoadings}
                                        // checkboxSelection
                                        // disableRowSelectionOnClick
                                        components={{
                                          Toolbar: () => {
                                            return CustomToolbar();
                                          },
                                        }}
                                        sx={{
                                          "& ::-webkit-scrollbar": {
                                            width: "0.3rem",
                                            height: "0.3rem",
                                          },
                                          "& ::-webkit-scrollbar-thumb": {
                                            // background: $thumb-color;
                                            backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                                            borderRadius: "10px",
                                          },
                                          "& ::- webkit-scrollbar-thumb: hover ": {
                                            // width: "4px",
                                            backgroundColor: "#045DE9 !important",
                                          },
                                          "::-webkit-scrollbar-track": {
                                            backgroundColor: "#e1e1f3 !important",
                                          },
                                          "&.MuiDataGrid-root": {
                                            border: "none",
                                          },
                                          "&.MuiDataGrid-root .MuiDataGrid-cell": {
                                            borderBottomColor: "primary.light",
                                          },
                                        }}
                                      />
                                    )}
                                  </Box>
                                </AccordionDetails>
                              </Accordion> */}
                    </>
                    );
                  })}
              </Box>
              <Box
                sx={{
                  height: { lg: '79vh', xl: '83vh' },
                  width: { lg: "96vw", xl: '96.5vw' },
                  // width: "100%",
                  "& .super-app-theme--cell": {
                    backgroundColor: "#ddefff !important",
                    textAlign: "center !important",
                  },
                }}>
                {selectedModule && getColumnDataElectrical(selectedModule)?.length > 0 && (
                  <DataGrid
                    headerHeight={42}
                    rowHeight={36}
                    {...getRowDataElectrical(selectedModule)}
                    loading={subElectricalLoadings}
                    // checkboxSelection
                    // disableRowSelectionOnClick
                    components={{
                      Toolbar: () => {
                        return CustomToolbarElectrical();
                      },
                    }}
                    sx={{
                      "& ::-webkit-scrollbar": {
                        width: "0.3rem",
                        height: "0.3rem",
                      },
                      "& ::-webkit-scrollbar-thumb": {
                        // background: $thumb-color;
                        backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                        borderRadius: "10px",
                      },
                      "& ::- webkit-scrollbar-thumb: hover ": {
                        // width: "4px",
                        backgroundColor: "#045DE9 !important",
                      },
                      "::-webkit-scrollbar-track": {
                        backgroundColor: "#e1e1f3 !important",
                      },
                      "&.MuiDataGrid-root": {
                        border: "none",
                      },
                      "&.MuiDataGrid-root .MuiDataGrid-cell": {
                        borderBottomColor: "primary.light",
                      },
                    }}
                  />
                )}
              </Box>
            </Box>
          ) : <Box sx={{ width: '100%', height: '92%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography>Select Category</Typography>
          </Box>}
        </>
      )}

      {subRevisionEdit.isOpen && (
        <CreateBoughtOutDatabaseModal
          isOpen={subRevisionEdit.isOpen}
          onCloseModal={subRevisionEdit.close}
          actions={subRevisionEdit.propsId}
          expanded={expanded}
          getMaterialData={getMaterialData}
          categoryList={categoryList}
          getCategoryData={getCategoryData}
          handleChangeElectrical={handleChangeElectrical}
          getElectricalCategories={getElectricalCategories}
        />
      )}
      {bopMoveModal.isOpen && (
        <RMBOPmoveModal
          isOpen={bopMoveModal.isOpen}
          onCloseModal={bopMoveModal.close}
          actions={bopMoveModal.propsId}
          expanded={expanded}
          getMaterialData={getMaterialData}
          categoryList={categoryList}
          getCategoryData={getCategoryData}
          categoryListMaterial={categoryListMaterial}
        />
      )}
      {fetchRawMaterial.isOpen && (
        <FetchRawMaterialModal
          isOpen={fetchRawMaterial.isOpen}
          onCloseModal={fetchRawMaterial.close}
          categorydata={categoryList && categoryList}
        />
      )}
      {databaseMaterialInfo.isOpen && (
        <InfoBoughtoutrevisionModal
          isOpen={databaseMaterialInfo.isOpen}
          onCloseModal={databaseMaterialInfo.close}
          itemId={databaseMaterialInfo.propsId}
        />
      )}

    </div>
  );
}
