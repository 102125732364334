// import { ID, ObjectType } from "app/commonInterfaces";
// import { ICustomValidation } from "app/Redux/Reducers/customValidation.reducer";
// import { Vault } from "app/Redux/Reducers/vault.reducer";
import { ICustomValidation } from "../Redux/Reducers/customValidation.reducer";
import { Vault } from "../Redux/Reducers/vault.reducer";
import { ID, ObjectType } from "./commonInterfaces";
import { AnyExpression } from "./expression";
// import { AnyExpression } from "app/utils/expression";

const customValidate = (
    parameter_id: ID,
    /** parameter id to data_type */
    dbTypeMap: { [parameter_id: string]: string },
    validations: any,
    err: any,
    values: Record<string, any>,
    vault: Pick<Vault, "name" | "part_no">,
    mappingToDbMap: any
) => {
    let error: string | undefined = err;
    validations.forEach((v: any) => {
        const exp: ObjectType = {};

        v.expressions.forEach((e: any, i: any) => {
            const { lhs, operation, rhs, type } = e;
            /**
             * lhs,rhs are mapping ids, i.e, parameterConfig id,
             * to extract the corresponding values mappingId has to be converted to parameter_id(databases module parameter)
             */
            const modifiedLHS = mappingToDbMap[lhs];
            const modifiedRHS = mappingToDbMap[rhs];

            switch (dbTypeMap[lhs]) {
                case "boolean":
                case "checkbox":
                    break;
                case "string":
                case "singleselect":
                case "multiselect":
                case "textarea":
                    if (type === "p-p")
                        exp[i + 1] = new AnyExpression(
                            values[modifiedLHS],
                            values[modifiedRHS]
                        );
                    else if (type === "p-m")
                        exp[i + 1] = new AnyExpression(
                            values[modifiedLHS],
                            vault?.[rhs as "name" | "part_no"] ?? ""
                        );
                    else

                        exp[i + 1] = new AnyExpression(
                            values[modifiedLHS],
                            rhs
                        );
                    break;
                case "integer":
                case "positive-int":
                case "negative-int":
                case "nonzero-integer":
                case "nonzero-negative-int":
                case "nonzero-positive-int":
                case "float":
                case "percentage":
                case "positive-float":
                case "negative-float":
                case "nonzero-float":
                case "nonzero-negative-float":
                case "nonzero-positive-float":
                    if (type === "p-p")
                        exp[i + 1] = new AnyExpression(
                            values[modifiedLHS],
                            values[modifiedRHS]
                        );
                    else if (type === "p-m")
                        exp[i + 1] = new AnyExpression(
                            values[modifiedLHS],
                            vault?.[rhs as "name" | "part_no"] ?? NaN
                        );
                    else
                    exp[i + 1] = new AnyExpression(
                        values[modifiedLHS],
                        isNaN(parseFloat(rhs as any))
                            ? rhs
                            : parseFloat(rhs as any)
                    );
                    break;
                default:
                    break;
            }

            exp[i + 1] = exp[i + 1]?.[operation]?.();
        });

        let evaluation = null;
        try {
            evaluation = eval(v.finalExpression);
        } catch (e) {
            console.warn("wrong final expression", v.finalExpression);
        }

        // if (
        //     evaluation &&
        //     v.allowedValue.length > 0 &&
        //     !v.allowedValue.includes(
        //         typeof values[parameter_id] !== "string"
        //             ? JSON.stringify(values[parameter_id])
        //             : values[parameter_id]
        //     )
        // )
        // error = `value should be one in: ${v.allowedValue.toString()}`;

        // if (
        //     evaluation &&
        //     v.notAllowedValue.length > 0 &&
        //     v.notAllowedValue.includes(
        //         typeof values[parameter_id] !== "string"
        //             ? JSON.stringify(values[parameter_id])
        //             : values[parameter_id]
        //     )
        // )
        // error = `value can't be one in: ${v.notAllowedValue.toString()}`;

        if (
            !evaluation 
            // &&
            // v.allowedValue.length === 0 &&
            // v.notAllowedValue.length === 0
        )
            error =
                typeof v.message === "string" && v.message.length === 0
                    ? `invalid input`
                    : v.message;
    });
    return error;
};

export { customValidate };
