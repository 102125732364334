import * as React from 'react';
import styles from '../EIRandWorkshop/EIRandWorkshopLanding.module.scss';
import {
  Box,
  Checkbox,
  Divider,
  Drawer,
  IconButton,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import { useHistory } from "react-router-dom";
import { useState } from 'react';
import { useBaseModal } from '../SearchPage/useBaseModal';
import { useRouteParams } from '../../ui-reusable-component/useRouteParams';
import { API } from '../../api-services';
import { LoadingButton } from '@mui/lab';
import { AxiosError, AxiosResponse } from 'axios';
import { BannerEIRandWorkshopPage } from './BannerEIRandWorkshopPage';

interface Params{
  projectId:any;
  projectname:any;
}
export interface IBannerPageProps {
}

type Anchor = 'top' | 'left' | 'bottom' | 'right';

export function BannerPage(props: IBannerPageProps) {

  const {projectId,projectname} = useRouteParams<Params>();
  const [projectList, setProjectList] = useState<any>();
  const [selectedProject, setSelectedProject] = useState<any>([]);
  const [infoEIRWorkshop, setInfoEIRWorkshop] = useState<any>();
  const [workshopAndCompanionInfo, setWorkshopAndCompanionInfo] = useState<any>();
  const [apiCallWorkshopAndCompanionInfo,setApiCallWorkshopAndCompanionInfo] = useState<boolean>(false);

  const history = useHistory();
  const Workshoppresentupload = useBaseModal();
  const companionInfoModel = useBaseModal();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  React.useEffect(() => {
    API.get(`/customer/compare_module/`, { project:true,module:1 }, 0)
      .then((res: AxiosResponse) => {
        setProjectList(res?.data);
      })
      .catch((err: AxiosError) => {
        console.log(err, "error");
      });
  }, []);

  React.useEffect(()=>{
    API.get('workshop/banner_fields/',{get_validation_status:projectId},0).then((res:any)=>{
      setWorkshopAndCompanionInfo(res?.data[0]);
    }).catch((err:any)=>{
      console.log(err)
    });
  },[projectId,apiCallWorkshopAndCompanionInfo])

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }
        setState({ ...state, [anchor]: open });
        if (open) {
          setSelectedProject([Number(projectId)]);
        }
      };

  const selectAllProject = (productIds: any) => {
    setSelectedProject([productIds]);
  };

  const getselectedProjectBackgroundColor = (idd: any) => {
    if (selectedProject?.includes(idd)) {
      return "primary.main";
    } else {
      return "primary.light";
    }
  };

  const getSelectedProjectTextColor = (idd: any) => {
    if (selectedProject?.includes(idd)) {
      return "primary.light";
    } else {
      return "primary.main";
    }
  };

  const redirectToProject: any = () => {
    history.push(`/banner/${selectedProject[0]}/${projectList && projectList?.filter((item:any)=>item?.id == selectedProject[0])[0]?.name}/0/0/0`);
  };

  const list = (anchor: Anchor) => (
    <Box
      sx={{
        minWidth: 300,
        width: anchor === "top" || anchor === "bottom" ? "auto" : "auto",
      }}
      role="presentation"
    >
      <Box sx={{ width: "100%", padding: "0" }}>
        <Box
          sx={{ width: "100%", padding: "0 1rem" }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3"
        >
          <Box>
            <Typography
              style={{
                width: "100%",
                fontSize: "0.75rem",
              }}
            >
              Project Name
            </Typography>
            {projectList &&
              projectList?.map((item: any, index: any) => {
                return (
                  <>
                    <Box sx={{ alignItems: "center" }}>
                      <Box sx={{ display: "flex", alignItems: "center" }}>
                        <Checkbox
                          style={{ padding: "3px" }}
                          checked={
                            selectedProject?.includes(item.id) ? true : false
                          }
                          onClick={() => selectAllProject(item?.id)}
                          inputProps={{ "aria-label": "controlled" }}
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                        />
                        <Box
                          onClick={() => selectAllProject(item?.id)}
                          key={index}
                          className={styles.selectVehBtn}
                          sx={{
                            // margin: "0rem 2rem",
                            padding: "0.2rem 0.5rem",
                            backgroundColor: getselectedProjectBackgroundColor(
                              item.id
                            ),
                            color: getSelectedProjectTextColor(item.id),
                            textAlign: "left",
                            cursor: "pointer",
                            width: "100%",
                          }}
                        >
                          <Typography
                            style={{ fontSize: "0.87rem", display: "flex" }}
                          >
                            {item.name}{" "}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                  </>
                );
              })}
          </Box>
        </Box>
        <div
          style={{
            paddingBottom: "0.5rem",
            position: "sticky",
            bottom: "0",
            height: "4rem",
            backgroundColor: "aliceblue",
            marginTop: "2rem",
          }}
        >
          <Box sx={{ textAlign: "right", paddingRight: "1rem" }}>
            <Button
              className={styles.drawBtn}
              sx={{
                marginRight: "1rem",
                fontSize: "0.75rem",
                "&:hover": {
                  transform: "Scale(1.1)",
                  transition: "transform 0.5s ease",
                },
              }}
              variant="contained"
              onClick={toggleDrawer(anchor, false)}
              size="small"
            >
              Close
            </Button>
            <LoadingButton size="small" onClick={redirectToProject}>
              <Button
                variant="contained"
                sx={{
                  fontSize: "0.75rem",
                  "&:hover": {
                    transform: "Scale(1.1)",
                    transition: "transform 0.5s ease",
                  },
                }}
                onClick={toggleDrawer(anchor, false)}
                size="small"
              >
                Ok
              </Button>
            </LoadingButton>
          </Box>
        </div>
      </Box>
    </Box>
  );


  const handleClick = () => {
    history.goBack();
  };


  return (
    <Box>
      <Box sx={{ padding: "0 0.5rem" }}>
        <Box sx={{
          display: "grid",
          gridTemplateColumns: {
            sm: "1fr 1fr",
            md: "2fr 1fr",
            lg: "2fr 0.7fr",
            xl: "2fr 0.7fr",
          }
        }}>
          <Box sx={{ display: 'flex', columnGap: '1rem', alignItems: 'center' }}>
            <IconButton sx={{ cursor: "pointer", }} onClick={handleClick}>
              <ArrowBackIcon
                color="primary"
                titleAccess="Go Back"
              />
            </IconButton>
            <Typography sx={{ fontSize: '1rem', whiteSpace: 'nowrap' }}> EIR & Workshop [ {projectname} ]</Typography>
            {workshopAndCompanionInfo && workshopAndCompanionInfo?.workshop && <Box sx={{ display: 'flex', columnGap: '1rem', alignItems: 'center', justifyContent: 'end', width: '100%' }}>
              <Box sx={{ display: 'flex', columnGap: '1rem', alignItems: 'center' }}>
                <Box sx={{cursor:'pointer', backgroundColor: 'primary.main', borderRadius: '3rem', display: 'flex', columnGap: '0.5rem', padding: '0.3rem 1rem', alignItems: 'center' }}>
                  <Typography onClick={() => window.open(workshopAndCompanionInfo?.workshop_file)} sx={{ fontSize: '1rem', whiteSpace: 'nowrap', color: 'white' }}>Workshop Presentation</Typography>
                </Box>
              </Box>
            </Box>}
          </Box>
          <Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: 'flex', alignItems: 'flex-start', width: '100%' }}>
        <Box className={styles.expandIconBox}>
          <div className={styles.iconSec}>
            {(["left"] as const).map((anchor) => (
              <React.Fragment key={anchor}>
                <IconButton
                  size="medium"
                  sx={{
                    backgroundColor: "primary.light",
                    color: "primary.main",
                  }}
                  onClick={toggleDrawer(anchor, true)}
                >
                  <AddIcon sx={{ fontSize: "1.7rem" }} />
                </IconButton>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  className={styles.drawerContainer}
                  PaperProps={{
                    sx: {
                      backgroundColor: "aliceblue",
                    },
                  }}
                >
                  <Box
                    sx={{ padding: "0.5rem 0", position: "sticky", top: "0" }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3"
                  >
                    <Typography
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "1rem",
                      }}
                    >
                      Select Project
                    </Typography>
                    <Divider sx={{ margin: "0.2rem" }} />
                    {list(anchor)}
                  </Box>
                </Drawer>
                <Typography
                  sx={{ fontSize: "1rem", color: "primary.main" }}
                  onClick={toggleDrawer(anchor, true)}
                  className={styles.sideTitle}
                >
                  Selection Options
                </Typography>
              </React.Fragment>
            ))}
          </div>
        </Box>
        <Box sx={{ width: { lg: '96%', xl: '97%' } }}>
          <BannerEIRandWorkshopPage setInfoEIRWorkshop={setInfoEIRWorkshop}/>
        </Box>
      </Box>
    </Box >
  );
}
