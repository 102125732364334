import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
// import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { object } from "yup";
import { API } from "../../api-services";
import NoImgLoader from "../../Assets/images/loading-screen-landing.gif";
import Skeleton from '@mui/material/Skeleton';
import Image from "../../ViewModule/FeatureLandingPage/ArchitectureImageCard";
import BOMcomparePage from "./BOMcomparePage";
import CostingComparePage from "./CostingComparePage";
import VehiclelevelCostCompare from "./VehiclelevelCostCompare";

interface CompareViewpageProps {
  counts?:any
}

const CompareViewpage = (props: CompareViewpageProps) => {
  const { projectId, moduleType, topVaultId, abbr, NodeId, TopId, Partname, Nodeleaf, depthNode } =
    useParams<any>();
    const {counts}=props;



    console.log(Partname,"PartnamePartnamePartname")
  return (

    <Box>
      {moduleType == 1 ?
        <BOMcomparePage />
        :
<>
        {abbr=="product"?<VehiclelevelCostCompare counts={counts}/>:
        <CostingComparePage counts={counts} />
        
        
        }
        </>
      }
    </Box >
  );
};
export default CompareViewpage;
