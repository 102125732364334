// react
import React from 'react';
import { useAppDispatch } from '../../../../AppRouter';
import { useSelector } from 'react-redux';
import { getGanttChartNotes, resetGanttChart, updateGanttChartData } from '../../../Redux/Actions/ganttChart.action';
import { useQueryDispatch } from '../../../CustomHook/useQueryDispatch';
import { GannttChartNotessSelectors } from '../../../Redux/Reducers/GannttChartNotes.reducers';
import { Box, Button, Modal, Skeleton, TextareaAutosize, Typography } from '@mui/material';

// vendors
// services
// utils
// constants
// factories
// core
// redux
// ui-components
// others
// styles
// import styles from "./GanttChartMessageModal.module.scss";

// interfaces and types
// lazy

// ----------------------------------------------------------------------------------

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    height: "fit-content",
    width: "40vw",
    maxWidth: '80vw',
    maxHeight: "80vh",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    borderRadius: '10px',
    // border: "2px solid #000",
    // boxShadow: 24,
    p: 2,
};

interface GanttChartMessageModalProps {
    isOpen: boolean;
    onClose: () => void;
    modalData: any;
}

const GanttChartMessageModal: React.FC<GanttChartMessageModalProps> = (props) => {


    const dispatch = useAppDispatch();
    const { isOpen, onClose, modalData } = props;

    const [notes, setNotes] = React.useState("");


    const ganttChartFetch = useQueryDispatch({
        query: {
            action: getGanttChartNotes,
            arg: { id: modalData?.id },
            condition: modalData?.id !== undefined
        },
        cleanup: {
            action: resetGanttChart,
            args: [],
        },
        dependency: [modalData?.id],
    });


    const modulenotes: any = useSelector(GannttChartNotessSelectors.selectAll);

    React.useEffect(() => {
        if (modulenotes) {
            setNotes(modulenotes[0]?.notes);
        }
    }, [modulenotes]);



    const inferenceChangeHandler = (e: any) => {
        setNotes(e.target.value);
    };


    const handleSave = async () => {
        if (notes !== modulenotes[0]?.notes) {
            let action = await dispatch(updateGanttChartData({ id: modalData.id, notes: notes }));
        }
        else {
            onClose();
            // setNotes( "" );
        }
    };

    const handleClose = () => {
        setNotes("");
        onClose();
    }

    return (
        <Modal
            open={isOpen}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ width: '100%',padding:'1rem' }}
        >
            <Box sx={style}>
                <div style={{ backgroundColor: 'white', width: '100%' }}>
                    {ganttChartFetch.match("TRUE") ? <div>
                        <Typography sx={{fontSize:'1rem',marginBottom:'0.5rem'}}>{`${modalData?.activity_name} remarks`}</Typography>
                        <div>
                            <TextareaAutosize
                                placeholder="Inference"
                                onChange={inferenceChangeHandler}
                                value={notes}
                                style={{
                                    width: '100%',
                                    // minWidth: "95%",
                                    // maxWidth: "95%",
                                    fontSize: "1rem",
                                    margin: '0 auto',
                                    padding: '1rem',
                                    border: "1px solid #007fff14",
                                    maxWidth:'40vw',
                                    maxHeight:'65vh',

                                }}

                            />

                        </div>
                        <div style={{ display: 'flex', justifyContent: 'end' }}>
                            <Button size='small' onClick={handleSave}
                            variant='contained'
                            sx={{"&:hover": {
                                transform: 'Scale(1.1)',
                                transition: 'transform 0.5s ease',
                              }}}>
                                {notes !== modulenotes[0]?.notes ? "Save" : "Close"}
                            </Button>
                        </div>
                    </div> : <Box sx={{width:'37vw',}}>
                        <Skeleton animation="pulse" width={"100%"} height={'1.5rem'} sx={{ margin: '0.5rem 1.5rem' }} />
                        <Skeleton animation="pulse" width={"100%"} height={'1.5rem'} sx={{ margin: '0.5rem 1.5rem' }} />
                        <Skeleton animation="pulse" width={"100%"} height={'1.5rem'} sx={{ margin: '0.5rem 1.5rem' }} />
                        <Skeleton animation="pulse" width={"100%"} height={'1.5rem'} sx={{ margin: '0.5rem 1.5rem' }} />
                        <Skeleton animation="pulse" width={"100%"} height={'1.5rem'} sx={{ margin: '0.5rem 1.5rem' }} />
                        <Skeleton animation="pulse" width={"100%"} height={'1.5rem'} sx={{ margin: '0.5rem 1.5rem' }} />
                    </Box>}
                </div>
            </Box>
        </Modal>
    );
};

// ----------------------------------------------------------------------------------

export default GanttChartMessageModal;