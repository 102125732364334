import { Skeleton, TableCell, TableRow, TextField } from '@mui/material'
import { isUndefined } from 'lodash';
import React, { useEffect, useState } from 'react'
import SaveIcon from '@mui/icons-material/Save';
import InfoIcon from '@mui/icons-material/Info';
import DeleteIcon from '@mui/icons-material/Delete';
import { API } from '../../api-services';
import _ from 'lodash';
import swal from "sweetalert";
import Swal from 'sweetalert2';
interface ProductCategoryRowProps {

    rowItem: any;
    savetype: any;
    setsaveType: any;
    setCounter: any;
    GetRowIds: any;
   
}

const ProductCategoryRow = (props: ProductCategoryRowProps) => {
    const { rowItem, savetype, setsaveType, setCounter, GetRowIds } = props
    const [loader, setloader] = useState<any>(false)
    const [updatedIds, setUpdatedIds] = useState<any>()
    const [Name, setName] = useState<any>()
    const [Abbreviation, setAbbreviation] = useState<any>()
    const [Description, setDescription] = useState<any>()
    const [Color, setColor] = useState<any>(false)
    const [nameError, setnameError] = useState<any>(false)
    const [AbbrError, setAbbrErrorr] = useState<any>(false)

    const [desError, setdesError] = useState<any>(false)


    useEffect(() => {

        if (!isUndefined(rowItem)) {
            setName(rowItem?.name)
            setAbbreviation(rowItem?.abbreviation)
            setDescription(rowItem?.description)
        }

    }, [rowItem])
    const checkUpdateFileddata = (Item: any) => {

        if (Item?.name !== Name) {

            return { name: Name }
        } if (Item?.description !== Description) {
            return { description: Description }
        } if (Item?.abbreviation !== Abbreviation) {
            // console.log(Item, Abbreviation, "Abbreviation")
            return { abbreviation: Abbreviation }

        }
    }

    const handleChange = (e: any, type: any) => {
        setColor(true)

        if (type == "name") {
            setnameError(false)
            setName(e?.target?.value)
        }
        if (type == "abbr") {
            setAbbrErrorr(false)
            setAbbreviation(e?.target?.value)
        }
        if (type == "description") {
            setdesError(false)
            setDescription(e?.target?.value)
        }

    }


    const CreateSaveRowHandler = (Id: any) => {
        if (savetype == "create") {

            if (Name !== null && Name?.length > 0 && Abbreviation !== null && Abbreviation?.length > 0 && Description !== null && Description?.length > 0) {


                setUpdatedIds(Id)
                setloader(true)
                API.post("/api/db/vehicle_type/", { abbreviation: Abbreviation, description: Description, name: Name }).then((res: any) => {
                    setsaveType("update")
                    setloader(false)
                    setColor(false)
                    setCounter((prev: any) => prev + 1)
                }).catch((err: any) => {
                   
                    setloader(false)
                    console.log("Sever error")
                })

            }
            if (Name !== null && Name?.length == 0) {
                setnameError(true)
            } if (Description !== null && Description?.length == 0) {
                setdesError(true)
            } if (Abbreviation !== null && Abbreviation?.length == 0) {
                setAbbrErrorr(true)
            }
        }
        else {
            if (Name !== null && Name?.length > 0 && Abbreviation !== null && Abbreviation?.length > 0 && Description !== null && Description?.length > 0) {


                setloader(true)
                setUpdatedIds(Id)
                API.put(`/api/db/vehicle_type/${Id}/`, { ...checkUpdateFileddata(rowItem) }).then((res: any) => {
                    setloader(false)
                    setCounter((prev: any) => prev + 1)
                    setColor(false)
                }).catch((err: any) => {
                    setloader(false)
                    console.log("Sever error")
                })

            }
            if (Name !== null && Name?.length == 0) {
                setnameError(true)
            } if (Description !== null && Description?.length == 0) {
                setdesError(true)
            } if (Abbreviation !== null && Abbreviation?.length == 0) {
                setAbbrErrorr(true)
            }

        }
    }
    const DeleteHanlder = (Id: any) => {


        swal({

            text: "Are you sure you want to delete ?",
            buttons: ["No", "Yes"],
        }).then(confirm => {
            if (confirm) {
                API.delete(`/api/db/vehicle_type/${Id}`).then((res: any) => {
                    setCounter((prev: any) => prev + 1)
                }).catch((err:any)=>{
                    const {data}=err?.response;
                    Swal.fire({
                        icon:"error",
                        html: `<div>
                        <br />
                        <p style="font-size: 1rem,color:"red">${data[0]}</p>
                         </div>`,
                 
            
                    })
                })
            }

        });
    }

    const InformationHanlder = (id: any) => {
        GetRowIds(id)
    }



    return (
        <>
            {updatedIds == rowItem?.id && loader ? <>

                <TableRow>
                    <TableCell sx={{ padding: '0.2rem 0.5rem', }}>
                        <Skeleton animation="wave" />
                    </TableCell>
                    <TableCell sx={{ padding: '0.2rem 0.5rem', }}>
                        <Skeleton animation="wave" />
                    </TableCell>
                    <TableCell sx={{ padding: '0.2rem 0.5rem', }}>
                        <Skeleton animation="wave" />
                    </TableCell>
                    <TableCell sx={{ padding: '0.2rem 0.5rem', }}>
                        <Skeleton animation="wave" />
                    </TableCell>
                </TableRow>
            </>
                :
                <TableRow>
                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <TextField
                            variant='standard'
                            error={nameError}
                            value={Name}

                            focused={rowItem?.status}

                            onChange={(e: any) => handleChange(e, "name")}
                            size='small'
                            InputProps={{
                                style: { fontSize: '1rem' },
                            }}
                            sx={{
                                '& .MuiInput-underline:before': { borderBottomColor: 'white' },
                                '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                            }}
                        />
                    </TableCell>
                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <TextField
                         focused={rowItem?.status}
                            variant='standard'
                            error={AbbrError}
                            value={Abbreviation}
                            onChange={(e: any) => handleChange(e, "abbr")}
                            size='small'
                            InputProps={{
                                style: { fontSize: '1rem' },
                            }}
                            sx={{
                                '& .MuiInput-underline:before': { borderBottomColor: 'white' },
                                '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                            }}
                        />
                    </TableCell>
                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <TextField
                         focused={rowItem?.status}
                            variant='standard'
                            error={desError}
                            value={Description}
                            onChange={(e: any) => handleChange(e, "description")}
                            size='small'
                            InputProps={{
                                style: { fontSize: '1rem' },
                            }}
                            sx={{
                                '& .MuiInput-underline:before': { borderBottomColor: 'white' },
                                '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                            }}

                        />
                    </TableCell>
                    <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
                        <SaveIcon sx={{ color: Color ? "red" : "primary.main", cursor: "pointer" }} onClick={() => CreateSaveRowHandler(rowItem?.id)} />

                        {rowItem?.mapped_status ?
                            <InfoIcon sx={{ cursor: "pointer" }} onClick={() => InformationHanlder(rowItem?.id)} /> :
                            <DeleteIcon sx={{ cursor: "pointer" }} onClick={() => DeleteHanlder(rowItem?.id)} />

                        }
                    </TableCell>

                </TableRow>
            }
        </>
    )
}

export default ProductCategoryRow;