// react
import { Box, Skeleton } from "@mui/material";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { MODULES } from "../../Constants/modules.constants";
import useFetch from "../../Core/CustomHooks/useFetch";
import LetSuspense from "../../LetSuspense";
import driveData from "../../Redux/Services/driveData.service";
import reportsbank from "../../Redux/Services/reportsBank.service";
import BouncingLoader from "../../ui-reusable-component/BouncingLoader";
// import styles from "./DriveDirectory.module.scss";

// import RepotsLandingPage from "./RepotsLandingPage";
import RepotsProjectList from "./ReportsProjectList";
import { useDocumentTitle } from "../../ui-reusable-component/useDocumentTitle";
// interfaces and types
// lazy

// ----------------------------------------------------------------------------------

interface ReportsDirectoryProps {}

const ReportsDirectory: React.FC<ReportsDirectoryProps> = (props) => {
  useDocumentTitle( "My Reports" );
  // const { 0: fetched, 1: projects } = useFetch({
  //   method: reportsbank.getProjectRepotsBank,
  //   args: [],
  //   fallBackErrMsg: "Could not fetch projects",
  // });

  return (
    <Box>
      <Box>
        <RepotsProjectList />
      </Box>
    </Box>
  ) 
  
 
};

// ----------------------------------------------------------------------------------

export default ReportsDirectory;
