import {
  Backdrop,
  Box,
  Divider,
  IconButton,
  Modal,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import styles from "../../ViewModule/Comparefolder/CompareTreeViewcomponents.module.scss";
import FolderIcon from "@mui/icons-material/Folder";
import CircleIcon from "@mui/icons-material/Circle";
import calculator from "../../Assets/images/calculator.svg";
import * as React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { API } from "../../api-services";
import { ADMIN } from "../../Redux/Services/admin.service";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { TreeItem, TreeView } from "@mui/lab";
import openFolder from "../../Assets/images/svgs/openfolderblue.svg";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "70vw",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 2,
};

export function CalculatorRow({
  calculatorItem,
  data,
  setCalculators,
  calculators,
  getCalculators,
}: any) {
  const handleInputChangeSequence = (id: number, value: any) => {
    setCalculators(
      calculators?.map((row: any) =>
        row?.id === id
          ? {
              ...row,
              sequence: typeof value === "number" ? value : Number(value),
            }
          : row
      )
    );
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>, id?: any) => {
    const newValue = e.target.value;
    const oldValue = calculators?.find((row: any) => row?.id === id);
console.log(data);

    if (newValue !== oldValue?.id) {
      API.put(
        `config/change_sequence_in_configuration/${calculatorItem?.id}/`,
        {   
            process_group_sequence:(data?.type ===
                "calculator_category" && data?.name === "Process" && data?.type !== "process") ? true : null,
          sequence: typeof newValue === "number" ? newValue : Number(newValue),
        },
        {},
        0
      )
        .then(() => {
          ADMIN.toast.info("Sequence changed successfully");
          getCalculators(); // Refresh calculators after successful update
        })
        .catch((err: any) => {
          if (err?.response?.data) {
            ADMIN.toast.error(err.response.data[0]);
          }
        });
    }
  };

  return (
    <>
      <TableRow>
        <TableCell
          sx={{
            fontWeight: "bold",
            fontSize: "1rem",
            borderBottomColor: "primary.light",
            padding: "0.2rem 0.5rem",
            width: "14rem",
          }}
        >
          <TextField
            type="number"
            variant="standard"
            placeholder="Sequence"
            value={calculatorItem?.sequence}
            disabled={calculatorItem?.id === -1}
            onChange={(e) =>
              +e.target.value > 0 &&
              handleInputChangeSequence(calculatorItem?.id, +e.target.value)
            }
            onBlur={(e: any) => handleBlur(e, calculatorItem?.id)}
            sx={{
              width: "100px",
              "& .MuiInput-root": { padding: 0, height: "1.5rem" },
            }}
          />
        </TableCell>
        <TableCell
          sx={{
            fontWeight: "bold",
            fontSize: "1rem",
            borderBottomColor: "primary.light",
            padding: "0.2rem 0.5rem",
          }}
        >
          <Typography>{(data?.type ===
            "calculator_category" && data?.name === "Process") ? calculatorItem?.process : calculatorItem?.calculator_ref_name}</Typography>
        </TableCell>
      {(!(data?.type ===
            "calculator_category" && data?.name === "Process") && data?.type !== "process") &&  <TableCell
          sx={{
            fontWeight: "bold",
            fontSize: "1rem",
            borderBottomColor: "primary.light",
            padding: "0.2rem 0.5rem",
          }}
        >
          <Typography>
            {
              calculatorItem?.cost_calculator__commodity_mapping__commodity__name
            }
          </Typography>
        </TableCell>}
        {(!(data?.type ===
            "calculator_category" && data?.name === "Process") && data?.type !== "process") && <TableCell
          sx={{
            fontWeight: "bold",
            fontSize: "1rem",
            borderBottomColor: "primary.light",
            padding: "0.2rem 0.5rem",
          }}
        >
          <Typography>
            {calculatorItem?.cost_calculator__process__name}
          </Typography>
        </TableCell>}
      </TableRow>
      <TableRow key={calculatorItem?.id}></TableRow>
    </>
  );
}

function CalculatorSequenceModal({ isOpen, onCloseModal, data }: any) {
  const { projectId, projectname, categoryId } = useRouteParams<any>();
  const [calculators, setCalculators] = React.useState([]);
  const [loading, setLoading] = React.useState(false);


  

  const flattenCalculators = (data: any[]) => {
    return data?.flatMap((commodityItem:any) => 
      Object.values(commodityItem.data)?.flat()?.map((calculator:any) => ({
        ...calculator,
        cost_calculator__commodity_mapping__commodity__name: commodityItem?.commodity,
        cost_calculator__process__name: commodityItem?.process || null // Assuming 'process' is a field in your top-level objects
      }))
    )?.filter((calculator:any) => calculator?.calculator_ref_name); // Filter out any undefined or null values
  };
  

  const getCalculators = () => {
    setLoading(true);
    API.get(
      `config/change_sequence_in_configuration/`,
      {get_process_cost_models: data?.type === "process" ? true : null,
        commodity:data?.type === "process" ? data?.commodity_id : null,
        calc_type: data?.type === "process" ? data?.calc_category : null,
        process:data?.type === "process" ? data?.id : null,
        estimation_id:
          data?.type === "process" || data?.name === "Bought-Out Part" || (data?.type ===
                            "calculator_category" && data?.name === "Process")
            ? null
            : data?.id,
        category_id:
           (data?.name === "Bought-Out Part" || (data?.type ===
            "calculator_category" && data?.name === "Process") || data?.type === "process")
            ? categoryId
            : null,
        get_process:  (data?.type ===
            "calculator_category" && data?.name === "Process") ? true : null,
        get_bop: data?.name === "Bought-Out Part" ? true : null,
      },
      0
    )
      .then((res: any) => {
        // if (data?.type === "process") {
        //     const setFlattenedCalculators = (data: any[]) => {
        //         const flattened:any = flattenCalculators(data);
        //         setCalculators(flattened);
        //       };
              
        //       // Call this function with your data
        //       setFlattenedCalculators(res.data);
              
        // } else {
            setCalculators(res.data);
        // }
       
      })
      .catch((err: any) => {
        console.error(err);
      })
      .finally(() => setLoading(false));
  };

  React.useEffect(() => {
    if (isOpen) {
      getCalculators(); // Fetch calculators only when modal opens
    }
  }, [isOpen]);

  

  // calculators?.map((comodity:any)=> comodity?.data?.map((process:any)=> ))

//   const dataa = [
//     {
//       commodity: "Assembly",
//       data: {
//         Labour: [
//           {
//             alias: "a",
//             calculator_ref_name: "Assembly",
//             id: 7831,
//             is_mirror: false,
//             sequence: 1,
//           },
//           {
//             alias: "b",
//             calculator_ref_name: "EL Lug Manual Press Fit",
//             id: 7832,
//             is_mirror: false,
//             sequence: 2,
//           },
//         ],
//         Machine: [],
//         Setup: [],

//         Tool: [
//           {
//             alias: "a",
//             calculator_ref_name: "Tool cost calculator",
//             id: 7837,
//             is_mirror: false,
//             sequence: 1,
//           },
//           {
//             alias: "b",
//             calculator_ref_name: "EL Lug Manual Press Fit",
//             id: 7832,
//             is_mirror: false,
//             sequence: 2,
//           },
//         ],
//       },
//     },
//     {
//       commodity: "Casting",
//       data: {
//         Labour: [],
//         Machine: [],
//         Tool: [],

//         Setup: [
//           {
//             alias: "a",
//             calculator_ref_name: "Ingots Melting_Set UP",
//             id: 7845,
//             is_mirror: false,
//             sequence: 1,
//           },
//         ],
//       },
//     },
//   ];

console.log(calculators);
const [selectedCalculators, setSelectedCalculators] = React.useState<any>([]);
const handleSelectCalculator = (calculator:any) => {
    setSelectedCalculators((prev:any) => [...prev, calculator]);
  };





  return (
    <Modal
      open={isOpen}
      onClose={onCloseModal}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: { timeout: 500 },
      }}
    >
      <Box sx={style}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h6" fontWeight="600">
            Change Sequence of  {(data?.type ===
            "calculator_category" && data?.name === "Process") ? "Processes" : "Calculators"}
          </Typography>
          <IconButton title="Close" onClick={onCloseModal}>
            <CancelIcon />
          </IconButton>
        </Box>
        <Divider />
        <Box
          sx={{
            height: { lg: "51vh", xl: "52vh" },
          }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3"
        >



        
          <TableContainer sx={{ height: "100%" }}>
            <Table
              stickyHeader
              aria-label="sticky table"
              sx={{ width: "100%" }}
            >
              <TableHead
                sx={{
                  position: "sticky",
                  top: "0",
                  backgroundColor: "white",
                  zIndex: "1",
                }}
              >
                <TableRow>
                  <TableCell
                    sx={{
                      cursor: "pointer",
                      fontSize: "1rem",
                      borderBottomColor: "primary.light",
                      padding: "0.2rem 0.5rem",
                      textAlign: "left",
                      lineHeight: "1.2",
                      backgroundColor: "primary.light",
                      width: "14rem",
                    }}
                    rowSpan={2}
                  >
                    Sequence
                  </TableCell>
                  <TableCell
                    sx={{
                      cursor: "pointer",
                      fontSize: "1rem",
                      borderBottomColor: "primary.light",
                      padding: "0.2rem 0.5rem",
                      backgroundColor: "primary.light",
                    }}
                  >
                    
                    {(data?.type ===
            "calculator_category" && data?.name === "Process") ? "Process" : "Calculator"} Name{" "}
                  </TableCell>
                 {(!(data?.type ===
            "calculator_category" && data?.name === "Process") && data?.type !== "process") &&  <TableCell
                    sx={{
                      cursor: "pointer",
                      fontSize: "1rem",
                      borderBottomColor: "primary.light",
                      padding: "0.2rem 0.5rem",
                      backgroundColor: "primary.light",
                    }}
                  >
                    Comodity
                  </TableCell>}
                 {(!(data?.type ===
            "calculator_category" && data?.name === "Process") && data?.type !== "process") && <TableCell
                    sx={{
                      cursor: "pointer",
                      fontSize: "1rem",
                      borderBottomColor: "primary.light",
                      padding: "0.2rem 0.5rem",
                      backgroundColor: "primary.light",
                    }}
                  >
                    Process
                  </TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {loading
                  ? Array.from(Array(3)).map((_, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          <Skeleton variant="text" width="100px" />
                        </TableCell>
                        <TableCell>
                          <Skeleton variant="text" width="200px" />
                        </TableCell>
                      </TableRow>
                    ))
                  : calculators?.map((row: any) => (
                      <CalculatorRow
                        key={row.id}
                        data={data}
                        calculatorItem={row}
                        calculators={calculators}
                        setCalculators={setCalculators}
                        getCalculators={getCalculators}
                      />
                    ))}
              </TableBody>
            </Table>
          </TableContainer>

















        </Box>
      </Box>
    </Modal>
  );
}

export default CalculatorSequenceModal;
