import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid";
import SaveAltIcon from "@mui/icons-material/SaveAlt";
import GetAppIcon from "@mui/icons-material/GetApp";
//@ts-ignore
import { CSVLink, CSVDownload } from "react-csv";

import { ButtonProps } from "@mui/material/Button";
import { useEffect, useState } from "react";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { API } from "../../api-services";
import { isUndefined } from "lodash";
import { useHistory, useRouteMatch } from "react-router";
import { BorderBottom } from "@mui/icons-material";
export interface ICostingProductLevelTableProps {
  productLevelData: any;
  count: any;
}

export default function CostingProductLevelTable(
  props: ICostingProductLevelTableProps
) {
  const { productLevelData, count } = props;
  const { projectId, topVault, vault, treeabbr, leafNode,scenarioId } = useRouteParams<any>();
  const {url}=useRouteMatch()
  const [productLevelDataCost,setProductLevelDataCost]=useState<any>([])
  const history = useHistory();
  const csvOptions: GridCsvExportOptions = {
    fileName: "Idea Approve Table In CSV",
    delimiter: ",",
    utf8WithBom: true
  };
  useEffect(()=>{
    API.get("/customer/system_cost/",{top_vault:topVault,get_system_top_vault_data:true,scenario: url.includes("scenario") ? scenarioId : undefined,}).then((res:any)=>{
      setProductLevelDataCost(res.data)
    }).catch((err: any) => {
      setProductLevelDataCost([]);
    })
  },[topVault,scenarioId])
  let rows: any = [];
  // .toLocaleString("en-US");
  let rowGenerator =
    productLevelData &&
    productLevelData?.map((item: any, index: any) => {
      let tempObj = {
        id: item?.id,
        name: item?.name,
        cost: String(item?.cost?.toLocaleString("en-US")),
        weight: item?.weight,
        contribution_percent: item?.contribution_percent,
        count: item?.count,
        abbreviation: item?.abbreviation
      };
      rows.push(tempObj);
    });

  const setRoute = (idd: any, abb: any) => {
    sessionStorage.setItem("node", JSON.stringify([Number(topVault), Number(idd)]));
    history.push(`/view/${projectId}/${topVault}/${idd}/${abb}/costing`)
  }

  const columns = [
    {
      field: "name",
      headerName: "Sub-System",
      headerClassName: "super-app-theme--header",
      minWidth: 220,
      sortable: false,
      flex: 1,
    },
    {
      field: "count",
      headerName: "Part Count",
      headerClassName: "super-app-theme--header",
      headerAlign: "right",
      align: 'right',
      flex: 1,
      type: 'number',
    },
    {
      field: "weight",
      headerName: "Weight (Kg)",
      headerClassName: "super-app-theme--header",
      headerAlign: "right",
      align: 'right',
      flex: 1,
      type: 'number',
    },
    {
      field: "cost",
      headerName: `Cost(${sessionStorage.getItem("currency")})`,
      headerClassName: "super-app-theme--header",
      headerAlign: "right",
      align: 'right',
      flex: 1,
      type: 'number',
    },

    {
      field: "contribution_percent",
      headerName: "% Contribution",
      headerClassName: "super-app-theme--header",
      headerAlign: "right",
      align: 'right',
      flex: 1,
      type: 'number',
    },
  ];
  // const CustomExportButton = (props: ButtonProps) => (
  //   <GridToolbarExportContainer {...props}>
  //     <GridCsvExportMenuItem options={csvOptions} />
  //   </GridToolbarExportContainer>
  // );
  const CustomToolbar = () => (
    <GridToolbarContainer
      style={{
        height: "2rem",
        display: "flex",
        justifyContent: "space-between",
        marginBottom: "0.2rem",
        width: "100%",
      }}
    >
      <Typography style={{ color: "red", fontSize: "12px" }}>
        {/* *Only completed systems data is visible {" "} */}
      </Typography>
      {/* <CustomExportButton />      */}
      <CSVLink
        data={rows}
        filename={"product-details-cost.csv"}
        className="btn btn-primary"
        target="_blank"
        sx={{ textDecoration: 'none !important' }}
      >
        <Box
          sx={{
            color: "#007fff",
            display: "flex",
            alignItems: "center",
            columnGap: "10px",
            // textDecoration: "none",
          }}
        >
          <SaveAltIcon sx={{ fontSize: "1.2rem" }} />
          EXPORT
          {/* <GetAppIcon /> */}
        </Box>
      </CSVLink>
      {/* <CSVDownload data={rows} target="_blank" /> */}
    </GridToolbarContainer>
  );
  return (
    <div>
      <Box>
        <Table>
          <TableHead sx={{ backgroundColor: 'primary.light', }}>
            <TableRow>
              <TableCell sx={{ color: 'primary.main', padding: '0.2rem 0.5rem', fontSize: '12px' }}>{!isUndefined(productLevelDataCost[0]?.name) ? productLevelDataCost[0]?.name : 'loading..'}</TableCell>
              <TableCell sx={{ color: 'primary.main', padding: '0.2rem 0.5rem', fontSize: '12px' }}>{`Parts Count: ${productLevelDataCost[0]?.count ? productLevelDataCost[0]?.count : 'loading..'}`}</TableCell>
              <TableCell sx={{ color: 'primary.main', padding: '0.2rem 0.5rem', fontSize: '12px' }}>{`Weight(Kg): ${!isUndefined(productLevelDataCost[0]?.weight) ? productLevelDataCost[0]?.weight : 'loading..'}`}</TableCell>
              <TableCell sx={{ color: 'primary.main', padding: '0.2rem 0.5rem', fontSize: '12px' }}>{`Total Cost(${sessionStorage.getItem("currency")}): ${!isUndefined(productLevelDataCost[0]?.cost) ? productLevelDataCost[0]?.cost : 'loading..'}`}</TableCell>
              <TableCell sx={{ color: 'primary.main', padding: '0.2rem 0.5rem', fontSize: '12px' }}>{`Contribution: ${!isUndefined(productLevelDataCost[0]?.contribution_percent) ? productLevelDataCost[0]?.contribution_percent : 'loading..'}%`}</TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </Box>
      <Box
        adl-scrollbar="true"
        adl-scrollbar-width="0.3"
        sx={{
          height: { xs: 630, sm: 630, md: 630, lg: "72vh", xl: "79.5vh" },
          overflowY: "auto",
          "& ::-webkit-scrollbar": {
            width: "0.3rem",
            height: "0.3rem",
          },

          "& ::-webkit-scrollbar-thumb": {
            // background: $thumb-color;
            backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
            // borderRadius: "10px",
          },

          "& ::- webkit-scrollbar-thumb: hover ": {
            // width: "4px",
            backgroundColor: "#045DE9 !important",
          },
          "::-webkit-scrollbar-track": {
            backgroundColor: "#e1e1f3 !important",
          },
          width: "100%",
          "& .nowrap-cells": {
            whiteSpace: "normal !important",
          },
          // "& .super-app-theme--header": {
          //   backgroundColor: "primary.main",
          //   color:'white',
          // },
          " .MuiDataGrid-cell --textRight": {
            textAlign: "right",
          },
          "& .MuiDataGrid-columnHeaderTitle": {
            color: '#fff !important',
          },
          "& .MuiDataGrid-columnHeaders.MuiDataGrid-columnHeaders": {
            backgroundColor: "primary.main",
          },
          "& .super-app-theme--product": {
            // color: "#0021f3 !important",
            // #f7d8d9
            backgroundColor: "primary.light",
            color: "primary.main",
            // bgcolor: (theme) => getBackgroundColor("#d32f2f", theme.palette.mode),
            "&:hover": {
              // "#FF6962 !important",
              backgroundColor: "primary.light",
              color: "primary.main",
            },
          },
        }}
      >
        <DataGrid
          headerHeight={48}
          rowHeight={36}
          {...{
            columns: columns,
            rows: rows,
          }}
          //   initialState={{
          //     sorting: {
          //       sortModel: [{ field: "name", sortable: false }],
          //     },
          //   }}
          isRowSelectable={() => false}
          density="compact"
          //@ts-ignore
          hideFooter="true"
          components={{
            Toolbar: () => {
              return CustomToolbar();
            },
          }}
          onCellClick={(params, event) => {
            event.defaultMuiPrevented = true;
            if (params?.field == "name") {
              setRoute(
                params?.row?.id,
                params?.row?.abbreviation

              );
            }
          }}
          // rowsPerPageOptions={[16]}
          sx={{
            fontSize: "12px",
            boxShadow: 2,
            border: 2,
            cursor: "pointer",
            "&.MuiDataGrid-root .MuiDataGrid-cell": {
              textTransform: 'capitalize',
            },
            ".MuiDataGrid-cell": {
              borderBottomColor: 'primary.light',
            },
          }}
          getRowClassName={(params) =>
            `super-app-theme--${params.row.abbreviation}`
          }
        />
      </Box>
    </div>
  );
}
