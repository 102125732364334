import React, { useEffect, useRef, useState } from "react";
import { Badge, Box, Button, Checkbox, Chip, IconButton, Skeleton, Table, TextField, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import styles from "./FeatureNamesLibraryLandingPage.module.scss";
import Drawer from "@mui/material/Drawer";
import InfoIcon from "@mui/icons-material/Info";
import Divider from "@mui/material/Divider";
import AddIcon from "@mui/icons-material/Add";
import { API } from "../../api-services";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import { useBaseModal } from "../../ComponentUI/SearchPage/useBaseModal";
import deletefill from "../../Assets/images/deletefill.svg";
import DataBaseDrawer from "../DataBaseDrawer";
import FeatureNamesTable from "./FeatureNamesTable";
import InsertLinkIcon from "@mui/icons-material/InsertLink";
import AddFeatureNameModal from "./AddFeatureNameModal";
import AddFeatureProjectCategoryModal from "./AddFeatureProjectCategoryModal";
import swal from "sweetalert";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import FeatureNameTab from "./FeatureNameTab";
import { relative } from "path";

type Anchor = "top" | "left" | "bottom" | "right";
type Props = {};

const FeatureNamesLibraryLandingPage = (props: Props) => {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { categoryId, subsystemId, search } = useParams<any>();

  const [ParentsCounter, setParentsCounter] = useState<any>(0);
  const [TableCounter, setTableCounter] = useState<any>(0);
  const [Loader, setLoader] = useState<any>(false);
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [RecyclebinCounter, setRecyclebinCounter] = useState<any>(0);

  const [CotegoryData, setCategoryData] = useState<any>([]);
  const parameterModal = useBaseModal();
  const AddsystemNameModal = useBaseModal();
  const AddsystemNameEditModal = useBaseModal();
  const AddAEditCategoryModal = useBaseModal();
  const [recyclebinCount, setRecyclebinCount] = useState<any>(0);

  const [Counter, setCounter] = useState<any>(0);
  const [useraction, setuseraction] = useState<any>(null)

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [SystemData, setSystemData] = useState<any>([]);
  const [EditData, setEditData] = useState<any>();
  const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === "keydown" && ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const handleClick = () => {
    history.push("/internallanding");
  };

  const subystemEditHanlder = (item: any) => {
    setEditData(item);
    AddsystemNameEditModal.open();
  };
  const SubsystemHanlder = (id: any) => {
    history.push(`/data-base/feature-names-database/${categoryId}/${id}/0/0`);
  };

  useEffect(() => {

    API.get("/auth/mui_trash_view/", {
      bin_type: "featurenamedatabase",
      count: true,
      is_oem: false
    }).then((res: any) => {
      setRecyclebinCount(res?.data);
    });
  }, [RecyclebinCounter]);

  useEffect(() => {
    if (categoryId !== "0") {
      if (ParentsCounter == 0) {
        setLoader(true);
        API.get(
          "/api/db/sub_system/",
          {
            vehicle_types: categoryId,
            feature_library: true
          },
          0
        )
          .then((res: any) => {
            setSystemData(res.data);
            setLoader(false);
          })
          .catch((err: any) => {
            setLoader(false);
          });
      } else {
        API.get(
          "/api/db/sub_system/",
          {
            vehicle_types: categoryId,
            feature_library: true
          },
          0
        )
          .then((res: any) => {
            setSystemData(res.data);
          })
          .catch((err: any) => { });
      }
    }
  }, [ParentsCounter, Counter]);

  useEffect(() => {
    API.get("/api/db/vehicle_type/")
      .then((res: any) => {
        setCategoryData(res?.data);
      })
      .catch((err: any) => {
        console.log("Srver Error");
      });
  }, []);

  React.useEffect(() => {
    API.get(
      "/auth/calibration_user_permission/",
      {
        action: true,
        database: true,
        module: "DB-Systems & Names"
      },
      0
    )
      .then((res: any) => {
        setuseraction(res.data.action)
        // sessionStorage.setItem("DBPermission", res.data.action);
      })
      .catch((err: any) => { });
  }, []);

  const AddSubsystemName = () => {
    AddsystemNameModal.open();
  };
  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
    // onClick={toggleDrawer(anchor, false)}
    // onKeyDown={toggleDrawer(anchor, false)}
    >
      <DataBaseDrawer />
    </Box>
  );


  const CategoryModalHandler = (e: any, id: any) => {
    e.stopPropagation();
    if (sessionStorage.getItem("DBPermission")?.includes("U") || sessionStorage.getItem("DBPermission")?.includes("C")) {
      AddAEditCategoryModal.open();
    }
  };

  const CategoryHandler = (e: any, id: any) => {
    setLoader(true);
    API.get(
      "/api/db/sub_system/",
      {
        vehicle_types: id,
      },
      0
    )
      .then((res: any) => {
        setSystemData(res.data);
        history.push(`/data-base/feature-names-database/${id}/${res.data && res.data[0]?.id}/0/0`);
        setLoader(false);
      })
      .catch((err: any) => {
        setLoader(false);
      });
  };

  const RecycleBinHanlder = () => {
    history.push("/data-base/FeatureNameRecycleBin");
  };

  const deleteHanlder = (id: any) => {
    swal({
      text: "Are you sure you want to delete ?",
      buttons: ["No", "Yes"],
    }).then((confirm) => {
      if (confirm) {
        API.delete(
          `/api/db/sub_system/${id}/`,

          0
        )
          .then((res: any) => {
            setRecyclebinCounter((prev: any) => prev + 1);
            setCounter((prev: any) => prev + 1);
            history.push(`/data-base/feature-names-database/${categoryId}/0/0/0`);
          })
          .catch((err: any) => { });
      }
    });
  };
  return (
    <>
      <div>
        <AddFeatureNameModal
          isOpen={AddsystemNameModal.isOpen}
          onCloseHanlder={AddsystemNameModal.close}
          setCounter={setCounter}
          modalType={"Add"}
        />
        {AddAEditCategoryModal.isOpen && (
          <AddFeatureProjectCategoryModal
            useraction={useraction}
            isOpen={AddAEditCategoryModal.isOpen}
            onCloseHanlder={AddAEditCategoryModal.close}
          />
        )}
        {AddsystemNameEditModal.isOpen && (
          <AddFeatureNameModal
            isOpen={AddsystemNameEditModal.isOpen}
            onCloseHanlder={AddsystemNameEditModal.close}
            setCounter={setCounter}
            modalType={"Edit"}
            SubsystemData={EditData}
          />
        )}
        <Box sx={{ margin: "0 0.5rem", width: "100%" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              width: "100%",
            }}>
            <Box sx={{ display: "flex", alignItems: "center", columnGap: "1rem", width: "18vw" }}>
              <IconButton
                sx={{ cursor: "pointer" }}
                title="Go Back"
                onClick={handleClick}>
                <ArrowBackIcon
                  color="primary"
                  titleAccess="Go Back"
                />
              </IconButton>
              <Typography
                sx={{
                  fontSize: "1rem",
                  whiteSpace: "nowrap",
                  // fontWeight: "bold",
                }}>
                Database (Feature Names)
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", columnGap: "1rem" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: { lg: "77vw", xl: "78vw" },
                }}>
                <Box
                  sx={{ display: "flex", alignItems: "center", columnGap: "1rem", width: "100%", padding: "0.2rem 0.5rem" }}
                  adl-scrollbar="true"
                  adl-scrollbar-width="0.3">
                  {CotegoryData &&
                    CotegoryData?.map((item: any, index: any) => {
                      return (
                        <>
                          <Box
                            onClick={(e: any) => CategoryHandler(e, item?.id)}
                            sx={{
                              borderRadius: "2rem",
                              border: "1px solid",
                              display: "flex",
                              columnGap: "0.5rem",
                              alignItems: "center",
                              cursor: "pointer",
                              padding: "0.5rem",
                              backgroundColor: categoryId == item?.id ? "#007fff" : "white",
                              color: categoryId == item?.id ? "white" : "#007fff",
                              fontSize: "1rem",
                              paddingLeft: "0.5rem",
                              "&:hover": {
                                transform: "Scale(1.05)",
                                transition: "transform 0.5s ease",
                              },
                            }}>
                            <Typography sx={{ cursor: "pointer", whiteSpace: "nowrap", fontSize: "1rem" }}>{item?.name}</Typography>

                          </Box>
                        </>
                      );
                    })}
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "1rem",
                }}>
                <Box sx={{ width: "2.5rem", textAlign: "center" }}>
                  {recyclebinCount && recyclebinCount?.count > 0 ? (
                    <Badge
                      color="error"
                      badgeContent={recyclebinCount && recyclebinCount?.count}>
                      <img
                        title="Recycle bin"
                        className={styles.internalIcons}
                        src={deletefill}

                        style={{

                          width: "auto",
                          height: "1.6rem",
                          margin: "0px -2px 0 0",
                          verticalAlign: "middle",
                          cursor: "pointer",
                        }}
                        onClick={() => RecycleBinHanlder()}
                      />
                    </Badge>
                  ) : (
                    <DeleteOutlineIcon titleAccess="Recycle bin " sx={{ fontSize: "2rem" }} />
                  )}
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
        <Box sx={{ display: "flex", width: "100%", height: { lg: "86vh", xl: "90vh" } }}>
          <Box className={styles.expandIconBox}>
            <div className={styles.iconSec}>
              {(["left"] as const).map((anchor) => (
                <React.Fragment key={anchor}>
                  <IconButton
                    size="medium"
                    sx={{
                      backgroundColor: "primary.light",
                      color: "primary.main",
                    }}
                    onClick={toggleDrawer(anchor, true)}>
                    <AddIcon sx={{ fontSize: "1.7rem" }} />
                  </IconButton>
                  <Drawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                    className={styles.drawerContainer}
                    PaperProps={{
                      sx: {
                        backgroundColor: "aliceblue",
                      },
                    }}>
                    <Box
                      sx={{ padding: "1rem 0rem", paddingBottom: "0" }}
                      adl-scrollbar="true"
                      adl-scrollbar-width="0.3">
                      <Typography
                        style={{
                          width: "100%",
                          textAlign: "center",
                          fontSize: "1rem",
                        }}>
                        Select Module
                      </Typography>
                      <Divider sx={{ margin: "0.5rem" }} />
                      {list(anchor)}
                    </Box>
                  </Drawer>
                  <Typography
                    style={{
                      transform: "rotate(270deg)",
                      whiteSpace: "nowrap",
                      marginTop: "40vh",
                      cursor: "pointer",
                      fontSize: "1rem",
                    }}
                    onClick={toggleDrawer(anchor, true)}>
                    Select Module
                  </Typography>
                </React.Fragment>
              ))}
            </div>
          </Box>
          <Box sx={{ width: "100%", height: { lg: "86vh", xl: "90vh" } }}>
            {Loader ? (
              <Box sx={{ width: "100%", height: { lg: "80vh", xl: "87vh" }, display: "flex" }}>
                <Box sx={{ width: "17%", padding: "1rem" }}>
                  <Skeleton
                    variant="rectangular"
                    sx={{ width: "100%", marginBottom: "1rem", borderRadius: "10px" }}
                    height={70}
                  />
                  <Skeleton
                    variant="rectangular"
                    sx={{ width: "100%", marginBottom: "1rem", borderRadius: "10px" }}
                    height={70}
                  />
                  <Skeleton
                    variant="rectangular"
                    sx={{ width: "100%", marginBottom: "1rem", borderRadius: "10px" }}
                    height={70}
                  />
                  <Skeleton
                    variant="rectangular"
                    sx={{ width: "100%", marginBottom: "1rem", borderRadius: "10px" }}
                    height={70}
                  />
                  <Skeleton
                    variant="rectangular"
                    sx={{ width: "100%", marginBottom: "1rem", borderRadius: "10px" }}
                    height={70}
                  />
                  <Skeleton
                    variant="rectangular"
                    sx={{ width: "100%", marginBottom: "1rem", borderRadius: "10px" }}
                    height={70}
                  />
                  <Skeleton
                    variant="rectangular"
                    sx={{ width: "100%", marginBottom: "1rem", borderRadius: "10px" }}
                    height={70}
                  />
                  <Skeleton
                    variant="rectangular"
                    sx={{ width: "100%", marginBottom: "1rem", borderRadius: "10px" }}
                    height={70}
                  />
                  <Skeleton
                    variant="rectangular"
                    sx={{ width: "100%", marginBottom: "1rem", borderRadius: "10px" }}
                    height={70}
                  />
                  <Skeleton
                    variant="rectangular"
                    sx={{ width: "100%", marginBottom: "1rem", borderRadius: "10px" }}
                    height={70}
                  />
                </Box>
                <Box
                  sx={{
                    height: "100%",
                    width: "100%",
                    padding: "0 1rem",
                  }}>
                  <Skeleton
                    height={"3rem"}
                    animation="wave"
                  />
                  <Skeleton
                    height={"3rem"}
                    animation={false}
                  />
                  <Skeleton
                    height={"3rem"}
                    animation="wave"
                  />
                  <Skeleton
                    height={"3rem"}
                    animation={false}
                  />
                  <Skeleton
                    height={"3rem"}
                    animation="wave"
                  />
                  <Skeleton
                    height={"3rem"}
                    animation={false}
                  />
                  <Skeleton
                    height={"3rem"}
                    animation="wave"
                  />
                  <Skeleton
                    height={"3rem"}
                    animation={false}
                  />
                  <Skeleton
                    height={"3rem"}
                    animation="wave"
                  />
                  <Skeleton
                    height={"3rem"}
                    animation={false}
                  />
                  <Skeleton
                    height={"3rem"}
                    animation="wave"
                  />
                  <Skeleton
                    height={"3rem"}
                    animation={false}
                  />
                  <Skeleton
                    height={"3rem"}
                    animation="wave"
                  />
                  <Skeleton
                    height={"3rem"}
                    animation={false}
                  />
                  <Skeleton
                    height={"3rem"}
                    animation="wave"
                  />
                  <Skeleton
                    height={"3rem"}
                    animation={false}
                  />
                  <Skeleton
                    height={"3rem"}
                    animation="wave"
                  />
                  <Skeleton
                    height={"3rem"}
                    animation={false}
                  />
                  <Skeleton
                    height={"3rem"}
                    animation="wave"
                  />
                  <Skeleton
                    height={"3rem"}
                    animation={false}
                  />
                </Box>
              </Box>
            ) : (
              <Box>
                {/* { <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "16vw",
                    padding: "0 1rem 0.5rem",
                  }}>
                  <Button
                    size="small"
                    onClick={() => AddSubsystemName()}
                    sx={{
                      cursor: "pointer",
                      padding: "0.2rem 0.5rem",
                      "&:hover": {
                        transform: "Scale(1.1)",
                        transition: "transform 0.5s ease",
                      },
                    }}
                    startIcon={<AddIcon sx={{ marginRight: "-0.5rem" }} />}
                    disabled={!sessionStorage.getItem("DBPermission")?.includes("C")}
                    variant="contained">
                    ADD System
                  </Button>
                </Box>} */}
                <Box sx={{ width: "100%" }}>
                  {SystemData && SystemData.length > 0 ? (
                    <Box sx={{ width: "100%", paddingRight: "1rem", marginTop: "1rem", display: "flex", height: { lg: "81vh", xl: "87vh" }, alignItems: "flex-start", gap: '1rem' }}>
                      <FeatureNameTab
                        ParentsCounter={ParentsCounter}
                        SystemData={SystemData}
                        SubsystemHanlder={SubsystemHanlder}
                        subystemEditHanlder={subystemEditHanlder}
                        deleteHanlder={deleteHanlder}
                      />
                      <FeatureNamesTable
                        useraction={useraction}
                        setRecyclebinCounter={setRecyclebinCounter}
                        setParentsCounter={setParentsCounter}
                      />
                    </Box>
                  ) : (
                    <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", width: "100%", height: "89vh" }}>
                      <p>No Data</p>
                    </Box>
                  )}
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </div>
    </>
  );
};
export default FeatureNamesLibraryLandingPage;
