import React, { useEffect, useRef, useState } from "react";
import { usePdf } from "@mikecousins/react-pdf";
import { Box, Button, Checkbox, Hidden, Modal, Skeleton, Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel"; import { API } from "../../api-services";
import { useParams } from "react-router-dom";
import milestoneFileUpload from "./Milestone";
import DownloadIcon from '@mui/icons-material/Download';
;
// import { API } from "../../api-services";

const label = { inputProps: { "aria-label": "Checkbox demo" } };
interface ProposalDocumentModalProps {
  isOpen: any;
  onCloseModal: any;
  milestone: any;
  documentDataGet: any;
  setGetdocumentDataAPICall: any;
  getdocumentDataAPICall: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  height: "96vh",
  width: "auto",
  maxWidth: '80vw',
  // minWidth: 'auto',
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: '10px',
  // border: "2px solid #000",
  // boxShadow: 24,
  p: 1,
};

interface Params {
  projectId: any;
}

const ProposalDocumentModal = (props: ProposalDocumentModalProps) => {
  const { projectId } = useParams<Params>();
  const { isOpen, onCloseModal, milestone, documentDataGet, setGetdocumentDataAPICall, getdocumentDataAPICall } = props;
  const [loader, setLoader] = useState<boolean>(true);
  const [documentData, setDocumentData,] = useState<any>(documentDataGet);
  // Dont delete below states
  const [dummy, setDummy] = useState<boolean>(true);
  const [dummyData, setDummyData] = useState<any>();

  const inputElRef = useRef<any>(null);

  const canvasRef = useRef<HTMLCanvasElement>(null);
  const onDocumentLoadSuccess = (data: any) => {
    setLoader(false);
  };

  const onDocumentLoadFail = () => {
    setLoader(true);
  };


  useEffect(() => {
    if (isOpen == true) {
      setDocumentData(documentDataGet);
      setLoader(true);
    } else {
      setDocumentData("");
    }
  }, [isOpen, documentDataGet]);


  const { pdfDocument, pdfPage } = usePdf({
    file:
      documentData && documentData != undefined
        ? documentData
        : "https://arxiv.org/pdf/quant-ph/0410100.pdf",
    canvasRef,
    onDocumentLoadFail,
    onDocumentLoadSuccess,
  });

  const ClosedHandler = () => {
    setLoader(true);
    setDocumentData(undefined);
    onCloseModal();
  };

  const browseFiles = (ed: any) => {
    return inputElRef.current?.click?.();
  };

  const handleUploadPDF = async (e: React.ChangeEvent<HTMLInputElement>) => {
    setLoader(true);
    const file: any = e.target.files;
    await milestoneFileUpload(file, projectId, "milestone", setDummy, setDummyData, setGetdocumentDataAPICall, getdocumentDataAPICall);
  };

  const downloadProposalDocument: any = (() => {
    API.get(`xcpep/proposal_document_view`, { id: projectId }, 0)
      .then((res: any) => {
        fetch(res?.data, {
          method: 'GET'
        }).then(resp => resp.blob())
          .then(blob => {
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.style.display = 'none';
            a.href = url;
            a.download = `Proposal_Document-${milestone && milestone[0]?.project_name}`; // the filename you want
            document.body.appendChild(a);
            a.click();
            window.URL.revokeObjectURL(url);
          })
      })
      .catch((err: any) => {
        console.log(err, "error");
      });
  })

  return (
    <div>
      <Modal
        open={isOpen}
        // onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <input
            accept=".pdf"
            id="profilePhoto"
            type="file"
            style={{ display: "none" }}
            onChange={handleUploadPDF}
            ref={inputElRef}
          />
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', padding: '0 0.5rem' }}>
            <Box sx={{ fontWeight: '500' }}>Proposal Document ({milestone && milestone[0]?.project_name})</Box>
            <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
              <Button size="small" sx={{
                cursor: "pointer", color: '#007fff', marginRight: '0.3rem',
                "&:hover": {
                  transform: 'Scale(1.1)',
                  transition: 'transform 0.5s ease',
                }
              }} onClick={downloadProposalDocument}>Download <DownloadIcon sx={{ fontSize: '1.5rem' }} /></Button>
              <Button size="small" sx={{
                "&:hover": {
                  transform: 'Scale(1.1)',
                  transition: 'transform 0.5s ease',
                }
              }} onClick={browseFiles}>Replace</Button>
              <CancelIcon
                sx={{ cursor: "pointer", }}
                onClick={() => ClosedHandler()}
              />
            </Box>
          </Box>
          <Box
            sx={{
              textAlign: "center",
              height: { lg: '87vh', xl: "88vh" },
              minWidth: '35vw',
            }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3"
          >
            <a
              style={
                loader ? { pointerEvents: "none" } : { pointerEvents: "auto" }
              }
            >
              {loader ? (
                <span
                  style={{
                    padding: "0 1rem",
                    height: "80vh",
                    width: "30vw",
                    // display: "flex",
                  }}
                >
                  <Skeleton
                    variant="rectangular"
                    width="95%"
                    height="90%"
                    sx={{ padding: "0 1rem", margin: "0 auto" }}
                  />
                </span>
              ) : (
                <canvas
                  ref={canvasRef}
                  // id= {isExpand==true ?"canvasexpand" : "canvas"}
                  // title={fileName}
                  // width="auto"
                  // height="20"
                  width="100" height="400"
                  style={{ width: "100%", height: 'auto' }}
                />
              )}
            </a>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default ProposalDocumentModal;
