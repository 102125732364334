import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { TreeItemProps, treeItemClasses } from "@mui/lab/TreeItem";
import Collapse from "@mui/material/Collapse";
// web.cjs is required for IE11 support

import { TransitionProps } from "@mui/material/transitions";
import { animated, useSpring } from "@react-spring/web";

// src/Components/Assets/images/svgs/openfolderblue.svg
// openfolderblue.svg
import openFolder from "../../Assets/images/svgs/openfolderblue.svg";
import FolderIcon from "@mui/icons-material/Folder";
import styles from "../../ViewModule/Comparefolder/CompareTreeViewcomponents.module.scss";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import calculator from "../../Assets/images/calculator.svg";

// import { API } from "../../api-services";
import { Box, Typography } from "@mui/material";
import { ControlledMenu, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import AddProcessModal from "./AddProcessModal";
import DataSaverOnIcon from "@mui/icons-material/DataSaverOn";
import DeleteIcon from "@mui/icons-material/Delete";
import ContentPasteGoIcon from '@mui/icons-material/ContentPasteGo';
import FileCopyIcon from "@mui/icons-material/FileCopy";
import InfoIcon from "@mui/icons-material/Info";
import { API } from "../../api-services";
import InfoTreeModal from "./InfoTreeModal";
import { useBaseModal } from "../../ComponentUI/SearchPage/useBaseModal";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import Swal from "sweetalert2";

function TransitionComponent(props: TransitionProps) {
    const style = useSpring({
        from: {
            opacity: 0,
            transform: "translate3d(20px,0,0)",
            display: "none",
        },
        to: {
            opacity: props.in ? 1 : 0,
            transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
            display: "block",
        },
    });

    return (
        <animated.div style={style} className="Tempdekho">
            <Collapse {...props} />
        </animated.div>
    );
}

const StyledTreeItem = styled((props: TreeItemProps) => (
    <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
    [`& .${treeItemClasses.iconContainer}`]: {
        "& .close": {
            opacity: 0.3,
        },
    },
    [`& .${treeItemClasses.group}`]: {
        marginLeft: 10,
        paddingLeft: 8,
        borderLeft: `1px dashed ${theme.palette.text.primary}`,
    },
}));

interface CostingTreeViewcomponentsProps {
    HierarchyData: any;
    onSelectCallback: any;
    setSelectedTreeData?: any;
    setCounterApiCall?: any;
    sourceCalc?: any;
    parentCloseModal?: any;
}
const CopyCalculatorTree = (
    props: CostingTreeViewcomponentsProps
) => {
    const {
        HierarchyData,
        onSelectCallback,
        setSelectedTreeData,
        setCounterApiCall,
        sourceCalc,
        parentCloseModal
    } = props;
    const { selectedIdTree, projectname, projectId } = useRouteParams<any>();
    const infoModal = useBaseModal();
    const costModelPaste = useBaseParamsModal();
    const history = useHistory();
    const { url } = useRouteMatch();
    const [activeId, setActiveId] = React.useState<any>(null);
    const [HierarchyAllData, setHierarchyAllData] = React.useState<any>(null);
    const [loader, setLoader] = React.useState<any>(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [isOpen, setOpen] = useState<any>(false);
    const [selectedCommodity, setSelectedCommodity] = React.useState<any>(null);
    const [rightClickedId, setRightClickedId] = React.useState<any>("");
    const [pasteData,setPasteData]=React.useState<any>({})
    const [anchorPoint, setAnchorPoint] = useState<any>({ x: 0, y: 0 });
    const open = Boolean(anchorEl);

    // @ts-ignore
    const [expanded, setExpanded] = React.useState<any>([
        parseInt(selectedIdTree),
    ]);
    const [selected, setSelected] = React.useState<any>(selectedIdTree);

    const addProcess = useBaseParamsModal();

    const handleChange = (
        nodeId: any,
        id: any,
        HierarchyAllData: any,
        itemId: any
    ) => {
        onSelectCallback(nodeId, id, HierarchyAllData, itemId);
        if (HierarchyAllData && HierarchyAllData?.type === "sub_calculator_cat") {
            setSelectedTreeData(HierarchyAllData);
        } else {
            setSelectedTreeData({});
        }
        let a: any = sessionStorage.getItem("CopyCostingModels");
        let val: any = JSON.parse(a) || [];
        if (val?.includes(id)) {
            let noDuplicate: any = val?.filter((item: any) => {
                return item != id;
            });
            let b: any = sessionStorage.setItem(
                "CopyCostingModels",
                JSON.stringify(noDuplicate)
            );
        } else {
            val?.push(id);
            let b: any = sessionStorage.setItem(
                "CopyCostingModels",
                JSON.stringify(val)
            );
        }

        setActiveId(id);

        const index = expanded.indexOf(id);

        const copyExpanded = [...expanded];
        if (index === -1) {
            copyExpanded?.push(id);
        } else {
            copyExpanded?.splice(index, 1);
        }

        setExpanded(copyExpanded);
        setSelected(copyExpanded);
    };

    useEffect(() => {
        let a: any = sessionStorage.getItem("CopyCostingModels");
        let val: any = JSON.parse(a) || [];
        if (val != null) {
            setExpanded(val);
            setSelected(val);
        }
    }, [selectedIdTree, sessionStorage.getItem("CopyCostingModels")]);
    //@ts-ignore
    const menuItemClassName = ({ hover }) =>
        hover ? "my-menuitem-hover" : "my-menuitem";
    useEffect(() => {
        setHierarchyAllData(HierarchyData && HierarchyData);
    }, [HierarchyData]);

    return (
        <>

            <ControlledMenu
                anchorPoint={anchorPoint}
                state={isOpen ? "open" : "closed"}
                direction="top"
                // align="center"
                position="anchor"
                transition
                // arrow={true}
                menuClassName="my-menu"
                onClose={(e: any) => {
                    // e.stopPropagation()
                    setOpen(false);
                }}
                onClick={(e: any) => {
                    e.stopPropagation();
                }}
            // onSelect={(ed:any)=>{

            // }}
            >

                <Box sx={{ position: "relative", zIndex: 5 }}>

                    <MenuItem
                        style={{ fontSize: '1rem' }}
                        value={"calculator"}
                        // disabled={useraction && useraction?.includes("D")?false:true}
                        className={menuItemClassName}
                        onClick={() => {
                            API.post(
                                "/cost/cost_calculator_copy/",
                                {
                                    commodity_mapping: pasteData.commodity,
                                    process: pasteData.process,
                                    category: pasteData.id,
                                    source_cal:sourceCalc.id
                                },
                                {},
                                0
                            )
                                .then((res: any) => {
                                    parentCloseModal();
                                    
                                    setCounterApiCall((prev: any) => prev + 1);
                                    Swal.fire({
                                        icon: "success",
                                        title: "Copied Successfully",
                                        showConfirmButton: true,
                                    });
                                    //   history.push(
                                    //     `/costingconfiguration/${projectId}/${projectname}/${res.data.id
                                    //     }/${"calculator"}`
                                    //   );
                                })
                                .catch((err: any) => { });
                        }}
                    >
                        <ContentPasteGoIcon sx={{ cursor: "pointer", marginRight: "0.5rem", fontSize: '1.5rem' }} />
                        Paste
                    </MenuItem>


                </Box>
            </ControlledMenu>
            <TreeView
                expanded={expanded}
                key={HierarchyAllData?.sequence_id}
                id={HierarchyAllData?.sequence_id}
                defaultParentIcon={<FolderIcon fill="primary" />}
                className={styles.treetemp}
                defaultCollapseIcon={
                    HierarchyAllData &&
                    HierarchyAllData?.type !== "calculator" && (
                        // HierarchyAllData?.sequence_id == false && (
                        <img src={openFolder} style={{ width: "18px", color: "#007fff" }} />
                    )
                    // )
                }
                defaultExpandIcon={<FolderIcon color="primary" />}
                defaultEndIcon={
                    HierarchyAllData?.type === "calculator" ? (
                        <img src={calculator} style={{ width: "13px", color: "#007fff" }} />
                    ) : (
                        <FolderIcon color="primary" />
                    )
                }
                onNodeSelect={() =>
                    handleChange(
                        HierarchyAllData?.name,
                        HierarchyAllData?.sequence_id,
                        HierarchyAllData,
                        HierarchyAllData?.id
                    )
                }
                selected={selected}
                aria-controls={open ? "basic-menu" : undefined}
                aria-haspopup="true"
                aria-expanded={open ? "true" : undefined}
                sx={{
                    "&.MuiTreeItem-content{}.Mui-selected": {
                        backgroundColor:
                            HierarchyAllData?.id == selectedIdTree
                                ? "#cee6fa !important"
                                : "white !important",
                    },
                }}
            >
                <StyledTreeItem
                    data-index={HierarchyAllData?.sequence_id}
                    nodeId={HierarchyAllData?.sequence_id}
                    label={
                        <Typography className={styles.treeViewData}>
                            <div
                                className={styles.treeViewText}
                                style={HierarchyAllData?.type === "calculator" ? { color: HierarchyAllData?.mapped_status ? "green" : "red" } : { color: "" }}
                                // style={{position:'relative',zIndex:1}}
                                onContextMenu={(e: any) => {
                                    setPasteData(HierarchyAllData)
                                    setRightClickedId(HierarchyAllData.id);
                                    if (HierarchyAllData?.type === "sub_calculator_cat") {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        setAnchorPoint({ x: e.clientX, y: e.clientY });
                                        setOpen(true);
                                    }
                                }}
                            >
                                {HierarchyAllData?.name}{" "}
                                {HierarchyAllData?.calc_count ||
                                    HierarchyAllData?.calc_count === 0
                                    ? `(${HierarchyAllData?.calc_count})`
                                    : ""}
                            </div>
                        </Typography>
                    }
                >
                    {HierarchyAllData &&
                        HierarchyAllData?.data?.map((subsystemData: any) => {
                            return (
                                <>
                                    <CopyCalculatorTree
                                        HierarchyData={subsystemData}
                                        key={subsystemData?.sequence_id}
                                        setSelectedTreeData={setSelectedTreeData}
                                        onSelectCallback={onSelectCallback}
                                        setCounterApiCall={setCounterApiCall}
                                        sourceCalc={sourceCalc}
                                        parentCloseModal={parentCloseModal}
                                    />
                                </>
                            );
                        })}
                </StyledTreeItem>
            </TreeView>
            {addProcess.isOpen && (
                <AddProcessModal
                    isOpen={addProcess.isOpen}
                    onCloseModal={addProcess.close}
                    commodityId={addProcess.propsId}
                    counterApiCall={setCounterApiCall}
                />
            )}
            {infoModal.isOpen && (
                <InfoTreeModal
                    isOpen={infoModal.isOpen}
                    onCloseModal={infoModal.close}
                    rightClickedId={rightClickedId && rightClickedId}
                />
            )}




        </>
    );
};

export default CopyCalculatorTree;
