import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Skeleton,
  Button,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useBaseModal } from "../../ComponentUI/SearchPage/useBaseModal";
import { AssumptionModal } from "./AssumptionModal";
//Acccodian
import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import styles from "./CostingAccordianParent.module.scss";
import { API } from "../../api-services";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import flag from "../../Assets/images/flag.png";
import flagRed from "../../Assets/images/flag_red.png";

import { isUndefined } from "lodash";
import { useHistory, useRouteMatch } from "react-router";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import { InformationModal } from "./InformationModal";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export interface ICostingAccordianParentProps {
  onCloseModal?: any;
  costDetailsData?: any;
  handleChangeCostingStatus?: any;
  parentdata?: any;
  setParentData?: any;
}

export function CostingAccordionParentCreate(
  props: ICostingAccordianParentProps
) {
  const { onCloseModal, costDetailsData, handleChangeCostingStatus,parentdata,setParentData } = props;
  function formatNumber(num: number): string {
    if (num === 0) return "0";

    const formatted = num.toFixed(4); // Fix to 4 decimal places first
    const parts = formatted.split("."); // Split into integer and decimal parts

    if (parts.length === 2 && parts[1].startsWith("000")) {
      return `${parts[0]}.000`;
    }

    return num.toFixed(3); // Otherwise, return fixed to 3 decimal places
  }
  const { url } = useRouteMatch();
  const informationModal = useBaseParamsModal();
  const history = useHistory();
  const {
    vault,
    topVault,
    projectname,
    projectId,
    scenarioId,
    productId,
    abbrebation,
  } = useRouteParams<any>();
  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  const [expandedChildData, setExpandedChildData] = React.useState<
    string | false
  >("panel4");
  const [expandedProcess, setExpandedProcess] = React.useState<string | false>(
    "panel2"
  );
  const [expandedOverhead, setExpandedOverhead] = React.useState<
    string | false
  >("panel3");
  const [expandedSub, setExpandedSub] = React.useState<any>(undefined);
  const [expandedSubProcess, setExpandedSubProcess] =
    React.useState<any>(undefined);
  const [expandedSubOverhead, setExpandedSubOverhead] =
    React.useState<any>(undefined);
  const [subChild, setSubChild] = React.useState<any>(undefined);
  const [subGrandChild, setSubGrandChild] = React.useState<any>(undefined);
  const [greatGrandChild, setGreatGrandChild] = React.useState<any>(undefined);
  const [greatRMGrandChild, setGreatRMGrandChild] =
    React.useState<any>(undefined);
  const [processData, setProcessData] = React.useState<any>([]);
  // const [childCostData, setProcessData] = React.useState<any>([]);
  const [rawMaterialData, setRawMaterialData] = React.useState<any>([]);
  const [childCostData, setChildCostData] = React.useState<any>([]);
  const [overheadsData, setOverheadsData] = React.useState<any>([]);
  const [subChildData, setSubChildData] = React.useState<any>([]);
  const [subChildDataProcess, setSubChildDataProcess] = React.useState<any>([]);
  const [subChildDataOverhead, setSubChildDataOverhead] = React.useState<any>(
    []
  );
  const [accoLoader, setAccoLoader] = React.useState<boolean>(false);
  const [accoLoderChildCost, setAccoLoaderChildCost] =
    React.useState<boolean>(false);
  const [accoLoaderProcess, setAccoLoaderProcess] =
    React.useState<boolean>(false);
  const [accoLoaderOverhead, setAccoLoaderOverhead] =
    React.useState<boolean>(false);
  const [accoLoaderCalc, setAccoLoaderCalc] = React.useState<boolean>(false);
  const [accoLoaderProcessCalc, setAccoLoaderProcessCalc] =
    React.useState<boolean>(false);
  const [accoLoaderOverheadCalc, setAccoLoaderOverheadCalc] =
    React.useState<boolean>(false);

  const [accoLoaderSubChild, setAccoLoaderSubChild] =
    React.useState<boolean>(false);
  const [accoLoaderProcessSubChild, setAccoLoaderProcessSubChild] =
    React.useState<boolean>(false);
  const [accoLoaderOverheadSubChild, setAccoLoaderOverheadSubChild] =
    React.useState<boolean>(false);

  const [accoLoaderGrand, setAccoLoaderGrand] = React.useState<boolean>(false);
  const [accoLoaderProcessGrand, setAccoLoaderProcessGrand] =
    React.useState<boolean>(false);
  const [accoLoaderOverheadGrand, setAccoLoaderOverheadGrand] =
    React.useState<boolean>(false);
  const [subGrandChildData, setSubGrandChildData] = React.useState<any>([]);
  const [subGrandChildDataProcess, setSubGrandChildDataProcess] =
    React.useState<any>([]);

  const [subGrandChildDataOverhead, setSubGrandChildDataOverhead] =
    React.useState<any>([]);

  const [calculatorData, setCalculatorData] = React.useState<any>([]);
  const [calculatorDataRaw, setCalculatorDataRaw] = React.useState<any>([]);
  const [calculatorDataProcess, setCalculatorDataProcess] = React.useState<any>(
    []
  );
  const [calculatorDataOverhead, setCalculatorDataOverhead] =
    React.useState<any>([]);

  const [value, setValue] = React.useState(0);
  const [valueRaw, setValueRaw] = React.useState(0);

  const handleTabChange = (
    event: React.SyntheticEvent,
    newValue: number,
    type: any
  ) => {
    // getCalculatorsData(childData)
    setExpandedSub(false);
    setValue(newValue);
  };
  const handleTabChangeRaw = (
    event: React.SyntheticEvent,
    newValue: number,
    type: any
  ) => {
    // getCalculatorsData(childData)

    setSubChild(false);
    getCalculatorsData({ calculator_id: newValue });
    setValueRaw(newValue);
  };

  React.useEffect(() => {
    if (subGrandChildDataProcess?.length > 0) {
      setValue(subGrandChildDataProcess[0]?.calculator_id);
    }
    if (subChildData?.length > 0) {
      setValueRaw(subChildData[0]?.calculator_id);
      getCalculatorsData({ calculator_id: subChildData[0]?.calculator_id });
    }
  }, [subGrandChildDataProcess, subChildData]);

  React.useEffect(() => {
    getCalculatorsDataProcess();
  }, [value]);

  const getAccordinData = () => {
    setAccoLoader(true);
    API.get(
      "/customer/costing_module/",
      {
        vault_id: vault,
        get_net_estimation: true,
        scenario: scenarioId != 0?scenarioId : undefined,
      },
      0
    )
      .then((res: any) => {
        setParentData(res.data[0]);
        setAccoLoader(false);
      })
      .catch((err: any) => {
        setParentData({});
        setAccoLoader(false);
      });
  };
  const getRawMaterialData = () => {
    setAccoLoader(true);
    API.get(
      "/customer/costing_module/",
      {
        vault_id: vault,
        get_raw_materials_details: true,
        scenario: scenarioId != 0?scenarioId : undefined
      },
      0
    )
      .then((res: any) => {
        setRawMaterialData(res.data);
        setAccoLoader(false);
      })
      .catch((err: any) => {
        setRawMaterialData([]);
        setAccoLoader(false);
      });
  };

  const getChildCostData = () => {
    setAccoLoaderChildCost(true);
    API.get(
      "/customer/costing_module/",
      {
        vault_id: vault,
        get_children_estimation: true,
        scenario: scenarioId != 0?scenarioId : undefined
      },
      0
    )
      .then((res: any) => {
        setChildCostData(res.data);
        setAccoLoaderChildCost(false);
      })
      .catch((err: any) => {
        setChildCostData([]);
        setAccoLoaderChildCost(false);
      });
  };

  const getProcessData = () => {
    setAccoLoaderProcess(true);
    API.get(
      "/customer/costing_module/",
      {
        vault_id: vault,
        get_process_details: true,
        scenario: scenarioId != 0?scenarioId : undefined
      },
      0
    )
      .then((res: any) => {
        setProcessData(res.data);
        setAccoLoaderProcess(false);
      })
      .catch((err: any) => {
        setProcessData([]);
        setAccoLoaderProcess(false);
      });
  };
  const setRoute = (idd: any, abb: any, ancestors: any) => {
    sessionStorage.setItem("node", JSON.stringify(ancestors));
    history.push(`/view/${projectId}/${topVault}/${idd}/${abb}/costing`);
  };

  const getChildCostDataAll = () => {
    setAccoLoaderProcess(true);
    API.get("/cost/get_children_estimation/", { vault_id: vault }, 0)
      .then((res: any) => {
        setProcessData(res.data);
        setAccoLoaderProcess(false);
      })
      .catch((err: any) => {
        setProcessData([]);
        setAccoLoaderProcess(false);
      });
  };

  const getOverheadsData = () => {
    setAccoLoaderOverhead(false);
    API.get(
      "/customer/costing_module/",
      {
        vault_id: vault,
        get_overhead_details: true,
        scenario: scenarioId != 0?scenarioId : undefined
      },
      0
    )
      .then((res: any) => {
        setOverheadsData(res.data);
        setAccoLoaderOverhead(false);
      })
      .catch((err: any) => {
        setOverheadsData([]);
        setAccoLoaderOverhead(false);
      });
  };

  // React.useEffect(()=>{
  //   if (expanded === "panel1") {
  //     getRawMaterialData();
  //   } else if (expanded === "panel2") {
  //     getProcessData();
  //   } else if (expanded === "panel3") {
  //     getOverheadsData();
  //   }
  // },[expandedSub])

  const subChildExpand = (item: any) => {
    setSubChildData([]);

    setAccoLoaderSubChild(true);

    API.get(
      "/customer/costing_module/",
      {
        vault_id: vault,
        get_calculators: true,
        model_name: "materialcostestimation",
        object_id: item?.material_estimation_id,
        scenario: scenarioId != 0?scenarioId : undefined
      },
      0
    )
      .then((res: any) => {
        setAccoLoaderSubChild(false);
        setSubChildData(res.data);
        //subChildData
      })
      .catch((err: any) => {
        setSubChildData([]);
        setAccoLoaderSubChild(false);
      });
  };

  const subChildExpandProcess = (item: any) => {
    setSubChildDataProcess([]);
    setAccoLoaderProcessSubChild(true);

    API.get(
      "/customer/costing_module/",
      {
        vault_id: vault,
        get_calculations: true,
        model_name: "processcostestimation",
        object_id: item?.process_estimation_id,
        scenario: scenarioId != 0?scenarioId : undefined
      },
      0
    )
      .then((res: any) => {
        setSubChildDataProcess(res.data);
        setAccoLoaderProcessSubChild(false);
      })
      .catch((err: any) => {
        setSubChildDataProcess([]);
        setAccoLoaderProcessSubChild(false);
      });
  };
  const subChildExpandOverhead = (item: any) => {
    setSubChildDataOverhead([]);
    setAccoLoaderOverheadSubChild(true);

    API.get(
      "/customer/costing_module/",
      {
        vault_id: vault,
        get_calculators: true,
        model_name: "overheadcostestimation",
        object_id: item?.overhead_estimation_id,
        scenario: scenarioId != 0?scenarioId : undefined
      },
      0
    )
      .then((res: any) => {
        setSubChildDataOverhead(res.data);
        setAccoLoaderOverheadSubChild(false);
      })
      .catch((err: any) => {
        setSubChildDataOverhead([]);
        setAccoLoaderOverheadSubChild(false);
      });
  };

  React.useEffect(() => {
    getAccordinData();
  }, [vault]);

  React.useEffect(() => {
    //Raw Material
    if (!isUndefined(expandedSub)) {
      subChildExpand({ material_estimation_id: expandedSub });
    }

    if (!isUndefined(subGrandChild)) {
      getCalculatorsData({ calculator_id: subGrandChild });
    }

    //Overhead

    if (!isUndefined(expandedSubOverhead)) {
      subChildExpandOverhead({ overhead_estimation_id: expandedSubOverhead });
    }

    if (!isUndefined(subGrandChild)) {
      getCalculatorsDataOverhead({ calculator_id: subGrandChild });
    }

    //Process

    if (!isUndefined(expandedSubProcess)) {
      subChildExpandProcess({ process_estimation_id: expandedSubProcess });
    }
    if (
      sessionStorage.getItem("process_estimation_id") &&
      sessionStorage.getItem("calculator_category_id")
    ) {
      getGrandChildDataProcess(
        {
          calculator_category_id: sessionStorage.getItem(
            "calculator_category_id"
          ),
        },
        {
          process_estimation_id: sessionStorage.getItem(
            "process_estimation_id"
          ),
        }
      );
    }
    if (!isUndefined(value)) {
      getCalculatorsDataProcess();
    }
  }, []);

  React.useEffect(() => {
    getOverheadsData();
  }, [expandedOverhead]);
  React.useEffect(() => {
    getRawMaterialData();
  }, [expanded]);
  React.useEffect(() => {
    getProcessData();
  }, [expandedProcess]);
  React.useEffect(() => {
    getChildCostData();
  }, [expandedChildData]);
  React.useEffect(() => {
    getOverheadsData();
    getRawMaterialData();
    getProcessData();
    getChildCostData();
    // return ()=>{setExpandedSub(undefined);
    // setSubChild(undefined);
    // setSubGrandChild(undefined);
    // setExpanded(false);
    // setExpandedProcess(false)
    // setExpandedOverhead(false)
    // }
  }, [vault]);
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };
  // expandedChildData
  const handleChangeChildCost =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpandedChildData(newExpanded ? panel : false);
    };

  const handleChangeProcess =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpandedProcess(newExpanded ? panel : false);
    };

  const handleChangeOverhead =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpandedOverhead(newExpanded ? panel : false);
    };

  const handleChangeSub =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpandedSub(newExpanded ? panel : false);
      setSubChild(false);
    };
  const handleChangeSubProcess =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpandedSubProcess(newExpanded ? panel : false);
    };
  const handleChangeSubOverhead =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpandedSubOverhead(newExpanded ? panel : false);
    };

  const getCalculatorsData = (calculatorsData: any) => {
    setAccoLoaderCalc(true);
    setCalculatorData([]);
    API.get(
      "customer/costing_module/",
      {
        id: calculatorsData?.calculator_id,
        get_calculator_data: true,
        scenario: scenarioId != 0?scenarioId : undefined
      },
      0
    )
      .then((res: any) => {
        setCalculatorData(res.data);
        setAccoLoaderCalc(false);
      })
      .catch((err: any) => {
        setCalculatorData([]);
        setAccoLoaderCalc(false);
      });
  };

  // const getCalculatorsDataRaw = (calculatorsData: any) => {
  //   setAccoLoaderCalc(true);
  //   setCalculatorDataRaw([]);
  //   API.get(
  //     "customer/costing_module/",
  //     {
  //       id: calculatorsData?.calculator_id,
  //       get_calculator_data: true,
  //       scenario: url.includes("scenario") ? scenarioId : undefined
  //     },
  //     0
  //   )
  //     .then((res: any) => {
  //       setCalculatorDataRaw(res.data);
  //       setAccoLoaderCalc(false);
  //     })
  //     .catch((err: any) => {
  //       setCalculatorDataRaw([]);
  //       setAccoLoaderCalc(false);
  //     });
  // };
  const getCalculatorsDataProcess = () => {
    setAccoLoaderProcessCalc(true);
    setCalculatorDataProcess([]);
    if (value != 0) {
      API.get(
        "customer/costing_module/",
        {
          id: value,
          get_calculator_data: true,
          scenario: scenarioId != 0?scenarioId : undefined
        },
        0
      )
        .then((res: any) => {
          setCalculatorDataProcess(res.data);
          setAccoLoaderProcessCalc(false);
        })
        .catch((err: any) => {
          setCalculatorDataProcess([]);
          setAccoLoaderProcessCalc(false);
        });
    }
  };
  const getCalculatorsDataOverhead = (calculatorsData: any) => {
    setAccoLoaderOverheadCalc(true);
    setCalculatorDataOverhead([]);
    API.get(
      "customer/costing_module/",
      {
        id: calculatorsData?.calculator_id,
        get_calculator_data: true,
        scenario: scenarioId != 0?scenarioId : undefined
      },
      0
    )
      .then((res: any) => {
        setCalculatorDataOverhead(res.data);
        setAccoLoaderOverheadCalc(false);
      })
      .catch((err: any) => {
        setCalculatorDataOverhead([]);
        setAccoLoaderOverheadCalc(false);
      });
  };

  const getGrandChildData = (itm: any, item: any) => {
    setAccoLoaderGrand(true);
    setSubGrandChildData([]);

    API.get(
      "/customer/costing_module/",
      {
        vault_id: vault,
        get_calculators: true,
        model_name: "materialcostestimation",
        object_id: item?.material_estimation_id,
        category: itm.calculator_category_id,
        scenario: scenarioId != 0?scenarioId : undefined
      },
      0
    )
      .then((res: any) => {
        setSubGrandChildData(res.data);
        setAccoLoaderGrand(false);
      })
      .catch((err: any) => {
        setSubGrandChildData([]);
        setAccoLoaderGrand(false);
      });
  };
  const getGrandChildDataProcess = (itm: any, item: any) => {
    setAccoLoaderProcessGrand(true);
    setSubGrandChildDataProcess([]);
    API.get(
      "/customer/costing_module/",
      {
        vault_id: vault,
        get_calculators: true,
        model_name: "processcostestimation",
        object_id: item?.process_estimation_id,
        category: itm.calculator_category_id,
        scenario: scenarioId != 0?scenarioId : undefined
      },
      0
    )
      .then((res: any) => {
        setSubGrandChildDataProcess(res.data);
        setAccoLoaderProcessGrand(false);
        sessionStorage.setItem(
          "process_estimation_id",
          item?.process_estimation_id
        );
        sessionStorage.setItem(
          "calculator_category_id",
          itm?.calculator_category_id
        );
      })
      .catch((err: any) => {
        setSubGrandChildDataProcess([]);
        setAccoLoaderProcessGrand(false);
      });
  };

  const getGrandChildDataOverhead = (itm: any, item: any) => {
    setAccoLoaderOverheadGrand(true);
    setSubGrandChildDataOverhead([]);
    API.get(
      "/customer/costing_module/",
      {
        vault_id: vault,
        get_calculators: true,
        model_name: "overheadcostestimation",
        object_id: item?.overhead_estimation_id,
        category: itm.calculator_category_id,
        scenario: scenarioId != 0?scenarioId : undefined
      },
      0
    )
      .then((res: any) => {
        setSubGrandChildDataOverhead(res.data);
        setAccoLoaderOverheadGrand(false);
      })
      .catch((err: any) => {
        setSubGrandChildDataOverhead([]);
        setAccoLoaderOverheadGrand(false);
      });
  };

  const handleChangeSubAccChild =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setSubChild(newExpanded ? panel : false);
      setExpandedSub(false);
    };

  const handleChangeSubAccgrandChild =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setSubGrandChild(newExpanded ? panel : false);
    };

  const handleChangeSubGreatAccgrandChild =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setGreatGrandChild(newExpanded ? panel : false);
    };

  const handleChangeSubGreatRMAccgrandChild =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setGreatRMGrandChild(newExpanded ? panel : false);
    };

  const AssumptionCardModal = useBaseModal();

  const getShowStatus = (contentType: any) => {
    switch (contentType) {
      case "Machine":
        return true;
      case "Labour":
        return true;
      case "ToolDatabase":
        return true;
    }
  };

  const getShowMaterialStatus = (contentType: any) => {
    switch (contentType) {
      case "Material":
        return true;
      case "PurchasingItem":
        return true;
      case "FastenerDatabase":
        return true;
    }
  };

  const detailsShow = (calcId: any, objId: any, type: any) => {
    API.get(
      "customer/costing_module/",
      {
        vault_id: vault,
        costing_info: type.toLowerCase(),
        object_id: calcId,
        param_id: objId,
        scenario: scenarioId != 0?scenarioId : undefined
      },
      0
    )
      .then((res: any) => {
        informationModal.open(res.data);
      })
      .catch((err) => {});
  };

  return (
    <div>
      {!accoLoderChildCost ? (
        <Box>
          
          <Box
            sx={{
              display: "flex",
              // justifyContent: "space-between",
              columnGap: "10px",
              alignItems: "center",
              margin: "0.5rem 0",
              padding: "0 1rem",
              justifyContent: "space-between",
              flexDirection: "row",
            }}
          >
            
          </Box>
          <Box sx={{ padding: "0 0.5rem" }}>
            <Table>
              <TableHead>
                <TableRow sx={{ borderBottom: "1px solid #fff" }}>
                  <TableCell
                    sx={{
                      backgroundColor: "primary.light",
                      color: "primary.main",
                      fontSize: "12px",
                    }}
                    className={styles.AccHeader}
                  >
                    Total Cost
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: "primary.light",
                      color: "primary.main",
                      fontSize: "12px",
                      borderBottom: "1px solid #fff",
                    }}
                    className={styles.AccHeader}
                  ></TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: "primary.light",
                      color: "primary.main",
                      fontSize: "12px",
                    }}
                    className={styles.AccHeader}
                  ></TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: "primary.light",
                      color: "primary.main",
                      fontSize: "12px",
                    }}
                    className={styles.AccHeader}
                  ></TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: "primary.light",
                      color: "primary.main",
                      fontSize: "12px",
                      textAlign: "right",
                    }}
                    className={styles.AccHeader}
                  >
                    {parentdata && !isUndefined(parentdata?.total_cost) ? (
                      `${parentdata?.total_cost} INR`
                    ) : (
                      <Skeleton animation={false} />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow sx={{ borderBottom: "1px solid #fff" }}>
                  <TableCell
                    sx={{
                      backgroundColor: "primary.light",
                      color: "primary.main",
                      fontSize: "12px",
                    }}
                    className={styles.AccHeader}
                  >
                    Cost Per kg
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: "primary.light",
                      color: "primary.main",
                      fontSize: "12px",
                      borderBottom: "1px solid #fff",
                    }}
                    className={styles.AccHeader}
                  ></TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: "primary.light",
                      color: "primary.main",
                      fontSize: "12px",
                    }}
                    className={styles.AccHeader}
                  ></TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: "primary.light",
                      color: "primary.main",
                      fontSize: "12px",
                    }}
                    className={styles.AccHeader}
                  ></TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: "primary.light",
                      color: "primary.main",
                      fontSize: "12px",
                      textAlign: "right",
                    }}
                    className={styles.AccHeader}
                  >
                    {parentdata && !isUndefined(parentdata?.cost_per_kg) ? (
                      `${parentdata?.cost_per_kg} INR`
                    ) : (
                      <Skeleton animation={false} />
                    )}
                  </TableCell>
                </TableRow>
                <TableRow>
                  <TableCell
                    sx={{
                      backgroundColor: "primary.light",
                      color: "primary.main",
                      fontSize: "12px",
                    }}
                    className={styles.AccHeader}
                  >
                    Share in product BOM cost
                  </TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: "primary.light",
                      color: "primary.main",
                      fontSize: "12px",
                    }}
                    className={styles.AccHeader}
                  ></TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: "primary.light",
                      color: "primary.main",
                      fontSize: "12px",
                    }}
                    className={styles.AccHeader}
                  ></TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: "primary.light",
                      color: "primary.main",
                      fontSize: "12px",
                    }}
                    className={styles.AccHeader}
                  ></TableCell>
                  <TableCell
                    sx={{
                      backgroundColor: "primary.light",
                      color: "primary.main",
                      fontSize: "12px",
                      textAlign: "right",
                    }}
                    className={styles.AccHeader}
                  >
                    {parentdata && !isUndefined(parentdata?.share_percent) ? (
                      `${parentdata?.share_percent} %`
                    ) : (
                      <Skeleton animation={false} />
                    )}
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
            <Box
              sx={{
                height: { lg: "65vh", xl: "73vh" },
                padding: "0 0.5rem",
                margin: "0 -0.5rem",
              }}
              adl-scrollbar="true"
              adl-scrollbar-width="0.3"
            >
              {/* Raw Material Accordian */}
              <Accordion
                expanded={expanded === "panel1"}
                onChange={handleChange("panel1")}
                disabled={
                  parseFloat(parentdata?.material_cost) === 0 ? true : false
                }
              >
                <AccordionSummary
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                  sx={{
                    backgroundColor: "#ffb347",
                    minHeight: { lg: "26px", xl: "30px" },
                    display: "flex",
                    justifyContent: "space-around",
                    padding: "0 0 0 0.5rem",
                    margin: "-1px",
                    // marginBottom:'0.5rem'
                  }}
                >
                  <Table>
                    {/* <TableHead>
                          <TableCell colSpan={3} sx={{borderBottom:'none',padding:'0 0.5rem',lineHeight:'1.2' }}>
                          <Typography style={{ fontSize: "12px", fontWeight: '500',lineHeight:'1.2' }}>
                      Raw Material Cost
                    </Typography>
                          </TableCell>
                          <TableCell sx={{borderBottom:'none',padding:'0 0.5rem',textAlign:'right',fontSize:'12px',lineHeight:'1.2' }}>
                          {`RM Cost (${sessionStorage.getItem("currency")})`}
                            </TableCell>
                        </TableHead> */}
                    <TableBody>
                      <TableRow>
                        <TableCell
                          sx={{
                            // backgroundColor: "primary.light",
                            color: "primary.main",
                            fontSize: "12px",
                            fontWeight: "500",
                          }}
                          className={styles.AccHeader}
                        >
                          Raw Material
                        </TableCell>
                        {/* <TableCell
                              sx={{
                                backgroundColor: "primary.light",
                                color: "primary.main",
                                fontSize: "12px",
                                width: "10rem",
                              }}
                              className={styles.AccHeader}
                            >
                              RM Name
                            </TableCell> */}
                        <TableCell
                          sx={{
                            // backgroundColor: "primary.light",
                            color: "primary.main",
                            fontSize: "12px",
                            width: "10rem",
                            fontWeight: "500",
                          }}
                          className={styles.AccHeader}
                        >
                          RM Grade
                        </TableCell>
                        <TableCell
                          sx={{
                            // backgroundColor: "primary.light",
                            color: "primary.main",
                            fontSize: "12px",
                            textAlign: "right",
                            width: "3.5rem",
                            fontWeight: "500",
                          }}
                          className={styles.AccHeader}
                        >
                          Qty.
                        </TableCell>
                        <TableCell
                          sx={{
                            // backgroundColor: "primary.light",
                            color: "primary.main",
                            fontSize: "12px",
                            textAlign: "right",
                            width: "5.5rem",
                            fontWeight: "500",
                          }}
                          className={styles.AccHeader}
                        >
                          Var/For
                        </TableCell>
                        <TableCell
                          sx={{
                            // backgroundColor: "primary.light",
                            color: "primary.main",
                            textAlign: "right",
                            fontSize: "12px",
                            width: "9rem",
                          }}
                          className={styles.AccHeader}
                        >
                          <Typography
                            style={{ fontSize: "12px", fontWeight: "500" }}
                          >
                            {parentdata &&
                            !isUndefined(parentdata?.material_cost) ? (
                              parentdata?.material_cost + " " + "INR"
                            ) : (
                              <Skeleton animation={false} />
                            )}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </AccordionSummary>
                <AccordionDetails>
                  <Box>
                    <Box>
                      {rawMaterialData && !accoLoader ? (
                        rawMaterialData.map((item: any, index: any) => {
                          return (
                            <Accordion
                              expanded={
                                expandedSub === item?.material_estimation_id
                              }
                              onChange={handleChangeSub(
                                item?.material_estimation_id
                              )}
                              sx={{ border: "none" }}
                            >
                              <AccordionSummary
                                aria-controls="panel2d-content"
                                id={item?.material_estimation_id}
                                sx={{
                                  backgroundColor: "#ffbe65",
                                  minHeight: { lg: "26px", xl: "30px" },
                                  padding: "0 0 0 1rem",
                                  margin: "-1px",
                                }}
                                onClick={() => subChildExpand(item)}
                              >
                                <Box
                                  sx={{ width: "100%" }}
                                  // onClick={() => subChildExpand(item)}
                                >
                                  <Table>
                                    <TableBody>
                                      <TableRow>
                                        <TableCell
                                          sx={{
                                            padding: "0 0.5rem",
                                            borderBottom: "none",
                                            fontSize: "12px",
                                          }}
                                        >
                                          {item?.subpart_name}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0 0.5rem",
                                            borderBottom: "none",
                                            fontSize: "12px",
                                            width: "10rem",
                                          }}
                                        >
                                          {item?.rm_grade}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0 0.5rem",
                                            borderBottom: "none",
                                            fontSize: "12px",
                                            textAlign: "right",
                                            width: "3.5rem",
                                          }}
                                        >
                                          {item?.quantity}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0 0.5rem",
                                            borderBottom: "none",
                                            fontSize: "12px",
                                            textAlign: "right",
                                            width: "5.5rem",
                                          }}
                                        >
                                          {item?.cm_count &&
                                            item?.cm_count > 1 &&
                                            `(${item?.formula})`}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0 0.5rem",
                                            borderBottom: "none",
                                            fontSize: "12px",
                                            textAlign: "right",
                                            width: "9rem",
                                          }}
                                        >
                                          {`${item?.total_cost} INR`}
                                        </TableCell>
                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                </Box>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Box>
                                  {subChildData && !accoLoaderSubChild ? (
                                    subChildData?.map(
                                      (childData: any, i: any) => {
                                        return (
                                          i === 0 && (
                                            <Box>
                                              {subChildData &&
                                              !accoLoaderSubChild ? (
                                                subChildData?.map(
                                                  (childData: any, i: any) => {
                                                    return (
                                                      <Accordion
                                                        expanded={
                                                          greatRMGrandChild ===
                                                          childData?.calculator_id
                                                        }
                                                        onChange={handleChangeSubGreatRMAccgrandChild(
                                                          childData?.calculator_id
                                                        )}
                                                        sx={{
                                                          border: "none",
                                                        }}
                                                      >
                                                        <AccordionSummary
                                                          aria-controls="panel2da-content"
                                                          // id={childData?.calculator_id}
                                                          sx={{
                                                            backgroundColor:
                                                              "#ffd4a1",
                                                            minHeight: {
                                                              lg: "26px",
                                                              xl: "30px",
                                                            },
                                                            padding:
                                                              "0 0 0 2rem",
                                                            margin: "-1px",
                                                          }}
                                                          onClick={(
                                                            event: any
                                                          ) =>
                                                            handleTabChangeRaw(
                                                              event,
                                                              childData?.calculator_id,
                                                              childData
                                                            )
                                                          }
                                                        >
                                                          <Table>
                                                            <TableBody>
                                                              <TableRow>
                                                                <TableCell
                                                                  sx={{
                                                                    // backgroundColor: "primary.light",
                                                                    color:
                                                                      "primary.main",
                                                                    fontSize:
                                                                      "12px",
                                                                    fontWeight:
                                                                      "500",
                                                                  }}
                                                                  className={
                                                                    styles.AccHeader
                                                                  }
                                                                >
                                                                  {
                                                                    childData?.calculator_name
                                                                  }
                                                                </TableCell>

                                                                <TableCell
                                                                  sx={{
                                                                    // backgroundColor: "primary.light",
                                                                    color:
                                                                      "primary.main",
                                                                    fontSize:
                                                                      "12px",
                                                                    width:
                                                                      "10rem",
                                                                    fontWeight:
                                                                      "500",
                                                                  }}
                                                                  className={
                                                                    styles.AccHeader
                                                                  }
                                                                ></TableCell>
                                                                <TableCell
                                                                  sx={{
                                                                    // backgroundColor: "primary.light",
                                                                    color:
                                                                      "primary.main",
                                                                    fontSize:
                                                                      "12px",
                                                                    textAlign:
                                                                      "right",
                                                                    width:
                                                                      "3.5rem",
                                                                    fontWeight:
                                                                      "500",
                                                                  }}
                                                                  className={
                                                                    styles.AccHeader
                                                                  }
                                                                ></TableCell>
                                                                <TableCell
                                                                  sx={{
                                                                    padding:
                                                                      "0 0.5rem",
                                                                    borderBottom:
                                                                      "none",
                                                                    fontSize:
                                                                      "12px",
                                                                    textAlign:
                                                                      "right",
                                                                    width:
                                                                      "5.5rem",
                                                                  }}
                                                                >
                                                                  {subChildData &&
                                                                    subChildData?.length >
                                                                      1 &&
                                                                    `(${childData?.alias})`}
                                                                </TableCell>
                                                                <TableCell
                                                                  sx={{
                                                                    // backgroundColor: "primary.light",
                                                                    color:
                                                                      "primary.main",
                                                                    textAlign:
                                                                      "right",
                                                                    fontSize:
                                                                      "12px",
                                                                    width:
                                                                      "9rem",
                                                                  }}
                                                                  className={
                                                                    styles.AccHeader
                                                                  }
                                                                >
                                                                  <Typography
                                                                    style={{
                                                                      fontSize:
                                                                        "12px",
                                                                      fontWeight:
                                                                        "500",
                                                                    }}
                                                                  >
                                                                    {
                                                                      childData?.net_cost
                                                                    }
                                                                    &nbsp;
                                                                    {
                                                                      childData?.currency
                                                                    }
                                                                  </Typography>
                                                                </TableCell>
                                                              </TableRow>
                                                            </TableBody>
                                                          </Table>
                                                        </AccordionSummary>

                                                        <AccordionDetails>
                                                          <Box
                                                            sx={{
                                                              marginLeft:
                                                                "0rem",
                                                              // marginBottom:'0.5rem',
                                                            }}
                                                          >
                                                            <Table>
                                                              <TableHead
                                                                sx={{
                                                                  position:
                                                                    "sticky",
                                                                  top: "0",
                                                                  backgroundColor:
                                                                    "white",
                                                                }}
                                                              >
                                                                <TableRow>
                                                                  <TableCell
                                                                    sx={{
                                                                      padding:
                                                                        "0 0.5rem 0 3.5rem",
                                                                      borderBottomColor:
                                                                        "primary.light",
                                                                      color:
                                                                        "primary.main",
                                                                      fontSize:
                                                                        "12px",
                                                                    }}
                                                                  >
                                                                    Parameter
                                                                  </TableCell>
                                                                  <TableCell
                                                                    sx={{
                                                                      padding:
                                                                        "0 0.5rem",
                                                                      borderBottomColor:
                                                                        "primary.light",
                                                                      color:
                                                                        "primary.main",
                                                                      fontSize:
                                                                        "12px",
                                                                      width:
                                                                        "10rem",
                                                                      textAlign:
                                                                        "right",
                                                                    }}
                                                                  >
                                                                    Value
                                                                  </TableCell>
                                                                  <TableCell
                                                                    sx={{
                                                                      padding:
                                                                        "0 0.5rem",
                                                                      borderBottomColor:
                                                                        "primary.light",
                                                                      color:
                                                                        "primary.main",
                                                                      fontSize:
                                                                        "12px",
                                                                      width:
                                                                        "5rem",
                                                                      textAlign:
                                                                        "right",
                                                                    }}
                                                                  >
                                                                    Unit
                                                                  </TableCell>
                                                                  <TableCell
                                                                    sx={{
                                                                      padding:
                                                                        "0 0.5rem",
                                                                      borderBottomColor:
                                                                        "primary.light",
                                                                      color:
                                                                        "primary.main",
                                                                      fontSize:
                                                                        "12px",
                                                                      width:
                                                                        "9rem",
                                                                      textAlign:
                                                                        "center",
                                                                    }}
                                                                  >
                                                                    Flags
                                                                  </TableCell>
                                                                </TableRow>
                                                              </TableHead>
                                                              <TableBody>
                                                                {calculatorData &&
                                                                !accoLoaderCalc ? (
                                                                  calculatorData?.map(
                                                                    (
                                                                      calcData: any,
                                                                      index: any
                                                                    ) => {
                                                                      return (
                                                                        <TableRow
                                                                        // style={
                                                                        //   calculatorData?.length -
                                                                        //     1 ===
                                                                        //   index
                                                                        //     ? {
                                                                        //         backgroundColor:
                                                                        //           "rgb(130, 202, 157)",
                                                                        //       }
                                                                        //     : {}
                                                                        // }
                                                                        >
                                                                          <TableCell
                                                                            sx={{
                                                                              display:
                                                                                "flex",
                                                                              alignItems:
                                                                                "center",
                                                                              columnGap:
                                                                                "1rem",
                                                                              padding:
                                                                                "0.2rem 0.5rem 0.2rem 3.5rem",
                                                                              borderBottomColor:
                                                                                "primary.light",
                                                                              textTransform:
                                                                                "capitalize",
                                                                              fontSize:
                                                                                "12px",
                                                                            }}
                                                                          >
                                                                            {
                                                                              calcData?.name
                                                                            }
                                                                            {getShowMaterialStatus(
                                                                              calcData.content_type
                                                                            ) && (
                                                                              <Box>
                                                                                <InfoIcon
                                                                                  sx={{
                                                                                    fontSize:
                                                                                      "1rem",
                                                                                    color:
                                                                                      "#007fff",
                                                                                    cursor:
                                                                                      "pointer",
                                                                                  }}
                                                                                  onClick={() =>
                                                                                    detailsShow(
                                                                                      childData?.calculator_id,
                                                                                      calcData?.id,
                                                                                      calcData.content_type
                                                                                    )
                                                                                  }
                                                                                />
                                                                              </Box>
                                                                            )}
                                                                          </TableCell>
                                                                          <TableCell
                                                                            sx={{
                                                                              padding:
                                                                                "0.2rem 0.5rem",
                                                                              borderBottomColor:
                                                                                "primary.light",
                                                                              fontSize:
                                                                                "12px",
                                                                              width:
                                                                                "10rem",
                                                                              textAlign:
                                                                                "right",
                                                                            }}
                                                                          >
                                                                            {calcData?.value !==
                                                                              null &&
                                                                              `${
                                                                                typeof calcData?.value ===
                                                                                "number"
                                                                                  ? formatNumber(
                                                                                      calcData?.value
                                                                                    )
                                                                                  : calcData?.value
                                                                              }`}
                                                                          </TableCell>
                                                                          <TableCell
                                                                            sx={{
                                                                              padding:
                                                                                "0.2rem 0.5rem",
                                                                              borderBottomColor:
                                                                                "primary.light",
                                                                              fontSize:
                                                                                "12px",
                                                                              width:
                                                                                "5rem",
                                                                              textAlign:
                                                                                "right",
                                                                            }}
                                                                          >
                                                                            {calcData?.unit
                                                                              ? calcData?.unit
                                                                              : ""}
                                                                          </TableCell>
                                                                          <TableCell
                                                                            sx={{
                                                                              padding:
                                                                                "0.2rem 0.5rem",
                                                                              borderBottomColor:
                                                                                "primary.light",
                                                                              fontSize:
                                                                                "12px",
                                                                              width:
                                                                                "9rem",
                                                                              textAlign:
                                                                                "center",
                                                                            }}
                                                                          >
                                                                            <Box
                                                                              sx={{
                                                                                display:
                                                                                  "flex",
                                                                                alignItems:
                                                                                  "center",
                                                                                justifyContent:
                                                                                  "center",
                                                                                columnGap:
                                                                                  "10px",
                                                                                lineHeight:
                                                                                  "1",
                                                                              }}
                                                                            >
                                                                              {calcData?.flag && (
                                                                                <img
                                                                                  src={
                                                                                    flag
                                                                                  }
                                                                                  style={{
                                                                                    width:
                                                                                      "1.5rem",
                                                                                    cursor:
                                                                                      "pointer",
                                                                                  }}
                                                                                  onClick={() =>
                                                                                    window.open(
                                                                                      calcData?.flag,
                                                                                      "_blank",
                                                                                      " noopener"
                                                                                    )
                                                                                  }
                                                                                />
                                                                              )}
                                                                              {calcData?.db_file && (
                                                                                <img
                                                                                  title="Machine Cost"
                                                                                  src={
                                                                                    flagRed
                                                                                  }
                                                                                  style={{
                                                                                    width:
                                                                                      "1.5rem",
                                                                                    color:
                                                                                      "red",
                                                                                    cursor:
                                                                                      "pointer",
                                                                                  }}
                                                                                  onClick={() =>
                                                                                    window.open(
                                                                                      calcData?.db_file,
                                                                                      "_blank",
                                                                                      " noopener"
                                                                                    )
                                                                                  }
                                                                                />
                                                                              )}
                                                                              {calcData?.db_spcification_file && (
                                                                                <img
                                                                                  title="Machine Specification"
                                                                                  src={
                                                                                    flagRed
                                                                                  }
                                                                                  style={{
                                                                                    width:
                                                                                      "1.5rem",
                                                                                    color:
                                                                                      "red",
                                                                                    cursor:
                                                                                      "pointer",
                                                                                  }}
                                                                                  onClick={() =>
                                                                                    window.open(
                                                                                      calcData?.db_spcification_file,
                                                                                      "_blank",
                                                                                      " noopener"
                                                                                    )
                                                                                  }
                                                                                />
                                                                              )}
                                                                            </Box>
                                                                          </TableCell>
                                                                        </TableRow>
                                                                      );
                                                                    }
                                                                  )
                                                                ) : (
                                                                  <Typography
                                                                    variant="subtitle2"
                                                                    mt={1}
                                                                    align="center"
                                                                  >
                                                                    <Skeleton
                                                                      animation={
                                                                        false
                                                                      }
                                                                    />
                                                                  </Typography>
                                                                )}
                                                              </TableBody>
                                                            </Table>
                                                          </Box>
                                                        </AccordionDetails>
                                                      </Accordion>
                                                    );
                                                  }
                                                )
                                              ) : (
                                                <Typography
                                                  variant="subtitle2"
                                                  mt={1}
                                                  align="center"
                                                >
                                                  <Skeleton
                                                    animation={"wave"}
                                                  />
                                                </Typography>
                                              )}
                                            </Box>
                                          )
                                        );
                                      }
                                    )
                                  ) : (
                                    <Typography
                                      variant="subtitle2"
                                      mt={1}
                                      align="center"
                                    >
                                      <Skeleton animation={false} />
                                    </Typography>
                                  )}
                                </Box>
                              </AccordionDetails>
                            </Accordion>
                          );
                        })
                      ) : (
                        <Typography variant="subtitle2" mt={1} align="center">
                          <>
                            {" "}
                            <Skeleton animation={false} />
                            <Skeleton animation={false} />
                            <Skeleton animation={false} />
                          </>
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>

              {/* Process Accordian */}
              <Accordion
                expanded={expandedProcess === "panel2"}
                onChange={handleChangeProcess("panel2")}
                disabled={
                  parseFloat(parentdata?.process_cost) === 0 ? true : false
                }
              >
                <AccordionSummary
                  aria-controls="panel2d-content"
                  id="panel2d-header"
                  sx={{
                    backgroundColor: "#b8d8be",
                    color: "primary.main",
                    minHeight: { lg: "26px", xl: "30px" },
                    display: "flex",
                    padding: "0 0 0 0.5rem",
                    justifyContent: "space-around",
                    margin: "-1px",
                  }}
                >
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          sx={{
                            // backgroundColor: "primary.light",
                            color: "primary.main",
                            //
                            fontSize: "12px",
                            fontWeight: "500",
                          }}
                          className={styles.AccHeader}
                        >
                          Process
                        </TableCell>
                        <TableCell
                          sx={{
                            // backgroundColor: "primary.light",
                            color: "primary.main",
                            //
                            width: "10rem",
                            textAlign: "left",
                            fontSize: "12px",
                            fontWeight: "500",
                          }}
                          className={styles.AccHeader}
                        >
                          Category
                        </TableCell>
                        <TableCell
                          sx={{
                            // backgroundColor: "primary.light",
                            color: "primary.main",
                            //
                            width: "3.5rem",
                            textAlign: "right",
                            fontSize: "12px",
                            fontWeight: "500",
                          }}
                          className={styles.AccHeader}
                        >
                          Qty.
                        </TableCell>
                        <TableCell
                          sx={{
                            // backgroundColor: "primary.light",
                            color: "primary.main",
                            fontSize: "12px",
                            textAlign: "right",
                            width: "5.5rem",
                            fontWeight: "500",
                          }}
                          className={styles.AccHeader}
                        >
                          Var/For
                        </TableCell>
                        <TableCell
                          sx={{
                            // backgroundColor: "primary.light",
                            color: "primary.main",
                            //
                            textAlign: "right",
                            width: "9rem",
                            fontSize: "12px",
                          }}
                          className={styles.AccHeader}
                        >
                          <Typography
                            style={{ fontSize: "12px", fontWeight: "500" }}
                          >
                            {parentdata &&
                            !isUndefined(parentdata?.process_cost) ? (
                              parentdata?.process_cost + " " + "INR"
                            ) : (
                              <Skeleton animation={false} />
                            )}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </AccordionSummary>
                <AccordionDetails>
                  <Box>
                    <Box>
                      {processData && !accoLoaderProcess ? (
                        processData.map((item: any, index: any) => {
                          return (
                            <Accordion
                              expanded={
                                expandedSubProcess ===
                                item?.process_estimation_id
                              }
                              onChange={handleChangeSubProcess(
                                item?.process_estimation_id
                              )}
                              sx={{ border: "none" }}
                            >
                              <AccordionSummary
                                aria-controls="panel2d-content"
                                id={item?.process_estimation_id}
                                sx={{
                                  minHeight: { lg: "26px", xl: "30px" },
                                  padding: "0 0 0 1rem",
                                  backgroundColor: "#c8e1cc",
                                  margin: "-1px",
                                }}
                                onClick={() => subChildExpandProcess(item)}
                              >
                                <Box sx={{ width: "100%" }}>
                                  <Table>
                                    <TableBody>
                                      <TableRow>
                                        <TableCell
                                          sx={{
                                            padding: "0 0.5rem",
                                            borderBottom: "none",
                                            fontSize: "12px",
                                          }}
                                        >
                                          {item?.subpart_name}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0 0.5rem",
                                            borderBottom: "none",
                                            width: "10rem",
                                            textAlign: "left",
                                            fontSize: "12px",
                                          }}
                                        >
                                          {item?.commodity_name}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0 0.5rem",
                                            borderBottom: "none",
                                            width: "3.5rem",
                                            textAlign: "right",
                                            fontSize: "12px",
                                          }}
                                        >
                                          {item?.quantity}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0 0.5rem",
                                            borderBottom: "none",
                                            fontSize: "12px",
                                            textAlign: "right",
                                            width: "5.5rem",
                                          }}
                                        ></TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0 0.5rem",
                                            borderBottom: "none",
                                            width: "9rem",
                                            textAlign: "right",
                                            fontSize: "12px",
                                          }}
                                        >
                                          {`${item?.total_cost} INR`}
                                        </TableCell>
                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                </Box>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Box>
                                  {subChildDataProcess &&
                                  !accoLoaderProcessSubChild ? (
                                    subChildDataProcess?.map(
                                      (itm: any, ind: any) => {
                                        return (
                                          <Box>
                                            <Accordion
                                              expanded={
                                                subChild ===
                                                `${itm?.value}${item?.process_estimation_id}`
                                              }
                                              onChange={handleChangeSubAccChild(
                                                `${itm?.value}${item?.process_estimation_id}`
                                              )}
                                              sx={{ border: "none" }}
                                            >
                                              <AccordionSummary
                                                aria-controls="panel2da-content"
                                                id={`${itm?.value}${item?.process_estimation_id}`}
                                                sx={{
                                                  minHeight: {
                                                    lg: "26px",
                                                    xl: "30px",
                                                  },
                                                  padding: "0 0 0 0.5rem",
                                                  paddingLeft: "1.5rem",
                                                  backgroundColor: "#d2e7d6",

                                                  margin: "-1px",
                                                }}
                                                onClick={() =>
                                                  getGrandChildDataProcess(
                                                    itm,
                                                    item
                                                  )
                                                }
                                              >
                                                <Box
                                                  sx={{
                                                    width: "100%",
                                                    display: "flex",
                                                    justifyContent:
                                                      "space-between",
                                                    paddingLeft: "0.5rem",
                                                  }}
                                                >
                                                  {/* <Typography
                                                      style={{ fontSize: "12px" }}
                                                      align="left"
                                                    >
                                                      {itm?.value}
                                                    </Typography>
                                                    <Typography
                                                      style={{ fontSize: "12px" }}
                                                      align="right"
                                                    >
                                                      {`${
                                                        itm?.net_cost
                                                      } ${sessionStorage.getItem(
                                                        "currency"
                                                      )}`}
                                                    </Typography> */}
                                                  <Table>
                                                    <TableBody>
                                                      <TableRow>
                                                        <TableCell
                                                          sx={{
                                                            padding: "0 0.5rem",
                                                            borderBottom:
                                                              "none",
                                                            fontSize: "12px",
                                                          }}
                                                        >
                                                          {itm?.value}
                                                        </TableCell>
                                                        <TableCell
                                                          sx={{
                                                            padding: "0 0.5rem",
                                                            borderBottom:
                                                              "none",
                                                            width: "10rem",
                                                            textAlign: "left",
                                                            fontSize: "12px",
                                                          }}
                                                        ></TableCell>
                                                        <TableCell
                                                          sx={{
                                                            padding: "0 0.5rem",
                                                            borderBottom:
                                                              "none",
                                                            width: "3.5rem",
                                                            textAlign: "right",
                                                            fontSize: "12px",
                                                          }}
                                                        ></TableCell>
                                                        <TableCell
                                                          sx={{
                                                            padding: "0 0.5rem",
                                                            borderBottom:
                                                              "none",
                                                            fontSize: "12px",
                                                            textAlign: "right",
                                                            width: "5.5rem",
                                                          }}
                                                        >
                                                          {itm?.cm_count &&
                                                            itm?.cm_count > 1 &&
                                                            `(${itm?.formula})`}
                                                        </TableCell>
                                                        <TableCell
                                                          sx={{
                                                            padding: "0 0.5rem",
                                                            borderBottom:
                                                              "none",
                                                            width: "9rem",
                                                            textAlign: "right",
                                                            fontSize: "12px",
                                                          }}
                                                        >
                                                          {`${itm?.net_cost} INR`}
                                                        </TableCell>
                                                      </TableRow>
                                                    </TableBody>
                                                  </Table>
                                                </Box>
                                              </AccordionSummary>
                                              <AccordionDetails>
                                                {subGrandChildDataProcess &&
                                                !accoLoaderProcessGrand ? (
                                                  subGrandChildDataProcess?.map(
                                                    (
                                                      childData: any,
                                                      i: any
                                                    ) => {
                                                      return (
                                                        // <Tab
                                                        //   sx={{
                                                        //     padding: "0",
                                                        //     minHeight: "24px",
                                                        //   }}
                                                        //   label={
                                                        //     childData?.calculator_name
                                                        //   }
                                                        //   value={
                                                        //     childData?.calculator_id
                                                        //   }
                                                        // />
                                                        <Accordion
                                                          expanded={
                                                            greatGrandChild ===
                                                            childData?.calculator_id
                                                          }
                                                          onChange={handleChangeSubGreatAccgrandChild(
                                                            childData?.calculator_id
                                                          )}
                                                          sx={{
                                                            border: "none",
                                                          }}
                                                        >
                                                          <AccordionSummary
                                                            aria-controls="panel2da-content"
                                                            // id={childData?.calculator_id}
                                                            sx={{
                                                              backgroundColor:
                                                                "#e6faeb",
                                                              minHeight: {
                                                                lg: "26px",
                                                                xl: "30px",
                                                              },
                                                              padding:
                                                                "0 0 0 2rem",
                                                              margin: "-1px",
                                                            }}
                                                            onClick={(
                                                              event: any
                                                            ) =>
                                                              handleTabChange(
                                                                event,
                                                                childData?.calculator_id,
                                                                "process"
                                                              )
                                                            }
                                                          >
                                                            <Table>
                                                              <TableBody>
                                                                <TableRow>
                                                                  <TableCell
                                                                    sx={{
                                                                      // backgroundColor: "primary.light",
                                                                      color:
                                                                        "#0c66cc",
                                                                      fontSize:
                                                                        "12px",
                                                                      // fontWeight:
                                                                      //   "500",
                                                                      marginLeft:
                                                                        "0.5rem",
                                                                    }}
                                                                    className={
                                                                      styles.AccHeader
                                                                    }
                                                                  >
                                                                    {
                                                                      childData?.calculator_name
                                                                    }
                                                                  </TableCell>

                                                                  <TableCell
                                                                    sx={{
                                                                      // backgroundColor: "primary.light",
                                                                      color:
                                                                        "#0c66cc",
                                                                      fontSize:
                                                                        "12px",
                                                                      width:
                                                                        "10rem",
                                                                      fontWeight:
                                                                        "500",
                                                                    }}
                                                                    className={
                                                                      styles.AccHeader
                                                                    }
                                                                  ></TableCell>
                                                                  <TableCell
                                                                    sx={{
                                                                      // backgroundColor: "primary.light",
                                                                      color:
                                                                        "#0c66cc",
                                                                      fontSize:
                                                                        "12px",
                                                                      textAlign:
                                                                        "right",
                                                                      width:
                                                                        "3.5rem",
                                                                      fontWeight:
                                                                        "500",
                                                                    }}
                                                                    className={
                                                                      styles.AccHeader
                                                                    }
                                                                  ></TableCell>
                                                                  <TableCell
                                                                    sx={{
                                                                      padding:
                                                                        "0 0.5rem",
                                                                      borderBottom:
                                                                        "none",
                                                                      fontSize:
                                                                        "12px",
                                                                      textAlign:
                                                                        "right",
                                                                      width:
                                                                        "5.5rem",
                                                                    }}
                                                                  >
                                                                    {subGrandChildDataProcess &&
                                                                      subGrandChildDataProcess?.length >
                                                                        1 &&
                                                                      `(${childData?.alias})`}
                                                                  </TableCell>

                                                                  <TableCell
                                                                    sx={{
                                                                      // backgroundColor: "primary.light",
                                                                      color:
                                                                        "#0c66cc",
                                                                      textAlign:
                                                                        "right",
                                                                      fontSize:
                                                                        "12px",
                                                                      width:
                                                                        "9rem",
                                                                    }}
                                                                    className={
                                                                      styles.AccHeader
                                                                    }
                                                                  >
                                                                    <Typography
                                                                      style={{
                                                                        fontSize:
                                                                          "12px",
                                                                        // fontWeight:
                                                                        //   "500",
                                                                      }}
                                                                    >
                                                                      {
                                                                        childData?.net_cost
                                                                      }
                                                                      &nbsp;
                                                                      {
                                                                        childData?.currency
                                                                      }
                                                                    </Typography>
                                                                  </TableCell>
                                                                </TableRow>
                                                              </TableBody>
                                                            </Table>
                                                            {/* <Box
                                                                sx={{
                                                                  display: "flex",
                                                                  justifyContent:
                                                                    "space-between",
                                                                  width: "100%",
                                                                  fontSize:
                                                                    "12px",
                                                                  paddingLeft:
                                                                    "0.5rem",
                                                                }}
                                                              >
                                                                {
                                                                  childData?.calculator_name
                                                                }
                                                                <Typography
                                                                  style={{
                                                                    fontSize:
                                                                      "12px",
                                                                  }}
                                                                ></Typography>
                                                              </Box> */}
                                                          </AccordionSummary>
                                                          <AccordionDetails>
                                                            <Box
                                                              sx={{
                                                                border:
                                                                  "1px solid #007fff14",
                                                              }}
                                                            >
                                                              <Table>
                                                                <TableHead
                                                                  sx={{
                                                                    position:
                                                                      "sticky",
                                                                    top: "0",
                                                                    backgroundColor:
                                                                      "white",
                                                                  }}
                                                                >
                                                                  <TableRow>
                                                                    <TableCell
                                                                      sx={{
                                                                        padding:
                                                                          "0 0.5rem 0 3.5rem",
                                                                        borderBottomColor:
                                                                          "primary.light",
                                                                        color:
                                                                          "primary.main",
                                                                        fontSize:
                                                                          "12px",
                                                                      }}
                                                                    >
                                                                      Parameter
                                                                    </TableCell>
                                                                    <TableCell
                                                                      sx={{
                                                                        padding:
                                                                          "0 0.5rem",
                                                                        borderBottomColor:
                                                                          "primary.light",
                                                                        color:
                                                                          "primary.main",
                                                                        fontSize:
                                                                          "12px",
                                                                        width:
                                                                          "10rem",
                                                                        textAlign:
                                                                          "right",
                                                                      }}
                                                                    >
                                                                      Value
                                                                    </TableCell>
                                                                    <TableCell
                                                                      sx={{
                                                                        padding:
                                                                          "0 0.5rem",
                                                                        borderBottomColor:
                                                                          "primary.light",
                                                                        color:
                                                                          "primary.main",
                                                                        fontSize:
                                                                          "12px",
                                                                        width:
                                                                          "5rem",
                                                                        textAlign:
                                                                          "right",
                                                                      }}
                                                                    >
                                                                      Unit
                                                                    </TableCell>
                                                                    <TableCell
                                                                      sx={{
                                                                        padding:
                                                                          "0 0.5rem",
                                                                        borderBottomColor:
                                                                          "primary.light",
                                                                        color:
                                                                          "primary.main",
                                                                        fontSize:
                                                                          "12px",
                                                                        width:
                                                                          "9rem",
                                                                        textAlign:
                                                                          "center",
                                                                      }}
                                                                    >
                                                                      Flags
                                                                    </TableCell>
                                                                  </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                  {calculatorDataProcess &&
                                                                  !accoLoaderProcessCalc ? (
                                                                    calculatorDataProcess?.map(
                                                                      (
                                                                        calcData: any,
                                                                        index: any
                                                                      ) => {
                                                                        return (
                                                                          <TableRow
                                                                          // style={
                                                                          //   calculatorDataProcess?.length -
                                                                          //     1 ===
                                                                          //   index
                                                                          //     ? {
                                                                          //         backgroundColor:
                                                                          //           "rgb(130, 202, 157)",
                                                                          //       }
                                                                          //     : {}
                                                                          // }
                                                                          >
                                                                            <TableCell
                                                                              sx={{
                                                                                display:
                                                                                  "flex",
                                                                                alignItems:
                                                                                  "center",
                                                                                columnGap:
                                                                                  "10px",
                                                                                padding:
                                                                                  "0.2rem 0.5rem 0.2rem 3.5rem",
                                                                                borderBottomColor:
                                                                                  "primary.light",
                                                                                textTransform:
                                                                                  "capitalize",
                                                                                fontSize:
                                                                                  "12px",
                                                                              }}
                                                                            >
                                                                              {
                                                                                calcData?.name
                                                                              }
                                                                              {getShowStatus(
                                                                                calcData.content_type
                                                                              ) && (
                                                                                <Box>
                                                                                  <InfoIcon
                                                                                    sx={{
                                                                                      fontSize:
                                                                                        "1rem",
                                                                                      color:
                                                                                        "#007fff",
                                                                                      cursor:
                                                                                        "pointer",
                                                                                    }}
                                                                                    onClick={() =>
                                                                                      detailsShow(
                                                                                        value,
                                                                                        calcData?.id,
                                                                                        calcData.content_type
                                                                                      )
                                                                                    }
                                                                                  />
                                                                                </Box>
                                                                              )}
                                                                            </TableCell>
                                                                            <TableCell
                                                                              sx={{
                                                                                padding:
                                                                                  "0.2rem 0.5rem",
                                                                                borderBottomColor:
                                                                                  "primary.light",
                                                                                fontSize:
                                                                                  "12px",
                                                                                width:
                                                                                  "10rem",
                                                                                textAlign:
                                                                                  "right",
                                                                              }}
                                                                            >
                                                                              {calcData?.value !==
                                                                                null &&
                                                                                `${
                                                                                  typeof calcData?.value ===
                                                                                  "number"
                                                                                    ? formatNumber(
                                                                                        calcData?.value
                                                                                      )
                                                                                    : calcData?.value
                                                                                }`}
                                                                            </TableCell>
                                                                            <TableCell
                                                                              sx={{
                                                                                padding:
                                                                                  "0.2rem 0.5rem",
                                                                                borderBottomColor:
                                                                                  "primary.light",
                                                                                fontSize:
                                                                                  "12px",
                                                                                width:
                                                                                  "5rem",
                                                                                textAlign:
                                                                                  "right",
                                                                              }}
                                                                            >
                                                                              {calcData?.unit
                                                                                ? calcData?.unit
                                                                                : ""}
                                                                            </TableCell>
                                                                            <TableCell
                                                                              sx={{
                                                                                padding:
                                                                                  "0.2rem 0.5rem",
                                                                                borderBottomColor:
                                                                                  "primary.light",
                                                                                fontSize:
                                                                                  "12px",
                                                                                width:
                                                                                  "9rem",
                                                                                textAlign:
                                                                                  "center",
                                                                              }}
                                                                            >
                                                                              <Box
                                                                                sx={{
                                                                                  display:
                                                                                    "flex",
                                                                                  alignItems:
                                                                                    "center",
                                                                                  justifyContent:
                                                                                    "center",
                                                                                  columnGap:
                                                                                    "10px",
                                                                                  lineHeight:
                                                                                    "1",
                                                                                }}
                                                                              >
                                                                                {calcData?.flag && (
                                                                                  <img
                                                                                    src={
                                                                                      flag
                                                                                    }
                                                                                    style={{
                                                                                      width:
                                                                                        "1.5rem",
                                                                                      cursor:
                                                                                        "pointer",
                                                                                    }}
                                                                                    onClick={() =>
                                                                                      window.open(
                                                                                        calcData?.flag,
                                                                                        "_blank",
                                                                                        " noopener"
                                                                                      )
                                                                                    }
                                                                                  />
                                                                                )}
                                                                                {calcData?.db_file && (
                                                                                  <img
                                                                                    title="Machine Cost"
                                                                                    src={
                                                                                      flagRed
                                                                                    }
                                                                                    style={{
                                                                                      width:
                                                                                        "1.5rem",
                                                                                      color:
                                                                                        "red",
                                                                                      cursor:
                                                                                        "pointer",
                                                                                    }}
                                                                                    onClick={() =>
                                                                                      window.open(
                                                                                        calcData?.db_file,
                                                                                        "_blank",
                                                                                        " noopener"
                                                                                      )
                                                                                    }
                                                                                  />
                                                                                )}
                                                                                {calcData?.db_spcification_file && (
                                                                                  <img
                                                                                    title="Machine Specification"
                                                                                    src={
                                                                                      flagRed
                                                                                    }
                                                                                    style={{
                                                                                      width:
                                                                                        "1.5rem",
                                                                                      color:
                                                                                        "red",
                                                                                      cursor:
                                                                                        "pointer",
                                                                                    }}
                                                                                    onClick={() =>
                                                                                      window.open(
                                                                                        calcData?.db_spcification_file,
                                                                                        "_blank",
                                                                                        " noopener"
                                                                                      )
                                                                                    }
                                                                                  />
                                                                                )}
                                                                              </Box>
                                                                            </TableCell>
                                                                          </TableRow>
                                                                        );
                                                                      }
                                                                    )
                                                                  ) : (
                                                                    <Typography
                                                                      variant="subtitle2"
                                                                      mt={1}
                                                                      align="center"
                                                                    >
                                                                      <Skeleton
                                                                        animation={
                                                                          false
                                                                        }
                                                                      />
                                                                    </Typography>
                                                                  )}
                                                                </TableBody>
                                                              </Table>
                                                            </Box>
                                                          </AccordionDetails>
                                                        </Accordion>
                                                      );
                                                    }
                                                  )
                                                ) : (
                                                  <Typography
                                                    variant="subtitle2"
                                                    mt={1}
                                                    align="center"
                                                  ></Typography>
                                                )}
                                              </AccordionDetails>
                                            </Accordion>
                                          </Box>
                                        );
                                      }
                                    )
                                  ) : (
                                    <Typography
                                      variant="subtitle2"
                                      mt={1}
                                      align="center"
                                    >
                                      <Skeleton animation={false} />
                                    </Typography>
                                  )}
                                </Box>
                              </AccordionDetails>
                            </Accordion>
                          );
                        })
                      ) : (
                        <Typography variant="subtitle2" mt={1} align="center">
                          <>
                            {" "}
                            <Skeleton animation={false} />
                            <Skeleton animation={false} />
                            <Skeleton animation={false} />
                          </>
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>

              {/* Overhead Accordian */}
              <Accordion
                expanded={expandedOverhead === "panel3"}
                onChange={handleChangeOverhead("panel3")}
                disabled={
                  parseFloat(parentdata?.overhead_cost) === 0 ? true : false
                }
              >
                <AccordionSummary
                  aria-controls="panel3d-content"
                  id="panel3d-header"
                  sx={{
                    minHeight: { lg: "26px", xl: "30px" },
                    padding: "0 0 0 0.5rem",
                    backgroundColor: "#d4d4d4",
                    margin: "-1px",
                  }}
                >
                  <Table>
                    {/* <TableHead>
                        <TableRow>
                          <TableCell colSpan={2} sx={{ borderBottom: 'none', padding: '0 0.5rem',fontSize:'12px',lineHeight:'1.2'  }}>
                            Overhead Cost
                          </TableCell>
                          <TableCell sx={{ borderBottom: 'none', padding: '0 0.5rem', textAlign: 'right',fontSize:'12px',lineHeight:'1.2'  }}>
                            {`Total Cost (${sessionStorage.getItem(
                              "currency"
                            )})`}
                          </TableCell>
                        </TableRow>
                      </TableHead> */}
                    <TableBody>
                      <TableRow>
                        <TableCell
                          sx={{
                            // backgroundColor: "primary.light",
                            color: "primary.main",
                            fontSize: "12px",
                            fontWeight: "500",
                          }}
                          className={styles.AccHeader}
                        >
                          Overheads
                        </TableCell>
                        <TableCell
                          sx={{
                            // backgroundColor: "primary.light",
                            color: "primary.main",
                            width: "3.5rem",
                            textAlign: "right",
                            fontSize: "12px",
                            fontWeight: "500",
                          }}
                          className={styles.AccHeader}
                        >
                          Qty.
                        </TableCell>
                        <TableCell
                          sx={{
                            // backgroundColor: "primary.light",
                            color: "primary.main",
                            fontSize: "12px",
                            textAlign: "right",
                            width: "5.5rem",
                            fontWeight: "500",
                          }}
                          className={styles.AccHeader}
                        >
                          Var/For
                        </TableCell>
                        <TableCell
                          sx={{
                            // backgroundColor: "primary.light",
                            color: "primary.main",
                            width: "9rem",
                            textAlign: "right",
                            fontSize: "12px",
                          }}
                          className={styles.AccHeader}
                        >
                          <Typography
                            style={{ fontSize: "12px", fontWeight: "500" }}
                          >
                            {parentdata &&
                            parentdata?.overhead_cost !== undefined ? (
                              parentdata?.overhead_cost + " " + "INR"
                            ) : (
                              <Skeleton animation={false} />
                            )}
                          </Typography>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </AccordionSummary>
                <AccordionDetails>
                  <Box>
                    <Box>
                      {overheadsData && !accoLoaderOverhead ? (
                        overheadsData.map((item: any, index: any) => {
                          return (
                            <Accordion
                              expanded={
                                expandedSubOverhead ===
                                item?.overhead_estimation_id
                              }
                              onChange={handleChangeSubOverhead(
                                item?.overhead_estimation_id
                              )}
                              sx={{ border: "none" }}
                            >
                              <AccordionSummary
                                aria-controls="panel2d-content"
                                id={item?.overhead_estimation_id}
                                sx={{
                                  minHeight: { lg: "26px", xl: "30px" },
                                  padding: "0 0 0 1rem",
                                  backgroundColor: "#dedede",
                                  margin: "-1px",
                                }}
                                onClick={() => subChildExpandOverhead(item)}
                              >
                                <Box sx={{ width: "100%" }}>
                                  <Table>
                                    <TableBody>
                                      <TableRow>
                                        <TableCell
                                          sx={{
                                            padding: "0 0.5rem",
                                            borderBottom: "none",
                                            fontSize: "12px",
                                          }}
                                        >
                                          {item?.subpart_name}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            padding: "0 0.5rem",
                                            borderBottom: "none",
                                            width: "3.5rem",
                                            textAlign: "right",
                                            fontSize: "12px",
                                          }}
                                        >
                                          {item?.quantity}
                                        </TableCell>
                                        <TableCell
                                          sx={{
                                            // backgroundColor: "primary.light",
                                            color: "primary.main",
                                            fontSize: "12px",
                                            textAlign: "right",
                                            width: "5.5rem",
                                            fontWeight: "500",
                                          }}
                                          className={styles.AccHeader}
                                        >
                                          {item?.cm_count &&
                                            item?.cm_count > 1 &&
                                            `(${item?.formula})`}
                                        </TableCell>

                                        <TableCell
                                          sx={{
                                            padding: "0 0.5rem",
                                            borderBottom: "none",
                                            textAlign: "right",
                                            width: "9rem",
                                            fontSize: "12px",
                                          }}
                                        >
                                          {`${item?.total_cost} INR`}
                                        </TableCell>
                                      </TableRow>
                                    </TableBody>
                                  </Table>
                                </Box>
                              </AccordionSummary>
                              <AccordionDetails>
                                {subChildDataOverhead &&
                                !accoLoaderOverheadSubChild ? (
                                  subChildDataOverhead?.map(
                                    (childData: any, i: any) => {
                                      return (
                                        <Box>
                                          <Accordion
                                            expanded={
                                              subGrandChild ===
                                              childData?.calculator_id
                                            }
                                            onChange={handleChangeSubAccgrandChild(
                                              childData?.calculator_id
                                            )}
                                            sx={{ border: "none" }}
                                          >
                                            <AccordionSummary
                                              aria-controls="panel2da-content"
                                              id={childData?.calculator_id}
                                              sx={{
                                                minHeight: {
                                                  lg: "26px",
                                                  xl: "30px",
                                                },
                                                padding: "0 0 0 0.5rem",
                                                paddingLeft: "2rem",
                                                backgroundColor: "#fafafa",
                                                margin: "-1px",
                                              }}
                                              onClick={() =>
                                                getCalculatorsDataOverhead(
                                                  childData
                                                )
                                              }
                                            >
                                              <Box
                                                sx={{
                                                  display: "flex",
                                                  justifyContent:
                                                    "space-between",
                                                  width: "100%",
                                                  fontSize: "12px",
                                                  paddingLeft: "0.5rem",
                                                }}
                                              >
                                                {/* {childData?.calculator_name}
                                                  <Typography
                                                    sx={{
                                                      fontSize: "12px",
                                                    }}
                                                  >
                                                    {`${
                                                      childData?.net_cost
                                                    } ${sessionStorage.getItem(
                                                      "currency"
                                                    )}`}
                                                  </Typography> */}
                                                <Table>
                                                  <TableBody>
                                                    <TableRow>
                                                      <TableCell
                                                        sx={{
                                                          padding: "0 0.5rem",
                                                          borderBottom: "none",
                                                          fontSize: "12px",
                                                        }}
                                                      >
                                                        {
                                                          childData?.calculator_name
                                                        }
                                                      </TableCell>
                                                      <TableCell
                                                        sx={{
                                                          padding: "0 0.5rem",
                                                          borderBottom: "none",
                                                          width: "3.5rem",
                                                          textAlign: "right",
                                                          fontSize: "12px",
                                                        }}
                                                      ></TableCell>
                                                      <TableCell
                                                        sx={{
                                                          // backgroundColor: "primary.light",
                                                          color: "primary.main",
                                                          fontSize: "12px",
                                                          textAlign: "right",
                                                          width: "5.5rem",
                                                          fontWeight: "500",
                                                        }}
                                                        className={
                                                          styles.AccHeader
                                                        }
                                                      >
                                                        {subChildDataOverhead &&
                                                          subChildDataOverhead?.length >
                                                            1 &&
                                                          `(${childData?.alias})`}
                                                      </TableCell>

                                                      <TableCell
                                                        sx={{
                                                          padding: "0 0.5rem",
                                                          borderBottom: "none",
                                                          textAlign: "right",
                                                          width: "9rem",
                                                          fontSize: "12px",
                                                        }}
                                                      >
                                                        {`${childData?.net_cost} INR`}
                                                      </TableCell>
                                                    </TableRow>
                                                  </TableBody>
                                                </Table>
                                              </Box>
                                            </AccordionSummary>
                                            <AccordionDetails>
                                              <Box
                                                sx={{
                                                  marginLeft: "0rem",
                                                }}
                                              >
                                                <Table>
                                                  <TableHead
                                                    sx={{
                                                      position: "sticky",
                                                      top: "0",
                                                      backgroundColor: "white",
                                                    }}
                                                  >
                                                    <TableRow>
                                                      <TableCell
                                                        sx={{
                                                          padding:
                                                            "0 0.5rem 0 3.5rem",
                                                          borderBottomColor:
                                                            "primary.light",
                                                          color: "primary.main",
                                                          fontSize: "12px",
                                                        }}
                                                      >
                                                        Parameter
                                                      </TableCell>
                                                      <TableCell
                                                        sx={{
                                                          padding: "0 0.5rem",
                                                          borderBottomColor:
                                                            "primary.light",
                                                          color: "primary.main",
                                                          fontSize: "12px",
                                                          textAlign: "right",
                                                          width: "10rem",
                                                        }}
                                                      >
                                                        Value
                                                      </TableCell>
                                                      <TableCell
                                                        sx={{
                                                          padding: "0 0.5rem",
                                                          borderBottomColor:
                                                            "primary.light",
                                                          color: "primary.main",
                                                          fontSize: "12px",
                                                          textAlign: "right",
                                                          width: "5rem",
                                                        }}
                                                      >
                                                        Unit
                                                      </TableCell>
                                                      <TableCell
                                                        sx={{
                                                          padding: "0 0.5rem",
                                                          borderBottomColor:
                                                            "primary.light",
                                                          color: "primary.main",
                                                          fontSize: "12px",
                                                          width: "9rem",
                                                          textAlign: "center",
                                                        }}
                                                      >
                                                        Flags
                                                      </TableCell>
                                                    </TableRow>
                                                  </TableHead>
                                                  <TableBody>
                                                    {calculatorDataOverhead &&
                                                    !accoLoaderOverheadCalc ? (
                                                      calculatorDataOverhead.map(
                                                        (
                                                          calcData: any,
                                                          index: any
                                                        ) => {
                                                          return (
                                                            <TableRow
                                                            // style={
                                                            //   calculatorDataOverhead?.name -
                                                            //     1 ===
                                                            //   index
                                                            //     ? {
                                                            //         backgroundColor:
                                                            //           "rgb(130, 202, 157)",
                                                            //       }
                                                            //     : {}
                                                            // }
                                                            >
                                                              <TableCell
                                                                sx={{
                                                                  padding:
                                                                    "0.2rem 0.5rem 0.2rem 3.5rem",
                                                                  borderBottomColor:
                                                                    "primary.light",
                                                                  fontSize:
                                                                    "12px",
                                                                }}
                                                              >
                                                                {calcData?.name}
                                                              </TableCell>
                                                              <TableCell
                                                                sx={{
                                                                  padding:
                                                                    "0.2rem 0.5rem",
                                                                  borderBottomColor:
                                                                    "primary.light",
                                                                  textAlign:
                                                                    "right",
                                                                  width:
                                                                    "10rem",
                                                                  fontSize:
                                                                    "12px",
                                                                }}
                                                              >
                                                                {calcData?.value !==
                                                                  null &&
                                                                  `${
                                                                    typeof calcData?.value ===
                                                                    "number"
                                                                      ? formatNumber(
                                                                          calcData?.value
                                                                        )
                                                                      : calcData?.value
                                                                  } `}
                                                              </TableCell>
                                                              <TableCell
                                                                sx={{
                                                                  padding:
                                                                    "0.2rem 0.5rem",
                                                                  borderBottomColor:
                                                                    "primary.light",
                                                                  textAlign:
                                                                    "right",
                                                                  width: "5rem",
                                                                  fontSize:
                                                                    "12px",
                                                                }}
                                                              >
                                                                {calcData?.unit
                                                                  ? calcData?.unit
                                                                  : ""}
                                                              </TableCell>
                                                              <TableCell
                                                                sx={{
                                                                  padding:
                                                                    "0.2rem 0.5rem",
                                                                  borderBottomColor:
                                                                    "primary.light",
                                                                  fontSize:
                                                                    "12px",
                                                                  width: "9rem",
                                                                  textAlign:
                                                                    "center",
                                                                }}
                                                              >
                                                                <Box
                                                                  sx={{
                                                                    display:
                                                                      "flex",
                                                                    alignItems:
                                                                      "center",
                                                                    justifyContent:
                                                                      "center",
                                                                    columnGap:
                                                                      "10px",
                                                                    lineHeight:
                                                                      "1",
                                                                  }}
                                                                >
                                                                  {calcData?.flag && (
                                                                    <img
                                                                      src={flag}
                                                                      style={{
                                                                        width:
                                                                          "1.5rem",
                                                                      }}
                                                                    />
                                                                  )}
                                                                  {calcData?.db_file && (
                                                                    <img
                                                                      title="Machine Cost"
                                                                      src={
                                                                        flagRed
                                                                      }
                                                                      style={{
                                                                        width:
                                                                          "1.5rem",
                                                                        color:
                                                                          "red",
                                                                      }}
                                                                    />
                                                                  )}
                                                                  {calcData?.db_spcification_file && (
                                                                    <img
                                                                      title="Machine Specification"
                                                                      src={
                                                                        flagRed
                                                                      }
                                                                      style={{
                                                                        width:
                                                                          "1.5rem",
                                                                        color:
                                                                          "red",
                                                                      }}
                                                                    />
                                                                  )}
                                                                </Box>
                                                              </TableCell>
                                                            </TableRow>
                                                          );
                                                        }
                                                      )
                                                    ) : (
                                                      <Typography
                                                        variant="subtitle2"
                                                        mt={1}
                                                        align="center"
                                                      >
                                                        <Skeleton
                                                          animation={false}
                                                        />
                                                      </Typography>
                                                    )}
                                                  </TableBody>
                                                </Table>
                                              </Box>
                                            </AccordionDetails>
                                          </Accordion>
                                        </Box>
                                      );
                                    }
                                  )
                                ) : (
                                  <Typography
                                    variant="subtitle2"
                                    mt={1}
                                    align="center"
                                  >
                                    <Skeleton animation={false} />
                                  </Typography>
                                )}
                              </AccordionDetails>
                            </Accordion>
                          );
                        })
                      ) : (
                        <Typography variant="subtitle2" mt={1} align="center">
                          <>
                            {" "}
                            <Skeleton animation={false} />
                            <Skeleton animation={false} />
                            <Skeleton animation={false} />
                          </>
                        </Typography>
                      )}
                    </Box>
                  </Box>
                </AccordionDetails>
              </Accordion>

              {/* ChildCost Accordian */}
              {/* parentdata?. */}
              {parentdata?.has_child_part && (
                <Accordion
                  expanded={expandedChildData === "panel4"}
                  onChange={handleChangeChildCost("panel4")}
                >
                  <AccordionSummary
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                    sx={{
                      minHeight: { lg: "26px", xl: "30px" },
                      display: "flex",
                      justifyContent: "space-around",
                      padding: "0 0 0 0.5rem",
                      backgroundColor: "#ffa781",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell
                              sx={{
                                // backgroundColor: "primary.light",
                                color: "#333333",
                                fontSize: "12px",
                                padding: "0 0.5rem",
                              }}
                              className={styles.AccHeader}
                            >
                              Child Part
                            </TableCell>
                            <TableCell
                              sx={{
                                // backgroundColor: "primary.light",
                                color: "#333333",
                                fontSize: "12px",
                                width: "3.5rem",
                                textAlign: "right",
                                padding: "0 0.5rem",
                              }}
                              className={styles.AccHeader}
                            >
                              Qty.
                            </TableCell>
                            <TableCell
                              sx={{
                                // backgroundColor: "primary.light",
                                color: "#333333",
                                fontSize: "12px",
                                width: "9rem",
                                textAlign: "right",
                                padding: "0 0.5rem",
                              }}
                              className={styles.AccHeader}
                            >
                              <Typography
                                style={{ fontSize: "12px", fontWeight: "500" }}
                              >
                                {parentdata &&
                                !isUndefined(parentdata?.material_cost) ? (
                                  parentdata?.child_part_cost + " " + "INR"
                                ) : (
                                  <Skeleton animation={false} />
                                )}
                              </Typography>
                              {/* {`Total Cost ${sessionStorage.getItem("currency")}`} */}
                            </TableCell>
                          </TableRow>
                        </TableHead>
                      </Table>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Box>
                      {/* {rawMaterialData && rawMaterialData?.length > 0 && ( */}
                      <Table>
                        {/* <TableHead>
                          <TableRow>
                            <TableCell
                              sx={{
                                backgroundColor: "primary.light",
                                color: "primary.main",
                                fontSize: "12px",
                                padding:'0 0.5rem',
                              }}
                              className={styles.AccHeader}
                            >
                              Part Number
                            </TableCell>
                            <TableCell
                              sx={{
                                backgroundColor: "primary.light",
                                color: "primary.main",
                                fontSize: "12px",
                                width: "5rem",
                                textAlign:'right',
                                padding:'0 0.5rem',
                              }}
                              className={styles.AccHeader}
                            >
                              Quantity
                            </TableCell>
                            <TableCell
                              sx={{
                                backgroundColor: "primary.light",
                                color: "primary.main",
                                fontSize: "12px",
                                width: "9rem",
                                textAlign:'right',
                                padding:'0 0.5rem',
                              }}
                              className={styles.AccHeader}
                            >
                              {`Total Cost ${sessionStorage.getItem("currency")}`}
                            </TableCell>
                          </TableRow>
                        </TableHead> */}
                        <TableBody>
                          {childCostData && !accoLoderChildCost ? (
                            childCostData.map((item: any) => {
                              return (
                                <TableRow>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                      fontSize: "12px",
                                      // width: "9rem",
                                      textAlign: "left",
                                      cursor: "pointer",
                                      textDecoration: "underline",
                                    }}
                                    onClick={() =>
                                      setRoute(
                                        item.vault,
                                        item.abbreviation,
                                        item.ancestors
                                      )
                                    }
                                  >
                                    {`${item.part_no} (${item?.vault_name})`}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                      fontSize: "12px",
                                      width: "3.5rem",
                                      textAlign: "right",
                                    }}
                                  >
                                    {item?.quantity}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                      fontSize: "12px",
                                      width: "9rem",
                                      textAlign: "right",
                                    }}
                                  >
                                    {`${item?.total_cost} INR`}
                                  </TableCell>
                                </TableRow>
                              );
                            })
                          ) : (
                            <Typography align="center" mt={2}>
                              <>
                                {" "}
                                <Skeleton animation={false} />
                                <Skeleton animation={false} />
                                <Skeleton animation={false} />
                              </>
                            </Typography>
                          )}
                        </TableBody>
                      </Table>
                      {/* )} */}
                    </Box>
                  </AccordionDetails>
                </Accordion>
              )}
            </Box>
          </Box>
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            margin: "0 auto",
            padding: "0 1rem",
          }}
        >
          <Skeleton sx={{ width: "100%", height: "3rem" }} />
          <Skeleton sx={{ width: "100%", height: "3rem" }} />
          <Skeleton sx={{ width: "100%", height: "3rem" }} />
          <Skeleton sx={{ width: "100%", height: "3rem" }} />
          <Skeleton sx={{ width: "100%", height: "3rem" }} />
          <Skeleton sx={{ width: "100%", height: "3rem" }} />
          <Skeleton sx={{ width: "100%", height: "3rem" }} />
          <Skeleton sx={{ width: "100%", height: "3rem" }} />
          <Skeleton sx={{ width: "100%", height: "3rem" }} />
          <Skeleton sx={{ width: "100%", height: "3rem" }} />
          <Skeleton sx={{ width: "100%", height: "3rem" }} />
        </Box>
      )}
      <AssumptionModal
        isOpen={AssumptionCardModal.isOpen}
        onClose={AssumptionCardModal.close}
      />
      <InformationModal
        isOpen={informationModal.isOpen}
        onClose={informationModal.close}
        tableData={informationModal.propsId}
      />
    </div>
  );
}
