import { API } from "./index.service";
import { GetArchRepoImagesHeaderDataArg, GetRepoArchArg } from "../Actions/archRepository.action";
import { isArray } from "lodash";
import { ID } from "../../utlis/commonInterfaces";

// -------------------------------------------------------------------------------------

export default {
    get_dataImage ( arg: GetRepoArchArg ) {
        return API.get(`/circuit_diagram/arch_new_ui/`, { project: arg.project, top_vault: arg.topVault, abbreviation: arg.abbreviation, categories: 3, module: arg.module, row: arg.row, page: arg.page,group:arg?.group },0);
    },
    get_infoImage ( arg: GetArchRepoImagesHeaderDataArg ) {
        return API.get(`/circuit_diagram/arch_details_new_ui/`,{ top_vault: arg.topVault, abbreviation: arg.abbreviation, categories: 3, module: arg.module },0);
    },
    downloadbatch (topVaultId: ID | ID[], abbreviation: string | number, range: any, GrpId: any, category: any) {
        return API.get("circuit_diagram/download/", {
            top_vault: isArray(topVaultId) ? topVaultId.join() : topVaultId,
            categories: category,
            abbreviation: abbreviation || undefined,
            range: isArray(range) ? range.join() : range,
            group: GrpId
        }, 0);
    }
};
