import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { Autocomplete, Checkbox, Chip, Divider, FormControl, Input, Link, TextField } from "@mui/material";
import useFetch from "../../Core/CustomHooks/useFetch";
import reportsbank from "../../Redux/Services/reportsBank.service";
import { isNull, isUndefined, transform } from "lodash";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import { API } from "../../api-services";
import { EastRounded, Preview } from "@mui/icons-material";
import MenuItem from '@mui/material/MenuItem';
import LoadingButton from '@mui/lab/LoadingButton';
import CancelIcon from '@mui/icons-material/Cancel';
import dayjs, { Dayjs } from 'dayjs';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import moment from "moment";
import { useBaseModal } from "../SearchPage/useBaseModal";
import ReportsCreateModal from "./ReportsCreateModal";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "25%",
  minWidth:'20rem',
  borderRadius: '1rem',
  bgcolor: "background.paper",
  // border: "2px solid #000",
  //   boxShadow: 24,
  padding: "0 1rem 1rem",
};
const styleError = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "25%",
  minWidth: "20rem",

  borderRadius: '1rem',
  bgcolor: "background.paper",
  // border: "2px solid #000",
  //   boxShadow: 24,
  padding: " 1rem 1rem",
};
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
export interface ShareReportsProps {
  Open: any;
  isClose: any;
  ProjectIdData: any;
  reprtsType: any;
  fileId: any;
  SelectedDataValue: any
  selectedUserNamesId?: any;
  setCounters?: any;
}

const MoveToDriveModal = (props: ShareReportsProps) => {
  const {
    Open,
    isClose,
    ProjectIdData,
    reprtsType,
    fileId,
    SelectedDataValue,

  } = props;

  const [value, setValue] = React.useState<Dayjs | null>(
    dayjs('2014-08-18T21:11:54'),
  );

  const [selecteData, setSelectedData] = React.useState();

  const [CreateName,setCreateName]=React.useState<any>()
  const [descriptionData, setDescriptionData] = React.useState();
  const [Errormsg, setErrormsg] = React.useState<any>(null)
  const [MoveErrormsg, setMoveErrormsgtErrormsg] = React.useState<any>(false)
  const [UserErrormsg, setUserErrormsg] = React.useState<any>(false)
  const [loader, setLoader] = useState<any>(false)
  const [Counter, setCounter] = useState<any>(0)
  const [UsersName, setUsersName] = useState<any>([])
  const [successfulMsg, setsuccessfulMsg] = useState<any>(false)
  const [selectFromDateRange, setSelectFromDateRange] = useState<any>();
  const [UpdatedDate, setUpdatedDate] = useState<any>()
   const createModaldata=useBaseModal();
  // @ts-ignore
  // var {0: fetched, 1: UsersName,2:setUsersName} = useFetch({
  //   method: reportsbank.getAllUsersMove,
  //   args: [ProjectIdData],
  //   fallBackErrMsg: "Could not fetch projects",

  //   condition:!isUndefined(fileId),

  // });





  var today: any = new Date();
  var dd: number | string = today.getDate();
  var mm: number | string = today.getMonth() + 1;
  var yyyy = today.getFullYear();
  // @ts-ignore
  if (dd < 10) {
    dd = "0" + dd;
  }
  // @ts-ignore
  if (mm < 10) {
    mm = "0" + mm;
  }
  today = yyyy + "-" + mm + "-" + dd;
  useEffect(() => {
    if (!isUndefined(fileId)) {
      API.get("/customer/get_drive_groups/", { group_type: "created", project: ProjectIdData }, 0).then((res: any) => {
        setUsersName(res.data)
      })
    }
  }, [fileId])



  const DateHandler = (newValue: any) => {



    const changesDate = moment(newValue).format("YYYY-MM-DD");
    setSelectFromDateRange(changesDate)
  
  }

  const handleChange = (e: any) => {
    if (e?.target?.value == 0) {
      createModaldata.open()
      setCreateName(null)
      API.post("/drive/group/", { name: "", project: ProjectIdData }).then((res1: any) => {
        setSelectedData(res1?.data?.id);
         setCreateName(res1?.data?.name)
        API.get("/customer/get_drive_groups/", { group_type: "created", project: ProjectIdData }).then((res: any) => {
          // @ts-ignore

          setUsersName(res.data)
        })

      })
    }
    else {
      setSelectedData(e?.target?.value);
      setMoveErrormsgtErrormsg(false)
      setErrormsg(null)
    }

  };

  const descriptionHandler = (e: any) => {
    setUserErrormsg(false)
    setDescriptionData(e.target.value);
    setErrormsg(null)
  }

  useEffect(() => {
    // @ts-ignore
    setSelectedData(null)
    // @ts-ignore
    setDescriptionData("")
    setErrormsg(null)
    setsuccessfulMsg(false)
  }, [Open])

  const SaveHandler = () => {
    if (selecteData) {
      setLoader(true)
      setsuccessfulMsg(false)
      API.post("/drive/report/", {
        client: false,
        description: descriptionData,
        group: selecteData,
        project: Number(ProjectIdData),
        report_id: fileId,
        expiry_date: selectFromDateRange


      }, {}, 0).then(
        (res: any) => {

          setLoader(false)
          setsuccessfulMsg(true)
          // setCounters((prev: any) => prev + 1);
          // isClose();
        }
      ).catch((err: any) => {
        const { data } = err.response;
        setLoader(false)
        setsuccessfulMsg(false)
        // isClose();
        setErrormsg(data && data[0])

      })
    } else {
      setMoveErrormsgtErrormsg(true)
      setUserErrormsg(true)
    }
  };


 

  var data = { active: 0, created_by_user: 0, expired: 0, id: 0, name: "Create_Folder", project: ProjectIdData, total_files: 0 }
  var FilterData = [data, ...UsersName]



  //   {Errormsg == null ? "" :
  //   <Typography id="modal-modal-title" style={{ fontSize: '0.75rem', color: "red" }}>
  //     {Errormsg}

  //   </Typography>
  // }

  return (
    <Box>
      <Modal
        open={Open}
        onClose={isClose}
      // aria-labelledby="modal-modal-title"
      // aria-describedby="modal-modal-description"      
      >

       
<Box>
        {Errormsg == null ?
          <Box sx={style}>
            <Box>

              <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
                <Typography
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    width: "100%",
                    textAlign: "center",
                    marginRight: '-2rem'
                  }}
                >
                  Copy to Drive
                </Typography>
                <CancelIcon titleAccess="Close" onClick={isClose} sx={{ cursor: 'pointer', margin: '0.5rem', marginRight: '-1rem' }} />
              </Box>
              <Divider
                sx={{
                  borderColor: "primary.light",
                  margin: "0 1rem",
                  marginBottom: "1rem",
                }}
              />
              {successfulMsg ?
                <Box>

                  <Typography id="modal-modal-title" style={{ fontSize: '1rem', color: "green" }}>
                    Your downloaded file has been moved



                    <Link
                      component="button"
                      variant="body2"
                      onClick={() => {
                        window.open(`/#/drive/${ProjectIdData}/my/${selecteData}`, "_blank");
                      }}
                    >



                    </Link> in drive.
                  </Typography>
                </Box>
                : <Box>
                  <Box sx={{ marginBottom: '0.5rem' }}>
                    <Box id="modal-modal-title" style={{ fontSize: '1rem', display: 'flex', flexDirection:'column', columnGap: '10px', }}>
                      <Typography style={{ fontSize: '12px', width: '8rem', }} >Expiry Date* :</Typography>
                      <LocalizationProvider variant="standard" dateAdapter={AdapterMoment}>
                        <DesktopDatePicker
                          // label="Expiry *"
                          value={selectFromDateRange}
                          inputFormat="DD/MM/YYYY"
                          minDate={new Date()}                          
                          onChange={(newValue) => DateHandler(newValue)}
                          renderInput={(params) => <TextField {...params} variant="standard" sx={{
                            width: "100%",
                            "& .MuiFormLabel-root": {
                              color: 'primary.main'
                            },
                            "& .MuiFormLabel-root.Mui-focused": {
                              color: 'primary.main'
                            },
                            ".MuiSvgIcon-root ": {
                              fill: "#007fff !important",
                            },   
                            "& .Mui-error": {
                              color: '#d32f2f',
                              borderBottomColor: '#d32f2f',
                            },
                            '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                            '& .MuiInput-underline:after': { borderBottomColor: 'primary.light' },
                          }} />}
                        />
                      </LocalizationProvider>
                      {/* <Typography style={{ fontSize: '1rem', }}> {item?.deletion_date}</Typography> */}
                    </Box>
                  </Box>
                  {SelectedDataValue && SelectedDataValue.map((item: any, index: any) => {
                    return (<>

                      <Box sx={{ marginBottom: '0.5rem', display: 'flex',flexDirection:'column', columnGap: '10px' }}>
                        <Typography id="modal-modal-title" style={{ fontSize: '12px', width: '8rem' }}>
                          View to Report :
                        </Typography>
                        <Link
                          component="button"
                          variant="body2"
                          onClick={() => {
                            window.open(item?.file_path, "_blank");
                          }}
                          sx={{
                            textAlign:'left'
                          }}
                        >
                          {item?.name}
                        </Link>
                      </Box>
                    </>)
                  })}



                  {/* {SelectedDataValue && SelectedDataValue.map((item: any, index: any) => {
                return (<>

                  <TextField
                    id="standard-multiline-flexible"
                    variant="standard"
                    value={item?.deletion_date}
                    onChange={descriptionHandler}
                  />
                  <Typography id="modal-modal-title" style={{ fontSize: '0.75rem' }}>
                    View for report
                  </Typography>
                  <Link
                    component="button"
                    variant="body2"
                    onClick={() => {
                      window.open(item?.file_path, "_blank");
                    }}
                  >
                    {item?.name}
                  </Link>

                </>)
              })} */}

                  <Typography id="modal-modal-title" style={{ fontSize: '12px' }}>
                    Select Folder to Copy
                  </Typography>
                  <FormControl variant="standard" fullWidth>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={selecteData}
                      onChange={handleChange}
                      sx={{
                        '& .MuiSvgIcon-root': {
                          color: 'primary.main'
                        },
                        "& .MuiInputBase-root .MuiInput-root .MuiSelect-root:before": {
                          borderBottomColor: 'primary.light !important',
                        }
                      }}
                    >
                      {FilterData && FilterData?.length > 0 &&
                        FilterData?.map((option: any) => (

                          <MenuItem key={option.key} value={option?.id}>
                            {option.name == "Create_Folder" ? <>
                              {/* <Button title="Create New Folder" variant="contained" size="small" sx={{ cursor: 'pointer' }}> */}
                              {"Create New Folder"}
                            
                              {/* </Button> */}
                            </> : `${option?.name}`}
                          </MenuItem>
                        ))}

                    </Select>
                    {MoveErrormsg ? <Typography style={{ color: 'red' }}>*Please select drive folder</Typography> : ""}
                  </FormControl>
                  <Typography id="modal-modal-title" style={{ fontSize: '0.75rem', marginTop: '1rem' }}>
                    Description
                  </Typography>
                  <FormControl fullWidth>
                    <TextField
                      id="standard-multiline-flexible"
                      sx={{
                        '& .MuiInput-underline:before': { borderColor: 'primary.light' },
                        '& .MuiInput-underline:after': { borderColor: 'primary.light' },
                      }}
                      variant='standard'
                      value={descriptionData}
                      onChange={descriptionHandler}
                    />


                    {MoveErrormsg ? <Typography style={{ color: 'red' }}>*Please write description</Typography> : ""}
                  </FormControl>





                  <Box
                    sx={{
                      display: "flex",
                      columnGap: "10px",
                      justifyContent: "flex-end",
                      width: "100%",
                      marginTop: "0.5rem",
                    }}
                  >
                    {/* <Button
              sx={{
                "&:hover": {
                  transform: "scale(1.1)",
                  transition: "transform 0.5s ease",
                },
              }}
              size="small"
              variant="contained"
              onClick={() => SaveHandler()}
            > */}


                    <LoadingButton
                      loading={loader}
                      loadingPosition="center"
                      sx={{
                        "&:hover": {
                          transform: "scale(1.1)",
                          transition: "transform 0.5s ease",
                        },
                      }}
                      size="small"
                      variant="contained"
                      onClick={() => SaveHandler()}

                    >
                      Copy to drive
                    </LoadingButton>
                    {/* </Button> */}
                    {/* <Button
                  sx={{
                    "&:hover": {
                      transform: "scale(1.1)",
                      transition: "transform 0.5s ease",
                    },
                  }}
                  size="small"
                  variant="contained" onClick={() => isClose()}>

                  Cancel
                </Button> */}

                  </Box>
                </Box>
              }

            </Box>
          </Box> :


          <Box sx={styleError}>
            <Typography id="modal-modal-title" style={{ fontSize: '1rem', color: "red" }}>
              {Errormsg}
            </Typography>
          </Box>
        }
      

        <ReportsCreateModal
         CreateName={CreateName}
         setCreateName={setCreateName}
        Open={createModaldata.isOpen}
        isClose={createModaldata.close}
        selecteData={selecteData}
        setUsersName={setUsersName}
        ProjectIdData={ProjectIdData}
        />
       </Box>
      </Modal>
    </Box>
  );
};
export default MoveToDriveModal;
