import { createEntityAdapter, createReducer, EntityState } from "@reduxjs/toolkit";


import { ADMIN } from "../Services/admin.service";
import { RootState } from "../Store/storeConfigurations";
import { getTriangulationData, resetTriangulation, updateTriangulation } from "../Actions/triangulation.action";
import { ID } from "../../utlis/commonInterfaces";


// -----------------------------------------------------------------------------------
// entity adaptor

export interface Triangulation {
    id: ID;
    asi_ex_showroom_cost: number;
    asi_manufacturing_cost: number;
    image: string[];
    inference: string;
    oem_ex_showroom_cost: number;
    oem_manufacturing_cost: number;
    top_vault: number;
    allowed_action: boolean;
}

const adaptor = createEntityAdapter<Triangulation>( {
    selectId: ( triangulation ) => triangulation.id,
    // sortComparer: ( a, b ) => 
} );

// -----------------------------------------------------------------------------------
// interfaces & initialState

type AdditionalFields = {};

type IReducer = EntityState<Triangulation> & AdditionalFields;

const additionalFields: AdditionalFields = {};

const initialState: IReducer = Object.assign( {}, adaptor.getInitialState(), additionalFields );

// -----------------------------------------------------------------------------------
// reducer

const TriangulationReducer = createReducer( initialState, builder => {

    builder
        .addCase( getTriangulationData.fulfilled, ( state, action: any ) => {
            adaptor.setAll( state, action.payload.triangulation );
        } )
        .addCase( getTriangulationData.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could no fetch variants" );
        } );

    builder
        .addCase( updateTriangulation.fulfilled, ( state, action: any ) => {
            adaptor.upsertOne( state, action.payload.triangulation );

            // ADMIN.toast.success( "Inference Updated" );
        } )
        .addCase( updateTriangulation.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could not update Inference" );
        } );

    builder.
        addCase( resetTriangulation, () => initialState );
} );

// -----------------------------------------------------------------------------------
// selectors

const triangulationSelectors = adaptor.getSelectors<RootState>( ( state ) => state.triangulation );

// -----------------------------------------------------------------------------------
// exports

export {
    TriangulationReducer,
    initialState as TiangulationReducerInit,
    triangulationSelectors
};