import { PAGE_TITLE } from '../Constants/general.constants'
import { useEffect } from "react";

// ----------------------------------------------------------------------------------

const useDocumentTitle = ( title: string ) => {
    useEffect( () => {
        document.title = `${title} | ${PAGE_TITLE}`;
        return () => {
            document.title = PAGE_TITLE;
        };
    }, [] );
};

// ----------------------------------------------------------------------------------

export { useDocumentTitle };
