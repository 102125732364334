import React, { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import TreeView from "@mui/lab/TreeView";
import TreeItem, { TreeItemProps, treeItemClasses } from "@mui/lab/TreeItem";
import Collapse from "@mui/material/Collapse";
// web.cjs is required for IE11 support

import { TransitionProps } from "@mui/material/transitions";
import { animated, useSpring } from "@react-spring/web";

// src/Components/Assets/images/svgs/openfolderblue.svg
// openfolderblue.svg
import openFolder from "../../../Assets/images/svgs/openfolderblue.svg";
import FolderIcon from "@mui/icons-material/Folder";
import styles from "../../../ViewModule/Comparefolder/CompareTreeViewcomponents.module.scss";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import calculator from "../../../Assets/images/calculator.svg";

// import { API } from "../../api-services";
import { Box, Typography } from "@mui/material";
import { ControlledMenu, MenuItem } from "@szhsin/react-menu";
import "@szhsin/react-menu/dist/index.css";

import DataSaverOnIcon from "@mui/icons-material/DataSaverOn";
import DeleteIcon from "@mui/icons-material/Delete";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import InfoIcon from "@mui/icons-material/Info";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { useBaseModal } from "../../SearchPage/useBaseModal";
import { useBaseParamsModal } from "../../../CustomHook/useBaseParamsModal";
import { API } from "../../../api-services";
import CostingConfigurationTreeViewcomponents from "../../../Configuration/Costing/CostingTreeViewComponents";
import AddProcessModal from "../../../Configuration/Costing/AddProcessModal";
import CheckIcon from "@mui/icons-material/Check";
import { useDispatch, useSelector } from "react-redux";
import { selectedCalculators } from "../../../Redux/Actions/compareopen.action";

function TransitionComponent(props: TransitionProps) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: "translate3d(20px,0,0)",
      display: "none",
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
      display: "block",
    },
  });

  return (
    <animated.div style={style} className="Tempdekho">
      <Collapse {...props} />
    </animated.div>
  );
}

const StyledTreeItem = styled((props: TreeItemProps) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 10,
    paddingLeft: 8,
    borderLeft: `1px dashed ${theme.palette.text.primary}`,
  },
}));

interface CostingTreeViewcomponentsProps {
  HierarchyData: any;
  onSelectCallback: any;
  setSelectedTreeData?: any;
  setCounterApiCall?: any;
  selectedTreeData?: any;
  setSingleSelectedTreeData?: any;
  singleSelectedTreeData?: any;
  commonCalc?: any;
  autoTreeOpenIds?:any
}
const CalculatorListTree = (props: CostingTreeViewcomponentsProps) => {
  const {
    HierarchyData,
    onSelectCallback,
    setSelectedTreeData,
    setCounterApiCall,
    selectedTreeData,
    setSingleSelectedTreeData,
    singleSelectedTreeData,
    commonCalc,
  } = props;
  const dispatch = useDispatch();
  const store: any = useSelector((state) => state);
  const { selectedIdTree, projectname, projectId } = useRouteParams<any>();
  const infoModal = useBaseModal();
  const history = useHistory();
  const { url } = useRouteMatch();
  const [activeId, setActiveId] = React.useState<any>(null);
  const [HierarchyAllData, setHierarchyAllData] = React.useState<any>(null);
  const [loader, setLoader] = React.useState<any>(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [isOpen, setOpen] = useState<any>(false);
  const [selectedCommodity, setSelectedCommodity] = React.useState<any>(null);
  const [rightClickedId, setRightClickedId] = React.useState<any>("");
  const [anchorPoint, setAnchorPoint] = useState<any>({ x: 0, y: 0 });
  const open = Boolean(anchorEl);

  // @ts-ignore
  const [expanded, setExpanded] = React.useState<any>([
    parseInt(selectedIdTree),
  ]);
  

  //   React.useEffect(() => {
  //     if (store?.is_compare?.selcetedCalculators) {
  //       setSelectedTreeData(store?.is_compare?.selcetedCalculators);
  //     }
  //   }, [store?.is_compare?.selcetedCalculators]);
  const [selected, setSelected] = React.useState<any>(selectedIdTree);

  const addProcess = useBaseParamsModal();

  const handleChange = (
    nodeId: any,
    id: any,
    HierarchyAllData: any,
    itemId: any
  ) => {
    onSelectCallback(nodeId, id, HierarchyAllData, itemId);
    if (HierarchyAllData && HierarchyAllData?.type === "calculator") {
      // setSelectedTreeData((prev: any) => [...prev, HierarchyAllData]);
      if (store?.is_compare?.selcetedCalculators.includes(HierarchyAllData)) {
        setSelectedTreeData(
          [...store?.is_compare?.selcetedCalculators].filter(
            (item: any) => item !== HierarchyAllData
          )
        );
        dispatch(
          selectedCalculators(
            [...store?.is_compare?.selcetedCalculators].filter(
              (item: any) => item !== HierarchyAllData
            )
          )
        );
      } else {
        dispatch(selectedCalculators([...store?.is_compare?.selcetedCalculators, HierarchyAllData]));
        setSingleSelectedTreeData([...[HierarchyAllData]]);
        setSelectedTreeData((prev: any) => [...prev, HierarchyAllData]);
      }
    } else {
      // setSelectedTreeData([]);
      // setSingleSelectedTreeData([]);
    }
    let a: any = sessionStorage.getItem("CostingCreateCalc");
    let val: any = JSON.parse(a) || [];
    if (val?.includes(id)) {
      let noDuplicate: any = val?.filter((item: any) => {
        return item != id;
      });
    } else {
      val?.push(id);
    }

    setActiveId(id);

    const index = expanded.indexOf(id);

    const copyExpanded = [...expanded];
    if (index === -1) {
      copyExpanded?.push(id);
    } else {
      copyExpanded?.splice(index, 1);
    }

    setExpanded(copyExpanded);
    setSelected(copyExpanded);
  };

  useEffect(() => {
    let a: any = sessionStorage.getItem("CostingCreateCalc");
    let val: any = JSON.parse(a) || [];
    if (val != null) {
      setExpanded(val);
      setSelected(val);
    }
  }, [selectedIdTree, sessionStorage.getItem("CostingCreateCalc")]);
  //@ts-ignore
  const menuItemClassName = ({ hover }) =>
    hover ? "my-menuitem-hover" : "my-menuitem";
  useEffect(() => {
    setHierarchyAllData(HierarchyData && HierarchyData);
  }, [HierarchyData]);

  return (
    <>
      <ControlledMenu
        anchorPoint={anchorPoint}
        state={isOpen ? "open" : "closed"}
        direction="top"
        // align="center"
        position="anchor"
        transition
        // arrow={true}
        menuClassName="my-menu"
        onClose={() => {
          // e.stopPropagation()
          setOpen(false);
        }}
        onClick={(e: any) => {
          e.stopPropagation();
        }}
        // onSelect={(ed:any)=>{

        // }}
      >
        {HierarchyAllData?.type === "commodity" && (
          <MenuItem
            value={"Process"}
            className={menuItemClassName}
            disabled={
              !sessionStorage.getItem("ConfigPermission")?.includes("C")
            }
            onClick={() => {
              setSelectedCommodity(HierarchyAllData.id);
              addProcess.open(HierarchyAllData.id);
            }}
          >
            <DataSaverOnIcon
              sx={{ cursor: "pointer", marginRight: "0.5rem" }}
            />
            Add Process
          </MenuItem>
        )}
        {HierarchyAllData?.type === "sub_calculator_cat" && (
          <MenuItem
            value={"Process"}
            className={menuItemClassName}
            disabled={
              !sessionStorage.getItem("ConfigPermission")?.includes("C")
            }
            onClick={() => {
              API.post(
                "/cost/calculator/",
                {
                  name: "calculator1",
                  process: HierarchyAllData?.process,
                  commodity_mapping: HierarchyAllData?.commodity,
                  result: "",
                  category: HierarchyAllData.id,
                },
                {},
                0
              )
                .then((res: any) => {
                  history.push(
                    `/costingconfiguration/${projectId}/${projectname}/${
                      res.data.id
                    }/${"calculator"}`
                  );
                  setCounterApiCall((prev: any) => prev + 1);
                  
                })
                .catch(() => {});
            }}
          >
            <img
              src={calculator}
              style={{
                width: "13px",
                color: "#007fff",
                cursor: "pointer",
                marginRight: "0.5rem",
              }}
            />
            Add Cost model
          </MenuItem>
        )}
        {HierarchyAllData?.type === "calculator" && (
          <Box sx={{ position: "relative", zIndex: 5 }}>
            {!HierarchyAllData?.mapped_status && (
              <MenuItem
                style={{ fontSize: "1rem" }}
                value={"calculator"}
                disabled={
                  !sessionStorage.getItem("ConfigPermission")?.includes("D")
                }
                className={menuItemClassName}
                onClick={() => {
                  API.delete(`/cost/calculator/${selectedIdTree}/`, {}, 0)
                    .then(() => {
                      setCounterApiCall((prev: any) => prev + 1);
                    })
                    .catch(() => {});
                }}
              >
                <DeleteIcon
                  sx={{
                    cursor: "pointer",
                    marginRight: "0.5rem",
                    fontSize: "1.5rem",
                  }}
                />
                Delete
              </MenuItem>
            )}
            <MenuItem
              style={{ fontSize: "1rem" }}
              value={"calculator"}
              className={menuItemClassName}
              disabled={
                !sessionStorage.getItem("ConfigPermission")?.includes("C")
              }
              onClick={() => {
                API.post(
                  "/cost/copy_calculator/",
                  {
                    id: HierarchyAllData.id,
                    name: "copy_calc1",
                  },
                  {},
                  0
                )
                  .then((res: any) => {
                    setCounterApiCall((prev: any) => prev + 1);
                    history.push(
                      `/costingconfiguration/${projectId}/${projectname}/${
                        res.data.id
                      }/${"calculator"}`
                    );
                  })
                  .catch(() => {});
              }}
            >
              <FileCopyIcon
                sx={{
                  cursor: "pointer",
                  marginRight: "0.5rem",
                  fontSize: "1.5rem",
                }}
              />
              Copy
            </MenuItem>
            {HierarchyAllData?.mapped_status && (
              <MenuItem
                style={{ fontSize: "1rem" }}
                value={"calculator"}
                className={menuItemClassName}
                onClick={() => infoModal.open()}
              >
                <InfoIcon
                  sx={{
                    cursor: "pointer",
                    marginRight: "0.5rem",
                    fontSize: "1.5rem",
                  }}
                />
                Usage Info
              </MenuItem>
            )}
          </Box>
        )}
      </ControlledMenu>
      <TreeView
        expanded={expanded}
        key={HierarchyAllData?.sequence_id}
        id={HierarchyAllData?.sequence_id}
        defaultParentIcon={<FolderIcon fill="primary" />}
        className={styles.treetemp}
        defaultCollapseIcon={
          HierarchyAllData &&
          HierarchyAllData?.type !== "calculator" && (
            // HierarchyAllData?.sequence_id == false && (
            <img src={openFolder} style={{ width: "18px", color: "#007fff" }} />
          )
          // )
        }
        disableSelection={commonCalc?.includes(HierarchyAllData?.id)}
        disabledItemsFocusable={commonCalc?.includes(HierarchyAllData?.id)}
        style={commonCalc?.includes(HierarchyAllData?.id) ? { opacity: 0.5 } : {}}
        defaultExpandIcon={<FolderIcon color="primary" />}
        defaultEndIcon={
          HierarchyAllData?.type === "calculator" ? (
            <img src={calculator} style={{ width: "13px", color: "#007fff" }} />
          ) : (
            <FolderIcon color="primary" />
          )
        }
        onNodeSelect={() =>
          handleChange(
            HierarchyAllData?.name,
            HierarchyAllData?.sequence_id,
            HierarchyAllData,
            HierarchyAllData?.id
          )
        }
        selected={selected}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        sx={{
          "&.MuiTreeItem-content{}.Mui-selected": {
            backgroundColor: singleSelectedTreeData
              ?.map((item: any) => item.id)
              ?.includes(HierarchyAllData?.id)
              ? "#cee6fa !important"
              : "white !important",
              
            
          },
          
        }}
      >
        <StyledTreeItem
          data-index={HierarchyAllData?.sequence_id}
          nodeId={HierarchyAllData?.sequence_id}
          label={
            <Box sx={{ display: "flex" }}>
              <Typography className={styles.treeViewData}>
                <div
                  className={styles.treeViewText}
                  // style={{position:'relative',zIndex:1}}
                  // onContextMenu={(e: any) => {
                  //   setRightClickedId(HierarchyAllData.id);
                  //   if (HierarchyAllData?.type === "commodity") {
                  //     e.preventDefault();
                  //     e.stopPropagation();
                  //     setAnchorPoint({ x: e.clientX + 10, y: e.clientY + 10 });
                  //     setOpen(true);
                  //   } else if (HierarchyAllData?.type === "sub_calculator_cat") {
                  //     e.preventDefault();
                  //     e.stopPropagation();
                  //     setAnchorPoint({ x: e.clientX, y: e.clientY });
                  //     setOpen(true);
                  //   } else if (HierarchyAllData?.type === "calculator") {
                  //     e.preventDefault();
                  //     e.stopPropagation();
                  //     setAnchorPoint({ x: e.clientX, y: e.clientY });
                  //     setOpen(true);
                  //   }
                  // }}
                >
                  {HierarchyAllData?.name}{" "}
                  {HierarchyAllData?.calc_count ||
                  HierarchyAllData?.calc_count === 0
                    ? `(${HierarchyAllData?.calc_count})`
                    : ""}
                </div>
              </Typography>
              {HierarchyAllData?.type === "calculator" &&
                store?.is_compare?.selcetedCalculators &&
                store?.is_compare?.selcetedCalculators
                  ?.map((item: any) => item.id)
                  ?.includes(HierarchyAllData?.id) && (
                  <CheckIcon sx={{ color: "green", width: "1.2rem" }} />
                )}
            </Box>
          }
        >
          {HierarchyAllData &&
            HierarchyAllData?.data?.map((subsystemData: any) => {
              return (
                <>
                  <CalculatorListTree
                    HierarchyData={subsystemData}
                    key={subsystemData?.sequence_id}
                    setSelectedTreeData={setSelectedTreeData}
                    onSelectCallback={onSelectCallback}
                    setCounterApiCall={setCounterApiCall}
                    selectedTreeData={selectedTreeData}
                    setSingleSelectedTreeData={setSingleSelectedTreeData}
                    singleSelectedTreeData={singleSelectedTreeData}
                    commonCalc={commonCalc}
                  />
                </>
              );
            })}
        </StyledTreeItem>
      </TreeView>
      {addProcess.isOpen && (
        <AddProcessModal
          isOpen={addProcess.isOpen}
          onCloseModal={addProcess.close}
          commodityId={addProcess.propsId}
          counterApiCall={setCounterApiCall}
        />
      )}
      {/* {infoModal.isOpen && (
        <InfoTreeModaleeModal
          isOpen={infoModal.isOpen}
          onCloseModal={infoModal.close}
          rightClickedId={rightClickedId && rightClickedId}
        />
      )} */}
    </>
  );
};

export default CalculatorListTree;
