import * as React from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import { Backdrop, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, IconButton } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "60vw",
  maxWidth: "65vw",
  bgcolor: "background.paper",
  borderRadius: "12px",
  boxShadow: 24,
  p: 0,
};

interface IWatermarkModelProps {
  isOpen: any;
  onCloseModal: any;
  data: any;
}

function DependencyCalcListModal(props: IWatermarkModelProps) {
  const { isOpen, onCloseModal, data } = props;
  const { projectId, projectname } = useRouteParams<any>();

  const handleRedirect = (calculatorId: string, ancestors: any) => {
    sessionStorage.setItem("Costingconfigurationnode", JSON.stringify(ancestors));
    window.open(`/#/costingconfiguration/${projectId}/${projectname}/${calculatorId}/calculator`, "_blank");
  };

 
  
  return (
    <Modal
      open={isOpen}
      onClose={onCloseModal}
      aria-labelledby="transition-modal-title"
      aria-describedby="transition-modal-description"
      closeAfterTransition
      slots={{ backdrop: Backdrop }}
      slotProps={{
        backdrop: {
          timeout: 500,
        },
      }}
    >
      <Box sx={style}>
        {/* Header Section */}
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            height: "3.5rem",
            p: 2,
            backgroundColor: "#007fff",
            borderTopLeftRadius: "12px",
            borderTopRightRadius: "12px",
          }}
        >
          <Typography
            sx={{
              fontSize: "1.2rem",
              fontWeight: "bold",
              width: "100%",
              textAlign: "center",
              color: "#fff",
            }}
          >
            Using the following Cost Models
          </Typography>
          <CancelIcon
            titleAccess="Close"
            sx={{ cursor: "pointer", color: "#fff", fontSize: "1.5rem" }}
            onClick={onCloseModal}
          />
        </Box>
        <Divider sx={{ borderColor: "primary.light" }} />

        {/* Table Section */}
        <Box sx={{ p: 3 }}>
          <TableContainer>
            <Table>
              <TableHead sx={{ bgcolor: "primary.light" }}>
                <TableRow>
                <TableCell sx={{ fontWeight: "bold", color: "primary.main" }}>Commodity</TableCell>
                <TableCell sx={{ fontWeight: "bold", color: "primary.main" }}>Process</TableCell>
                <TableCell sx={{ fontWeight: "bold", color: "primary.main" }}>Category</TableCell>
              
               


                  <TableCell sx={{ fontWeight: "bold", color: "primary.main" }}>Cost Model</TableCell>
                  <TableCell sx={{ fontWeight: "bold", textAlign: "center", color: "primary.main" }}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data && data.length > 0 ? (
                  data.map((row: any, index: any) => (
                    <TableRow key={index} hover>
                   
                      <TableCell>{row.commodity}</TableCell>
                      <TableCell>{row.process}</TableCell>
                      <TableCell>{row.category}</TableCell>



                      <TableCell>{row.calculator_name}</TableCell>
                      <TableCell sx={{ textAlign: "center" }}>
                        <IconButton
                          onClick={() => handleRedirect(row.calculator_id, row?.ancestors)}
                          aria-label="redirect"
                        >
                          <OpenInNewIcon sx={{ color: "#007fff" }} />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={2} sx={{ textAlign: "center" }}>
                      No data available.
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    </Modal>
  );
}

export default DependencyCalcListModal;
