import { Box, Divider, FormControl, InputLabel, Skeleton } from "@mui/material";
import * as React from "react";
import bomMobile from "../../../../Assets/images/BOMMobile.svg";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import auto from "../../../../Assets/images/tata.jpg";
import styles from "./IdeaImagesandVideosCombine.module.scss";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import InsertDriveFileOutlinedIcon from "@mui/icons-material/InsertDriveFileOutlined";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import UploadIcon from "@mui/icons-material/Upload";
import DeleteIcon from "@mui/icons-material/Delete";
import CameraAltRoundedIcon from "@mui/icons-material/CameraAltRounded";
import StraightenIcon from "@mui/icons-material/Straighten";
import NoteAddOutlinedIcon from "@mui/icons-material/NoteAddOutlined";
import IdeaCreateImagesSection from "./IdeaCreateImagesSection";
import { API } from "../../../../api-services";
import { useRouteParams } from "../../../../ui-reusable-component/useRouteParams";
import swal from "sweetalert";
import RViewerJS from "viewerjs-react";
import { useBaseModal } from "../../../SearchPage/useBaseModal";
// import { IdeaCreateAddPdfModel } from "./IdeaCreateAddPdfModel";
import { LoadingButton } from "@mui/lab";
import DescriptionIcon from "@mui/icons-material/Description";
import IdeaCreateVideosSection from "./IdeaCreateVideosSection";
import { useEffect } from "react";
import IdeaThreeSixtyCreate from "./IdeaThreeSixtyCreate/IdeaThreeSixtyCreate";
import { AxiosError, AxiosResponse } from "axios";
import { IdeaCreateAddPdfModel } from "./IdeaCreateAddPdfModel";

export interface IIdeaImagesandVideosCombineProps {}

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

interface Params {
  projectId: any;
  projectName: any;
  topVault: any;
  vault: any;
  treeabbr: any;
  workstation: any;
  isLeaf: any;
  copyStatus: any;
}


const top100Films = [
  { title: 'The Shawshank Redemption', year: 1994 },
  { title: 'The Godfather', year: 1972 },
  { title: 'The Godfather: Part II', year: 1974 },
  { title: 'The Dark Knight', year: 2008 },
  { title: '12 Angry Men', year: 1957 },
  { title: "Schindler's List", year: 1993 },
];

const defaultProps = {
  options: top100Films,
  getOptionLabel: (option: FilmOptionType) => option.title,
};



interface FilmOptionType {
  title: string;
  year: number;
}



export default function IdeaImagesandVideosCombine(
  props: IIdeaImagesandVideosCombineProps
) {
  const {
    projectId,
    projectName,
    topVault,
    vault,
    treeabbr,
    workstation,
    isLeaf,
    copyStatus,
    system,
    ideaId,
  } = useRouteParams<any>();
  const [value, setValue] = React.useState("1");
  const [imageSectionDataList, setImageSectionDataList] = React.useState<any>(
    []
  );
  const [imageLoader, setImageLoader] = React.useState<any>(false);
  const [imageParameterSelect, setImageParameterSelect] = React.useState<any>(
    []
  );
  const [apiCallVaultData, setApiCallVaultData] = React.useState<any>(false);
  const [documentBOMData, setDocumentBOMData] = React.useState<any>([]);
  const [apiCallDocumentBOMData, setApiCallDocumentBOMData] =
    React.useState<any>(false);
  const [deleteAPILoader, setDeleteAPILoader] = React.useState<boolean>(false);
  const [ThreesixtyCounter, setthreesixtyCounter] = React.useState<any>(0);
  const [tabValue, setTabValue] = React.useState("1"); // 1 for image, 2 for video, 3 for 360 deg
  const [actions, setActions] = React.useState<any>();

  const imageParameter: any = [];
  const videoParameter: any = [];
  const threeSixityDegViewParameter: any = [];

  const openIdeaCreateAddPdfModel = useBaseModal();


const flatProps = {
  options: top100Films.map((option) => option.title),
};
// const [value, setValue] = React.useState<FilmOptionType | null>(null);

  imageSectionDataList?.image
    ?.filter((item: any) => !item?.required && item?.value == "")
    ?.map((item: any) => {
      imageParameter.push({ label: item?.paramter_name, keyId: item?.key });
    });

  imageSectionDataList?.video
    ?.filter((item: any) => !item?.required && item?.value == "")
    ?.map((item: any) => {
      videoParameter.push({ label: item?.paramter_name, keyId: item?.key });
    });

  imageSectionDataList?.three_sixity
    ?.filter(
      (item: any) => !item?.required && Object.keys(item?.value)?.length == 0
    )
    ?.map((item: any) => {
      threeSixityDegViewParameter.push({
        label: item?.paramter_name,
        keyId: item?.key,
      });
    });

  React.useEffect(() => {
    setImageLoader(true);
    setImageSectionDataList(undefined);
    setImageParameterSelect([]);
    setImageLoader(true);
    if(ideaId !=0){
    API.get(
      "/idea/idea_create_view/",
      { top_vault: topVault, id: ideaId },
      0
    ).then((res: any) => {
      setImageLoader(false);
      setImageSectionDataList(res.data);
    });
  }
  }, [apiCallVaultData, vault, topVault, workstation, ThreesixtyCounter,ideaId]);

  React.useEffect(() => {
    setDocumentBOMData(undefined);
    if(ideaId != 0){
    API.get(
      "/idea/idea_create_view/",
      { document: true, top_vault: topVault, id: ideaId },
      0
    ).then((res: any) => {
      setDocumentBOMData(res.data);
    });
  }
  }, [apiCallDocumentBOMData, vault, topVault, ideaId]);

  useEffect(() => {
    API.get(
      `/auth/calibration_user_permission/`,
      {
        action: true,
        bom_create: true,
        top_vault: topVault,
      },
      0
    )
      .then((res: AxiosResponse) => {
        setActions(res.data?.action);
      })
      .catch((err: AxiosError) => {
        console.log(err, "error");
      });
  }, [topVault, projectId]);

  const handleTabClicked = (value: any) => {
    setImageParameterSelect([]);
    setTabValue(value);
  };

  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const handleSelectImage = (event: any, newSystemValue: any) => {
    setImageParameterSelect(newSystemValue);
  };

  const deletePdfDocument = (component_id: any, keyId: any) => {
    swal({
      title: `Delete Document`,
      text: `Are you sure?`,
      icon: "error",
      //@ts-ignore
      buttons: {
        cancel: "Cancel",
        permanent: {
          text: `Delete`,
          value: `deleteDocument`,
        },
      },
      closeOnClickOutside: false,
      closeOnEsc: false,
      className: "delete-swal",
    }).then(async (type: string) => {
      switch (type) {
        case "deleteDocument":
          setDeleteAPILoader(true);
          API.put(
            `/idea/pool/${ideaId}/`,
            { [keyId]: "null" },
            {
              document: true,
            }, 0
          )
            .then((res: any) => {
              setDeleteAPILoader(false);
              setApiCallDocumentBOMData(!apiCallDocumentBOMData);
            })
            .catch((err: any) => {
              console.log(err, "Unable to delete file. Server Error");
            });
          break;
        default:
          swal(`Data saved from deleting`, { icon: "error" });
      }
    });
  };

  return (
    <div>
      <IdeaCreateAddPdfModel
        isOpen={openIdeaCreateAddPdfModel.isOpen}
        onCloseModal={openIdeaCreateAddPdfModel.close}
        setApiCallDocumentBOMData={setApiCallDocumentBOMData}
        apiCallDocumentBOMData={apiCallDocumentBOMData}
        documentBOMData={documentBOMData}
      />
      <Box>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "0.5rem",
            width: "100%",
          }}
        >
          {(copyStatus == false || copyStatus == "false") &&
            (actions?.includes("U") || actions?.includes("C")) && (
              <img
                src={bomMobile}
                alt=""
                style={{ height: "2.5rem", cursor: "pointer" }}
                onClick={() =>
                  window.open(`/#/bomscanner/${projectId}/${projectName}`)
                }
              />
            )}
         
              <FormControl fullWidth>
                <Autocomplete
                  multiple
                  id="tags-standard"
                  limitTags={1}
                  disableClearable
                  value={imageParameterSelect}
                  disableCloseOnSelect
                  sx={{
                    ".MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator":
                      {
                        color: "primary.main",
                      },
                    ".MuiButtonBase-root.MuiChip-root": {
                      backgroundColor: "primary.light",
                      height: "26px",
                    },
                  }}
                  options={
                    tabValue == "1"
                      ? imageParameter
                      : tabValue == "2"
                      ? videoParameter
                      : threeSixityDegViewParameter
                  }
                  getOptionLabel={(option: any) => option?.label}
                  onChange={(event: any, newSystemValue: any) => {
                    handleSelectImage(event, newSystemValue);
                  }}
                  renderOption={(props, option) => (
                    <li {...props} style={{ fontSize: "1rem" }}>
                      {option?.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      // label="Select Images to Upload"
                      placeholder={`Select ${
                        tabValue == "1"
                          ? "Images"
                          : tabValue == "2"
                          ? "Videos"
                          : "360° Videos"
                      } to Upload`}
                      sx={{
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "primary.light",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "primary.main",
                        },
                        ".MuiInputBase-root.MuiInput-root": {
                          fontSize: "1rem !important",
                        },
                        ".MuiSvgIcon-root": { color: "primary.main" },
                      }}
                    />
                  )}
                />
              </FormControl>
           
        </Box>
        <Box
          sx={{
            width: "100%",
            marginTop: "0.5rem",
            height: { lg: "45vh", xl: "50vh" },
          }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3"
        >
          <TabContext value={value}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              {!imageLoader ? (
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tabs example"
                  sx={{
                    ".MuiTabs-scroller": { height: "2rem" },
                    "& .MuiTabs-indicator": { backgroundColor: "primary.main" },
                    "& .MuiTab-root": {
                      color: "primary.main",
                      height: "2rem",
                      minHeight: "2.1rem",
                      maxWidth: "100% !important",
                      width:
                        imageSectionDataList?.image?.length > 0 &&
                        imageSectionDataList?.video?.length > 0 &&
                        imageSectionDataList?.three_sixity?.length > 0
                          ? "33%"
                          : imageSectionDataList?.image?.length > 0 &&
                            imageSectionDataList?.video?.length > 0
                          ? "50%"
                          : imageSectionDataList?.image?.length > 0
                          ? "100%"
                          : " ",
                    },
                    "& .Mui-selected": {
                      color: "#fff !important",
                      backgroundColor: "primary.main",
                      height: "2rem",
                      minHeight: "2.1rem",
                      maxWidth: "100% !important",
                      width:
                        imageSectionDataList?.image?.length > 0 &&
                        imageSectionDataList?.video?.length > 0 &&
                        imageSectionDataList?.three_sixity?.length > 0
                          ? "33%"
                          : imageSectionDataList?.image?.length > 0 &&
                            imageSectionDataList?.video?.length > 0
                          ? "50%"
                          : imageSectionDataList?.image?.length > 0
                          ? "100%"
                          : " ",
                    },
                    minHeight: "22px",
                  }}
                >
                  {imageSectionDataList?.image?.length > 0 && (
                    <Tab
                      label="Images"
                      value="1"
                      onClick={() => handleTabClicked("1")}
                    />
                  )}
                  {imageSectionDataList?.video?.length > 0 && (
                    <Tab
                      label="Videos"
                      value="2"
                      onClick={() => handleTabClicked("2")}
                    />
                  )}
                  {imageSectionDataList?.three_sixity?.length > 0 && (
                    <Tab
                      label="360 deg"
                      value="3"
                      onClick={() => handleTabClicked("3")}
                    />
                  )}
                </TabList>
              ) : (
                <Box>
                  <Skeleton
                    variant="rectangular"
                    height="2rem"
                    width="100%"
                    sx={{ marginBottom: "1rem", borderRadius: "0.5rem" }}
                  />
                </Box>
              )}
            </Box>
            <TabPanel
              value="1"
              sx={{ height: { lg: "37vh", xl: "45vh" }, padding: "0.5rem" }}
              adl-scrollbar="true"
              adl-scrollbar-width="0.3"
            >
              {!imageLoader ? (
                <>
                  {/* @ts-ignore */}
                  <RViewerJS>
                    <Box className={styles.bomImageSection}>
                      {imageSectionDataList?.image
                        ?.filter(
                          (item: any) =>
                            item?.required == true || item?.value != ""
                        )
                        ?.map((item: any, index: any) => {
                          return (
                            <IdeaCreateImagesSection
                              key={index}
                              itemIncoming={item}
                              apiCallVaultData={apiCallVaultData}
                              setApiCallVaultData={setApiCallVaultData}
                              actions={actions}
                            />
                          );
                        })}


                        
                      {imageParameterSelect?.length > 0 &&
                        imageSectionDataList?.image
                          ?.filter((item: any) =>
                            imageParameterSelect?.some(
                              (itemInside: any) =>
                                itemInside.label === item?.paramter_name
                            )
                          )
                          ?.map((item: any, index: any) => {
                            return (
                              <IdeaCreateImagesSection
                                key={index}
                                itemIncoming={item}
                                apiCallVaultData={apiCallVaultData}
                                setApiCallVaultData={setApiCallVaultData}
                                actions={actions}
                              />
                            );
                          })}
                    </Box>
                  </RViewerJS>
                </>
              ) : (
                <Box className={styles.loaderStyles}>
                  <Skeleton
                    variant="rectangular"
                    height="8rem"
                    width="100%"
                    sx={{ marginBottom: "1rem", borderRadius: "0.5rem" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height="8rem"
                    width="100%"
                    sx={{ marginBottom: "1rem", borderRadius: "0.5rem" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height="8rem"
                    width="100%"
                    sx={{ marginBottom: "1rem", borderRadius: "0.5rem" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height="8rem"
                    width="100%"
                    sx={{ marginBottom: "1rem", borderRadius: "0.5rem" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height="8rem"
                    width="100%"
                    sx={{ marginBottom: "1rem", borderRadius: "0.5rem" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height="8rem"
                    width="100%"
                    sx={{ marginBottom: "1rem", borderRadius: "0.5rem" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height="8rem"
                    width="100%"
                    sx={{ marginBottom: "1rem", borderRadius: "0.5rem" }}
                  />{" "}
                  <Skeleton
                    variant="rectangular"
                    height="8rem"
                    width="100%"
                    sx={{ marginBottom: "1rem", borderRadius: "0.5rem" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height="8rem"
                    width="100%"
                    sx={{ marginBottom: "1rem", borderRadius: "0.5rem" }}
                  />
                  <Skeleton
                    variant="rectangular"
                    height="8rem"
                    width="100%"
                    sx={{ marginBottom: "1rem", borderRadius: "0.5rem" }}
                  />
                </Box>
              )}
            </TabPanel>

            <TabPanel
              value="2"
              sx={{ height: { lg: "37vh", xl: "45vh" }, padding: "0.5rem" }}
              adl-scrollbar="true"
              adl-scrollbar-width="0.3"
            >
              {imageSectionDataList?.image?.length > 0 && (
                <Box className={styles.bomImageSection}>
                  {imageSectionDataList?.video
                    ?.filter(
                      (item: any) => item?.required == true || item?.value != ""
                    )
                    ?.map((item: any, index: any) => {
                      return (
                        <IdeaCreateVideosSection
                          key={index}
                          itemIncoming={item}
                          apiCallVaultData={apiCallVaultData}
                          setApiCallVaultData={setApiCallVaultData}
                          actions={actions}
                        />
                      );
                    })}
                  {imageParameterSelect?.length > 0 &&
                    imageSectionDataList?.video
                      ?.filter((item: any) =>
                        imageParameterSelect?.some(
                          (itemInside: any) =>
                            itemInside.label === item?.paramter_name
                        )
                      )
                      ?.map((item: any, index: any) => {
                        return (
                          <IdeaCreateVideosSection
                            key={index}
                            itemIncoming={item}
                            apiCallVaultData={apiCallVaultData}
                            setApiCallVaultData={setApiCallVaultData}
                            actions={actions}
                          />
                        );
                      })}
                </Box>
              )}
            </TabPanel>
            <TabPanel
              value="3"
              sx={{ height: { lg: "37vh", xl: "45vh" }, padding: "0.5rem" }}
              adl-scrollbar="true"
              adl-scrollbar-width="0.3"
            >
              {imageSectionDataList?.three_sixity?.length > 0 && (
                <>
                  {imageSectionDataList?.three_sixity
                    ?.filter(
                      (item: any) =>
                        item?.required == true ||
                        Object.keys(item?.value)?.length > 0
                    )
                    ?.map((item: any, index: any) => {
                      return (
                        <IdeaThreeSixtyCreate
                          threesixtyData={item}
                          setthreesixtyCounter={setthreesixtyCounter}
                          actions={actions}
                        />
                      );
                    })}
                </>
              )}

              {imageParameterSelect?.length > 0 &&
                imageSectionDataList?.three_sixity
                  ?.filter((item: any) =>
                    imageParameterSelect?.some(
                      (itemInside: any) =>
                        itemInside.label === item?.paramter_name
                    )
                  )
                  ?.map((item: any, index: any) => {
                    return (
                      <IdeaThreeSixtyCreate
                        threesixtyData={item}
                        setthreesixtyCounter={setthreesixtyCounter}
                        actions={actions}
                      />
                    );
                  })}
            </TabPanel>
          </TabContext>
        </Box>
        {/* <Divider sx={{ borderColor: "primary.light" }} /> */}
          <Divider sx={{borderColor:'primary.light',margin:'1rem 0'}} />
          {/* <FormControl fullWidth variant="standard">
            <InputLabel sx={{ color: "primary.main" }} shrink id="demo-simple-select-label">Select Documents</InputLabel>
            <Autocomplete
              {...defaultProps}
              id="Documents"
              autoSelect
              sx={{marginTop:'1.4rem'}}
              renderInput={(params) => (
                <TextField {...params} variant="standard"
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "primary.light",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "primary.main",
                  },
                  ".MuiInputBase-root.MuiInput-root": {
                    fontSize: "1rem !important",
                  },
                  ".MuiSvgIcon-root": { color: "primary.main" },
                }} />
              )}
            />                 
          </FormControl> */}
        <Box>
          {/* <Typography sx={{ fontSize: "1rem" }}>Document:</Typography> */}
          <Box className={styles.documentSection}>
           
                <Box
                  className={styles.AddDocuments}
                  onClick={() => openIdeaCreateAddPdfModel.open()}
                >
                  <NoteAddOutlinedIcon
                    titleAccess="Add Document"
                    sx={{ fontSize: "2rem", cursor: "pointer" }}
                  />
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      whiteSpace: "nowrap",
                      cursor: "pointer",
                    }}
                  >
                    Add Doc
                  </Typography>
                </Box>
             
            <Divider
              orientation="vertical"
              flexItem
              sx={{ borderColor: "primary.light" }}
            />
            <Box
              className={styles.listDocumentsec}
              adl-scrollbar="true"
              adl-scrollbar-width="0.3"
            >
              {documentBOMData &&
                documentBOMData
                  ?.filter((item: any) => {
                    return item?.value != "";
                  })
                  ?.map((item: any) => {
                    return (
                      <Box className={styles.listDocuments}>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "flex-start",
                            columnGap: "0.5rem",
                            justifyItems: "center",
                          }}
                        >
                          <DescriptionIcon
                            titleAccess={item?.file_name}
                            sx={{ cursor: "pointer", fontSize: "2rem" }}
                            onClick={() => {
                              window.open(`${item?.cdn + item?.value}`);
                            }}
                          />                         
                              <LoadingButton
                                sx={{ padding: 0, minWidth: 0 }}
                                onClick={() =>
                                  deletePdfDocument(
                                    item?.component_id,
                                    item?.key
                                  )
                                }
                                loading={deleteAPILoader}
                              >
                                <CloseIcon
                                  titleAccess="Delete Document"
                                  sx={{
                                    cursor: "pointer",
                                    color: "red",
                                    fontSize: "1rem",
                                    "&:hover": {
                                      transform: "Scale(1.1)",
                                      transition: "transform 0.5s ease",
                                    },
                                  }}
                                />
                              </LoadingButton>                           
                        </Box>
                        <Typography
                          sx={{
                            fontSize: "1rem",
                            minWidth: "6rem",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                            overflow: "hidden",
                            cursor: "pointer",
                          }}
                          title={item?.paramter_name}
                        >
                          {item?.paramter_name}
                        </Typography>
                      </Box>
                    );
                  })}
            </Box>
          </Box>
        </Box>
      </Box>
    </div>
  );
}
