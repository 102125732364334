import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CancelIcon from '@mui/icons-material/Cancel';

import { Divider, Skeleton, Tooltip } from '@mui/material';
import { API } from '../../../api-services';
import { IdeaTypesList } from '../../../utlis/Idea.constant';
import { useHistory, useParams } from 'react-router-dom';

interface InfoModalProps {


    onCloseModal: any;
    isOpen: any;
    Item: any;

}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: "28vw",
    // maxWidth: '80vw',
    // height: '80vh',
    bgcolor: 'background.paper',
    borderRadius: '10px',
    p: 1,
};

const IdeaGenartionInfoModal = (props: InfoModalProps) => {

    const [InfoData, setInfoData] = useState<any>([])
    const [Loader, setLoader] = useState<any>(false)

    const { onCloseModal,
        isOpen,
        Item } = props
        const history = useHistory();
        const {
          projectId,
          projectName,
          topVault,
          subSystem,
          subSystemId,
          expandTable,
          abbr,
          partId,
          depth,
          vault_id,
        } = useParams<any>();
    useEffect(() => {

        setLoader(true)
        API.get(`/idea/pool/`, {
            module: 15, page: 1, search_id: "", single_idea: Item?.id
        }).then((res: any) => {
            setLoader(false)
            setInfoData(res?.data[0])
        }).catch((err: any) => {
            setLoader(false)
        })
    }, [Item])

    const getOrigin = (origin: any) => {
        switch (origin) {
            case 1:
                return "BOM";
            case 2:
                return "Architecture";
            case 3:
                return "Feature";
            case 4:
                return "Costing";
            case 5:
                return "Media";
            case 7:
                return "Specification";

            default:
                break;
        }
    };
    //   let value=idea_id


    const IdeaData = IdeaTypesList && IdeaTypesList?.filter((item: any, index: any) => { return item?.value == InfoData?.idea_type })[0]



    const getStatus = (status: any) => {
        switch (status) {
            case 0:
                return "Initiated";
            case 1:
                return "In Review";
            case 2:
                return "Submitted";
            case 3:
                return "Accepted";

            default:
                break;
        }
    };


    return (
        <div>


            <Modal
                open={isOpen}
                onClose={onCloseModal}
            >
                <Box sx={style}>
                    <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
                        <Typography
                            style={{
                                fontSize: "1rem",
                                fontWeight: "600",
                                width: "100%",
                                textAlign: "center",
                                marginRight: '-2rem'
                            }}
                        >
                            Idea Generation Details ({Item?.idea_id})
                        </Typography>
                        <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
                    </Box>
                    <Divider
                        sx={{
                            borderColor: "primary.light",
                            margin: "0 1rem",
                            marginBottom: "1rem",
                        }}
                    />
                    <Box sx={{ height: 'fit-content', width: '100%' }}
                        adl-scrollbar="true"
                        adl-scrollbar-width="0.3">

                        {
                            Loader ? <><Skeleton /><Skeleton /><Skeleton /><Skeleton /><Skeleton /><Skeleton /><Skeleton /><Skeleton /><Skeleton /><Skeleton /><Skeleton /><Skeleton /><Skeleton /><Skeleton /></>



                                :

                                <Box sx={{display: "flex",
                                    flexDirection: "row",
                                    gap: "2.5rem",
                                    margin:" auto 1.4rem"}}>

                                    <Box>


                                        <Box sx={{marginBottom: "0.3rem",}}>
                                            <Typography sx={{ fontSize:'1rem'}}>Status </Typography>









                                        </Box>
                                        <Box sx={{marginBottom: "0.3rem",}}>
                                            <Typography sx={{ fontSize:'1rem'}}>ASI Owner</Typography>








                                        </Box>
                                        <Box  sx={{marginBottom: "0.3rem"}}>
                                            <Typography sx={{ fontSize:'1rem'}}>Wt. Savings(g)</Typography>









                                        </Box>
                                        <Box  sx={{marginBottom: "0.3rem"}}>
                                            <Typography sx={{ fontSize:'1rem'}}>Idea Type</Typography>









                                        </Box>


                                        <Box  sx={{marginBottom: "0.3rem"}}>
                                            <Typography sx={{ fontSize:'1rem'}}>Client Owner</Typography>









                                        </Box>
                                        <Box  sx={{marginBottom: "0.3rem"}}>
                                            <Typography sx={{ fontSize:'1rem'}}>Origin</Typography>








                                        </Box>
                                        <Box  sx={{marginBottom: "0.3rem"}}>
                                            <Typography sx={{ fontSize:'1rem'}}>Duration(Days)

                                            </Typography>









                                        </Box>
                                        <Box  sx={{marginBottom: "0.3rem"}}>
                                            <Typography sx={{ fontSize:'1rem'}}>Savings Value

                                            </Typography>








                                        </Box>
                                        <Box  sx={{marginBottom: "0.3rem"}}>
                                            <Typography sx={{ fontSize:'1rem'}}>Cost Verification

                                            </Typography>








                                        </Box>
                                        <Box  sx={{marginBottom: "0.3rem"}}>
                                            <Typography sx={{ fontSize:'1rem'}}>Ease (1-10)

                                            </Typography>









                                        </Box>
                                      {  InfoData && InfoData?.merge && Object.keys(InfoData?.merge) &&  Object.keys(InfoData?.merge)?.length>0 &&
                                        <Box  sx={{marginBottom: "0.3rem"}}>
                                            <Typography sx={{ fontSize:'1rem'}}>Merged with

                                            </Typography>









                                        </Box>}
                                    </Box>
                                    <Box>


                                        <Box  sx={{marginBottom: "0.3rem"}}>
                                            <Typography sx={{ fontSize:'1rem'}}>: </Typography>









                                        </Box>
                                        <Box  sx={{marginBottom: "0.3rem"}}>
                                            <Typography sx={{ fontSize:'1rem'}}>:</Typography>









                                        </Box>
                                        <Box  sx={{marginBottom: "0.3rem"}}>
                                            <Typography sx={{ fontSize:'1rem'}}>:</Typography>








                                        </Box>
                                        <Box  sx={{marginBottom: "0.3rem"}}>
                                            <Typography sx={{ fontSize:'1rem'}}>:</Typography>






                                        </Box>


                                        <Box  sx={{marginBottom: "0.3rem"}}>
                                            <Typography sx={{ fontSize:'1rem'}}>:</Typography>







                                        </Box>
                                        <Box>
                                            <Typography sx={{ fontSize:'1rem'}}>:</Typography>







                                        </Box>
                                        <Box  sx={{marginBottom: "0.3rem"}}>
                                            <Typography sx={{ fontSize:'1rem'}}>:</Typography>






                                        </Box>
                                        <Box  sx={{marginBottom: "0.3rem"}}>
                                            <Typography sx={{ fontSize:'1rem'}}>:</Typography>







                                        </Box>
                                        <Box  sx={{marginBottom: "0.3rem"}}>
                                            <Typography sx={{ fontSize:'1rem'}}>:</Typography>








                                        </Box>
                                        <Box  sx={{marginBottom: "0.3rem"}}>
                                            <Typography sx={{ fontSize:'1rem'}}>:</Typography>








                                        </Box>
                                       { InfoData &&  InfoData?.merge && Object.keys(InfoData?.merge) &&  Object.keys(InfoData?.merge)?.length>0 &&
                                        <Box  sx={{marginBottom: "0.3rem"}}>
                                            <Typography sx={{ fontSize:'1rem'}}>:</Typography>








                                        </Box>
}
                                    </Box>
                                    <Box>


                                        <Box  sx={{marginBottom: "0.3rem"}}>

                                            <Typography sx={{ fontSize:'1rem'}}>  {InfoData && InfoData?.rejected
                                                ? "Rejected"
                                                : getStatus(InfoData?.stage)}</Typography>








                                        </Box>
                                        <Box  sx={{marginBottom: "0.3rem"}}>

                                            <Typography sx={{ fontSize:'1rem'}}>{InfoData?.owner_name}</Typography>








                                        </Box>
                                        <Box  sx={{marginBottom: "0.3rem"}}>

                                            <Typography sx={{ fontSize:'1rem'}}>{InfoData?.weight}</Typography>








                                        </Box>
                                        <Box  sx={{marginBottom: "0.3rem"}}>

                                            <Typography sx={{ fontSize:'1rem'}}>{IdeaData?.text}</Typography>








                                        </Box>


                                        <Box  sx={{marginBottom: "0.3rem"}}>

                                            <Typography sx={{ fontSize:'1rem'}}>{InfoData?.client_owner_name==null?"__":InfoData?.client_owner_name}</Typography>








                                        </Box>
                                        <Box  sx={{marginBottom: "0.3rem"}}>

                                            <Typography sx={{ fontSize:'1rem'}}> {InfoData && getOrigin(InfoData?.origin)}</Typography>








                                        </Box>
                                        <Box  sx={{marginBottom: "0.3rem"}}>

                                            <Typography sx={{ fontSize:'1rem'}}>{InfoData?.duration}</Typography>








                                        </Box>
                                        <Box  sx={{marginBottom: "0.3rem"}}>

                                            <Typography sx={{ fontSize:'1rem'}}>{InfoData?.cost}</Typography>








                                        </Box>
                                        <Box  sx={{marginBottom: "0.3rem"}}>

                                            <Typography sx={{ fontSize:'1rem'}}>{InfoData && InfoData?.cost_verified==true
                                                ? "Completed"
                                                : "Not completed"}</Typography>








                                        </Box>
                                        <Box  sx={{marginBottom: "0.3rem"}}>

                                            <Typography sx={{ fontSize:'1rem'}}>{InfoData?.ease}</Typography>








                                        </Box>
                                        {InfoData &&  InfoData?.merge &&  Object.keys(InfoData?.merge) &&  Object.keys(InfoData?.merge)?.length>0 && 

                                        <Box  sx={{marginBottom: "0.3rem"}}>

                                            <Typography sx={{ fontSize:'1rem',textDecoration:'underline',cursor:"pointer"}}
                                            
                                            onClick={()=>{
                                                window.open(
                                                  `/#/idea-generation-view/${projectId}/${projectName}/${topVault}/${subSystem}/${subSystemId}/${expandTable}/${abbr}/${partId}/${0}/${
                                                    InfoData?.merge?.id
                                                  }/0/0`
                                                );
                                               }}
                                            
                                            >{InfoData?.merge?.idea_id}</Typography>








                                        </Box>
}
                                    </Box>
                                </Box>
                        }

                    </Box>
                </Box>
            </Modal>
        </div>
    )
}

export default IdeaGenartionInfoModal;