import * as React from "react";
import Box from "@mui/material/Box";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid";
import useWindowDimensions from "../../ui-reusable-component/useWindowDimensions";
import Button, { ButtonProps } from "@mui/material/Button";
import ShareIcon from "@mui/icons-material/Share";
import EditIcon from "@mui/icons-material/Edit";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import DeleteIcon from "@mui/icons-material/Delete";
import extlink from "../../Assets/images/ext_link.svg";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import moment from "moment";
import { API } from "../../api-services";
import {
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  Divider,
} from "@mui/material";
import Swal from "sweetalert2";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import { EditScenarioModal } from "./EditScenarioModal";
import { ShareScenarioModal } from "./ShareScenarioModal";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import ZoomInMapIcon from "@mui/icons-material/ZoomInMap";
import ShareDetailsModal from "./ShareDetailsModal";
import InfoIcon from "@mui/icons-material/Info";
import Popover from "@mui/material/Popover";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import { LoadingButton } from "@mui/lab";
import DownloadIcon from "@mui/icons-material/Download";
import { styled, alpha } from "@mui/material/styles";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import LockOpenIcon from "@mui/icons-material/LockOpen";
import LockIcon from "@mui/icons-material/Lock";
import { useSelector } from "react-redux";
import { userSelectors } from "../../Redux/Reducers/user.reducer";
import { number } from "yup";
import { useBaseModal } from "../SearchPage/useBaseModal";
import { DialogComponent } from "../../utlis/DialogComponent";
export interface ICostingScenarioSectionProps {
  count: any;
 
}

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

const csvOptions: GridCsvExportOptions = {
  fileName: "Idea Approve Table User Session In CSV",
  delimiter: ",",
  utf8WithBom: true,
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer
    sx={{ height: "2.5rem", justifyContent: "flex-end", paddingRight: "1rem" }}
  >
    <span
    // style={{ position: "absolute", right: "0rem" }}
    >
      <GridToolbarColumnsButton />
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);

export function CostingScenarioSection(props: ICostingScenarioSectionProps) {
  const { height, width } = useWindowDimensions();
  const { count } = props;
  const userProfile = useSelector(userSelectors.selectAll);
  const { projectId, projectName, topVault, vault, scenarioId, treeabbr } =
    useRouteParams<any>();
  const editScenarioModal = useBaseParamsModal();
  const shareScenario = useBaseParamsModal();
  const shareDetails = useBaseParamsModal();
  const [TableData, setTableData] = React.useState<any>([]);
  const [currentPage, setCurrentPage] = React.useState<number>(0);
  const [totalCount, setTotalcount] = React.useState<number>(0);
  const [anchorDownEl, setAnchorDownEl] = React.useState<null | HTMLElement>(
    null
  );
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const openDownload = Boolean(anchorDownEl);
  const getObj = () => {
    if (vault == 0) {
      return { top_vault: topVault };
    } else {
      return { vault: vault };
    }
  };
  const [costStatus, setCostStatus] = React.useState<any>(null);
  const [Loader, setLoader] = React.useState(false);
  const [CompareGraphData, SetCompareGraphData] = React.useState<any>([]);
  const [hoveredData, setHoveredData] = React.useState<any>({});
  const [copyLoading, setCopyLoading] = React.useState<any>(false);
  const [selectedIdForCopy, setSelectedIdForCopy] = React.useState<any>(null);
  const [iconText, setIconText] = React.useState<any>("");
  const [contentText, setContentText] = React.useState<any>("");
  const [isLeaf, setISLeaf] = React.useState<boolean>(false);
  const openStatusContent = useBaseModal();

  const [TotalCompareGraphData, setTotalCompareGraphData] = React.useState<any>(
    []
  );
  // const [Loader, serLoader] = React.useState<any>(false)
  const [TotalLoader, serTotalLoader] = React.useState<any>(false);
  const getDisableStatus = () => {
    if (sessionStorage.getItem("addScenarioStatus") === "3") {
      return false;
    } else if (sessionStorage.getItem("addScenarioStatus") !== "3") {
      return true;
    } else {
      return false;
    }
  };
  const getTableData = () => {
    setLoader(true);
    API.get("/cost/scenario_table/", { ...getObj(), page: currentPage + 1 },0)
      .then((res: any) => {
        // console.log(res?.data);
        setTableData(res?.data);
        setISLeaf(res?.data[0]?.is_leaf);
        setCostStatus(res?.data[0]?.cost_status);
        setLoader(false);
      })
      .catch((err: any) => {
        console.log("Sever Error");
        setLoader(false);
      });
  };
  // console.log(costStatus, "costStatus")
  const [isActive, setIsActive] = React.useState(true);
  const expandGraph = () => {
    // toggle
    setIsActive((current) => !current);

    // or set to true
    // setIsActive(true);
  };
  // const ScenarioTableModalHandler = () => {
  //     ScenarioTableModal.open()
  // }
  const getCompareGraphData = () => {
    setLoader(true);
    API.get("/cost/scenario_graph/", { ...getObj(), page: currentPage + 1 }, 0)
      .then((res: any) => {
        setLoader(false);
        SetCompareGraphData(res?.data);
      })
      .catch((err: any) => {
        setLoader(false);
        console.log("Server Error");
      });
  };

  const getRedirection = (cellValues: any) => {
    if (!cellValues?.is_leaf) {
      if (vault == "0") {
        if (cellValues?.scenario_creation == "Main") {
          // /view/:projectId/:topVault/:vault/:treeabbr/costing/
          window.open(
            `/#/view/${projectId}/${topVault}/${vault}/${cellValues.abbreviation}/costing`
          );
        } else if (cellValues.scenario_creation == "Dependent") {
          window.open(
            `/#/createcostingpart/${projectId}/${projectName}/${
              cellValues?.product_name
            }/${topVault}/${0}/${
              cellValues.abbreviation ? cellValues?.abbreviation : "none"
            }/0/0/0/0/0/0/0/${cellValues?.id}/0/true`
          );
        } else {
          window.open(
            `/#/costingscenarioabbrlanding/${projectId}/${projectName}/${topVault}/${cellValues.id}/${cellValues?.scenario_list}/true/${cellValues.id}/${cellValues?.product_name}/0/${vault}`
          );
        }
      } else {
        if (cellValues?.scenario_creation == "Main") {
          window.open(
            `/#/view/${projectId}/${topVault}/${vault}/${cellValues.abbreviation}/costing`
          );
        } else if (cellValues.scenario_creation == "Dependent") {
          window.open(
            `/#/createcostingpart/${projectId}/${projectName}/${
              cellValues?.product_name
            }/${topVault}/${vault}/${
              cellValues.abbreviation ? cellValues?.abbreviation : "none"
            }/0/0/0/0/0/0/0/${cellValues?.id}/0/true`
          );
        } else {
          window.open(
            `/#/costingscenarioabbrlanding/${projectId}/${projectName}/${topVault}/${cellValues.id}/${cellValues?.scenario_list}/true/${cellValues.id}/${cellValues?.product_name}/${cellValues.parent_abbreviation}/${vault}`
          );
        }
      }
    } else {
      if (cellValues?.scenario_creation == "Main") {
        window.open(
          `/#/view/${projectId}/${topVault}/${vault}/${cellValues.abbreviation}/costing`
        );
      } else {
        window.open(
          `/#/createcostingpart/${projectId}/${projectName}/${
            cellValues?.product_name
          }/${topVault}/${vault}/${
            cellValues.abbreviation ? cellValues?.abbreviation : "none"
          }/0/0/0/0/0/0/0/${cellValues?.id}/0/true`
        );
      }
    }
  };

  const getTotalCompareGraphData = () => {
    serTotalLoader(true);

    API.get(
      "/cost/scenario_graph/",
      { ...getObj(), total_cost: true, page: currentPage + 1 },
      0
    )
      .then((res: any) => {
        setTotalCompareGraphData(res?.data);
        serTotalLoader(false);
      })
      .catch((err: any) => {
        serTotalLoader(false);

        console.log("Server Error");
      });
  };

  const getTotalCount = () => {
    API.get("/cost/scenario_table/", { ...getObj(), total_count: true },0)
      .then((res: any) => {
        setTotalcount(res?.data?.total_count);
      })
      .catch((err: any) => {
        console.log("Server Error");
      });
  };

  React.useEffect(() => {
    getTotalCount();
    setCurrentPage(0);
  }, [vault, topVault]);

  React.useEffect(() => {
    getCompareGraphData();
    getTotalCompareGraphData();
  }, [topVault, vault, count, treeabbr, currentPage]);

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement>,
    rowData: any
  ) => {
    setHoveredData(rowData);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  //   const handleRedirectSingle = (id: any) => {
  //     setIconText("firstdownload");
  //     setContentText(
  //       "We’ve got this report downloading faster than a cheetah running. You will also receive a notification.But in the meantime, keep using xcPEP like the productivity champion !"
  //     );
  //     openStatusContent.open();
  //     API.get(
  //       "customer/individual_report/",
  //       { vault: id, individual_type: "BOM" },
  //       0
  //     )
  //       .then((res: any) => {
  //         window.open(res?.data, "_self");
  //       })
  //       .catch((err: any) => { });
  // }
  // customer/individual_report/?vault=68481&individual_type=Costing&scenario=234
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorDownEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorDownEl(null);
  };

  const detailSceanrioDownload = (module: any, cellValues: any) => {
    if (module === "w") {
      // setIconText("firstdownload");
      // setContentText(
      // "We’ve got this report downloading faster than a cheetah running. You will also receive a notification.But in the meantime, keep using xcPEP like the productivity champion !"
      // );
      // openStatusContent.open();
      // console.log(cellValues?.vault,cellValues,"cellValuesvault");
      API.get(
        "/customer/consolidated_report/",
        {
          vault: cellValues?.vault,
          with_image: "true",
          consolidate_type: "Costing",
          scenario: cellValues?.id,
        },
        0
      )
        .then((res: any) => {
          window.open(res?.data, "_self");
        })
        .catch((err: any) => {});
    } else if (module === "w/o") {
      //   setIconText("firstdownload");
      //   setContentText(
      //    "We’ve got this report downloading faster than a cheetah running. You will also receive a notification.But in the meantime, keep using xcPEP like the productivity champion !"
      //    );
      //  openStatusContent.open();
      API.get(
        "/customer/consolidated_report/",
        {
          vault: cellValues?.vault,
          with_image: "false",
          consolidate_type: "Costing",
          scenario: cellValues?.id,
        },
        0
      )
        .then((res: any) => {
          window.open(res?.data, "_self");
        })
        .catch((err: any) => {});
    }
  };

  const detailSceanrioDirectDownload = (cellValues: any) => {
    // setIconText("firstdownload");
    //   setContentText(
    //    "We’ve got this report downloading faster than a cheetah running. You will also receive a notification.But in the meantime, keep using xcPEP like the productivity champion !"
    //    );
    //  openStatusContent.open();
    API.get(
      "customer/individual_report/",
      {
        vault: cellValues?.vault,
        individual_type: "Costing",
        scenario: cellValues?.id,
      },
      0
    )
      .then((res: any) => {
        window.open(res?.data, "_self");
      })
      .catch((err: any) => {});
  };

  const open = Boolean(anchorEl);

  let columns: any = [
    {
      field: "actions",
      headerName: "Actions",
      minWidth: width > 1370 ? 300 : 270,
      // minWidth: width > 1370 ? 250 : 220,
      flex: 1,

      renderCell: (cellValues: any) => {
        return (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                columnGap: "0.5rem",
              }}
            >
              <Popover
                id="mouse-over-popover"
                sx={{
                  pointerEvents: "none",
                }}
                open={open}
                anchorEl={anchorEl}
                elevation={0}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                onClose={handlePopoverClose}
                disableRestoreFocus
              >
                <Box
                  sx={{
                    // p: 1,
                    border: "1px solid #007fff",
                    borderRadius: "10px",
                    overflow: "hidden",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      textAlign: "center",
                      width: "100%",
                      backgroundColor: "primary.light",
                      // color: "white",
                    }}
                  >
                    Scenario Info
                  </Typography>
                  <Divider />
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          Assumption Card/s
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          {" "}
                          : {hoveredData.config}
                        </TableCell>
                      </TableRow>
                      {Object.keys(hoveredData)?.length > 0 && (
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            Created By
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.2rem 0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            {" "}
                            : {hoveredData.created_by_name}
                          </TableCell>
                        </TableRow>
                      )}
                      <TableRow>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          Created Date
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          {" "}
                          : {hoveredData?.created_at}
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </Box>
              </Popover>
              {cellValues.row.scenario_creation !== "Main" && (
                <Box
                  sx={{ cursor: "pointer", lineHeight: 1 }}
                  aria-owns={open ? "mouse-over-popover" : undefined}
                  aria-haspopup="true"
                  onMouseEnter={(event: any) =>
                    handlePopoverOpen(event, cellValues.row)
                  }
                  onMouseLeave={handlePopoverClose}
                >
                  <InfoIcon />
                </Box>
              )}

              {cellValues.row.scenario_creation !== "Main" &&
                cellValues.row.action?.includes("U") && (
                  <EditIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      editScenarioModal.open(cellValues.row);
                    }}
                  />
                )}

              {cellValues.row.scenario_creation !== "Main" &&
                cellValues.row.action?.includes("D") && (
                  <DeleteIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => {
                      Swal.fire({
                        title: "Are you sure?",
                        text: "You want to delete this scenario",
                        icon: "error",
                        showCancelButton: true,
                        confirmButtonColor: "#007FFF",
                        cancelButtonColor: "#d33",
                        confirmButtonText: "Yes, delete it!",
                      }).then((result) => {
                        if (result.isConfirmed) {
                          API.delete(
                            `/cost/scenario/${cellValues.row.id}/`,
                            {},
                            0
                          )
                            .then((res: any) => {
                              getTableData();
                              getCompareGraphData();
                              getTotalCompareGraphData();
                              Swal.fire(
                                "Deleted!",
                                "Scenario has been deleted.",
                                "success"
                              );
                            })
                            .catch((err: any) => {});
                        }
                      });
                    }}
                  />
                )}

              {cellValues.row.scenario_creation !== "Main" &&
                cellValues.row.action?.includes("C") && (
                  <LoadingButton
                    sx={{ minWidth: "0.5rem", padding: "0" }}
                    loading={
                      selectedIdForCopy === cellValues.row.id
                        ? copyLoading
                        : false
                    }
                  >
                    <FileCopyIcon
                      sx={{ cursor: "pointer", fontSize: "1.4rem" }}
                      onClick={() => {
                        setSelectedIdForCopy(cellValues.row.id);
                        setCopyLoading(true);
                        API.get(
                          "/cost/scenario_copy/",
                          {
                            scenario: cellValues.row.id,
                            ...getObj(),
                          },
                          0
                        )
                          .then((res: any) => {
                            getTableData();
                            setCopyLoading(false);
                            getCompareGraphData();
                            getTotalCompareGraphData();
                            Swal.fire({
                              title: "Success",
                              text: "Scenario has been copied successfully",
                              icon: "success",
                              confirmButtonColor: "#007FFF",
                            });
                          })
                          .catch((err: any) => {
                            setCopyLoading(false);
                          });
                      }}
                    />
                  </LoadingButton>
                )}

              {cellValues.row.cost !== "Inprogress" &&
                cellValues.row.action?.includes("R") && (
                  <img
                    title={
                      !getDisableStatus()
                        ? ""
                        : "Validate main costing to enable this scenario"
                    }
                    src={extlink}
                    alt="extlink"
                    onClick={() => {
                      if (!getDisableStatus()) {
                        getRedirection(cellValues?.row);
                      }
                    }}
                    style={{
                      height: "1.4rem",
                      // margin: "0 0.2rem",
                      cursor: "pointer",
                    }}
                  />
                )}

              {costStatus == true && cellValues.row.is_leaf == true ? (
                <DownloadIcon
                  sx={{ cursor: "pointer" }}
                  titleAccess="Download"
                  onClick={() => detailSceanrioDirectDownload(cellValues.row)}
                />
              ) : (
                <>
                  {costStatus == true && (
                    <Button
                      id="demo-customized-button"
                      aria-controls={
                        openDownload ? "demo-customized-menu" : undefined
                      }
                      aria-haspopup="true"
                      aria-expanded={openDownload ? "true" : undefined}
                      disableElevation
                      onClick={handleClick}
                      style={{ padding: "0rem", minWidth: "1rem" }}
                      title="Download"
                    >
                      <DownloadIcon
                        sx={{ cursor: "pointer" }}
                        titleAccess="Download"
                      />
                    </Button>
                  )}
                  <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                      "aria-labelledby": "demo-customized-button",
                    }}
                    anchorEl={anchorDownEl}
                    open={openDownload}
                    onClose={handleClose}
                    sx={{
                      borderRadius: "10px",
                      border: "1px solid",
                      borderColor: "primary.light",
                    }}
                  >
                    <MenuItem
                      sx={{
                        fontSize: "1rem",
                        color: "primary.main",
                        padding: "0.2rem 0.5rem",                        
                      }}
                      
                      onClick={() =>
                        detailSceanrioDownload("w", cellValues.row)
                      }
                    >
                      Consolidated with images
                    </MenuItem>
                    <MenuItem
                      sx={{
                        fontSize: "1rem",
                        color: "primary.main",
                        padding: "0.2rem 0.5rem",
                      }}
                      onClick={() =>
                        detailSceanrioDownload("w/o", cellValues.row)
                      }                      
                    >
                      Consolidated w/o images
                    </MenuItem>
                  </StyledMenu>
                </>
              )}

              {cellValues.row.scenario_creation !== "Main" &&
                cellValues.row.scenario_creation !== "Dependent" &&
                cellValues.row.action === "CRUD" && (
                  <ShareIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => shareScenario.open(cellValues.row)}
                  />
                )}

              {cellValues.row.scenario_creation !== "Main" &&
                userProfile &&
                (userProfile[0]?.id === cellValues.row?.created_by ||
                  userProfile[0].is_superuser) &&
                cellValues.row.action?.includes("U") &&
                (cellValues.row.lock ? (
                  <LockIcon
                    sx={{ cursor: "pointer", color: "green" }}
                    onClick={() => {
                      API.put(
                        `/cost/scenario/${cellValues.row.id}/`,
                        {
                          lock: false,
                        },
                        {},
                        0
                      )
                        .then((res: any) => {
                          Swal.fire({
                            title: "Success",
                            text: "Scenario has been unlocked successfully",
                            icon: "success",
                            confirmButtonColor: "#007FFF",
                          });
                          getTableData();
                        })
                        .catch((err: any) => {
                          const { data } = err.response;
                          Swal.fire({
                            title: "Error",
                            text: data[0],
                            icon: "error",
                            confirmButtonColor: "#007FFF",
                          });
                        });
                    }}
                  />
                ) : (
                  <LockOpenIcon
                    sx={{ cursor: "pointer", color: "red" }}
                    onClick={() => {
                      API.put(
                        `/cost/scenario/${cellValues.row.id}/`,
                        {
                          lock: true,
                        },
                        {},
                        0
                      )
                        .then((res: any) => {
                          Swal.fire({
                            title: "Success",
                            text: "Scenario has been locked successfully",
                            icon: "success",
                            confirmButtonColor: "#007FFF",
                          });
                          getTableData();
                        })
                        .catch((err: any) => {
                          const { data } = err.response;
                          Swal.fire({
                            title: "Error",
                            text: data[0],
                            icon: "error",
                            confirmButtonColor: "#007FFF",
                          });
                        });
                    }}
                  />
                ))}
            </div>
          </>
        );
      },
    },
    {
      field: "color",
      headerName: "Color",
      width: width > 1370 ? 60 : 40,
      // flex: 1,
      renderCell: (cellValues: any) => {
        return (
          <>
            <Box
              sx={{
                backgroundColor: cellValues.row.color,
                width: "2.5rem",
                height: "1rem",
              }}
            ></Box>
          </>
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      minWidth: width > 1370 ? 280 : 250,
      flex: 1,
      renderCell: (cellValues: any) => {
        // console.log(cellValues?.row?.cost_percent);
        return (
          <>
            <span
              style={{
                textDecoration:
                  cellValues.row.cost !== "Inprogress" &&
                  cellValues.row.action?.includes("R") &&
                  !getDisableStatus()
                    ? "underline"
                    : "",
                cursor: "pointer",
                display: "flex",
                justifyContent: "end",
              }}
              onClick={() => {
                if (
                  cellValues.row.cost !== "Inprogress" &&
                  cellValues.row.action?.includes("R") &&
                  !getDisableStatus()
                ) {
                  getRedirection(cellValues?.row);
                }
              }}
              title={
                !getDisableStatus()
                  ? ""
                  : "Validate main costing to enable this scenario"
              }
            >
              {cellValues?.row?.name}
              
            </span>
          </>
        );
      },
    },
    {
      field: "scenario_creation",
      headerName: "Type",
      minWidth: width > 1370 ? 100 : 100,
      // flex: 1,
    },
    {
      field: "cost",
      headerName: `Total Cost ${sessionStorage.getItem("currency")}`,
      minWidth: width > 1370 ? 110 : 90,
      headerAlign: "right",
      align: "right",

      renderCell: (cellValues: any) => {
        return (
          <>
         <span>{typeof cellValues?.row?.cost === "number"?cellValues?.row?.cost?.toFixed(2):cellValues?.row?.cost}</span>
          </>
        );
      },
      // flex: 1,
    },
    {
      field: "rm_cost",
      headerName: `RM Cost  ${sessionStorage.getItem("currency")}`,
      minWidth: width > 1370 ? 90 : 100,
      headerAlign: "right",
      align: "right",
      // flex: 1,
      renderCell: (cellValues: any) => {
        return (
          <>
         <span>{typeof cellValues?.row?.rm_cost === "number"? cellValues?.row?.rm_cost?.toFixed(2):cellValues?.row?.rm_cost}</span>
          </>
        );
      },
    },
    {
      field: "process_cost",
      headerName:
        width > 1370
          ? `Process Cost ${sessionStorage.getItem("currency")}`
          : `PS Cost ${sessionStorage.getItem("currency")}`,
      minWidth: width > 1370 ? 130 : 100,
      headerAlign: "right",
      align: "right",
      renderCell: (cellValues: any) => {
        return (
          <>
         <span>{typeof cellValues?.row?.process_cost === "number" ? cellValues?.row?.process_cost?.toFixed(2):cellValues?.row?.process_cost}</span>
          </>
        );
      },
      // flex: 1,
    },
    {
      field: "overhead_cost",
      headerName:
        width > 1370
          ? `Overhead Cost ${sessionStorage.getItem("currency")}`
          : `OH Cost ${sessionStorage.getItem("currency")}`,
      minWidth: width > 1370 ? 140 : 100,
      headerAlign: "right",
      align: "right",
      renderCell: (cellValues: any) => {
        return (
          <>
         <span>{typeof cellValues?.row?.overhead_cost === "number"? cellValues?.row?.overhead_cost?.toFixed(2):cellValues?.row?.overhead_cost}</span>
          </>
        );
      },
      // flex: 1,
    },
    
  ];

  if(!isLeaf){
    columns.push({
      field: "child_cost",
      headerName:
        width > 1370
          ? `Child Part Cost ${sessionStorage.getItem("currency")}`
          : `CP Cost ${sessionStorage.getItem("currency")}`,
      minWidth: width > 1370 ? 150 : 100,
      headerAlign: "right",
      align: "right",
      // flex: 1,
      renderCell: (cellValues: any) => {
        return (
          <>
         <span>{typeof cellValues?.row?.child_cost ==="number" ? cellValues?.row?.child_cost ?.toFixed(2):cellValues?.row?.child_cost}</span>
          </>
        );
      },
    })
  }
  columns.push({
    field: "shred_to_user",
    headerName: "Shared With",
    minWidth: width > 1370 ? 100 : 120,
    flex: 1,
    renderCell: (cellValues: any) => {
      return (
        <>
          {cellValues.row.scenario_creation !== "Main" && (
            <span
              onClick={() => {
                if (cellValues.row.shred_to_user > 0) {
                  shareDetails.open(cellValues.row);
                }
              }}
            >
              {cellValues.row.shred_to_user > 0 ? (
                <span
                  style={{ textDecoration: "underline", cursor: "pointer" }}
                >
                  {" "}
                  {cellValues?.row?.shred_to_user}{" "}
                  {cellValues.row.shred_to_user > 1 ? "Users" : "User"}
                </span>
              ) : (
                "-"
              )}
            </span>
          )}
        </>
      );
    }
  })

  // In rows which is a array, we are pushing data which is structured in frontEnd so, that we can pass it to dataGrid

  function CustomizedTick(props: any) {
    const { x, y, stroke, payload } = props;
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={8} fill="#666">
          <tspan
            style={{ fill: "black", fontSize: "12" }}
            textAnchor="middle"
            x="0"
          >
            {payload.value}
          </tspan>
        </text>
      </g>
    );
  }

  const CustomizedLabel = (props: any) => {
    const { x, y, fill, value } = props;

    return (
      <text
        x={x}
        y={y}
        dy={-4}
        fontSize="10"
        fontFamily="sans-serif"
        fill={fill}
        textAnchor="inherit"
      >
        {TotalCompareGraphData && TotalCompareGraphData[0]?.currency} {value}
      </text>
    );
  };

  
  const CustomizedCostTooltip: any = ({ active, payload, label }: any) => {
    const firstCurrency = payload && payload.length > 0 ? payload[0]?.payload?.currency : '';

    if (active && payload && payload.length) {
      return (
        <div
          style={{
            backgroundColor: "white",
            padding: "0.2rem 0.5rem",
            borderRadius: "5px",
            border: "1px solid",
          }}
        >
          <Typography
            style={{
              fontWeight: "bold",
              fontSize: "0.87rem",
              minWidth: "8rem",
              width: "100%",
              textAlign: "center",
            }}
          >{`${label}`}</Typography>
          <Typography
            sx={{
              fontSize: "0.87rem",
              width: "100%",
              display: "flex",
              columnGap: "0.5rem",
              alignItems: "center",
              fontWeight: "bold", 
            }}
          >
            <span style={{ width: "6rem", textTransform: "capitalize" }}>
              Total:
            </span>
            <span style={{ width: "7rem", textAlign: "right" }}>
            {payload.reduce((accumulator: number, currentItem: any) => accumulator + currentItem.value,0).toFixed(2) }{' '}
            {firstCurrency}        
            </span>
          </Typography>

          {payload.map((item: any, index: any) => {
            return (
              <>
                {/* @ts-ignore */}
                {
                  <Typography
                    sx={{
                      fontSize: "0.87rem",
                      width: "100%",
                      display: "flex",
                      columnGap: "0.5rem",
                      alignItems: "center",
                    }}
                  >
                    <span
                      style={{ width: "6rem", textTransform: "capitalize" }}
                    >
                      {item?.name}:
                    </span>
                    <span style={{ width: "7rem", textAlign: "right" }}>
                      {item?.value?.toFixed(2)} {item.payload?.currency}
                    </span>
                  </Typography>
                }
              </>
            );
          })}          
        </div>
      );
    }
    return null;
  };

  React.useEffect(() => {
    getTableData();
  }, [vault, topVault, count, treeabbr, currentPage]);
  // In rows which is a array, we are pushing data which is structured in frontEnd so, that we can pass it to dataGrid

  var RowData =
    TableData &&
    TableData?.map((item: any, index: any) => ({
      ...item,
      sno: index + 1,


      created_at:
        item?.created_at == ""
          ? "__"
          : moment(item?.created_at).format("DD-MM-YYYY"),
    }));

  let dataStructureDataGrid: any = {
    columns: columns,
    rows: RowData && RowData,
  };

  return (
    <>
      <Box>
        {editScenarioModal.isOpen && (
          <EditScenarioModal
            isOpen={editScenarioModal.isOpen}
            onCloseModal={editScenarioModal.close}
            exsistingData={editScenarioModal.propsId}
            getTableData={getTableData}
            getCompareGraphData={getCompareGraphData}
            getTotalCompareGraphData={getTotalCompareGraphData}
          />
        )}
        {shareDetails.isOpen && (
          <ShareDetailsModal
            isOpen={shareDetails.isOpen}
            onCloseModal={shareDetails.close}
            data={shareDetails.propsId}
            getParentTableData={getTableData}
          />
        )}
        {shareScenario.isOpen && (
          <ShareScenarioModal
            isOpen={shareScenario.isOpen}
            onCloseModal={shareScenario.close}
            data={shareScenario.propsId}
            getTableData={getTableData}
          />
        )}
        <Box
          sx={{
            height: isActive
              ? { lg: "45vh", xl: "48vh" }
              : { lg: "88vh", xl: "89vh" },
            width: { lg: "95vw", xl: "95vw" },
            // width: { lg: "74.5vw", xl: "78vw" },
          }}
        >
          <DataGrid
            headerHeight={48}
            rowHeight={36}
            {...dataStructureDataGrid}
            loading={Loader}
            disableSelectionOnClick={true}
            // loading={userDataGetLoad}
            components={{
              Toolbar: () => {
                return CustomToolbar();
              },
            }}
            rowClassName={(params: any) => {
              return {
                backgroundColor: params.row.color, // Set the background color dynamically
              };
            }}
            density={"compact"}
            // pageSize={100}
            page={currentPage}
            pageSize={10}
            rowsPerPageOptions={[10]}
            paginationMode="server"
            rowCount={totalCount}
            rows={dataStructureDataGrid.rows}
            onPageChange={(params: any) => {
              setCurrentPage(params);
            }}
            sx={{
              "& ::-webkit-scrollbar": {
                width: "0.3rem",
                height: "0.3rem",
              },
              "& ::-webkit-scrollbar-thumb": {
                // background: $thumb-color;
                backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                borderRadius: "10px",
              },
              "& ::- webkit-scrollbar-thumb: hover ": {
                // width: "4px",
                backgroundColor: "#045DE9 !important",
              },
              "::-webkit-scrollbar-track": {
                backgroundColor: "#e1e1f3 !important",
              },
              "&.MuiDataGrid-root": {
                border: "none",
              },
              "&.MuiDataGrid-root .MuiDataGrid-cell": {
                borderBottomColor: "primary.light",
              },
            }}
          />
        </Box>
        <Box
          sx={{
            position: "absolute",
            marginTop: "-3rem",
            zIndex: "1",
            display: "flex",
            alignItems: "center",
            padding: "0 1rem",
          }}
        >
          {isActive ? (
            <ZoomInMapIcon
              titleAccess="Collapse Graph"
              sx={{ cursor: "pointer" }}
              onClick={expandGraph}
            />
          ) : (
            <ZoomOutMapIcon
              titleAccess="Expand Graph"
              sx={{ cursor: "pointer" }}
              onClick={expandGraph}
            />
          )}
        </Box>
        <Box
          sx={{
            height: isActive ? "41vh" : "0vh",
            width: "78vw",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Box sx={{ display: isActive ? "block" : "none" }}>
            {TotalCompareGraphData.length <= 5 ? (
              <Box sx={{ display: "flex", alignItems: "flex-end" }}>
                {/* <Box sx={{ width: { lg: "38vw", xl: "39vw" }, height: "38vh" }}>
                  {TotalLoader ? (
                    <>
                      <Skeleton></Skeleton>
                      <Skeleton></Skeleton>
                      <Skeleton></Skeleton>
                      <Skeleton></Skeleton>
                      <Skeleton></Skeleton>
                      <Skeleton></Skeleton>
                    </>
                  ) : (
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart
                        width={500}
                        height={300}
                        data={[...TotalCompareGraphData,...TotalCompareGraphData,...TotalCompareGraphData,...TotalCompareGraphData,...TotalCompareGraphData]}
                        data={TotalCompareGraphData}
                        margin={{
                          top: 20,
                          right: 30,
                          left: 20,
                          bottom: 18,
                        }}
                      >
                       
                        <XAxis dataKey="name" stroke="black" />
                        <YAxis
                          
                          left={0}
                          stroke="#007FFF"
                          fill="#1a4faa"
                          fontSize="0.87rem"
                          
                          domain={[
                            () => 0,
                            (dataMax: number) =>
                              Math.round(dataMax + (dataMax * 5) / 100),
                          ]}
                          label={{
                            value: `Currency (${
                              TotalCompareGraphData &&
                              TotalCompareGraphData[0]?.currency
                            })`,
                            angle: -90,
                            position: "middle",
                            dx: -32,
                            fill: "black",
                            fontSize: "12px",
                          }}
                        />
                        <Bar dataKey="cost" maxBarSize={80}>
                          {TotalCompareGraphData &&
                            TotalCompareGraphData?.map((entry: any) => {
                              return <Cell fill={entry?.color} />;
                            })}
                        </Bar>

                        <Tooltip
                          cursor={false}
                          content={<CustomizedCostTooltip />}
                        />
                      </BarChart>
                    </ResponsiveContainer>
                  )}
                </Box> */}
                <Box sx={{ width: { lg: "75vw", xl: "75vw" }, height: "38vh" }}>
                  {Loader ? (
                    <>
                      <Skeleton></Skeleton>
                      <Skeleton></Skeleton>
                      <Skeleton></Skeleton>
                      <Skeleton></Skeleton>
                      <Skeleton></Skeleton>
                      <Skeleton></Skeleton>
                    </>
                  ) : (
                    <ResponsiveContainer width="100%" height="100%">
                      <BarChart
                        width={500}
                        height={300}
                        data={CompareGraphData}                        
                        margin={{ top: 10, right: 30, left: 30, bottom: 0 }}
                      >
                        <XAxis
                          dataKey="name"
                          interval={0}
                          style={{
                            fontSize: "10px",
                            fontWeight: "bold",
                          }}
                          stroke="#007FFF"
                          tick={<CustomizedTick />}
                        />
                        <YAxis
                          //@ts-ignore
                          left={0}
                          stroke="#007FFF"
                          fill="#1a4faa"
                          fontSize="0.87rem"
                          // type="number"
                          // domain={[(dataMin:any) => (0 - Math.abs(dataMin)),( dataMax:any) => (dataMax * 2)]}
                          domain={[
                            () => 0,
                            (dataMax: number) =>
                              Math.round(dataMax + (dataMax * 5) / 100),
                          ]}
                          label={{
                            value: `Currency (${
                              CompareGraphData && CompareGraphData[0]?.currency
                            })`,
                            angle: -90,
                            position: "middle",
                            dx: -42,
                            fill: "black",
                            fontSize: "12px",
                          }}
                        />
                        <Legend verticalAlign="bottom" align="center" />
                        <Tooltip
                          cursor={false}
                          content={<CustomizedCostTooltip />}
                        />
                        <Bar
                          maxBarSize={80}
                          dataKey="RM Cost"
                          stackId="1"
                          stroke="#8884d8"
                          fill="#8884d8"
                          id="aarea"
                        >
                          
                        </Bar>
                        <Bar
                          maxBarSize={80}
                          dataKey="Process Cost"
                          stackId="1"
                          stroke="#82ca9d"
                          fill="#82ca9d"
                          id="barea"
                          cursor={"pointer"}
                        >
                          
                        </Bar>
                        <Bar
                          maxBarSize={80}
                          cursor={"pointer"}
                          dataKey="Overhead Cost"
                          stackId="1"
                          stroke="#ffc658"
                          fill="#ffc658"
                          id="carea"
                        >
                          
                        </Bar>
                                              </BarChart>
                    </ResponsiveContainer>
                  )}
                </Box>
              </Box>
            ) : (
              <Box
                sx={{ height: { lg: "40vh", xl: "43vh" } }}
                adl-scrollbar="true"
                adl-scrollbar-width="0.3"
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    height: "84vh",
                  }}
                >
                  {/* <Box sx={{ width: "75vw", height: "42vh" }}>
                    {TotalLoader ? (
                      <>
                        <Skeleton></Skeleton>
                        <Skeleton></Skeleton>
                        <Skeleton></Skeleton>
                        <Skeleton></Skeleton>
                        <Skeleton></Skeleton>
                        <Skeleton></Skeleton>
                      </>
                    ) : (
                      <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                          // width={500}
                          // height={300}
                          // data={[...TotalCompareGraphData,...TotalCompareGraphData,...TotalCompareGraphData,...TotalCompareGraphData,...TotalCompareGraphData]}
                          data={TotalCompareGraphData}
                          margin={{
                            top: 20,
                            right: 20,
                            left: 30,
                            bottom: TotalCompareGraphData.length > 10 ? 30 : 5,
                          }}
                        >
                          
                          <XAxis
                            dataKey="name"
                            stroke="black"
                            fontSize="0.75rem"
                            angle={TotalCompareGraphData.length > 10 ? -25 : 0}
                            textAnchor={
                              TotalCompareGraphData.length > 10
                                ? "end"
                                : "middle"
                            }
                          />
                          <YAxis
                            //@ts-ignore
                            left={0}
                            stroke="#007FFF"
                            fill="#1a4faa"
                            fontSize={12}
                            // tick={<CustomizedYAxisTick />}
                            domain={[
                              () => 0,
                              (dataMax: number) =>
                                Math.round(dataMax + (dataMax * 5) / 100),
                            ]}
                            label={{
                              value: `Currency (${
                                TotalCompareGraphData &&
                                TotalCompareGraphData[0]?.currency
                              })`,
                              angle: -90,
                              position: "middle",
                              dx: -32,
                              fill: "black",
                              fontSize: "12px",
                            }}
                          />
                          <Bar dataKey="cost" maxBarSize={80}>
                           
                            {TotalCompareGraphData &&
                              TotalCompareGraphData?.map((entry: any) => {
                                return <Cell fill={entry?.color} />;
                              })}
                          </Bar>

                          <Tooltip
                            cursor={false}
                            content={<CustomizedCostTooltip />}
                          />
                        </BarChart>
                      </ResponsiveContainer>
                    )}
                  </Box> */}
                  <Box sx={{ width: "75vw", height: "42vh" }}>
                    {Loader ? (
                      <>
                        <Skeleton></Skeleton>
                        <Skeleton></Skeleton>
                        <Skeleton></Skeleton>
                        <Skeleton></Skeleton>
                        <Skeleton></Skeleton>
                        <Skeleton></Skeleton>
                      </>
                    ) : (
                      <ResponsiveContainer width="100%" height="100%">
                        <BarChart
                          // width={500}
                          // height={300}
                          data={CompareGraphData}
                          // stackOffset="expand"
                          margin={{
                            top: 10,
                            right: 20,
                            left: 30,
                            bottom: CompareGraphData.length > 10 ? 20 : 5,
                          }}
                        >
                          <XAxis
                            dataKey="name"
                            stroke="black"
                            fontSize="0.75rem"
                            angle={CompareGraphData.length > 10 ? -25 : 0}
                            textAnchor={
                              CompareGraphData.length > 10 ? "end" : "middle"
                            }
                            tick={<CustomizedTick />}
                          />
                          <YAxis
                            //@ts-ignore
                            left={0}
                            stroke="#007FFF"
                            fill="#1a4faa"
                            fontSize={12}
                            // tick={<CustomizedYAxisTick />}
                            domain={[
                              () => 0,
                              (dataMax: number) =>
                                Math.round(dataMax + (dataMax * 5) / 100),
                            ]}
                            label={{
                              value: `Currency (${
                                CompareGraphData &&
                                CompareGraphData[0]?.currency
                              })`,
                              angle: -90,
                              position: "middle",
                              dx: -42,
                              fill: "black",
                              fontSize: "12px",
                            }}
                          />
                          <Legend verticalAlign="bottom" align="center" />
                          <Tooltip
                            cursor={false}
                            content={<CustomizedCostTooltip />}
                          />
                          <Bar
                            maxBarSize={80}
                            dataKey="RM Cost"
                            stackId="1"
                            stroke="#8884d8"
                            fill="#8884d8"
                            id="aarea"
                          >
                            
                          </Bar>
                          <Bar
                            maxBarSize={80}
                            dataKey="Process Cost"
                            stackId="1"
                            stroke="#82ca9d"
                            fill="#82ca9d"
                            id="barea"
                            cursor={"pointer"}
                          >
                           
                          </Bar>
                          <Bar
                            maxBarSize={80}
                            cursor={"pointer"}
                            dataKey="Overhead Cost"
                            stackId="1"
                            stroke="#ffc658"
                            fill="#ffc658"
                            id="carea"
                          >
                            
                          </Bar>
                          
                        </BarChart>
                      </ResponsiveContainer>
                    )}
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
      <DialogComponent
        isOpen={openStatusContent.isOpen}
        onClose={openStatusContent.close}
        content={contentText}
        type={iconText}
      />
    </>
  );
}