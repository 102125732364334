import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  Autocomplete,
  Checkbox,
  Chip,
  Table,
  TableCell,
  TableRow,
  TextField,
  Typography,
  TableHead,
  TableBody,
  Skeleton,
  Paper,
  Badge,
} from "@mui/material";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { useHistory } from "react-router-dom";
import styles from "./CostingScenarioLanding.module.scss";
import { CostingScenarioPage } from "./CostingScenarioPage";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import { AddScenarioModal } from "./AddScenarioModal";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import { useBaseModal } from "../SearchPage/useBaseModal";
import AddAssumptionModal from "./AddAssumptionModal";
import { API } from "../../api-services";
import { CostingScenarioCreateDrawer } from "../CostingCreateModule/CostingScenario/CostingScenarioCreateDrawer";
import { AxiosError, AxiosResponse } from "axios";
import { CostingCreateScenarioMainDrawer } from "../CostingCreateModule/CostingScenario/CostingCreateScenarioMainDrawer";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { CurrencyChange } from "../../ViewModule/CostingView/CurrencyChange";
import SettingsIcon from "@mui/icons-material/Settings";
import { ADMIN } from "../../Redux/Services/admin.service";
import Swal from "sweetalert2";

export interface ICostingScenarioLandingProps {}

interface Params {
  projectId: any;
  projectName: any;
  topVaultIds: any;
  expandTable: any;
  initialTopVault: any;
  scenarioType:any;
}

type Anchor = "top" | "left" | "bottom" | "right";

export function CostingScenarioLanding(props: ICostingScenarioLandingProps) {
  const { projectId, projectName, topVault, vault, scenarioId, treeabbr, scenarioType } =
    useRouteParams<any>();
  const [count, setCount] = React.useState<any>(0);
  const [status, setStatus] = React.useState<any>(null);
  const [productList, setProductList] = React.useState<any>([]);
  const [selectedProduct, setSelectedProduct] = React.useState<any>([]);
  const [counts, setCounts] = React.useState<any>(0);
  const [assumptionsCardCount, setAssumptionCardCount] = React.useState<any>(0);
  const [assCardCounts, setAssCardCounts] = React.useState<any>(0);
  const [configPermission, setConfigPermission] = React.useState<any>("");
  const [permission, setPermission] = React.useState<any>("");
  const [costStatus, setCostStatus] = React.useState<any>(null);
  const [title, setTitle] = React.useState<any>(null);
  const [searchValueOptions, setSearchVaultsOptions] = React.useState<any>()
  const [warningMsg,setwarningMsg]=useState<any>()
  const [locatorCount, setLocatorCount] = React.useState<any>(null)
  const [scenarioDashboardOpen,setScenarioDashboardOpen] = React.useState<any>(false);

  const currencyModal = useBaseModal();
  const history = useHistory();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const getDisableStatus = () => {
    if (costStatus) {
      return false;
    } else if (!costStatus) {
      return true;
    } else {
      return false;
    }
  };

  const getPermission = () => {
    API.get(
      "/cost/scenario_permission/",
      {
        top_vault: topVault,
      },
      0
    )
      .then((res: any) => {
        setPermission(res.data);
      })
      .catch((err: any) => {});
  };

  const checkPermission = () => {
    API.get(
      "/auth/calibration_user_permission/",
      {
        costing_assumption: true,
        action: true,
        top_vault: topVault,
      },
      0
    )
      .then((res: any) => {
        console.log(res.data);
        const tab = res.data.cost_main ? 1 : 2;
        console.log(res.data.cost_scenario, res.data.cost_main);
        window.open(
          `/#/costingconfiguration/${projectId}/${projectName}/assumptioncard/${topVault}/${tab}/${res.data.cost_main}/${res.data.cost_scenario}`
        );
      })
      .catch((err: any) => {});
  };
  

  React.useEffect(() => {
    API.get(
      "/auth/calibration_user_permission/",
      {
        action: true,
        configuration: true,
        project: projectId,
        module: "Costing Configuration",
      },
      0
    )
      .then((res: any) => {
        setConfigPermission(res?.data?.action);
        // sessionStorage.setItem("ConfigPermission", res.data.action);
      })
      .catch((err: any) => {});
  }, []);

  const getObj = () => {
    if (vault == 0) {
      return { top_vault: topVault };
    } else {
      return { vault: vault };
    }
  };

  const getPartInfo = (newInputValue:any) => {
    API.get(
      `/xcpep/vault/search/`,
      { search: newInputValue, project: projectId },
      0
    )
      .then((res) => {
        setSearchVaultsOptions(res.data);
      })
      .catch((err: AxiosError) => {
        setSearchVaultsOptions([]);
        const { data }: any = err?.response;
        Swal.fire({
          icon: "error",
          html: `<div>
                  <br />
                  <p style="color:"red">${data[0]}</p>   
                </div>`,
        });
      });
  }

  const getTableData = () => {
    API.get("/cost/scenario_table/", { ...getObj(), main_cost_info: true },0)
      .then((res: any) => {
        setCostStatus(res?.data[0]?.cost_status);
      })
      .catch((err: any) => {
        console.log("Sever Error");
      });
  };

  // xcpep/vault_info/id

  const getTitleFromVault = () => {
    API.get("xcpep/vault_info/", { vault: vault }, 0)
      .then((res: any) => {
        setTitle(`${res?.data[0]?.part_no} (${res?.data[0]?.name})`);
      })
      .catch((err: any) => {
        console.log("Server Error");
      });
  };

  const getTitleFromTopVault = () => {
    API.get("xcpep/top_vault/", { top_vault: topVault }, 0)
      .then((res: any) => {
        setTitle(`${res?.data[0]?.name}`);
      })
      .catch((err: any) => {
        console.log("Server Error");
      });
  };

  React.useEffect(() => {
    getPermission();
    getTableData();
    if (vault == 0) {
      getTitleFromTopVault();
    } else {
      getTitleFromVault();
    }
  }, [topVault, vault]);

  const getLocationsName = () => {
    API.get(
      "cost/assumption_card/",
      {
        revision_type: 2,
        project: projectId,
      },
      0
    )
      .then((res: any) => {
        setCounts(res.data?.length);
      })
      .catch((err: any) => {});
  };
  const totalAssumptionCardCounts = () => {
    API.get(
      "cost/assumption_card/",
      {
        top_vault: topVault,
        assumption_type: 2,
      },
      0
    )
      .then((res: any) => {
        setAssumptionCardCount(res?.data?.length);
      })
      .catch((err: any) => {});
    // https://api-stage2.xcpep.com/cost/assumption_card/?top_vault=49&assumption_type=2
  };
  React.useEffect(() => {
    getLocationsName();
    totalAssumptionCardCounts();
  }, [topVault, vault, projectId]);
  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      API.get(
        `/xcpep/product_list_new/`,
        { project: `${projectId}`, module: "Cost-Scenario" },
        0
      )
        .then((res: AxiosResponse) => {
          setProductList(res?.data);
          setSelectedProduct([topVault]);
        })
        .catch((err: AxiosError) => {
          console.log(err, "error");
        });
      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <CostingCreateScenarioMainDrawer
      anchorComing={anchor}
      toggleDrawer={toggleDrawer}
      productList={productList}
      setProductList={setProductList}
      selectedProduct={selectedProduct}
      setSelectedProduct={setSelectedProduct}
    />
  );

  const backButtonHandler: any = () => {
    history.goBack();
  };

  useEffect(() => {
    API.get(
      "/customer/costing_conversion/",

      { get_conversion_factor: true, module: "costing_create" },

      0
    ).then((res: any) => {
      sessionStorage.setItem(
        "costingtable",

        res.data[0]?.current_currency
      );
      // currency
      sessionStorage.setItem(
        "currency",

        res.data[0]?.current_currency
      );

      sessionStorage.setItem(
        "actionkey_costingtable",

        res.data[0]?.is_manual ? "mannual" : "auto"
      );

      if (res.data[0]?.is_manual) {
        sessionStorage.setItem(
          "currencyrate_costingtable_mannual",

          res.data[0]?.conversion_factor
        );
      } else {
        sessionStorage.setItem(
          "currencyrate_costingtable",

          (1 / res.data[0]?.conversion_factor).toString()
        );
      }
    });
  }, [count]);

  const AddScenarioModel = useBaseParamsModal();
  const addAssumptionModal = useBaseModal();
  const AddScenarioHandler = () => {
    AddScenarioModel.open();
  };

  useEffect(()=>{


    API.get(`/cost/scenario/`, { scenario_delta: true, vault: vault }, 0)
    .then((res: any) => {
      // setWarningLoader(false);
      setwarningMsg(res?.data);
    })
    .catch((err: any) => {
      // setWarningLoader(false);
    });
  },[vault])

  const DeltaHandler=(id:any)=>{
    API.get(
      "/cost/cost_calculator_update/",
      { vault: warningMsg?.vault },
      0
    )
      .then((res: any) => {
        ADMIN.toast.info("Delta sync is in progress.");
        setCount((prev:any)=>prev+1)
        API.get(`/cost/scenario/`, { scenario_delta: true, vault: vault }, 0)
        .then((res: any) => {
          // setWarningLoader(false);
          setwarningMsg(res?.data);
        })
        .catch((err: any) => {
          // setWarningLoader(false);
        });
      })
      .catch((err: any) => {});
  }

  const handleAssumptionRedirection = () => {
    
      window.open(
    `/#/costingconfiguration/${projectId}/${projectName}/assumptioncard/${topVault}/1/true/true`
  )
  }

  React.useEffect(()=> {
    
    if (title?.split(" ")?.length > 0) {
      getPartInfo(title?.split(" ")?.[0]);
    }

    
    
    
// 
  },[title])

  
  

  return (
    <div>
      {AddScenarioModel.isOpen && (
        <AddScenarioModal
          isOpen={AddScenarioModel.isOpen}
          onCloseModal={AddScenarioModel.close}
          setCount={setCount}
        />
      )}
      {addAssumptionModal.isOpen && (
        <AddAssumptionModal
          isOpen={addAssumptionModal.isOpen}
          onCloseModal={addAssumptionModal.close}
          //   setCounter={setCount}
        />
      )}
      {currencyModal.isOpen && (
        <CurrencyChange
          isOpen={currencyModal.isOpen}
          onCloseModal={currencyModal.close}
          setCount={setCount}
        />
      )}
      <Box>
        <Box className={styles.titleSection}>
          <Box className={styles.titleSectionLeft}>
            <IconButton sx={{ cursor: "pointer" }} onClick={backButtonHandler}>
              <ArrowBackIcon color="primary" titleAccess="Go Back" />
            </IconButton>
            <Typography
              sx={{
                fontSize: "1rem",
                whiteSpace: "nowrap",
                display: "inline-block",
              }}
            >
              Scenario Costing ( {projectName} )
            </Typography>
          </Box>
          <Box className={styles.titleSectionRight}>
            {/* @ts-ignore */}
            {!JSON.parse(sessionStorage.getItem("isCategory"))?.[1] && (
              <Box sx={{textDecoration:"underline", cursor:"pointer"}} onClick={()=> {
                if (searchValueOptions?.length > 0) {
    
                  setLocatorCount(searchValueOptions?.[0])
                }
              }}>{title} </Box>
            )}
            {/* @ts-ignore */}
            {JSON.parse(sessionStorage.getItem("isCategory"))?.[1] && (
              <Box>
                Scenario Dashboard ( {/* @ts-ignore */}
                {JSON.parse(sessionStorage.getItem("isCategory"))?.[0]} )
              </Box>
            )}
            {/* @ts-ignore */}
            {!JSON.parse(sessionStorage.getItem("isCategory"))?.[1] && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "2rem",
                }}
              >
                {treeabbr != 'none'&& treeabbr != 'PRODUCT' && scenarioType == '1' && <>
                {!scenarioDashboardOpen ? <Button
                  variant="contained"
                  className={styles.downloadBtn}
                  size="small"
                  style={{
                    fontSize: "12px",
                    minWidth: "3rem",
                    lineHeight: 1,
                    padding: "0.45rem 0.7rem",
                  }}
                  onClick={() => setScenarioDashboardOpen(true)}
                >
                  Scenario Dashboard
                </Button> :
                <Button
                  variant="contained"
                  className={styles.downloadBtn}
                  size="small"
                  style={{
                    fontSize: "12px",
                    minWidth: "3rem",
                    lineHeight: 1,
                    padding: "0.45rem 0.7rem",
                  }}
                  onClick={() => setScenarioDashboardOpen(false)}
                >
                  Scenario Main Page
                </Button>}
                </>}
                {
                  (warningMsg?.is_delta==true &&  warningMsg?.updated==false) &&
                
                <Button
                  variant="contained"
                  className={styles.downloadBtn}
                  size="small"
                  style={{
                    fontSize: "12px",
                    minWidth: "3rem",
                    lineHeight: 1,
                    padding: "0.45rem 0.7rem",
                  }}
                  onClick={() => DeltaHandler(warningMsg)}
                >
                  Delta Update
                </Button>
}
              

                <Button
                  variant="contained"
                  className={styles.downloadBtn}
                  size="small"
                  endIcon={<ArrowDropDownIcon sx={{ fontSize: "1rem" }} />}
                  style={{
                    fontSize: "12px",
                    minWidth: "3rem",
                    lineHeight: 1,
                    padding: "0.2rem 0.7rem",
                  }}
                  onClick={() => currencyModal.open()}
                >
                  {sessionStorage.getItem("currency")}
                </Button>
                {permission && permission?.includes("R") && (
                  // <Badge
                  //   badgeContent={counts && counts}
                  //   sx={{ marginBottom: "-0.2rem" }}
                  //   color="error"
                  // >
                  <CreditCardIcon
                    sx={{
                      color:
                        assumptionsCardCount > 0 ? "green" : "primary.main",
                      fontSize: { lg: "2.8rem", xl: "2.5rem" },
                      cursor: "pointer",
                      marginTop: "-0.3rem",
                      marginBottom: "-0.3rem",
                    }}
                    onClick={() => addAssumptionModal.open()}
                    titleAccess={`Assumption Card: ${
                      assumptionsCardCount && assumptionsCardCount
                    } Revisions: ${counts && counts}`}
                  />
                  // </Badge>
                )}

                {configPermission && configPermission?.length > 0 && (
                  <SettingsIcon
                    sx={{
                      fontSize: { lg: "2.5rem", xl: "2.5rem" },
                      cursor: "pointer",
                      marginTop: "-0.3rem",
                      marginBottom: "-0.3rem",
                    }}
                    titleAccess="Go to Assumption card configuration"
                    onClick={handleAssumptionRedirection}
                  />
                )}

                {permission &&
                permission?.includes("C") &&
                costStatus && costStatus===true ? (
                  <Button
                    variant="contained"
                    size="small"
                    startIcon={<AddIcon sx={{ marginRight: "-0.5rem" }} />}
                    title="Add Scenario"
                    disabled={getDisableStatus()}
                    onClick={() => AddScenarioHandler()}
                    sx={{
                      padding: "0.2rem 0.7rem",
                      minWidth: "1.5rem",
                      lineHeight: 1,
                      "&:hover": {
                        transform: "Scale(1.05)",
                        transition: "transform 0.5s ease",
                      },
                    }}
                  >
                    Add Scenario
                  </Button>
                ) : (
                  <Typography sx={{ fontSize: "0.9rem", color: "red" }}>
                    <sup style={{ color: "red" }}>*</sup> Validate main costing
                    to add scenario
                  </Typography>
                )}
              </Box>
            )}
          </Box>
        </Box>
        <Box sx={{ display: "flex", alignItems: "flex-start" }}>
          <Box className={styles.expandIconBox}>
            <div className={styles.iconSec}>
              {(["left"] as const).map((anchor) => (
                <React.Fragment key={anchor}>
                  <IconButton
                    size="medium"
                    sx={{
                      backgroundColor: "primary.light",
                      color: "primary.main",
                    }}
                    onClick={toggleDrawer(anchor, true)}
                  >
                    <AddIcon sx={{ fontSize: "1.7rem" }} />
                  </IconButton>
                  <Drawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                    className={styles.drawerContainer}
                    PaperProps={{
                      sx: {
                        backgroundColor: "aliceblue",
                      },
                    }}
                  >
                    <Box
                      sx={{ padding: "1rem" }}
                      adl-scrollbar="true"
                      adl-scrollbar-width="0.3"
                    >
                      <Typography
                        style={{ width: "100%", textAlign: "center" }}
                      >
                        Selection Options
                      </Typography>
                      <Divider sx={{ margin: "1rem" }} />
                      {list(anchor)}
                    </Box>
                  </Drawer>
                  <Typography
                    style={{ cursor: "pointer" }}
                    className={styles.sideTitle}
                    onClick={toggleDrawer(anchor, true)}
                  >
                    Selection Options
                  </Typography>
                </React.Fragment>
              ))}
            </div>
          </Box>
          <Box>
            {/* sx={{ width: {lg:'95.5%',xl:"96.5%"}, height: { lg: "86vh", xl: "86vh" } }} */}
            {/* adl-scrollbar="true"
          adl-scrollbar-width="0.3" */}
            <CostingScenarioPage count={count} setStatus={setStatus} scenarioDashboardOpen={scenarioDashboardOpen} setScenarioDashboardOpen={setScenarioDashboardOpen} locatorCount={locatorCount} setLocatorCount={setLocatorCount}/>
          </Box>
        </Box>
      </Box>
    </div>
  );
}
