import {
    createEntityAdapter,
    createReducer,
    createSelector,
    EntityState,
} from "@reduxjs/toolkit";
import { ID } from "../../utlis/commonInterfaces";
import { getTopVaults, getTopvoultId, createTopVault, editTopVault, deleteTopVault, deleteTopcardVault, copyTopVault, topVaultLocked, resetTopVaults } from "../Actions/topVault.actions";
import { ADMIN } from "../Services/admin.service";
import { RootState } from "../Store/storeConfigurations";



// -----------------------------------------------------------------------------------
// entity adaptor

export interface TopVault {
    lock: boolean;
    //change id type any To ID if any isseue came here
    id: any;
    name: string;
    product_id: number;
    alias: string | null;
    project: ID;
    project_name: string;
    oem: ID | null;
    oem_name: string | null;
    vehicle_type: number;
    type: number;
    manufacturing_year: number;
    error_acceptance: number;
    weight_validation: number;
    category: ID | null;
    status: number;
    cost_status: number;
    component_details: ID | null;
    commodity: ID[];
    idea_base: boolean;
    qr_code: any;
    weight_lock: boolean;
    template_configuration: any;
    created_at: any;
    allowed_action: any;
    can_approve_idea: any;
    config_status?:any;
    module?:any
}

const topVaultAdaptor = createEntityAdapter<TopVault>( {
    selectId: topVault => topVault.id,
    sortComparer: ( a, b ) => a.product_id - b.product_id,
} );

// -----------------------------------------------------------------------------------
// interfaces & initialState

type AdditionalFields = {};

type ITopVaultReducer = EntityState<TopVault> & AdditionalFields;

const additionalFields: AdditionalFields = {};


const initialState: ITopVaultReducer = Object.assign(
    {},
    topVaultAdaptor.getInitialState(),
    additionalFields
);



// -----------------------------------------------------------------------------------
// reducer

const TopVaultReducer = createReducer( initialState, builder => {
    // get
    builder
        .addCase( getTopVaults.fulfilled, ( state, action: any ) => {
            topVaultAdaptor.setAll( state, action.payload.topVaults );
        } )
        .addCase( getTopVaults.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could no fetch variants" );
        } );
    //getTopvoultId
    builder
        .addCase( getTopvoultId.fulfilled, ( state, action: any ) => {
            topVaultAdaptor.setAll( state, action.payload.topVaults );
        } )
        .addCase( getTopvoultId.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could no fetch variants" );
        } );
    // create
    builder
        .addCase( createTopVault.fulfilled, ( state, action: any ) => {
            topVaultAdaptor.addOne( state, action.payload.topVault );

            ADMIN.toast.success( "Variants created" );
        } )
        .addCase( createTopVault.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could no create variants" );
        } );

    // update
    builder
        .addCase( editTopVault.fulfilled, ( state, action ) => {
            const { id, updates: changes } = action.meta.arg;
            topVaultAdaptor.updateOne( state, { id, changes } );

            ADMIN.toast.success( "Variants updated" );
        } )
        .addCase( editTopVault.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could no update variants" );
        } );

    // delete
    builder
        .addCase( deleteTopVault.fulfilled, ( state, action ) => {
            const { id } = action.meta.arg;
            topVaultAdaptor.removeOne( state, id );

            ADMIN.toast.success( "variants deleted" );
        } )
        .addCase( deleteTopVault.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could no delete variants" );
        } );

    //deleteTopcardVault

    builder
        .addCase( deleteTopcardVault.fulfilled, ( state, action ) => {
            const { id } = action.meta.arg;
            topVaultAdaptor.removeOne( state, id );

            ADMIN.toast.success( "variants deleted" );
        } )
        .addCase( deleteTopcardVault.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could no delete variants" );
        } );
    // copy
    builder
        .addCase( copyTopVault.fulfilled, ( state, action: any ) => {
            topVaultAdaptor.addOne( state, action.payload.topVault );

            ADMIN.toast.success( "Copied Variants Created" );
        } )
        .addCase( copyTopVault.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could no create variants" );
        } );

    // copy
    builder
        .addCase( topVaultLocked.fulfilled, ( state, action: any ) => {
            const { topVault }: any = action.payload;
            if ( action.payload.topVault.lock ) { ADMIN.toast.error( "This Product is Locked " ); }
            else { ADMIN.toast.success( " This Product is Unlocked " ); }
        } )
        .addCase( topVaultLocked.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could no create variants" );
        } );


    builder.addCase( resetTopVaults, () => initialState );
} );



// -----------------------------------------------------------------------------------
// selectors



const selectors = topVaultAdaptor.getSelectors<RootState>(
    state => state.topVaults
);



const selectByProjectId = createSelector(
    selectors.selectAll,
    ( _state: RootState, projectId: ID | null ) => projectId, ( topVaults, projectId ) => {
        return projectId
            ? topVaults.filter( topVault => topVault.project === projectId )
            : [];
    }
);




const selectOptions = createSelector( selectors.selectAll, topVaults => {
    return topVaults.map( topVault => ( {
        key: topVault.id,
        value: topVault.id,
        text: topVault.name,
    } ) );
} );


const selectBaseVehicle = createSelector( selectors.selectAll, topVaults => {
    return topVaults.filter( topVault => topVault.idea_base === true );
} );

const topVaultSelectors = Object.assign(
    {},
    { selectByProjectId, selectOptions , selectBaseVehicle },
    selectors
);

// -----------------------------------------------------------------------------------
// exports

export {
    TopVaultReducer,
    initialState as TopVaultReducerInit,
    topVaultSelectors,
};
