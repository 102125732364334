
const getOptionsMap = ( options: Record<string, any>[] ) => {
    return options.reduce( ( map, option ) => {
        map[option.value] = option.text;
        return map;
    }, {} as Record<string, any> );
};

export {
    getOptionsMap
};