import {
    Autocomplete,
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
  } from "@mui/material";
  import { debounce } from "lodash";
  import * as React from "react";
  import { API } from "../../api-services";
  import { ADMIN } from "../../Redux/Services/admin.service";
  import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
  import Swal from "sweetalert2";
  
  export interface ILibraryParameterDropdownBoxProps {
    selectedData: any;
    allData:any;
    iddd:any;
    getFeatureDataWOL:any;
    selectedProduct:any;
  }
  
  export function LibraryParamsDropdownBoxModal(
    props: ILibraryParameterDropdownBoxProps
  ) {
    const {selectedData,allData,iddd,getFeatureDataWOL,selectedProduct } = props;
    const [value, setValue] = React.useState<any>("");
    const [inputValue, setInputValue] = React.useState<any>("");
    const [selectedValue, setSelectedValue] = React.useState<any>({});
    const [options, setOptions] = React.useState<any>([]);
    const { feId } = useRouteParams<any>();
  
    const getSearchOptions = async (value: any) => {
      API.get(
        "/api/db/feature_library/",
        { name_type: selectedData?.name_type, search_key: value },
        0
      )
        .then((res: any) => {
          if (res.data?.length > 0) {
            setOptions(res.data);
          }
        })
        .catch((err: any) => {});
    };
  
    React.useEffect(() => {
      if (selectedData && selectedData?.name_details) {
        setSelectedValue(selectedData?.name_details);
        setInputValue(selectedData?.name_details?.name);
        setOptions([selectedData?.name_details]);
      }
    }, [selectedData]);
  
    const ExactDebounceParameters = React.useRef(
      debounce(async (value: any) => {
        await getSearchOptions(value);
      }, 1000)
    ).current;
  
    const handleInputChange = (e: any, newInputValue: any) => {
      if (e?.type == "change" && typeof newInputValue === "string") {
        if (newInputValue && typeof newInputValue === "string") {
          setInputValue(newInputValue);
        }
  
        if (typeof newInputValue === "string") {
          // Make an API call here to fetch search results
          ExactDebounceParameters(newInputValue);
        } else {
          setOptions([]); // Clear the options when the input is empty
        }
      }
    };
  
    return (
      <Box sx={{display:'flex',alignItems:'center'}}>
        <FormControl fullWidth>      
          <Autocomplete
            title={selectedValue && selectedValue?.unit && selectedValue?.unit !== 'null' && selectedValue?.unit !== '' ? selectedValue?.name +" "+ "("+selectedValue?.unit+")" : selectedValue?.name}
            options={options}
            getOptionLabel={(option: any) => option?.name || ""}
            value={selectedValue && selectedValue}
            autoSelect
            inputValue={inputValue || ""}
            onChange={(event: any, newValue: any) => {
              if (newValue) {
                setSelectedValue(newValue);
                setInputValue(newValue?.name);
                API.put(
                  `/xcpep/feature_details/${iddd}/`,
                  {
                    product_feature_library_id: selectedData?.product_library_id,
                    library_id: newValue?.id,
                  },
                  {},
                  0
                )
                  .then((res: any) => {
                    ADMIN.toast.info("Parameter Selected Successfully");
                    getFeatureDataWOL(selectedProduct);
                  })
                  .catch((err: any) => {
  
                      Swal.fire({
                      title: "Error!",
                      text: err.response.data[0],
                      icon: "error",
                      confirmButtonText: "Ok",
                      })
                  });
                // setSelectedId(newValue?.id);
              } else {
                setSelectedValue({});
                setInputValue("");
              }
            }}
            sx={{
              width: { xs: "80%", sm: "80%", md: "80%", lg: "140px",xl:'190px' },
              ".MuiButtonBase-root.MuiAutocomplete-popupIndicator":
                {
                  color: "primary.main",
                },
              ".MuiButtonBase-root.MuiChip-root": {
                backgroundColor: "primary.light",
              },
            }}
            onInputChange={(e: any, newInputValue: any) =>
              handleInputChange(e, newInputValue)
            }
            renderOption={(props, option) => (
              <li {...props} style={{ fontSize: '1rem' }}>
                {option?.name} {option?.unit}
              </li>
            )}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                placeholder="Search parameter"
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "transparent",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "primary.main",
                  },
                  ".MuiInputBase-root.MuiInput-root": {
                    fontSize: "12px",
                    backgroundColor: "rgba(255,255,255,0.3)",
                    paddingLeft: "0.3rem",
                  },
                  ".MuiSvgIcon-root": { color: "primary.main" },
                }}
              />
            )}
          />        
        </FormControl> 
        {selectedValue && selectedValue?.unit && selectedValue?.unit !== 'null' && selectedValue?.unit !== '' ? <> ({selectedValue?.unit})</> : <><Typography sx={{color:'primary.light',fontSize:'1rem'}}>(Unit)</Typography></>}          
      </Box>
    );
  }
  