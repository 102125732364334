import { useState } from "react";

// ----------------------------------------------------------------------------------

const useBaseAnalysisMaterial = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [propsData, setPropsData] = useState<any>();
  const [propsMaterialName, setPropsMaterialName] = useState<any>();

  const handleOpen = (data:any,materialName:any) => {
    setPropsData(data);
    setPropsMaterialName(materialName);
    setIsOpen(true);
  };

  const handleClose = () => {
    setPropsData(undefined);
    setIsOpen(false);
    setPropsMaterialName(undefined)
  };

  return Object.freeze({
    open: handleOpen,
    close: handleClose,
    isOpen,
    propsData,
    propsMaterialName
  });
};

// ----------------------------------------------------------------------------------

export { useBaseAnalysisMaterial };
