import * as React from "react";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { TextField } from "@mui/material";
import { API } from "../../api-services";
import { debounce } from "lodash";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { increaseCounter, validationCheckCounter } from "../../Redux/Actions/compareopen.action";
import Swal from "sweetalert2";
import { LoadingButton } from "@mui/lab";
import SaveIcon from '@mui/icons-material/Save';

export interface IEditFeatureNameProps {
  value: any;
  idd: any;
  permission?: any;
}

export function EditGroupName(props: IEditFeatureNameProps) {
  const history = useHistory();
  const store: any = useSelector((state) => state);
  const dispatch = useDispatch();

  const { projectId, projectname, featureId, feId, pageNumber, parent, child, type } = useRouteParams<any>();
  const { value, idd,permission } = props;
  const [color,setColor]=React.useState<any>('')
  const [featureName, setFeatureName] = React.useState<any>("");
  React.useEffect(() => {
    if (value) {
      setFeatureName(value);
    }
  }, [value]);
  // const ExactDebounce = React.useRef(
  //   debounce(async (value: any) => {
  //     await API.put(`/xcpep/feature_groups/${child}/`, { name: value }, {}, 0)
  //       .then((res: any) => {
  //         var random: any = Math.floor(Math.random() * 1000000 + 1);
  //         history.push(`/createfeature/${projectId}/${projectname}/${type}/${parent}/${child}/${featureId}/${feId}/${pageNumber}`);
  //         dispatch(increaseCounter(random));
  //         dispatch(validationCheckCounter(random + 1));
  //         setColor("red")
  //       })
  //       .catch((err: any) => {
  //         const { data } = err?.response;
  //         Swal.fire({
  //           icon: "error",
  //           html: `<div>
  //             <br />
  //             <p style="color:"red">${data[0]}</p>   
  //              </div>`,
  //         });
  //         setColor("red")
  //       });
  //   }, 500)
  // ).current;

  // React.useEffect(() => {
  //   return () => {
  //     ExactDebounce.cancel(); // Cancel the debounced function
  //   };
  // }, []);

  const handleonChange = (e: any) => {
    const { value } = e.target;
    setColor("red")
    setFeatureName(value);
  };

  return (
    <div style={{ display: "flex",alignItems:'center',columnGap:'0.5rem' }}>
      <TextField
        variant="standard"
        disabled={!permission?.includes("U")}
        sx={{
          ".MuiInputBase-root.MuiInput-root": {
            fontSize: "1rem !important",
          },
          "& .MuiInput-underline:before": { borderBottomColor: "white" },
          "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
        }}
        onChange={handleonChange}
        value={featureName}
        focused={true}
      />
      <LoadingButton sx={{
          minWidth: "1rem",
          padding: "0.2rem 0.5rem",
          lineHeight: 1,         
          ".MuiCircularProgress-root": {
            color: "primary.main",
          },
        }}> 
        <SaveIcon sx={{cursor:'pointer',color:color}} onClick={()=>{
          API.put(`/xcpep/feature_groups/${child}/`, { name: featureName }, {}, 0)
          .then((res: any) => {
            var random: any = Math.floor(Math.random() * 1000000 + 1);
            history.push(`/createfeature/${projectId}/${projectname}/${type}/${parent}/${child}/${featureId}/${feId}/${pageNumber}`);
            dispatch(increaseCounter(random));
            dispatch(validationCheckCounter(random + 1));
            setColor(undefined)
          })
          .catch((err: any) => {
            const { data } = err?.response;
            Swal.fire({
              icon: "error",
              html: `<div>
                <br />
                <p style="color:"red">${data[0]}</p>   
                 </div>`,
            });
            setColor(undefined)
          });
        }}/>
      </LoadingButton>
    </div>
  );
}
