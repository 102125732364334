// react
import React, { useEffect, useMemo, useRef, useState } from "react";

// vendors
import { RouteComponentProps, useHistory, useParams } from "react-router-dom";
import {
  invert,
  isNull,
  isNumber,
  isUndefined,
  mapValues,
  omit,
  pickBy,
  uniqBy,
} from "lodash";
// import { Button, Dropdown, DropdownProps, Icon, IconProps, Input, InputOnChangeData, Table } from 'semantic-ui-react';
import * as yup from "yup";
import FactCheckIcon from "@mui/icons-material/FactCheck";
import FactCheckOutlinedIcon from "@mui/icons-material/FactCheckOutlined";
// services
// import calculatorInputService from 'app/services/calculatorInput.service';
// import { ADMIN, API } from 'app/services';
// import bomConfigService from 'app/services/bomConfig.service';
// import processConfigService from 'app/services/processConfig.service';
// import calculatorService from 'app/services/calculator.service';

// utils
// import { deleteSwal } from 'app/utils/deleteSwal';
// import yupValidate from 'app/utils/yupValidate';
// import { pipe } from 'app/utils/other';

// constants
// import {
//     calculatorCategoryOptions,
//     calculatorCostInputOptions,
//     calculatorDatabaseColumnsOptions,
//     calculatorDatabaseOptions,
//     calculatorInputBackgroundColorMap,
//     TriangulationInputOptions,
//     CALCULATOR_COST_INPUT_MAP,
//     CALCULATOR_INPUT_CONTENT_TYPE,
//     CALCULATOR_INPUT_TYPE,
//     CALCULATOR_TYPE,
//     InputContentType,
//     triangulation_calculator
// } from 'app/constants/calculator.constants';
// import { transformConfigurationsFromApi } from 'app/constants/configuration.constants';
// import { numericDataTypes } from 'app/constants/parametersDataTypes.constants';

// factories
// core
// import Crumbs from 'app/Core/Crumbs';
// import { useCollection } from 'app/Core/CustomHooks/useCollection';
// import useFetch from 'app/Core/CustomHooks/useFetch';
// import NoData from 'app/Core/NoData';

// redux
// import { CalculatorInput } from 'app/Redux/Reducers/calculatorInput.reducer';
// import { ParameterConfig } from 'app/Redux/Reducers/parameterConfig.reducer';
// import { ICalculator } from 'app/Redux/Reducers/calculator.reducer';

// ui-components
// import { BouncingLoader } from 'app/ui-components';

// others
// styles
import styles from "./IdeaCostCalculator.module.scss";

// interfaces and types
// import { fetched, ID, ObjectType } from 'app/commonInterfaces';
import RViewerJS from "viewerjs-react";
import {
  CALCULATOR_INPUT_CONTENT_TYPE,
  CALCULATOR_INPUT_TYPE,
  CALCULATOR_TYPE,
  InputContentType,
  TriangulationInputOptions,
  calculatorCategoryOptions,
  calculatorCostInputOptions,
  calculatorDatabaseColumnsOptions,
  calculatorDatabaseOptions,
  calculatorInputBackgroundColorMap,
  triangulation_calculator,
} from "../../../Configuration/Costing/calculator.constant";
import { ID, ObjectType, fetched } from "../../../utlis/commonInterfaces";
import { CalculatorInput } from "../../../Redux/Reducers/calculatorInput.reducer";
import yupValidate from "../../../utlis/yupValidate";
import { ParameterConfig } from "../../../Redux/Reducers/parameterConfig.reducer";
import { numericDataTypes } from "../../../utlis/parameterDataTypes.constants";
import { pipe } from "../../../utlis/other";
import { useCollection } from "../../../CustomHook/useCollection";
import useFetch from "../../../Core/CustomHooks/useFetch";
import { transformConfigurationsFromApi } from "../../../utlis/configuration.constants";
import { ICalculator } from "../../../Redux/Reducers/calculator.reducer";
import calculatorService from "../../../Redux/Services/calculator.service";

import calculatorInputService from "../../../Redux/Services/calculatorInput.service";
import {
  Box,
  Button,
  Divider,
  FormControl,
  IconButton,
  IconProps,
  MenuItem,
  Select,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  TextareaAutosize,
  Typography,
  debounce,
} from "@mui/material";
import { deleteSwal } from "../../../utlis/deleteSwal";
import BouncingLoader from "../../../ui-reusable-component/BouncingLoader";
import SaveIcon from "@mui/icons-material/Save";
import DeleteIcon from "@mui/icons-material/Delete";
import bomconfigService from "../../../Redux/Services/bomconfig.service";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import SyncIcon from "@mui/icons-material/Sync";
import { LoadingButton } from "@mui/lab";
import { API } from "../../../api-services";
import { TextFields } from "@mui/icons-material";
import { ADMIN } from "../../../Redux/Services/admin.service";
import FileCopyRoundedIcon from "@mui/icons-material/FileCopyRounded";
import { UserAction } from "./IdeaGenerationViewLanding";
import InfoIcon from "@mui/icons-material/Info";
import Popover from '@mui/material/Popover';

// lazy

// ----------------------------------------------------------------------------------
// params



// ----------------------------------------------------------------------------------
// constants

const inputKeysToOmit: Record<number, string[]> = {
  [CALCULATOR_INPUT_TYPE.BOM_PARAMETER]: [
    "database",
    "formula",
    "constant",
    "volume",
    "attribute",
    "user_input",
  ],
  [CALCULATOR_INPUT_TYPE.COMMODITY_PARAMETER]: [
    "database",
    "formula",
    "attribute",
    "constant",
    "volume",
    "user_input",
  ],
  // [CALCULATOR_INPUT_TYPE.CONSTANT]: [
  //   "database",
  //   "formula",
  //   "parameter",
  //   "user_input",
  //   "volume",
  //   "table_element",
  // ],
  [CALCULATOR_INPUT_TYPE.CONSTANT]: [
    "database",
    "formula",
    "parameter",
    "volume",
    "table_element",
  ],
  [CALCULATOR_INPUT_TYPE.VOLUME]: [
    "database",
    "formula",
    "parameter",
    "constant",
    "user_input",
    "table_element",
  ],
  [CALCULATOR_INPUT_TYPE.DATABASE]: [
    "formula",
    "constant",
    "volume",
    "parameter",
    "user_input",
    "table_element",
  ],
  [CALCULATOR_INPUT_TYPE.FORMULA]: [
    "database",
    "constant",
    "volume",
    "parameter",
    "attribute",
    "table_element",
  ],
  [CALCULATOR_INPUT_TYPE.USER_INPUT]: [
    "database",
    "constant",
    "volume",
    "parameter",
    "attribute",
    "formula",
    "table_element",
  ],
};
interface CalculatorInputFromApi {
  id: ID;
  content_type: InputContentType;
  name: string;
  /** string used to refere the input in the formula */
  alias: string;

  parameter?: ID;
  table_element?: ID;

  attribute?: string;

  formula?: string;
  constant?: number;
  user_input?: string;
  calculator_category?: number;
  volume?: number;
  calculator: ID;
  image_url?: string;
  test_input?: number;
  result?: number;
}

const inputContentTypeToDatabaseMap: ObjectType<number> = {
  Material: 1,
  Machine: 2,
  Labour: 3,
  ToolDatabase: 4,
  FastenerDatabase: 5,
  PurchasingItem: 6,
  StrokeRateDatabase: 7,
};

const databaseMapToConntentType = invert(inputContentTypeToDatabaseMap);

const styleDivider = {
  width: '100%',
  height: '1px',
  backgroundColor: 'primary.main',
};


export const transformCalculatorInputFromApi = (
  input: CalculatorInputFromApi
): CalculatorInput => {
  const { content_type, ...rest } = input;

  const obj: any = {};
  if (content_type) {
    if (rest.parameter) {
      if (content_type === CALCULATOR_INPUT_CONTENT_TYPE.BOM_PARAMETER)
        obj.type = CALCULATOR_INPUT_TYPE.BOM_PARAMETER;
      if (content_type === CALCULATOR_INPUT_CONTENT_TYPE.COMMODITY_PARAMETER)
        obj.type = CALCULATOR_INPUT_TYPE.COMMODITY_PARAMETER;
    } else if (rest.attribute) {
      if (content_type === CALCULATOR_INPUT_CONTENT_TYPE.COST_INPUT) {
        obj.type = CALCULATOR_INPUT_TYPE.COST_INPUT;
      } else {
        obj.type = CALCULATOR_INPUT_TYPE.DATABASE;
        obj.database = inputContentTypeToDatabaseMap[content_type];
      }
    } else if (isNumber(rest.calculator_category)) {
      obj.type = CALCULATOR_INPUT_TYPE.CALCULATOR_RESULT;
    }
  }
  if (rest.formula) obj.type = CALCULATOR_INPUT_TYPE.FORMULA;
  else if (!isNull(rest.user_input))
    obj.type = CALCULATOR_INPUT_TYPE.USER_INPUT;
  if (rest.constant || rest.constant == 0) obj.type = CALCULATOR_INPUT_TYPE.CONSTANT;

  if (rest.volume) obj.type = CALCULATOR_INPUT_TYPE.VOLUME;

  return Object.assign({}, rest, obj);
};

const toApi = async (
  input: CalculatorInput,
  colorstatus: any,
  setValidationMsg: any
): Promise<CalculatorInputFromApi> => {
  const transformedInput = transformInput(input) as Required<CalculatorInput>;
  try {
    const validatedInput = (await yupValidate(
      schema,
      transformedInput
    )) as CalculatorInput;

    const { type, database, ...rest } = validatedInput;


    const obj: any = {};

    if (database) {
      obj.content_type = databaseMapToConntentType[database];
    }
    if (type === CALCULATOR_INPUT_TYPE.BOM_PARAMETER) {
      obj.content_type = CALCULATOR_INPUT_CONTENT_TYPE.BOM_PARAMETER;
    }

    if (type === CALCULATOR_INPUT_TYPE.COMMODITY_PARAMETER) {
      obj.content_type = CALCULATOR_INPUT_CONTENT_TYPE.COMMODITY_PARAMETER;
    }
    if (type === CALCULATOR_INPUT_TYPE.COST_INPUT) {
      obj.content_type = CALCULATOR_INPUT_CONTENT_TYPE.COST_INPUT;
    }
    if (type === CALCULATOR_INPUT_TYPE.CALCULATOR_RESULT) {
      obj.content_type = CALCULATOR_INPUT_CONTENT_TYPE.CALCULATOR_RESULT;
    }
    if (type === CALCULATOR_INPUT_TYPE.USER_INPUT) {
      obj.content_type = null;
    }
    colorstatus("")
    return pickBy(
      Object.assign({}, rest, obj),
      (value) => !isUndefined(value)
    ) as any;
  } catch (e: any) {
    const msg = Object.values(e).join(", ");
    // colorstatus("red")
    // setValidationMsg(e)


    throw new Error(msg);

  }
};

// ----------------------------------------------------------------------------------
// helpers

const generateConfigsOptions = (configs: ParameterConfig[]) => {
  return configs.map((config) => ({
    key: config.id,
    value: config.parameter_id,
    text: config.name,
  }));
};

// to only maintain keys that are related to the input type during saving of data
const transformInputs = (inputs: CalculatorInput[]) => {
  return inputs.map(transformInput);
};

/** removes unwanted columns depending on type */
const transformInput = (input: CalculatorInput) => {
  return omit(input, inputKeysToOmit[input.type] || []) as CalculatorInput;
};

const numericOrTableDataTypes = numericDataTypes.concat("table");
const filterNumericOrTableConfigs = (configs: ParameterConfig[]) => {
  return configs.filter((config) =>
    numericOrTableDataTypes.includes(config.data_type)
  );
};

const filterNonTableColumnConfigs = (configs: ParameterConfig[]) => {
  return configs.filter((config) => !config.parameter_table_id);
};

const filterTableColumnConfigs = (
  configs: ParameterConfig[],
  parameterId: ID
) => {
  return configs.filter((config) => config.parameter_table_id === parameterId);
};

/** configs that need to be shown for BOM/Commodity parameters dropdown */
const getConfigsOptions = pipe(
  filterNumericOrTableConfigs,
  filterNonTableColumnConfigs,
  generateConfigsOptions
);

/** configs that need to shown for columns of selectedParameter */
const getTableColumnConfigsOptions = (
  configs: ParameterConfig[],
  parameterId: ID
) => {
  const tableColumnConfigs = filterTableColumnConfigs(configs, parameterId);
  return pipe(
    filterNumericOrTableConfigs,
    generateConfigsOptions
  )(tableColumnConfigs);
};

// ----------------------------------------------------------------------------------
// schema

const schema = yup.object().shape({
  type: yup.number().required('Type is required'),
  name: yup.string().required('Name is required'),
  //   alias: yup.string().required('"As" is required'),
  parameter: yup.mixed().when("type", {
    is: (type) =>
      [
        CALCULATOR_INPUT_TYPE.BOM_PARAMETER,
        CALCULATOR_INPUT_TYPE.COMMODITY_PARAMETER,
      ].includes(type),
    then: yup
      .number()
      .transform((value) => (Boolean(value) ? value : undefined))
      .required('Parameter is required'),
    otherwise: yup.number().transform(() => undefined),
  }),
  table_element: yup.mixed().when("type", {
    is: (type) =>
      [
        CALCULATOR_INPUT_TYPE.BOM_PARAMETER,
        CALCULATOR_INPUT_TYPE.COMMODITY_PARAMETER,
      ].includes(type),
    then: yup
      .number()
      .transform((value) => (Boolean(value) ? value : undefined))
      .notRequired(),
    otherwise: yup.number().transform(() => undefined),
  }),
  attribute: yup.string().when("type", {
    is: (type) =>
      [
        CALCULATOR_INPUT_TYPE.DATABASE,
        CALCULATOR_INPUT_TYPE.COST_INPUT,
      ].includes(type),
    then: yup
      .string()
      .transform((value) => (isNull(value) ? "" : value))
      .required('"Column/Cost Type" is required'),
    otherwise: yup.string().transform(() => undefined),
  }),
  database: yup.number().when("type", {
    is: CALCULATOR_INPUT_TYPE.DATABASE,
    then: yup
      .number()
      .transform((value) => (Boolean(value) ? value : undefined))
      .required('"Database" is required'),
    otherwise: yup.number().transform(() => undefined),
  }),
  formula: yup.string().when("type", {
    is: CALCULATOR_INPUT_TYPE.FORMULA,
    then: yup
      .string()
      .transform((value) => (isNull(value) ? "" : value))
      .required('Formula is required'),
    otherwise: yup.string().transform(() => undefined),
  }),
  constant: yup.number().when("type", {
    is: CALCULATOR_INPUT_TYPE.CONSTANT,
    then: yup
      .number()
      .transform((value) => (Boolean(value) ? value : 0))
      .notOneOf([0])
      .required('Constant Value is required'),
    otherwise: yup.number().transform(() => undefined),
  }),
  // volume: yup.number().when( "type", {
  //     is: CALCULATOR_INPUT_TYPE.VOLUME,
  //     then: yup.string().transform( value => isNull( value ) ? "" : value ),
  //     otherwise: yup.number().transform( () => undefined )
  // } ),
  user_input: yup.string().when("type", {
    is: (type) =>
      [

        CALCULATOR_INPUT_TYPE.USER_INPUT,
        CALCULATOR_INPUT_TYPE.CONSTANT,
        CALCULATOR_INPUT_TYPE.FORMULA,
      ].includes(type),
    then: yup.string().transform((value) => (isNull(value) ? "" : value)),
    otherwise: yup.string().transform(() => undefined),
  }),
  calculator_category: yup.number().when("type", {
    is: CALCULATOR_INPUT_TYPE.CALCULATOR_RESULT,
    then: yup
      .number()
      .transform((value) => (isNumber(value) ? value : undefined))
      .required('"Calculator Category" is required'),
    otherwise: yup.number().transform(() => undefined),
  }),
});

// ----------------------------------------------------------------------------------

interface InputConfigurationProps {

  projectID: any;
  topVaultID: any;
  type: any;
  Item: any;
}

const IdeaCostCalculator: React.FC<InputConfigurationProps> = (props) => {
  const { projectId, projectName, topVault, subSystem, subSystemId, expandTable, abbr, partId, depth, vault_id } = useParams<any>()

  //   const { calculatorId, projectID, type, topVaultID } = mapValues(
  //     params,
  //     Number
  //   );
  const {
    projectID, topVaultID, type,


    Item } = props
  // ----------------------------------------------------------------------------------
  // state
  const history = useHistory<any>();

  const [inputsFetched, setInputsFetched] = useState<fetched>("FALSE");
  const [isUploading, setIsUploading] = useState<boolean>(false);
  const [currentSelected, setCurrentSelected] = useState<any>(-1);
  const [Loader, setloader] = useState<any>(false);

  const inputElRef = useRef<any>();
  const imageViewerElRef = useRef<any>();
  const [color, setColor] = useState<any>("blue");
  const [ValidationMsg, setValidationMsg] = useState<any>()
  const [saveApiCallCount, setSaveApiCallCount] = useState<any>(0);
  const [activeId, setActiveId] = useState<any>();
  const [calculator, setcalculator] = useState<any>(null)
  const [ValidationIds, setValidationIds] = useState<any>(null)
  const [ResultData, setResultData] = useState<any>("")
  const [DataList, setDataList] = useState<any>([])
  const [expandInput, setExpandInput] = useState<any>();
  const [formulaTextArea, setFormulaTextArea] = useState<any>("");
  const [expand, setExpand] = useState<any>(false);
  const [formulaCheckedStatus, setFormulaCheckedStatus] = useState<any>(false);
  const [SaveStatus, setSaveStatus] = useState<any>("#007fff")
  const [SaveLoader, setSaveLoader] = useState<any>(false)
  const [textToCopy, setTextToCopy] = useState<any>("");
  const [isCopied, setIsCopied] = useState<any>(false);
  const [activeCopyId, setActiveCopyId] = useState<any>();
  const useraction = React.useContext<any>(UserAction)
  // ----------------------------------------------------------------------------------
  // collection hook to hold inputs

  const inputs: any = useCollection<CalculatorInput>();


  // popover
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const poid = open ? 'simple-popover' : undefined;



  // ----------------------------------------------------------------------------------
  // fetch parameter mappings

  // fetch bom parameters
  // const { 1: bomConfigs } = useFetch<ParameterConfig[]>({
  //   method: bomconfigService.getByProjectId,
  //   args: [projectID, true],
  //   transformResData(data) {
  //     const configs = transformConfigurationsFromApi(data);

  //     return uniqBy(configs, (config) => config.parameter_id);
  //   },
  //   fallBackErrMsg: "Could not fetch bom parameters",
  // });

  //   const calculatorName = triangulation_calculator.filter(
  //     (data: any) => data.key == calculatorId
  //   );

  //   const {
  //     0: gettingCalvalue,
  //     1: calculator,
  //     2: setCalculator,
  //   } = useFetch<ICalculator>({
  //     method: calculatorService.getByIdTriangulation,
  //     args: [topVaultID, calculatorName[0]?.value],
  //     getZeroth: true,
  //   });
  useEffect(() => {
    API.get(`/idea/idea_cost_calculation/`, { idea_id: vault_id, config_id: Item, calculation: true }, 0).then((res: any) => {
      setResultData(res?.data?.calculator_result)
      setcalculator(res?.data)
    })
  }, [vault_id, Item])
  // ----------------------------------------------------------------------------------
  // helpers

  // const bomTableConfigOptions = (parameterId: ID) => {
  //   return bomConfigs
  //     ? getTableColumnConfigsOptions(bomConfigs, parameterId)
  //     : [];
  // };

  type DE_TYPE =
    | typeof CALCULATOR_INPUT_TYPE.BOM_PARAMETER
    | typeof CALCULATOR_INPUT_TYPE.COMMODITY_PARAMETER;
  /**
   * indicates whether the give parameter id is table or not
   * @param type whether the parameterId needs to found in bom or peocess paramaters
   * @param parameterId id
   */

  // ----------------------------------------------------------------------------------
  // memorization

  // const bomConfigsOptions = useMemo(() => {
  //   return bomConfigs ? getConfigsOptions(bomConfigs) : [];
  // }, [bomConfigs]);

  // ----------------------------------------------------------------------------------
  // api helpers

  const fetchInputs = async () => {
    setInputsFetched("FETCHING");
    try {
      if (calculator !== null) {
        const { data } = await calculatorInputService.getIdeaCost(
          calculator?.calculator
        );
        setDataList(data)
        // console.log(data,"datadatadata")
        inputs.setAll(
          data
            .map(transformCalculatorInputFromApi)
            .sort((a: any, b: any) => a.sequence - b.sequence)
        );
        setInputsFetched("TRUE");
      }
    } catch (e) {
      // ADMIN.toast.throwError( e, "Could not fetch input" );
      setInputsFetched("ERROR");
    }
  };
  const correctFormula = (formula: string) => {
    const openingBrackets = "([{";
    const closingBrackets = ")]}";
    const stack: string[] = [];

    let correctedFormula = "";

    for (const char of formula) {
      if (openingBrackets.includes(char)) {
        stack.push(char); // Push opening brackets onto the stack
      } else if (closingBrackets.includes(char)) {
        const correspondingOpeningBracket =
          openingBrackets[closingBrackets.indexOf(char)];

        // If the stack is not empty and the top of the stack matches the corresponding opening bracket
        if (
          stack.length > 0 &&
          stack[stack.length - 1] === correspondingOpeningBracket
        ) {
          stack.pop(); // Pop the matching opening bracket from the stack
        } else {
          // Add a missing opening bracket to the corrected formula
          correctedFormula += correspondingOpeningBracket;
        }
      }

      correctedFormula += char; // Add the current character to the corrected formula
    }

    // Add any missing closing brackets for the remaining opening brackets on the stack
    while (stack.length > 0) {
      const missingClosingBracket =
        closingBrackets[openingBrackets.indexOf(stack.pop()!)]; // Use ! to assert non-null
      correctedFormula += missingClosingBracket;
    }
    if (correctedFormula) {
      setFormulaTextArea(correctedFormula);
      setColor("red");
      setActiveId(expandInput?.id);
      setFormulaCheckedStatus(false);
      inputs.updateOne({
        id: expandInput?.id,
        changes: { formula: correctedFormula },
      });
    }
  };
  const createInput = async (inputId: ID, input: CalculatorInput) => {
    setValidationIds(inputId)
    setloader(true);
    try {
      const validatedInput = await toApi(input, setColor, setValidationMsg);

      var datavalue = validatedInput

      const { data } = await calculatorInputService.createCostModal(
        datavalue
      );
      inputs.overlapOne(inputId, transformCalculatorInputFromApi(data));
      setSaveApiCallCount((prev: any) => prev + 1);
      //   setColor("");
      // ADMIN.toast.success( "Input Created" );
    } catch (e) {
      setColor("red");
      // ADMIN.toast.throwError( e, "Could not create input", true );
    }
  };

  const updateInput = async (input: Partial<CalculatorInput>) => {
    setloader(true);

    try {
      const validatedInput = await toApi(input as any, setColor, setValidationMsg);

      var datavalue = validatedInput
      await calculatorInputService.updateCostModal(input);
      setSaveApiCallCount((prev: any) => prev + 1);
      //   setColor("");
      // ADMIN.toast.success( "Input Updated" );
    } catch (e) {
      setColor("");

      ADMIN.toast.error("Server Error")

    }
  };

  const deleteInput = async (inputId: ID) => {
    // delete directly from memory if not created in backend
    // @ts-ignore
    if (inputId < 1) return inputs.removeOne(inputId);

    try {
      await calculatorInputService.deleteCostModal(inputId);
      inputs.removeOne(inputId);
      setExpand(false)

      // ADMIN.toast.success( "Input deleted" );
    } catch (e) {
      setExpand(false)
      // ADMIN.toast.throwError( e, "Could not delete input" );
    }
  };

  const handleCopyClick = async (alias: any, idd: any) => {
    setTextToCopy(`val.${alias}`);
    setActiveCopyId(idd);
    try {
      await navigator.clipboard.writeText(`val.${alias}`);

      setIsCopied(true);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };



  // const updateResult = async () => {
  //     if ( !calculator ) return;
  //     if ( !calculator.result ) return ADMIN.toast.error( '"Result Formula" is required.' );

  //     try {
  //         await calculatorService.updateTiangulation( { id: calculator.id, updates: { result: calculator.result } } );
  //         ADMIN.toast.success( "Result updated" );
  //     } catch ( e ) {
  //         ADMIN.toast.throwError( e, "Could not update result" );
  //     }
  // };

  // ----------------------------------------------------------------------------------
  // handlers
  const TestInputChange = useRef(
    debounce(async (value: any, name: any) => {
      API.put(`idea/idea_calculator_field/${value}/`, { test_input: name }, {}, 0)
        .then((res: any) => { })
        .catch((err: any) => { });
    }, 500)
  ).current;

  const getVisible = (inputt: any) => {
    if (expandInput?.id !== inputt.id) {
      return true;
    } else if (inputt.type === CALCULATOR_INPUT_TYPE.FORMULA && !expand) {
      return true;
    } else if (inputt.alias !== "" && !expand) {
      return true;
    } else {
      return false;
    }
  };
  const handleChange = (inputId: ID) => (e: any) => {


    const { name, value } = e.target;

    if (name === "formula") {
      setFormulaTextArea(value);
    }

    if (name === "test_input") {
      inputs.updateOne({ id: inputId, changes: { [name]: value } });
      TestInputChange(inputId, value);
    }

    else if (name === "alias") {
      inputs.updateOne({ id: inputId, changes: { [name]: value } });
      setActiveId(inputId);
      // aliasChange(inputId, value);
    }
    else if (name === "formula") {
      setColor("red");
      setActiveId(inputId);
      inputs.updateOne({ id: inputId, changes: { [name]: value } });
    }
    else if (name === "database") {
      setColor("");
      setActiveId(inputId);
      inputs.updateOne({ id: inputId, changes: { [name]: value } });
      inputs.updateOne({ id: inputId, changes: { ["attribute"]: null } });
    }
    else if (name === "type" && value == 11) {
      setExpand(false);
      setActiveId(inputId);
      getVisible(5) && setColor('red');
      inputs.updateOne({ id: inputId, changes: { [name]: value } });
      inputs.updateOne({ id: inputId, changes: { ["parameter"]: null } });
      inputs.updateOne({ id: inputId, changes: { ["database"]: null } });
      inputs.updateOne({ id: inputId, changes: { ["attribute"]: null } });
      inputs.updateOne({ id: inputId, changes: { ["constant"]: null } });
      inputs.updateOne({ id: inputId, changes: { ["user_input"]: "Qty" } });
      inputs.updateOne({ id: inputId, changes: { ["volume"]: 1 } });
      inputs.updateOne({ id: inputId, changes: { ["formula"]: null } });
      inputs.updateOne({ id: inputId, changes: { ["calculator_category"]: null } });
    }
    else if (name === "type" && value == 4) {
      setExpand(false);
      setActiveId(inputId);
      setColor('');
      inputs.updateOne({ id: inputId, changes: { [name]: value } });
      inputs.updateOne({ id: inputId, changes: { ["parameter"]: null } });
      inputs.updateOne({ id: inputId, changes: { ["database"]: null } });
      inputs.updateOne({ id: inputId, changes: { ["attribute"]: null } });
      inputs.updateOne({ id: inputId, changes: { ["constant"]: 0 } });
      inputs.updateOne({ id: inputId, changes: { ["user_input"]: "" } });
      inputs.updateOne({ id: inputId, changes: { ["volume"]: null } });
      inputs.updateOne({ id: inputId, changes: { ["formula"]: null } });
      inputs.updateOne({ id: inputId, changes: { ["calculator_category"]: null } });
    }
    else if (name === "type" && value != 5) {
      setExpand(false);
      setActiveId(inputId);
      setColor('');
      inputs.updateOne({ id: inputId, changes: { [name]: value } });
      inputs.updateOne({ id: inputId, changes: { ["parameter"]: null } });
      inputs.updateOne({ id: inputId, changes: { ["database"]: null } });
      inputs.updateOne({ id: inputId, changes: { ["attribute"]: null } });
      inputs.updateOne({ id: inputId, changes: { ["constant"]: null } });
      inputs.updateOne({ id: inputId, changes: { ["user_input"]: "" } });
      inputs.updateOne({ id: inputId, changes: { ["volume"]: null } });
      inputs.updateOne({ id: inputId, changes: { ["formula"]: null } });
      inputs.updateOne({ id: inputId, changes: { ["calculator_category"]: null } });
    }
    else if (name === "type" && value == 5) {
      setActiveId(inputId);
      inputs.updateOne({ id: inputId, changes: { [name]: value } });
      inputs.updateOne({ id: inputId, changes: { [name]: value } });
      inputs.updateOne({ id: inputId, changes: { ["parameter"]: null } });
      inputs.updateOne({ id: inputId, changes: { ["database"]: null } });
      inputs.updateOne({ id: inputId, changes: { ["attribute"]: null } });
      inputs.updateOne({ id: inputId, changes: { ["constant"]: null } });
      inputs.updateOne({ id: inputId, changes: { ["user_input"]: "" } });
      inputs.updateOne({ id: inputId, changes: { ["volume"]: null } });
      inputs.updateOne({ id: inputId, changes: { ["formula"]: null } });
      inputs.updateOne({ id: inputId, changes: { ["calculator_category"]: null } });
    }


    else {

      setActiveId(inputId);
      inputs.updateOne({ id: inputId, changes: { [name]: value } });
    }


  };




  // const handleResultChange = ( e: React.SyntheticEvent, ed: InputOnChangeData ) => {
  //     setCalculator( ( prev: any ) => Object.assign( {}, prev, { result: ed.value } ) );
  // };

  const getData = (data: any) => {

    if (type !== CALCULATOR_TYPE.OVERHEAD) {
      return data.filter(
        (item: any) => item.value !== CALCULATOR_INPUT_TYPE.COST_INPUT
      );
    } else {
      return data;
    }
  };
  const handleAdd = () => {
    const newInput: CalculatorInput = {
      id: Math.random(),
      name: "",
      alias: "",
      calculator: calculator?.calculator,
      type: CALCULATOR_INPUT_TYPE.BOM_PARAMETER,
    };
    setExpand(false)
    inputs.addOne(newInput);
  };

  const handleDelete = (id: any) => async () => {
    const confirm = await deleteSwal("Delete Input");
    if (confirm) deleteInput(id);
  };





  const handleSave = (inputId: ID) => {

    setExpand(false);
    // create
    const input = inputs.getById(inputId);


    if (!input) return;
    // @ts-ignore
    if (inputId < 1) {
      createInput(inputId, input);
      return;
    }

    updateInput(input);
  };


  const handleChangeResult = (e: any) => {
    setSaveStatus("red")
    setResultData(e.target?.value)

  }



  const HandleSavehandler = () => {
    setSaveLoader(true)
    API.put(`/idea/idea_cost_calculation/${calculator?.calculator}/`, { calculator_result: ResultData }).then((res: any) => {
      ADMIN.toast.success("Result Updated Successfully")
      setExpand(false)
      setSaveStatus("#007fff")
      setSaveLoader(false)
    }).catch((err: any) => {
      setExpand(false)
      setSaveLoader(false)
      ADMIN.toast.error("Server Error")
      console.log("Server Error")
    })
  }
  // ----------------------------------------------------------------------------------
  // side effects

  // fetch input for the caluclator on mount
  useEffect(() => {
    fetchInputs();
  }, [calculator, saveApiCallCount, Item]);
  // const getOptionData = (type: any) => {
  //   if (type === CALCULATOR_INPUT_TYPE.BOM_PARAMETER) {
  //     return bomConfigsOptions;
  //   } else {
  //     return null;
  //   }
  // };
  // ----------------------------------------------------------------------------------
  // jsx

  // TODO: substitute 1,2,3 w/ CALC_INPUT_MAP values
  const renderValueColumn = (input: CalculatorInput) => {
    switch (input.type) {
      case CALCULATOR_INPUT_TYPE.BOM_PARAMETER:

      case CALCULATOR_INPUT_TYPE.DATABASE:
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem', }}>
            {/* <Dropdown
                            name="database"
                            options={ calculatorDatabaseOptions }
                            placeholder="Database"
                            search
                            selection
                            value={ input.database || "" }
                            onChange={ handleChange( input.id ) }
                        /> */}
            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                variant="standard"
                label="Type"
                // disabled={!sessionStorage.getItem("ConfigPermission")?.includes("U")}
                value={input.database || ""}
                // @ts-ignore
                onChange={handleChange(input.id)}
                name="database"
                sx={{
                  width: "50%",
                  fontSize: "1rem",
                  "&:before": {
                    borderBottomColor: "primary.light",
                  },
                  ".MuiSvgIcon-root.MuiSelect-icon": {
                    color: "primary.main",
                  },
                }}>
                {calculatorDatabaseOptions?.map((item: any) => {
                  return (
                    <MenuItem sx={{ fontSize: "1rem" }} value={item.value}>
                      {item.text}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
            {/* <Dropdown
                            name="attribute"
                            options={
                                calculatorDatabaseColumnsOptions[
                                input.database || 0
                                ] || []
                            }
                            value={ input.attribute || "" }
                            placeholder="Column"
                            search
                            selection
                            onChange={ handleChange( input.id ) }
                        /> */}

            <FormControl fullWidth>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                variant="standard"
                label="Type"
                // disabled={!sessionStorage.getItem("ConfigPermission")?.includes("U")}
                value={input.attribute || ""}
                // @ts-ignore
                onChange={handleChange(input.id)}
                name="attribute"
                sx={{
                  width: "50%",
                  fontSize: "1rem",
                  "&:before": {
                    borderBottomColor: "primary.light",
                  },
                  ".MuiSvgIcon-root.MuiSelect-icon": {
                    color: "primary.main",
                  },
                }}>
                {calculatorDatabaseColumnsOptions[input.database || 0]?.map(
                  (item: any) => {
                    return (
                      <MenuItem sx={{ fontSize: "1rem" }} value={item.value}>
                        {item.text}
                      </MenuItem>
                    );
                  }
                )}
              </Select>
            </FormControl>
          </Box>
        );
      case CALCULATOR_INPUT_TYPE.CONSTANT:
        return (
          <Box
            sx={{ display: "flex", alignItems: "center", columnGap: "0.5rem" }}
          >
            <TextField
              name="constant"
              type="number"
              variant="standard"
              placeholder="Constant Value"
              disabled={(useraction && useraction?.action?.includes("U") || useraction && useraction?.action?.includes("C")) ? false : true}
              required
              // sx={{ width: "19rem !important" }}
              sx={{
                width: "100%",
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "white",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
              }}
              value={input.constant || 0}
              onFocus={() => {
                setExpandInput(input);
                setExpand(false);
              }}

              onBlur={() => {

                Number(input.id) > 1 && input.user_input != null && input.constant != null && input.user_input != "" && handleSave(input.id)
              }}
              onChange={handleChange(input.id)}
            />
            <FormControl fullWidth>
              <TextField
                variant="standard"
                name="user_input"
                disabled={(useraction && useraction?.action?.includes("U") || useraction && useraction?.action?.includes("C")) ? false : true}
                placeholder="User Input Unit"
                value={input.user_input || ""}
                onChange={handleChange(input.id)}
                onBlur={() => input.user_input != null && input.constant != null && input.user_input != "" && handleSave(input.id)}
                onFocus={() => {
                  setExpandInput(input);
                  setExpand(false);
                }}
                InputProps={{
                  style: { fontSize: "1rem" },
                }}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "primary.light",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "primary.main",
                  },
                }}
              />
            </FormControl>

          </Box>
        );
      case CALCULATOR_INPUT_TYPE.VOLUME:
        return (
          <>
            <TextField
              variant="standard"
              name="volume"
              type="number"
              // disabled={!sessionStorage.getItem("ConfigPermission")?.includes("U")}
              placeholder="volume"
              value={input.volume || ""}
              onChange={handleChange(input.id)}
              InputProps={{
                style: { fontSize: "1rem" },
              }}
              sx={{
                "& .MuiInput-underline:before": {
                  borderBottomColor: "white",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
              }}
            />
            {/* <TextField
                                name="volume"
                                type="number"
                                variant="standard"
                                placeholder="volume"
                                sx={{ width: "19rem !important" }}
                                required
                                value={input.volume || 0}
                                onChange={handleChange(input.id)} /> */}
          </>
        );
      case CALCULATOR_INPUT_TYPE.FORMULA:
        return (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "0.5rem",
              width: "100%",
            }}>
            <TextField
              name="formula"
              placeholder="Formula"
              variant="standard"
              disabled={(useraction && useraction?.action?.includes("U") || useraction && useraction?.action?.includes("C")) ? false : true}
              required
              sx={{
                width: "100%",
                minWidth: '15rem',
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "white",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
              }}
              value={input.formula || ""}

              onFocus={() => {
                setExpandInput(input);
                setFormulaTextArea(input.formula);
                setExpand(true);
              }}
              onChange={handleChange(input.id)}
            />


            <TextField
              name="user_input"
              variant="standard"
              placeholder="Unit"
              required
              sx={{
                width: "100%",
                minWidth: '8rem',
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: "1rem !important",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "white",
                },
                "& .MuiInput-underline:after": {
                  borderBottomColor: "primary.main",
                },
              }}
              value={input.user_input || ""}
              disabled={(useraction && useraction?.action?.includes("U") || useraction && useraction?.action?.includes("C")) ? false : true}
              onChange={handleChange(input.id)}
              onBlur={() => {

                Number(input.id) > 1 && input.user_input != null && input.user_input != "" && input.formula != null && input.formula != "" && handleSave(input.id)
              }}
              onFocus={() => {
                setExpandInput(input);
                // setExpand(true);
              }}

            />
          </Box>
        );
      case CALCULATOR_INPUT_TYPE.USER_INPUT:
        return (
          <TextField
            name="user_input"
            variant="standard"
            placeholder="User Input Unit (optional)"
            required
            sx={{
              width: "100%",
              ".MuiInputBase-root.MuiInput-root": {
                fontSize: "1rem !important",
              },
              "& .MuiInput-underline:before": {
                borderBottomColor: "white",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "primary.main",
              },
            }}
            disabled={(useraction && useraction?.action?.includes("U") || useraction && useraction?.action?.includes("C")) ? false : true}
            onFocus={() => {
              setExpandInput(input);
              setExpand(false);
            }}
            onBlur={() => {


              input.user_input != null && input.user_input != "" && handleSave(input.id)
            }
            }

            value={input.user_input || ""}
            onChange={handleChange(input.id)}
          />
        );
      case CALCULATOR_INPUT_TYPE.COST_INPUT:
        return (
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              variant="standard"
              disabled={(useraction && useraction?.action?.includes("U") || useraction && useraction?.action?.includes("C")) ? false : true}
              size="small"
              label="Cost Type"
              value={input.attribute || ""}
              onBlur={() => input.attribute != null && input.attribute != "" && handleSave(input.id)}
              // @ts-ignore
              onChange={handleChange(input.id)}
              onFocus={() => {
                setExpandInput(input);
                setExpand(false);
              }}
              name="attribute"
              sx={{
                width: "100%",
                fontSize: "1rem",
                "&:before": {
                  borderBottomColor: "primary.light",
                },
                ".MuiSvgIcon-root.MuiSelect-icon": {
                  color: "primary.main",
                },
              }}>
              {calculatorCostInputOptions?.map((item: any) => {
                return (
                  <MenuItem sx={{ fontSize: "1rem" }} value={item.value}>
                    {item.text}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        );
        //   case CALCULATOR_INPUT_TYPE.CALCULATOR_RESULT:
        //     return (


        //       <Select
        //         labelId="demo-simple-select-label"
        //         id="demo-simple-select"
        //         variant="standard"
        //         size="small"
        //         // disabled={!sessionStorage.getItem("ConfigPermission")?.includes("U")}
        //         label="calculator_category"
        //         value={input.calculator_category ?? ""}
        //         // @ts-ignore
        //         onChange={handleChange(input.id)}
        //         name="calculator_category"
        //         sx={{
        //           width: "100%",
        //           fontSize: "1rem",
        //           "&:before": {
        //             borderBottomColor: "primary.light",
        //           },
        //           ".MuiSvgIcon-root.MuiSelect-icon": {
        //             color: "primary.main",
        //           },
        //         }}>
        //         {calculatorCategoryOptions[type]?.map((item: any) => {
        //           return (
        //             <MenuItem sx={{ fontSize: "1rem" }} value={item.value}>
        //               {item.text}
        //             </MenuItem>
        //           );
        //         })}
        //       </Select>
        //     );
        //   default:
        return null;
    }
  };
  //this code is for multiple image upload

  // const handleFileUpload = ( e: React.ChangeEvent<HTMLInputElement>, id: any ) => {
  //     setIsUploading( true );
  //     const data = new FormData();
  //     if ( e.target.files?.length ) {
  //         //@ts-ignore
  //         data.append( `image0`, e.currentTarget.files[0] );

  //         API.put( `cost/calculator_field_image_upload/${inputs.getCollection()[currentSelected].id}/`, data, {}, 0 )
  //             .then( ( res: any ) => {
  //                 setIsUploading( false );
  //                 ADMIN.toast.success( "Data uploaded successfully" );
  //                 fetchInputs();
  //             } ).catch( ( err: any ) => {
  //                 ADMIN.toast.error( "Could not upload file" );
  //                 setIsUploading( false );
  //             } );
  //     }

  // };

  const handleFileInputClick = (e: React.MouseEvent) => {
    // clear the prev value on click so that same image can be uploaded again

    (e.target as HTMLInputElement).value = "";
  };

  // ----------------------------------------------------------------------------------
  // jsx


  if (["FALSE", "FETCHING"].includes(inputsFetched))
    return (
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          marginTop: "4rem",
          justifyContent: "center",
        }}>
        {" "}
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
        <Skeleton />
      </Box>
    );
  return (
    <div data-testid="input-configuration" className={styles.container}>
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: '100%', textAlign: 'center' }}>

        {/* <div className={styles.options}> */}
        {(useraction && useraction?.action?.includes("U") || useraction && useraction?.action?.includes("C")) && <>
          {inputs.length === 0 && (<div>
            <AddIcon
              titleAccess="Add"
              sx={{ cursor: "pointer", marginTop: '50%', }}
              onClick={handleAdd}
            />
            {inputs.length === 0 && (
              <Typography className={styles.noInputCreated} onClick={handleAdd}>No inputs created yet</Typography>
            )}
          </div>)}
        </>}
        {/* </div> */}
      </div>
      <Box className={styles.tableWrapper} sx={{ height: expand == true ? { lg: '60vh', xl: '57vh' } : { lg: '79vh', xl: '71vh' } }}>


        {inputs.length > 0 && (<Box sx={{ maxHeight: expand == true ? { lg: '54vh', xl: '53vh' } : { lg: '73vh', xl: '67vh' } }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3">
          <Table stickyHeader={true}>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    backgroundColor: "primary.light",
                    fontSize: "1rem",
                    padding: "0.2rem 0.5rem",
                    whiteSpace: "nowrap",
                    borderBlockColor: 'primary.light',
                    minWidth: '6rem',
                  }}>
                  Sl No.
                </TableCell>
                <TableCell
                  sx={{
                    backgroundColor: "primary.light",
                    fontSize: "1rem",
                    padding: "0.2rem 0.5rem",
                    borderBlockColor: 'primary.light',
                    width: '100%',
                  }}>
                  Input Name
                </TableCell>
                <TableCell
                  sx={{
                    backgroundColor: "primary.light",
                    fontSize: "1rem",
                    padding: "0.2rem 0.5rem",
                    borderBlockColor: 'primary.light',
                    minWidth: '7rem',
                  }}>
                  As
                </TableCell>
                <TableCell
                  sx={{
                    backgroundColor: "primary.light",
                    fontSize: "1rem",
                    padding: "0.2rem 0.5rem",
                    borderBlockColor: 'primary.light',
                    minWidth: '12rem',
                  }}>
                  Type
                </TableCell>
                <TableCell
                  sx={{
                    backgroundColor: "primary.light",
                    fontSize: "1rem",
                    padding: "0.2rem 0.5rem",
                    borderBlockColor: 'primary.light',
                    minWidth: '20rem'
                  }}
                >
                  Value
                </TableCell>
                <TableCell
                  sx={{
                    backgroundColor: "primary.light",
                    fontSize: "1rem",
                    padding: "0.2rem 0.5rem",
                    borderBlockColor: 'primary.light',
                    minWidth: '8rem'
                  }}
                >
                  {useraction && useraction?.action?.includes("D") &&
                    "Actions"}
                </TableCell>
                <TableCell
                  sx={{
                    backgroundColor: "primary.light",
                    fontSize: "1rem",
                    padding: "0.2rem 0.5rem",
                    borderBlockColor: 'primary.light',
                    minWidth: '12rem'
                  }}>
                  Test Input
                  {(useraction && useraction?.action?.includes("U") || useraction && useraction?.action?.includes("C")) &&
                    <span
                      style={{
                        cursor: "pointer",
                        fontSize: "1rem",
                        textDecoration: "underline",
                        marginLeft: '0.5rem',
                      }}
                      onClick={fetchInputs}
                    >
                      Click for results
                    </span>
                  }
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>

              {inputs.getCollection().map((input: any, index: any) => (


                <TableRow key={input.id}>

                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    <TextField
                      variant="standard"
                      name="sequence"
                      type="number"
                      // disabled={ (Lockstatus?.lock_status==false && useraction?.includes("U"))?false:true
                      // }
                      placeholder="Seq"
                      value={input.sequence}
                      disabled={(useraction && useraction?.action?.includes("U") || useraction && useraction?.action?.includes("C")) ? false : true}
                      onChange={handleChange(input.id)}
                      onBlur={() => input?.id > 0 && handleSave(input.id)}
                      inputProps={{
                        step: 'any',
                        min: 1,
                        style: { fontSize: '1rem', }
                      }}
                      sx={{
                        "& .MuiInputBase-input.MuiInput-input": {
                          fontSize: "1rem",
                          paddingLeft: "0.5rem",
                          backgroundColor: "white",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "primary.light",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "primary.main",
                        },
                      }}
                    />
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    <TextField
                      name="name"
                      placeholder="Name"
                      variant="standard"
                      value={input.name}
                      disabled={(useraction && useraction?.action?.includes("U") || useraction && useraction?.action?.includes("C")) ? false : true}
                      sx={{
                        width: "100%",
                        ".MuiInputBase-root.MuiInput-root": {
                          fontSize: "1rem !important",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "white",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "primary.main",
                        },
                      }}
                      onBlur={() => input?.id > 1 && handleSave(input.id)}
                      onChange={handleChange(input.id)}
                    />

                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    <Box sx={{ display: "flex", columnGap: "0.5rem" }}>
                      <TextField
                        name="alias"
                        placeholder="As"
                        disabled
                        variant="standard"
                        value={input.alias}
                        sx={{
                          width: "100%",
                          ".MuiInputBase-root.MuiInput-root": {
                            fontSize: "1rem !important",
                          },
                          "& .MuiInput-underline:before": {
                            borderBottomColor: "white",
                          },
                          "& .MuiInput-underline:after": {
                            borderBottomColor: "primary.main",
                          },
                        }}
                      // onChange={handleChange(input.id)}
                      />
                      {getVisible(input) && (
                        <FileCopyRoundedIcon

                          sx={{
                            cursor: "pointer",

                            color:
                              isCopied && activeCopyId === input.id
                                ? "green"
                                : "",
                          }}
                          onClick={() =>
                            handleCopyClick(input.alias, input.id)
                          }
                        />
                      )}
                    </Box>
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    {input?.type &&

                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        variant="standard"
                        // disabled={!sessionStorage.getItem("ConfigPermission")?.includes("U")}
                        size="small"
                        value={input.type}
                        disabled={(useraction && useraction?.action?.includes("U") || useraction && useraction?.action?.includes("C")) ? false : true}
                        // @ts-ignore
                        onChange={handleChange(input.id)}
                        name="type"
                        sx={{
                          width: "100%",
                          fontSize: "1rem",
                          "&:before": {
                            borderBottomColor: "primary.light",
                          },
                          ".MuiSvgIcon-root.MuiSelect-icon": {
                            color: "primary.main",
                          },
                        }}>
                        {
                          // calculatorInputOptions.filter(input => {
                          //                         /** filter cost_input type, when calculator type is not overhead */
                          //         return selectedTreeData.calculator_type !== CALCULATOR_TYPE.OVERHEAD ? input.value !== CALCULATOR_INPUT_TYPE.COST_INPUT : input;
                          // })
                          //   @ts-ignore
                          getData(TriangulationInputOptions)?.map((item: any) => {
                            return (
                              <MenuItem
                                sx={{ fontSize: "1rem" }}
                                value={item.value}>
                                {item.text !== "Volume" && item.text}
                              </MenuItem>
                            );
                          })
                        }
                      </Select>
                    }
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                      width: "30vw",
                    }}>
                    {renderValueColumn(input)}

                  </TableCell>

                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
                      {
                        useraction && useraction?.action?.includes("D") &&

                        <DeleteIcon
                          sx={{ cursor: "pointer" }}
                          titleAccess="Delete"
                          onClick={handleDelete(input.id)}
                        />
                      }
                    </Box>
                    {/* <Icon title="delete" name="trash" link inputid={ input.id } onClick={ handleDelete } /> */}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                      backgroundColor:
                        calculatorInputBackgroundColorMap[input.type],
                    }}>
                    <Box style={{ color: "primary.main", }}>
                      <TextField
                        variant="standard"
                        name={input?.formula ? "result" : "test_input"}
                        placeholder="Enter Value"
                        type="number"
                        inputProps={{
                          step: 'any',
                          min: 0,
                          style: { fontSize: '1rem', }
                        }}
                        value={
                          input?.formula ? input.result : input.test_input
                        }
                        onChange={handleChange(input.id)}
                        disabled={(input.formula == null) && (useraction && useraction?.action?.includes("U") || useraction && useraction?.action?.includes("C")) ? false : true}
                        sx={{
                          width: "100%",
                          "& .MuiInputBase-input.MuiInput-input": {
                            fontSize: "1rem",
                            paddingLeft: "0.5rem",
                            backgroundColor: "white",
                          },
                          "& .MuiInput-underline:before": {
                            borderBottomColor: "primary.light",
                          },
                          "& .MuiInput-underline:after": {
                            borderBottomColor: "primary.main",
                          },

                          backgroundColor: calculatorInputBackgroundColorMap[input.type],
                        }}
                      />
                    </Box>
                  </TableCell>
                </TableRow>
              ))}

            </TableBody>
          </Table>
        </Box>)}
        {(useraction && useraction?.action?.includes("U") || useraction && useraction?.action?.includes("C")) && <>
          {inputs.length > 0 && (<>  <Box sx={{ position: 'sticky', bottom: '0', width: '100%', backgroundColor: 'white' }}>
            <Button
              title='Add Field'
              onClick={handleAdd}
              size='small'
              sx={{ width: '100%' }}>
              <Box sx={styleDivider} />
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  columnGap: '0.4rem',
                  whiteSpace: 'nowrap',
                  margin: '0 1rem',
                  backgroundColor: 'primary.light',
                  padding: '0.2rem 1rem 0.2rem 0.5rem',
                  borderRadius: '3px',
                }}>
                <AddIcon />
                Add Field
              </Box>
              <Box sx={styleDivider} />
            </Button>

          </Box> </>)}
        </>}
      </Box>
      {
        <Box>
          {/* formula */}
          {expand && (
            <>
              <Box
                sx={{
                  // border: "1px solid #007fff",
                  borderRadius: "5px",
                  padding: "0.5rem 1rem 0",
                  backgroundColor: "primary.light",
                  marginBottom: '0.1rem',
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    width: "100%",
                    marginBottom: "0.5rem",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      whiteSpace: "nowrap",
                      fontWeight: "500",
                    }}
                  >
                    {expandInput?.name} Formula :{" "}
                  </Typography>
                  <Box>
                    {expand && (
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          columnGap: "1rem",
                          width: "100%",
                        }}
                      >
                        <IconButton
                          title="Formula Fusion: Multi-Operation Showcase"
                          sx={{ padding: '0' }}
                          aria-describedby={poid}
                          onClick={handleClick}>
                          <InfoIcon sx={{ color: 'primary.main' }} />
                        </IconButton>
                        <Popover
                          id={poid}
                          open={open}
                          anchorEl={anchorEl}
                          onClose={handleClose}
                          anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                          }}
                          transformOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right',
                          }}
                          disableRestoreFocus
                          sx={{
                            '.MuiPaper-root.MuiPopover-paper': {
                              borderRadius: '1rem',
                              //   backgroundColor:'primary.light'
                            },
                          }}
                        >
                          <Typography sx={{ width: '100%', textAlign: 'center', fontSize: '1rem', padding: '0.5rem', fontWeight: '600', backgroundColor: '#ddefff' }}>
                            Formula Fusion: Multi-Operation Showcase
                          </Typography>
                          <Divider sx={{ borderColor: "primary.light" }} />
                          <Box sx={{ display: 'flex', alignItems: 'flex-start', columnGap: '0.5rem', padding: '0.5rem' }}>
                            <Table>
                              <TableBody>
                                <TableRow>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>1</TableCell>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#fffaea' }}>val.a + val.b</TableCell>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>Addition</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>2</TableCell>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#fffaea' }}>val.a - val.b</TableCell>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>Subtraction</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>3</TableCell>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#fffaea' }}>val.a * val.b</TableCell>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>Multiplication</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>4</TableCell>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#fffaea' }}>val.a / val.b</TableCell>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>Division</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>5</TableCell>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#fffaea' }}>val.a ** val.b</TableCell>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>Exponentiation</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>6</TableCell>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#fffaea' }}>val.a % val.b</TableCell>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>Modulo</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>7</TableCell>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#fffaea' }}>val.a and val.b</TableCell>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>AND</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>8</TableCell>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#fffaea' }}>val.a or val.b</TableCell>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>OR</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>9</TableCell>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#fffaea' }}>not val.a</TableCell>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>NOT</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>10</TableCell>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#fffaea' }}>val.a == val.b</TableCell>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>Equal</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>11</TableCell>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#fffaea' }}>val.a != val.b</TableCell>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>Not equal</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>12</TableCell>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#fffaea' }}>val.a {'>'} val.b</TableCell>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>Greater than</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>13</TableCell>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#fffaea' }}>val.a {'<'} val.b</TableCell>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>Less than</TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                            <Divider sx={{ borderColor: "primary.light" }} orientation="vertical" variant="middle" flexItem />
                            <Table>
                              <TableBody>
                                <TableRow>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>14</TableCell>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#fffaea' }}>val.a {'>'}= val.b</TableCell>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>Greater than or equal to</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>15</TableCell>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#fffaea' }}>val.a {'<'}= val.b</TableCell>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>Less than or equal to</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>16</TableCell>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#fffaea' }}>val.a + val.b if val.a {'>'} val.b else val.a - val.b</TableCell>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>If and else condition</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>17</TableCell>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#fffaea' }}>min(val.a, val.b, val.c, val.d)</TableCell>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>min</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>18</TableCell>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#fffaea' }}>max(val.a, val.b, val.c, val.d)</TableCell>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>max</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>19</TableCell>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#fffaea' }}>round(val.a, 2)</TableCell>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>round</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>20</TableCell>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#fffaea' }}>math.floor(val.a)</TableCell>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>rounded down</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>21</TableCell>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#fffaea' }}>math.ceil(val.a)</TableCell>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>rounded up</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>22</TableCell>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#fffaea' }}>math.sqrt(val.a)</TableCell>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>Square root</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>23</TableCell>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#fffaea' }}>math.sin(val.a)<sup style={{ color: 'red' }}>**</sup></TableCell>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>Sine function</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>24</TableCell>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#fffaea' }}>math.cos(val.a)<sup style={{ color: 'red' }}>**</sup></TableCell>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>Cosine function</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>25</TableCell>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#fffaea' }}>math.log(val.a)</TableCell>
                                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', whiteSpace: 'nowrap', backgroundColor: '#edfbee' }}>Natural logarithm</TableCell>
                                </TableRow>
                                <TableRow>
                                  <TableCell colSpan={3} sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light", whiteSpace: "nowrap", backgroundColor: "#edfbee", }}>
                                    <span style={{ color: 'red' }}>Note: </span> <sup style={{ color: 'red' }}>**</sup> The values entered should be in radians.
                                  </TableCell>
                                </TableRow>
                              </TableBody>
                            </Table>
                          </Box>
                        </Popover>
                        {formulaCheckedStatus ? (
                          <FactCheckIcon
                            sx={{
                              cursor: "pointer",
                              color: "green",
                            }}
                            titleAccess="Check formula"
                            onClick={() => {
                              correctFormula(formulaTextArea);
                              setFormulaCheckedStatus(true);
                            }}
                          />
                        ) : (
                          <FactCheckOutlinedIcon
                            sx={{
                              cursor: "pointer",
                              // color:  "green" : "",
                            }}
                            titleAccess="Check formula"
                            onClick={() => {
                              correctFormula(formulaTextArea);
                              setFormulaCheckedStatus(true);
                            }}
                          />
                        )}
                        {(useraction && useraction?.action?.includes("U") || useraction && useraction?.action?.includes("C")) &&

                          <SaveIcon

                            titleAccess="Save Formula"
                            sx={{ color: color, cursor: expandInput.formula != null && expandInput.formula != "" ? "pointer" : "not-allowed" }}
                            onClick={() => expandInput.formula != null && expandInput.formula != "" && handleSave(expandInput.id)}
                          // onClick={handleSave(expandInput.id)}
                          />}

                      </Box>
                    )}
                  </Box>
                </Box>
                <TextareaAutosize
                  aria-label="minimum height"
                  name="formula"
                  // value={input.formula || ""}
                  disabled={(useraction && useraction?.action?.includes("U") || useraction && useraction?.action?.includes("C")) ? false : true}

                  onChange={handleChange(expandInput?.id)} // Set the minimum number of rows
                  placeholder="Enter your formula"
                  value={formulaTextArea || ""}
                  minRows={5}
                  style={{
                    color: "#007fff",
                    width: "100%",
                    border: "1px solid #ddefff",
                    borderRadius: "5px",
                    padding: "0.5rem 1rem 0",
                  }} // Set the width
                />
              </Box>
            </>
          )}
        </Box>
      }
      {DataList && DataList.length > 0 &&
        <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', columnGap: '1rem', backgroundColor: 'primary.light', padding: '0 0.5rem' }}>
          <Typography sx={{ fontSize: '1rem' }}>Result</Typography>
          <TextField
            //   name="result"
            variant="standard"
            placeholder="result"
            required
            sx={{
              width: "100%",
              ".MuiInputBase-root.MuiInput-root": {
                fontSize: "1rem !important",
              },
              "& .MuiInput-underline:before": {
                borderBottomColor: "white",
              },
              "& .MuiInput-underline:after": {
                borderBottomColor: "primary.main",
              },
            }}
            value={ResultData || ""}
            disabled={(useraction && useraction?.action?.includes("U") || useraction && useraction?.action?.includes("C")) ? false : true}
            onChange={(e: any) => handleChangeResult(e)}
          />
          <div>
            {(useraction && useraction?.action?.includes("U") || useraction && useraction?.action?.includes("C")) &&
              <LoadingButton loading={SaveLoader} onClick={() => HandleSavehandler()}>
                <SaveIcon
                  titleAccess="Save"
                  sx={{ cursor: "pointer", color: `${SaveStatus}` }}
                />
              </LoadingButton>
            }
          </div>
        </Box>
      }
    </div>
  );
};

// ----------------------------------------------------------------------------------

export default IdeaCostCalculator;
