// react
import React, { useState } from "react";
import { QrReader } from "react-qr-reader";
import Modal from "@mui/material/Modal";

// styles
// import styles from "./ScanQRModal.module.scss";
import styles from "./ScanQRModal.module.scss";
import { Box, Divider, Typography } from "@mui/material";
import { useHistory, useRouteMatch } from "react-router-dom";
import { API } from "../../api-services";
import { Cancel, Countertops } from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import { useScroll } from "@react-spring/web";
import ClearIcon from '@mui/icons-material/Clear';
// interfaces and types

// lazy

// ----------------------------------------------------------------------------------

interface ScanQRModalProps {
  isOpen: boolean;
  onClose: () => void;

  changeRootId?: any;
}

const ScanQRModal: React.FC<ScanQRModalProps> = (props) => {
  const { isOpen, onClose, changeRootId } = props;
  const { url } = useRouteMatch<any>();
  const [CounterData, setCounterData] = useState<any>(0);
  var Module = localStorage.getItem("ModuleName");
  const history = useHistory();

  // console.log(Module, "serachUrl");
  // ----------------------------------------------------------------------------------
  // handlers

  const handleScan = async (vaultId: any) => {
    setCounterData((prev: any) => prev + 1);
    if (!vaultId) return;

    sessionStorage.removeItem("SearchKey");
    sessionStorage.removeItem("recentKey");
    sessionStorage.removeItem("projectKey");
    sessionStorage.removeItem("ProjectIds");
    await API.get("customer/qr_search/", {
      vault_id: vaultId?.text,
      mod: "GEN",
    })
      .then((res: any) => {


        if (res?.data && Object.keys(res?.data)?.length == 0) {
          sessionStorage.removeItem("SearchKey");
          sessionStorage.removeItem("recentKey");
          sessionStorage.removeItem("projectKey");
          sessionStorage.removeItem("ProjectIds");

          sessionStorage.removeItem("ExactSearchKey");
          sessionStorage.setItem("Counter", CounterData);
          sessionStorage.removeItem("VoultId");
          sessionStorage.removeItem("moduletype");

          history.push("/search");


          // @ts-ignore

          onClose();
        }

        var conditionWise =
          res?.data?.data[0]?.model_type == "topvault"
            ? res?.data?.data[0]?.topvault_id
            : res?.data?.data[0]?.id;

        var WholeData =
          res?.data?.data[0]?.model_type == "topvault"
            ? res?.data?.data[0]?.top_vault_name
            : res?.data?.data[0]?.name +
            "(" +
            res?.data?.data[0]?.part_no +
            ")" +
            "|" +
            res?.data?.data[0]?.top_vault_name;
        sessionStorage.setItem("ExactSearchKey", WholeData);
        sessionStorage.setItem("Counter", CounterData);
        sessionStorage.setItem("VoultId", conditionWise);
        sessionStorage.setItem("moduletype", res?.data?.data[0]?.model_type);
        history.push("/search");


        // @ts-ignore

        onClose();



        // if(Module=="Bom"){

        //   var WholeData =
        //   res?.data?.data[0]?.model_type == "topvault"
        //     ? res?.data?.data[0]?.top_vault_name
        //     : res?.data?.data[0]?.name + "(" + res?.data?.data[0]?.part_no + ")" + "|" + res?.data?.data[0]?.top_vault_name;
        //     sessionStorage.setItem("ExactSearchKey", WholeData);
        //   history.push(`/view/${res?.data?.data[0]?.proj_id}/${res?.data?.data[0]?.topvault_id}/${res?.data?.data[0]?.id}/${res?.data?.data[0]?.abbreviation}/true`);

        //   onClose()

        // }
      })
      .catch((err: any) => {
        console.log(err);
      });

    //@ts-ignore
    // const { item } = await unwrapResult( action );
    // changeRootId( item.id );
    // if ( module === 33 ) {
    //     history.push(
    //         `/bomgen/ImageData/${projectId}/${topVaultId}/${vaultId}/0/${workstationId}`
    //     );
    //     onClose();
    // }
    // else {
    //     history.push(
    //         `/bomgen/de/${projectId}/${topVaultId}/${vaultId}/${tab}/${workstationId}`
    //     );
    //     onClose();
    // }
  };

  // ----------------------------------------------------------------------------------
  // jsx
  const videoframestyle = {
    height: {xs : "60vh", lg: "100vh"},
    width: {xs: "100vw", lg: "40vw"},
  }

  const closeModal = (e:any) => {
    e?.stopPropagation();
    onClose();
  }

  return (
    <>
      <Modal
        open={isOpen}
        onClose={onClose}
      >
        <Box className={styles.child}>
          <Box sx={{ width: '100%', height: '100%' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
              <Typography
                style={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  width: "100%",
                  textAlign: "center",
                  marginRight: '-2rem'
                }}
              >
                QR Scanner
              </Typography>
              <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={(e:any) => closeModal(e)} />
            </Box>
            <Divider
              sx={{
                borderColor: "primary.light",
                margin: "0 1rem",
                marginBottom: "1rem",
              }}
            />
            <Box sx={{ width: '100%', height: '100%',overflow:'hidden',minHeight: {lg:'34rem',xl:'43rem'},marginBottom:'1rem' }}>
              {/* @ts-ignore */}
              <QrReader
                className={styles.scannerSection}
                containerStyle={videoframestyle}
                // scanDelay={300}
                onResult={handleScan}
              />
            </Box>
          </Box>
        </Box>
      </Modal>
    </>
  );
};

export default ScanQRModal;
