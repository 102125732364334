import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";

import { Box, Button, Divider, Skeleton, TextField, Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import Table from "@mui/material/Table";
import { TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { API } from "../../api-services";
import React, { memo, useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { LoadingButton } from "@mui/lab";
import {
  CellNameOne,
  CellNameSecond,
  CellNameThird,
} from "./BomTemplatedHeader";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import {Checkbox} from "@mui/material";
import { debounce } from "lodash";
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import swal from "sweetalert";
const popstyled = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: "50%", xl: "50%" },
  height: { lg: "90vh", xl: "80vh" },
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

// @ts-ignore

const useStyles = makeStyles((theme) => ({
  /** Changed modalStyle */
  ModalRoot: {
    backgroundColor: "rgba(111, 126, 140, 0.2) !important",
    backdropFilter: "blur(1px) !important",
  },
}));







export interface AddParameterModalProps {
  isOpen: any;
  onCloseModal: any;
  setCount: any;
  setParentCount: any;
  handleChangeCheckBox: any
  InfoModalhandler:any;
}

const EditSequenceModal = (props: AddParameterModalProps) => {
  const { isOpen, onCloseModal, setCount, setParentCount, handleChangeCheckBox,InfoModalhandler } = props;
  const {
    projectId,
    projectname,
    categoryId,
    productId,
    datatype,
    validationId,
  } = useRouteParams<any>();
  const classes = useStyles();


  const [rowDatalist, setrowDatalist] = useState<any>([])

  const [IsLoading, setIsLoading] = useState<any>(false)
  const [multipleDelete, setMultipleDelete] = useState<any>([])
  const [deletedSelectedDisable, setDeletedSelectedDisable] = useState<any>(false)
  const [deletedSelectedLoader, setDeletedSelectedLoader] = useState<any>(false)

  const [Counter, setCounter] = useState<any>(0)
  useEffect(() => {

    setIsLoading(true);
    setrowDatalist([]);
    API.get("/config/parameter_mapping/", {
      top_vault: productId,
      project: projectId,
      module: 6,
      category: categoryId,
    })
      .then((res: any) => {
        setIsLoading(false);

        setrowDatalist(res?.data);
      })
      .catch((err: any) => {
        setIsLoading(false);
      });
  }, [categoryId, Counter])


  const handleAllDelete = () => {
    
    console.log(multipleDelete)
    
    swal({
      text: `Are you sure you want to delete ?`,
      icon: "error",
      buttons: ["Cancel", "Yes, Delete"],
    }).then((confirm) => {
      if (confirm) {
        setDeletedSelectedLoader(true)
        API.delete(
          `/config/parameter_mapping/${multipleDelete[0]}/`,
          {
            top_vault:
              validationId == 0
                ? productId?.toString()
                : validationId?.toString(),
                ids: multipleDelete
          },
          0
        )
          .then((res: any) => {
            setCounter((prev: any) => prev + 1);
            handleChangeCheckBox();
            setDeletedSelectedLoader(false)
          })
          .catch((err: any) => { 
            setDeletedSelectedLoader(false)
          });
      }
    });
    setMultipleDelete([]);
  }





  const DeleteHandler = (rowItem:any) => {
    swal({
      text: `Are you sure you want to delete ?`,
      icon: "error",
      buttons: ["Cancel", "Yes, Delete"],
    }).then((confirm) => {
      if (confirm) {
        API.delete(
          `/config/parameter_mapping/${rowItem?.id}/`,
          {
            top_vault:
              validationId == 0
                ? productId?.toString()
                : validationId?.toString(),
          },
          0
        )
          .then((res: any) => {
            setCounter((prev: any) => prev + 1);
            handleChangeCheckBox();
          })
          .catch((err: any) => { });
      }
    });
  };

const Infohandler=(item:any)=>{
  InfoModalhandler(item)
}

const handleMultiSelect = (e:any, item:any) => {
  if(e.target.checked){
    setMultipleDelete([...multipleDelete, item?.id])
  }
  else{
    setMultipleDelete(multipleDelete.filter((i:any)=>i!=item?.id))
  }
}

const handleSelectAll = (e:any) => {
  if(e.target.checked){
    setMultipleDelete(rowDatalist.filter((item:any) => item?.map_status !== true).map((item:any)=>item?.id))
  }
  else{
    setMultipleDelete([])
  
  }
}

  return (
    <div>
      <Modal
        open={isOpen}
        className={classes.ModalRoot}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={popstyled}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}>
              Edit Sequence
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box

            sx={{ height: { lg: "92%", xl: "92%" }, width: "100%" }}
            // sx={{ height: { lg: "85%", xl: "89%" }, width: "100%" }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3"
          >
            {multipleDelete.length > 0 && <Box><LoadingButton loading={deletedSelectedLoader} onClick={handleAllDelete}><DeleteIcon sx={{fontSize:'1.2rem', marginRight:'0.2rem'}}/><span style={{fontSize:"0.9rem"}}>Delete Selected</span></LoadingButton></Box>}

            {IsLoading ?

              <><Skeleton /><Skeleton animation="wave" /><Skeleton animation={false} /><Skeleton /><Skeleton animation="wave" /><Skeleton animation={false} /><Skeleton /><Skeleton animation="wave" /><Skeleton animation={false} /><Skeleton /><Skeleton animation="wave" /><Skeleton animation={false} /></>


              :

              <Table>
                <TableHead
                  sx={{
                    backgroundColor: "primary.light",
                    color: "white",
                    position: "sticky",
                    top: "0",
                    zIndex: "1",
                  }}>
                  <TableRow>
                  <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light',width:'5rem' }}><Checkbox disabled={rowDatalist.length == 0}  onClick={(e:any) => handleSelectAll(e)} sx={{color:"primary.main"}} /></TableCell>
                    <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light',width:'5rem' }}>Sequence</TableCell>
                    <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>Parameter Name</TableCell>
                    <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>Data Type</TableCell>
                    <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>Action</TableCell>
                    
                  </TableRow>
                </TableHead>
                <TableBody>


                  {rowDatalist.map((item: any, index: any) => {
                    return (<>

                      <TableRow>
                      <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light',width:'5rem' }}>
                          <Typography style={{ fontSize: '1rem', fontWeight: '600' }}><Checkbox disabled={item?.map_status} sx={{color:"primary.main"}} checked={multipleDelete.includes(item?.id)} onClick={(e) => handleMultiSelect(e, item)}/></Typography>
                        </TableCell>
                        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>
                          <Rowrender setCounter={setCounter} data={item} setCount={setCount}
                            handleChangeCheckBox={handleChangeCheckBox} />
                        </TableCell>
                        { item?.required==true?
    <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>{item?.parameter?.name} <sup style={{color:"red"}}>*</sup></TableCell>:

    <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>{item?.parameter?.name} </TableCell>
                        }
                    
                        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>{item?.data_type}</TableCell>
                        <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}>


                        {item?.map_status?
                          
                           
                           <InfoIcon
                           titleAccess="Info"
                           onClick={() => Infohandler(item)}
                           sx={{ cursor: "pointer" }}
                         />
                           :
                           <DeleteIcon titleAccess="Delete" sx={{cursor:"pointer",}} onClick={()=>DeleteHandler(item)}/>
                      }
                       
                     
                        
                        
                        </TableCell>


                      </TableRow>
                    </>)
                  })}
                </TableBody>

              </Table>
            }
          </Box>
        </Box>
      </Modal>
    </div>
  );
};



export default EditSequenceModal;

export interface renderRowProps {
  data: any;
  setCount: any;
  setCounter: any;
  handleChangeCheckBox: any;

}
export const Rowrender = (props: renderRowProps) => {
  const {
    projectId,
    projectname,
    categoryId,
    productId,
    datatype,
    validationId,
    categoryname,
  } = useRouteParams<any>();
  const { data, setCount,
    handleChangeCheckBox, setCounter } = props;
  const [sequence, setsequence] = useState<any>(null)


  const ExactDebounce = React.useRef(
    debounce(async (criteria: any, Id: any, topVault: any, validationsIds: any) => {
      {


        {


          await API.put(`/config/parameter_mapping/${Id}/`, { sequence: parseInt(criteria), top_vault: validationsIds == 0 ? topVault?.toString() : validationsIds?.toString() }).then(
            (res: any) => {
              //  setSequence(res.data?.sequence );
              setCount((prev: any) => prev + 1)
              setCounter((prev: any) => prev + 1)
              handleChangeCheckBox()
            }
          );
        }

      }


    }, 800)
  ).current;


  const handlechnage = (e: any) => {
    setsequence(e?.target?.value)
    if (e?.target?.value?.length !== 0 || e?.target?.value !== null) {
      ExactDebounce(e.target.value, data?.id, productId, validationId)
    }

  }

  useEffect(() => {
    setsequence(data?.sequence)
  }, [data])

  return (<>
    <TextField
      variant="standard"
      value={sequence}
      type="number"
      inputProps={{
        step: 'any',
        min: 1,
        // style: { fontSize: '1rem', marginTop: '0.4rem' }
      }}
      onChange={(e: any) => handlechnage(e)}
      sx={{
        ".MuiInputBase-root.MuiInput-root":{
					fontSize:'1rem !important'
				},
        '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
        '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
      }}
    />


  </>)
}