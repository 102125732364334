import * as React from "react";
import { Box, Checkbox, Chip, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import DownloadIcon from "@mui/icons-material/Download";
import VerifiedIcon from "@mui/icons-material/Verified";
import { Dispatchproducts } from "./Dispatchproducts";
import dispatchcode from "../Assets/images/QRCodedispatch.png";
import verifyweight from "../Assets/images/Verifyweight.svg";
import downarrowfill from "../Assets/images/downarrowfill.svg";
import downarrowoutline from "../Assets/images/downarrowoutline.svg";
import { ScanandVerifyModal } from "./ScanandVerifyModal";
import { useBaseModal } from "../ComponentUI/SearchPage/useBaseModal";
import { CheckSystemWeightandVerifyModal } from "./CheckSystemWeightandVerifyModal";
import { API } from "../api-services";
import { useRouteParams } from "../ui-reusable-component/useRouteParams";
import { MODULES } from "../Constants/modules.constants";
import LoadingButton from "@mui/lab/LoadingButton";

export interface IDispatchVerificationProps {
  counter: any
}

export function DispatchVerification(props: IDispatchVerificationProps) {
  const { counter } = props;
  const { projectId } = useRouteParams<any>();
  const ScanModal = useBaseModal();
  const CheckSystem = useBaseModal();
  const [subSystemList, setSubSystemList] = React.useState<any>([]);
  const [subSystemLoader, setSubSystemLoader] = React.useState<any>(false)
  const [productList, setProductList] = React.useState<any>([]);
  const [productListLoading, setProductLstLoading] = React.useState<any>(false)
  const [details, setDetails] = React.useState<any>([]);
  const [detailsLoading, setDetailsLoading] = React.useState<any>(false);
  const [isLoading, setIsLoading] = React.useState<any>(false);

  const scanandverifyHandler = () => {
    ScanModal.open();
  };

  const checksystemandweightHandler = () => {
    CheckSystem.open();
  };

  const getDetails = () => {
    setDetailsLoading(true)
    API.get(
      "/xcpep/dispatch/",
      {
        dispatch_project: projectId,
      },
      0
    )
      .then((res: any) => {
        setDetails(res.data);
        setDetailsLoading(false)
      })
      .catch((err: any) => { setDetailsLoading(false) });
  };
  const getSubSystemList = () => {
    setSubSystemLoader(true)
    API.get("/xcpep/dispatch/", { get_subsystem_names: projectId }, 0)
      .then((res: any) => {
        setSubSystemList(res.data);
        setSubSystemLoader(false)
      })
      .catch((err: any) => { setSubSystemLoader(false) });
  };

  const downloadListTopVault = (productId: any) => {

    // setTimeout(() => {
    setIsLoading(true);
    try {
      window.open(API.apiBase + `xcpep/dispatch/?top_vault=${productId}&module=${MODULES.BOM_CHECK}&download=${true}`, "_blank", "noopener noreferrer");
      setIsLoading(false);
    } catch (e) {
      // ADMIN.toast.throwError(e, "Could not fetch download url");
      setIsLoading(false);
    }
    // }, 100);

  };

  const getProductList = () => {
    setProductLstLoading(true)
    API.get("/xcpep/dispatch/", { dispatch_project_status: projectId }, 0)
      .then((res: any) => {
        setProductList(res.data);
        setProductLstLoading(false)
      })
      .catch((err: any) => { setProductLstLoading(false) });
  };

  React.useEffect(() => {
    getSubSystemList();
    getProductList();
    getDetails();
  }, [projectId, counter]);

  return (
    <div>
      {ScanModal.isOpen && (
        <ScanandVerifyModal
          onCloseModal={ScanModal.close}
          isOpen={ScanModal.open}
        />
      )}

      {CheckSystem.isOpen && (
        <CheckSystemWeightandVerifyModal
          onCloseModal={CheckSystem.close}
          isOpen={CheckSystem.open}
        />
      )}
      <Box
        sx={{ display: "flex", alignItems: "flex-start", height: { lg: "86vh", xl: "90vh" } }}
        adl-scrollbar="true"
        adl-scrollbar-width="0.3">
        <Box sx={{ width: { lg: "13vw", xl: "15vw" }, borderRight: "1px solid", position: 'sticky', left: '0', backgroundColor: 'white', zIndex: 1 }}>
          <Table>
            <TableHead sx={{ backgroundColor: "primary.light", position: "sticky", top: 0, display: "flex", flexDirection: "column" }}>
              <TableRow>
                <TableCell sx={{ textAlign: "center", padding: "0.2rem 0.5rem", borderBottomColor: "primary.light", width: "14vw", color: "primary.light", height: { lg: '2.4rem', xl: "2.3rem" } }}>
                  <Typography sx={{ color: "primary.light", fontSize: '1rem' }}>System</Typography>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell sx={{ textAlign: "center", padding: "0.2rem 0.5rem", borderBottomColor: "primary.light", width: "14vw" }}>System</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <Box>

                {!subSystemLoader && subSystemList ?
                  subSystemList?.map((item: any, index: number) => {
                    return (
                      <Box>
                        <Box sx={{ textAlign: "left", padding: "0.2rem 0.5rem", borderBottom: '1px solid', borderBottomColor: "primary.light", whiteSpace: "nowrap", textTransform: 'capitalize' }}>{item?.name?.toLowerCase()}</Box>
                      </Box>
                    );
                  })
                  : <Box>
                    <Skeleton sx={{ height: '2rem' }}></Skeleton>
                    <Skeleton sx={{ height: '2rem' }}></Skeleton>
                    <Skeleton sx={{ height: '2rem' }}></Skeleton>
                    <Skeleton sx={{ height: '2rem' }}></Skeleton>
                    <Skeleton sx={{ height: '2rem' }}></Skeleton>
                    <Skeleton sx={{ height: '2rem' }}></Skeleton>
                    <Skeleton sx={{ height: '2rem' }}></Skeleton>
                    <Skeleton sx={{ height: '2rem' }}></Skeleton>
                    <Skeleton sx={{ height: '2rem' }}></Skeleton>
                    <Skeleton sx={{ height: '2rem' }}></Skeleton>
                    <Skeleton sx={{ height: '2rem' }}></Skeleton>
                    <Skeleton sx={{ height: '2rem' }}></Skeleton>
                    <Skeleton sx={{ height: '2rem' }}></Skeleton>
                    <Skeleton sx={{ height: '2rem' }}></Skeleton>
                    <Skeleton sx={{ height: '2rem' }}></Skeleton>
                    <Skeleton sx={{ height: '2rem' }}></Skeleton>
                    <Skeleton sx={{ height: '2rem' }}></Skeleton>
                    <Skeleton sx={{ height: '2rem' }}></Skeleton>
                    <Skeleton sx={{ height: '2rem' }}></Skeleton>
                    <Skeleton sx={{ height: '2rem' }}></Skeleton>
                    <Skeleton sx={{ height: '2rem' }}></Skeleton>
                    <Skeleton sx={{ height: '2rem' }}></Skeleton>
                    <Skeleton sx={{ height: '2rem' }}></Skeleton>
                    <Skeleton sx={{ height: '2rem' }}></Skeleton>
                  </Box>}
              </Box>
            </TableBody>
          </Table>
        </Box>
        <Box sx={{ width: { lg: "63vw", xl: "66vw" }, }}>
          <Box sx={{ width: '100%', display: 'flex', alignItems: 'flex-start', }}>
            {!detailsLoading && productList ?
              productList?.map((item: any, index: number) => {
                return (
                  <Box sx={{ display: "flex", flexDirection: "column", borderRight: "1px solid", width: { lg: '18vw', xl: '15vw' }, backgroundColor: 'primary.light' }}>

                    <Box sx={{ display: "flex", alignItems: "center", width: '100%', padding: '0 0.5rem', }}>
                      <Box sx={{ width: "100%", }}><Typography sx={{ fontSize: "1rem", width: "100%", whiteSpace: 'nowrap', textAlign: 'center' }}>{item?.top_vault_name}</Typography></Box>
                      <Box sx={{ display: "flex", alignItems: "center", width: "5rem" }}>
                        <LoadingButton sx={{ minWidth: '1rem' }} loading={isLoading} onClick={() => downloadListTopVault(item?.top_vault_id)} ><DownloadIcon /></LoadingButton>
                        <Checkbox
                          style={{ padding: "5px" }}
                          checkedIcon={
                            <Box sx={{ backgroundColor: "white", borderRadius: "50%", height: "1rem", width: "1rem" }}>
                              <VerifiedIcon sx={{ color: "#39FF14", position: "relative", margin: "-0.35rem -0.25rem 0" }} />
                            </Box>
                          }
                          checked={item?.is_verified}
                          title="Validated"
                          color="success"
                          sx={{ "& .MuiSvgIcon-root": { fontSize: 22 }, color: "#007fff", padding: { lg: '5px', xl: "7px" } }}
                        />
                      </Box>
                    </Box>

                    <Box sx={{ display: 'flex', alignItems: 'center', width: '100%' }}>
                      <Box sx={{ width: { lg: '6vw', xl: '5vw' }, textAlign: "center", padding: "0.2rem 0.5rem", borderBottomColor: "primary.light", whiteSpace: "nowrap" }}>Verified</Box>
                      <Box sx={{ width: { lg: '6vw', xl: '5vw' }, textAlign: "center", padding: "0.2rem 0.5rem", borderBottomColor: "primary.light", whiteSpace: "nowrap" }}>Total Parts</Box>
                      <Box sx={{ width: { lg: '6vw', xl: '5vw' }, textAlign: "center", padding: "0.2rem 0.5rem", borderBottomColor: "primary.light", whiteSpace: "nowrap" }}>Method</Box>
                    </Box>
                  </Box>
                );
              }) : <Box sx={{ width: '100%', padding: '0 1rem' }}>
                <Skeleton
                  variant="rectangular"
                  height="4rem"
                  width="100%"
                  sx={{ marginBottom: "1rem" }}
                />
              </Box>}
          </Box>
          <Box sx={{ width: '100%' }}>
            {!detailsLoading && details ?
              details?.map((item: any, index: any) => {
                return (
                  <Box>
                    <Box key={index} sx={{ width: '100%' }}>
                      <Box sx={{ borderBottom: '1px solid', borderBottomColor: 'primary.light', width: 'fit-content', display: 'flex', alignContent: 'center' }}>
                        {item.data?.map((itm: any, index: any) => {
                          return <Dispatchproducts data={itm} />;
                        })}
                      </Box>
                    </Box>
                  </Box>
                );
              })
              : <Box sx={{ padding: '0 1rem' }}>
                <Skeleton sx={{ height: '2rem' }}></Skeleton>
                <Skeleton sx={{ height: '2rem' }}></Skeleton>
                <Skeleton sx={{ height: '2rem' }}></Skeleton>
                <Skeleton sx={{ height: '2rem' }}></Skeleton>
                <Skeleton sx={{ height: '2rem' }}></Skeleton>
                <Skeleton sx={{ height: '2rem' }}></Skeleton>
                <Skeleton sx={{ height: '2rem' }}></Skeleton>
                <Skeleton sx={{ height: '2rem' }}></Skeleton>
                <Skeleton sx={{ height: '2rem' }}></Skeleton>
                <Skeleton sx={{ height: '2rem' }}></Skeleton>
                <Skeleton sx={{ height: '2rem' }}></Skeleton>
                <Skeleton sx={{ height: '2rem' }}></Skeleton>
                <Skeleton sx={{ height: '2rem' }}></Skeleton>
                <Skeleton sx={{ height: '2rem' }}></Skeleton>
                <Skeleton sx={{ height: '2rem' }}></Skeleton>
                <Skeleton sx={{ height: '2rem' }}></Skeleton>
                <Skeleton sx={{ height: '2rem' }}></Skeleton>
                <Skeleton sx={{ height: '2rem' }}></Skeleton>
                <Skeleton sx={{ height: '2rem' }}></Skeleton>
                <Skeleton sx={{ height: '2rem' }}></Skeleton>
                <Skeleton sx={{ height: '2rem' }}></Skeleton>
                <Skeleton sx={{ height: '2rem' }}></Skeleton>
                <Skeleton sx={{ height: '2rem' }}></Skeleton>
                <Skeleton sx={{ height: '2rem' }}></Skeleton>
              </Box>}
          </Box>
        </Box>
      </Box>
    </div>
  );
}
