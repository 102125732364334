import { Box } from "@mui/material";
import Modal from "@mui/material/Modal";
import { orderBy, update } from "lodash";
import { useEffect, useState } from "react";
import Plotly from "plotly.js";
import createPlotlyComponent from "react-plotly.js/factory";
import { numberFormat } from "../../utlis/valueFormat";
import ClearIcon from "@mui/icons-material/Clear";
import BouncingLoader from "../../ui-reusable-component/BouncingLoader";
import SkeletonLoaderComponent from "../../ComponentUI/SkeletonLoader/SkeletonLoaderComponent";
const Plot = createPlotlyComponent(Plotly);

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 1000,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export interface FeatureModalProp {
  isOpen: any;
  onCloseModal: any;
  GraphData: any;
  graphLoader: any;
}

const NumericalFeatureModal = (prop: FeatureModalProp) => {
  const { isOpen, onCloseModal, GraphData, graphLoader } = prop;

  const [BarGraphs, setBarGraphs] = useState<any>([]);

  useEffect(() => {
    setBarGraphs(GraphData);
  }, [GraphData]);

  // @ts-ignore

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
      
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {graphLoader ? (
            // <BouncingLoader />
            <SkeletonLoaderComponent/>
          ) : (
            <>
              <ClearIcon onClick={onCloseModal} style={{cursor:"pointer"}} titleAccess="Close"/>
              <div className="selected-parameters-plot">
                <div className="plotgraph" style={{ fontWeight: 600 }}>
                  <Plot
                    // layout={ el.graphLayout }

                    data={
                      BarGraphs &&
                      BarGraphs?.map((values: any) => {
                        return {
                          x: [values?.x],
                          y: [values?.y],
                          type:"bar",
                          text: `${(values?.y)
                            ?.toString()
                            .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`,
                          textposition: "auto",

                          hoverinfo: "name",
                          showlegend: true,
                          name: values?.name,
                        };
                      })
                    }
                    layout={{
                      xaxis: {
                        visible: true,
                        showticklabels: false,
                        fixedrange: true,
                        title: {
                          text: BarGraphs && BarGraphs[0]?.x,
                        },
                      },
                      yaxis: { fixedrange: true },
                      height: 350,
                      width: 900,
                      margin: {
                        l: 40,
                        t: 20,
                        r: 20,
                        b: 30,
                        pad: 0,
                      },
                      font: {
                        // @ts-ignore
                        size: "10",
                      },
                      showlegend: true,
                      // @ts-ignore
                      legend: { orientation: "t" },
                    }}
                    config={{
                      modeBarButtonsToRemove: [
                        "zoom2d",
                        "pan2d",
                        "select2d",
                        "lasso2d",
                        "zoomIn2d",
                        "zoomOut2d",
                        "autoScale2d",
                        "resetScale2d",
                        "hoverClosestGl2d",
                        "hoverClosestPie",
                        "toggleHover",
                        "resetViews",
                        "sendDataToCloud",
                        //   "resetViewMapbox",
                        "hoverClosestCartesian",
                        "hoverCompareCartesian",
                        "toggleSpikelines",
                      ],
                      displaylogo: false,
                    }}
                  />
                </div>
              </div>
            </>
          )}
        </Box>
      </Modal>
    </div>
  );
};

export default NumericalFeatureModal;
