// react

import axios from "axios";
import { MODULES } from "../../Constants/modules.constants";
import { API } from "../../api-services";
import { s3Url } from "../../utlis/s3url.utils";
import { getVideoCover } from "../../CustomHook/CreateVideoThumbnail";
import { ADMIN } from "../../Redux/Services/admin.service";
import { createRepoImage, updateRepoImage } from "../../Redux/Actions/imageRepository.actions";


const mediaSingleUpload = (
   fileList: any,
   group: any,
   top_vault: any,
   vault: any,
   dispatch: any,
   setIsLoading: any,
   mediaType: any,
   uploadmodal: any,
   imageId: any,
   actionCome: any,
   image_category?: any,
   sequence?: any,
   history?: any,
   projectId?:any,
   abbreviation?:any,
   projectName?:any,
   category?:any,
   productList?:any,
   pageNumber?:any,
   apiCall?:any, 
   is_mute?:any 
) => {

   // const file_name = file?.name;
   var random: any = Math.floor( Math.random() * 1000000 + 1 );
   var editedImageName: any = `EditedImage${random}`;
   var obj: Array<any> = [];
   // const dispatch = useAppDispatch();
   // + "-" + uuidv4();
   API.get( "cad/cad_upload/", {
      module: MODULES.CAD,
      product: top_vault == 0 ? vault : top_vault,
   } ).then( res => {

      const data = Array.from( fileList ).forEach( ( file: any, index: any ) => {
         const formData = new FormData();
         formData.append(
            "key", `${group ? group : "UnCategorised"}/${top_vault}/${vault ? vault : "Vehicle"}/${random}/${file.name || editedImageName}` );
         formData.append( "Content-Type", file?.type );
         formData.append( "x-amz-server-side-encryption", "AES256" );
         formData.append( "X-Amz-Credential", res.data.data.x_amz_credential );
         formData.append( "X-Amz-Algorithm", "AWS4-HMAC-SHA256" );
         formData.append( "X-Amz-Date", res.data.data.x_amz_date );
         formData.append(
            "X-Amz-Signature",
            res.data.data.aws_policy_signature
         );
         formData.append( "Policy", res.data.data.aws_policy );
         formData.append( "file", file, file?.name );

         axios
            .post( s3Url, formData, {
               onUploadProgress: data => {
                  //Set the progress value to show the progress bar
               },
            } )
            .then( async ( res: any ) => {
               if ( res.status === 204 ) {
                  if ( mediaType === "video" ) {
                     try {
                        // get the frame at 1.5 seconds of the video file
                        const cover = await getVideoCover( file, 1.5 );
                        //@ts-ignore
                        formData.set( "file", cover, cover?.name );
                        formData.set( "key", `${group ? group : "UnCategorised"}/${top_vault}/${vault ? vault : "Vehicle"}/${random}/thumb/${file.name.replace( ".mp4", ".jpeg" )}` );
                        formData.set( "Content-Type", 'image/jpeg' );
                        axios
                           .post( s3Url,
                              formData );
                        // print out the result image blob
                     } catch ( ex ) {
                        console.log( "ERROR: ", ex );
                     }
                  }

                  if ( mediaType === "video" ) {
                     let customObj: any = {
                        image: `/${group ? group : "UnCategorised"}/${top_vault}/${vault ? vault : "Vehicle"}/${random}/${file.name}`,
                        thumbnail: `/${group ? group : "UnCategorised"}/${top_vault}/${vault ? vault : "Vehicle"}/${random}/thumb/${file.name.replace( ".mp4", ".jpeg" )}`
                     };
                     obj.push( customObj );
                  }
                  if ( mediaType === "video" ) {
                     if ( actionCome === "update" ) {
                        await dispatch( updateRepoImage( {
                           id: imageId,
                           image: obj,
                           rgb: false,
                           media_type: "video",
                           group: group,
                           is_mute:is_mute
                        } ) ).then((res:any) => {
                           history.push(
                              `/mediacreateinternal/${projectId}/${projectName}/${mediaType}/${abbreviation}/${category}/${group}/${productList}/${pageNumber}/${apiCall == "true" ? "false" : "true"}`
                            );
                           setIsLoading( false );
                           uploadmodal.close();
                        }).catch((err:any)=>{
                           setIsLoading( false );
                        });
                     } else {
                        await dispatch( createRepoImage( {
                           image: obj,
                           image_category: image_category,
                           sequence,
                           top_vault: top_vault,
                           vault: vault,
                           media_type: mediaType,
                           group: group,
                           is_mute : is_mute
                        } ) ).then((res:any) => {
                           history.push(
                             `/mediacreateinternal/${projectId}/${projectName}/${mediaType}/${abbreviation}/${category}/${group}/${productList}/${pageNumber}/${apiCall == "true" ? "false" : "true"}`
                           );
                           setIsLoading( false );
                           uploadmodal.close();
                         }).catch((err:any)=>{
                           setIsLoading( false );
                        });
                     }

                  }
                  if ( mediaType === "image" ) {
                     if ( actionCome === "update" ) {
                      var action=  await dispatch(
                           updateRepoImage( {
                              id: imageId,
                              image: `/${group ? group : "UnCategorised"}/${top_vault}/${vault ? vault : "Vehicle"}/${random}/${file.name || editedImageName}`,
                              rgb: false,
                              media_type: mediaType,
                              group: group,
                           } )
                        ).then((res:any) => {
                           history.push(
                             `/mediacreateinternal/${projectId}/${projectName}/${mediaType}/${abbreviation}/${category}/${group}/${productList}/${pageNumber}/${apiCall == "true" ? "false" : "true"}`
                           );
                           setIsLoading( false );
                           uploadmodal.close();
                         }).catch((err:any)=>{
                           setIsLoading( false );
                        });
                     } 
                     else {
                     var action=  await dispatch( createRepoImage( {
                           image: `/${group ? group : "UnCategorised"}/${top_vault}/${vault ? vault : "Vehicle"}/${random}/${file.name || editedImageName}`,
                           image_category: image_category,
                           sequence,
                           top_vault: top_vault,
                           vault: vault,
                           rgb: false,
                           media_type: mediaType,
                           group: group
                        } ) ).then((res:any) => {
                           history.push(
                             `/mediacreateinternal/${projectId}/${projectName}/${mediaType}/${abbreviation}/${category}/${group}/${productList}/${pageNumber}/${apiCall == "true" ? "false" : "true"}`
                           );
                           setIsLoading( false );
                           uploadmodal.close();
                         }).catch((err:any)=>{
                           setIsLoading( false );
                        });
                     }
                  }
                  ;
                  // }
               }
            } ).catch((err)=>{return ADMIN?.toast?.error(`Files not uploaded are :${file?.name}`);});
      } );
   } );
};

// ----------------------------------------------------------------------------------

export default mediaSingleUpload;
