const MODULES = {
    USER_MANAGEMENT: 1,
    PERMISSION_MANAGEMENT: 2,
    DATABASES: 3,
    PROJECT: 4,
    TOP_VAULT: 5,
    TEMPLATE_CONFIGURATION: 6,
    CUSTOM_VALIDATIONS: 7,
    WORKSTATIONS: 8,
    BOM_GENERATION: 9,
    BOM_VISUALIZATION: 10,
    REPORT_CONFIGURATION: 11,
    REPORT_GENERATION: 12,
    BOM_ANALYTICS: 13,
    IDEA_CONFIGURATION: 14,
    IDEA_GENERATION: 15,
    COSTING: 16,
    FEATURE_CONFIGURATION: 17,
    FEATURE_GENERATION: 18,
    FEATURE_ANALYTICS: 19,
    RECYCLE_BIN: 20,
    ACTIVITY_LOGS: 21,
    COMMODITY_CONFIGURATION: 22,
    ARCHITECTURE: 23,
    IMAGE_REPOSITORY: 24,
    IDEA_APPROVAL: 25,
    CIRCUIT_DIAGRAM_ANALYTICS: 26,
    BOM_CHECK: 27,
    PROCURE: 28,
    SELL_BOM: 29,
    SELL_PO: 30,
    PROCURE_CONFIG: 31,
    SELL_BOM_CONFIGURATION: 32,
    SELL_PO_CONFIGURATION: 33,
    CAD: 34,
    VEHICLE_INWARDING: 35,
    FEATURE_REPORT: 36,
    FEATURE_REPORT_CONFIG: 37,
    IDEA_REPORT: 38,
    IDEA_REPORT_CONFIG: 39,
    PROJECT_CREATION: 40,
    WORKSHOP: 41,
    DRIVE: 42,
    MOM: 43,
    BANNER:47,
    WORKSHOP_BANNER:48,
};




const MODULES_OPTIONS = [
    {
        key: MODULES.BOM_ANALYTICS,
        text: "BOM Analytics",
        value: MODULES.BOM_ANALYTICS,
    },
    {
        key: MODULES.BOM_VISUALIZATION,
        text: "BOM Visualisation",
        value: MODULES.BOM_VISUALIZATION,
    },
    {
        key: MODULES.BOM_GENERATION,
        text: "BOM Generations",
        value: MODULES.BOM_GENERATION,
    },
    {
        key: MODULES.ARCHITECTURE,
        text: "Schematics",
        value: MODULES.ARCHITECTURE,
    },
    {
        key: MODULES.CIRCUIT_DIAGRAM_ANALYTICS,
        text: "Circuit Diagram Analytics",
        value: MODULES.CIRCUIT_DIAGRAM_ANALYTICS,
    },
    {
        key: MODULES.COMMODITY_CONFIGURATION,
        text: "Commodity Configuration",
        value: MODULES.COMMODITY_CONFIGURATION,
    },
    {
        key: MODULES.COSTING,
        text: "Costing Module",
        value: MODULES.COSTING,
    },
    {
        key: MODULES.CUSTOM_VALIDATIONS,
        text: "Custom Validation",
        value: MODULES.CUSTOM_VALIDATIONS,
    },
    {
        key: MODULES.DATABASES,
        text: "Databases",
        value: MODULES.DATABASES,
    },
    {
        key: MODULES.FEATURE_ANALYTICS,
        text: "Features Analytics",
        value: MODULES.FEATURE_ANALYTICS,
    },
    {
        key: MODULES.FEATURE_CONFIGURATION,
        text: "Features Configuration",
        value: MODULES.FEATURE_CONFIGURATION,
    },
    {
        key: MODULES.FEATURE_GENERATION,
        text: "Features Generation",
        value: MODULES.FEATURE_GENERATION,
    },
    {
        key: MODULES.IDEA_APPROVAL,
        text: "Idea Approval",
        value: MODULES.IDEA_APPROVAL,
    },
    {
        key: MODULES.IDEA_CONFIGURATION,
        text: "Idea Configuration",
        value: MODULES.IDEA_CONFIGURATION,
    },
    {
        key: MODULES.IDEA_GENERATION,
        text: "Idea Generation",
        value: MODULES.IDEA_GENERATION,
    },
    {
        key: MODULES.IMAGE_REPOSITORY,
        text: "Image Repository",
        value: MODULES.IMAGE_REPOSITORY,
    },
    {
        key: MODULES.PERMISSION_MANAGEMENT,
        text: "Permission Management",
        value: MODULES.PERMISSION_MANAGEMENT,
    },
    {
        key: MODULES.REPORT_CONFIGURATION,
        text: "Report Configuration",
        value: MODULES.REPORT_CONFIGURATION,
    },
    {
        key: MODULES.REPORT_GENERATION,
        text: "Report Generation",
        value: MODULES.REPORT_GENERATION,
    },
    {
        key: MODULES.TEMPLATE_CONFIGURATION,
        text: "Template Configuration",
        value: MODULES.TEMPLATE_CONFIGURATION,
    },
    {
        key: MODULES.USER_MANAGEMENT,
        text: "User Management",
        value: MODULES.USER_MANAGEMENT,
    },
    {
        key: MODULES.WORKSTATIONS,
        text: "Workstation Management",
        value: MODULES.WORKSTATIONS,
    },
    {
        key: MODULES.BOM_CHECK,
        text: "Despatch",
        value: MODULES.BOM_CHECK,
    },
    {
        key: MODULES.PROCURE,
        text: "Procure",
        value: MODULES.PROCURE,
    },
    {
        key: MODULES.SELL_BOM,
        text: "Sell",
        value: MODULES.SELL_BOM,
    },
    {
        key: MODULES.PROCURE_CONFIG,
        text: "Procure Config",
        value: MODULES.PROCURE_CONFIG,
    },
    {
        key: MODULES.SELL_BOM_CONFIGURATION,
        text: "Sell Config",
        value: MODULES.SELL_BOM_CONFIGURATION,
    },
    {
        key: MODULES.CAD,
        text: "CAD",
        value: MODULES.CAD,
    }
];
const MODULES_OPTIONS_STRING = [
    {
        key: MODULES.BOM_ANALYTICS,
        text: "BOM Analytics",
        value: "BOM Analytics",
    },
    {
        key: MODULES.BOM_VISUALIZATION,
        text: "BOM Visualisation",
        value: "BOM Visualisation",
    },
    {
        key: MODULES.BOM_GENERATION,
        text: "BOM Generations",
        value: "BOM Generations",
    },
    {
        key: MODULES.ARCHITECTURE,
        text: "Schematics",
        value: "Schematics",
    },
    {
        key: MODULES.CIRCUIT_DIAGRAM_ANALYTICS,
        text: "Circuit Diagram Analytics",
        value: "Circuit Diagram Analytics",
    },
    {
        key: MODULES.COMMODITY_CONFIGURATION,
        text: "Commodity Configuration",
        value: "Commodity Configuration",
    },
    {
        key: MODULES.COSTING,
        text: "Costing Module",
        value: "Costing Module",
    },
    {
        key: MODULES.CUSTOM_VALIDATIONS,
        text: "Custom Validation",
        value: "Custom Validation",
    },
    {
        key: MODULES.DATABASES,
        text: "Databases",
        value: "Databases",
    },
    {
        key: MODULES.FEATURE_ANALYTICS,
        text: "Features Analytics",
        value: "Features Analytics",
    },
    {
        key: MODULES.FEATURE_CONFIGURATION,
        text: "Features Configuration",
        value: "Features Configuration",
    },
    {
        key: MODULES.FEATURE_GENERATION,
        text: "Features Generation",
        value: "Features Generation",
    },
    {
        key: MODULES.IDEA_APPROVAL,
        text: "Idea Approval",
        value: "Idea Approval",
    },
    {
        key: MODULES.IDEA_CONFIGURATION,
        text: "Idea Configuration",
        value: "Idea Configuration",
    },
    {
        key: MODULES.IDEA_GENERATION,
        text: "Idea Generation",
        value: "Idea Generation",
    },
    {
        key: MODULES.IMAGE_REPOSITORY,
        text: "Image Repository",
        value: "Image Repository",
    },
    {
        key: MODULES.PERMISSION_MANAGEMENT,
        text: "Permission Management",
        value: "Permission Management",
    },
    {
        key: MODULES.REPORT_CONFIGURATION,
        text: "Report Configuration",
        value: "Report Configuration",
    },
    {
        key: MODULES.REPORT_GENERATION,
        text: "Report Generation",
        value: "Report Generation",
    },
    {
        key: MODULES.TEMPLATE_CONFIGURATION,
        text: "Template Configuration",
        value: "Template Configuration",
    },
    {
        key: MODULES.USER_MANAGEMENT,
        text: "User Management",
        value: "User Management",
    },
    {
        key: MODULES.WORKSTATIONS,
        text: "Workstation Management",
        value: "Workstation Management",
    },
    {
        key: MODULES.BOM_CHECK,
        text: "Despatch",
        value: "Despatch",
    },
    {
        key: MODULES.PROCURE,
        text: "Procure",
        value: "Procure",
    },
    {
        key: MODULES.SELL_BOM,
        text: "Sell",
        value: "Sell",
    },
    {
        key: MODULES.PROCURE_CONFIG,
        text: "Procure Config",
        value: "Procure Config",
    },
    {
        key: MODULES.SELL_BOM_CONFIGURATION,
        text: "Sell Config",
        value: "Sell Config",
    },
    {
        key: MODULES.CAD,
        text: "CAD",
        value: "CAD",
    }
];

export { MODULES, MODULES_OPTIONS, MODULES_OPTIONS_STRING };