
import { CancelToken } from "axios";
import { API } from "./index.service";
import { ID } from "../../utlis/commonInterfaces";


const url = "xcpep/component_details/";
const inwardUrl = "vehicle_inward/input_vehicle_inward/";
const tableApi = "/xcpep/table_parameter_image/";

export interface GetTemplateValues {
    topVault: ID;
    /**
     * can be zero, while fetching values for top vault
     */
    vault: ID;
}

export default {
    
    get ( arg: GetTemplateValues, cancelToken?: CancelToken ) {
        const { topVault, vault } = arg;

        return API.get(
            url,
            {
                top_vault: vault ? undefined : topVault,
                vault: vault ? vault : undefined,
            },
            0,
            
        );
    },




};
