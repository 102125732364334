import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import { useHistory } from "react-router-dom";
import { Backdrop, Chip, Divider, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { API } from "../../api-services";
import { LoadingButton } from "@mui/lab";
import CancelIcon from "@mui/icons-material/Cancel";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import Swal from "sweetalert2";
import successIcon from "../../Assets/images/success.png";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45vw",
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 0,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface IWatermarkModelProps {
  isOpen: any;
  onCloseModal: any;
  nameType: any;
  setCounter: any;
  // setValue: any;
}

function AddAssumptionCardModal(props: IWatermarkModelProps) {
  const { isOpen, onCloseModal, nameType, setCounter } = props;
  const { library } = useRouteParams<any>();
  const { productId, projectId } = useRouteParams<any>();
  const [volume, setVolume] = React.useState<any>("");
  const [revision, setRevision] = React.useState<any>("");
  const [locationList, setLocationList] = React.useState<any>([]);
  const handleChange = (e: any) => {
    const { name, value } = e.target;
    if (name === "volume") {
      setVolume(value);
    } else {
      setRevision(value);
    }
  };
console.log(nameType, "nameType");
  const getLocationsName = () => {
    API.get(
      "cost/assumption_card/",
      {
        revision_type: nameType.assumptionType === "main" ? 1 : 2,
        project: projectId,
      },
      0
    )
      .then((res: any) => {
        setLocationList(res.data);
      })
      .catch((err: any) => {});
  };
  React.useEffect(() => {
    getLocationsName();
  }, []);
  // console.log(Object.keys(nameType)?.length,"teststtststsst",nameType)
  React.useEffect(() => {
    if (typeof nameType !== "string") {
      const { location, volume, revision_id } = nameType;
      setVolume(volume);
      setRevision(revision_id);
    }
  }, [nameType]);

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}>
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}>
              Add/Edit Assumption
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ padding: " 1rem 2rem", display: "flex", alignItems: "baseline", columnGap: "1rem" }}>
            <FormControl fullWidth>
              <InputLabel  shrink sx={{color:'primary.main',marginLeft:'-1rem'}}  id="demo-simple-select-label">Select Revision</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                variant="standard"
                label="Type"
                value={revision}
                name="revision"
                sx={{
                  width: "100%",
                  fontSize: "1rem",
                  "&:before": {
                    borderBottomColor: "primary.light",
                  },
                  ".MuiSvgIcon-root.MuiSelect-icon": {
                    color: "primary.main",
                  },
                }}
                onChange={handleChange}>
                {locationList &&
                  locationList?.map((item: any) => {
                    return (
                      <MenuItem
                        sx={{ fontSize: "1rem" }}
                        value={item.id}>
                        {item.revision_name}
                      </MenuItem>
                    );
                  })}
              </Select>
            </FormControl>
            <FormControl
              fullWidth
              variant="standard">
                <InputLabel shrink sx={{color:'primary.main',}} id='demo-simple-select-label'>
                  Volume
							</InputLabel>
              <TextField
                variant="standard"
                name="volume"
                type="number"
                // placeholder="Volume"
                value={volume || ""}
                onChange={handleChange}
                sx={{
                  marginTop:'1.5rem',
                  width: "100%",
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "primary.light",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "primary.main",
                  },
                }}
              />
            </FormControl>
          </Box>
          <Box sx={{ display: "flex", justifyContent: "flex-end", margin: "0 1rem 1rem 0" }}>
            <LoadingButton
              size="small"
              variant="contained"
              sx={{ marginTop: "1rem" }}
              onClick={() => {
                if (Object.keys(nameType)?.length > 1) {
                  API.put(
                    `/cost/assumption/${nameType.id}/`,
                    {
                      top_vault: productId,
                      project: projectId,
                      revision_id: revision,
                      volume: volume,
                      assumption_type: nameType.assumptionType === "main" ? 1 : 2,
                      validation: false,
                    },
                    {},
                    0
                  )
                    .then((res: any) => {
                      setCounter((prev: any) => prev + 1);
                      onCloseModal();
                      
                      Swal.fire({
                        html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />          
                <br />          
                <p style="color:#007fff;">Assumption card updated successfully!</p>             
                 </div>`,
                      customClass: 
                        {
                          container: 'swal2Container',
                        },
                      });
                    })
                    .catch((err: any) => {
                      Swal.fire({
                        icon: "error",
                        html: `<div>          
                        <br />          
                        <p style="color:#007fff;">Assumption card update failed!</p>   
                        </div>`,
                        customClass: 
                              {
                                container: 'swal2Container',
                              },
                            });
                          });
                } else {
                  API.post(
                    "/cost/assumption/",
                    {
                      top_vault: productId,
                      project: projectId,
                      revision_id: revision,
                      volume: volume,
                      assumption_type: nameType.assumptionType === "main" ? 1 : 2,
                    },
                    {},
                    0
                  )
                    .then((res: any) => {
                      setCounter((prev: any) => prev + 1);
                      // setValue((prev: any) => {
                      //   return prev;
                      // });
                      Swal.fire({
                        html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
                      <br />
                      <p style="color:#007fff;">Assumption card created successfully!</p> 
                      </div>`,
                 customClass: 
                        {
                          container: 'swal2Container',
                        },
                      });
                      onCloseModal();
                    })
                    .catch((err: any) => {
                      Swal.fire({
                        icon: "error",
                        html: `<div>          
                <br />          
                <p style="color:#007fff;">Assumption card creation failed!</p>
                 </div>`,
                 customClass: 
                        {
                          container: 'swal2Container',
                        },
                      });
                    });
                }
              }}>
              {Object.keys(nameType)?.length > 1 ? "Save" : "Create"}
            </LoadingButton>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
export default AddAssumptionCardModal;
