import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CancelIcon from '@mui/icons-material/Cancel';
import { Chip, Divider, TextField } from '@mui/material';
import { useBaseModal } from '../SearchPage/useBaseModal';
import { MediaCreateImageUploadModal } from './MediaCreateImageUploadModal';
import { useContext, useState } from 'react';
import { createGroupsByType } from '../../Redux/Actions/groupingMediaCreate.action';
import { useRouteParams } from '../../ui-reusable-component/useRouteParams';
import { useAppDispatch } from '../../../AppRouter';
import { MyContext } from './MediaCreateLanding';
import ErrorModalFilesMail from '../ProjectMailModule/ErrorModalFilesMail';
import { MediaMobileImageCapture } from './MediaMobileImageCapture';
import useWindowDimensions from '../../ui-reusable-component/useWindowDimensions';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '80vw', sm: '50vw', lg: '30vw' },
    height: 'fit-content',
    bgcolor: 'background.paper',
    borderRadius: '10px',
    p: 1,
};

interface Params {
    projectId: any;
    projectName: any;
    mediaType: any;
    category: any;
    abbreviation: any;
    groupId: any;
    productList: any;
    pageNumber: any;
    apiCall: any;
}
export interface IMediaCreateNameModalProps {
    onCloseModal: any;
    isOpen: any;
    abbreviationGet: any;
    categoryGet: any
}

export function MediaCreateNameModal(props: IMediaCreateNameModalProps) {
    const { onCloseModal, isOpen, abbreviationGet, categoryGet } = props;
    const { projectId, projectName, productList, mediaType, groupId, pageNumber, apiCall } = useRouteParams<Params>();
    const [groupName, setGroupName] = useState('');
    const [sendNextModelGroupId, setSendNextModelGroupId] = useState<any>();
    const [errorMessage, setErrorMessage] = useState<any>();

    const { height, width } = useWindowDimensions();
    const errorValidation = useBaseModal();
    const ImageUploadModal = useBaseModal();
    const dispatch = useAppDispatch();
    const MobileImageUploadModal = useBaseModal();

    const ImageUploadHandler = () => {
        dispatch(createGroupsByType({
            name: groupName,
            projectId: projectId,
            mediaType: mediaType,
            abbreviation: abbreviationGet,
            image_category: categoryGet,
        })).then((res: any) => {
            if (res.payload?.response?.status == 400) {
                setErrorMessage(res.payload?.response?.data[0]);
                errorValidation.open();
            }
            else {
                setSendNextModelGroupId(res.payload?.Imagegroups?.filter((item: any) => item?.name == res.payload?.name)[0]?.id);
                ImageUploadModal.open();
            }
        })
    }

    const mobileUploadHandler = () => {
        dispatch(createGroupsByType({
            name: groupName,
            projectId: projectId,
            mediaType: mediaType,
            abbreviation: abbreviationGet,
            image_category: categoryGet,
        })).then((res: any) => {
            if (res.payload?.response?.status == 400) {
                setErrorMessage(res.payload?.response?.data[0]);
                errorValidation.open();
            }
            else {
                setSendNextModelGroupId(res.payload?.Imagegroups?.filter((item: any) => item?.name == res.payload?.name)[0]?.id);
                MobileImageUploadModal.open();
            }
        })
    }

    const groupNameChange = (event: any) => {
        setGroupName(event.target.value);
    };

    return (
        <div>
            <ErrorModalFilesMail
                onCloseModal={errorValidation.close}
                isOpen={errorValidation.isOpen}
                ErrorMsg={errorMessage}
            />
            {ImageUploadModal.isOpen && (
                <MediaCreateImageUploadModal
                    onCloseModal={ImageUploadModal.close}
                    isOpen={ImageUploadModal.open}
                    sendNextModelGroupId={sendNextModelGroupId}
                    onCloseModalGroupName={onCloseModal}
                    groupName={groupName}
                    categoryGet={categoryGet}
                    abbreviationGet={abbreviationGet}
                    sequenceSend={1}
                    type={'imageGroupSideUpload'}
                />
            )}

            {MobileImageUploadModal.isOpen && (
                <MediaMobileImageCapture
                    onCloseModal={MobileImageUploadModal.close}
                    isOpen={MobileImageUploadModal.open}
                    sendNextModelGroupId={sendNextModelGroupId}
                    onCloseModalGroupName={onCloseModal}
                    categoryGet={categoryGet}
                    abbreviationGet={abbreviationGet}
                    sequenceSend={1}
                    type={'imageGroupSideUpload'}
                />
            )}


            <Modal
                open={isOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
                        <Typography
                            style={{
                                fontSize: "1rem",
                                fontWeight: "600",
                                width: "100%",
                                // textAlign: "center",
                                marginLeft: '1rem',
                                marginRight: '-2rem'
                            }}
                        >
                            System Name {'->'} Group Name
                        </Typography>
                        <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
                    </Box>
                    <Divider
                        sx={{
                            borderColor: "primary.light",
                            margin: "0 1rem",
                            marginBottom: "1rem",
                        }}
                    />
                    <Box sx={{ margin: '0 1rem 0.5rem' }}>
                        <TextField
                            variant="standard"
                            label="Add Group"
                            placeholder="Add Group"
                            fullWidth
                            value={groupName}
                            onChange={groupNameChange}
                            sx={{
                                ".MuiInputBase-root.MuiInput-root": {
                                    fontSize: '1rem !important'
                                },
                                '& .MuiInput-underline:before': { borderBottomColor: 'primary.main' },
                                '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                            }}
                        />
                        <Box sx={{ width: '100%', textAlign: 'right', marginTop: '1rem' }}>
                            {width > 600 ?
                                <Button variant='contained' size='small' sx={{ borderRadius: '3rem', }} onClick={() => ImageUploadHandler()}>Save & Proceed to add pictures</Button> :

                                <Button variant='contained' size='small' sx={{ borderRadius: '3rem', }} onClick={() => mobileUploadHandler()}>Save & Proceed to add pictures</Button>}
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </div >
    );
}
