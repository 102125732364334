import React, { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  Divider,
  Skeleton,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import { useRouteParams } from "../../../../ui-reusable-component/useRouteParams";
import { useBaseModal } from "../../../SearchPage/useBaseModal";
import { API } from "../../../../api-services";
import { DialogComponent } from "../../../../utlis/DialogComponent";
import CostingScenerioSystemInsideBodyRows from "./CostingScenerioSystemInsideBodyRows";
import { AxiosError, AxiosResponse } from "axios";
import { LoadingButton } from "@mui/lab";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useHistory } from "react-router-dom";
import { Bar, BarChart, CartesianGrid, Cell, Label, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import CancelIcon from '@mui/icons-material/Cancel';
import { TransitionProps } from "@mui/material/transitions";

const colors = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', 'red', 'pink', "#2d969b", "#fa913c", "#ff4164", "#64505a", "#9b5a64", "#376ea5", "#c86469", "#5f7896", "#e1b496", "#a0a5a0", "#e1e6e6", "#6e6437", "#2d2d1e"];

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Params {
  projectId: any;
  projectName: any;
  top_vault: any;
  abbr: any;
  scenarioName: any;
  vault_id: any;
  inside_scenario_id:any;
  scenarioIds:any;
  initialScenarioId:any;
  expandTable:any;
}

interface ICostingScenerioSystemInsideBodyProps {
  pasteVaultNoList: any;
  setPasteVaultNoList: any;
  setCopyVaultNo: any;
  setCopyVaultCategory: any;
  copyVaultCategory: any;
  setShowCheckboxCopyClicked: any;
  showCheckboxCopyClicked: any;
  reloadCostingData: any;
  setReloadCostingData: any;
  apiCallMain: any;
  category: any;
  assemblyPart: any;
  calculatorType: any;
  count:any;
  setAncestorSetFromAPI:any;
}

const CostingScenerioSystemInsideBody: React.FC<
  ICostingScenerioSystemInsideBodyProps
> = (props) => {
  const {
    pasteVaultNoList,
    setPasteVaultNoList,
    setCopyVaultNo,
    setCopyVaultCategory,
    copyVaultCategory,
    setShowCheckboxCopyClicked,
    showCheckboxCopyClicked,
    reloadCostingData,
    setReloadCostingData,
    apiCallMain,
    category,
    assemblyPart,
    calculatorType,
    count,
    setAncestorSetFromAPI
  } = props;
  const { projectId, projectName, top_vault, abbr, scenarioName, vault_id, inside_scenario_id, scenarioIds, initialScenarioId, expandTable} =
    useRouteParams<Params>();
  const [costingTableData, setCostingTableData] = useState<any>();
  const [costingTableDataLoader, setCostingTableDataLoader] =
    useState<boolean>(false);
  const [sessionGetInto, setSessionGetInt] = useState<any>([]);
  const [contentText, setContentText] = React.useState<any>("");
  const [iconText, setIconText] = React.useState<any>("");
  const [categoryCopy, setCategoryCopy] = useState<any>();
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [paginationProduct, setPaginationProduct] = useState<any>();
  const [openStatisticsModel, setOpenStatisticsModel] = React.useState(false);
  const [permissionCosting,costingPermissionCosting] = React.useState();
  const [statisticsData, setStatisticsData] = React.useState<any>([]);

  const openStatusContent = useBaseModal();

  const history = useHistory();

  useEffect(() => {
    API.get(
      "/customer/costing_conversion/",
      { get_conversion_factor: true, module: "costing_create" },
      0
    ).then((res: any) => {
      sessionStorage.setItem(
        "costingtable",
        res.data[0]?.current_currency
      );
      sessionStorage.setItem(
        "actionkey_costingtable",
        res.data[0]?.is_manual ? "mannual" : "auto"
      )
      if (res.data[0]?.is_manual) {
        sessionStorage.setItem(
          "currencyrate_costingtable_mannual",
          res.data[0]?.conversion_factor
        )
      } else {
        sessionStorage.setItem(
          "currencyrate_costingtable",
          (1 / res.data[0]?.conversion_factor).toString()
        )
      }
    });
  }, [count]);

  useEffect(()=>{
    API.get("/customer/module_permission/",{top_vault_id:top_vault,module:"Costing-View"},0).then((res:any)=>{
      costingPermissionCosting(res.data);
    })
  },[top_vault]);

  useEffect(() => {
    setCostingTableDataLoader(true);
    API.get(
      "/xcpep/scenario_part_table/",
      {
        parent: vault_id,
        get_children: true,
        scenario:scenarioIds,
        category_filter: category,
        general_filter: assemblyPart,
        calculator_type: calculatorType,
        top_vault:top_vault
      },
      0
    ).then((res: any) => {
      setCostingTableData(res?.data?.[0]);
      setAncestorSetFromAPI(res?.data?.[0]?.data?.[0]?.ancestors);
      sessionStorage.setItem(
        "hierarchyCostingSelection",
        JSON.stringify([vault_id])
      );
      setSessionGetInt([vault_id]);
      setCostingTableDataLoader(false);
    });
  }, [apiCallMain, projectId, scenarioIds, count]);

  useEffect(()=>{

  API.get(`/cost/costing_create_ui/`, { get_costing_scenario: top_vault, start_scenario: inside_scenario_id, series: scenarioIds }, 0)
    .then((res: AxiosResponse) => {
      setPaginationProduct(res.data);
    })
    .catch((err: AxiosError) => {
      console.log(err, "error");
    });

}, [projectId, scenarioIds, count]);

  useEffect(() => {
    if (costingTableData != undefined) {
      setCostingTableDataLoader(true);
      API.get(
        "/xcpep/scenario_part_table/",
        { parent: vault_id, get_children: true, scenario:scenarioIds , top_vault:top_vault},
        0
      ).then((res: any) => {
        setCostingTableData(res?.data?.[0]);
        setAncestorSetFromAPI(res?.data?.[0]?.data?.[0]?.ancestors);
        setCostingTableDataLoader(false);
      });
    }
  }, [reloadCostingData]);

  useEffect(() => {
    setSessionGetInt(sessionStorage.getItem("hierarchyCostingSelection"));
  }, []);

  const handleOpenHierarchy = (idPush: any) => {
    let a: any = sessionStorage.getItem("hierarchyCostingSelection");
    let val: any = JSON.parse(a);
    if (val?.includes(idPush)) {
      sessionStorage.setItem(
        "hierarchyCostingSelection",
        JSON.stringify(val?.filter((item: any) => item != idPush))
      );
      setSessionGetInt(val?.filter((item: any) => item != idPush));
    } else {
      sessionStorage.setItem(
        "hierarchyCostingSelection",
        JSON.stringify([...val, idPush])
      );
      setSessionGetInt([...val, idPush]);
    }
  };

  const handleChangePasteCheckbox = (vaultIdComing: any) => {
    if (pasteVaultNoList?.includes(vaultIdComing)) {
      setPasteVaultNoList(
        pasteVaultNoList?.filter((item: any) => item != vaultIdComing)
      );
    } else {
      setPasteVaultNoList((prev: any) => [...prev, vaultIdComing]);
    }
  };

  const loaderLoadingHeader = (type:string) => {
    let arrayLoopComponent:any = [];
    for (let i = 0; i < scenarioIds?.split(',')?.length+1; i++) {
      arrayLoopComponent.push(
        type == "Body" ? 
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
         :  <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              <Skeleton variant="text" />
            </TableCell>
      );
    }
    return arrayLoopComponent;
  };

  const loaderLoadingComponent = () => {
    let arrayLoopComponent = [];
    for (let i = 0; i < 20; i++) {
      arrayLoopComponent.push(
        <TableRow>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          {String(scenarioIds)?.includes(',') ? loaderLoadingHeader('Body')
          :
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>}
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
        </TableRow>
      );
    }
    return arrayLoopComponent;
  };

  const redirectPaginationPush = (type: any) => {
    if (type == "prev") {
      history.push( `/costingscenarioabbrlanding/${projectId}/${projectName}/${top_vault}/${initialScenarioId}/${
        paginationProduct &&
        paginationProduct?.[0]?.previous_index?.toString()
      }/${expandTable}/${inside_scenario_id}/${scenarioName}/${abbr}/${vault_id}`);
    } else {
      history.push( `/costingscenarioabbrlanding/${projectId}/${projectName}/${top_vault}/${initialScenarioId}/${
        paginationProduct &&
        paginationProduct?.[0]?.next_index?.toString()
      }/${expandTable}/${inside_scenario_id}/${scenarioName}/${abbr}/${vault_id}`
      );
    }
  };

  const getPath = (x: any, y: any, width: any, height: any) => {
    return `M${x},${y + height}C${x + width / 3},${y + height} ${x + width / 2},${y + height / 3}
        ${x + width / 2}, ${y}
        C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${y + height} ${x + width}, ${y + height}
        Z`;
  };

  const TriangleBar = (props: any) => {
    const { fill, x, y, width, height } = props;

    return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
  };

  const handleStatisticsClose = () => {
    setOpenStatisticsModel(false);
    setStatisticsData([]);
  }

  return (
    <>
      {openStatusContent.isOpen && (
        <DialogComponent
          isOpen={openStatusContent.isOpen}
          onClose={openStatusContent.close}
          content={contentText}
          type={iconText}
        />
      )}
      <Dialog
        open={openStatisticsModel}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        sx={{
          '.MuiPaper-root.MuiDialog-paper': {
            maxWidth: '95vw',
            borderRadius: '10px',
          }
        }}
      >
        <DialogContent sx={{ padding: "0rem", width: '95vw' }}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem", position: 'sticky', top: 0, zIndex: 2, backgroundColor: 'white' }}>
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Statistics ( {abbr} )
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={handleStatisticsClose}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
            }}
          />
          <Box
            sx={{
              padding: "1rem",
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
              height: '80vh',
            }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3"
          >

            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
              <Typography sx={{ margin: '1rem', textAlign: 'center', fontWeight: 'bold' }}>Cost</Typography>
              <Box sx={{ margin: '0rem', width: '90vw', height: '55vh', padding: '1rem 1rem' }}>
                <ResponsiveContainer>
                  <BarChart
                    width={500}
                    height={300}
                    data={statisticsData}
                    margin={{
                      top: 20,
                      right: 10,
                      left: 25,
                      bottom: 55,
                    }}
                  >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" interval={0} angle={statisticsData.length > 4 ? -25 : 0} textAnchor={statisticsData.length > 4 ? 'end' : 'middle'} />
                    <YAxis>
                      <Label
                        style={{
                          textAnchor: "middle",
                          fontSize: "100%",
                          fontWeight: "600",
                        }}
                        angle={270}
                        dx={-28}
                        value={`Cost ( ${sessionStorage.getItem("costingtable")} )`}
                      />
                    </YAxis>
                    <Bar dataKey="cost" fill="#8884d8" shape={<TriangleBar />} label={{ position: 'top' }}>
                      {statisticsData?.map((entry: any, index: any) => (
                        <Cell key={`cell-${index}`} fill={colors[index % 20]} />
                      ))}
                    </Bar>
                  </BarChart>
                </ResponsiveContainer>
              </Box>
            </div>
          </Box>
        </DialogContent>
      </Dialog>

      <Table>
        <TableHead
          sx={{
            backgroundColor: "primary.light",
            position: "sticky",
            top: 0,
            zIndex: 1,
          }}
        >
          <TableRow>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              Part No.
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              Part Name
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              Category
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              Net Wt.(g)
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              Qty.
            </TableCell>
          { costingTableDataLoader ? 
          String(scenarioIds)?.includes(',') ? 
            loaderLoadingHeader('Header') :
            <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
              textAlign: "right",
            }}
          >
            <Skeleton variant="text" />
          </TableCell> :
          costingTableData && Object.keys(costingTableData?.data?.[0]?.vault_details)?.map((item: any, index: number) => {
            return (
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              {index == 1 && (
              <LoadingButton
                sx={{ p: 0, minWidth:'2rem' }}
                disabled={
                  !(
                    paginationProduct &&
                    paginationProduct?.[0]?.previous
                  )
                }
                onClick={() => redirectPaginationPush("prev")}
                title="Previous Vehicle"
              >
                <ChevronLeftIcon />
              </LoadingButton>)}
              {item} 
              {index == (costingTableData && Object.keys(costingTableData?.data?.[0]?.vault_details)?.length - 1)  && (
                <LoadingButton
                  sx={{ p: 0, minWidth:'2rem' }}
                  disabled={
                    !(
                      paginationProduct &&
                      paginationProduct?.[0]?.next
                    )
                  }
                  onClick={() => redirectPaginationPush("next")}
                  title="Next Vehicle"
                >
                  <ChevronRightIcon />
                </LoadingButton>)}
            </TableCell>
             )})
             }
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              Action
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {costingTableDataLoader
            ? loaderLoadingComponent()
            : costingTableData &&
              costingTableData?.data?.map((item: any, index: number) => {
                return (
                  <CostingScenerioSystemInsideBodyRows
                    costingTableData={item}
                    handleOpenHierarchy={handleOpenHierarchy}
                    sessionGetInto={sessionGetInto}
                    costingTableDataParent={sessionGetInto}
                    setIconText={setIconText}
                    setContentText={setContentText}
                    openStatusContent={openStatusContent}
                    categoryCopy={categoryCopy}
                    setCategoryCopy={setCategoryCopy}
                    handleChangePasteCheckbox={handleChangePasteCheckbox}
                    pasteVaultNoList={pasteVaultNoList}
                    copyVaultCategory={copyVaultCategory}
                    setCopyVaultCategory={setCopyVaultCategory}
                    setCopyVaultNo={setCopyVaultNo}
                    showCheckboxCopyClicked={showCheckboxCopyClicked}
                    setShowCheckboxCopyClicked={setShowCheckboxCopyClicked}
                    hoveredIndex={hoveredIndex}
                    setHoveredIndex={setHoveredIndex}
                    reloadCostingData={reloadCostingData}
                    setReloadCostingData={setReloadCostingData}
                    permissionCosting={permissionCosting}
                    setStatisticsData={setStatisticsData}
                    setOpenStatisticsModel={setOpenStatisticsModel}
                  />
                );
              })}
        </TableBody>
      </Table>
    </>
  );
};

export default CostingScenerioSystemInsideBody;
