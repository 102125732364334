import React, { useEffect } from "react";
import useFetch from "../../Core/CustomHooks/useFetch";
import landingPageService from "../../Redux/Services/landingPage.service";
import LetSuspense from "../../Core/CustomHooks/LetSuspense";
import BouncingLoader from "../../ui-reusable-component/BouncingLoader";
import LandingPageBodyRows from "./LandingPageBodyRows";
import { string } from "yup";
import axios from "axios";
import { API } from "../../api-services";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";
import nopermis from "../../Assets/images/landng_no_permission.png";
import { Typography } from "@mui/material";
import { AUTH } from "../../Redux/Services/auth.service";



// import LandingPageStyle from './LandingPageStyle';
interface IAppProps { }

export interface Itop_vault_details {
  original_image: string;
  thumbnail: null;
  top_vault_id: number;
  top_vault_name: string;
}

export interface ILandingImagesGet {
  project_description: string;
  project_id: number;
  project_name: string;
  top_vault_details: Itop_vault_details[];
}

function App(props: IAppProps) {
  const [projectCount, setProjectCount] = React.useState<any>();
  const [dataPaginate, setDataPaginate] = React.useState<any>([]);
  const [calculationData, setCalculationData] = React.useState<any>([])
  const [apiCallCount, setApiCallCount] = React.useState<number>(0)
  const [allApiUrl, setAllApiUrl] = React.useState<any>([]);
  const [isLoading, setIsLoading] = React.useState<any>(false);
  const [isLoadingProjectCount, setIsLoadingCount] = React.useState<any>(false);
  const [calcLoading, setCalcLoading] = React.useState<any>(false)


  const getData = () => {
    setIsLoadingCount(true)
    setIsLoading(true)
    setCalcLoading(true)
    API.get("/customer/dashboard/", { get_project_count: true }, 0).then((res: any) => {
      setIsLoadingCount(false)
      setProjectCount(res.data?.["project_count"]);
    })
    API.get(`/customer/dashboard/`, { page_no: 1 }, 0).then(
      (res: any) => {
        setIsLoading(false)
        setDataPaginate(res.data);
      }
    ).catch(() => {
      setIsLoading(false)
    })


  }
  const getCalculationData = () => {
    API.get("/customer/dashboard/", AUTH?.user_category === "External" ? { calculation: true} : { calculation: true ,  mode:true}, 0).then((res: any) => {
      setCalculationData(res.data);
      setCalcLoading(false)
    }).catch((err: any) => {
      setCalcLoading(false)
    })
  }




  useEffect(() => {
    getData();
    getCalculationData()
  }, [apiCallCount]);
  const getCalcData = (item: any) => {
    let filteredObj = {};
    if (item.project_id && calculationData) {
      calculationData.filter((itm: any) => {
        if (itm.project_id === item.project_id) {
          filteredObj = itm
        }
      })
    }
    return filteredObj;
  }

  // const fetchdata = async () => {
  // if(allApiUrl?.length === projectCount-1){
  // //     try {
  // //       const res = await Promise.all([allApiUrl]).then((res:any)=>{
  // //         const data = res.map((res:any) => res.data);
  // //         console.log("fghjbnkm",data.flat())
  // //       })

  // // //  setLandingImagesGet((prev:any)=>[...prev,...data.flat()]);
  // //     } catch {
  // //       throw Error("Promise failed");
  // //     }
  // for(let i=2;i<=Math.round(projectCount/2)-1;i++){
  //     API.get(`/customer/dashboard/`,{page_no:i},0).then((res:any)=>{
  //         console.log("RESSSSSSSS",res.data)
  //         // setDataPaginate((prev:any)=>[...prev,res.data])
  //     })
  // }

  // }
  //   };

  //   useEffect(()=>{
  //     if(Number.isInteger(projectCount)){
  //      fetchdata();
  //     }
  //   },[projectCount])

  // console.log(projectCount, "projectCountprojectCount")

 
  

  return (!isLoadingProjectCount ?
    <>
      {!projectCount && projectCount == 0 ?
        <>
          <Box sx={{
            textAlign: "center",
            fontSize: " 12px",
            margin: '0 auto',
            padding: '1rem',
            borderRadius: '10px',
            background: '#ffffff9e',
            width: 'fit-content',
            boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px',
          }}
          >
            <img src={nopermis} style={{ width: '55vw' }} />
            <br />
            <Typography sx={{ fontSize: '3rem' }}>I'm sorry,</Typography>
            <Typography>it seems like you do not have access to any project. Please contact ASI representative for assistance. <br />Thank you for your understanding. </Typography>
          </Box>
        </>
        :
        <>
          {!isLoading ? (
            [...dataPaginate].map((item: any, index: number) => {
              return (
                <Box sx={{
                  boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px', backgroundColor: 'rgba(255, 255, 255, 0.75)', margin: '0.2rem 2rem 2rem', padding: '0.5rem 0rem', borderRadius: '10px',
                  // background: 'linear-gradient(90deg, rgba(255,255,255,1) 0%, rgba(231,243,255,1) 100%)'
                }} >
                  <LandingPageBodyRows item={item} index={index} calculationData={getCalcData(item) && getCalcData(item)} calcLoading={calcLoading} setApiCallCount={setApiCallCount} />
                </Box>
              );
            })
          ) : (
            <>
              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                width: '97%',
                margin: '0 auto 2rem'

              }}>
                <Box sx={{ width: "100%", marginLeft: "auto", marginRight: "auto", backgroundColor: '#007fff14', borderRadius: '5px', height: { sm: '30rem', md: 'auto', lg: 'auto', xl: 'auto' }, padding: '2rem 1rem' }}>
                  <Skeleton animation="pulse" sx={{ height: "2rem", width: { sm: '100%', md: '20rem', lg: '20rem', xl: '20rem' } }} />
                  <Skeleton animation="pulse" sx={{ height: "2rem", width: { sm: '100%', md: '20rem', lg: '20rem', xl: '20rem' } }} />
                  <Skeleton animation="pulse" sx={{ height: "2rem", width: { sm: '100%', md: '20rem', lg: '20rem', xl: '20rem' } }} />
                  <Skeleton animation="pulse" sx={{ height: "2rem", width: { sm: '100%', md: '20rem', lg: '20rem', xl: '20rem' } }} />
                  <Skeleton animation="pulse" sx={{ height: "2rem", width: { sm: '100%', md: '20rem', lg: '20rem', xl: '20rem' } }} />
                  <Skeleton animation="pulse" sx={{ height: "2rem", width: { sm: '100%', md: '20rem', lg: '20rem', xl: '20rem' } }} />
                </Box>
              </Box>
              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                width: '97%',
                margin: '0 auto 2rem'

              }}>
                <Box sx={{ width: "100%", marginLeft: "auto", marginRight: "auto", backgroundColor: '#007fff14', borderRadius: '5px', height: { sm: '30rem', md: 'auto', lg: 'auto', xl: 'auto' }, padding: '2rem 1rem' }}>
                  <Skeleton animation="pulse" sx={{ height: "2rem", width: { sm: '100%', md: '20rem', lg: '20rem', xl: '20rem' } }} />
                  <Skeleton animation="pulse" sx={{ height: "2rem", width: { sm: '100%', md: '20rem', lg: '20rem', xl: '20rem' } }} />
                  <Skeleton animation="pulse" sx={{ height: "2rem", width: { sm: '100%', md: '20rem', lg: '20rem', xl: '20rem' } }} />
                  <Skeleton animation="pulse" sx={{ height: "2rem", width: { sm: '100%', md: '20rem', lg: '20rem', xl: '20rem' } }} />
                  <Skeleton animation="pulse" sx={{ height: "2rem", width: { sm: '100%', md: '20rem', lg: '20rem', xl: '20rem' } }} />
                  <Skeleton animation="pulse" sx={{ height: "2rem", width: { sm: '100%', md: '20rem', lg: '20rem', xl: '20rem' } }} />
                </Box>
              </Box>
              <Box sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'space-around',
                width: '97%',
                margin: '0 auto 2rem'

              }}>
                <Box sx={{ width: "100%", marginLeft: "auto", marginRight: "auto", backgroundColor: '#007fff14', borderRadius: '5px', height: { sm: '30rem', md: 'auto', lg: 'auto', xl: 'auto' }, padding: '2rem 1rem' }}>
                  <Skeleton animation="pulse" sx={{ height: "2rem", width: { sm: '100%', md: '20rem', lg: '20rem', xl: '20rem' } }} />
                  <Skeleton animation="pulse" sx={{ height: "2rem", width: { sm: '100%', md: '20rem', lg: '20rem', xl: '20rem' } }} />
                  <Skeleton animation="pulse" sx={{ height: "2rem", width: { sm: '100%', md: '20rem', lg: '20rem', xl: '20rem' } }} />
                  <Skeleton animation="pulse" sx={{ height: "2rem", width: { sm: '100%', md: '20rem', lg: '20rem', xl: '20rem' } }} />
                  <Skeleton animation="pulse" sx={{ height: "2rem", width: { sm: '100%', md: '20rem', lg: '20rem', xl: '20rem' } }} />
                  <Skeleton animation="pulse" sx={{ height: "2rem", width: { sm: '100%', md: '20rem', lg: '20rem', xl: '20rem' } }} />
                </Box>
              </Box>
            </>
          )}
        </>
      }

    </> :
    (
      <>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          width: '97%',
          margin: '0 auto 2rem'

        }}>
          <Box sx={{ width: "100%", marginLeft: "auto", marginRight: "auto", backgroundColor: '#007fff14', borderRadius: '5px', height: { sm: '30rem', md: 'auto', lg: 'auto', xl: 'auto' }, padding: '2rem 1rem' }}>
            <Skeleton animation="pulse" sx={{ height: "2rem", width: { sm: '100%', md: '20rem', lg: '20rem', xl: '20rem' } }} />
            <Skeleton animation="pulse" sx={{ height: "2rem", width: { sm: '100%', md: '20rem', lg: '20rem', xl: '20rem' } }} />
            <Skeleton animation="pulse" sx={{ height: "2rem", width: { sm: '100%', md: '20rem', lg: '20rem', xl: '20rem' } }} />
            <Skeleton animation="pulse" sx={{ height: "2rem", width: { sm: '100%', md: '20rem', lg: '20rem', xl: '20rem' } }} />
            <Skeleton animation="pulse" sx={{ height: "2rem", width: { sm: '100%', md: '20rem', lg: '20rem', xl: '20rem' } }} />
            <Skeleton animation="pulse" sx={{ height: "2rem", width: { sm: '100%', md: '20rem', lg: '20rem', xl: '20rem' } }} />
          </Box>
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          width: '97%',
          margin: '0 auto 2rem'

        }}>
          <Box sx={{ width: "100%", marginLeft: "auto", marginRight: "auto", backgroundColor: '#007fff14', borderRadius: '5px', height: { sm: '30rem', md: 'auto', lg: 'auto', xl: 'auto' }, padding: '2rem 1rem' }}>
            <Skeleton animation="pulse" sx={{ height: "2rem", width: { sm: '100%', md: '20rem', lg: '20rem', xl: '20rem' } }} />
            <Skeleton animation="pulse" sx={{ height: "2rem", width: { sm: '100%', md: '20rem', lg: '20rem', xl: '20rem' } }} />
            <Skeleton animation="pulse" sx={{ height: "2rem", width: { sm: '100%', md: '20rem', lg: '20rem', xl: '20rem' } }} />
            <Skeleton animation="pulse" sx={{ height: "2rem", width: { sm: '100%', md: '20rem', lg: '20rem', xl: '20rem' } }} />
            <Skeleton animation="pulse" sx={{ height: "2rem", width: { sm: '100%', md: '20rem', lg: '20rem', xl: '20rem' } }} />
            <Skeleton animation="pulse" sx={{ height: "2rem", width: { sm: '100%', md: '20rem', lg: '20rem', xl: '20rem' } }} />
          </Box>
        </Box>
        <Box sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-around',
          width: '97%',
          margin: '0 auto 2rem'

        }}>
          <Box sx={{ width: "100%", marginLeft: "auto", marginRight: "auto", backgroundColor: '#007fff14', borderRadius: '5px', height: { sm: '30rem', md: 'auto', lg: 'auto', xl: 'auto' }, padding: '2rem 1rem' }}>
            <Skeleton animation="pulse" sx={{ height: "2rem", width: { sm: '100%', md: '20rem', lg: '20rem', xl: '20rem' } }} />
            <Skeleton animation="pulse" sx={{ height: "2rem", width: { sm: '100%', md: '20rem', lg: '20rem', xl: '20rem' } }} />
            <Skeleton animation="pulse" sx={{ height: "2rem", width: { sm: '100%', md: '20rem', lg: '20rem', xl: '20rem' } }} />
            <Skeleton animation="pulse" sx={{ height: "2rem", width: { sm: '100%', md: '20rem', lg: '20rem', xl: '20rem' } }} />
            <Skeleton animation="pulse" sx={{ height: "2rem", width: { sm: '100%', md: '20rem', lg: '20rem', xl: '20rem' } }} />
            <Skeleton animation="pulse" sx={{ height: "2rem", width: { sm: '100%', md: '20rem', lg: '20rem', xl: '20rem' } }} />
          </Box>
        </Box>
      </>
    )
  );
}
export default App;
