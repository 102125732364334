import {
	Box,
	Divider,
	FormControl,
	InputLabel,
	Modal,
	Skeleton,
	TextField,
	Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';

import CancelIcon from '@mui/icons-material/Cancel';
import { LoadingButton } from '@mui/lab';
import { API } from '../../../api-services';
import { useHistory, useParams } from 'react-router-dom';
import { useBaseModal } from '../../SearchPage/useBaseModal';
import ErrorModalFilesMail from '../../ProjectMailModule/ErrorModalFilesMail';
import CopyMoveHeirarchyBOM from './CopyMoveHeirarchyBOM';
const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '52vw',
	bgcolor: 'background.paper',
	borderRadius: '10px',
	p: 1,
};

interface MovePartModalProps {
	onCloseModal: any;
	isOpen: any;
	getData: any;

	setParentsCounter: any;
	SucessAndMovepart: any;
}

const MovePartModal = (props: MovePartModalProps) => {
	const {
		onCloseModal,
		isOpen,
		getData,
		setParentsCounter,
		SucessAndMovepart,
	} = props;

	const {
		projectId,
		topVault,
		vault,
		leafNode,
		treeabbr,
		scenarioId,
		projectName,
		workstation,
		isLeaf,
	} = useParams<any>();
	const [quantity, setQuantity] = useState<any>(1);

	const [Loader, setLoader] = useState<any>(false);
	const [ErrorModalFileMsg, setErrorModalFileMsg] = React.useState<any>();
	const [checkboxvalue, setcheckboxvalue] = useState<any>([]);
	const [CheckboxData, setCheckboxData] = useState<any>();
	const ErrorModalFiles = useBaseModal();
	const [HierarchyData, setHierarchyData] = React.useState<any>([]);
	const [CopyInfoList, setCopyInfoList] = React.useState<any>({});

	let CopyId = sessionStorage?.getItem('moveId');
	const history = useHistory<any>();
	// @ts-ignore
	let matchData = JSON?.parse(CopyId);
	const handleChange = (e: any, type: any) => {
		if (type == 'quantity') {
			setQuantity(e?.target?.value);
		}
	};

	useEffect(() => {
		API.get(`xcpep/vault_copy_info/`, {
			copied_count: true,
			vault: getData?.id,
		})
			.then((res: any) => {
				setCopyInfoList(res?.data);
			})
			.catch((err: any) => {
				console.log('Server error');
			});
	}, [getData]);
	useEffect(() => {
		setLoader(true);
		API.get('/xcpep/bom_create_hirearchy/', { top_vault: topVault }, 0)
			.then((res: any) => {
				setLoader(false);
				setHierarchyData(res?.data?.data);
			})
			.catch((err: any) => {
				setLoader(false);
				console.log('Server EEror');
			});
	}, [getData]);

	const onSelectCallback = (data: any) => {
		if (checkboxvalue?.includes(data?.id)) {
			setCheckboxData({});
		} else {
			API.get('/xcpep/vault_copy_info/', {
				root_id: data?.id,
				source_id: +getData?.id,
			})
				.then((res: any) => {
					setCheckboxData(data);
					setcheckboxvalue([data?.id]);
				})
				.catch((err: any) => {
					setcheckboxvalue([]);
					const { data } = err.response;
					console.log(data, 'datadata');
					ErrorModalFiles.open();
					setErrorModalFileMsg(data);
					console.log('Server Error');
				});
		}
	};

	const Movehandler = () => {
		{
			setLoader(true);
			API.post(
				`/xcpep/hierarchy_operations/`,
				{
					node_id: getData?.id,
					parent_id: checkboxvalue[0],
					quantity: +quantity,
					type: 'move',
				},
				{},
				0
			)
				.then((res: any) => {
					onCloseModal();
					setParentsCounter((prev: any) => prev + 1);
					// sessionStorage.setItem("newvault", res?.data?.id)
					// history.push(`/bomentry/${projectId}/${projectName}/${topVault}/${res?.data?.id}/${treeabbr}/${workstation}/${isLeaf}`)
					// sessionStorage.setItem("BOMCreateNode", JSON.stringify(res?.data?.ancestors))
					sessionStorage.removeItem('TragetIds');
					sessionStorage.removeItem('moveId');
					SucessAndMovepart();
					setLoader(false);
				})
				.catch((err: any) => {
					setLoader(false);
					const { data } = err.response;
					console.log(data, 'datadata');
					ErrorModalFiles.open();
					setErrorModalFileMsg(data);
					console.log('Server Error');
				});
		}
	};

	return (
		<div>
			<Modal
				open={isOpen}
				onClose={onCloseModal}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'>
				<Box sx={style}>
					<ErrorModalFilesMail
						isOpen={ErrorModalFiles.isOpen}
						onCloseModal={ErrorModalFiles.close}
						ErrorMsg={ErrorModalFileMsg}
					/>
					<Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
						<Typography
							style={{
								fontSize: '1rem',
								fontWeight: '600',
								width: '100%',
								textAlign: 'center',
								marginRight: '-2rem',
							}}>
							Part Cut & Move
						</Typography>
						<CancelIcon
							titleAccess='Close'
							sx={{ cursor: 'pointer', margin: '0.5rem' }}
							onClick={onCloseModal}
						/>
					</Box>
					<Divider
						sx={{
							borderColor: 'primary.light',
							margin: '0 1rem',
							marginBottom: '1rem',
						}}
					/>

					<Box
						sx={{
							padding: '0 1rem 1rem',
							display: 'flex',
							flexDirection: 'row',
							gap: '1rem',
						}}>
						{Loader ? (
							<Box sx={{ width: '100%' }}>
								<Skeleton />
								<Skeleton />
								<Skeleton />
								<Skeleton />
								<Skeleton />
								<Skeleton />
								<Skeleton />
								<Skeleton />
								<Skeleton />
								<Skeleton />	
							</Box>
						) : (
							<>
								<Box sx={{width:'100%',borderRight:'1px solid',borderColor:'primary.light',paddingRight:'0.5rem' }}>
									<Typography
										sx={{
											fontSize: '1rem',
											fontWeight: '500',
											width: '100%',
											textAlign: 'center',
											marginBottom: '1rem',
											textDecoration: 'underline',
										}}>
										Move Information
									</Typography>
									<Box>
										<Box sx={{ display: 'flex', alignItems: 'center' }}>
											<Typography
												sx={{
													fontSize: '1rem',
													width: '10rem',
													fontWeight: '500',
												}}>
												Copy Part Count
											</Typography>
											<Typography sx={{ fontSize: '1rem' }}>
												: {CopyInfoList?.copied_count} Parts
											</Typography>
										</Box>
										<Box sx={{ display: 'flex', alignItems: 'center' }}>
											<Typography
												sx={{
													fontSize: '1rem',
													width: '10rem',
													fontWeight: '500',
												}}>
												Source Part
											</Typography>
											<Typography sx={{ fontSize: '1rem' }}>
												: {getData?.name}
											</Typography>
										</Box>
									</Box>
									<Box
										sx={{
											display: 'flex',
											alignItems: 'center',
											columnGap: '0.5rem',
											marginTop: '2rem',
										}}>
										<FormControl>
											<InputLabel
												shrink
												sx={{ color: 'primary.main', marginLeft: '-1.2rem' }}>
												Enter Quantity
											</InputLabel>
											<TextField
												id='standard-number'
												value={quantity}
												onChange={(e: any) => handleChange(e, 'quantity')}
												type='number'
												inputProps={{ min: 1 }}
												InputLabelProps={{
													shrink: true,
												}}
												variant='standard'
												sx={{
													marginTop: '0.5rem',
													'.MuiInputBase-root.MuiInput-root': {
														fontSize: '1rem !important',
													},
													'& .MuiInput-underline:before': {
														borderBottomColor: 'primary.main',
													},
													'& .MuiInput-underline:after': {
														borderBottomColor: 'primary.main',
													},
												}}
											/>
										</FormControl>
										<LoadingButton
											size='small'
											variant='contained'
											sx={{ cursor: 'pointer' }}
											disabled={checkboxvalue?.length > 0 ? false : true}
											onClick={() => Movehandler()}
											loading={Loader}>
											Move
										</LoadingButton>
									</Box>
								</Box>
								<Box
									sx={{ height: '80vh',width:'100%' }}
									adl-scrollbar='true'
									adl-scrollbar-width='0.3'>
									<Typography
										sx={{
											fontSize: '1rem',
											fontWeight: '500',
											width: '100%',
											textAlign: 'center',
											marginBottom: '1rem',
											textDecoration: 'underline',
										}}>
										Select Destination Part
									</Typography>
									<CopyMoveHeirarchyBOM
										HierarchyData={HierarchyData}
										onSelectCallback={onSelectCallback}
										checkboxvalue={checkboxvalue}
										setcheckboxvalue={setcheckboxvalue}
									/>
								</Box>
							</>
						)}
					</Box>
				</Box>
			</Modal>
		</div>
	);
};
export default MovePartModal;
