import React, { createContext, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Badge, FormControl, Menu, MenuItem, Typography } from "@mui/material";
import styles from "./BOMEntryLandingPage.module.scss";
import BOMEntryCombinePage from "./BOMEntryCombinePage";
import bomConfigIcon from "../../../Assets/images/configuration.svg";
import InsightsIcon from "@mui/icons-material/Insights";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { BOMEntryDrawer } from "./BOMEntryDrawer";
import { API } from "../../../api-services";
import { AxiosError, AxiosResponse } from "axios";
import UploadIcon from "@mui/icons-material/Upload";
import { useHistory } from "react-router-dom";
import deletefill from "../../../Assets/images/deletefill.svg";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import DownloadIcon from '@mui/icons-material/Download';
import { LoadingButton } from "@mui/lab";
import { useDocumentTitle } from "../../../ui-reusable-component/useDocumentTitle";
export const userPermissionAction = createContext<any>(null)
interface IBOMEntryLandingPageProps { }

type Anchor = "top" | "left" | "bottom" | "right";

const BOMEntryLandingPage: React.FC<IBOMEntryLandingPageProps> = (props) => {
  const { projectId, topVault, vault, leafNode, treeabbr, scenarioId, projectName, workstation, isLeaf, copyStatus } =
    useRouteParams<any>();
  useDocumentTitle("BOM Create Entry");
  const history = useHistory();
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [productList, setProductList] = React.useState<any>([]);
  const [selectedProduct, setSelectedProduct] = useState<any>([]);
  const [analysisData, setAnalysisData] = useState<any>([]);

  const [recyclebinCount, setrecyclebinCount] = useState<any>();
  const [recyclebinCounter, setrecyclebinCounter] = useState<any>(0);
  const [ParentsCounter, setParentsCounter] = useState<any>(0);
  const [loadUpload, setLoadUpload] = useState<boolean>(false);
  const [anchorElDownload, setAnchorElDownload] = React.useState<null | HTMLElement>(null);
  const openDownload = Boolean(anchorElDownload);
  const [anchorElUpload, setAnchorElUpload] = React.useState<null | HTMLElement>(null);
  const openUpload = Boolean(anchorElUpload);
  const [PermissionActionList, setPermissionActionList] = React.useState<any>(null)
  const [uploadType, setUploadType] = useState<string>('');

  // For Redirection to BOM Configuration Page
  const [bomCategory, setBomCategory] = useState<any>();

  const inputElRefExcel = useRef<any>();

  React.useEffect(() => {
    API.get("/auth/calibration_user_permission/", { action: true, bom_create: true, top_vault: topVault }).then((res: any) => {
      setPermissionActionList(res?.data)
    }).catch((err: any) => {
      console.log("server Error")
    })
  }, [topVault])
  let UserAction = PermissionActionList && PermissionActionList?.action;


  // console.log(PermissionActionList && PermissionActionList?.action, "PermissionActionListPermissionActionList")
  useEffect(() => {
    API.get(`/auth/mui_trash_view/`, { project_id: projectId, trash_count: true }).then((res: any) => {
      setrecyclebinCount(res?.data?.count)
    }).catch((err: any) => {
      console.log("Sever Error")
    })

  }, [recyclebinCounter]);

  useEffect(() => {
    API.get(`/xcpep/bom_hirearchy_function/`, { current_top_vault: topVault, module: "BOM" }).then((res: any) => {
      setAnalysisData(res?.data)
    }).catch((err: any) => {
      console.log("Sever Error")
    })

  }, [projectId, topVault]);

  const backButtonHandler: any = () => {
    API.get("/xcpep/bom_hirearchy_function/", {
      current_top_vault: topVault,
      module: "BOM",

    }, 0).then((res: any) => {
      console.log("sklnflk", res.data)
      history.push(`/BOMCreate/${projectId}/${projectName}/${topVault}/${res.data}/true`)
    }).catch((err: any) => {

    })
    // history.goBack();
  };

  const handleClickDownload = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElDownload(event.currentTarget);
  };
  const handleCloseDownloadMenu = () => {
    setAnchorElDownload(null);
  };

  const handleClickUpload = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElUpload(event.currentTarget);
  };
  const handleCloseUploadMenu = () => {
    setAnchorElUpload(null);
  };

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }
        API.get(
          `/xcpep/product_list_new/`,
          { project: `${projectId}`, module: "BOM-Create" },
          0
        )
          .then((res: AxiosResponse) => {
            setProductList(res?.data);
            setSelectedProduct([topVault]);
          })
          .catch((err: AxiosError) => {
            console.log(err, "error");
          });

        setSelectedProduct([topVault]);
        setState({ ...state, [anchor]: open });
      };

  const list = (anchor: Anchor) => (
    <BOMEntryDrawer
      anchorComing={anchor}
      toggleDrawer={toggleDrawer}
      productList={productList}
      setProductList={setProductList}
      selectedProduct={selectedProduct}
      setSelectedProduct={setSelectedProduct}
    />
  );

  React.useEffect(() => {
    API.get("/auth/calibration_user_permission/", { action: true, bom_create: true, top_vault: topVault }).then((res: any) => {
      setPermissionActionList(res?.data)
    }).catch((err: any) => {
      console.log("server Error")
    })
  }, [topVault])
  const RecycleBinHanlder = () => {
    window.open(`/#/recyclebin/${projectId}`);
  };

  const UploadExcel = (e: any) => {
    setLoadUpload(true);
    const data = new FormData();
    data.append('file', e?.target?.files[0]);
    data.append('upload_type', uploadType);
    data.append('top_vault', topVault);
    API.post(`/xcpep/reverse_upload_bom/`, data, {}, 0).then((res: any) => {
      setUploadType('');
      setLoadUpload(false);
      window.location.reload();
    }).catch((err: any) => {
      console.log(err, "error")
    })
    handleCloseUploadMenu();
  }

  const handleDownloadTemplate = (type: string) => {
    if (type == 'table') {
      window.open(
        API.apiBase +
        `xcpep/bom_reverse_template/?top_vault=${topVault}&type=${type}`,
        "_blank",
        "noopener noreferrer");
      handleCloseDownloadMenu();
    }
    else {
      window.open(
        API.apiBase +
        `xcpep/bom_reverse_template/?top_vault=${topVault}&type=${type}`,
        "_blank",
        "noopener noreferrer");
      handleCloseDownloadMenu();
    }
  }

  const handleUploadTemplate = (type: string) => {
    if (type == 'table') {
      setUploadType('table')
      return inputElRefExcel.current.click()
    }
    else {
      setUploadType('parameter')
      return inputElRefExcel.current.click()
    }
  }

  

  

  // console.log(bomCategory, "bomCategory");


  return (
    <div>
      <Box className={styles.BomEntryContainer}>
        <Box className={styles.titleSection}>
          <Box className={styles.titleSectionLeft}>
            <IconButton sx={{ cursor: "pointer" }} onClick={backButtonHandler}>
              <ArrowBackIcon color="primary" titleAccess="Go Back" />
            </IconButton>
            <Typography
              sx={{
                fontSize: "1rem",
                whiteSpace: "nowrap",
                display: "inline-block",
              }}
            >
              BOM Entry ( {projectName} )
            </Typography>
          </Box>
          <Box className={styles.titleSectionMiddel}></Box>

          {
            <Box className={styles.titleSectionRight}>
              {
                (copyStatus == false || copyStatus == "false") &&

                <><Button
                  id="basic-button"
                  aria-controls={openDownload ? 'basic-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={openDownload ? 'true' : undefined}
                  onClick={handleClickDownload}
                  style={{ maxWidth: '1.5rem', minWidth: '1.5rem' }}

                >
                  <DownloadIcon sx={{ fontSize: '2rem', cursor: 'pointer' }} titleAccess="Download Reverse Upload Template" />
                </Button><Menu
                  id="basic-menu"
                  anchorEl={anchorElDownload}
                  open={openDownload}
                  onClose={handleCloseDownloadMenu}
                  MenuListProps={{
                    'aria-labelledby': 'basic-button',
                  }}
                >
                    <MenuItem onClick={() => handleDownloadTemplate('table')}>Table Template</MenuItem>
                    <MenuItem onClick={() => handleDownloadTemplate('parameter')}>Parameter Template</MenuItem>
                  </Menu>

                  {UserAction && UserAction?.includes("U") &&
                    <LoadingButton
                      id="basic-button"
                      aria-controls={openUpload ? 'basic-menu' : undefined}
                      aria-haspopup="true"
                      aria-expanded={openUpload ? 'true' : undefined}
                      onClick={handleClickUpload}
                      style={{ maxWidth: '1.5rem', minWidth: '1.5rem' }}
                      loading={loadUpload}
                    >

                      <UploadIcon sx={{ fontSize: '2rem', cursor: 'pointer' }} titleAccess="Reverse Upload" />

                    </LoadingButton>
                  }



                  <Menu
                    id="basic-menu"
                    anchorEl={anchorElUpload}
                    open={openUpload}
                    onClose={handleCloseUploadMenu}
                    MenuListProps={{
                      'aria-labelledby': 'basic-button',
                    }}
                  >
                    <MenuItem onClick={() => handleUploadTemplate('table')}>Table Template</MenuItem>
                    <MenuItem onClick={() => handleUploadTemplate('parameter')}>Parameter Template</MenuItem>
                  </Menu></>
              }
              <input
                accept=".xlsx, .xls"
                id="Excel Upload"
                type="file"
                style={{ marginTop: '0.5rem', display: 'none' }}
                onChange={(e: any) => UploadExcel(e)}
                ref={inputElRefExcel}
              />
              <InsightsIcon sx={{ fontSize: '2rem', cursor: 'pointer' }} titleAccess="BOM Analysis" onClick={() => window.open(`/#/analysis/${projectId}/${analysisData.toString()}`)} />

              {
                (PermissionActionList && PermissionActionList?.configuration) &&

                <img src={bomConfigIcon} style={{ width: "1.5rem", cursor: 'pointer' }} onClick={() => {
                  window.open(`/#/bomconfiguration/${projectId}/${projectName}/${topVault}/${bomCategory}/0/0/0`)
                }} />
              }

              <Box sx={{ width: "2.5rem", textAlign: "center", paddingRight: '1rem' }}>

                {(UserAction && UserAction?.includes("U") || UserAction && UserAction?.includes("C")) && <>

                  {recyclebinCount > 0 ? (
                    <Badge color="error" badgeContent={recyclebinCount}>
                      <img
                        title="Recycle bin"
                        className={styles.internalIcons}
                        src={deletefill}
                        style={{
                          width: "auto",
                          height: "1.5rem",
                          margin: "0px 3px 0 0",
                          verticalAlign: "middle",
                          cursor: "pointer",
                        }}
                        onClick={() => RecycleBinHanlder()}
                      />
                    </Badge>
                  ) : (
                    <DeleteOutlineIcon
                      titleAccess="Recycle bin "
                      sx={{ fontSize: "2rem" }}
                    />
                  )}
                </>
                }
              </Box>
            </Box>
          }
        </Box>

        <Box sx={{ display: "flex", alignItems: "flex-start", }}>
          <Box className={styles.expandIconBox}>
            <div className={styles.iconSec}>
              {(["left"] as const).map((anchor) => (
                <React.Fragment key={anchor}>
                  <IconButton
                    size="medium"
                    sx={{
                      backgroundColor: "primary.light",
                      color: "primary.main",
                    }}
                    onClick={toggleDrawer(anchor, true)}
                  >
                    <AddIcon sx={{ fontSize: "1.7rem" }} />
                  </IconButton>
                  <Drawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                    className={styles.drawerContainer}
                    PaperProps={{
                      sx: {
                        backgroundColor: "aliceblue",
                      },
                    }}
                  >
                    <Box
                      sx={{ padding: "1rem" }}
                      adl-scrollbar="true"
                      adl-scrollbar-width="0.3"
                    >
                      <Typography
                        style={{ width: "100%", textAlign: "center" }}
                      >
                        Selection Options
                      </Typography>
                      <Divider sx={{ margin: "1rem" }} />
                      {list(anchor)}
                    </Box>
                  </Drawer>
                  <Typography
                    style={{ cursor: "pointer" }}
                    className={styles.sideTitle}
                    onClick={toggleDrawer(anchor, true)}
                  >
                    Selection Options
                  </Typography>
                </React.Fragment>
              ))}
            </div>
          </Box>
          <Box>
            {/* sx={{ width: {lg:'95.5%',xl:"96.5%"}, height: { lg: "86vh", xl: "86vh" } }} */}
            {/* adl-scrollbar="true"
          adl-scrollbar-width="0.3" */}
            <userPermissionAction.Provider value={PermissionActionList}>
              <BOMEntryCombinePage setrecyclebinCounter={setrecyclebinCounter} setBomCategory={setBomCategory} bomCategory={bomCategory} />
            </userPermissionAction.Provider>
          </Box>
        </Box>

      </Box>
    </div>
  );
};
export default BOMEntryLandingPage;





