import * as React from "react";
import Box from "@mui/material/Box";

import { Backdrop, ButtonProps, Divider, Modal, Skeleton, TablePagination, Tooltip, Typography } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import CancelIcon from "@mui/icons-material/Cancel";
import { DataGrid, GridCsvExportMenuItem, GridCsvExportOptions, GridFooterContainer, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExportContainer } from "@mui/x-data-grid";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { API } from "../../api-services";
import useWindowDimensions from "../../ui-reusable-component/useWindowDimensions";
import { useHistory } from "react-router-dom";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  height: "fit-content",
  width: { lg: '50vw', xl: "40vw" },
  maxWidth: "80vw",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};
const csvOptions: GridCsvExportOptions = {
  fileName: "Table In CSV",
  delimiter: ",",
  utf8WithBom: true,
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer style={{ height: "1.5rem", marginBottom: "0.5rem" }}>
    <span style={{ position: "absolute", right: "0rem" }}>
      {/* <GridToolbarColumnsButton /> */}
      {/* <GridToolbarDensitySelector /> */}
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);

interface IFeatureInfoProps {
  isOpen: any;
  onCloseModal: any;
  item?: any;
}

function CostCategoryInfoModal(props: IFeatureInfoProps) {
  const history = useHistory();
  const { isOpen, onCloseModal, item } = props;
  const { projectId, type, categoryId } = useRouteParams<any>();
  const { height, width } = useWindowDimensions();
  const [infoData, setInfoData] = React.useState<any>([]);
  const [isLoading, setIsLoading] = React.useState<any>(false);
  const [pageNumber, setPageNumber] = React.useState<any>(0);

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPageNumber(newPage);
  };

  const CustomToolbarIncludingPagination = ({ count }: any) => (
    <GridToolbarContainer style={{ height: "2rem" }}>
      <div
        style={{
          position: "absolute",
          right: "0rem",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <TablePagination
          rowsPerPageOptions={[100]}
          component="div"
          rowsPerPage={100}
          page={pageNumber}
          count={count}
          onPageChange={handleChangePage}
          // You can adjust other TablePagination props here
          sx={{ display: "flex", justifyContent: "flex-start" }}
        />
        <GridToolbarColumnsButton />
        <GridFooterContainer></GridFooterContainer>

        <CustomExportButton />
      </div>
    </GridToolbarContainer>
  );
  //   const getSelectedName = () => {
  //     switch (type) {
  //       case 0:
  //         return "Feature";
  //       case 1:
  //         return "Specification";
  //       case 2:
  //         return "Information";
  //       default:
  //         return "Feature";
  //     }
  //   };
  
  const redirectToCostingPage = (item:any) => {
       window.open(
      `/#/createcostingpart/${projectId}/${item.vault__top_vault__project__name}/${item.vault__top_vault__name}/${item.vault__top_vault}/${
        item?.vault__id
      }/${
        item?.vault__abbreviation ?? "none"
      }/0/0/0/0/0/0/0/0/0/true`
    );
  };

  const getInfoData = () => {
    setIsLoading(true);
    API.get(
      `/config/get_config_usage_info/${item?.id}/`,
      {
      },
      0
    )
      .then((res: any) => {
        setIsLoading(false);
        let rows: any = [];
        const dataGridDataGenerator: any = res.data?.map((ideaItem: any, index: any) => {
          let objShared: any = {
            id: index + 1,
            ...ideaItem,
          };
          rows.push(objShared);
        });
        setInfoData(rows);
      })
      .catch((err: any) => {
        setIsLoading(false);
      });
  };
  React.useEffect(() => {
    getInfoData();
  }, []);

  var Columns: any = [
    // {
    //   field: "id", headerName: 'Name', hide: true,
    //   minWidth: 50,
    //   flex: 1,
    // },
    {
      field: "vault__top_vault__name",
      headerName: "Product Name",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "vault__name",
      headerName: "Name",
      minWidth: 160,
      flex: 1,
      renderCell: (cellValues: any) => {
        return (
          <>
            <Tooltip
              sx={{ bacgroundColor: "primary.light", color: "primary.main" }}
              title={cellValues?.row?.vault__name}>
              <p
                style={{cursor: 'pointer' }}
                >
                {cellValues?.row?.vault__name}
              </p>
            </Tooltip>
          </>
        );
      },
    },
    {
      field: "vault__part_no",
      headerName: "Part No",
      minWidth: 160,
      flex: 1,
      renderCell: (cellValues: any) => {
        return (
          <>
            <Box
              onClick={()=>redirectToCostingPage(cellValues?.row)}
              sx={{ textDecoration:'underline', color: "primary.main" }}>
              <p
                style={{cursor: 'pointer' }}
                >
                {cellValues?.row?.vault__part_no}
              </p>
            </Box>
          </>
        );
      },
    },
  ];

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}>
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}>
              Details info
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider />
          <Box
            sx={{ height: "80vh", width: "100%" }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.4">
            {!isLoading && infoData ? (
              <DataGrid
                headerHeight={48}
                rowHeight={36}
                rows={
                  pageNumber * 100 > infoData?.length
                    ? infoData?.slice(
                        pageNumber * 100,
                        (pageNumber + 1) * 100
                      )
                    : infoData?.slice(
                        pageNumber * 100,
                        infoData.length
                      )
                }
                columns={Columns}
                density="compact"
                // autoHeight={true}
                components={{
                  Toolbar: () => {
                    return infoData?.length > 100 ? (
                      <CustomToolbarIncludingPagination
                        count={infoData?.length}
                      />
                    ) : (
                      <CustomToolbar />
                    );
                  },
                }}
                hideFooter={true}
                hideFooterPagination={true}
                pageSize={100}
                disableSelectionOnClick
                pagination
                sx={{
                  "&.MuiDataGrid-root": {
                    border: "none",
                  },
                  "&.MuiDataGrid-root .MuiDataGrid-cell": {
                    borderBottomColor: "primary.light",
                  },
                  "& ::-webkit-scrollbar": {
                    width: "0.3rem",
                    height: "0.3rem",
                  },
                  "& ::-webkit-scrollbar-thumb": {
                    // background: $thumb-color;
                    backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                    borderRadius: "10px",
                  },
                  "& ::- webkit-scrollbar-thumb: hover ": {
                    // width: "4px",
                    backgroundColor: "#045DE9 !important",
                  },
                  "::-webkit-scrollbar-track": {
                    backgroundColor: "#e1e1f3 !important",
                  },
                }}
              />
            ) : (
              <Box>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </Box>
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
export default CostCategoryInfoModal;
