import React, { useEffect, useState } from "react";
import useWindowDimensions from "../../utlis/useWindowDimensions";
import createPlotlyComponent from "react-plotly.js/factory";
import Plotly from "plotly.js";
import { API } from "../../api-services";
import LetSuspense from "../../LetSuspense";
import BouncingLoader from "../../ui-reusable-component/BouncingLoader";
import { Box, Skeleton } from "@mui/material";

interface ParetoGraphActivityAnalysisI {
    usertypeChange: any;
}

const ParetoGraphActivityAnalysis: React.FC<ParetoGraphActivityAnalysisI> = (props) => {
    const { usertypeChange } = props;
    const { height, width } = useWindowDimensions();
    const Plot = createPlotlyComponent(Plotly);

    const [cumsum, setCumsum] = useState<any>();
    const [xAxis, SetXaxis] = useState<any>();
    const [yAxis, SetYaxis] = useState<any>();
    const [loader, setLoader] = useState<boolean>(true);

    const fetchData = async (
        type?: string,
    ) => {
        setLoader(true);
        try {
            const result = await API.get(`activity/activity_pareto_count/`, {
                user_type: type,
            }, 0)
            setCumsum(result.data.cumsum);
            const count = result?.data.response.map((counts: any) => {
                return counts.content_type__app_label;
            });
            SetXaxis(count);
            const login_count = result.data.response.map((counts: any) => {
                return counts.module_count;
            });
            SetYaxis(login_count);
            setLoader(false);
        } catch (error) {
            setLoader(false);
            console.error(error);
        }
    };

    useEffect(() => {
        fetchData(usertypeChange);
    }, [usertypeChange]);

    return (
        // <LetSuspense
        // loadingPlaceholder={BouncingLoader}
        // condition=

        <div data-testid="stacked_chart">

            <div>
                {!loader ?
                    (
                        <Plot
                            style={{ width: "100%", height: "100%" }}
                            data={[
                                {
                                    name: "Module",
                                    type: "bar",
                                    x: xAxis,
                                    y: yAxis,
                                    width: 0.4,
                                    marker: {
                                        color: usertypeChange == "Internal" ? "#007fff" : "#a3c182",
                                    },
                                    // @ts-ignore
                                    font: {
                                        size: width <= 1400 && height <= 800 ? 8 : 11,
                                    },
                                },
                                {
                                    name: "Pareto",
                                    type: "scatter",
                                    x: xAxis,
                                    y: cumsum,
                                    yaxis: "y2",
                                    marker: {
                                        color: "red",
                                    },
                                    // @ts-ignore
                                    font: {
                                        size: width <= 1400 && height <= 800 ? 8 : 11,
                                    },
                                },
                            ]}
                            layout={{
                                yaxis2: {
                                    side: "right",
                                    range: [0, 110],
                                    overlaying: "y",
                                    ticksuffix: "%",
                                    showgrid: false,
                                    // autorange: true,
                                },
                                height: height <= 800 ? 230 : 310,
                                // width: width <= 1400 ? 450 : 600,
                                margin: {
                                    l: width <= 1400 ? 40 : 60,
                                    r: width <= 1400 ? 60 : 80,
                                    b: width <= 1400 ? 20 : 50,
                                    t: 0,
                                },
                                showlegend: false,
                                font: {
                                    // family: 'Courier New, monospace',
                                    size: width <= 1400 && height <= 800 ? 10 : 11,
                                    color: "#007fff",
                                },
                                xaxis: {
                                    showgrid: false,
                                    autorange: true,
                                    rangemode: 'tozero',
                                },
                                yaxis: {
                                    showgrid: false,
                                    autorange: true,
                                    rangemode: 'tozero',
                                }
                            }}
                            config={{
                                responsive: true,
                                modeBarButtonsToRemove: [
                                    "zoom2d",
                                    "pan2d",
                                    "select2d",
                                    "lasso2d",
                                    "zoomIn2d",
                                    "zoomOut2d",
                                    "autoScale2d",
                                    "resetScale2d",
                                    "hoverClosestGl2d",
                                    "hoverClosestPie",
                                    "toggleHover",
                                    "resetViews",
                                    "sendDataToCloud",
                                    // @ts-ignore
                                    "resetViewMapbox",
                                    "hoverClosestCartesian",
                                    "hoverCompareCartesian",
                                    "toggleSpikelines",
                                    "toImage",
                                ],
                                displaylogo: false,
                            }}
                        />
                    )
                    :
                    <Box sx={{ width: '100%', height: '30vh' }}>
                        <Skeleton variant="rectangular" sx={{ height: '100%' }} />
                    </Box>
                }
            </div>

        </div>

        // </LetSuspense>
    );
};

// ----------------------------------------------------------------------------------

export default ParetoGraphActivityAnalysis;