import { Box, ButtonProps, Divider, Modal, Skeleton, Tooltip, Typography } from '@mui/material';
import { DataGrid, GridCsvExportMenuItem, GridCsvExportOptions, GridToolbarContainer, GridToolbarExportContainer } from '@mui/x-data-grid';
import * as React from 'react';
import useWindowDimensions from '../../ui-reusable-component/useWindowDimensions'; import CancelIcon from '@mui/icons-material/Cancel';
import { API } from '../../api-services';
import { useRouteParams } from '../../ui-reusable-component/useRouteParams';
import { useStage } from 'spritespin';

export interface IInfoParameterDetailsProps {
  onCloseModal: any;
  isOpen: any;
  item: any;

}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: "60vw",
  maxWidth: '80vw',
  // height: '80vh',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  p: 1,
};
const csvOptions: GridCsvExportOptions = { fileName: 'Table In CSV', delimiter: ",", utf8WithBom: true };

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer style={{ height: '1.5rem', marginBottom: '0.5rem' }}>
    <span style={{ position: 'absolute', right: '0rem' }}>
      {/* <GridToolbarColumnsButton /> */}
      {/* <GridToolbarDensitySelector /> */}
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
)


export function InfoParameterDetails(props: IInfoParameterDetailsProps) {
  const { height, width } = useWindowDimensions();
  const { projectId } = useRouteParams<any>();
  const [infoData, setInfoData] = React.useState<any>([]);
  const [loader, setLoader] = React.useState<any>(false);

  const { onCloseModal,
    isOpen,
    item } = props
  const getInfoData = () => {
    setLoader(true)
    API.get("config/idea_parameter_info/", {
      parameter_id: item,
      project: projectId
    }, 0).then((res: any) => {
      // setInfoData(res.data)
      let rows: any = [];
      setLoader(false)
      const dataGridDataGenerator: any = res.data?.map((ideaItem: any, index: any) => {
        let objShared: any = {
          id: index + 1,
          ...ideaItem
        };
        rows.push(objShared);
      });
      setInfoData(rows)
      setLoader(false)
    }).catch((err: any) => {
      setLoader(false)
    })
  }

  React.useEffect(() => {
    setLoader(true)
    getInfoData();
  },[item])
    var Columns: any = [
        // {
        //   field: "id", headerName: 'Name', hide: true,
        //   minWidth: 50,
        //   flex: 1,
        // },
        {
          field: 'topvault_name',
          headerName: 'Product Name',
          width: width > 1400 ? 250 : 220,
        },
    
        {
          field: 'part_name',
          headerName: 'Part Name',
          minWidth: width > 1400 ? 300 : 220,
          flex: 1,
          renderCell: (cellValues: any) => {
            return (<>
                <Tooltip sx={{ bacgroundColor: 'primary.light', color: 'primary.main' }} title={cellValues?.row?.part_name} >
                  <p>
                    {cellValues?.row?.part_name}
                  </p>
                </Tooltip>
              </>)
          }
        },
    
        {
          field: 'part_no',
          headerName: 'Part No',
          minWidth: width > 1400 ? 300 : 220,
          flex: 1,
          rendeCell: (cellvalues: any) => {
            return (<>    
              <Tooltip sx={{ bacgroundColor: 'primary.light', color: 'primary.main' }} title={cellvalues?.row?.part_no} >
                <p>
                  {cellvalues?.row?.part_no}
                </p>
              </Tooltip>
            </>)
          }
    
        },
    
      ]
  return (
    <div>


      <Modal
        open={isOpen}
        onClose={onCloseModal}
      >
        <Box sx={style}>
          <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: '-2rem'
              }}
            >
              Idea Information Table
            </Typography>
            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ height: '65vh', width: '100%' }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3">
            {!loader ? <>
              {infoData &&
                <DataGrid
                  headerHeight={48}
                  rowHeight={36}
                  rows={infoData && infoData}
                  columns={Columns}
                  density="compact"
                  // autoHeight={true}
                  components={{ Toolbar: () => { return CustomToolbar(); } }}
                  hideFooter={true}
                  hideFooterPagination={true}
                  sx={{
                    "&.MuiDataGrid-root .MuiDataGrid-cell": {
                      borderBottomColor: "primary.light",
                    },
                    "& ::-webkit-scrollbar": {
                      width: "0.3rem",
                      height: "0.3rem",
                    },
                    "& ::-webkit-scrollbar-thumb": {
                      // background: $thumb-color;
                      backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                      borderRadius: "10px",
                    },
                    "& ::- webkit-scrollbar-thumb: hover ": {
                      // width: "4px",
                      backgroundColor: "#045DE9 !important",
                    },
                    "::-webkit-scrollbar-track": {
                      backgroundColor: "#e1e1f3 !important",
                    },
                  }}
                />
              }
            </> : 
            <Box sx={{ padding: '0 1rem' }}>
              <Skeleton animation="wave" variant="rectangular" width="100%" sx={{ marginBottom: '1rem' }} />
              <Skeleton animation="wave" variant="rectangular" width="100%" sx={{ marginBottom: '1rem' }} />
              <Skeleton animation="wave" variant="rectangular" width="100%" sx={{ marginBottom: '1rem' }} />
              <Skeleton animation="wave" variant="rectangular" width="100%" sx={{ marginBottom: '1rem' }} />
              <Skeleton animation="wave" variant="rectangular" width="100%" sx={{ marginBottom: '1rem' }} />
              <Skeleton animation="wave" variant="rectangular" width="100%" sx={{ marginBottom: '1rem' }} />
              <Skeleton animation="wave" variant="rectangular" width="100%" sx={{ marginBottom: '1rem' }} />
              <Skeleton animation="wave" variant="rectangular" width="100%" sx={{ marginBottom: '1rem' }} />
              <Skeleton animation="wave" variant="rectangular" width="100%" sx={{ marginBottom: '1rem' }} />
              <Skeleton animation="wave" variant="rectangular" width="100%" sx={{ marginBottom: '1rem' }} />
              <Skeleton animation="wave" variant="rectangular" width="100%" sx={{ marginBottom: '1rem' }} />
              <Skeleton animation="wave" variant="rectangular" width="100%" sx={{ marginBottom: '1rem' }} />
              <Skeleton animation="wave" variant="rectangular" width="100%" sx={{ marginBottom: '1rem' }} />
              <Skeleton animation="wave" variant="rectangular" width="100%" sx={{ marginBottom: '1rem' }} />
              <Skeleton animation="wave" variant="rectangular" width="100%" sx={{ marginBottom: '1rem' }} />
              <Skeleton animation="wave" variant="rectangular" width="100%" sx={{ marginBottom: '1rem' }} />
              <Skeleton animation="wave" variant="rectangular" width="100%" sx={{ marginBottom: '1rem' }} />
              <Skeleton animation="wave" variant="rectangular" width="100%" sx={{ marginBottom: '1rem' }} />
              <Skeleton animation="wave" variant="rectangular" width="100%" sx={{ marginBottom: '1rem' }} />
              <Skeleton animation="wave" variant="rectangular" width="100%" sx={{ marginBottom: '1rem' }} />
            </Box>}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
