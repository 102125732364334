import * as React from 'react';
import Modal from '@mui/material/Modal';
import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Divider, TextField, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import moment from 'moment';


export interface ICompanionEIRInfoModelProps {
    onCloseModal: any;
    isOpen: any;
    companionInfo: any;
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { lg: '25vw', xl: '15vw' },
    height: 'fit-content',
    bgcolor: 'background.paper',
    borderRadius: '10px',
    p: 1,
};


export function CompanionEIRInfoModel(props: ICompanionEIRInfoModelProps) {
    const { onCloseModal, isOpen, companionInfo } = props;

    return (
        <div>
            <Modal
                open={isOpen}
                onClose={onCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
                        <Typography
                            style={{
                                fontSize: "1rem",
                                fontWeight: "600",
                                width: "100%",
                                textAlign: "center",
                                marginRight: '-2rem'
                            }}
                        >
                            Companion Info
                        </Typography>

                        <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
                    </Box>
                    <Divider
                        sx={{
                            borderColor: "primary.light",
                            margin: "0 1rem",
                            marginBottom: "1rem",
                        }}
                    />
                    <Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography sx={{ color: 'primay.main', fontSize: '1rem', width: "6.5rem" }}>From </Typography>
                            <Typography sx={{ color: 'primay.main', fontSize: '1rem', }}>: {moment(companionInfo?.from_session).format('DD-MM-YYYY')}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography sx={{ color: 'primay.main', fontSize: '1rem', width: "6.5rem" }}>To </Typography>
                            <Typography sx={{ color: 'primay.main', fontSize: '1rem', }}>: {moment(companionInfo?.to_session).format('DD-MM-YYYY')}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography sx={{ color: 'primay.main', fontSize: '1rem', width: "6.5rem" }}>Session ID </Typography>
                            <Typography sx={{ color: 'primay.main', fontSize: '1rem', }}>: {companionInfo?.session}</Typography>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </div >
    );
}
