import * as React from 'react';
import { TextField } from '@mui/material';
import { useParams } from 'react-router-dom';
import { userPermissionAction } from '../../../../BOMCreate/BOMEntry/BOMEntryLandingPage';
import { UserAction } from '../../IdeaGenerationViewLanding';
import { rejectedStatus } from '../../IdeaViewpage';
// import { userPermissionAction } from '../../../BOMCreate/BOMEntry/BOMEntryLandingPage';


export interface IIdeaTabDescriptionInputBoxTableProps {
    itmm: any;
    key1: any;
    dataBody: any;
    val: any;
    data: any;
    count: any;
    setDataForUpdate: any;
    target: any;
    AllData: any;
    setColorButton:any;
    setShowButton:any;
}

export default function IdeaTabDescriptionInputBoxTable (props: IIdeaTabDescriptionInputBoxTableProps) {
    const { itmm, key1, dataBody, val, data, count, setDataForUpdate, target, AllData,setColorButton,setShowButton } = props;
    const userAction = React.useContext<any>(UserAction);
    let UserActionParams = userAction && userAction?.action;
    const rejectedAction = React.useContext<any>(rejectedStatus);
  let rejectedStatusActionParams = rejectedAction && rejectedAction;
    const { copyStatus } = useParams<any>()
    const [value, setValue] = React.useState<any>("-")
    React.useEffect(() => {
        if (val) {
            setValue(itmm[key1])
        }else{
            setValue("-")
        }
    }, [val])



    const handleChange = (event: any) => {
        setShowButton(true)
        setColorButton('red')
        const { value } = event.target;
        setValue(value)
        AllData.filter((item: any, index: any) => index === target)[0][key1] =value? value:"";
        setDataForUpdate(AllData)
    }


  return (
    <TextField
            variant="standard"
            type="text"
            multiline
            rows={3}
            disabled={(!rejectedStatusActionParams && UserActionParams?.includes("U"))?false:true}
            // disabled={((copyStatus == false || copyStatus == "false") && (UserAction?.includes("U") || UserAction?.includes("C"))) ? false : true}
            onChange={handleChange}
            value={value}
            placeholder="Enter Value"
            sx={{

                ".MuiInputBase-root.MuiInput-root": {
                    fontSize: "12px",
                    backgroundColor:'rgba(0,0,0,0.04)',
                    padding:'0.2rem',
                },
                "& .MuiInput-underline:before": {
                    borderBottomColor: "transparent",
                },
                "& .MuiInput-underline:after": {
                    borderBottomColor: "primary.light",
                },
            }}

        />
  );
}
