import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import * as yup from "yup";
import { useFormik } from "formik";
import { Backdrop, Chip, Divider, Input, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import styles from "./AddRevisionModal.module.scss";
import { API } from "../../api-services";
import { LoadingButton } from "@mui/lab";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import CancelIcon from "@mui/icons-material/Cancel";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import RawMaterialS3Upload from "./RawMaterialS3Upload";
import { useRouteMatch } from "react-router-dom";
import Swal from "sweetalert2";
import successIcon from "../../Assets/images/success.png"

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45vw",
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 0,
};
const RevisionTypeList = [
  { id: 1, text: "Main Costing", value: 1 },
  { id: 2, text: "Scenario Costing", value: 2 },
];
const validationSchema = yup.object({
  description: yup.mixed(),
  material_cost: yup.mixed(),
  material_source: yup.mixed(),
  material_reference: yup.mixed(),
  scrap_cost: yup.mixed(),
  scrap_source: yup.mixed(),
  scrap_reference: yup.mixed(),
  density: yup.mixed(),
  unit: yup.mixed().required("Please enter unit"),
  currency_id: yup.mixed().required("Please enter currency"),
});

const useStyles = makeStyles((theme) => ({
  select: {
    "&.MuiInputBase-root-MuiInput-root-MuiSelect-root": {
      borderBottom: "1px solid red",
    },
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface IWatermarkModelProps {
  isOpen: any;
  onCloseModal: any;
  actions?: any;
  expanded?: any;
  getMaterialData?: any;
  categoryList?: any;
  onCloseParent?:any
}

function SubRevisionEditModalMove(props: IWatermarkModelProps) {
  const { isOpen, onCloseModal, actions, expanded, getMaterialData, categoryList,onCloseParent } = props;
  const { url } = useRouteMatch();
  const [category, selectedCategory] = React.useState<any>()
        // ----------------------------------------------

        const [countryList, setCountryList] = React.useState<any[]>([]);
        const [currencyCodes, setCurrencyCodes] = React.useState<string[]>([]);
        const [countryLoading, setCountryLoading] = React.useState<boolean>(false);
        const [selectedCurrencyCodes, setSelectedCurrencyCodes] =
          React.useState<any>("");
          const [selectedCurrencyIds, setSelectedCurrencyIds] =
          React.useState<any>("");
        // ----------------------------------------------
  const handleChangeCategory = (ed: any) => {
    const { value } = ed.target;
    selectedCategory(value);
  };
  const [isLoadingsave, setIsLoadingSave] = React.useState<any>(false);
  const classes = useStyles();

  const [flag, setFlag] = React.useState<any>();
  const handleChange = (e: any) => {
    setFlag(e.currentTarget.files);
  };
  React.useEffect(() => {
    if (actions) {
      formik.setFieldValue("description", actions.description);
      formik.setFieldValue("material_cost", actions?.material_cost?.slice(1));
      formik.setFieldValue("material_source", actions.material_source);
      formik.setFieldValue("material_reference", actions?.material_reference);
      formik.setFieldValue("scrap_cost", actions.scrap_cost?.slice(1));
      formik.setFieldValue("scrap_source", actions.scrap_source);
      formik.setFieldValue("scrap_reference", actions.scrap_reference);
      formik.setFieldValue("density", actions.density);
    }
  }, [actions]);
  const formik = useFormik({
    initialValues: {
      description: "",
      material_cost: "",
      material_source: "",
      material_reference: "",
      scrap_cost: "",
      scrap_source: "",
      scrap_reference: "",
      density: "",
      unit: "",
      currency_id: "",
      currency_code: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsLoadingSave(true);
      values.currency_id = String(values.currency_id);
      const { currency_code, ...apiPayload } = values; // remove currency_code from apiPayload  as it is not needed in the api

      API.post(`api/db/move_cost_data/`, { ...values, target_commodity: category, id: url.includes("DB") ? actions.id : actions.purchase_item, boughtout_to_material: true }, {}, 0)
        .then((res: any) => {
          setIsLoadingSave(false);
          Swal.fire({
            html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />

      <br />

      <p style="color:#007fff;">Moved Successfully!</p>   

       </div>`,
          });
          getMaterialData(expanded);
          onCloseModal();
          onCloseParent()
        })
        .catch((err: any) => {
          setIsLoadingSave(false);
        });
    },
  });

  const getCountryData = () => {
    setCountryLoading(true);
    API.get("customer/currency_search/", { all_currencies: true, database:true }, 0)
      .then((res: any) => {
        const c_codes = res?.data.map((item: any) => item.currency_code);
        setCurrencyCodes(c_codes);
        setCountryList(res.data);
        setCountryLoading(false);
      })
      .catch((err: any) => {
        console.error("Error fetching currency data:", err);
        setCountryLoading(false);
      });
  };

  React.useEffect(() => {
    getCountryData();
  }, []);
  const flagMap = countryList?.reduce((acc, country) => {
    acc[country?.currency_code] = country?.flag;
    return acc;
  }, {});

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}>
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}>
              Bought-out to Raw Material move
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ padding: '0 2rem', marginBottom: '1rem' }}>
            <FormControl
              fullWidth
              variant="standard">
              <InputLabel sx={{ color: "primary.main" }} shrink id="demo-simple-select-label">Select category</InputLabel>
              <Select
                id="category"
                name="category"
                className={classes.select}
                labelId="demo-select-small"
                // id="demo-select-small"
                sx={{
                  "&:before": {
                    borderBottomColor: "primary.light",
                  },
                  "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                    fontSize: '1rem'
                  },
                  '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
                }}
                //@ts-ignore
                MenuProps={{
                  style: {
                    maxHeight: 400,
                    color: "007fff",
                  },
                }}
                onChange={handleChangeCategory}
                value={category}>
                {/* <option value={""} style={{ display: "none" }}></option> */}
                {categoryList &&
                  categoryList.map((item: any, index: any) => {
                    return (
                      <MenuItem
                        sx={{ color: "primary.main", fontSize: "1rem" }}
                        key={index}
                        value={item.category}>
                        {item.category}
                      </MenuItem>
                    );
                  })}
              </Select>
              {/* <div className={styles.errormsg}>{formik.touched.category && Boolean(formik.errors.category) && <p>{formik.errors.category}</p>}</div> */}
            </FormControl>
          </Box>
          {category && <Box sx={{ padding: "0 2rem 1rem", textAlign: "center" }}>
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ display: "flex", alignItems: "center", columnGap: "1rem", marginBottom: "0.5rem" }}>
                <FormControl
                  fullWidth
                  variant="standard">
                  <TextField
                    variant="standard"
                    label="Remarks"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": { borderBottomColor: "primary.light" },
                      "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="description"
                    name="description"
                    onChange={formik.handleChange}
                    value={formik.values.description}
                    multiline
                    error={formik.touched.description && Boolean(formik.errors.description)}
                    helperText={formik.touched.description && formik.errors.description}
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  variant="standard">
                  <TextField
                    variant="standard"
                    label="Density"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": { borderBottomColor: "primary.light" },
                      "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="density"
                    name="density"
                    onChange={formik.handleChange}
                    value={formik.values.density}
                    type="number"
                    error={formik.touched.density && Boolean(formik.errors.density)}
                    helperText={formik.touched.density && formik.errors.density}
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  variant="standard">
                  <TextField
                    variant="standard"
                    label="Raw material Cost"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": { borderBottomColor: "primary.light" },
                      "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="material_cost"
                    name="material_cost"
                    onChange={formik.handleChange}
                    value={formik.values.material_cost}
                    multiline
                    error={formik.touched.material_cost && Boolean(formik.errors.material_cost)}
                    helperText={formik.touched.material_cost && formik.errors.material_cost}
                  />
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", columnGap: "1rem", marginBottom: "0.5rem" }}>
                <FormControl
                  fullWidth
                  variant="standard">
                  <TextField
                    variant="standard"
                    label="Material Source"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": { borderBottomColor: "primary.light" },
                      "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="material_source"
                    name="material_source"
                    onChange={formik.handleChange}
                    value={formik.values.material_source}
                    multiline
                    error={formik.touched.material_source && Boolean(formik.errors.material_source)}
                    helperText={formik.touched.material_source && formik.errors.material_source}
                  />
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", columnGap: "1rem", marginBottom: "0.5rem" }}>
                <FormControl
                  fullWidth
                  variant="standard">
                  <TextField
                    variant="standard"
                    label="Material Reference"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": { borderBottomColor: "primary.light" },
                      "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="material_reference"
                    name="material_reference"
                    onChange={formik.handleChange}
                    value={formik.values.material_reference}
                    multiline
                    error={formik.touched.material_reference && Boolean(formik.errors.material_reference)}
                    helperText={formik.touched.material_reference && formik.errors.material_reference}
                  />
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", columnGap: "1rem", marginBottom: "0.5rem" }}>
                <FormControl
                  fullWidth
                  variant="standard">
                  <TextField
                    variant="standard"
                    label="Scrap Cost"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": { borderBottomColor: "primary.light" },
                      "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="scrap_cost"
                    name="scrap_cost"
                    onChange={formik.handleChange}
                    value={formik.values.scrap_cost}
                    multiline
                    error={formik.touched.scrap_cost && Boolean(formik.errors.scrap_cost)}
                    helperText={formik.touched.scrap_cost && formik.errors.scrap_cost}
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  variant="standard">
                  <TextField
                    variant="standard"
                    label="Scrap Source"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": { borderBottomColor: "primary.light" },
                      "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="scrap_source"
                    name="scrap_source"
                    onChange={formik.handleChange}
                    value={formik.values.scrap_source}
                    multiline
                    error={formik.touched.scrap_source && Boolean(formik.errors.scrap_source)}
                    helperText={formik.touched.scrap_source && formik.errors.scrap_source}
                  />
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", columnGap: "1rem", marginBottom: "0.5rem" }}>
                <FormControl
                  fullWidth
                  variant="standard">
                  <TextField
                    variant="standard"
                    label="Scrap Reference"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": { borderBottomColor: "primary.light" },
                      "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="scrap_reference"
                    name="scrap_reference"
                    onChange={formik.handleChange}
                    value={formik.values.scrap_reference}
                    multiline
                    error={formik.touched.scrap_reference && Boolean(formik.errors.scrap_reference)}
                    helperText={formik.touched.scrap_reference && formik.errors.scrap_reference}
                  />
                </FormControl>
                {/* <FormControl fullWidth>
                  <InputLabel
                    id="demo-simple-select-label-Activity"
                    sx={{
                      color: "primary.main",
                      fontSize: "0.87rem",
                      marginLeft: "-1rem",
                      position: "relative",
                      textAlign: "left",
                    }}>
                    Flag
                  </InputLabel>
                  <Input
                    type="file"
                    onChange={handleChange}
                  />
                </FormControl> */}
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "0.5rem",
                  marginBottom: "0.5rem",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "0.25rem",
                    width: "50%",
                  }}
                >
                  <FormControl
                    fullWidth
                    variant="standard"
                    sx={{ ".MuiFormLabel-asterisk": { color: "red" } }}
                  >
                    <InputLabel
                      id="demo-multiple-name-label"
                      sx={{ fontSize: "0.75rem" }}
                      style={{
                        color: "#007fff",
                        fontSize: "1.1rem",
                      }}
                    >
                      Currency
                    </InputLabel>
                    <Select
                      labelId="demo-multiple-name-label"
                      id="demo-multiple-name"
                      multiple={false}
                      value={selectedCurrencyCodes}
                      onChange={(event: any) => {
                        setSelectedCurrencyCodes(event.target.value);

                        const selectedCurIds = countryList
                          .filter(
                            (item: any) =>
                              event.target.value === item?.currency_code
                          )
                          .map((obj: any) => obj?.id);
                        

                        formik.setFieldValue("currency_id", String(selectedCurIds));
                        formik.setFieldValue("currency_code", String(event.target.value));
                      
                      }}
                      input={
                        <Input id="standard-input" placeholder="Currency" />
                      }
                      error={
                        formik.touched.currency_id &&
                        Boolean(formik.errors.currency_id)
                      }
                      //@ts-ignore
                      helperText={
                        formik.touched.currency_id && formik.errors.currency_id
                      }
                      sx={{
                        "& .MuiSelect-select": {
                          textAlign: "left",
                          fontSize: "1rem",
                          borderBottom: "1px solid #007fff",
                        },
                      }}
                    >
                      {currencyCodes.map((name: any, index: any) => (
                        <MenuItem key={index} value={name}>
                          {flagMap[name] && (
                            <img
                              src={`data:image/png;base64, ${flagMap[name]}`}
                              alt={`${name} flag`}
                              style={{
                                width: "1.5rem",
                                height: "1.5rem",
                                marginLeft: "1rem",
                                marginRight: "1rem",
                              }}
                            />
                          )}
                          {name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  {/* Add the '/' separator */}
                  <Typography
                    variant="body1"
                    sx={{
                      alignSelf: "center",
                      fontSize: "1.5rem",
                      marginTop: "1.85rem",
                    }}
                  >
                    /
                  </Typography>

                  <FormControl fullWidth variant="standard">
                    <TextField
                      variant="standard"
                      label="Unit"
                      InputProps={{
                        style: { color: "#007fff" },
                        inputProps: {
                          pattern: "^[^0-9]*$", // This regex pattern disallows any numerical input
                        },
                      }}
                      InputLabelProps={{
                        shrink: true,
                      }}
                      sx={{
                        "& .Mui-error": {
                          color: "#d32f2f",
                          borderBottomColor: "#d32f2f",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "primary.light",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "primary.main",
                        },
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "primary.main",
                        },
                        "& .MuiFormLabel-root.MuiInputLabel-root ": {
                          color: "primary.main",
                        },
                        ".MuiInputBase-root.MuiInput-root": {
                          fontSize: "1rem !important",
                        },
                      }}
                      id="unit"
                      name="unit"
                      onChange={(e)=> {
                        formik.handleChange(e);
                        // if (!/[0-9]/.test(e.target.value)) {
                         
                          
                        // }  
                      }}
                      value={formik.values.unit}
                      multiline
                      error={formik.touched.unit && Boolean(formik.errors.unit)}
                      helperText={formik.touched.unit && formik.errors.unit}
                    />
                  </FormControl>
                </Box>
              </Box>
              <Box sx={{ width: "100%", textAlign: "right" }}>
                <LoadingButton
                  size="small"
                  loading={isLoadingsave}
                  variant="contained"
                  type="submit"
                  sx={{
                    marginTop: "1rem",
                    "&:hover": {
                      transform: "Scale(1.05)",
                      transition: "transform 0.5s ease",
                    },
                    ".MuiCircularProgress-root": {
                      color: "primary.main",
                    },
                  }}>
                  Move
                </LoadingButton>
              </Box>
            </form>
          </Box>}
        </Box>
      </Modal>
    </div>
  );
}
export default SubRevisionEditModalMove;
