import { Box, Skeleton, Typography, styled } from '@mui/material';
import { MenuItem, Select, TableCell, TableRow, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import DeleteIcon from '@mui/icons-material/Delete';
import InfoIcon from '@mui/icons-material/Info';
import TableChartIcon from '@mui/icons-material/TableChart';
import VisibilityIcon from '@mui/icons-material/Visibility';
const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#f5f5f9',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));
interface FeatureConfigRowsProps {


  rowItems: any;
  sequence: any
  getData: any;
  keys: any;
  getDropDownData: any;
  updateLoader: any;

}

const FeatureTableRows = (props: FeatureConfigRowsProps) => {

  const { rowItems, sequence, getData, getDropDownData, keys, updateLoader } = props
  const [Sequence, setSequence] = useState<number>(parseInt(sequence && sequence))
  const [allData, setallData] = useState<any>(rowItems && rowItems)
  const [requiredData, setrequiredData] = useState<any>(rowItems && rowItems?.required)
  const [ids, setIds] = useState<any>()
  useEffect(() => {
    setallData(rowItems)
    setrequiredData(rowItems && rowItems?.required)
    setSequence(sequence && sequence)
  }, [rowItems])



  const Handlechange = (e: any, id: any) => {
    setSequence(e.target.value)
    getData(e.target.value, id)
    setIds(id)

  }

  const handleChangeRequried = (e: any, id: any) => {
    setrequiredData(e.target.value)

    getDropDownData(e.target.value, id)
    setIds(id)
  }



  return (
    <>
      <TableRow key={keys}>

        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>

          <TextField
            variant='standard'
            sx={{
              width: '4rem',
              ".MuiInputBase-root.MuiInput-root": {
                fontSize: '1rem !important'
              },
              '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
              '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
            }}
            type='number'
            onChange={(e: any) => Handlechange(e, allData?.id)}
            defaultValue={Sequence && Sequence}
          />
        </TableCell>
        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>{allData && allData?.parameter?.name !== "" ? allData && allData?.parameter?.name : "-"}</TableCell>
        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>{allData && allData?.parameter?.unit !== "" ? allData && allData?.parameter?.unit : "-"}</TableCell>
        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>{allData && allData?.parameter?.data_type !== "" ? allData && allData?.parameter?.data_type : "-"}</TableCell>
        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>{allData && allData?.parameter?.exceptions?.length > 0 ? allData && allData?.parameter?.exceptions?.toString(", ") : "-"}</TableCell>
        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', maxWidth: '10rem ' }}><Box sx={{ display: 'flex', flexWrap: 'wrap' }}>{allData && allData?.parameter?.allowed_values?.length > 0 ? allData && allData?.parameter?.allowed_values?.map((item: any, index: any) => { return (<span>{item},&nbsp;</span>) }) : "-"}</Box></TableCell>
        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', maxWidth: '10rem ' }}><Box sx={{ display: 'flex', flexWrap: 'wrap' }}>{allData && allData?.parameter?.not_allowed_values?.length > 0 ? allData && allData?.parameter?.not_allowed_values?.map((item: any, index: any) => {
          return (<>
            <span>{item},&nbsp;</span>
          </>)
        }) : "-"}
        </Box></TableCell>
        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>{allData && allData?.parameter?.default_value !== "" ? allData && allData?.parameter?.default_value : "-"}</TableCell>
        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>{allData && allData?.parameter?.info_doc !== null ?
          <VisibilityIcon sx={{ cursor: "pointer" }} onClick={() => window.open(allData && allData?.parameter?.info_doc, "_blank")} />
          : "-"}</TableCell>
        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>

          <Select
            variant='standard'
            id="demo-simple-select-filled"
            value={requiredData}
            onChange={(e: any) => handleChangeRequried(e, allData?.id)}
            sx={{
              width: { lg: '75%', xl: '75%' },
              "&:before": {
                borderBottomColor: "white",
              },
              '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
            }}
          >
            {/* @ts-ignore */}
            <MenuItem sx={{ fontSize: '1rem' }} value={true}>Yes</MenuItem>
            {/* @ts-ignore */}
            <MenuItem sx={{ fontSize: '1rem' }} value={false}>No</MenuItem>
          </Select>
        </TableCell>
      </TableRow>
    </>
  )
}
export default FeatureTableRows;