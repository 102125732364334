import * as React from 'react';
import { Box, IconButton, Menu, MenuItem, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DeleteIcon from '@mui/icons-material/Delete';
import CommentIcon from '@mui/icons-material/Comment';
import MenuIcon from '@mui/icons-material/Menu';

export interface ICostModelMenuItemProps {
	calculator: any;
	index: any;
	handleCommentEdit: any;
	handleDelete: any;
}

export default function CostModelMenuItem(props: ICostModelMenuItemProps) {
	const { calculator, index, handleCommentEdit, handleDelete } = props;

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		event.stopPropagation();
        event.preventDefault()
		setAnchorEl(event.currentTarget);
	};
	const handleClose = (event:any) => {
		event.stopPropagation();
		setAnchorEl(null);
	};

	return (
		<>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center',
					columnGap: '0.5rem',
					width: '3rem',
				}}>
				<Tooltip title='More Items'>
					<IconButton
						onClick={handleClick}
						size='small'
						sx={{ padding: '2px' }}
						aria-controls={open ? 'account-menu' : undefined}
						aria-haspopup='true'
						aria-expanded={open ? 'true' : undefined}>
						<MenuIcon sx={{ color: 'primary.main' }} />
					</IconButton>
				</Tooltip>
				<Menu
					anchorEl={anchorEl}
					id='account-menu'
					open={open}
					onClose={handleClose}
					onClick={handleClose}
					PaperProps={{
						elevation: 3,
						sx: {
							overflow: 'visible',
							// filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
							// boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px',
							// mt: 1.5,
							'& .MuiAvatar-root': {
								width: 32,
								height: 32,
								//   ml: -0.5,
								//   mr: 1,
							},
							'&::before': {
								content: '""',
								display: 'block',
								position: 'absolute',
								top: 0,
								right: 14,
								width: 10,
								height: 10,
								bgcolor: 'background.paper',
								transform: 'translateY(-50%) rotate(45deg)',
								zIndex: 0,
							},
						},
					}}
					transformOrigin={{ horizontal: 'right', vertical: 'top' }}
					anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
					<MenuItem
						title={calculator?.message != null ? calculator?.message : ' '}
						onClick={(e: any) => handleCommentEdit(e, calculator)}>
						<CommentIcon
							sx={{
								fontSize: '1.4rem',
								cursor: 'pointer',
								color: calculator?.message != null ? 'green' : '',
							}}
						/>
					</MenuItem>
					<MenuItem
						title='Delete'
						onClick={(e) => handleDelete(calculator?.calculator?.id, e)}>
						<DeleteIcon sx={{ fontSize: '1.4rem', cursor: 'pointer' }} />
					</MenuItem>
				</Menu>
			</Box>
		</>
	);
}
