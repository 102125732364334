import { Box, Chip, FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import * as React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { API } from "../../api-services";
import { debounce } from "lodash";
import { validationCheckCounter } from "../../Redux/Actions/compareopen.action";
import { useDispatch } from "react-redux";

export interface IParamsMultiselectProps {
  item: any;
  condition:any;
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export function CreateFeParamMultiselect(props: IParamsMultiselectProps) {
  const { item,condition } = props;
  const dispatch = useDispatch();
  const [selectedParams, setSelectedParams] = React.useState<any>([]);
  const [selectedAssembly, setSelectedAssembly] = React.useState<any>([]);
  const [selectedParts, setSelectedParts] = React.useState<any>([]);
  const [options, setOptions] = React.useState<any>([]);
  const [optionsAssembly, setOptionsAssembly] = React.useState<any>([]);
  const [optionsParts, setOptionsParts] = React.useState<any>([]);
  const ExactDebounce = React.useRef(
    debounce(async (value: any) => {
      await API.put(`/xcpep/feature_details/${item?.itemId}/`, { system: value }, {}, 0)
        .then((res: any) => {
          getOptionsUpdate("system");
        })
        .catch((err: any) => {});
    }, 1000)
  ).current;

  const ExactDebounceAssembly = React.useRef(
    debounce(async (value: any) => {
      await API.put(`/xcpep/feature_details/${item?.itemId}/`, { assembly: value }, {}, 0)
        .then((res: any) => {
          getOptionsUpdate("assambly");
        })
        .catch((err: any) => {});
    }, 1000)
  ).current;

  const ExactDebounceParts = React.useRef(
    debounce(async (value: any) => {
      await API.put(`/xcpep/feature_details/${item?.itemId}/`, { part: value }, {}, 0)
        .then((res: any) => {})
        .catch((err: any) => {});
    }, 1000)
  ).current;

  React.useEffect(() => {
    return () => {
      ExactDebounce.cancel(); // Cancel the debounced function
      ExactDebounceAssembly.cancel(); // Cancel the debounced function
      ExactDebounceParts.cancel(); // Cancel the debounced function
    };
  }, []);
  const handleSelectChangParams = (event: SelectChangeEvent<any>) => {
    const { value } = event.target;
    setSelectedParams(value);
    ExactDebounce(value);
  };
  const handleSelectChangParamsAssembly = (event: SelectChangeEvent<any>) => {
    const { value } = event.target;
    setSelectedAssembly(value);
    ExactDebounceAssembly(value);
  };
  const handleSelectChangParamsParts = (event: SelectChangeEvent<any>) => {
    const { value } = event.target;
    setSelectedParts(value);
    ExactDebounceParts(value);
  };
  React.useEffect(() => {
    if (item) {
      if (item?.type === "system") {
        setSelectedParams(item?.values);
      } else if (item?.type === "assambly") {
        setSelectedAssembly(item?.values);
      } else if (item?.type === "parts") {
        setSelectedParts(item?.values);
      }
    }
  }, [item]);

  const getOptions = () => {
    if (item?.productId) {
      API.get(
        "/xcpep/feature_costing/",
        {
          product: item?.productId,
          type: "system",
          system: selectedParams?.length > 0 ? selectedParams.toString() : undefined,
          assembly: selectedAssembly?.length > 0 ? selectedAssembly.toString() : undefined,
        },
        0
      )
        .then((res: any) => {
          if (item?.type === "system") {
            setOptions(res?.data?.subsystem);
          } else if (item?.type === "assambly") {
            setOptionsAssembly(res?.data?.assambly);
          } else if (item?.type === "parts") {
            setOptionsParts(res?.data?.parts);
          }
        })
        .catch((err: any) => {});
    }
  };
  const getOptionsUpdate = (type: any) => {
    if (item?.productId) {
      API.get(
        "/xcpep/feature_costing/",
        {
          product: item?.productId,
          type: "system",
          system: selectedParams?.length > 0 ? selectedParams.toString() : undefined,
          assembly: selectedAssembly?.length > 0 ? selectedAssembly.toString() : undefined,
        },
        0
      )
        .then((res: any) => {
          if (item?.type === "system") {
            setOptions(res?.data?.subsystem);
          } else if (item?.type === "assambly") {
            setOptionsAssembly(res?.data?.assambly);
          } else if (item?.type === "parts") {
            setOptionsParts(res?.data?.parts);
          }
        })
        .catch((err: any) => {});
    }
  };

  React.useEffect(() => {
    if (item) {
      getOptions();
    }
  }, []);

  return (
    <div>
      {item?.type === "system" && (
        <FormControl
          fullWidth
          variant="standard">
          {/* <InputLabel id="demo-simple-select-label">
                          Select roles
                        </InputLabel> */}
          {selectedParams && (
            <Select
            disabled={condition?.id?false:true}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedParams || ""}
              multiple
              multiline
              label="Select Assembly"
              placeholder="Select Assmbly"
              onChange={handleSelectChangParams}
              size="small"
              sx={{
                "&:before": {
                  borderBottomColor: "White",
                },
                ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
              }}
              renderValue={(selected) => (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 0.5,
                  }}>
                  {options
                    .filter((item: any) => selected?.includes(item?.id))
                    ?.map((value: any) => (
                      <Chip
                        size="small"
                        key={value.id}
                        label={value.name}
                        onDelete={() => {
                          setSelectedParams((prev: any) => prev?.filter((item: any) => value.id !== item));
                          // API.put(
                          //   "/auth/assign_permission/",
                          //   {
                          //     user: itemId,
                          //     submodule: moduleId,
                          //     action: selected?.filter(
                          //       (item: any) => name.name !== item
                          //     ),
                          //   },
                          //   {},
                          //   0
                          // )
                          //   .then((res: any) => {})
                          //   .catch((err: any) => {});
                          API.put(`/xcpep/feature_details/${item?.itemId}/`, { system: selected?.filter((item: any) => value?.id !== item) }, {}, 0)
                            .then((res: any) => {
                              getOptions();
                            })
                            .catch((err: any) => {});
                        }}
                        deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
                      />
                    ))}
                </Box>
              )}
              MenuProps={MenuProps}>
              {options &&
                options?.map((item: any, index: number) => {
                  return (
                    item?.id &&
                    !selectedParams.includes(item?.id) && (
                      <MenuItem sx={{fontSize:'1rem'}}
                        key={index}
                        value={item?.id ? item?.id : ""}>
                        {item.name}
                      </MenuItem>
                    )
                  );
                })}
            </Select>
          )}
        </FormControl>
      )}
      {item.type === "assambly" && (
        <FormControl
          fullWidth
          variant="standard">
          {/* <InputLabel id="demo-simple-select-label">
                        Select roles
                      </InputLabel> */}
          {
            <Select
            disabled={condition?.id?false:true}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedAssembly || ""}
              multiple
              multiline
              label="Select assembly"
              placeholder="Select assembly"
              onChange={handleSelectChangParamsAssembly}
              size="small"
              sx={{
                "&:before": {
                  borderBottomColor: "White",
                },
                ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
              }}
              renderValue={(selected) => (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 0.5,
                  }}>
                  {optionsAssembly &&
                    optionsAssembly
                      .filter((item: any) => selected?.includes(item?.id))
                      ?.map((value: any) => {
                        return (
                          <Chip
                            size="small"
                            key={value.id}
                            label={value.name}
                            onDelete={() => {
                              setSelectedAssembly((prev: any) => prev?.filter((item: any) => value.id !== item));
                              API.put(`/xcpep/feature_details/${item?.itemId}/`, { assembly: selected?.filter((item: any) => value?.id !== item) }, {}, 0)
                                .then((res: any) => {
                                  getOptions();
                                })
                                .catch((err: any) => {});
                            }}
                            deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
                          />
                        );
                      })}
                </Box>
              )}
              MenuProps={MenuProps}>
              {optionsAssembly &&
                optionsAssembly?.map((item: any, index: number) => {
                  return (
                    !selectedAssembly.includes(item?.id) && (
                      <MenuItem sx={{fontSize:'1rem'}}
                        key={index}
                        value={item?.id ? item?.id : ""}>
                        {item.name}
                      </MenuItem>
                    )
                  );
                })}
            </Select>
          }
        </FormControl>
      )}
      {item?.type === "parts" && (
        <FormControl
          fullWidth
          variant="standard">
          {/* <InputLabel id="demo-simple-select-label">
                        Select roles
                      </InputLabel> */}
          {selectedParts && (
            <Select
            disabled={condition?.id?false:true}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selectedParts || ""}
              multiple
              multiline
              label="Select Parts"
              placeholder="Select Parts"
              onChange={handleSelectChangParamsParts}
              size="small"
              sx={{
                "&:before": {
                  borderBottomColor: "White",
                },
                ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
              }}
              renderValue={(selected) => (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    gap: 0.5,
                  }}>
                  {optionsParts
                    .filter((item: any) => selected?.includes(item?.id))
                    ?.map((value: any) => (
                      <Chip
                        size="small"
                        key={value.id}
                        label={value.name}
                        onDelete={() => {
                          setSelectedParts((prev: any) => prev?.filter((item: any) => value.id !== item));
                          API.put(`/xcpep/feature_details/${item?.itemId}/`, { part: selected?.filter((item: any) => value?.id !== item) }, {}, 0)
                            .then((res: any) => {
                              getOptions();
                            })
                            .catch((err: any) => {});
                        }}
                        deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
                      />
                    ))}
                </Box>
              )}
              MenuProps={MenuProps}>
              {optionsParts &&
                optionsParts?.map((item: any, index: number) => {
                  return (
                    item?.id &&
                    !selectedParts.includes(item?.id) && (
                      <MenuItem sx={{fontSize:'1rem'}}
                        key={index}
                        value={item?.id ? item?.id : ""}>
                        {item.name}
                      </MenuItem>
                    )
                  );
                })}
            </Select>
          )}
        </FormControl>
      )}
    </div>
  );
}
