// vendors
import { createEntityAdapter, createReducer, EntityState } from "@reduxjs/toolkit";
import { ID } from "../../utlis/commonInterfaces";
import { copyProcessModel, createProcessCardCosts, deleteProcessCardCost, getProcessCardCosts, resetProcessCard, updateProcessCardCost } from "../Actions/processCard.action";
import { ADMIN } from "../Services/admin.service";
import { RootState } from "../Store/storeConfigurations";

// services

// redux


// -----------------------------------------------------------------------------------
// entity adaptor

export interface ProcessCardCost {
    id: ID;
    readonly commodity_name: string;
    readonly process_name: string;
    alias: string | null;
    sequence: number;
    quantity: number;
    mirror_id?:any;
    labour_result: string | null;
    setup_result: string | null;
    tool_result: string | null;
    machine_result: string | null;
    readonly labour_cost: number;
    readonly setup_cost: number;
    readonly tool_cost: number;
    readonly machine_cost: number;

    /** ids for redirection(has no other inportance) */
    readonly link: {
        commodity_mapping: ID;
        process: ID;
    } | null;

    calculator_mapped?: boolean;
}

const collator = new Intl.Collator( undefined, { numeric: true, sensitivity: "base" } );

const adaptor = createEntityAdapter<ProcessCardCost>( {
    selectId: ( processCard ) => processCard.id,
    sortComparer: ( a, b ) => a.sequence - b.sequence || collator.compare( a.alias || "", b.alias || "" )
} );

// -----------------------------------------------------------------------------------
// interfaces & initialState

type AdditionalFields = {};

type IProcessCardReducer = EntityState<ProcessCardCost> & AdditionalFields;

const additionalFields: AdditionalFields = {};

const initialState: IProcessCardReducer = Object.assign( {}, adaptor.getInitialState(), additionalFields );

// -----------------------------------------------------------------------------------
// reducer

const ProcessCardReducer = createReducer( initialState, builder => {
    // get
    builder
        .addCase( getProcessCardCosts.fulfilled, ( state, action: any ) => {
            adaptor.setAll( state, action.payload.costs );
        } )
        .addCase( getProcessCardCosts.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could not fetch costs" );
        } );

    // create
    builder
        .addCase( createProcessCardCosts.fulfilled, ( state, action: any ) => {
            adaptor.addMany( state, action.payload.costs );

            // ADMIN.toast.success( "Process cost(s) created" );
        } )
        .addCase( createProcessCardCosts.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could not create cost(s)" );
        } );


    // update
    builder
        .addCase( updateProcessCardCost.fulfilled, ( state, action: any ) => {
            adaptor.upsertOne( state, action.payload.cost );

            // ADMIN.toast.success( "Process cost updated" );
        } )
        .addCase( updateProcessCardCost.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could not update cost" );
        } );

    // delete
    builder
        .addCase( deleteProcessCardCost.fulfilled, ( state, action ) => {
            const { id } = action.meta.arg;
            adaptor.removeOne( state, id );

            // ADMIN.toast.success( "Process cost deleted" );
        } )
        .addCase( deleteProcessCardCost.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could not delete cost" );
        } );

    //Process Copy
    builder
        .addCase( copyProcessModel.fulfilled, ( state, action: any ) => {
            adaptor.addMany( state, action.payload.costs );
            // ADMIN.toast.success( "Process cost(s) Copied" );
        } )
        .addCase( copyProcessModel.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could not copy cost(s)" );
        } );

    // reset
    builder.addCase( resetProcessCard, () => initialState );
} );

// -----------------------------------------------------------------------------------
// selectors

const selectors = adaptor.getSelectors<RootState>( ( state ) => state.processCard );

const processCardSelectors = Object.assign( {}, {}, selectors );

// -----------------------------------------------------------------------------------
// exports

export {
    ProcessCardReducer,
    initialState as ProcessCardReducerInit,
    processCardSelectors
};