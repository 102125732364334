// react

import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import NativeSelect from "@mui/material/NativeSelect";
import Select from "@mui/material/Select";
import React, { useEffect, useState } from "react";
import PhoneInput from "react-phone-input-2";
import { useAppDispatch } from "../../../AppRouter";
import { API } from "../../api-services";
import { ADMIN } from "../../Redux/Services/admin.service";
import { AUTH } from "../../Redux/Services/auth.service";
import { countryCode } from "../Authentication/Verification/utils";
import "react-phone-input-2/lib/style.css";

import { isUndefined } from "lodash";
import styles from "./Header.module.scss";
import TextField from "@mui/material/TextField";
import { useBaseModal } from "../SearchPage/useBaseModal";
import ErrorToastModal from "../../ViewModule/ErrorToastModal";
import { Box, Grid } from "@mui/material";
import swal from "sweetalert";
// vendors
// services
// utils
// constants
// factories
// core
// redux
// ui-components
// others
// styles
// import styles from "./Guestuser.module.scss";

// interfaces and types
// lazy

// ----------------------------------------------------------------------------------

interface GuestuserProps {
  dependency?: any;
  filled?: boolean;
  data?: any;
  dependencyDelete?: any;
  tab?: any;
  fromCount?: any;
  setIdColor: any;
  loader?: any;
  fillfromId?: any;
  tabId?: any;
  setFilledData?: any;
}

const Guestuser: React.FC<GuestuserProps> = (props) => {
  const {
    dependency,
    filled,
    data,
    fromCount,
    dependencyDelete,
    loader,
    tab,
    fillfromId,
    tabId,
    setIdColor,
  } = props;

  const [gusetUser, setguestUser] = useState<any>({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
  });

  const [MobileNumber, setMobileNumber] = useState<any>();
  const [DailCode, setDialCode] = useState<any>();
  const [FirstNameError, setFirstNameError] = useState<any>(false);
  const [LastNameError, setLastNameError] = useState<any>(false);
  const [EmailError, setEmailError] = useState<any>(false);
  const [phoneError, setphoneError] = useState<any>(false);
  const [NumberFormat, setNumberFormat] = useState<any>();
  const [filledData, setFilledData] = useState<any>();
  const [FormTab, setFormTab] = useState<any>();
  const [ErrorMsg, setErrorMsg] = useState<any>(false);
  const [id, setId] = useState<any>();
  const [ErrorMsgServer, setErrorMsgServer] = useState<any>(null);
  const [ToastSucessMessage, setToastSucessMessage] = useState<any>(null);

  const ModalOpen = useBaseModal();
  useEffect(() => {
    if (tab == "PrefilledFrom") {
      setId(data[0]?.id);
      setguestUser({
        firstName: data[0]?.first_name,
        lastName: data[0]?.last_name,
        email: data[0]?.email,
        // phone: data[0]?.phone,
        country_code: data[0]?.country_code,
        expiration: data[0]?.user_expiration,
        days_remaining: data[0]?.days_remaining,
      });
      setFilledData(true);
      setMobileNumber(data[0]?.phone);
      setFormTab(tab);
    } else {
      setguestUser({
        firstName: "",
        lastName: "",
        email: "",
        // phone: "",
        country_code: "",
        expiration: "",
      });
      setFormTab("fillFrom");
      setFilledData(false);
      setMobileNumber("");
    }
  }, [data, tab, FormTab]);

  const [country, setCountry] = useState<any>(
    filled ? data[0]?.country_code : "in"
  );
  const [days, setDays] = useState<any>();
  const [OpenCountry, setOpenCountry] = React.useState(false);

  const OnchnageHandler = (e: any) => {
    const value = e.target.value;
    const name = e.target.name;

    setguestUser({
      ...gusetUser,
      [name]: value,
    });
    name == "firstName" && setFirstNameError(false);
    name == "lastName" && setLastNameError(false);
    name == "email" && setEmailError(false);
  };

  const DeletedHandler = () => {
    swal({
      text: "Are you sure, you want to delete this guest user?",
      buttons: ["Cancel", "Ok"],
      icon: "warning",
    }).then((confirm: any) => {
      if (confirm) {
        API.delete(`/auth/user/${id}/`)
          .then((res: any) => {
            dependency((prevstate: any) => prevstate + 1);
            // ADMIN.toast.success("Guest User deleted");
            setFilledData(false);
            setFormTab("fillFrom");
            setIdColor("1");
          })
          .catch((err: any) => {
            // ADMIN.toast.throwError(err, "Server Error");
          });
      }
    });
  };

  const phoneNumberHandler = (Phonenumber: any, countryCode: any) => {
    // var MobileNumbervalue=Phonenumber.slice(countryCode?.dialCode.length)
    setMobileNumber(Phonenumber);
    setDialCode(countryCode?.dialCode);
    setphoneError(false);

    var temp = (countryCode?.format).replace(/\s+/g, "");
    temp = temp.replace("-", "");
    setNumberFormat(temp);

    // setMobileNumber()
  };

  const domain: any = gusetUser.email?.split("@")?.pop();
  const existDomain: any = AUTH.email?.split("@")?.pop();

  const guestHandler = (action: any) => {
    var MobileNumbervalue = MobileNumber && MobileNumber.slice(DailCode);

    const EmailReqExp: any = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

    let data: any = new Object();
    data["first_name"] = gusetUser.firstName;
    data["last_name"] = gusetUser.lastName;
    data["email"] = gusetUser.email;
    data["phone"] = MobileNumber;

    // data["country_code"] = country;

    data["domain"] = domain;
    // data["validity"] = days;
    var testNaumber = "+" + MobileNumber;
    var Numberlen = testNaumber?.length;
    var NumberFormateStatus = NumberFormat?.length == Numberlen;
    if (
      NumberFormat?.length == Numberlen &&
      data["first_name"].length > 0 &&
      data["last_name"].length > 0 &&
      data["email"].length > 0
    ) {
      // console.log(EmailReqExp.test(data["email"]), "EmailReqExp", data["email"])
      if (EmailReqExp.test(data["email"])) {
        // console.log("emailrex")

        if (existDomain == domain) {
          if (action === "Guestuser") {
            if (
              domain === "gmail.com" ||
              domain === "yahoo.com" ||
              domain === "hotmail.com"
            ) {
              // ADMIN.toast.error("Please vaild enter domain email");
            } else {
              API.post(`auth/guest_user/`, data, 0)
                .then((res: any) => {
                  // dependency((prevstate: any) => prevstate + 1);
                  setFilledData(false);
                  setErrorMsg(false);
                  setIdColor("1");
                  setErrorMsgServer(null);

                  setToastSucessMessage("Guest User Created Successfully!!!");
                  ModalOpen.open();
                  //  ADMIN.toast.success("Successfully Create Guest User");
                })
                .catch((error: any) => {
                  setErrorMsgServer("*User already exists");
                  setToastSucessMessage("");
                });
            } // if ( logout ) AUTH.logout( () => dispatch( { type: "RESET_ALL" } ) );
          }
        } else {
          setErrorMsg(true);
          // ADMIN.toast.error(
          //   "Domain name incorrect please use your same domain name."
          // );
        }
      } else {
        setEmailError(true);
      }
    } else {
      if (NumberFormateStatus == false) {
        setphoneError(true);
      }
      data["first_name"].length == 0 && setFirstNameError(true);
      data["last_name"].length == 0 && setLastNameError(true);
      data["email"].length == 0 && setEmailError(true);
      //   loader(false);
    }
  };

  return (
    <div
      data-testid=""
      // className={ styles.container }
      className={styles.GuestUser}
    >
      <ErrorToastModal
        Masseges={ToastSucessMessage && ToastSucessMessage}
        isOpen={ModalOpen.isOpen}
        onClose={ModalOpen.close}
        dependencies={dependency}
      />
      {data && (
        <form onSubmit={() => guestHandler("Guestuser")}>
          <Grid container sx={{ padding: '0.5rem 1rem' }} columnSpacing={{ xs: 1, sm: 2, md: 2, lg: 2, xl: 2 }} >
            <Grid item xs={12} md={6} lg={6} xl={6} sx={{ pb: 1 }} >
              {FormTab == "PrefilledFrom" && data?.length > 0 && (
                <div style={{ display: "flex", alignItems: "center" }}>
                  <span style={{ color: "#007fff" }}>
                    {gusetUser?.days_remaining}- Days Remaining To Expire
                  </span>
                </div>
              )}
            </Grid>
            <Grid item xs={12} md={6} lg={6} xl={6} sx={{ pb: 1 }}>
              {FormTab == "PrefilledFrom" && data?.length > 0 && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "#007fff",
                  }}
                >
                  <label style={{ width: "7rem" }}> Expiry Date : </label>
                  <span>{gusetUser.expiration}</span>
                </div>
              )}
            </Grid>
            <Grid item xs={12} md={6} lg={6} xl={6} sx={{ pb: 1 }}>
              <div>
                <TextField
                  label="First Name"
                  name="firstName"
                  // sx={{width:"100%"}}
                  value={gusetUser.firstName}
                  onChange={(e: any) => OnchnageHandler(e)}
                  placeholder="First Name"
                  InputProps={{
                    readOnly: data?.length > 0 ? true : false,
                    style: { color: "#007fff" },
                  }}
                  sx={{
                    width: "100%",
                    "& .MuiFormLabel-root": {
                      color: "primary.main",
                    },
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "primary.main",
                      borderColor: "primary.light",
                    },
                    "& .Mui-focused": {
                      color: "primary.main",
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "primary.light",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "primary.light",
                    },
                  }}
                  required
                  variant="standard"
                  size="small"
                />
                <div >
                  {FirstNameError ? (
                    <span style={{ color: 'red', fontSize: '0.75rem' }}>Please enter your First Name</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6} xl={6} sx={{ pb: 1 }}>
              <div>
                <TextField
                  type="text"
                  name="lastName"
                  label="Last Name"
                  sx={{
                    width: "100%",
                    "& .MuiFormLabel-root": {
                      color: "primary.main",
                    },
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "primary.main",
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "primary.light",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "primary.light",
                    },
                  }}
                  value={gusetUser.lastName}
                  onChange={(e: any) => OnchnageHandler(e)}
                  placeholder="Last Name"
                  required
                  InputProps={{
                    readOnly: data?.length > 0 ? true : false,
                    style: { color: "#007fff" },
                  }}
                  variant="standard"
                  size="small"
                />
                <div className={styles.error}>
                  {LastNameError ? (
                    <span style={{ color: 'red', fontSize: '0.75rem' }}>Please enter your Last Name</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6} xl={6} sx={{ pb: 1 }}>
              <div>
                <TextField
                  type="email"
                  name="email"
                  label="Email"
                  sx={{
                    width: "100%",
                    "& .MuiFormLabel-root": {
                      color: "primary.main",
                    },
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "primary.main",
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "primary.light",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "primary.light",
                    },
                  }}
                  value={gusetUser.email}
                  onChange={(e: any) => OnchnageHandler(e)}
                  placeholder="Email"
                  required
                  InputProps={{
                    readOnly: data?.length > 0 ? true : false,
                    style: { color: "#007fff", borderColor: "primary.light" },
                  }}
                  color="primary"
                  variant="standard"
                  size="small"
                />
                <div className={styles.error}>
                  {EmailError ? (
                    <span style={{ color: 'red', fontSize: '0.75rem' }}>Please enter your valid Email Address!</span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6} xl={6} sx={{ pb: 1 }}>
              <div>
                <div style={{ marginTop: "-0.7rem" }}>
                  <label style={{ color: "#007fff", fontSize: "0.8rem" }}>
                    {/* color: "#0000008c", */}
                    Phone
                    <sup
                      style={{
                        fontSize: "0.8rem",
                        // color: "#db2828",
                        margin: "0em 0 0 0.2em",
                      }}
                    >
                      *
                    </sup>
                  </label>
                  <div>
                    {/* @ts-ignore */}
                    <PhoneInput
                      //@ts-ignore
                      id="phone"
                      value={MobileNumber}
                      name="phone"
                      label="Phone"
                      className="phoneInput"
                      readOnly={data?.length > 0 ? true : false}
                      required
                      onChange={(value, data) => {
                        phoneNumberHandler(value, data);
                      }}
                      // InputProps={{
                      //   style: { color: "#007fff" }
                      // }}
                      sx={{
                        width: "100%",
                        "& .MuiFormLabel-root": {
                          color: "primary.main",
                        },
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "primary.main",
                        },
                        "& .MuiInput-underline:before": {
                          borderColor: "primary.light",
                        },
                        "& .MuiInput-underline:after": {
                          borderColor: "primary.light",
                        },
                      }}
                      country={"in"}
                      countryCodeEditable={false}
                    />
                  </div>
                  <div className={styles.error}>
                    {phoneError ? (
                      <span style={{ color: 'red', fontSize: '0.75rem' }}>Please enter your Phone Number</span>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6} xl={6} sx={{ pb: 1 }}>
              {ErrorMsgServer !== null && (
                <div>
                  <span style={{ color: 'red', fontSize: '0.75rem' }}>{ErrorMsgServer}</span>
                </div>
              )}
              <div>
                {ErrorMsg ? (
                  <span style={{ color: 'red', fontSize: '0.75rem' }}>
                    *Please use same domain name as yours.
                  </span>
                ) : (
                  ""
                )}
              </div>
            </Grid>
            <Grid item xs={12} md={6} lg={6} xl={6} sx={{ pb: 1 }}>
              <Box sx={{ display: "flex", justifyContent: 'flex-end', alignItems: 'center', columnGap: '1rem' }}>
                {FormTab == "PrefilledFrom" && data?.length > 0 && (
                  <Button sx={{
                    '&:hover': {
                      transform: "scale(1.1)",
                      transition: 'transform 0.5s ease'
                    }
                  }} variant="contained" size="small" onClick={DeletedHandler}>
                    {/* <DeleteIcon /> */} Delete
                  </Button>
                )}
                <Button
                  sx={{
                    '&:hover': {
                      transform: "scale(1.1)",
                      transition: 'transform 0.5s ease'
                    }
                  }}
                  variant="contained"
                  size="small"
                  disabled={data?.length > 0 ? true : false}
                  onClick={() => guestHandler("Guestuser")}
                >
                  {/* <SaveIcon /> */} Save
                </Button>
              </Box>
            </Grid>
          </Grid>
        </form>
      )}
    </div>
  );
};

// ----------------------------------------------------------------------------------

export default Guestuser;
