import * as React from "react";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import verifyweight from "../Assets/images/Verifyweight.svg";
import { Box, Divider, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import styles from "./ScanandVerifyModal.module.scss";
import { QrReader } from "react-qr-reader";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { API } from "../api-services";
import { useRouteParams } from "../ui-reusable-component/useRouteParams";
import { useBaseModal } from "../ComponentUI/SearchPage/useBaseModal";
import ErrorModalFilesMail from "../ComponentUI/ProjectMailModule/ErrorModalFilesMail";
import Swal from "sweetalert2";
import successIcon from "../Assets/images/success.png";

export interface IScanandVerifyModalProps {
  onCloseModal: any;
  isOpen: any;
  // onClose: () => void;
}

// const style = {
//     position: 'absolute' as 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -50%)',
//     // width: '60vw',
//     // height: '60vh',
//     bgcolor: 'background.paper',
//     borderRadius: '10px',
//     p: 1,
// };

export function ScanandVerifyModal(props: IScanandVerifyModalProps) {
  const { onCloseModal, isOpen } = props;
  const { projectId } = useRouteParams<any>();
  const history = useHistory();
  const [ErrorModalFileMsg, setErrorModalFileMsg] = useState<any>();
  const [CounterData, setCounterData] = useState<any>(0);
  const [data, setData] = useState("No result");
  var ErrorModalFiles = useBaseModal();

  const handleScan = async (vaultId: any) => {
    setCounterData((prev: any) => prev + 1);
    if (!vaultId) return;
    API.post(
      "/xcpep/dispatch/",
      {
        qr_method: vaultId?.text,
        project: projectId,
      },
      {},
      0
    )
      .then((res: any) => {
        Swal.fire({
          html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
    
      <br />
    
      <p style="color:#007fff;">Part verified successfully
    
      </p>   
    
       </div>`,
        });
        // onCloseModal();
      })
      .catch((err: any) => {
        setErrorModalFileMsg(err.response?.data[0]);
        ErrorModalFiles.open();
      });
  };

  const videoframestyle = {
    height: "auto",
    width: { xs: '70vw', sm: "45vw" },
  };

  return (
    <div>
      <Modal
        open={isOpen}
        // onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box className={styles.child}>
          <ErrorModalFilesMail
            isOpen={ErrorModalFiles.isOpen}
            onCloseModal={ErrorModalFiles.close}
            ErrorMsg={ErrorModalFileMsg}
          />
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}>
              Scan and Verify
            </Typography>

            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />

          <Box sx={{ width: { xs: '80vw', sm: "60vw" }, height: { xs: '32vh', sm: "60vh" }, marginTop: { xs: '0', lg: '-5rem', xl: "-7rem" }, marginLeft: { lg: "-5.5rem", xl: "-7rem" } }}>
            {/* @ts-ignore */}
            <QrReader
              className={styles.scannerSection}
              containerStyle={videoframestyle}
              // scanDelay={300}
              constraints={{ facingMode: "environment" }}
              onResult={handleScan}
            />
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
