import * as React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Typography,
  Skeleton,
  ButtonProps,
  Menu,
  MenuItem,
  Popper,
  PopperPlacementType,
  Tooltip,
  ClickAwayListener,
  debounce,
} from "@mui/material";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import FlagIcon from "@mui/icons-material/Flag";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import flagreen from "../../../Assets/images/flag.png";
import flagred from "../../../Assets/images/flag_red.png";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { API } from "../../../api-services";

import { DataGrid } from "@mui/x-data-grid/DataGrid/DataGrid";
import successIcon from "../../../Assets/images/success.png";
import FileCopyOutlinedIcon from "@mui/icons-material/FileCopyOutlined";
import {
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExportContainer,
} from "@mui/x-data-grid";
import BOMDataFlagModal from "./BOMDataFlagModal";
import { useBaseModal } from "../../SearchPage/useBaseModal";
import Swal from "sweetalert2";

export interface IBOMDrawerTableProps {
  TabStatus: any;
}
const csvOptions: GridCsvExportOptions = {
  fileName: "BOM module CSV file",
  delimiter: ",",
  utf8WithBom: true,
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer style={{ height: "2rem" }}>
    <span style={{ position: "absolute", right: "0rem" }}>
      <GridToolbarColumnsButton />
      {/* <GridToolbarDensitySelector /> */}
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);

export function BOMDrawerTable(props: IBOMDrawerTableProps) {
  const { TabStatus } = props;
  const { vault, topVault, projectId, projectname, productId, abbrebation } = useRouteParams<any>();

  const [TablelistData, setTablelistData] = React.useState<any>([]);
  const [ParameterData, setParameterDaa] = React.useState<any>([]);
  const [TableLoader, setTableLoader] = React.useState<any>(false);
  const [SigmaData, setSigmaData] = React.useState<any>({});
  const [flagData, setFlagdata] = React.useState<any>([]);
  const [commentsLoader, setCommentsLoader] = React.useState<any>(false)

  const [open, setOpen] = React.useState();

  const flagModal = useBaseModal();
  React.useEffect(() => {
    setTableLoader(true);
    if (TabStatus == "Parameters") {
      setTableLoader(true);
      API.get("/xcpep/cost_bom_data/", { part: true, vault: vault }, 0)
        .then((res: any) => {
          // console.log(res.data, "vhdvbjdvbjdv");
          setParameterDaa(res.data);
          setTableLoader(false);
        })
        .catch((err: any) => {
          setTableLoader(false);
        });
    } else {
      setTableLoader(true);
      API.get("/xcpep/cost_bom_data/", { table: true, vault: vault }, 0)
        .then((res: any) => {
          // console.log(res.data, "vhdvbjdvbjdv");
          setTableLoader(false);
          setTablelistData(res.data);
        })
        .catch((err: any) => {
          setTableLoader(false);
        });
    }
  }, [TabStatus, vault]);

  function CopyData(value: any) {
    setOpen(value?.param_id);
    // console.log(value, "valuevalue");
    copyToClipboard(value?.value);
  }

  function copyToClipboard(text: any) {
    var dummy = document.createElement("textarea");
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    dummy.setSelectionRange(0, 99999);
    /* Copy the text inside the text field */
    navigator.clipboard.writeText(dummy.value);
    document.body.removeChild(dummy);
  }

  const FlagHandler = (value: any) => {
    setFlagdata(value);
    flagModal.open();
  };
  const PcbTableHeader = (HeaderData: any) => {
    var valuedata: any =
      HeaderData &&
      HeaderData?.map((key: any, value: any) => {
        return {
          field: key?.name,
          flex: 1,

          align: key?.data_type === "int" && "left",
          headerAlign: key?.data_type === "int" && "left",
          type: key?.data_type === "int" ? "number" : "string",
          //  headerAlign: 'center',
          // type: 'string',
          disableExport: false,
          headerName:
            SigmaData && Object.keys(SigmaData)?.includes(key?.name)
              ? `${key?.name} (${SigmaData[key?.name]}) `
              : key?.name,
          //   headerClassName: styles.Dataheader,

          renderCell: (params: any) => {
            return (
              <>
                <span>{params.row[key?.name]}</span>
              </>
            );
            // if (key?.data_type == "image") {
            //   return (
            //     <CameraAltIcon
            //       style={{ cursor: "pointer" }}
            //     //   onClick={() => {
            //     //     handleClick(params.row[key?.name]);
            //     //   }}
            //     />
            //   );
            // }
            //  else {
            //   return <span>{params.row[key?.name]}</span>;
            // }
          },
        };
      });
    return valuedata;
  };

  const PcbTableRow = (Rowdata: any) => {
    var valuedata =
      Rowdata &&
      Rowdata.map((item: any, id: any) => ({
        ...item,
        id: id + 1,
      }));

    return valuedata;
  };


  const flagonchangeHanlder = (event: any, item: any) => {

    if (TabStatus == "Parameters") {
      setCommentsLoader(true)
      {
        API.put(`/xcpep/cost_bom_data/`, {
          vault: vault,
          parameter_id: item,
          flag: event,
        }, {}, 0).then((res: any) => {
          flagModal?.close()
          setCommentsLoader(false)
          Swal.fire({
            html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
      <br />
      <p style="color:#007fff;"> Comments updated successfully
      </p>   
       </div>`,
          });
          API.get("/xcpep/cost_bom_data/", { part: true, vault: vault }, 0)
            .then((res1: any) => {
              setParameterDaa(res1.data);
              setTableLoader(false);
            })
            .catch((err: any) => {
              setTableLoader(false);
            });
        }).catch((err: any) => {
          setCommentsLoader(false)
        })
      }
    } else {
      setCommentsLoader(true)
      API.put(`/xcpep/cost_bom_data/`, {
        vault: vault,
        parameter_id: item,
        flag: event,
      }, {}, 0).then((res: any) => {
        flagModal?.close()
        setCommentsLoader(false)
        Swal.fire({
          html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
    <br />
    <p style="color:#007fff;"> Comments updated successfully
    </p>   
     </div>`,
        });
        API.get("/xcpep/cost_bom_data/", { table: true, vault: vault }, 0)
          .then((res1: any) => {
            console.log(res.data, "vhdvbjdvbjdv");
            setTableLoader(false);
            setTablelistData(res1.data);
          })
          .catch((err: any) => {
            setTableLoader(false);
          });
      }).catch((err: any) => {
        setCommentsLoader(false)
      })
    }
  };

  return (
    <Box
      sx={{ height: { lg: "80vh", xl: "85vh" }, border: "1px solid" }}
      adl-scrollbar="true"
      adl-scrollbar-width="0.3">
      {flagModal?.isOpen && (
        <BOMDataFlagModal
          isOpen={flagModal?.isOpen}
          handleClose={flagModal?.close}
          Item={flagData}
          TabStatus={TabStatus}
          loader={commentsLoader}
          callbackHandler={flagonchangeHanlder}
        />
      )}
      {TabStatus == "Parameters" ? (
        TableLoader ? (
          <>
            <Skeleton
              sx={{
                width: "70vw",
                height: "3rem",
                marginLeft: "1.2rem",
              }}
            />
            <Skeleton
              sx={{
                width: "70vw",
                height: "3rem",
                marginLeft: "1.2rem",
              }}
            />
            <Skeleton
              sx={{
                width: "70vw",
                height: "3rem",
                marginLeft: "1.2rem",
              }}
            />
            <Skeleton
              sx={{
                width: "70vw",
                height: "3rem",
                marginLeft: "1.2rem",
              }}
            />
          </>
        ) : (
          <>
            <Table>
              <TableHead
                sx={{
                  position: "sticky",
                  top: "0",
                  backgroundColor: "primary.light",
                  zIndex: 1,
                }}>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    Parameter Name
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    Value
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    Unit
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}>
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {ParameterData &&
                  ParameterData?.part_details?.map(
                    (item: any, index: number) => {
                      return (
                        <>
                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                              }}>
                              {item?.paramter_name}
                            </TableCell>
                            <TableCell
                              sx={{
                                position: "relative !important",
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                              }}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  position: "relative !important",
                                  gap: "0.5rem",
                                }}>
                                <Tooltip
                                  title={
                                    item?.param_id == open
                                      ? "Copied"
                                      : "Click to copy"
                                  }>
                                  {item?.param_id == open ? (
                                    <FileCopyIcon sx={{ fontSize: "1.2rem" }} />
                                  ) : (
                                    <>
                                      <FileCopyOutlinedIcon
                                        onClick={(e: any) => {
                                          CopyData(item);
                                          //   handleClick(e, "right");
                                        }}
                                        sx={{
                                          fontSize: "1.2rem",
                                          cursor: "pointer",
                                        }}
                                      />
                                    </>
                                  )}
                                </Tooltip>
                                {item?.value}{" "}
                              </Box>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                              }}>
                              {item?.unit}
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                              }}>
                              {item?.flag !== null ?
                                <FlagIcon titleAccess="Flog info" style={{ height: "1.5rem", cursor: "pointer", color: "green" }} onClick={() => FlagHandler(item)} />

                                :
                                <img
                                  title="Flag info"
                                  onClick={() => FlagHandler(item)}
                                  src={flagreen}
                                  style={{ height: "1.5rem", cursor: "pointer" }}
                                />
                              }
                            </TableCell>
                          </TableRow>
                        </>
                      );
                    }
                  )}
              </TableBody>
            </Table>
          </>
        )
      ) : (
        <>

          {TableLoader ? (
            <>
              <Skeleton
                sx={{
                  width: "70vw",
                  height: "3rem",
                  marginLeft: "1.2rem",
                }}
              />
              <Skeleton
                sx={{
                  width: "70vw",
                  height: "3rem",
                  marginLeft: "1.2rem",
                }}
              />
              <Skeleton
                sx={{
                  width: "70vw",
                  height: "3rem",
                  marginLeft: "1.2rem",
                }}
              />
              <Skeleton
                sx={{
                  width: "70vw",
                  height: "3rem",
                  marginLeft: "1.2rem",
                }}
              />
            </>
          ) :

            (

              TablelistData && TablelistData?.length == 0 ?

                (
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      marginTop: "30rem",
                    }}>
                    <Typography sx={{ fontSize: "1.5rem" }}>No Data</Typography>
                  </Box>
                )
                : (
                  TablelistData &&
                  TablelistData?.map((tableItem: any, ind: any) => {
                    return (
                      <>
                        {(
                          <>
                            <Box
                              sx={{
                                display: "flex",
                                columnGap: "1rem",
                                alignItems: "center",
                                padding: "0.2rem 1rem",
                                width: "50%",
                                zIndex: "1000",
                                position: "relative",
                              }}>
                              <Box sx={{
                                display: "flex",
                                gap: "0.3rem"
                              }}>
                                <Typography
                                  sx={{ fontSize: "1rem", marginLeft: "1rem" }}>
                                  {" "}
                                  {tableItem?.table_name}

                                </Typography>
                                <img
                                  title="Flag info"
                                  //

                                  onClick={() => FlagHandler(tableItem)}
                                  src={flagreen}
                                  style={{ height: "1.3rem", cursor: "pointer" }}
                                />
                              </Box>
                            </Box>
                            <Box
                              sx={{
                                width: "100%",
                                padding: "0 1rem",
                                marginTop: "-2rem",
                              }}>
                              {/* height: tableItem?.data.length == 1 ? 200 : 337, */}
                              <DataGrid
                                headerHeight={48}
                                rowHeight={36}
                                rows={PcbTableRow(tableItem?.data)}
                                columns={PcbTableHeader(
                                  tableItem && tableItem?.header_item
                                )}
                                // pageSize={5}
                                // rowsPerPageOptions={[5]}
                                sx={{
                                  fontSize: "12px",
                                  borderColor: "primary.light",
                                  "&.MuiDataGrid-root.MuiDataGrid-autoHeight .MuiDataGrid-row--lastVisible .MuiDataGrid-cell":
                                  {
                                    borderBottomColor: "primary.light",
                                  },
                                  "&.MuiDataGrid-root .MuiDataGrid-cell": {
                                    borderBottomColor: "primary.light",
                                  },
                                  "& .MuiDataGrid-root.MuiDataGrid-autoHeight.MuiDataGrid-root--densityCompact.MuiDataGrid-root":
                                  {
                                    border: "none !important",
                                  },
                                }}
                                density="compact"
                                // checkboxSelection
                                // @ts-ignore
                                // components={{ Toolbar: GridToolbar }}
                                components={{
                                  Toolbar: () => {
                                    return CustomToolbar();
                                  },
                                }}
                                autoHeight={true}
                                disableSelectionOnClick
                                hideFooterPagination={true}
                                hideFooter={true}
                              // experimentalFeatures={{ newEditingApi: true }}
                              />
                            </Box>
                          </>
                        )}
                      </>
                    );
                  })))}

        </>
      )}
    </Box>
  );
}
