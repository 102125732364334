// vendors

import { EntityState, createEntityAdapter,createSelector, createReducer } from "@reduxjs/toolkit";
import { ID } from "../../utlis/commonInterfaces";
import { createFlaggedAssumption, deleteFlaggedAssumption, getFlaggedAssumptions, resetFlaggedAssumptions, updateFlaggedAssumption } from "../Actions/flaggedAssumption.actions";
import { RootState } from "../Store/storeConfigurations";

// -----------------------------------------------------------------------------------
// entity adaptor

export interface FlaggedAssumption {
    id: ID;
    input_field: ID;
    reference: string | null;
}

const adaptor = createEntityAdapter<FlaggedAssumption>( {
    selectId: ( flaggedAssumption ) => flaggedAssumption.id,
} );

// -----------------------------------------------------------------------------------
// interfaces & initialState

type AdditionalFields = {};

type IFlaggedAssumptionReducer = EntityState<FlaggedAssumption> & AdditionalFields;

const additionalFields: AdditionalFields = {};

const initialState: IFlaggedAssumptionReducer = Object.assign( {}, adaptor.getInitialState(), additionalFields );

// -----------------------------------------------------------------------------------
// reducer

const FlaggedAssumptionReducer = createReducer( initialState, builder => {
    // get
    builder
        .addCase( getFlaggedAssumptions.fulfilled, ( state, action: any ) => {
            adaptor.setAll( state, action.payload.flaggedAssumptions );
        } )
        .addCase( getFlaggedAssumptions.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could not get flagged assumptions" );
        } );

    // create
    builder
        .addCase( createFlaggedAssumption.fulfilled, ( state, action: any ) => {
            adaptor.addOne( state, action.payload.flaggedAssumption );

            // ADMIN.toast.success( "Input flagged as assumption" );
        } )
        .addCase( createFlaggedAssumption.rejected, ( state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could not flag input as assumption" );
        } );

    // update
    builder
        .addCase( updateFlaggedAssumption.fulfilled, ( state, action: any ) => {
            adaptor.upsertOne( state, action.payload.flaggedAssumption );

            // ADMIN.toast.success( "Flagged assumption updated" );
        } )
        .addCase( updateFlaggedAssumption.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could not update flagged assumption" );
        } );

    // delete
    builder
        .addCase( deleteFlaggedAssumption.fulfilled, ( state, action ) => {
            const { id } = action.meta.arg;
            adaptor.removeOne( state, id );

            // ADMIN.toast.success( "Unflagged input" );
        } )
        .addCase( deleteFlaggedAssumption.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could not unflag assumption" );
        } );

    // reset
    builder.addCase( resetFlaggedAssumptions, () => initialState );
} );

// -----------------------------------------------------------------------------------
// selectors

const selectors = adaptor.getSelectors<RootState>( ( state ) => state.flaggedAssumptions );

const selectByInputId = createSelector(
    selectors.selectAll,
    ( _state: RootState, inputId: ID ) => inputId,
    ( flaggedAssumptions, inputId ) => {
        return flaggedAssumptions.find( flaggedAssumption => flaggedAssumption.input_field === inputId );
    }
);

const flaggedAssumptionSelectors = Object.assign( {}, {
    selectByInputId
}, selectors );

// -----------------------------------------------------------------------------------
// exports

export {
    FlaggedAssumptionReducer,
    initialState as FlaggedAssumptionReducerInit,
    flaggedAssumptionSelectors
};