import * as React from "react";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import * as yup from "yup";
import { useFormik } from "formik";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { API } from "../../api-services";
import LoadingButton from "@mui/lab/LoadingButton";
import styles from "./AddGroupModal.module.scss";
import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { conditionalExpression } from "@babel/types";
import { AUTH } from "../../Redux/Services/auth.service";
import CancelIcon from '@mui/icons-material/Cancel';


export interface IAddGroupModalProps {
  isOpen: boolean;
  onClose: () => void;
  groupDetails: any;
  setCreateCount?: any;
}
const validationSchema = yup.object({
  attendees: yup.array().required("Please Select attendees"),
  project: yup.mixed().nullable().required("Project can't be blank"),
  client: yup.mixed(),
  name: yup.string().required("Please enter group name"),
});

const styled = {
  boxSize: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",

    width: "39rem",
    maxWidth: "80%",
    height: "fit-content",
    minHeight: "20rem",
    borderRadius: "0.5rem",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    p: 0,
    outline: 0,
  },
  NoAccessBoxSize: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center !important",
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "39rem",
    height: "fit-content",
    minHeight: "20rem",
    borderRadius: "0.5rem",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    p: 0,
    outline: 0,
  },
  HeaderLine: {
    textAlign: "center",
    position: "relative",
  },
  SelectedHeaderLine: {
    color: "#1976d2",
  },
  nextButton: {
    position: "absolute",
    zIndex: "9999",
    left: "35rem",
    top: "1.8rem",
  },

  summery: {
    backgroundColor: "rgb(240, 247, 255)",
    borderColor: " rgb(0, 127, 255)",
    borderRadius: "10px",
    minHeight: "45px",
  },
  searchButton: {
    position: "absolute",

    // right: "37rem",
    top: "0.4rem",

    left: "-2rem",
    cursor: "default",
    color: "#1976d2",
    padding: " 0rem 0rem 0rem 2rem",
  },
  closedIcon: {
    position: "absolute",
    zIndex: "999",
    color: "#1976d2",
    right: "0.2rem",
    top: "0.5rem",
    cursor: "pointer",
  },
  editIcon: {
    marginTop: "0.3rem",
    color: "#1976d2",
    cursor: "pointer",
  },
};
// @ts-ignore

const useStyles = makeStyles(() => ({
  /** Changed modalStyle */
  ModalRoot: {
    backgroundColor: "rgba(111, 126, 140, 0.2) !important",
    backdropFilter: "blur(1px) !important",
  },
}));

export function AddGroupModal(props: IAddGroupModalProps) {
  const { isOpen, onClose, groupDetails, setCreateCount } = props;
  const classes = useStyles();
  const { projectId, groupId } = useRouteParams<any>();
  const [internalattendeesList, setInternalAttendeesList] = React.useState<any>(
    []
  );

  const [externalattendeesList, setExternalAttendeesList] = React.useState<any>(
    []
  );




  const getUserList = () => {
    API.get(
      "/auth/mom_permission/",
      { project: projectId },
      0
    ).then((res: any) => {
      setInternalAttendeesList(
        res.data?.users.filter(
          (user: any) => user?.user_category === "Internal"
        )
      );
      setExternalAttendeesList(
        res.data?.users.filter(
          (user: any) => user?.user_category === "External"
        )
      );
    });
  };

  React.useEffect(() => {
    if (projectId != 0) {
      getUserList();
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      attendees: [],
      project: parseInt(projectId),
      client: false,
      name: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let obj = {
        attendees: [...values.attendees, ...[AUTH.user]],
        project: values.project,
        client: values.client,
        name: values.name,
      }
      if (groupDetails !== "Add") {
        API.put(`/drive/meeting_group/${groupId}/`, obj, {}, 0).then((res: any) => {
          setCreateCount((prev: any) => prev + 1)
          onClose()
        }).catch((err: any) => {
          console.log(err)

        })
      } else {
        API.post("/drive/meeting_group/", obj, {}, 0).then(() => {
          setCreateCount((prev: any) => prev + 1)
          onClose()
        }).catch((err: any) => {
          console.log(err);
        })
      }
    },
  });
  const deleteGroup = () => {
    API.delete(`/drive/meeting_group/${groupDetails?.id}/`, {}, 0)
      .then((res: any) => {
        setCreateCount((prev: any) => prev + 1)
        onClose()
      })
      .catch((err: any) => {
        console.log(err);
      });
  }
  React.useEffect(() => {
    if (groupDetails !== "Add" && groupDetails != 0 && isOpen) {
      formik.values.attendees = groupDetails.attendees
      formik.values.name = groupDetails.name
    }
  }, [groupDetails])


  return (
    <div>
      <Modal // autoFocusdal
        open={isOpen}
        className={classes.ModalRoot}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styled.boxSize}>

          <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: '-2rem'
              }}
            >
              {groupDetails ==="Add"?"Create Meeting Group":"Edit Meeting Group"}
            </Typography>
            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onClose} />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ width: "100%", overflowWrap: "break-word", padding: "1rem" }}>
            <form onSubmit={formik.handleSubmit}>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120, width: '97%' }} size="small" >
                <InputLabel>Add Internal Users</InputLabel>
                <Select
                  id="attendees"
                  name="attendees"
                  multiple
                  required
                  // className={classes.select}
                  labelId="demo-select-small"
                  // id="demo-select-small"
                  sx={{
                    "& .MuiFormLabel-root": {
                      color: "#007fff",
                    },                       
                    color: "#007fff",
                    // "& .MuiSelect-select-MuiInputBase-input-MuiInput-input.MuiSelect-select:before":
                    //   { borderBottom: "1px solid blue !important" },
                    // "& .MuiInputBase-root-MuiInput-root-MuiSelect-root.Mui-disabled:before":
                    //   { borderBottom: "1px solid blue !important" },
                    "& .MuiInput-root-MuiSelect-root:after": {
                      borderBottomColor: "primary.light",
                    },
                    ".MuiSelect-select.MuiInputBase-input.MuiInput-input": {
                      fontSize: "1rem",
                    },
                    // .css - 1to371u- MuiInputBase - root - MuiInput - root - MuiSelect - root:before
                    // '.MuiOutlinedInput-notchedOutline': {
                    //   borderColor: '#007fff',
                    // },
                    // '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    //   borderColor: '#007fff',
                    // },
                    // '&:hover .MuiOutlinedInput-notchedOutline': {
                    //   borderColor: '#007fff',
                    // },
                    ".MuiSvgIcon-root ": {
                      fill: "#007fff !important",
                    },
                    borderBottom:'1px solid',
                    borderBottomColor:'primary.main',                 
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "primary.main",
                    },
                    "&:before": {
                      borderBottomColor: "white",
                    },
                    // '& .MuiInputBase-root.MuiInput-root.MuiSelect-root:before': { borderBottomColor: '#007fff !important'},
                    // '& .MuiOutlinedInput-underline:before': { borderBottomColor: '#007fff' },
                    // '& .MuiOutlinedInput-underline:after': { borderBottomColor: '#007fff' },
                  }}
                  MenuProps={{
                    style: {
                      maxHeight: 400,
                      color: "007fff",
                    },
                  }}
                  onChange={formik.handleChange}
                  value={formik.values.attendees}
                >
                  {/* <option value={""} style={{ display: "none" }}></option> */}
                  {internalattendeesList &&
                    internalattendeesList.filter((item: any) => item.id !== AUTH.user).map((item: any, index: any) => {
                      return (
                        <MenuItem
                          sx={{ color: "primary.main", fontSize: "1rem" }}
                          key={index}
                          value={item.id}
                        >
                          {item.username}
                        </MenuItem>
                      );
                    })}
                </Select>
                <div className={styles.errormsg}>
                  {formik.touched.attendees &&
                    Boolean(formik.errors.attendees) && (
                      //@ts-ignore
                      <p>{formik.errors.attendees}</p>
                    )}
                </div>
              </FormControl>
              <FormControl
                variant="standard"
                sx={{ m: 1, minWidth: 120, width: '97%' }}
                size="small"
              >
                <InputLabel>Add External Users</InputLabel>
                <Select
                  id="attendees"
                  name="attendees"
                  multiple
                  required
                  // className={classes.select}
                  labelId="demo-select-small"
                  // id="demo-select-small"
                  sx={{
                    "& .MuiFormLabel-root": {
                      color: "#007fff",
                    },
                    borderBottom:'1px solid',
                    borderBottomColor:'primary.main',
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "primary.main",
                    },
                    color: "#007fff",
                    "& .MuiSelect-select-MuiInputBase-input-MuiInput-input.MuiSelect-select:before":
                      { borderBottom: "1px solid blue !important" },
                    "& .MuiInputBase-root-MuiInput-root-MuiSelect-root.Mui-disabled:before":
                      { borderBottom: "1px solid blue !important" },
                    "& .MuiInput-root-MuiSelect-root:after": {
                      borderBottomColor: "primary.light",
                    },
                    ".MuiSelect-select.MuiInputBase-input.MuiInput-input": {
                      fontSize: "1rem",
                    },
                    "&:before": {
                      borderBottomColor: "white",
                    },
                    // .css - 1to371u- MuiInputBase - root - MuiInput - root - MuiSelect - root:before
                    // '.MuiOutlinedInput-notchedOutline': {
                    //   borderColor: '#007fff',
                    // },
                    // '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    //   borderColor: '#007fff',
                    // },
                    // '&:hover .MuiOutlinedInput-notchedOutline': {
                    //   borderColor: '#007fff',
                    // },
                    ".MuiSvgIcon-root ": {
                      fill: "#007fff !important",
                    },
                    // '& .MuiInputBase-root.MuiInput-root.MuiSelect-root:before': { borderBottomColor: '#007fff !important'},
                    // '& .MuiOutlinedInput-underline:before': { borderBottomColor: '#007fff' },
                    // '& .MuiOutlinedInput-underline:after': { borderBottomColor: '#007fff' },
                  }}
                  MenuProps={{
                    style: {
                      maxHeight: 400,
                      color: "007fff",
                    },
                  }}
                  onChange={formik.handleChange}
                  value={formik.values.attendees}
                >
                  {/* <option value={""} style={{ display: "none" }}></option> */}
                  {externalattendeesList &&
                    externalattendeesList?.filter((item: any) => item.id !== AUTH.user)?.map((item: any, index: any) => {
                      return (
                        <MenuItem
                          sx={{ color: "primary.main", fontSize: "1rem" }}
                          key={index}
                          value={item.id}
                        >
                          {item.username}
                        </MenuItem>
                      );
                    })}
                </Select>
                <div className={styles.errormsg}>
                  {formik.touched.attendees &&
                    Boolean(formik.errors.attendees) && (
                      //@ts-ignore
                      <p>{formik.errors.attendees}</p>
                    )}
                </div>
              </FormControl>
              <FormControl sx={{ m: 1, minWidth: 120, width: '97%' }}>
                <TextField
                  variant="standard"
                  InputProps={{
                    style: { color: "#007fff" },
                  }}
                  sx={{
                    width: "100%",
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "#007fff",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "#ffffff",
                    },
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "primary.main",
                    },                   
                  }}
                  id="name"
                  name="name"
                  label="Group Name"
                  onChange={formik.handleChange}
                  value={formik.values.name}
                  multiline
                  rows={4}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  //@ts-ignore
                  helperText={formik.touched.name && formik.errors.name}
                />
              </FormControl>
              <Box sx={{textAlign:'right'}}>
                <LoadingButton
                  //   className={styles.feedbackSubmit}
                  variant="contained"
                  size="small"
                  type="submit"
                  sx={{
                    backgroundColor: groupDetails ==="Add"? "primary.main ":'#6aa84f',
                    '&:hover':{
                      transform:'scale(1.1)',
                      transition:'transform 0.5s ease'
                    }   
                  }}
                //   loading={isLoading}
                //   disabled={userId !== "" ? false : true}
                >
                  {groupDetails ==="Add"?"Add Group":"Save Changes"}
                </LoadingButton>
                {groupDetails !== "Add" && <LoadingButton
                  //   className={styles.feedbackSubmit}
                  sx={{ marginLeft: 2,backgroundColor:'#d65e5e',
                	'&:hover':{
                    transform:'scale(1.1)',
                    transition:'transform 0.5s ease'
                  }    }}
                  size="small"
                  variant="contained"
                  onClick={() => deleteGroup()}
                //   loading={isLoading}
                //   disabled={userId !== "" ? false : true}
                >
                  Delete Group
                </LoadingButton>}
              </Box>
            </form>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
