import { Box, ButtonProps, Divider, Modal, Skeleton, Tooltip, Typography } from '@mui/material';

import * as React from 'react';
import useWindowDimensions from '../../ui-reusable-component/useWindowDimensions'; 
import CancelIcon from '@mui/icons-material/Cancel';
import { API } from '../../api-services';
import { useRouteParams } from '../../ui-reusable-component/useRouteParams';
import { GridCsvExportOptions } from '@mui/x-data-grid/models/gridExport';
import { GridToolbarExportContainer } from '@mui/x-data-grid/components/toolbar/GridToolbarExportContainer';
import { GridCsvExportMenuItem } from '@mui/x-data-grid/components/toolbar/GridToolbarExport';
import { GridToolbarContainer } from '@mui/x-data-grid/components/containers/GridToolbarContainer';
import { DataGrid } from '@mui/x-data-grid/DataGrid/DataGrid';


export interface IInfoParameterDetailsProps {
  onCloseModal: any;
  isOpen: any;
  item: any;

}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: "60vw",
  maxWidth: '80vw',
  // height: '80vh',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  p: 1,
};
const csvOptions: GridCsvExportOptions = { fileName: 'Table In CSV', delimiter: ",", utf8WithBom: true };

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer style={{ height: '1.5rem', marginBottom: '0.5rem' }}>
    <span style={{ position: 'absolute', right: '0rem' }}>
      {/* <GridToolbarColumnsButton /> */}
      {/* <GridToolbarDensitySelector /> */}
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
)


export function RevisionAssumptionInfoModal(props: IInfoParameterDetailsProps) {
  const { height, width } = useWindowDimensions();
  const { projectId } = useRouteParams<any>();
  const [infoData, setInfoData] = React.useState<any>([]);
  const [isLoading, setIsLoading] = React.useState<any>(false)
  const { tab, year, month, revisionId, typeId, databaseName, status, search, categoryName, } = useRouteParams<any>();
  const { onCloseModal, isOpen, item } = props


  const getInfoData = () => {
    setIsLoading(true)
    API.get("/api/db/check_variant/", { type: 'assumption', id: item }, 0).then((res: any) => {
      setInfoData(res.data)
      setIsLoading(false)
    }).catch((err: any) => {
      setIsLoading(false)
    })
  }

  React.useEffect(() => {
    getInfoData();
  }, [item])


  //  /createcostingpart/14/23-T-P18/Ecu/3/7405/none/0/0/0/0/0/0/0/0
  const dataGridDataGenerator: any = infoData?.map((ideaItem: any, index: any) => ({
    ids: index + 1,
    ...ideaItem
  }));

  var Columns: any = [
    {
      field: "id", headerName: 'Name', hide: true,
      minWidth: 50,
      flex: 1,
    },
    {
      field: "ids", headerName: 'Sl No.',
      minWidth: 50,
      flex: 1,
    },
    {
      field: 'project_name',
      headerName: 'Project Name',
      width: width > 1400 ? 200 : 180,
    },

    {
      field: 'name',
      headerName: 'Name',
      minWidth: width > 1400 ? 300 : 220,
      flex: 1,
      renderCell: (cellValues: any) => {
        return (<>
          <Tooltip sx={{ bacgroundColor: 'primary.light', color: 'primary.main' }} title={cellValues?.row?.name} >
            <p>
              {cellValues?.row?.name}
            </p>
          </Tooltip>
        </>)
      }
    },

    {
      field: 'part_no',
      headerName: 'Part No',
      // minWidth: width > 1400 ? 300 : 220,
      flex: 1,
      renderCell: (cellvalues: any) => {
        return (
          <>
            <Typography style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => window.open(`/#/createcostingpart/${cellvalues?.row?.project_id}/${cellvalues?.row?.project_name}/${cellvalues?.row?.topvault_name}/${cellvalues?.row?.topvault_id}/${cellvalues?.row?.vault}/${cellvalues?.row?.abbreviation == null ? "none" : cellvalues?.row?.abbreviation}/0/0/0/0/0/0/0/${tab == 1 ? 0 : 1}/true`)}>
              {cellvalues?.row?.part_no}
            </Typography>
          </>
        );
      }

    },

  ]
  return (
    <div>


      <Modal
        open={isOpen}
        onClose={onCloseModal}
      >
        <Box sx={style}>
          <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: '-2rem'
              }}
            >
              Assumption Info
            </Typography>
            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ height: '65vh', width: '100%' }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3">
            {!isLoading && infoData ?
              <DataGrid
                headerHeight={48}
                rowHeight={36}
                rows={dataGridDataGenerator && dataGridDataGenerator}
                columns={Columns}
                density="compact"
                // autoHeight={true}
                components={{ Toolbar: () => { return CustomToolbar(); } }}
                // hideFooter={true}
                // hideFooterPagination={true}
                sx={{
                  "&.MuiDataGrid-root .MuiDataGrid-cell": {
                    borderBottomColor: "primary.light",
                  },
                  "& ::-webkit-scrollbar": {
                    width: "0.3rem",
                    height: "0.3rem",
                  },
                  "& ::-webkit-scrollbar-thumb": {
                    // background: $thumb-color;
                    backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                    borderRadius: "10px",
                  },
                  "& ::- webkit-scrollbar-thumb: hover ": {
                    // width: "4px",
                    backgroundColor: "#045DE9 !important",
                  },
                  "::-webkit-scrollbar-track": {
                    backgroundColor: "#e1e1f3 !important",
                  },
                }}
              />
              : <Box>
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
                <Skeleton animation="wave" />
              </Box>
            }
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
