
import { isArray } from "lodash";
import { ID } from "../../utlis/commonInterfaces";
import { MODULES } from "../../Constants/modules.constants";
import { API } from "../../api-services";
import { transformConfigurationsFromApi } from "../../utlis/configuration.constants";
import { ParameterConfig } from "../Reducers/parameterConfig.reducer";

const url = "/config/parameter_mapping/";

export default {
    /**
     * @param projectId
     * @param all boolean - get all the configs irrespective of table_id
     */
    get ( projectId: ID, categoryId: ID | ID[], all?: boolean ) {
        return API.get( url, {
            project: projectId,
            category: isArray( categoryId ) ? undefined : categoryId,
            categories: isArray( categoryId ) ? categoryId.join() : undefined,
            module: MODULES.FEATURE_CONFIGURATION,
            all: all || undefined,
        } );
    },
    /**
     * @param projectId
     * @param all boolean - get all the configs irrespective of table_id
     */
    getByProjectId ( projectId: ID, all?: boolean ) {
        return API.get( url, {
            project: projectId,
            module: MODULES.FEATURE_CONFIGURATION,
            all,
        }, 0 );
    },
    getById ( parameterId: ID ) {
        return API.get( url, { id: parameterId }, 0 );
    },
    getByTableId ( tableId: number ) {
        return API.get( url, { table_id: tableId }, 0 );
    },
    async inherit (
        parameters: Record<string, any>[],
        meta?: Record<string, any>
    ) {
        const { data, ...rest } = await API.post(
            url,
            Object.assign(
                { parameters, module: MODULES.FEATURE_CONFIGURATION },
                meta
            )
        );
        return { data: transformConfigurationsFromApi( data ), ...rest };
    },
    update ( configId: ID, updates: Partial<ParameterConfig> ) {
        return API.put( url + configId + "/", updates );
    },
    delete ( configId: ID ) {
        return API.delete( url + configId + "/" );
    },
};
