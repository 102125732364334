import React, { useEffect, useState } from "react";

import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  DataGrid,
  GridColDef,
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridToolbar,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExportContainer,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import { Autocomplete, Divider, Skeleton, TextField, Tooltip } from "@mui/material";
import { API } from "../../../api-services";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import LoadingButton from "@mui/lab/LoadingButton";
import useWindowDimensions from "../../../ui-reusable-component/useWindowDimensions";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "60vw",
  maxWidth: "80vw",
  // height: '80vh',
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};
const csvOptions: GridCsvExportOptions = {
  fileName: "Table In CSV",
  delimiter: ",",
  utf8WithBom: true,
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer style={{ height: "2rem", marginBottom: "0.5rem", }}>
    <span style={{ position: "absolute", right: "0rem" }}>     
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);
interface AddMachineListModalProps {
  onCloseModal: any;
  isOpen: any;
  setCounter: any;
  expanded: any;


}




const FetchLabourListModal = (props: AddMachineListModalProps) => {
  const { onCloseModal, isOpen, setCounter, expanded } = props;
  const { height, width } = useWindowDimensions();
  const { revisionId, typeId, databaseName, categoryName } = useRouteParams<any>();

  const [MachineList, setMachineList] = useState<any>([]);
  const [GetSelectedIds, setGetSelectedIds] = useState<any>([]);
  const [Loader, setLoader] = useState<any>(false)
  const [CommodityLoader, setCommodityLoader] = useState<any>(false)
  const [CommodityList, setCommodityList] = useState<any>([])
  const [DataTypeValues, setDataTypeValues] = useState<any>({});
  const [CounterLoader, setCounterLoader] = useState<any>(0)
  const handleSelectdatatype = (item: any) => {
    setDataTypeValues(item);
    setLoader(true)
    setMachineList([])
    API.get("/api/db/labour/", { commodity: item?.id, revision: revisionId,fetch:true })
      .then((res: any) => {
        setLoader(false)
        setMachineList(res?.data);
      })
      .catch((err: any) => {
        setLoader(false)
        console.log("Srver Error");
      });

  };




  useEffect(() => {

    setMachineList([])

    setCommodityLoader(true)

    API.get("/api/db/commodity/", { labour_commodity: true }).then((res: any) => {

      var data = res?.data?.filter((item: any, index: any) => { return item?.name !== "UnCategorised" })

      setCommodityList(data)

      setCommodityLoader(false)

      if (categoryName == 0) {




        var FilterData = res?.data?.filter((item: any, index: any) => { return item?.id == expanded })

        var filterdByname=FilterData?.filter((item: any, index: any)=>{return item?.name !== "UnCategorised"} )[0]




        if(filterdByname==undefined){

          setDataTypeValues(res?.data[0])



          API.get("/api/db/labour/", { commodity: res?.data[0]?.id, revision: revisionId })
  
            .then((res: any) => {
  
              setMachineList(res?.data);
  
              setLoader(false)
  
            })
  
            .catch((err: any) => {
  
              console.log("Srver Error");
  
              setLoader(false)
  
            });

        }else{

          setLoader(true)

          API.get("/api/db/labour/", { commodity: filterdByname?.id, revision: revisionId })
  
            .then((res: any) => {
  
              setMachineList(res?.data);
  
              setLoader(false)
  
            })
  
            .catch((err: any) => {
  
              console.log("Srver Error");
  
              setLoader(false)
  
            });

          setDataTypeValues(filterdByname)

        }

 

      




      }





      else {

        var FilterData = res?.data?.filter((item: any, index: any) => { return item?.id == categoryName })

        var filterdByname=FilterData?.filter((item: any, index: any)=>{return item?.name !== "UnCategorised"} )[0]




     

        if(filterdByname==undefined){

          setDataTypeValues(res?.data[0])
          setLoader(true)

          API.get("/api/db/labour/", { commodity: res?.data[0]?.id, revision: revisionId })
  
            .then((res: any) => {
  
              setMachineList(res?.data);
  
              setLoader(false)
  
            })
  
            .catch((err: any) => {
  
              console.log("Srver Error");
  
              setLoader(false)
  
            });
          

        }else{
          setLoader(true)

          API.get("/api/db/labour/", { commodity: filterdByname?.id, revision: revisionId })
  
            .then((res: any) => {
  
              setMachineList(res?.data);
  
              setLoader(false)
  
            })
  
            .catch((err: any) => {
  
              console.log("Srver Error");
  
              setLoader(false)
  
            });
          setDataTypeValues(filterdByname)

        }

    

       

      }

    }).catch((err: any) => {




      setCommodityLoader(false)

      console.log("Sever Error")

    })




  }, [CounterLoader])




  var Columns: any = [
    {
      field: "labour_code",
      headerName: "Labour Code",
      width: width > 1400 ? 200: 150,
      // flex:1,
    },

    {
      field: "labour_type",
      headerName: "Labour Type",
      width: 150,
    },

    {
      field: "description",
      headerName: "Description",
      width: width > 1400 ? 750 : 400,
      flex:1,

    },
  ];

  const saveHandler = () => {
    setLoader(true)
    API.post("/api/db/labour_cost/", {
      commodity: DataTypeValues && DataTypeValues?.id,
      labour_ids: GetSelectedIds,
      revision: revisionId
    }).then((res: any) => {
      setGetSelectedIds([])
      setCounterLoader((prev: any) => prev + 1)
      setCounter((prev: any) => prev + 1)
      setLoader(false)
    }).catch((err: any) => {
      setLoader(false)
    })


  }


  return (
    <Modal
      open={isOpen}
      onClose={onCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description">
      <Box sx={style}>
        <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
          <Typography
            style={{
              fontSize: "1rem",
              fontWeight: "600",
              width: "100%",
              textAlign: "center",
              marginRight: "-2rem",
            }}>
            Fetch Labour
          </Typography>
          <CancelIcon
            titleAccess="Close"
            sx={{ cursor: "pointer", margin: "0.5rem" }}
            onClick={onCloseModal}
          />
        </Box>
        <Divider
          sx={{
            borderColor: "primary.light",
            margin: "0 1rem",
            marginBottom: "1rem",
          }}
        />



        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: { lg: '89%', xl: '91%' }, marginBottom: '-2rem' }}>
          {CommodityLoader ? <Skeleton sx={{ width: '100%' }}></Skeleton> : <>
            <Autocomplete
              sx={{
                zIndex:1,
                width: '300px',
                '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
                fontSize: '1rem !important',
              }}
              size="small"
              id="combo-box-demo"
              //   disabled={Modaltype == "Edit" || Modaltype == "tableEdit" ? true : false}
              value={DataTypeValues}
              getOptionLabel={(option) =>
                option?.name == undefined ? "" : option?.name
              }
              onChange={(event, newValue, situation) =>
                handleSelectdatatype(newValue)
              }
              options={CommodityList && CommodityList}
              renderOption={(props, option) => (
                <Box component="li" sx={{ fontSize: "1rem" }} {...props}>
                  {option?.name}
                </Box>
              )}
              renderInput={(params) => (
                <TextField
                  variant="standard"
                  sx={{
                    fontSize: '1rem',
                    borderBottom: "none",
                    ".MuiInputBase-root.MuiInput-root": {
                      fontSize: '1rem !important'
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "primary.main",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "primary.main",
                    },
                  }}
                  {...params}
                  placeholder="Category Name"
                />
              )}
            />
            <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
              <Typography sx={{ fontSize: '1rem' }}>Selected Item : {GetSelectedIds?.length}</Typography>
              <LoadingButton variant="contained" size="small" loading={Loader} onClick={() => saveHandler()} sx={{
                padding: "0.5rem",
                lineHeight: 1,
                zIndex: 1,
                "&:hover": {
                  transform: 'Scale(1.05)',
                  transition: 'transform 0.5s ease',
                },
                '.MuiCircularProgress-root': {
                  color: 'primary.main',
                },
                background: GetSelectedIds?.length > 0 ? "#d32f2f" : ""
              }} disabled={GetSelectedIds?.length > 0 ? false : true}>
                Add
              </LoadingButton>

            </Box>
          </>
          }
        </Box>


        <Box
          sx={{ height: "65vh", width: "100%" }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.4">
          {DataTypeValues && Object.keys(DataTypeValues) && (
            <DataGrid
              loading={Loader}
              headerHeight={48}
              rowHeight={36}
              rows={MachineList && MachineList}
              columns={Columns}
              density="compact"
              // autoHeight={true}
              checkboxSelection
              components={{
                Toolbar: () => {
                  return CustomToolbar();
                },
              }}
              onSelectionModelChange={(ids) => {
                const selectedIDs = new Set(ids);
                setGetSelectedIds(ids);
              }}

              sx={{
                "& .MuiDataGrid-checkboxInput": {
                  color: "primary.main",

                },
                "& ::-webkit-scrollbar": {
                  width: "0.4rem",
                  height: "0.4rem",
                },
                "& ::-webkit-scrollbar-thumb": {
                  // background: $thumb-color;
                  backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                  borderRadius: "10px",
                },
                "& ::- webkit-scrollbar-thumb: hover ": {
                  // width: "4px",
                  backgroundColor: "#045DE9 !important",
                },
                "::-webkit-scrollbar-track": {
                  backgroundColor: "#e1e1f3 !important",
                },
                "&.MuiDataGrid-root": {
                  border: 'none',
                },
                "&.MuiDataGrid-root .MuiDataGrid-cell": {
                  borderBottomColor: "primary.light",
                },
              }}
            />
          )}
        </Box>







      </Box>
    </Modal>
  );
};

export default FetchLabourListModal;
