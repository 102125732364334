import React from 'react';
import { TableCell, TableRow } from '@mui/material';

interface BannerEIRWorkshopPageBodyRowI{
    lineItem:any;
    indexIncoming:any;
    ViewPdfRedirect:any;
}

const BannerEIRWorkshopPageBodyRow:React.FC<BannerEIRWorkshopPageBodyRowI>=(props)=>{
  const {lineItem,indexIncoming,ViewPdfRedirect} = props;

return(
    <TableRow>
        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{indexIncoming}</TableCell>
        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{lineItem?.subsystem_name}</TableCell>
        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{lineItem?.abbreviation}</TableCell>
        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{lineItem?.total_file}</TableCell>
        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{lineItem?.file_paths_read?.length<1? 0 :lineItem?.file_paths_read?.map((item:any,index:number)=>{
          return <span title={item?.file_name} style={{cursor:'pointer', textDecoration:'underline', marginRight:'0.5rem'}} onClick={()=>ViewPdfRedirect(lineItem?.subsystem_name,index+1)}>{index+1}</span>
        })}
        </TableCell>
        <TableCell sx={{ borderBottomColor: 'primary.light', padding: '0.2rem 0.5rem' }}>{lineItem?.total_file_size} MB</TableCell>
    </TableRow>)
}

export default BannerEIRWorkshopPageBodyRow;