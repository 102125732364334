import swal from "sweetalert";

const deleteSwal = (title: string) =>
    swal({
        title: title,
        text: "Are you sure?",
        buttons: ["Cancel", "Yes, Delete"],
        icon: "error",
    });

export { deleteSwal };
