import { Box, Skeleton } from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import UploadIcon from "@mui/icons-material/Upload";
import DeleteIcon from "@mui/icons-material/Delete";
import CameraAltRoundedIcon from "@mui/icons-material/CameraAltRounded";
import StraightenIcon from "@mui/icons-material/Straighten";
import styles from "./IdeagenImagesSection.module.scss";
import RViewerJS from "viewerjs-react";
import swal from "sweetalert";
import { AxiosError, AxiosResponse } from "axios";
import { LoadingButton } from "@mui/lab";
import { API } from "../../../../api-services";
import { useRouteParams } from "../../../../ui-reusable-component/useRouteParams";
// import IdeagenImageSingleUpload from '../../IdeagenImageSingleUpload';
import { useBaseModal } from "../../../SearchPage/useBaseModal";
import ArchImageEditModal from "../../../ArchitectureCreate/ArchImageEditModal";
import { IdeagenImageFetchModel } from "./IdeagenImageFetchModel";
import IdeagenImageSingleUpload from "./IdeagenImageSingleUpload";
import { ADMIN } from "../../../../Redux/Services/admin.service";
import { TabParametershowCheckBox } from "../IdeaTabData/IdeaTabInputComponent/TabParametershowCheckBox";
import { UserAction } from "../IdeaGenerationViewLanding";
import { rejectedStatus } from "../IdeaViewpage";

interface IIdeagenImagesSectionProps {
  itemIncoming: any;
  apiCallVaultData: any;
  setApiCallVaultData: any;
  actions: any;
  status: any;
}

interface Params {
  projectId: any;
  projectName: any;
  topVault: any;
  vault: any;
  treeabbr: any;
  workstation: any;
  isLeaf: any;
  copyStatus: any;
}

const IdeagenImagesSection: React.FC<IIdeagenImagesSectionProps> = (props) => {
  const { itemIncoming, apiCallVaultData, setApiCallVaultData, actions, status } =
    props;
  const [isShowCheckBox, setIsShowCheckbox] = React.useState<boolean>(false);
  const {
    projectId,
    projectName,
    topVault,
    vault,
    treeabbr,
    workstation,
    isLeaf,
    copyStatus,
    vault_id,
  } = useRouteParams<any>();
  const userAction = React.useContext<any>(UserAction);
  let UserActionParams = userAction && userAction?.action;
  const rejectedAction = React.useContext<any>(rejectedStatus);
  let rejectedStatusActionParams = rejectedAction && rejectedAction;
  const [deleteLoading, setDeleteLoading] = React.useState<any>(false);
  const getCheckBoxStatus = () => {
    API.get(
      "/idea/pool/",
      {
        idea_id: vault_id,
      },
      0
    ).then((res: any) => {
      setIsShowCheckbox(res?.data?.is_image);
    });
  };

  React.useEffect(() => {
    if (vault_id != 0) {
      getCheckBoxStatus();
    }
  }, [vault_id]);
  const [id, setIsID] = React.useState<number>(0);
  const [isUpdating, setIsUpdating] = React.useState<any>(false);
  const [isUpdatingIconName, setIsUpdatingIconName] = React.useState<any>("");
  const [loaderForFetch, setLoaderForFetch] = React.useState<any>(false);
  const ImageEditor = useBaseModal();
  const createBOMFetch = useBaseModal();

  const inputElRef = useRef<any>();
  const inputElRefPhone = useRef<any>();

  //-------------------image upload--------------------------------------------------------

  const saveImage = async (file: File, id: number) => {
    setIsUpdating(true);
    if (id == 1) {
      IdeagenImageSingleUpload(
        [file],
        "Idea",
        itemIncoming?.key,
        topVault,
        vault,
        setApiCallVaultData,
        setIsUpdating,
        vault_id,
        "image"
      );
    } else {
      const data = new FormData();
      data.append(itemIncoming?.key, file);
      API.put(`/idea/pool/${vault_id}/`, data, { image: true, rgb: true }, 0)
        .then((res: AxiosResponse) => {
          setApiCallVaultData(!apiCallVaultData);
          setIsUpdating(false);
        })
        .catch((err: AxiosError) => {});
    }
  };

  // --------------------------------- image editor -----------------------------------------------------
  const imageForApp = itemIncoming?.["s3_url"]?.replaceAll(
    "prod.xcpep.com/",
    ""
  );
  const relImageURL =
    itemIncoming?.["s3_url"]?.includes("prod.xcpep.com/") === false
      ? itemIncoming?.["s3_url"]
          ?.split("?")[0]
          ?.split(".com")[1]
          ?.replaceAll("%20", " ")
          ?.replaceAll("%28", "(")
          ?.replaceAll("%29", ")")
      : imageForApp
          ?.split("?")[0]
          ?.split(".com")[1]
          ?.replaceAll("%20", " ")
          ?.replaceAll("%28", "(")
          ?.replaceAll("%29", ")");

  const updateEditedImage = async (file: File) => {
    saveImage(file, 1);
  };

  const imageEditorHandler = () => {
    ImageEditor.open();
  };

  // -----------------------------------------------------------------------------------------------------

  const browseFilesPhone = (idGet: any, iconName: string) => {
    setIsID(idGet);
    setIsUpdatingIconName(iconName);
    return inputElRefPhone.current?.click?.();
  };

  const handleChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file: any = e.target.files?.[0];
    saveImage(file, id);
  };

  const handleInputClickPhone = (e: React.MouseEvent) => {
    // clear the prev value on click so that same image can be uploaded again
    (e.target as HTMLInputElement).value = "";
  };

  // -------------------------------------------------------------------------------------------------------

  const browseFiles = (idGet: any, iconName: string) => {
    setIsID(idGet);
    setIsUpdatingIconName(iconName);
    return inputElRef.current?.click?.();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file: any = e.target.files?.[0];
    saveImage(file, id);
    // setDisable( false );
  };

  const handleInputClick = (e: React.MouseEvent) => {
    // clear the prev value on click so that same image can be uploaded again
    (e.target as HTMLInputElement).value = "";
  };

  //----------------------------------------------------------------------------------------------------------------

  const handleDeleteSelectedImages = async (keyId: any) => {
    // e.stopPropagation();
    swal({
      title: `Delete Image Parameter`,
      text: `Are you sure?`,
      icon: "error",
      //@ts-ignore
      buttons: {
        cancel: "Cancel",
        permanent: {
          text: `Delete Image`,
          value: `deleteImages`,
        },
      },
      closeOnClickOutside: false,
      closeOnEsc: false,
      className: "delete-swal",
    }).then(async (type: string) => {
      switch (type) {
        case "deleteImages":
          setDeleteLoading(true);
          API.put(
            `/idea/pool/${vault_id}/`,
            { [keyId]: "null" },
            {
              image: true,
              rgb: false,
            },
            0
          )
            .then((res: AxiosResponse) => {
              setApiCallVaultData(!apiCallVaultData);
              setDeleteLoading(false);
              ADMIN.toast.info(`Image deleted successfully`);
            })
            .catch((err: AxiosError) => {});
          break;
        default:
          swal(`Image saved from deleting`, { icon: "error" });
      }
    });
  };

  const handleFetchImage = (urlFetchedImage: any) => {
    setLoaderForFetch(true);
    let abc = 0;
    const data = new FormData();
    data.append(
      itemIncoming?.key,
      urlFetchedImage?.current[abc]?.substring(
        urlFetchedImage?.current[abc]?.indexOf(".net") + 4
      )
    );
    API.put(
      `/xcpep/component_details/${itemIncoming?.component_id}/`,
      data,
      {
        vault: vault == "0" ? null : vault,
        top_vault: vault == "0" ? topVault : null,
        image: true,
        rgb: true,
      },
      0
    )
      .then((res: AxiosResponse) => {
        setLoaderForFetch(false);
        setApiCallVaultData(!apiCallVaultData);
      })
      .catch((err: AxiosError) => {});
  };

  return (
    <>
      {createBOMFetch.isOpen && (
        <IdeagenImageFetchModel
          onCloseModal={createBOMFetch.close}
          isOpen={createBOMFetch.isOpen}
          handleFetchImage={handleFetchImage}
          loaderForFetch={loaderForFetch}
        />
      )}

      {ImageEditor?.isOpen && (
        <ArchImageEditModal
          Loader={isUpdating}
          open={ImageEditor.isOpen}
          imageURI={relImageURL}
          closeModal={ImageEditor.close}
          updateImage={updateEditedImage}
        />
      )}

      <Box className={styles.bomgridContainer}>
        {itemIncoming?.value == "" ? (
          <div className={styles.bomDivNoImage}>
            {isUpdating ? (
              <Skeleton sx={{ height: "100%", width: "100%" }} />
            ) : (
              <span
                onClick={() =>
                  actions?.includes("C") &&
                  browseFiles(1, "OriginalWithBackgroudGallery")
                }
              >
                {itemIncoming?.paramter_name}
              </span>
            )}
          </div>
        ) : isUpdating ? (
          <Skeleton sx={{ height: "100%", width: "100%" }} />
        ) : (
          <img
            title={itemIncoming?.paramter_name}
            src={`${itemIncoming?.cdn + itemIncoming?.value}`}
            className={styles.bomgridImages}
            alt={itemIncoming?.paramter_name}
          />
        )}
        {status != 2 && <Box className={styles.imageOptions} sx={{ margin: "1rem 0" }}>
          <Box className={styles.optionDiv}>
            <LoadingButton
              style={{ padding: 0, minWidth: "2rem" }}
              onClick={() => browseFiles(1, "OriginalWithBackgroudGallery")}
              loading={
                isUpdatingIconName == "OriginalWithBackgroudGallery" &&
                isUpdating
              }
              disabled={
               (!rejectedStatusActionParams &&  UserActionParams?.includes("U") && itemIncoming?.editable)
                  ? false
                  : true
              }
            >
              <UploadIcon
                titleAccess="Upload Original Background"
                sx={{ cursor: "pointer" }}
              />
            </LoadingButton>
            <LoadingButton
              style={{ padding: 0, minWidth: "2rem" }}
              onClick={() => browseFiles(2, "OriginalWithoutBackgroudGallery")}
              loading={
                isUpdatingIconName == "OriginalWithoutBackgroudGallery" &&
                isUpdating
              }
              disabled={
                (!rejectedStatusActionParams && UserActionParams?.includes("U") && itemIncoming?.editable)
                  ? false
                  : true
              }
            >
              <UploadIcon
                titleAccess="Upload without Background"
                sx={{ cursor: "pointer", color: "#999191" }}
              />
            </LoadingButton>
            {itemIncoming?.value !== "" && <LoadingButton
              style={{ padding: 0, minWidth: "2rem" }}
              onClick={() => handleDeleteSelectedImages(itemIncoming?.key)}
              loading={deleteLoading}
              disabled={(!rejectedStatusActionParams && UserActionParams?.includes("D") && status != 2) ? false : true}
            >
              <DeleteIcon titleAccess="Delete" sx={{ cursor: "pointer" }} />
            </LoadingButton>}
            {itemIncoming?.value != "" &&
              (itemIncoming?.parameter_clicked || isShowCheckBox === false) && (
                <TabParametershowCheckBox
                  checkedValue={itemIncoming?.parameter_clicked}
                  item={itemIncoming}
                  type={"media"}
                  apiCallVaultData={setApiCallVaultData}
                  getCheckBoxStatus={getCheckBoxStatus}
                  status={status}
                />
              )}
          </Box>
        </Box>}
        <input
          type="file"
          style={{ display: "none" }}
          // required
          accept="image/*"
          id="file"
          name="file"
          ref={inputElRefPhone}
          onChange={handleChangePhone}
          onClick={handleInputClickPhone}
          capture="user"
        />

        <input
          type="file"
          style={{ display: "none" }}
          // required
          accept="image/*"
          id="file"
          name="file"
          ref={inputElRef}
          onChange={handleChange}
          onClick={handleInputClick}
        />
      </Box>
    </>
  );
};

export default IdeagenImagesSection;
