

// ----------------------------------------------------------------------------------

import { CopyCalculatorArg, CreateCalculatorArg, DeleteCalculatorArg, GetCalculatorByIdArg, GetCalculatorsArg, UpdateCalculatorArg } from "../Actions/calculator.actions";
import { API } from "./index.service";

const url = "/cost/calculator/";

const triangulation = "/cost/triangulation_calculator/";
const Ideaaction="/idea/idea_calculator/";
const BOMaction="/xcpep/bom_entry_calculator/"

// ----------------------------------------------------------------------------------

export default {
    get ( arg: GetCalculatorsArg ) {
        return API.get( url, {
            process: arg.processId,
            commodity_mapping: arg.commodityMappingId,
            category: arg.calculatorCategory,
            object_id: arg.object_id
        } );
    },
    getById ( arg: GetCalculatorByIdArg ) {
        return API.get( url, arg );
    },

    getByIdTriangulation ( topVault: any, name: any ) {
        return API.get( triangulation, { top_vault: topVault, name: name } );
    },
    getByIdeadCalcultorIds ( id: any ) {
        return API.get( Ideaaction, { id: id} );
    },

    getByBOMCalcultorIds ( vault:any, id: any, top_vault:any ) {
        return API.get( BOMaction, { vault: vault != 0 ?vault:undefined, top_vault: vault == 0 ? top_vault : undefined, calculation_id:id} );
    },

    create ( arg: CreateCalculatorArg ) {
        return API.post( url, arg );
    },
    update ( arg: UpdateCalculatorArg ) {
        return API.put( url + arg.id + "/", arg.updates );
    },

    updateTiangulation ( arg: UpdateCalculatorArg ) {
        return API.patch( triangulation + arg.id + "/", arg.updates );
    },

    delete ( arg: DeleteCalculatorArg ) {
        return API.delete( url + arg.id + "/" );
    },
    copy ( arg: CopyCalculatorArg ) {
        return API.post( "cost/copy_calculator/", {
            id: arg.calculatorId,
            name: arg.name
        }, {}, 0 );
    }
};
