import { useState } from "react";

// ----------------------------------------------------------------------------------

const useBomCategoryUse = () => {
    const [isOpen, setIsOpen] = useState( false );
    const [id,setId] = useState<any>();

    const handleOpen = (id:any) => {
        setIsOpen( true );
        setId(id);
    }
    const handleClose = () => {
        setIsOpen( false );
        setId(undefined);
    }

    return Object.freeze( {
        open: handleOpen,
        close: handleClose,
        isOpen,
        id
    } );
};


// ----------------------------------------------------------------------------------

export { useBomCategoryUse };