import {
    createEntityAdapter,
    createReducer,
    createSelector,
    EntityState,
} from "@reduxjs/toolkit";
import { ID } from "../../utlis/commonInterfaces";
import { getCompetitorVaults, getSubassembies, resetVaults, setVaults } from "../Actions/vault.actions";
import { ADMIN } from "../Services/admin.service";
import { RootState } from "../Store/storeConfigurations";

// -----------------------------------------------------------------------------------
// entity adaptor

export interface Vault {
    id: ID;
    name: string;
    part_no: string;
    parent_name: string;
    parent: ID;
    status: number;
    category: ID;
    quantity: number;
    top_vault: ID;
    abbreviation: string | null;
    copy_info: any;
    qr_code: string | null;
    commodity: ID[];
    weight_validation: number;
    level: number;
    cost_status: number;
    child_count: any;
    weight_lock: boolean,
    weight: string;
    is_leaf: boolean | null,
    allowed_actions: any;
}
const vaultAdaptor = createEntityAdapter<Vault>( {
    selectId: vault => vault.id,
    // sortComparer: ( a, b ) => vault
} );

// -----------------------------------------------------------------------------------
// interfaces & initialState

type AdditionalFields = {};

type IVaultReducer = EntityState<Vault> & AdditionalFields;

const additionalFields: AdditionalFields = {};

const initialState: IVaultReducer = Object.assign(
    {},
    vaultAdaptor.getInitialState(),
    additionalFields
);

// -----------------------------------------------------------------------------------
// reducer

const VaultReducer = createReducer( initialState, builder => {
    // get subassemblies
    builder
        .addCase( getSubassembies.fulfilled, ( state, action: any ) => {
            vaultAdaptor.setAll( state, action.payload.vaults );
        } )
        .addCase( getSubassembies.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError(
            //     action.payload,
            //     "Could not fetch subassemblies"
            // );
        } );

    builder
        .addCase( getCompetitorVaults.fulfilled, ( state, action: any ) => {
            vaultAdaptor.setAll( state, action.payload.vaults );
        } )
        .addCase( getCompetitorVaults.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError(
            //     action.payload,
            //     "Could not fetch subassemblies"
            // );
        } );

    // set
    builder.addCase( setVaults, vaultAdaptor.setAll );

    // reset reducer
    builder.addCase( resetVaults, () => initialState );
} );

// -----------------------------------------------------------------------------------
// selectors

const selectors = vaultAdaptor.getSelectors<RootState>( state => state.vaults );

const selectByTopVaultId = createSelector(
    selectors.selectAll,
    ( _state: RootState, topVaultId: ID ) => topVaultId,
    ( vaults, topVaultId ) => {
        return vaults.filter( vault => vault.top_vault === topVaultId );
    }
);

const selectByNonTopVaultId = createSelector(
    selectors.selectAll,
    ( _state: RootState, topVaultId: ID ) => topVaultId,
    ( vaults, topVaultId ) => {
        return vaults.filter( vault => vault.top_vault !== topVaultId );
    }
);

const selectIdsByTopVaultId = createSelector( selectByTopVaultId, vaults => {
    return vaults.map( vault => vault.id );
} );

// const selectAllTopVaultId = createSelector( selectors.selectAll, vaults => {
//      vaults.map( vault => return{
//         key: vault.part_no,
//         value:vault.id,
//         text:vault.name
//     });
// } );

const selectAllTopVaultId = createSelector( selectors.selectAll, vaults => {
    return vaults.map( vault => ( {
        key: vault.part_no,
        value: vault.id,
        text: vault.name
    } ) );
} );

const selectAllOption= createSelector( selectors.selectAll, vaults => {
    return vaults.map( vault => ( {
        key: vault.part_no,
        value: vault.abbreviation as string,
        text: vault.name
    } ) );
} );


const vaultSelectors = Object.assign(
    {},
    {
        selectByTopVaultId,
        selectIdsByTopVaultId,
        selectByNonTopVaultId,
        selectAllTopVaultId,
        selectAllOption
    },
    selectors
);

// -----------------------------------------------------------------------------------
// exports

export { VaultReducer, initialState as VaultReducerInit, vaultSelectors };
