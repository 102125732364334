// react
import { useEffect, useReducer } from "react";

// vendors
import { createEntityAdapter, createSlice, Dictionary, PayloadAction, SliceCaseReducers, Update } from "@reduxjs/toolkit";
import { omit } from "lodash";

// ----------------------------------------------------------------------------------
// typings

type ID = React.ReactText;

interface Base {
    id: ID;
    [x: string]: any;
}

// ----------------------------------------------------------------------------------
// helpers

const adaptor = createEntityAdapter<Base>( {
    selectId: item => item.id,
} );

const selectors = adaptor.getSelectors();

// ----------------------------------------------------------------------------------
// silce

const reducers = omit( adaptor, [
    "getInitialState",
    "selectId",
    "sortComparer",
    "getSelectors",
] );

const additionalReducers: SliceCaseReducers<any> = {
    overlapOne: ( state, action: PayloadAction<{ overlapId: ID, entity: Base; }> ) => {
        const { entity, overlapId } = action.payload;

        adaptor.removeOne( state, overlapId );
        adaptor.addOne( state, entity );
    }
};

const { actions, reducer } = createSlice( {
    name: "Collection",
    initialState: adaptor.getInitialState(),
    reducers: Object.assign( reducers, additionalReducers ),
} );

// ----------------------------------------------------------------------------------
// hook definition

function useCollection<O extends Base> ( initialState?: O[] ) {
    // ----------------------------------------------------------------------------------
    // state

    const [state, dispatch] = useReducer( reducer, adaptor.getInitialState() );

    // ----------------------------------------------------------------------------------
    // side effects

    // initialize on mount
    useEffect( () => {
        if ( initialState ) dispatch( actions.setAll( initialState ) );
    }, [] );

    // ----------------------------------------------------------------------------------
    // return object with methods to change/get state

    /** object with methods to change/get state */
    return Object.freeze( {
        length: selectors.selectIds( state ).length,
        value: state,
        getCollection: () => selectors.selectAll( state ) as O[],
        getEntities: () => selectors.selectEntities( state ) as Dictionary<O>,
        getById: ( id: ID ) => selectors.selectById( state, id ) as O | undefined,
        getIds: () => selectors.selectIds( state ),
        setAll: ( arg: O[] | Record<ID, O> ) => dispatch( actions.setAll( arg ) ),
        addOne: ( arg: O ) => dispatch( actions.addOne( arg ) ),
        addMany: ( arg: O[] ) => dispatch( actions.addMany( arg ) ),
        updateMany: ( arg: Update<O>[] ) => dispatch( actions.updateMany( arg ) ),
        updateOne: ( arg: Update<O> ) => dispatch( actions.updateOne( arg ) ),
        upsertOne: ( arg: O ) => dispatch( actions.upsertOne( arg ) ),
        upsertMany: ( arg: O[] | Record<ID, O> ) => dispatch( actions.upsertMany( arg ) ),
        removeOne: ( id: ID ) => dispatch( actions.removeOne( id ) ),
        removeMany: ( ids: ID[] ) => dispatch( actions.removeMany( ids ) ),
        removeAll: () => dispatch( actions.removeAll() ),
        overlapOne: ( overlapId: ID, entity: O ) => dispatch( actions.overlapOne( { overlapId, entity } ) )
    } );
}

// ----------------------------------------------------------------------------------
// exports

export {
    useCollection
};
