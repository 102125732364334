import React, { useState, useRef } from "react";
import {
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    OutlinedInput,
    TextField,
    Theme,
    useTheme,
    Divider,
    Typography,
    Skeleton,
    Button,
    Autocomplete,
    CircularProgress,
  } from "@mui/material";
  import Modal from "@mui/material/Modal";
  import { makeStyles } from "@mui/styles";
  import CancelIcon from "@mui/icons-material/Cancel";
  import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import DownloadIcon from '@mui/icons-material/Download';
import UploadIcon from '@mui/icons-material/Upload';
import { IconButton } from '@mui/material';
import { set } from "lodash";
import { API } from "../../api-services";
import InfoIcon from '@mui/icons-material/Info';
import { Info } from "@mui/icons-material";
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';
import { styled as newstyle } from '@mui/material/styles';


  export interface IReverseUploadModalProps {
    isOpen: any;
    onCloseModal: any;
    topVaultSelected: any;
  }
  
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 200,
        borderBottomColor: "primary.light",
        "& .MuiInput-underline:before": { borderBottomColor: "#007fff" },
        "& .MuiInput-underline:after": { borderBottomColor: "#007fff" },
      },
    },
  };
  
  function getStyles(name: string, countryName: string, theme: Theme) {
    return {
      fontWeight:
        countryName.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    };
  }
  
  
  
  const styled = {
    boxSize: {
      position: "absolute" as "absolute",
      top: "50%",
      left: "50%",
      transform: "translate(-50%, -50%)",
      width: "33rem",
      height: "fit-content",
      // minHeight: "20rem",
      borderRadius: "0.5rem",
      bgcolor: "background.paper",
      border: "none",
      boxShadow: 24,
      p: 0,
      outline: 0,
      paddingBottom: "1rem",
    },
  
    closedIcon: {
      position: "absolute",
      zIndex: "999",
      color: "#1976d2",
      right: "0.2rem",
      top: "0.5rem",
      cursor: "pointer",
    },
    errormsg: {
      fontSize: "0.75rem",
      color: "#d32f2f",
      height: "1rem",
    },
  };
  
  const useStyles = makeStyles(() => ({
    /** Changed modalStyle */
    ModalRoot: {
      backgroundColor: "rgba(111, 126, 140, 0.2) !important",
      backdropFilter: "blur(1px) !important",
    },
  }));

  const HtmlTooltip = newstyle(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }));
  
  export function ReverseUploadModal(props: IReverseUploadModalProps) {
    const classes = useStyles();
    const theme = useTheme();
    const { isOpen, onCloseModal, topVaultSelected } = props;
    const [type1, setType1] = React.useState<boolean>(false);
    const [type2, setType2] = React.useState<boolean>(false);
    const [type3, setType3] = React.useState<boolean>(false);
    const [selectedOption, setSelectedOption] = React.useState<number>(0);
    const [loadUpload,setLoadUpload] = useState<boolean>(false);

    const inputElRefExcel = useRef<any>();
    
    const handleDownload = (type:number) => {
      window.open(
        API.apiBase +
          `xcpep/hierarchy_reverse_template/?type=${type}&top_vault=${topVaultSelected}`,
        "_blank",
        "noopener noreferrer");
      
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, index: number) => {
        console.log(event.target.checked)
        console.log(index)
        if(!event.target.checked){
            setType1(false);
            setType2(false);
            setType3(false);
            setSelectedOption(0);
        }
        else{
            if(index==1){
                setType1(!event.target.checked);
                setType2(true);
                setType3(true);
                setSelectedOption(1);
            }
            else if(index==2){
                setType2(!event.target.checked);
                setType1(true);
                setType3(true);
                setSelectedOption(2);
            }
            else if(index==3){
                setType3(!event.target.checked);
                setType1(true);
                setType2(true);
                setSelectedOption(3);
            }
        }
        
      }

      const onCancelClick = (e:any) => {
        e.stopPropagation();
        onCloseModal();
      }

      const UploadExcel = (e: any) => {
        setLoadUpload(true);
        const data = new FormData();
        data.append('file',e?.target?.files[0]);
        data.append('type',selectedOption.toString());
        data.append('top_vault',topVaultSelected);
        API.post(`/xcpep/legacy_data_import/`,data,{}, 0).then((res:any)=>{
          
          setLoadUpload(false);
          window.location.reload();
        }).catch((err:any)=>{
          console.log(err,"error")
        })
        
    }

    const handleUpload = (e:any) => {
        e.stopPropagation();
        inputElRefExcel.current.click();
    }
  
    return (
      <>
        <Modal // autoFocusdal
          open={isOpen}
          className={classes.ModalRoot}
          onClose={onCloseModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
            <Box sx={styled.boxSize}>
          <Box sx={{
                // marginTop: "1rem",
                alignItem: "center",
                justifyContent: "center",
                //   display: "flex",
                //   flexDirection: "column",
              }}>
                <Box
                sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}
              >
                <Typography
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    width: "100%",
                    textAlign: "center",
                    marginRight: "-2rem",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  Reverse Upload
                  <HtmlTooltip
        title={
          <React.Fragment>
            <Typography color="#007fff">Reverse Upload</Typography>
            <Typography  sx={{fontSize:"0.8rem", color:"#007fff "}}>{`1- In our system, the part number is dynamically generated based on the specified level. It is crucial to ensure accurate data entry in the Level column for each entry.` }<br />{`
2- Please note that if a category is not explicitly added during data entry, our system will automatically consider a default category.`}</Typography>
          </React.Fragment>
        }
        placement="right-end"
      >
        <Button><InfoIcon /></Button>
      </HtmlTooltip>
                </Typography>
                
                  <CancelIcon
                    titleAccess="Close"
                    sx={{ cursor: "pointer", margin: "0.5rem" }}
                    onClick={(e:any)=>onCancelClick(e)}
                  />
                
              </Box>
              <Divider
                sx={{
                  borderColor: "primary.light",
                  margin: "0 1rem",
                  marginBottom: "1rem",
                }}
              />
              <Box sx={{margin:"0.5rem 1rem"}}>
              <FormGroup>
                <Box sx={{display:"flex", gap:"1rem", alignItems:"center"}}>
                <FormControlLabel control={<Checkbox onChange={(e:any) => handleChange(e, 1)} disabled={type1} />} label="Hierarchy with Category" />
                {selectedOption == 1 && <Box sx={{display:"flex", gap:"0.5rem"}}>
                    <IconButton  onClick={() => handleDownload(1)}><DownloadIcon sx={{color: "primary.main"}}/></IconButton>
                    {loadUpload ? <CircularProgress size={20} sx={{alignItems:"center", marginTop:"0.4rem"}} /> : <IconButton onClick={(e:any) => handleUpload(e)} ><UploadIcon sx={{color: "primary.main"}}/></IconButton>}
                    
                    </Box>}
             </Box>
                <Box sx={{display:"flex", gap:"1rem", alignItems:"center"}}>
                <FormControlLabel control={<Checkbox onChange={(e:any) => handleChange(e, 2)} disabled={type2} />} label="Hierarchy without Category" />
                {selectedOption == 2 && <Box sx={{display:"flex", gap:"0.5rem"}}><IconButton  onClick={() => handleDownload(2)}><DownloadIcon sx={{color: "primary.main"}} /></IconButton>
                    {loadUpload ? <CircularProgress size={20} sx={{alignItems:"center", marginTop:"0.4rem"}} /> : <IconButton onClick={(e:any) => handleUpload(e)} ><UploadIcon sx={{color: "primary.main"}} /></IconButton>}</Box>}
                </Box>
                <Box sx={{display:"flex", gap:"1rem", alignItems:"center"}}>
                <FormControlLabel control={<Checkbox onChange={(e:any) => handleChange(e, 3)} disabled={type3}/>} label="Data with Category" />
                {selectedOption == 3 && <Box sx={{display:"flex", gap:"0.5rem"}}><IconButton  onClick={() => handleDownload(3)}><DownloadIcon sx={{color: "primary.main"}} /></IconButton>
                    {loadUpload ? <CircularProgress size={20} sx={{alignItems:"center", marginTop:"0.4rem"}} /> : <IconButton onClick={(e:any) => handleUpload(e)}><UploadIcon sx={{color: "primary.main"}} /></IconButton>}</Box>}
                </Box>
                <input
                accept=".xlsx, .xls"
                id="Excel Upload"
                type="file"
                style={{ marginTop:'0.5rem', display:'none' }}
                onChange={(e: any) => UploadExcel(e)}
                ref={inputElRefExcel}
            />
            </FormGroup>
            
              </Box>
              </Box>
          </Box>
        </Modal>
      </>
    );
  }
  