import React, { useEffect, useState } from "react";
import useWindowDimensions from "../../utlis/useWindowDimensions";
import createPlotlyComponent from "react-plotly.js/factory";
import Plotly from "plotly.js";
import { Box, Skeleton } from "@mui/material";

interface CostingAnalysisParetoI {
    dataIncoming: any;
    fullscreen:boolean;
}

const CostingAnalysisPareto: React.FC<CostingAnalysisParetoI> = (props) => {
    const { dataIncoming ,fullscreen} = props;
    const { height, width } = useWindowDimensions();
    const Plot = createPlotlyComponent(Plotly);

    const [cumsum, setCumsum] = useState<any>();
    const [xAxis, SetXaxis] = useState<any>();
    const [yAxis, SetYaxis] = useState<any>();
    const [loader, setLoader] = useState<boolean>(true);
    const [xAxisName,setXAxisName] = useState<any>();

    useEffect(()=>{setCumsum(dataIncoming && dataIncoming?.cumulative_percent);

    const count = dataIncoming && dataIncoming?.pareto_data?.map((counts: any) => {
        return counts?.part_no;
    });

    SetXaxis(count);

    const login_count = dataIncoming && dataIncoming?.pareto_data?.map((counts: any) => {
        return counts.param_value;
    });

    SetYaxis(login_count)
 
    const partName = dataIncoming && dataIncoming?.pareto_data?.map((counts: any) => {
        return counts.part_name;
    });

    setXAxisName(partName);

},[dataIncoming]);


    return (
        <div data-testid="stacked_chart">

            <div>
                {dataIncoming && dataIncoming?.pareto_data?.length>0 ?
                    (
                        <Plot
                            style={fullscreen?{ width: "100rem", height: "52rem" } :{ width: "50rem", height: "26rem" }}
                            data={[
                                {
                                    name: "Module",
                                    type: "bar",
                                    x: xAxis,
                                    y: yAxis,
                                    width: 0.4,
                                    marker: {
                                        color: "#007fff",
                                    },
                                    // @ts-ignore
                                    font: {
                                        size: width <= 1400 && height <= 800 ? 8 : 11,
                                    },
                                    hovertemplate: `<b>%{text} - %{y} ${dataIncoming && dataIncoming?.unit}</b><extra></extra>`,
                                    text: xAxisName,
                                    textposition : "none"
                                },
                                {
                                    name: "Pareto",
                                    type: "scatter",
                                    x: xAxis,
                                    y: cumsum,
                                    yaxis: "y2",
                                    mode: "lines",
                                    line: { shape: "spline" },
                                    marker: {
                                        color: "red",
                                    },
                                    // @ts-ignore
                                    font: {
                                        size: width <= 1400 && height <= 800 ? 8 : 11,
                                    },
                                },
                            ]}
                            layout={{
                                yaxis2: {
                                    side: "right",
                                    range: [0, 110],
                                    overlaying: "y",
                                    ticksuffix: "%",
                                    showgrid: false,
                                    // autorange: true,
                                },
                                height: fullscreen ? height <= 800 ? 230 : 740 : height <= 800 ? 230 : 350,
                                // width: width <= 1400 ? 450 : 600,
                                margin: {
                                    l: width <= 1400 ? 40 : 60,
                                    r: width <= 1400 ? 60 : 80,
                                    b: width <= 1400 ? 20 : 140,
                                    t: 0,
                                },
                                showlegend: false,
                                font: {
                                    // family: 'Courier New, monospace',
                                    size: width <= 1400 && height <= 800 ? 10 : 11,
                                    color: "#007fff",
                                },
                                xaxis: {
                                    showgrid: false,
                                    autorange: true,
                                    rangemode: 'tozero',
                                },
                                yaxis: {
                                    showgrid: false,
                                    autorange: true,
                                    rangemode: 'tozero',
                                    title:{
                                        text:`${dataIncoming && dataIncoming?.pareto_type} (${dataIncoming && dataIncoming?.unit})`
                                    }
                                }
                            }}
                            config={{
                                responsive: true,
                                modeBarButtonsToRemove: [
                                    "zoom2d",
                                    "pan2d",
                                    "select2d",
                                    "lasso2d",
                                    "zoomIn2d",
                                    "zoomOut2d",
                                    "autoScale2d",
                                    "resetScale2d",
                                    "hoverClosestGl2d",
                                    "hoverClosestPie",
                                    "toggleHover",
                                    "resetViews",
                                    "sendDataToCloud",
                                    // @ts-ignore
                                    "resetViewMapbox",
                                    "hoverClosestCartesian",
                                    "hoverCompareCartesian",
                                    "toggleSpikelines",
                                    "toImage",
                                ],
                                displaylogo: false,
                            }}
                        />
                    )
                    :
                    <Box sx={{ width: '100%', height: '30vh' }}>
                        <Skeleton variant="rectangular" sx={{ height: '100%' }} />
                    </Box>
                }
            </div>

        </div>
    );
};

// ----------------------------------------------------------------------------------

export default CostingAnalysisPareto;