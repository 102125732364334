import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { API } from "../../api-services";
import TreeViewComponents from "../TreeViewComponents";
import BomViewData from "./BomViewData";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import styles from "../ViewComponents.module.scss";
import { Box, IconButton, Skeleton, Typography } from "@mui/material";
import LetSuspense from "../../LetSuspense";
import BouncingLoader from "../../ui-reusable-component/BouncingLoader";
import Footer from "../Footer";
import { useDocumentTitle } from "../../ui-reusable-component/useDocumentTitle";
type Props = {};

const BomViewComponents = (props: Props) => {
  const { projectId, topVault, vault, treeabbr, leafNode } = useParams<any>();

  const [selectedData, setSelectedData] = useState<any>();
  useDocumentTitle("BOM View");
  const childRef = React.useRef<any>(null);
  const [loader, setLoading] = useState<any>(false);
  const [AllProjectData, setAllorojectData] = useState<any>();
  const [HierarchyData, setHierarchyData] = useState<any>();
  const [HierarchyAllData, setHierarchyAllData] = React.useState<any>({});
  const [HierarchyActiveId, setHierarchyActiveId] = React.useState<any>(null);
  const [TopLevel, setTopLevel] = React.useState(null);
  const [abbr, setAbbr] = useState<any>();
  const [expendbom, setExpendBom] = useState<any>(false);
  const history = useHistory<any>();
  var togglemodule = sessionStorage.getItem("bomModule");
  useEffect(() => {
    sessionStorage.setItem("historyProject", projectId);
  }, [projectId]);

  useEffect(() => {
    API.get("/customer/dashboard/", {
      get_product_list: projectId,
      module: 10,
    }).then((res: any) => {
      setSelectedData(null);
      setAllorojectData(res?.data[0]);
    });
  }, [projectId]);

  const ClosedExpendHandler = () => {
    // setExpendBom(false)
    // @ts-ignore
    sessionStorage.setItem("bomModule", false);
    history.push(`/view/${projectId}/${topVault}/${vault}/${treeabbr}/false`);
  };

  const ProjectHandler = (ProductID: any, type: any) => {
    sessionStorage.removeItem("node");
    history.push(`/view/${projectId}/${ProductID}/0/product/true`);
  };

  useEffect(() => {
    setLoading(true);
    setHierarchyData(null);
    setHierarchyAllData({})
    API.get(
      "/xcpep/new_system_hierarchy_fetch/",
      {
        top_vault:
          topVault == 0
            ? AllProjectData && AllProjectData?.products[0]?.vehicle_id
            : topVault,
        // hierarchy: true,
      },
      0
    )
      .then((res: any) => {
        setLoading(false);
        setHierarchyData(res?.data?.data);
        setHierarchyAllData(res.data.data);
        if(childRef.current){
          if(vault != 0){
        fetchSequentially();
          }else{
        fetchFirstLevelData();
          }
        }
        // if (topVault == 0) {
        //   setHierarchyActiveId(res.data?.data?.id);
        //   history.push(`/view/${projectId}/${res.data?.data?.id}/0/product/true`);
        // } else {
        //   setHierarchyActiveId(topVault);
        //   history.push(
        //     `/view/${projectId}/${topVault}/${vault}/${treeabbr}/true`
        //   );
        // }
      })
      .catch((err: any) => {
        setLoading(true);
      });
  }, [topVault]);

  useEffect(() => {
    if (topVault != null) {
      let a: any = sessionStorage.getItem("node") && sessionStorage.getItem("node");
      let val: any = a && JSON?.parse(a);
      if (val == null) {
        sessionStorage.setItem("node", JSON.stringify([topVault]));
      }
    }
  }, [topVault]);

  const fetchSequentially = async () => {
    let a: any = sessionStorage.getItem("node");
    let val: any = JSON.parse(a) || [];

    // Check if vault is not 0 and val contains topVault
    if (vault != 0 && val?.includes(topVault?.toString())) {
      await childRef.current?.fetchChildData(topVault, "product");
      // Sequentially fetch data for each item in val
      for (let nodeId of val) {
        if (nodeId && nodeId != topVault) {
          try {
            // Await each fetchChildData call to ensure sequential execution
            await childRef.current?.fetchChildData(nodeId, HierarchyAllData?.abbreviation);
          } catch (error) {
            console.error(`Failed to fetch child data for node ${nodeId}:`, error);
          }
        }
      }
    }
  };
  const fetchSequentiallySearch = async (nodeIds:any) => {
    if (vault != 0 && nodeIds?.includes(topVault?.toString())) {
      await childRef.current?.fetchChildData(topVault, "product");
      // Sequentially fetch data for each item in nodeIds
      for (let nodeId of nodeIds) {
        if (nodeId && nodeId != topVault) {
          try {
            // Await each fetchChildData call to ensure sequential execution
            await childRef.current?.fetchChildData(nodeId, "");
          } catch (error) {
            console.error(`Failed to fetch child data for node ${nodeId}:`, error);
          }
        }
      }
    }
  };
  const fetchFirstLevelData=()=>{
    if(childRef.current && vault ==0){
      childRef.current?.fetchChildData(topVault, "product");
    }
  }
  const fetchFirstLevelDataAll=()=>{
    if(childRef.current){
      childRef.current?.fetchChildData(topVault, "product");
    }
  }

  // useEffect(() => {
  //   ProjectHandler(AllProjectData && AllProjectData?.products[0]?.vehicle_id, "useEffect");
  // }, [AllProjectData]);

  // useEffect(() => {
  //   return () => {
  //     setSelectedData(null);
  //   };
  // }, [projectId]);

  const onSelect = (value: any, abbreviation: any) => {
    // You can put whatever here
    setSelectedData(value);
    setAbbr(abbreviation);
    setLoading(true);
    if (abbreviation == "product") {
      setLoading(false);
      history.push(`/view/${projectId}/${topVault}/0/product/true`);
    } else {
      setLoading(false);
      history.push(
        `/view/${projectId}/${topVault}/${value}/${abbreviation}/true`
      );
    }
  };

  return (
    <div>
      {/* <LetSuspense
            loadingPlaceholder={BouncingLoader}
            condition={!loader}
          > */}

      <div className={styles.view_container}>
        <div className={styles.tab_mainContainer}>
          <Box className={styles.icon_project}>
            <Box sx={{ width: "20rem", display: "flex", alignItems: "center" }}>
              <IconButton
                sx={{ cursor: "pointer" }}
                onClick={() => ClosedExpendHandler()}
              >
                <ArrowBackIcon color="primary" titleAccess="Go Back" />
              </IconButton>
              <span className={styles.productLevel}>
                <Typography style={{ fontSize: "12px" }}>
                  {AllProjectData?.project_name}
                </Typography>
                <Typography
                  style={{ fontSize: "12px" }}
                  className={styles.proddesc}
                >
                  {AllProjectData?.project_description}{" "}
                </Typography>
              </span>
            </Box>
            <div className={styles.tabBox_container}>
              {AllProjectData &&
                AllProjectData?.products?.map(
                  (projectData: any, index: any) => {
                    return (
                      <>
                        <span
                          className={
                            topVault == projectData?.vehicle_id
                              ? styles.Project_chip_active
                              : styles.Project_chip
                          }
                          onClick={() => {
                            ProjectHandler(
                              projectData?.vehicle_id,
                              "onclickHandler"
                            );
                          }}
                        >
                          <Typography
                            style={{ lineHeight: "1.2", fontSize: "12px" }}
                          >
                            {projectData?.prod_id} - {projectData?.vehicle_name}
                          </Typography>
                          {/* <Typography style={{ lineHeight: '1.2', fontSize: '12px' }}>{projectData?.prod_id}</Typography> */}
                        </span>
                      </>
                    );
                  }
                )}
            </div>
          </Box>
          {/* <div className={styles.downloadBtn}>
            <Button size="small" variant="contained">
              Download
            </Button>
          </div> */}
        </div>
        <div
          className={styles.recursive_container}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3"
        >
          {!loader ? (
            <div
              className={styles.left_container}
              adl-scrollbar="true"
              adl-scrollbar-width="0.3"
            >
              {HierarchyData && (
                <TreeViewComponents
                  HierarchyData={HierarchyData && HierarchyData}
                  onSelectCallback={onSelect}
                  childRef={childRef}
                />
              )}
              {/* <RecursiveTree       
           HierarchyData={HierarchyData && HierarchyData}
           onSelectCallback={onSelect}
        
        /> */}
            </div>
          ) : (
            <>
              <Box sx={{ width: "20rem", padding: "0 0.5rem" }}>
                <Skeleton sx={{ height: "2rem", width: "30%" }} />
                <Skeleton sx={{ height: "2rem", width: "45%" }} />
                <Skeleton sx={{ height: "2rem", width: "50%" }} />
                <Skeleton sx={{ height: "2rem", width: "65%" }} />
                <Skeleton sx={{ height: "2rem", width: "70%" }} />
                <Skeleton sx={{ height: "2rem", width: "60%" }} />
                <Skeleton sx={{ height: "2rem", width: "55%" }} />
                <Skeleton sx={{ height: "2rem", width: "30%" }} />
                <Skeleton sx={{ height: "2rem", width: "45%" }} />
                <Skeleton sx={{ height: "2rem", width: "50%" }} />
                <Skeleton sx={{ height: "2rem", width: "35%" }} />
                <Skeleton sx={{ height: "2rem", width: "70%" }} />
                <Skeleton sx={{ height: "2rem", width: "55%" }} />
                <Skeleton sx={{ height: "2rem", width: "80%" }} />
                <Skeleton sx={{ height: "2rem", width: "30%" }} />
                <Skeleton sx={{ height: "2rem", width: "45%" }} />
                <Skeleton sx={{ height: "2rem", width: "50%" }} />
                <Skeleton sx={{ height: "2rem", width: "65%" }} />
                <Skeleton sx={{ height: "2rem", width: "70%" }} />
                <Skeleton sx={{ height: "2rem", width: "60%" }} />
                <Skeleton sx={{ height: "2rem", width: "55%" }} />
                <Skeleton sx={{ height: "2rem", width: "30%" }} />
                <Skeleton sx={{ height: "2rem", width: "45%" }} />
                <Skeleton sx={{ height: "2rem", width: "50%" }} />
                <Skeleton sx={{ height: "2rem", width: "35%" }} />
                <Skeleton sx={{ height: "2rem", width: "70%" }} />
                <Skeleton sx={{ height: "2rem", width: "55%" }} />
                <Skeleton sx={{ height: "2rem", width: "80%" }} />
              </Box>
            </>
          )}
          {!loader ? (
            <div className={styles.righ_container}>
              <BomViewData />
            </div>
          ) : (
            <>
              {/* <Box sx={{width:"50%",marginTop:'0rem'}}> */}
              <Box
                sx={{
                  width: "40%",
                }}
              >
                <Skeleton sx={{ width: "44vw", height: "3rem" }} />
                <Skeleton sx={{ width: "44vw", height: "3rem" }} />
                <Skeleton sx={{ width: "44vw", height: "3rem" }} />
                <Skeleton sx={{ width: "44vw", height: "3rem" }} />
                <Skeleton sx={{ width: "44vw", height: "3rem" }} />
                <Skeleton sx={{ width: "44vw", height: "3rem" }} />
                <Skeleton sx={{ width: "44vw", height: "3rem" }} />
                <Skeleton sx={{ width: "44vw", height: "3rem" }} />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  //  height: "20rem",
                  marginLeft: "8rem",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    columnGap: "3rem",
                    flexDirection: "row",
                    height: "8rem",
                    marginTop: "0rem",
                    padding: "0 0.5rem",
                  }}
                >
                  <Skeleton sx={{ width: "8vw", height: "10rem" }} />
                  <Skeleton sx={{ width: "8vw", height: "10rem" }} />
                  <Skeleton sx={{ width: "8vw", height: "10rem" }} />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    columnGap: "3rem",
                    flexDirection: "row",
                    height: "8rem",
                    marginTop: "0rem",
                    padding: "0 0.5rem",
                  }}
                >
                  <Skeleton sx={{ width: "8vw", height: "10rem" }} />
                  <Skeleton sx={{ width: "8vw", height: "10rem" }} />
                  <Skeleton sx={{ width: "8vw", height: "10rem" }} />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    columnGap: "3rem",
                    flexDirection: "row",
                    height: "8rem",
                    marginTop: "0rem",
                    padding: "0 0.5rem",
                  }}
                >
                  <Skeleton sx={{ width: "8vw", height: "10rem" }} />
                  <Skeleton sx={{ width: "8vw", height: "10rem" }} />
                  <Skeleton sx={{ width: "8vw", height: "10rem" }} />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    columnGap: "3rem",
                    flexDirection: "row",
                    height: "8rem",
                    marginTop: "0rem",
                    padding: "0 0.5rem",
                  }}
                >
                  <Skeleton sx={{ width: "8vw", height: "10rem" }} />
                  <Skeleton sx={{ width: "8vw", height: "10rem" }} />
                  <Skeleton sx={{ width: "8vw", height: "10rem" }} />
                </Box>
              </Box>
            </>
          )}
        </div>
      </div>

      {/* </LetSuspense> */}
      <Footer />
    </div>
  );
};

export default BomViewComponents;
