import * as React from "react";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import * as yup from "yup";
import { useFormik } from "formik";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { API } from "../../api-services";
import LoadingButton from "@mui/lab/LoadingButton";
import styles from "./AddGroupModal.module.scss";
import {
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  TextFieldProps,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs, { Dayjs } from "dayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import moment from "moment";
import { AUTH } from "../../Redux/Services/auth.service";
import CancelIcon from "@mui/icons-material/Cancel";

export interface IAddGroupModalProps {
  isOpen: boolean;
  onClose: () => void;
  groupData: any;
  setCreateMeetingCount?: any;
  meetingDetails?: any;
}
const validationSchema = yup.object({
  attendees: yup.array().required("Please Select attendees"),
  date: yup.string().nullable().required("date can't be blank"),
  group: yup.mixed(),
  project: yup.mixed().required("Please enter group name"),
});

const styled = {
  boxSize: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",

    width: "39rem",
    maxWidth: "80%",
    height: "fit-content",
    minHeight: "20rem",
    borderRadius: "0.5rem",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    p: 0,
    outline: 0,
  },
  NoAccessBoxSize: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center !important",
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "39rem",
    height: "fit-content",
    minHeight: "20rem",
    borderRadius: "0.5rem",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    p: 0,
    outline: 0,
  },
  HeaderLine: {
    textAlign: "center",
    position: "relative",
  },
  SelectedHeaderLine: {
    color: "#1976d2",
  },
  nextButton: {
    position: "absolute",
    zIndex: "9999",
    left: "35rem",
    top: "1.8rem",
  },

  summery: {
    backgroundColor: "rgb(240, 247, 255)",
    borderColor: " rgb(0, 127, 255)",
    borderRadius: "10px",
    minHeight: "45px",
  },
  searchButton: {
    position: "absolute",

    // right: "37rem",
    top: "0.4rem",

    left: "-2rem",
    cursor: "default",
    color: "#1976d2",
    padding: " 0rem 0rem 0rem 2rem",
  },
  closedIcon: {
    position: "absolute",
    zIndex: "999",
    color: "#1976d2",
    right: "0.2rem",
    top: "0.5rem",
    cursor: "pointer",
  },
  editIcon: {
    marginTop: "0.3rem",
    color: "#1976d2",
    cursor: "pointer",
  },
};
// @ts-ignore

const useStyles = makeStyles((theme) => ({
  /** Changed modalStyle */
  ModalRoot: {
    backgroundColor: "rgba(111, 126, 140, 0.2) !important",
    backdropFilter: "blur(1px) !important",
  },
}));

export function CreateMeetingModal(props: IAddGroupModalProps) {
  const { isOpen, onClose, groupData, setCreateMeetingCount, meetingDetails } =
    props;
  const classes = useStyles();
  const { projectId, groupId, pointsId } = useRouteParams<any>();
  const [allUserList, setAllUserList] = React.useState<any>([]);

  const formik = useFormik({
    initialValues: {
      attendees: [],
      date: moment().format("YYYY-MM-DD").toString(),
      group: parseInt(groupId),
      project: projectId.toString(),
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      let obj = {
        attendees: [...values.attendees, ...[AUTH.user]],
        date: values.date,
        group: values.group,
        project: values.project,
      };
      let editObj = {
        attendees: values.attendees,
        date: values.date,
      };
      if (meetingDetails === "Add") {
        API.post("/drive/meeting/", obj, {}, 0)
          .then((res: any) => {
            setCreateMeetingCount((prev: any) => prev + 1);
            onClose();
          })
          .catch((err: any) => {
            console.log(err);
          });
      } else {
        API.patch(`/drive/meeting/${meetingDetails?.id}/`, editObj, {}, 0)
          .then((res: any) => {
            setCreateMeetingCount((prev: any) => prev + 1);
            onClose();
          })
          .catch((err: any) => {
            console.log(err);
          });
      }
    },
  });
  React.useEffect(() => {
    if (meetingDetails && meetingDetails !== "Add") {
      formik.values.attendees = meetingDetails.attendees;
      formik.values.date = meetingDetails.date;
    }
  }, [meetingDetails]);
  const getAlluserList = () => {
    let temp: any = {};
    temp = groupData?.filter((item: any) => item.id === parseInt(groupId))[0];
    API.get(
      "/auth/user_list_drive/",
      { module: 42, project: projectId },
      0
    ).then((res: any) => {
      setAllUserList(
        res.data.users.filter((item: any) => temp?.attendees.includes(item.id))
      );
    });
  };

  React.useEffect(() => {
    getAlluserList();
  }, [groupData]);

  return (
    <div>
      <Modal // autoFocusdal
        open={isOpen}
        className={classes.ModalRoot}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styled.boxSize}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              {meetingDetails ==="Add"?"Create Meeting":"Edit Meeting"}
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onClose}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box
            sx={{ width: "100%", overflowWrap: "break-word", padding: "1rem" }}
          >
            <form onSubmit={formik.handleSubmit}>
              <FormControl
                variant="standard"
                sx={{ m: 1, minWidth: 120, width: "97%" }}
                size="medium"
              >
                <InputLabel>Add Internal Users</InputLabel>
                <Select
                  id="attendees"
                  name="attendees"
                  multiple
                  // className={classes.select}
                  labelId="demo-select-small"
                  // id="demo-select-small"
                  sx={{
                    "& .MuiFormLabel-root": {
                      color: "#007fff",
                    },                    
                    color: "#007fff",                    
                    "& .MuiInput-root-MuiSelect-root:after": {
                      borderBottomColor: "primary.light",
                    },
                    ".MuiSelect-select.MuiInputBase-input.MuiInput-input": {
                      fontSize: "1rem",
                    },
                    ".MuiSvgIcon-root ": {
                      fill: "#007fff !important",
                    },
                    borderBottom:'1px solid',
                    borderBottomColor:'primary.main',                 
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "#ffffff",
                    },
                    "&:before": {
                      borderBottomColor: "white",
                    },
                  }}
                  required
                  MenuProps={{
                    style: {
                      maxHeight: 400,
                      color: "007fff",
                    },
                  }}
                  onChange={formik.handleChange}
                  value={formik.values.attendees}
                >
                  {/* <option value={""} style={{ display: "none" }}></option> */}
                  {allUserList &&
                    allUserList.map((item: any, index: any) => {
                      return (
                        <MenuItem
                          sx={{ color: "primary.main", fontSize: "1rem" }}
                          key={index}
                          value={item.id}
                        >
                          {item.username}
                        </MenuItem>
                      );
                    })}
                </Select>
                <div className={styles.errormsg}>
                  {formik.touched.attendees &&
                    Boolean(formik.errors.attendees) && (
                      //@ts-ignore
                      <p>{formik.errors.attendees}</p>
                    )}
                </div>
              </FormControl>
              <FormControl
                variant="standard"
                sx={{ m: 1, minWidth: 120, width: "97%" }}
                size="medium"
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <input
                    name="date"
                    value={formik.values.date}
                    onChange={formik.handleChange}
                    type="date"
                    style={{
                      color: "#007fff",
                      borderTop: "none",
                      borderLeft: "none",
                      borderRight: "none",
                      borderBottom: "1px solid #007fff",
                      paddingBottom: "5px",
                      fontFamily:'roboto',
                    }}
                  />
                </LocalizationProvider>
              </FormControl>
              <Box sx={{ textAlign: "right", marginTop: "1rem", width: "98%" }}>
                <LoadingButton
                  //   className={styles.feedbackSubmit}
                  sx={{backgroundColor: meetingDetails === "Add" ? "primary.main" : '#6aa84f',
                  "&:hover":{
                    transform:'scale(1.1)',
                    transition:'transform 0.5s ease'
                  }}}
                  variant="contained"
                  size="small"
                  type="submit"
                  //   loading={isLoading}
                  //   disabled={userId !== "" ? false : true}
                >
                  {meetingDetails === "Add" ? "Create Meeting" : "Save Changes"}
                </LoadingButton>
                {meetingDetails !=="Add"&&<LoadingButton
                  //   className={styles.feedbackSubmit}
                  sx={{marginLeft:"2rem",backgroundColor:'#d65e5e',
                 " &:hover":{
                    transform:'scale(1.1)',
                    transition:'transform 0.5s ease'
                  }
                }}
                  size="small"
                  variant="contained"
                  onClick={()=>{
                    API.delete(`/drive/meeting/${meetingDetails?.id}/`,{},0).then((res:any)=>{
                      setCreateMeetingCount((prev: any) => prev - 1);
                      onClose();
                    })
                  }}
                  //   loading={isLoading}
                  //   disabled={userId !== "" ? false : true}
                >
                  Delete
                </LoadingButton>}
              </Box>
            </form>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
