import {
    createEntityAdapter,
    createReducer,
    createSelector,
    EntityState,
} from "@reduxjs/toolkit";

import { ID } from "../../utlis/commonInterfaces";
import { ADMIN } from "../Services/admin.service";
import { RootState } from "../Store/storeConfigurations";
import { getCalculators, createCalculator, copyCalculator, updateCalculator, deleteCalculator, resetCalculators } from "../Actions/calculator.actions";

// -----------------------------------------------------------------------------------
// entity adaptor

export interface ICalculator {
    id: ID;
    /** CALCULATOR_TYPE */
    calculator_type: number;
    /** CALCULATOR_CATEGORY */
    category: number;
    process: ID;
    project: ID;
    commodity_mapping: ID;

    name: string;
    result: string;

    current_qty?:number;
    parent?:any;
    mapped_status?:any;
    validation?:any
}

const calculatorAdaptor = createEntityAdapter<ICalculator>( {
    selectId: calculator => calculator.id,
    sortComparer: ( a, b ) => a.name.localeCompare( b.name ),
} );

// -----------------------------------------------------------------------------------
// interfaces & initialState

type AdditionalFields = {};

type ICalculatorReducer = EntityState<ICalculator> & AdditionalFields;

const additionalFields: AdditionalFields = {};

const initialState: ICalculatorReducer = Object.assign(
    {},
    calculatorAdaptor.getInitialState(),
    additionalFields
);

// -----------------------------------------------------------------------------------
// reducer

const CalculatorReducer = createReducer( initialState, builder => {
    // get
    builder
        .addCase( getCalculators.fulfilled, ( state, action: any ) => {
            calculatorAdaptor.setAll( state, action.payload.calculators );
        } )
        .addCase( getCalculators.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError(
            //     action.payload,
            //     "Could not fetch calculators"
            // );
        } );

    // create
    builder
        .addCase( createCalculator.fulfilled, ( state, action: any ) => {
            calculatorAdaptor.addOne( state, action.payload.calculator );

            // ADMIN.toast.success( "calculator created" );
        } )
        .addCase( createCalculator.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could not create calculator" );
        } );

    // copy
    builder
        .addCase( copyCalculator.fulfilled, ( state, action: any ) => {
            calculatorAdaptor.addOne( state, action.payload.calculator );

            // ADMIN.toast.success( "calculator copied" );
        } )
        .addCase( copyCalculator.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could not copy calculator" );
        } );

    // update
    builder
        .addCase( updateCalculator.fulfilled, ( state, action ) => {
            const { id, updates: changes } = action.meta.arg;
            calculatorAdaptor.updateOne( state, { id, changes } );

            // ADMIN.toast.success( "calculator updated" );
        } )
        .addCase( updateCalculator.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError(
            //     action.payload,
            //     "Could not update calculator"
            // );
        } );

    // delete
    builder
        .addCase( deleteCalculator.fulfilled, ( state, action ) => {
            const { id } = action.meta.arg;
            calculatorAdaptor.removeOne( state, id );

            // ADMIN.toast.success( "calculator deleted" );
        } )
        .addCase( deleteCalculator.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError(
            //     action.payload,
            //     "Could not delete calculator"
            // );
        } );

    // reset reducer
    builder.addCase( resetCalculators, () => initialState );
} );

// -----------------------------------------------------------------------------------
// selectors

const selectors = calculatorAdaptor.getSelectors<RootState>(
    state => state.calculators
);

const selectByCategoryId = createSelector(
    selectors.selectAll,
    ( _state: RootState, categoryId: ID ) => categoryId,
    ( calculators, categoryId: ID ) => {
        return calculators.filter( calculator => calculator.category === categoryId );
    }
);

const calculatorSelectors = Object.assign( {}, { selectByCategoryId }, selectors );

// -----------------------------------------------------------------------------------
// exports

export {
    CalculatorReducer,
    initialState as CalculatorReducerInit,
    calculatorSelectors,
};
