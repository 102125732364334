import * as React from 'react';
import {  Typography } from "@mui/material";
import ZoomOutMapIcon from "@mui/icons-material/ZoomOutMap";
import { useRouteParams } from '../../../../ui-reusable-component/useRouteParams';
import { useBaseParamsModal } from '../../../../CustomHook/useBaseParamsModal';
import IdeaTabPCBExpandPopupModalView from './IdeaTabPCBExpandPopupModalView';


export interface IIdeaTabEntryAccordianProps {
    expandStatus: any;
    tableName: any;
    item: any;
    getTableData?: any;
    dataForUpdate?: any;
    tableData?: any;
    setTableData?: any;
    dataForCheck?: any;
    setSelectedOptionsTable?: any;
    setColorButton?: any;
    colorButton?: any;
    setSelectedTableId?: any;
    selectedTableId?: any;
    getTableDataAll?: any;
    tableDataLoader?: any;
    setShowButton?: any;
    showButton?: any;
    materialCount?: any;
    // BOMValidationStatus?:any;
    tableAccData?:any;
}

export default function IdeaTabEntryAccordian (props: IIdeaTabEntryAccordianProps) {
    const {
        tableName,
        item,
        getTableData,
        dataForUpdate,
        tableData,
        setTableData,
        setColorButton,
        colorButton,
        setSelectedTableId,
        selectedTableId,
        setShowButton,
        materialCount,        
        tableAccData
      } = props;
      const { topVault, vault } = useRouteParams<any>();
      const [tableOptions, setTableOptions] = React.useState<any>([]);
      const [allData, setAllData] = React.useState<any>([]);
      const [selectedOptions, setSelectedOptions] = React.useState<any>([]);
      const [isSave, setIsSave] = React.useState<any>(false);
      const [isAdding, setIsAdding] = React.useState<any>(false);

      const IdeaPCBModal = useBaseParamsModal();
      const IdeaPCBModalHandler = () => {
        IdeaPCBModal.open();
      };


  return (
    <>
      {IdeaPCBModal.isOpen && (
        <IdeaTabPCBExpandPopupModalView
          isOpen={IdeaPCBModal.isOpen}
          onCloseModal={IdeaPCBModal.close}
          expandStatus={false}
          idddd={item}
          tableName={tableName}
          getTableData={getTableData}
          dataForUpdate={dataForUpdate}
          tableData={tableData}
          setTableData={setTableData}
          dataForCheck={item?.name}
          setSelectedOptionsTable={setSelectedOptions}
          setColorButton={setColorButton}
          colorButton={colorButton}
          setSelectedTableId={setSelectedTableId}
          selectedTableId={selectedTableId}
          setShowButton={setShowButton}          
          tableAccData={tableAccData}
        />
      )}
      <Typography onClick={() => IdeaPCBModalHandler()} sx={{fontSize:'12px',width:'100%', cursor:'pointer'}}>{tableName} ({materialCount})</Typography>
     
        <ZoomOutMapIcon
          titleAccess="Expand PCB Table"
          sx={{ cursor: "pointer" }}
          onClick={() => IdeaPCBModalHandler()}
        />
    </>
  );
}
