import { Button, ButtonProps, Table, TableBody, TableCell, TableHead, TableRow, Typography, IconButton, Accordion, AccordionSummary, AccordionDetails, Popover } from "@mui/material";
import _ from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { object } from "yup";
import { API } from "../../api-services";
import NoImgLoader from "../../Assets/images/loading-screen-landing.gif";

import Image from "../../ViewModule/FeatureLandingPage/ArchitectureImageCard";
import { BomImageViewer } from "../BomImageViewer";
import BomCompareImg from "./BomCompareImg";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';
import { DataGrid, GridColDef, GridCsvExportMenuItem, GridCsvExportOptions, GridToolbar, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExportContainer, GridValueGetterParams } from "@mui/x-data-grid";
import RViewerJS from "viewerjs-react";
import styles from "./BOMcomparePage.module.scss";
import TableIcon from "../../Assets/images/svgs/tableIcon.svg"
import TableChartIcon from '@mui/icons-material/TableChart';
import BomTableModal from "../BomTableModal";
import { useBaseModal } from "../../ComponentUI/SearchPage/useBaseModal";
import ReplyIcon from '@mui/icons-material/Reply';
import RidirectionModal from "./RidirectionModal";
import EqualizerIcon from '@mui/icons-material/Equalizer';
import CompareCostingGraphmodal from "./CompareCostingGraphmodal";
interface BOMcomparePageProps {
}
const csvOptions: GridCsvExportOptions = { fileName: 'Table In CSV', delimiter: ",", utf8WithBom: true };
const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>);
const CustomToolbar = () => (
  <GridToolbarContainer style={{ height: '2rem' }}>
    <span style={{ position: 'absolute', right: '0rem' }}>
      <GridToolbarColumnsButton />
      {/* <GridToolbarDensitySelector /> */}
      <CustomExportButton />
    </span>
  </GridToolbarContainer>)
const BOMcomparePage = (props: BOMcomparePageProps) => {
  const { projectId, moduleType, topVaultId, abbr, NodeId, Count, TopId, Partname, Nodeleaf, depthNode, pathname } =
    useParams<any>();
  const [AllProductData, setAllProductData] = useState([]);
  const [imagesData, setImagesData] = useState();
  const [ParameterData, setParameterData] = useState<any>()
  const [tableData, setTableData] = useState<any>()
  const [loaderImage, setLoaderImage] = useState(false);
  const [Imageslength, setImageslength] = useState()
  const [PageCount, setPageCount] = useState<any>(1)
  const [PcbLoader, setPcbLoader] = useState(false)
  const [ParameterLoader, setParameterLoader] = useState<any>(false)
  const [LoaderImagePaagination, setLoaderImagePaagination] = useState(false)
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [accordianTableData, setAccordianTableData] = useState()
  const [PcbTableData, setPcbTableData] = useState<any>()
  const [parameterName, setParameterName] = useState<any>([])

  const [BomGraphLoader, setBomGraphLoader] = useState<any>(false)

  const [FilterTopVaultIds, setFilterTopVaultIds] = useState<any>()
  const [tableName, setTableName] = useState<any>()
  const [LoaderPcbData, setLoaderPcbData] = useState(false)
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const [redirectionData, setRedirectionData] = useState<any>()
  const [anchorElPcb, setAnchorElPcb] = React.useState<any>(false)
  const history = useHistory<any>()
  const TableModalOpen = useBaseModal();

  const graphModal=useBaseModal()
  const ridirectionopen = useBaseModal();
  const [BomGraphData, setBomGraphData] = useState<any>()
  // const riderictionPopup=useRef<any>(false)


  const topVaultsParam: any =
    topVaultId && typeof topVaultId == "string"
      ? topVaultId?.split(",")?.map(Number)
      : [topVaultId];
  var filterDataIds = topVaultsParam?.sort(function (a: any, b: any) { return a - b });






  const handleClick = () => {
    // setAnchorEl(event.currentTarget);


  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


  useEffect(() => { setPageCount(1) }, [NodeId])


  React.useEffect(() => {
    if (projectId) {
      setLoaderImage(true)
      // setLoaderProduct(true);
      API.get("/customer/compare_module/", {
        project: projectId,
        product_list: true,
        module: moduleType,
        depth: depthNode
      },0)
        .then((res: any) => {
          setAllProductData(res.data);
          setLoaderImage(false)
          // setSelectedTopVaultIds([])
          // setLoaderProduct(false);
        })
        .catch((err: any) => {
          setLoaderImage(false)
          // setLoaderProduct(false);
          // console.log("compare error");
        });
    }
  }, [projectId, Count]);
  var selectedTopVaultData = AllProductData && AllProductData.filter((item: any, index: number) => topVaultsParam.includes(item?.id))
  let OrderData = _.orderBy(selectedTopVaultData, ['id'],
    ['asc', 'desc']);
  let OrderTopvaults = _.orderBy(topVaultsParam, ['asc', 'desc']);
  const togglePartName = (name: any) => {
    if (abbr == "product") {
      return { part_name: "product" }
    } else {
      return { part_name: name }
    }
  }



  useEffect(() => {


    setExpanded(false)
  }, [Partname])




  useEffect(() => {
    setParameterLoader(true)
    API.get("/customer/compare_module/", {
      top_vaults: filterDataIds.toString(),
      ...togglePartName(Partname),
      module: moduleType,
      is_leaf: Nodeleaf,
      parameters: true,
      depth: depthNode,
      path: pathname
    },0)
      .then((res: any) => {
        setParameterData(res?.data);
        setParameterLoader(false)
        //   setLoaderImage(false)
        // setSelectedTopVaultIds([])
        // setLoaderProduct(false);
      })
      .catch((err: any) => {
        // setLoaderProduct(false);
        setParameterLoader(true)
        // console.log("compare error");
      });
  }, [Nodeleaf, NodeId, Count, topVaultId])
  useEffect(() => {
    setPcbLoader(true)
    API.get("/customer/compare_module/", {
      top_vaults: filterDataIds.toString(),
      ...togglePartName(Partname),
      module: moduleType,
      is_leaf: Nodeleaf,
      table: true,
      depth: depthNode,
      path: pathname
    },0)
      .then((res: any) => {
        setTableData(res?.data);
        setPcbLoader(false)
        // setSelectedTopVaultIds([])
        // setLoaderProduct(false);
      })
      .catch((err: any) => {
        // setLoaderProduct(false);
        // console.log("compare error");
        setPcbLoader(false)
      });
  }, [Nodeleaf, NodeId, Count, topVaultId])
  useEffect(() => {
    setLoaderImage(true)
    API.get("/customer/compare_module/", {
      top_vaults: filterDataIds.toString(),
      ...togglePartName(Partname),
      module: moduleType,
      is_leaf: Nodeleaf,
      depth: depthNode,
      image_length: true,
      path: pathname
    },0)
      .then((res: any) => {
        setImageslength(res?.data?.image_length);
        setLoaderImage(false)
        // setSelectedTopVaultIds([])
        // setLoaderProduct(false);
      })
      .catch((err: any) => {
        // setLoaderProduct(false);
        // console.log("compare error");
        setLoaderImage(false)
      });
  }, [Nodeleaf, NodeId, Count])
  const PcbTableRow = (rowitem: any) => {
    var FilterData = rowitem && rowitem?.map((item: any, id: any) => ({
      ...item,
      id: id + 1,
    }));
    return FilterData;
  };
  const BomTableRow = (rowitem: any) => {
    var FilterData = rowitem && rowitem?.map((item: any, id: any) => ({
      ...item,
      id: id + 1,
    }));
    return FilterData;
  };

  const compareAllhandler = (item: any, tablename: any) => {


    TableModalOpen.open();
    var namedata = item.slice(0, -6)
    // var dataTable=OrderData && OrderData.filter((item: any, id: any) => item.name ===namedata).map((item1: any, id1: any) =>{return item1.id})
    setFilterTopVaultIds(topVaultId)
    var dataname = tablename?.Parameter.slice(0, -12)

    setTableName(dataname)

  }
  //   console.log(TableRow() ,"TableRow")
  const BomTableHeader = (HedaerItem: any) => {
    var dataHeader: any = HedaerItem && Object.keys(HedaerItem)
    dataHeader.pop();
    dataHeader.pop();
    dataHeader.unshift("Unit");
    dataHeader.unshift("Parameter");
    var valuedata = dataHeader && dataHeader?.map((key: any, value: any) => {
      //  console.log(key,"checkKey")
      return {
        field: key,
        headerName: key,
        maxWidth: key == "Unit" ? 60 : 600,
        minWidth: 60,
        width: 300,
        flex: 1,
        hide:key=="data_type"?true:false,

        renderCell: (params: any) => {
          // TableChartIcon

          // console.log(params.row,"parararrarararar")
          // var data = "'" + params.row[key] + ""

          if (key == "Parameter") {

            return (
              <>
             { params.row["data_type"]=="int"?             
             <Box sx={{display:'flex',justifyContent:'space-between',width:'100%'}}>
             <span>{params.row[key]}</span>             
             <EqualizerIcon titleAccess='Graph' style={{ cursor: "pointer" }}   onClick={() => OpenGraphModal(params?.row["Parameter"])} />
             </Box>
             
             :
              <span>{params.row[key]}</span>
            }
              </>
              // <img src={TableIcon} style={{ cursor: 'pointer', marginRight: '0.5rem', width: '1.7rem', height: '1.8rem' }} onClick={() => PcbHanlder(key, HedaerItem)} />
            )
          }

        

          else {
            return <span>{params.row[key]}</span>;
          }
        }
        //   headerClassName: styles.Dataheader,
      };
    });
    return valuedata;
  };
  const PcbTableHeader = (HedaerItem: any) => {
    var dataHeader: any = HedaerItem && Object.keys(HedaerItem)
    dataHeader.pop();
    dataHeader.pop();
    dataHeader.unshift("Unit");
    dataHeader.unshift("Parameter");
    var valuedata = dataHeader && dataHeader?.map((key: any, value: any) => {
      // console.log(key,"checkKey")
      return {
        field: key,
        headerName: key,
        maxWidth: key == "Unit" ? 80 : 600,
        minWidth: 80,
        width: 300,
        flex: 1,

        renderCell: (params: any) => {
          // TableChartIcon
          var data = "'" + params.row[key] + ""

          if (params.row[key] == "table_data") {
            return (
              <img src={TableIcon} style={{ cursor: 'pointer', marginRight: '0.5rem', width: '1.7rem', height: '1.8rem' }} onClick={() => PcbHanlder(key, HedaerItem)} />
            )
          }

          if (data.includes('Compare')) {
            var dataparametername = params?.row[key].slice(0, -12)
            return (
              <>
                <Typography style={{ paddingRight: '0.5rem', fontSize: '1rem', }}>{dataparametername} </Typography>
                <Button variant="contained" size="small" sx={{lineHeight:1,textTransform:'capitalize'}} onClick={() => compareAllhandler(key, HedaerItem)} >Compare all</Button>
              </>)
          }

          else {
            return <span>{params.row[key]}</span>;
          }
        }
      };
    });
    return valuedata;
  };



  const ImgPaginationHandler = (btnType: any) => {
    if (btnType == "next") {
      setPageCount(PageCount + 1)
    }
    if (btnType == "prev") {
      setPageCount(PageCount - 1)
    }
  }
  useEffect(() => {
    setLoaderImagePaagination(true)
    API.get("/customer/compare_module/", {
      top_vaults: filterDataIds.toString(),
      ...togglePartName(Partname),
      module: moduleType,
      is_leaf: Nodeleaf,
      depth: depthNode,
      page: PageCount,
      path: pathname
    },0)
      .then((res: any) => {
        setImagesData(res?.data);
        setLoaderImagePaagination(false)
      })
      .catch((err: any) => {

        setLoaderImagePaagination(false)
      });
  }, [Nodeleaf, NodeId, PageCount, topVaultId])
  var ImageLengthData = Imageslength && Imageslength;




  const PcbHanlder = (item: any, tablename: any) => {

    TableModalOpen.open();
    var namedata = item.slice(0, -6)
    var dataTable = OrderData && OrderData.filter((item: any, id: any) => item.name === namedata).map((item1: any, id1: any) => { return item1.id })


    setFilterTopVaultIds(dataTable)
    var dataname = tablename?.Parameter.slice(0, -12)

    setTableName(dataname)

  }


  const Redirection = (item: any) => {
    // setAnchorElPcb(false)
    // riderictionPopup.current=false
    API.get("/customer/compare_redirect/", { top_vaults: item, part_name: Partname, is_leaf: Nodeleaf, depth: depthNode, path: pathname }).then((res: any) => {
      // sessionStorage.setItem("redirect_url",);
      // sessionStorage.setItem("node", JSON.stringify(res.data?.root_nodes_id));

      setRedirectionData(res?.data)

      if (Object.keys(res?.data)?.length > 0) {
        setAnchorElPcb(false)
        window.open(
          `/#/view/${res.data?.project}/${res.data?.top_vault}/${res.data?.vault}/${res.data?.abbreviation}/true`,
          "_blank",
          " noopener"
        );
      }
      else {
        // riderictionPopup.current=true
        // console.log("insidedatad")
        setAnchorElPcb(true)

        ridirectionopen.open()



      }
      // history.push(`/view/${res.data?.project}/${res.data?.top_vault}/${res.data?.vault}/${res.data?.abbreviation}/true`)
    })


  }

  const topVaultColor: any = [
    "#075482",
    "#e14958",
    "#fe932b",
    "#64d0dc",
    "#33b1e4",
    "#ffcc67",
    "#8dc73f",
    "#8945aa",
    "#9fd3c7",
    "#385170",
  ];

  const OpenGraphModal = (item: any) => {

    graphModal.open()


    setBomGraphLoader(true)
    API.get("/customer/compare_module/", {


      top_vaults: filterDataIds.toString(),
      ...togglePartName(Partname),
      module: moduleType,
      is_leaf: Nodeleaf,
      depth: depthNode,
      bom_graph: true, path: pathname,
      key: item,
      colors: topVaultColor
        ?.slice(0, topVaultId && topVaultId.length > 1 ? topVaultId?.split(",").length : filterDataIds.length)
        ?.toString(),

    },0).then((res: any) => {
      setBomGraphData(res?.data)
      setBomGraphLoader(false)
      // console.log(res.data, "resdata").
    }).catch((err: any) => {

      setBomGraphLoader(false)
    })
    setParameterName(item)

  }


  // /customer/compare_redirect/?top_vaults=46&part_name=cross member&is_leaf=true&depth=2&path=00JT0001





  // console.log(OrderData.length + 3, "OrderDataOrderData")
  return (
    <Box sx={{ height: { sm: '91vh', md: '87vh', lg: '86vh', xl: '89vh' }, }}>

      <RidirectionModal
        isOpen={ridirectionopen.isOpen}
        onCloseModal={ridirectionopen.close}
      />

      <CompareCostingGraphmodal
        Open={graphModal.isOpen}
        Close={graphModal.close}
        parameterGraphData={BomGraphData && BomGraphData}
        ParameterNames={parameterName && parameterName}
        CostingGraphLoader={BomGraphLoader}
        moduleType={"Bom"}
      />

      <BomTableModal
        isOpen={TableModalOpen.isOpen}
        onCloseModal={TableModalOpen.close}
        pathData={pathname}
        TableName={tableName}
        Topvaults={FilterTopVaultIds}
        PartData={Partname}
        LeafNode={Nodeleaf}
        DepthNode={depthNode}
      />
      {LoaderImagePaagination ?
        <Box sx={{ width: '100%', padding: '0 1rem', height: '18rem', }}>
          <Skeleton sx={{ height: "18rem", width: "100%" }} />
        </Box> :
        <Table>
          <TableHead sx={{ backgroundColor: 'primary.light' }} >
            {/* @ts-ignore */}
            <TableRow>
              <TableCell sx={{ width: "1rem", borderBottom: 'none', padding: "0.2rem 0.5rem", }}></TableCell>
              <TableCell className={OrderData?.length == 5 ? styles.headerLength5 : OrderData?.length == 4 ? styles.headerLength4 : OrderData?.length == 3 ? styles.headerLength3 : OrderData?.length == 2 ? styles.headerLength2 : OrderData?.length == 1 ? styles.headerLength1 : styles.headerLength}>BOM Comparison</TableCell>
              <TableCell sx={{ padding: "0.2rem 0.5rem", width: '4rem', borderBottom: 'none', }}></TableCell>
              {OrderData && OrderData?.map((item: any, index: any) => {
                return (<>
                  <TableCell sx={{ padding: "0.2rem 0.5rem", width: OrderData?.length == 5 ? '13vw' : OrderData?.length == 4 ? '15vw' : OrderData?.length == 3 ? '20vw' : OrderData?.length == 2 ? "25vw" : OrderData?.length == 1 ? '25vw' : '30vw', borderBottom: 'none', }} align="center">
                    <Typography style={{ fontSize: '1rem', display: 'flex', justifyContent: 'space-between', paddingRight: '0.5rem' }}><span style={{ width: '100%'}}>{item?.name}({item?.product_id})</span>  <ReplyIcon
                      sx={{ fontSize: "1.5rem", transform: "scaleX(-1)", cursor: 'pointer' }}
                      onClick={() => Redirection(item?.id)}
                      titleAccess="Go to BOM"
                    /></Typography>
                  </TableCell> </>)
              })}
              {/* <TableCell sx={{ padding: "0.2rem 0.5rem", width: '1rem', borderBottom: 'none', }}>&nbsp;</TableCell> */}
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell colSpan={OrderData?.length + 4} sx={{ padding: "0.2rem 0rem", borderBottom: 'none', width: '100%', height: '10rem' }} align="left">
                <Table>
                  <TableRow>
                    <TableCell className={OrderData?.length == 5 ? styles.lengthCount5 : OrderData?.length == 4 ? styles.lengthCount4 : OrderData?.length == 3 ? styles.lengthCount3 : OrderData?.length == 2 ? styles.lengthCount2 : OrderData?.length == 1 ? styles.lengthCount1 : styles.lengthCount}></TableCell>
                    <TableCell sx={{ width: '4rem', padding: "0.2rem 0.5rem", borderBottom: 'none', }}>
                      <IconButton style={{ cursor: "pointer" }} disabled={PageCount > 1 ? false : true} onClick={() => ImgPaginationHandler("prev")}>
                        <ArrowBackIosIcon sx={{ color: PageCount > 1 ? 'primary.main' : ''}} />
                      </IconButton>
                    </TableCell>                    {/* @ts-ignore */}
                    <RViewerJS>                      {/* @ts-ignore */}
                      <span title={imagesData && imagesData?.parameter}>
                        {/* @ts-ignore */}
                        {imagesData && imagesData?.data?.map((item: any, index: any) => {
                          {/* @ts-ignore */ }
                          return (<><BomCompareImg ImgItem={item} topVaultIds={OrderData?.length} viweimg={imagesData && imagesData?.parameter} />              </>)
                        })}
                      </span>
                    </RViewerJS>
                    <TableCell sx={{ width: '2rem', padding: "0.2rem 0rem", borderBottom: 'none', textAlign: 'right' }} colSpan={OrderData?.length}>
                      <IconButton style={{ cursor: "pointer" }}
                        // @ts-ignore
                        disabled={PageCount <= ImageLengthData - 1 ? false : true}
                        onClick={() => ImgPaginationHandler("next")}>
                          {/* @ts-ignore */}
                        <ArrowForwardIosIcon sx={{ cursor: "pointer", color: PageCount <= ImageLengthData - 1 ? 'primary.main': '' }} />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                </Table>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      }
      <Box sx={{ height: { lg: "63vh", xl: '71vh' } }}
        adl-scrollbar="true"
        adl-scrollbar-width="0.3">
        {ParameterLoader ?

          <Box sx={{ width: "100%", padding: '0 1rem' }}>
            <Skeleton sx={{ height: "4rem", width: "100%" }} />
          </Box>
          :
          <Box sx={{
            width: "100%", padding: '0 1rem',
            "& .MuiDataGrid-columnHeaderTitle": {
              color: '#fff !important',
            },
            "& .MuiDataGrid-columnHeaders.MuiDataGrid-columnHeaders": {
              backgroundColor: "primary.main",
              minHeight: '34px !important',
              maxHeight: '34px !important',
              lineHeight: '34px !important'
            }
          }}>
            {ParameterData && ParameterData.length > 0 &&
              <DataGrid
                headerHeight={48}
                rowHeight={36}
                rows={BomTableRow(ParameterData && ParameterData)}
                columns={BomTableHeader(ParameterData && ParameterData[0])}
                components={{ Toolbar: () => { return CustomToolbar() } }}
                // filterable={false}

                // onRowClick={(params: any, event: any, details: any)=>


                //   EditClick(params.row.FE_ID, params.row.from_user_id)


                // }
                density="compact"
                autoHeight={true}
                disableSelectionOnClick
                hideFooterPagination={true}
                hideFooter={true}
              />
            }

          </Box>
        }

        {PcbLoader ? <Box sx={{ width: "100%", padding: '0 1rem' }}>
          <Skeleton sx={{ height: "4rem", width: "100%" }} />
          <Skeleton sx={{ height: "4rem", width: "100%" }} />
          <Skeleton sx={{ height: "4rem", width: "100%" }} />
          <Skeleton sx={{ height: "4rem", width: "100%" }} />
          <Skeleton sx={{ height: "4rem", width: "100%" }} />
        </Box> :
          <> {tableData && Object.keys(tableData)?.map((tableItem: any, ind: any) => {
            return (
              <>
                <Box sx={{ display: 'flex', columnGap: '1rem', alignItems: 'center', padding: '0.5rem 1rem', width: '50%', zIndex: '1000', position: 'relative' }}>
                  <Typography sx={{fontSize:'1rem',fontWeight:'600',marginBottom:'-3rem'}}> {tableItem}</Typography>
                </Box>
                <Box sx={{
                  width: "100%", padding: '0 1rem',
                  "& .MuiDataGrid-columnHeaderTitle": {
                    color: '#fff !important',
                  },
                  "& .MuiDataGrid-columnHeaders.MuiDataGrid-columnHeaders": {
                    backgroundColor: "primary.main",
                    minHeight: '34px !important',
                    maxHeight: '34px !important',
                    lineHeight: '34px !important'
                  }
                }}>
                  <DataGrid
                    headerHeight={48}
                    rowHeight={36}
                    rows={PcbTableRow(tableData[tableItem] && tableData[tableItem])}
                    columns={PcbTableHeader(tableData[tableItem] && tableData[tableItem][0])}
                    density="compact"
                    components={{ Toolbar: () => { return CustomToolbar() } }}
                    autoHeight={true}
                    // disableSelectionOnClick
                    disableColumnFilter={true}
                    hideFooterPagination={true}
                    hideFooter={true}
                  />
                </Box>
              </>);
          })}
          </>}



      </Box>
    </Box>
  )
}
export default BOMcomparePage;