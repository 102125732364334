import React, { useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import { ADMIN } from "../../../Redux/Services/admin.service";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import "viewerjs-react/dist/index.css"; // Ensure this is imported

import {
  Autocomplete,
  FormControl,
  MenuItem,
  Select,
  TextField,
  Divider,
  InputLabel,
  Input,
  CircularProgress,
  IconButton,
} from "@mui/material";
import { useParams } from "react-router-dom";
import { userPermissionAction } from "./BOMEntryLandingPage";
import { LoadingButton } from "@mui/lab";
import { API } from "../../../api-services";
import Swal from "sweetalert2";
import { last, set } from "lodash";
import { ImageInputViewBox } from "./ImageInputViewBox";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { table } from "console";
import { ImageEdit } from "material-ui/svg-icons";
import { ImageEditViewBox } from "./ImageEditViewBox";
import Switch from "@mui/material/Switch";
import { useBaseModal } from "../../SearchPage/useBaseModal";
import SelectDuplicate from "./SelectDuplicate";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import Chip from "@mui/material/Chip";
import OutlinedInput from "@mui/material/OutlinedInput";
import PreviewIcon from "@mui/icons-material/Preview";
import RViewerJS from "viewerjs-react";

import { SelectChangeEvent } from "@mui/material/Select";
import PCBS3Upload from "../../../DataBaseModule/Costing/PCBS3Upload";

type Props = {};

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: " 60vw",
  height: "80vh",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 0,
  display: "flex",
  flexDirection: "column" as "column",
};

export interface IEditPCBModalProps {
  onCloseModal: any;
  isOpen: any;
  tableData: any;
  key1: any;
  val: any;
  setDataForUpdate: any;
  target: any;
  AllData: any;
  setColorButton: any;
  setShowButton: any;
  setCounter: any;
  options: any;
  itmKey: any;
  currentPage: any;
  counter: any;
  type: any;
  mappKeyValue: any;
  tableIdddList: any;
  initialData: any;
  setSelectedOptions: any;
  setTableDataCount?: any;
  lastRow: any;
}

export default function EditModalForm(props: IEditPCBModalProps) {
  const {
    onCloseModal,
    isOpen,
    tableData,
    key1,
    val,
    target,
    AllData,
    setCounter,
    setDataForUpdate,
    setColorButton,
    setShowButton,
    options,
    itmKey,
    currentPage,
    counter,
    type,
    mappKeyValue,
    tableIdddList,
    initialData,
    setSelectedOptions,
    setTableDataCount,
    lastRow,
  } = props;
  const [isSave, setIsSave] = React.useState<any>(false);
  const [isDeleting, setIsDeleting] = React.useState<any>(false);
  const [saveDisabled, setSaveDisabled] = React.useState<any>(true);
  const [totalPercentage, setTotalPercentage] = React.useState<any>(0);
  const selectDuplicate = useBaseModal();

  // let currentTarget: any;
  // if(tableData?.[0]?.total_length > 75){
  //   currentTarget = 75 - target -1;
  // }
  // else {
  //   currentTarget = tableData?.[0]?.total_length - target -1;
  // }


  

  const currentTarget =
    tableData?.[0]?.total_length - target - 1 - (currentPage - 1) * 75;
  const [rowData, setRowData] = React.useState<any>(
    tableData?.[0]?.data?.[
    tableData?.[0]?.total_length - target - 1 - (currentPage - 1) * 75
    ]
  );
  const [originalData, setOriginalData] = useState<any>(
    tableData?.[0]?.data?.[
    tableData?.[0]?.total_length - target - 1 - (currentPage - 1) * 75
    ]);
  const [hasChanged, setHasChanged] = useState<boolean>(false);
  const [pcbComponentChanged, setPCBComponentChanged] = React.useState<any>(0);
  const [rowPrevData, setRowPrevData] = React.useState<any>(lastRow?.[0]);
  const { vault, topVault, projectId } = useRouteParams<any>();
  // const [name, setName] = React.useState<any>(
  //   rowData?.[mappKeyValue["Item Name"]]?.length > 0 ? true : false
  // );
  // const [description, setDescription] = React.useState<any>(
  //   rowData?.[mappKeyValue["Item Description"]]?.length > 0 ? true : false
  // );
  // const [length, setLength] = React.useState<any>(
  //   Number(rowData?.[mappKeyValue["Length"]]) != -1 ? true : false
  // );
  // const [width, setWidth] = React.useState<any>(
  //   Number(rowData?.[mappKeyValue["Width"]]) != -1 ? true : false
  // );
  // const [componentType, setComponentType] = React.useState<any>(
  //   rowData?.[mappKeyValue["Component Type"]]?.length > 0 ? true : false
  // );
  // const [reference, setReference] = React.useState<any>(
  //   rowData?.[mappKeyValue["Reference"]]?.length > 0 ? true : false
  // );
  // const [discount, setDiscount] = React.useState<any>(
  //   rowData?.[mappKeyValue["Discount(%)"]]?.length > 0 ? true : false
  // );
  const [currency, setCurrency] = React.useState<any>(
    rowData?.[mappKeyValue["Currency"]]?.length > 0 ? true : false
  );
  // const [currencyUnit, setCurrencyUnit] = React.useState<any>(
  //   rowData?.[mappKeyValue["Currency Unit"]]?.length > 0 ? true : false
  // );
  // const [unitCost, setUnitCost] = React.useState<any>(
  //   Number(rowData?.[mappKeyValue["Unit Cost"]]) > 0 ? true : false
  // );
  const [isItemNameChanged, setIsItemNameChanged] = React.useState<any>(false);
  const [isItemDescriptionChanged, setIsItemDescriptionChanged] =
    React.useState<any>(false);
  const [initialItemName, setInitialItemName] = React.useState<any>(rowData?.[mappKeyValue["Item Name"]]

  );
  const [initialItemDescription, setInitialItemDescription] =
    React.useState<any>(rowData?.[mappKeyValue["Item Description"]]);
  const imgRef = useRef<any>();
  const displayImage = () => imgRef.current?.click?.();
  const [isAnythingChanged, setIsAnythingChanged] = React.useState<any>(false);
  const [flag, setFlag] = React.useState<any>();
  const [checkDuplicateShow, setCheckDuplicateShow] =
    React.useState<any>(false);
  const [revisionList, setRevisionList] = React.useState<any>();
  const [disabledFeilds, setDisabledFeilds] = React.useState<any>(
    rowData?.[mappKeyValue["Item Name"]]?.length > 0 &&
    rowData?.[mappKeyValue["Item Description"]]?.length > 0
  );
  const [revision, setRevision] = React.useState<any>(true);
  const [selectedRevision, setSelectedRevision] = React.useState<any>();
  const [formType, setFormType] = React.useState<any>();

  const [flagS3Url, setFlagS3Url] = React.useState<any>();
  const [uploadFile, setUploadFile] = React.useState<any>(false);
  const ImageViewer = React.useRef<any>();
  const ImageViewerCS = React.useRef<any>();
  const [srcImages, setSrcImages] = React.useState<any>();
  const [assumptionCardList, setAssumptionCardList] = React.useState<any>();
  const [assumptionCard, setAssumptionCard] = React.useState<any>([]);
  const [disableItemDescriptionAndName, setDisableItemDescriptionAndName] =
    React.useState<any>(false);
  const [revisionData, setRevisionData] = React.useState<any>();
  const [flagUploaded, setFlagUploaded] = React.useState<any>(false);

  React.useEffect(() => {
    if (type == "edit") {
      setRowData(
        tableData?.[0]?.data?.[
        tableData?.[0]?.total_length - target - 1 - (currentPage - 1) * 75
        ]
      );
      setOriginalData(
        tableData?.[0]?.data?.[
        tableData?.[0]?.total_length - target - 1 - (currentPage - 1) * 75
        ]
      );
      
    } else {
      setRowData(initialData);
      setOriginalData(initialData);
    }
  }, [target, currentPage, tableData]);


  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleChangeFile = async (e: any) => {
    setFlag(e.currentTarget.files);
    await PCBS3Upload(
      e.currentTarget.files,
      "PCBS3Upload",
      setFlagS3Url,
      setUploadFile,
      setFlagUploaded
    );
  };
  // React.useEffect(() => {
  //   if (type == "add") {

  //     setRowPrevData(AllData?.slice(-1)[0]);
  //   }
  //   else {

  //     setRowPrevData(AllData?.slice(-1)[0]);
  //   }
  // }, []);

  const handleClick = (srcImg: any, e: any) => {
    e.stopPropagation();
    if (
      srcImg?.length > 0 &&
      srcImg?.split("/").pop()?.split(".").pop() != "pdf"
    ) {
      ImageViewer.current.src = srcImg;
      ImageViewer.current?.click?.();
      setSrcImages(srcImg);
    } else {
      const encodedString = srcImg.replace(/\s/g, "%20");

      window.open(encodedString, "_blank");
    }
  };

  const handleClickCS = (srcImg: any, e: any) => {
    e.stopPropagation();
    if (
      srcImg?.length > 0
      // && srcImg?.split("/").pop()?.split(".").pop() != "pdf"
    ) {
      ImageViewerCS.current.src = srcImg;
      ImageViewerCS.current?.click?.();
      // setSrcImages(srcImg);
    } else {
      const encodedString = srcImg.replace(/\s/g, "%20");

      window.open(encodedString, "_blank");
    }
  };

  // React.useEffect(() => {
  // }, [rowData]);

  const getAssumptionCard = () => {
    API.get(
      `/api/db/location_revision/`,
      {
        project: projectId,
        revision_main: true,
        top_vault: topVault,
      },
      0
    )
      .then((res: any) => {
        // console.log(res?.data);
        setAssumptionCardList(res.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  const checkDuplicate = (itemName: any, itemDescription: any) => {
    if (isItemNameChanged || isItemDescriptionChanged) {
      console.log("disableAllandCheckDuplicates api call");
      API.get(
        `/xcpep/pcbcosting_redirection/`,
        {
          project: projectId,
          item_name: itemName,
          item_description: itemDescription,
          top_vault: topVault,
        },
        0
      )
        .then((res: any) => {
          if (res?.data?.length > 0) {
            setCheckDuplicateShow(true);
            setRevisionList(res?.data);

            setDisabledFeilds(true);
            // setDisableItemDescriptionAndName(true);
          } else {
            setCheckDuplicateShow(false);
            setDisabledFeilds(false);
          }
          setIsItemDescriptionChanged(false);
          setIsItemNameChanged(false);

          // selectDuplicate.open();
        })
        .catch((err: any) => {
          console.log(err);
        });
    } else {
      console.log("no disableAllandCheckDuplicates api call");
    }
  };

  const getObjKeyName = () => {
    if (vault == 0) {
      return { top_vault: topVault };
    } else {
      return { vault: vault };
    }
  };

  React.useEffect(() => {
    if (
      tableData?.[0]?.key != tableIdddList["PCB BOM Table"] ||
      type == "edit"
    ) {
      
      
      setSaveDisabled(!isAnythingChanged);
    }
  }, [isAnythingChanged]);

  const calculateTotalPercentage = () => {
    let total = 0;
    rowData &&
      Object.keys(rowData).map((item: any) => {
        total += Number(rowData?.[item]);
      });
    setTotalPercentage(total);
  };

  const handleDataAdd = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    if (tableData?.[0]?.key == tableIdddList["PCB BOM Table"]) {
      handleDataPCBAdd(e);
    } else {
      handleOtherAdd(e);
    }
    setSelectedOptions([]);
    // setTableDataCount((prev: any) => prev + 1);
  };

  React.useEffect(() => {
    calculateTotalPercentage();
  }, [rowData]);

  React.useEffect(() => {
    getAssumptionCard();
  }, []);

  const handleClickOnSave = (e: any) => {
    if (checkDuplicateShow) {
      selectDuplicate.open();
    } else {
      handleDataAdd(e);
    }
  };

  const handleOtherAdd = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    API.put(
      "/xcpep/component_details/74699/",
      {
        param_value: rowData,
        index: target,
        table_id: tableData?.[0]?.key,
      },
      {
        ...getObjKeyName(),
      },

      0
    )
      .then((res: any) => {
        ADMIN.toast.info("Data Updated Successfully");

        setIsSave(false);
        setCounter((prev: any) => prev + 1);
      })
      .catch((err: any) => {
        setIsSave(false);
        const { data } = err.response;
        Swal.fire({
          icon: "error",
          html: `<div>
                <br />
                <p style="color:"green">${data[0]}</p>   
                 </div>`,
          customClass: {
            container: "swal2Container",
          },
        });
      });
    onCloseModal();
  };

  const handleDataPCBAdd = (e: any) => {
    e.preventDefault();
    e.stopPropagation();

    // const filteredObj = Object.keys(rowData)
    // ?.filter((key) => Object.values(mappKeyValue)?.includes(key?.toString())) // Check if the key is present in obj2Values
    // ?.reduce((acc, key) => {
    //   acc[key] = rowData[key]; // Rebuild the filtered object
    //   return acc;
    // }, {} as any);

    if (formType == "Revision") {
      API.put(
        "/xcpep/component_details/74699/",
        {
          param_value: {
            ...rowData,
            [mappKeyValue["Item Name"]]:
              rowData?.[mappKeyValue["Item Name"]].trim(),
            [mappKeyValue["Item Description"]]:
              rowData?.[mappKeyValue["Item Description"]].trim(),
            [mappKeyValue["Quantity"]]:
              rowData?.[mappKeyValue["Quantity"]] == 0
                ? 1
                : rowData?.[mappKeyValue["Quantity"]],
          },
          index: target,
          table_id: tableData?.[0]?.key,
          purchase_item: selectedRevision?.purchase_item,
          revision: selectedRevision?.revision,
          flag: flagS3Url,
        },
        {
          ...getObjKeyName(),
        },

        0
      )
        .then((res: any) => {
          ADMIN.toast.info("Data Updated Successfully");

          setIsSave(false);
          setCounter((prev: any) => prev + 1);
        })
        .catch((err: any) => {
          setIsSave(false);
          const { data } = err.response;
          Swal.fire({
            icon: "error",
            html: `<div>
                  <br />
                  <p style="color:"green">${data[0]}</p>   
                   </div>`,
            customClass: {
              container: "swal2Container",
            },
          });
        });
      onCloseModal();
    } else {
      if (formType == "Database") {
        API.put(
          "/xcpep/component_details/74699/",
          {
            param_value: {
              ...rowData,
              [mappKeyValue["Item Name"]]:
                rowData?.[mappKeyValue["Item Name"]].trim(),
              [mappKeyValue["Item Description"]]:
                rowData?.[mappKeyValue["Item Description"]].trim(),
              [mappKeyValue["Quantity"]]:
                rowData?.[mappKeyValue["Quantity"]] == 0
                  ? 1
                  : rowData?.[mappKeyValue["Quantity"]],
            },
            index: target,
            table_id: tableData?.[0]?.key,
            purchase_item: selectedRevision?.purchase_item,
            assumption_list: [assumptionCard],
            flag: flagS3Url,
          },
          {
            ...getObjKeyName(),
          },

          0
        )
          .then((res: any) => {
            ADMIN.toast.info("Data Updated Successfully");

            setIsSave(false);
            setCounter((prev: any) => prev + 1);
          })
          .catch((err: any) => {
            setIsSave(false);
            const { data } = err.response;
            Swal.fire({
              icon: "error",
              html: `<div>
                    <br />
                    <p style="color:"green">${data[0]}</p>   
                     </div>`,
              customClass: {
                container: "swal2Container",
              },
            });
          });
        onCloseModal();
      } else {
        const filteredObj = Object.keys(rowData)
          ?.filter((key) =>
            Object.values(mappKeyValue)?.includes(key?.toString())
          ) // Check if the key is present in obj2Values
          ?.reduce((acc, key) => {
            acc[key] = rowData[key]; // Rebuild the filtered object
            return acc;
          }, {} as any);
        API.put(
          "/xcpep/component_details/74699/",
          {
            param_value: {
              ...filteredObj,
              [mappKeyValue["Item Name"]]:
                rowData?.[mappKeyValue["Item Name"]].trim(),
              [mappKeyValue["Item Description"]]:
                rowData?.[mappKeyValue["Item Description"]].trim(),
              [mappKeyValue["Quantity"]]:
                rowData?.[mappKeyValue["Quantity"]] == 0
                  ? 1
                  : rowData?.[mappKeyValue["Quantity"]],
            },
            index: target,
            table_id: tableData?.[0]?.key,
            assumption_list: [assumptionCard],
            flag: flagS3Url,
          },
          {
            ...getObjKeyName(),
          },

          0
        )
          .then((res: any) => {
            ADMIN.toast.info("Data Updated Successfully");

            setIsSave(false);
            setCounter((prev: any) => prev + 1);
          })
          .catch((err: any) => {
            setIsSave(false);
            const { data } = err.response;
            Swal.fire({
              icon: "error",
              html: `<div>
                    <br />
                    <p style="color:"green">${data[0]}</p>   
                     </div>`,
              customClass: {
                container: "swal2Container",
              },
            });
          });
        onCloseModal();
      }
    }

    // API.put(
    //   "/xcpep/component_details/74699/",
    //   {
    //     param_value: {...rowData, assumption_list: assumptionCard},
    //     index: target,
    //     table_id: tableData?.[0]?.key,
    //   },
    //   {
    //     ...getObjKeyName(),
    //   },

    //   0
    // )
    //   .then((res: any) => {
    //     ADMIN.toast.info("Data Updated Successfully");

    //     setIsSave(false);
    //     setCounter((prev: any) => prev + 1);
    //   })
    //   .catch((err: any) => {
    //     setIsSave(false);
    //     const { data } = err.response;
    //     Swal.fire({
    //       icon: "error",
    //       html: `<div>
    //             <br />
    //             <p style="color:"green">${data[0]}</p>
    //              </div>`,
    //       customClass: {
    //         container: "swal2Container",
    //       },
    //     });
    //   });
    // onCloseModal();
  };

  const handleDelete = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this data!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
      customClass: {
        container: "swal2Container",
      },
    }).then((result) => {
      if (result.isConfirmed) {
        setIsDeleting(true);
        // setSelectedIdDelete(ind);
        API.put(
          "/xcpep/component_details/74699/",
          {
            table_id: tableData?.[0]?.key,
            index: target,
          },
          {
            ...getObjKeyName(),
          },
          0
        )
          .then((res: any) => {
            setIsDeleting(false);
            // setSelectedIdDelete("");
            setColorButton("");
            setShowButton(false);

            setCounter((prev: any) => prev + 1);
            onCloseModal();
          })
          .catch((err: any) => {
            setIsDeleting(false);
            onCloseModal();
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire({
          title: "Cancelled",
          text: "Your data is safe :)",
          icon: "error",
          customClass: {
            container: "swal2Container",
          },
        });
      }
    });
  };

  // const handleCloseModal = () => {
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: "This action will discard all of your unsaved data. Please make sure that everything has been saved before proceeding.",
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonText: "Yes, Save and Close",
  //     cancelButtonText: "No",
  //     customClass: {
  //       container: "swal2Container",
  //     },
  //   }).then((result) => {
  //     if (result.isConfirmed) {

  //       handleDataAdd()
  //       onCloseModal();
  //     }
  //   });
  // };

  const handleRedirectiontoDB = () => {
    if (formType == "Revision") {
      const date = selectedRevision?.revised_on.split("-");

      const monthName = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const databaseName = "Electronic Child Components";
      const searchKeywords = `${rowData?.[mappKeyValue["Item Name"]]},${rowData?.[mappKeyValue["Item Description"]]
        }`;
      const url = `#/data-base/revision-database/1/${date[0]}/${monthName[date[1] - 1]
        }/${selectedRevision?.revision}/${selectedRevision?.revision
        }/bop/${databaseName}/true/${rowData?.[mappKeyValue["Item Name"]]}/0`;
      window.open(url, "_blank");
    } else {
      const databaseName = "Electronic Child Components";
      const searchKeywords = `${rowData?.[mappKeyValue["Item Name"]]},${rowData?.[mappKeyValue["Item Description"]]
        }`;
      const url = `#/data-base/revision-database/1/0/0/0/0/bopDB/${databaseName}/true/${rowData?.[mappKeyValue["Item Name"]]
        }/0`;
      window.open(url, "_blank");
    }
  };

  const disableAllandCheckDuplicates = () => {
    if (
      rowData?.[mappKeyValue["Item Description"]] != "" &&
      rowData?.[mappKeyValue["Item Name"]] != "" &&
      rowData?.[mappKeyValue["Item Description"]] &&
      rowData?.[mappKeyValue["Item Name"]] &&
      rowData?.[mappKeyValue["Item Description"]]?.length > 0 &&
      rowData?.[mappKeyValue["Item Name"]]?.length > 0
    ) {
      checkDuplicate(
        rowData?.[mappKeyValue["Item Name"]],
        rowData?.[mappKeyValue["Item Description"]]
      );
    } else {
    }
  };

  React.useEffect(() => {
    if (type == "edit") {
      API.get(
        `/xcpep/pcbcosting_redirection/`,
        {
          project: projectId,
          item_name: rowData?.[mappKeyValue["Item Name"]],
          item_description: rowData?.[mappKeyValue["Item Description"]],
          top_vault: topVault,
          get_selected_assumption: true,
          vault: vault,
        },
        0
      )
        .then((res: any) => {
          setRevisionData(res?.data);
          // setAssumptionCard(res?.data);
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  }, [assumptionCardList]);

  const handleRedirection = () => {
    const date = revisionData?.revised_on.split("-");

    const monthName = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const searchKeywords = `${rowData?.[mappKeyValue["Item Name"]]},${rowData?.[mappKeyValue["Item Description"]]
      }`;
    const databaseName = "Electronic Child Components";
    const url = `#/data-base/revision-database/1/${date[0]}/${monthName[date[1] - 1]
      }/${revisionData?.id}/${revisionData?.id}/bop/${databaseName}/true/${rowData?.[mappKeyValue["Item Name"]]
      }/0`;
    window.open(url, "_blank");
  };

  const handleClose = () => {
    if (type == "edit") {
      onCloseModal();
    } else {
      API.put(
        "/xcpep/component_details/74699/",
        {
          table_id: tableData?.[0]?.key,
          index: target,
        },
        {
          ...getObjKeyName(),
        },
        0
      )
        .then((res: any) => {
          setIsDeleting(false);
          // setSelectedIdDelete("");
          setColorButton("");
          setShowButton(false);

          setCounter((prev: any) => prev + 1);
          onCloseModal();
        })
        .catch((err: any) => {
          setIsDeleting(false);
          onCloseModal();
        });
    }
  };

  console.log("tableData", tableData);

  console.log("rowData", rowData);
  console.log(originalData, "originalData");
  

  // console.log("checkDuplicateShow", checkDuplicateShow);

  console.log("mappKeyValue", mappKeyValue);
  
  // // console.log(revisionData, "revisionData");
  // console.log(assumptionCardList, "assumptionCardList");


  // console.log(disabledFeilds, "disabledFeilds");
  // console.log(disableItemDescriptionAndName, "disableItemDescriptionAndName");
  // console.log(revision, "revision");

  // ----------------------------------------------

  const [countryList, setCountryList] = React.useState<any[]>([]);
  const [currencyCodes, setCurrencyCodes] = React.useState<string[]>([]);
  const [countryLoading, setCountryLoading] = React.useState<boolean>(false);
  const [selectedCurrencyCodes, setSelectedCurrencyCodes] =
    React.useState<any>("");

  const getCountryData = () => {
    setCountryLoading(true);
    API.get(
      "customer/currency_search/",
      { all_currencies: true, database: true },
      0
    )
      .then((res: any) => {
        const c_codes = res?.data.map((item: any) => item.currency_code);
        setCurrencyCodes(c_codes);
        setCountryList(res.data);
        setCountryLoading(false);
      })
      .catch((err: any) => {
        console.error("Error fetching currency data:", err);
        setCountryLoading(false);
      });
  };

  React.useEffect(() => {
    getCountryData();
  }, []);

  const flagMap = countryList?.reduce((acc, country) => {
    acc[country?.currency_code] = country?.flag;
    return acc;
  }, {});

  // ----------------------------------------------

  const sortOrder = [
    "Item Name",
    "Item Description",
    "Component Type",
    "Location of Component",
    "PCB Component Size",
    "Length",
    "Width",
    "Currency",
    "Unit",
    "Discount(%)",
    "Reference",
    "Unit Cost",
    "Quantity",
  ];

  useEffect(() => {
    // Check if assumptionCardList and revisionData are loaded
    if (revisionData?.id && assumptionCardList?.length > 0) {
      setAssumptionCard(revisionData?.id);
      // Find the matching item in the assumptionCardList based on id
      const prepopulatedCard = assumptionCardList?.find(
        (item: any) => item?.id === revisionData?.id
      );

      if (prepopulatedCard) {
        setAssumptionCard(prepopulatedCard?.id);
      }


    }
 

  }, [assumptionCardList, revisionData]);



  useEffect(() => {
    const baseCondition = 
    typeof assumptionCard === 'number' &&
    Number(rowData?.[mappKeyValue["Unit Cost"]]) > 0 &&
    !isNaN(Number(rowData?.[mappKeyValue["Unit Cost"]])) &&
    Number(rowData?.[mappKeyValue["Unit Cost"]]) > 0 &&
    !isNaN(Number(rowData?.[mappKeyValue["Quantity"]])) &&
    Number(rowData?.[mappKeyValue["Quantity"]]) > 0 &&
    !isNaN(Number(rowData?.[mappKeyValue["Length"]])) &&
    Number(rowData?.[mappKeyValue["Length"]]) > 0 &&
    !isNaN(Number(rowData?.[mappKeyValue["Width"]])) &&
    Number(rowData?.[mappKeyValue["Width"]]) > 0 &&
    !isNaN(Number(rowData?.[mappKeyValue["Discount(%)"]])) &&
    Number(rowData?.[mappKeyValue["Discount(%)"]]) > 0 &&
    rowData?.[mappKeyValue["Reference"]] &&
    rowData?.[mappKeyValue["Reference"]] !== "" &&
    rowData?.[mappKeyValue["Item Name"]] &&
    rowData?.[mappKeyValue["Item Name"]] !== "" &&
    rowData?.[mappKeyValue["Item Description"]] &&
    rowData?.[mappKeyValue["Item Description"]] !== "" &&
    rowData?.[mappKeyValue["Component Type"]] &&
    rowData?.[mappKeyValue["Component Type"]] !== "" &&
    rowData?.[mappKeyValue["Currency"]] &&
    rowData?.[mappKeyValue["Currency"]] !== "" &&
    rowData?.[mappKeyValue["Unit"]] &&
    rowData?.[mappKeyValue["Unit"]] !== "" &&
    rowData?.[mappKeyValue["PCB Component Size"]] &&
    rowData?.[mappKeyValue["PCB Component Size"]] !== "" &&
    rowData?.[mappKeyValue["Location of Component"]] &&
    rowData?.[mappKeyValue["Location of Component"]] !== "";

    if (type === "add") {
      if (baseCondition && flagS3Url !== undefined) {
        setSaveDisabled(false) 
      } 
        
        
    } else {
      if (baseCondition) {
        if (hasChanged) {
          setSaveDisabled(false); // Enable button if changes have been made
        }
        
      } 
    }
    

    if (
      rowData?.[mappKeyValue["Currency"]] &&
      rowData?.[mappKeyValue["Currency"]] !== ""
    ) {
      setSelectedCurrencyCodes(rowData?.[mappKeyValue["Currency"]]);
    }
  }, [rowData, assumptionCard, flagS3Url]);



  useEffect(() => {
    const pcbComponentSize = rowData?.[mappKeyValue["PCB Component Size"]];

    // Run the logic only if PCB Component Size is defined and valid
    if (pcbComponentSize && pcbComponentChanged > 0) {

      const pcbComponentSizeString = pcbComponentSize?.toString();
      if (pcbComponentSizeString?.length > 0) {
        const sizeLength = pcbComponentSizeString?.length;
        let lengthValue = "";
        let widthValue = "";

        // Handle cases based on the number of digits
        if (sizeLength % 2 === 0) {
          // Even length - divide exactly by half
          const half = sizeLength / 2;
          lengthValue = `.${pcbComponentSizeString?.slice(0, half)}`;
          widthValue = `.${pcbComponentSizeString?.slice(half)}`;
        } else {
          // Odd length - left side gets one extra digit
          const half = Math.floor(sizeLength / 2) + 1;
          lengthValue = `.${pcbComponentSizeString?.slice(0, half)}`;
          widthValue = `.${pcbComponentSizeString?.slice(half)}`;
        }

        // Avoid re-rendering by checking if the values have actually changed
        setRowData((prev: any) => {
          const currentLength = prev[mappKeyValue["Length"]];
          const currentWidth = prev[mappKeyValue["Width"]];

          // Only update if the values have changed
          if (currentLength !== lengthValue || currentWidth !== widthValue) {
            return {
              ...prev,
              [mappKeyValue["Length"]]: lengthValue,
              [mappKeyValue["Width"]]: widthValue,
            };
          }

          return prev; // No update if the values are the same
        });
      }
    }
  }, [rowData?.[mappKeyValue["PCB Component Size"]], mappKeyValue, pcbComponentChanged]);

  const imageUrls: any = [
    `${tableData?.[0]?.header_item?.[0]?.cdn}/PCBS3Upload/101352/pcb.png`,
    `${tableData?.[0]?.header_item?.[0]?.cdn}/PCBS3Upload/16198/pcb2.png`,
  ];

  const filteredItems = tableData?.header_item?.filter((itm: any) =>
    ![
      "Item Name",
      "Item Description",
      "Component Type",
      "Length",
      "Width",
      "Unit Cost",
      "Reference",
      "Discount(%)",
      "Currency",
      "Unit",
      "Quantity",
      "PCB Component Size",
      "Location of Component"
    ].includes(itm.paramter_name)
  );

  useEffect(() => {
    const isEqual = JSON.stringify(rowData) === JSON.stringify(originalData);
     setHasChanged(!isEqual);

  }, [rowData, originalData]);

  const handleKeyDown = (e:any, item?:any) => {
    // List of types that should not allow the negative sign
    const disallowedNegativeTypes = [
      "nonzero-positive-int", 
      "positive-float", 
      "nonzero-positive-float"
    ];
  
    // Prevent the user from typing "-" for certain types
    if (disallowedNegativeTypes.includes(item?.type) && e.key === "-") {
      e.preventDefault(); // Block the negative sign
    }
  };

  const getMinValue = (item?:any) => {
    switch (item?.data_type) {
      case "nonzero-positive-int":
      case "positive-int":
        return 1; // Positive integers and non-zero positive integers should start from 1 (no zero or negative values allowed)
      
      case "nonzero-positive-float":
      case "positive-float":
        return 0.0001; // For non-zero positive float and positive float, we allow values greater than 0, but set a very small minimum value to avoid zero
      
      case "float":
      case "nonzero-float":
        return 0; // For general float and non-zero float, we allow 0 (but not for non-zero float if required, adjust accordingly)
  
      case "percentage":
        return 0; // Percentage typically starts from 0% (0) and can go up to 100%
      
      case "number":
        return -Infinity; // For generic "number" type, allow any number (including negative values)
  
      case "integer":
        return -Infinity; // Integer should allow any value, including negative integers
      
      default:
        return undefined; // If no valid type, no restriction on min value
    }
  };
  


  //  console.log("hasChanged", hasChanged);
  //  console.log(saveDisabled, "hasChanged save disabled");
  // console.log(saveDisabled && !checkDuplicateShow, "hasChanged saveDisabled && !checkDuplicateShow");
  
  
  
  
  return (
    <Modal
      open={isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      {/* sx={style} adl-scrollbar="true" adl-scrollbar-width="0.3" */}
      <Box sx={style} >
        <Box
          sx={{

            display: "flex",
            alignItems: "center",
            height: "3rem",
            padding: "2rem 0.75rem",
            borderBottom: "1px solid #e0e0e0",
          }}
        >
          {tableData?.[0]?.key == tableIdddList["PCB BOM Table"] ? (
            <Typography sx={{ display: "flex", gap: "0.2rem", width: "15%" }}>
              Last Entry:{" "}
              <Tooltip
                title={
                  <React.Fragment>
                    <Typography color="inherit">
                      S No. : {rowPrevData?.[mappKeyValue["Sl No."]]}
                    </Typography>
                    <Typography color="inherit">
                      Item Name : {rowPrevData?.[mappKeyValue["Item Name"]]}
                    </Typography>
                    <Typography color="inherit">
                      Item Description :{" "}
                      {rowPrevData?.[mappKeyValue["Item Description"]]}
                    </Typography>
                    <Typography color="inherit">
                      Component Type :{" "}
                      {rowPrevData?.[mappKeyValue["Component Type"]]}
                    </Typography>
                    <Typography color="inherit">
                      Quantity : {rowPrevData?.[mappKeyValue["Quantity"]]}
                    </Typography>
                    <Typography color="inherit">
                      Length : {rowPrevData?.[mappKeyValue["Length"]]}
                    </Typography>
                    <Typography color="inherit">
                      Width : {rowPrevData?.[mappKeyValue["Width"]]}
                    </Typography>
                    <Typography color="inherit">
                      Unit Cost : INR {rowPrevData?.[mappKeyValue["Unit Cost"]]}
                    </Typography>
                    <Typography color="inherit">
                      Reference : {rowPrevData?.[mappKeyValue["Reference"]]}
                    </Typography>
                    {/* <Typography color="inherit">Flag : {rowPrevData?.[mappKeyValue['Flag']]}</Typography>
              <Typography color="inherit">Revision : {rowPrevData?.[mappKeyValue['Revision']]}</Typography> */}
                  </React.Fragment>
                }
              >
                <InfoIcon />
              </Tooltip>
            </Typography>
          ) : (
            ""
          )}
          <Typography
            id="modal-modal-title"
            style={{
              fontSize: "1rem",
              fontWeight: "600",
              flex: 1,
              textAlign: "center",
            }}
          >
            {tableData?.[0]?.key == tableIdddList["PCB BOM Table"]
              ? type == "edit"
                ? "Edit ECP Data"
                : "Add ECP Data"
              : "Edit Row Data"}
          </Typography>
          <Box sx={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
            {tableData?.[0]?.key == tableIdddList["PCB BOM Table"] &&
              type == "add" &&
              (formType == "Revision" || formType == "Database") ? (
              <Button
                variant="outlined"
                sx={{ cursor: "pointer", margin: "0.5rem", }}
                onClick={handleRedirectiontoDB}
              >
                {formType == "Revision" ? "Go To Revision" : "Go To Database"}
              </Button>
            ) : null}
            {tableData?.[0]?.key == tableIdddList["PCB BOM Table"] &&
              type == "edit" ? (
              <Button
                variant="outlined"
                sx={{ cursor: "pointer", margin: "0.5rem", }}
                onClick={handleRedirection}
              >
                {" "}
                Go to Revision{" "}
              </Button>
            ) : null}
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={handleClose}
            />
          </Box>

        </Box>

        <Box sx={{ flex: "1", paddingTop: "0.75rem" }} adl-scrollbar="true" adl-scrollbar-width="0.3">
          <Box sx={{ borderBottom: "0.1px solid #e0e0e0", padding: "0.5rem" }}>
            {tableData?.[0]?.key == tableIdddList["PCB BOM Table"] && (
              <>
                <Typography
                  sx={{
                    display: "flex",
                    gap: "0.2rem",
                    width: "100%",
                    padding: "0.5rem",
                    marginLeft: "0.5rem",
                  }}
                >
                  Database Parameters
                </Typography>
              </>
            )}

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                rowGap: "1.5rem",
                margin: "1rem",
                columnGap: "1rem",
              }}
            >
              {tableData?.[0]?.key == tableIdddList["PCB BOM Table"] && (
                <>
                  {tableData?.[0]?.header_item
                    ?.filter(
                      (itm: any) =>
                        itm?.paramter_name === "Item Name" ||
                        itm?.paramter_name === "Quantity" ||
                        itm?.paramter_name === "Item Description" ||
                        itm?.paramter_name === "Component Type" ||
                        itm?.paramter_name === "Length" ||
                        itm?.paramter_name === "Width" ||
                        itm?.paramter_name === "Unit Cost" ||
                        itm?.paramter_name === "Reference" ||
                        itm?.paramter_name === "Discount(%)" ||
                        itm?.paramter_name === "Currency" ||
                        itm?.paramter_name === "Unit" ||
                        itm?.paramter_name === "PCB Component Size" ||
                        itm?.paramter_name === "Location of Component"

                    )
                    ?.sort(
                      (a: any, b: any) =>
                        sortOrder.indexOf(a?.paramter_name) -
                        sortOrder.indexOf(b?.paramter_name)
                    )
                    ?.map((item: any, index: any) => {
                     
                      
                      switch (item?.data_type) {
                        case "text":
                        case "string":
                          if (item?.paramter_name === "Currency") {
                            return (
                              <>

                                <FormControl variant="standard" sx={{ width: "100%", marginTop: "-0.3rem" }}>
                                  <InputLabel id="demo-simple-select-standard-label"
                                    sx={{
                                      fontSize: "1rem",
                                      fontWeight: "400",
                                      color: disabledFeilds ? "gray" : "primary.main",
                                      "& .MuiFormLabel-asterisk": {
                                        color: "#d32f2f",
                                      },
                                    }}
                                    required> {item?.paramter_name}</InputLabel>
                                  <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    label="Age"
                                    sx={{
                                      width: "100%",
                                      "& .MuiSelect-select": {
                                        fontSize: "1rem",
                                        color: tableData?.[0]?.key === tableIdddList["PCB BOM Table"] ? (disabledFeilds ? "gray" : "primary.main") : "primary.main",
                                      },
                                      "& .MuiSvgIcon-root": {
                                        color: disabledFeilds ? "gray" : "primary.main",
                                      },
                                      "&:before": { // Before the input (default state)
                                        borderBottom: "1px solid #ddefff",
                                      },
                                      "&:after": { // After the input (focused state)
                                        borderBottom: "1px solid primary.main",
                                      },
                                      "&.Mui-error": {
                                        color: "#d32f2f",
                                        "&:before": {
                                          borderBottomColor: "#d32f2f",
                                        },
                                        "&:after": {
                                          borderBottomColor: "#d32f2f",
                                        },
                                      },
                                    }}
                                    multiple={false}
                                    value={selectedCurrencyCodes}
                                    onChange={(event: any) => {
                                      // setSaveDisabled(true);
                                      setSelectedCurrencyCodes(event.target.value);
                                      if (item?.paramter_name === "PCB Component Size") {
                                        setPCBComponentChanged((prev: any) => prev + 1)
                                      }

                                      if (event.target.value === "") {
                                        setRowData({
                                          ...rowData,
                                          [mappKeyValue["Currency"]]:
                                            event.target.value,
                                        });
                                        setCurrency(true);

                                        setIsAnythingChanged(true);
                                      } else {
                                        setRowData({
                                          ...rowData,
                                          [mappKeyValue["Currency"]]:
                                            event.target.value,
                                        });
                                        setCurrency(true);

                                        setIsAnythingChanged(true);
                                      }
                                      // setSaveDisabled(false);
                                    }}
                                    input={
                                      <Input
                                        id="standard-input"
                                        placeholder="Currency"
                                      />
                                    }
                                  >
                                    {currencyCodes.map((name: any, index: any) => (
                                      <MenuItem key={index} value={name}>
                                        {flagMap[name] && (
                                          <img
                                            src={`data:image/png;base64, ${flagMap[name]}`}
                                            alt={`${name} flag`}
                                            style={{
                                              width: "1.5rem",
                                              height: "1.5rem",
                                              marginLeft: "1rem",
                                              marginRight: "1rem",
                                            }}
                                          />
                                        )}
                                        {name}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>

                              </>
                            );
                          }
                          return (
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                gap: "0.3rem",
                              }}
                            >

                              <TextField
                                id="outlined-basic"
                                variant="standard"
                                type="text"
                                label={item?.paramter_name}
                                required={true} // Add an asterisk if required
                                value={rowData?.[item?.key] || ""}
                                disabled={
                                  item?.paramter_name === "Item Name" ||
                                    item?.paramter_name === "Item Description"
                                    ? disabledFeilds && disableItemDescriptionAndName
                                    : item?.paramter_name === "Component Type" ||
                                      item?.paramter_name === "Length" ||
                                      item?.paramter_name === "Width" ||
                                      item?.paramter_name === "Discount(%)" ||
                                      item?.paramter_name === "Unit" ||
                                      item?.paramter_name === "Currency" ||
                                      item?.paramter_name === "Quantity" ||
                                      item?.paramter_name === "Location of Component" ||
                                      item?.paramter_name === "PCB Component Size"
                                      ? disabledFeilds
                                      : item?.paramter_name === "Unit Cost" ||
                                        item?.paramter_name === "Reference"
                                        ? disabledFeilds && revision
                                        : false
                                }
                                onBlur={() => {
                                  if (
                                    item?.paramter_name === "Item Name" ||
                                    item?.paramter_name === "Item Description"
                                  ) {
                                    disableAllandCheckDuplicates();
                                  }
                                }}
                                onChange={(e) => {
                                  setRowData({
                                    ...rowData,
                                    [item?.key]: e.target.value,
                                  });
                                  setIsAnythingChanged(true);

                                  if (item?.paramter_name === "Item Name") {
                                    setIsItemNameChanged(true);
                                  }
                                  if (item?.paramter_name === "Item Description") {
                                    setIsItemDescriptionChanged(true);
                                  }
                                  if (item?.paramter_name === "PCB Component Size") {
                                    setPCBComponentChanged((prev: any) => prev + 1);
                                  }
                                }}
                                autoFocus={item?.key === key1}
                                InputLabelProps={{
                                  sx: {
                                    fontSize: "1rem", // Adjust the size as needed
                                    color:
                                      (item?.paramter_name === "Item Name" || item?.paramter_name === "Item Description")
                                        ? (disabledFeilds && disableItemDescriptionAndName ? "gray" : "primary.main")
                                        : (disabledFeilds || (item?.paramter_name === "Unit Cost" && revision)) ? "gray" : "primary.main", // Adjust as necessary
                                    "& .MuiFormLabel-asterisk": {
                                      color: "#d32f2f",
                                    },
                                       
                                  },
                                }}
                                sx={{
                                  width: "100%",
                                  "& .MuiSvgIcon-root": {
                                    color: "#000",
                                  },
                                  ".MuiInputBase-input.MuiOutlinedInput-input": {
                                    fontSize: "1rem",
                                    color:
                                      tableData?.[0]?.key === tableIdddList["PCB BOM Table"]
                                        ? "red"
                                        : "primary.main",
                                  },
                                  "& .Mui-error": {
                                    color: "#d32f2f",
                                    borderBottomColor: "#d32f2f",
                                  },
                                  "& .MuiInput-underline:before": {
                                    borderBottomColor: "primary.light",
                                  },
                                  "& .MuiInput-underline:after": {
                                    borderBottomColor: "primary.main",
                                  },
                                  "& .MuiInput-underline": { fontSize: "1rem" },
                                }}
                                inputProps={{
                                  style: { color: "primary.main" }, // Set input text color
                                }}
                              />

                            </Box>
                          );

                        case "number":
                        case "float":
                        case "nonzero-positive-int":
                        case "nonzero-positive-float":
                        case "positive-float":
                        case "nonzero-float":
                        case "percentage":
                        case "positive-int":
                        case "integer":
                        case "percentage":
                          return (
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                gap: "0.3rem",
                              }}
                            >
                              <TextField
                                id="outlined-basic"
                                variant="standard"
                                type="number"
                                label={`${item?.paramter_name}`}
                                required={true} // Add an asterisk if required
                                value={rowData?.[item?.key] || 0}
                                onBlur={() => {
                                  if (
                                    item?.paramter_name === "Item Name" ||
                                    item?.paramter_name === "Item Description"
                                  ) {
                                    disableAllandCheckDuplicates();
                                  }
                                }}
                                disabled={
                                  item?.paramter_name == "Item Name" ||
                                    item?.paramter_name == "Item Description"
                                    ? disabledFeilds &&
                                    disableItemDescriptionAndName
                                    : item?.paramter_name == "Component Type" ||
                                      item?.paramter_name == "Length" ||
                                      item?.paramter_name == "Width" ||
                                      item?.paramter_name == "Discount(%)" ||
                                      item?.paramter_name == "Unit" ||
                                      item?.paramter_name == "Currency" ||
                                      item?.paramter_name == "Quantity" ||
                                      item?.paramter_name == "Location of Component" ||
                                      item?.paramter_name == "PCB Component Size"
                                      ? disabledFeilds
                                      : item?.paramter_name === "Unit Cost" ||
                                        item?.paramter_name == "Reference"
                                        ? disabledFeilds && revision
                                        : false
                                }
                                onChange={(e) => {

                                  if (
                                    itmKey == tableIdddList["Material Percentage"]
                                  ) {
                                    setTotalPercentage(
                                      totalPercentage -
                                      rowData?.[item?.key] +
                                      Number(e.target.value)
                                    );
                                  }
                                  setRowData({
                                    ...rowData,
                                    [item?.key]: e.target.value,
                                  });
                                  setIsAnythingChanged(true);
                                  if (item?.paramter_name === "Item Name") {
                                    setIsItemNameChanged(true);
                                  }
                                  if (
                                    item?.paramter_name === "Item Description"
                                  ) {
                                    setIsItemDescriptionChanged(true);
                                  }
                                  if (item?.paramter_name === "PCB Component Size") {
                                    setPCBComponentChanged((prev: any) => prev + 1)
                                  }
                                }}
                                autoFocus={item?.key === key1 ? true : false}
                                InputLabelProps={{
                                  sx: {
                                    fontSize: "1rem",
                                    color:
                                      (item?.paramter_name === "Item Name" || item?.paramter_name === "Item Description")
                                        ? (disabledFeilds && disableItemDescriptionAndName ? "gray" : "primary.main")
                                        : (disabledFeilds || (item?.paramter_name === "Unit Cost" && revision)) ? "gray" : "primary.main", // Adjust as necessary
                                    "& .MuiFormLabel-asterisk": {
                                      color: "#d32f2f",
                                    },
                                  },
                                }}
                                inputProps={{
                                  min: 
                                   getMinValue(item)
                                }}
                                // onKeyDown={(e)=> handleKeyDown(e, item)} // Prevent "-" from being entered
                                sx={{
                                  width: "100%",
                                  "& .MuiSvgIcon-root": {
                                    color: "#000",
                                  },
                                  ".MuiInputBase-input.MuiOutlinedInput-input": {
                                    fontSize: "1rem",
                                    color:
                                      tableData?.[0]?.key ===
                                        tableIdddList["PCB BOM Table"]
                                        ? "#000000"
                                        : "primary.main",
                                  },
                                  "& .Mui-error": {
                                    color: "#d32f2f",
                                    borderBottomColor: "#d32f2f",
                                  },
                                  "& .MuiInput-underline:before": {
                                    borderBottomColor: "primary.light",
                                  },
                                  "& .MuiInput-underline:after": {
                                    borderBottomColor: "primary.main",
                                  },
                                  "& .MuiInput-underline": { fontSize: "1rem" },
                                }}
                              />

                              {/* <TextField
                            id="outlined-basic"
                            variant="outlined"
                            type="number"
                            disabled={item?.paramter_name == "Result"}
                            value={rowData?.[item?.key] || 0}
                            onChange={(e) => {
                              // let temp = [...val]
                              // temp[index] = e.target.value
                              if (
                                itmKey == tableIdddList["Material Percentage"]
                              ) {
                                setTotalPercentage(
                                  totalPercentage -
                                    rowData?.[item?.key] +
                                    Number(e.target.value)
                                );
                              }
                              setRowData({
                                ...rowData,
                                [item?.key]: e.target.value,
                              });

                              setIsAnythingChanged(true);
                            }}
                            autoFocus={item?.key === key1 ? true : false}
                            sx={{
                              width: "100%",
                              color: "#000",
                              "& .MuiFormLabel-root": {
                                color: "#000",
                              },
                              "& .MuiFormLabel-root.Mui-focused": {
                                color: "#000",
                              },
                              "& .MuiSvgIcon-root": {
                                color: "#000",
                              },
                              ".MuiInputBase-input.MuiOutlinedInput-input": {
                                fontSize: "1rem",
                                color:
                                  tableData?.[0]?.key ==
                                  tableIdddList["PCB BOM Table"]
                                    ? "#000000"
                                    : "primary.main",
                              },
                              "& .Mui-error": {
                                color: "#d32f2f",
                                borderBottomColor: "#d32f2f",
                              },
                              "& .MuiInput-underline:before": {
                                borderBottomColor: "primary.light",
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: "primary.main",
                              },
                              "& .MuiInput-underline": { fontSize: "1rem" },
                            }}
                          /> */}
                            </Box>
                          );
                        case "BOM-table-calculator":
                          return (
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                gap: "0.3rem",
                              }}
                            >
                              <TextField
                                id="outlined-basic"
                                variant="standard"
                                type="number"
                                label={item?.paramter_name}
                                required={true} // Add an asterisk if required
                                value={rowData?.[item?.key] || 0}
                                disabled={
                                  item?.paramter_name == "Item Name" ||
                                    item?.paramter_name == "Item Description"
                                    ? disabledFeilds &&
                                    disableItemDescriptionAndName
                                    : item?.paramter_name == "Component Type" ||
                                      item?.paramter_name == "Length" ||
                                      item?.paramter_name == "Width" ||
                                      item?.paramter_name == "Discount(%)" ||
                                      item?.paramter_name == "Unit" ||
                                      item?.paramter_name == "Currency" ||
                                      item?.paramter_name == "Quantity" ||
                                      item?.paramter_name == "Location of Component" ||
                                      item?.paramter_name == "PCB Component Size"
                                      ? disabledFeilds
                                      : item?.paramter_name === "Unit Cost" ||
                                        item?.paramter_name == "Reference"
                                        ? disabledFeilds && revision
                                        : false
                                }
                                onChange={(e) => {
                                  if (
                                    itmKey == tableIdddList["Material Percentage"]
                                  ) {
                                    setTotalPercentage(
                                      totalPercentage -
                                      rowData?.[item?.key] +
                                      Number(e.target.value)
                                    );
                                  }
                                  setRowData({
                                    ...rowData,
                                    [item?.key]: e.target.value,
                                  });

                                  if (item?.paramter_name === "Item Name") {
                                    setIsItemNameChanged(true);
                                  }
                                  if (
                                    item?.paramter_name === "Item Description"
                                  ) {
                                    setIsItemDescriptionChanged(true);
                                  }
                                  if (item?.paramter_name === "PCB Component Size") {
                                    setPCBComponentChanged((prev: any) => prev + 1)
                                  }
                                }}
                                onBlur={() => {
                                  if (
                                    item?.paramter_name === "Item Name" ||
                                    item?.paramter_name === "Item Description"
                                  ) {
                                    disableAllandCheckDuplicates();
                                  }
                                }}
                                autoFocus={item?.key === key1 ? true : false}
                                InputLabelProps={{
                                  sx: {
                                    fontSize: "1rem",
                                    color:
                                      (item?.paramter_name === "Item Name" || item?.paramter_name === "Item Description")
                                        ? (disabledFeilds && disableItemDescriptionAndName ? "gray" : "primary.main")
                                        : (disabledFeilds || (item?.paramter_name === "Unit Cost" && revision)) ? "gray" : "primary.main", // Adjust as necessary
                                    "& .MuiFormLabel-asterisk": {
                                      color: "#d32f2f",
                                    },
                                  },
                                }}
                                sx={{
                                  width: "100%",
                                  "& .MuiSvgIcon-root": {
                                    color: "#000",
                                  },
                                  ".MuiInputBase-input.MuiOutlinedInput-input": {
                                    fontSize: "1rem",
                                    color:
                                      tableData?.[0]?.key ===
                                        tableIdddList["PCB BOM Table"]
                                        ? "#000000"
                                        : "primary.main",
                                  },
                                  "& .Mui-error": {
                                    color: "#d32f2f",
                                    borderBottomColor: "#d32f2f",
                                  },
                                  "& .MuiInput-underline:before": {
                                    borderBottomColor: "primary.light",
                                  },
                                  "& .MuiInput-underline:after": {
                                    borderBottomColor: "primary.main",
                                  },
                                  "& .MuiInput-underline": { fontSize: "1rem" },
                                }}
                              />
                            </Box>
                          );

                        case "dropdown":
                        case "singleselect":
                          return (
                            <Box
                              sx={{
                                width: "100%",
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                                gap: "0.3rem",
                                marginTop: "-0.3rem"
                              }}
                            >
                              <FormControl variant="standard" sx={{ width: "100%" }}>
                                <InputLabel id="demo-simple-select-standard-label"
                                  sx={{
                                    fontSize: "1rem",
                                    fontWeight: "400",
                                    color: 
                                    (item?.paramter_name === "Item Name" || item?.paramter_name === "Item Description")
                                      ? (disabledFeilds && disableItemDescriptionAndName ? "gray" : "primary.main")
                                      : (disabledFeilds || (item?.paramter_name === "Unit Cost" && revision)) ? "gray" : "primary.main",
                                 
                                    "& .MuiFormLabel-asterisk": {
                                      color: "#d32f2f",
                                    },
                                  }}
                                  required> {item?.paramter_name}</InputLabel>
                                <Select
                                  labelId="demo-simple-select-standard-label"
                                  id="demo-simple-select-standard"
                                  label="Age"
                                  sx={{
                                    width: "100%",
                                    "& .MuiSelect-select": {
                                      fontSize: "1rem",
                                      color: tableData?.[0]?.key === tableIdddList["PCB BOM Table"] ? "primary.main" : "primary.main",
                                    },
                                    "& .MuiSvgIcon-root": {
                                      color: (item?.paramter_name === "Item Name" || item?.paramter_name === "Item Description")
                                      ? (disabledFeilds && disableItemDescriptionAndName ? "gray" : "primary.main")
                                      : (disabledFeilds || (item?.paramter_name === "Unit Cost" && revision)) ? "gray" : "primary.main",
                                    },
                                    "&:before": { // Before the input (default state)
                                      borderBottom: "1px solid #ddefff",
                                    },
                                    "&:after": { // After the input (focused state)
                                      borderBottom: "1px solid primary.main",
                                    },
                                    "&.Mui-error": {
                                      color: "#d32f2f",
                                      "&:before": {
                                        borderBottomColor: "#d32f2f",
                                      },
                                      "&:after": {
                                        borderBottomColor: "#d32f2f",
                                      },
                                    },
                                  }}
                                  multiple={false}
                                  value={rowData?.[item?.key] || ""}
                                  disabled={
                                    item?.paramter_name === "Item Name" || item?.paramter_name === "Item Description"
                                      ? disabledFeilds && disableItemDescriptionAndName
                                      : ["Component Type", "Length", "Width", "Discount(%)", "Unit", "Currency", "Quantity", "Location of Component", "PCB Component Size"].includes(item?.paramter_name)
                                        ? disabledFeilds
                                        : item?.paramter_name === "Unit Cost" || item?.paramter_name === "Reference"
                                          ? disabledFeilds && revision
                                          : false
                                  }
                                  autoFocus={item?.key === key1}
                                  onBlur={() => {
                                    if (item?.paramter_name === "Item Name" || item?.paramter_name === "Item Description") {
                                      disableAllandCheckDuplicates();
                                    }
                                  }}
                                  onChange={(e) => {
                                    setRowData({
                                      ...rowData,
                                      [item?.key]: e.target.value,
                                    });
                                    if (item?.paramter_name === "Item Name") {
                                      setIsItemNameChanged(true);
                                    }
                                    if (item?.paramter_name === "Item Description") {
                                      setIsItemDescriptionChanged(true);
                                    }
                                    if (item?.paramter_name === "PCB Component Size") {
                                      setPCBComponentChanged((prev: any) => prev + 1);
                                    }
                                  }}
                                  input={<Input id="standard-input" placeholder="Select an option" />}
                                >
                                  {item?.allowed_values?.map((value: any, index: any) => (
                                    <MenuItem key={index} value={value}>
                                      {value}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>


                              {/* <FormControl
                              fullWidth
                              sx={{
                                ".MuiFormLabel-asterisk": { color: "red" },
                              }}
                            >
                              <InputLabel
                                id="demo-multiple-name-label"
                                sx={{ fontSize: "0.75rem" }}
                                style={{
                                  color: "#007fff",
                                  fontSize: "1.1rem",
                                }}
                                required
                              >
                                {item?.paramter_name}
                              </InputLabel>
                              <Select
                                labelId="demo-multiple-name-label"
                                id="demo-multiple-name"
                                multiple={false}
                                value={rowData?.[item?.key] || ""}
                                disabled={
                                  item?.paramter_name == "Item Name" ||
                                  item?.paramter_name == "Item Description"
                                    ? disabledFeilds &&
                                      disableItemDescriptionAndName
                                    : item?.paramter_name == "Component Type" ||
                                      item?.paramter_name == "Length" ||
                                      item?.paramter_name == "Width" ||
                                      item?.paramter_name == "Discount(%)" ||
                                      item?.paramter_name == "Unit" ||
                                      item?.paramter_name == "Currency" ||
                                      item?.paramter_name == "PCB Component Size" ||
                                      item?.paramter_name == "Quantity" ||
                                    item?.paramter_name == "Location of Component" ||
                                    item?.paramter_name == "PCB Component Size"
                                      
                                    ? disabledFeilds
                                    : item?.paramter_name === "Unit Cost" ||
                                      item?.paramter_name == "Reference"
                                    ? disabledFeilds && revision
                                    : false
                                }
                                autoFocus={item?.key === key1 ? true : false}
                                onBlur={() => {
                                  if (
                                    item?.paramter_name === "Item Name" ||
                                    item?.paramter_name === "Item Description"
                                  ) {
                                    disableAllandCheckDuplicates();
                                  }
                                }}
                                onChange={(e) => {
                                  setRowData({
                                    ...rowData,
                                    [item?.key]: e.target.value,
                                  });
                                  // setIsAnythingChanged(true);
                                  // setSaveDisabled(false);

                                  if (item?.paramter_name === "Item Name") {
                                    setIsItemNameChanged(true);
                                  }
                                  if (
                                    item?.paramter_name === "Item Description"
                                  ) {
                                    setIsItemDescriptionChanged(true);
                                  }
                                  if (item?.paramter_name === "PCB Component Size") {
                                    setPCBComponentChanged((prev:any)=> prev+1)
                                  }
                                }}
                                input={
                                  <Input
                                    id="standard-input"
                                    placeholder="Currency"
                                  />
                                }
                                sx={{
                                  "& .MuiSelect-select": {
                                    textAlign: "left",
                                    fontSize: "1rem",
                                  },
                                }}
                              >
                                {item?.allowed_values?.map((item: any) => (
                                  <MenuItem value={item}>{item}</MenuItem>
                                ))}
                              </Select>
                            </FormControl> */}

                              {/* <FormControl fullWidth>
                                <InputLabel
                                  id="demo-simple-select-standard-label" 
                                  sx={{
                                    fontSize: "1rem",
                                    fontWeight: "400",
                                    color: "primary.main",
                                    "& .MuiFormLabel-asterisk": {
                                      color: "#d32f2f",
                                    },
                                  }}
                                  required
                                >
                                  {item?.paramter_name}
                                </InputLabel>
                                <Select
                                  labelId="demo-simple-select-standard-label"
                                  id="demo-simple-select-standard"
                                  multiple={false}
                                  value={rowData?.[item?.key] || ""}
                                  disabled={
                                    item?.paramter_name === "Item Name" || item?.paramter_name === "Item Description"
                                      ? disabledFeilds && disableItemDescriptionAndName
                                      : ["Component Type", "Length", "Width", "Discount(%)", "Unit", "Currency", "Quantity", "Location of Component", "PCB Component Size"].includes(item?.paramter_name)
                                        ? disabledFeilds
                                        : item?.paramter_name === "Unit Cost" || item?.paramter_name === "Reference"
                                          ? disabledFeilds && revision
                                          : false
                                  }
                                  autoFocus={item?.key === key1}
                                  onBlur={() => {
                                    if (item?.paramter_name === "Item Name" || item?.paramter_name === "Item Description") {
                                      disableAllandCheckDuplicates();
                                    }
                                  }}
                                  onChange={(e) => {
                                    setRowData({
                                      ...rowData,
                                      [item?.key]: e.target.value,
                                    });
                                    if (item?.paramter_name === "Item Name") {
                                      setIsItemNameChanged(true);
                                    }
                                    if (item?.paramter_name === "Item Description") {
                                      setIsItemDescriptionChanged(true);
                                    }
                                    if (item?.paramter_name === "PCB Component Size") {
                                      setPCBComponentChanged((prev: any) => prev + 1);
                                    }
                                  }}
                                  input={<Input id="standard-input" placeholder="Select an option" />}
                                  sx={{
                                    "& .MuiSelect-select": {
                                      textAlign: "left",
                                      fontSize: "1rem",
                                    },
                                  }}
                                >
                                  {item?.allowed_values?.map((value: any, index: any) => (
                                    <MenuItem key={index} value={value}>
                                      {value}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl> */}
                            </Box>
                          );

                          case "multiselect":
                            return (
                              <Box
                                sx={{
                                  width: "100%",
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                  gap: "0.3rem",
                                  marginTop: "-1rem",
                                }}
                              >
                               
                                {/* <Autocomplete
                                  multiple
                                  id="tags-standard"
                                  limitTags={1}
                                  options={item?.allowed_values}
                                  value={rowData?.[item?.key] || []}
                                  renderOption={(props, option) => (
                                    <li {...props} style={{ fontSize: "12px" }}>
                                      {option}
                                    </li>
                                  )}
                                  onChange={(e, newSystemValue) => {
                                    setRowData({
                                      ...rowData,
                                      [item?.key]: [...newSystemValue],
                                    });
                                    setIsAnythingChanged(true);
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      autoFocus={item?.key === key1 ? true : false}
                                      label={item?.paramter_name}
                                      variant="standard"
                                      placeholder="Select"
                                      sx={{
                                        marginTop: "1rem",
                                        "& .MuiInput-underline:before": {
                                          borderBottomColor: "primary.light",
                                        },
                                        "& .MuiInput-underline:after": {
                                          borderBottomColor: "primary.main",
                                        },
                                        ".MuiSvgIcon-root": { color: "primary.main" },
                                      }}
                                    />
                                  )}
                                  sx={{
                                    // margin: "0.5rem",
                                    width: "97%",
                                    "& .MuiFormLabel-root": {
                                      color: "#007fff",
                                    },
                                    "& .MuiFormLabel-root.Mui-focused": {
                                      color:
                                        tableData?.[0]?.key ==
                                          tableIdddList["PCB BOM Table"]
                                          ? "#724A24"
                                          : "primary.main",
                                      borderColor: "primary.main",
                                    },
                                    "&:focus-visible": {
                                      outline: "none",
                                      borderColor: "#007fff",
                                    },
                                    "&:hover": {
                                      // outline: "none",
                                      borderColor: "#007fff",
                                    },
                                  }}
                                /> */}

                                 <FormControl variant="standard" sx={{ width: "100%" }}>
                                <InputLabel id="demo-simple-select-standard-label"
                                  sx={{
                                    fontSize: "1rem",
                                    fontWeight: "400",
                                    color: 
                                    (item?.paramter_name === "Item Name" || item?.paramter_name === "Item Description")
                                      ? (disabledFeilds && disableItemDescriptionAndName ? "gray" : "primary.main")
                                      : (disabledFeilds || (item?.paramter_name === "Unit Cost" && revision)) ? "gray" : "primary.main",
                                 
                                    "& .MuiFormLabel-asterisk": {
                                      color: "#d32f2f",
                                    },
                                  }}
                                  required> {item?.paramter_name}</InputLabel>
                                <Select
                                  labelId="demo-simple-select-standard-label"
                                  id="demo-simple-select-standard"
                                  label="Age"
                                  sx={{
                                    width: "100%",
                                    "& .MuiSelect-select": {
                                      fontSize: "1rem",
                                      color: tableData?.[0]?.key === tableIdddList["PCB BOM Table"] ? "primary.main" : "primary.main",
                                    },
                                    "& .MuiSvgIcon-root": {
                                      color: (item?.paramter_name === "Item Name" || item?.paramter_name === "Item Description")
                                      ? (disabledFeilds && disableItemDescriptionAndName ? "gray" : "primary.main")
                                      : (disabledFeilds || (item?.paramter_name === "Unit Cost" && revision)) ? "gray" : "primary.main",
                                    },
                                    "&:before": { // Before the input (default state)
                                      borderBottom: "1px solid #ddefff",
                                    },
                                    "&:after": { // After the input (focused state)
                                      borderBottom: "1px solid primary.main",
                                    },
                                    "&.Mui-error": {
                                      color: "#d32f2f",
                                      "&:before": {
                                        borderBottomColor: "#d32f2f",
                                      },
                                      "&:after": {
                                        borderBottomColor: "#d32f2f",
                                      },
                                    },
                                  }}
                                  multiple={true}
                                  value={rowData?.[item?.key] || ""}
                                  disabled={
                                    item?.paramter_name === "Item Name" || item?.paramter_name === "Item Description"
                                      ? disabledFeilds && disableItemDescriptionAndName
                                      : ["Component Type", "Length", "Width", "Discount(%)", "Unit", "Currency", "Quantity", "Location of Component", "PCB Component Size"].includes(item?.paramter_name)
                                        ? disabledFeilds
                                        : item?.paramter_name === "Unit Cost" || item?.paramter_name === "Reference"
                                          ? disabledFeilds && revision
                                          : false
                                  }
                                  autoFocus={item?.key === key1}
                                  onBlur={() => {
                                    if (item?.paramter_name === "Item Name" || item?.paramter_name === "Item Description") {
                                      disableAllandCheckDuplicates();
                                    }
                                  }}
                                  onChange={(e) => {
                                    setRowData({
                                      ...rowData,
                                      [item?.key]: e.target.value,
                                    });
                                    if (item?.paramter_name === "Item Name") {
                                      setIsItemNameChanged(true);
                                    }
                                    if (item?.paramter_name === "Item Description") {
                                      setIsItemDescriptionChanged(true);
                                    }
                                    if (item?.paramter_name === "PCB Component Size") {
                                      setPCBComponentChanged((prev: any) => prev + 1);
                                    }
                                  }}
                                  input={<Input id="standard-input" placeholder="Select an option" />}
                                >
                                  {item?.allowed_values?.map((value: any, index: any) => (
                                    <MenuItem key={index} value={value}>
                                      {value}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                              </Box>
                            );
                      }
                    })}


                  {
                    initialItemName == rowData?.[mappKeyValue["Item Name"]] &&
                    initialItemDescription ==
                    rowData?.[mappKeyValue["Item Description"]] &&
                    type == "edit" && <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "0.3rem",
                      }}
                    >
                      <TextField
                        id="outlined-basic"
                        variant="standard"
                        type="text"
                        label={" Assumption Card"}
                        required={true} // Add an asterisk if required
                        value={`${revisionData?.revision_name}-(${revisionData?.city},${revisionData?.country})`}
                        disabled={true}
                        InputLabelProps={{
                          sx: {
                            color: "primary.main", // Set label color to primary
                            "& .MuiFormLabel-asterisk": {
                              color: "#d32f2f", // Set asterisk color to red
                            },
                          },
                        }}
                        sx={{
                          width: "100%",
                          "& .MuiSvgIcon-root": {
                            color: "#000",
                          },
                          ".MuiInputBase-input.MuiOutlinedInput-input": {
                            fontSize: "1rem",
                            color:
                              tableData?.[0]?.key ===
                                tableIdddList["PCB BOM Table"]
                                ? "#000000"
                                : "primary.main",
                          },
                          "& .Mui-error": {
                            color: "#d32f2f",
                            borderBottomColor: "#d32f2f",
                          },
                          "& .MuiInput-underline:before": {
                            borderBottomColor: "primary.light",
                          },
                          "& .MuiInput-underline:after": {
                            borderBottomColor: "primary.main",
                          },
                          "& .MuiInput-underline": { fontSize: "1rem" },
                        }}
                      />
                    </Box>}









                  {
                    formType != "Revision" && type == "add" &&
                    // (initialItemName != rowData?.[mappKeyValue["Item Name"]] ||
                    //   initialItemDescription !=
                    //   rowData?.[mappKeyValue["Item Description"]]) &&

                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        gap: "0.3rem",
                      }}
                    >

                      <FormControl variant="standard" sx={{ width: "100%" }}>
                        <InputLabel id="demo-simple-select-standard-label"
                          sx={{
                            fontSize: "1rem",
                            fontWeight: "400",
                            color: "primary.main",
                            "& .MuiFormLabel-asterisk": {
                              color: "#d32f2f",
                            },
                          }}
                          required>Assumption Card</InputLabel>
                        <Select
                          labelId="demo-simple-select-standard-label"
                          id="demo-simple-select-standard"
                          label="Age"
                          sx={{
                            width: "100%",
                            "& .MuiSelect-select": {
                              fontSize: "1rem",
                              color: tableData?.[0]?.key === tableIdddList["PCB BOM Table"] ? "primary.main" : "primary.main",
                            },
                            "& .MuiSvgIcon-root": {
                              color: "primary.main",
                            },
                            "&:before": { // Before the input (default state)
                              borderBottom: "1px solid #ddefff",
                            },
                            "&:after": { // After the input (focused state)
                              borderBottom: "1px solid primary.main",
                            },
                            "&.Mui-error": {
                              color: "#d32f2f",
                              "&:before": {
                                borderBottomColor: "#d32f2f",
                              },
                              "&:after": {
                                borderBottomColor: "#d32f2f",
                              },
                            },
                          }}
                          multiple={false}
                          value={assumptionCard}
                          disabled={disabledFeilds && revision}
                          onChange={(e) => {
                            setAssumptionCard(e.target.value);
                            checkDuplicate(rowData?.[1004], rowData?.[1005]);

                            // setSaveDisabled(false);

                            // if (
                            //   rowData?.[mappKeyValue["Unit Cost"]] > 0 &&
                            //   e.target.value.length > 0 &&
                            //   rowData?.[mappKeyValue["Reference"]] &&
                            //   rowData?.[mappKeyValue["Reference"]].length > 0 &&
                            //   rowData?.[mappKeyValue["Component Type"]] &&
                            //   rowData?.[mappKeyValue["Component Type"]].length > 0 &&
                            //   rowData?.[mappKeyValue["Item Description"]] &&
                            //   rowData?.[mappKeyValue["Item Description"]].length >
                            //     0 &&
                            //   rowData?.[mappKeyValue["Item Name"]] &&
                            //   rowData?.[mappKeyValue["Item Name"]].length > 0 &&
                            //   flagUploaded
                            // ) {
                            //   setSaveDisabled(false);
                            // } else {
                            //   setSaveDisabled(true);
                            // }

                            // checkDuplicate(rowData?.[1004], rowData?.[1005]);

                            // setIsAnythingChanged(true);
                          }}



                        >
                          {assumptionCardList?.map((item: any) => (
                            <MenuItem value={item?.id}>
                              {item.item_name} - ({item?.city},{item?.country})
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>



                      {/* <FormControl
                        fullWidth
                        sx={{ ".MuiFormLabel-asterisk": { color: "red" } }}
                      >
                        <InputLabel
                          id="demo-multiple-name-label"
                          sx={{ fontSize: "0.75rem" }}
                          style={{
                            color: "#007fff",
                            fontSize: "1.1rem",
                          }}
                          required
                        >
                          Assumption Card
                        </InputLabel>
                        <Select
                          variant="standard"
                          labelId="demo-multiple-name-label"
                          id="demo-multiple-name"
                          multiple={false}
                          value={assumptionCard}
                          disabled={disabledFeilds && revision}
                          onChange={(e) => {
                            setAssumptionCard(e.target.value);
                            checkDuplicate(rowData?.[1004], rowData?.[1005]);

                            // setSaveDisabled(false);

                            // if (
                            //   rowData?.[mappKeyValue["Unit Cost"]] > 0 &&
                            //   e.target.value.length > 0 &&
                            //   rowData?.[mappKeyValue["Reference"]] &&
                            //   rowData?.[mappKeyValue["Reference"]].length > 0 &&
                            //   rowData?.[mappKeyValue["Component Type"]] &&
                            //   rowData?.[mappKeyValue["Component Type"]].length > 0 &&
                            //   rowData?.[mappKeyValue["Item Description"]] &&
                            //   rowData?.[mappKeyValue["Item Description"]].length >
                            //     0 &&
                            //   rowData?.[mappKeyValue["Item Name"]] &&
                            //   rowData?.[mappKeyValue["Item Name"]].length > 0 &&
                            //   flagUploaded
                            // ) {
                            //   setSaveDisabled(false);
                            // } else {
                            //   setSaveDisabled(true);
                            // }

                            // checkDuplicate(rowData?.[1004], rowData?.[1005]);

                            // setIsAnythingChanged(true);
                          }}
                          // renderValue={(selected) => (
                          //   <Box sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}>
                          //     {selected?.map((value: any) => {
                          //       let selectedOptionsTag: any =
                          //         assumptionCardList?.filter(
                          //           (item: any) => item.id == value
                          //         )[0];
                          //       return (
                          //         <Chip
                          //           key={value}
                          //           label={`${selectedOptionsTag["item_name"]} - (${selectedOptionsTag["city"]},${selectedOptionsTag["country"]})`}
                          //         />
                          //       );
                          //     })}
                          //   </Box>
                          // )}
                          MenuProps={MenuProps}
                          sx={{
                            "& .MuiSelect-select": {
                              textAlign: "left",
                              fontSize: "1rem",
                            },
                          }}
                        >
                          {assumptionCardList?.map((item: any) => (
                            <MenuItem value={item?.id}>
                              {item.item_name} - ({item?.city},{item?.country})
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl> */}
                    </Box>
                  }

                  {type == "add" &&
                    formType != "Revision" &&
                    formType != "Database" && (
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          gap: "0.3rem",
                        }}
                      >
                        <div
                          style={{
                            width: "100%",
                            alignSelf: "left",
                            fontSize: "0.85rem",
                          }}
                        >
                          Flag
                          <sup style={{ color: "red", fontSize: "0.8rem" }}>
                            *
                          </sup>
                        </div>
                        {/* <FormControl
                      fullWidth
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "row",
                        gap: "0.3rem",
                      }}
                    >
                      <TextField
                        type="file"
                        style={{ fontSize: "1rem", flexGrow: "1" }}
                        inputProps={{
                          accept: "image/*,application/pdf",
                        }}
                        onChange={handleChangeFile}
                        // disabled={disabledFeilds && (formType == "Revision" || formType == "Database")}
                      />
                      {uploadFile && (
                        <CircularProgress
                          size={20}
                          sx={{
                            color: "primary.main",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-12px",
                            marginLeft: "-12px",
                          }}
                        />
                      )}
                      <PreviewIcon
                        sx={{
                          cursor:
                            flagS3Url?.length > 0 ? "pointer" : "not-allowed",
                          color: flagS3Url?.length > 0 ? "green" : "red",
                        }}
                        onClick={(e: any) => {
                          handleClick(
                            `${tableData?.[0]?.header_item?.[0]?.cdn}${flagS3Url}`,
                            e
                          );
                        }}
                      />
                    </FormControl> */}
                        <FormControl
                          fullWidth
                          variant="standard" // Use standard variant here
                          sx={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "row",
                            gap: "0.3rem",
                          }}
                        >
                          <Input
                            type="file"
                            inputProps={{
                              accept: "image/*,application/pdf",
                            }}
                            onChange={handleChangeFile}

                            sx={{
                              fontSize: "1rem",
                              flexGrow: 1,
                              "&:before": {
                                borderBottom: "1px solid #ddefff", // bottom line in default state
                              },
                              "&:after": {
                                borderBottom: "2px solid #3f51b5", // bottom line when focused (primary color)
                              },
                            }}
                          />

                          {uploadFile && (
                            <CircularProgress
                              size={20}
                              sx={{
                                color: "primary.main",
                                position: "absolute",
                                top: "50%",
                                left: "50%",
                                marginTop: "-12px",
                                marginLeft: "-12px",
                              }}
                            />
                          )}

                          <PreviewIcon
                            sx={{
                              cursor:
                                flagS3Url?.length > 0 ? "pointer" : "not-allowed",
                              color: flagS3Url?.length > 0 ? "green" : "red",
                            }}
                            onClick={(e: any) => {
                              handleClick(
                                `${tableData?.[0]?.header_item?.[0]?.cdn}${flagS3Url}`,
                                e
                              );
                            }}
                          />
                        </FormControl>

                        <div style={{ display: "none" }}>
                          {/* @ts-ignore */}
                          <RViewerJS>
                            <img
                              src={ImageViewer.current?.src}
                              alt="image"
                              ref={ImageViewer}
                            />
                          </RViewerJS>
                        </div>
                      </Box>
                    )}

                  {/* --------------- */}

                  {/* <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "0.3rem",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    alignSelf: "left",
                    fontSize: "1.2rem",
                  }}
                >
                  Item Name
                  <sup style={{ color: "red", fontSize: "0.8rem" }}>*</sup>
                </div>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  disabled={disabledFeilds && disableItemDescriptionAndName}
                  value={rowData?.[mappKeyValue["Item Name"]] || ""}
                  onChange={(e) => {
                    // let temp = [...val]
                    // temp[index] = e.target.value
                    if (e.target.value == "") {
                      setRowData({
                        ...rowData,
                        [mappKeyValue["Item Name"]]: e.target.value,
                      });
                      setName(false);
                      setSaveDisabled(true);
                      setIsItemNameChanged(true);
                      setIsAnythingChanged(true);
                    } else {
                      setRowData({
                        ...rowData,
                        [mappKeyValue["Item Name"]]: e.target.value,
                      });
                      setSaveDisabled(
                        description &&
                          length >= 0 &&
                          width >= 0 &&
                          componentType &&
                          reference &&
                          unitCost > 0 &&
                          assumptionCard?.length > 0
                          ? false
                          : true
                      );
                      setName(true);
                      setIsItemNameChanged(true);
                      setIsAnythingChanged(true);
                    }
                  }}
                  onBlur={disableAllandCheckDuplicates}
                  autoFocus={mappKeyValue["Item Name"] === key1 ? true : false}
                  sx={{
                    width: "100%",
                    "& .MuiFormLabel-root": {
                      color: "primary.main",
                    },
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "primary.main",
                    },
                    "& .MuiSvgIcon-root": {
                      color: "primary.main",
                    },
                    ".MuiInputBase-input.MuiInput-input": {
                      fontSize: "1rem",
                    },
                    "& .Mui-error": {
                      color: "#d32f2f",
                      borderBottomColor: "#d32f2f",
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "primary.light",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "primary.main",
                    },
                    "& .MuiInput-underline": { fontSize: "1rem" },
                  }}
                />
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "0.3rem",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    alignSelf: "left",
                    fontSize: "1.2rem",
                  }}
                >
                  Item Description
                  <sup style={{ color: "red", fontSize: "0.8rem" }}>*</sup>
                </div>
                <TextField
                  id="outlined-basic"
                  disabled={disabledFeilds && disableItemDescriptionAndName}
                  variant="outlined"
                  type="text"
                  value={rowData?.[mappKeyValue["Item Description"]] || ""}
                  onBlur={disableAllandCheckDuplicates}
                  onChange={(e) => {
                    // let temp = [...val]
                    // temp[index] = e.target.value
                    if (e.target.value == "") {
                      setRowData({
                        ...rowData,
                        [mappKeyValue["Item Description"]]: e.target.value,
                      });
                      setDescription(false);
                      setSaveDisabled(true);
                      setIsItemDescriptionChanged(true);
                      setIsAnythingChanged(true);
                    } else {
                      setRowData({
                        ...rowData,
                        [mappKeyValue["Item Description"]]: e.target.value,
                      });
                      setDescription(true);
                      setIsItemDescriptionChanged(true);
                      setSaveDisabled(
                        name &&
                          length >= 0 &&
                          width >= 0 &&
                          componentType &&
                          reference &&
                          unitCost > 0 &&
                          assumptionCard?.length > 0
                          ? false
                          : true
                      );
                      setIsAnythingChanged(true);
                    }
                  }}
                  autoFocus={
                    mappKeyValue["Item Description"] === key1 ? true : false
                  }
                  sx={{
                    width: "100%",
                    "& .MuiFormLabel-root": {
                      color: "primary.main",
                    },
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "primary.main",
                    },
                    "& .MuiSvgIcon-root": {
                      color: "primary.main",
                    },
                    ".MuiInputBase-input.MuiInput-input": {
                      fontSize: "1rem",
                    },
                    "& .Mui-error": {
                      color: "#d32f2f",
                      borderBottomColor: "#d32f2f",
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "primary.light",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "primary.main",
                    },
                    "& .MuiInput-underline": { fontSize: "1rem" },
                  }}
                />
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "0.3rem",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    alignSelf: "left",
                    fontSize: "1.2rem",
                  }}
                >
                  Component Type
                  <sup style={{ color: "red", fontSize: "0.8rem" }}>*</sup>
                </div>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  value={rowData?.[mappKeyValue["Component Type"]] || ""}
                  disabled={disabledFeilds}
                  onChange={(e) => {
                    // let temp = [...val]
                    // temp[index] = e.target.value
                    if (e.target.value == "") {
                      setRowData({
                        ...rowData,
                        [mappKeyValue["Component Type"]]: e.target.value,
                      });
                      setComponentType(false);
                      setSaveDisabled(true);
                      setIsAnythingChanged(true);
                    } else {
                      setRowData({
                        ...rowData,
                        [mappKeyValue["Component Type"]]: e.target.value,
                      });
                      setComponentType(true);
                      setSaveDisabled(
                        name &&
                          description &&
                          length >= 0 &&
                          width >= 0 &&
                          reference &&
                          unitCost > 0 &&
                          assumptionCard?.length > 0
                          ? false
                          : true
                      );
                      setIsAnythingChanged(true);
                    }
                  }}
                  autoFocus={
                    mappKeyValue["Component Type"] === key1 ? true : false
                  }
                  sx={{
                    width: "100%",
                    "& .MuiFormLabel-root": {
                      color: "primary.main",
                    },
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "primary.main",
                    },
                    "& .MuiSvgIcon-root": {
                      color: "primary.main",
                    },
                    ".MuiInputBase-input.MuiInput-input": {
                      fontSize: "1rem",
                    },
                    "& .Mui-error": {
                      color: "#d32f2f",
                      borderBottomColor: "#d32f2f",
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "primary.light",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "primary.main",
                    },
                    "& .MuiInput-underline": { fontSize: "1rem" },
                  }}
                />
              </Box>

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "0.3rem",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    alignSelf: "left",
                    fontSize: "1.2rem",
                  }}
                >
                  Length
                  <sup style={{ color: "red", fontSize: "0.8rem" }}>*</sup>
                </div>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  type="number"
                  value={rowData?.[mappKeyValue["Length"]] || 0}
                  disabled={disabledFeilds}
                  onChange={(e) => {
                    // let temp = [...val]
                    // temp[index] = e.target.value
                    // if (itmKey == 90) {
                    //   setTotalPercentage(totalPercentage - rowData?.[item?.key] + Number(e.target.value));
                    // }
                    if (Number(e.target.value) <= 0) {
                      setRowData({
                        ...rowData,
                        [mappKeyValue["Length"]]: e.target.value,
                      });
                      setLength(false);
                      setSaveDisabled(true);
                      setIsAnythingChanged(true);
                    } else {
                      setRowData({
                        ...rowData,
                        [mappKeyValue["Length"]]: e.target.value,
                      });
                      setLength(true);
                      setSaveDisabled(
                        name &&
                          description &&
                          componentType &&
                          width >= 0 &&
                          reference &&
                          unitCost > 0 &&
                          assumptionCard?.length > 0
                          ? false
                          : true
                      );
                      setIsAnythingChanged(true);
                    }
                  }}
                  autoFocus={mappKeyValue["Length"] === key1 ? true : false}
                  sx={{
                    width: "100%",
                    "& .MuiFormLabel-root": {
                      color: "primary.main",
                    },
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "primary.main",
                    },
                    "& .MuiSvgIcon-root": {
                      color: "primary.main",
                    },
                    ".MuiInputBase-input.MuiInput-input": {
                      fontSize: "1rem",
                    },
                    "& .Mui-error": {
                      color: "#d32f2f",
                      borderBottomColor: "#d32f2f",
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "primary.light",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "primary.main",
                    },
                    "& .MuiInput-underline": { fontSize: "1rem" },
                  }}
                />
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "0.3rem",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    alignSelf: "left",
                    fontSize: "1.2rem",
                  }}
                >
                  Width<sup style={{ color: "red", fontSize: "0.8rem" }}>*</sup>
                </div>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  type="number"
                  value={rowData?.[mappKeyValue["Width"]] || 0}
                  onChange={(e) => {
                    // let temp = [...val]
                    // temp[index] = e.target.value
                    // if (itmKey == 90) {
                    //   setTotalPercentage(totalPercentage - rowData?.[item?.key] + Number(e.target.value));
                    // }
                    if (Number(e.target.value) <= 0) {
                      setRowData({
                        ...rowData,
                        [mappKeyValue["Width"]]: e.target.value,
                      });
                      setWidth(false);
                      setSaveDisabled(true);
                      setIsAnythingChanged(true);
                    } else {
                      setRowData({
                        ...rowData,
                        [mappKeyValue["Width"]]: e.target.value,
                      });
                      setWidth(true);
                      setSaveDisabled(
                        name &&
                          description &&
                          componentType &&
                          length >= 0 &&
                          reference &&
                          unitCost > 0 &&
                          assumptionCard?.length > 0
                          ? false
                          : true
                      );
                      setIsAnythingChanged(true);
                    }
                  }}
                  disabled={disabledFeilds}
                  autoFocus={mappKeyValue["Width"] === key1 ? true : false}
                  sx={{
                    width: "100%",
                    "& .MuiFormLabel-root": {
                      color: "primary.main",
                    },
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "primary.main",
                    },
                    "& .MuiSvgIcon-root": {
                      color: "primary.main",
                    },
                    ".MuiInputBase-input.MuiInput-input": {
                      fontSize: "1rem",
                    },
                    "& .Mui-error": {
                      color: "#d32f2f",
                      borderBottomColor: "#d32f2f",
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "primary.light",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "primary.main",
                    },
                    "& .MuiInput-underline": { fontSize: "1rem" },
                  }}
                />
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "0.3rem",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    alignSelf: "left",
                    fontSize: "1.2rem",
                  }}
                >
                  Unit Cost
                  <sup style={{ color: "red", fontSize: "0.8rem" }}>*</sup>
                </div>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  type="number"
                  value={rowData?.[mappKeyValue["Unit Cost"]] || ""}
                  onChange={(e) => {
                    // let temp = [...val]
                    // temp[index] = e.target.value
                    // if (itmKey == 90) {
                    //   setTotalPercentage(totalPercentage - rowData?.[1294] + Number(e.target.value));
                    // }
                    if (Number(e.target.value) <= 0) {
                      setRowData({
                        ...rowData,
                        [mappKeyValue["Unit Cost"]]: e.target.value,
                      });
                      setUnitCost(false);
                      setSaveDisabled(true);
                      setIsAnythingChanged(true);
                    } else {
                      setRowData({
                        ...rowData,
                        [mappKeyValue["Unit Cost"]]: e.target.value,
                      });
                      setUnitCost(true);
                      setSaveDisabled(
                        name &&
                          description &&
                          componentType &&
                          length >= 0 &&
                          width >= 0 &&
                          reference &&
                          assumptionCard?.length > 0
                          ? false
                          : true
                      );
                      setIsAnythingChanged(true);
                    }
                  }}
                  disabled={disabledFeilds && revision}
                  autoFocus={mappKeyValue["Unit Cost"] === key1 ? true : false}
                  sx={{
                    width: "100%",
                    "& .MuiFormLabel-root": {
                      color: "primary.main",
                    },
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "primary.main",
                    },
                    "& .MuiSvgIcon-root": {
                      color: "primary.main",
                    },
                    ".MuiInputBase-input.MuiInput-input": {
                      fontSize: "1rem",
                    },
                    "& .Mui-error": {
                      color: "#d32f2f",
                      borderBottomColor: "#d32f2f",
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "primary.light",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "primary.main",
                    },
                    "& .MuiInput-underline": { fontSize: "1rem" },
                  }}
                />
              </Box>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "0.3rem",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    alignSelf: "left",
                    fontSize: "1.2rem",
                  }}
                >
                  Reference
                  <sup style={{ color: "red", fontSize: "0.8rem" }}>*</sup>
                </div>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  value={rowData?.[mappKeyValue["Reference"]] || ""}
                  disabled={disabledFeilds && revision}
                  onChange={(e) => {
                    // let temp = [...val]
                    // temp[index] = e.target.value
                    if (e.target.value == "") {
                      setRowData({
                        ...rowData,
                        [mappKeyValue["Reference"]]: e.target.value,
                      });
                      setReference(false);
                      setSaveDisabled(true);
                      setIsAnythingChanged(true);
                    } else {
                      setRowData({
                        ...rowData,
                        [mappKeyValue["Reference"]]: e.target.value,
                      });
                      setReference(true);
                      setSaveDisabled(
                        name &&
                          description &&
                          componentType &&
                          length >= 0 &&
                          width >= 0 &&
                          unitCost > 0 &&
                          assumptionCard?.length > 0
                          ? false
                          : true
                      );
                      setIsAnythingChanged(true);
                    }
                  }}
                  autoFocus={mappKeyValue["Reference"] === key1 ? true : false}
                  sx={{
                    width: "100%",
                    "& .MuiFormLabel-root": {
                      color: "primary.main",
                    },
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "primary.main",
                    },
                    "& .MuiSvgIcon-root": {
                      color: "primary.main",
                    },
                    ".MuiInputBase-input.MuiInput-input": {
                      fontSize: "1rem",
                    },
                    "& .Mui-error": {
                      color: "#d32f2f",
                      borderBottomColor: "#d32f2f",
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "primary.light",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "primary.main",
                    },
                    "& .MuiInput-underline": { fontSize: "1rem" },
                  }}
                />
              </Box> */}
                  {/* {type == "add" &&
                formType != "Revision" &&
                formType != "Database" && (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "0.3rem",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        alignSelf: "left",
                        fontSize: "1.2rem",
                      }}
                    >
                      Flag
                      <sup style={{ color: "red", fontSize: "0.8rem" }}>*</sup>
                    </div>
                    <FormControl
                      fullWidth
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        flexDirection: "row",
                        gap: "0.3rem",
                      }}
                    >
                      
                      <TextField
                        type="file"
                        style={{ fontSize: "1rem", flexGrow: "1" }}
                        inputProps={{
                          accept: "image/*,application/pdf",
                        }}
                        onChange={handleChangeFile}
                        // disabled={disabledFeilds && (formType == "Revision" || formType == "Database")}
                      />
                      {uploadFile && (
                        <CircularProgress
                          size={20}
                          sx={{
                            color: "primary.main",
                            position: "absolute",
                            top: "50%",
                            left: "50%",
                            marginTop: "-12px",
                            marginLeft: "-12px",
                          }}
                        />
                      )}
                      <PreviewIcon
                        sx={{
                          cursor:
                            flagS3Url?.length > 0 ? "pointer" : "not-allowed",
                          color: flagS3Url?.length > 0 ? "green" : "red",
                        }}
                        onClick={(e: any) => {
                          handleClick(
                            `${tableData?.[0]?.header_item?.[0]?.cdn}${flagS3Url}`,
                            e
                          );
                        }}
                      />
                    </FormControl>
                    <div style={{ display: "none" }}>
                      
                      <RViewerJS>
                        <img
                          src={ImageViewer.current?.src}
                          alt="image"
                          ref={ImageViewer}
                        />
                      </RViewerJS>
                    </div>
                  </Box>
                )} */}
                  {/* {formType != "Revision" &&
                (initialItemName != rowData?.[mappKeyValue["Item Name"]] ||
                  initialItemDescription !=
                    rowData?.[mappKeyValue["Item Description"]]) && (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "0rem",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        alignSelf: "left",
                        fontSize: "1.2rem",
                      }}
                    >
                      Select Assumption Card
                      <sup style={{ color: "red", fontSize: "0.8rem" }}>*</sup>
                    </div>
                    <FormControl fullWidth>
                      <Select
                        id="demo-simple-select"
                        value={assumptionCard}
                        // autoFocus={item?.key === key1 ? true : false}
                        multiple
                        disabled={(disabledFeilds && revision) || uploadFile}
                        onChange={(e) => {
                          setAssumptionCard(e.target.value);

                          if (
                            rowData?.[mappKeyValue["Unit Cost"]] > 0 &&
                            e.target.value.length > 0 &&
                            rowData?.[mappKeyValue["Reference"]] &&
                            rowData?.[mappKeyValue["Reference"]].length > 0 &&
                            rowData?.[mappKeyValue["Component Type"]] &&
                            rowData?.[mappKeyValue["Component Type"]].length >
                              0 &&
                            rowData?.[mappKeyValue["Item Description"]] &&
                            rowData?.[mappKeyValue["Item Description"]].length >
                              0 &&
                            rowData?.[mappKeyValue["Item Name"]] &&
                            rowData?.[mappKeyValue["Item Name"]].length > 0 &&
                            flagUploaded
                          ) {
                            setSaveDisabled(false);
                          } else {
                            setSaveDisabled(true);
                          }

                          // checkDuplicate(rowData?.[1004], rowData?.[1005]);

                          // setIsAnythingChanged(true);
                        }}
                        renderValue={(selected) => (
                          <Box
                            sx={{ display: "flex", flexWrap: "wrap", gap: 0.5 }}
                          >
                            {selected?.map((value: any) => {
                              let selectedOptionsTag: any =
                                assumptionCardList?.filter(
                                  (item: any) => item.id == value
                                )[0];
                              return (
                                <Chip
                                  key={value}
                                  label={`${selectedOptionsTag["item_name"]} - (${selectedOptionsTag["city"]},${selectedOptionsTag["country"]})`}
                                />
                              );
                            })}
                          </Box>
                        )}
                        MenuProps={MenuProps}
                        // disabled= {!name || !description || disabledFeilds}
                        sx={{
                          width: "100%",
                          marginTop: "0.5rem !important",
                          "&:before": {
                            borderBottomColor: "primary.light",
                          },
                          "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                            {
                              fontSize: "1rem",
                            },
                          ".MuiSvgIcon-root.MuiSelect-icon": {
                            color: "primary.main",
                          },
                        }}
                      >
                        {assumptionCardList?.map((item: any) => (
                          <MenuItem value={item.id}>
                            {item.item_name} - ({item?.city},{item?.country})
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Box>
                )} */}
                  {/* {initialItemName == rowData?.[mappKeyValue["Item Name"]] &&
                initialItemDescription ==
                  rowData?.[mappKeyValue["Item Description"]] &&
                type == "edit" && (
                  <Box
                    sx={{
                      width: "100%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      gap: "0.3rem",
                    }}
                  >
                    <div
                      style={{
                        width: "100%",
                        alignSelf: "left",
                        fontSize: "1.2rem",
                      }}
                    >
                      Selected Assumption Card
                      <sup style={{ color: "red", fontSize: "0.8rem" }}>*</sup>
                    </div>
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      type="text"
                      value={`${revisionData?.revision_name} - (${revisionData?.city},${revisionData?.country})`}
                      disabled={true}
                      sx={{
                        width: "100%",
                        "& .MuiFormLabel-root": {
                          color: "primary.main",
                        },
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "primary.main",
                        },
                        "& .MuiSvgIcon-root": {
                          color: "primary.main",
                        },
                        ".MuiInputBase-input.MuiInput-input": {
                          fontSize: "1rem",
                        },
                        "& .Mui-error": {
                          color: "#d32f2f",
                          borderBottomColor: "#d32f2f",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "primary.light",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "primary.main",
                        },
                        "& .MuiInput-underline": { fontSize: "1rem" },
                      }}
                    />
                  </Box>
                )} */}

                  {/* <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "0.3rem",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    alignSelf: "left",
                    fontSize: "1.2rem",
                  }}
                >
                  Discount(%)
                  <sup style={{ color: "red", fontSize: "0.8rem" }}>*</sup>
                </div>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  value={rowData?.[mappKeyValue["Discount(%)"]] || ""}
                  disabled={disabledFeilds && revision}
                  onChange={(e) => {
                    // let temp = [...val]
                    // temp[index] = e.target.value
                    if (e.target.value == "") {
                      setRowData({
                        ...rowData,
                        [mappKeyValue["Discount(%)"]]: e.target.value,
                      });
                      setDiscount(false);
                      setSaveDisabled(true);
                      setIsAnythingChanged(true);
                    } else {
                      setRowData({
                        ...rowData,
                        [mappKeyValue["Discount(%)"]]: e.target.value,
                      });
                      setDiscount(true);
                      setSaveDisabled(
                        name &&
                          description &&
                          componentType &&
                          length >= 0 &&
                          width >= 0 &&
                          unitCost > 0 &&
                          assumptionCard?.length > 0
                          ? false
                          : true
                      );
                      setIsAnythingChanged(true);
                    }
                  }}
                  autoFocus={
                    mappKeyValue["Discount(%)"] === key1 ? true : false
                  }
                  sx={{
                    width: "100%",
                    "& .MuiFormLabel-root": {
                      color: "primary.main",
                    },
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "primary.main",
                    },
                    "& .MuiSvgIcon-root": {
                      color: "primary.main",
                    },
                    ".MuiInputBase-input.MuiInput-input": {
                      fontSize: "1rem",
                    },
                    "& .Mui-error": {
                      color: "#d32f2f",
                      borderBottomColor: "#d32f2f",
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "primary.light",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "primary.main",
                    },
                    "& .MuiInput-underline": { fontSize: "1rem" },
                  }}
                />
              </Box> */}
                </>
              )}
            </Box>
          </Box>

          {/* {tableData?.[0]?.key == tableIdddList["PCB BOM Table"] && (
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 1fr)",
              rowGap: "1rem",
              margin: "1rem",
              columnGap: "1rem",
            }}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: "0.25rem",
                width: "100%",
              }}
            >
              <FormControl
                fullWidth
                sx={{ ".MuiFormLabel-asterisk": { color: "red" } }}
              >
                <InputLabel
                  id="demo-multiple-name-label"
                  sx={{ fontSize: "0.75rem" }}
                  style={{
                    color: "#007fff",
                    fontSize: "1.1rem",
                  }}
                >
                  Currency
                </InputLabel>
                <Select
                  labelId="demo-multiple-name-label"
                  id="demo-multiple-name"
                  multiple={false}
                  value={selectedCurrencyCodes}
                  onChange={(event: any) => {
                    setSelectedCurrencyCodes(event.target.value);

                    if (event.target.value === "") {
                      setRowData({
                        ...rowData,
                        [mappKeyValue["Currency"]]: event.target.value,
                      });
                      setCurrency(false);
                      setSaveDisabled(true);
                      setIsAnythingChanged(true);
                    } else {
                      setRowData({
                        ...rowData,
                        [mappKeyValue["Currency"]]: event.target.value,
                      });
                      setCurrency(true);
                      setSaveDisabled(
                        name &&
                          description &&
                          componentType &&
                          length >= 0 &&
                          width >= 0 &&
                          unitCost > 0 &&
                          assumptionCard?.length > 0
                          ? false
                          : true
                      );
                      setIsAnythingChanged(true);
                    }
                  }}
                  input={<Input id="standard-input" placeholder="Currency" />}
                  sx={{
                    "& .MuiSelect-select": {
                      textAlign: "left",
                      fontSize: "1rem",
                    },
                  }}
                >
                  {currencyCodes.map((name: any, index: any) => (
                    <MenuItem key={index} value={name}>
                      {flagMap[name] && (
                        <img
                          src={`data:image/png;base64, ${flagMap[name]}`}
                          alt={`${name} flag`}
                          style={{
                            width: "1.5rem",
                            height: "1.5rem",
                            marginLeft: "1rem",
                            marginRight: "1rem",
                          }}
                        />
                      )}
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              
              <Typography
                variant="body1"
                sx={{
                  alignSelf: "center",
                  fontSize: "1.5rem",
                  marginTop: "1.85rem",
                }}
              >
                /
              </Typography>

              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  gap: "0.3rem",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    alignSelf: "left",
                    fontSize: "1.2rem",
                  }}
                >
                  Unit
                  <sup style={{ color: "red", fontSize: "0.8rem" }}>*</sup>
                </div>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  type="text"
                  value={rowData?.[mappKeyValue["Currency Unit"]] || ""}
                  disabled={disabledFeilds && revision}
                  onChange={(e) => {
                    // let temp = [...val]
                    // temp[index] = e.target.value
                    if (e.target.value == "") {
                      setRowData({
                        ...rowData,
                        [mappKeyValue["Currency Unit"]]: e.target.value,
                      });
                      setDiscount(false);
                      setSaveDisabled(true);
                      setIsAnythingChanged(true);
                    } else {
                      setRowData({
                        ...rowData,
                        [mappKeyValue["Currency Unit"]]: e.target.value,
                      });
                      setDiscount(true);
                      setSaveDisabled(
                        name &&
                          description &&
                          componentType &&
                          length >= 0 &&
                          width >= 0 &&
                          unitCost > 0 &&
                          assumptionCard?.length > 0
                          ? false
                          : true
                      );
                      setIsAnythingChanged(true);
                    }
                  }}
                  autoFocus={
                    mappKeyValue["Currency Unit"] === key1 ? true : false
                  }
                  sx={{
                    width: "100%",
                    "& .MuiFormLabel-root": {
                      color: "primary.main",
                    },
                    "& .MuiFormLabel-root.Mui-focused": {
                      color: "primary.main",
                    },
                    "& .MuiSvgIcon-root": {
                      color: "primary.main",
                    },
                    ".MuiInputBase-input.MuiInput-input": {
                      fontSize: "1rem",
                    },
                    "& .Mui-error": {
                      color: "#d32f2f",
                      borderBottomColor: "#d32f2f",
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "primary.light",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "primary.main",
                    },
                    "& .MuiInput-underline": { fontSize: "1rem" },
                  }}
                />
              </Box>
            </Box>
          </Box>
        )} */}

          {/* <Divider
          sx={{
            borderColor: "primary.light",
            margin: "0 1rem",
            marginBottom: "1.2rem",
          }}
        /> */}

          <Box sx={{ padding: "0.5rem" }}>
            {tableData?.[0]?.key == tableIdddList["PCB BOM Table"] && (
              <>
                <Typography
                  sx={{
                    display: "flex",
                    gap: "0.2rem",
                    width: "100%",
                    padding: "0.5rem",
                    marginLeft: "0.5rem",
                    // color: "#724A24",
                    color: "primary.main",
                    marginBottom: "0.5rem"
                  }}
                >
                  BOM Parameters
                </Typography>
              </>
            )}

            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr)",
                rowGap: "1.75rem",
                margin: "1rem",
                columnGap: "1rem",
              }}
            >
              {tableData?.[0]?.header_item
                ?.filter(
                  (itm: any) => {
                    const shouldFilter = tableData?.[0]?.key === tableIdddList["PCB BOM Table"];
                    

                    return !shouldFilter || (itm?.paramter_name !== "Item Name" &&
                      itm?.paramter_name !== "Item Description" &&
                      itm?.paramter_name !== "Component Type" &&
                      itm?.paramter_name !== "Length" &&
                      itm?.paramter_name !== "Width" &&
                      itm?.paramter_name !== "Unit Cost" &&
                      itm?.paramter_name !== "Reference" &&
                      itm?.paramter_name !== "Discount(%)" &&
                      itm?.paramter_name !== "Currency" &&
                      itm?.paramter_name !== "Unit" &&
                      itm?.paramter_name !== "Quantity" &&
                      itm?.paramter_name !== "PCB Component Size" &&
                      itm?.paramter_name !== "Location of Component")
                  }



                )
                ?.map((item: any, index: any) => {
                 

                  switch (item?.data_type) {
                    case "number":
                    case "float":
                    case "nonzero-positive-int":
                    case "nonzero-positive-float":
                    case "positive-float":
                    case "nonzero-float":
                    case "percentage":
                    case "positive-int":
                    case "integer":
                    case "percentage":
                      if (
                        item?.key == mappKeyValue["Discount(%)"] ||
                        item?.key == mappKeyValue["Total Cost"] ||
                        item?.key == mappKeyValue["Length"] ||
                        item?.key == mappKeyValue["Width"] ||
                        item?.key == mappKeyValue["Unit Cost"]
                      ) {
                        return;
                      }
                      if (item?.key == mappKeyValue["Quantity"]) {
                        return (
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              gap: "0.3rem",
                            }}
                          >

                            <TextField
                              id="outlined-basic"
                              variant="standard"
                              type="number"
                              required={true}
                              label={item?.paramter_name}
                              value={
                                rowData?.[item?.key] == 0
                                  ? 1
                                  : rowData?.[item?.key]
                              }
                              onChange={(e) => {
                                // let temp = [...val]
                                // temp[index] = e.target.value
                                if (
                                  itmKey == tableIdddList["Material Percentage"]
                                ) {
                                  setTotalPercentage(
                                    totalPercentage -
                                    rowData?.[item?.key] +
                                    Number(e.target.value)
                                  );
                                }
                                setRowData({
                                  ...rowData,
                                  [item?.key]: e.target.value,
                                });

                                if (item?.paramter_name === "PCB Component Size") {
                                  setPCBComponentChanged((prev: any) => prev + 1)
                                }

                                setIsAnythingChanged(true);
                              }}
                              autoFocus={item?.key === key1 ? true : false}
                              InputLabelProps={{
                                sx: {
                                  fontSize: "1rem",
                                  color: "primary.main", // Set label color to primary
                                  "& .MuiFormLabel-asterisk": {
                                    color: "#d32f2f", // Set asterisk color to red
                                  },
                                },
                              }}
                              sx={{
                                width: "100%",
                                "& .MuiSvgIcon-root": {
                                  color: "#000",
                                },
                                ".MuiInputBase-input.MuiOutlinedInput-input": {
                                  fontSize: "1rem",
                                  color:
                                    tableData?.[0]?.key ===
                                      tableIdddList["PCB BOM Table"]
                                      ? "#000000"
                                      : "primary.main",
                                },
                                "& .Mui-error": {
                                  color: "#d32f2f",
                                  borderBottomColor: "#d32f2f",
                                },
                                "& .MuiInput-underline:before": {
                                  borderBottomColor: "primary.light",
                                },
                                "& .MuiInput-underline:after": {
                                  borderBottomColor: "primary.main",
                                },
                                "& .MuiInput-underline": { fontSize: "1rem" },
                              }}
                            />

                            {/* <TextField
                              id="outlined-basic"
                              variant="standard"
                              type="number"
                              required={true}
                              label={item?.paramter_name}
                              value={
                                rowData?.[item?.key] == 0
                                  ? 1
                                  : rowData?.[item?.key]
                              }
                              onChange={(e) => {
                                // let temp = [...val]
                                // temp[index] = e.target.value
                                if (
                                  itmKey == tableIdddList["Material Percentage"]
                                ) {
                                  setTotalPercentage(
                                    totalPercentage -
                                    rowData?.[item?.key] +
                                    Number(e.target.value)
                                  );
                                }
                                setRowData({
                                  ...rowData,
                                  [item?.key]: e.target.value,
                                });

                                if (item?.paramter_name === "PCB Component Size") {
                                  setPCBComponentChanged((prev: any) => prev + 1)
                                }

                                setIsAnythingChanged(true);
                              }}
                              autoFocus={item?.key === key1 ? true : false}
                              InputLabelProps={{
                                sx: {
                                  color: "primary.main", // Set label color to primary
                                  "& .MuiFormLabel-asterisk": {
                                    color: "#d32f2f", // Set asterisk color to red
                                  },
                                },
                              }}
                              sx={{
                                width: "100%",
                                "& .MuiSvgIcon-root": {
                                  color: "#000",
                                },
                                ".MuiInputBase-input.MuiOutlinedInput-input": {
                                  fontSize: "1rem",
                                  color:
                                    tableData?.[0]?.key ===
                                      tableIdddList["PCB BOM Table"]
                                      ? "#000000"
                                      : "primary.main",
                                },
                                "& .Mui-error": {
                                  color: "#d32f2f",
                                  borderBottomColor: "#d32f2f",
                                },
                                "& .MuiInput-underline:before": {
                                  borderBottomColor: "primary.main",
                                },
                                "& .MuiInput-underline:after": {
                                  borderBottomColor: "primary.main",
                                },
                                "& .MuiInput-underline": { fontSize: "1rem" },
                              }}
                            /> */}
                          </Box>
                        );
                      }
                      return (
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "0.3rem",
                          }}
                        >
                          <TextField
                            id="outlined-basic"
                            variant="standard"
                            type="number"
                            label={item?.paramter_name}
                            value={rowData?.[item?.key] || 0}
                            disabled={item?.paramter_name == "Result"}
                            onChange={(e) => {
                              // let temp = [...val]
                              // temp[index] = e.target.value
                              if (
                                itmKey == tableIdddList["Material Percentage"]
                              ) {
                                setTotalPercentage(
                                  totalPercentage -
                                  rowData?.[item?.key] +
                                  Number(e.target.value)
                                );
                              }
                              setRowData({
                                ...rowData,
                                [item?.key]: e.target.value,
                              });
                              if (item?.paramter_name === "PCB Component Size") {
                                setPCBComponentChanged((prev: any) => prev + 1)
                              }
                              setIsAnythingChanged(true);
                            }}
                            autoFocus={item?.key === key1 ? true : false}
                            InputLabelProps={{
                              sx: {
                                fontSize: "1rem",
                                color: "primary.main", // Set label color to primary
                                "& .MuiFormLabel-asterisk": {
                                  color: "#d32f2f", // Set asterisk color to red
                                },
                              },
                            }}
                            sx={{
                              width: "100%",
                              "& .MuiSvgIcon-root": {
                                color: "#000",
                              },
                              ".MuiInputBase-input.MuiOutlinedInput-input": {
                                fontSize: "1rem",
                                color:
                                  tableData?.[0]?.key ===
                                    tableIdddList["PCB BOM Table"]
                                    ? "#000000"
                                    : "primary.main",
                              },
                              "& .Mui-error": {
                                color: "#d32f2f",
                                borderBottomColor: "#d32f2f",
                              },
                              "& .MuiInput-underline:before": {
                                borderBottomColor: "primary.light",
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: "primary.main",
                              },
                              "& .MuiInput-underline": { fontSize: "1rem" },
                            }}
                          />
                          {/* <TextField
                            id="outlined-basic"
                            variant="standard"
                            type="number"
                            label={item?.paramter_name}
                            value={rowData?.[item?.key] || 0}
                            disabled={item?.paramter_name == "Result"}
                            onChange={(e) => {
                              // let temp = [...val]
                              // temp[index] = e.target.value
                              if (
                                itmKey == tableIdddList["Material Percentage"]
                              ) {
                                setTotalPercentage(
                                  totalPercentage -
                                  rowData?.[item?.key] +
                                  Number(e.target.value)
                                );
                              }
                              setRowData({
                                ...rowData,
                                [item?.key]: e.target.value,
                              });
                              if (item?.paramter_name === "PCB Component Size") {
                                setPCBComponentChanged((prev: any) => prev + 1)
                              }
                              setIsAnythingChanged(true);
                            }}
                            autoFocus={item?.key === key1 ? true : false}
                            InputLabelProps={{
                              sx: {
                                color: "primary.main", // Set label color to primary
                                "& .MuiFormLabel-asterisk": {
                                  color: "#d32f2f", // Set asterisk color to red
                                },
                              },
                            }}
                            sx={{
                              width: "100%",
                              "& .MuiSvgIcon-root": {
                                color: "#000",
                              },
                              ".MuiInputBase-input.MuiOutlinedInput-input": {
                                fontSize: "1rem",
                                color:
                                  tableData?.[0]?.key ===
                                    tableIdddList["PCB BOM Table"]
                                    ? "#000000"
                                    : "primary.main",
                              },
                              "& .Mui-error": {
                                color: "#d32f2f",
                                borderBottomColor: "#d32f2f",
                              },
                              "& .MuiInput-underline:before": {
                                borderBottomColor: "primary.main",
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: "primary.main",
                              },
                              "& .MuiInput-underline": { fontSize: "1rem" },
                            }}
                          /> */}
                        </Box>
                      );
                    case "BOM-table-calculator":
                      if (
                        item?.key == mappKeyValue["Total Cost"] ||
                        item?.key == mappKeyValue["Length"] ||
                        item?.key == mappKeyValue["Width"] ||
                        item?.key == mappKeyValue["Unit Cost"]
                      ) {
                        return;
                      }
                      if (item?.key == mappKeyValue["Quantity"]) {
                        return (
                          <Box
                            sx={{
                              width: "100%",
                              display: "flex",
                              flexDirection: "column",
                              alignItems: "center",
                              gap: "0.3rem",
                            }}
                          >
                            <TextField
                              id="outlined-basic"
                              variant="standard"
                              type="number"
                              required={true}
                              label={item?.paramter_name}
                              disabled={true}
                              value={
                                rowData?.[item?.key] == 0
                                  ? 1
                                  : rowData?.[item?.key]
                              }
                              onChange={(e) => {
                                // let temp = [...val]
                                // temp[index] = e.target.value
                                if (
                                  itmKey == tableIdddList["Material Percentage"]
                                ) {
                                  setTotalPercentage(
                                    totalPercentage -
                                    rowData?.[item?.key] +
                                    Number(e.target.value)
                                  );
                                }
                                setRowData({
                                  ...rowData,
                                  [item?.key]: e.target.value,
                                });

                                if (item?.paramter_name === "PCB Component Size") {
                                  setPCBComponentChanged((prev: any) => prev + 1)
                                }

                                setIsAnythingChanged(true);
                              }}
                              autoFocus={item?.key === key1 ? true : false}
                              InputLabelProps={{
                                sx: {
                                  fontSize: "1rem",
                                  color: "primary.main", // Set label color to primary
                                  "& .MuiFormLabel-asterisk": {
                                    color: "#d32f2f", // Set asterisk color to red
                                  },
                                },
                              }}
                              sx={{
                                width: "100%",
                                "& .MuiSvgIcon-root": {
                                  color: "#000",
                                },
                                ".MuiInputBase-input.MuiOutlinedInput-input": {
                                  fontSize: "1rem",
                                  color:
                                    tableData?.[0]?.key ===
                                      tableIdddList["PCB BOM Table"]
                                      ? "#000000"
                                      : "primary.main",
                                },
                                "& .Mui-error": {
                                  color: "#d32f2f",
                                  borderBottomColor: "#d32f2f",
                                },
                                "& .MuiInput-underline:before": {
                                  borderBottomColor: "primary.light",
                                },
                                "& .MuiInput-underline:after": {
                                  borderBottomColor: "primary.main",
                                },
                                "& .MuiInput-underline": { fontSize: "1rem" },
                              }}
                            />
                            {/* <TextField
                              id="outlined-basic"
                              variant="standard"
                              type="number"
                              required={true}
                              label={item?.paramter_name}
                              disabled={true}
                              value={
                                rowData?.[item?.key] == 0
                                  ? 1
                                  : rowData?.[item?.key]
                              }
                              onChange={(e) => {
                                // let temp = [...val]
                                // temp[index] = e.target.value
                                if (
                                  itmKey == tableIdddList["Material Percentage"]
                                ) {
                                  setTotalPercentage(
                                    totalPercentage -
                                    rowData?.[item?.key] +
                                    Number(e.target.value)
                                  );
                                }
                                setRowData({
                                  ...rowData,
                                  [item?.key]: e.target.value,
                                });

                                if (item?.paramter_name === "PCB Component Size") {
                                  setPCBComponentChanged((prev: any) => prev + 1)
                                }

                                setIsAnythingChanged(true);
                              }}
                              autoFocus={item?.key === key1 ? true : false}
                              InputLabelProps={{
                                sx: {
                                  color: "primary.main", // Set label color to primary
                                  "& .MuiFormLabel-asterisk": {
                                    color: "#d32f2f", // Set asterisk color to red
                                  },
                                },
                              }}
                              sx={{
                                width: "100%",
                                "& .MuiSvgIcon-root": {
                                  color: "#000",
                                },
                                ".MuiInputBase-input.MuiOutlinedInput-input": {
                                  fontSize: "1rem",
                                  color:
                                    tableData?.[0]?.key ===
                                      tableIdddList["PCB BOM Table"]
                                      ? "#000000"
                                      : "primary.main",
                                },
                                "& .Mui-error": {
                                  color: "#d32f2f",
                                  borderBottomColor: "#d32f2f",
                                },
                                "& .MuiInput-underline:before": {
                                  borderBottomColor: "primary.main",
                                },
                                "& .MuiInput-underline:after": {
                                  borderBottomColor: "primary.main",
                                },
                                "& .MuiInput-underline": { fontSize: "1rem" },
                              }}
                            /> */}
                            {/* <div
                          style={{
                            width: "100%",
                            alignSelf: "left",
                            fontSize: "1.2rem",
                            color:
                              tableData?.[0]?.key ==
                              tableIdddList["PCB BOM Table"]
                                ? "#724A24"
                                : "primary.main",
                          }}
                        >
                          {item?.paramter_name}
                          <sup style={{ color: "red", fontSize: "0.8rem" }}>
                            *
                          </sup>
                        </div> */}
                            {/* <TextField
                          id="outlined-basic"
                          variant="standard"
                          type="number"
                          disabled={true}
                          value={
                            rowData?.[item?.key] == 0 ? 1 : rowData?.[item?.key]
                          }
                          onChange={(e) => {
                            // let temp = [...val]
                            // temp[index] = e.target.value
                            if (
                              itmKey == tableIdddList["Material Percentage"]
                            ) {
                              setTotalPercentage(
                                totalPercentage -
                                  rowData?.[item?.key] +
                                  Number(e.target.value)
                              );
                            }
                            setRowData({
                              ...rowData,
                              [item?.key]: e.target.value,
                            });

                            setIsAnythingChanged(true);
                          }}
                          autoFocus={item?.key === key1 ? true : false}
                          sx={{
                            width: "100%",
                            color: "#000",
                            "& .MuiFormLabel-root": {
                              color: "#000",
                            },
                            "& .MuiFormLabel-root.Mui-focused": {
                              color: "#000",
                            },
                            "& .MuiSvgIcon-root": {
                              color: "#000",
                            },
                            ".MuiInputBase-input.MuiOutlinedInput-input": {
                              fontSize: "1rem",
                              color:
                                tableData?.[0]?.key ==
                                tableIdddList["PCB BOM Table"]
                                  ? "#000000"
                                  : "primary.main",
                            },
                            "& .Mui-error": {
                              color: "#d32f2f",
                              borderBottomColor: "#d32f2f",
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "primary.light",
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "primary.main",
                            },
                            "& .MuiInput-underline": { fontSize: "1rem" },
                          }}
                        /> */}
                          </Box>
                        );
                      }
                      return (
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "0.3rem",
                          }}
                        >
                          <TextField
                            id="outlined-basic"
                            variant="standard"
                            type="number"
                            label={item?.paramter_name}
                            disabled={true}
                            value={rowData?.[item?.key] || 0}
                            onChange={(e) => {
                              // let temp = [...val]
                              // temp[index] = e.target.value
                              if (
                                itmKey == tableIdddList["Material Percentage"]
                              ) {
                                setTotalPercentage(
                                  totalPercentage -
                                  rowData?.[item?.key] +
                                  Number(e.target.value)
                                );
                              }
                              setRowData({
                                ...rowData,
                                [item?.key]: e.target.value,
                              });
                              if (item?.paramter_name === "PCB Component Size") {
                                setPCBComponentChanged((prev: any) => prev + 1)
                              }

                              setIsAnythingChanged(true);
                            }}
                            autoFocus={item?.key === key1 ? true : false}
                            InputLabelProps={{
                              sx: {
                                fontSize: "1rem",
                                color: "primary.main", // Set label color to primary
                                "& .MuiFormLabel-asterisk": {
                                  color: "#d32f2f", // Set asterisk color to red
                                },
                              },
                            }}
                            sx={{
                              width: "100%",
                              "& .MuiSvgIcon-root": {
                                color: "#000",
                              },
                              ".MuiInputBase-input.MuiOutlinedInput-input": {
                                fontSize: "1rem",
                                color:
                                  tableData?.[0]?.key ===
                                    tableIdddList["PCB BOM Table"]
                                    ? "#000000"
                                    : "primary.main",
                              },
                              "& .Mui-error": {
                                color: "#d32f2f",
                                borderBottomColor: "#d32f2f",
                              },
                              "& .MuiInput-underline:before": {
                                borderBottomColor: "primary.light",
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: "primary.main",
                              },
                              "& .MuiInput-underline": { fontSize: "1rem" },
                            }}
                          />


                          {/* <TextField
                            id="outlined-basic"
                            variant="standard"
                            type="number"
                            label={item?.paramter_name}
                            disabled={true}
                            value={rowData?.[item?.key] || 0}
                            onChange={(e) => {
                              // let temp = [...val]
                              // temp[index] = e.target.value
                              if (
                                itmKey == tableIdddList["Material Percentage"]
                              ) {
                                setTotalPercentage(
                                  totalPercentage -
                                  rowData?.[item?.key] +
                                  Number(e.target.value)
                                );
                              }
                              setRowData({
                                ...rowData,
                                [item?.key]: e.target.value,
                              });
                              if (item?.paramter_name === "PCB Component Size") {
                                setPCBComponentChanged((prev: any) => prev + 1)
                              }

                              setIsAnythingChanged(true);
                            }}
                            autoFocus={item?.key === key1 ? true : false}
                            InputLabelProps={{
                              sx: {
                                color: "primary.main", // Set label color to primary
                                "& .MuiFormLabel-asterisk": {
                                  color: "#d32f2f", // Set asterisk color to red
                                },
                              },
                            }}
                            sx={{
                              width: "100%",
                              "& .MuiSvgIcon-root": {
                                color: "#000",
                              },
                              ".MuiInputBase-input.MuiOutlinedInput-input": {
                                fontSize: "1rem",
                                color:
                                  tableData?.[0]?.key ===
                                    tableIdddList["PCB BOM Table"]
                                    ? "#000000"
                                    : "primary.main",
                              },
                              "& .Mui-error": {
                                color: "#d32f2f",
                                borderBottomColor: "#d32f2f",
                              },
                              "& .MuiInput-underline:before": {
                                borderBottomColor: "primary.main",
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: "primary.main",
                              },
                              "& .MuiInput-underline": { fontSize: "1rem" },
                            }}
                          /> */}

                          {/* <div
                        style={{
                          width: "100%",
                          alignSelf: "left",
                          fontSize: "1.2rem",
                          color:
                            tableData?.[0]?.key ==
                            tableIdddList["PCB BOM Table"]
                              ? "#724A24"
                              : "primary.main",
                        }}
                      >
                        {item?.paramter_name}
                      </div>
                      <TextField
                        id="outlined-basic"
                        variant="standard"
                        type="number"
                        disabled={true}
                        value={rowData?.[item?.key] || 0}
                        onChange={(e) => {
                          // let temp = [...val]
                          // temp[index] = e.target.value
                          if (itmKey == tableIdddList["Material Percentage"]) {
                            setTotalPercentage(
                              totalPercentage -
                                rowData?.[item?.key] +
                                Number(e.target.value)
                            );
                          }
                          setRowData({
                            ...rowData,
                            [item?.key]: e.target.value,
                          });

                          setIsAnythingChanged(true);
                        }}
                        autoFocus={item?.key === key1 ? true : false}
                        sx={{
                          width: "100%",
                          color: "#000",
                          "& .MuiFormLabel-root": {
                            color: "#000",
                          },
                          "& .MuiFormLabel-root.Mui-focused": {
                            color: "#000",
                          },
                          "& .MuiSvgIcon-root": {
                            color: "#000",
                          },
                          ".MuiInputBase-input.MuiOutlinedInput-input": {
                            fontSize: "1rem",
                            color:
                              tableData?.[0]?.key ==
                              tableIdddList["PCB BOM Table"]
                                ? "#000000"
                                : "primary.main",
                          },
                          "& .Mui-error": {
                            color: "#d32f2f",
                            borderBottomColor: "#d32f2f",
                          },
                          "& .MuiInput-underline:before": {
                            borderBottomColor: "primary.light",
                          },
                          "& .MuiInput-underline:after": {
                            borderBottomColor: "primary.main",
                          },
                          "& .MuiInput-underline": { fontSize: "1rem" },
                        }}
                      /> */}
                        </Box>
                      );

                    case "text":
                    case "string":
                      if (
                        item?.key == mappKeyValue["Item Name"] ||
                        item?.key == mappKeyValue["Item Description"] ||
                        item?.key == mappKeyValue["Component Type"] ||
                        item?.key == mappKeyValue["Reference"] ||
                        item?.key == mappKeyValue["Currency"] ||
                        item?.key == mappKeyValue["Currency Unit"]
                      ) {
                        return;
                      }

                      return (
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "0.3rem",
                          }}
                        >
                          {/* <div
                      style={{
                        width: "100%",
                        alignSelf: "left",
                        fontSize: "1.2rem",
                        color:
                          tableData?.[0]?.key == tableIdddList["PCB BOM Table"]
                            ? "#724A24"
                            : "primary.main",
                      }}
                    >
                      {item?.paramter_name}
                    </div>
                    <TextField
                      id="outlined-basic"
                      variant="standard"
                      type="text"
                      value={rowData?.[item?.key] || ""}
                      onChange={(e) => {
                        // let temp = [...val]
                        // temp[index] = e.target.value
                        setRowData({ ...rowData, [item?.key]: e.target.value });
                        setIsAnythingChanged(true);
                      }}
                      autoFocus={item?.key === key1 ? true : false}
                      sx={{
                        width: "100%",
                        color: "#000",
                        "& .MuiFormLabel-root": {
                          color: "#000",
                        },
                        "& .MuiFormLabel-root.Mui-focused": {
                          color: "#000",
                        },
                        "& .MuiSvgIcon-root": {
                          color: "#000",
                        },
                        ".MuiInputBase-input.MuiOutlinedInput-input": {
                          fontSize: "1rem",
                          color:
                            tableData?.[0]?.key ==
                            tableIdddList["PCB BOM Table"]
                              ? "#000000"
                              : "primary.main",
                        },
                        "& .Mui-error": {
                          color: "#d32f2f",
                          borderBottomColor: "#d32f2f",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "primary.light",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "primary.main",
                        },
                        "& .MuiInput-underline": { fontSize: "1rem" },
                      }}
                    /> */}
                          <TextField
                            id="outlined-basic"
                            variant="standard"
                            type="text"
                            label={item?.paramter_name}
                            required={true} // Add an asterisk if required
                            value={rowData?.[item?.key] || ""}
                            onChange={(e) => {
                              setRowData({
                                ...rowData,
                                [item?.key]: e.target.value,
                              });
                              if (item?.paramter_name === "PCB Component Size") {
                                setPCBComponentChanged((prev: any) => prev + 1)
                              }
                              setIsAnythingChanged(true);
                            }}
                            autoFocus={item?.key === key1}
                            InputLabelProps={{
                              sx: {
                                fontSize: "1rem",
                                color: "primary.main", // Set label color to primary
                                "& .MuiFormLabel-asterisk": {
                                  color: "#d32f2f", // Set asterisk color to red
                                },
                              },
                            }}
                            sx={{
                              width: "100%",
                              "& .MuiSvgIcon-root": {
                                color: "#000",
                              },
                              ".MuiInputBase-input.MuiOutlinedInput-input": {
                                fontSize: "1rem",
                                color:
                                  tableData?.[0]?.key ===
                                    tableIdddList["PCB BOM Table"]
                                    ? "#000000"
                                    : "primary.main",
                              },
                              "& .Mui-error": {
                                color: "#d32f2f",
                                borderBottomColor: "#d32f2f",
                              },
                              "& .MuiInput-underline:before": {
                                borderBottomColor: "primary.light",
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: "primary.main",
                              },
                              "& .MuiInput-underline": { fontSize: "1rem" },
                            }}
                          />


                        </Box>
                      );

                    case "link":
                      return (
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "0.3rem",
                          }}
                        ><TextField
                            id="outlined-basic"
                            variant="standard"
                            type="url"
                            label={item?.paramter_name}
                            disabled={true}
                            value={rowData?.[item?.key] || ""}
                            onChange={(e) => {
                              // let temp = [...val]
                              // temp[index] = e.target.value
                              setRowData({
                                ...rowData,
                                [item?.key]: e.target.value,
                              });
                              if (item?.paramter_name === "PCB Component Size") {
                                setPCBComponentChanged((prev: any) => prev + 1)
                              }
                              setIsAnythingChanged(true);
                            }}
                            autoFocus={item?.key === key1 ? true : false}
                            InputLabelProps={{
                              sx: {
                                fontSize: "1rem",
                                color: "primary.main", // Set label color to primary
                                "& .MuiFormLabel-asterisk": {
                                  color: "#d32f2f", // Set asterisk color to red
                                },
                              },
                            }}
                            sx={{
                              width: "100%",
                              "& .MuiSvgIcon-root": {
                                color: "#000",
                              },
                              ".MuiInputBase-input.MuiOutlinedInput-input": {
                                fontSize: "1rem",
                                color:
                                  tableData?.[0]?.key ===
                                    tableIdddList["PCB BOM Table"]
                                    ? "#000000"
                                    : "primary.main",
                              },
                              "& .Mui-error": {
                                color: "#d32f2f",
                                borderBottomColor: "#d32f2f",
                              },
                              "& .MuiInput-underline:before": {
                                borderBottomColor: "primary.light",
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: "primary.main",
                              },
                              "& .MuiInput-underline": { fontSize: "1rem" },
                            }}
                          />



                          {/* <div
                        style={{
                          width: "100%",
                          alignSelf: "left",
                          fontSize: "1.2rem",
                          color:
                            tableData?.[0]?.key ==
                            tableIdddList["PCB BOM Table"]
                              ? "#724A24"
                              : "primary.main",
                        }}
                      >
                        {item?.paramter_name}
                      </div>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        type="url"
                        value={rowData?.[item?.key] || ""}
                        onChange={(e) => {
                          // let temp = [...val]
                          // temp[index] = e.target.value
                          setRowData({
                            ...rowData,
                            [item?.key]: e.target.value,
                          });
                          setIsAnythingChanged(true);
                        }}
                        autoFocus={item?.key === key1 ? true : false}
                        sx={{
                          width: "100%",
                          color: "#000",
                          "& .MuiFormLabel-root": {
                            color: "#000",
                          },
                          "& .MuiFormLabel-root.Mui-focused": {
                            color: "#000",
                          },
                          "& .MuiSvgIcon-root": {
                            color: "#000",
                          },
                          ".MuiInputBase-input.MuiOutlinedInput-input": {
                            fontSize: "1rem",
                            color:
                              tableData?.[0]?.key ==
                              tableIdddList["PCB BOM Table"]
                                ? "#000000"
                                : "primary.main",
                          },
                          "& .Mui-error": {
                            color: "#d32f2f",
                            borderBottomColor: "#d32f2f",
                          },
                          "& .MuiInput-underline:before": {
                            borderBottomColor: "primary.light",
                          },
                          "& .MuiInput-underline:after": {
                            borderBottomColor: "primary.main",
                          },
                          "& .MuiInput-underline": { fontSize: "1rem" },
                        }}
                      /> */}
                        </Box>
                      );
                    case "textarea":
                    case "longtext":
                      return (
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "0.3rem",
                          }}
                        >
                          <TextField
                            id="outlined-basic"
                            variant="standard"
                            type="text"
                            multiline
                            rows={3}
                            label={item?.paramter_name}
                            // required={true} // Add an asterisk if required
                            value={rowData?.[item?.key] || ""}
                            onChange={(e) => {
                              // let temp = [...val]
                              // temp[index] = e.target.value
                              setRowData({
                                ...rowData,
                                [item?.key]: e.target.value,
                              });
                              if (item?.paramter_name === "PCB Component Size") {
                                setPCBComponentChanged((prev: any) => prev + 1)
                              }
                              setIsAnythingChanged(true);
                            }}
                            autoFocus={item?.key === key1 ? true : false}
                            InputLabelProps={{
                              sx: {
                                fontSize: "1rem",
                                color: "primary.main", // Set label color to primary
                                "& .MuiFormLabel-asterisk": {
                                  color: "#d32f2f", // Set asterisk color to red
                                },
                              },
                            }}
                            sx={{
                              width: "100%",
                              "& .MuiSvgIcon-root": {
                                color: "#000",
                              },
                              ".MuiInputBase-input.MuiOutlinedInput-input": {
                                fontSize: "1rem",
                                color:
                                  tableData?.[0]?.key ===
                                    tableIdddList["PCB BOM Table"]
                                    ? "#000000"
                                    : "primary.main",
                              },
                              "& .Mui-error": {
                                color: "#d32f2f",
                                borderBottomColor: "#d32f2f",
                              },
                              "& .MuiInput-underline:before": {
                                borderBottomColor: "primary.light",
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: "primary.main",
                              },
                              "& .MuiInput-underline": { fontSize: "1rem" },
                            }}
                          />


                          {/* <div
                        style={{
                          width: "100%",
                          alignSelf: "left",
                          fontSize: "1.2rem",
                          color:
                            tableData?.[0]?.key ==
                            tableIdddList["PCB BOM Table"]
                              ? "#724A24"
                              : "primary.main",
                        }}
                      >
                        {item?.paramter_name}
                      </div>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        type="text"
                        multiline
                        rows={3}
                        value={rowData?.[item?.key] || ""}
                        onChange={(e) => {
                          // let temp = [...val]
                          // temp[index] = e.target.value
                          setRowData({
                            ...rowData,
                            [item?.key]: e.target.value,
                          });
                          setIsAnythingChanged(true);
                        }}
                        autoFocus={item?.key === key1 ? true : false}
                        sx={{
                          width: "100%",
                          color: "#000",
                          "& .MuiFormLabel-root": {
                            color: "#000",
                          },
                          "& .MuiFormLabel-root.Mui-focused": {
                            color: "#000",
                          },
                          "& .MuiSvgIcon-root": {
                            color: "#000",
                          },
                          ".MuiInputBase-input.MuiOutlinedInput-input": {
                            fontSize: "1rem",
                            color:
                              tableData?.[0]?.key ==
                              tableIdddList["PCB BOM Table"]
                                ? "#000000"
                                : "primary.main",
                          },
                          "& .Mui-error": {
                            color: "#d32f2f",
                            borderBottomColor: "#d32f2f",
                          },
                          "& .MuiInput-underline:before": {
                            borderBottomColor: "primary.light",
                          },
                          "& .MuiInput-underline:after": {
                            borderBottomColor: "primary.main",
                          },
                          "& .MuiInput-underline": { fontSize: "1rem" },
                        }}
                      /> */}
                        </Box>
                      );

                    case "dropdown":
                    case "singleselect":
                      return (
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "0.3rem",
                            position: "relative",
                          }}
                        >
                          {item?.paramter_name === "PCB Component Size" && (
                            <span
                              style={{
                                marginTop: "0.5rem",
                                marginLeft: "0.5rem",
                                position: "absolute",
                                right: "2.5rem",
                                top: "1rem",
                                zIndex: "100",
                              }}
                            >
                              {" "}
                              {/* Add spacing between text and icon */}
                              <InfoOutlinedIcon
                                // onClick={handleIconClick}
                                onClick={(e: any) => {
                                  displayImage();
                                }}
                                sx={{ cursor: "pointer", color: "primary.main" }}
                              />
                            </span>
                          )}

                          {/* RViewerJS to display the image */}

                          <div style={{ display: "none" }}>
                            {/* @ts-ignore */}
                            <RViewerJS>
                              <div>
                                {imageUrls?.length > 0 &&
                                  imageUrls?.map((item: any) => {
                                    return (
                                      <img src={item} alt={"img"} ref={imgRef} />
                                    );
                                  })}
                              </div>
                            </RViewerJS>
                          </div>


                          <FormControl variant="standard" sx={{ width: "100%" }}>
                            <InputLabel id="demo-simple-select-standard-label"
                              sx={{
                                fontSize: "1rem",
                                fontWeight: "400",
                                color: "primary.main",
                                "& .MuiFormLabel-asterisk": {
                                  color: "#d32f2f",
                                },
                              }}
                              required> {item?.paramter_name}</InputLabel>
                            <Select
                              labelId="demo-simple-select-standard-label"
                              id="demo-simple-select-standard"
                              label="Age"
                              sx={{
                                width: "100%",
                                "& .MuiSelect-select": {
                                  fontSize: "1rem",
                                  color: tableData?.[0]?.key === tableIdddList["PCB BOM Table"] ? "primary.main" : "primary.main",
                                },
                                "& .MuiSvgIcon-root": {
                                  color: "primary.main",
                                },
                                "&:before": { // Before the input (default state)
                                  borderBottom: "1px solid #ddefff",
                                },
                                "&:after": { // After the input (focused state)
                                  borderBottom: "1px solid primary.main",
                                },
                                "&.Mui-error": {
                                  color: "#d32f2f",
                                  "&:before": {
                                    borderBottomColor: "#d32f2f",
                                  },
                                  "&:after": {
                                    borderBottomColor: "#d32f2f",
                                  },
                                },
                              }}
                              multiple={false}
                              value={rowData?.[item?.key] || ""}
                              autoFocus={item?.key === key1 ? true : false}
                              onChange={(e) => {
                                setRowData({
                                  ...rowData,
                                  [item?.key]: e.target.value,
                                });
                                if (item?.paramter_name === "PCB Component Size") {
                                  setPCBComponentChanged((prev: any) => prev + 1)
                                }
                                setIsAnythingChanged(true);
                              }}
                              input={<Input id="standard-input" placeholder="item?.paramter_name" />}
                            >
                              {item?.allowed_values?.map((item: any) => (
                                <MenuItem value={item}>{item}</MenuItem>
                              ))}
                            </Select>
                          </FormControl>



                          {/* <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center", // Ensures vertical alignment
                          fontSize: "1.2rem",
                          color:
                            tableData?.[0]?.key ===
                            tableIdddList["PCB BOM Table"]
                              ? "#724A24"
                              : "primary.main",
                        }}
                      >
                        {item?.paramter_name}

                        {item?.paramter_name === "PCB Component Size" && (
                          <span
                            style={{
                              marginTop: "0.5rem",
                              marginLeft: "0.5rem",
                            }}
                          >
                            {" "}
                          
                            <InfoOutlinedIcon
                              // onClick={handleIconClick}
                              onClick={(e: any) => {
                                displayImage();
                              }}
                              sx={{ cursor: "pointer", color: "primary.main" }}
                            />
                          </span>
                        )}

                      

                        <div style={{ display: "none" }}>
                          
                          <RViewerJS>
                            <div>
                              {imageUrls?.length > 0 &&
                                imageUrls?.map((item: any) => {
                                  return (
                                    <img src={item} alt={"img"} ref={imgRef} />
                                  );
                                })}
                            </div>
                          </RViewerJS>
                        </div>
                      </div>
                      <FormControl fullWidth>
                        <Select
                          id="demo-simple-select"
                          value={rowData?.[item?.key] || ""}
                          autoFocus={item?.key === key1 ? true : false}
                          onChange={(e) => {
                            setRowData({
                              ...rowData,
                              [item?.key]: e.target.value,
                            });
                            setIsAnythingChanged(true);
                          }}
                          sx={{
                            width: "100%",
                            marginTop: "0.5rem !important",
                            "&:before": {
                              borderBottomColor: "primary.light",
                            },
                            "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                              {
                                color:
                                  tableData?.[0]?.key ==
                                  tableIdddList["PCB BOM Table"]
                                    ? "#724A24"
                                    : "primary.main",
                                fontSize: "1rem",
                              },
                            ".MuiSvgIcon-root.MuiSelect-icon": {
                              color: "primary.main",
                            },
                          }}
                        >
                          {item?.allowed_values?.map((item: any) => (
                            <MenuItem value={item}>{item}</MenuItem>
                          ))}
                        </Select>
                      </FormControl> */}
                        </Box>
                      );

                    case "boolean":
                      if (item?.key == mappKeyValue["is_validated"]) {
                        return;
                      }
                      return (
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "0.3rem",
                          }}
                        >
                          {/* <div
                        style={{
                          width: "100%",
                          alignSelf: "left",
                          fontSize: "1.2rem",
                          color:
                            tableData?.[0]?.key ==
                            tableIdddList["PCB BOM Table"]
                              ? "#724A24"
                              : "primary.main",
                        }}
                      >
                        {item?.paramter_name}
                      </div> */}
                          <FormControl fullWidth>
                            <InputLabel
                              id="demo-multiple-name-label"
                              sx={{ fontSize: "0.75rem" }}
                              style={{
                                color: "#007fff",
                                fontSize: "1.1rem",
                              }}
                            >
                              {item?.paramter_name}
                            </InputLabel>
                            <Switch
                              id="demo-simple-select"
                              checked={
                                rowData?.[item?.key] == true ? true : false
                              }
                              autoFocus={item?.key === key1 ? true : false}
                              onChange={(e) => {
                                setRowData({
                                  ...rowData,
                                  [item?.key]: e.target.checked,
                                });
                                if (item?.paramter_name === "PCB Component Size") {
                                  setPCBComponentChanged((prev: any) => prev + 1)
                                }
                                // setIsAnythingChanged(true);
                              }}
                              sx={{
                                // width: '100%',
                                marginTop: "0.5rem !important",
                                "&:before": {
                                  borderBottomColor: "primary.light",
                                },
                                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                                {
                                  color:
                                    tableData?.[0]?.key ==
                                      tableIdddList["PCB BOM Table"]
                                      ? "#724A24"
                                      : "primary.main",
                                  fontSize: "1rem",
                                },
                                ".MuiSvgIcon-root.MuiSelect-icon": {
                                  color: "primary.main",
                                },
                              }}
                            />
                          </FormControl>
                        </Box>
                      );

                    case "image":
                      return (
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "0",
                            paddingBottom: "0",
                            borderBottom: "1px solid #ddefff",
                          }}
                        >
                          <div
                            style={{
                              width: "100%",
                              alignSelf: "left",
                              fontSize: "0.82rem",
                              color:
                                tableData?.[0]?.key ==
                                  tableIdddList["PCB BOM Table"]
                                  ? 'primary.main'
                                  : 'primary.main',
                            }}
                          >
                            {item?.paramter_name}
                          </div>
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "start",
                              minHeight: "1rem",

                              paddingBottom: "0"
                            }}
                          >
                            <ImageEditViewBox
                              key1={item?.key}
                              val={rowData?.[item?.key]}
                              setDataForUpdate={setDataForUpdate}
                              target={target}
                              AllData={tableData}
                              allOptions={
                                tableData?.[0]?.header_item?.filter(
                                  (itemmm: any) => itemmm?.key === item?.key
                                ) &&
                                tableData?.[0]?.header_item?.filter(
                                  (itemmm: any) => itemmm?.key === item?.key
                                )?.[0]?.allowed_values
                              }
                              option={tableData?.[0]?.header_item?.filter(
                                (itemmm: any) => itemmm?.key === item?.key
                              )}
                              itmKey={itmKey}
                              setColorButton={setColorButton}
                              setShowButton={setShowButton}
                              setCounter={setCounter}
                              setSaveDisabled={setIsAnythingChanged}
                              rowData={rowData}
                              setRowData={setRowData}
                            />
                          </div>
                        </Box>
                      );

                    case "multiselect":
                      return (
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            gap: "0.3rem",
                            marginTop: "-1rem",
                          }}
                        >
                          {/* <div
                        style={{
                          width: "100%",
                          alignSelf: "left",
                          fontSize: "1.2rem",
                          color:
                            tableData?.[0]?.key ==
                            tableIdddList["PCB BOM Table"]
                              ? "#724A24"
                              : "primary.main",
                        }}
                      >
                        {item?.paramter_name}
                      </div> */}
                          <Autocomplete
                            multiple
                            id="tags-standard"
                            limitTags={1}
                            options={item?.allowed_values}
                            value={rowData?.[item?.key] || []}
                            renderOption={(props, option) => (
                              <li {...props} style={{ fontSize: "12px" }}>
                                {option}
                              </li>
                            )}
                            onChange={(e, newSystemValue) => {
                              setRowData({
                                ...rowData,
                                [item?.key]: [...newSystemValue],
                              });
                              setIsAnythingChanged(true);
                            }}
                            renderInput={(params) => (
                              <TextField
                                {...params}
                                autoFocus={item?.key === key1 ? true : false}
                                label={item?.paramter_name}
                                variant="standard"
                                placeholder="Select"
                                sx={{
                                  marginTop: "1rem",
                                  "& .MuiInput-underline:before": {
                                    borderBottomColor: "primary.light",
                                  },
                                  "& .MuiInput-underline:after": {
                                    borderBottomColor: "primary.main",
                                  },
                                  ".MuiSvgIcon-root": { color: "primary.main" },
                                }}
                              />
                            )}
                            sx={{
                              // margin: "0.5rem",
                              width: "97%",
                              "& .MuiFormLabel-root": {
                                color: "#007fff",
                              },
                              "& .MuiFormLabel-root.Mui-focused": {
                                color:
                                  tableData?.[0]?.key ==
                                    tableIdddList["PCB BOM Table"]
                                    ? "#724A24"
                                    : "primary.main",
                                borderColor: "primary.main",
                              },
                              "&:focus-visible": {
                                outline: "none",
                                borderColor: "#007fff",
                              },
                              "&:hover": {
                                // outline: "none",
                                borderColor: "#007fff",
                              },
                            }}
                          />
                        </Box>
                      );
                  }
                })}
            </Box>

            <Box
              sx={{
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              {itmKey == tableIdddList["Material Percentage"] && (
                <Typography
                  sx={{
                    width: "100%",
                    flexGrow: "1",
                    fontSize: "1rem",
                    color: "red",
                    margin: "1rem 0rem",
                    padding: "0rem 0.5rem",
                  }}
                >
                  *Sum of all parameters must be 100%
                </Typography>
              )}
              {itmKey == tableIdddList["Material Percentage"] && (
                <Typography
                  sx={{
                    width: "100%",
                    fontSize: "1rem",
                    margin: "1rem 0rem",
                    padding: "0rem 0.5rem",
                  }}
                >
                  Total Percentage: {totalPercentage}%
                </Typography>
              )}
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  gap: "0.5rem",
                  margin: "1rem 0rem",
                  padding: "0rem 0.5rem",
                }}
              >
                <LoadingButton
                  variant="outlined"
                  color="error"
                  loading={isDeleting}
                  onClick={() => handleDelete()}
                >
                  Delete Row
                </LoadingButton>
                {itmKey == tableIdddList["Material Percentage"] && (
                  <LoadingButton
                    variant="contained"
                    loading={isSave}
                    disabled={saveDisabled || totalPercentage != 100}
                    onClick={(e: any) => handleDataAdd(e)}
                  >
                    Save
                  </LoadingButton>
                )}
                {/* check based on name and description any duplicate is present or not -> if yes check for duplicates and if not save the data */}
                {itmKey != tableIdddList["Material Percentage"] && (
                  <LoadingButton
                    variant="contained"
                    loading={isSave}
                    disabled={saveDisabled && !checkDuplicateShow}
                    onClick={(e: any) => handleClickOnSave(e)}
                  >
                    {checkDuplicateShow ? "Check Duplicate" : "Save"}
                  </LoadingButton>
                )}
              </Box>
            </Box>
          </Box>
          {selectDuplicate.isOpen && (
            <SelectDuplicate
              isOpen={selectDuplicate.open}
              onCloseModal={selectDuplicate.close}
              revisionList={revisionList}
              rowData={rowData}
              index={target}
              table_id={tableData?.[0]?.key}
              closeParentModal={onCloseModal}
              setCounter={setCounter}
              setCheckDuplicateShow={setCheckDuplicateShow}
              setRevision={setRevision}
              setDisabledFeilds={setDisabledFeilds}
              setSelectedRevision={setSelectedRevision}
              setFormType={setFormType}
              mappKeyValue={mappKeyValue}
              setDisableItemDescriptionAndName={setDisableItemDescriptionAndName}
              setSaveDisabled={setSaveDisabled}
            />
          )}
        </Box>
      </Box>
    </Modal>
  );
}
