import Box from "@mui/material/Box";
import Portal from "@mui/material/Portal";
import React, { useEffect } from "react";

// vendors
// services
// utils
// constants
// factories
// core
// redux
// ui-components

// others
// styles
import styles from "./TagTransitionablePortal.module.scss";
import CloseIcon from '@mui/icons-material/Close';

// interfaces and types
// lazy

// ----------------------------------------------------------------------------------

interface TagTransitionablePortalProps {
  isOpen: boolean;
  onClose: () => void;
  tagData: any;
  setVistedId: any;
}

const TagTransitionablePortal: React.FC<TagTransitionablePortalProps> = ({
  isOpen,
  onClose,
  tagData,
  setVistedId,
}) => {
  const [loaded, setLoaded] = React.useState(false);


  const [show, setShow] = React.useState(false);
  const container = React.useRef(null);
  useEffect(() => {
    setLoaded(false);
    // setVistedId(0);
  }, [isOpen]);

  const handleClose = () => {
    setLoaded(false);
    onClose();
    setShow(false)
    setVistedId(0);
  };


  return (
    <>
    {isOpen &&
    <div className={styles.portalContainer}>
       
   <CloseIcon   titleAccess="Close" onClick={handleClose} sx={{marginLeft:"27rem !important",cursor:"pointer"}}/>

           




                      <div>

                          {tagData?.image_url !== "" ? (
                              // eslint-disable-next-line jsx-a11y/alt-text
                              <img
                                  src={ tagData?.image_url }
                                  style={{ width: "100%", maxHeight: "20rem", objectFit: "cover" }}
                                  onLoad={() => setLoaded(true)} />
                          ) : null}

                          {/* <div
                              adl-scrollbar="true"
                              adl-scrollbar-width="0.6"
                              style={{ maxHeight: "calc(100vh - 35em)", minHeight: "5rem" }}
                          > */}
                              <p>{tagData?.text}</p>
                          {/* </div> */}
                      </div>

                 

  </div>
          
                           }
                           </>
 
  );
};

// ----------------------------------------------------------------------------------

export default TagTransitionablePortal;
