import {
  Avatar,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Divider,
  MenuItem,
  Typography,
} from "@mui/material";
import React from "react";
import avatar from "../../Assets/images/avatar.svg";
import swal from "sweetalert";
import CheckIcon from "@mui/icons-material/Check";
import ClearIcon from "@mui/icons-material/Clear";
import Image from "../../ViewModule/FeatureLandingPage/ArchitectureImageCard";
import loaderImage from "../../Assets/images/loading-screen.gif";
import { API } from "../../api-services";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import { UserActivationActivityLog } from "./UserActivationActivityLog";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useHistory } from "react-router-dom";
import CancelIcon from '@mui/icons-material/Cancel';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';


export interface ISingleUserCardProps {
  index: number;
  item: any;
  setApiCallCount: any;
}

export default function SingleUserCardUnapproved(Props: ISingleUserCardProps) {
  const { index, item, setApiCallCount } = Props;
  const history = useHistory();
  const [selectedDate, setSelectedDate] = React.useState<any>("");

  const activitylogModal = useBaseParamsModal();
  const addEditModal = useBaseParamsModal();
  const [open, setOpen] = React.useState<boolean>(false);
  const handleClose = () => {
    history.push("/user-management");
    setOpen(false);
  };

  const [userType, setuserType] = React.useState('');

  const handleChangeUserType = (event: SelectChangeEvent) => {
    setuserType(event.target.value as string);
  };



  const handleChange = (e: any) => {
    setSelectedDate(e.target.value);
  };

  var AvatarName=(item:any)=>{
     const [first, last] = item?.split(' ')  
     const FirstLatter=first?.charAt(0);  
     const lastWord=last?.charAt(0)  
     return `${FirstLatter}${lastWord}`;  
  } 


  return (
    <>
      <Box
        sx={{
          borderRadius: "5px",
          boxShadow:
            "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px",
          display: "flex",
          margin: "0 0.5rem",
          marginBottom: "0.5rem",
          backgroundColor:
            item?.user_category == "External" ? "yellow" : "white",
          "&:hover": {
            backgroundColor: '#d0eaff',
            transform: "scale(1.03)",
            transition: "transform 0.5s ease",
          },
        }}
      >
        <Box sx={{ margin: "auto", width: "6rem", padding: "10px" }}>
          {item?.user_info ? (
            <Image
              src={item?.user_info}
              placeholderImg={loaderImage}
              title={item?.fullname}
              style={{ width: "5rem", height: "5rem", borderRadius: '50%',objectFit:'cover' }}
            />
          ) : (
            <Avatar
                  src="/broken-image.jpg"
                  sx={{
                    width: "5rem",
                    height: "5rem",                    
                    objectFit: "cover",
                    backgroundColor: "#007fff !important",
                    textTransform:'uppercase',
                  }}
                >
                 <Typography sx={{fontSize:'2rem !important',textTransform:'uppercase',}}> {AvatarName(item?.fullname)}</Typography>
                </Avatar>
          )}
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "-2rem",
              marginLeft: "0rem",
            }}
          >
            {item?.is_active == true ? (
              <Box
                sx={{
                  borderRadius: "50%",
                  height: "1.5rem",
                  width: "1.5rem",
                  backgroundColor: "#5bad06",
                  position: "relative",
                  zIndex: "1",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <CheckIcon sx={{ color: "white", fontSize: "1.2rem" }} />
              </Box>
            ) : (
              <Box
                sx={{
                  borderRadius: "50%",
                  height: "1.5rem",
                  width: "1.5rem",
                  backgroundColor: "red",
                  position: "relative",
                  zIndex: "1",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <ClearIcon sx={{ color: "white", fontSize: "1.2rem" }} />
              </Box>
            )}
          </Box>
        </Box>
        <Box sx={{ width: "100%", paddingRight: "0.5rem" }}>
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              sx={{
                fontSize: "1.1rem",
                color: "black",
                fontWeight: "bold",
                marginTop: "0.5rem",
                textTransform: "capitalize",
              }}
            >
              {item?.fullname}
            </Typography>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: "10px",
              }}
            >
              <CheckCircleIcon
                titleAccess="Approve"
                sx={{ fontSize: "1.2rem", cursor: "pointer", color: "#5bad06" }}
                onClick={() => setOpen(true)}
              />
              <Dialog
                PaperProps={{
                  style: { borderRadius: 10, width: '20%' },
                }}
                open={open}
                onClose={handleClose}
              >
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      height: "2.5rem",
                      width: "100%",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "1rem",
                        fontWeight: "600",
                        width: "100%",
                        textAlign: "center",
                        marginRight: "-2rem",
                      }}
                    >
                      User Approval
                    </Typography>
                    <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={handleClose} />
                  </Box>
                  <Divider
                    sx={{
                      borderColor: "primary.light",
                      margin: "0 1rem",
                      marginBottom: "1rem",
                    }}
                  />
                  <Box sx={{ width: "100%", textAlign: "center" }}>
                    {/* <CheckCircleOutlineIcon sx={{ color: 'green', fontSize: '5rem', textAlign:'center' }} /> */}
                    {/* <WarningAmberRoundedIcon /> */}
                    {/* {` Alert`} */}
                  </Box>
                  <DialogContent
                    sx={{ padding: "0 20px 10px", alignContent: "center" }}
                  >
                    <InputLabel sx={{ fontSize: '0.9rem', color: 'primary.main' }}>User Expirations</InputLabel>
                    <input
                      type="date"
                      onChange={handleChange}
                      style={{
                        color: "#007fff",
                        borderTop: "none",
                        borderLeft: "none",
                        borderRight: "none",
                        borderBottom: "1px solid #007fff",
                        paddingBottom: "5px",
                        fontFamily: "roboto",
                        cursor: 'pointer',
                        width: '100%'
                      }}
                    />
                    <Box sx={{ marginTop: '1rem' }}>
                      <InputLabel sx={{ fontSize: '0.9rem', color: 'primary.main' }}>User Type</InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={userType}
                        label="User Type"
                        onChange={handleChangeUserType}
                        sx={{
                          width: '100%',
                          borderBottom: '1px solid',
                          borderBottomColor: 'primary.light',
                          fontSize: '1rem',
                        }}
                        variant="standard"
                      >
                        <MenuItem sx={{ fontSize: '1rem' }} value={"Project Administrator"}>Project Administrator</MenuItem>
                        {/* <MenuItem value={"Module Administrator"}>Module Administrator</MenuItem> */}
                        <MenuItem sx={{ fontSize: '1rem' }} value={"User"}>User</MenuItem>
                        <MenuItem sx={{ fontSize: '1rem' }} value={"Guest User"}>Guest User</MenuItem>
                      </Select>
                    </Box>

                  </DialogContent>
                  <DialogActions>
                    <Button
                      size="small"
                      variant="contained"
                      onClick={() => {
                        API.post(
                          "/auth/user_request/",
                          {
                            accepted: true,
                            date: selectedDate,
                            email_otp: item?.email_otp,
                            user_id: item?.id,
                            phone_otp: item?.phone_otp,
                            user_type: userType,
                          },
                          {},
                          0
                        ).then((res: any) => {
                          setApiCallCount((prev: any) => prev + 1);
                          setOpen(false)
                        });
                      }}
                    >
                      Activate User
                    </Button>
                  </DialogActions>
                </Box>
              </Dialog>

              <HighlightOffIcon
                titleAccess="Reject"
                sx={{ fontSize: "1.2rem", cursor: "pointer", color: "red" }}
                onClick={() => {
                  swal({
                    title: "Are you sure?",
                    text: "User request will be reject.",
                    // icon: "warning",
                    buttons: ["No", "Yes"],
                  }).then((isData: any) => {
                    if (isData) {
                      API.post(
                        "/auth/user_request/",
                        {
                          accepted: false,
                          user_id: item?.id,
                        },
                        {},
                        0
                      ).then((res: any) => {
                        setApiCallCount((prev: any) => prev + 1);
                      });
                    }
                  }).catch((e: any) => {
                  });
                }}
              />
              {/* <InfoIcon
                    sx={{ fontSize: "1.2rem", cursor: "pointer" }}
                    onClick={() => activitylogModal.open(item.id)}
                  />
                  <EditIcon
                    sx={{ fontSize: "1.2rem", cursor: "pointer" }}
                    onClick={() => addEditModal.open(item)}
                  />

                  <CloseIcon
                    sx={{ fontSize: "1.2rem", cursor: "pointer", color: "red" }}
                    onClick={() => {
                      API.patch(
                        `auth/user/${item.id}/`,
                        {
                          is_active: item?.is_active ? false : true,
                          user_expiration: item.is_active
                            ? undefined
                            : moment().format("YYYY-MM-DD").toString(),
                        },
                        {},
                        0
                      ).then((res: any) => {
                        setApiCallCount((prev: any) => prev + 1);
                      });
                    }}
                  /> */}
            </Box>
          </Box>
          <Typography
            sx={{
              fontSize: "0.9rem",
              color: "black",
              marginLeft: "0.5rem",
            }}
          >
            {item?.email}
          </Typography>
          <Typography
            sx={{
              fontSize: "0.9rem",
              color: "black",
              marginLeft: "0.5rem",
            }}
          >
            {item?.phone ? `${item?.country_code}-${item?.phone}` : "--"}
          </Typography>
          {item?.is_expired ? (
            <Typography sx={{ fontSize: "0.9rem", color: "red" }}>
              Access expired
            </Typography>
          ) : (
            <Typography
              sx={{
                fontSize: "0.9rem",
                color: "black",
                marginBottom: "0.5rem",
              }}
            >
              {`Access expires on ${item?.user_expiration} ( ${item?.expiry_in} Days)`}
            </Typography>
          )}
        </Box>
        {activitylogModal.isOpen && (
          <UserActivationActivityLog
            isOpen={activitylogModal.isOpen}
            onClose={activitylogModal.close}
            user={activitylogModal.propsId}
          />
        )}
      </Box>

      {/* {addEditModal.isOpen && (
        <AddEditUserFormModal
          isOpen={addEditModal.isOpen}
          onClose={addEditModal.close}
          userDetails={addEditModal.propsId}
        />
      )} */}
    </>
  );
}
