import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Button, { ButtonProps } from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import styles from "./BomTableModal.module.scss";
import TableContainer from "@mui/material/TableContainer";
import TableCell from "@mui/material/TableCell";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useState, useRef, useEffect } from "react";
import FunctionsIcon from "@mui/icons-material/Functions";
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import { API } from "../api-services";
import { ADMIN } from "../Redux/Services/admin.service";
import TableBody from "@mui/material/TableBody";
import RViewerJS from "viewerjs-react";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";
import Image from "../ViewModule/FeatureLandingPage/ArchitectureImageCard"
import NoImgLoader from "../Assets/images/loading-screen-landing.gif";
import CancelIcon from '@mui/icons-material/Cancel';

// loading-screen-landing.gif
import { DataGrid, GridColDef, GridCsvExportMenuItem, GridCsvExportOptions, GridToolbar, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExportContainer, GridValueGetterParams } from "@mui/x-data-grid";
import { Divider, Skeleton, styled } from "@mui/material";
import { isUndefined } from "lodash";
import SkeletonLoader from "../ComponentUI/SkeletonLoader/SkeletonLoader";
export interface BomTableModalProps {
  isOpen: any;
  onCloseModal: any;
  pathData: any;
  TableName: any;
  Topvaults: any;
  PartData: any;
  LeafNode: any;
  DepthNode: any
}


const csvOptions: GridCsvExportOptions = { fileName: 'Table In CSV', delimiter: ",", utf8WithBom: true };

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer style={{ height: '1.5rem', marginBottom: '0.5rem' }}>
    <span style={{ position: 'absolute', right: '0rem' }}>
      {/* <GridToolbarColumnsButton /> */}
      {/* <GridToolbarDensitySelector /> */}
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
)

const BomTableModal = (props: BomTableModalProps) => {
  const { isOpen, onCloseModal, TableName, Topvaults, pathData, PartData, LeafNode, DepthNode } = props;
  const [Loader, setLoader] = useState<any>(false)

  const ImageViewer = useRef<any>();


  const [TableData, setTableData] = useState<any>()




  useEffect(() => {
    if (Topvaults?.length > 0) {
      setLoader(true)
      setTableData(null)
      API.get("/customer/compare_bom_table/", { top_vaults: Topvaults.toString(), part_name: PartData, parameter_name: TableName, path: pathData, is_leaf: LeafNode, depth: DepthNode }).then((res: any) => {
        setLoader(false)

        setTableData(res.data)


      }).catch((err: any) => {
        setLoader(false)
      })
    }
  }

    , [Topvaults, isOpen])




  const PcbTableRow = (Rowdata: any) => {
    var valuedata =
      Rowdata &&
      Rowdata.map((item: any, id: any) => ({
        ...item,
        id: id + 1,
      }));

    return valuedata;
  };

  // const handleClick = (srcImg: any) => {
  //    ImageViewer.current.src = srcImg;
  //   ImageViewer.current?.click?.();



  // };
  const PcbTableHeader = (HeaderData: any) => {
    var Pcbdata: any = TableData && Object.keys(HeaderData);

    var valuedata: any =
      Pcbdata &&
      Pcbdata?.map((key: any, value: any) => {
        return {
          field: key,
          flex: 1,
          minWidth: key == "Name" ? 200 : 120,
          headerName: key,
          disableExport:key === "Image"?true:false,          
          headerClassName: styles.Dataheader,
          renderCell: (params: any) => {
            if (key == "Image") {

              return (
                <>
                  {/* @ts-ignore */}
                  <RViewerJS>
                    <Image
                      src={params?.row[key]}
                      placeholderImg={NoImgLoader}
                      loading="lazy"
                      style={{ cursor: "pointer", width: '6rem', height: '4rem', objectFit: 'contain' }}
                      // onClick={() => {

                      //   handleClick(params.row[key]);
                      // } } 
                      alt={params?.row[key]} />
                  </RViewerJS>
                </>
              );
            }

            else {
              return <span>{params.row[key]}</span>;
            }
          },
        };
      });
    return valuedata;
  };

 

  const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
    "& .MuiDataGrid-main": {

      overflow: "unset"
    },
    "& .MuiDataGrid-virtualScroller": {

      marginTop: "0!important"
    },
    "& .MuiDataGrid-columnHeaders": {
      backgroundColor: "aliceblue",
      position: "sticky",
      zIndex: '1500',
    }
  }));

  return (
    <Modal open={isOpen} onClose={onCloseModal} sx={{ overflow: 'hidden' }}>
      <Box className={styles.boxCont}>
        <>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', height: '2.5rem', width: '100%', zIndex: '1' }}>
            <span></span>
            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
          </Box>
          {
            Loader ? <>
              <Box sx={{ width: '100%', padding: '0 1rem', height: "50vh", display: 'flex', columnGap: '3rem' }}>
                <Box sx={{ width: "100%" }}>
                  <Skeleton sx={{ height: '3rem', marginBottom: '1rem' }} />
                  <SkeletonLoader />
                </Box>
                <Box sx={{ width: "100%" }}>
                  <Skeleton sx={{ height: '3rem', marginBottom: '1rem' }} />
                  <SkeletonLoader />
                </Box>
              </Box>
            </> :
              <>
                <Box className={styles.popHeader}>
                  <Box sx={{
                    display: 'flex', alignItems: "center", zIndex: '1000', position: 'relative',
                    marginBottom: "0rem",
                    width: "100%",
                    justifyContent: "space-around"
                  }}>
                    {TableData && Object.keys(TableData).map((key: any, value: any) => {
                      return (<div>
                        <Typography style={{ fontSize: '1rem', lineHeight: '1.2', fontWeight: 700 }}>{key}</Typography>
                      </div>);
                    })}
                  </Box>
                </Box>
                <Box sx={{ width: "100%", padding: '-1px 1rem 0rem', display: 'flex',columnGap:'0.5rem',marginBottom:'0.5rem' }}
                  adl-scrollbar="true"
                  adl-scrollbar-width="0.3">
                  {TableData && Object.keys(TableData).map((item: any, index: any) => {
                    // console.log(item, "itrtrtrttrtr")
                    return (<>
                      <StyledDataGrid
                        rows={PcbTableRow(TableData[item]["data"])}
                        columns={PcbTableHeader(TableData[item]["data"][0])}
                        components={{ Toolbar: () => { return CustomToolbar(); } }}
                        // components={{ Toolbar: GridToolbar }}
                        sx={{ borderColor: 'primary.light', width: '25rem',
                        "& ::-webkit-scrollbar": {
                          width: "0.3rem",
                          height: "0.3rem",
                        },
                        "& ::-webkit-scrollbar-thumb": {
                          // background: $thumb-color;
                          backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                          borderRadius: "10px",
                        },
                        "& ::- webkit-scrollbar-thumb: hover ": {
                          // width: "4px",
                          backgroundColor: "#045DE9 !important",
                        },
                        "::-webkit-scrollbar-track": {
                          backgroundColor: "#e1e1f3 !important",
                        },
                        "&.MuiDataGrid-root .MuiDataGrid-cell": {
                          borderBottomColor: "primary.light",
                          }, }}
                        density="compact"
                        autoHeight={true}
                        disableSelectionOnClick  
                        hideFooter={true}                      
                        hideFooterPagination={true} />
                    </>
                    );
                  })}
                </Box>
              </>
          }
        </>

      </Box>
    </Modal>
  );
};

export default BomTableModal;
