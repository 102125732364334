import React, { useEffect, useState } from "react";
import styles from "./CalibrationRecyclebinPage.module.scss";
import { useHistory, useRouteMatch } from "react-router-dom";
import {
  Box,
  IconButton,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridToolbarColumnsButton,
} from "@mui/x-data-grid";
import { ButtonProps } from "@mui/material/Button";
import { styled } from '@mui/material/styles';
import useWindowDimensions from "../ui-reusable-component/useWindowDimensions";
import VisibilityIcon from '@mui/icons-material/Visibility';
import { AxiosResponse } from "axios";
import { API } from "../api-services";
import moment from "moment";
import RestoreIcon from '@mui/icons-material/Restore';
import swal from 'sweetalert';

const StyledGridOverlay = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
  '& .ant-empty-img-1': {
    fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
  },
  '& .ant-empty-img-2': {
    fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
  },
  '& .ant-empty-img-3': {
    fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
  },
  '& .ant-empty-img-4': {
    fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
  },
  '& .ant-empty-img-5': {
    fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
    fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
  },
}));

function CustomNoRowsOverlay() {
  return (
    <StyledGridOverlay>
      <svg
        width="120"
        height="100"
        viewBox="0 0 184 152"
        aria-hidden
        focusable="false"
      >
        <g fill="none" fillRule="evenodd">
          <g transform="translate(24 31.67)">
            <ellipse
              className="ant-empty-img-5"
              cx="67.797"
              cy="106.89"
              rx="67.797"
              ry="12.668"
            />
            <path
              className="ant-empty-img-1"
              d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
            />
            <path
              className="ant-empty-img-2"
              d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
            />
            <path
              className="ant-empty-img-3"
              d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
            />
          </g>
          <path
            className="ant-empty-img-3"
            d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
          />
          <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
            <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
            <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
          </g>
        </g>
      </svg>
      <Box sx={{ mt: 1 }}>No Rows</Box>
    </StyledGridOverlay>
  );
}

const csvOptions: GridCsvExportOptions = {
  fileName: "Companion Session ID Logs In CSV", delimiter: ",", utf8WithBom: true
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer style={{ height: "2rem" }}>
    <span style={{ position: "absolute", right: "0rem", fontSize: "1rem", paddingRight: '1rem' }}>
      <GridToolbarColumnsButton />
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);

interface CalibrationRecyclebinPageI { }

const CalibrationRecyclebinPage: React.FC<CalibrationRecyclebinPageI> = (props) => {
  const history = useHistory();
  const { width } = useWindowDimensions();
  const [dataLoader, setDataLoader] = useState<boolean>(true);
  const [dataCalibrationRecyclebin, setDataCalibrationRecyclebin] = useState<any>();
  const [restoreDataCall, setRestoreDataCall] = useState<boolean>(false)
  const { url } = useRouteMatch();

  useEffect(() => {
    setDataLoader(true);
    API.get("xcpep/instrument_calibration/", { get_recycle_bin_data: true }, 0).then((res: any) => {
      setDataCalibrationRecyclebin(res?.data);
      setDataLoader(false);
    })
  }, [restoreDataCall]);


  const viewFile = (file: string) => {
    window.open(file)
  };

  const handleRestoreCalibration = (e: any, id: any) => {
    e.stopPropagation();
    swal({
      title: `Restore Calibration`,
      text: `Are you sure?`,
      icon: "warning",
      //@ts-ignore
      buttons: {
        cancel: "Cancel",
        permanent: {
          text: `Restore Calibration`,
          value: `restoreCalibration`,
        },
      },
      closeOnClickOutside: false,
      closeOnEsc: false,
      className: "warning-swal",
    }).then(async (type: string) => {
      switch (type) {
        case "restoreCalibration":
          API.put(`xcpep/instrument_calibration/${id}/`, { restore_delete: true }, 0).then((res: AxiosResponse) => {
            setRestoreDataCall(!restoreDataCall)
          })
            .catch((err: any) => {
              console.log(err, "Unable to upload file. Server Error");
            });
          break;
        default:
          swal(`Data saved from restoring`, { icon: "error" });
      }
    });
  }

  // columns is a variable where column data is stored. This columns is then used as a prop in dataGrid.

  let columns: any = [
    { field: "sno", headerName: "SNo.", width: width > 1370 ? 50 : width < 900 ? 50 : 50, },
    { field: "instrumentDescription", headerName: "Instrument Description", minWidth: width > 1370 ? 300 : 200, flex: 1 },
    {
      field: "dateOfPurchase",
      headerName: "Date Of Purchase",
      minWidth: width > 1370 ? 120 : 80,
      flex: 1,
    },
    {
      field: "lifeInYears",
      headerName: "Life In Years",
      minWidth: width > 1370 ? 110 : 80,
      flex: 1,
    },
    { field: "calibrationRequired", headerName: "Calibration Required", minWidth: width > 1370 ? 150 : 120, flex: 1 },
    { field: "lastCalibrationDate", headerName: "Last Calibration Date", minWidth: width > 1370 ? 150 : 120, flex: 1 },
    { field: "calibrationExpiryDate", headerName: "Calibration Expiry Date", minWidth: width > 1370 ? 150 : width < 1200 ? 140 : 120, flex: 1 },
    {
      field: "calibrationReport", headerName: "Calibration Report", width: width > 1370 ? 130 : width < 1200 ? 130 : 120, renderCell: (cellValues: any) => {
        return (<>
          <VisibilityIcon
            onClick={() => viewFile(cellValues?.row?.calibrationReport)}
            titleAccess="view calibration report"
            sx={{ cursor: 'pointer' }} />
        </>)
      }
    },
    { field: "status", headerName: "Status", width: width > 1370 ? 90 : width < 1200 ? 90 : 70, },
    { field: "ownership", headerName: "Ownership", width: width > 1370 ? 100 : width < 1200 ? 100 : 90, },
    {
      field: "actions",
      headerName: "Actions",
      width: width > 1370 ? 105 : width < 1200 ? 80 : 100,
      renderCell: (cellValues: any) => {
        return (<>
          <RestoreIcon
            onClick={(e: any) => handleRestoreCalibration(e, cellValues?.row?.id)}
            sx={{ cursor: 'pointer' }}
          />

        </>)
      }
    },
  ];

  // In rows which is a array, we are pushing data which is structured in frontEnd so, that we can pass it to dataGrid

  let rows: any = [];

  let dataGridDataGenerator: any =
    dataCalibrationRecyclebin &&
    dataCalibrationRecyclebin?.map((ideaItem: any, index: any) => {
      let objShared: any = {
        id: ideaItem?.id,
        sno: index + 1,
        instrumentDescription: ideaItem?.instrument_description,
        dateOfPurchase: ideaItem?.date_of_purchase,
        lifeInYears: ideaItem?.instrument_life,
        calibrationRequired: ideaItem?.calibration_required == true ? 'YES' : 'NO',
        lastCalibrationDate: ideaItem?.last_calibration_date,
        calibrationExpiryDate: ideaItem?.calibration_expiry_date,
        calibrationReport: ideaItem?.calibration_report_link,
        status: ideaItem?.instrument_status,
        ownership: ideaItem?.ownership,
        created_by_fullname: ideaItem?.created_by_fullname,
        created_on: moment(ideaItem?.created_on).format("YYYY-MM-DD")
      };
      rows.push(objShared);
    });

  // dataStructureDataGrid is the total data format which is ready to pass to the dataGrid Props

  let dataStructureDataGrid: any = {
    columns: columns,
    rows: rows,
  };

  return (
    <div className={styles.container}>
      <Box sx={{ display: "flex", alignItems: "center", columnGap: '1rem', margin: '0 0.5rem' }}>
        <IconButton
          sx={{ cursor: "pointer" }}
          title="Go Back"
          onClick={() => history.push(`/calibration`)}
        >
          <ArrowBackIcon color="primary" titleAccess="Go Back" />
        </IconButton>
        <Typography sx={{ fontSize: "1rem" }}>
          Calibration Recyclebin
        </Typography>
      </Box>
      <Box
        sx={{
          height: { sm: '90vh', md: '89vh', lg: '88vh', xl: '91vh' },
          width: "100%",
          padding: '0 1rem',
        }}>
        <DataGrid
          headerHeight={48}
          rowHeight={48}
          {...dataStructureDataGrid}
          loading={dataLoader}
          disableSelectionOnClick={true}
          components={{
            Toolbar: () => {
              return CustomToolbar();
            },
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          sx={{
            "& .MuiDataGrid-virtualScroller-thumb": {
              backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
              borderRadius: "10px",
            },
            "& .MuiDataGrid-virtualScroller-thumb: hover ": {
              height: '3px',
              backgroundColor: "#045DE9 !important",
            },
            ".MuiDataGrid-virtualScroller-track": {
              backgroundColor: "#e1e1f3 !important",
            },
            "&.MuiDataGrid-root": {
              border: 'none',
            },
            "&.MuiDataGrid-root .MuiDataGrid-cell": {
              borderBottomColor: "primary.light",
            },

          }}
          density={"compact"}
          pageSize={100}
          rowsPerPageOptions={[100]}
        />
      </Box>
    </div>
  );
};

export default CalibrationRecyclebinPage;
