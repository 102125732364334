import { useState } from "react";

// ----------------------------------------------------------------------------------

const useCalibrationEditModal = () => {
    const [isOpen, setIsOpen] = useState( false );
    const [id,setId] = useState<any>();
    const [instrumentDescription,setInstrumentDescription] = useState<any>();
    const [dateOfPurchase,setDateOfPurchase] = useState<any>();
    const [lifeInYrs,setLifeInYrs] = useState<any>();
    const [calibrationRequired,setCalibrationRequired] = useState<any>();
    const [lastCalibrationDate,setLastCalibrationDate] = useState<any>();
    const [calibrationExpiryDate,setCalibrationExpiryDate] = useState<any>();
    const [calibrationReport,setCalibrationReport] = useState<any>();
    const [status,setStatus] = useState<any>();
    const [ownership,setOwnership] = useState<any>();
    const [quantity, setQuantity] = useState<any>();
    const [calibrationImage, setCalibrationImage] = useState<any>();

    const handleOpen = (id:any,instrument_any_description:any,date_of_purchase:any,instrument_life:any,calibration_required:any,last_calibration_date:any,calibration_expiry_date:any,calibration_report:any,instrument_status:any,ownership:any,quantityGet:any, calibrationImageGet:any) => {
        setIsOpen( true );
        setId(id);
        setInstrumentDescription(instrument_any_description);
        setDateOfPurchase(date_of_purchase);
        setLifeInYrs(instrument_life);
        setCalibrationRequired(calibration_required);
        setLastCalibrationDate(last_calibration_date);
        setCalibrationExpiryDate(calibration_expiry_date);
        setCalibrationReport(calibration_report);
        setStatus(instrument_status);
        setOwnership(ownership);
        setQuantity(quantityGet);
        setCalibrationImage(calibrationImageGet);
    }
    const handleClose = () => {
        setIsOpen( false );
        setId(undefined);
        setInstrumentDescription(undefined);
        setDateOfPurchase(undefined);
        setLifeInYrs(undefined);
        setCalibrationRequired(undefined);
        setLastCalibrationDate(undefined);
        setCalibrationExpiryDate(undefined);
        setCalibrationReport(undefined);
        setStatus(undefined);
        setOwnership(undefined);
        setQuantity(undefined);
        setCalibrationImage(undefined);
    }

    return Object.freeze( {
        open: handleOpen,
        close: handleClose,
        isOpen,
        id,
        instrumentDescription,
        dateOfPurchase,
        lifeInYrs,
        calibrationRequired,
        lastCalibrationDate,
        calibrationExpiryDate,
        calibrationReport,
        status,
        ownership,
        quantity,
        calibrationImage
    } );
};


// ----------------------------------------------------------------------------------

export { useCalibrationEditModal };