import {
  Box,
  Button,
  Divider,
  Modal,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import SaveIcon from "@mui/icons-material/Save";
import { API } from "../../api-services";
import { LoadingButton } from "@mui/lab";
import ErrorModalFilesMail from "../../ComponentUI/ProjectMailModule/ErrorModalFilesMail";
import { useBaseModal } from "../../ComponentUI/SearchPage/useBaseModal";

import sucessIcon from "../../Assets/images/success.png";

interface AddSystemInformationModalProps {
  isOpen: any;
  onCloseHanlder: any;
  setCounter: any;
  modalType: any;
  SubsystemData?: any;

}
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: '25vw',
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

const AddSubsystemNameModal = (props: AddSystemInformationModalProps) => {
  const { categoryId, subsystemId, search } = useParams<any>();
  const { isOpen, onCloseHanlder, setCounter, modalType, SubsystemData } = props;
  const ErrorModalFiles = useBaseModal();
  const [saveLoader, setsaveLoader] = useState<any>(false);
  const [Name, setName] = useState<any>("");
  const [SucessLoader, setSucessLoader] = useState<any>(false);
  const [ErrorModalFileMsg, setErrorModalFileMsg] = useState<any>(false);
  const [Description, setDescription] = useState<any>("");
  const [Abbrivations, setAbbrivations] = useState<any>("");
  const [NameError, setNameError] = useState<any>(false);
  const [AbbrivationsError, setAbbrivationsError] = useState<any>(false);

  const handleChange = (e: any, type: any) => {
    if (type == "Name") {
      setNameError(false);
      setName(e?.target?.value?.toUpperCase());
    } if (type == "Abbrivations") {
      setAbbrivationsError(false);
      setAbbrivations(e?.target?.value?.toUpperCase());
    } if (type == "Description") {
      setDescription(e?.target?.value);
    }
  };


  useEffect(() => {
    if (modalType == "Edit") {
      setAbbrivations(SubsystemData && SubsystemData?.abbreviation)
      setName(SubsystemData && SubsystemData?.name)
      setDescription(SubsystemData && SubsystemData?.description)

    } else {
      setAbbrivations("")
      setName("")
      setDescription("")
    }

  }, [SubsystemData, isOpen])
  const Close = () => {
    setAbbrivations("")
    setName("")
    setDescription("")
    onCloseHanlder()
  }

  useEffect(() => {
    setSucessLoader(false);
  }, [isOpen]);



  console.log(SucessLoader,"Cscvscvschvuy")
  const SaveHandler = () => {
    var testdata = /\S+/.test(Name);

    var testAbbr = /\S+/.test(Abbrivations);


    if (testdata == true && testAbbr == true) {

      if (modalType == "Edit") {
        setsaveLoader(true);
        API.put(`/api/db/sub_system/${subsystemId}/`, {

          name: Name,
          abbreviation: Abbrivations,
          description: Description == null ? "" : Description,
          vehicle_types: parseInt(categoryId),
        })
          .then((res: any) => {
            setSucessLoader(true);
            setsaveLoader(false);
            setCounter((prev: any) => prev + 1);
          })
          .catch((err: any) => {
            const { data } = err?.response;
            setErrorModalFileMsg(data[0]);
            ErrorModalFiles.open()
            setsaveLoader(false);
          });


      }
       else {


        setsaveLoader(true);
        API.post("/api/db/sub_system/", {
          id: 0,
          name: Name?.trim(),
          abbreviation: Abbrivations?.trim(),
          description: Description == null ? "" : Description?.trim(),
          vehicle_types: parseInt(categoryId),
        })
          .then((res: any) => {
            setSucessLoader(true);
            setsaveLoader(false);
            setCounter((prev: any) => prev + 1);
          })
          .catch((err: any) => {
            const { data } = err?.response;
            setErrorModalFileMsg(data[0]);
            ErrorModalFiles.open()
            setsaveLoader(false);
          });
      }
    }
    if (testdata == false) {
      setNameError(true);
    }

    if (testAbbr == false) {
      setAbbrivationsError(true);
    }
  };
  return (
    <div>

      {isOpen &&
        <Modal open={isOpen}>
          <>
             <ErrorModalFilesMail
              isOpen={ErrorModalFiles.isOpen}
              onCloseModal={ErrorModalFiles.close}
              ErrorMsg={ErrorModalFileMsg}
            />
          <Box sx={style}>
         
            <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
              {modalType == "Edit" ?
                <Typography
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    width: "100%",
                    textAlign: "center",
                    marginRight: "-2rem",
                  }}>
                  Edit System Name
                </Typography>
                :
                <Typography
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    width: "100%",
                    textAlign: "center",
                    marginRight: "-2rem",
                  }}>
                  Add System Name
                </Typography>

              }
              <CancelIcon
                titleAccess="Close"
                sx={{ cursor: "pointer", margin: "0.5rem" }}
                onClick={onCloseHanlder}
              />
            </Box>
            <Divider
              sx={{
                borderColor: "primary.light",
                margin: "0 1rem",
                marginBottom: "1rem",
              }}
            />
            {SucessLoader ? (
              <Box sx={{ textAlign: "center" }}>
                <img src={sucessIcon} style={{ width: "7rem" }} />
                {
                  modalType == "Edit" ?

                    <Typography sx={{ fontSize: '1rem', color: "green", marginTop: "1rem" }}>
                      {Name} ({Abbrivations})   System Updated Successfully
                    </Typography>
                    :

                    <Typography sx={{ fontSize: '1rem', color: "green", marginTop: "1rem" }}>
                      System Added Successfully
                    </Typography>}
                <Button
                  sx={{ marginTop: "1rem" }}
                  variant="contained"
                  size="small"
                  onClick={Close}>
                  ok
                </Button>
              </Box>
            ) : (
              <Box sx={{ padding: '0 0.5rem', }}>
                <TextField
                  type="text"
                  value={Name || ""}
                  error={NameError}
                  variant="standard"
                  onChange={(e: any) => handleChange(e, "Name")}
                  placeholder="System Name"
                  sx={{
                    width: '100%',
                    ".MuiInputBase-root.MuiInput-root": {
                      fontSize: '1rem !important'
                    },
                    '& .MuiInput-underline:before': { borderBottomColor: 'primary.main' },
                    '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                  }}
                />
                <TextField
                  type="text"
                  disabled={SubsystemData && SubsystemData?.mapped_status==true?true:false}
                  error={AbbrivationsError}
                  value={Abbrivations || ""}
                  variant="standard"
                  onChange={(e: any) => handleChange(e, "Abbrivations")}
                  placeholder="Abbreviation"
                  sx={{
                    width: '100%',
                    marginTop: '0.5rem',
                    ".MuiInputBase-root.MuiInput-root": {
                      fontSize: '1rem !important'
                    },
                    '& .MuiInput-underline:before': { borderBottomColor: 'primary.main' },
                    '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                  }}
                />
                <TextField
                  type="text"
                  value={Description || ""}
                  variant="standard"
                  onChange={(e: any) => handleChange(e, "Description")}
                  placeholder="Description"
                  sx={{
                    width: '100%',
                    marginTop: '0.5rem',
                    ".MuiInputBase-root.MuiInput-root": {
                      fontSize: '1rem !important'
                    },
                    '& .MuiInput-underline:before': { borderBottomColor: 'primary.main' },
                    '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                  }}
                />
                <Box sx={{ width: '100%', textAlign: 'right', marginTop: '0.5rem' }}>
                  <LoadingButton variant='contained'
                    onClick={() => SaveHandler()}
                    sx={{
                      cursor: "pointer", minWidth: '1rem', padding: '0.2rem 0.5rem', marginLeft: '0.5rem',
                       '.MuiCircularProgress-root': {
                        color: 'primary.main',
                      },
                      "&:hover": {
                        transform: 'Scale(1.05)',
                        transition: 'transform 0.5s ease',
                      }
                    }} loading={saveLoader}>
                    save
                    {/* <SaveIcon
                      
                      sx={{  margin: "0.5rem" }}
                    /> */}
                  </LoadingButton>
                </Box>
              </Box>
            )}
          </Box>
          </>
        </Modal>}
    </div>
  );
};
export default AddSubsystemNameModal;
