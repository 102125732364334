import { Box, ButtonProps, Dialog, Divider, Modal, Skeleton, Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import React, { useEffect, useState } from "react";

import InfoIcon from '@mui/icons-material/Info';
import {
    DataGrid,
    GridCsvExportMenuItem,
    GridCsvExportOptions,
    GridToolbarColumnsButton,
    GridToolbarContainer,
    GridToolbarExportContainer,
} from "@mui/x-data-grid";
import LoadingButton from "@mui/lab/LoadingButton";

import RViewerJS from "viewerjs-react";
import useWindowDimensions from "../../ui-reusable-component/useWindowDimensions";
import { API } from "../../api-services";
import { get } from "lodash";

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",

    width: {lg:'90vw',xl:"85vw"},
    // height: '80vh',
    bgcolor: "background.paper",
    borderRadius: "10px",
    p: 1,
};

interface SheetViewModal {
    onCloseModal: any;
    isOpen: any;
    getData: any;
    status_filter: any;
    category_filter: any;
    general_filter: any;
    calculator_type: any;
}
const csvOptions: GridCsvExportOptions = {
    fileName: `Sheet View Table`,
    delimiter: ",",
    utf8WithBom: true,
};

const CustomExportButton = (props: ButtonProps) => (
    <GridToolbarExportContainer {...props}>
        <GridCsvExportMenuItem options={csvOptions} />
    </GridToolbarExportContainer>
);

const CustomToolbar = () => (

    <GridToolbarContainer style={{ height: "2rem" }}>
        <span style={{ position: "absolute", right: "0rem", fontSize: "1rem", paddingRight: '1rem' }}>

            <GridToolbarColumnsButton />
            <CustomExportButton />
        </span>
    </GridToolbarContainer>
);
export default function SheetInfoModal(props: SheetViewModal) {
    const { onCloseModal, isOpen, getData, status_filter, category_filter, general_filter, calculator_type } = props;
    const [SheetData, setSheetData] = useState<any>([]);
    const [loader, setloader] = useState<any>(true);
    const { height, width } = useWindowDimensions();
    const [CostingTableData, setCostingTableData] = useState<any>([])

    useEffect(() => {
        setloader(true);

        API.get(
            "/xcpep/cost_part_table/",
            {
                sheet_view: true,
                parent: getData?.id,
                get_children: true,
                status_filter: status_filter,
                category_filter: category_filter,
                general_filter: general_filter,
                calculator_type: calculator_type,
            },
            0
        ).then((res: any) => {
            setCostingTableData(res?.data);
            setloader(false);

        }).catch((err: any) => {
            setloader(false);
        })
      
    }, [getData]);


   



    let Colums:any=[
        { field: "id", headerName: "id", hide: true, width: width > 1370 ? 50 : width < 900 ? 50 : 50, },
        {
            field: "part_no",
            headerName: "Part No.",
            // headerAlign: "center",
            // align: 'right',
            minWidth: width > 1370 ? 100 : width < 900 ? 120 : 140,
            flex: 1,
        },
        { field: "name", headerName: "Part Name", minWidth: width > 1370 ? 300 : 220, flex: 1 },
        {
            field: "category",
            headerName: "Category",
            minWidth: width > 1370 ? 80 : 40,
            //   flex: 1,
        },
        {
            field: "weight",
            headerName: "Net Wt.(g)",
            minWidth: width > 1370 ? 80 : 40,
            headerAlign: "right",
            align: 'right',
            //   flex: 1,
        },
        {
            field: "cost_status",
            headerName: "Status",
            minWidth: width > 1370 ? 80 : 40,
            headerAlign: "right",
            align: 'right',
            // renderCell:(cells:any)=>{
            //     return (<>
                
            //     {cells?.row?.cost_status==0?<span>Not started</span>:cells?.row?.cost_status==1?<span>Inprogress</span>:<span>Validated</span>}
            //     </>)
            // }
            //   flex: 1,
        },
        {
            field: "cost_per_kg",
            headerName: "Cost/Kg",
            minWidth: width > 1370 ? 80 : 40,
            headerAlign: "right",
            align: 'right',
            //   flex: 1,
        },
       
        {
            field: "quantity",
            headerName: "Qty",
            minWidth: width > 1370 ? 80 : 40,
            headerAlign: "right",
            align: 'right',
            //   flex: 1,
        },
        
        {
            field: "per_unit_cost",
            headerName: "Per Unit Cost	",
            minWidth: width > 1370 ? 80 : 40,
            headerAlign: "right",
            align: 'right',
            //   flex: 1,
        },
        {
            field: "material_cost",
            headerName: "MC",
            minWidth: width > 1370 ? 80 : 40,
            headerAlign: "right",
            align: 'right',
            //   flex: 1,
        },
        {
            field: "process_cost",
            headerName: "PC",
            minWidth: width > 1370 ? 80 : 40,
            headerAlign: "right",
            align: 'right',
            //   flex: 1,
        },
        {
            field: "overhead_cost",
            headerName: "OC",
            minWidth: width > 1370 ? 80 : 40,
            headerAlign: "right",
            align: 'right',
            //   flex: 1,
        },
        {
            field: "child_part_cost",
            headerName: "CPC",
            minWidth: width > 1370 ? 80 : 40,
            headerAlign: "right",
            align: 'right',
            //   flex: 1,
        },
        {
            field: "total_cost",
            headerName: "Total Cost",            
            minWidth: width > 1370 ? 80 : 40,
            headerAlign: "right",
            align: 'right',
            //   flex: 1,
        },

    ]


    let rows: any = [];

    let dataGridDataGenerator: any =
        CostingTableData &&
        CostingTableData?.map((Item: any, index: any) => {
            let objShared: any = {
                id: Item?.id,
                sno: index + 1,
                part_no: Item?.part_no,
                name: Item?.name,
                category: Item?.category,
                weight: Item?.weight,
                cost_status: Item?.cost_status,
                cost_per_kg: Item?.vault_details?.cost_per_kg,
                quantity: Item?.quantity,

                child_part_cost: Item?.vault_details?.child_part_cost,
                 material_cost: Item?.vault_details?.material_cost,
                overhead_cost: Item?.vault_details?.overhead_cost,
                per_unit_cost
                    : Item?.vault_details?.per_unit_cost,

                process_cost
                    :
                    Item?.vault_details?.process_cost,
                total_cost
                    : Item?.vault_details?.total_cost,




            };
            rows.push(objShared);
        });
      var columsData = () => {
        if (CostingTableData?.length > 0) {
          return Object?.keys(CostingTableData[0]);
        }
      };

      const dataRender: any = {
        columns: Colums && Colums,
        rows: rows,
     };



    return (
        <Modal
            open={isOpen}
            onClose={onCloseModal}
            disableEscapeKeyDown={true}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>




                <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
                    <Typography
                        style={{
                            fontSize: "1rem",
                            fontWeight: "600",
                            width: "100%",
                            textAlign: "center",
                            marginRight: "-2rem",
                        }}
                    >
                        Sheet View Table({getData?.name})
                    </Typography>
                    <CancelIcon
                        titleAccess="Close"
                        sx={{ cursor: "pointer", margin: "0.5rem" }}
                        onClick={onCloseModal}
                    />
                </Box>
                <Divider
                    sx={{
                        borderColor: "primary.light",
                        margin: "0 1rem",
                        marginBottom: "1rem",
                    }}
                />
                <Box
                    sx={{ height: "75vh", width: "100%" }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3"
                >
                    {
                        loader ?
                            <><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton></>

                            : <>

                                 { (
            <DataGrid
              {...dataRender}
              headerHeight={48}
              rowHeight={36}
              loading={loader}
              density="compact"
              // autoHeight={true}
              components={{
                Toolbar: () => {
                  return CustomToolbar();
                },
              }}
              // hideFooter={true}
              // hideFooterPagination={true}
              sx={{
                "&.MuiDataGrid-root .MuiDataGrid-cell": {
                  borderBottomColor: "primary.light",
                },
                "& ::-webkit-scrollbar": {
                  width: "0.3rem",
                  height: "0.3rem",
                },
                "& ::-webkit-scrollbar-thumb": {
                  // background: $thumb-color;
                  backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                  borderRadius: "10px",
                },
                "& ::- webkit-scrollbar-thumb: hover ": {
                  // width: "4px",
                  backgroundColor: "#045DE9 !important",
                },
                "::-webkit-scrollbar-track": {
                  backgroundColor: "#e1e1f3 !important",
                },
              }}
            />
          )
          
          
          
          
          } 
                            </>

                    }
                </Box>
            </Box>
        </Modal>
    );
}
