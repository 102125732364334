import {
  Box,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  Typography,
} from "@mui/material";
import styles from "./CostingTableSection.module.scss";

export interface IMaterialRivisionProps {
  machineRevisionData?: any;
}

export function MaterialRivision(props: IMaterialRivisionProps) {
  const { machineRevisionData } = props;
  return (
    <Box sx={{marginBottom:'1rem'}}>
      <Table>
        <TableHead
          sx={{ backgroundColor: "primary.main", color: "common.white", }}
        >
          <TableRow >
            <TableCell colSpan={2} sx={{ color: "common.white", padding: "0.1rem 0.5rem", }}>
              Raw Material:
            </TableCell>
            {/* <TableCell
              sx={{ color: "common.white", padding: "0.2rem 0.5rem" }}
            ></TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {machineRevisionData && Object.keys(machineRevisionData)?.length > 0 ?
            Object.keys(machineRevisionData)?.map((item: any, index: any) => {
              return (
                index !== 0 && (
                  <TableRow>
                    <TableCell
                      sx={{
                        borderBottomColor: "primary.light",
                        padding: "0 0.5rem",
                        color:'#646464',                        
                      }}
                    >
                      {item}
                    </TableCell>
                    <TableCell
                      sx={{
                        borderBottomColor: "primary.light",
                        padding: "0 0.5rem",
                        width:'40%',
                        textAlign:'right',
                      }}
                    >
                      {machineRevisionData[item]}
                    </TableCell>
                  </TableRow>
                )
              );
            }):<Typography align="center" mt={2}>No Data available</Typography>}
        </TableBody>
      </Table>
    </Box>
  );
}
