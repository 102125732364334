import { createAsyncThunk } from "@reduxjs/toolkit";
import MomData from "../Services/Mom.service";
import { ID } from "../../utlis/commonInterfaces";

export interface getMomGroupI {
  project: number;
}

export const getMomGroup: any = createAsyncThunk(
  "Mom/getGroup",
  async (arg: createMomGroupI, { rejectWithValue }) => {
    try {
      const { data } = await MomData.get_groupName(arg.project);
      return {
        momGroup: data as any,
      };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export interface createMomGroupI {
  project: number;
  name: string;
  type: boolean;
  attendees: any;
}

export const createMomGroup = createAsyncThunk(
  "Mom/Grouping/create",
  async (arg: createMomGroupI, { rejectWithValue }) => {
    try {
      const { data } = await MomData.create_groupName(
        arg.project,
        arg.name,
        arg.type,
        arg.attendees
      );
      return {
        momGroup: data as any,
      };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export interface deleteMomGroupI {
  id: ID;
}

export const deleteMomGroup = createAsyncThunk(
  "Mom/Grouping/delete",
  async (arg: deleteMomGroupI, { rejectWithValue }) => {
    try {
      const { data } = await MomData.delete_groupName(arg.id);
      return {
        momGroup: data as any,
      };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

//------------------------------------------ change name ---------------------------------------------------------------

export interface updateMomGroupI {
  id: ID;
  name: string;
}

export const updateMomGroup = createAsyncThunk(
  "Mom/Grouping/update",
  async (arg: updateMomGroupI, { rejectWithValue }) => {
    try {
      const { data } = await MomData.update_groupName(arg.id, arg.name);
      return {
        Drivegroups: data as any,
      };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export interface updateMomGroupAttendeesI {
  id: any;
  type: boolean;
  attendees: any;
}

export const updateMomGroupAttendees = createAsyncThunk(
  "Mom/Grouping/update",
  async (arg: updateMomGroupAttendeesI, { rejectWithValue }) => {
    try {
      const { data } = await MomData.update_MomGroupAttendees(
        arg.id,
        arg.type,
        arg.attendees
      );
      return {
        Drivegroups: data as any,
      };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export interface getMomColumnI {
  project: number;
}

export const getMomColumn: any = createAsyncThunk(
  "Mom/Column/get",
  async (arg: getMomColumnI, { rejectWithValue }) => {
    try {
      const { data } = await MomData.get_Column(arg.project);
      return {
        MomDataSave: data as any,
      };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export interface createMomColumnI {
  project: number;
  group: ID;
  date: string;
  attendees: any;
}

export const createMomColumn = createAsyncThunk(
  "Mom/Column/create",
  async (arg: createMomColumnI, { rejectWithValue }) => {
    try {
      const { data } = await MomData.create_Column(
        arg.project,
        arg.group,
        arg.date,
        arg.attendees
      );
      return {
        MomDataSave: data as any,
      };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export interface updateMomDataI {
  id: ID;
  date: string | undefined;
  attendees: any;
}

export const updateMomData = createAsyncThunk(
  "Mom/Column/update",
  async (arg: updateMomDataI, { rejectWithValue }) => {
    try {
      const { data } = await MomData.update_Column(
        arg.id,
        arg.date,
        arg.attendees
      );
      return {
        MomDataSave: data as any,
      };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
export interface permissionMomDataI {
  project: any;
  action: any;
}
// export const getMOMPermission = createAsyncThunk(
//   "Mom/Permissions",
//   async (arg: permissionMomDataI, { rejectWithValue }) => {
//     try {
//       const { data } = await MomData.get_mom_permissions(
//         arg.project,
//         arg.action
//       );
//       return {
//         MOMPermissions: data as any,
//       };
//     } catch (e) {
//       return rejectWithValue(e);
//     }
//   }
// );
export const SET_MOM_PERMISSION = "SET_MOM_PERMISSION";

export const setMOMPermission =
  (project:any,action:any) => async (dispatch: any) => {
    try {
      const { data } = await MomData.get_mom_permissions(
       project,
       action
      );
      if (data) {
        dispatch({ type: "SET_MOM_PERMISSION", payload: data });
      }
    } catch (e) {}
  };

export interface deleteMomDriveI {
  id: ID;
}

export const deleteDataMom = createAsyncThunk(
  "Mom/Column/delete",
  async (arg: deleteMomDriveI, { rejectWithValue }) => {
    try {
      const { data } = await MomData.delete_Column(arg.id);
      return {
        MomDataSave: data as any,
      };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
export interface createMeetingColumnI {
  meeting: number;
  points?: string | undefined;
  responsible?: any;
  date?: string | undefined;
  status?: any;
  remark?: string | undefined;
}

export const createMeetingColumn = createAsyncThunk(
  "Mom/MeetingColumn/create",
  async (arg: createMeetingColumnI, { rejectWithValue }) => {
    try {
      const { data } = await MomData.create_MeetingColumn(
        arg.meeting,
        arg.points,
        arg.responsible,
        arg.date,
        arg.status,
        arg.remark
      );
      return {
        MomDetailSave: data as any,
      };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export interface getMomMeetingColumnI {
  meeting: number;
}

export const getMomMeetingColumn: any = createAsyncThunk(
  "Mom/MeetingColumn/get",
  async (arg: getMomMeetingColumnI, { rejectWithValue }) => {
    try {
      const { data } = await MomData.get_MeetingColumn(arg.meeting);
      return {
        MomDetailSave: data as any,
      };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export interface updateMomDetailDataI {
  id: ID;
  points?: string | undefined;
  responsible?: any;
  date?: string | undefined;
  status?: any;
  remark?: string | undefined;
  path?: string | undefined;
  name?: string | undefined;
}

export const updateMomDetailData = createAsyncThunk(
  "Mom/MeetingColumn/update",
  async (arg: updateMomDetailDataI, { rejectWithValue }) => {
    try {
      const { data } = await MomData.update_MeetingColumn(
        arg.id,
        arg.points,
        arg.responsible,
        arg.date,
        arg.status,
        arg.remark,
        arg.path,
        arg.name
      );
      return {
        MomDetailSave: data as any,
      };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export interface deleteDetailDataMomeI {
  id: ID;
}

export const deleteDetailDataMom = createAsyncThunk(
  "Arch/MeetingColumn/delete",
  async (arg: deleteDetailDataMomeI, { rejectWithValue }) => {
    try {
      const { data } = await MomData.delete_MeetingColumn(arg.id);
      return {
        MomDataSave: data as any,
      };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);

export interface momNotificationI {
  url: string;
  responsible?: string;
  project?: any;
  group?: any;
  meeting?: any;
}

export const momNotification = createAsyncThunk(
  "Mom/MeetingColumn/notification",
  async (arg: momNotificationI, { rejectWithValue }) => {
    try {
      const { data } = await MomData.notification(
        arg.url,
        arg?.responsible,
        arg?.project,
        arg?.group,
        arg?.meeting
      );
      return {
        data,
      };
    } catch (e) {
      return rejectWithValue(e);
    }
  }
);
