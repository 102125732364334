import * as React from "react";
import Table from "@mui/material/Table";
import {
  Box,
  FormControlLabel,
  FormGroup,
  Skeleton,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from "@mui/material";
import SearchBar from "../../ComponentUI/Header/SearchBar";
import CachedIcon from "@mui/icons-material/Cached";
import libraryIcon from "../../Assets/images/library.svg";
import { Checkbox, IconButton, Typography } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory, useRouteMatch } from "react-router-dom";
import styles from "../../Configuration/Costing/CostingConfigurationLanding.module.scss";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import AddIcon from "@mui/icons-material/Add";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import guidelines from "../../Assets/images/guidelines.svg";
import calculator from "../../Assets/images/calculator.svg";
import ShareIcon from "@mui/icons-material/Share";
import { useBaseModal } from "../../ComponentUI/SearchPage/useBaseModal";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import SaveIcon from "@mui/icons-material/Save";
import InfoIcon from "@mui/icons-material/Info";
import { API } from "../../api-services";
import { LoadingButton } from "@mui/lab";
import DeleteIcon from "@mui/icons-material/Delete";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import { deleteSwal } from "../../utlis/deleteSwal";
import ValidatedGuidelines from "../../Assets/images/guidelines_green.svg";

import Swal from "sweetalert2";
import successIcon from "../../Assets/images/success.png";
import { EditName } from "../../Configuration/Costing/EditName";
import { EditDescription } from "../../Configuration/Costing/EditDescription";
import LibraryInfoDetailsModal from "../../Configuration/Costing/LibraryInfoDetailsModal";
import DataBaseDrawer from "../DataBaseDrawer";
import { useState } from "react";
import { AUTH } from "../../Redux/Services/auth.service";
import LockIcon from '@mui/icons-material/Lock';
import LockOpenIcon from '@mui/icons-material/LockOpen';

type Anchor = "top" | "left" | "bottom" | "right";

interface ICommodityandProcessProps { }

function CommodityandProcess(props: ICommodityandProcessProps) {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { library, projectId, projectname, selectedIdTree, dataType } =
    useRouteParams<any>();
  const [selectedLibrary, setSelectedLibrary] = React.useState<any>(library);
  const [counterApiCall, setCounterApiCall] = React.useState<any>(0);
  const [processData, setProcessData] = React.useState<any>([]);
  const [processCreateCount, setProcessCreateCount] = React.useState<any>(0);
  const [commodityCreateCount, setCommodityCreateCount] =
    React.useState<any>(0);
  const [commodityData, setCommodityData] = React.useState<any>([]);
  const [AllHierarchy, setAllHierarchy] = React.useState<any>([]);
  const [isTreeLoading, setIsTreeLoading] = React.useState<any>(false);
  const [createName, setCreateName] = React.useState<any>("");
  const [createDes, setCreateDes] = React.useState<any>("");
  const [createNameProcess, setCreateNameProcess] = React.useState<any>("");
  const [createDesProcess, setCreateDesProcess] = React.useState<any>("");
  const [editName, setEditName] = React.useState<any>("");
  const [editDes, setEditDes] = React.useState<any>("");
  const [selectedTreeData, setSelectedTreeData] = React.useState<any>({});
  const [isLoading, setIsLoading] = React.useState<any>(false);
  const [isLoadingProcess, setIsLoadingProcess] = React.useState<any>(false);
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [open, setOpen] = React.useState(false);
  const shareModal = useBaseModal();
  const WatermrkModel = useBaseModal();
  const libraryInfoModal = useBaseParamsModal();
  const [useraction, setuseraction] = React.useState<any>(null)
  const AssumptionCardModal = useBaseModal();

  const [commodityCounter, setCommodityCounter] = useState<any>(0);
  const [processCounter, setProcessCounter] = useState<any>(0);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }

        setState({ ...state, [anchor]: open });
      };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
    // onClick={toggleDrawer(anchor, false)}
    // onKeyDown={toggleDrawer(anchor, false)}
    >
      <DataBaseDrawer />
    </Box>
  );

  const getTreeData = () => {
    setIsTreeLoading(true);
    API.get(
      "/config/costing_configuration/",
      {
        project: projectId,
        costing_config_hier: true,
      },
      0
    )
      .then((res: any) => {
        setAllHierarchy(res.data[0]?.data);
        setIsTreeLoading(false);
      })
      .catch((err: any) => {
        setIsTreeLoading(false);
      });
  };
  React.useEffect(() => {
    getTreeData();
  }, []);
  React.useEffect(() => {
    API.get(
      "/auth/calibration_user_permission/",
      {
        action: true,
        database: true,
      },
      0
    )
      .then((res: any) => {
        sessionStorage.setItem("DBPermission", res.data.action);
      })
      .catch((err: any) => { });
  }, []);

  const handleChangeName = React.useMemo(
    () => (e: any) => {
      const { value } = e.target;
      setCreateName(value);
    },
    []
  );

  const handleChangeNameProcess = React.useMemo(
    () => (e: any) => {
      const { value } = e.target;
      setCreateNameProcess(value);
    },
    []
  );

  const handleChangeDescription = React.useMemo(
    () => (e: any) => {
      const { value } = e.target;
      setCreateDes(value);
    },
    []
  );

  const handleChangeDescriptionProcess = React.useMemo(
    () => (e: any) => {
      const { value } = e.target;
      setCreateDesProcess(value);
    },
    []
  );

  const onSelect = (
    nodeId: any,
    id: any,
    HierarchyAllData: any,
    itemId: any
  ) => {
    if (HierarchyAllData.type === "calculator") {
      history.push(
        `/costingconfiguration/${projectId}/${projectname}/${itemId}/${HierarchyAllData.type}/`
      );
    } else {
      history.push(
        `/costingconfiguration/${projectId}/${projectname}/${itemId}/${dataType}/${library}`
      );
    }
  };
  React.useEffect(() => {
    API.get(
      "/auth/calibration_user_permission/",
      {
        action: true,
        database: true,
        module: "DB-Cost commodity and process"
      },
      0
    )
      .then((res: any) => {
        setuseraction(res?.data?.action)
        sessionStorage.setItem("DBPermission", res.data.action);
      })
      .catch((err: any) => { });
  }, []);


  const comapnionHandle = () => {
    API.get(
      "/web_companion/comapanion_activity_log",
      {
        //   get_recycle_bin_data_count: true
        project: projectId,
        viewed: true,
      },
      0
    )
      .then((res: any) => {
        // console.log(res?.data.count, "countdata")
      })
      .catch((err: any) => {
        // console.log("Server error")
        //   SetLoader(false)
      });
    history.push(`/companionConfig/${projectId}/${projectname}`);
  };

  const getCalcData = (data: any) => { };

  const costingModel = () => {
    history.push(`/costingconfiguration/${projectId}/${projectname}/0/0`);
  };

  const guideHandler = () => {
    history.push(
      `/costingconfiguration/${projectId}/${projectname}/guidelines`
    );
  };

  const assumptionHandler = () => {
    history.push(
      `/costingconfiguration/${projectId}/${projectname}/assumptioncard/0/1/true/true`
    );
  };

  const getProcessData = () => {
    setIsLoadingProcess(true);
    API.get(
      "/config/costing_configuration/",
      {
        get_library: "process_library",
      },
      0
    )
      .then((res: any) => {
        setProcessData(res.data);
        setIsLoadingProcess(false);
      })
      .catch((err: any) => {
        setIsLoadingProcess(false);
      });
  };
  const [otherValidation, setOtherValidation] = React.useState<any>({});
  const getAllResult = () => {
    API.get(
      "/config/share_costing_config/",
      {
        project: projectId,
        costing_config: true,
      },
      0
    )
      .then((res: any) => {
        setOtherValidation(res.data[0]);
        sessionStorage.setItem("otherValidation", JSON.stringify(res.data[0]));
      })
      .catch((err: any) => { });
  };
  React.useEffect(() => {
    getAllResult();
  }, []);
  const getCommodityData = () => {
    setIsLoading(true);
    API.get(
      "/config/costing_configuration/",
      {
        get_library: "commodity_library",
      },
      0
    )
      .then((res: any) => {
        setCommodityData(res.data);
        setIsLoading(false);
      })
      .catch((err: any) => {
        setIsLoading(false);
      });
  };
  const handleCreate = async () => {
    const response = await API.post(
      "/config/costing_configuration/",
      {
        add_data: "commodity_library",
        name: createName,
        description: createDes,
      },
      {},
      0
    );
    if (response.data.error) {
    } else {
      setCreateName("");
      setCreateDes("");
      Swal.fire({
        html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
        <br />
        <p style="color:#007fff;">Commodity Created Successfully!</p>   
         </div>`,
      });

      getCommodityData();
      getTreeData();
    }
  };
  const handleCreateProcess = async () => {
    const response = await API.post(
      "/config/costing_configuration/",
      {
        add_data: "process_library",
        name: createNameProcess,
        description: createDesProcess,
      },
      {},
      0
    );

    if (response.data.error) {
    } else {
      setCreateNameProcess("");
      setCreateDesProcess("");
      Swal.fire({
        html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
        <br />
        <p style="color:#007fff;">Process Created Successfully!</p>   
         </div>`,
      });
      getProcessData();
      getTreeData();
    }
  };

  React.useEffect(() => {
    getProcessData();
    getCommodityData();
  }, []);

  const lockCostModelHandler = (item: any, type: any) => {
    Swal.fire({
      title: item?.lock == true ? "Unlock" : "Lock",
      text:
        item?.lock == true
          ? `Are you sure you want to Unlock this  ${type}?`
          : `Are you sure you want to lock this ${type}?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result: any) => {
      if (result.isConfirmed) {
        API.put(
          `/config/costing_configuration/${item.id}/`, {
          update_library: type === "commodity" ? "commodity_library" : "process_library",
          lock: item?.lock == true ? false : true,
        }, 0).then((res: any) => {
          if (type === "commodity") {
            setCommodityCounter((prev: any) => prev + 1);
          } else {
            setProcessCounter((prev: any) => prev + 1);
          }

        })
          .catch((err: any) => {
            console.log("Server Error");
            // setLoader(false);
          });
      }
    });
  };

  React.useEffect(() => {
    if (commodityCounter > 0) {
      getCommodityData();
    }
  }, [commodityCounter]);

  React.useEffect(() => {
    if (processCounter > 0) {
      getProcessData();
    }
  }, [processCounter]);


  return (
    <div>
      <Box sx={{ margin: "0 0.5rem" }}>
        <Box sx={{ margin: "0 0.5rem" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <Box
              sx={{ display: "flex", alignItems: "center", columnGap: "1rem" }}
            >
              <IconButton
                sx={{ cursor: "pointer" }}
                title="Go Back"
                onClick={() => history.goBack()}
              >
                <ArrowBackIcon color="primary" titleAccess="Go Back" />
              </IconButton>
              <Typography
                sx={{
                  fontSize: "1rem",
                  whiteSpace: "nowrap",
                }}
              >
                Database (Costing DB-Commodity & Process Library)
              </Typography>
            </Box>
            <Box sx={{ display: "flex", alignItems: "center", columnGap: "1.5rem", paddingRight: '1rem' }}>

            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "flex-start" }}>
        <Box className={styles.expandIconBox}>
          <div className={styles.iconSec}>
            {(["left"] as const).map((anchor) => (
              <React.Fragment key={anchor}>
                <IconButton
                  size="medium"
                  sx={{
                    backgroundColor: "primary.light",
                    color: "primary.main",
                  }}
                  onClick={toggleDrawer(anchor, true)}
                >
                  <AddIcon sx={{ fontSize: "1.7rem" }} />
                </IconButton>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  className={styles.drawerContainer}
                  PaperProps={{
                    sx: {
                      backgroundColor: "aliceblue",
                    },
                  }}
                >
                  <Box
                    sx={{ padding: "1rem 0rem", paddingBottom: "0" }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3"
                  >
                    <Typography
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "1rem",
                      }}
                    >
                      Commodity and Process
                    </Typography>
                    <Divider sx={{ margin: "0.5rem" }} />
                    {list(anchor)}
                  </Box>
                </Drawer>
                <Typography
                  style={{
                    transform: "rotate(270deg)",
                    whiteSpace: "nowrap",
                    marginTop: "40vh",
                    cursor: "pointer",
                    fontSize: "1rem",
                  }}
                  onClick={toggleDrawer(anchor, true)}
                >
                  Commodity and Process
                </Typography>
              </React.Fragment>
            ))}
          </div>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: "1fr 1fr",
                columnGap: "1rem",
                padding: "0  0.5rem",
              }}
            >
              <Box
                sx={{
                  height: { lg: "86.5vh", xl: "89vh" },
                  padding: "0 0.5rem",
                }}
                adl-scrollbar="true"
                adl-scrollbar-width="0.3"
              >
                <Table sx={{ padding: "0 0.5rem" }}>
                  <TableHead
                    sx={{
                      backgroundColor: "primary.main",
                      color: "white",
                      position: "sticky",
                      top: "0",
                      zIndex: 1,
                    }}
                  >
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          color: "primary.main",
                          backgroundColor: "primary.light",
                          textAlign: "center",
                        }}
                        colSpan={4}
                      >
                        Commodity
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          color: "white",
                          borderBottomColor: "primary.light",
                          width: "5vw",
                        }}
                      >
                        S.No
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          color: "white",
                          borderBottomColor: "primary.light",
                          width: "15vw",
                        }}
                      >
                        Name
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          color: "white",
                          borderBottomColor: "primary.light",
                          width: "15vw",
                        }}
                      >
                        Description
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          color: "white",
                          borderBottomColor: "primary.light",
                          width: "5vw",
                        }}
                      >
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {useraction && useraction?.includes("C") && <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      ></TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        <TextField
                          placeholder="Name"
                          variant="standard"
                          size="small"
                          value={createName}
                          onChange={handleChangeName}
                          InputProps={{
                            style: { fontSize: "1rem" },
                          }}
                          sx={{
                            width: "100%",
                            ".MuiInputBase-root.MuiInput-root": {
                              fontSize: "1rem !important",
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "primary.main",
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "primary.main",
                            },
                          }}
                        />
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        <TextField
                          placeholder="Description"
                          variant="standard"
                          size="small"
                          value={createDes}
                          onChange={handleChangeDescription}
                          InputProps={{
                            style: { fontSize: "1rem" },
                          }}
                          sx={{
                            width: "100%",
                            ".MuiInputBase-root.MuiInput-root": {
                              fontSize: "1rem !important",
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "primary.main",
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "primary.main",
                            },
                          }}
                        />
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {useraction && useraction?.includes("C") && <LoadingButton
                          disabled={
                            createDes?.length > 0 && createName?.length > 0
                              ? false
                              : true
                          }
                          onClick={() => handleCreate()}
                        >
                          <SaveIcon sx={{ cursor: "pointer" }} />
                        </LoadingButton>}

                        {/* <OpenInNewIcon /> */}
                        {/* <InfoIcon /> */}
                      </TableCell>
                    </TableRow>}
                    {commodityData && !isLoading ? (
                      commodityData?.map((item: any, index: any) => {
                        return (
                          <TableRow key={index + 1}>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <EditName
                                item={item && item}
                                useraction={useraction}
                                editName={editName}
                                setEditName={setEditName}
                                key={index}
                                type={"commodity"}
                              />
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <EditDescription
                                item={item && item}
                                useraction={useraction}
                                setEditDes={setEditDes}
                                editDes={editDes}
                                key={index}
                                type={"commodity"}
                              />
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              {item?.lock === true ? (
                                // Case 1: If user is SuperUser or ProjectAdmin, show clickable green lock
                                (AUTH.isSuperUser || AUTH.isProjectAdmin) ? (
                                  <LockIcon
                                    sx={{ cursor: 'pointer', color: 'green' }}
                                    onClick={() => lockCostModelHandler(item, "commodity")}
                                  />
                                ) : (
                                  // Case 2: If user is not SuperUser or ProjectAdmin, show disabled lock
                                  <LockIcon sx={{ opacity: 0.5 }} />
                                )
                              ) : (
                                // Case 3: If lock is false and user is SuperUser or ProjectAdmin, show red open lock
                                (AUTH.isSuperUser || AUTH.isProjectAdmin) && (
                                  <LockOpenIcon
                                    sx={{ cursor: 'pointer', color: 'red' }}
                                    onClick={() => lockCostModelHandler(item, "commodity")}
                                  />
                                )
                              )}

                              {item?.map_status && (
                                <InfoIcon
                                  sx={{
                                    cursor: "pointer",
                                    marginLeft: "1.5rem",
                                  }}
                                  onClick={() =>
                                    libraryInfoModal.open({
                                      key: "process",
                                      value: item.id,
                                      name: item.name,
                                    })
                                  }
                                />
                              )}{
                                useraction && useraction?.includes("D") && (
                                  !item?.map_status && (
                                    <DeleteIcon
                                      sx={{
                                        cursor: "pointer",
                                        marginLeft: "1.5rem",
                                        opacity: item?.lock == true ? 0.5 : 1,
                                      }}
                                      onClick={async () => {
                                        if (item?.lock != true) {
                                          const confirm: any = await deleteSwal(
                                            `Delete ${item.name}`
                                          );
                                          if (confirm) {
                                            API.delete(`api/db/commodity/${item.id}/`)
                                              .then((res: any) => {
                                                getCommodityData();
                                              })
                                              .catch((err: any) => { });
                                          }
                                        }
                                      }}
                                    />
                                  )

                                )
                              }



                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  </TableBody>
                </Table>
              </Box>
              <Box
                sx={{
                  height: { lg: "86.5vh", xl: "89vh" },
                  padding: "0 0.5rem",
                }}
                adl-scrollbar="true"
                adl-scrollbar-width="0.3"
              >
                <Table sx={{ padding: "0 0.5rem" }}>
                  <TableHead
                    sx={{
                      backgroundColor: "primary.main",
                      color: "white",
                      position: "sticky",
                      top: "0",
                      zIndex: 1,
                    }}
                  >
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          color: "primary.main",
                          backgroundColor: "primary.light",
                          textAlign: "center",
                        }}
                        colSpan={4}
                      >
                        Process
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          color: "white",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        S.No
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          color: "white",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Name
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          color: "white",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Description
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          color: "white",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        Actions
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {useraction && useraction?.includes("C") && <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      ></TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        <TextField
                          placeholder="Name"
                          variant="standard"
                          size="small"
                          value={createNameProcess}
                          onChange={handleChangeNameProcess}
                          InputProps={{
                            style: { fontSize: "1rem" },
                          }}
                          sx={{
                            ".MuiInputBase-root.MuiInput-root": {
                              fontSize: "1rem !important",
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "primary.main",
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "primary.main",
                            },
                          }}
                        />
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        <TextField
                          placeholder="Description"
                          variant="standard"
                          size="small"
                          value={createDesProcess}
                          onChange={handleChangeDescriptionProcess}
                          InputProps={{
                            style: { fontSize: "1rem" },
                          }}
                          sx={{
                            ".MuiInputBase-root.MuiInput-root": {
                              fontSize: "1rem !important",
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "primary.main",
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "primary.main",
                            },
                          }}
                        />
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {useraction && useraction?.includes("C") && <LoadingButton
                          disabled={
                            createDesProcess?.length > 0 &&
                              createNameProcess?.length > 0
                              ? false
                              : true
                          }
                          onClick={() => handleCreateProcess()}
                        >
                          <SaveIcon sx={{ cursor: "pointer" }} />
                        </LoadingButton>}

                        {/* <OpenInNewIcon /> */}
                        {/* <InfoIcon /> */}
                      </TableCell>
                    </TableRow>}
                    {processData && !isLoadingProcess ? (
                      processData?.map((item: any, index: any) => {
                        return (
                          <TableRow key={index + 1}>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <EditName
                                useraction={useraction}
                                item={item && item}
                                editName={editName}
                                setEditName={setEditName}
                                key={index}
                                type={"process"}
                              />
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              <EditDescription
                                item={item && item}
                                useraction={useraction}
                                setEditDes={setEditDes}
                                editDes={editDes}
                                key={index}
                                type={"process"}
                              />
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                              }}
                            >
                              {/* <SaveIcon /> */}
                              {/* <OpenInNewIcon /> */}
                              {item?.lock === true ? (
                                // Case 1: If user is SuperUser or ProjectAdmin, show clickable green lock
                                (AUTH.isSuperUser || AUTH.isProjectAdmin) ? (
                                  <LockIcon
                                    sx={{ cursor: 'pointer', color: 'green' }}
                                    onClick={() => lockCostModelHandler(item, "process")}
                                  />
                                ) : (
                                  // Case 2: If user is not SuperUser or ProjectAdmin, show disabled lock
                                  <LockIcon sx={{ opacity: 0.5 }} />
                                )
                              ) : (
                                // Case 3: If lock is false and user is SuperUser or ProjectAdmin, show red open lock
                                (AUTH.isSuperUser || AUTH.isProjectAdmin) && (
                                  <LockOpenIcon
                                    sx={{ cursor: 'pointer', color: 'red' }}
                                    onClick={() => lockCostModelHandler(item, "process")}
                                  />
                                )
                              )}

                              {item?.map_status && (
                                <InfoIcon
                                  sx={{
                                    cursor: "pointer",
                                    marginLeft: "1.5rem",
                                  }}
                                  onClick={() =>
                                    libraryInfoModal.open({
                                      key: "process",
                                      value: item.id,
                                      name: item.name,
                                    })
                                  }
                                />
                              )}
                              {!item?.map_status && useraction && useraction?.includes("D") && (
                                <DeleteIcon
                                  sx={{
                                    cursor: "pointer",
                                    marginLeft: "1.5rem",
                                    opacity: item?.lock == true ? 0.5 : 1,
                                  }}
                                  onClick={async () => {
                                    if (item?.lock != true) {
                                      const confirm: any = await deleteSwal(
                                        `Delete ${item.name}`
                                      );
                                      if (confirm) {
                                        API.delete(`api/db/process/${item.id}/`)
                                          .then((res: any) => {
                                            getProcessData();
                                          })
                                          .catch((err: any) => { });
                                      }
                                    }
                                  }}
                                />
                              )}
                            </TableCell>
                          </TableRow>
                        );
                      })
                    ) : (
                      <>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                        </TableRow>

                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.5rem",
                              borderBottomColor: "primary.light",
                            }}
                          >
                            <Skeleton></Skeleton>
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  </TableBody>
                </Table>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <LibraryInfoDetailsModal
        isOpen={libraryInfoModal.isOpen}
        onCloseModal={libraryInfoModal.close}
        libraryData={libraryInfoModal.propsId}
      />
    </div>
  );
}

export default CommodityandProcess;
