import { createEntityAdapter, createReducer, createSelector, EntityState } from "@reduxjs/toolkit";
// redux


import { createMomColumn, deleteDataMom, getMomColumn, updateMomData } from "../Actions/Mom.action";
import { ID } from "../../utlis/commonInterfaces";
import { ADMIN } from "../Services/admin.service";
import { RootState } from "../Store/storeConfigurations";

// -----------------------------------------------------------------------------------
// entity adaptor
export interface momDataSave {
    status: string;
   
    id: ID,
    closed: number,
    date: string,
    group: number,
    name: string,
    open: number,
    remark: string;
    attendees: any;
}
const adaptor = createEntityAdapter<momDataSave>( {
    selectId: ( momData ) => momData.id,
} );
// -----------------------------------------------------------------------------------
// interfaces & initialState
type AdditionalFields = {};
type IgroupingDriveReducer = EntityState<momDataSave> & AdditionalFields;
const additionalFields: AdditionalFields = {};
const initialState: IgroupingDriveReducer = Object.assign( {}, adaptor.getInitialState(), additionalFields );
// -----------------------------------------------------------------------------------
// reducer
const momDataSaveReducer = createReducer( initialState, builder => {
    // // get Data

    builder
        .addCase( getMomColumn.fulfilled, ( state, action: any ) => {
            adaptor.setAll( state, action.payload.MomDataSave );
        } )
        .addCase( getMomColumn.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could not fetch MOM Data" );
        } );

    //create Data
    builder
        .addCase( createMomColumn.fulfilled, ( state, action: any ) => {
            adaptor.addOne( state, action.payload.MomDataSave );
            // ADMIN.toast.success( "MOM Data Created" );
        } )
        .addCase( createMomColumn.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could Not Create MOM Data" );
        } );
    // update

    builder
        .addCase( updateMomData.fulfilled, ( state, action: any ) => {
            adaptor.upsertOne( state, action.payload.MomDataSave );

            // ADMIN.toast.success( "MOM Data Updated" );
        } )
        .addCase( updateMomData.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could Not Update MOM Data Successfully" );
        } );

    //delete
    builder
        .addCase( deleteDataMom.fulfilled, ( state, action: any ) => {
            const { id } = action.meta.arg;
            adaptor.removeOne( state, id );
            // ADMIN.toast.success( "MOM Data Deleted Successfully" );
        } )
        .addCase( deleteDataMom.rejected, ( state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could Not Delete MOM Data" );
        } );

} );

// selectors
const momDataSelector = adaptor.getSelectors<RootState>( ( state ) => state.MomDataSave );
const bymomDataSaveAbbreviationSelectors = Object.assign(
    {},
    momDataSelector ,

);

// -----------------------------------------------------------------------------------
// exports

export {
    momDataSaveReducer,
    initialState as momDataSaveReducerInit,
    bymomDataSaveAbbreviationSelectors
};