import React from 'react'
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CancelIcon from '@mui/icons-material/Cancel';

import { Divider, Tooltip } from '@mui/material';
import { DataGrid, GridColDef, GridCsvExportMenuItem, GridCsvExportOptions, GridToolbar, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExportContainer, GridValueGetterParams } from "@mui/x-data-grid";
import useWindowDimensions from '../../ui-reusable-component/useWindowDimensions';
interface InfoModalProps {


  onCloseModal: any;
  isOpen: any;
  Item: any;

}

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth: "60vw",
  maxWidth: '80vw',
  // height: '80vh',
  bgcolor: 'background.paper',
  borderRadius: '10px',
  p: 1,
};
const csvOptions: GridCsvExportOptions = { fileName: 'Table In CSV', delimiter: ",", utf8WithBom: true };

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer style={{ height: '1.5rem', marginBottom: '0.5rem' }}>
    <span style={{ position: 'absolute', right: '0rem' }}>
      {/* <GridToolbarColumnsButton /> */}
      {/* <GridToolbarDensitySelector /> */}
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
)
const InfoModal = (props: InfoModalProps) => {

  const { height, width } = useWindowDimensions();

  const { onCloseModal,
    isOpen,
    Item } = props

  var Columns: any = [
    // {
    //   field: "id", headerName: 'Name', hide: true,
    //   minWidth: 50,
    //   flex: 1,
    // },
    {
      field: 'name',
      headerName: 'Name',
      width: width > 1400 ? 180 : 150,
    },

    {
      field: 'module_name',
      headerName: 'Module',
      minWidth: width > 1400 ? 300 : 250,
      flex: 1,
      renderCell: (cellValues: any) => {
        return (<>
            <Tooltip sx={{ bacgroundColor: 'primary.light', color: 'primary.main' }} title={cellValues?.row?.module_name.toString(", ")} >
              <p>
                {cellValues?.row?.module_name.toString(", ")}
              </p>
            </Tooltip>
          </>)
      }
    },

    {
      field: 'top_vault_list',
      headerName: 'Product',
      minWidth: width > 1400 ? 300 : 250,
      flex: 1,
      rendeCell: (cellvalues: any) => {
        return (<>

          <Tooltip sx={{ bacgroundColor: 'primary.light', color: 'primary.main' }} title={cellvalues?.row?.top_vault_list.toString(", ")} >
            <p>
              {cellvalues?.row?.top_vault_list.toString(", ")}
            </p>
          </Tooltip>
        </>)
      }

    },

  ]


  return (
    <div>


      <Modal
        open={isOpen}
        onClose={onCloseModal}
      >
        <Box sx={style}>
          <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: '-2rem'
              }}
            >
              Catergory Info
            </Typography>
            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ height: '65vh', width: '100%' }}
           adl-scrollbar="true"
           adl-scrollbar-width="0.3">
            {Item &&
              <DataGrid
                headerHeight={48}
                rowHeight={36}
                rows={Item && Item}
                columns={Columns}
                density="compact"
                // autoHeight={true}
                components={{ Toolbar: () => { return CustomToolbar(); } }}
                hideFooter={true}
                hideFooterPagination={true}
                sx={{
                  "&.MuiDataGrid-root .MuiDataGrid-cell": {
                    borderBottomColor: "primary.light",
                  },
                  "& ::-webkit-scrollbar": {
                    width: "0.3rem",
                    height: "0.3rem",
                  },
                  "& ::-webkit-scrollbar-thumb": {
                    // background: $thumb-color;
                    backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                    borderRadius: "10px",
                  },
                  "& ::- webkit-scrollbar-thumb: hover ": {
                    // width: "4px",
                    backgroundColor: "#045DE9 !important",
                  },
                  "::-webkit-scrollbar-track": {
                    backgroundColor: "#e1e1f3 !important",
                  },                  
                }}
              />
            }
          </Box>
        </Box>
      </Modal>
    </div>
  )
}

export default InfoModal;