import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";

import {
  Autocomplete,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  TextField,
  Tooltip,
} from "@mui/material";
import { API } from "../../../api-services";
import { IdeaTypesList } from "../../../utlis/Idea.constant";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";

interface InfoModalProps {
  onCloseModal: any;
  isOpen: any;
  setIdeaList: any;
  setLoader: any;
  IdeaList: any;
  setIsShowCancelFilter: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "28vw",
  // maxWidth: '80vw',
  // height: '80vh',
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

const IdeaFilterOptions = (props: InfoModalProps) => {
  const [InfoData, setInfoData] = useState<any>([]);
  const [stage, setStage] = React.useState<any>();
  const [searchList, setSearchList] = React.useState<any>([]);
  const [selectedIdeaId, setSelectedIdeaId] = React.useState<any>();
  const { partId, topVault ,subSystemId,subSystem} = useRouteParams<any>();
  const { onCloseModal, isOpen, setIdeaList, setLoader, IdeaList,setIsShowCancelFilter } = props;

  const getOrigin = (origin: any) => {
    switch (origin) {
      case 1:
        return "BOM";
      case 2:
        return "Architecture";
      case 3:
        return "Feature";
      case 4:
        return "Costing";
      case 5:
        return "Media";
      case 7:
        return "Specification";

      default:
        break;
    }
  };

  React.useEffect(() => {
    setSearchList(IdeaList);
  }, [IdeaList]);
  //   let value=idea_id
  // React.useEffect(() => {
  //   setLoader(true);
  //   API.get(`/idea/pool/`, {
  //     vault: partId != 0 ? partId : 0,
  //     top_vault: topVault,
  //     module: 15,
  //     page: 1,
  //     search_id: "",
  //     table: true,
  //   })
  //     .then((res: any) => {
  //       setIdeaList(res.data);
  //       setLoader(false);
  //     })
  //     .catch((err: any) => {
  //       setLoader(false);
  //     });
  // }, [partId]);

  const IdeaData =
    IdeaTypesList &&
    IdeaTypesList?.filter((item: any, index: any) => {
      return item?.value == InfoData?.idea_type;
    })[0];

  const handleChange = (ed: any) => {
    const { value } = ed.target;
    setStage(+value);
    API.get(`/idea/pool/`, {
      vault: subSystem != "PRODUCT" ? subSystemId : undefined,
      top_vault: topVault,
      module: 15,
      page: 1,
      search_id: "",
      hierarchy: true,
      stage: +value,
    },0)
      .then((res: any) => {
        setIdeaList(res.data);
        setLoader(false);
      })
      .catch((err: any) => {
        setLoader(false);
      });
    
   
  };

  const onApply = (stg:any) =>{
    setLoader(true);
    setIsShowCancelFilter(true)
    if(selectedIdeaId?.idea_id){
    API.get(`/idea/pool/`, {
      vault: subSystem != "PRODUCT" ? subSystemId : undefined,
      top_vault: topVault,
      module: 15,
      page: 1,
      search_id: selectedIdeaId?.idea_id?selectedIdeaId?.idea_id:"",
      hierarchy: true,
    },0)
      .then((res: any) => {
        setIdeaList(res.data);
        setLoader(false);
      
        onCloseModal();
      })
      .catch((err: any) => {
        setLoader(false);
      });
    }else{
      API.get(`/idea/pool/`, {
        vault: subSystem != "PRODUCT" ? subSystemId : undefined,
        top_vault: topVault,
        module: 15,
        page: 1,
        search_id: "",
        stage: stg,
        hierarchy: true,
      },0)
        .then((res: any) => {
          setIdeaList(res.data);
          setLoader(false);
        
          onCloseModal();
        })
        .catch((err: any) => {
          setLoader(false);
        });
    }
  }



  const getStatus = (status: any) => {
    switch (status) {
      case 0:
        return "Initiated";
      case 1:
        return "In Review";
      case 2:
        return "Submitted";
      case 3:
        return "Accepted";

      default:
        break;
    }
  };

  return (
    <div>
      <Modal open={isOpen} >
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Idea Filter
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={()=>{
                API.get(`/idea/pool/`, {
                  vault: subSystem != "PRODUCT" ? subSystemId : undefined,
                  top_vault: topVault,
                  module: 15,
                  page: 1,
                  search_id: "",
                  hierarchy: true,
                },0)
                  .then((res: any) => {
                    setIdeaList(res.data);
                    setLoader(false);
                  
                    onCloseModal();
                  })
                  .catch((err: any) => {
                    setLoader(false);
                  });
                onCloseModal()}}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box>
            <FormControl fullWidth variant="standard">
              <InputLabel
                sx={{ color: "primary.main" }}
                shrink
                id="demo-simple-select-label"
              >
                Select Status
              </InputLabel>
              <Select
                value={stage}
                onChange={handleChange}
                variant="standard"
                // size="small"
                fullWidth
                sx={{
                  marginBottom: "1rem",
                  minWidth: "15rem",
                  "&:before": {
                    borderBottomColor: "primary.light",
                  },
                  "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                    {
                      fontSize: "1rem",
                    },
                  ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
                }}
              >
                <MenuItem value={0}>Initiated</MenuItem>
                <MenuItem value={1}>In Review</MenuItem>
                <MenuItem value={2}>Submitted</MenuItem>
                <MenuItem value={3}>Accepted</MenuItem>
                <MenuItem value={5}>Rejected</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth variant="standard">
              <InputLabel
                sx={{ color: "primary.main" }}
                shrink
                id="demo-simple-select-label"
              >
                Select Idea Id
              </InputLabel>
              <Autocomplete
                id="competitor"
                autoSelect
                getOptionLabel={(option: any) => option.idea_id}
                value={selectedIdeaId}
                options={searchList}
                onChange={(event: any, newValue: string | null) => {
                  setSelectedIdeaId(newValue);
                 
                }}
                sx={{ marginTop: "1.4rem" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    name="competitor"
                    sx={{
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.light",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                      ".MuiSvgIcon-root": { color: "primary.main" },
                    }}
                  />
                )}
              />
            </FormControl>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              columnGap: "1rem",
              width: "100%",
            }}
          >
            
            <Button
              variant="contained"
              size="small"
              sx={{
                marginTop: "1rem",
                marginBottom: "1rem",
                backgroundColor: "primary.main",
                color: "white",
                // width: "100%",
                fontSize: "1rem",
                fontWeight: "600",
              }}
              onClick={()=>onApply(stage)}
            >
              Apply
            </Button>
            {/* <Button
              variant="contained"
              size="small"
              sx={{
                marginTop: "1rem",
                marginBottom: "1rem",
                backgroundColor: "primary.light",
                color: "white",
                width: "100%",
                fontSize: "1rem",
                fontWeight: "600",
              }}
              onClick={() => {
                setLoader(true);
                API.get(`/idea/pool/`, {
                  vault: partId != 0 ? partId : 0,
                  top_vault: topVault,
                  module: 15,
                  page: 1,
                  search_id: "",
                  table: true,
                })
                  .then((res: any) => {
                    setIdeaList(res.data);
                    setLoader(false);
                  })
                  .catch((err: any) => {
                    setLoader(false);
                  });
              }}
              >
                Filter
              </Button> */}
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default IdeaFilterOptions;
