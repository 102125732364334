import { createEntityAdapter, createReducer, createSelector, EntityState } from "@reduxjs/toolkit";
// redux

import { createMeetingColumn, deleteDetailDataMom, getMomMeetingColumn, updateMomDetailData } from "../Actions/Mom.action";
import { ID } from "../../utlis/commonInterfaces";
import { ADMIN } from "../Services/admin.service";
import { RootState } from "../Store/storeConfigurations";

// -----------------------------------------------------------------------------------
// entity adaptor
export interface momDetailSave {
    id: ID,
    meeting: number,
    points: string,
    status: string,
    responsible: any,
    date: string,
    remark: string;
    file_name: string,
    absolute_path: string;
}
const adaptor = createEntityAdapter<momDetailSave>( {
    selectId: ( momData ) => momData.id,
} );
// -----------------------------------------------------------------------------------
// interfaces & initialState
type AdditionalFields = {};
type IgroupingDriveReducer = EntityState<momDetailSave> & AdditionalFields;
const additionalFields: AdditionalFields = {};
const initialState: IgroupingDriveReducer = Object.assign( {}, adaptor.getInitialState(), additionalFields );
// -----------------------------------------------------------------------------------
// reducer
const momDetailSaveReducer = createReducer( initialState, builder => {
    // // get Data

    builder
        .addCase( getMomMeetingColumn.fulfilled, ( state, action: any ) => {
            adaptor.setAll( state, action.payload.MomDetailSave );
        } )
        .addCase( getMomMeetingColumn.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could not fetch MOM Data" );
        } );

    //create Data
    builder
        .addCase( createMeetingColumn.fulfilled, ( state, action: any ) => {
            adaptor.addOne( state, action.payload.MomDetailSave );
            // ADMIN.toast.success( "MOM Data Created" );
        } )
        .addCase( createMeetingColumn.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could Not Create MOM Data" );
        } );
    // update

    builder
        .addCase( updateMomDetailData.fulfilled, ( state, action: any ) => {
            adaptor.updateOne( state, action.payload.MomDetailSave );

            // ADMIN.toast.success( "MOM Data Updated" );
        } )
        .addCase( updateMomDetailData.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could Not Update MOM Data Successfully" );
        } );

    //delete
    builder
        .addCase( deleteDetailDataMom.fulfilled, ( state, action: any ) => {
            const { id } = action.meta.arg;
            adaptor.removeOne( state, id );
            // ADMIN.toast.success( "MOM Data Deleted Successfully" );
        } )
        .addCase( deleteDetailDataMom.rejected, ( state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could Not Delete MOM Data" );
        } );

} );

// selectors
const momDetailSelector = adaptor.getSelectors<RootState>( ( state ) => state.MomDetailSave );
const byMomDetailSaveAbbreviationSelectors = Object.assign(
    {},
    momDetailSelector ,

);

// -----------------------------------------------------------------------------------
// exports

export {
    momDetailSaveReducer,
    initialState as momDetailSaveReducerInit,
    byMomDetailSaveAbbreviationSelectors
};