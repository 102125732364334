import {
  Box,
  Divider,
  Modal,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { useParams } from "react-router-dom";
import { API } from "../../api-services";
import SubststemListcategoryrenderRows from "./SubststemListcategoryrenderRows";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90vw",
  height: "85vh",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};
interface AddProjectCategoryModalProps {
  isOpen: any;
  onCloseHanlder: any;
  useraction:any;
}

const AddProjectCategoryModal = (props: AddProjectCategoryModalProps) => {
  const { isOpen, onCloseHanlder ,useraction} = props;
  const { categoryId, subsystemId, search } = useParams<any>();

  const [SubsystemList, setSubsystemList] = useState<any>([]);
  const [loader, setLoader] = useState<any>(false);

  const [ProjectList, setProjectList] = useState<any>([]);

  const [Counter, setCounter] = useState<any>(0);

  useEffect(() => {
    setLoader(true);
    API.get("/api/db/sub_system/", { vehicle_types: categoryId })
      .then((res: any) => {        
        setSubsystemList(res?.data);
        setLoader(false);
      })
      .catch((err: any) => {
        setLoader(false);
      });
  }, [categoryId, Counter,isOpen]);

  useEffect(() => {
    API.get("/xcpep/project/", { module: 3, vehicle_type: categoryId })
      .then((res: any) => {       
        setProjectList(res.data);        
      })
      .catch((err: any) => {
        console.log("Srver Error");        
      });
  }, [categoryId]);

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseHanlder}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}>
              Map System to Project
            </Typography>

            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseHanlder}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />


          <Box sx={{ height: { lg: '76vh', xl: '78vh' } }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3">
            <Table >
              <TableHead sx={{ backgroundColor: 'primary.main', position: 'sticky', top: '0', zIndex: 1 }}>
                <TableCell sx={{ padding: '0.2rem 0.5rem', borderBlockColor: 'primary.light', color: 'white', width: '10vw' }}>Name</TableCell>
                <TableCell sx={{ padding: '0.2rem 0.5rem', borderBlockColor: 'primary.light', color: 'white', width: '3vw' }}>Abbreviation</TableCell>
                <TableCell sx={{ padding: '0.2rem 0.5rem', borderBlockColor: 'primary.light', color: 'white', width: '30vw' }}>Project</TableCell>
                <TableCell sx={{ padding: '0.2rem 0.5rem', borderBlockColor: 'primary.light', color: 'white', width: '10vw' }}>Description</TableCell>
                <TableCell sx={{ padding: '0.2rem 0.5rem', borderBlockColor: 'primary.light', color: 'white', width: '3vw' }}>Action</TableCell>
              </TableHead>
              <TableBody>
              {loader ? (<>
                          <TableRow>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                            <TableCell sx={{ padding: "0.2rem 0.5rem", borderBottomColor: 'primary.light' }}><Skeleton animation="wave"></Skeleton> </TableCell>
                          </TableRow>
                        </>
                        ) : ( <>
                {SubsystemList &&
                  SubsystemList.map((item: any, index: any) => {
                    return (
                      <>
                        

                          <SubststemListcategoryrenderRows
                          useraction={useraction}
                            setCounter={setCounter}
                            ProjectList={ProjectList && ProjectList}
                            rowItem={item}
                            Keys={index + 1}
                          />
                       
                      </>
                    );
                  })}</>
                  )}
              </TableBody>
            </Table>
          </Box>

        </Box>
      </Modal>
    </div>
  );
};
export default AddProjectCategoryModal;
