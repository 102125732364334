// react
import React, { useEffect, useRef, useState } from "react";
// styles
import "../Authentication/SignUp/SignUp.scss";
import userIcon from "../../Assets/images/user.png";
import { useHistory, useRouteMatch } from "react-router-dom";
import moment from "moment";
import { makeStyles } from "@mui/styles";
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Fab,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  Modal,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import { useFormik } from "formik";
import * as yup from "yup";
import WarningIcon from "@mui/icons-material/Warning";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import styles from "../Authentication/SignUp/SignUp.module.scss";
import success from "../../Assets/images/success.png";
import warning from "../../Assets/images/Warning.png";
import LoadingButton from "@mui/lab/LoadingButton";
import VerifiedUserIcon from "@mui/icons-material/VerifiedUser";
import { API } from "../../api-services";
import CancelIcon from "@mui/icons-material/Cancel";

// interfaces and types
// lazy

// ----------------------------------------------------------------------------------

interface IAddEditUserModal {
  isOpen: boolean;
  onClose: () => void;
  userDetails: any;
  setApiCallCount: any;
}

const validationSchema = yup.object({
  first_name: yup.string().required("Please enter your First Name"),
  last_name: yup.string().required("Please enter your Last Name"),
  employee_id: yup.string().required("Please enter your Employee ID"),
  department: yup.string().required("Please enter your Department"),
  designation: yup.string().required("Please enter your Designation"),
  email: yup
    .string()
    .email("Please enter valid email")
    .required("Please enter your Email"),
  domain: yup.string().when(["email", "client"], {
    is: (email, client) => {
      const domain = email?.split("@")?.pop();
      return domain == "gmail.com" ||
        domain == "yahoo.com" ||
        domain == "hotmail.com"
    },
    then: yup
      .string()
      .required(
        "This domain is not allowed for user creation please use your organization domain or contact ASI Administrator."
      ),
  }),
  email_otp: yup.bool().when(["phone_otp"], {
    is: (phone_otp) => {
      return phone_otp == false;
    },
    then: yup.bool().oneOf([true], "Please select one of the OTP options"),
  }),
  phone: yup.string().required("Please enter your Phone Number"),
  user_expiration: yup.string().required("Please enter  User Expiration"),
  type: yup.string().required("Please enter type"),
  is_active: yup.mixed(),
});
const styled = {
  boxSize: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",

    width: "49rem",
    maxWidth: "80%",
    height: "fit-content",
    minHeight: "20rem",
    borderRadius: "0.5rem",
    bgcolor: "background.paper",
    border: "none",
    // boxShadow: 24,    
    outline: 0,
    padding: '1.5rem'
  },
  NoAccessBoxSize: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center !important",
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "39rem",
    height: "fit-content",
    minHeight: "20rem",
    borderRadius: "0.5rem",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    p: 0,
    outline: 0,
  },
  HeaderLine: {
    textAlign: "center",
    position: "relative",
  },
  SelectedHeaderLine: {
    color: "#1976d2",
  },
  nextButton: {
    position: "absolute",
    zIndex: "9999",
    left: "35rem",
    top: "1.8rem",
  },

  summery: {
    backgroundColor: "rgb(240, 247, 255)",
    borderColor: " rgb(0, 127, 255)",
    borderRadius: "10px",
    minHeight: "45px",
  },
  searchButton: {
    position: "absolute",

    // right: "37rem",
    top: "0.4rem",

    left: "-2rem",
    cursor: "default",
    color: "#1976d2",
    padding: " 0rem 0rem 0rem 2rem",
  },
  closedIcon: {
    position: "absolute",
    zIndex: "999",
    color: "#1976d2",
    right: "0.2rem",
    top: "0.5rem",
    cursor: "pointer",
  },
  editIcon: {
    marginTop: "0.3rem",
    color: "#1976d2",
    cursor: "pointer",
  },
};
// @ts-ignore

const useStyles = makeStyles(() => ({
  /** Changed modalStyle */
  ModalRoot: {
    backgroundColor: "rgba(111, 126, 140, 0.2) !important",
    backdropFilter: "blur(1px) !important",
  },
}));

const AddEditUserFormModal: React.FC<IAddEditUserModal> = (props) => {
  // isOpen: boolean;
  // onClose: () => void;
  // userDetails: any;
  const { isOpen, onClose, userDetails, setApiCallCount } = props;
  const classes = useStyles();
  const { url } = useRouteMatch();
  const [open, setOpen] = React.useState(false);
  const [openError, setOpenError] = React.useState(false);
  const [createUserBtnLoader, setCreateUserBtnLoader] = React.useState(false);
  const [otpSentWell, setOTPSentWell] = React.useState(false);
  const [verifyEmailOTP, setVerifyEmailOTP] = React.useState(false);
  const [verifyPhoneOTP, setVerifyPhoneOTP] = React.useState(false);

  React.useState(true);
  const [errorMessagePopup, setErrorMessagePopup] = useState<any>();

  const inputElRef = useRef<any>();
  const [{ alt, src }, setImg] = useState<any>({
    src: userIcon,
    alt: "Upload an Image",
  });

  const [imgData, setImageData] = useState<any>();
  const history = useHistory();

  const browseFiles = () => {
    return inputElRef.current?.click?.();
  };

  const handleImg = (e: any) => {
    if (e.target.files[0]) {
      setImg({
        src: URL.createObjectURL(e.target.files[0]),
        alt: e.target.files[0].name,
      });
      setImageData(e.target.files[0]);
    }
  };
  const getType = () => {
    switch (userDetails) {
      case "projectadmin_add":
        return "Project Administrator"
      case "moduleadmin_add":
        return "Module Administrator"
      case "users_add":
        return "User"
      case "guestusers_add":
        return "Guest User"

    }
  }



  const formik = useFormik({
    initialValues: {
      first_name: "",
      last_name: "",
      employee_id: "",
      department: "",
      designation: "",
      email: "",
      domain: "",
      phone: "",
      phone_otp: false,
      email_otp: true,
      dialCode: {
        countryCode: "",
        dialCode: "",
        format: "",
        name: "",
      },
      user_expiration: moment().format("YYYY-MM-DD").toString(),
      type: userDetails && typeof userDetails !== "string" ? "" : getType(),
      is_active: true,
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      setCreateUserBtnLoader(true);
      var formData = new FormData();
      var formData1 = new FormData();
      let val: any = values;
      formData.append("first_name", val.first_name);
      formData.append("last_name", val.last_name);
      formData.append("employee_id", val.employee_id);
      formData.append("designation", val.designation);
      formData.append("email", val.email);
      formData.append("department", val.department);
      formData.append("domain", val.email?.split("@")?.pop());
      formData.append("image", imgData ? imgData : "null");
      formData.append("phone",val.phone?.length===11? val.phone.substring(val.dialCode.dialCode.length+1):val.phone.substring(val.dialCode.dialCode.length));
      formData.append("country_code", `+${val.dialCode.dialCode}`);
      formData.append("user_category", val.email?.includes("advancedstructures.in") ? "Internal" : "External");
      formData.append("username", val.first_name + val.last_name);
      formData.append("user_expiration", val.user_expiration);
      formData.append("is_active", val.is_active);
      formData.append("phone_otp", val.phone_otp);
      formData.append("email_otp", val.email_otp);
      formData.append("type", val.type);

      formData1.append("first_name", val.first_name);
      formData1.append("last_name", val.last_name);
      formData1.append("employee_id", val.employee_id);
      formData1.append("designation", val.designation);
      formData1.append("email", val.email);
      formData1.append("department", val.department);
      formData1.append("domain", val.email?.split("@")?.pop());
      typeof imgData !== "string" && formData1.append("image", imgData);
      formData1.append("phone", val.phone.substring(val.dialCode.dialCode.length));
      formData1.append("country_code", `+${val.dialCode.dialCode}`);
      formData1.append("user_category", val.email?.includes("advancedstructures.in") ? "Internal" : "External");
      formData1.append("username", val.first_name + val.last_name);
      formData1.append("user_expiration", val.user_expiration);
      formData1.append("is_active", val.is_active);
      formData1.append("phone_otp", val.phone_otp);
      formData1.append("email_otp", val.email_otp);
      formData1.append("type", val.type);
      delete val.dialCode;
      if (userDetails && typeof userDetails !== "string") {
        API.patch(`auth/user/${userDetails.id}/`, formData1, {}, 0)
          .then(() => {
            setOpen(true);
            setCreateUserBtnLoader(false);
            setApiCallCount((prev: any) => prev + 1)
            onClose()
          })
          .catch((e: any) => {
            setOpenError(true);
            setErrorMessagePopup(e?.response?.data);
          });
      } else {
        API.post("auth/user/", formData, {}, 0)
          .then(() => {
            setOpen(true);
            setCreateUserBtnLoader(false);
            setApiCallCount((prev: any) => prev + 1)
            onClose()
          })
          .catch((e: any) => {
            setOpenError(true);
            setErrorMessagePopup(e?.response?.data);
          });
      }
    },
  });
  useEffect(() => {
    if (userDetails && typeof userDetails !== "string") {
      formik.setFieldValue("first_name", userDetails.first_name);
      formik.setFieldValue("last_name", userDetails.last_name);
      formik.setFieldValue("employee_id", userDetails.employee_id);
      formik.setFieldValue("department", userDetails.department);
      formik.setFieldValue("designation", userDetails.designation);
      formik.setFieldValue("email", userDetails.email);
      formik.setFieldValue("domain", userDetails.email?.split("@")?.pop());
      formik.setFieldValue("phone", `${userDetails?.country_code?.slice(1)}${userDetails.phone}`);
      formik.setFieldValue("phone_otp", userDetails?.phone_otp);
      formik.setFieldValue("email_otp", userDetails?.email_otp);
      formik.setFieldValue("type", userDetails?.type);
      formik.setFieldValue("user_expiration", userDetails?.user_expiration);
      formik.setFieldValue("is_active", userDetails?.is_active);
      formik.setFieldValue("dialCode", {
        countryCode: "",
        dialCode: userDetails?.country_code?.slice(1),
        format: "",
        name: "",
      })
      setImg({
        src: userDetails.user_info,
        alt: "user_img",
      })
      setImageData(userDetails?.user_info)
    }
  }, [userDetails])
  const handleClose = () => {
    history.push("/user-management");
    setOpen(false);
  };
  const handleCloseErrorBox = () => {
    setOpenError(false);
    history.push("/user-management");
  };

  var AvatarName = (item: any) => {
    const [first, last] = item?.split(" ");

    const FirstLatter = first?.charAt(0);

    const lastWord = last?.charAt(0);

    return `${FirstLatter}${lastWord}`;
  };


  return (
    <div>
      <Modal
        open={isOpen}
        className={classes.ModalRoot}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styled.boxSize}>
          <Dialog
            PaperProps={{
              style: { borderRadius: 10 },
            }}
            open={open}
            onClose={handleClose}
          >
            <Box sx={{ width: "30vw" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  height: "2.5rem",
                  width: "100%",
                }}
              >
                <Typography
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    width: "100%",
                    textAlign: "center",
                    marginRight: "-2rem",
                  }}
                >
                  {userDetails && typeof userDetails !== "string" ? "" : " New User Creation Successfull!"}
                </Typography>
                <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={handleClose} />
              </Box>
              <Divider
                sx={{
                  borderColor: "primary.light",
                  margin: "0 1rem",
                  marginBottom: "1rem",
                }}
              />
              <Box sx={{ width: "100%", textAlign: "center" }}>
                {/* <CheckCircleOutlineIcon sx={{ color: 'green', fontSize: '5rem', textAlign:'center' }} /> */}
                <img src={success} style={{ height: "5rem", width: "auto" }} />
                {/* <WarningAmberRoundedIcon /> */}
                {/* {` Alert`} */}
              </Box>
              <DialogContent sx={{ padding: "0 20px 10px" }}>
                <Typography
                  sx={{ color: "green", fontSize: "1rem", textAlign: "center" }}
                >
                  {userDetails && typeof userDetails !== "string" ? "User details updated successfully!" : "User created successfully!"}
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button
                  size="small"
                  className={styles.btn}
                  variant="contained"
                  onClick={handleClose}
                >
                  Ok
                </Button>
              </DialogActions>
            </Box>
          </Dialog>
          <Dialog
            PaperProps={{
              style: { borderRadius: 10 },
            }}
            open={openError}
            onClose={handleCloseErrorBox}
          >
            <Box sx={{ width: "30vw" }}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  height: "2.5rem",
                  width: "100%",
                }}
              >
                <Typography
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    width: "100%",
                    textAlign: "center",
                    marginRight: "-2rem",
                  }}
                >
                  Warning!
                </Typography>
                <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={handleClose} />
              </Box>
              <Divider
                sx={{
                  borderColor: "primary.light",
                  margin: "0 1rem",
                  marginBottom: "1rem",
                }}
              />
              <Box sx={{ width: "100%", textAlign: "center" }}>
                <img src={warning} style={{ height: "5rem", width: "auto" }} />
              </Box>
              <DialogContent sx={{ padding: "0 20px 10px", margin: "0 3rem" }}>
                <Typography
                  style={{
                    color: "red",
                    fontSize: "1rem",
                    textAlign: "center",
                  }}
                >
                  {errorMessagePopup}
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button
                  size="small"
                  className={styles.btn}
                  variant="contained"
                  onClick={handleCloseErrorBox}
                >
                  Ok
                </Button>
              </DialogActions>
            </Box>
          </Dialog>
          <form onSubmit={formik.handleSubmit}>
            <Grid container columnSpacing={2}>
              <Grid
                item
                xs={12}
                sx={{ pb: 1 }}
                style={{
                  justifyContent: "center",
                  display: "flex",
                  padding: "1rem 0",
                }}
              >
                {src != null ? <img
                  src={src}
                  alt={alt}
                  onClick={browseFiles}
                  className="card-root"
                  draggable={false}
                />
                  : <Avatar
                    src="/broken-image.jpg"
                    onClick={browseFiles}
                    sx={{
                      width: "11rem",
                      height: "11rem",
                      fontSize: "5rem !important",
                      objectFit: "cover",
                      backgroundColor: "#007fff !important",
                      textTransform: 'uppercase',
                    }}
                  >
                    {AvatarName(userDetails?.fullname)}
                  </Avatar>
                }
                <div>
                  <input
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    id="photo"
                    className="visually-hidden"
                    style={{ display: "none" }}
                    onChange={handleImg}
                    ref={inputElRef}
                  />
                </div>
              </Grid>
              <Grid item xs={12}>
                <div className={styles.formSectionsignup}>
                  <Grid container columnSpacing={2}>
                    <Grid item xs={6} sx={{ pb: 1 }}>
                      <div>
                        <TextField
                          autoFocus
                          InputProps={{
                            style: { color: "#007fff", fontSize: "1rem" },
                          }}
                          InputLabelProps={{ style: { fontSize: "1rem" } }}
                          sx={{
                            width: "100%",
                            "& .MuiFormLabel-root": {
                              color: "primary.main",
                            },
                            "& .MuiFormLabel-root.Mui-focused": {
                              color: "primary.main",
                            },
                            "& .Mui-focused": {
                              color: "primary.main",
                            },
                            "& .Mui-error": {
                              color: "#d32f2f",
                              borderBottomColor: "#d32f2f",
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "primary.light",
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "primary.light",
                            },
                          }}
                          id="first_name"
                          name="first_name"
                          label="First Name *"
                          variant="standard"
                          value={formik.values.first_name}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.first_name &&
                            Boolean(formik.errors.first_name)
                          }
                          helperText={
                            formik.touched.first_name &&
                            formik.errors.first_name
                          }
                        />
                      </div>
                    </Grid>
                    <Grid item xs={6} sx={{ pb: 1 }}>
                      <div>
                        <TextField
                          InputProps={{
                            style: { color: "#007fff", fontSize: "1rem" },
                          }}
                          InputLabelProps={{ style: { fontSize: "1rem" } }}
                          sx={{
                            width: "100%",
                            "& .MuiFormLabel-root": {
                              color: "primary.main",
                            },
                            "& .MuiFormLabel-root.Mui-focused": {
                              color: "primary.main",
                            },
                            "& .Mui-focused": {
                              color: "primary.main",
                            },
                            "& .Mui-error": {
                              color: "#d32f2f",
                              borderBottomColor: "#d32f2f",
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "primary.light",
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "primary.light",
                            },
                          }}
                          id="last_name"
                          name="last_name"
                          label="Last Name *"
                          value={formik.values.last_name}
                          variant="standard"
                          onChange={formik.handleChange}
                          size="small"
                          error={
                            formik.touched.last_name &&
                            Boolean(formik.errors.last_name)
                          }
                          helperText={
                            formik.touched.last_name && formik.errors.last_name
                          }
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} lg={6} xl={6} sx={{ pb: 1 }}>
                      <div>
                        <TextField
                          fullWidth
                          InputProps={{
                            style: { color: "#007fff", fontSize: "1rem" },
                          }}
                          InputLabelProps={{ style: { fontSize: "1rem" } }}
                          sx={{
                            width: "100%",
                            "& .MuiFormLabel-root": {
                              color: "primary.main",
                            },
                            "& .MuiFormLabel-root.Mui-focused": {
                              color: "primary.main",
                            },
                            "& .Mui-focused": {
                              color: "primary.main",
                            },
                            "& .Mui-error": {
                              color: "#d32f2f",
                              borderBottomColor: "#d32f2f",
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "primary.light",
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "primary.light",
                            },
                          }}
                          id="employee_id"
                          name="employee_id"
                          label="Employee ID *"
                          variant="standard"
                          size="small"
                          value={formik.values.employee_id}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.employee_id &&
                            Boolean(formik.errors.employee_id)
                          }
                          helperText={
                            formik.touched.employee_id &&
                            formik.errors.employee_id
                          }
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} lg={6} xl={6} sx={{ pb: 1 }}>
                      <div>
                        <FormControl
                          variant="standard"
                          sx={{ marginBottom: '-8px', minWidth: 120, width: "100%" }}
                          size="medium"
                        ><label style={{ fontSize: '0.75rem' }}>Expiration Date:</label>
                          <input value={formik.values.user_expiration} onChange={formik.handleChange} type="date" name="user_expiration" required
                            style={{
                              color: '#007fff',
                              borderTop: 'none',
                              borderLeft: 'none',
                              borderRight: 'none',
                              borderBottom: '1px solid #007fff',
                              paddingBottom: '5px',
                              width: '100%',
                            }}
                          />
                        </FormControl>
                      </div>
                    </Grid>
                    <Grid item xs={6} md={6} lg={6} xl={6} sx={{ pb: 1 }}>
                      <div>
                        <TextField
                          fullWidth
                          InputProps={{
                            style: { color: "#007fff", fontSize: "1rem" },
                          }}
                          InputLabelProps={{ style: { fontSize: "1rem" } }}
                          sx={{
                            width: "100%",
                            "& .MuiFormLabel-root": {
                              color: "primary.main",
                            },
                            "& .MuiFormLabel-root.Mui-focused": {
                              color: "primary.main",
                            },
                            "& .Mui-focused": {
                              color: "primary.main",
                            },
                            "& .Mui-error": {
                              color: "#d32f2f",
                              borderBottomColor: "#d32f2f",
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "primary.light",
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "primary.light",
                            },
                          }}
                          id="department"
                          name="department"
                          label="Department *"
                          variant="standard"
                          size="small"
                          value={formik.values.department}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.department &&
                            Boolean(formik.errors.department)
                          }
                          helperText={
                            formik.touched.department &&
                            formik.errors.department
                          }
                        />
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      md={6}
                      lg={6}
                      xl={6}
                      sx={{ pb: 1, md: "ml:4%;" }}
                    >
                      <div>
                        <TextField
                          fullWidth
                          InputProps={{
                            style: { color: "#007fff", fontSize: "1rem" },
                          }}
                          InputLabelProps={{ style: { fontSize: "1rem" } }}
                          sx={{
                            width: "100%",
                            "& .MuiFormLabel-root": {
                              color: "primary.main",
                            },
                            "& .MuiFormLabel-root.Mui-focused": {
                              color: "primary.main",
                            },
                            "& .Mui-focused": {
                              color: "primary.main",
                            },
                            "& .Mui-error": {
                              color: "#d32f2f",
                              borderBottomColor: "#d32f2f",
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "primary.light",
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "primary.light",
                            },
                          }}
                          id="designation"
                          name="designation"
                          label="Designation *"
                          variant="standard"
                          size="small"
                          value={formik.values.designation}
                          onChange={formik.handleChange}
                          error={
                            formik.touched.designation &&
                            Boolean(formik.errors.designation)
                          }
                          helperText={
                            formik.touched.designation &&
                            formik.errors.designation
                          }
                        />
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      md={6}
                      lg={6}
                      xl={6}
                      columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 5, xl: 0 }}
                      sx={{ pb: 1 }}
                    >
                      <div>
                        <TextField
                          fullWidth
                          InputProps={{
                            style: { color: "#007fff", fontSize: "1rem" },
                          }}
                          InputLabelProps={{ style: { fontSize: "1rem" } }}
                          // sx={{ width: "95%", }}
                          sx={{
                            width: "100%",
                            "& .MuiFormLabel-root": {
                              color: "primary.main",
                            },
                            "& .MuiFormLabel-root.Mui-focused": {
                              color: "primary.main",
                            },
                            "& .Mui-focused": {
                              color: "primary.main",
                            },
                            "& .Mui-error": {
                              color: "#d32f2f",
                              borderBottomColor: "#d32f2f",
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "primary.light",
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "primary.light",
                            },
                          }}
                          id="email"
                          name="email"
                          label="Email ID *"
                          variant="standard"
                          size="small"
                          value={formik.values.email}
                          onChange={(e) => {
                            setVerifyEmailOTP(false);
                            setOTPSentWell(false);
                            return formik.setFieldValue(
                              "email",
                              e.target.value
                            );
                          }}
                          error={
                            formik.touched.email && Boolean(formik.errors.email)
                          }
                          helperText={
                            formik.touched.email && formik.errors.email
                          }
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} xl={6}>
                      <div>
                        {/* columnSpacing={{ xs: 1, sm: 2, md: 3, lg: 5, xl: 0 }} sx={{ pb: 1 }} */}
                        <TextField
                          InputProps={{
                            style: { color: "#007fff", fontSize: "1rem" },
                          }}
                          InputLabelProps={{ style: { fontSize: "1rem" } }}
                          sx={{
                            width: "100%",
                            "& .MuiFormLabel-root": {
                              color: "primary.light",
                            },
                            "& .MuiFormLabel-root.Mui-focused": {
                              color: "primary.light",
                            },
                            "& .Mui-error": {
                              color: "#d32f2f",
                              borderBottomColor: "#d32f2f",
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "primary.light",
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "primary.light",
                            },
                          }}
                          id="domain"
                          name="domain"
                          label="Domain"
                          variant="standard"
                          value={formik.values.email?.split("@")?.pop()}
                          disabled
                          // onChange={formik.handleChange(
                          //   formik.values.email?.split("@")?.pop()
                          // )}
                          error={
                            formik.touched.domain &&
                            Boolean(formik.errors.domain)
                          }
                          helperText={
                            formik.touched.domain && formik.errors.domain
                          }
                        />
                      </div>
                    </Grid>
                    <Grid item xs={12} lg={6} xl={6}>
                      <div className={styles.phoneNumber}>
                        {/* sx={{ lg: { pl: 3 }, xl: { pl: 3 }, xs: { textAlign: 'left' } }} */}
                        <FormLabel
                          sx={{
                            color: "primary.main",
                            mt: {
                              xl: "0.2rem",
                              lg: "0.2rem",
                              md: "0.5rem",
                              sm: "0.5rem",
                              xs: "0.5rem",
                            },
                            textAlign: "left",
                            fontSize: "0.8rem",
                          }}
                          style={
                            formik.touched.phone && Boolean(formik.errors.phone)
                              ? { color: "#d32f2f" }
                              : {}
                          }
                        >
                          Phone Number *
                        </FormLabel>
                        {/* @ts-ignore */}
                        <PhoneInput
                          //@ts-ignore
                          id="phone"
                          value={formik.values.phone}
                          name="phone"
                          className="phoneInput"
                          onChange={(value, data) => {
                            return (
                              formik.setFieldValue("phone", value),
                              formik.setFieldValue("dialCode", data)
                            );
                          }}
                          country={"in"}
                        />
                        {formik.touched.phone &&
                          Boolean(formik.errors.phone) && (
                            <p
                              style={{
                                fontSize: "0.75rem",
                                color: "#d32f2f",
                                marginTop: "0.4rem",
                              }}
                            >
                              {formik.errors.phone}
                            </p>
                          )}
                      </div>
                    </Grid>
                    <Grid item xs={12} lg={6} xl={6}>
                      <div>
                        <Grid
                          container
                          columnSpacing={1}
                          style={{
                            justifyContent: "center",
                            alignItems: "center",
                          }}
                          sx={{ pb: 1, color: "#007fff" }}
                        >
                          <FormLabel
                            sx={{
                              "& .MuiTextField-root": { m: 1 },

                              width: "100%",
                              "& .MuiFormLabel-root": {
                                color: "primary.main",
                              },
                              "& .MuiFormLabel-root.Mui-focused": {
                                color: "primary.main",
                              },
                              "& .Mui-error": {
                                color: "#d32f2f",
                                borderBottomColor: "#d32f2f",
                              },
                              "& .MuiInput-underline:before": {
                                borderBottomColor: "primary.light",
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: "primary.light",
                              },
                              marginTop: '0.5rem',
                            }}
                            style={
                              formik.touched.email_otp &&
                                Boolean(formik.errors.email_otp)
                                ? { color: "#d32f2f", fontSize: "0.75rem", paddingLeft: '0.5rem' }
                                : { color: "#007fff", fontSize: "0.75rem", paddingLeft: '0.5rem' }
                            }
                          >
                            <span>OTP *</span>
                          </FormLabel>
                          <Grid
                            item
                            xs={6}
                            style={{ display: "flex", alignItems: "baseline" }}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  sx={{
                                    color: "primary.main",
                                    fontSize: "1rem",
                                  }}
                                  id="phone_otp"
                                  name="phone_otp"
                                  defaultChecked={formik.values.phone_otp}
                                  onChange={(value, data) => {
                                    return formik.setFieldValue(
                                      "phone_otp",
                                      data
                                    );
                                  }}
                                />
                              }
                              label="Phone"
                              sx={{
                                "& .MuiSvgIcon-root": { fontSize: 24 },
                                "&.MuiFormControlLabel-root .MuiFormControlLabel-label":
                                {
                                  fontSize: "1rem",
                                },
                              }}
                            />
                            {verifyPhoneOTP && (
                              <VerifiedUserIcon color="success" />
                            )}
                          </Grid>
                          <Grid
                            item
                            xs={6}
                            style={{ display: "flex", alignItems: "baseline" }}
                          >
                            <FormControlLabel
                              control={
                                <Checkbox
                                  sx={{
                                    color: "primary.light",
                                    fontSize: "1rem",
                                  }}
                                  disabled
                                  defaultChecked={formik.values.email_otp}
                                />
                              }
                              label="Email"
                              sx={{
                                "& .MuiSvgIcon-root": { fontSize: 24 },
                                "&.MuiFormControlLabel-root .MuiFormControlLabel-label":
                                {
                                  fontSize: "1rem",
                                },
                              }}
                            />

                            {verifyEmailOTP && (
                              <VerifiedUserIcon color="success" />
                            )}
                          </Grid>

                          {/* <Grid item xs={2}>
                            {checkDisabled() && (
                              <>
                                {" "}
                                {!otpSentWell ? (
                                  <>
                                    {apiSendOTP ? (
                                      <Button
                                        onClick={handleSendOtp}
                                        className={styles.signupBtn}
                                        disabled={formik.values.email == ""}
                                        size="small"
                                      >
                                        Send OTP
                                      </Button>
                                    ) : (
                                      <Skeleton
                                        sx={{ height: "2rem", width: "100%" }}
                                      />
                                    )}
                                  </>
                                ) : apiReSendOTP ? (
                                  <Button
                                    onClick={handleReSendOtp}
                                    className={styles.signupBtn}
                                    size="small"
                                  >
                                    Re-send OTP
                                  </Button>
                                ) : (
                                  <Skeleton
                                    sx={{ height: "2rem", width: "100%" }}
                                  />
                                )}
                              </>
                            )}
                          </Grid> */}
                          {/* {otpSentWell && (
                            <>
                              <Grid item xs={2}></Grid>
                              <Grid item xs={4}>
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <Box>
                                    {!verifyPhoneOTP && (
                                      <>
                                        {formik.values.phone_otp && (
                                          <TextField
                                            InputProps={{
                                              style: {
                                                color: "primary.main",
                                                fontSize: 16,
                                              },
                                            }}
                                            InputLabelProps={{
                                              style: { fontSize: 16 },
                                            }}
                                            sx={{
                                              width: "8rem",
                                              // padding: '0 1rem',
                                              "& .MuiFormLabel-root": {
                                                color: "primary.main",
                                                // padding: "0 1rem",
                                              },
                                              "& .MuiFormLabel-root.Mui-focused":
                                                {
                                                  color: "primary.main",
                                                  // padding: "0 1rem",
                                                },
                                              "& .Mui-focused": {
                                                color: "primary.main",
                                              },
                                              "& .Mui-error": {
                                                color: "#d32f2f",
                                              },
                                              "& .MuiInput-underline:before": {
                                                borderBottomColor:
                                                  "primary.main",
                                              },
                                              "& .MuiInput-underline:after": {
                                                borderBottomColor:
                                                  "primary.main",
                                              },
                                            }}
                                            id="otpGot"
                                            name="otpGot"
                                            placeholder="Enter Phone OTP"
                                            // label="Enter Phone OTP"
                                            value={phoneNumberOTP}
                                            onChange={(e: any) =>
                                              enterPhoneOtp(e)
                                            }
                                            color="primary"
                                            variant="standard"
                                          />
                                        )}
                                      </>
                                    )}
                                  </Box>
                                  <Box>
                                    {formik.values.phone_otp && (
                                      <>
                                        {!verifyPhoneOTP && (
                                          <>
                                            {apiVerficationPhoneOTP ? (
                                              <Button
                                                onClick={handleVerifyPhoneOtp}
                                                className={styles.signupBtn}
                                                size="small"
                                              >
                                                Verify
                                              </Button>
                                            ) : (
                                              <Skeleton
                                                sx={{
                                                  height: "2rem",
                                                  width: "100%",
                                                }}
                                              />
                                            )}
                                          </>
                                        )}
                                      </>
                                    )}
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item xs={4}>
                                <Box
                                  sx={{ display: "flex", alignItems: "center" }}
                                >
                                  <Box>
                                    {!verifyEmailOTP && (
                                      <TextField
                                        InputProps={{
                                          style: {
                                            color: "primary.main",
                                            fontSize: 16,
                                          },
                                        }}
                                        InputLabelProps={{
                                          style: { fontSize: 16 },
                                        }}
                                        sx={{
                                          width: "8rem",
                                          // padding: '0 1rem',
                                          "& .MuiFormLabel-root": {
                                            color: "primary.main",
                                            // padding: "0 1rem",
                                          },
                                          "& .MuiFormLabel-root.Mui-focused": {
                                            color: "primary.main",
                                            // padding: "0 1rem",
                                          },
                                          "& .Mui-focused": {
                                            color: "primary.main",
                                          },
                                          "& .Mui-error": {
                                            color: "#d32f2f",
                                          },
                                          "& .MuiInput-underline:before": {
                                            borderBottomColor: "primary.main",
                                          },
                                          "& .MuiInput-underline:after": {
                                            borderBottomColor: "primary.main",
                                          },
                                        }}
                                        id="otpGot"
                                        name="otpGot"
                                        placeholder="Enter Email OTP"
                                        // label="Enter Email OTP"
                                        value={emailNumberOTP}
                                        onChange={(e: any) => enterEmailOtp(e)}
                                        color="primary"
                                        variant="standard"
                                      />
                                    )}
                                  </Box>
                                  <Box>
                                    {!verifyEmailOTP && (
                                      <>
                                        {apiVerficationEmailOTP ? (
                                          <Button
                                            onClick={handleVerifyEmailOtp}
                                            className={styles.signupBtn}
                                            size="small"
                                          >
                                            Verify
                                          </Button>
                                        ) : (
                                          <Skeleton
                                            sx={{
                                              height: "2rem",
                                              width: "100%",
                                            }}
                                          />
                                        )}
                                      </>
                                    )}
                                  </Box>
                                </Box>
                              </Grid>
                              <Grid item xs={2}></Grid>
                            </>
                          )} */}
                          {/* {otpSentWell && (
                            <>
                              <Grid item xs={2}></Grid>
                              <Grid item xs={4}>
                                {resendOTPPhoneError ? (
                                  <span
                                    style={{
                                      fontSize: "0.75rem",
                                      color: "#d32f2f",
                                      marginTop: "0rem",
                                    }}
                                  >
                                    OTP Expired
                                  </span>
                                ) : (
                                  <>
                                    {formik.values.phone_otp &&
                                      phoneOTPError && (
                                        <span
                                          style={{
                                            fontSize: "0.75rem",
                                            color: "#d32f2f",
                                            marginTop: "0rem",
                                          }}
                                        >
                                          Invalid OTP
                                        </span>
                                      )}
                                  </>
                                )}
                              </Grid>
                              <Grid item xs={4}>
                                {resendOTPEmailError ? (
                                  <span
                                    style={{
                                      fontSize: "0.75rem",
                                      color: "#d32f2f",
                                      marginTop: "0rem",
                                    }}
                                  >
                                    OTP Expired
                                  </span>
                                ) : (
                                  <>
                                    {emailOTPError && (
                                      <span
                                        style={{
                                          fontSize: "0.75rem",
                                          color: "#d32f2f",
                                          marginTop: "0rem",
                                        }}
                                      >
                                        Invalid OTP
                                      </span>
                                    )}
                                  </>
                                )}
                              </Grid>
                              <Grid item xs={2}></Grid>
                            </>
                          )} */}
                        </Grid>
                      </div>
                    </Grid>
                  </Grid>
                  {/* {!verifyEmailOTP && !verifyPhoneOTP && (
                    <div
                      style={{ marginBottom: "0.5rem", fontSize: "0.85rem" }}
                    >
                      *OTP has been sent to your registered mobile number or
                      email please check junk and spam folder also.
                    </div>
                  )} */}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Button
                    >
                    </Button>
                    <LoadingButton
                      loading={createUserBtnLoader}
                    >
                      <Button
                        size="small"
                        className={styles.signupBtn}
                        variant="contained"
                        type="submit"
                      // disabled={checkDisabled()}
                      >
                        {userDetails && typeof userDetails !== "string" ? "Save changes" : "Create User"}
                      </Button>
                    </LoadingButton>
                  </div>
                </div>
              </Grid>
            </Grid>
          </form>
        </Box>
      </Modal>
    </div>
  );
};

// ----------------------------------------------------------------------------------

export default AddEditUserFormModal;
