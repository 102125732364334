import React, { useEffect, useState } from "react";

import { useParams } from "react-router-dom";
import { API } from "../../api-services";
import { Box, Skeleton } from "@mui/material";
import Workshoppdfmodule from "./Workshoppdfmodule";


export interface IWorkShopPDFViewerProps {
  setIsExpand:any;
  isExpand?:any
  apiCallWorkstationData:boolean;
  setApiCallWorkstationData:any;
}

const WorkShopPDFViewer = (props: IWorkShopPDFViewerProps) => {
  const { projectId, fileIndex,abbreviation, pageCount } = useParams<{
    projectId: string;
    fileIndex: any;
    abbreviation:any;
    pageCount: any;
  }>();
  const { setIsExpand,isExpand,apiCallWorkstationData,setApiCallWorkstationData}= props;
  const [pdfData, setpdfData] = useState<any>();
  const [pdfName, setPdfName] = useState<string>();
  const [pdfDataLength, setPdfDataLength] = useState<number>();
  const [pdfInfo,setPdfInfo] = useState<any>();
  const [apiCallAfterDelete,setApiCallAfterDelete] = useState<boolean>(false);

  useEffect(() => {
    if(fileIndex != 0){
    API.get("workshop/banner_fields/", { banner_data: projectId },0).then((res: any) => {
      setpdfData(res?.data?.data?.filter((item:any)=>item?.subsystem_name==abbreviation)[0]?.file_paths_read[fileIndex-1]?.file_link);
      setPdfName(res?.data?.data?.filter((item:any)=>item?.subsystem_name==abbreviation)[0]?.file_paths_read[fileIndex-1]?.file_name);
      setPdfDataLength(res?.data?.data?.filter((item:any)=>item?.subsystem_name==abbreviation)[0]?.file_paths_read?.length);
      setPdfInfo(res?.data?.data?.filter((item:any)=>item?.subsystem_name==abbreviation)[0]?.file_paths_read[fileIndex-1])
      });
    }
  }, [projectId, fileIndex, pageCount, abbreviation,apiCallAfterDelete]);

  return (
    <div>
      <Box>
      {fileIndex!=0 ?<Box sx={{ width: "100%", height: "100%",}}>
              {pdfData && <Workshoppdfmodule
                  file_paths_read={pdfData}
                  page={1}
                  pdfName={pdfName}
                  setIsExpand={setIsExpand}
                  isExpand={isExpand}
                  pdfDataLength={pdfDataLength}
                  apiCallWorkstationData={apiCallWorkstationData} 
                  setApiCallWorkstationData={setApiCallWorkstationData}
                  apiCallAfterDelete={apiCallAfterDelete}
                  setApiCallAfterDelete={setApiCallAfterDelete}
                  pdfInfo={pdfInfo}
                />}
            </Box> : 
            <Box>
            <div style={{height:'57rem',display:'flex',justifyContent:'center',alignItems:'center',fontSize:'1.5rem', fontWeight:'bold'}}>Please first select the PDF to view</div>
            </Box>}
      </Box>
    </div>
  );
};
export default WorkShopPDFViewer;
