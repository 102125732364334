import React, { memo, useEffect, useMemo, useRef, useState } from "react";
import SvgIcon, { SvgIconProps } from "@mui/material/SvgIcon";
import { styled } from "@mui/material/styles";
//@ts-ignore
import TreeView from "@mui/lab/TreeView";
//@ts-ignore
import TreeItem, { TreeItemProps, treeItemClasses } from "@mui/lab/TreeItem";
import Collapse from "@mui/material/Collapse";
import Paper from "@mui/material/Paper";
// web.cjs is required for IE11 support

import { TransitionProps } from "@mui/material/transitions";
import { animated, useSpring } from "@react-spring/web";
import { isNull, isUndefined, result, set } from "lodash";
import openFolder from "../../../Assets/images/svgs/openfolderblue.svg";
import FolderOpenIcon from "@mui/icons-material/FolderOpen";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import FolderIcon from "@mui/icons-material/Folder";
import styles from "./BOMTreeViewCreate.module.scss";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import CopyrightIcon from "@mui/icons-material/Copyright";
import {
  Box,
  Typography,
  MenuItem,
  Menu,
  CircularProgress,
} from "@mui/material";

import swal from "sweetalert";
import { useBaseModal } from "../../SearchPage/useBaseModal";
import { API } from "../../../api-services";
import { useDispatch, useSelector } from "react-redux";
import {
  setAddCompareCount,
  setIsCompare,
} from "../../../Redux/Actions/compareopen.action";
import { DialogComponent } from "../../../utlis/DialogComponent";
import { ControlledMenu } from "@szhsin/react-menu";
import BOMEntryAddBOMPartModal from "./BOMEntryAddBOMPartModal";
import BOMEntryCopytoOtherModal from "./BOMEntryCopytoOtherModal";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import WarningRoundedIcon from "@mui/icons-material/WarningRounded";
import AgentIcon from "../../../Assets/images/AgentIcon.svg";
import BOMEntryAddSystemModal from "./BOMEntryAddSystemModal";
import BOMEntryWeightAlertModal from "./BOMEntryWeightAlertModal";
import { useReactToPrint } from "react-to-print";
import Swal from "sweetalert2";
import successIcon from "../../../Assets/images/success.png";
import SheetViewModal from "./SheetViewModal";
import AgentModal from "./AgentModal";
import BOMCopyModal from "./BOMCopyModal";
import { Button } from "devextreme-react";
import BOMFlagModal from "./BOMFlagModal";
import FlagIcon from "@mui/icons-material/Flag";
import NestedMenuItem from "material-ui-nested-menu-item";
import MovePartModal from "./MovePartModal";

import { userSelectors } from "../../../Redux/Reducers/user.reducer";
import { userPermissionAction } from "./BOMEntryLandingPage";
function TransitionComponent(props: TransitionProps) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: "translate3d(20px,0,0)",
      display: "none",
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
      display: "block",
    },
  });

  return (
    <animated.div style={style} className="Tempdekho">
      <Collapse {...props} />
    </animated.div>
  );
}

const StyledTreeItem = styled((props: TreeItemProps) => (
  <TreeItem {...props} TransitionComponent={TransitionComponent} />
))(({ theme }) => ({
  [`& .${treeItemClasses.iconContainer}`]: {
    "& .close": {
      opacity: 0.3,
    },
  },
  [`& .${treeItemClasses.group}`]: {
    marginLeft: 10,
    paddingLeft: 8,
    borderLeft: `1px dashed ${theme.palette.text.primary}`,
  },
}));

interface TreeViewComponentsProps {
  HierarchyData: any;
  onSelectCallback: any;
  getTreeData: any;
  type:any;
  getTreeDataResponse?: any;
  // setParentsCounter?: any;
}
const APIRequestTreeView = (props: TreeViewComponentsProps) => {
  const { HierarchyData, onSelectCallback, getTreeData,type ,getTreeDataResponse} = props;
  const { projectId, productId, treeId, scenarioId,requestId } = useParams<any>();
  const { url } = useRouteMatch();
  const openStatusContent = useBaseModal();
  const [activeId, setActiveId] = React.useState<any>(null);
  const [contentText, setContentText] = React.useState<any>("");
  const [iconText, setIconText] = React.useState<any>("");
  const [HierarchyAllData, setHierarchyAllData] = React.useState<any>(null);
  const [loader, setLoader] = React.useState<any>(false);
  const [opendailog, setOpendailog] = React.useState<any>(false);
  const [TopVaultsfetched, setTopVaultsfetch] = useState<any>([]);
  const [menuItems, setMenuItems] = React.useState<any>();
  const [validatedModules, setValidatedModules] = React.useState<any>([]);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const [ModalData, setModalData] = useState<any>(null);
  const [PartAssemblyData, setPartAssemblyData] = React.useState<any>([]);
  const [newVault, setNewvault] = useState<any>();
  const [PartLoader, setPartLoader] = React.useState<any>(false);
  const imageIframeRef = useRef<any>(null);
  const [contentRef, setContentRef] = React.useState<any>();
  const sheetview = useBaseModal();
  const AddBOMPartModal = useBaseModal();
  const [QRLoader, setQRLoader] = useState<any>(false);
  const [SystemData, setSystemData] = useState([]);
  const movemodal = useBaseModal();
  const userAction = React.useContext(userPermissionAction);

  let ActionUser = userAction && userAction?.action;
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    idd: any,
    is_leaf: any,
    data: any
  ) => {
    event.preventDefault();
    event.stopPropagation();
    setModalData(data);
    sessionStorage.setItem("TargetIds", idd);
    setAnchorEl(event.currentTarget);
    // setAnchorPointBOMCreate({ x: event.clientX, y: event.clientY });
    // setOpenContext(true);
    // getMenuItems(event.currentTarget, idd, is_leaf, data?.abbreviation);
  };

  const [expanded, setExpanded] = React.useState<any>([]);
  const dispatch = useDispatch();
  var random: any = Math.floor(Math.random() * 1000000 + 1);
  const store: any = useSelector((state) => state);
  const [TopLevel, setTopLevel] = React.useState();
  const [titleString, setTitleString] = React.useState<any>("");
  const [selected, setSelected] = React.useState<any>([]);
  const history = useHistory<any>();
  const AgentDataModal = useBaseModal();
  const FlagModal = useBaseModal();
  const [copytype, setcopytype] = useState<any>();
  const [systemloader, setSystemLoader] = useState<any>(false);
  const [isProduct, setIsProduct] = useState<boolean>(false);
  const [routeLoader, setrouteLoader] = useState<any>(false);
  const [mastervalidationloader, setmastervalidationloader] =
    useState<any>(false);
  const copymodal = useBaseModal();
  const userProfilePic = useSelector(userSelectors.selectAll);
  const handleCompareClick = (abbr: any, idd: any) => {
    API.post(
      "/customer/compare_cart/",
      {
        treeId: parseInt(idd),
        project: projectId,
      },
      {},
      0
    )
      .then((res: any) => {
        sessionStorage.setItem("is_compare", "true");
        dispatch(setIsCompare("true"));
        dispatch(setAddCompareCount(store.is_compare?.add_count + 1));
      })
      .catch((err: any) => {
        if (err.response.status === 400) {
          // swal("Selection Exceeds",'warning');
          swal({
            // title: "Good job!",
            text: "Selection Exceeds!",
            icon: "warning",
            //@ts-ignore
            button: "OK",
          });
        }
      });
  };

  // useEffect(()=>{return sessionStorage.setItem("BOMCreateNode",JSON.stringify([Number(topVault)]));},[topVault])

  const handleChange = (e: any, nodeId: any, selectedData: any) => {
    let a: any = sessionStorage.getItem("APIRequestNode");
    let val: any = JSON.parse(a) || [];
    if (val?.includes(nodeId)) {
      let noDuplicate: any = val?.filter((item: any) => {
        return item !== nodeId;
      });
      let b: any = sessionStorage?.setItem(
        "APIRequestNode",
        JSON.stringify(noDuplicate)
      );
    } else {
      val?.push(nodeId);
      let b: any = sessionStorage?.setItem(
        "APIRequestNode",
        JSON.stringify(val)
      );
    }
    setActiveId(nodeId);

    onSelectCallback(nodeId, selectedData, selectedData.id);
    const index = expanded?.indexOf(nodeId);

    const copyExpanded = [...expanded];
    if (index === -1) {
      copyExpanded?.push(nodeId);
    } else {
      copyExpanded?.splice(index, 1);
    }
    setExpanded(copyExpanded);
    setSelected(copyExpanded);
  };

  useEffect(() => {
    let a: any = sessionStorage.getItem("APIRequestNode");
    let val: any = JSON.parse(a);
    if (val != null) {
      setExpanded(val);
      setSelected(val);
    }
  }, [productId, sessionStorage.getItem("APIRequestNode")]);

  useEffect(() => {
    setHierarchyAllData(HierarchyData && HierarchyData);
  }, [HierarchyData]);

  useEffect(() => {
    setTimeout(
      () =>
        document.getElementById(treeId)?.scrollIntoView({
          block: "nearest",
          inline: "start",
          behavior: "smooth",
        }),
      1000
    );
    // @ts-ignore
  }, [treeId]);

  const getColor = (status: any) => {
    switch (status) {
      case 0:
        return "red";
      case 1:
        return "#007fff";
      case 2:
        return "#007fff";
      default:
        return "green";
    }
  };

  const AddBOMPartHandler = () => {
    {
      // setPartLoader(true);
      AddBOMPartModal.open();
      setAnchorEl(null);
      // API.get("/api/db/part_name/", { treeId: ModalData?.id ,search:true}, 0)
      //   .then((res: any) => {
      //     setPartAssemblyData(res?.data);
      //     AddBOMPartModal.open();
      //     setAnchorEl(null);
      //     setPartLoader(false);
      //   })
      //   .catch((err: any) => {
      //     setPartLoader(false);
      //     console.log("Server Error");
      //   });
    }
  };

  const AddBOMPartHandlerSystem = (e: any, data: any) => {
    {
      e?.stopPropagation();
      // setSystemLoader(true);

      setModalData(data);
      AddBOMPartModal.open();
      // API.get("/api/db/part_name/", { treeId: data?.id }, 0)
      //   .then((res: any) => {
      //     setPartAssemblyData(res?.data);
      //     setSystemLoader(false);
      //     setModalData(data);
      //     AddBOMPartModal.open();
      //   })
      //   .catch((err: any) => {
      //     setSystemLoader(false);
      //     console.log("Server Error");
      //   });
    }
  };

  const CopytoOtherModal = useBaseModal();
  const CopytoOtherHandler = () => {
    CopytoOtherModal.open();
  };

  const AddSystemModal = useBaseModal();
  const AddSystemHandler = (e: any, data: any) => {
    e?.stopPropagation();

    setSystemLoader(true);

    API.get(
      `/api/db/sub_system/`,
      {
        project: projectId,
        vehicle_types: data?.vechile_type,
        top_vault: data?.id,
      },
      0
    )
      .then((res: any) => {
        setSystemData(res?.data);
        AddSystemModal.open();
        setSystemLoader(false);
        setModalData(data);
      })
      .catch((err: any) => {
        setSystemLoader(false);
        console.log("Server Error");
      });
  };

  React.useEffect(() => {
    setNewvault(sessionStorage?.getItem("newvault"));
    if (newVault !== null) {
      setTimeout(function () {
        setNewvault(null);
        sessionStorage.removeItem("newvault");
      }, 10000);
    }
  }, [newVault, sessionStorage?.getItem("newvault")]);

  const SucessAndErrorMsg = (type: any) => {
    if (type == true) {
      Swal.fire({
        html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
<br />
<p style="color:#007fff;"> System Added Successfully
</p>   
</div>`,
      });
    }
    if (type == false) {
      Swal.fire({
        icon: "error",
        html: `<div>
<br />
<p style="color:red;">Server Error
</p>   
 </div>`,
      });
    }
  };

  const SucessAndErrorMsgVault = () => {
    {
      Swal.fire({
        html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
<br />
<p style="color:#007fff;"> Part/Assembly Added Successfully
</p>   
</div>`,
      });
    }
  };

  return (
    <>
      {contentRef && (
        <>
          <Box className={styles.centeredContent}>
            <img
              ref={imageIframeRef}
              src={contentRef}
              height={200}
              width={200}
            />
          </Box>
        </>
      )}
      {/* {AddBOMPartModal.isOpen && (
        <BOMEntryAddBOMPartModal
          onCloseModal={AddBOMPartModal.close}
          isOpen={AddBOMPartModal.open}
          getData={ModalData}
          copypartmsg={copypartmsg}
          AssemblyData={PartAssemblyData}
          setParentsCounter={setParentsCounter}
          SucessAndErrorMsgVault={SucessAndErrorMsgVault}
        />
      )}

      {FlagModal.isOpen && (
        <BOMFlagModal
          onCloseModal={FlagModal.close}
          isOpen={FlagModal.open}
          getData={ModalData}
        />
      )}
      {AgentDataModal?.isOpen && (
        <AgentModal
          onCloseModal={AgentDataModal.close}
          isOpen={AgentDataModal.open}
          getData={ModalData}
          isProduct={isProduct}
        />
      )}

      {sheetview?.isOpen && (
        <SheetViewModal
          onCloseModal={sheetview.close}
          isOpen={sheetview.open}
          getData={ModalData}
        />
      )}

      {CopytoOtherModal.isOpen && (
        <BOMEntryCopytoOtherModal
          onCloseModal={CopytoOtherModal.close}
          isOpen={CopytoOtherModal.open}
          getData={ModalData}
          setParentsCounter={setParentsCounter}
          SucessAndCoptpartToOther={SucessAndCoptpartToOther}
        />
      )}

      {AddSystemModal.isOpen && (
        <BOMEntryAddSystemModal
          onCloseModal={AddSystemModal.close}
          isOpen={AddSystemModal.open}
          getData={ModalData}
          SystemData={SystemData}
          SucessAndErrorMsg={SucessAndErrorMsg}
          setParentsCounter={setParentsCounter}
        />
      )}

      {WeightAlertModal.isOpen && (
        <BOMEntryWeightAlertModal
          onCloseModal={WeightAlertModal.close}
          isOpen={WeightAlertModal.open}
          queryData={ModalData}
        />
      )}
      {copymodal.isOpen && (
        <BOMCopyModal
          onCloseModal={copymodal.close}
          isOpen={copymodal.open}
          getData={ModalData}
          copytype={copytype}
          SucessAndCoptpart={SucessAndCoptpart}
          setParentsCounter={setParentsCounter}
        />
      )}

      {movemodal.isOpen && (
        <MovePartModal
          onCloseModal={movemodal.close}
          isOpen={movemodal.open}
          getData={ModalData}
          SucessAndMovepart={SucessAndMovepart}
          setParentsCounter={setParentsCounter}
        />
      )} */}

      <TreeView
        expanded={expanded}
        key={HierarchyAllData?.id}
        id={HierarchyAllData?.id}
        defaultParentIcon={<FolderIcon sx={{ color: "blue" }} />}
        className={styles.treetemp}
        defaultCollapseIcon={
          HierarchyAllData &&
          HierarchyAllData?.is_leaf == false && (
            <FolderOpenIcon style={{ color: "blue" }} />
          )
        }
        defaultExpandIcon={
          HierarchyAllData?.is_leaf == false && (
            <FolderIcon sx={{ color: "blue" }} />
          )
        }
        defaultEndIcon={
          HierarchyAllData?.is_leaf ? (
            <InsertDriveFileIcon sx={{ color: "blue" }} />
          ) : (
            <FolderIcon sx={{ color: "blue" }} />
          )
        }
        onNodeSelect={(e: any) =>
          handleChange(e, HierarchyAllData?.id, HierarchyAllData)
        }
        selected={selected}
        aria-controls={open ? "basic-menu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        sx={{
          "&.MuiTreeItem-content{}.Mui-selected": {
            backgroundColor:
              HierarchyAllData?.id == parseInt(treeId)
                ? "#cee6fa !important"
                : "white !important",
          },
        }}
      >
        <StyledTreeItem
          data-index={HierarchyAllData?.id}
          nodeId={HierarchyAllData?.id}
          id={HierarchyAllData?.id}
          // onMouseEnter={() =>
          //   hoverData(
          //     `${HierarchyAllData?.part_no}  ${HierarchyAllData?.name}`,
          //     HierarchyAllData.id
          //   )
          // }
          //   title={titleString && url.includes("costing") && titleString}
          label={
            <Typography className={styles.treeViewData}>
              <Typography
                style={{
                  fontSize: "12px",
                  //   color: getColor(HierarchyAllData?.status),
                }}
                className={styles.treeViewText}
              >
                {HierarchyAllData?.name}
              </Typography>

              {newVault == undefined || newVault == null ? (
                ""
              ) : (
                <>
                  {newVault == HierarchyAllData?.id && (
                    <span className={styles.blink}>New</span>
                  )}
                </>
              )}
              <Typography className={styles.treeViewText}>
                {
                  <>
                    <>
                      <AddCircleOutlineIcon
                        onClick={(e: any) => {
                          e.stopPropagation();
                          API.post(
                            "/xcpep/api_user_structure_view/",
                            {
                              parent_id: HierarchyAllData?.parent_id,
                              level: HierarchyAllData?.level,
                              is_leaf: HierarchyAllData?.is_leaf,
                              object_type: HierarchyAllData?.object_type,
                              value_type: HierarchyAllData?.value_type,
                              name: HierarchyAllData?.name,
                              request_data:type === "Request" ?true:undefined,
                              response_data: type === "Response" ?true:undefined,
                              id: +requestId,
                              tree_id: HierarchyAllData?.id,
                            },
                            {},
                            0
                          )
                            .then((res: any) => {
                              getTreeData();
                            })
                            .catch((err: any) => {});
                        }}
                      />
                    </>
                  </>
                }
              </Typography>
            </Typography>
          }
        >
          {HierarchyAllData &&
            HierarchyAllData?.data?.map((subsystemData: any, index: any) => {
              return (
                <>
                  <APIRequestTreeView
                    HierarchyData={subsystemData}
                    key={subsystemData?.id}
                    onSelectCallback={onSelectCallback}
                    getTreeData={getTreeData}
                    type={type}
                    getTreeDataResponse={getTreeDataResponse}
                    // setParentsCounter={setParentsCounter}
                  />
                </>
              );
            })}
        </StyledTreeItem>
      </TreeView>
      <DialogComponent
        isOpen={openStatusContent.isOpen}
        onClose={openStatusContent.close}
        content={contentText}
        type={iconText}
      />
    </>
  );
};

export default APIRequestTreeView;
