import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CancelIcon from '@mui/icons-material/Cancel';
import { Checkbox, Chip, Divider, FormControlLabel, IconButton } from '@mui/material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import { useRouteParams } from '../../ui-reusable-component/useRouteParams';
import { AxiosResponse } from 'axios';
import { useContext, useEffect, useRef, useState } from 'react';
import { API } from '../../api-services';
import { MyContext } from './MediaCreateLanding';
import { useAppDispatch } from '../../../AppRouter';
import { useBaseModal } from '../SearchPage/useBaseModal';
import MediaAddSingleImageModel from './MediaAddSingleImageModel';
import { createRepoImage, updateRepoImage } from '../../Redux/Actions/imageRepository.actions';
import mediaSingleUploadFromGroup from './MediaSingleUploadFromGroup';
import { isUndefined } from 'lodash';
import Image from "../../ViewModule/FeatureLandingPage/ArchitectureImageCard";
import NoImgLoader from "../../Assets/images/loading-screen.gif";
import { useHistory } from 'react-router-dom';
import CameraIcon from '@mui/icons-material/Camera';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { LoadingButton } from '@mui/lab';
import VideoIcon from '../../Assets/images/VideoIcon.svg';
import MediaAddSingleVideoModel from './MediaAddSingleVideoModel';

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '80vw',
    height: '90vh',
    bgcolor: 'background.paper',
    borderRadius: '10px',
    p: 1,
};



export interface IMediaMobileImageCaptureProps {
    onCloseModal: any;
    isOpen: any;
    sendNextModelGroupId: any;
    categoryGet: any;
    abbreviationGet: any;
    sequenceSend: any;
    type: any;
    topVaultIdImageSection?: any;
    onCloseModalGroupName?: any;
}

export function MediaMobileImageCapture(props: IMediaMobileImageCaptureProps) {
    const { onCloseModal, isOpen, sendNextModelGroupId, onCloseModalGroupName, categoryGet, abbreviationGet, sequenceSend, type, topVaultIdImageSection } = props;
    const { projectId, projectName, mediaType, productList, pageNumber, apiCall } = useRouteParams<any>();
    const [productData, setProductData] = useState<any>([]);
    const [currentProductData, setCurrentProductData] = useState<any>([]);
    const [productSelected, setProductSelected] = useState<any>();
    const [sequence, setSequence] = useState<any>();
    const [isUpdating, setIsUpdating] = useState<any>(false);
    const [vaultId, setVaultId] = useState<any>();
    const [imageDetails, setImageDetails] = useState<any>();
    const [imageDetailApiCallCounter, setImageDetailApiCallCounter] = useState<number>(0);
    const [pagenationCount, setPagenationCount] = useState<any>();
    const [topVaultPagenation, setTopVaultPagenation] = useState<any>();
    const [checked, setChecked] = useState<boolean>(false);

    const history = useHistory();
    const uploadmodal = useBaseModal();
    const uploadmodalVideo = useBaseModal();
    const { callGroupAPI, setCallGroupAPI, callGroupNameAPI, setCallGroupNameAPI, permissionAccess } = useContext(MyContext);
    const dispatch = useAppDispatch();

    useEffect(() => {
        setSequence(sequenceSend);
        type == "imageSingleUpload" && setProductSelected(topVaultIdImageSection)
    }, [sequenceSend, (type == 'imageSingleUpload' && topVaultIdImageSection)])

    useEffect(() => {
        API.get(
            "/image_repository/media_module/",
            {
                project: projectId,
                media_type: mediaType,
                image_category: categoryGet,
                abbreviation: abbreviationGet,
                download_image_count: true,
                group_id: sendNextModelGroupId,
                sequence: sequenceSend
            },
            0
        ).then((res: AxiosResponse) => {
            setProductData(res?.data);
            setVaultId(res?.data?.[0]?.vault_id);
        });
    }, [productSelected])

    useEffect(() => {
        API.get(
            "/image_repository/media_module/",
            {
                project: projectId,
                media_type: mediaType,
                image_category: categoryGet,
                abbreviation: abbreviationGet,
                download_image_count: true,
                group_id: sendNextModelGroupId,
                sequence: sequenceSend
            },
            0
        ).then((res: AxiosResponse) => {
            setCurrentProductData(res?.data?.[0]);
            setProductSelected(res?.data?.[0]?.top_vault_id)
        });
    }, [])

    useEffect(() => {
        setImageDetails(undefined);
        if (productSelected) {
            API.get(
                "/image_repository/media_group_upload/",
                {
                    top_vault: productSelected,
                    abbreviation: abbreviationGet,
                    image_category: categoryGet,
                    sequence: sequence,
                    group_id: sendNextModelGroupId,
                    media_type: mediaType,
                    current_sequence: true
                },
                0
            ).then((res: AxiosResponse) => {
                setImageDetails(res?.data);
            });

            API.get(
                "/image_repository/media_product_pagenation/",
                {
                    top_vaults: productSelected,
                    media_type: mediaType,
                    group_id: sendNextModelGroupId,
                    abbreviation: abbreviationGet,
                    project: projectId,
                    single_topvault_check: true
                },
                0
            ).then((res: AxiosResponse) => {
                setTopVaultPagenation(res?.data);
            });

            API.get(
                "/image_repository/media_group_upload/",
                {
                    project_id: projectId,
                    abbreviation: abbreviationGet,
                    image_category: categoryGet,
                    group_id: sendNextModelGroupId,
                    media_type: mediaType,
                    max_sequence: true
                },
                0
            ).then((res: AxiosResponse) => {
                setPagenationCount(res?.data);
            });
        }
    }, [productSelected, imageDetailApiCallCounter, sequence])

    //-------------------image upload--------------------------------------------------------

    const saveImage = async (file: File, id: number) => {
        setIsUpdating(true);
        if (imageDetails && imageDetails?.length > 0) {
            if (id == 1) {
                mediaSingleUploadFromGroup(
                    [file],
                    sendNextModelGroupId,
                    productSelected,
                    abbreviationGet == '0' ? null : vaultId,
                    dispatch,
                    setIsUpdating,
                    mediaType,
                    uploadmodal,
                    imageDetails && imageDetails?.[0]?.id,
                    "update",
                    categoryGet,
                    sequence,
                    setImageDetailApiCallCounter
                );
            } else {
                await dispatch(
                    updateRepoImage({
                        id: imageDetails && imageDetails?.[0]?.id,
                        image: file,
                        rgb: true,
                        media_type: mediaType,
                        group: sendNextModelGroupId,
                    })
                ).then(() => {
                    uploadmodal.close();
                    setIsUpdating(false);
                    setImageDetailApiCallCounter((prev: any) => prev + 1);
                });
            }
        }
        else {
            if (id == 1) {
                mediaSingleUploadFromGroup(
                    [file],
                    sendNextModelGroupId,
                    productSelected,
                    abbreviationGet == '0' ? null : vaultId,
                    dispatch,
                    setIsUpdating,
                    mediaType,
                    uploadmodal,
                    null,
                    'create',
                    categoryGet,
                    sequence,
                    setImageDetailApiCallCounter
                );
            } else {
                dispatch(
                    createRepoImage({
                        image: file,
                        image_category: categoryGet,
                        sequence,
                        top_vault: productSelected,
                        vault: abbreviationGet == '0' ? null : vaultId,
                        rgb: true,
                        media_type: mediaType,
                        group: sendNextModelGroupId,
                    })
                ).then((res: any) => {
                    uploadmodal.close();
                    setIsUpdating(false);
                    setImageDetailApiCallCounter((prev: any) => prev + 1);
                });
            }
        }
    };

    const onCloseModalHandler = () => {
        setCallGroupAPI(!callGroupAPI);
        setCallGroupNameAPI(!callGroupNameAPI);
        type == "imageGroupSideUpload" ? history.push(
            `/mediacreateinternal/${projectId}/${projectName}/${mediaType}/${abbreviationGet}/${categoryGet}/${sendNextModelGroupId}/${productList}/1/${apiCall == "true" ? "false" : "true"}`
        ) :
            history.push(
                `/mediacreateinternal/${projectId}/${projectName}/${mediaType}/${abbreviationGet}/${categoryGet}/${sendNextModelGroupId}/${productList}/${pageNumber}/${apiCall == "true" ? "false" : "true"}`
            )
        type == "imageGroupSideUpload" && onCloseModalGroupName();
        onCloseModal();
    }

    const browseFiles = () => {
        uploadmodal.open();
    };

    const browseVideo = () => {
        uploadmodalVideo.open();
    }

   //--------------- video upload------------------------------------------------------

  const saveVideo  = async (file: File, id: number) => {
    if (isUndefined(file)) return;
    setIsUpdating(true);
    if (imageDetails && imageDetails?.length > 0) {
      mediaSingleUploadFromGroup(
        [file],
        sendNextModelGroupId,
        productSelected,
        abbreviationGet == "0" ? null : vaultId,
        dispatch,
        setIsUpdating,
        mediaType,
        uploadmodalVideo,
        imageDetails && imageDetails?.[0]?.id,
        "update",
        categoryGet,
        sequence,
        setImageDetailApiCallCounter,
        id == 1 ? false : true
      );
    } else {
      mediaSingleUploadFromGroup(
        [file],
        sendNextModelGroupId,
        productSelected,
        abbreviationGet == "0" ? null : vaultId,
        dispatch,
        setIsUpdating,
        mediaType,
        uploadmodalVideo,
        null,
        "create",
        categoryGet,
        sequence,
        setImageDetailApiCallCounter,
        id == 1 ? false : true
      );
    }
  };

    const handleChangeNaBox = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(event.target.checked);
        API.get(
            "/image_repository/media_group_upload/",
            {
                top_vault: productSelected?.toString(),
                abbreviation: abbreviationGet,
                image_category: categoryGet,
                sequence: sequence,
                group_id: sendNextModelGroupId,
                media_type: mediaType,
                upload_na: true
            },
            0
        ).then((res: AxiosResponse) => {
            setChecked(false);
            setImageDetailApiCallCounter((prev: any) => prev + 1);
        });
    };

    return (
        <div>

            <Modal
                open={isOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem', justifyContent: 'space-between' }}>

                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                            {!checked && <>
                                <FormControlLabel control={<Checkbox
                                    sx={{ color: 'primary.main', fontSize: '1rem', }}
                                    checked={checked}
                                    onChange={handleChangeNaBox} />}
                                    label={<Typography sx={{ fontSize: '1rem', lineHeight: 1, }}>Click if not available in this product</Typography>} />
                            </>}
                            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModalHandler} />
                        </Box>
                    </Box>
                    <Divider
                        sx={{
                            borderColor: "primary.light",
                            margin: "0 1rem",
                            marginBottom: "1rem",
                        }}
                    />
                    <Box sx={{ display: "flex", alignItems: "center", marginBottom: "0.2rem", width: "100%", justifyContent: 'space-between', padding: "0.2rem 0" }}>
                        <Box>{topVaultPagenation && <LoadingButton disabled={!(topVaultPagenation && topVaultPagenation?.previous)} onClick={() => { return setProductSelected(topVaultPagenation?.pre_top_vault?.[0]),setCurrentProductData(productData?.filter((item:any)=> item?.top_vault_id == topVaultPagenation?.pre_top_vault?.[0])?.[0])}}><ChevronLeftIcon /></LoadingButton>}</Box>
                        <Typography sx={{ fontSize: '1rem' }}>{currentProductData?.name}</Typography>
                        <Box>{topVaultPagenation && <LoadingButton disabled={!(topVaultPagenation && topVaultPagenation?.next)} onClick={() => { return setProductSelected(topVaultPagenation?.next_top_vault?.[0]),setCurrentProductData(productData?.filter((item:any)=> item?.top_vault_id == topVaultPagenation?.next_top_vault?.[0])?.[0])}}><ChevronRightIcon /></LoadingButton>} </Box>
                    </Box>
                    <Box sx={{ marginBottom: '-2rem', marginTop: '0.5rem', display: 'flex', alignItems: 'center' }}>
                        <span style={{ marginRight: '1rem', marginLeft: '0.5rem' }}>  Sequence : </span><Box sx={{ padding: '0.3rem 0.6rem', borderRadius: '50%', border: '1px solid', width: '2rem', height: '2rem', display: 'inline-block' }}>{sequence}</Box>
                    </Box>
                    <Box sx={imageDetails && imageDetails?.length > 0 ? { display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', width: '100%', height: '71vh' } : { width: '100%', height: '71vh', }}>
                        <Box sx={imageDetails && imageDetails?.length > 0 ? { display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '55vh' } : { display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '72vh' }}>
                            {imageDetails && imageDetails?.length > 0 ? <Image
                                src={imageDetails && imageDetails[0]?.image_url_cdn}
                                placeholderImg={NoImgLoader}
                                alt={imageDetails && imageDetails[0]?.image_name}
                                style={{ height: "auto", width: "100%" }}
                            /> :
                                <>{mediaType == "image" ? <AddAPhotoIcon sx={{ fontSize: '12rem', cursor: 'pointer' }} onClick={browseFiles} />
                                    : <img src={VideoIcon} style={{ width: '12rem', cursor: 'pointer' }} onClick={browseVideo} />}
                                </>}
                        </Box>
                        {imageDetails && imageDetails?.length > 0 && permissionAccess && permissionAccess?.filter((item:any)=>item?.top_vault == 'all')?.[0]?.action?.includes('U') && <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column' }}>
                            <CameraIcon />
                            <Typography onClick={mediaType == "image" ? browseFiles : browseVideo} >Click to update Media</Typography>
                        </Box>}
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%', }}>
                        {sequence == 1 ? <span></span> : <Button style={sequence != 1 ? { cursor: 'pointer' } : { cursor: 'not-allowed', color: 'grey' }} onClick={() => sequence != 1 ? setSequence((prev: any) => prev - 1) : ""}>Previous ({sequence})</Button>}
                        {pagenationCount?.max_sequence != 1 && <Button style={imageDetails && imageDetails?.length > 0 ? { cursor: 'pointer' } : { cursor: 'not-allowed', color: 'grey' }} onClick={() => imageDetails && imageDetails?.length > 0 ? setSequence((prev: any) => prev + 1) : ''}>Next ({pagenationCount?.max_sequence})</Button>}
                    </Box>
                    <MediaAddSingleImageModel
                        isOpen={uploadmodal.isOpen}
                        onCloseModal={uploadmodal.close}
                        saveImage={saveImage}
                        isUpdating={isUpdating}
                        type={"mobile"}
                    />
                    <MediaAddSingleVideoModel
                      isOpen={uploadmodalVideo.isOpen}
                      onCloseModal={uploadmodalVideo.close}
                      saveVideo={saveVideo}
                      isUpdating={isUpdating}
                      type={'mobile'}
                    />
                </Box>
            </Modal>
        </div>
    );
}
