import { Box, Chip, FormControl, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material";
import * as React from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { API } from "../../api-services";
import { debounce } from "lodash";
import { useDispatch } from "react-redux";
import { increaseCounter, validationCheckCounter } from "../../Redux/Actions/compareopen.action";
import Autocomplete from '@mui/material/Autocomplete';

export interface IParamsMultiselectProps {
  item: any;
  getFeatureData: any;
  disabled?: any;
  productCount?: any;
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export function ParamsMultiselect(props: IParamsMultiselectProps) {
  const { item, getFeatureData, disabled,productCount } = props;
  const dispatch = useDispatch();
  const [selectedParams, setSelectedParams] = React.useState<any>([]);
  const [loader, setLoader] = React.useState<any>(false);
  const [selectedAssembly, setSelectedAssembly] = React.useState<any>([]);
  const [selectedParts, setSelectedParts] = React.useState<any>([]);
  const [options, setOptions] = React.useState<any>([]);
  const [listData, setListData] = React.useState<any>({});
  const [optionsAssembly, setOptionsAssembly] = React.useState<any>([]);
  const [optionsParts, setOptionsParts] = React.useState<any>([]);

  // console.log(item,productCount, "itemitem");
  const ExactDebounce = React.useRef(
    debounce(async (value: any) => {
      await API.put(`/xcpep/feature_details/${item?.itemId}/`, { system: value }, {}, 0)
        .then((res: any) => {
          // var randomm: any = Math.floor(Math.random() * 1000000 + 1);
          // dispatch(validationCheckCounter(randomm + 1));
          var random: any = Math.floor(Math.random() * 1000000 + 1);
          dispatch(increaseCounter(random));
          dispatch(validationCheckCounter(random + 1));
        })
        .catch((err: any) => { });
    }, 1000)
  ).current;

  const ExactDebounceAssembly = React.useRef(
    debounce(async (value: any) => {
      await API.put(`/xcpep/feature_details/${item?.itemId}/`, { assembly: value }, {}, 0)
        .then((res: any) => {
          var random: any = Math.floor(Math.random() * 1000000 + 1);
          dispatch(increaseCounter(random));
          dispatch(validationCheckCounter(random + 1));
        })
        .catch((err: any) => { });
    }, 1000)
  ).current;

  const ExactDebounceParts = React.useRef(
    debounce(async (value: any) => {
      await API.put(`/xcpep/feature_details/${item?.itemId}/`, { part: value }, {}, 0)
        .then((res: any) => {
          var random: any = Math.floor(Math.random() * 1000000 + 1);
          dispatch(increaseCounter(random));
          dispatch(validationCheckCounter(random + 1));
        })
        .catch((err: any) => { });
    }, 1000)
  ).current;

  React.useEffect(() => {
    return () => {
      ExactDebounce.cancel(); // Cancel the debounced function
      ExactDebounceAssembly.cancel(); // Cancel the debounced function
      ExactDebounceParts.cancel(); // Cancel the debounced function
    };
  }, []);
  const handleSelectChangParams = (event: any, newSystemValue: any) => {
    setSelectedParams(newSystemValue);
    let newSelectedSystem = newSystemValue.map((item: any) => item.id);
    ExactDebounce(newSelectedSystem);
  };

  const handleSelectChangParamsAssemblyAuto = (event: any, newSystemParaValue: any) => {
    setSelectedAssembly(newSystemParaValue);
    let newSelectedAssemblies = newSystemParaValue.map((item: any) => item.id);
    ExactDebounceAssembly(newSelectedAssemblies);
  };

  const handleSelectChangParamsAssemblyPart = (event: any, newSystemParaPartValue: any) => {
    setSelectedParts(newSystemParaPartValue);
    let newSelectedAssembliesPart = newSystemParaPartValue.map((item: any) => item.id);
    ExactDebounceParts(newSelectedAssembliesPart);
  };


  // const handleSelectChangParams = (event: SelectChangeEvent<any>) => {
  //   const { value } = event.target;
  //   setSelectedParams(value);
  //   ExactDebounce(value);
  // };

  // const handleSelectChangParamsAssembly = (event: SelectChangeEvent<any>) => {
  //   const { value } = event.target;
  //   setSelectedAssembly(value);
  //   ExactDebounceAssembly(value);
  // };
  // const handleSelectChangParamsParts = (event: SelectChangeEvent<any>) => {
  //   const { value } = event.target;
  //   setSelectedParts(value);
  //   ExactDebounceParts(value);
  // };

  React.useEffect(() => {
    setLoader(true);
    if (item) {
      API.get(
        "/xcpep/feature_costing/",
        {
          product: item?.productId,
          type: "system",
          system:item?.values?.length>0? item?.values?.toString() : undefined,
          assembly: item?.values?.length > 0 ? item?.values?.toString() : undefined,
        },
        0
      )
        .then((res: any) => {

          if (item?.type === "system") {
            res.data && setSelectedParams(res.data?.subsystem?.filter((item1: any) => item?.values?.includes(item1.id)));
          } else if (item?.type === "assambly") {
            res.data && setSelectedAssembly(res.data?.assambly?.filter((item1: any) => item?.values?.includes(item1.id)));
          } else if (item?.type === "parts") {
            res.data && setSelectedParts(res.data?.parts?.filter((item1: any) => item?.values?.includes(item1.id)));
          }
          setLoader(false);


        }).catch((err: any) => {
          setLoader(false);
        })
    }
  }, [item]);

  const getOptions = () => {
    API.get(
      "/xcpep/feature_costing/",
      {
        product: item?.productId,
        type: "system",
        system: selectedParams?.length > 0 ? selectedParams?.map((item: any) => item.id)?.toString() : undefined,
        assembly: selectedAssembly?.length > 0 ? selectedAssembly?.map((item: any) => item.id)?.toString() : undefined,
      },
      0
    )
      .then((res: any) => {
        setListData(res.data)
        if (item?.type === "system") {
          setOptions(res?.data?.subsystem);
          // console.log(res.data?.subsystem, "sdkksjdhkjdsh")
        } else if (item?.type === "assambly") {
          setOptionsAssembly(res?.data?.assambly);
        } else if (item?.type === "parts") {
          setOptionsParts(res?.data?.parts);
        }
      })
      .catch((err: any) => { });
  };
 

  React.useEffect(() => {
    if (item) {
      getOptions();
    }
  }, []);

  return (
    <div>
      {item?.type === "system" && (
        <FormControl
          fullWidth
          variant="standard">


          <Autocomplete
            multiple
            size="small"
            defaultValue={selectedParams}
            id="size-small-standard"
            disableClearable
            disableCloseOnSelect
            loading={loader}

            options={options}
            limitTags={1}
            ListboxProps={{
              style: { fontSize: '1rem' }
            }}
            sx={{
              '.MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator': {
                color: 'primary.main'
              },
              '.MuiButtonBase-root.MuiChip-root': {
                backgroundColor: 'primary.light',
              },
              '.MuiChip-label': {
                width: productCount >=4 ? {lg:'6rem',xl:'11rem'} : productCount ===3 ? {lg:'13rem',xl:'19rem'} : 'auto'
              },
            }}
            getOptionLabel={(option: any) => option.name}
            //  defaultValue={[top100Films[13]]}
            onChange={(event: any, newSystemValue: any) => {
              handleSelectChangParams(event, newSystemValue);
            }}
            value={selectedParams}
            renderTags={(value: any[], getTagProps: any) =>
              value.map((option, index) => (
                <Chip
                size="small"
                key={index}
                label={option?.name}
                {...getTagProps({ index })}
                title={option?.name} // Set title attribute with full text content
                sx={{ backgroundColor: 'primary.light' }} // Update chip styles as needed
                />
              ))
              }
            renderInput={(params) => (
              <TextField
                {...params}
                variant="standard"
                placeholder="Select System"
                sx={{
                  ".MuiInputBase-root.MuiInput-root": {
                    fontSize: '1rem !important'
                  },
                  '& .MuiInput-underline:before': { borderBottomColor: 'transparent' },
                  '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                }}
              />
            )}
          />

        </FormControl>
      )}
      {item.type === "assambly" && (
        <FormControl
          fullWidth
          variant="standard">
         
          {
            <Autocomplete
              multiple
              size="small"
              loading={loader}
              disableClearable
              disableCloseOnSelect
              id="size-small-standard"
              options={optionsAssembly}
              limitTags={1}
              ListboxProps={{
                style: { fontSize: '1rem' }
              }}
              sx={{
                '.MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator': {
                  color: 'primary.main'
                },
                '.MuiButtonBase-root.MuiChip-root': {
                  backgroundColor: 'primary.light',
                },
                '.MuiChip-label': {
                  width: productCount >=4 ? {lg:'6rem',xl:'11rem'} : productCount ===3 ? {lg:'13rem',xl:'19rem'} : 'auto'
                },
              }}
              getOptionLabel={(optionsAssembly) => optionsAssembly.name}
              //  defaultValue={[top100Films[13]]}            
              onChange={(event: any, newSystemParaValue: any) => {
                handleSelectChangParamsAssemblyAuto(event, newSystemParaValue);
              }}
              value={selectedAssembly}
              renderTags={(value: any[], getTagProps: any) =>
                value.map((optionsAssembly, index) => (
                  <Chip
                  size="small"
                  key={index}
                  label={optionsAssembly.name}
                  {...getTagProps({ index })}
                  title={optionsAssembly.name} // Set title attribute with full text content
                  sx={{ backgroundColor: 'primary.light' }} // Update chip styles as needed
                  />
                ))
                }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  placeholder="Select Assembly"
                  sx={{
                    ".MuiInputBase-root.MuiInput-root": {
                      fontSize: '1rem !important'
                    },
                    '& .MuiInput-underline:before': { borderBottomColor: 'transparent' },
                    '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                  }}
                />
              )}
            />

          }
        </FormControl>
      )}
      {item?.type === "parts" && (
        <FormControl
          fullWidth
          variant="standard">
         
          {selectedParts && (      

            <Autocomplete
              multiple
              size="small"
              loading={loader}
              id="size-small-standard"
              options={optionsParts}
              disableClearable
              disableCloseOnSelect
              limitTags={1}
              ListboxProps={{
                style: { fontSize: '1rem' }
              }}
              sx={{
                '.MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator': {
                  color: 'primary.main'
                },
                '.MuiButtonBase-root.MuiChip-root': {
                  backgroundColor: 'primary.light',
                },
                '.MuiChip-label': {
                  width: productCount >=4 ? {lg:'6rem',xl:'11rem'} : productCount ===3 ? {lg:'13rem',xl:'19rem'} : 'auto'
                },
              }}
              getOptionLabel={(optionsParts) => optionsParts.name}
              //  defaultValue={[top100Films[13]]}            
              onChange={(event: any, newSystemParaPartValue: any) => {
                handleSelectChangParamsAssemblyPart(event, newSystemParaPartValue);
              }}
              value={selectedParts}
              renderTags={(value: any[], getTagProps: any) =>
                value.map((optionsParts, index) => (
                  <Chip
                  size="small"
                  key={index}
                  label={optionsParts.name}
                  {...getTagProps({ index })}
                  title={optionsParts.name} // Set title attribute with full text content
                  sx={{ backgroundColor: 'primary.light' }} // Update chip styles as needed
                  />
                ))
                }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  placeholder="Select Parts"
                  sx={{
                    ".MuiInputBase-root.MuiInput-root": {
                      fontSize: '1rem !important'
                    },
                    '& .MuiInput-underline:before': { borderBottomColor: 'transparent' },
                    '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                  }}
                />
              )}
            />

          )}
        </FormControl>
      )}
    </div>
  );
}
