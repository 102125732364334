import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import Swal from "sweetalert2";
import { Box, Button, Divider, FormControl, Skeleton, TextField, Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import { API } from "../../../api-services";
import React, { memo, useState } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { LoadingButton } from "@mui/lab";
import { set } from "lodash";
import { ADMIN } from "../../../Redux/Services/admin.service";


const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: { lg: "35vw", xl: "30vw" },
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;


// @ts-ignore

const useStyles = makeStyles((theme) => ({
  /** Changed modalStyle */
  ModalRoot: {
    backgroundColor: "rgba(111, 126, 140, 0.2) !important",
    backdropFilter: "blur(1px) !important",
  },
}));


export interface EditCombinationNameModalProps {
  isOpen: any;
  onCloseModal: any;
  rowId: any;
  editName: any;
  getCategoryData: any;
  categorySelected:any
}

const EditCombinationNameModal = (props: EditCombinationNameModalProps) => {
  const { isOpen, onCloseModal, rowId, editName, getCategoryData, categorySelected} = props;
  const { abbrevID } = useRouteParams<any>();
  const classes = useStyles();
    const [name, setName] = useState<any>(editName);
    const [loader, setLoader] = useState(false);
    const handleChangeName = (e:any) => {
        setName(e.target.value);
    }

    const handleSubmit = () => {
        setLoader(true);
        API.put(`/idea/combination/${rowId}/`,{
            name: name
        },{},0).then((res:any)=>{
            onCloseModal();
            setLoader(false);
            setName(null);
            getCategoryData(categorySelected, abbrevID);
            ADMIN.toast.success("Combination Name Updated Successfully")
        }).catch((err:any)=>{
            setLoader(false);
            onCloseModal();
            Swal.fire({
                icon: "error",
                html: `<div>
                <br />
                <p style="color:"red">${err.response}</p>   
                 </div>`,
              });
        })
    }

  return (
    <div>
      <Modal
        open={isOpen}
        className={classes.ModalRoot}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}><Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Edit Combination Name
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{padding:'0 0.5rem'}}>
          
              
             <FormControl fullWidth>
                <TextField
                  value={name}
                  onChange={(e: any) => handleChangeName(e)}
                  variant="standard"
                  type="text"
                  required
                  InputLabelProps={{
                    shrink: true,
                    style:{
                    color:'#007fff'
                    }
                  }}
                  label="Name"
                  placeholder="Name"
                  sx={{
                    ".MuiInputBase-root.MuiInput-root": {
                      fontSize: "1rem !important",
                    },
                    "& .MuiInput-underline:before": {
                      borderBottomColor: "primary.main",
                    },
                    "& .MuiInput-underline:after": {
                      borderBottomColor: "primary.main",
                    },
                  }}
                />
              </FormControl>
              
              <Box sx={{display: "flex", justifyContent: "flex-end", width:"100%", marginTop:"1rem"}}>
              <LoadingButton size="small" disabled={name == editName} loading={loader}  variant="contained" onClick={() => handleSubmit()}>Update</LoadingButton>
            </Box>
          </Box>
          </Box>
      </Modal>
    </div>
  );
};

export default EditCombinationNameModal;
