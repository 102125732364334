import React, { useEffect, useState } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  Divider,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CostingCreateSystemInsideBodyRow from "./CostingCreateSystemInsideBodyRow";
import { API } from "../../api-services";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { DialogComponent } from "../../utlis/DialogComponent";
import { useBaseModal } from "../SearchPage/useBaseModal";
import InfoIcon from "@mui/icons-material/Info";
import CancelIcon from "@mui/icons-material/Cancel";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { LoadingButton } from "@mui/lab";
import Swal from "sweetalert2";
import CostingSheetviewModal from "./CostingSheetviewModal";
import CostingCreateSortingPage from "./CostingCreateSortingPage";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Params {
  projectId: any;
  projectName: any;
  top_vault: any;
  abbr: any;
  top_vaultName: any;
  vault_id: any;
}

interface ICostingCreateSystemInsideBodyProps {
  pasteVaultNoList: any;
  setPasteVaultNoList: any;
  setCopyVaultNo: any;
  setCopyVaultCategory: any;
  copyVaultCategory: any;
  setShowCheckboxCopyClicked: any;
  showCheckboxCopyClicked: any;
  reloadCostingData: any;
  setReloadCostingData: any;
  apiCallMain: any;
  status: any;
  category: any;
  assemblyPart: any;
  calculatorType: any;
  sortingStatus:any;
}

const CostingCreateSystemInsideBody: React.FC<
  ICostingCreateSystemInsideBodyProps
> = (props) => {
  const {
    pasteVaultNoList,
    setPasteVaultNoList,
    setCopyVaultNo,
    setCopyVaultCategory,
    copyVaultCategory,
    setShowCheckboxCopyClicked,
    showCheckboxCopyClicked,
    reloadCostingData,
    setReloadCostingData,
    apiCallMain,
    status,
    category,
    assemblyPart,
    calculatorType,sortingStatus
  } = props;
  const { projectId, projectName, top_vault, abbr, top_vaultName, vault_id } =
    useRouteParams<Params>();
  const [costingTableData, setCostingTableData] = useState<any>();
  const [costingTableDataLoader, setCostingTableDataLoader] =
    useState<boolean>(false);
  const [sessionGetInto, setSessionGetInt] = useState<any>([]);
  const [contentText, setContentText] = React.useState<any>("");
  const [iconText, setIconText] = React.useState<any>("");
  const [categoryCopy, setCategoryCopy] = useState<any>();
  const [flagInfoData, setFlagInfoData] = useState<any>();
  const [bopDCInfoData, setBopDCInfoData] = useState<any>();
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [openInfo, setOpenInfo] = React.useState(false);
  const [statisticsDataLoader, setStatisticsDataLoader] = React.useState(false);
  const [permissionCosting, costingPermissionCosting] = React.useState();
  const [loaderRecursiveId, setLoaderRecursivId] = React.useState<any>();
  const [SheetviewData, setSheetviewData] = useState<any>();
  const sheetview = useBaseModal();
  const openStatusContent = useBaseModal();




  useEffect(() => {
    setCostingTableDataLoader(true);
    API.get(
      "/xcpep/cost_part_table/",
      {
        single_level: true,
        parent: vault_id,
        get_children: true,
        status_filter: status,
        category_filter: category,
        general_filter: assemblyPart,
        calculator_type: calculatorType,
      },
      0
    ).then((res: any) => {
      setCostingTableData(res?.data?.[0]);
      sessionStorage.setItem(
        "hierarchyCostingSelection",
        JSON.stringify([vault_id])
      );
      setSessionGetInt([vault_id]);
      setCostingTableDataLoader(false);
    });
  }, [apiCallMain]);

  useEffect(() => {
    API.get(
      "/customer/module_permission/",
      { top_vault_id: top_vault, module: "Costing-Create" },
      0
    ).then((res: any) => {
      costingPermissionCosting(res.data);
    });
  }, [top_vault]);

  useEffect(() => {
    if (costingTableData != undefined) {
      setCostingTableDataLoader(true);
      API.get(
        "/xcpep/cost_part_table/",
        { parent: vault_id, get_children: true, single_level: true },
        0
      ).then((res: any) => {
        setCostingTableData(res?.data?.[0]);
        setCostingTableDataLoader(false);
      });
    }
  }, [reloadCostingData]);

  useEffect(() => {
    setSessionGetInt(sessionStorage.getItem("hierarchyCostingSelection"));
  }, []);

  function replaceDataWithSameId(
    obj: any,
    targetId: any,
    newData: any,
    setLoaderRecursivId: any
  ) {
    if (obj.id === targetId) {
      // Replace data in the current object with the matching ID
      obj.data = newData.data;
      setLoaderRecursivId(-1);
      return;
      // console.log(obj.id,targetId,"targetId",obj.data,newData.data)
    }

    if (obj.data && obj.data.length > 0) {
      // Recursively traverse the children
      for (let i = 0; i < obj.data.length; i++) {
        replaceDataWithSameId(
          obj.data[i],
          targetId,
          newData,
          setLoaderRecursivId
        );
      }
    }
  }

  const handleOpenHierarchy = (idPush: any) => {
    let a: any = sessionStorage.getItem("hierarchyCostingSelection");
    let val: any = JSON.parse(a);

    if (val?.includes(idPush)) {
      sessionStorage.setItem(
        "hierarchyCostingSelection",
        JSON.stringify(val?.filter((item: any) => item != idPush))
      );
      setSessionGetInt(val?.filter((item: any) => item != idPush));
    } else {
      sessionStorage.setItem(
        "hierarchyCostingSelection",
        JSON.stringify([...val, idPush])
      );
      setLoaderRecursivId(idPush);
      setSessionGetInt([...val, idPush]);
      API.get(
        "/xcpep/cost_part_table/",
        {
          single_level: true,
          parent: idPush,
          get_children: true,
          status_filter: status,
          category_filter: category,
          general_filter: assemblyPart,
          calculator_type: calculatorType,
        },
        0
      ).then((res: any) => {
        replaceDataWithSameId(
          costingTableData,
          idPush,
          res?.data?.[0]?.data?.[0],
          setLoaderRecursivId
        );
      });
    }
  };

  const handleOpenFlagModel = () => {
    setStatisticsDataLoader(true);
    API.get("/cost/cost_flag_count/", { parent: vault_id }, 0).then(
      (res: any) => {
        setFlagInfoData(res?.data);
        setStatisticsDataLoader(false);
        setOpenInfo(true);
      }
    );
    API.get("/cost/cost_dc_bop_count/", { parent: vault_id }, 0).then(
      (res: any) => {
        setBopDCInfoData(res?.data);
      }
    );
  };

  const handleCloseInfo = () => {
    setOpenInfo(false);
  };

  const handleChangePasteCheckbox = (vaultIdComing: any) => {
    if (pasteVaultNoList?.includes(vaultIdComing)) {
      setPasteVaultNoList(
        pasteVaultNoList?.filter((item: any) => item != vaultIdComing)
      );
    } else {
      setPasteVaultNoList((prev: any) => [...prev, vaultIdComing]);
    }
  };
  const redirectBOMnotValidated = () => {
    handleCloseInfo();
    Swal.fire({
      icon: "error",
      html: `<div>
      <br />
      <p style="color:"red">Please validate BOM first to redirect to the costing page.</p>   
       </div>`,
    });
  };

  const loaderLoadingComponent = () => {
    let arrayLoopComponent = [];
    for (let i = 0; i < 20; i++) {
      arrayLoopComponent.push(
        <TableRow>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
          <TableCell
            sx={{
              padding: "0.2rem 0.5rem",
              borderBottomColor: "primary.light",
            }}
          >
            <Skeleton variant="text" />
          </TableCell>
        </TableRow>
      );
    }
    return arrayLoopComponent;
  };

  const redirectToCostingPage = (
    ancestorsData: any,
    vaultId: any,
    abbreviation: any
  ) => {
    sessionStorage.setItem("CostingCreateCalc", JSON.stringify(ancestorsData));
    window.open(
      `/#/createcostingpart/${projectId}/${projectName}/${top_vaultName}/${top_vault}/${vaultId}/${abbreviation == null ? "none" : abbreviation
      }/0/0/0/0/0/0/0/0/0/true`
    );
  };

  const SheetViewHandler = (item: any) => {
    setSheetviewData(item);
    sheetview.open();
  };



  return (
    <>
      {sheetview.isOpen && (
        <CostingSheetviewModal
          getData={SheetviewData}
          onCloseModal={sheetview.close}
          isOpen={sheetview.isOpen}
          status_filter={status}
          category_filter={category}
          general_filter={assemblyPart}
          calculator_type={calculatorType}
        />
      )}
      {openStatusContent.isOpen && (
        <DialogComponent
          isOpen={openStatusContent.isOpen}
          onClose={openStatusContent.close}
          content={contentText}
          type={iconText}
        />
      )}
      <Dialog
        open={openInfo}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseInfo}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          ".MuiPaper-root.MuiDialog-paper": {
            minWidth: "40vw",
            borderRadius: "10px",
          },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
          <Typography
            style={{
              fontSize: "1rem",
              fontWeight: "600",
              width: "100%",
              textAlign: "center",
              marginRight: "-2rem",
            }}
          >
            Flag Information ({abbr})
          </Typography>
          <CancelIcon
            titleAccess="Close"
            sx={{ cursor: "pointer", margin: "0.5rem" }}
            onClick={handleCloseInfo}
          />
        </Box>
        <Divider
          sx={{
            borderColor: "primary.light",
            margin: "0 1rem",
          }}
        />
        <DialogContent sx={{ width: "100%" }}>
          <Typography sx={{ marginBottom: "0.5rem" }}>
            Count of parts :
          </Typography>
          <Table>
            <TableHead
              sx={{ backgroundColor: "primary.main", border: "1px solid" }}
            >
              <TableRow>
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  BOP
                </TableCell>
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  DC
                </TableCell>
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  Flag(YES)
                </TableCell>
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                    textAlign: "center",
                    color: "white",
                  }}
                >
                  Flag(NO)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    border: "1px solid",
                    borderColor: "primary.main",
                    textAlign: "center",
                  }}
                >
                  {bopDCInfoData && bopDCInfoData?.bop_count}
                </TableCell>
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    border: "1px solid",
                    borderColor: "primary.main",
                    textAlign: "center",
                  }}
                >
                  {bopDCInfoData && bopDCInfoData?.dc_count}
                </TableCell>
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    border: "1px solid",
                    borderColor: "primary.main",
                    textAlign: "center",
                  }}
                >
                  {flagInfoData && flagInfoData?.flag_yes}
                </TableCell>
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    border: "1px solid",
                    borderColor: "primary.main",
                    textAlign: "center",
                  }}
                >
                  {flagInfoData && flagInfoData?.flag_no}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <br />
          <Typography sx={{ margin: "0.5rem 0" }}>
            Parts that don't have flag :
          </Typography>
          <TableContainer
            sx={{ maxHeight: { lg: "58vh", xl: "61vh" } }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3"
          >
            <Table>
              <TableHead
                sx={{
                  backgroundColor: "primary.main",
                  position: "sticky",
                  top: "0",
                }}
              >
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                      color: "white",
                    }}
                  >
                    Part No.
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                      color: "white",
                    }}
                  >
                    Part Name
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {flagInfoData &&
                  flagInfoData?.flag_no_part?.map((item: any) => {
                    return (
                      <TableRow>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                            textDecoration:
                              item?.status === 3 ? "underline" : "none",
                            cursor: "pointer",
                            color: item?.color,
                          }}
                          onClick={() =>
                            item?.status === 3
                              ? redirectToCostingPage(
                                item?.ancestors,
                                item?.id,
                                item?.abbreviation
                              )
                              : redirectBOMnotValidated()
                          }
                        >
                          {item?.part_no}
                        </TableCell>
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                          }}
                        >
                          {item?.name}
                        </TableCell>
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </TableContainer>
        </DialogContent>
      </Dialog>

{
  sortingStatus?


      <CostingCreateSortingPage

        setIconText={setIconText}
        setContentText={setContentText}
        openStatusContent={openStatusContent}

        apiCallMain={apiCallMain}
        status={status}
        category={category}
        assemblyPart={assemblyPart}
        calculatorType={calculatorType}
      />
:
      <Table>
        <TableHead
          sx={{
            backgroundColor: "primary.light",
            position: "sticky",
            top: 0,
            zIndex: 1,
          }}
        >
          <TableRow>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              Part No.
            </TableCell>

            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              Part Name
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              Category
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              Net Wt.(g)
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              Qty.
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
            >
              Cost/Kg
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
              title="(PUC=MC+PC+OC+CPC)"
            >
              Per Unit Cost
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
              title="Material Cost"
            >
              MC
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
              title="Process Cost"
            >
              PC
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
              title="Overhead Cost"
            >
              OC
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
              title="Child Parts Cost"
            >
              CPC
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                textAlign: "right",
              }}
              title="(PUC x Qty.)"
            >
              Total Cost
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              Action
              <LoadingButton
                loading={statisticsDataLoader}
                sx={{
                  p: 0,
                  minWidth: "1rem",
                  ".MuiCircularProgress-root": {
                    color: "primary.main",
                  },
                }}
              >
                <InfoIcon
                  titleAccess="Flag Information"
                  sx={{ cursor: "pointer", marginLeft: "0.7rem" }}
                  onClick={handleOpenFlagModel}
                />
              </LoadingButton>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {costingTableDataLoader
            ? loaderLoadingComponent()
            : costingTableData &&
            costingTableData?.data?.map((item: any, index: number) => {
              return (
                <CostingCreateSystemInsideBodyRow
                  costingTableData={item}
                  SheetViewHandler={SheetViewHandler}
                  handleOpenHierarchy={handleOpenHierarchy}
                  sessionGetInto={sessionGetInto}
                  costingTableDataParent={sessionGetInto}
                  setIconText={setIconText}
                  setContentText={setContentText}
                  openStatusContent={openStatusContent}
                  categoryCopy={categoryCopy}
                  setCategoryCopy={setCategoryCopy}
                  handleChangePasteCheckbox={handleChangePasteCheckbox}
                  pasteVaultNoList={pasteVaultNoList}
                  copyVaultCategory={copyVaultCategory}
                  setCopyVaultCategory={setCopyVaultCategory}
                  setCopyVaultNo={setCopyVaultNo}
                  showCheckboxCopyClicked={showCheckboxCopyClicked}
                  setShowCheckboxCopyClicked={setShowCheckboxCopyClicked}
                  hoveredIndex={hoveredIndex}
                  setHoveredIndex={setHoveredIndex}
                  reloadCostingData={reloadCostingData}
                  setReloadCostingData={setReloadCostingData}
                  permissionCosting={permissionCosting}
                  loaderRecursiveId={loaderRecursiveId}
                />
              );
            })}
        </TableBody>
      </Table>

          }

    </>
  );
};

export default CostingCreateSystemInsideBody;
