import * as React from 'react';
import Divider from '@mui/material/Divider';
import CircleTwoToneIcon from '@mui/icons-material/CircleTwoTone';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styles from './IdeaEntryInnerPage.module.scss';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { useRouteParams } from '../../../ui-reusable-component/useRouteParams';
import { API } from '../../../api-services';




export interface IIdeaClientWorkflowProps {
    ideaAllDetails?:any;
    setWorkflowState?:any;
    StatusCounter?:any;
}

export default function IdeaClientWorkflow (props: IIdeaClientWorkflowProps) {
    const {ideaAllDetails,setWorkflowState, StatusCounter}=props;
      
        const [workflowData,setWorkFlowData]=React.useState<any>({})
        const [initiatedData,setInitiatedData]=React.useState<any>({})
        const [stage,setStage]=React.useState<any>("")
        const [reviewedData,setReviewedData]=React.useState<any>({})
        const [submittedData,setSubmittedData]=React.useState<any>({})
        const [acceptedData,setAcceptedData]=React.useState<any>({})
        const [rejectedDataASI,setRejectedDataASI]=React.useState<any>({})
        const [rejectedDataClient,setRejectedDataClient]=React.useState<any>({})
        const {ideaId} = useRouteParams<any>();
       
        console.log("ideaAllDetails",ideaAllDetails);
        React.useEffect(()=>{
            setStage("")
            if(ideaAllDetails && ideaAllDetails[0]?.stage){
                setStage(ideaAllDetails[0]?.stage)
            }
        },[ideaAllDetails]);
        
        const getWorkFlowData=()=>{
            if(ideaId !=0){
                setInitiatedData({});
                setReviewedData({});
                setSubmittedData({});
                setAcceptedData({});
                setRejectedDataASI({})
                setRejectedDataClient({})

                API.get("/idea/idea_work_flow/",{id:ideaId},0).then((res:any)=>{
                
                setWorkFlowData(res.data);
                setInitiatedData(res.data[0]);
                setReviewedData(res.data[1]);
                setSubmittedData(res.data[2]);
                setAcceptedData(res.data[3]);
                if(res.data[0]?.rejected === true){
                    setRejectedDataASI(res.data[0])
                }else if(res.data[1]?.rejected === true){
                    setRejectedDataASI(res.data[1])
                }else if(res.data[2]?.rejected === true){
                    setRejectedDataClient(res.data[2])
                }
            }).catch((err:any)=>{

            })
        }
        }
        React.useEffect(()=>{
            getWorkFlowData();
        },[ideaId,StatusCounter])


  return (
       <Box sx={{backgroundColor:'#fdffd7',border:'1px solid',borderColor:'primary.light',borderRadius:'1rem',width:'100%',padding:'0.5rem'}}>
            <Box sx={{display:'flex',alignItems:'center',justifyContent:'space-between', width:'100%'}}>
                <Typography sx={{width:'100%',fontSize:'1rem',fontWeight:'500'}}>Workflow</Typography>
                <CloseIcon sx={{color:'red', cursor:'pointer',}} titleAccess='Close'  onClick={()=>setWorkflowState(false)}/>
            </Box>                    
            <Box sx={{display:'flex',alignItems:'center',width:'100%'}}>
                <Box sx={{width:'10rem',textAlign:'center',marginTop:'4rem'}}>                      
                    <Box sx={{display:'flex',alignItems:'center',justifyContent:'center'  }}>                        
                        {stage && stage !=0 || (stage ===0 || stage <=1 )?<CheckCircleOutlineIcon sx={{color:'#007fff'}}/>:<CircleTwoToneIcon sx={{color:'#007fff'}}  /> }
                    </Box>  
                    <Box sx={{textAlign:'center',color:'primary.main',marginLeft:{lg:'-1.5rem',xl:'-3rem',height:'4rem'}}}>                       
                        <Typography className={styles.workflow_name}>Initiated</Typography>
                        <Typography className={styles.workflow_name} title="Name">{initiatedData?.stage_onwer || " "}</Typography>
                        <Typography className={styles.workflow_name}>{initiatedData?.stage_on || " "}</Typography>
                    </Box>
                </Box>
                <Box sx={{display:'flex',alignItems:'center',}}>
                        <Divider sx={{width:{lg:'7rem',xl:'10rem'},border: '1px solid',color:stage &&stage !==0 && (stage ===1 || stage <=3 )? '#ffc800': '#e5e5e5',marginLeft:{lg:'-4rem',xl:'-4rem'}}} />
                        <Box sx={{width: '0',height: '0',borderTop: '5px solid transparent', borderBottom: '5px solid transparent', borderLeft:stage &&stage !==0 && (stage ===1 || stage <=3 )? '13px solid #ffc800': '13px solid #e5e5e5',}} ></Box>
                        {stage &&stage !==0 && (stage ===1 || stage <=3 )?<CheckCircleIcon sx={{color:'#ffc800'}}/>:<CircleTwoToneIcon sx={{color:'#e5e5e5'}}  /> }
                </Box>
                <Box sx={{width:{lg:'9rem',xl:'11rem'},textAlign:'center',marginTop:'5.5rem',marginLeft:{lg:'-8rem',xl:'-9rem'},color:stage &&stage !==0 && (stage ===1 || stage <=3 )?'primary.main': '#e5e5e5',height:'4rem'}}> 
                    <Typography className={styles.workflow_name}>Reviewed</Typography>
                    <Typography className={styles.workflow_name} title="Name">{reviewedData?.stage_onwer || " "}</Typography>
                    <Typography className={styles.workflow_name}>{reviewedData?.stage_on || " "}</Typography>
                </Box>
                <Box sx={{display:'flex',alignItems:'center',  }}>
                        <Divider sx={{width:{lg:'7rem',xl:'10rem'},border: '1px solid',zIndex:1, color: stage &&( stage !==0  && stage >=2)&&(stage ===2 || stage <=3 )?  '#40a6ff':Object.keys(rejectedDataASI)?.length>0? 'red' :'#e5e5e5',marginLeft:{lg:'-1rem',xl:'-2rem'}}} />                        
                </Box>  
                <Box sx={{display:'flex',flexDirection:'column',height:'12rem',marginLeft:'-0.1rem'}}>                    
                    <Box className={styles.containReject}>                     
                            <Divider orientation="vertical"   sx={{border: '1px solid',marginTop:'0.8rem', width: '1px',height: '5.2rem', color: Object.keys(rejectedDataASI)?.length>0?  'red':'#e5e5e5',}}  />
                            <Box sx={{display:'flex',alignItems:'flex-start',flexDirection:'column'}}>
                                <Box sx={{display:'flex',alignItems:'center',}}>
                                    <Divider sx={{width:{lg:'6rem',xl:'10rem'},border: '1px solid',color: Object.keys(rejectedDataASI)?.length>0?  'red':'#e5e5e5',}}  />
                                    <Box sx={{width: '0',height: '0',borderTop: '5px solid transparent', borderBottom: '5px solid transparent', borderLeft:Object.keys(rejectedDataASI)?.length>0? '13px solid red':  '13px solid #e5e5e5',}} ></Box>
                                    {Object.keys(rejectedDataASI)?.length>0?<CheckCircleIcon sx={{color:'red'}}/> :<CircleTwoToneIcon sx={{color:'#e5e5e5'}}  />}
                                </Box>
                                <Box sx={{width:'7rem',textAlign:'center',marginLeft:{lg:'1rem',xl:'3rem'},color:Object.keys(rejectedDataASI)?.length>0?  'primary.main':'#e5e5e5'}}>  
                                    <Typography className={styles.workflow_name}>Rejected by ASI</Typography>
                                    <Typography className={styles.workflow_name} title="Name">{rejectedDataASI?.rejected_by}</Typography>
                                    <Typography className={styles.workflow_name}>{rejectedDataASI?.rejected_on}</Typography>                                        
                                </Box>
                            </Box>                        
                    </Box>        
                    <Box  className={styles.containSubmit}>
                        <Divider orientation="vertical"   sx={{border: '1px solid',width: '1px',height: '5rem',marginTop:"0.9rem", color: stage &&( stage !==0  && stage >=2) &&(stage ===2 || stage <=3 )? '#40a6ff' : '#e5e5e5' }}  />
                        <Box sx={{display:'flex',alignItems:'flex-end'}}>
                            <Box sx={{width:'9rem',textAlign:'center',position:'absolute',left:{lg:'1rem',xl:'3rem'},bottom:'0rem',color:stage && ( stage !==0  && stage >=2) &&(stage ===2 || stage <=3 )?"primary.main":'#e5e5e5'}}> 
                                <Typography className={styles.workflow_name}>Submitted</Typography>
                                <Typography className={styles.workflow_name} title="Name">{submittedData?.stage_onwer}</Typography>
                                <Typography className={styles.workflow_name}>{submittedData?.stage_on}</Typography>                                    
                            </Box> 
                            <Box sx={{display:'flex',alignItems:'center',marginTop:'-0.9rem'}}>
                                <Divider sx={{width:{lg:'6rem',xl:'10rem'},border: '1px solid',color: stage &&( stage !==0  && stage >=2) &&(stage ===2 || stage <=3 )? '#40a6ff': '#e5e5e5',}}  />
                                <Box sx={{width: '0',height: '0',borderTop: '5px solid transparent', borderBottom: '5px solid transparent', borderLeft: stage && ( stage !==0  && stage >=2) &&(stage ===2 || stage <=3 )?'13px solid #40a6ff':'13px solid #e5e5e5',}} ></Box>
                                {stage &&( stage !==0  && stage >=2)&&(stage ===2 || stage <=3 )?<CheckCircleIcon sx={{color:'#40a6ff'}}/> :<CircleTwoToneIcon sx={{color:'#e5e5e5'}} />}
                                <Divider sx={{width:{lg:'7rem',xl:'10rem'},border: '1px solid',color: Object.keys(rejectedDataClient)?.length>0?  'red' : stage && (stage ===3 )? '#7afba9': '#e5e5e5',}}  />
                            </Box>                
                        </Box>
                    </Box>             
                </Box> 
                <Box sx={{display:'flex',height:'12rem'}}>
                    <Divider orientation="vertical" sx={{border: '1px solid',width: '1px',height: '10rem',marginTop:"0.8rem",marginLeft:'-2px', color: Object.keys(rejectedDataClient)?.length>0?  'red':'#e5e5e5',}}  /> 
                    <Box sx={{display:'flex',flexDirection:'column',height:'12rem'}}>
                        <Box className={styles.clientReject}>
                            <Box sx={{display:'flex',alignItems:'flex-start',flexDirection:'column'}}>
                                <Box sx={{display:'flex',alignItems:'center',}}>
                                    <Divider sx={{width:{lg:'7rem',xl:'10rem'},border: '1px solid',color:Object.keys(rejectedDataClient)?.length>0? 'red':'#e5e5e5',}} />
                                    <Box sx={{width: '0',height: '0',borderTop: '5px solid transparent', borderBottom: '5px solid transparent', borderLeft:Object.keys(rejectedDataClient)?.length>0?  '13px solid red':  '13px solid #e5e5e5',}} ></Box>
                                    {Object.keys(rejectedDataClient)?.length>0?<CheckCircleIcon sx={{color:'red'}}/> :<CircleTwoToneIcon sx={{color:Object.keys(rejectedDataClient)?.length>0? 'red': '#e5e5e5'}}  />}
                                </Box> 
                                <Box sx={{width:'fit-content',textAlign:'center',height: '5rem',marginLeft:{lg:'1rem',xl:'3rem'},color: Object.keys(rejectedDataClient)?.length>0? "primary.main": '#e5e5e5'}}>  
                                    <Typography className={styles.workflow_name}>Rejected by</Typography>
                                    <Typography className={styles.workflow_name} title="Name"> {rejectedDataClient?.rejected_by}</Typography>
                                    <Typography className={styles.workflow_name}>{rejectedDataClient?.rejected_on}</Typography>
                                </Box>                                                       
                            </Box>
                        </Box>
                        <Box className={styles.clientAccept}>                        
                            <Box sx={{display:'flex',alignItems:'flex-start',flexDirection:'column'}}>
                                <Box sx={{width:'fit-content',flexDirection:'column',textAlign:'center',height:'4rem',marginLeft:{lg:'1rem',xl:'3rem'},color:stage && (stage ===3 )? 'primary.main' :'#e5e5e5',display:'flex',alignItems:'flex-end',justifyContent:'flex-end'}}>
                                    <Typography className={styles.workflow_name}>Accepted</Typography>
                                    {acceptedData?.stage_onwer && <Typography className={styles.workflow_name} title="Name">{acceptedData?.stage_onwer}</Typography>}
                                    {acceptedData?.stage_on && <Typography className={styles.workflow_name}>{acceptedData?.stage_on}</Typography>}
                                </Box>  
                                <Box sx={{display:'flex',alignItems:'center', }}>
                                    <Divider sx={{width:{lg:'7rem',xl:'10rem'},border: '1px solid',color:stage && (stage ===3 )? '#7afba9':'#e5e5e5'}} />
                                    <Box sx={{width: '0',height: '0',borderTop: '5px solid transparent', borderBottom: '5px solid transparent', borderLeft: stage && (stage ===3 )? '13px solid #7afba9' : '13px solid #e5e5e5',}}></Box>
                                    {stage && (stage ===3 )?<CheckCircleIcon sx={{color:'#7afba9'}}/> :<CircleTwoToneIcon sx={{color:'#e5e5e5'}} />}                                
                                </Box>                                           
                            </Box>
                        </Box>
                    </Box>
                </Box>                
            </Box>
        </Box>
  );
}
