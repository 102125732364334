import React, { useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import { Divider } from "@mui/material";
import styles from "./FeatureVideoRenderModal.module.scss";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  width: "auto",
  maxHeight: "90%",
  minWidth: "50%",
  minHeight: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  borderRadius: "10px",
  //   boxShadow: 24,
};

interface FeatureAllvideoProps {
  isOpen: any;
  onCloseModal: any;
  url: any;
}
interface VideoPlayerState {
  volume: number;
  muted: boolean;
}

export default function FeatureVideoRenderModal(props: FeatureAllvideoProps) {
  const { isOpen, onCloseModal, url } = props;
  const videoRef = React.useRef<HTMLVideoElement | null>(null);
  const handlePlay = () => {
    if (videoRef.current) {
      videoRef.current.muted = url?.is_mute; // Ensure the video stays muted
    }
  };

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}>
              Feature Video
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
            }}
          />
          <Box className={styles.videoCome} sx={{ display: "flex", justifyContent: "center", padding: "0.5rem" }}>
            <video
              style={{
                height: "100%",
                width: "100%",
              }}
              ref={videoRef}
              // ref={ this.VideoRef }
              controls
              muted={true}
              src={url.value}
              onPlay={handlePlay}
              autoPlay
              // onLoadedMetadata={ this.loadedmetadataListner }
            >
              <source type="video/mp4"></source>
            </video>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
