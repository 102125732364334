
import { useState } from "react";
import { ID } from "../../../../../../../utlis/commonInterfaces";

// ----------------------------------------------------------------------------------

const useSelectedDatabaseRecordInfoModal = () => {
    const [isOpen, setIsOpen] = useState( false );
    const [database, setDatabase] = useState( 0 );
    const [recordId, setRecordId] = useState<ID>( 0 );

    const handleOpen = ( database: number, recordId: ID ) => () => {
        setDatabase( database );
        setRecordId( recordId );
        setIsOpen( true );
    };

    const handleClose = () => {
        setDatabase( 0 );
        setRecordId( 0 );
        setIsOpen( false );
    };

    return Object.freeze( {
        open: handleOpen,
        close: handleClose,
        isOpen,
        database,
        recordId
    } );
};

// ----------------------------------------------------------------------------------

export { useSelectedDatabaseRecordInfoModal };
