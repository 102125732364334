import { UpdateGanttChartData } from "../Actions/ganttChart.action";
import { API } from "./index.service";

const url = "/xcpep/gantt_chart/";

const note_url = "/xcpep/gantt_chart/notes/";



class GanttChart {

    get = ( project: number,activity_type?:any,work_type?:any ) => {
        return API.get( url, { project: project,activity_type:activity_type == 'All' ? null : activity_type,work_type:work_type == 'All' ? null : work_type} );
    };


    getNotes = ( id: string, ) => {
        return API.get( note_url, { id: id } );
    };


    updateNotes = ( arg: UpdateGanttChartData ) => {
        return API.patch( note_url, { id: arg.id, notes: arg.notes } );
    };

}

const GanttChartServices = new GanttChart();

export default GanttChartServices;
