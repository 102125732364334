// vendors
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

// interfaces

// services

// redux
import { ICalculator } from "../Reducers/calculator.reducer";
import { ID } from "../../utlis/commonInterfaces";
import calculatorService from "../Services/calculator.service";

// -------------------------------------------------------------------------------------
// api actions
// -------------------------------------------------------------------------------------

export interface GetCalculatorsArg {
    calculatorCategory: number;
    processId: ID;
    commodityMappingId: ID;
    object_id?: ID;
}
const getCalculators = createAsyncThunk(
    "calculator/get",
    async ( arg: GetCalculatorsArg, { rejectWithValue } ) => {
        try {
            const { data } = await calculatorService.get( arg );

            return {
                calculators: data as ICalculator[],
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

// -------------------------------------------------------------------------------------

export interface GetCalculatorByIdArg {
    id: ID;
}
const getCalculatorById = createAsyncThunk(
    "calculator/getById",
    async ( arg: GetCalculatorByIdArg, { rejectWithValue } ) => {
        try {
            const { data } = await calculatorService.getById( arg );

            return {
                calculator: data as ICalculator
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

// -------------------------------------------------------------------------------------

export type CreateCalculatorArg = Omit<ICalculator, "id" | "calculator_type">;
const createCalculator = createAsyncThunk(
    "calculator/create",
    async ( arg: CreateCalculatorArg, { rejectWithValue } ) => {
        try {
            const { data } = await calculatorService.create( arg );

            return {
                calculator: data as ICalculator,
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

// -------------------------------------------------------------------------------------

export interface CopyCalculatorArg {
    calculatorId: ID;
    name: string;
}
const copyCalculator = createAsyncThunk(
    "calculator/copy",
    async ( arg: CopyCalculatorArg, { rejectWithValue } ) => {
        try {
            const { data } = await calculatorService.copy( arg );

            return {
                calculator: data as ICalculator
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

// -------------------------------------------------------------------------------------

export interface UpdateCalculatorArg {
    id: ID;
    updates: Partial<
        Omit<ICalculator, "id" | "process" | "project" | "calculator_type" | "commodity_mapping" | "category">
    >;
}
const updateCalculator = createAsyncThunk(
    "calculator/update",
    async ( arg: UpdateCalculatorArg, { rejectWithValue } ) => {
        try {
            await calculatorService.update( arg );

            return {};
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

// -------------------------------------------------------------------------------------

export interface DeleteCalculatorArg {
    id: ID;
}
const deleteCalculator = createAsyncThunk(
    "calculator/delete",
    async ( arg: DeleteCalculatorArg, { rejectWithValue } ) => {
        try {
            await calculatorService.delete( arg );

            return {};
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

// -------------------------------------------------------------------------------------
// reducer actions
// -------------------------------------------------------------------------------------

const resetCalculators = createAction( "calculator/reset" );

// -------------------------------------------------------------------------------------
// exports
// -------------------------------------------------------------------------------------

export {
    getCalculators,
    getCalculatorById,
    createCalculator,
    copyCalculator,
    updateCalculator,
    deleteCalculator,
    resetCalculators,
};
