import React, { useEffect, useState }  from "react"
import useWindowDimensions from "../../ui-reusable-component/useWindowDimensions";
import { DataGrid } from "@mui/x-data-grid/DataGrid";
import { Box, Button, ButtonProps, Menu, MenuItem, MenuProps, Skeleton, alpha, styled } from "@mui/material";
import { GridCsvExportMenuItem, GridCsvExportOptions, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExportContainer } from "@mui/x-data-grid";
import Swal from "sweetalert2";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";

import scenerioDoneIcon from "../../Assets/images/scenarioDone.svg";
import LppIcon from "../../Assets/images/LppIcon.svg";
import scenerioInProgressIcon from "../../Assets/images/scenarioInProgress.svg";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import { API } from "../../api-services";
import { useBaseModal } from "../SearchPage/useBaseModal";
const csvOptions: GridCsvExportOptions = {
    fileName: "Idea Approve Table User Session In CSV",
    delimiter: ",",
    utf8WithBom: true,
  };
  const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "left",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      minWidth: 150,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));
  const CustomExportButton = (props: ButtonProps) => (
    <GridToolbarExportContainer {...props}>
      <GridCsvExportMenuItem options={csvOptions} />
    </GridToolbarExportContainer>
  );
  
  const CustomToolbar = () => (
    <GridToolbarContainer
      sx={{ height: "2.5rem", justifyContent: "flex-end", paddingRight: "1rem" }}
    >
      <span
      // style={{ position: "absolute", right: "0rem" }}
      >
        <GridToolbarColumnsButton />
        <CustomExportButton />
      </span>
    </GridToolbarContainer>
  );

interface CostingCreateSortingPageProp{
  
    setIconText: any;
  setContentText: any;
  openStatusContent: any;
  status:any;
                  category:any;
                  assemblyPart:any;
                  calculatorType:any;
                  apiCallMain:any;
        
}


const CostingCreateSortingPage=(prop:CostingCreateSortingPageProp)=>{
const {setIconText,setContentText,openStatusContent,status,category,assemblyPart,calculatorType,apiCallMain}=prop
const {
    projectId,
    projectName,
    top_vault,
    abbr,
    top_vaultName,
    initialTopVault,
    topVaultIds,
    expandTable,
    vault_id,
  } = useRouteParams<any>();
   
const { height, width } = useWindowDimensions();

const [anchorEl, setAnchorEl] = React.useState<null |any>(null);
const openDownload = Boolean(anchorEl);
const openDialogModal = useBaseModal();

const [costingTableData, setCostingTableData] = useState<any>();
const [costingTableDataLoader, setCostingTableDataLoader] =
  useState<boolean>(false);
const [sessionGetInto, setSessionGetInt] = useState<any>([]);

useEffect(() => {
    setCostingTableDataLoader(true);
    API.get(
      "/xcpep/cost_part_table/",
      {
        single_level: true,
        parent: vault_id,
        get_children: true,
        status_filter: status,
        category_filter: category,
        general_filter: assemblyPart,
        calculator_type: calculatorType,
        sort:true
      },
      0
    ).then((res: any) => {
      setCostingTableData(res?.data?.[0]);


      sessionStorage.setItem(
        "hierarchyCostingSelection",
        JSON.stringify([vault_id])
      );
      setSessionGetInt([vault_id]);
      setCostingTableDataLoader(false);
    });
  }, [apiCallMain]);
const handleExcelReport = async (vaultIDGet: any) => {
    openDialogModal.open();
    try {
      const { data: url } = await API.get(
        "/customer/individual_report/",
        {
          vault: vaultIDGet,
          individual_type: "Costing",
          scenario: 0,
        },
        0
      );
      window.open(url, "_blank", "noopener noreferrer");
    } catch (e) {}
  };


const handleClick = (event: React.MouseEvent<HTMLElement>,item:any) => {

   
    item?.is_leaf
      ? handleExcelReport(item?.id)
      : setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };



const redirectToCostingPage = (item:any) => {
    sessionStorage.setItem(
      "CostingCreateCalc",
      JSON.stringify(item?.ancestors)
    );
    window.open(
      `/#/createcostingpart/${projectId}/${projectName}/${top_vaultName}/${top_vault}/${
        item?.id
      }/${
        item?.abbreviation == null
          ? "none"
          : item?.abbreviation
      }/0/0/0/0/0/0/0/0/0/true`
    );
  };


  const redirectBOMnotValidated = () => {
    Swal.fire({
      icon: "error",
      html: `<div>
      <br />
      <p style="color:"red">Please validate BOM first to redirect to the costing page.</p>   
       </div>`,
    });
  };


  const handleRedirectToLpp = (
    abbreviationComing: any,
    partid: any,
    depth: any
  ) => {
    if (abbreviationComing == null) {
      window.open(
        `/#/costingqualitycheck/${projectId}/${projectName}/${initialTopVault}/${topVaultIds}/${expandTable}/Part/${partid}/${depth}/${vault_id}`
      );
    } else {
      window.open(
        `/#/costingqualitycheck/${projectId}/${projectName}/${initialTopVault}/${topVaultIds}/${expandTable}/${abbreviationComing}/0/0/${vault_id}`
      );
    }
  };


  const handleRedirect = (module: any, id: any) => {
    if (module === "w") {
      API.get(
        "/customer/check_consolidate_downoad/",
        {
          vault: id,
          with_image: true,
          consolidate_type: "Costing",
          scenario: undefined,
        },
        0
      ).then((res: any) => {
        if (res.data.status === "Failed") {
          setIconText("failed");
          setContentText(res.data.message);
          openStatusContent.open();
        } else if (res.data.status === "First Download") {
          setIconText("firstdownload");
          setContentText(res.data.message);
          handleClose();
          openStatusContent.open();
          API.get(
            "customer/consolidated_report/",
            {
              vault: id,
              with_image: true,
              consolidate_type: "Costing",
              scenario: 0,
            },
            0
          ).then((res: any) => {
            handleClose();
            window.open(res?.data, "_self");
          });
        } else if (res.data.status === "In Progress") {
          setIconText("reportProgress");
          setContentText(res.data.message);
          handleClose();
          openStatusContent.open();
        } else if (res.data.status === "Completed") {
          handleClose();
          window.open(res?.data?.url, "_self");
        }
      });
    } else if (module === "w/o") {
      API.get(
        "/customer/check_consolidate_downoad/",
        {
          vault: id,
          with_image: false,
          consolidate_type: "Costing",
          scenario: undefined,
        },
        0
      ).then((res: any) => {
        if (res.data.status === "Failed") {
          setIconText("failed");
          setContentText(res.data.message);
          handleClose();
          openStatusContent.open();
        } else if (res.data.status === "First Download") {
          setIconText("firstdownload");
          setContentText(res.data.message);
          handleClose();
          openStatusContent.open();
          API.get(
            "customer/consolidated_report/",
            {
              vault: id,
              with_image: false,
              scenario: 0,
              consolidate_type: "Costing",
            },
            0
          ).then((res: any) => {
            handleClose();
            window.open(res?.data, "_self");
          });
        } else if (res.data.status === "In Progress") {
          setIconText("reportProgress");
          setContentText(res.data.message);
          handleClose();
          openStatusContent.open();
        } else if (res.data.status === "Completed") {
          handleClose();
          window.open(res?.data?.url, "_self");
        }
      });
    }
  };
let columns: any = [
    { field: "sno", headerName: "SNo.", width:  50},
    {
      field: "part_no",
      headerName: "Part No",         
      minWidth:150,  
      flex: 1,
      renderCell:(cellValues:any)=>{
        return(<>       
        
        <span
        style={
            !cellValues?.row?.is_leaf
              ? {
                  color: `${cellValues?.row?.color}`,
                  cursor: "pointer",
                //   display: "flex",
                //   alignItems: "center",
                //   justifyContent: "space-between",
                //   columnGap: "0.5rem",
                }
              : cellValues?.row?.abbreviation != abbr &&
               
                cellValues?.row?.cost_status != 3 
                
              ? {
                  color: `${cellValues?.row?.color}`,
                  cursor: "pointer",
                //   display: "flex",
                //   alignItems: "center",
                //   justifyContent: "space-between",
                //   columnGap: "0.5rem",
                }
              : {
                  marginLeft: "2.3rem",
                  color: `${cellValues?.row?.color}`,
                  cursor: "pointer",
                //   display: "flex",
                //   alignItems: "center",
                //   justifyContent: "space-between",
                //   columnGap: "0.5rem",
                }
          }

          onClick={() => {
            
            (cellValues?.row?.bom_status === 3
              ? redirectToCostingPage(cellValues?.row)
              : redirectBOMnotValidated())
        }}
      
        >
            
            {cellValues?.row?.part_no}</span>
        </>)
      }
    },
   
    { field:"name" , headerName:  "Part Name", minWidth: width > 1370 ? 300 : 200, flex: 1, renderCell:(cellValues:any)=>{
        return (<>
        <span style={{cursor:"pointer"}}
        
        onClick={() => {
            
              (cellValues?.row?.bom_status === 3
                ? redirectToCostingPage(cellValues?.row)
                : redirectBOMnotValidated())
          }}
        
       
          > {cellValues?.row?.name?.toLowerCase()}</span>
         
        
        </>)
      } },
    {
      field: "category",
      headerName: "Category",
      minWidth: 120,
      flex: 1,
    },
    {
      field: "weight",
      headerName:"Net Wt.(g)",
     
      type:"number",
      minWidth: 80 ,
      flex: 1,
      renderCell:(cellValues:any)=>{
        return (<>
         {cellValues?.row?.weight == 0
            ? cellValues?.row?.weight
            : cellValues?.row?.weight?.toFixed(2)}
        
        </>)
      }
    },
    {
      field: "Qty",
      headerName: "Quantity",
      type: "number",
      minWidth:  80 ,
      flex: 1,
    },
    {
      field: "cost_per_kg", headerName: "Cost/Kg",
      
      type: "number",
      width: width > 1370 ? 130 : width < 1200 ? 130 : 120, 
      
    },
    { field: "per_unit_cost", headerName: "Per Unit Cost",
    
    type: "number",
    minWidth: width > 1370 ? 130 : 120, flex: 1,renderCell:(cellValues:any)=>{

return(  <>
        {cellValues?.row?.per_unit_cost == 0
            ? cellValues?.row?.per_unit_cost
            : cellValues?.row?.per_unit_cost?.toFixed(3)}
            
            </>
            )
    } },
    { field: "material_cost",
    
    type: "number",
    headerName: "MC", minWidth: width > 1370 ? 120 : 120, flex: 1 ,renderCell:(cellValues:any)=>{
        return (<>
        
        {cellValues?.row?.material_cost == 0
            ? cellValues?.row?.material_cost
            : cellValues?.row?.material_cost?.toFixed(3)}
        
        </>)
    }},
    { field: "process_cost",
    
    type: "number",
    headerName: "PC", minWidth: width > 1370 ? 120 : width < 1200 ? 140 : 120, flex: 1,renderCell:(cellValues:any)=>{
        return (<>
        
        {cellValues?.row?.process_cost == 0
            ? cellValues?.row?.process_cost
            : cellValues?.row?.process_cost?.toFixed(3)}
        
        </>)
    } },


    { field: "overhead_cost",
    
    type: "number",
    headerName: "OC", minWidth: width > 1370 ? 120 : width < 1200 ? 140 : 120, flex: 1,renderCell:(cellValues:any)=>{
        return (<>
        
        {cellValues?.row?.overhead_cost == 0
            ? cellValues?.row?.overhead_cost
            : cellValues?.row?.overhead_cost?.toFixed(3)}
        
        </>)
    } },

    { field: "child_part_cost",
    
    type: "number",
    headerName: "CPC", minWidth: width > 1370 ? 120 : width < 1200 ? 140 : 120, flex: 1,renderCell:(cellValues:any)=>{
        return (<>
        
        {cellValues?.row?.child_part_cost == 0
            ? cellValues?.row?.child_part_cost
            : cellValues?.row?.child_part_cost?.toFixed(3)}
        
        </>)
    } },
    { field: "total_cost", 
    type: "number",
    headerName: "Total Cost", minWidth: width > 1370 ? 120 : width < 1200 ? 140 : 120, flex: 1,renderCell:(cellValues:any)=>{
        return (<>
        
        {cellValues?.row?.total_cost == 0
            ? cellValues?.row?.total_cost
            : cellValues?.row?.total_cost?.toFixed(3)}
        
        </>)
    } },
    {
      field: "actions",
      headerName: "Actions",
      width: width > 1370 ? 105 : width < 1200 ? 80 : 100,
      renderCell: (cellValues: any) => {
        return (<>
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              columnGap: "0.7rem",
            }}
          >
          

          {cellValues?.row?.cost_status == 3 && (
              <Button
                id="demo-customized-button"
                aria-controls={
                  openDownload ? "demo-customized-menu" : undefined
                }
                aria-haspopup="true"
                aria-expanded={openDownload ? "true" : undefined}
                disableElevation
                onClick={(e:any)=>handleClick(e,cellValues?.row)}
                style={{
                  padding: "0rem",
                  minWidth: "1rem",
                  margin: "0 0.2rem",
                }}
              >
                <CloudDownloadIcon sx={{ cursor: "pointer" }} />
              </Button>
            )}
            {cellValues?.row?.scenario_show &&
              (cellValues?.row?.scenario_exists ? (
                <img
                  src={scenerioDoneIcon}
                  height="18px"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    sessionStorage.removeItem("nodeCostingScenario");
                    sessionStorage.setItem(
                      "nodeCostingScenario",
                      JSON.stringify(cellValues?.row?.ancestors)
                    );
                    sessionStorage.setItem("addScenarioStatus", "3");
                    window.open(
                      `/#/costingscenario/${projectId}/${projectName}/${top_vault}/${
                        cellValues?.row?.id
                      }/1/${
                        cellValues?.row?.abbreviation
                          ? cellValues?.row?.abbreviation
                          : "none"
                      }/0`
                    );
                  }}
                />
              ) : (
                <img
                  src={scenerioInProgressIcon}
                  height="18px"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    sessionStorage.removeItem("nodeCostingScenario");
                    sessionStorage.setItem(
                      "nodeCostingScenario",
                      JSON.stringify(cellValues?.row?.ancestors)
                    );
                    sessionStorage.setItem("addScenarioStatus", "3");
                    window.open(
                      `/#/costingscenario/${projectId}/${projectName}/${top_vault}/${
                        cellValues?.row?.id
                      }/1/${
                        cellValues?.row?.abbreviation
                          ? cellValues?.row?.abbreviation
                          : "none"
                      }/0`
                    );
                  }}
                />
              ))}
            <img
              src={LppIcon}
              height="20px"
              style={{ marginLeft: "0.2rem", cursor: "pointer" }}
              onClick={() =>
                handleRedirectToLpp(
                  cellValues?.row?.abbreviation,
                  cellValues?.row?.id,
                  cellValues?.row?.ancestors?.length - 1
                )
              }
            />

<StyledMenu
              id="demo-customized-menu"
              MenuListProps={{
                "aria-labelledby": "demo-customized-button",
              }}
              anchorEl={anchorEl}
              open={openDownload}
              onClose={handleClose}
            >
              <MenuItem
                sx={{
                  fontSize: "1rem",
                  color: "primary.main",
                  padding: "0.2rem 0.5rem",
                }}
                onClick={() => handleRedirect("w", cellValues?.row?.id)}
              >
                Consolidated with images
              </MenuItem>
              <MenuItem
                sx={{
                  fontSize: "1rem",
                  color: "primary.main",
                  padding: "0.2rem 0.5rem",
                }}
                onClick={() => handleRedirect("w/o", cellValues?.row?.id)}
              >
                Consolidated w/o images
              </MenuItem>
            </StyledMenu>
          
          </span>
        </>)
      }
    },
  ];

     


    let rows: any = [];

    let dataGridDataGenerator: any =
      costingTableData &&
      costingTableData?.data?.map((ideaItem: any, index: any) => {
        let objShared: any = {
          id: ideaItem?.id,
          sno: index + 1,
          part_no:ideaItem?.part_no,
          name:ideaItem?.name,
          category:ideaItem?.category,
          weight:ideaItem?.weight,
          Qty :ideaItem?.vault_details?.Qty,
          cost_per_kg:ideaItem?.vault_details?.cost_per_kg,
          per_unit_cost:ideaItem?.vault_details?.per_unit_cost,
          material_cost:ideaItem?.vault_details?.material_cost,
          process_cost:ideaItem?.vault_details?.process_cost,
          overhead_cost:ideaItem?.vault_details?.overhead_cost,
          child_part_cost:ideaItem?.vault_details?.child_part_cost,
          total_cost:ideaItem?.vault_details?.total_cost,
          abbreviation:ideaItem?.abbreviation,
          ancestors:ideaItem?.ancestors,
          scenario_exists:ideaItem?.scenario_exists,
          scenario_show:ideaItem?.scenario_show,
          cost_status:ideaItem?.cost_status,
          bom_status:ideaItem?.bom_status,
          is_leaf:ideaItem?.is_leaf,
          color:ideaItem?.color
        };
        rows.push(objShared);
      });

      let dataStructureDataGrid: any = {
        columns: columns,
        rows: rows,
      };
    return (<>
    
    <Box
     adl-scrollbar="true"
     adl-scrollbar-width="0.3"
        sx={{
          height: { sm: '90vh', md: '89vh', lg: '86vh', xl: '91vh' },
          width: "100%",
          padding: '0 0.5rem',
          // marginTop:"1rem"
        }}>
            {costingTableDataLoader?<><Skeleton /><Skeleton /><Skeleton /><Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            <Skeleton />
            </>
        
       :<>
        { costingTableData && costingTableData?.data.length>0 &&  <DataGrid
          headerHeight={48}
          rowHeight={48}
          {...dataStructureDataGrid}
         loading={costingTableDataLoader}
          disableSelectionOnClick={true}
          components={{
            // Toolbar: () => {
            //   return CustomToolbar();
            // },
       
          }}
          sx={{
            "& ::-webkit-scrollbar": {
              width: "0.3rem",
              height: "0.3rem",
            },
            "& ::-webkit-scrollbar-thumb": {
              // background: $thumb-color;
              backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
              borderRadius: "10px",
            },
            "& ::- webkit-scrollbar-thumb: hover ": {
              // width: "4px",
              backgroundColor: "#045DE9 !important",
            },
            "::-webkit-scrollbar-track": {
              backgroundColor: "#e1e1f3 !important",
            },

            "&.MuiDataGrid-root": {
              border: "none",
              },

              "&.MuiDataGrid-root .MuiDataGrid-cell": {
              borderBottomColor: "primary.light",
              },

          }}
          density={"compact"}
          pageSize={100}
          rowsPerPageOptions={[100]}
        />
    
    
        }
        </>
}
   
    
    </Box>
    </>

    )
}
export default CostingCreateSortingPage;