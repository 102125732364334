import { createAction, createAsyncThunk } from "@reduxjs/toolkit";


import { ID } from "../../utlis/commonInterfaces";
import TRIANGULATION_SERVICE from "../Services/triangulation.services";



// -------------------------------------------------------------------------------------
// api actions
// -------------------------------------------------------------------------------------

export interface GetTriangulationData {
    topvault: number,
}


const getTriangulationData = createAsyncThunk(
    "triangulation/get",
    async ( arg: GetTriangulationData, { rejectWithValue } ) => {
        try {
            const { data } = await TRIANGULATION_SERVICE.getTriangulationData(
                arg.topvault
            );
            return {
                triangulation: data
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

export interface UpdateTriangulationArg {
    id: ID;
    // updates: Omit<Triangulation, "id">;\
    inference: string;
    // updates: Partial<Pick<Triangulation, "inference">>;
}

const updateTriangulation = createAsyncThunk(
    "triangulation/update",
    async ( arg: UpdateTriangulationArg, { rejectWithValue } ) => {
        try {
            const { data } = await TRIANGULATION_SERVICE.updateTriangulation( arg );
            return {
                triangulation: data
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);


// -------------------------------------------------------------------------------------
// reducer actions
// -------------------------------------------------------------------------------------
const resetTriangulation = createAction( "triangulation/reset" );
// -------------------------------------------------------------------------------------
// exports
// -------------------------------------------------------------------------------------

export {
    getTriangulationData,
    resetTriangulation,
    updateTriangulation
};