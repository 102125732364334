import Plotly from 'plotly.js';
import React from 'react';
import createPlotlyComponent from 'react-plotly.js/factory';
import useWindowDimensions from '../../../ui-reusable-component/useWindowDimensions';

interface PieChartComponentI{
  pieChart:{name:string,value:number,count:number}[];
}


const Plot = createPlotlyComponent(Plotly);
const COLORS = ["#007fff", "#a3c182", "red"];

const PieChartComponent:React.FC<PieChartComponentI>=(props)=>{
const {pieChart}=props;
const pieData:number[] = pieChart && pieChart?.map((item:{name:string,value:number,count:number})=>{return item?.value});
const c:number[] = pieChart && pieChart?.map((item:{name:string,value:number,count:number})=>{return item?.count});
const pieDataLabel:string[] = pieChart && pieChart?.map((item:{name:string,value:number,count:number})=>{return item?.name});
const { height, width } = useWindowDimensions();

return(<>
<div style={{width:'auto',margin:'1rem 0'}}>
  <Plot
      data={[{
          values: pieData,
          labels: pieDataLabel,
          text:c,
          marker: {
              colors: COLORS
          },
          texttemplate: "₹%{value}, %{percent}",
          type: "pie",
          hovertemplate:"<b>%{label}</b><br>%{text} Ideas<br>₹%{value}<br>%{percent}<extra></extra>",
      }]}
      layout={{
          height: width > 1440 ? 220 : 160,
          width: width > 1440 ? 300 : 280,
          margin: {
              l: 25, t: 15, r: 0, b: 15,
          },
          showlegend: false,
          hovermode: 'closest'
      }}
      config={{
          displaylogo: false,
      }}
  />
</div>
</>)
};
export default PieChartComponent;