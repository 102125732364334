import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import { useParams } from "react-router-dom";
// import { makeStyles } from '@mui/styles';
import Typography from "@mui/material/Typography";
// import Typography from "@material-ui/core/Typography";
// import Typography from 'material-ui/styles/typography';
import React, { Fragment, useEffect, useState } from "react";
import ViewComponents from "./ViewComponents";
import styles from "./ArchiteactureModule.module.scss";
import { makeStyles } from "@mui/styles";
import { API } from "../api-services";
import BouncingLoader from "../ui-reusable-component/BouncingLoader";
import SubBouncingLoader from "../ui-reusable-component/SubBouncingLoader";
import InfiniteScroll from "react-infinite-scroll-component";
import { ADMIN } from "../Redux/Services/admin.service";
import NoDocuments from ".././Assets/images/svgs/NoData.svg";
import RViewerJS from "viewerjs-react";
import Image from "./FeatureLandingPage/ArchitectureImageCard";
import NoImages from "../Assets/images/loading-screen.gif";
import { Skeleton } from "@mui/material";

export interface ArchitectureModuleProps {}

export default function ArchitectureModule(props: ArchitectureModuleProps) {
  // @ts-ignoreTopLevel
  const { projectId, topVault, vault, leafNode, treeabbr } = useParams<any>();

  const [ArchData, setArchData] = useState<any>([]);
  const [Loader, setLoader] = useState<any>(false);
  const [items, setItems] = useState([]);
const[ModuleCount,setModuleCount]=useState<any>()
  const [hasMore, sethasMore] = useState(true);

  const [page, setpage] = useState(2);
  const toggleTopvault = () => {
    if (topVault !== 0) {
      if (treeabbr == "product") {
        return { top_vault: topVault };
      } else {
        return { vault: vault };
      }
    }
  };


  useEffect(() => {
    API.get(
      "customer/view_data_module/",
      { ...toggleTopvault(), module_count: true },
      0
    ).then((res: any) => {
      setModuleCount(res.data);
    });
  }, [vault]);

  useEffect(() => {
    if (topVault != 0) {
      const getComments = async () => {
        setLoader(true);
        API.get("/customer/view_data/", {
          arch_type: true,
          ...toggleTopvault(),
          page_items: 16,
          page_no: 1,
        })
          .then((res: any) => {
            setArchData(res?.data);
            setLoader(false);
          })
          .catch((err: any) => {
            setLoader(false);
            // ADMIN.toast.throwError(err, " Server Error");
          });
      };
      getComments();
    }
  }, [vault, leafNode, topVault]);

  const fetchComments = async () => {
    try {
      //@ts-ignore
      const { data } = await API.get("/customer/view_data/", {
        arch_type: true,
        ...toggleTopvault(),
        page_items: 16,
        page_no: page,
      });
      return data;
    } catch (e) {
      console.error("server error", e);
      return null;
    }
  };

  const fetchData = async () => {
    const commentsFormServer: any = await fetchComments();

    setArchData([...ArchData, ...commentsFormServer]);
    if (commentsFormServer?.length === 0 || commentsFormServer?.length < 16) {
      sethasMore(false);
    }
    setpage(page + 1);
  };

  // useEffect(() => {
  //   setLoader(true)
  //   API.get("/customer/view_data/", { arch_type: true, ...toggleTopvault() }).then((res: any) => {
  //     setArchData(res?.data)
  //     setLoader(false)
  //   })

  // }, [VaultID, TopLevel])

  return (
    <div
      id="scrollableDiv"
      // style={{
      //   height: "50rem",
      //   overflow: 'auto',
      //   display: 'flex',
      //   // flexDirection: 'column-reverse',
      // }}
      className={styles.ArchInfiniteScrollBar}
    >
      {Loader ? (
        // <SubBouncingLoader />
       <Skeleton animation="pulse" height={400} />
      
      ) : ArchData && ArchData?.length > 0 ? (
        <InfiniteScroll
          dataLength={ArchData?.length} //This is important field to render the next data
          next={fetchData}
          hasMore={hasMore}
          // loader={<Loader />}
          loader={<h4></h4>}
          endMessage={
            <p style={{ textAlign: "center" }}>
              <Typography sx={{ color: "primary.main", fontWeight: "700" }}>
                ** End **
              </Typography>
            </p>
          }
          scrollableTarget="scrollableDiv"
        >
          {/* @ts-ignore */}
          <RViewerJS>
            <div             
             className={ ModuleCount && ModuleCount?.valid_module_count == 6 ? styles.archData6 : ModuleCount && ModuleCount?.valid_module_count == 5 ? styles.archData5 : ModuleCount && ModuleCount?.valid_module_count == 4 ? styles.archData4 : ModuleCount && ModuleCount?.valid_module_count == 3 ? styles.archData3 : ModuleCount && ModuleCount?.valid_module_count == 2 ? styles.archData3 : ModuleCount && ModuleCount?.valid_module_count == 1 ? styles.archData1 : styles.archData  }>
              <Fragment>
                {ArchData &&
                  ArchData.map((archItem: any, index: any) => {
                    return (
                      <>
                        {/* <img src={archItem?.original || NoImages} className={ styles.archImageIn}/> */}
                        <Image
                          className={styles.archImageIn}
                          src={archItem?.original}
                          placeholderImg={NoImages}
                        />
                      </>
                    );
                  })}
              </Fragment>
            </div>
          </RViewerJS>
        </InfiniteScroll>
      ) : (
        <span className={styles.archIamges}>
          <img style={{ width: "2rem" }} src={NoDocuments} alt="no data" />
          <span>No Images</span>
        </span>
      )}
    </div>
  );
}
