import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchChildNodes, fetchTopLevelNodes } from "../Services/hierarchy.service";
import { ID } from "../../utlis/commonInterfaces";

// Fetch top-level nodes
export const fetchTopLevelNodesAction = createAsyncThunk(
  "hierarchy/fetchTopLevelNodes",
  async (_, { rejectWithValue }) => {
    try {
      const response:any = await fetchTopLevelNodes();
      return response.data; // Assuming the response has a "data" field
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Fetch child nodes by node ID
export const fetchChildNodesAction = createAsyncThunk(
  "hierarchy/fetchChildNodes",
  async (nodeId: ID, { rejectWithValue }) => {
    try {
      const response:any = await fetchChildNodes(nodeId);
      return { data: response.data, parentId: nodeId }; // Assuming the response has a "data" field
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

// Action to manage the open state of nodes
export const setOpenNodes = createAction<ID[]>("hierarchy/setOpenNodes");
