import React, {useState} from "react";
import {
  Box,
  Modal,
  Typography,
} from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import styles from "../IdeaDashboard/IdeaShowModal/IdeaShowModal.module.scss";
import useWindowDimensions from "../../../ui-reusable-component/useWindowDimensions";

import Clock from "./Clock";
import TotalCountScore from "./CountBoard";

interface WorkshopIdeasI {
  onClose: () => void;
  isOpen: boolean;
//   bubblePoints: any;
//   scatterIdeaData: any;
}




const DashBoardWorkshop: React.FC<WorkshopIdeasI> = (props) => {
  // const {height, width} = useWindowDimensions();
  const {isOpen, onClose, } = props;

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      sx={{backgroundColor: 'rgb(94,43,83)',
        background: 'linear-gradient(128deg, rgba(94,43,83,1) 7%, rgba(40,42,54,1) 96%)'}}
    >
      <Box
        className={styles.container}
        adl-scrollbar="true"
        adl-scrollbar-width="0.6"
        sx={{
          flexGrow: 1,
          bgcolor: "#f9e0be",
          height:"85vh",
          width: "85vw",
          borderRadius:'2rem',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', height: '3.5rem' }}>
            <Box style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: '-2rem'
              }}>
            {/* <Clock/> */}
            </Box>
            <CancelIcon titleAccess="Close" sx={{fontSize:'2.5rem', cursor: 'pointer', margin: '0.5rem' }} onClick={onClose} />
          </Box>
       
        <Box style={{display:'flex',alignItems:'center',justifyContent:'center',flexDirection:'column', height: "80%", width: '100%' }}>
            <Clock/>
            <Typography sx={{color:'#28282B',fontSize:'5rem',fontWeight:'700'}}>Total Idea Generated in this workshop</Typography>

            <TotalCountScore/>
           
        </Box>
      </Box>
    </Modal>
  );
};
export default DashBoardWorkshop;
