const getTopThreeHighestObjectsIds = (array: Array<any>, key: string) => {
    const newArray = [...array]
    const sortedArray = newArray.sort((a, b) => b[key] - a[key]);
    const topThreeHighestObjects = sortedArray.slice(0, 3);
    const topThreeHighestObjectsIds = topThreeHighestObjects.map(
        (object) => object.id
    );
    return topThreeHighestObjectsIds;
    };

    export { getTopThreeHighestObjectsIds };
     