// react
import axios from "axios";
import { API } from "../../../api-services";
import { MODULES } from "../../../Constants/modules.constants";
import { s3Url } from "../../../utlis/s3url.utils";

const MachineFlagS3UploadFetch = async (
  fileList: any,
  values: any,
  setIsLoadingSave: any,
  setCounter: any,
  formik: any,
  setFlagFile: any,
  Id: any,
  type: any,
  swalMsgalterHandlerAdd: any,
  onCloseModal: any,
  typeId: any,
  revisionId: any
) => {
  // const file_name = file?.name;
  var random: any = Math.floor(Math.random() * 1000000 + 1);

  var obj: Array<any> = [];
  const sortAlphaNum = (a: any, b: any) =>
    a.localeCompare(b, "en", { numeric: true });
  var img: any = "";
  // const dispatch = useAppDispatch();
  // + "-" + uuidv4();
  setIsLoadingSave(true);
  API.get("cad/cad_upload/", {
    module: MODULES.CAD,
  }).then((res) => {
    const data = Array.from(fileList).forEach((file: any, index: any) => {
      const formData = new FormData();
      formData.append("key", `inward/${values?.id}/${random}/${file.name}`);
      formData.append("Content-Type", file?.type);
      formData.append("x-amz-server-side-encryption", "AES256");
      formData.append("X-Amz-Credential", res.data.data.x_amz_credential);
      formData.append("X-Amz-Algorithm", "AWS4-HMAC-SHA256");
      formData.append("X-Amz-Date", res.data.data.x_amz_date);
      formData.append("X-Amz-Signature", res.data.data.aws_policy_signature);
      formData.append("Policy", res.data.data.aws_policy);
      formData.append("file", file, file?.name);

      axios
        .post(s3Url, formData, {
          onUploadProgress: (data) => {
            //Set the progress value to show the progress bar
          },
        })
        .then((res: any) => {
          //  console.log("dchidcdcidcihdicdhicdihc",res.status)
          if (res.status === 204) {
            setIsLoadingSave(true);

            if (type == "Create") {
              API.post("/api/db/machine/", {
                flag: `/inward/${values?.id}/${random}/${file?.name}`,
                ...values,
              })
                .then((res: any) => {
                  API.post("/api/db/machine_cost/", {
                    commodity: typeId,

                    machines: [res.data.id],

                    revision: revisionId,
                  })
                    .then((res: any) => {
                      formik.resetForm();
                      setCounter((prev: any) => prev + 1);
                      setIsLoadingSave(false);
                      //   swalMsgalterHandlerAdd(true,"create","")
                      onCloseModal();
                      setFlagFile(null);
                    })
                    .catch((err: any) => {});
                })
                .catch((err: any) => {
                  const { data } = err?.response;
                  //  onCloseModal()
                  swalMsgalterHandlerAdd(false, "create", data);

                  setIsLoadingSave(false);
                });
            }
            if (type == "Edit") {
              API.put(`/api/db/machine/${Id}/`, {
                flag: `/inward/${values?.id}/${random}/${file?.name}`,
                ...values,
              })
                .then((res: any) => {
                  setCounter((prev: any) => prev + 1);
                  setIsLoadingSave(false);
                  swalMsgalterHandlerAdd(true, "edit", "");
                  onCloseModal();

                  setFlagFile(null);
                })
                .catch((err: any) => {
                  setIsLoadingSave(false);
                  const { data } = err?.response;
                  //  onCloseModal()
                  swalMsgalterHandlerAdd(false, "edit", data);
                });
            }
          }
        });
    });
  });
};

// ----------------------------------------------------------------------------------

export default MachineFlagS3UploadFetch;
