import { Box, TextField } from "@mui/material";
import * as React from "react";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { debounce } from "lodash";
import { API } from "../../api-services";
import { ADMIN } from "../../Redux/Services/admin.service";
import { useRouteMatch } from "react-router-dom";

export interface IEditDescriptionProps {
  item?: any;
  setEditDes?: any;
  editDes?: any;
  type?: any;
  useraction?: any;
  useraction1?: any;
}

export function EditDescription(props: IEditDescriptionProps) {
  const { item, setEditDes, editDes, type, useraction, useraction1 } = props;
  const { url } = useRouteMatch<any>()

  const { library } = useRouteParams<any>();
  const [description, setDescription] = React.useState<any>("");
  React.useEffect(() => {
    if (item) {
      setDescription(item?.description);
    }
  }, [item]);

  const ExactDebounce = React.useRef(
    debounce(async (value: any) => {
      await API.put(
        `/config/costing_configuration/${item.id}/`,
        {
          update_library:
            type === "commodity" ? "commodity_library" : "process_library",
          description: value,
        },
        {},
        0
      )
        .then((res: any) => {
          ADMIN.toast.info("Description Updated Successfully");
        })
        .catch((err: any) => {
        });
    }, 1000)
  ).current;

  React.useEffect(() => {
    return () => {
      ExactDebounce.cancel(); // Cancel the debounced function
    };
  }, []);
  return (
    <Box>
      {url?.includes("/data-base/commodity-process") ?
        <TextField
          placeholder="Description"
          variant="standard"
          value={description}
          sx={{
            width: "100%",
            ".MuiInputBase-root.MuiInput-root": {
              fontSize: "1rem !important",
            },
            "& .MuiInput-underline:before": {
              borderBottomColor: "primary.main",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "primary.main",
            },
          }}
          disabled={item?.lock == true ? true : useraction && useraction?.includes("U") ? false : true}
          onChange={(event: any) => {
            const { value } = event.target;
            setDescription(value);
            ExactDebounce(value);
          }}
        /> : <TextField
          placeholder="Description"
          variant="standard"
          value={description}
          
          sx={{
            width: "100%",
            ".MuiInputBase-root.MuiInput-root": {
              fontSize: "1rem !important",
            },
            "& .MuiInput-underline:before": {
              borderBottomColor: "primary.main",
            },
            "& .MuiInput-underline:after": {
              borderBottomColor: "primary.main",
            },
          }}
          disabled={item?.lock == true ? true : useraction1.includes("U") ? false : true}
          onChange={(event: any) => {
            const { value } = event.target;
            setDescription(value);
            ExactDebounce(value);
          }}
        />
      }

    </Box>
  );
}
