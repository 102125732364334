import * as React from "react";
import { Box, Checkbox, IconButton, Typography } from "@mui/material";
import { useHistory, useRouteMatch } from "react-router-dom";

import warningImg from "../../Assets/images/Warning.png";
import { useRouteParams } from "../ui-reusable-component/useRouteParams";
import { API } from "../api-services";
import { useEffect } from "react";
interface ICostingConfigurationDrawerProps { }
function DataBaseDrawer(props: ICostingConfigurationDrawerProps) {
  const history = useHistory();
  const { url } = useRouteMatch();
  const { projectId, projectname } = useRouteParams<any>();
  const [DataBaseCount, setDataBaseCount] = React.useState<any>();
  const [Datatype, setDatatype] = React.useState<any>();
  const [checkCosting, setCheckCosting] = React.useState<any>(false);
  const [systemName, setSystemName] = React.useState<any>(false);
  const [SystemData, setSystemData] = React.useState<any>([]);
  const [GetCategoryIds, setGetCategoryIds] = React.useState<any>([]);
  const [useraction, setuseraction] = React.useState<any>(null);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  React.useEffect(() => {
    if (
      url.includes("revision-database") ||
      url.includes("commodity-process")
    ) {
      setCheckCosting(true);
    }
  }, [url]);

  React.useEffect(() => {
    if (
      url.includes("system-names-database") ||
      url.includes("feature-names-database")
    ) {
      setSystemName(true);
    }
  }, [url]);

  React.useEffect(() => {
    API.get(
      "/auth/calibration_user_permission/",
      {
        action: true,
        database: true,
        alldb: true,
      },
      0
    )
      .then((res: any) => {
        setuseraction(res?.data);
        // sessionStorage.setItem("DBPermission", res.data.action);
      })
      .catch((err: any) => { });
  }, []);

  const Redirection = (type: any) => {
    if (type == "Feature") {
      sessionStorage.setItem('storedExpanded', '0'); // Default expanded
      sessionStorage.setItem('storedSelectedId', '0'); // Default selected ID
      sessionStorage.setItem('storedSelectedTab', '0'); // Default selected tab
      API.get("/api/db/vehicle_type/")
        .then((res: any) => {
          setGetCategoryIds(res?.data[0]?.id);
          API.get(
            "/api/db/sub_system/",
            {
              vehicle_types: res?.data && res?.data[0]?.id,
            },
            0
          )
            .then((res1: any) => {
              setSystemData(res?.data[0]?.id);
              history.push(
                `/data-base/feature-names-database/${res?.data && res?.data[0]?.id}/${res1?.data[0]?.id}/${0}/0`
              );
            })
            .catch(() => { });
        })
        .catch(() => {
          console.log("Srver Error");
        });
    } else {
      API.get("/api/db/vehicle_type/")
        .then((res: any) => {
          setGetCategoryIds(res?.data[0]?.id);
          API.get(
            "/api/db/sub_system/",
            {
              vehicle_types: res?.data && res?.data[0]?.id,
            },
            0
          )
            .then((res1: any) => {
              setSystemData(res?.data[0]?.id);
              history.push(
                `/data-base/system-names-database/${res?.data && res?.data[0]?.id
                }/${res1?.data[0]?.id}/${0}`
              );
            })
            .catch(() => { });
        })
        .catch(() => {
          console.log("Srver Error");
        });
    }
  };

  useEffect(() => { }, []);
  React.useEffect(() => {
    API.get("/api/db/database_module_count/")
      .then((res: any) => {
        setDataBaseCount(res?.data);
      })
      .catch(() => {
        console.log("Sever Error");
      });
  }, [projectId]);

  React.useEffect(() => {
    API.get("/config/parameter_types/", {
      parameter_database: true,
      first_datatype: true,
    })
      .then((res: any) => {
        setDatatype(res?.data);
      })
      .catch(() => {
        console.log("Sever Error");
      });
  }, [projectId]);

  const Revisionroute = () => {
    if (
      (useraction?.revision_main == true && useraction?.scenario == true) ||
      (useraction?.revision_main == true && useraction?.scenario == false)
    ) {
      history.push(`/data-base/revision-database/1/0/0/0/0/0/0/${true}/0/0`);
    } else if (
      useraction?.revision_main == false &&
      useraction?.scenario == true
    ) {
      history.push(`/data-base/revision-database/2/0/0/0/0/0/0/${true}/0/0`);
    }
  };

  // console.log( useraction && useraction?.database,"useractionuseractionuseraction")

  return (
    <div>
      <Box
        sx={{ padding: "0 0.5rem" }}
      // onClick={toggleDrawer(anchor, false)}
      // onKeyDown={toggleDrawer(anchor, false)}
      >
        <Box sx={{ padding: "0 0.5rem" }}>
          {useraction && useraction?.parameter && (
            <Box sx={{ alignItems: "center", display: "flex" }}>
              <Checkbox
                style={{ padding: "3px" }}
                checked={url.includes("Parameter-library")}
                onClick={() =>
                  history.push(
                    `/data-base/Parameter-library/${Datatype && Datatype?.data_type
                    }/${0}`
                  )
                }
                inputProps={{ "aria-label": "controlled" }}
                sx={{
                  "& .MuiSvgIcon-root": { fontSize: { lg: "24", xl: "30" } },
                }}
              />
              <Box
                // key={index}
                onClick={() =>
                  history.push(
                    `/data-base/Parameter-library/${Datatype && Datatype?.data_type
                    }/${0}`
                  )
                }
                sx={{
                  padding: "0.2rem",
                  margin: "0.5rem,0rem",
                  backgroundColor: url.includes("Parameter-library")
                    ? "primary.main"
                    : "primary.light",
                  color: url.includes("Parameter-library")
                    ? "white"
                    : "primary.main",
                  textAlign: "left",
                  cursor: "pointer",
                  display: "inline-block",
                  width: "100%",
                }}
              >
                <Typography
                  style={{ fontSize: "0.87rem", textTransform: "capitalize" }}
                >
                  Parameter Library ({DataBaseCount && DataBaseCount?.parameter}
                  )
                </Typography>
              </Box>
            </Box>
          )}

          <Box sx={{ alignItems: "center", display: "flex" }}>
            <Checkbox
              style={{ padding: "3px" }}
              checked={url.includes("Feature-library")}
              onClick={() =>
                history.push(
                  `/data-base/Feature-library/${Datatype && Datatype?.data_type
                  }/${0}`
                )
              }
              inputProps={{ "aria-label": "controlled" }}
              sx={{
                "& .MuiSvgIcon-root": { fontSize: { lg: "24", xl: "30" } },
              }}
            />
            <Box
              // key={index}
              onClick={() =>
                history.push(
                  `/data-base/Feature-library/${Datatype && Datatype?.data_type
                  }/${0}`
                )
              }
              sx={{
                padding: "0.2rem",
                margin: "0.5rem,0rem",
                backgroundColor: url.includes("Feature-library")
                  ? "primary.main"
                  : "primary.light",
                color: url.includes("Feature-library")
                  ? "white"
                  : "primary.main",
                textAlign: "left",
                cursor: "pointer",
                display: "inline-block",
                width: "100%",
              }}
            >
              <Typography
                style={{ fontSize: "0.87rem", textTransform: "capitalize" }}
              >
                Feature Library

              </Typography>
            </Box>
          </Box>



          {useraction && useraction?.systems && (
            <Box sx={{ alignItems: "center", display: "flex" }}>
              <Checkbox
                style={{ padding: "3px" }}
                checked={systemName || url.includes("system-names-database")}
                // checked={systemName || url.includes("revision-database")||url.includes("commodity-process")}
                onChange={() => setSystemName((prev: any) => !prev)}
                inputProps={{ "aria-label": "controlled" }}
                sx={{
                  "& .MuiSvgIcon-root": { fontSize: { lg: "24", xl: "30" } },
                }}
              />
              <Box
                // key={index}
                onClick={() => setSystemName((prev: any) => !prev)}
                sx={{
                  padding: "0.2rem",
                  margin: "0.5rem,0rem",
                  backgroundColor: systemName
                    ? "primary.main"
                    : "primary.light",
                  color: systemName ? "white" : "primary.main",
                  textAlign: "left",
                  cursor: "pointer",
                  display: "inline-block",
                  width: "100%",
                }}
              >
                <Typography
                  style={{ fontSize: "0.87rem", textTransform: "capitalize" }}
                >
                  Systems & Names
                </Typography>
              </Box>
            </Box>
          )}
          {systemName && (
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                marginLeft: "1.5rem",
              }}
            >
              <Checkbox
                style={{ padding: "3px" }}
                checked={url.includes("system-names-database")}
                onClick={() => Redirection("Bom")}
                inputProps={{ "aria-label": "controlled" }}
                sx={{
                  "& .MuiSvgIcon-root": { fontSize: { lg: "24", xl: "30" } },
                }}
              />
              <Box
                // key={index}
                onClick={() => Redirection("Bom")}
                sx={{
                  padding: "0.2rem",
                  margin: "0.5rem,0rem",
                  backgroundColor: url.includes("system-names-database") ? "primary.main" : "primary.light",
                  color: url.includes("system-names-database") ? 'white' : "primary.main",
                  textAlign: "left",
                  cursor: "pointer",
                  display: "inline-block",
                  width: "100%",
                }}
              >
                <Typography
                  style={{ fontSize: "0.87rem", textTransform: "capitalize" }}
                >
                  Bom Part Name ({DataBaseCount && DataBaseCount?.systems_name})
                </Typography>
              </Box>
            </Box>
          )}
          {systemName && (
            <Box
              sx={{
                alignItems: "center",
                display: "flex",
                marginLeft: "1.5rem",
              }}
            >
              <Checkbox
                style={{ padding: "3px" }}
                // "/data-base/feature-names-database/:categoryId/:subsystemId/:search"
                checked={url.includes("feature-names-database")}
                onClick={() => Redirection("Feature")}
                inputProps={{ "aria-label": "controlled" }}
                sx={{
                  "& .MuiSvgIcon-root": { fontSize: { lg: "24", xl: "30" } },
                }}
              />
              <Box
                // key={index}

                onClick={() => Redirection("Feature")}
                sx={{
                  padding: "0.2rem",
                  margin: "0.5rem,0rem",
                  backgroundColor: url.includes("feature-names-database") ? "primary.main" : "primary.light",
                  color: url.includes("feature-names-database") ? 'white' : "primary.main",
                  textAlign: "left",
                  cursor: "pointer",
                  display: "inline-block",
                  width: "100%",
                }}
              >
                <Typography
                  style={{ fontSize: "0.87rem", textTransform: "capitalize" }}
                >
                  Feature Names
                </Typography>
              </Box>
            </Box>
          )}
          {useraction && useraction?.supplier && (
            <Box sx={{ alignItems: "center", display: "flex" }}>
              <Checkbox
                style={{ padding: "3px" }}
                checked={url.includes("supplier-database")}
                onClick={() => history.push(`/data-base/supplier-database/1`)}
                inputProps={{ "aria-label": "controlled" }}
                sx={{
                  "& .MuiSvgIcon-root": { fontSize: { lg: "24", xl: "30" } },
                }}
              />
              <Box
                // key={index}
                onClick={() => history.push(`/data-base/supplier-database/1`)}
                sx={{
                  padding: "0.2rem",
                  margin: "0.5rem,0rem",
                  backgroundColor: url.includes("supplier-database")
                    ? "primary.main"
                    : "primary.light",
                  color: url.includes("supplier-database")
                    ? "white"
                    : "primary.main",
                  textAlign: "left",
                  cursor: "pointer",
                  display: "inline-block",
                  width: "100%",
                }}
              >
                <Typography
                  style={{ fontSize: "0.87rem", textTransform: "capitalize" }}
                >
                  Supplier Library (
                  {DataBaseCount && DataBaseCount?.supplier_libray}){" "}
                </Typography>
              </Box>
            </Box>
          )}
          {useraction && useraction?.categories && (
            <Box sx={{ alignItems: "center", display: "flex" }}>
              <Checkbox
                style={{ padding: "3px" }}
                checked={url.includes("data-base/bomCategories")}
                onClick={() => history.push(`/data-base/bomCategories`)}
                inputProps={{ "aria-label": "controlled" }}
                sx={{
                  "& .MuiSvgIcon-root": { fontSize: { lg: "24", xl: "30" } },
                }}
              />
              <Box
                // key={index}
                onClick={() => history.push(`/data-base/bomCategories`)}
                sx={{
                  padding: "0.2rem",
                  margin: "0.5rem,0rem",
                  backgroundColor: url.includes("data-base/bomCategories")
                    ? "primary.main"
                    : "primary.light",
                  color: url.includes("data-base/bomCategories")
                    ? "white"
                    : "primary.main",
                  textAlign: "left",
                  cursor: "pointer",
                  display: "inline-block",
                  width: "100%",
                }}
              >
                <Typography
                  style={{ fontSize: "0.87rem", textTransform: "capitalize" }}
                >
                  BOM Categories ({DataBaseCount && DataBaseCount?.bom_category}
                  )
                </Typography>
              </Box>
            </Box>
          )}
          {useraction && useraction?.database && (
            <Box sx={{ alignItems: "center", display: "flex" }}>
              <Checkbox
                style={{ padding: "3px" }}
                checked={
                  checkCosting ||
                  url.includes("revision-database") ||
                  url.includes("commodity-process")
                }
                onChange={() => setCheckCosting((prev: any) => !prev)}
                inputProps={{ "aria-label": "controlled" }}
                sx={{
                  "& .MuiSvgIcon-root": { fontSize: { lg: "24", xl: "30" } },
                }}
              />
              <Box
                // key={index}
                onClick={() => setCheckCosting((prev: any) => !prev)}
                sx={{
                  padding: "0.2rem",
                  margin: "0.5rem,0rem",
                  backgroundColor: checkCosting
                    ? "primary.main"
                    : "primary.light",
                  color: checkCosting ? "white" : "primary.main",
                  textAlign: "left",
                  cursor: "pointer",
                  display: "inline-block",
                  width: "100%",
                }}
              >
                <Typography
                  style={{ fontSize: "0.87rem", textTransform: "capitalize" }}
                >
                  Costing DB
                </Typography>
              </Box>
            </Box>
          )}
          {useraction && useraction?.revision && (
            <>
              {checkCosting && (
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    marginLeft: "1.5rem",
                  }}
                >
                  <Checkbox
                    style={{ padding: "3px" }}
                    checked={url.includes("revision-database")}
                    onClick={() => Revisionroute()}
                    inputProps={{ "aria-label": "controlled" }}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: { lg: "24", xl: "30" },
                        color: "#2b9cf6eb",
                      },
                    }}
                  />
                  <Box
                    // key={index}
                    onClick={() => Revisionroute()}
                    // onClick={() =>

                    //     history.push(`/data-base/revision-database/1/0/0/0/0/0/0/${true}/0`)}
                    sx={{
                      padding: "0.2rem",
                      margin: "0.5rem,0rem",
                      backgroundColor: url.includes("revision-database")
                        ? "#2b9cf6eb"
                        : "primary.light",
                      color: url.includes("revision-database")
                        ? "white"
                        : "primary.main",
                      textAlign: "left",
                      cursor: "pointer",
                      display: "inline-block",
                      width: "100%",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "0.87rem",
                        textTransform: "capitalize",
                      }}
                    >
                      Revisions
                    </Typography>
                  </Box>
                </Box>
              )}
            </>
          )}
          {useraction && useraction?.cost_commodity && (
            <>
              {checkCosting && (
                <Box
                  sx={{
                    alignItems: "center",
                    display: "flex",
                    marginLeft: "1.5rem",
                  }}
                >
                  <Checkbox
                    style={{ padding: "3px" }}
                    checked={url.includes("commodity-process")}
                    onClick={() => history.push("/data-base/commodity-process")}
                    inputProps={{ "aria-label": "controlled" }}
                    sx={{
                      "& .MuiSvgIcon-root": {
                        fontSize: { lg: "24", xl: "30" },
                        color: "#2b9cf6eb",
                      },
                    }}
                  />
                  <Box
                    // key={index}
                    onClick={() => history.push("/data-base/commodity-process")}
                    sx={{
                      padding: "0.2rem",
                      margin: "0.5rem,0rem",
                      backgroundColor: url.includes("commodity-process")
                        ? "#2b9cf6eb"
                        : "primary.light",
                      color: url.includes("commodity-process")
                        ? "white"
                        : "primary.main",
                      textAlign: "left",
                      cursor: "pointer",
                      display: "inline-block",
                      width: "100%",
                    }}
                  >
                    <Typography
                      style={{
                        fontSize: "0.87rem",
                        textTransform: "capitalize",
                      }}
                    >
                      Commodity & Process
                    </Typography>
                  </Box>
                </Box>
              )}

            </>
          )}
        </Box>

      </Box>

    </div>
  );
}
export default DataBaseDrawer;
