// Clock.tsx
import React, { useEffect, useState } from 'react';
import './Clock.scss'; // Import the SCSS file

interface ClockProps {}

const Clock: React.FC<ClockProps> = () => {
  const [date, setDate] = useState(new Date());

  useEffect(() => {
    const intervalId = setInterval(() => {
      setDate(new Date());
    }, 1000); // Update every second

    return () => clearInterval(intervalId);
  }, []);

  const formattedTime = date.toLocaleTimeString('en-US', {
    hour12: true, // Use 12-hour format (optional)
    hour: 'numeric',
    minute: '2-digit',
    second: '2-digit',
  });

  const formattedDate = date.toLocaleDateString('en-US', {
    weekday: 'long',
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  });

  return (
    <div className="clock">
      <div className="date">{formattedDate}</div>
      <div className="time">{formattedTime}</div>
    </div>
  );
};

export default Clock;