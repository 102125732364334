import { API } from "../../api-services";
import { ID } from "../../utlis/commonInterfaces";
import { transformConfigurationsFromApi } from "../../utlis/configuration.constants";
import { ParameterConfig } from "../Reducers/parameterConfig.reducer";

const url = "api/db/process_mapping/";

export default {
    get ( processId: ID, all?: boolean ) {
        return API.get( url, { process: processId, all } );
    },
    getByTableId ( tableId: ID ) {
        return API.get( url, { table_id: tableId } );
    },
    async inherit (
        parameters: Record<string, any>[],
        meta?: Record<string, any>
    ) {
        const { data, ...rest } = await API.post(
            url,
            Object.assign( { parameters }, meta )
        );

        return { data: transformConfigurationsFromApi( data ), ...rest };
    },
    update ( configId: ID, updates: Partial<ParameterConfig> ) {
        return API.put( url + configId + "/", updates );
    },
    delete ( configId: ID ) {
        return API.delete( url + configId + "/" );
    },
};
