import React, { useEffect, useState } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Autocomplete, Chip, Divider, FormControl, InputLabel, TextField } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { LoadingButton } from '@mui/lab';
import { API } from '../../Redux/Services/index.service';
import sucessIcon from "../../Assets/images/success.png";
import { useRouteParams } from '../../ui-reusable-component/useRouteParams';
const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "40vw",
  bgcolor: 'background.paper',
  borderRadius: '10px',
  p: 1,
};
interface ShareParameterModalProps {


  CotegoryIds: any;
  isOpen: any;
  ClosedHandler: any;
  CategoryDatalist: any;
  projectIds: any;
  setcategoryCount: any;
  setcategoryData: any;

}

const ShareParameterModal = (props: ShareParameterModalProps) => {
  const { CotegoryIds, isOpen, ClosedHandler, CategoryDatalist, projectIds, setcategoryCount, setcategoryData } = props;
  const [Selectedcategory, setSelectedcategory] = useState<any>({})
  const [Selectedparamter, setSelectedparamer] = useState<any>([])
  const [loader, setloader] = useState<any>(false)
  const [CategoryList, setCategorylist] = useState<any>(CategoryDatalist && CategoryDatalist.filter((item: any, index: any) => { return item?.id !== CotegoryIds }))
  const [ParmeterList, setParameterList] = useState<any>([])
  const {
    projectId,
    projectname,
    categoryId,
    productId,
    datatype,
    validationId,
  } = useRouteParams<any>();
  const [SuccessLoader, setSuccessLoader] = useState<any>(false)
  const handleSelectcategory = (item: any) => {
    setSelectedcategory(item)
    console.log(item, "itemitem")
    API.get("/config/share_parameter_list/", { sur_category: categoryId, tar_category: item && item?.category, module: 17, project: projectIds }).then((res: any) => {
      setParameterList(res?.data)

    }).catch((err: any) => {

      console.log("server error")
    })


  }
  const handleSelectParameter = (item: any) => {
    setSelectedparamer(item)
    var Ids = item && item?.map((itm: any, index: any) => { return itm?.id })
    var datafilter = ParmeterList && ParmeterList?.filter((item1: any, index: any) => { return !Ids.includes(item1?.id) })

    setParameterList(datafilter)
  }


  useEffect(() => {

    setSuccessLoader(false)
  }, [])

  const saveHandler = () => {


    setloader(true)

    if (Selectedparamter && Selectedparamter?.length > 0)
      var Ids = Selectedparamter && Selectedparamter?.map((item: any, index: any) => { return item?.id })

    API.post("/config/share_feature/", { target_category: Selectedcategory && Selectedcategory?.category, category: categoryId, module: 17, target_project: projectIds, type: 4, catergoy_parameter: true, relation_ids: Ids?.toString() }).then((res: any) => {
      API.get("/config/feature_config/", { module: 17, project: projectIds }).then((res1: any) => {
        setcategoryData(res1?.data)

        setSuccessLoader(true)
        setloader(false)
      }).catch((err: any) => {
        setloader(false)
        setSuccessLoader(false)
      })

    }).catch((err: any) => {
      setloader(false)
      console.log("server error")
    })

  }
  return (
    <div><Modal
      open={isOpen}
      keepMounted
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >

      {SuccessLoader ?
        <Box sx={style}>
          <Box sx={{ textAlign: 'center', }} >
            <img src={sucessIcon} style={{ width: '7rem' }} />
            <Typography sx={{ color: "green", marginTop: '1rem' }}>Shared Successfully</Typography>
            <Button sx={{ marginTop: '1rem' }} variant='contained' size='small' onClick={ClosedHandler}>ok</Button>
          </Box>
        </Box>

        :
        <Box sx={style}>
          <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: '-2rem'
              }}
            >
              Share Parameters
            </Typography>
            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={ClosedHandler} />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          {/* <Typography>Share Parameters</Typography>
        <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={ClosedHandler} /> */}
          <Box sx={{ width: '100%', display: 'flex', columnGap: '1rem', alignItems: 'flex-end', padding: '0 1rem 1rem', }}>
            <Box sx={{ width: '100%', }}>
              <Box sx={{ display: 'flex', columnGap: '1rem', alignItems: 'center', }}>
                <FormControl fullWidth>
                  <InputLabel sx={{ color: 'primary.main', marginLeft: '-1rem' }} shrink>Select category</InputLabel>
                  <Autocomplete
                    id="multiple-limit-tags"
                    placeholder="Select vehicle"
                    //@ts-ignore
                    options={CategoryList && CategoryList}
                    //@ts-ignore
                    getOptionLabel={(option) =>
                      option?.name == undefined ? "" :
                        option?.name
                    }
                    // defaultValue={[top100Films[13], top100Films[12], top100Films[11]]}
                    sx={{
                      marginTop: '0.5rem',
                      '.MuiSvgIcon-root': { color: 'primary.main' },
                    }}
                    value={Selectedcategory}
                    onChange={(event, newValue, situation) =>
                      handleSelectcategory(newValue)
                    }
                    // @ts-ignore
                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          size="small"
                          sx={{ background: "#007fff14", fontSize: "1rem" }}
                          //@ts-ignore
                          label={option?.name}
                          {...getTagProps({ index })}
                        // disabled={fixedOptions.indexOf(option) !== -1}
                        />
                      ))
                    }
                    renderOption={(props, option, { selected }) => (
                      <li style={{ fontSize: "1rem" }} {...props}>
                        {/*@ts-ignore */}
                        {option?.name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // @ts-ignore 
                        // placeholder={"Select category"}
                        variant="standard"
                        sx={{
                          width: '100%',
                          fontSize: "1rem",
                          ".MuiInputBase-root.MuiInput-root": {
                            fontSize: '1rem !important'
                          },
                          '& .MuiInput-underline:before': { borderBottomColor: 'primary.main' },
                          '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                        }}
                      />
                    )}
                  />
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel sx={{ color: 'primary.main', marginLeft: '-1rem' }} shrink>Select Parameter</InputLabel>
                  <Autocomplete
                    multiple
                    id="multiple-limit-tags"
                    limitTags={4}
                    placeholder="Select vehicle"
                    disableCloseOnSelect
                    //@ts-ignore

                    options={ParmeterList && ParmeterList}
                    //@ts-ignore
                    getOptionLabel={(option) => option.parameter__name}
                    // defaultValue={[top100Films[13], top100Films[12], top100Films[11]]}
                    sx={{
                      marginTop: '0.5rem',
                      '.MuiSvgIcon-root': { color: 'primary.main' },
                    }}
                    value={Selectedparamter}
                    onChange={(event, newValue, situation) =>
                      handleSelectParameter(newValue)
                    }

                    // @ts-ignore

                    renderTags={(tagValue, getTagProps) =>
                      tagValue.map((option, index) => (
                        <Chip
                          size="small"
                          sx={{ background: "#007fff14", fontSize: "1rem" }}
                          //@ts-ignore
                          label={option?.parameter__name}
                          {...getTagProps({ index })}
                        // disabled={fixedOptions.indexOf(option) !== -1}
                        />
                      ))
                    }
                    renderOption={(props, option, { selected }) => (
                      <li style={{ fontSize: "1rem" }} {...props}>
                        {/*@ts-ignore */}
                        {option?.parameter__name}
                      </li>
                    )}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        // @ts-ignore 
                        // placeholder={"Select Parameter"}
                        variant="standard"
                        sx={{
                          width: '100%',
                          height: 'fit-content',
                          fontSize: "1rem",
                          ".MuiInputBase-root.MuiInput-root": {
                            fontSize: '1rem !important'
                          },
                          '& .MuiInput-underline:before': { borderBottomColor: 'primary.main' },
                          '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                        }}
                      />
                    )}
                  />
                </FormControl>
              </Box>
            </Box>
          </Box>
          <Box sx={{ width: '100%', textAlign: 'right', padding: '0 1rem' }}>
            <LoadingButton
              size="small"
              variant="contained"
              sx={{
                padding: "0.5rem",
                lineHeight: 1,                
                "&:hover": {
                  transform: 'Scale(1.05)',
                  transition: 'transform 0.5s ease',
                },
                '.MuiCircularProgress-root': {
                  color: 'primary.main',
                },                
              }}
              loading={loader}
              disabled={Selectedparamter && Selectedparamter?.length > 0 ? false : true} onClick={() => saveHandler()}>
              Share
            </LoadingButton>
          </Box>

        </Box>
      }

    </Modal></div>
  )
}

export default ShareParameterModal;