import React, { useRef, useState, useEffect } from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CancelIcon from '@mui/icons-material/Cancel';
import { Grid, Divider, InputLabel } from '@mui/material';
import Webcam from "react-webcam";
import { Autocomplete, FormControl, MenuItem, Select, TextField } from '@mui/material';
import { useParams } from 'react-router-dom';
import { userPermissionAction } from './BOMEntryLandingPage';
import RViewerJS from "viewerjs-react";
import { API } from '../../../api-services';
import { LoadingButton } from '@mui/lab';
import { useRouteParams } from '../../../ui-reusable-component/useRouteParams';
import { ADMIN } from '../../../Redux/Services/admin.service';
import useWindowDimensions from '../../../ui-reusable-component/useWindowDimensions';


type Props = {}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: " 50vw",
  height: "80vh",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

export interface ICameraModalProps {
  onCloseModal: any;
  isOpen: any;
  key1: any;
  val: any;
  setDataForUpdate: any;
  target: any;
  AllData: any;
  option: any;
  itmKey: any;
  setColorButton: any;
  setShowButton: any;
  setCounter: any;
  setSaveDisabled?: any;
  type?: any;
  setValue?: any;
  setRowData?: any;
  rowData?: any;
    
}

export default function CameraModal(props: ICameraModalProps) {
    const { onCloseModal, isOpen, itmKey, target, option, key1, setDataForUpdate, AllData, setShowButton, val, setColorButton, setCounter, setSaveDisabled, type, setValue, setRowData, rowData } = props;
    const [imageSrc, setImageSrc] = useState<any>(null);
    const webcamRef = React.useRef<Webcam>(null);
    const [imagePreview, setImagePreview] = useState<any>(false);
    
    const [loading, setLoading] = useState<any>(false);
    const { vault, topVault } = useRouteParams<any>();
  const getObj = () => {
    if (vault == 0) {
      return { top_vault: topVault };
    } else {
      return { vault: vault };
    }
  };
  const { height, width } = useWindowDimensions();
  const capturePhoto = React.useCallback(() => {
    const imageSrc = webcamRef.current?.getScreenshot();
    setImageSrc(imageSrc);
    setImagePreview(true);

  }, [webcamRef, setImageSrc]);

  function base64StringToBlob(base64: string, contentType: string): Blob {
    const byteCharacters = atob(base64.split(',')[1]);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += 512) {
      const slice = byteCharacters.slice(offset, offset + 512);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  const uploadPhoto = () => {
    if (imageSrc) {
      // Convert base64 to Blob
      const blob = base64StringToBlob(imageSrc, 'image/jpeg');
      // Create a File from Blob
      const file = new File([blob], "captured_image.jpg", { type: "image/jpeg" });
      console.log(file);
      // Implement the upload functionality here
      // Example: Using FormData to append the file and send it via fetch API
      const formData = new FormData();
      formData.append("image", file);
      setLoading(true);
      API.post(
        `/xcpep/table_parameter_image/`,
        formData,
        {
          ...getObj(),
          parameter: itmKey,
          image_param_id: option[0]?.key,
          index: target,
          new_ui: true,
        },
        0
      )
        .then((res: any) => {
          setColorButton("red")
          setShowButton(true)
          
          console.log(res?.data);
          if(type == "edit"){
            console.log(rowData)
            setValue(res.data[key1]);
          setRowData({...rowData, [key1]: res.data[key1]})
          }
          else{
            AllData.filter((item: any, index: any) => index === target)[0][key1] =
            res.data[key1] ? res.data[key1] : "";
          setDataForUpdate(AllData);
          }
          

          // setValue(res.data[key1]);
          
          // console.log(res);
          setCounter((prev: any) => prev + 1);
          setLoading(false);
          // if(type == "edit"){
          //   setSaveDisabled(false);
          // }
          
          onCloseModal();
          ADMIN.toast.success("Image Uploaded Successfully")

        })
        .catch((err: any) => {
          setLoading(false);
          onCloseModal();
          ADMIN.toast.error("Image Upload Failed")
        });
    }

  };

  const handleReset = () => {
    setImageSrc(null);
    setImagePreview(false);
  }
  // React.useEffect(() => {
  //   if (val) {
  //     setValue(val);
  //   } else {
  //     setValue("");
  //   }
  // }, [val]);


  return (

    <Modal
      open={isOpen}
      onClose={onCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >

      <Box sx={style}>

        <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
          <Typography id="modal-modal-title" style={{
            fontSize: "1rem",
            fontWeight: "600",
            width: "100%",
            textAlign: "center",
          }}>
            Capture Image
          </Typography>
          <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />

        </Box>

        <Divider sx={{ borderColor: 'primary.light', margin: '0 1rem', marginBottom: '1rem' }} />
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', }}>
          <Box sx={{height: '100%'}}>
            {!imagePreview && <Webcam
              audio={false}
              ref={webcamRef}              
              screenshotFormat="image/jpeg"
              style={{ width: '100%', height: width > 1368 ? '67vh' : '63vh', }}
            />}
          </Box>
          <div>

            {imageSrc && (
              <div>

                <img src={imageSrc} alt="Captured" />
              </div>
            )}
            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: "center", gap: "1rem", margin: "0.5rem" }}>
              {!imagePreview && <Button variant="contained" onClick={capturePhoto}>Capture</Button>}
              <Button variant="contained" disabled={!imagePreview} onClick={() => handleReset()}>Retake</Button>
              {imageSrc && <LoadingButton variant="contained" loading={loading} onClick={uploadPhoto}>Upload</LoadingButton>}
            </Box>

          </div>



        </Box>

      </Box>
    </Modal>

  )

}

