import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import ReplyIcon from "@mui/icons-material/Reply";
import { useHistory } from "react-router-dom";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import CancelIcon from '@mui/icons-material/Cancel';
import { Divider } from "@mui/material";
import sendIcon from "../../Assets/images/sendOutline.png";
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import { API } from "../../api-services";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: " 40rem",
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  // border: "2px solid #000",
  // boxShadow: 24,
  p: 0,
};

interface ProjectmailselectionModalProps {
  isOpen: any;
  onCloseModal: any;
  Emaildata: any;
}

const ProjectmailselectionModal = (props: ProjectmailselectionModalProps) => {
  const { isOpen, onCloseModal, Emaildata } = props;
  const [value, setValue] = React.useState("Delivery");
  const history = useHistory<any>();

  const [FileCount, setFileCount] = useState<any>()
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };


  const redirectionhandler = () => {
    history.push(`/mail-module/${value}/${Emaildata?.ProjectName}/${Emaildata?.ProjectIds}/0/${false}/0/0`);
  };


  const Logsredirection = (logs: any, logsname: any) => {
    history.push(`/Project-mail-logs/${Emaildata?.ProjectIds}/${logs}/${logsname}`)
  }
  useEffect(() => {
    API.get("/activity/mail_log_view/", { mail_count: true, project: Emaildata?.ProjectIds }).then((res: any) => {
      setFileCount(res?.data)
    }).catch((err: any) => {
      console.log("Server Error")
    })
  }, [Emaildata])


  useEffect(()=>{


    API.delete("/analytics/store_attachment_details/", { delete_all: true, mail_type: 3 }).then((res: any) => {
    
    }).catch((err: any) => {
     
      console.log("Server Error")
    })

    API.delete("/analytics/store_attachment_details/", { delete_all: true,mail_type:6 }).then((res: any) => {
    
    }).catch((err: any) => {
    
  }).catch((err: any) => {
   
  })
  },[])


  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem', backgroundColor: '#007fff14', padding: '0.5rem 1rem', justifyContent: 'space-between' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '5px' }}>
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  width: "100%",
                  textAlign: "left",
                }}
              >
                Send email
              </Typography>
              <img src={sendIcon} style={{ width: '1.5rem' }} />
            </Box>
            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', }} onClick={onCloseModal} />
          </Box>
          <Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                columnGap: '1rem',
                margin: '1rem 0',
                padding: '1rem',
                justifyContent: 'space-between',
              }}
            >
              <Typography sx={{ fontSize: '1rem' }}>Draft an email:</Typography>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby="demo-controlled-radio-buttons-group"
                  name="controlled-radio-buttons-group"
                  value={value}
                  onChange={handleChange}
                  sx={{
                    '.MuiFormControlLabel-root .MuiFormControlLabel-label': {
                      fontSize: '1rem'
                    }
                  }}
                >
                  <FormControlLabel
                    value="Delivery"
                    control={<Radio />}
                    label="Delivery mail"
                  />
                  <FormControlLabel
                    value="Other"
                    control={<Radio />}
                    label="Other mail"
                  />
                </RadioGroup>
              </FormControl>
              <Button
                sx={{ cursor: "pointer" }}
                onClick={() => redirectionhandler()}
              >
                <ReplyIcon
                  titleAccess="GO"
                  style={{
                    fontSize: "1.5rem",
                    transform: "scaleX(-1)",
                  }}                 
                />
              </Button>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '5px', backgroundColor: '#007fff14', padding: '0.5rem 1rem' }}>
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  textAlign: "left",
                }}
              >
                Email logs
              </Typography>
              <DescriptionOutlinedIcon sx={{ fontSize: '1.5rem' }} />
            </Box>
            <Box sx={{ padding: '1rem' }}>



              <Box>
                <Stack spacing={1} alignItems="center">
                  <Stack direction="row" spacing={1}>
                    <Chip
                      sx={{ width: "17rem", cursor: "pointer" }}
                      label={`${"Product Inward"} (${FileCount && FileCount?.product_inward})`}
                      onClick={() => Logsredirection(1, "Product Inward")}
                      color="primary"

                    />
                    <Chip
                      sx={{ width: "17rem", cursor: "pointer" }}
                      label={`${"Project Plan"} (${FileCount && FileCount?.project_plan} ) `}
                      onClick={() => Logsredirection(2, "Project Plan")}
                      color="primary"
                    />
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <Chip
                      sx={{ width: "17rem", cursor: "pointer" }}
                      label={`${"Product Outward"} (${FileCount && FileCount?.product_outward})  `}
                      onClick={() => Logsredirection(4, "Product Outward")}
                      color="primary"
                    />
                    <Chip
                      sx={{ width: "17rem", cursor: "pointer" }}
                      label={`${"Project Delivery"} (${FileCount && FileCount?.project_delivery} ) `}
                      onClick={() => Logsredirection(3, "Project Delivery")}
                      color="primary"
                    />
                  </Stack>
                  <Stack direction="row" spacing={1}>
                    <Chip
                      sx={{ width: "17rem", cursor: "pointer" }}
                      label={`${"Minutes of Meeting"}( ${FileCount && FileCount?.MOM})  `}
                      onClick={() => Logsredirection(5, "MOM")}
                      color="primary"
                    />
                    <Chip sx={{ width: "17rem", cursor: "pointer" }}

                      label={`${"Other"} (${FileCount && FileCount?.other})  `}
                      color="primary"
                      onClick={() => Logsredirection(6, "Other")}

                    />
                  </Stack>
                </Stack>
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default ProjectmailselectionModal;
