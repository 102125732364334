import * as React from "react";
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
} from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { API } from "../../api-services";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { useContext, useEffect } from "react";
import { AxiosError, AxiosResponse } from "axios";
import { MODULES } from "../../Constants/modules.constants";
import InfoIcon from "@mui/icons-material/Info";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import CancelIcon from "@mui/icons-material/Cancel";

import { ControlledMenu } from "@szhsin/react-menu";
import MediaDownloadSystemTree from "./MediaDownloadSystemTree";
import MediaGroupDownloadConsolidated from "./MediaGroupDownloadConsolidated";
import { AUTH } from "../../Redux/Services/auth.service";
import { MyContext } from "./MediaCreateLanding";
import { MediaCreateNameModal } from "./MediaCreateNameModal";
import { useAddGroupModel } from "./useAddGroupModel";
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import FolderIcon from '@mui/icons-material/Folder';
import openFolder from '../../Assets/images/svgs/openfolderblue.svg';

import swal from "sweetalert";
import Swal from "sweetalert2";
import successIcon from "../../Assets/images/success.png";
import { Width } from "devextreme-react/chart";
import { ADMIN } from "../../Redux/Services/admin.service";
import { useSelector } from "react-redux";
import { userSelectors } from "../../Redux/Reducers/user.reducer";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion
    disableGutters
    elevation={0}
    square
    {...props}
  />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    // expandIcon={<ArrowRightIcon sx={{ fontSize: "2rem", color: "primary.main" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "white",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(0deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  // borderTop: "1px solid #ddefff",
}));

interface Params {
  projectId: any;
  mediaType: any;
  category: any;
  projectName: any;
  abbreviation: any;
  groupId: any;
  productList: any;
  pageNumber: any;
  apiCall: any;
}
export interface IMediaProductandSystemTreeDetailsProps {
  selectedType: any;
}

export function MediaProductandSystemTreeDetails(
  props: IMediaProductandSystemTreeDetailsProps
) {
  const { projectId, mediaType, category, abbreviation, projectName, groupId, productList, pageNumber, apiCall } = useRouteParams<Params>();
  const [subsystemList, setSubsystemList] = React.useState<any>();
  const [groupList, setGroupList] = React.useState<any>();
  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  const [groupValidationInfo, setGroupValidationInfo] = React.useState<any>();
  const [open, setOpen] = React.useState(false);
  const [openMove, setOpenMove] = React.useState(false);
  const [openMediaDownload, setOpenMediaDownload] = React.useState(false);
  const [openMediaConsolidatedDownload, setOpenMediaConsolidatedDownload] = React.useState(false);
  const [openSystemMediaDownload, setOpenSystemMediaDownload] = React.useState(false);
  const [isOpenContext, setOpenContext] = React.useState(false);
  const [anchorPoint, setAnchorPoint] = React.useState({ x: 0, y: 0 });
  const [isOpenContextGroup, setOpenContextGroup] = React.useState(false);
  const [anchorPointGroup, setAnchorPointGroup] = React.useState({
    x: 0,
    y: 0,
  });
  const [groupContextId, setGroupContextId] = React.useState();
  const [groupContextTopVault, setGroupContextTopVault] = React.useState([]);
  const [groupContextImageCount, setGroupContextImageCount] = React.useState();
  const [groupContextNAShow, setGroupContextNAShow] = React.useState();
  const [groupContextValidationShow, setGroupContextValidationShow] = React.useState();
  const [productSubsystemGroupMove, setProductSubsystemGroupMove] = React.useState("");
  const [groupContextImageCategory, setGroupContextImageCategory] = React.useState();
  const [groupContextAbbreviation, setGroupContextAbbreviation] = React.useState();
  const [groupContextGroupName, setGroupContextGroupName] = React.useState();
  const [downloadProductInfo, setDownloadProductInfo] = React.useState<any>();
  const [rangeListSelected, setRangeListSelected] = React.useState<any>([]);
  const [optionGetValidatedRows, setOptionGetValidatedRows] = React.useState<any>([]);
  const [groupIdUnvalidatedRows, setGroupIdUnvalidatedRows] = React.useState<any>();
  const [categoryUnvalidatedRows, setCategoryUnvalidatedRows] = React.useState<any>();
  const [checked, setChecked] = React.useState<any>([])
  const userProfilePic = useSelector(userSelectors.selectAll);
  const history = useHistory();
  const { callGroupAPI, setCallGroupAPI, callGroupNameAPI, setCallGroupNameAPI, setIsNaShowDataApiCall, isNaShowDataApiCall, setRecycleBinCounter, permissionAccess } = useContext(MyContext);
  const MediaCreateName = useAddGroupModel();

  useEffect(() => {
    setChecked([])
    API.get(
      "/image_repository/media_module/",
      {
        project: projectId,
        module: MODULES.IMAGE_REPOSITORY,
        subsystem_fetch: true,
        media_type: mediaType,
        image_category: category == '1' || category == '2' ? '2' : category,
      },
      0
    )
      .then((res: AxiosResponse) => {
        setSubsystemList(res?.data);


        let FilterDashboardStatus=res?.data?.filter((item:any)=>{return item?.dashboard_status==true})?.map((item1:any,index1:any)=>{return item1?.id})
            
        setChecked(FilterDashboardStatus)







      })
      .catch((err: AxiosError) => {
        console.log(err, "error Message");
      });
  }, [projectId, mediaType, productList, callGroupAPI, apiCall, category]);








  const queries = (item: any, status: any) => {

    console.log(item, "item")
    if (item?.name == "product") {
      return { project: projectId, status: status,image_category: category == 5 ? category : 1 }
    } else {
      return { vault_info: item?.id, status: status ,image_category:category == 1 ? 2 : category}
    }
  }



  const handleBoxChange = (event: React.ChangeEvent<HTMLInputElement>, id: any) => {
    if (event?.target?.checked == true) {
      console.log(queries(id, true), id, "datattatatat")
      // const image_category = id.abbreviation == '0' ? 1 : 2;
      API.post("/image_repository/media_submission_status/", { ...queries(id, true) }, {}, 0).then((res: any) => {
        console.log(res.data, "datattatatat")
        setChecked([...checked, id?.id])
        ADMIN.toast.success("System/Product updated successfully.")
      }).catch((err: any) => {
        ADMIN.toast.error("Server Error")
      })
    }
    else if (event?.target?.checked == false) {
      API.post("/image_repository/media_submission_status/", { ...queries(id, false) }, {}, 0).then((res: any) => {
        ADMIN.toast.success("System/Product updated successfully.")
        setChecked((prev: any) => prev?.filter((item: any) => item !== id?.id))
      }).catch((err: any) => {
        ADMIN.toast.error("Server Error")
      })
    }



    // setChecked(event.target.checked);
  };

  useEffect(() => {
    setExpanded(abbreviation);
    API.get(
      "/image_repository/media_module/",
      {
        project: projectId,
        abbreviation: abbreviation,
        media_type: mediaType,
        image_category: category,
        get_groups: true,
        get_media_groups: true,
      },
      0
    )
      .then((res: AxiosResponse) => {
        setGroupList(res?.data);
      })
      .catch((err: AxiosError) => {
        console.log(err, "error Message");
      });
  }, [category, callGroupAPI, projectId, mediaType, productList, apiCall]);

  console.log(mediaType,category,"dbvbdvbkdjvkbjdkvbdkfbjv")
  const handleChange:any =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      if (expanded != panel) {
        setGroupList(undefined);
        setExpanded(newExpanded ? panel : false);
        API.get(
          "/image_repository/media_module/",
          {
            project: projectId,
            abbreviation: panel,
            media_type: mediaType,
            image_category:
              category == "1" || category == "2"
                ? panel == "0"
                  ? 1
                  : 2
                : category,
            get_groups: true,
            get_media_groups: true,
          },
          0
        )
          .then((res: AxiosResponse) => {
            setGroupList(res?.data);
          })
          .catch((err: AxiosError) => {
            console.log(err, "error Message");
          });
      }
    };

  const redirectToMedia = (e: any, idGroup: any, abbr: any, idGroupList: any) => {
    e.stopPropagation();
    history.push(
      `/mediacreateinternal/${projectId}/${projectName}/${mediaType}/${abbr}/${category == "1" || category == "2" ? (abbr == "0" ? 1 : 2) : category
      }/${idGroup}/${idGroupList?.splice(0, 4)?.toString()}/${1}/true`
    );
  };

  const rowsInfoOpen = (e: any, grpId: any, categoryGet: any) => {
    e.stopPropagation();
    setGroupIdUnvalidatedRows(grpId);
    setCategoryUnvalidatedRows(categoryGet);
    API.get("image_repository/quality_validation/", { project: projectId, abbreviation, category, row: 5, page: pageNumber, media_type: mediaType, group_id: groupId }, 0).then((res: any) => {
      API.get(
        "/image_repository/quality_validation_page_count/",
        {
          project: projectId,
          row: 5,
          abbreviation: expanded,
          category: categoryGet,
          group_id: grpId,
          media_type: mediaType
        },
        0
      )
        .then((res: AxiosResponse) => {
          setGroupValidationInfo(res?.data);
          setOpen(true);
        })
        .catch((err: AxiosError) => {
          console.log(err, "error Message");
        });
    })
  };

  const handleClose = () => {
    setGroupValidationInfo(undefined);
    setOpen(false);
  };

  const handleCloseMove = () => {
    setOpenMove(false);
  };

  const handleCloseMediaDownload = () => {
    setOpenMediaDownload(false);
  };

  const handleCloseMediaConsolidatedDownload = () => {
    setOpenMediaConsolidatedDownload(false);
  };

  const handleCloseSystemMediaDownload = () => {
    setOpenSystemMediaDownload(false);
  };

  const handleDeleteGroup = async (e: any) => {
    e.stopPropagation();
    swal({
      text: `Deleting this group will permanently delete all the ${mediaType}'s of this group even from the recycle bin.`,
      title: `Delete this group?`,
      icon: "error",
      //@ts-ignore
      buttons: {
        cancel: "Cancel",
        recycleBin: {
          text: `Delete ${mediaType} Group`,
          value: `deleteGroup/VideosImage`,
        },
      },
      closeOnClickOutside: false,
      closeOnEsc: false,
      className: "delete-swal",
    }).then((type: string) => {
      switch (type) {
        case "deleteGroup/VideosImage":
          API.delete("image_repository/media_group/" + groupContextId + "/", {
            top_vault: groupContextId == groupId ? productList : groupContextTopVault && groupContextTopVault?.toString(),
            abbreviation: groupContextAbbreviation,
            media_type: mediaType,
            image_category: groupContextImageCategory,
            type: 1,
          }, 0)
            .then((res: AxiosResponse) => {
              API.get(
                "/image_repository/media_module/",
                {
                  project: projectId,
                  abbreviation: groupContextAbbreviation,
                  media_type: mediaType,
                  image_category: groupContextImageCategory,
                  get_groups: true,
                  get_media_groups: true,
                },
                0
              )
                .then((res: AxiosResponse) => {
                  setGroupList(res?.data);
                  setRecycleBinCounter((prev: any) => prev + 1);
                  history.push(
                    `/mediacreateinternal/${projectId}/${projectName}/${mediaType}/${abbreviation}/${category}/0/${productList}/0/0`
                  );
                })
                .catch((err: AxiosError) => {
                  console.log(err, "error Message");
                });
            })
            .catch((err: AxiosError) => { });
          break;
        default:
          swal(`Group/${mediaType} saved from deleting`, { icon: "error" });
      }
    });
  };

  const handleFillNAImages = (e: any) => {
    e.stopPropagation();
    API.post(
      "/image_repository/upload_na_image_group/",
      {},
      {
        project: projectId,
        top_vault_list:
          groupContextTopVault && groupContextTopVault?.toString(),
        abbreviation: groupContextAbbreviation,
        image_category: groupContextImageCategory,
        media_type: mediaType,
        group: groupContextId,
      },
      0
    )
      .then((res: AxiosResponse) => {
        Swal.fire({
          html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
                    <br />
                    <p style="color:#007fff;">${groupContextNAShow ? "NA Images Filled Successfully in blank places." : `Validation successful`}
                    </p>   
                  </div>`,
        });
        API.get(
          "/image_repository/media_module/",
          {
            project: projectId,
            abbreviation: abbreviation,
            media_type: mediaType,
            image_category: category,
            get_groups: true,
            get_media_groups: true,
          },
          0
        )
          .then((res: AxiosResponse) => {
            setGroupList(res?.data);
            setIsNaShowDataApiCall(!isNaShowDataApiCall);
            history.push(
              `/mediacreateinternal/${projectId}/${projectName}/${mediaType}/${abbreviation}/${category}/${groupId}/${productList}/${pageNumber}/${apiCall == "true" ? "false" : "true"}`
            );
          })
          .catch((err: AxiosError) => {
            console.log(err, "error Message");
          });
      })
      .catch((err: AxiosError) => {
        console.log(err, "error console");
      });
  };

  const handleMoveProductSubsystemGroup = (event: SelectChangeEvent) => {
    setProductSubsystemGroupMove(event.target.value);
  };

  const MediaDownloadTree = (e: any, type: any) => {
    e.stopPropagation();
    if (type == "subsystem") {
      API.get("/image_repository/media_module/",
        {
          project: projectId,
          media_type: mediaType,
          image_category: category == '1' || category == '2' ? groupContextAbbreviation == '0' ? '1' : '2' : category,
          abbreviation: groupContextAbbreviation,
          download_image_count: true,
          subsystem_count: "true",
        },
        0
      ).then((res: AxiosResponse) => {
        setDownloadProductInfo(res?.data);
        setOpenSystemMediaDownload(true);
      })
        .catch((err: AxiosError) => {
          console.log(err, "error console");
        });
    }
    else if (type == "consolidatedGroup") {
      API.get(
        "/image_repository/media_module/",
        {
          project: projectId,
          media_type: mediaType,
          image_category: groupContextImageCategory,
          abbreviation: groupContextAbbreviation,
          download_image_count: true,
          group_id: groupContextId,
        },
        0
      )
        .then((res: AxiosResponse) => {
          setDownloadProductInfo(res?.data);
          setOpenMediaConsolidatedDownload(true);
        })
        .catch((err: AxiosError) => {
          console.log(err, "error console");
        });
    }
    else {
      API.get(
        "/image_repository/media_module/",
        {
          project: projectId,
          media_type: mediaType,
          image_category: groupContextImageCategory,
          abbreviation: groupContextAbbreviation,
          download_image_count: true,
          group_id: groupContextId,
        },
        0
      )
        .then((res: AxiosResponse) => {
          setDownloadProductInfo(res?.data);
          setOpenMediaDownload(true);
        })
        .catch((err: AxiosError) => {
          console.log(err, "error console");
        });
    }
  };

  const handleOpenMoveModel = (e: any) => {
    e.stopPropagation();
    setRangeListSelected([]);
    API.get(
      "/image_repository/quality_validation/",
      {
        project: projectId,
        media_type: mediaType,
        category: groupContextImageCategory,
        abbreviation: groupContextAbbreviation,
        group_validated_sequence: groupContextId,
      },
      0
    )
      .then((res: AxiosResponse) => {
        setOptionGetValidatedRows(res?.data)
        setOpenMove(true);
      })
      .catch((err: AxiosError) => {
        console.log(err, "error console");
      });
  }

  const handleSelectChange = (rangeId: any) => {
    // @ts-ignore
    setRangeListSelected([...rangeId]);
  };

  const optionValidatedRow = () => {
    let ValidatedRowsyId: any = rangeListSelected && rangeListSelected?.map((itm: any) => itm.sequence);
    return (
      optionGetValidatedRows &&
      optionGetValidatedRows
        ?.filter((item: any) => ![...ValidatedRowsyId]?.includes(item.sequence))
    );
  }

  const handleMoveImages = (e: any) => {
    e.stopPropagation();
    API.post('/image_repository/media_group_move/', {
      project: projectId,
      abbreviation: groupContextAbbreviation,
      media_type: mediaType,
      source_group: groupContextId,
      target_group: productSubsystemGroupMove,
      image_category: groupContextImageCategory,
      move_sequence: rangeListSelected && rangeListSelected?.map((itm: any) => itm.sequence)
    }, 0).then((res: AxiosResponse) => {
      setCallGroupAPI(!callGroupAPI);
      setCallGroupNameAPI(!callGroupNameAPI);
      history.push(
        `/mediacreateinternal/${projectId}/${projectName}/${mediaType}/${abbreviation}/${category}/${groupId}/${productList}/${pageNumber}/${apiCall == "true" ? "false" : "true"}`
      );
      setOpenMove(false);
    }).catch((err: AxiosError) => {
      console.log(err, "error console");
    });
  }

  const MediaCreateNameHandler = (e: any, abbreviationGet: any, categoryGet: any) => {
    e.stopPropagation();
    MediaCreateName.open(abbreviationGet, categoryGet);
  }

  const Accordianhandler=(e:any,item:any)=>{
    e.stopPropagation();
    e.preventDefault();
    setAnchorPoint({ x: e.clientX, y: e.clientY });
    setOpenContext(true);
    setGroupContextAbbreviation(item?.abbreviation);
    setGroupContextImageCategory(category == 1 || category == 2 ? item?.abbreviation == '0' ? 1 : 2 : category);
    setGroupContextImageCount(item?.image_count)
  }
  return (
    <div style={{ backgroundColor: 'white' }}>
      {MediaCreateName.isOpen &&
        <MediaCreateNameModal
          onCloseModal={MediaCreateName.close}
          isOpen={MediaCreateName.open}
          abbreviationGet={MediaCreateName.abbreviationGet}
          categoryGet={MediaCreateName.categoryGet}
        />}
      <Dialog
        open={openSystemMediaDownload}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseSystemMediaDownload}
        sx={{
          ".MuiPaper-root.MuiDialog-paper":
            { borderRadius: '10px' }
        }}
      >
        <DialogContent sx={{ width: "30vw", padding: "0rem" }}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Media {groupContextAbbreviation == '0' ? 'Product' : `${groupContextAbbreviation}`} Download
              {mediaType == "image"
                ? " ( maximum 125 images at a time)"
                : " ( maximum 10 Videos at a time )"}
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={handleCloseSystemMediaDownload}
            />
          </Box>
          <Box sx={{ margin: "0.5rem", }}>
            <MediaDownloadSystemTree downloadProductInfo={downloadProductInfo} groupContextAbbreviation={groupContextAbbreviation} groupContextId={groupContextId} groupContextImageCategory={category == '1' || category == '2' ? groupContextAbbreviation == '0' ? '1' : '2' : category} openMediaDownload={openMediaDownload} type={'productLevel'} permissionAccess={permissionAccess} />
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog
        open={open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        sx={{
          ".MuiPaper-root.MuiDialog-paper":
            { borderRadius: '10px' }
        }}
      >
        <DialogContent sx={{ width: "30vw", padding: "0rem" }}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Page Not Validated
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={handleClose}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ margin: "0.5rem 1rem 1rem", }}>
            <Typography sx={{ fontSize: '1rem' }}>
              Pages that are not validated : &nbsp;
              {groupValidationInfo &&
                groupValidationInfo?.[0]?.page_list?.map((item: any) => {
                  return (
                    <span
                      style={{
                        textDecoration: "underline",
                        marginRight: "1rem",
                        cursor: "pointer",
                      }}
                      onClick={() => history.push(
                        `/mediacreateinternal/${projectId}/${projectName}/${mediaType}/${abbreviation}/${categoryUnvalidatedRows}/${groupIdUnvalidatedRows}/${productList}/${item}/${apiCall == "true" ? "false" : "true"}`
                      )}
                    >
                      {item}
                    </span>
                  );
                })}
            </Typography>
          </Box>
        </DialogContent>
      </Dialog>
      <Dialog
        open={openMove}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseMove}
        sx={{
          ".MuiPaper-root.MuiDialog-paper":
            { borderRadius: '10px' }
        }}
      >
        <DialogContent sx={{ width: { sm: '40vw', xl: '42rem' }, padding: "0rem" }}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Move Group
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={handleCloseMove}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box
            sx={{
              margin: "1rem",
              Width: '100%',
            }}
          >
            <Typography sx={{ fontSize: '1rem' }}>Note* :- Moved {mediaType} will not retain the sequence in the destination group. They will be added in the end.</Typography>

            <Box sx={{ display: 'flex', alignItems: 'flex-start', columnGap: '1rem', marginTop: '0.5rem' }}>
              <FormControl variant="standard" fullWidth>
                <InputLabel sx={{ color: 'primary.main' }} shrink id="demo-simple-select-standard-label">Image rows to be moved :</InputLabel>
                <Autocomplete
                  multiple
                  disableClearable
                  value={rangeListSelected}
                  limitTags={4}
                  disableCloseOnSelect
                  onChange={(event, newValue) => {
                    handleSelectChange(newValue);
                  }}
                  sx={{ marginBottom: '0.5rem' }}
                  options={optionValidatedRow()}
                  // @ts-ignore
                  getOptionLabel={(option) => option?.sequence}
                  renderTags={(tagValue, getTagProps) =>
                    tagValue.map((option, index) => (
                      <Chip
                        size="small"
                        //  @ts-ignore
                        sx={{ backgroundColor: '#007fff14', color: "primary.main", fontSize: '1rem' }}
                        // @ts-ignore
                        label={option?.sequence}
                        {...getTagProps({ index })}
                      />
                    ))
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      // label="Commodity *"
                      variant="standard"
                      // placeholder="Select Image rows to be moved"
                      sx={{
                        marginTop: { lg: '1.5rem', xl: '1.4rem' },
                        ".MuiInputBase-root.MuiInput-root": {
                          fontSize: '1rem !important'
                        },
                        '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                        '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                        '.MuiSvgIcon-root': { color: 'primary.main' },
                      }}
                    />
                  )}
                />
              </FormControl>
              <FormControl variant="standard" fullWidth>
                <InputLabel sx={{ color: 'primary.main' }} shrink id="demo-simple-select-standard-label">Move to group :</InputLabel>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={productSubsystemGroupMove}
                  onChange={handleMoveProductSubsystemGroup}
                  sx={{
                    "&:before": {
                      borderBottomColor: "primary.light",
                    },
                    "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                      fontSize: '1rem'
                    },
                    '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
                  }}
                >
                  {groupList &&
                    groupList?.filter((item: any) => item.id != groupContextId)?.map((item: any, index: number) => {
                      return (
                        <MenuItem
                          key={index + 1}
                          value={item?.["id"]}
                          sx={{ textTransform: "capitalize", fontSize: '1rem' }}
                        >
                          {item?.["name"]}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ width: '100%', textAlign: 'right' }}>
              <Button size="small" variant="contained" onClick={(e: any) => handleMoveImages(e)}>
                Move
              </Button>
            </Box>
          </Box>
        </DialogContent>
      </Dialog>

      {openMediaDownload && <Dialog
        open={openMediaDownload}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseMediaDownload}
        sx={{
          ".MuiPaper-root.MuiDialog-paper":
            { borderRadius: '10px' }
        }}
      >
        <DialogContent sx={{ width: "30vw", padding: "0rem" }}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              {groupContextGroupName} Group Download
              {mediaType == "image"
                ? " ( maximum 125 images at a time)"
                : " ( maximum 10 Videos at a time )"}
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={handleCloseMediaDownload}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ margin: "0.5rem", color: "primary.main" }}>
            <MediaDownloadSystemTree downloadProductInfo={downloadProductInfo} groupContextAbbreviation={groupContextAbbreviation} groupContextId={groupContextId} groupContextImageCategory={groupContextImageCategory} openMediaDownload={openMediaDownload} type={'groupLevel'} permissionAccess={permissionAccess} />
          </Box>
        </DialogContent>
      </Dialog>}
      {openMediaConsolidatedDownload &&
        <Dialog
          open={openMediaConsolidatedDownload}
          TransitionComponent={Transition}
          keepMounted
          sx={{
            ".MuiPaper-root.MuiDialog-paper":
              { borderRadius: '10px' }
          }}
        >
          <DialogContent sx={{ width: "30vw", padding: "0rem" }}>
            <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  width: "100%",
                  textAlign: "center",
                  marginRight: "-2rem",
                }}
              >
                Media Consolidated Download (Excel)
              </Typography>
              <CancelIcon
                titleAccess="Close"
                sx={{ cursor: "pointer", margin: "0.5rem" }}
                onClick={handleCloseMediaConsolidatedDownload}
              />
            </Box>
            <Box sx={{ margin: "0.5rem", }}>
              <MediaGroupDownloadConsolidated countImage={downloadProductInfo && downloadProductInfo?.reduce((totalItem: any, item: any) => totalItem + item?.count, 0)} groupContextId={groupContextId} groupContextTopVault={groupContextTopVault} groupContextImageCategory={groupContextImageCategory} groupContextAbbreviation={groupContextAbbreviation} openMediaConsolidatedDownload={openMediaConsolidatedDownload} setOpenMediaConsolidatedDownload={setOpenMediaConsolidatedDownload} groupContextGroupName={groupContextGroupName} />
            </Box>
          </DialogContent>
        </Dialog>}
      <Box
        sx={{ width: "100%", height: { xs: "78vh", sm: '83vh', lg: "77vh", xl: '83vh', } }}
        adl-scrollbar="true"
        adl-scrollbar-width="0.3">
        <ControlledMenu
          anchorPoint={anchorPoint}
          position="anchor"
          transition
          state={isOpenContext ? "open" : "closed"}
          direction="right"
          onClose={() => setOpenContext(false)}
          onClick={(e: any) => {
            e.stopPropagation();
          }}
        >
          {permissionAccess && permissionAccess?.filter((item: any) => item?.top_vault == 'all')?.[0]?.action?.includes('C') && <MenuItem sx={{ color: 'primary.main', fontSize: '1rem', }} onClick={(e: any) => MediaCreateNameHandler(e, groupContextAbbreviation, groupContextImageCategory)}>Add Group</MenuItem>}
          {groupContextImageCount != 0 && <MenuItem sx={{ color: 'primary.main', fontSize: '1rem', }} onClick={(e: any) => MediaDownloadTree(e, "subsystem")}>
            Media Download
          </MenuItem>}
          {/* <MenuItem onClick={()=> setOpenSystemConsolidatedMediaDownload(true)}>Consolidated Excel Download</MenuItem> */}
        </ControlledMenu>

        <ControlledMenu
          anchorPoint={anchorPointGroup}
          position="anchor"
          transition
          state={isOpenContextGroup ? "open" : "closed"}
          direction="right"
          onClose={() => setOpenContextGroup(false)}
          onClick={(e: any) => {
            e.stopPropagation();
          }}
        >
          {groupContextImageCount == 0 && permissionAccess && permissionAccess?.filter((item: any) => item?.top_vault == 'all')?.[0]?.action?.includes('D') && (
            <MenuItem sx={{ color: 'primary.main', fontSize: '1rem', }} onClick={(e: any) => handleDeleteGroup(e)}>Delete Group</MenuItem>
          )}
          {groupContextImageCount != 0 && permissionAccess && permissionAccess?.filter((item: any) => item?.top_vault == 'all')?.[0]?.action?.includes('U') && <MenuItem sx={{ color: 'primary.main', fontSize: '1rem', }} onClick={(e: any) => handleOpenMoveModel(e)}>Move</MenuItem>}
          {groupContextImageCount != 0 && <MenuItem sx={{ color: 'primary.main', fontSize: '1rem', }} onClick={(e: any) => MediaDownloadTree(e, "groupLevel")}>
            Media Download
          </MenuItem>}
          {groupContextImageCount != 0 && mediaType == 'image' && <MenuItem sx={{ color: 'primary.main', fontSize: '1rem', }} onClick={(e: any) => MediaDownloadTree(e, "consolidatedGroup")}>
            Consolidated Excel Download
          </MenuItem>}
          {AUTH.isSuperUser && groupContextImageCount != 0 && permissionAccess && permissionAccess?.filter((item: any) => item?.top_vault == 'all')?.[0]?.action?.includes('C') && groupContextValidationShow != 'validated' && (
            <MenuItem onClick={(e: any) => handleFillNAImages(e)} sx={{ color: 'primary.main', fontSize: '1rem', }}> {groupContextNAShow ? "Fill blank as NA &" : ''} Validate {mediaType}</MenuItem>
          )}
        </ControlledMenu>

        {subsystemList &&
          subsystemList?.map((item: any) => {
            return (
              <Accordion
                key={item?.id}
                sx={{ border: "none" }}
                expanded={expanded == item?.abbreviation}
                onChange={mediaType=="image"? checked?.includes(item?.id)==false &&  handleChange(item?.abbreviation):handleChange(item?.abbreviation)}
              >
                <AccordionSummary
                  expandIcon={expanded == item?.abbreviation ? <img src={openFolder} style={{ height: '1.2rem' }} /> : <FolderIcon sx={{ fontSize: "1.5rem", color: "primary.main" }} />}
                  sx={{ minHeight: "24px", padding: "0 0.5rem" }}
                  aria-controls="panel1d-content"
                  id="panel1d-header"
                >
                  <Box sx={{ width: "100%", display: "flex", alignItems: "center", fontSize: "1rem", }}>
                    <Box sx={{ width: "100%", display: "flex", columnGap: "0.2rem", justifyContent: "space-between" }}
                      onContextMenu={(e:any) => { mediaType=="image"? checked?.includes(item?.id)==false && Accordianhandler(e,item):Accordianhandler(e,item)
                        
                      }}
                    >
                      <Typography
                        sx={
                          item?.group_status == "validated"
                            ? {
                              padding: '0.3rem 0.5rem',
                              color: "green",
                              fontSize: "0.87rem",
                              textTransform: "capitalize",
                              lineHeight: 1.2,
                            }
                            : item?.group_status == "not_validated"
                              ? {
                                padding: '0.3rem 0.5rem',
                                color: "red",
                                fontSize: "0.87rem",
                                textTransform: "capitalize",
                                lineHeight: 1.2,
                              } : {
                                padding: '0.3rem 0.5rem',
                                color: "#ffa500",
                                fontSize: "0.87rem",
                                textTransform: "capitalize",
                                lineHeight: 1.2,
                              }
                        }
                      >
                        {item?.name}&nbsp;({item?.group_count < 2 ? `${item?.group_count} group` : `${item?.group_count} groups`}, {item?.image_count < 2 ? `${item?.image_count} ${mediaType}` : `${item?.image_count} ${mediaType}s`})
                      </Typography>

                      <Box sx={{display:"flex"}}>
{   item?.group_count==0 &&
 ( category==1 || category==3)  && <>

                        { mediaType=="image" && userProfilePic && userProfilePic[0]?.is_superuser &&
                          <Checkbox
                            checked={checked?.includes(item?.id)}
                            onChange={(e: any) => handleBoxChange(e, item)}
                            inputProps={{ 'aria-label': 'controlled' }}
                            size='small'
                   
                            title={`${' Dashboard Status - No Media to be generated'}`}
                            // title='Dashboard Status media will not be added'
                            sx={{ color: "#007fff" }}
                          />
                        }
</>
}




                        {permissionAccess && permissionAccess?.filter((item: any) => item?.top_vault == 'all')?.[0]?.action?.includes('C') ? <Typography
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            fontSize: "0.87rem",
                          }}
                          onClick={(e: any) => MediaCreateNameHandler(e, item?.abbreviation, category == 1 || category == 2 ? item?.abbreviation == '0' ? 1 : 2 : category)}
                        >
                          <AddIcon sx={{ fontSize: "1.5rem" }} /> Group
                        </Typography> : <div></div>}
                      </Box>
                    </Box>
                  </Box>
                </AccordionSummary>
                <AccordionDetails>
                  <Box sx={{ width: '100%' }}>
                    {groupList &&
                      groupList?.map((itemInside: any) => {
                        return (
                          <Typography
                            key={itemInside?.id}
                            sx={
                              groupId == itemInside?.id &&
                                abbreviation == item?.abbreviation
                                ? {
                                  padding: "0.1rem 0.5rem",
                                  marginLeft: '2rem',
                                  fontSize: "0.87rem",
                                  lineHeight: 1,
                                  height: '1.8rem',
                                  backgroundColor: "#ddefff",
                                  cursor: "pointer",
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: 'center'
                                }
                                : {
                                  padding: "0.1rem 0.5rem",
                                  marginLeft: '2rem',
                                  fontSize: "0.87rem",
                                  lineHeight: 1,
                                  cursor: "pointer",
                                  height: '1.8rem',
                                  display: "flex",
                                  justifyContent: "space-between",
                                  alignItems: 'center'
                                }
                            }
                            onClick={(e: any) =>
                              itemInside.top_vault_list?.length != 0 && redirectToMedia(
                                e,
                                itemInside.id,
                                item.abbreviation,
                                itemInside.top_vault_list
                              )
                            }
                            onContextMenu={(e) => {
                              e.stopPropagation();
                              e.preventDefault();
                              setAnchorPointGroup({
                                x: e.clientX,
                                y: e.clientY,
                              });
                              setOpenContextGroup(true);
                              setGroupContextId(itemInside?.id);
                              setGroupContextTopVault(
                                itemInside?.top_vault_list
                              );
                              setGroupContextImageCount(
                                itemInside?.image_count
                              );
                              setGroupContextNAShow(itemInside?.is_na_show);
                              setGroupContextValidationShow(itemInside?.group_status)
                              setGroupContextImageCategory(
                                itemInside?.image_category
                              );
                              setGroupContextAbbreviation(item?.abbreviation);
                              setGroupContextGroupName(itemInside?.name);
                            }}
                          >
                            <span
                              style={
                                itemInside?.group_status == "validated"
                                  ? { color: "green", fontSize: '0.87rem', textTransform: 'capitalize' }
                                  : itemInside?.group_status == "not_validated"
                                    ? { color: "red", fontSize: '0.87rem', textTransform: 'capitalize' }
                                    : { color: "#ffa500", fontSize: '0.87rem', textTransform: 'capitalize' }
                              }
                            >
                              {itemInside?.name} ({itemInside?.image_count < 2 ? `${itemInside?.image_count} ${mediaType}` : `${itemInside?.image_count} ${mediaType}s`})
                            </span>
                            <span style={{ display: 'flex', alignItems: 'center' }}>
                              {itemInside?.group_status == "validated" || itemInside?.image_count == 0 ? (
                                <></>
                              ) : (
                                <InfoIcon
                                  onClick={(e: any) =>
                                    rowsInfoOpen(
                                      e,
                                      itemInside?.id,
                                      itemInside?.image_category
                                    )
                                  }
                                />
                              )}
                            </span>
                          </Typography>
                        );
                      })}
                  </Box>
                </AccordionDetails >
              </Accordion >
            );
          })}
      </Box >
    </div >
  );
}
