// import { ParameterConfig } from "app/Redux/Reducers/parameterConfig.reducer";

import { ParameterConfig } from "../Redux/Reducers/parameterConfig.reducer";

const transformConfigurationsFromApi = ( data: any ): ParameterConfig[] => {
    return data.map( ( el: any ) => {
        const {
            parameter: {
                id: parameter_id,
                table_id: parameter_table_id,
                ...prest
            },
            ...rest
        } = el;
        return { ...rest, parameter_id, parameter_table_id, ...prest };
    } );
};

export { transformConfigurationsFromApi };
