// react

import React from "react";
import { RouteComponentProps, useHistory, useParams } from "react-router-dom";

// vendors
// services
// utils
// constants
// factories
// core
// redux
// ui-components
// others
// styles
import styles from "./TriangulationLanding.module.scss";

import { useQueryDispatch } from "../../../CustomHook/useQueryDispatch";
import {
  getTriangulationData,
  resetTriangulation,
} from "../../../Redux/Actions/triangulation.action";
import { API } from "../../../api-services";
import TriangulationTable from "./TriangulationTable";
import TriangulationGraph from "./TriangulationGraph";
import TriagulationImageSection from "./TriagulationImageSection";
import Box from "@mui/material/Box/Box";
import excelimg from "../../../Assets/images/excel.png";
import { LoadingButton } from "@mui/lab";
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { IconButton, Typography } from "@mui/material";
// interfaces and types
// lazy

// ----------------------------------------------------------------------------------

interface CostAnalysisTriangulationProps extends RouteComponentProps { }

interface Params {
  projectID: string;
  topVaultID: string;
  topVaultName: string;
}

const TriangulationLanding: React.FC<CostAnalysisTriangulationProps> = (
  props
) => {
  const { crumbs } = props;
  // useDocumentTitle( "Costing-Analysis" );
  const { projectID, topVaultID, topVaultName } = useParams<Params>();
  const [count, setCount] = React.useState<number>(0);
  const [download, setDownload] = React.useState<boolean>(false);
  const history = useHistory<any>()
  // React.useEffect( () => {
  //     const objIndex = crumbs.findIndex( ( ( obj, index ) => index == 1 ) );
  //     crumbs[objIndex].content = ` ${crumbs[objIndex].content}  ( ${topVaultName} )`;
  // }, [crumbs] );

  const dataFetched :any= useQueryDispatch({
    query: {
      action: getTriangulationData,
      arg: { topvault: Number(topVaultID) },
    },
    cleanup: {
      action: resetTriangulation,
      args: [],
    },
    dependency: [count],
  });

  const captureElement = async () => {
    const data = new FormData();
    data.append("top_vault", topVaultID);
    setDownload(true);
    API.post("/cost/graph_data/", data, {}, 0)
      .then((res: any) => {
        setDownload(false);
        window.open(res.data, "_blank", "noopener noreferrer");
      })
      .catch(() => setDownload(false));
  };
  console.log(dataFetched?.fetch, "dataFetcheddataFetched", dataFetched.match("TRUE"))
  return (
    <>
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
        <Box
          sx={{
            padding: "0 0.5rem",
            display: "flex",
            alignItems: "center",
            columnGap: "10px",
          }}
        >
          <IconButton sx={{ cursor: "pointer" }} onClick={() => history.goBack()} >
            <ArrowBackIcon color="primary" titleAccess="Go Back" />
          </IconButton>
          <Typography sx={{ fontSize: '1rem', }}>Costing Triangulation</Typography>
        </Box>
        <div className={styles.header}>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "space-between",
            }}>
            <div className={styles.legend}>
              <span className={styles.active_user}>
                <span
                  className={styles.dot}
                  style={{ backgroundColor: "#007fff" }}></span>
                - Fact
              </span>
              <span className={styles.active_user}>
                <span
                  className={styles.dot}
                  style={{ backgroundColor: "green" }}></span>
                - Assumption
              </span>
              <span className={styles.active_user}>
                <span
                  className={styles.dot}
                  style={{ backgroundColor: "#D8B02A" }}></span>
                - Sum Total
              </span>
              <LoadingButton sx={{ minWidth: '1.5rem', }} loading={download} onClick={() => captureElement()} startIcon={<img src={excelimg} alt="excel" title="Excel" onClick={() => captureElement()} style={{ cursor: "pointer", height: "1.5rem", marginRight: '-1rem' }} />} >
              </LoadingButton>
            </div>
          </div>
        </div>
      </Box>
      <div data-testid="Cost-Analysis-Triangulation" className={styles.container}>        
        <div style={{ width: "100%" }} className={styles.graph_area}>
          <TriangulationTable dataFetched={dataFetched} setCountValue={setCount} />
          <TriangulationGraph />
        </div>
        <Box>
          <Box className={styles.top_section}>
            <TriagulationImageSection />
          </Box>
        </Box>        
      </div></>
  );
};

// ----------------------------------------------------------------------------------

export default TriangulationLanding;
