import { Checkbox } from '@mui/material';
import * as React from 'react';
import { useRouteParams } from '../../../ui-reusable-component/useRouteParams';
import { API } from '../../../api-services';
import { ADMIN } from '../../../Redux/Services/admin.service';
import Swal from 'sweetalert2';
import { debounce } from 'lodash';
import { userPermissionAction } from './BOMEntryLandingPage';

export interface ICheckBoxComponentProps {
  item: any;
  getBOMParametersData: any;
  BOMValidationStatus: any;
  check: any;
  modalType?:any;
  vaultpropId?:any;
}

export function CheckBoxComponent(props: ICheckBoxComponentProps) {
  const { item, BOMValidationStatus, getBOMParametersData, check ,vaultpropId,modalType} = props;
  const { vault, topVault, copyStatus } = useRouteParams<any>();
  const [value, setValue] = React.useState<any>({});
  const [isCheck, setIsCheck] = React.useState<any>()
  const userAction = React.useContext(userPermissionAction)
  let UserAction = userAction && userAction?.action

  React.useEffect(() => {
    setIsCheck(check)
  }, [check])


  const getobj = (v: any, tv: any) => {
    if (v == 0) {
      return { top_vault: tv }
    } else {
      return { vault: v }
    }
  }

  const getChecked = (itemm: any) => {

    if (item?.exceptions?.includes(item?.value)) {
      return true
    } else {
      return false
    }
  }

  const updateDataVaultCheckbox = async (name: any, valuee: any, vlt: any, tv: any) => {
    API.put(`/xcpep/component_details/74699/`, {
      [name]: valuee,
    }, {
      ...getobj(vlt, tv),
    }, 0).then((res: any) => {
      // window.location.reload();
      getBOMParametersData();
      BOMValidationStatus()
      ADMIN.toast.info("Updated Successfully")
    }).catch((err: any) => {
      const { data } = err.response;
      Swal.fire({
        title: "Error!",
        text: data[0],
        icon: "error",
        confirmButtonText: "Ok",
      });
    })
  }




  return (
    <Checkbox disabled={((copyStatus == false || copyStatus == "false") && (UserAction?.includes("U") || UserAction?.includes("C"))) ? false : true} checked={getChecked(item)} sx={{
      color: 'primary.main',
      padding: '3px',
      [`&.Mui-checked`]: {
        color: 'green',
      },
    }}
      onClick={() => {

        if(modalType=="modalform"){
          if (getChecked(item)) {
            updateDataVaultCheckbox(item?.key, "", vaultpropId, topVault)
  
  
          }
           else {
            updateDataVaultCheckbox(item?.key, item?.exceptions[0], vault, topVault)
          }
        }
        
        
        else{
          if (getChecked(item)) {
            updateDataVaultCheckbox(item?.key, "", vault, topVault)
            console.log(item?.key,"keyeyeyyeyeyey")
  
          }
          else {

            
            updateDataVaultCheckbox(item?.key, item?.exceptions[0], vault, topVault)
          }
        }
       
      }
      }

    />
  );
}
