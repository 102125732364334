function toExponential ( num: number ) {
    const array = num.toString().split( "." );

    if ( array[0] !== "0" && array[1]?.length > 2 ) return num.toFixed( 2 );

    const startsWith = num.toString().startsWith( "0.00" );

    if ( array[0] === "0" && array[1]?.length > 2 ) {
        if ( startsWith ) return num.toExponential( 2 );
        else return num.toFixed( 2 );
    }

    return num;
}
function toExponentialResultParam ( num: number ) {
    const array = num.toString().split( "." );

    if ( array[0] !== "0" && array[1]?.length > 2 ) return num.toFixed( 5 );

    const startsWith = num.toString().startsWith( "0.00" );

    if ( array[0] === "0" && array[1]?.length > 2 ) {
        if ( startsWith ) return num.toExponential( 2 );
        else return num.toFixed( 5 );
    }

    return num;
}

export {
    toExponential,toExponentialResultParam
};