import { TableCell } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { API } from '../../api-services';
import styles from "./BomCompareImg.module.scss";
import Image from "../../ViewModule/FeatureLandingPage/ArchitectureImageCard";
import NoImgLoader from "../../Assets/images/loading-screen-landing.gif";
import FallbackImg from "../../Assets/images/loading-screen.gif";
interface BomcompareImgProps {
  ImgItem: any
  topVaultIds?: any;
  viweimg:any 
}

const BomCompareImg = (props: BomcompareImgProps) => {
  const { ImgItem, topVaultIds,viweimg } = props;

  // console.log(topVaultIds && topVaultIds, "topVaultIdstopVaultIds")
  const { projectId, moduleType, topVaultId, abbr, NodeId, Count, TopId, Partname, Nodeleaf, depthNode } =
    useParams<any>();
  return (
    <TableCell sx={{ borderBottom: 'none', padding: '0.2rem 0.5rem' }} align="right">
      {/* @ts-ignore */}
      <Image
        src={ImgItem && ImgItem}
        placeholderImg={FallbackImg}
        loading="lazy"
        className={topVaultIds == 5 ? styles.compImage5 : topVaultIds == 4 ? styles.compImage4 : topVaultIds == 3 ? styles.compImage3 : topVaultIds == 2 ? styles.compImage2 : topVaultIds == 1 ? styles.compImage1 : styles.compImage}
        alt={viweimg && viweimg}
      />
      {/* <img src={ImgItem && ImgItem} className={styles.compImage} /> */}
    </TableCell>
  )
}

export default BomCompareImg;