import * as React from "react";
import Modal from "@mui/material/Modal";
import { makeStyles } from "@mui/styles";
import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { API } from "../../api-services";
import { Divider, Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";

export interface IUserActivationActivityLog {
  isOpen: boolean;
  onClose: () => void;
  user: any;
}

const styled = {
  boxSize: {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)", 
    width:'80rem',   
    maxWidth: "80%",
    // height: "fit-content",
    minHeight: "20rem",
    borderRadius: "0.5rem",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    p: 0,
    outline: 0,
  },
  NoAccessBoxSize: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center !important",
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    minWidth: "39rem",
    height: "fit-content",
    minHeight: "20rem",
    borderRadius: "0.5rem",
    bgcolor: "background.paper",
    border: "none",
    boxShadow: 24,
    p: 0,
    outline: 0,
  },
  HeaderLine: {
    textAlign: "center",
    position: "relative",
  },
  SelectedHeaderLine: {
    color: "#1976d2",
  },
  nextButton: {
    position: "absolute",
    zIndex: "9999",
    left: "35rem",
    top: "1.8rem",
  },

  summery: {
    backgroundColor: "rgb(240, 247, 255)",
    borderColor: " rgb(0, 127, 255)",
    borderRadius: "10px",
    minHeight: "45px",
  },
  searchButton: {
    position: "absolute",

    // right: "37rem",
    top: "0.4rem",

    left: "-2rem",
    cursor: "default",
    color: "#1976d2",
    padding: " 0rem 0rem 0rem 2rem",
  },
  closedIcon: {
    position: "absolute",
    zIndex: "999",
    color: "#1976d2",
    right: "0.2rem",
    top: "0.5rem",
    cursor: "pointer",
  },
  editIcon: {
    marginTop: "0.3rem",
    color: "#1976d2",
    cursor: "pointer",
  },
};
// @ts-ignore

const useStyles = makeStyles(() => ({
  /** Changed modalStyle */
  ModalRoot: {
    backgroundColor: "rgba(111, 126, 140, 0.2) !important",
    backdropFilter: "blur(1px) !important",
  },
}));

export function UserActivationActivityLog(props: IUserActivationActivityLog) {
  const { isOpen, onClose, user } = props;
  const [logData, setLogData] = React.useState<any>([]);
  const classes = useStyles();
  const COLUMN = [
    { field: "id", hide: true },
    {
      field: "description",
      headerName: "Description",
      flex: 1,
    },
    {
      field: "dates",
      headerName: "Date",
      flex: 1,
    },
    {
      field: "duration_left",
      headerName: "Duration left",
      flex: 1,
    },
    {
      field: "updated_by",
      headerName: "Done by",
      flex: 1,
    },
    {
        field: "comments",
        headerName: "Comments",
        flex: 1,
      },
  ];
  const getActivityLogData = () => {
    API.get("/activity/activation_logs/", { user: user }, 0)
      .then((res: any) => {
        setLogData(res.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    getActivityLogData();
  }, []);
  const dataRender: any = {
    columns: COLUMN,
    rows: logData && logData,
  };

  return (
    <div>
      <Modal // autoFocusdal
        open={isOpen}
        className={classes.ModalRoot}
        onClose={onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={styled.boxSize}>
        <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: '-2rem'
              }}
            >
              User Account Activity
            </Typography>
            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onClose} />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{height:'28rem',width:'100%',padding:'1rem',minWidth:'40rem'}}>
            {logData && (
              <DataGrid
                {...dataRender}
                pageSize={59}
                sx={{
                  height: "100%",
                  fontSize: "1rem",
                  "&.MuiDataGrid-root.MuiDataGrid-autoHeight .MuiDataGrid-row--lastVisible .MuiDataGrid-cell":
                    {
                      borderBottomColor: "primary.light",
                    },
                  "&.MuiDataGrid-root .MuiDataGrid-cell": {
                    borderBottomColor: "primary.light",
                  },
                }}
                rowsPerPageOptions={[59]}
                density={"compact"}
              />
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
