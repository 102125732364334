// react
import React, { useContext, useEffect, useRef, useState } from "react";
// vendors
import { useSelector } from "react-redux";
import { useHistory, useRouteMatch } from "react-router-dom";

// styles
import styles from "./MediaCreateProductImageCard.module.scss";
import RViewerJS from "viewerjs-react";
import NoImgLoader from "../../Assets/images/loading-screen.gif";
import { ID } from "../../utlis/commonInterfaces";
import { useIntersection } from "../MediaModule/MediaLandingPage/MediaContentBody/MediaGrouping/MediaMultipleTopvaultGallery/intersectionObserver";
import { RootState } from "../../Redux/Store/storeConfigurations";
import { imageRepositorySelectors } from "../../Redux/Reducers/imageRepository.reducer";
import VideoModal from "../MediaModule/VideoModal/VideoModal";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import Image from "../../ViewModule/FeatureLandingPage/ArchitectureImageCard";
import ClearIcon from "@mui/icons-material/Clear";
import DeleteIcon from "@mui/icons-material/Delete";
import UploadIcon from "@mui/icons-material/Upload";
import ArrowCircleUpIcon from "@mui/icons-material/ArrowCircleUp";
import ArrowCircleDownIcon from "@mui/icons-material/ArrowCircleDown";
import MoveDownIcon from "@mui/icons-material/MoveDown";
import MoveUpIcon from "@mui/icons-material/MoveUp";
import RotateRightIcon from "@mui/icons-material/RotateRight";
import RotateLeftIcon from "@mui/icons-material/RotateLeft";
import { useAppDispatch } from "../../../AppRouter";
import {
  SequenceImageCard,
  createBlankRepoImage,
  createRepoImage,
  deleteBlankRepoImage,
  deleteRepoImage,
  moveRepoImage,
  rotateRepoImage,
  updateRepoImage,
} from "../../Redux/Actions/imageRepository.actions";
import swal from "sweetalert";
import { useImageRotate } from "../../CustomHook/useImageRotate";
import { LoadingButton } from "@mui/lab";
import { Box, Checkbox, CircularProgress, TextField } from "@mui/material";
import { API } from "../../api-services";
import { useBaseModal } from "../SearchPage/useBaseModal";
import mediaSingleUpload from "./MediaSingleUpload";
import MediaAddSingleImageModel from "./MediaAddSingleImageModel";
import { isUndefined } from "lodash";
import MediaMoveSingleImageModel from "./MediaMoveSingleImageModel";
import { MyContext } from "./MediaCreateLanding";
import { MediaCreateImageUploadModal } from "./MediaCreateImageUploadModal";
import DriveFileMoveOutlinedIcon from '@mui/icons-material/DriveFileMoveOutlined';
import EditIcon from '@mui/icons-material/Edit';
import MediaAddSingleVideoModel from "./MediaAddSingleVideoModel";
import ArchImageEditModal from "../ArchitectureCreate/ArchImageEditModal";


// lazy
// ----------------------------------------------------------------------------------
// params
// ----------------------------------------------------------------------------------
interface MediaCreateProductImageCardProps {
  topVaultId: ID;
  vaultId: ID | null;
  sequence: number;
  page: number;
  rowSize: number;
  selectedTopVaultIds?: any;
  setcheckboxcount?: any;
  validationvalueProduct?: any;
}

const MediaCreateProductImageCard: React.FC<
  MediaCreateProductImageCardProps
> = (props) => {
  const {
    topVaultId,
    vaultId,
    sequence,
    page,
    rowSize,
    selectedTopVaultIds,
    setcheckboxcount,
    validationvalueProduct,
  } = props;
  const {
    projectId,
    projectName,
    mediaType,
    abbreviation,
    category,
    groupId,
    productList,
    pageNumber,
    apiCall,
  } = useRouteParams<any>();
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [isInView, setIsInView] = useState<boolean>(false);
  const [selectedsceImages, setSelectedsceImages] = useState<any>([]);
  const [naUploadInProgress, setNaUploadInProgress] = useState<boolean>(false);
  const [sequenceNum, setSequenceNum] = useState<number | undefined>();
  const [isUpdating, setIsUpdating] = useState(false);
  const [datavalidation, setDatavalidation] = useState<any>();

  const dispatchImage = useAppDispatch();
  const uploadmodal = useBaseModal();
  const uploadmodalVideo = useBaseModal();
  const movemodal = useBaseModal();
  const ImageUploadModal = useBaseModal();
  const imageEditorModal=useBaseModal()

  const imgRef = useRef<any>();
  const VideoModalRef = useRef<any>(null);
  const imagereff = useRef<any>(null);

  const { url } = useRouteMatch();
  const dispatch = useAppDispatch();
  const history = useHistory();
  const { setRecycleBinCounter, isNaShowDataApiCall, setIsNaShowDataApiCall, permissionAccess } =  useContext(MyContext);

  useEffect(() => {
    setDatavalidation(
      validationvalueProduct &&
      validationvalueProduct.filter((item: any) => item.group == groupId)
    );
  }, [validationvalueProduct]);

  useEffect(() => {
    if (selectedsceImages?.length > 0) {
      !url.includes("architecture") && imagereff.current.click();
    }
  }, [selectedsceImages]);

  useEffect(() => {
    setIsLoaded(false);
  }, [page, rowSize, selectedTopVaultIds]);

  // ----------------------------------------------------------------------------------
  // selectors

  const image: any = useSelector((state: RootState) => {
    return imageRepositorySelectors.selectBySequence(
      state,
      topVaultId,
      sequence,
      groupId,
      category
    );
  });

  useEffect(() => {
    setSequenceNum(image?.sequence);
  }, [image?.sequence]);

  const allimages: any = useSelector((state: RootState) => {
    return imageRepositorySelectors.selectAllImages(state, topVaultId);
  });

  useIntersection(imgRef, () => {
    setIsInView(true);
  });

  const handleOnLoad = () => {
    setIsLoaded(true);
  };

  const openModalInPreviewMode = (link: string) => {
    return VideoModalRef.current?.open([link, image?.is_mute]);
  };

  const imageExpandClick = (sce: any, topVault: any) => {
    let topVaultIdGet: any = [];

    let a: any = topVaultIdGet?.push(
      selectedTopVaultIds.slice(
        selectedTopVaultIds.indexOf(topVault) + 1,
        selectedTopVaultIds.length
      )
    )[0];
    let b: any = topVaultIdGet?.push(
      selectedTopVaultIds.slice(0, selectedTopVaultIds.indexOf(topVault) + 1)
    )[0];

    let topVaultIds: any = topVaultIdGet?.flat();

    const abc: any = allimages?.filter(
      (item: any) =>
        item.sequence === sce && item.group == (groupId == -2 ? null : groupId)
    );

    const c = topVaultIds?.map((i: any) =>
      abc?.find((j: any) => j.top_vault === i)
    );

    setSelectedsceImages(c);
  };

  //-------------------------------------------------------------------------------------

  const handleClear = () => {
    if (!image) return;
    dispatch(
      deleteRepoImage({
        id: image.id,
        rearrange: false,
        media_type: mediaType,
      })
    ).then(() => {
      setRecycleBinCounter((prev: any) => prev + 1);
      setcheckboxcount((pre: any) => pre + 1);
      const ValidationId =
        datavalidation &&
        datavalidation.filter((item: any) => item.sequence == sequence);

      API.patch(`image_repository/quality_validation/${ValidationId[0]?.id}/`, {
        validation: false,
        unchecked: true,
      }).then((res: any) => {
        history.push(
          `/mediacreateinternal/${projectId}/${projectName}/${mediaType}/${abbreviation}/${category}/${groupId}/${productList}/${pageNumber}/${apiCall == "true" ? "false" : "true"
          }`
        );
      });
    });
  };

  //-------------------------------------------------------------------------------------

  const handleDelete = async (e: React.MouseEvent) => {
    e.stopPropagation();
    const ValidationId =
      datavalidation &&
      datavalidation.filter((item: any) => item.sequence == sequence);
    swal({
      title: `Deleting this ${mediaType} will effect the sequence.`,
      text: `Are you sure?`,
      icon: "error",
      //@ts-ignore
      buttons: {
        cancel: "Cancel",
        permanent: {
          text: `Delete Image`,
          value: `deleteImage`,
        },
      },
      closeOnClickOutside: false,
      closeOnEsc: false,
      className: "delete-swal",
    }).then(async (type: string) => {
      switch (type) {
        case "deleteImage":
          if (!image) {
            dispatch(
              deleteBlankRepoImage({
                category,
                sequence,
                topVaultId,
                vaultId,
                page_items: 5,
                key: pageNumber,
                media_type: mediaType,
              })
            ).then(() => {
              API.get(
                "image_repository/validation/",

                {
                  abbreviation: abbreviation,
                  product: topVaultId,
                  category: category,
                }
              )
                .then((res: any) => { })
                .catch((err: any) => { });
              API.patch(
                `image_repository/quality_validation/${ValidationId[0]?.id}/`,
                { validation: false, unchecked: true }
              )
                .then((res: any) => {
                  setcheckboxcount((pre: any) => pre + 1);
                })
                .catch((err) => { });
              setRecycleBinCounter((prev: any) => prev + 1);
              setIsNaShowDataApiCall(!isNaShowDataApiCall);
              history.push(
                `/mediacreateinternal/${projectId}/${projectName}/${mediaType}/${abbreviation}/${category}/${groupId}/${productList}/${pageNumber}/${apiCall == "true" ? "false" : "true"
                }`
              );
            });
          } else {
            dispatch(
              deleteRepoImage({
                id: image.id,
                rearrange: true,
                page_items: 5,
                key: pageNumber,
                media_type: mediaType,
              })
            ).then(() => {
              API.get(
                "image_repository/validation/",

                {
                  abbreviation: abbreviation,
                  product: topVaultId,
                  category: category,
                }
              )
                .then((res: any) => { })
                .catch((err: any) => { });
              API.patch(
                `image_repository/quality_validation/${ValidationId[0]?.id}/`,
                { validation: false, unchecked: true }
              )
                .then((res: any) => {
                  setcheckboxcount((pre: any) => pre + 1);
                })
                .catch((err) => { });
              setRecycleBinCounter((prev: any) => prev + 1);
              history.push(
                `/mediacreateinternal/${projectId}/${projectName}/${mediaType}/${abbreviation}/${category}/${groupId}/${productList}/${pageNumber}/${apiCall == "true" ? "false" : "true"
                }`
              );
            });
          }
          break;
        default:
          swal(`Data saved from deleting`, { icon: "error" });
      }
    });
  };

  //-------------------------------------------------------------------------------------

  const handleMoveUp = () => {
    if (!image || sequence === 0) return;
    const ValidationId =
      datavalidation &&
      datavalidation.filter((item: any) => item.sequence == sequence);
    dispatch(
      moveRepoImage({
        id: image.id,
        newSequence: image.sequence - 1,
        media_type: mediaType,
        project: projectId,
        abbreviation: abbreviation
      })
    ).then(() => {
      API.get(
        "image_repository/validation/",

        {
          abbreviation: abbreviation,
          product: topVaultId,
          category: category,
        }
      )
        .then((res: any) => { })
        .catch((err: any) => { });
      API.patch(`image_repository/quality_validation/${ValidationId[0]?.id}/`, {
        validation: false,
        unchecked: true,
      })
        .then((res: any) => {
          setcheckboxcount((pre: any) => pre + 1);
        })
        .catch((err) => { });
      history.push(
        `/mediacreateinternal/${projectId}/${projectName}/${mediaType}/${abbreviation}/${category}/${groupId}/${productList}/${pageNumber}/${apiCall == "true" ? "false" : "true"
        }`
      );
    });
  };

  //-------------------------------------------------------------------------------------

  const handleMoveDown = () => {
    if (!image) return;
    const ValidationId =
      datavalidation &&
      datavalidation.filter((item: any) => item.sequence == sequence);
    dispatch(
      moveRepoImage({
        id: image.id,
        newSequence: image.sequence + 1,
        media_type: mediaType,
        project: projectId,
        abbreviation: abbreviation
      })
    ).then(() => {
      API.get(
        "image_repository/validation/",

        {
          abbreviation: abbreviation,
          product: topVaultId,
          category: category,
        }
      )
        .then((res: any) => { })
        .catch((err: any) => { });
      API.patch(`image_repository/quality_validation/${ValidationId[0]?.id}/`, {
        validation: false,
        unchecked: true,
      })
        .then((res: any) => {
          setcheckboxcount((pre: any) => pre + 1);
        })
        .catch((err) => { });
      history.push(
        `/mediacreateinternal/${projectId}/${projectName}/${mediaType}/${abbreviation}/${category}/${groupId}/${productList}/${pageNumber}/${apiCall == "true" ? "false" : "true"
        }`
      );
    });
  };

  // ---------------rotate the image-----------------------------------------------------

  const onImageRotate = async (rotation: number) => {
    const ValidationId =
      datavalidation &&
      datavalidation.filter((item: any) => item.sequence == sequence);
    const action = await dispatch(
      rotateRepoImage({
        id: image?.id || 0,
        rotation,
        mediaType,
      })
    );
    if (rotateRepoImage.fulfilled.match(action)) {
      API.get(
        "image_repository/validation/",

        {
          abbreviation: abbreviation,
          product: topVaultId,
          category: category,
        }
      )
        .then((res: any) => { })
        .catch((err: any) => { });
      API.patch(`image_repository/quality_validation/${ValidationId[0]?.id}/`, {
        validation: false,
        unchecked: true,
      })
        .then((res: any) => {
          setcheckboxcount((pre: any) => pre + 1);
        })
        .catch((err) => { });
      history.push(
        `/mediacreateinternal/${projectId}/${projectName}/${mediaType}/${abbreviation}/${category}/${groupId}/${productList}/${pageNumber}/${apiCall == "true" ? "false" : "true"
        }`
      );
    }

    return rotateRepoImage.fulfilled.match(action);
  };

  // hooks for rotating

  const { handleRotate, isRotating } = useImageRotate(onImageRotate);

  //-------------------------------------------------------------------------------------

  const createBlank = async (sequence: number) => {
    const ValidationId =
      datavalidation &&
      datavalidation.filter((item: any) => item.sequence == sequence);
    dispatch(
      createBlankRepoImage({
        topVaultId,
        vaultId,
        category,
        sequence,
        mediaType,
        groupId
      })
    ).then(() => {
      API.get(
        "image_repository/validation/",

        {
          abbreviation: abbreviation,
          product: topVaultId,
          category: category,
        }
      )
        .then((res: any) => { })
        .catch((err: any) => { });
      API.patch(`image_repository/quality_validation/${ValidationId[0]?.id}/`, {
        validation: false,
        unchecked: true,
      })
        .then((res: any) => {
          setcheckboxcount((pre: any) => pre + 1);
        })
        .catch((err) => { });
      history.push(
        `/mediacreateinternal/${projectId}/${projectName}/${mediaType}/${abbreviation}/${category}/${groupId}/${productList}/${pageNumber}/${apiCall == "true" ? "false" : "true"
        }`
      );
    });
  };

  const handleCreateBlankAbove = () => createBlank(sequence);
  const handleCreateBlankBelow = () => createBlank(sequence + 1);

  //-------------------------------------------------------------------------------------

  const NAhandleChange = () => {
    setNaUploadInProgress(true);
    API.post(
      `image_repository/fetchs/`,
      { NA: true },
      {
        project: projectId,
        top_vault: topVaultId,
        abbreviation: abbreviation,
        image_category: category,
        sequence: sequence,
        group: groupId,
        media_type: mediaType,
      }
    ).then((res: any) => {
      setNaUploadInProgress(false);
      const ValidationId =
        datavalidation &&
        datavalidation.filter((item: any) => item.sequence == sequence);
      API.patch(`image_repository/quality_validation/${ValidationId[0]?.id}/`, {
        validation: false,
        unchecked: true,
      })
        .then((res: any) => {
          setcheckboxcount((pre: any) => pre + 1);
        })
        .catch((err) => { });
      history.push(
        `/mediacreateinternal/${projectId}/${projectName}/${mediaType}/${abbreviation}/${category}/${groupId}/${productList}/${pageNumber}/${apiCall == "true" ? "false" : "true"
        }`
      );
    });
  };

  //-------------------------------------------------------------------------------------

  const handleChangeSequence = (e: any) => {
    if (e.target.value != image?.sequence) {
      setSequenceNum(e.target.value);
    }
  };

  const handleSequenceChange = (e: any) => {
    if (image?.sequence == sequenceNum) return;
    else {
      dispatch(
        SequenceImageCard({
          id: image?.id,
          new_sequence: sequenceNum,
          project: projectId,
          abbreviation: abbreviation,
        })
      ).then((res: any) => {
        const ValidationId =
          datavalidation &&
          datavalidation.filter((item: any) => item.sequence == sequence);
        API.patch(
          `image_repository/quality_validation/${ValidationId[0]?.id}/`,
          {
            validation: false,
            unchecked: true,
          }
        )
          .then((res: any) => {
            setcheckboxcount((pre: any) => pre + 1);
          })
          .catch((err) => { });
        history.push(
          `/mediacreateinternal/${projectId}/${projectName}/${mediaType}/${abbreviation}/${category}/${groupId}/${productList}/${pageNumber}/${apiCall == "true" ? "false" : "true"
          }`
        );
      });
    }
  };

  //------------------- edited image upload--------------------------------------------------------

  const imageForApp = image && image.original.replaceAll("prod.xcpep.com/", "");
  const relImageURL =
    image &&
    (image.original.includes("prod.xcpep.com/") === false
      ? image.original
        ?.split("?")[0]
        ?.split(".com")[1]
        ?.replaceAll("%20", " ")
        ?.replaceAll("%28", "(")
        .replaceAll("%29", ")")
      : imageForApp
        .split("?")[0]
        ?.split(".com")[1]
        ?.replaceAll("%20", " ")
        ?.replaceAll("%28", "(")
        .replaceAll("%29", ")"));

  const imageEditorHandler=(item:any)=>{
    imageEditorModal.open()
  }

  const updateEditedImage = async (file: File) => {
    setIsUpdating(true);
    if (image) {
        mediaSingleUpload(
          [file],
          groupId,
          topVaultId,
          vaultId,
          dispatchImage,
          setIsUpdating,
          mediaType,
          imageEditorModal,
          image.id,
          "update",
          category,
          sequence,
          history,
          projectId,
          abbreviation,
          projectName,
          category,
          productList,
          pageNumber,
          apiCall
        )

        API.get(
          "image_repository/validation/",
          {
            abbreviation: abbreviation,
            product: topVaultId,
            category: category,
          }
        )
          .then((res: any) => {
          })
        const ValidationId = datavalidation && datavalidation.filter((item: any) => item.sequence == sequence);

        API.patch(
          `image_repository/quality_validation/${ValidationId[0]?.id}/`,
          { validation: false, unchecked: true }
        )
          .then((res: any) => {
            setcheckboxcount((pre: any) => pre + 1);
          })
          .catch((err) => {
          })
    }
  }

  //-------------------image upload--------------------------------------------------------

  const saveImage = async (file: File, id: number) => {
    setIsUpdating(true);
    if (image) {
      if (id == 1) {
        mediaSingleUpload(
          [file],
          groupId,
          topVaultId,
          vaultId,
          dispatchImage,
          setIsUpdating,
          mediaType,
          uploadmodal,
          image.id,
          "update",
          category,
          sequence,
          history,
          projectId,
          abbreviation,
          projectName,
          category,
          productList,
          pageNumber,
          apiCall
        );

        API.get(
          "image_repository/validation/",

          {
            abbreviation: abbreviation,
            product: topVaultId,
            category: category,
          }
        )
          .then((res: any) => {
          })
        const ValidationId =
          datavalidation &&
          datavalidation.filter((item: any) => item.sequence == sequence);

        API.patch(
          `image_repository/quality_validation/${ValidationId[0]?.id}/`,
          { validation: false, unchecked: true }
        )
          .then((res: any) => {
            setcheckboxcount((pre: any) => pre + 1);
          })
          .catch((err) => {
          });
      } else {
        await dispatch(
          updateRepoImage({
            id: image.id,
            image: file,
            rgb: true,
            media_type: mediaType,
            group: groupId,
          })
        ).then(() => {
          uploadmodal.close();
          setIsUpdating(false);
          API.get(
            "image_repository/validation/",

            {
              abbreviation: abbreviation,
              product: topVaultId,
              category: category,
            }
          )
            .then((res: any) => {
            })
          const ValidationId =
            datavalidation &&
            datavalidation.filter((item: any) => item.sequence == sequence);

          API.patch(
            `image_repository/quality_validation/${ValidationId[0]?.id}/`,
            { validation: false, unchecked: true }
          )
            .then((res: any) => {
              setcheckboxcount((pre: any) => pre + 1);
            })
            .catch((err) => {
            });
          history.push(
            `/mediacreateinternal/${projectId}/${projectName}/${mediaType}/${abbreviation}/${category}/${groupId}/${productList}/${pageNumber}/${apiCall == "true" ? "false" : "true"
            }`
          );
        });
      }
    } else {
      if (id == 1) {
        mediaSingleUpload(
          [file],
          groupId,
          topVaultId,
          vaultId,
          dispatchImage,
          setIsUpdating,
          mediaType,
          uploadmodal,
          null,
          "create",
          category,
          sequence,
          history,
          projectId,
          abbreviation,
          projectName,
          category,
          productList,
          pageNumber,
          apiCall
        );

        API.get(
          "image_repository/validation/",

          {
            abbreviation: abbreviation,
            product: topVaultId,
            category: category,
          }
        )
          .then((res: any) => {
          })
        const ValidationId =
          datavalidation &&
          datavalidation.filter((item: any) => item.sequence == sequence);

        API.patch(
          `image_repository/quality_validation/${ValidationId[0]?.id}/`,
          { validation: false, unchecked: true }
        )
          .then((res: any) => {
            setcheckboxcount((pre: any) => pre + 1);
          })
          .catch((err) => {
          });
      } else {
        dispatch(
          createRepoImage({
            image: file,
            image_category: category,
            sequence,
            top_vault: topVaultId,
            vault: vaultId,
            rgb: true,
            media_type: mediaType,
            group: groupId,
          })
        ).then((res: any) => {
          uploadmodal.close();
          setIsUpdating(false);
          API.get(
            "image_repository/validation/",

            {
              abbreviation: abbreviation,
              product: topVaultId,
              category: category,
            }
          )
            .then((res: any) => {
            })
          const ValidationId =
            datavalidation &&
            datavalidation.filter((item: any) => item.sequence == sequence);

          API.patch(
            `image_repository/quality_validation/${ValidationId[0]?.id}/`,
            { validation: false, unchecked: true }
          )
            .then((res: any) => {
              setcheckboxcount((pre: any) => pre + 1);
            })
            .catch((err) => {
            });
          history.push(
            `/mediacreateinternal/${projectId}/${projectName}/${mediaType}/${abbreviation}/${category}/${groupId}/${productList}/${pageNumber}/${apiCall == "true" ? "false" : "true"
            }`
          );
        });
      }
    }
  };

  const browseFiles:any = () => {
    uploadmodal.open();
  };

  const browseVideo:any = () => {
    uploadmodalVideo.open();
  };

  //--------------- video upload------------------------------------------------------

  const saveVideo =  (file: File, id: number) => {
    if (isUndefined(file)) return;
    setIsUpdating(true);
    if (image) {
      mediaSingleUpload(
        [file],
        groupId,
        topVaultId,
        vaultId,
        dispatchImage,
        setIsUpdating,
        mediaType,
        uploadmodalVideo.close(),
        image.id,
        "update",
        category,
        sequence,
        history,
        projectId,
        abbreviation,
        projectName,
        category,
        productList,
        pageNumber,
        apiCall,
        id == 1 ? false : true
      );

      const ValidationId =
        datavalidation &&
        datavalidation.filter((item: any) => item.sequence == sequence);

      API.patch(`image_repository/quality_validation/${ValidationId[0]?.id}/`, {
        validation: false,
        unchecked: true,
      })
        .then((res: any) => {
          setcheckboxcount((pre: any) => pre + 1);
        })
        .catch((err) => {
        });

    } else {
      mediaSingleUpload(
        [file],
        groupId,
        topVaultId,
        vaultId,
        dispatchImage,
        setIsUpdating,
        mediaType,
        uploadmodalVideo.close(),
        null,
        "create",
        category,
        sequence,
        history,
        projectId,
        abbreviation,
        projectName,
        category,
        productList,
        pageNumber,
        apiCall,
        id == 1 ? false : true
      );

      const ValidationId =
        datavalidation &&
        datavalidation.filter((item: any) => item.sequence == sequence);

      API.patch(`image_repository/quality_validation/${ValidationId[0]?.id}/`, {
        validation: false,
        unchecked: true,
      })
        .then((res: any) => {
          setcheckboxcount((pre: any) => pre + 1);
        })
        .catch((err) => {
        });
    }
  };

  // ----------------------------------------------------------------------------------
  // jsx
  return (
    <div data-testid="product-image-card" className={styles.container}>
      {ImageUploadModal.isOpen && (
                <MediaCreateImageUploadModal
                    onCloseModal={ImageUploadModal.close}
                    isOpen={ImageUploadModal.open}
                    sendNextModelGroupId={groupId}
                    categoryGet={category}
                    abbreviationGet={abbreviation}
                    sequenceSend={sequence}
                    type={'imageSingleUpload'}
                    topVaultIdImageSection = {topVaultId}
                />
            )}
      <div
        className={styles.imageContainer}
        data-columns={selectedTopVaultIds && selectedTopVaultIds.length}
      >

        {image && (
          <div className={styles.imageTitle}>
            <span className={styles.sequence_no}>
            {permissionAccess && permissionAccess?.filter((item:any)=>item?.top_vault == topVaultId)[0]?.action?.includes('U') ?
              <TextField
                variant="standard"
                sx={{
                  width: "3rem",
                  marginLeft: "0.3rem",
                  ".MuiInputBase-root.MuiInput-root": {
                    fontSize: "1rem !important",
                  },
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "primary.light",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "primary.main",
                  },
                }}
                type="number"
                inputProps={{
                  step: "any",
                  min: 1,
                }}
                onChange={(e: any) => handleChangeSequence(e)}
                value={sequenceNum}
                onBlur={handleSequenceChange}
              /> :
              <span>{sequenceNum}</span>}
            </span>
          </div>
        )}
        <VideoModal ref={VideoModalRef} />
        <div style={{ display: "flex", flexDirection: "column" }} ref={imgRef}>
          {!image ? (
            <div className={styles.noImage}>No {mediaType}</div>
          ) : mediaType == "image" ? (
            <>
              {isInView && image && (
                <Image
                  src={
                    isLoaded
                      ? image && image?.image_url_cdn
                      : image && image?.image_url_cdn
                  }
                  placeholderImg={NoImgLoader}
                  alt={image && image?.product_name}
                  onLoad={handleOnLoad}
                  onClick={() =>
                    imageExpandClick(
                      image && image.sequence,
                      image && image.top_vault
                    )
                  }
                  className={styles.image}
                />
              )}
            </>
          ) : (
            image && (
              <img
                className={styles.image}
                src={isLoaded ? image?.thumbnail : image?.thumbnail}
                onLoad={handleOnLoad}
                onClick={(e: any) => openModalInPreviewMode(image?.original)}
                alt={image?.image_name}
              />
            )
          )}
        </div>
        <div className={styles.buttonContainer}>
          {image && permissionAccess && permissionAccess?.filter((item:any)=>item?.top_vault == topVaultId)[0]?.action?.includes('D') && (
            <ClearIcon
              titleAccess={`Clear ${mediaType}`}
              className={styles.icon}
              onClick={handleClear}
            />
          )}
          {image && permissionAccess && permissionAccess?.filter((item:any)=>item?.top_vault == topVaultId)[0]?.action?.includes('D') && (
            <DeleteIcon
              titleAccess={`Delete ${mediaType}`}
              className={styles.icon}
              onClick={handleDelete}
            />
          )}
          {!image && permissionAccess && permissionAccess?.filter((item:any)=>item?.top_vault == topVaultId)[0]?.action?.includes('C') &&
            (naUploadInProgress ? (
              <CircularProgress
                className={styles.icon}
                color="inherit"
                size={"1rem"}
              />
            ) : (
              <Box className={styles.icon}>
                <Checkbox
                  style={{ padding: '0px' }}
                  onChange={NAhandleChange}
                  title={`Upload NA`}
                  inputProps={{ "aria-label": "controlled" }}
                  sx={{
                    margin: '0',
                    color: 'primary.main',
                    "& .MuiSvgIcon-root": { fontSize: 20 },
                  }}
                />
              </Box>
            ))}
          {!image ?
          permissionAccess && permissionAccess?.filter((item:any)=>item?.top_vault == topVaultId)[0]?.action?.includes('C') &&
            <UploadIcon
              titleAccess={`Upload ${mediaType}`}
              className={styles.icon}
              onClick={()=>ImageUploadModal.open()}
            /> :
            permissionAccess && permissionAccess?.filter((item:any)=>item?.top_vault == topVaultId)[0]?.action?.includes('U') &&
            <UploadIcon
            titleAccess={`Upload ${mediaType}`}
            className={styles.icon}
            onClick={mediaType == "image" ? browseFiles : browseVideo}
          /> }
          {image && sequence != 1 && permissionAccess && permissionAccess?.filter((item:any)=>item?.top_vault == topVaultId)[0]?.action?.includes('U') && (
            <MoveUpIcon
              titleAccess="Move up"
              className={styles.icon}
              onClick={handleMoveUp}
            />
          )}
          {image && permissionAccess && permissionAccess?.filter((item:any)=>item?.top_vault == topVaultId)[0]?.action?.includes('U') && (
            <MoveDownIcon
              titleAccess="Move down"
              className={styles.icon}
              onClick={handleMoveDown}
            />
          )}
          {image && mediaType == 'image' && permissionAccess && permissionAccess?.filter((item:any)=>item?.top_vault == topVaultId)[0]?.action?.includes('U') && (
            <EditIcon
            onClick={imageEditorHandler}
            className={styles.icon}
            sx={{ cursor: "pointer" }}
            />)
          }
          {image && mediaType == 'image' && permissionAccess && permissionAccess?.filter((item:any)=>item?.top_vault == topVaultId)[0]?.action?.includes('U') && (
            <LoadingButton
              title="Rotate left"
              className={styles.icon}
              style={{ padding: 0, minWidth: "1.5rem", height: "1.7rem" }}
              loading={isRotating?.type == "undo" ? isRotating?.load : false}
              disabled={isRotating?.load}
              sx={{
                marginTop: '2px',
                '.MuiCircularProgress-root': {
                  color: 'primary.main',
                },
              }}
            >
              <RotateLeftIcon onClick={() => handleRotate("undo")} />
            </LoadingButton>
          )}
          {image && mediaType == 'image' && permissionAccess && permissionAccess?.filter((item:any)=>item?.top_vault == topVaultId)[0]?.action?.includes('U') && (
            <LoadingButton
              title="Rotate right"
              className={styles.icon}
              style={{ padding: 0, minWidth: "1.5rem", height: "1.7rem" }}
              loading={isRotating?.type == "redo" ? isRotating?.load : false}
              disabled={isRotating?.load}
              sx={{
                marginTop: '2px',
                '.MuiCircularProgress-root': {
                  color: 'primary.main',
                },
              }}
            >
              <RotateRightIcon onClick={() => handleRotate("redo")} />
            </LoadingButton>
          )}
          {permissionAccess && permissionAccess?.filter((item:any)=>item?.top_vault == topVaultId)[0]?.action?.includes('C') &&
          <ArrowCircleUpIcon
            titleAccess="Add blank above"
            className={styles.icon}
            onClick={handleCreateBlankAbove}
          />}
          {permissionAccess && permissionAccess?.filter((item:any)=>item?.top_vault == topVaultId)[0]?.action?.includes('C') &&
          <ArrowCircleDownIcon
            titleAccess="Add blank below"
            className={styles.icon}
            onClick={handleCreateBlankBelow}
          />}
          {image && permissionAccess && permissionAccess?.filter((item:any)=>item?.top_vault == topVaultId)[0]?.action?.includes('U') && (
            <DriveFileMoveOutlinedIcon
              titleAccess={`Move ${mediaType}`}
              className={styles.icon}
              onClick={() => movemodal.open()}
            />
          )}
        </div>
        {/* @ts-ignore */}
        {<RViewerJS>
          <div>
            {selectedsceImages.length > 0 &&
              selectedsceImages?.map((item: any) => {
                return (
                  <img
                    src={
                      isLoaded
                        ? item && item.image_url_cdn
                        : item && item.image_url_cdn
                    }
                    style={{ display: "none" }}
                    onLoad={handleOnLoad}
                    ref={imagereff}
                    alt={item && item.product_name}
                  />
                );
              })}
          </div>
        </RViewerJS>
        }
      </div>
      {imageEditorModal?.isOpen &&
        <ArchImageEditModal
          Loader={isUpdating}
          open={imageEditorModal.isOpen}
          imageURI={relImageURL}
          closeModal={imageEditorModal.close}
          imageUrl={image?.original}
          updateImage={updateEditedImage}
        />}

      <MediaAddSingleImageModel
        isOpen={uploadmodal.isOpen}
        onCloseModal={uploadmodal.close}
        saveImage={saveImage}
        isUpdating={isUpdating}
        type={'desktop'}
      />
      <MediaAddSingleVideoModel
        isOpen={uploadmodalVideo.isOpen}
        onCloseModal={uploadmodalVideo.close}
        saveVideo={saveVideo}
        isUpdating={isUpdating}
        type={'desktop'}
      />
      <MediaMoveSingleImageModel
        isOpen={movemodal.isOpen}
        onCloseModal={movemodal.close}
        imageId={image && image.id}
      />
    </div>
  );
};

// ----------------------------------------------------------------------------------
export default MediaCreateProductImageCard;
