import React, { useEffect, useState } from "react";
import {
  Badge,
  Box,
  Button,
  Paper,
  Skeleton,
  Stack,
  Typography,
} from "@mui/material";
import styles from "../InternalLanding/InternalLanding.module.scss";
import { useHistory } from "react-router-dom";
import analysis from "../../Assets/images/analysis.svg";
import Idea from "../../Assets/images/Idea.png";
import ImageRoundedIcon from "@mui/icons-material/ImageRounded";
import TipsAndUpdatesIcon from "@mui/icons-material/TipsAndUpdates";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import Grid from "@mui/material/Grid";
import BookmarkBorderIcon from "@mui/icons-material/BookmarkBorder";
import PushPinIcon from "@mui/icons-material/PushPin";
import PushPinOutlinedIcon from "@mui/icons-material/PushPinOutlined";
import RoomPreferencesOutlinedIcon from "@mui/icons-material/RoomPreferencesOutlined";
import PermIdentityOutlinedIcon from "@mui/icons-material/PermIdentityOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import config from "../../Assets/images/configuration.svg";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import MoMIcon from "../../Assets/images/MoMIcon.svg";
import despIN from "../../Assets/images/DespatchIN.svg";
import despOUT from "../../Assets/images/DespatchOUT.svg";
import reportIcon from "../../Assets/images/Report.svg";
import bomIcon from "../../Assets/images/hierarchy.png";
import circuitIcon from "../../Assets/images/microchip.svg";
import CurrencyRupeeRoundedIcon from "@mui/icons-material/CurrencyRupeeRounded";
import cadIcon from "../../Assets/images/CADIcon.svg";
import ideaCreate from "../../Assets/images/IdeaCreate.png";
import ProductProgress from "../Landing/ProductProgress";
import { API } from "../../api-services";
import { useBaseModal } from "../SearchPage/useBaseModal";
import RidirectionModal from "../../ViewModule/Comparefolder/RidirectionModal";
import StarIcon from "@mui/icons-material/Star";
import featureIcon from "../../Assets/images/FeatureIcon.png";
import ChangeHistoryRoundedIcon from "@mui/icons-material/ChangeHistoryRounded";
import MonitorRoundedIcon from "@mui/icons-material/MonitorRounded";
import imageIcon from "../../Assets/images/imageIcon.png";
import functionIcon from "../../Assets/images/function.svg";
import calender from "../../Assets/images/calender.svg";
import usreperm from "../../Assets/images/userpermis.svg";
import deletefill from "../../Assets/images/deletefill.svg";
import CastForEducationIcon from "@mui/icons-material/CastForEducation";

//SLick carousel
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import useWindowDimensions from "../../ui-reusable-component/useWindowDimensions";
import { AUTH } from "../../Redux/Services/auth.service";
import { repeat } from "lodash";

import ProjectmailselectionModal from "../ProjectMailModule/ProjectmailselectionModal";
import { useSelector } from "react-redux";
import { userSelectors } from "../../Redux/Reducers/user.reducer";

interface IProductProgress { }
interface ILandingCountGet {
  SPOC: null | string;
  architecture_count: number;
  bom_part_count: number;
  completed_status: number;
  idea_count: number;
  idea_value: string;
  media_count: number;
  project_description: string;
  project_id: number;
  project_name: string;
  product_data: any;
}
interface IInternalLandingProps {
  item?: any;
  index?: number;
  calculationData?: any;
  calcLoading?: any;
  setApiCallCount?: any;
  productItem?: any;
}
function InternalLanding(props: IInternalLandingProps) {
  const history = useHistory();
  const {
    item,
    index,
    calculationData,
    calcLoading,
    setApiCallCount,
    productItem,
  } = props;
  const [apiCallProduct, setApiCallProduct] = useState<boolean>(false);
  const [productList, setProductList] = useState<any>([]);
  const [apiCallAnalysisProduct, setApiCallAnalysisProduct] =
    useState<boolean>(false);
  const [productAnalysisList, setProductAnalysisList] = useState<any>();
  const [emaildata, setEmaildata] = useState<boolean>();
  const validation = useBaseModal();
  const mailmodal = useBaseModal();
  const { height, width } = useWindowDimensions();
  const userProfilePic = useSelector(userSelectors.selectAll);

  const settings = {
    dots: true,
    infinite: false,
    slidesToShow: 5,
    slidesToScroll: 5,
    autoplay: false,
    speed: 1000,
    autoplaySpeed: 1000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const PinedClicked = (projectId: any, pinnedStatus: any) => {
    API.put(
      `/customer/dashboard/${projectId}/`,
      { pin_project: pinnedStatus ? false : true },
      {},
      0
    )
      .then((res: any) => {
        setApiCallCount((prev: any) => prev + 1);
      })
      .catch(() => { });
  };

  const redirectViewData = (Project_Id: any, FirstTopVoult: any) => {
    // @ts-ignore
    sessionStorage.removeItem("node");
    sessionStorage.removeItem("bomModule");
    history.push(`/view/${Project_Id}/${FirstTopVoult}/${0}/product/false`);
    sessionStorage.setItem("activeIndex", `${FirstTopVoult}`);
  };

  const redirectComparePage = (Project_Id: any, allTopVouts: any) => {
    var FiterTovoults =
      allTopVouts &&
      allTopVouts.map((item: any, index: any) => {
        return item?.top_vault_id;
      });

    API.get("customer/compare_landing/", { project: Project_Id }).then(
      (res: any) => {

        if (Object.keys(res?.data)?.length > 0) {
          var sliceTopvaoults =
            res?.data?.top_vault && res?.data?.top_vault.slice(0, 4);

          var modulepression = res?.data?.module == "BOM" ? 1 : 2;
          history.push(
            `/newcompare/${Project_Id}/${modulepression}/${sliceTopvaoults?.toString()}/product/0/Vehicle/0/vehicle/false/-1/null`
          );
        } else {
          validation.open();
        }
        // validation
      }
    );
  };
  const mouseHoverHandler = async (id: any) => {
    setApiCallProduct(false);
    API.get(
      `/customer/dashboard/`,
      {
        get_idea_topvault: true,
        project: id,
      },
      0
    ).then((res: any) => {
      setApiCallProduct(true);
      setProductList(res?.data);
    });
  };

  const mouseHoverAnalysisHandler = async (id: any) => {
    setApiCallAnalysisProduct(false);
    API.get(
      `/customer/analysis/`,
      {
        get_drawer_values: "analysis_module",
        project: id,
      },
      0
    ).then((res: any) => {
      setApiCallAnalysisProduct(true);
      setProductAnalysisList(res.data);
    });
  };

  const redirectBannerPage = (Project_Id: any, Project_name: any) => {
    history.push(`/banner/${Project_Id}/${Project_name}/0/0/0`);
  };

  const analysisHandler: any = () => {
    if (apiCallAnalysisProduct && productAnalysisList?.length > 0) {
      let filteredBOM: any =
        productAnalysisList &&
        productAnalysisList?.filter((item: any) => {
          return item?.module == "BOM";
        });
      if (filteredBOM?.length > 0) {
        history.push(
          `/analysis/${filteredBOM[0]?.project}/${filteredBOM
            ?.map((itemId: any) => {
              return itemId?.top_vault_id;
            })
            ?.toString()}`
        );
      } else {
        let filteredCosting: any =
          productAnalysisList &&
          productAnalysisList?.filter((item: any) => {
            return item?.module == "Costing";
          });
        history.push(
          `/analysis_costing/${filteredCosting[0]?.project}/${filteredCosting
            ?.map((itemId: any) => {
              return itemId?.top_vault_id;
            })
            ?.toString()}`
        );
      }
    }
  };

  const productsGetfromProject: any =
    productItem &&
    productItem?.filter((val: any) => {
      return val?.id == item?.project_id;
    });


  const EmailHandler = (ids: any, name: any) => {
    // @ts-ignore
    setEmaildata({ ProjectIds: ids, ProjectName: name });
    mailmodal.open();
  };

  return (
    <Box>
      <RidirectionModal
        isOpen={validation.isOpen}
        onCloseModal={validation.close}
        validationText={"permission"}
      />

      {mailmodal.isOpen && (
        <ProjectmailselectionModal
          isOpen={mailmodal.isOpen}
          onCloseModal={mailmodal.close}
          Emaildata={emaildata}
        />
      )}

      <Grid
        container
        spacing={2}
        columnSpacing={4}
        className={styles.projectTitlesec}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={3.2}
          lg={2.8}
          xl={2.8}
          sx={{ paddingTop: "0 !important" }}
          className={styles.projectDetails}
        >
          <Box>
            <Box
              sx={{
                display: {
                  xs: "block",
                  sm: "block",
                  md: "block",
                  lg: "none",
                  xl: "none",
                },
                position: "relative",
                marginBottom: "-2rem",
                zIndex: "1",
              }}
            >
              <Box sx={{ textAlign: "right" }}>
                {!item?.is_pinned && (
                  <PushPinOutlinedIcon
                    sx={{
                      fontSize: "1.5rem",
                      cursor: "pointer",
                      ":hover": {
                        transform: "scale(1.1)",
                        transition: "transform 0.5s ease",
                      },
                    }}
                    titleAccess="Pin project on top"
                    onClick={() => {
                      PinedClicked(item?.project_id, item?.is_pinned);
                    }}
                  />
                )}
                {item?.is_pinned && (
                  <PushPinIcon
                    titleAccess="Unpin"
                    onClick={() => {
                      PinedClicked(item?.project_id, item?.is_pinned);
                    }}
                    sx={{
                      color: "green",
                      fontSize: "1.5rem",
                      cursor: "pointer",
                      ":hover": {
                        transform: "scale(1.1)",
                        transition: "transform 0.5s ease",
                      },
                    }}
                  />
                )}
              </Box>
            </Box>
            <Box>
              <Typography className={styles.project_name}>
                {item?.project_name}
                <span
                  className={styles.project_description}
                >{` [ ${item?.project_description} ]`}</span>
              </Typography>
              <div className={styles.innerSection}>
                <Grid container className={styles.btnLink}>
                  <Grid item>
                    {!calcLoading ? (
                      <div
                        className={styles.progresbar}
                        style={
                          AUTH.isSuperUser || item?.is_project_admin
                            ? { cursor: "pointer" }
                            : {}
                        }
                        onClick={() => {
                          return (
                            AUTH.isSuperUser &&
                            // ||
                            //  item?.is_project_admin ? productItem?.filter((dataItem: any) => {
                            // return dataItem?.id == item?.project_id })?.[0]?.top_vaults?.length == 1 ?

                            // history.push(`/dashboard/${item?.project_id}/${productItem?.filter((dataItem: any) => { return dataItem?.id == item?.project_id })?.[0]?.top_vaults?.[0]?.product_id}`)

                            // :
                            history.push(
                              `/dashboard/${item?.project_id}/AllProduct`
                            )
                          );

                          // : ''
                        }}
                      >
                        <ProductProgress
                          progressData={
                            calculationData &&
                            calculationData?.overall_progress &&
                            calculationData?.overall_progress[0]
                          }
                        />
                      </div>
                    ) : (
                      <Skeleton animation="wave" />
                    )}
                    <Box
                      sx={{
                        height: { lg: "6.7rem", xl: "8.5rem" },
                        marginTop: "0.5rem",
                      }}
                    >
                      {!calcLoading ? (
                        <div className={styles.moduleDetails}>
                          {item?.has_ideas && (
                            <Stack
                              direction="row"
                              alignItems="flex-start"
                              columnGap={1}
                            >
                              <TipsAndUpdatesIcon
                                color="primary"
                                sx={{ fontSize: "1.5rem" }}
                              />
                              {item?.has_ideas && (
                                <Typography className={styles.project_module}>
                                  {`${calculationData?.idea_count?.idea_count} Ideas Worth `}

                                  {`${calculationData?.idea_count?.total_cost} INR`}
                                </Typography>
                              )}
                            </Stack>
                          )}
                          {calculationData?.has_media == true &&
                            calculationData?.has_architecture == true && (
                              <Stack
                                direction="row"
                                alignItems="center"
                                columnGap={1}
                              >
                                <ImageRoundedIcon
                                  color="primary"
                                  sx={{ fontSize: "1.5rem" }}
                                />
                                <Typography className={styles.project_module}>
                                  {`${calculationData?.total_media} Images & `}
                                  {`${calculationData?.architecture_count} Architecture Diagram`}
                                </Typography>
                              </Stack>
                            )}
                          <Stack
                            direction="row"
                            alignItems="center"
                            columnGap={1}
                          >
                            <AccountTreeIcon
                              color="primary"
                              sx={{ fontSize: "1.5rem" }}
                            />
                            <Typography className={styles.project_module}>
                              {item?.costing_status == false
                                ? `${calculationData?.bom_count} BOM Parts`
                                : `${calculationData?.bom_count}  Parts BOM & Zero-based costing`}
                            </Typography>
                          </Stack>
                          {calculationData?.has_features == true &&
                            calculationData?.feature_count != 0 && (
                              <Stack
                                direction="row"
                                alignItems="center"
                                columnGap={1}
                              >
                                <StarIcon
                                  color="primary"
                                  sx={{ fontSize: "1.5rem" }}
                                />
                                <Typography className={styles.project_module}>
                                  {`${calculationData?.feature_count} Features`}
                                </Typography>
                              </Stack>
                            )}
                        </div>
                      ) : (
                        <Box>
                          <Skeleton animation="wave" />
                          <Skeleton animation="wave" />
                          <Skeleton animation="wave" />
                          <Skeleton animation="wave" />
                        </Box>
                      )}
                    </Box>
                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr 1fr 1fr 1fr",
                        columnGap: "10px",
                        margin: "0.5rem 0 0",
                      }}
                    >
                      <MonitorRoundedIcon
                        className={styles.iconLink}
                        titleAccess="View Data"
                        sx={{
                          cursor: "pointer",
                          filter: "drop-shadow(3px 4px 2px rgba(0, 0, 0, 0.4))",
                          fontSize: "2.5rem",
                          display: "flex",
                          alignItems: "center",
                          margin: "0 auto",
                        }}
                        onClick={() =>
                          redirectViewData(
                            item?.project_id,
                            item?.top_vault_details &&
                            item?.top_vault_details[0]?.top_vault_id
                          )
                        }
                      />

                      {item && item?.top_vault_details.length >= 2 && (
                        <ChangeHistoryRoundedIcon
                          titleAccess="Compare"
                          className={styles.iconLink}
                          sx={{
                            cursor: "pointer",
                            filter:
                              "drop-shadow(3px 4px 2px rgba(0, 0, 0, 0.4))",
                            fontSize: "2.9rem",
                            display: "flex",
                            alignItems: "center",
                            margin: "0 auto",
                            marginTop: "-0.3rem",
                          }}
                          onClick={() =>
                            redirectComparePage(
                              item?.project_id,
                              item && item && item?.top_vault_details
                            )
                          }
                        />
                      )}

                      {item?.has_analysis && (
                        <img
                          src={analysis}
                          className={styles.iconLink}
                          style={{
                            width: "2.5rem",
                            height: "2rem",
                            // fontSize: "3.5rem",
                            margin: "0 auto",
                            marginTop: "3px",
                            alignItems: "center",
                            display: "flex",
                            cursor: "pointer",
                            filter:
                              "drop-shadow(3px 4px 2px rgba(0, 0, 0, 0.4))",
                          }}
                          title="Analysis"
                          onClick={() =>
                            productAnalysisList?.length > 0 && analysisHandler()
                          }
                          onMouseEnter={() =>
                            mouseHoverAnalysisHandler(item?.project_id)
                          }
                        />
                      )}

                      {item?.has_ideas && (
                        <img
                          src={Idea}
                          title="Evaluate Idea"
                          className={styles.iconLink}
                          style={{
                            height: "2rem",
                            margin: "0 auto",
                            marginTop: "3px",
                            alignItems: "center",
                            display: "flex",
                            cursor: "pointer",
                            filter:
                              "drop-shadow(3px 4px 2px rgba(0, 0, 0, 0.4))",
                          }}
                          onClick={() => {
                            return (
                              productList?.length > 0 &&
                              apiCallProduct &&
                              history.push(
                                `/idea_dashboard/${item?.project_id}/${productList[0]?.topvault_id}`
                              )
                            );
                          }}
                          onMouseEnter={() =>
                            mouseHoverHandler(item?.project_id)
                          }
                        />
                      )}
                      {item?.banner && (
                        <BookmarkBorderIcon
                          titleAccess="Banner"
                          className={styles.iconLink}
                          sx={{
                            cursor: "pointer",
                            filter:
                              "drop-shadow(3px 4px 2px rgba(0, 0, 0, 0.4))",
                            fontSize: "2.5rem",
                            display: "flex",
                            alignItems: "center",
                            margin: "0 auto",
                          }}
                          onClick={() =>
                            redirectBannerPage(
                              item?.project_id,
                              item?.project_name
                            )
                          }
                        />
                      )}
                    </Box>
                  </Grid>
                </Grid>
              </div>
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={8.8}
          lg={9.2}
          xl={9.2}
          className={styles.prodctSec}
          sx={{
            paddingTop: "0 !important",
            display: {
              xs: "block",
              sm: "block",
              md: "block",
              lg: "block",
              xl: "block",
            },
          }}
        >
          <Box sx={{ display: "flex", height: "100%" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: { xs: "column", sm: "column", md: "row" },
                columnGap: "2rem",
                width: "100%",
                marginRight: { xs: "0", sm: "0.6rem" },
              }}
            >
              <Box
                sx={{
                  display: {
                    xs: "grid",
                    sm: "flex",
                    md: "grid",
                    lg: "grid",
                    xl: "grid",
                  },
                  gridTemplateColumns: {
                    xs: "1fr 1fr 1fr 1fr",
                    md: "1fr 1fr",
                    lg: "1fr 1fr",
                    xl: "1fr 1fr",
                  },
                  flexDirection: { sm: "row" },
                  columnGap: "1rem",
                  rowGap: "1rem",
                  flexWrap: { xs: "wrap", sm: "nowrap" },
                  borderTop: {
                    xs: "1px solid",
                    sm: "1px solid",
                    md: "none",
                    lg: "none",
                    xl: "none",
                  },
                  borderBottom: {
                    xs: "1px solid",
                    sm: "1px solid",
                    md: "none",
                    lg: "none",
                    xl: "none",
                  },
                  justifyContent: "center",
                  paddingTop: { xs: "0.5rem", sm: "0.2rem" },
                  paddingBottom: { xs: "0.5rem", sm: "0.2rem" },
                  alignItems: "center",
                }}
              >
                <Box
                  sx={
                    AUTH.isSuperUser || item?.is_project_admin
                      ? { margin: "0 auto", cursor: "pointer" }
                      : { margin: "0 auto", cursor: "not-allowed" }
                  }
                >
                  <img
                    title="Project Plan"
                    className={styles.internalIcons}
                    src={calender}
                    style={{
                      width: "auto",
                      height: "2.5rem",
                      margin: "auto",
                      verticalAlign: "middle",
                    }}
                    onClick={() =>
                      AUTH.isSuperUser || item?.is_project_admin
                        ? history.push(`/Milestone/${item?.project_id}`)
                        : ""
                    }
                  />
                </Box>
                <Box
                  sx={{
                    margin: "0 auto",
                    cursor: item?.has_user_permission
                      ? "pointer"
                      : "not-allowed",
                    opacity: !item?.has_user_permission ? "0.4" : "1",
                  }}
                  onClick={() =>
                    item?.has_user_permission &&
                    history.push(
                      `/user-permissions/${item?.project_id}/0/all/projectSpecific`
                    )
                  }
                >
                  <img
                    title="User Permission"
                    className={styles.internalIcons}
                    src={usreperm}
                    style={{
                      width: "auto",
                      height: "2.5rem",
                      margin: "auto",
                      verticalAlign: "middle",
                    }}
                  />
                </Box>
                <Box
                  sx={{ margin: "0 auto", cursor: "pointer" }}
                  onClick={() =>
                    history.push(`/MinutesofMeeting/${item?.project_id}/0/0`)
                  }
                >
                  <img
                    title="Minutes of Meeting"
                    className={styles.internalIcons}
                    src={MoMIcon}
                    style={{
                      width: "auto",
                      height: "2.5rem",
                      margin: "auto",
                      verticalAlign: "middle",
                    }}
                  />
                </Box>
                <Box
                  sx={{ margin: "0 auto", cursor: "pointer" }}
                  onClick={() =>
                    history.push(`/recyclebin/${item?.project_id}`)
                  }
                >
                  {productsGetfromProject &&
                    productsGetfromProject[0]?.trash_count != 0 ? (
                    <Badge
                      color="error"
                      badgeContent={productsGetfromProject[0]?.trash_count}
                    >
                      <img
                        title="Recycle bin"
                        className={styles.internalIcons}
                        src={deletefill}
                        style={{
                          width: "auto",
                          height: "2.5rem",
                          margin: "-8px -3px 0 0",
                          verticalAlign: "middle",
                        }}
                      />
                    </Badge>
                  ) : (
                    <DeleteOutlinedIcon
                      className={styles.internalIcons}
                      titleAccess="Recycle bin"
                      sx={{ fontSize: "2.75rem", verticalAlign: "middle" }}
                    />
                  )}
                </Box>

                <Box
                  sx={{
                    margin: "0 auto",
                    opacity:
                      (userProfilePic && userProfilePic[0]?.is_superuser) ||
                        (userProfilePic && userProfilePic[0]?.is_project_admin)
                        ? "1"
                        : "0.4",
                    cursor:
                      (userProfilePic && userProfilePic[0]?.is_superuser) ||
                        (userProfilePic && userProfilePic[0]?.is_project_admin)
                        ? "pointer"
                        : "not-allowed",
                    verticalAlign: "middle",
                  }}
                >
                  <EmailOutlinedIcon
                    onClick={() =>
                      ((userProfilePic && userProfilePic[0]?.is_superuser) ||
                        (userProfilePic &&
                          userProfilePic[0]?.is_project_admin)) &&
                      EmailHandler(item?.project_id, item?.project_name)
                    }
                    className={styles.internalIcons}
                    titleAccess="Send Mail"
                    sx={{ fontSize: "2.75rem" }}
                  />
                </Box>
                <Box
                  sx={{ margin: "0 auto", cursor: "pointer" }}
                  onClick={() =>
                    history.push(
                      `/eirandworkshop/${item?.project_id}/${item?.project_name}/0/0/0`
                    )
                  }
                >
                  <img
                    title="Engineering Insight Reports"
                    className={styles.internalIcons}
                    src={reportIcon}
                    style={{
                      width: "auto",
                      height: "2.5rem",
                      margin: "auto",
                      verticalAlign: "middle",
                    }}
                  />
                </Box>
                <Box
                  sx={{ margin: "0 auto", cursor: "pointer" }}
                  onClick={() =>
                    history.push(
                      `/configurationmain/${item?.project_id}/${item?.project_name}`
                    )
                  }
                >
                  {/* onClick={()=>history.push(`/ideaconfiguration/${item?.project_id}`)} */}
                  <img
                    title="Project Configuration"
                    className={styles.internalIcons}
                    src={config}
                    style={{
                      width: "2.5rem",
                      height: "auto",
                      verticalAlign: "middle",
                    }}
                  />
                </Box>
                <Box sx={{ margin: "0 auto", cursor: "pointer" }}>
                  <img title="Functions & Filters" className={styles.internalIcons}
                    src={functionIcon}
                    style={{ width: "auto", height: "2.5rem", margin: "auto", cursor: 'pointer', verticalAlign: 'middle' }}
                  />
                  {/* <CastForEducationIcon
                    titleAccess="API Console"
                    style={{
                      width: "auto",
                      height: "2.5rem",
                      margin: "auto",
                      cursor: "pointer",
                      verticalAlign: "middle",
                    }}
                    onClick={() =>
                      history.push(
                        `/API-console/${item?.project_id}/${item?.project_name}/0/0/0/0`
                      )
                    }
                  /> */}
                </Box>
              </Box>
              <Box
                sx={{
                  width: {
                    xs: "100%",
                    sm: "100%",
                    md: "90%",
                    lg: '"90%"',
                    xl: "90%",
                  },
                  padding: { xs: "0.5rem 0", sm: "0" },
                }}
              >
                {productsGetfromProject &&
                  productsGetfromProject[0]?.top_vaults?.map((val: any) => {
                    return (
                      <Box
                        sx={{
                          display: {
                            xs: "flex",
                            sm: "grid",
                            md: "grid",
                            lg: "grid",
                            xl: "grid",
                          },
                          gridTemplateColumns: "23% 30% 1fr",
                          flexDirection: { xs: "column" },
                          columnGap: {
                            sm: "1rem",
                            md: "1rem",
                            lg: "1rem",
                            xl: "2rem",
                          },
                          gap: { xs: "0.5rem", sm: "1rem", md: "1rem" },
                          marginTop: { xs: "1rem", sm: "0" },
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            whiteSpace: "nowrap",
                            fontSize: "1rem",
                            width: "100%",
                            lineHeight: "1.2",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                          }}
                        >
                          <span title={val?.product_name} style={{ textAlign: "right", overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap' }}>
                            {val?.product_name} 
                          </span>
                          <span style={{ padding: "0 0.5rem" }}>
                            {val?.product_id}
                          </span>
                        </Typography>
                        <Box
                          sx={
                            AUTH.isSuperUser || item?.is_project_admin
                              ? { width: "100%", cursor: "pointer" }
                              : { width: "100%" }
                          }
                          onClick={() => {
                            return AUTH.isSuperUser || item?.is_project_admin
                              ? productsGetfromProject[0]?.top_vaults.length ==
                                1
                                ? history.push(
                                  `/dashboard/${item?.project_id}/AllProduct`
                                )
                                : history.push(
                                  `/dashboard/${item?.project_id}/${val?.product_id}`
                                )
                              : "";
                          }}
                        >
                          <ProductProgress
                            progressData={val?.overall_progress[0]}
                          />
                        </Box>
                        <Box
                          sx={{
                            width: "100%",
                            alignItems: "center",
                            gap: { xs: "1rem", sm: "0.5rem" },
                            display: {
                              xs: "grid",
                              sm: "grid",
                              md: "grid",
                              lg: "grid",
                              xl: "grid",
                            },
                            gridTemplateColumns: {
                              xs: "repeat(4, 1fr)",
                              sm: "repeat(8, 1fr)",
                              md: "repeat(8, 1fr)",
                              lg: "repeat(8, 1fr)",
                              xl: "repeat(8, 1fr)",
                            },
                          }}
                        >
                          {Array.isArray(val.modules) &&
                            typeof val.modules !== undefined &&
                            val?.modules?.includes("Product Inward") ? (
                            <img
                              title="Product Inward"
                              className={styles.internalIcons}
                              src={despIN}
                              style={{
                                width: "2.5rem",
                                height: "auto",
                                cursor: "pointer",
                                margin: "0 auto",
                              }}
                              onClick={() =>
                                history.push(
                                  `/inward/${item?.project_id}/${item?.project_name}/${val?.id}`
                                )
                              }
                            />
                          ) : (
                            <img
                              title="Product Inward"
                              className={styles.internalIcons}
                              src={despIN}
                              style={{
                                width: "2.5rem",
                                height: "auto",
                                margin: "0 auto",
                                opacity: "0.3",
                              }}
                            />
                          )}

                          {Array.isArray(val.modules) &&
                            typeof val.modules !== undefined &&
                            val?.modules?.includes("Media") ? (
                            <img
                              title="Media Entry"
                              className={styles.internalIcons}
                              src={imageIcon}
                              style={{
                                width: "2.5rem",
                                height: "auto",
                                cursor: "pointer",
                                margin: "0 auto",
                              }}
                              onClick={() =>
                                history.push(
                                  `/mediacreateselect/${item?.project_id}/${item?.project_name}`
                                )
                              }
                            />
                          ) : (
                            <img
                              title="Media Entry"
                              className={styles.internalIcons}
                              src={imageIcon}
                              style={{
                                width: "2.5rem",
                                height: "auto",
                                opacity: "0.3",
                                margin: "0 auto",
                              }}
                            />
                          )}

                          {Array.isArray(val.modules) &&
                            typeof val.modules !== undefined &&
                            val?.modules?.includes("Features") ? (
                            <img
                              title="Feature Entry"
                              className={styles.internalIcons}
                              src={featureIcon}
                              style={{
                                width: "2.5rem",
                                height: "auto",
                                cursor: "pointer",
                                margin: "0 auto",
                              }}
                              onClick={() =>
                                history.push(
                                  `/createfeature/${item?.project_id}/${item?.project_name}/1/0/0/0/0/1`
                                )
                              }
                            />
                          ) : (
                            <img
                              title="Feature Entry"
                              className={styles.internalIcons}
                              src={featureIcon}
                              style={{
                                width: "2.5rem",
                                height: "auto",
                                opacity: "0.3",
                                margin: "0 auto",
                              }}
                            />
                          )}

                          {Array.isArray(val.modules) &&
                            typeof val.modules !== undefined &&
                            val?.modules?.includes("Architecture") ? (
                            <img
                              title="Architecture Entry"
                              className={styles.internalIcons}
                              src={circuitIcon}
                              style={{
                                width: "2.5rem",
                                height: "auto",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                history.push(
                                  `/architecturecreateinternal/${item?.project_id}/${item?.project_name}/0/0/0/0/0/0/0/0/0`
                                )
                              }
                            />
                          ) : (
                            <img
                              title="Architecture Entry"
                              className={styles.internalIcons}
                              src={circuitIcon}
                              style={{
                                width: "2.5rem",
                                height: "auto",
                                opacity: "0.3",
                                margin: "0 auto",
                              }}
                            />
                          )}

                          {Array.isArray(val.modules) &&
                            typeof val.modules !== undefined &&
                            val?.modules?.includes("BOM") ? (
                            <img
                              title="BOM Entry"
                              className={styles.internalIcons}
                              src={bomIcon}
                              onClick={() =>
                                history.push(
                                  `/BOMCreate/${item?.project_id}/${item?.project_name
                                  }/${val?.bom_product[0]
                                  }/${val?.bom_product?.toString()}/true`
                                )
                              }
                              style={{
                                width: "2.5rem",
                                height: "auto",
                                cursor: "pointer",
                                margin: "0 auto",
                              }}
                            />
                          ) : (
                            <img
                              title="BOM Entry"
                              className={styles.internalIcons}
                              src={bomIcon}
                              style={{
                                width: "2.5rem",
                                height: "auto",
                                opacity: "0.3",
                                margin: "0 auto",
                              }}
                            />
                          )}

                          {Array.isArray(val.modules) &&
                            typeof val.modules !== undefined &&
                            val?.modules?.includes("Costing") ? (
                            <CurrencyRupeeRoundedIcon
                              titleAccess="Cost Estimation"
                              className={styles.internalIcons}
                              sx={{
                                fontSize: "2.75rem",
                                cursor: "pointer",
                                margin: "0 auto",
                              }}
                              onClick={() =>
                                history.push(
                                  `/costingsystemlanding/${item?.project_id}/${item?.project_name
                                  }/${val?.top_vault_ids[0]
                                  }/${val?.top_vault_ids?.toString()}/true`
                                )
                              }
                            />
                          ) : (
                            <CurrencyRupeeRoundedIcon
                              titleAccess="Cost Estimation"
                              className={styles.internalIcons}
                              sx={{
                                fontSize: "2.75rem",
                                opacity: "0.3",
                                margin: "0 auto",
                              }}
                            />
                          )}

                          {Array.isArray(val.modules) &&
                            typeof val.modules !== undefined &&
                            val?.modules?.includes("Ideas") ? (
                            <img
                              title="Ideas Entry"
                              className={styles.internalIcons}
                              src={ideaCreate}
                              style={{
                                width: "2.5rem",
                                height: "auto",
                                cursor: "pointer",
                                margin: "0 auto",
                              }}
                              onClick={() =>
                                history.push(
                                  `/idea-generation/${item?.project_id}/${item?.project_name}/${val?.id}/0/0/0`
                                )
                              }
                            />
                          ) : (
                            <img
                              title="Ideas Entry"
                              className={styles.internalIcons}
                              src={ideaCreate}
                              style={{
                                width: "2.5rem",
                                height: "auto",
                                opacity: "0.3",
                                margin: "0 auto",
                              }}
                            />
                          )}

                          {/* {Array.isArray(val.modules) && typeof val.modules !== undefined && val?.modules?.includes("CAD") ? <img title="CAD" className={styles.internalIcons}
                            src={cadIcon}
                            style={{ width: "2.5rem", height: "auto", cursor: 'pointer', margin:'0 auto' }}
                          /> : <img title="CAD" className={styles.internalIcons}
                            src={cadIcon}
                            style={{ width: "2.5rem", height: "auto", cursor: 'not-allowed', opacity: '0.3' }}
                          />} */}

                          {Array.isArray(val.modules) &&
                            typeof val.modules !== undefined &&
                            val?.modules?.includes("Product Outward") ? (
                            <img
                              title="Product Outward"
                              className={styles.internalIcons}
                              src={despOUT}
                              style={{
                                width: "2.5rem",
                                height: "auto",
                                cursor: "pointer",
                                margin: "0 auto",
                              }}
                              onClick={() =>
                                history.push(
                                  `/dispatch/${item?.project_id}/${item?.project_name}/${val?.id}`
                                )
                              }
                            />
                          ) : (
                            <img
                              title="Product Outward"
                              className={styles.internalIcons}
                              src={despOUT}
                              style={{
                                width: "2.5rem",
                                height: "auto",
                                cursor: "not-allowed",
                                opacity: "0.3",
                              }}
                            />
                          )}
                        </Box>
                      </Box>
                    );
                  })}
              </Box>
            </Box>
            <Box
              sx={{
                textAlign: "right",
                display: { xs: "none", sm: "none", md: "block" },
              }}
            >
              {!item?.is_pinned && (
                <PushPinOutlinedIcon
                  sx={{
                    fontSize: "1.5rem",
                    cursor: "pointer",
                    ":hover": {
                      transform: "scale(1.1)",
                      transition: "transform 0.5s ease",
                    },
                  }}
                  titleAccess="Pin project on top"
                  onClick={() => {
                    PinedClicked(item?.project_id, item?.is_pinned);
                  }}
                />
              )}
              {item?.is_pinned && (
                <PushPinIcon
                  titleAccess="Unpin"
                  onClick={() => {
                    PinedClicked(item?.project_id, item?.is_pinned);
                  }}
                  sx={{
                    color: "green",
                    fontSize: "1.5rem",
                    cursor: "pointer",
                    ":hover": {
                      transform: "scale(1.1)",
                      transition: "transform 0.5s ease",
                    },
                  }}
                />
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}
export default InternalLanding;
