import React, { useEffect, useState } from 'react';
import styles from './MilestonePage.module.scss';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Box, Checkbox, Chip, FormControl, ListItemText, MenuItem, OutlinedInput, Select, SelectChangeEvent, Skeleton, TableCell, TableRow, TextField } from '@mui/material';
import CancelIcon from "@mui/icons-material/Cancel";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import SaveIcon from '@mui/icons-material/Save';
import RestoreIcon from '@mui/icons-material/Restore';
import CloseIcon from '@mui/icons-material/Close';
import moment, { duration } from 'moment';
import { MileStone } from '../../Redux/Services/milestone.service';
import { debounce } from 'lodash';
import { ADMIN } from '../../Redux/Services/admin.service';

interface MilestoneRowsI {
  products: any;
  item: any;
  index: any;
  getDetailsUpdate: any;
  setIdUpdated: any;
  idUpdated: any;
  allProductCheckbox?:boolean;
}

const deptColor = [
  {
    module: "BOM",
    color: "#ecdaea"
  },
  
  {
    module: "PM",
    color: "#bdd7ee"
  },
  {
    module: "Ideas",
    color: "#c6e0b4"
  },
  {
    module: "Costing",
    color: "#f6cbad"
  },
  {
    module: "MAF",
    color: "lightgrey"
  },
  {
    module: "CAD",
    color: "#ecdaea"
  },
  {
    module: "Finance",
    color: "#ADD8E6"
  },
  {
    module: "Finance",
    color: "#c0aee0"
  },
  {
      module: 'Workshop',
      color:'#F5E2E1'
  },
  {
    module:"Product Inward",
    color: "#9EF1A8"
   
  },
  {
    module:"Product Outward",
    color: "#588bf057"
  }
  
 
];

const MilestoneRows: React.FC<MilestoneRowsI> = (props) => {
  const { products, item, index, getDetailsUpdate, setIdUpdated, idUpdated,allProductCheckbox } = props;
  const [productList, setProductList] = useState<any>(products && products[item?.id]?.filter((itemData: any) => item?.top_vault?.includes(itemData?.id))?.map((item: any) => { return item?.name }));
  const [selectStartDateRange, setSelectStartDateRange] = useState<any>(item?.start_date);
  const [selectEndDateRange, setSelectEndDateRange] = useState<any>();
  const [selectStatus, setSelectStatus] = useState<any>(item?.current_status);
  const [selectDuration, setSelectDuration] = useState<any>(item?.duration);
  const [disableRow, setDisableRow] = useState<any>(item?.is_active);
  const [changesSave, setChangesSave] = useState<boolean>(false);
  const [selectActivityType, setSelectActivityType] = useState<any>(item?.activity_type);
  let {url} = useRouteMatch();

  const handleSelectChangeModule = (event: SelectChangeEvent<any>) => {
    setChangesSave(true);
    setProductList(event.target.value)
  };

  function arrayEquals(a:any, b:any) {
    return Array.isArray(a) &&
        Array.isArray(b) &&
        a?.length === b?.length &&
        a?.every((val, index) => val === b[index]);
}

  useEffect(() => {
    setProductList(undefined);
    setSelectStartDateRange(undefined);
    setSelectStatus(undefined);
    setDisableRow(undefined);
    setSelectDuration(undefined);
    setSelectActivityType(undefined);
    setSelectEndDateRange(undefined);
    setProductList(item && item?.is_active && allProductCheckbox ? products && products[item?.id]?.map((item:any)=> item?.name) : products && products[item?.id]?.filter((itemData: any) => item?.top_vault?.includes(itemData?.id))?.map((item: any) => { return item?.name }));
    setSelectStartDateRange(item?.start_date);
    setSelectStatus(item?.current_status)
    setDisableRow(item?.is_active)
    setSelectDuration(item?.duration)
    setSelectActivityType(item?.activity_type)

    let result = new Date(moment(item?.start_date).format());
    const newDate = addDays(result, item?.duration);
    setSelectEndDateRange(newDate);

    if(item?.is_active && allProductCheckbox){
      !arrayEquals(products && products[item?.id]?.map((item:any)=> item?.name),products && products[item?.id]?.filter((itemData: any) => item?.top_vault?.includes(itemData?.id))?.map((item: any) => { return item?.name })) && setChangesSave(true);
    }
  }, [item,allProductCheckbox,products])

  const ITEM_HEIGHT = 48;

  const ITEM_PADDING_TOP = 8;

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  function addDays(date: any, days: any) {
    const dateval = date;
    dateval.setDate(dateval.getDate() + Number(days));
    return dateval;
  }

  const handleAddDuration: any = (e: any) => {
    setChangesSave(true)
    setSelectDuration(Number(e.target.value));
    let result = new Date(moment(selectStartDateRange && selectStartDateRange).format());
    const newDate = addDays(result, e.target.value);
    setSelectEndDateRange(newDate);
  }




  const handleChangeStartDate: any = (newValue: any,index:any,id:any) => {
   
    setChangesSave(true)
    setSelectStartDateRange(newValue);
    setSelectDuration(selectDuration);
    let result = new Date(moment(newValue && newValue).format());
    const newDate = addDays(result, selectDuration);
    setSelectEndDateRange(newDate);
    if (selectActivityType!='' && productList?.length>0 ){
      MileStone.updateMileStoneDates(id, products && products[item?.id]?.filter((itemData: any) => productList?.includes(itemData?.name))?.map((item: any) => item?.id), moment(newValue).format('YYYY-MM-DD'), selectDuration,item?.work_type == "OFF" &&  new Date(newValue) > new Date() ? "Not Started" : selectStatus, moment(newDate).format('YYYY-MM-DD'), selectActivityType)
      .then((res: any) => {
        ADMIN.toast.success("Updated Successfully")
        setChangesSave(false)
        // setIdUpdated(id);
        setProductList(undefined);
        setSelectStartDateRange(undefined);
        setSelectStatus(undefined);
        setDisableRow(undefined);
        setSelectDuration(undefined);
        setSelectActivityType(undefined);
        setSelectEndDateRange(undefined);
        setProductList(res?.data && res?.data?.is_active && allProductCheckbox ? products && products[res?.data?.id]?.map((item:any)=> item?.name) : products && products[res?.data?.id]?.filter((itemData: any) => res?.data?.top_vault?.includes(itemData?.id))?.map((item: any) => { return item?.name }));
        setSelectStartDateRange(res?.data?.start_date);
        setSelectStatus(res?.data?.current_status)
        setDisableRow(res?.data?.is_active)
        setSelectDuration(res?.data?.duration)
        setSelectActivityType(res?.data?.activity_type)
    
        let result = new Date(moment(res?.data?.start_date).format());
        const newDate = addDays(result, res?.data?.duration);
        setSelectEndDateRange(newDate);
    
        if(res?.data?.is_active && allProductCheckbox){
          !arrayEquals(products && products[res?.data?.id]?.map((item:any)=> item?.name),products && products[res?.data?.id]?.filter((itemData: any) => res?.data?.top_vault?.includes(itemData?.id))?.map((item: any) => { return item?.name })) && setChangesSave(true);
        }
        // getDetailsUpdate();
    
      })
      .catch((error: any) =>  ADMIN.toast.error("Server Error"));
    }
  
      
  }

  const handleChangeActivityType: any = (e: any) => {
    setChangesSave(true)
    setSelectActivityType(e.target.value);
  }

  const handleChange = (event: SelectChangeEvent) => {
    setChangesSave(true)
    setSelectStatus(event.target.value as string);
  };


  const handleStatus: any = (activeStatus: any, id: any) => {
    MileStone.updateStatus(activeStatus, id)
      .then((res: any) => {
        setIdUpdated(id);
        getDetailsUpdate();
      })
      .catch((error: any) => {
        console.log(error, "Server Error");
      });
  };

  const handleUpdateChanges = (index: any, id: any) => {
    if (changesSave) {
      MileStone.updateMileStoneDates(id, products && products[item?.id]?.filter((itemData: any) => productList?.includes(itemData?.name))?.map((item: any) => item?.id), moment(selectStartDateRange).format('YYYY-MM-DD'), selectDuration,item?.work_type == "OFF" &&  new Date(selectStartDateRange) > new Date() ? "Not Started" : selectStatus, moment(selectEndDateRange).format('YYYY-MM-DD'), selectActivityType)
        .then((res: any) => {


            console.log(res.data,"cdbchdbjchbjdcbdjbjh")
            ADMIN.toast.success("Updated Successfully")

          setChangesSave(false)
          // setIdUpdated(id);
          setProductList(undefined);
          setSelectStartDateRange(undefined);
          setSelectStatus(undefined);
          setDisableRow(undefined);
          setSelectDuration(undefined);
          setSelectActivityType(undefined);
          setSelectEndDateRange(undefined);
          setProductList(res?.data && res?.data?.is_active && allProductCheckbox ? products && products[res?.data?.id]?.map((item:any)=> item?.name) : products && products[res?.data?.id]?.filter((itemData: any) => res?.data?.top_vault?.includes(itemData?.id))?.map((item: any) => { return item?.name }));
          setSelectStartDateRange(res?.data?.start_date);
          setSelectStatus(res?.data?.current_status)
          setDisableRow(res?.data?.is_active)
          setSelectDuration(res?.data?.duration)
          setSelectActivityType(res?.data?.activity_type)
      
          let result = new Date(moment(res?.data?.start_date).format());
          const newDate = addDays(result, res?.data?.duration);
          setSelectEndDateRange(newDate);
      
          if(res?.data?.is_active && allProductCheckbox){
            !arrayEquals(products && products[res?.data?.id]?.map((item:any)=> item?.name),products && products[res?.data?.id]?.filter((itemData: any) => res?.data?.top_vault?.includes(itemData?.id))?.map((item: any) => { return item?.name })) && setChangesSave(true);
          }
          // getDetailsUpdate();
        })
        .catch((error: any) => ADMIN.toast.error("Server Error"));
    }
  }
 
return(
  <>
    <TableRow style={!disableRow?{} :{backgroundColor :`${deptColor?.filter((itemColor:any)=>{return itemColor?.module==item?.dept})?.[0]?.color}`}}>
        {idUpdated != item?.id ? <TableCell sx={disableRow ? { padding: "0 0.6rem", fontSize: '1rem', borderBottomColor: 'primary.light' } : {padding: "0 0.6rem", fontSize: '1rem', borderBottomColor: 'primary.light', color:'#808080a1'}}>{item?.sequence}</TableCell>:<TableCell sx={{padding:'0.5rem 0.5rem'}}><Skeleton sx={{ height: "1.5rem", width: "100%" }} /></TableCell>}
        {idUpdated != item?.id ? <TableCell sx={disableRow ? { padding: "0 0.6rem", fontSize: '1rem', borderBottomColor: 'primary.light' } : {padding: "0 0.6rem", fontSize: '1rem', borderBottomColor: 'primary.light', color:'#808080a1'}}>{item?.group}</TableCell>:<TableCell sx={{padding:'0.5rem 0.5rem'}}><Skeleton sx={{ height: "1.5rem", width: "100%" }} /></TableCell>}
        {url?.includes('Milestone') ? idUpdated != item?.id ? <TableCell sx={disableRow ? { padding: "0 0.6rem", fontSize: '1rem', borderBottomColor: 'primary.light'} : { padding: "0 0.6rem", fontSize: '1rem', borderBottomColor: 'primary.light', color:'#808080a1'}}>{item?.dept}</TableCell>:<TableCell sx={{padding:'0.5rem 0.5rem'}}><Skeleton sx={{ height: "1.5rem", width: "100%" }} /></TableCell>:<></>}
        {idUpdated != item?.id ? <TableCell sx={disableRow ? { padding: "0 0.6rem", fontSize: '1rem', borderBottomColor: 'primary.light'} : { padding: "0 0.6rem", fontSize: '1rem', borderBottomColor: 'primary.light', color:'#808080a1'}}>{item?.activity}</TableCell>:<TableCell sx={{padding:'0.5rem 0.5rem'}}><Skeleton sx={{ height: "1.5rem", width: "100%" }} /></TableCell>}
        {idUpdated != item?.id ? <TableCell sx={{ padding: "0 0.6rem", fontSize: '1rem', borderBottomColor: 'primary.light' }}>
          <FormControl variant="standard" sx={{ minWidth: { lg: '180px', xl: '300px' }, maxWidth: { lg: '180px', xl: '300px' } }}>
            {productList && <Select labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={productList && productList}
              multiple
              disabled={!disableRow}
              onChange={handleSelectChangeModule}
              onBlur={() => !(selectStartDateRange!=null && selectActivityType!='' && productList?.length>0 && typeof selectDuration == 'number') ? console.log("start date or activity type or products not selected") : handleUpdateChanges(index, item?.id)}
              placeholder="Select Products"
              sx={disableRow ? { fontSize: '1rem', borderBottom: "1px solid",'.MuiSvgIcon-root.MuiSelect-icon':{color:'primary.main'}, borderBottomColor: "primary.main", "& .MuiFormLabel-root.Mui-focused": { color: "primary.main", }, "&:before": { borderBottomColor: "white", }, } : { fontSize: '1rem', borderBottom: "1px solid", "& .MuiFormLabel-root.Mui-focused": { color: "primary.main", }, "&:before": { borderBottomColor: "white", }, }}
              renderValue={(selected) => selected.join(', ')}
              MenuProps={MenuProps}>
              {products && products[item?.id]?.map((name: any) => {
                return (
                  <MenuItem sx={{ padding: '0 0.5rem' }} key={name?.id} value={name?.name}>
                    <Checkbox style={{ padding: '6px' }} checked={productList?.indexOf(name?.name) > -1} />
                    <ListItemText sx={{ fontSize: '1rem' }} primary={name?.name} />
                  </MenuItem> 
                )
              })}
            </Select>}
          </FormControl>
        </TableCell> : <TableCell sx={{ padding: '0.5rem 0.5rem' }}><Skeleton sx={{ height: "1.5rem", width: "100%" }} /></TableCell>}
        {idUpdated != item?.id ? <TableCell sx={{ padding: "0 0.6rem", fontSize: '1rem', borderBottomColor: 'primary.light' }}>
          <LocalizationProvider variant="standard" dateAdapter={AdapterMoment}>
            <DesktopDatePicker
              value={selectStartDateRange}
              inputFormat="DD/MM/YYYY"
              disabled={!disableRow}
              
              onChange={(newValue) => {return  handleChangeStartDate(newValue,index,item?.id) }}
               // @ts-ignore
          
              

              renderInput={(params) =>(
                 <TextField 
               
                //  onBlur={() => !(selectStartDateRange!=null && selectActivityType!='' && productList?.length>0 && typeof selectDuration == 'number') ? console.log("start date or activity type or products not selected") : handleUpdateChanges(index, item?.id)}
                {...params} variant="standard" sx={disableRow ? {
                width: "100%",

                "& .MuiFormLabel-root": {
                  color: 'primary.main'
                },
                "& .MuiFormLabel-root.Mui-focused": {
                  color: 'primary.main'
                },
                "& .MuiSvgIcon-root": {
                  color: 'primary.main',
                },
                ".MuiInputBase-input.MuiInput-input": {
                  fontSize: '1rem'
                },
                "& .Mui-error": {
                  color: '#d32f2f',
                  borderBottomColor: '#d32f2f',
                },
                '& .MuiButtonBase-root.MuiIconButton-root': {
                  padding: '2px',
                  marginRight:'5px'
                },
                '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                '& .MuiInput-underline:after': { borderBottomColor: 'primary.light' },
                '& .MuiInput-underline': { fontSize: '1rem' },
              } : {'& .MuiButtonBase-root.MuiIconButton-root': {
                padding: '2px',
                marginRight:'5px',
              }, width: "100%", }} />)}
            />
          </LocalizationProvider>
        </TableCell> : <TableCell sx={{ padding: '0.5rem 0.5rem' }}><Skeleton sx={{ height: "1.5rem", width: "100%" }} /></TableCell>}
        {idUpdated != item?.id ? <TableCell sx={{ padding: "0 0.6rem", fontSize: '1rem', borderBottomColor: 'primary.light' }}>
          <TextField
            type="number"
            variant='standard'
            value={selectDuration}
            onChange={handleAddDuration}
            onBlur={() => !(selectStartDateRange!=null && selectActivityType!='' && productList?.length>0 && typeof selectDuration == 'number') ? console.log("start date or activity type or products not selected") : handleUpdateChanges(index, item?.id)}
            disabled={!disableRow}
            InputProps={{
              inputProps: {
                min: 0
              }
            }}
            sx={{ width: "100%", borderBottomColor: 'primary.light', "& .MuiOutlinedInput-root": { fontSize: '1rem', color: 'primary.main' }, "& .MuiOutlinedInput-input": { fontSize: '1rem', color: 'primary.main', padding: '0.5rem' } }}
          />
        </TableCell> : <TableCell sx={{ padding: '0.5rem 0.5rem' }}><Skeleton sx={{ height: "1.5rem", width: "100%" }} /></TableCell>}
        {url?.includes('Milestone') ? idUpdated != item?.id ? <TableCell sx={disableRow ? { padding: "0 0.6rem", fontSize: '1rem', borderBottomColor: 'primary.light' } : { padding: "0 0.6rem", fontSize: '1rem', borderBottomColor: 'primary.light', color: '#808080a1' }}>{selectEndDateRange && selectEndDateRange != 'Invalid Date' ? moment(selectEndDateRange).format('DD/MM/YYYY') : ""}</TableCell> : <TableCell sx={{ padding: '0.5rem 0.5rem' }}><Skeleton sx={{ height: "1.5rem", width: "100%" }} /></TableCell>:<></>}
        {idUpdated != item?.id ? <TableCell sx={disableRow ? { padding: "0 0.6rem", fontSize: '1rem', borderBottomColor: 'primary.light' } : { padding: "0 0.6rem", fontSize: '1rem', borderBottomColor: 'primary.light', color: '#808080a1' }}>
          {typeof selectActivityType == 'string' && <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            variant='standard'
            disabled={!disableRow}
            value={selectActivityType || ""}
            onChange={handleChangeActivityType}
            onBlur={() => !(selectStartDateRange!=null && selectActivityType!='' && productList?.length>0 && typeof selectDuration == 'number') ? console.log("start date or activity type or products not selected") : handleUpdateChanges(index, item?.id)}
            sx={{ 	borderBottom:'0.5px solid',
            // borderBottomColor:'primary.main',                 
            "& .MuiFormLabel-root.Mui-focused": {
              color: "primary.main",
            },
            '.MuiSvgIcon-root.MuiSelect-icon':{color:!disableRow ?'' :'primary.main'},
            "&:before": {
              borderBottomColor: "white",
            },fontSize: '1rem', '& .MuiSelect-select': { padding: '0.5rem', fontSize: '1rem' }, width: '100%', }}
          >
            <MenuItem value={"Internal"}>Internal</MenuItem>
            <MenuItem value={"External"}>External</MenuItem>
          </Select>}
        </TableCell> : <TableCell sx={{ padding: '0.5rem 0.5rem' }}><Skeleton sx={{ height: "1.5rem", width: "100%" }} /></TableCell>}
        {idUpdated != item?.id ? <TableCell sx={{ padding: "0 0.6rem", fontSize: '1rem', borderBottomColor: 'primary.light' }}>
          {
            item?.work_type == "OFF" ?
            selectStartDateRange!=null && new Date(selectStartDateRange) > new Date() ?
            selectStatus && <span style={disableRow ?{ color: 'red', padding: '0.5rem'}:{ color: '#808080a1', padding: '0.5rem' }}>NOT STARTED</span>
            : selectStatus && <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                variant='standard'
                disabled={!disableRow}
                value={selectStatus}
              
                onBlur={() => !(selectStartDateRange!=null && selectActivityType!='' && productList?.length>0 && typeof selectDuration == 'number') ? console.log("start date or activity type or products not selected") : handleUpdateChanges(index, item?.id)}
                onChange={handleChange}
                sx={selectStatus == "Completed" ? { 	borderBottom:'0.5px solid',
                borderBottomColor:'primary.main',                 
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "&:before": {
                  borderBottomColor: "white",
                },fontSize: '1rem', color: 'green','.MuiSvgIcon-root.MuiSelect-icon':{color:'primary.main'},'& .MuiSelect-select': { padding: '0.5rem', fontSize: '1rem' }, width: '90%' } 
                : selectStatus == "Not Started" ? { 	borderBottom:'0.5px solid',
                // borderBottomColor:'primary.main',                 
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "&:before": {
                  borderBottomColor: "white",
                },color: 'red', '& .MuiSelect-select': { padding: '0.5rem', fontSize: '1rem' }, width: '90%' } : { 	borderBottom:'0.5px solid',
                // borderBottomColor:'primary.main',                 
                "& .MuiFormLabel-root.Mui-focused": {
                  color: "primary.main",
                },
                "&:before": {
                  borderBottomColor: "white",
                },fontSize: '1rem', color: 'black','.MuiSvgIcon-root.MuiSelect-icon':{color:disableRow?'primary.main':''}, '& .MuiSelect-select': { padding: '0.5rem', fontSize: '1rem' }, width: '90%' }}
              >
                <MenuItem style={{ color: 'green' }} value={"Completed"}>COMPLETED</MenuItem>
                <MenuItem style={{ color: 'red' }} value={"Not Started"}>NOT STARTED</MenuItem>
                <MenuItem style={{ color: 'black' }} value={"Work In Progress"}>WORK IN PROGRESS</MenuItem>
              </Select>
              :
              <span style={disableRow ? item?.current_status == "Completed" ? { color: 'green', padding: '0.5rem' } : item?.current_status == "Not Started" ? { color: 'red', padding: '0.5rem' } : { color: 'black', padding: '0.5rem' } : { color: '#808080a1', padding: '0.5rem' }}>{item?.current_status?.toUpperCase()}</span>
          }
        </TableCell> : <TableCell sx={{ padding: '0.5rem 0.5rem' }}><Skeleton sx={{ height: "1.5rem", width: "100%" }} /></TableCell>}

        {idUpdated != item?.id ? <TableCell sx={disableRow ? { padding: "0 0.6rem", fontSize: '1rem', borderBottomColor: 'primary.light'} : { padding: "0 0.6rem", fontSize: '1rem', borderBottomColor: 'primary.light', color:'#808080a1'}}>{item?.work_type}</TableCell>:<TableCell sx={{padding:'0.5rem 0.5rem'}}><Skeleton sx={{ height: "1.5rem", width: "100%" }} /></TableCell>}

        {idUpdated != item?.id ? <TableCell sx={{ padding: "0 0.6rem", fontSize: '1rem', borderBottomColor: 'primary.light', textAlign: 'center' }}>
          {/* {disableRow && <SaveIcon sx={!(selectStartDateRange!=null && selectActivityType!='' && productList?.length>0 && typeof selectDuration == 'number') ? { cursor: 'not-allowed', color: '#808080a1' } : changesSave ? { cursor: 'pointer', color: 'red' } : { cursor: 'pointer' }}
           onClick={() => !(selectStartDateRange!=null && selectActivityType!='' && productList?.length>0 && typeof selectDuration == 'number') ? console.log("start date or activity type or products not selected") : handleUpdateChanges(index, item?.id)} />
          
          
          
          } */}
          {!disableRow && <RestoreIcon sx={{ cursor: 'pointer' }} onClick={() => handleStatus(true, item.id)} />}
          {disableRow && <CloseIcon sx={{ color: 'red', cursor: 'pointer' }} onClick={() => handleStatus(false, item.id)} />}
        </TableCell> : <TableCell sx={{ padding: '0.5rem 0.5rem' }}><Skeleton sx={{ height: "1.5rem", width: "100%" }} /></TableCell>}
      </TableRow>
    </>
  )
}

export default MilestoneRows;