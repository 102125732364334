import * as React from 'react';
import { Box, Table, TableBody, TableCell, TableHead, TableRow, Typography, } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import styles from './MediaCreateBody.module.scss';
import { useHistory } from 'react-router-dom';
import { useRouteParams } from '../../ui-reusable-component/useRouteParams';
import { MediaProductandSystemTreeDetails } from './MediaProductandSystemTreeDetails';
import { MediaCreateImageSection } from './MediaCreateImageSection';
import BarChartIcon from '@mui/icons-material/BarChart';
import ArrowCircleLeftIcon from '@mui/icons-material/ArrowCircleLeft';
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import CancelIcon from '@mui/icons-material/Cancel';
import { AxiosError, AxiosResponse } from 'axios';
import { API } from '../../api-services';
import { MODULES } from "../../Constants/modules.constants";


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export interface IMediaCreateBodyProps {
}

export function MediaCreateBody(props: IMediaCreateBodyProps) {
  const { projectId, projectName, category, mediaType, abbreviation } = useRouteParams<any>();
  const history = useHistory();
  const [selectedType, setSelectedType] = React.useState<any>(1)
  const [openCategoryInfo, setOpenCategoryInfo] = React.useState(false);
  const [categoryInfo, setCategoryInfo] = React.useState<any>([]);

  const [isActive, setIsActive] = React.useState(true);
  const handleClick = () => {
    // 👇️ toggle
    setIsActive((current) => !current);

    // 👇️ or set to true
    // setIsActive(true);
  };

  const [systemMediaInfo, setSystemMediaInfo] = React.useState<any>([]);
  const [openSystemMediaInfo, setOpenSystemMediaInfo] = React.useState(false);
  const [groupContextAbbreviation, setGroupContextAbbreviation] = React.useState();

  const handleFeatureTypeChange = (types: any) => {
    history.push(`/mediacreateinternal/${projectId}/${projectName}/${mediaType}/0/${types}/0/0/0/true`);
    setSelectedType(types);
  }

  const handleOpenCategoryInfo = () => {
    setOpenCategoryInfo(false);
  }

  const handleInfoCategory = (e: any, category: any) => {
    setCategoryInfo(undefined);
    e.stopPropagation();
    API.get(`/image_repository/media_module/`, { project: projectId, category_level_info: true, image_category: category, media_type: mediaType }, 0).then((res: AxiosResponse) => {
      setCategoryInfo(res?.data);
      setOpenCategoryInfo(true);
    }).catch((err: AxiosError) => {
      console.log(err, "error")
    })
  }

  const handleCloseSystemMediaInfo = () => {
    setOpenSystemMediaInfo(false);
  };

  const handleSystemMediaInfo = () => {
    setSystemMediaInfo([]);
    API.get('/image_repository/media_module/', {
      project: projectId,
      module: MODULES.IMAGE_REPOSITORY,
      subsystem_statistics: true,
      media_type: mediaType,
      image_category: category == '1' || category == '2' ? groupContextAbbreviation == '0' ? '1' : '2' : category
    }, 0).then((res: AxiosResponse) => {
      setSystemMediaInfo(res?.data);
      setOpenSystemMediaInfo(true);
    }).catch((err: AxiosError) => {
      console.log(err, "error console");
    });
  }

  return (
    <div>
      <Dialog
        open={openCategoryInfo}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleOpenCategoryInfo}
        sx={{
          ".MuiPaper-root.MuiDialog-paper":
            { borderRadius: '10px' }
        }}
      >
        <DialogContent sx={{ width: '30vw', padding: '0rem' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: '-2rem'
              }}
            >
              Media Category Information
            </Typography>
            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={handleOpenCategoryInfo} />
          </Box>
          <Box sx={{ margin: '0.5rem', color: 'grey' }}>
            <Table>
              <TableHead sx={{ backgroundColor: 'primary.light' }}>
                <TableRow>
                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', textTransform: 'capitalize' }}>Total {mediaType} count</TableCell>
                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Non-validated Rows</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow>
                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>{categoryInfo && categoryInfo[0]?.image_count}</TableCell>
                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>{categoryInfo && categoryInfo[0]?.not_validated_image_row}</TableCell>
                </TableRow>
              </TableBody>
            </Table>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openSystemMediaInfo}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseSystemMediaInfo}
        sx={{
          ".MuiPaper-root.MuiDialog-paper":
            { borderRadius: '10px' }
        }}
      >
        <DialogContent sx={{ width: "42rem", padding: "0rem" }}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              System-wise & Product-wise statistics table
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={handleCloseSystemMediaInfo}
            />
          </Box>
          <Box sx={{ margin: "0.5rem", }}>
            <Table>
              <TableHead sx={{ backgroundColor: 'primary.light' }}>
                <TableRow>
                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Name</TableCell>
                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Row Count</TableCell>
                  <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Validated Row Count</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {systemMediaInfo?.map((item: any) => {
                  return (
                    <TableRow>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', textTransform: 'capitalize' }}>{item?.name}</TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>{item?.total_count}</TableCell>
                      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>{item?.total_validated_count}</TableCell>
                    </TableRow>)
                })}
              </TableBody>
            </Table>
          </Box>
        </DialogContent>
      </Dialog>

      <Box sx={{
        display: "flex",
        flexDirection: 'row',
        alignItems: "flex-start",
        width: { xs: '90rem', sm: '100%' },
        height: { xs: '86vh', sm: '86vh', md: '86vh', lg: '85vh', xl: '89vh' },
        boxShadow: 'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px',
        borderRadius: '10px',
        overflow: 'visible',
      }}
        adl-scrollbar="true"
        adl-scrollbar-width="0.3">
        <Box className={isActive ? styles.slideactive : styles.slideinactive}>
          <Box className={styles.tabBox}>
            <Box className={category == "1" || category == "2" ? styles.MediatabActive : styles.MediatabInactive} onClick={() => handleFeatureTypeChange(1)}>Pre TD <InfoIcon sx={{ cursor: 'pointer' }} onClick={(e: any) => { return handleInfoCategory(e, '1,2') }} /></Box>
            <Box className={category == "3" ? styles.MediatabActive : styles.MediatabInactive} onClick={() => handleFeatureTypeChange(3)}>Post TD <InfoIcon sx={{ cursor: 'pointer' }} onClick={(e: any) => { return handleInfoCategory(e, '3') }} /> </Box>
            <Box className={category == "5" ? styles.MediatabActive : styles.MediatabInactive} onClick={() => handleFeatureTypeChange(5)}>Other <InfoIcon sx={{ cursor: 'pointer' }} onClick={(e: any) => { return handleInfoCategory(e, '5') }} /> </Box>
          </Box>
          <Box>
            <MediaProductandSystemTreeDetails selectedType={selectedType} />
          </Box>
          <Box className={styles.Mediatreefoot} >
            <BarChartIcon titleAccess='Statistics Table' onClick={handleSystemMediaInfo} sx={{ cursor: 'pointer' }} />
            <Box className={styles.slideIcon} >
              <ArrowCircleLeftIcon titleAccess='Collapse Section' sx={{ cursor: "pointer" }}
                onClick={handleClick} />
            </Box>
          </Box>
          {isActive ? (
            ""
          ) : (
            <>
              <Box className={styles.slideIcon}>
                <ArrowCircleRightIcon
                  sx={{ marginLeft: "-2.5rem", cursor: "pointer", zIndex: 2 }}
                  onClick={handleClick}
                />
              </Box>
            </>
          )}
        </Box>
        <Box sx={{ width: '100%' }}>
          <MediaCreateImageSection />
        </Box>
      </Box>
    </div>
  );
}
