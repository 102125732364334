
import { createEntityAdapter, createReducer, createSelector, EntityState } from "@reduxjs/toolkit";

// redux

import { ICalculator } from "./calculator.reducer";

import { RootState } from "../Store/storeConfigurations";
import { ID } from "../../utlis/commonInterfaces";
import { getUserInputs, getUserInputsTriangulation, resetUserInputs, updateUserInput, updateUserInputTriangulation } from "../Actions/IdeauserInput.actions";

// services


// interfaces


// -----------------------------------------------------------------------------------
// entity adaptor

/** data entered by user for each calculator for a specific cardCost */
export interface UserInput {
    id: ID;

    calculator: ICalculator;
    is_used:any;
    user_input: { [inputId: string]: number; };
    constant_input: { [inputId: string]: number|any; };
    constant_message: { [inputId: string]: number|any; };
    alias: string;
    result: any;
}

const collator = new Intl.Collator( undefined, { numeric: true, sensitivity: "base" } );

const adaptor = createEntityAdapter<UserInput>( {
    selectId: ( userInput ) => userInput.id,
    sortComparer: ( a, b ) => collator.compare( a.calculator.name, b.calculator.name )
} );

// -----------------------------------------------------------------------------------
// interfaces & initialState

type AdditionalFields = {};

type IUserInputReducer = EntityState<UserInput> & AdditionalFields;

const additionalFields: AdditionalFields = {};

const initialState: IUserInputReducer = Object.assign( {}, adaptor.getInitialState(), additionalFields );

// -----------------------------------------------------------------------------------
// reducer

const IdeaUserInputReducer = createReducer( initialState, builder => {
    // get
    builder
        .addCase( getUserInputs.fulfilled, ( state, action: any ) => {
            adaptor.setAll( state, action.payload.inputs );

       
        } )
        .addCase( getUserInputs.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could not fetch user inputs" );
        } );

    // update
    builder
        .addCase( updateUserInput.fulfilled, ( state, action: any ) => {
            adaptor.upsertOne( state, action.payload.input );

            // ADMIN.toast.success( "Applied" );
        } )
        .addCase( updateUserInput.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could not apply changes" );
        } );

    //getUserInputsTriangulation
    builder
        .addCase( getUserInputsTriangulation.fulfilled, ( state, action: any ) => {
            adaptor.setAll( state, action.payload.inputs );
        } )
        .addCase( getUserInputsTriangulation.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could not fetch user inputs" );
        } );

    //updateUserInputTriangulation
    builder
        .addCase( updateUserInputTriangulation.fulfilled, ( state, action: any ) => {
            adaptor.upsertOne( state, action.payload.input );

            // ADMIN.toast.success( "Calculator Value Updated" );
        } )
        .addCase( updateUserInputTriangulation.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could not update value" );
        } );


    // reset
    builder.addCase( resetUserInputs, () => initialState );
} );

// -----------------------------------------------------------------------------------
// selectors

const selectors = adaptor.getSelectors<RootState>( ( state ) => state.IdeauserInputs );

const selectCalculators = createSelector(
    selectors.selectAll,
    ( IdeauserInputs ) => {
        return IdeauserInputs.map( input => input.calculator );
    }
);

const selectCalculatorByCategory = createSelector(
    selectCalculators,
    ( _state: RootState, category: number ) => category,
    ( calculators, category ) => {
        return calculators.filter( calculator => calculator.category === category );
    }
);

const selectByCalculatorId = createSelector(
    selectors.selectAll,
    ( _state: RootState, calculatorId: ID ) => calculatorId,
    ( IdeauserInputs, calculatorId ) => {
        return IdeauserInputs.find( userInput => userInput.calculator.id === calculatorId );
    }
);

const IdeauserInputSelectors = Object.assign( {}, {
    selectCalculators,
    selectByCalculatorId,
    selectCalculatorByCategory
}, selectors );

// -----------------------------------------------------------------------------------
// exports

export {
    IdeaUserInputReducer,
    initialState as IdeaUserInputReducerInit,
    IdeauserInputSelectors
};