// -------------------------------------------------------------------------------------

import {
  CreateOverheadCardCostsArg,
  DeleteOverheadCardCost,
  GetOverheadCardCostsArg,
  UpdateOverheadCardCostArg,
} from "../Actions/overheadCard.action";
import { API } from "./index.service";

const url = "cost/overhead/";

// -------------------------------------------------------------------------------------

export default {
  get(arg: GetOverheadCardCostsArg) {
    return API.get(
      url,
      {
        cost_estimation: arg.costEstimationId,
        id:arg.tableId
      },
      20000
    );
  },
  create(arg: CreateOverheadCardCostsArg) {
    return API.post(url, {
      cost_estimation: arg.costEstimationId,
      alias: arg.alias,
    });
  },
  update(arg: UpdateOverheadCardCostArg) {
    return API.put(url + arg.id + "/", arg.updates);
  },
  delete(arg: DeleteOverheadCardCost) {
    return API.delete(url + arg.id + "/");
  },
};
