import React, { useEffect, useState } from "react";
import { Box, Modal, Tooltip, Typography } from "@mui/material";
import CancelIcon from "@mui/icons-material/Cancel";
import Divider from "@mui/material/Divider";
import styles from "../IdeaDashboard/IdeaShowModal/IdeaShowModal.module.scss";
import useWindowDimensions from "../../../ui-reusable-component/useWindowDimensions";
import { API } from "../../../api-services";
import { ButtonProps } from "@mui/material/Button";
import RViewerJS from "viewerjs-react";

import {
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  DataGrid,
} from "@mui/x-data-grid";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { LoadingButton } from "@mui/lab";
import DownloadIcon from "@mui/icons-material/Download";

interface WorkshopIdeasI {
  onClose: () => void;
  isOpen: boolean;
  //   bubblePoints: any;
  //   scatterIdeaData: any;
}

const csvOptions: GridCsvExportOptions = {
  fileName: "Costing Subsystem-wise Summary In CSV",
  delimiter: ",",
  utf8WithBom: true,
  allColumns: true,
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer style={{ height: "4rem" }}>
    <span
      style={{
        position: "absolute",
        right: "0rem",
        fontSize: "1rem",
        paddingRight: "1rem",
      }}
    >
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);

const WorkshopIdeas: React.FC<WorkshopIdeasI> = (props) => {
  const { projectId } = useRouteParams<any>();
  const { height, width } = useWindowDimensions();
  const { isOpen, onClose } = props;
  const [workshopIdeaDetailsLoader, setWorkshopIdeaDetailsLoader] =
    useState<boolean>(false);
  const [wiLoader, setWiLoader] = React.useState<boolean>(false);
  const [woiLoader, setWoiLoader] = React.useState<boolean>(false);
  const [workshopIdeaDetails, setWorkshopIdeaDetails] = useState<any>();

  const getIdeaGeneration = () => {
    setWorkshopIdeaDetailsLoader(true);
    API.get(
      `/web_companion/comapanion_customer_idea_generation/`,
      { project: projectId },
      0
    )
      .then((response) => {
        // console.log(response);
        setWorkshopIdeaDetails(response?.data);
        setWorkshopIdeaDetailsLoader(false);
      })
      .catch((error) => {
        console.log(error);
        setWorkshopIdeaDetailsLoader(false);
      });
  };

  useEffect(() => {
    getIdeaGeneration();
  }, []);

  let columns: any = [
    {
      field: "name",
      headerName: "Name",
      width: width > 1380 ? 140 : 120,
      // flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      width: width > 1380 ? 240 : 220,
      renderCell: (params: any) => {
        return (
          <Tooltip title={params?.row?.email}>
            <Typography>{params?.row?.email}</Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "phone",
      headerName: "Phone",
      width: width > 1380 ? 140 : 120,
    },
    {
      field: "part_name",
      headerName: "Part Name",
      width: width > 1380 ? 240 : 220,
      renderCell: (params: any) => {
        return (
          <Tooltip title={params?.row?.part_name}>
            <Typography>{params?.row?.part_name}</Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "part_no",
      headerName: "Part No",
      width: width > 1380 ? 140 : 120,
    },
    {
      field: "part_weight",
      headerName: "Part Weight",
      width: width > 1380 ? 140 : 120,
    },
    {
      field: "idea_type",
      headerName: "Idea Type",
      width: width > 1380 ? 180 : 160,
    },
    {
      field: "idea_description",
      headerName: "Idea Description",
      width: width > 1380 ? 240 : 220,
      renderCell: (params: any) => {
        return (
          <Tooltip title={params?.row?.idea_description}>
            <Typography>{params?.row?.idea_description}</Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "current_scenario",
      headerName: "Current Scenario",
      width: width > 1380 ? 140 : 120,
      renderCell: (params: any) => {
        return (
          <Tooltip title={params?.row?.current_scenario}>
            <Typography>{params?.row?.current_scenario}</Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "competitor_scenario",
      headerName: "Competitor Scenario",
      width: width > 1380 ? 140 : 120,
      renderCell: (params: any) => {
        return (
          <Tooltip title={params?.row?.competitor_scenario}>
            <Typography>{params?.row?.competitor_scenario}</Typography>
          </Tooltip>
        );
      },
    },
    {
      field: "current_scenario_image",
      headerName: "Current Scenario Image",
      width: width > 1380 ? 140 : 120,
      renderCell: (params: any) => {
        return (
          //@ts-ignore
          <RViewerJS>
            <img
              src={params?.row?.current_scenario_image}
              style={{
                width: "100%",
                objectFit: "contain",
                cursor: "pointer",
                // maxHeight: "21rem",
                // maxWidth: "78vw",
              }}
            />
          </RViewerJS>
        );
      },
    },
    {
      field: "competitor_scenario_image",
      headerName: "Competitor Scenario Image",
      width: width > 1380 ? 140 : 120,
      renderCell: (params: any) => {
        return (
          //@ts-ignore
          <RViewerJS>
            <img
              src={params?.row?.competitor_scenario_image}
              style={{
                width: "100%",
                objectFit: "contain",
                cursor: "pointer",
                // maxHeight: "21rem",
                // maxWidth: "78vw",
              }}
            />
          </RViewerJS>
        );
      },
    },
  ];

  let dataStructureDataGrid: any = {
    columns: columns,
    rows:
      workshopIdeaDetails && workshopIdeaDetails.length > 0
        ? workshopIdeaDetails
        : [],
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        className={styles.container}
        adl-scrollbar="true"
        adl-scrollbar-width="0.6"
        sx={{
          flexGrow: 1,
          bgcolor: "background.paper",
          height: "90vh",
          width: "90vw",
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", height: "3.5rem" }}>
          <Typography
            style={{
              fontSize: "1rem",
              fontWeight: "600",
              width: "100%",
              textAlign: "center",
              marginRight: "-2rem",
            }}
          >
            Workshop Generated Ideas{" "}
            {workshopIdeaDetails?.length > 0
              ? `(Total Count : ${workshopIdeaDetails?.length})`
              : ""}
          </Typography>
          <CancelIcon
            titleAccess="Close"
            sx={{ cursor: "pointer", margin: "0.5rem" }}
            onClick={onClose}
          />
        </Box>
        <Divider
          sx={{
            borderColor: "primary.light",
            margin: "0 1rem",
            marginBottom: "1rem",
          }}
        />
        {workshopIdeaDetails?.length>0 && <Box
          sx={{
            display: "flex",
            alignItems: "center",
            gap: "1.5rem",
            justifyContent: "flex-end",
            mb: 5,
          }}
        >
          <LoadingButton
            variant="contained"
            loading={wiLoader}
            onClick={() => {
              setWiLoader(true);
              API.get(
                "/web_companion/download_workshop_ideas/",
                { project: projectId, image: true },
                0
              )
                .then((res: any) => {
                  setWiLoader(false);
                  window.open(res.data);
                })
                .catch((err: any) => {
                  setWiLoader(false);
                });
            }}
            startIcon={<DownloadIcon />}
            sx={{
              backgroundColor: "#007fff",
              color: "white",
              padding: "4px 12px",
              fontWeight: "bold",
              "&:hover": { backgroundColor: "#007fff" },
              minWidth: "auto",
            }}
          >
            With Images
          </LoadingButton>
          <LoadingButton
            loading={woiLoader}
            onClick={() => {
              setWoiLoader(true);
              API.get(
                "/web_companion/download_workshop_ideas/",
                { project: projectId,image: false },
                0
              )
                .then((res: any) => {
                  setWoiLoader(false);
                  window.open(res.data);
                })
                .catch((err: any) => {
                  setWoiLoader(false);
                });
            }}
            variant="contained"
            startIcon={<DownloadIcon />}
            sx={{
              backgroundColor: "#007fff",
              color: "white",
              padding: "4px 12px",
              fontWeight: "bold",
              "&:hover": { backgroundColor: "#007fff" },
              minWidth: "auto",
            }}
          >
            W/O Images
          </LoadingButton>
        </Box>}
        <Box style={{ height: "78vh", width: "100%" }}>
          <DataGrid
            headerHeight={48}
            rowHeight={36}
            {...dataStructureDataGrid}
            loading={workshopIdeaDetailsLoader}
            disableSelectionOnClick={true}
            // components={{
            //   Toolbar: () => {
            //     return CustomToolbar();
            //   },
            // }}
            style={{ marginTop: "-2rem", marginRight: "0.5rem" }}
            sx={{
              "& ::-webkit-scrollbar": {
                width: "0.3rem",
                height: "0.3rem",
              },
              "& ::-webkit-scrollbar-thumb": {
                // background: $thumb-color;
                backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                borderRadius: "10px",
              },
              "& ::- webkit-scrollbar-thumb: hover ": {
                // width: "4px",
                backgroundColor: "#045DE9 !important",
              },
              "::-webkit-scrollbar-track": {
                backgroundColor: "#e1e1f3 !important",
              },
              "&.MuiDataGrid-root": {
                border: "none",
              },
              "&.MuiDataGrid-root .MuiDataGrid-cell": {
                borderBottomColor: "primary.light",
              },
              "& .even": {
                backgroundColor: "#ddefff !important",
                fontWeight: "450 !important",
              },
            }}
            density="compact"
            pageSize={100}
            rowsPerPageOptions={[100]}
            getRowClassName={"odd"}
            disableColumnMenu
            disableColumnFilter
            disableColumnSelector
          />
        </Box>
      </Box>
    </Modal>
  );
};
export default WorkshopIdeas;
