// react
import React from "react";

// vendors
import { useRouteMatch } from "react-router-dom";
import Routes, { RoutesProps } from "../../ui-reusable-component/Routes";
import styles from "./ActivityLogRouter.module.scss";
import LogsAnalysisPage from "./LogsAnalysisPage";
import StarRatingreview from "./StarRatingreview";
const ActivityLogAnalysisPage = React.lazy(()=>import("./ActivityLogLandingPage"));

// interfaces and types
// lazy

// ----------------------------------------------------------------------------------

interface ActivityLogRouterProps {}

const ActivityLogRouter: React.FC<ActivityLogRouterProps> = () => {
    const { path } = useRouteMatch();

    const routes: RoutesProps["routes"] = [
        {path: `${path}/:activityType/:userType`, exact: true, component: ActivityLogAnalysisPage},
        {path: `${path}/:activityType/:userType/analysis`, exact: true, component: LogsAnalysisPage},
        {path: `${path}/star-rating`, exact: true, component:StarRatingreview }
      
      
        
        
    ];
 
    return (
        <div className={styles.ActivityLogRouterModule}>
        <Routes routes={ routes } redirectTo={ `${path}` }  />
        </div>
    );
};

// ----------------------------------------------------------------------------------

export default ActivityLogRouter;
