
// import { ISellBomData } from "app/Redux/Reducers/Sell/sellBomData.reducer";
import { useState, useRef } from "react";

// ----------------------------------------------------------------------------------

function createContextFromEvent ( e: any ) {
    const left = e.clientX;
    const top = e.clientY;
    const right = left + 1;
    const bottom = top + 1;

    return {
        getBoundingClientRect: () => ( {
            left,
            top,
            right,
            bottom,

            height: 0,
            width: 0,
        } ),
    };
}

const useContextMenu = () => {
    const [isOpen, setIsOpen] = useState( false );
    const ref = useRef<any>( null );
    const [hierarchyItem, setHierarchyItem] = useState<any>();
    const[vaultItem,setvaultItem]=useState<any>()
    const [tagData , setTagData] = useState<any>();

    //for sellcontextMenu
    const [sellHierarchyItem, setSellHierarchyItem] =
        useState<any>();
    //for ProcureHierarchy
    const [procureHierarchyItem, setProcureHierarchyItem] =
        useState<any>();
    //for Cad Hierarchy
    const [cadHierarchyItem, setCadHierarchyItem] =
        useState<any>();

    const onContextMenu = ( e: React.MouseEvent, item: any ) => {
        e.preventDefault();
        e.stopPropagation();
        ref.current = createContextFromEvent( e );
        setIsOpen( true );
        setHierarchyItem( item );
    };


    const onSellContextMenu = ( e: React.MouseEvent, item: any ) => {
        e.preventDefault();
        e.stopPropagation();
        ref.current = createContextFromEvent( e );
        setIsOpen( true );
        setSellHierarchyItem( item );
    };

    //procure hierarchy
    const onProcureContextMenu = (
        e: React.MouseEvent,
        item: any
    ) => {
        e.preventDefault();
        e.stopPropagation();
        ref.current = createContextFromEvent( e );
        setIsOpen( true );
        setProcureHierarchyItem( item );
    };
    //Cad hierarchy
    const onCadContextMenu = ( e: React.MouseEvent, item: any ) => {
        e.preventDefault();
        e.stopPropagation();
        ref.current = createContextFromEvent( e );
        setIsOpen( true );
        setCadHierarchyItem( item );
    };

    const onContextThreeSixtyViewer = ( e: React.MouseEvent, tagData:any) => {
        e.preventDefault();
        e.stopPropagation();
        ref.current = createContextFromEvent( e );
        setIsOpen( true );
        setTagData(tagData);
    };

    const handleClose = () => setIsOpen( false );

    return Object.freeze( {
        isOpen,
        onContextMenu,
        ref,
        close: handleClose,
        hierarchyItem,
        onSellContextMenu,
        // onClickPlus,
        // vaultItem,
        sellHierarchyItem,
        procureHierarchyItem,
        onProcureContextMenu,
        cadHierarchyItem,
        onCadContextMenu,
        onContextThreeSixtyViewer,
        tagData
    } );
};

// ----------------------------------------------------------------------------------

export { useContextMenu };