import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import { useHistory } from "react-router-dom";
import {
  Backdrop,
  Chip,
  Divider,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  TextField,
  Typography,
  Skeleton,
} from "@mui/material";
import { API } from "../../api-services";
import { LoadingButton } from "@mui/lab";
import CancelIcon from "@mui/icons-material/Cancel";
import Swal from "sweetalert2";
import successIcon from "../../Assets/images/success.png";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import rightArrIcon from "../../Assets/images/rightArrows.svg";
import ReplyIcon from "@mui/icons-material/Reply";
import { useBaseModal } from "../SearchPage/useBaseModal";
import styles from "../../Configuration/Costing/AssumptionCard.module.scss";
import rightArrow from "../../Assets/images/rightArrows.svg";
import rightArrowBrown from "../../Assets/images/rightArrowBrown.svg";
import InfoIcon from "@mui/icons-material/Info";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import { AssumptionUsedDetails } from "../../Configuration/Costing/AssumptionUsedDetails";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45vw",
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 0,
};
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface IWatermarkModelProps {
  isOpen: any;
  onCloseModal: any;
  tab: any;
  // setCounter: any;
}

function AssumptionCardModalDetail(props: IWatermarkModelProps) {
  const { isOpen, onCloseModal, tab } = props;
  const history = useHistory();
  const { topVault, projectId, vault } = useRouteParams<any>();
  const [volume, setVolume] = React.useState<any>("");
  const [revision, setRevision] = React.useState<any>("");
  const [locationList, setLocationList] = React.useState<any>([]);
  const [permission, setPermission] = React.useState<any>(null);
  const [assumptionsData, setAssumptionsData] = React.useState<any>([]);
  const [isassumptionLoading, setIsassumptionLoading] = React.useState(false);

  const assumptionUsedDetailsModal = useBaseParamsModal();

  const getAssumptionsData = (tab: any) => {
    setIsassumptionLoading(true);

    API.get(
      "/cost/assumption_card/",
      {
        top_vault: topVault,
        assumption_type: tab,
      },
      0
    )
      .then((res: any) => {
        console.log(res.data);
        setAssumptionsData(res.data);
        setIsassumptionLoading(false);
      })
      .catch((err: any) => {
        setIsassumptionLoading(false);
      });
  };

  React.useEffect(() => {
    getAssumptionsData(tab);
  }, []);

  const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "90vw",
    height: "fit-content",
    bgcolor: "background.paper",
    borderRadius: "10px",
    p: 0,
  };

  const handleRedirect = (
    item: any,
    name: string,
    id: string,
    revisionId: string
  ): void => {
    // Create a routes object to map the name of the item to the url
    const routes: { [key: string]: string } = {
      "Raw Material": "rm",
      "Bought-Out Parts": "bop",
      "Machine Hour Rate": "machine",
      "Labor Hour Rate": "labor",
      "Tooling Cost DB": "tools",
      Operations: "operations",
    };

    // Create a url string with the revisionId, id, and route name
    // let tabValue = value=="main" ? 1 : 2;
    const url: string = `/data-base/revision-database/${tab}/${item?.year}/${item?.month}/${revisionId}/${id}/${routes[name]}/0/false/0/0`;

    // Use the history object to push the url to the application
    history.push(url);
  };



  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
       
      >
      <Box sx={style}>
      <Box
              sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}
            >
              <Typography
                style={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  width: "100%",
                  textAlign: "center",
                  marginRight: "-14rem",
                }}
              >
                {tab === 1 ? "Main Costing Assumptions" : "Scenario Costing Assumptions"}
              </Typography>
              
              <CancelIcon
                titleAccess="Close"
                sx={{ cursor: "pointer", margin: "0.5rem" }}
                onClick={onCloseModal}
              />
            </Box>
      {!isassumptionLoading && assumptionsData ? (
							assumptionsData?.map((item: any) => {
								return (
									<Box
										className={
											tab == 1
												? styles.assumptionCard
												: styles.assumptionCardScenario
										}>
										<Box className={styles.cardDetails}>
											<Box>
												<Box className={styles.cardDetails}>
													<Typography
														sx={{
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'space-between',
															width: '8rem',
														}}>
														<Typography
															className={styles.textColorleft}
															sx={{
																fontSize: '1rem',
																whiteSpace: 'nowrap',
																textAlign: 'right',
															}}>
															Revision Name :
														</Typography>
														{/* <Typography className={styles.textColorright} sx={{ fontSize: "1rem" }}> : </Typography> */}
													</Typography>
													<Typography
														className={styles.textColor}
														sx={{
															fontSize: '1rem',
															width: { lg: '11rem', xl: '12rem' },
														}}>
														{item?.revision_name}
													</Typography>
												</Box>
												<Box className={styles.cardDetails}>
													<Typography
														sx={{
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'space-between',
															width: '8rem',
														}}>
														<Typography
															className={styles.textColorleft}
															sx={{
																fontSize: '1rem',
																whiteSpace: 'nowrap',
																textAlign: 'right',
															}}>
															Name :
														</Typography>
														{/* <Typography className={styles.textColorright} sx={{ fontSize: "1rem" }}> : </Typography> */}
													</Typography>
													<Typography
														className={styles.textColor}
														sx={{
															fontSize: '1rem',
															width: { lg: '11rem', xl: '12rem' },
														}}>
														{item?.name}
													</Typography>
												</Box>
												<Box className={styles.cardDetails}>
													<Typography
														sx={{
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'space-between',
															width: '8rem',
														}}>
														<Typography
															className={styles.textColorleft}
															sx={{
																fontSize: '1rem',
																whiteSpace: 'nowrap',
																textAlign: 'right',
															}}>
															Volume :
														</Typography>
														{/* <Typography className={styles.textColor} sx={{ fontSize: "1rem" }}> : </Typography> */}
													</Typography>
													<Typography
														className={styles.textColor}
														sx={{
															fontSize: '1rem',
															width: { lg: '10rem', xl: '12rem' },
														}}>
														{item?.volume}
													</Typography>
												</Box>
											</Box>
											<Box>
												<Box className={styles.cardDetails}>
													<Typography
														sx={{
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'space-between',
															width: { lg: '6rem', xl: '7rem' },
														}}>
														<Typography
															className={styles.textColorleft}
															sx={{ fontSize: '1rem', whiteSpace: 'nowrap' }}>
															Created On :
														</Typography>
														{/* <Typography className={styles.textColor} sx={{ fontSize: "1rem" }}> : </Typography> */}
													</Typography>
													<Typography
														className={styles.textColor}
														sx={{
															fontSize: '1rem',
															width: { lg: '13rem', xl: '14rem' },
														}}>
														{item?.created_at}
													</Typography>
												</Box>
												<Box className={styles.cardDetails}>
													<Typography
														sx={{
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'space-between',
															width: { lg: '6rem', xl: '7rem' },
														}}>
														<Typography
															className={styles.textColorleft}
															sx={{ fontSize: '1rem', whiteSpace: 'nowrap' }}>
															Revised On :
														</Typography>
														{/* <Typography className={styles.textColor} sx={{ fontSize: "1rem" }}> : </Typography> */}
													</Typography>
													<Typography
														className={styles.textColor}
														sx={{
															fontSize: '1rem',
															width: { lg: '11rem', xl: '12rem' },
														}}>
														{item?.revised_on}
													</Typography>
												</Box>
												<Box className={styles.cardDetails}>
													<Typography
														sx={{
															display: 'flex',
															alignItems: 'center',
															justifyContent: 'space-between',
															width: { lg: '6rem', xl: '7rem' },
														}}>
														<Typography
															className={styles.textColorleft}
															sx={{ fontSize: '1rem', whiteSpace: 'nowrap' }}>
															Created By :
														</Typography>
														{/* <Typography className={styles.textColor} sx={{ fontSize: "1rem" }}> : </Typography> */}
													</Typography>
													<Typography
														className={styles.textColor}
														sx={{
															fontSize: '1rem',
															width: { lg: '10rem', xl: '12rem' },
														}}>
														{item?.created_by}
													</Typography>
												</Box>
											</Box>
										</Box>
										{/* <Divider orientation="vertical" sx={{borderColor:'primary.main'}} flexItem /> */}
										<Box sx={{ width: '100%' }}>
											<Box
												sx={{
													display: 'flex',
													width: '100%',
													alignItems: 'center',
													flexWrap: 'wrap',
													padding: '0.1rem 0.5rem',
													rowGap: '0.5rem',
													columnGap: '1rem',
												}}>
												{item?.data?.map((itm: any) => {
													// console.log(itm,"itmitm");
													return (
														<Chip
															title={'Go to ' + itm.name}
															variant='outlined'
															size='small'
															className={styles.chipOutline}	
															sx={{
																'.MuiChip-label':{
																	width:'100%',
																}
															}}														
															onClick={() =>
																handleRedirect(
																	item,
																	itm.name,
																	itm.id,
																	item.revision_id
																)
															}
															label={(
																<Box sx={{display:'flex',alignItems:'center',justifyContent:'space-between',width:'100%'}}>
																  {itm.name}
																  <img src={tab == 1 ? rightArrow : rightArrowBrown} alt="rightArrow" style={{height:'1.4rem'}} />
																</Box>
															  )}
														/>
													);
												})}
											</Box>
										</Box>
										{/* <Divider orientation="vertical" sx={{borderColor:'primary.main'}} flexItem /> */}
										<Box
											sx={{
												display: 'flex',
												rowGap: '1rem',
												columnGap: '1rem',
												alignItems: 'center',
												width: '10rem',
												padding: '0 1rem',
											}}>
											{item?.map_status && (
												<InfoIcon
													titleAccess='Assumption Info'
													sx={{ cursor: 'pointer' }}
													onClick={() =>
														assumptionUsedDetailsModal.open(item.id)
													}
												/>
											)}
										</Box>
									</Box>
								);
							})
						) : (
							<>
								<Box sx={{ padding: '0 1rem' }}>
									<Skeleton
										variant='rectangular'
										height='8.5rem'
										width='100%'
										sx={{ marginBottom: '1rem', borderRadius: '10px' }}
									/>

									<Skeleton
										variant='rectangular'
										height='8.5rem'
										width='100%'
										sx={{ marginBottom: '1rem', borderRadius: '10px' }}
									/>

									<Skeleton
										variant='rectangular'
										height='8.5rem'
										width='100%'
										sx={{ marginBottom: '1rem', borderRadius: '10px' }}
									/>

									<Skeleton
										variant='rectangular'
										height='8.5rem'
										width='100%'
										sx={{ marginBottom: '1rem', borderRadius: '10px' }}
									/>
								</Box>
							</>
						)}
        </Box>
		
      </Modal>
	  <Box sx={style}>
		{assumptionUsedDetailsModal.isOpen && (
				<AssumptionUsedDetails
					isOpen={assumptionUsedDetailsModal.isOpen}
					onCloseModal={assumptionUsedDetailsModal.close}
					item={assumptionUsedDetailsModal.propsId}
				/>
			)}
		</Box>
      
    </div>
  );
}
export default AssumptionCardModalDetail;
