import * as React from 'react';
import styles from './ArchitectureCreateProjectSelection.module.scss';
import {
    Box, Checkbox, Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import { useHistory, useRouteMatch } from "react-router-dom";
import { useState } from 'react';

import { useRouteParams } from '../../ui-reusable-component/useRouteParams';
import { API } from '../../api-services';
import { LoadingButton } from '@mui/lab';
import { AxiosError, AxiosResponse } from 'axios';
import AddGroupNameModal from './AddGroupNameModal';
import { useBaseModal } from '../SearchPage/useBaseModal';



interface Params {
    projectId: any;
    projectname: any;
}


export interface IArchitectureCreateDrawerProps {

}


export function ArchitectureCreateDrawer(props: IArchitectureCreateDrawerProps) {

    const { projectId } = useRouteParams<Params>();
    const [projectList, setProjectList] = useState<any>();

    const [mediaCount, setMediaCount] = useState<any>();
    const [selectedProject, setSelectedProject] = useState<any>([]);
    const [selectAllProject, setSelectAllProject] = useState<any>([]);
    const [selectedMediaType, setSelectedMediaType] = useState<any>([]);

    const { url } = useRouteMatch();
    const history = useHistory();


    React.useEffect(() => {
        setSelectedProject([projectId]);
    }, []);

    React.useEffect(() => {
        API.get(`/image_repository/media_module/`, { media_project_drawer: true,module:"Architecture" }, 0)
            .then((res: AxiosResponse) => {
                setProjectList(res?.data);
            })
            .catch((err: AxiosError) => {
                console.log(err, "error");
            });

        API.get(`image_repository/media_module/`, { project_repository_details: projectId }, 0)
            .then((res: AxiosResponse) => {
                setMediaCount(res?.data);
            })
            .catch((err: AxiosError) => {
                console.log(err, "error");
            });
    }, []);



    const redirectToProject: any = (item: any) => {
        history.push(`/architecturecreateinternal/${item.id}/${item.name}/0/0/0/0/0/0/0/0/0/0`);
    };




    return (
        <Box
            sx={{
                minWidth: 300,
                // width: anchor === "top" || anchor === "bottom" ? "auto" : "auto",
            }}
            role="presentation"
        >
    
            <Box sx={{ width: "100%", padding: "0" }}>
                <Box
                    sx={{ width: "100%", padding: "0 1rem" }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3"
                >
                    <Box sx={{ marginTop: '1rem' }}>
                        {projectList &&
                            projectList?.map((item: any, index: any) => {
                                console.log(item, "item");
                                return (
                                    <>
                                        <Box sx={{ alignItems: "center" }}>
                                            <Box sx={{ display: "flex", alignItems: "center" }}>
                                                <Checkbox
                                                    style={{ padding: "3px" }}
                                                    checked={
                                                        selectedProject?.includes(item.id) ? true : false
                                                    }
                                                    onClick={() => redirectToProject(item)}
                                                    inputProps={{ "aria-label": "controlled" }}
                                                    sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                                                />
                                                <Box
                                                    onClick={() => redirectToProject(item)}
                                                    key={item?.id}
                                                    className={styles.selectVehBtn}
                                                    sx={{
                                                        // margin: "0rem 2rem",
                                                        padding: "0.2rem 0.5rem",
                                                        backgroundColor: selectedProject?.includes(item.id) ? 'primary.main' : 'primary.light',
                                                        color: selectedProject?.includes(item.id) ? "white" : 'primary.main',
                                                        textAlign: "left",
                                                        cursor: "pointer",
                                                        width: "100%",
                                                    }}
                                                >
                                                    <Typography sx={{ fontSize: "0.87rem", display: "flex" }}>
                                                        {item.name}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Box>
                                    </>
                                );
                            })}
                    </Box>
                </Box>

            </Box>
        </Box>
    );
}
