import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import { API } from "../../api-services";
import calculator_green from "../../Assets/images/calculator_green.svg";
import DeleteIcon from "@mui/icons-material/Delete";
import swal from "sweetalert";
import InfoIcon from "@mui/icons-material/Info";
import TableChartIcon from "@mui/icons-material/TableChart";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import CancelIcon from "@mui/icons-material/Cancel";

import {
  DataGrid,
  GridColDef,
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridValueGetterParams,
} from "@mui/x-data-grid";
import AddIcon from "@mui/icons-material/Add";
import { useBaseModal } from "../../ComponentUI/SearchPage/useBaseModal";
import { AddParameterDataBaseModal } from "./AddParameterDataBaseModal";
import { useParams } from "react-router-dom";
import { ParameterlibraryInfoModal } from "./ParameterlibraryInfoModal";
import { Divider } from "@mui/material";
import CostModelForm from "./AddCostModelModal";
import calcIcon from "../../Assets/images/calculator.svg";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import { BOMConfigTableCalculator } from "./BOMTableCalculatorr";
const csvOptions: GridCsvExportOptions = {
  fileName: "Table In CSV",
  delimiter: ",",
  utf8WithBom: true,
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer style={{ height: "1.5rem", marginBottom: "0.5rem" }}>
    <span style={{ position: "absolute", right: "0rem" }}>
      {/* <GridToolbarColumnsButton /> */}
      {/* <GridToolbarDensitySelector /> */}
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "auto",
  height: "90vh",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};
interface TableParameterModalProps {
  isOpen: any;
  onCloseModal: any;
  Item: any;
  setCounter: any;
}

export const BOMTableCalcParameterModal = (props: TableParameterModalProps) => {
  const { isOpen, onCloseModal, Item, setCounter } = props;
  const { datatype } = useParams<any>();
  const createCostModal = useBaseModal();
  const formulaModal = useBaseParamsModal();
  const [count, setCount] = useState<any>(0);
  const [TableRows, setTableRows] = useState<any>([]);
  const [costModals, setCostModals] = useState<any>([]);
  const [inpuData, setInputData] = useState<any>(null);
  const [selectedCalculator, setSelectedCalculator] = useState<any>(null);
  const parameterModal = useBaseModal();
  const parameterModalEdit = useBaseModal();
  const [loader, setLoader] = useState<any>(false);
  const [rows, setrows] = useState<any>();
  const infoModal = useBaseModal();
  const [TableCounter, setTableCounter] = useState<any>(0);
  const AddParameter = () => {
    parameterModal.open();
  };

  const EditHandler = (item: any) => {
    parameterModalEdit.open();
    setrows(item);
  };

  const InfoHandler = (item: any) => {
    infoModal?.open();
    setrows(item);
  };
  useEffect(() => {
    if (Item !== 0) {
      setLoader(true);
      setTableRows([]);
      API.get(`/api/db/parameter/`, { table_id: Item })
        .then((res: any) => {
          setTableRows(res?.data);
          setLoader(false);
        })
        .catch((err: any) => [setLoader(false)]);
    }
  }, [Item, TableCounter, count]);

  const DeleteHanlder = (id: any) => {
    swal({
      title: "Delete",
      text: "Are sure you want to delete ?",
      buttons: ["Cancel", "Ok"],
      // icon: "sucess",
    }).then((confirm: any) => {
      if (confirm) {
        API.delete(`/api/db/parameter/${id}/`).then((res: any) => {
          setCounter((prev: any) => prev + 1);
          API.get(`/api/db/parameter/`, { table_id: Item })
            .then((res: any) => {
              setTableRows(res?.data);
              setLoader(false);
            })
            .catch((err: any) => [setLoader(false)]);
        });
      }
    });
  };

  const columnsFiled = [
    { field: "id", hide: true },
    { field: "Ids", headerName: "Sl No.", width: 50 },
    {
      field: "actions",
      headerName: "Action",
      minWidth: 100,
      renderCell: (cellvalues: any) => {
        return (
          <>
            {cellvalues?.row?.mapped_status ? (
              <InfoIcon
                sx={{ cursor: "pointer" }}
                onClick={() => InfoHandler(cellvalues?.row)}
              />
            ) : (
              <DeleteIcon
                onClick={() => DeleteHanlder(cellvalues?.row?.id)}
                titleAccess="Delete"
                sx={{ cursor: "pointer" }}
              />
            )}
            {cellvalues?.row?.editable && (
              <>
                <EditIcon
                  titleAccess="Edit"
                  sx={{ cursor: "pointer" }}
                  onClick={() => EditHandler(cellvalues?.row)}
                />
                {cellvalues?.row?.data_type === "table-calculator-result" && (
                  <img
                    src={cellvalues?.row?.data_type === "table-calculator-result" ? calculator_green : calcIcon}
                    style={{ cursor: "pointer", height: "1.4rem", marginLeft: "0.15rem" }}
                    title=""
                    onClick={() => editCostModel(cellvalues?.row)}
                  />
                )}
              </>
            )}
          </>
        );
      },
      // minWidth: width > 1400 ? 70 : 110

      // flex: 1
    },
    {
      field: "name",
      headerName: "Parameter Name",
      minWidth: 250,
      // minWidth: width > 1400 ? 70 : 110

      // flex: 1
    },
    {
      field: "alias",
      headerName: "Alias",
      minWidth: 80,
      // minWidth: width > 1400 ? 70 : 110

      // flex: 1
    },
    {
      field: "calculator?.result",
      headerName: "Results",
      minWidth: 180,
      renderCell: (cellValues: any) => {
        return (
          <>
            <Box title={cellValues.row.calculator?.result}>
              {cellValues.row.calculator?.result == null
                ? "_"
                : cellValues.row.calculator?.result}
            </Box>
          </>
        );
      }
    },
    {
      field: "data_type",
      headerName: "Data Type",
      minWidth: 180,
      // minWidth: width > 1400 ? 70 : 110

      // flex: 1
    },
    {
      field: "description",
      headerName: "Description",
      minWidth: 200,
      renderCell: (cellValues: any) => {
        return (
          <>
            {cellValues.row.description == null
              ? "_"
              : cellValues.row.description}
          </>
        );
      },
      // minWidth: width > 1400 ? 70 : 110

      // flex: 1
    },
    {
      field: "unit",
      headerName: "Unit",
      minWidth: 100,
      renderCell: (cellValues: any) => {
        return <>{cellValues?.row?.data_type === "table-calculator-result" ?
          <>
            {cellValues.row.calculator?.unit == "" || cellValues.row.calculator?.unit == null ? "_" : cellValues.row.calculator?.unit}
          </>
          : cellValues.row.unit == "" || cellValues.row.unit == null ? "_" : cellValues.row.unit
        }</>;
      },
      // minWidth: width > 1400 ? 70 : 110

      // flex: 1
    },
    {
      field: "exceptions",
      headerName: "Exceptions",
      minWidth: 150,
      renderCell: (cellValues: any) => {
        return (
          <>
            {cellValues.row.exceptions?.length == 0
              ? "_"
              : cellValues?.row?.exceptions.toString()}
          </>
        );
      },
      // minWidth: width > 1400 ? 70 : 110

      // flex: 1
    },
    {
      field: "allowed_values",
      headerName: "Allowed Values",
      minWidth: 150,
      renderCell: (cellvalues: any) => {
        return (
          <>
            {cellvalues.row.allowed_values?.length == 0
              ? "_"
              : cellvalues?.row?.allowed_values.toString()}
          </>
        );
      },
      // minWidth: width > 1400 ? 70 : 110

      // flex: 1
    },
    {
      field: "not_allowed_values",
      headerName: "Prohibited  Values",
      minWidth: 150,
      renderCell: (cellvalues: any) => {
        return (
          <>
            {cellvalues.row.not_allowed_values?.length == 0
              ? "_"
              : cellvalues?.row?.not_allowed_values.toString()}
          </>
        );
      },
      // minWidth: width > 1400 ? 70 : 110

      // flex: 1
    },

    {
      field: "default_value",
      headerName: "Default Values",
      minWidth: 150,
      renderCell: (cellvalues: any) => {
        return (
          <>
            {cellvalues.row.default_value == ""
              ? "_"
              : cellvalues?.row?.default_value}
          </>
        );
      },
    },
    {
      field: "info_doc",
      headerName: "Guideline",
      minWidth: 150,
      renderCell: (cellvalues: any) => {
        return (
          <>
            {cellvalues.row.info_doc == null ? (
              "_"
            ) : (
              <VisibilityIcon
                sx={{ cursor: "pointer" }}
                onClick={() => window.open(cellvalues?.row?.info_doc, "_blank")}
              />
            )}
          </>
        );
      },
      // minWidth: width > 1400 ? 70 : 110

      // flex: 1
    },
    {
      field: "mapped_status",
      headerName: "Status",
      minWidth: 80,

      renderCell: (cellValues: any) => {
        return <>{cellValues.row.mapped_status ? "In Use" : "Not Used"}</>;
      },
      // minWidth: width > 1400 ? 70 : 110

      // flex: 1
    },
    {
      field: "created_by",
      headerName: "Created By",
      minWidth: 150,
      renderCell: (cellValues: any) => {
        return (
          <>
            {cellValues.row.created_by == null
              ? "_"
              : cellValues.row.created_by}
          </>
        );
      },
      // minWidth: width > 1400 ? 70 : 110

      // flex: 1
    },
    {
      field: "updated_on",
      headerName: "Last Updated On",
      minWidth: 150,
      // minWidth: width > 1400 ? 70 : 110

      // flex: 1
    },
  ];
  var RowData =
    TableRows &&
    TableRows?.map((item: any, index: any) => ({ ...item, Ids: index + 1 }));

  const AddCalculator = () => {
    createCostModal.open();
  };

  const editCostModel = (row?: any) => {
    setSelectedCalculator(row);
    formulaModal.open();
  };

  useEffect(() => {
    setInputData({
      id: selectedCalculator?.id,
      calcId: selectedCalculator?.calculator?.id,
      finalResult: selectedCalculator?.calculator?.result,
      BOM_table_unit: selectedCalculator?.calculator?.unit,
      table_calculator: selectedCalculator?.calculator,
    });
  }, [selectedCalculator]);

  return (
    <div>
      {createCostModal.isOpen && (
        <CostModelForm
          onCloseModal={createCostModal.close}
          isOpen={createCostModal.isOpen}
          setCounter={setCount}
          parameter_relation_id={Item}
        />
      )}

      {selectedCalculator && formulaModal.isOpen && (
        <BOMConfigTableCalculator
          isOpen={formulaModal.isOpen}
          onCloseModal={formulaModal.close}
          tableId={Item}
          rowObj={inpuData}
          inputData={selectedCalculator}
          setCounter={setCounter}
          setCountt={setCount}
        />
      )}
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Database BOM Calculator Table Parameter
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />

          {parameterModal.isOpen && (
            <AddParameterDataBaseModal
              Modaltype={"tableAdd"}
              isOpen={parameterModal.isOpen}
              onCloseModal={parameterModal.close}
              datatype={datatype}
              TableIds={Item}
              setTableCounter={setTableCounter}
              extraType={"filterList"}
            />
          )}
          {infoModal.isOpen && (
            <ParameterlibraryInfoModal
              isOpen={infoModal.isOpen}
              onCloseModal={infoModal.close}
              Item={rows}
            />
          )}

          {parameterModalEdit.isOpen && (
            <AddParameterDataBaseModal
              Modaltype={"tableEdit"}
              isOpen={parameterModalEdit.isOpen}
              onCloseModal={parameterModalEdit.close}
              datatype={datatype}
              rows={rows}
              setTableCounter={setTableCounter}
            />
          )}

          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "end",
              padding: "0 1rem",
              width: "92%",
              marginBottom: "-1.5rem",
            }}
          >
            {datatype === "BOM-table-calculator" && (
              <Button
                onClick={() => AddCalculator()}
                sx={{
                  cursor: "pointer",
                  position: "relative",
                  zIndex: 1,
                  marginRight: "0.5rem",
                }}
                size="small"
                startIcon={
                  <>
                    <AddIcon />
                  </>
                }
                variant="contained"
              >
                ADD Calculator
              </Button>
            )}
            <Button
              onClick={() => AddParameter()}
              sx={{ cursor: "pointer", position: "relative", zIndex: 1 }}
              size="small"
              startIcon={
                <>
                  <AddIcon />
                </>
              }
              variant="contained"
            >
              ADD Parameter
            </Button>
          </Box>
          <Box sx={{
            width: "90vw", height: "80vh",
            "& .super-app-theme--calc": {
              bgcolor: "#ecffec",
              color: "green"
            },
          }}>
            {RowData && (
              <DataGrid
                headerHeight={48}
                rowHeight={40}
                rows={RowData}
                columns={columnsFiled}
                loading={loader}
                density="compact"
                // autoHeight={true}
                components={{
                  Toolbar: () => {
                    return CustomToolbar();
                  },
                }}
                sx={{
                  "&.MuiDataGrid-root": {
                    border: "none",
                  },
                  "&.MuiDataGrid-root .MuiDataGrid-cell": {
                    borderBottomColor: "primary.light",
                  },
                  "& ::-webkit-scrollbar": {
                    width: "0.3rem",
                    height: "0.3rem",
                  },
                  "& ::-webkit-scrollbar-thumb": {
                    // background: $thumb-color;
                    backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                    borderRadius: "10px",
                  },
                  "& ::- webkit-scrollbar-thumb: hover ": {
                    // width: "4px",
                    backgroundColor: "#045DE9 !important",
                  },
                  "::-webkit-scrollbar-track": {
                    backgroundColor: "#e1e1f3 !important",
                  },
                }}
                getRowClassName={(params) => {
                  // Handle both lock and mapped_status conditions
                  if (params.row.data_type === "table-calculator-result") {
                    return "super-app-theme--calc";
                  }
                  return `super-app-theme--${params.row.mapped_status}`;
                }}
                disableSelectionOnClick={true}
                hideFooter={true}
                hideFooterPagination={true}
              />
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
