// MySelect.tsx
import React from "react";
import styles from "./MySelect.module.scss";
import { Select, MenuItem, FormControl, InputLabel } from "@mui/material";
export interface Option {
  value: any;
  label: string;
}

interface MySelectProps {
  label: string;
  value: string;
  options: Option[];
  onChange: (value: string) => void;
  disabled?: any;
}

const MySelect: React.FC<MySelectProps> = ({ label, value, options, onChange ,disabled}) => {
  const getColor = (value: string) => {
    switch (value) {
      case "Yes":
        return { color: "green", fontSize: "1rem" };

      case "No":
        return { color: "red", fontSize: "1rem" };
      default:
        break;
    }
  };
  return (
    <FormControl
      fullWidth
      variant="standard">
      {/* <InputLabel>{label}</InputLabel> */}
      <Select
        value={value}
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled}
        sx={{
          "&:before": {
            borderBottomColor: "primary.light",
          },
          "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
            fontSize: "1rem",
          },
          ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
        }}>
        {options.map((option) => (
          <MenuItem
            key={option.value}
            value={option.value}
            sx={getColor(option.label)}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

export default MySelect;
