import { ID } from "../../utlis/commonInterfaces";
import { API } from "./index.service";

const urlGet = "image_repository/media_module/";
const  url = "image_repository/media_group/";
const imageRepo = "image_repository/update/";
const validationUrl="image_repository/quality_validation/";
class groupingImage {
    getAllGroups = ( projectId: ID, mediaType: any, imageCategory: any, abbreviation: any, get_groups: any, get_media_groups:any ) => {
        // return API.get( url, { abbreviation: abbreviation, top_vault: topVault } );
        return API.get( urlGet, {
            project: projectId,
            media_type: mediaType,
            image_category: imageCategory,
            abbreviation: abbreviation,
            get_groups:get_groups,
            get_media_groups:get_media_groups
        } );
    };
    createGroup = ( name: any, projectId: ID, mediaType: any, abbreviation: any ,image_category:any) => {
        return API.post( url, {
            name: name, project: projectId, media_type: mediaType, abbreviation: abbreviation,image_category:image_category
        } );
    };
    updateGroup = ( id: any, name: any ) => {
        return API.put( url + id + "/", { name: name } );
    };
    getValidationData=(project:any,abbreviation:any,category:any,row:any,page:any,mediaType:any,groupId:any)=>{
        return API.get(validationUrl,{project,abbreviation,category,row,page,media_type:mediaType,group_id:groupId})
    }
    deleteGroup = ( id: any,
        topVault: any,
        abbreviation: any,
        media_type: any,
        image_category: any ,type:number) => {
        return API.delete( url + id + "/", {
            top_vault: topVault,
            abbreviation: abbreviation,
            media_type: media_type,
            image_category: image_category,
            type:type
        } );
    };
    moveImageToAnotherGrp = ( id: any, group: any, media_type: any, new_sequence: any ) => { return API.put( imageRepo + id + '/', { media_type: media_type, group: group, new_sequence: new_sequence } ); };
}
const GROUPINGIMAGE = new groupingImage();
export default GROUPINGIMAGE;