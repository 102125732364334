// react
import React from "react";

// vendors
import { useRouteMatch } from "react-router-dom";

// services
// utils
// constants
// factories
// core

// redux
// ui-components
// others
// styles

import Routes, { RoutesProps } from "../ui-reusable-component/Routes";
import DataBaseLandingPage from "./DataBaseLandingPage";
import styles from "./DataBaseRouter.module.scss";
import ParameterLibraryLandingPage from "./ParameterLibrary/ParameterLibraryLandingPage";
import BomCategories from "./BOM Categories/BomCategories";
import SuppliersLandingPage from "./Suppliers/SuppliersLandingPage";
import BomRecycleBin from "./BOM Categories/BomRecycleBin";
import SupplierDatabaseRecyclebin from "./Suppliers/SupplierDatabaseRecyclebin";
import ParameterLibraryRecycleBin from "./ParameterLibrary/ParameterLibraryRecycleBin";
import SystemAndNamesLibraryLandingPage from "./SystemAndNamesLibrary/SystemAndNamesLibraryLandingPage";
import { CommodityProcess } from "./Costing/CommodityProcess";
import { RevisionAndDatabase } from "./Costing/RevisionAndDataBase";
import SubsystemNameRecycleBin from "./SystemAndNamesLibrary/SubsystemNameRecycleBin";
import { MachineHourRateTable } from "./Costing/MHRDataBase/MachineHourRateTable";
import CostingDataBaseRecycleBin from "./Costing/CostingDataBaseRecycleBin";
import FeatureNamesLibraryLandingPage from "./FeatureNameLibrary/FeatureNamesLibraryLandingPage";
import FeatureNameRecycleBin from "./FeatureNameLibrary/FeatureNameRecycleBin";
import FeatureLibraryLandingPage from "./ParameterLibrary/FeatureLibraryLandingPage";
import FeatureLibraryRecycleBin from "./ParameterLibrary/FeatureLibraryRecycleBin";
import MainDatabase from "./Costing/Database/MainDatabase";


// interfaces and types
// lazy


// ----------------------------------------------------------------------------------

interface MediaRepositoryRouterProps { }

const DataBaseRouter: React.FC<MediaRepositoryRouterProps> = () => {
  const { path } = useRouteMatch();

  const routes: RoutesProps["routes"] = [
    {
      path: `/data-base/data-base-landing-page`,
      exact: true,
      component: DataBaseLandingPage,
      name: `Compare`
    },
    
    {
      path: `/data-base/Parameter-library/:datatype/:search`,
      exact: true,
      component: ParameterLibraryLandingPage,
      name: `Compare`
    },
    {
      path: `/data-base/Feature-library/:datatype/:search`,
      exact: true,
      component: FeatureLibraryLandingPage,
      name: `Compare`
    },
    {
      path: `/data-base/SubsystemNameRecycleBin`,
      exact: true,
      component: SubsystemNameRecycleBin,
   
    },
    {
      path: `/data-base/FeatureNameRecycleBin`,
      exact: true,
      component: FeatureNameRecycleBin,
   
    },

    {
      path: `/data-base/bomCategories`,
      exact: true,
      component: BomCategories,
      name: `BOM Categories`
    },
    {
      path: "/data-base/supplier-database/:page",
      component: SuppliersLandingPage,
      exact: true,
    },
    {
      path: "/data-base/recycleBinBom",
      component: BomRecycleBin,
      exact: true,
    },
    {
      path: "/data-base/supplier-recyclebin",
      component: SupplierDatabaseRecyclebin,
      exact: true,
    },
    {
      path: "/data-base/recycleBinParameterLibrary",
      component: ParameterLibraryRecycleBin,
      exact: true,
    },
    {
      path: "/data-base/recycleBinFeatureLibrary",
      component: FeatureLibraryRecycleBin,
      exact: true,
    },
    {
      path: "/data-base/system-names-database/:categoryId/:subsystemId/:search",
      component: SystemAndNamesLibraryLandingPage,
      exact: true
    }, 
    {
      path:"/data-base/feature-names-database/:categoryId/:subsystemId/:search/:Featuretype",
      component: FeatureNamesLibraryLandingPage,
      exact: true
    }, 
    {
      path: "/data-base/commodity-process",
      component:CommodityProcess,
      exact: true
    },
    {
      path: "/data-base/revision-database/:tab/:year/:month/:revisionId/:typeId/:databaseName/:categoryName/:status/:search/:lockCounter",
      component:RevisionAndDatabase,
      exact: true
    },
    {
      path: "/data-base/database/:tab/:year/:month/:revisionId/:typeId/:databaseName/:categoryName/:status/:search",
      component: MainDatabase,
      exact: true
    },

    {
      path:"/data-base/revision-database/CostingDataBaseRecycleBin",
      exact: true,
      component: CostingDataBaseRecycleBin,
    }

  ];

  return (
    <div className={styles.MediaRepositoryRouter} >
      <Routes routes={routes} />
    </div>
  );
};

// ----------------------------------------------------------------------------------

export default DataBaseRouter;
