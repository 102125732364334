import React from 'react'
import Box from '@mui/material/Box';
import Button, { ButtonProps } from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CancelIcon from '@mui/icons-material/Cancel';

import { Divider, Tooltip } from '@mui/material';



interface InfoModalProps {


    onCloseModal: any;
    isOpen: any;
    Item: any;

}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    minWidth: "35vw",

    // height: '80vh',
    bgcolor: 'background.paper',
    borderRadius: '10px',
    p: 1,
};

const LockStatusInfoModal = (props: InfoModalProps) => {



    const { onCloseModal,
        isOpen,
        Item } = props

    console.log(Item, "dgdvhdvhdhvdhhdvdvdbjvdhj")


    return (
        <div>


            <Modal
                open={isOpen}
                onClose={onCloseModal}
            >
                <Box sx={style}>
                    <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
                        <Typography
                            style={{
                                fontSize: "1rem",
                                fontWeight: "600",
                                width: "100%",
                                textAlign: "center",
                                marginRight: '-2rem'
                            }}
                        >
                            Lock Info
                        </Typography>
                        <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
                    </Box>
                    <Divider
                        sx={{
                            borderColor: "primary.light",
                            margin: "0 1rem",
                            marginBottom: "1rem",
                        }}
                    />

                    <Box sx={{ width: '97%', margin: "0 auto" }}


                    >
                        <Box>
                            <Box>
                                <Box
                                    sx={{
                                        display: "flex",
                                        flexDirection: "row",
                                    }}


                                >
                                    <Typography

                                        sx={{
                                            marginBottom: "0.5rem",
                                        }}

                                    >Locked By</Typography>
                                    <Typography

                                        sx={{ margin: "0rem 1rem 0rem 3.2rem" }}


                                    >:</Typography>
                                    <Typography>
                                        
                                        {Item?.locked_user_name}
                                    
                                    </Typography>
                                </Box>
                                <Box sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <Typography   sx={{
                                            marginBottom: "0.5rem",
                                        }}>Locked Date</Typography>
                                    <Typography sx={{ margin: "0rem 1rem 0rem 2rem" }} >:</Typography>
                                    <Typography>  {Item?.locked_on}
                                    </Typography>
                                </Box>
                            </Box>
                            <Box>
                                <Box sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <Typography   sx={{
                                            marginBottom: "0.5rem",
                                        }}>Email</Typography>
                                    <Typography sx={{ margin: "0rem 1rem 0rem 5.5rem" }}>:</Typography>
                                    <Typography>  {Item?.locked_user_email}
                                    </Typography>
                                </Box>
                                <Box sx={{
                                    display: "flex",
                                    flexDirection: "row",
                                }}>
                                    <Typography   sx={{
                                            marginBottom: "0.5rem",
                                        }}>Phone Number</Typography>
                                    <Typography sx={{ margin: "0rem 1rem 0rem 0.7rem" }}>:</Typography>
                                    <Typography>  {Item?.locked_user_phone}
                                    </Typography>
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </div>
    )
}

export default LockStatusInfoModal;