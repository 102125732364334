import React, { useState } from "react";
import { Box, Button, Checkbox, Dialog, DialogContent, Divider, Slide, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { styled, alpha } from "@mui/material/styles";
import styles from "../../CostingCreateSystemInsideBodyRow.module.scss";
// import Swal from "sweetalert2";
// import swal from "sweetalert";
// import successIcon from "../../../../Assets/images/success.png";
import { useHistory } from "react-router-dom";
import { API } from "../../../../api-services";
import { useRouteParams } from "../../../../ui-reusable-component/useRouteParams";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import { LoadingButton } from "@mui/lab";
import { AxiosError, AxiosResponse } from "axios";
import CancelIcon from "@mui/icons-material/Cancel";
import { TransitionProps } from "@mui/material/transitions";
import { DialogComponent } from "../../../../utlis/DialogComponent";
import { useBaseModal } from "../../../SearchPage/useBaseModal";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "left",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    border: '0.1px solid #007fff',
    minWidth: 150,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

interface Params {
  projectId: any;
  projectName: any;
  top_vault: any;
  abbr: any;
  scenarioName: any;
  inside_scenario_id:any;
  vault_id: any;
  scenarioIds: any;
  expandTable: any;
  initialScenarioId:any;
}
interface ICostingScenerioSystemInsideBodyRowsProps {
  costingTableData: any;
  handleOpenHierarchy: any;
  sessionGetInto: any;
  costingTableDataParent: any;
  setIconText: any;
  setContentText: any;
  openStatusContent: any;
  categoryCopy: any;
  setCategoryCopy: any;
  handleChangePasteCheckbox: any;
  pasteVaultNoList: any;
  copyVaultCategory: any;
  setCopyVaultCategory: any;
  setCopyVaultNo: any;
  showCheckboxCopyClicked: any;
  setShowCheckboxCopyClicked: any;
  hoveredIndex: any;
  setHoveredIndex: any;
  reloadCostingData: any;
  setReloadCostingData: any;
  permissionCosting:any;
  setStatisticsData:any;
  setOpenStatisticsModel:any;
}

const CostingScenerioSystemInsideBodyRows: React.FC<ICostingScenerioSystemInsideBodyRowsProps> = (props) => {
  const {
    costingTableData,
    handleOpenHierarchy,
    sessionGetInto,
    costingTableDataParent,
    setIconText,
    setContentText,
    openStatusContent,
    categoryCopy,
    setCategoryCopy,
    handleChangePasteCheckbox,
    pasteVaultNoList,
    copyVaultCategory,
    setCopyVaultCategory,
    setCopyVaultNo,
    showCheckboxCopyClicked,
    setShowCheckboxCopyClicked,
    hoveredIndex,
    setHoveredIndex,
    reloadCostingData,
    setReloadCostingData,
    permissionCosting,
    setStatisticsData,
    setOpenStatisticsModel
  } = props;

  const {
    projectId,
    projectName,
    top_vault,
    abbr,
    scenarioName,
    inside_scenario_id,
    vault_id,
    scenarioIds,expandTable,initialScenarioId
  } = useRouteParams<Params>();
  
  const [statisticsDataLoader, setStatisticsDataLoader] = React.useState(false);
  const [openDownloadModel,setOpenDownloadModel] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [scenarioData,setScenarioData] = React.useState<any>();
  const [vaultTypeDownload,setVaultTypeDownload] = React.useState<any>();
  const [vaultIdGet,setVaultIdGet] = React.useState<any>();

  const openDownload = Boolean(anchorEl);
  const openDialogModal=useBaseModal();
  

  const handleOpenDownloadModel = (scenarioVaultType:any,scenarioData:any) => {
    setVaultTypeDownload(scenarioVaultType);
    setScenarioData(scenarioData)
    setOpenDownloadModel(true);
  };

  const handleCloseDownloadModel = () => {
    setVaultTypeDownload(undefined);
    setScenarioData(undefined);
    setVaultIdGet(undefined);
    setOpenDownloadModel(false);
  };

  const handleExcelReport = async(idIncomingData:any) => {
    openDialogModal.open();
    try {
      const { data: url } = await API.get(
        "/customer/individual_report/",
        {
          vault: costingTableData?.id,
          individual_type:"Costing",
          scenario:idIncomingData == costingTableData?.id ? 0 : idIncomingData
        },
        0
      );
      window.open(url, "_blank", "noopener noreferrer");
    } catch (e) {
    }
  }

  const handleRedirect = (module: any, id: any) => {
    if (module === "w") {
      API.get(
        "/customer/check_consolidate_downoad/",
        {
          vault: id,
          with_image: true,
          consolidate_type: "Costing",
          scenario: vaultIdGet == id ? 0 : vaultIdGet,
        },
        0
      ).then((res: any) => {
        if (res.data.status === "Failed") {
          setIconText("failed");
          setContentText(res.data.message);
          openStatusContent.open();
        } else if (res.data.status === "First Download") {
          setIconText("firstdownload");
          setContentText(res.data.message);
          handleClose();
          openStatusContent.open();
          API.get(
            "customer/consolidated_report/",
            {
              vault: id,
              with_image: true,
              consolidate_type: "Costing",
              scenario: vaultIdGet == id ? 0 : vaultIdGet,
            },
            0
          ).then((res: any) => {
            handleClose();
            window.open(res?.data, "_self");
          });
        } else if (res.data.status === "In Progress") {
          setIconText("reportProgress");
          setContentText(res.data.message);
          handleClose();
          openStatusContent.open();
        } else if (res.data.status === "Completed") {
          handleClose();
          window.open(res?.data?.url, "_self");
        }
      });
    } else if (module === "w/o") {
      API.get(
        "/customer/check_consolidate_downoad/",
        {
          vault: id,
          with_image: false,
          consolidate_type: "Costing",
          scenario: vaultIdGet == id ? 0 : vaultIdGet,
        },
        0
      ).then((res: any) => {
        if (res.data.status === "Failed") {
          setIconText("failed");
          setContentText(res.data.message);
          handleClose();
          openStatusContent.open();
        } else if (res.data.status === "First Download") {
          setIconText("firstdownload");
          setContentText(res.data.message);
          handleClose();
          openStatusContent.open();
          API.get(
            "customer/consolidated_report/",
            {
              vault: id,
              with_image: false,
              scenario: vaultIdGet == id ? 0 : vaultIdGet,
              consolidate_type: "Costing",
            },
            0
          ).then((res: any) => {
            handleClose();
            window.open(res?.data, "_self");
          });
        } else if (res.data.status === "In Progress") {
          setIconText("reportProgress");
          setContentText(res.data.message);
          handleClose();
          openStatusContent.open();
        } else if (res.data.status === "Completed") {
          handleClose();
          window.open(res?.data?.url, "_self");
        }
      });
    }
  };

  const handleStaticticsOpen = (vault_idIncoming: any) => {
    setStatisticsDataLoader(true);
    API.get(`/cost/scenario_indepedant_graph/`, { top_vault: top_vault, parent: vault_id, total_cost: true, vault:vault_idIncoming }, 0)
      .then((res: AxiosResponse) => {
        setStatisticsData(res.data);
        setOpenStatisticsModel(true);
        setStatisticsDataLoader(false);
      })
      .catch((err: AxiosError) => {
        console.log(err, "error");
      });
  }

  const handleClick = (event: React.MouseEvent<HTMLElement>,idIncoming:any) => {
    setVaultIdGet(idIncoming);
    vaultTypeDownload ?
    handleExcelReport(idIncoming) :
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setVaultIdGet(undefined);
  };

  const redirectToCostingPage = (vault_id:any) => {
    if(permissionCosting && permissionCosting?.action?.includes("R")){
    sessionStorage.setItem(
      "node",
      JSON.stringify(costingTableData?.ancestors)
    );
    window.open(
      `/#/view/${projectId}/${top_vault}/${vault_id}/${costingTableData?.abbreviation}/costing`
    )
    }
  };

  const redirectToCostingPageScenerio = (scenario_id:any) => {
    window.open(
      `/#/createcostingpart/${projectId}/${projectName}/${scenarioName}/${top_vault}/${
        costingTableData?.id
      }/${
        costingTableData?.abbreviation == null
          ? "none"
          : costingTableData?.abbreviation
      }/0/0/0/0/0/0/0/${scenario_id}/0/true`
    );
  };

  return (
    <>
    <DialogComponent isOpen={openDialogModal.isOpen} onClose={openDialogModal.close} content={"We've got this report downloading faster than a cheetah running. You will also receive a notification.But in the meantime, keep using xcPEP like the productivity champion !"} type={"firstdownload"}/>
    <Dialog
        open={openDownloadModel}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseDownloadModel}
        // aria-describedby="alert-dialog-slide-description"
        sx={{".MuiPaper-root.MuiDialog-paper":{
          minWidth:'30%',
        }   }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
          <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: '-2rem'
              }}
            >
              Download Report
            </Typography>
            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={handleCloseDownloadModel} />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
        <DialogContent sx={{padding:'0 0.5rem'}}>
          <Table>
            <TableHead sx={{ backgroundColor: 'primary.light' }}>
              <TableRow>
                <TableCell sx={{ color: 'primary.main', padding: '0.2rem 0.5rem', fontSize: '12px' }}>Costing Type</TableCell>
                <TableCell sx={{ color: 'primary.main', padding: '0.2rem 0.5rem', fontSize: '12px' }}>Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
            {scenarioData && Object.keys(scenarioData)?.map((item: any, index: number) => {
              return(<TableRow>
                <TableCell sx={{ padding: '0.2rem 0.5rem', fontSize: '12px' }}>{item}</TableCell>
                <TableCell sx={{ padding: '0.2rem 0.5rem', fontSize: '12px' }}>
                <Button
                  id="demo-customized-button"
                  aria-controls={
                    openDownload ? "demo-customized-menu" : undefined
                  }
                  aria-haspopup="true"
                  aria-expanded={openDownload ? "true" : undefined}
                  disableElevation
                  onClick={(event: React.MouseEvent<HTMLElement>)=>handleClick(event,scenarioData[item])}
                  style={{ padding: "0rem", minWidth: "1rem",margin:'0 0.2rem' }}
                >
                  <CloudDownloadIcon sx={{ cursor: "pointer" }} />
                </Button>
                  <StyledMenu
                    id="demo-customized-menu"
                    MenuListProps={{
                      "aria-labelledby": "demo-customized-button",
                    }}
                    anchorEl={anchorEl}
                    open={openDownload}
                    onClose={handleClose}
                  >
                    <MenuItem
                      sx={{
                        fontSize: "1rem",
                        color: "primary.main",
                        padding: "0.2rem 0.5rem",
                      }}
                      onClick={() => handleRedirect("w", costingTableData?.id)}
                    >
                      Consolidated with images
                    </MenuItem>
                    <MenuItem
                      sx={{
                        fontSize: "1rem",
                        color: "primary.main",
                        padding: "0.2rem 0.5rem",
                      }}
                      onClick={() => handleRedirect("w/o", costingTableData?.id)}
                    >
                      Consolidated w/o images
                    </MenuItem>
                  </StyledMenu>
                </TableCell>
              </TableRow>)})}
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
      <TableRow
        sx={
          hoveredIndex == costingTableData?.id
            ? { backgroundColor: "#f7e1f4", zIndex: 1, position: "relative" }
            : {}
        }
        onMouseEnter={() => setHoveredIndex(costingTableData?.id)}
        onMouseLeave={() => setHoveredIndex(null)}
      >
        <TableCell
          className={styles?.CheckboxTable}
          sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
        >
          <span
            style={
              sessionGetInto?.includes(costingTableDataParent?.id)
                ? {
                    marginLeft: `${costingTableData?.ancestors?.length - 2}rem`,
                    display: "flex",
                    alignItems: "center",
                  }
                : { display: "flex", alignItems: "center" }
            }
          >
            {!costingTableData?.is_leaf &&
              (!sessionGetInto?.includes(costingTableData?.id) ? (
                <AddCircleOutlineIcon
                  sx={{ marginRight: "0.5rem", cursor: "pointer" }}
                  onClick={() => handleOpenHierarchy(costingTableData?.id)}
                />
              ) : (
                <RemoveCircleOutlineIcon
                  sx={{ marginRight: "0.5rem", cursor: "pointer" }}
                  onClick={() => handleOpenHierarchy(costingTableData?.id)}
                />
              ))}
            <span
              style={
                !costingTableData?.is_leaf
                  ? { }
                  : {
                      marginLeft: "2.3rem",
                      // cursor: "pointer",
                    }
              }
            >
              {costingTableData?.part_no}
            </span>
          </span>
        </TableCell>
        <TableCell
          sx={{
            padding: "0.2rem 0.5rem",
            borderBottomColor: "primary.light"
          }}
        >
          <LoadingButton loading={statisticsDataLoader}
            sx={{
              p: 0, minWidth: '1rem',
              '.MuiCircularProgress-root': {
                color: 'primary.main',
              },
            }}>
          <LeaderboardIcon
                titleAccess="Statistics"
                sx={{cursor:'pointer'}}
                onClick={() =>
                  handleStaticticsOpen(
                    costingTableData?.id
                  )
                }
              />
          </LoadingButton>
        </TableCell>
        <TableCell
          sx={{
            padding: "0.2rem 0.5rem",
            borderBottomColor: "primary.light",
          }}
        >
          <span style={{ textTransform: "capitalize" }}>
            {costingTableData?.name.toLowerCase()}
          </span>
        </TableCell>
        <TableCell
          sx={{ padding: "0.2rem 0.5rem", borderBottomColor: "primary.light" }}
        >
          {costingTableData?.category}
        </TableCell>
        <TableCell
          sx={{
            padding: "0.2rem 0.5rem",
            borderBottomColor: "primary.light",
            textAlign: "right",
          }}
        >
          {costingTableData?.weight == 0
            ? costingTableData?.weight
            : costingTableData?.weight?.toFixed(2)}
        </TableCell>
        <TableCell
          sx={{
            padding: "0.2rem 0.5rem",
            borderBottomColor: "primary.light",
            textAlign: "right",
          }}
        >
          {costingTableData?.quantity}
        </TableCell>
        {costingTableData && costingTableData?.vault_details && Object.keys(costingTableData?.vault_details)?.map((item: any, index: number) => {
        return(<TableCell
          sx={item == 'Main Cost' && permissionCosting && permissionCosting?.action?.includes("R") ? 
          {
            padding: "0.2rem 0.5rem",
            borderBottomColor: "primary.light",
            textAlign: "right",
          } : {
            padding: "0.2rem 0.5rem",
            borderBottomColor: "primary.light",
            textAlign: "right",
            textDecoration:'underline',
            cursor:'pointer'
          }}
          onClick={item == 'Main Cost' ? ()=>redirectToCostingPage(costingTableData?.scenario_details?.[item]) : ()=>redirectToCostingPageScenerio(costingTableData?.scenario_details?.[item]) }
        >
          {costingTableData?.vault_details?.[item]}
        </TableCell>
        )})
        }
        <TableCell
          sx={{
            padding: "0.2rem 0.5rem",
            borderBottomColor: "primary.light",
            textAlign: "right",
          }}
        >
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              columnGap: "0.7rem",
            }}
          >
            <Button
              onClick={()=>handleOpenDownloadModel(costingTableData?.is_leaf,costingTableData?.scenario_details)}
              style={{ padding: "0rem", minWidth: "1rem",margin:'0 0.2rem' }}
            >
              <CloudDownloadIcon sx={{ cursor: "pointer" }} />
            </Button>
          </span>
        </TableCell>
      </TableRow>
      {costingTableData?.data?.map((item: any, index: number) => {
        return (
          sessionGetInto?.includes(costingTableData?.id) && (
            <CostingScenerioSystemInsideBodyRows
              costingTableData={item}
              handleOpenHierarchy={handleOpenHierarchy}
              sessionGetInto={sessionGetInto}
              costingTableDataParent={costingTableData}
              setIconText={setIconText}
              setContentText={setContentText}
              openStatusContent={openStatusContent}
              categoryCopy={categoryCopy}
              setCategoryCopy={setCategoryCopy}
              handleChangePasteCheckbox={handleChangePasteCheckbox}
              pasteVaultNoList={pasteVaultNoList}
              copyVaultCategory={copyVaultCategory}
              setCopyVaultCategory={setCopyVaultCategory}
              setCopyVaultNo={setCopyVaultNo}
              showCheckboxCopyClicked={showCheckboxCopyClicked}
              setShowCheckboxCopyClicked={setShowCheckboxCopyClicked}
              hoveredIndex={hoveredIndex}
              setHoveredIndex={setHoveredIndex}
              reloadCostingData={reloadCostingData}
              setReloadCostingData={setReloadCostingData}
              permissionCosting={permissionCosting}
              setStatisticsData={setStatisticsData}
              setOpenStatisticsModel={setOpenStatisticsModel}
            />
          )
        );
      })}
    </>
  );
};

export default CostingScenerioSystemInsideBodyRows;
