import { mapValues } from "lodash";
import { useParams } from "react-router-dom";

// ----------------------------------------------------------------------------------

function useRouteParams<P extends { [K in keyof P]?: string | number; } = {}> () {
    const params = useParams<any>();

    return mapValues( params, ( value ) => {
        const converted = Number( value );

        return isNaN( converted ) ? value : converted;
    } ) as P;
};

// ----------------------------------------------------------------------------------

export {
    useRouteParams
};