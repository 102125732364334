import { Box, ButtonProps, Divider, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography, darken, lighten } from "@mui/material";
import * as React from "react";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import { API } from "../api-services";
import useWindowDimensions from "../ui-reusable-component/useWindowDimensions";
import VisibilityIcon from "@mui/icons-material/Visibility";
import WeightImage from "../Assets/images/Verifyweight.svg"
import { DataGrid, GridCsvExportMenuItem, GridCsvExportOptions, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExportContainer } from "@mui/x-data-grid";

export interface IVerifydocumentModalProps {
  onCloseModal: any;
  isOpen: any;
  itemID: any;
}
const csvOptions: GridCsvExportOptions = {
  fileName: "Dispatch Details",
  delimiter: ",",
  utf8WithBom: true,
};

const getBackgroundColor = (color: string, mode: string) => {
  return mode === "dark" ? darken(color, 0.6) : lighten(color, 0.6);
};

const getHoverBackgroundColor = (color: string, mode: string) => {
  return mode === "dark" ? darken(color, 0.5) : lighten(color, 0.5);
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer sx={{ height: "2rem", justifyContent: "flex-end", paddingRight: "1rem", marginBottom: "0.5rem" }}>
    <span
    // style={{ position: "absolute", right: "0rem" }}
    >
      <GridToolbarColumnsButton />
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: "90vw", xl: "80vw" },
  height: "90vh",
  bgcolor: "background.paper",
  borderRadius: "10px",
  // border: '2px solid #000',
  // boxShadow: 24,
  p: 1,
};

export function VerifydocumentModal(props: IVerifydocumentModalProps) {
  const { onCloseModal, isOpen, itemID } = props;
  const { itemId, vm } = itemID
  const { height, width } = useWindowDimensions();
  const [tableDataLoading, setTableDataLoading] = React.useState<any>(false);
  const [weightImg, setWeightImg] = React.useState<any>("");
  const [partDetails, setPartDetails] = React.useState<any>([]);
  const handleOpenLinkReference = (reference: string) => {
    window.open(reference);
  };
  React.useEffect(() => {
    if (vm === "SS Weight") {
      API.get("xcpep/dispatch/", {
        get_ss_weight_image: itemId
      }, 0).then((res: any) => {
        console.log("kjbsdkjdskjfgdsk", res.data[0]?.image_url)
        setWeightImg(res.data[0]?.image_url)
      }).catch((err: any) => {

      })
    }
  }, [vm])
  React.useEffect(() => {
    setTableDataLoading(true);
    API.get(
      "/xcpep/dispatch/",
      {
        get_parts_details: itemId,
      },
      0
    )
      .then((res: any) => {
        setPartDetails(res.data);
        setTableDataLoading(false);
      })
      .catch((err: any) => {
        setTableDataLoading(false);
      });
  }, [itemId]);

  const COLUMNS = [
    { field: "sno", headerName: "S.No", width: width > 1370 ? 50 : width < 900 ? 50 : 50 },
    {
      field: "part_no",
      headerName: "Part No.",
      width: width > 1370 ? 160 : 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "part_name",
      headerName: "Part Name",
      // type: "number",
      minWidth: width > 1370 ? 180 : 180,
      align: 'left',
      headerClassName: "super-app-theme--header",
      flex:1,
    },
    {
      field: "subsystem_code",
      headerName: "System Code",
      // type: "number",
      minWidth: width > 1370 ? 120 : 80,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "image",
      headerName: "Image",
      sortable: false,
      minWidth: width > 1370 ? 50 : width < 1200 ? 70 : 70,
      headerClassName: "super-app-theme--header",
      renderCell: (cellValues: any) => {
        return (
          <>
            <IconButton
              title="Material Info"
              sx={{ padding: "0", cursor: "pointer", color: "primary.main" }}
              onClick={() => handleOpenLinkReference(cellValues?.row?.image)}>
              <VisibilityIcon />
            </IconButton>
          </>
        );
      },
    },
    {
      field: "level",
      headerName: "Level",
      // align: "right",
      // headerAlign:'right',
      sortable: false,
      minWidth: width > 1370 ? 50 : 70,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "category",
      headerName: "Category",
      sortable: false,
      minWidth: width > 1370 ? 160 : width < 1200 ? 150 : 160,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "type",
      headerName: "Type",
      // cellClassName: "super-app-theme--cell",
      sortable: false,
      align: "left",
      minWidth: width > 1370 ? 100 : width < 1200 ? 90 : 100,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "weight",
      headerName: "Weight",
      sortable: false,
      headerAlign:'right',
      align: "right",
      minWidth: width > 1370 ? 120 : width < 1200 ? 120 : 140,
      headerClassName: "super-app-theme--header",
    },

    {
      field: "quantity",
      headerName: "Quantity",
      // cellClassName: "super-app-theme--cell",
      sortable: false,
      headerAlign:'right',
      align: "right",
      minWidth: width > 1370 ? 90 : width < 1200 ? 90 : 120,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "total_weight",
      headerName: "Total Weight",
      sortable: false,
      headerAlign:'right',
      align: "right",
      minWidth: width > 1370 ? 90 : width < 1200 ? 90 : 90,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "absolute_quantity",
      headerName: "Absolute Quantity",
      align: "right",
      sortable: false,
      width: width > 1370 ? 110 : width < 900 ? 90 : 90,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "verified",
      headerName: "Verified",
      sortable: false,
      width: width > 1370 ? 90 : width < 900 ? 90 : 90,
      headerClassName: "super-app-theme--header",
    },
  ];

  let rows: any = [];
  let dataGridDataGenerator: any =
    partDetails &&
    partDetails?.map((item: any, index: any) => {
      let objShared: any = {
        id: item?.id,
        sno: index + 1,
        part_no: item?.part_no,
        part_name: item?.part_name,
        subsystem_code: item?.subsystem_code,
        image: item?.image,
        level: item?.level,
        description: item?.description,
        category: item?.category,
        type: item?.type,
        weight: item?.weight,
        quantity: item?.quantity,
        total_weight: item?.total_weight,
        absolute_quantity: item?.absolute_quantity,
        verified: item?.verified,
      };
      rows.push(objShared);
    });

  let dataStructureDataGrid: any = {
    columns: COLUMNS,
    rows: rows && rows,
  };

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}>
              Verifications details
            </Typography>
            <>
              {vm === "SS Weight" && <img src={WeightImage} height={22} width={22} style={{ cursor: "pointer", marginRight: '0.5rem' }} title="weight verify image" onClick={() => window.open(weightImg)} />}
              <CancelIcon
                titleAccess="Close"
                sx={{ cursor: "pointer", margin: "0.5rem" }}
                onClick={onCloseModal}
              />
            </>
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box
            sx={{
              height: { lg: "80vh", xl: "83vh" },
              width: "100%",
              "& .super-app-theme--cell": {
                backgroundColor: "#ddefff !important",
                textAlign: "center !important",
              },
              "& .super-app-theme--Verified": {
                color: "#0021f3 !important",
                bgcolor: (theme) => getBackgroundColor("#337a33", theme.palette.mode),
                "&:hover": {
                  backgroundColor: "#41f049 !important",
                },
              },

              "& .super-app-theme--Unverified": {
                color: "#0021f3 !important",
                // #f7d8d9
                bgcolor: (theme) => getBackgroundColor("#d32f2f", theme.palette.mode),
                "&:hover": {
                  // "#FF6962 !important",
                  backgroundColor: "#FF7974 !important",
                },
              },
            }}>
            <DataGrid
              headerHeight={42}
              rowHeight={36}
              {...dataStructureDataGrid}
              loading={tableDataLoading}
              components={{
                Toolbar: () => {
                  return CustomToolbar();
                },
              }}
              sx={{
                "& ::-webkit-scrollbar": {
                  width: "0.3rem",
                  height: "0.3rem",
                },
                "& ::-webkit-scrollbar-thumb": {
                  // background: $thumb-color;
                  backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                  borderRadius: "10px",
                },
                "& ::- webkit-scrollbar-thumb: hover ": {
                  // width: "4px",
                  backgroundColor: "#045DE9 !important",
                },
                "::-webkit-scrollbar-track": {
                  backgroundColor: "#e1e1f3 !important",
                },
                "&.MuiDataGrid-root": {
                  border: "none",
                },
                "&.MuiDataGrid-root .MuiDataGrid-cell": {
                  borderBottomColor: "primary.light",
                },

              }}
              getRowClassName={(params) =>
                `super-app-theme--${params.row.verified.replace(/ /g, "")}`
              }
            />
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
