// react
import React, { useEffect, useState } from "react";
import {API} from "../../api-services";
import Modal from "@mui/material/Modal";
import styles from "./ConfigureColorModal.module.scss";
import { ButtonProps } from "@mui/material/Button";
import { Box, Divider, Typography, Input, Button } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { a, to } from "@react-spring/web";




interface ConfigureColorModalProps {
  isOpen: boolean;
  onClose: () => void;
  AllProjectdata: any;
  topVaultColor: any;
  setCounter: any;
  
}

interface Params {
  projectId: any;
  topVault: any;
}


const ConfigureColorModal: React.FC<ConfigureColorModalProps> = (props) => {
  const { isOpen, onClose, AllProjectdata, topVaultColor, setCounter } = props;
  const { projectId, topVault } = useRouteParams<Params>();
  const [colorSelect, setColorSelect] = useState(topVaultColor);
  console.log(topVaultColor);

  
  let handleOnClose = () => {
    onClose();
  }
  const topVaultsParam: any =
    topVault && typeof topVault == "string"
      ? topVault?.split(",")?.map(Number)
      : [topVault];

  console.log(AllProjectdata);
  const handleColorSelect: any = (e: any, id:any) => {
    console.log(e.target.value);
    console.log(id);
    let alltopVaultColor :any = {...colorSelect};
    alltopVaultColor[id] = e.target.value;

    setColorSelect(alltopVaultColor);
  };

  
  const handleSave = () => {
    console.log(colorSelect);
    API.post(`/customer/get_product_color/`, {
      data: colorSelect,
    }, 0).then((res) => {
      console.log(res);
      setCounter((prev: any) => prev + 1);
      onClose();
    })
    .catch((e) => {
      console.log(e);
    }
    )
  }
  

  // all state are declared here
  return (<Modal
    open={isOpen}
    onClose={handleOnClose}
    className={styles.container}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description">
    <div className={styles.materalcontainerInside}>
      <Box
        sx={{
          height: '94vh',
          width: "100%",
        }} >
        <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
          <Typography
            style={{
              fontWeight: "600",
              width: "100%",
              textAlign: "center",
              marginRight: '-2rem',
            }}>
                Color Configuration
          </Typography>
          <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={handleOnClose} />
        </Box>
        <Divider
          sx={{
            borderColor: "primary.light",
            margin: "0 1rem",
            marginBottom: "1rem",
          }}
        />
        {
            AllProjectdata && AllProjectdata?.map((item : any) => {
                
                let allProjectDataDetails = Object.keys(item).map((key : any) => {
                  return item[key]?.map((projectDetails: any, index:any) => {
                    return projectDetails
                  })
                })


                console.log(allProjectDataDetails.flat(1));
                allProjectDataDetails = allProjectDataDetails.flat(1);
                const sortedAllProjectDataDetails = allProjectDataDetails.sort((a: any, b: any) => a.id - b.id);
                return (
                  sortedAllProjectDataDetails.map((key: any, index:any) => {
                  console.log(key);
                  return (
                    <Box sx={{display:"flex", width:"100%", gap:"2rem", margin:"0.5rem"}}>
                      <Typography>{key.name}</Typography>
                      
                      
                      <Input
                  type="color"
                  onChange={(e) => handleColorSelect(e, key?.id)}
                  
                  value={colorSelect[key?.id]}
                  sx={{
                    "&.MuiInputBase-root.MuiInput-root:before": {
                      borderBottomColor: 'primary.light'
                    },
                    width: "10rem",
                  }}
                />
                    </Box>
                  )})
                )
                
            } )
        }
        <Box sx={{ display: 'flex', justifyContent: 'end', marginTop: '2rem', gap:"0.5rem" }}>
        <Button variant="contained" color="error" onClick={handleOnClose}>
            Cancel
          </Button>
          <Button variant="contained" color="primary" onClick={handleSave}>
            Save
          </Button>
          
          </Box>
      </Box>
    </div>
  </Modal>);
};

export default ConfigureColorModal;
