import * as React from "react";
import MySelect, { Option } from "./MySelect";
import { API } from "../../api-services";
import { Box } from "@mui/material";
import { validationCheckCounter } from "../../Redux/Actions/compareopen.action";
import { useDispatch } from "react-redux";
import { isLettersAvailable } from "../../utlis/CheckString";

export interface IEditAvailabilityProps {
  value: any;
  itemId: any;
  paramsId: any;
  getFeatureData: any;
  permission:any;
}
const options: Option[] = [
  { value: "Yes", label: "Yes" },
  { value: "No", label: "No" },
];

export function EditAvailability(props: IEditAvailabilityProps) {
  const { value, itemId, paramsId,getFeatureData,permission } = props;
  const dispatch=useDispatch();
  const [selectAvailability, setSelectAvailability] = React.useState<any>("No");
  const handleChangeAvailabilty = async (value: string) => {
    setSelectAvailability(value);
    await API.put(`/xcpep/feature_details/${itemId}/`, { is_feature: value }, {}, 0)
      .then((res: any) => {
        getFeatureData();
        var random: any = Math.floor(Math.random() * 1000000 + 1);
        dispatch(validationCheckCounter(random))
      })
      .catch((err: any) => {});
  };

  React.useEffect(() => {
    if (value) {
      setSelectAvailability(value);
    }
  }, [value]);

  return (
    <div>
      {value !== "Not Available" && permission? (
        <MySelect
          label="Select availability"
          value={selectAvailability}
          options={options}
          disabled={!permission?.includes("U")}
          onChange={handleChangeAvailabilty}
        />
      ) : (
        <Box>{value}</Box>
      )}
    </div>
  );
}
