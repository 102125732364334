import React, { useEffect } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import {
  Checkbox,
  CircularProgress,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import useFetch from "../../Core/CustomHooks/useFetch";
import reportsbank from "../../Redux/Services/reportsBank.service";
import { API } from "../../api-services";
import { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import styles from "./ComparedMainPage.module.scss";
import NewcompareLandingPage from "./NewcompareLandingPage";
import { useDocumentTitle } from "../../ui-reusable-component/useDocumentTitle";
import { set } from "lodash";
type Anchor = "top" | "left" | "bottom" | "right";
interface ComparedMainPageProps { }
const ComparedMainPage = (prop: ComparedMainPageProps) => {
  // @ts-ignore
  const { projectId, moduleType, topVaultId, abbr, NodeId, Partname, TopId, Nodeleaf, depthNode, pathname } =
    useParams<any>();
  const [ProjectIdInfo, setProjectIdInfo] = React.useState([]);
  useDocumentTitle( "Compare view" );
  const [AllProductData, setAllProductData] = React.useState([]);
  const [selectedTopVaultIds, setSelectedTopVaultIds] = useState<any>([]);
  const [ProjectData, setProjectData] = useState([]);
  const [loaderProduct, setLoaderProduct] = useState(false);
  const [allModule, setallModule] = useState<any>([parseInt(moduleType)]);
  const [Counter, setCounter] = useState<any>(0);
  const [PremissionModule, setPremissionModule] = useState<any>()
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const history = useHistory<any>();
  useEffect(() => {
    API.get("/customer/compare_module/", { project: true, module: allModule.toString() },0).then((res: any) => {
      setProjectData(res.data);
      
      // setSelectedTopVaultIds(res)
    });
  }, [projectId, allModule]);
  const projectIdsParam: any =
    projectId && typeof projectId == "string"
      ? projectId?.split(",")?.map(Number)
      : [projectId];
  useEffect(() => {
    setProjectIdInfo(projectIdsParam);
  }, [projectId]);

  React.useEffect(() => {
    if (ProjectIdInfo?.length > 0) {
      setLoaderProduct(true);
      API.get("/customer/compare_module/", {
        project: ProjectIdInfo.toString(),
        product_list: true,
        module: allModule.toString(),
        path: pathname
      },0)
        .then((res: any) => {
          setAllProductData(res.data);
          setSelectedTopVaultIds(res.data.map((item: any, index: any) => item?.id))
          console.log(res?.data)


          setLoaderProduct(false);
        })
        .catch((err: any) => {
          setLoaderProduct(false);
          console.log("compare error");
        });
    }
  }, [ProjectIdInfo, allModule]);
  const backButtonHandler: any = () => {
    history.push(`/landing`);
  };
  const topVaultsParam: any =
    topVaultId && typeof topVaultId == "string"
      ? topVaultId?.split(",")?.map(Number)
      : [topVaultId];
  useEffect(() => {
    setSelectedTopVaultIds(topVaultsParam);
  }, [topVaultId]);
  const selectAllProduct = (productIds: any) => {
    if (selectedTopVaultIds?.includes(productIds)) {
      setSelectedTopVaultIds((prev: any) => prev?.filter((item: any) => item !== productIds)
      );
      setCounter(Counter + 1);
    } else {
      setSelectedTopVaultIds((prev: any) => [...prev, productIds]);
      setCounter(Counter + 1);
    }
  };


  const selectedProject = (projectIds: any, projectname: any) => {
    // setSelectedTopVaultIds([]);
    // @ts-ignore
    if (ProjectIdInfo?.includes(projectIds)) {
      setProjectIdInfo((prev: any) => prev.filter((item: any) => item !== projectIds)
      );
      var filteeProductIds = AllProductData.filter((item: any, index: any) => item.project__name == projectname).map((item1: any, index1: any) => item1?.id)
      var projectlevelTopvaults = selectedTopVaultIds.filter((item: any, index: any) => {

        if (filteeProductIds?.includes(item) == false) {
          return item
        }


      });
      setSelectedTopVaultIds(projectlevelTopvaults)



      if (ProjectIdInfo?.length == 1) {
        setSelectedTopVaultIds([])
        setAllProductData([])
      }

    } else {
      console.log(projectIds)

      //  @ts-ignore
      setProjectIdInfo((prev: any) => [...prev, projectIds]);

    }
  };





  const selectedModule = (moduleId: any) => {
    setSelectedTopVaultIds([])
    setProjectIdInfo([])
    setAllProductData([])
    if (allModule.includes(moduleId)) {
      setallModule((prev: any) => prev.filter((item: any) => item !== moduleId)
      );
      setCounter(Counter + 1);
    } else {
      setallModule((prev: any) => [moduleId]);
      setCounter(Counter + 1);
    }
  };
  const toggleDrawer =
    (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" && ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }


      API.get("customer/compare_module_permission/").then((res: any) => {

        setPremissionModule(res.data)


      }).catch((err: any) => {

        console.log("Sever Error")

      })

      setState({ ...state, [anchor]: open });
    };


  const getselectedBackgroundColor = (idd: any) => { if (selectedTopVaultIds?.includes(idd)) { return "#2b9cf6eb"; } else { return "primary.light"; } };
  const getSelectedTextColor = (idd: any) => {
    if (selectedTopVaultIds?.includes(idd)) {
      return "primary.light";
    } else {
      return "primary.main";
    }
  };
  const getselectedProjectBackgroundColor = (idd: any) => {
    // @ts-ignore
    if (ProjectIdInfo?.includes(idd)) {
      return "primary.main";
    } else {
      return "primary.light";
    }
  };
  const getSelectedProjectTextColor = (idd: any) => {
    // @ts-ignore
    if (ProjectIdInfo?.includes(idd)) {
      return "primary.light";
    } else {
      return "primary.main";
    }
  };
  const getselectedModuleBackgroundColor = (idd: any) => {
    if (allModule?.includes(idd)) {
      return "primary.main";
    } else {
      return "primary.light";
    }
  };
  const getSelectedModuleTextColor = (idd: any) => {
    if (allModule?.includes(idd)) {
      return "primary.light";
    } else {
      return "primary.main";
    }
  };
  const formInputField = (e: any) => {
    setallModule(e?.target?.value);
  };
  const SearchViewHandler = () => {
    setCounter(Counter + 1)
    sessionStorage.setItem("Comparenode", JSON.stringify(["vehicle"]));
    var IdFilter =
      selectedTopVaultIds && selectedTopVaultIds.filter((item: any) => item !== 0);
    history.push(
      `/newcompare/${ProjectIdInfo}/${allModule}/${IdFilter.toString()}/product/${0}/productassembly/${Counter}/vehicle/${false}/${0}/null`
    );
  };
  const allModuleName = PremissionModule && PremissionModule.length > 0 && PremissionModule.map((item: any, index: any) => {
    return ({
      id: item == "Costing" ? 2 : index + 1,
      name: item
    })
  })

  // [
  //   { id: 1, name: "BOM" },
  //   { id: 2, name: "Costing" },
  // ];
  const list = (anchor: Anchor) => (
    <Box
      sx={{ padding: '0 0.5rem', minWidth: { lg: 220, xl: 300 }, maxWidth: '30vw', width: anchor === "top" || anchor === "bottom" ? "auto" : "auto" }}
      role="presentation"
    >
      <Box sx={{ width: "100%", padding: '0 1rem', height: { lg: '95vh', xl: '95vh' } }}
        adl-scrollbar="true"
        adl-scrollbar-width="0.3">
        <Box sx={{ width: "100%" }}>
          <Typography sx={{ fontSize: '0.75rem', margin: '0.5rem 0 0.1rem' }}>Select Module*</Typography>
          <Box sx={{ display: "flex", alignItems: 'center' }}>
            {allModuleName && allModuleName?.map((item: any, index: any) => {
              return (
                <Box
                  sx={{
                    alignItems: "center",
                    width: "100%",
                  }}
                >
                  {/* <Checkbox
                        checked={allModule?.includes(item?.id) ? true : false}
                        onClick={() => selectedModule(item?.id)}
                        inputProps={{ "aria-label": "controlled" }}
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                      /> */}
                  <Box
                    onClick={() => selectedModule(item?.id)}
                    key={index}
                    sx={{
                      padding: "0.2rem 0.5rem",
                      backgroundColor: getselectedModuleBackgroundColor(
                        item.id
                      ),
                      color: getSelectedModuleTextColor(item.id),
                      textAlign: "center",
                      cursor: "pointer",
                      width: "100%",
                    }}
                  >
                    <Typography style={{ fontSize: "0.87rem" }}>
                      {item?.name}
                    </Typography>
                  </Box>
                </Box>
              );
            })}
          </Box>
        </Box>
        <Box sx={{ width: "100%" }}>
          <Typography
            style={{
              width: "100%",
              fontSize: "0.75rem",
              margin: '0.5rem 0 0rem'
            }}
          >
            *Select Project /  Products
          </Typography>
          {ProjectData && ProjectData?.map((item: any, index: any) => {
            return (
              <>
                <Box>
                  <Box sx={{ alignItems: "center", display: "flex" }}>
                    <Checkbox
                      style={{ padding: '3px' }}
                      checked={
                        // @ts-ignore
                        ProjectIdInfo?.includes(item?.id) ? true : false
                      }
                      onClick={() => selectedProject(item?.id, item?.name)}
                      inputProps={{ "aria-label": "controlled" }}
                      sx={{ "& .MuiSvgIcon-root": { fontSize: { lg: '24', xl: '30' } } }}
                    />                          <Box
                      onClick={() => selectedProject(item?.id, item?.name)}
                      key={index}
                      // className={styles.selectVehBtn}
                      sx={{
                        // margin: "0rem 2rem",
                        padding: "0.2rem 0.5rem",
                        backgroundColor:
                          getselectedProjectBackgroundColor(item.id),
                        color: getSelectedProjectTextColor(item.id),
                        textAlign: "left",
                        cursor: "pointer",
                        // display: "inline-block",
                        width: "100%",
                      }}
                    >
                      <Typography style={{ fontSize: "0.87rem" }}> {item?.name}</Typography>
                    </Box>
                  </Box>
                  <Box sx={{ marginLeft: '2rem' }}>                          {AllProductData.filter(
                    (item1: any, index1: any) => item1?.project__name == item?.name
                  ).map((item2: any, index2: any) => {
                    return (
                      <>
                        <Box
                          sx={{ alignItems: "center", display: "flex" }}
                        >
                          <Checkbox
                            style={{ padding: '3px' }}
                            checked={
                              selectedTopVaultIds?.includes(item2?.id)
                                ? true
                                : false
                            }
                            onClick={() => selectAllProduct(item2?.id)}
                            inputProps={{ "aria-label": "controlled" }}
                            sx={{
                              "& .MuiSvgIcon-root": { fontSize: { lg: '24', xl: '30' }, color: "#2b9cf6eb", },
                            }}
                          />
                          <Box
                            onClick={() => selectAllProduct(item2?.id)}
                            key={index2}
                            // className={styles.selectVehBtn}
                            sx={{
                              // margin: "0rem 2rem",
                              padding: "0.2rem 0.5rem",
                              backgroundColor:
                                getselectedBackgroundColor(item2.id),
                              color: getSelectedTextColor(item2.id),
                              textAlign: "left",
                              cursor: "pointer",
                              // display: "inline-block",
                              width: "100%",
                            }}
                          >
                            <Typography style={{ fontSize: "0.87rem" }}>
                              {item2?.name}
                            </Typography>
                          </Box>
                        </Box>
                      </>);
                  })}

                  </Box>
                </Box>
              </>);
          })}
        </Box>
        <Box sx={{ position: 'sticky', bottom: '0', backgroundColor: 'aliceblue' }}>
          {ProjectIdInfo?.length < 0 && (
            <Typography
              sx={{
                fontSize: "0.87rem",
                color: "#d32f2f",
                marginTop: "0.4rem",
                marginLeft: "1.45rem",
              }}
            >
              {"*Please select less then 1 Products"}
            </Typography>)}
          <Box sx={{ width: "100%" }}>
            <Typography
              style={{
                width: "100%",
                fontSize: "1rem",
              }}
            >              {/* Select Products* */}
            </Typography>
            {loaderProduct ? (
              <>
                <Box sx={{ display: "flex" }}>
                  <CircularProgress />
                </Box>
              </>) : (
              <>                {AllProductData && AllProductData.length > 0 ? (
                <>                    {selectedTopVaultIds?.length < 2 && (
                  <Typography
                    style={{
                      fontSize: "0.87rem",
                      color: "#d32f2f",
                      marginTop: "0.4rem",
                      marginLeft: "1.45rem",
                    }}
                  >
                    {"*Please select atleast 2 Product"}
                  </Typography>)}
                  {selectedTopVaultIds.length > 5 && (
                    <Typography
                      style={{
                        fontSize: "0.87rem",
                        color: "#d32f2f",
                        marginTop: "0.4rem",
                        marginLeft: "1.45rem",
                      }}
                    >
                      {"*Please select maximum 5 Products"}
                    </Typography>)}
                </>) :
                ""
              }
              </>)}
          </Box>
          <Box style={{ textAlign: "right", marginBottom: "1rem" }}>
            <Button
              // className={styles.drawBtn}
              sx={{ marginRight: "1rem", fontSize: '0.87rem' }}
              variant="contained"
              size="small"
              onClick={toggleDrawer(anchor, false)}
            >
              Close
            </Button>
            <Button
              onClick={SearchViewHandler}
              disabled={
                selectedTopVaultIds?.length < 2 ||
                selectedTopVaultIds?.length >= 6
              }
            >
              <Button
                // className={styles.drawBtn}
                variant="contained"
                onClick={toggleDrawer(anchor, false)}
                size="small"
                sx={{ fontSize: '0.87rem' }}
                disabled={
                  selectedTopVaultIds?.length < 2 ||
                  selectedTopVaultIds?.length >= 6
                }
              >
                Ok
              </Button>
            </Button>
          </Box>
        </Box>
      </Box>



    </Box>);
  return (
    <>
      <Box sx={{ display: "flex", height: "100%" }}>
        <Box sx={{ height: "100%" }} >
          <Box sx={{ width: "3rem", padding: "0 0.5rem", marginTop: "0.5rem" }}>
            <IconButton sx={{ cursor: "pointer" }} onClick={backButtonHandler}>
              <ArrowBackIcon color="primary" titleAccess="Go Back" />
            </IconButton>
          </Box>
          <Box className={styles.expandIconBox}>
            <div className={styles.iconSec}>
              {(["left"] as const).map((anchor) => (
                <React.Fragment key={anchor}>
                  <IconButton
                    size="medium"
                    sx={{
                      backgroundColor: "primary.light",
                      color: "primary.main",
                    }}
                    onClick={toggleDrawer(anchor, true)}
                  >
                    <AddIcon sx={{ fontSize: "1.7rem" }} />
                  </IconButton>
                  <Drawer
                    anchor={anchor}
                    open={state[anchor]}
                    onClose={toggleDrawer(anchor, false)}
                    className={styles.drawerContainer}
                    PaperProps={{
                      sx: {
                        backgroundColor: "aliceblue",
                      },
                    }}
                  >
                    <Box
                      sx={{ padding: "0.5rem 0rem", paddingBottom: '0' }}>
                      <Typography style={{ width: "100%", fontSize: '1rem', textAlign: "center" }}>
                        Select Module/Projects/Products
                      </Typography>
                      <Divider sx={{ margin: "0.2rem", }} />
                      {list(anchor)}
                    </Box>
                  </Drawer>
                  <Typography onClick={toggleDrawer(anchor, true)} className={styles.sideTitle}>
                    Select Module/Projects/Products
                  </Typography>
                </React.Fragment>))}
            </div>
          </Box>
        </Box>
        {/* <Box> */}
        <NewcompareLandingPage />
        {/* </Box> */}
      </Box>
    </>);
};
export default ComparedMainPage;