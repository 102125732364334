import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import topVaultService from "../Services/topVault.service";
import { ID } from "../../utlis/commonInterfaces";
import { Vault } from "../Reducers/vault.reducer";
// import { Vault } from "../Reducers/vault.reducer";

// -------------------------------------------------------------------------------------
// api actions
// -------------------------------------------------------------------------------------

export interface GetSubassembliesArg {
    topVaultIds: ID[];
    module: number;
}
const getSubassembies = createAsyncThunk(
    "vault/getSubassemblies",
    async ( arg: GetSubassembliesArg, { rejectWithValue } ) => {
        try {
            const { data } = await topVaultService.getSubassemblies(
                arg.topVaultIds,
                arg.module
            );

            return {
                vaults: data as Vault[],
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);
//competitor Models
const getCompetitorModels = createAsyncThunk(
    "idea/competitorModel",
    async ( arg: { subsystemId: ID; }, { rejectWithValue } ) => {
        try {
            const { data } = await topVaultService.getCompetitorModel( arg.subsystemId );
            return {
                ideaCompetitor: data as any,
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);
//competitor vaults
const getCompetitorVaults = createAsyncThunk(
    "idea/competitorVaults",
    async ( arg: { subsystemId: ID, originId: number; }, { rejectWithValue } ) => {
        try {
            const { data } = await topVaultService.getCompetitorVault( arg.subsystemId, arg.originId );
            return {
                vaults: data as Vault[],
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);


const getCompetitorVaultsList = createAsyncThunk(
    "idea/competitorVaultsList",
    async ( arg: { top_vault: ID, abbreviation: string; origin: number }, { rejectWithValue } ) => {
        try {


            const { data } = await topVaultService.getCompetitorVaultList( arg.top_vault, arg.abbreviation, arg.origin );
            return {
                vaults: data as Vault[],
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);
// -------------------------------------------------------------------------------------
// reducer actions
// -------------------------------------------------------------------------------------

const resetVaults = createAction( "vault/reset" );
const setVaults = createAction<Vault[]>( "vault/set" );

// -------------------------------------------------------------------------------------
// exports
// -------------------------------------------------------------------------------------

export {
    getSubassembies,
    setVaults,
    resetVaults,
    getCompetitorModels,
    getCompetitorVaults,
    getCompetitorVaultsList
};
