import {
	Box,
	Checkbox,
	Chip,
	Typography,
	Divider,
	Skeleton,
} from '@mui/material';
import * as React from 'react';
import Button, { ButtonProps } from "@mui/material/Button";
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import InfoIcon from '@mui/icons-material/Info';
import { useRouteParams } from '../../ui-reusable-component/useRouteParams';
import { useHistory } from 'react-router-dom';
import AddAssumptionCardModal from './AddAssumptionCardModal';
import { API } from '../../api-services';
import { useBaseParamsModal } from '../../CustomHook/useBaseParamsModal';
import { deleteSwal } from '../../utlis/deleteSwal';
import { AssumptionUsedDetails } from './AssumptionUsedDetails';
import VerifiedIcon from '@mui/icons-material/Verified';
// import styles from "../../ComponentUI/BOMCreate/BOMEntry/BOMEntryTreeSection.module.scss";
import SearchIcon from '@mui/icons-material/Search';
import { debounce } from 'lodash';
import { Tabs, Tab } from '@mui/material';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import styles from './AssumptionCard.module.scss';
import rightArrow from '../../Assets/images/rightArrows.svg';
import rightArrowBrown from '../../Assets/images/rightArrowBrown.svg';
import {
	DataGrid,
	GridToolbarContainer,
	GridToolbarExportContainer,
	GridCsvExportMenuItem,
	GridCsvExportOptions,
	GridToolbarColumnsButton,
  } from "@mui/x-data-grid";

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 'auto',
	minWidth: '80vw',
	bgcolor: 'background.paper',
	borderRadius: '10px',
	p: 1,
};

interface IAssumptionCardProps {
	// isASCDopen: any;
	// isASCDclose: any;
	products?: any;
}

function AssumptionCard(props: IAssumptionCardProps) {
	const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
	const { projectId, projectname, productId, tab,  } = useRouteParams<any>();
	const { products } = props;
	const assumptionUsedDetailsModal = useBaseParamsModal();
	const addAssumptionModal = useBaseParamsModal();
	const [assumptionsData, setAssumptionsData] = React.useState<any>([]);
	const [costMain, setCostMain] = React.useState<any>(true);
	const [costSenario, setCostSenario] = React.useState<any>(true);
	const [assumptionsDataScenario, setAssumptionsDataScenario] =
		React.useState<any>([]);
	const [isassumptionLoading, setIsassumptionLoading] =
		React.useState<any>(false);
	const [counter, setCounter] = React.useState<number>(0);
	const [useraction, setuseraction] = React.useState<any>(null);
	const [selectedValue, setSelectedValue] = React.useState<any>(null);
	const [searchValue, setSearchValue] = React.useState<any>([]);
	const inputRef = React.useRef<any>(null);
	const descRef = React.useRef<any>(null);
	const OuterRef = React.useRef<any>(null);
	
	// const [value, setValue] = React.useState<any>(tab);
	const [totalCount, setTotalCount] = React.useState<number>(0);
	const [currentPage, setCurrentPage] = React.useState<number>(0);
	const history = useHistory();
	const handleChange = (newValue: any) => {
		if (newValue == 1) {
			history.push(`/costingconfiguration/${projectId}/${projectname}/assumptioncard/${productId}/${newValue}/${costMain}/${costSenario}`)
			getAssumptionsData(newValue);
		} else {
			history.push(`/costingconfiguration/${projectId}/${projectname}/assumptioncard/${productId}/${newValue}/${costMain}/${costSenario}`)
			getAssumptionsDataScenario(newValue);

		}
		// setValue(newValue);
		setSelectedValue('');
		
	};

	
	React.useEffect(() => {

		if(productId !== 0){
		API.get(
			"auth/calibration_user_permission/",
			{
			  costing_assumption: true,
			  action: true,
			  top_vault: productId
			}, 0
			
		  )
		  .then((res:any) => {
			setCostMain(res.data.cost_main);
			setCostSenario(res.data.cost_scenario);
			  history.push(`/costingconfiguration/${projectId}/${projectname}/assumptioncard/${productId}/1/${res.data.cost_main === true ? true : false}/${res.data.cost_scenario === true ? true : false}`);
			 
		  })
		  .catch((err: any) => {console.log(err)})
	

	}
	else{
		history.push(`/costingconfiguration/${projectId}/${projectname}/assumptioncard/0/1/true/true`);
	}
}, [productId]);

	
	// const { isASCDopen, isASCDclose } = props;
	const getAssumptionsData = (tab: any) => {
		setIsassumptionLoading(true);
		
			API.get(
				'/cost/assumption_card/',
				{
					top_vault: productId,
					assumption_type: tab,
					page: currentPage+1,
				},
				0
			)
				.then((res: any) => {
					setAssumptionsData(res.data);
					setIsassumptionLoading(false);
				})
				.catch((err: any) => {
					setIsassumptionLoading(false);
				});
		
	};

	// 3. create a function that will redirect to a different page
	// Create a function to handle the redirect
	// let tabValue = value=="main" ? 1 : 2;

	const handleRedirect = (
		item: any,
		name: string,
		id: string,
		revisionId: string
	): void => {
		// Create a routes object to map the name of the item to the url
		const routes: { [key: string]: string } = {
			'Raw Material': 'rm',
			'Bought-Out Parts': 'bop',
			'Machine Hour Rate': 'machine',
			'Labor Hour Rate': 'labor',
			'Tooling Cost DB': 'tools',
			Operations: 'operations',
		};

		// Create a url string with the revisionId, id, and route name
		// let tabValue = value=="main" ? 1 : 2;
		const url: string = `#/data-base/revision-database/${tab}/${item?.year}/${item?.month}/${revisionId}/${id}/${routes[name]}/0/false/0/0`;
		window.open(url, '_blank');
		// Use the history object to push the url to the application
		// history.push(url);
	};

	const handleOnChange = async (event: any) => {
		setSearchValue(event.target.value);
		
		// var DataSplit = event?.target?.value?.split("(")?.shift();
		// inputRef.current.style.display = "block";
		const assumptionValue = tab == 1 ? 1 : 2;
		if (event?.target?.value?.length > 0) {
			ExactDebounce(event.target.value, productId, assumptionValue);
		} else {
			if (assumptionValue === 1) {
				getAssumptionsData(tab);
			} else {
				getAssumptionsDataScenario(tab);
			}
		}
	};

	const ExactDebounce = React.useRef(
		// GET: /cost/assumption_card/?top_vault=48&assumption_type=1&search_key=Hosur
		debounce(async (criteria: any, tv: any, assumptionValue: number) => {
			setIsassumptionLoading(true);
			await API.get('/cost/assumption_card/', {
				search_key: criteria,
				top_vault: tv,
				assumption_type: assumptionValue,
			}).then((res: any) => {
				setAssumptionsData(res.data);
				setIsassumptionLoading(false);
			});
		}, 500)
	).current;

	React.useEffect(() => {
		API.get(
			'/auth/calibration_user_permission/',
			{
				action: true,
				configuration: true,
				project: projectId,
				module: 'Costing Configuration',
			},
			0
		)
			.then((res: any) => {
				setuseraction(res?.data?.action);
				// sessionStorage.setItem("ConfigPermission", res.data.action);
			})
			.catch((err: any) => {});
	}, []);

	const getAssumptionsDataScenario = (tab: any) => {
		setIsassumptionLoading(true);
		API.get(
			'/cost/assumption_card/',
			{
				top_vault: productId,
				assumption_type: tab,
				page: currentPage + 1,
			},
			0
		)
			.then((res: any) => {
				setAssumptionsData(res.data);
				setIsassumptionLoading(false);
			})
			.catch((err: any) => {
				setIsassumptionLoading(false);
			});
	};
	React.useEffect(() => {
		if (productId != 0) {
			if(tab == 1){
				getAssumptionsData(tab);
			}
			else{
				getAssumptionsDataScenario(tab);
			}
			// getAssumptionsData();
		}
	}, [productId, counter]);

	const getTotalItemsCount = () => {
		API.get('/cost/assumption_card/', {
			top_vault: productId,
			page_count: true,
			assumption_type: tab == 1 ? 1 : 2,
		}).then((res: any) => {
			setTotalCount(Math.ceil(res.data.total_count));
		});
	};

	const handlePageChange = (
		event: React.ChangeEvent<unknown>,
		value: number
	) => {
		// console.log(value);
		setCurrentPage(value);
	};

	React.useEffect(() => {
		getTotalItemsCount();
		setCurrentPage(0);
	}, [productId, tab,counter]);

	React.useEffect(() => {
		if (tab == 1) {
			getAssumptionsData(tab);
		} else {
			getAssumptionsDataScenario(tab);
		}
	}, [currentPage]);

	const columns: any = [
		{
			field: "action",
			headerName: "Action",
			sortable: false,
			width: 200,
			headerAlign: "center",
			align: "center",
			renderCell: (cellValues: any) => {
			  return (
				<Box sx={{display: "flex", gap: "10px"}}>
				  {useraction?.includes('U') && (
												  <Box>
													  <Checkbox
														  title='Validate'
														  {...label}
														  style={{ padding: '0px' }}
														  checked={cellValues?.row?.validation}
														  onChange={() => {
															  API.put(
																  `/cost/assumption/${cellValues?.row?.id}/`,
																  {
																	  validation: !cellValues?.row?.validation,
																  },
																  {},
																  0
															  ).then((res: any) => {
																  getAssumptionsData(tab);
															  });
														  }}
														  checkedIcon={
															  <Box
																  sx={{
																	  backgroundColor: 'white',
																	  borderRadius: '50%',
																	  height: '1rem',
																	  width: '1rem',
																  }}>
																  <VerifiedIcon
																	  sx={{
																		  color: '#39FF14',
																		  position: 'relative',
																		  margin: '-0.35rem -0.25rem 0',
																	  }}
																  />
															  </Box>
														  }
														  sx={{
															  color: 'primary.main',
														  }}
													  />
												  </Box>
											  )}
  
											  {useraction?.includes('U') && (
												  <EditIcon
													  titleAccess='Edit Assumption Card'
													  sx={{ cursor: 'pointer' }}
													  onClick={() =>
														  addAssumptionModal.open({
															  ...cellValues?.row,
															  ...{ assumptionType:  tab == 1 ? "main" : "scenario"  },
														  })
													  }
												  />
											  )}
  
											  {cellValues?.row?.map_status && (
												  <InfoIcon
													  titleAccess='Assumption Info'
													  sx={{ cursor: 'pointer' }}
													  onClick={() =>
														  assumptionUsedDetailsModal.open(cellValues?.row?.id)
													  }
												  />
											  )}
  
											  {!cellValues?.row?.map_status && useraction?.includes('D') && (
												  <CloseIcon
													  titleAccess='Delete Assumption Card'
													  sx={{ cursor: 'pointer', color: 'red' }}
													  onClick={async () => {
														  const confirm: any = await deleteSwal(
															  'Delete Assumption'
														  );
														  if (confirm) {
															  API.delete(`/cost/assumption/${cellValues?.row?.id}/`, {}, 0)
																  .then((res: any) => {
																	  setCounter((prev: any) => prev + 1);
																  })
																  .catch((err: any) => {});
														  }
													  }}
												  />
											  )}
				</Box>
			  );
			},
		  },
		{ field: "revision_name", headerName: "Revision Name", width: 220 },
		{
		  field: "name",
		  headerName: "Name",
		  width: 220,
		},
		{
		  field: "volume",
		  headerName: "Volume",
		  width: 150,
		},
		{
		  field: "created_at",
		  headerName: "Created At",
		  width: 150,
		},
		{
		  field: "revised_on",
		  headerName: "Revised On",
		  width: 150,
		},
		{
		  field: "created_by",
		  headerName: "Created By",
		  width: 150,
		},
		{
		  field: "total_cost",
		  headerName: "Total Cost",
		  width: 80,
		  sortable: false,
		  headerAlign: "center",
		  align: "center",
		  renderCell: (cellValues: any) => {
			return (
			  <Box>
				<img
				  src={tab == 1 ? rightArrow : rightArrowBrown}
				  alt="rightArrow"
				  style={{ height: "1.4rem", cursor: "pointer" }}
				  onClick={() =>
					handleRedirect(
					  cellValues.row,
					  cellValues.row.data[0].name,
					  cellValues.row.data[0].id,
					  cellValues.row.revision_id
					)
				  }
				/>
			  </Box>
			);
		  },
		},
	
		{
		  field: "mh_rate",
		  headerName: "MHR",
		  width: 80,
		  sortable: false,
		  headerAlign: "center",
		  align: "center",
		  renderCell: (cellValues: any) => {
			return (
			  <Box>
				<img
				  src={tab == 1 ? rightArrow : rightArrowBrown}
				  alt="rightArrow"
				  style={{ height: "1.4rem", cursor: "pointer" }}
				  onClick={() =>
					handleRedirect(
					  cellValues.row,
					  cellValues.row.data[1].name,
					  cellValues.row.data[1].id,
					  cellValues.row.revision_id
					)
				  }
				/>
			  </Box>
			);
		  },
		},
		{
		  field: "raw_material",
		  headerName: "RM",
		  width: 80,
		  sortable: false,
		  headerAlign: "center",
		  align: "center",
		  renderCell: (cellValues: any) => {
			return (
			  <Box>
				<img
				  src={tab == 1 ? rightArrow : rightArrowBrown}
				  alt="rightArrow"
				  style={{ height: "1.4rem", cursor: "pointer" }}
				  onClick={() =>
					handleRedirect(
					  cellValues.row,
					  cellValues.row.data[2].name,
					  cellValues.row.data[2].id,
					  cellValues.row.revision_id
					)
				  }
				/>
			  </Box>
			);
		  },
		},
		{
		  field: "lh_rate",
		  headerName: "LHR",
		  width: 80,
		  sortable: false,
		  headerAlign: "center",
		  align: "center",
		  renderCell: (cellValues: any) => {
			return (
			  <Box>
				<img
				  src={tab == 1 ? rightArrow : rightArrowBrown}
				  alt="rightArrow"
				  style={{ height: "1.4rem", cursor: "pointer" }}
				  onClick={() =>
					handleRedirect(
					  cellValues.row,
					  cellValues.row.data[3].name,
					  cellValues.row.data[3].id,
					  cellValues.row.revision_id
					)
				  }
				/>
			  </Box>
			);
		  },
		},
		{
		  field: "bop",
		  headerName: "BOP",
		  width: 80,
		  sortable: false,
		  headerAlign: "center",
		  align: "center",
		  renderCell: (cellValues: any) => {
			return (
			  <Box>
				<img
				  src={tab == 1 ? rightArrow : rightArrowBrown}
				  alt="rightArrow"
				  style={{ height: "1.4rem", cursor: "pointer" }}
				  onClick={() =>
					handleRedirect(
					  cellValues.row,
					  cellValues.row.data[5].name,
					  cellValues.row.data[5].id,
					  cellValues.row.revision_id
					)
				  }
				/>
			  </Box>
			);
		  },
		},
		{
		  field: "operations",
		  headerName: "Operations",
		  width: 80,
		  sortable: false,
		  headerAlign: "center",
		  align: "center",
		  renderCell: (cellValues: any) => {
			return (
			  <Box>
				<img
				  src={tab == 1 ? rightArrow : rightArrowBrown}
				  alt="rightArrow"
				  style={{ height: "1.4rem", cursor: "pointer" }}
				  onClick={() =>
					handleRedirect(
					  cellValues.row,
					  cellValues.row.data[4].name,
					  cellValues.row.data[4].id,
					  cellValues.row.revision_id
					)
				  }
				/>
			  </Box>
			);
		  },
		},
		{
		  field: "currency",
		  headerName: "Currency",
		  width: 80,
		  sortable: false,
		  headerAlign: "center",
		  align: "center",
		  renderCell: (cellValues: any) => {
			return (
			  <Box>
				<img
				  src={tab == 1 ? rightArrow : rightArrowBrown}
				  alt="rightArrow"
				  style={{ height: "1.4rem", cursor: "pointer" }}
				  onClick={() =>
					handleRedirect(
					  cellValues.row,
					  cellValues.row.data[6].name,
					  cellValues.row.data[6].id,
					  cellValues.row.revision_id
					)
				  }
				/>
			  </Box>
			);
		  },
		},
		
	  ];


  const csvOptions: GridCsvExportOptions = {
    fileName: "Revision Table In CSV",
    delimiter: ",",
    utf8WithBom: true,
  };
  
  const CustomExportButton = (props: ButtonProps) => (
    <GridToolbarExportContainer {...props}>
      <GridCsvExportMenuItem options={csvOptions} />
    </GridToolbarExportContainer>
  );
  
  const CustomToolbar = () => (
    <GridToolbarContainer
      sx={{ height: "2.5rem", justifyContent: "flex-end", paddingRight: "1rem" }}
    >
      <span
      // style={{ position: "absolute", right: "0rem" }}
      >
        <GridToolbarColumnsButton />
        <CustomExportButton />
      </span>
    </GridToolbarContainer>
  );

	return (
		<div>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'space-between',
					padding: '0.5rem',
				}}>
				<Box
					sx={{ display: 'flex', alignItems: 'center', padding: '0 0.5rem' }}>
					{products &&
						products?.map((item: any) => {
							return (
								<Box
									sx={
										item.id !== productId
											? {
													// backgroundColor: "primary.light",
													color: 'primary.main',
													padding: '0.2rem 1rem',
													boxShadow:
														'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px',
													textAlign: 'center',
													marginRight: '1rem',
													cursor: 'pointer',
													whiteSpace: 'nowrap',
													width: 'fit-content',
													borderRadius: '5px',
													'&:hover': {
														transform: 'Scale(1.05)',
														transition: 'transform 0.5s ease',
													},
											  }
											: {
													backgroundColor: 'primary.main',
													color: 'white',
													padding: '0.2rem 1rem',
													boxShadow:
														'rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px',
													textAlign: 'center',
													marginRight: '1.5rem',
													width: 'fit-content',
													cursor: 'pointer',
													whiteSpace: 'nowrap',
													borderRadius: '5px',
													'&:hover': {
														transform: 'Scale(1.05)',
														transition: 'transform 0.5s ease',
													},
											  }
									}
									onClick={() => {
										history.push(
											`/costingconfiguration/${projectId}/${projectname}/assumptioncard/${item.id}/1/${costMain}/${costSenario}`
										);
										// setValue('main');
										setCurrentPage(1);
										
									}}>
									<Typography sx={{ fontSize: '1rem' }}>
										{item?.name}
									</Typography>
								</Box>
							);
						})}
				</Box>
			</Box>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'start',
					width: '100%',
					padding: '0.5rem 1rem',
					columnGap: { lg: '1rem', xl: '2rem' },
				}}>
				<Box sx={{ width: '100%' }}>
					<Box
						sx={{
							width: '100%',
							display: 'flex',
							alignItems: 'flex-end',
              				columnGap: '1rem',
							padding: '0.5rem 0',
						}}>
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								columnGap: '0.5rem',
								width: {lg:'81%',xl:'83%'},
								borderBottom: '1px solid',
							}}>
							{/* {ActionData && ActionData?.revision_main && */}
							{(costMain === true) && <Box
								sx={{
									color: '#007fff',
									height: '2rem',
									minHeight: '2.1rem',
									maxWidth: '100% !important',
									padding: '0',
									fontSize: '1rem',
									width: '100%',
									backgroundColor: tab == 1 ? 'primary.light' : 'white',
									cursor: 'pointer',
									display: 'flex',
									alignItems: 'center',
								}}>
								<Typography
									sx={{
										color: '#007fff',
										height: '2rem',
										minHeight: '2.1rem',
										maxWidth: '100% !important',
										padding: '0',
										fontSize: '1rem',
										width: '100%',
										textAlign: 'center',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
									}}
									onClick={() => handleChange(1)}>
									Main Costing Assumptions
								</Typography>
								{tab == 1 && (
									<AddIcon
										sx={{ cursor: 'pointer', margin: '0 0.5rem' }}
										onClick={() =>
											addAssumptionModal.open({ assumptionType: 'main' })
										}
									/>
								)}
							</Box>}
							{/* } */}
							{/* {ActionData && ActionData?.scenario && */}
							{(costSenario === true) && <Box
								sx={{
									color: '#833406',
									height: '2rem',
									minHeight: '2.1rem',
									maxWidth: '100% !important',
									padding: '0',
									fontSize: '1rem',
									width: '100%',
									backgroundColor: tab == 2 ? '#faebd7' : 'white',
									cursor: 'pointer',
									display: 'flex',
									alignItems: 'center',
								}}>
								<Typography
									sx={{
										color: '#833406',
										height: '2rem',
										minHeight: '2.1rem',
										maxWidth: '100% !important',
										padding: '0',
										fontSize: '1rem',
										width: '100%',
										textAlign: 'center',
										display: 'flex',
										alignItems: 'center',
										justifyContent: 'center',
									}}
									onClick={() => handleChange(2)}>
									Scenario Costing Assumptions
								</Typography>
								{tab == 2 && (
									<AddIcon
										sx={{ cursor: 'pointer', margin: '0 0.5rem' }}
										onClick={() =>
											addAssumptionModal.open({ assumptionType: 'scenario' })
										}
									/>
								)}
							</Box>}
							{/* } */}
						</Box>

						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								columnGap: '1rem',
								width: '15%',
								justifyContent: 'center',
								marginLeft:'1rem',
							}}>							
									<Box sx={{ display: 'flex', alignItems: 'flex-end', marginLeft:'1rem',borderBottom: '1px solid #007fff', backgroundColor: tab == 1 ? '#007fff14' : "#faebd7"	 }}>
										<input
											type='search'
											placeholder='Search Revison'
											value={searchValue}
											autoFocus={false}
                      						className={tab == 2 ? styles.searchBoxSenario : styles.searchBox }											
											// @ts-expect-
											ref={descRef}
											onKeyPress={(event: any) => handleOnChange(event)}
											onChange={(event: any) => handleOnChange(event)}
										/>
										<SearchIcon sx={{margin:'0 5px'}}/>
									</Box>								
							
						</Box>
					</Box>
					{/* //type 1 for main costing */}
					{/* //type 2 for search costing */}

					<Box
						sx={{ marginTop: '1rem', height: typeof searchValue === 'string' && searchValue.trim().length > 0 ? { lg: '71vh', xl: '77vh' } : { lg: '64vh', xl: '73vh' } }}
						adl-scrollbar='true'
						adl-scrollbar-width='0.3'>
						{isassumptionLoading ? (
                    <Box sx={{ width: "100%", padding: "0" }}>
                      <Skeleton sx={{ height: "4rem", width: "100%" }} />
                      <Skeleton sx={{ height: "4rem", width: "100%" }} />
                      <Skeleton sx={{ height: "4rem", width: "100%" }} />
                      <Skeleton sx={{ height: "4rem", width: "100%" }} />
                      <Skeleton sx={{ height: "4rem", width: "100%" }} />
                      <Skeleton sx={{ height: "4rem", width: "100%" }} />
                    </Box>
                  ) : (
                    <DataGrid
                      rows={assumptionsData}
                      columns={columns}
                      page= {currentPage}
					  components={{
						Toolbar: () => {
						  return CustomToolbar();
						},
					  }}
                      density={"compact"}
                      pageSize={5}
                      rowsPerPageOptions={[5]}
                      paginationMode="server"
                      disableSelectionOnClick
                      rowCount={totalCount}
                      // rows={assumptionsData}
                      getCellClassName={() => {
                        return tab == 1 ? "main" : "scenario";
                      }}
                      onPageChange={(params: any) => {
                        setCurrentPage(params);
                      }}
                    />
                  )}
					</Box>
				</Box>				
			</Box>	
					
			{/* {typeof searchValue === 'string' && searchValue.trim().length > 0 ?  '' : <Box
				sx={{
					width: '100%',
					display: 'flex',
					justifyContent: 'flex-end',
					paddingRight: '1rem',
				}}>
				<Stack spacing={2}>
					<Pagination
						// count={totalPages}
						page={currentPage}
						onChange={handlePageChange}
						showFirstButton
						showLastButton
					/>
				</Stack>
			</Box>} */}

			{addAssumptionModal.isOpen && (
				<AddAssumptionCardModal
					isOpen={addAssumptionModal.isOpen}
					onCloseModal={addAssumptionModal.close}
					nameType={addAssumptionModal.propsId}
					setCounter={setCounter}

					
				/>
			)}
			{assumptionUsedDetailsModal.isOpen && (
				<AssumptionUsedDetails
					isOpen={assumptionUsedDetailsModal.isOpen}
					onCloseModal={assumptionUsedDetailsModal.close}
					item={assumptionUsedDetailsModal.propsId}
				/>
			)}
		</div>
	);
}
export default AssumptionCard;
