import * as React from "react";
import {
  Modal,
  Divider,
  Box,
  TableCell,
  Table,
  TableBody,
  TableRow,
  TableHead,
  Button,
  TextField,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import CancelIcon from "@mui/icons-material/Cancel";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import styles from "./AddMaterialRevisionModal.module.scss";
import { DataGrid, GridRowsProp, GridColDef } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import { AssignCostModeltoAddedMaterialsModal } from "./AssignCostModeltoAddedMaterialsModal";
import { useBaseModal } from "../../SearchPage/useBaseModal";
import Chip from "@mui/material/Chip";
import Autocomplete from "@mui/material/Autocomplete";
import { API } from "../../../api-services";
import { LoadingButton } from "@mui/lab";
import Swal from "sweetalert2";
import successIcon from "../../../Assets/images/success.png";
import { useBaseParamsModal } from "../../../CustomHook/useBaseParamsModal";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";

export interface IAddMaterialRevisionModalProps {
  onCloseModal: any;
  isOpen: any;
  costDetailsData?: any;
  getRMData?: any;
  SelectedmulIds?:any;

}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: { lg: '40vw', xl: '30vw' },
  // width: "96vw",
  heigth: "80vh",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

export function AddMaterialRevisionModal(
  props: IAddMaterialRevisionModalProps
) {
  const { onCloseModal, isOpen, costDetailsData, getRMData, SelectedmulIds} = props;
  const [age, setAge] = React.useState("");
  const { vault,scenarioId } = useRouteParams<any>();
  const [categoryData, setCategoryData] = React.useState<any>([]);
  const [selectedCategory, setSelectedCategory] = React.useState<any>("");
  const [materialData, setMaterialData] = React.useState<any>([]);
  const [selectedMaterial, setSelectedMaterial] = React.useState<any>([]);
  const [highestSequence, setHighestSequence] = React.useState<any>(0);
  const getHeighestSequence = () => {
    API.get(
      "/cost/cost_calculator/",
      {
        vault: vault,
        get_level: "material_calculator",
        scenario:scenarioId?scenarioId:undefined
      },
      0
    )
      .then((res: any) => {
        setHighestSequence(res.data[0].count);
      })
      .catch((err: any) => { });
  };

  React.useEffect(() => {
    getHeighestSequence();
  }, []);

  const getCategoryData = () => {
    API.get(
      "/api/db/revision_database/",
      {
        category_type: "materialcost",
        revision_id: costDetailsData?.machine_revision,
      },
      0
    )
      .then((res: any) => {
        setCategoryData(res.data);
      })
      .catch((err: any) => { });
  };


const multipleassumption=()=>{
  if(SelectedmulIds.length>0){
    return {assumption_list:SelectedmulIds?.toString()}
  }
}
  const getMaterialData = (category: any) => {
    API.get(
      "/api/db/material_cost/",
      { revision: costDetailsData?.machine_revision, category: category,...multipleassumption() },
      0
    )
      .then((res: any) => {
        setMaterialData(res.data);
      })
      .catch((err: any) => { });
  };
  React.useEffect(() => {
    if (costDetailsData) {
      getCategoryData();
    }
  }, [costDetailsData]);

  const handleCategoryChange = (ed: any) => {
    const { value } = ed.target;
    setSelectedCategory(value);
    getMaterialData(value);
  };

  const AssignCostModal = useBaseParamsModal();
  const AssignCostModalHandler = () => {
    API.post(
      "/cost/material/",
      {
        cost_estimation: costDetailsData?.cost_estimation,
        materials: selectedMaterial.map((item: any) => item.material_id),
      },
      {},
      0
    )
      .then((res: any) => {
        getRMData();
        Swal.fire({
          html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
  <br />
  <p style="color:#007fff;"> Materials added successfully
  </p>   
   </div>`,
        });
        AssignCostModal.open(costDetailsData);
      })
      .catch((err: any) => { });
  };

  const handleSelectChangMaterial = (event: any, newSystemValue: any) => {
    setSelectedMaterial(newSystemValue);
    // let newSelectedSystem = newSystemValue.map((item: any) => item.id);
    // ExactDebounceCommodity(newSelectedSystem);
  };

  const getOptionDisabled = (option: any) => {
    return selectedMaterial.some(
      (selectedOption: any) =>
        selectedOption.material_code === option.material_code
    );
  };

  return (
    <div>
      {AssignCostModal.isOpen && (
        <AssignCostModeltoAddedMaterialsModal
          isOpen={AssignCostModal.open}
          onCloseModal={AssignCostModal.close}
          costDetailsData={AssignCostModal.propsId}
          parentClose={onCloseModal}
          getRMData={getRMData}
          SelectedmulIds={SelectedmulIds}
          selectedCategory={selectedCategory}
          revisionsName={costDetailsData?.machine_revision}

          
        />
      )}

      <Modal
        open={isOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                // textAlign: "center",
                marginLeft: "1rem",
              }}
            >
              Add Material Items
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={() => {
                API.post("/cost/cost_calculator/", {
                  material: true,
                  cost_estimation: costDetailsData?.cost_estimation,
                  scenario:scenarioId?scenarioId:undefined
                }, {}, 0).then((res: any) => {

                }).catch((err: any) => { })
                onCloseModal();
                getRMData();

              }}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ padding: "0 1rem" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
                columnGap: "1rem",
                width: "100%",
                margin: '4rem 0',
              }}
            >
              <FormControl fullWidth>
                <InputLabel shrink
                  sx={{ marginLeft: "-1rem", marginBottom: "-0.5rem",color:'primary.main' }}
                >
                  Select Material Category
                </InputLabel>
                <Select
                  variant="standard"
                  size="small"
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedCategory}
                  label="Select Material Category"
                  onChange={handleCategoryChange}
                  sx={{
                    width: "100%",
                    "&:before": {
                      borderBottomColor: "primary.main",
                    },
                    "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                    {
                      fontSize: "1rem",
                    },
                    ".MuiSvgIcon-root.MuiSelect-icon": {
                      color: "primary.main",
                    },
                  }}
                >
                  {categoryData &&
                    categoryData?.map((item: any) => {
                      return (
                        <MenuItem
                          sx={{ fontSize: "1rem" }}
                          value={item?.category}
                        >
                          {item?.category}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
              <FormControl fullWidth>
              <InputLabel shrink
                  sx={{ marginLeft: "-1rem",marginTop:'-0.75rem', color:'primary.main' }}
                >
                  Select Material
                </InputLabel>
                <Autocomplete
                  multiple
                  id="tags-standard"
                  limitTags={3}
                  disableClearable
                  disableCloseOnSelect                  
                  sx={{
                    ".MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator":
                    {
                      color: "primary.main",
                    },
                    ".MuiButtonBase-root.MuiChip-root": {
                      backgroundColor: "primary.light",
                      height: "26px",
                    },
                  }}
                  options={materialData}
                  getOptionLabel={(option: any) => option?.material_code}
                  value={selectedMaterial}
                  onChange={(event: any, newSystemValue: any) => {
                    handleSelectChangMaterial(event, newSystemValue);
                  }}
                  getOptionDisabled={getOptionDisabled}
                  renderOption={(props, option) => (
                    <li {...props} style={{ fontSize: "1rem" }}>
                      {option.material_code}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="standard"
                      // label="Select Material"
                      // placeholder="Select Material"
                      sx={{
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "primary.main",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "primary.main",
                        },
                        ".MuiInputBase-root.MuiInput-root": {
                          fontSize: "1rem !important",
                        },
                        ".MuiSvgIcon-root": { color: "primary.main" },
                      }}
                    />
                  )}
                />
              </FormControl>
            </Box>
            {/* <Box sx={{height: { lg: '56vh', xl: '62vh' }}}> */}
              {selectedMaterial && selectedMaterial?.length > 0 &&
                <Box>
                  <Box sx={{ margin: "0.5rem", width: '100%' }}>
                    <Typography sx={{ fontSize: "1rem" }}>
                      Selected Material
                    </Typography>
                  </Box>
                  <Box className={styles.selectMaterial}>
                    <Box sx={{ width: '95vw', marginBottom: "0.5rem", height: { lg: '45vh', xl: '54vh' } }}
                      adl-scrollbar="true"
                      adl-scrollbar-width="0.3"
                    >
                      <Table stickyHeader aria-label="sticky table">
                        <TableHead>
                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                                backgroundColor: "primary.light",
                                width: "3rem",
                              }}
                            >
                              Seq.
                            </TableCell>
                            {/* <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                                backgroundColor: "primary.light",
                                lineHeight: "1.2",
                                whiteSpace: "nowrap",
                              }}
                            >
                              Name
                            </TableCell> */}
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                                backgroundColor: "primary.light",
                                lineHeight: "1.2",
                                whiteSpace: 'nowrap',
                              }}
                            >
                              Material Code
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                                backgroundColor: "primary.light",
                              }}
                            >
                              Grade
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                                backgroundColor: "primary.light",
                                lineHeight: "1.2",
                              }}
                            >
                              Sub Grade
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                                backgroundColor: "primary.light",
                                lineHeight: "1.2",
                              }}
                            >
                              Initial Shape
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                                backgroundColor: "primary.light",
                              }}
                            >
                              Density
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                                backgroundColor: "primary.light",
                              }}
                            >
                              Remarks
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                                backgroundColor: "primary.light",
                                lineHeight: "1.2",
                              }}
                            >
                              Material Costing INR
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                                backgroundColor: "primary.light",
                                lineHeight: "1.2",
                              }}
                            >
                              Material Source
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                                backgroundColor: "primary.light",
                                width: { lg: "20rem", xl: "23rem" },
                                lineHeight: "1.2",
                              }}
                            >
                              Material Reference
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                                backgroundColor: "primary.light",
                                lineHeight: "1.2",
                              }}
                            >
                              Scrap Cost
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                                backgroundColor: "primary.light",
                                lineHeight: "1.2",
                              }}
                            >
                              Scrap Source
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                                backgroundColor: "primary.light",
                                lineHeight: "1.2",
                              }}
                            >
                              Scrap Reference
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottomColor: "primary.light",
                                backgroundColor: "primary.light",
                                width: "5rem",
                              }}
                            >
                              Action
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                            selectedMaterial &&
                            selectedMaterial?.map((item: any, index: any) => {
                              return (
                                <TableRow>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <TextField
                                      size="small"
                                      variant="standard"
                                      type="number"
                                      disabled={true}
                                      value={highestSequence + index + 1}
                                      sx={{
                                        width: "3rem",
                                        ".MuiInputBase-root.MuiInput-root": {
                                          fontSize: "1rem",
                                        },
                                        "& .MuiInput-underline:before": {
                                          borderBottomColor: "white",
                                        },
                                        "& .MuiInput-underline:after": {
                                          borderBottomColor: "primary.main",
                                        },
                                      }}
                                    />
                                  </TableCell>
                                  {/* <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    {`Material${highestSequence + index + 1}`}
                                  </TableCell> */}

                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    {item?.material_code}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    {item?.material_grade}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    {item?.material_subgrade}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    {item?.shape}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    {item?.density}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    {item?.description}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                      textAlign: "right",
                                    }}
                                  >
                                    {item?.material_cost}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    {item?.material_source}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                      maxWidth: '25rem',
                                      wordWrap: 'break-word',
                                    }}
                                  >
                                    {item?.material_reference}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                      textAlign: "right",
                                    }}
                                  >
                                    {item?.scrap_cost}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    {item?.scrap_source}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                      maxWidth: '25rem',
                                      wordWrap: 'break-word',
                                    }}
                                  >
                                    {item?.scrap_reference}
                                  </TableCell>
                                  <TableCell
                                    sx={{
                                      padding: "0.2rem 0.5rem",
                                      borderBottomColor: "primary.light",
                                    }}
                                  >
                                    <DeleteIcon
                                      sx={{ cursor: "pointer" }}
                                      onClick={() => {
                                        setSelectedMaterial((prev: any) =>
                                          prev.filter((item: any, i: any) => i !== index)
                                        );
                                      }}
                                    />
                                  </TableCell>
                                </TableRow>
                              );
                            })}
                        </TableBody>
                      </Table>
                    </Box>
                    <Box
                      sx={{
                        width: "100%",
                        textAlign: "right",
                        margin: "1rem 0 0.5rem",
                        paddingRight: "0.5rem",
                      }}
                    >
                      <LoadingButton
                        size="small"
                        variant="contained"
                        onClick={() => AssignCostModalHandler()}
                        sx={{
                          ".MuiCircularProgress-root": {
                            color: "primary.main",
                          },
                          "&:hover": {
                            transform: "Scale(1.05)",
                            transition: "transform 0.5s ease",
                          },
                        }}
                      >
                        Add Material
                      </LoadingButton>
                    </Box>
                  </Box>
                </Box>}
            {/* </Box> */}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
