// react
import axios from "axios";
import { MODULES } from "../../Constants/modules.constants";
import { API } from "../../api-services";
import { s3Url } from "../../utlis/s3url.utils";



const ImageS3Upload = async (
   fileList: any,
   oldUrl:any,
   parametername:any,
   setImageUploadCounter:any,
   topVaultId:any,
   setImageLoader:any,
   filename:any,
   PdfName:any

) => {
   // const file_name = file?.name;
   var random: any = Math.floor( Math.random() * 1000000 + 1 );


   var obj: Array<any> = [];
   const sortAlphaNum = ( a: any, b: any ) => a.localeCompare( b, 'en', { numeric: true } );
   var img: any = "";
   // const dispatch = useAppDispatch();
   // + "-" + uuidv4();
        setImageLoader(true)
      API.get( "cad/cad_upload/", {
         module: MODULES.CAD,
      } ).then( res => {

         const data = Array.from( fileList ).forEach( ( file: any, index: any ) => {
            const formData = new FormData();
            formData.append(
               "key",
               `inward/${parametername}/${random}/${file.name}`
            );
            formData.append( "Content-Type", file?.type );
            formData.append( "x-amz-server-side-encryption", "AES256" );
            formData.append( "X-Amz-Credential", res.data.data.x_amz_credential );
            formData.append( "X-Amz-Algorithm", "AWS4-HMAC-SHA256" );
            formData.append( "X-Amz-Date", res.data.data.x_amz_date );
            formData.append(
               "X-Amz-Signature",
               res.data.data.aws_policy_signature
            );
            formData.append( "Policy", res.data.data.aws_policy );
            formData.append( "file", file, file?.name );

            axios
               .post( s3Url, formData, {
                  onUploadProgress: data => {
                   
                     //Set the progress value to show the progress bar
                  },
               } )
               .then(  ( res: any ) => {
               //  console.log("dchidcdcidcihdicdhicdihc",res.status)
                  if ( res.status === 204 ) {
                    setImageLoader(true)

                     var filedata=file?.name==undefined?filename:file?.name;
                  //   console.log(file,"filefilefile")
                    // console.log("dchidcdcidcihdicdhicdihc",res.status)
                    API.put(
                        "/vehicle_inward/inward_view/",
                        {new_data: `/inward/${parametername}/${random}/${filedata}`,top_vault_id:topVaultId,old_data:oldUrl,parameter_name:parametername,...PdfName}).then((res:any)=>{
                        setImageUploadCounter((prev:any)=>prev+1) 
                        setImageLoader(true)
                    })
                    // `/inward/${parametername}/${random}/${file.name}`
                  }
               } );
         } );
      } );
   

};

// ----------------------------------------------------------------------------------

export default ImageS3Upload;
