import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import FormLabel from "@mui/material/FormLabel";
import { useHistory } from "react-router-dom";
import Chip from "@mui/material/Chip";
import Stack from "@mui/material/Stack";
import CancelIcon from "@mui/icons-material/Cancel";
import VerifiedIcon from "@mui/icons-material/Verified";
import {
  Checkbox,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
} from "@mui/material";
import { API } from "../../api-services";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import LoadingButton from "@mui/lab/LoadingButton";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45vw",
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 0,
};

interface ShareCostingModelProps {
  isOpen: any;
  onCloseModal: any;
}
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 180,
    },
  },
};

const ShareCostingModel = (props: ShareCostingModelProps) => {
  const { isOpen, onCloseModal } = props;
  const { projectId } = useRouteParams<any>();
  const [value, setValue] = React.useState("Delivery");
  const history = useHistory<any>();

  const [FileCount, setFileCount] = useState<any>();
  const [projectList, setProjectList] = React.useState<any>([]);
  const [selectedProject, setSelectedProject] = React.useState<any>([]);
  const [sharedProjectList, setSharedProjectList] = React.useState<any>([]);
  const [shareStatus, setShareStatus] = useState<any>({});
  const [shareCheck, setShareCheck] = useState<any>([])
  const [isLoading, setIsLoading] = React.useState<any>(false)
  const [isShareLoading, setIsShareLoading] = React.useState<any>(false)

  const getProjectList = () => {
    API.get(
      "/config/share_costing_config/",
      {
        project: projectId,
        module: 16
      },
      0
    )
      .then((res: any) => {
        setProjectList(res.data);
      })
      .catch((err: any) => { });
  };
  const getStatus = () => {
    setIsLoading(true)
    API.get("/config/share_costing_config/", {
      project: projectId,
      check_config_project: true
    }, 0).then((res: any) => {
      setShareStatus(res.data[0])
      setIsLoading(false)
    }).catch((err: any) => {
      setIsLoading(false)
    })
  }
  const handleChangeCheckBox = (type: any) => {
    if (shareCheck?.includes(type)) {
      setShareCheck((prev: any) => prev?.filter((item: any) => item !== type))
    } else {
      setShareCheck((prev: any) => [...prev, type])
    }
  }

  const getSharedProjectList = () => {
    API.get(
      "/config/share_costing_config/",
      {
        project: projectId,
        shared_project: true,
      },
      0
    )
      .then((res: any) => {
        setSharedProjectList(res.data);
      })
      .catch((err: any) => { });
  };
  React.useEffect(() => {
    getProjectList();
    getSharedProjectList();
    getStatus();
  }, []);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);
  };
  const handleSelectChangeProject = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    setSelectedProject(value);
  };

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "2.5rem",
              backgroundColor: "#007fff14",
              padding: "0.5rem 1rem",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{ display: "flex", alignItems: "center", columnGap: "5px" }}
            >
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  width: "100%",
                  textAlign: "left",
                }}
              >
                Share Costing Configuration
              </Typography>
            </Box>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer" }}
              onClick={onCloseModal}
            />
          </Box>
          <Box>
            <FormControl variant="standard" sx={{ minWidth: "100%", padding: '0 1rem', marginTop: '1rem' }}>
              <InputLabel
                id="demo-simple-select-label"
                sx={{ color: "#007fff50", marginLeft: '1rem', fontSize: '1rem' }}
              >
                Share configuration
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedProject && selectedProject}
                multiple
                multiline
                label="Share configuration"
                placeholder="Share configuration"
                onChange={handleSelectChangeProject}
                size="small"
                sx={{
                  "&:before": {
                    borderBottomColor: "primary.main",
                  },
                  ".MuiSvgIcon-root.MuiSelect-icon": {
                    color: "primary.main",
                  },
                }}
                renderValue={(selected) => (
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 0.5,
                    }}
                  >
                    {projectList
                      .filter((item: any) => selected?.includes(item?.id))
                      ?.map((value: any) => (
                        <Chip
                          size="small"
                          key={value.id}
                          label={value.name}
                          sx={{ backgroundColor: "#007fff14" }}
                          onDelete={() => {
                            if (selectedProject.length > 1) {
                              setSelectedProject((prev: any) =>
                                prev?.filter((item: any) => value.id !== item)
                              );
                              let temp = selectedProject.filter(
                                (item: any) => value.id === item
                              );
                            }
                          }}
                          deleteIcon={
                            <CancelIcon
                              onMouseDown={(event) => event.stopPropagation()}
                            />
                          }
                        />
                      ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {/* <Box><InfoIcon/></Box> */}
                {projectList &&
                  projectList?.map((item: any, index: number) => {
                    return (
                      !selectedProject?.includes(item?.id) && (
                        <MenuItem sx={{ fontSize: '1rem' }} key={index} value={item?.id}>
                          {item?.name}
                        </MenuItem>
                      )
                    );
                  })}
              </Select>
            </FormControl>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                columnGap: "1rem",
                margin: "1rem 0",
                padding: "1rem",
                justifyContent: "space-between",
              }}
            >
              {/* <Typography sx={{ fontSize: '1rem' }}> </Typography> */}
              {!isLoading ? <FormControl sx={{ display: "flex", flexDirection: "row", columnGap: '1rem', }}>
                <Box sx={{ display: "flex", alignItems: 'center', width: '10rem' }}>
                  {shareStatus && Object.keys(shareStatus)?.length > 0 && shareStatus?.calculator_config ?
                    <Checkbox onChange={() => handleChangeCheckBox("Cost Model")} checked={shareCheck?.includes("Cost Model") ? true : false}
                      checkedIcon={<Box sx={{ backgroundColor: 'white', borderRadius: '50%', height: '1rem', width: '1rem', }}>
                        <VerifiedIcon sx={{ color: '#39FF14', position: 'relative', margin: '-0.35rem -0.25rem 0', }} />
                      </Box>}
                      sx={{ width: '3rem', height: '3rem', }} /> : <Skeleton animation="wave" />}
                  <Typography sx={{ fontSize: '1rem' }}>Cost Models</Typography>
                </Box>
                <Box sx={{ display: "flex", alignItems: 'center' }}>
                  {shareStatus && Object.keys(shareStatus)?.length > 0 && shareStatus?.guidline_config && <Checkbox onChange={() => handleChangeCheckBox("Guidline")} checked={shareCheck?.includes("Guidline") ? true : false}
                    checkedIcon={<Box sx={{ backgroundColor: 'white', borderRadius: '50%', height: '1rem', width: '1rem', }}>
                      <VerifiedIcon sx={{ color: '#39FF14', position: 'relative', margin: '-0.35rem -0.25rem 0', }} />
                    </Box>}
                    sx={{ width: '3rem', height: '3rem' }} />}
                  <Typography sx={{ fontSize: '1rem' }}>Guidelines</Typography>
                </Box>               
              </FormControl> : <Skeleton animation="wave" sx={{ width: '100vh' }} />}
              <LoadingButton
                size="small"
                variant="contained"
                loading={isShareLoading}
                sx={{
                  cursor: "pointer",
                  '.MuiCircularProgress-root': {
                    color: 'primary.main',
                  },
                  "&:hover": {
                    transform: 'Scale(1.05)',
                    transition: 'transform 0.5s ease',
                  }
                }}
                onClick={() => {
                  setIsShareLoading(true)
                  API.put("/config/share_costing_config/", {
                    source_project: projectId,
                    target_project: selectedProject,
                    allowed_module: shareCheck
                  }, {}, 0).then((res: any) => {
                    onCloseModal()
                    setIsShareLoading(false)
                  }).catch((err: any) => {
                    setIsShareLoading(false)
                  })
                }}
              >
                share
              </LoadingButton>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: "5px",
                backgroundColor: "#007fff14",
                padding: "0.5rem 1rem",
              }}
            >
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  textAlign: "left",
                }}
              >
                Already Shared with :
              </Typography>
              {/* <DescriptionOutlinedIcon sx={{ fontSize: '1.5rem' }} /> */}
            </Box>
            <Box sx={{ padding: "1rem" }}>
              <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', width: '100%', rowGap: '1rem' }}>
                {sharedProjectList &&
                  sharedProjectList.length > 0 ? <>
                  {sharedProjectList &&
                    sharedProjectList?.map((item: any) => {
                      return (
                        <Chip
                          variant="outlined"
                          sx={{ width: "10rem", cursor: "pointer" }}
                          label={item.name}
                          color="primary"
                        />
                      );
                    })}
                </>
                  : <Box sx={{ textAlign: 'center', width: '100%' }}>
                    <Typography sx={{ fontSize: '1rem' }}>Not Shared</Typography></Box>}
              </Box>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};

export default ShareCostingModel;
