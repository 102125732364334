// react
import Modal from "@mui/material/Modal";
import React, { useRef, useState } from "react";
import RViewerJS from "viewerjs-react";
import { CardMedia } from "@mui/material";
import styles from './IdeaImageViewModal.module.scss';
import noImage from "../../Assets/images/noImage.png";
import Image from '../../ViewModule/FeatureLandingPage/ArchitectureImageCard';
import loaderImage from '../../Assets/images/loading-screen.gif'

// interfaces and types
// lazy
interface IdeaImageViewModalProps {
  isOpen: boolean;
  onClose: () => void;
  id?: any;
  ideaImage?: any;
}

const IdeaImageViewModal: React.FC<IdeaImageViewModalProps> = (props) => {
  const {isOpen,onClose,id,ideaImage} = props;
  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      className={styles.container}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
       <div className={styles.imageContainer}>
        {ideaImage && ideaImage?.length <1 ?
        <div><img src={noImage} style={{ height: "21rem" }} /></div>
        : <>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr" }}>
             {ideaImage && ideaImage?.map((item:any)=>{
               return  <h1 style={{marginLeft:'2rem'}}>{item?.parameter_name}</h1>
               })}
          </div>
          {/* @ts-ignore */}
          <RViewerJS>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr" }}>
            {ideaImage && ideaImage?.map((item:any)=>{
              return <div>
              <Image
                height="100%"
                width="100%"
                placeholderImg={loaderImage}
                src={item?.image_name}
                alt={item?.parameter_name}
                style={{ objectFit: 'contain', cursor: 'pointer' }}
              />
              </div>
                })}
              </div>
          </RViewerJS>  
        </>
        }
      </div>
    </Modal>
  );
};

// ----------------------------------------------------------------------------------

export default IdeaImageViewModal;
