import { Box, Divider, Modal, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import { useHistory } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: '80vw', sm: " 70vw", md: '50vw' },
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

interface MediaAddSingleVideoModelI {
  isOpen: any;
  onCloseModal: any;
  saveVideo: any;
  isUpdating: any;
  type: string;
}

const MediaAddSingleVideoModel: React.FC<MediaAddSingleVideoModelI> = (props) => {
  const { isOpen, onCloseModal, saveVideo, isUpdating, type } = props;
  const [id, setIsID] = useState<number>();
  const inputElRef = useRef<any>();

  const browseFiles = (idGet: any) => {
    setIsID(idGet);
    return inputElRef.current?.click?.();
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file: any = e.target.files?.[0];
    saveVideo(file, id);
    // setDisable( false );
  };

  const handleInputClick = (e: React.MouseEvent) => {
    // clear the prev value on click so that same image can be uploaded again
    (e.target as HTMLInputElement).value = "";
  };

  return (
    <Modal
      open={isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
          <Typography
            style={{
              fontSize: "1rem",
              fontWeight: "600",
              width: "100%",
              textAlign: "center",
              marginRight: '-2rem'
            }}
          >
            Media Individual Video Upload
          </Typography>
          {isUpdating ? <span></span> : <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />}
        </Box>
        <Divider
          sx={{
            borderColor: "primary.light",
            margin: "0 1rem",
            marginBottom: "1rem",
          }}
        />
        <Box style={{ display: "flex", justifyContent: "space-evenly" }}>
          <LoadingButton
            sx={{
              padding: "0.5rem",
              lineHeight: 1,              
              "&:hover": {
                transform: 'Scale(1.05)',
                transition: 'transform 0.5s ease',
              },
              '.MuiCircularProgress-root': {
                color: 'primary.main',
              },
            }}
            size='small' loading={id && id == 1 && isUpdating} onClick={() => browseFiles(1)} id="1">Upload With Audio</LoadingButton>
          <LoadingButton 
          sx={{
            padding: "0.5rem",
            lineHeight: 1,              
            "&:hover": {
              transform: 'Scale(1.05)',
              transition: 'transform 0.5s ease',
            },
            '.MuiCircularProgress-root': {
              color: 'primary.main',
            },
          }}
          size='small' loading={id && id == 2 && isUpdating} onClick={() => browseFiles(2)} id="2">Upload Without Audio</LoadingButton>
        </Box>
        {type == 'mobile' ?
          <input
            type="file"
            accept="video/*"
            capture="environment"
            ref={inputElRef}
            onChange={handleChange}
            onClick={handleInputClick}
            style={{ display: "none" }}
          />
          : <input
            type="file"
            accept="video/*"
            ref={inputElRef}
            onChange={handleChange}
            onClick={handleInputClick}
            style={{ display: "none" }}
          />}
      </Box>
    </Modal>
  )
}

export default MediaAddSingleVideoModel;