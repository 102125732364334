import { Box, Button, Divider, Modal, Typography } from "@mui/material";
import React from "react";
import CancelIcon from '@mui/icons-material/Cancel';
import success from "../Assets/images/success.png"

interface ErrorModalProps {
  Masseges: any;
  isOpen: any;
  onClose: any;
  dependencies: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  // color: "green",
  fontSize: "0.4rem",
  borderRadius: '10px',
  bgcolor: "background.paper",
  //   border: "2px solid #000",
  boxShadow: 24,
  // p: 4,
};

const ErrorToastModal: React.FC<ErrorModalProps> = (props) => {
  const { Masseges, isOpen, onClose, dependencies } = props;
  const HandleClosed = () => {

    dependencies((pre: any) => pre + 1)
    onClose()

  }
  return (
    <>
      <Modal
        open={isOpen}
        onClose={HandleClosed}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: '-2rem'
              }}
            >
              Guest User Creation Success !
            </Typography>
            {/* <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={HandleClosed} /> */}
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />

          {Masseges && Masseges.includes("Guest")?
          <Box sx={{textAlign:'center',paddingBottom:'1rem'}}>
            <img src={success} style={{ width: '5rem', margin: '0 auto' }} />
            <Typography sx={{fontSize:'1rem'}} id="modal-modal-title" variant="h6" component="h2">
              {Masseges}
            </Typography>
            <Button size="small" variant="contained" onClick={HandleClosed}>Ok</Button>
          </Box>
          :
          
          <Box sx={{textAlign:'center',paddingBottom:'1rem'}}>
         
          <Typography sx={{fontSize:'1rem'}} id="modal-modal-title" variant="h6" component="h2">
            {Masseges}
          </Typography>
          <Button size="small" variant="contained" onClick={HandleClosed}>Ok</Button>
        </Box>
          
          }
        </Box>

      </Modal>
    </>
  );
};

export default ErrorToastModal;
