import * as React from 'react';
import { Box, Divider, Table, TableBody, TableCell, TableRow, Typography } from '@mui/material';
import Modal from '@mui/material/Modal';
import CancelIcon from '@mui/icons-material/Cancel';


export interface IBoughtOutInfoModalProps {
    onCloseModal: any;
    isOpen: any;
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    borderRadius: '10px',
    p: 1,
};


export function BoughtOutInfoModal(props: IBoughtOutInfoModalProps) {
    const { onCloseModal, isOpen } = props;

    return (
        <div>
            <Modal
                open={isOpen}
                onClose={onCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
                        <Typography
                            style={{
                                fontSize: "1rem",
                                fontWeight: "600",
                                width: "100%",
                                textAlign: "center",
                                marginRight: '-2rem'
                            }}
                        >
                            Addition Info
                        </Typography>
                        <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
                    </Box>
                    <Divider
                        sx={{
                            borderColor: "primary.light",
                            margin: "0 1rem",
                            marginBottom: "1rem",
                        }}
                    />
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Material Code</TableCell>
                                <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}> Material </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Material Category</TableCell>
                                <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Material Category</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Grade</TableCell>
                                <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Grade</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}> Sub Grade</TableCell>
                                <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Sub Grade</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Shape</TableCell>
                                <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Shape </TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Density(Kg/m3)</TableCell>
                                <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}> Density</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Remarks</TableCell>
                                <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}> Remarks</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </Box>
            </Modal>
        </div>
    );
}
