import { TextField } from "@mui/material";
import { debounce } from "lodash";
import * as React from "react";
import { API } from "../../../api-services";
import Swal from "sweetalert2";

export interface IEditSequenceMaterialProps {
  value: any;
  costEstimationId: any;
  getMaterialItemData: any;
  item: any;
}

export function EditSequenceMaterial(props: IEditSequenceMaterialProps) {
  const { value, costEstimationId, getMaterialItemData,item } = props;
  const [sequence, setSequence] = React.useState<any>();
  const ExactDebounce = React.useRef(
    debounce(async (value: any, iddd: any) => {
      await API.put(
        `/cost/material/${iddd}/`,
        {
          sequence: parseInt(value),
        },
        {},
        0
      )
        .then((res: any) => {
          // getRMDataList();
          getMaterialItemData((prev: any) => prev + 1);
          Swal.fire({                  
            icon: "success",
            html: `<div>
                             <br />
                             <p style="color:"#007fff">Sequence Updated Successfully</p>   
                              </div>`,
            customClass: {container: 'swal2Container'},
          });
          // getRMData();
        })
        .catch((err: any) => {
          const { data } = err?.response;
          Swal.fire({
            icon: "error",
            html: `<div>
                                   <br />
                                   <p style="color:"red">${data[0]}</p>   
                                    </div>`,
          });
        });
    }, 1000)
  ).current;

  React.useEffect(() => {
    return () => {
      ExactDebounce.cancel(); // Cancel the debounced function
    };
  }, []);

  React.useEffect(() => {
    if (item?.sequence) {
      setSequence(item?.sequence);
    }
  }, [item]);
  const handleOnChange = (e: any) => {
    const { value } = e.target;
    setSequence(value);
    ExactDebounce(value, costEstimationId);
  };
  return (
    <TextField
      size="small"
      variant="standard"
      type="number"
      value={sequence}
      onChange={handleOnChange}
      sx={{
        width: "3rem",
        ".MuiInputBase-root.MuiInput-root": {
          fontSize: "1rem",
        },
        "& .MuiInput-underline:before": {
          borderBottomColor: "primary.main",
        },
        "& .MuiInput-underline:after": {
          borderBottomColor: "primary.main",
        },
      }}
    />
  );
}
