import { useState } from "react";

// ----------------------------------------------------------------------------------

const useShareWithDb = () => {
    const [isOpen, setIsOpen] = useState( false );
    const [shareItemName, setShareItemName] = useState(  );
    const [shareItemCode, setShareItemCode] = useState(  );
    const [shareItemCategory, setShareItemCategory] = useState(  );
    const [id,setId] = useState<any>();

    const handleOpen = (idGet:any,shareItemCodeGet:any,shareItemNameGet:any,shareItemCategoryGet:any) => {
        setIsOpen( true );
        setId(idGet);
        setShareItemName(shareItemNameGet);
        setShareItemCode(shareItemCodeGet);
        setShareItemCategory(shareItemCategoryGet);
    }
    const handleClose = () => {
        setIsOpen( false );
        setId(undefined);
        setShareItemName(undefined);
        setShareItemCategory(undefined)
    }

    return Object.freeze( {
        open: handleOpen,
        close: handleClose,
        isOpen,
        id,
        shareItemName,
        shareItemCode,
        shareItemCategory
    } );
};


// ----------------------------------------------------------------------------------

export { useShareWithDb };