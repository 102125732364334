import React, { useEffect, useState } from 'react';
import { Autocomplete, Chip, FormControl, IconButton, MenuItem, Select, SelectChangeEvent, TableCell, TableRow, TextField } from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import { API } from '../../api-services';
import { AxiosResponse } from 'axios';
import { uuid } from 'uuidv4';
import swal from "sweetalert";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { LoadingButton } from '@mui/lab';
import useWindowDimensions from '../../ui-reusable-component/useWindowDimensions';

const stringTypes = [
  "textarea",
  "string",
  "longtext",
  "singleselect",
  "multiselect",
];
const numberTypes = [
  "float",
  "nonzero-float",
  "positive-float",
  "nonzero-positive-float",
  "negative-float",
  "nonzero-negative-float",
  "integer",
  "nonzero-integer",
  "positive-int",
  "nonzero-positive-int",
  "negative-int",
  "nonzero-negative-int",
  "percentage",
];

const stringOperations = {
  matches: "Matches",
  noMatch: "Not Match",
  includes: "Includes",
  in: "In",
  oneOf: "One Of",
  notOneOf: "Not One Of",
};
const numberOperations = {
  lessThan: "Less Than",
  greaterThan: "Greater Than",
  lessThanOrEqual: "Less Than or Equal",
  greaterThanOrEqual: "Greater Than or Equal",
  equality: "Equals",
  unEquality: "Not Equals",
  oneOf: "One Of",
  notOneOf: "Not One Of",
};
const booleanOperations = {
  equality: "Equals",
};


interface BOMAddValidationRowsI {
  countOfArray: any;
  index: any;
  parameterListData: any;
  validationuuid: any;
  idParameter: any;
  relationIDParameter: any;
  apiCallValidationShowData: any;
  setApiCallValidationShowData: any;
  handleClickSnackbar: any;
  deleteModelShow: any;
  setErrorMessage: any;
  expressionIncoming?: any,
  setExpressionIncoming?: any,
  messageIncoming: any;
  setMessageIncoming: any;
  saveLogicIncoming: any;
  setSaveLogicIncoming: any;
  setHideExpressions?: any;
}

const BOMAddValidationRows: React.FC<BOMAddValidationRowsI> = (props) => {
  const { countOfArray, index, parameterListData, validationuuid, idParameter, relationIDParameter, apiCallValidationShowData, setApiCallValidationShowData, handleClickSnackbar, deleteModelShow, setErrorMessage, expressionIncoming, setExpressionIncoming, messageIncoming, setMessageIncoming, saveLogicIncoming, setSaveLogicIncoming, setHideExpressions } = props;

  const [parameter, setParameter] = React.useState<any>();
  const [parameterType, setParameterType] = React.useState<any>();
  const [expression, setExpression] = React.useState<any>();
  const [message, setMessage] = React.useState<any>();
  const [operator, setOperator] = React.useState<any>();
  const [optionsOperator, setOptionsOperator] = React.useState<any>([]);
  const [value, setValue] = React.useState<any>();
  const [multiValue, setMultiValue] = React.useState<string[]>([]);
  const [saveExpression, setSaveExpression] = React.useState<boolean>(false);
  const [saveLogic, setSaveLogic] = React.useState<boolean>(false);
  const [validationuuidIncoming, setValidationUuidIncoming] = React.useState<any>();
  const [showDeleteAfterSaveExpressionValue, setShowDeleteAfterSaveExpressionValue] = React.useState<boolean>(false);
  const [showCopyAfterSaveExpressionLogic, setShowCopyAfterSaveExpressionLogic] = React.useState<boolean>(false);
  const { height, width } = useWindowDimensions();

  
  const expressionCreateuuid = uuid();

  useEffect(() => {
    setExpression(expressionIncoming);
  }, [expressionIncoming])

  useEffect(() => {
    setMessage(messageIncoming);
  }, [messageIncoming])

  useEffect(() => {
    setSaveLogic(saveLogicIncoming);
  }, [saveLogicIncoming])

  useEffect(() => {
    setValidationUuidIncoming(validationuuid);
  }, [])

  const parameterDropdown = parameterListData && parameterListData?.map((item: any) => {
    return { id: item?.id, name: item?.parameter?.name, value: item?.id }
  })

  const handleChangeParameterType = (event: SelectChangeEvent) => {
    setSaveExpression(true);
    setParameter(undefined);
    setOperator(undefined);
    setValue(undefined);
    setParameterType(event.target.value);
  };

  const handleChangeParameter = (event: SelectChangeEvent) => {
    setSaveExpression(true);
    setOperator(undefined);
    setValue(undefined);
    setParameter(event.target.value);
    let abc: any = parameterListData && parameterListData?.find((item: any) => { return item?.id === event.target.value })?.parameter?.data_type;
    setOptionsOperator(stringTypes.includes(abc)
      ? Object.entries(stringOperations).map(
        ([key, value]) => ({
          key: key,
          value: key,
          text: value,
        })
      )
      : numberTypes.includes(abc)
        ? Object.entries(numberOperations).map(
          ([key, value]) => ({
            key: key,
            value: key,
            text: value,
          })
        )
        : Object.entries(booleanOperations).map(
          ([key, value]) => ({
            key: key,
            value: key,
            text: value,
          })
        ));
  };

  const handleChangeOperator = (event: any) => {
    setValue(undefined);
    setSaveExpression(true);
    setOperator(event.target.value);
    setMultiValue([]);
  };

  const handleChangeValue = (event: any) => {
    setSaveExpression(true);
    setValue(event.target.value);
  };

  const handleChangeExpression = (event: any) => {
    setSaveLogicIncoming(true);
    setExpression(event.target.value);
  };

  const handleChangeMessage = (event: any) => {
    setSaveLogicIncoming(true);
    setMessageIncoming(event.target.value);
  };

  const handleSaveExpressionValue: any = () => {
    API.put(`/config/template_validation/${idParameter}/`, { relation_id: relationIDParameter, main_uid: validationuuidIncoming, sub_uid: expressionCreateuuid, type: parameterType, lhs: parameter, operation: operator, rhs: parameterType === 'p-v' && ["singleselect", "multiselect"].includes(parameterListData && parameterListData?.find((item: any) => { return item?.id === parameter })?.parameter?.data_type) ? multiValue?.map((item: any) => item.value) : value }, 0).then((res: AxiosResponse) => {
      setSaveExpression(false);
      setShowDeleteAfterSaveExpressionValue(true);
      setHideExpressions(true);
      setShowCopyAfterSaveExpressionLogic(true);
    }).catch((err: any) => {
      const { data } = err.response;
      setErrorMessage(data?.[0]);
      deleteModelShow.open();
    });
  }

  const handleSaveExpressionLogic: any = (e: any) => {
    e.stopPropagation();
    if (expression?.includes("]exp")) {
      setErrorMessage("Please check the expression");
      deleteModelShow.open()
    }
    else {
      API.put(`/config/template_validation/${idParameter}/`, { relation_id: relationIDParameter, main_uid: validationuuidIncoming, message: message, finalExpression: expression }, 0).then((res: AxiosResponse) => {
        setApiCallValidationShowData(!apiCallValidationShowData);
        setSaveLogicIncoming(false);
      }).catch((err: any) => {
        const { data } = err.response;
        setErrorMessage(data?.[0]);
        deleteModelShow.open();
      });
    }
  }

  const checkDisabledButtonExpressionValue = () => {
    if (parameter && operator && parameterType === 'p-v' ? ["singleselect", "multiselect"].includes(parameterListData && parameterListData?.find((item: any) => { return item?.id === parameter })?.parameter?.data_type) ? multiValue?.length > 0 : value : value) {
      return false;
    }
    return true;
  }

  const checkDisabledButtonExpressionLogic = () => {
    if (parameter && operator && value && message) {
      return false;
    }
    return true;
  }

  const handleDeleteValidationValue = async (e: React.MouseEvent, id: number) => {
    e.stopPropagation();
    swal({
      title: `Delete Validation Expression Values`,
      text: `Are you sure?`,
      icon: "error",
      //@ts-ignore
      buttons: {
        cancel: "Cancel",
        permanent: {
          text: `Delete`,
          value: `deleteValidationExpressionValues`,
        },
      },
      closeOnClickOutside: false,
      closeOnEsc: false,
      className: "delete-swal",
    }).then(async (type: string) => {
      switch (type) {
        case "deleteValidationExpressionValues":
          API.delete("/config/template_validation/" + id + "/", { relation_id: relationIDParameter, main_uid: validationuuidIncoming, sub_uid: expressionCreateuuid }, 0)
            .then((res: any) => {
              setShowDeleteAfterSaveExpressionValue(false);
              setApiCallValidationShowData(!apiCallValidationShowData);
            })
            .catch((err: any) => {
              setErrorMessage(err.response.data);
              deleteModelShow.open()
              console.log(err, "Unable to upload. Server Error");
            });
          break;
        default:
          swal(`You Canceled Validation Expression Values from deleting`, { icon: "error" });
      }
    });
  };

  // const handleDeleteValidationLogic = async (e: React.MouseEvent, id: number) => {
  //   e.stopPropagation();
  //   swal({
  //     title: `Delete Validation Final Expression`,
  //     text: `Are you sure?`,
  //     icon: "error",
  //     //@ts-ignore
  //     buttons: {
  //       cancel: "Cancel",
  //       permanent: {
  //         text: `Delete`,
  //         value: `deleteValidationFinalExpression`,
  //       },
  //     },
  //     closeOnClickOutside: false,
  //     closeOnEsc: false,
  //     className: "delete-swal",
  //   }).then(async (type: string) => {
  //     switch (type) {
  //       case "deleteValidationFinalExpression":
  //         API.delete("/config/template_validation/" + id + "/",{relation_id:relationIDParameter,main_uid:validationuuidIncoming,delete_validation:true},0)
  //           .then((res: any) => {
  //             setShowDeleteAfterSaveExpressionValue(false);
  //             setApiCallValidationShowData(!apiCallValidationShowData);
  //           })
  //           .catch((err: any) => {
  //             console.log(err, "Unable to upload. Server Error");
  //           });
  //         break;
  //       default:
  //         swal(`Validation Final Expression saved from deleting`, { icon: "error" });
  //     }
  //   });
  // };

  const copyContentExpressionID: any = (content: any) => {
    navigator.clipboard.writeText(content);
    setExpressionIncoming(expressionIncoming + content);
    setSaveLogicIncoming(true);
    handleClickSnackbar();
  }

  const handleSelectChangeProduct = (Productitem: any, situation: any) => {
    setSaveExpression(true);
    setMultiValue([...Productitem])
  };

  return (
    <TableRow>
      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>{index + 1}</TableCell>
      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}><div style={{ display: 'flex' }}><span style={expression?.includes(`exp['${index + 1}']`) ? { marginRight: '0.5rem', color: 'green' } : { marginRight: '0.5rem' }}>exp['{index + 1}'] </span>{showCopyAfterSaveExpressionLogic && <ContentCopyIcon sx={{ cursor: 'pointer' }} onClick={() => copyContentExpressionID(`exp['${index + 1}']`)} />}</div></TableCell>
      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
        <FormControl variant="standard" sx={{ m: 1, width: width > 1400 ? 170 : 80 }} fullWidth>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={parameterType}
            onChange={handleChangeParameterType}
            sx={{
              "&:before": {
                borderBottomColor: "primary.light",
              },
              "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                fontSize: '1rem'
              },
              '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
            }}
          >
            <MenuItem sx={{ fontSize: '1rem' }} value={"p-p"}>Parameter to Parameter</MenuItem>
            <MenuItem sx={{ fontSize: '1rem' }} value={"p-v"}>Parameter to Value</MenuItem>
            <MenuItem sx={{ fontSize: '1rem' }} value={"p-m"}>Parameter to Meta</MenuItem>
          </Select>
        </FormControl>
      </TableCell>
      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
        <FormControl variant="standard" sx={{ m: 1, width: width > 1400 ? 170 : 80 }} fullWidth>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={parameter}
            onChange={handleChangeParameter}
            sx={{
              "&:before": {
                borderBottomColor: "primary.light",
              },
              "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                fontSize: '1rem'
              },
              '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
            }}
          >
            {parameterDropdown?.map((item: any) => {
              return <MenuItem key={item?.id} value={item?.value}>{item?.name}</MenuItem>
            })}
          </Select>
        </FormControl>
      </TableCell>
      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>{ }
        <FormControl variant="standard" sx={{ m: 1, width: width > 1400 ? 120 : 80 }} fullWidth>
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={operator}
            onChange={handleChangeOperator}
            sx={{
              "&:before": {
                borderBottomColor: "primary.light",
              },
              "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                fontSize: '1rem'
              },
              '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
            }}
          >
            {optionsOperator && optionsOperator?.map((item: any) => {
              return <MenuItem sx={{ fontSize: '1rem' }} key={item?.key} value={item?.value}>{item?.text}</MenuItem>
            })}
          </Select>
        </FormControl>
      </TableCell>
      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>
        <FormControl variant="standard" sx={{ m: 1, width: width > 1400 ? 300 : 150 }} fullWidth>
          {parameterType === 'p-p' ? <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            value={value}
            onChange={handleChangeValue}
            sx={{
              "&:before": {
                borderBottomColor: "primary.light",
              },
              "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                fontSize: '1rem'
              },
              '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
            }}
          >
            {parameterDropdown?.map((item: any) => {
              return <MenuItem sx={{ fontSize: '1rem' }} key={item?.id} value={item?.value}>{item?.name}</MenuItem>
            })}
          </Select> : parameterType === "p-m" ?
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={value}
              onChange={handleChangeValue}
              sx={{
                "&:before": {
                  borderBottomColor: "primary.light",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                  fontSize: '1rem'
                },
                '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
              }}
            >
              <MenuItem sx={{ fontSize: '1rem' }} value={"name"}>Vault Name</MenuItem>
              <MenuItem sx={{ fontSize: '1rem' }} value={"part_no"}>Part No</MenuItem>
            </Select> :
            ["singleselect", "multiselect"].includes(parameterListData && parameterListData?.find((item: any) => { return item?.id === parameter })?.parameter?.data_type) ?
              <Autocomplete
                multiple
                disableClearable
                id="multiple-limit-tags"
                limitTags={2}
                disableCloseOnSelect
                sx={{
                  '.MuiSvgIcon-root': { color: 'primary.main' },
                }}
                value={multiValue}
                onChange={(event, newValue, situation) =>
                  handleSelectChangeProduct(newValue, situation)
                }
                options={(parameterListData && parameterListData?.find((item: any) => { return item?.id === parameter })?.parameter?.allowed_values?.map((av: string) => ({
                  key: av,
                  value: av,
                  name: av,
                })))?.filter((item: any) => {
                  const multiValueMap = multiValue?.map((item: any) => item?.name);
                  return !multiValueMap?.includes(item.name)
                }) ?? []}
                // @ts-ignore
                getOptionLabel={(option) => option?.name}
                renderTags={(tagValue, getTagProps) =>
                  tagValue.map((option, index) => (
                    <Chip
                      size="small"
                      sx={{ background: '#007fff14', fontSize: '1rem' }}
                      //@ts-ignore
                      label={option?.name}
                      {...getTagProps({ index })}
                    // disabled={fixedOptions.indexOf(option) !== -1}
                    />
                  ))
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="standard"
                    sx={{
                      fontSize: '1rem',
                      '& .MuiInput-underline:before': { borderBottomColor: '#007fff' },
                      '& .MuiInput-underline:after': { borderBottomColor: '#007fff' },
                    }}
                  />
                )}
              /> :
              <TextField
                fullWidth
                InputProps={{
                  style: { color: "#007fff", fontSize: 16 },
                }}
                InputLabelProps={{ style: { fontSize: 16 } }}
                sx={{
                  width: "100%",
                  "& .MuiFormLabel-root": {
                    color: 'primary.main'
                  },
                  "& .MuiFormLabel-root.Mui-focused": {
                    color: 'primary.main'
                  },
                  "& .Mui-focused": {
                    color: 'primary.main',
                  },
                  "& .Mui-error": {
                    color: '#d32f2f',
                    borderBottomColor: '#d32f2f'
                  },
                  '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                  '& .MuiInput-underline:after': { borderBottomColor: 'primary.light' },
                }}
                variant="standard"
                size="small"
                value={value}
                onChange={handleChangeValue}
              />}
        </FormControl>
      </TableCell>
      <TableCell sx={{ padding: '0.2rem 0.5rem',borderBottomColor:'primary.light', borderRight: '0.5rem solid white' }}>
        <IconButton title='Save' sx={checkDisabledButtonExpressionValue() ? { padding: '0rem' } : saveExpression ? { color: 'red', padding: '0rem' } : { color: '#007fff', padding: '0rem' }} disabled={checkDisabledButtonExpressionValue()} onClick={handleSaveExpressionValue}><SaveIcon /></IconButton>
        {!checkDisabledButtonExpressionValue() && showDeleteAfterSaveExpressionValue && <IconButton title='Delete' sx={{ color: '#007fff', padding: '0rem' }} onClick={(e: any) => handleDeleteValidationValue(e, idParameter)}><DeleteIcon /></IconButton>}
      </TableCell>
      {index == 0 && <>
        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }} rowSpan={index}>
          <TextField
            fullWidth
            InputProps={{
              style: { color: "#007fff", fontSize: 16 },
            }}
            InputLabelProps={{ style: { fontSize: 16 } }}
            sx={{
              width: "100%",
              "& .MuiFormLabel-root": {
                color: 'primary.main'
              },
              "& .MuiFormLabel-root.Mui-focused": {
                color: 'primary.main'
              },
              "& .Mui-focused": {
                color: 'primary.main',
              },
              "& .Mui-error": {
                color: '#d32f2f',
                borderBottomColor: '#d32f2f'
              },
              '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
              '& .MuiInput-underline:after': { borderBottomColor: 'primary.light' },
            }}
            variant="standard"
            size="small"
            value={expression}
            onChange={handleChangeExpression}
          />
        </TableCell>
        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }} rowSpan={index}>
          <TextField
            fullWidth
            InputProps={{
              style: { color: "#007fff", fontSize: 16 },
            }}
            InputLabelProps={{ style: { fontSize: 16 } }}
            sx={{
              width: "100%",
              "& .MuiFormLabel-root": {
                color: 'primary.main'
              },
              "& .MuiFormLabel-root.Mui-focused": {
                color: 'primary.main'
              },
              "& .Mui-focused": {
                color: 'primary.main',
              },
              "& .Mui-error": {
                color: '#d32f2f',
                borderBottomColor: '#d32f2f'
              },
              '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
              '& .MuiInput-underline:after': { borderBottomColor: 'primary.light' },
            }}
            variant="standard"
            size="small"
            value={message}
            onChange={handleChangeMessage}
          />
        </TableCell>
        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }} rowSpan={index}>
          <IconButton title='Save' sx={checkDisabledButtonExpressionLogic() ? { padding: '0rem' } : saveLogic ? { color: 'red', padding: '0rem' } : { color: '#007fff', padding: '0rem' }} disabled={checkDisabledButtonExpressionLogic()} onClick={(e: any) => handleSaveExpressionLogic(e)}><SaveIcon /></IconButton>
        </TableCell>
      </>}
    </TableRow>)
}

export default BOMAddValidationRows;