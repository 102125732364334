import * as React from 'react';
import Modal from '@mui/material/Modal';
import CancelIcon from '@mui/icons-material/Cancel';
import { Box, Divider, TextField, Typography } from '@mui/material';
import { useRef, useState } from 'react';
import LoadingButton from '@mui/lab/LoadingButton';
import WorkshopEIRS3Upload from './WorshopEIRS3Upload';
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";

interface Params{
    projectId:any;
  }


export interface IWorkshopPresentationModalProps {
    onCloseModal: any;
    isOpen: any;
    type:any;
    apiCallWorkstationData?:any;
    setApiCallWorkstationData?:any;
    abbreviationSendUpload?:any;
}

const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: {lg:'40vw',xl:'30vw'},
    height: 'fit-content',
    bgcolor: 'background.paper',
    borderRadius: '10px',
    p: 1,
};


export function WorkshopPresentationModal(props: IWorkshopPresentationModalProps) {
    const { onCloseModal, isOpen, type, apiCallWorkstationData, setApiCallWorkstationData, abbreviationSendUpload} = props;
    const {projectId} = useRouteParams<Params>();
    const [fileList,setFileList] = useState<any>();
    const [fileListLoader,setFileListLoader] = useState<boolean>(false);
    const inputElRefPdf = useRef<any>();

    const UploadPDF = (e: any) => {
        setFileList(e?.target?.files)
    };

    const handleAddWorkstationEIR = () => {
        setFileListLoader(true);
        if(type=='workshopPresentation'){
        WorkshopEIRS3Upload(
            fileList,
            setFileListLoader,
            "workshopUpload",
            projectId,
            apiCallWorkstationData,
            setApiCallWorkstationData,
            onCloseModal
        )}
        else{
            WorkshopEIRS3Upload(
                fileList,
                setFileListLoader,
                "bannerUpload",
                projectId,
                apiCallWorkstationData,
                setApiCallWorkstationData,
                onCloseModal,
                abbreviationSendUpload
            )
        }
    }

    return (
        <div>
            <Modal
                open={isOpen}
                onClose={onCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
                        <Typography
                            style={{
                                fontSize: "1rem",
                                fontWeight: "600",
                                width: "100%",
                                textAlign: "center",
                                marginRight: '-2rem'
                            }}
                        >
                           {`${type=="workshopPresentation" ? (abbreviationSendUpload ? "Edit Workshop Presentation": "Add Workshop Presentation") : ("Add ( "+abbreviationSendUpload+" ) EIR")}`}
                        </Typography>

                        <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
                    </Box>
                    <Divider
                        sx={{
                            borderColor: "primary.light",
                            margin: "0 1rem",
                            marginBottom: "1rem",
                        }}
                    />
                    <Box sx={{ height: 'fit-content' }}
                        adl-scrollbar="true"
                        adl-scrollbar-width="0.3">
                        <Box>
                            <Box sx={{ margin: 'auto', alignItems: 'center', padding: '0 1rem', marginBottom: '1rem' }}>
                                <label style={{ marginRight: '1rem',fontSize:'0.87rem' }}>Select File (PDF only) : </label>
                                <input
                                    accept=".pdf"
                                    id="PDF Upload"
                                    type="file"
                                    style={{ marginTop:'0.5rem', }}
                                    onChange={(e: any) => UploadPDF(e)}
                                    ref={inputElRefPdf}
                                />
                            </Box>
                            <Box sx={{ width: '100%', textAlign: 'right',padding: '0 1rem', }}>
                                <LoadingButton variant='contained'
                                    loading={fileListLoader}
                                    size='small'
                                    disabled={fileList==undefined}
                                    onClick={()=>handleAddWorkstationEIR()}
                                    sx={{
                                        lineHeight:1,
                                        padding:'0.5rem 1rem',
                                        marginLeft: '1rem',
                                        minWidth: '1rem',
                                        '.MuiCircularProgress-root': {
                                            color: 'primary.main',
                                        },
                                        "&:hover": {
                                            transform: 'Scale(1.05)',
                                            transition: 'transform 0.5s ease',
                                          }
                                    }}>
                                    Add File
                                </LoadingButton>
                            </Box>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </div >
    );
}
