import {
  Box,
  Divider,
  FormControl,
  InputLabel,
  Modal,
  Skeleton,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import CancelIcon from "@mui/icons-material/Cancel";
import { API } from "../../../api-services";
import { useHistory, useParams } from "react-router-dom";
import { useBaseModal } from "../../SearchPage/useBaseModal";
import ErrorModalFilesMail from "../../ProjectMailModule/ErrorModalFilesMail";
import CopyMoveHeirarchyBOM from "./CopyMoveHeirarchyBOM";
import APIRequestTreeView from "./APIRequestTreeView";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import JsonViewer from "./JSONViewer";
// import { JsonView, darkStyles, defaultStyles } from "react-json-view-lite";
// import "react-json-view-lite/dist/index.css";
// import { Height } from "@mui/icons-material";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

interface MovePartModalProps {
  onCloseModal: any;
  isOpen: any;
  type: any;
}

const APIRequestTreeModal = (props: MovePartModalProps) => {
  const { productId, projectId, projectName, productName, requestId } =
    useRouteParams<any>();
  const history = useHistory();
  const { onCloseModal, isOpen, type } = props;
  const sampleJson = {
    name: "John Doe",
    age: 30,
    address: {
      street: "123 Main St",
      city: "Anytown",
      state: "CA",
    },
    hobbies: ["reading", "traveling", "swimming"],
  };

  const [treeData, setTreeData] = useState<any>([]);
  const [responseTreedata,setResponseTreeData] = useState<any>([])

  const getTreeData = () => {
    API.get(
      "/xcpep/api_request_response_view/",
      {
        request_hierarchy: type === "Request" ? true : undefined,
        response_hierarchy: type === "Response" ? true : undefined,
      },
      0
    )
      .then((res: any) => {
        setTreeData(res.data);
      })
      .catch((err: any) => {});
  };
  const getTreeDataResponse = () => {
    API.get(
      "/xcpep/api_user_structure_view/",
      {
        request_data: type === "Request" ? true : undefined,
        response_data: type === "Response" ? true : undefined,
        id: requestId
      },
      0
    )
      .then((res: any) => {
        setResponseTreeData(res.data);
      })
      .catch((err: any) => {});
  };

  React.useEffect(() => {
    getTreeData();
    getTreeDataResponse();
  }, []);

  const onSelect = (
    nodeId: any,
    HierarchyAllData: any,
    HierarchyAllDataId: any
  ) => {
    history.push(
      `/API-console/${projectId}/${projectName}/${productId}/${productName}/0/0/landing/${requestId}/${HierarchyAllDataId}`
    );
  };

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {/* <ErrorModalFilesMail
						isOpen={ErrorModalFiles.isOpen}
						onCloseModal={ErrorModalFiles.close}
						ErrorMsg={ErrorModalFileMsg}
					/> */}
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Configure API {type} Structure
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{display:'flex'}}>
            <Box
              adl-scrollbar="true"
              adl-scrollbar-width="0.3"
              sx={{ marginTop: "1rem", height: "50rem", width: "99.5%" }}
            >
              {treeData &&
                treeData?.data?.map((singleTree: any) => {
                  return (
                    <APIRequestTreeView
                      HierarchyData={singleTree}
                      onSelectCallback={onSelect}
                      getTreeData={getTreeData}
                      type={type}
                      getTreeDataResponse={getTreeDataResponse}
                    />
                  );
                })}
            </Box>
            <Box
              adl-scrollbar="true"
              adl-scrollbar-width="0.3"
              sx={{ marginTop: "1rem", height: "50rem", width: "99.5%" }}
            >
              {/* <JsonView
                data={responseTreedata}
                shouldInitiallyExpand={(level) => true}
                style={darkStyles}
              /> */}
              <JsonViewer data={responseTreedata} />
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
export default APIRequestTreeModal;
