import { Box, CircularProgress, Skeleton } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import UploadIcon from '@mui/icons-material/Upload';
import DeleteIcon from '@mui/icons-material/Delete';
import styles from './BOMCreateImagesSection.module.scss';
import swal from 'sweetalert';
import { AxiosError, AxiosResponse } from 'axios';
import { LoadingButton } from '@mui/lab';
import { API } from '../../../../api-services';
import { useRouteParams } from '../../../../ui-reusable-component/useRouteParams';
import VideoModal from '../../../MediaModule/VideoModal/VideoModal';
import BOMImageSingleUpload from '../../BOMImageSingleUpload';

interface IBOMCreateVideosSectionProps {
	itemIncoming: any;
	apiCallVaultData: any;
	setApiCallVaultData: any;
	actions: any;
}

interface Params {
	projectId: any;
	projectName: any;
	topVault: any;
	vault: any;
	treeabbr: any;
	workstation: any;
	isLeaf: any;
	copyStatus: any;
}

const BOMCreateVideosSection: React.FC<IBOMCreateVideosSectionProps> = (
	props
) => {
	const { itemIncoming, apiCallVaultData, setApiCallVaultData, actions } =
		props;

	const {
		projectId,
		projectName,
		topVault,
		vault,
		treeabbr,
		workstation,
		isLeaf,
		copyStatus,
	} = useRouteParams<Params>();
	const [deleteLoading, setDeleteLoading] = React.useState<any>(false);
	const [isUpdating, setIsUpdating] = React.useState<any>(false);

	const inputElRef = useRef<any>();
	const VideoModalRef = useRef<any>(null);

	// ------------------video view----------------------------------------------------------

	const openModalInPreviewMode = (link: string) => {
		return VideoModalRef.current?.open([link, false]);
	};

	//-------------------video upload--------------------------------------------------------

	const saveImage = async (file: File) => {
		setIsUpdating(true);
		BOMImageSingleUpload(
			[file],
			itemIncoming?.component_id,
			itemIncoming?.key,
			vault,
			topVault,
			setApiCallVaultData,
			apiCallVaultData,
			null,
			setIsUpdating,
			null,
			'video'
		);
	};

	// -------------------------------------------------------------------------------------------------------

	const browseFiles = () => {
		return inputElRef.current?.click?.();
	};

	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		const file: any = e.target.files?.[0];
		saveImage(file);
		// setDisable( false );
	};

	const handleInputClick = (e: React.MouseEvent) => {
		// clear the prev value on click so that same image can be uploaded again
		(e.target as HTMLInputElement).value = '';
	};

	//----------------------------------------------------------------------------------------------------------------

	const handleDeleteSelectedVideos = async (e: React.MouseEvent) => {
		e.stopPropagation();
		swal({
			title: `Delete Video Parameter`,
			text: `Are you sure?`,
			icon: 'error',
			//@ts-ignore
			buttons: {
				cancel: 'Cancel',
				permanent: {
					text: `Delete Video`,
					value: `deleteVideo`,
				},
			},
			closeOnClickOutside: false,
			closeOnEsc: false,
			className: 'delete-swal',
		}).then(async (type: string) => {
			switch (type) {
				case 'deleteVideo':
					setDeleteLoading(true);
					API.put(
						`/xcpep/component_details/${itemIncoming?.component_id}/`,
						{ [itemIncoming?.key]: null },
						{
							vault: vault == '0' ? null : vault,
							top_vault: vault == '0' ? topVault : null,
							image: true,
							rgb: true,
						},
						0
					)
						.then((res: AxiosResponse) => {
							setApiCallVaultData(!apiCallVaultData);
							setDeleteLoading(false);
						})
						.catch((err: AxiosError) => {});
					break;
				default:
					swal(`Video saved from deleting`, { icon: 'error' });
			}
		});
	};

	return (
		<Box className={styles.bomgridContainer}>
			<VideoModal ref={VideoModalRef} />
			{itemIncoming?.value == '' ? (
				<div className={styles.bomDivNoImage}>
					{isUpdating ? (
						<Box className={styles.bomUploadVideo}><CircularProgress size={40} /></Box>
					) : (
						<span onClick={actions?.includes('C') && browseFiles}>
							{itemIncoming?.paramter_name}
						</span>
					)}
				</div>
			) : isUpdating ? (
				<Box className={styles.bomUploadVideo}><CircularProgress size={40} /></Box>
			) : (
				<img
					src={itemIncoming?.['thumb_url']}
					className={styles.bomgridImages}
					alt={itemIncoming?.paramter_name}
					onClick={(e: any) => openModalInPreviewMode(itemIncoming?.['s3_url'])}
				/>
			)}
			{(copyStatus == false || copyStatus == 'false') && (
				<Box className={styles.imageOptions} sx={{ margin: '1rem 0' }}>
					<Box className={styles.optionDiv}>
						{(itemIncoming?.value != ''
							? actions?.includes('U')
							: actions?.includes('C')) && (
							<LoadingButton
								style={{ padding: 0, minWidth: '2rem' }}
								onClick={browseFiles}
								loading={isUpdating}>
								<UploadIcon
									titleAccess='Upload Video'
									sx={{ cursor: 'pointer' }}
								/>
							</LoadingButton>
						)}
						{itemIncoming?.value != '' &&
							!itemIncoming?.required &&
							actions?.includes('D') && (
								<LoadingButton
									style={{ padding: 0, minWidth: '2rem' }}
									onClick={handleDeleteSelectedVideos}
									loading={deleteLoading}>
									<DeleteIcon titleAccess='Delete' sx={{ cursor: 'pointer' }} />
								</LoadingButton>
							)}
					</Box>
				</Box>
			)}
			<input
				type='file'
				style={{ display: 'none' }}
				// required
				accept='video/*'
				id='file'
				name='file'
				ref={inputElRef}
				onChange={handleChange}
				onClick={handleInputClick}
			/>
		</Box>
	);
};

export default BOMCreateVideosSection;
