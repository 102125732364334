// react
import { useRef, useState } from "react";
import { ADMIN } from "../../Redux/Services/admin.service";

// services


// ----------------------------------------------------------------------------------

export interface RotateImageCalback {
    ( rotation: number ): Promise<boolean>;
}

function useImageRotate ( callback?: RotateImageCalback ) {

    // ----------------------------------------------------------------------------------
    // state

    const [isRotating, setIsRotating] = useState( {load:false,type:'undo'} );

    // ----------------------------------------------------------------------------------
    // ref

    const timeout = useRef<any>( null );
    const rotation = useRef<number>( 0 );

    // ----------------------------------------------------------------------------------
    // helpers

    const rotateImage = async (rotationType:any) => {
        if ( rotation.current > 360 ) {
            // (totalAngle = n*pi) >> (n = totalAngle/pi)
            const n = Math.floor( rotation.current / 360 );

            // angle = totalAngle - (pi * n)
            rotation.current = rotation.current - ( 360 * n );
        }

        if ( rotation.current === 360 ) return ;

        setIsRotating({load:true,type:rotationType});
        await callback?.( 360 - rotation.current );
        setIsRotating({load:false,type:rotationType});
        rotation.current = 0;
    };

    // ----------------------------------------------------------------------------------
    // handlers

    const handleRotate = (rotationType:any) => {
        clearTimeout( timeout.current );
        setIsRotating({load:false,type:rotationType});
        timeout.current = setTimeout( ()=>rotateImage(rotationType), 500 );
        rotationType==='redo'?
        rotation.current += 90:
        rotation.current -= 90
    };

    // ----------------------------------------------------------------------------------
    // return handler

    return { handleRotate, isRotating };
};

// ----------------------------------------------------------------------------------

export {
    useImageRotate
};