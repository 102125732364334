import { LoadingButton } from '@mui/lab';
import { Autocomplete, Chip, FormControl, TableCell, TableRow, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react';
import DownloadIcon from '@mui/icons-material/Download';
import { useRouteMatch } from 'react-router-dom';
import { ADMIN } from '../../Redux/Services/admin.service';
import { API } from '../../api-services';
import { useRouteParams } from '../../ui-reusable-component/useRouteParams';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface Params {
  mediaType: any;
}

interface MediaDownloadTableBodyI {
  topVaultName: any;
  topVaultId: any;
  countImage: any;
  groupContextAbbreviation: any;
  groupContextImageCategory: any;
  openMediaDownload: any;
  type: any;
  userpermission:any;
  groupContextId?: any;
}

const ArchitectureDownloadTableBody: React.FC<MediaDownloadTableBodyI> = (props) => {
  const { topVaultName, topVaultId, countImage, groupContextAbbreviation, groupContextImageCategory, openMediaDownload, type, groupContextId,userpermission } = props;
  const { mediaType } = useRouteParams<Params>();
  const [rangeListSelected, setRangeListSelected] = React.useState<any>([]);
  const [disableDownload, setDisableDownload] = useState<boolean>(true);
  const [loadingDownload, setLoadingDownload] = useState<boolean>(false);

  useEffect(() => {
    setDisableDownload(true);
    setLoadingDownload(false);
  }, [openMediaDownload])

  const { url } = useRouteMatch();

  const handleImageoptions = (value: any) => {
    var a = value;
    var b = 25;
    let temp = [];

    if (a) {
      for (let i = 0; i < Math.ceil(a / b); i++) {
        temp.push({
          name: `${i * 25 + 1} - ${i * 25 + 25}`,
          value: i * 25 + 25,
        });
      }
    }

    const imageRange = temp?.map(subsystem => ({
      key: subsystem.name,
      value: subsystem.value,
      text: subsystem.name,
    }));

    let rangeListSelectedValue: any = rangeListSelected && rangeListSelected?.map((itm: any) => itm.value);

    return imageRange?.filter((item: any) => ![...rangeListSelectedValue]?.includes(item.value));
  };

  const handleVideoOptions = (value: any) => {
    var a = value;
    var b = 5;
    let temp = [];
    if (a) {
      for (let i = 0; i < Math.ceil(a / b); i++) {
        temp.push({
          name: `${i * 5 + 1} - ${i * 5 + 5}`,
          value: i * 5 + 5,
        });
      }
    }

    const imageRange = temp?.map(subsystem => ({
      key: subsystem.name,
      value: subsystem.value,
      text: subsystem.name,
    }));

    let rangeListSelectedValue: any = rangeListSelected && rangeListSelected?.map((itm: any) => itm.value);

    return imageRange?.filter((item: any) => ![...rangeListSelectedValue]?.includes(item.value));
  };

  const handleSelectChange = (rangeId: any) => {
    // @ts-ignore
    setRangeListSelected([...rangeId]);
    let abc: any = [...rangeId]?.length == 0 || [...rangeId]?.length > 5 ? setDisableDownload(true) : setDisableDownload(false);
  };

  const handleDownload = async () => {
    setLoadingDownload(true);
    if (type == "groupLevel") {
      try {
        const { data: url } = await API.get("/circuit_diagram/download/", {
          top_vault: topVaultId,
          categories: groupContextImageCategory,
          abbreviation: groupContextAbbreviation,
          range: rangeListSelected?.map((item: any) => item?.value)?.join(),
          group: groupContextId,
        }, 0);
        setLoadingDownload(false);
        setRangeListSelected([]);
        window.open(url, "_blank", "noopener noreferrer");
      } catch (e) {
        setLoadingDownload(false);
        // ADMIN.toast.throwError(e, "Could not download");
      }
    }
    else {
      try {

        const { data: url } = await API.get("/circuit_diagram/download/", {
          top_vault: topVaultId,
          categories: groupContextImageCategory,
          abbreviation: groupContextAbbreviation,

          range: rangeListSelected?.map((item: any) => item?.value)?.join(),
          subsystem_download: "true",
        }, 0);
        setLoadingDownload(false);
        setRangeListSelected([]);
        window.open(url, "_blank", "noopener noreferrer");
      } catch (e) {
        setLoadingDownload(false);
        // ADMIN.toast.throwError(e, "Could not download");
      }
    }
  };
var TopVaultPermission=  userpermission!==undefined && userpermission?.filter((item:any,index:any)=>{return (item?.top_vault!=="all" && item?.action!=null) &&  item?.top_vault==topVaultId })[0]
  return (
    <TableRow>
      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light',width: '14rem', }}>{topVaultName}</TableCell>
      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', width: 'fit-content' }}>
        {countImage == 0 ? <span>No Image Available</span> :
          <FormControl variant='standard' sx={{ minWidth: '250px' }} >
            <Autocomplete
              multiple
              disableClearable
              id="Commodity"
              value={rangeListSelected}
              limitTags={4}
              disableCloseOnSelect
              sx={{ width: '100%' }}
              onChange={(event, newValue) => {
                handleSelectChange(newValue);
              }}
              options={(url.includes("image-repository/video") ? handleVideoOptions(countImage && countImage) : handleImageoptions(countImage && countImage))}
              // @ts-ignore
              getOptionLabel={(option) => option?.text}
              renderTags={(tagValue, getTagProps) =>
                tagValue.map((option, index) => (
                  <Chip
                    size="small"
                    //  @ts-ignore
                    sx={{ backgroundColor: '#007fff14', color: "primary.main", fontSize: '1rem' }}
                    // @ts-ignore
                    label={option?.text}
                    {...getTagProps({ index })}
                  />
                ))
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  placeholder='Select Range'
                  sx={{
                    width: '100%',
                    ".MuiInputBase-root.MuiInput-root": {
                      fontSize: '1rem !important'
                    },
                    '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                    '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
                    '.MuiSvgIcon-root': { color: 'primary.main' },
                  }}
                />
              )}
            />
          </FormControl>}
      </TableCell>
      <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', width: '5rem' }}>

        {
          
        
        <LoadingButton
          sx={{
            padding: "0.3rem",
            lineHeight: 1,
            "&:hover": {
              transform: 'Scale(1.05)',
              transition: 'transform 0.5s ease',
            },
            '.MuiCircularProgress-root': {
              color: 'primary.main',
            },
          }}

          loading={loadingDownload} disabled={!disableDownload && TopVaultPermission && TopVaultPermission?.action?.includes("R") ?false:true} onClick={handleDownload}>
          <DownloadIcon />
        </LoadingButton>
}
      </TableCell>
    </TableRow>
  );
}

export default ArchitectureDownloadTableBody;