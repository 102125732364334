import { API } from "../../api-services";


class user{

 getUsers = ( id: any ) => {
    return API.get( `/auth/user/${id}/` );
}
guestUser = ( id: any ) => {
    return API.get( `auth/user/?invited_by__user=${id}` );
};
getAllusersDetails = () => {
    return API.get( "/auth/user_details/", );
};
getProjectAndProduct = () => {
    return API.get( "/auth/authorized_projects/" );
};
getModulesPermission = ( product: any ) => {
    return API.get( "/auth/product_permission/", { product: product } );
};
postPermissionRequest = ( module: string, sub_module: string, action: string, product: string ) => {
    return API.post( "/auth/user_permission_request/", {
        module: module,
        sub_module: sub_module,
        action: action,
        product: product,
        type: "Request"
    } );
};

updateUserDetails = ( email: any, id: any, designation: any, countryCode?:any,phone?: any) => {
    return API.patch( `/auth/user/${id}/`, phone == null || countryCode == null ? {
        email: email,
        id: id,
        designation: designation,
    }:{email: email,
        country_code:countryCode,
        phone: phone,
        id: id,
        designation: designation,} );
};

organisationDetailData = (  ) => {
    return API.get( "auth/organisation/");
};
getAllUsers = ( page?: number, numberOfItems?: number, active?: boolean ) => {
    return API.get( "/auth/user/", {
        key: page,
        page_items: numberOfItems,
        is_active: active,
    } );
};

getByType = ( user_type: string, page?: number, numberOfItems?: number, active?: boolean ) => {
    return API.get( "/auth/user/", {
        user_type: user_type,
        key: page,
        page_items: numberOfItems,
        is_active: active,
    } );
};

getGroupUsers = ( id: number ) => {
    return API.get( `/auth/user/?designation=${id}` );
};

createUser = ( user: any ) => {
    return API.post( "/auth/user/", user );
};

updateUser = ( user: any ) => {
    return API.put( "/auth/user/" + user.existing.id + "/", user );
};

makeUserActive_Inactive = ( userid: number, action: object ) => {
    return API.put( "/auth/user/" + userid + "/", action );
};

forgotPassword = ( email: string ) => {
    return API.post( "/auth/forgot_password/", { email } );
};
getMailDate=(projectId:any)=>{
return API.get("analytics/sent_mail_list/",{project:projectId})
};
getMailProductList=(projectId:any,module:any)=>{
return API.get("/xcpep/top_vault/",{project:projectId,module:module})
}
getGroups = () => {
    return API.get( "auth/fetch_group/" );
};



getPermissions = ( module: any, project?: any, user_type?: any,user_name?:any ) => {
    return API.get( "/auth/user_submodule_list/", {
        module: module,
        project: project,
        user_type: user_type,
        user_name:user_name
    } );
};

getAllusers = (remove_admin?:boolean) => {
    return API.get( "/auth/user_list/", {remove_admin : remove_admin ? remove_admin : false } );
};
getMomAporvedUsers = (module?:any,project?:any)=>{
    return API.get("auth/user_list_drive",{module:module,project:project})
}


getsearchAllusers=(module:any,user_type:any)=>{
return API.get("auth/user_submodule_list/",{module:module,user_type:user_type});
};
getUsersCount = ( project?: any ) => {
    return API.get( '/auth/user_hierarchy_counts/', { project: project } );
};

getAllProjects = () => {
    return API.get( '/xcpep/project_names/' );
};

getdashboardData=(QuerData:any)=>{
  return API.get(`/analytics/dashboard_mail/`,{date:QuerData?.date,top_vault:QuerData?.top_vault,project:QuerData?.project},0)
}



updateImage = ( image: any, id: any ) => {
    const data = new FormData();
    data.append( "image", image );
    return API.patch( `/auth/user/${id}/`, data, {}, 0 );
};




}

const USER = new user();

export { USER };