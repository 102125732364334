import {
    createEntityAdapter,
    createReducer,
    createSelector,
    EntityState,
} from "@reduxjs/toolkit";
// import { booleanDataTypes, dropdownDataTypes, numericDataTypes, textDataTypes } from "app/constants/parametersDataTypes.constants";
import { isNull, mapValues, unionBy } from "lodash";
import {
    getBOMConfigurations,
    getBOMConfigurationsByCategory,
    getBOMConfigurationsByDatatype,
    getFeatureConfigurations,
    getFeatureConfigurationsByCategory,
    getIdeaConfigurations,
    getInwardConfigurations,
    getParameterData, getParameterDataOption,//    //Data analysis dropdown option
    getProcessConfigurations,
    resetParameterConfigs,
    getCombinationOptions
} from "../Actions/parameterConfig.actions";
import { ID } from "../../utlis/commonInterfaces";
import { RootState } from "../Store/storeConfigurations";
import { numericDataTypes, booleanDataTypes, dropdownDataTypes, textDataTypes } from "../../utlis/parameterDataTypes.constants";

// -----------------------------------------------------------------------------------
// entity adaptor
type Extra<
    T extends "table" | "table_child" | "other" | "process"
    > = T extends "table"
    ? { data_type: "table"; }
    : T extends "table_child"
    ? { table_id: ID; parameter_table_id: ID; }
    : { table_id: null; parameter_table_id: null; };

export type ParameterConfig<
    T extends "table" | "table_child" | "other" | "process" = any
    > = {
        id: ID;

        // org | database
        name: string;
        unit: string;
        data_type: string;
        exceptions: string[];
        allowed_values: string[];
        not_allowed_values: string[];
        default_value: string;
        parameter_info: ID | null;
        table_id: ID | null;

        // general
        sequence: number;
        required: boolean;
        parameter_id: ID;
        parameter_table_id: ID | null;
        /** parameter_info instance, used by custom form fields */
        info_details: any | null;

        // non process parameter config
        category: ID;
        module: number; //enum
        database: string;
        project: ID;
        top_vault: ID | null;

        // bom
        ml_parameters?: ID[];

        // idea
        editable?: any;

        // process parameter config
        process: ID;
        time?: number;
    } & Extra<T>;

export const parameterConfigAdaptor = createEntityAdapter<ParameterConfig>( {
    selectId: parameterConfig => parameterConfig.id,
    sortComparer: ( a, b ) => a.sequence - b.sequence,
} );

// -----------------------------------------------------------------------------------
// interfaces & initialState

type AdditionalFields = {};

export type IParameterConfigReducer = EntityState<ParameterConfig> &
    AdditionalFields;

const additionalFields: AdditionalFields = {};

const initialState: IParameterConfigReducer = Object.assign(
    {},
    parameterConfigAdaptor.getInitialState(),
    additionalFields
);

const setConfigs = ( state: any, action: any ) => {

    parameterConfigAdaptor.setAll( state, action.payload.configs );
};

// -----------------------------------------------------------------------------------
// reducer

const ParameterConfigReducer = createReducer( initialState, builder => {
    builder.addCase( getBOMConfigurations.fulfilled, setConfigs );

    //Data analysis dropdown option
    builder.addCase( getParameterData.fulfilled, setConfigs );

    //getParameterDataOption
    builder.addCase( getParameterDataOption.fulfilled, setConfigs );
    //getCombinationOptions
    builder.addCase( getCombinationOptions.fulfilled, setConfigs );

    builder.addCase( getBOMConfigurationsByDatatype.fulfilled, setConfigs );

    builder.addCase( getBOMConfigurationsByCategory.fulfilled, setConfigs );

    builder.addCase( getFeatureConfigurations.fulfilled, setConfigs );

    builder.addCase( getFeatureConfigurationsByCategory.fulfilled, setConfigs );

    builder.addCase( getProcessConfigurations.fulfilled, setConfigs );

    builder.addCase( getIdeaConfigurations.fulfilled, setConfigs );

    builder.addCase( resetParameterConfigs, () => initialState );

    builder.addCase( getInwardConfigurations.fulfilled, setConfigs );
} );

// -----------------------------------------------------------------------------------
// selectors

const selectors = parameterConfigAdaptor.getSelectors<RootState>(
    state => state.parameterConfigs
);

const selectByCategoryId = createSelector(
    selectors.selectAll,
    ( _state: RootState, categoryId: ID | null ) => categoryId,
    ( configs, categoryId ) => {
        return categoryId ? configs.filter( c => c.category === categoryId ) : [];
    }
);

/** IMP: To be only used in workstation creatation, as union operation is applied on parameter_id, and config ids are not accurate */
const selectByCategoryIds = createSelector(
    selectors.selectAll,
    ( _state: RootState, categoryIds: ID[] ) => categoryIds,
    ( configs, categoryIds ) => {
        const filteredConfigs = configs.filter( config =>
            categoryIds.includes( config.category )
        );
        return unionBy( filteredConfigs, config => config.parameter_id );
    }
);

const selectByTableId = createSelector(
    selectors.selectAll,
    ( _state: RootState, tableId: ID | null ) => tableId,
    ( configs, id ) => {
        return ( id
            ? configs.filter( c => c.parameter_table_id === id )
            : [] ) as ParameterConfig<"table_child">[];
    }
);

/** IMP: To be only used in workstation creatation, as union operation is applied on parameter_id, and config ids are not accurate */
const selectByParameterIds = createSelector(
    selectors.selectAll,
    ( _state: RootState, parameterIds: ID[] ) => parameterIds,
    ( configs, parameterIds ) => {
        const filteredConfigs = configs.filter( config =>
            parameterIds.includes( config.parameter_id )
        );
        return unionBy( filteredConfigs, config => config.parameter_id );
    }
);

const selectByDataType = createSelector(
    selectors.selectAll,
    // TODO: change typings og data_type
    ( _state: RootState, data_type: string[] ) => data_type,
    ( configs, data_type ) => {
        return configs.filter( config => config.data_type === data_type[0] || config.data_type === data_type[1] );
    }
);

/**
 * no images
 */
const selectNonImageConfigs = createSelector( selectors.selectAll, configs => {
    return configs.filter( config => config.data_type !== "image" );
} );



/**
 * no images, no table children
 */
const selectNonImageNonTableChildernConfigs = createSelector(
    selectNonImageConfigs,
    configs => {
        return configs.filter( config => isNull( config.parameter_table_id ) );
    }
);

//select Document data tyoe
const selectDocumentConfigs = createSelector( selectors.selectAll, configs => {
    return configs.filter( config => config.data_type === "document" );
} );

/**
 * no images, not tables, no tablel children
 */
const selectNonImageNonTableConfigs = createSelector(
    selectNonImageNonTableChildernConfigs,
    configs => {
        return configs.filter( config => config.data_type !== "table" && config.data_type !== "document" );
    }
);

const selectNonImageNonTableChildernConfigIds = createSelector(
    selectNonImageNonTableChildernConfigs,
    configs => {
        return configs.map( config => config.id );
    }
);

const selectConfigIdNameMap = createSelector(
    selectors.selectEntities,
    entities => {
        return mapValues( entities, o => o?.name || "" );
    }
);

const selectTableConfigs = createSelector(
    selectors.selectAll,
    configs => configs.filter( config => config.data_type === "table" )
);

const selectNumericConfigs = createSelector(
    selectors.selectAll,
    configs => configs.filter( config => numericDataTypes.includes( config.data_type ) )
);
const selectBoolConfigs = createSelector(
    selectors.selectAll,
    configs => configs.filter( config => booleanDataTypes.includes( config.data_type ) )
);
const selectDropdownConfigs = createSelector(
    selectors.selectAll,
    configs => configs.filter( config => dropdownDataTypes.includes( config.data_type ) )
);

const selectTextConfigs = createSelector(
    selectors.selectAll,
    configs => configs.filter( config => textDataTypes.includes( config.data_type ) )
);

const selectByProcessId = createSelector(
    selectors.selectAll,
    ( _state: RootState, processId: ID ) => processId,
    ( configs, processId ) => {
        return configs.filter( config => config.process === processId );
    }
);

const selectWeightConfig = createSelector(
    selectors.selectAll,
    configs => configs.filter( parameter => parameter.name === "Weight" )
);

//Data analysis dropdown option
const selectOptions = createSelector( selectors.selectAll, parameter => {
    return parameter.map( parameter => ( {
        key: parameter.parameter_id,
        value: parameter.parameter_id,
        text: parameter.name,
    } ) );
} );


const parameterConfigSelectors = Object.assign(
    {},
    {
        selectByCategoryId,
        selectByCategoryIds,
        selectByTableId,
        selectByParameterIds,
        selectByDataType,
        selectNonImageConfigs,
        selectConfigIdNameMap,
        selectNonImageNonTableChildernConfigs,
        selectNonImageNonTableChildernConfigIds,
        selectByProcessId,
        selectNonImageNonTableConfigs,
        selectTableConfigs,
        selectNumericConfigs,
        selectBoolConfigs,
        selectDropdownConfigs,
        selectTextConfigs,
        selectDocumentConfigs,
        selectOptions,
        selectWeightConfig
    },
    selectors
);

// -----------------------------------------------------------------------------------
// exports

export {
    ParameterConfigReducer,
    initialState as ParameterConfigReducerInit,
    parameterConfigSelectors,
};
