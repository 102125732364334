import React, { useEffect, useState } from "react";
import {
  Badge,
  Box,
  Button,
  Checkbox,
  IconButton,
  Skeleton,
  Table,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory, useParams, useRouteMatch } from "react-router-dom";
import styles from "../DataBaseLandingPage.module.scss";
import Drawer from "@mui/material/Drawer";

import Divider from "@mui/material/Divider";
import AddIcon from "@mui/icons-material/Add";
import { API } from "../../api-services";
import { ParameterTable } from "./ParameterTable";
import { AddParameterDataBaseModal } from "./AddParameterDataBaseModal";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import { useBaseModal } from "../../ComponentUI/SearchPage/useBaseModal";
import recyleBin from "../../Assets/images/deletefill.svg";
import DataBaseDrawer from "../DataBaseDrawer";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { useDocumentTitle } from "../../ui-reusable-component/useDocumentTitle";
import { FeatureParameterTable } from "./FeatureParameterTable";
import { AddParameterDataBaseFeatureModal } from "./AddParameterDataBaseFeatureModal";

type Anchor = "top" | "left" | "bottom" | "right";
type Props = {};

const FeatureLibraryLandingPage = (props: Props) => {
  const history = useHistory();
  useDocumentTitle("Feature Database");
  const { url } = useRouteMatch();
  const { projectId, datatype, search } = useParams<any>();
  const [ParentsCounter, setParentsCounter] = useState<any>(0);
  const [TableCounter, setTableCounter] = useState<any>(0);
  const [Loader, setLoader] = useState<any>(false);
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const [RecyclebinCounter, setRecyclebinCounter] = useState<any>(0);
  const parameterModal = useBaseModal();

  const AddParameter = () => {
    parameterModal.open();
  };
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [DataTypes, setDataTypes] = useState<any>([]);

  const [recyclebinCount, setRecyclebinCount] = useState<any>();
  const [useraction, setuseraction] = useState<any>(null);
  useEffect(() => {
    API.get("/auth/mui_trash_view/", {
      bin_type: "featurelibrary",
      count: true,
    }).then((res: any) => {
      setRecyclebinCount(res?.data);
    });
  }, [RecyclebinCounter]);
  React.useEffect(() => {
    API.get(
      "/auth/calibration_user_permission/",
      {
        action: true,
        database: true,
        module: "DB-Parameter Library",
      },
      0
    )
      .then((res: any) => {
        setuseraction(res?.data?.action);
        sessionStorage.setItem("DBPermission", res.data.action);
      })
      .catch((err: any) => { });
  }, []);

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === "keydown" &&
          ((event as React.KeyboardEvent).key === "Tab" ||
            (event as React.KeyboardEvent).key === "Shift")
        ) {
          return;
        }

        setState({ ...state, [anchor]: open });
      };
  const handleClick = () => {
    history.push("/internallanding");
  };

  const DataTypeHanlder = (type: any) => {
    history.push(`/data-base/Feature-library/${type}/0`);
  };

  useEffect(() => {
    if (ParentsCounter == 0) {
      setLoader(true);
      API.get(
        "/api/db/feature_library/",
        {
          type_details: true,
        },
        0
      )
        .then((res: any) => {
          setDataTypes(res.data);
          setLoader(false);
        })
        .catch((err: any) => {
          setLoader(false);
        });
    } else {
      API.get(
        "/config/parameter_types/",
        {
          parameter_database: true,
        },
        0
      )
        .then((res: any) => {
          setDataTypes(res.data);
        })
        .catch((err: any) => { });
    }
  }, [projectId, ParentsCounter]);

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      role="presentation"
    // onClick={toggleDrawer(anchor, false)}
    // onKeyDown={toggleDrawer(anchor, false)}
    >
      <DataBaseDrawer />
    </Box>
  );

  useEffect(() => {
    document.getElementById(datatype)?.scrollIntoView({
      block: "nearest",
      inline: "start",
      behavior: "smooth",
    });
  }, []);

  const RecycleBinHanlder = () => {
    history.push("/data-base/recycleBinFeatureLibrary");
  };
  return (
    <div style={{ width: "100%" }}>
      <Box sx={{ margin: "0 0.5rem", width: "99%" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            width: "100%",
          }}
        >
          <Box
            sx={{ display: "flex", alignItems: "center", columnGap: "1rem" }}
          >
            <IconButton
              sx={{ cursor: "pointer" }}
              title="Go Back"
              onClick={handleClick}
            >
              <ArrowBackIcon color="primary" titleAccess="Go Back" />
            </IconButton>
            <Typography
              sx={{
                fontSize: "1rem",
                whiteSpace: "nowrap",
                // fontWeight: "bold",
              }}
            >
              Database (Feature Library)
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              columnGap: "1rem",
              padding: "0 0.5rem",
            }}
          >
            {useraction && useraction?.includes("C") && (
              <Button
                size="small"
                onClick={() => AddParameter()}
                // variant="contained"
                sx={{
                  cursor: "pointer",
                  padding: "0.2rem 0.5rem",
                  "&:hover": {
                    transform: "Scale(1.05)",
                    transition: "transform 0.5s ease",
                  },
                }}
                startIcon={<AddIcon sx={{ marginRight: "-0.5rem" }} />}
              >
                ADD Parameter
              </Button>
            )}
            <Box sx={{ width: "2.5rem" }}>
              {recyclebinCount && recyclebinCount?.count > 0 ? (
                <Typography
                  title="Recycle bin"
                  style={{ cursor: "pointer" }}
                  onClick={() => RecycleBinHanlder()}
                >
                  <Badge
                    badgeContent={`${recyclebinCount && recyclebinCount?.count
                      }`}
                    color="error"
                  >
                    <img src={recyleBin} style={{ height: "1.6rem" }} />
                  </Badge>
                </Typography>
              ) : (
                <DeleteOutlineIcon
                  sx={{ color: "primary.main", fontSize: "2rem" }}
                />
              )}
            </Box>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "flex-start", width: "100%" }}>
        <Box className={styles.expandIconBox}>
          <div className={styles.iconSec}>
            {(["left"] as const).map((anchor) => (
              <React.Fragment key={anchor}>
                <IconButton
                  size="medium"
                  sx={{
                    backgroundColor: "primary.light",
                    color: "primary.main",
                  }}
                  onClick={toggleDrawer(anchor, true)}
                >
                  <AddIcon sx={{ fontSize: "1.7rem" }} />
                </IconButton>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  className={styles.drawerContainer}
                  PaperProps={{
                    sx: {
                      backgroundColor: "aliceblue",
                    },
                  }}
                >
                  <Box
                    sx={{ padding: "1rem 0rem", paddingBottom: "0" }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3"
                  >
                    <Typography
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "1rem",
                      }}
                    >
                      Select Module
                    </Typography>
                    <Divider sx={{ margin: "0.5rem" }} />
                    {list(anchor)}
                  </Box>
                </Drawer>
                <Typography
                  style={{
                    transform: "rotate(270deg)",
                    whiteSpace: "nowrap",
                    marginTop: "40vh",
                    cursor: "pointer",
                    fontSize: "1rem",
                  }}
                  onClick={toggleDrawer(anchor, true)}
                >
                  Select Module
                </Typography>
              </React.Fragment>
            ))}
          </div>
        </Box>
        {Loader ? (
          <Box
            sx={{
              width: "100%",
              height: { lg: "86vh", xl: "89vh" },
              display: "flex",
            }}
          >
            <Box sx={{ width: "16%", padding: "0 1rem 1rem" }}>
              <Skeleton
                variant="rectangular"
                sx={{ width: "100%", marginBottom: "1rem" }}
                height={60}
              />
              <Skeleton
                variant="rectangular"
                sx={{ width: "100%", marginBottom: "1rem" }}
                height={60}
              />
              <Skeleton
                variant="rectangular"
                sx={{ width: "100%", marginBottom: "1rem" }}
                height={60}
              />
              <Skeleton
                variant="rectangular"
                sx={{ width: "100%", marginBottom: "1rem" }}
                height={60}
              />
              <Skeleton
                variant="rectangular"
                sx={{ width: "100%", marginBottom: "1rem" }}
                height={60}
              />
              <Skeleton
                variant="rectangular"
                sx={{ width: "100%", marginBottom: "1rem" }}
                height={60}
              />
              <Skeleton
                variant="rectangular"
                sx={{ width: "100%", marginBottom: "1rem" }}
                height={60}
              />
              <Skeleton
                variant="rectangular"
                sx={{ width: "100%", marginBottom: "1rem" }}
                height={60}
              />
              <Skeleton
                variant="rectangular"
                sx={{ width: "100%", marginBottom: "1rem" }}
                height={60}
              />
              <Skeleton
                variant="rectangular"
                sx={{ width: "100%", marginBottom: "1rem" }}
                height={60}
              />
              <Skeleton
                variant="rectangular"
                sx={{ width: "100%", marginBottom: "1rem" }}
                height={60}
              />
              <Skeleton
                variant="rectangular"
                sx={{ width: "100%", marginBottom: "1rem" }}
                height={60}
              />
            </Box>
            <Box
              sx={{
                height: "auto",
                width: "100%",
                padding: "0 1rem",
              }}
            >
              <Skeleton height={"3rem"} animation="wave" />
              <Skeleton height={"3rem"} animation={false} />
              <Skeleton height={"3rem"} animation="wave" />
              <Skeleton height={"3rem"} animation={false} />
              <Skeleton height={"3rem"} animation="wave" />
              <Skeleton height={"3rem"} animation={false} />
              <Skeleton height={"3rem"} animation="wave" />
              <Skeleton height={"3rem"} animation={false} />
              <Skeleton height={"3rem"} animation="wave" />
              <Skeleton height={"3rem"} animation={false} />
              <Skeleton height={"3rem"} animation="wave" />
              <Skeleton height={"3rem"} animation={false} />
              <Skeleton height={"3rem"} animation="wave" />
              <Skeleton height={"3rem"} animation={false} />
              <Skeleton height={"3rem"} animation="wave" />
              <Skeleton height={"3rem"} animation={false} />
              <Skeleton height={"3rem"} animation="wave" />
              <Skeleton height={"3rem"} animation={false} />
              <Skeleton height={"3rem"} animation="wave" />
              <Skeleton height={"3rem"} animation={false} />
            </Box>
          </Box>
        ) : (
          <>
            <Box sx={{ width: "100%", height: { lg: "86vh", xl: "89vh" }, display: "flex", alignItems: "flex-start", gap: '0.5rem' }}>
              <Box
                sx={{
                  width: { lg: "17%", xl: "15%" }, maxHeight: { lg: "86vh", xl: "89vh" },
                  marginLeft: "0.5rem",
                  borderLeft: '1px solid',                  
                  paddingRight: '0.5rem',
                }}
                adl-scrollbar="true"
                adl-scrollbar-width="0.3">
                {DataTypes &&
                  DataTypes?.map((item: any, index: any) => {
                    return (
                      <>
                        <Box
                          key={index}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            cursor: "pointer",
                            ':hover': {
                              backgroundColor: '#f0f0f0',
                            }
                          }}
                          onClick={() => DataTypeHanlder(item?.name_type)}
                        >
                          <Box
                            id={datatype}
                            className={search == 0 && datatype == item?.name_type ? styles.active_box_container : styles.box_container}>
                            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                              <span style={{ whiteSpace: 'nowrap' }}>--</span>
                              <span>{item?.name_type}</span>
                            </Box>
                            <Box>
                              <span>({item?.count})</span>
                            </Box>
                          </Box>

                          {/* <div
                            id={datatype}
                            className={
                              search == 0 && datatype == item?.name_type
                                ? styles.active_box_container
                                : styles.box_container
                            }
                          >
                            <span>{item?.name_type}</span>
                            <div>
                              <span>({item?.count} Parameters )</span>
                            </div>
                          </div> */}
                        </Box>
                      </>
                    );
                  })}
              </Box>
              <Box sx={{ height: "89vh", width: "100%", padding: '0 0.5rem', borderLeft: '1px solid' }}>
                {DataTypes && DataTypes?.length > 0 && (
                  <FeatureParameterTable
                    useraction={useraction}
                    setRecyclebinCounter={setRecyclebinCounter}
                    TableCounter={TableCounter}
                    setParentsCounter={setParentsCounter}
                  />
                )}
              </Box>
            </Box>
          </>
        )}
      </Box>

      {parameterModal.isOpen && (
        <AddParameterDataBaseFeatureModal
          Modaltype={"Add"}
          isOpen={parameterModal.isOpen}
          onCloseModal={parameterModal.close}
          datatype={datatype}
          setTableCounter={setTableCounter}
          setDataTypeCounter={setParentsCounter}
        />
      )}
    </div>
  );
};
export default FeatureLibraryLandingPage;
