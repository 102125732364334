import { isArray, isEqual, isObject, transform, xor } from "lodash";
import moment from "moment";
import { ObjectType } from "./commonInterfaces";

/**
 * removes all the numbers in the string.
 * @param {String} string
 * @returns {String} string with no numbers in it.
 */

const nonNumbersInString = ( string: string ) => string.replace( /\d/g, "" );

/**
 * removes all the characters except numbers in the string.
 * @param {String} string
 * @returns {String} string with no characters other than numbers in it.
 */

const numbersInString = ( string: string ) => parseInt( string.replace( /\D/g, "" ) );

/**
 * indicates whether the paramater given is integer or not.
 * @param {any} value
 * @returns {Boolean} boolean which indicates whether the paramater given is integer or not.
 * - **imp** "Number.isInteger(val:any)" can also b used.
 */
const isInt = ( value: any ) => Number( value ) && value % 1 === 0;

/**
 *
 * @param {any} value
 * @returns {Boolean} boolean which indicates whether the paramater given is float or not.
 */

const isFloat = ( value: any ) => Number( value ) && value % 1 !== 0;

/**
 * Deep diff between two object, using lodash
 * @link https://gist.github.com/Yimiprod/7ee176597fef230d1451
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
function difference ( object: ObjectType, base: ObjectType ) {
    function changes ( object: ObjectType, base: ObjectType ) {
        return transform( object, function ( result: any, value, key ) {
            if ( !isEqual( (value)!, base[key] ) ) {
                result[key] =
                    isArray( value ) && isArray( base[key] )
                        ? value
                        : isObject( value ) && isObject( base[key] )
                            ? changes( value, base[key] )
                            : value;
            }
        } );
    }
    return changes( object, base );
}

function shallowDifference ( object: ObjectType, base: ObjectType ) {
    return transform( object, function ( result: any, value, key ) {
        if ( !isEqual( ( value )!, base[key] ) ) {
            result[key] = value;
        }
    } );
}

/**
 * @description To Convert Array Of Objects to Objects of Objects
 */
const convertToArray = ( array: any, key: any ) => {
    const initialValue = {};
    return array.reduce( ( obj: any, item: any ) => {
        return {
            ...obj,
            [toSnakeCase( item[key] )]: item,
        };
    }, initialValue );
};

/** @description To Convert a string to snake case*/
const toSnakeCase = ( str: any ) =>
    str &&
    str
        .match(
            /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g
        )
        .map( ( x: any ) => x.toLowerCase() )
        .join( "_" );

//@ts-ignore
const uniqueElements = arr => [...new Set( arr )];

const isNumber = ( val: any ) => typeof val === "number" && val === val;

const normalize = ( arr: ObjectType[], key?: string ) => {
    const k = key ?? "id";

    const finalObj: ObjectType = {};
    arr.forEach( el => {
        finalObj[el[k]] = el;
    } );
    return finalObj;
};

const normalizePop = ( arr: ObjectType[], key?: string ) => {
    const k = key ?? "id";

    const finalObj: ObjectType = {};
    arr.forEach( el => {
        finalObj[el[k]] = el;
        delete finalObj[el[k]][k];
    } );
    return finalObj;
};

/**
 * @description Returns 'TRUE' if any key value is redundant in the array of objects
 * @param array Input Array
 * @param key Property value to check for duplicate value
 */
const duplicateElements = ( array: any, key: any ) => {
    let valueArr = array.map( function ( rd: any ) {
        return rd[key];
    } );
    valueArr = valueArr.filter( ( el: any ) => el !== "" );
    const isDuplicate = valueArr.some( function ( item: any, idx: any ) {
        return valueArr.indexOf( item ) !== idx;
    } );
    return isDuplicate;
};

const dynamicSort = ( property: string ) => {
    var sortOrder = 1;
    if ( property[0] === "-" ) {
        sortOrder = -1;
        property = property.substr( 1 );
    }
    return function ( a: any, b: any ) {
        /* next line works with strings and numbers,
         * and you may want to customize it to your needs
         */
        var result =
            a[property] < b[property] ? -1 : a[property] > b[property] ? 1 : 0;
        return result * sortOrder;
    };
};

/**
 * Hack for pipeline operator
 * - it works with a mix of promises and non-promises
 * - link for implemention, example:
 * {@link https://repl.it/@devmastery/PromisablePipe#main.js}
 * - youtube video (by "Dev Mastery") link:
 * {@link https://youtu.be/38q7aSu52NY}
 * @param fns
 */
const pipe = <P extends any = any> ( ...fns: Function[] ) => ( param: P ) =>
    fns.reduce(
        ( result: any, fn ) => ( result.then && result.then( fn ) ) || fn( result ),
        param
    );

/**
 * @description Returns an array sorted based on the property, generally used as a callback function in sort method
 * @param property Object property value to be sorted
 */
function dynamicsort ( property: any, order: any ) {
    var sort_order = 1;
    if ( order === "desc" ) {
        sort_order = -1;
    }
    return function ( a: any, b: any ) {
        // a should come before b in the sorted order
        if ( a[property] < b[property] ) {
            return -1 * sort_order;
            // a should come after b in the sorted order
        } else if ( a[property] > b[property] ) {
            return 1 * sort_order;
            // a and b are the same
        } else {
            return 0 * sort_order;
        }
    };
}

const currentDate = moment( new Date() ).format( "DD MM YYYY" );

function convertRemToPixels ( rem: number ) {
    return (
        rem * parseFloat( getComputedStyle( document.documentElement ).fontSize )
    );
}

/**
 * toggle items in array, i.e, add or remove based on whether the array contains that element ot not
 */

const toggleArrEl = ( array: any[], item: any ) => xor( array, [item] );

/**
 * @description To compare an array of strings
 * @param a string
 * @param b string
 */
function naturalCompare ( a: any, b: any ) {
    var ax: any = [],
        bx: any = [];

    a.replace( /(\d+)|(\D+)/g, function ( _: any, $1: any, $2: any ) {
        ax.push( [$1 || Infinity, $2 || ""] );
    } );
    b.replace( /(\d+)|(\D+)/g, function ( _: any, $1: any, $2: any ) {
        bx.push( [$1 || Infinity, $2 || ""] );
    } );

    while ( ax.length && bx.length ) {
        var an = ax.shift();
        var bn = bx.shift();
        var nn = an[0] - bn[0] || an[1].localeCompare( bn[1] );
        if ( nn ) return nn;
    }

    return ax.length - bx.length;
}

export {
    nonNumbersInString,
    numbersInString,
    isInt,
    isFloat,
    difference,
    convertToArray,
    toSnakeCase,
    uniqueElements,
    isNumber,
    normalize,
    normalizePop,
    duplicateElements,
    dynamicSort,
    pipe,
    dynamicsort,
    currentDate,
    convertRemToPixels,
    toggleArrEl,
    naturalCompare,
    shallowDifference,
};
