import { Box, Typography } from "@mui/material";
import * as React from "react";
import styles from "./Landing.module.scss";

interface ProductProgressProps {
  progressData: any;
}

const ProductProgress: React.FunctionComponent<ProductProgressProps> = (
  props
) => {
  const { progressData } = props;
  const totalPercent =
    progressData &&
    progressData?.completed +
    progressData?.in_progress +
    progressData?.not_started;
  const getPercentageValue: any = (value: number, total: number) => {
    var etotal = total >= 1 ? total : 1;
    return Math.round((value / etotal) * 100);
  };

  const getCompleted: any = getPercentageValue(
    progressData && progressData?.completed / (totalPercent== 0 ? 1 : totalPercent)
  );
  const getInProgress: any = getPercentageValue(
    progressData && progressData?.in_progress / (totalPercent== 0 ? 1 : totalPercent)
  );
  const getNotStarted: any = getPercentageValue(
    progressData && progressData?.not_started / (totalPercent== 0 ? 1 : totalPercent)
  );
  const workInProgress = getCompleted + getInProgress;

  return (
    <>
      {/* <Typography className={styles.progText}>&nbsp;{getCompleted}%</Typography> */}      
      {
        <Box className={styles.lineGraph} sx={{display:"flex",lineHeight:{lg:'1.5rem',xl:'2rem'}, background: `linear-gradient(90deg, #437d42 ${getCompleted}%, #fbec5d ${workInProgress}%, #f95959 100%)`, borderRadius: '0.3rem' }} >
          <div style={{ color: "white",fontWeight:'500', width: `${getCompleted}%`, paddingLeft: '5px', borderTopLeftRadius: '3px', borderBottomLeftRadius: '3px', fontSize: '10px', height: '1.5rem', alignItems: 'center', display: 'flex' }} title={`${getCompleted}% Completed`}>{`${getCompleted}%`}</div> 
          {getCompleted !== 100 && getInProgress != 0 && <div title={`${getInProgress}% In-Progress`} style={{ width: `${getInProgress}%`, color: "#007fff", fontWeight:'500',fontSize: '10px', height: '1.5rem', alignItems: 'center', display: 'flex' }} ></div>}
          {getCompleted !== 100 && getNotStarted != 0 && <div title={`${getNotStarted}% Not Started`} style={{ width: `${getNotStarted}%`, color: "#007fff", fontWeight:'500',borderTopRightRadius: '3px', borderBottomRightRadius: '3px', fontSize: '10px', height: '1.5rem', alignItems: 'center', display: 'flex' }}></div>}
        </Box>}

    </>
  );
};

export default ProductProgress;
