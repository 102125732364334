import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Divider,
  Drawer,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import AddIcon from "@mui/icons-material/Add";
import styles from "./CostingCreateSystemLandingPage.module.scss";
import { useHistory } from "react-router-dom";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import InsightsIcon from "@mui/icons-material/Insights";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { CostingCreateDrawer } from "./CostingCreateDrawer";
import CostingCreateSystemInsideBody from "./CostingCreateSystemInsideBody";
import scenerioDoneIcon from "../../Assets/images/scenarioDone.svg";
import scenerioInProgressIcon from "../../Assets/images/scenarioInProgress.svg";
import InfoIcon from "@mui/icons-material/Info";
import { API } from "../../api-services";
import CancelIcon from "@mui/icons-material/Cancel";
import Slide from "@mui/material/Slide";
import Swal from "sweetalert2";
import swal from "sweetalert";
import { TransitionProps } from "@mui/material/transitions";
import successIcon from "../../Assets/images/success.png";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import FilterAltOffIcon from "@mui/icons-material/FilterAltOff";
import { LoadingButton } from "@mui/lab";
import { useDocumentTitle } from "../../ui-reusable-component/useDocumentTitle";
import SwapVertIcon from '@mui/icons-material/SwapVert';
const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

type Anchor = "top" | "left" | "bottom" | "right";

interface Params {
  projectId: any;
  projectName: any;
  topVaultIds: any;
  expandTable: any;
  initialTopVault: any;
  top_vault: any;
  vault_id: any;
  abbr: any;
  top_vaultName: any;
}

interface ICostingCreateSystemInsideProps {}

const CostingCreateSystemInside: React.FC<ICostingCreateSystemInsideProps> = (
  props
) => {
  const {
    projectId,
    projectName,
    topVaultIds,
    expandTable,
    initialTopVault,
    top_vault,
    vault_id,
    abbr,
    top_vaultName,
  } = useRouteParams<Params>();
  useDocumentTitle( "Zero-based Costing" );
  const [infoAboutCostingSubsystem, setInfoAboutCostingSubsystem] =
    useState<any>({});
  const [openInfo, setOpenInfo] = React.useState(false);
  const [openFilter, setOpenFilter] = React.useState(false);
  const [pasteVaultNoList, setPasteVaultNoList] = useState<any>([]);
  const [copyVaultNo, setCopyVaultNo] = useState<any>();
  const [copyVaultCategory, setCopyVaultCategory] = useState<any>();
  const [showCheckboxCopyClicked, setShowCheckboxCopyClicked] =
    React.useState<boolean>(false);
  const [reloadCostingData, setReloadCostingData] = useState<boolean>(false);
  const [assemblyPart, setAssemblyPart] = React.useState();
  const [category, setCategory] = React.useState();
  const [status, setStatus] = React.useState<any>();
  const [apiCallMain, setApiCallMain] = useState<boolean>(false);
  const [calculatorType, setCalculatorType] = React.useState<any>();
  const [costingPasteWithDataLoader, setCostingPasteWithDataLoader] =
    useState<boolean>(false);
  const [costingPasteWithoutDataLoader, setCostingPasteWithoutDataLoader] =
    useState<boolean>(false);
  const [costingPasteWithDataDisable, setCostingPasteWithDataDisable] =
    useState<boolean>(false);
  const [costingPasteWithoutDataDisable, setCostingPasteWithoutDataDisable] =
    useState<boolean>(false);
    const [sortingStatus,setsortingStatus]=useState<any>(false)
  useEffect(() => {
    API.get(
      "cost/cost_info_commodity_desc/",
      { parent: vault_id, module: 16 },
      0
    ).then((res: any) => {
      setInfoAboutCostingSubsystem(res?.data);
    });
  }, []);

  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const history = useHistory();

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <CostingCreateDrawer anchorComing={anchor} toggleDrawer={toggleDrawer} />
  );

  const handleClickBack = () => {
    history.push(
      `/costingsystemlanding/${projectId}/${projectName}/${initialTopVault}/${topVaultIds}/${expandTable}`
    );
  };

  const handleCloseInfo = () => {
    setOpenInfo(false);
  };

  const handleCloseFilter = () => {
    setOpenFilter(false);
  };

  const handleCostingPasteData = (e: any, type: any) => {
    if (type == "DataWithCostModel") {
      e.stopPropagation();
      swal({
        title: `Paste Data with Cost Model`,
        text: `Are you sure to overwrite ?`,
        icon: "warning",
        //@ts-ignore
        buttons: {
          cancel: "Cancel",
          permanent: {
            text: `Paste`,
            value: `DataWithCostModel`,
          },
        },
        closeOnClickOutside: false,
        closeOnEsc: false,
        className: "warning-swal",
      }).then(async (type: string) => {
        switch (type) {
          case "DataWithCostModel":
            setCostingPasteWithDataLoader(true);
            setCostingPasteWithoutDataDisable(true);
            API.post(
              "/cost/costing_copy/",
              {
                source: copyVaultNo,
                target: pasteVaultNoList,
                multi_select: true,
              },
              0
            )
              .then((res: any) => {
                Swal.fire({
                  html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
                            <br />
                            <p style="color:#007fff;">The data with cost model has been pasted.
                            </p>   
                          </div>`,
                });
                setCostingPasteWithDataLoader(false);
                setCostingPasteWithoutDataDisable(false);
                setPasteVaultNoList([]);
                setShowCheckboxCopyClicked(false);
                setReloadCostingData(!reloadCostingData);
              })
              .catch((err: any) => {
                console.log(err, "Unable to paste. Server Error");
              });
            break;
          default:
            swal(`Data with cost model saved from pasting.`, { icon: "error" });
        }
      });
    } else {
      e.stopPropagation();
      swal({
        title: `Paste with only Cost Model`,
        text: `Are you sure to overwrite ?`,
        icon: "warning",
        //@ts-ignore
        buttons: {
          cancel: "Cancel",
          permanent: {
            text: `Paste`,
            value: `DataWithOnlyCostModel`,
          },
        },
        closeOnClickOutside: false,
        closeOnEsc: false,
        className: "warning-swal",
      }).then(async (type: string) => {
        switch (type) {
          case "DataWithOnlyCostModel":
            setCostingPasteWithoutDataLoader(true);
            setCostingPasteWithDataDisable(true);
            API.post(
              "/cost/costing_copy/",
              {
                source: copyVaultNo,
                target: pasteVaultNoList,
                multi_select_only_cost_model: true,
              },
              0
            )
              .then((res: any) => {
                Swal.fire({
                  html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
                            <br />
                            <p style="color:#007fff;"> The cost model without data has been pasted.
                            </p>   
                          </div>`,
                });
                setCostingPasteWithoutDataLoader(false);
                setCostingPasteWithDataDisable(false);
                setReloadCostingData(!reloadCostingData);
                setPasteVaultNoList([]);
                setShowCheckboxCopyClicked(false);
              })
              .catch((err: any) => {
                console.log(err, "Unable to paste. Server Error");
              });
            break;
          default:
            swal(`Cost model without data saved from pasting`, {
              icon: "error",
            });
        }
      });
    }
  };

  const handleChangeAssemblyPart = (event: any) => {
    setAssemblyPart(event.target.value);
  };

  const handleChangeStatus = (event: any) => {
    setStatus(event.target.value);
  };

  const handleChangeCategory = (event: any) => {
    setCategory(event.target.value);
  };

  const handleChangeCalculatorType = (event: any) => {
    setCalculatorType(event.target.value);
  };

  const handleClearFilter = () => {
    setAssemblyPart(undefined);
    setStatus(undefined);
    setCategory(undefined);
    setCalculatorType(undefined);
    setApiCallMain(!apiCallMain);
    handleCloseFilter();
  };

  
  const handleFilter = () => {
    setApiCallMain(!apiCallMain);
    handleCloseFilter();
  };


  const SortingHandler=()=>{
    if(sortingStatus==false){
      setsortingStatus(true)
    }else if(sortingStatus==true){
      setsortingStatus(false)
    }
   
  }
  return (
    <>
      <Dialog
        open={openFilter}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseFilter}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          ".MuiPaper-root.MuiDialog-paper": {
            // minWidth: '40vw',
            borderRadius: "10px",
          },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
          <Typography
            style={{
              fontSize: "1rem",
              fontWeight: "600",
              width: "100%",
              textAlign: "center",
              marginRight: "-2rem",
            }}
          >
            Filter
          </Typography>
          <CancelIcon
            titleAccess="Close"
            sx={{ cursor: "pointer", margin: "0.5rem" }}
            onClick={handleCloseFilter}
          />
        </Box>
        <Divider
          sx={{
            borderColor: "primary.light",
            margin: "0 1rem",
          }}
        />
        <DialogContent>
          <FormControl variant="standard" sx={{ mr: 1, ml: 1, minWidth: 320 }}>
            <InputLabel shrink sx={{color:'primary.main'}} id="demo-simple-select-standard-label">
              Assembly/Part
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={assemblyPart || ""}
              onChange={handleChangeAssemblyPart}
              label="Assembly/Part"
              sx={{
                "&:before": {
                  borderBottomColor: "primary.main",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                  {
                    fontSize: "1rem",
                  },
                ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
              }}
            >
              <MenuItem sx={{ fontSize: "1rem" }} value={"Assembly"}>
                Assembly
              </MenuItem>
              <MenuItem sx={{ fontSize: "1rem" }} value={"Part"}>
                Part
              </MenuItem>
            </Select>
          </FormControl>
          <Typography style={{ textAlign: "center", fontSize: "1rem",margin:'1rem',fontWeight:'600' }}>
            AND
          </Typography>
          <FormControl variant="standard" sx={{ mr: 1, ml: 1, minWidth: 320 }}>
            <InputLabel shrink sx={{color:'primary.main'}} id="demo-simple-select-standard-label">
              Select Category
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={category || ""}
              onChange={handleChangeCategory}
              label="Select Category"
              sx={{
                "&:before": {
                  borderBottomColor: "primary.main",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                  {
                    fontSize: "1rem",
                  },
                ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
              }}
            >
              {infoAboutCostingSubsystem &&
                infoAboutCostingSubsystem?.data?.map((item: any) => {
                  return (
                    <MenuItem
                      sx={{ fontSize: "1rem" }}
                      value={`${item?.category_name}`}
                    >
                      {item?.category_name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
          <Typography style={{ textAlign: "center", fontSize: "1rem",margin:'1rem',fontWeight:'600' }}>
            AND
          </Typography>
          <FormControl variant="standard" sx={{ mr: 1, ml: 1, minWidth: 320 }}>
            <InputLabel shrink sx={{color:'primary.main'}} id="demo-simple-select-standard-label">
              Status
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={status == 0 ? status : status || ""}
              onChange={handleChangeStatus}
              label="Status"
              sx={{
                "&:before": {
                  borderBottomColor: "primary.main",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                  {
                    fontSize: "1rem",
                  },
                ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
              }}
            >
              <MenuItem sx={{ fontSize: "1rem" }} value={0}>
                Not Started
              </MenuItem>
              <MenuItem sx={{ fontSize: "1rem" }} value={1}>
                In-Progress
              </MenuItem>
              <MenuItem sx={{ fontSize: "1rem" }} value={2}>
                Completed
              </MenuItem>
              <MenuItem sx={{ fontSize: "1rem" }} value={3}>
                Validated
              </MenuItem>
            </Select>
          </FormControl>
          <Typography style={{ textAlign: "center", fontSize: "1rem",margin:'1rem',fontWeight:'600' }}>
            AND
          </Typography>
          <FormControl variant="standard" sx={{ mr: 1, ml: 1, minWidth: 320 }}>
            <InputLabel shrink sx={{color:'primary.main'}} id="demo-simple-select-standard-label">
              Costing Type
            </InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={calculatorType || ""}
              onChange={handleChangeCalculatorType}
              label="Costing Type"
              sx={{
                "&:before": {
                  borderBottomColor: "primary.main",
                },
                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                  {
                    fontSize: "1rem",
                  },
                ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
              }}
            >
              <MenuItem sx={{ fontSize: "1rem" }} value={"bop"}>
                Bought-out part costing
              </MenuItem>
              <MenuItem sx={{ fontSize: "1rem" }} value={"dc"}>
                Detailed costing
              </MenuItem>
            </Select>
          </FormControl>
          <div
            style={{
              display: "flex",
              justifyContent: "end",
              marginTop: "1rem",
            }}
          >
            <Button
              sx={{ mr: 1, cursor: "pointer" }}
              title="Clear Filter"
              onClick={handleClearFilter}
            >
              {" "}
              Clear Filter
            </Button>
            <LoadingButton
              variant="contained"
              sx={{ padding: "0.2rem 0.5rem", lineHeight: 1 }}
              onClick={handleFilter}
            >
              Filter
            </LoadingButton>
          </div>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openInfo}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleCloseInfo}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          ".MuiPaper-root.MuiDialog-paper": {
            // minWidth: '40vw',
            borderRadius: "10px",
          },
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
          <Typography
            style={{
              fontSize: "1rem",
              fontWeight: "600",
              width: "100%",
              textAlign: "center",
              marginRight: "-2rem",
            }}
          >
            Costing Information ({abbr})
          </Typography>
          <CancelIcon
            titleAccess="Close"
            sx={{ cursor: "pointer", margin: "0.5rem" }}
            onClick={handleCloseInfo}
          />
        </Box>
        <Divider
          sx={{
            borderColor: "primary.light",
            margin: "0 1rem",
            marginBottom: "0.5rem",
          }}
        />
        <DialogContent sx={{padding:'0.5rem 1rem'}}>
          <Table>
            <TableHead sx={{ backgroundColor: "primary.light" }}>
              <TableRow>
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                  }}
                >
                  Category Name
                </TableCell>
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                  }}
                >
                  Count
                </TableCell>
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                  }}
                >
                  Validated
                </TableCell>
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                  }}
                >
                  Completed
                </TableCell>
                <TableCell
                  sx={{
                    padding: "0.2rem 0.5rem",
                    borderBottomColor: "primary.light",
                  }}
                >
                  Not Started
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {infoAboutCostingSubsystem &&
                infoAboutCostingSubsystem?.data?.map((item: any) => {
                  return (
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                        }}
                      >
                        {item?.category_name}
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                          textAlign: "center",
                        }}
                      >
                        {item?.count}
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                          textAlign: "center",
                        }}
                      >
                        {item?.validated}
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                          textAlign: "center",
                        }}
                      >
                        {item?.completed}
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                          textAlign: "center",
                        }}
                      >
                        {item?.not_started}
                      </TableCell>
                    </TableRow>
                  );
                })}
            </TableBody>
          </Table>
        </DialogContent>
      </Dialog>
      <Box sx={{ padding: "0 0.5rem", display: "flex", alignItems: "center" }}>
        <Box className={styles.titleSection}>
          <Box className={styles.titleSectionLeft}>
            <IconButton sx={{ cursor: "pointer" }} onClick={handleClickBack}>
              <ArrowBackIcon color="primary" titleAccess="Go Back" />
            </IconButton>
            <Typography
              sx={{
                fontSize: "1rem",
                whiteSpace: "nowrap",
                display: "inline-block",
              }}
            >
              Estimate Cost ( {`${projectName} > ${top_vaultName} > ${abbr}`} )
            </Typography>
          </Box>
          <Box className={styles.titleSectionRight}>
            {pasteVaultNoList?.length > 0 && (
              <span>
                <LoadingButton
                  sx={{ padding: "0 0.5rem", mr: "1rem" }}
                  variant="contained"
                  loading={costingPasteWithDataLoader}
                  disabled={costingPasteWithDataDisable}
                  onClick={(e: any) =>
                    handleCostingPasteData(e, "DataWithCostModel")
                  }
                >
                  Paste with Data & Cost Model
                </LoadingButton>
                <LoadingButton
                  sx={{ padding: "0 0.5rem", mr: "1rem" }}
                  variant="contained"
                  loading={costingPasteWithoutDataLoader}
                  disabled={costingPasteWithoutDataDisable}
                  onClick={(e: any) =>
                    handleCostingPasteData(e, "DataWithOnlyCostModel")
                  }
                >
                  Paste with only Cost Model
                </LoadingButton>
              </span>
            )}
            <IconButton title="Sorting"  onClick={()=>SortingHandler()}>
          
              <SwapVertIcon

           
            
            sx={{ color: sortingStatus?"green":"#007fff", cursor: "pointer" }}
            
            />
           
          
            
            </IconButton>
          
            <IconButton title="Filter">
              <FilterAltIcon
                sx={{ color: (assemblyPart!==undefined || status!==undefined || category!==undefined || calculatorType!==undefined)?"green": "#007fff", cursor: "pointer" }}
                onClick={() => setOpenFilter(true)}
              />
            </IconButton>
            <IconButton title="Info">
              <InfoIcon
                sx={{ color: "#007fff", cursor: "pointer" }}
                onClick={() => setOpenInfo(true)}
              />
            </IconButton>
            {/* {infoAboutCostingSubsystem?.scenario_exists ? <img src={scenerioDoneIcon} height='18px' style={{ margin: '0 0.25rem' }} /> : <img src={scenerioInProgressIcon} height='18px' style={{ margin: '0 0.25rem' }} />} */}
            <IconButton title="Scan QR">
              <QrCodeScannerIcon
                onClick={() =>
                  history.push(`/costingscanner/${projectId}/${projectName}`)
                }
                sx={{ color: "#007fff" }}
              />
            </IconButton>
            <IconButton
              title="Analyse"
              onClick={() =>
                window.open(`/#/analysis_costing/${projectId}/${topVaultIds}`)
              }
            >
              <InsightsIcon sx={{ color: "#007fff" }} />
            </IconButton>
          </Box>
        </Box>
      </Box>
      <Box sx={{ display: "flex", alignItems: "flex-start", width: "100%" }}>
        <Box className={styles.expandIconBox}>
          <div className={styles.iconSec}>
            {(["left"] as const).map((anchor) => (
              <React.Fragment key={anchor}>
                <IconButton
                  size="medium"
                  sx={{
                    backgroundColor: "primary.light",
                    color: "primary.main",
                  }}
                  onClick={toggleDrawer(anchor, true)}
                >
                  <AddIcon sx={{ fontSize: "1.7rem" }} />
                </IconButton>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false)}
                  className={styles.drawerContainer}
                  PaperProps={{
                    sx: {
                      backgroundColor: "aliceblue",
                    },
                  }}
                >
                  <Box
                    sx={{ padding: "0.5rem 0", position: "sticky", top: "0" }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3"
                  >
                    <Typography
                      style={{
                        width: "100%",
                        textAlign: "center",
                        fontSize: "1rem",
                      }}
                    >
                      Select Project
                    </Typography>
                    <Divider sx={{ margin: "0.2rem" }} />
                    {list(anchor)}
                  </Box>
                </Drawer>
                <Typography
                  sx={{ fontSize: "1rem", color: "primary.main" }}
                  onClick={toggleDrawer(anchor, true)}
                  className={styles.sideTitle}
                >
                  Project Selection
                </Typography>
              </React.Fragment>
            ))}
          </div>
        </Box>
        <Box
          sx={{ width: "98%", height: { lg: "86vh", xl: "90vh" } }}
          adl-scrollbar="true"
          adl-scrollbar-width="0.3"
        >
          <CostingCreateSystemInsideBody
            pasteVaultNoList={pasteVaultNoList}

            setPasteVaultNoList={setPasteVaultNoList}
            setCopyVaultNo={setCopyVaultNo}
            setCopyVaultCategory={setCopyVaultCategory}
            copyVaultCategory={copyVaultCategory}
            setShowCheckboxCopyClicked={setShowCheckboxCopyClicked}
            showCheckboxCopyClicked={showCheckboxCopyClicked}
            reloadCostingData={reloadCostingData}
            setReloadCostingData={setReloadCostingData}
            apiCallMain={apiCallMain}
            status={status}
            category={category}
            assemblyPart={assemblyPart}
            calculatorType={calculatorType}
            sortingStatus={sortingStatus}
          />
        </Box>
      </Box>
    </>
  );
};

export default CostingCreateSystemInside;
