
import { useState } from "react";
import { ID } from "../../../../../../utlis/commonInterfaces";

// ----------------------------------------------------------------------------------

const useFlaggedAssumptionFileModal = () => {
    const [isOpen, setIsOpen] = useState( false );
    const [flaggedAssumptionId, setFlaggedAssumptionId] = useState<ID>( 0 );

    const handleOpen = ( flaggedAssumptionId: ID ) => {
        setFlaggedAssumptionId( flaggedAssumptionId );
        setIsOpen( true );
    };
    const handleClose = () => {
        setFlaggedAssumptionId( 0 );
        setIsOpen( false );
    };

    return Object.freeze( {
        open: handleOpen,
        close: handleClose,
        isOpen,
        flaggedAssumptionId
    } );
};

// ----------------------------------------------------------------------------------

export { useFlaggedAssumptionFileModal };
