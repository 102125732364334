import axios, { AxiosError, AxiosResponse } from "axios";
import { API } from "../../api-services";
import { MODULES } from "../../Constants/modules.constants";
import { s3Url } from "../../utlis/s3url.utils";
import Swal from "sweetalert2";
import { getVideoCover } from "../../utlis/CreateVideoThumbnail";

const S3FeatureVideoUploadCreate = (fileList: any, itemId: any, setLoading: any, projectId: any, featureId: any, getFeatureData: any, is_mute: any, selectedProduct: any,dispatch:any) => {
  var random: any = Math.floor(Math.random() * 1000000 + 1);
  API.get("cad/cad_upload/", {
    module: MODULES.CAD,
  }).then((res) => {
    setLoading(true);
    const data = Array.from(fileList).forEach((file: any, index: any) => {
      const formData = new FormData();
      formData.append("key", `feature/video/${itemId ? itemId : 0}/${featureId}/${projectId}/${random}/${file.name}`);
      formData.append("Content-Type", file?.type);
      formData.append("x-amz-server-side-encryption", "AES256");
      formData.append("X-Amz-Credential", res.data.data.x_amz_credential);
      formData.append("X-Amz-Algorithm", "AWS4-HMAC-SHA256");
      formData.append("X-Amz-Date", res.data.data.x_amz_date);
      formData.append("X-Amz-Signature", res.data.data.aws_policy_signature);
      formData.append("Policy", res.data.data.aws_policy);
      formData.append("file", file, file?.name);
      axios
        .post(s3Url, formData, {
          onUploadProgress: (data) => {
            //Set the progress value to show the progress bar
          },
        })
        .then(async (res: any) => {
          if (res.status === 204) {
            try {
              // get the frame at 1.5 seconds of the video file
              const cover = await getVideoCover(file, 1.5);
              //@ts-ignore
              formData.set("file", cover, cover?.name);
              formData.set("key", `feature/thumbnail/${itemId ? itemId : 0}/${featureId}/${projectId}/${random}/thumb/${file.name.replace(".mp4", ".jpeg")}`);
              formData.set("Content-Type", "image/jpeg");
              axios.post(s3Url, formData);
              // print out the result image blob
            } catch (ex) {
              console.log("ERROR: ", ex);
            }
            API.put(
              `/xcpep/feature_details/${featureId}/`,
              {
                [itemId]: {
                  video: `/feature/video/${itemId ? itemId : 0}/${featureId}/${projectId}/${random}/${file.name}`,
                  thumbnail: `/feature/thumbnail/${itemId ? itemId : 0}/${featureId}/${projectId}/${random}/thumb/${file.name.replace(".mp4", ".jpeg")}`,
                  is_mute: is_mute,
                },

                // is_feature:true
              },
              {},
              0
            )
              .then((res: any) => {
                getFeatureData(selectedProduct);
                setLoading(false);
              })
              .catch((err: any) => {
                setLoading(false);
                const { data } = err.response;
                Swal.fire({
                  icon: "error",
                  html: `<div>
            <br />
            <p style="color:red;">${data[0]}</p>   
             </div>`,
                });
              });
          }
        });
    });
  });
};
// ----------------------------------------------------------------------------------
export default S3FeatureVideoUploadCreate;
