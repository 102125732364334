import * as React from "react";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
  IconButton,
  styled,
  Divider,
} from "@mui/material";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import CancelIcon from "@mui/icons-material/Cancel";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Chip from "@mui/material/Chip";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { useHistory, useRouteMatch } from "react-router-dom";
import { PermissionModuleAccordion } from "./PermissionModuleAccordion";
import { API } from "../../api-services";
import SaveIcon from "@mui/icons-material/Save";
import { ProjectSpecificModuleAccordiion } from "./ProjectSpecificModuleAccordiion";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { GeneralPermission } from "./GeneralPermission";
import { RoleList } from "../../Constants/projectspecificmodules.constants";
import { useBaseModal } from "../SearchPage/useBaseModal";
import { useDispatch, useSelector } from "react-redux";
import FilterModal from "./FilterModal";
import { setUserSerach } from "../../Redux/Actions/compareopen.action";
import ErrorModalFilesMail from "../ProjectMailModule/ErrorModalFilesMail";
import InfoIcon from "@mui/icons-material/Info";
import Popover from '@mui/material/Popover';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import ProjectInfoData from "./ProjectInfoData";

export interface IProductPermissionProps {
  projectList: any;
  isProjectSuccess: any;
}

// const Accordion = styled((props: AccordionProps) => (
//   <MuiAccordion disableGutters elevation={0} square {...props} />
// ))(({ theme }) => ({
//   border: `1px solid ${theme.palette.divider}`,
//   "&:not(:last-child)": {
//     borderBottom: 0,
//   },
//   "&:before": {
//     display: "none",
//   },
// }));

// const AccordionSummary = styled((props: AccordionSummaryProps) => (
//   <MuiAccordionSummary
//     expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
//     {...props}
//   />
// ))(({ theme }) => ({
//   backgroundColor:
//     theme.palette.mode === "dark"
//       ? "rgba(255, 255, 255, .05)"
//       : "rgba(0, 0, 0, .03)",
//   flexDirection: "row-reverse",
//   "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
//     transform: "rotate(90deg)",
//   },
//   "& .MuiAccordionSummary-content": {
//     marginLeft: theme.spacing(1),
//   },
// }));

// const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
//   padding: theme.spacing(2),
//   borderTop: "1px solid rgba(0, 0, 0, .125)",
// }));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      // width: 250,
    },
  },
};

function ProductPermission(props: IProductPermissionProps) {
  const history = useHistory();
  const { projectList, isProjectSuccess } = props;
  const dispatch = useDispatch();
  const filterModals = useBaseModal();
  const store: any = useSelector((state) => state);

  const { projectId, moduleId, filterOPtion, routeType } = useRouteParams<any>();
  const [selectedProject, setSelectedProject] = React.useState<any>(parseInt(projectId));
  const [products, setProducts] = React.useState<any>([]);
  const [modules, setModules] = React.useState<any>([]);
  const [isModuleSuccess, setIsModuleSuccess] = React.useState<any>(false);
  const [selectedProjectAdmins, setSeletedProjectAdmins] = React.useState<any>(
    []
  );
  const [productList, setProductList] = React.useState<any>([]);
  const { url } = useRouteMatch();
  // console.log(projectId, "projectId")

  //Tab Panel
  const [value, setValue] = React.useState(moduleId==0 ? '1' : '2');
  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue);
    if(newValue == '1'){
      history.push(`/user-permissions/${projectId}/Project Specific Modules/${filterOPtion}/${routeType}`)
    }  
    if(newValue == '2'){
      history.push(`/user-permissions/${projectId}/0/${filterOPtion}/${routeType}`)
    }
  };

React.useEffect(() => {
  if(url.includes('/Project Specific Modules')){
    setValue('1')
    history.push(`/user-permissions/${projectId}/Project Specific Modules/${filterOPtion}/${routeType}`)
  }
},[url]);


  //popover code
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


  const getProductList = () => {
    API.get("/xcpep/product_list/", { project: projectId }, 0)
      .then((res: any) => {
        setProductList(res.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    if (projectId !== "Gen") {
      getProductList();
    }
  }, [projectId]);
  const [modulesItems, setModulesItem] = React.useState<any>([]);
  const [users, setUsers] = React.useState<any>(null);
  const [userList, setUserList] = React.useState<any>([]);
  const [allUserList, setAllUserList] = React.useState<any>(null);
  const [roles, setRoles] = React.useState<any>([]);
  const [moduleList, setModuleList] = React.useState<any>([]);
  const [updateClick, setUpdateClick] = React.useState<any>([]);
  const [projectSpecMod, setProjectSpecMod] = React.useState<any>([]);
  const [projectAdmins, setProjectAdmins] = React.useState<any>([]);
  const [superSaveClickCount, setSuperSaveClickCount] = React.useState<any>(0);
  const ErrorModalFiles = useBaseModal()
  const [ErrorModalFileMsg, setErrorModalFileMsg] = React.useState<any>();
  var AvatarName = (item: any) => {
    const [first, last] = item?.split(" ");
    const FirstLatter = first?.charAt(0);
    const lastWord = last?.charAt(0);
    return `${FirstLatter}${lastWord}`;
  };

  const getProjectName = () => {
    let name: any = "";
    projectList?.filter((item: any) => {
      if (projectId == item.id) {
        if (item?.description) {
          name = `${item?.name} [${item?.description}]`;
        } else {
          name = `${item?.name}`;
        }
      }
    });
    return name;
  };

  const getUserList = () => {
    if(filterOPtion == "all"){
      API.get(
        "/auth/user/",
        {
          is_active: true,
          is_superuser: false,
          sub_module: "multiple",
          project: projectId,
        },
        0
      )
        .then((res: any) => {
          setUserList(res.data);
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
    else{
      API.get(
        "/auth/user/",
        {
          is_active: true,
          is_superuser: false,
          sub_module: "multiple",
          project: projectId,
          user_category: filterOPtion
        },
        0
      )
        .then((res: any) => {
          setUserList(res.data);
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
    
  };
  const getAllUserList = () => {
    if(filterOPtion == "all"){
    API.get(
      "/auth/user/",
      {
        is_active: true,
        is_superuser: false,
      },
      0
    )
      .then((res: any) => {
        let user: any = [];
        let resolve = res.data?.map((item: any) => {
          user.push({
            label: `${item?.first_name} ${item?.last_name}`,
            value: item?.id,
            isClient: item?.client,
            
          });
        });
        
        setAllUserList(user);
      })
      .catch((err: any) => {
        console.log(err);
      });
    }
    else {
      API.get(
        "/auth/user/",
        {
          is_active: true,
          is_superuser: false,
          user_category: filterOPtion
        },
        0
      )
        .then((res: any) => {
          let user: any = [];
          let resolve = res.data?.map((item: any) => {
            user.push({
              label: `${item?.first_name} ${item?.last_name}`,
              value: item?.id,
              isClient: item?.client,
              
            });
          });
          setAllUserList(user);
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  };
  React.useEffect(() => {
    getAllUserList();
  }, [filterOPtion]);
  const handleSelectChangeRole = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    setRoles(value);
  };

  const handleChangeSearch = (event: any, newValue: any | null) => {
    if (newValue !== null) {
      // history.push(`/user-permissions/${projectId}/0/${filterOPtion}/${routeType}`)
      dispatch(setUserSerach(newValue?.value));
    } else {
      dispatch(setUserSerach(""));
    }
  };

  const getModuleList = (userId: any) => {
    API.get(
      "/auth/permission_module/",
      {
        user: userId,
        project: projectId,
      },
      0
    )
      .then((res: any) => {
        setModuleList(res.data?.modules);
      })
      .catch((err: any) => {});
  };

  React.useEffect(() => {
    if (projectId !== "Gen") {
      getUserList();
    }
    // getModuleList();
  }, [updateClick, filterOPtion]);

  const getProjectAdmins = () => {
    API.get(
      "/auth/user/",
      {
        project_admin: true,
      },
      0
    )
      .then((res: any) => {
        setProjectAdmins(res.data);
      })
      .catch((err: any) => {
        console.log(err);
      });
  };
  React.useEffect(() => {
    getProjectAdmins();
  }, [projectId]);
  const handleDelete = () => {
    console.info("You clicked the delete icon.");
  };

  const [personName, setPersonName] = React.useState<string[]>([]);

  const handleUserChange = (event: SelectChangeEvent<typeof personName>) => {
    const {
      target: { value },
    } = event;
    setPersonName(
      // On autofill we get a stringified value.
      typeof value === "string" ? value.split(",") : value
    );
  };

  const handleProjectSelect = (idd: any) => {
    setSelectedProject(idd);
    history.push(`/user-permissions/${idd}/${moduleId==0 ? 'Project Specific Modules' : moduleId}/${filterOPtion}/${routeType}`);
  };

  const saveClicked = () => {
    API.post(
      "/auth/assign_permission/",
      {
        user_list: [users],
        submodule_list: modulesItems,
        products: products,
        action: roles,
      },
      {},
      0
    )
      .then((res: any) => {
        setUsers(null);
        setModulesItem([]);
        setProducts([]);
        setRoles([]);
        setSuperSaveClickCount((prev: any) => prev + 1);
      })
      .catch((err: any) => {});
  };

  React.useEffect(() => {
    setUsers(null);
    setModulesItem([]);
    setProducts([]);
    setRoles([]);
  }, [projectId]);

  const getModules = () => {
    if (moduleId == 0) {
      setIsModuleSuccess(true);
    }
    API.get(
      "/xcpep/project_modules/",
      {
        project: projectId,
        new_ui_permission: true,
        user_type: filterOPtion,
        action:
          store?.is_compare?.permission_filter?.length > 0
            ? store?.is_compare?.permission_filter?.toString()
            : undefined,
        user_id:
          typeof store?.is_compare?.selected_userId !== "string"
            ? store?.is_compare?.selected_userId
            : undefined,
      },
      0
    )
      .then((res: any) => {
        setSeletedProjectAdmins([]);
        setIsModuleSuccess(false);
        setModules(
          res.data.data?.filter(
            (item: any) => item?.module !== "Project Specific Modules"
          )
        );
        let arr = res.data?.project_admin?.map((item: any, index: any) => {
          setSeletedProjectAdmins((prev: any) => [...prev, item?.user]);
        });
        // setSeletedProjectAdmins
        setProjectSpecMod(
          res.data.data?.filter(
            (item: any) => item?.module === "Project Specific Modules"
          )
        );
      })
      .catch((err: any) => {
        setIsModuleSuccess(false);
        console.log(err);
      });
  };
  React.useEffect(() => {
    if (projectId !== "Gen") {
      getModules();
    }
  }, [
    projectId,
    updateClick,
    superSaveClickCount,
    store?.is_compare?.permission_filter,
    filterOPtion,
    store?.is_compare?.selected_userId,
  ]);

  const handleSelectChangeUser = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    getModuleList(value);
    setUsers(value);
  };
  // handleSelectChangeProduct
  const handleSelectChangeModule = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    setModulesItem(value);
  };

  const handleSelectChangeProduct = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    setProducts(value);
  };

  const handleSelectChange = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
   
    API.put(
      "/xcpep/project_modules/",
      {
        project: projectId,
        user: value,
        role: "Project Administrator",
      },
      {},
      0
    )
      .then((res: any) => {
        setSeletedProjectAdmins(value);
      })
      .catch((err: any) => {
        const { data } = err.response;
        
        ErrorModalFiles.open()
        setErrorModalFileMsg(data)



      });
  };

  const selectedShow = (idd: any, name: any) => {
    return idd == projectId;
  };

  
  /*********AutoSearch *********************** */

  return (<>
    <Box
      // sx={{
      //   display: "grid",
      //   gridTemplateColumns: url.includes("/projectSpecific")
      //     ? "1fr"
      //     : "1fr 4fr",
      //   alignItems: "flex-start",
      //   width: "100%",
      // }}
      sx={{
        display:'flex',
        // alignItems:'center',
        flexDirection:'column',
        width:'100%',
      }}
    >
      {url.includes("/projectSpecific") ? (
        <Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              columnGap: "10px",
              marginTop: "0.5rem",
              marginBottom: "0.5rem",              
            }}
          >
            <Typography sx={{ fontSize: "1rem" }}>
              {getProjectName()}
            </Typography>
            <Box
              sx={{ display: "flex", alignItems: "center", columnGap: "10px" }}
            >
              <Autocomplete
                disablePortal
                id="combo-box-demo"
                size="small"
                onChange={handleChangeSearch}
                options={allUserList}
                sx={{
                  width: 300,
                  ".MuiSvgIcon-root": {
                    color: "primary.main",
                  },
                }}
                renderInput={(params) => (
                  <TextField
                    variant="standard"
                    sx={{
                      borderBottom: "none",
                      "& .MuiInput-underline:before": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "primary.main",
                      },
                    }}
                    {...params}
                    placeholder="Search"
                  />
                )}
              />

              <FilterAltIcon
                sx={{ cursor: "pointer" }}
                onClick={() => filterModals.open()}
              />
            </Box>
          </Box>

          {projectId !== "Gen" && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "primary.light",
                color: "primary.main",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "1rem",
                  padding: "0.2rem 1rem 0",
                  width: "100%",
                }}
              >
                <Typography sx={{ fontSize: "1rem", whiteSpace: "nowrap" }}>
                 Admin
                </Typography>
                <FormControl fullWidth variant="standard">
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedProjectAdmins && selectedProjectAdmins}
                    multiple
                    multiline
                    label="User"
                    onChange={handleSelectChange}
                    size="small"
                    placeholder="Select Users"
                    sx={{
                      "&:before": {
                        borderBottomColor: "primary.main",
                      },
                      ".MuiSvgIcon-root.MuiSelect-icon": {
                        color: "primary.main",
                      },
                      "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":{
                        fontSize:'1rem'
                      },
                    }}
                    renderValue={(selected) => (
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 0.5,
                        }}
                      >
                        {projectAdmins
                          .filter((item: any) => selected?.includes(item?.id))
                          ?.map((value: any) => (
                            <Chip
                              avatar={
                                <Avatar
                                  src={value?.user_info}
                                  sx={{
                                    color: "white !important",
                                    objectFit: "cover",
                                    backgroundColor: "#007fff !important",
                                    textTransform: "uppercase",
                                    fontSize: "0.75rem",
                                    " .MuiAvatar-root.MuiAvatar-circular": {
                                      backgroundColor: "primary.main",
                                    },
                                  }}
                                >
                                  {AvatarName(
                                    `${value?.first_name} ${value?.last_name}`
                                  )}
                                </Avatar>
                              }
                              // size="small"
                              variant="outlined"
                              sx={{ borderColor: "primary.main" }}
                              key={value.id}
                              label={`${value?.first_name} ${value?.last_name}`}
                              onDelete={() => {
                                setSeletedProjectAdmins((prev: any) =>
                                  prev?.filter((item: any) => value.id !== item)
                                );
                                API.put(
                                  "/xcpep/project_modules/",
                                  {
                                    project: projectId,
                                    user: selectedProjectAdmins.filter(
                                      (item: any) => value.id !== item
                                    ),
                                    role: "Project Administrator",
                                  },
                                  {},
                                  0
                                )
                                  .then((res: any) => {})
                                  .catch((err: any) => {});
                              }}
                              deleteIcon={
                                <CancelIcon
                                  onMouseDown={(event) =>
                                    event.stopPropagation()
                                  }
                                />
                              }
                            />
                          ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}
                  >
                    {projectAdmins &&
                      projectAdmins?.map((item: any, index: number) => {
                        return (
                          !selectedProjectAdmins?.includes(item.id) && (
                            <MenuItem sx={{fontSize:'1rem'}} key={index} value={item?.id}>
                              {item?.first_name} {item?.last_name}
                            </MenuItem>
                          )
                        );
                      })}
                  </Select>
                </FormControl>
              </Box>
            </Box>
          )}
          <Box
            sx={{ padding: "0.2rem 0", height: "88vh" }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3"
          >
            {projectId !== "Gen" && (
              <ProjectSpecificModuleAccordiion
                modules={projectSpecMod && projectSpecMod}
              />
            )}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                columnGap: "10px",
                marginBottom: "0.5rem",                
              }}
            >
              {projectId !== "Gen" && (
                <Table>
                  <TableBody>
                    <TableRow>
                      <TableCell
                        sx={{
                          padding: "0 0.5rem",
                          borderBottom: "none",
                          textAlign: "center",
                          width: "7vw",
                          height: "15vh",
                        }}
                      >
                        Activity Modules
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0 0.5rem",
                          borderBottom: "none",
                          textAlign: "center",
                          width: "20vw",
                          height: "15vh",
                          verticalAlign: "bottom",
                        }}
                      >
                        <FormControl fullWidth variant="standard">
                          <InputLabel id="demo-simple-select-label">
                            Select User
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={users && users}
                            label="User"
                            onChange={handleSelectChangeUser}
                            size="small"
                            sx={{
                              "&:before": {
                                borderBottomColor: "primary.main",
                              },
                              ".MuiSvgIcon-root.MuiSelect-icon": {
                                color: "primary.main",
                              },
                              "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":{
                                fontSize:'1rem'
                              },
                            }}
                            MenuProps={MenuProps}
                          >
                            {userList &&
                              userList?.map((item: any, index: number) => {
                                return (
                                  <MenuItem sx={{fontSize:'1rem'}} key={index} value={item?.id}>
                                    {item?.first_name} {item?.last_name}
                                  </MenuItem>
                                );
                              })}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0 0.5rem",
                          borderBottom: "none",
                          textAlign: "center",
                          width: "20vw",
                          height: "15vh",
                          verticalAlign: "bottom",
                        }}
                      >
                        <FormControl fullWidth variant="standard">
                          <InputLabel id="demo-simple-select-label">
                            Select Modules
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={modulesItems && modulesItems}
                            multiline
                            multiple
                            label="Module"
                            onChange={handleSelectChangeModule}
                            size="small"
                            placeholder="Select Users"
                            sx={{
                              "&:before": {
                                borderBottomColor: "primary.main",
                              },
                              ".MuiSvgIcon-root.MuiSelect-icon": {
                                color: "primary.main",
                              },
                              "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":{
                                fontSize:'1rem'
                              },
                            }}
                            renderValue={(selected) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 0.5,
                                }}
                              >
                                {moduleList
                                  .filter((item: any) =>
                                    selected?.includes(item)
                                  )
                                  ?.map((value: any, index: any) => (
                                    <Chip
                                      key={index}
                                      label={value}
                                      size="small"
                                      onDelete={() =>
                                        setModulesItem((prev: any) =>
                                          prev?.filter(
                                            (item: any) => value !== item
                                          )
                                        )
                                      }
                                      deleteIcon={
                                        <CancelIcon
                                          onMouseDown={(event) =>
                                            event.stopPropagation()
                                          }
                                        />
                                      }
                                    />
                                  ))}
                              </Box>
                            )}
                            MenuProps={MenuProps}
                          >
                            {moduleList &&
                              moduleList?.map((item: any, index: number) => {                               
                                return (
                                  !modulesItems?.includes(item) && (
                                    <MenuItem sx={{fontSize:'1rem'}} key={index} value={item}>
                                      {item}
                                    </MenuItem>
                                  )
                                );
                              })}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0 0.5rem",
                          borderBottom: "none",
                          textAlign: "center",
                          width: "20vw",
                          height: "15vh",
                          verticalAlign: "bottom",
                        }}
                      >
                        <FormControl fullWidth variant="standard">
                          <InputLabel id="demo-simple-select-label">
                            Select Products
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={products && products}
                            // multiple
                            // multiline
                            //   label="User"
                            placeholder="Select Products"
                            onChange={handleSelectChangeProduct}
                            size="small"
                            sx={{
                              "&:before": {
                                borderBottomColor: "primary.main",
                              },
                              ".MuiSvgIcon-root.MuiSelect-icon": {
                                color: "primary.main",
                              },
                              "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":{
                                fontSize:'1rem'
                              },
                            }}
                            renderValue={(selected) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 0.5,
                                }}
                              >
                                {productList
                                  .filter((item: any) =>
                                    selected?.includes(item?.id)
                                  )
                                  ?.map((value: any) => (
                                    <Chip
                                      key={value.id}
                                      label={value.name}
                                      size="small"
                                      onDelete={() =>
                                        setProducts((prev: any) =>
                                          prev?.filter(
                                            (item: any) => value.id !== item
                                          )
                                        )
                                      }
                                      deleteIcon={
                                        <CancelIcon
                                          onMouseDown={(event) =>
                                            event.stopPropagation()
                                          }
                                        />
                                      }
                                    />
                                  ))}
                              </Box>
                            )}
                            MenuProps={MenuProps}
                          >
                            {productList &&
                              productList?.map((item: any, index: number) => {
                                return (
                                  !products?.includes(item.id) && (
                                    <MenuItem sx={{fontSize:'1rem'}} key={index} value={item?.id}>
                                      {item?.name}
                                    </MenuItem>
                                  )
                                );
                              })}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0 0.5rem",
                          borderBottom: "none",
                          textAlign: "center",
                          width: "20vw",
                          height: "15vh",
                          verticalAlign: "bottom",
                        }}
                      >
                        <FormControl fullWidth variant="standard">
                          <InputLabel id="demo-simple-select-label">
                            Select Roles
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={roles && roles}
                            // multiple
                            // multiline
                            label="Role"
                            placeholder="Select Role"
                            onChange={handleSelectChangeRole}
                            size="small"
                            sx={{
                              "&:before": {
                                borderBottomColor: "primary.main",
                              },
                              ".MuiSvgIcon-root.MuiSelect-icon": {
                                color: "primary.main",
                              },
                              "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":{
                                fontSize:'1rem'
                              },
                            }}
                            renderValue={(selected) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 0.5,
                                }}
                              >
                                {RoleList.filter((item: any) =>
                                  selected?.includes(item?.value)
                                )?.map((value: any) => (
                                  <Chip
                                    key={value.value}
                                    label={value.value}
                                    size="small"
                                    onDelete={() =>
                                      setRoles((prev: any) =>
                                        prev?.filter(
                                          (item: any) => value.value !== item
                                        )
                                      )
                                    }
                                    deleteIcon={
                                      <CancelIcon
                                        onMouseDown={(event) =>
                                          event.stopPropagation()
                                        }
                                      />
                                    }
                                  />
                                ))}
                              </Box>
                            )}
                            MenuProps={MenuProps}
                          >
                            {RoleList &&
                              RoleList?.map((item: any, index: number) => {
                                return (
                                  !roles?.includes(item?.value) && (
                                    <MenuItem sx={{fontSize:'1rem'}} key={index} value={item?.value}>
                                      {item.text}
                                    </MenuItem>
                                  )
                                );
                              })}
                          </Select>
                        </FormControl>
                      </TableCell>
                      <TableCell
                        sx={{
                          padding: "0 0.5rem",
                          borderBottom: "none",
                          textAlign: "right",
                          width: "4vw",
                          height: "15vh",
                        }}
                      >
                        <Button
                          onClick={() => saveClicked()}
                          sx={{ minWidth: "1rem" }}
                          disabled={
                            users?.length === 0 ||
                            products?.length === 0 ||
                            roles?.length === 0 ||
                            modules?.length === 0
                              ? true
                              : false
                          }
                        >
                          <SaveIcon sx={{ cursor: "pointer" }} />
                        </Button>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              )}
            </Box>
            {projectId !== "Gen" ? (
              <PermissionModuleAccordion
                modules={modules && modules}
                setCount={setUpdateClick}
                isModuleSuccess={isModuleSuccess}
              />
            ) : (
              <Box>
                <GeneralPermission />
              </Box>
            )}
          </Box>
        </Box>
      ) : (
        <>
        <Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							columnGap: '0.5rem',
							width: {lg:'99%',xl:'99.5%'},
							borderBottom: '1px solid',
              paddingRight:'0.5rem',
              paddingBottom:'0.2rem',
						}} adl-scrollbar='true'
						adl-scrollbar-width='0.2'>
						{!isProjectSuccess ? (
              projectList?.map((item: any, index: any) => {
                return (								
									<>
										<Box
											key={index}
											sx={{
												color: '#007fff',
												height: '2.5rem',
												minHeight: '2.1rem',
												maxWidth: '100% !important',
												padding: '0',
												fontSize: '1rem',
												width: '100%',
												backgroundColor:
                          item?.id == projectId
														? 'primary.main'
														: '',
												cursor: 'pointer',
												display: 'flex',
												alignItems: 'center',
                        border:'1px solid',
                        // borderTopRightRadius:'5px',
                        // borderTopLeftRadius:'5px',
                        minWidth:'15rem',
											}}>
											<Typography
												sx={{
													color:
                          item?.id == projectId ? 'white' : '#007fff',
													height: '2.5rem',
													minHeight: '2.1rem',
													maxWidth: '100% !important',
													padding: '0 0.5rem',
													fontSize: '1rem',
													width: '100%',
													textAlign: 'center',
													display: 'flex',
													whiteSpace: 'nowrap',
													alignItems: 'center',
                          columnGap:'1rem',
													justifyContent: 'center',
													textTransform: 'capitalize',
                          
												}}
												onClick={() => handleProjectSelect(item?.id)}>
												{item?.name} 
                        <ProjectInfoData 
                              projectList={projectList}
                              item={item?.id}
                              projectId={projectId}
                            />                         
											</Typography>
										</Box>
									</>
								);
              })
            ) : (
              <Box sx={{display:'flex',alignItems:'center',columnGap:'1rem',width:'100%'}}>
                <Skeleton
                  animation="pulse"
                  variant="rectangular"
                  width="100%"
                  height="2rem"
                  // sx={{ borderRadius: "1rem", marginBottom: "1rem" }}
                /> 
                <Skeleton
                  animation="pulse"
                  variant="rectangular"
                  width="100%"
                  height="2rem"
                  // sx={{ borderRadius: "1rem", marginBottom: "1rem" }}
                />   
                <Skeleton
                  animation="pulse"
                  variant="rectangular"
                  width="100%"
                  height="2rem"
                  // sx={{ borderRadius: "1rem", marginBottom: "1rem" }}
                /> 
                <Skeleton
                  animation="pulse"
                  variant="rectangular"
                  width="100%"
                  height="2rem"
                  // sx={{ borderRadius: "1rem", marginBottom: "1rem" }}
                /> 
                <Skeleton
                  animation="pulse"
                  variant="rectangular"
                  width="100%"
                  height="2rem"
                  // sx={{ borderRadius: "1rem", marginBottom: "1rem" }}
                /> 
                <Skeleton
                  animation="pulse"
                  variant="rectangular"
                  width="100%"
                  height="2rem"
                  // sx={{ borderRadius: "1rem", marginBottom: "1rem" }}
                /> 

              </Box>
            )}
					</Box>
          {/* <Box
            sx={{
              padding: "0.2rem 1rem 0",
              height: "90vh",
              width: { lg: "23vw", xl: "20vw" },
              borderRight: "1px solid",
              borderColor:'primary.light',
            }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3"
          >
            {!isProjectSuccess ? (
              projectList?.map((item: any, index: any) => {
                return (
                  <Box
                    sx={{
                      padding: "1rem",
                      borderRadius: "10px",
                      boxShadow:
                        "rgba(0, 0, 0, 0.2) 0px 3px 3px -2px, rgba(0, 0, 0, 0.14) 0px 3px 4px 0px, rgba(0, 0, 0, 0.12) 0px 1px 8px 0px",
                      marginBottom: "1rem",
                      backgroundColor: selectedShow(item?.id, item?.name)
                        ? "#ddefff"
                        : "",
                      cursor: "pointer",
                    }}
                    onClick={() => handleProjectSelect(item?.id)}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography sx={{ fontSize: "1rem", fontWeight: "700" }}>
                        {item?.name}
                        {item?.name !== "General Modules" &&
                          `(${item?.description})`}
                      </Typography>
                      <Typography
                        sx={{ fontSize: "1rem", whiteSpace: "nowrap" }}
                      >
                        {item?.name !== "General Modules" &&
                          `(${item?.product_count} Products)`}
                      </Typography>
                    </Box>
                    <Box sx={{ marginLeft: "1rem" }}>
                      <Typography sx={{ display: "flex" }}>
                        <Typography sx={{ fontSize: "1rem", width: "8rem" }}>
                          Admin
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "1rem",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          :
                          <Typography sx={{ margin: "0 10px" }}>
                            {item?.admin}
                          </Typography>
                        </Typography>
                      </Typography>
                      <Typography sx={{ display: "flex" }}>
                        <Typography sx={{ fontSize: "1rem", width: "8rem" }}>
                          Project Admin
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "1rem",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          :
                          <Typography sx={{ margin: "0 10px" }}>
                            {item?.project_admin}
                          </Typography>
                        </Typography>
                      </Typography>
                      <Typography sx={{ display: "flex" }}>
                        <Typography sx={{ fontSize: "1rem", width: "8rem" }}>
                          User
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "1rem",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          :
                          <Typography
                            sx={{ fontSize: "1rem", margin: "0 10px" }}
                          >
                            {item?.user?.internal} Internal,
                          </Typography>
                          <Typography sx={{ fontSize: "1rem" }}>
                            {item?.user?.external} External
                          </Typography>
                        </Typography>
                      </Typography>
                    </Box>
                  </Box>
                );
              })
            ) : (
              <Box>
                <Skeleton
                  animation="pulse"
                  variant="rectangular"
                  width="100%"
                  height="10rem"
                  sx={{ borderRadius: "1rem", marginBottom: "1rem" }}
                />
                <Skeleton
                  animation="pulse"
                  variant="rectangular"
                  width="100%"
                  height="10rem"
                  sx={{ borderRadius: "1rem", marginBottom: "1rem" }}
                />
                <Skeleton
                  animation="pulse"
                  variant="rectangular"
                  width="100%"
                  height="10rem"
                  sx={{ borderRadius: "1rem", marginBottom: "1rem" }}
                />
                <Skeleton
                  animation="pulse"
                  variant="rectangular"
                  width="100%"
                  height="10rem"
                  sx={{ borderRadius: "1rem", marginBottom: "1rem" }}
                />
              </Box>
            )}
          </Box> */}
          <Box sx={{padding: '0 0.5rem',width: {lg:'99%',xl:'100%'},}}>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  columnGap: "10px",
                  marginTop:'0.5rem',
                  marginBottom: "0.5rem",
                  // marginRight: "1rem",
                }}
              >
                <Typography sx={{ fontSize: "1rem" }}>
                  {getProjectName()}
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "10px",
                  }}
                >
                  <Autocomplete
                    disablePortal
                    id="combo-box-demo"
                    size="small"
                    options={allUserList}
                    onChange={handleChangeSearch}
                    sx={{
                      width: 300,
                      ".MuiSvgIcon-root": {
                        color: "primary.main",
                      },
                    }}
                    getOptionLabel={(option) => option.label}
                    renderOption={(props, option) => (
                      
                      <li {...props} style={{ color: option.isClient ? 'red' : 'primary.main' }}>
                        {option.label}
                      </li>
                    )}
                    renderInput={(params) => {
                      
                      return (
                      <TextField
                        variant="standard"
                        sx={{
                          borderBottom: "none",
                          "& .MuiInput-underline:before": {
                            borderBottomColor: "primary.main",
                          },
                          "& .MuiInput-underline:after": {
                            borderBottomColor: "primary.main",
                          },
                        }}
                        {...params}
                        placeholder="Search"
                      />
                    )}}
                  />

                  <FilterAltIcon
                    sx={{ cursor: "pointer" }}
                    onClick={() => filterModals.open()}
                  />
                </Box>
              </Box>
              {projectId !== "Gen" && (<Box sx={{ display:'flex',alignItems:'center',columnGap:'0.5rem',width:'100%', padding: "0.2rem 0.5rem",}}>
                        <Typography sx={{ fontSize: "1rem", whiteSpace: "nowrap",marginBottom:'0.5rem',width:'10rem'}}>
                          Project Admin
                        </Typography>
                        <Box sx={{width:'100%'}}>                            
                          <FormControl fullWidth variant="standard">
                            <Select
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              value={selectedProjectAdmins && selectedProjectAdmins}
                              multiple
                              multiline
                              label="User"
                              onChange={handleSelectChange}
                              size="small"
                              placeholder="Select Users"
                              sx={{
                                "&:before": {
                                  borderBottomColor: "transparent",
                                },
                                ".MuiSvgIcon-root.MuiSelect-icon": {
                                  color: "primary.main",
                                },
                                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":{
                                  fontSize:'1rem'
                                },
                              }}
                              renderValue={(selected) => (
                                <Box
                                  sx={{
                                    display: "flex",
                                    flexWrap: "wrap",                                    
                                    gap: 0.5,
                                  }}>
                                  {projectAdmins
                                    .filter((item: any) => selected?.includes(item?.id))
                                    ?.map((value: any) => (
                                      <Chip
                                        sx={{display:'flex',alignItems:'center',justifyContent:'space-between',
                                        '.MuiChip-label':{
                                          width:'100%',
                                        },
                                        borderColor: 'primary.main',
                                        }}
                                        avatar={
                                          <Avatar
                                            src={value?.user_info}
                                            sx={{
                                              color: "white !important",
                                              objectFit: "cover",
                                              backgroundColor: "#007fff !important",
                                              textTransform: "uppercase",
                                              " .MuiAvatar-root.MuiAvatar-circular": {
                                                backgroundColor: "primary.main",
                                              },
                                            }}
                                          >
                                            <Typography
                                              sx={{
                                                fontSize: "0.87rem",
                                                lineHeight: "1",                                                    
                                              }}
                                            >
                                              {AvatarName(
                                                `${value?.first_name} ${value?.last_name}`
                                              )}
                                            </Typography>
                                          </Avatar>
                                        }
                                        variant="outlined"
                                        // size="small"
                                        key={value.id}
                                        label={`${value?.first_name} ${value?.last_name}`}
                                        onDelete={() => {
                                          setSeletedProjectAdmins((prev: any) =>
                                            prev?.filter(
                                              (item: any) => value.id !== item
                                            )
                                          );
                                          API.put(
                                            "/xcpep/project_modules/",
                                            {
                                              project: projectId,
                                              user: selectedProjectAdmins.filter(
                                                (item: any) => value.id !== item
                                              ),
                                              role: "Project Administrator",
                                            },
                                            {},
                                            0
                                          )
                                            .then((res: any) => {})
                                            .catch((err: any) => {});
                                        }}
                                        deleteIcon={
                                          <CancelIcon
                                            onMouseDown={(event) =>
                                              event.stopPropagation()
                                            }
                                          />
                                        }
                                      />
                                    ))}
                                </Box>
                              )}
                              MenuProps={MenuProps}
                            >
                              {projectAdmins &&
                                projectAdmins?.map((item: any, index: number) => {
                                  return (
                                    !selectedProjectAdmins?.includes(item?.id) && (
                                      <MenuItem sx={{fontSize:'1rem'}} key={index} value={item?.id}>
                                        {item?.first_name} {item?.last_name}
                                      </MenuItem>
                                    )
                                  );
                                })}
                            </Select>
                          </FormControl>                                                                
                      </Box>
                      </Box>)} 
                      {projectId !== "Gen" ? (
                       <TabContext value={value}>
                          <Box sx={{ border: 1,borderColor: 'primary.main' }}>
                            <TabList onChange={handleTabChange} aria-label="lab API tabs example"
                            sx={{
                              '& .MuiTabs-indicator': { backgroundColor: 'primary.main' },
                              '& .MuiTab-root': { color: 'primary.main',minHeight:'30px',maxWidth:'100%',width:'50%',padding:'0.5rem'  },
                              '& .Mui-selected': { color: '#fff !important', backgroundColor: 'primary.main',padding:'0.5rem' },
                              minHeight: '30px'
                            }}>
                              <Tab label="Project Specific Module" value="1" />
                              <Tab label="Module Permission" value="2" />                           
                            </TabList>
                          </Box>
                       <TabPanel value="1" sx={{padding:'0.5rem'}}>
                          <Box sx={{display:'flex',alignItems:'flex-start',width:'100%',}}> 
                              {projectId !== "Gen" && (
                                <Box sx={{width:'100%',padding:'0 0 0 0.5rem'}}> 
                                  <ProjectSpecificModuleAccordiion
                                    modules={projectSpecMod && projectSpecMod}
                                  />              
                              </Box>)}
                          </Box>
                       </TabPanel>
                       <TabPanel value="2" sx={{padding:'0.5rem'}}>
                          <Box sx={{ padding: "0.2rem 0", height: { lg: "67vh", xl: "76vh" }}}
                            adl-scrollbar="true"
                            adl-scrollbar-width="0.3">
                                {projectId !== "Gen" && (<Typography sx={{fontSize:'1rem'}}>Activity Modules</Typography>)}
                                {projectId !== "Gen" && (<Box
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    columnGap: "10px",
                                    marginBottom: "0.5rem",
                                    paddingBottom: "0.5rem",
                                    borderBottom:'1px solid',
                                    borderColor:'primary.light',
                                  }}
                                >                
                                    <Table>
                                      <TableBody>
                                        <TableRow>                        
                                          <TableCell
                                            sx={{
                                              padding: "0 0.5rem",
                                              borderBottom: "none",
                                              // textAlign: "center",
                                              width: "15vw",
                                              // height: "15vh",
                                              verticalAlign: "bottom",
                                            }}
                                          >
                                            <FormControl fullWidth variant="standard">
                                              <InputLabel id="demo-simple-select-label">
                                                Select Users
                                              </InputLabel>
                                              <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={users && users}
                                                label="User"
                                                onChange={handleSelectChangeUser}
                                                size="small"
                                                placeholder="Select Users"
                                                sx={{
                                                  "&:before": {
                                                    borderBottomColor: "primary.main",
                                                  },
                                                  ".MuiSvgIcon-root.MuiSelect-icon": {
                                                    color: "primary.main",
                                                  },
                                                  "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":{
                                                    fontSize:'1rem'
                                                  },
                                                }}
                                                MenuProps={MenuProps}
                                              >
                                                {userList &&
                                                  userList?.map((item: any, index: number) => {
                                                    return (
                                                      <MenuItem key={index} value={item?.id} sx={{fontSize:'1rem', color : item?.user_category == "External" ? "red" : "primary.main"}}>
                                                        {item?.first_name} {item?.last_name}
                                                      </MenuItem>
                                                    );
                                                  })}
                                              </Select>
                                            </FormControl>
                                          </TableCell>
                                          <TableCell
                                            sx={{
                                              padding: "0 0.5rem",
                                              borderBottom: "none",
                                              // textAlign: "center",
                                              width: "34vw",
                                              // height: "15vh",
                                              verticalAlign: "bottom",
                                            }}
                                          >
                                            <FormControl fullWidth variant="standard">
                                              <InputLabel id="demo-simple-select-label">
                                                Select Modules
                                              </InputLabel>
                                              <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={modulesItems && modulesItems}
                                                multiline
                                                multiple
                                                label="Module"
                                                onChange={handleSelectChangeModule}
                                                size="small"
                                                placeholder="Select Users"
                                                sx={{
                                                  "&:before": {
                                                    borderBottomColor: "primary.main",
                                                  },
                                                  ".MuiSvgIcon-root.MuiSelect-icon": {
                                                    color: "primary.main",
                                                  },
                                                  "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":{
                                                    fontSize:'1rem'
                                                  },
                                                }}
                                                renderValue={(selected) => (
                                                  <Box
                                                    sx={{
                                                      display: "flex",
                                                      flexWrap: "wrap",
                                                      gap: 0.5,
                                                    }}
                                                  >
                                                    {moduleList
                                                      .filter((item: any) =>
                                                        selected?.includes(item)
                                                      )
                                                      ?.map((value: any, index: any) => (
                                                        <Chip
                                                          key={index}
                                                          label={value}
                                                          size="small"
                                                          onDelete={() =>
                                                            setModulesItem((prev: any) =>
                                                              prev?.filter(
                                                                (item: any) => value !== item
                                                              )
                                                            )
                                                          }
                                                          deleteIcon={
                                                            <CancelIcon
                                                              onMouseDown={(event) =>
                                                                event.stopPropagation()
                                                              }
                                                            />
                                                          }
                                                        />
                                                      ))}
                                                  </Box>
                                                )}
                                                MenuProps={MenuProps}
                                              >
                                                {moduleList &&
                                                  moduleList?.map((item: any, index: number) => {
                                                    return (
                                                      !modulesItems?.includes(item) && (
                                                        <MenuItem sx={{fontSize:'1rem'}} key={index} value={item}>
                                                          {item}
                                                        </MenuItem>
                                                      )
                                                    );
                                                  })}
                                              </Select>
                                            </FormControl>
                                          </TableCell>
                                          <TableCell
                                            sx={{
                                              padding: "0 0.5rem",
                                              borderBottom: "none",
                                              // textAlign: "center",
                                              width: "18vw",
                                              // height: "15vh",
                                              verticalAlign: "bottom",
                                            }}
                                          >
                                            <FormControl fullWidth variant="standard">
                                              <InputLabel id="demo-simple-select-label">
                                                Select Products
                                              </InputLabel>
                                              <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={products && products}
                                                multiple
                                                multiline
                                                //   label="User"
                                                placeholder="Select Products"
                                                onChange={handleSelectChangeProduct}
                                                size="small"
                                                sx={{
                                                  "&:before": {
                                                    borderBottomColor: "primary.main",
                                                  },
                                                  ".MuiSvgIcon-root.MuiSelect-icon": {
                                                    color: "primary.main",
                                                  },
                                                  "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":{
                                                    fontSize:'1rem'
                                                  },
                                                }}
                                                renderValue={(selected) => (
                                                  <Box
                                                    sx={{
                                                      display: "flex",
                                                      flexWrap: "wrap",
                                                      gap: 0.5,
                                                    }}
                                                  >
                                                    {productList
                                                      .filter((item: any) =>
                                                        selected?.includes(item?.id)
                                                      )
                                                      ?.map((value: any) => (
                                                        <Chip
                                                          key={value.id}
                                                          label={value.name}
                                                          size="small"
                                                          onDelete={() =>
                                                            setProducts((prev: any) =>
                                                              prev?.filter(
                                                                (item: any) => value.id !== item
                                                              )
                                                            )
                                                          }
                                                          deleteIcon={
                                                            <CancelIcon
                                                              onMouseDown={(event) =>
                                                                event.stopPropagation()
                                                              }
                                                            />
                                                          }
                                                        />
                                                      ))}
                                                  </Box>
                                                )}
                                                MenuProps={MenuProps}
                                              >
                                                {productList &&
                                                  productList?.map((item: any, index: number) => {
                                                    return (
                                                      !products?.includes(item?.id) && (
                                                        <MenuItem sx={{fontSize:'1rem'}} key={index} value={item?.id}>
                                                          {item?.name}
                                                        </MenuItem>
                                                      )
                                                    );
                                                  })}
                                              </Select>
                                            </FormControl>
                                          </TableCell>
                                          <TableCell
                                            sx={{
                                              padding: "0 0.5rem",
                                              borderBottom: "none",
                                              // textAlign: "center",
                                              width: "14vw",
                                              // height: "15vh",
                                              verticalAlign: "bottom",
                                            }}
                                          >
                                            <FormControl fullWidth variant="standard">
                                              <InputLabel id="demo-simple-select-label">
                                                Select Roles
                                              </InputLabel>
                                              <Select
                                                labelId="demo-simple-select-label"
                                                id="demo-simple-select"
                                                value={roles && roles}
                                                multiple
                                                multiline
                                                label="Role"
                                                placeholder="Select Role"
                                                onChange={handleSelectChangeRole}
                                                size="small"
                                                sx={{
                                                  "&:before": {
                                                    borderBottomColor: "primary.main",
                                                  },
                                                  ".MuiSvgIcon-root.MuiSelect-icon": {
                                                    color: "primary.main",
                                                  },
                                                  "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":{
                                                    fontSize:'1rem'
                                                  },
                                                }}
                                                renderValue={(selected) => (
                                                  <Box
                                                    sx={{
                                                      display: "flex",
                                                      flexWrap: "wrap",
                                                      gap: 0.5,
                                                    }}
                                                  >
                                                    {RoleList.filter((item: any) =>
                                                      selected?.includes(item?.value)
                                                    )?.map((value: any) => (
                                                      <Chip
                                                        key={value.value}
                                                        label={value.value}
                                                        size="small"
                                                        onDelete={() =>
                                                          setRoles((prev: any) =>
                                                            prev?.filter(
                                                              (item: any) => value.value !== item
                                                            )
                                                          )
                                                        }
                                                        deleteIcon={
                                                          <CancelIcon
                                                            onMouseDown={(event) =>
                                                              event.stopPropagation()
                                                            }
                                                          />
                                                        }
                                                      />
                                                    ))}
                                                  </Box>
                                                )}
                                                MenuProps={MenuProps}
                                              >
                                                {RoleList &&
                                                  RoleList?.map((item: any, index: number) => {
                                                    return (
                                                      !roles?.includes(item?.value) && (
                                                        <MenuItem sx={{fontSize:'1rem'}} key={index} value={item?.value}>
                                                          {item.text}
                                                        </MenuItem>
                                                      )
                                                    );
                                                  })}
                                              </Select>
                                            </FormControl>
                                          </TableCell>
                                          <TableCell
                                            sx={{
                                              padding: "0 0.5rem",
                                              borderBottom: "none",
                                              textAlign: "right",
                                              width: "3vw",
                                              // height: "15vh",
                                            }}
                                          >
                                            <Button
                                              onClick={() => saveClicked()}
                                              sx={{ minWidth: "1rem" }}
                                              disabled={
                                                users?.length === 0 ||
                                                products?.length === 0 ||
                                                roles?.length === 0 ||
                                                modules?.length === 0
                                                  ? true
                                                  : false
                                              }
                                            >
                                              <SaveIcon sx={{ cursor: "pointer" }} />
                                            </Button>
                                          </TableCell>
                                        </TableRow>
                                      </TableBody>
                                    </Table>                
                                </Box>)}
                                {projectId !== "Gen" && (
                                  <PermissionModuleAccordion
                                    modules={modules && modules}
                                    setCount={setUpdateClick}
                                    isModuleSuccess={isModuleSuccess}
                                  />
                                )}
                            </Box>
                       </TabPanel>                       
                     </TabContext>)
                      : (
                        <Box>
                          <GeneralPermission />
                        </Box>
                      )}        
          </Box>
        </>
      )}
      <FilterModal
        isOpen={filterModals.isOpen}
        onCloseModal={filterModals.close}
      />
      {ErrorModalFiles.isOpen &&      
      <ErrorModalFilesMail
            isOpen={ErrorModalFiles.isOpen}
            onCloseModal={ErrorModalFiles.close}
            ErrorMsg={ErrorModalFileMsg}
          />
      }
    </Box>
    </>);
}
export default ProductPermission;
