// import InfoIcon from "@mui/icons-material/Info";
// import EqualizerIcon from "@mui/icons-material/Equalizer";
// import LaunchIcon from "@mui/icons-material/Launch";
// import AssistantPhotoIcon from "@mui/icons-material/AssistantPhoto";
// import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
// import EditIcon from "@mui/icons-material/Edit";
// import swal from "sweetalert";
// import DeleteIcon from "@mui/icons-material/Delete";

import { Box } from "@mui/material";

// import useWindowDimensions from "./useWindowDimensions";

const handleOpenLinkReference = (reference: string) => {
  window.open(reference);
};
const isUrl = (url:any) => {
  try {
    new URL(url);
    return true;
  } catch (error) {
    return false;
  }
};
// const { height, width } = useWindowDimensions();

const ConnectorsColumns: any = [
  { field: "sno", headerName: "S.No", minWidth: 50, },
  {
    field: "name",
    headerName: "Item Name",
    minWidth: 180,
    flex: 1,
  },
  {
    field: "no_of_pins",
    headerName: "No. of pins",
    width: 100,
  },
  {
    field: "postion_config",
    headerName: "Position Config(mxn)",
    headerAlign: "left",
    align: "left",
    type: "number",
    width: 120,
  },
  {
    field: "shape",
    headerName: "Shape",
    sortable: false,
    width: 100,
  },
  {
    field: "pin_pitch",
    headerName: "Terminal pin pitch",

    sortable: false,
    width: 100,
  },
  {
    field: "connection_type",
    headerName: "Connection Type",
    sortable: false,
    minWidth: 150,
  },
  {
    field: "gender",
    headerName: "Male/Female",
    sortable: false,
    minWidth: 150,
  },
  {
    field: "fit_type",
    headerName: "Fit Type",
    sortable: false,
    minWidth: 90,
  },
  {
    field: "material",
    headerName: "Material",
    sortable: false,
    minWidth: 150,
  },
  {
    field: "reference",
    headerName: "Reference",
    sortable: false,
    minWidth: 100,
    renderCell: (cellValues: any) => {
      return (
        <>
          {isUrl(cellValues?.row?.reference)?<span
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => handleOpenLinkReference(cellValues?.row?.reference)}>
            Link
          </span>:
          <span
          // style={{ textDecoration: "underline", cursor: "pointer" }}
          // onClick={() => handleOpenLinkReference(cellValues?.row?.reference)}
          >
          {cellValues?.row?.reference}
        </span>}
        </>
      );
    },
  },
  {
    field: "unit_cost",
    headerName: "Per unit cost(INR/Unit)",
    cellClassName: "super-app-theme--cell",
    sortable: false,
    minWidth: 90,
    align: 'right',
    renderCell: (cellValues: any) => {
      return (
        <Box title={`Cost : ${cellValues?.row?.unit_cost}`} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', columnGap: '1rem', }}>
          {`${cellValues?.row?.unit_cost?.toFixed(3)}`}
        </Box>
      );
    },
  },

];
const BearingsColumns: any = [
  { field: "sno", headerName: "S.No", minWidth: 80, },
  {
    field: "code",
    headerName: "Item Code",
    minWidth: 180,
    flex: 1,
  },
  {
    field: "name",
    headerName: "Item Name",
    minWidth: 180,
    flex: 1,
  },
  {
    field: "bearing_code",
    headerName: "Bearing Code",
    minWidth: 150,
    flex: 1,
  },
  {
    field: "item_id",
    headerName: "ID(mm)",
    type: "number",
    width: 70,
    align: 'right',
  },
  {
    field: "item_od",
    headerName: "OD(mm)",
    sortable: false,
    width: 70,
    align: 'right',
  },
  {
    field: "height",
    headerName: "Height (mm)",
    sortable: false,
    width: 100,
    align: 'right',
  },
  {
    field: "weight",
    headerName: "Weight (g)",
    sortable: false,
    minWidth: 100,
    align: 'right',
  },
  {
    field: "reference",
    headerName: "Source",
    sortable: false,
    minWidth: 80,
    renderCell: (cellValues: any) => {
      return (
        <>
          {isUrl(cellValues?.row?.reference)?<span
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => handleOpenLinkReference(cellValues?.row?.reference)}>
            Link
          </span>:
          <span
          // style={{ textDecoration: "underline", cursor: "pointer" }}
          // onClick={() => handleOpenLinkReference(cellValues?.row?.reference)}
          >
          {cellValues?.row?.reference}
        </span>}
        </>
      );
    },
  },
  {
    field: "unit_cost",
    headerName: "Per Unit Cost",
    cellClassName: "super-app-theme--cell",
    sortable: false,
    minWidth: 90,
    align: 'right',
    renderCell: (cellValues: any) => {
      return (
        <Box title={`Cost : ${cellValues?.row?.unit_cost}`} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', columnGap: '1rem', }}>
          {`${cellValues?.row?.unit_cost?.toFixed(3)}`}
        </Box>
      );
    },
  },


];
const ConsumablesColumns: any = [
  { field: "sno", headerName: "S.No", width: 80, },
  {
    field: "code",
    headerName: "Item Code",
    minWidth: 180,
    flex: 1,
  },
  {
    field: "name",
    headerName: "Item Name",
    minWidth: 180,
    flex: 1,
  },
  {
    field: "description",
    headerName: "Item Descriptions",
    minWidth: 180,
    flex: 1,
  },
  // {
  //   field: "unit",
  //   headerName: "Unit",
  //   type: "number",
  //   minWidth: 120,
  // },

  {
    field: "reference",
    headerName: "Source",

    sortable: false,
    minWidth: 150,
    renderCell: (cellValues: any) => {
      return (
        <>
          {isUrl(cellValues?.row?.reference)?<span
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => handleOpenLinkReference(cellValues?.row?.reference)}>
            Link
          </span>:
          <span
          // style={{ textDecoration: "underline", cursor: "pointer" }}
          // onClick={() => handleOpenLinkReference(cellValues?.row?.reference)}
          >
          {cellValues?.row?.reference}
        </span>}
        </>
      );
    },
  },
  {
    field: "unit_cost",
    headerName: "Per Unit Cost",
    cellClassName: "super-app-theme--cell",
    sortable: false,
    minWidth: 90,
    renderCell: (cellValues: any) => {
      return (
        <Box title={`Cost : ${cellValues?.row?.unit_cost}`} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', columnGap: '1rem', }}>
          {`${cellValues?.row?.unit_cost?.toFixed(3)}`}
        </Box>
      );
    },
  },


];
const ElectronicChildColumns: any = [
  { field: "sno", headerName: "S.No", minWidth: 80, },
  {
    field: "name",
    headerName: "Item Name",
    minWidth: 140,
    flex: 1,
  },
  {
    field: "description",
    headerName: "Item Descriptions",
    minWidth: 140,
    flex: 1,
  },
  {
    field: "length",
    headerName: "Length",
    type: "number",
    minWidth: 100,
  },
  {
    field: "width",
    headerName: "Width",
    type: "number",
    minWidth: 100,
  },
  // {
  //   field: "unit",
  //   headerName: "Size Unit",
  //   type: "number",
  //   minWidth: 100,
  // },
  {
    field: "component_type",
    headerName: "Component type",
    // type: "number",
    minWidth: 140,
  },
  {
    field: "reference",
    headerName: "Reference",
    sortable: false,
    minWidth: 100,
    renderCell: (cellValues: any) => {
      return (
        <>
          {isUrl(cellValues?.row?.reference)?<span
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => handleOpenLinkReference(cellValues?.row?.reference)}>
            Link
          </span>:
          <span
          // style={{ textDecoration: "underline", cursor: "pointer" }}
          // onClick={() => handleOpenLinkReference(cellValues?.row?.reference)}
          >
          {cellValues?.row?.reference}
        </span>}
        </>
      );
    },
  },
  {
    field: "unit_cost",
    headerName: "Cost",
    cellClassName: "super-app-theme--cell",
    sortable: false,
    minWidth: 90,
    align: 'right',
    renderCell: (cellValues: any) => {
      return (
        <Box title={`Cost : ${cellValues?.row?.unit_cost}`} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', columnGap: '1rem', }}>
          {`${cellValues?.row?.unit_cost?.toFixed(3)}`}
        </Box>
      );
    },
  },
];
const MEPartsColumns: any = [
  { field: "sno", headerName: "S.No", minWidth: 80, },
  {
    field: "code",
    headerName: "Item Code",
    minWidth: 140,
    flex: 1,
  },
  {
    field: "name",
    headerName: "Item Name",
    minWidth: 140,
    flex: 1,
  },
  {
    field: "description",
    headerName: "Item Descriptions",
    minWidth: 120,
    flex: 1,
  },
  {
    field: "brand_name",
    headerName: "Brand and Model",
    type: "number",
    minWidth: 120,
  },
  {
    field: "length",
    headerName: "Length(mm)",
    type: "number",
    minWidth: 100,
  },
  {
    field: "width",
    headerName: "Width(mm)",
    type: "number",
    minWidth: 100,
  },
  {
    field: "height",
    headerName: "Height(mm)",
    type: "number",
    minWidth: 100,
  },
  {
    field: "weight",
    headerName: "Weight",
    type: "number",
    minWidth: 90,
  },
  {
    field: "reference",
    headerName: "Reference",
    sortable: false,
    minWidth: 100,
    renderCell: (cellValues: any) => {
      return (
        <>
          {isUrl(cellValues?.row?.reference)?<span
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => handleOpenLinkReference(cellValues?.row?.reference)}>
            Link
          </span>:
          <span
          // style={{ textDecoration: "underline", cursor: "pointer" }}
          // onClick={() => handleOpenLinkReference(cellValues?.row?.reference)}
          >
          {cellValues?.row?.reference}
        </span>}
        </>
      );
    },
  },
  {
    field: "unit_cost",
    headerName: "Cost",
    cellClassName: "super-app-theme--cell",
    sortable: false,
    minWidth: 90,
    align: 'right',
    renderCell: (cellValues: any) => {
      return (
        <Box title={`Cost : ${cellValues?.row?.unit_cost}`} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', columnGap: '1rem', }}>
          {`${cellValues?.row?.unit_cost?.toFixed(3)}`}
        </Box>
      );
    },
  },


];

const OthersColumns: any = [
  { field: "sno", headerName: "S.No", minWidth: 80, },
  {
    field: "code",
    headerName: "Item Code",
    minWidth: 140,
    flex: 1,
  },
  {
    field: "name",
    headerName: "Item Name",
    minWidth: 140,
    flex: 1,
  },
  {
    field: "item_detail",
    headerName: "Item Descriptions",
    minWidth: 120,
    flex: 1,
  },
  {
    field: "commodity_name",
    headerName: "Commodity",
    // type: "number",
    minWidth: 120,
  },
  {
    field: "length",
    headerName: "Length(mm)",
    type: "number",
    minWidth: 100,
  },
  {
    field: "width",
    headerName: "Width(mm)",
    type: "number",
    minWidth: 100,
  },
  {
    field: "height",
    headerName: "Height(mm)",
    type: "number",
    minWidth: 100,
  },
  {
    field: "weight",
    headerName: "Weight(kg)",
    type: "number",
    minWidth: 100,
  },
  {
    field: "place",
    headerName: "Place of Mfg.",
    // type: "number",
    minWidth: 120,
  },
  {
    field: "reference",
    headerName: "Reference",
    sortable: false,
    minWidth: 100,
    renderCell: (cellValues: any) => {
      return (
        <>
         {isUrl(cellValues?.row?.reference)?<span
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => handleOpenLinkReference(cellValues?.row?.reference)}>
            Link
          </span>:
          <span
          // style={{ textDecoration: "underline", cursor: "pointer" }}
          // onClick={() => handleOpenLinkReference(cellValues?.row?.reference)}
          >
          {cellValues?.row?.reference}
        </span>}
        </>
      );
    },
  },
  {
    field: "unit_cost",
    headerName: "Cost",
    cellClassName: "super-app-theme--cell",
    sortable: false,
    minWidth: 90,
    align: 'right',
    renderCell: (cellValues: any) => {
      return (
        <Box title={`Cost : ${cellValues?.row?.unit_cost}`} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', columnGap: '1rem', }}>
          {`${cellValues?.row?.unit_cost?.toFixed(3)}`}
        </Box>
      );
    },
  },


];
const FastenersCols: any = [
  { field: "sno", headerName: "S.No", minWidth: 80, },
  {
    field: "name",
    headerName: "Item Name",
    minWidth: 140,
    flex: 1,
  },
  {
    field: "code",
    headerName: "Item Code",
    minWidth: 140,
    flex: 1,
  },
  {
    field: "type",
    headerName: "Type of Fastener",
    width: 120,
  },
  {
    field: "grade",
    headerName: "Grade",
    type: "number",
    minWidth: 90,
  },
  {
    field: "material",
    headerName: "Material",
    type: "number",
    minWidth: 120,
  },
  {
    field: "m_size",
    headerName: "M-Size",
    type: "number",
    minWidth: 100,
  },
  {
    field: "length",
    headerName: "Length",
    type: "number",
    minWidth: 100,
  },
  {
    field: "thread_length",
    headerName: "Thread Leanth",
    type: "number",
    minWidth: 120,
  },
  {
    field: "weight",
    headerName: "Weight",
    type: "number",
    minWidth: 100,
  },



  {
    field: "reference",
    headerName: "Reference",

    sortable: false,
    minWidth: 100,
    renderCell: (cellValues: any) => {
      return (
        <>
         {isUrl(cellValues?.row?.reference)?<span
            style={{ textDecoration: "underline", cursor: "pointer" }}
            onClick={() => handleOpenLinkReference(cellValues?.row?.reference)}>
            Link
          </span>:
          <span
          // style={{ textDecoration: "underline", cursor: "pointer" }}
          // onClick={() => handleOpenLinkReference(cellValues?.row?.reference)}
          >
          {cellValues?.row?.reference}
        </span>}
        </>
      );
    },
  },
  {
    field: "unit_cost",
    headerName: "Cost",
    cellClassName: "super-app-theme--cell",
    sortable: false,
    minWidth: 100,
    align: 'right',
    renderCell: (cellValues: any) => {
      return (
        <Box title={`Cost : ${cellValues?.row?.unit_cost}`} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', columnGap: '1rem', }}>
          {`${cellValues?.row?.unit_cost?.toFixed(3)}`}
        </Box>
      );
    },
  },


];
export { ConnectorsColumns, BearingsColumns, ConsumablesColumns, ElectronicChildColumns, MEPartsColumns, OthersColumns, FastenersCols };
