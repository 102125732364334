import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CancelIcon from '@mui/icons-material/Cancel';
import { Checkbox, Chip, CircularProgress, Divider } from '@mui/material';
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import { useRouteParams } from '../../ui-reusable-component/useRouteParams';
import { AxiosResponse } from 'axios';
import { useContext, useEffect, useRef, useState } from 'react';
import { API } from '../../api-services';
import { MyContext } from './MediaCreateLanding';
import { useAppDispatch } from '../../../AppRouter';
import { useBaseModal } from '../SearchPage/useBaseModal';
import MediaAddSingleImageModel from './MediaAddSingleImageModel';
import { createRepoImage, updateRepoImage } from '../../Redux/Actions/imageRepository.actions';
import mediaSingleUploadFromGroup from './MediaSingleUploadFromGroup';
import { isUndefined } from 'lodash';
import Image from "../../ViewModule/FeatureLandingPage/ArchitectureImageCard";
import NoImgLoader from "../../Assets/images/loading-screen.gif";
import { useHistory } from 'react-router-dom';
import { MediaImageFetchModel } from './MediaImageFetchModel';
import VideoModal from '../MediaModule/VideoModal/VideoModal';
import MediaAddSingleVideoModel from './MediaAddSingleVideoModel';
import validated from '../../Assets/images/Validated.svg'


const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  height: "90vh",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

export interface IMediaCreateImageUploadModalProps {
  onCloseModal: any;
  isOpen: any;
  sendNextModelGroupId: any;
  categoryGet: any;
  abbreviationGet: any;
  sequenceSend: any;
  type: any;
  topVaultIdImageSection?: any;
  onCloseModalGroupName?: any;
  groupName?: any;
}

export function MediaCreateImageUploadModal(props: IMediaCreateImageUploadModalProps) {
  const { onCloseModal, isOpen, sendNextModelGroupId, onCloseModalGroupName, groupName, categoryGet, abbreviationGet, sequenceSend, type, topVaultIdImageSection } = props;
  const { projectId, projectName, mediaType, productList, pageNumber, apiCall } = useRouteParams<any>();
  const [productListData, setProductListData] = useState<any>([]);
  const [productSelected, setProductSelected] = useState<any>();
  const [sequence, setSequence] = useState<any>();
  const [isUpdating, setIsUpdating] = useState<any>(false);
  const [vaultId, setVaultId] = useState<any>();
  const [imageDetails, setImageDetails] = useState<any>();
  const [imageDetailApiCallCounter, setImageDetailApiCallCounter] = useState<number>(0);
  const [pagenationCount, setPagenationCount] = useState<any>();
  const [checked, setChecked] = useState<boolean>(false);
  const [loaderForFetch, setLoaderForFetch] = useState<boolean>(false);

  const VideoModalRef = useRef<any>(null);

  const history = useHistory();
  const uploadmodal = useBaseModal();
  const uploadmodalVideo = useBaseModal();
  const createMediaFetch = useBaseModal();
  const { callGroupAPI, setCallGroupAPI, callGroupNameAPI, setCallGroupNameAPI, permissionAccess } = useContext(MyContext);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setSequence(sequenceSend);
    type == "imageSingleUpload" && setProductSelected(topVaultIdImageSection);
  }, [sequenceSend, type == "imageSingleUpload" && topVaultIdImageSection]);

  useEffect(() => {
    API.get(
      "/image_repository/media_module/",
      {
        project: projectId,
        media_type: mediaType,
        image_category: categoryGet,
        abbreviation: abbreviationGet,
        download_image_count: true,
        group_id: sendNextModelGroupId,
        sequence: sequence,
      },
      0
    ).then((res: AxiosResponse) => {
      setProductListData(res?.data);
      type != "imageGroupSideUpload" && setVaultId(res?.data?.filter((item: any) => productSelected == item?.top_vault_id)?.[0]?.vault_id);
    });
  }, [productSelected, sequence]);

  useEffect(() => {
    if (type == "imageGroupSideUpload") {
      API.get(
        "/image_repository/media_module/",
        {
          project: projectId,
          media_type: mediaType,
          image_category: categoryGet,
          abbreviation: abbreviationGet,
          download_image_count: true,
          group_id: sendNextModelGroupId,
          sequence: sequenceSend,
        },
        0
      ).then((res: AxiosResponse) => {
        setProductSelected(res?.data?.[0]?.top_vault_id);
        setVaultId(res?.data?.[0]?.vault_id);
      });
    }
  }, []);

  useEffect(() => {
    setImageDetails(undefined);
    if (productSelected) {
      API.get(
        "/image_repository/media_group_upload/",
        {
          top_vault: productSelected,
          abbreviation: abbreviationGet,
          image_category: categoryGet,
          sequence: sequence,
          group_id: sendNextModelGroupId,
          media_type: mediaType,
          current_sequence: true,
        },
        0
      ).then((res: AxiosResponse) => {
        setImageDetails(res?.data);
      });

      API.get(
        "/image_repository/media_group_upload/",
        {
          project_id: projectId,
          abbreviation: abbreviationGet,
          image_category: categoryGet,
          group_id: sendNextModelGroupId,
          media_type: mediaType,
          max_sequence: true,
        },
        0
      ).then((res: AxiosResponse) => {
        setPagenationCount(res?.data);
      });
    }
  }, [productSelected, imageDetailApiCallCounter, sequence]);

  //-------------------image upload--------------------------------------------------------

  const saveImage = async (file: File, id: number) => {
    setIsUpdating(true);
    if (imageDetails && imageDetails?.length > 0) {
      if (id == 1) {
        mediaSingleUploadFromGroup(
          [file],
          sendNextModelGroupId,
          productSelected,
          abbreviationGet == "0" ? null : vaultId,
          dispatch,
          setIsUpdating,
          mediaType,
          uploadmodal,
          imageDetails && imageDetails?.[0]?.id,
          "update",
          categoryGet,
          sequence,
          setImageDetailApiCallCounter
        );
      } else {
        await dispatch(
          updateRepoImage({
            id: imageDetails && imageDetails?.[0]?.id,
            image: file,
            rgb: true,
            media_type: mediaType,
            group: sendNextModelGroupId,
          })
        ).then(() => {
          uploadmodal.close();
          setIsUpdating(false);
          setImageDetailApiCallCounter((prev: any) => prev + 1);
        });
      }
    } else {
      if (id == 1) {
        mediaSingleUploadFromGroup(
          [file],
          sendNextModelGroupId,
          productSelected,
          abbreviationGet == "0" ? null : vaultId,
          dispatch,
          setIsUpdating,
          mediaType,
          uploadmodal,
          null,
          "create",
          categoryGet,
          sequence,
          setImageDetailApiCallCounter
        );
      } else {
        dispatch(
          createRepoImage({
            image: file,
            image_category: categoryGet,
            sequence,
            top_vault: productSelected,
            vault: abbreviationGet == "0" ? null : vaultId,
            rgb: true,
            media_type: mediaType,
            group: sendNextModelGroupId,
          })
        ).then((res: any) => {
          uploadmodal.close();
          setIsUpdating(false);
          setImageDetailApiCallCounter((prev: any) => prev + 1);
        });
      }
    }
  };

  const onCloseModalHandler = () => {
    setCallGroupAPI(!callGroupAPI);
    setCallGroupNameAPI(!callGroupNameAPI);
    type == "imageGroupSideUpload"
      ? history.push(`/mediacreateinternal/${projectId}/${projectName}/${mediaType}/${abbreviationGet}/${categoryGet}/${sendNextModelGroupId}/${productList}/1/${apiCall == "true" ? "false" : "true"}`)
      : history.push(
        `/mediacreateinternal/${projectId}/${projectName}/${mediaType}/${abbreviationGet}/${categoryGet}/${sendNextModelGroupId}/${productList}/${pageNumber}/${apiCall == "true" ? "false" : "true"}`
      );
    type == "imageGroupSideUpload" && onCloseModalGroupName();
    onCloseModal();
  };

  const handleProjectSelect = (id: any) => {
    setProductSelected(id);
  };

  const browseFiles = () => {
    uploadmodal.open();
  };

  const browseVideo = () => {
    uploadmodalVideo.open();
  }

  //--------------- video upload------------------------------------------------------

  const saveVideo = async (file: File, id: number) => {
    if (isUndefined(file)) return;
    setIsUpdating(true);
    if (imageDetails && imageDetails?.length > 0) {
      mediaSingleUploadFromGroup(
        [file],
        sendNextModelGroupId,
        productSelected,
        abbreviationGet == "0" ? null : vaultId,
        dispatch,
        setIsUpdating,
        mediaType,
        uploadmodalVideo,
        imageDetails && imageDetails?.[0]?.id,
        "update",
        categoryGet,
        sequence,
        setImageDetailApiCallCounter,
        id == 1 ? false : true
      );
    } else {
      mediaSingleUploadFromGroup(
        [file],
        sendNextModelGroupId,
        productSelected,
        abbreviationGet == "0" ? null : vaultId,
        dispatch,
        setIsUpdating,
        mediaType,
        uploadmodalVideo,
        null,
        "create",
        categoryGet,
        sequence,
        setImageDetailApiCallCounter,
        id == 1 ? false : true
      );
    }
  };

  const handleChangeNaBox = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
    API.get(
      "/image_repository/media_group_upload/",
      {
        top_vault: productSelected?.toString(),
        abbreviation: abbreviationGet,
        image_category: categoryGet,
        sequence: sequence,
        group_id: sendNextModelGroupId,
        media_type: mediaType,
        upload_na: true,
      },
      0
    ).then((res: AxiosResponse) => {
      setChecked(false);
      setImageDetailApiCallCounter((prev: any) => prev + 1);
    });
  };

  const handleFetchImageOpen = () => {
    createMediaFetch.open();
  }

  const handleFetchImage = (urlFetchedImage: any) => {
    setLoaderForFetch(true);
    let abc = 0;
    for (let a = sequence; a < (sequence + urlFetchedImage?.current?.length); a++) {
      dispatch(createRepoImage({
        image: urlFetchedImage?.current[abc]?.substring(urlFetchedImage?.current[abc]?.indexOf(".net") + 4),
        image_category: categoryGet,
        sequence: a,
        top_vault: productSelected,
        vault: abbreviationGet == '0' ? null : vaultId,
        rgb: false,
        media_type: mediaType,
        group: sendNextModelGroupId,
        fetch: true
      })).then((res: any) => {
        if (sequence + urlFetchedImage?.current?.length) {
          setImageDetailApiCallCounter((prev: any) => prev + 1);
          createMediaFetch.close();
          setLoaderForFetch(false);
        }
      })
      abc += 1;
    }
    // if (imageDetails && imageDetails?.length > 0) {
    //   dispatch(
    //     updateRepoImage({
    //       id: imageDetails && imageDetails?.[0]?.id,
    //       image: urlFetchedImage,
    //       rgb: false,
    //       media_type: mediaType,
    //       group: sendNextModelGroupId,
    //     })
    //   ).then((res: any) => {
    //     setImageDetailApiCallCounter((prev: any) => prev + 1);
    //     createMediaFetch.close();
    //     setLoaderForFetch(false);
    //   })
    // }
    // else {
    //   dispatch(createRepoImage({
    //     image: urlFetchedImage,
    //     image_category: categoryGet,
    //     sequence,
    //     top_vault: productSelected,
    //     vault: abbreviationGet == '0' ? null : vaultId,
    //     rgb: false,
    //     media_type: mediaType,
    //     group: sendNextModelGroupId
    //   })).then((res: any) => {
    //     setImageDetailApiCallCounter((prev: any) => prev + 1);
    //     createMediaFetch.close();
    //     setLoaderForFetch(false);
    //   })
    // }
  }

  // const openModalInPreviewMode = (link: string) => {
  //   return VideoModalRef.current?.open(link,imageDetails && imageDetails[0]?.is_mute);
  // };

  return (
    <div>
      {/* <VideoModal ref={VideoModalRef} /> */}
      {createMediaFetch.isOpen &&
        <MediaImageFetchModel
          onCloseModal={createMediaFetch.close}
          isOpen={createMediaFetch.isOpen}
          handleFetchImage={handleFetchImage}
          loaderForFetch={loaderForFetch}
          maxSelection={productListData?.filter((item: any) => productSelected == item?.top_vault_id)?.[0]?.fetch_count}
        />}
      <Modal
        open={isOpen}
        disableEnforceFocus
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description">
        <Box sx={style}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', height: '2.5rem' }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "70%",
                marginLeft: "1rem",
                marginRight: "-2rem",
              }}>
              {type == "imageGroupSideUpload" ? (
                <>
                  {abbreviationGet == "0" ? "Product" : abbreviationGet} {"->"} "{groupName}" Group {"->"} Add Images{" "}
                </>
              ) : (
                `Add ${mediaType}`
              )}
            </Typography>
            <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem' }}>
              {!checked && <>
                <span style={{ fontSize: '1rem' }}>Click if not available in this product</span>
                <Checkbox
                  checked={checked}
                  onChange={handleChangeNaBox}
                  inputProps={{ 'aria-label': 'controlled' }}
                  sx={{ color: 'primary.main' }}
                />
              </>}
              <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModalHandler} />
            </Box>
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ display: 'flex', alignItems: 'center', width: '100%', paddingBottom: '0.2rem' }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3">
            {productListData && productListData?.map((item: any, index: any) => {
              return (<>
                <Chip
                  size='medium'
                  icon={<img src={validated} style={{ display: item?.status == false ? 'none' : 'block', height: '1.5rem', cursor: 'pointer' }} />}
                  label={item?.name} variant="filled" onClick={() => handleProjectSelect(item?.top_vault_id)}
                  sx={productSelected == item?.top_vault_id ? {
                    backgroundColor: 'primary.main', color: 'white', padding: '0 2rem', cursor: "pointer",
                    '&:hover': {
                      backgroundColor: 'primary.main', color: 'white',
                    },
                  } : {
                    backgroundColor: 'primary.light', color: '#007fff', padding: '0 2rem', cursor: "pointer",
                    '&:hover': {
                      backgroundColor: 'primary.main', color: 'white',
                    },
                  }} />{productListData && productListData?.length != (index + 1) && <ArrowRightAltIcon />}
              </>)
            })}
          </Box>
          <Box sx={{ marginBottom: "-2rem", marginTop: "0.5rem", display: "flex", alignItems: "center" }}>
            <span style={{ marginRight: "1rem", marginLeft: "0.5rem" }}> Sequence : </span>
            <Box sx={{ borderRadius: "50%", border: "1px solid", width: "2rem", height: "2rem", display: "flex", alignItems: 'center', justifyContent: 'center' }}>{sequence}</Box>
          </Box>
          <Box sx={imageDetails && imageDetails?.length > 0 ? { display: 'flex', alignItems: 'center', justifyContent: 'center', width: '100%', height: '71vh' } : { width: '100%', height: '71vh' }}>
            {imageDetails && imageDetails?.length > 0 && <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', width: '70%', height: '71vh' }}>
              {mediaType == 'image'
                ? <Image
                  src={imageDetails && imageDetails[0]?.image_url_cdn}
                  placeholderImg={NoImgLoader}
                  alt={imageDetails && imageDetails[0]?.image_name}
                  style={{ height: "auto", width: "auto", maxWidth: '100%', maxHeight: '86%' }}
                />
                :
                <img
                  style={{ height: "auto", width: "auto", maxWidth: '100%', maxHeight: '86%', cursor: 'pointer' }}
                  src={imageDetails && imageDetails[0]?.thumbnail}
                // onClick={(e: any) => openModalInPreviewMode(imageDetails && imageDetails[0]?.original)}
                />}
            </Box>}
            <Box sx={imageDetails && imageDetails?.length > 0 ? { display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-evenly', width: '30%', height: '71vh' } : { display: 'flex', alignItems: 'center', justifyContent: 'space-evenly', width: '100%', height: '71vh' }}>
              {/* <AddAPhotoIcon sx={{ fontSize: '12rem' }} /> */}
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                {!isUpdating ? <>
                  {imageDetails && imageDetails?.length > 0 ? (permissionAccess && permissionAccess?.filter((item: any) => item?.top_vault == 'all')?.[0]?.action?.includes('U') && <><FileUploadIcon onClick={mediaType == "image" ? browseFiles : browseVideo} sx={{ fontSize: '12rem', cursor: 'pointer' }} /><span>Browse & Upload</span></>)
                    : (permissionAccess && permissionAccess?.filter((item: any) => item?.top_vault == 'all')?.[0]?.action?.includes('C') && <><FileUploadIcon onClick={mediaType == "image" ? browseFiles : browseVideo} sx={{ fontSize: '12rem', cursor: 'pointer' }} /><span>Browse & Upload</span></>)
                  } </> :
                  <CircularProgress color="success" />}
              </div>
              {/* {mediaType == 'image' && (imageDetails && imageDetails?.length > 0 ? permissionAccess && permissionAccess?.filter((item: any) => item?.top_vault == 'all')?.[0]?.action?.includes('U') && <Button variant='contained' sx={{ borderRadius: '3rem', padding: '1rem 2rem' }} onClick={handleFetchImageOpen}>Fetch</Button> : */}
              {imageDetails && imageDetails?.length > 0 ? `First clear the image & then fetch the ${mediaType} again.`
                : permissionAccess && permissionAccess?.filter((item: any) => item?.top_vault == 'all')?.[0]?.action?.includes('C') && <Button variant='contained' sx={{ borderRadius: '3rem', padding: '1rem 2rem' }} onClick={handleFetchImageOpen}>Fetch</Button>}

            </Box>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
            {sequence == 1 ? (
              <span></span>
            ) : (
              <Button
                style={sequence != 1 ? { cursor: "pointer" } : { cursor: "not-allowed", color: "grey" }}
                onClick={() => (sequence != 1 ? setSequence((prev: any) => prev - 1) : "")}>
                Previous ({sequence})
              </Button>
            )}
            {pagenationCount?.max_sequence != 1 && (
              <Button
                style={imageDetails && imageDetails?.length > 0 ? { cursor: "pointer" } : { cursor: "not-allowed", color: "grey" }}
                onClick={() => (imageDetails && imageDetails?.length > 0 ? setSequence((prev: any) => prev + 1) : "")}>
                Next ({pagenationCount?.max_sequence})
              </Button>
            )}
          </Box>
          <MediaAddSingleImageModel
            isOpen={uploadmodal.isOpen}
            onCloseModal={uploadmodal.close}
            saveImage={saveImage}
            isUpdating={isUpdating}
            type={'desktop'}
          />

          <MediaAddSingleVideoModel
            isOpen={uploadmodalVideo.isOpen}
            onCloseModal={uploadmodalVideo.close}
            saveVideo={saveVideo}
            isUpdating={isUpdating}
            type={'desktop'}
          />
        </Box>
      </Modal>
    </div>
  );
}
