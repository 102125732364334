import { Droppable } from "react-beautiful-dnd";
import SingleUserCard from "./SingleUserCard";
import { Box, Divider, Typography } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import AddEditUserFormModal from "./AddEditUserFormModal";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import SingleUserCardUnapproved from "./SingleUserCardUnapproved";
import AdminSingleCard from "./AdminSingleCard";

export interface IAllUserListWithGroupProps {
  projectadmin: any;
  setModuleAdmin: any;
  moduleAdmin: any;
  users: any;
  guestUser: any;
  unapproveUser: any;
  projectadminStatus: any;
  moduleadminStatus: any;
  usersStatus: any;
  guestUserStatus: any;
  unapproveUserStatus: any;
  setApiCallCount: any;
  superUserList: any;
  superUserStatus: any;
  getAllUserDetailsCount: any;
  setUnApprovedApiCallCount: any;
}

export function AllUserListWithGroup(props: IAllUserListWithGroupProps) {
  const {
    projectadmin,
    setModuleAdmin,
    moduleAdmin,
    users,
    guestUser,
    unapproveUser,
    projectadminStatus,
    moduleadminStatus,
    usersStatus,
    guestUserStatus,
    unapproveUserStatus,
    setApiCallCount,
    superUserList,
    superUserStatus,
    getAllUserDetailsCount,
    setUnApprovedApiCallCount
  } = props;
  const addEditModal = useBaseParamsModal();
  return (
    <div>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          height: "3rem",
        }}
      >
        <Typography sx={{ fontSize: "1rem", marginLeft: "1rem" }}>
          User Management (Directory)
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            columnGap: "15px",
            height: "fit-contnet",
          }}
        ></Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          columnGap: "5px",
          width: "100%",
          height: "85vh",
        }}
      >
        <Box sx={{ width: "100%", padding: "0rem 0.5rem 0 1rem" }}>
          <Droppable droppableId="projectadmin">
            {(provided, snapshot) => (
              <Box
                sx={{
                  padding: "0 0 1rem",
                  backgroundColor: "aliceblue",
                  borderRadius: "5px",
                  minHeight: "30vh",
                  minWidth: { lg: "20vw", xl: "22vw" },
                }}
                className={`todos ${snapshot.isDraggingOver ? "dragactive" : ""
                  }`}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0.1rem 0",
                  }}
                >
                  <Typography
                    sx={{
                      marginLeft: "0.5rem",
                      fontSize: "1rem",
                      display: "flex",
                      columnGap: "5px",
                      alignItems: "center",
                    }}
                    className="todos__heading"
                  >
                    Project Admins
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: "5px",
                        fontSize: "1rem",
                      }}
                    >
                      (<Typography sx={{ fontSize: "1rem", color: "#5bad06" }}>{getAllUserDetailsCount.projectadmin_active_count}</Typography>,
                      <Typography sx={{ fontSize: "1rem", color: "red" }}>{getAllUserDetailsCount?.projectadmin_inactive_count}</Typography>)
                    </Typography>
                  </Typography>
                  <AddIcon
                    sx={{ color: "primary.main", cursor: "pointer" }}
                    onClick={() => addEditModal.open("projectadmin_add")}
                  />
                </Box>
                <Box
                  sx={{ height: "82vh", margin: "0 -1rem", padding: "0 1rem" }}
                  adl-scrollbar="true"
                  adl-scrollbar-width="0.3"
                >
                  {projectadmin?.map((user: any, index: any) => (
                    <SingleUserCard index={index} item={user} key={user.id} setApiCallCount={setApiCallCount} />
                  ))}
                </Box>
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </Box>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Box sx={{ width: "100%", padding: "0rem 0.5rem" }}>
          <Droppable droppableId="users">
            {(provided, snapshot) => (
              <Box
                sx={{
                  padding: "0 0 1rem",
                  backgroundColor: "aliceblue",
                  borderRadius: "5px",
                  minHeight: "30vh",
                  minWidth: { lg: "20vw", xl: "22vw" },
                }}
                className={`todos ${snapshot.isDraggingOver ? "dragactive" : ""
                  }`}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0.1rem 0",
                  }}
                >
                  <Typography
                    sx={{
                      marginLeft: "0.5rem",
                      fontSize: "1rem",
                      display: "flex",
                      columnGap: "5px",
                      alignItems: "center",
                    }}
                    className="todos__heading"
                  >
                    Users
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: "5px",
                        fontSize: "1rem",
                      }}
                    >
                      (
                      <Typography sx={{ fontSize: "1rem", color: "#5bad06" }}>
                        {getAllUserDetailsCount?.user_active_count}
                      </Typography>
                      ,
                      <Typography sx={{ fontSize: "1rem", color: "red" }}>
                        {getAllUserDetailsCount?.user_inactive_count}
                      </Typography>
                      )
                    </Typography>
                  </Typography>
                  <AddIcon sx={{ color: "primary.main", cursor: "pointer" }} onClick={() => addEditModal.open("users_add")} />
                </Box>
                <Box
                  sx={{ height: { lg: '78vh', xl: "82vh" }, margin: "0 -1rem", padding: "0 1rem" }}
                  adl-scrollbar="true"
                  adl-scrollbar-width="0.3"
                >
                  {users &&
                    users?.map((user: any, index: any) => (
                      <SingleUserCard
                        index={index}
                        item={user}
                        key={user?.id}
                        setApiCallCount={setApiCallCount}
                      />
                    ))}
                </Box>
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </Box>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Box sx={{ width: "100%", padding: "0rem 0.5rem" }}>
          <Droppable droppableId="guestusers">
            {(provided, snapshot) => (
              <Box
                sx={{
                  padding: "0 0 1rem",
                  backgroundColor: "aliceblue",
                  borderRadius: "5px",
                  minHeight: "30vh",
                  minWidth: { lg: "20vw", xl: "22vw" },
                  maxWidth: { lg: "20vw", xl: "22vw" },
                }}
                className={`todos ${snapshot.isDraggingOver ? "dragactive" : ""
                  }`}
                ref={provided.innerRef}
                {...provided.droppableProps}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    padding: "0.1rem 0",
                  }}
                >
                  <Typography
                    sx={{
                      marginLeft: "0.5rem",
                      fontSize: "1rem",
                      display: "flex",
                      columnGap: "5px",
                      alignItems: "center",
                    }}
                    className="todos__heading"
                  >
                    Guest Users
                    <Typography
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        columnGap: "5px",
                        fontSize: "1rem",
                      }}
                    >
                      (
                      <Typography sx={{ fontSize: "1rem", color: "#5bad06" }}>
                        {getAllUserDetailsCount?.guest_user_active_count}
                      </Typography>
                      ,
                      <Typography sx={{ fontSize: "1rem", color: "red" }}>
                        {getAllUserDetailsCount?.guest_user_inactive_count}
                      </Typography>
                      )
                    </Typography>
                  </Typography>
                  <AddIcon sx={{ color: "primary.main", cursor: "pointer" }} onClick={() => addEditModal.open("guestusers_add")} />
                </Box>
                <Box
                  sx={{ height: { lg: '78vh', xl: "82vh" }, margin: "0 -1rem", padding: "0 1rem" }}
                  adl-scrollbar="true"
                  adl-scrollbar-width="0.3"
                >
                  {guestUser &&
                    guestUser?.map((user: any, index: any) => (
                      <SingleUserCard
                        index={index}
                        item={user}
                        key={user?.id}
                        setApiCallCount={setApiCallCount}
                      />
                    ))}
                </Box>
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </Box>
        <Divider orientation="vertical" variant="middle" flexItem />
        <Box sx={{ width: "100%", padding: "0rem 0.5rem" }}>
          <Box sx={{ height: unapproveUserStatus?.active_count > 0 ? { lg: '34vh', xl: "39vh" } : { lg: '78vh', xl: "82vh" } }}>

            <Box
              sx={{
                padding: "0 0 1rem",
                backgroundColor: "aliceblue",
                borderRadius: "5px",
                minHeight: "30vh",
                minWidth: { lg: "20vw", xl: "22vw" },
              }}

            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "0.1rem 0",
                }}
              >
                <Typography
                  sx={{
                    marginLeft: "0.5rem",
                    fontSize: "1rem",
                    display: "flex",
                    columnGap: "5px",
                    alignItems: "center",
                  }}
                  className="todos__heading"
                >
                  Super Admins
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: "5px",
                      fontSize: "1rem",
                    }}
                  >
                    (
                    <Typography sx={{ fontSize: "1rem", color: "#5bad06" }}>
                      {getAllUserDetailsCount?.superuser_active_count}
                    </Typography>,
                    <Typography sx={{ fontSize: "1rem", color: "red" }}>
                      {getAllUserDetailsCount?.superuser_inactive_count}
                    </Typography>
                    )
                  </Typography>
                </Typography>
                {/* <AddIcon
                      sx={{ color: "primary.main", cursor: "pointer" }}
                      onClick={() => addEditModal.open("guestusers_add")}
                    /> */}
              </Box>
              <Box
                sx={{
                  height: unapproveUserStatus?.active_count > 0 ? { lg: '34vh', xl: "37vh" } : { lg: '78vh', xl: "82vh" },
                  margin: "0 -1rem",
                  padding: "0 1rem",
                }}
                adl-scrollbar="true"
                adl-scrollbar-width="0.3"
              >
                {superUserList &&
                  superUserList?.map((user: any, index: any) => (
                    <AdminSingleCard
                      index={index}
                      item={user}
                      key={user?.id}
                      setApiCallCount={setApiCallCount}
                    />
                  ))}
              </Box>
            </Box>

          </Box>
          {unapproveUserStatus?.active_count > 0 ?
            <Divider variant="middle" flexItem sx={{ margin: "1rem 0" }} />
            : ""}
          {unapproveUserStatus?.active_count > 0 && <Box sx={{ height: "45vh" }}>

            <Box
              sx={{
                padding: "0 0 1rem",
                backgroundColor: "aliceblue",
                borderRadius: "5px",
                minHeight: "30vh",
                minWidth: { lg: "20vw", xl: "22vw" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  padding: "0.1rem 0",
                }}
              >
                <Typography
                  sx={{
                    marginLeft: "0.5rem",
                    fontSize: "1rem",
                    display: "flex",
                    columnGap: "5px",
                    alignItems: "center",
                  }}
                  className="todos__heading"
                >
                  Unapproved Users
                  <Typography
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: "5px",
                      fontSize: "1rem",
                    }}
                  >
                    (
                    <Typography sx={{ fontSize: "1rem", color: "#5bad06" }}>
                      {getAllUserDetailsCount?.unapproved_user_active_count}
                    </Typography>
                    ,
                    <Typography sx={{ fontSize: "1rem", color: "red" }}>
                      {getAllUserDetailsCount?.unapproved_user_inactive_count}
                    </Typography>
                    )
                  </Typography>
                </Typography>
              </Box>
              <Box
                sx={{
                  height: "40vh",
                  margin: "0 -1rem",
                  padding: "0 1rem",
                }}
                adl-scrollbar="true"
                adl-scrollbar-width="0.3"
              >
                {unapproveUser &&
                  unapproveUser?.map((user: any, index: any) => (
                    user && <SingleUserCardUnapproved
                      index={index}
                      item={user && user}
                      key={user?.id}
                      setApiCallCount={setUnApprovedApiCallCount}
                    />
                  ))}
              </Box>
            </Box>

          </Box>}
        </Box>
        <Divider orientation="vertical" variant="middle" flexItem />
      </Box>
      {addEditModal.isOpen && (
        <AddEditUserFormModal
          isOpen={addEditModal.isOpen}
          onClose={addEditModal.close}
          userDetails={addEditModal.propsId}
          setApiCallCount={setApiCallCount}
        />
      )}
    </div>
  );
}
