
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { ID } from "../../../../../../../utlis/commonInterfaces";


// ----------------------------------------------------------------------------------

const useStrokeDatabaseModal = () => {
    const [isOpen, setIsOpen] = useState( false );
    const [inputId, setInputId] = useState<ID>( 0 );
    const [showtable, setShowtable] = useState( false );
    const [path, setPath] = useState( '' );
    const [type, setType] = useState( 0 );
    const url = useLocation();

    const handleOpen = ( inputId: ID ) => ( database: number ) => {
        setInputId( inputId );
        setShowtable( false );
        setIsOpen( true );
        setType( 0 );
    };

    const handleClose = () => {
        setInputId( 0 );
        setShowtable( false );
        setIsOpen( false );
        setType( 0 );
    };

    const handleSelect = ( ed: any ) => {
        setType( ed );
        //checing path -------------
        setPath( url.pathname.includes( "/sellCost/create/" ) ? 'sell' : 'oem' );
        setShowtable( true );
    };
    return Object.freeze( {
        open: handleOpen,
        close: handleClose,
        isOpen,
        inputId,
        showtable,
        selectedCard: handleSelect,
        type, path
    } );
};

// ----------------------------------------------------------------------------------

export { useStrokeDatabaseModal };
