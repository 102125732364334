
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import GROUPINGIMAGE from "../Services/groupingMediaCreate.service";
import { ID } from "../../utlis/commonInterfaces";
import { groupingImage } from "../Reducers/groupingMediaCreate.reducer";


export interface fetchGroupsByType {
    projectId: ID;
    mediaType: any;
    category: any;
    abbreviation: any;
    get_groups: any;
    get_media_groups:any;
}

export const fetchGroupsByType = createAsyncThunk(
    "ImageRepo/GroupingImage/get",
    async ( arg: fetchGroupsByType, { rejectWithValue } ) => {
        try {
            const { data } = await GROUPINGIMAGE.getAllGroups(
                arg.projectId, arg.mediaType, arg.category, arg.abbreviation, arg.get_groups, arg.get_media_groups
            );

            return {
                Imagegroups: data as groupingImage[],
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);
//---------------------------------------------------------------------

export interface createGroupsByType {
    name: any;
    projectId: ID;
    mediaType: any;
    abbreviation: any;
    image_category?:any;
}

export const createGroupsByType = createAsyncThunk(
    "ImageRepo/GroupingImage/create",
    async (
        arg: createGroupsByType,
        { rejectWithValue }
    ) => {
        try {
            const { data } = await GROUPINGIMAGE.createGroup(
                arg.name, arg.projectId, arg.mediaType, arg.abbreviation,arg?.image_category
            );
            return {
                ...arg,
                Imagegroups: data as groupingImage[],
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);
//--------------------------------------------------------------------

export interface updateGroupsByType {
    id: any;
    name: any;
}

export const updateGroupsByType = createAsyncThunk(
    "ImageRepo/GroupingImage/update",
    async (
        arg: updateGroupsByType,
        { rejectWithValue }
    ) => {
        try {
            const { data } = await GROUPINGIMAGE.updateGroup(
                arg.id,
                arg.name
            );
            return {
                ...arg,
                Imagegroups: data as groupingImage[],
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);
//--------------------------------------------------------------------

export interface deleteGroupsByType {
    id: any;
    topVault: any;
    abbreviation: any;
    media_type: any;
    image_category: any;
    type:number
}

export const deleteGroupsByType = createAsyncThunk(
    "ImageRepo/GroupingImage/deleteGroup",
    async (
        arg: deleteGroupsByType,
        { rejectWithValue }
    ) => {
        try {
            const { data } = await GROUPINGIMAGE.deleteGroup(
                arg.id, arg.topVault, arg.abbreviation, arg.media_type, arg.image_category,arg.type
            );
            return {
                ...arg,
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

//------------------------------------------------------------------------
export interface moveImageToAnotherGrp {
    id: any;
    group: any;
    media_type: any;
    new_sequence: any;
}

export const moveImageToAnotherGrp = createAsyncThunk(
    "ImageRepo/GroupingImage/MoveImage",
    async (
        arg: moveImageToAnotherGrp,
        { rejectWithValue }
    ) => {
        try {
            const { data } = await GROUPINGIMAGE.moveImageToAnotherGrp(
                arg.id,
                arg.group,
                arg.media_type,
                arg.new_sequence
            );
            return data;
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);