import { createEntityAdapter, createReducer, createSelector, EntityState } from "@reduxjs/toolkit";
// redux


import { ID } from "../../utlis/commonInterfaces";
import { ADMIN } from "../Services/admin.service";
import { RootState } from "../Store/storeConfigurations";
import { createMomGroup, deleteMomGroup, getMomGroup, updateMomGroup } from "../Actions/Mom.action";

// -----------------------------------------------------------------------------------
// entity adaptor
export interface groupingMom {
    id: ID;
    name: string;
    attendees: any;
    notes_count: number;
    open: number;
    close: number;
    project: number;
    client: boolean;
}
const adaptor = createEntityAdapter<groupingMom>( {
    selectId: ( groupingMom ) => groupingMom.id,
} );
// -----------------------------------------------------------------------------------
// interfaces & initialState
type AdditionalFields = {};
type IgroupingDriveReducer = EntityState<groupingMom> & AdditionalFields;
const additionalFields: AdditionalFields = {};
const initialState: IgroupingDriveReducer = Object.assign( {}, adaptor.getInitialState(), additionalFields );
// -----------------------------------------------------------------------------------
// reducer
const groupingMomReducer = createReducer( initialState, builder => {

    // // get drive group
    builder
        .addCase( getMomGroup.fulfilled, ( state, action: any ) => {
            // console.log( action.payload, "data" );

            adaptor.setAll( state, action.payload.momGroup );
        } )
        .addCase( getMomGroup.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could not fetch Group" );
        } );
    //create 
    builder
        .addCase( createMomGroup.fulfilled, ( state, action: any ) => {
            adaptor.addOne( state, action.payload.momGroup );
            // ADMIN.toast.success( "Group created" );
        } )
        .addCase( createMomGroup.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could not create Group" );
        } );
    // update

    builder
        .addCase( updateMomGroup.fulfilled, ( state, action: any ) => {
            adaptor.upsertOne( state, action.payload.Drivegroups );

            // ADMIN.toast.success( "Group updated" );
        } )
        .addCase( updateMomGroup.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could not update group" );
        } );

    //delete
    builder
        .addCase( deleteMomGroup.fulfilled, ( state, action: any ) => {
            const { id } = action.meta.arg;
            adaptor.removeOne( state, id );
            // ADMIN.toast.success( "Deleted Successfully" );
        } )
        .addCase( deleteMomGroup.rejected, ( state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could not delete Group" );
        } );

} );

// selectors
const momGroupsSelector = adaptor.getSelectors<RootState>( ( state ) => state.momGroup );
const byMomAbbreviationSelectors = Object.assign(
    {},
    momGroupsSelector ,

);

// -----------------------------------------------------------------------------------
// exports

export {
    groupingMomReducer,
    initialState as groupingMomReducerInit,
    byMomAbbreviationSelectors
};