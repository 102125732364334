import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import { Button, Divider, Skeleton, Typography } from "@mui/material";
import CancelIcon from '@mui/icons-material/Cancel';
import OutlinedInput from '@mui/material/OutlinedInput';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import ListItemText from '@mui/material/ListItemText';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import { API } from "../../api-services";
import { LoadingButton } from "@mui/lab";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: '50vw', xl: "40vw" },
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
  zIndex: 0,
};

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


interface ShareWithPopUpProps {
  isOpen: any;
  onCloseModal: any;
  idIncoming: any;
  shareItemName: any;
  shareItemCode: any;
  shareItemCategory: any;
  apiCallAccordianInsideData: any;
  setApiCallAccordianInsideData: any;
  apiCallAccordianData?: any,
  setApiCallAccordianData?: any
}

const ShareWithPopUp = (props: ShareWithPopUpProps) => {
  const { categoryName, search } = useRouteParams<any>();
  const { isOpen, onCloseModal, idIncoming, shareItemName, shareItemCode, shareItemCategory, apiCallAccordianInsideData, setApiCallAccordianInsideData, apiCallAccordianData, setApiCallAccordianData } = props;
  const [commodity, setCommodity] = useState<any>();
  const [moveLoader, setMoveLoader] = useState<boolean>(false);
  const [loading, setLoading] = useState<any>(false)
  const [commodityList, setCommodityList] = useState<any>([]);
  const [commodityListLoader, setCommodityListLoader] = useState<boolean>(true);

  const handleChange = (e: any) => {
    setCommodity(e.target.value);
  }

  useEffect(() => {
    setCommodityList([]);
    setCommodityListLoader(true);
    API.get("/api/db/commodity/").then((res: any) => {
      let data: any = res?.data?.filter((item: any, index: any) => { return item?.name !== "UnCategorised" && item?.name != shareItemCategory })
      setCommodityList(data);
      setCommodityListLoader(false);
    });
  }, [isOpen])

  const handleMoveTools = () => {
    setMoveLoader(true);
    API.post('/api/db/move_cost_data/', { target_commodity: commodityList && commodityList?.filter((item: any) => { return item?.name == commodity })?.[0]?.id, id: idIncoming, tools: true }).then((res: any) => {
      setApiCallAccordianInsideData(!apiCallAccordianInsideData);
      search == "0" && setApiCallAccordianData(!apiCallAccordianData);
      setMoveLoader(false);
      onCloseModal();
    }).catch((err: any) => {
      setMoveLoader(false);
    })
  }

  return (
    <div>
      <Modal
        open={isOpen}
        // onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <>
          <Box sx={style}>
            <Box
              sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
              <Typography
                sx={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  width: "100%",
                  textAlign: "center",
                  marginRight: "-2rem",
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-around',
                }}>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontWeight: 500,
                    marginBottom: "0.5rem",
                  }}>
                  Tool Information
                </Typography>
                <Typography
                  sx={{
                    textAlign: "center",
                    fontWeight: 500,
                    marginBottom: "0.5rem",
                  }}>
                  Move Tool To
                </Typography>
              </Typography>
              <CancelIcon
                titleAccess="Close"
                sx={{ cursor: "pointer", margin: "0.5rem" }}
                onClick={onCloseModal}
              />
            </Box>

            <Divider
              sx={{
                borderColor: "primary.light",
                margin: "0 1rem",
                marginBottom: "1rem",
              }}
            />
            <Box sx={{ display: 'flex', justifyContent: 'space-between',columnGap:'1rem', alignItems: 'flex-start' }}>
              <Typography sx={{ padding: '0 1rem' }}>
                <Typography sx={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
                  <Typography sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '8rem' }}>
                    <Typography sx={{ fontSize: '1rem', }}>Tool Name</Typography>
                    <Typography sx={{ fontSize: '1rem' }}>:</Typography>
                  </Typography>
                  <Typography sx={{ fontSize: '1rem', whiteSpace: 'nowrap' }}> {commodityListLoader ? <span>Loading....</span> : <span> {shareItemName}</span>}</Typography>
                </Typography>
                {/* <Typography>
                  <span style={{ marginRight: '3rem', marginLeft: '1rem', fontWeight: 500 }}>Tool Name : </span>
                  <span></span>
                </Typography> */}
                <Typography sx={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
                  <Typography sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '8rem' }}>
                    <Typography sx={{ fontSize: '1rem', }}>Tool Code</Typography>
                    <Typography sx={{ fontSize: '1rem' }}>:</Typography>
                  </Typography>
                  <Typography sx={{ fontSize: '1rem', whiteSpace: 'nowrap' }}> {shareItemCode}</Typography>
                </Typography>
                {/* <Typography>
                  <span style={{ marginRight: '3rem', marginLeft: '1rem', fontWeight: 500 }}>Tool Code : </span>
                  <span></span>
                </Typography> */}
                <Typography sx={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
                  <Typography sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '8rem' }}>
                    <Typography sx={{ fontSize: '1rem', }}>Tool Commodity</Typography>
                    <Typography sx={{ fontSize: '1rem' }}>:</Typography>
                  </Typography>
                  <Typography sx={{ fontSize: '1rem', whiteSpace: 'nowrap' }}> {shareItemCategory}</Typography>
                </Typography>
                {/* <Typography>
                  <span style={{ marginRight: '3rem', marginLeft: '1rem', fontWeight: 500 }}>Tool Commodity : </span>
                  <span>{shareItemCategory}</span>
                </Typography> */}
              </Typography>
              <FormControl sx={{ mr: 1, width: 300 }} variant="standard">
                {commodityListLoader ? <Skeleton width={'100%'} height={'5rem'} /> : <>
                  {/* <InputLabel
                    id="demo-simple-select-label-Activity"
                    sx={{ color: "primary.main", fontSize: '0.87rem'}}
                  >
                    Select Commodity
                  </InputLabel> */}
                  <Select
                    variant="standard"
                    labelId="demo-simple-select-standard-label"
                    id="demo-simple-select-standard"
                    value={commodity}
                    onChange={handleChange}
                    // input={<OutlinedInput label="Commodity" />}
                    renderValue={(selected) => selected}
                    MenuProps={MenuProps}
                    placeholder=" Select Commodity"
                    sx={{
                      width: '100%',
                      marginTop: '0.5rem',
                      "&:before": {
                        borderBottomColor: "primary.light",
                      },
                      '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
                    }}
                  >
                    {commodityList && commodityList?.map((commodityData: any) => (
                      <MenuItem sx={{ fontSize: '1rem' }} key={commodityData?.name} value={commodityData?.name}>
                        <Checkbox style={{ padding: '3px' }} checked={commodityData?.name == commodity} />
                        <ListItemText sx={{ fontSize: '1rem' }} primary={commodityData?.name} />
                      </MenuItem>
                    ))}
                  </Select>
                </>}
              </FormControl>
            </Box>
            {/* <Box sx={{ textAlign: 'right', marginTop: '1rem', marginRight: '0.5rem' }}>
              <LoadingButton loading={moveLoader} sx={{ padding: '0.2rem' }} variant="contained" onClick={handleMoveTools}>Move</LoadingButton>
            </Box> */}
            <Box sx={{ textAlign: 'right', marginTop: '1rem', marginRight: '0.5rem' }}>
              <LoadingButton loading={moveLoader}
                size="small" variant="contained" sx={{
                  padding: "0.5rem",
                  lineHeight: 1,
                  '.MuiCircularProgress-root': {
                    color: 'primary.main',
                  },
                }}
                onClick={handleMoveTools}>Move</LoadingButton>
            </Box>
          </Box>
        </>
      </Modal>
    </div >
  );
};

export default ShareWithPopUp;
