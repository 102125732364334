import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ID } from '../../utlis/commonInterfaces';

interface Node {
  id: ID;
  name: string;
  is_leaf: boolean;
  subsystem: Node[]; // Recursive structure
}

interface HierarchyState {
  nodes: Node[]; // Top-level nodes
  openNodes: ID[]; // Expanded node IDs
}

export const initialState: HierarchyState = {
  nodes: [], // Start with an empty array
  openNodes: JSON.parse(localStorage.getItem("openNodes") || "[]"), // Restore from localStorage
};

const hierarchySlice = createSlice({
  name: 'hierarchy',
  initialState,
  reducers: {
    setOpenNodes: (state, action: PayloadAction<ID[]>) => {
      state.openNodes = action.payload;
      localStorage.setItem("openNodes", JSON.stringify(state.openNodes));
    },
    // Other reducers for fetching top-level nodes, child nodes, etc.
    setTopLevelNodes: (state, action: PayloadAction<Node[]>) => {
      state.nodes = action.payload;
    },
    addChildNodes: (state, action: PayloadAction<{ parentId: ID; childNodes: Node[] }>) => {
      const parentNode = state.nodes.find(node => node.id === action.payload.parentId);
      if (parentNode) {
        parentNode.subsystem = [...parentNode.subsystem, ...action.payload.childNodes];
      }
    }
  },
  // Extra reducers for async actions can be added here
});

export const { setOpenNodes, setTopLevelNodes, addChildNodes } = hierarchySlice.actions;
export default hierarchySlice.reducer;
