import { useState } from "react";

// ----------------------------------------------------------------------------------

const useToolAddEditModel = () => {
    const [isOpen, setIsOpen] = useState( false );
    const [actionSend, setActionSend] = useState(  );
    const [valueEdit,setValueEdit] = useState<any>();
    const [id,setId] = useState<any>();

    const handleOpen = (idGet:any,actionGet:any,valueEditGet?:any) => {
        setIsOpen( true );
        setId(idGet);
        setActionSend(actionGet);
        setValueEdit(valueEditGet)
    }
    const handleClose = () => {
        setIsOpen( false );
        setId(undefined);
        setActionSend(undefined);
        setValueEdit(undefined)
    }

    return Object.freeze( {
        open: handleOpen,
        close: handleClose,
        isOpen,
        id,
        actionSend,
        valueEdit
    } );
};


// ----------------------------------------------------------------------------------

export { useToolAddEditModel };