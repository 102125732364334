import * as React from 'react';
import {
  Box,
  Button,
  Divider,
  FormControl,
  TextField,
  Typography,
} from "@mui/material";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import { LoadingButton } from "@mui/lab";
import { API } from "../../../api-services";
import Swal from "sweetalert2";
import successIcon from "../../../Assets/images/success.png";
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';


export interface ICostModelCancelAlertModalProps {
  onCloseModal: any;
  isOpen: any;
  cancelHandler: any;
  parentClose: any;
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};


export function CostModelCancelAlertModal(props: ICostModelCancelAlertModalProps) {
  const { onCloseModal, isOpen, cancelHandler, parentClose } = props;

  // const cancelHandler = () => {
  //   onCloseModal();
  // };


  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>        
          <Box sx={{ width: '100%', textAlign: 'center', margin: '1rem' }}>
            <Box sx={{ color: '#f27474', borderRadius: '50%', height: '6rem', width: '6rem', border: '2px solid #f27474', display: 'flex', alignItems: 'center', justifyContent: 'center', margin: '0 auto 1rem' }}>
              <PriorityHighIcon sx={{ color: '#f27474', fontSize: '4rem' }} />
            </Box>
            <Typography sx={{ fontWeight: '500', fontSize: '1rem' }}> You have not selected any cost model <br />are you sure?</Typography>
            <Box sx={{ width: '100%', marginTop: '1rem', display: 'flex', alignItems: 'center', columnGap: '1rem', justifyContent: 'center' }}>
              <Button size='small' sx={{
                "&:hover": {
                  transform: 'Scale(1.05)',
                  transition: 'transform 0.5s ease',
                },
              }} variant='contained' onClick={onCloseModal}>No</Button>
              <Button size='small'
                sx={{
                  "&:hover": {
                    transform: 'Scale(1.05)',
                    transition: 'transform 0.5s ease',
                  },
                }}
                variant='contained' onClick={() => cancelHandler()}>Yes</Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
