import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Checkbox,
    Divider,
    Drawer,
    IconButton,
    List,
    ListItem,
    Typography,
  } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import CancelIcon from '@mui/icons-material/Cancel';
import AddIcon from "@mui/icons-material/Add";
import styles from './IdeaDashboard.module.scss';
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { API } from "../../../api-services";
import CircleComponent from "./CircleComponent";
import { useHistory } from "react-router-dom";
import LetSuspense from "../../../LetSuspense";
import BouncingLoader from "../../../ui-reusable-component/BouncingLoader";

interface IdeaPrioritisationMatrixI{}
interface Params {
    projectId: any;
    topVaultId: any;
}

type Anchor = "top" | "left" | "bottom" | "right";

const IdeaPrioritisationMatrix:React.FC<IdeaPrioritisationMatrixI>=(props)=>{
    const { projectId, topVaultId } = useRouteParams<Params>();
    const [selectedProducts, setSelectedProducts] = useState<any>([]);
    const [selectedProject, setSelectedProject] = useState<any>([]);
    const [productAvailable, setProductAvailable] = useState<any>([]);
    const [selectedDummyProducts, setSelectedDummyProducts] = useState<any>([]);
    const [projectList, setProjectList] = useState<any>([]);
    const [dashboardListAPI, setDashboardListAPI] = useState<boolean>(false);
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
      });
    const history = useHistory();

    const selectAllProduct = (productIds: any) => {
        setSelectedDummyProducts([productIds])
        setSelectedProducts([productIds]);
      };
    
      useEffect(() => {
        API.get(
          `/customer/dashboard/`,
          {
            get_module_project: "Ideas",
          },
          0
        ).then((res: any) => {
          setProjectList(res?.data);
          setDashboardListAPI(true);
        });
      }, []);
    
      useEffect(() => {
        API.get(
          `/customer/dashboard/`,
          {
            get_idea_topvault: true,
            project: projectId,
          },
          0
        ).then((res: any) => {
          setProductAvailable(res?.data);
        });
      }, [projectId]);
    

    useEffect(() => {
        setSelectedProject([projectId]);
      }, []);

      const selectAllProject = (productIds: any) => {
        if(productIds==projectId){
          setSelectedDummyProducts([topVaultId])
        }
        else{
          setSelectedDummyProducts([])
        }
        setSelectedProject([productIds]);
        API.get(
          `/customer/dashboard/`,
          {
            get_idea_topvault: true,
            project: productIds,
          },
          0
        ).then((res: any) => {
          setProductAvailable(res?.data);
        });
      };

      const getselectedProjectBackgroundColor = (idd: any) => {
        if (selectedProject?.includes(idd)) {
          return "primary.main";
        } else {
          return "primary.light";
        }
      };
      const getSelectedProjectTextColor = (idd: any) => {
        if (selectedProject?.includes(idd)) {
          return "primary.light";
        } else {
          return "primary.main";
        }
      };
    
      const getselectedBackgroundColor = (idd: any) => {
        if (selectedProducts?.includes(idd)) {
          return "primary.main";
        } else {
          return "primary.light";
        }
      };
      const getSelectedTextColor = (idd: any) => {
        if (selectedProducts?.includes(idd)) {
          return "primary.light";
        } else {
          return "primary.main";
        }
      };
    
  const handleChangeIdea = (anchor: any) => {    
    history.push(
      `/idea_dashboard/prioritisationMatrix/${selectedProject[0]}/${selectedProducts[0]}`
    );
  };

    const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }
      setSelectedProducts([topVaultId]);
      API.get(
        `/customer/dashboard/`,
        {
          get_idea_topvault: true,
          project: projectId,
        },
        0
      ).then((res: any) => {
        setProductAvailable(res?.data);
      });
      setSelectedDummyProducts([topVaultId]);
      setSelectedProject([projectId]);
      setState({ ...state, [anchor]: open });
    };


  const projectDetail: any =
  projectList &&
  projectList?.filter((item: any) => {
    return item?.proj_id == projectId;
  });


  const backButtonHandler: any = () => {
    history.push(`/idea_dashboard/${projectId}/${topVaultId}`);
  };
  
    const list = (anchor: Anchor) => (
        <Box
          sx={{ minWidth:'300px',width: anchor === "top" || anchor === "bottom" ? "auto" : "auto" }}
          role="presentation"
        >
          <List>
            <ListItem>
              <Box sx={{ width: "100%" }}>
                <div>
                  <Typography
                    style={{
                      width: "100%",
                      fontSize: "1rem",
                    }}
                  >
                    Project Name
                  </Typography>
                  {projectList &&
                    projectList?.map((item: any, index: any) => {
                      return (
                        <>
                          <Box sx={{ alignItems: "center", display: "flex" }}>
                            <Checkbox
                              checked={
                                selectedProject?.includes(item.proj_id)
                                  ? true
                                  : false
                              }
                              onClick={() => selectAllProject(item?.proj_id)}
                              inputProps={{ "aria-label": "controlled" }}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                            />
                            <Box
                              key={index}
                              onClick={() => selectAllProject(item?.proj_id)}
                              className={styles.selectVehBtn}
                              sx={{
                                padding: "0.2rem 0.5rem",
                                backgroundColor: getselectedProjectBackgroundColor(
                                  item.proj_id
                                ),
                                color: getSelectedProjectTextColor(item.proj_id),
                                textAlign: "left",
                                cursor: "pointer",
                                display: "inline-block",
                                width: "100%",
                                // marginBottom: '1rem'
                              }}
                            >
                              <Typography>{item.proj_name}</Typography>
                            </Box>
                          </Box>
                        </>
                      );
                    })}
                </div>
                <div>
                  <Typography
                    style={{
                      width: "100%",
                      fontSize: "1rem",
                    }}
                  >
                    Product Name
                  </Typography>
                  {productAvailable &&
                    productAvailable?.map((item: any, index: any) => {
                      return (
                        <>
                          <Box sx={{ alignItems: "center", display: "flex" }}>
                            <Checkbox
                              checked={
                                selectedProducts?.includes(item?.topvault_id)
                                  ? true
                                  : false
                              }
                              onClick={() => selectAllProduct(item?.topvault_id)}
                              inputProps={{ "aria-label": "controlled" }}
                              sx={{ "& .MuiSvgIcon-root": { fontSize: 24,} }}
                            />
                            <Box
                              key={index}
                              onClick={() => selectAllProduct(item?.topvault_id)}
                              className={styles.selectVehBtn}
                              sx={{
                                padding: "0.2rem 0.5rem",
                                backgroundColor: getselectedBackgroundColor(
                                  item.topvault_id
                                ),
                                color: getSelectedTextColor(item.topvault_id),
                                textAlign: "left",
                                cursor: "pointer",
                                display: "inline-block",
                                width: "100%",
                                // marginBottom: '1rem'
                              }}
                            >
                              <Typography>{item?.topvault_name}</Typography>
                            </Box>
                          </Box>
                        </>
                      );
                    })}
                </div>
                <div style={{ textAlign: "right", marginTop: "1rem" }}>
                  <Button
                    className={styles.drawBtn}
                    sx={{ marginRight: "1rem" }}
                    variant="contained"
                    onClick={toggleDrawer(anchor, false)}
                  >
                    Close
                  </Button>
                  <Button
                    variant="contained"
                    className={styles.drawBtn}
                    onClick={() => handleChangeIdea(anchor)}
                    disabled={
                      selectedProducts?.length < 1 || selectedProducts?.length > 1 || selectedDummyProducts?.length==0
                    }
                    > 
                    <Button
                    className={styles.drawBtn}
                    variant="contained"
                    onClick={toggleDrawer(anchor, false)}
                    size="small"
                   > Ok
                   </Button>
                  </Button>
                </div>
              </Box>
            </ListItem>
          </List>
        </Box>
      );

    return (
      <LetSuspense
      loadingPlaceholder={BouncingLoader}
      condition={dashboardListAPI}
    >
        <div
          className={styles.containerFullScreen}
        >
          <Box>
            <Box sx={{ width: "3rem", padding: "0 0.5rem" }}>
              <IconButton
                sx={{ cursor: "pointer" }}
                onClick={backButtonHandler}
              >
                <ArrowBackIcon color="primary" titleAccess="Go Back" />
              </IconButton>
            </Box>
            <Box className={styles.expandIconBox}>
              <div className={styles.iconSec}>
                {(["left"] as const).map((anchor) => (
                  <React.Fragment key={anchor}>
                    <IconButton
                      size="medium"
                      sx={{
                        backgroundColor: "primary.light",
                        color: "primary.main",
                      }}
                      onClick={toggleDrawer(anchor, true)}
                    >
                      <AddIcon sx={{ fontSize: "1.7rem" }} />
                    </IconButton>
                    <Drawer
                      anchor={anchor}
                      open={state[anchor]}
                      onClose={toggleDrawer(anchor, false)}
                      className={styles.drawerContainer}
                      PaperProps={{
                        sx: {
                          backgroundColor: "aliceblue",
                        },
                      }}
                    >
                      <Box
                        sx={{ padding: "1rem" }}
                        adl-scrollbar="true"
                        adl-scrollbar-width="0.3"
                      >
                        <Typography
                          style={{ width: "100%", textAlign: "center" }}
                        >
                          Select Project & Product
                        </Typography>
                        <Divider sx={{ margin: "1rem" }} />
                        {list(anchor)}
                      </Box>
                    </Drawer>
                    <Typography
                      style={{ cursor: "pointer" }}
                      className={styles.sideTitle}
                      onClick={toggleDrawer(anchor, true)}
                    >
                      Select Project & Product
                    </Typography>
                  </React.Fragment>
                ))}
              </div>
            </Box>
          </Box>
          <div
            style={
              { borderLeft: "1px solid #007FFF14" }
            }
          >
              <>
              <Typography
                style={{ margin: "0.5rem",fontWeight: "bold",fontSize:'1rem' }}
              >{`Idea Dashboard`}</Typography>
              <Box sx={{display:'flex',justifyContent:'space-between',alignItems:'center',paddingRight:'1rem'}}>
              <Typography
                style={{ margin: "0.5rem" ,fontSize:'1rem'}}
              >{` ${projectDetail && projectDetail[0]?.proj_name} [ ${
                projectDetail && projectDetail[0]?.proj_description
              } ]`}</Typography>
              <CancelIcon sx={{fontSize:'1.5rem', cursor:'pointer'}} onClick={backButtonHandler}/>
              </Box>
              </>
            <CircleComponent/>
          </div>
        </div>
        </LetSuspense>
    )
}

export default IdeaPrioritisationMatrix;