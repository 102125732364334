import {
    createEntityAdapter,
    createReducer,
    EntityState,
} from "@reduxjs/toolkit";

import {
    getCustomValidations,
    resetCustomValidations,
} from "../Actions/customValidations.actions";
import { ID } from "../../utlis/commonInterfaces";
import { ADMIN } from "../Services/admin.service";
import { RootState } from "../Store/storeConfigurations";

// -----------------------------------------------------------------------------------
// entity adaptor

export interface ICustomValidation {
    id: ID;
    /** parameter name */
    key: string;
    /** parameter config(parameter_mapping) id */
    relation_id: ID;
    /** id to parameter db */
    parameter_id: ID;
    validation: {
        uid: string;
        allowedValue: string[];
        notAllowedValue: string[];
        finalExpression: string;
        expressions: {
            uid: string;
            lhs: string;
            rhs: string;
            operation: string;
            type: "p-p" | "p-v" | "p-m";
        }[];
        message: string;
    }[];
}

const customValidationAdaptor = createEntityAdapter<ICustomValidation>({
    selectId: customValidation => customValidation.id,
    sortComparer: (a, b) => a.key.localeCompare(b.key),
});

// -----------------------------------------------------------------------------------
// interfaces & initialState

type AdditionalFields = {};

type ICustomValidationReducer = EntityState<ICustomValidation> &
    AdditionalFields;

const additionalFields: AdditionalFields = {};

const initialState: ICustomValidationReducer = Object.assign(
    {},
    customValidationAdaptor.getInitialState(),
    additionalFields
);

// -----------------------------------------------------------------------------------
// reducer

const CustomValidationReducer = createReducer(initialState, builder => {
    // get
    builder
        .addCase( getCustomValidations.fulfilled, ( state, action: any) => {
            customValidationAdaptor.setAll(state, action.payload.validations);
        })
        .addCase(getCustomValidations.rejected, (state, action) => {
            // ADMIN.toast.throwError(
            //     action.payload,
            //     "Could not fetch custom validations"
            // );
        });

    // reset
    builder.addCase(resetCustomValidations, () => initialState);
});

// -----------------------------------------------------------------------------------
// selectors

const customValidationSelectors = customValidationAdaptor.getSelectors<
    RootState
>(state => state.customValidations);

// -----------------------------------------------------------------------------------
// exports

export {
    CustomValidationReducer,
    initialState as CustomValidationReducerInit,
    customValidationSelectors,
};
