import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import * as yup from "yup";
import { useFormik } from "formik";
import { Backdrop, Chip, Divider, Input, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import styles from "../DataBaseModule/Costing/AddRevisionModal.module.scss";
import { LoadingButton } from "@mui/lab";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import CancelIcon from "@mui/icons-material/Cancel";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { API } from "../api-services";
import { useRouteParams } from "../ui-reusable-component/useRouteParams";
import { MODULES } from "../Constants/modules.constants";
import S3DocumentsUpload from "./S3DocumentUpload";
import Swal from "sweetalert2";
import warningIcon from "../Assets/images/Warning.png";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45vw",
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 0,
};
const RevisionTypeList = [
  { id: 1, text: "Main Costing", value: 1 },
  { id: 2, text: "Scenario Costing", value: 2 },
];
// description: "",
// expiry_date: "",
// name: "",
// outward: true,
// path: "",
// project: "",
// type: "",
// vehicle:[]
const validationSchema = yup.object({
  description: yup.mixed().required("Please type description"),
  expiry_date: yup.mixed().required("please select transfer date"),
  name: yup.mixed(),
  outward: yup.mixed(),
  path: yup.mixed(),
  project: yup.mixed(),
  type: yup.mixed(),
  vehicle: yup.mixed().required("Please select vehicle"),
});

const useStyles = makeStyles((theme) => ({
  select: {
    "&.MuiInputBase-root-MuiInput-root-MuiSelect-root": {
      borderBottom: "1px solid red",
    },
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface IWatermarkModelProps {
  isOpen: any;
  onCloseModal: any;
  actions?: any;
  getDocumentsData?: any;
}

function AddEditDocumentModal(props: IWatermarkModelProps) {
  const { isOpen, onCloseModal, actions, getDocumentsData } = props;
  const { projectId } = useRouteParams<any>();
  const [counter, setCounter] = React.useState<any>(0);
  const [isLoadingsave, setIsLoadingSave] = React.useState<any>(false);
  const [vehicles, setVehicles] = React.useState<any>([]);
  const [selectedVehicle, setSelectedVehicle] = React.useState<any>([]);
  const [fileSize, setFileSize] = React.useState<any>();
  const classes = useStyles();
  const currentDate = new Date();
  const [flag, setFlag] = React.useState<any>();
  const handleChangeFlag = (e: any) => {
    // var compreFileSize = 800;

    // 9437184

    setFileSize(e.currentTarget?.files[0]?.size);
    // if (datalength <= 4)

    {
      // var fileSizeData = Object.values(e.currentTarget.files).map((item: any, index: any) => {
      //   return item.size;
      // });

      // var getFileSize =
      //   e.currentTarget.files &&
      //   e.currentTarget.files?.map((item: any, index: any) => {
      //     {
      //       return item?.file_size;
      //     }
      //   });

      // var GetTotalFileSize = getFileSize?.reduce((accumulator: any, currentValue: any) => {
      //   return accumulator + currentValue;
      // }, 0);
      // const TotalFileSize = fileSizeData.reduce((accumulator: any, currentValue: any) => {
      //   return accumulator + currentValue;
      // }, 0);
      // var finalSize = GetTotalFileSize + TotalFileSize;

      setFlag(e.currentTarget.files);
    }
  };
  const handleChangeVehicle = (e: any) => {
    const { value } = e.target;
    setSelectedVehicle(value);
  };
  const getProducts = () => {
    API.get("/xcpep/top_vault/", { project: projectId, module: MODULES.BOM_CHECK }, 0)
      .then((res: any) => {
        setVehicles(res.data);
      })
      .catch((err: any) => { });
  };

  React.useEffect(() => {
    getProducts();
  }, [projectId]);
  React.useEffect(() => {
    if (actions !== "add") {
      formik.setFieldValue("description", actions.description);
      formik.setFieldValue("expiry_date", actions?.expiry_date);
      formik.setFieldValue("name", actions.name);
      formik.setFieldValue("outward", actions?.outward);
      formik.setFieldValue("path", actions.path);
      formik.setFieldValue("project", actions.project);
      formik.setFieldValue("type", actions.type);
      formik.setFieldValue("vehicle", actions.vehicle);
      setSelectedVehicle(actions?.vehicle);
    }
  }, [actions]);
  const formik = useFormik({
    initialValues: {
      description: "",
      expiry_date: "",
      name: "",
      outward: true,
      path: "",
      project: "",
      type: "",
      vehicle: [],
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setIsLoadingSave(true);
      if (actions === "add") {
        if (fileSize <= 7564320) {
          await S3DocumentsUpload(flag, null, setCounter, setIsLoadingSave, onCloseModal, "create", values, projectId, selectedVehicle, getDocumentsData);
        } else {
          setIsLoadingSave(false);
          onCloseModal();
          Swal.fire({
            html: `<div><img src="${warningIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
  
        <br />
  
        <p style="color:#007fff;">You can select upto 7.5Mb files.
  
        </p>
  
         </div>`,
          });
        }
      } else {
        if (flag) {
          if (fileSize <= 7564320) {
            await S3DocumentsUpload(flag, actions.id, setCounter, setIsLoadingSave, onCloseModal, "edit", values, projectId, selectedVehicle, getDocumentsData);
          } else {
            setIsLoadingSave(false);
            onCloseModal();
            Swal.fire({
              html: `<div><img src="${warningIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
    
          <br />
    
          <p style="color:#007fff;">You can select upto 7.5Mb files.
    
          </p>
    
           </div>`,
            });
          }
        } else {
          API.patch(
            `/drive/file/${actions?.id}/`,
            {
              description: values.description,
              expiry_date: values.expiry_date,
              vehicle: selectedVehicle,
            },
            {}
          )
            .then((res: any) => {
              setIsLoadingSave(false);
              onCloseModal();
              getDocumentsData();
            })
            .catch((err: any) => {
              setIsLoadingSave(false);
            });
        }
      }
    },
  });

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}>
        <Box sx={style}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}>
              {actions === "add" ? "Add" : "Edit"} Documents
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ padding: "0 2rem 1rem", textAlign: "center" }}>
            <form onSubmit={formik.handleSubmit}>
              <Box sx={{ display: "flex", alignItems: "center", columnGap: "1rem", marginBottom: "0.5rem" }}>
                <FormControl
                  fullWidth
                  variant="standard">
                  <TextField
                    variant="standard"
                    label="File Description"
                    // autoFocus={autoFocusStatus}
                    InputProps={{
                      style: { color: "#007fff" },
                    }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    sx={{
                      "& .Mui-error": {
                        color: "#d32f2f",
                        borderBottomColor: "#d32f2f",
                      },
                      "& .MuiInput-underline:before": { borderBottomColor: "primary.light" },
                      "& .MuiInput-underline:after": { borderBottomColor: "primary.main" },
                      "& .MuiFormLabel-root.Mui-focused": {
                        color: "primary.main",
                      },
                      "& .MuiFormLabel-root.MuiInputLabel-root ": {
                        color: "primary.main",
                      },
                      ".MuiInputBase-root.MuiInput-root": {
                        fontSize: "1rem !important",
                      },
                    }}
                    id="description"
                    name="description"
                    onChange={formik.handleChange}
                    value={formik.values.description}
                    multiline
                    error={formik.touched.description && Boolean(formik.errors.description)}
                    helperText={formik.touched.description && formik.errors.description}
                  />
                </FormControl>
                <FormControl
                  fullWidth
                  variant="standard">
                  <LocalizationProvider
                    variant="standard"
                    disabled={actions.mapped_status}
                    dateAdapter={AdapterMoment}
                    sx={{
                      ".MuiSvgIcon-root ": {
                        fill: "primary.main !important",
                      },
                    }}>
                    <DatePicker
                      label="Transfer Date"
                      // id="revised_on"
                      // @ts-ignore
                      value={formik.values.expiry_date}
                      // minDate={currentDate}                     
                      onChange={(newValue) => {
                        //   setDate(moment(newValue).format("YYYY-MM-DD HH:mm:ss"));
                        formik.setFieldValue("expiry_date", moment(newValue).format("YYYY-MM-DD"));
                      }}
                      renderInput={(params) => (
                        <TextField
                          name="expiry_date"
                          {...params}
                          //@ts-ignore
                          onError={formik.errors.expiry_date && formik.touched.expiry_date}
                          sx={{
                            marginBottom: "0.5rem",
                            width: "100%",
                          }}
                          variant="standard"
                        />
                      )}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Box>
              <Box sx={{ display: "flex", alignItems: "center", columnGap: "1rem", marginBottom: "0.5rem" }}></Box>
              <Box sx={{ display: "flex", alignItems: "center", columnGap: "1rem", marginBottom: "0.5rem" }}>
                <FormControl
                  fullWidth
                  variant="standard">
                  <InputLabel
                    shrink
                    id="demo-simple-select-label-Activity"
                    sx={{
                      color: "primary.main",
                      fontSize: "0.87rem",
                      // marginLeft: "-1rem",
                      position: "relative",
                      textAlign: "left",
                    }}>
                    Select Vehicle
                  </InputLabel>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={selectedVehicle}
                    multiple
                    multiline
                    // label="Role"
                    // placeholder="Select Role"
                    onChange={handleChangeVehicle}
                    size="small"
                    sx={{
                      "&:before": {
                        borderBottomColor: "primary.main",
                      },
                      ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
                    }}
                    renderValue={(selected) => (
                      <Box
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          gap: 0.5,
                        }}>
                        {vehicles
                          .filter((item: any) =>
                            //@ts-ignore
                            selectedVehicle?.includes(item?.id)
                          )
                          ?.map((value: any) => (
                            <Chip
                              sx={{ backgroundColor: 'primary.light' }}
                              size="small"
                              key={value.id}
                              label={value.name}
                              onDelete={() => {
                                if (selectedVehicle.length > 1) {
                                  setSelectedVehicle((prev: any) => prev.filter((item: any) => value.id !== item));
                                  // API.put(
                                  //   "/auth/assign_permission/",
                                  //   {
                                  //     ids: user,
                                  //     action: roles?.filter((item: any) => value.value !== item),
                                  //     submodule: selectedModules
                                  //   },
                                  //   {},
                                  //   0
                                  // )
                                  //   .then((res: any) => { })
                                  //   .catch((err: any) => { });
                                }
                              }}
                              deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
                            />
                          ))}
                      </Box>
                    )}
                    MenuProps={MenuProps}>
                    {vehicles &&
                      vehicles?.map((item: any, index: number) => {
                        return (
                          //@ts-ignore
                          !selectedVehicle?.includes(item?.id) && (
                            <MenuItem sx={{ fontSize: '1rem' }}
                              key={`${index}`}
                              value={item?.id}>
                              {item.name}
                            </MenuItem>
                          )
                        );
                      })}
                  </Select>
                </FormControl>
                <FormControl fullWidth>
                  <InputLabel
                    id="demo-simple-select-label-Activity"
                    sx={{
                      color: "primary.main",
                      fontSize: "0.87rem",
                      marginLeft: "-1rem",
                      position: "relative",
                      textAlign: "left",
                    }}>
                    File
                  </InputLabel>
                  <Input
                    type="file"
                    onChange={handleChangeFlag}
                  />
                </FormControl>
              </Box>
              <Box sx={{ width: "100%", textAlign: "right" }}>
                <LoadingButton
                  size="small"
                  loading={isLoadingsave}
                  variant="contained"
                  type="submit"
                  sx={{
                    marginTop: "1rem",
                    "&:hover": {
                      transform: "Scale(1.05)",
                      transition: "transform 0.5s ease",
                    },
                    ".MuiCircularProgress-root": {
                      color: "primary.main",
                    },
                  }}>
                  Save
                </LoadingButton>
              </Box>
            </form>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
export default AddEditDocumentModal;
