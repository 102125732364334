import React, { useEffect, useState } from "react";
import { AxiosError, AxiosResponse } from "axios";
import {
  Box,
  Button,
  Divider,
  FormControl,
  Select,
  SelectChangeEvent,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import DownloadDoneIcon from "@mui/icons-material/DownloadDone";
import CancelIcon from "@mui/icons-material/Cancel";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import LeaderboardIcon from "@mui/icons-material/Leaderboard";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Cell,
  Label,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from "recharts";
import styles from "../CostingCreateModule/CostingCreateSystemTable.module.scss";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { LoadingButton } from "@mui/lab";
import { useHistory } from "react-router-dom";
import { styled, alpha } from "@mui/material/styles";
import Menu, { MenuProps } from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { DialogComponent } from "../../utlis/DialogComponent";
import { useBaseModal } from "../SearchPage/useBaseModal";
import { API } from "../../api-services";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import BOMCreateSystemTableRow from "./BOMCreateSystemTableRow";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const colors = [
  "#0088FE",
  "#00C49F",
  "#FFBB28",
  "#FF8042",
  "red",
  "pink",
  "#2d969b",
  "#fa913c",
  "#ff4164",
  "#64505a",
  "#9b5a64",
  "#376ea5",
  "#c86469",
  "#5f7896",
  "#e1b496",
  "#a0a5a0",
  "#e1e6e6",
  "#6e6437",
  "#2d2d1e",
];

const StyledMenu = styled((props: MenuProps) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

interface Params {
  projectId: any;
  projectName: any;
  expandTable: any;
  initialTopVault: any;
  topVaultIds: any;
}

interface IBOMCreateSystemTableProps {}

const BOMCreateSystemTable: React.FC<IBOMCreateSystemTableProps> = (props) => {
  const { projectId, projectName, initialTopVault, topVaultIds, expandTable } = useRouteParams<Params>();
  const [totalBOMProduct, setTotalBOMProduct] = useState<any>([]);
  const [totalBOMCalculationData, setTotalBOMCalculationData] = useState<any>([]);
  const[downloadClickedId,setDownloadClickedId] = useState<any>(undefined);
  const [openTooltip, setOpenTooltip] = React.useState(false);
  const [openFlagModel, setOpenFlagModel] = React.useState(false);
  const [openStatisticsModel, setOpenStatisticsModel] = React.useState(false);
  const [partInfo, setPartInfo] = React.useState<any>({});
  const [statisticsData, setStatisticsData] = React.useState<any>([]);
  const [subsystemName, setSubsystemName] = React.useState<any>("");
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);
  const [paginationProduct, setPaginationProduct] = useState<any>();
  const [totalBOMWithoutCalculationDataLoader,setTotalBOMWithoutCalculationDataLoader,] = useState<boolean>(true);
  const [totalBOMProductLevelLoader, setTotalBOMProductLevelLoader] =useState<boolean>(true);
  const [contentText, setContentText] = React.useState<any>("");
  const [iconText, setIconText] = React.useState<any>("");
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [statisticsDataLoader, setStatisticsDataLoader] = React.useState(false);
  const [weightType, setWeightType] = React.useState<any>();
  const [flagData,setFlagData] = React.useState<any>([]);
  const [selectedTopVault,setSelectedTopVault]=React.useState<any>(undefined)

  const openDownload = Boolean(anchorEl);

  const openStatusContent = useBaseModal();
  const history = useHistory();

  useEffect(() => {
    if (sessionStorage.getItem("weightTypeBOM")) {
      setWeightType(sessionStorage.getItem("weightTypeBOM"));
    } else {
      setWeightType("Gram");
      sessionStorage.setItem("weightTypeBOM", "Gram");
    }
  }, [sessionStorage.getItem("weightTypeBOM")]);

  useEffect(() => {
    setTotalBOMProduct(undefined);
    setTotalBOMCalculationData(undefined);
    setTotalBOMWithoutCalculationDataLoader(true);
    setTotalBOMProductLevelLoader(true);

    API.get(
      `/xcpep/bom_total_count/`,
      { top_vault: topVaultIds, project: projectId },
      0
    )
      .then((res: AxiosResponse) => {
        setTotalBOMProduct(res.data);
        setTotalBOMProductLevelLoader(false);
      })
      .catch((err: AxiosError) => {
        console.log(err, "error");
      });

    API.get(`/xcpep/bom_create_table/`, { top_vault: topVaultIds }, 0)
      .then((res: AxiosResponse) => {
        setTotalBOMCalculationData(res.data);
        setTotalBOMWithoutCalculationDataLoader(false);
      })
      .catch((err: AxiosError) => {
        console.log(err, "error");
      });

    API.get(
      `/cost/costing_create_ui/`,
      {
        get_bom_vehicle: projectId,
        start_top_vault: initialTopVault,
        series: topVaultIds,
      },
      0
    )
      .then((res: AxiosResponse) => {
        setPaginationProduct(res.data);
      })
      .catch((err: AxiosError) => {
        console.log(err, "error");
      });
  }, [projectId, topVaultIds]);

  const handleChangeWeightType = (event: SelectChangeEvent) => {
    setWeightType(event.target.value as string);
    sessionStorage.setItem("weightTypeBOM", event.target.value);
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>,tvId:any) => {
    setAnchorEl(event.currentTarget);
    setSelectedTopVault(tvId)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleTooltipClose = () => {
    setOpenTooltip(false);
    setPartInfo({});
  };

  const handleFlagOpen = () => {
    setOpenFlagModel(true);
  };

  const handleFlagClose = () => {
    setOpenFlagModel(false);
    setFlagData([]);
  }

  const handleTooltipOpen = (partIndoData: any) => {
    setOpenTooltip(true);
    setPartInfo(partIndoData);
  };

  const handleStatisticsClose = () => {
    setOpenStatisticsModel(false);
    setSubsystemName("");
    setStatisticsData([]);
  };

  const handleStaticticsOpen = (type: any) => {
    setStatisticsDataLoader(true);
    API.get(
      `/cost/costing_create_ui/`,
      { start_top_vault: initialTopVault, get_bom_graph_data: projectId },
      0
    )
      .then((res: AxiosResponse) => {
        setStatisticsData(res.data);
        setSubsystemName(type);
        setOpenStatisticsModel(true);
        setStatisticsDataLoader(false);
      })
      .catch((err: AxiosError) => {
        console.log(err, "error");
      });
  };

  const getPath = (x: any, y: any, width: any, height: any) => {
    return `M${x},${y + height}C${x + width / 3},${y + height} ${
      x + width / 2
    },${y + height / 3}
        ${x + width / 2}, ${y}
        C${x + width / 2},${y + height / 3} ${x + (2 * width) / 3},${
      y + height
    } ${x + width}, ${y + height}
        Z`;
  };

  const TriangleBar = (props: any) => {
    const { fill, x, y, width, height } = props;

    return <path d={getPath(x, y, width, height)} stroke="none" fill={fill} />;
  };

  const redirectPaginationPush = (type: any) => {
    if (type == "prev") {
      history.push(
        `/BOMCreate/${projectId}/${projectName}/${initialTopVault}/${
          paginationProduct &&
          paginationProduct?.[0]?.previous_index?.toString()
        }/${expandTable}`
      );
    } else {
      history.push(
        `/BOMCreate/${projectId}/${projectName}/${initialTopVault}/${
          paginationProduct && paginationProduct?.[0]?.next_index?.toString()
        }/${expandTable}`
      );
    }
  };

  const handleRedirect = (module: any, id: any) => {
    if (module === "w") {
      API.get(
        "/customer/check_consolidate_downoad/",
        {
          top_vault: selectedTopVault,
          with_image: true,
          consolidate_type: "BOM",
        },
        0
      ).then((res: any) => {
        if (res.data.status === "Failed") {
          setIconText("failed");
          setContentText(res.data.message);
          openStatusContent.open();
        } else if (res.data.status === "First Download") {
          setIconText("firstdownload");
          setContentText(res.data.message);
          handleClose();
          openStatusContent.open();
          API.get(
            "customer/consolidated_report/",
            {
              top_vault: selectedTopVault,
              with_image: true,
              consolidate_type: "BOM",
            },
            0
          ).then((res: any) => {
            handleClose();
            window.open(res?.data, "_self");
          });
        } else if (res.data.status === "In Progress") {
          setIconText("reportProgress");
          setContentText(res.data.message);
          handleClose();
          openStatusContent.open();
        } else if (res.data.status === "Completed") {
          handleClose();
          window.open(res?.data?.url, "_self");
        }
      });
    } else if (module === "w/o") {
      API.get(
        "/customer/check_consolidate_downoad/",
        {
          top_vault: selectedTopVault,
          with_image: false,
          consolidate_type: "BOM",
        },
        0
      ).then((res: any) => {
        if (res.data.status === "Failed") {
          setIconText("failed");
          setContentText(res.data.message);
          handleClose();
          openStatusContent.open();
        } else if (res.data.status === "First Download") {
          setIconText("firstdownload");
          setContentText(res.data.message);
          handleClose();
          openStatusContent.open();
          API.get(
            "customer/consolidated_report/",
            {
              top_vault: selectedTopVault,
              with_image: false,
              consolidate_type: "BOM",
            },
            0
          ).then((res: any) => {
            handleClose();
            window.open(res?.data, "_self");
          });
        } else if (res.data.status === "In Progress") {
          setIconText("reportProgress");
          setContentText(res.data.message);
          handleClose();
          openStatusContent.open();
        } else if (res.data.status === "Completed") {
          handleClose();
          window.open(res?.data?.url, "_self");
        }
      });
    }
  };

  const redirectToBOMInnerPage = (dataIncoming: any) => {
  
    sessionStorage.setItem(
      "BOMCreateNode",
      JSON.stringify([dataIncoming?.top_vault_id?.toString()])
    );
    console.log(dataIncoming,"dataIncomingdataIncoming")
    window.open(
      `/#/bomentry/${projectId}/${projectName}/${dataIncoming?.top_vault_id}/0/product/0/false/false`
    );
  };

  const handleRedirectToFlag = (idIncoming:any,abbreviationIncoming:any,top_vaultIncoming:any,ancestorsIncoming:any) => {
    sessionStorage.setItem(
      "BOMCreateNode",
      JSON.stringify(ancestorsIncoming)
    );
    window.open(
      `/#/bomentry/${projectId}/${projectName}/${top_vaultIncoming}/${idIncoming}/${abbreviationIncoming == null ? 'none' : abbreviationIncoming}/0/false/false`
    )
  }

  return (
    <>
      {openStatusContent.isOpen && (
        <DialogComponent
          isOpen={openStatusContent.isOpen}
          onClose={openStatusContent.close}
          content={contentText}
          type={iconText}
        />
      )}

      <Dialog
        open={openFlagModel}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        sx={{
          ".MuiPaper-root.MuiDialog-paper": {
            // maxWidth: '80vw',
            borderRadius: "10px",
          },
        }}
      >
        <DialogContent sx={{ padding: "0rem", width: "30rem" }}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Flagged Part List
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={handleFlagClose}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              // marginBottom: "1rem",
            }}
          />
          <Box
            sx={{
              margin: "1rem",
              width: "100%",
            }}
          >
            <Table
                stickyHeader
                aria-label="sticky table"
                // sx={{
                //   borderRight: "1px solid rgba(224, 224, 224, 1)",
                //   borderRightColor: "#ddefff",
                // }}
                >
              <TableHead 
                  sx={{
                    backgroundColor: "primary.light",
                    position: "sticky",
                    top: 0}}>
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",

                    }}>
                      Part No.
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",

                    }}>
                      Part Name
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {flagData && flagData?.map((item:any)=>{
                  return(
                <TableRow>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                      textDecoration:'underline',
                      cursor:'pointer'
                    }}
                    onClick={()=>handleRedirectToFlag(item?.id,item?.abbreviation,item?.top_vault,item?.ancestors)}
                  >
                    {item?.part_no}
                  </TableCell>
                  <TableCell
                    sx={{
                      padding: "0.2rem 0.5rem",
                      borderBottomColor: "primary.light",
                    }}
                  >
                   {item?.name}
                  </TableCell>
                </TableRow>
                )})}
              </TableBody>
            </Table>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openTooltip}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        sx={{
          ".MuiPaper-root.MuiDialog-paper": {
            // maxWidth: '80vw',
            borderRadius: "10px",
          },
        }}
      >
        <DialogContent sx={{ padding: "0rem", width: "24rem" }}>
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Parts Information
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={handleTooltipClose}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box
            sx={{
              margin: "1rem",
              Width: "100%",
            }}
          >
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                  width: "100%",
                }}
              >
                Total Parts
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                  width: "100%",
                }}
              >
                {partInfo && partInfo?.total_parts}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                  width: "100%",
                }}
              >
                Validated
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                  width: "100%",
                }}
              >
                {partInfo && partInfo?.validated}
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                  width: "100%",
                }}
              >
                Pending
              </TableCell>
              <TableCell
                sx={{
                  padding: "0.2rem 0.5rem",
                  borderBottomColor: "primary.light",
                  width: "100%",
                }}
              >
                {partInfo && partInfo?.completed + partInfo?.in_progress}
              </TableCell>
            </TableRow>
          </Box>
        </DialogContent>
      </Dialog>

      <Dialog
        open={openStatisticsModel}
        TransitionComponent={Transition}
        keepMounted
        aria-describedby="alert-dialog-slide-description"
        sx={{
          ".MuiPaper-root.MuiDialog-paper": {
            maxWidth: "95vw",
            borderRadius: "10px",
          },
        }}
      >
        <DialogContent sx={{ padding: "0rem", width: "95vw" }}>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "2.5rem",
              position: "sticky",
              top: 0,
              zIndex: 2,
              backgroundColor: "white",
            }}
          >
            <Typography
              sx={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Statistics ( {subsystemName} )
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={handleStatisticsClose}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
            }}
          />

          <Box
            sx={{
              padding: "1rem",
              height: "80vh",
            }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3"
          >
            {subsystemName != "Product" && (
              <Box>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      margin: "1rem",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    Weight
                  </Typography>
                  <Box
                    sx={{
                      margin: "0rem",
                      width: "90vw",
                      height: "55vh",
                      padding: "1rem 1rem",
                    }}
                  >
                    <ResponsiveContainer>
                      <BarChart
                        width={500}
                        height={300}
                        data={statisticsData}
                        margin={{
                          top: 20,
                          right: 10,
                          left: 25,
                          bottom: 55,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="product_name"
                          interval={0}
                          angle={0}
                          textAnchor={"middle"}
                        />
                        <YAxis>
                          <Label
                            style={{
                              textAnchor: "middle",
                              fontSize: "100%",
                              fontWeight: "600",
                            }}
                            angle={270}
                            dx={subsystemName == "Product" ? -46 : -28}
                            value={`Weight ( Kg )`}
                          />
                        </YAxis>
                        <Bar
                          dataKey="weight"
                          fill="#8884d8"
                          shape={<TriangleBar />}
                          label={{ position: "top" }}
                        >
                          {statisticsData?.map((entry: any, index: any) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={colors[index % 20]}
                            />
                          ))}
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>
                  </Box>
                </div>
              </Box>
            )}
            <Box
              sx={{
                display: "grid",
                gridTemplateColumns: subsystemName == "Product" ? "1fr" :"1fr 1fr",
              }}
            >
              {subsystemName == "Product" && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      margin: "1rem",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    Weight
                  </Typography>
                  <Box
                    sx={{
                      margin: "0rem",
                      width: '90vw',
                      height: "55vh",
                      padding: "1rem 1rem",
                    }}
                  >
                    <ResponsiveContainer>
                      <BarChart
                        width={500}
                        height={300}
                        data={statisticsData}
                        margin={{
                          top: 20,
                          right: 10,
                          left: 25,
                          bottom: 55,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="product_name"
                          interval={0}
                          angle={0}
                          textAnchor={"middle"}
                        />
                        <YAxis>
                          <Label
                            style={{
                              textAnchor: "middle",
                              fontSize: "100%",
                              fontWeight: "600",
                            }}
                            angle={270}
                            dx={subsystemName == "Product" ? -46 : -28}
                            value={`Weight ( Kg )`}
                          />
                        </YAxis>
                        <Bar
                          dataKey="weight"
                          fill="#8884d8"
                          shape={<TriangleBar />}
                          label={{ position: "top" }}
                        >
                          {statisticsData?.map((entry: any, index: any) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={colors[index % 20]}
                            />
                          ))}
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>
                  </Box>
                </div>
              )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    margin: "1rem",
                    textAlign: "center",
                    fontWeight: "bold",
                  }}
                >
                  Count
                </Typography>
                <Box
                  sx={
                    subsystemName != "Product"
                      ? {
                          margin: "0rem",
                          width: '45vw',
                          height: "55vh",
                          padding: "1rem 1rem",
                        }
                      : {
                          margin: "0rem",
                          width: "90vw",
                          height: "55vh",
                          padding: "1rem 1rem",
                        }
                  }
                >
                  <ResponsiveContainer>
                    <BarChart
                      width={500}
                      height={300}
                      data={statisticsData}
                      margin={{
                        top: 20,
                        right: 10,
                        left: 25,
                        bottom: 55,
                      }}
                    >
                      <CartesianGrid strokeDasharray="3 3" />
                      <XAxis
                        dataKey="product_name"
                        interval={0}
                        angle={subsystemName != "Product" ? statisticsData.length > 4 ? -25 : 0 : 0}
                        textAnchor={
                          statisticsData.length > 4 ? "end" : "middle"
                        }
                      />
                      <YAxis>
                        <Label
                          style={{
                            textAnchor: "middle",
                            fontSize: "100%",
                            fontWeight: "600",
                          }}
                          angle={270}
                          dx={subsystemName == "Product" ? -46 : -28}
                          value={`Count (Qty)`}
                        />
                      </YAxis>
                      <Bar
                        dataKey="count"
                        fill="#8884d8"
                        shape={<TriangleBar />}
                        label={{ position: "top" }}
                      >
                        {statisticsData?.map((entry: any, index: any) => (
                          <Cell
                            key={`cell-${index}`}
                            fill={colors[index % 20]}
                          />
                        ))}
                      </Bar>
                    </BarChart>
                  </ResponsiveContainer>
                </Box>
              </div>
              {subsystemName != "Product" && (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    sx={{
                      margin: "1rem",
                      textAlign: "center",
                      fontWeight: "bold",
                    }}
                  >
                    Share
                  </Typography>
                  <Box
                    sx={{
                      margin: "0rem",
                      width: '45vw',
                      height: "55vh",
                      padding: "1rem 1rem",
                    }}
                  >
                    <ResponsiveContainer>
                      <BarChart
                        width={500}
                        height={300}
                        data={statisticsData}
                        margin={{
                          top: 20,
                          right: 10,
                          left: 25,
                          bottom: 55,
                        }}
                      >
                        <CartesianGrid strokeDasharray="3 3" />
                        <XAxis
                          dataKey="product_name"
                          interval={0}
                          angle={statisticsData.length > 4 ? -25 : 0}
                          textAnchor={
                            statisticsData.length > 4 ? "end" : "middle"
                          }
                        />
                        <YAxis>
                          <Label
                            style={{
                              textAnchor: "middle",
                              fontSize: "100%",
                              fontWeight: "600",
                            }}
                            angle={270}
                            dx={subsystemName == "Product" ? -46 : -28}
                            value={"Share ( % )"}
                          />
                        </YAxis>
                        <Bar
                          dataKey="share_percent"
                          fill="#8884d8"
                          shape={<TriangleBar />}
                          label={{ position: "top" }}
                        >
                          {statisticsData?.map((entry: any, index: any) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={colors[index % 20]}
                            />
                          ))}
                        </Bar>
                      </BarChart>
                    </ResponsiveContainer>
                  </Box>
                </div>
              )}
            </Box>
          </Box>
        </DialogContent>
      </Dialog>
      {totalBOMProductLevelLoader
        ? (String(topVaultIds)?.includes(",")
            ? topVaultIds?.split(",")
            : [topVaultIds]
          )?.map((item: any) => (
            <span style={{ width: "90%", margin: "0 auto" }}>
              <Skeleton animation="wave" height={40} />
              <Skeleton animation="wave" height={40} />
              <Skeleton animation="wave" height={40} />
              <Skeleton animation="wave" height={40} />
              <Skeleton animation="wave" height={40} />
              <Skeleton animation="wave" height={40} />
              <Skeleton animation="wave" height={40} />
              <Skeleton animation="wave" height={40} />
              <Skeleton animation="wave" height={40} />
              <Skeleton animation="wave" height={40} />
              <Skeleton animation="wave" height={40} />
              <Skeleton animation="wave" height={40} />
              <Skeleton animation="wave" height={40} />
              <Skeleton animation="wave" height={40} />
              <Skeleton animation="wave" height={40} />
              <Skeleton animation="wave" height={40} />
              <Skeleton animation="wave" height={40} />
              <Skeleton animation="wave" height={40} />
              <Skeleton animation="wave" height={40} />
              <Skeleton animation="wave" height={40} />
              <Skeleton animation="wave" height={40} />
            </span>
          ))
        : totalBOMProduct?.map((item: any, index: any) => {
            return (
              <Table
                stickyHeader
                aria-label="sticky table"
                sx={{
                  borderRight: "1px solid rgba(224, 224, 224, 1)",
                  borderRightColor: "#ddefff",
                }}
              >
                <TableHead
                  sx={{
                    backgroundColor: "primary.light",
                    position: "sticky",
                    top: 0,
                    zIndex: 3,
                  }}
                  key={index + 1}
                >
                  <TableRow>
                    {index == 0 && (
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                          backgroundColor: "primary.light",
                          position: "sticky",
                          left: "0",
                          zIndex: 3,
                        }}
                        rowSpan={2}
                      >
                        <FormControl fullWidth variant="standard" >
                          <Select
                            size="small"
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={weightType}
                            onChange={handleChangeWeightType}
                            sx={{
                              "&:before": {
                                borderBottomColor: "primary.main",
                                borderRadius: "0.2rem",
                              },
                              "&:after": {                               
                                borderRadius: "0.2rem",
                              },
                              "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                                {
                                  fontSize: "1rem",
                                  padding: "0.3rem 0.3rem 0.1rem",
                                  borderRadius: "0.2rem",
                                  color: "white",
                                  backgroundColor: "primary.main",
                                },
                              ".MuiSvgIcon-root.MuiSelect-icon": {
                                color: "white",
                                backgroundColor: "primary.main",
                              },
                            }}
                          >
                            <MenuItem value={"Gram"}>Gram</MenuItem>
                            <MenuItem value={"Kg"}>Kg</MenuItem>
                          </Select>
                        </FormControl>
                      </TableCell>
                    )}
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                        backgroundColor: "primary.light",
                      }}
                      colSpan={5}
                    >
                      <span
                        style={
                          String(topVaultIds)?.includes(",") &&
                          topVaultIds?.split(",")?.length <= 4
                            ? index == 0
                              ? {
                                  display: "flex",
                                  columnGap: "0.5rem",
                                  justifyContent: "space-between",
                                }
                              : index == totalBOMProduct?.length - 1
                              ? {
                                  display: "flex",
                                  columnGap: "0.5rem",
                                  justifyContent: "space-between",
                                }
                              : {
                                  display: "flex",
                                  columnGap: "0.5rem",
                                  justifyContent: "center",
                                }
                            : {
                                display: "flex",
                                columnGap: "0.5rem",
                                justifyContent: "center",
                              }
                        }
                      >
                        {index == 0 &&
                          String(topVaultIds)?.includes(",") &&
                          topVaultIds?.split(",")?.length <= 4 && (
                            <LoadingButton
                              sx={{ p: 0 }}
                              disabled={
                                !(
                                  paginationProduct &&
                                  paginationProduct?.[0]?.previous
                                )
                              }
                              onClick={() => redirectPaginationPush("prev")}
                              title="Previous Vehicle"
                            >
                              <ChevronLeftIcon />
                            </LoadingButton>
                          )}
                        {index == totalBOMProduct?.length - 1 && <span></span>}
                        <span
                          style={
                            String(topVaultIds)?.includes(",") &&
                            topVaultIds?.split(",")?.length <= 4
                              ? index == 0
                                ? { marginRight: "4rem",whiteSpace:'nowrap' }
                                : index == totalBOMProduct?.length - 1
                                ? { marginLeft: "0rem",whiteSpace:'nowrap' }
                                : {}
                              : {}
                          }
                          title={item?.name}
                        >{`${item?.name}`}</span>
                        {index == 0 && <span></span>}
                        {index == totalBOMProduct?.length - 1 &&
                          String(topVaultIds)?.includes(",") &&
                          topVaultIds?.split(",")?.length <= 4 && (
                            <LoadingButton
                              sx={{ p: 0, minWidth: "3rem" }}
                              disabled={
                                !(
                                  paginationProduct &&
                                  paginationProduct?.[0]?.next
                                )
                              }
                              onClick={() => redirectPaginationPush("next")}
                              title="Next Vehicle"
                            >
                              <ChevronRightIcon />
                            </LoadingButton>
                          )}
                      </span>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                        whiteSpace: "nowrap",
                        backgroundColor: "primary.light",
                        textAlign: "center",
                      }}
                    >
                      Count
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                        whiteSpace: "nowrap",
                        backgroundColor: "primary.light",
                        textAlign: "right",
                      }}
                    >
                      Weight ({`${weightType}`})
                    </TableCell>
                    {String(topVaultIds)?.includes(",") &&
                      topVaultIds?.split(",")?.length <= 4 && (
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                            whiteSpace: "nowrap",
                            backgroundColor: "primary.light",
                            textAlign: "right",
                          }}
                        >
                          Share
                        </TableCell>
                      )}
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                        whiteSpace: "nowrap",
                        backgroundColor: "primary.light",
                        textAlign: "center",
                        width: "5rem",
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow
                    style={{
                      height: "3rem",
                      position: "sticky",
                      top: "4.3rem",
                      zIndex: 3,
                      backgroundColor: "white",
                    }}
                  >
                    {index == 0 && (
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottomColor: "primary.light",
                          fontWeight: "bold",
                          position: "sticky",
                          left: "0",
                          zIndex: 3,
                          backgroundColor: "white",
                        }}
                      >
                        <span
                          style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "space-between",
                            columnGap: "0.5rem",
                          }}
                        >
                          Product{" "}
                          <LoadingButton
                            loading={statisticsDataLoader}
                            sx={{
                              p: 0,
                              minWidth: "1rem",
                              ".MuiCircularProgress-root": {
                                color: "primary.main",
                              },
                            }}
                          >
                            <LeaderboardIcon
                              titleAccess="Statistics"
                              sx={{ cursor: "pointer" }}
                              onClick={() => handleStaticticsOpen("Product")}
                            />
                          </LoadingButton>
                        </span>
                      </TableCell>
                    )}
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                        textAlign: "center",
                        cursor: "pointer",
                      }}
                      onClick={() => redirectToBOMInnerPage(item)}
                    >
                      {totalBOMProductLevelLoader ? (
                        <Skeleton />
                      ) : (
                        item?.total_count
                      )}
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                        textAlign: "right",
                        cursor: "pointer",
                      }}
                      onClick={() => redirectToBOMInnerPage(item)}
                    >
                      {totalBOMProductLevelLoader ? (
                        <Skeleton />
                      ) : weightType == "Kg" ? (
                        (item?.total_weight / 1000)?.toFixed(2)
                      ) : (
                        item?.total_weight?.toFixed(2)
                      )}
                    </TableCell>
                    {String(topVaultIds)?.includes(",") &&
                      topVaultIds?.split(",")?.length <= 4 && (
                        <TableCell
                          sx={{
                            padding: "0.2rem 0.5rem",
                            borderBottomColor: "primary.light",
                            textAlign: "right",
                            cursor: "pointer",
                          }}
                          onClick={() => redirectToBOMInnerPage(item)}
                        >
                          {totalBOMProductLevelLoader ? <Skeleton /> : "100%"}
                        </TableCell>
                      )}
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottomColor: "primary.light",
                        textAlign: "center",
                      }}
                    >
                      <span
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          columnGap: "0.5rem",
                        }}
                      >
                        <Button
                          id="demo-customized-button"
                          aria-controls={
                            openDownload ? "demo-customized-menu" : undefined
                          }
                          aria-haspopup="true"
                          aria-expanded={openDownload ? "true" : undefined}
                          disableElevation
                          onClick={(event:any)=>handleClick(event,item?.top_vault_id)}
                          style={{ padding: "0rem", minWidth: "1rem" }}
                          title="Download"
                        >
                          <CloudDownloadIcon sx={{ cursor: "pointer" }} />
                        </Button>
                        <StyledMenu
                          id="demo-customized-menu"
                          MenuListProps={{
                            "aria-labelledby": "demo-customized-button",
                          }}
                          anchorEl={anchorEl}
                          open={openDownload}
                          onClose={handleClose}
                        >
                          <MenuItem
                            sx={{
                              fontSize: "1rem",
                              color: "primary.main",
                              padding: "0.2rem 0.5rem",
                            }}
                            onClick={() =>
                              handleRedirect("w", item?.top_vault_id)
                            }
                          >
                            Consolidated with images
                          </MenuItem>
                          <MenuItem
                            sx={{
                              fontSize: "1rem",
                              color: "primary.main",
                              padding: "0.2rem 0.5rem",
                            }}
                            onClick={() =>
                              handleRedirect("w/o", item?.top_vault_id)
                            }
                          >
                            Consolidated w/o images
                          </MenuItem>
                        </StyledMenu>
                      </span>
                    </TableCell>
                  </TableRow>
                 {totalBOMWithoutCalculationDataLoader ? <>
                <TableRow>
                  <TableCell colSpan={5}>
                <Skeleton animation="wave" height={40} />
                <Skeleton animation="wave" height={40} />
                <Skeleton animation="wave" height={40} />
                <Skeleton animation="wave" height={40} />
                <Skeleton animation="wave" height={40} />
                <Skeleton animation="wave" height={40} />
                <Skeleton animation="wave" height={40} />
                <Skeleton animation="wave" height={40} />
                <Skeleton animation="wave" height={40} />
                <Skeleton animation="wave" height={40} />
                <Skeleton animation="wave" height={40} />
                <Skeleton animation="wave" height={40} />
                <Skeleton animation="wave" height={40} />
                <Skeleton animation="wave" height={40} />
                <Skeleton animation="wave" height={40} />
                <Skeleton animation="wave" height={40} />
                <Skeleton animation="wave" height={40} />
                <Skeleton animation="wave" height={40} />
                </TableCell>
                  </TableRow>
        </> 
        : 
        <>
            {totalBOMCalculationData &&
                totalBOMCalculationData?.map(
                  (internalData: any, indexInternal: number) => {
                    return (
                      <BOMCreateSystemTableRow
                        key={indexInternal + 1}
                        item={item}
                        index={index}
                        setHoveredIndex={setHoveredIndex}
                        internalData={internalData}
                        indexInternal={indexInternal}
                        hoveredIndex={hoveredIndex}
                        handleTooltipOpen={handleTooltipOpen}
                        openStatusContent={openStatusContent}
                        setContentText={setContentText}
                        setIconText={setIconText}
                        setStatisticsData={setStatisticsData}
                        setSubsystemName={setSubsystemName}
                        setOpenStatisticsModel={setOpenStatisticsModel}
                        weightType={weightType}
                        setFlagData={setFlagData}
                        handleFlagOpen={handleFlagOpen}
                      />
                    );
                  }
                )}
                </>}
                </TableBody>
              </Table>
            );
          })
          }
    </>
  );
};

export default BOMCreateSystemTable;
