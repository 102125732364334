import { IAssumptionCard } from "../../ComponentUI/CostingCreateModule/useAssumptioCard";
import { ID } from "../../utlis/commonInterfaces";
import { CopyTopVaultArg, CreateTopVaultArg, DeleteTopVaultArg, DeleteTopVaultCardArg, EditTopVaultArg, LockedVaultArg } from "../Actions/topVault.actions";
import { API } from "./index.service";


const topVaultUrl = "xcpep/top_vault/";
const vaultUrl = "xcpep/vault_info/";
const featureDetails = "xcpep/fetch_details/";
const lock = "auth/top_vault_locking/";

const url = "cost/assumption/";


export default {
    get ( projectIds: ID[], module?: number,project?:any ) {
        return API.get( topVaultUrl, {
            project: projectIds.join(),
            module,
            module_selected:project
        } );
    },

    getTopvoultId ( top_vault: ID ) {
        return API.get( url, {
            top_vault: top_vault,

        } );

    },
    featureDetailId ( vault: any, type: any ) {

        return API.get( featureDetails, {
            vault: vault,
            type: type
        } );

    },
    getTopvoultDeleteId ( id: any ) {
        return API.delete( url + id + "/" );
    },
    updateCard ( arg: ID, update: Partial<Pick<IAssumptionCard, "id" | "project">> ) {

        return API.put( url + arg + "/", update );
    },

    createCard ( Create: IAssumptionCard, projectId: ID, topVaultId: ID ) {
        return API.post( url, { ...Create, project: projectId, top_vault: topVaultId, } );
    },

    getCount ( vaultId: ID, module: number ) {
        return API.get( `xcpep/child_count/?vault=${vaultId}&module=${module}` );

    },

    getById ( topVaultId: ID, module: number ) {
        return API.get( topVaultUrl, {
            top_vault: Number( topVaultId ),
            module,
        } );
    },

    getByIds ( topVaultId: ID, module: number ) {
        return API.get( topVaultUrl, {
            top_vault: topVaultId,
            module,
        } );
    },
    getSubassemblies ( topVaultIds: ID[], module: number ) {
        return API.get( vaultUrl, {
            get_root: topVaultIds.join(),
            module,
        } );
    },
    getCompetitorModel ( subSystemId: ID ) {
        return API.get( "idea/fetch_component/", {
            subsystem: subSystemId,
            competitor: true
        } );
    },

    getCompetitorVault ( subSystemId: ID, originId: number ) {
        return API.get( "idea/fetch_component/", {
            subsystem: subSystemId,
            origin: originId
        } );
    }
    ,


    lockedVault ( arg: LockedVaultArg ) {
        return API.put( `auth/top_vault_locking/`, {
            top_vault: arg.topVaultId,
            lock: arg.weightlock
        }
        );
    },

    getCompetitorVaultList ( top_vault: ID, abbreviation: string, origin?: number ) {
        return API.get( "idea/fetch_component/", {
            top_vault,
            abbreviation,
            origin
        },0 );
    },


    create ( arg: CreateTopVaultArg ) {
        return API.post( topVaultUrl, arg,{},0 );
    },

    update ( arg: EditTopVaultArg ) {
        return API.put( topVaultUrl + arg.id + "/", arg.updates );
    },

    delete ( arg: DeleteTopVaultArg ) {
        return API.delete( topVaultUrl + arg.id + "/", {
            trash: arg.recycle,
        } );
    },

    deleteCard ( arg: DeleteTopVaultCardArg ) {
        return API.delete( url + arg.id + "/" );
    },
    copiedTopVault ( arg: CopyTopVaultArg ) {
        return API.get( '/xcpep/copy_variant/', {
            source: arg.source,
            product_id: arg.productId,
            name: arg.name
        }, 0 );
    },

    getTopVault ( arg: any ) {
        return API.get( '/cad/top_vault_list/', {
            project: arg.projectIds.join(),
            module: arg.module
        } );
    }
};
