import { Box, FormControl, MenuItem, Select } from "@mui/material";

import React, { useEffect, useState } from "react";

function RenderValueColumnItem({
  Lockstatus,
  useraction,
  input,
  setExpandInput,
  setExpand,
  handleChange,
  getOptionData,
  getCalculatorColumns,
  bomCalculatorColumns,
  table_element_list,
  isTableParameter,
  getdatafromtype,
  handleSave,
  editableRow,
  calcType,
  editableRowParameter,
  calculatorCategoryOptions,
}: any) {
  const [filteredTableElementList, setfilteredTableElementList] =
    useState<any>(table_element_list);
  useEffect(() => {
    // if (input?.parameter) {
    //   getCalculatorColumns(input?.parameter);
    // }
     
 
    
   
    
  }, [input]);

  useEffect(()=> {
    console.log(editableRowParameter, "editableRowParameter");
     getCalculatorColumns(editableRowParameter);
  },[editableRowParameter])



  
  
  

  useEffect(() => {
    
    const excludeItems = [
       "Individual row parameter with auto fetch",
      //"Individual row parameter without auto fetch"
    ];


    if (calcType.OVERHEAD == sessionStorage.getItem("calculator_type")) {
      const filterList: any =
        calcType &&
        calcType.OVERHEAD == sessionStorage.getItem("calculator_type")
          ? table_element_list?.filter((item: any) => {
              return !excludeItems?.includes(item);
            })
          : table_element_list;

      setfilteredTableElementList(filterList);
    } else if (
      calcType.RAW_MATERIAL == sessionStorage.getItem("calculator_type") &&
      Lockstatus?.category == 1
    ) {
      const filterList: any = table_element_list?.filter((item: any) => {
        return !excludeItems?.includes(item);
      });
      setfilteredTableElementList(filterList);
    } else {
      setfilteredTableElementList(table_element_list);
    }

   
  }, [Lockstatus, calcType]);



  return (
    <Box sx={{ display: "flex", alignItems: "center", columnGap: "0.5rem" }}>
      <FormControl fullWidth>
        {editableRow == input.id ? (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            variant="standard"
            label="Type"
            placeholder="Select Parameters"
            disabled={
              Lockstatus?.lock_status == false && useraction?.includes("U")
                ? (input.name == null || input.name == "") &&
                  Number(input.id) < 1
                  ? true
                  : false
                : true
            }
            value={input.parameter || ""}
            onFocus={() => {
              setExpandInput(input);
              setExpand(false);
            }}
            name="parameter"
            sx={{
              width: "50%",
              fontSize: "1rem",
              "&:before": {
                borderBottomColor: "primary.light",
              },
              ".MuiSvgIcon-root.MuiSelect-icon": {
                color: "primary.main",
              },
            }}
            // onBlur={()=> (Lockstatus?.lock_status==false && useraction?.includes("U")) && input.parameter!=null && input.parameter!="" && handleSave(input.id)}
            onChange={handleChange(input.id)}
          >
            {getOptionData(input.type)?.map((item: any) => {
              return (
                <MenuItem
                  sx={{ fontSize: "1rem" }}
                  value={item.value}
                  onClick={() => {
                    getCalculatorColumns(item.value);
                  }}
                >
                  {item.text}
                </MenuItem>
              );
            })}
          </Select>
        ) : (
          getOptionData(input.type)?.filter(
            (item: any) => item.value == input.parameter
          )[0]?.text || ""
        )}
      </FormControl>

      {input?.parameter && (
        <FormControl fullWidth>
          {editableRow == input.id && bomCalculatorColumns ? (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              variant="standard"
              label="Type"
              placeholder="Select calculators"
              disabled={
                Lockstatus?.lock_status == false && useraction?.includes("U")
                  ? (input?.name == null || input?.name == "") &&
                    Number(input?.id) < 1
                    ? true
                    : false
                  : true
              }
              value={input?.table_column_parameter || ""}
              onFocus={() => {
                setExpandInput(input);
                setExpand(false);
              }}
              name="table_column_parameter"
              sx={{
                width: "50%",
                fontSize: "1rem",
                "&:before": {
                  borderBottomColor: "primary.light",
                },
                ".MuiSvgIcon-root.MuiSelect-icon": {
                  color: "primary.main",
                },
              }}
              //onBlur={()=> (Lockstatus?.lock_status==false && useraction?.includes("U")) && input?.table_column_parameter!=null && input?.table_column_parameter!="" && handleSave(input?.id)}
              onChange={handleChange(input?.id)}
            >
              {bomCalculatorColumns
                ?.filter((itm: any) =>
                  [
                    "float",
                    "nonzero-positive-float",
                    "nonzero-positive-int",
                    "positive-float",
                    "positive-int",
                    "nonzero-float",
                    "integer",
                    "table-calculator-result",
                  ]?.includes(itm.data_type)
                )
                ?.map((item: any) => {
                  return (
                    <MenuItem sx={{ fontSize: "1rem" }} value={item?.id}>
                      {item?.name}
                    </MenuItem>
                  );
                })}
            </Select>
          ) : (
            (bomCalculatorColumns &&
              bomCalculatorColumns?.filter(
                (item: any) => item.id == input?.table_column_parameter
              )[0]?.name) ||
            ""
          )}
        </FormControl>
      )}

      {input?.parameter && (
        <FormControl fullWidth>
          {editableRow == input.id ? (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              variant="standard"
              label="Type"
              placeholder="Select"
              disabled={
                Lockstatus?.lock_status == false && useraction?.includes("U")
                  ? (input?.name == null || input?.name == "") &&
                    Number(input?.id) < 1
                    ? true
                    : false
                  : true
              }
              value={input?.table_element_type || ""}
              onFocus={() => {
                setExpandInput(input);
                setExpand(false);
              }}
              name="table_element_type"
              sx={{
                width: "50%",
                fontSize: "1rem",
                "&:before": {
                  borderBottomColor: "primary.light",
                },
                ".MuiSvgIcon-root.MuiSelect-icon": {
                  color: "primary.main",
                },
              }}
              onBlur={() =>
                Lockstatus?.lock_status == false &&
                useraction?.includes("U") &&
                input?.table_element_type != null &&
                input?.table_element_type != "" &&
                handleSave(input?.id)
              }
              onChange={handleChange(input?.id)}
            >
              
              {filteredTableElementList?.map((item: any) => {
                return (
                  <MenuItem sx={{ fontSize: "1rem" }} value={item}>
                    {item}
                  </MenuItem>
                );
              })}
            </Select>
          ) : (
            input?.table_element_type || ""
          )}
        </FormControl>
      )}

      {input.parameter && isTableParameter(input.type, input.parameter) && (
        <FormControl fullWidth>
          {editableRow == input.id ? (
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              variant="standard"
              label="Type"
              disabled={
                Lockstatus?.lock_status == false && useraction?.includes("U")
                  ? false
                  : true
              }
              value={input.table_element || ""}
              onChange={handleChange(input.id)}
              name="table_element"
              sx={{
                width: "100%",
                fontSize: "1rem",
                "&:before": {
                  borderBottomColor: "primary.light",
                },
                ".MuiSvgIcon-root.MuiSelect-icon": {
                  color: "primary.main",
                },
              }}
            >
              {getdatafromtype(input)?.map((item: any) => {
                return (
                  <MenuItem sx={{ fontSize: "1rem" }} value={item.value}>
                    {item.text}
                  </MenuItem>
                );
              })}
            </Select>
          ) : (
            getdatafromtype(input)?.filter(
              (item: any) => item.value == input.table_element
            )[0]?.text || ""
          )}
        </FormControl>
      )}
    </Box>
  );
}

export default RenderValueColumnItem;
