import * as React from "react";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { Box, Button, ButtonProps, IconButton, Skeleton, Tooltip, Typography, styled } from "@mui/material";
import { DataGrid, GridCsvExportMenuItem, GridCsvExportOptions, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExportContainer } from "@mui/x-data-grid";
import InfoIcon from "@mui/icons-material/Info";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import LaunchIcon from "@mui/icons-material/Launch";
import EditIcon from "@mui/icons-material/Edit";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { useHistory } from "react-router-dom";
import swal from "sweetalert";
import move from "../../../Assets/images/right move.svg";
import { useBaseParamsModal } from "../../../CustomHook/useBaseParamsModal";
import { useBaseModal } from "../../../ComponentUI/SearchPage/useBaseModal";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import useWindowDimensions from "../../../ui-reusable-component/useWindowDimensions";
import { API } from "../../../api-services";
import BoughtoutEditModal from "./BoughtoutEditModal";
import { BearingsColumns, ConnectorsColumns, ConsumablesColumns, ElectronicChildColumns, FastenersCols, MEPartsColumns, OthersColumns } from "../../../utlis/BoughtOutPart.constant";
import { BulbColumns, RemoteKeys, HornModules, IgnitionMOdules, FlasherModules } from "../../../utlis/BoughtOutElectricalParts.constant";
import FetchBoughtOutModal from "./FetchBoughtOutModal";
import InfoBoughtoutrevisionModal from "./InfoBoughtoutrevisionModal";
import BoughtoutGraph from "./BoughtoutGraph";
import FlagIcon from "@mui/icons-material/Flag";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import RMBOPmoveModal from "./RMBOPmoveModal";
import LockIcon from '@mui/icons-material/Lock';

import LockOpenIcon from '@mui/icons-material/LockOpen';

import { userSelectors } from "../../../Redux/Reducers/user.reducer";
import { useSelector } from "react-redux";
import LockStatusInfoModal from "../LockStatusInfoModal";
import CreateBoughtOutDatabaseModal from "./CreateBoughtOutDatabaseModal";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import BoughtOutPartActionComponents from "../ActionComponents/BoughtOutPartActionComponents";
import Popover from '@mui/material/Popover';


export interface IRawMaterialRevisionTableProps { 
  setCounter: any;
}


const csvOptions: GridCsvExportOptions = {
  fileName: "BoughtOut Parts Revision Table CSV",
  delimiter: ",",
  utf8WithBom: true,
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer sx={{
    width: { lg: '100%', xl: '100%' },
    height: '2.3rem',
    justifyContent: 'flex-end',
    paddingRight: '1rem',
    marginTop: '-2.6rem',
  }}>
    <span
    // style={{ position: "absolute", right: "0rem" }}
    >
      <GridToolbarColumnsButton />
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);

const CustomToolbarElectrical = () => (
  <GridToolbarContainer sx={{
    width: '100%',
    height: '2.3rem',
    justifyContent: 'flex-end',
    paddingRight: '1rem',
    marginTop: '-2.4rem',
  }}>
    <span
    // style={{ position: "absolute", right: "0rem" }}
    >
      <GridToolbarColumnsButton />
      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);

export function BoughOutPartsRevisionTable(props: IRawMaterialRevisionTableProps) {
  const { tab, year, month, revisionId, typeId, databaseName, status, search, categoryName, lockCounter } =
    useRouteParams<any>();

  const bopMoveModal = useBaseParamsModal();
  const {setCounter} = props;
  // const BOPtoRMMoveModal = useBaseParamsModal();
  const subRevisionEditAdd = useBaseParamsModal();
  const subRevisionEdit = useBaseParamsModal();
  const [electricalCategories, setElectricalCategories] = React.useState<any>([]);
  const graphModal = useBaseParamsModal();
  const fetchRawMaterial = useBaseModal();
  const lockmodal = useBaseModal()
  const history = useHistory();
  const { height, width } = useWindowDimensions();
  const [expanded, setExpanded] = React.useState<string | false>(categoryName && categoryName);
  const [categoryList, setCategoryList] = React.useState<any>([]);
  const [categoryListMaterial, setCategoryListMaterial] = React.useState<any>([]);
  const [count, setCount] = React.useState<number>(0);
  const [tableDataLoading, setTabledataLoading] = React.useState<any>(false);
  const [accordionLoading, setAccordionLoading] = React.useState<any>(false);
  const [tableData, setTableData] = React.useState<any>([]);
  const [addAllCategory, setAddAllCategory] = React.useState<any>([])
  const [electricalTableData, setElectricalTableData] = React.useState<any>([]);
  const [subElectricalLoadings, setSubElectricalLoadings] = React.useState<any>(false);
  const [selectedElectrical, setSelectedElectrical] = React.useState<string | false>(false);
  const infoRawMaterialModal = useBaseParamsModal();
  const [useraction, setuseraction] = React.useState<any>(null)
  const [selectedTab, setSelectedTab] = React.useState('');
  const [selectedModule, setSelectedModule] = React.useState('');
  const [LockData, SetLockData] = React.useState<any>(null)
  const userProfile: any = useSelector(userSelectors.selectAll);
  const [rowId, setRowId] = React.useState<any>(null);


  // Menu Item popover
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, id: any) => {
    setAnchorEl(event.currentTarget);
    setRowId(id);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;



  const handleOpenLinkReference = (reference: string) => {
    window.open(reference);
  };
  React.useEffect(() => {
    API.get(
      "/api/db/revision_database/",
      {
        category_type: "materialcost",
        revision_id: revisionId,
      },
      0
    ).then((res: any) => {
      setCategoryListMaterial(res.data);
    });
  }, [revisionId]);
  // const handleChangeElectrical = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
  //   event.stopPropagation();
  //   event.preventDefault();
  //   setSelectedElectrical(newExpanded ? panel : false);
  //   if (newExpanded !== expanded) {
  //     setElectricalTableData([]);
  //     setSubElectricalLoadings(true);

  //     API.get(
  //       "/api/db/bought_out/",
  //       {
  //         commodity: expanded,
  //         module_type: panel,
  //         revision: revisionId,
  //       },
  //       0
  //     )
  //       .then((res: any) => {
  //         setElectricalTableData(res.data);
  //         setSubElectricalLoadings(false);
  //       })
  //       .catch((err: any) => {
  //         setSubElectricalLoadings(false);
  //       });
  //   }

  // };

  const handleChangeElectrical = (selectedModule: any) => {

    setSelectedModule(selectedModule);
    if (selectedElectrical !== selectedModule) {
      setElectricalTableData([]);
      setSubElectricalLoadings(true);

      API.get(
        "/api/db/bought_out/",
        {
          commodity: selectedTab,
          module_type: selectedModule,
          revision: revisionId ? revisionId : undefined,
        },
        0
      )
        .then((res: any) => {
          setElectricalTableData(res.data);
          setSubElectricalLoadings(false);
        })
        .catch((err: any) => {
          setSubElectricalLoadings(false);
        });
    }

  };



  React.useEffect(() => {
    API.get(
      "/auth/calibration_user_permission/",
      {
        action: true,
        database: true,
        revision_id: revisionId
      },
      0
    )
      .then((res: any) => {
        setuseraction(res?.data?.action)
        sessionStorage.setItem("DBPermission", res.data.action);
      })
      .catch((err: any) => { });
  }, [revisionId]);

  const getElectricalCategories = () => {
    API.get(
      "/api/db/bought_out/",
      {
        commodity: selectedTab,
        module_count: true,
        revision: revisionId ? revisionId : undefined
      },
      0
    )
      .then((res: any) => {
        setElectricalCategories(res.data);
        if (res?.data[0]?.module_type) {
          setSelectedModule(res?.data[0]?.module_type);
          handleChangeElectrical(res?.data[0]?.module_type);
        }
      })
      .catch((err: any) => { });
  }

  React.useEffect(() => {
    if (selectedTab === "Electrical & Electronics Proprietary Parts") {
      getElectricalCategories()
    }
  }, [selectedTab]);
  React.useEffect(() => {
    API.get(
      "/api/db/bought_out/",
      {
        commodity: "Electrical & Electronics Proprietary Parts",
        module_count: true,
        revision: revisionId ? revisionId : undefined
      },
      0
    )
      .then((res: any) => {
        setElectricalCategories(res.data);
        if (res?.data[0]?.module_type) {
          setSelectedModule(res?.data[0]?.module_type);
          handleChangeElectrical(res?.data[0]?.module_type);
        }
      })
      .catch((err: any) => { });
  }, [])

  const getCategoryList = () => {
    setAccordionLoading(true);
    API.get(
      "/api/db/revision_database/",
      {
        category_type: "boughtoutpart",
        revision_id: revisionId,
      },
      0
    )
      .then((res: any) => {
        setCategoryList(res.data);
        setAccordionLoading(false);
        if (categoryName == 0) {
          setSelectedTab(res?.data[0]?.category);
          getMaterialData(res?.data[0]?.category);
          history.push(`/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/${databaseName}/${res?.data[0]?.category}/${status}/${search}/${lockCounter}`);
        }
      })
      .catch((err: any) => {
        setAccordionLoading(false);
      });
  };

  const getCategoryListAll = () => {
    API.get(
      "/api/db/cost_databases/",
      {
        database_type: "purchaseitem",
        // revision_id: revisionId,
      },
      0
    )
      .then((res: any) => {
        setAddAllCategory(res.data);

      })
      .catch((err: any) => {
        // setAccLoader(false);
      });
  };

  React.useEffect(() => {
    getCategoryListAll();
  }, [])


  // For Initial tab select on load
  // React.useEffect(() => {
  // 	if (categoryList && categoryList.length > 0) {
  // 	  // Set the selected tab to the first item in categoryList when the component loads
  // 	  setSelectedTab(categoryList[0].category);
  // 	  getMaterialData(categoryList[0].category);
  // 	}
  //   }, [categoryList]);


  React.useEffect(() => {
    getCategoryList();
  }, [revisionId]);
  React.useEffect(() => {
    if (categoryName !== '0') {
      // setSelectedTab(false);
      setSelectedTab(categoryName);
      getCategoryList();
    }
  }, [categoryName]);
  const editSubRevision = (item: any) => {
    subRevisionEdit.open(item);
  };
  const getMaterialData = (category: any) => {
    setTabledataLoading(true);
    API.get(
      "/api/db/bought_out_cost/",
      {
        revision: revisionId,
        commodity: category,
      },
      0
    )
      .then((res: any) => {
        setTableData(res.data);
        setTabledataLoading(false);
      })
      .catch((err: any) => {
        setTabledataLoading(false);
      });
  };

  const LockHandler = (item: any, type: any) => {
    if (type == false) {
      if ((userProfile && userProfile[0]?.is_superuser || userProfile[0]?.is_project_admin || userProfile[0]?.id == item?.locked_user)) {

        API.put(`/api/db/bought_out_cost/${item?.id}/`, {
          lock: type, revision: revisionId,
          category: categoryName
        }).then((res: any) => {
          getMaterialData(categoryName)
          setCounter((prev: any) => prev + 1);
        }).catch((err: any) => {
          console.log("Server error")
        })
      } else {
        SetLockData(item)
        lockmodal.open()
      }
    } if (type == true) {
      API.put(`/api/db/bought_out_cost/${item?.id}/`, {
        lock: type, revision: revisionId,
        category: categoryName
      }).then((res: any) => {
        getMaterialData(categoryName)
        setCounter((prev: any) => prev + 1);
      }).catch((err: any) => {
        console.log("Server error")
      })

    }



  }

  // const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
  //   setExpanded(newExpanded ? panel : false);
  //   if (newExpanded) {
  //     getMaterialData(panel);
  //     // typeId, databaseName,categoryName
  //     history.push(`/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/${databaseName}/${panel}/${status}/${search}`);
  //   }
  // };

  const handleChange = (categoryName: any) => {
    setSelectedTab(categoryName);
    getMaterialData(categoryName);
    history.push(`/data-base/revision-database/${tab}/${year}/${month}/${revisionId}/${typeId}/${databaseName}/${categoryName}/${status}/${search}/${lockCounter}`);
  };

  React.useEffect(() => {
    if (categoryName) {
      getMaterialData(categoryName);
    }
  }, [categoryName, lockCounter]);



  const getColumnDataElectrical = (category: any) => {
    const newColumn = {
      field: 'currency_code',
      headerName: 'Currency/Unit',
      sortable: false,
      minWidth: width > 1370 ? 120 : 150,
      renderCell: (cellValues: any) => {
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', columnGap: '1rem', }}>
            {`${cellValues?.row?.currency_code ? cellValues?.row?.currency_code : "--"}/${cellValues?.row?.unit ? cellValues?.row?.unit : "--"}`}
          </Box>
        );
      },
    };

    switch (category) {
      case "Bulb":
        return [
          {
            field: "actions",
            headerName: "Actions",
            width: width > 1370 ? 130 : 120,
            renderCell: (cellValues: any) => {
              return (
                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', alignItems: 'center', gap: '1rem', }}>
                  <Box>
                    {cellValues?.row?.lock_status ? <IconButton title='lock' sx={{ padding: '0', cursor: 'pointer', color: 'green' }} onClick={() => LockHandler(cellValues?.row, false)}>
                      <LockIcon />
                    </IconButton> : <IconButton title='lock' sx={{ padding: '0', cursor: 'pointer', color: 'primary.main' }} onClick={() => LockHandler(cellValues?.row, true)}>
                      <LockOpenIcon />
                    </IconButton>}
                  </Box>
                  <Box>
                    {useraction && useraction?.includes("U") && <IconButton
                      title="Edit"
                      sx={{ padding: "0", color: "primary.main" }}
                      onClick={() => editSubRevision(cellValues.row)}>
                      <EditIcon sx={{ cursor: "pointer" }} />
                    </IconButton>}
                  </Box>
                  <Tooltip title="More Items" placement='right-start'>
                    <IconButton
                      aria-describedby={id}
                      onClick={(event) => handleClick(event, cellValues.row)}
                      size="small"
                      sx={{ padding: '2px' }}
                    >
                      <MenuIcon sx={{ color: cellValues?.row?.flag_reference ? 'green' : 'primary.main', }} />
                    </IconButton>
                  </Tooltip>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'center',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'center',
                      horizontal: 'left',
                    }}

                    sx={{
                      '.MuiPaper-root.MuiPopover-paper': {
                        boxShadow: 'rgba(128, 128, 128, 0.2) 0px 3px 3px -2px, rgba(128, 128, 128, 0.14) 0px 3px 4px 0px, rgba(128, 128, 128, 0.12) 0px 1px 8px 0px'
                      }
                    }}
                  >
                    <BoughtOutPartActionComponents
                      cellValues={cellValues.row}
                      rowId={rowId}
                      infoRawMaterialModal={infoRawMaterialModal}
                      bopMoveModal={bopMoveModal}
                      graphModal={graphModal}
                      history={history}
                      tab={tab}
                      year={year}
                      month={month}
                      categoryName={categoryName}
                      status={status}
                      search={search}
                      useraction={useraction}
                      getMaterialData={getMaterialData}
                      setAnchorEl={setAnchorEl}
                    />
                  </Popover>
                </Box>
              );
            },
          },

          ...BulbColumns,
          newColumn,

        ];
      case "Remote Key/Lock":
        return [
          {
            field: "actions",
            headerName: "Actions",
            width: width > 1370 ? 130 : 120,
            renderCell: (cellValues: any) => {
              return (
                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', alignItems: 'center', gap: '1rem', }}>
                  <Box>
                    {cellValues?.row?.lock_status ? <IconButton title='lock' sx={{ padding: '0', cursor: 'pointer', color: 'green' }} onClick={() => LockHandler(cellValues?.row, false)}>
                      <LockIcon />
                    </IconButton> : <IconButton title='lock' sx={{ padding: '0', cursor: 'pointer', color: 'primary.main' }} onClick={() => LockHandler(cellValues?.row, true)}>
                      <LockOpenIcon />
                    </IconButton>
                    }
                  </Box>
                  <Box>
                    {useraction && useraction?.includes("U") && <EditIcon
                      titleAccess="Edit"
                      sx={{ cursor: "pointer" }}
                      onClick={() => editSubRevision(cellValues.row)}
                    />}
                  </Box>
                  <Tooltip title="More Items" placement='right-start'>
                    <IconButton
                      aria-describedby={id}
                      onClick={(event) => handleClick(event, cellValues.row)}
                      size="small"
                      sx={{ padding: '2px' }}
                    >
                      <MenuIcon sx={{ color: cellValues?.row?.flag_reference ? 'green' : 'primary.main' }} />
                    </IconButton>
                  </Tooltip>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'center',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'center',
                      horizontal: 'left',
                    }}

                    sx={{
                      '.MuiPaper-root.MuiPopover-paper': {
                        boxShadow: 'rgba(128, 128, 128, 0.2) 0px 3px 3px -2px, rgba(128, 128, 128, 0.14) 0px 3px 4px 0px, rgba(128, 128, 128, 0.12) 0px 1px 8px 0px'
                      }
                    }}
                  >
                    <BoughtOutPartActionComponents
                      cellValues={cellValues.row}
                      rowId={rowId}
                      infoRawMaterialModal={infoRawMaterialModal}
                      bopMoveModal={bopMoveModal}
                      graphModal={graphModal}
                      history={history}
                      tab={tab}
                      year={year}
                      month={month}
                      categoryName={categoryName}
                      status={status}
                      search={search}
                      useraction={useraction}
                      getMaterialData={getMaterialData}
                      setAnchorEl={setAnchorEl}
                    />
                  </Popover>
                </Box>
              );
            },
          },

          ...RemoteKeys,
          newColumn,
        ];

      case "Horn":
        return [
          {
            field: "actions",
            headerName: "Actions",
            width: width > 1370 ? 130 : 120,
            renderCell: (cellValues: any) => {
              return (
                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', alignItems: 'center', gap: '1rem', }}>
                  <Box>
                    {cellValues?.row?.lock_status ? <IconButton title='lock' sx={{ padding: '0', cursor: 'pointer', color: 'green' }} onClick={() => LockHandler(cellValues?.row, false)}>
                      <LockIcon />
                    </IconButton> : <IconButton title='lock' sx={{ padding: '0', cursor: 'pointer', color: 'primary.main' }} onClick={() => LockHandler(cellValues?.row, true)}>
                      <LockOpenIcon />
                    </IconButton>}
                  </Box>
                  <Box>
                    {useraction && useraction?.includes("U") && <EditIcon
                      titleAccess="Edit"
                      sx={{ cursor: "pointer" }}
                      onClick={() => editSubRevision(cellValues.row)}
                    />}
                  </Box>
                  <Tooltip title="More Items" placement='right-start'>
                    <IconButton
                      aria-describedby={id}
                      onClick={(event) => handleClick(event, cellValues.row)}
                      size="small"
                      sx={{ padding: '2px' }}
                    >
                      <MenuIcon sx={{ color: cellValues?.row?.flag_reference ? 'green' : 'primary.main', }} />
                    </IconButton>
                  </Tooltip>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'center',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'center',
                      horizontal: 'left',
                    }}

                    sx={{
                      '.MuiPaper-root.MuiPopover-paper': {
                        boxShadow: 'rgba(128, 128, 128, 0.2) 0px 3px 3px -2px, rgba(128, 128, 128, 0.14) 0px 3px 4px 0px, rgba(128, 128, 128, 0.12) 0px 1px 8px 0px'
                      }
                    }}
                  >
                    <BoughtOutPartActionComponents
                      cellValues={cellValues.row}
                      rowId={rowId}
                      infoRawMaterialModal={infoRawMaterialModal}
                      bopMoveModal={bopMoveModal}
                      graphModal={graphModal}
                      history={history}
                      tab={tab}
                      year={year}
                      month={month}
                      categoryName={categoryName}
                      status={status}
                      search={search}
                      useraction={useraction}
                      getMaterialData={getMaterialData}
                      setAnchorEl={setAnchorEl}
                    />
                  </Popover>
                </Box>
              );
            },
          },

          ...HornModules,
          newColumn,
        ];

      case "Ignition":
        return [
          {
            field: "actions",
            headerName: "Actions",
            width: width > 1370 ? 130 : 120,
            renderCell: (cellValues: any) => {
              return (
                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', alignItems: 'center', gap: '1rem', }}>
                  <Box>
                    {cellValues?.row?.lock_status ? <IconButton title='lock' sx={{ padding: '0', cursor: 'pointer', color: 'green' }} onClick={() => LockHandler(cellValues?.row, false)}>
                      <LockIcon />
                    </IconButton> : <IconButton title='lock' sx={{ padding: '0', cursor: 'pointer', color: 'primary.main' }} onClick={() => LockHandler(cellValues?.row, true)}>
                      <LockOpenIcon />
                    </IconButton>}
                  </Box>
                  <Box>
                    {useraction && useraction?.includes("U") && <EditIcon
                      titleAccess="Edit"
                      sx={{ cursor: "pointer" }}
                      onClick={() => editSubRevision(cellValues.row)}
                    />}
                  </Box>
                  <Tooltip title="More Items" placement='right-start'>
                    <IconButton
                      aria-describedby={id}
                      onClick={(event) => handleClick(event, cellValues.row)}
                      size="small"
                      sx={{ padding: '2px' }}
                    >
                      <MenuIcon sx={{ color: cellValues?.row?.flag_reference ? 'green' : 'primary.main', }} />
                    </IconButton>
                  </Tooltip>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'center',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'center',
                      horizontal: 'left',
                    }}

                    sx={{
                      '.MuiPaper-root.MuiPopover-paper': {
                        boxShadow: 'rgba(128, 128, 128, 0.2) 0px 3px 3px -2px, rgba(128, 128, 128, 0.14) 0px 3px 4px 0px, rgba(128, 128, 128, 0.12) 0px 1px 8px 0px'
                      }
                    }}
                  >
                    <BoughtOutPartActionComponents
                      cellValues={cellValues.row}
                      rowId={rowId}
                      infoRawMaterialModal={infoRawMaterialModal}
                      bopMoveModal={bopMoveModal}
                      graphModal={graphModal}
                      history={history}
                      tab={tab}
                      year={year}
                      month={month}
                      categoryName={categoryName}
                      status={status}
                      search={search}
                      useraction={useraction}
                      getMaterialData={getMaterialData}
                      setAnchorEl={setAnchorEl}
                    />
                  </Popover>
                </Box>
              );
            },
          },


          ...IgnitionMOdules,
          newColumn,
        ];

      case "Flasher":
        return [
          {
            field: "actions",
            headerName: "Actions",
            width: width > 1370 ? 130 : 120,
            renderCell: (cellValues: any) => {
              return (
                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', alignItems: 'center', gap: '1rem', }}>
                  <Box>
                    {cellValues?.row?.lock_status ? <IconButton title='lock' sx={{ padding: '0', cursor: 'pointer', color: 'green' }} onClick={() => LockHandler(cellValues?.row, false)}>
                      <LockIcon />
                    </IconButton> : <IconButton title='lock' sx={{ padding: '0', cursor: 'pointer', color: 'primary.main' }} onClick={() => LockHandler(cellValues?.row, true)}>
                      <LockOpenIcon />
                    </IconButton>}
                  </Box>
                  <Box>
                    {useraction && useraction?.includes("U") && <EditIcon
                      titleAccess="Edit"
                      sx={{ cursor: "pointer" }}
                      onClick={() => editSubRevision(cellValues.row)}
                    />}
                  </Box>
                  <Tooltip title="More Items" placement='right-start'>
                    <IconButton
                      aria-describedby={id}
                      onClick={(event) => handleClick(event, cellValues.row)}
                      size="small"
                      sx={{ padding: '2px' }}
                    >
                      <MenuIcon sx={{ color: cellValues?.row?.flag_reference ? 'green' : 'primary.main', }} />
                    </IconButton>
                  </Tooltip>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'center',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'center',
                      horizontal: 'left',
                    }}

                    sx={{
                      '.MuiPaper-root.MuiPopover-paper': {
                        boxShadow: 'rgba(128, 128, 128, 0.2) 0px 3px 3px -2px, rgba(128, 128, 128, 0.14) 0px 3px 4px 0px, rgba(128, 128, 128, 0.12) 0px 1px 8px 0px'
                      }
                    }}
                  >
                    <BoughtOutPartActionComponents
                      cellValues={cellValues.row}
                      rowId={rowId}
                      infoRawMaterialModal={infoRawMaterialModal}
                      bopMoveModal={bopMoveModal}
                      graphModal={graphModal}
                      history={history}
                      tab={tab}
                      year={year}
                      month={month}
                      categoryName={categoryName}
                      status={status}
                      search={search}
                      useraction={useraction}
                      getMaterialData={getMaterialData}
                      setAnchorEl={setAnchorEl}
                    />
                  </Popover>
                </Box>

              );
            },
          },

          ...FlasherModules,
          newColumn,
        ];
      default:
        return [];
    }
  };

  const getColumnData = (category: any) => {
    const newColumn = {
      field: 'currency_code',
      headerName: 'Currency/Unit',
      sortable: false,
      minWidth: width > 1370 ? 120 : 150,
      renderCell: (cellValues: any) => {


        return (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', columnGap: '1rem', }}>
            {`${cellValues?.row?.currency_code ? cellValues?.row?.currency_code : "--"}/${cellValues?.row?.unit ? cellValues?.row?.unit : "--"}`}
          </Box>
        );
      },
    };
    switch (category) {
      case "Connectors":
        return [
          {
            field: "actions",
            headerName: "Actions",
            width: width > 1370 ? 130 : 120,
            renderCell: (cellValues: any) => {
              return (
                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', alignItems: 'center', gap: '1rem', }}>
                  <Box>
                    {cellValues?.row?.lock_status ? <IconButton title='lock' sx={{ padding: '0', cursor: 'pointer', color: 'green' }} onClick={() => LockHandler(cellValues?.row, false)}>
                      <LockIcon />
                    </IconButton> : <IconButton title='lock' sx={{ padding: '0', cursor: 'pointer', color: 'primary.main' }} onClick={() => LockHandler(cellValues?.row, true)}>
                      <LockOpenIcon />
                    </IconButton>}
                  </Box>
                  <Box>
                    {(cellValues?.row?.lock_status == false && useraction && useraction?.includes("U")) && <EditIcon
                      titleAccess="Edit"
                      sx={{ cursor: "pointer" }}
                      onClick={() => editSubRevision(cellValues.row)}
                    />}
                  </Box>
                  <Tooltip title="More Items" placement='right-start'>
                    <IconButton
                      aria-describedby={id}
                      onClick={(event) => handleClick(event, cellValues.row)}
                      size="small"
                      sx={{ padding: '2px' }}
                    >
                      <MenuIcon sx={{ color: cellValues?.row?.flag_reference ? 'green' : 'primary.main', }} />
                    </IconButton>
                  </Tooltip>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'center',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'center',
                      horizontal: 'left',
                    }}

                    sx={{
                      '.MuiPaper-root.MuiPopover-paper': {
                        boxShadow: 'rgba(128, 128, 128, 0.2) 0px 3px 3px -2px, rgba(128, 128, 128, 0.14) 0px 3px 4px 0px, rgba(128, 128, 128, 0.12) 0px 1px 8px 0px'
                      }
                    }}
                  >
                    <BoughtOutPartActionComponents
                      cellValues={cellValues.row}
                      rowId={rowId}
                      infoRawMaterialModal={infoRawMaterialModal}
                      bopMoveModal={bopMoveModal}
                      graphModal={graphModal}
                      history={history}
                      tab={tab}
                      year={year}
                      month={month}
                      categoryName={categoryName}
                      status={status}
                      search={search}
                      useraction={useraction}
                      getMaterialData={getMaterialData}
                      setAnchorEl={setAnchorEl}
                    />
                  </Popover>
                </Box>




              );
            },
          },

          ...ConnectorsColumns,
          newColumn,
        ];
      case "Bearings":
        return [
          {
            field: "actions",
            headerName: "Actions",
            width: width > 1370 ? 130 : 120,
            renderCell: (cellValues: any) => {
              return (<>
                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', alignItems: 'center', gap: '1rem', }}>
                  <Box>
                    {cellValues?.row?.lock_status ? <IconButton title='lock' sx={{ padding: '0', cursor: 'pointer', color: 'green' }} onClick={() => LockHandler(cellValues?.row, false)}>
                      <LockIcon />
                    </IconButton> : <IconButton title='lock' sx={{ padding: '0', cursor: 'pointer', color: 'primary.main' }} onClick={() => LockHandler(cellValues?.row, true)}>
                      <LockOpenIcon />
                    </IconButton>}
                  </Box>
                  <Box>
                    {(cellValues?.row?.lock_status == false && useraction && useraction?.includes("U")) && <EditIcon
                      titleAccess="Edit"
                      sx={{ cursor: "pointer" }}
                      onClick={() => editSubRevision(cellValues.row)}
                    />}
                  </Box>
                  <Tooltip title="More Items" placement='right-start'>
                    <IconButton
                      aria-describedby={id}
                      onClick={(event) => handleClick(event, cellValues.row)}
                      size="small"
                      sx={{ padding: '2px' }}
                    >
                      <MenuIcon sx={{ color: cellValues?.row?.flag_reference ? 'green' : 'primary.main', }} />
                    </IconButton>
                  </Tooltip>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'center',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'center',
                      horizontal: 'left',
                    }}

                    sx={{
                      '.MuiPaper-root.MuiPopover-paper': {
                        boxShadow: 'rgba(128, 128, 128, 0.2) 0px 3px 3px -2px, rgba(128, 128, 128, 0.14) 0px 3px 4px 0px, rgba(128, 128, 128, 0.12) 0px 1px 8px 0px'
                      }
                    }}
                  >
                    <BoughtOutPartActionComponents
                      cellValues={cellValues.row}
                      rowId={rowId}
                      infoRawMaterialModal={infoRawMaterialModal}
                      bopMoveModal={bopMoveModal}
                      graphModal={graphModal}
                      history={history}
                      tab={tab}
                      year={year}
                      month={month}
                      categoryName={categoryName}
                      status={status}
                      search={search}
                      useraction={useraction}
                      getMaterialData={getMaterialData}
                      setAnchorEl={setAnchorEl}
                    />
                  </Popover>

                </Box>


              </>);
            },
          },

          ...BearingsColumns,
          newColumn,
        ];
      case "Consumables":
        return [
          {
            field: "actions",
            headerName: "Actions",
            width: width > 1370 ? 130 : 120,
            renderCell: (cellValues: any) => {
              return (
                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', alignItems: 'center', gap: '1rem', }}>
                  <Box>
                    {cellValues?.row?.lock_status ? <IconButton title='lock' sx={{ padding: '0', cursor: 'pointer', color: 'green' }} onClick={() => LockHandler(cellValues?.row, false)}>
                      <LockIcon />
                    </IconButton> : <IconButton title='lock' sx={{ padding: '0', cursor: 'pointer', color: 'primary.main' }} onClick={() => LockHandler(cellValues?.row, true)}>
                      <LockOpenIcon />
                    </IconButton>}
                  </Box>
                  <Box>
                    {(cellValues?.row?.lock_status == false && useraction && useraction?.includes("U")) && <EditIcon
                      titleAccess="Edit"
                      sx={{ cursor: "pointer" }}
                      onClick={() => editSubRevision(cellValues.row)}
                    />}
                  </Box>
                  <Tooltip title="More Items" placement='right-start'>
                    <IconButton
                      aria-describedby={id}
                      onClick={(event) => handleClick(event, cellValues.row)}
                      size="small"
                      sx={{ padding: '2px' }}
                    >
                      <MenuIcon sx={{ color: cellValues?.row?.flag_reference ? 'green' : 'primary.main', }} />
                    </IconButton>
                  </Tooltip>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'center',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'center',
                      horizontal: 'left',
                    }}

                    sx={{
                      '.MuiPaper-root.MuiPopover-paper': {
                        boxShadow: 'rgba(128, 128, 128, 0.2) 0px 3px 3px -2px, rgba(128, 128, 128, 0.14) 0px 3px 4px 0px, rgba(128, 128, 128, 0.12) 0px 1px 8px 0px'
                      }
                    }}
                  >
                    <BoughtOutPartActionComponents
                      cellValues={cellValues.row}
                      rowId={rowId}
                      infoRawMaterialModal={infoRawMaterialModal}
                      bopMoveModal={bopMoveModal}
                      graphModal={graphModal}
                      history={history}
                      tab={tab}
                      year={year}
                      month={month}
                      categoryName={categoryName}
                      status={status}
                      search={search}
                      useraction={useraction}
                      getMaterialData={getMaterialData}
                      setAnchorEl={setAnchorEl}
                    />
                  </Popover>


                </Box>

              );
            },
          },
          ...ConsumablesColumns,
          newColumn,
        ];
      case "Electronic Child Components":
        return [
          {
            field: "actions",
            headerName: "Actions",
            width: width > 1370 ? 130 : 120,
            renderCell: (cellValues: any) => {
              return (
                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', alignItems: 'center', gap: '1rem', }}>
                  <Box>
                    {cellValues?.row?.lock_status ? <IconButton title='lock' sx={{ padding: '0', cursor: 'pointer', color: 'green' }} onClick={() => LockHandler(cellValues?.row, false)}>
                      <LockIcon />
                    </IconButton> : <IconButton title='lock' sx={{ padding: '0', cursor: 'pointer', color: 'primary.main' }} onClick={() => LockHandler(cellValues?.row, true)}>
                      <LockOpenIcon />
                    </IconButton>}
                  </Box>
                  <Box>
                    {(cellValues?.row?.lock_status == false && useraction && useraction?.includes("U")) && <EditIcon
                      titleAccess="Edit"
                      sx={{ cursor: "pointer" }}
                      onClick={() => editSubRevision(cellValues.row)}
                    />}
                  </Box>
                  <Tooltip title="More Items" placement='right-start'>
                    <IconButton
                      aria-describedby={id}
                      onClick={(event) => handleClick(event, cellValues.row)}
                      size="small"
                      sx={{ padding: '2px' }}
                    >
                      <MenuIcon sx={{ color: cellValues?.row?.flag_reference ? 'green' : 'primary.main', }} />
                    </IconButton>
                  </Tooltip>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'center',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'center',
                      horizontal: 'left',
                    }}

                    sx={{
                      '.MuiPaper-root.MuiPopover-paper': {
                        boxShadow: 'rgba(128, 128, 128, 0.2) 0px 3px 3px -2px, rgba(128, 128, 128, 0.14) 0px 3px 4px 0px, rgba(128, 128, 128, 0.12) 0px 1px 8px 0px'
                      }
                    }}
                  >
                    <BoughtOutPartActionComponents
                      cellValues={cellValues.row}
                      rowId={rowId}
                      infoRawMaterialModal={infoRawMaterialModal}
                      bopMoveModal={bopMoveModal}
                      graphModal={graphModal}
                      history={history}
                      tab={tab}
                      year={year}
                      month={month}
                      categoryName={categoryName}
                      status={status}
                      search={search}
                      useraction={useraction}
                      getMaterialData={getMaterialData}
                      setAnchorEl={setAnchorEl}
                    />
                  </Popover>

                </Box>

              );
            },
          },

          ...ElectronicChildColumns,
          newColumn,
        ];
      case "Mechanical Proprietory Parts":
        return [
          {
            field: "actions",
            headerName: "Actions",
            width: width > 1370 ? 130 : 120,
            renderCell: (cellValues: any) => {
              return (
                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', alignItems: 'center', gap: '1rem', }}>
                  <Box>
                    {cellValues?.row?.lock_status ? <IconButton title='lock' sx={{ padding: '0', cursor: 'pointer', color: 'green' }} onClick={() => LockHandler(cellValues?.row, false)}>
                      <LockIcon />
                    </IconButton> : <IconButton title='lock' sx={{ padding: '0', cursor: 'pointer', color: 'primary.main' }} onClick={() => LockHandler(cellValues?.row, true)}>
                      <LockOpenIcon />
                    </IconButton>
                    }
                  </Box>
                  <Box>
                    {(cellValues?.row?.lock_status == false && useraction && useraction?.includes("U")) && <EditIcon
                      titleAccess="Edit"
                      sx={{ cursor: "pointer" }}
                      onClick={() => editSubRevision(cellValues.row)}
                    />}
                  </Box>

                  <Tooltip title="More Items" placement='right-start'>
                    <IconButton
                      aria-describedby={id}
                      onClick={(event) => handleClick(event, cellValues.row)}
                      size="small"
                      sx={{ padding: '2px' }}
                    >
                      <MenuIcon sx={{ color: cellValues?.row?.flag_reference ? 'green' : 'primary.main', }} />
                    </IconButton>
                  </Tooltip>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'center',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'center',
                      horizontal: 'left',
                    }}

                    sx={{
                      '.MuiPaper-root.MuiPopover-paper': {
                        boxShadow: 'rgba(128, 128, 128, 0.2) 0px 3px 3px -2px, rgba(128, 128, 128, 0.14) 0px 3px 4px 0px, rgba(128, 128, 128, 0.12) 0px 1px 8px 0px'
                      }
                    }}
                  >
                    <BoughtOutPartActionComponents
                      cellValues={cellValues.row}
                      rowId={rowId}
                      infoRawMaterialModal={infoRawMaterialModal}
                      bopMoveModal={bopMoveModal}
                      graphModal={graphModal}
                      history={history}
                      tab={tab}
                      year={year}
                      month={month}
                      categoryName={categoryName}
                      status={status}
                      search={search}
                      useraction={useraction}
                      getMaterialData={getMaterialData}
                      setAnchorEl={setAnchorEl}
                    />
                  </Popover>
                </Box>

              );
            },
          },

          ...MEPartsColumns,
          newColumn,
        ];
      case "Fasteners":
        return [
          {
            field: "actions",
            headerName: "Actions",
            width: width > 1370 ? 130 : 120,
            renderCell: (cellValues: any) => {
              return (<>
                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', alignItems: 'center', gap: '1rem', }}>
                  <Box>
                    {cellValues?.row?.lock_status ? <IconButton title='lock' sx={{ padding: '0', cursor: 'pointer', color: 'green' }} onClick={() => LockHandler(cellValues?.row, false)}>
                      <LockIcon />
                    </IconButton> : <IconButton title='lock' sx={{ padding: '0', cursor: 'pointer', color: 'primary.main' }} onClick={() => LockHandler(cellValues?.row, true)}>
                      <LockOpenIcon />
                    </IconButton>}
                  </Box>
                  <Box>
                    {(cellValues?.row?.lock_status == false && useraction && useraction?.includes("U")) && <EditIcon
                      titleAccess="Edit"
                      sx={{ cursor: "pointer" }}
                      onClick={() => editSubRevision(cellValues.row)}
                    />}
                  </Box>
                  <Tooltip title="More Items" placement='right-start'>
                    <IconButton
                      aria-describedby={id}
                      // onClick={handleClick(cellValues?.row?.id)}
                      onClick={(event) => handleClick(event, cellValues.row)}
                      size="small"
                      sx={{ padding: '2px' }}
                    >
                      <MenuIcon sx={{ color: cellValues?.row?.flag_reference ? 'green' : 'primary.main' }} />
                    </IconButton>
                  </Tooltip>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'center',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'center',
                      horizontal: 'left',
                    }}

                    sx={{
                      '.MuiPaper-root.MuiPopover-paper': {
                        boxShadow: 'rgba(128, 128, 128, 0.2) 0px 3px 3px -2px, rgba(128, 128, 128, 0.14) 0px 3px 4px 0px, rgba(128, 128, 128, 0.12) 0px 1px 8px 0px'
                      }
                    }}
                  >
                    <BoughtOutPartActionComponents
                      cellValues={cellValues.row}
                      rowId={rowId}
                      infoRawMaterialModal={infoRawMaterialModal}
                      bopMoveModal={bopMoveModal}
                      graphModal={graphModal}
                      history={history}
                      tab={tab}
                      year={year}
                      month={month}
                      categoryName={categoryName}
                      status={status}
                      search={search}
                      useraction={useraction}
                      getMaterialData={getMaterialData}
                      setAnchorEl={setAnchorEl}
                    />
                  </Popover>

                </Box>

              </>);
            },
          },

          ...FastenersCols,
          newColumn,
        ];
      case "Uncategorized":
        return [
          {
            field: "actions",
            headerName: "Actions",
            width: width > 1370 ? 130 : 120,
            renderCell: (cellValues: any) => {
              return (
                <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', alignItems: 'center', gap: '1rem', }}>
                  <Box>
                    {cellValues?.row?.lock_status ? <IconButton title='lock' sx={{ padding: '0', cursor: 'pointer', color: 'green' }} onClick={() => LockHandler(cellValues?.row, false)}>
                      <LockIcon />
                    </IconButton> : <IconButton title='lock' sx={{ padding: '0', cursor: 'pointer', color: 'primary.main' }} onClick={() => LockHandler(cellValues?.row, true)}>
                      <LockOpenIcon />
                    </IconButton>
                    }
                  </Box>
                  <Box>
                    {cellValues?.row?.lock_status == false && useraction && useraction?.includes("U") && <EditIcon
                      titleAccess="Edit"
                      sx={{ cursor: "pointer" }}
                      onClick={() => editSubRevision(cellValues.row)}
                    />}
                  </Box>
                  <Tooltip title="More Items" placement='right-start'>
                    <IconButton
                      aria-describedby={id}
                      // onClick={handleClick(cellValues?.row?.id)}
                      onClick={(event) => handleClick(event, cellValues.row)}
                      size="small"
                      sx={{ padding: '2px' }}
                    >
                      <MenuIcon sx={{ color: 'primary.main', }} />
                    </IconButton>
                  </Tooltip>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: 'center',
                      horizontal: 'right',
                    }}
                    transformOrigin={{
                      vertical: 'center',
                      horizontal: 'left',
                    }}

                    sx={{
                      '.MuiPaper-root.MuiPopover-paper': {
                        boxShadow: 'rgba(128, 128, 128, 0.2) 0px 3px 3px -2px, rgba(128, 128, 128, 0.14) 0px 3px 4px 0px, rgba(128, 128, 128, 0.12) 0px 1px 8px 0px'
                      }
                    }}
                  >
                    <BoughtOutPartActionComponents
                      cellValues={cellValues.row}
                      rowId={rowId}
                      infoRawMaterialModal={infoRawMaterialModal}
                      bopMoveModal={bopMoveModal}
                      graphModal={graphModal}
                      history={history}
                      tab={tab}
                      year={year}
                      month={month}
                      categoryName={categoryName}
                      status={status}
                      search={search}
                      useraction={useraction}
                      getMaterialData={getMaterialData}
                      setAnchorEl={setAnchorEl}
                    />
                  </Popover>
                </Box>


              );
            },
          },

          ...OthersColumns,
          newColumn,
        ];
      default:
        return [];
    }
  };
  const getRowDataElectrical = (category: any) => {
    switch (category) {
      case "Bulb":
        let rowsBulb: any = [];
        let connectors: any =
          electricalTableData &&
          electricalTableData?.map((item: any, index: any) => {
            let objShared: any = {
              id: item?.id,
              sno: index + 1,
              name: item?.name,
              type: item?.type,
              rated_voltage: item?.rated_voltage,
              base_code: item?.base_code,
              no_of_filament: item?.no_of_filament,
              watttage: item?.watttage,
              reference: item?.reference,
              unit_cost: item?.unit_cost,
              commodity_name: item?.commodity_name,
              module_type: item?.module_type,
              mapped_status: item?.mapped_status,
              purchase_item: item?.purchase_item,
              flag_reference: item?.flag_reference,
              currency_id: item?.currency_id,
              currency_code: item?.currency_code,
              unit: item?.unit,
            };
            rowsBulb.push(objShared);
          });

        let dataStructureDataGridBulb: any = {
          columns: getColumnDataElectrical(selectedModule) && getColumnDataElectrical(selectedModule),
          rows: rowsBulb && rowsBulb,
        };
        return dataStructureDataGridBulb && dataStructureDataGridBulb;

      case "Remote Key/Lock":
        let rowsRemoteKey: any = [];
        let remoteKey: any =
          electricalTableData &&
          electricalTableData?.map((item: any, index: any) => {
            let objShared: any = {
              id: item?.id,
              sno: index + 1,
              name: item?.name,
              type: item?.type,
              rated_voltage: item?.rated_voltage,
              base_code: item?.base_code,
              no_of_filament: item?.no_of_filament,
              watttage: item?.watttage,
              reference: item?.reference,
              unit_cost: item?.unit_cost,
              commodity_name: item?.commodity_name,
              module_type: item?.module_type,
              mapped_status: item?.mapped_status,
              purchase_item: item?.purchase_item,
              flag_reference: item?.flag_reference,
              currency_id: item?.currency_id,
              currency_code: item?.currency_code,
              unit: item?.unit,
            };
            rowsRemoteKey.push(objShared);
          });

        let dataStructureDataGridRemoteKey: any = {
          columns: getColumnDataElectrical(selectedModule) && getColumnDataElectrical(selectedModule),
          rows: rowsRemoteKey && rowsRemoteKey,
        };
        return dataStructureDataGridRemoteKey && dataStructureDataGridRemoteKey;

      case "Horn":
        let rowsHorn: any = [];
        let horn: any =
          electricalTableData &&
          electricalTableData?.map((item: any, index: any) => {
            let objShared: any = {
              id: item?.id,
              sno: index + 1,
              name: item?.name,
              type: item?.type,
              rated_voltage: item?.rated_voltage,
              base_code: item?.base_code,
              no_of_filament: item?.no_of_filament,
              watttage: item?.watttage,
              reference: item?.reference,
              unit_cost: item?.unit_cost,
              commodity_name: item?.commodity_name,
              module_type: item?.module_type,
              mapped_status: item?.mapped_status,
              purchase_item: item?.purchase_item,
              flag_reference: item?.flag_reference,
              currency_id: item?.currency_id,
              currency_code: item?.currency_code,
              unit: item?.unit,
            };
            rowsHorn.push(objShared);
          });

        let dataStructureDataGridHorn: any = {
          columns: getColumnDataElectrical(selectedModule) && getColumnDataElectrical(selectedModule),
          rows: rowsHorn && rowsHorn,
        };
        return dataStructureDataGridHorn && dataStructureDataGridHorn;

      case "Ignition":
        let rowsIgnition: any = [];
        let Ignition: any =
          electricalTableData &&
          electricalTableData?.map((item: any, index: any) => {
            let objShared: any = {
              id: item?.id,
              sno: index + 1,
              name: item?.name,
              type: item?.type,
              rated_voltage: item?.rated_voltage,
              base_code: item?.base_code,
              no_of_filament: item?.no_of_filament,
              watttage: item?.watttage,
              reference: item?.reference,
              unit_cost: item?.unit_cost,
              commodity_name: item?.commodity_name,
              module_type: item?.module_type,
              mapped_status: item?.mapped_status,
              purchase_item: item?.purchase_item,
              flag_reference: item?.flag_reference,
              currency_id: item?.currency_id,
              currency_code: item?.currency_code,
              unit: item?.unit,
            };
            rowsIgnition.push(objShared);
          });

        let dataStructureDataGridIgnition: any = {
          columns: getColumnDataElectrical(selectedModule) && getColumnDataElectrical(selectedModule),
          rows: rowsIgnition && rowsIgnition,
        };
        return dataStructureDataGridIgnition && dataStructureDataGridIgnition;

      case "Flasher":
        let rowsFlasher: any = [];
        let Flasher: any =
          electricalTableData &&
          electricalTableData?.map((item: any, index: any) => {
            let objShared: any = {
              id: item?.id,
              sno: index + 1,
              name: item?.name,
              type: item?.type,
              rated_voltage: item?.rated_voltage,
              base_code: item?.base_code,
              no_of_filament: item?.no_of_filament,
              watttage: item?.watttage,
              reference: item?.reference,
              unit_cost: item?.unit_cost,
              commodity_name: item?.commodity_name,
              module_type: item?.module_type,
              mapped_status: item?.mapped_status,
              purchase_item: item?.purchase_item,
              flag_reference: item?.flag_reference,
              currency_id: item?.currency_id,
              currency_code: item?.currency_code,
              unit: item?.unit,
            };
            rowsFlasher.push(objShared);
          });

        let dataStructureDataGridFlasher: any = {
          columns: getColumnDataElectrical(selectedModule) && getColumnDataElectrical(selectedModule),
          rows: rowsFlasher && rowsFlasher,
        };
        return dataStructureDataGridFlasher && dataStructureDataGridFlasher;
    }
  };

  const getRowData = (category: any) => {
    switch (category) {
      case "Connectors":
        let rowsConnectors: any = [];
        let connectors: any =
          tableData &&
          tableData?.map((item: any, index: any) => {
            let objShared: any = {
              id: item?.id,
              sno: index + 1,
              name: item?.name,
              no_of_pins: item?.no_of_pins,
              postion_config: item?.postion_config,
              fit_type: item?.fit_type,
              pin_pitch: item?.pin_pitch,
              shape: item?.shape,
              connection_type: item?.connection_type,
              gender: item?.gender,
              material: item?.material,
              reference: item?.reference,
              unit_cost: item?.unit_cost,
              commodity_name: item?.commodity_name,
              mapped_status: item?.mapped_status,
              purchase_item: item?.purchase_item,
              flag_reference: item?.flag_reference,
              currency_id: item?.currency_id,
              currency_code: item?.currency_code,
              unit: item?.unit,
              lock_status
                : item?.lock_status,

              locked_on
                :
                item?.locked_on,
              locked_user
                :
                item?.locked_user,
              locked_user_email
                :
                item?.locked_user_email,
              locked_user_name
                :
                item?.locked_user_name,
              locked_user_phone
                :
                item?.locked_user_phone,
            };
            rowsConnectors.push(objShared);
          });

        let dataStructureDataGridConnectors: any = {
          columns: getColumnData(selectedTab) && getColumnData(selectedTab),
          rows: rowsConnectors && rowsConnectors,
        };
        return dataStructureDataGridConnectors && dataStructureDataGridConnectors;

      case "Bearings":
        let rowsBearings: any = [];
        let dataGridDataGeneratorBearings: any =
          tableData &&
          tableData?.map((item: any, index: any) => {
            let objShared: any = {
              id: item?.id,
              sno: index + 1,
              name: item?.name,
              code: item?.code,
              height: item?.height,
              weight: item?.weight,
              item_id: item?.item_id,
              item_od: item?.item_od,
              bearing_code: item?.bearing_code,
              reference: item?.reference,
              unit_cost: item?.unit_cost,
              commodity_name: item?.commodity_name,
              mapped_status: item?.mapped_status,
              purchase_item: item?.purchase_item,
              flag_reference: item?.flag_reference,
              currency_id: item?.currency_id,
              currency_code: item?.currency_code,
              unit: item?.unit,
              lock_status
                : item?.lock_status,

              locked_on
                :
                item?.locked_on,
              locked_user
                :
                item?.locked_user,
              locked_user_email
                :
                item?.locked_user_email,
              locked_user_name
                :
                item?.locked_user_name,
              locked_user_phone
                :
                item?.locked_user_phone,
            };
            rowsBearings.push(objShared);
          });

        let dataStructureDataGridBearings: any = {
          columns: getColumnData(selectedTab) && getColumnData(selectedTab),
          rows: rowsBearings && rowsBearings,
        };
        return dataStructureDataGridBearings && dataStructureDataGridBearings;

      case "Consumables":
        let rowsConsumables: any = [];
        let dataGridDataGeneratorConsumables: any =
          tableData &&
          tableData?.map((item: any, index: any) => {
            let objShared: any = {
              id: item?.id,
              sno: index + 1,
              name: item?.name,
              code: item?.code,
              description: item?.description,
              unit: item?.unit,
              reference: item?.reference,
              unit_cost: item?.unit_cost,
              commodity_name: item?.commodity_name,
              mapped_status: item?.mapped_status,
              purchase_item: item?.purchase_item,
              flag_reference: item?.flag_reference,
              currency_id: item?.currency_id,
              currency_code: item?.currency_code,

              lock_status
                : item?.lock_status,

              locked_on
                :
                item?.locked_on,
              locked_user
                :
                item?.locked_user,
              locked_user_email
                :
                item?.locked_user_email,
              locked_user_name
                :
                item?.locked_user_name,
              locked_user_phone
                :
                item?.locked_user_phone,
            };
            rowsConsumables.push(objShared);
          });

        let dataStructureDataGridConsumables: any = {
          columns: getColumnData(selectedTab) && getColumnData(selectedTab),
          rows: rowsConsumables && rowsConsumables,
        };
        return dataStructureDataGridConsumables && dataStructureDataGridConsumables;

      case "Electronic Child Components":
        let rowsElectronicsChild: any = [];
        let dataGridDataGeneratorElectricChild: any =
          tableData &&
          tableData?.map((item: any, index: any) => {
            let objShared: any = {
              id: item?.id,
              sno: index + 1,
              name: item?.name,
              length: item?.length,
              description: item?.description,
              width: item?.width,
              unit: item?.unit,
              component_type: item?.component_type,
              mapped_status: item?.mapped_status,
              reference: item?.reference,
              unit_cost: item?.unit_cost,
              commodity_name: item?.commodity_name,
              purchase_item: item?.purchase_item,
              flag_reference: item?.flag_reference,
              currency_id: item?.currency_id,
              currency_code: item?.currency_code,

              lock_status
                : item?.lock_status,

              locked_on
                :
                item?.locked_on,
              locked_user
                :
                item?.locked_user,
              locked_user_email
                :
                item?.locked_user_email,
              locked_user_name
                :
                item?.locked_user_name,
              locked_user_phone
                :
                item?.locked_user_phone,
            };
            rowsElectronicsChild.push(objShared);
          });

        let dataStructureDataGridElectricalChild: any = {
          columns: getColumnData(selectedTab) && getColumnData(selectedTab),
          rows: rowsElectronicsChild && rowsElectronicsChild,
        };
        return dataStructureDataGridElectricalChild && dataStructureDataGridElectricalChild;

      case "Mechanical Proprietory Parts":
        let rowsMEParts: any = [];
        let dataGridDataGeneratorMEParts: any =
          tableData &&
          tableData?.map((item: any, index: any) => {
            let objShared: any = {
              id: item?.id,
              sno: index + 1,
              name: item?.name,
              code: item?.code,
              length: item?.length,
              description: item?.description,
              width: item?.width,
              height: item?.height,
              weight: item?.weight,
              brand_name: item?.brand_name,
              mapped_status: item?.mapped_status,
              reference: item?.reference,
              unit_cost: item?.unit_cost,
              commodity_name: item?.commodity_name,
              purchase_item: item?.purchase_item,
              flag_reference: item?.flag_reference,
              currency_id: item?.currency_id,
              currency_code: item?.currency_code,
              unit: item?.unit,
              lock_status
                : item?.lock_status,

              locked_on
                :
                item?.locked_on,
              locked_user
                :
                item?.locked_user,
              locked_user_email
                :
                item?.locked_user_email,
              locked_user_name
                :
                item?.locked_user_name,
              locked_user_phone
                :
                item?.locked_user_phone,
            };
            rowsMEParts.push(objShared);
          });

        let dataStructureDataGridMEParts: any = {
          columns: getColumnData(selectedTab) && getColumnData(selectedTab),
          rows: rowsMEParts && rowsMEParts,
        };
        return dataStructureDataGridMEParts && dataStructureDataGridMEParts;
      case "Uncategorized":
        let rowsOthers: any = [];
        let dataGridDataGeneratorOthers: any =
          tableData &&
          tableData?.map((item: any, index: any) => {
            let objShared: any = {
              id: item?.id,
              sno: index + 1,
              name: item?.name,
              code: item?.code,
              length: item?.length,
              item_detail: item?.description,
              width: item?.width,
              height: item?.height,
              weight: item?.weight,
              commodity_name: item?.commodity_name,
              mapped_status: item?.mapped_status,
              reference: item?.reference,
              unit_cost: item?.unit_cost,
              place: item.place,
              purchase_item: item?.purchase_item,
              flag_reference: item?.flag_reference,
              currency_id: item?.currency_id,
              currency_code: item?.currency_code,
              unit: item?.unit,
              lock_status
                : item?.lock_status,

              locked_on
                :
                item?.locked_on,
              locked_user
                :
                item?.locked_user,
              locked_user_email
                :
                item?.locked_user_email,
              locked_user_name
                :
                item?.locked_user_name,
              locked_user_phone
                :
                item?.locked_user_phone,
            };
            rowsOthers.push(objShared);
          });

        let dataStructureDataGridOthers: any = {
          columns: getColumnData(selectedTab) && getColumnData(selectedTab),
          rows: rowsOthers && rowsOthers,
        };
        return dataStructureDataGridOthers && dataStructureDataGridOthers;

      case "Fasteners":
        let rowsFasteners: any = [];
        let dataGridDataGeneratorFasteners: any =
          tableData &&
          tableData?.map((item: any, index: any) => {
            let objShared: any = {
              id: item?.id,
              sno: index + 1,
              name: item?.name,
              code: item?.code,
              type: item?.type,
              grade: item?.grade,
              material: item?.material,
              m_size: item?.m_size,
              length: item?.length,
              thread_length: item?.thread_length,
              weight: item?.weight,
              commodity_name: item?.commodity_name,
              mapped_status: item?.mapped_status,
              reference: item?.reference,
              unit_cost: item?.unit_cost,
              place: item.place,
              purchase_item: item?.purchase_item,
              flag_reference: item?.flag_reference,
              currency_id: item?.currency_id,
              currency_code: item?.currency_code,
              unit: item?.unit,
              lock_status: item?.lock_status,
              locked_on: item?.locked_on,
              locked_user: item?.locked_user,
              locked_user_email: item?.locked_user_email,
              locked_user_name: item?.locked_user_name,
              locked_user_phone: item?.locked_user_phone,
            };
            rowsFasteners.push(objShared);
          });

        let dataStructureDataGridFasteners: any = {
          columns: getColumnData(selectedTab) && getColumnData(selectedTab),
          rows: rowsFasteners && rowsFasteners,
        };
        return dataStructureDataGridFasteners && dataStructureDataGridFasteners;

      default:
        return [];
    }
  };

  return (
    <Box sx={{ width: '100%', }}>

      {accordionLoading ? (
        <Box sx={{ padding: "0 1rem", width: "100%" }}>
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />

          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />

          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />

          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
          <Skeleton
            variant="rectangular"
            height="2rem"
            width="100%"
            sx={{ marginBottom: "1rem" }}
          />
        </Box>
      ) : (
        <>
          {/* <Box sx={{
							display: 'flex',
							alignItems: 'center',
							columnGap: '0.5rem',
							width: '100%',
							borderBottom: '1px solid',
						}} adl-scrollbar='true'
						adl-scrollbar-width='0.2'>
          {categoryList &&
            categoryList?.map((item: any, index: any) => {
              
              return (<>
              <Box
										key={index}
											sx={{
												color: '#007fff',
												height: '2rem',
												minHeight: '2.1rem',
												maxWidth: '100% !important',
												padding: '0',
												fontSize: '1rem',
												width: '100%',
												backgroundColor:
													item?.category == selectedTab
														? 'primary.main'
														: 'primary.light',
												cursor: 'pointer',
												display: 'flex',
												alignItems: 'center',
											}}>
											<Typography
												sx={{
													color:
														item?.category == selectedTab ? 'white' : '#007fff',
													height: '2rem',
													minHeight: '2.1rem',
													maxWidth: '100% !important',
													padding: '0 0.5rem',
													fontSize: '1rem',
													width: '100%',
													textAlign: 'center',
													display: 'flex',
													whiteSpace:'nowrap',
													alignItems: 'center',
													justifyContent: 'center',
													textTransform: 'capitalize',
												}}
												onClick={() => handleChange(item?.category)}>
												{item?.category.toLowerCase()} ({item?.count})
											</Typography>
										</Box>               
                </>
                );
              })}
          </Box> */}
          <Box sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: 'flex-start',
            margin: "0.3rem",
            columnGap: "0.5rem",
            width: '100%',
          }}>
            {useraction && useraction?.includes("C") && (
              <Box sx={{ width: "9rem", paddingRight: "1rem" }}>
                <Button
                  size="small"
                  startIcon={<AddIcon sx={{ marginRight: "-0.5rem" }} />}
                  sx={{
                    whiteSpace: "nowrap", zIndex: 1, '&:hover': {
                      backgroundColor: "#007fff", color: "#fff",
                    }
                  }}
                  onClick={() => fetchRawMaterial.open()}>
                  Fetch BOP
                </Button>
              </Box>
            )}
            {useraction && useraction?.includes("C") && (
              <Box sx={{ width: "9rem", paddingRight: "1rem" }}>
                <Button
                  size="small"
                  startIcon={<AddIcon sx={{ marginRight: "-0.5rem" }} />}
                  sx={{
                    whiteSpace: "nowrap", zIndex: 1, '&:hover': {
                      backgroundColor: "#007fff", color: "#fff",
                    }
                  }}
                  onClick={() => subRevisionEditAdd.open("add")}>
                  Fetch & Add BOP
                </Button>
              </Box>
            )}
          </Box>
          {tableData && selectedTab && categoryName !== "Electrical & Electronics Proprietary Parts" && getColumnData(selectedTab)?.length > 0 ? (<Box
            sx={{
              height: { lg: '82vh', xl: '85vh' },
              // height: selectedTab !== "Electrical & Electronics Proprietary Parts" ? 400 : "100%",
              width: '100%',
              "& .super-app-theme--cell": {
                backgroundColor: "#ddefff !important",
                textAlign: "center !important",
              },
            }}>
            <DataGrid
              headerHeight={42}
              rowHeight={36}
              {...getRowData(selectedTab)}
              loading={tableDataLoading}
              // checkboxSelection
              // disableRowSelectionOnClick
              components={{
                Toolbar: () => {
                  return CustomToolbar();
                },
              }}
              sx={{
                "& ::-webkit-scrollbar": {
                  width: "0.3rem",
                  height: "0.3rem",
                },
                "& ::-webkit-scrollbar-thumb": {
                  // background: $thumb-color;
                  backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                  borderRadius: "10px",
                },
                "& ::- webkit-scrollbar-thumb: hover ": {
                  // width: "4px",
                  backgroundColor: "#045DE9 !important",
                },
                "::-webkit-scrollbar-track": {
                  backgroundColor: "#e1e1f3 !important",
                },

                "&.MuiDataGrid-root": {
                  border: "none",
                },

                "&.MuiDataGrid-root .MuiDataGrid-cell": {
                  borderBottomColor: "primary.light",
                },
              }}
            />
          </Box>
          ) : tableData && selectedTab && categoryName == "Electrical & Electronics Proprietary Parts" ? (
            <Box
              sx={{
                height: selectedTab !== "Electrical & Electronics Proprietary Parts" ? { lg: '78vh', xl: '79vh' } : " ",
                width: { lg: "80%", xl: '85%' },
                "& .super-app-theme--cell": {
                  backgroundColor: "#ddefff !important",
                  textAlign: "center !important",
                },
              }}>
              <Box sx={{
                display: 'flex',
                alignItems: 'center',
                columnGap: '0.5rem',
                width: '100%',
                borderBottom: '1px solid',
              }} adl-scrollbar='true'
                adl-scrollbar-width='0.2'>
                {electricalCategories &&
                  selectedTab &&
                  electricalCategories?.map((item: any, index: any) => {
                    return (<>
                      <Box
                        key={index}
                        sx={{
                          color: '#007fff',
                          height: '2rem',
                          minHeight: '2.1rem',
                          maxWidth: '100% !important',
                          padding: '0',
                          fontSize: '1rem',
                          width: '100%',
                          backgroundColor:
                            item?.module_type == selectedModule
                              ? 'primary.main'
                              : 'primary.light',
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          zIndex: 1,
                        }}>
                        <Typography
                          sx={{
                            color:
                              item?.module_type == selectedModule ? 'white' : '#007fff',
                            height: '2rem',
                            minHeight: '2.1rem',
                            maxWidth: '100% !important',
                            padding: '0 0.5rem',
                            fontSize: '1rem',
                            width: '100%',
                            textAlign: 'center',
                            display: 'flex',
                            whiteSpace: 'nowrap',
                            alignItems: 'center',
                            justifyContent: 'center',
                            textTransform: 'capitalize',
                          }}
                          onClick={() => handleChangeElectrical(item?.module_type)}>
                          {item?.module_type.toLowerCase()} ({item?.count})
                        </Typography>
                      </Box>
                    </>
                    );
                  })}
              </Box>
              <Box
                sx={{
                  // marginTop: '2.5rem',
                  height: { lg: '73vh', xl: '80vh' },
                  width: { lg: "76.5vw", xl: '81.5vw' },
                  "& .super-app-theme--cell": {
                    backgroundColor: "#ddefff !important",
                    textAlign: "center !important",
                  },
                }}>
                {selectedModule && getColumnDataElectrical(selectedModule)?.length > 0 && (
                  <DataGrid
                    headerHeight={42}
                    rowHeight={36}
                    {...getRowDataElectrical(selectedModule)}
                    loading={subElectricalLoadings}
                    // checkboxSelection
                    // disableRowSelectionOnClick
                    components={{
                      Toolbar: () => {
                        return CustomToolbarElectrical();
                      },
                    }}
                    sx={{
                      "& ::-webkit-scrollbar": {
                        width: "0.3rem",
                        height: "0.3rem",
                      },
                      "& ::-webkit-scrollbar-thumb": {
                        // background: $thumb-color;
                        backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                        borderRadius: "10px",
                      },
                      "& ::- webkit-scrollbar-thumb: hover ": {
                        // width: "4px",
                        backgroundColor: "#045DE9 !important",
                      },
                      "::-webkit-scrollbar-track": {
                        backgroundColor: "#e1e1f3 !important",
                      },

                      "&.MuiDataGrid-root": {
                        border: "none",
                      },

                      "&.MuiDataGrid-root .MuiDataGrid-cell": {
                        borderBottomColor: "primary.light",
                      },
                    }}
                  />
                )}
              </Box>

            </Box>
          ) : <Box sx={{ width: '99%', height: '90%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Typography sx={{ fontSize: '1rem' }}>Select Category</Typography>
          </Box>}
        </>
      )}
      {subRevisionEdit.isOpen && (
        <BoughtoutEditModal
          isOpen={subRevisionEdit.isOpen}
          onCloseModal={subRevisionEdit.close}
          actions={subRevisionEdit.propsId}
          expanded={expanded}
          getMaterialData={getMaterialData}
        />
      )}
      {subRevisionEditAdd.isOpen && (
        <CreateBoughtOutDatabaseModal
          isOpen={subRevisionEditAdd.isOpen}
          onCloseModal={subRevisionEditAdd.close}
          actions={subRevisionEditAdd.propsId}
          expanded={expanded}
          getMaterialData={getMaterialData}
          categoryList={addAllCategory}
          getCategoryData={getCategoryListAll}
          getCategoryList={getCategoryListAll}
          handleChangeElectrical={handleChangeElectrical}
          getElectricalCategories={getElectricalCategories}
        />
      )}
      {fetchRawMaterial.isOpen && (
        <FetchBoughtOutModal
          isOpen={fetchRawMaterial.isOpen}
          onCloseModal={fetchRawMaterial.close}
          categorydata={categoryList && categoryList}
          electricalCategories={electricalCategories}
        />
      )}
      {infoRawMaterialModal.isOpen && (
        <InfoBoughtoutrevisionModal
          isOpen={infoRawMaterialModal.isOpen}
          onCloseModal={infoRawMaterialModal.close}
          itemId={infoRawMaterialModal.propsId}
        />
      )}
      {graphModal.isOpen && (
        <BoughtoutGraph
          isOpen={graphModal.isOpen}
          onCloseModal={graphModal.close}
          rowItems={graphModal.propsId}
        />
      )}
      {bopMoveModal.isOpen && (
        <RMBOPmoveModal
          isOpen={bopMoveModal.isOpen}
          onCloseModal={bopMoveModal.close}
          actions={bopMoveModal.propsId}
          expanded={expanded}
          getMaterialData={getMaterialData}
          categoryList={categoryList}
          getCategoryData={getCategoryList}
          categoryListMaterial={categoryListMaterial}
        />
      )}
      {lockmodal.isOpen && (
        <LockStatusInfoModal
          isOpen={lockmodal.isOpen}
          onCloseModal={lockmodal.close}
          Item={LockData}
        />
      )}
    </Box>
  );
}
