// react
import React, { useEffect, useMemo, useState } from "react";
import { API } from "../api-services";
import {
  PieChart,
  Pie,
  Tooltip,
  ResponsiveContainer,
  Cell,
  Legend,
} from "recharts";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import SubBouncingLoader from "../ui-reusable-component/SubBouncingLoader";
import styles from "./CostingModule.module.scss";
import TableContainer from "@mui/material/TableContainer";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import NoDocuments from ".././Assets/images/svgs/NoData.svg";
import { ADMIN } from "../Redux/Services/admin.service";
import { useHistory, useParams } from "react-router-dom";
import { isArray, isNull, isObject, isUndefined, values } from "lodash";
import createPlotlyComponent from "react-plotly.js/factory";
import Plotly from "plotly.js";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import CostChart from "./FeatureLandingPage/CostChart";
// import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { useRef } from "react";
import { typeOf } from "react-is";
import ErrorToastModal from "./ErrorToastModal";
import SkeletonLoader from "../ComponentUI/SkeletonLoader/SkeletonLoader";
import Skeleton from '@mui/material/Skeleton';
import CostViewChart from "./CostingView/CostViewChart";
import EqualizerIcon from '@mui/icons-material/Equalizer';
import { useBaseModal } from "../ComponentUI/SearchPage/useBaseModal";
import { LoadingButton } from "@mui/lab";
import { CostingScenarioChartModal } from "./CostingScenario/CostingScenarioChartModal";

import DependsScenariographIcon from "../Assets/images/svgs/chartdependent.svg"
import IndependsScenariographIcon from "../Assets/images/svgs/chartindependent.svg";
// vendors
const Plot = createPlotlyComponent(Plotly);
// services
// utils
// constants
// factories
// core
// redux
// styles
// interfaces and types
// lazy
// ----------------------------------------------------------------------------------
// initializations
// ----------------------------------------------------------------------------------
interface CostingModuleProps { }
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));
const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem", display: "none" }} />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));
const CostingModule: React.FC<CostingModuleProps> = (props) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [CostingData, setCostingData] = useState<any>();
  const [ProcessData, setProcessData] = useState<any>();
  const [RawMaterailData, setRawMaterailData] = useState<any>();
  const [OverHeadData, setOverHeadData] = useState<any>();
  const [Loader, setLoader] = useState<any>(false);
  const { vault, treeabbr, topVault, projectId } = useParams<any>();
  const [SubPartName, setSubPartName] = useState<any>();
  const [GraphBarData, setGraphBarData] = useState<any>([]);
  const [StackGraph, setSatckGraph] = useState<any>({});
  const [SelecteGraphiId, setSelectedGraphId] = useState<any>()
  const refWidth = useRef(null);
  const history = useHistory()
  const [GraphStatus,setGraphStatus]=useState<any>()
  const ScenarioChartModal = useBaseModal();
  // ----------------------------------------------------------------------------------
  // selectors
  // ----------------------------------------------------------------------------------
  // helpers
  const [height, setHeight] = useState(0);
  const [width, setWidth] = useState(320);
  useEffect(() => {
    if (width !== undefined) {
      //@ts-ignore
      setHeight(refWidth?.current?.offsetHeight);
      //@ts-ignore
      setWidth(refWidth?.current?.offsetWidth);
      //@ts-ignore
    }
  }, [width, vault]);
  const toggleTopvault = () => {
    if (treeabbr == "product") {
      return { top_vault: topVault };
    } else {
      return { vault: vault };
    }
  };
  useEffect(() => {
    API.get(
      "/customer/costing_conversion/",
      { get_conversion_factor: true, module: "Costing" },
      0
    ).then((res: any) => {
      sessionStorage.setItem("currency", res.data[0]?.current_currency);
      sessionStorage.setItem(
        "actionKey",
        res.data[0]?.is_manual ? "mannual" : "auto"
      )
      if (res.data[0]?.is_manual) {
        sessionStorage.setItem(
          "currencyrate_mannual",
          res.data[0]?.conversion_factor
        );
      } else {
        sessionStorage.setItem(
          "currencyrate",
          (1 / res.data[0]?.conversion_factor).toString()
        );
      }
    });
  }, []);

  // console.log(StackGraph && StackGraph?.cost_type, "StackGraphStackGraph")
  const toggleTopvaultGraph = () => {
    if (treeabbr == "product") {
      return { top_vault: topVault };
    } else {
      return { vault_id: vault };
    }
  };
  useEffect(() => {


    if (vault !== "0") {
      setLoader(true);
      API.get(
        "/customer/view_data/",
        {
          costing: true,
          ...toggleTopvault(),
        },
        0
      )
        .then((res: any) => {
          setLoader(false);
          setCostingData(res?.data);
          setProcessData(res?.data?.process_material);
          setRawMaterailData(res?.data?.rm_material);

          setOverHeadData(res?.data?.overhead_material);
        })
        .catch((err: any) => {
          setLoader(false);

          // console.log(err.response.data, "resppperroer")
          // ADMIN.toast.throwError(err, "Server Error");
        });
    }
  }, [vault]);

  // console.log(vault, typeof vault, " typeOfvault")
  React.useEffect(() => {
    setSatckGraph([]);
    setLoader(true);
    API.get(`/customer/costing_module/`, { ...toggleTopvaultGraph(), get_graph_data: true }, 0)
      .then((res: any) => {
        if (res.data?.length > 0) {
          setLoader(false);
          setSatckGraph(res.data[0]);
        } else {
          setLoader(false);
          setSatckGraph([]);
        }
      })
      .catch((err: any) => {
        setLoader(false);
      });
  }, [vault]);
  // useEffect(() => {
  //   setLoader(true);
  //   if (vault != 0) {
  //     API.get("customer/view_data/", {
  //       costing: true,
  //       ...toggleTopvault(),
  //       cost_graph: true,
  //     }, 0)
  //       .then((res: any) => {
  //         setGraphBarData(res.data);
  //       })
  //       .catch((err: any) => {
  //         setGraphBarData([]);
  //         ADMIN.toast.throwError(err, "Server Error");
  //       });
  //   }
  // }, [vault]);

  useEffect(() => {

    if (vault != 0) {
      setLoader(true);
      API.get(
        "/customer/view_data/",
        {
          costing: true,
          sub_partname: true,
          ...toggleTopvault(),
        },
        0
      )
        .then((res: any) => {
          setSubPartName(res?.data);
          // setCostingData(res?.data);
          // setProcessData(res?.data?.process_material);
          // setRawMaterailData(res?.data?.rm_material);
          setLoader(false);
          // setOverHeadData(res?.data?.overhead_material);
        })
        .catch((err: any) => {
          setLoader(false);
          // ADMIN.toast.throwError(err, "Server Error");
        });
    }
  }, [vault]);


  const ridirectipartlevel = (keys: any) => {
    var partName = "" + keys + ""
    API.get("/customer/costing_module/", { get_children_estimation: true, vault_id: vault }).then((res: any) => {

      var filterData = res.data.filter((item: any, index: any) => item.part_no == partName)

      var dataNodes = filterData[0]?.ancestors;
      // console.log(dataNodes, "dcdikcidcjidvijdijv")

      window.open(`/#/view/${projectId}/${topVault}/${filterData[0]?.vault}/${filterData[0].abbreviation}/costing`, "_blank")
      var margedata = dataNodes.concat(topVault)

      // console.log(margedata, "margedatamargedata")
      sessionStorage.setItem("node", JSON.stringify(dataNodes))

      //  onClick={() =>
      // setRoute(
      //   item.vault,
      //   item.abbreviation,
      //   item.ancestors
      // )
      // }

      // var filterdata=res.data.filter((item)=>{
    })


  }



  const ScenarioChartModalHandler = (e: any, key: any) => {


    var selectedId = CostingData && CostingData?.child_cost_object_ids[key]

    var Status=CostingData && CostingData?. scenario_type[key]
    setGraphStatus(Status)
    setSelectedGraphId({ vault: selectedId })

    e?.stopPropagation()
    ScenarioChartModal.open()
  }

  const ChildPartCostPercentage = (values: any) => {
    var percentageChildCost = (values / CostingData?.total_cost) * 100;
    return percentageChildCost.toFixed(1);
  };
  const renderGrouph = (key: any) => {


      
    var data = CostingData && CostingData?.child_cost_scenario[key]
    if (data == true) {
      return <>
      {
        CostingData && CostingData?. scenario_type[key]? <img src={IndependsScenariographIcon} title="Scenario Independent Chart" style={{ cursor: 'pointer', height: "2rem" }} onClick={(e: any) => ScenarioChartModalHandler(e, key)} />:

        <img src={DependsScenariographIcon} title="Scenario dependent Chart" style={{ cursor: 'pointer', height: "2rem" }} onClick={(e: any) => ScenarioChartModalHandler(e, key)} />
      }
       
      </>


    }

  }
  const ChilPartCostData = () => {
    return (
      CostingData &&
      CostingData?.children_cost_info &&
      Object.entries(CostingData?.children_cost_info).map(
        ([key, value]: any) => {
          // console.log(key, "grknrkjgkjrgnrgrkjgrj")
          return (
            <>
              <TableRow onClick={() => ridirectipartlevel(key)} style={{ cursor: "pointer" }}>
                <TableCell colSpan={2}
                  sx={{ width: "60%", borderBottom: "none" }}
                  className={styles.tbodycellp}
                >
                  <Typography sx={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem', textDecoration: "underline", fontSize: '0.87rem' }}>{renderGrouph(key)} {key}  </Typography>
                </TableCell>

                <TableCell
                  sx={{ borderBottom: "none", fontSize: '0.87rem', padding: '0.2rem 1rem 0.2rem 0.5rem', textAlign: 'right' }}
                // className={styles.tbodycellRight}
                >
                  {value.toFixed(1)}
                </TableCell>
                {/* @ts-ignore */}
                <TableCell
                  sx={{ borderBottom: "none", fontSize: '0.87rem' }}
                  className={styles.tbodycellRight}
                >
                  {ChildPartCostPercentage(value)}%
                </TableCell>
              </TableRow>
            </>
          );
        }
      )
    );
  };
  // console.log(CostingData, "CostingData");
  //  @ts-ignore
  //   const material_cost: number = Number(
  //     (CostingData?.material_cost / CostingData?.total_cost) * 100
  //   );
  //   //  @ts-ignore
  //   const Process_cost: number = Number(
  //     (CostingData?.process_cost / CostingData?.total_cost) * 100
  //   );
  //   //  @ts-ignore
  //   const OverHead: number = Number(
  //     (CostingData?.overhead / CostingData?.total_cost) * 100
  //   );
  //   const rawmaterial: any = CostingData?.raw_material + CostingData?.bop;
  //   const ProcessCost: any =
  //     CostingData?.labour +
  //     CostingData?.bop +
  //     CostingData?.setup +
  //     CostingData?.tool;
  const material_cost = CostingData && CostingData?.material_cost_per;
  const Process_cost = CostingData && CostingData?.process_cost_per;
  const OverHead = CostingData && CostingData?.overhead_per;
  var ChildCost = CostingData && CostingData?.child_cost;
  // const totalCostPer=CostingData && CostingData?.totalCost
  const costData = [
    {
      name: "Materal Cost",
      value: material_cost,
      cost: CostingData && CostingData?.material_cost,
    },
    {
      name: "Over head Cost",
      value: OverHead,
      cost: CostingData && CostingData?.overhead,
    },
    {
      name: "Process Cost",
      value: Process_cost,
      cost: CostingData && CostingData?.process_cost,
    },
  ];
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  var ProcessCostData = (ProcessKey: any) => {
    return (
      SubPartName &&
      SubPartName["process"][ProcessKey].map((itemdata: any, index: any) => {
        return (
          <TableRow>
            <TableCell
              sx={{ padding: "0.1rem 0.5rem", borderBottomColor: "#fff" }}
            >
              {itemdata?.alias}
            </TableCell>
          </TableRow>
        );
      })
    );
  };
  var ProcessCostDataValue = (ProcessKey: any) => {
    return (
      SubPartName &&
      SubPartName["process"][ProcessKey].map((itemdata: any, index: any) => {
        return (
          <TableRow>
            <TableCell
              sx={{ padding: "0.1rem 0.5rem", borderBottomColor: "#fff" }}
            >
              {itemdata?.cost}
            </TableCell>
          </TableRow>
        );
      })
    );
  };
  const ProcessCostPer = (ProcessKey: any) => {
    return (
      SubPartName &&
      SubPartName["Process"][ProcessKey].map((itemdata: any, index: any) => {
        var NumberData = parseInt(itemdata.cost);
        var Costpercentage = (NumberData / CostingData?.total_cost) * 100;
        return (
          <TableRow>
            <TableCell
              sx={{ padding: "0.1rem 0.5rem", borderBottomColor: "#fff" }}
            >
              {Costpercentage.toFixed(2)}%
            </TableCell>
          </TableRow>
        );
      })
    );
  };
  const MaterailCostPer = (MaterailKey: any) => {
    return (
      SubPartName &&
      SubPartName["material"][MaterailKey].map((itemdata: any, index: any) => {
        var NumberData = parseInt(itemdata.cost);
        var Costpercentage = (NumberData / CostingData?.total_cost) * 100;
        return (
          <TableRow>
            <TableCell
              sx={{ padding: "0.1rem 0.5rem", borderBottomColor: "#fff" }}
            >
              {Costpercentage.toFixed(2)}%
            </TableCell>
          </TableRow>
        );
      })
    );
  };
  const OverHeadCostPer = (OverHeadKey: any) => {
    return (
      SubPartName &&
      SubPartName["overhead"][OverHeadKey].map((itemdata: any, index: any) => {
        var NumberData = parseInt(itemdata.cost);
        var Costpercentage = (NumberData / CostingData?.total_cost) * 100;
        return (
          <TableRow>
            <TableCell
              sx={{
                padding: "0.1rem 0.5rem",
                textAlign: "right",
                fontSize: "12px",
                width: "100%",
                borderBottomColor: "#fff",
              }}
            >
              {Costpercentage.toFixed(2)}%
            </TableCell>
          </TableRow>
        );
      })
    );
  };
  var overHeadCostData = (ProcessKey: any) => {
    return (
      SubPartName &&
      SubPartName["overhead"][ProcessKey].map((itemdata: any, index: any) => {
        return (
          <TableRow>
            <TableCell
              sx={{
                padding: "0.1rem 0.5rem",
                fontSize: "12px",
                borderBottomColor: "#fff",
              }}
            >
              {itemdata?.alias}
            </TableCell>
          </TableRow>
        );
      })
    );
  };
  var overHeadCostDataValue = (ProcessKey: any) => {
    return (
      SubPartName &&
      SubPartName["overhead"][ProcessKey].map((itemdata: any, index: any) => {
        return (
          <TableRow>
            <TableCell
              sx={{
                padding: "0.1rem 0.5rem",
                textAlign: "right",
                fontSize: "12px",
                width: "100%",
                borderBottomColor: "#fff",
              }}
            >
              {itemdata?.cost}
            </TableCell>
          </TableRow>
        );
      })
    );
  };
  const MaterailConatiner = (item: any) => {
    return (
      SubPartName &&
      SubPartName["material"][item].map((itemdata: any, index: any) => {
        return (
          <TableRow>
            <TableCell
              sx={{ padding: "0.1rem 0.5rem", borderBottomColor: "#fff" }}
            >
              {itemdata?.alias}
            </TableCell>
          </TableRow>
        );
      })
    );
  };
  const RawMaterailRow = () => {
    return (
      SubPartName &&
      SubPartName["material"]["raw_material"].map(
        (itemdata: any, index: any) => {
          return (
            <TableRow>
              <TableCell
                sx={{ padding: "0.1rem 0.5rem", borderBottomColor: "#fff" }}
              >
                {itemdata?.alias}
              </TableCell>
            </TableRow>
          );
        }
      )
    );
  };
  const RowMaterailConatinerValue = () => {
    return (
      SubPartName &&
      SubPartName["material"]["raw_material"].map(
        (itemdata: any, index: any) => {
          return (
            <TableRow>
              <TableCell
                sx={{ padding: "0.1rem 0.5rem", borderBottomColor: "#fff" }}
              >
                {itemdata?.cost}
              </TableCell>
            </TableRow>
          );
        }
      )
    );
  };
  const RowMaterailConatiner = () => {
    return (
      SubPartName &&
      SubPartName["material"]["raw_material"].map(
        (itemdata: any, index: any) => {
          var NumberData = parseInt(itemdata.cost);
          var Costpercentage = (itemdata?.cost / CostingData?.total_cost) * 100;
          return (
            <TableRow>
              <TableCell
                sx={{
                  padding: "0.1rem 0.5rem",
                  textAlign: "right",
                  fontSize: "12px",
                  width: "100%",
                  borderBottomColor: "#fff",
                }}
              >
                {Costpercentage?.toFixed(2)}%
              </TableCell>
            </TableRow>
          );
        }
      )
    );
  };

  // console.log(CostingData && CostingData?.cost_type, "CostingDataCostingDataCostingData")
  const RawMaterailCostPer = (rawCost: any) => {
    var NumberData = parseInt(rawCost);
    var Costpercentage = (rawCost / CostingData?.total_cost) * 100;
    return Costpercentage?.toFixed();
    // return (
    //   SubPartName &&  //   SubPartName["material"]["raw_materail"].map(
    //     (itemdata: any, index: any) => {
    //       var NumberData = parseInt(itemdata.cost)
    //       var Costpercentage = (NumberData / CostingData?.total_cost) * 100
    //       return Costpercentage.toFixed(2);
    //     }
    //   )
    // );
  };
  // ----------------------------------------------------------------------------------
  // memorized
  // ----------------------------------------------------------------------------------
  // jsx

  return (
    <div id="graph" ref={refWidth}>
      {/* <ErrorToastModal
      Masseges={}
     isOpen={}
    handleOnClose={}
      /> */}
      {/* scenario_type */}

      {ScenarioChartModal.isOpen &&
        <CostingScenarioChartModal
          isOpen={ScenarioChartModal.isOpen}
          onCloseModal={ScenarioChartModal.close}
          query={SelecteGraphiId}
          chartType={GraphStatus}

        />}
      {(
        <>
          {Loader ? (
            <Box sx={{ width: "100%", padding: '0 0.5rem' }}>
              <Box sx={{ margin: '0 auto', width: '100%' }}>
                <Skeleton sx={{ height: "20rem", width: '12rem', borderRadius: '50%', margin: '-3rem auto' }} />
              </Box>
              <Skeleton />
              <Skeleton animation="wave" />
              <Skeleton animation={false} />
            </Box>

          ) : (

            (CostingData && Object.keys(CostingData)?.length > 0 &&
              <div className={styles.CostingModuleSection}>
                {StackGraph && StackGraph?.cost_type == "DC" &&
                  <Box>
                    <CostChart costEstimation={StackGraph && StackGraph} />
                  </Box>}
                <Box className={styles.costTable}>
                  <Table>
                    <TableRow sx={{ backgroundColor: "primary.light" }}>
                      <TableCell
                        colSpan={3}
                        sx={{ color: "primary.main !important" }}
                        className={styles.tbodycellp}
                      >
                        Total Cost({CostingData?.current_currency})
                      </TableCell>
                      <TableCell
                        sx={{ color: "primary.main !important" }}
                        className={styles.tbodycellRight}
                      >
                        {CostingData?.total_cost?.toFixed(1)}
                      </TableCell>
                    </TableRow>
                    <TableRow sx={{ backgroundColor: "primary.light" }}>
                      <TableCell
                        colSpan={3}
                        sx={{ color: "primary.main !important" }}
                        className={styles.tbodycellp}
                      >
                        Part Cost Per kg
                      </TableCell>
                      <TableCell
                        sx={{ color: "primary.main !important" }}
                        className={styles.tbodycellRight}
                      >
                        {CostingData?.cost_per_kg?.toFixed(1)}
                      </TableCell>
                    </TableRow>
                  </Table>
                  <TableContainer
                    className={styles.CostSecTable}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3"
                  >
                    <Table
                      sx={{ minWidth: 310, fontSize: "0.9rem" }}
                      aria-label="simple table"
                    >
                      <TableHead sx={{ position: 'sticky', top: '0', zIndex: '1' }}>
                        <TableRow sx={{ backgroundColor: "primary.main" }}>
                          <TableCell
                            sx={{
                              color: "#FFFFFF !important",
                              padding: "0rem 0.5rem",
                              fontSize: "12px",
                              width: "50%",
                            }}
                          >
                            Drivers
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "#FFFFFF !important",
                              padding: "0rem 0.5rem",
                              textAlign: "right",
                              fontSize: "12px",
                              width: "15%",
                            }}
                          >
                            Qty.
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "#FFFFFF !important",
                              padding: "0rem 0.5rem",
                              textAlign: "right",
                              fontSize: "12px",
                              width: "20%",
                            }}
                          >

                            {/* @ts-ignore */}
                            Cost({CostingData?.current_currency})
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "#FFFFFF !important",
                              padding: "0 0.5rem",
                              textAlign: "right",
                              fontSize: "12px",
                              width: "15%",
                            }}
                          >
                            %
                          </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        <TableRow>
                          <TableCell
                            sx={{
                              color: "primary.main",
                              padding: '0.2rem 0.5rem',
                              fontSize: '0.87rem',
                              borderBottom: 'none',
                              width: '50%',
                            }}
                          >
                            Material
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "primary.main",
                              padding: '0.2rem 0.5rem',
                              fontSize: '0.87rem',
                              textAlign: 'right',
                              borderBottom: 'none',
                              width: '15%',
                            }}
                          >
                            {SubPartName?.quantity?.material_qty}
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "primary.main",
                              padding: '0.2rem 1rem 0.2rem 0.5rem',
                              fontSize: '0.87rem',
                              textAlign: 'right',
                              borderBottom: 'none',
                              width: '20%',
                            }}

                          >
                            {CostingData?.material_cost?.toFixed(1)}
                          </TableCell>
                          <TableCell
                            sx={{
                              color: "primary.main",
                              padding: '0 0.5rem',
                              fontSize: '0.87rem',
                              textAlign: 'right',
                              borderBottom: 'none',
                              width: '15%',
                            }}
                          >
                            {CostingData?.material_cost_per?.toFixed(1)}%
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            colSpan={4}
                            sx={{
                              color: "primary.main",
                              borderBottom: "none",
                              padding: '0',
                              paddingLeft: '5% !important',
                            }}
                            className={styles.tbodycell}
                          >

                            {CostingData?.raw_material == 0 ? "" :
                              <Accordion
                                expanded={expanded == "panel1"}
                                onChange={handleChange("panel1")}
                                sx={{
                                  // width: "100%",
                                  margin: "0rem !important",
                                  "&.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiAccordion-root.MuiPaper-root.MuiAccordion-root":
                                  {
                                    borderColor: "#FFFFFF",
                                    // color: $palette-primary-main;
                                    borderRight: "none",
                                    borderLeft: "none",
                                  },
                                }}
                              >


                                <AccordionSummary
                                  sx={{
                                    minHeight: "24px",
                                    padding: "0rem",
                                    margin: "0rem !important",
                                    width: "100%",
                                  }}
                                  aria-controls="panel2d-content"
                                  id="panel2d-header"
                                >
                                  <Table>
                                    <TableRow sx={{ width: "100%" }}>
                                      <TableCell
                                        sx={{
                                          // width: "50%",
                                          padding: "0 0.5rem",
                                          fontSize: "12px",
                                          borderBottom: "none",
                                        }}
                                      >
                                        Raw Material
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          padding: "0 0.5rem 0 0",
                                          textAlign: "right",
                                          width: "15%",
                                          fontSize: "12px",
                                          borderBottom: "none",
                                        }}
                                      >
                                        {SubPartName?.quantity?.raw_material_qty}
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          padding: "0 0.5rem 0 0",
                                          textAlign: "right",
                                          width: "20%",
                                          fontSize: "12px",
                                          borderBottom: "none",
                                        }}
                                      >
                                        {CostingData?.raw_material?.toFixed(1)}
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          padding: "0 0.5rem 0 0",
                                          textAlign: "right",
                                          width: "17%",
                                          fontSize: "12px",
                                          borderBottom: "none",
                                        }}
                                      >
                                        {CostingData?.raw_material_per?.toFixed(1)}%
                                      </TableCell>
                                    </TableRow>
                                  </Table>
                                </AccordionSummary>

                                <AccordionDetails>
                                  <Table sx={{ width: "100%" }}>
                                    <TableBody>
                                      {SubPartName &&
                                        Object.keys(SubPartName["material"])
                                          ?.length > 0 &&
                                        SubPartName["material"]["raw_material"]
                                          ?.length > 0 &&
                                        SubPartName["material"]["raw_material"].map(
                                          (rowItem: any, index: any) => {
                                            return (
                                              <>
                                                <TableRow>
                                                  <TableCell
                                                    sx={{
                                                      // width: "50%",
                                                      padding: "0 0.5rem",
                                                      paddingLeft: "5%",
                                                      borderBottom: "none",
                                                      fontSize: '0.87rem',
                                                    }}
                                                  >
                                                    {rowItem.alias}
                                                  </TableCell>
                                                  <TableCell
                                                    sx={{
                                                      padding: "0 0.5rem 0 0",
                                                      textAlign: "right",
                                                      width: "15%",
                                                      fontSize: "12px",
                                                      borderBottom: "none",
                                                    }}
                                                  >
                                                    {rowItem?.qty}
                                                  </TableCell>
                                                  <TableCell
                                                    sx={{
                                                      width: "20%",
                                                      padding: "0 0.5rem 0 0",
                                                      borderBottom: "none",
                                                      textAlign: "right",
                                                      fontSize: "12px",
                                                    }}
                                                  >
                                                    {rowItem?.cost?.toFixed(1)}
                                                  </TableCell>
                                                  {/* @ts-ignore */}
                                                  <TableCell
                                                    sx={{
                                                      width: "17%",
                                                      padding: "0 0.5rem 0 0",
                                                      borderBottom: "none",
                                                      textAlign: "right",
                                                      fontSize: "12px",
                                                    }}
                                                  >

                                                    {((rowItem?.cost / CostingData?.total_cost) * 100)?.toFixed(1)} %
                                                    {/* {RawMaterailCostPer(
                                                  rowItem.cost
                                                )}
                                                % */}
                                                  </TableCell>
                                                </TableRow>
                                              </>
                                            );
                                          }
                                        )}
                                      {SubPartName &&
                                        Object.keys(SubPartName["process"])
                                          .length == 0 && (
                                          <TableRow>
                                            <TableCell
                                              colSpan={4}
                                              sx={{
                                                padding: "0 0.5rem",
                                                textAlign: "center",
                                              }}
                                            >
                                              No Data
                                            </TableCell>
                                          </TableRow>
                                        )}
                                    </TableBody>
                                  </Table>
                                </AccordionDetails>
                              </Accordion>
                            }
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            colSpan={4}
                            sx={{ color: "primary.main", borderBottom: "none", padding: '0 !important', paddingLeft: '5% !important', }}
                            className={styles.tbodycell}
                          >

                            {CostingData?.bop == 0 ? "" :
                              <Accordion
                                expanded={expanded === "panel7"}
                                onChange={handleChange("panel7")}
                                sx={{
                                  // width: "100%",
                                  margin: "0rem !important",
                                  "&.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiAccordion-root.MuiPaper-root.MuiAccordion-root":
                                  {
                                    borderColor: "#FFFFFF",
                                    // color: $palette-primary-main;
                                    borderRight: "none",
                                    borderLeft: "none",
                                  },
                                }}
                              >
                                <AccordionSummary
                                  sx={{
                                    minHeight: "24px",
                                    padding: "0rem",
                                    margin: "0rem !important",
                                    width: "100%",
                                  }}
                                  aria-controls="panel2d-content"
                                  id="panel2d-header"
                                >
                                  <Table sx={{ width: "100%" }}>
                                    <TableRow>
                                      <TableCell
                                        sx={{
                                          padding: "0 0.5rem",
                                          fontSize: "12px",
                                          display: "flex",
                                          borderBottom: "none",
                                          // width: "50%",
                                        }}
                                      >
                                        Bought Out Parts
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          padding: "0 0.5rem 0 0",
                                          textAlign: "right",
                                          width: "15%",
                                          fontSize: "12px",
                                          borderBottom: "none",
                                        }}
                                      >
                                        {SubPartName?.quantity?.bop_qty}
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          padding: "0 0.5rem 0 0",
                                          textAlign: "right",
                                          width: "20%",
                                          fontSize: "12px",
                                          borderBottom: "none",
                                        }}
                                      >
                                        {CostingData?.bop?.toFixed(1)}
                                      </TableCell>
                                      <TableCell
                                        sx={{
                                          padding: "0 0.5rem 0 0",
                                          textAlign: "right",
                                          width: "17%",
                                          fontSize: "12px",
                                          borderBottom: "none",
                                        }}
                                      >
                                        {CostingData?.bop_per?.toFixed(1)}%
                                      </TableCell>
                                    </TableRow>
                                  </Table>
                                </AccordionSummary>
                                <AccordionDetails>
                                  <Table sx={{ width: "100%" }}>
                                    <TableBody>
                                      {SubPartName &&
                                        Object.keys(SubPartName["material"])
                                          ?.length > 0 &&
                                        SubPartName["material"]["purchase_item"]
                                          ?.length > 0 &&
                                        SubPartName["material"][
                                          "purchase_item"
                                        ].map((rowItem: any, index: any) => {
                                          return (
                                            <>
                                              <TableRow>
                                                <TableCell
                                                  sx={{
                                                    // width: "40%",
                                                    padding: "0 0.5rem",
                                                    borderBottom: "none",
                                                    fontSize: '0.87rem',
                                                  }}
                                                >
                                                  {rowItem.alias}
                                                </TableCell>
                                                <TableCell
                                                  sx={{
                                                    width: "20%",
                                                    padding: "0 0.5rem 0 0",
                                                    borderBottom: "none",
                                                    textAlign: "right",
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  {rowItem.qty}
                                                </TableCell>
                                                <TableCell
                                                  sx={{
                                                    width: "20%",
                                                    padding: "0 0.5rem 0 0",
                                                    borderBottom: "none",
                                                    textAlign: "right",
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  {rowItem?.cost?.toFixed(1)}
                                                </TableCell>
                                                {/* @ts-ignore */}
                                                <TableCell
                                                  sx={{
                                                    width: "17%",
                                                    padding: "0 0.5rem 0 0",
                                                    borderBottom: "none",
                                                    textAlign: "right",
                                                    fontSize: "12px",
                                                  }}
                                                >
                                                  {((rowItem?.cost / CostingData?.total_cost) * 100)?.toFixed(1)}%
                                                  {/* {RawMaterailCostPer(rowItem.cost)}
                                              % */}
                                                </TableCell>
                                              </TableRow>
                                            </>
                                          );
                                        })}
                                      {SubPartName &&
                                        Object.keys(SubPartName["material"])
                                          .length == 0 && (
                                          <TableRow>
                                            <TableCell
                                              colSpan={3}
                                              sx={{
                                                padding: "0 0.5rem",
                                                textAlign: "center",
                                                fontSize: '0.87rem',
                                              }}
                                            >
                                              No Data
                                            </TableCell>
                                          </TableRow>
                                        )}
                                    </TableBody>
                                  </Table>
                                </AccordionDetails>
                              </Accordion>
                            }
                          </TableCell>
                        </TableRow>
                        {CostingData && CostingData?.cost_type == "DC" &&
                          <><TableRow>
                            <TableCell
                              sx={{
                                color: "primary.main",
                                borderBottom: "none",
                                // width: "50%",
                                fontSize: "12px",
                              }}
                              className={styles.tbodycellp}
                            >
                              Process
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "primary.main",
                                borderBottom: "none",
                                width: "15%",
                                textAlign: "right",
                                fontSize: "12px",
                              }}
                              className={styles.tbodycellRight}
                            >
                              {SubPartName?.quantity?.process_qty}
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "primary.main",
                                borderBottom: "none",
                                width: "20%",
                                textAlign: "right",
                                fontSize: "12px",
                                padding: '0.2rem 1rem 0.2rem 0.5rem'
                              }}
                            >
                              {CostingData?.process_cost?.toFixed(1)}
                            </TableCell>
                            <TableCell
                              sx={{
                                color: "primary.main",
                                borderBottom: "none",
                                width: "17%",
                                textAlign: "right",
                                fontSize: "12px",
                              }}
                              className={styles.tbodycellRight}
                            >
                              {CostingData?.process_cost_per?.toFixed(1)}%
                            </TableCell>
                          </TableRow><TableRow>
                              <TableCell
                                colSpan={4}
                                sx={{ color: "primary.main", borderBottom: "none", paddingLeft: '5% !important', }}
                                className={styles.tbodycell}
                              >

                                {CostingData?.labour == 0 ? "" :
                                  <Accordion
                                    expanded={expanded === "panel2"}
                                    onChange={handleChange("panel2")}
                                    sx={{
                                      width: "100%",
                                      margin: "0rem !important",
                                      "&.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiAccordion-root.MuiPaper-root.MuiAccordion-root": {
                                        borderColor: "#FFFFFF",
                                        // color: $palette-primary-main;
                                        borderRight: "none",
                                        borderLeft: "none",
                                      },
                                    }}
                                  >
                                    <AccordionSummary
                                      sx={{
                                        minHeight: "24px",
                                        padding: "0rem",
                                        margin: "0rem !important",
                                        width: "100%",
                                      }}
                                      aria-controls="panel2d-content"
                                      id="panel2d-header"
                                    >
                                      <Table>
                                        <TableRow>
                                          <TableCell
                                            sx={{
                                              padding: "0 0.5rem",
                                              fontSize: "12px",
                                              display: "flex",
                                              borderBottom: "none",
                                              // width: "50%",
                                            }}
                                          >
                                            Labour
                                          </TableCell>
                                          <TableCell
                                            sx={{
                                              padding: "0 0.5rem 0 0",
                                              textAlign: "right",
                                              width: "15%",
                                              fontSize: "12px",
                                              borderBottom: "none",
                                            }}
                                          >
                                            {SubPartName?.quantity?.labour_qty}
                                          </TableCell>
                                          <TableCell
                                            sx={{
                                              padding: "0 0.5rem 0 0",
                                              textAlign: "right",
                                              width: "20%",
                                              fontSize: "12px",
                                              borderBottom: "none",
                                            }}
                                          >
                                            {CostingData?.labour?.toFixed(1)}
                                          </TableCell>
                                          <TableCell
                                            sx={{
                                              padding: "0 0.5rem 0 0",
                                              textAlign: "right",
                                              width: "17%",
                                              fontSize: "12px",
                                              borderBottom: "none",
                                              // width:'3rem',
                                            }}
                                          >
                                            {CostingData?.labour_per?.toFixed(1)}%
                                          </TableCell>
                                        </TableRow>
                                      </Table>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <Table sx={{ width: "100%" }}>
                                        <TableBody>
                                          {SubPartName &&
                                            Object.keys(SubPartName["process"])
                                              ?.length > 0 &&
                                            SubPartName["process"]["labour"]?.length >
                                            0 &&
                                            SubPartName["process"]["labour"].map(
                                              (rowItem: any, index: any) => {
                                                return (
                                                  <>
                                                    <TableRow>
                                                      <TableCell
                                                        sx={{
                                                          // width: "40%",
                                                          padding: "0 0.5rem",
                                                          paddingLeft: "5%",
                                                          borderBottom: "none",
                                                          fontSize: '0.87rem',
                                                        }}
                                                      >
                                                        {rowItem.alias}
                                                      </TableCell>
                                                      <TableCell
                                                        sx={{
                                                          width: "15%",
                                                          padding: "0 0.5rem 0 0",
                                                          borderBottom: "none",
                                                          textAlign: "right",
                                                          fontSize: "12px",
                                                        }}
                                                      >
                                                        {rowItem.qty}
                                                      </TableCell>
                                                      <TableCell
                                                        sx={{
                                                          width: "20%",
                                                          padding: "0 0.5rem 0 0",
                                                          borderBottom: "none",
                                                          textAlign: "right",
                                                          fontSize: "12px",
                                                        }}
                                                      >
                                                        {rowItem?.cost?.toFixed(1)}
                                                      </TableCell>
                                                      {/* @ts-ignore */}
                                                      <TableCell
                                                        sx={{
                                                          width: "17%",
                                                          // width:'3rem',
                                                          padding: "0 0.5rem 0 0",
                                                          borderBottom: "none",
                                                          textAlign: "right",
                                                          fontSize: "12px",
                                                        }}
                                                      >
                                                        {((rowItem?.cost / CostingData?.total_cost) * 100)?.toFixed(1)}%
                                                      </TableCell>
                                                    </TableRow>
                                                  </>
                                                );
                                              }
                                            )}
                                          {SubPartName &&
                                            Object.keys(SubPartName["process"])
                                              .length == 0 && (
                                              <TableRow>
                                                <TableCell
                                                  colSpan={3}
                                                  sx={{
                                                    padding: "0 0.5rem",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  No Data
                                                </TableCell>
                                              </TableRow>
                                            )}
                                        </TableBody>
                                      </Table>
                                    </AccordionDetails>
                                  </Accordion>}
                              </TableCell>
                            </TableRow><TableRow>
                              <TableCell
                                colSpan={4}
                                sx={{ color: "primary.main", borderBottom: "none", paddingLeft: '5% !important', }}
                                className={styles.tbodycell}
                              >

                                {CostingData?.machine == 0 ? "" :
                                  <Accordion
                                    expanded={expanded === "panel3"}
                                    onChange={handleChange("panel3")}
                                    sx={{
                                      width: "100%",
                                      margin: "0rem !important",
                                      "&.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiAccordion-root.MuiPaper-root.MuiAccordion-root": {
                                        borderColor: "#FFFFFF",
                                        // color: $palette-primary-main;
                                        borderRight: "none",
                                        borderLeft: "none",
                                      },
                                    }}
                                  >
                                    <AccordionSummary
                                      sx={{
                                        minHeight: "24px",
                                        padding: "0rem",
                                        margin: "0rem !important",
                                        width: "100%",
                                      }}
                                      aria-controls="panel2d-content"
                                      id="panel2d-header"
                                    >
                                      <Table>
                                        <TableRow>
                                          <TableCell
                                            sx={{
                                              padding: "0 0.5rem",
                                              fontSize: "12px",
                                              display: "flex",
                                              borderBottom: "none",
                                              width: "60%",
                                            }}
                                          >
                                            Machine
                                          </TableCell>
                                          <TableCell
                                            sx={{
                                              padding: "0 0.5rem 0 0",
                                              textAlign: "right",
                                              width: "15%",
                                              fontSize: "12px",
                                              borderBottom: "none",
                                            }}
                                          >
                                            {SubPartName?.quantity?.machine_qty}
                                          </TableCell>
                                          <TableCell
                                            sx={{
                                              padding: "0 0.5rem 0 0",
                                              textAlign: "right",
                                              width: "20%",
                                              fontSize: "12px",
                                              borderBottom: "none",
                                            }}
                                          >
                                            {CostingData?.machine?.toFixed(1)}
                                          </TableCell>
                                          <TableCell
                                            sx={{
                                              padding: "0 0.5rem 0 0",
                                              textAlign: "right",
                                              width: "17%",
                                              fontSize: "12px",
                                              borderBottom: "none",
                                            }}
                                          >
                                            {CostingData?.machine_per?.toFixed(1)}%
                                          </TableCell>
                                        </TableRow>
                                      </Table>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <Table>
                                        <TableBody>
                                          {SubPartName &&
                                            Object.keys(SubPartName["process"])
                                              ?.length > 0 &&
                                            SubPartName["process"]["machine"]?.length >
                                            0 &&
                                            SubPartName["process"]["machine"].map(
                                              (rowItem: any, index: any) => {
                                                return (
                                                  <>
                                                    <TableRow>
                                                      <TableCell
                                                        sx={{
                                                          // width: "40%",
                                                          padding: "0 0.5rem",
                                                          paddingLeft: "5%",
                                                          borderBottom: "none",
                                                          fontSize: '0.87rem',
                                                        }}
                                                      >
                                                        {rowItem.alias}
                                                      </TableCell>
                                                      <TableCell
                                                        sx={{
                                                          width: "15%",
                                                          padding: "0 0.5rem 0 0",
                                                          borderBottom: "none",
                                                          textAlign: "right",
                                                          fontSize: "12px",
                                                        }}
                                                      >
                                                        {rowItem.qty}
                                                      </TableCell>
                                                      <TableCell
                                                        sx={{
                                                          width: "20%",
                                                          padding: "0 0.5rem 0 0",
                                                          borderBottom: "none",
                                                          textAlign: "right",
                                                          fontSize: "12px",
                                                        }}
                                                      >
                                                        {rowItem?.cost?.toFixed(1)}
                                                      </TableCell>
                                                      {/* @ts-ignore */}
                                                      <TableCell
                                                        sx={{
                                                          width: "17%",
                                                          padding: "0 0.5rem 0 0",
                                                          borderBottom: "none",
                                                          textAlign: "right",
                                                          fontSize: "12px",
                                                        }}
                                                      >
                                                        {((rowItem?.cost / CostingData?.total_cost) * 100)?.toFixed(1)}%
                                                      </TableCell>
                                                    </TableRow>
                                                  </>
                                                );
                                              }
                                            )}
                                          {SubPartName &&
                                            Object.keys(SubPartName["process"])
                                              .length == 0 && (
                                              <TableRow>
                                                <TableCell
                                                  colSpan={3}
                                                  sx={{
                                                    padding: "0 0.5rem",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  No Data
                                                </TableCell>
                                              </TableRow>
                                            )}
                                        </TableBody>
                                      </Table>
                                    </AccordionDetails>
                                  </Accordion>}
                              </TableCell>
                              {/* <TableCell
  sx={{ color: "primary.main !important", borderBottom: 'none' }}
  className={styles.tbodycellp}
>                        Machine
</TableCell>                      <TableCell
  sx={{ color: "primary.main !important", borderBottom: 'none' }}
  className={styles.tbodycelllast}
>                        {CostingData?.machine}
</TableCell>                      <TableCell
  sx={{ color: "primary.main !important", borderBottom: 'none' }}
  className={styles.tbodycelllast}
>                        {CostingData?.machine_per}%
</TableCell> */}
                            </TableRow><TableRow>
                              <TableCell
                                colSpan={4}
                                sx={{ color: "primary.main", borderBottom: "none", paddingLeft: '5% !important', }}
                                className={styles.tbodycell}
                              >

                                {CostingData?.setup == 0 ? "" :
                                  <Accordion
                                    expanded={expanded === "panel4"}
                                    onChange={handleChange("panel4")}
                                    sx={{
                                      width: "100%",
                                      margin: "0rem !important",
                                      "&.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiAccordion-root.MuiPaper-root.MuiAccordion-root": {
                                        borderColor: "#FFFFFF",
                                        // color: $palette-primary-main;
                                        borderRight: "none",
                                        borderLeft: "none",
                                      },
                                    }}
                                  >
                                    <AccordionSummary
                                      sx={{
                                        minHeight: "24px",
                                        padding: "0rem",
                                        margin: "0rem !important",
                                        width: "100%",
                                      }}
                                      aria-controls="panel2d-content"
                                      id="panel2d-header"
                                    >
                                      <Table>
                                        <TableRow>
                                          <TableCell
                                            sx={{
                                              padding: "0 0.5rem",
                                              fontSize: "12px",
                                              display: "flex",
                                              borderBottom: "none",
                                              // width: "60%",
                                            }}
                                          >
                                            Setup
                                          </TableCell>
                                          <TableCell
                                            sx={{
                                              padding: "0 0.5rem 0 0",
                                              textAlign: "right",
                                              width: "15%",
                                              fontSize: "12px",
                                              borderBottom: "none",
                                            }}
                                          >
                                            {SubPartName?.quantity?.setup_qty}
                                          </TableCell>
                                          <TableCell
                                            sx={{
                                              padding: "0 0.5rem 0 0",
                                              textAlign: "right",
                                              width: "20%",
                                              fontSize: "12px",
                                              borderBottom: "none",
                                            }}
                                          >
                                            {CostingData?.setup?.toFixed(1)}
                                          </TableCell>
                                          <TableCell
                                            sx={{
                                              padding: "0 0.5rem 0 0",
                                              textAlign: "right",
                                              width: "17%",
                                              fontSize: "12px",
                                              borderBottom: "none",
                                            }}
                                          >
                                            {CostingData?.setup_per?.toFixed(1)}%
                                          </TableCell>
                                        </TableRow>
                                      </Table>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <Table>
                                        <TableBody>
                                          {SubPartName &&
                                            Object.keys(SubPartName["process"])
                                              ?.length > 0 &&
                                            SubPartName["process"]["setup"]?.length >
                                            0 &&
                                            SubPartName["process"]["setup"].map(
                                              (rowItem: any, index: any) => {
                                                return (
                                                  <>
                                                    <TableRow>
                                                      <TableCell
                                                        sx={{
                                                          // width: "40%",
                                                          padding: "0 0.5rem",
                                                          paddingLeft: "5%",
                                                          borderBottom: "none",
                                                          fontSize: "12px",
                                                        }}
                                                      >
                                                        {rowItem.alias}
                                                      </TableCell>
                                                      <TableCell
                                                        sx={{
                                                          width: "15%",
                                                          padding: "0 0.5rem 0 0",
                                                          borderBottom: "none",
                                                          textAlign: "right",
                                                          fontSize: "12px",
                                                        }}
                                                      >
                                                        {rowItem.qty}
                                                      </TableCell>
                                                      <TableCell
                                                        sx={{
                                                          width: "20%",
                                                          padding: "0 0.5rem 0 0",
                                                          borderBottom: "none",
                                                          textAlign: "right",
                                                          fontSize: "12px",
                                                        }}
                                                      >
                                                        {rowItem?.cost?.toFixed(1)}
                                                      </TableCell>
                                                      {/* @ts-ignore */}
                                                      <TableCell
                                                        sx={{
                                                          width: "17%",
                                                          padding: "0 0.5rem 0 0",
                                                          borderBottom: "none",
                                                          textAlign: "right",
                                                          fontSize: "12px",
                                                        }}
                                                      >
                                                        {((rowItem?.cost / CostingData?.total_cost) * 100)?.toFixed(1)}%
                                                      </TableCell>
                                                    </TableRow>
                                                  </>
                                                );
                                              }
                                            )}
                                          {SubPartName &&
                                            Object.keys(SubPartName["process"])
                                              .length == 0 && (
                                              <TableRow>
                                                <TableCell
                                                  colSpan={3}
                                                  sx={{
                                                    padding: "0 0.5rem",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  No Data
                                                </TableCell>
                                              </TableRow>
                                            )}
                                        </TableBody>
                                      </Table>
                                    </AccordionDetails>
                                  </Accordion>}
                              </TableCell>
                              {/* <TableCell
  sx={{ color: "primary.main !important", borderBottom: 'none' }}
  className={styles.tbodycellp}
>                        Setup
</TableCell>                      <TableCell
  sx={{ color: "primary.main !important", borderBottom: 'none' }}
  className={styles.tbodycelllast}
>                        {CostingData?.setup}
</TableCell>                      <TableCell
  sx={{ color: "primary.main !important", borderBottom: 'none' }}
  className={styles.tbodycelllast}
>                        {CostingData?.setup_per}%
</TableCell> */}
                            </TableRow><TableRow>
                              <TableCell
                                colSpan={4}
                                sx={{ color: "primary.main", borderBottom: "none", paddingLeft: '5% !important', }}
                                className={styles.tbodycell}
                              >

                                {CostingData?.tool == 0 ? "" :
                                  <Accordion
                                    expanded={expanded === "panel5"}
                                    onChange={handleChange("panel5")}
                                    sx={{
                                      width: "100%",
                                      margin: "0rem !important",
                                      "&.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiAccordion-root.MuiPaper-root.MuiAccordion-root": {
                                        borderColor: "#FFFFFF",
                                        // color: $palette-primary-main;
                                        borderRight: "none",
                                        borderLeft: "none",
                                      },
                                    }}
                                  >
                                    <AccordionSummary
                                      sx={{
                                        minHeight: "24px",
                                        padding: "0rem",
                                        margin: "0rem !important",
                                        width: "100%",
                                      }}
                                      aria-controls="panel2d-content"
                                      id="panel2d-header"
                                    >
                                      <Table>
                                        <TableRow>
                                          <TableCell
                                            sx={{
                                              padding: "0 0.5rem",
                                              fontSize: "12px",
                                              display: "flex",
                                              borderBottom: "none",
                                              // width: "60%",
                                            }}
                                          >
                                            Tool
                                          </TableCell>
                                          <TableCell
                                            sx={{
                                              padding: "0 0.5rem 0 0",
                                              textAlign: "right",
                                              width: "15%",
                                              fontSize: "12px",
                                              borderBottom: "none",
                                            }}
                                          >
                                            {SubPartName?.quantity?.tool_qty}
                                          </TableCell>
                                          <TableCell
                                            sx={{
                                              padding: "0 0.5rem 0 0",
                                              textAlign: "right",
                                              width: "20%",
                                              fontSize: "12px",
                                              borderBottom: "none",
                                            }}
                                          >
                                            {CostingData?.tool?.toFixed(1)}
                                          </TableCell>
                                          <TableCell
                                            sx={{
                                              padding: "0 0.5rem 0 0",
                                              textAlign: "right",
                                              width: "17%",
                                              fontSize: "12px",
                                              borderBottom: "none",
                                            }}
                                          >
                                            {CostingData?.tool_per?.toFixed(1)}%
                                          </TableCell>
                                        </TableRow>
                                      </Table>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <Table>
                                        <TableBody>
                                          {SubPartName &&
                                            Object.keys(SubPartName["process"])
                                              ?.length > 0 &&
                                            SubPartName["process"]["tool"]?.length >
                                            0 &&
                                            SubPartName["process"]["tool"].map(
                                              (rowItem: any, index: any) => {
                                                return (
                                                  <>
                                                    <TableRow>
                                                      <TableCell
                                                        sx={{
                                                          // width: "40%",
                                                          padding: "0 0.5rem",
                                                          paddingLeft: "5%",
                                                          borderBottom: "none",
                                                          fontSize: "12px",
                                                        }}
                                                      >
                                                        {rowItem.alias}
                                                      </TableCell>
                                                      <TableCell
                                                        sx={{
                                                          width: "15%",
                                                          padding: "0 0.5rem 0 0",
                                                          borderBottom: "none",
                                                          textAlign: "right",
                                                          fontSize: "12px",
                                                        }}
                                                      >
                                                        {rowItem.qty}
                                                      </TableCell>
                                                      <TableCell
                                                        sx={{
                                                          width: "20%",
                                                          padding: "0 0.5rem 0 0",
                                                          borderBottom: "none",
                                                          textAlign: "right",
                                                          fontSize: "12px",
                                                        }}
                                                      >
                                                        {rowItem?.cost?.toFixed(1)}
                                                      </TableCell>
                                                      <TableCell
                                                        sx={{
                                                          width: "17%",
                                                          padding: "0 0.5rem 0 0",
                                                          borderBottom: "none",
                                                          textAlign: "right",
                                                          fontSize: "12px",
                                                        }}
                                                      >
                                                        {((rowItem?.cost / CostingData?.total_cost) * 100)?.toFixed(1)}%
                                                      </TableCell>
                                                    </TableRow>
                                                  </>
                                                );
                                              }
                                            )}
                                          {SubPartName &&
                                            Object.keys(SubPartName["process"])
                                              .length == 0 && (
                                              <TableRow>
                                                <TableCell
                                                  colSpan={3}
                                                  sx={{
                                                    padding: "0 0.5rem",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  No Data
                                                </TableCell>
                                              </TableRow>
                                            )}
                                        </TableBody>
                                      </Table>
                                    </AccordionDetails>
                                  </Accordion>}
                              </TableCell>
                            </TableRow><TableRow>
                              <TableCell
                                sx={{
                                  color: "primary.main !important",
                                  borderBottom: "none",
                                  // width: "50%",
                                  fontSize: "12px",
                                }}
                                className={styles.tbodycellp}
                              >
                                Overheads
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "primary.main !important",
                                  borderBottom: "none",
                                  width: "15%",
                                  textAlign: "right",
                                  fontSize: "12px",
                                }}
                                className={styles.tbodycellRight}
                              >
                                {SubPartName?.quantity?.overhead_qty}
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "primary.main !important",
                                  borderBottom: "none",
                                  width: "20%",
                                  textAlign: "right",
                                  fontSize: "12px",
                                  padding: '0.2rem 1rem 0.2rem 0.5rem',
                                }}
                              >
                                {CostingData?.overhead?.toFixed(1)}
                              </TableCell>
                              <TableCell
                                sx={{
                                  color: "primary.main !important",
                                  borderBottom: "none",
                                  width: "17%",
                                }}
                                className={styles.tbodycellRight}
                              >
                                {CostingData?.overhead_per?.toFixed(1)}%
                              </TableCell>
                            </TableRow><TableRow>
                              <TableCell
                                colSpan={4}
                                sx={{ color: "primary.main", borderBottom: "none", paddingLeft: '5% !important', }}
                                className={styles.tbodycell}
                              >
                                {CostingData?.overhead == 0 ? "" :
                                  <Accordion
                                    expanded={expanded === "panel6"}
                                    onChange={handleChange("panel6")}
                                    sx={{
                                      width: "100%",
                                      margin: "0rem !important",
                                      "&.MuiPaper-root.MuiPaper-elevation.MuiPaper-elevation0.MuiAccordion-root.MuiPaper-root.MuiAccordion-root": {
                                        borderColor: "#FFFFFF",
                                        // color: $palette-primary-main;
                                        borderRight: "none",
                                        borderLeft: "none",
                                      },
                                    }}
                                  >
                                    <AccordionSummary
                                      sx={{
                                        minHeight: "24px",
                                        padding: "0rem",
                                        margin: "0rem !important",
                                        width: "100%",
                                      }}
                                      aria-controls="panel2d-content"
                                      id="panel2d-header"
                                    >
                                      <Table>
                                        <TableRow>
                                          <TableCell
                                            sx={{
                                              padding: "0 0.5rem",
                                              fontSize: "12px",
                                              display: "flex",
                                              borderBottom: "none",
                                              // width: "60%",
                                            }}
                                          >
                                            Overhead & Profit Cost
                                          </TableCell>
                                          <TableCell
                                            sx={{
                                              padding: "0 0.5rem 0 0",
                                              textAlign: "right",
                                              width: "15%",
                                              fontSize: "12px",
                                              borderBottom: "none",
                                            }}
                                          >
                                            {SubPartName?.quantity?.overhead_qty}
                                          </TableCell>
                                          <TableCell
                                            sx={{
                                              padding: "0 0.5rem 0 0",
                                              textAlign: "right",
                                              width: "20%",
                                              fontSize: "12px",
                                              borderBottom: "none",
                                            }}
                                          >
                                            {CostingData?.overhead.toFixed(1)}
                                          </TableCell>
                                          <TableCell
                                            sx={{
                                              padding: "0 0.5rem 0 0",
                                              textAlign: "right",
                                              width: "17%",
                                              fontSize: "12px",
                                              borderBottom: "none",
                                            }}
                                          >
                                            {CostingData?.overhead_per}%
                                          </TableCell>
                                        </TableRow>
                                      </Table>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                      <Table>
                                        <TableBody>
                                          {SubPartName &&
                                            Object.keys(SubPartName["overhead"])
                                              ?.length > 0 &&
                                            SubPartName["overhead"]["overhead"]
                                              ?.length > 0 &&
                                            SubPartName["overhead"]["overhead"].map(
                                              (rowItem: any, index: any) => {
                                                return (
                                                  <>
                                                    <TableRow>
                                                      <TableCell
                                                        sx={{
                                                          // width: "40%",
                                                          padding: "0 0.5rem",
                                                          paddingLeft: "5%",
                                                          borderBottom: "none",
                                                          fontSize: "12px",
                                                        }}
                                                      >
                                                        {rowItem.alias}
                                                      </TableCell>
                                                      <TableCell
                                                        sx={{
                                                          width: "15%",
                                                          padding: "0 0.5rem 0 0",
                                                          borderBottom: "none",
                                                          textAlign: "right",
                                                          fontSize: "12px",
                                                        }}
                                                      >
                                                        {rowItem.qty}
                                                      </TableCell>
                                                      <TableCell
                                                        sx={{
                                                          width: "20%",
                                                          padding: "0 0.5rem 0 0",
                                                          borderBottom: "none",
                                                          textAlign: "right",
                                                          fontSize: "12px",
                                                        }}
                                                      >
                                                        {rowItem.cost.toFixed(1)}
                                                      </TableCell>
                                                      {/* @ts-ignore */}
                                                      <TableCell
                                                        sx={{
                                                          width: "17%",
                                                          padding: "0 0.5rem 0 0",
                                                          borderBottom: "none",
                                                          textAlign: "right",
                                                          fontSize: "12px",
                                                        }}
                                                      >
                                                        {((rowItem?.cost / CostingData?.total_cost) * 100)?.toFixed(1)}%
                                                      </TableCell>
                                                    </TableRow>
                                                  </>
                                                );
                                              }
                                            )}
                                          {SubPartName &&
                                            Object.keys(SubPartName["overhead"])
                                              .length == 0 &&
                                            SubPartName["overhead"]["overhead"]
                                              ?.length == 0 && (
                                              <TableRow>
                                                <TableCell
                                                  colSpan={3}
                                                  sx={{
                                                    padding: "0 0.5rem",
                                                    textAlign: "center",
                                                  }}
                                                >
                                                  No Data
                                                </TableCell>
                                              </TableRow>
                                            )}
                                        </TableBody>
                                      </Table>
                                    </AccordionDetails>
                                  </Accordion>}
                              </TableCell>
                            </TableRow></>
                        }
                        {ChildCost > 0 && (
                          <TableRow sx={{ backgroundColor: "primary.light" }}>
                            <TableCell
                              colSpan={2}
                              sx={{ color: "primary.main", borderBottom: "none" }}
                              className={styles.tbodycellp}
                            >
                              Child Cost
                            </TableCell>
                            <TableCell
                              sx={{ color: "primary.main", borderBottom: "none", fontSize: '0.87rem', padding: '0.2rem 1rem 0.2rem 0.5rem', textAlign: 'right' }}
                            // className={styles.tbodycellRight}
                            >
                              {ChildCost?.toFixed(1)}
                            </TableCell>
                            <TableCell
                              sx={{ color: "primary.main", borderBottom: "none", fontSize: '0.87rem' }}
                              className={styles.tbodycellRight}
                            >
                              {CostingData?.child_cost_per.toFixed(1)}%
                            </TableCell>
                          </TableRow>
                        )}
                        {ChilPartCostData()}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              </div>)
          )}
        </>
      )}
    </div>
  );
};
// ----------------------------------------------------------------------------------
export default CostingModule;
