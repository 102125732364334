import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { LoadingButton } from '@mui/lab';
import {
	Checkbox,
	Divider,
	FormControl,
	FormControlLabel,
	InputLabel,
	Skeleton,
	TextField,
} from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { API } from '../../../api-services';
import ErrorModalFilesMail from '../../ProjectMailModule/ErrorModalFilesMail';
import { useBaseModal } from '../../SearchPage/useBaseModal';
import { useHistory, useParams } from 'react-router-dom';
import CopyPartHeirarchyBOM from './CopyPartHeirarchyBOM';
const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '51vw',
	bgcolor: 'background.paper',
	borderRadius: '10px',
	p: 1,
};
interface BOMCopyModalProps {
	onCloseModal: any;
	isOpen: any;
	getData: any;
	copytype: any;
	setParentsCounter: any;
	SucessAndCoptpart: any;
}

const BOMCopyModal = (props: BOMCopyModalProps) => {
	const {
		onCloseModal,
		isOpen,
		getData,
		copytype,
		setParentsCounter,
		SucessAndCoptpart,
	} = props;

	const {
		projectId,
		topVault,
		vault,
		leafNode,
		treeabbr,
		scenarioId,
		projectName,
		workstation,
		isLeaf,
	} = useParams<any>();
	const [quantity, setQuantity] = useState<any>(1);
	const [Name, setName] = useState<any>();
	const [Loader, setLoader] = useState<any>(false);
	const [mainLoader, setmainLoader] = useState<any>(false);
	const [ErrorModalFileMsg, setErrorModalFileMsg] = React.useState<any>();

	const [NameError, setNameError] = useState<any>(false);

	const [checkboxvalue, setcheckboxvalue] = useState<any>([]);
	const [CheckboxData, setCheckboxData] = useState<any>();
	const ErrorModalFiles = useBaseModal();
	const [checked, setChecked] = React.useState<any>(1);
	const [HierarchyData, setHierarchyData] = React.useState<any>([]);
	const [CopyInfoList, setCopyInfoList] = React.useState<any>({});
	let CopyId = sessionStorage?.getItem('copyId');

	const history = useHistory<any>();
	// @ts-ignore
	let matchData = JSON?.parse(CopyId);
	const handleChange = (e: any, type: any) => {
		if (type == 'name') {
			setNameError(false);
			setName(e?.target?.value);
		} else if (type == 'quantity') {
			setQuantity(e?.target?.value);
		}
	};

	const PasteHandler = () => {
		if (checked == 2) {
			const NameValue = Name?.trim();
			if (NameValue?.length > 0 && NameValue !== null) {
				setLoader(true);
				API.post(
					`/xcpep/hierarchy_operations/`,
					{
						node_id: getData?.id,
						parent_id: checkboxvalue[0],
						quantity: +quantity,
						type: 'unlinked',
						part_name: Name,
					},
					{},
					0
				)
					.then((res: any) => {
						setLoader(false);

						onCloseModal();
						setParentsCounter((prev: any) => prev + 1);

						sessionStorage.removeItem('TragetIds');
						sessionStorage.removeItem('copyId');
						SucessAndCoptpart();
					})
					.catch((err: any) => {
						setLoader(false);
						const { data } = err.response;
						console.log(data, 'datadata');
						ErrorModalFiles.open();
						setErrorModalFileMsg(data);
						console.log('Server Error');
					});
			}
			if (NameValue == null || NameValue?.length == 0) {
				setNameError(true);
			}
		} else {
			setLoader(true);
			API.post(
				`/xcpep/hierarchy_operations/`,
				{
					node_id: getData?.id,
					parent_id: checkboxvalue[0],
					quantity: +quantity,
					type: 'linked',
				},
				{},
				0
			)
				.then((res: any) => {
					onCloseModal();
					setParentsCounter((prev: any) => prev + 1);

					sessionStorage.removeItem('TragetIds');
					sessionStorage.removeItem('copyId');
					SucessAndCoptpart();
					setLoader(false);
				})
				.catch((err: any) => {
					setLoader(false);
					const { data } = err.response;
					console.log(data, 'datadata');
					ErrorModalFiles.open();
					setErrorModalFileMsg(data);
					console.log('Server Error');
				});
		}
	};

	const handleChange3 = (id: any) => {
		if (checked == id) {
			setChecked(null);
		} else {
			setChecked(id);
		}
	};

	useEffect(() => {
		API.get(`xcpep/vault_copy_info/`, {
			copied_count: true,
			vault: getData?.id,
		})
			.then((res: any) => {
				setCopyInfoList(res?.data);
			})
			.catch((err: any) => {
				console.log('Server error');
			});
	}, [getData]);
	useEffect(() => {
		setmainLoader(true);
		API.get('/xcpep/bom_create_hirearchy/', { top_vault: topVault }, 0)
			.then((res: any) => {
				setmainLoader(false);
				setHierarchyData(res?.data?.data);
			})
			.catch((err: any) => {
				setmainLoader(false);
				console.log('Server EEror');
			});
	}, [getData]);

	const onSelectCallback = (data: any) => {
		if (checkboxvalue?.includes(data?.id)) {
			setCheckboxData({});
		} else {
			API.get('/xcpep/vault_copy_info/', {
				root_id: data?.id,
				source_id: +getData?.id,
			})
				.then((res: any) => {
					setCheckboxData(data);
					setcheckboxvalue([data?.id]);
				})
				.catch((err: any) => {
					setcheckboxvalue([]);
					const { data } = err.response;
					console.log(data, 'datadata');
					ErrorModalFiles.open();
					setErrorModalFileMsg(data);
					console.log('Server Error');
				});
		}
	};
	return (
		<div>
			<Modal
				open={isOpen}
				onClose={onCloseModal}
				aria-labelledby='modal-modal-title'
				aria-describedby='modal-modal-description'>
				<Box sx={style}>
					<ErrorModalFilesMail
						isOpen={ErrorModalFiles.isOpen}
						onCloseModal={ErrorModalFiles.close}
						ErrorMsg={ErrorModalFileMsg}
					/>

					<Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
						<Typography
							style={{
								fontSize: '1rem',
								fontWeight: '600',
								width: '100%',
								textAlign: 'center',
								marginRight: '-2rem',
							}}>
							Part Copy & Paste
						</Typography>
						<CancelIcon
							titleAccess='Close'
							sx={{ cursor: 'pointer', margin: '0.5rem' }}
							onClick={onCloseModal}
						/>
					</Box>

					<Divider
						sx={{
							borderColor: 'primary.light',
							margin: '0 1rem',
							marginBottom: '1rem',
						}}
					/>

					{mainLoader ? (
						<Box sx={{ width: '100%' }}>
							<Skeleton></Skeleton>
							<Skeleton></Skeleton>
							<Skeleton></Skeleton>
							<Skeleton></Skeleton>
							<Skeleton></Skeleton>
							<Skeleton></Skeleton>
						</Box>
					) : (
						<Box sx={{ padding: '0 1rem 1rem', display: 'flex' }}>
							<Box sx={{ width: '100%',borderRight:'1px solid',borderColor:'primary.light',paddingRight:'0.5rem' }}>
								<Typography
									sx={{
										fontSize: '1rem',
										fontWeight: '500',
										width: '100%',
										textAlign: 'center',
										marginBottom: '1rem',
										textDecoration: 'underline',
									}}>
									Copy Information
								</Typography>
								<Box
									sx={{
										width: '100%',
										padding: '0 1rem',
									}}>
									<Box sx={{ display: 'flex', alignItems: 'center' }}>
										<Typography
											sx={{
												fontSize: '1rem',
												width: '10rem',
												fontWeight: '500',
											}}>
											Copy Part Count
										</Typography>
										<Typography sx={{ fontSize: '1rem' }}>
											: {CopyInfoList?.copied_count} Parts
										</Typography>
									</Box>
									<Box sx={{ display: 'flex', alignItems: 'center' }}>
										<Typography
											sx={{
												fontSize: '1rem',
												width: '10rem',
												fontWeight: '500',
											}}>
											Source Part
										</Typography>
										<Typography sx={{ fontSize: '1rem' }}>
											: {getData?.name}
										</Typography>
									</Box>
									<Box sx={{ width: '100%', margin: '1rem 0',display:'flex',alignItems:'center',justifyContent:'space-between', columnGap:'1rem' }}>
										<FormControlLabel
											label='Paste'
											// @ts-ignore
											control={
												<Checkbox
													checked={checked == 1 ? true : false}
													onChange={() => handleChange3(1)}
												/>
											}
										/>
										OR
										<FormControlLabel
											label='Paste as New (Select ) '
											// @ts-ignore
											control={
												<Checkbox
													checked={checked == 2 ? true : false}
													onChange={() => handleChange3(2)}
												/>
											}
										/>
									</Box>

									<Box
										sx={{
											display: 'flex',
											flexDirection: 'column',
											rowGap: '2rem',
										}}>
										{checked == 2 && (
											<FormControl fullWidth>
												<InputLabel
													shrink
													sx={{ color: 'primary.main', marginLeft: '-1.2rem' }}>
													Enter Name<sup style={{ color: 'red' }}>*</sup>
												</InputLabel>
												<TextField
													value={Name}
													onChange={(e: any) => handleChange(e, 'name')}
													required
													variant='standard'
													type='text'
													sx={{
														marginTop: '0.75rem',
														'.MuiInputBase-root.MuiInput-root': {
															fontSize: '1rem !important',
														},
														'& .MuiInput-underline:before': {
															borderBottomColor: 'primary.main',
														},
														'& .MuiInput-underline:after': {
															borderBottomColor: 'primary.main',
														},
													}}
												/>
												{NameError ? (
													<span style={{ color: 'red' }}>
														Pelase Enter name *
													</span>
												) : (
													''
												)}
											</FormControl>
										)}

										<FormControl fullWidth>
											<InputLabel
												shrink
												sx={{ color: 'primary.main', marginLeft: '-1.2rem' }}>
												Enter Quantity<sup style={{ color: 'red' }}>*</sup>
											</InputLabel>
											<TextField
												value={quantity}
												onChange={(e: any) => handleChange(e, 'quantity')}
												required
												variant='standard'
												type='number'
												inputProps={{ min: 1 }}
												sx={{
													marginTop: '0.75rem',
													'.MuiInputBase-root.MuiInput-root': {
														fontSize: '1rem !important',
													},
													'& .MuiInput-underline:before': {
														borderBottomColor: 'primary.main',
													},
													'& .MuiInput-underline:after': {
														borderBottomColor: 'primary.main',
													},
												}}
											/>
										</FormControl>
									</Box>
								</Box>
								<Box
									sx={{
										display: 'flex',
										marginTop: '1rem',
										justifyContent: 'end',
										padding: '0 1rem',
									}}>
									<LoadingButton
										loading={Loader}
										size='small'
										disabled={checkboxvalue?.length > 0 ? false : true}
										variant='contained'
										onClick={() => PasteHandler()}
										sx={{
											width: '8rem',
											'&:hover': {
												transform: 'Scale(1.05)',
												transition: 'transform 0.5s ease',
											},
										}}>
										Paste
									</LoadingButton>
								</Box>
							</Box>
							<Box
								sx={{ height: '80vh', width: '100%',paddingLeft:'0.5rem' }}
								adl-scrollbar='true'
								adl-scrollbar-width='0.3'>
								<Typography
									sx={{
										fontSize: '1rem',
										fontWeight: '500',
										width: '100%',
										textAlign: 'center',
										marginBottom: '1rem',
										textDecoration: 'underline',
									}}>
									Select Destination Part
								</Typography>
								<CopyPartHeirarchyBOM
									HierarchyData={HierarchyData}
									onSelectCallback={onSelectCallback}
									checkboxvalue={checkboxvalue}
									setcheckboxvalue={setcheckboxvalue}
								/>
							</Box>
						</Box>
					)}
				</Box>
			</Modal>
		</div>
	);
};

export default BOMCopyModal;
