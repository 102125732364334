import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import InfoIcon from "@mui/icons-material/Info";
import styles from "./SystemAndNamesLibraryLandingPage.module.scss";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { isNull } from 'lodash';
import { string } from 'yup';
import { Box } from '@mui/material';
import { SystemInfoModal } from './SystemInfoModal';
import { useBaseModal } from '../../ComponentUI/SearchPage/useBaseModal';


interface SystemNameTabProps {
  SystemData: any;
  SubsystemHanlder: any
  subystemEditHanlder: any;
  deleteHanlder: any;
  ParentsCounter: any;
  useraction: any;
}

const SystemNameTab = (props: SystemNameTabProps) => {
  const { categoryId, subsystemId, search } = useParams<any>();
  const {
    SystemData,
    SubsystemHanlder,
    subystemEditHanlder,
    deleteHanlder, ParentsCounter, useraction
  } = props
  const infoModal = useBaseModal();
  const [SystemDataList, setSystemDataList] = useState<any>(SystemData && SystemData)
  const [SelectedId, setSelectedId] = useState<any>([])
  useEffect(() => {
    {
      setSystemDataList(SystemData && SystemData)
    }
  }, [SystemData, ParentsCounter]);
  useEffect(() => {
    if (subsystemId && subsystemId != null && subsystemId != 0) {
      let element: any = !isNull(subsystemId) && document.getElementById(String(subsystemId));

      let b: any = element && element.scrollIntoView(true);
    }

  }, []);

  const InfoHandler = (Id: any) => {
    infoModal?.open()
    setSelectedId(Id)
  }
  return (
    <>

      {infoModal.isOpen &&
        <SystemInfoModal
          isOpen={infoModal.isOpen}
          onCloseModal={infoModal.close}
          Item={SelectedId && SelectedId}
        />
      }
      <Box sx={{ width: "20%", height: { lg: '80vh', xl: '86vh' } }}
        adl-scrollbar="true"
        adl-scrollbar-width="0.3">
        {SystemDataList &&
          SystemDataList?.map((item: any, index: any) => {
            return (
              <Box
                id={item?.id}
                key={index}
                sx={{ marginLeft: '0.5rem', marginRight: '0.5rem', borderLeft: '1px solid', }}
                onClick={() => SubsystemHanlder(item?.id)}>
                <div
                  className={
                    (search == 0 || search == "0") &&
                      subsystemId == item?.id
                      ? styles.active_box_container
                      : styles.box_container
                  }>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                      width: "100%",
                      paddingRight: '0.5rem',
                      fontSize: "1rem",
                    }}>

                    <span>
                      <span>--</span>
                      {item?.name} ({item?.abbreviation})
                    </span>
                    <span> ({item?.part_count})</span>
                  </Box>
                  <Box
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: '0.5rem',
                      justifyContent: 'space-between',
                    }}>

                    {useraction && useraction?.includes("U") && <EditIcon
                      titleAccess="Edit"
                      onClick={() => subystemEditHanlder(item)}
                    />}
                    {item?.mapped_status ? (
                      <InfoIcon onClick={() => InfoHandler(item?.id)} />
                    ) : (
                      useraction && useraction?.includes("D") && <DeleteIcon
                        titleAccess="Delete"
                        onClick={() => deleteHanlder(item?.id)}
                      />
                    )}
                  </Box>
                </div>
              </Box>

            );
          })}
      </Box>
    </>
  )
}

export default SystemNameTab;