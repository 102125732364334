import * as React from 'react';
import { Box, Checkbox, IconButton, Typography } from '@mui/material';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { useHistory, useRouteMatch } from 'react-router-dom';
import styles from "./CompanionMainLanding.module.scss";
import Drawer from '@mui/material/Drawer';
import Divider from '@mui/material/Divider';
import AddIcon from '@mui/icons-material/Add';
import { useRouteParams } from '../../ui-reusable-component/useRouteParams';
import CompanionConfig from '../../CompanionConfig/CompanionConfig';
import { useEffect } from 'react';
import { API } from '../../api-services';
import CostingConfigurationDrawer from '../Costing/CostingConfigurationDrawer';


type Anchor = 'top' | 'left' | 'bottom' | 'right';

interface ICompanionMainLandingProps {
    projectId: any,
    module: any,
}

function CompanionMainLanding(props: ICompanionMainLandingProps) {
    const history = useHistory();
    const { url } = useRouteMatch();
    const { projectId, projectname } = useRouteParams<any>();
    const label = { inputProps: { 'aria-label': 'Checkbox demo' } };
    const [state, setState] = React.useState({
        top: false,
        left: false,
        bottom: false,
        right: false,
    });

    React.useEffect(() => {
        API.get(
          "/auth/calibration_user_permission/",
          {
            action: true,
            configuration: true,
            project: projectId,
          },
          0
        )
          .then((res: any) => {
            sessionStorage.setItem("ConfigPermission", res.data.action);
          })
          .catch((err: any) => {});
      }, []);

    const toggleDrawer =
        (anchor: Anchor, open: boolean) =>
            (event: React.KeyboardEvent | React.MouseEvent) => {
                if (
                    event.type === 'keydown' &&
                    ((event as React.KeyboardEvent).key === 'Tab' ||
                        (event as React.KeyboardEvent).key === 'Shift')
                ) {
                    return;
                }

                setState({ ...state, [anchor]: open });
            };

    const list = (anchor: Anchor) => (
        <Box
            sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
            role="presentation"
            // onClick={toggleDrawer(anchor, false)}
            // onKeyDown={toggleDrawer(anchor, false)}
        >
            {/* <Box sx={{ padding: '0 0.5rem' }}>
                <Box sx={{ alignItems: "center", display: "flex" }}>
                    <Checkbox
                        style={{ padding: '3px' }}
                        checked={url.includes("bomconfiguration")}
                        onClick={() => history.push(`/bomconfiguration/${projectId}/${projectname}`,)}
                        inputProps={{ "aria-label": "controlled" }}
                        sx={{ "& .MuiSvgIcon-root": { fontSize: { lg: '24', xl: '30' } } }}
                    />
                    <Box
                        // key={index}
                        // onClick={() => formInputField('category_change', item?.value)}
                        onClick={() => history.push(`/bomconfiguration/${projectId}/${projectname}`,)}
                        sx={{
                            padding: "0.2rem",
                            margin: '0.5rem,0rem',
                            backgroundColor: url.includes("bomconfiguration") ? 'primary.main' : 'primary.light',
                            color: url.includes("bomconfiguration") ? 'white' : 'primary.main',
                            textAlign: "left",
                            cursor: "pointer",
                            display: "inline-block",
                            width: "100%",
                        }}
                    >
                        <Typography style={{ fontSize: "0.87rem", textTransform: 'capitalize' }}>BOM Configuration</Typography>
                    </Box>
                </Box>
                <Box sx={{ alignItems: "center", display: "flex" }}>
                    <Checkbox
                        style={{ padding: '3px' }}
                        checked={url.includes("costingconfiguration")}
                        onClick={() => history.push(`/costingconfiguration/${projectId}/${projectname}/guidelines/`,)}
                        inputProps={{ "aria-label": "controlled" }}
                        sx={{ "& .MuiSvgIcon-root": { fontSize: { lg: '24', xl: '30' } } }}
                    />
                    <Box
                        // key={index}
                        // onClick={() => formInputField('category_change', item?.value)}
                        onClick={() => history.push(`/costingconfiguration/${projectId}/${projectname}/guidelines/`,)}
                        sx={{
                            padding: "0.2rem",
                            margin: '0.5rem,0rem',
                            backgroundColor: url.includes("costingconfiguration") ? 'primary.main' : 'primary.light',
                            color: url.includes("costingconfiguration") ? 'white' : 'primary.main',
                            textAlign: "left",
                            cursor: "pointer",
                            display: "inline-block",
                            width: "100%",
                        }}
                    >
                        <Typography style={{ fontSize: "0.87rem", textTransform: 'capitalize' }}>Costing Configuration</Typography>
                    </Box>
                </Box>
                <Box sx={{ alignItems: "center", display: "flex" }}>
                    <Checkbox
                        style={{ padding: '3px' }}
                        checked={url.includes("feature-configuration")}
                        onClick={() => history.push(`/feature-configuration/${projectId}/${projectname}`,)}
                        inputProps={{ "aria-label": "controlled" }}
                        sx={{ "& .MuiSvgIcon-root": { fontSize: { lg: '24', xl: '30' } } }}
                    />
                    <Box
                        onClick={() => history.push(`/feature-configuration/${projectId}/${projectname}`,)}
                        sx={{
                            padding: "0.2rem",
                            margin: '0.5rem,0rem',
                            backgroundColor: url.includes("feature-configuration") ? 'primary.main' : 'primary.light',
                            color: url.includes("feature-configuration") ? 'white' : 'primary.main',
                            textAlign: "left",
                            cursor: "pointer",
                            display: "inline-block",
                            width: "100%",
                        }}
                    >
                        <Typography style={{ fontSize: "0.87rem", textTransform: 'capitalize' }}>Feature Configuration</Typography>
                    </Box>
                </Box>
                <Box sx={{ alignItems: "center", display: "flex" }}>
                    <Checkbox
                        style={{ padding: '3px' }}
                        checked={url.includes("ideaconfiguration")}
                        onClick={() => history.push(`/ideaconfiguration/${projectId}/${projectname}`,)}
                        inputProps={{ "aria-label": "controlled" }}
                        sx={{ "& .MuiSvgIcon-root": { fontSize: { lg: '24', xl: '30' } } }}
                    />
                    <Box
                        // key={index}
                        onClick={() => history.push(`/ideaconfiguration/${projectId}/${projectname}`,)}
                        sx={{
                            padding: "0.2rem",
                            margin: '0.5rem,0rem',
                            backgroundColor: url.includes("ideaconfiguration") ? 'primary.main' : 'primary.light',
                            color: url.includes("ideaconfiguration") ? 'white' : 'primary.main',
                            textAlign: "left",
                            cursor: "pointer",
                            display: "inline-block",
                            width: "100%",
                        }}
                    >
                        <Typography style={{ fontSize: "0.87rem", textTransform: 'capitalize' }}>Ideas Configuration</Typography>
                    </Box>
                </Box>
                <Box sx={{ alignItems: "center", display: "flex" }}>
                    <Checkbox
                        style={{ padding: '3px' }}
                        checked={url.includes("companionConfig")}
                        onClick={() => comapnionHandle()}
                        inputProps={{ "aria-label": "controlled" }}
                        sx={{ "& .MuiSvgIcon-root": { fontSize: { lg: '24', xl: '30' } } }}
                    />
                    <Box
                        // key={index}
                        onClick={() => comapnionHandle()}
                        sx={{
                            padding: "0.2rem",
                            margin: '0.5rem,0rem',
                            backgroundColor: url.includes("companionConfig") ? 'primary.main' : 'primary.light',
                            color: url.includes("companionConfig") ? 'white' : 'primary.main',
                            textAlign: "left",
                            cursor: "pointer",
                            display: "inline-block",
                            width: "100%",
                        }}
                    >
                        <Typography style={{ fontSize: "0.87rem", textTransform: 'capitalize' }}>Companion App Configuration</Typography>
                    </Box>
                </Box>
            </Box> */}
            <CostingConfigurationDrawer />
        </Box>
    );



    const handleClick = () => {
        history.goBack();
    };

    const comapnionHandle = () => {
        API.get("/web_companion/comapanion_activity_log", {
            //   get_recycle_bin_data_count: true
            project: projectId, viewed: true
        }, 0).then((res: any) => {
            // console.log(res?.data.count, "countdata")
        }).catch((err: any) => {
            // console.log("Server error")
            //   SetLoader(false)
        })
        history.push(`/companionConfig/${projectId}/${projectname}`);
    };

    const costingModel = () => {
        history.push("/costingconfiguration/calculator");
    };

    return (
        <div>
            <Box sx={{ margin: '0 0.5rem' }}>
                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', width: '100%' }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
                        <IconButton sx={{ cursor: "pointer", }} title='Go Back' onClick={handleClick}>
                            <ArrowBackIcon color="primary" titleAccess="Go Back" />
                        </IconButton>
                        <Typography sx={{ fontSize: '1rem', whiteSpace: 'nowrap', }}> {projectname} - Workshop-Companion App Configuration</Typography>
                    </Box>
                    <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '1rem' }}>
                    </Box>
                </Box>
            </Box>
            <Box sx={{ display: 'flex', alignItems: 'flex-start' }}>
                <Box className={styles.expandIconBox}>
                    <div className={styles.iconSec}>
                        {(["left"] as const).map((anchor) => (
                            <React.Fragment key={anchor}>
                                <IconButton
                                    size="medium"
                                    sx={{
                                        backgroundColor: "primary.light",
                                        color: "primary.main",
                                    }}
                                    onClick={toggleDrawer(anchor, true)}
                                >
                                    <AddIcon sx={{ fontSize: "1.7rem" }} />
                                </IconButton>
                                <Drawer
                                    anchor={anchor}
                                    open={state[anchor]}
                                    onClose={toggleDrawer(anchor, false)}
                                    className={styles.drawerContainer}
                                    PaperProps={{
                                        sx: {
                                            backgroundColor: "aliceblue",
                                        },
                                    }}
                                >
                                    <Box
                                        sx={{ padding: "1rem 0rem", paddingBottom: '0' }}
                                        adl-scrollbar="true"
                                        adl-scrollbar-width="0.3"
                                    >
                                        <Typography style={{ width: "100%", textAlign: "center", fontSize: '1rem', }}>
                                            Select Configuration Module
                                        </Typography>
                                        <Divider sx={{ margin: "0.5rem" }} />
                                        {list(anchor)}
                                    </Box>
                                </Drawer>
                                <Typography
                                    style={{
                                        transform: "rotate(270deg)",
                                        whiteSpace: "nowrap",
                                        marginTop: "40vh",
                                        cursor: "pointer",
                                        fontSize: '1rem'
                                    }}
                                    onClick={toggleDrawer(anchor, true)}
                                >
                                    Select Configuration Module
                                </Typography>
                            </React.Fragment>
                        ))}
                    </div>
                </Box>
                <Box sx={{ width: '100%' }}>
                    <CompanionConfig />
                </Box>
            </Box>
        </div>
    );
}
export default CompanionMainLanding;
