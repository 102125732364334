import React, { useEffect, useRef, useState } from 'react';
import { LoadingButton } from '@mui/lab';
import { FormControl, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material';
import { useRouteMatch } from 'react-router-dom';
import FastForwardIcon from '@mui/icons-material/FastForward';
import { API } from '../../api-services';
import MediaExcelModalDownload from '../MediaModule/MediaLandingPage/MediaContentBody/MediaGrouping/MediaExcelDownload/MediaExcelModalDownload';
import { useBaseModal } from '../SearchPage/useBaseModal';

interface MediaGroupDownloadConsolidatedI {
    countImage: any;
    groupContextId: any;
    groupContextTopVault: any;
    groupContextImageCategory: any;
    groupContextAbbreviation: any;
    openMediaConsolidatedDownload: any;
    setOpenMediaConsolidatedDownload: any;
    groupContextGroupName: any;
}

const MediaGroupDownloadConsolidated: React.FC<MediaGroupDownloadConsolidatedI> = (props) => {
    const { countImage, groupContextId, groupContextTopVault, groupContextImageCategory, groupContextAbbreviation, openMediaConsolidatedDownload, setOpenMediaConsolidatedDownload, groupContextGroupName } = props;
    const [rangeListSelected, setRangeListSelected] = React.useState<any>("");
    const [imagesDataExcel, setImagesDataExcel] = useState<any>([]);
    const [disableNext, setDisableNext] = useState<boolean>(true);
    const [loadingNext, setLoadingNext] = useState<boolean>(false);
    const [changeImageSelection, setChangeImageSelection] = useState<boolean>(true);
    const [errorExcelDownload, setErrorExcelDownload] = useState<boolean>(false);

    const mediaExcelDownloadModal = useBaseModal();

    const result = useRef({
        data: [],
        loaded: false
    });

    useEffect(() => {
        setLoadingNext(false);
        setDisableNext(true);
        setChangeImageSelection(true);
    }, [openMediaConsolidatedDownload])

    const { url } = useRouteMatch();

    useEffect(() => {
        setChangeImageSelection(true)
    }, [])

    const handleSelectChange = (event: any) => {
        // @ts-ignore
        setRangeListSelected(event.target.value);
        setDisableNext(false);
    };

    const handleImageoptions = (value: any) => {
        var a = value;
        var b = 25;
        let temp = [];

        if (a) {
            for (let i = 0; i < Math.ceil(a / b); i++) {
                temp.push({
                    name: `${i * 25 + 1} - ${i * 25 + 25}`,
                    value: i * 25 + 25,
                });
            }
        }

        const imageRange = temp?.map(subsystem => ({
            key: subsystem.name,
            value: subsystem.value,
            text: subsystem.name,
        }));

        return imageRange
    };

    const handleVideoOptions = (value: any) => {
        var a = value;
        var b = 5;
        let temp = [];
        if (a) {
            for (let i = 0; i < Math.ceil(a / b); i++) {
                temp.push({
                    name: `${i * 5 + 1} - ${i * 5 + 5}`,
                    value: i * 5 + 5,
                });
            }
        }

        const imageRange = temp?.map(subsystem => ({
            key: subsystem.name,
            value: subsystem.value,
            text: subsystem.name,
        }));

        return imageRange
    };

    const dataCall = async () => {
        result.current.data = [];
        API.get(`image_repository/fetch_multiple/?top_vault=${groupContextTopVault.join()}&abbreviation=${groupContextAbbreviation == 0 ? '' : groupContextAbbreviation}&image_category=${groupContextImageCategory}&media_type=image&range=${rangeListSelected}&group=${groupContextId == '-2' ? null : groupContextId}&internal_download=true`).then((res) => {
            setImagesDataExcel(res.data);
        }).catch((err) => {
            setErrorExcelDownload(false);
        })
    }

    const nextExcelDownload = () => {
        setErrorExcelDownload(true);
        dataCall();
        setLoadingNext(true);
        setTimeout(() => {
            setLoadingNext(false);
            mediaExcelDownloadModal.open();
        }, 18000);
    }

    return (
        <>
            <Table>
                <TableHead sx={{ backgroundColor: 'primary.light' }}>
                    <TableRow>
                        <TableCell sx={{ padding: '0.2rem 0.5rem' }}>Group</TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem' }}>Range</TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem' }}>Action</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow>
                        <TableCell sx={{ padding: '0.2rem 0.5rem' }}>{groupContextGroupName}</TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem' }}>
                            {countImage == 0 ? <span>No Image Available</span> :
                                <FormControl variant="standard" sx={{ width: '330px' }}>
                                    <Select
                                        labelId="demo-simple-select-standard-label"
                                        id="demo-simple-select-standard"
                                        value={rangeListSelected}
                                        onChange={handleSelectChange}
                                        sx={{
                                            "&:before": {
                                                borderBottomColor: "primary.light",
                                            },
                                            "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                                                fontSize: '1rem'
                                            },
                                            '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },
                                        }}
                                    >
                                        {(url.includes("image-repository/video") ? handleVideoOptions(countImage && countImage) : handleImageoptions(countImage && countImage))?.map((item: any) => {
                                            return <MenuItem sx={{ fontSize: '1rem' }} key={item?.key} value={item?.value}>{item?.text}</MenuItem>
                                        })}
                                    </Select>
                                </FormControl>}
                        </TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem' }}>
                            <LoadingButton
                                sx={{
                                    padding: "0.2rem",
                                    lineHeight: 1,
                                    "&:hover": {
                                        transform: 'Scale(1.05)',
                                        transition: 'transform 0.5s ease',
                                    },
                                    '.MuiCircularProgress-root': {
                                        color: 'primary.main',
                                    },
                                }}
                                loading={loadingNext} disabled={disableNext} onClick={nextExcelDownload}>
                                <FastForwardIcon />
                            </LoadingButton>
                        </TableCell>
                    </TableRow>
                </TableBody>
            </Table>
            <MediaExcelModalDownload
                result={result}
                isOpen={mediaExcelDownloadModal.isOpen}
                onClose={mediaExcelDownloadModal.close}
                topVaultId={""}
                range={changeImageSelection}
                imagesData={imagesDataExcel}
                setOpenMediaConsolidatedDownload={setOpenMediaConsolidatedDownload}
            />
        </>)
}

export default MediaGroupDownloadConsolidated;