
// -----------------------------------------------------------------------------------
// entity adaptor

import { EntityState, createEntityAdapter, createReducer } from "@reduxjs/toolkit";
import { getGanttChartNotes, updateGanttChartData } from "../Actions/ganttChart.action";
import { RootState } from "../Store/storeConfigurations";
import { ID } from "../../utlis/commonInterfaces";

export interface GanttChartNotes {
    id: ID;
    notes?: string;
}

const adaptor = createEntityAdapter<GanttChartNotes>( {
    // selectId: ( GannttChartNotess ) => GannttChartNotess.id,
    // sortComparer: ( a, b ) => 
} );

// -----------------------------------------------------------------------------------
// interfaces & initialState

type AdditionalFields = {};

type IGanttChartNotesReducer = EntityState<GanttChartNotes> & AdditionalFields;

const additionalFields: AdditionalFields = {};

const initialState: IGanttChartNotesReducer = Object.assign( {}, adaptor.getInitialState(), additionalFields );

// -----------------------------------------------------------------------------------
// reducer

const GanttChartNotesReducer = createReducer( initialState, builder => {
    //getGanttChartNotes

    builder
        .addCase( getGanttChartNotes.fulfilled, ( state, action: any ) => {
            // console.log( action.payload, "action.payload" );
            adaptor.setAll( state, action.payload.ganttChartNotes );
        } )
        .addCase( getGanttChartNotes.rejected, ( _state, action ) => {
            // console.log( action.payload, "Could no fetch Data" );
        } );


    builder
        .addCase( updateGanttChartData.fulfilled, ( state, action: any ) => {
            adaptor.setAll( state, action.payload.ganttChartNotes );

            // console.log( "Notes Updated" );
        } )
        .addCase( updateGanttChartData.rejected, ( _state, action ) => {
            // console.log( action.payload, "Could not update Notes" );
        } );

    // builder.
    //     addCase( resetGanttChart, () => initialState );

} );

// -----------------------------------------------------------------------------------
// selectors

const GannttChartNotessSelectors = adaptor.getSelectors<RootState>( ( state ) => state.ganttChartNotes );

// -----------------------------------------------------------------------------------
// exports

export {
    GanttChartNotesReducer,
    initialState as GanttChartNotesReducerInit,
    GannttChartNotessSelectors
};