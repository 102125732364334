import { API } from "./index.service";

const url = "/image_repository/subsystem_fetch/";

class subsystemServices {

    getSubsystemData = ( project:any  , module:any) => {
        return API.get( url, { project , module } );
    }

    getInfo = ( project:any , abbreviation:any) => {
        return API.get( url, { project , abbreviation } );
    }


}


const subsystem_Services = new subsystemServices();

export default subsystem_Services;
