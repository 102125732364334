import * as React from "react";
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import {
  Autocomplete,
  Box,
  Chip,
  Divider,
  FormControl,
  MenuItem,
  Skeleton,
  TextField,
  Typography,
  Stepper,
  Step,
  StepLabel,
  CircularProgress,
  Button,
  Menu,
  MenuProps,
  FormControlLabel,
  Switch,
  InputLabel,
  FormGroup,
  Stack,
} from "@mui/material";
import openFolderred from "../../../Assets/images/svgs/openfolderred.svg";
import openFolderGreen from "../../../Assets/images/svgs/openfoldergreen.svg";
import { alpha, styled, useTheme } from "@mui/material/styles";
import config from "../../../Assets/images/configuration.svg";
import DescriptionIcon from "@mui/icons-material/Description";
import CancelIcon from "@mui/icons-material/Cancel";
import { API } from "../../../api-services";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import CreateCostingCategoryTree from "../../../Configuration/Costing/CreateCostingCategoryTree";
import { ID } from "../../../utlis/commonInterfaces";
import { useHistory } from "react-router-dom";
import { CostingCalculatorConfig } from "./CostingCalculatorConfig";
import { CostingAccordionParentCreate } from "../../../ViewModule/CostingView/CostingAccordionParentCreate";
import Swal from "sweetalert2";
import { ADMIN } from "../../../Redux/Services/admin.service";
import JoditEditor from "jodit-react";
import SyncIcon from "@mui/icons-material/Sync";
import Image from "../../../ViewModule/FeatureLandingPage/ArchitectureImageCard";
import arrowRight from "../../../Assets/images/rightArrowswhite.svg";
import RViewerJS from "viewerjs-react";
import { isUndefined } from "lodash";
import { AssumptionModal } from "../../../ViewModule/CostingView/AssumptionModal";
import { useBaseModal } from "../../SearchPage/useBaseModal";
import InfoIcon from "@mui/icons-material/Info";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import { AxiosError, AxiosResponse } from "axios";
import scnarioprog from "../../../Assets/images/scenarioInProgress.svg";
import QrCodeScannerIcon from "@mui/icons-material/QrCodeScanner";
import { AUTH } from "../../../Redux/Services/auth.service";
import swal from "sweetalert";
import DeleteIcon from "@mui/icons-material/Delete";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import BOMIcon from "../../../Assets/images/hierarchy.png";
import { BOMDrawerTable } from "./BOMDrawerTable";
import styles from "./CreateCostingPart.module.scss";
import { CostigngCalculatorSection } from "./CostigngCalculatorSection";
import Draggable from "react-draggable";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import FolderIcon from "@mui/icons-material/Folder";
import openFolder from "../../../Assets/images/svgs/openfolderblue.svg";
import useWindowDimensions from "../../../ui-reusable-component/useWindowDimensions";
import CreateCostingCategoryTreeNew from "../../../Configuration/Costing/CreateCostingCategoryTreeNew";
import { useSelector } from "react-redux";
import { userSelectors } from "../../../Redux/Reducers/user.reducer";


export const userPermission = React.createContext<any>(null);

export interface ICreateCalculatorWithCategoryModalProps {
  assumptionList: any;
  stepLoader?: any;
  isShowCost: any;
  selectedAssumption: any;
  costDetailsData: any;
  handleChange: any;
  selectedCategory: any;
  setSelectedCategory: any;
  getCostDetailsDataWOL: any;
  categoryList: any;
  categoryLoader: any;
  setCategoryLoader: any;
  handleSelectChangParams: any;
  commodityList: any;
  getOptionDisabled: any;
  selectedCommodity: any;
  arraysAreEqual: any;
  oldSelectedCommodity: any;
  ExactDebounceCommodity: any;
  handleSelectChangAssumtionParams: any;
  multipleDataSet: any;
  Selectedmulassumption: any;
  handleChangeCostingStatus: any;
  SelectedmulIds: any;
  CostType: any;
  setCostType: any;
  setCategoryList: any;
  setCount: any;
  unvalidatedCategory?: any;
}

const style = {
  // position: "absolute" as "absolute",
  // top: "50%",
  // left: "50%",
  // transform: "translate(-50%, -50%)",
  // minWidth: "50%",
  // height: "99vh",
  // overflowY: "auto",
  // // width: "97vw",
  // bgcolor: "background.paper",
  // borderRadius: "10px",
  // p: 1,
};
export const costingDetails = React.createContext<any>({});

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&::before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "white",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(0deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "0px solid rgba(0, 0, 0, .125)",
}));

const SelectableAccordionSummary = styled(AccordionSummary)({
  userSelect: "text", // Allows text selection in the summary
});

export function CreateCalculatorWithCategoryModal(
  props: ICreateCalculatorWithCategoryModalProps
) {
  const {
    assumptionList,
    isShowCost,
    selectedAssumption,
    costDetailsData,
    handleChange,
    selectedCategory,
    setSelectedCategory,
    getCostDetailsDataWOL,
    categoryList,
    handleSelectChangAssumtionParams,
    multipleDataSet,
    Selectedmulassumption,
    handleChangeCostingStatus,
    SelectedmulIds,
    CostType,
    setCostType,
    categoryLoader,
    setCategoryLoader,
    setCategoryList,
    setCount,
    unvalidatedCategory,
    stepLoader,
  } = props;
  const {
    vault,
    topVault,
    projectId,
    projectname,
    productId,
    abbrebation,
    tableId,
    tableCounter,
    condition,
    calctype,
    calcultorId,
    scenarioId,
    conditionPopup,
    newUI,
    costEstimationId,
    cardtype,
  } = useRouteParams<any>();
  const editorConfig = {
    style: {
      // padding: "20px",
      width: "100%",
      height: "60vh",
    },
    readonly: true, // Set the editor to read-only mode
    toolbar: false,
  };

  const StyledMenu = styled((props: MenuProps) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",

        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",

        horizontal: "left",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,

      border: "0.1px solid #007fff",

      minWidth: 150,

      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],

      "& .MuiMenu-list": {
        padding: "4px 0",
      },

      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,

          color: theme.palette.text.secondary,

          marginRight: theme.spacing(1.5),
        },

        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,

            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));
  const editor = React.useRef<any>(null);
  const AssumptionCardModal = useBaseModal();
  const [anchorElRedirect, setAnchorElRedirect] =
    React.useState<null | HTMLElement>(null);
  const openRedirect = Boolean(anchorElRedirect);
  const [status, setStatus] = React.useState("");
  const history = useHistory();
  const [redirectData, setRedirectData] = React.useState<any>([]);
  const [ValidationLoader, setValidationLoader] = React.useState<any>(false);
  const [treeData, setTreeData] = React.useState<any>([]);
  const [treeexpanded, setTreeExpanded] = React.useState<string | false>(
    "panel1"
  );
  const [treeDataHeader, setTreeDataHeader] = React.useState<any>(null);
  const [treeDataMaterial, setTreeDataMaterial] = React.useState<any>(null);
  const [treeDataProcess, setTreeDataProcess] = React.useState<any>(null);
  const [treeDataOverhead, setTreeDataOverhead] = React.useState<any>(null);
  const [treeDataChildCost, setTreeDataChildCost] = React.useState<any>(null);
  const [estimationCompleted, setEstimationCompleted] =
    React.useState<any>(false);
  const [costingStatus, setCostingStatus] = React.useState<any>("");
  const [active, setActive] = React.useState<ID>(0);
  const [content, setContent] = React.useState<any>("");
  const [activefield, setActivefield] = React.useState<any>(0);
  const [result, setResult] = React.useState<any>("");
  const [loading, setLoading] = React.useState<boolean>(false);
  const [pendingRequests, setPendingRequests] = React.useState(0);
  const [openForm, setOpenForm] = React.useState<any>("");
  const [calculatorData, setCalculatorData] = React.useState<any>({});
  const [selectedTreeData, setSelectedTreeData] = React.useState<any>({});
  const [counterApiCall, setCounterApiCall] = React.useState<any>(0);
  const [parentdata, setParentData] = React.useState<any>({});
  const [loadingButton, setLoadingButton] = React.useState(false);
  const [statusData, setStatusData] = React.useState<any>(null);
  const [completedConfigCounts, setCompletedConfigCounts] =
    React.useState<any>(0);

  const hasMounted = React.useRef(false);
  const isDataReady = React.useRef(false); // To track when data is ready
  const timeoutRef = React.useRef<NodeJS.Timeout | null>(null);
  const [activeStep, setActiveStep] = React.useState<any>(null);

  const [completedSteps, setCompletedSteps] = React.useState<boolean[]>([
    false,
    false,
    false,
  ]); // Track completed steps
  const theme = useTheme();
  const [PermissionData, setPermissionData] = React.useState<any>(null);

  const [hideDisable, setHideDisable] = React.useState<any>(false);
  const [showHideDisable, setShowHideDisable] = React.useState<any>(false);

  const { width } = useWindowDimensions();

  // BOM Data Drawer
  const [Tabstatus, setTabstatus] = React.useState<any>("Parameters");
  const [passingObj, setPassingObj] = React.useState<any>({});
  const [isActive, setIsActive] = React.useState(false);
  const BomdataDrawer = () => {
    // toggle
    setIsActive((current) => !current);

    // or set to true
    // setIsActive(true);
  };


  const getCompletedCostModelStatus = (isShow: any) => {
    
    
    if (costEstimationId !== 0 || (costDetailsData?.cost_estimation !== 0 && costDetailsData?.cost_estimation !== null && costDetailsData?.cost_estimation !== undefined)) {
      API.get(
        `/cost/get_costing_progress/${costEstimationId !== 0 ? costEstimationId : costDetailsData?.cost_estimation}/`,
        {
          sync: isShow ? true : undefined,
        },
        0
      )
        .then((res: any) => {
          isShow && ADMIN.toast.info("Cost estimation counts Updated");
          setStatusData(res.data);
        })
        .catch((err: any) => {});
    }
  };

  React.useEffect(() => {
    getCompletedCostModelStatus(false);
  }, []);

  const countCompletedSteps = (indices: number[]): number => {
    return indices.reduce((count, index) => {
      return checkFieldCompletion(index) ? count + 1 : count;
    }, 0);
  };

  const checkFieldCompletion = (index: number) => {
    switch (index) {
      case 0:
        return !!selectedAssumption; // Step 1 is completed if 'selectedAssumption' has a value
      case 1:
        return Selectedmulassumption && Selectedmulassumption?.length > 0; // Step 2 is completed if 'Selectedmulassumption' has a value
      case 2:
        return !!selectedCategory; // Step 3 is completed if 'selectedCategory' has a value
      default:
        return false;
    }
  };

  const getPreviousButtonText = (type: any) => {
    switch (type) {
      case 0:
        return "Previous";
      case 1:
        return `Configuration ( ${completedConfigCounts}/3)`;
      case 2:
        return "Cost Estimation";
      default:
        return "Previous";
    }
  };
  const getNextButtonText = (type: any) => {
    switch (type) {
      case 0:
        return "Cost Estimation";
      case 1:
        return "Complete";
      case 2:
        return "Validate";
      default:
        return "Next";
    }
  };

  // console.log(activeStep, "newUI activeStep");
  // console.log(costDetailsData, "newUI costDetailsData", stepLoader);

  const userProfile = useSelector(userSelectors.selectAll);

  React.useEffect(() => {
    // // Skip the effect on the first render
    // if (!hasMounted.current) {
    //   hasMounted.current = true;
    //   return;
    // }

    // Clear any existing timeout to prevent stale updates
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
      timeoutRef.current = null;
    }

    let newStep: number | null = null;

    // Determine activeStep based on costDetailsData
    if (costDetailsData?.costing_status === "validated") {
      if(scenarioId == 0){
      newStep = 2;
      }else{
        newStep = 1;
      }
    } else if (checkFieldCompletion(0) && checkFieldCompletion(2)) {
      newStep = 1;
    } else {
      // Delay setting activeStep to 0 to avoid immediate update
      timeoutRef.current = setTimeout(() => {
        setActiveStep(0);
      }, 3500); // Adjust the delay as needed
      return; // Exit early to avoid setting `0` immediately
    }

    // If a valid step is determined, update activeStep
    if (newStep !== null && newStep !== activeStep) {
        setActiveStep(newStep);
      

      
    }
  }, [costDetailsData]);
  

  // Cleanup timeout on component unmount
  React.useEffect(() => {
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, []);

  // React.useEffect(() => {

  //   if (costDetailsData?.costing_status === "validated") {
  //     setActiveStep(2);
  //   } else if (
  //     checkFieldCompletion(0) === true &&
  //     checkFieldCompletion(2) === true
  //   ) {
  //     setActiveStep(1);
  //   } else if(!(costDetailsData?.costing_status === "validated") && !(
  //     checkFieldCompletion(0) === true &&
  //     checkFieldCompletion(2) === true
  //   )) {
  //     setActiveStep(0);

  //   }
  // }, [costDetailsData]);

  const stepsFields = [
    `Main Assumption Card`,
    "Other Assumption Cards (Optional)",
    `Configuration`,
  ];
  const steps = [
    `Configuration ( ${completedConfigCounts}/3)`,
    `Cost Estimation (User inputs- ${
      (statusData && statusData?.user_input) || "0/0"
    }, DB fetch- ${(statusData && statusData?.db_fetch) || "0/0"})`,
    "Completed",
  ];

  const getGuidelinesData = () => {
    API.get(
      "/cost/cost_guide_line/",
      {
        project: projectId,
      },
      0
    )
      .then((res: any) => {
        setContent(res.data[0].content);
      })
      .catch((err: any) => {});
  };

  React.useEffect(() => {
    getGuidelinesData();
  }, []);

  const getObjj = () => {
    if (vault == 0) {
      return { top_vault: topVault };
    } else {
      return { vault: vault };
    }
  };

  const getTableData = () => {
    API.get("/cost/scenario_table/", { ...getObjj() }, 0)
      .then((res: any) => {
        setRedirectData(res?.data);
      })
      .catch((err: any) => {
        console.log("Sever Error");
      });
  };

  React.useEffect(() => {
    getTableData();
  }, [vault, topVault, scenarioId]);

  React.useEffect(() => {
    if (!!selectedAssumption && !!selectedCategory) {
      setCompletedSteps([true, false, false]);
      // setActiveStep(1)
    }
  }, [selectedAssumption, selectedCategory]);

  const getDisabledStatus = () => {
    if (activeStep === 0) {
      if (completedSteps[0] !== true) {
        return true;
      } else {
        return false;
      }
    } else if (activeStep === 1) {
      if (completedSteps[1] !== true) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  React.useEffect(() => {
    setCompletedConfigCounts(countCompletedSteps([0, 1, 2]));
  }, [selectedCategory, selectedAssumption, Selectedmulassumption]);

  React.useEffect(() => {
    API.get(
      "/auth/calibration_user_permission/",
      {
        action: true,
        costing_create: true,
        vault: vault,
        scenario: scenarioId ? scenarioId : undefined,
      },
      0
    )
      .then((res: any) => {
        setPermissionData(res?.data);
      })
      .catch(() => {
        console.log("Srver Error");
      });
  }, [vault]);

  React.useEffect(() => {
    API.get(
      "/auth/calibration_user_permission/",
      {
        action: true,
        costing_create: true,
        vault: vault,
      },
      0
    )
      .then((res: any) => {
        setPermissionData(res?.data);
      })
      .catch(() => {
        console.log("Srver Error");
      });
  }, [vault]);

  const guidelinesHandler = () => {
    window.open(`/#/costingguidelines/${projectId}/${projectname}`, "_blank");
    // history.push(`/costingguidelines/${projectId}/${projectname}`);

    // setDownloadLoader(true);
    // try {
    //   // API.apiBase + `cost/generate_cost_guide_line/?project=${projectId}&user=${AUTH.user}`

    //   fetch(
    //     API.apiBase +
    //       `cost/generate_cost_guide_line/?project=${projectId}&user=${AUTH.user}`,
    //     {
    //       method: "GET",
    //     }
    //   ).then((resp) => window.open(resp.url));
    // } catch (e) {
    //   setDownloadLoader(false);
    // }
  };

  const predefinedNodeItems = [
    "0HierarchyParent",
    "1Parent",
    "2Material",
    "4BoughtOutPart",
    // "5Process",
    // "6Overheads",
  ];

  const getHideDisableData = () => {
    API.get(
      "/cost/check_status_hide_CM/",
      {
        cost_est_id: costEstimationId,
      },
      0
    )
      .then((res: any) => {
        console.log(res?.data);

        setShowHideDisable(res?.data?.status);
      })
      .catch(() => {
        console.log("Server Error");
      });
  };

  const handleStepClick = (index: number) => {
    if (index !== 2) {
      if (index === 1) {
        if (!selectedCategory) {
          ADMIN.toast.error("Please select Configuration");
          return;
        } else if (!selectedAssumption) {
          ADMIN.toast.error("Please select Main Assumption Card");
          return;
        } else {
          setActiveStep(index);
        }
      } else {
       
        setActiveStep(index);
      }
    } else {
      if (costDetailsData?.costing_status !== "validated") {
        API.get(
          `/cost/check_costing_status/${costDetailsData?.cost_estimation}`,
          {},
          0
        )
          .then(() => {
            if (index - 1 === 1) {
              setCompletedSteps([true, true, false]);
              // handleChangeCostingStatus("completed");
              API.put(
                `/cost/update_estimation/${costDetailsData?.cost_estimation}/`,
                {
                  completed: true,
                },
                {},
                0
              )
                .then((res: any) => {
                  ADMIN.toast.info("Costing Completed");
                })
                .catch((err: any) => {});
            }
            setActiveStep(index);
          })
          .catch((err: any) => {
            if (err && err?.response && err?.response?.data) {
              Swal.fire({
                icon: "error",
                customClass: {
                  container: "swal2Container",
                },
                title: "Oops...",
                text: err?.response?.data[0],
              });
            }
          });
      } else {
        setActiveStep(index);
      }
    }
  };
  const getTreeData = (type: any) => {
    setLoading(true);
    setPendingRequests((prev) => prev + 1);
    API.get(
      `/cost/get_standard_hierarchy/${costDetailsData?.cost_estimation}/`,
      {
        refresh: calcultorId !== 0 ? +calctype || 0 : undefined,
        hide_disabled: hideDisable,
        response_type: type,
      },
      0
    )
      .then((res: any) => {
        if (type === "header") {
          setTreeDataHeader(res.data?.data?.[0]);
        } else if (type === "material") {
          setTreeDataMaterial(res.data);
        } else if (type === "process") {
          setTreeDataProcess(res.data);
        } else if (type === "overhead") {
          setTreeDataOverhead(res.data);
        } else if (type === "child_cost") {
          setTreeDataChildCost(res.data);
        }
        setTreeData(res.data?.data);
        setEstimationCompleted(res.data?.completed);
        if (res.data?.completed === true) {
          setCompletedSteps([true, true, false]);
        }
        let a: any = sessionStorage.getItem("Costingconfigurationnodeconfig");
        let val: any = JSON.parse(a) || [];
        // if (val?.length > 0) {
        // } else {
        //   sessionStorage.setItem(
        //     "Costingconfigurationnodeconfig",
        //     JSON.stringify([
        //       "0HierarchyParent",
        //       "1Parent",
        //       "2Material",
        //       "3RawMaterial",
        //       "4BoughtOutPart",
        //       "5Process",
        //       "6Overheads",
        //     ])
        //   );
        // }
        predefinedNodeItems?.forEach((item) => {
          // Only push the item if it's not already present in the array
          if (!val?.includes(item)) {
            val?.push(item); // Add item if not found
          }
        });

        sessionStorage.setItem("Costingconfigurationnodeconfig", JSON.stringify(val));
        setLoading(false);
      })
      .catch(() => {})
      .finally(() => {
        // Decrement the counter and check if all requests are done
        setPendingRequests((prev) => {
          const newCount = prev - 1;
          if (newCount === 0) {
            setLoading(false); // Hide loader when all requests are done
          }
          return newCount;
        });
      });
  };

  const getTreeDataWOL = () => {
    API.get(
      `/cost/get_standard_hierarchy/${costDetailsData?.cost_estimation}/`,
      {
        refresh: calcultorId !== 0 ? +calctype || 0 : undefined,
        hide_disabled: hideDisable,
      },
      0
    )
      .then((res: any) => {
        setTreeData(res.data?.data);
        setEstimationCompleted(res.data?.completed);
        if (res.data?.completed === true) {
          setCompletedSteps([true, true, false]);
        }
        let a: any = sessionStorage.getItem("Costingconfigurationnodeconfig");
        let val: any = JSON.parse(a) || [];
        if (val?.length > 0) {
        } else {
          sessionStorage.setItem(
            "Costingconfigurationnodeconfig",
            JSON.stringify([
              "0HierarchyParent",
              "1Parent",
              // "2Material",
              // "3RawMaterial",
              // "4BoughtOutPart",
              // "5Process",
              // "6Overheads",
            ])
          );
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const AddBOPRefreshTree = () => {
    API.get(
      `/cost/get_standard_hierarchy/${costDetailsData?.cost_estimation}/`,
      {
        refresh: +calctype || 0,
      },
      0
    )
      .then((res: any) => {
        setTreeData(res.data?.data);
        setEstimationCompleted(res.data?.completed);
        if (res.data?.completed === true) {
          setCompletedSteps([true, true, false]);
        }
        let a: any = sessionStorage.getItem("Costingconfigurationnodeconfig");
        let val: any = JSON.parse(a) || [];
        if (val?.length > 0) {
        } else {
          sessionStorage.setItem(
            "Costingconfigurationnodeconfig",
            JSON.stringify([
              "0HierarchyParent",
              "1Parent",
              // "2Material",
              // "3RawMaterial",
              // "4BoughtOutPart",
              // "5Process",
              // "6Overheads",
            ])
          );
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const onSelect = (nodeId: any, id: any, HierarchyAllData: any) => {
    setOpenForm("");
    if (HierarchyAllData?.type === "calculator") {
      setActive(HierarchyAllData?.id);
      setCalculatorData(HierarchyAllData);
      history.push(
        `/createcostingpart/${projectId}/${projectname}/${productId}/${topVault}/${vault}/${abbrebation}/${HierarchyAllData?.id}/${HierarchyAllData?.object_id}/${costDetailsData?.cost_estimation}/${tableCounter}/${condition}/${HierarchyAllData?.calculator?.category}/${HierarchyAllData?.calculator?.id}/${scenarioId}/${conditionPopup}/true`
      );
    }
  };

  const redirectScenario = () => {
    if (vault == 0) {
      sessionStorage.removeItem("nodeCostingScenario");
      sessionStorage.setItem("nodeCostingScenario", JSON.stringify([topVault]));
      sessionStorage.setItem("addScenarioStatus", "3");
      window.open(
        `/#/costingscenario/${projectId}/${projectname}/${topVault}/${vault}/1/${abbrebation}/0`
      );
    } else {
      API.get(`xcpep/vault_info/`, { vault: vault }, 0)
        .then((res: AxiosResponse) => {
          sessionStorage.removeItem("nodeCostingScenario");
          sessionStorage.setItem(
            "nodeCostingScenario",
            JSON.stringify(res?.data?.[0]?.ancestors)
          );
          sessionStorage.setItem("addScenarioStatus", "3");
          window.open(
            `/#/costingscenario/${projectId}/${projectname}/${topVault}/${vault}/1/${abbrebation}/0`
          );
        })
        .catch((err: AxiosError) => {});
    }
  };

  const handleClickRedirect = async (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElRedirect(event.currentTarget);
  };

  const handleCloseRedirect = () => {
    setAnchorElRedirect(null);
  };

  React.useEffect(() => {
    if (costDetailsData && costDetailsData?.cost_estimation) {
      getTreeData("header");
      getTreeData("material");
      getTreeData("process");
      getTreeData("overhead");
      if (costDetailsData?.is_leaf == false) {
        getTreeData("child_cost");
      }
    }
    getHideDisableData();
  }, []);

  React.useEffect(() => {
    if (costDetailsData && costDetailsData?.cost_estimation) {
      getTreeData("header");
      getTreeData("material");
      getTreeData("process");
      getTreeData("overhead");
      if (costDetailsData?.is_leaf == false) {
        getTreeData("child_cost");
      }
    }
  }, [hideDisable]);

  React.useEffect(() => {
    if (costDetailsData && costDetailsData?.cost_estimation) {
      // getTreeDataWOL();
      getTreeData("header");
      getTreeData("material");
      getTreeData("process");
      getTreeData("overhead");
      if (costDetailsData?.is_leaf == false) {
        getTreeData("child_cost");
      }
    }
  }, [tableCounter, costDetailsData, hideDisable]);

  const getCalcData = (data: any) => {
    setSelectedTreeData(data);
  };

  const [selectedCostType, setSelectedCostType] = React.useState<any>(CostType);

  React.useEffect(() => {
    if (CostType !== selectedCostType) {
      setSelectedCostType(CostType);
    }
  }, [CostType]);

  React.useEffect(() => {
    const filterCategories = () => {
      // if (!selectedCostType) {
      //   // Show both `dc` and `bop` if nothing is selected
      //   setCategoryLoader(true);
      //   API.get(
      //     "/cost/get_all_validated_configuration_cat/",
      //     { project_id: projectId, vault_id: vault },
      //     0
      //   )
      //     .then((res: any) => {
      //       setCategoryList(res.data);
      //       setCategoryLoader(false);
      //     })
      //     .catch((err: any) => {
      //       setCategoryLoader(false);
      //     });
      // } else 
      setCategoryList([]);
      if (selectedCostType) {
        setCategoryLoader(true);
        API.get(
          "/cost/get_all_validated_configuration_cat/",
          {
            project_id: projectId,
            costing_type: selectedCostType,
            vault_id: vault,
          },
          0
        )
          .then((res: any) => {
            setCategoryList(res.data);
            setCategoryLoader(false);
          })
          .catch((err: any) => {
            setCategoryLoader(false);
          });
      }
    };

    filterCategories();
  }, [selectedCostType]);

  const handleTypeChange = (event: SelectChangeEvent) => {
    setSelectedCostType(event.target.value);
  };

  const handleDisableChange = (event: any) => {
    setHideDisable(event.target.checked);
  };

  const isCategoryValidated =
    Array.isArray(categoryList) &&
    selectedCategory &&
    categoryList?.some((item) => item?.id === selectedCategory);

  const TabHandler = (tab: any) => {
    if (tab == "Parameters") {
      setTabstatus("Parameters");
    } else {
      setTabstatus("Table");
    }
  };

 

  return (
    <div>
      <Box>
        <Box sx={style}>
          {(activeStep !== 0 && activeStep !== 1 && activeStep !== 2) ?  <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent:"space-between",
              height: "2rem",
              marginTop: "0.6rem",
              padding:"0 1rem"
            }}
          >
            
                 
                  <Skeleton variant="text" sx={{ fontSize: "1rem", width:"5rem",  }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem", width:"5rem" }} />
               
              
          </Box> : <Box
            sx={{
              display: "flex",
              alignItems: "center",
              height: "2rem",
              marginTop: "0.6rem",
            }}
          >
            {activeStep !== 0 && (
              <Button
                disabled={activeStep === 0}
                onClick={() => setActiveStep((step: any) => step - 1)}
                variant="contained"
                size="small"
                startIcon={<ArrowBackIosIcon />}
                sx={{
                  marginLeft: "1rem",
                  width: { lg: "26rem", xl: "20rem" },
                  whiteSpace: "nowrap",
                  paddingLeft: "0.8rem",
                  paddingRight: "0.8rem",
                }}
              >
                {getPreviousButtonText(activeStep)}
              </Button>
            )}

            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                gap: "0.5rem",
                justifyContent: "space-evenly",
                padding: "0 0.5rem",
                width: "95%",
              }}
            >
              {activeStep === 1 && showHideDisable && (
                <FormGroup>
                  <FormControlLabel
                    control={<Switch size="small" />}
                    label="Hide Disabled Calculators"
                    onChange={(e) => handleDisableChange(e)}
                  />
                </FormGroup>
              )}
              {activeStep === 1 && (
                <Box
                  sx={{
                    // marginBottom: "2rem",
                    textAlign: "center",
                    fontWeight: "bolder",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textDecoration: "underline",
                  }}
                >
                  {activeStep === 1 &&
                    `Cost Estimation (User inputs- ${
                      (statusData && statusData?.user_input) || "0/0"
                    }, DB fetch- ${
                      (statusData && statusData?.db_fetch) || "0/0"
                    })`}

                  {activeStep === 1 && (
                    <SyncIcon
                      sx={{
                        fontSize: "18px",
                        marginLeft: "1rem",
                        cursor: "pointer",
                      }}
                      onClick={(e: any) => {
                        e.preventDefault();
                        e.stopPropagation();
                        getCompletedCostModelStatus(true);
                      }}
                    />
                  )}
                </Box>
              )}
              {activeStep === 2 && (
                <Box
                  sx={{
                    // marginBottom: "2rem",
                    textAlign: "center",
                    fontWeight: "bolder",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    textDecoration: "underline",
                  }}
                >
                  {isUndefined(parentdata?.vault_name) &&
                  isUndefined(parentdata?.part_no) ? (
                    <Skeleton animation={false} sx={{ width: "10rem" }} />
                  ) : (
                    `  ${parentdata?.vault_name} (${parentdata?.part_no}) - ${parentdata?.costing_type} `
                  )}
                  <InfoIcon
                    style={{ cursor: "pointer", fontSize: "1.5rem" }}
                    onClick={() => AssumptionCardModal.open()}
                  />
                </Box>
              )}
              <Stepper
                alternativeLabel
                activeStep={activeStep}
                sx={{
                  fontSize: "1rem",
                  fontWeight: "600",
                  display: "none",
                  width: "100%",
                  textAlign: "center",
                  marginRight: "-2rem",
                  cursor: "pointer",
                }}
              >
                {steps.map((label, index) => (
                  <Step
                    key={label}
                    onClick={() => handleStepClick(index)}
                    completed={completedSteps[index]}
                    sx={{ cursor: "pointer" }}
                  >
                    <StepLabel
                      sx={{
                        display: "flex !important",
                        alignItems: "center !important",
                        gap: "0.5rem",
                        "MuiStepLabel-label .MuiStepLabel-alternativeLabel": {
                          marginTop: "0.2rem !important",
                        },
                        color: completedSteps[index]
                          ? "green !important" // Completed step text color
                          : "#007fff !important", // Non-completed step text color
                        "& .MuiStepIcon-root.Mui-completed": {
                          color: "green !important", // Green color for completed step icon
                        },
                        "& .MuiStepIcon-root": {
                          color: !completedSteps[index] ? "#007fff" : undefined, // Gray color for non-completed step icon
                        },
                      }}
                    >
                      {label}
                      
                      {activeStep === 1 && label?.includes("Cost") && (
                        <SyncIcon
                          sx={{ fontSize: "15px", marginLeft: "1rem" }}
                          onClick={(e: any) => {
                            e.preventDefault();
                            e.stopPropagation();
                            getCompletedCostModelStatus(true);
                          }}
                        />
                      )}
                    </StepLabel>
                  </Step>
                ))}
              </Stepper>
            </Box>

            <Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  columnGap: "1rem",
                  justifyContent: "space-between",
                  paddingRight: "1rem",
                  width: "100%",
                }}
              >
                {scenarioId ? (
                  <Box></Box>
                ) : (
                  newUI != "true" && (
                    <Box
                      sx={{
                        display: "flex",
                        columnGap: "1rem",
                        alignItems: "center",
                      }}
                    >
                      Status:{" "}
                      {ValidationLoader ? (
                        <CircularProgress size={17} />
                      ) : (
                        <>
                          {costingStatus == "Not Started" ? (
                            <Box
                              sx={{
                                border: "2px solid #d32f2f",
                                color: "#d32f2f",
                                borderRadius: "5px",
                                height: { lg: "1.8rem", xl: "1.8rem" },
                                padding: "0.15rem 0.5rem",
                                width: {
                                  lg: "9.2rem",
                                  xl: "8rem",
                                  textAlign: "center",
                                },
                              }}
                            >
                              {" "}
                              Not Started
                            </Box>
                          ) : costingStatus == "In Progress" ? (
                            <FormControl
                              sx={{ minWidth: 110 }}
                              size="small"
                              variant="standard"
                            >
                              <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={costingStatus}
                                disabled={
                                  PermissionData &&
                                  PermissionData?.action?.includes("U")
                                    ? false
                                    : true
                                }
                                label="Status"
                                onChange={handleChangeCostingStatus}
                                sx={{
                                  "&:before": {
                                    borderBottomColor: "transparent !important",
                                    borderRadius: "5px",
                                  },
                                  "&:after": {
                                    borderBottomColor: "transparent !important",
                                    borderRadius: "5px",
                                  },
                                  "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                                    {
                                      fontSize: "1rem",
                                      border: "2px solid",
                                      borderRadius: "5px",
                                      height: "1rem",
                                      padding: "0.1rem 0.5rem 0",
                                      color:
                                        costingStatus == "Not Started"
                                          ? "red"
                                          : costingStatus == "In Progress"
                                          ? "#ffc800"
                                          : costingStatus == "completed"
                                          ? "#007fff"
                                          : costingStatus == "validated"
                                          ? "#008000"
                                          : "red", //status Color update
                                    },
                                  "& .MuiInputBase-root.MuiInput-root.MuiSelect-root:hover":
                                    {
                                      border: "2px solid",
                                      borderColor: "red !important", //status Color update
                                    },
                                  ".MuiSvgIcon-root.MuiSelect-icon": {
                                    color: "#ffc800",
                                  },
                                }}
                              >
                                <MenuItem
                                  sx={{
                                    fontSize: "1rem",
                                    color: "red",
                                    display: "none",
                                  }}
                                  value={"Not Started"}
                                >
                                  Not Started
                                </MenuItem>
                                <MenuItem
                                  sx={{
                                    fontSize: "1rem",
                                    color: "#ffc800",
                                    display: "none",
                                  }}
                                  value={"In Progress"}
                                >
                                  In Progress
                                </MenuItem>
                                <MenuItem
                                  sx={{ fontSize: "1rem", color: "#007fff" }}
                                  value={"completed"}
                                >
                                  Completed
                                </MenuItem>
                                {
                                  <MenuItem
                                    sx={{
                                      fontSize: "1rem",
                                      color: "#008000",
                                      display:
                                        costDetailsData?.costing_status ===
                                        "In Progress"
                                          ? "none"
                                          : "",
                                    }}
                                    value={"validated"}
                                  >
                                    Validated
                                  </MenuItem>
                                }
                              </Select>
                            </FormControl>
                          ) : costingStatus == "completed" ? (
                            <FormControl
                              sx={{ minWidth: 110 }}
                              size="small"
                              variant="standard"
                            >
                              <Select
                                labelId="demo-select-small-label"
                                id="demo-select-small"
                                value={costingStatus}
                                disabled={
                                  PermissionData &&
                                  PermissionData?.action?.includes("U")
                                    ? false
                                    : true
                                }
                                label="Status"
                                onChange={handleChangeCostingStatus}
                                sx={{
                                  "&:before": {
                                    borderBottomColor: "transparent !important",
                                    borderRadius: "5px",
                                  },
                                  "&:after": {
                                    borderBottomColor: "transparent !important",
                                    borderRadius: "5px",
                                  },
                                  "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                                    {
                                      fontSize: "1rem",
                                      border: "2px solid",
                                      borderRadius: "5px",
                                      height: "1rem",
                                      padding: "0.1rem 0.5rem 0",
                                      color:
                                        costingStatus == "Not Started"
                                          ? "red"
                                          : costingStatus == "In Progress"
                                          ? "#ffc800"
                                          : costingStatus == "completed"
                                          ? "#007fff"
                                          : costingStatus == "validated"
                                          ? "#008000"
                                          : "red", //status Color update
                                    },
                                  "& .MuiInputBase-root.MuiInput-root.MuiSelect-root:hover":
                                    {
                                      border: "2px solid",
                                      borderColor: "red !important", //status Color update
                                    },
                                  ".MuiSvgIcon-root.MuiSelect-icon": {
                                    color: "primary.main",
                                  },
                                }}
                              >
                                <MenuItem
                                  sx={{
                                    fontSize: "1rem",
                                    color: "red",
                                    display: "none",
                                  }}
                                  value={"Not Started"}
                                >
                                  Not Started
                                </MenuItem>
                                <MenuItem
                                  sx={{
                                    fontSize: "1rem",
                                    color: "#ffc800",
                                    display: "none",
                                  }}
                                  value={"In Progress"}
                                >
                                  In Progress
                                </MenuItem>
                                {
                                  <MenuItem
                                    sx={{
                                      fontSize: "1rem",
                                      color: "#007fff",
                                      display:
                                        costingStatus == "completed"
                                          ? "none"
                                          : "",
                                    }}
                                    value={"completed"}
                                  >
                                    Completed
                                  </MenuItem>
                                }
                                {
                                  <MenuItem
                                    sx={{
                                      fontSize: "1rem",
                                      color: "#008000",
                                      display:
                                        costDetailsData?.costing_status ===
                                        "In Progress"
                                          ? "none"
                                          : "",
                                    }}
                                    value={"validated"}
                                  >
                                    Validated
                                  </MenuItem>
                                }
                              </Select>
                            </FormControl>
                          ) : costingStatus == "validated" ? (
                            <Box
                              sx={{
                                border: "2px solid #008000",
                                color: "#008000",
                                borderRadius: "5px",
                                height: { lg: "2rem", xl: "1.85rem" },
                                padding: "0.15rem 0.5rem",
                                width: {
                                  lg: "9.2rem",
                                  xl: "8rem",
                                  textAlign: "center",
                                },
                              }}
                            >
                              Validated
                            </Box>
                          ) : (
                            ""
                          )}
                        </>
                      )}
                    </Box>
                  )
                )}
                <Box
                  sx={{ display: "flex", alignItems: "center", gap: "1.2rem" }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      columnGap: "1rem",
                    }}
                  >
                    
                    {(userProfile[0]?.is_project_admin ||
                      userProfile[0]?.is_superuser) && <FormControlLabel
                      sx={{
                        width: "fit-content",
                        whiteSpace: "nowrap",
                        marginRight: "0.5rem",
                        fontSize: "1rem",
                      }}
                      control={
                        <Switch size="small" checked={newUI == "true"} />
                      }
                      label={newUI == "true" ? "New UI" : "Old UI"}
                      onChange={() => {
                        history.push(
                          `/createcostingpart/${projectId}/${projectname}/${productId}/${topVault}/${vault}/${abbrebation}/${0}/${0}/${0}/${0}/${0}/${0}/${0}/${0}/${0}/${
                            newUI == "true" ? false : true
                          }`
                        );
                      }}
                    />}
                    <img
                      title="Go to BOM"
                      src={BOMIcon}
                      style={{ height: "1.5rem", cursor: "pointer" }}
                      onClick={() => {
                        sessionStorage.setItem("node", JSON.stringify(costDetailsData?.ancestors));
                        window.open(
                          `/#/view/${projectId}/${topVault}/${vault}/${
                            abbrebation === "none" ? "null" : abbrebation
                          }/true`,
                        );
                      }}
                    />
                    {costDetailsData && (
                      <img
                        title="Project Configuration"
                        src={config}
                        style={{
                          width: "1.5rem",
                          height: "1.5rem !important",
                          verticalAlign: "middle",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          if (costDetailsData?.costing_category) {
                            const newUrl = `/costingconfiguration-template/${projectId}/${projectname}/${costDetailsData?.costing_category}/${costDetailsData?.costing_category_group}/false/0/0/0`;
                            window.open(
                              `${window.location.origin}/#${newUrl}`,
                              "_blank"
                            );
                          } else {
                            Swal.fire({
                              icon: "error",
                              title: "Please select costing category",
                            });
                          }
                        }}
                      />
                    )}
                    {scenarioId ? (
                      <Box>
                        <StyledMenu
                          id="demo-customized-menu"
                          MenuListProps={{
                            "aria-labelledby": "demo-customized-button",
                          }}
                          anchorEl={anchorElRedirect}
                          open={openRedirect}
                          onClose={handleCloseRedirect}
                        >
                          {redirectData &&
                            redirectData
                              ?.filter((item: any) => item.id != scenarioId)
                              ?.map((item: any) => {
                                return (
                                  <MenuItem
                                    sx={{
                                      fontSize: "1rem",

                                      color: "primary.main",

                                      padding: "0.2rem 0.5rem",
                                    }}
                                    // /view/:projectId/:topVault/:vault/:treeabbr/costing/</StyledMenu>
                                    onClick={() =>
                                      item?.name === "Main Costing"
                                        ? window.open(
                                            `/#/view/${projectId}/${topVault}/${vault}/${abbrebation}/costing`
                                          )
                                        : window.open(
                                            `/#/createcostingpart/${projectId}/${projectname}/${item?.product_name}/${topVault}/${vault}/${abbrebation}/${cardtype}/${tableId}/${costEstimationId}/${tableCounter}/${condition}/${calctype}/${calcultorId}/${item?.id}/0/${newUI}`
                                          )
                                    }
                                  >
                                    <span
                                      style={{
                                        marginRight: "0.5rem",
                                        whiteSpace: "nowrap",
                                        textOverflow: "ellipsis",
                                        overflow: "hidden",
                                        width: "12rem",
                                      }}
                                      title={item.name}
                                    >
                                      {item?.name}
                                    </span>

                                    {/* <span
                            style={{ marginRight: "0.5rem", color: "green" }}
                          >
                            {item?.currency}
                          </span> */}

                                    <span style={{ color: "green" }}>
                                      {item?.cost}
                                    </span>
                                  </MenuItem>
                                );
                              })}
                        </StyledMenu>
                        <Button
                          variant="contained"
                          endIcon={
                            <img
                              src={arrowRight}
                              style={{ height: "1.1rem" }}
                            />
                          }
                          title="Other Scenarios"
                          sx={{
                            padding: "0.4rem 0.7rem",
                            minWidth: "1.5rem",
                            lineHeight: 1,
                          }}
                          onClick={handleClickRedirect}
                        >
                          Other Scenarios
                        </Button>
                      </Box>
                    ) : (
                      <DescriptionIcon
                        titleAccess="Costing Guideline"
                        sx={{
                          cursor: "pointer",
                          fontSize: "1.5rem",
                          height: "1.5rem !important",
                        }}
                        onClick={() => guidelinesHandler()}
                      />
                    )}

                    {scenarioId ? (
                      <Box></Box>
                    ) : (
                      <img
                        src={scnarioprog}
                        style={{ height: "1.3rem", cursor: "pointer" }}
                        onClick={() => redirectScenario()}
                      />
                    )}
                    {newUI != "true" && (
                      <FormControl
                        sx={{ m: 1, minWidth: 60 }}
                        size="small"
                        variant="standard"
                      >
                        <Select
                          labelId="demo-select-small-label"
                          id="demo-select-small"
                          value={status}
                          disabled={
                            (PermissionData &&
                            PermissionData?.action?.includes("U")
                              ? false
                              : true) || loadingButton
                          }
                          label="BOP"
                          onChange={handleChange}
                          sx={{
                            display: scenarioId ? "none" : "",
                            "&:before": {
                              borderBottomColor: "primary.main",
                              borderRadius: "5px",
                            },
                            "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                              {
                                fontSize: "1rem",
                                border: "2px solid",
                                borderRadius: "5px",
                                height: "1rem",
                                padding: "0.1rem 0.5rem 0",
                              },
                            ".MuiSvgIcon-root.MuiSelect-icon": {
                              color: "primary.main",
                            },
                          }}
                        >
                          <MenuItem sx={{ fontSize: "1rem" }} value={"bop"}>
                            BOP
                          </MenuItem>
                          <MenuItem sx={{ fontSize: "1rem" }} value={"dc"}>
                            DC
                          </MenuItem>
                        </Select>
                      </FormControl>
                    )}
                    {scenarioId ? (
                      <Box></Box>
                    ) : (
                      <QrCodeScannerIcon
                        titleAccess="Scan QR"
                        sx={{
                          cursor: "pointer",
                          height: "1.5rem !important",
                          fontSize: "1.5rem",
                        }}
                        onClick={() =>
                          history.push(
                            `/costingscanner/${projectId}/${projectname}`
                          )
                        }
                      />
                    )}
                  </Box>
                </Box>
                {((userProfile[0]?.is_project_admin ||
                      userProfile[0]?.is_superuser)) &&
                  costDetailsData?.show_delete_button && (
                    <DeleteIcon
                      titleAccess="Delete Costing"
                      color="error"
                      sx={{
                        fontSize: "1.5rem",
                        cursor: "pointer",
                        height: "1.5rem !important",
                      }}
                      onClick={() => {
                        swal({
                          title: `Delete Costing`,
                          text: `Are you sure?`,
                          icon: "error",
                          //@ts-ignore
                          buttons: {
                            cancel: "Cancel",
                            permanent: {
                              text: `Delete Costing`,
                              // value: `deleteParameterLibrary`,
                            },
                          },
                          closeOnClickOutside: false,
                          closeOnEsc: false,
                          className: "delete-swal",
                        }).then(async (type: string) => {
                          if (type === "permanent") {
                            API.delete(
                              `/cost/update_estimation/${costDetailsData?.cost_estimation}/`
                            )
                              .then((res: any) => {
                                setCount((prev: any) => prev + 1);
                                setActiveStep((step: any) => step - 1);
                                window.location.reload();
                              })
                              .catch((err: any) => {
                                const { data }: any = err?.response;
                                Swal.fire({
                                  icon: "error",
                                  html: `<div>
                                      <br />
                                      <p style="color:"red">${data[0]}</p>   
                                    </div>`,
                                });
                              });
                          } else if (type == "Cancel") {
                            // @ts-ignore
                            swal.close();
                          }
                        });
                      }}
                    />
                  )}

                {activeStep !== 2 ? (
                  <Button
                    variant="contained"
                    size="small"
                    disabled={getDisabledStatus() || loadingButton}
                    sx={{ whiteSpace: "nowrap" }}
                    endIcon={<ArrowForwardIosIcon />}
                    onClick={() => handleStepClick(activeStep + 1)}
                  >
                    {getNextButtonText(activeStep)}
                  </Button>
                ) : costDetailsData?.costing_status === "validated" ? (
                  <Button
                    variant="outlined"
                    sx={{
                      height: "1.5rem",
                      whiteSpace: "nowrap",
                      color: "green",
                    }}
                  >
                    Validated
                  </Button>
                ) : (
                  <Button
                    size="small"
                    variant="contained"
                    color="primary"
                    endIcon={<ArrowForwardIosIcon />}
                    disabled={costDetailsData?.costing_status === "validated"}
                    onClick={() => {
                      handleChangeCostingStatus(() => {
                      });
                    }}
                    sx={{
                      marginRight: "1rem",
                      // marginBottom: "2rem",
                      whiteSpace: "nowrap",
                    }}
                  >
                    Validate
                  </Button>
                )}
              </Box>
            </Box>
          </Box>}
          <Box sx={{ width: "100%", padding: 1, height: "88vh" }}>
            {(activeStep !== 0 && activeStep !== 1 && activeStep !== 2) ? (
              <Box
                sx={{
                  background: theme.palette.background.default,
                  borderRadius: "10px",
                  textAlign: "center",
                  height: "100%",
                  width: "100%",
                  transition: "all 0.3s ease-in-out",
                }}
              >
                <Stack spacing={1}>
                  {/* For variant="text", adjust the height via font-size */}
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                  <Skeleton variant="text" sx={{ fontSize: "1rem" }} />
                </Stack>
              </Box>
            ) : (
              <Box
                sx={{
                  background: theme.palette.background.default,
                  borderRadius: "10px",
                  textAlign: "center",
                  height: "100%",
                  width: "100%",
                  transition: "all 0.3s ease-in-out",
                }}
              >
                {costDetailsData && activeStep === 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      width: "100%",
                      alignItems: "center",
                      flexDirection: "column",
                      gap: "1.1rem",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      {activeStep === 0 && (
                        <Stepper
                          activeStep={activefield}
                          orientation="vertical"
                          sx={{ width: "20%" }}
                        >
                          {stepsFields.map((label, index) => (
                            <Step
                              key={label}
                              completed={checkFieldCompletion(index)}
                            >
                              <StepLabel
                                StepIconProps={{
                                  sx: {
                                    color: checkFieldCompletion(index)
                                      ? "green !important"
                                      : undefined, // Completed step icon color is green
                                    "& .MuiStepIcon-root.Mui-completed": {
                                      color: "green !important", // Green color for completed step icon
                                    },
                                  },
                                }}
                              >
                                {label}{" "}
                                {(label === "Main Assumption Card" ||
                                  label === "Configuration") && (
                                  <sup style={{ color: "red" }}>*</sup>
                                )}
                              </StepLabel>
                            </Step>
                          ))}
                        </Stepper>
                      )}
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "1.5rem",
                          margin: "1rem 1rem",
                          alignItems: "center",
                          width: "100%",
                        }}
                      >
                        {isShowCost && (
                          <FormControl fullWidth>
                            <Select
                              variant="standard"
                              size="small"
                              labelId="demo-simple-select-label"
                              id="demo-simple-select"
                              placeholder="Select Assumption card"
                              value={selectedAssumption}
                              displayEmpty
                              renderValue={(selectedAssumption) => {
                                if (!selectedAssumption) {
                                  return (
                                    <Typography
                                      sx={{
                                        fontSize: "1rem",
                                        opacity: "0.5",
                                        marginTop: "0.5rem",
                                      }}
                                    >
                                      Select Assumption card
                                    </Typography>
                                  );
                                } else {
                                  return (
                                    assumptionList &&
                                    assumptionList?.filter(
                                      (item: any) =>
                                        item.id == selectedAssumption
                                    )[0]?.name
                                  );
                                }
                              }}
                              label="Select Assumption card"
                              disabled={
                                (costDetailsData &&
                                costDetailsData?.permission?.includes("U")
                                  ? false
                                  : true) || loadingButton
                              }
                              onChange={(ed: any) => {
                                setActivefield(0);
                                handleChange(ed);
                              }}
                              sx={{
                                width: "100%",
                                textAlign: "left",
                                "&:before": {
                                  borderBottomColor: "primary.main",
                                },
                                "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                                  {
                                    fontSize: "1rem",
                                    paddingLeft: "0.5rem",
                                  },
                                ".MuiSvgIcon-root.MuiSelect-icon": {
                                  color: "primary.main",
                                },
                              }}
                            >
                              {assumptionList &&
                                assumptionList?.map((item: any) => {
                                  return (
                                    <MenuItem
                                      sx={{
                                        fontSize: "1rem",
                                        backgroundColor:
                                          assumptionList?.is_mapped === true
                                            ? "#b3f7b3"
                                            : "white",
                                        color:
                                          assumptionList?.is_mapped === true
                                            ? "green"
                                            : "primary.main",
                                      }}
                                      value={item?.id}
                                    >
                                      {item?.name}
                                    </MenuItem>
                                  );
                                })}
                            </Select>
                          </FormControl>
                        )}
                        {isShowCost && (
                          <FormControl fullWidth>
                            <Autocomplete
                              multiple
                              disableClearable
                              disabled={
                                (costDetailsData &&
                                costDetailsData?.permission?.includes("U")
                                  ? false
                                  : true) || loadingButton
                              }
                              filterSelectedOptions
                              disableCloseOnSelect
                              onChange={(event: any, newSystemValue: any) => {
                                setActivefield(4);
                                handleSelectChangAssumtionParams(
                                  event,
                                  newSystemValue
                                );
                              }}
                              id="tags-standard"
                              limitTags={1}
                              options={multipleDataSet && multipleDataSet}
                              value={
                                Selectedmulassumption && Selectedmulassumption
                              }
                              getOptionLabel={(option: any) => option.name}
                              sx={{
                                ".MuiButtonBase-root.MuiIconButton-root.MuiAutocomplete-popupIndicator":
                                  {
                                    color: "primary.main",
                                  },
                                ".MuiButtonBase-root.MuiChip-root": {
                                  height: "26px",
                                },
                              }}
                              renderOption={(props, option) => (
                                <li
                                  {...props}
                                  style={{
                                    fontSize: "1rem",
                                    backgroundColor:
                                      option?.is_mapped === true
                                        ? "#b3f7b3"
                                        : "primary.light",
                                    color:
                                      option?.is_mapped === true
                                        ? "green"
                                        : "primary.main",
                                  }}
                                >
                                  {option.name}
                                </li>
                              )}
                              renderTags={(value: any[], getTagProps: any) =>
                                value.map((option, index) => {
                                  return (
                                    <Chip
                                      key={index}
                                      label={option.name}
                                      {...getTagProps({ index })}
                                      title={option.name} // Set title attribute with full text content
                                      sx={{
                                        backgroundColor:
                                          option?.is_mapped === true
                                            ? "#b3f7b3"
                                            : "primary.light",
                                        color:
                                          option?.is_mapped === true
                                            ? "green"
                                            : "primary.main",
                                      }} // Update chip styles as needed
                                    />
                                  );
                                })
                              }
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  variant="standard"
                                  placeholder="Select Other Assumptions"
                                  sx={{
                                    ".MuiInputBase-root.MuiInput-root": {
                                      fontSize: "1rem !important",
                                    },
                                    "& .MuiInput-underline:before": {
                                      borderBottomColor: "primary.main",
                                    },
                                    "& .MuiInput-underline:after": {
                                      borderBottomColor: "primary.main",
                                    },
                                  }}
                                />
                              )}
                            />
                          </FormControl>
                        )}
                        {
                          <FormControl fullWidth variant="standard">
                            {/* <InputLabel
                          sx={{ color: "black", opacity: 0.5,mb:2 }}
                          id="demo-simple-select-label"
                        >
                          Select Configuration
                        </InputLabel> */}
                            <Box
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                gap: 3,
                                width: "100%",
                              }}
                            >
                              <FormControl variant="standard">
                                <Select
                                  labelId="demo-simple-select-label"
                                  id="demo-simple-select"
                                  value={selectedCostType}
                                  label="Costing Type"
                                  onChange={handleTypeChange}
                                  disabled={loadingButton}
                                  sx={{
                                    width: "10rem",
                                    "&:before": {
                                      borderBottomColor: "primary.main",
                                    },
                                    "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select":
                                      {
                                        fontSize: "1rem",
                                        paddingLeft: "0.5rem",
                                        textAlign: "left",
                                      },
                                    ".MuiSvgIcon-root.MuiSelect-icon": {
                                      color: "primary.main",
                                    },
                                  }}
                                >
                                  <MenuItem value="dc">DC</MenuItem>
                                  <MenuItem value="bop">BOP</MenuItem>
                                </Select>
                              </FormControl>

                              <Select
                                labelId="demo-simple-select-standard-label"
                                id="demo-simple-select-standard"
                                disabled={
                                  categoryLoader ||
                                  CostType == null ||
                                  CostType == "" ||
                                  (CostType == undefined
                                    ? true
                                    : selectedAssumption
                                    ? false
                                    : true) ||
                                  loadingButton
                                }
                                // value={
                                //   isCategoryValidated ? selectedCategory : unvalidatedCategory ? unvalidatedCategory : selectedCategory
                                // }
                                value={
                                  isCategoryValidated
                                    ? selectedCategory
                                    : unvalidatedCategory || selectedCategory
                                }
                                onChange={(e: any) => {
                                  if (selectedCategory) {
                                    Swal.fire({
                                      title: "Are you sure?",
                                      text: "Changing the configuration will reset the costing data. Do you want to continue?",
                                      icon: "warning",
                                      showCancelButton: true,
                                      confirmButtonColor: "#3085d6",
                                      cancelButtonColor: "#d33",
                                      confirmButtonText: "Yes, continue!",
                                      customClass: {
                                        container: "swal2Container",
                                      },
                                    }).then((result) => {
                                      if (result.isConfirmed) {
                                        setLoadingButton(true);
                                        setActivefield(1);
                                        const { value } = e.target;
                                        API.put(
                                          `/cost/get_all_validated_configuration_cat/${vault}/`,
                                          {
                                            costing_category: value,
                                            costing_type: CostType,
                                          },
                                          {},
                                          0
                                        )
                                          .then(() => {
                                            setSelectedCategory(value);
                                            setLoadingButton(false);
                                            getCostDetailsDataWOL();
                                          })
                                          .catch((err: any) => {
                                            ADMIN.toast.error(
                                              err?.response?.data[0]
                                            );
                                            setLoadingButton(false);
                                          });
                                      }
                                    });
                                  } else {
                                    setLoadingButton(true);
                                    setActivefield(1);
                                    const { value } = e.target;
                                    API.put(
                                      `/cost/get_all_validated_configuration_cat/${vault}/`,
                                      {
                                        costing_category: value,
                                        costing_type: CostType,
                                      },
                                      {},
                                      0
                                    )
                                      .then(() => {
                                        setSelectedCategory(value);
                                        getCostDetailsDataWOL();
                                        setLoadingButton(false);
                                      })
                                      .catch((err: any) => {
                                        ADMIN.toast.error(
                                          err?.response?.data[0]
                                        );
                                        setLoadingButton(false);
                                      });
                                  }
                                }}
                                sx={{
                                  fontSize: "1rem",
                                  textAlign: "left",
                                  marginLeft: "1rem",
                                  width: loadingButton ? "98%" : "100%",
                                  "&:before": {
                                    borderBottomColor: "primary.main",
                                  },
                                  ".MuiSvgIcon-root.MuiSelect-icon": {
                                    color: "primary.main",
                                  },
                                }}
                                MenuProps={{
                                  PaperProps: {
                                    style: {
                                      maxHeight: "51vh", // Set the max height of the dropdown in pixels
                                    },
                                  },
                                }}
                              >
                                {categoryList &&
                                  categoryList?.map((item: any, index: any) => {
                                    return (
                                      <MenuItem key={index} value={item.id}>
                                        {item.name}
                                      </MenuItem>
                                    );
                                  })}
                                {!isCategoryValidated &&
                                  unvalidatedCategory && (
                                    <MenuItem
                                      sx={{ visibility: "hidden" }}
                                      value={unvalidatedCategory}
                                    >
                                      {unvalidatedCategory}
                                    </MenuItem>
                                  )}
                              </Select>
                              {loadingButton && (
                                <CircularProgress
                                  size={14}
                                  color="primary"
                                  sx={{ marginLeft: 2, width: "2%" }}
                                />
                              )}
                            </Box>
                          </FormControl>
                        }
                      </Box>
                    </Box>
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          gap: "1.5rem",
                          padding: "0 2rem",
                        }}
                      >
                        <Typography
                          sx={{
                            color: "primary.main",
                            textDecoration: "underline",
                            fontWeight: "bolder",
                          }}
                        >
                          Costing Guidelines :
                        </Typography>

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row", // Ensures horizontal layout
                            gap: "1rem",
                            height: "auto", // Adjust height to fit the content
                            overflowX: "auto", // Allows horizontal scrolling if images overflow
                            alignItems: "center", // Centers images vertically
                          }}
                        >
                          {/* Initialize RViewerJS with all the image URLs */}
                          {/* @ts-ignore */}
                          <RViewerJS
                            imageUrls={costDetailsData?.images?.map(
                              (item: any) => item?.image
                            )}
                          >
                            {/* Render only the first four images or all if needed */}
                            {costDetailsData?.images?.map(
                              (item: any, index: number) => (
                                <Box
                                  key={index}
                                  sx={{
                                    cursor: "pointer",
                                    minWidth: "4rem", // Set a minimum width for each image container
                                    height: "4rem", // Ensure height is consistent across images
                                    display: "inline-block", // Force images to stay inline
                                    marginLeft: "1rem",
                                  }}
                                >
                                  <Image
                                    src={item?.image}
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                      objectFit: "contain",
                                      borderRadius: "5px",
                                      cursor: "pointer",
                                      display: index + 1 > 4 ? "none" : "block",
                                    }}
                                  />
                                </Box>
                              )
                            )}
                          </RViewerJS>
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          width: { lg: "96vw", xl: "97vw" },
                          overflowY: "auto",
                          textAlign: "left",
                          padding: "0 2rem",
                        }}
                      >
                        <JoditEditor
                          ref={editor}
                          config={editorConfig}
                          // className="CostingConfigMailEditor"
                          value={content}
                          // onBlur={(newContent: any) => setContent(newContent)}
                        />
                      </Box>
                    </Box>
                  </Box>
                )}

                {costDetailsData && activeStep === 1 && (
                  <>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-around",
                        width: "100%",
                      }}
                    >
                      <Box
                        sx={{
                          width: "50%",
                          height: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            gap: "1rem",
                            mb: 1,
                          }}
                        ></Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "flex-end",
                            width: "100%",
                            backgroundColor: "#007fff1f",
                          }}
                        >
                          <Box
                            sx={{
                              whiteSpace: "nowrap",
                              padding: "0 0.2rem",
                              fontWeight: "bold",
                              width: "10rem",
                              textAlign: "center",
                            }}
                          >
                            Qty
                          </Box>
                          <Box
                            sx={{
                              whiteSpace: "nowrap",
                              padding: "0 0.2rem",
                              fontWeight: "bold",
                              width: "10rem",
                              textAlign: "center",
                            }}
                          >
                            Var/For
                          </Box>
                          <Box
                            sx={{
                              whiteSpace: "nowrap",
                              padding: "0 0.2rem",
                              fontWeight: "bold",
                              width: "7rem",
                              textAlign: "center",
                              cursor: "pointer",
                            }}
                          >
                            Cost (INR)
                          </Box>
                        </Box>

                        <Box
                          sx={{
                            height: { lg: "80vh", xl: "83vh" },
                            width: "100%",
                          }}
                          adl-scrollbar="true"
                          adl-scrollbar-width="0.3"
                        >
                          {!loading ? (
                            <>
                              <Accordion
                                expanded={treeexpanded === "panel1"}
                                sx={{ border: "none" }}
                              >
                                <SelectableAccordionSummary
                                  aria-controls="panel1d-content"
                                  id="panel1d-header"
                                  expandIcon={
                                    treeexpanded === "panel1" ? (
                                      <img
                                        src={openFolder}
                                        style={{
                                          height:
                                            width > 1369 ? "1.05rem" : "1.3rem",
                                        }}
                                      />
                                    ) : (
                                      <FolderIcon
                                        sx={{
                                          fontSize: {
                                            lg: "1.5rem",
                                            xl: "1.4rem",
                                          },
                                          color: "primary.main",
                                        }}
                                      />
                                    )
                                  }
                                  sx={{
                                    minHeight: "24px",
                                    padding: "0 0.2rem",
                                    marginRight: "0.5rem",
                                  }}
                                >
                                  <Box
                                    sx={{
                                      display: "flex",
                                      alignItems: "center",
                                      justifyContent: "space-between",
                                      width: "100%",
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        marginLeft: "0.5rem",
                                        width: { lg: "45%", xl: "51%" },
                                        textTransform: "none",
                                        textAlign: "left",
                                        lineHeight: "1.3",
                                      }}
                                    >
                                      <Typography
                                        title={treeDataHeader?.name}
                                        sx={{
                                          fontSize: "1rem",
                                          width: { lg: "20rem", xl: "29rem" },
                                        }}
                                      >
                                        {treeDataHeader?.name}
                                      </Typography>
                                    </Box>
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "flex-end",
                                        gap: "0.5rem",
                                        width: { lg: "56%", xl: "46%" },
                                      }}
                                    >
                                      <Box
                                        sx={{
                                          whiteSpace: "nowrap",
                                          padding: "0 0.2rem",
                                          width: { lg: "10rem", xl: "10rem" },
                                          textTransform: "none",
                                          textAlign: "center",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          borderRight: "1px solid #007fff1f",
                                        }}
                                      >
                                        <Typography sx={{ fontSize: "1rem" }}>
                                          {treeDataHeader?.show_count}
                                        </Typography>
                                      </Box>
                                      <Box
                                        sx={{
                                          whiteSpace: "nowrap",
                                          padding: "0 0.2rem",
                                          width: { lg: "10rem", xl: "10rem" },
                                          textTransform: "none",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          borderRight: "1px solid #007fff1f",
                                          color: "green",
                                          cursor: "pointer",
                                        }}
                                      >
                                        <Typography sx={{ fontSize: "1rem" }}>
                                          {treeDataHeader?.alias}{" "}
                                        </Typography>
                                      </Box>
                                      <Box
                                        sx={{
                                          whiteSpace: "nowrap",
                                          padding: "0 0.2rem",
                                          width: "5rem",
                                          textTransform: "none",
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          textAlign: "center",
                                        }}
                                      >
                                        <Typography sx={{ fontSize: "1rem" }}>
                                          {treeDataHeader?.cost}
                                        </Typography>
                                      </Box>
                                    </Box>
                                  </Box>
                                </SelectableAccordionSummary>
                                <AccordionDetails>

                               
                                  
                                  <Box sx={{ marginLeft: '1rem' }}>
                                  {[
                                    { data: treeDataMaterial, type: "material" },
                                    { data: treeDataProcess, type: "process" },
                                    { data: treeDataOverhead, type: "overhead" },
                                    {
                                      data: treeDataChildCost,
                                      type: "child_cost",
                                      condition: !costDetailsData?.is_leaf,
                                    },
                                  ]
                                    ?.filter((item: any) => {
                                      // Check that data is not null/undefined
                                      const isValidData =
                                        item?.data !== null && item?.data !== undefined;

                                      // Check if data is an object (not an array)
                                      const isObject =
                                        typeof item?.data === "object" &&
                                        !Array.isArray(item.data);

                                      // If data is an array, it should not be empty
                                      const isValidArray =
                                        Array.isArray(item?.data) && item?.data?.length > 0;

                                      // Return true if data is either a valid object or a non-empty array
                                      return (
                                        (isValidData && (isObject || isValidArray)) ||
                                        item?.condition
                                      );
                                    })
                                    ?.map((item, index) => (
                                      <CreateCostingCategoryTreeNew
                                        key={index} // use key to ensure unique identification in a list
                                        HierarchyData={item.data}
                                        type={item.type}
                                        onSelectCallback={onSelect}
                                        setSelectedTreeData={getCalcData}
                                        setCounterApiCall={setCounterApiCall}
                                        useraction="CRUD"
                                        getTreeData={getTreeData}
                                        setOpenForm={setOpenForm}
                                        costDetailsData={costDetailsData}
                                        SelectedmulIds={SelectedmulIds}
                                        AddBOPRefreshTree={AddBOPRefreshTree}
                                      />
                                    ))}
                                </Box>
                                </AccordionDetails>
                              </Accordion>
                            </>
                          ) : (
                            <Box
                              sx={{
                                width: "56%",
                                padding: "0 0.2rem",
                                height: "86vh",
                              }}
                            >
                              <Skeleton sx={{ height: "2rem", width: "30%" }} />
                              <Skeleton sx={{ height: "2rem", width: "45%" }} />
                              <Skeleton sx={{ height: "2rem", width: "50%" }} />
                              <Skeleton sx={{ height: "2rem", width: "65%" }} />
                              <Skeleton sx={{ height: "2rem", width: "70%" }} />
                              <Skeleton sx={{ height: "2rem", width: "60%" }} />
                              <Skeleton sx={{ height: "2rem", width: "55%" }} />
                              <Skeleton sx={{ height: "2rem", width: "30%" }} />
                              <Skeleton sx={{ height: "2rem", width: "45%" }} />
                              <Skeleton sx={{ height: "2rem", width: "50%" }} />
                              <Skeleton sx={{ height: "2rem", width: "35%" }} />
                              <Skeleton sx={{ height: "2rem", width: "70%" }} />
                              <Skeleton sx={{ height: "2rem", width: "55%" }} />
                              <Skeleton sx={{ height: "2rem", width: "80%" }} />
                              <Skeleton sx={{ height: "2rem", width: "30%" }} />
                              <Skeleton sx={{ height: "2rem", width: "45%" }} />
                              <Skeleton sx={{ height: "2rem", width: "50%" }} />
                              <Skeleton sx={{ height: "2rem", width: "65%" }} />
                              <Skeleton sx={{ height: "2rem", width: "70%" }} />
                              <Skeleton sx={{ height: "2rem", width: "60%" }} />
                              <Skeleton sx={{ height: "2rem", width: "55%" }} />
                              <Skeleton sx={{ height: "2rem", width: "30%" }} />
                              <Skeleton sx={{ height: "2rem", width: "45%" }} />
                              <Skeleton sx={{ height: "2rem", width: "50%" }} />
                              <Skeleton sx={{ height: "2rem", width: "35%" }} />
                              <Skeleton sx={{ height: "2rem", width: "70%" }} />
                              <Skeleton sx={{ height: "2rem", width: "55%" }} />
                              <Skeleton sx={{ height: "2rem", width: "80%" }} />
                            </Box>
                          )}
                        </Box>
                      </Box>
                      <Box sx={{ width: "56%", height: "75vh" }}>
                        <Box
                          sx={{ flexGrow: 1, width: "100%", height: "89vh" }}
                        >
                          <Box>
                            <Box
                              sx={{
                                zIndex: "0",
                                position: "relative",
                              }}
                            >
                              {isShowCost &&
                                tableId !== 0 &&
                                calcultorId != 0 && (
                                  <userPermission.Provider
                                    value={PermissionData && PermissionData}
                                  >
                                    <costingDetails.Provider
                                      value={costDetailsData}
                                    >
                                      <CostingCalculatorConfig
                                        passingObj={{}}
                                        calculatorData={calculatorData}
                                        costDetailsData={costDetailsData}
                                      />
                                    </costingDetails.Provider>
                                  </userPermission.Provider>
                                )}
                            </Box>
                          </Box>
                        </Box>
                      </Box>
                      {costDetailsData?.costing_status !== "Not Started" && (
                        <Box className={styles.BomDrawer}>
                          <Box className={styles.drabtnSec}>
                            <Draggable axis="y">
                              <Box
                                className={styles.bombutton}
                                onClick={BomdataDrawer}
                              >
                                <Typography
                                  className={styles.text}
                                  sx={{
                                    fontSize: "1rem",
                                    color: "white",
                                    padding: "0.5rem 0.2rem",
                                  }}
                                >
                                  BOM Data
                                </Typography>
                              </Box>
                            </Draggable>
                          </Box>
                          <Box
                            className={
                              isActive
                                ? styles.bomTableActive
                                : styles.bomTableInactive
                            }
                          >
                            <Box className={styles.bomBodySection}>
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  columnGap: "1px",
                                }}
                              >
                                <Box
                                  onClick={() => TabHandler("Parameters")}
                                  className={
                                    Tabstatus == "Parameters"
                                      ? styles.bomactiveTab
                                      : styles.bomTab
                                  }
                                >
                                  Parameters
                                </Box>
                                <Box
                                  onClick={() => TabHandler("Table")}
                                  className={
                                    Tabstatus == "Table"
                                      ? styles.bomactiveTab
                                      : styles.bomTab
                                  }
                                >
                                  Table
                                </Box>
                              </Box>
                              <Box
                                sx={{
                                  display: "grid",
                                  gridTemplateColumns: "1fr 3rem",
                                }}
                              >
                                <BOMDrawerTable TabStatus={Tabstatus} />
                                <Box
                                  onClick={BomdataDrawer}
                                  sx={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    backgroundColor: "primary.main",
                                    color: "white",
                                    cursor: "pointer",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      transform: "rotateZ(270deg)",
                                      whiteSpace: "nowrap",
                                    }}
                                  >
                                    Close BOM Data
                                  </Typography>
                                </Box>
                              </Box>
                            </Box>
                          </Box>
                        </Box>
                      )}
                    </Box>
                  </>
                )}
                {costDetailsData && activeStep === 2 && (
                  <>
                    <CostingAccordionParentCreate
                      onCloseModal={() => {
                        console.log("closed");
                      }}
                      parentdata={parentdata}
                      setParentData={setParentData}
                      costDetailsData={costDetailsData}
                      handleChangeCostingStatus={handleChangeCostingStatus}
                    />
                  </>
                )}
                {AssumptionCardModal.isOpen && (
                  <AssumptionModal
                    isOpen={AssumptionCardModal.isOpen}
                    onClose={AssumptionCardModal.close}
                  />
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </div>
  );
}
