import * as React from "react";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import {
  Box,
  Button,
  ButtonProps,
  IconButton,
  InputAdornment,
  Popover,
  Skeleton,
  Table,
  TableCell,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  styled,
} from "@mui/material";
import { DataGrid, GridColDef, GridCsvExportMenuItem, GridCsvExportOptions, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarExportContainer, GridValueGetterParams } from "@mui/x-data-grid";
import { API } from "../../../api-services";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { useCallback, useState } from "react";
import SaveIcon from "@mui/icons-material/Save";
import AddIcon from "@mui/icons-material/Add";

import { useHistory } from "react-router-dom";
import { useBaseModal } from "../../../ComponentUI/SearchPage/useBaseModal";
import FlagOutlinedIcon from "@mui/icons-material/FlagOutlined";
import LoadingButton from "@mui/lab/LoadingButton";
import EqualizerIcon from '@mui/icons-material/Equalizer';
import InfoIcon from '@mui/icons-material/Info';
import Flag from "../../../Assets/images/flag.png"
import FlagIcon from '@mui/icons-material/Flag';
import LaunchIcon from '@mui/icons-material/Launch';
import AddMachineListModal from "../MHRDataBase/FetchMachineListModal";
import MachineFlagModal from "../MHRDataBase/MachineFlagModal";
import EditLHRModal from "./EditLHRModal";
import EditIcon from '@mui/icons-material/Edit';
import Swal from "sweetalert2";
import successIcon from "../../../Assets/images/success.png"
import FetchLabourListModal from "./FetchLabourListModal";
import LabourmoveModal from "./LabourmoveModal";
import LabourGraph from "./LabourGraph";
import useWindowDimensions from "../../../ui-reusable-component/useWindowDimensions";
import move from "../../../Assets/images/right move.svg";
import { LHRRevisionInfoModal } from "./LHRRevisionInfoModal";
import swal from "sweetalert";
import DeleteIcon from '@mui/icons-material/Delete';
import { useSelector } from "react-redux";
import { userSelectors } from "../../../Redux/Reducers/user.reducer";
import LockIcon from '@mui/icons-material/Lock';

import LockOpenIcon from '@mui/icons-material/LockOpen';
import LabourHourRateActionComponents from "../ActionComponents/LabourHourRateActionComponents";
import MenuIcon from '@mui/icons-material/Menu';


export interface IRawMaterialRevisionTableProps {

  setRecyclebinCounter: any;
}

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const csvOptions: GridCsvExportOptions = {
  fileName: "Labour Hours Rates CSV", delimiter: ",", utf8WithBom: true
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridToolbarColumnsButton />
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer style={{ height: "2rem" }}>
    <span style={{ position: "absolute", right: "0rem", fontSize: "1rem", paddingRight: '1rem' }}>

      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem", color: 'primary.main' }} />}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export function SearchLHRrevisionPage(props: IRawMaterialRevisionTableProps) {




  const { tab, year, month, revisionId, typeId, databaseName, status, search, categoryName, lockCounter } =
    useRouteParams<any>();

  const [expanded, setExpanded] = React.useState<string | false>(categoryName);
  const [CategoryList, setCategoryList] = React.useState<any>([]);
  const [CommodityLoader, setCommodityLoader] = useState<any>(false)
  const { setRecyclebinCounter } = props
  const [headerData, setHeaderData] = useState<any>([]);
  const [TableData, setTableData] = useState<any>([]);

  const [HourValue, setHourValue] = useState<any>();
  const [dayValues, setdayValues] = useState<any>();

  const [statusColor, setStatusColor] = useState<any>(false)
  const [Loader, setLoader] = useState<any>(false)
  const history = useHistory<any>()
  const machinemodal = useBaseModal()
  const [Counter, setCounter] = useState<any>(0)
  const [CounterEdit, setCounterEdit] = useState<any>(0)
  const [tableLoader, settableLoder] = useState<any>(false)
  const [RowItem, setRowItem] = useState<any>()
  const [SingleData, setSingleData] = useState<any>([])
  const [rowItems, setRowItems] = useState<any>({})
  const LabourMove = useBaseModal();
  const Labourgraph = useBaseModal()
  const Editmodal = useBaseModal()
  const [useraction, setuseraction] = useState<any>(null)
  const { height, width } = useWindowDimensions();
  const lockmodal = useBaseModal()
  const [LockData, SetLockData] = React.useState<any>(null)
  const userProfile = useSelector(userSelectors.selectAll);
  const [selectedTab, setSelectedTab] = React.useState('');
  const infomodal = useBaseModal()


  const [rowId, setRowId] = React.useState<any>(null);

  // Menu Item popover
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>, id: any) => {
    setAnchorEl(event.currentTarget);
    setRowId(id);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;



  const handleShareWith: any = (item: any) => {

    setRowItems(item)
    LabourMove.open()

  }
  const GraphHandler: any = (item: any) => {

    setRowItems(item)
    Labourgraph.open()

  }

  React.useEffect(() => {
    settableLoder(true)
    API.get("/api/db/costDB_search/", {
      labour_revision: true,
      revision: revisionId,
      search_key: search?.trim(),


    })
      .then((res: any) => {
        settableLoder(false)
        setTableData(res?.data);
      })
      .catch((err: any) => {
        settableLoder(false)
        console.log("Sever EEror");
      });


  }, [categoryName, Counter, CounterEdit, search])


  const redirectionHandler = (item: any) => {
    history.push(`/data-base/revision-database/${tab}${year}/${month}/${revisionId}/${typeId}/laborDB/${categoryName}/true/0/0`);

  }
  const InfoHandler: any = (item: any) => {
    setRowItems(item)
    infomodal.open()
  }


  React.useEffect(() => {
    API.get(
      "/auth/calibration_user_permission/",
      {
        action: true,
        database: true,
        revision_id: revisionId
      },
      0
    )
      .then((res: any) => {
        setuseraction(res?.data?.action)
        sessionStorage.setItem("DBPermission", res.data.action);
      })
      .catch((err: any) => { });
  }, [revisionId]);
  const DeleteHandler = (item: any) => {



    swal({
      text: "Are you sure, you want to delete ?",
      buttons: ["Cancel", "Ok"],
      icon: "warning",
    }).then((confirm: any) => {
      if (confirm) {
        API.delete(`/api/db/labour_cost/${item?.id}/`).then((res: any) => {
          setCounter((prev: any) => prev + 1)
          setRecyclebinCounter((prev: any) => prev + 1)
        }).catch((err: any) => {

        })
      }
    });





  }



  const LockHandler = (item: any, type: any) => {

    if (type == false) {
      if ((userProfile && userProfile[0]?.is_superuser || userProfile[0]?.is_project_admin || userProfile[0]?.id == item?.locked_user)) {
        API.put(`/api/db/labour_cost/${item?.id}/`, {
          lock: type, revision: revisionId,
          commodity: categoryName,

        }).then((res: any) => {
          settableLoder(true)
          API.get("/api/db/costDB_search/", {
            labour_revision: true,
            revision: revisionId,
            search_key: search?.trim(),


          })
            .then((res: any) => {
              settableLoder(false)
              setTableData(res?.data);
            })
            .catch((err: any) => {
              settableLoder(false)
              console.log("Sever EEror");
            });
        }).catch((err: any) => {
          console.log("Server error")
        })
      } else {
        SetLockData(item)
        lockmodal.open()
      }
    }
    if (type == true) {
      API.put(`/api/db/labour_cost/${item?.id}/`, {
        lock: type, revision: revisionId,
        commodity: categoryName,

      }).then((res: any) => {
        settableLoder(true)
        API.get("/api/db/costDB_search/", {
          labour_revision: true,
          revision: revisionId,
          search_key: search?.trim(),


        })
          .then((res: any) => {
            settableLoder(false)
            setTableData(res?.data);
          })
          .catch((err: any) => {
            settableLoder(false)
            console.log("Sever EEror");
          });
      }).catch((err: any) => {
        console.log("Server error")
      })
    }



  }
  const columns: any = [
    {
      field: "Actions",
      headerName: "Actions",
      width: 200,
      renderCell: (cellvalues: any) => {
        return (<>
        <Box sx={{ display: 'grid', gridTemplateColumns: '1fr 1fr 1fr', alignItems: 'center', gap: '1rem', }}>
            <Box>
              {cellvalues?.row?.lock_status ? <IconButton sx={{ padding: '0', cursor: 'pointer', color: 'green' }} onClick={() => LockHandler(cellvalues?.row, false)}>
                <LockIcon />
              </IconButton> : <IconButton sx={{ padding: '0', cursor: 'pointer', color: 'primary.main' }} onClick={() => LockHandler(cellvalues?.row, true)}>
                <LockOpenIcon />
              </IconButton>}
            </Box>
            <Box>
              {(cellvalues?.row?.lock_status == false && useraction && useraction?.includes("U")) && <IconButton
                title="Edit"
                sx={{ color: "#007fff", padding: "0rem", cursor: "pointer" }}
                onClick={(e: any) => EditHandler(cellvalues?.row, e)} >
                <EditIcon /></IconButton>}
            </Box>
            <Tooltip title="More Items" placement='right-start'>
              <IconButton
                aria-describedby={id}
                // onClick={handleClick(cellValues?.row?.id)}
                onClick={(event) => handleClick(event, cellvalues.row)}
                size="small"
                sx={{ padding: '2px' }}
              >
                <MenuIcon sx={{ color: 'primary.main', }} />
              </IconButton>
            </Tooltip>
            <Popover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'center',
                horizontal: 'right',
              }}
              transformOrigin={{
                vertical: 'center',
                horizontal: 'left',
              }}

              elevation={1}
            >
              <LabourHourRateActionComponents
                cellValues={cellvalues.row}
                rowId={rowId}
                InfoHandler={InfoHandler}
                DeleteHandler={DeleteHandler}
                GraphHandler={GraphHandler}
                redirectionHandler={redirectionHandler}
                handleShareWith={handleShareWith}
                useraction={useraction}
                setAnchorEl={setAnchorEl}
              />
            </Popover>
          </Box>
          {/* {cellvalues?.row.status ?
            <IconButton
              title="Info"
              sx={{ color: "primary.main", padding: "0rem", cursor: "pointer" }} onClick={() => InfoHandler(cellvalues?.row)}>
              <InfoIcon /></IconButton>
            : (cellvalues?.row?.lock_status == false && useraction && useraction?.includes("D")) && <IconButton
              title="Delete"
              sx={{ color: "primary.main", padding: "0rem", cursor: "pointer" }}
              onClick={() => DeleteHandler(cellvalues?.row)}><DeleteIcon style={{ cursor: "pointer" }} /></IconButton>}
          {
            cellvalues?.row?.lock_status ? <IconButton sx={{ padding: '0', cursor: 'pointer', color: 'green' }} onClick={() => LockHandler(cellvalues?.row, false)}>
              <LockIcon />
            </IconButton> : <IconButton sx={{ padding: '0', cursor: 'pointer', color: 'primary.main' }} onClick={() => LockHandler(cellvalues?.row, true)}>
              <LockOpenIcon />
            </IconButton>
          }
          <IconButton title="Graph" sx={{ color: "primary.main", padding: "0rem", cursor: "pointer" }} onClick={() => GraphHandler(cellvalues?.row)} ><EqualizerIcon /></IconButton>
          <IconButton title="Redirect" sx={{ color: "primary.main", padding: "0rem", cursor: "pointer" }} onClick={() => redirectionHandler(cellvalues?.row)}><LaunchIcon /></IconButton>

          {
            cellvalues.row.flag !== null ?
              <IconButton title="Flag" sx={{ color: "primary.main", padding: "0rem", cursor: "pointer" }}>
                <FlagIcon onClick={() => cellvalues.row.flag !== null && window.open(cellvalues?.row?.flag, "_blank")} sx={{ color: "green" }} />
              </IconButton>
              :
              <IconButton title="Flag" sx={{ color: "primary.main", padding: "0rem", }}>
                <FlagOutlinedIcon />
              </IconButton>
          }
          {(cellvalues?.row?.lock_status == false && useraction && useraction?.includes("U")) && <IconButton
            title="Move"
            sx={{ color: "primary.main", padding: "0rem", cursor: "pointer" }}
            onClick={() => handleShareWith(cellvalues?.row)}
          >
            <img src={move} style={{ height: '1.3rem', cursor: 'pointer' }} />
          </IconButton>}
          {(cellvalues?.row?.lock_status == false && useraction && useraction?.includes("U")) && <IconButton title="Edit" sx={{ color: "primary.main", padding: "0rem", cursor: "pointer" }} onClick={(e: any) => EditHandler(cellvalues?.row, e)}>
            <EditIcon />
          </IconButton>} */}
        </>)
      }
    },
    { field: "Ids", headerName: "Sl.No", width: 60 },
    {
      field: "labour_code",
      headerName: "Labour Code",
      width: 150,
    },
    {
      field: "labour_type",
      headerName: "Labour Type",
      width: width > 1400 ? 120 : 100,
    },

    {
      field: "commodity_name",
      headerName: "Category Name",
      width: width > 1400 ? 120 : 100,
    },
    {
      field: 'currency_code',
      headerName: 'Currency/Unit',
      sortable: false,
      minWidth: width > 1370 ? 150 : width < 1200 ? 150 : 150,
      renderCell: (cellValues: any) => {


        return (
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', columnGap: '1rem', }}>
            {`${cellValues?.row?.currency_code ? cellValues?.row?.currency_code : "--"}/${cellValues?.row?.unit ? cellValues?.row?.unit : "--"}`}
          </Box>
        );
      },
    },

    {
      field: "description",
      headerName: "Description",
      width: width > 1400 ? 300 : 150,
    },
    {
      field: "wage_per_day",
      headerName: "Min.wage(Curr/D)",
      width: width > 1400 ? 130 : 110,
      align: 'right',
      renderCell: (cellvalue: any) => {
        return (
          <>
            <Typography sx={{ fontSize: '1rem' }}>
              {cellvalue?.row?.wage_per_day?.toFixed(2)}
            </Typography>
          </>
        );
      }

    },
    {
      field: "dearness_allowance",
      headerName: "D.A (Curr/Month) ",
      width: width > 1400 ? 120 : 100,
      align: 'right',
    },

    {
      field: "perks",
      headerName: "Perks (%) ",
      width: width > 1400 ? 90 : 80,
      align: 'right',
    },
    {
      field: "reference",
      headerName: "Reference",
      width: 100,
      renderCell: (cellvalue: any) => {
        if (cellvalue?.row?.reference !== null) {
          if (cellvalue?.row?.reference.includes("/")) {
            return (
              <>
                <Typography
                  sx={{ fontSize: '1rem', textDecoration: "underline", cursor: "pointer", }}
                  onClick={() => window.open(cellvalue?.row?.reference)}>
                  Link
                </Typography>
              </>
            );
          }
        }
        else {
          return <>{cellvalue?.row?.reference}</>;
        }
      },
    },
    {
      field: "wage_per_month",
      headerName: "Min.wage(Curr/M)",
      cellClassName: "super-app-theme--cell",
      width: width > 1400 ? 140 : 110,
      align: 'right',
      headerAlign: 'right',
      renderCell: (cellvalue: any) => {
        return (
          <>
            <Typography sx={{ fontSize: '1rem' }}>
              {cellvalue?.row?.wage_per_month?.toFixed(2)}
            </Typography>
          </>
        );
      }

    },

    {
      field: "cost_per_hour",
      headerName: width > 1400 ? "Labour Hour Rate" : "LHR",
      cellClassName: "super-app-theme--cell",
      width: width > 1400 ? 150 : 90,
      align: 'right',
      headerAlign: 'right',
      renderCell: (cellvalue: any) => {
        return (
          <>
            <Typography sx={{ fontSize: '1rem' }}>
              {cellvalue?.row?.cost_per_hour?.toFixed(2)}
            </Typography>
          </>
        );
      }

    },
  ];
  const Rows: any = () => {
    return (

      TableData &&
      TableData?.map((item: any, index: any) => {
        return {
          Ids: index + 1,
          id: item?.id,
          labour_code: item?.labour?.labour_code,
          labour_type: item?.labour?.labour_type,
          status: item?.mapped_status,
          description: item?.labour?.description,
          commodity_name: item?.labour?.commodity_name,
          wage_per_day: item?.wage_per_day,
          perks: item?.perks,
          wage_per_month: item?.wage_per_month,
          rowId: item?.labour?.id,
          reference: item?.reference,
          flag: item?.labour?.flag,
          cost_per_hour: item?.cost_per_hour,
          dearness_allowance: item?.dearness_allowance,
          lock_status: item?.lock_status,
          locked_on: item?.locked_on,
          currency_id: item?.currency_id,
          currency_code: item?.currency_code,
          unit: item?.unit,

          locked_user: item?.locked_user,

          locked_user_email
            :
            item?.locked_user_email,
          locked_user_name
            :
            item?.locked_user_name,
          locked_user_phone
            :
            item?.locked_user_phone,

        };
      })
    );
  };

  const swalalterHandler = useCallback(() => {

    Swal.fire({
      html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
    <br />
    <p style="color:#007fff;">Labour Revision Updated successfully!</p>   
     </div>`,
    });

  }, []);
  const swalalterHandlerMove = useCallback((type: any) => {
    if (type == true) {
      setCounter((prev: any) => prev + 1);
      Swal.fire({
        html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
    <br/>
      <p style="color:#007fff;">Moved successfully!</p>   
       </div>`,

      });
    } else {
      Swal.fire({
        html: `<div>
      <p style="color:red;">Server Error!</p>   
       </div>`,
        icon: "error",
      });
    }
  }, []);







  const EditHandler = (item: any, e: any) => {
    e.stopPropagation()
    setRowItem(item)
    Editmodal.open()
  }
  React.useEffect(() => {
    API.get("/api/db/labour_detail/", {
      revision: revisionId,
    }).then((res: any) => {
      setHeaderData(res?.data);
    });
  }, [revisionId]);




  return (
    <Box sx={{ width: "100%" }}>


      {
        Labourgraph.isOpen &&
        <LabourGraph
          isOpen={Labourgraph.open}
          onCloseModal={Labourgraph.close}
          rowItems={rowItems}

        />
      }
      {LabourMove.isOpen && (
        <LabourmoveModal
          isOpen={LabourMove.open}
          onCloseModal={LabourMove.close}
          rowItems={rowItems}
          swalalterHandler={swalalterHandlerMove}
        />
      )}

      {infomodal.isOpen &&
        <LHRRevisionInfoModal
          onCloseModal={infomodal.close}
          isOpen={infomodal.isOpen}
          Item={rowItems}

        />

      }
      {Editmodal.isOpen &&
        <EditLHRModal
          swalalterHandler={swalalterHandler}
          rowItems={RowItem}
          onCloseModal={Editmodal.close}
          isOpen={Editmodal.isOpen}
          setCounter={setCounterEdit}

        />
      }



      <Box sx={{
        height: { lg: '88vh', xl: '90vh' }, width: "100%", "& .super-app-theme--cell": {
          backgroundColor: "#ddefff !important",
          textAlign: "center !important",
        },
      }}>



        {Rows() && Rows() && (
          <DataGrid
            loading={tableLoader}
            headerHeight={48}
            rowHeight={40}
            rows={Rows()}
            columns={columns}
            density="compact"
            components={{
              Toolbar: () => {
                return CustomToolbar();
              },
            }}
            disableSelectionOnClick={true}
            disableColumnSelector={true}
            sx={{
              "& ::-webkit-scrollbar": {
                width: "0.3rem",
                height: "0.3rem",
              },
              "& ::-webkit-scrollbar-thumb": {
                // background: $thumb-color;
                backgroundImage: "linear-gradient(#09C6F9, #045DE9)",
                borderRadius: "10px",
              },
              "& ::- webkit-scrollbar-thumb: hover ": {
                // width: "4px",
                backgroundColor: "#045DE9 !important",
              },
              "::-webkit-scrollbar-track": {
                backgroundColor: "#e1e1f3 !important",
              },
              "&.MuiDataGrid-root": {
                border: "none",
              },
              "&.MuiDataGrid-root .MuiDataGrid-cell": {
                borderBottomColor: "primary.light",
              },
            }}
          />
        )}

      </Box>


    </Box>
  );
}
