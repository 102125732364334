import * as React from 'react';
import { useHistory } from 'react-router-dom';
import { useRouteParams } from '../../../../ui-reusable-component/useRouteParams';
import styles from '../CostingCreateCommodityAnalysisDrawer.module.scss';
import {
	Box,
	Button,
	IconButton,
	Skeleton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Typography,
} from '@mui/material';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { API } from '../../../../api-services';

interface ICostingAnalysisSummaryProps {}

interface Params {
	projectId: any;
	projectName: any;
}

export default function CostingAnalysisSummary(
	props: ICostingAnalysisSummaryProps
) {
	const { projectId, projectName } = useRouteParams<Params>();
	const history = useHistory();
	const [summaryLoader, setSummaryLoader] = React.useState(false);
	const [summaryData, setSummaryData] = React.useState<any>({});
	const handleClickBack = () => {
		// history.push(
		// 	`/CostingCreateCommodiyWiseAnalysis/${projectId}/${projectName}/${0}`
		// );
    history.goBack();
	};

	const getAnalysisSummary = () => {
		setSummaryLoader(true);
		API.get('cost/product_analysis_summary/', {
			analysis_summary: projectId,
		})
			.then((response) => {
				setSummaryLoader(false);
				setSummaryData(response.data);
				// console.log(response.data);
			})
			.catch((error) => {
				setSummaryLoader(false);
				console.log(error);
			});
	};
	React.useEffect(() => {
		getAnalysisSummary();
	}, [projectId]);

   

    const DataTable = ({ categories, types, products, data }: { categories: any[], types: any[], products: any[], data: any }) => {


        return (<Box sx={{padding:'0 0.5rem',height:{lg:'86vh',xl:'89vh'}}}
        adl-scrollbar="true"
        adl-scrollbar-width="0.3">            
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light',backgroundColor:'primary.light' }} rowSpan={2}>Category</TableCell>
                <TableCell sx={{ padding: '0.2rem 0.5rem', textAlign:'center', borderBottomColor: 'primary.light',backgroundColor:'primary.light' }} rowSpan={2}>
                  Type
                </TableCell>
                {products && products?.map((product, index) => (
                  <TableCell sx={{ padding: '0.2rem 0.5rem',textAlign:'center',  borderBottomColor: 'primary.light',backgroundColor:'primary.light' }} key={index} colSpan={1}>
                    {product}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
             <TableBody>
              {categories && categories?.map((category, catIndex) => (
                types.map((type, typeIndex) => (
                  <TableRow key={`${catIndex}-${typeIndex}`}>
                    {typeIndex === 0 && <TableCell sx={{width:'20vw', padding: '0.2rem 0.5rem', borderRight:'1px solid',borderRightColor: 'primary.light',borderBottomColor:'#7471b0' }} rowSpan={types.length}>{category}</TableCell>}
                    {(data?.[category]?.[type]?.count && +data?.[category]?.[type]?.count >0)&&<TableCell sx={{ padding: '0.2rem 0.5rem',textAlign:'center',  borderBottomColor: '#7471b0'}}>{type} {`(${data?.[category]?.[type]?.count})`} </TableCell>}
                    {products.map((product, prodIndex) => (
                      (data?.[category]?.[type]?.count && +data?.[category]?.[type]?.count >0) && <TableCell sx={{ padding: '0.2rem 0.5rem',textAlign:'center', backgroundColor: data[category][type]?.products[product] == "Not Reviewed" ? '#fadcdc' : '#afddaf', borderBottomColor:  '#7471b0' }} key={`${catIndex}-${typeIndex}-${prodIndex}`}>
                        {data?.[category]?.[type]?.products !== null ? data[category][type]?.products[product] : '-'} 
                        {/* Display '-' for missing data */}
                      </TableCell>
                    ))}
                  </TableRow>
                ))
              ))}
            </TableBody>
          </Table>
          </Box>);
      };


	return (
		<>
			<Box sx={{ padding: '0 0.5rem', display: 'flex', alignItems: 'center' }}>
				<Box className={styles.titleSection}>
					<Box className={styles.titleSectionLeft}>
						<IconButton sx={{ cursor: 'pointer' }} onClick={handleClickBack}>
							<ArrowBackIcon color='primary' titleAccess='Go Back' />
						</IconButton>
						<Typography
							sx={{
								fontSize: '1rem',
								whiteSpace: 'nowrap',
								display: 'inline-block',
							}}>
							Costing Analysis Summary ( {projectName} )
						</Typography>
					</Box>
					<Box className={styles.titleSectionRight}></Box>
				</Box>
			</Box>
			<Box>
                {summaryLoader ? <Box sx={{width:'100%'}}>
                    <Table>
                    <TableRow>
                        <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                        <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                        <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                        <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                        <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                        <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                        <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                        <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                        <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                        <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                        <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                        <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                        <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                        <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                        <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                        <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                        <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                        <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                        <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                        <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                        <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                        <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                        <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                        <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                        <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                        <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                        <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                        <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                        <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                        <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                        <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                        <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                        <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                        <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                        <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                        <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                        <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                    </TableRow> 
                    <TableRow>
                        <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                        <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                        <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                        <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                        <TableCell sx={{padding:'0.2rem 0.5rem',borderBottomColor:'primary.light'}}><Skeleton /></TableCell>
                    </TableRow>
                    </Table>
                     </Box> : <Box> 
                     <DataTable
                        categories={summaryData?.categories}
                        types={summaryData?.types}
                        products={summaryData?.products}
                        data={summaryData?.data}
                        />
                        </Box>}
            </Box>
		</>
	);
}
