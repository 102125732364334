import * as React from 'react';
import { TextField } from "@mui/material";
import { useParams } from "react-router-dom";
import { userPermissionAction } from '../../../BOMCreate/BOMEntry/BOMEntryLandingPage';


export interface IIdeaNumberInputBoxTableProps {
    itmm: any;
    key1: any;
    dataBody: any;
    val: any;
    data: any;
    count: any;
    setDataForUpdate: any;
    target: any;
    AllData: any;
    setColorButton: any;
    setShowButton: any;
}

export default function IdeaNumberInputBoxTable (props: IIdeaNumberInputBoxTableProps) {
    const {
        itmm,
        key1,
        dataBody,
        val,
        data,
        count,
        setDataForUpdate,
        target,
        AllData,
        setColorButton,
        setShowButton,
      } = props;
      const { copyStatus } = useParams<any>();
      const userAction = React.useContext(userPermissionAction);
      let UserAction = userAction && userAction?.action;
      const [value, setValue] = React.useState<any>(0);
    
      React.useEffect(() => {
        if (val) {
          setValue(val);
        }else{
          setValue(0);
        }
      }, [val]);
    
    
      const handleChange = (event: any) => {
        setColorButton("red");
        setShowButton(true);
        const { value } = event.target;
        setValue(+value);
        AllData.filter((item: any, index: any) => index === target)[0][key1] = value
          ? +value
          : "";
        setDataForUpdate(AllData);
      };


  return (
    
      <TextField
      variant="standard"
      type="number"
      // disabled={
      //   (copyStatus == false || copyStatus == "false") &&
      //   (UserAction?.includes("U") || UserAction?.includes("C"))
      //     ? false
      //     : true
      // }
      name={key1}
      // onChange={(event: any) => handleChangeNumber(event, item)}
      onChange={handleChange}
      value={value || 0}
      placeholder="Enter Value"
      sx={{
        width: "100%",
        ".MuiInputBase-root.MuiInput-root": {
          fontSize: "12px",
          backgroundColor: "rgba(0,0,0,0.04)",
          padding: "0.1rem 0.3rem",
        },
        "& .MuiInput-underline:before": {
          borderBottomColor: "transparent",
        },
        "& .MuiInput-underline:after": {
          borderBottomColor: "primary.light",
        },
      }}
    />  
  );
}
