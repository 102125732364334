import React, { useContext, useEffect, useState } from 'react';
import { useRouteParams } from '../../ui-reusable-component/useRouteParams';
import { ID } from '../../utlis/commonInterfaces';
import { Box, Skeleton } from '@mui/material';
import { getRepoCreateImages, getRepoImagesHeaderData, resetImageRepository } from '../../Redux/Actions/imageRepository.actions';
import { useSelector } from 'react-redux';
import { RootState } from '../../Redux/Store/storeConfigurations';
import { imageRepositoryProductInfoSelectors } from '../../Redux/Reducers/imageRepositoryProductInfo.reducer';
import { useQueryDispatch } from '../../CustomHook/useQueryDispatch';
import styles from "../MediaModule/MediaLandingPage/MediaContentBody/MediaBodyHeader/MediaBodyHeader.module.scss";
import FileUploadIcon from '@mui/icons-material/FileUpload';
import { MyContext } from './MediaCreateLanding';

interface Params{
    projectId:any;
    projectName:any;
    mediaType:any;
    abbreviation:any;
    category:any;
    groupId:any;
    productList:any;
    pageNumber:any;
    apiCall:any;
}

interface MediaCreateProductNameI{
    topVaultId: ID;  
    selectedTopVaultIds: any;
    mediaBulkUploadModel:any;
    setMediaUploadBulkTopVault:any;
    setMediaUploadBulkVault:any;
}

const MediaCreateProductName:React.FC<MediaCreateProductNameI>=(props)=>{
    const { topVaultId, selectedTopVaultIds, mediaBulkUploadModel, setMediaUploadBulkTopVault,setMediaUploadBulkVault } = props;
    const {projectId,projectName,mediaType,abbreviation,category,groupId,productList,pageNumber,apiCall} = useRouteParams<Params>();
    const { permissionAccess } =  useContext(MyContext);
  
    // ----------------------------------------------------------------------------------
  
    const productInfo: any = useSelector((state: RootState) => {
      return imageRepositoryProductInfoSelectors.selectById(state, topVaultId);
    });
  
    // ----------------------------------------------------------------------------------
  
    // ----------------------------------------------------------------------------------
    // side effects
    const fetchedImagesInfoData = useQueryDispatch({
      query: {
        action: getRepoImagesHeaderData,
        arg: {
          topVaultId,
          subsystemAbbreviation: abbreviation,
          imageCategory: category,
          media_type: mediaType,
        },
        condition: groupId != 0 && topVaultId != 0,
      },
      dependency: [abbreviation, topVaultId, category, productList,pageNumber,groupId,apiCall],
    });
  
    const fetchedImagesData = useQueryDispatch({
      query: {
        action: getRepoCreateImages,
        arg: {
          topVaultId,
          subsystemAbbreviation: abbreviation,
          imageCategory: category,
          media_type: mediaType,
          project: projectId,
          row: 5,
          page: pageNumber,
          group: groupId
        },
        condition: groupId != 0 && topVaultId != 0,
      },
      cleanup: { action: resetImageRepository, args: [topVaultId, groupId, abbreviation, category, pageNumber] },
      dependency: [abbreviation, topVaultId, category, pageNumber, groupId, productList,pageNumber,apiCall],
    });
  
    // useEffect(() => {
    //     fetchedImagesData.match("TRUE")
    //       ? setLoaderImage(true)
    //       : setLoaderImage(false);
    
    // }, [fetchedImagesData]);
  
    // ----------------------------------------------------------------------------------
    // jsx
    if (!productInfo) return null;
    return (
      <> { fetchedImagesInfoData.match("TRUE") ? <>
        <div data-testid="product-images" className={styles.container}>
          <div>
              <div style={{
                width: "100%",
                margin:'0 0.5rem',
                border: '1px solid #007fff',
                borderRadius: '2rem',
                fontSize: '1rem',               
                padding: '0.2rem 1rem',
                display:'flex',
                justifyContent:'space-between',
                alignItems:'center'
              }}>
                <span>{productInfo?.product_name}</span>
                {permissionAccess && permissionAccess?.filter((item:any)=>item?.top_vault == topVaultId)[0]?.action?.includes('C') && <FileUploadIcon sx={{cursor:'pointer'}} onClick={()=> {return mediaBulkUploadModel.open(), setMediaUploadBulkTopVault(productInfo?.top_vault), setMediaUploadBulkVault(productInfo?.vault) }}/>}
              </div>
          </div>
        </div>
      </> : <Box
        sx={{
          height: "3rem",          
        }}
      >
        <Skeleton sx={{ width: "15vw", height: "3rem" }} />
      </Box>
      }</>
    );
}

export default MediaCreateProductName;