import React, { useEffect, useState } from 'react';
import { Button, IconButton, Popover, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import BOMAddValidationRows from './BOMAddValidationRows';
import InfoIcon from '@mui/icons-material/Info';
import AddIcon from '@mui/icons-material/Add';

interface BOMAddValidationI {
  validationCreateuuid: any;
  parameterListData: any;
  idParameter: any;
  relationIDParameter: any;
  apiCallValidationShowData: any;
  setApiCallValidationShowData: any;
  handleClickSnackbar: any;
  deleteModelShow: any;
  setErrorMessage: any;
}

const BOMAddValidation: React.FC<BOMAddValidationI> = (props) => {
  const { validationCreateuuid, parameterListData, idParameter, relationIDParameter, apiCallValidationShowData, setApiCallValidationShowData, handleClickSnackbar, deleteModelShow, setErrorMessage } = props;
  const [validationCount, setValidationCount] = useState<number>(0);
  const [validationuuidIncoming, setValidationUuidIncoming] = React.useState<any>();
  const [anchorElValidationLogic, setAnchorElValidationLogic] = React.useState<HTMLElement | null>(null);
  const [anchorElValidationMessage, setAnchorElValidationMessage] = React.useState<HTMLElement | null>(null);
  const [anchorElParameter, setAnchorElParameter] = React.useState<HTMLElement | null>(null);
  const [expression, setExpression] = React.useState<any>(`exp['1']`);
  const [message, setMessage] = React.useState<any>();
  const [saveLogic, setSaveLogic] = React.useState<boolean>(false);
  const [hideExpressions, setHideExpressions] = React.useState<boolean>(true);

  const openValidationLogic = Boolean(anchorElValidationLogic);

  const handleValidationLogicPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElValidationLogic(event.currentTarget);
  };

  const handleValidationLogicPopoverClose = () => {
    setAnchorElValidationLogic(null);
  };


  const openValidationMessage = Boolean(anchorElValidationMessage);

  const handleValidationMessagePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElValidationMessage(event.currentTarget);
  };

  const handleValidationMessagePopoverClose = () => {
    setAnchorElValidationMessage(null);
  };

  const openParameter = Boolean(anchorElParameter);

  const handleParameterPopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElParameter(event.currentTarget);
  };

  const handleParameterPopoverClose = () => {
    setAnchorElParameter(null);
  };

  useEffect(() => {
    setValidationUuidIncoming(validationCreateuuid);
  }, [])

  const expressionCountMap = () => {
    const countTimes: any = [];
    for (let i = 0; i < validationCount; i++) {
      countTimes.push(<BOMAddValidationRows countOfArray={validationCount} index={i} parameterListData={parameterListData} validationuuid={validationuuidIncoming} idParameter={idParameter} relationIDParameter={relationIDParameter} apiCallValidationShowData={apiCallValidationShowData} setApiCallValidationShowData={setApiCallValidationShowData} handleClickSnackbar={handleClickSnackbar} deleteModelShow={deleteModelShow} setErrorMessage={setErrorMessage} expressionIncoming={expression} setExpressionIncoming={setExpression} messageIncoming={message} setMessageIncoming={setMessage} saveLogicIncoming={saveLogic} setSaveLogicIncoming={setSaveLogic} setHideExpressions={setHideExpressions} />)
    }
    return countTimes;
  }

  const handleExpressionAdd: any = (e: any) => {
    setValidationCount((prev: number) => prev + 1);
    setHideExpressions(false);
  }

  return (
    <div>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={openValidationLogic}
        anchorEl={anchorElValidationLogic}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleValidationLogicPopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 ,fontSize: '1rem'}}>
          <Typography sx={{ fontSize: '1rem' }}>&& - AND</Typography>
          <Typography sx={{ fontSize: '1rem' }}>|| - OR</Typography>
          <Typography sx={{ fontSize: '1rem' }}>! - NOT</Typography>
          <hr />
          <Typography sx={{ fontWeight: 'bold', fontSize: '1rem' }}>Example:</Typography>
          <Typography sx={{ fontSize: '1rem' }}>( exp['1'] && !exp['2'] ) || exp['3']</Typography>
        </Typography>
      </Popover>

      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={openValidationMessage}
        anchorEl={anchorElValidationMessage}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleValidationMessagePopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>
          <Typography sx={{ fontSize: '1rem' }}>Message to be displayed if 'Final Expression'</Typography>
          <Typography sx={{ fontSize: '1rem' }}>evaluates FALSE</Typography>
        </Typography>
      </Popover>
      <Popover
        id="mouse-over-popover"
        sx={{
          pointerEvents: 'none',
        }}
        open={openParameter}
        anchorEl={anchorElParameter}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handleParameterPopoverClose}
        disableRestoreFocus
      >
        <Typography sx={{ p: 1 }}>
          <Typography sx={{ fontSize: '1rem' }}>Value field for a expression can only have one value except,</Typography>
          <Typography sx={{ fontSize: '1rem' }}>if operation is "One Of" or "Not One Of" it can take multiple values comma separated.</Typography>
        </Typography>
      </Popover>
      <div style={{ display: 'flex',width: '100%', height: "2.5rem", paddingLeft: '0.7rem' }}>
        <Button size='small' sx={{ lineHeight: 1, fontSize: '1rem' }} startIcon={<AddIcon sx={{ marginRight: '-0.5rem' }} />} onClick={(e: any) => handleExpressionAdd(e)}>Expression</Button>
      </div>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ backgroundColor: 'primary.light', padding: '0.2rem 0.5rem', color: 'primary.main', }}>SNo.</TableCell>
            <TableCell sx={{ backgroundColor: 'primary.light', padding: '0.2rem 0.5rem', color: 'primary.main', }}>ID</TableCell>
            <TableCell sx={{ backgroundColor: 'primary.light', padding: '0.2rem 0.5rem', color: 'primary.main', }}>Validation Type</TableCell>
            <TableCell sx={{ backgroundColor: 'primary.light', padding: '0.2rem 0.5rem', color: 'primary.main', }}>Parameter <IconButton sx={{ p: 0, marginLeft: '0.5rem', color: 'primary.main' }} aria-owns={openParameter ? 'mouse-over-popover' : undefined} aria-haspopup="true" onMouseEnter={handleParameterPopoverOpen} onMouseLeave={handleParameterPopoverClose}><InfoIcon /></IconButton></TableCell>
            <TableCell sx={{ backgroundColor: 'primary.light', padding: '0.2rem 0.5rem', color: 'primary.main', }}>Condition</TableCell>
            <TableCell sx={{ backgroundColor: 'primary.light', padding: '0.2rem 0.5rem', color: 'primary.main', }}>Parameter/Value/Vault Info</TableCell>
            <TableCell sx={{ backgroundColor: 'primary.light', padding: '0.2rem 0.5rem', color: 'primary.main', borderRight: '0.5rem solid white' }}>Actions</TableCell>
            <TableCell sx={{ backgroundColor: 'primary.main', padding: '0.2rem 0.5rem', color: 'white', }}>Validation Logic <IconButton sx={{ p: 0, marginLeft: '0.5rem', color: 'white' }} aria-owns={openValidationLogic ? 'mouse-over-popover' : undefined} aria-haspopup="true" onMouseEnter={handleValidationLogicPopoverOpen} onMouseLeave={handleValidationLogicPopoverClose}><InfoIcon /></IconButton></TableCell>
            <TableCell sx={{ backgroundColor: 'primary.main', padding: '0.2rem 0.5rem', color: 'white', }}>Validation Message <IconButton sx={{ p: 0, marginLeft: '0.5rem', color: 'white' }} aria-owns={openValidationMessage ? 'mouse-over-popover' : undefined} aria-haspopup="true" onMouseEnter={handleValidationMessagePopoverOpen} onMouseLeave={handleValidationMessagePopoverClose}><InfoIcon /></IconButton></TableCell>
            <TableCell sx={{ backgroundColor: 'primary.main', padding: '0.2rem 0.5rem', color: 'white', }}>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {expressionCountMap()}
        </TableBody>
      </Table>
    </div>)
}

export default BOMAddValidation;