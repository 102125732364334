import { Box, Divider, FormControl, InputLabel, MenuItem, Modal, Select, TextField, Typography } from '@mui/material';
import React, { useContext, useEffect, useRef, useState } from 'react';
import CancelIcon from '@mui/icons-material/Cancel';
import { useHistory } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { useDispatch, useSelector } from 'react-redux';
import { byAbbreviationSelectors } from '../../Redux/Reducers/groupingMediaCreate.reducer';
import { useRouteParams } from '../../ui-reusable-component/useRouteParams';
import { moveImageToAnotherGrp } from '../../Redux/Actions/groupingMediaCreate.action';
import { MyContext } from './MediaCreateLanding';

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { xs: '80vw', sm: " 70vw", md: '50vw' },
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

interface MediaMoveSingleImageModelI {
  isOpen: any;
  onCloseModal: any;
  imageId: any;
}

const MediaMoveSingleImageModel: React.FC<MediaMoveSingleImageModelI> = (props) => {
  const { isOpen, onCloseModal, imageId } = props;
  const { projectId, projectName, mediaType, abbreviation, category, groupId, productList, pageNumber, apiCall } = useRouteParams<any>();

  const { callGroupAPI, setCallGroupAPI } = useContext(MyContext);
  const dispatch = useDispatch();
  const history = useHistory();
  // ----------------------------------------------------------------------------------
  // state

  const [group, setGroup] = useState();

  const [sequenceNum, setSequenceNum] = useState<number>();

  const [isApplying, setIsApplying] = useState(false);

  // ----------------------------------------------------------------------------------
  const GroupNames = useSelector(byAbbreviationSelectors.selectAll);
  const GroupOptions: any = GroupNames && GroupNames.filter((item: any) => item.id !== -2).map((item: any, index: number) => {
    return {
      key: index,
      text: item.name,
      value: item.id
    };
  });

  const handleGroupChange = (e: any) => {
    setGroup(e.target.value);
  };

  const handleChangeSequence = (e: any) => {
    setSequenceNum(e.target.value);
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    setIsApplying(true);
    dispatch(moveImageToAnotherGrp({
      id: imageId,
      group: group,
      media_type: mediaType,
      new_sequence: sequenceNum
      //@ts-ignore
    })).then(() => {
      setIsApplying(false);
      setCallGroupAPI(!callGroupAPI)
      onCloseModal();
      history.push(
        `/mediacreateinternal/${projectId}/${projectName}/${mediaType}/${abbreviation}/${category}/${groupId}/${productList}/${pageNumber}/${apiCall == "true" ? "false" : "true"}`
      );
    }).catch(() => {
      setIsApplying(false);
    })
  }

  return (
    <Modal
      open={isOpen}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={style}>
        <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
          <Typography
            style={{
              fontSize: "1rem",
              fontWeight: "600",
              width: "100%",
              textAlign: "center",
              marginRight: '-2rem'
            }}
          >
            Media Individual Image Move
          </Typography>
          <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
        </Box>
        <Divider
          sx={{
            borderColor: "primary.light",
            margin: "0 1rem",
            marginBottom: "1rem",
          }}
        />
        <Box style={{ display: "flex", justifyContent: 'space-between' }}>
          <FormControl variant="standard" sx={{ m: 1, minWidth: '20%' }}>
            <InputLabel sx={{ color: 'primary.main' }} shrink>Sequence *</InputLabel>
            <TextField
              variant='standard'
              sx={{
                width: '8rem',
                marginTop: '1.5rem',
                marginLeft: '0.3rem',
                ".MuiInputBase-root.MuiInput-root": {
                  fontSize: '1rem !important'
                },
                '& .MuiInput-underline:before': { borderBottomColor: 'primary.light' },
                '& .MuiInput-underline:after': { borderBottomColor: 'primary.main' },
              }}
              type='number'
              inputProps={{
                step: 'any',
                min: 1,
              }}
              onChange={(e: any) => handleChangeSequence(e)}
              value={sequenceNum}
            />
          </FormControl>
          <FormControl variant="standard" sx={{ m: 1, minWidth: '30%' }}>
            <InputLabel sx={{ color: 'primary.main' }} shrink>Move to group *</InputLabel>
            <Select
              labelId="demo-simple-select-standard-label"
              id="demo-simple-select-standard"
              value={group}
              onChange={handleGroupChange}
            >
              {GroupOptions &&
                GroupOptions?.map((item: any, index: number) => {
                  return (
                    <MenuItem
                      key={index + 1}
                      value={item?.["value"]}
                      sx={{ textTransform: "capitalize" }}
                    >
                      {item?.["text"]}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
          <LoadingButton size='small'
            sx={{
              padding: "0.5rem",
              lineHeight: 1,
              "&:hover": {
                transform: 'Scale(1.05)',
                transition: 'transform 0.5s ease',
              },
              '.MuiCircularProgress-root': {
                color: 'primary.main',
              },
              float: 'right', marginRight: '0.5rem'
            }} disabled={!(sequenceNum && group)} loading={isApplying} onClick={(e: any) => handleSubmit(e)} >Move</LoadingButton>
        </Box>
      </Box>
    </Modal>
  )
}

export default MediaMoveSingleImageModel;