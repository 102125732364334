import React from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CustomImageEditor from "./CustomImageEditor";
import ClearIcon from '@mui/icons-material/Clear';
import SaveIcon from '@mui/icons-material/Save';
import InwardS3images from "./InwardS3images";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "90rem",
  bgcolor: "background.paper",
  // border: "2px solid #000",
  height: "65rem",
   
  // boxShadow: 24,
  p: 4,
};
interface InwardImageEditorModalProps {
  isOpen: any;
  onCloseModal: any;
  productId: any;
  imageURI:any;
  ImageNamedata:any;
  setImageLoader:any;
  setImageUploadCounter:any
}
const InwardImageEditorModal = (props: InwardImageEditorModalProps) => {
  const { isOpen, onCloseModal, productId ,imageURI,ImageNamedata,setImageLoader,setImageUploadCounter} = props;


  const imageEditor = React.createRef<HTMLDivElement | any>();

  const handleUpdateImage = () => {
    const updatedImage = imageEditor.current.imageEditorInst;
    const updatedData = updatedImage.toDataURL().split( "," )[1];
    var oldUrlSplit = imageURI == "null" ? "" : imageURI?.split(".net")[1];
    // console.log(updatedData,"updatedDataupdatedData",updatedImage,oldUrlSplit?.split("/")?.slice(-1))
    const byteCharacters = atob( updatedData );
    const byteNumbers = new Array( byteCharacters.length );
    for ( let i = 0; i < byteCharacters.length; i++ ) {
        byteNumbers[i] = byteCharacters.charCodeAt( i );
    }
    const byteArray = new Uint8Array( byteNumbers );
    const file = new Blob( [byteArray], {
        type: 'image/png'
        
       
    }
        
        
        );
        
       
var filename=oldUrlSplit?.split("/")?.slice(-1)[0];
   
  //  console.log(file,"ImageNamedataImageNamedata")
    InwardS3images(
      [file],
      oldUrlSplit,
      ImageNamedata && ImageNamedata,
      setImageUploadCounter,
      productId,
      setImageLoader,
      filename,null
    );

    
    // updateImage( file, 0 );
    onCloseModal();
};
  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Edit Image
          </Typography>
          <CustomImageEditor imageURI={ imageURI } imageEditor={ imageEditor } imageUrl={ imageURI } />

          
                    <Button
                        onClick={ handleUpdateImage }

                    >
                        <SaveIcon 
                        
                        
                        />
                    </Button>
                    <Button
                        onClick={ onCloseModal }
                    >
                        <ClearIcon 
                        
                       
                            // className="bicon"
                        />
                    </Button>
                
        </Box>
      </Modal>
    </div>
  );
};

export default InwardImageEditorModal;
