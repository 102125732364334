import * as React from 'react';




import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Chip, IconButton } from '@mui/material';
import sendIcon from "../../Assets/images/sendOutline.png";
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: " 10px",
    height: "90vh",
    width: "80vw",
    // overflowY: "scroll",
    p: 1,
};



export interface PreviewMailModalProps {

    onCloseModal: any;
    isOpen: any;
    Tousers: any;
    CCusers: any;
    Subject: any;
    AttchmentData: any;
    emailContent: any;
    FinalEmailSendHandler: any;
    SendEmail?: any;


}



export function PreviewMailModal(props: PreviewMailModalProps) {

    const { onCloseModal, isOpen, Tousers,
        CCusers,
        Subject,
        AttchmentData, emailContent, FinalEmailSendHandler, SendEmail } = props

    const sendmail = () => {
        FinalEmailSendHandler()
    }

    return (
        <div>
            <Modal
                open={isOpen}

                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box>
                        <Box sx={{
                            display: "flex",
                            alignItems: 'center',
                            justifyContent: "space-between",
                            position: 'sticky', top: '0',
                            backgroundColor: '#ffffff'
                        }}>
                            <Box></Box>
                            <Typography sx={{ fontSize: '1rem', textAlign: 'center', fontWeight: 500 }}>Mail Preview </Typography>
                            <Box sx={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: 'center',
                                columnGap: "2rem",
                            }}>
                                <Button variant='contained' size='small' onClick={() => onCloseModal()}
                                    sx={{
                                        "&:hover": {
                                            transform: 'Scale(1.05)',
                                            transition: 'transform 0.5s ease',
                                        }
                                    }}>Close & Edit</Button>
                                <IconButton><img src={sendIcon} title='Send Mail' style={{ cursor: "pointer", width: '1.7rem', height: 'auto' }} onClick={() => sendmail()} /></IconButton>
                                {/* <HighlightOffIcon sx={{ cursor: "pointer" }} /> */}
                            </Box>
                        </Box>
                        <Box sx={{ padding: '0 1rem', height: '82vh' }}
                            adl-scrollbar="true"
                            adl-scrollbar-width="0.3">
                            <div style={{ marginBottom: '1rem' }}>
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        gap: "1rem",
                                        marginBottom:'0.5rem',
                                    }}


                                >
                                    <Typography sx={{ width: '6rem', textAlign: 'right', fontSize: '1rem' }}>To :</Typography>
                                    <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '10px', flexWrap: 'wrap', width: '100%' }}>
                                        {Tousers && Tousers?.map((item: any, index: any) => {
                                            return (<>

                                                <Chip sx={{ borderColor: 'primary.main' }} label={item?.email} variant="outlined" size="small" />
                                            </>)
                                        })}
                                    </Box>
                                </div>
                                <div

                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        gap: "1rem",
                                        marginBottom:'0.5rem',
                                    }}
                                >


                                    <Typography sx={{ width: '6rem', textAlign: 'right', fontSize: '1rem', marginTop: '0.5rem' }}>Cc:</Typography>
                                    <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '10px', flexWrap: 'wrap', width: '100%' }}>
                                        {CCusers && CCusers?.map((item: any, index: any) => {
                                            return (<>
                                                <Chip sx={{ borderColor: 'primary.main' }} label={item?.email} variant="outlined" size="small" />
                                            </>)
                                        })}
                                    </Box>
                                </div>
                                <div

                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        gap: "1rem"
                                    }}

                                ><Typography sx={{ width: '6rem', textAlign: 'right', fontSize: '1rem' }}>Subject :</Typography>

                                    <p>
                                        {Subject && Subject}
                                    </p>
                                </div>  <div


                                    style={{
                                        display: "flex",
                                        flexDirection: "row",
                                        alignItems: "center",
                                        gap: "1rem"
                                    }}
                                >
                                    <Typography sx={{ width: '6rem', textAlign: 'right', fontSize: '1rem' }}>Attachments: </Typography>
                                    {AttchmentData && AttchmentData?.map((item: any, index: any) => {
                                        return (<>
                                            <Chip sx={{ borderColor: 'primary.main' }} label={item?.file_name} variant="outlined" size="small" onClick={() => window.open(item?.file_url)} />
                                        </>)
                                    })}

                                </div>
                            </div>
                            <div dangerouslySetInnerHTML={{ __html: emailContent }}   ></div>
                        </Box>
                    </Box>
                </Box>
            </Modal>
        </div>
    );
}
