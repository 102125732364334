import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import CancelIcon from "@mui/icons-material/Cancel";
import styles from "../../../../../ViewModule/ViewComponents.module.scss";
import { Divider, Skeleton, TablePagination, TextField } from "@mui/material";
import { API } from "../../../../../api-services";
import { LoadingButton } from "@mui/lab";
import { useRouteParams } from "../../../../../ui-reusable-component/useRouteParams";
import useWindowDimensions from "../../../../../ui-reusable-component/useWindowDimensions";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import LaunchIcon from "@mui/icons-material/Launch";
import InfoIcon from "@mui/icons-material/Info";
import {
  DataGrid,
  GridCsvExportMenuItem,
  GridCsvExportOptions,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarExportContainer,
  GridFooterContainer,
} from "@mui/x-data-grid";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: "60vw",
  maxWidth: "80vw",
  maxHeight: { lg: "63vh", xl: "48vh" },
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};
interface ConstantmsgModalProps {
  onCloseModal: any;
  isOpen: any;
  Item: any;
  data: any;
  handleSave: any;
}
const csvOptions: GridCsvExportOptions = {
  fileName: "BOM module CSV file",
  delimiter: ",",
  utf8WithBom: true,
};

const CustomExportButton = (props: ButtonProps) => (
  <GridToolbarExportContainer {...props}>
    <GridCsvExportMenuItem options={csvOptions} />
  </GridToolbarExportContainer>
);

const CustomToolbar = () => (
  <GridToolbarContainer style={{ height: "2rem" }}>
    <span style={{ position: "absolute", right: "0rem" }}>
      <GridToolbarColumnsButton />
      {/* <GridToolbarDensitySelector /> */}

      <CustomExportButton />
    </span>
  </GridToolbarContainer>
);

const BOMTableCalculatorFetch = (props: ConstantmsgModalProps) => {
  const { onCloseModal, isOpen, Item, data, handleSave } = props;

  const { height, width } = useWindowDimensions();
  const CustomToolbarIncludingPagination = ({ count }: any) => (
    <GridToolbarContainer style={{ height: "2rem" }}>
      <div
        style={{
          position: "absolute",
          right: "0rem",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <TablePagination
          rowsPerPageOptions={[100]}
          component="div"
          rowsPerPage={100}
          page={pageNumber}
          count={count}
          onPageChange={handleChangePage}
          // You can adjust other TablePagination props here
          sx={{ display: "flex", justifyContent: "flex-start" }}
        />
        {/* <GridToolbarColumnsButton /> */}
        <GridFooterContainer></GridFooterContainer>

        {/* <CustomExportButton /> */}
      </div>
    </GridToolbarContainer>
  );
  const {
    vault,
    topVault,
    projectId,
    projectname,
    productId,
    abbrebation,
    cardtype,
    tableId,
    costEstimationId,
    tableCounter,
    condition,
    calctype,
    calcultorId,
    scenarioId,
  } = useRouteParams<any>();
  const ImageViewer = React.useRef<any>();
  const [srcImages, setSrcImages] = useState<any>();
  const [pageNumber, setPageNumber] = useState<any>(0);
  const [obj, setObj] = React.useState<any>({});
  const [BomParameter, setBomParameter] = useState<any>();
  const [tableData, setTableData] = useState<any>();
  const [TableLoader, setTableLoader] = useState<boolean>(true);
  const [selectedRow, setSelectedRow] = useState<any>(null);
  const [selectionModel, setSelectionModel] = React.useState([]);
  const [redirectionData, setRedirectionData] = useState<any>({});

  const handleSelectionModelChange = (newSelection: any, data?:any) => {
    const rowId = newSelection[newSelection?.length - 1] - 1
  
    
    setSelectedRow(Number(data?.[rowId]?.row_id));
    if (newSelection.length > 1) {
      const newSelectionModel = newSelection.slice(-1);
      setSelectionModel(newSelectionModel);
    } else {
      setSelectionModel(newSelection);
    }
  };

  const handleRowSelection = (row: any) => {
    setSelectedRow(row.id);
  };
  const handleClick = (srcImg: any) => {
    ImageViewer.current.src = srcImg;
    ImageViewer.current?.click?.();
    setSrcImages(srcImg);
  };
  const toggleTopvault = () => {
    if (abbrebation == "product") {
      return { top_vault: topVault };
    } else {
      return { vault: vault };
    }
  };

  useEffect(() => {
    // setPartLoader(true);
    setTableLoader(true);

    API.get(
      "/customer/Bom_view_data/",
      {
        table: true,
        ...toggleTopvault(),
        cal_table_id: Item?.parameter,
        costing_create:true
      },
      0
    )
      .then((res: any) => {
        setTableData(res?.data);
        setTableLoader(false);
      })
      .catch((err: any) => {
        // ADMIN.toast.throwError(err, "Server error in Bom module");
        setTableData(null);
        setTableLoader(false);
      });
  }, []);

  
  
  //   const BomparameterData = () => {
  //     var valuedata = BomParameter && BomParameter?.part_details[0];
  //     var data = BomParameter && valuedata && Object?.keys(valuedata);
  //     // console.log(data);
  //     // console.log(valuedata, "valuedata")
  //     var valuedata =
  //       data &&
  //       data?.map((key: any, value: any) => {
  //         // console.log(key,"checkKey")
  //         return {
  //           field: key,
  //           headerName: key,
  //           // maxWidth: key=="Value"? 300 : key=="Unit"? 80 :  key=="Parameter"?300: 200,
  //           // minWidth: key=="Value"? 300 : key=="Unit"? 80 :  key=="Parameter"?300:200,
  //           width: key == "Value" ? width > 1400 ? 350 : 200 : key == "Unit" ? width > 1400 ? 90 : 80 : key == "Parameter" ? width > 1400 ? 400 : 200 : width > 1400 ? 300 : 210,
  //           headerClassName: styles.Dataheader,
  //         };
  //       });

  //     return valuedata;

  //   };

  // const BomparameterValue = () => {
  //     var valuedata =
  //       BomParameter &&
  //       BomParameter?.part_details.map((item: any, id: any) => ({
  //         ...item,
  //         id: id + 1,
  //       }));

  //     return valuedata;
  //   };

  //   const dataRender: any = {
  //     columns: BomparameterData(),
  //     rows: BomparameterValue(),
  //   };

  const closeHandaler = () => {
    onCloseModal();
    //     if(type=="msg"){
    //       onCloseModal()
    //       if(Ids?.message?.length>0){
    //         API.put(`/cost/cost_constant_message/${Ids?.id}/`,{message:masgdata}).then((res:any)=>{

    //           API.get("/cost/cost_constant_message/",{calculation:Item}).then((res:any)=>{
    //             var data=res?.data?.filter((item:any,index:any)=>{return item?.input_field==values})
    //             setmasgdata(data[0]?.message)
    //             setIds(data[0])
    //             setValue(data[0]?.user_name)
    //         }).catch((err:any)=>{
    //             console.log("Sever error")
    //         })

    //         })
    //     }else{
    //     API.post("/cost/cost_constant_message/",{calculation:Item,input_field:values,message:masgdata}).then((res:any)=>{
    //       API.get("/cost/cost_constant_message/",{calculation:Item}).then((res:any)=>{
    //         var data=res?.data?.filter((item:any,index:any)=>{return item?.input_field==values})
    //         setmasgdata(data[0]?.message)
    //         setIds(data[0])
    //         setValue(data[0]?.user_name)
    //     }).catch((err:any)=>{
    //         console.log("Sever error")
    //     })

    //     })
    // }
    //     }else{
    //       onCloseModal()
    //     }
  };
  const PcbTableRow = (Rowdata: any) => {
    var valuedata =
      Rowdata &&
      Rowdata.map((item: any, id: any) => ({
        ...item,
        id: id + 1,
      }));

    return valuedata;
  };

  const PcbTableHeader = (HeaderData: any) => {
    var Pcbdata: any = BomParameter && Object.keys(HeaderData);

    var valuedata: any =
      HeaderData &&
      HeaderData?.map((key: any, value: any) => {
        return {
          field: key?.name,
          flex: 1,

          align: key?.data_type === "int" && "left",
          headerAlign: key?.data_type === "int" && "left",
          type: key?.data_type === "int" ? "number" : "string",
          //  headerAlign: 'center',
          // type: 'string',
          disableExport: key?.data_type === "image" ? true : false,
          headerName: key?.name,
          headerClassName: styles.Dataheader,

          renderCell: (params: any) => {
            if (key?.data_type == "image") {
             
              
              return (
                <CameraAltIcon
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleClick(params.row[key?.name]);
                  }}
                />
              );
            } else {
              return (
                <span title={params.row[key?.name]}>
                  {params.row[key?.name]}
                </span>
              );
            }
          },
        };
      });
    return valuedata;
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    setPageNumber(newPage);
  };
  React.useEffect(() => {
    if (data?.user_input) {
      setObj(data?.user_input);
    } else {
      setObj({});
    }
  }, [data?.user_input]);

  const fetchRow = () => {
    if (Object.keys(data?.user_input)?.includes(Item?.id)) {
      data.user_input[Item?.id] = selectedRow;
      API.put(
        `/cost/calculation/${data?.id}/`,
        { user_input: data?.user_input },
        {},
        0
      )
        .then((res: any) => {
          handleSave();
          onCloseModal();
        })
        .catch((err: any) => {
          console.log("Server error");
        });
    } else {
      setObj((prev: any) => ({ ...prev, [Item?.id]: selectedRow}));
      API.put(
        `/cost/calculation/${data?.id}/`,
        { user_input: { ...data?.user_input, [Item?.id]: selectedRow} },
        {},
        0
      )
        .then((res: any) => {
          handleSave();
          onCloseModal();
        })
        .catch((err: any) => {
          console.log("Server error");
        });
    }
  };
  return (
    <div>
      <Modal
        open={isOpen}
        //   onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          {
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                height: "3rem",
                padding: "1rem",
              }}
            >
              {
                <Typography
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    width: "100%",
                    textAlign: "center",
                    marginRight: "-2rem",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "0.5rem",
                  }}
                >
                  <InfoIcon
                    sx={{ cursor: "pointer" }}
                    titleAccess={
                      tableData &&
                      tableData?.length > 0 &&
                      tableData[0]?.bom_table_formula
                    }
                  />{" "}
                  {"Fetch calculator Results from "}{" "}
                  {`${
                    tableData &&
                    tableData?.length > 0 &&
                    tableData[0]?.table_name
                  }`}
                  <LaunchIcon
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      window.open(
                        `/#/bomconfiguration/${projectId}/${projectname}/${topVault}/${
                          tableData &&
                          tableData?.length > 0 &&
                          tableData[0]?.category_id
                        }/${
                          tableData &&
                          tableData?.length > 0 &&
                          tableData[0]?.category_name
                        }/${topVault}/${"BOM-table-calculator"}`
                      )
                    }
                  />
                </Typography>
              }
              <CancelIcon
                titleAccess="Close"
                sx={{
                  cursor: "pointer",
                  margin: "0.5rem",
                  marginBottom: "1rem",
                }}
                onClick={closeHandaler}
              />
            </Box>
          }
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
              //   marginTop:"1rem"
            }}
          />
          <Box sx={{ width: "100%", padding: "0 1rem" }}>
            {tableData &&
              tableData.map((tableItem: any, ind: any) => {
                return (
                  <>
                    {TableLoader ? (
                      <>
                        <Skeleton
                          sx={{
                            width: "70vw",
                            height: "3rem",
                            marginLeft: "1.2rem",
                          }}
                        />
                        <Skeleton
                          sx={{
                            width: "70vw",
                            height: "3rem",
                            marginLeft: "1.2rem",
                          }}
                        />
                        <Skeleton
                          sx={{
                            width: "70vw",
                            height: "3rem",
                            marginLeft: "1.2rem",
                          }}
                        />
                        <Skeleton
                          sx={{
                            width: "70vw",
                            height: "3rem",
                            marginLeft: "1.2rem",
                          }}
                        />
                      </>
                    ) : (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            columnGap: "1rem",
                            alignItems: "center",
                            padding: "0.2rem 1rem",
                            width: "50%",
                            zIndex: "1000",
                            position: "relative",
                            marginTop: "2rem",
                          }}
                        >
                          {/* <Typography
                            sx={{ fontSize: "1rem", marginLeft: "1rem" }}
                          >
                            {" "}
                            {tableItem?.table_name}
                          </Typography> */}
                        </Box>
                        <Box
                          sx={{
                            width: "100%",
                            padding: "0 1rem",
                            marginTop: "-2rem",
                          }}
                        >
                          {/* height: tableItem?.data.length == 1 ? 200 : 337, */}
                          <DataGrid
                            headerHeight={48}
                            rowHeight={36}
                            rows={PcbTableRow(
                              pageNumber * 100 > tableItem?.data.length
                                ? tableItem?.data?.slice(
                                    pageNumber * 100,
                                    (pageNumber + 1) * 100
                                  )
                                : tableItem?.data?.slice(
                                    pageNumber * 100,
                                    tableItem?.data.length
                                  )
                            )}
                            // rows={PcbTableRow(tableItem?.data)}
                            columns={PcbTableHeader(
                              tableItem && tableItem?.header_item
                            )}
                            checkboxSelection
                            selectionModel={selectionModel}
                            onSelectionModelChange={(newSelection) => handleSelectionModelChange(newSelection, tableItem?.data)}
                            hideFooterSelectedRowCount
                            // pageSize={5}
                            // rowsPerPageOptions={[5]}
                            sx={{
                              fontSize: "12px",
                              borderColor: "primary.light",
                              "&.MuiDataGrid-root.MuiDataGrid-autoHeight .MuiDataGrid-row--lastVisible .MuiDataGrid-cell":
                                {
                                  borderBottomColor: "primary.light",
                                },
                              "&.MuiDataGrid-root .MuiDataGrid-cell": {
                                borderBottomColor: "primary.light",
                              },
                              "& .MuiDataGrid-root.MuiDataGrid-autoHeight.MuiDataGrid-root--densityCompact.MuiDataGrid-root":
                                {
                                  border: "none !important",
                                },
                            }}
                            density="compact"
                            // checkboxSelection
                            // @ts-ignore
                            // components={{ Toolbar: GridToolbar }}

                            pageSize={100}
                            // components={{
                            //   Toolbar: () => {
                            //     return tableItem?.data.length > 100 ? (
                            //       <CustomToolbarIncludingPagination
                            //         count={tableItem?.data.length}
                            //       />
                            //     ) : (
                            //       <CustomToolbar />
                            //     );
                            //   },
                            // }}
                            autoHeight={true}
                            pagination
                            hideFooterPagination
                            // experimentalFeatures={{ newEditingApi: true }}
                          />

                          {/* {tableItem?.data.length > 100 && <Pagination count={Math.ceil(tableItem?.data.length / 100)} shape="rounded" showFirstButton showLastButton onClick={(e:any) => handlePageChange(e)} />  } */}
                        </Box>
                      </>
                    )}
                  </>
                );
              })}
          </Box>
          <Box
            sx={{ float: "right", marginRight: "2rem", marginBottom: "1rem" }}
          >

{selectedRow !== null && selectedRow !== undefined  &&  !isNaN(selectedRow) && (
  <LoadingButton
    variant="contained"
    onClick={() => fetchRow()}
  >
    Fetch
  </LoadingButton>
)}
         
            {/* {selectedRow && (
              <LoadingButton
                variant="contained"
                // sx={{ backgroundColor: "green"}}
                onClick={() => fetchRow()}
              >
                Fetch
              </LoadingButton>
            )} */}
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
export default BOMTableCalculatorFetch;
