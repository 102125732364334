import { API } from "../../api-services";

const url = "xcpep/project_milestone";
const projectUrl = "/xcpep/project_milstone_product/";
const newUrl = "/xcpep/project_milestone_view"
// "session_id"


class Milestone {
    getMileStone = ( projectId: number,page_no?: number,selectFilterActivity?:any,selectFilterStatus?:any,selectFilterActivityType?:any,selectFilterDepartment?:any,selectFilterGroup?:any ) => {
        return API.get( newUrl + "/", { project: projectId,page_no:page_no,activity_type:selectFilterActivityType=="All"?null:selectFilterActivityType,is_active:selectFilterActivity=="All"?null:selectFilterActivity,status:selectFilterStatus=="All"?null:selectFilterStatus,dept:selectFilterDepartment=="All"?null:selectFilterDepartment,group:selectFilterGroup=="All"?null:selectFilterGroup } ,0);
    };

    getMileStoneRaw = ( projectId: number,page_no:any ) => {
        return API.get( newUrl + "/", { project: projectId,page_no:page_no } );
    };

    getMileStonePageCount = ( projectId: number,selectFilterActivity?:any,selectFilterStatus?:any,selectFilterActivityType?:any,selectFilterDepartment?:any,selectFilterGroup?:any, disabled_empty_field?:any ) => {
        return API.get( newUrl + "/", { project: projectId,activity_type:selectFilterActivityType=="All"?null:selectFilterActivityType,is_active:selectFilterActivity=="All"?null:selectFilterActivity,status:selectFilterStatus=="All"?null:selectFilterStatus,dept:selectFilterDepartment=="All"?null:selectFilterDepartment,group:selectFilterGroup=="All"?null:selectFilterGroup,page_count:true,disabled_empty_field:disabled_empty_field } );
    };

    getProject = ( id: string ) => {
        return API.get( projectUrl, {
            project_id: id
        } );
    };

    updateStatus = ( is_active: any, id: any ) => {
        return API.patch( `${url}/${id}/`, {
            is_active: is_active
        } );
    };

    updateMileStoneDates = ( id:any , productList:any , startDateRange:any , selectDuration:any,selectStatus:any,selectEndDateRange:any,selectActivityType:any ) => {
        return API.patch( `${url}/${id}/`, {top_vaults: productList, start_date:startDateRange, duration:selectDuration, status:selectStatus,end_date:selectEndDateRange,activity_type:selectActivityType});
    };
}

const MileStone = new Milestone();

export { MileStone };