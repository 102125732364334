import Image from "../FeatureLandingPage/ArchitectureImageCard"
import placeHolderImage from '../../Assets/images/loading-screen.gif'
import RViewerJS from 'viewerjs-react';
import styles from "./CostingImageData.module.scss"
import { Card, CardMedia } from "@mui/material";

export interface ICostingImageSectionProps {
  imageData: any
}

export function CostingImageSection(props: ICostingImageSectionProps) {
  const { imageData } = props;
  return (
    //@ts-ignore
    <RViewerJS className={styles.imageContainer}>
      {imageData && Object.keys(imageData)?.map((item: any, index: number) => {
        return <div>
          {index <3 && <Image src={imageData[item]} placeholderImg={placeHolderImage} alt={item} title={item} style={{width:'100%',height:'9rem',cursor:'pointer',objectFit:'contain',borderRadius:'5px',display:'flex'}} />}
          {index > 2 && <Image src={imageData[item]} placeholderImg={placeHolderImage} alt={item} title={item} style={{ width: '100%', height: '9rem', cursor: 'pointer', objectFit: 'contain', display: 'none',borderRadius:'5px',}} />}
        </div>
      })}
    </RViewerJS>
  );
}
