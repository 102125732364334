import { Box, IconButton, Skeleton, Typography, styled } from "@mui/material";
import {
  MenuItem,
  Select,
  TableCell,
  TableRow,
  TextField,
} from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import InfoIcon from "@mui/icons-material/Info";
import TableChartIcon from "@mui/icons-material/TableChart";
import VisibilityIcon from "@mui/icons-material/Visibility";
import {
  CellNameOne,
  CellNameSecond,
  CellNameThird,
} from "../BOM/BomTemplatedHeader";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { UserContextFeature } from "./FeatureConfigurationLanding";
import { API } from "../../api-services";
import { ADMIN } from "../../Redux/Services/admin.service";
const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));
interface FeatureConfigRowsProps {
  rowItems: any;
  sequence: any;
  getData: any;
  keys: any;
  getDropDownData: any;
  updateLoader: any;
  DeleteHandler: any;
  infoHandler: any;
  TableHandler: any;
  dropdownList?: any;
  getDropDownList?: any;
  CategoryData?: any;
}

const FeatureConfigRows = (props: FeatureConfigRowsProps) => {
  const {
    projectId,
    projectname,
    categoryId,
    productId,
    datatype,
    paramType,
    categoryname,
  } = useRouteParams<any>();
  const {
    rowItems,
    sequence,
    getData,
    getDropDownData,
    keys,
    updateLoader,
    DeleteHandler,
    TableHandler,
    infoHandler,
    dropdownList,
    getDropDownList,
    CategoryData,
  } = props;
  const [Sequence, setSequence] = useState<number>(
    parseInt(sequence && sequence)
  );
  const [allData, setallData] = useState<any>();
  const [requiredData, setrequiredData] = useState<any>(
    rowItems && rowItems?.required
  );
  const [ids, setIds] = useState<any>();
  const [selectedParam, setSelectedParam] = useState<any>(
    rowItems && rowItems?.table_id
  );
  const Premisssions = useContext(UserContextFeature);
  useEffect(() => {
    setallData(rowItems);
    setrequiredData(rowItems && rowItems?.required);
    if (rowItems && rowItems?.table_id) {
      setSelectedParam(rowItems && rowItems?.table_id);
    }
    setSequence(sequence && sequence);
  }, [rowItems, updateLoader, sequence]);

  const Handlechange = (e: any, id: any) => {
    setSequence(e.target.value);
    getData(e.target.value, id);
    setIds(id);
  };
  const handleChangeRequried = (e: any, id: any) => {
    setrequiredData(e.target.value);
    getDropDownData(e.target.value, id);
    setIds(id);
  };

  const handleChangeParams = (e: any) => {
    setSelectedParam(e.target.value);
    API.put(
      `/config/parameter_mapping/${rowItems.id}/`,
      {
        table_id: e.target.value,
      },
      {},
      0
    )
      .then((res: any) => {
        getDropDownList();
        ADMIN.toast.info("Parameter linked successfully");
      })
      .catch((err: any) => {
        ADMIN.toast.error("Parameter linking failed");
      });
    getDropDownList();
    // setIds(id);
  };
  const [lockStatus, setLockStatus] = useState<any>(null);

  const getLockStatusByName = (categoryname: any) => {
    // Filter the CategoryData array to find the item with the matching name
    const filteredItem = CategoryData.filter((item: any) => item.category === categoryId);

    // If an item is found (filteredItem is not empty), update the state with its lock status
    if (filteredItem.length > 0) {
      setLockStatus(filteredItem[0].lock);
    } else {
      // If no matching item is found, set the lockStatus to null or any default value
      setLockStatus(null);
    }
  };

  // Use useEffect to trigger getLockStatusByName whenever categoryname changes
  useEffect(() => {
    if (categoryname) {
      getLockStatusByName(categoryname);
    }
  }, [categoryname]);

  return (
    <>
      <>
        {CellNameOne.includes(datatype) ? (
          <TableRow key={keys}>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              <TextField
                variant="standard"
                sx={{
                  width: "4rem",
                  ".MuiInputBase-root.MuiInput-root": {
                    fontSize: "1rem !important",
                  },
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "primary.light",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "primary.main",
                  },
                }}
                type="number"
                inputProps={{
                  step: "any",
                  min: 1,
                  // style: { fontSize: '1rem', marginTop: '0.4rem' }
                }}
                disabled={lockStatus == true ? true :
                  Premisssions && Premisssions?.includes("U") ? false : true
                }
                onChange={(e: any) => Handlechange(e, allData?.id)}
                value={Sequence && Sequence}
              />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {allData && allData?.parameter?.name !== ""
                ? allData && allData?.parameter?.name
                : "-"}
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {allData && allData?.parameter?.unit !== ""
                ? allData && allData?.parameter?.unit
                : "-"}
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {allData && allData?.parameter?.description !== null
                ? allData && allData?.parameter?.description
                : "-"}
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {allData && allData?.parameter?.exceptions?.length > 0
                ? allData && allData?.parameter?.exceptions?.toString(", ")
                : "-"}
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                maxWidth: "10rem ",
              }}
            >
              <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                {allData && allData?.parameter?.allowed_values?.length > 0
                  ? allData &&
                  allData?.parameter?.allowed_values?.map(
                    (item: any, index: any) => {
                      return <span>{item},&nbsp;</span>;
                    }
                  )
                  : "-"}
              </Box>
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                maxWidth: "10rem ",
              }}
            >
              <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                {allData && allData?.parameter?.not_allowed_values?.length > 0
                  ? allData &&
                  allData?.parameter?.not_allowed_values?.map(
                    (item: any, index: any) => {
                      return (
                        <>
                          <span>{item},&nbsp;</span>
                        </>
                      );
                    }
                  )
                  : "-"}
              </Box>
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {allData && allData?.parameter?.default_value !== ""
                ? allData && allData?.parameter?.default_value
                : "-"}
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {allData && allData?.parameter?.info_doc !== null ? (
                <VisibilityIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() =>
                    window.open(
                      allData && allData?.parameter?.info_doc,
                      "_blank"
                    )
                  }
                />
              ) : (
                "-"
              )}
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              <Select
                variant="standard"
                id="demo-simple-select-filled"
                disabled={lockStatus == true ? true :
                  Premisssions && Premisssions?.includes("U") ? false : true
                }
                value={requiredData}
                onChange={(e: any) => handleChangeRequried(e, allData?.id)}
                sx={{
                  width: { lg: "75%", xl: "50%" },
                  "&:before": {
                    borderBottomColor: "white",
                  },
                  ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
                }}
              >
                {/* @ts-ignore */}
                <MenuItem sx={{ fontSize: "1rem" }} value={true}>
                  Yes
                </MenuItem>
                {/* @ts-ignore */}
                <MenuItem sx={{ fontSize: "1rem" }} value={false}>
                  No
                </MenuItem>
              </Select>
            </TableCell>

            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {allData && allData?.parameter?.data_type == "table" && (
                <>
                  {Premisssions && Premisssions?.includes("U") ? (
                    <TableChartIcon
                      onClick={() => TableHandler(allData?.id)}
                      sx={{ cursor: "pointer" }}
                    />
                  ) : (
                    <TableChartIcon sx={{ opacity: 0.3 }} />
                  )}
                </>
              )}
              {allData?.map_status ? (
                <InfoIcon
                  titleAccess="info"
                  sx={{ cursor: "pointer" }}
                  onClick={() => infoHandler(allData?.id)}
                />
              ) : (
                <>
                  {lockStatus != true && Premisssions && Premisssions?.includes("D") ? (
                    <DeleteIcon
                      titleAccess="Delete"
                      sx={{ cursor: "pointer", opacity: lockStatus == true ? 0.3 : 1 }}
                      onClick={() => {
                        if (lockStatus != true) {
                          DeleteHandler(allData?.id)
                        }
                      }}
                    />
                  ) : (
                    <DeleteIcon sx={{ opacity: 0.3 }} />
                  )}
                </>
              )}

            </TableCell>
          </TableRow>
        ) : CellNameSecond.includes(datatype) ? (
          <TableRow key={keys}>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              <TextField
                variant="standard"
                sx={{
                  width: "4rem",
                  ".MuiInputBase-root.MuiInput-root": {
                    fontSize: "1rem !important",
                  },
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "primary.light",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "primary.main",
                  },
                }}
                type="number"
                inputProps={{
                  step: "any",
                  min: 1,
                  // style: { fontSize: '1rem', marginTop: '0.4rem' }
                }}
                disabled={lockStatus == true ? true :
                  Premisssions && Premisssions?.includes("U") ? false : true
                }
                onChange={(e: any) => Handlechange(e, allData?.id)}
                value={Sequence && Sequence}
              />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {allData && allData?.parameter?.name !== ""
                ? allData && allData?.parameter?.name
                : "-"}
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {allData && allData?.parameter?.description !== null
                ? allData && allData?.parameter?.description
                : "-"}
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {allData && allData?.parameter?.info_doc !== null ? (
                <VisibilityIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() =>
                    window.open(
                      allData && allData?.parameter?.info_doc,
                      "_blank"
                    )
                  }
                />
              ) : (
                "-"
              )}
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              <Select
                variant="standard"
                id="demo-simple-select-filled"
                value={requiredData}
                disabled={lockStatus == true ? true :
                  Premisssions && Premisssions?.includes("U") ? false : true
                }
                onChange={(e: any) => handleChangeRequried(e, allData?.id)}
                sx={{
                  width: { lg: "75%", xl: "50%" },
                  "&:before": {
                    borderBottomColor: "white",
                  },
                  ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
                }}
              >
                {/* @ts-ignore */}
                <MenuItem sx={{ fontSize: "1rem" }} value={true}>
                  Yes
                </MenuItem>
                {/* @ts-ignore */}
                <MenuItem sx={{ fontSize: "1rem" }} value={false}>
                  No
                </MenuItem>
              </Select>
            </TableCell>

            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {allData && allData?.parameter?.data_type == "table" && (
                <>
                  {Premisssions && Premisssions?.includes("U") ? (
                    <TableChartIcon
                      onClick={() => TableHandler(allData?.id)}
                      sx={{ cursor: "pointer" }}
                    />
                  ) : (
                    <TableChartIcon sx={{ opacity: 0.3 }} />
                  )}
                </>
              )}
              {allData?.map_status ? (
                <InfoIcon
                  titleAccess="info"
                  sx={{ cursor: "pointer" }}
                  onClick={() => infoHandler(allData?.id)}
                />
              ) : (
                <>
                  {lockStatus != true && Premisssions && Premisssions?.includes("D") ? (
                    <DeleteIcon
                      titleAccess="Delete"
                      sx={{ cursor: "pointer" }}
                      onClick={() => DeleteHandler(allData?.id)}
                    />
                  ) : (
                    <DeleteIcon sx={{ opacity: 0.3 }} />
                  )}
                </>
              )}
            </TableCell>
          </TableRow>
        ) : CellNameThird.includes(datatype) ? (
          <TableRow key={keys}>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              <TextField
                variant="standard"
                sx={{
                  width: "4rem",
                  ".MuiInputBase-root.MuiInput-root": {
                    fontSize: "1rem !important",
                  },
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "primary.light",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "primary.main",
                  },
                }}
                type="number"
                inputProps={{
                  step: "any",
                  min: 1,
                  // style: { fontSize: '1rem', marginTop: '0.4rem' }
                }}
                disabled={lockStatus == true ? true :
                  Premisssions && Premisssions?.includes("U") ? false : true
                }
                onChange={(e: any) => Handlechange(e, allData?.id)}
                value={Sequence && Sequence}
              />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {allData && allData?.parameter?.name !== ""
                ? allData && allData?.parameter?.name
                : "-"}
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {allData && allData?.parameter?.unit !== ""
                ? allData && allData?.parameter?.unit
                : "-"}
            </TableCell>

            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {allData && allData?.parameter?.exceptions?.length > 0
                ? allData && allData?.parameter?.exceptions?.toString(", ")
                : "-"}
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {allData && allData?.parameter?.description !== null
                ? allData && allData?.parameter?.description
                : "-"}
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
                maxWidth: "10rem ",
              }}
            >
              <Box sx={{ display: "flex", flexWrap: "wrap" }}>
                {allData && allData?.parameter?.allowed_values?.length > 0
                  ? allData &&
                  allData?.parameter?.allowed_values?.map(
                    (item: any, index: any) => {
                      return <span>{item},&nbsp;</span>;
                    }
                  )
                  : "-"}
              </Box>
            </TableCell>

            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {allData && allData?.parameter?.info_doc !== null ? (
                <VisibilityIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() =>
                    window.open(
                      allData && allData?.parameter?.info_doc,
                      "_blank"
                    )
                  }
                />
              ) : (
                "-"
              )}
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              <Select
                variant="standard"
                id="demo-simple-select-filled"
                value={requiredData}
                disabled={lockStatus == true ? true :
                  Premisssions && Premisssions?.includes("U") ? false : true
                }
                onChange={(e: any) => handleChangeRequried(e, allData?.id)}
                sx={{
                  width: { lg: "75%", xl: "50%" },
                  "&:before": {
                    borderBottomColor: "white",
                  },
                  ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
                }}
              >
                {/* @ts-ignore */}
                <MenuItem sx={{ fontSize: "1rem" }} value={true}>
                  Yes
                </MenuItem>
                {/* @ts-ignore */}
                <MenuItem sx={{ fontSize: "1rem" }} value={false}>
                  No
                </MenuItem>
              </Select>
            </TableCell>


            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {allData && allData?.parameter?.data_type == "table" && (
                <>
                  {Premisssions && Premisssions?.includes("U") ? (
                    <TableChartIcon
                      onClick={() => TableHandler(allData?.id)}
                      sx={{ cursor: "pointer" }}
                    />
                  ) : (
                    <TableChartIcon sx={{ opacity: 0.3 }} />
                  )}
                </>
              )}
              {allData?.map_status ? (
                <InfoIcon
                  titleAccess="info"
                  sx={{ cursor: "pointer" }}
                  onClick={() => infoHandler(allData?.id)}
                />
              ) : (
                <>
                  {lockStatus != true && Premisssions && Premisssions?.includes("D") ? (
                    <DeleteIcon
                      titleAccess="Delete"
                      sx={{ cursor: "pointer" }}
                      onClick={() => DeleteHandler(allData?.id)}
                    />
                  ) : (
                    <DeleteIcon sx={{ opacity: 0.3 }} />
                  )}
                </>
              )}
            </TableCell>
          </TableRow>
        ) : (
          <TableRow key={keys}>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              <TextField
                variant="standard"
                sx={{
                  width: "4rem",
                  ".MuiInputBase-root.MuiInput-root": {
                    fontSize: "1rem !important",
                  },
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "primary.light",
                  },
                  "& .MuiInput-underline:after": {
                    borderBottomColor: "primary.main",
                  },
                }}
                type="number"
                inputProps={{
                  step: "any",
                  min: 1,
                  // style: { fontSize: '1rem', marginTop: '0.4rem' }
                }}
                disabled={lockStatus == true ? true :
                  Premisssions && Premisssions?.includes("U") ? false : true
                }
                onChange={(e: any) => Handlechange(e, allData?.id)}
                value={Sequence && Sequence}
              />
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {allData && allData?.parameter?.name !== ""
                ? allData && allData?.parameter?.name
                : "-"}
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {allData && allData?.parameter?.description !== null
                ? allData && allData?.parameter?.description
                : "-"}
            </TableCell>

            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {allData && allData?.parameter?.info_doc !== null ? (
                <VisibilityIcon
                  sx={{ cursor: "pointer" }}
                  onClick={() =>
                    window.open(
                      allData && allData?.parameter?.info_doc,
                      "_blank"
                    )
                  }
                />
              ) : (
                "-"
              )}
            </TableCell>
            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              <Select
                variant="standard"
                id="demo-simple-select-filled"
                value={requiredData}
                disabled={lockStatus == true ? true :
                  Premisssions && Premisssions?.includes("U") ? false : true
                }
                onChange={(e: any) => handleChangeRequried(e, allData?.id)}
                sx={{
                  width: { lg: "75%", xl: "50%" },
                  "&:before": {
                    borderBottomColor: "white",
                  },
                  ".MuiSvgIcon-root.MuiSelect-icon": { color: "primary.main" },
                }}
              >
                {/* @ts-ignore */}
                <MenuItem sx={{ fontSize: "1rem" }} value={true}>
                  Yes
                </MenuItem>
                {/* @ts-ignore */}
                <MenuItem sx={{ fontSize: "1rem" }} value={false}>
                  No
                </MenuItem>
              </Select>
            </TableCell>


            <TableCell
              sx={{
                padding: "0.2rem 0.5rem",
                borderBottomColor: "primary.light",
              }}
            >
              {allData && allData?.parameter?.data_type == "table" && (
                <>
                  {Premisssions && Premisssions?.includes("U") ? (
                    <TableChartIcon
                      onClick={() => TableHandler(allData?.id)}
                      sx={{ cursor: "pointer" }}
                    />
                  ) : (
                    <TableChartIcon sx={{ opacity: 0.3 }} />
                  )}
                </>
              )}
              {allData?.map_status ? (
                <InfoIcon
                  titleAccess="info"
                  sx={{ cursor: "pointer" }}
                  onClick={() => infoHandler(allData?.id)}
                />
              ) : (
                <>
                  {lockStatus != true && Premisssions && Premisssions?.includes("D") ? (
                    <DeleteIcon
                      titleAccess="Delete"
                      sx={{ cursor: "pointer" }}
                      onClick={() => DeleteHandler(allData?.id)}
                    />
                  ) : (
                    <DeleteIcon sx={{ opacity: 0.3 }} />
                  )}
                </>
              )}
            </TableCell>
          </TableRow>
        )}
      </>
    </>
  );
};
export default FeatureConfigRows;
