
// -------------------------------------------------------------------------------------
// api actions
// -------------------------------------------------------------------------------------

import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import GanttChartServices from "../Services/GanttChartServices";

export interface GetGanttChartData {
    project: number;
    activity_type?:any,
    work_type?:any
}

const getGanttChartData = createAsyncThunk(
    "ganttchart/get",
    async ( arg: GetGanttChartData, { rejectWithValue } ) => {
        try {
            const { data } = await GanttChartServices.get( arg.project,arg.activity_type,arg.work_type );
            return {
                ganttChart: data
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);


//getNotes
export interface GetGanttChartNotes {
    id: any;
}

const getGanttChartNotes = createAsyncThunk(
    "ganttchart/getNotes",
    async ( arg: GetGanttChartNotes, { rejectWithValue } ) => {
        try {
            const { data } = await GanttChartServices.getNotes( arg.id );
            return {
                ganttChartNotes: [data]
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

export interface UpdateGanttChartData {
    id: number;
    notes: string;
}

const updateGanttChartData = createAsyncThunk(
    "ganttchart/update",
    async ( arg: UpdateGanttChartData, { rejectWithValue } ) => {
        try {
            const { data } = await GanttChartServices.updateNotes( arg );
            return {
                ganttChartNotes: [data]
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);



// -------------------------------------------------------------------------------------
// reducer actions
// -------------------------------------------------------------------------------------
const resetGanttChart = createAction( "ganttChart/reset" );

// -------------------------------------------------------------------------------------
// exports
// -------------------------------------------------------------------------------------

export {
    getGanttChartData,
    resetGanttChart,
    updateGanttChartData,
    getGanttChartNotes
};