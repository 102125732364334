// Counter.tsx
import React, { useState, useEffect } from 'react';
import './Counter.scss';
import { API } from '../../../api-services';

interface DigitProps {
  value: number;
}

const Digit: React.FC<DigitProps> = ({ value }) => {
  return (
    <div className="digit_3">
      <span className={`digit__inner digit__inner-${value}`}>{value}</span>
    </div>
  );
};

interface CounterProps {
  target?: number;
}

const CountBoard: React.FC<CounterProps> = () => {
  const [count, setCount] = useState<number>(0);

  const getCount = ()=>{
    API.get(`/web_companion/comapanion_customer_idea_generation/count/`, {}, 0).then((res:any)=>{
        setCount(res.data)
        
    }).catch((err:any)=>{
        console.log(err)
    
    })
  }

  React.useEffect(()=>{
    getCount()
  },[])
  
  useEffect(() => {
    const intervalId = setInterval(() => {
        getCount()
      
    }, 5000);

    return () => clearInterval(intervalId);
  }, []);

  const digits = String(count).padStart(4, '0').split('').map((digit) => (
    <Digit key={digit} value={parseInt(digit, 10)} />
  ));

  return <div className="counter">{digits}</div>;
};

export default CountBoard;