import { Divider, Modal, Skeleton } from "@mui/material";
import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import CopyHierarchyBOM from "./CopyHierarchyBOM";
import { API } from "../../../api-services";

import CancelIcon from "@mui/icons-material/Cancel";
import { useBaseModal } from "../../SearchPage/useBaseModal";
import ErrorModalFilesMail from "../../ProjectMailModule/ErrorModalFilesMail";
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: { lg: "40vw", xl: "30vw" },
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

interface CopyHierarchyBOMModalProps {
  onCloseModal: any;
  isOpen: any;
  SelectedProduct: any;
  getModalData: any;
  ApplyHandler: any;
}

export default function CopyHierarchyBOMModal(
  props: CopyHierarchyBOMModalProps
) {
  const { onCloseModal, isOpen, SelectedProduct, getModalData, ApplyHandler } =
    props;
  const [HierarchyData, setHierarchyData] = React.useState<any>([]);
  const [loader, setLoader] = useState<any>(false);
  const [checkboxvalue, setcheckboxvalue] = useState<any>([]);
  const [CheckboxData, setCheckboxData] = useState<any>();
  const [ErrorModalFileMsg, setErrorModalFileMsg] = useState<any>();
  const ErrorModalFiles = useBaseModal();
  useEffect(() => {
    setLoader(true);
    API.get(
      "/xcpep/bom_create_hirearchy/",
      { top_vault: +SelectedProduct?.id },
      0
    )
      .then((res: any) => {
        setLoader(false);
        setHierarchyData(res?.data?.data);
      })
      .catch((err: any) => {
        setLoader(false);
        console.log("Server EEror");
      });
  }, [SelectedProduct]);

  const onSelectCallback = (data: any) => {
    if (checkboxvalue?.includes(data?.id)) {
      setCheckboxData({});
      setcheckboxvalue([]);
    } else {
      API.get("/xcpep/vault_copy_info/", {
        root: data?.id,
        source: +getModalData?.id,
      })
        .then((res: any) => {
          setcheckboxvalue([data?.id]);
          setCheckboxData(data);
        })
        .catch((err: any) => {
          setcheckboxvalue([]);
          const { data } = err.response;
          console.log(data, "datadata");
          ErrorModalFiles.open();
          setErrorModalFileMsg(data);
          console.log("Server Error");
        });
    }
  };

  const SaveHandler = () => {
    ApplyHandler(CheckboxData);
    onCloseModal();
  };
  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>
          <ErrorModalFilesMail
            isOpen={ErrorModalFiles.isOpen}
            onCloseModal={ErrorModalFiles.close}
            ErrorMsg={ErrorModalFileMsg}
          />
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}
            >
              Select Part/Assembly
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "0.5rem",
            }}
          />
          <Box>
            {loader ? (
              <>
                <Skeleton></Skeleton>
                <Skeleton></Skeleton>
                <Skeleton></Skeleton>
                <Skeleton></Skeleton>
                <Skeleton></Skeleton>
                <Skeleton></Skeleton>
                <Skeleton></Skeleton>
              </>
            ) : (
              <Box>
                <Box
                  sx={{
                    textAlign: "right",
                    width: "100%",
                    padding: "0 1rem",
                    marginBottom: "0.5rem",
                  }}
                >
                  <Button
                    size="small"
                    variant="contained"
                    sx={{
                      "&:hover": {
                        transform: "Scale(1.05)",
                        transition: "transform 0.5s ease",
                      },
                    }}
                    onClick={() => SaveHandler()}
                  >
                    Apply
                  </Button>
                </Box>
                <Box
                  sx={{ height: "80vh" }}
                  adl-scrollbar="true"
                  adl-scrollbar-width="0.3"
                >
                  <CopyHierarchyBOM
                    HierarchyData={HierarchyData}
                    onSelectCallback={onSelectCallback}
                    checkboxvalue={checkboxvalue}
                    setcheckboxvalue={setcheckboxvalue}
                  />
                </Box>
              </Box>
            )}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
