// react 
import React, { memo } from 'react';
import './BouncingLoader.scss'

// vendors
// services
// utils
// core
// ui-components
// others
// scss

const BouncingLoader = () => {
    return (
        <div className="bouncing-loader">
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
        </div>

    );
};

export default memo( BouncingLoader );


