// vendors
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";


// redux
// import { RepoImage } from "app/Redux/Reducers/imageRepository.reducer";
// import { ImageRepositoryProductInfo } from "app/Redux/Reducers/imageRepositoryProductInfo.reducer";
import { ID } from "../../utlis/commonInterfaces";
import { RepoImage } from "../Reducers/imageRepository.reducer";
import { ImageRepositoryProductInfo } from "../Reducers/imageRepositoryProductInfo.reducer";
import imageRepositoryService from "../Services/imageRepository.service";

// -------------------------------------------------------------------------------------
// api actions
// -------------------------------------------------------------------------------------

export interface GetRepoImagesArg {
    topVaultId: ID;
    subsystemAbbreviation: string | number;
    imageCategory: any;
    media_type: string;
    project?: any;
    row?: number;
    page?: number;
    group?: any;
}
const getRepoImages = createAsyncThunk(
    "imageRepository/get",
    async ( arg: GetRepoImagesArg, { rejectWithValue } ) => {
        try {
            const { data: { images, ...rest } } = await imageRepositoryService.get( arg );
            return {
                images: images as RepoImage[],
                productInfo: rest as ImageRepositoryProductInfo
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

// ---------------create get image media---------------------------------------------------------
export interface GetRepoCreateImagesArg {
    topVaultId: ID;
    subsystemAbbreviation: string | number;
    imageCategory: any;
    media_type: string;
    project?: any;
    row?: number;
    page?: number;
    group?: any;
}
const getRepoCreateImages = createAsyncThunk(
    "imageRepository/get",
    async ( arg: GetRepoCreateImagesArg, { rejectWithValue } ) => {
        try {
            const { data: { images, ...rest } } = await imageRepositoryService.getCreate( arg );
            return {
                images: images as RepoImage[],
                productInfo: rest as ImageRepositoryProductInfo
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);
//---------------------------------------------------------------------------------------------

export interface GetRepoImagesHeaderDataArg {
    topVaultId: ID;
    subsystemAbbreviation: string | number;
    imageCategory: number;
    media_type: string;
}
 const getRepoImagesHeaderData = createAsyncThunk(
    "imageRepository/getHeaderData",
    async ( arg: GetRepoImagesHeaderDataArg, { rejectWithValue } ) => {
        try {
            const { data } = await imageRepositoryService.get_infoImage( arg );

            return {
                infoImage: data as any,
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

// -------------------create media clear/delete image---------------------------------------------------------

export interface DeleteRepoImageArg {
    id: ID;
    /** if false, creates as blank sequence */
    rearrange: boolean;
    page_items?: any;
    key?: any;
    media_type: string;
}
const deleteRepoImage = createAsyncThunk(
    "imageRepository/delete",
    async ( arg: DeleteRepoImageArg, { rejectWithValue } ) => {
        try {
            const { data } = await imageRepositoryService.delete( arg );

            return {
                sequenceObject: data as Record<string, number>
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

// ---------------------delete if image does not exist-----------------------------------------------------

export interface DeleteBlankRepoImageArg {
    topVaultId: ID;
    vaultId: ID | null;
    sequence: number;
    category: number;
    page_items?: any,
    key?: any;
    media_type: string;
}
const deleteBlankRepoImage = createAsyncThunk(
    "imageRepository/deleteBlank",
    async ( arg: DeleteBlankRepoImageArg, { rejectWithValue } ) => {
        try {
            const { data } = await imageRepositoryService.deleteBlankSpace( arg );

            return {
                sequenceObject: data as Record<string, number>
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

// -------------------------------------------------------------------------------------

export interface MoveRepoImageArg {
    id: ID;
    newSequence: number;
    media_type?: any;
    project?: any,
    abbreviation?: any,
}
const moveRepoImage = createAsyncThunk(
    "imageRepository/move",
    async ( arg: MoveRepoImageArg, { rejectWithValue } ) => {
        try {
            const { data } = await imageRepositoryService.moveRepoImage( arg );

            return {
                sequenceObject: data as Record<string, number>
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

// -------------------create rotate the image--------------------------------------------------------

export interface RotateRepoImageArg {
    id: ID;
    rotation?: any;
    mediaType?: any;
}
const rotateRepoImage = createAsyncThunk(
    "imageRepository/rotate",
    async ( arg: RotateRepoImageArg, { rejectWithValue } ) => {
        try {
            const { data } = await imageRepositoryService.rotate( arg );

            return {
                image: data as RepoImage
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

// -------------------------create blank image--------------------------------------------------

export interface CreateBlankRepoImageArg {
    topVaultId: ID;
    vaultId: ID | null;
    sequence: number;
    category: number;
    mediaType?: string;
    groupId?:any
}
const createBlankRepoImage = createAsyncThunk(
    "imageRepository/createBlank",
    async ( arg: CreateBlankRepoImageArg, { rejectWithValue } ) => {
        try {
            const { data } = await imageRepositoryService.createBlankSpace( arg );

            return {
                sequenceObject: data as Record<string, number>
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

//-----------------------------------sequence change image card-----------------------------------------------

export interface SequenceImageCardProp {
    id: any;
    new_sequence: any;
    project: any,
    abbreviation: any,
}

 const SequenceImageCard = createAsyncThunk(
    "GroupingImage/MoveImageCard",
    async (
        arg: SequenceImageCardProp,
        { rejectWithValue }
    ) => {
        try {
            const { data } = await imageRepositoryService.moveImageCard(
                arg.id,
                arg.new_sequence,
                arg.project,
                arg.abbreviation
            );
            return data;
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

// -------------------------------------------------------------------------------------

export interface UpdateRepoImageArg {
    id: ID;
    sequence?: number;
    image?: any;
    rgb?: boolean;
    media_type?: any;
    group?: any;
    is_mute?:any;
}
const updateRepoImage = createAsyncThunk(
    "imageRepository/update",
    async ( arg: UpdateRepoImageArg, { rejectWithValue } ) => {
        try {
            const { data }: any = await imageRepositoryService.update( arg );

            return {
                image: data as RepoImage
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

// -------------------------------------------------------------------------------------

export type CreateRepoImageArg = Omit<RepoImage, "id" | "original" | "thumbnail"> & {
    image: any;
    media_type?: string;
    rgb?: boolean;
    is_mute?:any;
    fetch?: boolean;
};
const createRepoImage = createAsyncThunk(
    "imageRepository/create",
    async ( arg: CreateRepoImageArg, { rejectWithValue } ) => {
        try {
            const { data } = await imageRepositoryService.create( arg );

            return {
                image: data[0] as RepoImage
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

//------------------------ upload bulk image product level----------------------------------------------

export interface UploadRepoImagesArg {
    topVaultId: ID;
    /** null if for topvault */
    vaultId: ID | null;
    images: any;
    imageCategory: number;
    rgb: boolean;
    media_type: string;
    group?: any;
    is_mute?:any;
}
const uploadRepoImages = createAsyncThunk(
    "imageRepository/upload",
    async ( arg: UploadRepoImagesArg, { rejectWithValue } ) => {
        try {
            const { data } = await imageRepositoryService.upload( arg );

            return {
                images: data as RepoImage[]
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

// -------------------------------------------------------------------------------------
// reducer actions
// -------------------------------------------------------------------------------------

const resetImageRepository = createAction( "imageRepository/reset" );
const clearRepoImages = createAction<ID>( "imageRepository/clearByTopVault" );

// -------------------------------------------------------------------------------------
// exportsclearRepoImages
// -------------------------------------------------------------------------------------

export {
    getRepoImages,
    getRepoCreateImages,
    clearRepoImages,
    getRepoImagesHeaderData,
    resetImageRepository,
    deleteRepoImage,
    deleteBlankRepoImage,
    moveRepoImage,
    rotateRepoImage,
    createBlankRepoImage,
    SequenceImageCard,
    updateRepoImage,
    createRepoImage,
    uploadRepoImages
};
