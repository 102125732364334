import { Box, FormControl, MenuItem, Select } from "@mui/material";

import React, { useEffect } from "react";
import { BOMPCBOptions, pcb_table_element_list } from "./calculator.constant";

function RenderValueColumnItemPCBCalc({
  Lockstatus,
  useraction,
  input,
  setExpandInput,
  setExpand,
  handleChange,
  getOptionData,
  getCalculatorColumns,
  bomCalculatorColumns,
  table_element_list,
  isTableParameter,
  getdatafromtype,
  handleSave,
  pcbItemNames,
  editableRow,
  namesAndParams,
}: any) {
  useEffect(() => {
    if (input?.parameter) {
      getCalculatorColumns(input?.parameter);
    }
  }, [input]);


  return (
    <Box sx={{ display: "flex", alignItems: "center", columnGap: "0.5rem" }}>
      <FormControl fullWidth>
        {editableRow == input?.id ? (
          <Select
            labelId="demo-simple-select-label"
            id="demo-simple-select"
            variant="standard"
            label="Type"
            placeholder="Select Parameters"
            disabled={
              Lockstatus?.lock_status == false && useraction?.includes("U")
                ? (input.name == null || input.name == "") &&
                  Number(input.id) < 1
                  ? true
                  : false
                : true
            }
            value={input.table_column_parameter || ""}
            onFocus={() => {
              setExpandInput(input);
              setExpand(false);
            }}
            name="table_column_parameter"
            sx={{
              width: "50%",
              fontSize: "1rem",
              "&:before": {
                borderBottomColor: "primary.light",
              },
              ".MuiSvgIcon-root.MuiSelect-icon": {
                color: "primary.main",
              },
            }}
            // onBlur={()=> (Lockstatus?.lock_status==false && useraction?.includes("U")) && input.parameter!=null && input.parameter!="" && handleSave(input.id)}
            onChange={handleChange(input.id)}
          >
            {namesAndParams?.map((item: any) => {
              return (
                <MenuItem
                  sx={{ fontSize: "1rem" }}
                  value={item.id}
                  onClick={() => {
                    getCalculatorColumns(item.id);
                  }}
                >
                  {item.name}
                </MenuItem>
              );
            })}
          </Select>
        ) : (
          (namesAndParams &&
            namesAndParams?.filter(
              (item: any) => item?.id == input.table_column_parameter
            )[0]?.name) ||
          ""
        )}
      </FormControl>

      {input.table_column_parameter &&
        namesAndParams &&
        namesAndParams?.filter(
          (item: any) => item?.id == input.table_column_parameter
        )[0]?.name === "Item Name" && (
          <FormControl fullWidth>
            {editableRow == input?.id ? (
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                variant="standard"
                label="Type"
                placeholder="Select calculators"
                disabled={
                  Lockstatus?.lock_status == false && useraction?.includes("U")
                    ? (input?.name == null || input?.name == "") &&
                      Number(input?.id) < 1
                      ? true
                      : false
                    : true
                }
                value={input?.pcb_item_name || ""}
                onFocus={() => {
                  setExpandInput(input);
                  setExpand(false);
                }}
                name="pcb_item_name"
                sx={{
                  width: "50%",
                  fontSize: "1rem",
                  "&:before": {
                    borderBottomColor: "primary.light",
                  },
                  ".MuiSvgIcon-root.MuiSelect-icon": {
                    color: "primary.main",
                  },
                }}
                //onBlur={()=> (Lockstatus?.lock_status==false && useraction?.includes("U")) && input?.table_column_parameter!=null && input?.table_column_parameter!="" && handleSave(input?.id)}
                onChange={handleChange(input?.id)}
              >
                {pcbItemNames?.map((item: any) => {
                  return (
                    <MenuItem sx={{ fontSize: "1rem",backgroundColor:item === "All Components"?"antiquewhite":"" }} value={item}>
                      {item}
                    </MenuItem>
                  );
                })}
              </Select>
            ) : (
              input?.pcb_item_name
            )}
          </FormControl>
        )}

      {input?.pcb_item_name &&
        namesAndParams &&
        namesAndParams?.filter(
          (item: any) => item?.id == input.table_column_parameter
        )[0]?.name === "Item Name" && (
          <FormControl fullWidth>
            {editableRow == input.id ? (
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                variant="standard"
                label="Type"
                placeholder="Select calculators"
                disabled={
                  Lockstatus?.lock_status == false && useraction?.includes("U")
                    ? (input?.name == null || input?.name == "") &&
                      Number(input?.id) < 1
                      ? true
                      : false
                    : true
                }
                value={input?.pcb_item_type || ""}
                onFocus={() => {
                  setExpandInput(input);
                  setExpand(false);
                }}
                name="pcb_item_type"
                sx={{
                  width: "50%",
                  fontSize: "1rem",
                  "&:before": {
                    borderBottomColor: "primary.light",
                  },
                  ".MuiSvgIcon-root.MuiSelect-icon": {
                    color: "primary.main",
                  },
                }}
                onBlur={() =>
                  Lockstatus?.lock_status == false &&
                  useraction?.includes("U") &&
                  input?.pcb_item_name != null &&
                  input?.pcb_item_name != "" &&
                  input?.pcb_item_type != null &&
                  input?.pcb_item_type != "" &&
                  handleSave(input?.id)
                }
                onChange={handleChange(input?.id)}
              >
                {BOMPCBOptions?.map((item: any) => {
                  return (
                    <MenuItem sx={{ fontSize: "1rem",backgroundColor: item.title == "ALL"?"antiquewhite !important":"" }} value={item.value}>
                      {item.title}
                    </MenuItem>
                  );
                })}
              </Select>
            ) : (
              BOMPCBOptions?.filter(
                (item: any) => item.value == input?.pcb_item_type
              )[0]?.title || ""
            )}
          </FormControl>
        )}

      {input.table_column_parameter &&
        namesAndParams &&
        namesAndParams?.filter(
          (item: any) => item?.id == input.table_column_parameter
        )[0]?.name !== "Item Name" && (
          <FormControl fullWidth>
            {editableRow == input.id ? (
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                variant="standard"
                label="Type"
                placeholder="Select calculators"
                disabled={
                  Lockstatus?.lock_status == false && useraction?.includes("U")
                    ? (input?.name == null || input?.name == "") &&
                      Number(input?.id) < 1
                      ? true
                      : false
                    : true
                }
                value={input?.table_element_type || ""}
                onFocus={() => {
                  setExpandInput(input);
                  setExpand(false);
                }}
                name="table_element_type"
                sx={{
                  width: "50%",
                  fontSize: "1rem",
                  "&:before": {
                    borderBottomColor: "primary.light",
                  },
                  ".MuiSvgIcon-root.MuiSelect-icon": {
                    color: "primary.main",
                  },
                }}
                onBlur={() =>
                  Lockstatus?.lock_status == false &&
                  useraction?.includes("U") &&
                  input.table_column_parameter != null &&
                  input.table_column_parameter != "" &&
                  handleSave(input?.id)
                }
                onChange={handleChange(input?.id)}
              >
                {pcb_table_element_list?.map((item: any) => {
                  return (
                    <MenuItem sx={{ fontSize: "1rem" }} value={item.value}>
                      {item.title}
                    </MenuItem>
                  );
                })}
              </Select>
            ) : (
              input?.table_element_type || ""
            )}
          </FormControl>
        )}
    </Box>
  );
}

export default RenderValueColumnItemPCBCalc;
