import * as React from "react";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import swal from "sweetalert";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import {
  Avatar,
  Box,
  Chip,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import { useHistory } from "react-router-dom";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { API } from "../../api-services";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Cancel";
import {
  RoleList,
  RoleListAll,
  projectSpecificModules,
} from "../../Constants/projectspecificmodules.constants";
import { LoadingButton } from "@mui/lab";
import avatar from "../../Assets/images/maleavatar.png";
import Image from "../../ViewModule/FeatureLandingPage/ArchitectureImageCard";
import LoadingImage from "../../Assets/images/loading-screen.gif";
import { EditRoleProjectSpec } from "./EditRoleProjectSpec";
import { useSelector } from "react-redux";
import AddprojectPermissionModal from "./AddProjectPermissionModal";
import { useBaseModal } from "../SearchPage/useBaseModal";

export interface IPermissionModuleAccordionProps {
  modules?: any;
}
const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    expandIcon={
      <ArrowForwardIosSharpIcon
        sx={{ fontSize: "0.9rem", color: "primary.main" }}
      />
    }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark"
      ? "rgba(255, 255, 255, .05)"
      : "rgba(0, 0, 0, .03)",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      // width: 250,
    },
  },
};

const names = [
  "Oliver Hansen",
  "Van Henry",
  "April Tucker",
  "Ralph Hubbard",
  "Omar Alexander",
  "Carlos Abbott",
  "Miriam Wagner",
  "Bradley Wilkerson",
  "Virginia Andrews",
  "Kelly Snyder",
];

export function ProjectSpecificModuleAccordiion(
  props: IPermissionModuleAccordionProps
) {
  const { modules } = props;
  const { projectId, moduleId, filterOPtion, routeType } =
    useRouteParams<any>();
  const history = useHistory();
  const addModal = useBaseModal();
  const store: any = useSelector((state) => state);

  // const [expanded, setExpanded] = React.useState<string | false>(moduleId);
  const [specificPermissionList, setSpecificPermissionList] = React.useState<any>([]);
  const [permissionApiCallCount, setPermissionApiCallCount] =
    React.useState<any>(0);
  const [isPermissionListLoading, setIsPermissionListLoading] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    if (moduleId !== "Project Specific Modules") {
      // setExpanded(false);
      handleSpecificChange(moduleId);
    }
  }, [moduleId]);
  // const handleSpecificChange =
  //   (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
  //     setExpanded(newExpanded ? panel : false);
  //     history.push(
  //       `/user-permissions/${projectId}/${panel}/${filterOPtion}/${routeType}`
  //     );      
  //   };
  // React.useEffect(()=>{
  //   handleSpecificChange(moduleId)
  // },[permissionApiCallCount])

  const handleSpecificChange = (modId: any) => {
      history.push(`/user-permissions/${projectId}/${modId}/${filterOPtion}/${routeType}`
      );      
    };
  React.useEffect(()=>{
    handleSpecificChange(moduleId)
  },[permissionApiCallCount])

  const getPermissionsData = () => {
    setIsPermissionListLoading(true);
    API.get(
      "/auth/assign_permission/",
      {
        submodule: moduleId,
        project: projectId,
        user_type: filterOPtion,
        action:
          store?.is_compare?.permission_filter?.length > 0
            ? store?.is_compare?.permission_filter?.toString()
            : undefined,
        user_id:
          typeof store?.is_compare?.selected_userId !== "string"
            ? store?.is_compare?.selected_userId
            : undefined,
      },
      0
    )
      .then((res: any) => {
        setSpecificPermissionList(res.data);
        setIsPermissionListLoading(false);
      })
      .catch((err: any) => {
        console.log(err);
        setIsPermissionListLoading(false);
      });
  };

  React.useEffect(() => {
    if (moduleId === "Project Specific Modules") {
      getPermissionsData();
    }
  }, [
    moduleId,
    projectId,
    permissionApiCallCount,
    filterOPtion,
    store?.is_compare?.permission_filter,
    store?.is_compare?.selected_userId,
  ]);

  var AvatarName = (item: any) => {
    const [first, last] = item?.split(" ");
    const FirstLatter = first?.charAt(0);
    const lastWord = last?.charAt(0);
    return `${FirstLatter}${lastWord}`;
  };

  // Add Project specific module
  // const { isOpen, onCloseModal,setPermissionApiCallCount } = props;
  // const { projectId, moduleId, filterOPtion, routeType } = useRouteParams<any>();
  // const dispatch = useDispatch()
  // const classes = useStyles();
  // const { url } = useRouteMatch();
  // const history = useHistory();
  const [roles, setRoles] = React.useState<any>([]);
  const [users, setUsers] = React.useState<any>([]);
  const [userList, setUserList] = React.useState<any>([]);
  const [module, setModule] = React.useState<any>([]);
  const [isSaveLoading,setIsSaveLoading]=React.useState<boolean>(false)

  const getUserList = () => {
    if(filterOPtion == "all"){
      API.get(
        "/auth/user/",
        {
          is_active: true,
          is_superuser: false,
          sub_module: moduleId,
          project: projectId,
          
  
        },
        0
      )
        .then((res: any) => {
          setUserList(res.data);
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
    else{
      API.get(
        "/auth/user/",
        {
          is_active: true,
          is_superuser: false,
          sub_module: moduleId,
          project: projectId,
          user_category: filterOPtion
  
        },
        0
      )
        .then((res: any) => {
          setUserList(res.data);
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
    
  };

  React.useEffect(() => {
    getUserList();
  }, [filterOPtion]);

  // let handleOnClose = () => {
  //   onCloseModal();
  // };
  const handleSelectChange = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    setUsers(value);
  };
  React.useEffect(() => {
    setUsers([]);
    setRoles([]);
    setModule([]);
  }, [projectId, moduleId]);







  const handleSubmit = () => {
    setIsSaveLoading(true)
    API.post(
      "/auth/assign_permission/",
      {
        user_list: users,
        submodule_list:module,
          // moduleId === "Project Specific Modules" ? module : [moduleId],
        action: roles,
        project: projectId,
      },
      {},
      0
    )
      .then((res: any) => {
        setPermissionApiCallCount((prev: any) => prev + 1);
        setUsers([]);
        setRoles([]);
        setModule([]);
        setIsSaveLoading(false)
        getPermissionsData()
      })
      .catch((err: any) => {
        console.log("permission error: " + err);
        setIsSaveLoading(false)
      });
  };

  const handleSelectChangeRole = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    setRoles(value);
  };

  const handleSelectChangeModule = (event: SelectChangeEvent<any>) => {
    const {
      target: { value },
    } = event;
    setModule(value);
  };

  
                               
                                

  return (
    <Box>
      {/* {addModal.isOpen && (
        <AddprojectPermissionModal
          isOpen={addModal.isOpen}
          onCloseModal={addModal.close}
          setPermissionApiCallCount={setPermissionApiCallCount}
        />
      )} */}
      {/* <Box sx={{display:'flex',alignItems:'center',justifyContent:'space-between',padding:'0 0.5rem',backgroundColor:'primary.light'}}>
          <Typography sx={{fontSize:'1rem'}}>Project Specific Modules</Typography>
          <CloseIcon titleAccess="Remove all access"
              sx={{ color: "red", cursor: "pointer" }}
              onClick={(event: any) => {
                event.stopPropagation();
                swal({
                  title: "Are you sure?",
                  text: "All user permissions will be deleted from this module.",
                  // icon: "warning",
                  buttons: ["No", "Yes"],
                }).then((isData: any) => {
                  if (isData) {
                API.delete(
                        "/auth/assign_permission/",
                        { submodule: 'Project Specific Modules', project: projectId },
                        0
                      )
                        .then((res: any) => {
                          setPermissionApiCallCount((prev: any) => prev + 1);
                        })
                        .catch((err: any) => {
                          console.log(err);
                        });
                  }
                }).catch((e: any) => {
                });
              }}
            />
      </Box> */}
      {/* <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              // marginBottom: "1rem",
            }}
          /> */}
      {modules?.map((item: any, index: number) => {
        return (<>
          <Table>
                <TableBody>
                  <TableRow>  
                  <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottom: "none",
                        width: "3vw",
                      }}
                    >
                      &nbsp;
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottom: "none",
                        width: "5vw",
                      }}
                    >   &nbsp;                       
                    </TableCell>                  
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottom: "none",
                        width: "23vw",
                        verticalAlign: "bottom",
                      }}
                    >
                      <FormControl fullWidth variant="standard">
                        <InputLabel id="demo-simple-select-label">
                          Select Users
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={users&&users}
                          multiple
                          multiline
                          label="User"
                          onChange={handleSelectChange}
                          size="small"
                          placeholder="Select Users"
                          sx={{
                            "&:before": {
                              borderBottomColor: "primary.main",
                            },
                            '.MuiSvgIcon-root.MuiSelect-icon':{color:'primary.main'},	
                          }}
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              
                              
                               
                              {userList
                                .filter((item: any) =>
                                  selected?.includes(item?.id)
                                )
                                ?.map((value: any) => (
                                  <Chip
                                  size="small"
                                  sx = {{
                                    color:value?.user_category === "External" ? "red" : "primary.main"
                                  }}
                                    key={value.id}
                                    label={`${value?.first_name} ${value?.last_name}`}
                                    onDelete={() =>
                                      setUsers((prev: any) =>
                                        prev?.filter(
                                          (item: any) => value.id !== item
                                        )
                                      )
                                    }
                                    deleteIcon={
                                      <CancelIcon
                                    
                                      sx = {{
                                        color:value?.user_category === "External" ? "red" : "primary.main"
                                      }}
                                    
                                        onMouseDown={(event) =>
                                          event.stopPropagation()
                                        }
                                      />
                                      
                                    }
                                  />
                                  
                                ))}
                            </Box>
                          )}
                          
                          MenuProps={MenuProps}
                        >
                          {userList &&
                            userList?.map((item: any, index: number) => {                              
                              return (
                                !users?.includes(item.id) && (
                                  <MenuItem key={index} value={item?.id} sx={{color : item?.user_category === "External" ? "red" : "primary.main"}}>
                                    {item?.first_name} {item?.last_name}
                                  </MenuItem>
                                )
                              );
                            })}
                        </Select>
                      </FormControl>
                    </TableCell>
                    {/* {moduleId === "Project Specific Modules" && ( */}
                      <TableCell
                        sx={{
                          padding: "0.2rem 0.5rem",
                          borderBottom: "none",
                          width: "40vw",
                          verticalAlign: "bottom",
                        }}
                      >
                        <FormControl fullWidth variant="standard">
                          <InputLabel id="demo-simple-select-label">
                            Select Module
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={module&&module}
                            multiple
                            multiline
                            //   label="Module"
                            placeholder="Select Module"
                            onChange={handleSelectChangeModule}
                            size="small"
                            sx={{
                              "&:before": {
                                borderBottomColor: "primary.main",
                              },
                              '.MuiSvgIcon-root.MuiSelect-icon':{color:'primary.main'},	
                            }}
                            renderValue={(selected) => (
                              <Box
                                sx={{
                                  display: "flex",
                                  flexWrap: "wrap",
                                  gap: 0.5,
                                }}
                              >
                                {projectSpecificModules
                                  .filter((item: any) =>
                                    selected?.includes(item)
                                  )
                                  ?.map((value: any) => (
                                    <Chip
                                    size="small"
                                      key={value}
                                      label={value}
                                      onDelete={() =>
                                        setModule((prev: any) =>
                                          prev?.filter(
                                            (item: any) => value !== item
                                          )
                                        )
                                      }
                                      deleteIcon={
                                        <CancelIcon
                                          onMouseDown={(event) =>
                                            event.stopPropagation()
                                          }
                                        />
                                      }
                                    />
                                  ))}
                              </Box>
                            )}
                            MenuProps={MenuProps}
                          >
                            {projectSpecificModules &&
                              projectSpecificModules?.map(
                                (item: any, index: number) => {
                                  return (
                                    !module?.includes(item) && (
                                      <MenuItem key={index} value={item}>
                                        {item}
                                      </MenuItem>
                                    )
                                  );
                                }
                              )}
                          </Select>
                        </FormControl>
                      </TableCell>
                    {/* )} */}
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottom: "none",
                        width: "19vw",
                        verticalAlign: "bottom",
                      }}
                    >
                      <FormControl fullWidth variant="standard">
                        <InputLabel id="demo-simple-select-label">
                          Select roles
                        </InputLabel>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={roles&&roles}
                          multiple
                          multiline
                          label="Role"
                          placeholder="Select Role"
                          onChange={handleSelectChangeRole}
                          size="small"
                          sx={{
                            "&:before": {
                              borderBottomColor: "primary.main",
                            },
                            '.MuiSvgIcon-root.MuiSelect-icon':{color:'primary.main'},	
                          }}
                          renderValue={(selected) => (
                            <Box
                              sx={{
                                display: "flex",
                                flexWrap: "wrap",
                                gap: 0.5,
                              }}
                            >
                              {RoleListAll.filter((item: any) =>
                                selected?.includes(item?.value)
                              )?.map((value: any) => (
                                <Chip
                                size="small"
                                  key={value.value}
                                  label={value.value}
                                  onDelete={() =>
                                    setRoles((prev: any) =>
                                      prev?.filter(
                                        (item: any) => value.value !== item
                                      )
                                    )
                                  }
                                  deleteIcon={
                                    <CancelIcon
                                      onMouseDown={(event) =>
                                        event.stopPropagation()
                                      }
                                    />
                                  }
                                />
                              ))}
                            </Box>
                          )}
                          MenuProps={MenuProps}
                        >
                          {RoleListAll &&
                            RoleListAll?.map((item: any, index: number) => {
                              return (
                                !roles?.includes(item?.value) && (
                                  <MenuItem key={index} value={item?.value}>
                                    {item.text}
                                  </MenuItem>
                                )
                              );
                            })}
                        </Select>
                      </FormControl>
                    </TableCell>
                    <TableCell
                      sx={{
                        padding: "0.2rem 0.5rem",
                        borderBottom: "none",
                        width: "6vw",          
                      }}
                    >
                      <Box sx={{display:'flex',alignItems:'center',columnGap:'0.5rem',justifyContent:'flex-end'}}>
                      <LoadingButton
                        sx={{ minWidth: "1rem" }}
                        disabled={users?.length === 0 ? true : false}
                        onClick={() => handleSubmit()}
                        loading={isSaveLoading}
                      >
                        <SaveIcon titleAccess="Save" />
                      </LoadingButton>
                      <CloseIcon titleAccess="Remove all access"
                        sx={{ color: "red", cursor: "pointer" }}
                        onClick={(event: any) => {
                          event.stopPropagation();
                          swal({
                            title: "Are you sure?",
                            text: "All user permissions will be deleted from this module.",
                            // icon: "warning",
                            buttons: ["No", "Yes"],
                          }).then((isData: any) => {
                            if (isData) {
                          API.delete(
                                  "/auth/assign_permission/",
                                  { submodule: 'Project Specific Modules', project: projectId },
                                  0
                                )
                                  .then((res: any) => {
                                    setPermissionApiCallCount((prev: any) => prev + 1);
                                  })
                                  .catch((err: any) => {
                                    console.log(err);
                                  });
                            }
                          }).catch((e: any) => {
                          });
                        }}
                      />
                      </Box>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
              {!isPermissionListLoading ? (<Box
                       sx={{ height: {lg:'59vh',xl:"71vh"} }}
                       adl-scrollbar="true"
                       adl-scrollbar-width="0.3"
                     >
                       {specificPermissionList.length > 0 ? <Table>
                         <TableBody>
                           {specificPermissionList?.map((item: any, index: number) => {
                             return (
                               <TableRow>
                                 <TableCell
                                   sx={{
                                     padding: "0.2rem 0.5rem",
                                     borderBottom: "none",
                                     width: "2vw",
                                   }}
                                 >
                                   {index + 1}
                                 </TableCell>
                                 <TableCell
                                   sx={{
                                     padding: "0.2rem 0.5rem",
                                     borderBottom: "none",
                                     width: "3vw",
                                   }}
                                 >
                                   {item && item.image ? (
                                     <Image
                                       alt="UserImg"
                                       placeholderImg={LoadingImage}
                                       src={item?.image}
                                       style={{
                                         width: "2rem",
                                         height: "2rem",
                                         borderRadius: "50%",
                                         objectFit: "cover",
                                       }}
                                     />
                                   ) : (
                                     <Avatar
                                       src="/broken-image.jpg"
                                       sx={{
                                         width: { xs: "2rem", sm: "2rem" },
                                         height: { xs: "2rem", sm: "2rem" },
                                         fontSize: "1rem !important",
                                         objectFit: "cover",
                                         backgroundColor: "#007fff !important",
                                         textTransform: "uppercase",
                                         " .MuiAvatar-root.MuiAvatar-circular": {
                                           backgroundColor: "primary.main",
                                         },
                                       }}
                                     >
                                       {AvatarName(item?.fullname)}
                                     </Avatar>
                                   )}
                                 </TableCell>
                                 <TableCell
                                   sx={{
                                     padding: "0.2rem 0.5rem",
                                     borderBottom: "none",
                                     width: "15vw",
                                     textAlign: "left",
                                   }}
                                 >
                                   <Box
                                     sx={{
                                       display: "flex",
                                       alignItems: "center",
                                       columnGap: "1rem",
                                     }}
                                   >
                                     <Typography sx={{ fontSize: "1rem" }}>
                                       {item?.fullname}
                                     </Typography>
                                   </Box>
                                 </TableCell>
                                 <TableCell
                                   sx={{
                                     padding: "0.2rem 0.5rem",
                                     borderBottom: "none",
                                     width: "25vw",
                                   }}
                                 >
                                   {item?.submodule}
                                 </TableCell>
     
                                 <TableCell
                                   sx={{
                                     padding: "0.2rem 0.5rem",
                                     borderBottom: "none",
                                     width: "12vw",
                                   }}
                                 >
                                   <EditRoleProjectSpec
                                     action={item?.actions || []}
                                     itemId={item.id}
                                     roleOptions={RoleListAll}
                                     selectedModules={item?.submodule}
                                     user={item?.user}
                                   />
                                 </TableCell>
                                 <TableCell
                                   sx={{
                                     padding: "0.2rem 0.5rem",
                                     borderBottom: "none",
                                     width: '5rem',
                                     textAlign: "right",                                    
                                   }}
                                 >
                                   <CloseIcon
                                     sx={{ color: "red", cursor: "pointer" }}
                                     onClick={() => {
                                       API.delete(
                                         "/auth/assign_permission/",
                                         { ids: item.id },
                                         0
                                       ).then((res: any) => {
                                         setPermissionApiCallCount(
                                           (prev: any) => prev + 1
                                         );
                                       });
                                     }}
                                   />
                                 </TableCell>
                               </TableRow>
                             );
                           })}
                         </TableBody>
                       </Table> : <Box sx={{ height: {lg:'59vh',xl:"72vh"},display:'flex',alignItems:'center',justifyContent:'center',fontWeight:'500' }}>
                          No Data Found
                        </Box>}
                     </Box> 
                     ) : (
                      <Box
                        sx={{ maxHeight: "12rem" }}
                        adl-scrollbar="true"
                        adl-scrollbar-width="0.3"
                      >
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            columnGap: "1rem",
                            margin: "0.4rem 0",
                          }}
                        >
                          <Skeleton
                            variant="circular"
                            width="2rem"
                            height="2rem"
                            sx={{ margin: { lg: "0 2rem", xl: "0 3rem" } }}
                          />
                          <Skeleton
                            variant="rectangular"
                            width="100%"
                            sx={{ marginRight: "1rem" }}
                          />
                        </Box>
                      </Box>
                    )}
                    
          {/* <Accordion
            expanded={expanded === item?.module}
            onChange={handleSpecificChange(item?.module)}
          >
            <AccordionSummary
              aria-controls="panel1d-content"
              id={item?.module}
              sx={{
                minHeight: "36px",
                backgroundColor: "primary.light",
                color: "primary.main",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  marginLeft: "0.5rem",
                  justifyContent: "space-between",
                  width: "100%",
                }}
              >
                <Box>
                  
                  <Typography sx={{ fontSize: "1rem" }}>
                    {item?.module}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "1rem",
                  }}
                >
                  <Typography sx={{ fontSize: "1rem",display:'flex',alignItems:'center' }}>
                    User(External: <Typography sx={{ fontSize: "1rem",width:'1.6rem',textAlign:'right',marginRight:'0.5rem' }}>{item.external},</Typography>Internal: <Typography sx={{ fontSize: "1rem",width:'1.6rem',textAlign:'right' }}>{item?.internal}</Typography>)
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "1rem",
                      display: "flex",
                      alignItems: "center",
                      columnGap: "1rem",
                    }}
                  >
                    <AddIcon
                      sx={{ cursor: "pointer" }}
                      onClick={(event: any) => {
                        // event.stopPropagation();
                        addModal.open();
                      }}
                    />
                    <CloseIcon
                      sx={{ color: "red", cursor: "pointer" }}
                      onClick={(event: any) => {
                        event.stopPropagation();
                        swal({
                          title: "Are you sure?",
                          text: "All user permissions will be deleted from this module.",
                          // icon: "warning",
                          buttons: ["No", "Yes"],
                        }).then((isData: any) => {
                          if (isData) {
                        API.delete(
                                "/auth/assign_permission/",
                                { submodule: item?.module, project: projectId },
                                0
                              )
                                .then((res: any) => {
                                  setPermissionApiCallCount((prev: any) => prev + 1);
                                })
                                .catch((err: any) => {
                                  console.log(err);
                                });
                          }
                        }).catch((e: any) => {
                        });
                      }}
                    />
                  </Typography>
                </Box>
              </Box>
            </AccordionSummary>

            {!isPermissionListLoading ? (
              <AccordionDetails>
                <Box
                  sx={{ maxHeight: "20rem" }}
                  adl-scrollbar="true"
                  adl-scrollbar-width="0.3"
                >
                  <Table>
                    <TableBody>
                      {specificPermissionList?.map((item: any, index: number) => {
                        return (
                          <TableRow>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottom: "none",
                                width: "2vw",
                              }}
                            >
                              {index + 1}
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottom: "none",
                                width: "3vw",
                              }}
                            >
                              {item && item.image ? (
                                <Image
                                  alt="UserImg"
                                  placeholderImg={LoadingImage}
                                  src={item?.image}
                                  style={{
                                    width: "2rem",
                                    height: "2rem",
                                    borderRadius: "50%",
                                    objectFit: "cover",
                                  }}
                                />
                              ) : (
                                <Avatar
                                  src="/broken-image.jpg"
                                  sx={{
                                    width: { xs: "2rem", sm: "2rem" },
                                    height: { xs: "2rem", sm: "2rem" },
                                    fontSize: "1rem !important",
                                    objectFit: "cover",
                                    backgroundColor: "#007fff !important",
                                    textTransform: "uppercase",
                                    " .MuiAvatar-root.MuiAvatar-circular": {
                                      backgroundColor: "primary.main",
                                    },
                                  }}
                                >
                                  {AvatarName(item?.fullname)}
                                </Avatar>
                              )}
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottom: "none",
                                width: "15vw",
                                textAlign: "left",
                              }}
                            >
                              <Box
                                sx={{
                                  display: "flex",
                                  alignItems: "center",
                                  columnGap: "1rem",
                                }}
                              >
                                <Typography sx={{ fontSize: "1rem" }}>
                                  {item?.fullname}
                                </Typography>
                              </Box>
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottom: "none",
                                width: "25vw",
                              }}
                            >
                              {item?.submodule}
                            </TableCell>

                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottom: "none",
                                width: "12vw",
                              }}
                            >
                              <EditRoleProjectSpec
                                action={item?.actions || []}
                                itemId={item.id}
                                roleOptions={RoleListAll}
                                selectedModules={item?.submodule}
                                user={item?.user}
                              />
                            </TableCell>
                            <TableCell
                              sx={{
                                padding: "0.2rem 0.5rem",
                                borderBottom: "none",
                                width: { lg: "2vw", xl: "2vw" },
                                textAlign: "right",
                                paddingRight: "1rem",
                              }}
                            >
                              <CloseIcon
                                sx={{ color: "red", cursor: "pointer" }}
                                onClick={() => {
                                  API.delete(
                                    "/auth/assign_permission/",
                                    { ids: item.id },
                                    0
                                  ).then((res: any) => {
                                    setPermissionApiCallCount(
                                      (prev: any) => prev + 1
                                    );
                                  });
                                }}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                    </TableBody>
                  </Table>
                </Box>
              </AccordionDetails>
            ) : (
              <Box
                sx={{ maxHeight: "12rem" }}
                adl-scrollbar="true"
                adl-scrollbar-width="0.3"
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    columnGap: "1rem",
                    margin: "0.4rem 0",
                  }}
                >
                  <Skeleton
                    variant="circular"
                    width="2rem"
                    height="2rem"
                    sx={{ margin: { lg: "0 2rem", xl: "0 3rem" } }}
                  />
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    sx={{ marginRight: "1rem" }}
                  />
                </Box>
              </Box>
            )}
          </Accordion> */}
          </> );
      })}
    </Box>
  );
}
