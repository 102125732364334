import { API } from "./index.service";

import { isArray } from "lodash";
import { ID } from "../../utlis/commonInterfaces";
import { GetArchRepoImagesHeaderDataArg, GetRepoArchArg } from "../Actions/architecture.action";

// -------------------------------------------------------------------------------------

class architectureaction {
    get_dataImage ( arg: GetRepoArchArg ) {
     
        return API.get(`/circuit_diagram/archs/`, { project: arg.project, abbreviation: arg.abbreviation, categories: 3,   page: arg.page,group_id:arg?.group_id,col_page:arg?.col_page },0);
    };
    get_infoImage ( arg: GetArchRepoImagesHeaderDataArg ) {
        return API.get(`/circuit_diagram/arch_details_new_ui/`,{ top_vault: arg.topVault, abbreviation: arg.abbreviation, categories: 3, module: arg.module },0);
    };
    downloadbatch (topVaultId: ID | ID[], abbreviation: string | number, range: any, GrpId: any, category: any) {
        return API.get("circuit_diagram/download/", {
            top_vault: isArray(topVaultId) ? topVaultId.join() : topVaultId,
            categories: category,
            abbreviation: abbreviation || undefined,
            range: isArray(range) ? range.join() : range,
            group: GrpId
        }, 0);
    };
};
const architectureAction = new architectureaction();

export { architectureAction };