// vendors
import { createAction, createAsyncThunk } from "@reduxjs/toolkit";
import { ID } from "../../utlis/commonInterfaces";
import processCardService from "../Services/processCard.service";
import { ProcessCardCost } from "../Reducers/processCard.reducer";

// interfaces

// services
// import processCardService from "app/services/processCard.service";

// // redux
// import { ProcessCardCost } from "app/Redux/Reducers/processCard.reducer";

// -------------------------------------------------------------------------------------
// api actions
// -------------------------------------------------------------------------------------

export interface GetProcessCardCostsArg {
    costEstimationId: ID;
    tableId:any;
}
const getProcessCardCosts = createAsyncThunk(
    "processCard/get",
    async ( arg: GetProcessCardCostsArg, { rejectWithValue } ) => {
        try {
            const { data } = await processCardService.get( arg );

            return {
                costs: data as ProcessCardCost[]
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

// -------------------------------------------------------------------------------------

export interface CreateProcessCardCostsArg {
    costEstimationId: ID;
    commodityMappingId: ID;
    processes: ID[];
}
const createProcessCardCosts = createAsyncThunk(
    "processCard/create",
    async ( arg: CreateProcessCardCostsArg, { rejectWithValue } ) => {
        try {
            const { data } = await processCardService.create( arg );

            return {
                costs: data as ProcessCardCost[]
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

// -------------------------------------------------------------------------------------

export interface UpdateProcessCardCostArg {
    id: ID;
    updates: Partial<Pick<ProcessCardCost, "labour_cost" | "machine_cost" | "tool_cost" | "setup_cost" | "alias" | "sequence" | "quantity">>;
}
const updateProcessCardCost = createAsyncThunk(
    "processCard/update",
    async ( arg: UpdateProcessCardCostArg, { rejectWithValue } ) => {
        try {
            const { data } = await processCardService.update( arg );

            return {
                // TODO: merge with column name
                cost: data as ProcessCardCost
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

// -------------------------------------------------------------------------------------

export interface DeleteProcessCardCost {
    id: ID;
}
const deleteProcessCardCost = createAsyncThunk(
    "processCard/delete",
    async ( arg: DeleteProcessCardCost, { rejectWithValue } ) => {
        try {
            await processCardService.delete( arg );

            return {};
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);


export interface CopyProcessModelId {
    id: ID;
    data: boolean;
}

const copyProcessModel = createAsyncThunk(
    "cost/process-copy",
    async ( arg: CopyProcessModelId, { rejectWithValue } ) => {
        try {
            const { data } = await processCardService.processCopy( { id: arg.id, data: arg.data } );
            return {
                costs: data as ProcessCardCost[]
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);
// -------------------------------------------------------------------------------------
// reducer actions
// -------------------------------------------------------------------------------------

const resetProcessCard = createAction( "processCard/reset" );

// -------------------------------------------------------------------------------------
// exports
// -------------------------------------------------------------------------------------

export {
    getProcessCardCosts,
    createProcessCardCosts,
    updateProcessCardCost,
    deleteProcessCardCost,
    resetProcessCard,
    copyProcessModel

};