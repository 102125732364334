import * as React from 'react';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import { Checkbox, IconButton, Typography } from '@mui/material';
import { useHistory, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { API } from '../../api-services';
import AddIcon from '@mui/icons-material/Add';
import MailLogsTable from './MailLogsTable';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import styles from './MailLogs.module.scss';


type Anchor = 'top' | 'left' | 'bottom' | 'right';
export interface MailLogsProps {
}

export function MailLogs(props: MailLogsProps) {

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const { projectId, logtype, logsname } = useParams<any>();

  const [selectedId, setSelectedId] = useState<any>([parseInt(logtype)]);
  const [FileCount, setFileCount] = useState<any>();
  const [TableData, setTableData] = useState<any>([]);
  const [MailogsName, setMailLogsName] = useState<any>(logsname);
  const [loader, setLoader] = useState<any>(false);
  const history = useHistory<any>();
  const [projectDetails,setProjectDetails] = React.useState<any>();
  const toggleDrawer =
    (anchor: Anchor, open: boolean, type: any) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
          event.type === 'keydown' &&
          ((event as React.KeyboardEvent).key === 'Tab' ||
            (event as React.KeyboardEvent).key === 'Shift')
        ) {
          return;
        }

        setState({ ...state, [anchor]: open });

        if (type == "open") {
          history.push(`/Project-mail-logs/${projectId}/${selectedId.toString()}/${MailogsName}`)
          setState({ ...state, [anchor]: open });
        }



      };


  // console.log(selectedId, "selectedIdselectedId")
  const selectlogs = (Ids: any, name: any) => {
    setSelectedId([Ids])
    setMailLogsName(name)
    // console.log(Ids, "Idcdcdcs")
    // setProjectName(name);

    // setSelectedId([productIds]);
  };

  useEffect(() => {



    API.get("/activity/mail_log_view/", { mail_count: true, project: projectId }).then((res: any) => {
      setFileCount(res?.data)
    }).catch((err: any) => {
      console.log("Server Error")
    })
  }, [projectId])

  const getselectedBackgroundColor = (idd: any) => {
    if (selectedId?.includes(idd)) {
      return "primary.main";
    } else {
      return "primary.light";
    }
  };
  const getSelectedTextColor = (idd: any) => {
    if (selectedId?.includes(idd)) {
      return "primary.light";
    } else {
      return "primary.main";
    }
  };

  var mailLogsOption = [{ name: "Product Inward", id: 1, count: FileCount && FileCount?.product_outward },


  { name: "Project Plan", id: 2, count: FileCount && FileCount?.project_plan },
  { name: "Project Delivery", id: 3, count: FileCount && FileCount?.project_delivery },
  { name: "Product Outward", id: 4, count: FileCount && FileCount?.product_outward },
  { name: "MOM", id: 5, count: FileCount && FileCount?.MOM },
  { name: "Other", id: 6, count: FileCount && FileCount?.other }
  ]


  useEffect(() => {
    setLoader(true)
    setTableData([])
    API.get("/activity/mail_log_view/", { mail_type: logtype, project: projectId }).then((res: any) => {
      setTableData(res?.data)
      setLoader(false)
    }).catch((err: any) => {
      setLoader(false)

      console.log("Sever Error")
    })
  }, [logtype])

  const backButtonHandler: any = () => {
    let a: any = sessionStorage.getItem("arch");
    if (a == null) {
      history.push(`/internallanding`)
    }
    else {
      window.location.href = a;
    }
  }

  useEffect(()=>{

     API.get("/xcpep/project/", {
    
     id: projectId,
    
     },
    
     0).then((res: any) => {
    
     setProjectDetails(res?.data);
    
     });
    
     },[projectId])
  const list = (anchor: Anchor) => (
    <Box
      sx={{
        minWidth: 300,
        width: anchor === "top" || anchor === "bottom" ? "auto" : 300,
      }}
      role="presentation"
    >
      <Box sx={{ width: "100%", padding: "0.5rem 1rem" }}>        
        {mailLogsOption &&
          mailLogsOption?.map((item: any, index: any) => {
            return (
              <>
                <Box
                  sx={{ alignItems: "center", display: "flex", margin: '0.5rem 0' }}
                >
                  <Checkbox
                    style={{ padding: "6px" }}
                    checked={selectedId?.includes(item?.id) ? true : false}
                    onClick={() => selectlogs(item?.id, item?.name)}
                    inputProps={{ "aria-label": "controlled" }}
                    sx={{ "& .MuiSvgIcon-root": { fontSize: 24 } }}
                  />
                  <Box
                    onClick={() => selectlogs(item?.id, item?.name)}
                    key={index}
                    sx={{
                      padding: "0.5rem 0.5rem",
                      backgroundColor: getselectedBackgroundColor(item.id),
                      color: getSelectedTextColor(item.id),
                      textAlign: "left",
                      cursor: "pointer",
                      width: "100%",
                    }}
                  >
                    <Typography style={{ fontSize: "1rem", lineHeight: '1.2' }}>
                      {item?.name}({item?.count})
                    </Typography>
                  </Box>
                </Box>
              </>
            );
          })}

        {/* {selectedId?.length !== 1 && (
          <p
            style={{
              fontSize: "12px",
              color: "#d32f2f",
              marginTop: "0.4rem",
              marginLeft: "1.45rem",
            }}
          >
            {"*Please select  at least one project..."}
          </p>
        )} */}
      </Box>
      <Box style={{ textAlign: "right", marginTop: "1rem", padding: '0 1rem' }}>
        <Button
          className={styles.drawBtn}
          sx={{ marginRight: "1rem" }}
          variant="contained"
          size="small"
          onClick={toggleDrawer(anchor, false, "close")}
        >
          Close
        </Button>
        <Button
          className={styles.drawBtn}
          variant="contained"
          onClick={toggleDrawer(anchor, false, "open")}
          size="small"
        >
          Ok
        </Button>

      </Box>
    </Box>
  );
  return (
    <>
      <Box sx={{display:'flex',alignItems:'center',columnGap:'1rem'}}>
        <Box sx={{ width: '3rem', padding: '0 0.5rem', marginTop: '0.5rem' }}>
          <IconButton sx={{ cursor: 'pointer' }} onClick={backButtonHandler} >
            <ArrowBackIcon color="primary" titleAccess="Go Back" />
          </IconButton>
        </Box>
        <Box sx={{marginTop: '0.5rem'}}>
          <Typography sx={{fontSize:"1rem"}} >
          Email Logs
          
          <span style={{font:'1rem',margin:"0 1rem"}}>[{`${projectDetails && projectDetails?.[0]?.name}`}  ({logsname})]</span> 
         
          
          
          
          </Typography>
        </Box>
      </Box>
      <div style={{ display: "flex", width: '100%' }}>
        <Box className={styles.expandIconBox}>
          <div className={styles.iconSec}>
            {(['left'] as const).map((anchor) => (
              <React.Fragment key={anchor}>
                <IconButton size="medium" sx={{ backgroundColor: 'primary.light', color: 'primary.main' }} onClick={toggleDrawer(anchor, true, "")}><AddIcon sx={{ fontSize: '1.7rem' }} /></IconButton>
                <Drawer
                  anchor={anchor}
                  open={state[anchor]}
                  onClose={toggleDrawer(anchor, false, "")}
                >
                  <Box sx={{ padding: '0.5rem 0', position: 'sticky', top: '0' }}
                    adl-scrollbar="true"
                    adl-scrollbar-width="0.3">
                    <Typography style={{ width: '100%', textAlign: 'center', fontSize: '1rem' }}>Select Module</Typography>
                    <Divider sx={{ margin: '0.2rem' }} />
                    {list(anchor)}
                  </Box>
                </Drawer>
                <Typography onClick={toggleDrawer("left", true, "no")} className={styles.sideTitle}>Module Selection</Typography>
              </React.Fragment>
            ))}
          </div>
        </Box>
        <Box sx={{ width: '97vw' }}>
          {TableData && <MailLogsTable tableData={TableData && TableData} loader={loader} />}
        </Box>
      </div>
    </>
  );
}
