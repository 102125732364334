import * as React from 'react';




import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Chip, CircularProgress } from '@mui/material';
import SendIcon from "@mui/icons-material/Send";
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Fab from '@mui/material/Fab';
import CheckIcon from '@mui/icons-material/Check';
import SaveIcon from '@mui/icons-material/Save';
import { useHistory, useRouteMatch } from 'react-router-dom';
import successIcon from "../../Assets/images/success.png";
import CheckCircleIcon from '@mui/icons-material/CheckCircle';




const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    borderRadius: " 8px",
    height: '17rem',
    width: "39rem",
    p: 1,
};



export interface PreviewMailModalProps {

    onCloseModal: any;
    isOpen: any;
    SendEmail: any;
    parentsClosed: any;
    Project: any;
    ProjectName: any
    emailType?: any
    ProductId?: any

}



export function MailSucessModal(props: PreviewMailModalProps) {

    const { onCloseModal, isOpen, SendEmail,


        parentsClosed, Project, ProjectName, emailType, ProductId

    } = props
    const {url}=useRouteMatch();

    const history = useHistory<any>()
    const ClosedHandler = () => {
        if (emailType == "Other") {
            parentsClosed()
            onCloseModal()

            SendEmail == false && window.open(`/#/mail-module/Other/${ProjectName}/${Project}/0/${false}/0/0`, "_self", " noopener")
        }
        else if (emailType == "2") {
            parentsClosed()
            onCloseModal()
            SendEmail == false && history.push(`/GanttChart/${Project}`)
        }
        else if (emailType == "5") {
            parentsClosed()
            onCloseModal()
            SendEmail == false && window.open(`/#/dispatchemail/${Project}/${ProjectName}/${false}/0`,"_self", " noopener")
        }
        else {
            parentsClosed()
            onCloseModal()

            SendEmail == false && window.open(`/#/mail-module/Delivery/${ProjectName}/${Project}/0/${false}/0/0`, "_self", " noopener")
        }

        if (emailType == "inward") {

            parentsClosed()
            onCloseModal()

            SendEmail == false && window.open(`/#/inward/${ProjectName}/${Project}/${ProductId}`, "_self", " noopener")

        }

    }


    return (
        <div>

            <Modal
                open={isOpen}
                // onClose={onCloseModal}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <div style={{ width: "100%" }}>
                        <div style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            gap: "1rem"
                        }}>
                            {/* {SendEmail == false &&

                                <HighlightOffIcon

                                    onClick={() => ClosedHandler()}
                                    sx={{ cursor: "pointer" }}

                                />
                            } */}
                        </div>
                        <div>
                            <Box sx={{ display: 'flex', alignItems: 'center', padding: '1rem', }}>
                                {SendEmail ?
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', columnGap: '1rem', width: '100%', justifyContent: 'center', marginTop: '2rem' }}>
                                        <CircularProgress size="5rem" />
                                        <Typography sx={{marginTop:'1rem'}}>Sending...</Typography>
                                    </Box>  
                                    : <>
                                        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', columnGap: '1rem', width: '100%', justifyContent: 'center' }}>

                                            <img src={successIcon} style={{ width: '7rem', height: 'auto' }} />
                                            {/* <CheckCircleIcon color='success' sx={{ fontSize: '10rem', }} /> */}
                                            <Typography color="success">Thank you!</Typography>
                                            <Typography color="success">Your mail has been sent.</Typography>
                                            <Button variant='contained' sx={{
                                                margin: '1rem',
                                                padding: '0.2rem 2rem',
                                                "&:hover": {
                                                    transform: 'Scale(1.05)',
                                                    transition: 'transform 0.5s ease',
                                                }
                                            }} onClick={() => ClosedHandler()}>ok </Button>
                                        </Box>

                                    </>}
                            </Box>







                        </div>
                    </div>



                </Box>


            </Modal>
        </div>
    );
}
