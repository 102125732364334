// vendors
import { createEntityAdapter, createReducer, EntityState, createSelector, Update } from "@reduxjs/toolkit";
import { isUndefined, mapKeys, max, pickBy, set } from "lodash";
import { ID } from "../../utlis/commonInterfaces";
import { getRepoImages, resetImageRepository } from "../Actions/imageRepository.actions";
import { ADMIN } from "../Services/admin.service";
import { RootState } from "../Store/storeConfigurations";
import { getArchRepoImages } from "../Actions/archRepository.action";


// -----------------------------------------------------------------------------------
// helpers

const rearrangeImages = ( state: IImageRepositoryReducer, sequenceObject: Record<string, number> ) => {
    const updates: Update<RepoImage>[] = [];
    for ( let [id, sequence] of Object.entries( sequenceObject ) ) {
        updates.push( { id: +id, changes: { sequence } } );
    }
    adaptor.updateMany( state, updates );
};

// -----------------------------------------------------------------------------------
// entity adaptor

export interface RepoImage {
    id: ID;
    sequence: any;
    abbreviation?: any;
    image_category: number;
    image_name?: string;
    readonly thumbnail: string;
    readonly original: string;
    media_type: any;
    vault: ID | null;
    top_vault: any;
    group: any;
    image_url_cdn?: string;
    circuit_type?: number;
    new_sequence?: any;
    validation?:any;
    is_mute?:any
}

const adaptor = createEntityAdapter<RepoImage>( {
    selectId: ( repoImage ) => repoImage.id,
    sortComparer: ( a, b ) => a.sequence - b.sequence
} );

// -----------------------------------------------------------------------------------
// interfaces & initialState

type AdditionalFields = {};

type IImageRepositoryReducer = EntityState<RepoImage> & AdditionalFields;

const additionalFields: AdditionalFields = {};

const initialState: IImageRepositoryReducer = Object.assign( {}, adaptor.getInitialState(), additionalFields );

// -----------------------------------------------------------------------------------
// reducer

const ImageRepositoryReducer = createReducer( initialState, builder => {
    // get
    builder
        .addCase( getRepoImages.fulfilled, ( state, action: any ) => {

            adaptor.setMany( state, action.payload.images );
        } )
        .addCase( getRepoImages.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could not fetch media" );
        } );
    // get
    builder
        .addCase( getArchRepoImages.fulfilled, ( state, action: any ) => {
            adaptor.setMany( state, action.payload.images );
        } )
        .addCase( getArchRepoImages.rejected, ( _state, action ) => {
            // ADMIN.toast.throwError( action.payload, "Could not fetch media" );
        } );

    // reset
    builder.addCase( resetImageRepository, () => initialState );
} );

// -----------------------------------------------------------------------------------
// selectors

const selectors = adaptor.getSelectors<RootState>( ( state ) => state.imageRepository );
const selectImagesBySequence = createSelector(
    selectors.selectAll,
    ( _state: RootState, sequence: number ) => sequence,
    ( _state: RootState, _sequence: number, group: any ) => group, 
    ( _state: RootState, _sequence: number, _group: any,imageCategory:any ) => imageCategory, 
    ( repoImages, sequence, group,imageCategory ) => {
        return repoImages
            .filter( repoImage => repoImage.sequence === sequence && repoImage.group == group  && repoImage.image_category == imageCategory);
    }
);

const selectArchImagesBySequence = createSelector(
    selectors.selectAll,
    ( _state: RootState, sequence: number ) => sequence,
    ( _state: RootState, _sequence: number, group: any ) => group,
    ( repoImages, sequence, group ) => {
        return repoImages
            .filter( repoImage => repoImage.sequence === sequence && repoImage.group == group && repoImage.image_category == null );
    }
);

const selectByTopVaultId = createSelector(
    selectors.selectAll,
    ( _state: RootState, topVaultId: ID ) => topVaultId,
    ( repoImages, topVaultId ) => {
        return repoImages.filter( repoImage => repoImage.top_vault === topVaultId );
    }
);
const selectAllImages = createSelector(
    selectors.selectAll,
    ( _state: RootState, topVaultId: any ) => topVaultId,
    ( repoImages, topVaultId ) => {
        return repoImages;
    }
);

const selectIdsByTopVaultId = createSelector(
    selectByTopVaultId,
    ( repoImages ) => repoImages.map( adaptor.selectId )
);

const selectMaxCount = createSelector(
    selectors.selectAll,
    ( repoImages ) => {

        const countArray = repoImages.map( repoImage => repoImage.sequence ),
            maxCount = max( countArray );
        /** 1 is added as sequence starts from 0 */

        return isUndefined( maxCount ) ? 0 : maxCount + 1;
    }
);


const selectCurrentCount = createSelector(
    selectors.selectAll,
    ( _state: RootState, size: number ) => size,
    ( _state: RootState, size: number, pageNumber: number ) => pageNumber,
    ( repoImages, pageNumber, size ) => {
        const countArray = repoImages.map( repoImage => repoImage.sequence - ( ( pageNumber - 1 ) * size ) ),
            maxCount = max( countArray );
        /** 1 is added as sequence starts from 0 */

        return isUndefined( maxCount ) ? 0 : maxCount + 1;
    }
);

const selectBySequence = createSelector(
    selectors.selectAll,
    ( _state: RootState, topVaultId: ID ) => topVaultId,
    ( _state: RootState, _topVaultId: ID, sequence: number ) => sequence,
    ( _state: RootState, __topVaultId: ID, _sequence: number, group: any ) => group,
    ( _state: RootState, __topVaultId: ID, _sequence: number, _group: any,imageCategory:any ) => imageCategory, 
    ( repoImages, topVaultId, sequence, group,imageCategory ) => {
        return repoImages.find( repoImage => {
            return repoImage.top_vault === topVaultId && repoImage.sequence === sequence && repoImage.group === group && repoImage.image_category === imageCategory
        } );
    }
);

const selectByArchSequence = createSelector(
    selectors.selectAll,
    ( _state: RootState, topVaultId: ID ) => topVaultId,
    ( _state: RootState, _topVaultId: ID, sequence: number ) => sequence,
    ( _state: RootState, __topVaultId: ID, _sequence: number, group: any ) => group,
    ( repoImages, topVaultId, sequence, group ) => {
        return repoImages.find( repoImage => {
            return repoImage.top_vault === topVaultId && repoImage.sequence === sequence && repoImage.group === group && repoImage.image_category == null
        } );
    }
);


const imageRepositorySelectors = Object.assign( {}, {
    selectImagesBySequence,
    selectMaxCount,
    selectCurrentCount,
    selectByTopVaultId,
    selectIdsByTopVaultId,
    selectBySequence,
    selectAllImages,
    selectArchImagesBySequence,
    selectByArchSequence
}, selectors );

// -----------------------------------------------------------------------------------
// exports

export {
    ImageRepositoryReducer,
    initialState as ImageRepositoryReducerInit,
    imageRepositorySelectors,
    selectAllImages
};
