
// -------------------------------------------------------------------------------------

import { useDispatch } from "react-redux";
import { API } from "../../api-services";
import { setBomAllTableData } from "../Actions/compareopen.action";

const url = "/xcpep/bom_create_view/";
// export interface GetAssumptionCardByIdArg {
//     id: ID;
//     /** indicates whether the location & revision keys has to be populated by instances or not */
//     populate?: boolean;
// }
// -------------------------------------------------------------------------------------
export default {
    
    getAllTableDataService ( arg: any,dispatch:any ) {
        
         API.get( url, arg ).then((res:any)=>{
              dispatch(setBomAllTableData(res.data))
         }).catch((err:any)=>{})
    },
 


  
};
