import * as React from "react";
import {
  Badge,
  Box,
  Dialog,
  DialogContent,
  Divider,
  IconButton,
  Pagination,
  Skeleton,
  Slide,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { API } from "../../api-services";
import RViewerJS from "viewerjs-react";

import styles from "./ArchitectureCreateImageSection.module.scss";
import ArchitectureImageCard from "./ArchitectureImageCard";
import FullscreenIcon from "@mui/icons-material/Fullscreen";
import { useRef, useState } from "react";

import {
  clearArchRepoImages,
  getArchImages,
} from "../../Redux/Actions/architecture.action";
import { useSelector } from "react-redux";
import { ArchRepositorySelectors } from "../../Redux/Reducers/architecture.reducer";
import { resetImageRepository } from "../../Redux/Actions/imageRepository.actions";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import ArchitectureImageUplaodModal from "./ArchitectureImageUplaodModal";
import { useBaseModal } from "../SearchPage/useBaseModal";
import UploadIcon from "@mui/icons-material/Upload";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import { useHistory } from "react-router-dom";
import { debounce, set } from "lodash";

import { useQueryDispatch } from "../../CustomHook/useQueryDispatch";
import { useAppDispatch } from "../../../AppRouter";

import ArchProductlevelDeleteModal from "./ArchProductlevelDeleteModal";
import ArchGroupDownloadConsolidated from "./ArchGroupDownloadConsolidated";
import { TransitionProps } from "@mui/material/transitions";
import CancelIcon from "@mui/icons-material/Cancel";
import ArchitectureDownloadSystemTree from "./ArchitectureDownloadSystemTree";

import ArchInfoModal from "./ArchInfoModal";
import Swal from "sweetalert2";
import successIcon from "../../Assets/images/success.png";
import { error } from "console";
import { UserPermissionData } from "./ArchitectureCreateLanding";




const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});
export interface IArchitectureCreateImageSectionProps {
  selectedType: any;
  setselectedType: any;
  setRecycleBinCounter: any;
  recycleBinCounter: any;
  setSequenceCount: any;
  SequenceCount: any;
}

export function ArchitectureCreateImageSection(
  props: IArchitectureCreateImageSectionProps
) {
  const {
    projectId,
    projectname,
    abbr,
    topvoult,
    subSystem,
    groupId,
    collapestatus,
    pageNumber,
    productpage,
    groupName,
    diagramsCount
  } = useRouteParams<any>();

  const [loader, setLoader] = React.useState<any>(false);
  const viewerRef = React.useRef<any>(null);
  const {
    selectedType,
    setselectedType,
    setRecycleBinCounter,
    recycleBinCounter,
    setSequenceCount,
    SequenceCount,
  } = props;
  const [isViewerOpen, setIsViewerOpen] = useState(false);
  const [Counter, setCounter] = useState<any>(0);
  const [openMove, setOpenMove] = React.useState(false);
  const [UploadData, setUploadData] = useState<any>();
  const [CounterPage, setCounterPage] = useState<any>(pageNumber);
  const [ProductCounter, setProductCounter] = useState<any>(
    parseInt(productpage)
  );
  const imageViewer = useRef<any>();
  const [imageurl, setImageUrl] = React.useState<any>([]);
  const ImageModal = useBaseModal();
  const deleteModal = useBaseModal();
  const Datalist: any = useSelector(ArchRepositorySelectors.selectAll)[0];
  const [ProductPageCount, setProductPageCount] = useState<any>({});
  const [openMediaConsolidatedDownload, setOpenMediaConsolidatedDownload] =
    React.useState(false);
  const [groupContextTopVault, setGroupContextTopVault] = React.useState([]);
  const [groupContextGroupName, setGroupContextGroupName] = React.useState();
  const [openMediaDownload, setOpenMediaDownload] = React.useState(false);
  const [openSystemMediaDownload, setOpenSystemMediaDownload] =
    React.useState(false);
  const dispatch = useAppDispatch();
  const [Grouploader, setGroupLoader] = useState<any>(false);
  const [GroupName, setGroupName] = useState<any>(groupName);
  const [downloadProductInfo, setDownloadProductInfo] = React.useState<any>([]);
  const [recyclebinCount, setRecyclebinCount] = useState<any>();

  const history = useHistory<any>();
  const [AllImageList, setAllImageList] = useState<any>([])
  const UserPermissionValue = React.useContext<any>(UserPermissionData)
  // const GroupNameHandler = (e: any) => {
  //   if(e.target.value.length>0){
  //     ExactDebounce(e.target.value,projectId,abbr,groupId,pageNumber,productpage)
  //   }

  //   setGroupName(e.target.value);
  // };

  React.useEffect(() => {
    setGroupName(groupName);
  }, [groupId, pageNumber]);

  const GroupNameSaveHandler = () => {
    setGroupLoader(true);
    API.put(
      `/circuit_diagram/arch_group/${groupId}/`,

      { name: GroupName, project: projectId, abbreviation: abbr }
    )
      .then((res: any) => {
        dispatch(
          getArchImages({
            project: projectId,

            abbreviation: abbr,
            col_page: productpage,
            page: pageNumber,
            group_id: groupId,
          })
        );
        setselectedType((prev: any) => prev + 1);
        history.push(
          `/architecturecreateinternal/${projectId}/${projectname}/${abbr}/${topvoult}/${subSystem}/${groupId}/${collapestatus}/${pageNumber}/${productpage}/${GroupName}/${diagramsCount}`
        );
        setGroupLoader(false);
      })
      .catch((err: any) => {
        setGroupLoader(false);
      });
  };
  const ExactDebounce = React.useRef(
    debounce(async (criteria: any, projectId: any, abbreviation: any, groupId: any, pageNumber: any, productpage: any) => {
      await API.put(`/circuit_diagram/arch_group/${groupId}/`, { name: criteria, project: projectId, abbreviation: abbreviation }).then((res: any) => {
        setSequenceCount((prev: any) => prev + 1);
        history.push(
          `/architecturecreateinternal/${projectId}/${projectname}/${abbr}/${topvoult}/${subSystem}/${groupId}/${collapestatus}/${pageNumber}/${productpage}/${criteria}`
        );
        dispatch(
          getArchImages({
            project: projectId,

            abbreviation: abbreviation,

            page: pageNumber,
            col_page: productpage,
            group_id: groupId,
          })
        );

      }).catch((err: any) => {
        const { data } = err?.response;
        Swal.fire({

          icon: "error",
          html: `<div>
          <br />
          <p style="color:"red">${data[0]}</p>   
           </div>`,
        })
      })
    }, 1000)
  ).current;




  React.useEffect(() => {
    setCounterPage(pageNumber);

    setProductCounter(parseInt(productpage));
  }, [groupId, pageNumber]);


  const fetchedArchData = useQueryDispatch({
    query: {
      action: getArchImages,
      arg: {
        project: projectId,

        abbreviation: abbr,
        col_page: productpage,
        page: pageNumber,
        group_id: groupId,
      },
      condition: groupId !== 0,
    },
    cleanup: { action: clearArchRepoImages, args: [groupId, abbr, pageNumber] },
    dependency: [groupId, abbr, pageNumber, productpage, selectedType],
  });

  React.useEffect(() => {
    if (groupId != 0) {
      fetchedArchData.match("TRUE") ? setLoader(true) : setLoader(false);
    }

  }, [fetchedArchData, groupId, abbr]);



  const ImageModalhandler = (item: any) => {
    setUploadData(item);
    ImageModal.open();
  };
  const RowPaginationHandler = (type?: any) => {
    if (type == "previous") {
      history.push(
        `/architecturecreateinternal/${projectId}/${projectname}/${abbr}/${topvoult}/${subSystem}/${groupId}/${collapestatus}/${pageNumber}/${ProductCounter - 1
        }/${groupName}/${diagramsCount}`
      );
      setProductCounter((prev: any) => prev - 1);
    } else {
      history.push(
        `/architecturecreateinternal/${projectId}/${projectname}/${abbr}/${topvoult}/${subSystem}/${groupId}/${collapestatus}/${pageNumber}/${ProductCounter + 1
        }/${groupName}/${diagramsCount}`
      );
      setProductCounter((prev: any) => prev + 1);
    }
  };

  React.useEffect(() => {
    API.get(`/circuit_diagram/archs/`, {
      project: projectId,

      abbreviation: abbr,
      categories: 3,
      group_id: groupId,
      page_count: true,
    }).then((res: any) => {
      setProductPageCount(res?.data);
    });
  }, [projectId, groupId, SequenceCount]);

  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    setCounterPage(value);
    history.push(
      `/architecturecreateinternal/${projectId}/${projectname}/${abbr}/${topvoult}/${subSystem}/${groupId}/${collapestatus}/${value}/${productpage}/${groupName}/${diagramsCount}`
    );
  };
  const ProductDeleteHandler = () => {
    deleteModal.open();
  };

  const ConsolidatedReportHandler = () => {
    {
      API.get(
        "/circuit_diagram/arch_download/",
        {
          project: projectId,
          group_id: groupId,
          categories: 3,
          abbreviation: abbr,
          download_image_count: true,
        },
        0
      )
        .then((res: any) => {
          setDownloadProductInfo(res?.data);
          setOpenMediaConsolidatedDownload(true);
        })
        .catch((err: any) => {
          console.log(err, "error console");
        });
      API.get(`/circuit_diagram/arch_groups/`, {
        project: projectId,
        abbreviation: abbr,
      }).then((res: any) => {
        var filterdata = res?.data?.filter(
          (item: any) => item?.id == groupId
        )[0];

        setGroupContextTopVault(filterdata?.top_vault_list);
      });
    }
  };

  const ArchDownloadHandler = () => {
    {
      API.get(
        "/circuit_diagram/arch_download/",
        {
          project: projectId,
          group_id: groupId,
          categories: 3,
          abbreviation: abbr,
          download_image_count: true,
        },
        0
      )
        .then((res: any) => {
          setDownloadProductInfo(res?.data);
          setOpenMediaDownload(true);
        })
        .catch((err: any) => { });
    }
  };

  const handleCloseMediaConsolidatedDownload = () => {
    setOpenMediaConsolidatedDownload(false);
  };
  const handleCloseMove = () => {
    setOpenMove(false);
  };
  const handleCloseMediaDownload = () => {
    setOpenMediaDownload(false);
  };

  const imageHandler = () => {

    // imageViewer.current.value=url

    setIsViewerOpen(true);
    // console.log("isViewerOpenisViewerOpenisViewerOpen",url)
    return imageViewer.current?.click?.();
  };
  const ImageDataHandler = (item: any) => {


    imageHandler()

    API.get("/circuit_diagram/architecture_Tag_View/", {
      abbreviation: abbr,

      project: projectId,

      group_id: groupId,

      sequence: item?.sequence,

      categories: 3,
    }).then((res: any) => {
      const position = res?.data?.map((e: any) => e?.top_vault).indexOf(item?.top_vault);

      let topVaultIdGet: any = [];
      let a: any = topVaultIdGet?.push(
        res?.data.slice(
          position + 1,
          res?.data.length
        )
      )[0];



      let b: any = topVaultIdGet?.push(
        res?.data.slice(0, position + 1)
      )[0];

      let topVaultIds: any = topVaultIdGet?.flat();
      setAllImageList(topVaultIds);

    });

  }

  const RecycleBinHanlder = () => {
    history.push("/architecturecreate/recycleBinRrchitecturecreate");
  };



  React.useEffect(() => {
    setLoader(false);
    API.get("/circuit_diagram/arch_recyclebin/", { bin_count: true })
      .then((res: any) => {
        setRecyclebinCount(res?.data);
      })
      .catch((err: any) => { });
  }, [projectId, recycleBinCounter]);

  const SwalAlertHandler = React.useCallback((type: any, group: any) => {
    if (type == true) {
      API.get(`/circuit_diagram/arch_groups/`, {
        project: projectId,
        abbreviation: abbr,
      }).then((res: any) => {
        var FilterData = res?.data?.filter((item: any, index: any) => { return item?.id == group })[0]

        console.log(groupId, "Cscsciucgiuc", FilterData)
        history.push(
          `/architecturecreateinternal/${projectId}/${projectname}/${abbr}/${topvoult}/${subSystem}/${group}/${collapestatus}/${pageNumber}/${productpage}/${groupName}/${FilterData?.image_count}`
        );
      })



      Swal.fire({
        html: `<div><img src="${successIcon}" alt="Custom Image" style="width: 7rem; height: auto;" />
  <br />
  <p style="color:#007fff;"> Diagrams added successfully
  </p>   
   </div>`,
      });
    } else if (type == false) {
      Swal.fire({
        icon: "error",
        html: `<div>
  <br />
  <p style="color:red;">Server Error
  </p>   
   </div>`,
      });
    }
  }, []);


  return (
    <div>

      {ImageModal.isOpen && (
        <ArchitectureImageUplaodModal
          UploadData={UploadData}

          setLoader={setLoader}
          setSequenceCount={setSequenceCount}
          isOpen={ImageModal.isOpen}
          SwalAlertHandler={SwalAlertHandler}
          handleClose={ImageModal.close}
        />
      )}


      <Box
        sx={{
          display: "flex",
          alignItems: "flex-start",
          width: "100%",
        }}>
        <IconButton
          size="small"
          sx={{ cursor: "pointer",marginTop:'0.5rem' }}
          disabled={ProductCounter > 1 ? false : true}>
          <ChevronLeftIcon
            onClick={() => RowPaginationHandler("previous")}
            titleAccess="Previous"
            sx={{ cursor: "pointer", }}
          />
        </IconButton>
        {/* {ProductCounter} */}
        <Box
          sx={{
            display: "flex",
            alignItems: "flex-start",
            width: "100%",
            position: "relative",
          }}>

          {groupId == 0 &&

            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                marginTop: "23rem"
              }}
            >
              <Typography> Select Group </Typography>
            </Box>}

          {groupId !== 0 && !loader ? (
            <Box sx={{ width: '100%', padding: '1rem' }}>
              <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '1rem', width: '100%', marginBottom: '1rem', }}>
                <Skeleton
                  variant="rectangular"
                  // width={281}
                  height={137}
                  sx={{ width: '100%', }}
                />

                <Skeleton
                  variant="rectangular"
                  // width={281}
                  height={137}
                  sx={{ width: '100%', }}
                />
                <Skeleton
                  variant="rectangular"
                  // width={281}
                  height={137}
                  sx={{ width: '100%', }}
                />
                <Skeleton
                  variant="rectangular"
                  // width={281}
                  height={137}
                  sx={{ width: '100%', }}
                />

              </Box>
              <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '1rem', width: '100%', marginBottom: '1rem', }}>
                <Skeleton
                  variant="rectangular"
                  // width={281}
                  height={137}
                  sx={{ width: '100%', }}
                />

                <Skeleton
                  variant="rectangular"
                  // width={281}
                  height={137}
                  sx={{ width: '100%', }}
                />
                <Skeleton
                  variant="rectangular"
                  // width={281}
                  height={137}
                  sx={{ width: '100%', }}
                />
                <Skeleton
                  variant="rectangular"
                  // width={281}
                  height={137}
                  sx={{ width: '100%', }}
                />

              </Box>
              <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '1rem', width: '100%', marginBottom: '1rem', }}>
                <Skeleton
                  variant="rectangular"
                  // width={281}
                  height={137}
                  sx={{ width: '100%', }}
                />

                <Skeleton
                  variant="rectangular"
                  // width={281}
                  height={137}
                  sx={{ width: '100%', }}
                />
                <Skeleton
                  variant="rectangular"
                  // width={281}
                  height={137}
                  sx={{ width: '100%', }}
                />
                <Skeleton
                  variant="rectangular"
                  // width={281}
                  height={137}
                  sx={{ width: '100%', }}
                />

              </Box>
              <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '1rem', width: '100%', marginBottom: '1rem', }}>
                <Skeleton
                  variant="rectangular"
                  // width={281}
                  height={137}
                  sx={{ width: '100%', }}
                />

                <Skeleton
                  variant="rectangular"
                  // width={281}
                  height={137}
                  sx={{ width: '100%', }}
                />
                <Skeleton
                  variant="rectangular"
                  // width={281}
                  height={137}
                  sx={{ width: '100%', }}
                />
                <Skeleton
                  variant="rectangular"
                  // width={281}
                  height={137}
                  sx={{ width: '100%', }}
                />

              </Box>
              <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gap: '1rem', width: '100%', marginBottom: '1rem', }}>
                <Skeleton
                  variant="rectangular"
                  // width={281}
                  height={137}
                  sx={{ width: '100%', }}
                />

                <Skeleton
                  variant="rectangular"
                  // width={281}
                  height={137}
                  sx={{ width: '100%', }}
                />
                <Skeleton
                  variant="rectangular"
                  // width={281}
                  height={137}
                  sx={{ width: '100%', }}
                />
                <Skeleton
                  variant="rectangular"
                  // width={281}
                  height={137}
                  sx={{ width: '100%', }}
                />

              </Box>
            </Box>
          ) : (
            <>
              <div style={{ display: "none" }}>
                {/* @ts-ignore */}
                <RViewerJS>

                  {/* <img src={imageViewer?.current?.value} alt="image" ref={imageViewer} /> */}
                  {AllImageList &&
                    AllImageList?.map((item: any, index: any) => {
                      return (
                        <>
                          <img
                            src={item?.image_url_cdn}
                            alt={item?.name}
                            ref={imageViewer}
                          />
                        </>

                      );
                    })}
                </RViewerJS>
              </div>
              {groupId !== 0 && <>
                {Datalist &&
                  /* @ts-ignore */
                  Datalist?.map((item: any, inde: any) => {
                    var filterPermission = UserPermissionValue && UserPermissionValue?.filter((permissionItem: any, index1: any) => { return (permissionItem?.action !== null && permissionItem?.top_vault !== "all") && permissionItem?.top_vault == item?.top_vault })[0]?.action
                    return (

                      <>
                        <div style={{ width: '100%' }}>


                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', columnGap: '1rem', border: '1px solid', borderRadius: '3rem', margin: '0.5rem 0.5rem', padding: '0.2rem 1rem' }}>
                            {item?.product_name}

                            {
                              filterPermission && filterPermission?.includes("C") &&
                              <UploadIcon
                                titleAccess="Upload Diagrams"
                                onClick={() => ImageModalhandler(item)}
                                sx={{ cursor: "pointer" }}
                              />
                            }


                          </div>
                          <div>
                            {/* @ts-ignore */}

                            {item?.images
                              ?.slice()
                              ?.sort((a: any, b: any) => a.sequence - b.sequence)
                              .map((imageitem: any, index: any) => {
                                return (
                                  <div key={index} style={{ marginBottom: '1rem' }}>
                                    <ArchitectureImageCard

                                      userPermissionTopvaluewise={UserPermissionValue}
                                      ImageDataHandler={ImageDataHandler}
                                      setRecycleBinCounter={setRecycleBinCounter}
                                      setSelectedType={setselectedType}
                                      setSequenceCount={setSequenceCount}
                                      SequenceCount={SequenceCount}
                                      indexCount={index + 1}
                                      imageListdata={imageitem}
                                      setCounter={setCounter}
                                      cardType={"fill"}
                                    />
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </>
                    );
                  })}
              </>}
            </>
          )}
        </Box>
        <IconButton
          size="small"
          sx={{ cursor: "pointer",marginTop:'0.5rem' }}
          disabled={
            ProductCounter < ProductPageCount?.product_page_count
              ? false
              : true
          }>
          <ChevronRightIcon
            onClick={() => RowPaginationHandler()}
            titleAccess="Next"
            sx={{ cursor: "pointer", }}
          />
        </IconButton>
      </Box>
      <Box
        sx={{
          position: "fixed",
          bottom: { lg: '0.5rem', xl: "1rem" },
          backgroundColor: "#fff",
          right: " 1.2rem",
        }}>
        <Pagination
          count={ProductPageCount && ProductPageCount?.page_count}
          page={CounterPage}
          onChange={handleChange}
        />
      </Box>
    </div>
  );
}
