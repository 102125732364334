import React from 'react';
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import warning from "../Assets/images/Warning.png"
import success from "../Assets/images/success.png"
import reportProgress from "../Assets/images/reportprogress.png"
import reportDownloading from "../Assets/images/ReportDownload.png"
import reportFailed from "../Assets/images/reportFailed.png"

export interface IDialogComponentProps {
  isOpen: any,
  onClose: any,
  content?: any
  type?: any,

}

export function DialogComponent(props: IDialogComponentProps) {
  const { isOpen, onClose, content, type } = props;
  const getSrc = () => {
    switch (type) {
      case "warning":
        return warning
      case "success":
        return success
      case "reportProgress":
        return reportProgress
      case "firstdownload":
        return reportDownloading
        case "failed":
        return reportFailed
    }
  }
  return (
    <>
      <Dialog open={isOpen} onClose={onClose}>
        <DialogTitle
          style={{
            color: "red",
            display: "flex",
            justifyContent: "center",
            margin: "0rem",
            alignItems: "center",
            padding: "10px 20px 0",
          }}>
          {type && <img src={getSrc()} style={{width : '12rem', height: 'auto' }} />}
          {/* <WarningAmberRoundedIcon sx={{ fontSize: "4rem" }} /> */}
          {/* {` Alert`} */}
        </DialogTitle>
        <DialogContent sx={{ padding: "0 20px 10px", margin: "0 5rem" }}>
          <Typography
            sx={{
              color: "primary.main",
              fontSize: "1rem",
              textAlign: "center",
            }}>
            {content && content}
          </Typography>
        </DialogContent>
        <DialogActions sx={{justifyContent: 'center'}}>
          <Button variant="contained" size="small" onClick={onClose}>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
