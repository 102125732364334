

import { API } from "./index.service";
import { MODULES } from "../../Constants/modules.constants";
import { GetMappedParametersArg } from "../Actions/parameter.actions";



const url = "config/report_configuration/";
const mappingsUrl = "config/report/parameter_mapping/";
const parameterUrl = "config/report/parameter/";
const reportBankUrl = 'xcpep/report_bank/stats/';
const bulk_save_params = 'config/bulk_update_parameter_mapping/';

export default {
 

    getBomParameters ( arg: GetMappedParametersArg ) {
        return API.get( parameterUrl, {
            project: arg.projectId,
            module: MODULES.TEMPLATE_CONFIGURATION,
        } );
    },
    getFeatureParameters ( arg: GetMappedParametersArg ) {
        return API.get( parameterUrl, {
            project: arg.projectId,
            module: arg.module,
        } );
    },

  
};
