import { createAction, createAsyncThunk } from "@reduxjs/toolkit";

import { CalculatorInput } from "../Reducers/calculatorInput.reducer";
import { ID } from "../../utlis/commonInterfaces";
import calculatorInputService from "../Services/calculatorInput.service";

// -------------------------------------------------------------------------------------
// api actions
// -------------------------------------------------------------------------------------

export interface GetCalculatorInputsArg {
    calculatorId: ID;
}
const getCalculatorInputs = createAsyncThunk(
    "calculatorInput/get",
    async ( arg: GetCalculatorInputsArg, { rejectWithValue } ) => {
        try {
            const { data } = await calculatorInputService.get( arg.calculatorId );

            return {
                inputs: data as CalculatorInput[]
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);


export interface updateFlagValueArg {
    id: ID;
    flag: boolean;
}
const updateFlagValue = createAsyncThunk(
    "calculatorInput/updateFlagValue",
    async ( arg: updateFlagValueArg, { rejectWithValue } ) => {
        try {
            const { data } = await calculatorInputService.updateflagTriangulation( arg.id, arg.flag );

            return {
                inputs: data as CalculatorInput[]
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);

//uploadFileTriangulation
export interface uploadFileTriangulationArg {
    id: ID;
    file: any;
}

const uploadFileTriangulation = createAsyncThunk(
    "calculatorInput/uploadFileTriangulation",
    async ( arg: uploadFileTriangulationArg, { rejectWithValue } ) => {
        try {
            const { data } = await calculatorInputService.uploadFileTriangulation( arg.id, arg.file );

            return {
                inputs: data as CalculatorInput[]
            };
        } catch ( e ) {
            return rejectWithValue( e );
        }
    }
);


// -------------------------------------------------------------------------------------
// reducer actions
// -------------------------------------------------------------------------------------

const resetCalculatorInputs = createAction( "calculatorInput/reset" );

// -------------------------------------------------------------------------------------
// exports
// -------------------------------------------------------------------------------------

export {
    getCalculatorInputs,
    resetCalculatorInputs,
    updateFlagValue,
    uploadFileTriangulation
};