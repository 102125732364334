import React from 'react';
import { Box, Button, Modal, TextField, Typography } from '@mui/material';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import { API } from '../../api-services';
import { LoadingButton } from '@mui/lab';
import CancelIcon from '@mui/icons-material/Cancel';

function CostModelForm({ isOpen, setCounter, onCloseModal, parameter_relation_id }: any) {
    const [loading, setLoading] = React.useState(false);
  // Validation schema for the cost model name
  const validationSchema = Yup.object().shape({
    cal_name: Yup.string()
      .required('Cost Model Name is required')
      .min(2, 'Must be at least 2 characters')
      .max(50, 'Must be at most 50 characters'),
  });

  const styleModal = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "auto",
    height: "auto",
    bgcolor: "background.paper",
    borderRadius: "10px",
    p: 1,
  };

  return (
    <Modal
      open={isOpen}
      onClose={onCloseModal}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={styleModal}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            width: '35vw',
            margin: 'auto',
            padding: 2,
            borderRadius: '4px',
          }}
        >
          
          <Box sx={{ display: "flex", justifyContent:"space-between", marginBottom:"1.5rem", alignItems: "center", height: "2.5rem",  width:"100%"}}>
           <Box/>
           <Typography variant="h6" gutterBottom>
            Create Cost Model
          </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer",  }}
              onClick={onCloseModal}
            />
          </Box>
          <Formik
            initialValues={{ cal_name: '', description: '' }}
            validationSchema={validationSchema}
            onSubmit={(values) => {
                setLoading(true);
              // Handle form submission
              API.post(`/config/bom_table_calculator_config/`, {
                cal_name: values.cal_name,
                description: values.description,
                parameter_id: parameter_relation_id,
              }, 0).then((res) => {
                console.log(res);
                setCounter((prev: any) => prev + 1);
                onCloseModal(); // Close modal after successful submission
              })
              .catch((e) => {
                console.log(e);
              }).finally(() => {
                setLoading(false);
              });
            }}
          >
            {({ handleSubmit, handleChange, values }) => (
              <Form onSubmit={handleSubmit}>
                <Field
                  name="cal_name"
                  as={TextField}
                  label="Cost Model Name"
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                  value={values.cal_name}
                  error={!!(values.cal_name && !validationSchema.isValidSync(values))}
                  helperText={<ErrorMessage name="cal_name" />}
                  sx={{ marginBottom: 2 }}
                />
                <Field
                  name="description"
                  as={TextField}
                  label="Description"
                  variant="outlined"
                  fullWidth
                  onChange={handleChange}
                  value={values.description}
                  sx={{ marginBottom: 2 }}
                />
                <LoadingButton loading={loading} type="submit" variant="contained" color="primary">
                  Submit
                </LoadingButton>
              </Form>
            )}
          </Formik>
        </Box>
      </Box>
    </Modal>
  );
}

export default CostModelForm;
