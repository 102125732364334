import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Modal from "@mui/material/Modal";
import FormControl from "@mui/material/FormControl";
import * as yup from "yup";
import { useFormik } from "formik";
import { Backdrop, Chip, Divider, InputLabel, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import styles from "../AddRevisionModal.module.scss";
import { LoadingButton } from "@mui/lab";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import CancelIcon from "@mui/icons-material/Cancel";
import { DatePicker } from "@mui/x-date-pickers";
import moment from "moment";
import { API } from "../../../api-services";
import { useRouteParams } from "../../../ui-reusable-component/useRouteParams";
import { BearingForm } from "./BearingForm";
import { ConnectorsForm } from "./ConnectorsForm";
import { ConsumablesForm } from "./ConsumablesForm";
import { ElectronicChildPartsForm } from "./ElectronicChildPartsForm";
import { MechanicalProprietoryPartsForm } from "./MechanicalProprietoryPartsForm";
import { BulbForm } from "./BulbForm";
import { HornModulesForm } from "./HornModulesForm";
import { FlasherModulesForm } from "./FlasherModulesForm";
import { IgnitionModulesForm } from "./IgnitionModulesForm";
import { RemoteKeyModulesForm } from "./RemoteKeyModulesForm";
import { OthersForm } from "./OthersForm";
import { BearingFormMove } from "./BearingFormMove";
import { ConnectorsFormMove } from "./ConnectorsFormMove";
import { ConsumablesFormMove } from "./ConsumablesFormMove";
import { ElectronicChildPartsFormMove } from "./ElectronicChildPartsFormMove";
import { MechanicalProprietoryPartsFormMove } from "./MechanicalProprietoryPartsFormMove";
import { OthersFormMove } from "./OthersFormMove";
import { BulbFormMove } from "./BulbFormMove";
import { HornModulesFormMove } from "./HornModulesFormMove";
import { FlasherModulesFormMove } from "./FlasherModulesFormMove";
import { IgnitionModulesFormMove } from "./IgnitionModulesFormMove";
import { RemoteKeyModulesFormMove } from "./RemoteKeyModulesFormMove";
import { useRouteMatch } from "react-router-dom";
import { FastenersFormMove } from "./FastenersFormMove";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "45vw",
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 0,
};
const RevisionTypeList = [
  { id: 1, text: "Main Costing", value: 1 },
  { id: 2, text: "Scenario Costing", value: 2 },
];
const validationSchema = yup.object({
  category: yup.string().required("Please select a category"),
  material_code: yup.string().required("Please enter material code"),
  material_grade: yup.string(),
  material_subgrade: yup.string(),
  shape: yup.string(),
  density: yup.number().required("Please enter density"),
  description: yup.mixed(),
  material_cost: yup.number().required("Please enter Material Cost"),
  material_source: yup.mixed().required("Please enter Material Source"),
  material_reference: yup.mixed(),
  scrap_cost: yup.number().required("Please enter Scrap Cost"),
  scrap_source: yup.mixed(),
  scrap_reference: yup.mixed(),
});

const useStyles = makeStyles((theme) => ({
  select: {
    "&.MuiInputBase-root-MuiInput-root-MuiSelect-root": {
      borderBottom: "1px solid red",
    },
  },
}));
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

interface ICreateRevisionDatabaseModalProps {
  isOpen: any;
  onCloseModal: any;
  actions?: any;
  expanded?: any;
  getMaterialData?: any;
  categoryList?: any;
  getCategoryData: any;
  onCloseParent:any
}

function CreateBoughtOutDatabaseModalMove(props: ICreateRevisionDatabaseModalProps) {
  const { isOpen, onCloseModal, actions, expanded, getMaterialData, categoryList, getCategoryData,onCloseParent } = props;
  const [isLoadingsave, setIsLoadingSave] = React.useState<any>(false);
  const { url } = useRouteMatch()
  const [category, setCategory] = React.useState<any>("");
  const [subCategory, setSubCategory] = React.useState<any>("");
  const { categoryName } = useRouteParams<any>();
  const [electricalCategories, setElectricalCategories] = React.useState<any>([]);
  const classes = useStyles();
  const handleChangeCategory = (ed: any) => {
    const { value } = ed.target;
    setCategory(value);
  };
  const handleChangeSubCategory = (ed: any) => {
    const { value } = ed.target;
    setSubCategory(value);
  };
  //   React.useEffect(() => {
  //     if (actions !== "add") {
  //       setSubCategory(actions.module_type);

  //       setCategory(actions?.commodity_name);
  //     }
  //   }, [actions]);

  React.useEffect(() => {
    if (category === "Electrical & Electronics Proprietary Parts")
      API.get(
        "/api/db/bought_out/",
        {
          commodity: category,
          module_count: true,
        },
        0
      )
        .then((res: any) => {
          setElectricalCategories(res.data);
        })
        .catch((err: any) => { });
  }, [category]);
  const getSelectedForm = () => {
    switch (category) {
      case "Bearings":
        return (
          <BearingFormMove
            actions={actions && actions}
            onCloseModal={onCloseModal}
            getCategoryData={getMaterialData}
            category={category}
            onCloseParent={onCloseParent}
          />
        );
      case "Connectors":
        return (
          <ConnectorsFormMove
            actions={actions && actions}
            onCloseModal={onCloseModal}
            getCategoryData={getMaterialData}
            category={category}
            onCloseParent={onCloseParent}
          />
        );
      case "Consumables":
        return (
          <ConsumablesFormMove
            actions={actions && actions}
            onCloseModal={onCloseModal}
            getCategoryData={getMaterialData}
            category={category}
            onCloseParent={onCloseParent}
          />
        );
      case "Electronic Child Components":
        return (
          <ElectronicChildPartsFormMove
            actions={actions && actions}
            onCloseModal={onCloseModal}
            getCategoryData={getMaterialData}
            category={category}
            onCloseParent={onCloseParent}
          />
        );
      case "Mechanical Proprietory Parts":
        return (
          <MechanicalProprietoryPartsFormMove
            actions={actions && actions}
            onCloseModal={onCloseModal}
            getCategoryData={getMaterialData}
            category={category}
            onCloseParent={onCloseParent}
          />
        );
      case "Uncategorized":
        return (
          <OthersFormMove
            actions={actions && actions}
            onCloseModal={onCloseModal}
            getCategoryData={getMaterialData}
            category={category}
            onCloseParent={onCloseParent}
          />
        );
      case "Fasteners":
        return (
          <FastenersFormMove
            actions={actions && actions}
            onCloseModal={onCloseModal}
            getCategoryData={getMaterialData}
            category={category}
            onCloseParent={onCloseParent}
          />
        );
      default:
        return false;
    }
  };

  const getSubForm = () => {
    switch (subCategory) {
      case "Bulb":
        return (
          <BulbFormMove
            actions={actions && actions}
            onCloseModal={onCloseModal}
            getMaterialData={getCategoryData}
            subCategory={category}
            target={subCategory}
            onCloseParent={onCloseParent}
          />
        );
      case "Horn":
        return (
          <HornModulesFormMove
            actions={actions && actions}
            onCloseModal={onCloseModal}
            getMaterialData={getCategoryData}
            subCategory={category}
            target={subCategory}
            onCloseParent={onCloseParent}
          />
        );
      case "Flasher":
        return (
          <FlasherModulesFormMove
            actions={actions && actions}
            onCloseModal={onCloseModal}
            getMaterialData={getCategoryData}
            subCategory={category}
            target={subCategory}
            onCloseParent={onCloseParent}
          />
        );
      case "Ignition":
        return (
          <IgnitionModulesFormMove
            actions={actions && actions}
            onCloseModal={onCloseModal}
            getMaterialData={getCategoryData}
            subCategory={category}
            target={subCategory}
            onCloseParent={onCloseParent}
          />
        );
      case "Remote Key/Lock":
        return (
          <RemoteKeyModulesFormMove
            actions={actions && actions}
            onCloseModal={onCloseModal}
            getMaterialData={getCategoryData}
            subCategory={category}
            target={subCategory}
            onCloseParent={onCloseParent}
          />
        );
      default:
        break;
    }
  };

  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}>
        <Box sx={style}>
            
          <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: "-2rem",
              }}>
              Select Type of Bought-out part
            </Typography>
            <CancelIcon
              titleAccess="Close"
              sx={{ cursor: "pointer", margin: "0.5rem" }}
              onClick={onCloseModal}
            />
          </Box>
          <Divider
            sx={{
              borderColor: "primary.light",
              margin: "0 1rem",
              marginBottom: "1rem",
            }}
          />
          <Box sx={{ padding: "0 2rem 1rem",}}>
            {/* electricalCategories */}
            <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '1rem', marginBottom: '0.5rem' }}>
              <FormControl
                fullWidth
                variant="standard">
                <InputLabel sx={{ color: 'primary.main' }} shrink id="demo-simple-select-label">Select</InputLabel>

                <Select
                  id="category"
                  name="category"
                  className={classes.select}
                  labelId="demo-select-small"
                  // id="demo-select-small"
                  sx={{
                    "&:before": {
                      borderBottomColor: "primary.main",
                    },
                    "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                      fontSize: '1rem'
                    },
                    '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },

                  }}
                  //@ts-ignore
                  MenuProps={{
                    style: {
                      maxHeight: 400,
                      color: "007fff",
                    },
                  }}
                  onChange={handleChangeCategory}
                  value={category}>
                  {/* <option value={""} style={{ display: "none" }}></option> */}
                  {categoryList &&
                    categoryList.map((item: any, index: any) => {
                      return (
                        <MenuItem
                          sx={{ color: "primary.main", fontSize: "1rem" }}
                          key={index}
                          value={url.includes("DB") ? item.name : item.category}>
                          {url.includes("DB") ? item.name : item.category}
                        </MenuItem>
                      );
                    })}
                </Select>
                {/* <div className={styles.errormsg}>{formik.touched.category && Boolean(formik.errors.category) && <p>{formik.errors.category}</p>}</div> */}
              </FormControl>
              {category === "Electrical & Electronics Proprietary Parts" && (
                <FormControl
                  fullWidth
                  variant="standard" sx={{ marginBottom: '0.5rem' }}>
                  <InputLabel sx={{ color: 'primary.main' }} shrink id="demo-simple-select-label">Select Sub Part</InputLabel>

                  <Select
                    //   id="category"
                    //   name="category"
                    className={classes.select}
                    labelId="demo-select-small"
                    // id="demo-select-small"
                    sx={{
                      "&:before": {
                        borderBottomColor: "primary.main",
                      },
                      "& .MuiSelect-select.MuiInputBase-input.MuiInput-input.MuiSelect-select": {
                        fontSize: '1rem'
                      },
                      '.MuiSvgIcon-root.MuiSelect-icon': { color: 'primary.main' },

                    }}
                    MenuProps={{
                      style: {
                        maxHeight: 400,
                        color: "007fff",
                      },
                    }}
                    onChange={handleChangeSubCategory}
                    value={subCategory}>
                    {/* <option value={""} style={{ display: "none" }}></option> */}
                    {electricalCategories &&
                      electricalCategories.map((item: any, index: any) => {
                        return (
                          <MenuItem
                            sx={{ color: "primary.main", fontSize: "1rem" }}
                            key={index}
                            value={item.module_type}>
                            {item.module_type}
                          </MenuItem>
                        );
                      })}
                  </Select>
                  {/* <div className={styles.errormsg}>{formik.touched.category && Boolean(formik.errors.category) && <p>{formik.errors.category}</p>}</div> */}
                </FormControl>
              )}
            </Box>
            {/* <LoadingButton
                size="small"
                loading={isLoadingsave}
                variant="contained"
                type="submit"
                sx={{ marginTop: "1rem" }}>
                Save
              </LoadingButton> */}
            {category !== "Electrical & Electronics Proprietary Parts" && getSelectedForm()}
            {category === "Electrical & Electronics Proprietary Parts" && getSubForm()}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
export default CreateBoughtOutDatabaseModalMove;
