import * as React from "react";
import Box from "@mui/material/Box";

import { Backdrop, ButtonProps, Divider, Modal, Skeleton, Table, TableBody, TableRow, TableCell, TableHead, Tooltip, Typography } from "@mui/material";
import { API } from "../../api-services";
import CancelIcon from "@mui/icons-material/Cancel";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";


const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minWidth: { lg: "40vw", xl: '30vw' },
  maxWidth: "80vw",
  // height: '80vh',
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};


interface IWatermarkModelProps {
  isOpen: any;
  onCloseModal: any;
  item: any;
}

function TreeInfoModal(props: IWatermarkModelProps) {
  const { isOpen, onCloseModal, item } = props;
  const { revisionId } = useRouteParams<any>();
  const [infoData, setInfoData] = React.useState<any>([]);
  const [isLoading, setIsLoading] = React.useState<any>(false);
  const getInfoData = () => {
    setIsLoading(true)
    API.get(
      "/cost/costing_view/",
      {
        vault: item?.idd,
        level: "costing_info",
      },
      0
    )
      .then((res: any) => {
        setIsLoading(false)
        let rows: any = [];
        const dataGridDataGenerator: any = [res.data]?.map(
          (infoItem: any, index: any) => {
            let objShared: any = {
              id: index + 1,
              assumption: infoItem?.assumption,
              category: infoItem?.category,
              commodity_name: infoItem?.commodity_name,
              part_no: infoItem?.part_no,
              name: item?.name,
              total_cost: item?.total_cost

            };
            rows.push(objShared);
          }
        );
        setInfoData(rows);
      })
      .catch((err: any) => {
        setIsLoading(false)
      });
  };
  React.useEffect(() => {
    getInfoData();
  }, []);

  
  return (
    <div>
      <Modal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Box sx={style}>
          <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
            <Typography
              style={{
                fontSize: "1rem",
                fontWeight: "600",
                width: "100%",
                textAlign: "center",
                marginRight: '-2rem',
                textTransform: 'capitalize',
              }}
            >
              {item?.name?.toLowerCase()} Costing Info
            </Typography>
            <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={onCloseModal} />
          </Box>
          <Divider />
          <Box
            sx={{ height: "fit-content", width: "100%" }}
            adl-scrollbar="true"
            adl-scrollbar-width="0.3"
          >
            {!isLoading && infoData ? (<>
              <Box>
                <Table>
                  <TableBody>
                    {infoData?.map((column: any, index: any) => {
                      return <><TableRow key={index}>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Name</TableCell>
                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>{column?.name}</TableCell>
                      </TableRow>
                        <TableRow>
                          <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Part No</TableCell>
                          <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>{column?.part_no}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Assumptioncard Name</TableCell>
                          <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>{column?.assumption}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Category</TableCell>
                          <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>{column?.category}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Commodity Name</TableCell>
                          <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>{column?.commodity_name?.length > 1 ? column?.commodity_name?.join(",  ") : column?.commodity_name?.toString()}</TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>Total Cost</TableCell>
                          <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light' }}>{column?.total_cost}</TableCell>
                        </TableRow>
                      </>
                    })}
                  </TableBody>
                </Table>
              </Box>              
            </>) : <Box>
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
              <Skeleton animation="wave" />
            </Box>}
          </Box>
        </Box>
      </Modal>
    </div>
  );
}
export default TreeInfoModal;
