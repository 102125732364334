import { Box, Checkbox, MenuItem, Skeleton, Typography } from "@mui/material";
import * as React from "react";
import styles from "./FeatureProductandSystemTreeDetails.module.scss";
import AddIcon from "@mui/icons-material/Add";
import TreeView from "@mui/lab/TreeView";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import TreeItem from "@mui/lab/TreeItem";
import { useRouteParams } from "../../ui-reusable-component/useRouteParams";
import { API } from "../../api-services";
import { useHistory } from "react-router-dom";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, {
  AccordionSummaryProps,
} from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { useBaseModal } from "../SearchPage/useBaseModal";
import { AddGroupModal } from "../MinutesofMeeting/AddGroupModal";
import AddFeatureGroupModal from "./AddFeatureGroupModal";
import { ControlledMenu } from "@szhsin/react-menu";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import AddFeatureModal from "./AddFeatureModal";
import { useBaseParamsModal } from "../../CustomHook/useBaseParamsModal";
import { FeatureCreateImageUploadModal } from "./FeatureCreateImageUploadModal";
import { CreateFeatureFetchModal } from "./CreateFeatureFetchModal";
import swal from "sweetalert";
import { CreateFeatureMoveModal } from "./CreateFeatureMoveModal";
import { useDispatch, useSelector } from "react-redux";
import { FeatureGroupToGroupMoveModal } from "./FeatureGroupToGroupMoveModal";
import { DialogComponent } from "../../utlis/DialogComponent";
import Swal from "sweetalert2";
import {
  increaseCounter,
  recyclebinCounter,
} from "../../Redux/Actions/compareopen.action";
import FolderIcon from "@mui/icons-material/Folder";
import openFolder from "../../Assets/images/svgs/openfolderblue.svg";
import { ADMIN } from "../../Redux/Services/admin.service";
import { userSelectors } from "../../Redux/Reducers/user.reducer";
// import featureIcon from "../../Assets/images/FeatureIcon.svg";

const Accordion = styled((props: AccordionProps) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
  <MuiAccordionSummary
    // expandIcon={
    //   <FolderIcon sx={{ fontSize: "1.5rem", color: "primary.main" }} />
    // }
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "white",
  flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(0deg)",
  },

  "& .MuiAccordionSummary-content": {
    marginLeft: theme.spacing(1),
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  // borderTop: "1px solid #ddefff",
}));

export interface IFeatureProductandSystemTreeDetailsProps {
  selectedType: any;
}

export function FeatureProductandSystemTreeDetails(
  props: IFeatureProductandSystemTreeDetailsProps
) {
  const { selectedType } = props;
  const openStatusContent = useBaseModal();
  const history = useHistory();
  const addGroupModal = useBaseParamsModal();
  const store: any = useSelector((state) => state);
  const CreateFeatureFetch = useBaseModal();
  const FeatureCreateUploadModal = useBaseParamsModal();
  const { projectId, projectname, type, parent, child, featureId } =
    useRouteParams<any>();
  const [systemdata, setSystemData] = React.useState<any>([]);
  const [featuresData, setFeaturesData] = React.useState<any>([]);
  const [featureDataLoading, setFeatureDataLoading] =
    React.useState<any>(false);
  const [systemDataLoading, setSystemDataLoading] = React.useState<any>(false);
  const [groupData, setGroupData] = React.useState<any>([]);
  const [totalFeatureCount, setTotalFeatureCount] = React.useState<any>(0);
  const [totalGroupCount, setTotalGroupCount] = React.useState<any>(0);
  const [groupDataLoading, setGroupDataLoading] = React.useState<any>(false);
  const [iconText, setIconText] = React.useState<any>("");
  const [contentText, setContentText] = React.useState<any>("");
  const [isOpenContext, setOpenContext] = React.useState(false);
  const [anchorPoint, setAnchorPoint] = React.useState({ x: 0, y: 0 });
  const [isOpenContextGroup, setOpenContextGroup] = React.useState(false);
  const [anchorPointGroup, setAnchorPointGroup] = React.useState({
    x: 0,
    y: 0,
  });
  const [groupContextAbbreviation, setGroupContextAbbreviation] =
    React.useState();
  const [permission, setPermission] = React.useState<any>("");
  const [isOpenContextFeature, setOpenContextFeaure] = React.useState(false);
  const [anchorPointFeature, setAnchorPointFeature] = React.useState({
    x: 0,
    y: 0,
  });

  const addFeatureModal = useBaseModal();
  const dispatch = useDispatch();
  const moveModal = useBaseParamsModal();
  const moveModalFeature = useBaseParamsModal();

  const [expanded, setExpanded] = React.useState<string | false>(parent);
  const [addFeatureAddData, setAddFeatureAddData] = React.useState<any>({});
  const [FeatureInfo, setFeatureInfo] = React.useState<any>({});
  const [checked, setChecked] = React.useState<any>([]);
  const [expandedGroup, setExpandedGroup] = React.useState<string | false>(
    child
  );
  const [targetFeatureData, setTargetFeatureData] = React.useState<any>({});
  const userProfilePic = useSelector(userSelectors.selectAll);
  const featureMoveHanler = () => {
    if (Object.keys(addFeatureAddData).length > 0) {
      moveModal.open(addFeatureAddData?.group);
    }
  };
  const getColor = (status: any) => {
    switch (status) {
      
      case 1:
        return "red";
      case 2:
        return "#ffa500";
      case 3:
        return "green";
        
      default:
        return "";
    }
  };
console.log(checked,"checked",)
  const getFeatureColor = (status: any) => {
    switch (status) {
      
      case 0:
        return "red";
        case 1:
          return "#ffa500";
      case 2:
        return "#007fff";
      case 3:
        return "green";
        
      default:
        return "";
    }
  };

  

  const getModule = () => {
    switch (parseInt(type)) {
      case 1:
        return "Feature";
      case 2:
        return "Specification";
      case 3:
        return "Information";
      default:
        return "";
    }
  }



  const getModuleMulti = () => {
    switch (parseInt(type)) {
      case 1:
        return "Features";
      case 2:
        return "Specifications";
      case 3:
        return "Informations";
      default:
        return "";
    }
  }
  const handleChange:any =
    (panel: string, name: any) => (event: React.SyntheticEvent, newExpanded: boolean) => {

      // console.log(name, "namename")
      setExpanded(newExpanded ? panel : false);
      if (newExpanded) {
        history.push(
          `/createfeature/${projectId}/${projectname}/${type}/${panel}/${0}/0/0/1`
        );
        getGroupData(panel);
      }
    };




    const queries=(item:any,status:any)=>{

    
      if (item?.abbreviation=="PRODUCT"){
          return {top_vault:item?.id,status:status}
      }else{
          return {vault_info:item?.id,status:status}
      }
  }
  
  
  
      const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>,id:any) => {
           if(event?.target?.checked==true){
              API.post("/xcpep/feature_submission_status/",{...queries(id,true)},{},0).then((res:any)=>{
                  console.log(res.data,"datattatatat")
                   setChecked([...checked,id?.id])
                  ADMIN.toast.success("System/Product updated successfully.")
              }).catch((err:any)=>{
                  ADMIN.toast.error("Server Error")
              })
           }
           else if (event?.target?.checked==false){
              API.post("/xcpep/feature_submission_status/",{...queries(id,false)},{},0).then((res:any)=>{
                  ADMIN.toast.success("System/Product updated successfully.")
                  setChecked((prev:any)=>prev?.filter((item:any)=>item!==id?.id))
              }).catch((err:any)=>{
                  ADMIN.toast.error("Server Error")
              })
           }
  
  
    
          // setChecked(event.target.checked);
      };
  React.useEffect(() => {
    API.get(
      "/auth/calibration_user_permission/",
      {
        action: true,
        project: projectId,
        feature_create: true,
      },
      0
    )
      .then((res: any) => {
        setPermission(res.data[0]?.action);
      })
      .catch((err: any) => { });
  }, [projectId]);

  const handleChangeGroup =
    (panel: string, itemId: any, abbr: any, vehicle: any) =>
      (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpandedGroup(newExpanded ? itemId : false);
        sessionStorage.setItem("groupNameFeature", panel);
        setFeatureInfo({
          vehicle: vehicle,
          abbreviation: abbr,
          group: itemId
        })
        if (newExpanded) {
          history.push(
            `/createfeature/${projectId}/${projectname}/${type}/${parent}/${itemId}/0/0/1`
          );
          getFeaturesData(itemId);
        }
      };

  const handleClickFeature = (featureId: any, idd: any) => {
    history.push(
      `/createfeature/${projectId}/${projectname}/${type}/${parent}/${child}/${featureId}/${idd}/1`
    );
  };

  const getSystemData = (type: any) => {
    setSystemDataLoading(true);
    API.get(
      "/xcpep/feature_project/",
      {
        level: "singleproject",
        project: projectId,
        type: type,
      },
      0
    )
      .then((res: any) => {
        setSystemData(res.data);
        let FilterDashboardStatus=res?.data?.filter((item:any)=>{return item?.dashboard_status==true})?.map((item1:any,index1:any)=>{return item1?.id})
            
        setChecked(FilterDashboardStatus)
        setSystemDataLoading(false);
      })
      .catch((err: any) => {
        setSystemDataLoading(false);
      });
  };
  React.useEffect(() => {
    getSystemData(parseInt(type) - 1);
  }, [projectId]);

  React.useEffect(() => {
    getSystemData(parseInt(type) - 1);
    getGroupData(parent);
    getFeaturesData(child);
    setExpanded(parent);
    setExpandedGroup(child);
    getFeaturesData(child);
  }, [
    store?.is_compare?.actionCount,
    projectId,
    store?.is_compare?.validationCounter,
  ]);
  React.useEffect(() => {
    if (child != 0) {
      setExpandedGroup(child);
    }
  }, [child]);
  React.useEffect(() => {
    setExpanded(parent);
  }, [parent]);
console.log(type,"vdkbjvdbjvbjdvbjdbjvdhbjvhb")
  const getGroupData = (parentGroup: any) => {
    setGroupDataLoading(true);
    API.get(
      "/xcpep/feature_groups/",
      {
        project: projectId,
        type: type - 1,
        abbreviation: parentGroup,
      },
      0
    )
      .then((res: any) => {
        setGroupData(res.data);
        setGroupDataLoading(false);
      })
      .catch((err: any) => {
        setGroupDataLoading(false);
      });
  };

  const getFeaturesData = (groupId: any) => {
    setFeatureDataLoading(true);
    API.get(
      "/xcpep/product_feature/",
      {
        project: projectId,
        type: type - 1,
        abbreviation: parent,
        group: groupId,
      },
      0
    )
      .then((res: any) => {
        setFeaturesData(res.data);
        setFeatureDataLoading(false);
      })
      .catch((err: any) => {
        setFeatureDataLoading(false);
      });
  };

  React.useEffect(() => {
    if (type) {
      getSystemData(type - 1);
    }
  }, [type]);
  React.useEffect(() => {
    if (child !== "0") {
      getFeaturesData(child);
    }
  }, [child]);
  React.useEffect(() => {
    if (parent !== "0") {
      getGroupData(parent);
    }
  }, [parent]);


const AccordianHandler=(e:any,item:any)=>{
  e.stopPropagation();
  e.preventDefault();
  setAnchorPoint({ x: e.clientX, y: e.clientY });
  setOpenContext(true);
  setGroupContextAbbreviation(item?.abbreviation);
  setTotalGroupCount(item?.group_count);
}


console.log(type,checked,"checkedcgudcdhchdjchjdcvdchvjdvchjd")
  return (
    <div style={{ backgroundColor: "white" }}>
      {moveModal.isOpen && (
        <FeatureGroupToGroupMoveModal
          onCloseModal={moveModal.close}
          isOpen={moveModal.open}
          featuresData={featuresData}
          groupId={moveModal.propsId}
          getModule={getModule}
        />
      )}
      {moveModalFeature.isOpen && (
        <CreateFeatureMoveModal
          onCloseModal={moveModalFeature.close}
          isOpen={moveModalFeature.open}
          featuresData={featuresData}
          data={moveModalFeature.propsId}
          getModule={getModule}
        />
      )}

      <Box
        sx={{ width: "100%", height: { lg: "76.8vh", xl: "83vh" } }}
        adl-scrollbar="true"
        adl-scrollbar-width="0.3"
      >
        {FeatureCreateUploadModal.isOpen && FeatureCreateUploadModal.isOpen && (
          <FeatureCreateImageUploadModal
            onCloseModal={FeatureCreateUploadModal.close}
            isOpen={FeatureCreateUploadModal.isOpen}
            response={FeatureCreateUploadModal.propsId}
            closeParent={FeatureCreateUploadModal.close}
            addFeatureAddData={FeatureInfo}
            getModule={getModule}
          />
        )}

        <ControlledMenu
          anchorPoint={anchorPoint}
          position="anchor"
          transition
          state={isOpenContext ? "open" : "closed"}
          direction="left"
          onClose={() => setOpenContext(false)}
          onClick={(e: any) => {
            e.stopPropagation();
          }}
        >
          <MenuItem
            disabled={!permission?.includes("C")}
            sx={{ color: "primary.main", fontSize: "1rem" }}
            onClick={() => addGroupModal.open(groupContextAbbreviation)}
          >
            Add Group
          </MenuItem>
          {totalGroupCount > 0 && (
            <MenuItem
              sx={{ color: "primary.main", fontSize: "1rem" }}
              onClick={() => {
                API.get(
                  "/customer/check_feature_consolidated/",
                  {
                    report_type: "Feature Media",
                    type: parseInt(type) - 1,
                    project: projectId,
                    abbreviation: groupContextAbbreviation,
                  },
                  0
                )
                  .then((res: any) => {
                    if (res.data.status === "Failed") {
                      setIconText("failed");
                      setContentText(res.data.message);
                      openStatusContent.open();
                    } else if (res.data.status === "First Download") {
                      setIconText("firstdownload");
                      setContentText(res.data.message);
                      openStatusContent.open();
                      API.get(
                        "/customer/feature_consolidated/",
                        {
                          report_type: "Feature Media",
                          type: parseInt(type) - 1,
                          project: projectId,
                          abbreviation: groupContextAbbreviation,
                        },
                        0
                      ).then((res: any) => {
                        window.open(res?.data, "_self");
                      });
                    } else if (res.data.status === "In Progress") {
                      setIconText("reportProgress");
                      setContentText(res.data.message);
                      openStatusContent.open();
                    } else if (res.data.status === "Completed") {
                      window.open(res?.data?.url, "_self");
                    }
                  })
                  .catch((err: any) => { });
              }}
            >
              Download Media (Zip File)
            </MenuItem>
          )}
          {totalGroupCount > 0 && (
            <MenuItem
              sx={{ color: "primary.main", fontSize: "1rem" }}
              onClick={() => {
                API.get(
                  "/customer/check_feature_consolidated/",
                  {
                    report_type: "Feature Consolidated",
                    type: parseInt(type) - 1,
                    project: projectId,
                    abbreviation: groupContextAbbreviation,
                  },
                  0
                )
                  .then((res: any) => {
                    if (res.data.status === "Failed") {
                      setIconText("failed");
                      setContentText(res.data.message);
                      openStatusContent.open();
                    } else if (res.data.status === "First Download") {
                      setIconText("firstdownload");
                      setContentText(res.data.message);
                      openStatusContent.open();
                      API.get(
                        "/customer/feature_consolidated/",
                        {
                          report_type: "Feature Consolidated",
                          type: parseInt(type) - 1,
                          project: projectId,
                          abbreviation: groupContextAbbreviation,
                        },
                        0
                      ).then((res: any) => {
                        window.open(res?.data, "_self");
                      });
                    } else if (res.data.status === "In Progress") {
                      setIconText("reportProgress");
                      setContentText(res.data.message);
                      openStatusContent.open();
                    } else if (res.data.status === "Completed") {
                      window.open(res?.data?.url, "_self");
                    }
                  })
                  .catch((err: any) => { });
              }}
            >
              Download Consolidated Report (Excel)
            </MenuItem>
          )}
        </ControlledMenu>
        <ControlledMenu
          anchorPoint={anchorPointGroup}
          position="anchor"
          transition
          state={isOpenContextGroup ? "open" : "closed"}
          direction="left"
          onClose={() => setOpenContextGroup(false)}
          onClick={(e: any) => {
            e.stopPropagation();
          }}
        >
          <MenuItem
            sx={{ color: "primary.main", fontSize: "1rem" }}
            disabled={!permission?.includes("C")}
            onClick={() => {
              API.post(
                "/xcpep/product_feature/",
                {
                  project: projectId,
                  group: addFeatureAddData?.group,
                  abbreviation: addFeatureAddData?.abbreviation,
                  type: parseInt(type) - 1,
                },
                {},
                0
              )
                .then((res: any) => {
                  FeatureCreateUploadModal.open(res.data);
                })
                .catch((err: any) => {
                  const { data } = err?.response;
                  Swal.fire({
                    icon: "error",
                    html: `<div>
                    <br />
                    <p style="color:"red">${data[0]}</p>   
                     </div>`,
                  });
                });
              // CreateFeatureFetch.open();
            }}
          >
            Add {getModule()}
          </MenuItem>
          <MenuItem
            sx={{ color: "primary.main", fontSize: "1rem" }}
            disabled={!permission?.includes("D")}
            onClick={() => {
              swal({
                title: 'Delete this group?',
                icon: "error",
                text: `Deleting this group will permanently delete all the "${getModule()}" belongs to this group from Recycle bin, Are you sure, you want to delete this group?`,
                buttons: ["Cancel", "Ok"],
              }).then((confirm: any) => {
                if (confirm) {
                  API.delete(
                    `/xcpep/feature_groups/${addFeatureAddData?.group}/`,
                    {},
                    0
                  )
                    .then((res: any) => {
                      getGroupData(parent);
                      var random: any = Math.floor(Math.random() * 1000000 + 1);
                      dispatch(increaseCounter(random));
                      dispatch(recyclebinCounter(random + 1));
                    })
                    .catch((err: any) => {
                      const { data } = err?.response;
                      Swal.fire({
                        icon: "error",
                        html: `<div>
                               <br />
                               <p style="color:"red">${data[0]}</p>   
                                </div>`,
                      });
                    });
                }
              });
            }}
          >
            Delete Group
          </MenuItem>
          {totalFeatureCount > 0 && (
            <MenuItem
              sx={{ color: "primary.main", fontSize: "1rem" }}
              disabled={!permission?.includes("U")}
              onClick={() => featureMoveHanler()}
            >
              Move  {getModule()} to another group
            </MenuItem>
          )}
          {totalFeatureCount > 0 && (
            <MenuItem
              sx={{ color: "primary.main", fontSize: "1rem" }}
              onClick={() => {
                API.get(
                  "/customer/check_feature_consolidated/",
                  {
                    report_type: "Feature Media",
                    type: parseInt(type) - 1,
                    group: addFeatureAddData?.group,
                  },
                  0
                )
                  .then((res: any) => {
                    if (res.data.status === "Failed") {
                      setIconText("failed");
                      setContentText(res.data.message);
                      openStatusContent.open();
                    } else if (res.data.status === "First Download") {
                      setIconText("firstdownload");
                      setContentText(res.data.message);
                      openStatusContent.open();
                      API.get(
                        "/customer/feature_consolidated/",
                        {
                          report_type: "Feature Media",
                          type: parseInt(type) - 1,
                          group: addFeatureAddData?.group,
                        },
                        0
                      ).then((res: any) => {
                        window.open(res?.data, "_self");
                      });
                    } else if (res.data.status === "In Progress") {
                      setIconText("reportProgress");
                      setContentText(res.data.message);
                      openStatusContent.open();
                    } else if (res.data.status === "Completed") {
                      window.open(res?.data?.url, "_self");
                    }
                  })
                  .catch((err: any) => { });
              }}
            >
              Download Media (Zip File)
            </MenuItem>
          )}
          {totalFeatureCount > 0 && (
            <MenuItem
              sx={{ color: "primary.main", fontSize: "1rem" }}
              onClick={() => {
                API.get(
                  "/customer/check_feature_consolidated/",
                  {
                    report_type: "Feature Consolidated",
                    type: parseInt(type) - 1,
                    group: addFeatureAddData?.group,
                  },
                  0
                )
                  .then((res: any) => {
                    if (res.data.status === "Failed") {
                      setIconText("failed");
                      setContentText(res.data.message);
                      openStatusContent.open();
                    } else if (res.data.status === "First Download") {
                      setIconText("firstdownload");
                      setContentText(res.data.message);
                      openStatusContent.open();
                      API.get(
                        "/customer/feature_consolidated/",
                        {
                          report_type: "Feature Consolidated",
                          type: parseInt(type) - 1,
                          group: addFeatureAddData?.group,
                        },
                        0
                      ).then((res: any) => {
                        window.open(res?.data, "_self");
                      });
                    } else if (res.data.status === "In Progress") {
                      setIconText("reportProgress");
                      setContentText(res.data.message);
                      openStatusContent.open();
                    } else if (res.data.status === "Completed") {
                      window.open(res?.data?.url, "_self");
                    }
                  })
                  .catch((err: any) => { });
              }}
            >
              Download Consolidated Report
            </MenuItem>
          )}
        </ControlledMenu>

        <ControlledMenu
          anchorPoint={anchorPointFeature}
          position="anchor"
          transition
          state={isOpenContextFeature ? "open" : "closed"}
          direction="left"
          onClose={() => setOpenContextFeaure(false)}
          onClick={(e: any) => {
            e.stopPropagation();
          }}
        >
          <MenuItem
            disabled={!permission?.includes("U")}
            sx={{ color: "primary.main", fontSize: "1rem" }}
            onClick={() =>
              moveModalFeature.open({
                group: addFeatureAddData?.group,
                feature: targetFeatureData?.id,
              })
            }
          >
            Move to another group
          </MenuItem>
          <MenuItem
            sx={{ color: "primary.main", fontSize: "1rem" }}
            disabled={!permission?.includes("D")}
            onClick={() => {
              swal({
                text: `Are you sure, you want to delete this ${getModule()} ?`,
                buttons: ["Cancel", "Ok"],
                icon: "warning",
              }).then((confirm: any) => {
                if (confirm) {
                  API.delete(
                    `/xcpep/product_feature/${targetFeatureData?.id}/`,
                    {},
                    0
                  )
                    .then((res: any) => {
                      getFeaturesData(targetFeatureData?.group);
                      var random: any = Math.floor(Math.random() * 1000000 + 1);
                      dispatch(increaseCounter(random));
                      dispatch(recyclebinCounter(random + 1));
                    })
                    .catch((err: any) => {
                      const { data } = err?.response;
                      Swal.fire({
                        icon: "error",
                        html: `<div>
                               <br />
                               <p style="color:"red">${data[0]}</p>   
                                </div>`,
                      });
                    });
                }
              });
            }}
          >
            Delete  {getModule()}
          </MenuItem>
          <MenuItem
            sx={{ color: "primary.main", fontSize: "1rem" }}
            onClick={() => {
              API.get(
                "/customer/check_feature_consolidated/",
                {
                  report_type: "Feature Media",
                  type: parseInt(type) - 1,
                  feature: targetFeatureData?.id,
                },
                0
              )
                .then((res: any) => {
                  if (res.data.status === "Failed") {
                    setIconText("failed");
                    setContentText(res.data.message);
                    openStatusContent.open();
                  } else if (res.data.status === "First Download") {
                    setIconText("firstdownload");
                    setContentText(res.data.message);
                    openStatusContent.open();
                    API.get(
                      "/customer/feature_consolidated/",
                      {
                        report_type: "Feature Media",
                        type: parseInt(type) - 1,
                        feature: targetFeatureData?.id,
                      },
                      0
                    ).then((res: any) => {
                      window.open(res?.data, "_self");
                    });
                  } else if (res.data.status === "In Progress") {
                    setIconText("reportProgress");
                    setContentText(res.data.message);
                    openStatusContent.open();
                  } else if (res.data.status === "Completed") {
                    window.open(res?.data?.url, "_self");
                  }
                })
                .catch((err: any) => { });
            }}
          >
            Download Media (Zip File)
          </MenuItem>
          <MenuItem
            sx={{ color: "primary.main", fontSize: "1rem" }}
            onClick={() => {
              API.get(
                "/customer/check_feature_consolidated/",
                {
                  report_type: "Feature Consolidated",
                  type: parseInt(type) - 1,
                  feature: targetFeatureData?.id,
                },
                0
              )
                .then((res: any) => {
                  if (res.data.status === "Failed") {
                    setIconText("failed");
                    setContentText(res.data.message);
                    openStatusContent.open();
                  } else if (res.data.status === "First Download") {
                    setIconText("firstdownload");
                    setContentText(res.data.message);
                    openStatusContent.open();
                    API.get(
                      "/customer/feature_consolidated/",
                      {
                        report_type: "Feature Consolidated",
                        type: parseInt(type) - 1,
                        feature: targetFeatureData?.id,
                      },
                      0
                    ).then((res: any) => {
                      window.open(res?.data, "_self");
                    });
                  } else if (res.data.status === "In Progress") {
                    setIconText("reportProgress");
                    setContentText(res.data.message);
                    openStatusContent.open();
                  } else if (res.data.status === "Completed") {
                    window.open(res?.data?.url, "_self");
                  }
                })
                .catch((err: any) => { });
            }}
          >
            Download  {getModule()} Report (Excel File)
          </MenuItem>
        </ControlledMenu>

        {!systemDataLoading && systemdata ? (
          systemdata?.map((item: any) => {
            return (
              <>
                {
                  <Accordion
                    sx={{ border: "none" }}
                    expanded={expanded === item?.abbreviation}
                    onChange={(type && type==1) ? checked.includes(item?.id)==false && handleChange(item?.abbreviation, item?.name) :handleChange(item?.abbreviation, item?.name)}
                  >
                    <AccordionSummary
                      expandIcon={
                        expanded === item?.abbreviation ? (
                          <img src={openFolder} style={{ height: "1.2rem" }} />
                        ) : (
                          <FolderIcon
                            sx={{ fontSize: "1.5rem", color: "primary.main" }}
                          />
                        )
                      }
                      sx={{ minHeight: "24px", padding: "0 0.5rem" }}
                      aria-controls="panel1d-content"
                      id={item?.abbreviation}
                    >
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          alignItems: "center",
                          fontSize: "1rem",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box
                          sx={{ display: "flex", columnGap: "0.2rem" }}
                          onContextMenu={(e) => { (type && type==1) ? checked.includes(item?.id)==false &&  AccordianHandler(e,item):
                           
                            AccordianHandler(e,item) }}
                        >
                          <Typography
                            sx={{
                              padding: "0.3rem 0.5rem",
                              fontSize: "0.87rem",
                              color: getColor(item?.status),
                              textTransform: "capitalize",
                              lineHeight: 1.2,
                            }}
                          >
                            {item?.name.toLowerCase()}&nbsp; (
                            {item?.group_count}{" "}
                            {item?.group_count > 1 ? "Groups" : "Group"},&nbsp;
                            {item?.total_feature}{" "}
                            {item?.total_feature > 1 ? `${getModuleMulti()}` : `${getModule()}`})

                          

                          </Typography>
                        </Box>
<Box sx={{display:"flex"}}>

  {
    item?.total_feature ==0 && <>
                        { type && type-1==0 && userProfilePic && userProfilePic[0]?.is_superuser && 
                                        <Checkbox
                                            checked={checked?.includes(item?.id)}
                                            onChange={(e:any)=>handleCheckboxChange(e,item)}
                                            inputProps={{ 'aria-label': 'controlled' }}
                                            size='small'
                                           
                                            title={`${' Dashboard Status - No Feature to be generated'}`}
                                            // title='Dashboard Status Feature will not be added'
                                            sx={{ color: "#007fff" }}
                                        />
                    }
                        {permission?.includes("C") && (


(type && type-1==0 && checked?.includes(item?.id)==false) &&
                          <Typography
                            sx={{
                              display: "flex",
                              alignItems: "center",
                              fontSize: "0.87rem",
                            }}
                            onClick={() => {
                              setFeatureInfo({
                                vehicle: item?.vehicle_type,
                                abbreviation: item?.abbreviation,
                              })
                              addGroupModal.open(item?.abbreviation)
                            }

                            }
                          >
                            
                              
                            
                            <AddIcon sx={{ fontSize: "1.5rem" }} /> Group
                          </Typography>
                        )}
</>
  }


</Box>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box sx={{ marginLeft: "1rem" }}>
                        {!groupDataLoading && groupData ? (
                          groupData?.map((item: any) => {
                            return (
                              <Accordion
                                sx={{ border: "none" }}
                                expanded={expandedGroup === item?.id}
                                onChange={handleChangeGroup(
                                  item?.name,
                                  item?.id,
                                  item?.abbreviation,
                                  item?.vehicle_type

                                )}
                              >
                                <AccordionSummary
                                  expandIcon={
                                    expandedGroup === item?.id ? (
                                      <img
                                        src={openFolder}
                                        style={{ height: "1.2rem" }}
                                      />
                                    ) : (
                                      <FolderIcon
                                        sx={{
                                          fontSize: "1.5rem",
                                          color: "primary.main",
                                        }}
                                      />
                                    )
                                  }
                                  sx={{
                                    minHeight: "24px",
                                    padding: "0 0.5rem",
                                  }}
                                  aria-controls="panel2d-content"
                                  id={item?.id}
                                >
                                  <Box
                                    sx={{ width: "100%", fontSize: "1rem" }}
                                    onContextMenu={(e) => {
                                      e.stopPropagation();
                                      e.preventDefault();
                                      setAnchorPointGroup({
                                        x: e.clientX,
                                        y: e.clientY,
                                      });
                                      setOpenContextGroup(true);
                                      setFeatureInfo({
                                        vehicle: item?.vehicle_type,
                                        group: item?.id,
                                        abbreviation: item?.abbreviation,
                                      })
                                      setAddFeatureAddData({
                                        abbreviation: item?.abbreviation,
                                        group: item?.id,
                                        vehicle: item?.vehicle_type,
                                      });
                                      setTotalFeatureCount(item?.total_feature); // set total feature count
                                    }}
                                  >
                                    <Box
                                      sx={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                        marginLeft: "0.5rem",
                                      }}
                                    >
                                      <Typography
                                        sx={{
                                          fontSize: "0.87rem",
                                          color: getColor(item?.status),
                                        }}
                                      >
                                        {item?.name}
                                      </Typography>
                                      <Typography sx={{ fontSize: "0.87rem" }}>
                                        {item?.total_feature}&nbsp;
                                        {item?.total_feature > 1
                                          ? `${getModuleMulti()}` : `${getModule()}`}
                                      </Typography>
                                    </Box>
                                  </Box>
                                </AccordionSummary>
                                <AccordionDetails>
                                  {!featureDataLoading && featuresData ? (
                                    featuresData?.map((item: any) => {
                                      return (
                                        <Box
                                          sx={
                                            featureId === item?.name
                                              ? {
                                                // display: "flex",
                                                // alignItems: "center",
                                                marginLeft: "2rem",
                                                backgroundColor:
                                                  "primary.light",
                                               color: getFeatureColor(item?.status),
                                                cursor: "pointer",
                                              }
                                              : {
                                                // display: "flex",
                                                // alignItems: "center",
                                                marginLeft: "2rem",
                                              color: getFeatureColor(item?.status),
                                                cursor: "pointer",
                                              }
                                          }
                                          onContextMenu={(e) => {
                                            e.stopPropagation();
                                            e.preventDefault();
                                            setAnchorPointFeature({
                                              x: e.clientX,
                                              y: e.clientY,
                                            });
                                            setOpenContextFeaure(true);
                                            setTargetFeatureData(item);
                                          }}
                                        >
                                          {/* <img src={featureIcon} style={{ height: "1.2rem" }} /> */}
                                          <Typography
                                            sx={{
                                              fontSize: "0.87rem",
                                              padding: "0.2rem 0",
                                              paddingLeft: "0.5rem",
                                            }}
                                            onClick={() =>
                                              handleClickFeature(
                                                item?.name,
                                                item?.id
                                              )
                                            }
                                          >
                                            {item?.feature_id} &nbsp;
                                            {item?.name}
                                          </Typography>
                                        </Box>
                                      );
                                    })
                                  ) : (
                                    <Box sx={{ paddingRight: "1rem" }}>
                                      <Skeleton animation="wave" />
                                      <Skeleton animation="wave" />
                                      <Skeleton animation="wave" />
                                      <Skeleton animation="wave" />
                                      <Skeleton animation="wave" />
                                      <Skeleton animation="wave" />
                                      <Skeleton animation="wave" />
                                    </Box>
                                  )}
                                </AccordionDetails>
                              </Accordion>
                            );
                          })
                        ) : (
                          <Box sx={{ paddingRight: "1rem" }}>
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                            <Skeleton animation="wave" />
                          </Box>
                        )}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                }
              </>
            );
          })
        ) : (
          <Box sx={{ padding: "0 1rem" }}>
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
            <Skeleton animation="wave" />
          </Box>
        )}
      </Box>
      {addGroupModal.isOpen && (
        <AddFeatureGroupModal
          isOpen={addGroupModal.isOpen}
          onCloseModal={addGroupModal.close}
          getSystemData={getSystemData}
          abbreviation={addGroupModal.propsId}
          FeatureInfo={FeatureInfo}
          getModule={getModule()}
        />
      )}
      <AddFeatureModal
        isOpen={addFeatureModal.isOpen}
        onCloseModal={addFeatureModal.close}
        getSystemData={getSystemData}
        getModule={getModule()}
      />
      {openStatusContent.isOpen && (
        <DialogComponent
          isOpen={openStatusContent.isOpen}
          onClose={openStatusContent.close}
          content={contentText}
          type={iconText}
        />
      )}
    </div>
  );
}