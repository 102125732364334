
import { API } from "../../api-services";
import { UpdateTriangulationArg } from "../Actions/triangulation.action";

const url = "cost/triangulation_data/";

const graphurl = "/cost/graph_data/";

class TriangulationDB {

    getTriangulationData = ( topvault: number, ) => {
        return API.get( url, { top_vault: topvault } );
    };

    updateTriangulation ( arg: UpdateTriangulationArg ) {
        return API.patch( url + arg.id + "/", { inference: arg.inference } );
    }

    getGraphData = ( topvault: number, ) => {
        return API.get( graphurl, { top_vault: topvault } );
    };
};


const TRIANGULATION_SERVICE = new TriangulationDB();

export default TRIANGULATION_SERVICE;