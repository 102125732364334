import React, { useEffect, useRef, useState } from "react";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Autocomplete from "@mui/material/Autocomplete";
import {
  CellNameFive,
  CellNameOne,
  CellNameSecond,
  CellNameThird,
  FeatureNumericalCellNames,
} from "./ParameterHeaderType";
import { Label } from "@mui/icons-material";
import CancelIcon from "@mui/icons-material/Cancel";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import { API } from "../../api-services";
import ParameterDataBaseS3Doc from "./ParameterDataBaseS3Doc";
import { LoadingButton } from "@mui/lab";
import successIcon from "../../Assets/images/success.png";
import ErrorModalFilesMail from "../../ComponentUI/ProjectMailModule/ErrorModalFilesMail";
import { useBaseModal } from "../../ComponentUI/SearchPage/useBaseModal";
import Swal from "sweetalert2";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "40rem",
  height: "auto",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

interface AddParameterDataBaseModalProps {
  isOpen: any;
  onCloseModal: any;
  Item?: any;
  datatype?: any;
  Modaltype: any;
  rows?: any;
  TableIds?: any;
  setTableCounter: any;
  setDataTypeCounter?: any;
}
var DataTypeList = [
  {
    lable: "float",
    id: 1,
  },
  {
    lable: "nonzero-float",
    id: 2,
  },
  {
    lable: "positive-float",
    id: 3,
  },
  {
    lable: "nonzero-positive-float",
    id: 4,
  },
  {
    lable: "positive-int",
    id: 5,
  },
  {
    lable: "nonzero-positive-int",
    id: 6,
  },

  {
    lable: "longtext",
    id: 28,
  },
];
export const AddParameterDataBaseFeatureModal = (
  props: AddParameterDataBaseModalProps
) => {
  const {
    isOpen,
    onCloseModal,
    datatype,
    setTableCounter,
    setDataTypeCounter,
    Modaltype,
    Item,
    rows,
    TableIds,
  } = props;
  const [DataTypeValues, setDataTypeValues] = useState<any>({});

  const [Name, setName] = useState<any>(null);
  const [NameError, setNameError] = useState<any>(false);
  const [unit, setUnit] = useState<any>(null);
  const [unitError, setUnitError] = useState<any>(false);
  const [Description, setDescription] = useState<any>(null);
  const [Exceptions, setExceptions] = useState<any>(null);
  const [Allowedvalues, setAllowedvalues] = useState<any>(null);
  const [NotAllowed, setNotAllowed] = useState<any>(null);
  const [Defaultvalues, setDefaultvalues] = useState<any>(null);
  const [FileData, setFiledata] = useState<any>("text");
  const [DocLoader, setDocLoader] = useState<any>(false);
  const [FileUrl, setFileUrl] = useState<any>(null);
  const [loader, setLoader] = useState<any>(false);
  const [SuccessStatus, setSuccessStatus] = useState<any>(false);
  const [ErrorModalFileMsg, setErrorModalFileMsg] = useState<any>();
  const [TimingData, setTimingData] = useState<any>(null);
  const [TimingDataError, setTimingDataError] = useState<any>(false);
  const [FileName, setFileName] = useState<any>(null);
  const ErrorModalFiles = useBaseModal();
  const inputElRef = useRef<any>();
  const inputElRef2 = useRef<any>();
  const handleSelectdatatype = (item: any) => {
    setDataTypeValues(item);
  };

  useEffect(() => {
    setSuccessStatus(false);
    if (Modaltype == "Edit") {
      var data =
        DataTypeList &&
        DataTypeList?.filter((item: any, index: any) => {
          return item?.lable == rows?.data_type;
        })[0];

      setDataTypeValues(data);
    }
    if (Modaltype == "tableEdit") {
      var data =
        DataTypeList &&
        DataTypeList?.filter((item: any, index: any) => {
          return item?.lable == rows?.data_type;
        })[0];

      setDataTypeValues(data);
    }
  }, [Modaltype, rows]);


  useEffect(() => {
    setNameError(false);
    setTimingDataError(false);
    setUnit("");
    setName("");
    setTimingData(null);
    setFiledata(null);
    setFileUrl(null);
    setDescription("");
    setExceptions("");
    setNotAllowed("");
    setAllowedvalues("");
    setDefaultvalues("");
    setFileName(null);
    setDocLoader(false);
    if (Modaltype == "Edit" || Modaltype == "tableEdit") {
      setNameError(false);
      setTimingDataError(false);
      setUnit(rows && rows?.unit);
      setName(rows && rows?.name);
      setFiledata(null);
      setFileUrl(null);
      setTimingData(rows && rows?.time);
      setFileName(rows && rows?.file_name);
      setDescription(rows && rows?.description);
      setExceptions(rows && rows?.exceptions?.toString());
      setNotAllowed(rows && rows?.not_allowed_values?.toString());
      setAllowedvalues(rows && rows?.allowed_values?.toString());
      setDefaultvalues(rows && rows?.default_value);
      setDocLoader(false);
    }
  }, [DataTypeValues]);
  const handlechange = (e: any, type: any) => {
    if (type == "Name") {
      setNameError(false);
      setName(e?.target?.value);
    } else if (type == "unit") {
      setUnit(e?.target?.value);
    }
  };


  const Filechange = (e: any) => {
    setFiledata(e?.target?.files[0]);
    setFileName(e?.target?.files[0]?.name);

    ParameterDataBaseS3Doc(
      [e?.target?.files[0]],
      DataTypeValues && DataTypeValues?.lable,
      setFileUrl,
      setDocLoader
    );
  };

  const browseFiles = () => {
    return inputElRef.current?.click?.();
  };

  const browseFilesDoc = () => {
    return inputElRef2.current?.click?.();
  };
  const SaveHanlder = () => {
    if (Modaltype !== "Edit") {
      if (Name !== null && unit !== null) {
        API.post(
          "/api/db/feature_library/ ",
          {
            name: Name,
            unit: unit,
            name_type:
              DataTypeValues?.lable === "longtext" ? "text" : "numerical",
            data_type: DataTypeValues?.lable,
          },
          {}
        )
          .then((res: any) => {
            setLoader(false);
            onCloseModal();
            Swal.fire({
              icon: "success",
              title: "Success",
              text: "Parameter Added Successfully!",
            });

            setTableCounter((prev: any) => prev + 1);
          })
          .catch((err: any) => {
            const { data } = err.response;
            Swal.fire({
              icon: "error",
              title: "Error...",
              text: data[0],
              customClass: {
                container: "swal2Container",
              },
            });
          });
      } else {
        setNameError(true);
      }
    } else {
      API.put(
        `/api/db/feature_library/${rows?.id}/`,
        {
          name: Name,
          unit: unit,
          name_type: datatype,
          data_type: DataTypeValues?.lable,
        },
        {}
      )
        .then((res: any) => {
          setLoader(false);
          onCloseModal();
          Swal.fire({
            icon: "success",
            title: "Success",
            text: "Parameter Added Successfully!",
          });

          setTableCounter((prev: any) => prev + 1);
        })
        .catch((err: any) => {
          const { data } = err.response;
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: data[0],
            customClass: {
              container: "swal2Container",
            },
          });
        });
    }
  };

  return (
    <div>
      <Modal
        open={isOpen}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box>
          <input
            type="file"
            accept="application/pdf,.png, .jpg, .jpeg"
            style={{ display: "none" }}
            ref={inputElRef}
            onChange={(e: any) => Filechange(e)}
          />

          <input
            type="file"
            accept="application/pdf,.jpeg,.xlsx,.xls,.docx,.doc"
            style={{ display: "none" }}
            ref={inputElRef2}
            onChange={(e: any) => Filechange(e)}
          />

          {SuccessStatus ? (
            <Box sx={style}>
              <Box sx={{ textAlign: "center", width: "100%" }}>
                <img src={successIcon} style={{ width: "7rem" }} />
                <Typography
                  sx={{
                    marginTop: "1rem",
                    fontSize: "1rem",
                    width: "100%",
                    textAlign: "center",
                  }}
                >
                  {" "}
                  {Modaltype == "Edit"
                    ? "Parameter Updated Successfully!"
                    : "Parameter Added Successfully!"}
                </Typography>
                <Button
                  variant="contained"
                  onClick={onCloseModal}
                  size="small"
                  sx={{ margin: "1rem auto" }}
                >
                  ok
                </Button>
              </Box>
            </Box>
          ) : (
            <Box sx={style}>
              <Box
                sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}
              >
                <Typography
                  style={{
                    fontSize: "1rem",
                    fontWeight: "600",
                    width: "100%",
                    textAlign: "center",
                    marginRight: "-2rem",
                  }}
                >
                  Add Parameter
                </Typography>
                <CancelIcon
                  titleAccess="Close"
                  sx={{ cursor: "pointer", margin: "0.5rem" }}
                  onClick={onCloseModal}
                />
              </Box>
              <Divider
                sx={{
                  borderColor: "primary.light",
                  margin: "0 1rem",
                  marginBottom: "1rem",
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  width: "100%",
                  marginBottom: "0.5rem",
                }}
              >
                <Typography sx={{ fontSize: "1rem", width: "14rem" }}>
                  Data type<sup style={{ color: "red" }}>*</sup>{" "}
                </Typography>
                <Autocomplete
                  sx={{
                    width: "100%",
                    ".MuiSvgIcon-root.MuiSelect-icon": {
                      color: "primary.main",
                    },
                    fontSize: "1rem !important",
                  }}
                  size="small"
                  id="combo-box-demo"
                  disabled={
                    Modaltype == "Edit" || Modaltype == "tableEdit"
                      ? true
                      : false
                  }
                  value={DataTypeValues}
                  getOptionLabel={(option) =>
                    option?.lable == undefined
                      ? ""
                      : option?.lable?.toUpperCase()
                  }
                  onChange={(event, newValue, situation) =>
                    handleSelectdatatype(newValue)
                  }
                  options={DataTypeList && DataTypeList}
                  renderOption={(props, option) => (
                    <Box component="li" sx={{ fontSize: "1rem" }} {...props}>
                      {option?.lable?.toUpperCase()}
                    </Box>
                  )}
                  renderInput={(params) => (
                    <TextField
                      variant="standard"
                      sx={{
                        fontSize: "1rem",
                        borderBottom: "none",
                        ".MuiInputBase-root.MuiInput-root": {
                          fontSize: "1rem !important",
                        },
                        "& .MuiInput-underline:before": {
                          borderBottomColor: "primary.main",
                        },
                        "& .MuiInput-underline:after": {
                          borderBottomColor: "primary.main",
                        },
                      }}
                      {...params}
                      placeholder="Data Type"
                    />
                  )}
                />
              </Box>

              {DataTypeValues?.lable == undefined ? (
                ""
              ) : (
                <Box>
                  {FeatureNumericalCellNames.includes(DataTypeValues?.lable) ? (
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          marginBottom: "0.5rem",
                        }}
                      >
                        <Typography sx={{ fontSize: "1rem", width: "14rem" }}>
                          Name<sup style={{ color: "red" }}>*</sup>{" "}
                        </Typography>
                        <TextField
                          error={NameError}
                          value={Name}
                          onChange={(e: any) => handlechange(e, "Name")}
                          variant="standard"
                          sx={{
                            fontSize: "1rem",
                            width: "100%",
                            ".MuiInputBase-root.MuiInput-root": {
                              fontSize: "1rem !important",
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: NameError
                                ? "red"
                                : "primary.light",
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: NameError
                                ? "red"
                                : "primary.light",
                            },
                          }}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          marginBottom: "0.5rem",
                        }}
                      >
                        <Typography sx={{ fontSize: "1rem", width: "14rem" }}>
                          Unit<sup style={{ color: "red" }}>*</sup>{" "}
                        </Typography>
                        <TextField
                          value={unit}
                          onChange={(e: any) => handlechange(e, "unit")}
                          variant="standard"
                          sx={{
                            fontSize: "1rem",
                            width: "100%",
                            ".MuiInputBase-root.MuiInput-root": {
                              fontSize: "1rem !important",
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "primary.light",
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "primary.main",
                            },
                          }}
                        />
                      </Box>
                    </Box>
                  ) : (
                    <Box>
                      <Box
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          width: "100%",
                          marginBottom: "0.5rem",
                        }}
                      >
                        <Typography sx={{ fontSize: "1rem", width: "14rem" }}>
                          Name<sup style={{ color: "red" }}>*</sup>{" "}
                        </Typography>
                        <TextField
                          error={NameError}
                          value={Name}
                          onChange={(e: any) => handlechange(e, "Name")}
                          variant="standard"
                          sx={{
                            fontSize: "1rem",
                            width: "100%",
                            ".MuiInputBase-root.MuiInput-root": {
                              fontSize: "1rem !important",
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: NameError
                                ? "red"
                                : "primary.light",
                            },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: NameError
                                ? "red"
                                : "primary.light",
                            },
                          }}
                        />
                      </Box>
                    </Box>
                  )}
                </Box>
              )}
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "flex-end",
                  marginTop: "1rem",
                }}
              >
                <Button
                  disabled={DocLoader}
                  onClick={() => SaveHanlder()}
                  variant="contained"
                  size="small"
                  sx={{ cursor: "pointer" }}
                >
                  Save
                </Button>
              </Box>
            </Box>
          )}
          <Box>
            <ErrorModalFilesMail
              isOpen={ErrorModalFiles.isOpen}
              onCloseModal={ErrorModalFiles.close}
              ErrorMsg={ErrorModalFileMsg}
            />
          </Box>
        </Box>
      </Modal>
    </div>
  );
};
