import { API } from "../../api-services";
import { ID } from "../../utlis/commonInterfaces";
import { CreateFlaggedAssumptionArg, GetFlaggedAssumptionsArg, UpdateFlaggedAssumptionArg } from "../Actions/flaggedAssumption.actions";

export interface DeleteAssumptionCardArg {
    id: ID;
}
export default {
    get ( arg: GetFlaggedAssumptionsArg ) {
        return API.get( "cost/fetch_input_assumption/", {
            calculation: arg.userInputId
        },0 );
    },
    create ( arg: CreateFlaggedAssumptionArg ) {
        return API.post( "cost/set_input_assumption/", {
            calculation: arg.userInputId,
            input_field: arg.inputId
        },{},0 );
    },
    update ( arg: UpdateFlaggedAssumptionArg ) {
        const data = new FormData();
        data.append( "file", arg.image || "null" );

        return API.uploadPut( "cost/change_input_assumption/" + arg.id + "/", data );
    },
    delete ( arg: DeleteAssumptionCardArg ) {
        return API.delete( "cost/change_input_assumption/" + arg.id + "/" );
    }
};