import React from 'react'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import CancelIcon from '@mui/icons-material/Cancel';
import { DataGrid } from "@mui/x-data-grid/DataGrid/DataGrid";
import useWindowDimensions from '../../../ui-reusable-component/useWindowDimensions';
import Checkbox from '@mui/material/Checkbox';


import { ADMIN } from '../../../Redux/Services/admin.service';
import { Autocomplete, FormControl, MenuItem, Select, TextField, Divider, InputLabel, Input } from '@mui/material';
import { useParams } from 'react-router-dom';
import { userPermissionAction } from './BOMEntryLandingPage';
import { LoadingButton } from '@mui/lab';
import { API } from '../../../api-services';
import Swal from 'sweetalert2';
import { set } from 'lodash';
import { ImageInputViewBox } from "./ImageInputViewBox";
import { useRouteParams } from '../../../ui-reusable-component/useRouteParams';
import { table } from 'console';
import { ImageEdit } from 'material-ui/svg-icons';
import { ImageEditViewBox } from './ImageEditViewBox';
import Switch from '@mui/material/Switch';

type Props = {}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: " 80vw",
  height: "fit-content",
  bgcolor: "background.paper",
  borderRadius: "10px",
  p: 1,
};

export interface ISelectDuplicateProps {
  onCloseModal: any;
  isOpen: any;
  revisionList: any;
  rowData: any;
  index: any;
  table_id: any;
  closeParentModal: any;
  setCounter: any;
  setRevision: any;
  setCheckDuplicateShow: any;
  setDisabledFeilds: any;
  setSelectedRevision: any;
  setFormType: any;
  mappKeyValue: any;
  setDisableItemDescriptionAndName: any;
  setSaveDisabled: any;
}

export default function SelectDuplicate(props: ISelectDuplicateProps) {
  const { onCloseModal, isOpen, revisionList, rowData, index, table_id, closeParentModal, setCounter, setCheckDuplicateShow,  setRevision, setDisabledFeilds, setSelectedRevision, setFormType, mappKeyValue, setDisableItemDescriptionAndName, setSaveDisabled} = props;
  const { width } = useWindowDimensions();
  const [selected, setSelected] = React.useState<any>();
  const [selectedRow, setSelectedRow] = React.useState<any>();
  const [isOneCheckboxChecked, setIsOneCheckboxChecked] = React.useState(false);
  const { vault, topVault, projectId } = useRouteParams<any>();

  const getObjKeyName = () => {
    if (vault == 0) {
      return { top_vault: topVault };
    } else {
      return { vault: vault };
    }
  };

  const isUrl = (url:any) => {
    try {
      new URL(url);
      return true;
    } catch (error) {
      return false;
    }
  };
  const handleOpenLinkReference = (reference: string) => {
    window.open(reference);
  };

  const handleClose = () => {
    setCheckDuplicateShow(false);
    setDisabledFeilds(true);
    // setRevision(false);
    setFormType("New");
    setDisableItemDescriptionAndName(false);
    onCloseModal();

  }



  const checkDuplicateExistInPCB = () => {
    API.get(`/xcpep/pcbcosting_redirection/`, {
    project: projectId,
    item_name: selectedRow?.name,
    item_description: selectedRow?.description,
    top_vault: topVault,
    duplicate_create_record: true,
    vault: vault,
    table_id: table_id,

  }
  , 0)
  .then((res: any) => {
    handleDataAdd();
  })
  .catch((err: any) => {
    const { data } = err.response;
    Swal.fire({
      icon: "error",
      html: `<div>
            <br />
            <p style="color:"green">${data[0]}</p>   
             </div>`,
      customClass: {
        container: "swal2Container",
      },
    })
    .then((res: any) => {
      handleClose();
    })
    .catch((err: any) => {
      console.log(err);
    });
  })
}

  const handleDataAdd = () => {
    rowData[mappKeyValue['Item Name']] = selectedRow?.name;
    rowData[mappKeyValue['Item Description']] = selectedRow?.description;
    rowData[mappKeyValue['Unit Cost']] = selectedRow?.unit_cost;
    rowData[mappKeyValue['Length']] = selectedRow?.length;
    rowData[mappKeyValue['Width']] = selectedRow?.width;
    rowData[mappKeyValue['Component Type']] = selectedRow?.component_type;
    rowData[mappKeyValue['Reference']] = selectedRow?.reference;


    // API.put(
    //   "/xcpep/component_details/74699/",
    //   {
    //     param_value: rowData,
    //     index: index,
    //     table_id: table_id,
    //     purchase_item: selectedRow?.purchase_item,
    //     revision: selectedRow?.revision,
    //   },
    //   {
    //     ...getObjKeyName(),
    //   },

    //   0
    // )
    //   .then((res: any) => {
    //     ADMIN.toast.info("Data Updated Successfully");
        
        
    //   })
    //   .catch((err: any) => {
        
    //     const { data } = err.response;
    //     Swal.fire({
    //       icon: "error",
    //       html: `<div>
    //             <br />
    //             <p style="color:"green">${data[0]}</p>   
    //              </div>`,
    //       customClass: {
    //         container: "swal2Container",
    //       },
    //     });
    //   });



    setCounter((prev: any) => prev + 1);
      onCloseModal();
  setCheckDuplicateShow(false);
  setFormType(selectedRow?.revision_name == "Database" ? "Database" : "Revision");
  setRevision(selectedRow?.revision_name == "Database" ? false : true);
  setSaveDisabled(selectedRow?.revision_name == "Database" ? true : false);

    
    

  }


  let columns: any = [
    {
      field: "id",
      headerName: "Action",
      
      minWidth: width > 1370 ? 100 : 80,
      flex: 1,
      
      renderCell: (cellValues: any) => {
        return (<Box sx={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem', }}>
            <Checkbox disabled={cellValues?.row?.id != selected && isOneCheckboxChecked == true} onChange={(e) => {
                
                if(e.target.checked){
                  setIsOneCheckboxChecked(true)
                  setSelected(cellValues?.row?.id)
                  setSelectedRow(cellValues?.row)
                  console.log(cellValues?.row)
                  setSelectedRevision(cellValues?.row)


                }
                else{
                  setIsOneCheckboxChecked(false)
                  setSelected(null)
                  setSelectedRow(null)
                }

            }} />
           </Box>)
      }
  },
  { field: "sno", headerName: "S.No", width: width > 1370 ? 140 : 120, },
  { field: "name", headerName: "Item Name", width: width > 1370 ? 280 : 160, },
  { field: "description", headerName: "Item Description", width: width > 1370 ? 280 : 160, },
  {field: "revision_name", headerName: "Revision Name", width: width > 1370 ? 280 : 160, 
  renderCell: (cellValues: any) => {
    if(cellValues?.row?.revision_name == "Database"){
      return (
        <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem', }}>
          <Typography sx={{fontSize:"0.9rem"}}>{cellValues?.row?.revision_name}</Typography>
          
        </Box>
      )
    }
    else{
    
    
    return (
      <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem', }}>
        <Typography sx={{fontSize:"0.9rem"}}>{cellValues?.row?.revision_name} ({cellValues?.row?.city_country})</Typography>
        
      </Box>
    )
    }
  }
  },
  
  { field: "length", headerName: "Length", width: width > 1370 ? 140 : 120, },
  { field: "width", headerName: "Width", width: width > 1370 ? 140 : 120, },
  { field: "unit", headerName: "Unit", width: width > 1370 ? 140 : 120, },
  { field: "component_type", headerName: "Component type", width: width > 1370 ? 140 : 120, },
  { field: "reference", headerName: "Reference", width: width > 1370 ? 140 : 120,
  renderCell: (cellValues: any) => {
    
      
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem', }}>
            {isUrl(cellValues?.row?.reference)?<span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() => handleOpenLinkReference(cellValues?.row?.reference)}>
              Link
            </span>:<span
              style={{ textDecoration: "underline", cursor: "pointer" }}
              >
              {cellValues?.row?.reference}
            </span>}
            
          </Box>
        )
  }
  },
  { field: "purchase_item", headerName: "Purchase Item", width: width > 1370 ? 140 : 120,
 },
  { field: "unit_cost", headerName: "Unit Cost", width: width > 1370 ? 140 : 120,
  renderCell: (cellValues: any) => {
    
      if(cellValues?.row?.revision_name == "Database"){
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem', }}>
            <Typography sx={{fontSize:"0.9rem"}}>N.A.</Typography>
            
          </Box>
        )
      }
      else{
        return (
          <Box sx={{ display: 'flex', alignItems: 'center', columnGap: '0.5rem', }}>
            <Typography sx={{fontSize:"0.9rem"}}>{cellValues?.row?.unit_cost}</Typography>
            
          </Box>
        )
      }
  }
 },
];


var RowData =
revisionList &&
revisionList?.map((item: any, index: any) => ({
      ...item,
      sno: index + 1,


      
    }));

  let dataStructureDataGrid: any = {
    columns: columns,
    rows: RowData && RowData,
  };

  return (

    <Modal
      open={isOpen}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >

      <Box sx={style}>
        <Box sx={{ display: "flex", alignItems: "center", height: "2.5rem" }}>
          <Typography id="modal-modal-title" style={{
            fontSize: "1rem",
            fontWeight: "600",
            width: "100%",
            textAlign: "center",

          }}>
            Select Revision
          </Typography>
          <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={handleClose} />

        </Box>
        <Divider
          sx={{
            borderColor: "primary.light",
            margin: "0 1rem",
            marginBottom: "1rem",
          }}
        />
        <Box
                        sx={{
                          width: "100%",
                          padding: "0 1rem",
                          marginTop: "2rem",
                        }}>
                        {/* height: tableItem?.data.length == 1 ? 200 : 337, */}
                        {revisionList.length > 0 ? <DataGrid
                          headerHeight={60}
                          rowHeight={48}
                          {...dataStructureDataGrid}
                          
                          disableRowSelectionOnClick
                          // pageSize={5}
                          // rowsPerPageOptions={[5]}
                          sx={{
                            fontSize: "12px",
                            borderColor: "primary.light",
                            "&.MuiDataGrid-root.MuiDataGrid-autoHeight .MuiDataGrid-row--lastVisible .MuiDataGrid-cell":
                              {
                                borderBottomColor: "primary.light",
                              },
                            "&.MuiDataGrid-root .MuiDataGrid-cell": {
                              borderBottomColor: "primary.light",
                            },
                            "& .MuiDataGrid-root.MuiDataGrid-autoHeight.MuiDataGrid-root--densityCompact.MuiDataGrid-root":
                              {
                                border: "none !important",
                              },
                          }}
                          density="compact"
                          // checkboxSelection
                          // @ts-ignore
                          // components={{ Toolbar: GridToolbar }}
                          
                          autoHeight={true}
                          disableSelectionOnClick
                          hideFooterPagination={true}
                          hideFooter={true}
                          // experimentalFeatures={{ newEditingApi: true }}
                        />: (
                          <Box sx={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center", gap: "0.5rem", margin: "1rem 0rem", padding: "0rem 0.5rem" }}>
                            <Typography>No Duplicates Found, Create New!</Typography>
                          </Box>
                        
                        )}
                      </Box>
                      <Box sx={{ width: '100%', display: "flex", justifyContent: "end", alignItems: "center", gap: "0.5rem", margin: "1rem 0rem", padding: "0rem 0.5rem" }}>
                      {revisionList?.length > 0 ? <><Button variant="outlined" onClick={checkDuplicateExistInPCB} disabled={!isOneCheckboxChecked}>OK</Button>
                      <Button variant="contained" onClick={handleClose}>Discard & Create New</Button></> : <Button variant="contained" onClick={handleClose}>OK</Button>}
                      </Box>

        
        
      </Box>

    </Modal>

  )

}