import React, {useEffect, useRef, useState} from "react";
import {
  Box,
  CardMedia,
  IconButton,
  Modal,
  Table,
  TableCell,
  TableRow,
  Typography,
} from "@mui/material";
import styles from "./IdeaShowModal.module.scss";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import RViewerJS from "viewerjs-react";
import Image from "../../../../ViewModule/FeatureLandingPage/ArchitectureImageCard";
import loaderImage from "../../../../Assets/images/loading-screen.gif";
import useWindowDimensions from "../../../../ui-reusable-component/useWindowDimensions";

interface IdeaShowModalI {
  onClose: () => void;
  isOpen: boolean;
  bubblePoints: any;
  scatterIdeaData: any;
}

const IdeaShowModal: React.FC<IdeaShowModalI> = (props) => {
  const {height, width} = useWindowDimensions();
  const {isOpen, onClose, bubblePoints, scatterIdeaData} = props;
  const [idIdea, setIdIdea] = useState<number | null>();
  const [idIdeaDummy, setIdIdeaDummy] = useState<number | null>();
  let [startCombinationIndex, setstartCombinationIndex] = useState<any>(0);
  let [endCombinationIndex, setendCombinationIndex] = useState<any>(width > 1440 ? 8 : 6);
  const [pageNo, setPageNo]: any = useState<number>(1);  
  let MAX_LENGTH_COMBINATION: any = width > 1440 ? 8 : 6;
  const imageViewerElRef = useRef<any>(null);
  let [imageClicked, setImageClicked] = useState<any>();
  const handleClickIdea = (ideaID: number) => {
    setIdIdea(ideaID);
  };
  const filteredData: any =
    scatterIdeaData &&
    scatterIdeaData?.filter((item: any) => {
      return (
        item?.ease_of_implementation == bubblePoints?.ease &&
        item?.days_of_implementation == bubblePoints?.duration
      );
    });

  const IdeaIdfilteredData: any =
    scatterIdeaData &&
    filteredData?.filter((item: any, index: number) => {
      return item?.id == idIdea;
    });

  useEffect(() => {
    setIdIdea(null);
    setIdIdeaDummy(null);
  }, [isOpen]);

  useEffect(() => {
    if (idIdeaDummy == idIdea) {
      setIdIdea(scatterIdeaData && filteredData[0]?.id);
    }
  }, [filteredData]);

  const IdeaIdCombination: any =
    filteredData && filteredData?.map((data: any) => data?.id);

  let COMBINATION_MAP_LIST: any = IdeaIdCombination?.slice(
    startCombinationIndex,
    endCombinationIndex
  );

  const btnCombinationHandler = (key: any, type: any) => {
    type === "prev"
      ? setPageNo((prev: number) => prev - 1)
      : setPageNo((prev: number) => prev + 1);
    switch (key) {
      case 1:
        startCombinationIndex = endCombinationIndex;
        endCombinationIndex = startCombinationIndex + MAX_LENGTH_COMBINATION;
        setstartCombinationIndex(startCombinationIndex);
        setendCombinationIndex(endCombinationIndex);
        COMBINATION_MAP_LIST = IdeaIdCombination?.slice(
          startCombinationIndex,
          endCombinationIndex
        );
        break;
      case 2:
        endCombinationIndex = endCombinationIndex - MAX_LENGTH_COMBINATION;
        startCombinationIndex = startCombinationIndex - MAX_LENGTH_COMBINATION;
        setstartCombinationIndex(startCombinationIndex);
        setendCombinationIndex(endCombinationIndex);
        COMBINATION_MAP_LIST = IdeaIdCombination?.slice(
          startCombinationIndex,
          endCombinationIndex
        );
        break;
    }
  };

  const CombinationDataAnalysis: any =
    COMBINATION_MAP_LIST &&
    filteredData &&
    filteredData?.filter((data: any) => {
      return COMBINATION_MAP_LIST.includes(data?.id);
    });

  const handleImageClick = (e: any, image: string) => {
    setImageClicked(image);
    imageViewerElRef.current.src = image;
    return imageViewerElRef.current?.click?.();
  };

  return (
    <Modal
      open={isOpen}
      onClose={onClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box
        className={styles.container}
        adl-scrollbar="true"
        adl-scrollbar-width="0.6"
        sx={{
          flexGrow: 1,
          bgcolor: "background.paper",
        }}
      >
        <Box sx={{marginBottom: "2rem", display: "flex", alignItems: "center"}}>
          {width > 1440 ? (
            <>
              {filteredData && filteredData?.length > 8 && (
                <IconButton
                  onClick={() => btnCombinationHandler(2, "prev")}
                  disabled={pageNo == 1}
                  // style={{paddingRight: "0rem", paddingLeft: "0rem"}}
                >
                  <ChevronLeftIcon sx={{fontSize: "1.5rem"}} />
                </IconButton>
              )}
            </>
          ) : (
            <>
              {filteredData && filteredData?.length > 6 && (
                <IconButton
                  onClick={() => btnCombinationHandler(2, "prev")}
                  disabled={pageNo == 1}
                  // style={{paddingRight: "0rem", paddingLeft: "0rem"}}
                >
                  <ChevronLeftIcon sx={{fontSize: "1.5rem"}} />
                </IconButton>
              )}
            </>
          )}
          {CombinationDataAnalysis &&
            CombinationDataAnalysis?.map((item: any, index: number) => {
              return (
                <span
                  style={
                    item?.idea_status == "Submitted"
                      ? {
                          backgroundColor: "#007fff",
                          color: "white",
                          padding: "0.7rem",
                          cursor: "pointer",
                          marginRight: "0.25rem",
                          whiteSpace: "nowrap",
                        }
                      : item?.idea_status == "Rejected"
                      ? {
                          backgroundColor: "red",
                          color: "white",
                          padding: "0.7rem",
                          cursor: "pointer",
                          marginRight: "0.25rem",
                          whiteSpace: "nowrap",
                        }
                      : {
                          backgroundColor: "#a3c182",
                          color: "black",
                          padding: "0.7rem",
                          cursor: "pointer",
                          marginRight: "0.25rem",
                          whiteSpace: "nowrap",
                        }
                  }
                  onClick={() => handleClickIdea(item?.id)}
                >
                  {item?.idea_id}
                </span>
              );
            })}
          {width > 1440 ? (
            <>
              {filteredData && filteredData?.length > 8 && (
                <IconButton
                  onClick={() => btnCombinationHandler(1, "next")}
                  disabled={Math.floor(filteredData?.length / 8) + 1 == pageNo}
                  // style={{paddingRight: "0rem", paddingLeft: "0rem"}}
                >
                  <ChevronRightIcon sx={{fontSize: "1.5rem"}} />
                </IconButton>
              )}
            </>
          ) : (
            <>
              {filteredData && filteredData?.length > 6 && (
                <IconButton
                  onClick={() => btnCombinationHandler(1, "next")}
                  disabled={Math.floor(filteredData?.length / 6) + 1 == pageNo}
                  // style={{paddingRight: "0rem", paddingLeft: "0rem"}}
                >
                  <ChevronRightIcon sx={{fontSize: "1.5rem"}} />
                </IconButton>
              )}
            </>
          )}
        </Box>
        <div>
          {filteredData &&
            CombinationDataAnalysis &&
            IdeaIdfilteredData?.map((item: any, index: number) => {
              return (
                <div>
                  {item?.parameter_clicked?.length > 0 && (
                    <div style={{marginBottom: "0.5rem"}}>
                      {/* @ts-ignore */}
                      <RViewerJS>
                        <div
                          style={{
                            display: "grid",
                            gridTemplateColumns: "1fr 1fr",
                          }}
                        >
                          {item?.parameter_clicked?.map((itemsVal: any) => {
                            return (
                              <div>
                                <Image
                                  placeholderImg={loaderImage}
                                  src={itemsVal?.value}
                                  alt={itemsVal?.name}
                                  style={{
                                    objectFit: "contain",
                                    width: "90%",
                                    height: "300px",
                                    cursor: "pointer",
                                  }}
                                />
                              </div>
                            );
                          })}
                        </div>
                      </RViewerJS>
                    </div>
                  )}
                  <div
                    style={
                      item?.text_parameter?.length == 0
                        ? {
                            display: "grid",
                            gridTemplateColumns: "1fr 1.2fr",
                            marginBottom: "2rem",
                          }
                        : {
                            display: "grid",
                            gridTemplateColumns: "1fr 1.2fr",
                            marginBottom: "1rem",
                          }
                    }
                  >
                    <div>
                      <Table>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.1rem 0.5rem",
                              borderBottom: "none",
                              fontSize: "1rem",
                              width: "40%",
                            }}
                          >
                            <span
                              style={{marginRight: "1rem", fontWeight: "600"}}
                            >
                              Part No :
                            </span>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.1rem 0.5rem",
                              borderBottom: "none",
                              fontSize: "1rem",
                            }}
                          >
                            <span>{item?.part_no}</span>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.1rem 0.5rem",
                              borderBottom: "none",
                              fontSize: "1rem",
                              width: "40%",
                            }}
                          >
                            <span
                              style={{marginRight: "1rem", fontWeight: "600"}}
                            >
                              Part Name :
                            </span>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.1rem 0.5rem",
                              borderBottom: "none",
                              fontSize: "1rem",
                            }}
                          >
                            <span>{item?.part_name}</span>
                          </TableCell>
                        </TableRow>
                      </Table>
                    </div>

                    <div>
                      <Table>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.1rem 0.5rem",
                              borderBottom: "none",
                              fontSize: "1rem",
                              width: "50%",
                            }}
                          >
                            <span
                              style={{marginRight: "1rem", fontWeight: "600"}}
                            >
                              Competitor Part No :
                            </span>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.1rem 0.5rem",
                              borderBottom: "none",
                              fontSize: "1rem",
                            }}
                          >
                            <span>{item?.competitor_part_no}</span>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.1rem 0.5rem",
                              borderBottom: "none",
                              fontSize: "1rem",
                              width: "50%",
                            }}
                          >
                            <span
                              style={{marginRight: "1rem", fontWeight: "600"}}
                            >
                              Competitor Part Name :
                            </span>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.1rem 0.5rem",
                              borderBottom: "none",
                              fontSize: "1rem",
                            }}
                          >
                            {" "}
                            <span>{item?.competitor_part_name}</span>
                          </TableCell>
                        </TableRow>
                      </Table>
                    </div>
                    <div>
                      <Table>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.1rem 0.5rem",
                              borderBottom: "none",
                              fontSize: "1rem",
                              width: "40%",
                            }}
                          >
                            <span
                              style={{marginRight: "1rem", fontWeight: "600"}}
                            >
                              Savings :
                            </span>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.1rem 0.5rem",
                              borderBottom: "none",
                              fontSize: "1rem",
                            }}
                          >
                            <span>{item?.cost_of_implementation} INR</span>
                          </TableCell>
                        </TableRow>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.1rem 0.5rem",
                              borderBottom: "none",
                              fontSize: "1rem",
                              width: "40%",
                            }}
                          >
                            <span
                              style={{marginRight: "1rem", fontWeight: "600"}}
                            >
                              Ease :
                            </span>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.1rem 0.5rem",
                              borderBottom: "none",
                              fontSize: "1rem",
                            }}
                          >
                            {" "}
                            <span>{item?.ease_of_implementation}/10</span>
                          </TableCell>
                        </TableRow>
                      </Table>
                    </div>
                    <div>
                      <Table>
                        <TableRow>
                          <TableCell
                            sx={{
                              padding: "0.1rem 0.5rem",
                              borderBottom: "none",
                              fontSize: "1rem",
                              width: "50%",
                            }}
                          >
                            <span
                              style={{marginRight: "1rem", fontWeight: "600"}}
                            >
                              Time of Implementation :
                            </span>
                          </TableCell>
                          <TableCell
                            sx={{
                              padding: "0.1rem 0.5rem",
                              borderBottom: "none",
                              fontSize: "1rem",
                            }}
                          >
                            <span>{item?.days_of_implementation} Days</span>
                          </TableCell>
                        </TableRow>
                      </Table>
                    </div>
                  </div>
                  {item?.text_parameter?.length > 0 && (
                    <div style={{marginBottom: "2rem", fontSize: "1rem"}}>
                      <span style={{marginRight: "1rem", fontWeight: "600"}}>
                        {item?.text_parameter[0]?.parameter_name} :
                      </span>
                      <span>{item?.text_parameter[0]?.value}</span>
                    </div>
                  )}
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      fontSize: "1rem",
                    }}
                  >
                    <span
                      style={
                        item?.idea_status == "Submitted"
                          ? {
                              backgroundColor: "#007fff",
                              color: "white",
                              padding: "0.5rem 1rem",
                              width: "100%",
                              textAlign: "center",
                            }
                          : item?.idea_status == "Rejected"
                          ? {
                              backgroundColor: "red",
                              color: "white",
                              padding: "0.5rem 1rem",
                              width: "100%",
                              textAlign: "center",
                            }
                          : {
                              backgroundColor: "#a3c182",
                              color: "black",
                              padding: "0.5rem  1rem",
                              width: "100%",
                              textAlign: "center",
                            }
                      }
                    >
                      {item?.idea_status == "Submitted"
                        ? "Submitted for Implementation"
                        : item?.idea_status}
                    </span>
                  </div>
                </div>
              );
            })}
        </div>
      </Box>
    </Modal>
  );
};
export default IdeaShowModal;
