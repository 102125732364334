// react

// vendors
// services    "immutability-helper": "~3.0.1",
// utils
// constants
// factories
// core
// redux
// ui-components
// others
// styles
import styles from "./IdeaCalculatorValueInputModal.module.scss";

import Box from '@mui/material/Box/Box';
import { Button, Divider, Modal, Skeleton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from '@mui/material';
import { CALCULATOR_INPUT_CONTENT_TYPE, CALCULATOR_INPUT_TYPE, InputContentType } from "../../../Configuration/Costing/calculator.constant";
import { ID } from "../../../utlis/commonInterfaces";
import { CalculatorInput } from "../../../Redux/Reducers/calculatorInput.reducer";
import { debounce, isNull, isNumber } from "lodash";
import { useCollection } from "../../../CustomHook/useCollection";
import { useEffect, useState } from "react";
import { UserInput, userInputSelectors } from "../../../Redux/Reducers/userInput.reducer";
import { useDispatch, useSelector } from "react-redux";

import { triangulationSelectors } from "../../../Redux/Reducers/triagulation.reducer";

import update from "immutability-helper";
import React from "react";
import CancelIcon from '@mui/icons-material/Cancel';
import CalculatorInputRow from "../../CostingCreateModule/CostingTriangulation/CalculatorInputRow";
import { IdeauserInputSelectors } from "../../../Redux/Reducers/IdeauserInput.reducer";
import { getUserInputsTriangulation, resetUserInputs, updateUserInputTriangulation } from "../../../Redux/Actions/IdeauserInput.actions";
import { useQueryDispatch } from "../../../CustomHook/useQueryDispatch";
import IdeaCalculatorInputRow from "./IdeaCalculatorInputRow";
// interfaces and types
// lazy

// ----------------------------------------------------------------------------------
const style = {
    position: 'absolute' as 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: "60rem",
    bgcolor: 'background.paper',
    borderRadius: '10px',
    p: 1,
};
interface CalculatorValueInputModalProps {
    isOpen: any;
    onClose: any;
    fetchCalculator: string;
    calculatorData: any;
    CalculatorItem:any;
    setCount?: any;
    count?: any;
    setCounter?:any;
    IdeaType?:any;
}

interface CalculatorInputFromApi {
    id: ID;
    content_type: InputContentType;
    name: string;
    /** string used to refere the input in the formula */
    alias: string;

    parameter?: ID;
    table_element?: ID;

    attribute?: string;

    formula?: string;
    constant?: number;
    user_input?: string;
    calculator_category?: number;
    volume?: number;
    calculator: ID;
    image_url?: string;
    test_input?: number;
    result?: number;
}



export const transformCalculatorInputFromApi = (input: CalculatorInputFromApi): CalculatorInput => {
    const { content_type, ...rest } = input;
    const obj: any = {};
    if (content_type) {
        if (rest.parameter) {
            if (content_type === CALCULATOR_INPUT_CONTENT_TYPE.BOM_PARAMETER) obj.type = CALCULATOR_INPUT_TYPE.BOM_PARAMETER;
            if (content_type === CALCULATOR_INPUT_CONTENT_TYPE.COMMODITY_PARAMETER) obj.type = CALCULATOR_INPUT_TYPE.COMMODITY_PARAMETER;
        }
        else if (rest.attribute) {
            if (content_type === CALCULATOR_INPUT_CONTENT_TYPE.COST_INPUT) {
                obj.type = CALCULATOR_INPUT_TYPE.COST_INPUT;
            }
            else if(content_type === CALCULATOR_INPUT_CONTENT_TYPE.CALCULATOR_RESULT) {
                obj.type = CALCULATOR_INPUT_TYPE.CALCULATOR_RESULT;
            }
        }
    }
    if (rest.formula) obj.type = CALCULATOR_INPUT_TYPE.FORMULA;
    else if (!isNull(rest.user_input)) obj.type = CALCULATOR_INPUT_TYPE.USER_INPUT;
    if (rest.constant) obj.type = CALCULATOR_INPUT_TYPE.CONSTANT;
    if (rest.volume) obj.type = CALCULATOR_INPUT_TYPE.VOLUME;

    return Object.assign({}, rest, obj);
};

const IdeaCalculatorValueInputModal: React.FC<CalculatorValueInputModalProps> = (props) => {

    const { fetchCalculator, calculatorData, isOpen, onClose, setCount, count,CalculatorItem ,setCounter,IdeaType} = props;

    const inputs = useCollection<CalculatorInput>();

    const [data, setData] = useState<UserInput>();

    const dispatch = useDispatch();



    useEffect(() => {
        if (fetchCalculator == "TRUE") {
            inputs.setAll(calculatorData && calculatorData[0]?.calculator_fields.map(transformCalculatorInputFromApi).sort((a: any, b: any) => a.sequence - b.sequence));
        }
    }, [fetchCalculator]);



    const userInputsFetched = useQueryDispatch({
        query: { action: getUserInputsTriangulation, arg: { cardCostId: calculatorData && calculatorData[0].id }, condition: fetchCalculator === "TRUE" },
        cleanup: { action: resetUserInputs, args: [] },
        dependency: [fetchCalculator == "TRUE",isOpen],
    });


    const userInput = useSelector(IdeauserInputSelectors.selectAll);


    useEffect(() => {
        if (userInput) setData(userInput[0]);
    }, [userInput]);


    const handleUserInputValueChange = (inputId: ID) => (value: any) => {
        if (!data) return;
        const updated = update(data, {
            user_input: {
                [inputId]: { $set: value }
            }
        });
        ExactDebounce(updated, calculatorData)
        setData(updated);
    };




    const ExactDebounce = React.useRef(
        debounce(async (values: any, cal: any) => {
            if (!values) return;
            const { id } = values;
        
            const action: any = await dispatch(updateUserInputTriangulation({ id: values?.id, calculator: cal && cal[0].id, updates: values }));
            // 
            if (updateUserInputTriangulation.fulfilled.match(action)) {
                setCount((prev: any) => prev + 1);
            }
        }, 500)
    ).current;

    const renderRow = (inputCalculationId: any) => {
        // NOTE: inputCalculationId & inputId are same
        return (
            <IdeaCalculatorInputRow
            IdeaType={IdeaType}
                inputCalculationData={inputCalculationId}
                userInputValue={data?.user_input[inputCalculationId.id]}
                onValueChange={handleUserInputValueChange(inputCalculationId.id)}
                resultValue={data?.result[inputCalculationId.alias]}
            />
        );
    };


    const handleSave = async () => {
        if (!data) return;
        const { id } = data;
        const action: any = await dispatch(updateUserInputTriangulation({ id: id, calculator: calculatorData && calculatorData[0].id, updates: data }));
        // 
        if (updateUserInputTriangulation.fulfilled.match(action)) {
            setCount(count + 1);
        }

    };

    const closeHandler=()=>{
      
        if(IdeaType=="Client"){
            onClose()
        }else{
            setCounter((prev:any)=>prev+1)
            onClose()
        }
       
    }

    return (
        <div
            data-testid="calculator-value-input-modal"
            className={styles.container}
        >
            <Modal
                open={isOpen}
              
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Box sx={{ display: 'flex', alignItems: 'center', height: '2.5rem' }}>
                        <Typography
                            style={{
                                fontSize: "1rem",
                                fontWeight: "600",
                                width: "100%",
                                textAlign: "center",
                                marginRight: '-2rem'
                            }}
                        >
                            Calculator Input ({CalculatorItem?.name})
                        </Typography>
                        <CancelIcon titleAccess="Close" sx={{ cursor: 'pointer', margin: '0.5rem' }} onClick={()=>closeHandler()} />
                    </Box>
                    <Divider
                        sx={{
                            borderColor: "primary.light",
                            margin: "0 1rem",
                            marginBottom: "1rem",
                        }}
                    />
                    <Box sx={{ maxHeight: '80vh' }}
                        adl-scrollbar="true"
                        adl-scrollbar-width="0.3">
                        {fetchCalculator == "FETCHING" ? <><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton><Skeleton></Skeleton></>
                            :
                            <Table stickyHeader aria-label="sticky table" className={styles.tableStyle}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', backgroundColor: "primary.light" }}>Input</TableCell>
                                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', backgroundColor: "primary.light" }}>Value</TableCell>
                                        <TableCell sx={{ padding: '0.2rem 0.5rem', borderBottomColor: 'primary.light', backgroundColor: "primary.light", textAlign: 'center', }} >Unit</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {inputs.getCollection().map(renderRow)}
                                </TableBody>
                                <Button onClick={onClose}>
                                    {/* <Icon name="close" className="bicon" title="Cancel" />  */}
                                </Button>
                            </Table>
                        }
                    </Box>
                </Box>
            </Modal>

        </div>
    );
};

// ----------------------------------------------------------------------------------

export default IdeaCalculatorValueInputModal;